<app-dialog [overlayClose]="false" (action)="onDialogAction($event)" [(visible)]="visible">
  <div class="FormElement Icon-inline-container u-section-trailer" *ngIf="showTestImportError">
    <span class="u-remLineHeight" [innerHTML]="'CompanyDataImport.TestImportErrorMessage' | translate"></span>
  </div>

  <div *ngIf="!testImport">
    <!-- Fatal -->
    <div class="FormElement Icon-inline-container u-section-trailer" *ngIf="IsFatal">
      <div class="u-spacing-right">
        <app-icon [icon]="'Error'" [size]="'large'"></app-icon>
      </div>
      <span class="u-remLineHeight" [innerHTML]="'CompanyDataImport.FatalError' | translate"></span>
    </div>

    <div class="FormElement  Icon-inline-container" *ngIf="IsError || IsWarning">
      <div class="u-spacing-right">
        <app-icon [icon]="'Success'"></app-icon>
      </div>
      <span class="u-remLineHeight" [innerHTML]="'CompanyDataImport.ErrorWarningSuccessMessage' | translate"></span>
    </div>
    <!-- Error -->
    <div class="FormElement Icon-inline-container u-section-trailer" *ngIf="IsError">
      <div class="u-spacing-right">
        <app-icon [icon]="'Error'"></app-icon>
      </div>
      <span class="u-remLineHeight" [innerHTML]="'CompanyDataImport.ErrorMessage' | translate"></span>
    </div>
    <!-- Warning -->
    <div class="FormElement Icon-inline-container u-section-trailer" *ngIf="IsWarning">
      <div class="u-spacing-right">
        <app-icon [icon]="'Warning'"></app-icon>
      </div>
      <span class="u-remLineHeight" [innerHTML]="'CompanyDataImport.WarningMessage' | translate"></span>
    </div>
  </div>
  <div class="FormElement" *ngIf="hasError">
    <div class="CustomTable ImportErrorGrid">
      <div class="FormElement-grid k-grid k-widget">
        <table>
          <thead>
            <tr>
              <th>{{ 'CompanyDataImport.EmployeeImportErrorMessage' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of importResult?.Errors">
              <td>
                <div class="FormElement Icon-inline-container">
                  <div class="u-spacing-left u-spacing-right">
                    <app-icon *ngIf="item.Error" [icon]="'Error'" [size]="'small'"></app-icon>
                    <app-icon *ngIf="!item.Error" [icon]="'Warning'" [size]="'small'"></app-icon>
                  </div>
                  <span [innerHTML]="item.Message"></span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <app-dialog-action *ngIf="!IsFatal && !showTestImportError" [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action *ngIf="IsFatal || showTestImportError" [type]="'Ok'"></app-dialog-action>
  <app-dialog-action
    *ngIf="!IsFatal && !showTestImportError"
    [action]="'Complete'"
    [label]="'CompanyDataImport.CompleteImportText' | translate"
    [class]="'Button--primary'"
  >
  </app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" (action)="visible = false" [(visible)]="successDialogVisible">
  <div class="FormElement Icon-inline-container u-trailer">
    <div class="u-spacing-right">
      <app-icon [icon]="'Success'"></app-icon>
    </div>
    <span *ngIf="isBalanceImport">{{ 'CompanyDataImport.DataUploadSuccess_CorrectBalance' | translate }}</span>
    <span *ngIf="!isBalanceImport">{{ 'CompanyDataImport.DataUploadSuccess_GeneralTab' | translate }}</span>
  </div>

  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" [(visible)]="fatalDialogVisible">
  <div class="FormElement Icon-inline-container u-trailer">
    <div class="u-spacing-right">
      <app-icon [icon]="'Error'" [size]="'large'"></app-icon>
    </div>
    <span>{{ importResult?.FatalMessage }}</span>
  </div>

  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
