<div class="company-approval">
  <app-fixed-panel [excludedComponents]="listClassExcluded" [specificClassName]="'Island-approval'">
    <div class="Island Island-approval tabs">
      <div class="ConfigNav Navigation-wrapper Navigation Navigation-inner" [ngClass]="{ 'is-active': menuOpen }">
        <div>
          <button
            id="divCompanyOptions"
            class="Navigation-link MainTabButton"
            (click)="gotoTimeEntryApproval()"
            [ngClass]="{ 'is-active': approvalTimeEntryIsActive }"
          >
            {{ 'CompanySalaryTypes.ApprovalApprovalTimeEntry' | translate }}
          </button>
        </div>
      </div>

      <div *ngIf="approvalTimeEntryIsActive">
        <!-- class="tab-content tab active" -->
        <app-module-check [moduleId]="8">
          <app-company-approval-grid></app-company-approval-grid>
        </app-module-check>
      </div>
    </div>
  </app-fixed-panel>

  <div class="Actions" *ngIf="!isMobile">
    <app-menu-button
      *ngIf="reportDialogService.isVisibleReportIcon"
      [menuCondition]="true"
      (menuClick)="onShowReportsEventClick()"
      [menuIcon]="'File'"
      [menuLabel]="'Employee.Reports'"
      [menuTooltip]="'Employee.Reports'"
      [actionButtonMenu]="true"
      [menuButtonClass]="'Action-button Action-buttonReports'"
      [menuButtonId]="'ActionButtonReports'"
    >
    </app-menu-button>

    <app-menu-button
      [menuIcon]="'Help'"
      [menuLabel]="'Help.Title'"
      [menuTooltip]="'Help.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
      <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
    </app-menu-button>
  </div>

  <div class="Actions" *ngIf="isMobile">
    <app-menu-button
      [menuIcon]="'ThreeDots'"
      [menuLabel]="'More.Title'"
      [menuTooltip]="'More.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
      <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
    </app-menu-button>
  </div>

  <app-dialog
    class="blue-dialog"
    [(visible)]="isVisible"
    [title]="'CompanyApproval.WelcomeTitle' | translate"
    [leadingText]="'CompanyApproval.WelcomeMessage' | translate"
  >
    <app-dialog-action type="Ok"></app-dialog-action>
  </app-dialog>

  <app-dialog class="blue-dialog" [(visible)]="noReportVisible" [leadingText]="'Report.NoReport' | translate">
    <app-dialog-action type="Ok"></app-dialog-action>
  </app-dialog>
</div>
