/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./accountant-leave-dialog.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../custom-controls/dialogs/dialog.component.ngfactory";
import * as i3 from "../../custom-controls/dialogs/dialog.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../services/session/session.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../custom-controls/dialogs/dialog-action.component.ngfactory";
import * as i8 from "../../custom-controls/dialogs/dialog-action.component";
import * as i9 from "./accountant-leave-dialog.component";
import * as i10 from "./accountant-summary.service";
import * as i11 from "../../common/router-state.service";
import * as i12 from "../../services/api/data.service";
var styles_AccountantLeaveDialogComponent = [i0.styles];
var RenderType_AccountantLeaveDialogComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AccountantLeaveDialogComponent, data: {} });
export { RenderType_AccountantLeaveDialogComponent as RenderType_AccountantLeaveDialogComponent };
export function View_AccountantLeaveDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "app-dialog", [], null, [[null, "visibleChange"], [null, "action"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.visible = $event) !== false);
        ad = (pd_0 && ad);
    } if (("action" === en)) {
        var pd_1 = (_co.dialogAction($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_DialogComponent_0, i2.RenderType_DialogComponent)), i1.ɵdid(1, 49152, null, 1, i3.DialogComponent, [i4.DomSanitizer, i5.SessionService, i1.ElementRef], { title: [0, "title"], overlayClose: [1, "overlayClose"], visible: [2, "visible"] }, { visibleChange: "visibleChange", action: "action" }), i1.ɵqud(603979776, 1, { buttonElements: 1 }), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, 0, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "app-dialog-action", [], null, null, null, i7.View_DialogActionComponent_0, i7.RenderType_DialogActionComponent)), i1.ɵdid(6, 180224, [[1, 4]], 0, i8.DialogActionComponent, [i5.SessionService], { disabled: [0, "disabled"], type: [1, "type"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "app-dialog-action", [], null, null, null, i7.View_DialogActionComponent_0, i7.RenderType_DialogActionComponent)), i1.ɵdid(8, 180224, [[1, 4]], 0, i8.DialogActionComponent, [i5.SessionService], { action: [0, "action"], label: [1, "label"], class: [2, "class"], close: [3, "close"], disabled: [4, "disabled"] }, null), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 6, "app-dialog", [["class", "blue-dialog"]], null, [[null, "action"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("action" === en)) {
        var pd_0 = (_co.redirectToLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DialogComponent_0, i2.RenderType_DialogComponent)), i1.ɵdid(11, 49152, null, 1, i3.DialogComponent, [i4.DomSanitizer, i5.SessionService, i1.ElementRef], { visible: [0, "visible"] }, { action: "action" }), i1.ɵqud(603979776, 2, { buttonElements: 1 }), (_l()(), i1.ɵted(13, 0, [" ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, 0, 1, "app-dialog-action", [], null, null, null, i7.View_DialogActionComponent_0, i7.RenderType_DialogActionComponent)), i1.ɵdid(16, 180224, [[2, 4]], 0, i8.DialogActionComponent, [i5.SessionService], { type: [0, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform("Accountant.Leave")); var currVal_1 = true; var currVal_2 = _co.visible; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _co.busy; var currVal_5 = "Cancel"; _ck(_v, 6, 0, currVal_4, currVal_5); var currVal_6 = "Leave"; var currVal_7 = i1.ɵunv(_v, 8, 1, i1.ɵnov(_v, 9).transform("Accountant.Leave")); var currVal_8 = "Button--destructive"; var currVal_9 = false; var currVal_10 = _co.busy; _ck(_v, 8, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.redirectDialogVisible; _ck(_v, 11, 0, currVal_11); var currVal_13 = "Ok"; _ck(_v, 16, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.leaveMessage; _ck(_v, 4, 0, currVal_3); var currVal_12 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("Accountant.RedirectToLogin")); _ck(_v, 13, 0, currVal_12); }); }
export function View_AccountantLeaveDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-accountant-leave-dialog", [], null, null, null, View_AccountantLeaveDialogComponent_0, RenderType_AccountantLeaveDialogComponent)), i1.ɵdid(1, 245760, null, 0, i9.AccountantLeaveDialogComponent, [i10.AccountantSummaryService, i11.RouterStateService, i5.SessionService, i6.TranslateService, i12.DataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountantLeaveDialogComponentNgFactory = i1.ɵccf("app-accountant-leave-dialog", i9.AccountantLeaveDialogComponent, View_AccountantLeaveDialogComponent_Host_0, { visible: "visible" }, { visibleChange: "visibleChange" }, []);
export { AccountantLeaveDialogComponentNgFactory as AccountantLeaveDialogComponentNgFactory };
