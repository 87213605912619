import { ElementRef, EventEmitter, QueryList } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as srcDoc from 'srcdoc-polyfill';
import { Browser } from '../../common/browser';
import { SessionService } from '../../services/session/session.service';
import { DialogActionComponent } from './dialog-action.component';
var DialogComponent = /** @class */ (function () {
    function DialogComponent(sanitizer, sessionService, elementRef) {
        this.sanitizer = sanitizer;
        this.sessionService = sessionService;
        this.elementRef = elementRef;
        this.minWidth = 0;
        this.overlayClose = true;
        this.isOverlayKeydown = false;
        this.isHideWhenSessionExpired = true;
        this.disableCloseButtonTitle = false;
        this.visibleValue = false;
        this.mustChangePassword = false;
        this.visibleChange = new EventEmitter();
        this.action = new EventEmitter();
    }
    Object.defineProperty(DialogComponent.prototype, "isCordovaApp", {
        get: function () {
            return this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogComponent.prototype, "isEdge", {
        get: function () {
            return this.sessionService.browser.isEdge;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogComponent.prototype, "leadingText", {
        get: function () {
            return this.leadingTextValue.toString();
        },
        set: function (value) {
            this.leadingTextValue = this.sanitizer.bypassSecurityTrustHtml(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogComponent.prototype, "visible", {
        get: function () {
            if (this.isHideWhenSessionExpired && this.sessionService.checkIsAliveError && !this.mustChangePassword) {
                return false;
            }
            return this.visibleValue;
        },
        set: function (value) {
            var _this = this;
            if (this.visibleValue !== value) {
                if (value === false) {
                    if (this.sessionService.browser.isHybridApp) {
                        window.screen.orientation.lock('portrait');
                    }
                }
                this.visibleValue = value;
                this.contentLoaded = false;
                this.visibleChange.emit(value);
                if (value) {
                    this.registerEvents();
                }
                if (value) {
                    setTimeout(function () {
                        if (_this.disableCloseButtonTitle) {
                            if (_this.buttonArea && _this.buttonArea.el && _this.buttonArea.el.nativeElement) {
                                var elRoot = _this.buttonArea.el.nativeElement;
                                var elPrimary = elRoot.getElementsByClassName('Button--primary')[0];
                                if (elPrimary) {
                                    elPrimary.remove();
                                }
                                var elDestructive = elRoot.getElementsByClassName('Button--destructive')[0];
                                if (elDestructive) {
                                    elDestructive.remove();
                                }
                                var elSeconDary = elRoot.getElementsByClassName('Button--secondary')[0];
                                if (elSeconDary) {
                                    elSeconDary.remove();
                                }
                            }
                        }
                        var el = document.getElementsByTagName('kendo-dialog');
                        if (el) {
                            Array.prototype.forEach.call(el, function (model) {
                                setTimeout(function () {
                                    var el1 = model
                                        .getElementsByTagName('div')[1]
                                        .getElementsByTagName('kendo-dialog-titlebar')[0]
                                        .getElementsByTagName('div')[1]
                                        .getElementsByTagName('a')[0];
                                    if (el1) {
                                        if (_this.disableCloseButtonTitle) {
                                            el1.remove();
                                            return;
                                        }
                                        el1.addEventListener('click', function () {
                                            _this.visible = false;
                                            _this.action.emit('close');
                                        });
                                    }
                                });
                            });
                        }
                        _this.onDefaultFocusbutton();
                    });
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    DialogComponent.prototype.onDefaultFocusbutton = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.buttonArea && _this.buttonArea.el && _this.buttonArea.el.nativeElement) {
                var elRoot = _this.buttonArea.el.nativeElement;
                var elPrimary = elRoot.getElementsByClassName('Button--primary')[0];
                if (elPrimary) {
                    elPrimary.focus();
                    return;
                }
                var elDestructive = elRoot.getElementsByClassName('Button--destructive')[0];
                if (elDestructive) {
                    elDestructive.focus();
                    return;
                }
                var elSeconDary = elRoot.getElementsByClassName('Button--secondary')[0];
                if (elSeconDary) {
                    elSeconDary.focus();
                    return;
                }
            }
        });
    };
    Object.defineProperty(DialogComponent.prototype, "iframeContent", {
        set: function (value) {
            if (this.iframeContentValue !== value) {
                this.iframeContentValue = value;
                this.contentLoaded = false;
                this.safeContent = this.sanitizer.bypassSecurityTrustHtml(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogComponent.prototype, "buttons", {
        get: function () {
            if (this.actions && this.actions.length > 0) {
                return this.actions;
            }
            return this.buttonElements.toArray();
        },
        enumerable: true,
        configurable: true
    });
    DialogComponent.prototype.registerEvents = function () {
        var _this = this;
        setTimeout(function () {
            var overlayElements = _this.elementRef.nativeElement.querySelectorAll('.k-overlay');
            if (overlayElements && overlayElements.length > 0) {
                overlayElements[0].addEventListener('click', function () {
                    if (_this.overlayClose) {
                        _this.onClose();
                    }
                });
            }
            var primaryButton = _this.elementRef.nativeElement.querySelectorAll('[ng-reflect-ng-class=Button--destructive]');
            if (primaryButton && primaryButton.length > 0) {
                primaryButton[0].focus();
            }
        });
    };
    DialogComponent.prototype.onClick = function (action, close) {
        if (!this.customVisible) {
            this.visible = !close;
        }
        this.action.emit(action);
    };
    DialogComponent.prototype.onClose = function (event) {
        if (this.overlayClose) {
            this.visible = false;
            this.action.emit('close');
        }
        else if (this.isOverlayKeydown) {
            this.visible = false;
            this.action.emit('close');
        }
    };
    DialogComponent.prototype.iframeLoaded = function () {
        var _this = this;
        if (this.isEdge && this.iframeContentValue && !this.contentLoaded) {
            // https://stackoverflow.com/questions/42257549/angular-2-srcdoc-polyfill-doesnt-work
            srcDoc.set(this.iframe.nativeElement, this.iframeContentValue);
            this.contentLoaded = true;
        }
        if (this.iframe.nativeElement &&
            this.iframe.nativeElement.contentWindow &&
            this.iframe.nativeElement.contentWindow.document) {
            var iframeContentWindow = this.iframe.nativeElement.contentWindow.document || null;
            iframeContentWindow.addEventListener('keydown', function (e) {
                _this.onkeydown(e);
            });
        }
        ////if (!this.isCordovaApp) {
        ////    this.iframe.nativeElement.style.width = "calc(44.5mm + " + this.iframe.nativeElement.contentWindow.document.body.scrollWidth + "px)";
        ////    this.iframe.nativeElement.style.height = "calc(28.25mm + " + this.iframe.nativeElement.contentWindow.document.body.scrollHeight + "px)";
        ////}
        ////this.iframe.nativeElement.style.width = "calc(44.5mm + " + this.iframe.nativeElement.contentWindow.document.body.scrollWidth + "px)";
        ////console.log(this.iframe.nativeElement.contentWindow.document.body.scrollWidth);
        ////this.iframe.nativeElement.style.height = "calc(28.25mm + " + this.iframe.nativeElement.contentWindow.document.body.scrollHeight + "px)";
        ////console.log(this.iframe.nativeElement.contentWindow.document.body.scrollHeight);
    };
    DialogComponent.prototype.onkeydown = function (event) {
        if (event.code === 'Escape') {
            this.onClose();
        }
    };
    Object.defineProperty(DialogComponent.prototype, "isMobile", {
        get: function () {
            var browser = new Browser();
            return browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogComponent.prototype, "isIframeScrolling", {
        get: function () {
            return this.isMobile ? 'no' : 'yes';
        },
        enumerable: true,
        configurable: true
    });
    return DialogComponent;
}());
export { DialogComponent };
