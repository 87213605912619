import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from '../../common/global';
import { OverviewDisplayModeEnum } from '../../model/enum';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { ReportDialogService } from '../../shared-components/report-dialog/report-dialog.service';
import { CompanyPayrollDataService } from './company-payroll-data.service';
var PayrollFilterType;
(function (PayrollFilterType) {
    PayrollFilterType[PayrollFilterType["SalaryCycle"] = 1] = "SalaryCycle";
    PayrollFilterType[PayrollFilterType["Template"] = 2] = "Template";
    PayrollFilterType[PayrollFilterType["Department"] = 3] = "Department";
})(PayrollFilterType || (PayrollFilterType = {}));
var CompanyPayrollDataComponent = /** @class */ (function () {
    function CompanyPayrollDataComponent(sessionService, translateService, staticDataService, changeDetectorRef, reportDialogService, companyPayrollDataService) {
        var _this = this;
        this.sessionService = sessionService;
        this.translateService = translateService;
        this.staticDataService = staticDataService;
        this.changeDetectorRef = changeDetectorRef;
        this.reportDialogService = reportDialogService;
        this.companyPayrollDataService = companyPayrollDataService;
        this.pageSize = 25;
        this.skip = 0;
        this.selectedRows = [];
        this.gridInit = false;
        this.listClassExcluded = ['Footer', '10'];
        this.numericOptions = {
            format: 'n2',
            decimals: 2,
            step: 0,
            min: null,
            spinners: false
        };
        this.gridData = [];
        this.ngUnsubscribe = new Subject();
        this.listColumnCheck = [];
        this.rowTotal = {};
        this.gridFilterData = this.gridData;
        this.companyPayrollDataService.showReportShortcutChange
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && _this.reportDialogService.isVisibleReportIcon) {
                _this.reportDialogService.onShowReportsEventClick();
            }
        });
        this.companyPayrollDataService.componentStateChange
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            _this.changeDetectorRef.markForCheck();
        });
        this.companyPayrollDataService.allSalaryTypes.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (salaryTypes) {
            if (salaryTypes && salaryTypes.length > 0) {
                _this.overviewMaxIndex = 1;
                var onverviewIndexList_1 = [];
                _this.companyPayrollDataService.salaryTypes.forEach(function (salaryType) {
                    var unitType;
                    if (Global.UNIT_TYPES) {
                        unitType = Global.UNIT_TYPES.find(function (ut) { return ut.Id === salaryType.UnitTypeId; });
                    }
                    salaryType.unitTypeTitle = unitType ? unitType.Name : '';
                    _this.companyPayrollDataService.assignSalaryTypeText(salaryType, salaryTypes);
                    onverviewIndexList_1.push(salaryType.OverviewIndex);
                });
                var flag = 1;
                var _loop_1 = function (i) {
                    if (!onverviewIndexList_1.find(function (index) { return index === i; })) {
                        flag = i;
                        return "break";
                    }
                    flag += 1;
                };
                for (var i = 1; i <= 7; i++) {
                    var state_1 = _loop_1(i);
                    if (state_1 === "break")
                        break;
                }
                _this.overviewMaxIndex = flag;
                _this.companyPayrollDataService.getData();
            }
        }, function () {
            _this.closeOverlay();
        });
        this.companyPayrollDataService.gridData.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.gridData = data;
            _this.pageChange({ skip: 0, take: _this.pageSize });
            _this.closeOverlay();
            setTimeout(function () {
                _this.changeDetectorRef.markForCheck();
            });
        }, function () {
            _this.closeOverlay();
        });
    }
    Object.defineProperty(CompanyPayrollDataComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyPayrollDataComponent.prototype, "pageable", {
        get: function () {
            return this.gridView && this.gridView.total <= this.pageSize ? false : true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyPayrollDataComponent.prototype, "hasAddNewColumn", {
        get: function () {
            return this.overviewMaxIndex <= 7;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyPayrollDataComponent.prototype, "showDepartment", {
        get: function () {
            return this.gridData && this.gridData.some(function (d) { return !!d.DepartmentName; });
        },
        enumerable: true,
        configurable: true
    });
    CompanyPayrollDataComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.companyPayrollDataService.isWattingLoading = true;
        this.translateFilterDataSource();
        if (this.isMobile) {
            this.sessionService.isShowHugeFeaturesAlert = true;
        }
        setTimeout(function () {
            _this.gridInit = true;
            _this.changeDetectorRef.markForCheck();
        });
    };
    CompanyPayrollDataComponent.prototype.closeOverlay = function () {
        var _this = this;
        this.companyPayrollDataService
            .closeOverlay()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function () {
            _this.changeDetectorRef.markForCheck();
        });
    };
    CompanyPayrollDataComponent.prototype.translateFilterDataSource = function () {
        this.translateService
            .get(['CompanyPayrollData.Salary Cycle', 'CompanyPayrollData.Template', 'CompanyPayrollData.Department'])
            .subscribe(function (translations) {
            var dataSource = [];
            dataSource.push({ Id: PayrollFilterType.SalaryCycle, Name: translations['CompanyPayrollData.Salary Cycle'] });
            dataSource.push({ Id: PayrollFilterType.Template, Name: translations['CompanyPayrollData.Template'] });
            dataSource.push({ Id: PayrollFilterType.Department, Name: translations['CompanyPayrollData.Department'] });
        });
    };
    CompanyPayrollDataComponent.prototype.ngOnDestroy = function () {
        this.companyPayrollDataService.ngOnDestroy();
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    CompanyPayrollDataComponent.prototype.trackIndex = function (index) {
        return index;
    };
    CompanyPayrollDataComponent.prototype.UnitsColumnVisible = function (salaryType) {
        return (salaryType &&
            (!salaryType.OverviewDisplayMode ||
                salaryType.OverviewDisplayMode === OverviewDisplayModeEnum.UnitsAndAmount ||
                salaryType.OverviewDisplayMode === OverviewDisplayModeEnum.UnitsOnly));
    };
    CompanyPayrollDataComponent.prototype.AmountColumnVisible = function (salaryType) {
        return (salaryType &&
            (!salaryType.OverviewDisplayMode ||
                salaryType.OverviewDisplayMode === OverviewDisplayModeEnum.UnitsAndAmount ||
                salaryType.OverviewDisplayMode === OverviewDisplayModeEnum.AmountOnly));
    };
    CompanyPayrollDataComponent.prototype.PerUnitColumnVisible = function (salaryType) {
        return (salaryType &&
            (!salaryType.OverviewDisplayMode || salaryType.OverviewDisplayMode === OverviewDisplayModeEnum.UnitsAndAmount));
    };
    CompanyPayrollDataComponent.prototype.onKeyDown = function (event) {
        var _this = this;
        if ((event.key === 'Tab' || event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Escape') &&
            this.selectedCell) {
            var cell = this.findNextCell(this.selectedCell, event);
            if (cell &&
                (!this.gridView || !this.gridView.data || (cell.rowIndex >= 0 && cell.rowIndex < this.gridView.data.length))) {
                var dataItem_1 = this.gridView && this.gridView.data ? this.gridView.data[cell.rowIndex] : undefined;
                Object.assign(this.companyPayrollDataService.original, dataItem_1);
                this.selectedRows = dataItem_1 ? [dataItem_1.UserEmploymentId] : [];
                if (event.key === 'Escape') {
                    setTimeout(function () {
                        _this.kendoGrid.editCell(_this.selectedCell.rowIndex, _this.selectedCell.columnIndex, _this.companyPayrollDataService.createFormGroup(dataItem_1));
                        _this.kendoGrid.closeCell();
                    });
                }
                else {
                    this.kendoGrid.editCell(cell.rowIndex, cell.columnIndex, this.companyPayrollDataService.createFormGroup(dataItem_1));
                    this.kendoGrid.focusCell(cell.rowIndex + 2, cell.columnIndex);
                    setTimeout(function () {
                        var element = document.querySelector('.k-state-focused input');
                        if (element) {
                            element.focus();
                        }
                    });
                }
                this.companyPayrollDataService.formGroup = this.companyPayrollDataService.createFormGroup(dataItem_1);
            }
            event.preventDefault();
            event.stopPropagation();
        }
    };
    CompanyPayrollDataComponent.prototype.findNextCell = function (cell, event) {
        var _this = this;
        if (!this.listColumnCheck || this.listColumnCheck.length === 0) {
            this.listColumnCheck = [];
            this.kendoGrid.columnList.columns.forEach(function (column) {
                if (!column.children) {
                    _this.listColumnCheck.push(column);
                }
                else {
                    column.children.forEach(function (childColumn) {
                        if (column !== childColumn) {
                            _this.listColumnCheck.push(childColumn);
                        }
                    });
                }
            });
        }
        if (event.key === 'ArrowUp') {
            if (cell.rowIndex > 0) {
                cell.rowIndex = cell.rowIndex - 1;
            }
        }
        else if (event.key === 'ArrowDown') {
            if (cell.rowIndex < this.gridView.data.length - 1) {
                cell.rowIndex = cell.rowIndex + 1;
            }
        }
        else {
            if (!event.shiftKey) {
                if (cell.columnIndex >= 2 && cell.columnIndex < this.listColumnCheck.length - 2) {
                    for (var i = cell.columnIndex + 1; i < this.listColumnCheck.length - 1; i++) {
                        if (this.listColumnCheck[i] && this.listColumnCheck[i].editable) {
                            cell.columnIndex = i;
                            break;
                        }
                    }
                }
                else {
                    cell.columnIndex = 2;
                }
            }
            else {
                if (cell.columnIndex > 2 && cell.columnIndex < this.listColumnCheck.length - 1) {
                    for (var i = cell.columnIndex - 1; i >= 2; i--) {
                        if (this.listColumnCheck[i] && this.listColumnCheck[i].editable) {
                            cell.columnIndex = i;
                            break;
                        }
                    }
                }
                else {
                    cell.columnIndex = this.listColumnCheck.length - 2;
                }
            }
        }
        return cell;
    };
    CompanyPayrollDataComponent.prototype.onCellClick = function (e) {
        this.selectedCell = e;
        this.selectedRows = e.dataItem ? [e.dataItem.UserEmploymentId] : [];
        if (!this.sessionService.role.IsReadOnly) {
            Object.assign(this.companyPayrollDataService.original, e.dataItem);
            this.companyPayrollDataService.formGroup = this.companyPayrollDataService.createFormGroup(e.dataItem);
            e.sender.editCell(e.rowIndex, e.columnIndex, this.companyPayrollDataService.createFormGroup(e.dataItem));
        }
        setTimeout(function () {
            var element = document.querySelector('.k-state-focused input');
            if (element) {
                element.focus();
            }
        });
    };
    CompanyPayrollDataComponent.prototype.onCellClose = function (e) {
        if (this.isColumnDataChanged(e)) {
            this.companyPayrollDataService.saveCurrent(e.dataItem, e.column ? e.column.field : undefined);
        }
        var obj = this.addRowTotal();
        this.rowTotal = this.CalculateTotal(obj);
    };
    CompanyPayrollDataComponent.prototype.pageChange = function (event) {
        this.skip = event.skip;
        this.loadView();
    };
    CompanyPayrollDataComponent.prototype.onSalaryTypeConfigrationClicked = function (salaryType) {
        var correctDatasource = JSON.parse(JSON.stringify(this.companyPayrollDataService.availableSalaryTypes));
        var dataSource = correctDatasource && correctDatasource.length > 0 ? correctDatasource : [];
        if (!salaryType) {
            dataSource = dataSource.filter(function (s) { return !s.OverviewIndex; });
        }
        else {
            var indexSalaryType = dataSource.find(function (model) { return model.Id === salaryType.Id; });
            if (indexSalaryType) {
                dataSource.splice(dataSource.indexOf(indexSalaryType), 1);
            }
            dataSource.unshift(salaryType);
        }
        this.configurationSalaryTypeDataSource = dataSource;
        this.selectedSalaryTypeForConfiguration = salaryType;
        this.showSalaryTypeConfiguration = true;
    };
    CompanyPayrollDataComponent.prototype.loadView = function () {
        // setTimeout(() => {
        //   try {
        //     this.elementdown1 = this.kendoGrid.wrapper.nativeElement
        //       .getElementsByClassName('k-grid-aria-root')[0]
        //       .getElementsByClassName('k-grid-container')[0]
        //       .getElementsByClassName('k-grid-content')[0];
        //     this.elementdown2 = this.kendoGrid.wrapper.nativeElement
        //       .getElementsByClassName('k-grid-aria-root')[0]
        //       .getElementsByClassName('k-grid-container')[0]
        //       .getElementsByClassName('k-grid-content-locked')[0];
        //   } catch (e) {
        //   }
        //   if (this.elementdown2) {
        //     this.elementdown2.addEventListener('scroll', (): void => {
        //       setTimeout(() => {
        //         this.elementdown1.scrollTop = this.elementdown2.scrollTop;
        //       }, 100);
        //     });
        //   }
        // }, 1000);
        this.gridFilterData = filterBy(this.gridData, this.filter);
        this.gridView = {
            data: this.gridFilterData.slice(this.skip, this.skip + this.pageSize),
            total: this.gridFilterData.length
        };
        var obj = this.addRowTotal();
        this.rowTotal = this.CalculateTotal(obj);
    };
    CompanyPayrollDataComponent.prototype.CalculateTotal = function (result) {
        this.gridFilterData.forEach(function (item) {
            result.SalaryRecord1Amount = item.SalaryRecord1Amount
                ? item.SalaryRecord1Amount + result.SalaryRecord1Amount
                : result.SalaryRecord1Amount;
            result.SalaryRecord1Units = item.SalaryRecord1Units
                ? item.SalaryRecord1Units + result.SalaryRecord1Units
                : result.SalaryRecord1Units;
            result.SalaryRecord2Amount = item.SalaryRecord2Amount
                ? item.SalaryRecord2Amount + result.SalaryRecord2Amount
                : result.SalaryRecord2Amount;
            result.SalaryRecord2Units = item.SalaryRecord2Units
                ? item.SalaryRecord2Units + result.SalaryRecord2Units
                : result.SalaryRecord2Units;
            result.SalaryRecord3Amount = item.SalaryRecord3Amount
                ? item.SalaryRecord3Amount + result.SalaryRecord3Amount
                : result.SalaryRecord3Amount;
            result.SalaryRecord3Units = item.SalaryRecord3Units
                ? item.SalaryRecord3Units + result.SalaryRecord3Units
                : result.SalaryRecord3Units;
            result.SalaryRecord4Amount = item.SalaryRecord4Amount
                ? item.SalaryRecord4Amount + result.SalaryRecord4Amount
                : result.SalaryRecord4Amount;
            result.SalaryRecord4Units = item.SalaryRecord4Units
                ? item.SalaryRecord4Units + result.SalaryRecord4Units
                : result.SalaryRecord4Units;
            result.SalaryRecord5Amount = item.SalaryRecord5Amount
                ? item.SalaryRecord5Amount + result.SalaryRecord5Amount
                : result.SalaryRecord5Amount;
            result.SalaryRecord5Units = item.SalaryRecord5Units
                ? item.SalaryRecord5Units + result.SalaryRecord5Units
                : result.SalaryRecord5Units;
            result.SalaryRecord6Amount = item.SalaryRecord6Amount
                ? item.SalaryRecord6Amount + result.SalaryRecord6Amount
                : result.SalaryRecord6Amount;
            result.SalaryRecord6Units = item.SalaryRecord6Units
                ? item.SalaryRecord6Units + result.SalaryRecord6Units
                : result.SalaryRecord6Units;
            result.SalaryRecord7Amount = item.SalaryRecord7Amount
                ? item.SalaryRecord7Amount + result.SalaryRecord7Amount
                : result.SalaryRecord7Amount;
            result.SalaryRecord7Units = item.SalaryRecord7Units
                ? item.SalaryRecord7Units + result.SalaryRecord7Units
                : result.SalaryRecord7Units;
        });
        result.SalaryRecord1Amount = parseFloat((Math.round(result.SalaryRecord1Amount * 100) / 100).toFixed(2));
        result.SalaryRecord1Units = parseFloat((Math.round(result.SalaryRecord1Units * 100) / 100).toFixed(2));
        result.SalaryRecord2Amount = parseFloat((Math.round(result.SalaryRecord2Amount * 100) / 100).toFixed(2));
        result.SalaryRecord2Units = parseFloat((Math.round(result.SalaryRecord2Units * 100) / 100).toFixed(2));
        result.SalaryRecord3Amount = parseFloat((Math.round(result.SalaryRecord3Amount * 100) / 100).toFixed(2));
        result.SalaryRecord3Units = parseFloat((Math.round(result.SalaryRecord3Units * 100) / 100).toFixed(2));
        result.SalaryRecord4Amount = parseFloat((Math.round(result.SalaryRecord4Amount * 100) / 100).toFixed(2));
        result.SalaryRecord4Units = parseFloat((Math.round(result.SalaryRecord4Units * 100) / 100).toFixed(2));
        result.SalaryRecord5Amount = parseFloat((Math.round(result.SalaryRecord5Amount * 100) / 100).toFixed(2));
        result.SalaryRecord5Units = parseFloat((Math.round(result.SalaryRecord5Units * 100) / 100).toFixed(2));
        result.SalaryRecord6Amount = parseFloat((Math.round(result.SalaryRecord6Amount * 100) / 100).toFixed(2));
        result.SalaryRecord6Units = parseFloat((Math.round(result.SalaryRecord6Units * 100) / 100).toFixed(2));
        result.SalaryRecord7Amount = parseFloat((Math.round(result.SalaryRecord7Amount * 100) / 100).toFixed(2));
        result.SalaryRecord7Units = parseFloat((Math.round(result.SalaryRecord7Units * 100) / 100).toFixed(2));
        return result;
    };
    CompanyPayrollDataComponent.prototype.addRowTotal = function () {
        var obj = {
            DepartmentName: 'Total',
            SalaryRecord1Amount: 0,
            SalaryRecord1Id: undefined,
            SalaryRecord1PerUnit: 0,
            SalaryRecord1Units: 0,
            SalaryRecord2Amount: 0,
            SalaryRecord2Id: undefined,
            SalaryRecord2PerUnit: 0,
            SalaryRecord2Units: 0,
            SalaryRecord3Amount: 0,
            SalaryRecord3Id: undefined,
            SalaryRecord3PerUnit: 0,
            SalaryRecord3Units: 0,
            SalaryRecord4Amount: 0,
            SalaryRecord4Id: undefined,
            SalaryRecord4PerUnit: 0,
            SalaryRecord4Units: 0,
            SalaryRecord5Amount: 0,
            SalaryRecord5Id: undefined,
            SalaryRecord5PerUnit: 0,
            SalaryRecord5Units: 0,
            SalaryRecord6Amount: 0,
            SalaryRecord6Id: undefined,
            SalaryRecord6PerUnit: 0,
            SalaryRecord6Units: 0,
            SalaryRecord7Amount: 0,
            SalaryRecord7Id: undefined,
            SalaryRecord7PerUnit: 0,
            SalaryRecord7Units: 0
        };
        return obj;
    };
    CompanyPayrollDataComponent.prototype.isColumnDataChanged = function (args) {
        var dataChanged;
        var column = args.column, dataItem = args.dataItem, formGroup = args.formGroup;
        if (dataItem && formGroup && formGroup.value && column && column.field) {
            dataChanged =
                ((dataItem[column.field] === null || dataItem[column.field] === undefined) &&
                    formGroup.value[column.field] !== null &&
                    formGroup.value[column.field] !== undefined) ||
                    (dataItem[column.field] !== null &&
                        dataItem[column.field] !== undefined &&
                        formGroup.value.hasOwnProperty(column.field) &&
                        (formGroup.value[column.field] === null || formGroup.value[column.field] === undefined)) ||
                    (dataItem[column.field] !== null &&
                        dataItem[column.field] !== undefined &&
                        formGroup.value[column.field] !== null &&
                        formGroup.value[column.field] !== undefined &&
                        dataItem[column.field] !== formGroup.value[column.field]);
        }
        return dataChanged;
    };
    CompanyPayrollDataComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        this.gridFilterData = filterBy(this.gridData, this.filter);
        this.gridView = {
            data: this.gridFilterData.slice(this.skip, this.skip + this.pageSize),
            total: this.gridFilterData.length
        };
        var obj = this.addRowTotal();
        this.rowTotal = this.CalculateTotal(obj);
    };
    return CompanyPayrollDataComponent;
}());
export { CompanyPayrollDataComponent };
