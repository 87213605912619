/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./employee-list/employee-list.component.ngfactory";
import * as i2 from "./employee-list/employee-list.component";
import * as i3 from "../services/session/session.service";
import * as i4 from "../services/employee.service";
import * as i5 from "./employee-tab/employee-tabs.component.ngfactory";
import * as i6 from "./employee-tab/employee-tabs.component";
import * as i7 from "@uirouter/core";
import * as i8 from "../services/broadcast.service";
import * as i9 from "../services/api/static-data.service";
import * as i10 from "../common/router-state.service";
import * as i11 from "../services/api/data.service";
import * as i12 from "../shared-components/report-dialog/report-dialog.service";
import * as i13 from "@angular/common";
import * as i14 from "./employee.component";
var styles_EmployeeComponent = [];
var RenderType_EmployeeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmployeeComponent, data: {} });
export { RenderType_EmployeeComponent as RenderType_EmployeeComponent };
export function View_EmployeeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-employee-list", [], null, null, null, i1.View_EmployeeListComponent_0, i1.RenderType_EmployeeListComponent)), i0.ɵdid(2, 49152, null, 0, i2.EmployeeListComponent, [i3.SessionService, i4.EmployeeService], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 3, "app-employee-tabs", [], null, null, null, i5.View_EmployeeTabsComponent_0, i5.RenderType_EmployeeTabsComponent)), i0.ɵdid(4, 245760, null, 0, i6.EmployeeTabsComponent, [i4.EmployeeService, i3.SessionService, i7.TransitionService, i8.BroadcastService, i9.StaticDataService, i10.RouterStateService, i11.DataService, i12.ReportDialogService], { employee: [0, "employee"], currentUserEmployment: [1, "currentUserEmployment"] }, null), i0.ɵpid(131072, i13.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i13.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.employeeService.employee)); var currVal_1 = i0.ɵunv(_v, 4, 1, i0.ɵnov(_v, 6).transform(_co.employeeService.employment)); _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_EmployeeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-employee", [], null, null, null, View_EmployeeComponent_0, RenderType_EmployeeComponent)), i0.ɵdid(1, 49152, null, 0, i14.EmployeeComponent, [i4.EmployeeService], null, null)], null, null); }
var EmployeeComponentNgFactory = i0.ɵccf("app-employee", i14.EmployeeComponent, View_EmployeeComponent_Host_0, {}, {}, []);
export { EmployeeComponentNgFactory as EmployeeComponentNgFactory };
