/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../custom-controls/buttons/action-button.component.ngfactory";
import * as i3 from "../../custom-controls/buttons/action-button.component";
import * as i4 from "@angular/common";
import * as i5 from "../../custom-controls/buttons/edit-button.component.ngfactory";
import * as i6 from "../../custom-controls/buttons/edit-button.component";
import * as i7 from "../../services/session/session.service";
import * as i8 from "./menu-button.component";
import * as i9 from "../report-dialog/report-dialog.service";
import * as i10 from "../../services/employee.service";
var styles_MenuButtonComponent = [];
var RenderType_MenuButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MenuButtonComponent, data: {} });
export { RenderType_MenuButtonComponent as RenderType_MenuButtonComponent };
function View_MenuButtonComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "li", [["class", "Dropdown-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reportDialogService.onShowReportsEventClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Employee.Reports")); _ck(_v, 2, 0, currVal_0); }); }
function View_MenuButtonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "Dropdown-container"]], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (_co.onkeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-action-button", [["buttonClass", "Action-button Action-buttonMore"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleMenu($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ActionButtonComponent_0, i2.RenderType_ActionButtonComponent)), i0.ɵdid(2, 49152, null, 0, i3.ActionButtonComponent, [], { buttonClass: [0, "buttonClass"], label: [1, "label"], icon: [2, "icon"], tooltip: [3, "tooltip"] }, { click: "click" }), (_l()(), i0.ɵeld(3, 0, null, null, 7, "div", [["class", "Dropdown Dropdown--bottom helpDropDownDialog"]], null, null, null, null, null)), i0.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(5, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(6, { "is-active": 0 }), (_l()(), i0.ɵeld(7, 0, null, null, 3, "ul", [["role", "menu"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuButtonComponent_2)), i0.ɵdid(9, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Action-button Action-buttonMore"; var currVal_1 = _co.menuLabel; var currVal_2 = _co.menuIcon; var currVal_3 = _co.menuTooltip; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = "Dropdown Dropdown--bottom helpDropDownDialog"; var currVal_5 = _ck(_v, 6, 0, _co.menuOpen); _ck(_v, 5, 0, currVal_4, currVal_5); var currVal_6 = ((_co.isMobile && _co.reportDialogService.isVisibleReportIcon) && _co.isShowReportOption); _ck(_v, 9, 0, currVal_6); }, null); }
function View_MenuButtonComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-action-button", [], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onMenuClickEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ActionButtonComponent_0, i2.RenderType_ActionButtonComponent)), i0.ɵdid(1, 49152, null, 0, i3.ActionButtonComponent, [], { buttonClass: [0, "buttonClass"], label: [1, "label"], icon: [2, "icon"], tooltip: [3, "tooltip"], disabled: [4, "disabled"] }, { click: "click" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.menuButtonClass; var currVal_2 = _co.menuLabel; var currVal_3 = _co.menuIcon; var currVal_4 = _co.menuTooltip; var currVal_5 = _co.menuDisabled; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuButtonId; _ck(_v, 0, 0, currVal_0); }); }
function View_MenuButtonComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuButtonComponent_4)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuCondition; _ck(_v, 2, 0, currVal_0); }, null); }
function View_MenuButtonComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-edit-button", [], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onEditModeChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_EditButtonComponent_0, i5.RenderType_EditButtonComponent)), i0.ɵdid(2, 49152, null, 0, i6.EditButtonComponent, [i7.SessionService], { editMode: [0, "editMode"], module: [1, "module"], isVisible: [2, "isVisible"] }, { change: "change" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editMode; var currVal_1 = "employee"; var currVal_2 = _co.menuCondition; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_MenuButtonComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-edit-button", [], null, [[null, "change"], [null, "errorChangeEditMode"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onEditModeChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("errorChangeEditMode" === en)) {
        var pd_1 = (_co.onErrorChangeEditMode() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_EditButtonComponent_0, i5.RenderType_EditButtonComponent)), i0.ɵdid(2, 49152, null, 0, i6.EditButtonComponent, [i7.SessionService], { editMode: [0, "editMode"], module: [1, "module"], isVisible: [2, "isVisible"], allowCompanyPreference: [3, "allowCompanyPreference"], isCanChangeEditMode: [4, "isCanChangeEditMode"] }, { change: "change", errorChangeEditMode: "errorChangeEditMode" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editMode; var currVal_1 = "employee"; var currVal_2 = _co.menuCondition; var currVal_3 = _co.allowCompanyPreference; var currVal_4 = _co.isCanChangeEditMode; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_MenuButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuButtonComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuButtonComponent_3)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuButtonComponent_5)), i0.ɵdid(5, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuButtonComponent_6)), i0.ɵdid(7, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.multiMenuItems; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.actionButtonMenu; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.editButtonMenu; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.editButtonMenuSS; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_MenuButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-menu-button", [], null, [["document", "click"], ["document", "dblclick"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).closeMenu() !== false);
        ad = (pd_0 && ad);
    } if (("document:dblclick" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).closeMenuDbl() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_MenuButtonComponent_0, RenderType_MenuButtonComponent)), i0.ɵdid(1, 245760, null, 1, i8.MenuButtonComponent, [i9.ReportDialogService, i7.SessionService, i10.EmployeeService], null, null), i0.ɵqud(603979776, 1, { listItems: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuButtonComponentNgFactory = i0.ɵccf("app-menu-button", i8.MenuButtonComponent, View_MenuButtonComponent_Host_0, { singleMenuItem: "singleMenuItem", multiMenuItems: "multiMenuItems", actionButtonMenu: "actionButtonMenu", editButtonMenu: "editButtonMenu", editButtonMenuSS: "editButtonMenuSS", editMode: "editMode", allowCompanyPreference: "allowCompanyPreference", isCanChangeEditMode: "isCanChangeEditMode", menuCondition: "menuCondition", menuDisabled: "menuDisabled", menuIcon: "menuIcon", menuLabel: "menuLabel", menuTooltip: "menuTooltip", menuButtonClass: "menuButtonClass", menuButtonId: "menuButtonId" }, { menuClick: "menuClick", menuChangeClick: "menuChangeClick", errorChangeEditMode: "errorChangeEditMode" }, ["app-menu-button-item"]);
export { MenuButtonComponentNgFactory as MenuButtonComponentNgFactory };
