import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { IntlModule } from '@progress/kendo-angular-intl';

import { CustomControlsModule } from '../custom-controls/custom-controls.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';

import { DepartmentFilterComponent } from './employee-list/controls/department-filter.component';
import { SalaryCycleFilterComponent } from './employee-list/controls/salary-cycle-filter.component';
import { SortByComponent } from './employee-list/controls/sort-by.component';
import { TemplateFilterComponent } from './employee-list/controls/template-filter.component';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { EmployeeTabsComponent } from './employee-tab/employee-tabs.component';
import { EmployeeHiringStatusComponent } from './employee-tab/hiring-status/employee-hiring-status.component';
import { PayrollDataGridComponent } from './employee-tab/payroll/controls/payroll-data-grid.component';
import { PayrollIssuesComponent } from './employee-tab/payroll/controls/payroll-issues.component';
import { PayrollPreviewComponent } from './employee-tab/payroll/controls/payroll-preview.component';
import { NegativeDefaultDialogComponent } from './employee-tab/payroll/dialogs/negative-default-dialog.component';
import { EmployeePayrollDataMobileComponent } from './employee-tab/payroll/employee-payroll-data-mobile.component';
import { EmployeePayrollDataComponent } from './employee-tab/payroll/employee-payroll-data.component';
import { PayslipMobileComponent } from './employee-tab/payslip/payslip-mobile.component';
import { PayslipComponent } from './employee-tab/payslip/payslip.component';
import { EmployeeComponent } from './employee.component';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule, IntlModule, CustomControlsModule, SharedComponentsModule],
  declarations: [
    EmployeeComponent,
    DepartmentFilterComponent,
    SalaryCycleFilterComponent,
    SortByComponent,
    TemplateFilterComponent,
    EmployeeListComponent,
    EmployeeHiringStatusComponent,
    PayrollDataGridComponent,
    PayrollPreviewComponent,
    NegativeDefaultDialogComponent,
    EmployeePayrollDataComponent,
    EmployeePayrollDataMobileComponent,
    PayslipComponent,
    PayslipMobileComponent,
    EmployeeTabsComponent,
    PayrollIssuesComponent
  ],
  exports: [EmployeeComponent],
  entryComponents: [EmployeeComponent]
})
export class EmployeeModule {}
