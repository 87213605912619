import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File, FileEntry } from '@ionic-native/file/ngx';
import { TranslateService } from '@ngx-translate/core';
import { saveAs as SaveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Base64 } from '../common/base64';
import { ModalService } from './modal.service';
import { SessionService } from './session/session.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService implements OnDestroy, OnInit {
  constructor(
    private translateService: TranslateService,
    private modalService: ModalService,
    private sessionService: SessionService,
    private file: File,
    private fileOpener: FileOpener
  ) {}

  public ngOnInit(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e: string) =>
      this.translateText()
    );
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private get folderPath(): string {
    return /*this.sessionService.browser.iOSMobileDevice ? this.file.syncedDataDirectory : */ this.file.dataDirectory;
  }

  private openFileErrorMessage: string;
  private translateText(): void {
    this.translateService
      .get(['CompanyDataImport.TroubleOpenFile'])
      .subscribe((translations: { [key: string]: string }) => {
        this.openFileErrorMessage = translations['CompanyDataImport.TroubleOpenFile'];
      });
  }

  public download(defaultFileName: string, responseData: any): void {
    if (!responseData) {
      return;
    }

    try {
      const isFileSaverSupported: boolean = !!new Blob();
    } catch (e) {
      console.error("Browser doesn't support FileSaver:");
      console.error(e);
    }

    // const fileName: string = this.getFileNameFromContentDisposition(defaultFileName, responseData).replace(/\s/g, '');
    let fileName: string = this.getFileNameFromContentDisposition(defaultFileName, responseData);
    if (this.sessionService.browser.isHybridApp) {
      if (fileName.indexOf('.pdf') < 0) {
        // Decode filename for mobile device app
        if (fileName.indexOf('?') >= 0 || fileName.indexOf('=') >= 0) {
          fileName = fileName.replace('=?utf-8?B?', '').replace('?=', '');
        } else {
          fileName = fileName.replace('utf-8B', '');
        }
        const length = fileName.length % 4;
        if (length > 0) {
          for (let i = 4; i > length; i--) {
            fileName += '=';
          }
        }
        fileName = Base64.decode(fileName);
      }
    } else if (this.sessionService.browser.isMobile) {
      fileName = fileName.replace(/\s/g, '');
    }

    // let byteArray: Uint8Array = new Uint8Array(responseData.body ? responseData.body : responseData.data);
    const data: any = responseData.body ? responseData.body : responseData.data ? responseData.data : responseData;
    const byteArray: Uint8Array = new Uint8Array(data);
    const blob: Blob = new Blob([byteArray], { type: 'application/octet-stream' });

    if (!this.sessionService.browser.isHybridApp) {
      // Use File Saver
      SaveAs(blob, fileName);
    } else {
      // Use Cordova File plugin to download into device folder.
      setTimeout(() => {
        this.createFile(fileName, data);
      });
    }
  }

  private createFile(fileName: string, arrayBuffer: any): void {
    if (!this.folderPath || !fileName) {
      return;
    }

    this.file
      .createFile(this.folderPath, fileName, true)
      .then((fileEntry: FileEntry) => {
        setTimeout(() => {
          ////this.modalService.alert("", fileEntry.toInternalURL());
          this.file
            .writeFile(this.folderPath, fileName, arrayBuffer, { replace: true })
            .then(() => {
              this.openFile(fileName);
            })
            .catch((err: any) => {
              this.modalService.alert('', err.message);
            });
        });
      })
      .catch((err: any) => {
        this.modalService.alert('', err.message);
      });
  }

  private openFile(fileName: string): void {
    setTimeout(() => {
      ////this.modalService.alert("", "Try to open file");
      this.file
        .checkFile(this.folderPath, fileName)
        .then((fileSystem: any) => {
          if (fileSystem) {
            const fileLocation: string = this.folderPath + fileName;
            this.fileOpener
              .open(fileLocation, 'application/' + this.getFileExtension(fileName))
              .then()
              .catch((err: any) => {
                this.modalService.alert('', this.openFileErrorMessage + '<br />' + this.folderPath);
              });
          }
        })
        .catch((err: any) => {
          this.modalService.alert('Check file error ', err.message);
        });
    });
  }

  private getFileNameFromContentDisposition(defaultFileName: string, responseData: any): string {
    let contentDisposition: string;
    if (responseData.headers) {
      contentDisposition = responseData.headers.get('Content-Disposition');
    } else {
      try {
        contentDisposition = responseData.headers('Content-Disposition');
      } catch (e) {
        return defaultFileName.replace(/[^a-zA-Z0-9.\- ,]/g, '');
      }
    }

    if (!contentDisposition) {
      return defaultFileName.replace(/[^a-zA-Z0-9.\- ,]/g, '');
    }

    const filenameOffset: number = contentDisposition.lastIndexOf('filename=');
    const startOffset: number = filenameOffset + 'filename='.length;
    const result: string = contentDisposition.substring(startOffset);
    return result.replace(/[^a-zA-Z0-9.\- ,]/g, '');
  }

  private getFileExtension(fileName: string): string {
    let extension: string = fileName.split('.').pop();
    extension = extension ? extension.toLowerCase() : extension;
    switch (extension) {
      case 'xlsx':
        return 'vnd.ms-excel';
      case 'csv':
        return 'vnd.ms-excel';
      default:
        return extension;
    }
  }
}
