import { ChangeDetectorRef } from '@angular/core';
import { EmployeeService } from 'src/app/services/employee.service';
import { DataService } from '../../../../services/api/data.service';
import { SessionService } from '../../../../services/session/session.service';
var SalaryBatchSalaryTypesComponent = /** @class */ (function () {
    function SalaryBatchSalaryTypesComponent(dataService, sessionService, employeeService, changeDetectorRef) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.employeeService = employeeService;
        this.changeDetectorRef = changeDetectorRef;
        this.salaryTypePerEmployee = [];
        this.salaryTypeTotalsValue = [];
    }
    Object.defineProperty(SalaryBatchSalaryTypesComponent.prototype, "SalaryBatchId", {
        get: function () {
            return this.salaryBatchIdValue;
        },
        set: function (value) {
            if (this.salaryBatchIdValue !== value) {
                this.salaryBatchIdValue = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchSalaryTypesComponent.prototype, "SalaryTypeTotals", {
        get: function () {
            return this.salaryTypeTotalsValue;
        },
        set: function (value) {
            if (value !== this.salaryTypeTotalsValue) {
                this.salaryTypeTotalsValue = value;
                this.salaryTypePerEmployee = [];
                if (value && value.length > 0 && this.SalaryBatchId) {
                    this.selectedSalaryTypeTotal = value[0];
                }
                this.componentUpdate();
            }
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchSalaryTypesComponent.prototype.componentUpdate = function () {
        var _this = this;
        setTimeout(function () {
            _this.changeDetectorRef.markForCheck();
        });
    };
    SalaryBatchSalaryTypesComponent.prototype.onSelectedSalaryTypeTotalChange = function (data) {
        var _this = this;
        this.dataService.SalaryBatches_GetSalaryTypeTotalsByEmployee(this.SalaryBatchId, data.SalaryTypeId).subscribe(function (salaryTypeSum) {
            _this.salaryTypePerEmployee = salaryTypeSum;
        }, function () { }, function () {
            _this.componentUpdate();
        });
    };
    SalaryBatchSalaryTypesComponent.prototype.onDoubleClick = function (data) {
        if (data && data.UserEmploymentId) {
            this.employeeService.selectEmployeeFromEmploymentId(data.UserEmploymentId);
            this.sessionService.NavigateTo('tabs.employee.payrolldata');
        }
    };
    return SalaryBatchSalaryTypesComponent;
}());
export { SalaryBatchSalaryTypesComponent };
