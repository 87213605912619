<app-module-check [moduleId]="4">
  <div class="company-balances-vacation">
    <div class="residual-area">
      <div class="residual-control">
        <div class="per-date-control">
          <app-date-picker
            [editMode]="true"
            [(value)]="service.effectivePer"
            [label]="'ResidualVacation.ChangeEffectivePerLabel' | translate"
            (valueChange)="service.Reload_Balances_GetExtraVacationHours()"
            [required]="true"
          >
          </app-date-picker>
        </div>

        <div class="check-control-area">
          <app-check-edit
            [editMode]="true"
            [(value)]="service.hideEmployeeNoRemainingBlance"
            (valueChange)="service.filterData()"
            [label]="'ResidualVacation.HideEmployeeNoRemainingBlanceLabel' | translate"
          >
          </app-check-edit>
        </div>
      </div>

      <div class="residual-button">
        <div>
          <app-button
            class="Button--card"
            (clickEvent)="MaxFieldHours('PayoutHours')"
            [isDisabled]="isReadOnly"
            [label]="'ResidualVacation.PayOutAllRemainingButtonLabel' | translate"
          >
          </app-button>
        </div>
      </div>
    </div>

    <!-- grid -->
    <app-grid
      [(data)]="gridDataSource"
      [editMode]="true"
      (saveChangesEvent)="onGridSave($event)"
      [(triggerUpdate)]="refreshData"
      (gridButtonClick)="onGridButtonClick($event)"
      [idColumn]="'UserEmploymentId'"
    >
      <app-grid-column
        field="FullName"
        [type]="'text'"
        [editable]="false"
        [title]="'ResidualVacation.Name' | translate"
        [viewClass]="'truncated'"
        [width]="170"
      ></app-grid-column>
      <app-grid-column
        *ngIf="!isSmallScreen"
        field="BasedOnTemplateName"
        [type]="'text'"
        [editable]="false"
        [title]="'ResidualVacation.Template' | translate"
        [viewClass]="'truncated'"
      ></app-grid-column>
      <app-grid-column
        *ngIf="!isSmallScreen"
        field="DepartmentName"
        [type]="'text'"
        [editable]="false"
        [title]="'CompanySalaryBatches.Department' | translate"
        [viewClass]="'truncated'"
      ></app-grid-column>
      <app-grid-column
        *ngIf="!isSmallScreen"
        field="ExternalReference"
        [type]="'text'"
        [editable]="false"
        [title]="'ResidualVacation.ExternalReferenceHeader' | translate"
        [viewClass]="'truncated'"
      ></app-grid-column>

      <!-- numeric -->
      <app-grid-column
        *ngIf="!isSmallScreen"
        field="BaseAmount"
        [type]="'numeric'"
        [editable]="false"
        [title]="'CompanySalaryTypes.BaseAmount' | translate"
        [viewClass]="'truncated'"
        [width]="115"
      ></app-grid-column>
      <app-grid-column
        *ngIf="!isSmallScreen"
        field="AmountEarned"
        [type]="'numeric'"
        [editable]="false"
        [title]="'ResidualVacation.GrossEarned' | translate"
        [viewClass]="'truncated'"
        [width]="115"
      ></app-grid-column>
      <app-grid-column
        field="AmountTotal"
        [type]="'numeric'"
        [editable]="false"
        [title]="'ResidualVacation.CurrentBalanceGross' | translate"
        [viewClass]="'truncated'"
        [width]="115"
      >
      </app-grid-column>

      <app-grid-column
        field="HoursEarned"
        [type]="'numeric'"
        [editable]="false"
        [title]="'ResidualVacation.HoursEarned' | translate"
        [viewClass]="'truncated'"
        [width]="115"
      ></app-grid-column>
      <app-grid-column
        field="HoursSpent"
        [type]="'numeric'"
        [editable]="false"
        [title]="'ResidualVacation.Spent' | translate"
        [viewClass]="'truncated'"
        [width]="115"
      ></app-grid-column>
      <app-grid-column
        field="HoursTotal"
        [type]="'numeric'"
        [editable]="false"
        [title]="'ResidualVacation.Remaining' | translate"
        [viewClass]="'truncated'"
        [width]="115"
      ></app-grid-column>
      <!-- numeric -->

      <!-- Edit -->
      <app-grid-column
        field="AdjustHours"
        [type]="'numeric'"
        [editable]="!isReadOnly"
        [title]="'ResidualVacation.Adjust' | translate"
        [width]="85"
        [headerTooltip]="'ResidualVacation.EarnTooltip' | translate"
        [numbericOption]="spinnerOption"
      ></app-grid-column>
      <app-grid-column
        field="SpendHours"
        [type]="'numeric'"
        [editable]="!isReadOnly"
        [title]="'ResidualVacation.Spend' | translate"
        [width]="125"
        [minResizableWidth]="110"
        [headerTooltip]="'ResidualVacation.SpendTooltip' | translate"
        [numbericOption]="spinnerOption"
        [addOnButtonEdit]="true"
        [addOnButtonEditTooltip]="'ResidualVacation.Max' | translate"
        [addOnButtonEditClass]="'numericControlButton'"
        [addOnButtonEditClassFormControl]="'numericControlButtonForm'"
        [addOnButtonEditAction]="'MaxSpend'"
        [addOnButtonEditDisabledFeild]="'disbaleMaxButton'"
        [addOnButtonEditIcon]="'fast-forward'"
        [addOnButtonEditLabel]="'Max'"
      ></app-grid-column>

      <app-grid-column
        field="PayoutHours"
        [type]="'numeric'"
        [editable]="!isReadOnly"
        [title]="'ResidualVacation.PayOut' | translate"
        [width]="125"
        [minResizableWidth]="110"
        [headerTooltip]="'ResidualVacation.PayOutTooltip' | translate"
        [numbericOption]="spinnerOption"
        [addOnButtonEdit]="true"
        [addOnButtonEditTooltip]="'ResidualVacation.Max' | translate"
        [addOnButtonEditClass]="'numericControlButton'"
        [addOnButtonEditClassFormControl]="'numericControlButtonForm'"
        [addOnButtonEditAction]="'MaxPayOuts'"
        [addOnButtonEditDisabledFeild]="'disbaleMaxButton'"
        [addOnButtonEditIcon]="'fast-forward'"
        [addOnButtonEditLabel]="'Max'"
      ></app-grid-column>

      <app-grid-column
        field="left"
        [type]="'numeric'"
        [editable]="false"
        [title]="'ResidualVacation.Left' | translate"
        [width]="85"
        [viewClass]="'left-value-display-cell'"
        [classField]="'errorLeftClass'"
      ></app-grid-column>
    </app-grid>
    <!-- grid -->

    <app-button
      class="Button--card"
      buttonClass="residual-button-submit"
      (clickEvent)="onSubmitButtonClick()"
      [isDisabled]="isDisableSubmitButton"
      [label]="'ResidualVacation.Execute' | translate"
    >
    </app-button>

    <app-dialog
      class="blue-dialog"
      [(visible)]="isShowDataInvalidAlert"
      [width]="'500'"
      [leadingText]="'ResidualVacation.NegativeLeftWarning' | translate"
    >
      <!-- missing title -->
      <app-dialog-action type="Ok"></app-dialog-action>
    </app-dialog>

    <app-dialog
      class="blue-dialog"
      [(visible)]="isShowAfterSaveSucess"
      [width]="'500'"
      [leadingText]="'CompanyDataImport.DataUploadSuccess_GeneralTab' | translate"
    >
      <!-- missing title -->
      <app-dialog-action type="Ok"></app-dialog-action>
    </app-dialog>

    <div class="Actions" *ngIf="!isMobile" [ngClass]="isIOSApp ? 'is-ios-app' : ''">
      <app-menu-button
        [menuIcon]="'Help'"
        [menuLabel]="'Help.Title'"
        [menuTooltip]="'Help.Title'"
        [multiMenuItems]="true"
      >
        <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
      </app-menu-button>

      <app-menu-button
        *ngIf="reportDialogService.isVisibleReportIcon"
        [menuCondition]="true"
        (menuClick)="reportDialogService.onShowReportsEventClick()"
        [menuIcon]="'File'"
        [menuLabel]="'Company.Reports'"
        [menuTooltip]="'Company.Reports'"
        [actionButtonMenu]="true"
        [menuButtonClass]="'Action-button Action-buttonReports'"
        [menuButtonId]="'ActionButtonReports'"
      >
      </app-menu-button>
    </div>
  </div>
</app-module-check>
