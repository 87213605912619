import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IImportMapping, ImportMappingFileRequest } from 'src/app/services/api/api-model';
import { EmployeeService } from 'src/app/services/employee.service';
import { environment } from 'src/environments/environment';
import { FileUploadComponent } from '../../../custom-controls/file-upload/file-upload.component';
import { ActionTypesEnum, ImportTypesEnum } from '../../../model/enum';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { DownloadService } from '../../../services/download.service';
import { SessionService } from '../../../services/session/session.service';
import { ReportDialogService } from '../../../shared-components/report-dialog/report-dialog.service';
import { CompanyDataImportservice } from './data-import.service';
import { ImportOptionHelper } from './import-option-helper';

@Component({
  selector: 'app-data-import',
  templateUrl: './data-import.component.html',
  styleUrls: ['./data-import.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DataImportComponent implements OnInit, OnDestroy {
  public importType: ImportTypesEnum = ImportTypesEnum.Payroll;
  public actionType: ActionTypesEnum = ActionTypesEnum.GetTemplate;
  public importFromExternalSource: any[];
  public importFromExternalSourceEmployee: any[];
  public importFromExternalSourceValue = 1;
  public importPayrollFromExternalSource: any[];
  public importFromExternalSourcePayrollData: any[];
  public importPayrollFromExternalSourceValue = 1;
  public showUploadFileDialog = false;
  public showCreateTemplateDialog = false;
  public mappingData: any[] = [];
  public mappingDataSource: any[] = [];
  public mappingDataSourceRecord: any;
  public balanceDateDialogVisible = false;
  public alertDialogVisible = false;
  public alertType: string;
  public balanceDate: Date = new Date();
  public radioIndex: number;

  public get branding() {
    return environment.branding;
  }

  public get showDownLoadButton(): boolean {
    return this.actionType === ActionTypesEnum.GetTemplate || this.actionType === 5;
  }
  public get showUploadButton(): boolean {
    return (
      this.actionType === ActionTypesEnum.Upload_File || this.actionType === ActionTypesEnum.Convert_From_Other_System
    );
  }
  public get showCreateCustomFile(): boolean {
    return this.actionType === ActionTypesEnum.CustomImport;
  }
  public get convertFromOtherSystemFieldsVisible(): boolean {
    return this.actionType === ActionTypesEnum.Convert_From_Other_System;
  }
  public get getTemplateVisible(): boolean {
    return (
      this.actionType === ActionTypesEnum.GetTemplate ||
      this.actionType === ActionTypesEnum.Convert_From_Other_System ||
      this.actionType === ActionTypesEnum.Export_existing_data
    );
  }
  public get isPayrollDataImport(): boolean {
    return this.importType === ImportTypesEnum.Payroll || this.importType === ImportTypesEnum.TimeEntriesRow;
  }
  public get isTimeEntriesDataImport(): boolean {
    return this.importType === ImportTypesEnum.TimeEntriesRow;
  }
  public get acceptFileType(): string {
    return this.importType === ImportTypesEnum.Payroll || this.importType === ImportTypesEnum.TimeEntriesRow
      ? this.importPayrollFromExternalSourceValue === 2
        ? '.csv'
        : '.dat'
      : '.pdf'; /*this.importFromExternalSourceValue === 2
      ? '.csv'
      : '.pdf';*/
  }
  public get hasTimeModule(): boolean {
    return this.sessionService.feature.hasModuleId(8);
  }
  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }
  public get isShowExportExistingData(): boolean {
    return this.importType === ImportTypesEnum.Employees || this.importType === ImportTypesEnum.Initial_Balances;
  }

  @ViewChild('fileupload', { static: true }) private fileUpload: FileUploadComponent;

  constructor(
    private translateService: TranslateService,
    private dataService: DataService,
    private downloadService: DownloadService,
    private sessionService: SessionService,
    private staticDataService: StaticDataService,
    private employeeService: EmployeeService,
    public reportDialogService: ReportDialogService,
    public companyDataImportservice: CompanyDataImportservice
  ) {
    this.translateText();

    this.companyDataImportservice.showReportShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && this.reportDialogService.isVisibleReportIcon) {
          this.reportDialogService.onShowReportsEventClick();
        }
      });
  }

  public ngOnInit(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e: string) =>
      this.translateText()
    );
    this.balanceDate = this.getLastDateOfPreviousMonth();
    this.selectDefaultMapping();

    if (this.isMobile) {
      this.sessionService.isShowHugeFeaturesAlert = true;
    }
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onDownLoad(): void {
    switch (this.importType) {
      case ImportTypesEnum.Employees:
      case ImportTypesEnum.Payroll:
      case ImportTypesEnum.TimeEntriesRow:
        this.downloadDocument();
        break;
      case ImportTypesEnum.Initial_Balances:
        // Popup date for Balances
        this.showBalancesDatePopup();
        break;
    }
  }

  public onUpload(): void {
    if (this.actionType === ActionTypesEnum.Convert_From_Other_System) {
      this.fileUpload.addFile();
    } else {
      this.showUploadFileDialog = true;
    }
  }

  public onCreate(): void {
    this.showCreateTemplateDialog = true;
  }

  private translateText(): void {
    this.translateService
      .get([
        'CompanyDataImport.ImportFromExternalOption',
        'CompanyDataImport.AOFdatfile',
        'CompanyDataImport.ImportFromPlanDay'
      ])
      .subscribe((translations: { [key: string]: string }) => {
        this.importFromExternalSource = [];
        this.importFromExternalSource.push({ Id: 1, Name: translations['CompanyDataImport.ImportFromExternalOption'] });

        this.importFromExternalSourceEmployee = [];
        this.importFromExternalSourceEmployee.push({
          Id: 1,
          Name: translations['CompanyDataImport.ImportFromExternalOption']
        });

        this.importPayrollFromExternalSource = [];
        this.importPayrollFromExternalSource.push({ Id: 1, Name: translations['CompanyDataImport.AOFdatfile'] });

        this.importFromExternalSourcePayrollData = [];
        this.importFromExternalSourcePayrollData.push({ Id: 1, Name: translations['CompanyDataImport.AOFdatfile'] });
      });
  }

  private getLastDateOfPreviousMonth(): Date {
    const date: Date = new Date();
    const currentMonth: number = date.getMonth();
    const currentYear: number = date.getFullYear();
    return new Date(currentYear, currentMonth, 0);
  }

  public onBalanceDateDialogAction(action: string): void {
    if (action === 'Ok' || action === 'Upload') {
      this.downloadDocument();
    }
  }

  private showBalancesDatePopup(): void {
    this.balanceDateDialogVisible = true;
    this.balanceDate = this.getLastDateOfPreviousMonth();
  }

  private downloadDocument(): void {
    const date: string = this.getBalanceDay();
    const record = this.mappingDataSource.find((id: any) => id.Id === this.mappingDataSourceRecord) || {};
    switch (this.importType) {
      case ImportTypesEnum.Employees:
        // Employees
        if (this.actionType === 5) {
          this.dataService.Download_ImportMapping_GetFilledImportTemplate(record.MappingId).subscribe(
            (fileData: any) => {
              this.onDownloadCompleted(fileData);
            },
            (err) => {
              console.log(err);
            }
          );
          break;
        }
        this.dataService.Download_ImportMapping_GetImportTemplate(record.MappingId).subscribe(
          (fileData: any) => {
            this.onDownloadCompleted(fileData);
          },
          (err) => {
            console.log(err);
          }
        );
        break;
      case ImportTypesEnum.Initial_Balances:
        // Balances
        if (this.actionType === 5) {
          this.dataService
            .Download_ImportMapping_GetFilledImportTemplateWithParameters(record.MappingId, [date])
            .subscribe(
              (fileData: any) => {
                this.onDownloadCompleted(fileData);
              },
              (err) => {
                console.log(err);
              }
            );
          break;
        }
        this.dataService.Download_ImportMapping_GetImportTemplateWithParameters(record.MappingId, [date]).subscribe(
          (fileData: any) => {
            this.onDownloadCompleted(fileData);
          },
          (err) => {
            console.log(err);
          }
        );
        break;
      case ImportTypesEnum.Payroll:
      case ImportTypesEnum.TimeEntriesRow:
        this.dataService.Download_ImportMapping_GetImportTemplate(record.MappingId).subscribe(
          (fileData: any) => {
            this.onDownloadCompleted(fileData);
          },
          (err) => {
            console.log(err);
          }
        );
        break;
    }
  }

  private onDownloadCompleted(promiseValue: any): void {
    if (this.sessionService.browser.isMobileSafari) {
      this.alertType = 'SafariWarning';
      this.alertDialogVisible = true;
    }

    this.downloadService.download('ImportTemplate.XLSX', promiseValue);
  }

  public onFileSelected(file: File): void {
    const maxSize: number = 1024 * 1024 * 64;
    if (file && file.size > maxSize) {
      this.alertType = 'FileSizeError';
      this.alertDialogVisible = true;
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const base64 = reader.result as string;
      const formDataString = base64.split('base64,')[1] || '';
      this.postDataImportFromDatFile(formDataString);
    };

    reader.readAsDataURL(file);
  }

  private postDataImportFromDatFile(formData: string): void {
    let operation: string = ImportOptionHelper.getImportTypeKey(this.importType);
    const record = this.mappingDataSource.find((mapping: IImportMapping) => {
      // return mapping.CompanyId === null;
      return mapping.Id === this.mappingDataSourceRecord;
    });
    const request: ImportMappingFileRequest = {
      FileBase64: formData,
      MappingId: record.MappingId
    };
    if (operation === 'ImportSalaryRecords') {
      if (this.importPayrollFromExternalSourceValue === 2) {
        this.dataService
          .CompanyDataImport_PlanDayToExcel(operation, formData)
          .subscribe((fileData: any) => this.onDownloadCompleted(fileData));
      } else {
        this.dataService
          .ImportMapping_DatFileToExcel(request)
          .subscribe((fileData: any) => this.onDownloadCompleted(fileData));
      }
    } else if (operation === 'ImportTimeEntries') {
      operation = 'TimeEntries';
      this.dataService
        .ImportMapping_DatFileToExcel(request)
        .subscribe((fileData: any) => this.onDownloadCompleted(fileData));
    } else {
      this.dataService.ImportMapping_DanloenPdfToExcel(request).subscribe(
        (fileData: any) => {
          if (operation === 'ImportEmployees') {
            this.employeeService.loadEmployees();
          }
          this.onDownloadCompleted(fileData);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  // private postEmployeeFileFromExternalSource(formData: FormData): void {
  //   const operation: string = ImportOptionHelper.getImportTypeKey(this.importType);
  //   this.dataService.Import_DanloenPdfToExcel(operation, formData).subscribe((fileData: string) => {
  //     this.onDownloadCompleted(fileData);
  //     if (this.importType === ImportTypesEnum.Employees) {
  //       this.staticDataService.loadDepartments();
  //       this.staticDataService.loadCompanyUsers();
  //       this.alertType = 'DanlonPDFSuccess';
  //       this.alertDialogVisible = true;
  //     } else if (this.importType === ImportTypesEnum.Initial_Balances) {
  //       this.alertType = 'BalancesDanlonPDFSuccess';
  //       this.alertDialogVisible = true;
  //     }
  //   });
  // }

  // Get date with format YYYYMMDD
  private getBalanceDate(): string {
    if (!this.balanceDate) {
      return undefined;
    }
    const dateTemp: Date = new Date(this.balanceDate);

    return (
      dateTemp.getFullYear().toString() +
      this.sessionService.toString(dateTemp.getMonth() + 1, '00') +
      this.sessionService.toString(dateTemp.getDate(), '00')
    );
  }

  // Get date with format DD-MM-YYYY
  protected getBalanceDay(): string {
    if (!this.balanceDate) {
      return undefined;
    }
    const dateTemp: Date = new Date(this.balanceDate);

    return (
      this.sessionService.toString(dateTemp.getDate(), '00') +
      '-' +
      this.sessionService.toString(dateTemp.getMonth() + 1, '00') +
      '-' +
      dateTemp.getFullYear().toString()
    );
  }

  public onCheckBoxImportTypeChange(): void {
    this.actionType = 1;
    this.importFromExternalSourceValue = 1;
    this.importPayrollFromExternalSourceValue = 1;
    this.updateListCurrentMappingData();
  }

  private selectDefaultMapping(): void {
    this.dataService.ImportMapping_CompanyMappings().subscribe(
      (mappingData: IImportMapping[]): void => {
        this.mappingData = mappingData || [];
        this.updateListCurrentMappingData();
      },
      () => {
        this.mappingData = [];
      }
    );
  }

  private updateListCurrentMappingData() {
    this.mappingDataSource = [];
    const importType = this.importType;

    this.mappingData.forEach((mapping: IImportMapping, index: number) => {
      if (importType === mapping.TypeId) {
        this.mappingDataSource.push({
          Id: index++,
          Name: mapping.Name,
          MappingId: mapping.Id,
          CompanyId: mapping.CompanyId
        });
      }
    });

    const isDefaultMapping = this.mappingDataSource.find((mapping: IImportMapping) => {
      return mapping.CompanyId === null;
    });
    if (!isDefaultMapping) {
      this.mappingDataSourceRecord =
        this.mappingDataSource && this.mappingDataSource.length > 0 ? this.mappingDataSource[0].Id : undefined;
    } else {
      this.mappingDataSourceRecord = isDefaultMapping.Id;
    }
  }

  public onActionTypeChange(): void {
    this.importFromExternalSourceValue = 1;
    switch (this.actionType) {
      case 1:
        this.radioIndex = 1;
        this.updateListCurrentMappingData();
        break;
      case 5:
        this.radioIndex = 2;
        this.updateListCurrentMappingData();
        break;
      case 4:
        if (this.importType >= 3) {
          this.radioIndex = 2;
        } else {
          this.radioIndex = 3;
        }
        this.updateListCurrentMappingData();
        break;
      case 3:
      case 2:
        if (this.importType >= 3) {
          this.radioIndex = 2;
        } else {
          this.radioIndex = 3;
        }
        break;
    }

    if (this.uploadButtonDisable) {
      this.alertType = 'DisableDanlon';
      this.alertDialogVisible = true;
    }
  }

  public get uploadButtonDisable(): boolean {
    return false;
  }

  public onImportMappingChange() {
    this.selectDefaultMapping();
  }
}
