import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FormComponentBase } from '../../../common/form-component-base';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';
import { ReportDialogService } from '../../../shared-components/report-dialog/report-dialog.service';
import { IntegrationsService } from './integrations.service';
var IntegrationsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(IntegrationsComponent, _super);
    function IntegrationsComponent(sessionService, settingService, reportDialogService, integrationsService) {
        var _this = _super.call(this) || this;
        _this.sessionService = sessionService;
        _this.settingService = settingService;
        _this.reportDialogService = reportDialogService;
        _this.integrationsService = integrationsService;
        _this.isShowingAlertMessage = false;
        _this.isShowingDeleteMessage = false;
        _this.OperationRunSucess = false;
        _this.dialogVisible = false;
        _this.ngUnsubscribe = new Subject();
        _this.integrationsService.editModeChange.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (value) {
            if (!value && _this.integrationsService.tabDirty) {
                _this.integrationsService.saveValueControlParam();
            }
        });
        _this.integrationsService.onContinuteState.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (value) {
            if (value) {
                _this.integrationsService.editMode = false;
            }
        });
        _this.integrationsService.editShortcutChange.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (value) {
            if (value) {
                _this.onDoubleClick();
            }
        });
        _this.integrationsService.addNewShortcutChange.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (value) {
            if (value && !_this.integrationsService.editMode && !_this.integrationsService.isReadonlyUser) {
                _this.integrationsService.addNewsDialogVisible = true;
            }
        });
        _this.integrationsService.discardChangeShortcutChange
            .pipe(takeUntil(_this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && _this.integrationsService.editMode) {
                _this.applyDiscard();
            }
        });
        _this.integrationsService.saveChangeShortcutChange
            .pipe(takeUntil(_this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && _this.integrationsService.editMode) {
                _this.onEditClick(true);
            }
        });
        return _this;
    }
    Object.defineProperty(IntegrationsComponent.prototype, "isShowExternalIntegrationsConfiguration", {
        get: function () {
            if ((this.integrationsService.configurationControl && this.integrationsService.configurationControl.length > 0) ||
                (this.integrationsService.integrationConfigurationControl &&
                    this.integrationsService.integrationConfigurationControl.length > 0)) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntegrationsComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntegrationsComponent.prototype, "isShowExternalIntegrationsCredential", {
        get: function () {
            if (this.integrationsService.credentialControl && this.integrationsService.credentialControl.length > 0) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    IntegrationsComponent.prototype.ngOnInit = function () {
        this.integrationsService.loadIntegrationModel();
        this.serviceInIt();
        this.settingService.showModuleMessage('company.integrations');
    };
    IntegrationsComponent.prototype.serviceInIt = function () {
        this.integrationsService.integrations.pipe(takeUntil(this.ngUnsubscribe)).subscribe();
        this.integrationsService.integration.pipe(takeUntil(this.ngUnsubscribe)).subscribe();
    };
    IntegrationsComponent.prototype.ngOnDestroy = function () {
        this.integrationsService.ngOnDestroy();
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    IntegrationsComponent.prototype.onDoubleClick = function () {
        if (this.integrationsService.hasIntegrationModule &&
            !this.sessionService.isGetting &&
            !this.sessionService.isSubmitting) {
            if (!this.integrationsService.isReadonlyUser && !this.integrationsService.isEmptyIntegrations) {
                this.integrationsService.editMode = true;
            }
        }
    };
    IntegrationsComponent.prototype.applyDiscard = function () {
        if (this.integrationsService.tabDirty) {
            this.isShowingAlertMessage = true;
        }
        else {
            this.integrationsService.editMode = false;
        }
    };
    IntegrationsComponent.prototype.onUnSaveWarningDialogAction = function (action) {
        if (action === 'ConfirmDiscard') {
            this.integrationsService.selectedId = undefined;
            this.integrationsService.loadIntegrationModel();
            if (this.integrationsService.nextIntegrationIdChange) {
                this.integrationsService.selectedId = this.integrationsService.nextIntegrationIdChange;
                this.integrationsService.nextIntegrationIdChange = undefined;
            }
        }
    };
    IntegrationsComponent.prototype.onEditClick = function ($event) {
        if (this.integrationsService.editMode !== $event) {
            this.integrationsService.editMode = $event;
        }
    };
    return IntegrationsComponent;
}(FormComponentBase));
export { IntegrationsComponent };
