<app-text-edit
  [(isDirty)]="childIsDirty[0]"
  [label]="'CompanyGeneral.AddressLine1' | translate"
  [editMode]="editMode"
  [(value)]="address.Line1"
  (valueChange)="onChange()"
></app-text-edit>

<app-text-edit
  [(isDirty)]="childIsDirty[0]"
  [label]="'CompanyGeneral.AddressLine2' | translate"
  [editMode]="editMode"
  [placeholder]="'CompanyGeneral.Optional' | translate"
  [(value)]="address.Line2"
  (valueChange)="onChange()"
></app-text-edit>

<app-text-edit
  [(isDirty)]="childIsDirty[0]"
  [label]="'CompanyGeneral.Postal code' | translate"
  [editMode]="editMode"
  [(value)]="address.PostalCode"
  (valueChange)="onPostalCodeChanged($event)"
></app-text-edit>

<app-text-edit
  [(isDirty)]="childIsDirty[0]"
  [label]="'CompanyGeneral.City' | translate"
  [editMode]="editMode"
  [(value)]="address.City"
  (valueChange)="onChange()"
></app-text-edit>

<app-combobox-edit
  [label]="'NewEmployee.Country' | translate"
  [editMode]="false"
  [textField]="'Name'"
  [idField]="'Id'"
  [comboboxDataSource]="countries"
  [(value)]="address.CountryId"
  [disable]="true"
  (valueChange)="onChange()"
></app-combobox-edit>
