import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { NumericTextBoxOptions } from '../../../custom-controls/numeric-edit/numeric-text-box-options';
import {
  BalanceAdjustmentRequest,
  IBalanceAdjustmentRequestDetail,
  IGetBalancesEmployeeViewByDate_Result
} from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';
import { ReportDialogService } from '../../../shared-components/report-dialog/report-dialog.service';
import { ExtraVacationHoursService } from '../company-extra-vacation-hours.services';

@Component({
  selector: 'app-company-balances-extra-vacation-hours',
  templateUrl: './company-extra-vacation-hours.component.html',
  styleUrls: ['./company-extra-vacation-hours.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyExtraVacationHoursComponent extends ExtraVacationHoursService implements OnInit, OnDestroy {
  constructor(
    public service: ExtraVacationHoursService,
    protected sessionService: SessionService,
    protected dataService: DataService,
    protected staticDataService: StaticDataService,
    public reportDialogService: ReportDialogService,
    protected transitionService: TransitionService,
    protected broadcaster: BroadcastService,
    protected settingService: SettingService
  ) {
    super(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster);

    this.service.showReportShortcutChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      if (value && this.reportDialogService.isVisibleReportIcon) {
        this.reportDialogService.onShowReportsEventClick();
      }
    });
  }

  public refreshData = false;
  public gridDataSource: IGetBalancesEmployeeViewByDate_Result[] = [];
  public spinnerOption: NumericTextBoxOptions = {
    max: undefined,
    min: undefined,
    step: 1,
    spinners: true,
    format: 'n2'
  };

  public isShowDataInvalidAlert = false;
  public isDataValid = true;
  public isShowAfterSaveSucess = false;

  private isSaing = false;

  private isSmallScreenValue = false;
  public get isSmallScreen(): boolean {
    return this.isSmallScreenValue;
  }

  public set isSmallScreen(value: boolean) {
    if (value !== this.isSmallScreenValue) {
      this.isSmallScreenValue = value;
      this.refreshData = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.onScreenCheck();
  }

  private onScreenCheck() {
    this.isSmallScreen = window.innerWidth <= 1440;
  }

  public get minCellWidth(): number {
    return this.isSmallScreen ? 150 : undefined;
  }

  public ngOnInit(): void {
    this.onScreenCheck();
    this.service.BalancesEmployeeViewByDate.pipe().subscribe((model: IGetBalancesEmployeeViewByDate_Result[]): void => {
      // copy dirty records
      let arrayItemDirty: IGetBalancesEmployeeViewByDate_Result[] | any[] = [];
      if (
        this.service.originalDataBalancesEmployeeViewByDate &&
        this.service.originalDataBalancesEmployeeViewByDate.length > 0
      ) {
        arrayItemDirty = this.service.originalDataBalancesEmployeeViewByDate.filter(
          (item: IGetBalancesEmployeeViewByDate_Result | any) => item.isDirty === true
        );
      }

      this.gridDataSource = model;
      this.gridDataSource.forEach((item: IGetBalancesEmployeeViewByDate_Result | any) => {
        if (item.HoursTotal || item.HoursTotal === 0) {
          item.HoursTotal = parseFloat(parseFloat(item.HoursTotal).toFixed(2));
        }
        this.caculateLeft(item);
        item.isDirty = false;
      });

      if (this.service.isFirstLoad) {
        this.service.isFirstLoad = false;
      } else {
        if (arrayItemDirty && arrayItemDirty.length > 0) {
          arrayItemDirty.forEach((item: IGetBalancesEmployeeViewByDate_Result | any) => {
            const recordDirty:
              | IGetBalancesEmployeeViewByDate_Result
              | any = this.service.originalDataBalancesEmployeeViewByDate.find(
              (find: IGetBalancesEmployeeViewByDate_Result | any) => find.UserEmploymentId === item.UserEmploymentId
            );
            if (recordDirty) {
              recordDirty.isDirty = true;
            }
          });
        }
      }
    });
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private get isDirtyData(): boolean {
    if (this.gridDataSource && this.gridDataSource.length > 0) {
      return this.gridDataSource.some((item: any) => item.isDirty);
    }
    return false;
  }

  public get isReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public get isIOSApp(): boolean {
    return this.sessionService.browser.isHybridApp && this.sessionService.browser.iOSMobileDevice;
  }

  public get isDisableSubmitButton(): boolean {
    return this.isReadOnly || !this.service.effectivePer;
  }

  public onGridSave(event: any): void {
    if (event.dataItem) {
      event.dataItem.SpendHours =
        event.dataItem.SpendHours && event.dataItem.SpendHours > 0
          ? event.dataItem.SpendHours * -1
          : event.dataItem.SpendHours;
      event.dataItem.PayoutHours =
        event.dataItem.PayoutHours && event.dataItem.PayoutHours > 0
          ? event.dataItem.PayoutHours * -1
          : event.dataItem.PayoutHours;

      this.caculateLeft(event.dataItem);
    }
  }

  public onGridButtonClick(event: any): void {
    let feildChnage = '';
    switch (event.buttonAction) {
      case 'MaxSpend':
        feildChnage = 'SpendHours';
        break;
      default:
        feildChnage = 'PayoutHours';
        break;
    }
    if (feildChnage) {
      if (event.dataItem['left'] === 0) {
        event.dataItem['disbaleMaxButton'] = true;
        return;
      } else {
        this.caculateMaxValue(feildChnage, event.dataItem);
      }
    }
  }

  public MaxFieldHours(field: string): void {
    if (this.isReadOnly) {
      return;
    }
    this.gridDataSource.forEach((item: any) => {
      if (item['left'] !== 0) {
        this.caculateMaxValue(field, item);
      }
    });
  }

  private caculateMaxValue(feildMax: string, data: any) {
    if (data['left'] === 0) {
      data['disbaleMaxButton'] = true;
      return;
    } else {
      const leftValue = parseFloat(parseFloat(data['left']).toFixed(2));

      const dataFeildMax = data[feildMax] ? data[feildMax] : 0;
      data[feildMax] = leftValue * -1 + parseFloat(parseFloat(dataFeildMax).toFixed(2));
      data[feildMax] = parseFloat(parseFloat(data[feildMax]).toFixed(2));

      this.caculateLeft(data);
      this.refreshData = true;
    }
  }

  private caculateLeft(data: IGetBalancesEmployeeViewByDate_Result | any): void {
    const HoursTotalValues = data.HoursTotal ? data.HoursTotal : 0;

    const AdjustHoursValues = data.AdjustHours ? data.AdjustHours : 0;
    const SpendHoursValues = data.SpendHours ? data.SpendHours : 0;
    const PayoutHoursValues = data.PayoutHours ? data.PayoutHours : 0;

    data.left = this.sessionService.parseFloat(
      HoursTotalValues +
        (parseFloat(parseFloat(AdjustHoursValues).toFixed(2)) +
          parseFloat(parseFloat(SpendHoursValues).toFixed(2)) +
          parseFloat(parseFloat(PayoutHoursValues).toFixed(2)))
    );

    data.left = parseFloat(parseFloat(data.left).toFixed(2));

    if (data.left < 0) {
      data.errorLeftClass = 'left-error';
      data['disbaleMaxButton'] = false;
    } else {
      data.errorLeftClass = '';
      data['disbaleMaxButton'] = false;

      if (data.left === 0) {
        data['disbaleMaxButton'] = true;
      }
    }
    data.isDirty = true;
  }

  private submitGridValues(): void {
    if (!this.isDirtyData) {
      return;
    }
    const request: BalanceAdjustmentRequest = {
      BalanceDefinitionId: 7,
      BalancePeriodId: null,
      PerDate: this.service.effectivePer
    };
    const DetailDataSource: IBalanceAdjustmentRequestDetail[] = [];
    let countError = 0;
    this.gridDataSource.forEach((item: any) => {
      if (item.isDirty) {
        DetailDataSource.push({
          UserEmploymentId: item.UserEmploymentId || null,
          AdjustDays: null,
          SpendDays: null,
          MoveDays: null,
          PayoutDays: null,

          AdjustHours: item.AdjustHours || null,
          SpendHours: item.SpendHours || null,
          PayoutHours: item.PayoutHours || null
        });
        if (item.left < 0) {
          countError += 1;
        }
      }
    });
    this.isDataValid = countError <= 0;

    if (this.isDataValid) {
      request.Details = DetailDataSource;
      if (request.Details && request.Details.length > 0) {
        this.save(request);
      }
    } else {
      this.isShowDataInvalidAlert = true;
    }
  }

  public onSubmitButtonClick() {
    setTimeout(() => {
      this.submitGridValues();
    }, 500);
  }

  public save(model: BalanceAdjustmentRequest): void {
    if (this.isSaing) {
      return;
    }
    this.isSaing = true;
    super.save(model);
  }

  // public onEffecivePerKeyDown(event: any): void {
  //   if (event === 'Enter') {
  //     this.service.Reload_Balances_GetExtraVacationHours();
  //   }
  // }

  public afterSaveSucess(data: IGetBalancesEmployeeViewByDate_Result[]): void {
    if (data && data.length > 0) {
      data.forEach((item: IGetBalancesEmployeeViewByDate_Result) => {
        const itemAfterSave: any = this.gridDataSource.find(
          (model: IGetBalancesEmployeeViewByDate_Result | any) => model.UserEmploymentId === item.UserEmploymentId
        );
        if (itemAfterSave) {
          itemAfterSave.FullName = item.FullName;
          itemAfterSave.BasedOnTemplateName = item.BasedOnTemplateName;
          itemAfterSave.DepartmentName = item.DepartmentName;
          itemAfterSave.ExternalReference = item.ExternalReference;

          itemAfterSave.BaseAmount = item.BaseAmount || null;
          itemAfterSave.AmountEarned = item.AmountEarned || null;
          itemAfterSave.AmountTotal = item.AmountTotal || null;
          itemAfterSave.HoursEarned = item.HoursEarned || null;
          itemAfterSave.HoursSpent = item.HoursSpent || null;
          itemAfterSave.HoursTotal = item.HoursTotal || null;

          itemAfterSave.SecondaryDaysEarned = item.SecondaryDaysEarned || null;
          itemAfterSave.SecondaryDaysSpent = item.SecondaryDaysSpent || null;
          itemAfterSave.CurrentBalanceSecondaryDays = item.CurrentBalanceSecondaryDays || null;
          itemAfterSave.SecondaryAmountTotal = item.SecondaryAmountTotal || null;

          itemAfterSave.DaysEarned = itemAfterSave.DaysEarned + itemAfterSave.SecondaryDaysEarned;
          itemAfterSave.DaysSpent = itemAfterSave.DaysSpent + itemAfterSave.SecondaryDaysSpent;
          itemAfterSave.DaysTotal = itemAfterSave.DaysTotal + itemAfterSave.CurrentBalanceSecondaryDays;
          itemAfterSave.AmountTotal = itemAfterSave.AmountTotal + itemAfterSave.SecondaryAmountTotal;

          itemAfterSave.AdjustHours = null;
          itemAfterSave.SpendHours = null;
          itemAfterSave.PayoutHours = null;

          this.caculateLeft(itemAfterSave);
          itemAfterSave.isDirty = false;
        }
      });
      this.refreshData = true;
    }

    this.isShowAfterSaveSucess = true;
  }

  public completeSave() {
    this.isSaing = false;
  }
}
