import { OnInit } from '@angular/core';
import { SessionService } from '../../services/session/session.service';
var CompanyBalancesComponent = /** @class */ (function () {
    function CompanyBalancesComponent(sessionService) {
        this.sessionService = sessionService;
        this.isCollapseButtonClick = false;
        this.menuOpen = false;
        this.listClassExcluded = ['Footer', '10'];
    }
    Object.defineProperty(CompanyBalancesComponent.prototype, "ResidualVacationIsActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.balances.residualvacation';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyBalancesComponent.prototype, "ResidualExtravacationhoursIsActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.balances.extravacationhours';
        },
        enumerable: true,
        configurable: true
    });
    CompanyBalancesComponent.prototype.ngOnInit = function () { };
    CompanyBalancesComponent.prototype.onToggleNavigation = function () {
        this.isCollapseButtonClick = true;
        this.menuOpen = !this.menuOpen;
    };
    CompanyBalancesComponent.prototype.changeState = function (state) {
        if (state === void 0) { state = 'tabs.company.balances.residualvacation'; }
        this.menuOpen = false;
        this.sessionService.NavigateTo(state);
    };
    return CompanyBalancesComponent;
}());
export { CompanyBalancesComponent };
