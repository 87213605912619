/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./configuration-integrations.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../custom-controls/icon/icon.component.ngfactory";
import * as i3 from "../../../../../custom-controls/icon/icon.component";
import * as i4 from "../../../../../services/session/session.service";
import * as i5 from "../../../../../custom-controls/buttons/button.component.ngfactory";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../custom-controls/buttons/button.component";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../../../../custom-controls/check-edit/check-edit.component.ngfactory";
import * as i10 from "../../../../../custom-controls/check-edit/check-edit.component";
import * as i11 from "../../../../../custom-controls/text-edit/text-edit.component.ngfactory";
import * as i12 from "../../../../../custom-controls/text-edit/text-edit.component";
import * as i13 from "../../../../../custom-controls/combobox-edit/combobox-edit.component.ngfactory";
import * as i14 from "../../../../../custom-controls/combobox-edit/combobox-edit.component";
import * as i15 from "../../../../../custom-controls/date-picker/date-picker.component.ngfactory";
import * as i16 from "../../../../../custom-controls/date-picker/date-picker.component";
import * as i17 from "../../../../../custom-controls/dialogs/dialog.component.ngfactory";
import * as i18 from "../../../../../custom-controls/dialogs/dialog.component";
import * as i19 from "@angular/platform-browser";
import * as i20 from "../../../../../custom-controls/dialogs/dialog-action.component.ngfactory";
import * as i21 from "../../../../../custom-controls/dialogs/dialog-action.component";
import * as i22 from "../../../../../shared-components/loading-tips/loading-tips.component.ngfactory";
import * as i23 from "../../../../../shared-components/loading-tips/loading-tips.component";
import * as i24 from "./configuration-integrations.component";
import * as i25 from "../../integrations.service";
import * as i26 from "../../../../../services/api/data.service";
import * as i27 from "../../../../../services/employee.service";
var styles_ConfigurationIntegrationsComponent = [i0.styles];
var RenderType_ConfigurationIntegrationsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ConfigurationIntegrationsComponent, data: {} });
export { RenderType_ConfigurationIntegrationsComponent as RenderType_ConfigurationIntegrationsComponent };
function View_ConfigurationIntegrationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-icon", [], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(1, 49152, null, 0, i3.IconComponent, [i1.ElementRef, i4.SessionService, i1.ChangeDetectorRef], { icon: [0, "icon"], tooltip: [1, "tooltip"], istooltipHTML: [2, "istooltipHTML"] }, null)], function (_ck, _v) { var currVal_0 = "Warning"; var currVal_1 = _v.parent.context.$implicit.translatemessage; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ConfigurationIntegrationsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-icon", [], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(1, 49152, null, 0, i3.IconComponent, [i1.ElementRef, i4.SessionService, i1.ChangeDetectorRef], { icon: [0, "icon"], tooltip: [1, "tooltip"], istooltipHTML: [2, "istooltipHTML"] }, null)], function (_ck, _v) { var currVal_0 = "Success"; var currVal_1 = _v.parent.context.$implicit.translatemessage; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ConfigurationIntegrationsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-icon", [], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(1, 49152, null, 0, i3.IconComponent, [i1.ElementRef, i4.SessionService, i1.ChangeDetectorRef], { icon: [0, "icon"], tooltip: [1, "tooltip"], istooltipHTML: [2, "istooltipHTML"] }, null)], function (_ck, _v) { var currVal_0 = "ErrorExclamation"; var currVal_1 = _v.parent.context.$implicit.translatemessage; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ConfigurationIntegrationsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-icon", [], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(1, 49152, null, 0, i3.IconComponent, [i1.ElementRef, i4.SessionService, i1.ChangeDetectorRef], { icon: [0, "icon"], tooltip: [1, "tooltip"], istooltipHTML: [2, "istooltipHTML"] }, null)], function (_ck, _v) { var currVal_0 = "SuccessGray"; var currVal_1 = _v.parent.context.$implicit.translatemessage; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ConfigurationIntegrationsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-icon", [], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(1, 49152, null, 0, i3.IconComponent, [i1.ElementRef, i4.SessionService, i1.ChangeDetectorRef], { icon: [0, "icon"], tooltip: [1, "tooltip"], istooltipHTML: [2, "istooltipHTML"] }, null)], function (_ck, _v) { var currVal_0 = "SuccessGray"; var currVal_1 = _v.parent.context.$implicit.translatemessage; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ConfigurationIntegrationsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "app-button", [["class", "right Button--card Button-RunNow"]], null, [[null, "clickEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickEvent" === en)) {
        var pd_0 = (_co.runOperation(_v.parent.context.$implicit.Id, _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "button-action-integration-disabled": 0 }), i1.ɵdid(4, 49152, null, 0, i7.ButtonComponent, [i4.SessionService], { label: [0, "label"], isDisabled: [1, "isDisabled"] }, { clickEvent: "clickEvent" }), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "right Button--card Button-RunNow"; var currVal_1 = _ck(_v, 3, 0, ((!_v.parent.context.$implicit.value || _co.sessionService.isSubmitting) || _co.integrationsService.editMode)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("Company.ExternalIntegrationsRunNow")); var currVal_3 = ((!_v.parent.context.$implicit.value || _co.sessionService.isSubmitting) || _co.integrationsService.editMode); _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
function View_ConfigurationIntegrationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "clearboth configuration-control"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "a", [["class", "left FormElement-cursorHand"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurationIntegrationsComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurationIntegrationsComponent_3)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurationIntegrationsComponent_4)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurationIntegrationsComponent_5)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurationIntegrationsComponent_6)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-check-edit", [["class", "configuration-checkbox"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (_co.isDirtyChange(true) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_v.context.$implicit.value = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_CheckEditComponent_0, i9.RenderType_CheckEditComponent)), i1.ɵdid(13, 114688, null, 0, i10.CheckEditComponent, [i1.ElementRef, i1.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], label: [2, "label"], helpTooltip: [3, "helpTooltip"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurationIntegrationsComponent_7)), i1.ɵdid(15, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.getStatusMessageColorOperation(_v.context.$implicit.statuscode) === "Changed"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.getStatusMessageColorOperation(_v.context.$implicit.statuscode) === "Success"); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.getStatusMessageColorOperation(_v.context.$implicit.statuscode) === "Error"); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.getStatusMessageColorOperation(_v.context.$implicit.statuscode) === "Inactive"); _ck(_v, 9, 0, currVal_3); var currVal_4 = (_co.getStatusMessageColorOperation(_v.context.$implicit.statuscode) === ""); _ck(_v, 11, 0, currVal_4); var currVal_5 = _v.context.$implicit.value; var currVal_6 = _co.integrationsService.editMode; var currVal_7 = _v.context.$implicit.name; var currVal_8 = _v.context.$implicit.Description; _ck(_v, 13, 0, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = !_co.integrationsService.isReadonlyUser; _ck(_v, 15, 0, currVal_9); }, null); }
function View_ConfigurationIntegrationsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-check-edit", [["class", "integration-checkbox extraTop"]], null, [[null, "isDirtyChange"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isDirtyChange" === en)) {
        var pd_0 = ((_co.integrationsService.tabDirty = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_v.parent.context.$implicit.value = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_CheckEditComponent_0, i9.RenderType_CheckEditComponent)), i1.ɵdid(1, 114688, null, 0, i10.CheckEditComponent, [i1.ElementRef, i1.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], isDirty: [2, "isDirty"], label: [3, "label"], helpTooltip: [4, "helpTooltip"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.value; var currVal_1 = _co.integrationsService.editMode; var currVal_2 = _co.integrationsService.tabDirty; var currVal_3 = _v.parent.context.$implicit.name; var currVal_4 = _v.parent.context.$implicit.Description; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_ConfigurationIntegrationsComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-text-edit", [["class", "integration-textbox"]], null, [[null, "isDirtyChange"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isDirtyChange" === en)) {
        var pd_0 = ((_co.integrationsService.tabDirty = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_v.parent.context.$implicit.value = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i11.View_TextEditComponent_0, i11.RenderType_TextEditComponent)), i1.ɵdid(1, 114688, null, 0, i12.TextEditComponent, [i4.SessionService, i1.ElementRef, i1.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], isDirty: [2, "isDirty"], label: [3, "label"], tooltipContent: [4, "tooltipContent"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.value; var currVal_1 = _co.integrationsService.editMode; var currVal_2 = _co.integrationsService.tabDirty; var currVal_3 = _v.parent.context.$implicit.name; var currVal_4 = _v.parent.context.$implicit.Description; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_ConfigurationIntegrationsComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-combobox-edit", [], null, [[null, "isDirtyChange"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isDirtyChange" === en)) {
        var pd_0 = ((_co.integrationsService.tabDirty = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_v.parent.context.$implicit.value = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i13.View_ComboboxEditComponent_0, i13.RenderType_ComboboxEditComponent)), i1.ɵdid(1, 4440064, null, 0, i14.ComboboxEditComponent, [i4.SessionService, i1.ElementRef, i1.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], isDirty: [2, "isDirty"], idField: [3, "idField"], textField: [4, "textField"], keepDataSourceOrder: [5, "keepDataSourceOrder"], label: [6, "label"], nullValueLabel: [7, "nullValueLabel"], comboboxDataSource: [8, "comboboxDataSource"], tooltipContent: [9, "tooltipContent"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange" }), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.value; var currVal_1 = _co.integrationsService.editMode; var currVal_2 = _co.integrationsService.tabDirty; var currVal_3 = _v.parent.context.$implicit.idField; var currVal_4 = _v.parent.context.$implicit.textField; var currVal_5 = true; var currVal_6 = _v.parent.context.$implicit.name; var currVal_7 = i1.ɵunv(_v, 1, 7, i1.ɵnov(_v, 2).transform("DropdownList.None")); var currVal_8 = _v.parent.context.$implicit.dataSource; var currVal_9 = _v.parent.context.$implicit.Description; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
function View_ConfigurationIntegrationsComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-date-picker", [["class", "integration-datepicker"]], null, [[null, "isDirtyChange"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isDirtyChange" === en)) {
        var pd_0 = ((_co.integrationsService.tabDirty = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_v.parent.context.$implicit.value = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i15.View_DatePickerComponent_0, i15.RenderType_DatePickerComponent)), i1.ɵdid(1, 4308992, null, 0, i16.DatePickerComponent, [i1.ElementRef, i4.SessionService, i1.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], isDirty: [2, "isDirty"], label: [3, "label"], helpTooltip: [4, "helpTooltip"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.value; var currVal_1 = _co.integrationsService.editMode; var currVal_2 = _co.integrationsService.tabDirty; var currVal_3 = _v.parent.context.$implicit.name; var currVal_4 = _v.parent.context.$implicit.Description; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_ConfigurationIntegrationsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "clearboth integration-control"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurationIntegrationsComponent_9)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurationIntegrationsComponent_10)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurationIntegrationsComponent_11)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurationIntegrationsComponent_12)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type === "CheckBox"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type === "TextBox"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.type === "Dropdown"); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_v.context.$implicit.type === "DatePicker"); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_ConfigurationIntegrationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "fieldset", [["class", "Form--horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "legend", [["class", "Card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurationIntegrationsComponent_1)), i1.ɵdid(5, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurationIntegrationsComponent_8)), i1.ɵdid(7, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 7, "app-dialog", [], null, [[null, "visibleChange"], [null, "action"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.isShowingAutoMap = $event) !== false);
        ad = (pd_0 && ad);
    } if (("action" === en)) {
        var pd_1 = (_co.onAutoMap($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i17.View_DialogComponent_0, i17.RenderType_DialogComponent)), i1.ɵdid(9, 49152, null, 1, i18.DialogComponent, [i19.DomSanitizer, i4.SessionService, i1.ElementRef], { leadingText: [0, "leadingText"], visible: [1, "visible"] }, { visibleChange: "visibleChange", action: "action" }), i1.ɵqud(603979776, 1, { buttonElements: 1 }), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "app-dialog-action", [], null, null, null, i20.View_DialogActionComponent_0, i20.RenderType_DialogActionComponent)), i1.ɵdid(13, 180224, [[1, 4]], 0, i21.DialogActionComponent, [i4.SessionService], { type: [0, "type"] }, null), (_l()(), i1.ɵeld(14, 0, null, 0, 1, "app-dialog-action", [], null, null, null, i20.View_DialogActionComponent_0, i20.RenderType_DialogActionComponent)), i1.ɵdid(15, 180224, [[1, 4]], 0, i21.DialogActionComponent, [i4.SessionService], { type: [0, "type"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "app-loading-tips", [], null, null, null, i22.View_LoadingTipsComponent_0, i22.RenderType_LoadingTipsComponent)), i1.ɵdid(17, 49152, null, 0, i23.LoadingTipsComponent, [i8.TranslateService, i4.SessionService], { visible: [0, "visible"], tiptext: [1, "tiptext"] }, null), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, null, 5, "app-dialog", [["class", "blue-dialog"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.OperationRunSucess = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_DialogComponent_0, i17.RenderType_DialogComponent)), i1.ɵdid(20, 49152, null, 1, i18.DialogComponent, [i19.DomSanitizer, i4.SessionService, i1.ElementRef], { leadingText: [0, "leadingText"], visible: [1, "visible"] }, { visibleChange: "visibleChange" }), i1.ɵqud(603979776, 2, { buttonElements: 1 }), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(23, 0, null, 0, 1, "app-dialog-action", [], null, null, null, i20.View_DialogActionComponent_0, i20.RenderType_DialogActionComponent)), i1.ɵdid(24, 180224, [[2, 4]], 0, i21.DialogActionComponent, [i4.SessionService], { type: [0, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.integrationsService.configurationControl; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.integrationsService.integrationConfigurationControl; _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 11).transform("CompanyGeneral.ReplaceExternalReferenceConfirmationMessage")); var currVal_4 = _co.isShowingAutoMap; _ck(_v, 9, 0, currVal_3, currVal_4); var currVal_5 = "Cancel"; _ck(_v, 13, 0, currVal_5); var currVal_6 = "Proceed"; _ck(_v, 15, 0, currVal_6); var currVal_7 = _co.overlayVisible; var currVal_8 = i1.ɵunv(_v, 17, 1, i1.ɵnov(_v, 18).transform("Integrations.OperationRunWarning")); _ck(_v, 17, 0, currVal_7, currVal_8); var currVal_9 = i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 22).transform("Company.OperationRun")); var currVal_10 = _co.OperationRunSucess; _ck(_v, 20, 0, currVal_9, currVal_10); var currVal_11 = "Ok"; _ck(_v, 24, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Company.ExternalIntegrationsConfiguration")); _ck(_v, 2, 0, currVal_0); }); }
export function View_ConfigurationIntegrationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-configuration-integrations", [], null, null, null, View_ConfigurationIntegrationsComponent_0, RenderType_ConfigurationIntegrationsComponent)), i1.ɵdid(1, 180224, null, 0, i24.ConfigurationIntegrationsComponent, [i25.IntegrationsService, i4.SessionService, i26.DataService, i27.EmployeeService], null, null)], null, null); }
var ConfigurationIntegrationsComponentNgFactory = i1.ɵccf("app-configuration-integrations", i24.ConfigurationIntegrationsComponent, View_ConfigurationIntegrationsComponent_Host_0, {}, {}, []);
export { ConfigurationIntegrationsComponentNgFactory as ConfigurationIntegrationsComponentNgFactory };
