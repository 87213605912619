import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Constants } from '../common/constants';
import { Utf8ArrayToStr } from '../common/utf8';
import { DataService } from './api/data.service';
import { BroadcastService } from './broadcast.service';
import { ModalService } from './modal.service';
import { SessionService } from './session/session.service';
import * as i0 from "@angular/core";
import * as i1 from "./modal.service";
import * as i2 from "./broadcast.service";
import * as i3 from "./session/session.service";
import * as i4 from "./api/data.service";
var ErrorInterceptor = /** @class */ (function () {
    function ErrorInterceptor(modalService, broadcaster, sessionService, dataService) {
        this.modalService = modalService;
        this.broadcaster = broadcaster;
        this.sessionService = sessionService;
        this.dataService = dataService;
    }
    Object.defineProperty(ErrorInterceptor.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    ErrorInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        return next.handle(req).pipe(catchError(function (err, caught) {
            if (err instanceof HttpErrorResponse) {
                var error_1 = err;
                switch (error_1.status) {
                    case 0:
                    case -1:
                        var networkError = _this.sessionService.modalTranslations['Error.NetworkError'].replace('{{SystemAlias}}', _this.branding.SystemAlias);
                        var localLanguageCode = localStorage.getItem('localLanguageCode');
                        var networkErrorMessage = _this.branding.SystemAlias +
                            ' har mistet forbindelsen til serveren. Dette kan være et midlertidigt problem, især hvis du bruger wifi eller mobilt internet. Tjek din internetforbindelse og prøv igen, eller kontakt os hvis problemet fortsætter.';
                        if (localLanguageCode === 'en') {
                            networkErrorMessage =
                                _this.branding.SystemAlias +
                                    " could not connect to the server. This can happen intermittently, especially if you're using wifi or mobile data. Check your internet connection and try again, or contact us if the issue persists.";
                        }
                        _this.modalService.alert('', networkError ? networkError : networkErrorMessage, true);
                        break;
                    case 400:
                        if (error_1.error.Message) {
                            _this.modalService.alert('', error_1.error.Message, true);
                        }
                        else {
                            var decodedString = void 0;
                            try {
                                var array = new Uint8Array(error_1.error);
                                decodedString = Utf8ArrayToStr(array);
                                var obj = JSON.parse(decodedString);
                                if (obj && obj.Message) {
                                    _this.modalService.alert('', obj.Message, true);
                                }
                            }
                            catch (e) {
                                if (decodedString) {
                                    _this.modalService.alert('', decodedString, true);
                                }
                            }
                        }
                        break;
                    case 401:
                        var urlResponse = error_1.url;
                        if (urlResponse.includes('session/isalive')) {
                            _this.broadcaster.broadcast(Constants.SESSION_EXPIRED);
                        }
                        else {
                            if (!_this.sessionService.currentState.includes('login') && !_this.sessionService.checkIsAliveError) {
                                _this.modalService.alert('', _this.sessionService.errorMessage401, true, false, 401);
                                var errormessage_1 = error_1.statusText;
                                if (urlResponse) {
                                    errormessage_1 += ' - ' + urlResponse;
                                }
                                _this.dataService.Auth_IsSessionAlive().subscribe(function () {
                                    Sentry.withScope(function (scope) {
                                        scope.setExtra('extra', error_1);
                                        Sentry.captureException(new Error(errormessage_1));
                                    });
                                }, function () {
                                    _this.sessionService.checkIsAliveError = true;
                                });
                            }
                        }
                        break;
                    case 404:
                        _this.modalService.alert('', _this.sessionService.errorMessage404, true);
                        break;
                    default:
                        if (_this.sessionService.checkIsAliveActive === true && error_1.status === 500) {
                            _this.sessionService.checkIsAliveActive = false;
                            break;
                        }
                        var message_1 = error_1.statusText;
                        if (error_1.url) {
                            message_1 += ' - ' + error_1.url;
                        }
                        Sentry.withScope(function (scope) {
                            scope.setExtra('extra', error_1);
                            Sentry.captureException(new Error(message_1));
                        });
                        break;
                }
            }
            ////return of([]);
            return throwError(err);
        }));
    };
    ErrorInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorInterceptor_Factory() { return new ErrorInterceptor(i0.ɵɵinject(i1.ModalService), i0.ɵɵinject(i2.BroadcastService), i0.ɵɵinject(i3.SessionService), i0.ɵɵinject(i4.DataService)); }, token: ErrorInterceptor, providedIn: "root" });
    return ErrorInterceptor;
}());
export { ErrorInterceptor };
