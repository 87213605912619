import * as tslib_1 from "tslib";
import { environment } from 'src/environments/environment';
import { FormComponentBase } from '../../../common/form-component-base';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
var CompanyContactComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyContactComponent, _super);
    function CompanyContactComponent(dataService, staticDataService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.staticDataService = staticDataService;
        _this.childIsDirty = Array(7).fill(false);
        _this.childIsValid = Array(3).fill(true);
        return _this;
    }
    Object.defineProperty(CompanyContactComponent.prototype, "resetChildFrom", {
        set: function (value) {
            if (value) {
                this.childIsDirty = Array(7).fill(false);
                this.childIsValid = Array(3).fill(true);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyContactComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyContactComponent.prototype, "currentLogo", {
        get: function () {
            return this.logoValue;
        },
        set: function (value) {
            if (value !== this.logoValue) {
                this.logoValue = value;
                this.logo = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    CompanyContactComponent.prototype.onCompanyLogoChanged = function (companyData) {
        var _this = this;
        if (Object.keys(companyData).length !== 0) {
            return;
        }
        this.dataService.Companies_UploadCompanyLogo(companyData).subscribe(function (data) {
            _this.logo = data;
            _this.currentLogo = data;
            _this.staticDataService.loadCurrentLogo();
            _this.isVisibleMessageAfterChangeLogo = true;
        }, function (error) {
            _this.logo = _this.currentLogo;
        });
    };
    return CompanyContactComponent;
}(FormComponentBase));
export { CompanyContactComponent };
