<app-grid
  [editMode]="false"
  [addNew]="false"
  [deleteRow]="false"
  [(data)]="timeEntryRecord"
  (selectedItemChange)="onSelectRow($event)"
  (gridButtonClick)="onGridButtonClick($event)"
  (clickAppGridIcon)="onClickAppGridIcon($event)"
  class="FormElement-grid Employee-timeEntryGrid {{ EmptyEmployeetimeApproval() }} Employee-timeEntryGrid--detail"
  [filterable]="'menu'"
  [filter]="approvalFilter"
  (filterChange)="onApprovalFilterChange($event)"
  [isCompanyApprovalDetail]="true"
>
  <app-grid-column
    [isFilterable]="true"
    [title]="'CompanySalaryBatches.OtherStatusGrid_EmployeeName' | translate"
    [type]="'text'"
    [field]="'EmployeeName'"
    [width]="0"
    [headerTooltip]="'CompanySalaryBatches.OtherStatusGrid_EmployeeName' | translate"
    [viewClass]="'truncated'"
    [tooltipField]="'EmployeeName'"
  ></app-grid-column>
  <app-grid-column
    [isFilterable]="true"
    [title]="'EmploymentTime.Type' | translate"
    [type]="'combobox'"
    [field]="'TimeEntryTypeId'"
    [comboboxDataSource]="TimeEntryTypes"
    [comboboxValueField]="'TimeEntryTypeId'"
    [comboboxDisplayField]="'Name'"
    [width]="150"
    [CurrentTextComboboxToolTip]="true"
    [headerTooltip]="'EmploymentTime.Type' | translate"
    [viewClass]="'truncated'"
  ></app-grid-column>
  <app-grid-column
    [isFilterable]="true"
    [title]="'EmploymentTime.EntryDate' | translate"
    [type]="'datepicker'"
    [field]="'EntryDate'"
    [width]="80"
    [headerTooltip]="'EmploymentTime.EntryDate' | translate"
  ></app-grid-column>
  <app-grid-column
    [isFilterable]="true"
    [title]="'EmploymentTime.EndDate' | translate"
    [type]="'datepicker'"
    [field]="'EndDate'"
    [minDate]="selectedMinDate"
    [width]="80"
    [headerTooltip]="'EmploymentTime.EndDate' | translate"
  >
  </app-grid-column>
  <app-grid-column
    [isFilterable]="true"
    [title]="'EmploymentTime.StartTime' | translate"
    [type]="'timepicker'"
    [field]="'StartTime'"
    [width]="55"
    [headerTooltip]="'EmploymentTime.StartTime' | translate"
  ></app-grid-column>
  <app-grid-column
    [isFilterable]="true"
    [title]="'EmploymentTime.EndTime' | translate"
    [type]="'timepicker'"
    [field]="'EndTime'"
    [width]="55"
    [headerTooltip]="'EmploymentTime.EndTime' | translate"
  ></app-grid-column>
  <app-grid-column
    [isFilterable]="true"
    [isFilterable]="true"
    [title]="'EmploymentTime.Units' | translate"
    [type]="'numeric'"
    [field]="'Units'"
    [numbericOption]="numberOption"
    [width]="55"
    [headerTooltip]="'EmploymentTime.Units' | translate"
  ></app-grid-column>
  <app-grid-column
    [isFilterable]="true"
    [title]="'EmploymentTime.UnitType' | translate"
    [type]="'combobox'"
    [field]="'UnitTypeId'"
    [comboboxDataSource]="UnitTypes"
    [comboboxValueField]="'Id'"
    [comboboxDisplayField]="'Name'"
    [width]="50"
    class="flexContainer"
    [CurrentTextComboboxToolTip]="false"
    [headerTooltip]="'EmploymentTime.UnitType' | translate"
  >
  </app-grid-column>
  <app-grid-column
    [isFilterable]="true"
    [title]="'CompanySalaryTypes.AmountPerUnit' | translate"
    [type]="'numeric'"
    [field]="'AmountPerUnit'"
    [numbericOption]="numberOption"
    [width]="50"
    [headerTooltip]="'CompanySalaryTypes.AmountPerUnit' | translate"
  ></app-grid-column>
  <app-grid-column
    [isFilterable]="true"
    [title]="'EmploymentTime.KilometersDriven' | translate"
    [type]="'numeric'"
    [numbericOption]="numberOption"
    [field]="'KilometersDriven'"
    [width]="70"
    [headerTooltip]="'EmploymentTime.KilometersDriven' | translate"
  ></app-grid-column>
  <app-grid-column
    [isFilterable]="true"
    [title]="'EmploymentTime.Description' | translate"
    [type]="'text'"
    [field]="'Description'"
    [viewClass]="'truncated'"
    [width]="125"
    [tooltipField]="'Description'"
    [headerTooltip]="'EmploymentTime.Description' | translate"
  ></app-grid-column>
  <app-grid-column
    [title]="'EmploymentTime.Status' | translate"
    [type]="'subProberty'"
    [field]="'Status'"
    [tooltipField]="'RejectionReason'"
    [subProberty]="'Status.Name'"
    [width]="110"
    [headerTooltip]="'EmploymentTime.Status' | translate"
  ></app-grid-column>
  <app-grid-column
    [isFilterable]="true"
    [title]="Dimension1Name"
    [type]="'text'"
    [field]="'Dimension1Value'"
    *ngIf="isHideDimension1"
    [width]="100"
  ></app-grid-column>
  <app-grid-column
    [isFilterable]="true"
    [title]="Dimension2Name"
    [type]="'text'"
    [field]="'Dimension2Value'"
    *ngIf="isHideDimension2"
    [width]="100"
  ></app-grid-column>
  <app-grid-column
    [isFilterable]="true"
    [title]="Dimension3Name"
    [type]="'text'"
    [field]="'Dimension3Value'"
    *ngIf="isHideDimension3"
    [width]="100"
  ></app-grid-column>
  <app-grid-column
    [title]="''"
    [type]="'icon'"
    [tooltip]="TooltipFroApproButtonAprrovalGrid | translate"
    [iconField]="'iconFeildApprove'"
    [iconAction]="'ApproveSingleRecord'"
    [width]="30"
    class="approval-grid-icon"
    [disableButtonFeild]="'isDisbaleSingleApprove'"
    [disableGridButtonValue]="!allowManagersToApproveTimeEntry"
    [disableGridButtonTooltip]="allowManagersToApproveTimeEntryMessage"
  ></app-grid-column>
  <app-grid-column
    [title]="''"
    [type]="'button'"
    [buttonToottip]="'EmploymentTime.Reject'"
    [buttonLabel]="'X'"
    [buttonClass]="'k-button k-button-icontext deleteButtonColumn k-grid-XCustoom'"
    [buttonAction]="'Reject'"
    [width]="30"
    class="approval-grid-icon"
    [disableButtonFeild]="'isDisbaleReject'"
    [disableGridButtonValue]="!allowManagersToApproveTimeEntry"
    [disableGridButtonTooltip]="allowManagersToApproveTimeEntryMessage"
  ></app-grid-column>
</app-grid>

<app-dialog [overlayClose]="false" [(visible)]="isShowRejectDialog" [width]="'500'" (action)="onRejectDialog($event)">
  <div innerHTML="{{ 'EmploymentTime.RejectionWarning' | translate }}"></div>
  <app-text-edit [label]="''" [editMode]="true" [(value)]="rejectMessage"></app-text-edit>
  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action type="RejectPrimary" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>
