import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { CustomControlsModule } from '../custom-controls/custom-controls.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { SelfServicePayslipComponent } from './payslip/self-service-payslip.component';
import { SelfServiceComponent } from './self-service.component';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule, CustomControlsModule, SharedComponentsModule],
  declarations: [SelfServiceComponent, SelfServicePayslipComponent],
  exports: [SelfServiceComponent],
  entryComponents: [SelfServiceComponent]
})
export class SelfServiceModule {}
