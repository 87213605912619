import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Constants } from '../../common/constants';
import { FormComponentBase } from '../../common/form-component-base';
import { Global } from '../../common/global';
import { IBaseCalculationMethod, IPensionProvider } from '../../services/api/api-model';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { PensionProviderModel } from './pension-provider-model';

@Component({
  selector: 'app-pension-provider',
  styleUrls: ['./pension-provider.component.scss'],
  templateUrl: './pension-provider.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PensionProviderComponent extends FormComponentBase implements OnInit {
  @Input() firstPension: boolean;
  @Input() disable = false;
  @Input() public disableBySecondaryIncomeType: boolean;

  public numericOptions: any = {
    format: 'n0',
    decimals: 0,
    step: 0,
    min: null,
    spinners: false
  };

  public get branding() {
    return environment.branding;
  }

  private modelValue: PensionProviderModel;
  @Input()
  public get model(): PensionProviderModel {
    if (!this.modelValue) {
      this.modelValue = {} as any;
    }

    return this.modelValue;
  }
  public set model(value: PensionProviderModel) {
    this.modelValue = value;
    this.updateDefaultComponentValue();
    this.modelChange.emit(value);
  }
  @Output() modelChange: EventEmitter<PensionProviderModel> = new EventEmitter<PensionProviderModel>();

  public pensionProviders: IPensionProvider[];
  public showPensionProvider = false;
  public PensionProviderNetsNo: string;
  public pensionData: any;

  constructor(private sessionService: SessionService, public staticDataService: StaticDataService) {
    super();
    this.childIsDirty = Array(12).fill(false);
    this.childIsValid = Array(2).fill(true);
    this.editModeChange.subscribe((value: boolean) => {
      if (this.model) {
        this.updateDefaultComponentValue();
      }
    });
  }

  public ngOnInit(): void {
    this.staticDataService.PensionProvider.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IPensionProvider[]) => {
      this.pensionProviders = data;
      this.updateDefaultComponentValue();
    });

    this.staticDataService.PensionBaseCalculationMethod.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (baseCalculationMethods: IBaseCalculationMethod[]) => {
        this.pensionData = baseCalculationMethods.filter((pension: any) => {
          return pension.IsPensionBase;
        });
        if (this.pensionData.length > 0 && !this.PensionBaseCalculationMethodId) {
          this.PensionBaseCalculationMethodId = 1;
        }
      }
    );
  }

  public get isHaveDueDate(): boolean {
    return this.sessionService.currentState.includes('tabs.company.configuration');
  }

  private get isEmployeePension(): boolean {
    return this.model && (this.model as any).HireDate;
  }

  public get isNoPensionTextHidden(): boolean {
    return this.editMode || this.showPensionProvider;
  }

  public get isShowBankInfo(): boolean {
    return this.SelectedPensionProvider && this.SelectedPensionProvider.AccountNumberRequired;
  }

  public get isOverridePolicyNo(): boolean {
    return this.SelectedPensionProvider && this.SelectedPensionProvider.PolicyNumberLabelOverride;
  }

  private get isDanishCompany() {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
  }

  public get isVisiblePensionTakenSalary(): boolean {
    if (
      this.isDanishCompany &&
      (!this.model.IncomeTypeId ||
        (this.model.IncomeTypeId && this.model.IncomeTypeId !== 8 && this.model.IncomeTypeId !== 99))
    ) {
      return true;
    }
    return false;
  }

  public get isAddButtonHidden(): boolean {
    return (
      !this.editMode ||
      this.showPensionProvider ||
      (this.isEmployeePension && !this.sessionService.feature.AllowEditUserEmployment(this.sessionService.currentState))
    );
  }

  public get SelectedPensionProvider(): any {
    const pensionProvider: any = this.pensionProviders
      ? this.pensionProviders.find((ppd: any) => {
          return ppd.Id === this.pensionProviderId;
        })
      : undefined;

    return pensionProvider;
  }

  public get pensionTaken(): boolean {
    return this.firstPension ? this.model.PensionIsNotTaxDeductible : this.model.Pension2IsNotTaxDeductible;
  }

  public set pensionTaken(value: boolean) {
    if (this.firstPension) {
      this.model.PensionIsNotTaxDeductible = value;
    } else {
      this.model.Pension2IsNotTaxDeductible = value;
    }
  }

  public get pensionProviderId(): number {
    return this.firstPension ? this.model.PensionProviderId : this.model.Pension2ProviderId;
  }
  public set pensionProviderId(value: number) {
    value = value ? this.sessionService.parseInt(value.toString()) : null;
    if (this.firstPension) {
      this.model.PensionProviderId = value;
      this.model.Pension2ProviderId = this.model.Pension2ProviderId || null;
    } else {
      this.model.PensionProviderId = this.model.PensionProviderId || null;
      this.model.Pension2ProviderId = value;
    }
  }

  public get PensionProviderBankRegNo(): string {
    return this.firstPension ? this.model.PensionProviderBankRegNo : this.model.Pension2ProviderBankRegNo;
  }
  public set PensionProviderBankRegNo(value: string) {
    if (this.firstPension) {
      this.model.PensionProviderBankRegNo = value;
    } else {
      this.model.Pension2ProviderBankRegNo = value;
    }
  }

  public get PensionProviderBankAccountNo(): string {
    return this.firstPension ? this.model.PensionProviderBankAccountNo : this.model.Pension2ProviderBankAccountNo;
  }
  public set PensionProviderBankAccountNo(value: string) {
    if (this.firstPension) {
      this.model.PensionProviderBankAccountNo = value;
    } else {
      this.model.Pension2ProviderBankAccountNo = value;
    }
  }

  public get PensionStartDate(): Date {
    return this.firstPension ? this.model.PensionStartDate : this.model.Pension2StartDate;
  }

  public set PensionStartDate(value: Date) {
    if (this.firstPension) {
      this.model.PensionStartDate = value;
    } else {
      this.model.Pension2StartDate = value;
    }
  }

  public get PensionEndDate(): Date {
    return this.firstPension ? this.model.PensionEndDate : this.model.Pension2EndDate;
  }

  public set PensionEndDate(value: Date) {
    if (this.firstPension) {
      this.model.PensionEndDate = value;
    } else {
      this.model.Pension2EndDate = value;
    }
  }

  public get PensionFixedAmountIsPerDay(): boolean {
    return this.firstPension ? this.model.PensionFixedAmountIsPerDay : this.model.Pension2FixedAmountIsPerDay;
  }

  public set PensionFixedAmountIsPerDay(value: boolean) {
    if (this.firstPension) {
      this.model.PensionFixedAmountIsPerDay = value;
    } else {
      this.model.Pension2FixedAmountIsPerDay = value;
    }
  }

  public get PensionBaseCalculationMethodId(): number {
    return this.firstPension ? this.model.PensionBaseCalculationMethodId : this.model.Pension2BaseCalculationMethodId;
  }
  public set PensionBaseCalculationMethodId(value: number) {
    value = value ? this.sessionService.parseInt(value.toString()) : undefined;
    if (this.firstPension) {
      this.model.PensionBaseCalculationMethodId = value;
    } else {
      this.model.Pension2BaseCalculationMethodId = value;
    }
  }

  public get PensionEmployeeShare(): number {
    return this.firstPension ? this.model.PensionEmployeeShare : this.model.Pension2EmployeeShare;
  }
  public set PensionEmployeeShare(value: number) {
    if (this.firstPension) {
      this.model.PensionEmployeeShare = value;
    } else {
      this.model.Pension2EmployeeShare = value;
    }
  }

  public get PensionCompanyShare(): number {
    return this.firstPension ? this.model.PensionCompanyShare : this.model.Pension2CompanyShare;
  }
  public set PensionCompanyShare(value: number) {
    if (this.firstPension) {
      this.model.PensionCompanyShare = value;
    } else {
      this.model.Pension2CompanyShare = value;
    }
  }

  public get PensionEmployeeAmount(): number {
    return this.firstPension ? this.model.PensionEmployeeAmount : this.model.Pension2EmployeeAmount;
  }
  public set PensionEmployeeAmount(value: number) {
    if (this.firstPension) {
      this.model.PensionEmployeeAmount = value;
    } else {
      this.model.Pension2EmployeeAmount = value;
    }
  }

  public get PensionCompanyAmount(): number {
    return this.firstPension ? this.model.PensionCompanyAmount : this.model.Pension2CompanyAmount;
  }
  public set PensionCompanyAmount(value: number) {
    if (this.firstPension) {
      this.model.PensionCompanyAmount = value;
    } else {
      this.model.Pension2CompanyAmount = value;
    }
  }

  public get PensionPolicyNumber(): string {
    return this.firstPension ? this.model.PensionPolicyNumber : this.model.Pension2PolicyNumber;
  }
  public set PensionPolicyNumber(value: string) {
    if (this.firstPension) {
      this.model.PensionPolicyNumber = value;
    } else {
      this.model.Pension2PolicyNumber = value;
    }
  }

  public get PensionDueDateDelayDays(): number {
    return this.firstPension ? this.model.Pension1DueDateDelayDays : this.model.Pension2DueDateDelayDays;
  }
  public set PensionDueDateDelayDays(value: number) {
    if (this.firstPension) {
      this.model.Pension1DueDateDelayDays = value;
    } else {
      this.model.Pension2DueDateDelayDays = value;
    }
  }

  private updateDefaultComponentValue(): void {
    this.showPensionProvider = this.model && !!this.pensionProviderId;
    const pensionProvider: any = this.pensionProviders
      ? this.pensionProviders.find((pension: any) => pension.Id === this.pensionProviderId)
      : undefined;
    this.PensionProviderNetsNo = pensionProvider ? pensionProvider.NetsNo : undefined;
  }

  public onPensionProviderChanged(): void {
    this.enablePensionProviderValues();
    this.onChange();
  }

  private enablePensionProviderValues(): void {
    setTimeout(() => {
      const pensionProvider: any = this.pensionProviders
        ? this.pensionProviders.find((pension: any) => pension.Id === this.pensionProviderId)
        : undefined;
      this.PensionProviderNetsNo = pensionProvider ? pensionProvider.NetsNo : undefined;
      if (pensionProvider) {
        if (this.firstPension) {
          this.model.PensionBaseCalculationMethodId = pensionProvider.DefaultBaseCalculationMethodId;
        } else {
          this.model.Pension2BaseCalculationMethodId = pensionProvider.DefaultBaseCalculationMethodId;
        }
      }
    });
  }

  public onAddFirstPensionProvider(): void {
    if (!this.editMode || this.disable) {
      return;
    }

    this.showPensionProvider = true;
  }
}
