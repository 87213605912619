import { Injector } from '@angular/core';
import { Ng2StateDeclaration } from '@uirouter/angular';
import { UIRouter } from '@uirouter/core';
import { AccountantComponent } from './accountant/accountant.component';
import { CompanyComponent } from './company/company.component';
import { EmployeeComponent } from './employee/employee.component';
import { AccountComponent } from './main/account.component';
import { LoginComponent } from './main/login/login.component';
import { TabsComponent } from './main/tabs.component';
import { SelfServiceComponent } from './self-service/self-service.component';
// import { StateTree } from "@uirouter/visualizer";

export function routerConfig(router: UIRouter, injector: Injector): void {
  router.urlService.rules.otherwise({ state: 'login' });
  // trace.enable();
  // StateTree.create(router, document.getElementById("statetree"), { width: 500, height: 200 });
  // console.log("routerConfig");
}

const routerStates: Ng2StateDeclaration[] = [
  {
    name: 'login',
    url: '/login/:languge',
    component: LoginComponent,
    params: {
      languge: null
    }
  },
  { name: 'tabs', redirectTo: '', component: TabsComponent },

  // Account
  { name: 'tabs.account', url: '/account', component: AccountComponent },
  { name: 'tabs.account.userpreferences', url: '/userpreferences' },

  // Employee
  { name: 'tabs.employee', url: '/employee', component: EmployeeComponent },
  { name: 'tabs.employee.general', url: '/general' },
  { name: 'tabs.employee.hiringstatus', url: '/hiringstatus' },
  { name: 'tabs.employee.time', url: '/time' },
  { name: 'tabs.employee.payrolldata', url: '/payrolldata' },
  { name: 'tabs.employee.payslip', url: '/payslip' },

  // Company
  { name: 'tabs.company', url: '/company', component: CompanyComponent },
  { name: 'tabs.company.general', url: '/general' },
  { name: 'tabs.company.payrolldata', url: '/payrolldata' },
  { name: 'tabs.company.salarybatches', url: '/salarybatches' },
  { name: 'tabs.company.balances', url: '/balances' },
  { name: 'tabs.company.balances.residualvacation', url: '/residualvacation' },
  { name: 'tabs.company.balances.extravacationhours', url: '/extravacationhours' },
  { name: 'tabs.company.approval', url: '/approval' },
  { name: 'tabs.company.approval.timeentry', url: '/timeentry' },
  { name: 'tabs.company.configuration', url: '/configuration' },
  { name: 'tabs.company.configuration.hiringstatus', url: '/employmenttemplates' },
  { name: 'tabs.company.configuration.departments', url: '/departments' },
  { name: 'tabs.company.configuration.integrations', url: '/integrations' },
  { name: 'tabs.company.configuration.dataimport', url: '/dataimport' },
  { name: 'tabs.company.configuration.settings', url: '/settings' },
  { name: 'tabs.company.configuration.advancedtypes', url: '/advancedtypes' },
  { name: 'tabs.company.configuration.advancedtypes.salarytypessingle', url: '/salarytypessingle' },
  { name: 'tabs.company.configuration.advancedtypes.advancedsalarytypes', url: '/advancedsalarytypes' },
  { name: 'tabs.company.configuration.advancedtypes.timeentrytypessingle', url: '/timeentrytypessingle' },
  { name: 'tabs.company.configuration.advancedtypes.timeentrytypes', url: '/timeentrytypes' },
  { name: 'tabs.company.configuration.dimensions', url: '/dimensions' },
  { name: 'tabs.company.modules', url: '/modules' },

  // Self Service
  { name: 'tabs.selfservice', url: '/selfservice', component: SelfServiceComponent },
  { name: 'tabs.selfservice.general', url: '/general' },
  { name: 'tabs.selfservice.payslip', url: '/payslip' },
  { name: 'tabs.selfservice.time', url: '/time' },

  // Accountant
  { name: 'tabs.accountant', url: '/accountant', component: AccountantComponent },
  { name: 'tabs.accountant.companies', url: '/companies' },
  { name: 'tabs.accountant.users', url: '/users' },
  { name: 'tabs.accountant.configuration', url: '/configuration' }
];

export { routerStates };
