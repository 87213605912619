import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StaticDataService } from '../../../services/api/static-data.service';
import { SessionService } from '../../../services/session/session.service';
var BenefitsComponent = /** @class */ (function () {
    function BenefitsComponent(staticDataService, sessionService) {
        this.staticDataService = staticDataService;
        this.sessionService = sessionService;
        this.modules = ([]);
        this.isEmployeeBenefitModuleActive = false;
        this.ngUnsubscribe = new Subject();
    }
    BenefitsComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(BenefitsComponent.prototype, "isIOSApp", {
        get: function () {
            return this.sessionService.browser.isHybridApp && this.sessionService.browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    BenefitsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.moduleCompanyView
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) {
            _this.modules = JSON.parse(JSON.stringify(data));
            if (_this.modules && _this.modules.length > 0) {
                var benefitModule = _this.modules.find(function (model) { return model.ModuleId === 6; });
                if (benefitModule) {
                    _this.isEmployeeBenefitModuleActive = benefitModule.IsEnabled;
                }
            }
        });
    };
    return BenefitsComponent;
}());
export { BenefitsComponent };
