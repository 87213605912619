<app-dialog
  (action)="onCreateNewDepartment($event)"
  [(visible)]="isVisible"
  [title]="'EmployeeGeneral.AddDepartment' | translate"
  [width]="500"
>
  <app-text-edit
    [editMode]="true"
    [(value)]="ModelDepartment.Name"
    [label]="'CompanyGeneral.DepartmentName' | translate"
  >
  </app-text-edit>
  <app-combobox-edit
    [editMode]="true"
    [textField]="'FullName'"
    [idField]="'Id'"
    [comboboxDataSource]="companyContacts"
    [(value)]="ModelDepartment.ManagerCompanyUserId"
    [label]="'CompanyGeneral.Manager' | translate"
    [nullValueLabel]="'DropdownList.None' | translate"
  >
  </app-combobox-edit>
  <app-text-edit
    [editMode]="true"
    [(value)]="ModelDepartment.ExternalReference"
    [label]="'CompanyGeneral.ERPref' | translate"
  >
  </app-text-edit>
  <app-text-edit
    [editMode]="true"
    [(value)]="ModelDepartment.EIncomeReference"
    [label]="'CompanyGeneral.EIncomeReference' | translate"
  >
  </app-text-edit>
  <app-dialog-action type="Create"></app-dialog-action>
</app-dialog>
