<div class="company-general" [ngClass]="companyGeneralService.editMode ? 'is-editing' : ''">
  <app-fixed-panel
    [excludedComponents]="listClassExcluded"
    [editMode]="companyGeneralService.editMode"
    [specificClassName]="'Island-general'"
  >
    <div class="Island Island-general" (dblclick)="onDoubleClick()">
      <div class="Cards Cards--packed Cards--packed max2col">
        <div class="Card">
          <div class="Card-inner">
            <app-company-contact
              [editMode]="companyGeneralService.editMode"
              [contacts]="companyGeneralService.companyContact | async"
              [company]="companyGeneralService.currentCompany"
              (valueChange)="onChange()"
              [(isValid)]="childIsValid[0]"
              [(isDirty)]="childIsDirty[0]"
              [currentLogo]="companyGeneralService.companyLogo | async"
              [resetChildFrom]="resetChildFrom"
            >
            </app-company-contact>
          </div>
        </div>
        <div class="Card">
          <div class="Card-inner">
            <app-company-data
              [editMode]="companyGeneralService.editMode"
              [company]="companyGeneralService.currentCompany"
              (valueChange)="onChange()"
              [(isValid)]="childIsValid[1]"
              [(isDirty)]="childIsDirty[1]"
              [resetChildFrom]="resetChildFrom"
            >
            </app-company-data>
          </div>
        </div>
        <div class="Card">
          <div class="Card-inner">
            <app-company-option
              [editMode]="companyGeneralService.editMode"
              [company]="companyGeneralService.currentCompany"
              [contacts]="companyGeneralService.companyContact | async"
              (valueChange)="onChange()"
              [(isValid)]="childIsValid[2]"
              [(isDirty)]="childIsDirty[2]"
              [(resetChildFrom)]="resetChildFrom"
            >
            </app-company-option>
          </div>
        </div>

        <div class="Card MultiCompanyOrganization" *ngIf="sessionService.role.IsFullAdmin">
          <div class="Card-inner">
            <div class="Company-Form">
              <fieldset class="Form--horizontal" *ngIf="sessionService.feature.IsMultiCompanyMember">
                <legend class="Card-title">
                  {{ 'CompanyGeneral.MultiCompanyOrganization' | translate }}
                </legend>
                <div>
                  <app-accountant-summary></app-accountant-summary>
                </div>
              </fieldset>
              <div *ngIf="!sessionService.feature.IsMultiCompanyMember">
                <app-button
                  *ngIf="!sessionService.role.IsReadOnly"
                  class="Button--card"
                  (clickEvent)="openJoinGridDialog = true"
                  [label]="'Accountant.Join' | translate"
                >
                </app-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-fixed-panel>

  <div
    class="Actions"
    *ngIf="!companyGeneralService.isMobile && !companyGeneralService.editMode"
    [ngClass]="companyGeneralService.isIOSApp ? 'is-ios-app' : ''"
  >
    <app-menu-button
      (menuClick)="onMoreMenuOptionClick($event)"
      [menuIcon]="'ThreeDots'"
      [menuLabel]="'More.Title'"
      [menuTooltip]="'More.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item (menuItemClick)="onMoreMenuOptionClick($event)" [field]="'ImportData'">
      </app-menu-button-item>
      <app-menu-button-item (menuItemClick)="onMoreMenuOptionClick($event)" [field]="'TestEmails'">
      </app-menu-button-item>
    </app-menu-button>

    <app-menu-button
      *ngIf="reportDialogService.isVisibleReportIcon"
      [menuCondition]="true"
      (menuClick)="reportDialogService.onShowReportsEventClick()"
      [menuIcon]="'File'"
      [menuLabel]="'Company.Reports'"
      [menuTooltip]="'Company.Reports'"
      [actionButtonMenu]="true"
      [menuButtonClass]="'Action-button Action-buttonReports'"
      [menuButtonId]="'ActionButtonReports'"
    >
    </app-menu-button>

    <app-menu-button
      [menuIcon]="'Help'"
      [menuLabel]="'Help.Title'"
      [menuTooltip]="'Help.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
      <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
    </app-menu-button>
    <app-menu-button
      [editButtonMenu]="true"
      (menuChangeClick)="onEditModeChange($event)"
      [menuCondition]="sessionService.role.IsFullAdmin"
      [editMode]="companyGeneralService.editMode"
    >
    </app-menu-button>
  </div>

  <div
    class="Actions"
    *ngIf="companyGeneralService.isMobile && !companyGeneralService.editMode"
    [ngClass]="companyGeneralService.isIOSApp ? 'is-ios-app' : ''"
  >
    <app-menu-button
      (menuClick)="onMoreMenuOptionClick($event)"
      [menuIcon]="'ThreeDots'"
      [menuLabel]="'More.Title'"
      [menuTooltip]="'More.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item (menuItemClick)="onMoreMenuOptionClick($event)" [field]="'ImportData'">
      </app-menu-button-item>

      <app-menu-button-item (menuItemClick)="onMoreMenuOptionClick($event)" [field]="'TestEmails'">
      </app-menu-button-item>

      <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
      <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
    </app-menu-button>
    <app-menu-button
      [editButtonMenu]="true"
      (menuChangeClick)="onEditModeChange($event)"
      [menuCondition]="sessionService.role.IsFullAdmin"
      [editMode]="companyGeneralService.editMode"
    >
    </app-menu-button>
  </div>

  <div class="EditingInfo Actions" *ngIf="companyGeneralService.editMode">
    <app-button
      [buttonClass]="'Discard'"
      (clickEvent)="confirmDiscardChanges()"
      [label]="'Employee.DiscardButton' | translate"
      *ngIf="companyGeneralService.editMode"
    ></app-button>
    <app-menu-button
      [editButtonMenu]="true"
      [editMode]="true"
      (menuChangeClick)="onEditModeChange($event)"
      [menuCondition]="true"
      class="menu-button--editing"
    >
    </app-menu-button>
  </div>
</div>

<app-dialog
  (action)="discardDialogAction($event)"
  [(visible)]="discardDialogVisible"
  [leadingText]="'Employee.DiscardMessage' | translate"
>
  <app-dialog-action [type]="'CancelDiscard'"></app-dialog-action>
  <app-dialog-action [type]="'ConfirmDiscard'"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="companyGeneralService.invalidDialogVisible"
  (action)="ChangeState()"
  [leadingText]="'Validation.InvalidForm' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" [(visible)]="showInvalidFormWarning">
  <div [innerHTML]="'Validation.InvalidForm' | translate"></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="companyGeneralService.onConfirmSaveDataOnChangeState($event)"
  [(visible)]="companyGeneralService.confirmUpdateDialogVisible"
>
  <div [innerHTML]="'Employee.UnsavedData' | translate"></div>
  <app-dialog-action [type]="'DiscardAndLeave'"></app-dialog-action>
  <app-dialog-action [type]="'Continue'"></app-dialog-action>
  <app-dialog-action [type]="'Stay'"></app-dialog-action>
</app-dialog>

<app-test-email [(visible)]="isShowtestEmailDialog"></app-test-email>
<app-accountant-join [(visible)]="openJoinGridDialog"></app-accountant-join>
