import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-salary-batch-item-finalization',
  templateUrl: './salary-batch-item-finalization.compone.html',
  styleUrls: ['./salary-batch-item-finalization.compone.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SalaryBatchItemFinalizationComponent {
  constructor() {}

  @Input() public title = '';
  @Input() public start = false;
  @Input() public complete = false;
  @Input() public error = false;
}
