import { version } from '../../../version';
import { SessionService } from '../session/session.service';
import * as i0 from "@angular/core";
import * as i1 from "../session/session.service";
var AuthenticationTokenInterceptor = /** @class */ (function () {
    function AuthenticationTokenInterceptor(sessionService) {
        this.sessionService = sessionService;
    }
    AuthenticationTokenInterceptor.prototype.intercept = function (request, next) {
        try {
            var authorizationToken = sessionStorage.getItem('token');
            if (authorizationToken) {
                var client = this.sessionService.browser.isHybridApp ? 'MobileClient' : 'WebClient';
                var headers = request.headers
                    .set('Authorization', authorizationToken)
                    .set('Intect-Client', client)
                    .set('Intect-Version', version);
                request = request.clone({ headers: headers });
            }
            return next.handle(request);
        }
        catch (e) {
            this.sessionService.isDetectedCookieDisable = true;
            return undefined;
        }
    };
    AuthenticationTokenInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationTokenInterceptor_Factory() { return new AuthenticationTokenInterceptor(i0.ɵɵinject(i1.SessionService)); }, token: AuthenticationTokenInterceptor, providedIn: "root" });
    return AuthenticationTokenInterceptor;
}());
export { AuthenticationTokenInterceptor };
