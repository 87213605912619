import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../common/constants';
import { IChangePasswordRequest } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { BroadcastService } from '../../services/broadcast.service';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html'
})
export class ChangePasswordDialogComponent implements OnInit, OnDestroy {
  public currentPass: string;
  public newPass: string;
  public isChangePassworSuccessDialog = false;
  public isChangePassworFailDialog = false;
  public userName: string;
  private isVisibleValue = false;
  @Input()
  public get isVisible(): boolean {
    return this.isVisibleValue;
  }
  public set isVisible(value: boolean) {
    if (this.isVisibleValue !== value) {
      if (value) {
        this.currentPass = '';
        this.newPass = '';
      }

      this.isVisibleValue = value;
      this.isVisibleChange.emit(value);
    }
  }
  @Output() public isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private dataService: DataService,
    private broadcaster: BroadcastService,
    public sessionService: SessionService
  ) {}

  public ngOnInit(): void {
    this.userName = localStorage.getItem('saveLoginUserName');
    this.broadcaster
      .on<any>(Constants.ACCOUNT_CHANGE_PASSWORD)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((event: any) => (this.isVisible = true));
  }

  public ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public keydown(e: any): void {
    if (e.keyCode !== 13) {
      return;
    }

    this.onChangePassword();
  }

  public onChangePasswordDialogAction(action: string): void {
    if (action === 'ChangePassword') {
      this.onChangePassword();
    } else {
      this.isVisible = false;
    }
  }

  private onChangePassword(): void {
    const CurrentPassword = this.currentPass;
    const NewPassword = this.newPass;
    if (CurrentPassword && NewPassword) {
      const request: IChangePasswordRequest = { CurrentPassword, NewPassword };
      this.dataService.Auth_ChangePassword(request).subscribe(
        () => {
          this.isChangePassworSuccessDialog = true;
        },
        () => {
          this.isVisible = false;
        }
      );
    } else {
      this.isChangePassworFailDialog = true;
      this.isVisible = false;
    }
  }

  public onChangePasswordDialogSuccessAction(action: string): void {
    if (action === 'Ok') {
      this.isVisible = false;
    }
  }

  public onChangePasswordDialogFailAction(action: string): void {
    if (action === 'Ok') {
      this.isChangePassworFailDialog = false;
    }
  }
}
