<kendo-dialog
  *ngIf="visible && safeContent"
  id="iframeDialog"
  [title]="title"
  [width]="960"
  [height]="700"
  (close)="visible = false"
  (keydown)="onkeydown($event)"
  #kendoDialog
>
  <iframe
    *ngIf="!isEdge"
    #iframeInDialog
    [srcdoc]="safeContent"
    src="about:blank"
    class="k-content-frame"
    width="960"
    height="700"
    (load)="iframeLoaded()"
  >
  </iframe>
  <iframe
    *ngIf="isEdge"
    #iframeInDialog
    class="k-content-frame"
    width="960"
    height="700"
    (load)="iframeLoaded()"
  ></iframe>
</kendo-dialog>
