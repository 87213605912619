/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fixed-panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../feedback/feedback.component.ngfactory";
import * as i3 from "../feedback/feedback.component";
import * as i4 from "../../services/api/data.service";
import * as i5 from "../../services/session/session.service";
import * as i6 from "../../services/setting.service";
import * as i7 from "./fixed-panel.component";
import * as i8 from "../../services/fixed-panel.service";
var styles_FixedPanelComponent = [i0.styles];
var RenderType_FixedPanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FixedPanelComponent, data: {} });
export { RenderType_FixedPanelComponent as RenderType_FixedPanelComponent };
export function View_FixedPanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "fixed-panel-container"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-feedback", [], [[8, "hidden", 0]], null, null, i2.View_FeedbackComponent_0, i2.RenderType_FeedbackComponent)), i1.ɵdid(3, 114688, null, 0, i3.FeedbackComponent, [i4.DataService, i5.SessionService, i6.SettingService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.editMode || _co.hideFooter); _ck(_v, 2, 0, currVal_0); }); }
export function View_FixedPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fixed-panel", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_FixedPanelComponent_0, RenderType_FixedPanelComponent)), i1.ɵdid(1, 4767744, null, 0, i7.FixedPanelComponent, [i8.FixedPanelService], null, null)], null, null); }
var FixedPanelComponentNgFactory = i1.ɵccf("app-fixed-panel", i7.FixedPanelComponent, View_FixedPanelComponent_Host_0, { excludedComponents: "excludedComponents", specificClassName: "specificClassName", editMode: "editMode", hideFooter: "hideFooter" }, {}, ["*"]);
export { FixedPanelComponentNgFactory as FixedPanelComponentNgFactory };
