import { SessionService } from './session/session.service';
import * as i0 from "@angular/core";
import * as i1 from "./session/session.service";
var FixedPanelService = /** @class */ (function () {
    function FixedPanelService(sessionService) {
        this.sessionService = sessionService;
        this.excludedComponents = [];
        this.specificClassName = '';
    }
    Object.defineProperty(FixedPanelService.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FixedPanelService.prototype, "isHybridApp", {
        get: function () {
            return this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FixedPanelService.prototype, "iOSMobileDevice", {
        get: function () {
            return this.sessionService.browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FixedPanelService.prototype, "isSafari", {
        get: function () {
            return this.sessionService.browser.isSafari;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FixedPanelService.prototype, "isIphoneNotch", {
        get: function () {
            return this.sessionService.browser.isIphoneNotch;
        },
        enumerable: true,
        configurable: true
    });
    FixedPanelService.prototype.calculateHeightContent = function (isEditMode) {
        var _this = this;
        var pageHeight = document.querySelector('.fixed-panel-container .Island');
        if (this.specificClassName) {
            pageHeight = document.querySelector('.fixed-panel-container ' + '.' + this.specificClassName);
        }
        if (!pageHeight) {
            setTimeout(function () {
                _this.calculateHeightContent();
            }, 1000);
            return;
        }
        var windowHeight = window.innerHeight;
        var windowWidth = window.innerWidth;
        var totalExcludedHeight = 0;
        this.excludedComponents.forEach(function (component) {
            var componentElement = document.getElementsByClassName(component)[0];
            if (componentElement) {
                totalExcludedHeight += componentElement.clientHeight;
            }
            var isExtraExcluded = parseInt(component, 10);
            if (!Number.isNaN(isExtraExcluded)) {
                totalExcludedHeight += isExtraExcluded;
            }
        });
        // Add header and tabpanel to exclude for all tab
        var headerComponent = document.getElementsByClassName('Header')[0];
        totalExcludedHeight += headerComponent ? headerComponent.clientHeight : 0;
        var tabPanelComponent = document.getElementsByClassName('TabPanel')[0];
        totalExcludedHeight += tabPanelComponent ? tabPanelComponent.clientHeight : 40;
        var footerComponent = document.getElementsByClassName('Footer-info')[0];
        var footerHeight = 10;
        if (windowWidth < 1400) {
            footerHeight = 35;
        }
        totalExcludedHeight += footerComponent ? footerHeight : 0;
        // End for all tab
        if (this.specificClassName === 'Island-employee--payroll' && (this.isHybridApp || this.isMobile)) {
            totalExcludedHeight += 105; //Employee payroll data, only for mobile
        }
        if (this.specificClassName === 'Island-employee--general' && (this.isHybridApp || this.isMobile)) {
            totalExcludedHeight += 30; //Employee general, only for mobile
        }
        if (this.specificClassName === 'Island-employee--payslip' && (this.isHybridApp || this.isMobile)) {
            totalExcludedHeight += 20; //Employee payslip, only for mobile
        }
        pageHeight.style.height = windowHeight - totalExcludedHeight + 'px';
    };
    FixedPanelService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FixedPanelService_Factory() { return new FixedPanelService(i0.ɵɵinject(i1.SessionService)); }, token: FixedPanelService, providedIn: "root" });
    return FixedPanelService;
}());
export { FixedPanelService };
