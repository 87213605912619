<form>
  <fieldset class="Form--horizontal">
    <legend class="Card-title">{{ 'EmployeeEmployment.IllnessAbsence' | translate }}</legend>

    <app-combobox-edit
      *ngIf="!IsUserEmployment"
      [label]="'EmployeeEmployment.TimeEntryRecordAcrossSalaryPeriodStrategy' | translate"
      [editMode]="editMode"
      [textField]="'Name'"
      [idField]="'Id'"
      [comboboxDataSource]="timeEntrySplitStrategy"
      [(value)]="model.TimeEntryRecordAcrossSalaryPeriodStrategy"
      [(isDirty)]="childIsDirty[4]"
      [(isValid)]="childIsValid[4]"
      (valueChange)="onChange()"
      [tooltipContent]="'EmployeeEmployment.TimeEntryRecordAcrossSalaryPeriodStrategyTooltip' | translate"
    >
    </app-combobox-edit>

    <app-check-edit
      *ngIf="!isSwedenCompany"
      [(isDirty)]="childIsDirty[0]"
      [(isValid)]="childIsValid[0]"
      [label]="'EmployeeEmployment.IllnessReducesSalary' | translate"
      [(value)]="model.IllnessReducesSalary"
      [editMode]="editMode"
      (valueChange)="onChange()"
    ></app-check-edit>

    <app-numeric-edit
      *ngIf="!isShowSpecifyWeekDay"
      [(isDirty)]="childIsDirty[1]"
      [(isValid)]="childIsValid[1]"
      [label]="'EmployeeEmployment.StandardWorkHoursPerWeek' | translate"
      [editMode]="editMode"
      [(value)]="model.StandardHoursPerWeek"
      (valueChange)="onChange()"
    ></app-numeric-edit>

    <app-numeric-edit
      *ngIf="!isShowSpecifyWeekDay"
      [(isDirty)]="childIsDirty[2]"
      [(isValid)]="childIsValid[2]"
      [label]="'EmployeeEmployment.StandardWorkDaysPerWeek' | translate"
      [editMode]="editMode"
      [(value)]="model.StandardWorkDaysPerWeek"
      (valueChange)="onChange()"
    ></app-numeric-edit>

    <app-button
      *ngIf="!isShowSpecifyWeekDay"
      class="Button--link"
      (clickEvent)="showSpecifyWeekday()"
      [label]="'EmployeeEmployment.WorkdayLink' | translate"
    >
    </app-button>

    <app-module-check [moduleId]="8" *ngIf="!isSwedenCompany">
      <app-numeric-edit
        [label]="'EmployeeEmployment.Defaulthourlyrate' | translate"
        [editMode]="editMode"
        [(value)]="model.DefaultRate"
        [(isValid)]="childIsValid[3]"
        [(isDirty)]="childIsDirty[3]"
        [option]="NumbericTextBoxFFHoursEarnedPerYearOptions"
        (valueChange)="onChange()"
      ></app-numeric-edit>
    </app-module-check>

    <app-check-edit
      *ngIf="isSwedenCompany && isCompanyTemplate"
      [(isDirty)]="childIsDirty[5]"
      [(isValid)]="childIsValid[5]"
      [label]="'EmployeeEmployment.ApplyLongTermIllnessCompensation' | translate"
      [(value)]="model.ApplyLongTermIllnessCompensation"
      [editMode]="editMode"
      (valueChange)="onApplyLongTermIllnessCompensationChange()"
    ></app-check-edit>

    <app-combobox-edit
      *ngIf="isSwedenCompany && isCompanyTemplate && model.ApplyLongTermIllnessCompensation"
      [label]="'EmployeeEmployment.AnnualIncomeMultiplicationFactor' | translate"
      [editMode]="editMode"
      [textField]="'Name'"
      [idField]="'Id'"
      [comboboxDataSource]="AnnualIncomeMultiplicationFactorDataSource"
      [(value)]="model.AnnualIncomeMultiplicationFactor"
      [(isDirty)]="childIsDirty[6]"
      [(isValid)]="childIsValid[6]"
      (valueChange)="onChange()"
    ></app-combobox-edit>
  </fieldset>
</form>
<form *ngIf="isShowSpecifyWeekDay">
  <div class="Form--horizontal">
    <legend class="Card-title">{{ 'EmployeeEmployment.WorkdayInAbsence' | translate }}</legend>

    <app-numeric-edit
      class="flex-numeric-50"
      [(isDirty)]="childIsDirty[7]"
      [(isValid)]="childIsValid[7]"
      [label]="'TimeEntryTypeConfigurations.Monday' | translate"
      [editMode]="editMode"
      [(value)]="model.StandardWorkHoursMonday"
      (valueChange)="onChange()"
    ></app-numeric-edit>

    <app-numeric-edit
      class="flex-numeric-50"
      [(isDirty)]="childIsDirty[8]"
      [(isValid)]="childIsValid[8]"
      [label]="'TimeEntryTypeConfigurations.Tuesday' | translate"
      [editMode]="editMode"
      [(value)]="model.StandardWorkHoursTuesday"
      (valueChange)="onChange()"
    ></app-numeric-edit>

    <app-numeric-edit
      class="flex-numeric-50"
      [(isDirty)]="childIsDirty[9]"
      [(isValid)]="childIsValid[9]"
      [label]="'TimeEntryTypeConfigurations.Wednesday' | translate"
      [editMode]="editMode"
      [(value)]="model.StandardWorkHoursWednesday"
      (valueChange)="onChange()"
    ></app-numeric-edit>

    <app-numeric-edit
      class="flex-numeric-50"
      [(isDirty)]="childIsDirty[10]"
      [(isValid)]="childIsValid[10]"
      [label]="'TimeEntryTypeConfigurations.Thursday' | translate"
      [editMode]="editMode"
      [(value)]="model.StandardWorkHoursThursday"
      (valueChange)="onChange()"
    ></app-numeric-edit>

    <app-numeric-edit
      class="flex-numeric-50"
      [(isDirty)]="childIsDirty[11]"
      [(isValid)]="childIsValid[11]"
      [label]="'TimeEntryTypeConfigurations.Friday' | translate"
      [editMode]="editMode"
      [(value)]="model.StandardWorkHoursFriday"
      (valueChange)="onChange()"
    ></app-numeric-edit>

    <app-numeric-edit
      class="flex-numeric-50"
      [(isDirty)]="childIsDirty[12]"
      [(isValid)]="childIsValid[12]"
      [label]="'TimeEntryTypeConfigurations.AppliesSaturday' | translate"
      [editMode]="editMode"
      [(value)]="model.StandardWorkHoursSaturday"
      (valueChange)="onChange()"
    ></app-numeric-edit>

    <app-numeric-edit
      class="flex-numeric-50"
      [(isDirty)]="childIsDirty[13]"
      [(isValid)]="childIsValid[13]"
      [label]="'TimeEntryTypeConfigurations.AppliesSundays' | translate"
      [editMode]="editMode"
      [(value)]="model.StandardWorkHoursSunday"
      (valueChange)="onChange()"
    ></app-numeric-edit>
  </div>
</form>
