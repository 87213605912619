/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./salary-batch-payslip-details.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../custom-controls/buttons/button.component.ngfactory";
import * as i3 from "../../../../custom-controls/buttons/button.component";
import * as i4 from "../../../../services/session/session.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../../custom-controls/grid/grid.componnent.ngfactory";
import * as i7 from "../../../../custom-controls/grid/grid.componnent";
import * as i8 from "@angular/forms";
import * as i9 from "../../../../custom-controls/grid/grid-column.component.ngfactory";
import * as i10 from "../../../../custom-controls/grid/grid-column.component";
import * as i11 from "@angular/common";
import * as i12 from "../../../../shared-components/revert-payslip/revert-payslip-dialog.component.ngfactory";
import * as i13 from "../../../../shared-components/revert-payslip/revert-payslip-dialog.component";
import * as i14 from "../../../../services/api/data.service";
import * as i15 from "../../../../services/api/static-data.service";
import * as i16 from "./salary-batch-payslip-details.component";
var styles_SalaryBatchPayslipDetailsComponent = [i0.styles];
var RenderType_SalaryBatchPayslipDetailsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SalaryBatchPayslipDetailsComponent, data: {} });
export { RenderType_SalaryBatchPayslipDetailsComponent as RenderType_SalaryBatchPayslipDetailsComponent };
function View_SalaryBatchPayslipDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-button", [["class", "payslip-revert Button--card"]], null, [[null, "clickEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickEvent" === en)) {
        var pd_0 = (_co.onRevertPayslip() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(1, 49152, null, 0, i3.ButtonComponent, [i4.SessionService], { tooltip: [0, "tooltip"], label: [1, "label"], buttonClass: [2, "buttonClass"], isDisabled: [3, "isDisabled"] }, { clickEvent: "clickEvent" }), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.revertButtonTooltip)); var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 3).transform("CompanySalaryBatches.RevertThisPayslip")); var currVal_2 = "Button--card width-100"; var currVal_3 = (!_co.isPaymentApprover || _co.sessionService.role.IsReadOnly); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_SalaryBatchPayslipDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "FormElement"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "app-grid", [["class", "FormElement-grid margin-bottom-1 Company-payslipsGrid"]], null, [[null, "filterChange"], ["document", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onMoewKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("filterChange" === en)) {
        var pd_1 = (_co.onFilterChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_GridComponent_0, i6.RenderType_GridComponent)), i1.ɵdid(2, 4243456, null, 4, i7.GridComponent, [i8.FormBuilder, i1.ChangeDetectorRef, i4.SessionService], { editMode: [0, "editMode"], filterable: [1, "filterable"], filter: [2, "filter"], pageSize: [3, "pageSize"], pageable: [4, "pageable"], miniPager: [5, "miniPager"], data: [6, "data"] }, { filterChange: "filterChange" }), i1.ɵqud(603979776, 1, { columns: 1 }), i1.ɵqud(603979776, 2, { actions: 1 }), i1.ɵqud(603979776, 3, { radios: 1 }), i1.ɵqud(603979776, 4, { groupColumns: 1 }), (_l()(), i1.ɵeld(7, 0, null, null, 3, "app-grid-column", [], null, null, null, i9.View_GridColumnComponent_0, i9.RenderType_GridColumnComponent)), i1.ɵdid(8, 49152, [[5, 4], [1, 4]], 1, i10.GridColumnComponent, [], { title: [0, "title"], field: [1, "field"], width: [2, "width"], type: [3, "type"], isFilterable: [4, "isFilterable"] }, null), i1.ɵqud(603979776, 5, { columns: 1 }), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 3, "app-grid-column", [], null, null, null, i9.View_GridColumnComponent_0, i9.RenderType_GridColumnComponent)), i1.ɵdid(12, 49152, [[6, 4], [1, 4]], 1, i10.GridColumnComponent, [], { title: [0, "title"], field: [1, "field"], width: [2, "width"], type: [3, "type"], isFilterable: [4, "isFilterable"] }, null), i1.ɵqud(603979776, 6, { columns: 1 }), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SalaryBatchPayslipDetailsComponent_1)), i1.ɵdid(16, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "app-revert-payslip-dialog", [], null, [[null, "isVisibleChange"], [null, "revertCompleted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isVisibleChange" === en)) {
        var pd_0 = ((_co.revertPayslipDialogVisible = $event) !== false);
        ad = (pd_0 && ad);
    } if (("revertCompleted" === en)) {
        var pd_1 = (_co.revertBatchCompleted.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i12.View_RevertPayslipDialogComponent_0, i12.RenderType_RevertPayslipDialogComponent)), i1.ɵdid(18, 245760, null, 0, i13.RevertPayslipDialogComponent, [i5.TranslateService, i14.DataService, i4.SessionService, i15.StaticDataService], { salaryStatement: [0, "salaryStatement"], employeeId: [1, "employeeId"], isVisible: [2, "isVisible"] }, { isVisibleChange: "isVisibleChange", revertCompleted: "revertCompleted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = "menu"; var currVal_2 = _co.filter; var currVal_3 = 100; var currVal_4 = true; var currVal_5 = true; var currVal_6 = _co.gridData; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 10).transform("EmployeePayslip.Description")); var currVal_8 = "Description"; var currVal_9 = 190; var currVal_10 = "text"; var currVal_11 = true; _ck(_v, 8, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_12 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 14).transform("EmployeePayslip.Amount")); var currVal_13 = "Amount"; var currVal_14 = 100; var currVal_15 = "numeric"; var currVal_16 = true; _ck(_v, 12, 0, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); var currVal_17 = (_co.IsFinalizedBatch && _co.selectedSalaryStatement); _ck(_v, 16, 0, currVal_17); var currVal_18 = _co.selectedSalaryStatement; var currVal_19 = ((_co.selectedSalaryStatement == null) ? null : _co.selectedSalaryStatement.UserEmploymentId); var currVal_20 = _co.revertPayslipDialogVisible; _ck(_v, 18, 0, currVal_18, currVal_19, currVal_20); }, null); }
export function View_SalaryBatchPayslipDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-salary-batch-payslip-details", [], null, null, null, View_SalaryBatchPayslipDetailsComponent_0, RenderType_SalaryBatchPayslipDetailsComponent)), i1.ɵdid(1, 49152, null, 0, i16.SalaryBatchPayslipDetailsComponent, [i4.SessionService], null, null)], null, null); }
var SalaryBatchPayslipDetailsComponentNgFactory = i1.ɵccf("app-salary-batch-payslip-details", i16.SalaryBatchPayslipDetailsComponent, View_SalaryBatchPayslipDetailsComponent_Host_0, { selectedSalaryStatement: "selectedSalaryStatement", salaryStatementDetails: "salaryStatementDetails", IsFinalizedBatch: "IsFinalizedBatch" }, { revertBatchCompleted: "revertBatchCompleted" }, []);
export { SalaryBatchPayslipDetailsComponentNgFactory as SalaryBatchPayslipDetailsComponentNgFactory };
