import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../common/constants';
import { DataService } from '../../services/api/data.service';
import { BroadcastService } from '../../services/broadcast.service';
import { SessionService } from '../../services/session/session.service';
var ChangePasswordDialogComponent = /** @class */ (function () {
    function ChangePasswordDialogComponent(dataService, broadcaster, sessionService) {
        this.dataService = dataService;
        this.broadcaster = broadcaster;
        this.sessionService = sessionService;
        this.isChangePassworSuccessDialog = false;
        this.isChangePassworFailDialog = false;
        this.isVisibleValue = false;
        this.isVisibleChange = new EventEmitter();
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(ChangePasswordDialogComponent.prototype, "isVisible", {
        get: function () {
            return this.isVisibleValue;
        },
        set: function (value) {
            if (this.isVisibleValue !== value) {
                if (value) {
                    this.currentPass = '';
                    this.newPass = '';
                }
                this.isVisibleValue = value;
                this.isVisibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    ChangePasswordDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userName = localStorage.getItem('saveLoginUserName');
        this.broadcaster
            .on(Constants.ACCOUNT_CHANGE_PASSWORD)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (event) { return (_this.isVisible = true); });
    };
    ChangePasswordDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    ChangePasswordDialogComponent.prototype.keydown = function (e) {
        if (e.keyCode !== 13) {
            return;
        }
        this.onChangePassword();
    };
    ChangePasswordDialogComponent.prototype.onChangePasswordDialogAction = function (action) {
        if (action === 'ChangePassword') {
            this.onChangePassword();
        }
        else {
            this.isVisible = false;
        }
    };
    ChangePasswordDialogComponent.prototype.onChangePassword = function () {
        var _this = this;
        var CurrentPassword = this.currentPass;
        var NewPassword = this.newPass;
        if (CurrentPassword && NewPassword) {
            var request = { CurrentPassword: CurrentPassword, NewPassword: NewPassword };
            this.dataService.Auth_ChangePassword(request).subscribe(function () {
                _this.isChangePassworSuccessDialog = true;
            }, function () {
                _this.isVisible = false;
            });
        }
        else {
            this.isChangePassworFailDialog = true;
            this.isVisible = false;
        }
    };
    ChangePasswordDialogComponent.prototype.onChangePasswordDialogSuccessAction = function (action) {
        if (action === 'Ok') {
            this.isVisible = false;
        }
    };
    ChangePasswordDialogComponent.prototype.onChangePasswordDialogFailAction = function (action) {
        if (action === 'Ok') {
            this.isChangePassworFailDialog = false;
        }
    };
    return ChangePasswordDialogComponent;
}());
export { ChangePasswordDialogComponent };
