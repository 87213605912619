import {
  Component,
  ContentChildren,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList
} from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Browser } from '../../common/browser';
import { EmployeeService } from '../../services/employee.service';
import { SessionService } from '../../services/session/session.service';
import { MenuButtonItemComponent } from '../menu-button-item/menu-button-item.component';
import { ReportDialogService } from '../report-dialog/report-dialog.service';

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html'
})
export class MenuButtonComponent implements OnInit, OnDestroy {
  @Input() public singleMenuItem: boolean;
  @Input() public multiMenuItems: boolean;
  @Input() public actionButtonMenu: boolean;
  @Input() public editButtonMenu: boolean;
  @Input() public editButtonMenuSS: boolean;
  @Input() public editMode: boolean;
  @Input() public allowCompanyPreference: boolean;
  @Input() public isCanChangeEditMode: boolean;

  @Input() public menuCondition: boolean;
  @Input() public menuDisabled: boolean;
  @Input() public menuIcon: string;
  @Input() public menuLabel: string;
  @Input() public menuTooltip: string;
  @Input() public menuButtonClass: string;
  @Input() public menuButtonId: string;

  @Output() public menuClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() public menuChangeClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public errorChangeEditMode: EventEmitter<boolean> = new EventEmitter<boolean>();

  public menuOpen = false;
  public keepOpen = false;

  public get isMobile(): boolean {
    const browser: Browser = new Browser();
    return browser.isMobile;
  }

  @ContentChildren(MenuButtonItemComponent) public listItems: QueryList<MenuButtonItemComponent>;

  constructor(
    public reportDialogService: ReportDialogService,
    private sessionService: SessionService,
    private employeeService: EmployeeService
  ) {}

  public get isShowReportOption(): boolean {
    if (this.sessionService.currentState.includes('tabs.employee') && this.employeeService.isNonSelected) {
      return false;
    }
    return true;
  }

  ngOnInit() {}

  ngOnDestroy() {}

  @HostListener('document:click')
  public closeMenu() {
    this.menuOpen = this.keepOpen;
    this.keepOpen = false;
  }

  @HostListener('document:dblclick')
  public closeMenuDbl() {
    this.menuOpen = this.keepOpen;
    this.keepOpen = false;
  }

  public toggleMenu(e: any) {
    this.menuOpen = !this.menuOpen;
    this.keepOpen = this.menuOpen;
  }

  public onMenuClickEvent(data?: string): void {
    this.menuClick.emit(data);
  }

  public onEditModeChange(editModeMenu: boolean) {
    this.menuChangeClick.emit(editModeMenu);
  }

  public onErrorChangeEditMode() {
    this.errorChangeEditMode.emit();
  }

  public onkeydown(event: any): void {
    if (event.code === 'Escape') {
      this.closeMenu();
      event.stopPropagation();
    }
  }
}
