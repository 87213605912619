import { EventEmitter } from '@angular/core';
import { DataService } from '../../services/api/data.service';
import { SessionService } from '../../services/session/session.service';
var RequestTaxCardDialogComponent = /** @class */ (function () {
    function RequestTaxCardDialogComponent(dataService, sessionService) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.change = new EventEmitter();
        this.isVisibleChange = new EventEmitter();
        this.visible = false;
        this.isShowAlert = false;
    }
    Object.defineProperty(RequestTaxCardDialogComponent.prototype, "isVisible", {
        get: function () {
            return this.visible;
        },
        set: function (value) {
            if (value === true) {
                // Set default selected value for tax card types combobox
                this.selectedTaxcardTypeId =
                    this.taxcardTypes && this.taxcardTypes.length > 0 ? this.taxcardTypes[0].Id : undefined;
            }
            this.visible = value;
            this.isVisibleChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    RequestTaxCardDialogComponent.prototype.onTaxCardAction = function (action) {
        var _this = this;
        if (action === 'Ok') {
            //// TODO: This should not be necessary when the combobox-edit has proper validation implemented
            if (!this.selectedTaxcardTypeId) {
                return;
            }
            this.dataService
                .Employments_RequestTaxInfo(this.userEmploymentId, this.selectedTaxcardTypeId)
                .subscribe(function (data) { return _this.requestTaxCardCompleted(data); });
        }
    };
    RequestTaxCardDialogComponent.prototype.requestTaxCardCompleted = function (data) {
        this.isShowAlert = true;
        this.alertMessage = data;
        this.change.emit();
    };
    return RequestTaxCardDialogComponent;
}());
export { RequestTaxCardDialogComponent };
