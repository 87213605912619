import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DataService } from 'src/app/services/api/data.service';
import { SessionService } from 'src/app/services/session/session.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/api/data.service";
import * as i2 from "../../services/session/session.service";
var AccountantJoinService = /** @class */ (function () {
    function AccountantJoinService(dataService, sessionService) {
        var _this = this;
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.filterSubject = new BehaviorSubject('');
        this.filterValue = '';
        this.selectedAccount = {};
        this.allAccounts = [];
        this.filterSubject
            .asObservable()
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(function () { return _this.applyFilter(); });
    }
    Object.defineProperty(AccountantJoinService.prototype, "joinableAccounts", {
        get: function () {
            if (!this.joinableAccountsSubject) {
                this.joinableAccountsSubject = new BehaviorSubject([]);
                this.loadJoinableAccounts();
            }
            return this.joinableAccountsSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantJoinService.prototype, "filter", {
        get: function () {
            return this.filterValue;
        },
        set: function (value) {
            this.filterValue = value;
            this.filterSubject.next(value);
        },
        enumerable: true,
        configurable: true
    });
    AccountantJoinService.prototype.joinAccount = function (id) {
        return this.dataService.Account_AttachCurrentCompany(id);
    };
    AccountantJoinService.prototype.loadJoinableAccounts = function () {
        var _this = this;
        if (!this.sessionService.feature.IsMultiCompanyMember && this.sessionService.role.IsFullAdmin) {
            this.dataService.Account_GetJoinablePublicAccounts().subscribe(function (data) {
                _this.allAccounts = data.map(function (d) { return ({
                    Id: d.Id,
                    Name: d.Name,
                    AccountTypeId: d.AccountTypeId,
                    Description: d.Description,
                    Icon: 'CalendarCheck'
                }); });
                _this.applyFilter();
            });
        }
    };
    AccountantJoinService.prototype.applyFilter = function () {
        var _this = this;
        var result = this.allAccounts.filter(function (a) {
            if (_this.filterValue && !a.Name.toLocaleLowerCase().includes(_this.filterValue.toLocaleLowerCase())) {
                return false;
            }
            return true;
        });
        this.joinableAccountsSubject.next(result);
        this.selectedAccount = {};
    };
    AccountantJoinService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountantJoinService_Factory() { return new AccountantJoinService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.SessionService)); }, token: AccountantJoinService, providedIn: "root" });
    return AccountantJoinService;
}());
export { AccountantJoinService };
