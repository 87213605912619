import { ElementRef, EventEmitter, OnDestroy, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import * as srcDoc from 'srcdoc-polyfill';
import { SessionService } from '../../services/session/session.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "../../services/session/session.service";
var IFrameDialogComponent = /** @class */ (function () {
    function IFrameDialogComponent(sanitizer, sessionService, renderer, elementRef) {
        this.sanitizer = sanitizer;
        this.sessionService = sessionService;
        this.renderer = renderer;
        this.elementRef = elementRef;
        this.title = ' ';
        this.isSelfService = false;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.helpDownloadVisible = false;
        this.isDown = false;
        this.defaultOffset = [0, 0];
        this.mousePosition = [0, 0];
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(IFrameDialogComponent.prototype, "visible", {
        get: function () {
            if (this.sessionService.checkIsAliveError) {
                return false;
            }
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                if (value === false) {
                    if (this.sessionService.browser.isHybridApp) {
                        window.screen.orientation.lock('portrait');
                    }
                }
                this.visibleValue = value;
                this.contentLoaded = false;
                this.visibleChange.emit(value);
            }
            if (value) {
                this.registerEvents();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IFrameDialogComponent.prototype, "iframeContent", {
        set: function (value) {
            if (this.iframeContentValue !== value) {
                this.iframeContentValue = value;
                this.contentLoaded = false;
                this.safeContent = this.sanitizer.bypassSecurityTrustHtml(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    IFrameDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    IFrameDialogComponent.prototype.registerEvents = function () {
        var _this = this;
        setTimeout(function () {
            var overlayElements = _this.elementRef.nativeElement.querySelectorAll('.k-overlay');
            if (overlayElements && overlayElements.length > 0) {
                overlayElements[0].addEventListener('click', function () {
                    _this.visible = false;
                });
            }
            // move dialog by mouse
            if (_this.kendoDialog._elRef && _this.kendoDialog._elRef.nativeElement && !_this.isSelfService) {
                if (window.innerWidth >= 1900) {
                    _this.moveDialog();
                }
            }
        });
    };
    IFrameDialogComponent.prototype.moveDialog = function () {
        var _this = this;
        // Get overlay root Dialog
        var root = this.kendoDialog._elRef.nativeElement;
        root.style.display = 'block';
        // Get child Dialog
        var childElementDialog = root.querySelector('div.k-window');
        childElementDialog.style.position = 'absolute';
        childElementDialog.style.left = window.innerWidth - childElementDialog.offsetWidth + 'px';
        childElementDialog.style.top = '0px';
        childElementDialog.style.cursor = 'move';
        // funtion mousedown
        childElementDialog.addEventListener('mousedown', function (event) {
            _this.isDown = true;
            _this.defaultOffset[0] = childElementDialog.offsetLeft - event.clientX;
            _this.defaultOffset[1] = childElementDialog.offsetTop - event.clientY;
        }, true);
        // function mouseup
        childElementDialog.addEventListener('mouseup', function () {
            _this.isDown = false;
        }, true);
        // function mouseleave
        childElementDialog.addEventListener('mouseleave', function () {
            _this.isDown = false;
        }, true);
        // funtion mousemove
        childElementDialog.addEventListener('mousemove', function (event) {
            event.preventDefault();
            childElementDialog.focus();
            if (window.getSelection().empty) {
                window.getSelection().empty();
            }
            else if (window.getSelection().removeAllRanges) {
                window.getSelection().removeAllRanges();
            }
            if (_this.isDown) {
                _this.mousePosition[0] = event.clientX;
                _this.mousePosition[1] = event.clientY;
                var endX = _this.mousePosition[0] + _this.defaultOffset[0] > 0 ? _this.mousePosition[0] + _this.defaultOffset[0] : 0;
                var endY = _this.mousePosition[1] + _this.defaultOffset[1] > 0 ? _this.mousePosition[1] + _this.defaultOffset[1] : 0;
                endX =
                    endX > window.innerWidth - childElementDialog.offsetWidth
                        ? window.innerWidth - childElementDialog.offsetWidth
                        : endX;
                endY =
                    endY > window.innerHeight - childElementDialog.offsetHeight
                        ? window.innerHeight - childElementDialog.offsetHeight
                        : endY;
                childElementDialog.style.left = endX + 'px';
                childElementDialog.style.top = endY + 'px';
            }
        }, true);
    };
    IFrameDialogComponent.prototype.onkeydown = function (event) {
        if (event.code === 'Escape') {
            this.visible = false;
        }
    };
    Object.defineProperty(IFrameDialogComponent.prototype, "isEdge", {
        get: function () {
            return this.sessionService.browser.isEdge;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IFrameDialogComponent.prototype, "isFirefox", {
        get: function () {
            return this.sessionService.browser.isFirefox;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IFrameDialogComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    IFrameDialogComponent.prototype.iframeLoaded = function () {
        var _this = this;
        if (this.isEdge && this.iframeContentValue && !this.contentLoaded) {
            // https://stackoverflow.com/questions/42257549/angular-2-srcdoc-polyfill-doesnt-work
            srcDoc.set(this.iframe.nativeElement, this.iframeContentValue);
            this.contentLoaded = true;
        }
        if (this.iframe.nativeElement &&
            this.iframe.nativeElement.contentWindow &&
            this.iframe.nativeElement.contentWindow.document) {
            var iframeContentWindow = this.iframe.nativeElement.contentWindow.document || null;
            iframeContentWindow.addEventListener('keydown', function (e) {
                _this.onkeydown(e);
            });
            var content = iframeContentWindow.getElementsByClassName('payslip');
            if (content && content.length > 0) {
                content[0].style.cssText = 'margin-top: 1.5rem;';
            }
        }
    };
    IFrameDialogComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IFrameDialogComponent_Factory() { return new IFrameDialogComponent(i0.ɵɵinject(i1.DomSanitizer), i0.ɵɵinject(i2.SessionService), i0.ɵɵinject(i0.Renderer2), i0.ɵɵinject(i0.ElementRef)); }, token: IFrameDialogComponent, providedIn: "root" });
    return IFrameDialogComponent;
}());
export { IFrameDialogComponent };
