import { environment } from 'src/environments/environment';
var Global = /** @class */ (function () {
    function Global() {
    }
    Object.defineProperty(Global, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Global.storeValues = function (key, values) {
        var jsonString = JSON.stringify(values);
        try {
            if (typeof sessionStorage === 'object') {
                if (jsonString) {
                    sessionStorage.setItem(key, jsonString);
                }
                else {
                    sessionStorage.removeItem(key);
                }
            }
        }
        catch (e) {
            alert('Bemærk: Din browser tillader ikke cookies, og ' +
                this.branding.SystemAlias +
                ' kan derfor ikke fungere optimalt. Vi anbefaler kraftigt at du tillader cookies på dette domæne. Cookies er nødvendige for at holde styr på væsentlige detaljer om din session, eksempelvis dit sprogvalg.Læs vores fulde cookie - politik.' +
                '\n' +
                'Please note: Your browser is not allowing cookies, which means ' +
                this.branding.SystemAlias +
                ' cannot function correctly. We strongly recommend that you allow cookies for this domain. Cookies are necessary to keep track of important details about your session, e.g.your choice of language.You can read our full policy on cookies.');
        }
    };
    Global.getValues = function (key) {
        try {
            var value = sessionStorage.getItem(key);
            return value ? JSON.parse(value) : undefined;
        }
        catch (e) {
            alert('Bemærk: Din browser tillader ikke cookies, og ' +
                this.branding.SystemAlias +
                ' kan derfor ikke fungere optimalt. Vi anbefaler kraftigt at du tillader cookies på dette domæne. Cookies er nødvendige for at holde styr på væsentlige detaljer om din session, eksempelvis dit sprogvalg.Læs vores fulde cookie - politik.' +
                '\n' +
                'Please note: Your browser is not allowing cookies, which means ' +
                this.branding.SystemAlias +
                ' cannot function correctly. We strongly recommend that you allow cookies for this domain. Cookies are necessary to keep track of important details about your session, e.g.your choice of language.You can read our full policy on cookies.');
        }
    };
    Object.defineProperty(Global, "COMPANY_ID", {
        get: function () {
            return this.companyIdValue || this.getValues(this.companyIdKey);
        },
        set: function (companyId) {
            this.companyIdValue = companyId;
            this.storeValues(this.companyIdKey, companyId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Global, "COMPANY", {
        get: function () {
            return this.companyValue || this.getValues(this.companyKey);
        },
        set: function (company) {
            this.companyValue = company;
            this.storeValues(this.companyKey, company);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Global, "UNIT_TYPES", {
        get: function () {
            return this.unitTypeValue || this.getValues(this.unitTypeKey);
        },
        set: function (value) {
            this.unitTypeValue = value;
            this.storeValues(this.unitTypeKey, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Global, "USER_PREFERENCES", {
        get: function () {
            return this.userPreferencesValue || this.getValues(this.userPreferencesKey);
        },
        set: function (value) {
            this.userPreferencesValue = value;
            this.storeValues(this.userPreferencesKey, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Global, "COMPANY_PREFERENCES", {
        get: function () {
            return this.companyPreferencesValue || this.getValues(this.companyPreferencesKey);
        },
        set: function (companyPreferences) {
            this.companyPreferencesValue = companyPreferences;
            this.storeValues(this.companyPreferencesKey, companyPreferences);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Global, "SESSION", {
        get: function () {
            if (this.sessionValue) {
                return this.sessionValue;
            }
            var storedSession = this.getValues(this.sessionKey);
            if (!storedSession && window.location.href.indexOf('login') < 0) {
                this.storeValues(this.sessionKey, this.sessionEmpty);
                window.location.reload(true);
            }
            this.sessionValue = storedSession !== this.sessionEmpty ? storedSession : undefined;
            return this.sessionValue;
        },
        set: function (session) {
            this.sessionValue = session;
            this.storeValues(this.sessionKey, session);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Global, "USEREMPLOYMENTS", {
        get: function () {
            return this.getValues(this.userEmploymentsKey);
        },
        set: function (value) {
            this.storeValues(this.userEmploymentsKey, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Global, "STARTUP_TASKS_VISIBILITY", {
        get: function () {
            return this.getValues(this.startupTaskVisibilityKey);
        },
        set: function (value) {
            this.storeValues(this.startupTaskVisibilityKey, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Global, "WELCOME_MESSAGE_SETTINGS", {
        get: function () {
            return this.welcomeMessageSettingValue || this.getValues(this.welcomeMessageSettingKey);
        },
        set: function (value) {
            this.welcomeMessageSettingValue = value;
            this.storeValues(this.welcomeMessageSettingKey, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Global, "BETA_MODULE_MESSAGE_SETTINGS", {
        get: function () {
            return this.betaModuleMessageSettingValue || this.getValues(this.betaModuleMessageSettingKey);
        },
        set: function (value) {
            this.betaModuleMessageSettingValue = value;
            this.storeValues(this.betaModuleMessageSettingKey, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Global, "STARTUP_TASKS", {
        get: function () {
            return this.getValues(this.startUpTasksKey);
        },
        set: function (value) {
            this.storeValues(this.startUpTasksKey, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Global, "IsCompanyDataCompleted", {
        get: function () {
            return this.getValues(this.companyTaskKey);
        },
        set: function (value) {
            this.storeValues(this.companyTaskKey, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Global, "IsEmployeeTaskCompleted", {
        get: function () {
            return this.getValues(this.employeeTaskKey);
        },
        set: function (value) {
            this.storeValues(this.employeeTaskKey, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Global, "IsTaskCompleted", {
        get: function () {
            return this.getValues(this.isTaskKey);
        },
        set: function (value) {
            this.storeValues(this.isTaskKey, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Global, "isOpenTabs", {
        get: function () {
            return this.getValues(this.isOpenTabsKey);
        },
        set: function (value) {
            this.storeValues(this.isOpenTabsKey, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Global, "IsDemo", {
        get: function () {
            return this.isDemoValue || this.getValues(this.isDemoKey);
        },
        set: function (value) {
            this.isDemoValue = value;
            this.storeValues(this.isDemoKey, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Global, "statesHistory", {
        get: function () {
            return this.statesHistoryValue || this.getValues(this.statesHistoryKey);
        },
        set: function (value) {
            this.statesHistoryValue = value;
            this.storeValues(this.statesHistoryKey, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Global, "isFirstCheckMessageAdBlock", {
        get: function () {
            return this.currentisFirstCheckMessageAdBlock || this.getValues(this.isFirstCheckMessageAdBlockKey);
        },
        set: function (value) {
            this.currentisFirstCheckMessageAdBlock = value;
            this.storeValues(this.isFirstCheckMessageAdBlockKey, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Global, "DimensionNumber", {
        get: function () {
            return this.dimensionNumberValue || this.getValues(this.dimensionNumberKey);
        },
        set: function (value) {
            this.dimensionNumberValue = value;
            this.storeValues(this.dimensionNumberKey, value);
        },
        enumerable: true,
        configurable: true
    });
    Global.sessionEmpty = 'sessionEmpty';
    Global.sessionKey = 'sessionKey';
    Global.companyIdKey = 'companyIdKey';
    Global.companyKey = 'companyKey';
    Global.unitTypeKey = 'unitTypeKey';
    Global.userPreferencesKey = 'userPreferencesKey';
    Global.companyPreferencesKey = 'companyPreferencesKey';
    Global.userEmploymentsKey = 'userEmploymentsKey';
    Global.startupTaskVisibilityKey = 'startupTaskVisibilityKey';
    Global.welcomeMessageSettingKey = 'welcomeMessageSettingKey';
    Global.betaModuleMessageSettingKey = 'betaModuleMessageSettingKey';
    Global.startUpTasksKey = 'startUpTasksKey';
    Global.companyTaskKey = 'companyStartUpTaskCompleted';
    Global.employeeTaskKey = 'employeeStartUpTaskCompleted';
    Global.isTaskKey = 'isStartUpTaskCompleted';
    Global.isOpenTabsKey = 'isOpenTabsKey';
    Global.isDemoKey = 'isDemoKey';
    Global.statesHistoryKey = 'statesHistoryKey';
    Global.isFirstCheckMessageAdBlockKey = 'isFirstCheckMessageAdBlockKey';
    Global.currentisFirstCheckMessageAdBlock = false;
    Global.dimensionNumberKey = 'DimensionNumber';
    return Global;
}());
export { Global };
