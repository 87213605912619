import { EventEmitter } from '@angular/core';
import { ImportTypesEnum } from '../../../model/enum';
var CreateImportTemplateDialogComponent = /** @class */ (function () {
    function CreateImportTemplateDialogComponent() {
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.showImportMappingDialog = false;
        this.option = { min: 0, step: 1, spinners: false, decimals: 0, format: 'n0' };
        this.fileSizeDialogVisible = false;
        this.importMappingChange = new EventEmitter();
    }
    Object.defineProperty(CreateImportTemplateDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
                if (value) {
                    this.showDataImportDialog();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateImportTemplateDialogComponent.prototype, "createTemplateButtonEnable", {
        get: function () {
            return !!this.formData && !!this.mappingName && !!this.startIndex;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateImportTemplateDialogComponent.prototype, "IsPayrollDataImport", {
        get: function () {
            return this.importType === ImportTypesEnum.Payroll;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateImportTemplateDialogComponent.prototype, "ImportTypeForMapping", {
        get: function () {
            if (this.IsPayrollDataImport) {
                return this.payrollMappingType;
            }
            else if (this.importType === ImportTypesEnum.TimeEntriesRow) {
                return this.timeEntriesMappingType;
            }
            return this.importType;
        },
        enumerable: true,
        configurable: true
    });
    CreateImportTemplateDialogComponent.prototype.onDialogAction = function (action) {
        if (action === 'Create') {
            this.showImportMappingDialog = true;
        }
    };
    CreateImportTemplateDialogComponent.prototype.onFileSelected = function (file) {
        var _this = this;
        if (file) {
            this.uploadFileName = file.name;
            this.formData = new FormData();
            this.formData.append('File', file, file.name);
            var reader_1 = new FileReader();
            reader_1.onload = function () {
                var base64 = reader_1.result;
                _this.formDataString = base64.split('base64,')[1];
                // const binaryString = window.atob(this.formDataString);
                // const bytes = [];
                // for (let i = 0; i < binaryString.length; i++) {
                //   bytes.push(binaryString.charCodeAt(i));
                // }
                // this.fileDataString = bytes.join();
            };
            reader_1.readAsDataURL(file);
        }
    };
    CreateImportTemplateDialogComponent.prototype.showDataImportDialog = function () {
        this.mappingName = undefined;
        this.startIndex = 1;
        this.uploadFileName = undefined;
        this.formData = undefined;
        if (this.importType === ImportTypesEnum.Payroll) {
            this.payrollMappingType = this.importType;
        }
        else if (this.importType === ImportTypesEnum.TimeEntriesRow) {
            this.timeEntriesMappingType = ImportTypesEnum.TimeEntriesRow;
        }
    };
    CreateImportTemplateDialogComponent.prototype.onImportMappingChange = function () {
        this.importMappingChange.emit();
    };
    CreateImportTemplateDialogComponent.prototype.onClosedMappingDialog = function () {
        this.visible = false;
    };
    return CreateImportTemplateDialogComponent;
}());
export { CreateImportTemplateDialogComponent };
