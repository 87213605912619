import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
var ModalService = /** @class */ (function () {
    function ModalService() {
        this.isErrorModalMessage = false;
        this.reloadAfterClose = false;
        this.modalServiceCloseErrorAlert = new EventEmitter();
    }
    ModalService.prototype.alert = function (title, message, isErrorModal, reloadAfterClose, status) {
        this.title = title;
        this.message = message;
        this.alertVisible = true;
        this.isErrorModalMessage = isErrorModal;
        this.reloadAfterClose = reloadAfterClose;
        this.status = status || null;
    };
    ModalService.prototype.onClose = function (action) {
        if (action === 'Ok' || action === 'close') {
            this.modalServiceCloseErrorAlert.emit(true);
        }
        if (this.reloadAfterClose) {
            window.location.reload();
        }
    };
    ModalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModalService_Factory() { return new ModalService(); }, token: ModalService, providedIn: "root" });
    return ModalService;
}());
export { ModalService };
