<div *ngIf="!vertical" class="FormElement-control">
  <label *ngFor="let option of options" class="FormElement-radio" [ngClass]="{ 'edit-mode': editMode && !disable }">
    <input
      type="radio"
      [id]="option.value"
      [checked]="value === option.value"
      [name]="controlId"
      [disabled]="disable || !editMode"
      (click)="onClick(option.value)"
    />
    <span class="FormElement-radioFaux"></span>
    <span class="FormElement-label" [ngClass]="{ 'radio-disabled': option.isDisabled }">{{ option.label }}</span>
  </label>
</div>

<div *ngIf="vertical">
  <div *ngFor="let option of options" class="FormElement-control">
    <div *ngIf="!option.isCheckModule" class="FormElement-static">
      <label [ngClass]="{ 'edit-mode': editMode && !disable }" class="FormElement-radio">
        <input
          type="radio"
          [id]="option.value"
          [checked]="value === option.value"
          [name]="controlId"
          [disabled]="disable || !editMode"
          (click)="onClick(option.value)"
        />
        <span class="FormElement-radioFaux"></span>
        <span class="FormElement-label" [ngClass]="{ 'radio-disabled': option.isDisabled }">{{ option.label }}</span>
        <span *ngIf="option.openLink" class="FormElement-label textWithLink" (click)="onReadMoreClick(option.openLink)">
          {{ 'Help.ReadMore' | translate }}
        </span>
        <app-icon
          *ngIf="option.addonIcon && option.visibleIcon"
          [icon]="option.addonIcon"
          (click)="option.iconClick.emit()"
        ></app-icon>
      </label>
    </div>

    <div *ngIf="option.isCheckModule" class="FormElement-static">
      <app-module-check [moduleId]="option.ModuleId" [dialogWidth]="option.moduleCheckWidth">
        <label class="FormElement-radio">
          <input
            type="radio"
            [id]="option.value"
            [checked]="value === option.value"
            [name]="controlId"
            [disabled]="!editMode"
            (click)="onClick(option.value)"
          />
          <span class="FormElement-radioFaux"></span>
          <span class="FormElement-label">{{ option.label }}</span>
          <span
            *ngIf="option.openLink"
            class="FormElement-label textWithLink"
            (click)="onReadMoreClick(option.openLink)"
          >
            {{ 'Help.ReadMore' | translate }}
          </span>
        </label>
      </app-module-check>
    </div>

    <div *ngIf="option.addonLink" class="FormElement-addon FormElement-addonSpace Icon-addOn">
      <app-icon [icon]="'Question'" [tooltip]="'GlobalDialog.ReadMore' | translate" [link]="option.addonLink">
      </app-icon>
    </div>
  </div>
</div>
