<span
  class="buttonPopUpContent"
  *ngFor="let IconChild of popupButtonIcon"
  [ngClass]="IconChild.IconPaysliphide ? 'Icon--Payslip--hide' : ''"
>
  <app-icon
    (click)="appIconClick(IconChild.Action)"
    [tooltip]="IconChild.tooltip | translate"
    [icon]="IconChild.Icon"
    [hostClass]="'align-center'"
  ></app-icon>
</span>
