<div class="Dropdown-container" (keydown)="onkeydown($event)" *ngIf="multiMenuItems">
  <app-action-button
    buttonClass="Action-button Action-buttonMore"
    [icon]="menuIcon"
    [label]="menuLabel"
    (click)="toggleMenu($event)"
    [tooltip]="menuTooltip"
  ></app-action-button>

  <div class="Dropdown Dropdown--bottom helpDropDownDialog" [ngClass]="{ 'is-active': menuOpen }">
    <ul role="menu">
      <li class="Dropdown-item" *ngIf="isMobile && reportDialogService.isVisibleReportIcon && isShowReportOption">
        <button type="button" (click)="reportDialogService.onShowReportsEventClick()">
          {{ 'Employee.Reports' | translate }}
        </button>
      </li>
      <ng-content select="app-menu-button-item"></ng-content>
    </ul>
  </div>
</div>
<div *ngIf="actionButtonMenu">
  <app-action-button
    *ngIf="menuCondition"
    [id]="menuButtonId"
    [buttonClass]="menuButtonClass"
    [icon]="menuIcon"
    [label]="menuLabel"
    (click)="onMenuClickEvent()"
    [disabled]="menuDisabled"
    [tooltip]="menuTooltip"
  >
  </app-action-button>
</div>
<div *ngIf="editButtonMenu">
  <app-edit-button
    [module]="'employee'"
    [isVisible]="menuCondition"
    [editMode]="editMode"
    (change)="onEditModeChange($event)"
  ></app-edit-button>
</div>
<div *ngIf="editButtonMenuSS">
  <app-edit-button
    [module]="'employee'"
    [isVisible]="menuCondition"
    [editMode]="editMode"
    (change)="onEditModeChange($event)"
    [isCanChangeEditMode]="isCanChangeEditMode"
    [allowCompanyPreference]="allowCompanyPreference"
    (errorChangeEditMode)="onErrorChangeEditMode()"
  ></app-edit-button>
</div>
