import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Global } from 'src/app/common/global';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class AccountantSummaryService {
  constructor(
    private dataService: DataService,
    private staticDataService: StaticDataService,
    private sessionService: SessionService
  ) {
    if (this.sessionService.role.IsFullAdmin && this.sessionService.feature.IsMultiCompanyMember) {
      this.staticDataService.companyAccount.pipe().subscribe((data) => {
        if (data && data.Name) {
          this.multiCompanyOrganizationSubject.next(data.Name);
          if (data.DefaultInvoiceType) {
            this.multiCompanyInvoiceSubject.next(data.DefaultInvoiceType.Name);
          }
        }
      });
    }
  }

  public updateCompanyInvoiceGeneral(company: any, isConfiguration?: boolean) {
    if (isConfiguration && company && company.DefaultInvoiceType) {
      this.multiCompanyInvoiceSubject.next(company.DefaultInvoiceType.Name);
    } else if (company && company.InvoiceType) {
      this.multiCompanyInvoiceSubject.next(company.InvoiceType.Name);
    }
  }

  private multiCompanyOrganizationSubject = new BehaviorSubject<string>('');
  public get multiCompanyOrganization() {
    return this.multiCompanyOrganizationSubject.asObservable();
  }

  private multiCompanyInvoiceSubject = new BehaviorSubject<string>('');
  public get multiCompanyInvoice() {
    return this.multiCompanyInvoiceSubject.asObservable();
  }

  public leaveAccount() {
    return this.dataService.Account_RemoveCompany(Global.COMPANY.Id);
  }
}
