<div class="PriceContent" [ngClass]="companyModuleService.showBilledViaMCMessage ? 'PriceContentMessage' : ''">
  <price></price>

  <div class="Actions" *ngIf="!companyModuleService.isMobile">
    <app-menu-button
      *ngIf="reportDialogService.isVisibleReportIcon"
      [menuCondition]="true"
      (menuClick)="reportDialogService.onShowReportsEventClick()"
      [menuIcon]="'File'"
      [menuLabel]="'Employee.Reports'"
      [menuTooltip]="'Employee.Reports'"
      [actionButtonMenu]="true"
      [menuButtonClass]="'Action-button Action-buttonReports'"
      [menuButtonId]="'ActionButtonReports'"
    >
    </app-menu-button>

    <app-menu-button
      [menuIcon]="'Help'"
      [menuLabel]="'Help.Title'"
      [menuTooltip]="'Help.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
      <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
    </app-menu-button>
  </div>

  <div class="Actions" *ngIf="companyModuleService.isMobile">
    <app-menu-button
      [menuIcon]="'ThreeDots'"
      [menuLabel]="'More.Title'"
      [menuTooltip]="'More.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
      <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
    </app-menu-button>
  </div>
</div>

<app-dialog
  (action)="companyModuleService.onConfirmDialogAction($event)"
  [(visible)]="companyModuleService.confirmDialogVisible"
  [leadingText]="'Price.SubmitConfirm' | translate"
>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Understood" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>
