import { UnitTypeEnum } from '../../../../model/enum';
var SalaryRecordCalculation = /** @class */ (function () {
    function SalaryRecordCalculation() {
    }
    Object.defineProperty(SalaryRecordCalculation.prototype, "UnitType", {
        get: function () {
            return this.unitType;
        },
        set: function (unitType) {
            this.unitType = unitType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryRecordCalculation.prototype, "Amount", {
        get: function () {
            return this.hasValue(this.amount) ? this.amount : null;
        },
        set: function (amount) {
            this.amount = amount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryRecordCalculation.prototype, "AmountPerUnit", {
        get: function () {
            return this.hasValue(this.amountPerUnit) ? this.amountPerUnit : null;
        },
        set: function (amountPerUnit) {
            this.amountPerUnit = amountPerUnit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryRecordCalculation.prototype, "Units", {
        get: function () {
            return this.hasValue(this.units) ? this.units : null;
        },
        set: function (units) {
            this.units = units;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryRecordCalculation.prototype, "BaseAmount", {
        get: function () {
            return this.hasValue(this.baseAmount) ? this.baseAmount : null;
        },
        set: function (baseAmount) {
            this.baseAmount = baseAmount;
        },
        enumerable: true,
        configurable: true
    });
    SalaryRecordCalculation.prototype.calculateBaseAmount = function () {
        ////if (this.unitType !== UnitTypeEnum.Percent) {
        ////    return;
        ////}
        ////let baseAmount: number;
        ////let percentage: number = this.units / 100;
        ////if (!this.hasValue(percentage) || !this.hasValue(this.amount)) {
        ////    return;
        ////}
        ////baseAmount = (this.amount * 100) / (percentage * 100);
        ////this.baseAmount = this.roundDecimal(baseAmount, 2);
    };
    SalaryRecordCalculation.prototype.calculateAmountPerUnit = function () {
        ////let amountPerUnit: number;
        ////if (this.unitType === UnitTypeEnum.Percent) {
        ////    return;
        ////}
        ////if (!this.hasValue(this.amount) || !this.hasValue(this.units)) {
        ////    return;
        ////}
        ////amountPerUnit = this.amount / (this.units * 100) * 100;
        ////this.amountPerUnit = this.roundDecimal(amountPerUnit, 2);
    };
    SalaryRecordCalculation.prototype.calculateUnits = function () {
        var unit;
        if (this.unitType === UnitTypeEnum.Percent) {
            // Calculate Units base on BaseAmount and Amount field.
            if (!this.hasValue(this.baseAmount)) {
                return;
            }
            if (this.hasValue(this.baseAmount) && this.hasValue(this.amount)) {
                unit = (this.amount * 100) / this.baseAmount;
            }
        }
        else {
            // Calculate Units base on AmountPerUnit and Amount field.
            if (!this.hasValue(this.amountPerUnit)) {
                return;
            }
            if (this.hasValue(this.amountPerUnit) && this.hasValue(this.amount)) {
                unit = (this.amount * 100) / (this.amountPerUnit * 100);
            }
        }
        this.units = this.hasValue(unit) ? this.roundDecimal(unit, 2) : null;
    };
    SalaryRecordCalculation.prototype.calculateAmount = function () {
        var amount;
        if (this.unitType === UnitTypeEnum.Percent) {
            if (!this.hasValue(this.baseAmount)) {
                return;
            }
            var percentage = this.units / 100;
            if (this.hasValue(percentage) && this.hasValue(this.baseAmount)) {
                amount = (this.baseAmount * 100 * (percentage * 100)) / 10000;
            }
        }
        else {
            if (!this.hasValueIncludeZero(this.amountPerUnit)) {
                return;
            }
            if (this.hasValue(this.amountPerUnit) && this.hasValue(this.units)) {
                amount = (this.amountPerUnit * 100 * (this.units * 100)) / 10000;
            }
        }
        this.amount = this.hasValue(amount) ? this.roundDecimal(amount, 2) : null;
    };
    SalaryRecordCalculation.prototype.hasValueIncludeZero = function (value) {
        if (value === 0) {
            return true;
        }
        return !!value;
    };
    SalaryRecordCalculation.prototype.hasValue = function (value) {
        return value && value !== 0;
    };
    SalaryRecordCalculation.prototype.roundDecimal = function (value, decimals) {
        if (value) {
            var negative = false;
            if (!decimals) {
                decimals = 0;
            }
            if (value < 0) {
                negative = true;
                value = value * -1;
            }
            var multiplicator = Math.pow(10, decimals);
            value = parseFloat((value * multiplicator).toFixed(11));
            value = (Math.round(value) / multiplicator).toFixed(2);
            if (negative) {
                value = (value * -1).toFixed(2);
            }
            return parseFloat(value);
            ////return parseFloat((Math.round(value * 100) / 100).toFixed(decimals));
        }
        return null;
    };
    return SalaryRecordCalculation;
}());
export { SalaryRecordCalculation };
