<form>
  <fieldset class="Form--horizontal">
    <legend class="Card-title">{{ 'EmployeeEmployment.TaxEIncome' | translate }}</legend>
    <app-combobox-edit
      [editMode]="editMode"
      [label]="'EmployeeEmployment.IncomeType' | translate"
      [comboboxDataSource]="incomeTypes"
      [(isDirty)]="childIsDirty[0]"
      [(value)]="model.IncomeTypeId"
      (valueChange)="onIncomeTypeChanged()"
      [textField]="'Name'"
      [idField]="'Id'"
      [tooltipField]="'Description'"
    >
    </app-combobox-edit>
    <app-check-edit
      class="MarginTop"
      [label]="'EmployeeEmployment.WorksElsewhere' | translate"
      [(value)]="model.WorksElsewhere"
      [(isDirty)]="childIsDirty[1]"
      [editMode]="editMode"
      (valueChange)="onChange()"
      [openLinkInTitle]="linkWorksElsewhere"
      [helpTooltip]="'EmployeeEmployment.WorksElsewhereTooltip' | translate"
    >
    </app-check-edit>
    <app-check-edit
      [label]="'EmployeeEmployment.TempWorkerATP' | translate"
      [(value)]="model.IsAtpTempWorker"
      [(isDirty)]="childIsDirty[2]"
      [editMode]="editMode"
      (valueChange)="onChange()"
    ></app-check-edit>

    <app-numeric-edit
      *ngIf="!iscompanyTemplatesTab && isDanishCompany"
      [label]="'EmployeeEmployment.OverrideHoursUsedForATPCalculation' | translate"
      [(value)]="model.AtpHours"
      [helpTooltip]="'EmployeeEmployment.OverrideHoursUsedForATPCalculationTooltip' | translate"
      [editMode]="editMode"
      [(isDirty)]="childIsDirty[5]"
      (valueChange)="onChange()"
    ></app-numeric-edit>

    <app-check-edit
      *ngIf="iscompanyTemplatesTab"
      class="MarginTop"
      [label]="'EmployeeGeneral.AllowExtendedTaxCardDeductions' | translate"
      [(value)]="model.AllowExtendedTaxCardDeductions"
      [(isDirty)]="childIsDirty[3]"
      [editMode]="editMode && admin"
      (valueChange)="onChange()"
      [helpTooltip]="'EmployeeGeneral.helpTooltipAllowExtendedTaxCardDeductions' | translate"
    ></app-check-edit>
    <app-module-check *ngIf="iscompanyTemplatesTab" [moduleId]="8" [hasModule]="false">
      <app-check-edit
        class="MarginTop"
        [label]="'EmployeeGeneral.UseDailyDeductionBasedOnTimeEntries' | translate"
        [editMode]="editMode && admin"
        [(isDirty)]="childIsDirty[4]"
        [(value)]="model.UseTaxDeductionPerWorkDay"
        (valueChange)="onChange()"
        [isOpenLink]="false"
        [helpTooltip]="'EmployeeGeneral.UseDailyDeductionBasedOnTimeEntriesToolTip' | translate"
      ></app-check-edit>
    </app-module-check>
  </fieldset>
</form>

<app-dialog (action)="onWarningDialogAction($event)" [(visible)]="warningDialogVisible">
  {{ 'EmployeeEmployment.IncomeType5WarningMessage' | translate }}
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Continue'"></app-dialog-action>
</app-dialog>
