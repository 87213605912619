import { ElementRef, EventEmitter } from '@angular/core';
var TemplateListComponent = /** @class */ (function () {
    function TemplateListComponent() {
        this.currentEditmode = false;
        this.selectedIdChange = new EventEmitter();
    }
    Object.defineProperty(TemplateListComponent.prototype, "isEditMode", {
        get: function () {
            return this.currentEditmode;
        },
        set: function (value) {
            var _this = this;
            if (this.templates && this.templates.length > 0) {
                if (value) {
                    this.templates.forEach(function (model) {
                        model.isActiveFeild = model.Id === _this.selectedId ? 'activeCell' : '';
                    });
                }
                else {
                    this.templates.forEach(function (model) {
                        model.isActiveFeild = '';
                    });
                }
                this.refreshList = true;
            }
            this.currentEditmode = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TemplateListComponent.prototype, "selectedId", {
        get: function () {
            return this.selectedIdValue;
        },
        set: function (value) {
            if (value && this.selectedIdValue !== value) {
                if (this.isEditMode) {
                    this.scrollToNewRow(value);
                }
                this.selectedIdValue = value;
                this.selectedIdChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TemplateListComponent.prototype, "refreshList", {
        get: function () {
            return this.refreshListValue;
        },
        set: function (value) {
            if (value) {
                this.triggerGridRefresh = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    TemplateListComponent.prototype.onSelectionChange = function (template) {
        this.selectedIdChange.emit(template ? template.Id : undefined);
    };
    Object.defineProperty(TemplateListComponent.prototype, "classDisableSelect", {
        get: function () {
            if (this.isEditMode) {
                return 'disableSelect';
            }
            return 'GridSelect';
        },
        enumerable: true,
        configurable: true
    });
    TemplateListComponent.prototype.scrollToNewRow = function (selectTemplateId) {
        var _this = this;
        setTimeout(function () {
            var count = 0;
            var check = false;
            _this.templates.forEach(function (item) {
                item.Id !== selectTemplateId && !check ? count++ : (check = true);
                item.isActiveFeild = item.Id === _this.selectedId ? 'activeCell' : '';
            });
            if (_this.templateList && _this.templateList.kendoGrid) {
                var element = _this.templateList.kendoGrid.wrapper.nativeElement.getElementsByTagName('tr')[count + 1];
                if (element) {
                    element.scrollIntoView(false);
                }
            }
        });
    };
    return TemplateListComponent;
}());
export { TemplateListComponent };
