import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { TooltipModule } from '@progress/kendo-angular-tooltip';

import { CustomControlsModule } from '../../custom-controls/custom-controls.module';
import { SharedComponentsModule } from '../../shared-components/shared-components.module';

import { CompanyModulesComponent } from './company-modules.component';
import { AnonymousPriceComponent } from './price/anonymous-price.component';
import { ModulePackageComponent } from './price/module-package.component';
import { ModuleComponent } from './price/module.component';
import { PriceSummaryComponent } from './price/price-summary.component';
import { PriceComponent } from './price/price.component';
import { TooltipPriceComponent } from './price/tool-tip-price.component';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule, TooltipModule, CustomControlsModule, SharedComponentsModule],
  declarations: [
    CompanyModulesComponent,
    AnonymousPriceComponent,
    ModuleComponent,
    ModulePackageComponent,
    PriceComponent,
    PriceSummaryComponent,
    TooltipPriceComponent
  ],
  exports: [CompanyModulesComponent],
  entryComponents: [CompanyModulesComponent]
})
export class CompanyExtraModule {}
