import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { EmployeeService } from 'src/app/services/employee.service';
import { FixedPanelService } from 'src/app/services/fixed-panel.service';
import { Global } from '../../common/global';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
var EmployeeTimeMobileComponent = /** @class */ (function () {
    function EmployeeTimeMobileComponent(dataService, sessionService, staticDataService, employeeService, fixedPanelService) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.employeeService = employeeService;
        this.fixedPanelService = fixedPanelService;
        this.updateDimension = new EventEmitter();
        this.timeEntryRecordsValues = [];
        this.approveEntries = new EventEmitter();
        this.periodsChange = new EventEmitter();
        this.selectedSalaryPeriodIdChange = new EventEmitter();
        this.isAddNewChange = new EventEmitter();
        this.isUpdateChange = new EventEmitter();
        this.currentisAddNew = false;
        this.currentisUpdate = false;
        this.isUpdateActive = false;
        this.isRejectModel = false;
        this.showSelectPeriodDialog = false;
        this.isVisibleUnit = false;
        this.isVisibleFrom = false;
        this.isVisibleUntill = false;
        this.allowSelfServiceTimeEntry = false;
        this.isgetNotMatchPeriodMessageString = false;
        this.isVisibleRejectbutton = true;
        this.isVisibleDeletebytton = true;
        this.NumericOptions = { format: 'n2', decimals: 4, step: 1, min: 0, spinners: false };
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(EmployeeTimeMobileComponent.prototype, "salaryPeriods", {
        get: function () {
            return this.currentsalaryPeriods;
        },
        set: function (value) {
            this.currentsalaryPeriods = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeMobileComponent.prototype, "selectedSalaryPeriodId", {
        get: function () {
            return this.currentselectedSalaryPeriodId;
        },
        set: function (value) {
            this.currentselectedSalaryPeriodId = value;
            this.selectedSalaryPeriodIdChange.emit(this.currentselectedSalaryPeriodId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeMobileComponent.prototype, "isAddNew", {
        get: function () {
            return this.currentisAddNew;
        },
        set: function (value) {
            if (value === true) {
                this.createNewTimeEntryTypeModel();
                this.ApplyTimeEntryRecordModalLogic();
                this.updateDimension.emit();
            }
            this.currentisAddNew = value;
            this.isAddNewChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeMobileComponent.prototype, "isUpdate", {
        get: function () {
            return this.currentisUpdate;
        },
        set: function (value) {
            this.currentisUpdate = value;
            this.updateDimension.emit();
            this.isUpdateChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeMobileComponent.prototype, "timeEntryTypes", {
        get: function () {
            return this.currenttimeEntryTypes;
        },
        set: function (value) {
            this.currenttimeEntryTypes = value;
            if (value && value.length > 0) {
                this.defaultTimeEntryType = value.find(function (mode) { return mode.Identifier === 'Work' || mode.Identifier === 'Overtime'; });
                if (this.defaultTimeEntryType === undefined || this.defaultTimeEntryType === null) {
                    this.defaultTimeEntryType = value[0];
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeMobileComponent.prototype, "timeEntryRecords", {
        get: function () {
            return this.timeEntryRecordsValues;
        },
        set: function (value) {
            if (value && value.length > 0) {
                this.timeEntryRecordsValues = value.sort(function (item1, item2) {
                    if (item1.EntryDate && item2.EntryDate) {
                        return new Date(item1.EntryDate).getTime() - new Date(item2.EntryDate).getTime();
                    }
                    return 0;
                });
            }
            else {
                this.timeEntryRecordsValues = [];
            }
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTimeMobileComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    EmployeeTimeMobileComponent.prototype.ngOnInit = function () {
        var _this = this;
        var objectAllowSelfServiceTimeEntry = this.sessionService.getCompanyPreference('Access.AllowSelfServiceTimeEntry');
        if (objectAllowSelfServiceTimeEntry && objectAllowSelfServiceTimeEntry.Value === 'true') {
            this.allowSelfServiceTimeEntry = true;
        }
        this.employeeService.selectedEmployeeId.subscribe(function (value) {
            _this.employeeSelectedId = value;
        });
    };
    EmployeeTimeMobileComponent.prototype.setTranslationTerms = function (translations) {
        this.PeriodWarningText = translations['EmploymentTime.PeriodWarning'];
    };
    Object.defineProperty(EmployeeTimeMobileComponent.prototype, "isHideButtonAction", {
        get: function () {
            return !this.isSelfService && this.isReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeMobileComponent.prototype, "isHideRejectAction", {
        get: function () {
            return this.isSelfService || this.isReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeMobileComponent.prototype, "isEmptyRecord", {
        get: function () {
            if (this.timeEntryRecords && this.timeEntryRecords.length > 0) {
                return false;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeMobileComponent.prototype, "isEmptyTimeEntryType", {
        get: function () {
            return !(this.timeEntryTypes && this.timeEntryTypes.length > 0);
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTimeMobileComponent.prototype.onAddNew = function () {
        var _this = this;
        if (this.sessionService.isGetting) {
            return;
        }
        setTimeout(function () {
            _this.fixedPanelService.calculateHeightContent();
        }, 500);
        this.isAddNew = true;
    };
    EmployeeTimeMobileComponent.prototype.onPreviewModalDialog = function () {
        this.showSelectPeriodDialog = true;
    };
    EmployeeTimeMobileComponent.prototype.ApproveEntries = function () {
        if (this.isReadOnLyControlSelfService) {
            return;
        }
        this.approveEntries.emit();
    };
    EmployeeTimeMobileComponent.prototype.onSalaryPeriodChanged = function () {
        this.periodsChange.emit();
    };
    EmployeeTimeMobileComponent.prototype.selectTimeEntryRecoredMobileCentric = function (model) {
        this.isVisibleRejectbutton = true;
        this.isVisibleDeletebytton = true;
        this.timeEntryModel = model;
        if (this.timeEntryModel.Status.Key === 'Open' || this.timeEntryModel.Status.Key === 'Rejected') {
            this.isUpdateActive = true;
            this.isRejectModel = this.timeEntryModel.Status.Key === 'Rejected' ? true : false;
        }
        else {
            this.isUpdateActive = false;
        }
        if (this.isHideButtonAction) {
            this.isUpdateActive = false;
        }
        this.onCheckActionButtonVisibale(this.timeEntryModel.Status.Key);
        this.ApplyTimeEntryRecordModalLogic(false);
        this.isUpdate = !this.isUpdate;
    };
    EmployeeTimeMobileComponent.prototype.onCheckActionButtonVisibale = function (status) {
        if (status) {
            switch (status) {
                case 'Open': {
                    this.isVisibleRejectbutton = true;
                    break;
                }
                case 'Rejected': {
                    this.isVisibleRejectbutton = true;
                    break;
                }
                case 'EmployeeApproved': {
                    this.isVisibleDeletebytton = false;
                    if (!this.isManager) {
                        this.isVisibleDeletebytton = true;
                        this.isVisibleRejectbutton = false;
                    }
                    else {
                        this.isVisibleRejectbutton = true;
                    }
                    break;
                }
                case 'AdminApproved':
                case 'Finalized': {
                    this.isVisibleRejectbutton = false;
                    this.isVisibleDeletebytton = false;
                    break;
                }
            }
        }
    };
    Object.defineProperty(EmployeeTimeMobileComponent.prototype, "titleDialog", {
        get: function () {
            if (!this.isUpdate) {
                return 'EmploymentTime.AddNewRecord';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeMobileComponent.prototype, "isManager", {
        get: function () {
            var _this = this;
            return ((Global.SESSION &&
                Global.SESSION.ManagerForUserEmploymentIds &&
                Global.SESSION.ManagerForUserEmploymentIds.length > 0 &&
                this.userEmployment &&
                !!Global.SESSION.ManagerForUserEmploymentIds.find(function (item) { return item === _this.userEmployment.Id; })) ||
                (Global.SESSION.CurrentCompanyUser &&
                    this.employeeSelectedId !== Global.SESSION.CurrentCompanyUser.Id &&
                    this.sessionService.role.IsSalaryAdminAndHigher));
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTimeMobileComponent.prototype.loadstatusLine = function (nameStatus, lineClass) {
        var res;
        switch (nameStatus) {
            case 'Open': {
                if (lineClass && lineClass.includes('NonWorkedBackround')) {
                    res = 'NonWorkedLine';
                    break;
                }
                res = 'OpenLine';
                break;
            }
            case 'AdminApproved': {
                res = 'AdminApprovedLine';
                break;
            }
            case 'Rejected': {
                res = 'RejectedLine';
                break;
            }
            case 'EmployeeApproved': {
                res = 'EmployeeApprovedLine';
                break;
            }
            case 'Finalized': {
                res = 'AdminApprovedLine';
                break;
            }
            default: {
                res = 'defaultnonestatus';
                break;
            }
        }
        return res;
    };
    EmployeeTimeMobileComponent.prototype.LineBackground = function (model) {
        var classRecord = '';
        var timeEntryType = this.timeEntryTypes.find(function (type) { return type.Id === model.TimeEntryTypeId; });
        if (timeEntryType.Identifier !== 'Work' && timeEntryType.Identifier !== 'Overtime') {
            classRecord = 'NonWorkedBackround';
        }
        if (model.Status.Key === 'Rejected') {
            classRecord = 'RejectedBackround';
        }
        var indexOfModel = this.timeEntryRecords.indexOf(model);
        if ((indexOfModel === 0 || indexOfModel) && indexOfModel < this.timeEntryRecords.length - 1) {
            var nextDay = this.sessionService.toString(new Date(this.timeEntryRecords[indexOfModel + 1].EntryDate), 'dd');
            var currentDay = this.sessionService.toString(new Date(this.timeEntryRecords[indexOfModel].EntryDate), 'dd');
            if (Number(nextDay) - Number(currentDay) === 1) {
                classRecord += ' On1DayApart';
            }
            else {
                classRecord += ' OnMoreDayApart';
            }
        }
        return classRecord;
    };
    Object.defineProperty(EmployeeTimeMobileComponent.prototype, "isSelfService", {
        get: function () {
            return this.sessionService.currentState === 'tabs.selfservice.time';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeMobileComponent.prototype, "toolTipDenyEdit", {
        get: function () {
            if (!this.allowSelfServiceTimeEntry) {
                return 'EmploymentTime.DisableGridTimeMessage';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTimeMobileComponent.prototype, "isReadOnLyControlSelfService", {
        get: function () {
            if (this.isSelfService && !this.allowSelfServiceTimeEntry) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTimeMobileComponent.prototype.ConvertDaytoString = function (timestring) {
        if (timestring) {
            return this.sessionService.toString(new Date(timestring), 'dd');
        }
        return '';
    };
    EmployeeTimeMobileComponent.prototype.ConvertMonthtoString = function (timestring) {
        if (timestring) {
            return this.sessionService.toString(new Date(timestring), 'MMM');
        }
        return '';
    };
    EmployeeTimeMobileComponent.prototype.checkexistenddate = function (enddate) {
        if (enddate) {
            return true;
        }
        return false;
    };
    EmployeeTimeMobileComponent.prototype.checkIsNullOrEmptyString = function (value) {
        if (!value) {
            return true;
        }
        return false;
    };
    EmployeeTimeMobileComponent.prototype.getTimeEntryTypeName = function (timeEntryTypeId) {
        if (this.timeEntryTypes && this.timeEntryTypes.length > 0) {
            var res = this.timeEntryTypes.find(function (model) { return model.TimeEntryTypeId === timeEntryTypeId; });
            if (res && res.Name) {
                return res.Name;
            }
            else if (res && res.BaseTimeEntryType && res.BaseTimeEntryType.Name) {
                return res.BaseTimeEntryType.Name;
            }
        }
        return '';
    };
    EmployeeTimeMobileComponent.prototype.formatunit = function (unitstring) {
        if (unitstring) {
            return this.sessionService.toString(parseFloat(unitstring), '0.00');
        }
        return '';
    };
    EmployeeTimeMobileComponent.prototype.showUnitType = function (nameType) {
        if (nameType && nameType === 'Hours') {
            return false;
        }
        return true;
    };
    EmployeeTimeMobileComponent.prototype.selectListSalaryPeriods = function (model) {
        if (model) {
            this.selectedSalaryPeriodId = model.Id;
            this.periodsChange.emit();
        }
        this.showSelectPeriodDialog = !this.showSelectPeriodDialog;
    };
    EmployeeTimeMobileComponent.prototype.createNewTimeEntryTypeModel = function () {
        var _this = this;
        this.timeEntryModel = {};
        if (this.timeEntryTypes) {
            var defaultTypeEntryType_1 = this.defaultTimeEntryType;
            var defaultUnitType = defaultTypeEntryType_1
                ? this.unitTypes
                    ? this.unitTypes.find(function (type) { return type.Id === defaultTypeEntryType_1.UnitTypeId; })
                    : null
                : null;
            defaultUnitType = defaultUnitType ? defaultUnitType : this.unitTypes ? this.unitTypes[0] : null;
            var today = this.staticDataService.getCurrentdate();
            this.timeEntryModel = {
                TimeEntryTypeId: defaultTypeEntryType_1 ? defaultTypeEntryType_1.TimeEntryTypeId : null,
                EntryDate: today,
                Units: 0,
                UnitTypeId: defaultUnitType ? defaultUnitType.Id : null,
                UnitType: defaultUnitType ? defaultUnitType : null,
                Created: today,
                SalaryPeriodId: this.selectedSalaryPeriodId,
                UserEmploymentId: this.userEmployment.Id,
                CalculatedPayoutKilometers: 0
            };
            var timeEntryType = this.timeEntryTypes && this.timeEntryTypes.length > 0
                ? this.timeEntryTypes.find(function (e) { return e.TimeEntryTypeId === _this.timeEntryModel.TimeEntryTypeId; })
                : null;
            this.timeEntryModel.isEditUnitType = timeEntryType ? timeEntryType.AllowEditUnitType : false;
            if (this.salaryPeriods && this.salaryPeriods.length > 0 && this.selectedSalaryPeriodId) {
                var salaryPeriod = this.salaryPeriods.find(function (model) { return model.Id === _this.selectedSalaryPeriodId; });
                if (salaryPeriod) {
                    if (new Date(this.timeEntryModel.EntryDate) < new Date(salaryPeriod.StartDate) ||
                        new Date(this.timeEntryModel.EntryDate) > new Date(salaryPeriod.EndDate)) {
                        this.timeEntryModel.EntryDate = salaryPeriod.StartDate;
                    }
                }
            }
        }
    };
    EmployeeTimeMobileComponent.prototype.onUnitTypeChange = function () {
        var _this = this;
        if (this.timeEntryModel.UnitTypeId) {
            this.timeEntryModel.UnitType = this.unitTypes.find(function (model) { return model.Id === _this.timeEntryModel.UnitTypeId; });
            if (this.timeEntryModel.UnitType &&
                this.timeEntryModel.UnitType.Key === 'Days' &&
                !this.timeEntryModel.EndDate &&
                !this.timeEntryModel.Units) {
                this.timeEntryModel.Units = 1;
            }
        }
    };
    EmployeeTimeMobileComponent.prototype.ApplyTimeEntryRecordModalLogic = function (isRecalculate) {
        var _this = this;
        if (isRecalculate === void 0) { isRecalculate = true; }
        setTimeout(function () {
            if (_this.timeEntryModel) {
                if (_this.timeEntryModel.UnitTypeId) {
                    _this.timeEntryModel.UnitType = _this.unitTypes.find(function (model) { return model.Id === _this.timeEntryModel.UnitTypeId; });
                }
                if (_this.timeEntryModel.UnitType && _this.timeEntryModel.UnitType.Key === 'Hours') {
                    _this.isVisibleFrom = true;
                    _this.isVisibleUntill = true;
                    _this.isVisibleUnit = !(_this.timeEntryModel.StartTime && _this.timeEntryModel.EndTime);
                }
                else {
                    _this.isVisibleFrom = false;
                    _this.isVisibleUntill = false;
                    _this.isVisibleUnit = !(_this.timeEntryModel.EntryDate && _this.timeEntryModel.EndDate);
                }
                var timeEntryType = _this.timeEntryTypes && _this.timeEntryTypes.length > 0
                    ? _this.timeEntryTypes.find(function (e) { return e.TimeEntryTypeId === _this.timeEntryModel.TimeEntryTypeId; })
                    : null;
                _this.timeEntryModel.isEditUnitType = timeEntryType ? timeEntryType.AllowEditUnitType : false;
                if (isRecalculate) {
                    _this.updateUnitsByDateAndTime(_this.timeEntryModel);
                }
            }
        });
    };
    EmployeeTimeMobileComponent.prototype.updateUnitsByDateAndTime = function (model) {
        var uniType = model.UnitTypeId && this.unitTypes ? this.unitTypes.find(function (type) { return type.Id === model.UnitTypeId; }) : null;
        var isHoursUnitType = uniType && uniType.Key === 'Hours';
        var isDaysUnitType = uniType && uniType.Key === 'Days';
        var start;
        var today = new Date();
        today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        if (model.StartTime) {
            start = new Date(today);
            start.setHours(this.sessionService.parseInt(model.StartTime.split(/\:/g)[0]), this.sessionService.parseInt(model.StartTime.split(/\:/g)[1]), 0, 0);
        }
        var end;
        if (model.EndTime) {
            end = new Date(today);
            end.setHours(this.sessionService.parseInt(model.EndTime.split(/\:/g)[0]), this.sessionService.parseInt(model.EndTime.split(/\:/g)[1]), 0, 0);
        }
        model.StartTime = start ? this.sessionService.toString(start, 'HH:mm:ss') : '';
        model.EndTime = end
            ? model.EndTime.indexOf('24:00') === 0
                ? '24:00:00'
                : this.sessionService.toString(end, 'HH:mm:ss')
            : '';
        if (isHoursUnitType) {
            if (model.StartTime && model.EndTime) {
                start.setHours(start.getHours(), start.getMinutes(), 0, 0);
                var startTime = start.getTime();
                var endTime = void 0;
                if (model.EntryDate && model.EndDate) {
                    var entryDate = new Date(model.EntryDate);
                    var endDate = new Date(model.EndDate);
                    startTime = entryDate.setHours(start.getHours(), start.getMinutes(), 0, 0);
                    endTime = endDate.setHours(end.getHours(), end.getMinutes(), 0, 0);
                }
                else {
                    end.setHours(end.getHours(), end.getMinutes(), 0, 0);
                    endTime = end;
                    endTime = start > end ? endTime.setDate(end.getDate() + 1) : endTime.getTime();
                }
                var timeDiff = endTime - startTime;
                model.Units = Math.abs(Math.floor(timeDiff / 3600) / 1000);
            }
            ////else {
            ////    this.calculateUnitByStartAndEndDate(model, isHoursUnitType);
            ////}
        }
    };
    EmployeeTimeMobileComponent.prototype.checkIsvalidPost = function (model) {
        if (model && model.EntryDate) {
            // switch (model.UnitType.Key) {
            //   case 'Hours': {
            //     if (model.Units !== null && model.Units >= 0) {
            //       return true;
            //     }
            //     return false;
            //   }
            //   default: {
            //     if (model.Units !== null && model.Units >= 0) {
            //       return true;
            //     } else {
            //       if (model.EndDate && model.EndDate > model.EntryDate) {
            //         return true;
            //       }
            //       return false;
            //     }
            //   }
            // }
            if (model.TimeEntryTypeId && model.EntryDate && (model.Units || model.Units === 0)) {
                return true;
            }
        }
        return false;
    };
    EmployeeTimeMobileComponent.prototype.CreateNewTimeentryRecordMobileCentric = function () {
        var _this = this;
        this.onUnitTypeChange();
        var newRecord = Object.assign({}, this.timeEntryModel);
        newRecord.StartTime =
            newRecord.StartTime && newRecord.StartTime.indexOf('24:00') === 0 ? '1.00:00:00' : newRecord.StartTime;
        newRecord.EndTime =
            newRecord.EndTime && newRecord.EndTime.indexOf('24:00') === 0 ? '1.00:00:00' : newRecord.EndTime;
        newRecord.CalculatedPayoutKilometers = 0;
        newRecord.ApprovedUserId = newRecord.ApprovedUserId || null;
        newRecord.RejectedUserId = newRecord.RejectedUserId || null;
        newRecord.Dimension1ValueId = newRecord.Dimension1ValueId || null;
        newRecord.Dimension2ValueId = newRecord.Dimension2ValueId || null;
        newRecord.Dimension3ValueId = newRecord.Dimension3ValueId || null;
        this.dataService.TimeEntry_CreateTimeEntryRecord(newRecord).subscribe(function (updatedRecord) {
            _this.periodsChange.emit();
            _this.isAddNew = !_this.isAddNew;
            if (updatedRecord && updatedRecord.SalaryPeriodId !== newRecord.SalaryPeriodId) {
                _this.getNotMatchPeriodMessageString = _this.getNotMatchPeriodMessage(newRecord);
                _this.isgetNotMatchPeriodMessageString = true;
            }
        }, function (error) {
            console.error('Create request failed:' + error);
            _this.periodsChange.emit();
            _this.isAddNew = !_this.isAddNew;
        });
    };
    EmployeeTimeMobileComponent.prototype.UpdateNewTimeentryRecordMobileCentric = function () {
        var _this = this;
        this.onUnitTypeChange();
        var updateRecord = Object.assign({}, this.timeEntryModel);
        updateRecord.StartTime =
            updateRecord.StartTime && updateRecord.StartTime.indexOf('24:00') === 0 ? '1.00:00:00' : updateRecord.StartTime;
        updateRecord.EndTime =
            updateRecord.EndTime && updateRecord.EndTime.indexOf('24:00') === 0 ? '1.00:00:00' : updateRecord.EndTime;
        updateRecord.CalculatedPayoutKilometers = 0;
        updateRecord.ApprovedUserId = updateRecord.ApprovedUserId || null;
        updateRecord.RejectedUserId = updateRecord.RejectedUserId || null;
        updateRecord.Dimension1ValueId = updateRecord.Dimension1ValueId || null;
        updateRecord.Dimension2ValueId = updateRecord.Dimension2ValueId || null;
        updateRecord.Dimension3ValueId = updateRecord.Dimension3ValueId || null;
        this.dataService.TimeEntry_UpdateTimeEntryRecord(updateRecord).subscribe(function (updatedRecord) {
            _this.periodsChange.emit();
            _this.isUpdate = !_this.isUpdate;
            if (updatedRecord && updatedRecord.SalaryPeriodId !== updateRecord.SalaryPeriodId) {
                _this.getNotMatchPeriodMessageString = _this.getNotMatchPeriodMessage(updateRecord);
                _this.isgetNotMatchPeriodMessageString = true;
            }
        }, function (error) {
            console.error('Update request failed:' + error);
            _this.periodsChange.emit();
            _this.isUpdate = !_this.isUpdate;
        });
    };
    EmployeeTimeMobileComponent.prototype.onConfirmDeleteDialogAction = function (action) {
        var _this = this;
        if (action === 'Delete') {
            this.dataService.TimeEntry_DeleteTimeEntryRecord(this.timeEntryModel.Id).subscribe(function () {
                _this.periodsChange.emit();
                _this.isUpdate = !_this.isUpdate;
            }, function (error) {
                console.error('Delete request failed:' + error);
                _this.periodsChange.emit();
                _this.isUpdate = !_this.isUpdate;
            });
        }
    };
    EmployeeTimeMobileComponent.prototype.DeleteNewTimeentryRecordMobileCentric = function () {
        this.confirmDeleteDialogVisible = true;
    };
    EmployeeTimeMobileComponent.prototype.onConfirmRejectDialogAction = function (action) {
        var _this = this;
        if (action === 'Reject') {
            var rejectionInfo = { Reason: this.rejectReason };
            this.dataService.TimeEntry_RejectTimeEntryRecord(this.timeEntryModel.Id, rejectionInfo).subscribe(function () {
                _this.periodsChange.emit();
                _this.isUpdate = !_this.isUpdate;
            });
        }
    };
    EmployeeTimeMobileComponent.prototype.RejectNewTimeentryRecordMobileCentric = function () {
        this.rejectReason = '';
        this.confirmRejectDialogVisible = true;
    };
    EmployeeTimeMobileComponent.prototype.isCloseModal = function (action) {
        if (action === 'create') {
            this.isAddNew = !this.isAddNew;
            this.createNewTimeEntryTypeModel();
        }
        if (action === 'update') {
            this.isUpdate = !this.isUpdate;
        }
        this.periodsChange.emit();
    };
    EmployeeTimeMobileComponent.prototype.onTimeEntryTypesChange = function (timeEntryTypeId) {
        var _this = this;
        ////this.newModel.TimeEntryTypeId = timeEntryTypeId;
        if (this.timeEntryTypes && this.timeEntryTypes.length > 0 && this.timeEntryModel.TimeEntryTypeId) {
            var timeEntryType = this.timeEntryTypes
                ? this.timeEntryTypes.find(function (e) { return e.TimeEntryTypeId === timeEntryTypeId; })
                : null;
            this.timeEntryModel.TimeEntryType = timeEntryType;
            this.timeEntryModel.Description = timeEntryType ? timeEntryType.Description : null;
            this.timeEntryModel.UnitTypeId =
                timeEntryType && timeEntryType.UnitTypeId
                    ? timeEntryType.UnitTypeId
                    : this.unitTypes && this.unitTypes.length > 0
                        ? this.unitTypes[0].Id
                        : null;
            if (this.timeEntryModel.UnitTypeId) {
                this.timeEntryModel.UnitType = this.unitTypes.find(function (type) { return type.Id === _this.timeEntryModel.UnitTypeId; });
            }
        }
        this.ApplyTimeEntryRecordModalLogic();
    };
    EmployeeTimeMobileComponent.prototype.onCloseDialogEdit = function () {
        this.periodsChange.emit();
        this.isAddNew = false;
    };
    Object.defineProperty(EmployeeTimeMobileComponent.prototype, "SelectedSalaryPeriod", {
        get: function () {
            var _this = this;
            if (!this.selectedSalaryPeriodId || !this.salaryPeriods || this.salaryPeriods.length <= 0) {
                return null;
            }
            return this.salaryPeriods.filter(function (period) {
                return period.Id === _this.selectedSalaryPeriodId;
            })[0];
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTimeMobileComponent.prototype.getNotMatchPeriodMessage = function (record) {
        if (record && record.EntryDate) {
            var outOfPeriodDate = void 0;
            var entryDate = record.EntryDate;
            var startDate = new Date(this.SelectedSalaryPeriod.StartDate.toString());
            var endDate = new Date(this.SelectedSalaryPeriod.EndDate.toString());
            endDate.setHours(23, 59, 59);
            if (entryDate < startDate || entryDate > endDate) {
                outOfPeriodDate = record.EntryDate;
            }
            else {
                outOfPeriodDate = record.EndDate;
            }
            return this.PeriodWarningText.replace('$$$', this.sessionService.toString(outOfPeriodDate));
        }
        return '';
    };
    return EmployeeTimeMobileComponent;
}());
export { EmployeeTimeMobileComponent };
