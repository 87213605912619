import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../common/constants';
import { FormComponentBase } from '../../common/form-component-base';
import { Global } from '../../common/global';
import { IIncomeType } from '../../services/api/api-model';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { TaxEIncomeModel } from './tax-e-income-model';

@Component({
  selector: 'app-tax-e-income',
  templateUrl: './tax-e-income.component.html',
  styleUrls: ['./tax-e-income.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaxEIncomeComponent extends FormComponentBase implements OnInit {
  private modelValue: TaxEIncomeModel;
  @Input()
  public get model(): TaxEIncomeModel {
    if (!this.modelValue) {
      this.modelValue = {} as any;
    }

    return this.modelValue;
  }
  public set model(value: TaxEIncomeModel) {
    this.modelValue = value;
    if (value) {
      this.previousImcomeTypeId = value.IncomeTypeId;
    }

    this.childIsDirty = Array(6).fill(false);
  }

  public get admin(): boolean {
    if (
      Global.SESSION.CurrentRole.Key === 'FullAdmin' ||
      Global.SESSION.CurrentRole.Key === 'SalaryAdmin' ||
      this.sessionService.currentState === 'tabs.selfservice.general'
    ) {
      return true;
    }
    return false;
  }

  public get iscompanyTemplatesTab(): boolean {
    return this.sessionService.currentState === 'tabs.company.configuration.hiringstatus';
  }

  public get isDanishCompany() {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
  }

  public incomeTypes: IIncomeType[];
  public warningDialogVisible = false;
  public linkWorksElsewhere = 'https://skat.dk/skat.aspx?oid=2234073&chk=217351';
  private previousImcomeTypeId: number;

  constructor(
    private staticDataService: StaticDataService,
    private changeDetectorRef: ChangeDetectorRef,
    public sessionService: SessionService
  ) {
    super();
    this.childIsDirty = Array(6).fill(false);
    this.editModeChange.subscribe((value: boolean) => {
      if (value && this.model) {
        this.previousImcomeTypeId = this.model.IncomeTypeId;
      }
    });
  }

  public ngOnInit(): void {
    this.staticDataService.IncomeType.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IIncomeType[]) => {
      this.incomeTypes = data;
      this.changeDetectorRef.markForCheck();
    });
  }

  public onWarningDialogAction(action: string): void {
    if (action === 'Continue') {
      this.previousImcomeTypeId = this.model.IncomeTypeId;
      this.onChange();
    } else {
      this.model.IncomeTypeId = this.previousImcomeTypeId;
    }
  }

  public onIncomeTypeChanged(): void {
    if (this.model.IncomeTypeId === 5) {
      this.warningDialogVisible = true;
      this.changeDetectorRef.markForCheck();
    } else {
      this.onChange();
    }
  }
}
