import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { ICompanyUser, IUserEmployment } from '../../services/api/api-model';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-delete-employee',
  templateUrl: './delete-employee.component.html'
})
export class DeleteEmployeeDialogComponent {
  private isVisibleValue = false;
  @Input()
  public get isVisible(): boolean {
    return this.isVisibleValue;
  }
  public set isVisible(value: boolean) {
    if (value) {
    }

    this.isVisibleValue = value;
    this.isVisibleChange.emit(value);
  }
  @Output() public isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private currentUserEmploymentValue: IUserEmployment;
  @Input()
  public get currentUserEmployment(): IUserEmployment {
    return this.currentUserEmploymentValue;
  }
  public set currentUserEmployment(value: IUserEmployment) {
    if (value) {
    }

    this.currentUserEmploymentValue = value;
  }

  private currentCompanyUserValue: ICompanyUser;
  @Input()
  public get currentCompanyUser(): ICompanyUser {
    return this.currentCompanyUserValue;
  }
  public set currentCompanyUser(value: ICompanyUser) {
    if (value) {
    }

    this.currentCompanyUserValue = value;
  }

  public get hasEmployment(): boolean {
    return !!this.currentUserEmployment && !!this.currentUserEmployment.Id;
  }

  constructor(
    public sessionService: SessionService,
    private employeeService: EmployeeService,
    private staticDataService: StaticDataService
  ) {}

  public onDeleteEmployeeAction(action: string): void {
    switch (action) {
      case 'DeleteEmployment':
        this.employeeService.deleteEmployment().subscribe(() => {
          this.isVisible = false;
          this.staticDataService.loadUserEmployments();
        });
        break;
      case 'DeleteCompanyUser':
        this.employeeService.deleteEmployee().subscribe(() => (this.isVisible = false));
        break;
    }
  }
}
