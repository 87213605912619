<div>
  <!-- Cycle -->
  <app-combobox-edit
    [label]="'CompanySalaryBatches.Cycle' | translate"
    [textField]="'Name'"
    [idField]="'Id'"
    [preventChange]="isPreparingContent"
    [editMode]="true"
    [disable]="IsExistingSalaryBatch"
    [comboboxDataSource]="salaryCycles"
    [(value)]="entityContext.CycleId"
    (valueChange)="onChildWindowSalaryCycleChange()"
  ></app-combobox-edit>
  <!-- Period -->
  <app-combobox-edit
    [label]="'CompanySalaryBatches.Period' | translate"
    [textField]="'FriendlyName'"
    [idField]="'Id'"
    [isConvertTime]="false"
    [editMode]="true"
    [disable]="IsExistingSalaryBatch"
    [comboboxDataSource]="entityContext.Periods"
    [preventChange]="isPreparingContent"
    [(value)]="entityContext.PeriodId"
    [showNavigationButton]="entityContext.BatchId == null || entityContext.BatchId < 1"
    [hasFeedback]="entityContext.HasBatch"
    [feedbackKey]="'CompanySalaryBatches.BatchExistedForPeriodWarning'"
    (valueChange)="onSalaryPeriodChanged($event)"
    [keepDataSourceOrder]="true"
  ></app-combobox-edit>

  <!-- Payment date -->
  <app-date-picker
    class="PaymentDatePicker"
    [label]="'CompanySalaryBatches.PaymentDate' | translate"
    *ngIf="isShowPaymentDate"
    [editMode]="true"
    [required]="true"
    [requiredMessage]="'EmployeeGeneral.Required' | translate"
    [preventChange]="isPreparingContent"
    [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
    [disable]="IsReadOnly || !entityContext.IsDraft"
    [(value)]="entityContext.PaymentDate"
    (valueChange)="entityContextMobileChange()"
  ></app-date-picker>

  <!-- FromDateOverride -->
  <app-date-picker
    class="PaymentDatePicker"
    [label]="'CompanySalaryBatches.OverriddenStartDate' | translate"
    *ngIf="isAllowOverride"
    [editMode]="true"
    [preventChange]="isPreparingContent"
    [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
    [disable]="IsReadOnly || !entityContext.IsDraft"
    [(value)]="entityContext.FromDateOverride"
    (valueChange)="entityContextMobileChange()"
  ></app-date-picker>

  <!-- ToDateOverride -->
  <app-date-picker
    class="PaymentDatePicker"
    [label]="'CompanySalaryBatches.OverriddenEndDate' | translate"
    *ngIf="isAllowOverride"
    [editMode]="true"
    [preventChange]="isPreparingContent"
    [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
    [disable]="IsReadOnly || !entityContext.IsDraft"
    [(value)]="entityContext.ToDateOverride"
    (valueChange)="entityContextMobileChange()"
  ></app-date-picker>

  <fieldset class="FormElement" *ngIf="entityContext.BatchId === -1">
    <app-radio-edit
      [editMode]="true"
      [vertical]="true"
      [(value)]="entityContext.EmployeeCategory"
      (valueChange)="onEmployeeCategoryChanged($event)"
    >
      <app-radio-edit-option [value]="1" [label]="'CompanySalaryBatches.AllEmployees' | translate">
      </app-radio-edit-option>
      <app-radio-edit-option
        [value]="2"
        [label]="'CompanySalaryBatches.PickEmployee' | translate"
        [addonIcon]="'Edit'"
        [visibleIcon]="IsNewBatchWithEmployeeSelection"
        (iconClick)="companySalaryBatchService.pickEmployeeDialogVisible = true"
      >
      </app-radio-edit-option>
      <app-radio-edit-option
        *ngIf="!IsGreenlandCompany"
        [value]="3"
        [label]="'CompanySalaryBatches.NoEmployee' | translate"
      ></app-radio-edit-option>
    </app-radio-edit>
  </fieldset>

  <!-- Message to employee -->
  <div class="FormElement salary-message-form">
    <label class="FormElement-label" for="Message">{{ 'CompanySalaryBatches.MessageToEmployee' | translate }}</label>
    <div class="FormElement-control">
      <textarea
        class="FormElement-textarea MessageToEmployeesTextArea"
        id="Message"
        [disabled]="IsReadOnly || !entityContext.IsDraft"
        [(ngModel)]="entityContext.Message"
        (ngModelChange)="entityContextMobileChange()"
      ></textarea>
    </div>
  </div>

  <app-check-edit
    *ngIf="showMultiPurposeOffDayCompensation || IsGreenlandCompany"
    class="FormElement u-leader"
    [label]="PayoutAllFlexLabel | translate"
    [editMode]="true"
    [(value)]="entityContext.PayoutAllFlex"
    [disable]="IsReadOnly || !entityContext.IsDraft"
    (valueChange)="entityContextMobileChange()"
  ></app-check-edit>

  <app-check-edit
    *ngIf="IsDenmarkCompany"
    class="FormElement u-leader"
    [label]="'CompanySalaryBatches.PayoutNewFlex' | translate"
    [editMode]="true"
    [(value)]="entityContext.PayoutNewFlex"
    [disable]="IsReadOnly || !entityContext.IsDraft"
    (valueChange)="entityContextMobileChange()"
  ></app-check-edit>

  <div
    class="u-leader"
    *ngIf="
      salaryStatements &&
      salaryStatements.length > 0 &&
      (entityContext.IsPendingForApproval || entityContext.IsPreliminary)
    "
  >
    <div class="u-flex">
      {{ 'CompanySalaryBatches.RecalculateSingleEmployee' | translate }}:
      <app-icon
        class="u-helpColor"
        [icon]="'Info'"
        [tooltip]="'CompanySalaryBatches.RecalculateSingleEmployeeBrief' | translate"
      ></app-icon>
    </div>

    <div class="u-flex">
      <app-combobox-edit
        class="u-flexGrow padding-right"
        [editMode]="true"
        [comboboxDataSource]="salaryStatements"
        [textField]="'UserFullName'"
        [idField]="'UserEmploymentId'"
        [(value)]="recalculateSingleUserEmploymentId"
      >
      </app-combobox-edit>
      <app-button
        class="Button--card right"
        (clickEvent)="onRecalculateSingleEmployeeDone()"
        [label]="'CompanySalaryBatches.Recalculate' | translate"
      ></app-button>
    </div>
  </div>
</div>
