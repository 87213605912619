import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountantSummaryService } from 'src/app/accountant/summary/accountant-summary.service';
import { FormComponentBase } from '../../common/form-component-base';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import { ReportDialogService } from '../../shared-components/report-dialog/report-dialog.service';
import { CompanyGeneralService } from './company-general.service';

@Component({
  selector: 'app-company-general',
  templateUrl: './company-general.component.html',
  styleUrls: ['./company-general.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyGeneralComponent extends FormComponentBase implements OnInit, OnDestroy {
  public discardDialogVisible = false;
  public invalidDialogVisible = false;
  public isShowtestEmailDialog = false;
  public showInvalidFormWarning = false;
  public resetChildFrom = false;
  public openJoinGridDialog = false;
  public reportDialogVisible = false;
  public listClassExcluded = ['Footer', '10'];

  constructor(
    private settingService: SettingService,
    public sessionService: SessionService,
    public companyGeneralService: CompanyGeneralService,
    public reportDialogService: ReportDialogService,
    private accountantSummaryService: AccountantSummaryService
  ) {
    super();
    this.childIsValid = Array(3).fill(true);
    this.childIsDirty = Array(3).fill(false);

    this.companyGeneralService.editModeChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      if (!value && this.companyGeneralService.tabDirty) {
        this.companyGeneralService.onSave();
      }
    });

    this.companyGeneralService.editShortcutChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      if (
        value &&
        this.sessionService.role.IsFullAdmin &&
        !this.sessionService.isGetting &&
        !this.companyGeneralService.editMode
      ) {
        this.onEditModeChange(true);
      }
    });

    this.companyGeneralService.discardChangeShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && this.companyGeneralService.editMode) {
          this.confirmDiscardChanges();
        }
      });

    this.companyGeneralService.saveChangeShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && this.companyGeneralService.editMode) {
          this.onEditModeChange(false);
        }
      });

    this.companyGeneralService.showReportShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && !this.companyGeneralService.editMode && this.reportDialogService.isVisibleReportIcon) {
          this.reportDialogService.onShowReportsEventClick();
        }
      });

    this.valueChange.subscribe(() => {
      this.companyGeneralService.tabDirty = this.isDirty;
      this.companyGeneralService.tabValid = this.isValid;
    });
  }

  public ngOnInit(): void {
    this.companyGeneralService.company.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      this.companyGeneralService.currentCompany = data;

      this.resetForm();
    });
    this.getCurrentCompany();

    this.settingService.showModuleMessage('Company.General');
    this.companyGeneralService.navigationToStateName = undefined;
  }

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    this.companyGeneralService.ngOnDestroy();
  }

  public onDoubleClick(): void {
    if (this.sessionService.role.IsFullAdmin && !this.sessionService.isGetting) {
      this.onEditModeChange(true);
    }
  }

  public onMoreMenuOptionClick(menu: string): void {
    switch (menu) {
      case 'companyGeneral_importData':
        this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
        break;
      case 'companyGeneral_testMail':
        this.isShowtestEmailDialog = true;
        break;
      case 'companyreport':
        this.reportDialogVisible = true;
        break;
      default:
        break;
    }
  }

  public onEditModeChange($event: boolean): void {
    if (this.companyGeneralService.editMode !== $event) {
      this.companyGeneralService.tabDirty = this.isDirty;
      this.companyGeneralService.tabValid = this.isValid;

      this.companyGeneralService.editMode = $event;
    }
  }

  public ChangeState(): void {
    if (this.sessionService.nextState) {
      this.sessionService.NavigateTo(this.sessionService.nextState);
    }
  }

  public confirmDiscardChanges(): void {
    if (this.isDirty) {
      this.discardDialogVisible = true;
    } else {
      this.onEditModeChange(false);
    }
  }

  public discardDialogAction(action: string): void {
    if (action === 'ConfirmDiscard') {
      this.getCurrentCompany();
    }
  }

  private getCurrentCompany(): void {
    this.companyGeneralService.loadCompany().subscribe((company: any) => {
      if (this.sessionService.role.IsFullAdmin && this.sessionService.feature.IsMultiCompanyMember) {
        this.accountantSummaryService.updateCompanyInvoiceGeneral(company);
      }
    });
  }

  private resetForm(): void {
    this.companyGeneralService.tabDirty = false;
    this.childIsValid = Array(3).fill(true);
    this.childIsDirty = Array(3).fill(false);

    this.resetChildFrom = true;
    this.isDirty = false;

    this.companyGeneralService.editMode = false;
  }
}
