<app-dialog
  [(visible)]="companyGeneralService.isVisibaleHolidayDialog"
  (visibleChange)="clearGridAction($event)"
  (dblclick)="dialogDblclick()"
>
  <div>
    <app-grid
      [editMode]="!sessionService.role.IsReadOnly && !sessionService.isSubmitting && !sessionService.isGetting"
      [(data)]="gridData"
      (action)="onGridHolidaySettingButtonClicked($event)"
      (saveChangesEvent)="onSaveChangeHolidayGrid($event)"
      [scrollOnDataSourceChange]="false"
      [reFocusAfterChangeEdit]="false"
      (dblClick)="gridDblClick($event)"
      [filterable]="'menu'"
      [filter]="dataFilter"
      (filterChange)="onGridFilterChange($event)"
      [idColumn]="'flag'"
      [(selectedId)]="selectedId"
      [(triggerUpdate)]="triggerUpdate"
      #kendoGridComponent
      [(filterClear)]="filterClear"
      [(sortClear)]="sortClear"
      [allowActionNonEditmode]="true"
    >
      <app-grid-action [action]="'AddNew'" [label]="'SenioritySupplements.AddNew' | translate"></app-grid-action>

      <!-- Date -->
      <app-grid-column
        [title]="'CompanyHolidaySetting.DateHeader' | translate"
        [type]="'datepicker'"
        [field]="'Date'"
        [editable]="false"
        [width]="65"
        [headerTooltip]="'CompanyHolidaySetting.DateHeader' | translate"
        [isFilterable]="true"
      ></app-grid-column>

      <!-- ConsiderHoliday -->
      <app-grid-column
        [title]="'CompanyHolidaySetting.ConsiderHolidayHeader' | translate"
        [type]="'checkboxgrid'"
        [field]="'ConsiderHoliday'"
        [editable]="true"
        [width]="75"
        [title]="'CompanyHolidaySetting.ConsiderHolidayHeader' | translate"
        [isFilterable]="true"
      ></app-grid-column>

      <!-- DisablesBankPayments  -->
      <app-grid-column
        [title]="'CompanyHolidaySetting.DisablesBankPaymentsHeader' | translate"
        [type]="'checkbox'"
        [field]="'DisablesBankPayments'"
        [editable]="false"
        [width]="75"
        [title]="'CompanyHolidaySetting.DisablesBankPaymentsHeader' | translate"
        [isFilterable]="true"
      ></app-grid-column>

      <!-- Description -->
      <app-grid-column
        [title]="'CompanyHolidaySetting.DescriptionHeader' | translate"
        [type]="'text'"
        [field]="'Description'"
        [editable]="false"
        [width]="255"
        [title]="'CompanyHolidaySetting.DescriptionHeader' | translate"
        [isFilterable]="true"
      ></app-grid-column>
    </app-grid>
  </div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  class="holiday-create-dialog"
  [(visible)]="companyGeneralService.isVisibleAddNewHolidayDialog"
  (visibleChange)="onDialogCreateOpen($event)"
  (action)="createNewDialogAction($event)"
  (dblclick)="dialogDblclick()"
>
  <!-- New Date -->
  <div class="calendar-box FormElement">
    <app-dev-express-calendar [editMode]="true" [(value)]="newHolidayDate"></app-dev-express-calendar>
  </div>

  <!-- New Description -->
  <div class="FormElement">
    <app-text-edit
      [label]="'CompanyHolidaySetting.NewHolidayDescriptionLabel' | translate"
      [editMode]="true"
      [type]="'text'"
      [(value)]="newHolidayDescription"
      [isTextArea]="true"
      [TextAreaRows]="2"
    ></app-text-edit>
  </div>

  <!-- is repeated for 10 year -->
  <div class="FormElement">
    <app-check-edit
      [editMode]="true"
      [(value)]="isRepeatedForTenYear"
      [label]="'CompanyHolidaySetting.IsRepeatedForTenYearLabel' | translate"
    >
    </app-check-edit>
  </div>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Create'"></app-dialog-action>
</app-dialog>
