import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormComponentBase } from '../../common/form-component-base';

@Component({
  selector: 'app-share-percentage',
  templateUrl: './share-percentage.component.html'
})
export class SharePercentageComponent extends FormComponentBase {
  @Input() public label: string;
  @Input() public disable: boolean;

  private currentfirstValue: number;
  @Input()
  public get firstValue(): number {
    return this.currentfirstValue;
  }
  public set firstValue(value: number) {
    if (this.currentfirstValue !== value) {
      this.currentfirstValue = value;
      this.firstValueChange.emit(this.currentfirstValue);
    }
  }
  @Output() public firstValueChange: EventEmitter<number> = new EventEmitter<number>();

  private currentsecondValue: number;
  @Input()
  public get secondValue(): number {
    return this.currentsecondValue;
  }
  public set secondValue(value: number) {
    if (this.currentsecondValue !== value) {
      this.currentsecondValue = value;
      this.secondValueChange.emit(this.currentsecondValue);
    }
  }
  @Output() public secondValueChange: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
    super();
    this.childIsDirty = Array(2).fill(false);
  }
}
