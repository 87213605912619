import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DxDropDownBoxComponent } from 'devextreme-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ControlComponentBase } from '../../common/control-component-base';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html'
})
export class MultiSelectComponent extends ControlComponentBase<any> implements OnInit, OnDestroy {
  private translationServiceTerms: string[];
  private allLabel: string;

  constructor(
    elementRef: ElementRef,
    changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private sessionService: SessionService
  ) {
    super(elementRef, changeDetectorRef);
    this.setTimeout = false;

    this.translationServiceTerms = ['DropdownList.All'];
  }

  @Input() public gridDataSource: any[] = [];
  @Input() public displayExp: string;
  @Input() public valueExp: string;
  @Input() public placeHolder: string;
  @Input() public label: string;
  @Input() public required: boolean;
  @Input() public requiredLabel: boolean;
  @Input() public nullValueLabel: any;
  @Input() public tooltipContent: string;
  @Input() public columns: string[] = [];
  @Input() public disabled = false;

  public get emptyDataSource(): boolean {
    return !this.gridDataSource || (this.gridDataSource && this.gridDataSource.length === 0);
  }

  @Output() public SelectValueIdsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @ViewChild('dxDropDownBoxControl', { static: true }) public dxDropDownBoxControl: DxDropDownBoxComponent | any;

  private selectValueIdsValue: number[] = [];
  @Input()
  public get SelectValueIds(): number[] {
    return this.selectValueIdsValue;
  }
  public set SelectValueIds(values: number[]) {
    if (values !== this.selectValueIdsValue) {
      this.selectValueIdsValue = values;

      if (values) {
        this.gridBoxValue = values.map((ids: number) => {
          return this.gridDataSource.find((item: any) => item[this.valueExp] === ids);
        });
      }

      this.SelectValueIdsChange.emit(values);
    }
  }

  private gridBoxValue: any[] = [];
  public get GridBoxValue(): any[] {
    return this.gridBoxValue;
  }

  public set GridBoxValue(value: any[]) {
    this.gridBoxValue = value;

    if (this.gridBoxValue && this.gridBoxValue.length > 0) {
      this.selectValueIdsValue = this.gridBoxValue.map((item: any) => {
        let id: number;
        id = item[this.valueExp];
        return id;
      });
    } else {
      this.selectValueIdsValue = [];
    }

    this.SelectValueIdsChange.emit(this.selectValueIdsValue);
  }

  public get isSelectAll(): boolean {
    return (
      this.selectValueIdsValue && this.gridDataSource && this.selectValueIdsValue.length === this.gridDataSource.length
    );
  }

  public get requiredValue(): boolean {
    if (this.required && this.gridDataSource && this.gridDataSource.length > 0) {
      return !this.selectValueIdsValue || (this.selectValueIdsValue && this.selectValueIdsValue.length === 0);
    }
    return false;
  }

  public ngOnInit(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateService.get(this.translationServiceTerms).subscribe((translations: { [key: string]: string }) => {
        this.allLabel = translations['DropdownList.All'];
      });
    });
  }

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public controlDisplayTextChange() {
    this.onTextChange();
  }

  private onTextChange() {
    setTimeout(() => {
      const placeHolder = this.dxDropDownBoxControl.element.nativeElement.querySelector(
        '.dx-texteditor-input-container .dx-placeholder'
      );
      if (this.isSelectAll && this.dxDropDownBoxControl.element && this.dxDropDownBoxControl.element.nativeElement) {
        const inputTextControl = this.dxDropDownBoxControl.element.nativeElement.querySelector(
          '.dx-texteditor-input-container input.dx-texteditor-input'
        );

        if (inputTextControl) {
          (inputTextControl as any).value = this.allLabel;
        }

        if (placeHolder) {
          placeHolder.style = 'visibility: hidden';
        }
      } else {
        if (placeHolder) {
          placeHolder.style = 'visibility: visible';
        }
      }
    });
  }
}
