import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from 'src/app/common/constants';
import { Global } from 'src/app/common/global';
import { IExternalAccount, IFinanceAccountType, ILanguage, ISalaryRecordPersistenceType, ISalarySummaryCategory, ISalaryType, ISalaryTypeCategory, ISalaryTypeCategoryView, ISalaryTypeDimensionDistributionOption, IUnitType } from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { SettingService } from 'src/app/services/setting.service';
import { ReportDialogService } from 'src/app/shared-components/report-dialog/report-dialog.service';
import { CompanyAdvancedTypesService } from '../company-advanced-types.service';
import { AdvancedSalaryTypesService } from './advanced-salary-types.service';
var SalaryTypesSingleComponent = /** @class */ (function () {
    function SalaryTypesSingleComponent(dataService, staticDataService, sessionService, settingService, reportDialogService, advancedSalaryTypesService, companyAdvancedTypesService) {
        var _this = this;
        this.dataService = dataService;
        this.staticDataService = staticDataService;
        this.sessionService = sessionService;
        this.settingService = settingService;
        this.reportDialogService = reportDialogService;
        this.advancedSalaryTypesService = advancedSalaryTypesService;
        this.companyAdvancedTypesService = companyAdvancedTypesService;
        this.gridData = [];
        this.accountTypes = [];
        this.summaryCategories = [];
        this.unitTypes = [];
        this.persistanceTypes = [];
        this.categories = [];
        this.externalReferences = [];
        this.dimensionDistributions = [];
        this.hasSalaryTypeModule = false;
        this.isShowWarrningChangeMode = false;
        this.triggerUpdate = false;
        this.isWattingLoading = false;
        this.childIsDirty = [];
        this.childIsValid = [];
        this.editClickAction = new EventEmitter();
        this.addClickAction = new EventEmitter();
        this.inheritDefaultValue = '<Nedarvet, klik for at overstyre>';
        this.ngUnsubscribe = new Subject();
        this.showTranslationDialog = new EventEmitter();
        this.advancedSalaryTypesService.addNewShortcutChange
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && !_this.sessionService.role.IsReadOnly) {
                _this.addClickAction.emit();
            }
        });
        this.advancedSalaryTypesService.editShortcutChange
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value &&
                _this.sessionService.role.IsFullAdmin &&
                !_this.sessionService.isGetting &&
                !_this.companyAdvancedTypesService.editMode) {
                _this.editClickAction.emit(true);
            }
        });
        this.resetDirty();
    }
    Object.defineProperty(SalaryTypesSingleComponent.prototype, "isLanguageModuleEnable", {
        get: function () {
            return this.sessionService.feature.hasModuleId(5);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryTypesSingleComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryTypesSingleComponent.prototype, "isFromDenmark", {
        get: function () {
            return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.DENMARK_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryTypesSingleComponent.prototype, "isFromGreenland", {
        get: function () {
            return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryTypesSingleComponent.prototype, "isFromSwedish", {
        get: function () {
            return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.SWEDEN_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    SalaryTypesSingleComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (Global.SESSION.SignOnToken.LanguageId === 2) {
            this.inheritDefaultValue = '<Inherited, click to override>';
        }
        else {
            this.inheritDefaultValue = '<Nedarvet, klik for at overstyre>';
        }
        this.isWattingLoading = true;
        this.staticDataService.FinanceAccountType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.accountTypes = data); });
        this.staticDataService.SalarySummaryCategory.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.summaryCategories = data); });
        this.staticDataService.SalaryRecordPersistenceType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.persistanceTypes = data); });
        this.staticDataService.SalaryTypeCategory.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.categories = data;
        });
        this.staticDataService.DimensionRedistributionBehavior.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.dimensionDistributions = data); });
        this.dataService.Integrations_GetAllExternalAccounts().subscribe(function (data) {
            _this.externalReferences = data;
            if (_this.externalReferences && _this.externalReferences.length > 0) {
                _this.externalReferences.forEach(function (model) {
                    model.FriendlyName = model.AccountNumber + (model.AccountName ? ' - ' + model.AccountName : '');
                });
            }
        });
        this.unitTypes = Global.UNIT_TYPES;
        this.settingService.showModuleMessage('company.advancedsalarytypes');
        if (this.isMobile) {
            this.sessionService.isShowHugeFeaturesAlert = true;
        }
    };
    SalaryTypesSingleComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    SalaryTypesSingleComponent.prototype.onChange = function (event, field, index) {
        if (field) {
            this.companyAdvancedTypesService.onChangeInheritValue(field, event, index);
        }
        this.companyAdvancedTypesService.tabDirty = this.childIsDirty.indexOf(true) >= 0;
    };
    SalaryTypesSingleComponent.prototype.onTranslationButtonClick = function () {
        this.showTranslationDialog.emit();
    };
    SalaryTypesSingleComponent.prototype.resetDirty = function () {
        this.childIsDirty = Array(29).fill(false);
        this.childIsValid = Array(18).fill(false);
        this.companyAdvancedTypesService.tabDirty = false;
    };
    SalaryTypesSingleComponent.prototype.revertInheritValue = function () {
        var _this = this;
        this.companyAdvancedTypesService.listRevertValues.forEach(function (element) {
            _this.companyAdvancedTypesService.selectedType[element.field] = null;
            _this.childIsDirty[element.index] = false;
            _this.companyAdvancedTypesService.tabDirty = _this.childIsDirty.indexOf(true) >= 0;
        });
    };
    return SalaryTypesSingleComponent;
}());
export { SalaryTypesSingleComponent };
