import { ApplicationRef, ComponentFactory, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injector, Type } from '@angular/core';
import { DialogActionComponent } from '../custom-controls/dialogs/dialog-action.component';
import { DialogComponent } from '../custom-controls/dialogs/dialog.component';
import { IFrameDialogComponent } from '../custom-controls/dialogs/i-frame-dialog.component';
import { SessionService } from './session/session.service';
import * as i0 from "@angular/core";
import * as i1 from "./session/session.service";
var DialogService = /** @class */ (function () {
    function DialogService(componentFactoryResolver, appRef, injector, session) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.appRef = appRef;
        this.injector = injector;
        this.session = session;
        this.isShowing = false;
        this.isBindding = false;
    }
    DialogService.prototype.showMessageBox = function (content, actions, title) {
        var _this = this;
        if (actions === void 0) { actions = ['Ok']; }
        if (title === void 0) { title = ' '; }
        if (this.isShowing) {
            return;
        }
        var buttons = [];
        if (actions) {
            buttons = actions.map(function (action) {
                var button = new DialogActionComponent(_this.session);
                button.type = action;
                return button;
            });
        }
        var hostDialogRef = this.appendDialogComponentToBody(DialogComponent);
        hostDialogRef.instance.title = title;
        hostDialogRef.instance.leadingText = content;
        hostDialogRef.instance.actions = buttons;
        hostDialogRef.instance.visible = true;
        this.isShowing = true;
        hostDialogRef.instance.visibleChange.subscribe(function (visible) {
            if (!visible) {
                buttons.forEach(function (b) { return b.ngOnDestroy(); });
                _this.removeDialogComponentFromBody(hostDialogRef);
                _this.isShowing = false;
            }
        });
    };
    DialogService.prototype.showIFrameDialog = function (content, title, isSelfService) {
        var _this = this;
        if (title === void 0) { title = ' '; }
        if (this.isShowing) {
            return;
        }
        var hostDialogRef = this.appendDialogComponentToBody(IFrameDialogComponent);
        hostDialogRef.instance.isSelfService = isSelfService;
        hostDialogRef.instance.title = title;
        hostDialogRef.instance.visible = true;
        hostDialogRef.instance.iframeContent = content;
        this.isShowing = true;
        hostDialogRef.instance.visibleChange.subscribe(function (visible) {
            if (!visible) {
                _this.removeDialogComponentFromBody(hostDialogRef);
                _this.isShowing = false;
            }
        });
    };
    DialogService.prototype.appendDialogComponentToBody = function (component) {
        var componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        var componentRef = componentFactory.create(this.injector);
        this.appRef.attachView(componentRef.hostView);
        var domElem = componentRef.hostView.rootNodes[0];
        document.body.appendChild(domElem);
        return componentRef;
    };
    DialogService.prototype.removeDialogComponentFromBody = function (componentRef) {
        this.appRef.detachView(componentRef.hostView);
        componentRef.changeDetectorRef.detectChanges();
        componentRef.destroy();
    };
    DialogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i1.SessionService)); }, token: DialogService, providedIn: "root" });
    return DialogService;
}());
export { DialogService };
