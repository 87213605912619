import { EventEmitter, Injectable } from '@angular/core';
import { Transition, TransitionService } from '@uirouter/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Global } from '../../common/global';
import {
  ICompanyUser,
  IDepartment,
  IReport,
  IReportArea,
  IReportParameter,
  IReportParameterValue,
  IReportRequest,
  ISalaryCycle,
  IUserEmployment
} from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import { ReportParametersDefaultValue } from './report-parameters-default-value';

// export interface IReportAreaSimple {
//   AreaId: number;
//   State: IReportState[];
// }

export interface IReportState {
  AreaId: number;
  Id: number;
  FormatOptions?: string[];
  FormatValue?: string;
  Reports?: IReportControl[];
}

export interface IReportControl {
  ReportId: number;
  ControlKey: string;
  ControlType: string;
  ControlValues?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReportDialogService {
  private reportAreas: IReportArea[];
  public selectedUserId: number;
  public selectedEmploymentId: number;

  constructor(
    public staticDataService: StaticDataService,
    public sessionService: SessionService,
    public settingService: SettingService,
    public dataService: DataService
  ) {
    this.staticDataService.ReportArea.pipe().subscribe((data: IReportArea[]) => (this.reportAreas = data));
  }

  private appReportSubject: BehaviorSubject<IReportState[]>;
  public get appReport(): Observable<IReportState[]> {
    if (!this.appReportSubject) {
      this.appReportSubject = new BehaviorSubject<IReportState[]>([]);
    }
    return this.appReportSubject.asObservable();
  }

  public changeReportImage(data: IReportState) {
    if (!this.appReportSubject) {
      this.appReportSubject = new BehaviorSubject<IReportState[]>([]);
    }

    const allState = this.appReportSubject.value;
    const currentState: IReportState = allState.find(
      (model: IReportState) => model.Id === data.Id && model.AreaId === data.AreaId
    );

    if (currentState) {
      const indexOfCurrentState = allState.indexOf(currentState);
      allState[indexOfCurrentState] = data;
    } else {
      allState.push(data);
    }

    this.appReportSubject.next(allState);
  }

  private reportDialogVisibleValue = false;
  public get reportDialogVisible() {
    return this.reportDialogVisibleValue;
  }

  public set reportDialogVisible(value: boolean) {
    if (value !== this.reportDialogVisibleValue) {
      this.reportDialogVisibleValue = value;
      this.visibleChange.emit(value);
    }
  }

  public key = '';
  public reportParams: ReportParametersDefaultValue;

  public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get localUrl(): string {
    if (this.sessionService.currentState) {
      switch (this.sessionService.currentState) {
        // Employee Area
        case 'tabs.employee.general':
          return '/employee/general';
        case 'tabs.employee.hiringstatus':
          return '/employee/hiringstatus';
        case 'tabs.employee.time':
          return '/employee/time';
        case 'tabs.employee.payrolldata':
          return '/employee/payrolldata';
        case 'tabs.employee.payslip':
          return '/employee/payslip';

        // Company Area
        case 'tabs.company.general':
          return '/company/general';
        case 'tabs.company.payrolldata':
          return '/company/payrolldata';
        case 'tabs.company.salarybatches':
          return '/company/salarybatches';
        case 'tabs.company.balances.residualvacation':
          return '/company/residualvacation';
        case 'tabs.company.balances.extravacationhours':
          return '/company/extravacationhours';
        case 'tabs.company.approval.timeentry':
          return '/company/approval/timeentry';
        case 'tabs.company.configuration.hiringstatus':
          return '/company/employmenttemplates';
        case 'tabs.company.configuration.departments':
          return '/company/departments';
        case 'tabs.company.configuration.integrations':
          return '/company/integrations';
        case 'tabs.company.configuration.dataimport':
          return '/company/dataimport';
        case 'tabs.company.configuration.settings':
          return '/company/settings';
        case 'tabs.company.configuration.advancedtypes.advancedsalarytypes':
          return '/company/advancedsalarytypes';
        case 'tabs.company.configuration.advancedtypes.timeentrytypes':
          return '/company/timeentrytypes';
        case 'tabs.company.configuration.advancedtypes.salarytypessingle':
          return '/company/salarytypessingle';
        case 'tabs.company.configuration.advancedtypes.timeentrytypessingle':
          return '/company/timeentrytypessingle';
        case 'tabs.company.modules':
          return '/company/modules';

        // Self service Area
        case 'tabs.selfservice.general':
          return '/selfservice/general';
        case 'tabs.selfservice.payslip':
          return '/selfservice/payslip';
        case 'tabs.selfservice.time':
          return '/selfservice/time';

        // Accountant Area
        case 'tabs.accountant.companies':
          return '/accountant/companies';
        case 'tabs.accountant.users':
          return '/accountant/users';
        case 'tabs.accountant.configuration':
          return '/accountant/configuration';

        default:
          return '';
      }
    }
    return '';
  }

  public get isVisibleReportIcon(): boolean {
    if (this.reportAreas && this.reportAreas.length > 0 && this.localUrl) {
      return !!this.reportAreas.find((item: IReportArea) => item.AppUri === this.localUrl);
    }
    return false;
  }

  public onShowReportsEventClick(): void {
    if (this.isVisibleReportIcon) {
      this.key = this.reportAreas.find((item: IReportArea) => item.AppUri === this.localUrl).Key;
      this.reportDialogVisible = true;
    }
  }
}
