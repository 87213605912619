import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Constants } from 'src/app/common/constants';
import { Global } from 'src/app/common/global';
import { ModuleCheckComponent } from 'src/app/custom-controls/module-check/module-check.component';
import { DefaultAvatar } from 'src/app/model/default-avatar';
import { ICompanyUser } from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { SessionService } from 'src/app/services/session/session.service';
import { SettingService } from 'src/app/services/setting.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styles: []
})
export class UserMenuComponent implements OnInit {
  @Input() public isDisable = false;
  public menuOpen = false;
  public avatar: string;
  public fullName: string;
  public firstname: string;
  public lastname: string;
  public role: string;
  public hasBackendAccess = false;
  public isShowAdHocSupport = false;
  public showcontentdialog = false;
  public changeAvatarDialog: boolean;
  public showLanguageChooserDialog: boolean;
  public hasLanguageModule = false;
  public languageId: number;
  public isTesting = true;

  public get environment(): string {
    return environment.environment;
  }

  private isFemale = false;
  private srcImageFemale: string = DefaultAvatar.imgFemaleAvatar;
  private srcImageMale: string = DefaultAvatar.imgMaleAvatar;
  private keepOpen = false;

  @ViewChild('userLanguageModuleCheck', { static: true }) private userLanguageModuleCheck: ModuleCheckComponent;

  constructor(
    public sessionService: SessionService,
    private settingService: SettingService,
    private staticDataService: StaticDataService,
    private dataService: DataService,
    private authenticationService: AuthenticationService,
    private broadcaster: BroadcastService
  ) {}

  public ngOnInit() {
    this.getUserImage();
    this.getUsercompany();
    if (!this.avatar) {
      this.avatar = this.isFemale ? this.srcImageFemale : this.srcImageMale;
    }
    this.isFemale = /* Global.SESSION ? Global.SESSION.IsFemale :*/ undefined;
    this.fullName = Global.SESSION ? Global.SESSION.CurrentCompanyUser.FullName : undefined;
    this.role = Global.SESSION ? Global.SESSION.CurrentRole.Name : undefined;
    this.hasBackendAccess = Global.SESSION ? Global.SESSION.HasBackendAccess : undefined;
    this.languageId = Global.SESSION ? Global.SESSION.SignOnToken.Language.Id : undefined;
  }

  public raiseError(): void {
    throw new Error('Test AngularJS error');
  }

  public onAvatarChanged(): void {
    this.staticDataService.loadcurrentUserImage();
  }

  private onWaittingLogOut(): void {
    if (!this.sessionService.isGetting) {
      this.onLogout();
    } else {
      setTimeout(() => {
        this.onWaittingLogOut();
      }, 500);
    }
  }

  public showModuleDialog(): void {
    this.sessionService.showMoreMenuToggled = false;
    this.sessionService.showHelpMenuToggled = false;
    this.sessionService.showMenuToggled = false;
    this.userLanguageModuleCheck.onClick();
  }

  private closeOtherMenus(): void {
    this.sessionService.showHelpMenuToggled = false;
    this.sessionService.showMoreMenuToggled = false;
    this.sessionService.showMenuToggled = false;
    this.settingService.showCompanySelector = !(
      this.sessionService.feature.hasModuleId(20) ||
      (Global.SESSION && Global.SESSION.HasBackendAccess)
    )
      ? false
      : this.settingService.showCompanySelector;
  }

  @HostListener('document:click')
  public closeMenu() {
    this.menuOpen = this.keepOpen;
    this.keepOpen = false;
    this.closeOtherMenus();
  }

  public toggleMenu() {
    this.menuOpen = !this.menuOpen;
    this.keepOpen = this.menuOpen;
    this.closeOtherMenus();
  }

  public onTestStatus(status: any): void {
    if (this.sessionService.isGetting || this.sessionService.isSubmitting) {
      return;
    }
    switch (status) {
      case 'random': // Random test
        this.dataService.Miscellaneous_Random().subscribe();
        break;
      case 'modal': // Modal Dialog test
        this.showcontentdialog = true;
        break;
      case 400:
        this.dataService.Miscellaneous_FailSoft().subscribe();
        break;
      case 401:
        this.dataService.Miscellaneous_FailUnauhorized().subscribe();
        break;
      case 404:
        this.dataService.Miscellaneous_FailNotFound().subscribe();
        break;
      case 500:
        this.dataService.Miscellaneous_FailHard().subscribe();
        break;
    }
  }

  public changeLanguage(args: any): void {
    this.setLanguageContext(args.languageId);
  }

  private setLanguageContext(languageId: number): void {
    this.dataService.Users_SetLanguageContext(languageId).subscribe(
      () => {
        document.cookie = 'languageId=' + languageId;
        this.staticDataService
          .loadStaticData()
          .then(() => {
            window.location.reload();
          })
          .catch(() => {
            // handle languge load fail
            this.settingService.ReloadWarrning = false;
          })
          .finally(() => {
            // handle languge load fail
            this.settingService.ReloadWarrning = false;
          });
      },
      () => {
        // handle languge load fail
        this.settingService.ReloadWarrning = false;
      }
    );
  }

  public onLogout(): void {
    this.sessionService.NavigateTo('login');
    this.authenticationService.logout().subscribe(() => {}, () => {});
  }

  public onShowAvatarDialog(): void {
    this.sessionService.showMoreMenuToggled = false;
    this.sessionService.showHelpMenuToggled = false;
    this.changeAvatarDialog = true;
  }

  public onChangePasswordClick(): void {
    this.settingService.showPasswordChangeDialog = true;
  }

  public onChangeLanguageDialogClick(): void {
    this.showLanguageChooserDialog = true;
  }

  public onShowAdHocSupport(): void {
    this.isShowAdHocSupport = true;
  }

  private getUserImage(): void {
    this.staticDataService.currentUserImage.subscribe((image) => {
      if (image && image.Image !== this.avatar) {
        this.avatar = image.Image || (this.isFemale ? this.srcImageFemale : this.srcImageMale);
      }
    });
  }

  private getUsercompany(): void {
    this.firstname = Global.SESSION.CurrentCompanyUser.FirstName;
    this.lastname = Global.SESSION.CurrentCompanyUser.LastName;
    // this.dataService
    //   .CompanyUsers_GetCompanyUserById(Global.SESSION.CurrentCompanyUser.Id)
    //   .subscribe((data: ICompanyUser): void => {
    //     this.firstname = data.FirstName;
    //     this.lastname = data.LastName;
    //   });
  }
}
