import { Global } from '../../common/global';
var Role = /** @class */ (function () {
    function Role() {
    }
    Object.defineProperty(Role.prototype, "IsRoleEmployeeAndHigher", {
        get: function () {
            return (Global.SESSION &&
                (Global.SESSION.CurrentRole.Key === 'FullAdmin' ||
                    Global.SESSION.CurrentRole.Key === 'SalaryAdmin' ||
                    Global.SESSION.CurrentRole.Key === 'Employee'));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Role.prototype, "IsSalaryAdminAndHigher", {
        get: function () {
            return (Global.SESSION &&
                (Global.SESSION.CurrentRole.Key === 'FullAdmin' || Global.SESSION.CurrentRole.Key === 'SalaryAdmin'));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Role.prototype, "IsAccountant", {
        get: function () {
            return Global.SESSION && (Global.SESSION.IsAccountAdmin || Global.SESSION.IsAccountMember);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Role.prototype, "IsAccountAdmin", {
        get: function () {
            return Global.SESSION && Global.SESSION.IsAccountAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Role.prototype, "IsFullAdmin", {
        get: function () {
            return Global.SESSION && Global.SESSION.CurrentRole.Key === 'FullAdmin';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Role.prototype, "IsSalaryAdmin", {
        get: function () {
            return Global.SESSION && Global.SESSION.CurrentRole.Key === 'SalaryAdmin';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Role.prototype, "IsEmployeeRole", {
        get: function () {
            return Global.SESSION && Global.SESSION.CurrentRole.Key === 'Employee';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Role.prototype, "EmployeeWithRoleAsManager", {
        get: function () {
            return (Global.SESSION &&
                Global.SESSION.CurrentRole &&
                Global.SESSION.CurrentRole.Key === 'Employee' &&
                Global.SESSION.ManagerForUserEmploymentIds &&
                Global.SESSION.ManagerForUserEmploymentIds.length > 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Role.prototype, "IsPaymentApprover", {
        get: function () {
            return Global.SESSION && Global.SESSION.IsPaymentApprover;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Role.prototype, "IsReadOnly", {
        get: function () {
            return Global.SESSION && Global.SESSION.CurrentRole.Key === 'ReadOnly';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Role.prototype, "IsAdminWithoutMultiCompanyRole", {
        get: function () {
            return !Global.SESSION.AccountRoleId && Global.SESSION.CurrentRole.Key === 'FullAdmin';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Role.prototype, "IsMultiCompanyUser", {
        get: function () {
            return (Global.SESSION &&
                (Global.SESSION.IsAccountAdmin || Global.SESSION.IsAccountMember) &&
                Global.SESSION.SignOnToken.UserAccountId !== null);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Role.prototype, "IsMultiCompanyAdmin", {
        get: function () {
            return (Global.SESSION &&
                Global.SESSION.IsAccountAdmin &&
                Global.SESSION.SignOnToken.UserAccountId !== null &&
                Global.SESSION.AccountRoleId === 90);
        },
        enumerable: true,
        configurable: true
    });
    return Role;
}());
export { Role };
