/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../custom-controls/text-edit/text-edit.component.ngfactory";
import * as i4 from "../../../custom-controls/text-edit/text-edit.component";
import * as i5 from "../../../services/session/session.service";
import * as i6 from "../../../custom-controls/dialogs/dialog.component.ngfactory";
import * as i7 from "../../../custom-controls/dialogs/dialog.component";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/common";
import * as i10 from "../../../custom-controls/dialogs/dialog-action.component.ngfactory";
import * as i11 from "../../../custom-controls/dialogs/dialog-action.component";
import * as i12 from "./batch-finalization-step-4-dialog.component";
import * as i13 from "../../../services/busy-indicator.service";
import * as i14 from "../../../services/api/data.service";
import * as i15 from "../../../services/employee.service";
import * as i16 from "../company-salary-batches.service";
var styles_BatchFinalizationStep4DialogComponent = [];
var RenderType_BatchFinalizationStep4DialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BatchFinalizationStep4DialogComponent, data: {} });
export { RenderType_BatchFinalizationStep4DialogComponent as RenderType_BatchFinalizationStep4DialogComponent };
function View_BatchFinalizationStep4DialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(2, 4210688, null, 0, i1.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.NgForm]), i0.ɵdid(4, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, null, 0, "input", [["autocomplete", "username"], ["class", "input-no-display"], ["id", "finalizesave_username"], ["name", "finalizesave_username"], ["readonly", "true"], ["type", "text"]], [[8, "value", 0]], null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "app-text-edit", [["id", "finalizesave_password"], ["name", "finalizesave_password"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.password = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_TextEditComponent_0, i3.RenderType_TextEditComponent)), i0.ɵdid(10, 114688, null, 0, i4.TextEditComponent, [i5.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { type: [0, "type"], value: [1, "value"], editMode: [2, "editMode"], label: [3, "label"], autocorrect: [4, "autocorrect"] }, { valueChange: "valueChange" }), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_9 = "password"; var currVal_10 = _co.password; var currVal_11 = true; var currVal_12 = i0.ɵunv(_v, 10, 3, i0.ɵnov(_v, 11).transform("CompanySalaryBatches.PasswordLabel")); var currVal_13 = true; _ck(_v, 10, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 4).ngClassValid; var currVal_5 = i0.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("Finalize.VettingDataNote")); _ck(_v, 6, 0, currVal_7); var currVal_8 = i0.ɵinlineInterpolate(1, "", _co.userName, ""); _ck(_v, 8, 0, currVal_8); }); }
export function View_BatchFinalizationStep4DialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "app-dialog", [], null, [[null, "action"], [null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("action" === en)) {
        var pd_0 = (_co.onDialogAction($event) !== false);
        ad = (pd_0 && ad);
    } if (("visibleChange" === en)) {
        var pd_1 = ((_co.visible = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_DialogComponent_0, i6.RenderType_DialogComponent)), i0.ɵdid(1, 49152, null, 1, i7.DialogComponent, [i8.DomSanitizer, i5.SessionService, i0.ElementRef], { overlayClose: [0, "overlayClose"], visible: [1, "visible"] }, { visibleChange: "visibleChange", action: "action" }), i0.ɵqud(603979776, 1, { buttonElements: 1 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_BatchFinalizationStep4DialogComponent_1)), i0.ɵdid(4, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, 0, 1, "app-dialog-action", [], null, null, null, i10.View_DialogActionComponent_0, i10.RenderType_DialogActionComponent)), i0.ɵdid(6, 180224, [[1, 4]], 0, i11.DialogActionComponent, [i5.SessionService], { disabled: [0, "disabled"], type: [1, "type"] }, null), (_l()(), i0.ɵeld(7, 0, null, 0, 1, "app-dialog-action", [], null, null, null, i10.View_DialogActionComponent_0, i10.RenderType_DialogActionComponent)), i0.ɵdid(8, 180224, [[1, 4]], 0, i11.DialogActionComponent, [i5.SessionService], { disabled: [0, "disabled"], type: [1, "type"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 4, "app-dialog", [["class", "blue-dialog"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.responseDialogVisible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_DialogComponent_0, i6.RenderType_DialogComponent)), i0.ɵdid(10, 49152, null, 1, i7.DialogComponent, [i8.DomSanitizer, i5.SessionService, i0.ElementRef], { leadingText: [0, "leadingText"], visible: [1, "visible"] }, { visibleChange: "visibleChange" }), i0.ɵqud(603979776, 2, { buttonElements: 1 }), (_l()(), i0.ɵeld(12, 0, null, 0, 1, "app-dialog-action", [], null, null, null, i10.View_DialogActionComponent_0, i10.RenderType_DialogActionComponent)), i0.ɵdid(13, 180224, [[2, 4]], 0, i11.DialogActionComponent, [i5.SessionService], { type: [0, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.visible; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.visible; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.sessionService.isSubmitting; var currVal_4 = "Cancel"; _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_5 = (!_co.password || _co.sessionService.isSubmitting); var currVal_6 = "Finalize"; _ck(_v, 8, 0, currVal_5, currVal_6); var currVal_7 = _co.response; var currVal_8 = _co.responseDialogVisible; _ck(_v, 10, 0, currVal_7, currVal_8); var currVal_9 = "Ok"; _ck(_v, 13, 0, currVal_9); }, null); }
export function View_BatchFinalizationStep4DialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-batch-finalization-step-4-dialog", [], null, null, null, View_BatchFinalizationStep4DialogComponent_0, RenderType_BatchFinalizationStep4DialogComponent)), i0.ɵdid(1, 180224, null, 0, i12.BatchFinalizationStep4DialogComponent, [i13.BusyIndicatorService, i14.DataService, i5.SessionService, i15.EmployeeService, i16.CompanySalaryBatchService], null, null)], null, null); }
var BatchFinalizationStep4DialogComponentNgFactory = i0.ɵccf("app-batch-finalization-step-4-dialog", i12.BatchFinalizationStep4DialogComponent, View_BatchFinalizationStep4DialogComponent_Host_0, { doRecalculation: "doRecalculation", step1Completed: "step1Completed", visible: "visible" }, { visibleChange: "visibleChange", finalizationExit: "finalizationExit", finalizationDone: "finalizationDone" }, []);
export { BatchFinalizationStep4DialogComponentNgFactory as BatchFinalizationStep4DialogComponentNgFactory };
