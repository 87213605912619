import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { isNumber } from '@progress/kendo-angular-dropdowns/dist/es2015/util';
import { GridComponent } from '@progress/kendo-angular-grid';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { EmployeeService } from 'src/app/services/employee.service';
import { ReportParametersDefaultValue } from 'src/app/shared-components/report-dialog/report-parameters-default-value';
import { Browser } from '../../common/browser';
import { FilterDateTimeGrid } from '../../common/filter-date-time-grid';
import { Global } from '../../common/global';
import { GridHelper } from '../../common/grid-helper';
import { NumericTextBoxOptions } from '../../custom-controls/numeric-edit/numeric-text-box-options';
import { ExtendSalaryPeriod } from '../../model/extend-salary-period';
import { ITimeEntryPreview } from '../../model/time-entry-model';
import {
  ICompanyUser,
  IRejectionInfo,
  ISalaryCycle,
  ISalaryPeriod,
  ISimpleKeyValuePair,
  ITimeEntryRecord,
  ITimeEntryStatus,
  ITimeEntryType,
  IUserEmployment,
  TimeEntryRecord
} from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import { ReportDialogService } from '../../shared-components/report-dialog/report-dialog.service';

@Component({
  selector: 'app-employee-time',
  templateUrl: './employee-time.component.html',
  styleUrls: ['./employee-time.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeTimeComponent implements OnInit, OnDestroy {
  public newEmploymentDialogVisible = false;
  private privateUserEmployment: IUserEmployment;
  private preventCellChangeEvent: boolean;
  private indexRemovePeriod: number;
  private selectedEntryDate: any;
  private isNotChange: boolean;
  public selectedRows: any[] = [];
  public selectedMinDate: Date;
  public defaultRecordValue: any = {};
  public createNewEmployeeDialogVisible = false;
  public filterClear = false;
  public listClassExcluded = ['EmployeeInfo', 'Footer'];

  @Input()
  public get userEmployment(): IUserEmployment {
    return this.privateUserEmployment;
  }

  public set userEmployment(value: IUserEmployment) {
    let preUserId: number;
    if (this.privateUserEmployment) {
      preUserId = this.privateUserEmployment.Id;
    }

    this.updateUnsavedRecords();
    this.privateUserEmployment = value;

    if (!this.privateUserEmployment || !this.privateUserEmployment.Id) {
      this.HasEmployment = false;
      return;
    }

    this.HasEmployment = true;
    if (!preUserId || preUserId !== value.Id) {
      this.filter = null;
      this.filterClear = true;

      this.tempselectedSalaryPeriodId = undefined;
      this.TimeEntryStatuses = [];
      this.TimeEntryRecords = [];
      this.DefaultTimeEntryRecord = [];
      this.newPreviewData = [];
      this.gridData = [];
      this.dataService
        .TimeEntryTypes_GetTimeEntryTypeViews()
        .subscribe((data: any[]): void => this.onGetTimeEntryTypesCompleted(data));
    }
  }

  private privateCurrentCompanyUser: ICompanyUser;
  @Input()
  public get currentCompanyUser(): ICompanyUser {
    return this.privateCurrentCompanyUser;
  }

  public set currentCompanyUser(value: ICompanyUser) {
    this.privateCurrentCompanyUser = value;
    this.resetStatusFilter();
  }

  public get isEnableDimensionPreference(): boolean {
    const pref = this.sessionService.getCompanyPreference('Dimension.UseDimensionValueEntities');
    return pref.Value === 'true' ? true : false;
  }

  public hasTimeModule = false;
  public HasEmployment = false;
  public selectedSalaryPeriodId: number;
  public SelectedTimeStatusId: number;
  public newPreviewData: any;
  public TimeEntryTypes: any[] = [];
  public UnitTypes: any[];
  public SalaryPeriods: ExtendSalaryPeriod[];
  public TimeEntryRecords: ITimeEntryRecord[] = [];
  public DefaultTimeEntryRecord: ITimeEntryRecord[];
  public TimeEntryStatuses: ITimeEntryStatus[] = [];
  public allowUsersToModifyOwnData = false;
  public allowSelfServiceTimeEntry = false;
  private translationServiceTerms: string[];
  private employeeSelectedId: number;

  public isDisableAddNew = false;
  public option: NumericTextBoxOptions = { min: null, step: 1, spinners: false };
  private defaultTimeEntryType: ITimeEntryType;
  public dimensionXValues: any[] = [];

  @ViewChild('kendoGridComponent', { static: false }) public kendoGrid: GridComponent;

  private isEmployeeTimeKeydownValue: boolean;
  @Input()
  public get isEmployeeTimeKeydown(): boolean {
    return this.isEmployeeTimeKeydownValue;
  }
  public set isEmployeeTimeKeydown(value: boolean) {
    this.isEmployeeTimeKeydownValue = value;
  }

  // Translation strings
  public PeriodWarningText: string;

  public AllText = 'All';
  public isSlect1020default = true;
  public get isMobile(): boolean {
    const browser: Browser = new Browser();
    return browser.isMobile;
  }

  public get noCPRMessage(): string {
    return this.currentCompanyUser && this.currentCompanyUser.HasDummyIdentityNumber ? 'Employee.NoValidCPR' : '';
  }

  public get isHideApprovePeriod(): boolean {
    return this.isSelfService || this.IsReadOnly;
  }

  public selectedSalaryCycleId: number;
  public currentSalaryCycle: ISalaryCycle;
  public currentSalaryPeriod: ISalaryPeriod;

  public currentTimeEntryRecord: ITimeEntryRecord;
  public TimeEntryStatus: ITimeEntryStatus[];
  public currentTimeEntryStatus: ITimeEntryStatus;

  filterEligibleforapproval: string;
  selectedemployeeIdinGrid: number;
  selectedIdinGrid: number;
  nameEmployeeSelect: string;
  public numberOption: any = { min: null, step: 0, spinners: false, decimals: 2, format: 'n2' };

  public get isHideDimension1(): boolean {
    return this.Dimension1Preference && !!this.Dimension1Preference.Value;
  }
  public get isHideDimension2(): boolean {
    return this.Dimension2Preference && !!this.Dimension2Preference.Value;
  }
  public get isHideDimension3(): boolean {
    return this.Dimension3Preference && !!this.Dimension3Preference.Value;
  }

  public get isSalaryAdminAndHigher(): boolean {
    return (
      Global.SESSION.CurrentCompanyUser &&
      this.employeeSelectedId !== Global.SESSION.CurrentCompanyUser.Id &&
      this.sessionService.role.IsSalaryAdminAndHigher
    );
  }

  public get isManager(): boolean {
    return (
      Global.SESSION.CurrentCompanyUser &&
      this.employeeSelectedId !== Global.SESSION.CurrentCompanyUser.Id &&
      this.sessionService.role.EmployeeWithRoleAsManager
    );
  }

  public get Dimension1Name(): string {
    if (!this.isHideDimension1) {
      return '';
    } else {
      const dimension1Object: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
        'Dimensions.Dimension1Name'
      );
      if (dimension1Object && dimension1Object.Value) {
        return dimension1Object.Value;
      }
    }

    return '1';
  }

  public get Dimension2Name(): string {
    if (!this.isHideDimension2) {
      return '';
    } else {
      const dimension2Object: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
        'Dimensions.Dimension2Name'
      );
      if (dimension2Object && dimension2Object.Value) {
        return dimension2Object.Value;
      }
    }

    return '2';
  }

  public get Dimension3Name(): string {
    if (!this.isHideDimension3) {
      return '';
    } else {
      const dimension3Object: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
        'Dimensions.Dimension3Name'
      );
      if (dimension3Object && dimension3Object.Value) {
        return dimension3Object.Value;
      }
    }

    return '3';
  }

  /*end variable of approval timeentry*/
  private preSalaryCycleId = 0;
  private preSalaryPeriodId = 0;
  private preStatus = 0;

  constructor(
    public employeeService: EmployeeService,
    public translateService: TranslateService,
    public dataService: DataService,
    public settingService: SettingService,
    public staticDataService: StaticDataService,
    public sessionService: SessionService,
    public reportDialogService: ReportDialogService
  ) {
    this.translationServiceTerms = [
      'EmploymentTime.PeriodWarning',
      'CompanySalaryBatches.SalaryCycle_Item_All_Text',
      'EmploymentTime.EligibleForApproval'
    ];

    this.UnitTypes = JSON.parse(JSON.stringify(Global.UNIT_TYPES));

    this.reportDialogService.reportParams = new ReportParametersDefaultValue();
    this.employeeService.employee
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (employee: ICompanyUser) =>
          (this.reportDialogService.reportParams.employeeId = employee ? employee.Id : undefined)
      );
    this.employeeService.employment
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (employment: IUserEmployment) =>
          (this.reportDialogService.reportParams.employmentId = employment ? employment.Id : undefined)
      );
  }

  public MessagErrorApi: string;
  public isErrorApiMessage = false;

  public get IsActive(): boolean {
    return (
      this.sessionService.currentState === 'tabs.employee.time' ||
      this.sessionService.currentState === 'tabs.selfservice.time'
    );
  }

  public get isSelfService(): boolean {
    return this.sessionService.currentState === 'tabs.selfservice.time';
  }

  public get isEmployeeTabs(): boolean {
    return this.sessionService.currentState.includes('tabs.employee');
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  public get allowToApprove(): boolean {
    return (
      this.currentCompanyUser &&
      !(
        this.sessionService.role.IsEmployeeRole &&
        Global.SESSION &&
        Global.SESSION.CurrentCompanyUser.Id === this.currentCompanyUser.Id
      )
    );
  }

  public get SelectedSalaryPeriod(): ISalaryPeriod {
    if (!this.selectedSalaryPeriodId || !this.SalaryPeriods || this.SalaryPeriods.length <= 0) {
      return undefined;
    }

    return this.SalaryPeriods.filter((period: ExtendSalaryPeriod): boolean => {
      return period.Id === this.selectedSalaryPeriodId;
    })[0];
  }

  private get Dimension1Preference(): ISimpleKeyValuePair {
    return this.sessionService.getCompanyPreference('Dimensions.Dimension1Name');
  }

  private get Dimension2Preference(): ISimpleKeyValuePair {
    return this.sessionService.getCompanyPreference('Dimensions.Dimension2Name');
  }

  private get Dimension3Preference(): ISimpleKeyValuePair {
    return this.sessionService.getCompanyPreference('Dimensions.Dimension3Name');
  }

  public timeStatusChanged(): void {
    this.filterTimeEntriesByStatus();
  }

  public get isAllowEditGridOption(): boolean {
    const currentState: string = this.sessionService.currentState;
    switch (currentState) {
      case 'tabs.employee.time':
        return true;
      case 'tabs.selfservice.time':
        if (this.allowSelfServiceTimeEntry) {
          return true;
        }
        break;
      default:
        break;
    }
    return false;
  }

  public get DisbaleTooltipAddNewEmployeetime(): string {
    if (!this.isAllowEditGridOption) {
      return 'EmploymentTime.DisableGridTimeMessage';
    }
    return '';
  }

  public timeEntryStatus: ITimeEntryStatus[];

  public ngOnInit(): void {
    const currentState: string = this.sessionService.currentState;
    switch (currentState) {
      case 'tabs.employee.time':
        const objectAllowUsersToModifyOwnData: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
          'Access.AllowUsersToModifyOwnData'
        );
        if (objectAllowUsersToModifyOwnData && objectAllowUsersToModifyOwnData.Value === 'true') {
          this.allowUsersToModifyOwnData = true;
        }

        break;
      case 'tabs.selfservice.time':
        const objectAllowSelfServiceTimeEntry: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
          'Access.AllowSelfServiceTimeEntry'
        );
        if (objectAllowSelfServiceTimeEntry && objectAllowSelfServiceTimeEntry.Value === 'true') {
          this.allowSelfServiceTimeEntry = true;
        }

        break;
      default:
        break;
    }

    this.employeeService.selectedEmployeeId.subscribe((value: number) => {
      this.employeeSelectedId = value;
    });

    this.sessionService.OnLocationChangeStart.pipe(takeUntil(this.ngUnsubscribe)).subscribe((transition: any): void => {
      if (this.IsActive) {
        this.updateUnsavedRecords();
      }
    });

    this.staticDataService.TimeEntryStatus.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (timeEntryStatus: ITimeEntryStatus[]) => {
        this.timeEntryStatus = timeEntryStatus;
        if (!this.timeEntryStatus || (this.timeEntryStatus && this.timeEntryStatus.length === 0)) {
          return;
        }
        this.enableTranslation();

        if (this.IsActive) {
          this.settingService.showModuleMessage('Employee.Time');
        }

        this.updateDimensionValue();
      }
    );
  }

  public updateDimensionValue() {
    if (this.Dimension1Preference && this.Dimension1Preference.Value) {
      this.updateDimensionXValues(1);
    }

    if (this.Dimension2Preference && this.Dimension2Preference.Value) {
      this.updateDimensionXValues(2);
    }

    if (this.Dimension3Preference && this.Dimension3Preference.Value) {
      this.updateDimensionXValues(3);
    }
  }

  private async updateDimensionXValues(index: number) {
    if (this.isEnableDimensionPreference) {
      let employment: any;
      await this.employeeService.employment.subscribe((data: any) => {
        employment = data;
      });
      this.dataService.DimensionValues_GetDimensionValues(index).subscribe((data: any) => {
        this.dimensionXValues[index - 1] = data.filter((value: any) => {
          if (!value.LimitToDepartmentId && !value.LimitToTemplateId) {
            return true;
          } else if (!value.LimitToDepartmentId) {
            if (employment.BasedOnTemplateId === value.LimitToTemplateId) {
              return true;
            }

            return false;
          } else if (!value.LimitToTemplateId) {
            if (employment.DepartmentId === value.LimitToDepartmentId) {
              return true;
            }

            return false;
          } else if (value.LimitToDepartmentId && value.LimitToTemplateId) {
            if (
              employment.DepartmentId === value.LimitToDepartmentId &&
              employment.BasedOnTemplateId === value.LimitToTemplateId
            ) {
              return true;
            }

            return false;
          }

          return false;
        });
      });
    } else {
      this.dataService.Miscellaneous_GetDimensionValues(index).subscribe((data: any) => {
        this.dimensionXValues[index - 1] = data.filter((value: any) => value);
      });
    }
  }

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public previousAddedTimeEntryUid: any[] = [];

  public enableTranslation(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateService.get(this.translationServiceTerms).subscribe((translations: { [key: string]: string }) => {
        this.setTranslationTerms(translations);
      });
    });
  }

  public setTranslationTerms(translations: { [key: string]: string }): void {
    this.PeriodWarningText = translations['EmploymentTime.PeriodWarning'];
    this.AllText = translations['CompanySalaryBatches.SalaryCycle_Item_All_Text'];
    this.filterEligibleforapproval = translations['EmploymentTime.EligibleForApproval'];
  }

  public onGetTimeEntryTypesCompleted(timeEntryTypes: ITimeEntryType[]): void {
    if (timeEntryTypes && timeEntryTypes.length > 0) {
      this.flagselect += 1;
      this.TimeEntryTypes = timeEntryTypes;
      this.TimeEntryTypes.forEach((model: ITimeEntryType) => {
        model.Id = model.Id || model.BaseTimeEntryTypeId || null;
        model.InternalReferenceId = model.InternalReferenceId ? model.InternalReferenceId : null;
      });
      this.defaultTimeEntryType = timeEntryTypes.find((mode: ITimeEntryType) => mode.Identifier === 'Work');
      if (!this.defaultTimeEntryType) {
        this.defaultTimeEntryType = timeEntryTypes[0];
      }
    }
    this.flagselect += 1;

    if (this.TimeEntryTypes && this.TimeEntryTypes.length > 0) {
      this.populateData(this.privateUserEmployment);
    }
  }

  public filterTimeEntriesByStatus(dataSource?: any): void {
    if (this.TimeEntryRecords) {
      if (this.SelectedTimeStatusId >= 0) {
        this.TimeEntryRecords = this.DefaultTimeEntryRecord.filter(
          (model: ITimeEntryRecord) => model.Status.Id === this.SelectedTimeStatusId
        );
      } else {
        this.TimeEntryRecords = this.DefaultTimeEntryRecord;
      }
      if (this.filter && this.filter.filters && this.filter.filters.length > 0) {
        this.onFilterChange(this.filter);
      }
    }
  }

  public get SelectedTimeStatus(): ITimeEntryStatus {
    return this.TimeEntryStatuses.filter((value: ITimeEntryStatus) => value.Id === this.SelectedTimeStatusId)[0];
  }

  private resetStatusFilter(): void {
    if (this.TimeEntryStatuses[0]) {
      this.SelectedTimeStatusId = this.TimeEntryStatuses[0].Id;
    }

    this.filterTimeEntriesByStatus();
  }

  private originalTimeEntryRecords: ITimeEntryRecord[];
  private populateTimeEntryGridDataSource(userEmploymentId: number, salaryPeriodId: number): void {
    this.dataService
      .TimeEntry_GetTimeEntryRecordsByEmployment(userEmploymentId, salaryPeriodId)
      .subscribe((records: ITimeEntryRecord[]): void => {
        if (records && records.length > 0) {
          records.forEach((model: any) => {
            this.flagselect += 1;
            this.updateEditableFieldsAndClasses(model);
            if (model.EndTime && model.EndTime === '23:59:59.9999999') {
              model.EndTime = '24:00:00';
            }
            model.TimeEntryType =
              this.TimeEntryTypes && this.TimeEntryTypes.length > 0
                ? this.TimeEntryTypes.find((item: any) => item.TimeEntryTypeId === model.TimeEntryTypeId)
                : {};
            model.isAllowEdit =
              model.Status && model.Status.Key && (model.Status.Key === 'Open' || model.Status.Key === 'Rejected')
                ? true
                : false;
            if (!model.TimeEntryType || model.TimeEntryType === undefined) {
              model.TimeEntryType = {};
            }
            model.TimeEntryType.Id = model.TimeEntryType ? model.TimeEntryType.TimeEntryTypeId : -1;
            model.TimeEntryTypeName = model.TimeEntryType && model.TimeEntryType.Name ? model.TimeEntryType.Name : '';
            model.idColumn = this.flagselect;
            model.RejectionReason = model.Status && model.Status.Key !== 'Rejected' ? '' : model.RejectionReason;
          });

          this.newPreviewData = [];
          this.TimeEntryRecords = [];
          this.DefaultTimeEntryRecord = [];

          this.TimeEntryRecords = JSON.parse(JSON.stringify(records));
          this.DefaultTimeEntryRecord = JSON.parse(JSON.stringify(records));
          this.populateGridPreview();

          if (!this.isMobile) {
            this.TimeEntryStatuses = [];
            this.DefaultTimeEntryRecord.forEach((item: ITimeEntryRecord) => {
              if (item && item.Status) {
                const isStatusExist: boolean =
                  this.TimeEntryStatuses.filter(
                    (timeEntryStatus: ITimeEntryStatus): boolean => timeEntryStatus.Name === item.Status.Name
                  ).length > 0;
                if (!isStatusExist) {
                  this.TimeEntryStatuses.push(item.Status);
                }
              }
            });

            this.TimeEntryStatuses = this.TimeEntryStatuses.sort((s: ITimeEntryStatus): any => {
              return s.Name;
            });
            const allStatus: ITimeEntryStatus = { Id: -1, Name: this.AllText } as ITimeEntryStatus;
            this.TimeEntryStatuses.unshift(allStatus);
            if (this.SelectedTimeStatus) {
              const selectedStatus: ITimeEntryStatus = this.TimeEntryStatuses.filter(
                (timeEntryStatus: ITimeEntryStatus): boolean => timeEntryStatus.Name === this.SelectedTimeStatus.Name
              )[0];
              this.SelectedTimeStatusId = selectedStatus ? selectedStatus.Id : allStatus.Id;
              if (!selectedStatus) {
                this.filterTimeEntriesByStatus();
              }
            } else {
              this.SelectedTimeStatusId = allStatus.Id;
            }
            this.initTimeEntryStatusDataSource();
          }
        } else {
          this.newPreviewData = [];
          this.TimeEntryRecords = [];
          this.gridData = [];
          this.DefaultTimeEntryRecord = [];
          if (!this.isMobile) {
            this.TimeEntryStatuses = [];
            const allStatus: ITimeEntryStatus = { Id: -1, Name: this.AllText } as ITimeEntryStatus;
            this.TimeEntryStatuses.unshift(allStatus);
            this.SelectedTimeStatusId = allStatus.Id;
          }
        }

        this.originalTimeEntryRecords = JSON.parse(JSON.stringify(this.TimeEntryRecords));
      });
  }

  private populateGridPreview(): void {
    let previewResultWithKm: any[] = [];
    const kmType: any = Global.UNIT_TYPES
      ? Global.UNIT_TYPES.find((type: any) => type.Key === 'Kilometers')
      : undefined;
    if (this.TimeEntryRecords && this.TimeEntryRecords.length > 0) {
      const previewcheckTimeentryRecord: ITimeEntryRecord[] = this.TimeEntryRecords;
      let array: ITimeEntryPreview[];
      let hasUndefinedItem = false;
      let newArray: ITimeEntryPreview[];

      array = previewcheckTimeentryRecord.map(
        (item: ITimeEntryRecord, index: number): ITimeEntryPreview => {
          const currentTimeEntryType = this.TimeEntryTypes.find(
            (tet: any) => tet.TimeEntryTypeId === item.TimeEntryTypeId
          );

          if (!currentTimeEntryType) {
            hasUndefinedItem = true;
            return undefined;
          }

          const unitType: any = this.UnitTypes.find((type: any) => type.Id === item.UnitTypeId);
          const previewItem: ITimeEntryPreview = {
            Type: currentTimeEntryType.Name,
            Units: item.Units,
            UnitName: unitType ? unitType.Name : undefined,
            KilometersDriven: item.KilometersDriven ? item.KilometersDriven : 0,
            KilometersDrivenUnitName: kmType ? kmType.Name : ''
          };

          return previewItem;
        }
      );
      if (hasUndefinedItem) {
        newArray = array.filter((value: ITimeEntryPreview): boolean => {
          return value !== undefined;
        });
      } else {
        newArray = array;
      }

      const previewResult: any[] = [];
      newArray.reduce((res: any, value: ITimeEntryPreview) => {
        if (!res[value.Type + value.UnitName]) {
          res[value.Type + value.UnitName] = {
            Type: value.Type,
            UnitName: value.UnitName,
            Units: value.Units,
            KilometersDriven: value.KilometersDriven,
            KilometersDrivenUnitName: value.KilometersDrivenUnitName
          };
          previewResult.push(res[value.Type + value.UnitName]);
        } else {
          res[value.Type + value.UnitName].Units += value.Units;
          res[value.Type + value.UnitName].KilometersDriven += value.KilometersDriven;
        }

        return res;
      }, {});
      previewResult.reduce((res: any, value: ITimeEntryPreview): void => {
        if (value.Units) {
          previewResultWithKm.push({
            Type: value.Type,
            UnitName: value.UnitName,
            Units: value.Units
          });
        }

        if (value.KilometersDriven) {
          const existedByType: any = previewResultWithKm
            ? previewResultWithKm.find((km: any) => km.KmType === true && km.Type === value.Type)
            : undefined;
          if (existedByType) {
            previewResultWithKm[previewResultWithKm.indexOf(existedByType)].Units += value.KilometersDriven;
          } else {
            previewResultWithKm.push({
              Type: value.Type,
              Units: value.KilometersDriven,
              UnitName: value.KilometersDrivenUnitName,
              KmType: true
            });
          }
        }

        return res;
      }, {});

      previewResultWithKm = previewResultWithKm
        .sort((first: any, next: any): any => {
          GridHelper.sortByString(first, next, 'KmType');
        })
        .reverse()
        .sort((aPreview: any, bPreview: any) => GridHelper.sortByStringValue(aPreview.Type, bPreview.Type));
      this.newPreviewData = previewResultWithKm;
    } else {
      this.newPreviewData = [];
    }
  }

  private initTimeEntryStatusDataSource(): void {
    // this.TimeEntryStatuses = [];
    if (this.TimeEntryRecords) {
      this.TimeEntryRecords.forEach((item: ITimeEntryRecord) => {
        if (item && item.Status) {
          const isStatusExist: boolean =
            this.TimeEntryStatuses.filter(
              (timeEntryStatus: ITimeEntryStatus): boolean => timeEntryStatus.Name === item.Status.Name
            ).length > 0;
          if (!isStatusExist) {
            this.TimeEntryStatuses.push(item.Status);
          }
        }
      });
    }

    this.TimeEntryStatuses = this.TimeEntryStatuses.sort((s: ITimeEntryStatus): any => {
      return s.Name;
    });

    const allStatus: ITimeEntryStatus = { Id: -1, Name: this.AllText } as ITimeEntryStatus;

    if (this.SelectedTimeStatus) {
      const selectedStatus: ITimeEntryStatus = this.TimeEntryStatuses.find(
        (timeEntryStatus: ITimeEntryStatus): boolean => timeEntryStatus.Name === this.SelectedTimeStatus.Name
      );
      this.SelectedTimeStatusId = selectedStatus ? selectedStatus.Id : allStatus.Id;
    } else {
      this.SelectedTimeStatusId = allStatus.Id;
    }
    this.filterTimeEntriesByStatus();
  }

  public getNotMatchPeriodMessageString: string;
  public isgetNotMatchPeriodMessageString = false;

  public getNotMatchPeriodMessage(record: any): string {
    if (record && record.EntryDate) {
      let outOfPeriodDate: Date;
      const entryDate: Date = record.EntryDate;
      const startDate: Date = new Date(this.SelectedSalaryPeriod.StartDate.toString());
      const endDate: Date = new Date(this.SelectedSalaryPeriod.EndDate.toString());
      endDate.setHours(23, 59, 59);
      if (entryDate < startDate || entryDate > endDate) {
        outOfPeriodDate = record.EntryDate;
      } else {
        outOfPeriodDate = record.EndDate;
      }

      return this.PeriodWarningText.replace('$$$', this.sessionService.toString(outOfPeriodDate));
    }

    return '';
  }

  private populateData(userEmployment: IUserEmployment): void {
    if (userEmployment) {
      this.dataService
        .SalaryBatches_GetSalaryPeriods(userEmployment.SalaryCycleId)
        .subscribe((salaryPeriods: ISalaryPeriod[]) => {
          if (salaryPeriods.length <= 0) {
            this.SalaryPeriods = [];
            this.TimeEntryRecords = [];
            this.gridData = [];
            this.DefaultTimeEntryRecord = [];
            this.newPreviewData = [];
            return;
          }

          this.SalaryPeriods = [];
          salaryPeriods = salaryPeriods.sort((data1: any, data2: any) =>
            GridHelper.sortByDateValue(data1.StartDate, data2.StartDate)
          );
          if (salaryPeriods) {
            salaryPeriods.forEach((salaryPeriod: any, key: number): void => {
              const startDate: string = this.sessionService.toString(
                salaryPeriod.StartDate ? new Date(salaryPeriod.StartDate) : undefined
              );
              const endDate: string = this.sessionService.toString(
                salaryPeriod.EndDate ? new Date(salaryPeriod.EndDate) : undefined
              );
              const isUserFriendlyname: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
                'SalaryPeriods.FriendlyNames'
              );
              if (isUserFriendlyname && isUserFriendlyname.Value === 'true') {
                const extendSalaryPeriod = new ExtendSalaryPeriod();
                extendSalaryPeriod.Id = salaryPeriod.Id;
                extendSalaryPeriod.StartDate = salaryPeriod.StartDate;
                extendSalaryPeriod.EndDate = salaryPeriod.EndDate;
                extendSalaryPeriod.Period = startDate + ' - ' + endDate;
                extendSalaryPeriod.FriendlyName = ' ' + salaryPeriod.FriendlyName;
                this.SalaryPeriods[key] = extendSalaryPeriod;
              } else {
                const extendSalaryPeriod = new ExtendSalaryPeriod();
                extendSalaryPeriod.Id = salaryPeriod.Id;
                extendSalaryPeriod.StartDate = salaryPeriod.StartDate;
                extendSalaryPeriod.EndDate = salaryPeriod.EndDate;
                extendSalaryPeriod.Period = startDate + ' - ' + endDate;
                extendSalaryPeriod.FriendlyName = startDate + ' - ' + endDate;
                this.SalaryPeriods[key] = extendSalaryPeriod;
              }
            });
          }

          const roleId: number = this.currentCompanyUser ? this.currentCompanyUser.RoleId : undefined;
          this.setDefaultSalaryPeriod(roleId, userEmployment);
        });
    }
  }

  public ApproveEntries(): void {
    this.isShowEmployeeTimeApproveDialog = true;
  }

  private setDefaultSalaryPeriod(roleId: number, userEmployment: IUserEmployment): void {
    if (
      this.sessionService.currentSalaryCycleId &&
      this.sessionService.currentSalaryPeriodId &&
      this.sessionService.currentSalaryCycleId === this.privateUserEmployment.SalaryCycleId
    ) {
      this.selectedSalaryPeriodId = this.sessionService.currentSalaryPeriodId;
      this.onSalaryPeriodChanged('');
    } else {
      const employeeRoleId = 20;
      if (roleId > employeeRoleId) {
        this.dataService
          .SalaryBatches_SuggestSalaryPeriod(userEmployment.SalaryCycleId)
          .pipe(
            finalize(() => {
              this.onSalaryPeriodChanged('');
            })
          )
          .subscribe((salaryPeriod: ISalaryPeriod) => {
            if (!salaryPeriod) {
              return;
            }
            this.selectedSalaryPeriodId = salaryPeriod.Id;
            if (
              this.SalaryPeriods &&
              this.SalaryPeriods.length > 0 &&
              !this.SalaryPeriods.find((item: ExtendSalaryPeriod) => item.Id === salaryPeriod.Id)
            ) {
              this.selectedSalaryPeriodId = this.SalaryPeriods[0].Id;
            }
            if (this.newRecordDefault) {
              this.newRecordDefault.EntryDate = new Date(salaryPeriod.StartDate.toString());
            }
          });
      } else {
        const today: Date = this.staticDataService.getCurrentdate();
        today.setHours(0, 0, 0, 0);
        const selectedSalaryPeriod: ExtendSalaryPeriod = this.SalaryPeriods.find((salaryPeriod: ExtendSalaryPeriod) => {
          const startDate: Date = new Date(salaryPeriod.StartDate.toString());
          const endDate: Date = new Date(salaryPeriod.EndDate.toString());
          startDate.setHours(0, 0, 0, 0);
          endDate.setHours(0, 0, 0, 0);
          return startDate <= today && endDate >= today;
        });

        this.selectedSalaryPeriodId = selectedSalaryPeriod.Id;
        this.onSalaryPeriodChanged('');
      }
    }
  }

  private tempselectedSalaryPeriodId: number;

  public onSalaryPeriodChanged(value: string): void {
    if (this.selectedSalaryPeriodId && this.selectedSalaryPeriodId !== this.tempselectedSalaryPeriodId) {
      this.sessionService.currentSalaryCycleId = this.privateUserEmployment.SalaryCycleId;
      this.sessionService.currentSalaryPeriodId = this.selectedSalaryPeriodId;
      this.tempselectedSalaryPeriodId = this.selectedSalaryPeriodId;
      this.populateTimeEntryGridDataSource(this.userEmployment.Id, this.selectedSalaryPeriodId);
    }
  }

  public createUserEmployment(): void {
    if (!this.hasTimeModule) {
      return;
    }
    this.newEmploymentDialogVisible = true;
  }

  public currentisaddnew = false;

  public get isAddNew(): boolean {
    return this.currentisaddnew;
  }

  public set isAddNew(value: boolean) {
    this.currentisaddnew = value;
  }

  private updateUnitsByDateAndTime(model: ITimeEntryRecord): void {
    const uniType: any =
      model.UnitTypeId && this.UnitTypes ? this.UnitTypes.find((type: any) => type.Id === model.UnitTypeId) : undefined;
    const isHoursUnitType: boolean = uniType && uniType.Key === 'Hours';
    const isDaysUnitType: boolean = uniType && uniType.Key === 'Days';

    let start: Date;
    if (model.StartTime) {
      start = this.staticDataService.getCurrentdate();
      start.setHours(
        this.sessionService.parseInt(model.StartTime.split(':')[0]),
        this.sessionService.parseInt(model.StartTime.split(':')[1]),
        0,
        0
      );
    }

    let end: Date;
    if (model.EndTime) {
      end = this.staticDataService.getCurrentdate();
      end.setHours(
        this.sessionService.parseInt(model.EndTime.split(':')[0]),
        this.sessionService.parseInt(model.EndTime.split(':')[1]),
        0,
        0
      );
    }

    model.StartTime = start ? this.sessionService.toString(start, 'HH:mm:ss') : '';
    model.EndTime = end
      ? model.EndTime.indexOf('24:00') === 0
        ? '24:00:00'
        : this.sessionService.toString(end, 'HH:mm:ss')
      : '';

    if (isHoursUnitType) {
      if (start && end) {
        start.setHours(start.getHours(), start.getMinutes(), 0, 0);
        let startTime: any = start.getTime();
        let endTime: any;

        if (model.EntryDate && model.EndDate) {
          const entryDate: any = new Date(model.EntryDate);
          const endDate: any = new Date(model.EndDate);
          startTime = entryDate.setHours(start.getHours(), start.getMinutes(), 0, 0);
          endTime = endDate.setHours(end.getHours(), end.getMinutes(), 0, 0);
        } else {
          end.setHours(end.getHours(), end.getMinutes(), 0, 0);
          endTime = end;
          endTime = start > end ? endTime.setDate(end.getDate() + 1) : endTime.getTime();
        }

        const timeDiff: any = endTime - startTime;
        model.Units = Math.abs(Math.floor(timeDiff / 3600) / 1000);
      }
      ////else {
      ////    this.calculateUnitByStartAndEndDate(model, isHoursUnitType);
      ////}
    }
  }
  /*update mobile centric*/

  private selectedItem: any;
  public onSelectRow(event: any): void {
    if (!event || event === undefined) {
      return;
    }
    this.updateDimensionValue();
    this.selectedItem = event;
    this.selectedMinDate = this.staticDataService.getCurrentdate(false, event.EntryDate);
    this.selectedEntryDate = event.EntryDate;
    if (event && event.Id && event.EmployeeName && event.UserEmploymentId) {
      this.selectedemployeeIdinGrid = event.UserEmploymentId;
      this.nameEmployeeSelect = event.EmployeeName;
    }
  }

  public isShowRejectDialog = false;
  public rejectMessage: string;
  public isShowRejectDialogNonApproveTimeEntry = false;
  public preUserEmploymentId: number;
  public onGridButtonClick(event: any): void {
    if (event && event.dataItem && event.buttonAction && event.buttonAction === 'Reject') {
      this.selectedemployeeIdinGrid = event.dataItem.Id;
      this.preUserEmploymentId = event.dataItem.UserEmploymentId;
      this.isShowRejectDialogNonApproveTimeEntry = true;
    }
  }

  public onCloseNotMatchPeriodMessage(event: any): void {
    if (event) {
      if (this.indexRemovePeriod > -1) {
        setTimeout(() => {
          this.TimeEntryRecords.splice(this.indexRemovePeriod, 1);
          this.indexRemovePeriod = -1;
        });
      }
    }
  }

  public onRejectDialog(event: any): void {
    if (event) {
      if (event === 'RejectPrimary') {
        const rejectionInfo: IRejectionInfo = { Reason: this.rejectMessage };
        this.dataService.TimeEntry_RejectTimeEntryRecord(this.selectedemployeeIdinGrid, rejectionInfo).subscribe(
          (): void => {
            this.rejectMessage = undefined;
            this.populateTimeEntryGridDataSource(this.userEmployment.Id, this.SelectedSalaryPeriod.Id);
          },
          () => {
            this.rejectMessage = undefined;
          }
        );
      } else {
        this.rejectMessage = undefined;
        this.selectedemployeeIdinGrid = this.preUserEmploymentId;
      }
    }
  }

  private updateUnsavedRecords(dataItem?: any): void {
    const unSavedTimeEntry: any[] = this.TimeEntryRecords
      ? this.TimeEntryRecords.filter((entry: any) => entry.UnSaved)
      : undefined;
    if (unSavedTimeEntry && unSavedTimeEntry.length > 0) {
      unSavedTimeEntry.forEach((entry: any) => {
        if (!dataItem || dataItem.idColumn !== entry.idColumn) {
          this.onSaveChangeGrid({ dataItem: entry } as any, true);
        }
      });
    }
  }

  public oncellCloseEvent(event: CellCloseEvent): void {
    if (event && event.dataItem && event.dataItem.EntryDate) {
      this.selectedMinDate = this.staticDataService.getCurrentdate(false, event.dataItem.EntryDate);
    }
    if (this.preventCellChangeEvent) {
      this.preventCellChangeEvent = false;
      return;
    }
    if (event && event.dataItem && event.dataItem.UnSaved && !event.sender.isEditing()) {
      this.onSaveChangeGrid({ dataItem: event.dataItem } as any, true);
    }
  }

  private sort(): void {
    this.TimeEntryRecords = this.TimeEntryRecords.sort((data1: any, data2: any) =>
      GridHelper.sortDescendingByDateValue(new Date(data1.EntryDate).getTime(), new Date(data2.EntryDate).getTime())
    );
    this.gridData = filterBy(this.TimeEntryRecords, this.filter);
    this.triggerUpdate = true;
  }

  public triggerUpdate = false;
  public onSaveChangeGrid(event: CellCloseEvent, force?: boolean): void {
    if (this.isNotChange) {
      this.isNotChange = false;
      return;
    }
    if (event && event.dataItem) {
      const startdate: number = event.dataItem.EntryDate
        ? new Date(new Date(event.dataItem.EntryDate).setHours(0)).getTime()
        : undefined;
      const enddate: number = event.dataItem.EndDate
        ? new Date(new Date(event.dataItem.EndDate).setHours(0)).getTime()
        : undefined;

      if (!event.dataItem.TimeEntryTypeId || !event.dataItem.EntryDate) {
        return;
      } else if (
        !force &&
        event.dataItem.UnitTypeId === 1 &&
        !event.dataItem.EndDate &&
        (event.dataItem.Units === null || event.dataItem.Units === undefined)
      ) {
        // Days Unit type must require either Units or EndDate.
        event.dataItem.UnSaved = true;
        return;
      } else if (
        !force &&
        event.dataItem.UnitTypeId !== 1 &&
        (event.dataItem.Units === null || event.dataItem.Units === undefined)
      ) {
        // Not Days Unit type must require Units.
        event.dataItem.UnSaved = true;
        return;
      }

      event.dataItem.UnSaved = false;
      if (!event.dataItem.Id) {
        if (event.dataItem && event.dataItem.EntryDate) {
          const entryDate: Date = event.dataItem.EntryDate;
          const modelEndDate: Date = event.dataItem.EndDate;
          const startDate: Date = new Date(this.SelectedSalaryPeriod.StartDate.toString());
          const endDate: Date = new Date(this.SelectedSalaryPeriod.EndDate.toString());
          endDate.setHours(23, 59, 59);
          if (entryDate < startDate || entryDate >= endDate) {
            this.getNotMatchPeriodMessageString = this.PeriodWarningText.replace(
              '$$$',
              this.sessionService.toString(entryDate)
            );
            this.isgetNotMatchPeriodMessageString = true;
            this.indexRemovePeriod = this.TimeEntryRecords.indexOf(event.dataItem, 0);
          }
        }

        if (!event.dataItem.SalaryPeriodId) {
          event.dataItem.SalaryPeriodId = this.selectedSalaryPeriodId;
        }

        if (!event.dataItem.UserEmploymentId) {
          event.dataItem.UserEmploymentId = this.userEmployment.Id;
        }

        event.dataItem.ApprovedUserId = event.dataItem.ApprovedUserId || null;
        event.dataItem.RejectedUserId = event.dataItem.RejectedUserId || null;

        if (this.preData !== event.dataItem) {
          this.preData = event.dataItem;
          const uniType: any = this.UnitTypes
            ? this.UnitTypes.find((type: any) => type.Id === event.dataItem.UnitTypeId)
            : undefined;
          if (uniType && uniType.Key === 'Days' && !event.dataItem.EndDate && !event.dataItem.Units) {
            event.dataItem.Units = 1;
          }
          let newRecord: ITimeEntryRecord = Object.assign({}, event.dataItem);
          newRecord.StartTime =
            newRecord.StartTime && newRecord.StartTime.indexOf('24:00') === 0 ? '1.00:00:00' : newRecord.StartTime;
          newRecord.EndTime =
            newRecord.EndTime && newRecord.EndTime.indexOf('24:00') === 0 ? '1.00:00:00' : newRecord.EndTime;
          newRecord = this.createTimeEntryRecord(newRecord);
          this.dataService.TimeEntry_CreateTimeEntryRecord(newRecord).subscribe(
            (updatedRecord: any) => {
              event.dataItem.Id = updatedRecord.Id;
              event.dataItem.UnSaved = false;
              this.flagselect += 1;
              if (updatedRecord.SalaryPeriodId !== this.selectedSalaryPeriodId) {
                this.getNotMatchPeriodMessageString = this.getNotMatchPeriodMessage(newRecord);
                this.isgetNotMatchPeriodMessageString = true;
                this.indexRemovePeriod = this.TimeEntryRecords.indexOf(event.dataItem, 0);
                // Prevent change event 1 time when got dialog error
                this.preventCellChangeEvent = true;
              } else {
                if (!this.DefaultTimeEntryRecord.find((item: any) => item.Id === event.dataItem.Id)) {
                  this.DefaultTimeEntryRecord.push(event.dataItem);
                }
                if ((uniType.Key === 'Days' && updatedRecord.Units) || uniType.Key !== 'Days') {
                  event.dataItem.Units = updatedRecord.Units;
                }
                this.assignToOriginalObject(event.dataItem);
                this.populateGridPreview();
                this.triggerUpdate = true;
              }
            },
            (error: any) => {
              console.error('Create request failed:' + error);
              this.populateTimeEntryGridDataSource(this.userEmployment.Id, this.SelectedSalaryPeriod.Id);
              this.selectedMinDate = this.staticDataService.getCurrentdate(false, event.dataItem.EntryDate);
            }
          );
        }
      } else {
        const uniType: any = this.UnitTypes
          ? this.UnitTypes.find((type: any) => type.Id === event.dataItem.UnitTypeId)
          : undefined;
        if (uniType && uniType.Key === 'Days' && !event.dataItem.EndDate && !event.dataItem.Units) {
          event.dataItem.Units = 1;
        }
        const updateRecord: ITimeEntryRecord = Object.assign({}, event.dataItem);
        updateRecord.StartTime =
          updateRecord.StartTime && updateRecord.StartTime.indexOf('24:00') === 0
            ? '1.00:00:00'
            : updateRecord.StartTime;
        updateRecord.EndTime =
          updateRecord.EndTime && updateRecord.EndTime.indexOf('24:00') === 0 ? '1.00:00:00' : updateRecord.EndTime;

        const modelrequest: TimeEntryRecord = this.createTimeEntryRecord(updateRecord);
        this.dataService.TimeEntry_UpdateTimeEntryRecord(modelrequest).subscribe(
          (updatedRecord: any) => {
            this.flagselect += 1;
            const record: ITimeEntryRecord = updatedRecord.length > 0 ? updatedRecord[0] : updatedRecord; //// updatedRecords[0];
            if (record && record.SalaryPeriodId !== event.dataItem.SalaryPeriodId) {
              this.getNotMatchPeriodMessageString = this.getNotMatchPeriodMessage(event.dataItem);
              this.isgetNotMatchPeriodMessageString = true;
              this.populateTimeEntryGridDataSource(this.userEmployment.Id, this.SelectedSalaryPeriod.Id);
            }

            Object.assign(event.dataItem, record);
            event.dataItem.RejectionReason = record.RejectionReason =
              event.dataItem.Status && event.dataItem.Status.Key !== 'Rejected' ? '' : event.dataItem.RejectionReason;
            this.filterTimeEntriesByStatus();
            this.assignToOriginalObject(record);
            this.populateGridPreview();
            this.updateEditableFieldsAndClasses(event.dataItem);
            this.triggerUpdate = true;
          },
          (error: any) => {
            console.error('Update request failed:' + error);
            const original: any = this.getOriginalObject(event.dataItem.Id);
            if (original) {
              Object.assign(event.dataItem, original);
              this.selectedMinDate = this.staticDataService.getCurrentdate(false, event.dataItem.EntryDate);
              this.updateEditableFieldsAndClasses(event.dataItem);
              this.triggerUpdate = true;
            }
            // this.populateTimeEntryGridDataSource(this.userEmployment.Id, this.SelectedSalaryPeriod.Id);
          }
        );
      }
    }
  }

  private RightValue(value: any): any {
    if (value) {
      return value;
    }
    return null;
  }

  private createTimeEntryRecord(model: TimeEntryRecord): TimeEntryRecord {
    return {
      UnitType: this.RightValue(model.UnitType),
      Status: this.RightValue(model.Status),
      EmployeeName: this.RightValue(model.EmployeeName),
      Title: this.RightValue(model.Title),
      DepartmentName: this.RightValue(model.DepartmentName),
      IsCalculated: model.IsCalculated || false,
      CalculatedPayout: isNumber(model.CalculatedPayout) ? model.CalculatedPayout : null,
      AmountPerUnit: this.RightValue(model.AmountPerUnit),
      Id: this.RightValue(model.Id),
      UserEmploymentId: this.RightValue(model.UserEmploymentId),
      EntryDate: this.RightValue(model.EntryDate),
      TimeEntryTypeId: this.RightValue(model.TimeEntryTypeId),
      Units: this.RightValue(model.Units),
      UnitTypeId: this.RightValue(model.UnitTypeId),
      Description: this.RightValue(model.Description),
      SalaryPeriodId: this.RightValue(model.SalaryPeriodId),
      StatusId: this.RightValue(model.StatusId),
      Created: this.RightValue(model.Created),
      RejectionReason: this.RightValue(model.RejectionReason),
      SalaryRecordId: this.RightValue(model.SalaryRecordId),
      SalaryStatementId: this.RightValue(model.SalaryStatementId),
      KilometersDriven: this.RightValue(model.KilometersDriven),
      StartTime: this.RightValue(model.StartTime),
      EndTime: this.RightValue(model.EndTime),
      Dimension1Value: this.RightValue(model.Dimension1Value),
      Dimension2Value: this.RightValue(model.Dimension2Value),
      Dimension3Value: this.RightValue(model.Dimension3Value),
      Dimension1ValueId: this.RightValue(model.Dimension1ValueId),
      Dimension2ValueId: this.RightValue(model.Dimension2ValueId),
      Dimension3ValueId: this.RightValue(model.Dimension3ValueId),
      EndDate: this.RightValue(model.EndDate),
      CalculatedFromRecordId: this.RightValue(model.CalculatedFromRecordId),
      ExternalIdentifier: this.RightValue(model.ExternalIdentifier),
      CalculatedPayoutKilometers: 0,
      ApprovedUserId: this.RightValue(model.ApprovedUserId),
      RejectedUserId: this.RightValue(model.RejectedUserId)
    };
  }

  private assignToOriginalObject(timeEntry: ITimeEntryRecord): void {
    if (!timeEntry) {
      return;
    }

    const original: any = this.originalTimeEntryRecords
      ? this.originalTimeEntryRecords.find((item: ITimeEntryRecord) => item.Id === timeEntry.Id)
      : undefined;
    if (original) {
      Object.assign(original, JSON.parse(JSON.stringify(timeEntry)));
    } else {
      this.originalTimeEntryRecords.push(JSON.parse(JSON.stringify(timeEntry)));
    }
  }

  private getOriginalObject(id: number): any {
    return this.originalTimeEntryRecords
      ? this.originalTimeEntryRecords.find((item: ITimeEntryRecord) => item.Id === id)
      : undefined;
  }

  public preData: ITimeEntryRecord = new TimeEntryRecord();
  public isShowDateErrorInput = false;
  public onControlDataChange(event: any): void {
    if (event && event.dataItem && event.field) {
      switch (event.field) {
        case 'TimeEntryTypeId':
          if (event.dataItem.TimeEntryTypeId) {
            const timeEntryType: any = this.TimeEntryTypes
              ? this.TimeEntryTypes.find((e: any) => e.TimeEntryTypeId === event.dataItem.TimeEntryTypeId)
              : undefined;
            if (timeEntryType) {
              event.dataItem.TimeEntryType = timeEntryType;
              event.dataItem.TimeEntryType.Id = timeEntryType.TimeEntryTypeId;
            }
            if (this.TimeEntryTypes && this.TimeEntryTypes.length > 0 && event.dataItem.TimeEntryTypeId) {
              const newTimeEntryType: ITimeEntryType = this.TimeEntryTypes.find(
                (tet: any) => tet.TimeEntryTypeId === event.dataItem.TimeEntryTypeId
              );
              event.dataItem.Description = newTimeEntryType.Description;
              event.dataItem.UnitTypeId = newTimeEntryType.UnitTypeId
                ? newTimeEntryType.UnitTypeId
                : this.UnitTypes && this.UnitTypes.length > 0
                ? this.UnitTypes[0].Id
                : undefined;
              if (newTimeEntryType.UnitTypeId) {
                event.dataItem.UnitType = this.UnitTypes.find((type: any) => type.Id === newTimeEntryType.UnitTypeId);
              }
            }
          }
          ////else {
          ////    this.populateTimeEntryGridDataSource(this.userEmployment.Id, this.SelectedSalaryPeriod.Id);
          ////    return;
          ////}

          break;
        case 'EntryDate':
          if (event && event.dataItem && !event.dataItem.EntryDate) {
            event.dataItem.EntryDate = this.selectedEntryDate;
            this.isNotChange = true;
          }
          break;
        case 'UnitTypeId':
          const uniType: any = this.UnitTypes
            ? this.UnitTypes.find((type: any) => type.Id === event.dataItem.UnitTypeId)
            : undefined;
          if (uniType && uniType.Key !== 'Hours') {
            event.dataItem.StartTime = undefined;
            event.dataItem.EndTime = undefined;
          }
          // if (uniType && uniType.Key === 'Days' && !event.dataItem.EndDate) {
          //   event.dataItem.Units = 1;
          // }

          break;
        case 'EndDate':
          const startDate: number = event.dataItem.EntryDate
            ? new Date(new Date(event.dataItem.EntryDate).setHours(0)).getTime()
            : undefined;
          const endDate: number = event.dataItem.EndDate
            ? new Date(new Date(event.dataItem.EndDate).setHours(0)).getTime()
            : undefined;
          if (startDate && endDate && startDate > endDate) {
            try {
              event.dataItem.EndDate = undefined;
            } catch (e) {
              console.log(e);
            }

            this.isShowDateErrorInput = true;
            return;
          }
          break;
        default:
          break;
      }

      if (event.dataItem.EndTime === '24:00') {
        event.dataItem.EndTime = '24:00:00';
      }

      this.updateEditableFieldsAndClasses(event.dataItem);

      const startdate: number = new Date(event.dataItem.EntryDate).getTime();
      const enddate: number = new Date(event.dataItem.EndDate).getTime();
      if (startdate && enddate && startdate > enddate) {
        return;
      }

      this.updateUnitsByDateAndTime(event.dataItem);
    }
  }

  public isUpdateWarrningPeriods = false;
  public MessageUpdateWarrningPeriods: string;
  public newRecordDefault: any;
  public flagselect = 0;

  private defaultRecord(): any {
    const today = this.staticDataService.getCurrentdate();
    this.flagselect += 1;
    const defaultTypeEntryType: any = this.defaultTimeEntryType ? this.defaultTimeEntryType : null;
    let defaultUnitType: any = defaultTypeEntryType
      ? this.UnitTypes
        ? this.UnitTypes.find((type: any) => type.Id === defaultTypeEntryType.UnitTypeId)
        : null
      : null;
    defaultUnitType = defaultUnitType ? defaultUnitType : this.UnitTypes ? this.UnitTypes[0] : null;
    this.newRecordDefault = {
      Id: null,
      isAllowEdit: true,
      TimeEntryTypeId: defaultTypeEntryType ? defaultTypeEntryType.TimeEntryTypeId : null,
      EntryDate: today,
      EndDate: null,
      Units: null,
      StartTime: null,
      EndTime: null,
      Description: null,
      DepartmentName: null,
      UnitTypeId: defaultUnitType ? defaultUnitType.Id : null,
      UnitType: defaultUnitType ? defaultUnitType : null,
      Created: today,
      SalaryPeriodId: this.selectedSalaryPeriodId,
      UserEmploymentId: this.userEmployment ? this.userEmployment.Id : null,
      Status: this.timeEntryStatus ? this.timeEntryStatus.find((model: ITimeEntryStatus) => model.Id === 10) : null,
      idColumn: this.flagselect,
      UnSaved: true,
      KilometersDriven: null,
      TimeEntryTypeName: defaultTypeEntryType && defaultTypeEntryType.Name ? defaultTypeEntryType.Name : '',
      TimeEntryType: defaultTypeEntryType,
      CalculatedPayoutKilometers: 0
    };
    if (this.SalaryPeriods && this.SalaryPeriods.length > 0 && this.selectedSalaryPeriodId) {
      const salaryPeriod: ISalaryPeriod = this.SalaryPeriods.find(
        (model: ISalaryPeriod) => model.Id === this.selectedSalaryPeriodId
      );
      if (salaryPeriod) {
        if (
          new Date(this.newRecordDefault.EntryDate) < new Date(salaryPeriod.StartDate) ||
          new Date(this.newRecordDefault.EntryDate) > new Date(salaryPeriod.EndDate)
        ) {
          this.newRecordDefault.EntryDate = salaryPeriod.StartDate;
        }
      }
    }

    if (this.isHideDimension1) {
      this.newRecordDefault.Dimension1Value = undefined;
    }

    if (this.isHideDimension2) {
      this.newRecordDefault.Dimension2Value = undefined;
    }

    if (this.isHideDimension3) {
      this.newRecordDefault.Dimension3Value = undefined;
    }

    this.newRecordDefault.TimeEntryType =
      this.TimeEntryTypes && this.TimeEntryTypes.length > 0
        ? this.TimeEntryTypes.find((item: any) => item.TimeEntryTypeId === this.newRecordDefault.TimeEntryTypeId)
        : undefined;
    this.updateEditableFieldsAndClasses(this.newRecordDefault);
    return this.newRecordDefault;
  }

  public onUpdateDefaultRecord(): void {
    this.defaultRecordValue = this.defaultRecord();
  }

  public onAddNewRecord(event: any): void {
    this.updateUnsavedRecords(event);
  }

  public isShowDeleteRecord = false;
  public IdDelete: number;
  public itemremmove: any;

  public onDeleteRow(event: any): void {
    if (event && event.dataItem) {
      if (event.dataItem.Id) {
        this.IdDelete = event.dataItem.Id;
        this.itemremmove = event.dataItem;
        this.isShowDeleteRecord = true;
      } else {
        const indexRemove: number = this.TimeEntryRecords.indexOf(event.dataItem);
        if (indexRemove !== -1) {
          this.TimeEntryRecords.splice(indexRemove, 1);
          this.triggerUpdate = true;
        }
      }
    }
  }

  public onDeleteRecordAction(event: any): void {
    if (event && event === 'Delete') {
      this.dataService.TimeEntry_DeleteTimeEntryRecord(this.IdDelete).subscribe(
        () => {
          const indexRemove: number = this.TimeEntryRecords.indexOf(this.itemremmove);
          if (indexRemove !== -1) {
            this.TimeEntryRecords.splice(indexRemove, 1);
            this.triggerUpdate = true;
          }

          if (this.TimeEntryRecords.length > 0) {
            const index: number = indexRemove > this.TimeEntryRecords.length - 1 ? 0 : indexRemove;
            this.kendoGrid.editCell(index, 0, this.createFormGroup(this.itemremmove));
          }

          this.populateGridPreview();
        },
        (error: any) => {
          console.error('Delete request failed:' + error);
          this.populateTimeEntryGridDataSource(this.userEmployment.Id, this.SelectedSalaryPeriod.Id);
        }
      );
    }
  }

  public isShowEmployeeTimeApproveDialog = false;
  public onEmployeeTimeApproveDialog(event: any): void {
    if (event && event === 'Approve') {
      this.dataService
        .TimeEntry_ApprovePeriodForEmployee(this.userEmployment.Id, this.selectedSalaryPeriodId)
        .subscribe(() => {
          this.isSlect1020default = false;
          this.populateTimeEntryGridDataSource(this.userEmployment.Id, this.selectedSalaryPeriodId);
        });
    }
  }

  public EmptyEmployeetime(): string {
    if (this.TimeEntryRecords && this.TimeEntryRecords.length > 0) {
      return '';
    } else {
      return 'EmptydataGrid';
    }
  }

  public EmptyEmployeePreview(): string {
    if (this.newPreviewData && this.newPreviewData.length > 0) {
      return '';
    } else {
      return 'EmptydataGrid';
    }
  }

  public onSalaryperiodsMobileCentricChange(): void {
    this.tempselectedSalaryPeriodId = undefined;
    this.onSalaryPeriodChanged('');
  }

  private updateEditableFieldsAndClasses(dataItem: any): void {
    const timeEntryType: any =
      this.TimeEntryTypes && this.TimeEntryTypes.length > 0
        ? this.TimeEntryTypes.find((e: any) => e.TimeEntryTypeId === dataItem.TimeEntryTypeId)
        : undefined;
    dataItem.isEditUnitType = timeEntryType ? timeEntryType.AllowEditUnitType : false;
    dataItem.ClassIsEditUnitType = 'grid-disable-cell'; //dataItem.isEditUnitType ? '' : 'grid-disable-cell';
    const unitType: any =
      this.UnitTypes && this.UnitTypes.length > 0
        ? this.UnitTypes.find((type: any) => type.Id === dataItem.UnitTypeId)
        : undefined;
    dataItem.isEditStartAndEndTime = unitType ? unitType.Key === 'Hours' : false;
    dataItem.ClassIsEditStartAndEndTime = dataItem.isEditStartAndEndTime ? '' : 'grid-disable-cell';

    if (dataItem.isEditStartAndEndTime) {
      dataItem.isEditUnits = dataItem.StartTime && dataItem.EndTime ? false : true;
    } else {
      dataItem.isEditUnits = dataItem.EntryDate && dataItem.EndDate ? false : true;
    }
    dataItem.ClassIsEditUnits = dataItem.isEditUnits ? '' : 'grid-disable-cell';
    dataItem.TimeEntryTypeName = timeEntryType && timeEntryType.Name ? timeEntryType.Name : '';

    if (dataItem.Status) {
      if (
        (dataItem.Status.Key === 'Open' || dataItem.Status.Key === 'EmployeeApproved') &&
        (this.IsReadOnly || this.isManager || this.isSalaryAdminAndHigher)
      ) {
        dataItem.isDisableRejectbutton = false;
      } else if (dataItem.Status.Key === 'ManagerApproved' && this.isSalaryAdminAndHigher) {
        dataItem.isDisableRejectbutton = false;
      } else {
        dataItem.isDisableRejectbutton = true;
      }
    }

    dataItem.isvisibleDeletebytton =
      dataItem.Status &&
      (dataItem.Status.Key === 'Rejected' ||
        dataItem.Status.Key === 'Open' ||
        (dataItem.Status.Key === 'EmployeeApproved' && !this.isSalaryAdminAndHigher));

    dataItem.rejectbuttonClass = dataItem.isDisableRejectbutton ? 'Reject k-button Disable' : 'Reject k-button';
  }

  private keyPress: any = { 17: false, 18: false, 107: false, 109: false };
  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: any): void {
    if (!this.IsReadOnly) {
      if (event.keyCode in this.keyPress) {
        this.keyPress[event.keyCode] = true;
        if (this.keyPress[17] && this.keyPress[18] && this.keyPress[109]) {
          if (this.isAllowEditGridOption) {
            if (this.selectedItem && this.TimeEntryRecords && this.TimeEntryRecords.length > 0) {
              const deleteArgs: any = {};
              deleteArgs.dataItem = this.selectedItem;
              this.onDeleteRow(deleteArgs);
              this.setKeypress();
              this.triggerUpdate = true;
            }
          } else {
            this.isDisableAddNew = true;
            this.setKeypress();
          }
        }
      } else {
        this.setKeypress();
      }
    }
  }

  public setKeypress(): void {
    this.keyPress[17] = false;
    this.keyPress[18] = false;
    this.keyPress[107] = false;
    this.keyPress[109] = false;
  }

  private createFormGroup(dataItem: ITimeEntryRecord): FormGroup {
    return new FormGroup({
      Id: new FormControl(dataItem ? dataItem.Id : undefined),
      TimeEntryTypeId: new FormControl(dataItem ? dataItem.TimeEntryTypeId : undefined),
      EntryDate: new FormControl(dataItem ? dataItem.EntryDate : undefined),
      EndDate: new FormControl(dataItem ? dataItem.EndDate : undefined),
      Units: new FormControl(dataItem ? dataItem.Units : undefined),
      StartTime: new FormControl(dataItem ? dataItem.StartTime : undefined),
      EndTime: new FormControl(dataItem ? dataItem.EndTime : undefined),
      Description: new FormControl(dataItem ? dataItem.Description : undefined),
      DepartmentName: new FormControl(dataItem ? dataItem.DepartmentName : undefined),
      UnitTypeId: new FormControl(dataItem ? dataItem.UnitTypeId : undefined),
      UnitType: new FormControl(dataItem ? dataItem.UnitType : undefined),
      Created: new FormControl(dataItem ? dataItem.Created : undefined),
      SalaryPeriodId: new FormControl(dataItem ? dataItem.SalaryPeriodId : undefined),
      UserEmploymentId: new FormControl(dataItem ? dataItem.UserEmploymentId : undefined),
      Status: new FormControl(dataItem ? dataItem.Status : undefined),
      KilometersDriven: new FormControl(dataItem ? dataItem.KilometersDriven : undefined)
    });
  }

  public filter: CompositeFilterDescriptor;
  public gridData: any[] = this.TimeEntryRecords;

  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    if (filter && filter.filters && filter.filters.length > 0) {
      const filterDateTimeGrid = new FilterDateTimeGrid();
      const filtersDate: any = [];
      // Using FilterDateTimeGrid to filter date before filter another field
      this.filter = filterDateTimeGrid.convertFilterOperator(filter, filtersDate, 'EntryDate', 'EndDate');

      this.TimeEntryRecords = filterDateTimeGrid.doFilterDate(
        this.DefaultTimeEntryRecord,
        filtersDate,
        'EntryDate',
        'EndDate'
      );

      this.TimeEntryRecords = filterBy(this.TimeEntryRecords, this.filter);
      if (filtersDate.length > 0) {
        [].push.apply(this.filter.filters, filtersDate);
      }
    } else {
      this.TimeEntryRecords = this.DefaultTimeEntryRecord;
      this.SelectedTimeStatusId = -1;
      this.timeStatusChanged();
    }
  }

  public expanded = false;
  public onExpand(event: any): void {
    this.expanded = event;
  }

  public onAddNewItem() {
    if (!this.sessionService.isSubmitting) {
      this.isAddNew = true;
    }
  }
}
