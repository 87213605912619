import { EventEmitter } from '@angular/core';
import { Transition, TransitionService } from '@uirouter/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { Constants } from '../common/constants';
import { Global } from '../common/global';
import { KeyDownHelper } from '../common/key-down-helper';
import { SessionService } from '../services/session/session.service';
import { IUserEmploymentView } from './api/api-model';
import { DataService } from './api/data.service';
import { StaticDataService } from './api/static-data.service';
import { SettingService } from './setting.service';

export abstract class CompanyService {
  public confirmUpdateDialogVisible = false;
  public navigationToStateName = '';

  constructor(
    protected dataService: DataService,
    protected staticDataService: StaticDataService,
    protected settingService: SettingService,
    protected sessionService: SessionService,
    protected transitionService: TransitionService,
    protected broadcaster: BroadcastService
  ) {
    this.transitionService.onStart({}, (transition: Transition) => {
      const fromState: string = transition.from().name;
      const toState = transition.to().name;
      this.navigationToStateName = JSON.parse(JSON.stringify(toState));

      if (this.editMode && fromState !== toState) {
        if (this.tabValid && this.tabDirty) {
          this.confirmUpdateDialogVisible = true;
        } else {
          this.editMode = false;
          this.sessionService.NavigateTo(this.navigationToStateName);
          return true;
        }
        return false;
      }
      return true;
    });

    this.broadcaster
      .on<any>(Constants.GLOBAL_KEY_DOWN)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => this.onGlobalKeyDown(data));
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();

  private editModeValue: boolean;
  public get editMode(): boolean {
    return this.editModeValue;
  }

  public set editMode(value: boolean) {
    if (this.allowChangeEdit(value) && this.editModeValue !== value) {
      this.editModeValue = value;
      this.sessionService.hideGetStarted = value;
      this.editModeChange.emit(value);
    }
  }

  public editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public editShortcutChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public discardChangeShortcutChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public saveChangeShortcutChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public showReportShortcutChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public addNewShortcutChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private tabValidValue: boolean;
  public get tabValid(): boolean {
    return this.tabValidValue;
  }
  public set tabValid(value: boolean) {
    if (this.tabValidValue !== value) {
      this.tabValidValue = value;
    }
  }

  private tabDirtyValue: boolean;
  public get tabDirty(): boolean {
    return this.tabDirtyValue;
  }
  public set tabDirty(value: boolean) {
    if (this.tabDirtyValue !== value) {
      this.tabDirtyValue = value;
    }
  }

  public get isIOSApp(): boolean {
    return this.sessionService.browser.isHybridApp && this.sessionService.browser.iOSMobileDevice;
  }

  public get isMobile() {
    return this.sessionService.browser.isMobile;
  }

  public get IsMobileOrHybridApp(): boolean {
    return this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
  }

  public get isIOSMobileApp(): boolean {
    return this.sessionService.browser.iOSMobileDevice;
  }

  public reloadCompanyPreference() {
    // TODO
    // reload company Preference from api Preferences_GetCompanyPreferences
  }

  public onConfirmSaveDataOnChangeState(action: string): void {
    if (action === 'Continue') {
      this.onContinueAction();
    } else if (action === 'DiscardAndLeave') {
      this.onDiscardAndLeaveAction();
    }
  }

  public onGlobalKeyDown($event: any): void {
    if (this.allowShortcut && !this.sessionService.isGetting && !this.sessionService.isSubmitting) {
      if (KeyDownHelper.isEditShortcutKey($event)) {
        $event.preventDefault();
        this.editShortcut();
        return;
      }

      if (KeyDownHelper.isDiscardingChangesShortcutKey($event)) {
        $event.preventDefault();
        this.discardChangeShortcut();
        return;
      }

      if (KeyDownHelper.isSavingChangesShortcutKey($event)) {
        $event.preventDefault();
        this.saveChangeShortcut();
        return;
      }

      if (KeyDownHelper.isReportShortcutKey($event)) {
        $event.preventDefault();
        this.showReportShortcut();
        return;
      }

      if (KeyDownHelper.isNewShortcutKey($event)) {
        $event.preventDefault();
        this.addNewShortcut();
        return;
      }
    }
  }

  public moveToNextState(): void {
    if (this.navigationToStateName) {
      this.sessionService.NavigateTo(this.navigationToStateName);
    }
  }

  protected editShortcut(): void {
    this.editShortcutChange.emit(true);
  }
  protected discardChangeShortcut(): void {
    this.discardChangeShortcutChange.emit(true);
  }
  protected saveChangeShortcut(): void {
    this.saveChangeShortcutChange.emit(true);
  }
  protected showReportShortcut(): void {
    this.showReportShortcutChange.emit(true);
  }
  protected addNewShortcut(): void {
    this.addNewShortcutChange.emit(true);
  }

  protected allowChangeEdit(value: boolean): boolean {
    return true;
  }

  protected get allowShortcut(): boolean {
    return true;
  }

  protected onContinueAction(): void {}

  protected onDiscardAndLeaveAction(): void {}
}
