import { GridColumnComponent } from './grid-column.component';

export class GridNavigationService {
  public getNextEditableCell(
    columns: GridColumnComponent[],
    rows: any[],
    columnIndex: number,
    rowIndex: number,
    event: any
  ): any {
    return this.getNextPreviousCell(columns, rows, columnIndex, rowIndex, event);
  }

  private getNextPreviousCell(
    columns: GridColumnComponent[],
    rows: any[],
    columnIndex: number,
    rowIndex: number,
    event: any
  ): any {
    if (event.key === 'ArrowUp') {
      rowIndex = rowIndex - 1;
    } else if (event.key === 'ArrowDown') {
      rowIndex = rowIndex + 1;
    } else {
      columnIndex = columnIndex + (!event.shiftKey ? 1 : -1);
    }

    if (columnIndex < 0) {
      columnIndex = columns.length - 1;
      rowIndex = rowIndex - 1;
    } else if (columnIndex > columns.length - 1) {
      columnIndex = 0;
      rowIndex = rowIndex + 1;
    }

    if (rowIndex < 0 || rowIndex > rows.length - 1) {
      return undefined;
    }

    const nextEditableColumn: GridColumnComponent = columns[columnIndex];
    if (
      (!nextEditableColumn.editableField && nextEditableColumn.editable) ||
      (nextEditableColumn.editableField && rows[rowIndex][nextEditableColumn.editableField])
    ) {
      if (nextEditableColumn['type'] && nextEditableColumn['type'] === 'checkbox') {
        return this.getNextPreviousCell(columns, rows, columnIndex, rowIndex, event);
      }
      return { rowIndex, columnIndex };
    } else {
      return this.getNextPreviousCell(columns, rows, columnIndex, rowIndex, event);
    }
  }
}
