import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Transition, TransitionService } from '@uirouter/core';
import { IAccount, ICompany } from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { environment } from 'src/environments/environment';
import { ReportDialogService } from '../../shared-components/report-dialog/report-dialog.service';
import { AccountantSummaryService } from '../summary/accountant-summary.service';
import { AccountantConfigurationService } from './accountant-configuration.service';

@Component({
  selector: 'app-accountant-configuration',
  templateUrl: './accountant-configuration.component.html',
  styleUrls: ['./accountant-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountantConfigurationComponent implements OnInit {
  public discardDialogVisible = false;
  public addNewUserVisible = false;
  public confirmDialogVisible = false;
  public warningNoNameMessage = false;
  public manager: any = {};
  public dataDefaultInvoiceTypeId: any[] = [];
  public dataAccountTypeId: any[] = [];
  public selectedCompanyId = 1;
  public listClassExcluded = ['Footer', '10'];

  public get isIOSApp(): boolean {
    return this.sessionService.browser.isHybridApp && this.sessionService.browser.iOSMobileDevice;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public get branding() {
    return environment.branding;
  }

  constructor(
    public service: AccountantConfigurationService,
    private summaryService: AccountantSummaryService,
    public staticDataService: StaticDataService,
    public dataService: DataService,
    public sessionService: SessionService,
    private transitionService: TransitionService,
    public reportDialogService: ReportDialogService
  ) {}

  public ngOnInit() {
    this.service.loadData();
    this.transitionService.onStart({}, (transition: Transition) => {
      const fromState: string = transition.from().name;
      const toState = transition.to().name;
      this.service.navigationToStateName = JSON.parse(JSON.stringify(toState));

      if (this.service.editMode && fromState !== toState) {
        if (this.service.editMode && !this.discardDialogVisible && this.service.isDirty) {
          this.service.showSaveDataConfirmationChangeState = true;
        } else {
          this.onEditModeChange(false);
          this.sessionService.NavigateTo(this.service.navigationToStateName);
          return true;
        }
        return false;
      }
      return true;
    });

    if (this.service.account.MasterCompanyId) {
      this.selectedCompanyId = 1;
    } else {
      this.selectedCompanyId = 2;
    }
  }

  public onConfirmSaveDataOnChangeState(action: string): void {
    if (action === 'ContinueAndSave') {
      this.onEditModeChange(false);
      this.service.isDirty = false;
      this.sessionService.NavigateTo(this.service.navigationToStateName);
      return;
    }

    if (action === 'DiscardAndLeave') {
      this.service.isDirty = false;
      this.service.account = JSON.parse(this.service.rawAccount);
      this.revertCompanyData();
      this.onEditModeChange(false);
      this.sessionService.NavigateTo(this.service.navigationToStateName);
      return;
    }
  }

  public tabDblclick(): void {
    this.onEditModeChange(true);
  }

  public onEditModeChange($event: boolean): void {
    if ($event) {
      if (this.service.account.MasterCompanyId) {
        this.selectedCompanyId = 1;
      } else {
        this.selectedCompanyId = 2;
      }
    }

    if (this.service.editMode === $event) {
      return;
    }

    if ($event && (this.sessionService.isGetting || this.sessionService.isSubmitting)) {
      return;
    }

    if (this.service.account.Name.length > 0) {
      if (this.service.editMode && !this.discardDialogVisible && this.service.isDirty) {
        this.checkAccountData();
        this.dataService.Account_UpdateAccount(this.service.account).subscribe((data: any) => {
          this.service.account = data;
          this.service.rawAccount = JSON.stringify(data);
          this.service.rawCompany = data.MasterCompanyId ? 1 : 2;
          if (data.MasterCompanyId === null) {
            this.service.externalCompany = this.service.currentCompany;
            this.service.rawExternalCompany = JSON.stringify(this.service.externalCompany);
          } else {
            this.service.rawInternalCompany = JSON.stringify(this.service.internalCompany);
          }
          this.service.isDirty = false;
          this.summaryService.updateCompanyInvoiceGeneral(this.service.account, true);
        });
      }
      this.service.editMode = $event;
    } else if (this.service.editMode === false) {
      this.warningNoNameMessage = true;
    }
  }

  private checkAccountData() {
    this.service.account.MasterCompanyEmail =
      this.service.account.MasterCompanyEmail === '' ? null : this.service.account.MasterCompanyEmail;

    const currentCompany = this.service.currentCompany;
    if (!currentCompany.CompanyId) {
      this.service.account.MasterCompanyId = null;
    } else {
      this.service.account.MasterCompanyId = currentCompany.CompanyId;
    }

    this.service.account.MasterCompanyVATRegistrationNumber = currentCompany.CVR || null;
    this.service.account.MasterCompanyName = currentCompany.Name || null;
    this.service.account.MasterCompanyAddress = currentCompany.Address || null;
    this.service.account.MasterCompanyPostalCode = currentCompany.PostalCode || null;
    this.service.account.MasterCompanyCity = currentCompany.City || null;
    this.service.account.MasterCompanyCountry = currentCompany.Country || null;
    this.service.account.MasterCompanyEAN = currentCompany.EAN || null;
  }

  public confirmAndDiscardChanges(action: string): void {
    if (
      this.service.rawAccount !== JSON.stringify(this.service.account) ||
      this.service.rawInternalCompany !== JSON.stringify(this.service.internalCompany) ||
      this.service.rawExternalCompany !== JSON.stringify(this.service.externalCompany) ||
      this.selectedCompanyId !== this.service.rawCompany
    ) {
      this.discardDialogVisible = true;
      return;
    }

    this.onEditModeChange(false);
  }

  public discardDialogAction(action: string): void {
    if (action === 'ConfirmDiscard') {
      this.service.account = JSON.parse(this.service.rawAccount);
      this.revertCompanyData();
      this.service.editMode = false;
    }
  }

  private revertCompanyData() {
    this.selectedCompanyId = this.service.rawCompany;
    this.service.internalCompany =
      this.service.rawInternalCompany.length > 10
        ? JSON.parse(this.service.rawInternalCompany)
        : this.service.internalCompany;
    this.service.internalCompany = JSON.parse(this.service.rawInternalCompany);
    this.service.externalCompany = JSON.parse(this.service.rawExternalCompany);
    this.service.currentCompany =
      this.service.rawCompany === 1 ? this.service.internalCompany : this.service.externalCompany;
  }

  public onNameChange(): void {}
  public onEmailChange(): void {}
  public onValueChange(): void {}
  public onCompanyChange(companyId: any): void {
    if (companyId === null || companyId === undefined || this.service.currentCompany.Id === companyId) {
      return;
    }
    if (companyId === 1) {
      this.service.currentCompany = this.service.internalCompany;
    } else if (companyId === 2) {
      this.service.currentCompany = this.service.externalCompany;
    }
    this.service.isDirty = true;
  }

  public onInternalCompanyChange(companyId: any): void {
    if (typeof companyId !== 'number') {
      return;
    }
    this.dataService.Companies_Get(companyId).subscribe((company: ICompany) => {
      const internalCompany: any = {};
      internalCompany.CVR = company.VATRegistrationNumber || null;
      internalCompany.Name = company.Name || '';
      internalCompany.Address = company.Address
        ? (company.Address.Line1 || '') + ' ' + (company.Address.Line2 || '')
        : '';
      internalCompany.PostalCode = company.Address ? company.Address.PostalCode : '';
      internalCompany.City = company.Address ? company.Address.City : '';
      internalCompany.Country = company.Country ? company.Country.Name : '';
      internalCompany.EAN = company.InvoiceIdentifier || null;
      internalCompany.CompanyId = company.Id;

      this.service.internalCompany = internalCompany;
      this.service.currentCompany = internalCompany;
    });
  }
}
