<div class="FormElement">
  <div class="FormElement-control">
    <app-grid
      [data]="gridData"
      class="FormElement-grid Company-gridOthersErrorDetails u-gridHeight250"
      [editMode]="false"
      [filterable]="'menu'"
      [filter]="filter"
      (filterChange)="onFilterChange($event)"
    >
      <app-grid-column
        [title]="'CompanySalaryBatches.OtherStatusGrid_IsError' | translate"
        [type]="'icon'"
        [iconField]="'icon'"
        [tooltipField]="'tooltip'"
        [width]="30"
      >
      </app-grid-column>

      <app-grid-column
        [isFilterable]="true"
        [title]="'CompanySalaryBatches.OtherStatusGrid_IsErrorDescription' | translate"
        [type]="'text'"
        [field]="'description'"
        [width]="200"
      >
      </app-grid-column>

      <app-grid-column
        [isFilterable]="true"
        [title]="'CompanySalaryBatches.OtherStatusGrid_EmployeeName' | translate"
        [type]="'text'"
        [field]="'employeeName'"
        [width]="100"
      >
      </app-grid-column>
    </app-grid>
  </div>
</div>
