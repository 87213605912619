<div class="Card-inner">
  <div class="FormElement">
    <div class="FormElement-control">
      <app-grid
        class="FormElement-grid Employee-payrollDataGrid"
        [editMode]="!IsReadOnly"
        [deleteRow]="!IsReadOnly"
        [customDeleteRow]="true"
        [addNew]="!IsReadOnly"
        [addNewDisable]="disableGridToolbar"
        [idColumn]="'Id'"
        [addNewText]="'Grids.AddNewRecord' | translate"
        [(data)]="gridData"
        [(selectedId)]="selectedId"
        (selectedItemChange)="onSelectedItemChanges($event)"
        [(triggerUpdate)]="triggerGridComponentUpdate"
        (addNewEvent)="addHandler($event)"
        (removeEvent)="removeHandler($event)"
        (saveChangesEvent)="saveChanges($event)"
        (cellCloseEvent)="onCellCloseEvent($event)"
        (action)="onGridAction($event)"
        (gridButtonClick)="onGridButtonClicked($event)"
        id="payrollGrid"
        #kendoGridComponent
        [filterable]="'menu'"
        [filter]="filter"
        [(filterClear)]="filterClear"
        (filterChange)="onFilterChange($event)"
      >
        <app-grid-action
          [isHidden]="IsReadOnly"
          [disable]="disableGridToolbar"
          [action]="'recalculate'"
          [label]="'EmploymentPayrollData.Recalculate' | translate"
        ></app-grid-action>
        <app-grid-action
          [isHidden]="!isFullAdmin || IsEnableDateAdjustment"
          [disable]="disableGridToolbar"
          [action]="'enableDate'"
          [label]="'EmploymentPayrollData.EnableDateAdjustment' | translate"
        ></app-grid-action>
        <app-grid-action
          [isHidden]="!isFullAdmin || !IsEnableDateAdjustment"
          [disable]="disableGridToolbar"
          [action]="'disableDate'"
          [label]="'EmploymentPayrollData.DisableDateAdjustment' | translate"
        >
        </app-grid-action>
        <app-grid-action
          [isHidden]="IsReadOnly"
          [disable]="disableGridToolbar"
          [action]="'correctBalance'"
          [label]="'EmploymentPayrollData.CorrectBalances' | translate"
        ></app-grid-action>
        <app-grid-action
          [actionsSpanClass]="'btnHidePreviewGrid'"
          [isHidden]="IsReadOnly"
          [disable]="disableGridToolbar"
          action="onHidePreviewGrid"
          icon="{{ IconisHidePreviewGrid }}"
        >
        </app-grid-action>

        <app-grid-column
          [isFilterable]="true"
          [field]="'SalaryTypeId'"
          [comboboxDataSource]="salaryTypes"
          [type]="'combobox'"
          [width]="150"
          [comboboxValueField]="'SalaryTypeId'"
          [comboboxDisplayField]="'Name'"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [editable]="true"
          [title]="'EmploymentPayrollData.SalaryType' | translate"
          [tooltipField]="'Description'"
          [tooltipFieldNonEdit]="'salaryTypeName'"
          [headerTooltip]="'EmploymentPayrollData.SalaryType' | translate"
        >
        </app-grid-column>

        <app-grid-column
          [isFilterable]="true"
          [field]="'Description'"
          [tooltipField]="'Description'"
          [type]="'text'"
          [editable]="true"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [title]="'EmploymentPayrollData.Description' | translate"
          [width]="150"
          [headerTooltip]="'EmploymentPayrollData.Description' | translate"
        ></app-grid-column>

        <app-grid-column
          [isFilterable]="true"
          [field]="'BaseAmount'"
          [type]="'numeric'"
          [editable]="true"
          [title]="'EmploymentPayrollData.BaseAmount' | translate"
          [width]="75"
          [numbericOption]="NumericOptions"
          [hasIncellPopup]="true"
          [popupContent]="negativeDefaultTranslationText['HandlingOfSygedagpenge']"
          [editableField]="'AllowEditBaseAmount'"
          [classField]="'BaseAmountClass'"
          [headerTooltip]="'EmploymentPayrollData.BaseAmount' | translate"
        ></app-grid-column>

        <app-grid-column
          [isFilterable]="true"
          [field]="'Units'"
          [type]="'numeric'"
          [editable]="true"
          [title]="'EmploymentPayrollData.Units' | translate"
          [hasIncellPopup]="true"
          [popupContent]="negativeDefaultTranslationText['UnitsAutoConfigured']"
          [width]="70"
          [numbericOption]="NumericOptions"
          [editableField]="'AllowEditUnits'"
          [classField]="'UnitsClass'"
          [headerTooltip]="'EmploymentPayrollData.Units' | translate"
        ></app-grid-column>

        <app-grid-column
          [isFilterable]="true"
          [field]="'UnitTypeId'"
          [comboboxDataSource]="unitTypes"
          [type]="'combobox'"
          [comboboxValueField]="'Id'"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [comboboxDisplayField]="'Name'"
          [editable]="true"
          [width]="60"
          [title]="' '"
          [editableField]="'AllowEditUnitType'"
          [classField]="'UnitTypeClass'"
        ></app-grid-column>

        <app-grid-column
          [isFilterable]="true"
          [field]="'AmountPerUnit'"
          [type]="'numeric'"
          [editable]="true"
          [title]="'EmploymentPayrollData.PerUnit' | translate"
          [width]="95"
          [numbericOption]="NumericOptions"
          [editableField]="'AllowEditAmountPerUnit'"
          [classField]="'AmountPerUnitClass'"
          [headerTooltip]="'EmploymentPayrollData.PerUnit' | translate"
        ></app-grid-column>

        <app-grid-column
          [isFilterable]="true"
          [field]="'Amount'"
          [type]="'numeric'"
          [editable]="true"
          [title]="'EmploymentPayrollData.Amount' | translate"
          [hasIncellPopup]="true"
          [popupContent]="AmountFlipMessage"
          [popupActions]="AmountFlipButtons"
          [width]="95"
          [numbericOption]="NumericOptions"
          [headerTooltip]="'EmploymentPayrollData.Amount' | translate"
        >
        </app-grid-column>

        <app-grid-column
          [isFilterable]="true"
          [field]="'PersistenceTypeId'"
          [comboboxDataSource]="persistanceTypes"
          [type]="'combobox'"
          [comboboxValueField]="'Id'"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [comboboxDisplayField]="'Name'"
          [editable]="true"
          [width]="100"
          [title]="'EmploymentPayrollData.AfterNextBatch' | translate"
          [headerTooltip]="'EmploymentPayrollData.AfterNextBatch' | translate"
          [tooltipField]="'Description'"
        >
        </app-grid-column>

        <app-grid-column
          [isFilterable]="true"
          [hideColumn]="isHideDateColumns"
          [field]="'StartDate'"
          [type]="'datepicker'"
          [editable]="true"
          [title]="'EmploymentPayrollData.FromDate' | translate"
          [width]="75"
          [headerTooltip]="'EmploymentPayrollData.FromDate' | translate"
          [AllowNull]="true"
        ></app-grid-column>

        <app-grid-column
          [isFilterable]="true"
          [hideColumn]="isHideDateColumns"
          [field]="'EndDate'"
          [type]="'datepicker'"
          [editable]="true"
          [title]="'EmploymentPayrollData.ToDate' | translate"
          [width]="75"
          [headerTooltip]="'EmploymentPayrollData.ToDate' | translate"
          [AllowNull]="true"
        ></app-grid-column>

        <app-grid-column
          [isFilterable]="true"
          *ngIf="Dimension1Preference && Dimension1Preference.Value && !isEnableDimensionPreference"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [type]="'text'"
          [editable]="true"
          [title]="Dimension1Preference?.Value"
          [width]="90"
          [field]="'Dimension1Value'"
        ></app-grid-column>
        <app-grid-column
          [isFilterable]="true"
          *ngIf="Dimension1Preference && Dimension1Preference.Value && isEnableDimensionPreference"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [type]="'combobox'"
          [editable]="true"
          [title]="Dimension1Preference?.Value"
          [width]="90"
          [field]="'Dimension1ValueId'"
          [comboboxDataSource]="DimensionXValue[0]"
          [comboboxValueField]="'Id'"
          [comboboxDisplayField]="'Value'"
          [allowComboeditNull]="true"
        ></app-grid-column>

        <app-grid-column
          [isFilterable]="true"
          *ngIf="Dimension2Preference && Dimension2Preference.Value && !isEnableDimensionPreference"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [type]="'text'"
          [editable]="true"
          [title]="Dimension2Preference?.Value"
          [width]="90"
          [field]="'Dimension2Value'"
        ></app-grid-column>
        <app-grid-column
          [isFilterable]="true"
          *ngIf="Dimension2Preference && Dimension2Preference.Value && isEnableDimensionPreference"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [type]="'combobox'"
          [editable]="true"
          [title]="Dimension2Preference?.Value"
          [width]="90"
          [field]="'Dimension2ValueId'"
          [comboboxDataSource]="DimensionXValue[1]"
          [comboboxValueField]="'Id'"
          [comboboxDisplayField]="'Value'"
          [allowComboeditNull]="true"
        ></app-grid-column>

        <app-grid-column
          [isFilterable]="true"
          *ngIf="Dimension3Preference && Dimension3Preference.Value && !isEnableDimensionPreference"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [type]="'text'"
          [editable]="true"
          [title]="Dimension3Preference?.Value"
          [width]="90"
          [field]="'Dimension3Value'"
        ></app-grid-column>
        <app-grid-column
          [isFilterable]="true"
          *ngIf="Dimension3Preference && Dimension3Preference.Value && isEnableDimensionPreference"
          [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
          [type]="'combobox'"
          [editable]="true"
          [title]="Dimension3Preference?.Value"
          [width]="90"
          [field]="'Dimension3ValueId'"
          [comboboxDataSource]="DimensionXValue[2]"
          [comboboxValueField]="'Id'"
          [comboboxDisplayField]="'Value'"
          [allowComboeditNull]="true"
        ></app-grid-column>
      </app-grid>
    </div>
  </div>
</div>

<app-negative-default-dialog
  [setting]="negativeDefaultMoreDialogModel.setting"
  [fieldName]="negativeDefaultMoreDialogModel.fieldName"
  [dataItem]="negativeDefaultMoreDialogModel.dataItem"
  [(showDialog)]="showNegativeDefaultMoreInfoDialog"
  (usePositiveAmount)="onMoreInfoUsePositiveAmount($event)"
  (enableAutoCorrect)="autoCorrect($event)"
></app-negative-default-dialog>

<app-dialog
  (action)="onConfirmDateAdjustmentSetting($event)"
  [(visible)]="showDateSettingConfirmation"
  [leadingText]="'EmploymentPayrollData.EnableDateAdjustmentMessage' | translate: { SystemAlias: branding.SystemAlias }"
>
  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action type="Enable" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onRemoveRowConfirmed($event)"
  [(visible)]="showDeleteItemConfirmation"
  [leadingText]="'Grids.ConfirmDelete' | translate"
>
  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action type="Delete" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>
