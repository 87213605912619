import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from 'src/app/common/constants';
import { Global } from 'src/app/common/global';
import { FormComponentBase } from '../../common/form-component-base';
import { NumericTextBoxOptions } from '../../custom-controls/numeric-edit/numeric-text-box-options';
import {
  Department,
  ICompanyUser,
  IDepartment,
  ISalaryCycle,
  IUserEmployment,
  IUserEmploymentTemplate
} from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-hiring-data',
  templateUrl: './hiring-data.component.html'
})
export class HiringDataComponent extends FormComponentBase implements OnInit {
  @Input()
  get userEmployment(): IUserEmployment {
    if (!this.userEmploymentValue) {
      this.userEmploymentValue = {} as any;
    }

    return this.userEmploymentValue;
  }

  set userEmployment(value: IUserEmployment) {
    if (this.userEmploymentValue !== value) {
      this.userEmploymentValue = value;
      this.loadEmploymentTemplates();
      this.hasEmployment = value && value.Id ? true : false;
      this.salaryCycleId = this.userEmployment.SalaryCycleId;
    }
  }

  public hasDepartmentEnabled = false;
  public templates: IUserEmploymentTemplate[];
  public hasEmployment: boolean;
  public AddDepartmentVisible = false;
  public isShowSalaryCycleChangeWarnning = false;
  public salaryCycleId: number;

  private userEmploymentValue: IUserEmployment;
  private departments: IDepartment[];

  constructor(
    private dataService: DataService,
    private sessionService: SessionService,
    public staticDataService: StaticDataService
  ) {
    super();
    this.childIsValid = Array(11).fill(true);
    this.childIsDirty = Array(11).fill(false);
  }

  public get IsDisableCreateDepartment(): boolean {
    return this.sessionService.role.IsReadOnly || this.sessionService.role.IsSalaryAdmin;
  }

  public ngOnInit(): void {
    this.staticDataService.departments.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IDepartment[]) => {
      this.departments = data;
    });
  }

  get shouldHideTerminationDate(): boolean {
    return (
      !this.editMode &&
      this.userEmployment &&
      !this.userEmployment.ExpireDate &&
      this.sessionService.role.IsEmployeeRole
    );
  }

  public get isSwedenCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
  }

  get showTemplates(): boolean {
    return this.sessionService.feature.ShowEmployeeEmploymentTemplate;
  }

  public onComboboxEmployeeDetailDepartmentSelectionChanged(): void {
    const departmentId: number = this.userEmployment.DepartmentId;
    this.selectDefaultManager(departmentId);
    this.onChange();
  }

  public onCycleChange(val: number) {
    if (val !== this.userEmployment.SalaryCycleId) {
      this.isShowSalaryCycleChangeWarnning = true;
    }
  }

  public confimCycleChange(changes: string) {
    if (changes === 'Understood') {
      this.userEmployment.SalaryCycleId = this.salaryCycleId;
      this.onChange();
    } else {
      this.salaryCycleId = this.userEmployment.SalaryCycleId;
    }
  }

  private loadEmploymentTemplates(): void {
    if (!this.showTemplates) {
      return;
    }
    this.dataService
      .EmploymentTemplates_GetEmploymentTemplates()
      .subscribe((templates: IUserEmploymentTemplate[]): void => {
        this.templates = templates;
      });
  }

  private selectDefaultManager(departmentId: number): void {
    let department: IDepartment;
    if (this.departments) {
      department = this.departments.find((dpm: IDepartment) => {
        return dpm.Id === departmentId;
      });
    }

    if (department) {
      this.userEmployment.ManagerCompanyUserId = department.ManagerCompanyUserId
        ? department.ManagerCompanyUserId
        : undefined;
    }
  }
  public NumbericTextBoxFFHoursEarnedPerYearOptions: NumericTextBoxOptions = {
    format: 'n2',
    decimals: 2,
    step: 1,
    spinners: false
  };
}
