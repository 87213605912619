import { Component, OnDestroy, OnInit } from '@angular/core';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from 'src/app/common/constants';
import { Global } from 'src/app/common/global';
import { GridCellClickEvent } from 'src/app/custom-controls/grid/cell-click-event';
import { IExternalAccount, ISalaryType, ISalaryTypeCategoryView, IUnitType } from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { SettingService } from 'src/app/services/setting.service';
import { ReportDialogService } from 'src/app/shared-components/report-dialog/report-dialog.service';
import { SalaryTypeView } from './salary-type-view';
import { CompanySalaryTypeService } from './salary-types.service';

@Component({
  selector: 'app-salary-types',
  templateUrl: './salary-types.component.html'
})
export class SalaryTypesComponent implements OnInit, OnDestroy {
  public gridData: SalaryTypeView[] = [];
  public unitTypes: IUnitType[] = Global.UNIT_TYPES;
  public translationSalaryType: SalaryTypeView;

  public selectedId: number;
  public externalReferences: any[] = [];
  public newSalaryTypeDialogVisible: boolean;
  public translationDialogVisible: boolean;
  public hasSalaryTypeModule = false;
  public isShowWarrningChangeMode = false;
  public triggerUpdate = false;

  private salaryTypes: ISalaryType[];
  private currentGridRadioAction = 1;
  private lastMode = 1;
  public get gridRadioAction(): number {
    return this.currentGridRadioAction;
  }

  public set gridRadioAction(value: number) {
    if (value !== this.currentGridRadioAction) {
      this.currentGridRadioAction = value;

      if (value !== 3) {
        this.companySalaryTypeService.isWattingLoading = true;
        this.lastMode = value;
        this.triggerUpdate = true;

        setTimeout(() => {
          this.ModeActive();
        });
      } else {
        this.isShowWarrningChangeMode = true;
      }
    }
  }

  private loadStaticData() {
    this.staticDataService.FinanceAccountType.subscribe();
    this.staticDataService.SalarySummaryCategory.subscribe();
    this.staticDataService.SalaryRecordPersistenceType.subscribe();
    this.staticDataService.SalaryTypeCategory.subscribe();
    this.staticDataService.DimensionRedistributionBehavior.subscribe();
  }

  public onCompletemodeChange(event: any): void {
    if (event === 'Ok') {
      this.companySalaryTypeService.isWattingLoading = true;
      this.lastMode = 3;
      this.triggerUpdate = true;
    } else {
      this.currentGridRadioAction = this.lastMode;
    }
    this.ModeActive();
  }

  public simpleMode = false;
  public completeMode = false;

  private ModeActive(): void {
    this.simpleMode = this.lastMode === 1;
    this.completeMode = this.lastMode === 3;
  }

  public get isFromDenmark(): boolean {
    return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.DENMARK_COUNTRY_ID;
  }
  public get isFromGreenland(): boolean {
    return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.GREENLAND_COUNTRY_ID;
  }
  public get isFromSwedish(): boolean {
    return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.SWEDEN_COUNTRY_ID;
  }

  constructor(
    private dataService: DataService,
    public staticDataService: StaticDataService,
    public sessionService: SessionService,
    public settingService: SettingService,
    public reportDialogService: ReportDialogService,
    public companySalaryTypeService: CompanySalaryTypeService
  ) {
    this.companySalaryTypeService.addNewShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && !this.sessionService.role.IsReadOnly) {
          this.onAddClick();
        }
      });

    this.companySalaryTypeService.showReportShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && this.reportDialogService.isVisibleReportIcon) {
          this.reportDialogService.onShowReportsEventClick();
        }
      });

    this.companySalaryTypeService.salaryTypes.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ISalaryType[]) => {
      this.salaryTypes = data;
      this.gridData = [];

      this.gridData = this.companySalaryTypeService.staticSalaryTypes;
      this.gridFilterData = filterBy(this.gridData, this.filter);
      this.convertExternalRefCodeToFriendlyName();
    });
  }

  public ngOnInit(): void {
    this.ModeActive();
    this.loadStaticData();
    this.dataService.Integrations_GetAllExternalAccounts().subscribe((data: IExternalAccount[]) => {
      this.externalReferences = data;
      if (this.externalReferences && this.externalReferences.length > 0) {
        this.externalReferences.forEach((model: any) => {
          model.FriendlyName = model.AccountNumber + (model.AccountName ? ' - ' + model.AccountName : '');
        });
        this.convertExternalRefCodeToFriendlyName();
      }
    });

    this.companySalaryTypeService.initData();

    this.settingService.showModuleMessage('company.advancedsalarytypes');
    if (this.companySalaryTypeService.isMobile) {
      this.sessionService.isShowHugeFeaturesAlert = true;
    }
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.companySalaryTypeService.serviceDestroy();

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public saveChanges(value: CellCloseEvent): void {
    value.dataItem.ExternalReference = this.getExteralReferenceCode(value.dataItem.ExternalReference, true);

    const salaryType: any = value.dataItem.toSalaryType();
    salaryType.ExternalReference = this.getExteralReferenceCode(salaryType.ExternalReference) || null;
    this.dataService.SalaryTypes_UpdateSalaryType(salaryType).subscribe(
      (data: ISalaryType) => {
        data.ExternalReference = this.getExteralReferenceCode(data.ExternalReference, true);
        this.transformSalaryTypeObject(data);
        Object.assign(value.dataItem, new SalaryTypeView(data, this.companySalaryTypeService.isLanguageModuleEnable));
      },
      (error: any) => this.getSalaryTypes()
    );
  }

  public onAddClick(): void {
    this.newSalaryTypeDialogVisible = true;
  }

  public onAddSalaryType(value: ISalaryType): void {
    this.salaryTypes.push(value);
    this.transformSalaryTypeObject(value);
    this.gridData.push(new SalaryTypeView(value, this.companySalaryTypeService.isLanguageModuleEnable));
    this.gridFilterData = filterBy(this.gridData, this.filter);
    this.selectedId = value.Id;
  }

  public onCellClick(event: GridCellClickEvent): void {
    if (
      ['Name', 'Description', 'DefaultPayslipText', 'Explanation'].includes(event.field) &&
      this.companySalaryTypeService.isLanguageModuleEnable
    ) {
      this.translationSalaryType = event.dataItem;
      this.translationDialogVisible = true;
    }
  }

  public getSalaryTypes(): void {
    this.gridData = [];
    this.companySalaryTypeService
      .loadSalaryTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        () => {
          this.companySalaryTypeService.isWattingLoading = false;
        },
        () => {
          this.companySalaryTypeService.isWattingLoading = false;
        }
      );
  }

  private transformSalaryTypeObject(salaryType: ISalaryType): void {
    const existingSalaryType: ISalaryType = this.salaryTypes.find((s: ISalaryType) => s.Id === salaryType.Id);
    salaryType.SalaryTypeTranslations = salaryType.SalaryTypeTranslations
      ? salaryType.SalaryTypeTranslations
      : existingSalaryType
      ? existingSalaryType.SalaryTypeTranslations
      : undefined;
    salaryType.BaseSalaryType = salaryType.BaseSalaryType
      ? salaryType.BaseSalaryType
      : existingSalaryType
      ? existingSalaryType.BaseSalaryType
      : undefined;
  }

  private convertExternalRefCodeToFriendlyName(): void {
    if (this.gridData && this.gridData.length > 0 && this.externalReferences && this.externalReferences.length > 0) {
      this.gridData.forEach((salaryType: any) => {
        salaryType.ExternalReference = this.getExteralReferenceCode(salaryType.ExternalReference, true);
      });
      this.gridFilterData = filterBy(this.gridData, this.filter);
    }
  }

  private getExteralReferenceCode(refCode: string, fullName?: boolean): any {
    if (!refCode) {
      return null;
    }

    const externalRefCode: any = this.externalReferences
      ? this.externalReferences.find((item: any) => {
          return refCode === item.AccountNumber || refCode === item.FriendlyName;
        })
      : null;

    if (externalRefCode) {
      return !fullName ? externalRefCode.AccountNumber : externalRefCode.FriendlyName;
    }

    return refCode;
  }

  public filter: CompositeFilterDescriptor;
  public gridFilterData: any[] = this.gridData;
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridFilterData = filterBy(this.gridData, filter);
  }

  public onMoreMenuOptionClick(action: string): void {
    switch (action) {
      case 'salarytypenew':
        this.onAddClick();
        break;
    }
  }
}
