import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef, EventEmitter, OnInit } from '@angular/core';
import { ControlComponentBase } from '../../common/control-component-base';
import { UniqueId } from '../../common/unique-id';
import { SessionService } from '../../services/session/session.service';
var TextEditComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TextEditComponent, _super);
    function TextEditComponent(sessionService, elementRef, changeDetectorRef) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.sessionService = sessionService;
        _this.isShowSymbolRequired = false;
        _this.isTextArea = false;
        _this.textAreaLimitChar = 200;
        _this.TextAreaRows = 4;
        _this.autocorrect = 'on';
        _this.addonVisible = true;
        _this.isShowLimit = true;
        _this.blur = new EventEmitter();
        _this.addonClick = new EventEmitter();
        _this.keyUp = new EventEmitter();
        _this.iconClick = new EventEmitter();
        _this.readOnly = false;
        _this.showPassword = false;
        _this.emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(((?![-@])(?!^[-@])[^@]*[A-z|ñ|Ñ|a-zA-ZæøåÆØÅa-zA-ZÀ-ÿ|0-9]+\.)+[a-zA-Z|ñ|Ñ|a-zA-ZæøåÆØÅa-zA-ZÀ-ÿ0-9]{2,}))$/;
        _this.elementId = 'textEditComponentId' + UniqueId();
        _this.setTimeout = false;
        return _this;
    }
    TextEditComponent.prototype.ngOnInit = function () {
        this.readOnly = this.readonlyOnNotFocus && this.sessionService.browser.isChrome;
    };
    Object.defineProperty(TextEditComponent.prototype, "TextLenght", {
        get: function () {
            if (this.value) {
                if (this.textAreaLimitChar === 0) {
                    return this.textAreaLimitChar.toString();
                }
                var res = this.textAreaLimitChar - this.value.length;
                return res >= 0 ? res.toString() : '0';
            }
            return this.textAreaLimitChar.toString();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextEditComponent.prototype, "TextAreaClass", {
        get: function () {
            if (this.value && this.value.length > this.textAreaLimitChar && this.textAreaLimitChar !== 0) {
                return ' input-required';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    TextEditComponent.prototype.TextAreakeypress = function () {
        if (this.value && this.value.length > this.textAreaLimitChar) {
            this.value = this.value.substring(0, this.textAreaLimitChar);
        }
    };
    Object.defineProperty(TextEditComponent.prototype, "requiredValueMissing", {
        get: function () {
            return (this.required && (!this.value || this.value.length === 0)) || this.wrongValue === true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextEditComponent.prototype, "showRequiredMessage", {
        get: function () {
            return this.editMode && this.requiredValueMissing;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextEditComponent.prototype, "showValidationMessage", {
        get: function () {
            return this.editMode && !this.valid() && this.type === 'email';
        },
        enumerable: true,
        configurable: true
    });
    TextEditComponent.prototype.setInputClass = function () {
        return ((this.inputClass ? this.inputClass : '') +
            (this.showValidationMessage || this.requiredValueMissing ? ' input-required' : ''));
    };
    Object.defineProperty(TextEditComponent.prototype, "hasMask", {
        get: function () {
            return this.mask && this.mask.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    TextEditComponent.prototype.onBlur = function () {
        if (this.readonlyOnNotFocus && this.sessionService.browser.isChrome) {
            this.readOnly = false;
        }
        this.blur.emit();
    };
    TextEditComponent.prototype.onFocus = function () {
        var _this = this;
        if (this.readonlyOnNotFocus && this.sessionService.browser.isChrome) {
            this.readOnly = false;
        }
        if (this.textControlInput) {
            if (this.isGrid) {
                setTimeout(function () {
                    _this.textControlInput.nativeElement.select();
                });
            }
            else {
                this.textControlInput.nativeElement.select();
            }
        }
    };
    TextEditComponent.prototype.valid = function () {
        if (this.required && (!this.value || this.value === '')) {
            return false;
        }
        switch (this.type) {
            case 'email':
                return !this.value || this.value.length === 0 || this.emailRegex.test(this.value.trim());
            default:
                return true;
        }
    };
    TextEditComponent.prototype.onkeyup = function (key) {
        this.keyUp.emit(key);
    };
    TextEditComponent.prototype.onControlValidate = function () {
        if (this.type === 'email' && this.value) {
            if (this.emailRegex.test(this.value.trim())) {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    };
    return TextEditComponent;
}(ControlComponentBase));
export { TextEditComponent };
