import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormComponentBase } from '../../../common/form-component-base';
import { ICompany, ICompanyUser } from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';

@Component({
  selector: 'app-company-contact',
  templateUrl: 'company-contact.component.html'
})
export class CompanyContactComponent extends FormComponentBase {
  @Input() public company: ICompany;
  @Input() public contacts: ICompanyUser[];
  @Input()
  public set resetChildFrom(value: boolean) {
    if (value) {
      this.childIsDirty = Array(7).fill(false);
      this.childIsValid = Array(3).fill(true);
    }
  }

  public get branding() {
    return environment.branding;
  }

  private logoValue: string;
  @Input()
  public get currentLogo(): string {
    return this.logoValue;
  }
  public set currentLogo(value: string) {
    if (value !== this.logoValue) {
      this.logoValue = value;
      this.logo = value;
    }
  }

  public logo: string;
  public isVisibleMessageAfterChangeLogo: boolean;

  constructor(private dataService: DataService, private staticDataService: StaticDataService) {
    super();
    this.childIsDirty = Array(7).fill(false);
    this.childIsValid = Array(3).fill(true);
  }

  public onCompanyLogoChanged(companyData: any): void {
    if (Object.keys(companyData).length !== 0) {
      return;
    }

    this.dataService.Companies_UploadCompanyLogo(companyData).subscribe(
      (data: any): void => {
        this.logo = data;
        this.currentLogo = data;
        this.staticDataService.loadCurrentLogo();

        this.isVisibleMessageAfterChangeLogo = true;
      },
      (error: any): void => {
        this.logo = this.currentLogo;
      }
    );
  }
}
