import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { Subject } from 'rxjs';
import { Global } from 'src/app/common/global';
import { IDimensionValue, ISimpleKeyValuePair } from 'src/app/services/api/api-model';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { CompanyService } from '../../../services/company.service';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';
import { DimensionView } from './dimension-view';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/api/data.service";
import * as i2 from "../../../services/api/static-data.service";
import * as i3 from "../../../services/setting.service";
import * as i4 from "../../../services/session/session.service";
import * as i5 from "@uirouter/core";
import * as i6 from "../../../services/broadcast.service";
var DimensionsService = /** @class */ (function (_super) {
    tslib_1.__extends(DimensionsService, _super);
    function DimensionsService(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) {
        var _this = _super.call(this, dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) || this;
        _this.dataService = dataService;
        _this.staticDataService = staticDataService;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.transitionService = transitionService;
        _this.broadcaster = broadcaster;
        _this.currentDimensionNumber = Global.DimensionNumber || 1;
        _this.dimensionName = '';
        _this.dimension1 = '';
        _this.dimension2 = '';
        _this.dimension3 = '';
        _this.translations = [];
        _this.isShowErrorDialog = false;
        _this.triggerUpdate = false;
        _this.dimensionNaming = '';
        _this.dimension1Active = false;
        _this.dimension2Active = false;
        _this.dimension3Active = false;
        _this.currentDimension = null;
        _this.currentDimensionId = 0;
        _this.ngUnsubscribe = new Subject();
        _this.errorMessage = '';
        return _this;
    }
    DimensionsService.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    DimensionsService.prototype.updateDimensionLabel = function () {
        var _this = this;
        Global.COMPANY_PREFERENCES.forEach(function (pref) {
            if (pref.Key.indexOf('Dimension1') >= 0) {
                _this.dimension1 = pref.Value;
            }
            else if (pref.Key.indexOf('Dimension2') >= 0) {
                _this.dimension2 = pref.Value;
            }
            else if (pref.Key.indexOf('Dimension3') >= 0) {
                _this.dimension3 = pref.Value;
            }
            if (pref.Key.indexOf('Dimension' + _this.currentDimensionNumber) >= 0) {
                _this.currentPref = pref;
            }
        });
        if (this.dimension1.length === 0) {
            this.dimension1 = this.translations['CompanyConfigurations.Dimension1'];
            this.dimension1Active = false;
        }
        else {
            this.dimension1Active = true;
        }
        if (this.dimension2.length === 0) {
            this.dimension2 = this.translations['CompanyConfigurations.Dimension2'];
            this.dimension2Active = false;
        }
        else {
            this.dimension2Active = true;
        }
        if (this.dimension3.length === 0) {
            this.dimension3 = this.translations['CompanyConfigurations.Dimension3'];
            this.dimension3Active = false;
        }
        else {
            this.dimension3Active = true;
        }
        this.updateDimensionName();
    };
    DimensionsService.prototype.updateDimensionName = function () {
        switch (this.currentDimensionNumber) {
            case 1:
                this.dimensionName = this.dimension1;
                break;
            case 2:
                this.dimensionName = this.dimension2;
                break;
            case 3:
                this.dimensionName = this.dimension3;
                break;
        }
    };
    DimensionsService.prototype.createDimensionValue = function (dimension) {
        if (!dimension) {
            dimension = new DimensionView({
                Id: 0,
                CompanyId: Global.COMPANY_ID,
                DimensionNumber: this.currentDimensionNumber
            });
        }
        var dimensionItem = {
            Id: dimension.Id,
            CompanyId: dimension.CompanyId,
            DimensionNumber: dimension.DimensionNumber,
            Value: dimension.Value || null,
            Description: dimension.Description || null,
            ApproverCompanyUserId: dimension.ApproverCompanyUserId || null,
            LimitToDepartmentId: dimension.LimitToDepartmentId || null,
            LimitToTemplateId: dimension.LimitToTemplateId || null
        };
        return dimensionItem;
    };
    DimensionsService.prototype.convertToDimensionView = function (dimension) {
        var dimensionItem = {
            Id: dimension.Id,
            IdView: dimension.Id > 0 ? dimension.Id : null,
            CompanyId: dimension.CompanyId,
            DimensionNumber: dimension.DimensionNumber,
            Value: dimension.Value || null,
            Description: dimension.Description || null,
            ApproverCompanyUserId: dimension.ApproverCompanyUserId || null,
            LimitToDepartmentId: dimension.LimitToDepartmentId || null,
            LimitToTemplateId: dimension.LimitToTemplateId || null
        };
        return dimensionItem;
    };
    DimensionsService.prototype.createDimension = function () {
        var _this = this;
        var item = this.createDimensionValue();
        this.dataService.DimensionValues_CreateDimensionValue(item).subscribe(function (dimensionValue) {
            var dimensionView = _this.convertToDimensionView(dimensionValue);
            _this.listDimensionsData.push(dimensionView);
            _this.currentDimensionId = dimensionView.Id;
            _this.triggerUpdate = true;
        });
    };
    DimensionsService.prototype.updateDimension = function (dimension) {
        var _this = this;
        if (!dimension) {
            dimension = this.currentDimension;
        }
        var rawDimension = JSON.stringify(dimension);
        var item = this.createDimensionValue(dimension);
        this.dataService.DimensionValues_UpdateDimensionValue(item).subscribe(function (dimensionValue) {
            var dimensionView = _this.convertToDimensionView(dimensionValue);
            _this.listDimensionsData.forEach(function (data) {
                if (data.Id === dimensionView.Id) {
                    data = dimensionView;
                }
            });
            _this.triggerUpdate = true;
        }, function (err) {
            var dimensionView = JSON.parse(rawDimension);
            _this.listDimensionsData.forEach(function (data) {
                if (data.Id === dimensionView.Id) {
                    data = dimensionView;
                }
            });
            _this.triggerUpdate = true;
        });
    };
    DimensionsService.prototype.deleteDimension = function (dimension) {
        var _this = this;
        if (!dimension) {
            dimension = this.currentDimension;
        }
        var item = this.createDimensionValue(dimension);
        this.dataService.DimensionValues_DeleteDimensionValue(item.Id).subscribe(function () {
            _this.getDimensionData(_this.currentDimensionNumber);
        });
    };
    DimensionsService.prototype.getDimensionData = function (index) {
        var _this = this;
        this.dataService.DimensionValues_GetDimensionValues(index).subscribe(function (data) {
            _this.listDimensionsData = data || [];
            _this.currentDimension = _this.listDimensionsData[0];
            _this.currentDimensionId = _this.listDimensionsData[0] ? _this.listDimensionsData[0].Id : 0;
            _this.triggerUpdate = true;
        });
    };
    DimensionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DimensionsService_Factory() { return new DimensionsService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.StaticDataService), i0.ɵɵinject(i3.SettingService), i0.ɵɵinject(i4.SessionService), i0.ɵɵinject(i5.TransitionService), i0.ɵɵinject(i6.BroadcastService)); }, token: DimensionsService, providedIn: "root" });
    return DimensionsService;
}(CompanyService));
export { DimensionsService };
