import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from 'src/app/common/constants';
import { Global } from 'src/app/common/global';
import { FormComponentBase } from '../../common/form-component-base';
import { IDepartment, IUserEmployment, IUserEmploymentTemplate } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
var HiringDataComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HiringDataComponent, _super);
    function HiringDataComponent(dataService, sessionService, staticDataService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.sessionService = sessionService;
        _this.staticDataService = staticDataService;
        _this.hasDepartmentEnabled = false;
        _this.AddDepartmentVisible = false;
        _this.isShowSalaryCycleChangeWarnning = false;
        _this.NumbericTextBoxFFHoursEarnedPerYearOptions = {
            format: 'n2',
            decimals: 2,
            step: 1,
            spinners: false
        };
        _this.childIsValid = Array(11).fill(true);
        _this.childIsDirty = Array(11).fill(false);
        return _this;
    }
    Object.defineProperty(HiringDataComponent.prototype, "userEmployment", {
        get: function () {
            if (!this.userEmploymentValue) {
                this.userEmploymentValue = {};
            }
            return this.userEmploymentValue;
        },
        set: function (value) {
            if (this.userEmploymentValue !== value) {
                this.userEmploymentValue = value;
                this.loadEmploymentTemplates();
                this.hasEmployment = value && value.Id ? true : false;
                this.salaryCycleId = this.userEmployment.SalaryCycleId;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HiringDataComponent.prototype, "IsDisableCreateDepartment", {
        get: function () {
            return this.sessionService.role.IsReadOnly || this.sessionService.role.IsSalaryAdmin;
        },
        enumerable: true,
        configurable: true
    });
    HiringDataComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.departments.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.departments = data;
        });
    };
    Object.defineProperty(HiringDataComponent.prototype, "shouldHideTerminationDate", {
        get: function () {
            return (!this.editMode &&
                this.userEmployment &&
                !this.userEmployment.ExpireDate &&
                this.sessionService.role.IsEmployeeRole);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HiringDataComponent.prototype, "isSwedenCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HiringDataComponent.prototype, "showTemplates", {
        get: function () {
            return this.sessionService.feature.ShowEmployeeEmploymentTemplate;
        },
        enumerable: true,
        configurable: true
    });
    HiringDataComponent.prototype.onComboboxEmployeeDetailDepartmentSelectionChanged = function () {
        var departmentId = this.userEmployment.DepartmentId;
        this.selectDefaultManager(departmentId);
        this.onChange();
    };
    HiringDataComponent.prototype.onCycleChange = function (val) {
        if (val !== this.userEmployment.SalaryCycleId) {
            this.isShowSalaryCycleChangeWarnning = true;
        }
    };
    HiringDataComponent.prototype.confimCycleChange = function (changes) {
        if (changes === 'Understood') {
            this.userEmployment.SalaryCycleId = this.salaryCycleId;
            this.onChange();
        }
        else {
            this.salaryCycleId = this.userEmployment.SalaryCycleId;
        }
    };
    HiringDataComponent.prototype.loadEmploymentTemplates = function () {
        var _this = this;
        if (!this.showTemplates) {
            return;
        }
        this.dataService
            .EmploymentTemplates_GetEmploymentTemplates()
            .subscribe(function (templates) {
            _this.templates = templates;
        });
    };
    HiringDataComponent.prototype.selectDefaultManager = function (departmentId) {
        var department;
        if (this.departments) {
            department = this.departments.find(function (dpm) {
                return dpm.Id === departmentId;
            });
        }
        if (department) {
            this.userEmployment.ManagerCompanyUserId = department.ManagerCompanyUserId
                ? department.ManagerCompanyUserId
                : undefined;
        }
    };
    return HiringDataComponent;
}(FormComponentBase));
export { HiringDataComponent };
