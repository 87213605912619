<div [ngClass]="companyTemplatesService.editMode ? 'is-editing' : ''">
  <div *ngIf="companyTemplatesService.isEnable" class="Island-configuration CompanyConfigTemplate">
    <form id="employmentTemplateForm" name="employmentTemplateForm" onsubmit="return false;" novalidate>
      <div class="Cards Cards--flex">
        <div class="Card Card--small">
          <div class="Card-inner Form--stacked">
            <app-template-list
              [templates]="companyTemplatesService.templates | async"
              [(selectedId)]="companyTemplatesService.selectedTemplateId"
              [refreshList]="companyTemplatesService.refreshTemplateGridList"
              [(isEditMode)]="companyTemplatesService.editMode"
            ></app-template-list>
            <app-template-employee-list
              [selectedTemplateId]="companyTemplatesService.selectedTemplateId"
              [(hasEmployees)]="companyTemplatesService.templateHasEmployees"
            ></app-template-employee-list>
          </div>
        </div>

        <div class="Card Card--large" (dblclick)="onDbClick()">
          <div class="Cards Cards--packed Cards--packed2col Cards--packed--block">
            <div class="Card">
              <div class="Card-inner">
                <!--General-->
                <fieldset *ngIf="companyTemplatesService.templateDetail" class="Form--horizontal">
                  <legend class="Card-title">{{ 'EmployeeEmployment.General' | translate }}</legend>
                  <app-text-edit
                    label="{{ 'CompanyHiringStatus.TemplateName' | translate }}"
                    [required]="true"
                    [editMode]="companyTemplatesService.editMode"
                    [(isDirty)]="childIsDirty[0]"
                    [(isValid)]="childIsValid[0]"
                    (valueChange)="onChange()"
                    [(value)]="companyTemplatesService.templateDetail.Name"
                  ></app-text-edit>
                  <app-text-edit
                    label="{{ 'EmployeeGeneral.ExternalReference' | translate }}"
                    [editMode]="companyTemplatesService.editMode"
                    [(isDirty)]="childIsDirty[17]"
                    (valueChange)="onChange()"
                    [(value)]="companyTemplatesService.templateDetail.TemplateExternalReference"
                  ></app-text-edit>

                  <app-combobox-edit
                    [editMode]="companyTemplatesService.editMode"
                    [label]="'EmployeeEmployment.SalaryCycle' | translate"
                    [comboboxDataSource]="staticDataService.SalaryCycle | async"
                    [(isDirty)]="childIsDirty[1]"
                    [(isValid)]="childIsValid[1]"
                    [(value)]="companyTemplatesService.templateDetail.SalaryCycleId"
                    (valueChange)="onChange()"
                    [textField]="'Name'"
                    [idField]="'Id'"
                  ></app-combobox-edit>

                  <app-module-check [moduleId]="4" [(hasModule)]="hasDepartmentModule">
                    <div
                      class="Control--wrapper IconAdd-container"
                      [ngClass]="companyTemplatesService.editMode && !IsDisableCreateDepartment ? 'controlFlex' : ''"
                    >
                      <app-combobox-edit
                        [ngClass]="{
                          customFlexComboAddNew: companyTemplatesService.editMode
                        }"
                        [editMode]="companyTemplatesService.editMode"
                        [label]="'EmployeeGeneral.Department' | translate"
                        [disable]="!hasDepartmentModule"
                        [nullValueLabel]="'DropdownList.None' | translate"
                        [(isDirty)]="childIsDirty[2]"
                        [(isValid)]="childIsValid[2]"
                        [comboboxDataSource]="staticDataService.departments | async"
                        [keepDataSourceOrder]="true"
                        [(value)]="companyTemplatesService.templateDetail.DepartmentId"
                        (valueChange)="onChange()"
                        [textField]="'Name'"
                        [idField]="'Id'"
                      ></app-combobox-edit>
                      <app-icon
                        *ngIf="companyTemplatesService.editMode && !IsDisableCreateDepartment"
                        [icon]="'circleplus'"
                        class="iconDepartment"
                        (click)="AddDepartmentVisible = true"
                      ></app-icon>
                    </div>
                  </app-module-check>

                  <app-text-edit
                    [label]="'EmployeeGeneral.Workplace' | translate"
                    *ngIf="isSwedenCompany"
                    [editMode]="companyTemplatesService.editMode"
                    [type]="'text'"
                    [(isValid)]="childIsValid[14]"
                    [(isDirty)]="childIsDirty[19]"
                    [(value)]="companyTemplatesService.templateDetail.WorkplaceIdentifier"
                    (valueChange)="onChange()"
                  >
                  </app-text-edit>

                  <app-text-edit
                    label="{{ 'EmploymentPayrollData.CollectiveAgreementCode' | translate }}"
                    [editMode]="companyTemplatesService.editMode"
                    [(isDirty)]="childIsDirty[3]"
                    [(isValid)]="childIsValid[3]"
                    [(value)]="companyTemplatesService.templateDetail.CollectiveBargainingAgreementCode"
                    (valueChange)="onChange()"
                  >
                  </app-text-edit>

                  <app-check-edit
                    [(isDirty)]="childIsDirty[4]"
                    [(isValid)]="childIsValid[4]"
                    label="{{ 'EmployeeEmployment.DefaultTemplate' | translate }}"
                    [(value)]="companyTemplatesService.templateDetail.IsDefault"
                    [editMode]="companyTemplatesService.editMode"
                    (valueChange)="onChange()"
                  ></app-check-edit>
                </fieldset>
              </div>
            </div>

            <div class="Card">
              <div class="Card-inner">
                <app-vacation
                  [(isDirty)]="childIsDirty[5]"
                  [(isValid)]="childIsValid[5]"
                  [editMode]="companyTemplatesService.editMode"
                  [(model)]="companyTemplatesService.templateDetail"
                  (valueChange)="onChange()"
                  [disable]="SecondaryIncomeType"
                  [disableBySecondaryIncomeType]="SecondaryIncomeType"
                ></app-vacation>
              </div>
            </div>

            <div class="Card" *ngIf="isShowExtraVacationHours">
              <div class="Card-inner">
                <app-additional-vacation
                  [disableBySecondaryIncomeType]="SecondaryIncomeType"
                  [(isDirty)]="childIsDirty[6]"
                  [(isValid)]="childIsValid[6]"
                  [editMode]="companyTemplatesService.editMode"
                  [model]="companyTemplatesService.templateDetail.TemplateBalanceConfigurations"
                  (valueChange)="onChange()"
                  (modelChange)="EmploymentBalanceConfigurationsChange($event, 7)"
                  [allowEdit]="sessionService.role.IsSalaryAdminAndHigher"
                  (parentChangeEditMode)="onEditClick(true)"
                  [NameOfEmplomentIdfeild]="'EmploymentTemplateId'"
                  [EmploymentId]="companyTemplatesService.templateDetail.Id"
                >
                </app-additional-vacation>
              </div>
            </div>

            <div class="Card" *ngIf="showMultiPurposeOffDayCompensation && !IsGreenlandCompany">
              <div class="Card-inner">
                <app-multi-purpose-off-day-compensation
                  [(isDirty)]="childIsDirty[7]"
                  [(isValid)]="childIsValid[7]"
                  [editMode]="companyTemplatesService.editMode"
                  [disable]="SecondaryIncomeType"
                  [disableBySecondaryIncomeType]="SecondaryIncomeType"
                  [(model)]="companyTemplatesService.templateDetail"
                  (valueChange)="onChange()"
                >
                </app-multi-purpose-off-day-compensation>
              </div>
            </div>

            <div *ngIf="!isSwedenCompany" class="Card">
              <div class="Card-inner">
                <app-multi-purpose-balance-compensation
                  [disableBySecondaryIncomeType]="SecondaryIncomeType"
                  [model]="companyTemplatesService.templateDetail.TemplateBalanceConfigurations"
                  [editMode]="companyTemplatesService.editMode"
                  [(isValid)]="childIsValid[13]"
                  [(isDirty)]="childIsDirty[18]"
                  (valueChange)="onChange()"
                  [EmploymentId]="companyTemplatesService.templateDetail.Id"
                  [NameOfEmplomentIdfeild]="'EmploymentTemplateId'"
                  [defaultId]="0"
                  (modelChange)="EmploymentBalanceConfigurationsChange($event, 3)"
                  [allowEdit]="sessionService.role.IsSalaryAdminAndHigher"
                  (parentChangeEditMode)="onEditClick(true)"
                >
                </app-multi-purpose-balance-compensation>
              </div>
            </div>

            <div class="Card" *ngIf="showStatisticsSection">
              <div class="Card-inner">
                <app-employment-statistics
                  [(isDirty)]="childIsDirty[8]"
                  [(isValid)]="childIsValid[8]"
                  [editMode]="companyTemplatesService.editMode"
                  [statistics]="companyTemplatesService.templateDetail"
                  (valueChange)="onChange()"
                >
                </app-employment-statistics>
              </div>
            </div>

            <div class="Card">
              <div class="Card-inner">
                <app-time-and-absence
                  [(isDirty)]="childIsDirty[9]"
                  [(isValid)]="childIsValid[9]"
                  [editMode]="companyTemplatesService.editMode"
                  [(model)]="companyTemplatesService.templateDetail"
                  (valueChange)="onChange()"
                ></app-time-and-absence>
              </div>
            </div>

            <div class="Card">
              <div class="Card-inner">
                <legend class="Card-title">{{ 'CompanyHiringStatus.PayrollAndSupplements' | translate }}</legend>
                <span class="Control--wrapper">
                  <div class="CompanyTemplate">
                    <app-button
                      id="PayrollDataButton"
                      class="Button--card"
                      label="{{ 'Company.PayrollData' | translate }}"
                      (clickEvent)="isPayrollGridVisible = true"
                    >
                    </app-button>

                    <app-button
                      id="SenioritySupplementsButton"
                      class="Button--card"
                      label="{{ 'Company.SenioritySupplements' | translate }}"
                      (clickEvent)="isSeniorityGridVisible = true"
                    >
                    </app-button>

                    <app-button
                      id="OvertimeSupplementsButton"
                      class="Button--card"
                      label="{{ 'Company.OvertimeSupplements' | translate }}"
                      (clickEvent)="isOvertimeGridVisible = true"
                    >
                    </app-button>
                  </div>
                </span>
              </div>
            </div>

            <div class="Card" *ngIf="!IsGreenlandCompany && !isSwedenCompany">
              <div class="Card-inner">
                <app-tax-e-income
                  [(isDirty)]="childIsDirty[10]"
                  [(isValid)]="childIsValid[10]"
                  [editMode]="companyTemplatesService.editMode"
                  [(model)]="companyTemplatesService.templateDetail"
                  (valueChange)="onChange()"
                ></app-tax-e-income>
              </div>
            </div>

            <div class="Card" *ngIf="IsGreenlandCompany">
              <div class="Card-inner">
                <app-greenland-tax
                  [(isDirty)]="childIsDirty[10]"
                  [(isValid)]="childIsValid[10]"
                  [editMode]="companyTemplatesService.editMode"
                  [(model)]="companyTemplatesService.templateDetail"
                  (valueChange)="onChange()"
                ></app-greenland-tax>
              </div>
            </div>

            <div
              class="Card"
              *ngIf="
                !SecondaryIncomeType &&
                !companyTemplatesService.editMode &&
                !has1stPensionProvider &&
                !has2ndPensionProvider &&
                !isSwedenCompany
              "
            >
              <div class="Card-inner">
                <fieldset class="Form--horizontal">
                  <legend class="Card-title">{{ 'EmployeeEmployment.Pension' | translate }}</legend>
                  <div class="FormElement-static">{{ 'EmployeeEmployment.NoPension' | translate }}</div>
                </fieldset>
              </div>
            </div>
            <div
              class="Card"
              *ngIf="
                (has1stPensionProvider ||
                  (companyTemplatesService.editMode && (!has1stPensionProvider || has2ndPensionProvider))) &&
                !isSwedenCompany
              "
            >
              <div class="Card-inner">
                <app-pension-provider
                  [(isDirty)]="childIsDirty[11]"
                  [(isValid)]="childIsValid[11]"
                  [(model)]="companyTemplatesService.templateDetail"
                  (valueChange)="onChange()"
                  [disable]="SecondaryIncomeType"
                  [disableBySecondaryIncomeType]="SecondaryIncomeType"
                  [firstPension]="true"
                  [editMode]="companyTemplatesService.editMode"
                >
                </app-pension-provider>
              </div>
            </div>
            <div
              class="Card"
              *ngIf="
                (has2ndPensionProvider || (companyTemplatesService.editMode && has1stPensionProvider)) &&
                !isSwedenCompany
              "
            >
              <div class="Card-inner">
                <app-pension-provider
                  [(isDirty)]="childIsDirty[12]"
                  [(isValid)]="childIsValid[12]"
                  [(model)]="companyTemplatesService.templateDetail"
                  (valueChange)="onChange()"
                  [disable]="SecondaryIncomeType"
                  [disableBySecondaryIncomeType]="SecondaryIncomeType"
                  [firstPension]="false"
                  [editMode]="companyTemplatesService.editMode"
                >
                </app-pension-provider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="Cards" *ngIf="!companyTemplatesService.isEnable">
    <div class="Card">
      <div class="Card-inner">
        <h2 class="Card-title">{{ 'Company.NoTemplate' | translate }}</h2>
        <app-button
          buttonClass="Button--primary"
          (clickEvent)="addNewDialogVisible = true"
          label="{{ 'Company.AddNewTemplate' | translate }}"
        ></app-button>
      </div>
    </div>
  </div>

  <div
    class="Actions"
    *ngIf="companyTemplatesService.isMobile && !companyTemplatesService.editMode"
    [ngClass]="companyTemplatesService.isIOSApp ? 'is-ios-app' : ''"
  >
    <app-menu-button
      [menuIcon]="'ThreeDots'"
      [menuLabel]="'More.Title'"
      [menuTooltip]="'More.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item (menuItemClick)="addNewDialogVisible = true" [field]="'CompanyAddNew'">
      </app-menu-button-item>
      <app-menu-button-item (menuItemClick)="onDeleteEmploymentTemplate()" [field]="'DeleteTemplate'">
      </app-menu-button-item>
      <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
      <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
    </app-menu-button>
    <app-menu-button
      [editButtonMenu]="true"
      (menuChangeClick)="onEditClick($event)"
      [menuCondition]="sessionService.role.IsFullAdmin || sessionService.role.IsSalaryAdmin"
      [editMode]="companyTemplatesService.editMode"
    >
    </app-menu-button>
  </div>

  <div
    class="Actions"
    *ngIf="!companyTemplatesService.isMobile && !companyTemplatesService.editMode"
    [ngClass]="companyTemplatesService.isIOSApp ? 'is-ios-app' : ''"
  >
    <app-module-check [moduleId]="12" [(hasModule)]="hasTemplateModule">
      <app-menu-button
        [menuCondition]="isAddIconVisible"
        (menuClick)="addNewDialogVisible = true"
        [menuIcon]="'Add'"
        [menuLabel]="'Company.New'"
        [menuTooltip]="'Company.New'"
        [actionButtonMenu]="true"
        [menuButtonClass]="'Action-button Action-buttonAdd'"
        [menuDisabled]="!hasTemplateModule"
        [menuButtonId]="'ActionButtonAdd'"
      >
      </app-menu-button>
    </app-module-check>
    <app-menu-button
      [menuIcon]="'ThreeDots'"
      [menuLabel]="'More.Title'"
      [menuTooltip]="'More.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item (menuItemClick)="onDeleteEmploymentTemplate()" [field]="'DeleteTemplate'">
      </app-menu-button-item>
    </app-menu-button>
    <app-menu-button
      [menuIcon]="'Help'"
      [menuLabel]="'Help.Title'"
      [menuTooltip]="'Help.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
      <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
    </app-menu-button>

    <app-menu-button
      *ngIf="reportDialogService.isVisibleReportIcon"
      [menuCondition]="true"
      (menuClick)="reportDialogService.onShowReportsEventClick()"
      [menuIcon]="'File'"
      [menuLabel]="'Company.Reports'"
      [menuTooltip]="'Company.Reports'"
      [actionButtonMenu]="true"
      [menuButtonClass]="'Action-button Action-buttonReports'"
      [menuButtonId]="'ActionButtonReports'"
    >
    </app-menu-button>

    <app-menu-button
      [editButtonMenu]="true"
      (menuChangeClick)="onEditClick($event)"
      [menuCondition]="sessionService.role.IsFullAdmin || sessionService.role.IsSalaryAdmin"
      [editMode]="companyTemplatesService.editMode"
    >
    </app-menu-button>
  </div>

  <div class="EditingInfo Actions" *ngIf="companyTemplatesService.editMode">
    <app-button
      [buttonClass]="'Discard'"
      (clickEvent)="onConfirmAndDiscardChanges()"
      [label]="'Employee.DiscardButton' | translate"
      *ngIf="companyTemplatesService.editMode"
    ></app-button>
    <app-menu-button
      [editButtonMenu]="true"
      [editMode]="true"
      (menuChangeClick)="onEditClick($event)"
      [menuCondition]="true"
      class="menu-button--editing"
    >
    </app-menu-button>
  </div>
</div>

<app-add-template-dialog
  *ngIf="addNewDialogVisible"
  [(visible)]="addNewDialogVisible"
  (addTemplateComplete)="onAddNewCompanyTemplateCompleted($event)"
></app-add-template-dialog>

<app-overtime-supplement-dialog
  *ngIf="companyTemplatesService.templateDetail"
  [(visible)]="isOvertimeGridVisible"
  [editMode]="companyTemplatesService.editMode"
  [(isDirty)]="childIsDirty[13]"
  (isDirtyChange)="onChange()"
  [templateId]="companyTemplatesService.templateDetail.Id"
  [(overtimeSupplement)]="companyTemplatesService.templateDetail.OvertimeSupplements"
></app-overtime-supplement-dialog>

<app-seniority-supplement-dialog
  *ngIf="companyTemplatesService.templateDetail"
  [(visible)]="isSeniorityGridVisible"
  [editMode]="companyTemplatesService.editMode"
  [templateId]="companyTemplatesService.templateDetail.Id"
  [(isDirty)]="childIsDirty[14]"
  (isDirtyChange)="onChange()"
  [seniority]="companyTemplatesService.templateDetail.SenioritySupplements"
>
</app-seniority-supplement-dialog>

<app-salary-template-dialog
  [(visible)]="isPayrollGridVisible"
  [editMode]="companyTemplatesService.editMode"
  [templateId]="companyTemplatesService.selectedTemplateId"
  [(isDirty)]="childIsDirty[15]"
  (isDirtyChange)="onChange()"
  [(updatedSalaryTemplate)]="companyTemplatesService.templateDetail.TemplateSalaryRecords"
  (salaryTemplateChange)="onChange()"
  [IncomeTypeId]="companyTemplatesService.templateDetail.IncomeTypeId"
>
</app-salary-template-dialog>

<app-dialog (action)="onDeleteConfirmationDialogAction($event)" [(visible)]="deleteConfirmationDialogVisible">
  <div innerHTML="{{ 'CompanyHiringStatus.DeleteTemplate' | translate }}"></div>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Delete" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onDiscardChangesDialogAction($event)"
  [(visible)]="discardChangesDialogVisible"
  [leadingText]="'Employee.DiscardMessage' | translate"
>
  <app-dialog-action type="CancelDiscard"></app-dialog-action>
  <app-dialog-action type="ConfirmDiscard"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onUpdateTemplateWarningAction($event)"
  [(visible)]="updateTemplateWarningDialogVisible"
  [leadingText]="'CompanyHiringStatus.UpdateTemplateWarning' | translate"
>
  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action type="Proceed"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onConfirmUpdateDialogAction($event)"
  [(visible)]="companyTemplatesService.confirmSaveOrUpdateDialogVisible"
  [leadingText]="'CompanyHiringStatus.TemplateConfirmUpdate' | translate"
>
  <app-dialog-action
    [requiredModuleId]="12"
    action="UpdateAllEmployees"
    [disabled]="sessionService.isSubmitting"
    label="{{ 'CompanyHiringStatus.UpdateAllEmployees' | translate }}"
    [class]="'Button--secondary'"
  >
  </app-dialog-action>
  <app-dialog-action
    action="UpdateTemplateOnly"
    [disabled]="sessionService.isSubmitting"
    label="{{ 'CompanyHiringStatus.UpdateTemplateOnly' | translate }}"
  ></app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" [(visible)]="showInvalidFormWarning">
  <div [innerHTML]="'Validation.InvalidForm' | translate"></div>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="companyTemplatesService.onConfirmSaveDataOnChangeState($event)"
  [(visible)]="companyTemplatesService.confirmUpdateDialogVisible"
>
  <div [innerHTML]="'Employee.UnsavedData' | translate"></div>
  <app-dialog-action type="DiscardAndLeave"></app-dialog-action>
  <app-dialog-action type="Stay"></app-dialog-action>
  <app-dialog-action type="Continue"></app-dialog-action>
</app-dialog>

<app-add-department
  [(isVisible)]="AddDepartmentVisible"
  [(userEmployment)]="companyTemplatesService.templateDetail"
  [companyContacts]="staticDataService.companyUsers | async"
></app-add-department>
