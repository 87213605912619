import { EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataService } from '../../../services/api/data.service';
import { SessionService } from '../../../services/session/session.service';
import { CompanySalaryBatchService } from '../company-salary-batches.service';
var LockSalaryBatchDialogComponent = /** @class */ (function () {
    function LockSalaryBatchDialogComponent(dataService, sessionService, companySalaryBatchService) {
        var _this = this;
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.companySalaryBatchService = companySalaryBatchService;
        this.visibleChange = new EventEmitter();
        this.batchLocked = new EventEmitter();
        this.notifyEmployee = true;
        this.ngUnsubscribe = new Subject();
        this.companySalaryBatchService.salaryBatchEntityContext
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && value.BatchId) {
                _this.salaryBatchId = value.BatchId;
            }
        });
    }
    Object.defineProperty(LockSalaryBatchDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (value !== this.visibleValue) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
                if (value) {
                    this.notifyEmployee = true;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    LockSalaryBatchDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    LockSalaryBatchDialogComponent.prototype.onDialogAction = function (action) {
        var _this = this;
        if (action === 'SubmitForApproval') {
            if (this.notifyEmployee) {
                this.dataService.SalaryBatches_ApproveSalaryBatchWithPreliminaryPayslips(this.salaryBatchId).subscribe(function () {
                    _this.batchLocked.emit();
                    _this.visible = false;
                });
            }
            else {
                this.dataService.SalaryBatches_ApproveSalaryBatch(this.salaryBatchId).subscribe(function () {
                    _this.batchLocked.emit();
                    _this.visible = false;
                });
            }
        }
    };
    return LockSalaryBatchDialogComponent;
}());
export { LockSalaryBatchDialogComponent };
