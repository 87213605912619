import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { EmployeeService } from 'src/app/services/employee.service';
import { FixedPanelService } from 'src/app/services/fixed-panel.service';
import { FormComponentBase } from '../../common/form-component-base';
import { ICompanyUser } from '../../services/api/api-model';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
var EmployeeInfoComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeInfoComponent, _super);
    function EmployeeInfoComponent(employeeService, staticDataService, sessionService, fixedPanelService) {
        var _this = _super.call(this) || this;
        _this.employeeService = employeeService;
        _this.staticDataService = staticDataService;
        _this.sessionService = sessionService;
        _this.fixedPanelService = fixedPanelService;
        _this.validated = new EventEmitter();
        _this.isEmployeeTab = false;
        _this.expandUserInfoDetails = false;
        _this.hasLanguageModule = false;
        _this.istoogledescription = false;
        _this.expandUserInfoEmitter = new EventEmitter();
        _this.EmploymentChangeEmitter = new EventEmitter();
        _this.childIsValid = Array(7).fill(true);
        _this.childIsDirty = Array(7).fill(false);
        return _this;
    }
    Object.defineProperty(EmployeeInfoComponent.prototype, "companyUser", {
        get: function () {
            if (!this.currentCompanyUser) {
                this.currentCompanyUser = {};
            }
            return this.currentCompanyUser;
        },
        set: function (value) {
            this.currentCompanyUser = value;
            this.childIsValid = Array(7).fill(true);
            this.childIsDirty = Array(7).fill(false);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeInfoComponent.prototype, "currentPositionId", {
        get: function () {
            return this.positionId;
        },
        set: function (value) {
            this.positionId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeInfoComponent.prototype, "allowEdit", {
        get: function () {
            return this.sessionService.role.IsSalaryAdminAndHigher;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeInfoComponent.prototype, "allowEditRole", {
        get: function () {
            return this.sessionService.role.IsFullAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeInfoComponent.prototype, "isGeneralTabSelfService", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.selfservice.general');
        },
        enumerable: true,
        configurable: true
    });
    EmployeeInfoComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(EmployeeInfoComponent.prototype, "userId", {
        get: function () {
            if (this.companyUser) {
                return this.companyUser.UserId;
            }
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeInfoComponent.prototype, "showIsPaymentApprover", {
        get: function () {
            return this.companyUser && this.companyUser.RoleId > 20 ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeInfoComponent.prototype, "EmployeeWithRoleAsManager", {
        get: function () {
            return this.sessionService.role.EmployeeWithRoleAsManager;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeInfoComponent.prototype.onToggleEmployeeInfoDropdown = function () {
        var _this = this;
        this.expandUserInfoDetails = !this.expandUserInfoDetails;
        this.expandUserInfoEmitter.emit(this.expandUserInfoDetails);
        this.istoogledescription = !this.istoogledescription;
        setTimeout(function () {
            _this.fixedPanelService.calculateHeightContent();
        }, 500);
    };
    EmployeeInfoComponent.prototype.onPaymentApproverClick = function () {
        this.childIsDirty[5] = true;
        this.childIsValid[5] = true;
    };
    EmployeeInfoComponent.prototype.onIsActiveClick = function () {
        this.childIsDirty[6] = true;
        this.childIsValid[6] = true;
    };
    EmployeeInfoComponent.prototype.onEmploymentChange = function () {
        this.EmploymentChangeEmitter.emit();
    };
    return EmployeeInfoComponent;
}(FormComponentBase));
export { EmployeeInfoComponent };
