import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ExtendSalaryPeriod } from '../../../../model/extend-salary-period';
import { DataService } from '../../../../services/api/data.service';
import { DialogService } from '../../../../services/dialog.service';
import { SessionService } from '../../../../services/session/session.service';
var PayrollPreviewComponent = /** @class */ (function () {
    function PayrollPreviewComponent(dataService, sessionService, dialog, translate) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.dialog = dialog;
        this.translate = translate;
        this.recalculatePreviewValue = false;
        this.recalculatePreviewChange = new EventEmitter();
        this.periodsNameChange = new EventEmitter();
        this.totalValueChange = new EventEmitter();
        this.closePreviewArea = new EventEmitter();
        this.isMobilePayrollDataSlide = false;
        this.isHidePreviewGrid = false;
        this.isWeb = isWeb;
        this.gridData = [];
        this.flagisDownloading = false;
        this.isShowError = false;
        this.isShowWarning = false;
        this.isShowValidationIssue = false;
        this.isError = false;
    }
    Object.defineProperty(PayrollPreviewComponent.prototype, "userEmployment", {
        get: function () {
            return this.userEmploymentValue;
        },
        set: function (value) {
            if (this.userEmploymentValue !== value) {
                this.userEmploymentValue = value;
                // reset grid state
                this.gridData = [];
                this.payslipIconsVisible = false;
                this.loadPreviewData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollPreviewComponent.prototype, "recalculatePreview", {
        get: function () {
            return this.recalculatePreviewValue;
        },
        set: function (value) {
            var _this = this;
            if (value) {
                this.loadSalaryStatementPreview();
                setTimeout(function () {
                    _this.recalculatePreviewValue = false;
                    _this.recalculatePreviewChange.emit(false);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollPreviewComponent.prototype, "periodsName", {
        get: function () {
            return this.currentperiodfriendlyName;
        },
        set: function (value) {
            this.currentperiodfriendlyName = value;
            this.periodsNameChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollPreviewComponent.prototype, "totalValue", {
        get: function () {
            return this.currentTotal;
        },
        set: function (value) {
            this.currentTotal = value;
            this.totalValueChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    PayrollPreviewComponent.prototype.onClosePreview = function () {
        this.closePreviewArea.emit();
    };
    Object.defineProperty(PayrollPreviewComponent.prototype, "classPayrollDataSlidePayrollDataSlide", {
        get: function () {
            if (this.isMobilePayrollDataSlide) {
                return 'classPayrollDataSlidePayrollDataSlide';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollPreviewComponent.prototype, "hideClass", {
        get: function () {
            if (this.isHidePreviewGrid) {
                return 'hidePreviewGrid';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollPreviewComponent.prototype, "isHidden", {
        get: function () {
            if (!this.sessionService.browser.isHybridApp) {
                return 'Icon--Payslip--hide';
            }
            else {
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollPreviewComponent.prototype, "IsAppOrDesktop", {
        get: function () {
            return !this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollPreviewComponent.prototype, "IsWeb", {
        get: function () {
            return !this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    PayrollPreviewComponent.prototype.onPeriodChanged = function () {
        this.loadSalaryStatementPreview();
    };
    Object.defineProperty(PayrollPreviewComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    PayrollPreviewComponent.prototype.downloadSalaryStatementsPreview = function (isPopup) {
        var _this = this;
        if (!this.flagisDownloading) {
            var outType = isPopup && this.isWeb ? 'a4phtmlstring' : 'htmlstring';
            this.obserDownloadData = this.dataService
                .SalaryStatements_GetHtmlPreview(outType, this.salaryStatement)
                .subscribe(function (data) {
                if (!isPopup) {
                    var newWindow = window.open('about:blank');
                    if (newWindow) {
                        newWindow.document.write(data);
                        newWindow.document.close();
                    }
                    else {
                        _this.dialog.showMessageBox(_this.translate.instant('Report.NewTabBlocked'), ['Ok']);
                    }
                }
                else {
                    _this.dialog.showIFrameDialog(data);
                }
                _this.flagisDownloading = false;
            }, function (error) {
                _this.flagisDownloading = false;
                console.log(error);
            });
            this.flagisDownloading = true;
        }
        else {
            if (this.isMobile) {
                this.flagisDownloading = false;
            }
        }
    };
    PayrollPreviewComponent.prototype.printSalaryStatementsPreview = function () {
        var _this = this;
        if (!this.flagisDownloading) {
            var format_1 = 'htmlstring';
            var salaryStatementId_1 = this.salaryStatement.Id;
            this.dataService.SalaryStatements_GetPayslipDownloadToken(salaryStatementId_1).subscribe(function (token) {
                var languageCode = _this.sessionService.LanguageCode;
                var downloadUrl = "/api/salarystatements/" + salaryStatementId_1 + "/token/" + token + "/lang/" + languageCode + "/format/" + format_1 + "/Payslip/DoPrint";
                window.open(environment.apiUrl + downloadUrl, '_blank');
                _this.flagisDownloading = false;
            }, function (error) {
                _this.flagisDownloading = false;
                console.log(error);
            });
            this.flagisDownloading = true;
        }
        else {
            if (this.isMobile) {
                this.flagisDownloading = false;
            }
        }
    };
    PayrollPreviewComponent.prototype.loadPreviewData = function () {
        if (!this.userEmployment || !this.userEmployment.Id || !this.userEmployment.SalaryCycleId) {
            return;
        }
        this.getPeriods();
    };
    PayrollPreviewComponent.prototype.getPeriods = function () {
        var _this = this;
        this.dataService
            .SalaryBatches_GetSalaryPeriods(this.userEmployment.SalaryCycleId)
            .subscribe(function (periods) {
            if (periods && periods[0].SalaryCycleId === _this.userEmployment.SalaryCycleId) {
                var dataSource_1 = [];
                var isUserFriendlyname_1 = _this.sessionService.getCompanyPreference('SalaryPeriods.FriendlyNames');
                periods.forEach(function (period, key) {
                    var startDate = _this.sessionService.toString(period.StartDate ? new Date(period.StartDate) : undefined);
                    var endDate = _this.sessionService.toString(period.EndDate ? new Date(period.EndDate) : undefined);
                    var item = new ExtendSalaryPeriod();
                    item.Id = period.Id;
                    item.StartDate = period.StartDate;
                    item.EndDate = period.EndDate;
                    item.Period = startDate + ' - ' + endDate;
                    item.FriendlyName =
                        isUserFriendlyname_1 && isUserFriendlyname_1.Value && isUserFriendlyname_1.Value === 'true'
                            ? ' ' + period.FriendlyName
                            : startDate + ' - ' + endDate;
                    dataSource_1[key] = item;
                });
                _this.periods = dataSource_1;
                _this.getSuggestPeriod();
            }
        });
    };
    PayrollPreviewComponent.prototype.getSuggestPeriod = function () {
        var _this = this;
        this.dataService
            .SalaryBatches_SuggestSalaryPeriod(this.userEmployment.SalaryCycleId)
            .subscribe(function (period) {
            if (!period) {
                if (_this.periods && _this.periods.length > 0) {
                    _this.periodId = _this.periods[0].Id;
                }
                return;
            }
            _this.periodId = period.Id;
            if (_this.periods &&
                _this.periods.length > 0 &&
                !_this.periods.find(function (item) { return item.Id === period.Id; })) {
                _this.periodId = _this.periods[0].Id;
            }
            if (_this.periods && _this.periods.length > 0) {
                var objectPeriods = _this.periods.find(function (model) { return model.Id === _this.periodId; });
                if (objectPeriods && objectPeriods.FriendlyName) {
                    _this.periodsName = objectPeriods.FriendlyName;
                }
            }
            _this.loadSalaryStatementPreview();
        });
    };
    PayrollPreviewComponent.prototype.loadSalaryStatementPreview = function () {
        var _this = this;
        if (this.userEmployment.Id && this.periodId) {
            if (this.currentuserEmploymentId !== this.userEmployment.Id ||
                this.currentuserperiodId !== this.periodId ||
                this.isUpdate) {
                this.currentuserEmploymentId = this.userEmployment.Id;
                this.currentuserperiodId = this.periodId;
            }
            else {
                return;
            }
            // reset grid state
            this.gridData = [];
            this.payslipIconsVisible = false;
            if (this.flagisDownloading) {
                if (this.obserDownloadData) {
                    this.obserDownloadData.unsubscribe();
                }
                if (this.dialog && this.dialog.isShowing) {
                    this.dialog.isShowing = false;
                }
                this.flagisDownloading = false;
            }
            this.dataService
                .SalaryStatements_GetSingleEmploymentPreviewByPeriod(this.userEmployment.Id, this.periodId)
                .subscribe(function (salaryStatement) {
                if (!salaryStatement) {
                    _this.gridData = [];
                    _this.payslipIconsVisible = false;
                    _this.totalValue = '0';
                    return;
                }
                _this.salaryStatement = salaryStatement;
                _this.isShowError = salaryStatement.SalaryBatch.HasErrors;
                _this.isShowWarning = salaryStatement.SalaryBatch.HasWarnings;
                _this.payslipIconsVisible =
                    salaryStatement.SalaryBatchRecords && salaryStatement.SalaryBatchRecords.length > 0;
                _this.gridData = salaryStatement.SalaryBatchRecords;
                if (_this.gridData && _this.gridData.length > 0) {
                    var objectTotal = _this.gridData.filter(function (model) { return model.SpecialIdentifier === 'PAYOUT'; });
                    if (objectTotal && objectTotal.length === 1 && objectTotal[0] && objectTotal[0].Amount) {
                        _this.totalValue = objectTotal[0].Amount.toString();
                    }
                }
            });
        }
    };
    PayrollPreviewComponent.prototype.showValidationIssues = function (isError) {
        this.listPayrollIssues = this.salaryStatement.SalaryBatch.SalaryBatchValidationIssues;
        this.listPayrollIssues.forEach(function (payroll) {
            payroll.icon = payroll.IsError ? 'ErrorExclamation' : 'warning';
        });
        this.isShowValidationIssue = true;
        this.isError = isError;
    };
    return PayrollPreviewComponent;
}());
export { PayrollPreviewComponent };
