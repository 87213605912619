import { EventEmitter, QueryList } from '@angular/core';
import { ReflectionHelper } from '../../common/reflection-helper';
// export class BaseGridColumn { }
var GridColumnComponent = /** @class */ (function () {
    function GridColumnComponent() {
        var _this = this;
        this.minResizableWidth = 40;
        this.visible = true;
        this.editable = true;
        this.isGroupColumn = false;
        this.isFilterable = false;
        this.tooltipFieldNonEdit = '';
        this.autoSize = true;
        this.hideColumn = false;
        this.tooltipTemplteClass = '';
        this.locked = false;
        this.percentageDecimals = true;
        this.autocompleteby1value = false;
        this.popupButtonArrayContentIcon = [];
        this.isPopupButtonIcon = false;
        this.disableGridButtonValue = false;
        this.disableGridButtonTooltip = '';
        this.dateFilter = new Date('');
        this.dateFilterOperator = 'eq';
        this.allowComboeditNull = false;
        this.isShowTextGridToolTip = false;
        this.isUseSubcolumTooltip = false;
        this.CurrentTextComboboxToolTip = true;
        this.CurrentTextComboboxToolTipFeild = '';
        this.addOnButtonEdit = false;
        this.addOnButtonEditTooltip = '';
        this.addOnButtonEditClass = '';
        this.addOnButtonEditClassFormControl = '';
        this.addOnButtonEditAction = '';
        this.addOnButtonEditDisabledFeild = '';
        this.addOnButtonEditIcon = '';
        this.addOnButtonEditLabel = '';
        this.addOnButtonEditIconEnable = false;
        this.AllowNull = false;
        this.addonClick = new EventEmitter();
        this.flatten = function (filter) {
            var filters = (filter || {}).filters;
            if (filters) {
                return filters.reduce(function (acc, curr) { return acc.concat(curr.filters ? _this.flatten(curr) : [curr]); }, []);
            }
            return [];
        };
    }
    Object.defineProperty(GridColumnComponent.prototype, "numbericOption", {
        get: function () {
            return this.numericOptionsValue;
        },
        set: function (value) {
            if (value && ReflectionHelper.isString(value)) {
                this.numericOptionsValue = {
                    format: value,
                    step: 0,
                    min: undefined,
                    spinners: false
                };
            }
            else {
                if (value && !value.step) {
                    value.step = 0;
                }
                if (value && !value.spinners) {
                    value.step = 0;
                    value.min = undefined;
                }
                this.numericOptionsValue = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    GridColumnComponent.prototype.categoryFilters = function (filter) {
        return this.flatten(filter).map(function (value) {
            return value;
        });
    };
    GridColumnComponent.prototype.onComboboxFilterChange = function (values, filterService) {
        var _this = this;
        filterService.filter({
            filters: values.map(function (value) { return ({
                field: _this.field,
                operator: 'eq',
                value: value
            }); }),
            logic: 'or'
        });
    };
    GridColumnComponent.prototype.onPercentageChange = function (value, filterService) {
        filterService.filter({
            filters: [{ field: this.field, operator: 'eq', value: value }],
            logic: 'or'
        });
    };
    GridColumnComponent.prototype.onFilterChange = function (value, filterService) {
        this.selectedValue = value;
        filterService.filter({
            filters: [{ field: this.field, operator: 'eq', value: value }],
            logic: 'or'
        });
    };
    return GridColumnComponent;
}());
export { GridColumnComponent };
