import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../../../common/form-component-base';
import { IOvertimeSupplement, IStaticDataEntity } from '../../../../services/api/api-model';
import { StaticDataService } from '../../../../services/api/static-data.service';
import { SessionService } from '../../../../services/session/session.service';

@Component({
  selector: 'app-overtime-supplement-dialog',
  templateUrl: './overtime-supplement-dialog.component.html'
})
export class OvertimeSupplementDialogComponent extends FormComponentBase implements OnInit {
  @Input() public templateId: number;

  @Input()
  public get overtimeSupplement(): IOvertimeSupplement[] {
    return this.overtimeSupplementValue;
  }
  public set overtimeSupplement(value: IOvertimeSupplement[]) {
    this.overtimeSupplementValue = value;
    this.overtimeSupplementChange.emit(value);
  }
  @Output() public overtimeSupplementChange: EventEmitter<IOvertimeSupplement[]> = new EventEmitter<
    IOvertimeSupplement[]
  >();

  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public intervalTypes: IStaticDataEntity[];
  public numericDecimalOptions: any = {
    format: 'n2',
    decimals: 2,
    step: 1,
    min: 0,
    spinners: false
  };

  public numericOptions: any = {
    format: 'n0',
    decimals: 0,
    step: 1,
    min: 0,
    spinners: false
  };

  private overtimeSupplementValue: IOvertimeSupplement[];
  private dirty = false;

  constructor(private staticDataService: StaticDataService, public sessionService: SessionService) {
    super();
  }

  public ngOnInit(): void {
    this.staticDataService.OvertimeSupplementPrinciple.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IStaticDataEntity[]) => (this.intervalTypes = data)
    );
  }

  protected hasChanges(): boolean {
    return this.dirty;
  }

  public addOvertimeHandler(dataItem: any): void {
    const defaultDataItem: any = this.createNewDataItem();
    Object.assign(dataItem, defaultDataItem);
    this.dirty = true;
    this.onChange();
  }

  public removeOvertimeHandler(dataItem: any): void {
    // if (dataItem && dataItem.Id) {
    //     this.dirty = true;
    //     this.onChange();
    // } else {
    //    let dataIndex: any = this.overtimeSupplement.indexOf(dataItem);
    //    this.overtimeSupplement.splice(dataIndex, 1);
    // }
    this.dirty = true;
    this.onChange();
  }

  public saveChanges(args: CellCloseEvent): void {
    ////let dataItem: any = args.dataItem ? args.dataItem : args;
    this.dirty = true;
    this.onChange();
  }

  private createNewDataItem(): any {
    return {
      Id: null,
      GridId: 0 - (this.overtimeSupplement.length + 1),
      EmploymentTemplateId: this.templateId,
      TotalHoursWorkedFrom: 0,
      TotalHoursWorkedUntil: 0,
      SupplementAmount: null,
      SupplementPercentage: 0,
      OvertimeSupplementPrinciple: this.intervalTypes ? this.intervalTypes[0].Id : null
    };
  }

  public selectedItem: IOvertimeSupplement;
  public refreshData = false;
  private keyPress: any = { 17: false, 18: false, 107: false, 109: false };

  @HostListener('document:keydown', ['$event'])
  onOverTimeKeyDown(event: any): void {
    if (this.visible) {
      if (this.editMode) {
        if (event.keyCode in this.keyPress) {
          this.keyPress[event.keyCode] = true;
          if (this.keyPress[17] && this.keyPress[18] && this.keyPress[109]) {
            if (this.selectedItem) {
              this.removeOvertimeHandler(this.selectedItem);
              this.setKeypress();
              this.refreshData = true;
              this.selectedItem = null;
            }
          }
        } else {
          this.setKeypress();
        }
      }
    }
  }

  private setKeypress(): void {
    this.keyPress[17] = false;
    this.keyPress[18] = false;
    this.keyPress[107] = false;
    this.keyPress[109] = false;
  }
}
