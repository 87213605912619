import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmployeeService } from 'src/app/services/employee.service';
import { environment } from 'src/environments/environment';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { Address, AddUserToCompanyRequest, CompanyUser, IAccount, ICity, IDepartment, IMunicipality, IRole, ISeTaxTable, ITaxCardType, IUserEmploymentTemplate } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
var NewEmployeeDialogComponent = /** @class */ (function () {
    function NewEmployeeDialogComponent(sessionService, staticDataService, employeeService, dataService) {
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.employeeService = employeeService;
        this.dataService = dataService;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.noCPRWarningDialogVisible = false;
        this.noEmailWarningDialogVisible = false;
        this.validationErrorDialogVisible = false;
        this.hasLanguageModule = false;
        this.createWithoutEmail = false;
        this.dateValid = true;
        this.municipality = [];
        this.hasDepartmentEnabled = false;
        this.accountName = '';
        this.taxCardTypes = [];
        this.isShowForEmploymentTemplateId = true;
        this.noCPRNumber = false;
        this.departments = [];
        this.templates = [];
        this.newEmployeeRequest = new AddUserToCompanyRequest();
        this.taxTableNumberDataSource = [];
        this.TaxColumnNumberOption = {
            min: 1,
            max: 6,
            spinners: true,
            step: 1,
            decimals: 0,
            format: 'n0'
        };
        this.roleDataSource = [];
        this.cities = [];
        this.defaultTitle = 'Medarbejder';
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(NewEmployeeDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.createNewEmployeeRequest();
                this.visibleValue = value;
                this.visibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewEmployeeDialogComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewEmployeeDialogComponent.prototype, "showAddToCompany", {
        get: function () {
            return this.sessionService.role.IsAccountAdmin && this.newEmployeeRequest.Details.RoleId >= 30;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewEmployeeDialogComponent.prototype, "employmentTemplateSelected", {
        get: function () {
            return this.newEmployeeRequest.EmploymentTemplateId && this.newEmployeeRequest.EmploymentTemplateId !== -1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewEmployeeDialogComponent.prototype, "isGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewEmployeeDialogComponent.prototype, "isSwedenCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewEmployeeDialogComponent.prototype, "isPrimanyTaxCard", {
        get: function () {
            return this.newEmployeeRequest.TaxCardTypeId === 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewEmployeeDialogComponent.prototype, "isFreeTaxCard", {
        get: function () {
            return this.newEmployeeRequest.TaxCardTypeId === 3;
        },
        enumerable: true,
        configurable: true
    });
    NewEmployeeDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.TaxCardType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.taxCardTypes = data); });
        if (this.isSwedenCompany) {
            this.defaultTitle = 'Metarbeter';
        }
        this.staticDataService.Role.subscribe(function (data) {
            _this.roleDataSource = JSON.parse(JSON.stringify(data));
            if (_this.sessionService.role.IsSalaryAdmin) {
                if (_this.roleDataSource && _this.roleDataSource.length > 0) {
                    var fullAdminOption = _this.roleDataSource.find(function (model) { return model.Key === 'FullAdmin'; });
                    if (fullAdminOption) {
                        _this.roleDataSource.splice(_this.roleDataSource.indexOf(fullAdminOption), 1);
                    }
                }
            }
        });
        this.staticDataService.City.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.cities = data); });
        this.staticDataService.departments
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) { return (_this.departments = data); });
        this.staticDataService.Municipality.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (municipalities) {
            return (_this.municipality = municipalities.filter(function (m) { return Global.SESSION && m.CountryId === Global.SESSION.CurrentCountryId; }));
        });
        if (this.sessionService.role.IsAccountAdmin) {
            this.staticDataService.currentAccount.subscribe(function (account) { return (_this.accountName = account.Name); });
        }
        this.createNewEmployeeRequest();
        if (this.isSwedenCompany) {
            this.dataService.StaticData_GetSeTaxTables().subscribe(function (data) {
                if (data && data.length > 0) {
                    _this.taxTableNumberDataSource = data;
                    _this.newEmployeeRequest.TaxTableNumber = data[0].Id;
                }
            });
        }
    };
    NewEmployeeDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    NewEmployeeDialogComponent.prototype.onDepartmentComboBoxChanged = function () {
        var _this = this;
        if (!this.newEmployeeRequest.DepartmentId) {
            this.newEmployeeRequest.ManagerCompanyUserId = undefined;
            return;
        }
        var department = this.departments.find(function (d) { return d.Id === _this.newEmployeeRequest.DepartmentId; });
        if (department) {
            this.newEmployeeRequest.ManagerCompanyUserId = department.ManagerCompanyUserId;
        }
    };
    NewEmployeeDialogComponent.prototype.onPostalCodeChanged = function () {
        var _this = this;
        var postalCode = this.newEmployeeRequest.Details.Address.PostalCode;
        if (!postalCode) {
            return;
        }
        var city = this.cities.find(function (c) {
            if (c.CountryId === _this.newEmployeeRequest.Details.Address.CountryId && c.PostalCode) {
                var numericCode = parseInt(postalCode, 10);
                if (!isNaN(numericCode)) {
                    if (c.IsRange && c.PostalCodeTo) {
                        if (numericCode >= c.PostalCode && numericCode <= c.PostalCodeTo) {
                            return true;
                        }
                    }
                    else {
                        if (numericCode === c.PostalCode) {
                            return true;
                        }
                    }
                }
            }
            return false;
        });
        if (!city) {
            city = this.cities.find(function (c) { return c.PostalCode && postalCode === c.PostalCode.toString(); });
        }
        if (city) {
            this.newEmployeeRequest.Details.Address.City = city.Key;
            this.autoFillMunicipality(city.Key);
            if (city.CountryId !== this.newEmployeeRequest.Details.Address.CountryId) {
                this.newEmployeeRequest.Details.Address.CountryId = city.CountryId;
                this.setDefaultApplyDanishRulesInGreenland();
            }
        }
    };
    NewEmployeeDialogComponent.prototype.onCityBlurChange = function (value) {
        this.autoFillMunicipality(this.newEmployeeRequest.Details.Address.City);
    };
    NewEmployeeDialogComponent.prototype.onMunicipalityChanged = function () {
        this.updateDefaultTaxRate();
    };
    NewEmployeeDialogComponent.prototype.TaxColumnNumberChange = function (value) {
        var _this = this;
        setTimeout(function () {
            if (value || value === 0) {
                if (value < 1) {
                    _this.newEmployeeRequest.TaxColumnNumber = 1;
                }
                if (value > 6) {
                    _this.newEmployeeRequest.TaxColumnNumber = 6;
                }
            }
        });
    };
    NewEmployeeDialogComponent.prototype.TaxColumnNumberBlur = function () {
        if (this.newEmployeeRequest.TaxColumnNumber === null || this.newEmployeeRequest.TaxColumnNumber === undefined) {
            this.newEmployeeRequest.TaxColumnNumber = 1;
        }
    };
    NewEmployeeDialogComponent.prototype.onCountryChange = function () {
        this.setDefaultApplyDanishRulesInGreenland();
        var country = this.newEmployeeRequest.Details.Address.CountryId;
        if (country !== Constants.DENMARK_COUNTRY_ID && country !== Constants.GREENLAND_COUNTRY_ID) {
            this.newEmployeeRequest.Details.MunicipalityId = 8;
            this.updateDefaultTaxRate();
        }
    };
    NewEmployeeDialogComponent.prototype.onEmploymentTemplateChanged = function () {
        if (this.newEmployeeRequest.EmploymentTemplateId) {
            this.isShowForEmploymentTemplateId = true;
        }
        else {
            this.isShowForEmploymentTemplateId = false;
        }
        this.updateDefaultTaxRate();
    };
    NewEmployeeDialogComponent.prototype.onNoCPRCheckboxClicked = function () {
        // if (this.noCPRNumber && !this.hasGender) {
        if (this.noCPRNumber) {
            this.noCPRWarningDialogVisible = true;
            this.newEmployeeRequest.IdentityNumber = '';
            this.newEmployeeRequest.EmploymentTemplateId = undefined;
        }
        else {
            this.setDefaultTemplate();
        }
    };
    NewEmployeeDialogComponent.prototype.closeForm = function (action) {
        switch (action) {
            case 'Close':
                this.visible = false;
                break;
            case 'CreateWithoutEmails':
                this.createNew();
                break;
            case 'Create':
                if (!this.newEmployeeRequest.Details.PersonalEmail && !this.newEmployeeRequest.Details.CompanyEmail) {
                    this.noEmailWarningDialogVisible = true;
                }
                else {
                    this.createNew();
                }
                break;
            default:
                break;
        }
    };
    NewEmployeeDialogComponent.prototype.autoFillMunicipality = function (cityName) {
        if (!cityName || !this.isGreenlandCompany) {
            return;
        }
        var city = this.cities.find(function (filterCity) { return filterCity.Key && cityName.toLowerCase() === filterCity.Key.toString().toLowerCase(); });
        if (city && city.MunicipalityId && this.newEmployeeRequest.Details.MunicipalityId !== city.MunicipalityId) {
            this.newEmployeeRequest.Details.MunicipalityId = city.MunicipalityId;
            this.updateDefaultTaxRate();
        }
    };
    NewEmployeeDialogComponent.prototype.updateDefaultTaxRate = function () {
        var _this = this;
        if (this.isGreenlandCompany || this.employmentTemplateSelected || this.newEmployeeRequest.Details.MunicipalityId) {
            var municipality = this.municipality.find(function (m) { return m.Id === _this.newEmployeeRequest.Details.MunicipalityId; });
            if (municipality) {
                this.newEmployeeRequest.TaxRate = municipality.DefaultTaxRate;
            }
        }
    };
    NewEmployeeDialogComponent.prototype.createNewEmployeeRequest = function () {
        var _this = this;
        this.newEmployeeRequest = new AddUserToCompanyRequest();
        this.newEmployeeRequest.Details = new CompanyUser();
        this.newEmployeeRequest.Details.Address = new Address();
        this.newEmployeeRequest.Details.CompanyId = Global.COMPANY_ID;
        this.newEmployeeRequest.Details.IsActive = true;
        this.newEmployeeRequest.Details.RoleId = 20;
        this.newEmployeeRequest.Title = this.defaultTitle;
        this.newEmployeeRequest.HireDate = this.staticDataService.getCurrentdate();
        this.newEmployeeRequest.Details.Address.CountryId =
            Global.COMPANY && Global.COMPANY.CountryId ? Global.COMPANY.CountryId : Constants.DENMARK_COUNTRY_ID;
        this.newEmployeeRequest.LanguageId = Global.COMPANY ? Global.COMPANY.DefaultLanguageId : 1;
        this.newEmployeeRequest.TaxCardTypeId = this.taxCardTypes.length > 1 ? this.taxCardTypes[0].Id : undefined;
        this.newEmployeeRequest.TaxColumnNumber = 1;
        this.newEmployeeRequest.ExpireDate = undefined;
        this.createWithoutEmail = false;
        this.noCPRNumber = false;
        if (this.taxTableNumberDataSource && this.taxTableNumberDataSource.length > 0) {
            this.newEmployeeRequest.TaxTableNumber = this.taxTableNumberDataSource[0].Id;
        }
        this.setDefaultApplyDanishRulesInGreenland();
        if (this.sessionService.feature.ShowEmployeeEmploymentTemplate) {
            this.dataService
                .EmploymentTemplates_GetEmploymentTemplates()
                .subscribe(function (templates) {
                _this.templates = templates;
                _this.setDefaultTemplate();
            });
        }
    };
    NewEmployeeDialogComponent.prototype.setDefaultApplyDanishRulesInGreenland = function () {
        if (this.isGreenlandCompany) {
            this.newEmployeeRequest.ApplyDanishRulesInGreenland =
                this.newEmployeeRequest.Details.Address.CountryId === Constants.DENMARK_COUNTRY_ID;
        }
    };
    NewEmployeeDialogComponent.prototype.setDefaultTemplate = function () {
        if (this.templates && this.templates.length > 0) {
            if (this.templates.length > 1) {
                this.newEmployeeRequest.EmploymentTemplateId = this.templates[1].Id;
            }
            else {
                this.newEmployeeRequest.EmploymentTemplateId = this.templates[0].Id;
            }
        }
        else {
            this.newEmployeeRequest.EmploymentTemplateId = undefined;
        }
    };
    NewEmployeeDialogComponent.prototype.createNew = function () {
        var _this = this;
        if (!this.validateInput()) {
            return;
        }
        this.newEmployeeRequest.Details.IsActive = true;
        this.newEmployeeRequest.Details.CompanyId = Global.COMPANY_ID;
        if (!this.newEmployeeRequest.EmploymentTemplateId) {
            this.newEmployeeRequest.EmploymentTemplateId = undefined;
            this.newEmployeeRequest.Title = undefined;
            this.newEmployeeRequest.DepartmentId = undefined;
            this.newEmployeeRequest.TaxCardTypeId = undefined;
            this.newEmployeeRequest.ManagerCompanyUserId = undefined;
        }
        this.employeeService.createEmployee(this.newEmployeeRequest).subscribe(function () { return (_this.visible = false); });
    };
    NewEmployeeDialogComponent.prototype.validateInput = function () {
        if (this.employmentTemplateSelected && !this.dateValid) {
            return false;
        }
        this.validationMessageKey = this.getErrorMessage();
        if (this.validationMessageKey) {
            this.validationErrorDialogVisible = true;
            return false;
        }
        return true;
    };
    NewEmployeeDialogComponent.prototype.getErrorMessage = function () {
        if (!this.newEmployeeRequest.Details.FirstName ||
            !this.newEmployeeRequest.Details.LastName ||
            (!this.noCPRNumber && !this.newEmployeeRequest.IdentityNumber) ||
            (!this.newEmployeeRequest.Details.MunicipalityId && this.isGreenlandCompany && this.employmentTemplateSelected)) {
            return 'NewEmployee.RequiredErrorMessage';
        }
        if (!this.newEmployeeRequest.EmploymentTemplateId) {
            return '';
        }
        if (!this.newEmployeeRequest.Title) {
            return 'NewEmployee.TitleErrorMessage';
        }
        if (!this.newEmployeeRequest.HireDate) {
            return 'NewEmployee.HiringErrorMessage';
        }
        return '';
    };
    return NewEmployeeDialogComponent;
}());
export { NewEmployeeDialogComponent };
