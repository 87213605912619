import { Injectable, OnDestroy } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { Global } from '../../common/global';
import { HolidayRequest, ICompany, ICompanyHolidayView, ICompanyUser } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { CompanyService } from '../../services/company.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyGeneralService extends CompanyService implements OnDestroy {
  private companySubject: BehaviorSubject<ICompany> = new BehaviorSubject<ICompany>(null);
  public get company(): Observable<ICompany> {
    if (!this.companySubject) {
      this.companySubject.next(Global.COMPANY);
    }
    return this.companySubject.asObservable();
  }

  private companyContactSubject: BehaviorSubject<ICompanyUser[]>;
  public get companyContact(): Observable<ICompanyUser[]> {
    if (!this.companyContactSubject) {
      this.loadCompanyContact();
    }
    return this.companyContactSubject.asObservable();
  }

  private companyLogoSubject: BehaviorSubject<string>;
  public get companyLogo(): Observable<string> {
    if (!this.companyLogoSubject) {
      this.loadCompanyLogo();
    }

    return this.companyLogoSubject.asObservable();
  }

  private companyHolidayViewSubject: BehaviorSubject<ICompanyHolidayView[]>;
  public get companyHolidayView(): Observable<ICompanyHolidayView[]> {
    if (!this.companyHolidayViewSubject) {
      this.loadCompanyHolidayView();
    }

    return this.companyHolidayViewSubject.asObservable();
  }

  private loadCompanyHolidayView() {
    if (!this.companyHolidayViewSubject) {
      this.companyHolidayViewSubject = new BehaviorSubject<ICompanyHolidayView[]>([]);
    }

    this.dataService.Holidays_GetHolidays().subscribe((data: ICompanyHolidayView[]) => {
      this.companyHolidayViewSubject.next(data);
    });
  }

  public createHoliday(holidayRequest: HolidayRequest, reload: boolean) {
    this.dataService.Holidays_CreateHoliday(holidayRequest).subscribe(
      () => {},
      () => {},
      () => {
        if (reload) {
          this.loadCompanyHolidayView();
        }
      }
    );
  }

  public deleteHoliday(holidayRequest: HolidayRequest, MasterHolidayId: number) {
    const year = new Date(holidayRequest.Date).getFullYear().toString();
    const month =
      new Date(holidayRequest.Date).getMonth() + 1 < 10
        ? '0' + (new Date(holidayRequest.Date).getMonth() + 1).toString()
        : (new Date(holidayRequest.Date).getMonth() + 1).toString();
    const date =
      new Date(holidayRequest.Date).getDate() < 10
        ? '0' + new Date(holidayRequest.Date).getDate().toString()
        : new Date(holidayRequest.Date).getDate().toString();

    const dateDelete = year + month + date;

    this.dataService.Holidays_DeleteHolidayByDate(dateDelete).subscribe(
      () => {},
      () => {},
      () => {
        if (!MasterHolidayId) {
          this.loadCompanyHolidayView();
        }
      }
    );
  }

  public invalidDialogVisible = false;
  public currentCompany: ICompany;
  public isVisibaleHolidayDialog = false;
  public isVisibleAddNewHolidayDialog = false;

  constructor(
    protected dataService: DataService,
    protected staticDataService: StaticDataService,
    protected settingService: SettingService,
    protected sessionService: SessionService,
    protected transitionService: TransitionService,
    protected broadcaster: BroadcastService
  ) {
    super(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster);
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  protected allowChangeEdit(value: boolean): boolean {
    if (!value && this.tabDirty && !this.tabValid) {
      return false;
    }
    return true;
  }

  protected onContinueAction(): void {
    this.onSave();
  }

  protected onDiscardAndLeaveAction(): void {
    this.loadCompany().subscribe(() => {
      this.tabDirty = false;
      this.tabValid = true;

      this.editMode = false;
      this.moveToNextState();
    });
  }

  public loadCompany() {
    return this.dataService.Companies_GetCurrent().pipe(
      tap((data: ICompany) => {
        if (data) {
          this.companySubject.next(data);
          Global.COMPANY = data;
        }
      })
    );
  }

  public loadCompanyContact() {
    if (!this.companyContactSubject) {
      this.companyContactSubject = new BehaviorSubject<ICompanyUser[]>([]);
    }
    this.staticDataService.companyUsers.subscribe((companyContact: ICompanyUser[]): void => {
      this.companyContactSubject.next(companyContact);
    });
  }

  public loadCompanyLogo() {
    if (!this.companyLogoSubject) {
      this.companyLogoSubject = new BehaviorSubject<string>(null);
    }

    this.staticDataService.currentLogo.subscribe((logo: string): void => {
      this.companyLogoSubject.next(logo);
    });
  }

  public onSave() {
    if (this.currentCompany && this.tabDirty) {
      if (this.currentCompany.StatisticsRecipientId === undefined) {
        this.currentCompany.StatisticsRecipientId = null;
      }
      if (this.currentCompany.IndustryOrganizationId === undefined) {
        this.currentCompany.IndustryOrganizationId = null;
      }

      this.currentCompany.BankAccountNo = this.currentCompany.BankAccountNo || null;

      if (this.tabValid) {
        this.tabDirty = false;
        this.dataService.Companies_UpdateCurrent(this.currentCompany).subscribe(
          (responseCompany: ICompany): void => {
            this.companySubject.next(responseCompany);
            Global.COMPANY = responseCompany;

            this.moveToNextState();
          },
          (error: any): void => {
            this.loadCompany().subscribe(() => {});
          }
        );
      } else {
        this.invalidDialogVisible = true;
        this.loadCompany().subscribe(() => {});
      }
    }
  }

  protected get allowShortcut(): boolean {
    return this.sessionService.currentState === 'tabs.company.general';
  }
}
