import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../common/constants';
import { Global } from '../../../common/global';
import { SalaryBatchStatusEnum } from '../../../model/enum';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { SessionService } from '../../../services/session/session.service';
import { CompanySalaryBatchesChildFormEntityContext } from '../company-salary-batches-child-form-entity-context';
import { CompanySalaryBatchService } from '../company-salary-batches.service';
import { SalaryBatchViewModel } from '../salary-batch-view-model';
var SalaryBatchInfoComponent = /** @class */ (function () {
    function SalaryBatchInfoComponent(dataService, staticDataService, sessionService, companySalaryBatchService) {
        var _this = this;
        this.dataService = dataService;
        this.staticDataService = staticDataService;
        this.sessionService = sessionService;
        this.companySalaryBatchService = companySalaryBatchService;
        this.createPayrollBatch = new EventEmitter();
        this.salaryBatchAction = new EventEmitter();
        this.reloadSalaryBatches = new EventEmitter();
        this.closeDialog = new EventEmitter();
        this.reloadEntityContextChange = new EventEmitter();
        this.visibleChange = new EventEmitter();
        this.selectedEmployeeIdsChange = new EventEmitter();
        this.reloadEntityContextValue = false;
        this.userEmploymentView = [];
        this.isPaymentApprover = Global.SESSION.IsPaymentApprover;
        this.ngUnsubscribe = new Subject();
        this.departmentSource = [];
        this.templateSource = [];
        this.selectDepartmentFilter = [];
        this.selectTemplateFilter = [];
        this.multiSelectDepartmentFilter = [];
        this.multiSelectTemplateFilter = [];
        // grid pick employee
        this.defaultEmployeesGridData = [];
        this.selectEmployeesGridData = [];
        this.filterEmployeesGridData = [];
        this.triggerUpdate = false;
        this.companySalaryBatchService.salaryBatchEntityContext
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            _this.salaryBatchContext = value;
        });
        this.staticDataService.userEmployments
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            _this.userEmploymentView = value;
        });
    }
    Object.defineProperty(SalaryBatchInfoComponent.prototype, "salaryBatch", {
        get: function () {
            return this.salaryBatchValue;
        },
        set: function (value) {
            if (this.salaryBatchValue !== value) {
                this.salaryBatchValue = value;
            }
            else if (!this.popup && !value) {
                this.salaryBatchValue = value;
            }
            this.prepareEntityContext(this.salaryBatch);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchInfoComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                if (value) {
                    this.selectDepartmentFilter = [];
                    this.selectTemplateFilter = [];
                    this.multiSelectDepartmentFilter = [];
                    this.multiSelectTemplateFilter = [];
                }
                this.visibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchInfoComponent.prototype, "selectedEmployeeIds", {
        get: function () {
            return this.selectedEmployeeIdsValue;
        },
        set: function (value) {
            if (this.selectedEmployeeIdsValue !== value) {
                this.selectedEmployeeIdsValue = value;
                this.selectedEmployeeIdsChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchInfoComponent.prototype, "reloadEntityContext", {
        get: function () {
            return this.reloadEntityContextValue;
        },
        set: function (value) {
            if (value !== this.reloadEntityContextValue) {
                this.reloadEntityContextValue = value;
                this.reloadEntityContextChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.departments.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (departments) {
            _this.departmentSource = departments.map(function (d) { return ({ Id: d.Id, Name: d.Name }); });
        });
        this.dataService
            .EmploymentTemplates_GetEmploymentTemplates(false)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (templates) {
            _this.templateSource = templates;
        });
    };
    SalaryBatchInfoComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(SalaryBatchInfoComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchInfoComponent.prototype.onCreatePayrollBatchClicked = function () {
        this.createPayrollBatch.emit();
    };
    Object.defineProperty(SalaryBatchInfoComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchInfoComponent.prototype.onDetailContextChange = function (value) {
        this.companySalaryBatchService.setNextValueSalaryBatchEntityContext(value);
    };
    Object.defineProperty(SalaryBatchInfoComponent.prototype, "isGreenland", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchInfoComponent.prototype.onSalaryBatchDialogAction = function (action) {
        this.salaryBatchAction.emit({ action: action, context: this.salaryBatchContext });
    };
    Object.defineProperty(SalaryBatchInfoComponent.prototype, "entityContext", {
        get: function () {
            return this.entityContextValue ? this.entityContextValue : {};
        },
        set: function (value) {
            if (this.entityContextValue !== value) {
                this.entityContextValue = value;
                this.entityContext.Employees =
                    this.userEmploymentView && this.userEmploymentView.length > 0
                        ? JSON.parse(JSON.stringify(this.userEmploymentView))
                        : [];
            }
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchInfoComponent.prototype.prepareEntityContext = function (batch) {
        if (batch && Array.isArray(batch) === false) {
            var updateContext = new CompanySalaryBatchesChildFormEntityContext(this.sessionService);
            updateContext.BatchId = batch.Id;
            updateContext.StatusName = batch.StatusName;
            updateContext.ChangeDate = batch.Rejected
                ? batch.Rejected
                : batch.Finalized
                    ? batch.Finalized
                    : batch.Approved
                        ? batch.Approved
                        : batch.Created;
            updateContext.SalaryBatchNumber = batch.SalaryBatchNumber;
            updateContext.PeriodId = batch.SalaryPeriodId;
            updateContext.IsDraft = batch.StatusId === SalaryBatchStatusEnum.Draft;
            updateContext.IsPendingForApproval = batch.StatusId === SalaryBatchStatusEnum.ForApproval;
            updateContext.isFinalizing = batch.StatusId === SalaryBatchStatusEnum.Finalizing;
            updateContext.IsFinalized = batch.StatusId === SalaryBatchStatusEnum.Finalized;
            updateContext.IsCancelled = batch.StatusId === SalaryBatchStatusEnum.Cancelled;
            updateContext.IsLocked = batch.StatusId === SalaryBatchStatusEnum.ForApproval;
            updateContext.IsPreliminary = batch.StatusId === SalaryBatchStatusEnum.ForApproval;
            updateContext.PaymentDate = new Date(batch.PayoutDate.toString());
            updateContext.PayoutAllFlex = batch.PayoutAllFlex;
            updateContext.PayoutNewFlex = batch.PayoutNewFlex;
            updateContext.IsRecalcBeforeFinalize = !(updateContext.IsLocked || updateContext.IsPreliminary);
            updateContext.Message = batch.Message;
            updateContext.HasErrors = batch.HasErrors;
            updateContext.HasWarnings = batch.HasWarnings;
            updateContext.CycleId = batch.SalaryCycleId;
            updateContext.EmployeeCategory = 1;
            updateContext.HasBatch = false;
            updateContext.FromDateOverride = batch.FromDateOverride;
            updateContext.ToDateOverride = batch.ToDateOverride;
            this.entityContext = updateContext;
            this.onEmployeeCategoryChanged();
        }
        else {
            if ( /*!this.salaryBatchesContext || */!this.salaryCycles || this.salaryCycles.length === 0) {
                return;
            }
            var newContext = new CompanySalaryBatchesChildFormEntityContext(this.sessionService);
            newContext.BatchId = -1;
            newContext.StatusName = undefined;
            newContext.ChangeDate = undefined;
            newContext.IsDraft = true;
            newContext.CycleId = this.salaryCycles[0].Id;
            newContext.PeriodId = undefined;
            newContext.EmployeeCategory = 1;
            newContext.PayoutAllFlex = false;
            newContext.HasBatch = false;
            this.entityContext = newContext;
            this.entityContext.Employees =
                this.userEmploymentView && this.userEmploymentView.length > 0
                    ? JSON.parse(JSON.stringify(this.userEmploymentView))
                    : [];
            this.setSalaryBatchEmployeeGridDataSource();
            this.onEmployeeCategoryChanged();
        }
    };
    SalaryBatchInfoComponent.prototype.onEmployeeCategoryChanged = function (category) {
        if (this.IsNewBatchWithEmployeeSelection) {
            this.showPickEmployeeDialog();
        }
        this.onEmployeesGridDataSaveChanges();
    };
    SalaryBatchInfoComponent.prototype.showPickEmployeeDialog = function () {
        this.companySalaryBatchService.pickEmployeeDialogVisible = true;
        this.filterEmployeesGrid();
    };
    Object.defineProperty(SalaryBatchInfoComponent.prototype, "DisbaleDepartmentFilter", {
        get: function () {
            return !(this.departmentSource && this.departmentSource.length > 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchInfoComponent.prototype, "DisbaleTemplateFilter", {
        get: function () {
            return !(this.templateSource && this.templateSource.length > 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchInfoComponent.prototype, "IsNewBatchWithEmployeeSelection", {
        get: function () {
            return this.entityContext.BatchId === -1 && this.entityContext.EmployeeCategory === 2;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchInfoComponent.prototype, "nonemployeePicked", {
        get: function () {
            return this.selectEmployeesGridData.every(function (model) { return !model.IsChecked; });
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchInfoComponent.prototype.onPickEmloyeeAction = function (event) {
        if (event && event === 'Ok') {
            this.defaultEmployeesGridData = this.selectEmployeesGridData;
            if (this.entityContext.IsCheckedAllEmployees && this.filterEmployeesGridData.length === 0) {
                this.selectedEmployeeIds = null;
            }
        }
        else {
            this.resetData();
        }
    };
    SalaryBatchInfoComponent.prototype.filterEmployeesGrid = function () {
        var _this = this;
        this.filterEmployeesGridData = this.selectEmployeesGridData;
        if (this.selectDepartmentFilter && this.selectDepartmentFilter.length > 0) {
            this.filterEmployeesGridData = this.filterEmployeesGridData.filter(function (model) {
                return _this.selectDepartmentFilter.some(function (item) { return model.DepartmentId === item; });
            });
        }
        if (this.selectTemplateFilter && this.selectTemplateFilter.length > 0) {
            this.filterEmployeesGridData = this.filterEmployeesGridData.filter(function (model) {
                return _this.selectTemplateFilter.some(function (item) { return model.BasedOnTemplateId === item; });
            });
        }
        this.selectedEmployeeIds = this.filterEmployeesGridData
            .filter(function (e) { return e.IsChecked; })
            .map(function (e) { return e.UserEmploymentId; });
        this.onEmployeesGridDataSaveChanges();
    };
    SalaryBatchInfoComponent.prototype.onEmployeeGridAction = function (action) {
        if (action === 'SelectAll') {
            this.onSelectAllEmployee(true);
        }
        else {
            this.onSelectAllEmployee(false);
        }
        this.filterEmployeesGrid();
        this.onEmployeesGridDataSaveChanges();
        this.triggerUpdate = true;
    };
    SalaryBatchInfoComponent.prototype.onEmployeesGridDataSaveChanges = function (event) {
        switch (this.entityContext.EmployeeCategory) {
            case 1: // All employeess
                this.selectedEmployeeIds = null;
                break;
            case 3: // No employees
                this.selectedEmployeeIds = [];
                break;
            case 2:
                // Selected employees
                if (event && event.dataItem) {
                    this.selectEmployeesGridData.find(function (model) { return model.UserEmploymentId === event.dataItem.UserEmploymentId; }).IsChecked = event.dataItem.IsChecked;
                }
                this.selectedEmployeeIds = this.filterEmployeesGridData
                    .filter(function (e) { return e.IsChecked; })
                    .map(function (e) { return e.UserEmploymentId; });
                this.triggerUpdate = true;
                this.entityContext.IsCheckedAllEmployees = this.selectEmployeesGridData.every(function (model) { return model.IsChecked; });
                break;
            default:
                break;
        }
    };
    SalaryBatchInfoComponent.prototype.resetData = function () {
        this.onSelectAllEmployee(true);
        this.defaultEmployeesGridData = this.selectEmployeesGridData;
        this.selectedEmployeeIds = this.defaultEmployeesGridData
            .filter(function (e) { return e.IsChecked; })
            .map(function (e) { return e.UserEmploymentId; });
        this.selectDepartmentFilter = [];
        this.selectTemplateFilter = [];
        this.multiSelectDepartmentFilter = [];
        this.multiSelectTemplateFilter = [];
        this.filterEmployeesGrid();
    };
    SalaryBatchInfoComponent.prototype.onSelectAllEmployee = function (isChecked) {
        this.filterEmployeesGridData.forEach(function (e) { return (e.IsChecked = isChecked); });
        this.entityContext.IsCheckedAllEmployees = this.selectEmployeesGridData.every(function (model) { return model.IsChecked; });
    };
    SalaryBatchInfoComponent.prototype.setSalaryBatchEmployeeGridDataSource = function () {
        if (this.entityContext.Employees) {
            var cycleId_1 = this.getChildWindowCycleId();
            var dataSource = [];
            if (cycleId_1) {
                dataSource = this.entityContext.Employees.filter(function (e) { return e.SalaryCycleId === cycleId_1 && e.IsActive; });
            }
            else {
                dataSource = this.entityContext.Employees.filter(function (e) { return e.IsActive; });
            }
            dataSource.forEach(function (e) {
                if (e.IsTerminated) {
                    e.FullName = '(' + e.FullName + ')';
                    e.IsChecked = false;
                }
                else {
                    e.IsChecked = true;
                }
            });
            this.defaultEmployeesGridData = JSON.parse(JSON.stringify(dataSource));
            this.selectEmployeesGridData = JSON.parse(JSON.stringify(dataSource));
            this.resetData();
        }
        else {
            this.defaultEmployeesGridData = [];
            this.selectEmployeesGridData = [];
            this.filterEmployeesGridData = [];
        }
    };
    SalaryBatchInfoComponent.prototype.getChildWindowCycleId = function () {
        var _this = this;
        var cycleId;
        var dataItem = this.salaryCycles.find(function (cycle) { return cycle.Id === _this.entityContext.CycleId; });
        if (!dataItem) {
            cycleId = this.entityContext.CycleId =
                this.salaryCycles && this.salaryCycles.length > 0 ? this.salaryCycles[0].Id : undefined;
        }
        else {
            cycleId = dataItem.Id;
        }
        return cycleId;
    };
    return SalaryBatchInfoComponent;
}());
export { SalaryBatchInfoComponent };
