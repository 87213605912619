import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { ISimpleKeyValuePair } from 'src/app/services/api/api-model';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { CompanyPreferenceService } from 'src/app/shared-components/preference/preference.service';
import { CompanyDepartmentService } from '../../departments/company-department.service';
import { CompanyTemplatesService } from '../../templates/templates.service';
import { DimensionsService } from '../dimensions.service';

@Component({
  selector: 'app-dimension-detail',
  templateUrl: './dimension-detail.component.html',
  styleUrls: ['./dimension-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DimensionDetailComponent implements OnInit {
  private selectedIdValue: number;
  @Input()
  public get selectedId(): number {
    return this.selectedIdValue;
  }
  public set selectedId(value: number) {
    if (value && this.selectedIdValue !== value) {
      this.selectedIdValue = value;
    }
  }

  public editMode = this.sessionService.role.IsSalaryAdmin || this.sessionService.role.IsFullAdmin;
  public templates: any = [];
  public companyusers: any = [];
  public filter: FilterDescriptor;

  public get isEnableDimension(): boolean {
    switch (this.dimensionsService.currentDimensionNumber) {
      case 1:
        return !this.dimensionsService.dimension1Active;
      case 2:
        return !this.dimensionsService.dimension2Active;
      case 3:
        return !this.dimensionsService.dimension3Active;
      default:
        return false;
    }
  }

  public get isShowCompanyUser(): boolean {
    return this.sessionService.feature.hasModuleId(3);
  }

  constructor(
    public sessionService: SessionService,
    public dimensionsService: DimensionsService,
    private companyPreferenceService: CompanyPreferenceService,
    public companyTemplateService: CompanyTemplatesService,
    public companyDepartmentService: CompanyDepartmentService,
    public staticDataService: StaticDataService
  ) {}

  ngOnInit() {
    this.companyTemplateService.loadTemplates();
    this.companyDepartmentService.loadDepartment();
    this.staticDataService.companyUsers.subscribe(() => {
      this.dimensionsService.triggerUpdate = true;
    });
  }

  public dimensionNamingVisible = false;
  public activeDimension() {
    try {
      const item = this.dimensionsService.currentPref;
      item.Value = this.dimensionsService.dimensionNaming;
      const itemToSubmit: ISimpleKeyValuePair = { Key: item.Key, Value: item.Value };
      this.companyPreferenceService.updateCompanySetting(itemToSubmit).subscribe(() => {
        this.dimensionNamingVisible = false;
        this.dimensionsService.updateDimensionLabel();
        this.dimensionsService.dimensionNaming = '';
        this.activeDimensionBoolean(true);
      });
    } catch (err) {
      this.dimensionsService.dimensionNaming = '';
    }
  }

  private activeDimensionBoolean(isActive?: boolean) {
    switch (this.dimensionsService.currentDimensionNumber) {
      case 1:
        this.dimensionsService.dimension1Active = isActive;
        break;
      case 2:
        this.dimensionsService.dimension2Active = isActive;
        break;
      case 3:
        this.dimensionsService.dimension3Active = isActive;
        break;
    }
  }

  public gridAction(action: string) {
    switch (action) {
      case 'AddNew':
        this.createNewDimension();
        break;
      case 'Delete':
        this.deleteDimension();
        break;
    }
  }

  public createNewDimension() {
    this.dimensionsService.createDimension();
  }

  public updateDimension(item?: any) {
    this.dimensionsService.updateDimension();
  }

  public removeDimensionDialogVisible = false;
  public deleteDimension() {
    this.removeDimensionDialogVisible = true;
  }

  public onRemoveDimension(action: string) {
    if (action === 'Delete') {
      this.dimensionsService.deleteDimension();
    }
  }

  public onFilterChange(filter: any) {}
  public gridDblclick(event: any) {}
  public onSelectedItemChange(item?: any) {
    if (item) {
      this.dimensionsService.currentDimension = item;
      this.dimensionsService.currentDimensionId = item.Id;
    }
  }
}
