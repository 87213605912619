import { EventEmitter } from '@angular/core';
import { DefaultAvatar } from '../model/default-avatar';
import { DataService } from '../services/api/data.service';
import { SessionService } from '../services/session/session.service';
var ChangeAvatarDialogComponent = /** @class */ (function () {
    function ChangeAvatarDialogComponent(dataService, sessionService) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.firstLoad = true;
        this.visibleChange = new EventEmitter();
        this.avatarChanged = new EventEmitter();
    }
    Object.defineProperty(ChangeAvatarDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangeAvatarDialogComponent.prototype, "avatar", {
        get: function () {
            return this.avatarValue;
        },
        set: function (value) {
            if (this.avatarValue !== value) {
                this.avatarValue = value;
                if (this.firstLoad) {
                    if (this.avatarValue !== DefaultAvatar.imgFemaleAvatar && this.avatarValue !== DefaultAvatar.imgMaleAvatar) {
                        this.firstLoad = false;
                    }
                }
                else {
                    this.avatarChanged.emit(value);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    ChangeAvatarDialogComponent.prototype.onAvatarChanged = function (formData) {
        var _this = this;
        if (this.sessionService.browser.isHybridApp) {
        }
        if (Object.keys(formData).length !== 0) {
            return;
        }
        this.dataService.Users_CurrentImage(formData).subscribe(function (data) {
            if (!data) {
                _this.avatar = '';
                _this.visible = false;
            }
            else {
                _this.avatar = data;
                _this.visible = false;
            }
        }, function (error) { });
    };
    return ChangeAvatarDialogComponent;
}());
export { ChangeAvatarDialogComponent };
