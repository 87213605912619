import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { booleanValue } from 'angular-walkthrough';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Global } from '../../common/global';
import { NumericTextBoxOptions } from '../../custom-controls/numeric-edit/numeric-text-box-options';
import {
  ICompanyUser,
  IDepartment,
  IEntityType,
  IReport,
  IReportArea,
  IReportParameter,
  IReportParameterValue,
  IReportRequest,
  ISalaryCycle
} from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { DownloadService } from '../../services/download.service';
import { SessionService } from '../../services/session/session.service';
import { IReportControl, IReportState, ReportDialogService } from './report-dialog.service';
import { ReportParametersDefaultValue } from './report-parameters-default-value';

@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportDialogComponent implements OnInit, OnDestroy {
  @Input() public param: ReportParametersDefaultValue;

  private keyValue: string;
  @Input()
  public get key(): string {
    return this.keyValue;
  }
  public set key(value: string) {
    if (this.keyValue !== value) {
      this.keyValue = value;
      this.filterStateImage();
      this.reports = undefined;
      this.reportControls = [];
    }
  }

  private visibleValue = false;
  public noReportVisible = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.gridVisible = true;
      this.visibleValue = value;
      if (value) {
        if (!this.reports) {
          this.getReports();
        }
        this.getSelectedReport();
        this.updateReportControl();
      } else {
        this.savePreviousState();
        // this.reportControls = [];
      }

      if (this.isMobile) {
        this.selectedReport = undefined;
      }
      this.visibleChange.emit(value);
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private selectedReportValue: IReport;
  public get selectedReport(): IReport {
    return this.selectedReportValue;
  }
  public set selectedReport(value: IReport) {
    if (value) {
      this.gridVisible = false;
    }
    // Save previous image of report staticRoleId
    if (this.selectedReportValue) {
      this.savePreviousState();
    }

    // Change value after save State
    if (this.selectedReportValue !== value) {
      this.selectedReportValue = value;
      this.createReportControls();
    }
  }

  public reports: IReport[];
  public reportImage: IReportState;

  public get formatOptions(): string[] {
    let options: string[] = [];
    if (this.selectedReport) {
      options = this.selectedReport.SupportedFormats.sort((a: string, b: string) => {
        const order: string[] = ['html', 'pdf', 'xlsx', 'csv', 'bank', 'si', 'nets'];
        return order.indexOf(a) - order.indexOf(b);
      });

      if (!this.selectedFormat && options) {
        this.selectedFormat = options[0];
      }
    }

    return options;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public get diaglogWidth(): number {
    if (this.isMobile) {
      return 600;
    }
    return 750;
  }

  public get displayFlex(): string {
    if (this.isMobile) {
      return '';
    }
    return 'displayFlex';
  }

  public gridVisible = true;
  public selectedFormat: string;
  public reportControls: any[];
  public numericTextBoxOption: NumericTextBoxOptions = { min: 0, step: 1, spinners: false };
  public missingRequiredFields = '';
  public validationDialogVisible = false;
  public newTabBlockedDialogVisible = false;
  public safariWarningDialogVisible = false;
  public safariWarning = '';
  public htmlReportDialogVisible = false;
  public htmlReportDialogContent = '';

  private translationServiceTerms: string[];
  private noneItemText: string;

  private allReportState: IReportState[];
  private currentReportState: IReportState[];
  private taskBind: Array<{ api: string; data: any[] }>;

  @ViewChild('ReportDialog', { static: false }) public gridDialog: any;

  public get hiddenScroll(): string {
    if (this.isMobile) {
      return '';
    }
    return 'hiddenScroll';
  }

  public get isCordovaApp(): boolean {
    return this.sessionService.browser.isHybridApp;
  }

  private reportAreas: IReportArea[];
  private reportParameters: IReportParameter[];
  private departments: IDepartment[];
  private salaryCycles: ISalaryCycle[];
  private entityTypes: IEntityType[];
  public columns: any[];

  constructor(
    private staticDataService: StaticDataService,
    private dataService: DataService,
    public sessionService: SessionService,
    private downloadService: DownloadService,
    public translateService: TranslateService,
    public reportDialogService: ReportDialogService
  ) {
    this.translationServiceTerms = ['DropdownList.None'];
    this.staticDataService.ReportParameter.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IReportParameter[]) => (this.reportParameters = data)
    );
    this.staticDataService.ReportArea.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IReportArea[]) => {
      this.reportAreas = data;
    });
    this.staticDataService.departments
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: IDepartment[]) => (this.departments = data));
    this.staticDataService.SalaryCycle.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ISalaryCycle[]) => (this.salaryCycles = data)
    );

    this.reportDialogService.appReport.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IReportState[]) => {
      this.allReportState = data;
      this.filterStateImage();
    });

    this.staticDataService.entityTypes
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: IEntityType[]) => (this.entityTypes = data));
  }

  private filterStateImage() {
    if (this.reportAreas && this.reportAreas.length > 0 && this.key) {
      const area = this.reportAreas.find((item: IReportArea) => item.Key === this.key);

      if (area) {
        this.currentReportState = this.allReportState.filter((item: IReportState) => item.AreaId === area.Id);
      } else {
        this.currentReportState = [];
      }
    } else {
      this.currentReportState = [];
    }
  }

  public ngOnInit(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateService.get(this.translationServiceTerms).subscribe((translations: { [key: string]: string }) => {
        this.noneItemText = translations['DropdownList.None'];
      });
    });
  }

  // public onSelectedChanged(values: any[]): void {
  //   this.MultiSelectValues = values;
  // }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onDialogAction(action: string): void {
    if (this.sessionService.isSubmitting || this.sessionService.isGetting) {
      return;
    }
    if (action === 'Download') {
      if (!this.validate()) {
        return;
      }

      const reportRequest: IReportRequest = {
        ReportId: this.selectedReport.Id,
        ParameterValues: this.getReportParameterValues()
      };
      let request: Observable<any> = this.dataService.Reports_GetReportDownloadWithOutput(
        this.selectedFormat,
        reportRequest
      );
      switch (this.selectedFormat) {
        case 'pdf':
          this.safariWarning = 'Download.SafariWarning_PDF';
          break;
        case 'xlsx':
          this.safariWarning = 'Download.SafariWarning_XLSX';
          break;
        case 'csv':
          this.safariWarning = 'Download.SafariWarning_CSV';
          break;
        case 'xml':
          this.safariWarning = 'Download.SafariWarning_XML';
          break;
        case 'html':
          request = this.dataService.Reports_GetReportWithOutput('html', reportRequest);
          break;
      }

      request.subscribe((response: any) => {
        // const enc = new TextDecoder('utf-8');
        if (this.selectedFormat === 'html') {
          if (!this.sessionService.browser.isMobile) {
            const newWindow: Window = window.open('about:blank');
            if (newWindow) {
              newWindow.document.write(response as any);
              // newWindow.document.write(enc.decode(response.body as BufferSource) as string);
              newWindow.document.close();
              this.visible = false;
              if (!this.isMobile) {
                this.reports = undefined;
              }
            } else {
              this.newTabBlockedDialogVisible = true;
            }
          } else {
            this.htmlReportDialogContent = response;
            // this.htmlReportDialogContent = enc.decode(response.body as BufferSource) as string;
            this.htmlReportDialogVisible = true;
            this.visible = false;
          }
        } else {
          if (this.sessionService.browser.isMobileSafari) {
            this.safariWarningDialogVisible = true;
          } else if (!this.isMobile) {
            this.reports = undefined;
          }

          this.downloadService.download(this.selectedReport.Name, response);
          // this.downloadService.download(this.selectedReport.Name, response.body);
          this.visible = false;
        }
      });
    }
    if (action === 'Cancel') {
      this.visible = false;
    }
  }

  public getControlDefaultLabel(control: any): string {
    return control.nullValueLabel ? control.nullValueLabel : 'Report.ComboboxDataSourceNoneFound';
  }

  private staticRoleId: number;
  private getReports(): void {
    if (this.key) {
      this.staticRoleId = Global.SESSION.CurrentRole.Id;
      const area: IReportArea = this.reportAreas.find((a: IReportArea) => a.Key === this.key);
      if (area && this.staticRoleId) {
        this.dataService.Reports_GetReportsByArea(area.Id).subscribe((reports: IReport[]) => {
          if (reports.length > 0) {
            this.reports = reports.sort((a: IReport, b: IReport) => (a.SortKey > b.SortKey ? 1 : -1));
            this.reports = this.reports.filter((z: IReport) => z.RequiredRoleId <= this.staticRoleId);
            this.getSelectedReport();
          } else {
            this.visible = false;
            this.noReportVisible = true;
          }
        });
      }
    }
  }

  public timeValues: string[] = [];

  private addTimeToDatePicker(value: any, currentTimeValue: string): any {
    let dayTimeValue: Date;
    dayTimeValue = value;
    if (!currentTimeValue) {
      currentTimeValue = '00:00';
    }
    const timeSeperate = currentTimeValue.split(':').map(Number);
    dayTimeValue.setUTCHours(timeSeperate[0], timeSeperate[1]);
    return dayTimeValue;
  }

  private getReportParameterValues(): IReportParameterValue[] {
    const parametersValue: IReportParameterValue[] = [];
    let value: any;
    if (this.reportControls) {
      this.reportControls.forEach((reportControl: any, idx: number) => {
        value = reportControl.value;
        if (reportControl.type && reportControl.type.toLowerCase() === 'datepicker' && reportControl.value) {
          value = this.staticDataService.getReportDate(reportControl.value);
        }

        if (reportControl.type && reportControl.type.toLowerCase() === 'checkbox' && !reportControl.value) {
          value = false;
        }

        if (reportControl.type && reportControl.type.toLowerCase() === 'combobox') {
          value = this.getComboboxParameterValue(reportControl);
        }

        if (reportControl.type && reportControl.type.toLowerCase() === 'multiselectlist') {
          value = this.getMultiSelectListParameterValue(reportControl);
        }

        parametersValue.push({ Key: reportControl.key, Value: value });
      });
    }

    return parametersValue;
  }

  private getMultiSelectListParameterValue(multiselectlistControl: any): string {
    const multiSelectValueIds = multiselectlistControl.value;
    if (multiSelectValueIds) {
      if (
        (multiSelectValueIds as any[]).length === 0 ||
        (multiSelectValueIds as any[]).length === multiselectlistControl.dataSource.length
      ) {
        return null;
      }

      return JSON.stringify(multiSelectValueIds);
    }

    return null;
  }

  private getComboboxParameterValue(comboboxControl: any): any {
    let value: any;
    if (
      comboboxControl.value === undefined &&
      comboboxControl.nullValueLabel &&
      comboboxControl.nullValueLabel.indexOf('Al') >= 0
    ) {
      value = null;
    }
    if (comboboxControl.value && comboboxControl.dataSource) {
      const comboObject: any = comboboxControl.dataSource.find(
        (data: any) => data[comboboxControl.underlyingId] === comboboxControl.value
      );
      value = comboObject ? comboboxControl.value : undefined;
    }

    return value;
  }

  private savePreviousState() {
    if (this.reportControls && this.reportControls.length > 0) {
      const area: IReportArea = this.reportAreas.find((a: IReportArea) => a.Key === this.key);

      const currentState: IReportState = {
        AreaId: area.Id,
        Id: this.selectedReport.Id,
        FormatOptions: this.formatOptions,
        FormatValue: this.selectedFormat
      };

      currentState.Reports = [];

      this.reportControls.forEach((item: any) => {
        if (this.isReportControlValid(item)) {
          const control: IReportControl = {
            ReportId: currentState.Id,
            ControlKey: item.key,
            ControlType: item.type,
            ControlValues: item.value
          };
          currentState.Reports.push(control);
        }
      });

      this.reportDialogService.changeReportImage(currentState);
    }
  }

  private createReportControls(): void {
    setTimeout(() => {
      // Clear current State
      this.reportControls = [];
      this.taskBind = [];
      this.selectedFormat = undefined;
      this.reportImage = undefined;

      if (this.selectedReport && this.selectedReport.Parameters) {
        const parameters: string[] = this.selectedReport.Parameters.split(',');
        if (parameters) {
          if (this.currentReportState && this.currentReportState.length > 0) {
            this.reportImage = this.currentReportState.find(
              (model: IReportState) =>
                model.AreaId === this.selectedReport.AreaId && model.Id === this.selectedReport.Id
            );
          }

          const controlHasDatasource: any[] = [];
          parameters.forEach((parameter: string) => {
            const control: any = this.createReportControl(parameter);
            if (control) {
              this.reportControls.push(control);
              this.timeValues.push('');

              if (control.type === 'ComboBox') {
                control.placeholderText = 'Report.NoAvailableOptions';
                this.loadComboboxDataSource(control);
                controlHasDatasource.push(control);
              } else if (control.type === 'MultiSelectList') {
                control.placeholderText = 'Report.NoAvailableOptions';
                this.loadMultiSelectList(control);
                controlHasDatasource.push(control);
              }
            }
          });

          if (controlHasDatasource && controlHasDatasource.length > 0) {
            if (this.taskBind && this.taskBind.length > 0) {
              this.taskBind.forEach((task) => {
                if (task.api.indexOf('api/entityType') >= 0) {
                  task.api = 'api/entityType/' + this.currentArea + '/Name';

                  this.dataService.httpGet(environment.apiUrl + '/' + task.api).subscribe((data: any) => {
                    controlHasDatasource.forEach((item: any) => {
                      if (item.api && item.api.indexOf('api/entityType') >= 0) {
                        item.api = 'api/entityType/' + this.currentArea + '/Name';
                      }
                      if (item.api === task.api) {
                        this.assignControlDataSource(item, item.listDataSourceParts, data);
                      } else {
                        this.dataService.httpGet(environment.apiUrl + '/' + item.api).subscribe((dataEntity: any) => {
                          this.assignControlDataSource(item, item.listDataSourceParts, dataEntity);
                        });
                      }
                    });
                  });
                } else {
                  this.dataService.httpGet(environment.apiUrl + '/' + task.api).subscribe((data: any) => {
                    controlHasDatasource.forEach((item: any) => {
                      if (item.api === task.api) {
                        this.assignControlDataSource(item, item.listDataSourceParts, data);
                      }
                    });
                  });
                }
              });
            }
          }

          if (this.reportImage) {
            if (this.reportImage.FormatOptions) {
              this.selectedFormat = this.reportImage.FormatValue;
            }

            if (this.reportImage.Reports && this.reportImage.Reports.length > 0) {
              this.reportImage.Reports.forEach((control: IReportControl) => {
                const updateItem = this.reportControls.find((item: any) => item.key === control.ControlKey);
                const indexUpdate = this.reportControls.indexOf(updateItem);

                if (indexUpdate !== -1) {
                  if (control.ControlType === 'MultiSelectList' || control.ControlType === 'combobox') {
                  } else {
                    this.reportControls[indexUpdate].value = control.ControlValues;
                  }
                }
              });
            }
          }
        }
      }
    });
  }

  public displayExp: string;
  private loadMultiSelectList(control: any): void {
    const listDataSourceParts: any = this.getPartsFromListDataSourceStringOfMultiSelect(control.listDataSource);
    const displayColumns: string[] = listDataSourceParts.displayText.split(',');

    control.columns = displayColumns.map((c) => ({ dataField: c, caption: c }));
    control.displayExp = displayColumns[0];
    control.value = [];
    control.underlyingId = listDataSourceParts.underlyingId;
    control.api = listDataSourceParts.api;
    control.listDataSourceParts = listDataSourceParts;

    if (!this.taskBind.find((item) => item.api === listDataSourceParts.api)) {
      this.taskBind.push({ api: listDataSourceParts.api, data: [] });
    }

    // this.dataService.httpGet(environment.apiUrl + '/' + listDataSourceParts.api).subscribe((data: any) => {
    //   this.assignControlDataSource(control, listDataSourceParts, data);
    // });
  }

  private loadComboboxDataSource(control: any): void {
    const listDataSourceParts: any = this.getPartsFromListDataSourceStringOfCombobox(control.listDataSource);
    control.underlyingId = listDataSourceParts.underlyingId;
    control.displayText = listDataSourceParts.displayText;

    if (!listDataSourceParts.api) {
      return;
    }

    control.api = listDataSourceParts.api;
    control.listDataSourceParts = listDataSourceParts;

    if (!this.taskBind.find((item) => item.api === listDataSourceParts.api)) {
      this.taskBind.push({ api: listDataSourceParts.api, data: [] });
    }

    // this.dataService.httpGet(environment.apiUrl + '/' + listDataSourceParts.api).subscribe((data: any) => {
    //   this.assignControlDataSource(control, listDataSourceParts, data);
    // });
  }

  private getPartsFromListDataSourceStringOfMultiSelect(stringListDataSource: string): any {
    stringListDataSource = stringListDataSource.replace(':', ';');
    const parts: string[] = stringListDataSource.split(';');
    // const employmentId: string = this.param && this.param.employmentId ? this.param.employmentId.toString() : '';

    return {
      api: parts[0],
      underlyingId: parts[1],
      displayText: parts[2]
    };
  }

  private assignControlDataSource(control: any, listDataSourceParts: any, data: any, isChanged?: boolean): void {
    const sameApiComboboxes: any[] = this.reportControls.filter((ctrl: any) => {
      if (ctrl.listDataSource) {
        const ctrlListDataSourceParts: any = this.getPartsFromListDataSourceStringOfCombobox(ctrl.listDataSource);
        return ctrlListDataSourceParts.api === listDataSourceParts.api;
      } else {
        return false;
      }
    });

    if (!sameApiComboboxes || sameApiComboboxes.length === 0) {
      return;
    }

    if (data && data.length > 0) {
      if (control.defaultValue === '{All}') {
        control.value = data.map((item: any) => {
          return item[control.underlyingId];
        });
        // control.defaultValue = JSON.parse(JSON.stringify(control.value));
      } else if (control.defaultValue === '{Current}' || control.key.indexOf('EmploymentId') >= 0) {
        control.displayText = 'Title';
        data = data.filter((item: any) => {
          if (item[control.underlyingId] === this.reportDialogService.selectedEmploymentId) {
            control.value = item[control.underlyingId];
          }
          return item.CompanyUserId === this.reportDialogService.selectedUserId;
        });
      } else if (control.defaultValue && control.underlyingId) {
        const isExitDefaultValueDataSource: any = (data as any[]).find(
          (model: any) => model[control.underlyingId] === control.defaultValue
        );
        if (!isExitDefaultValueDataSource) {
          const isExitIdValueDataSource: any = (data as any[]).find(
            (model: any) =>
              (model.Id ? model.Id.toString() : model.Id) ===
              (control.defaultValue ? control.defaultValue.toString() : control.defaultValue)
          );
          if (isExitIdValueDataSource) {
            control.value = isExitIdValueDataSource[control.underlyingId];
          }
        }
      }
      control.placeholderText = '';
      (data as any[]).forEach((model: any) => {
        if (!model[control.underlyingId]) {
          control.nullValueLabel = model.Name;
          const indexOf: number = (data as any[]).indexOf(model, 0);
          if (indexOf === 0) {
            control.hasItemNull = true;
          }
          (data as any[]).splice(indexOf, 1);
        }
      });
    }
    control.dataSource = data;

    control.dataSourceEmpty = data === undefined || data === null || data.length < 1;
    if (!control.dataSourceEmpty && control.defaultValue === '{First}') {
      if (control.hasItemNull) {
        control.value = undefined;
      } else {
        control.value = data[0][control.underlyingId];
      }
    }

    if (sameApiComboboxes && sameApiComboboxes.length > 0) {
      sameApiComboboxes.forEach((apiCombo: any) => {
        if (!apiCombo.dataSource || apiCombo.dataSource.length === 0) {
          apiCombo.dataSource = data;
          apiCombo.dataSourceEmpty = data === undefined || data === null || data.length < 1;
        }

        if (!apiCombo.dataSourceEmpty && apiCombo.defaultValue === '{First}') {
          if (control.hasItemNull) {
            apiCombo.value = undefined;
          } else {
            apiCombo.value = data[0][control.underlyingId];
          }
        }
      });
    }

    if (control.value && control.value.length === 1 && control.type !== 'MultiSelectList') {
      const indexOfValue: any = (control.dataSource as any[]).find(
        (model: any) => model[control.underlyingId] === control.value
      );
      if (!indexOfValue) {
        control.nullValueLabel = control.value;
        control.value = undefined;
      }
    }

    // Load last State
    if (this.reportImage && this.reportImage.Reports && this.reportImage.Reports.length > 0) {
      const dataSourceControlImage = this.reportImage.Reports.filter(
        (item: IReportControl) => item.ControlType === 'ComboBox' || item.ControlType === 'MultiSelectList'
      );

      if (dataSourceControlImage && dataSourceControlImage.length > 0) {
        dataSourceControlImage.forEach((controlImage: IReportControl) => {
          const updateItem = this.reportControls.find((item: any) => item.key === controlImage.ControlKey);
          const indexUpdate = this.reportControls.indexOf(updateItem);

          if (indexUpdate !== -1 && this.reportControls[indexUpdate].defaultValue !== '{Current}' && !isChanged) {
            if (controlImage.ControlKey === 'RequiredChangeLogType' && controlImage.ControlType === 'ComboBox') {
              this.currentArea = controlImage.ControlValues;
            }
            this.reportControls[indexUpdate].value = controlImage.ControlValues;
          }
        });
      }
    }
  }

  private getPartsFromListDataSourceStringOfCombobox(stringListDataSource: string): any {
    stringListDataSource = stringListDataSource.replace(':', ';');
    const parts: string[] = stringListDataSource.split(';');
    const employmentId: string = this.param && this.param.employmentId ? this.param.employmentId.toString() : '';

    if (parts[0].indexOf('{CurrentUserEmploymentId}') >= 0) {
      return {
        api: !!employmentId ? parts[0].replace('{CurrentUserEmploymentId}', employmentId) : '',
        underlyingId: parts[1],
        displayText: parts[2]
      };
    }

    return {
      api: parts[0],
      underlyingId: parts[1],
      displayText: parts[2]
    };
  }

  private createReportControl(parameter: string): any {
    const reportParameter: IReportParameter = this.reportParameters.find(
      (par: IReportParameter) => par.Key === parameter
    );
    if (!reportParameter) {
      console.log('reportParameter is undefined for parameter: ' + parameter);
      return undefined;
    }

    const key: string = reportParameter['Key'];
    // let defaultValue: any = this.getSelectedReportControlDefaultValueByKey(key);
    let defaultValue: any = this.getSelectedReportControlDefaultValue(reportParameter['DefaultValue'])
      ? this.getSelectedReportControlDefaultValue(reportParameter['DefaultValue'])
      : undefined;
    if (reportParameter.UiControlType === 'DatePicker') {
      defaultValue = this.staticDataService.getUTCDate(defaultValue);
    }
    if (!defaultValue && reportParameter['IsRequired']) {
      defaultValue = this.getSelectedReportControlDefaultValueByKey(key);
    }

    let nonCombo = '';
    if (!reportParameter['IsRequired'] && reportParameter['IsRequired'] === false) {
      nonCombo = 'DropdownList.None';
    }

    return {
      type: reportParameter['UiControlType'],
      defaultValue,
      value: defaultValue !== '{First}' ? defaultValue : undefined,
      listDataSource: reportParameter['ListDataSource'],
      nullValueLabel: nonCombo,
      isRequired: reportParameter['IsRequired'],
      isHidden: reportParameter['IsHidden'],
      name: reportParameter['Name'],
      key,
      sortKey: reportParameter['SortKey'],
      displayText: '',
      underlyingId: '',
      dataSource: [],
      dataSourceEmpty: false,
      dependsOn: reportParameter.DependsOn
    };
  }

  private getSelectedReportControlDefaultValueByKey(key: string): any {
    let defaultValue: any;
    switch (key) {
      case 'SalaryBatchId':
        defaultValue = this.param.salaryBatchId;
        break;
      case 'OptionalSalaryBatchId':
        defaultValue = this.param.salaryBatchId;
        break;
      case 'FilterByDepartment':
        if (this.departments.length > 0) {
          defaultValue = this.departments[0];
        }
        break;
      case 'FilterBySalaryCycle':
        if (this.salaryCycles.length > 0) {
          defaultValue = this.salaryCycles[0];
        }
        break;
      case 'CompanyUserId':
        defaultValue = this.param.employeeId;
        break;
      case 'SelfEmploymentId':
        defaultValue = this.param.employmentId;
        break;
      case 'UserEmploymentId':
        defaultValue = this.param.employmentId;
        break;
      case 'FromDate_YearBegin':
        defaultValue = this.getFirstDayOfCurrentYear();
        break;
      case 'ToDate_Today':
        defaultValue = this.staticDataService.getCurrentdate();
        break;
      case 'FromDate_LastMonthStart':
        defaultValue = this.getFirstDayOfPreviousMonth();
        break;
      case 'ToDate_LastMonthEnd':
        defaultValue = this.getLastDateOfPreviousMonth();
        break;
    }

    return defaultValue;
  }

  private getSelectedReportControlDefaultValue(defaultValue: any): any {
    switch (defaultValue) {
      case '{Current SalaryBatchId}':
        defaultValue = this.param.salaryBatchId;
        break;
      case '{Today}':
        defaultValue = this.staticDataService.getCurrentdate();
        break;
      case '{First day of current year}':
        defaultValue = this.getFirstDayOfCurrentYear();
        break;
      case '{First day of previous month}':
        defaultValue = this.getFirstDayOfPreviousMonth();
        break;
      case '{Last day of previous month}':
        defaultValue = this.getLastDateOfPreviousMonth();
        break;
      case '{Current}':
        // just define current for employee time
        if (this.sessionService.currentState.includes('tabs.employee.time')) {
          defaultValue = this.param.employmentId;
        }
        break;
    }
    return defaultValue;
  }

  private getFirstDayOfCurrentYear(): Date {
    const date: Date = new Date();
    return this.staticDataService.getCurrentdate(undefined, new Date(date.getFullYear(), 0, 1));
  }

  private getFirstDayOfPreviousMonth(): Date {
    const date: Date = new Date();
    let currentYear: number = date.getFullYear();
    let previousMonth: number = date.getMonth() - 1;
    if (previousMonth === 0) {
      currentYear -= 1;
      previousMonth = 12;
    }

    return this.staticDataService.getCurrentdate(undefined, new Date(currentYear, previousMonth, 1));
  }

  private getLastDateOfPreviousMonth(): Date {
    const date: Date = new Date();
    return this.staticDataService.getCurrentdate(undefined, new Date(date.getFullYear(), date.getMonth(), 0));
  }

  private validate(): boolean {
    this.missingRequiredFields = this.checkRequiredFields();
    if (this.missingRequiredFields) {
      this.validationDialogVisible = true;
      return false;
    }

    return true;
  }

  private checkRequiredFields(): string {
    let requiredFields = '';
    if (this.reportControls) {
      this.reportControls.forEach((reportControl: any) => {
        if (!this.isReportControlValid(reportControl)) {
          requiredFields += reportControl.name + ' ,';
        }
      });
    }

    if (!requiredFields) {
      return undefined;
    }

    requiredFields = requiredFields.slice(0, requiredFields.length - 2);
    return requiredFields;
  }

  private isReportControlValid(reportControl: any): boolean {
    if (!reportControl.isRequired && !(reportControl.type && reportControl.type.toLowerCase() === 'multiselectlist')) {
      return true;
    }

    // Special case for All selection at dropdownlist
    if (
      reportControl.type.toLowerCase() === 'combobox' &&
      reportControl.nullValueLabel &&
      reportControl.nullValueLabel.indexOf('Al') >= 0 &&
      reportControl.value === undefined
    ) {
      return true;
    }

    if (reportControl.type && reportControl.type.toLowerCase() === 'checkbox') {
      return true;
    }

    if (
      reportControl.type &&
      reportControl.type.toLowerCase() === 'datepicker' &&
      (!reportControl.value || (reportControl.value && new Date(reportControl.value) === undefined))
    ) {
      return false;
    }

    if (reportControl.type && reportControl.type.toLowerCase() === 'multiselectlist') {
      if (reportControl.isRequired) {
        return !(!reportControl.value || (reportControl.value && reportControl.value.length === 0));
      }

      return true;
    }

    return reportControl.value;
  }

  public onDoubleClick($event: any): void {
    setTimeout(() => {
      if ($event.event) {
        $event.event.stopPropagation();
        $event.event.preventDefault();
      }
      if (this.sessionService.isGetting || this.sessionService.isSubmitting) {
        return;
      }
      this.onDialogAction('Download');
    });
  }

  public onBackClick(): void {
    if (this.isMobile) {
      this.savePreviousState();
      this.selectedReport = undefined;
      this.gridVisible = true;
    }
  }

  private getSelectedReport() {
    if (this.reports && this.reports.length > 0 && !this.isMobile) {
      if (!this.selectedReport) {
        this.selectedReport = this.reports[0];
      } else {
        let existReportIndex = -1;
        const existReport = this.reports.filter((report, index) => {
          if (report.Key === this.selectedReport.Key) {
            existReportIndex = index;
            return true;
          }
          return false;
        });
        if (existReport && existReport.length === 0 && existReportIndex === -1) {
          this.selectedReport = this.reports[0];
        } else {
          this.selectedReport = this.reports[existReportIndex];
        }
      }
      setTimeout(() => {
        const tdSelect: HTMLElement = (this.gridDialog.kendoGrid.wrapper.nativeElement as HTMLBRElement).querySelector(
          'tr.k-state-selected > td'
        );
        if (tdSelect) {
          tdSelect.focus();
          tdSelect.click();
        }
      }, 700);
    }
  }

  private updateReportControl(control?: any, isChanged?: boolean) {
    setTimeout(() => {
      if (this.selectedReport && this.selectedReport.Parameters) {
        const parameters: string[] = this.selectedReport.Parameters.split(',');
        if (parameters) {
          if (this.currentReportState && this.currentReportState.length > 0) {
            this.reportImage = this.currentReportState.find(
              (model: IReportState) =>
                model.AreaId === this.selectedReport.AreaId && model.Id === this.selectedReport.Id
            );
          }
          if (this.reportControls && this.reportControls.length > 0) {
            if (control) {
              const reportControl = this.reportControls.find((item) => control.Key === item.Key && item.dependsOn);
              if (reportControl) {
                reportControl.api = 'api/entityType/' + this.currentArea + '/Name';
                reportControl.value = null;
                this.dataService.httpGet(environment.apiUrl + '/' + reportControl.api).subscribe((data: any) => {
                  this.assignControlDataSource(reportControl, reportControl.listDataSourceParts, data, isChanged);
                });
              }
            } else if (this.taskBind && this.taskBind.length > 0) {
              this.taskBind.forEach((task) => {
                if (task.api.indexOf('api/entityType') >= 0) {
                  task.api = 'api/entityType/' + this.currentArea + '/Name';
                }
                this.dataService.httpGet(environment.apiUrl + '/' + task.api).subscribe((data: any) => {
                  this.reportControls.forEach((item: any) => {
                    if (item.api && item.api.indexOf('api/entityType') >= 0) {
                      item.api = 'api/entityType/' + this.currentArea + '/Name';
                    }
                    if (item.api === task.api) {
                      this.assignControlDataSource(item, item.listDataSourceParts, data, isChanged);
                    }
                  });
                });
              });
            }
          }
        }
      }
    });
  }

  private currentArea = '1';
  public onComboboxChanged(item: any, control: any) {
    if (control.dependsOn || !item || control.listDataSource.indexOf('entityType') < 0) {
      return;
    }
    this.currentArea = item.toString();
    this.updateReportControl(control, true);
  }
}
