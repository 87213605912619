import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { AccountantModule } from '../../accountant/accountant.module';
import { CustomControlsModule } from '../../custom-controls/custom-controls.module';
import { SharedComponentsModule } from '../../shared-components/shared-components.module';

import { CompanyGeneralComponent } from './company-general.component';
import { AddressComponent } from './components/address.component';
import { CompanyContactComponent } from './components/company-contact.component';
import { CompanyDataComponent } from './components/company-data.component';
import { CompanyHolidaySettingComponent } from './components/company-holiday-setting.component';
import { CompanyOptionComponent } from './components/company-option.component';
import { TestEmailDialogComponent } from './dialogs/test-email.component';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule, CustomControlsModule, SharedComponentsModule, AccountantModule],
  declarations: [
    AddressComponent,
    CompanyGeneralComponent,
    CompanyContactComponent,
    CompanyDataComponent,
    CompanyOptionComponent,
    TestEmailDialogComponent,
    CompanyHolidaySettingComponent
  ],
  exports: [CompanyGeneralComponent],
  entryComponents: [CompanyGeneralComponent]
})
export class CompanyGeneralModule {}
