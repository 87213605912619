import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Transition, TransitionService } from '@uirouter/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Global } from '../common/global';
import { RouterStateService } from '../common/router-state.service';
import { ICompanyUser, IReportArea, ISimpleKeyValuePair, IUserEmployment } from '../services/api/api-model';
import { DataService } from '../services/api/data.service';
import { StaticDataService } from '../services/api/static-data.service';
import { EmployeeService } from '../services/employee.service';
import { SessionService } from '../services/session/session.service';
import { ReportDialogService } from '../shared-components/report-dialog/report-dialog.service';
import { ReportParametersDefaultValue } from '../shared-components/report-dialog/report-parameters-default-value';

@Component({
  selector: 'app-selfservice',
  templateUrl: './self-service.component.html',
  styleUrls: ['./self-service.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelfServiceComponent implements OnInit, OnDestroy {
  private reportAreas: IReportArea[];

  private editModeValue = false;
  public get editMode(): boolean {
    return this.editModeValue;
  }
  public set editMode(value: boolean) {
    if (this.editModeValue !== value) {
      this.editModeValue = value;
      this.sessionService.hideGetStarted = value;
    }
  }

  public get reportKey(): string {
    if (this.state.currentStateIs('tabs.selfservice.payslip')) {
      return 'SelfServicePayslips';
    }

    return '';
  }

  public get hasEmployments(): boolean {
    return this.employeeService.employmentsCount > 0;
  }

  public get reportViewEnabled(): boolean {
    if (this.reportAreas && this.reportAreas.length > 0) {
      const area: IReportArea = this.reportAreas.find((a: IReportArea) => a.Key === this.reportKey);
      if (area) {
        return true;
      }
    }

    return false;
  }

  public get disabledActionButtonClass(): string {
    return this.allowUsersToModifyOwnData ? '' : 'disabledActionButtonClass';
  }

  public get isFirefox(): boolean {
    return this.sessionService.browser.isFirefox;
  }

  private get selfServicePlayslipTabEnabled(): boolean {
    return this.hasEmployments;
  }

  private get selfServiceTimeTabEnabled(): boolean {
    return this.sessionService.feature.hasModuleId(8) && this.hasEmployments;
  }

  public get isIOSApp(): boolean {
    return this.sessionService.browser.isHybridApp && this.sessionService.browser.iOSMobileDevice;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public isValid = true;
  public discardDialogVisible = false;
  public allowUsersToModifyOwnData = false;
  // public isDirty = false;
  private isDirtyValue = false;
  public get isDirty() {
    return this.isDirtyValue;
  }
  public set isDirty(value: boolean) {
    this.isDirtyValue = value;
  }

  public showSaveDataConfirmationChangeState = false;
  public isDisableChangeEditMode = false;

  private companyUser: ICompanyUser;
  private userEmployment: IUserEmployment;
  private navigationToStateName: string;

  constructor(
    public sessionService: SessionService,
    public state: RouterStateService,
    public employeeService: EmployeeService,
    private dataService: DataService,
    private staticDataService: StaticDataService,
    private transitionService: TransitionService,
    public reportDialogService: ReportDialogService
  ) {}

  public ngOnInit(): void {
    this.transitionService.onStart({}, (transition: Transition) => {
      const fromState = transition.from().name;
      this.navigationToStateName = transition.to().name;
      const generalState = 'tabs.selfservice.general';

      if (this.editMode && fromState && fromState === generalState) {
        if (this.isDirty) {
          // If validation fails, alert user and do nothing.
          // Else show action confirmation.
          this.showSaveDataConfirmationChangeState = true;
          return false;
        } else {
          // Editmode but no changes, we close editmode without doing anything
          this.editMode = false;
          return true;
        }
      } else {
        this.editMode = false;
        return true;
      }
    });

    this.staticDataService.ReportArea.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IReportArea[]) => (this.reportAreas = data)
    );

    this.employeeService.selectEmployeeAsync(Global.SESSION.CurrentCompanyUser.Id).subscribe(() => {
      this.employeeService.filter = '';
      this.employeeService.employee.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e) => {
        this.companyUser = e;

        // this.loadCompanyUser();
        const allowUsersToModifyOwnData: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
          'Access.AllowUsersToModifyOwnData'
        );
        if (allowUsersToModifyOwnData && allowUsersToModifyOwnData.Value === 'true') {
          this.allowUsersToModifyOwnData = true;
        }

        this.employeeService.employments.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this.checkSwitchingSelfServiceTab();
        });
      });
    });
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onTabClick(): void {
    this.sessionService.menuToggled = true;
    this.sessionService.showMoreMenuToggled = false;
    this.sessionService.showHelpMenuToggled = false;
  }

  public onConfirmSaveDataOnChangeState(action: string) {
    switch (action) {
      case 'ContinueAndSave':
        this.editMode = false;
        if (this.navigationToStateName) {
          this.sessionService.NavigateTo(this.navigationToStateName);
        }
        break;
      case 'DiscardAndLeave':
        this.discardChanges();
        if (this.navigationToStateName) {
          this.sessionService.NavigateTo(this.navigationToStateName);
        }
        break;
    }

    this.navigationToStateName = undefined;
  }

  public onShowReportsEventClick(): void {
    if (!this.companyUser) {
      return;
    }

    this.reportDialogService.reportParams = new ReportParametersDefaultValue();

    this.reportDialogService.reportParams.employeeId = this.companyUser ? this.companyUser.Id : undefined;
    this.reportDialogService.reportParams.employmentId = this.userEmployment ? this.userEmployment.Id : undefined;
    this.reportDialogService.onShowReportsEventClick();
  }

  public onEmployeeGeneralChanged(): void {
    // this.shouldUpdateUserEmployment = true;
  }

  public onEditModeChange(editMode: boolean): void {
    if (this.editMode === editMode || !this.allowUsersToModifyOwnData || (!this.isValid && editMode === false)) {
      return;
    }

    if (editMode === false && this.isDirty) {
      this.updateUserEmployment();
    }

    this.editMode = editMode;
  }

  public discardDialogAction(action: string): void {
    if (action === 'ConfirmDiscard') {
      this.discardChanges();
    }
  }

  public confirmAndDiscardChanges(): void {
    if (!this.isDirty) {
      this.editMode = false;
      return;
    }

    this.discardDialogVisible = true;
  }

  public onDoubleClick(): void {
    if (!this.allowUsersToModifyOwnData) {
      this.isDisableChangeEditMode = true;
      return;
    }

    if (
      !this.sessionService.isGetting &&
      !this.sessionService.isSubmitting &&
      !this.editMode &&
      this.sessionService.currentState === 'tabs.selfservice.general'
    ) {
      this.onEditModeChange(true);
    }
  }

  private updateUserEmployment() {
    this.employeeService.saveChanges().subscribe(() => (this.isDirty = false), () => (this.editMode = true));
  }

  private discardChanges() {
    this.editMode = false;
    this.isDirty = false;
    this.isValid = true;
    this.employeeService.discardChanges();
  }

  private checkSwitchingSelfServiceTab(): void {
    if (this.sessionService.currentState) {
      switch (this.sessionService.currentState) {
        case 'tabs.selfservice.time':
          if (!this.selfServiceTimeTabEnabled) {
            this.sessionService.NavigateTo('tabs.selfservice.general');
          } else {
            this.sessionService.NavigateTo(this.sessionService.selfServiceTabState);
          }
          break;
        case 'tabs.selfservice.payslip':
          if (!this.selfServicePlayslipTabEnabled) {
            this.sessionService.NavigateTo('tabs.selfservice.general');
          } else {
            this.sessionService.NavigateTo(this.sessionService.selfServiceTabState);
          }
          break;
        default:
          if (!this.selfServicePlayslipTabEnabled) {
            this.sessionService.NavigateTo('tabs.selfservice.general');
          } else {
            if (!this.sessionService.selfServiceTabState) {
              this.sessionService.NavigateTo('tabs.selfservice.payslip');
            } else {
              this.sessionService.NavigateTo(this.sessionService.selfServiceTabState);
            }
          }
          break;
      }
    } else {
      this.sessionService.NavigateTo('tabs.selfservice.payslip');
    }
  }
}
