import { EventEmitter } from '@angular/core';
import { RouterStateService } from 'src/app/common/router-state.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { AccountantJoinService } from './accountant-join.service';
var AccountantJoinComponent = /** @class */ (function () {
    function AccountantJoinComponent(service, staticDataService, sessionService, stateService) {
        this.service = service;
        this.staticDataService = staticDataService;
        this.sessionService = sessionService;
        this.stateService = stateService;
        this.joinDialogVisible = false;
        this.componentVisible = false;
        this.visibleChange = new EventEmitter();
        if (this.sessionService.feature.IsMultiCompanyMember) {
            this.stateService.registerRedirect('tabs.accountant.join', 'tabs.accountant.summary');
        }
    }
    Object.defineProperty(AccountantJoinComponent.prototype, "visible", {
        get: function () {
            return this.componentVisible;
        },
        set: function (value) {
            if (this.componentVisible !== value) {
                this.componentVisible = value;
                if (!value) {
                    this.service.filter = '';
                }
                this.visibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantJoinComponent.prototype, "isNonSelect", {
        get: function () {
            if (this.service.selectedAccount && this.service.selectedAccount.Id) {
                return false;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    AccountantJoinComponent.prototype.onSelectedItem = function (event) {
        this.service.selectedAccount = event;
    };
    AccountantJoinComponent.prototype.dialogAction = function (action) {
        if (action === 'Attach') {
            this.joinDialogVisible = true;
        }
    };
    return AccountantJoinComponent;
}());
export { AccountantJoinComponent };
