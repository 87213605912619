<div class="FormElement has-fixedAddon">
  <span class="FormElement-label" *ngIf="label">
    <abbr *ngIf="required" class="FormElement-required" title="required">*</abbr
    ><span [ngClass]="{ textWithTooltip: labelTooltip }" kendoTooltip [title]="labelTooltip">{{ label }}:</span>
    <app-icon
      *ngIf="helpLink && !isValueIcon"
      class="infoIcon align-items-center {{ maxHeight20 }}"
      [isSelfService]="isSelfService"
      [icon]="helpIcon"
      [tooltip]="helpTooltip"
      [link]="helpLink"
      [isOpenLink]="isOpenLink"
      [tooltipWidth]="340"
      [istooltipHTML]="true"
    ></app-icon>
  </span>

  <div class="FormElement-control">
    <div
      class="FormElement-static"
      *ngIf="!editMode"
      [ngClass]="{ 'text-right': rightText, textWithTooltip: valueTooltip }"
      kendoTooltip
      [title]="valueTooltip"
    >
      {{ percentageDisplayText }}
    </div>

    <kendo-numerictextbox
      id="{{ componentId }}"
      [autoCorrect]="true"
      *ngIf="editMode"
      class="FormElement-numericInput"
      [ngClass]="{ disable: disable, 'text-right': rightText }"
      [hidden]="!editMode"
      [step]="step ? 0 : 1"
      [min]="minValue ? minValue : 0"
      [max]="maxValue ? maxValue : 100"
      [decimals]="allowDecimal ? decimals : 0"
      [disabled]="disable"
      [(ngModel)]="percentValue"
      [spinners]="false"
      [format]="decimalsCode"
      (blur)="onBlur()"
      (keydown)="onKeydown($event)"
      (keyup)="onkeyup($event)"
    ></kendo-numerictextbox>

    <app-icon
      *ngIf="helpLink && isValueIcon"
      class="infoIcon align-items-center {{ maxHeight20 }}"
      [isSelfService]="isSelfService"
      [icon]="helpIcon"
      [tooltip]="helpTooltip"
      [link]="helpLink"
      [isOpenLink]="isOpenLink"
      [tooltipWidth]="340"
      [istooltipHTML]="true"
    ></app-icon>

    <div class="FormElement-addon percentageSign" *ngIf="editMode && percentageSign">&nbsp;%</div>
    <!-- <div class="validation-texteditor k-widget k-tooltip k-tooltip-validation" *ngIf="isInvalid">{{'EmployeeGeneral.Required' | translate}}</div> -->
  </div>
</div>
