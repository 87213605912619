<app-dialog
  [title]="'Accountant.AddNewCompany' | translate"
  [(visible)]="visible"
  (action)="dialogAction($event)"
  [minWidth]="900"
  [overlayClose]="false"
>
  <div class="Form--horizontal" *ngIf="visible">
    <p>{{ 'Accountant.CreateNewCompanyIntro' | translate }}</p>
    <app-text-edit
      [label]="'Accountant.CVRNumber' | translate"
      [required]="CVRNumberValid"
      [wrongValue]="isWrongValue"
      [editMode]="true"
      [type]="'text'"
      [(value)]="cvrNumber"
      (blur)="lookupCompany()"
      (valueChange)="isValidValue()"
      (keyUp)="CVRKeyUp()"
      [isShowSymbolRequired]="true"
    ></app-text-edit>
    <p *ngIf="seNumberRequired">{{ 'Accountant.CompanyExists' | translate: { SystemAlias: branding.SystemAlias } }}</p>
    <app-text-edit
      *ngIf="seNumberRequired"
      [label]="'Accountant.SENumber' | translate"
      [wrongValue]="isWrongValue"
      [required]="true"
      [editMode]="true"
      [type]="'number'"
      [(value)]="seNumber"
    ></app-text-edit>
    <app-text-edit
      [label]="'Accountant.CompanyName' | translate"
      [required]="nameValid"
      [editMode]="true"
      [(value)]="name"
      (valueChange)="isValidValue()"
      [isShowSymbolRequired]="true"
    ></app-text-edit>
    <div class="FormElement">
      <span class="FormElement-label">{{ 'Accountant.SelectPackage' | translate }} : (*) </span>
      <div class="FormElement-control">
        <div class="FormElement">
          <app-radio-edit [editMode]="true" [(value)]="modulePackage">
            <app-radio-edit-option [value]="-1" [label]="'Accountant.None' | translate"></app-radio-edit-option>
            <app-radio-edit-option
              *ngFor="let package of staticData.ModulePackage | async"
              [value]="package.Id"
              [label]="package.Name"
            ></app-radio-edit-option>
          </app-radio-edit>
        </div>
        <div class="FormElement helpPackages">
          <a
            href="https://intect.dk/priser?_ga=2.190427307.1871532062.1552879033-1383993970.1544609209"
            target="_blank"
            >{{ 'Accountant.LearnModulePackage' | translate: { SystemAlias: branding.SystemAlias } }}</a
          >
        </div>
      </div>
    </div>

    <hr class="FormElementHr" />

    <app-combobox-edit
      [label]="'Accountant.CopyTypesFrom' | translate"
      [nullValueLabel]="'DropdownList.None' | translate"
      [editMode]="true"
      [comboboxDataSource]="sourceCompanyFilter"
      [textField]="'Relevant'"
      [idField]="'Id'"
      [(value)]="salaryTypesFrom"
    ></app-combobox-edit>

    <app-combobox-edit
      [label]="'Accountant.CopyTemplatesFrom' | translate"
      [nullValueLabel]="'DropdownList.None' | translate"
      [editMode]="true"
      [comboboxDataSource]="sourceCompanyFilter"
      [textField]="'Relevant'"
      [idField]="'Id'"
      [(value)]="templatesFrom"
    ></app-combobox-edit>

    <app-check-edit
      [label]="'Accountant.GrantAllUsersAccess' | translate"
      [editMode]="true"
      [(value)]="grantAccess"
      (valueChange)="grantAccessChange()"
    ></app-check-edit>
    <app-combobox-edit
      [label]="'Accountant.AsRole' | translate"
      [editMode]="true"
      [disable]="!grantAccess"
      [required]="accessRoleValid"
      [comboboxDataSource]="staticData.Role | async"
      [idField]="'Id'"
      [textField]="'Name'"
      [(value)]="accessRole"
      [nullValueLabel]="nonValueLableAccessRole"
    ></app-combobox-edit>
  </div>
  <p innerHTML="{{ 'Accountant.CreateMessageEndForm' | translate }}">></p>

  <app-check-edit
    [label]="'Accountant.AcceptTerms' | translate: { SystemAlias: branding.SystemAlias }"
    [editMode]="true"
    [(value)]="acceptTerms"
    [isLink]="true"
  >
  </app-check-edit>
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Create'" [close]="false"></app-dialog-action>
</app-dialog>

<app-loading-tips
  class="loadScreenCreateCompany"
  [tiptext]="'Accountant.CreatingTheCompany' | translate"
  [visible]="isWaittingCreat"
></app-loading-tips>

<app-dialog class="blue-dialog" [(visible)]="confimTermVisible">
  <p>{{ 'GlobalDialog.CheckboxValidationMessage' | translate }}</p>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
