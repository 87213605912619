/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./accountant-summary.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../custom-controls/buttons/button.component.ngfactory";
import * as i3 from "../../custom-controls/buttons/button.component";
import * as i4 from "../../services/session/session.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../custom-controls/text-edit/text-edit.component.ngfactory";
import * as i7 from "../../custom-controls/text-edit/text-edit.component";
import * as i8 from "@angular/common";
import * as i9 from "./accountant-leave-dialog.component.ngfactory";
import * as i10 from "./accountant-leave-dialog.component";
import * as i11 from "./accountant-summary.service";
import * as i12 from "../../common/router-state.service";
import * as i13 from "../../services/api/data.service";
import * as i14 from "./accountant-summary.component";
var styles_AccountantSummaryComponent = [i0.styles];
var RenderType_AccountantSummaryComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AccountantSummaryComponent, data: {} });
export { RenderType_AccountantSummaryComponent as RenderType_AccountantSummaryComponent };
function View_AccountantSummaryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-button", [["buttonClass", "Button--card"], ["class", "Button--card"]], null, [[null, "clickEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickEvent" === en)) {
        var pd_0 = ((_co.leaveDialogVisible = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(1, 49152, null, 0, i3.ButtonComponent, [i4.SessionService], { label: [0, "label"], buttonClass: [1, "buttonClass"] }, { clickEvent: "clickEvent" }), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Accountant.Leave")); var currVal_1 = "Button--card"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AccountantSummaryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "fieldset", [["class", "Form--horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "app-text-edit", [["class", "status-company-organization"]], null, null, null, i6.View_TextEditComponent_0, i6.RenderType_TextEditComponent)), i1.ɵdid(2, 114688, null, 0, i7.TextEditComponent, [i4.SessionService, i1.ElementRef, i1.ChangeDetectorRef], { value: [0, "value"], label: [1, "label"] }, null), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 3, "app-text-edit", [], null, null, null, i6.View_TextEditComponent_0, i6.RenderType_TextEditComponent)), i1.ɵdid(6, 114688, null, 0, i7.TextEditComponent, [i4.SessionService, i1.ElementRef, i1.ChangeDetectorRef], { value: [0, "value"], label: [1, "label"] }, null), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountantSummaryComponent_1)), i1.ɵdid(10, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-accountant-leave-dialog", [], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.leaveDialogVisible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_AccountantLeaveDialogComponent_0, i9.RenderType_AccountantLeaveDialogComponent)), i1.ɵdid(12, 245760, null, 0, i10.AccountantLeaveDialogComponent, [i11.AccountantSummaryService, i12.RouterStateService, i4.SessionService, i5.TranslateService, i13.DataService], { visible: [0, "visible"] }, { visibleChange: "visibleChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.service.multiCompanyOrganization)); var currVal_1 = i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 4).transform("Accountant.AccountConcern")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.service.multiCompanyInvoice)); var currVal_3 = i1.ɵunv(_v, 6, 1, i1.ɵnov(_v, 8).transform("Accountant.WhoIsInvoiced")); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = _co.sessionService.role.IsFullAdmin; _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.leaveDialogVisible; _ck(_v, 12, 0, currVal_5); }, null); }
export function View_AccountantSummaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-accountant-summary", [], null, null, null, View_AccountantSummaryComponent_0, RenderType_AccountantSummaryComponent)), i1.ɵdid(1, 49152, null, 0, i14.AccountantSummaryComponent, [i11.AccountantSummaryService, i4.SessionService, i12.RouterStateService], null, null)], null, null); }
var AccountantSummaryComponentNgFactory = i1.ɵccf("app-accountant-summary", i14.AccountantSummaryComponent, View_AccountantSummaryComponent_Host_0, {}, {}, []);
export { AccountantSummaryComponentNgFactory as AccountantSummaryComponentNgFactory };
