<div class="Cards Cards--flex">
  <div class="Card Form--stacked">
    <div class="Card-inner">
      <div class="FormElement">
        <div class="grid-header Company-salaryTotalsGrid grid-click">
          <app-grid
            (selectedItemChange)="onSelectedUnitTotalChange($event)"
            [data]="UnitTotals"
            [idColumn]="'Key'"
            [(selectedItem)]="selectedUnitTotals"
            [editMode]="false"
            [pageable]="true"
            [pageSize]="100"
            [miniPager]="true"
          >
            <app-grid-column
              [field]="'Name'"
              [width]="200"
              [title]="'CompanySalaryBatches.TimeGridField_Name' | translate"
              [type]="'text'"
            ></app-grid-column>
            <app-grid-column
              [field]="'Amount'"
              [width]="100"
              [title]="'CompanySalaryBatches.TimeGridField_Amount' | translate"
              [type]="'numeric'"
            ></app-grid-column>
          </app-grid>
        </div>
      </div>
    </div>
  </div>

  <div class="Card Form--stacked">
    <div class="Card-inner">
      <div class="FormElement">
        <div class="FormElement-grid Company-salaryTotalsGrid">
          <app-grid [data]="unitTotalsPerEmployee" [editMode]="false" (dblClick)="onDoubleClick($event)">
            <app-grid-column
              [field]="'FullName'"
              [width]="200"
              [title]="'CompanySalaryBatches.TimeMiscDetailGridField_Name' | translate"
              [type]="'text'"
            ></app-grid-column>
            <app-grid-column
              [field]="'AmountSum'"
              [width]="100"
              [title]="'CompanySalaryBatches.TimeMiscDetailGridField_Value' | translate"
              [type]="'numeric'"
            ></app-grid-column>
          </app-grid>
        </div>
      </div>
    </div>
  </div>
</div>
