/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payslip-icon.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@progress/kendo-angular-tooltip";
import * as i3 from "@progress/kendo-angular-popup";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../icon/icon.component.ngfactory";
import * as i6 from "../icon/icon.component";
import * as i7 from "../../services/session/session.service";
import * as i8 from "@angular/common";
import * as i9 from "./payslip-icon.component";
var styles_PayslipIconComponent = [i0.styles];
var RenderType_PayslipIconComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PayslipIconComponent, data: {} });
export { RenderType_PayslipIconComponent as RenderType_PayslipIconComponent };
function View_PayslipIconComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "button", [["kendoTooltip", ""], ["type", "button"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 9125888, null, 0, i2.TooltipDirective, [i1.ElementRef, i1.NgZone, i1.Renderer2, i3.PopupService, [2, i2.TooltipSettings], [2, i2.TOOLTIP_SETTINGS]], null, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 4, "span", [], [[8, "title", 0]], null, null, null, null)), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-icon", [], null, null, null, i5.View_IconComponent_0, i5.RenderType_IconComponent)), i1.ɵdid(6, 49152, null, 0, i6.IconComponent, [i1.ElementRef, i7.SessionService, i1.ChangeDetectorRef], { icon: [0, "icon"], tooltip: [1, "tooltip"] }, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); var currVal_2 = "warning"; var currVal_3 = i1.ɵunv(_v, 6, 1, i1.ɵnov(_v, 7).transform("CompanySalaryBatches.WarningPayrollPreview")); _ck(_v, 6, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 2).transform("CompanySalaryBatches.WarningPayrollPreview")), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("CompanySalaryBatches.WarningPayrollPreview")), ""); _ck(_v, 3, 0, currVal_1); }); }
function View_PayslipIconComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "button", [["kendoTooltip", ""], ["type", "button"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 9125888, null, 0, i2.TooltipDirective, [i1.ElementRef, i1.NgZone, i1.Renderer2, i3.PopupService, [2, i2.TooltipSettings], [2, i2.TOOLTIP_SETTINGS]], null, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 4, "span", [], [[8, "title", 0]], null, null, null, null)), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-icon", [], null, null, null, i5.View_IconComponent_0, i5.RenderType_IconComponent)), i1.ɵdid(6, 49152, null, 0, i6.IconComponent, [i1.ElementRef, i7.SessionService, i1.ChangeDetectorRef], { icon: [0, "icon"], tooltip: [1, "tooltip"] }, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); var currVal_2 = "ErrorExclamation"; var currVal_3 = i1.ɵunv(_v, 6, 1, i1.ɵnov(_v, 7).transform("CompanySalaryBatches.ErrorPayrollPreview")); _ck(_v, 6, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 2).transform("CompanySalaryBatches.ErrorPayrollPreview")), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("CompanySalaryBatches.ErrorPayrollPreview")), ""); _ck(_v, 3, 0, currVal_1); }); }
function View_PayslipIconComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], [[8, "title", 0]], null, null, null, null)), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(2, 0, null, null, 2, "app-icon", [], null, null, null, i5.View_IconComponent_0, i5.RenderType_IconComponent)), i1.ɵdid(3, 49152, null, 0, i6.IconComponent, [i1.ElementRef, i7.SessionService, i1.ChangeDetectorRef], { icon: [0, "icon"], tooltip: [1, "tooltip"] }, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = "html-outline"; var currVal_2 = i1.ɵunv(_v, 3, 1, i1.ɵnov(_v, 4).transform("EmploymentPayrollData.HTMLPopupToolTip")); _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform("EmploymentPayrollData.HTMLPopupToolTip")), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_PayslipIconComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "button", [["kendoTooltip", ""], ["type", "button"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 9125888, null, 0, i2.TooltipDirective, [i1.ElementRef, i1.NgZone, i1.Renderer2, i3.PopupService, [2, i2.TooltipSettings], [2, i2.TOOLTIP_SETTINGS]], null, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 4, "span", [], [[8, "title", 0]], null, null, null, null)), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-icon", [], null, null, null, i5.View_IconComponent_0, i5.RenderType_IconComponent)), i1.ɵdid(6, 49152, null, 0, i6.IconComponent, [i1.ElementRef, i7.SessionService, i1.ChangeDetectorRef], { icon: [0, "icon"], tooltip: [1, "tooltip"] }, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); var currVal_2 = "html"; var currVal_3 = i1.ɵunv(_v, 6, 1, i1.ɵnov(_v, 7).transform("EmploymentPayrollData.HTMLFileToolTip")); _ck(_v, 6, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 2).transform("EmploymentPayrollData.HTMLFileToolTip")), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("EmploymentPayrollData.HTMLFileToolTip")), ""); _ck(_v, 3, 0, currVal_1); }); }
function View_PayslipIconComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "button", [["kendoTooltip", ""], ["type", "button"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 9125888, null, 0, i2.TooltipDirective, [i1.ElementRef, i1.NgZone, i1.Renderer2, i3.PopupService, [2, i2.TooltipSettings], [2, i2.TOOLTIP_SETTINGS]], null, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 4, "span", [], [[8, "title", 0]], null, null, null, null)), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-icon", [], null, null, null, i5.View_IconComponent_0, i5.RenderType_IconComponent)), i1.ɵdid(6, 49152, null, 0, i6.IconComponent, [i1.ElementRef, i7.SessionService, i1.ChangeDetectorRef], { icon: [0, "icon"], tooltip: [1, "tooltip"] }, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); var currVal_2 = "pdf"; var currVal_3 = i1.ɵunv(_v, 6, 1, i1.ɵnov(_v, 7).transform("CompanySalaryBatches.PaySlipPDFTooltip")); _ck(_v, 6, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 2).transform("CompanySalaryBatches.PaySlipPDFTooltip")), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("CompanySalaryBatches.PaySlipPDFTooltip")), ""); _ck(_v, 3, 0, currVal_1); }); }
function View_PayslipIconComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "button", [["kendoTooltip", ""], ["type", "button"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 9125888, null, 0, i2.TooltipDirective, [i1.ElementRef, i1.NgZone, i1.Renderer2, i3.PopupService, [2, i2.TooltipSettings], [2, i2.TOOLTIP_SETTINGS]], null, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 4, "span", [], [[8, "title", 0]], null, null, null, null)), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-icon", [], null, null, null, i5.View_IconComponent_0, i5.RenderType_IconComponent)), i1.ɵdid(6, 49152, null, 0, i6.IconComponent, [i1.ElementRef, i7.SessionService, i1.ChangeDetectorRef], { icon: [0, "icon"], tooltip: [1, "tooltip"] }, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); var currVal_2 = "file-print"; var currVal_3 = i1.ɵunv(_v, 6, 1, i1.ɵnov(_v, 7).transform("CompanySalaryBatches.PaySlipPrintTooltip")); _ck(_v, 6, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 2).transform("CompanySalaryBatches.PaySlipPrintTooltip")), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("CompanySalaryBatches.PaySlipPrintTooltip")), ""); _ck(_v, 3, 0, currVal_1); }); }
export function View_PayslipIconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "span", [], null, null, null, null, null)), i1.ɵprd(512, null, i8.ɵNgClassImpl, i8.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i8.NgClass, [i8.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PayslipIconComponent_1)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PayslipIconComponent_2)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "button", [["kendoTooltip", ""], ["type", "button"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 9125888, null, 0, i2.TooltipDirective, [i1.ElementRef, i1.NgZone, i1.Renderer2, i3.PopupService, [2, i2.TooltipSettings], [2, i2.TOOLTIP_SETTINGS]], null, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PayslipIconComponent_3)), i1.ɵdid(11, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PayslipIconComponent_4)), i1.ɵdid(13, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PayslipIconComponent_5)), i1.ɵdid(15, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PayslipIconComponent_6)), i1.ɵdid(17, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isHidden ? "Icon--Payslip--hide" : ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.showWarningTab; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.showErrorTab; _ck(_v, 6, 0, currVal_2); _ck(_v, 8, 0); var currVal_4 = _co.showInCurrentTab; _ck(_v, 11, 0, currVal_4); var currVal_5 = (_co.showInNewTab && _co.IsWeb); _ck(_v, 13, 0, currVal_5); var currVal_6 = _co.showPDF; _ck(_v, 15, 0, currVal_6); var currVal_7 = _co.showPrintTab; _ck(_v, 17, 0, currVal_7); }, function (_ck, _v) { var currVal_3 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 9).transform("EmploymentPayrollData.HTMLPopupToolTip")), ""); _ck(_v, 7, 0, currVal_3); }); }
export function View_PayslipIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payslip-icon", [], null, null, null, View_PayslipIconComponent_0, RenderType_PayslipIconComponent)), i1.ɵdid(1, 49152, null, 0, i9.PayslipIconComponent, [i7.SessionService], null, null)], null, null); }
var PayslipIconComponentNgFactory = i1.ɵccf("app-payslip-icon", i9.PayslipIconComponent, View_PayslipIconComponent_Host_0, { payslipIconType: "payslipIconType", isHidden: "isHidden" }, { click: "click" }, []);
export { PayslipIconComponentNgFactory as PayslipIconComponentNgFactory };
