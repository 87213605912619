import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from 'src/app/common/global';
import { GridCellClickEvent } from 'src/app/custom-controls/grid/cell-click-event';
import { IExternalAccount, ITimeEntryType, ITimeEntryTypeTranslation, IUnitType } from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { SettingService } from 'src/app/services/setting.service';
import { ReportDialogService } from 'src/app/shared-components/report-dialog/report-dialog.service';
import { TimeEntryTypeView } from './time-entry-type-view';
import { CompanyTimeEntryTypeService } from './time-entry-types.service';

@Component({
  selector: 'app-time-entry-types',
  templateUrl: './time-entry-types.component.html'
})
export class TimeEntryTypesComponent implements OnInit, OnDestroy {
  public unitTypes: IUnitType[] = Global.UNIT_TYPES;
  public externalAccounts: IExternalAccount[];
  public TimeEntryTypes: ITimeEntryType[];
  public selectedTimeEntryType: TimeEntryTypeView;
  public gridData: any[] = [];
  public translatetooltipsalarytype = '';

  public translationDialogVisible = false;
  public newCustomTimeEntryDialogVisible = false;
  public timeEntryTypeConfigurationDialogVisible = false;
  public hasTimeEntryModule = false;
  public triggerUpdate = false;
  public isWattingLoading = false;
  public numberOption: any = { min: undefined, step: 1, spinners: false, decimals: 0, format: 'n0' };

  public get isLanguageModuleEnable(): boolean {
    return this.sessionService.feature.hasModuleId(5);
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  @ViewChild('timeEntryTypesGrid', { static: false }) public timeEntryTypesGrid: ElementRef;

  constructor(
    private dataService: DataService,
    private staticDataService: StaticDataService,
    public translateService: TranslateService,
    public sessionService: SessionService,
    public settingService: SettingService,
    public reportDialogService: ReportDialogService,
    public companyTimeEntryTypeService: CompanyTimeEntryTypeService
  ) {
    this.companyTimeEntryTypeService.addNewShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && !this.sessionService.role.IsReadOnly) {
          this.newCustomTimeEntryDialogVisible = true;
        }
      });

    this.companyTimeEntryTypeService.showReportShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && this.reportDialogService.isVisibleReportIcon) {
          this.reportDialogService.onShowReportsEventClick();
        }
      });

    this.companyTimeEntryTypeService.timeEntryType
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: ITimeEntryType[]) => {
        this.gridData = data.map((d: ITimeEntryType) => new TimeEntryTypeView(d, this.isLanguageModuleEnable));
        this.gridData.forEach((model: any) => {
          this.processGridItemObject(model);
        });
        //this.convertExternalRefCodeToFriendlyName();
        this.gridFilterData = filterBy(this.gridData, this.filter);

        this.updateGridState();
      });
  }

  private updateGridState() {
    if (this.gridFilterData && this.gridFilterData.length) {
      this.triggerUpdate = true;
    }
  }

  public enableTranslation(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      const translates = ['CompanyTimeEntryTypes.SalaryTypeDisableToolTip', 'CompanySalaryTypes.CreateFromScratch'];
      this.translateService.get(translates).subscribe((translations: { [key: string]: string }) => {
        this.translatetooltipsalarytype = translations['CompanyTimeEntryTypes.SalaryTypeDisableToolTip'];

        this.dataService
          .TimeEntryTypes_GetDefaultTimeEntryTypes()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((data: ITimeEntryType[]) => {
            this.TimeEntryTypes = data;
            this.TimeEntryTypes.unshift({ Name: translations['CompanySalaryTypes.CreateFromScratch'], Id: -1 });
          });
      });
    });
  }

  public ngOnInit(): void {
    this.isWattingLoading = true;
    this.staticDataService.moduleCompanyView
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.loadTimeEntryTypes());

    this.enableTranslation();
    this.companyTimeEntryTypeService.loadSalaryTypes().subscribe(() => {
      this.updateGridState();
    });

    this.dataService
      .Integrations_GetAllExternalAccounts()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((externalAccounts: IExternalAccount[]) => {
        this.externalAccounts = externalAccounts;
        if (this.externalAccounts && this.externalAccounts.length > 0) {
          this.externalAccounts.forEach((model: any) => {
            model.FriendlyName = model.AccountNumber + (model.AccountName ? ' - ' + model.AccountName : '');
          });
        }
      });

    this.settingService.showModuleMessage('company.timeentrytypes');
    if (this.isMobile) {
      this.sessionService.isShowHugeFeaturesAlert = true;
    }
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.companyTimeEntryTypeService.serviceDestroy();

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public saveChanges(data: CellCloseEvent): void {
    //data.dataItem.ExternalReference = this.getExteralReferenceCode(data.dataItem.ExternalReference, true);
    const timeEntryType: any = JSON.parse(JSON.stringify(data.dataItem));
    //timeEntryType.ExternalReference = this.getExteralReferenceCode(timeEntryType.ExternalReference);
    this.ConvertCorrectSubmitData(timeEntryType);
    this.dataService.TimeEntryTypes_UpdateTimeEntryType(timeEntryType).subscribe(
      (response: ITimeEntryType) => {
        this.updateGridState();
        //response.ExternalReference = this.getExteralReferenceCode(response.ExternalReference, true);
        this.processGridItemObject(response);
        Object.assign(data.dataItem, new TimeEntryTypeView(response, this.isLanguageModuleEnable));
      },
      (error: any) => {
        this.loadTimeEntryTypes();
      }
    );
  }

  private ConvertCorrectSubmitData(model: any): void {
    const classCheck = 'grid-inherit-cell';
    model.IsActive =
      model.isActiveClass === classCheck && model.IsActive === model.BaseTimeEntryType.IsActive ? null : model.IsActive;
    model.ExternalReference =
      model.externalReferenceClass === classCheck &&
      model.ExternalReference === model.BaseTimeEntryType.ExternalReference
        ? null
        : model.ExternalReference;
    model.SortOrder =
      model.sortOrderClass === classCheck && model.SortOrder === model.BaseTimeEntryType.SortOrder
        ? null
        : model.SortOrder;
    model.UnitTypeId =
      model.unitTypeClass === classCheck && model.UnitTypeId === model.BaseTimeEntryType.UnitTypeId
        ? null
        : model.UnitTypeId;
    model.AllowEditUnitType =
      model.allowEditUnitTypeClass === classCheck &&
      model.AllowEditUnitType === model.BaseTimeEntryType.AllowEditUnitType
        ? null
        : model.AllowEditUnitType;
    model.IsIllness =
      model.isIllnessClass === classCheck && model.IsIllness === model.BaseTimeEntryType.IsIllness
        ? null
        : model.IsIllness;
    model.SalaryTypeId =
      model.salaryTypeClass === classCheck && model.SalaryTypeId === model.BaseTimeEntryType.SalaryTypeId
        ? null
        : model.SalaryTypeId;
    if (!this.isLanguageModuleEnable) {
      model.Name = model.nameClass === classCheck && model.Name === model.BaseTimeEntryType.Name ? null : model.Name;
      model.Description =
        model.descriptionClass === classCheck && model.Description === model.BaseTimeEntryType.Description
          ? null
          : model.Description;
      model.DefaultPayslipText =
        model.defaultPayslipTextClass === classCheck &&
        model.DefaultPayslipText === model.BaseTimeEntryType.DefaultPayslipText
          ? null
          : model.DefaultPayslipText;
    } else {
      let bseModelLanguge: any = {};
      let modelLanguge: any = {};

      if (
        model.BaseTimeEntryType &&
        model.BaseTimeEntryType.TimeEntryTypeTranslations &&
        model.BaseTimeEntryType.TimeEntryTypeTranslations.length > 0
      ) {
        bseModelLanguge = model.BaseTimeEntryType.TimeEntryTypeTranslations.find(
          (t: ITimeEntryTypeTranslation) => t.LanguageId === Global.SESSION.SignOnToken.LanguageId
        );
      } else {
        bseModelLanguge = {
          Name: null,
          Description: null,
          DefaultPayslipText: null
        };
      }

      if (model.TimeEntryTypeTranslations && model.TimeEntryTypeTranslations.length > 0) {
        modelLanguge = model.TimeEntryTypeTranslations.find(
          (t: ITimeEntryTypeTranslation) => t.LanguageId === Global.SESSION.SignOnToken.LanguageId
        );
      } else {
        modelLanguge = {
          Name: null,
          Description: null,
          DefaultPayslipText: null
        };
      }

      let isModelBaseTimeEntryTypeNull = false;

      if (!model.BaseTimeEntryType) {
        model.BaseTimeEntryType = {
          Name: null,
          Description: null,
          DefaultPayslipText: null
        };

        isModelBaseTimeEntryTypeNull = true;
      }

      model.Name =
        model.nameClass === classCheck &&
        (model.Name === bseModelLanguge.Name ||
          model.Name === modelLanguge.Name ||
          model.Name === model.BaseTimeEntryType.Name)
          ? null
          : model.Name;

      model.Description =
        model.descriptionClass === classCheck &&
        (model.Description === bseModelLanguge.Description ||
          model.Description === modelLanguge.Description ||
          model.Description === model.BaseTimeEntryType.Description)
          ? null
          : model.Description;

      model.DefaultPayslipText =
        model.defaultPayslipTextClass === classCheck &&
        (model.DefaultPayslipText === bseModelLanguge.DefaultPayslipText ||
          model.DefaultPayslipText === modelLanguge.DefaultPayslipText ||
          model.DefaultPayslipText === model.BaseTimeEntryType.DefaultPayslipText)
          ? null
          : model.DefaultPayslipText;

      model.BaseTimeEntryType = isModelBaseTimeEntryTypeNull ? null : model.BaseTimeEntryType;
    }
  }

  public addNewTimeEntryType(timeEntryType: ITimeEntryType): void {
    const newRecord: TimeEntryTypeView = new TimeEntryTypeView(timeEntryType, this.isLanguageModuleEnable);
    this.processGridItemObject(newRecord);
    this.gridData.push(newRecord);
    this.selectedTimeEntryType = newRecord;
    this.gridFilterData = filterBy(this.gridData, this.filter);
    this.scrollToNewRow();
  }

  public onCellClick(event: GridCellClickEvent): void {
    if (['Name', 'Description', 'DefaultPayslipText'].includes(event.field) && this.isLanguageModuleEnable) {
      this.selectedTimeEntryType = event.dataItem;
      this.translationDialogVisible = true;
    }

    if (event.field === 'configurationIcon') {
      this.timeEntryTypeConfigurationDialogVisible = true;
    }
  }

  public loadTimeEntryTypes(event?: any): void {
    if (event) {
      this.selectedTimeEntryType.TimeEntryTypeTranslations = event;
    }
    this.gridData = [];
    this.gridFilterData = [];

    this.companyTimeEntryTypeService.loadTimeEntryType().subscribe(
      () => {
        this.isWattingLoading = false;
      },
      () => {
        this.isWattingLoading = false;
      }
    );
  }

  private processGridItemObject(model: any): void {
    const existingTimeEntryType: any =
      this.gridData && model ? this.gridData.find((s: ITimeEntryType) => s.Id === model.Id) : undefined;
    model.TimeEntryTypeTranslations = model.TimeEntryTypeTranslations
      ? model.TimeEntryTypeTranslations
      : existingTimeEntryType
      ? existingTimeEntryType.TimeEntryTypeTranslations
      : undefined;
    model.BaseTimeEntryType = model.BaseTimeEntryType
      ? model.BaseTimeEntryType
      : existingTimeEntryType
      ? existingTimeEntryType.BaseTimeEntryType
      : undefined;

    if (model.TimeEntryTypeConfigurations) {
      if (model.TimeEntryTypeConfigurations.length > 0) {
        model.customSalaryTypeClass = 'grid-disable-cell';
        model.editSalaryType = false;
        model.ToolTipSalaryType = this.translatetooltipsalarytype;
        model.SalaryTypeId = null;
      } else {
        model.customSalaryTypeClass = '';
        model.editSalaryType = !this.IsReadOnly;
        model.ToolTipSalaryType = '';
      }
    } else {
      model.customSalaryTypeClass = '';
      model.editSalaryType = !this.IsReadOnly;
      model.ToolTipSalaryType = '';
    }
  }

  private convertExternalRefCodeToFriendlyName(): void {
    if (this.gridData && this.gridData.length > 0 && this.externalAccounts && this.externalAccounts.length > 0) {
      this.gridData.forEach((salaryType: any) => {
        salaryType.ExternalReference = this.getExteralReferenceCode(salaryType.ExternalReference, true);
      });
    }
  }

  private getExteralReferenceCode(refCode: string, fullName?: boolean): any {
    if (!refCode) {
      return null;
    }

    const externalRefCode: any = this.externalAccounts
      ? this.externalAccounts.find((item: any) => {
          return refCode === item.AccountNumber || refCode === item.FriendlyName;
        })
      : null;

    if (externalRefCode) {
      return !fullName ? externalRefCode.AccountNumber : externalRefCode.FriendlyName;
    }

    return refCode;
  }

  public filter: CompositeFilterDescriptor;
  public gridFilterData: any[] = this.gridData;
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridFilterData = filterBy(this.gridData, filter);
  }

  public scrollToNewRow(): void {
    setTimeout(() => {
      if (this.timeEntryTypesGrid && (this.timeEntryTypesGrid as any).kendoGrid) {
        const element = (this.timeEntryTypesGrid as any).kendoGrid.wrapper.nativeElement
          .querySelector('.k-grid-content')
          .querySelector('.k-state-selected') as HTMLElement;
        if (element) {
          element.scrollIntoView(false);
        }
      }
    });
  }

  public onTimeEntryTypeConfigurationVisibleChange(change: boolean) {
    if (change === true) {
      this.loadTimeEntryTypes();
    }
  }
}
