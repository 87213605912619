/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../icon/icon.component.ngfactory";
import * as i3 from "../icon/icon.component";
import * as i4 from "../../services/session/session.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./popup-button.component";
var styles_PopUpButtonComponent = [];
var RenderType_PopUpButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PopUpButtonComponent, data: {} });
export { RenderType_PopUpButtonComponent as RenderType_PopUpButtonComponent };
function View_PopUpButtonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "span", [["class", "buttonPopUpContent"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "app-icon", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.appIconClick(_v.context.$implicit.Action) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i0.ɵdid(4, 49152, null, 0, i3.IconComponent, [i0.ElementRef, i4.SessionService, i0.ChangeDetectorRef], { hostClass: [0, "hostClass"], icon: [1, "icon"], tooltip: [2, "tooltip"] }, { click: "click" }), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "buttonPopUpContent"; var currVal_1 = (_v.context.$implicit.IconPaysliphide ? "Icon--Payslip--hide" : ""); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "align-center"; var currVal_3 = _v.context.$implicit.Icon; var currVal_4 = i0.ɵunv(_v, 4, 2, i0.ɵnov(_v, 5).transform(_v.context.$implicit.tooltip)); _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4); }, null); }
export function View_PopUpButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_PopUpButtonComponent_1)), i0.ɵdid(1, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popupButtonIcon; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PopUpButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-popup-button", [], null, null, null, View_PopUpButtonComponent_0, RenderType_PopUpButtonComponent)), i0.ɵdid(1, 49152, null, 0, i6.PopUpButtonComponent, [i4.SessionService], null, null)], null, null); }
var PopUpButtonComponentNgFactory = i0.ɵccf("app-popup-button", i6.PopUpButtonComponent, View_PopUpButtonComponent_Host_0, { label: "label", icon: "icon", buttonClass: "buttonClass", tooltipContent: "tooltipContent", popupButtonIcon: "popupButtonIcon", isUseLabelAppIcon: "isUseLabelAppIcon", isHidden: "isHidden" }, { IconClick: "IconClick", ButtonClick: "ButtonClick" }, []);
export { PopUpButtonComponentNgFactory as PopUpButtonComponentNgFactory };
