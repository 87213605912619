import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { locale } from 'devextreme/localization';
import { environment } from '../environments/environment';
import { StaticDataService } from './services/api/static-data.service';
import { SessionService } from './services/session/session.service';

@Component({
  selector: 'app',
  template: `
    <ng-progress id="appProgress" [thick]="true" [spinner]="false" [color]="'#4350a3'"></ng-progress>
    <app-icon-definitions></app-icon-definitions>
    <div ui-view></div>
  `
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private titleService: Title,
    private renderer: Renderer2,
    private sessionService: SessionService,
    private staticDataService: StaticDataService
  ) {}

  public ngOnInit(): void {
    if (environment.environment !== 'PROD') {
      this.titleService.setTitle(environment.environment + ' ' + environment.branding.SystemAlias);
    } else {
      this.titleService.setTitle(environment.branding.SystemAlias);
    }

    if (this.sessionService.IsAuthenticated) {
      if (!environment.production) {
        console.log('Already logged in');
      }

      this.staticDataService.loadStaticData();
    }

    this.sessionService.OnLocationChanged.subscribe((state: string) => {
      if (!this.sessionService.IsAuthenticated) {
        if (!environment.production) {
          console.log('app cmp goto login');
        }

        this.sessionService.NavigateTo('login');
      }
      this.sessionService.checkUrlPermission();
    });
  }

  public ngAfterViewInit(): void {
    this.renderer.listen(window, 'keyboardWillShow', () => this.renderer.addClass(document.body, 'mobileIOSApp'));
    this.renderer.listen(window, 'keyboardDidHide', () => this.renderer.removeClass(document.body, 'mobileIOSApp'));
  }
}
