<li class="Dropdown-item" *ngIf="field === 'ImportData'">
  <button type="button" (click)="onMoreMenuOptionClick('companyGeneral_importData')">
    {{ 'CompanyGeneral.ImportData' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'TestEmails'">
  <button type="button" (click)="onMoreMenuOptionClick('companyGeneral_testMail')">
    {{ 'More.TestEmails' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'DeleteTemplate'">
  <button type="button" (click)="onMoreMenuOptionClick('companyTemplate_deleteTemplate')">
    {{ 'More.DeleteTemplate' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'Recalculate'">
  <button type="button" (click)="onMoreMenuOptionClick('salaryBatches_recalculateDraft')">
    {{ 'More.RecalculateDrafts' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'SalaryImportData'">
  <button type="button" (click)="onMoreMenuOptionClick('salaryBatches_importData')">
    {{ 'CompanySalaryBatches.ImportData' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'CreateTemplateFromEmployee' && hasUserEmployment">
  <button type="button" (click)="onMoreMenuOptionClick('employeeHiringStatus_createTemplate')">
    {{ 'More.CreateTemplateFromThisEmployment' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'AddSecondEmployee'">
  <button
    [disabled]="isAddNewEmploymentDisable"
    type="button"
    id="AddEmploymentButton"
    (click)="onMoreMenuOptionClick('employeeHiringStatus_addEmployment')"
  >
    {{ 'More.AddSecondEmployment' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'SynchronizeWithTemplate' && hasUserEmployment">
  <button type="button" (click)="onMoreMenuOptionClick('employeeHiringStatus_synchronizeWithTemplate')">
    {{ 'More.SynchronizeWithTemplate' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'DeleteEmployee'">
  <button type="button" (click)="onMoreMenuOptionClick('employeeGeneral_deleteEmployee')">
    {{ 'More.DeleteEmployee' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'ImportEmployee'">
  <button type="button" (click)="onMoreMenuOptionClick('employeeGeneral_importEmployees')">
    {{ 'More.ImportEmployees' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'ResendWelcomeEmail'">
  <button type="button" (click)="onMoreMenuOptionClick('employeeGeneral_ResendWelcomeEmail')">
    {{ 'More.ResendWelcomeEmail' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'ResetPassword'">
  <button type="button" (click)="onMoreMenuOptionClick('employeeGeneral_ResetPassword')">
    {{ 'More.ResetPassword' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'ImportInitialBalances'">
  <button type="button" (click)="onMoreMenuOptionClick('employeeGeneral_importInitialBalances')">
    {{ 'More.ImportInitialBalances' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'AttachExistingCompany'">
  <button type="button" (click)="onMoreMenuOptionClick('accountant_attachexistingcompany')">
    {{ 'Accountant.AttachExistingCompany' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'RemoveUser'">
  <button type="button" (click)="onMoreMenuOptionClick('accountant_removeuser')">
    {{ 'Accountant.RemoveUser' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'RemoveCompany'">
  <button type="button" (click)="onMoreMenuOptionClick('accountant_removecompany')">
    {{ 'Accountant.RemoveCompany' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'AddNewEmployee'">
  <button type="button" (click)="onMoreMenuOptionClick('addnewemployee')">
    {{ 'Employee.NewEmployee' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'Reports'">
  <button type="button" (click)="onMoreMenuOptionClick('report')">
    {{ 'Employee.Reports' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'AddNewRecord'">
  <button type="button" (click)="onMoreMenuOptionClick('addnewrecord')">
    {{ 'EmploymentTime.AddNewRecord' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'SalaryBatchAddNew'">
  <button type="button" (click)="onMoreMenuOptionClick('salarybatchaddnew')">
    {{ 'CompanySalaryBatches.SalaryBatchAddNewButton' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'CompanyAddNew'">
  <button type="button" (click)="onMoreMenuOptionClick('companyaddnew')">
    {{ 'Company.New' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'CompanyReports'">
  <button type="button" (click)="onMoreMenuOptionClick('companyreport')">
    {{ 'Company.Reports' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'SalaryTypeNew'">
  <button type="button" (click)="onMoreMenuOptionClick('salarytypenew')">
    {{ 'CompanySalaryBatches.AddSalaryTypeText' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'TimeEntryTimeNew'">
  <button type="button" (click)="onMoreMenuOptionClick('timeentrytimenew')">
    {{ 'CompanyTimeEntryTypes.AddTimeEntryTypeText' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'AccountantAddNewCompany'">
  <button type="button" (click)="onMoreMenuOptionClick('accountantaddnewcompany')">
    {{ 'Accountant.AddNewCompany' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'AccountantAddNewUser'">
  <button type="button" (click)="onMoreMenuOptionClick('accountantaddnewuser')">
    {{ 'Accountant.AddNewUser' | translate }}
  </button>
</li>

<!-- Help button -->
<app-walkthrough *ngIf="field === 'welcomeMessage'"></app-walkthrough>
<li class="Dropdown-item" *ngIf="field === 'SystemHelp' && !isIOSMobileApp">
  <a [href]="helpLink" target="_blank">{{ 'Help.ViewOnlineHelp' | translate }}</a>
</li>
<li class="Dropdown-item" *ngIf="field === 'SystemHelp' && !isIOSMobileApp && LanguageId == 1 && isEmployee">
  <a *ngIf="!isGreenlandCompany" href="https://www.intect.dk/da/kontakt/" target="_blank">
    {{ 'Help.Contact' | translate: { SystemAlias: branding.SystemAlias } }}
  </a>
  <a *ngIf="isGreenlandCompany" href="https://intect.gl/" target="_blank">
    {{ 'Help.Contact' | translate: { SystemAlias: branding.SystemAlias } }}
  </a>
</li>
<li class="Dropdown-item" *ngIf="field === 'SystemHelp' && !isIOSMobileApp && LanguageId == 2 && isEmployee">
  <a *ngIf="!isGreenlandCompany" href="https://www.intect.dk/en/contact/" target="_blank">
    {{ 'Help.Contact' | translate: { SystemAlias: branding.SystemAlias } }}
  </a>
  <a *ngIf="isGreenlandCompany" href="https://intect.gl/" target="_blank">
    {{ 'Help.Contact' | translate: { SystemAlias: branding.SystemAlias } }}
  </a>
</li>
<li class="Dropdown-item" *ngIf="field === 'SystemHelp' && !isIOSMobileApp && LanguageId == 3 && isEmployee">
  <a *ngIf="!isGreenlandCompany" href="https://www.intect.dk/da/kontakt/" target="_blank">
    {{ 'Help.Contact' | translate: { SystemAlias: branding.SystemAlias } }}
  </a>
  <a *ngIf="isGreenlandCompany" href="https://intect.gl/" target="_blank">
    {{ 'Help.Contact' | translate: { SystemAlias: branding.SystemAlias } }}
  </a>
</li>
<li class="Dropdown-item" *ngIf="field === 'SystemHelp' && !isIOSMobileApp && !isEmployee">
  <button type=" button" (click)="onShowZenDesk()">
    {{ 'Help.Contact' | translate }}
  </button>
</li>
<li class="Dropdown-item" *ngIf="field === 'SystemHelp' && !isIOSMobileApp">
  <a href="{{ 'Login.UpdatedMessageUrl' | translate: { SystemAlias: branding.SystemAlias } }}" target="_blank">
    {{ 'Help.WhatNew' | translate }}
  </a>
</li>
