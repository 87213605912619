import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmployeeService } from 'src/app/services/employee.service';
import { UniqueId } from '../../../common/unique-id';
import { ImportButtonAction, ImportTypesEnum } from '../../../model/enum';
import {
  IImportMapping,
  IImportRequest,
  IImportType,
  ImportMapping,
  IPreviewRequest,
  ISimpleKeyValuePair
} from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { SessionService } from '../../../services/session/session.service';
import { ImportOptionHelper } from './import-option-helper';

@Component({
  selector: 'app-upload-import-file-dialog',
  templateUrl: './upload-import-file-dialog.component.html',
  styleUrls: ['./upload-import-file-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UploadImportFileDialogComponent implements OnInit, OnDestroy {
  @Input() public importType: ImportTypesEnum;

  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
      if (value) {
        this.IsPreviewExpanded = false;
        this.showDataImportDialog();
        this.updateSpecificationOptions();
      }
    }
  }
  @Output() public importMappingChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public replaceAllWarningVisible = false;
  public oldOption: any;
  public showEmptyMessage = false;
  public previewDataHeader: any;
  public previewDataResponse: any;
  public mappingDetail: IImportMapping = new ImportMapping();
  public mappingName: string;
  public formData: FormData;
  public optionsRadioButtonName: string;
  public importResults: any;
  public importOptions: any;
  public selectedMappingId: number;
  public selectedMapping: IImportMapping;
  public existingMappingsForCurrentImportType: IImportMapping[] = [];
  public startIndex: number;
  public showImportMappingDialog: boolean;
  public uploadFileName: string;
  public confirmDeleteDialogVisible = false;
  public confirmReplaceDialogVisible = false;
  public successDialogVisible = false;
  public resultfotalMessage: string;
  public successDialogFotalVisible = false;
  public importTypes: IImportType[] = [];
  public overlayVisible = false;

  public get addonReadMoreVisibility(): boolean {
    return this.importType === ImportTypesEnum.Payroll && this.selectedMappingId < 0;
  }
  public get intectStandardTemplateSelected(): boolean {
    if (this.selectedMapping) {
      return this.selectedMapping.CompanyId === null;
    }
    return false;
  }
  public get importPayrollDataOptionsVisible(): boolean {
    return false;
  }
  public get disableEditDefinitionButton(): boolean {
    return this.intectStandardTemplateSelected || !this.formData;
  }
  public get importButtonEnable(): boolean {
    return !!this.formData;
  }

  private payrollImportRowOperationId = 5;
  private timeEntriesImportRowOperationId = 6;
  private allCustomMappings: IImportMapping[];
  private previousMode: any;
  private addonLinkEmployees = 'http://support.gratisal.com/hc/da/articles/115003261669';
  private addonLinkBalances = 'http://support.gratisal.com/hc/da/articles/115003280345 ';
  private addonLinkPayroll =
    'https://support.gratisal.com/hc/da/articles/115003178565-Import-af-l%C3%B8ndata-fra-Excel';
  private confirmReplaceParameter: any;
  private confirmReplaceValue: any;
  public formDataString: string;
  private isNothingImport: boolean;

  constructor(
    private dataService: DataService,
    public sessionService: SessionService,
    private staticDataService: StaticDataService,
    private employeeService: EmployeeService
  ) {}

  public ngOnInit(): void {
    this.staticDataService.ImportTypes.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IImportType[]) => {
      this.importTypes = data;
      this.updateSpecificationOptions();
    });
    this.visibleChange.subscribe(() => (this.uploadFileName = ''));
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onDialogAction(action: string): void {
    switch (action) {
      case 'Edit':
        this.onEditClicked();
        break;
      case 'Delete':
        this.confirmDeleteDialogVisible = true;
        break;
      case 'Import':
        this.importMapping(ImportButtonAction.Import);
        break;
    }
  }

  public get ImportTypeForMappingEditor(): ImportTypesEnum {
    if (this.importType === ImportTypesEnum.Payroll) {
      if (this.SelectedMapping && this.SelectedMapping.TypeId === this.payrollImportRowOperationId) {
        return ImportTypesEnum.PayrollRow;
      }
    } else if (this.importType === ImportTypesEnum.TimeEntriesRow) {
      if (this.SelectedMapping && this.SelectedMapping.TypeId === this.timeEntriesImportRowOperationId) {
        return ImportTypesEnum.TimeEntriesRow;
      }
    }

    return this.importType;
  }

  public get SelectedMapping(): IImportMapping {
    if (
      this.selectedMappingId > 0 &&
      this.existingMappingsForCurrentImportType &&
      this.existingMappingsForCurrentImportType.length > 0
    ) {
      return this.existingMappingsForCurrentImportType.find((mapp: IImportMapping) => {
        return mapp.Id === this.selectedMappingId;
      });
    }

    return undefined;
  }

  public get addonLinks(): string {
    switch (this.importType) {
      case ImportTypesEnum.Employees:
        return this.addonLinkEmployees;
      case ImportTypesEnum.Initial_Balances:
        return this.addonLinkBalances;
      case ImportTypesEnum.Payroll:
        return this.addonLinkPayroll;
      default:
        return this.addonLinkEmployees;
    }
  }

  public get OptionsRadioButtonName(): string {
    if (!this.optionsRadioButtonName) {
      this.optionsRadioButtonName = 'optionsRadioButtonName_' + UniqueId();
    }

    return this.optionsRadioButtonName;
  }

  public get isLargeDialog(): boolean {
    return this.previewDataHeader && this.previewDataHeader.length > 5 ? true : false;
  }

  public onSaveMapingEvent(): void {
    this.loadExistingMappingComboboxDataSource();
  }

  public onCompleteImportEvent(): void {
    if (!this.isNothingImport) {
      this.visible = false;
      return;
    }
    this.importMapping(ImportButtonAction.CompleteImport);
  }

  public closeDialogAndFireClosedEvent(): void {
    if (this.importType === ImportTypesEnum.Employees) {
      this.employeeService.loadEmployees();
    }
    this.visible = false;
  }

  public onComboboxSelectMappingChanged(): void {
    this.selectedMapping = this.existingMappingsForCurrentImportType.find((mapping: IImportMapping) => {
      return mapping.Id === this.selectedMappingId;
    });
    this.showPreviewFile();
  }

  public onConfirmDeleteDialogAction(action: string): void {
    if (action === 'Delete') {
      this.dataService.ImportMapping_DeleteMapping(this.selectedMappingId).subscribe(() => {
        this.importMappingChange.emit();
        this.onDeleteMapping(this.selectedMappingId);
      });
    }
  }

  public onConfirmReplaceDialogAction(action: string): void {
    if (action === 'Understood') {
      if (this.confirmReplaceParameter === 'mode') {
        this.previousMode = this.confirmReplaceValue;
      }
    } else {
      const mode: any = this.importOptions.find((option: any) => option.ParameterValue === 'mode');
      const index: number = this.importOptions.indexOf(mode);
      mode.ControlValue = this.previousMode
        ? this.previousMode
        : mode.Options && mode.Options[0]
        ? mode.Options[0].RadioValue
        : undefined;
      this.importOptions[index] = mode;
    }
  }

  public onImportOptionsChanged(parameter: any, value: any): void {
    if (ImportOptionHelper.getOptionsString(this.importOptions) === 'mode=replaceall') {
      this.confirmReplaceParameter = parameter;
      this.confirmReplaceValue = value;
      this.confirmReplaceDialogVisible = true;
    } else {
      if (parameter === 'mode') {
        this.previousMode = value;
      }
    }
  }

  public onFileSelected(file: File): void {
    if (file) {
      this.formData = new FormData();
      this.formData.append('file', file, file.name);
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result as string;
        this.formDataString = base64.split('base64,')[1];
        this.showPreviewFile();
      };

      reader.readAsDataURL(file);
    }
  }

  private onEditClicked(): void {
    this.dataService.ImportMapping_LoadMapping(this.selectedMappingId).subscribe((mapping: IImportMapping) => {
      this.mappingName = mapping.Name;
      this.startIndex = mapping.StartRow;
      this.mappingDetail = mapping;
      this.showImportMappingDialog = true;
    });
  }

  private loadExistingMappingComboboxDataSource(defaultMappingId?: number): void {
    this.getExistingMappingDataSource().then((importMappings: IImportMapping[]) => {
      this.setExistingMappingDataSource(importMappings, defaultMappingId);
      this.showPreviewFile();
    });
  }

  private getExistingMappingDataSource(): Promise<IImportMapping[]> {
    return new Promise<IImportMapping[]>((resolve: (value: any) => void, reject: (value: any) => void) => {
      this.getMappingsForImportType().then((result: IImportMapping[]) => {
        return resolve(result);
      });
    });
  }

  private getMappingsForImportType(customTemplate?: boolean, action?: ImportButtonAction): Promise<IImportMapping[]> {
    return new Promise<IImportMapping[]>((resolve: (value: any) => void, reject: (value: any) => void) => {
      this.dataService.ImportMapping_CompanyMappings().subscribe(
        (existingMappings: IImportMapping[]): void => {
          this.allCustomMappings = existingMappings;
          let result: IImportMapping[] = [];
          if (existingMappings) {
            result = existingMappings.filter((item: IImportMapping) => {
              if ((this.importType === 4 && item.TypeId === 5) || item.TypeId === this.importType) {
                return true;
              }
              return false;
            });
          }

          if (customTemplate) {
            this.performImportCustomTemplate(action);
          }

          setTimeout(() => {
            this.overlayVisible = false;
          });

          return resolve(result);
        },
        () => {
          setTimeout(() => {
            this.overlayVisible = false;
          });
        }
      );
    });
  }

  private getStandardTemplateMappingId(): number {
    const importMapping: IImportMapping = this.allCustomMappings.find(
      (item: IImportMapping): boolean => !item.CompanyId
    );
    return importMapping ? importMapping.Id : -1;
  }

  private setExistingMappingDataSource(importMappings: IImportMapping[], defaultMappingId?: number): void {
    this.existingMappingsForCurrentImportType = [];
    this.selectedMappingId = undefined;
    if (importMappings.length === 0) {
      return;
    }

    this.existingMappingsForCurrentImportType = importMappings;
    this.selectedMappingId = defaultMappingId ? defaultMappingId : this.existingMappingsForCurrentImportType[0].Id;
  }

  private showDataImportDialog(): void {
    this.mappingName = undefined;
    this.formData = undefined;
    this.previewDataResponse = this.previewDataHeader = undefined;
    this.showEmptyMessage = false;
    this.importOptions = undefined;
    this.loadExistingMappingComboboxDataSource();
  }

  private importMapping(action: ImportButtonAction): void {
    this.overlayVisible = true;
    if (this.selectedMappingId === -1) {
      this.performImportCustomTemplate(action);
    } else {
      this.importCustomTemplate(action);
    }
  }

  private importCustomTemplate(action: ImportButtonAction): void {
    const mappingId: number = this.selectedMappingId;
    if (mappingId === -2) {
      this.getMappingsForImportType(true, action);
    } else {
      this.performImportCustomTemplate(action);
    }
  }

  private performImportCustomTemplate(action: ImportButtonAction): void {
    const optionString: ISimpleKeyValuePair[] = ImportOptionHelper.getImportOptionsString(this.importOptions, action);
    const importRequest: IImportRequest = {
      FileBase64: this.formDataString,
      MappingId: this.selectedMappingId,
      Options: optionString
    };
    this.dataService.Import_CustomImportExcelOptions(importRequest).subscribe(
      (result: any) => {
        this.onImportDataCompleted(result, action);
        this.isNothingImport = this.validateImportResult(result);

        setTimeout(() => {
          this.overlayVisible = false;
        });
      },
      () => {
        setTimeout(() => {
          this.overlayVisible = false;
        });
      }
    );
  }

  private onDeleteMapping(mappingId: number): void {
    this.loadExistingMappingComboboxDataSource();
  }

  private onImportDataCompleted(result: any, action: ImportButtonAction): void {
    if (action === ImportButtonAction.CompleteImport) {
      this.visible = false;
      if (result.FatalMessage) {
        this.resultfotalMessage = result.FatalMessage;
        this.successDialogFotalVisible = true;
        this.employeeService.loadEmployees();
        return;
      }
      this.successDialogVisible = true;
      this.employeeService.loadEmployees();
      return; //// don't show errors or warnings for complete import
    }

    if (!this.hasError) {
      this.successDialogVisible = true;
      this.employeeService.loadEmployees();
      return;
    }

    this.importResults = result;
  }

  private hasError(result: any): boolean {
    return result.Errors && result.Errors.length > 0;
  }

  private showPreviewFile(): void {
    if (!this.formData) {
      this.resetPreivewOfFile();
      return;
    }

    switch (this.selectedMappingId) {
      case -1:
        if (this.importType !== ImportTypesEnum.Payroll && this.importType !== ImportTypesEnum.TimeEntriesRow) {
          this.getPreviewOfFile(this.getStandardTemplateMappingId());
        } else {
          this.resetPreivewOfFile();
        }

        break;
      case -2:
        this.getMappingsForImportType();
        break;
      default:
        this.getPreviewOfFile(this.selectedMappingId);
        break;
    }
  }

  public IsPreviewExpanded = false;

  private getPreviewOfFile(mappingId: number): void {
    if (!mappingId || !this.formDataString) {
      return;
    }
    const previewRequest: IPreviewRequest = {
      Size: 4,
      FileBase64: this.formDataString,
      MappingId: mappingId
    };
    this.dataService.Import_GetPreviewOfFileImport(previewRequest).subscribe((response: string[]) => {
      if (!response || response.length <= 0) {
        this.showEmptyMessage = true;
        return;
      }

      this.showEmptyMessage = false;
      this.previewDataHeader = response.shift();
      this.previewDataResponse = response;
      if (this.previewDataHeader && this.previewDataHeader.length > 4) {
        this.IsPreviewExpanded = true;
      }
    });
  }

  private resetPreivewOfFile(): void {
    this.IsPreviewExpanded = false;
    this.previewDataResponse = this.previewDataHeader = undefined;
  }

  private updateSpecificationOptions(): void {
    const importTypeModel = this.importTypes.find((importT) => {
      return importT.Id === this.importType;
    });
    if (importTypeModel && importTypeModel.RelatedOptions && importTypeModel.RelatedOptions.length > 1) {
      this.importOptions = ImportOptionHelper.createImportOptionObjects(importTypeModel.RelatedOptions[1].Values);
    }
  }

  public warningText: string;
  public onRadioOptionChange(value: any): void {
    this.importOptions.forEach((option: any) => {
      if (option.Selected) {
        this.oldOption = option;
      }

      const optionElement = document.getElementsByClassName(option.ControlValue)[0] as HTMLInputElement;
      if (option.ControlValue === value.ControlValue) {
        option.Selected = true;
        if (optionElement) {
          optionElement.checked = true;
        }
      } else {
        option.Selected = false;
        if (optionElement) {
          optionElement.checked = false;
        }
      }

      if (!value.WarningText && typeof value.WarningText === 'string' && !this.replaceAllWarningVisible) {
        this.replaceAllWarningVisible = true;
        this.warningText = value.WarningText;
      }
    });
  }

  public onDialogActionWarning(action: string): void {
    switch (action) {
      case 'Cancel':
        if (this.oldOption) {
          this.onRadioOptionChange(this.oldOption);
          this.oldOption = null;
        }
        break;
      default:
        this.replaceAllWarningVisible = false;
        break;
    }
  }

  private validateImportResult(results: any): boolean {
    const isNothingImports = results.Errors.filter((result: any) => {
      return result.TranslationEntity.Key === 'NothingImported';
    });

    return isNothingImports.length > 0 ? false : true;
  }

  public onImportMappingChange() {
    this.importMappingChange.emit();
  }

  public onClosedMappingDialog() {
    this.visible = false;
  }
}
