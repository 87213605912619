<app-combobox-edit
  [disable]="employeeService.editMode"
  [editMode]="true"
  [textField]="'Text'"
  [idField]="'Id'"
  id="comboboxTemplate"
  [comboboxDataSource]="listSource"
  [(value)]="employeeService.templateId"
  [keepDataSourceOrder]="true"
></app-combobox-edit>
