<div [ngClass]="{ 'with-employeeFilter': showEmployeeList }">
  <app-tab-panel [parentTab]="'employee'"></app-tab-panel>
  <div
    [ngClass]="editMode ? 'is-editing' : ''"
    (dblclick)="PreonDoubleClick($event)"
    (click)="clickAreamenuToggledChange($event)"
  >
    <div
      class="Main Scrollable"
      id="divEmployeeTabs"
      tabindex="-1"
      [ngClass]="{ employeetimeBackground: employeeTimeTabVisible && isMobile }"
    >
      <!-- EmployeeView -->
      <div class="EmployeeView">
        <app-employee-general
          *ngIf="employeeGeneralTabVisible"
          [companyUser]="employeeService.employee | async"
          [userEmployment]="employeeService.employment | async"
          [editMode]="editMode"
          [(isValid)]="isTabsValid"
          [(isDirty)]="isDirty"
          [ngClass]="editMode ? 'is-edit-mode' : ''"
        ></app-employee-general>

        <app-employee-hiring-status
          *ngIf="employeeHiringStatusTabVisible"
          [companyUser]="employeeService.employee | async"
          [userEmployment]="employeeService.employment | async"
          [editMode]="editMode"
          [(isValid)]="isTabsValid"
          [(isDirty)]="isDirty"
          [ngClass]="editMode ? 'is-edit-mode' : ''"
        >
        </app-employee-hiring-status>

        <app-employee-time
          *ngIf="employeeTimeTabVisible"
          [currentCompanyUser]="employeeService.employee | async"
          [userEmployment]="employeeService.employment | async"
        ></app-employee-time>

        <app-employee-payroll-data
          *ngIf="employeePayrollDataTabVisible"
          [currentCompanyUser]="employeeService.employee | async"
          [userEmployment]="employeeService.employment | async"
        >
        </app-employee-payroll-data>

        <app-payslip *ngIf="employeePayslipTabVisible" [employee]="employeeService.employee | async"></app-payslip>
      </div>
      <!-- /EmployeeView -->

      <div class="Actions" [ngClass]="isIOSApp ? 'is-ios-app' : ''" *ngIf="!editMode">
        <app-menu-button
          *ngIf="reportDialogService.isVisibleReportIcon && !isMobile"
          [menuCondition]="true"
          [menuIcon]="'File'"
          [menuLabel]="'Employee.Reports'"
          [menuTooltip]="'Employee.Reports'"
          [actionButtonMenu]="true"
          [menuButtonClass]="'Action-button Action-buttonReports'"
          [menuButtonId]="'ActionButtonReports'"
          (menuClick)="reportDialogService.onShowReportsEventClick()"
        >
        </app-menu-button>

        <app-menu-button
          *ngIf="!employeeService.isNonSelected"
          [editButtonMenu]="true"
          [editMode]="editMode"
          (menuChangeClick)="onEditModeChange($event)"
          [menuCondition]="allowEditUserEmployment"
        >
        </app-menu-button>
      </div>

      <div class="EditingInfo Actions" *ngIf="editMode">
        <app-button
          [buttonClass]="'Discard'"
          (clickEvent)="confirmAndDiscardChanges()"
          [label]="'Employee.DiscardButton' | translate"
          *ngIf="editMode"
        ></app-button>
        <app-menu-button
          [editButtonMenu]="true"
          [editMode]="true"
          (menuChangeClick)="onEditModeChange($event)"
          [menuCondition]="true"
          class="menu-button--editing"
        >
        </app-menu-button>
      </div>
    </div>
  </div>
</div>

<app-dialog
  (action)="discardDialogAction($event)"
  [(visible)]="discardDialogVisible"
  [leadingText]="'Employee.DiscardMessage' | translate"
>
  <app-dialog-action type="CancelDiscard"></app-dialog-action>
  <app-dialog-action type="ConfirmDiscard"></app-dialog-action>
</app-dialog>

<!-- <app-dialog
  (action)="onUnsavedDataDialogAction($event)"
  [(visible)]="unsavedDataDialogVisible"
  [leadingText]="'Employee.UnsavedData' | translate"
>
  <app-dialog-action type="DiscardAndLeave"></app-dialog-action>
  <app-dialog-action type="Continue"></app-dialog-action>
</app-dialog> -->

<app-dialog
  (action)="onSynchTemplateDialogAction($event)"
  [(visible)]="synchTemplateDialogVisible"
  [leadingText]="'Employee.SyncTemplateBaseOnTemplate' | translate"
>
  <app-dialog-action type="No"></app-dialog-action>
  <app-dialog-action type="Yes"></app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" [(visible)]="showInvalidFormWarning">
  <div [innerHTML]="'Validation.InvalidForm' | translate"></div>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>

<app-dialog (action)="onConfirmSaveDataOnChangeState($event)" [(visible)]="showSaveDataConfirmationChangeState">
  <div [innerHTML]="'Employee.UnsavedData' | translate"></div>
  <app-dialog-action type="DiscardAndLeave"></app-dialog-action>
  <app-dialog-action type="Stay"></app-dialog-action>
  <app-dialog-action type="ContinueAndSave"></app-dialog-action>
</app-dialog>

<app-report-dialog
  [(visible)]="reportDialogService.reportDialogVisible"
  [key]="reportDialogService.key"
  [param]="reportDialogService.reportParams"
>
</app-report-dialog>
