import { OnInit } from '@angular/core';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { CompanyPreferenceService } from 'src/app/shared-components/preference/preference.service';
import { CompanyDepartmentService } from '../../departments/company-department.service';
import { CompanyTemplatesService } from '../../templates/templates.service';
import { DimensionsService } from '../dimensions.service';
var DimensionDetailComponent = /** @class */ (function () {
    function DimensionDetailComponent(sessionService, dimensionsService, companyPreferenceService, companyTemplateService, companyDepartmentService, staticDataService) {
        this.sessionService = sessionService;
        this.dimensionsService = dimensionsService;
        this.companyPreferenceService = companyPreferenceService;
        this.companyTemplateService = companyTemplateService;
        this.companyDepartmentService = companyDepartmentService;
        this.staticDataService = staticDataService;
        this.editMode = this.sessionService.role.IsSalaryAdmin || this.sessionService.role.IsFullAdmin;
        this.templates = [];
        this.companyusers = [];
        this.dimensionNamingVisible = false;
        this.removeDimensionDialogVisible = false;
    }
    Object.defineProperty(DimensionDetailComponent.prototype, "selectedId", {
        get: function () {
            return this.selectedIdValue;
        },
        set: function (value) {
            if (value && this.selectedIdValue !== value) {
                this.selectedIdValue = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DimensionDetailComponent.prototype, "isEnableDimension", {
        get: function () {
            switch (this.dimensionsService.currentDimensionNumber) {
                case 1:
                    return !this.dimensionsService.dimension1Active;
                case 2:
                    return !this.dimensionsService.dimension2Active;
                case 3:
                    return !this.dimensionsService.dimension3Active;
                default:
                    return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DimensionDetailComponent.prototype, "isShowCompanyUser", {
        get: function () {
            return this.sessionService.feature.hasModuleId(3);
        },
        enumerable: true,
        configurable: true
    });
    DimensionDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.companyTemplateService.loadTemplates();
        this.companyDepartmentService.loadDepartment();
        this.staticDataService.companyUsers.subscribe(function () {
            _this.dimensionsService.triggerUpdate = true;
        });
    };
    DimensionDetailComponent.prototype.activeDimension = function () {
        var _this = this;
        try {
            var item = this.dimensionsService.currentPref;
            item.Value = this.dimensionsService.dimensionNaming;
            var itemToSubmit = { Key: item.Key, Value: item.Value };
            this.companyPreferenceService.updateCompanySetting(itemToSubmit).subscribe(function () {
                _this.dimensionNamingVisible = false;
                _this.dimensionsService.updateDimensionLabel();
                _this.dimensionsService.dimensionNaming = '';
                _this.activeDimensionBoolean(true);
            });
        }
        catch (err) {
            this.dimensionsService.dimensionNaming = '';
        }
    };
    DimensionDetailComponent.prototype.activeDimensionBoolean = function (isActive) {
        switch (this.dimensionsService.currentDimensionNumber) {
            case 1:
                this.dimensionsService.dimension1Active = isActive;
                break;
            case 2:
                this.dimensionsService.dimension2Active = isActive;
                break;
            case 3:
                this.dimensionsService.dimension3Active = isActive;
                break;
        }
    };
    DimensionDetailComponent.prototype.gridAction = function (action) {
        switch (action) {
            case 'AddNew':
                this.createNewDimension();
                break;
            case 'Delete':
                this.deleteDimension();
                break;
        }
    };
    DimensionDetailComponent.prototype.createNewDimension = function () {
        this.dimensionsService.createDimension();
    };
    DimensionDetailComponent.prototype.updateDimension = function (item) {
        this.dimensionsService.updateDimension();
    };
    DimensionDetailComponent.prototype.deleteDimension = function () {
        this.removeDimensionDialogVisible = true;
    };
    DimensionDetailComponent.prototype.onRemoveDimension = function (action) {
        if (action === 'Delete') {
            this.dimensionsService.deleteDimension();
        }
    };
    DimensionDetailComponent.prototype.onFilterChange = function (filter) { };
    DimensionDetailComponent.prototype.gridDblclick = function (event) { };
    DimensionDetailComponent.prototype.onSelectedItemChange = function (item) {
        if (item) {
            this.dimensionsService.currentDimension = item;
            this.dimensionsService.currentDimensionId = item.Id;
        }
    };
    return DimensionDetailComponent;
}());
export { DimensionDetailComponent };
