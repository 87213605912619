import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-company-balances',
  templateUrl: './company-balances.component.html',
  styleUrls: ['./company-balances.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyBalancesComponent implements OnInit {
  public isCollapseButtonClick = false;
  public menuOpen = false;
  public listClassExcluded = ['Footer', '10'];

  constructor(public sessionService: SessionService) {}

  public get ResidualVacationIsActive(): boolean {
    return this.sessionService.currentState === 'tabs.company.balances.residualvacation';
  }

  public get ResidualExtravacationhoursIsActive(): boolean {
    return this.sessionService.currentState === 'tabs.company.balances.extravacationhours';
  }

  public ngOnInit(): void {}

  public onToggleNavigation(): void {
    this.isCollapseButtonClick = true;
    this.menuOpen = !this.menuOpen;
  }

  public changeState(state: string = 'tabs.company.balances.residualvacation') {
    this.menuOpen = false;
    this.sessionService.NavigateTo(state);
  }
}
