import { Component, Input } from '@angular/core';
import { EmployeeService } from 'src/app/services/employee.service';
import {
  ISalaryBatchEmployeeTotal,
  ISalaryBatchUnitTotal,
  ISalaryTypeSumsByEmploymentView
} from '../../../../services/api/api-model';
import { DataService } from '../../../../services/api/data.service';
import { SessionService } from '../../../../services/session/session.service';

@Component({
  selector: 'app-salary-batch-time-and-vacation',
  templateUrl: './salary-batch-time-and-vacation.component.html'
})
export class SalaryBatchTimeAndVacationComponent {
  @Input()
  public get SalaryBatchId(): number {
    return this.salaryBatchIdValue;
  }
  public set SalaryBatchId(value: number) {
    if (this.salaryBatchIdValue !== value) {
      this.salaryBatchIdValue = value;
    }
  }

  @Input()
  public get UnitTotals(): ISalaryBatchUnitTotal[] {
    return this.unitTotalsValue;
  }

  public set UnitTotals(value: ISalaryBatchUnitTotal[]) {
    if (value !== this.unitTotalsValue) {
      this.unitTotalsValue = value;
      this.unitTotalsPerEmployee = [];

      if (value && value.length > 0) {
        this.selectedUnitTotals = value[0];
      }
    }
  }

  public selectedUnitTotals: ISalaryBatchUnitTotal;
  public unitTotalsPerEmployee: ISalaryBatchEmployeeTotal[] = [];

  private salaryBatchIdValue: number;
  private unitTotalsValue: ISalaryBatchUnitTotal[] = [];

  constructor(
    private dataService: DataService,
    private sessionService: SessionService,
    private employeeService: EmployeeService
  ) {}

  public onSelectedUnitTotalChange(data: ISalaryBatchUnitTotal): void {
    this.dataService
      .SalaryBatches_GetUnitTotalsByEmployee(this.SalaryBatchId, data.Key)
      .subscribe((perEmployee: ISalaryBatchEmployeeTotal[]) => {
        this.unitTotalsPerEmployee = perEmployee;
      });
  }

  public onDoubleClick(data: ISalaryTypeSumsByEmploymentView): void {
    if (data && data.UserEmploymentId) {
      this.employeeService.selectEmployeeFromEmploymentId(data.UserEmploymentId);
      this.sessionService.NavigateTo('tabs.employee.payrolldata');
    }
  }
}
