<app-dialog
  *ngIf="!hasMultiCompanyModule"
  [title]="'Accountant.Join' | translate"
  [(visible)]="visible"
  (action)="dialogAction($event)"
  [overlayClose]="true"
>
  <p [innerHTML]="'Accountant.WarnAModuleAutoEnable' | translate"></p>
  <app-dialog-action [type]="'Cancel'" [disabled]="busy"></app-dialog-action>
  <app-dialog-action
    [action]="'Accept'"
    [label]="'GlobalDialog.Accept' | translate"
    [class]="'Button--destructive'"
    [close]="false"
    [disabled]="busy"
  ></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [visible]="redirectDialogVisible"
  (action)="redirectToLogin()"
  [leadingText]="'Accountant.RedirectToLogin' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
