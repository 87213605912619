<form>
  <fieldset class="Form--horizontal">
    <legend class="Card-title">{{ 'EmployeeGeneral.TaxCard' | translate }}</legend>

    <div class="tax-card-message" *ngIf="!hasEmployment">
      <p>{{ 'EmploymentPayrollData.NoEmployment' | translate }}</p>
    </div>

    <div class="tax-card-message" *ngIf="hasSpecialTaxCardRule && !isSwedenCompany">
      <p>{{ 'EmployeeGeneral.SpecialTaxBrief' | translate }}</p>
    </div>

    <div *ngIf="showTaxCardInfo && !isSwedenCompany">
      <div class="TaxCardT">
        <span class="TaxCardTypeSection-control">
          <app-percentage-edit
            *ngIf="taxCard.taxInfo.Id"
            [ngClass]="taxCard.taxInfo.IsDummy || taxCard.taxCardRequestTime ? 'padding-left' : ''"
            [label]="'EmployeeGeneral.TaxRate' | translate"
            [editMode]="false"
            [(value)]="taxCard.taxInfo.TaxRate"
          >
          </app-percentage-edit>
        </span>
        <div *ngIf="!IsGreenlandCompany && taxCard.taxInfo.Id" class="infoIcon">
          <app-tax-card-addon [taxInfo]="taxCard.taxInfo" [requested]="taxCard.taxCardRequestTime"></app-tax-card-addon>
        </div>
      </div>

      <div class="TaxCardT">
        <span class="TaxCardTypeSection-control" [ngClass]="!!taxCard.taxInfo.Id ? 'is-wrapdiv' : ''">
          <app-combobox-edit
            [editMode]="false"
            [label]="'EmployeeGeneral.TaxCardType' | translate"
            [comboboxDataSource]="taxCard.taxCardTypes"
            [textField]="'Name'"
            [idField]="'Id'"
            [nullValueLabel]="'EmployeeGeneral.NoTaxCard' | translate"
            [(value)]="taxCard.taxInfo.TaxCardTypeId"
            [isValueIcon]="true"
          ></app-combobox-edit>
        </span>
        <div *ngIf="!IsGreenlandCompany && !taxCard.taxInfo.Id" class="infoIcon">
          <app-tax-card-addon [taxInfo]="taxCard.taxInfo" [requested]="taxCard.taxCardRequestTime"></app-tax-card-addon>
        </div>
      </div>

      <app-numeric-edit
        *ngIf="showFreeAmount"
        [label]="'EmployeeGeneral.FreeAmount' | translate"
        [editMode]="false"
        [(value)]="taxCard.taxInfo.FreeAmount"
      ></app-numeric-edit>

      <app-numeric-edit
        *ngIf="showDeductionPerMonth"
        [label]="'EmployeeGeneral.DeductionPerMonth' | translate"
        [editMode]="false"
        [(value)]="taxCard.taxInfo.DeductionMonth"
      ></app-numeric-edit>
    </div>

    <app-check-edit
      *ngIf="IsGreenlandCompany && taxCard.userEmploymentId && !isSwedenCompany"
      [label]="'NewEmployee.CalculateDanishATPandAMcontribution' | translate"
      [editMode]="editMode"
      [disable]="!isAllowEditTaxCard"
      [(isValid)]="childIsValid[0]"
      [(isDirty)]="childIsDirty[0]"
      [(value)]="userEmployment.ApplyDanishRulesInGreenland"
      (valueChange)="onControlChanged()"
    ></app-check-edit>

    <app-text-edit
      *ngIf="IsGreenlandCompany && taxCard.userEmploymentId && !isSwedenCompany"
      class="annualTax"
      [label]="'EmployeeEmployment.AnnualTaxStatementComment' | translate"
      [editMode]="editMode"
      [disabled]="!isAllowEditTaxCard"
      [type]="'text'"
      [maxLength]="'165'"
      [(isValid)]="childIsValid[1]"
      [(isDirty)]="childIsDirty[1]"
      [(value)]="userEmployment.AnnualTaxComment"
      (valueChange)="onControlChanged()"
      [tooltipContent]="'EmployeeEmployment.AnnualTaxCommentTooltip' | translate"
    ></app-text-edit>

    <app-check-edit
      *ngIf="taxCard.userEmploymentId && !isSelfService && !isSwedenCompany"
      [label]="'EmployeeGeneral.AllowExtendedTaxCardDeductions' | translate"
      [editMode]="editMode && admin"
      [(isValid)]="childIsValid[2]"
      [(isDirty)]="childIsDirty[2]"
      [(value)]="userEmployment.AllowExtendedTaxCardDeductions"
      (valueChange)="onControlChanged()"
      [selfService]="selfService"
      [isOpenLink]="false"
      [helpTooltip]="'EmployeeGeneral.helpTooltipAllowExtendedTaxCardDeductions' | translate"
    ></app-check-edit>

    <div [ngClass]="editMode ? 'DeductionClass' : ''">
      <app-numeric-edit
        *ngIf="taxCard.userEmploymentId"
        class="reduceTax"
        [label]="ViewReduceTaxCardDeductionLabel | translate"
        [editMode]="editMode && admin"
        [(value)]="userEmployment.ReduceTaxCardDeduction"
        [(isValid)]="childIsValid[3]"
        [(isDirty)]="childIsDirty[3]"
        (valueChange)="onControlChanged()"
        [isNotNegativeValue]="true"
        [helpTooltip]="'EmployeeGeneral.DeductionAndPercentageTooltip' | translate"
        [isOpenLink]="false"
      >
      </app-numeric-edit>

      <app-percentage-edit
        *ngIf="taxCard.userEmploymentId && !isSwedenCompany"
        [label]="'EmployeeGeneral.IncreaseTaxRatePercentage' | translate"
        [editMode]="editMode && admin"
        [(value)]="userEmployment.IncreaseTaxRatePercentage"
        [(isValid)]="childIsValid[4]"
        [(isDirty)]="childIsDirty[4]"
        (valueChange)="onControlChanged()"
        [allowDecimal]="false"
        [isSelfService]="top8px"
      ></app-percentage-edit>
    </div>

    <app-module-check
      [moduleId]="8"
      [hasModule]="false"
      *ngIf="hasEmployment && !IsSelfServiceGenneral && !isSwedenCompany"
    >
      <app-check-edit
        [label]="'EmployeeGeneral.UseDailyDeductionBasedOnTimeEntries' | translate"
        [editMode]="editMode && admin"
        [(isDirty)]="childIsDirty[5]"
        [(value)]="userEmployment.UseTaxDeductionPerWorkDay"
        (valueChange)="onControlChanged()"
        [isOpenLink]="false"
        [helpTooltip]="'EmployeeGeneral.UseDailyDeductionBasedOnTimeEntriesToolTip' | translate"
      ></app-check-edit>
    </app-module-check>

    <div
      id="TaxCard-Sweden"
      *ngIf="isSwedenCompany && hasEmployment"
      [ngClass]="{ showIconWarrning: isShowTaxCardJamkningExistsMessage }"
    >
      <div [ngClass]="editMode ? 'TaxCardFlex' : 'TaxCardT'">
        <div class="TaxCardTypeSection-control">
          <app-combobox-edit
            [label]="'NewEmployee.Tax table' | translate"
            [editMode]="editMode"
            [comboboxDataSource]="taxTableNumberDataSource"
            [textField]="'Name'"
            [idField]="'Id'"
            [(value)]="userEmployment.TaxTableNumber"
            [(isDirty)]="childIsDirty[6]"
            (valueChange)="onControlChanged()"
          >
          </app-combobox-edit>
        </div>
        <div *ngIf="isShowTaxCardJamkningExistsMessage" class="infoIcon">
          <app-icon
            [istooltipHTML]="true"
            [icon]="'Warning'"
            [tooltip]="'EmployeeGeneral.TaxCardJamkningExistsMessage' | translate"
            [tooltipWidth]="340"
            [istooltipHTML]="true"
          ></app-icon>
        </div>
      </div>

      <app-numeric-edit
        [label]="'NewEmployee.Column' | translate"
        [editMode]="editMode"
        [(value)]="userEmployment.TaxColumnNumber"
        [option]="TaxColumnNumberOption"
        [(isDirty)]="childIsDirty[7]"
        (valueChange)="TaxColumnNumberChange($event)"
        (blur)="TaxColumnNumberBlur()"
      ></app-numeric-edit>

      <app-percentage-edit
        [label]="'EmployeeGeneral.AdHocTaxRateOverride' | translate"
        [editMode]="editMode"
        [(isDirty)]="childIsDirty[8]"
        [allowDecimal]="true"
        [(value)]="userEmployment.AdHocTaxRateOverride"
        (valueChange)="onControlChanged()"
        [decimals]="2"
      ></app-percentage-edit>
    </div>

    <div class="Card-footer" *ngIf="isAllowEditTaxCard || isDanishCompanyAndInSelfServiceGenneral">
      <span *ngIf="!isDanishCompanyAndInSelfServiceGenneral" class="Control--wrapper">
        <app-button
          id="ViewTaxCardButton"
          class="Button--card"
          [isDisabled]="hasSpecialTaxCardRule"
          (clickEvent)="onViewTaxCardHistoryButtonClickEvent()"
          [label]="ViewTaxCardHistoryLabel | translate"
        >
        </app-button>
      </span>
      <span
        class="Control--wrapper"
        *ngIf="(!IsGreenlandCompany || isDanishCompanyAndInSelfServiceGenneral) && taxCard.userEmploymentId"
      >
        <app-button
          id="RequestTaxCardButton"
          class="Button--card"
          [isDisabled]="hasSpecialTaxCardRule"
          (clickEvent)="onRequestTaxCardClickEvent()"
          [label]="'EmployeeGeneral.RequestTaxCard' | translate"
        >
        </app-button>
      </span>
    </div>
  </fieldset>
</form>
