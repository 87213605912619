<div class="Modal-footer u-leader">
  <div *ngFor="let button of buttons" kendoTooltip [title]="button.tooltip || ''">
    <app-module-check
      *ngIf="button.requiredModuleId"
      [moduleId]="button.requiredModuleId"
      [(hasModule)]="button.hasModule"
    >
      <button
        type="button"
        [disabled]="(button.requiredModuleId && !button.hasModule) || button.disabled"
        [ngClass]="button.classValue"
        (click)="onClick(button.actionValue)"
        kendoTooltip
        [title]="button.tooltip || ''"
      >
        {{ button.labelValue }}
      </button>
    </app-module-check>
    <button
      *ngIf="!button.requiredModuleId"
      type="button"
      [disabled]="button.disabled"
      [ngClass]="button.classValue"
      (click)="onClick(button.actionValue)"
    >
      {{ button.labelValue }}
    </button>
  </div>
</div>
