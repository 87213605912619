<form>
  <fieldset class="Form--horizontal">
    <legend class="Card-title">{{ 'EmployeeEmployment.Vacation' | translate }}</legend>
    <app-combobox-edit
      [editMode]="editMode"
      [label]="'EmployeeEmployment.VacationType' | translate"
      [(isDirty)]="childIsDirty[0]"
      [comboboxDataSource]="vacationTypes"
      [disable]="disable"
      [(value)]="model.VacationTypeId"
      (valueChange)="onComboboxVacationTypeChangedEvent()"
      [textField]="'Name'"
      [idField]="'Id'"
    ></app-combobox-edit>

    <app-combobox-edit
      [editMode]="editMode"
      [label]="'EmployeeEmployment.VacationProvider' | translate"
      *ngIf="!IsNoneVacation && !IsGreenlandCompany && !isSwedishCompany"
      [disable]="disable"
      [(isValid)]="childIsValid[1]"
      [(isDirty)]="childIsDirty[1]"
      [nullValueLabel]="'DropdownList.None' | translate"
      [comboboxDataSource]="vacationProviders"
      [(value)]="model.VacationProviderId"
      (valueChange)="onComboboxVacationProviderValueChange()"
      [textField]="'Name'"
      [idField]="'Id'"
    ></app-combobox-edit>

    <!-- hide because of GS-8745 -->
    <app-check-edit
      *ngIf="!IsNoneVacation && !IsGreenlandCompany && !isSwedishCompany"
      [label]="'EmployeeEmployment.SpentVacationReducesGrossSalary' | translate"
      [disable]="disable || IsVacationReduceSalaryDisable"
      [(isDirty)]="childIsDirty[2]"
      [(value)]="model.VacationReduceSalary"
      [editMode]="editMode"
      (valueChange)="onChange()"
    ></app-check-edit>

    <!-- hide untill GS-7341 done -->
    <!-- <app-combobox-edit
      [editMode]="editMode"
      [label]="VacationSupplementPayoutMonthLabel | translate"
      *ngIf="IsPaidVacation || IsPaidVacation_Swedish"
      [disable]="disable"
      [(isDirty)]="childIsDirty[3]"
      [comboboxDataSource]="months"
      [(value)]="model.VacationSupplementPayoutMonth"
      [textField]="'Name'"
      [idField]="'Id'"
      (valueChange)="onChange()"
    ></app-combobox-edit> -->

    <app-percentage-edit
      *ngIf="IsPaidVacation || IsPaidVacation_GreendLand || IsExtraVacation"
      [label]="VacationExtraPercentageLabel | translate"
      [editMode]="editMode"
      [disable]="disable"
      [allowDecimal]="true"
      [required]="false"
      [(isDirty)]="childIsDirty[4]"
      [(value)]="model.VacationExtraPercentage"
      (valueChange)="onChange()"
      [isOpenLink]="false"
    ></app-percentage-edit>

    <app-module-check *ngIf="isVisibleVacationExtraPercentageCheckbox" [moduleId]="4">
      <app-check-edit
        [editMode]="editMode"
        [(value)]="vacationExtraPercentageTypeCheckbox"
        [label]="'EmployeeEmployment.CheckBoxAnnualSupplementLabel' | translate"
        [(isDirty)]="childIsDirty[4]"
        (valueChange)="onChange()"
      >
      </app-check-edit>
    </app-module-check>

    <app-percentage-edit
      *ngIf="IsPaidVacation && !isSwedishCompany && !IsGreenlandCompany"
      [label]="'EmployeeEmployment.TerminationVacationOverridePercentage' | translate"
      [editMode]="editMode"
      [disable]="disable"
      [allowDecimal]="true"
      [(isDirty)]="childIsDirty[5]"
      [(value)]="model.TerminationVacationOverridePercentage"
      [required]="false"
      (valueChange)="onChange()"
    >
    </app-percentage-edit>

    <app-check-edit
      *ngIf="isUseContinuousVacationReportingVisible"
      [label]="'EmployeeEmployment.UseContinuousVacationReporting' | translate"
      [(isDirty)]="childIsDirty[6]"
      [(value)]="model.UseContinuousVacationReporting"
      [editMode]="editMode"
      (valueChange)="onChange()"
      [helpTooltip]="
        'EmployeeEmployment.UseContinuousVacationReportingTooltip' | translate: { SystemAlias: branding.SystemAlias }
      "
    >
    </app-check-edit>

    <app-check-edit
      *ngIf="isUseContinuousVacationReportingVisible"
      [label]="'EmployeeEmployment.OmitVacationReporting' | translate"
      [(isDirty)]="childIsDirty[8]"
      [(value)]="model.OmitVacationReporting"
      [editMode]="editMode"
      (valueChange)="onChange()"
      [openLinkInTitle]="omitVacationReportingLink"
      [helpTooltip]="'EmployeeEmployment.OmitVacationReportingTooltip' | translate"
    >
    </app-check-edit>

    <app-text-edit
      [editMode]="editMode"
      [label]="'EmployeeEmployment.VacationDaysPerYear' | translate"
      *ngIf="IsPaidVacation_Swedish"
      [(isDirty)]="childIsDirty[7]"
      [(value)]="model.VacationDaysPerYear"
      (valueChange)="onChange()"
      [type]="'number'"
    ></app-text-edit>

    <app-text-edit
      *ngIf="IsPaidVacation_Denmark"
      [(isDirty)]="childIsDirty[9]"
      [(value)]="model.VacationDaysPerYear"
      (valueChange)="onChange()"
      [editMode]="editMode"
      [label]="'EmployeeEmployment.VacationDaysPerYearDM' | translate"
      [type]="'number'"
    ></app-text-edit>

    <app-numeric-edit
      *ngIf="IsPaidVacation_Denmark"
      [editMode]="editMode"
      [label]="'EmployeeEmployment.VacationDaysEarnedPerYearDanishLabel' | translate"
      [(isDirty)]="childIsDirty[12]"
      [(value)]="model.AdvanceVacationDays"
      (valueChange)="onChange()"
      [option]="NumericOptions"
    ></app-numeric-edit>

    <app-check-edit
      *ngIf="IsPaidVacation_Denmark && model.VacationDaysPerYear > 0"
      [label]="'EmployeeEmployment.AdditionalVacationSpentFirst' | translate"
      [(isDirty)]="childIsDirty[10]"
      [(value)]="model.AdditionalVacationSpentFirst"
      [editMode]="editMode"
      (valueChange)="onChange()"
    >
    </app-check-edit>

    <app-combobox-edit
      [editMode]="editMode"
      [label]="'EmployeeEmployment.AdditionalVacationTerminationMethod' | translate"
      *ngIf="IsPaidVacation_Denmark && model.VacationDaysPerYear > 0"
      [(isDirty)]="childIsDirty[11]"
      [comboboxDataSource]="additionalVacations"
      [(value)]="model.AdditionalVacationTerminationMethod"
      [textField]="'Name'"
      [idField]="'Id'"
      (valueChange)="onChange()"
    ></app-combobox-edit>

    <div
      [ngClass]="{ 'nondiamond-inner-wrap': disableBySecondaryIncomeType }"
      kendoTooltip
      [title]="'EmployeeEmployment.ToolTipWhenDisabledBecauseOfIncomeType5' | translate"
    ></div>
  </fieldset>
</form>

<div
  [ngClass]="{ 'nondiamond-inner-wrap': disableBySecondaryIncomeType }"
  kendoTooltip
  [title]="'EmployeeEmployment.ToolTipWhenDisabledBecauseOfIncomeType5' | translate"
></div>

<app-dialog
  [(visible)]="vacationProviderWarningDialogVisible"
  (action)="onVacationProviderWarningDialogAction($event)"
  [leadingText]="'EmployeeEmployment.OwnCompanyConfirm' | translate"
>
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Proceed'" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog
  [(visible)]="vacationProviderChangeWarningVisible"
  (action)="onVacationProviderWarningDialogAction($event)"
  [leadingText]="'EmployeeEmployment.VacationProviderChange' | translate"
>
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Understood'" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>
