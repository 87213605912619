<app-dialog
  id="deleteUserDialog"
  [(visible)]="isVisible"
  width="700"
  (action)="onDeleteEmployeeAction($event)"
  title="{{ 'EmployeeGeneral.DeleteDialogTitle' | translate }}"
>
  <div>
    <div *ngIf="currentUserEmployment">
      <div innerHTML="{{ 'EmployeeGeneral.DeleteEmploymentMessage' | translate }}"></div>
    </div>

    <div *ngIf="!currentUserEmployment">
      <div innerHTML="{{ 'EmployeeGeneral.DeleteEmployeeMessage' | translate }}"></div>
    </div>
  </div>
  <app-dialog-action [type]="'Cancel'" [disabled]="sessionService.isSubmitting"></app-dialog-action>
  <app-dialog-action
    *ngIf="hasEmployment"
    [type]="'DeleteEmployment'"
    [disabled]="sessionService.isSubmitting"
    [close]="false"
  ></app-dialog-action>
  <app-dialog-action
    *ngIf="currentCompanyUser"
    [type]="'DeleteCompanyUser'"
    [disabled]="sessionService.isSubmitting"
    [close]="false"
  ></app-dialog-action>
</app-dialog>
