import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { SessionService } from './session/session.service';

@Injectable({
  providedIn: 'root'
})
export class BusyInterceptor implements HttpInterceptor {
  private inProgressCount = 0;
  private submittingCount = 0;
  private gettingCount = 0;
  private progressRef: NgProgressRef;

  constructor(private service: SessionService, private progress: NgProgress) {
    this.progressRef = this.progress.ref('appProgress');
  }

  public isSessionIsAliveGet(req: HttpRequest<any>): boolean {
    if (req && req.method === 'GET' && req.url.includes('session/isalive')) {
      return true;
    }
    return false;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    this.service.ResetIsAliveTimer();
    this.inProgressCount++;
    this.progressRef.start();

    if (req.method === 'PATCH' || req.method === 'PUT' || req.method === 'POST' || req.method === 'DELETE') {
      this.submittingCount++;
      this.service.isSubmitting = true;
    }

    if (req.method === 'GET' && !this.isSessionIsAliveGet(req)) {
      this.gettingCount++;
      this.service.isGetting = true;
    }

    return next.handle(req).pipe(
      finalize(() => {
        this.inProgressCount--;

        if (req.method === 'PATCH' || req.method === 'PUT' || req.method === 'POST' || req.method === 'DELETE') {
          this.submittingCount--;
          if (this.submittingCount <= 0) {
            this.service.isSubmitting = false;
          }
        }

        if (req.method === 'GET' && !this.isSessionIsAliveGet(req)) {
          this.gettingCount--;
          if (this.gettingCount <= 0) {
            this.service.isGetting = false;
          }
        }

        if (this.inProgressCount <= 0) {
          this.progressRef.set(80);
          setTimeout(() => {
            this.progressRef.complete();
          }, 1000);
        }
      }),
      catchError((err: any) => {
        if (err.status === 0 || err.status === -1) {
          this.gettingCount--;
          if (this.gettingCount <= 0) {
            this.service.isGetting = false;
          }
        }
        return observableThrowError(err);
      })
    );
  }
}
