import { EventEmitter, Inject, Injectable, LOCALE_ID, Output } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';
import { StateDeclaration, StateService, Transition, TransitionService } from '@uirouter/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Constants } from 'src/app/common/constants';
import { environment } from '../../../environments/environment';
import { Browser } from '../../common/browser';
import { Global } from '../../common/global';
import { IRequestLogEntry } from '../../model/irequest-log-entry';
import { ISimpleKeyValuePair } from '../../services/api/api-model';
import { Role } from './role';
import { RoleFeatures } from './role-features';

import Globalize from 'globalize';

import 'devextreme/localization/globalize/currency';
import 'devextreme/localization/globalize/date';
import 'devextreme/localization/globalize/message';
import 'devextreme/localization/globalize/number';

import daMessages from 'devextreme/localization/messages/de.json';
import enMessages from 'devextreme/localization/messages/en.json';
import seMessages from 'devextreme/localization/messages/sv.json';

// Common and language-specific CLDR JSONs
import deCldrData from 'devextreme-cldr-data/da.json';
import enCldrData from 'devextreme-cldr-data/en.json';
import supplemental from 'devextreme-cldr-data/supplemental.json';
import svCldrData from 'devextreme-cldr-data/sv.json';
import { RouterStateService } from 'src/app/common/router-state.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public browser: Browser = new Browser();
  public roleKey: string;
  public role: Role = new Role();
  public feature: RoleFeatures = new RoleFeatures(this.role);
  public isSubmitting = false;
  public isGetting = false;
  public currentState = '';
  public menuToggled = false;
  public showMoreMenuToggled = false;
  public showHelpMenuToggled = false;
  public selectedId = true;
  public showMenuToggled = false;
  public employeeListActive = false;
  public currentStyleMenuTabpanelReponsive = '';
  public isApprovalMessage = false;
  public employeeTabState = '';
  public companyTabState = '';
  public selfServiceTabState = '';
  public currentSalaryPeriodId: number;
  public currentSalaryCycleId: number;
  public isShowHugeFeaturesAlert = false;
  public nextState = '';
  public checkIsAliveActive = false;
  public isDetectedCookieDisable = false;
  public isBackToLogin = false;
  public redirectToApp = false;
  public restoreDefault = true;
  private companyPreference: ISimpleKeyValuePair[];
  public zenDeskOpen = false;

  private checkIsAliveErrorValue = false;
  public get checkIsAliveError(): boolean {
    return this.checkIsAliveErrorValue;
  }

  public set checkIsAliveError(value: boolean) {
    this.checkIsAliveErrorValue = value;
    this.checkIsAliveErrorChange.emit(value);
  }

  public get DomainAlias(): string {
    return window.location.hostname;
  }

  @Output() public checkIsAliveErrorChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private modalTranslationsValue: { [key: string]: string } = {};
  private modalTranslationsChangeSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public get OnModalTranslateChanged(): Observable<any> {
    return this.modalTranslationsChangeSubject.asObservable();
  }
  public set modalTranslations(value: { [key: string]: string }) {
    this.modalTranslationsValue = value;
    this.modalTranslationsChangeSubject.next({});
  }
  public get modalTranslations(): { [key: string]: string } {
    return this.modalTranslationsValue;
  }

  private translateChangeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('unknown');
  public get OnTranslateChanged(): Observable<string> {
    return this.translateChangeSubject.asObservable();
  }

  private locationChangedSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public get OnLocationChanged(): Observable<string> {
    return this.locationChangedSubject.asObservable();
  }

  private locationChangeStartSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public get OnLocationChangeStart(): Observable<any> {
    return this.locationChangeStartSubject.asObservable();
  }

  public errorMessage404: string;
  public errorMessage401: string;

  private isAuthenticationKey = 'isAuthenticationKey';
  private isAliveTimerId: any;
  private isAliveSubject: Subject<{}> = new Subject();
  public get isAlive(): Observable<{}> {
    return this.isAliveSubject.asObservable();
  }
  public dateFormat = 'dd-MM-yyyy'; // GS-5677 use only 'da' format

  public requestLog: IRequestLogEntry[] = [];
  private requestLogEnabledValue?: boolean = undefined;
  public get requestLogEnabled() {
    if (this.requestLogEnabledValue === undefined) {
      const value: string = localStorage.getItem('requestLogEnabled');
      this.requestLogEnabledValue = value ? JSON.parse(value) : false;
    }

    return this.requestLogEnabledValue;
  }
  public set requestLogEnabled(value: boolean) {
    this.requestLogEnabledValue = value;
    localStorage.setItem('requestLogEnabled', JSON.stringify(value));
  }

  private getStartedHiddenSubject = new BehaviorSubject<boolean>(true);
  public get getStartedHidden() {
    return this.getStartedHiddenSubject;
  }
  public set hideGetStarted(value: boolean) {
    this.getStartedHiddenSubject.next(value);
  }

  constructor(
    private translateService: TranslateService,
    @Inject(LOCALE_ID) public localeId: string,
    private intlService: IntlService,
    private transitionService: TransitionService,
    private stateService: StateService,
    private routerState: RouterStateService
  ) {
    this.OnTranslateChanged.subscribe((locale: string) => {
      if (locale === 'unknown') {
        if (!environment.production) {
          console.log('intlService.localeId not changed to', locale);
        }
      } else {
        this.localeId = this.applyLocale(locale);
        (this.intlService as CldrIntlService).localeId = 'da'; // Apply for GS-4780
      }

      this.loadGlobalize();
    });

    this.transitionService.onSuccess({}, (transition: Transition) => {
      this.currentState = transition.to().name;
      if (this.currentState !== 'tabs.employee' && this.currentState.includes('tabs.employee')) {
        this.employeeTabState = this.currentState;
      } else if (this.currentState !== 'tabs.company' && this.currentState.includes('tabs.company')) {
        this.companyTabState = this.currentState;
      } else if (this.currentState !== 'tabs.selfservice' && this.currentState.includes('tabs.selfservice')) {
        this.selfServiceTabState = this.currentState;
      }

      // if (environment.environment === 'DEV') {
      //   console.log('state: ', this.currentState);
      // }

      this.locationChangedSubject.next(this.currentState);
    });

    this.transitionService.onStart({}, (transition: Transition) => {
      this.locationChangeStartSubject.next(transition);
    });

    this.translateService.onTranslationChange.subscribe((event: TranslationChangeEvent) => {
      this.translateChangeSubject.next(event.lang);
    });

    this.translateService.onLangChange.subscribe((event: TranslationChangeEvent) => {
      this.translateChangeSubject.next(event.lang);
    });

    this.translateService.onDefaultLangChange.subscribe((event: TranslationChangeEvent) => {
      this.translateChangeSubject.next(event.lang);
    });
  }

  public logRequest(entry: IRequestLogEntry) {
    this.requestLog.push(entry);
    if (this.requestLog.length > 100) {
      this.requestLog.shift();
    }

    console.log(JSON.stringify(entry, null, 2));
  }

  public get isSwedenCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
  }
  public applyLocale(cultureCode: string): string {
    let locale: string;
    if (cultureCode) {
      cultureCode = cultureCode.substring(0, 2);
      if (this.isSwedenCompany) {
        locale = 'en-CA';
        this.dateFormat = 'yy-MM-dd';
      } else {
        switch (cultureCode) {
          case 'kl':
            locale = 'da-GL';
            break;
          case 'en':
            locale = 'en-GB';
            break;
          case 'sv':
            locale = 'da';
            break;
          default:
            locale = 'da';
            break;
        }
        this.dateFormat = 'dd-MM-yyyy';
      }
    }

    return locale;
  }

  public loadGlobalize() {
    Globalize.load(supplemental, deCldrData, enCldrData, svCldrData);
    let lang = 'en';
    switch (this.LanguageCode) {
      case 'en':
        lang = 'en';
        break;
      case 'da':
      case 'gl':
        lang = 'da';
        break;
      default:
        lang = 'sv';
        break;
    }

    Globalize.loadMessages(enMessages);
    Globalize.loadMessages(daMessages);
    Globalize.loadMessages(seMessages);

    Globalize.locale(lang);
  }

  public toString(value: Date | number, format?: string): string {
    if (typeof value === 'number') {
      return this.intlService.toString(value, format ? format : 'n2', 'da');
    }
    return this.intlService.toString(value, format ? format : this.dateFormat, this.localeId);
  }

  public parseDate(value: string, format?: string): Date {
    return this.intlService.parseDate(value, format, 'da');
  }

  public parseFloat(value: string): number {
    return this.intlService.parseNumber(value);
  }

  public parseInt(value: string): number {
    return this.intlService.parseNumber(value, { maximumFractionDigits: 0 });
  }

  public NavigateTo(state: string): any {
    if (Global.SESSION && Global.SESSION.CurrentRole) {
      const currentRoleKey: string = Global.SESSION.CurrentRole.Key;
      if (currentRoleKey === 'Employee') {
        if (state.includes('tabs.company')) {
          if (Global.SESSION.ManagerForUserEmploymentIds.length > 0) {
            this.stateService.go('tabs.company.approval.timeentry');
            return;
          }
          if (Global.SESSION.IsAccountAdmin || Global.SESSION.IsAccountMember) {
            this.stateService.go('tabs.accountant.companies');
            return;
          }
          this.stateService.go('tabs.selfservice.payslip');
          return;
        }
        if (state.includes('tabs.employee')) {
          const hasManager: boolean = this.role.EmployeeWithRoleAsManager;
          if (hasManager) {
            this.stateService.go('tabs.employee.time');
            return;
          } else {
            if (Global.SESSION.IsAccountAdmin || Global.SESSION.IsAccountMember) {
              this.stateService.go('tabs.accountant.companies');
              return;
            }
            this.stateService.go('tabs.selfservice.payslip');
            return;
          }
        }
      }
    }
    this.stateService.go(state);
  }

  public NavigateToAppUri(uri: string): boolean {
    const stateName: string = this.getStateByUri(uri);
    if (stateName && this.currentState !== stateName) {
      this.NavigateTo(stateName);
      return true;
    }

    return false;
  }

  public getStateByUri(uri: string): string {
    let matchedState: string;
    const allStates: StateDeclaration[] = this.stateService.get();

    if (!uri || !allStates || allStates.length === 0) {
      return undefined;
    }

    for (let index = 0; index < allStates.length; index++) {
      const absState: string = this.stateService.href(allStates[index], undefined, { absolute: true });
      if (absState && absState.indexOf(uri) >= 0) {
        matchedState = allStates[index].name;
        break;
      }
    }

    return matchedState;
  }

  get IsAuthenticated(): boolean {
    try {
      const value: string = sessionStorage.getItem(this.isAuthenticationKey);
      return value === 'true';
    } catch (e) {
      this.isDetectedCookieDisable = true;
      return false;
    }
  }

  set IsAuthenticated(isAuthenticate: boolean) {
    try {
      const value: string = isAuthenticate ? 'true' : 'false';
      sessionStorage.setItem(this.isAuthenticationKey, value);
    } catch (e) {
      this.isDetectedCookieDisable = true;
    }
  }

  public get LanguageCode(): string {
    return Global.SESSION && Global.SESSION.SignOnToken.Language
      ? Global.SESSION.SignOnToken.Language.CultureCode.split('-')[0]
      : undefined;
  }

  public ResetIsAliveTimer(): void {
    if (this.isAliveTimerId) {
      clearInterval(this.isAliveTimerId);
      this.isAliveTimerId = undefined;
    }

    this.isAliveTimerId = setInterval(() => {
      if (this.currentState !== 'login' && this.IsAuthenticated) {
        this.isAliveSubject.next();
      }
    }, 30000);
  }

  public refeshRoleAndFeature(): void {
    this.role = new Role();
    this.feature = new RoleFeatures(this.role);
  }

  public switchToDefaultTab(): void {
    let isFullAdmin = false;
    let isReadOnly = false;
    let isEmployee = false;
    let isSalaryAdmin = false;

    switch (Global.SESSION.CurrentRole.Key) {
      case 'FullAdmin':
        isFullAdmin = true;
        break;
      case 'ReadOnly':
        isReadOnly = true;
        break;
      case 'Employee':
        isEmployee = true;
        break;
      case 'SalaryAdmin':
        isSalaryAdmin = true;
        break;
      default:
        break;
    }

    if (Global.SESSION.SignOnToken.LanguageId) {
      try {
        document.cookie = 'languageId=' + Global.SESSION.SignOnToken.LanguageId;
      } catch (e) {
        this.isDetectedCookieDisable = true;
      }
    }

    if (Global.SESSION.SignOnToken.UserAccountId && (Global.SESSION.IsAccountAdmin || Global.SESSION.IsAccountMember)) {
      this.NavigateTo('tabs.accountant.companies');
      return;
    }

    if (isFullAdmin) {
      if (Global.IsTaskCompleted) {
        this.NavigateTo('tabs.company.salarybatches');
        return;
      }
      this.NavigateTo('tabs.company.general');
      return;
    }

    if (isReadOnly || isSalaryAdmin) {
      if (Global.SESSION.IsFirstLogin) {
        this.NavigateTo('tabs.company.general');
        return;
      }
      this.NavigateTo('tabs.company.salarybatches');
      return;
    }

    if (isEmployee) {
      this.NavigateTo('tabs.selfservice');
    }
  }

  public getCompanyPreference(key: string): ISimpleKeyValuePair {
    this.companyPreference = Global.COMPANY_PREFERENCES;

    if (this.companyPreference && this.companyPreference.length > 0) {
      const preference = this.companyPreference.find((item: ISimpleKeyValuePair) => item.Key === key);
      return preference;
    }
    return null;
  }

  public isAvailableTab(State: string, isDanishCompany?: boolean, isGreenlandCompany?: boolean): boolean {
    if (State.includes('tabs.employee') && this.feature.EmployeeTabEnabled) {
      switch (State) {
        case 'tabs.employee.payslip':
        case 'tabs.employee.hiringstatus':
        case 'tabs.employee.general':
          if (!this.role.EmployeeWithRoleAsManager) {
            return true;
          }
          break;
        case 'tabs.employee.time':
          if (this.feature.EmployeeTimeTabEnabled) {
            return true;
          }
          break;
        case 'tabs.employee.payrolldata':
          if (this.feature.EmployeePayrollDataTabEnabled && !this.role.EmployeeWithRoleAsManager) {
            return true;
          }
          break;
      }
    } else if (State.includes('tabs.company') && this.feature.CompanyTabEnabled) {
      switch (State) {
        case 'tabs.company.general':
        case 'tabs.company.payrolldata':
        case 'tabs.company.salarybatches':
        case 'tabs.company.configuration':
        case 'tabs.company.configuration.hiringstatus':
        case 'tabs.company.configuration.departments':
        case 'tabs.company.configuration.integrations':
        case 'tabs.company.configuration.advancedtypes.advancedsalarytypes':
        case 'tabs.company.configuration.advancedtypes.timeentrytypes':
        case 'tabs.company.configuration.advancedtypes':
        case 'tabs.company.configuration.advancedtypes.salarytypessingle':
        case 'tabs.company.configuration.advancedtypes.timeentrytypessingle':
          if (!this.role.EmployeeWithRoleAsManager) {
            return true;
          }
          break;
        case 'tabs.company.balances':
        case 'tabs.company.balances.residualvacation':
        case 'tabs.company.balances.extravacationhours':
          if (!this.role.IsEmployeeRole && isDanishCompany) {
            return true;
          }
          break;
        case 'tabs.company.modules':
          if (this.role.IsFullAdmin && !isGreenlandCompany) {
            return true;
          }
          break;
        case 'tabs.company.configuration.dataimport':
          if (!this.role.IsReadOnly) {
            return true;
          }
          break;
        case 'tabs.company.configuration.settings':
          if (this.role.IsFullAdmin || this.role.IsReadOnly) {
            return true;
          }
          break;
        case 'tabs.company.approval.timeentry':
          return true;
        case 'tabs.company.configuration.dimensions':
          return true;
      }
    } else if (State.includes('tabs.selfservice')) {
      return true;
    } else if (State.includes('tabs.accountant') && this.feature.AccountantTabEnabled) {
      switch (State) {
        case 'tabs.accountant.companies':
        case 'tabs.accountant.users':
          return true;
        case 'tabs.accountant.configuration':
          if (this.role.IsAccountAdmin) {
            return true;
          }
          break;
      }
    } else if (State.includes('tabs.account.userpreferences')) {
      return true;
    }
    return false;
  }

  public checkUrlPermission() {
    if (this.currentState.indexOf('tabs') < 0) {
      return;
    }
    const isDanishCompany = Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
    const isGreenlandCompany = Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
    const isAvailable = this.isAvailableTab(this.currentState, isDanishCompany, isGreenlandCompany);
    if (!isAvailable) {
      this.switchToDefaultTab();
    }
  }
}
