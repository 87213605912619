import { filterBy } from '@progress/kendo-data-query';
var SalaryBatchOtherErrorGridComponent = /** @class */ (function () {
    function SalaryBatchOtherErrorGridComponent() {
        this.validationRecordValue = [];
        this.gridData = [];
    }
    Object.defineProperty(SalaryBatchOtherErrorGridComponent.prototype, "validationRecord", {
        get: function () {
            return this.validationRecordValue;
        },
        set: function (data) {
            this.validationRecordValue = data;
            this.gridData = this.validationRecord;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchOtherErrorGridComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchOtherErrorGridComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        this.gridData = filterBy(this.validationRecord, filter);
    };
    return SalaryBatchOtherErrorGridComponent;
}());
export { SalaryBatchOtherErrorGridComponent };
