<div class="dimension-detail" *ngIf="isEnableDimension">
  <div class="dimension-activate">
    <span
      class="dimension-content"
      [innerHTML]="
        'CompanyConfigurations.Naming' | translate: { dimensionNumber: dimensionsService.currentDimensionNumber }
      "
    ></span>
    <div class="dimension-card Cards">
      <div class="Card">
        <div class="dimension-naming">
          <app-text-edit style="width: 100%" [(value)]="dimensionsService.dimensionNaming" [editMode]="true">
          </app-text-edit>
        </div>
      </div>
    </div>
  </div>
  <app-button
    [isDisabled]="dimensionsService.dimensionNaming.length === 0"
    id="CompanyConfigurationDimensionEnableButton"
    buttonClass="Button--card"
    (clickEvent)="activeDimension()"
    [label]="'CompanyConfigurations.ActiveDimension' | translate"
  >
  </app-button>
</div>
<app-grid
  *ngIf="!isEnableDimension"
  class="dimensions-grid"
  [data]="dimensionsService.listDimensionsData"
  [filterable]="false"
  [filter]="filter"
  (filterChange)="onFilterChange($event)"
  [idColumn]="'Id'"
  [(selectedId)]="selectedId"
  [(selectedItem)]="dimensionsService.currentDimension"
  (selectedItemChange)="onSelectedItemChange($event)"
  (dblClick)="gridDblclick($event)"
  [(triggerUpdate)]="dimensionsService.triggerUpdate"
  [editMode]="editMode"
  (action)="gridAction($event)"
  (saveChangesEvent)="updateDimension($event)"
>
  <app-grid-action *ngIf="editMode" [action]="'AddNew'" [label]="'Grids.AddNewRecord' | translate"></app-grid-action>
  <app-grid-action
    *ngIf="editMode"
    [action]="'Delete'"
    [label]="'Grids.Delete' | translate"
    [isHidden]="!dimensionsService.currentDimension"
  >
  </app-grid-action>
  <app-grid-column
    [field]="'Value'"
    [type]="'text'"
    [title]="'CompanyConfigurations.Name' | translate"
    [headerTooltip]="'CompanyConfigurations.Name' | translate"
    [viewClass]="'truncated'"
    [tooltipField]="'Name'"
    [editable]="editMode"
    [width]="150"
  ></app-grid-column>
  <app-grid-column
    [field]="'Description'"
    [type]="'text'"
    [title]="'CompanyConfigurations.Description' | translate"
    [headerTooltip]="'CompanyConfigurations.Description' | translate"
    [viewClass]="'truncated'"
    [tooltipField]="'Description'"
    [editable]="editMode"
    [width]="500"
  ></app-grid-column>
  <app-grid-column
    [hideColumn]="!isShowCompanyUser"
    [field]="'ApproverCompanyUserId'"
    [type]="'combobox'"
    [comboboxDataSource]="staticDataService.companyUsers | async"
    [comboboxValueField]="'Id'"
    [comboboxDisplayField]="'FullName'"
    [allowComboeditNull]="true"
    [editable]="editMode"
    [title]="'CompanyConfigurations.ApproverCompanyUser' | translate"
    [width]="150"
    [isFilterable]="true"
    [defaultnullvalue]="0"
    [CurrentTextComboboxToolTip]="false"
    [headerTooltip]="'CompanyConfigurations.ApproverCompanyUser' | translate"
    [viewClass]="'truncated'"
  >
  </app-grid-column>
  <app-grid-column
    [field]="'LimitToDepartmentId'"
    [type]="'combobox'"
    [comboboxDataSource]="companyDepartmentService.departments | async"
    [comboboxValueField]="'Id'"
    [comboboxDisplayField]="'Name'"
    [allowComboeditNull]="true"
    [editable]="editMode"
    [title]="'CompanyConfigurations.Department' | translate"
    [width]="150"
    [isFilterable]="true"
    [defaultnullvalue]="0"
    [CurrentTextComboboxToolTip]="false"
    [headerTooltip]="'CompanyConfigurations.Department' | translate"
    [viewClass]="'truncated'"
  >
  </app-grid-column>
  <app-grid-column
    [field]="'LimitToTemplateId'"
    [type]="'combobox'"
    [comboboxDataSource]="companyTemplateService.templates | async"
    [comboboxValueField]="'Id'"
    [comboboxDisplayField]="'Name'"
    [allowComboeditNull]="true"
    [editable]="editMode"
    [title]="'CompanyConfigurations.Template' | translate"
    [width]="150"
    [isFilterable]="true"
    [defaultnullvalue]="0"
    [CurrentTextComboboxToolTip]="false"
    [headerTooltip]="'CompanyConfigurations.Template' | translate"
    [viewClass]="'truncated'"
  >
  </app-grid-column>
</app-grid>

<app-dialog
  (action)="onRemoveDimension($event)"
  [(visible)]="removeDimensionDialogVisible"
  [leadingText]="'Grids.ConfirmDelete' | translate"
>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Delete"></app-dialog-action>
</app-dialog>
