import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-popup-button',
  templateUrl: './popup-button.component.html'
})
export class PopUpButtonComponent {
  @Input() public label: string;
  @Input() public icon: string;
  @Input() public buttonClass: string;
  @Input() public tooltipContent: string;
  @Input() public popupButtonIcon: any[] = [];
  @Input() public isUseLabelAppIcon = false;
  @Input() public isHidden = false;

  @Output() public IconClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() public ButtonClick: EventEmitter<any> = new EventEmitter<any>();

  public show = false;

  constructor(private sessionService: SessionService) {}

  public onToggle(): void {
    this.show = !this.show;
    if (!this.show) {
      this.ButtonClick.emit();
    }
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  private preAction: string;
  public appIconClick(action: string): void {
    if (this.isMobile) {
      if (!this.preAction || (this.preAction && this.preAction !== action)) {
        this.IconClick.emit(action);
        this.preAction = action;
      } else {
        this.preAction = '';
      }
    } else {
      this.IconClick.emit(action);
    }
  }
}
