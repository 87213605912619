import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { GridModule } from '@progress/kendo-angular-grid';
import { IntlModule } from '@progress/kendo-angular-intl';

import { CustomControlsModule } from '../../custom-controls/custom-controls.module';
import { SharedComponentsModule } from '../../shared-components/shared-components.module';

import { CompanyPayrollDataComponent } from './company-payroll-data.component';
import { SalaryTypeConfigurationDialogComponent } from './salarytype-configuration-dialog.component';

@NgModule({
  imports: [CommonModule, TranslateModule, GridModule, IntlModule, CustomControlsModule, SharedComponentsModule],
  declarations: [CompanyPayrollDataComponent, SalaryTypeConfigurationDialogComponent],
  exports: [CompanyPayrollDataComponent],
  entryComponents: [CompanyPayrollDataComponent]
})
export class CompanyPayrollDataModule {}
