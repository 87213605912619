import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Constants } from '../../../common/constants';
import { Global } from '../../../common/global';
import { ICompany, ISalaryBatch, IStartupTaskCompanyView } from '../../../services/api/api-model';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';
import { CompanySalaryBatchService } from '../company-salary-batches.service';

@Component({
  selector: 'app-batch-finalization-step-2-dialog',
  templateUrl: './batch-finalization-step-2-dialog.component.html'
})
export class BatchFinalizationStep2DialogComponent implements OnDestroy {
  private stepCompletedValue: boolean;
  @Input()
  public get stepCompleted(): boolean {
    return this.stepCompletedValue;
  }
  public set stepCompleted(value: boolean) {
    if (this.stepCompletedValue !== value) {
      // this.stepCompletedValue = value;
      setTimeout(() => this.stepCompletedChange.emit(value));
    }
  }
  @Output() public stepCompletedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      setTimeout(() => this.visibleChange.emit(value));
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public get isClose(): boolean {
    return this.visible;
  }
  public set isClose(value: boolean) {
    if (value) {
      this.visible = false;
    }
  }

  @Output() public finalizationExit: EventEmitter<void> = new EventEmitter<void>();

  public get isGreenland(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }
  public get isSwedenCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
  }
  public get isDanishCompany() {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
  }

  public get eBoksModuleEnabled(): boolean {
    return this.sessionService.feature.hasModuleId(9);
  }
  public get isAccountAdmin(): boolean {
    return this.sessionService.role.IsAccountAdmin;
  }
  public get isFullAdmin(): boolean {
    return this.sessionService.role.IsFullAdmin;
  }
  public get isIOSMobileApp(): boolean {
    return this.sessionService.browser.iOSMobileDevice;
  }
  public get company(): ICompany {
    return Global.COMPANY;
  }

  public salarybatch: ISalaryBatch;

  constructor(
    private settingService: SettingService,
    private sessionService: SessionService,
    public companySalaryBatchService: CompanySalaryBatchService
  ) {
    this.companySalaryBatchService.selectedSalaryBatch
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: ISalaryBatch) => {
        this.salarybatch = value;
      });
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onDialogAction(action: string): void {
    if (action === 'Understood') {
      this.stepCompleted = true;
      this.finalizationExit.emit();
    } else {
      this.onCancel();
    }
  }

  public onCancel(): void {
    this.finalizationExit.emit();
    this.visible = false;
  }

  public activateDataExport(): void {
    this.sessionService.NavigateTo('tabs.company.configuration.integrations');
    //window.open("http://support.gratisal.com/hc/da/articles/208119325-Integration-med-%C3%98konomisystem", "blank");
  }

  public navigateToExtraTab(): void {
    this.settingService.navigationParameters['EnableEboksModule'] = true;
    this.sessionService.NavigateTo('tabs.company.modules');
    this.onCancel();
  }

  public setStartupTaskDone(): void {
    const task: IStartupTaskCompanyView = Global.STARTUP_TASKS.find(
      (startupTask: IStartupTaskCompanyView) => startupTask.Key === 'NetsAgreement'
    );
    if (!task.StatusId) {
      this.navigateToTask(task);
      this.onCancel();
      return;
    }

    this.sessionService.NavigateTo('tabs.company.general');
    this.onCancel();
  }

  public openTabLink(): void {
    window.open('http://support.gratisal.com/hc/da/articles/208119325-Integration-med-%C3%98konomisystem', '_blank');
  }

  private navigateToTask(task: IStartupTaskCompanyView): void {
    if (task) {
      this.settingService.navigationStartUpTask = task;
      if (!this.sessionService.NavigateToAppUri(task.AppUri)) {
        this.settingService.showNavigationMessage();
      }
    }
  }
}
