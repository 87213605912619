import { Component, OnInit } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../../../environments/environment';
import { version } from '../../../version';
import { Global } from '../../common/global';
import { DataService } from '../../services/api/data.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html'
})
export class FeedbackComponent implements OnInit {
  public apiVersion = '';
  public clientVersion = '';
  public feedbackDialogVisible = false;
  public successDialogVisible = false;
  public feedbackText: string;
  public companyId: number;
  public userId: number;
  public country = '';
  public accountId: number;
  public widthDestopFooter = '';
  public paddingDestopFooter = '';

  public get environment(): string {
    return environment.environment;
  }

  public get branding() {
    return environment.branding;
  }

  public get demo(): boolean {
    return this.sessionService.feature.IsDemoVersion;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public languageCode = 'en/prices';
  public get isHaveSupportModule(): boolean {
    return this.sessionService.feature.hasModuleId(7) || this.sessionService.feature.hasModuleId(17);
  }

  constructor(
    private dataService: DataService,
    public sessionService: SessionService,
    public settingService: SettingService
  ) {
    this.clientVersion = version;
    this.getApiVersion();
  }

  public ngOnInit(): void {
    this.userId = Global.SESSION.CurrentCompanyUser.UserId;
    this.accountId = Global.SESSION.SignOnToken.UserAccountId;
    this.companyId = Global.SESSION.CurrentCompanyId;
    this.country = Global.SESSION.CurrentCompany.Country.Name;
    if (!this.isMobile) {
      this.widthDestopFooter = 'widthDestopFooter';
    }

    const isEng = (Global.SESSION ? Global.SESSION.SignOnToken.LanguageId : 1) === 2;
    if (isEng) {
      this.languageCode = 'en/prices';
    } else {
      this.languageCode = 'da/priser';
    }
  }

  public onAction(action: string): void {
    if (action === 'Submit') {
      this.submitFeedback();
    }
  }

  private getApiVersion(): void {
    if (this.settingService.apiVersion) {
      this.apiVersion = this.settingService.apiVersion;
      Sentry.setTags({ apiVersion: this.settingService.apiVersion });
      Sentry.setTags({ clientVersion: this.clientVersion });
    } else {
      this.dataService.Miscellaneous_GetVersion().subscribe((data: string): void => {
        this.apiVersion = data;
        this.settingService.apiVersion = data;
        Sentry.setTags({ apiVersion: data });
        Sentry.setTags({ clientVersion: this.clientVersion });
      });
    }
  }

  private submitFeedback(): void {
    if (!this.feedbackText || this.feedbackText.trim() === '') {
      this.feedbackDialogVisible = false;
      return;
    }

    this.dataService
      .Miscellaneous_SubmitFeedback({ Message: this.feedbackText })
      .subscribe(() => (this.successDialogVisible = true));
  }
}
