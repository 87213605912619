import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/browser';
import { AppModule } from './app/app.module';
import { AppConfig } from './app/model/app-config';
import { environment } from './environments/environment';
import { version } from './version';

declare let window: any;

if (!window.cordova) {
  getConfig();
} else {
  document.addEventListener('deviceready', () => getConfig(), false);
}

function getConfig() {
  if (fetch) {
    fetch('config.json').then(async (resp) => {
      const conf = (await resp.json()) as AppConfig;
      environment.apiUrl = conf.apiUrl;
      environment.environment = conf.environment;
      environment.production = conf.production;
      environment.sentryDSN = conf.sentryDSN;
      environment.branding = conf.branding;
      bootstrap();
    });
  } else {
    environment.apiUrl = 'https://api.intect.app/';
    environment.environment = 'PROD';
    environment.production = true;
    environment.sentryDSN = 'https://9efe1c9ac10840ecba53e0b96b5f0534@sentry.io/99131';
    bootstrap();
  }
}

function bootstrap() {
  Sentry.init({
    dsn: environment.sentryDSN,
    environment: environment.environment,
    release: version
  });

  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err: any) => console.log(err));
}
