import { HttpClient } from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Base64 } from '../../common/base64';
import { Global } from '../../common/global';
import { SessionService } from '../session/session.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../session/session.service";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(httpClient, sessionService) {
        this.httpClient = httpClient;
        this.sessionService = sessionService;
    }
    AuthenticationService.prototype.login = function () {
        var _this = this;
        if (!this.credentials.Remember) {
            try {
                sessionStorage.removeItem('credentials');
            }
            catch (e) {
                this.sessionService.isDetectedCookieDisable = true;
            }
        }
        //// btoa(unescape(encodeURIComponent(this.credentials.Username + ":" + this.credentials.Password)))
        var encodeString = Base64.encode(unescape(encodeURIComponent(this.credentials.Username + ':' + this.credentials.Password)));
        try {
            sessionStorage.setItem('token', 'Basic ' + encodeString);
        }
        catch (e) {
            this.sessionService.isDetectedCookieDisable = true;
        }
        return this.httpClient.post(environment.apiUrl + '/api/auth/login', undefined).pipe(map(function (result) {
            var token = result.Token;
            _this.sessionService.IsAuthenticated = true;
            sessionStorage.setItem('token', 'Token ' + token.replace(/"/g, ''));
            if (_this.credentials.Remember) {
                sessionStorage.setItem('credentials', Base64.encode(JSON.stringify(_this.credentials)));
            }
            Sentry.setContext('userName', { id: _this.credentials.Username });
            return result;
        }), catchError(function (error) { return observableThrowError(error); }), finalize(function () { return new Observable(); }));
    };
    AuthenticationService.prototype.logout = function () {
        this.clearAuthentication();
        return this.httpClient
            .post(environment.apiUrl + '/api/auth/logout', undefined)
            .pipe(catchError(function (error) { return observableThrowError(error); }));
    };
    AuthenticationService.prototype.clearAuthentication = function () {
        var _this = this;
        //// TODO: We should use ISO language code
        //// languageId == 1: Danish, languageId == 2: English
        var currentLanguageId = 1;
        if (Global.SESSION && Global.SESSION.SignOnToken.LanguageId) {
            currentLanguageId = Global.SESSION.SignOnToken.LanguageId;
        }
        this.sessionService.IsAuthenticated = false;
        var lastUserName = localStorage.getItem('lastUserName');
        var requestLogEnabled = localStorage.getItem('requestLogEnabled');
        //// TODO //// REVIEW --- IMPORTANT
        //// This is a shallow fix for the following error:
        //// Login to the app -> log out -> use the forget pass word feature -> login again with the temporary password -> watch console explodes
        //// for some reason, the above error does not happen when the login page is loaded for the first time
        // window.location.reload();
        ////
        setTimeout(function () {
            try {
                sessionStorage.clear();
                localStorage.clear();
                if (lastUserName) {
                    localStorage.setItem('lastUserName', lastUserName);
                }
                localStorage.setItem('requestLogEnabled', requestLogEnabled);
                window.location.reload();
            }
            catch (e) {
                _this.sessionService.isDetectedCookieDisable = true;
            }
        });
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SessionService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
