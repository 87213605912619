<kendo-dialog
  *ngIf="visible && !safeContent"
  [title]="title || ' '"
  [minWidth]="minWidth"
  [width]="width"
  [height]="height"
  (close)="onClose()"
  (keydown)="onkeydown($event)"
>
  <div *ngIf="leadingTextValue">
    <p [innerHTML]="leadingTextValue"></p>
  </div>
  <ng-content></ng-content>
  <kendo-dialog-actions #buttonArea *ngIf="!isAvatar && buttons && buttons.length > 0" class="Modal-footer">
    <div *ngFor="let button of buttons" kendoTooltip [title]="button.tooltip || ''">
      <app-module-check *ngIf="button.requiredModuleId" [moduleId]="button.requiredModuleId">
        <button
          [id]="'DialogButton_' + button.action"
          type="button"
          kendoButton
          [disabled]="button.disabled"
          [ngClass]="button.class"
          (click)="onClick(button.action, button.close)"
          kendoTooltip
          [title]="button.tooltip || ''"
        >
          {{ button.label }}
        </button>
      </app-module-check>
      <button
        *ngIf="!button.requiredModuleId"
        [id]="'DialogButton_' + button.action"
        kendoButton
        type="button"
        [disabled]="button.disabled"
        [ngClass]="button.class"
        (click)="onClick(button.action, button.close)"
        autofocus
      >
        {{ button.label }}
      </button>
    </div>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog
  #iframeDialog
  *ngIf="visible && safeContent"
  [title]="title || ' '"
  [minWidth]="minWidth"
  [width]="width"
  [height]="height"
  (close)="onClose()"
>
  <iframe
    *ngIf="!isEdge"
    #iframeInDialog
    [srcdoc]="safeContent"
    src="about:blank"
    class="k-content-frame"
    width="960"
    height="700"
    (load)="iframeLoaded()"
    scrolling="{{ isIframeScrolling }}"
  >
  </iframe>
  <iframe
    *ngIf="isEdge"
    #iframeInDialog
    class="k-content-frame"
    width="960"
    height="700"
    (load)="iframeLoaded()"
    scrolling="{{ isIframeScrolling }}"
  ></iframe>
</kendo-dialog>
