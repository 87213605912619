import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { environment } from 'src/environments/environment';
import { Global } from '../../common/global';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { CompanyService } from '../../services/company.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/api/data.service";
import * as i2 from "../../services/api/static-data.service";
import * as i3 from "../../services/setting.service";
import * as i4 from "../../services/session/session.service";
import * as i5 from "@uirouter/core";
import * as i6 from "../../services/broadcast.service";
import * as i7 from "@ngx-translate/core";
var CompanyModuleService = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyModuleService, _super);
    function CompanyModuleService(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster, translate) {
        var _this = _super.call(this, dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) || this;
        _this.dataService = dataService;
        _this.staticDataService = staticDataService;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.transitionService = transitionService;
        _this.broadcaster = broadcaster;
        _this.translate = translate;
        _this.ngUnsubscribe = new Subject();
        _this.salaryCycles = [];
        _this.usersMonthly = 0;
        _this.users14Day = 0;
        _this.usersWeekly = 0;
        _this.billedMessage = '';
        _this.selectedPackageId = 0;
        _this.confirmDialogVisible = false;
        return _this;
    }
    CompanyModuleService.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    CompanyModuleService.prototype.loadSalaryCycles = function () {
        var _this = this;
        return this.staticDataService.SalaryCycle.pipe(takeUntil(this.ngUnsubscribe), tap(function (data) {
            _this.salaryCycles = data;
        }));
    };
    CompanyModuleService.prototype.serviceInit = function () {
        var _this = this;
        this.staticDataService.accountInvoiceTypes.subscribe(function (data) {
            if (data && data.length > 0) {
                _this.clientAccountInvoiceType = data.find(function (model) { return model.Key === 'Client'; });
            }
        });
        if (this.isFullAdmin) {
            this.staticDataService.loadStaticData().then(function () {
                _this.loadSalaryCycles().subscribe(function () {
                    _this.staticDataService.userEmployments.subscribe(function (eData) {
                        _this.progressEmployementData(eData);
                    });
                });
            });
            this.loadModules();
        }
    };
    Object.defineProperty(CompanyModuleService.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyModuleService.prototype, "modules", {
        get: function () {
            if (!this.modulesSubject) {
                this.modulesSubject = new BehaviorSubject([]);
            }
            return this.modulesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CompanyModuleService.prototype.loadModules = function () {
        var _this = this;
        if (!this.modulesSubject) {
            this.modulesSubject = new BehaviorSubject([]);
        }
        if (this.isFullAdmin) {
            this.staticDataService.moduleCompanyView
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(function (data) {
                _this.modulesSubject.next(JSON.parse(JSON.stringify(data)));
            });
        }
    };
    Object.defineProperty(CompanyModuleService.prototype, "employementData", {
        get: function () {
            if (!this.employementDataSubject) {
                this.employementDataSubject = new BehaviorSubject([]);
            }
            return this.employementDataSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CompanyModuleService.prototype.progressEmployementData = function (employementData) {
        this.usersWeekly = 0;
        this.users14Day = 0;
        this.usersMonthly = 0;
        employementData = employementData.filter(function (item) {
            var now = new Date();
            return item.IsActive && (!item.ExpireDate || new Date(item.ExpireDate) > now);
        });
        var _loop_1 = function (employment) {
            var employmentCycle = this_1.hasSalaryCycles
                ? this_1.salaryCycles.find(function (cycle) { return cycle.Id === employment.SalaryCycleId; })
                : undefined;
            if (employmentCycle) {
                switch (employmentCycle.Key) {
                    case 'Weekly':
                        this_1.usersWeekly++;
                        break;
                    case '14Days':
                    case '14Days2':
                        this_1.users14Day++;
                        break;
                    default:
                        // GS-2187 : Cycle not Weekly nor 14days is Monthly.
                        this_1.usersMonthly++;
                        break;
                }
            }
        };
        var this_1 = this;
        for (var _i = 0, employementData_1 = employementData; _i < employementData_1.length; _i++) {
            var employment = employementData_1[_i];
            _loop_1(employment);
        }
        if (this.usersWeekly === 0 && this.users14Day === 0 && this.usersMonthly === 0) {
            this.usersMonthly = 1;
        }
        if (!this.employementDataSubject) {
            this.employementDataSubject = new BehaviorSubject([]);
        }
        this.employementDataSubject.next(employementData);
    };
    CompanyModuleService.prototype.loadBilledMessage = function () {
        var _this = this;
        if ((this.sessionService.role.IsFullAdmin || this.sessionService.role.IsMultiCompanyAdmin) &&
            this.sessionService.feature.IsMultiCompanyMember) {
            this.staticDataService.companyAccount.pipe().subscribe(function (data) {
                if (data && data.AccountType) {
                    _this.translate.get(['Accountant.BillViaMCMessage']).subscribe(function (translations) {
                        _this.billedMessage = translations['Accountant.BillViaMCMessage'].replace('{{SystemAlias}}', environment.branding.SystemAlias);
                        _this.billedMessage = _this.billedMessage.replace('{company.Account.AccountType.Name}', data.AccountType.Name);
                    });
                }
            });
        }
    };
    CompanyModuleService.prototype.calculatePriceByBillingPrinciple = function (billingPrincipleId, price) {
        var totalUsers = 0 + this.users14Day + this.usersMonthly + this.usersWeekly;
        switch (billingPrincipleId) {
            case 1: // Per payslip
                return price * (this.usersMonthly + this.users14Day * 2.17 + this.usersWeekly * 4.33);
            case 2: // Per salary batch
                //// TODO: Ask Daniel
                return 0;
            case 3: // Per month
                return price;
            case 4: // Per user per month
                return price * totalUsers;
            case 5: // Per unit
                //// TODO: Ask Daniel
                return 0;
        }
        return 0;
    };
    CompanyModuleService.prototype.onConfirmDialogAction = function (action) {
        var _this = this;
        if (action === 'Understood') {
            var selectedModules = this.modulesSubject.value
                // .filter((m: IModuleCompanyView) => m.IsEnabled && !m.InfoOnly)
                .filter(function (m) { return m.IsEnabled; })
                .map(function (m) { return m.ModuleId; });
            this.dataService.Modules_SetEnabledModules(this.selectedPackageId, selectedModules).subscribe(function () {
                _this.settingService.ReloadWarrning = true;
                _this.staticDataService
                    .loadStaticData()
                    .then(function () {
                    window.location.reload();
                })
                    .catch(function () {
                    _this.settingService.ReloadWarrning = false;
                });
            }, function (err) {
                _this.loadModules();
            });
        }
    };
    CompanyModuleService.prototype.onSubmit = function (packageId) {
        this.selectedPackageId = packageId;
        this.confirmDialogVisible = true;
    };
    Object.defineProperty(CompanyModuleService.prototype, "isMultiCompanyMember", {
        get: function () {
            return this.sessionService.feature.IsMultiCompanyMember;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyModuleService.prototype, "isFullAdmin", {
        get: function () {
            return this.sessionService.role.IsFullAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyModuleService.prototype, "showBilledViaMCMessage", {
        get: function () {
            return (Global &&
                Global.SESSION &&
                Global.SESSION.SignOnToken.UserAccountId &&
                Global.SESSION.CurrentCompany.InvoiceType &&
                this.clientAccountInvoiceType &&
                this.isMultiCompanyMember &&
                Global.SESSION.CurrentCompany.InvoiceType.Id !== this.clientAccountInvoiceType.Id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyModuleService.prototype, "hasSalaryCycles", {
        get: function () {
            return !!this.salaryCycles && !!this.salaryCycles.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyModuleService.prototype, "allowShortcut", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.modules';
        },
        enumerable: true,
        configurable: true
    });
    CompanyModuleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyModuleService_Factory() { return new CompanyModuleService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.StaticDataService), i0.ɵɵinject(i3.SettingService), i0.ɵɵinject(i4.SessionService), i0.ɵɵinject(i5.TransitionService), i0.ɵɵinject(i6.BroadcastService), i0.ɵɵinject(i7.TranslateService)); }, token: CompanyModuleService, providedIn: "root" });
    return CompanyModuleService;
}(CompanyService));
export { CompanyModuleService };
