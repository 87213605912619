import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlComponentBase } from '../../common/control-component-base';
import { UniqueId } from '../../common/unique-id';
import { SessionService } from '../../services/session/session.service';
@Component({
  selector: 'app-percentage-edit',
  templateUrl: './percentage-edit.component.html'
})
export class PercentageEditComponent extends ControlComponentBase<number> implements OnInit {
  @Input() public label: string;
  @Input() public validationMessage: string;
  @Input() public required: boolean;
  @Input() public allowDecimal = false;
  @Input() public minValue: string;
  @Input() public maxValue: string;
  @Input() public disable = false;
  @Input() public percentageSign = true;
  @Input() public rightText: boolean;
  @Input() public step = false;
  @Input() public helpLink: string;
  @Input() public helpTooltip = '';
  @Input() public labelTooltip = '';
  @Input() public valueTooltip = '';
  @Input() public helpIcon = 'Help';
  @Input() public isOpenLink = true;
  @Input() public isSelfService = '';
  @Input() public maxHeight20 = '';
  @Input() public isValueIcon: boolean;
  @Input() public decimals = 2;

  @Output() public blur: EventEmitter<void> = new EventEmitter<void>();
  @Output() public keyUp: EventEmitter<void> = new EventEmitter<void>();

  public get decimalsCode(): string {
    if (this.decimals === 2) {
      return 'n2';
    }
    return 'n' + this.decimals;
  }

  public get decimalsCaculateCode(): string {
    return 'n' + (this.decimals + 2).toString();
  }

  public componentId: string;

  constructor(elementRef: ElementRef, private sessionService: SessionService, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
    this.componentId = 'percentDecimalControlIdString' + UniqueId();
  }

  public ngOnInit(): void {
    // this.onChange();
  }

  protected valid(): boolean {
    return !this.required || this.value === 0 || !!this.value;
  }

  public get isInvalid(): boolean {
    return this.editMode && !this.valid();
  }

  public get percentageDisplayText(): string {
    return this.percentValue || this.percentValue === 0
      ? this.sessionService.toString(
          this.percentValue > 0 ? this.percentValue : 0,
          this.allowDecimal ? this.decimalsCode : 'n0'
        ) + ' %'
      : '';
  }

  public get percentValue(): number {
    return this.value && this.value > 0 ? this.value * 100 : this.value;
  }

  public set percentValue(value: number) {
    this.value = value > 0 ? value / 100 : value;
  }

  public onKeydown(key: any): void {
    if (key.keyCode === 27) {
      // Escape
      this.value = this.original;
    }
  }

  public onkeyup(key: any): void {
    this.keyUp.emit(key);
  }

  public onBlur(): void {
    this.value = this.value > 0 ? this.value * 100 : this.value;
    if (this.value !== undefined && this.value !== 0 && this.value !== null) {
      if (this.value > 1) {
        this.value = this.sessionService.parseFloat(
          this.sessionService.toString(this.value / 100, this.decimalsCaculateCode)
        );
      } else {
        this.value =
          this.value || this.value === 0
            ? this.sessionService.parseFloat(this.sessionService.toString(this.value / 100, this.decimalsCaculateCode))
            : this.value;
      }
    } else if (this.value === 0) {
      this.value = 0;
    } else {
      this.value = null;
    }

    this.blur.emit();
  }
}
