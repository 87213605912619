import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Global } from '../common/global';
import {
  CompanyUser,
  IBatchOperationResponse_User_User,
  IUserCompanyAccess,
  User,
  UserAccessPermissionsRequest
} from '../services/api/api-model';
import { DataService } from '../services/api/data.service';
import { StaticDataService } from '../services/api/static-data.service';
import { ModalService } from '../services/modal.service';
import { SessionService } from '../services/session/session.service';

export abstract class AccountantServiceBase {
  private editModeValue: boolean;
  public get editMode(): boolean {
    if (this.isBasicUser) {
      return false;
    }
    return this.editModeValue;
  }
  public set editMode(value: boolean) {
    this.editModeValue = value;
    this.sessionService.hideGetStarted = value;
  }

  public get roles(): Observable<IUserCompanyAccess[]> {
    return this.rolesSubject.asObservable();
  }

  public get isBasicUser(): boolean {
    return Global.SESSION && Global.SESSION.AccountRoleId !== 90 && Global.SESSION.UserAccountId !== null;
  }

  public get isCompanyAdmin(): boolean {
    return Global.SESSION && Global.SESSION.AccountRoleId === null && Global.SESSION.CurrentRole.Id === 90;
  }

  public get isAccountAdmin(): boolean {
    return Global.SESSION && Global.SESSION.IsAccountAdmin;
  }

  public get isShowEditMode(): boolean {
    if (this.isCompanyAdmin || this.isBasicUser) {
      return false;
    }

    return true;
  }

  private filterValue: string;
  public get filter(): string {
    return this.filterValue;
  }
  public set filter(value: string) {
    this.filterValue = value;
    this.filterSubject.next(value);
  }

  private currentPageValue = 0;
  public get currentPage(): number {
    return this.currentPageValue;
  }
  public set currentPage(value: number) {
    this.currentPageValue = value;
  }

  private selectedIdValue: number;
  public get selectedId(): number {
    return this.selectedIdValue;
  }
  public set selectedId(value: number) {
    if (this.selectedIdValue !== value) {
      this.selectedIdValue = value;
    }
  }

  protected rolesSubject: BehaviorSubject<IUserCompanyAccess[]> = new BehaviorSubject<IUserCompanyAccess[]>([]);
  private filterSubject: BehaviorSubject<string> = new BehaviorSubject('');
  protected isLoading = false;
  protected ngUnsubscribe: Subject<{}> = new Subject();

  constructor(
    protected api: DataService,
    protected modalService: ModalService,
    protected sessionService: SessionService,
    protected staticDataService: StaticDataService
  ) {
    this.loadData();
    this.filterSubject
      .asObservable()
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(() => this.applyFilter());

    this.modalService.modalServiceCloseErrorAlert.subscribe((event: boolean) => {
      if (event) {
        this.reloadData();
      }
    });
  }

  public abstract loadData(): void;

  public save(dataRolelist: UserAccessPermissionsRequest[], isReload: boolean = true): void {
    if (dataRolelist && dataRolelist.length > 0) {
      this.api.Account_BatchAssignUsersAccessToCompany(dataRolelist).subscribe(
        (): void => {
          if (isReload) {
            this.getRoles();
          }
        },
        (): void => {
          if (isReload) {
            this.getRoles();
          }
        }
      );
    }
  }

  public saveUserList(userList: User[]) {
    if (userList && userList.length > 0) {
      this.api.Users_UpdateUsers(userList).subscribe((response: IBatchOperationResponse_User_User) => {
        if (response.ProcessedItems && response.ProcessedItems.length > 0) {
          this.updateData(response.ProcessedItems);
        }
        if (response.Errors && response.HasErrors && response.Errors['Intect.User']) {
          this.modalService.alert('', response.Errors['Intect.User'], true);
        }
      });
    }
  }

  public discard(): void {
    this.getRoles();
  }

  protected abstract getRoles(): void;

  protected abstract applyFilter(): void;

  protected abstract updateData(model: User[]): void;

  protected abstract reloadData(): void;
}
