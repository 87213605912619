import { OnDestroy, OnInit } from '@angular/core';
import { PanelBarComponent } from '@progress/kendo-angular-layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionService } from '../../../services/session/session.service';
import { ReportDialogService } from '../../../shared-components/report-dialog/report-dialog.service';
import { CompanyPreferenceService } from '../preference.service';
var PreferenceMobileComponent = /** @class */ (function () {
    function PreferenceMobileComponent(sessionService, reportDialogService, companyPreferenceService) {
        var _this = this;
        this.sessionService = sessionService;
        this.reportDialogService = reportDialogService;
        this.companyPreferenceService = companyPreferenceService;
        this.numericTextBoxOption = { min: 0, step: 1, spinners: false, decimals: 0, format: 'n0' };
        this.ngUnsubscribe = new Subject();
        this.companyPreferenceService.reloadPanel.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            if (data) {
                _this.startPanel();
            }
        });
    }
    Object.defineProperty(PreferenceMobileComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    PreferenceMobileComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    PreferenceMobileComponent.prototype.ngOnInit = function () { };
    PreferenceMobileComponent.prototype.startPanel = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.companyPanelBar) {
                _this.childDataCompanyPanel = _this.companyPanelBar.childrenItems;
                if (_this.childDataCompanyPanel && _this.childDataCompanyPanel.length > 0) {
                    _this.childDataCompanyPanel[0].selected = true;
                    _this.childDataCompanyPanel[0].expanded = true;
                }
            }
        });
    };
    PreferenceMobileComponent.prototype.onCompanyPanelChanged = function (event) {
        var _this = this;
        if (event && event.length > 0) {
            event.forEach(function (ev) {
                if (ev.expanded) {
                    var childs = _this.childDataCompanyPanel.find(function (data) { return data.id === ev.id; });
                    if (childs && childs.childrenItems && childs.childrenItems.length === 1) {
                        childs.childrenItems[0].selected = true;
                        childs.childrenItems[0].expanded = true;
                    }
                }
            });
        }
    };
    return PreferenceMobileComponent;
}());
export { PreferenceMobileComponent };
