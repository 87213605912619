import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DataService } from 'src/app/services/api/data.service';
import { SessionService } from 'src/app/services/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class AccountantJoinService {
  private joinableAccountsSubject: BehaviorSubject<
    Array<{ Id: number; Name: string; AccountTypeId: number; Description: string; Icon: string }>
  >;
  public get joinableAccounts() {
    if (!this.joinableAccountsSubject) {
      this.joinableAccountsSubject = new BehaviorSubject<
        Array<{ Id: number; Name: string; AccountTypeId: number; Description: string; Icon: string }>
      >([]);
      this.loadJoinableAccounts();
    }

    return this.joinableAccountsSubject.asObservable();
  }

  private filterSubject: BehaviorSubject<string> = new BehaviorSubject('');
  private filterValue = '';
  public get filter() {
    return this.filterValue;
  }
  public set filter(value: string) {
    this.filterValue = value;
    this.filterSubject.next(value);
  }

  public selectedAccount: {
    Id?: number;
    Name?: string;
    Description?: string;
  } = {};

  private allAccounts: Array<{
    Id: number;
    Name: string;
    AccountTypeId: number;
    Description: string;
    Icon: string;
  }> = [];

  constructor(private dataService: DataService, private sessionService: SessionService) {
    this.filterSubject
      .asObservable()
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(() => this.applyFilter());
  }

  public joinAccount(id: number) {
    return this.dataService.Account_AttachCurrentCompany(id);
  }

  private loadJoinableAccounts() {
    if (!this.sessionService.feature.IsMultiCompanyMember && this.sessionService.role.IsFullAdmin) {
      this.dataService.Account_GetJoinablePublicAccounts().subscribe((data) => {
        this.allAccounts = data.map((d) => ({
          Id: d.Id,
          Name: d.Name,
          AccountTypeId: d.AccountTypeId,
          Description: d.Description,
          Icon: 'CalendarCheck'
        }));
        this.applyFilter();
      });
    }
  }

  private applyFilter() {
    const result = this.allAccounts.filter((a) => {
      if (this.filterValue && !a.Name.toLocaleLowerCase().includes(this.filterValue.toLocaleLowerCase())) {
        return false;
      }

      return true;
    });

    this.joinableAccountsSubject.next(result);
    this.selectedAccount = {};
  }
}
