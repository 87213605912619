import { ElementRef, EventEmitter } from '@angular/core';
import { CompanyAdvancedTypesService } from '../../company-advanced-types.service';
var AdvancedTypesListComponent = /** @class */ (function () {
    function AdvancedTypesListComponent(companyAdvancedTypesService) {
        this.companyAdvancedTypesService = companyAdvancedTypesService;
        this.currentEditmode = false;
        this.showTranslationDialog = new EventEmitter();
    }
    Object.defineProperty(AdvancedTypesListComponent.prototype, "isEditMode", {
        get: function () {
            return this.currentEditmode;
        },
        set: function (value) {
            var _this = this;
            if (this.types && this.types.length > 0) {
                if (value) {
                    this.types.forEach(function (model) {
                        model.isActiveFeild = model.Id === _this.selectedId ? 'activeCell' : '';
                    });
                }
                else {
                    this.types.forEach(function (model) {
                        model.isActiveFeild = '';
                    });
                }
                this.refreshList = true;
            }
            this.currentEditmode = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdvancedTypesListComponent.prototype, "selectedId", {
        get: function () {
            return this.selectedIdValue;
        },
        set: function (value) {
            if (value && this.selectedIdValue !== value) {
                if (this.isEditMode) {
                    this.scrollToNewRow(value);
                }
                this.selectedIdValue = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdvancedTypesListComponent.prototype, "refreshList", {
        get: function () {
            return this.refreshListValue;
        },
        set: function (value) {
            if (value) {
                this.triggerGridRefresh = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    AdvancedTypesListComponent.prototype.onSelectionChange = function (type) {
        this.companyAdvancedTypesService.selectedType = type;
    };
    Object.defineProperty(AdvancedTypesListComponent.prototype, "classDisableSelect", {
        get: function () {
            if (this.isEditMode) {
                return 'disableSelect';
            }
            return 'GridSelect';
        },
        enumerable: true,
        configurable: true
    });
    AdvancedTypesListComponent.prototype.scrollToNewRow = function (selectTemplateId) {
        var _this = this;
        setTimeout(function () {
            var count = 0;
            var check = false;
            _this.types.forEach(function (item) {
                item.Id !== selectTemplateId && !check ? count++ : (check = true);
                item.isActiveFeild = item.Id === _this.selectedId ? 'activeCell' : '';
            });
            if (_this.advancedTypesList && _this.advancedTypesList.kendoGrid) {
                var element = _this.advancedTypesList.kendoGrid.wrapper.nativeElement.getElementsByTagName('tr')[count + 1];
                if (element) {
                    element.scrollIntoView(false);
                }
            }
        });
    };
    AdvancedTypesListComponent.prototype.onCellClick = function (event) {
        if (event.field === 'Name' && this.companyAdvancedTypesService.isLanguageModuleEnable && event.isClickToIcon) {
            this.showTranslationDialog.emit();
        }
    };
    AdvancedTypesListComponent.prototype.onSaveName = function () {
        if (!this.companyAdvancedTypesService.isLanguageModuleEnable) {
            this.companyAdvancedTypesService.tabDirty = true;
        }
    };
    return AdvancedTypesListComponent;
}());
export { AdvancedTypesListComponent };
