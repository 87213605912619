import { TransitionService } from '@uirouter/core';
import { Global } from '../common/global';
import { RouterStateService } from '../common/router-state.service';
import { EmployeeService } from '../services/employee.service';
import { SessionService } from '../services/session/session.service';
import { ReportDialogService } from '../shared-components/report-dialog/report-dialog.service';
var AccountantComponent = /** @class */ (function () {
    function AccountantComponent(session, state, reportDialogService, employeeService, transitionService) {
        var _this = this;
        this.session = session;
        this.state = state;
        this.reportDialogService = reportDialogService;
        this.employeeService = employeeService;
        this.transitionService = transitionService;
        this.transitionService.onStart({}, function (transition) {
            var fromState = transition.from().name;
            var toState = transition.to().name;
            if (fromState &&
                toState &&
                fromState !== toState &&
                fromState.includes('tabs.accountant') &&
                !toState.includes('tabs.accountant') &&
                _this.employeeService.isNonSelected) {
                if (_this.session.feature.AccountantTabEnabled) {
                    setTimeout(function () {
                        _this.employeeService.filterEmployees();
                    });
                }
            }
            return true;
        });
    }
    Object.defineProperty(AccountantComponent.prototype, "IsMobile", {
        get: function () {
            return this.session.browser.isMobile || this.session.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantComponent.prototype, "IsMultiCompanyUser", {
        get: function () {
            return Global.SESSION && Global.SESSION.IsAccountMember && Global.SESSION.SignOnToken.UserAccountId !== null;
        },
        enumerable: true,
        configurable: true
    });
    AccountantComponent.prototype.clickAreamenuToggledChange = function () {
        this.session.menuToggled = true;
        this.session.showMoreMenuToggled = false;
        this.session.showHelpMenuToggled = false;
        this.session.showMenuToggled = false;
    };
    return AccountantComponent;
}());
export { AccountantComponent };
