import { EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../common/constants';
import { KeyDownHelper } from '../common/key-down-helper';
var CompanyService = /** @class */ (function () {
    function CompanyService(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) {
        var _this = this;
        this.dataService = dataService;
        this.staticDataService = staticDataService;
        this.settingService = settingService;
        this.sessionService = sessionService;
        this.transitionService = transitionService;
        this.broadcaster = broadcaster;
        this.confirmUpdateDialogVisible = false;
        this.navigationToStateName = '';
        this.ngUnsubscribe = new Subject();
        this.editModeChange = new EventEmitter();
        this.editShortcutChange = new EventEmitter();
        this.discardChangeShortcutChange = new EventEmitter();
        this.saveChangeShortcutChange = new EventEmitter();
        this.showReportShortcutChange = new EventEmitter();
        this.addNewShortcutChange = new EventEmitter();
        this.transitionService.onStart({}, function (transition) {
            var fromState = transition.from().name;
            var toState = transition.to().name;
            _this.navigationToStateName = JSON.parse(JSON.stringify(toState));
            if (_this.editMode && fromState !== toState) {
                if (_this.tabValid && _this.tabDirty) {
                    _this.confirmUpdateDialogVisible = true;
                }
                else {
                    _this.editMode = false;
                    _this.sessionService.NavigateTo(_this.navigationToStateName);
                    return true;
                }
                return false;
            }
            return true;
        });
        this.broadcaster
            .on(Constants.GLOBAL_KEY_DOWN)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) { return _this.onGlobalKeyDown(data); });
    }
    Object.defineProperty(CompanyService.prototype, "editMode", {
        get: function () {
            return this.editModeValue;
        },
        set: function (value) {
            if (this.allowChangeEdit(value) && this.editModeValue !== value) {
                this.editModeValue = value;
                this.sessionService.hideGetStarted = value;
                this.editModeChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyService.prototype, "tabValid", {
        get: function () {
            return this.tabValidValue;
        },
        set: function (value) {
            if (this.tabValidValue !== value) {
                this.tabValidValue = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyService.prototype, "tabDirty", {
        get: function () {
            return this.tabDirtyValue;
        },
        set: function (value) {
            if (this.tabDirtyValue !== value) {
                this.tabDirtyValue = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyService.prototype, "isIOSApp", {
        get: function () {
            return this.sessionService.browser.isHybridApp && this.sessionService.browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyService.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyService.prototype, "IsMobileOrHybridApp", {
        get: function () {
            return this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyService.prototype, "isIOSMobileApp", {
        get: function () {
            return this.sessionService.browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    CompanyService.prototype.reloadCompanyPreference = function () {
        // TODO
        // reload company Preference from api Preferences_GetCompanyPreferences
    };
    CompanyService.prototype.onConfirmSaveDataOnChangeState = function (action) {
        if (action === 'Continue') {
            this.onContinueAction();
        }
        else if (action === 'DiscardAndLeave') {
            this.onDiscardAndLeaveAction();
        }
    };
    CompanyService.prototype.onGlobalKeyDown = function ($event) {
        if (this.allowShortcut && !this.sessionService.isGetting && !this.sessionService.isSubmitting) {
            if (KeyDownHelper.isEditShortcutKey($event)) {
                $event.preventDefault();
                this.editShortcut();
                return;
            }
            if (KeyDownHelper.isDiscardingChangesShortcutKey($event)) {
                $event.preventDefault();
                this.discardChangeShortcut();
                return;
            }
            if (KeyDownHelper.isSavingChangesShortcutKey($event)) {
                $event.preventDefault();
                this.saveChangeShortcut();
                return;
            }
            if (KeyDownHelper.isReportShortcutKey($event)) {
                $event.preventDefault();
                this.showReportShortcut();
                return;
            }
            if (KeyDownHelper.isNewShortcutKey($event)) {
                $event.preventDefault();
                this.addNewShortcut();
                return;
            }
        }
    };
    CompanyService.prototype.moveToNextState = function () {
        if (this.navigationToStateName) {
            this.sessionService.NavigateTo(this.navigationToStateName);
        }
    };
    CompanyService.prototype.editShortcut = function () {
        this.editShortcutChange.emit(true);
    };
    CompanyService.prototype.discardChangeShortcut = function () {
        this.discardChangeShortcutChange.emit(true);
    };
    CompanyService.prototype.saveChangeShortcut = function () {
        this.saveChangeShortcutChange.emit(true);
    };
    CompanyService.prototype.showReportShortcut = function () {
        this.showReportShortcutChange.emit(true);
    };
    CompanyService.prototype.addNewShortcut = function () {
        this.addNewShortcutChange.emit(true);
    };
    CompanyService.prototype.allowChangeEdit = function (value) {
        return true;
    };
    Object.defineProperty(CompanyService.prototype, "allowShortcut", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    CompanyService.prototype.onContinueAction = function () { };
    CompanyService.prototype.onDiscardAndLeaveAction = function () { };
    return CompanyService;
}());
export { CompanyService };
