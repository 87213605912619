import { Component, Input } from '@angular/core';
import { EmployeeService } from 'src/app/services/employee.service';
import { DefaultAvatar } from '../../../model/default-avatar';

@Component({
  selector: 'app-employee-avatar',
  templateUrl: './employee-avatar.component.html'
})
export class EmployeeAvatarComponent {
  @Input() public editMode: boolean;

  constructor(public employeeService: EmployeeService) {}
}
