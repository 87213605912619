import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlComponentBase } from '../../common/control-component-base';
import { UniqueId } from '../../common/unique-id';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-text-edit',
  templateUrl: './text-edit.component.html'
})
export class TextEditComponent extends ControlComponentBase<string> implements OnInit {
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public icon: string;
  @Input() public type: string;
  @Input() public required: boolean;
  @Input() public wrongValue: boolean;
  @Input() public isShowSymbolRequired = false;
  @Input() public mask: string;
  @Input() public disabled: boolean;
  @Input() public addonIcon: string;
  @Input() public addonLink: string;
  @Input() public addonTooltip: string;
  @Input() public tooltipContent: string;
  @Input() public tooltipTextArea: string;
  @Input() public inputClass: string;
  @Input() public viewClass: string;
  @Input() public readonlyOnNotFocus: boolean;
  @Input() public maxLength: number;
  @Input() public requiredMessageKey: string;
  @Input() public hostClass: string;
  @Input() public addonSize: string;
  @Input() public isTextArea = false;
  @Input() public textAreaLimitChar = 200;
  @Input() public TextAreaRows = 4;
  @Input() public autocorrect = 'on';
  @Input() public addonVisible = true;
  @Input() public isShowLimit = true;

  @ViewChild('TextEditControl', { static: false }) public textControlInput: ElementRef;

  @Output() public blur: EventEmitter<void> = new EventEmitter<void>();
  @Output() public addonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public keyUp: EventEmitter<void> = new EventEmitter<void>();
  @Output() public iconClick: EventEmitter<string> = new EventEmitter<string>();

  public readOnly = false;
  public elementId: string;
  public showPassword = false;

  constructor(private sessionService: SessionService, elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
    this.elementId = 'textEditComponentId' + UniqueId();
    this.setTimeout = false;
  }

  public ngOnInit(): void {
    this.readOnly = this.readonlyOnNotFocus && this.sessionService.browser.isChrome;
  }

  public get TextLenght(): string {
    if (this.value) {
      if (this.textAreaLimitChar === 0) {
        return this.textAreaLimitChar.toString();
      }
      const res: number = this.textAreaLimitChar - this.value.length;
      return res >= 0 ? res.toString() : '0';
    }
    return this.textAreaLimitChar.toString();
  }

  public get TextAreaClass(): string {
    if (this.value && this.value.length > this.textAreaLimitChar && this.textAreaLimitChar !== 0) {
      return ' input-required';
    }
    return '';
  }

  public TextAreakeypress(): void {
    if (this.value && this.value.length > this.textAreaLimitChar) {
      this.value = this.value.substring(0, this.textAreaLimitChar);
    }
  }

  public get requiredValueMissing(): boolean {
    return (this.required && (!this.value || this.value.length === 0)) || this.wrongValue === true;
  }
  public get showRequiredMessage(): boolean {
    return this.editMode && this.requiredValueMissing;
  }
  public get showValidationMessage(): boolean {
    return this.editMode && !this.valid() && this.type === 'email';
  }

  public setInputClass(): string {
    return (
      (this.inputClass ? this.inputClass : '') +
      (this.showValidationMessage || this.requiredValueMissing ? ' input-required' : '')
    );
  }

  public get hasMask(): boolean {
    return this.mask && this.mask.length > 0;
  }

  public onBlur(): void {
    if (this.readonlyOnNotFocus && this.sessionService.browser.isChrome) {
      this.readOnly = false;
    }

    this.blur.emit();
  }

  public onFocus(): void {
    if (this.readonlyOnNotFocus && this.sessionService.browser.isChrome) {
      this.readOnly = false;
    }
    if (this.textControlInput) {
      if (this.isGrid) {
        setTimeout(() => {
          this.textControlInput.nativeElement.select();
        });
      } else {
        this.textControlInput.nativeElement.select();
      }
    }
  }

  private emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(((?![-@])(?!^[-@])[^@]*[A-z|ñ|Ñ|a-zA-ZæøåÆØÅa-zA-ZÀ-ÿ|0-9]+\.)+[a-zA-Z|ñ|Ñ|a-zA-ZæøåÆØÅa-zA-ZÀ-ÿ0-9]{2,}))$/;

  protected valid(): boolean {
    if (this.required && (!this.value || this.value === '')) {
      return false;
    }

    switch (this.type) {
      case 'email':
        return !this.value || this.value.length === 0 || this.emailRegex.test(this.value.trim());
      default:
        return true;
    }
  }

  public onkeyup(key: any): void {
    this.keyUp.emit(key);
  }

  protected onControlValidate(): boolean {
    if (this.type === 'email' && this.value) {
      if (this.emailRegex.test(this.value.trim())) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
