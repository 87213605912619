/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@progress/kendo-angular-tooltip";
import * as i2 from "@progress/kendo-angular-popup";
import * as i3 from "./tool-tip-price.component";
import * as i4 from "../../../../windowReference";
var styles_TooltipPriceComponent = [];
var RenderType_TooltipPriceComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TooltipPriceComponent, data: {} });
export { RenderType_TooltipPriceComponent as RenderType_TooltipPriceComponent };
function View_TooltipPriceComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.Tooltiptitle; _ck(_v, 0, 0, currVal_0); }); }
export function View_TooltipPriceComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { tooltip: 0 }), (_l()(), i0.ɵand(0, [["template", 2]], null, 0, null, View_TooltipPriceComponent_1)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "span", [["closable", "true"], ["kendoTooltip", ""], ["showOn", "none"]], null, null, null, null, null)), i0.ɵdid(3, 9125888, [[1, 4], ["tooltip", 4]], 0, i1.TooltipDirective, [i0.ElementRef, i0.NgZone, i0.Renderer2, i2.PopupService, [2, i1.TooltipSettings], [2, i1.TOOLTIP_SETTINGS]], { showOn: [0, "showOn"], closable: [1, "closable"], tooltipWidth: [2, "tooltipWidth"], tooltipTemplate: [3, "tooltipTemplate"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, [["anchor", 1]], null, 0, "abbr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(i0.ɵnov(_v, 5)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, ":svg:svg", [["aria-hidden", "true"], ["class", "Icon Icon--medium"], ["ng-reflect-ng-class", "Icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, ":svg:use", [[":xlink:href", "#info"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "none"; var currVal_1 = "true"; var currVal_2 = i0.ɵinlineInterpolate(1, "", _co.tooltipPackageWidth, ""); var currVal_3 = i0.ɵnov(_v, 1); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_TooltipPriceComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tool-tip-price", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_TooltipPriceComponent_0, RenderType_TooltipPriceComponent)), i0.ɵdid(1, 49152, null, 0, i3.TooltipPriceComponent, [i4.WindowReference], null, null)], null, null); }
var TooltipPriceComponentNgFactory = i0.ɵccf("tool-tip-price", i3.TooltipPriceComponent, View_TooltipPriceComponent_Host_0, { Tooltiptitle: "Tooltiptitle" }, {}, []);
export { TooltipPriceComponentNgFactory as TooltipPriceComponentNgFactory };
