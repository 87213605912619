<div class="FormElement" [ngClass]="{ 'has-fixedAddon': addonIcon, 'FormElement-ViewMode': !editMode }">
  <span
    *ngIf="label && tooltipContent"
    kendoTooltip
    title="{{ tooltipContent | translate }}"
    [ngClass]="{ 'u-hiddenVisually': icon }"
    class="FormElement-label keepIconInline"
  >
    <span [ngClass]="{ textWithTooltip: tooltipContent }">
      <abbr *ngIf="isShowSymbolRequired" class="FormElement-required" title="required">*</abbr>{{ label
      }}<i *ngIf="!hasMask && editMode && isTextArea && isShowLimit">( {{ TextLenght }} )</i>:
    </span>
    <span *ngIf="addonIcon && !addonSize && addonVisible" class="iconColor iconPosition">
      <app-icon [icon]="addonIcon" [tooltip]="addonTooltip" [link]="addonLink" (click)="addonClick.emit()"></app-icon>
    </span>
    <span *ngIf="addonIcon && addonSize && addonVisible" class="iconColor iconPosition">
      <app-icon
        [icon]="addonIcon"
        [size]="addonSize"
        [tooltip]="addonTooltip"
        [link]="addonLink"
        (click)="addonClick.emit()"
      ></app-icon>
    </span>
  </span>

  <span
    *ngIf="label && !tooltipContent"
    [ngClass]="{ 'u-hiddenVisually': icon }"
    class="FormElement-label keepIconInline"
  >
    <span [ngClass]="{ textWithTooltip: tooltipContent }">
      <abbr *ngIf="isShowSymbolRequired" class="FormElement-required" title="required">*</abbr>{{ label
      }}<i *ngIf="!hasMask && editMode && isTextArea && isShowLimit">( {{ TextLenght }} )</i>:
    </span>
    <span *ngIf="addonIcon && !addonSize && addonVisible" class="iconColor iconPosition">
      <app-icon [icon]="addonIcon" [tooltip]="addonTooltip" [link]="addonLink" (click)="addonClick.emit()"></app-icon>
    </span>
    <span *ngIf="addonIcon && addonSize && addonVisible" class="iconColor iconPosition">
      <app-icon
        [icon]="addonIcon"
        [size]="addonSize"
        [tooltip]="addonTooltip"
        [link]="addonLink"
        (click)="addonClick.emit()"
      ></app-icon>
    </span>
  </span>

  <div
    kendoTooltip
    title="{{ tooltipTextArea }}"
    *ngIf="tooltipTextArea"
    class="FormElement-control"
    [ngClass]="{ 'FormElement-control--iconLeft': icon }"
  >
    <div class="FormElement-static" *ngIf="!editMode" [ngClass]="viewClass" [innerHTML]="value"></div>
    <app-icon *ngIf="icon" [tooltip]="addonTooltip" [icon]="icon" (click)="iconClick.emit(icon)"></app-icon>

    <kendo-maskedtextbox
      [id]="elementId"
      [mask]="mask"
      [disabled]="disabled"
      *ngIf="hasMask && editMode"
      [readonly]="readOnly"
      [ngClass]="{ inputClass: inputClass, 'input-required': !isValid || this.requiredValueMissing }"
      [(ngModel)]="value"
      (focus)="onFocus()"
      (blur)="onBlur()"
    >
    </kendo-maskedtextbox>

    <input
      #TextEditControl
      autocomplete="{{ autocorrect && type !== 'email' ? 'true' : 'false' }}"
      autofill="true"
      kendoTextBox
      [id]="elementId"
      [maxLength]="maxLength || 9999"
      [placeholder]="placeholder || ''"
      class="FormElement-input"
      [disabled]="disabled"
      [readonly]="readOnly"
      *ngIf="!hasMask && editMode && !isTextArea"
      [ngClass]="setInputClass()"
      [(ngModel)]="value"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [type]="type === 'password' ? (showPassword ? 'text' : 'password') : type === 'email' ? 'contact' : type"
      (keyup)="onkeyup($event)"
    />

    <textarea
      kendoTextArea
      [id]="elementId"
      [maxLength]="maxLength || 9999"
      [placeholder]="placeholder || ''"
      class="FormElement-input {{ TextAreaClass }}"
      [disabled]="disabled"
      [readonly]="readOnly"
      *ngIf="!hasMask && editMode && isTextArea"
      [ngClass]="setInputClass()"
      [(ngModel)]="value"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (keypress)="TextAreakeypress()"
      rows="{{ TextAreaRows }}"
    ></textarea>

    <button
      *ngIf="type === 'password'"
      id="showPasswordButton"
      class="FormElement-passwordToggle"
      type="button"
      (mousedown)="showPassword = true"
      (mouseup)="showPassword = false"
      (mouseleave)="showPassword = false"
    >
      <app-icon [icon]="'Preview'"></app-icon>
    </button>
  </div>

  <div *ngIf="!tooltipTextArea" class="FormElement-control" [ngClass]="{ 'FormElement-control--iconLeft': icon }">
    <div class="FormElement-static" *ngIf="!editMode" [ngClass]="viewClass" [innerHTML]="value"></div>
    <app-icon *ngIf="icon" [tooltip]="addonTooltip" [icon]="icon" (click)="iconClick.emit(icon)"></app-icon>

    <kendo-maskedtextbox
      [id]="elementId"
      [mask]="mask"
      [disabled]="disabled"
      *ngIf="hasMask && editMode"
      [readonly]="readOnly"
      [ngClass]="{ inputClass: inputClass, 'input-required': !isValid || this.requiredValueMissing }"
      [(ngModel)]="value"
      (focus)="onFocus()"
      (blur)="onBlur()"
    >
    </kendo-maskedtextbox>

    <input
      #TextEditControl
      autocomplete="{{ autocorrect && type !== 'email' ? 'true' : 'false' }}"
      autofill="true"
      kendoTextBox
      [id]="elementId"
      [maxLength]="maxLength || 9999"
      [placeholder]="placeholder || ''"
      class="FormElement-input"
      [disabled]="disabled"
      [readonly]="readOnly"
      *ngIf="!hasMask && editMode && !isTextArea"
      [ngClass]="setInputClass()"
      [(ngModel)]="value"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [type]="type === 'password' ? (showPassword ? 'text' : 'password') : type === 'email' ? 'contact' : type"
      (keyup)="onkeyup($event)"
    />

    <textarea
      kendoTextArea
      [id]="elementId"
      [maxLength]="maxLength || 9999"
      [placeholder]="placeholder || ''"
      class="FormElement-input {{ TextAreaClass }}"
      [disabled]="disabled"
      [readonly]="readOnly"
      *ngIf="!hasMask && editMode && isTextArea"
      [ngClass]="setInputClass()"
      [(ngModel)]="value"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (keypress)="TextAreakeypress()"
      rows="{{ TextAreaRows }}"
    ></textarea>

    <button
      *ngIf="type === 'password'"
      id="showPasswordButton"
      class="FormElement-passwordToggle"
      type="button"
      (mousedown)="showPassword = true"
      (mouseup)="showPassword = false"
      (mouseleave)="showPassword = false"
    >
      <app-icon [icon]="'Preview'"></app-icon>
    </button>
  </div>
</div>
