/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../percentage-edit/percentage-edit.component.ngfactory";
import * as i2 from "../percentage-edit/percentage-edit.component";
import * as i3 from "../../services/session/session.service";
import * as i4 from "./share-percentage.component";
var styles_SharePercentageComponent = [];
var RenderType_SharePercentageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SharePercentageComponent, data: {} });
export { RenderType_SharePercentageComponent as RenderType_SharePercentageComponent };
export function View_SharePercentageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "FormElement has-fixedAddon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "FormElement-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ":"])), (_l()(), i0.ɵeld(3, 0, null, null, 7, "div", [["class", "FormElement-control"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "FormElement-control alignItems"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "app-percentage-edit", [], null, [[null, "isDirtyChange"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isDirtyChange" === en)) {
        var pd_0 = ((_co.childIsDirty[0] = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = (_co.onChange() !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = ((_co.firstValue = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_PercentageEditComponent_0, i1.RenderType_PercentageEditComponent)), i0.ɵdid(6, 114688, null, 0, i2.PercentageEditComponent, [i0.ElementRef, i3.SessionService, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], isDirty: [2, "isDirty"], allowDecimal: [3, "allowDecimal"], disable: [4, "disable"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange" }), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "FormElement-addon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["/"])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "app-percentage-edit", [], null, [[null, "isDirtyChange"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isDirtyChange" === en)) {
        var pd_0 = ((_co.childIsDirty[1] = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = (_co.onChange() !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = ((_co.secondValue = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_PercentageEditComponent_0, i1.RenderType_PercentageEditComponent)), i0.ɵdid(10, 114688, null, 0, i2.PercentageEditComponent, [i0.ElementRef, i3.SessionService, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], isDirty: [2, "isDirty"], allowDecimal: [3, "allowDecimal"], disable: [4, "disable"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.firstValue; var currVal_2 = _co.editMode; var currVal_3 = _co.childIsDirty[0]; var currVal_4 = true; var currVal_5 = _co.disable; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.secondValue; var currVal_7 = _co.editMode; var currVal_8 = _co.childIsDirty[1]; var currVal_9 = true; var currVal_10 = _co.disable; _ck(_v, 10, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 2, 0, currVal_0); }); }
export function View_SharePercentageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-share-percentage", [], null, null, null, View_SharePercentageComponent_0, RenderType_SharePercentageComponent)), i0.ɵdid(1, 180224, null, 0, i4.SharePercentageComponent, [], null, null)], null, null); }
var SharePercentageComponentNgFactory = i0.ɵccf("app-share-percentage", i4.SharePercentageComponent, View_SharePercentageComponent_Host_0, { editMode: "editMode", isDirty: "isDirty", isValid: "isValid", label: "label", disable: "disable", firstValue: "firstValue", secondValue: "secondValue" }, { editModeChange: "editModeChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange", valueChange: "valueChange", firstValueChange: "firstValueChange", secondValueChange: "secondValueChange" }, []);
export { SharePercentageComponentNgFactory as SharePercentageComponentNgFactory };
