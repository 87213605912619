<app-tab-panel [parentTab]="'accountant'"></app-tab-panel>

<div class="Main" [ngClass]="{ Mobile: IsMobile }" tabindex="-1" (click)="clickAreamenuToggledChange()">
  <div class="wrapper">
    <app-module-check [moduleId]="20">
      <app-accountant-companies *ngIf="state.currentStateIs('tabs.accountant.companies')"> </app-accountant-companies>
      <app-accountant-users *ngIf="state.currentStateIs('tabs.accountant.users')"> </app-accountant-users>
      <app-accountant-configuration *ngIf="state.currentStateIs('tabs.accountant.configuration')">
      </app-accountant-configuration>
    </app-module-check>
  </div>
</div>

<app-report-dialog
  [(visible)]="reportDialogService.reportDialogVisible"
  [key]="reportDialogService.key"
  [param]="reportDialogService.reportParams"
>
</app-report-dialog>
