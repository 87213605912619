<div class="FormElement EmployeeInfo-position" *ngIf="!editMode && hasSingleEmployment">
  <span class="FormElement-label">{{ 'Employee.Title' | translate }}:</span>
  <div class="FormElement-control">
    <span class="FormElement-static">
      {{ userEmploymentDataSource[0].DisplayTitle }}
    </span>
    <span
      *ngIf="isEmployeeTab && !sessionService.role.IsReadOnly"
      class="FormElement-addon FormElement-addonSpace Icon--text"
    >
      <app-icon
        *ngIf="!isSelfService && sessionService.role.IsSalaryAdminAndHigher"
        [icon]="'circleplus'"
        [tooltip]="'EmployeeGeneral.CreateEmployment' | translate"
        [size]="'small'"
        (click)="onAddonClick()"
      >
      </app-icon>
    </span>
  </div>
</div>

<app-combobox-edit
  *ngIf="!editMode && hasMultipleEmployments"
  [label]="'Employee.Title' | translate"
  [editMode]="true"
  [keepDataSourceOrder]="true"
  [comboboxDataSource]="userEmploymentDataSource"
  [(isValid)]="childIsValid[1]"
  [(isDirty)]="childIsDirty[1]"
  [textField]="'DisplayTitle'"
  [idField]="'Id'"
  [(value)]="currentPositionId"
  (valueChange)="onPositionChangedEvent($event)"
  [addonVisible]="isEmployeeTab && !isSelfService && sessionService.role.IsSalaryAdminAndHigher"
  [addonSize]="'small'"
  [addonIcon]="'circleplus'"
  [addonTooltip]="'EmployeeGeneral.CreateEmployment' | translate"
  (addonClick)="onAddonClick()"
  class="EmployeeInfo-position"
></app-combobox-edit>

<app-text-edit
  *ngIf="allowEditTitle"
  [label]="'Employee.Title' | translate"
  [editMode]="editMode"
  [type]="'text'"
  [required]="true"
  [(isValid)]="childIsValid[0]"
  [(isDirty)]="childIsDirty[0]"
  [(value)]="currentUserEmployment.Title"
  (valueChange)="onPositionTextInputChange()"
  [addonVisible]="isEmployeeTab && !isSelfService && sessionService.role.IsSalaryAdminAndHigher"
  [addonIcon]="'circleplus'"
  [addonSize]="'small'"
  [addonTooltip]="'EmployeeGeneral.CreateEmployment' | translate"
  (addonClick)="onAddonClick()"
></app-text-edit>

<app-new-employment-dialog
  [(isVisible)]="newEmploymentDialogVisible"
  [currentCompanyUserId]="companyUser?.Id"
></app-new-employment-dialog>
