import { OnDestroy, OnInit } from '@angular/core';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PriceFormatPipe } from 'src/app/custom-controls/pipes/price-format.pipe';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { SettingService } from 'src/app/services/setting.service';
import { environment } from 'src/environments/environment';
import { IntegrationsService } from '../../integrations/integrations.service';
var StandardSalaryTypesComponent = /** @class */ (function () {
    function StandardSalaryTypesComponent(dataService, sessionService, staticDataService, settingService, priceFormatPipe, integrationsService) {
        var _this = this;
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.settingService = settingService;
        this.priceFormatPipe = priceFormatPipe;
        this.integrationsService = integrationsService;
        this.salaryTypes = [];
        this.externalReferences = [];
        this.activateModuleDialogVisible = false;
        this.triggerUpdate = false;
        this.moduleActivatedDialogVisible = false;
        this.moduleActivatedDialogMessage = 'AccountModules.ReloginForChanges';
        this.originDepartmentSalaryTypeMapping = [];
        this.ngUnsubscribe = new Subject();
        this.gridData = this.salaryTypes;
        this.integrationsService.TriggerReload.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (value) {
            if (value) {
                _this.loadData();
            }
        });
        this.integrationsService.departmentSalaryTypeMapping
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) {
            _this.originDepartmentSalaryTypeMapping = data;
            _this.salaryTypes.forEach(function (item) {
                var salarytype = _this.originDepartmentSalaryTypeMapping.find(function (node) { return node.SalaryTypeId === item.SalaryTypeId; });
                item['ExternalReference'] = salarytype ? salarytype.ExternalReference : '';
            });
            _this.triggerUpdate = true;
        });
        this.integrationsService.inItSalaryTypeView();
    }
    Object.defineProperty(StandardSalaryTypesComponent.prototype, "isFullAdmin", {
        get: function () {
            return this.sessionService.role.IsFullAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StandardSalaryTypesComponent.prototype, "descriptionFeildWidth", {
        get: function () {
            return this.integrationsService.isMobile ? 300 : undefined;
        },
        enumerable: true,
        configurable: true
    });
    StandardSalaryTypesComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    StandardSalaryTypesComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.integrationsService.isMobile) {
            this.sessionService.isShowHugeFeaturesAlert = true;
        }
        this.loadSalaryTypes();
        this.integrationsService.getStaticDepartment();
        this.dataService.Integrations_GetAllExternalAccounts().subscribe(function (data) {
            _this.onGetAllExternalAccountsComplete(data);
        });
    };
    Object.defineProperty(StandardSalaryTypesComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StandardSalaryTypesComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    StandardSalaryTypesComponent.prototype.loadData = function () {
        var _this = this;
        this.integrationsService.loadStandardSalaryTypeView().subscribe();
        this.dataService.Integrations_GetAllExternalAccounts().subscribe(function (data) {
            _this.onGetAllExternalAccountsComplete(data);
        });
    };
    StandardSalaryTypesComponent.prototype.loadSalaryTypes = function () {
        var _this = this;
        this.integrationsService.standardSalaryTypeView
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) {
            _this.salaryTypes = data;
            _this.gridData = filterBy(_this.salaryTypes, _this.filter);
            _this.triggerUpdate = true;
            _this.convertExternalRefCodeToFriendlyName();
        });
    };
    StandardSalaryTypesComponent.prototype.onGetAllExternalAccountsComplete = function (externalReferences) {
        this.externalReferences = externalReferences;
        if (this.externalReferences && this.externalReferences.length > 0) {
            this.externalReferences.forEach(function (model) {
                model.FriendlyName = model.AccountNumber + (model.AccountName ? ' - ' + model.AccountName : '');
            });
            this.convertExternalRefCodeToFriendlyName();
        }
    };
    StandardSalaryTypesComponent.prototype.convertExternalRefCodeToFriendlyName = function () {
        var _this = this;
        if (this.salaryTypes &&
            this.salaryTypes.length > 0 &&
            this.externalReferences &&
            this.externalReferences.length > 0) {
            this.salaryTypes.forEach(function (salaryType) {
                salaryType.ExternalReference = _this.getExteralReferenceCode(salaryType.ExternalReference, true);
            });
        }
    };
    StandardSalaryTypesComponent.prototype.onSave = function (args) {
        var _this = this;
        args.dataItem.ExternalReference = this.getExteralReferenceCode(args.dataItem.ExternalReference, true);
        var salaryType = args.dataItem.toSalaryType();
        salaryType.ExternalReference = this.getExteralReferenceCode(salaryType.ExternalReference);
        var request = {
            Id: salaryType.Id ? salaryType.Id : salaryType.SalaryTypeId,
            ExternalReference: salaryType.ExternalReference,
            ImportExternalReference: salaryType.ImportExternalReference
        };
        request = this.staticDataService.checkModelRecord(request);
        if (!this.integrationsService.departmentId) {
            this.dataService.SalaryTypes_UpdateSalaryTypeSimple(request).subscribe(function (data) {
                data.ExternalReference = _this.getExteralReferenceCode(data.ExternalReference, true);
                args.dataItem = data;
                args.dataItem.SalaryTypeId = salaryType.SalaryTypeId;
            }, function (error) { return _this.integrationsService.loadStandardSalaryTypeView().subscribe(); });
        }
        else {
            var temp_1 = this.originDepartmentSalaryTypeMapping.find(function (item) { return item.SalaryTypeId === args.dataItem.Id; });
            // TODO
            if (!temp_1) {
                // add new
                var dataRequest = {
                    Id: null,
                    DepartmentId: this.integrationsService.departmentId,
                    SalaryTypeId: salaryType.Id,
                    ExternalReference: request.ExternalReference
                };
                this.dataService.SalaryTypes_CreateDepartmentSalaryTypeMapping(dataRequest).subscribe(function (data) {
                    _this.originDepartmentSalaryTypeMapping.push(data);
                    args.dataItem.Id = data.SalaryTypeId;
                    args.dataItem.ExternalReference = data.ExternalReference;
                }, function () {
                    _this.integrationsService.loadDepartmentMapping();
                });
            }
            else if (!request.ExternalReference) {
                // delete
                this.dataService.SalaryTypes_DeleteDepartmentSalaryTypeMapping(temp_1.Id).subscribe(function () {
                    _this.originDepartmentSalaryTypeMapping.splice(_this.originDepartmentSalaryTypeMapping.indexOf(temp_1), 1);
                }, function () {
                    _this.integrationsService.loadDepartmentMapping();
                });
            }
            else {
                // update
                var dataRequest = {
                    Id: temp_1.Id,
                    DepartmentId: this.integrationsService.departmentId,
                    SalaryTypeId: salaryType.Id,
                    ExternalReference: request.ExternalReference
                };
                this.dataService.SalaryTypes_UpdateDepartmentSalaryTypeMapping(dataRequest).subscribe(function (data) {
                    args.dataItem.Id = data.SalaryTypeId;
                    args.dataItem.ExternalReference = data.ExternalReference;
                }, function () {
                    _this.integrationsService.loadDepartmentMapping();
                });
            }
        }
    };
    StandardSalaryTypesComponent.prototype.getExteralReferenceCode = function (refCode, fullName) {
        if (!refCode || !this.externalReferences) {
            return undefined;
        }
        var externalRefCode = this.externalReferences.find(function (item) { return refCode === item.AccountNumber || refCode === item.FriendlyName; });
        if (externalRefCode) {
            return !fullName ? externalRefCode.AccountNumber : externalRefCode.FriendlyName;
        }
        return refCode;
    };
    StandardSalaryTypesComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        this.gridData = filterBy(this.salaryTypes, filter);
    };
    StandardSalaryTypesComponent.prototype.onActivateModuleDialogAction = function (action) {
        var _this = this;
        switch (action) {
            case 'ViewPackages':
                this.sessionService.NavigateTo('tabs.company.modules');
                break;
            case 'ActivateModule':
                this.dataService.Modules_EnableModule(4).subscribe(function (message) {
                    if (message) {
                        _this.moduleActivatedDialogMessage = message;
                    }
                    _this.staticDataService.loadStaticData().then(function () {
                        _this.moduleActivatedDialogVisible = true;
                    });
                }, function () {
                    // this.reloadPage();
                });
                break;
        }
    };
    StandardSalaryTypesComponent.prototype.onAddonClickEvent = function () {
        this.activateModuleDialogVisible = true;
    };
    StandardSalaryTypesComponent.prototype.reloadPage = function () {
        this.settingService.ReloadWarrning = true;
        window.location.reload();
    };
    Object.defineProperty(StandardSalaryTypesComponent.prototype, "translationParams", {
        //format the price before it gets passed as a translation param
        get: function () {
            return {
                moduleName: this.integrationsService.moduleSalaryTypeName,
                modulePrice: this.priceFormatPipe.transform(this.integrationsService.moduleSalaryTypePrice, true) === 'Price.Free'
                    ? '0,-'
                    : this.priceFormatPipe.transform(this.integrationsService.moduleSalaryTypePrice, true)
            };
        },
        enumerable: true,
        configurable: true
    });
    StandardSalaryTypesComponent.prototype.onCellClick = function (event) {
        if (event.dataItem) {
            if (event.dataItem.IsAdvanced === true && !this.integrationsService.hasSalaryTypeModule) {
                this.activateModuleDialogVisible = true;
            }
        }
    };
    return StandardSalaryTypesComponent;
}());
export { StandardSalaryTypesComponent };
