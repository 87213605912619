<app-fixed-panel
  [excludedComponents]="listClassExcluded"
  [editMode]="employeeService.editMode"
  [specificClassName]="'Island-employee-list'"
  [hideFooter]="true"
>
  <div
    class="EmployeeFilter"
    [ngClass]="{ 'is-active': sessionService.employeeListActive }"
    *ngIf="employeeListVisible"
  >
    <div class="EmployeeFilter-widgets Form--stacked">
      <div class="filter-list">
        <div class="EmployeeFilter-closeBtnWrapper">
          <a
            class="EmployeeFilter-closeBtn"
            style="color: #fff;font-size: 26px;"
            (click)="onCloseEmployeeFilterEventClick()"
            >X</a
          >
        </div>
        <div class="FormElement">
          <label class="FormElement-label u-hiddenVisually" for="comboboxDepartment">{{
            'Employee.FindEmployee' | translate
          }}</label>
          <div class="FormElement-control FormElement-control--iconLeft">
            <app-icon [icon]="'Search'" [iconClass]="'FormElement-icon'" (click)="onCloseEmployeeFilterEventClick()">
            </app-icon>

            <input
              [disabled]="employeeService.editMode"
              class="FormElement-input"
              type="search"
              placeholder="{{ 'Employee.Search' | translate }}"
              spellcheck="false"
              autocorrect="off"
              autocapitalize="off"
              [(ngModel)]="employeeService.filter"
              (keydown.enter)="employeeService.filter = ''"
              (keydown.arrowup)="employeeService.selectPrevious()"
              (keydown.arrowdown)="employeeService.selectNext()"
            />
          </div>
        </div>

        <app-department-filter [(hasDepartmentData)]="hasDepartmentFilter"></app-department-filter>
        <app-salary-cycle-filter></app-salary-cycle-filter>
        <app-template-filter></app-template-filter>
        <app-sort-by></app-sort-by>

        <div class="FormElement EmployeeFilter-includeInactive CheckBoxPadding" id="includeInactive">
          <div class="FormElement-control">
            <label class="FormElement-checkbox">
              <input type="checkbox" id="checkBoxIsActiveUserCompany" [(ngModel)]="employeeService.includeInactive" />
              <span class="FormElement-checkboxFaux"></span>
              <span class="FormElement-label">{{ 'Employee.IncludeInactive' | translate }}</span>
            </label>
          </div>
        </div>

        <div class="EmployeeFilter-countWrapper">
          <div class="EmployeeFilter-count FormElement-label">
            {{ 'Employee.Employees' | translate }} ({{ employeeService.employeesCount | async }})
            <app-icon
              class="EmployeeFilter-addnew"
              *ngIf="allowEditUserEmployment"
              [icon]="'AddUser'"
              [tooltip]="'Employee.NewEmployee' | translate"
              (click)="createNewEmployeeDialogVisible = true"
            ></app-icon>
          </div>
        </div>
      </div>

      <div class="grid-list Island Island-employee-list">
        <app-grid
          class="FormElement-grid CompanyUsers EmployeeList"
          [disabled]="employeeService.editMode"
          [ngClass]="{ CompanyUsersWithoutDepartment: !hasDepartmentFilter }"
          [editMode]="false"
          [addNew]="false"
          [deleteRow]="false"
          [data]="employeeService.employees | async"
          [idColumn]="'Id'"
          [selectedId]="(employeeService.employee | async)?.Id"
          (selectedItemChange)="onGridSelectionChange($event)"
          (cellClick)="employeeService.filter = ''"
          [pageable]="true"
          [pageSize]="100"
          [miniPager]="true"
        >
          <app-grid-column [field]="'Text'" [type]="'text'" [editable]="false"></app-grid-column>
        </app-grid>
      </div>
    </div>
  </div>
</app-fixed-panel>

<app-new-employee-dialog [(visible)]="createNewEmployeeDialogVisible"></app-new-employee-dialog>
