import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from 'src/app/common/global';
import { RouterStateService } from 'src/app/common/router-state.service';
import { DataService } from '../../services/api/data.service';
import { SessionService } from '../../services/session/session.service';
import { AccountantSummaryService } from './accountant-summary.service';
var AccountantLeaveDialogComponent = /** @class */ (function () {
    function AccountantLeaveDialogComponent(service, stateService, sessionService, translateService, dataService) {
        this.service = service;
        this.stateService = stateService;
        this.sessionService = sessionService;
        this.translateService = translateService;
        this.dataService = dataService;
        this.busy = false;
        this.redirectDialogVisible = false;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.translationParam = { CompanyName: '', AccountName: '' };
        this.leaveMessage = '';
        this.ngUnsubscribe = new Subject();
        this.translationServiceTerms = ['Accountant.LeaveMessage'];
    }
    Object.defineProperty(AccountantLeaveDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            this.visibleValue = value;
            this.visibleChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    AccountantLeaveDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.service.multiCompanyOrganization.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (OrganizationName) {
            _this.translationParam.AccountName = OrganizationName;
            _this.translationParam.CompanyName = Global.COMPANY.Name;
            _this.sessionService.OnTranslateChanged.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function () {
                _this.translateService.get(_this.translationServiceTerms).subscribe(function (translations) {
                    _this.leaveMessage = translations['Accountant.LeaveMessage'];
                    _this.leaveMessage = _this.leaveMessage.replace(/{{AccountName}}/g, OrganizationName);
                    _this.leaveMessage = _this.leaveMessage.replace(/{{CompanyName}}/g, Global.COMPANY.Name);
                });
            });
        });
    };
    AccountantLeaveDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    AccountantLeaveDialogComponent.prototype.dialogAction = function (action) {
        var _this = this;
        if (action === 'Leave') {
            this.busy = true;
            this.service.leaveAccount().subscribe(function () {
                _this.busy = false;
                _this.redirectDialogVisible = true;
                _this.visible = false;
            });
        }
    };
    AccountantLeaveDialogComponent.prototype.redirectToLogin = function () {
        this.stateService.navigateToState('login');
    };
    return AccountantLeaveDialogComponent;
}());
export { AccountantLeaveDialogComponent };
