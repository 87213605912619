import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../../../common/form-component-base';
import { Global } from '../../../../common/global';
import { UserEmploymentTemplate } from '../../../../services/api/api-model';
import { DataService } from '../../../../services/api/data.service';
import { StaticDataService } from '../../../../services/api/static-data.service';
import { SessionService } from '../../../../services/session/session.service';
var AddTemplateDialogComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AddTemplateDialogComponent, _super);
    function AddTemplateDialogComponent(dataService, sessionService, staticDataService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.sessionService = sessionService;
        _this.staticDataService = staticDataService;
        _this.visibleValue = false;
        _this.visibleChange = new EventEmitter();
        _this.addTemplateComplete = new EventEmitter();
        _this.userEmployements = [];
        _this.warningDialogVisible = false;
        return _this;
    }
    Object.defineProperty(AddTemplateDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
                this.templateName = '';
                this.salaryCycleId = this.salaryCycles && this.salaryCycles.length > 0 ? this.salaryCycles[0].Id : undefined;
                this.filterUserEmploymentBaseOnCycle();
            }
        },
        enumerable: true,
        configurable: true
    });
    AddTemplateDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.SalaryCycle.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.salaryCycles = data); });
        this.dataService.Employments_GetEmploymentViews().subscribe(function (userEmployments) {
            _this.userEmploymentView = userEmployments;
            _this.filterUserEmploymentBaseOnCycle();
        });
    };
    AddTemplateDialogComponent.prototype.onDialogAction = function (action) {
        if (action === 'Create') {
            if (!this.templateName) {
                this.warningDialogVisible = true;
                return;
            }
            var userEmploymentTemplate = this.createNewUserEmploymentObject(this.templateName, this.salaryCycleId, Global.COMPANY_ID);
            this.createEmploymentTemplate(userEmploymentTemplate);
        }
    };
    AddTemplateDialogComponent.prototype.onSalaryCycleChanged = function () {
        this.filterUserEmploymentBaseOnCycle();
    };
    AddTemplateDialogComponent.prototype.filterUserEmploymentBaseOnCycle = function () {
        var _this = this;
        if (this.salaryCycleId && this.userEmploymentView) {
            this.userEmployements = this.userEmploymentView.filter(function (item) { return item.SalaryCycleId.toString() === _this.salaryCycleId.toString(); });
        }
        this.userEmploymentId = undefined;
    };
    AddTemplateDialogComponent.prototype.createEmploymentTemplate = function (userEmploymentTemplate) {
        var _this = this;
        if (this.userEmploymentId) {
            var request = {
                BasedOnEmploymentId: this.userEmploymentId,
                Name: userEmploymentTemplate.Name
            };
            this.dataService
                .EmploymentTemplates_CreateTemplateFromEmployment(request)
                .subscribe(function (template) {
                _this.addTemplateComplete.emit(template);
                _this.visible = false;
            });
        }
        else {
            this.dataService
                .EmploymentTemplates_CreateEmploymentTemplate(userEmploymentTemplate)
                .subscribe(function (template) {
                _this.addTemplateComplete.emit(template);
                _this.visible = false;
            });
        }
    };
    AddTemplateDialogComponent.prototype.createNewUserEmploymentObject = function (templateName, salaryCycleId, companyId) {
        var newUserEmploymentTemplate = new UserEmploymentTemplate();
        newUserEmploymentTemplate.Name = templateName;
        newUserEmploymentTemplate.CompanyId = companyId;
        newUserEmploymentTemplate.SalaryCycleId = salaryCycleId;
        return newUserEmploymentTemplate;
    };
    return AddTemplateDialogComponent;
}(FormComponentBase));
export { AddTemplateDialogComponent };
