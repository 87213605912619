import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, forkJoin as observableForkJoin, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { environment } from '../../../environments/environment';
import { Global } from '../../common/global';
import { GridHelper } from '../../common/grid-helper';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { CompanyService } from '../../services/company.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import { PreferenceViewModel } from './preference-view-model';
import * as i0 from "@angular/core";
import * as i1 from "../../services/api/data.service";
import * as i2 from "../../services/api/static-data.service";
import * as i3 from "../../services/setting.service";
import * as i4 from "../../services/session/session.service";
import * as i5 from "@uirouter/core";
import * as i6 from "../../services/broadcast.service";
var CompanyPreferenceService = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyPreferenceService, _super);
    function CompanyPreferenceService(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) {
        var _this = _super.call(this, dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) || this;
        _this.dataService = dataService;
        _this.staticDataService = staticDataService;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.transitionService = transitionService;
        _this.broadcaster = broadcaster;
        _this.apiReponses = {};
        _this.ngUnsubscribe = new Subject();
        _this.reloveData = new EventEmitter();
        _this.reloadPanel = new EventEmitter();
        return _this;
    }
    Object.defineProperty(CompanyPreferenceService.prototype, "companyReferencesCategory", {
        get: function () {
            if (!this.companyReferencesCategorySubject) {
                this.companyReferencesCategorySubject = new BehaviorSubject([]);
            }
            return this.companyReferencesCategorySubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CompanyPreferenceService.prototype.loadCompanyReferencesCategory = function () {
        var _this = this;
        if (!this.companyReferencesCategorySubject) {
            this.companyReferencesCategorySubject = new BehaviorSubject([]);
        }
        return this.dataService.StaticData_GetCompanyPreferenceCategories().pipe(map(function (data) {
            _this.companyReferencesCategorySubject.next(data.sort(function (a, b) { return a.SortOrder - b.SortOrder; }));
        }));
    };
    Object.defineProperty(CompanyPreferenceService.prototype, "companyReferencesInfo", {
        get: function () {
            if (!this.companyReferencesInfoSubject) {
                this.companyReferencesInfoSubject = new BehaviorSubject([]);
            }
            return this.companyReferencesInfoSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CompanyPreferenceService.prototype.loadCompanyReferencesInfo = function () {
        var _this = this;
        if (!this.companyReferencesInfoSubject) {
            this.companyReferencesInfoSubject = new BehaviorSubject([]);
        }
        if (Global.COMPANY_PREFERENCES && Global.COMPANY_PREFERENCES.length > 0) {
            var data = JSON.parse(JSON.stringify(Global.COMPANY_PREFERENCES));
            this.companyReferencesInfoSubject.next(data);
            return Observable.create(function (observer) {
                observer.next(_this.companyReferencesInfoSubject);
                observer.complete();
            });
        }
        else {
            return this.dataService.Preferences_GetCompanyPreferences().pipe(map(function (data) {
                Global.COMPANY_PREFERENCES = data;
                _this.companyReferencesInfoSubject.next(data);
            }));
        }
    };
    Object.defineProperty(CompanyPreferenceService.prototype, "companyReferencesStructure", {
        get: function () {
            if (!this.companyReferencesStructureSubject) {
                this.companyReferencesStructureSubject = new BehaviorSubject([]);
            }
            return this.companyReferencesStructureSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CompanyPreferenceService.prototype.loadCompanyReferencesStructure = function () {
        var _this = this;
        if (!this.companyReferencesStructureSubject) {
            this.companyReferencesStructureSubject = new BehaviorSubject([]);
        }
        return this.dataService.StaticData_GetCompanyPreferences().pipe(map(function (staticData) {
            _this.companyReferencesStructureSubject.next(staticData);
        }));
    };
    Object.defineProperty(CompanyPreferenceService.prototype, "userPreferences", {
        get: function () {
            if (!this.userPreferencesSubject) {
                this.userPreferencesSubject = new BehaviorSubject([]);
            }
            return this.userPreferencesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CompanyPreferenceService.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    CompanyPreferenceService.prototype.dataInint = function () {
        var _this = this;
        if (this.isCompanySetting) {
            return observableForkJoin(this.loadCompanyReferencesInfo(), this.loadCompanyReferencesCategory(), this.loadCompanyReferencesStructure()).pipe(tap(function () { }));
        }
        else {
            return Observable.create(function (ob) {
                if (!_this.userPreferencesSubject) {
                    _this.userPreferencesSubject = new BehaviorSubject([]);
                }
                if (Global.USER_PREFERENCES && Global.USER_PREFERENCES.length > 0) {
                    _this.dataService.StaticData_GetUserPreferences().subscribe(function (staticData) {
                        _this.createPreferences(Global.USER_PREFERENCES, staticData).subscribe(function (preferences) {
                            _this.userPreferencesSubject.next(preferences);
                        });
                    });
                }
                else {
                    _this.dataService.Preferences_GetUserPreferences().subscribe(function (keyValuePairs) {
                        _this.dataService.StaticData_GetUserPreferences().subscribe(function (staticData) {
                            _this.createPreferences(keyValuePairs, staticData).subscribe(function (preferences) {
                                _this.userPreferencesSubject.next(preferences);
                            });
                        });
                    });
                }
                ob.complete();
            });
        }
    };
    CompanyPreferenceService.prototype.createPreferences = function (keyValuePairs, staticData) {
        var _this = this;
        return Observable.create(function (ob) {
            var results = [];
            var dataSourceCount = staticData.filter(function (data) { return _this.isDataTypeCombobox(data.UiControlType); })
                .length;
            var dataSourceLoaded = 0;
            var processedPref = 0;
            var _loop_1 = function (data) {
                var pref = new PreferenceViewModel();
                if (!data.UiControlType) {
                    return "continue";
                }
                pref.Id = data.Id;
                pref.CategoryId = data.CategoryId;
                pref.Key = data.Key;
                pref.DataType = data.UiControlType;
                pref.Name = data.Name;
                pref.Description = data.Description;
                pref.HelpUrl = data.HelpUrl;
                pref.RequiredModuleId = data.RequiredModuleId;
                pref.MinimumRoleId = data.MinimumRoleId;
                pref.SortOrder = data.SortKey ? _this.sessionService.parseInt(data.SortKey) : undefined;
                pref.listDataSource = data.listDataSource;
                if (!pref.MinimumRoleId || pref.MinimumRoleId <= Global.SESSION.CurrentRole.Id) {
                    pref.IsVisible = true;
                }
                else {
                    pref.IsVisible = false;
                }
                var foundSetting = _this.foundSetting(pref.Key, keyValuePairs);
                if (foundSetting) {
                    if (_this.isDataTypeBoolean(pref.DataType)) {
                        pref.Value = foundSetting.Value === 'true' ? true : false;
                    }
                    else if (_this.isDataTypeNumber(pref.DataType)) {
                        pref.Value = parseInt(foundSetting.Value, 10);
                    }
                    else {
                        pref.Value = foundSetting.Value;
                    }
                }
                else {
                    // Setting not found - get default value
                    if (_this.isDataTypeBoolean(pref.DataType)) {
                        pref.Value = data.Default === 'true' ? true : false;
                    }
                    else if (_this.isDataTypeNumber(pref.DataType)) {
                        pref.Value = parseInt(data.Default, 10);
                    }
                    else {
                        pref.Value = data.Default;
                    }
                }
                if (_this.isDataTypeCombobox(pref.DataType)) {
                    _this.loadComboboxDataSource(results, pref).subscribe(function () {
                        dataSourceLoaded += 1;
                        processedPref += 1;
                        results.push(pref);
                        if (dataSourceCount === dataSourceLoaded && processedPref === staticData.length) {
                            results = results.sort(function (data1, data2) {
                                return GridHelper.sortByNumberValue(data1.SortOrder, data2.SortOrder);
                            });
                            return ob.next(results.sort(function (a, b) { return a.SortOrder - b.SortOrder; }));
                        }
                    });
                }
                else {
                    processedPref += 1;
                    results.push(pref);
                }
            };
            for (var _i = 0, staticData_1 = staticData; _i < staticData_1.length; _i++) {
                var data = staticData_1[_i];
                _loop_1(data);
            }
            if (dataSourceCount === 0) {
                results = results.sort(function (data1, data2) {
                    return GridHelper.sortByNumberValue(data1.SortOrder, data2.SortOrder);
                });
                return ob.next(results.sort(function (a, b) { return a.SortOrder - b.SortOrder; }));
            }
        });
    };
    CompanyPreferenceService.prototype.loadComboboxDataSource = function (preferences, control) {
        var _this = this;
        return Observable.create(function (ob) {
            var listDataSourceParts = _this.getPartsFromListDataSourceStringOfCombobox(control.listDataSource);
            control.UnderlyingId = listDataSourceParts.UnderlyingId;
            control.DisplayText = listDataSourceParts.DisplayText;
            if (_this.hasDataOnClient(listDataSourceParts.api)) {
                control.DataSource = _this.apiReponses[listDataSourceParts.api];
                return ob.next(control);
            }
            _this.apiReponses[listDataSourceParts.api] = []; // Block request with the same api route.
            _this.getComboboxDataSource(listDataSourceParts.api).subscribe(function (data) {
                _this.apiReponses[listDataSourceParts.api] = data;
                control.DataSource = data;
                // Update other controls.
                if (preferences && preferences.length > 0) {
                    preferences.forEach(function (pref) {
                        var prefSourceParts = _this.getPartsFromListDataSourceStringOfCombobox(pref.listDataSource);
                        if (prefSourceParts.api === listDataSourceParts.api) {
                            pref.DataSource = data;
                        }
                    });
                }
                return ob.next(control);
            });
        });
    };
    CompanyPreferenceService.prototype.getComboboxDataSource = function (api) {
        var path = environment.apiUrl + '/' + api;
        return this.dataService.httpGet(path);
    };
    CompanyPreferenceService.prototype.hasDataOnClient = function (api) {
        return this.apiReponses[api];
    };
    CompanyPreferenceService.prototype.getPartsFromListDataSourceStringOfCombobox = function (stringListDataSource) {
        stringListDataSource = stringListDataSource.replace(':', ';');
        var parts = stringListDataSource.split(';');
        return {
            api: parts[0],
            UnderlyingId: parts[1],
            DisplayText: parts[2]
        };
    };
    CompanyPreferenceService.prototype.foundSetting = function (key, pairs) {
        for (var _i = 0, pairs_1 = pairs; _i < pairs_1.length; _i++) {
            var pair = pairs_1[_i];
            if (pair.Key === key) {
                return pair;
            }
        }
        return null;
    };
    CompanyPreferenceService.prototype.updateCompanySetting = function (item) {
        var _this = this;
        return this.dataService.Preferences_SetCompanyPreference(item).pipe(tap(function (response) {
            var currentCompanySettings = JSON.parse(JSON.stringify(Global.COMPANY_PREFERENCES));
            Global.COMPANY_PREFERENCES = _this.updateLocalStorageSetting(response, currentCompanySettings);
        }));
    };
    CompanyPreferenceService.prototype.updateUserSetting = function (item) {
        var _this = this;
        return this.dataService.Preferences_SetUserPreference(item).pipe(tap(function (response) {
            var currentUserSettings = JSON.parse(JSON.stringify(Global.USER_PREFERENCES));
            Global.USER_PREFERENCES = _this.updateLocalStorageSetting(response, currentUserSettings);
        }));
    };
    CompanyPreferenceService.prototype.updateLocalStorageSetting = function (newSetting, oldSettings) {
        var existingSetting = oldSettings.find(function (pref) { return pref.Key === newSetting.Key; });
        if (existingSetting) {
            existingSetting.Value = newSetting.Value;
        }
        else {
            oldSettings.push(newSetting);
        }
        return oldSettings;
    };
    CompanyPreferenceService.prototype.isDataTypeBoolean = function (dataType) {
        return dataType.includes('CheckBox') || dataType.includes('Checkbox');
    };
    CompanyPreferenceService.prototype.isDataTypeNumber = function (dataType) {
        return dataType.includes('Integer');
    };
    CompanyPreferenceService.prototype.isDataTypePercent = function (dataType) {
        return dataType.includes('Percentage');
    };
    CompanyPreferenceService.prototype.isDataTypeTextBox = function (dataType) {
        return dataType.includes('TextBox');
    };
    CompanyPreferenceService.prototype.isDataTypeCombobox = function (dataType) {
        return dataType.includes('ComboBox') || dataType.includes('Combobox');
    };
    CompanyPreferenceService.prototype.updateSetting = function (item) {
        var _this = this;
        if (this.isCompanySetting) {
            this.updateCompanySetting(item).subscribe(function () { }, function (complete) {
                if (complete.status) {
                    _this.reloveData.emit(true);
                }
            });
        }
        else {
            this.updateUserSetting(item).subscribe(function () { }, function (complete) {
                if (complete.status) {
                    _this.reloveData.emit(true);
                }
            });
        }
    };
    CompanyPreferenceService.prototype.submitValueChange = function (value, item) {
        item.Value = value;
        var itemToSubmit = { Key: item.Key, Value: '' + item.Value };
        this.updateSetting(itemToSubmit);
        item.dirty = false;
    };
    CompanyPreferenceService.prototype.textControlChanges = function (item) {
        if (item) {
            item.dirty = true;
        }
    };
    CompanyPreferenceService.prototype.textControlKeyUp = function (key, item) {
        if (key.key === 'Enter' && item.dirty) {
            this.submitValueChange(item.Value, item);
        }
    };
    CompanyPreferenceService.prototype.textControlBlur = function (item) {
        if (item.dirty) {
            this.submitValueChange(item.Value, item);
        }
    };
    Object.defineProperty(CompanyPreferenceService.prototype, "isCompanySetting", {
        get: function () {
            return this.sessionService.currentState.indexOf('tabs.company.configuration') >= 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyPreferenceService.prototype, "allowShortcut", {
        get: function () {
            return (this.sessionService.currentState === 'tabs.account.userpreferences' ||
                this.sessionService.currentState === 'tabs.company.configuration.settings');
        },
        enumerable: true,
        configurable: true
    });
    CompanyPreferenceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyPreferenceService_Factory() { return new CompanyPreferenceService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.StaticDataService), i0.ɵɵinject(i3.SettingService), i0.ɵɵinject(i4.SessionService), i0.ɵɵinject(i5.TransitionService), i0.ɵɵinject(i6.BroadcastService)); }, token: CompanyPreferenceService, providedIn: "root" });
    return CompanyPreferenceService;
}(CompanyService));
export { CompanyPreferenceService };
