<app-dialog
  [overlayClose]="false"
  (action)="onDialogAction($event)"
  [(visible)]="visible"
  width="800"
  [ngClass]="{ 'dialog-grid': IsPreviewExpanded }"
  class="upload-import-mapping"
>
  <app-combobox-edit
    [label]="'UploadFileImportDialog.SelectExistingMapping' | translate"
    [editMode]="true"
    [textField]="'Name'"
    [idField]="'Id'"
    [comboboxDataSource]="existingMappingsForCurrentImportType"
    [(value)]="selectedMappingId"
    (valueChange)="onComboboxSelectMappingChanged()"
    [addonVisible]="addonReadMoreVisibility"
    [addonIcon]="'Question'"
    [addonLink]="addonLinks"
    [addonTooltip]="'CompanyDataImport.ReadMore' | translate"
    [disable]="sessionService.isSubmitting"
  >
  </app-combobox-edit>

  <fieldset class="FormElement" *ngIf="importPayrollDataOptionsVisible">
    <div class="FormElement-legend FormElement-label"></div>
  </fieldset>

  <div *ngIf="importOptions && importOptions.length > 0">
    <div *ngFor="let option of importOptions">
      <fieldset class="FormElement" *ngFor="let radioOption of option.Options">
        <div class="FormElement-control" *ngIf="option.DataType === 'Radio'">
          <div class="FormElement-static">
            <label class="FormElement-radio">
              <input
                type="radio"
                name="{{ option.Name }}"
                [(ngModel)]="option.ControlValue"
                [ngClass]="option.ControlValue"
                value="{{ radioOption.RadioValue }}"
                (change)="onRadioOptionChange(option)"
              />
              <span class="FormElement-radioFaux"></span>
              <span class="FormElement-label">{{ radioOption.DisplayText }}</span>
            </label>
          </div>
        </div>
      </fieldset>
    </div>
  </div>

  <div>
    <app-file-upload
      [accept]="'.xlsx'"
      (fileSelected)="onFileSelected($event)"
      [label]="'CreateDataImportTemplate.UploadFile' | translate"
      [(shortFileName)]="uploadFileName"
    ></app-file-upload>
    <div class="fileName">
      <span class="FormElement-label">{{ uploadFileName }}</span>
    </div>
  </div>

  <div class="FormElement">
    <div class="PreferencesView ImportMapping">
      <div>
        {{ 'CompanyDataImport.Example' | translate }}
      </div>
      <div
        id="previewImportMappingTable"
        class="ImportMappingTable FormElement-grid k-grid k-widget"
        [ngClass]="isLargeDialog ? 'largeDialog' : ''"
      >
        <table id="previewTable" class="Preferences-list">
          <thead *ngIf="!showEmptyMessage">
            <tr *ngIf="previewDataResponse && previewDataResponse.length > 0">
              <th *ngFor="let td of this.previewDataHeader">{{ td }}</th>
            </tr>
          </thead>

          <tbody *ngIf="showEmptyMessage">
            <tr>
              <td>
                <p class="align-center preview-empty-data">
                  {{ 'CompanyDataImport.EmptyImportPreview' | translate }}
                </p>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!showEmptyMessage">
            <tr *ngFor="let row of previewDataResponse">
              <td *ngFor="let td of row">
                {{ td }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Edit'" *ngIf="!intectStandardTemplateSelected" [disabled]="disableEditDefinitionButton">
  </app-dialog-action>
  <app-dialog-action *ngIf="!intectStandardTemplateSelected" [type]="'Delete'" [disabled]="sessionService.isSubmitting">
  </app-dialog-action>
  <app-dialog-action [type]="'Import'" [disabled]="!importButtonEnable || sessionService.isSubmitting">
  </app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onConfirmDeleteDialogAction($event)"
  [(visible)]="confirmDeleteDialogVisible"
  [leadingText]="'CompanyDataImport.DeleteTemplateConfirm' | translate"
>
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Delete'" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onConfirmReplaceDialogAction($event)"
  [(visible)]="confirmReplaceDialogVisible"
  [leadingText]="'CompanyDataImport.ReplaceExistingRecordsWarningText' | translate"
>
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Understood'" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="successDialogVisible"
  [leadingText]="'CompanyDataImport.ImportSuccessMessage' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" [(visible)]="successDialogFotalVisible" [leadingText]="resultfotalMessage">
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-import-errors-dialog
  [importType]="importType"
  [importResult]="importResults"
  (closedEvent)="closeDialogAndFireClosedEvent()"
  (completeImportEvent)="onCompleteImportEvent()"
  [testImport]="false"
>
</app-import-errors-dialog>

<app-import-mapping-dialog
  [(visible)]="showImportMappingDialog"
  [importType]="ImportTypeForMappingEditor"
  [(formData)]="formDataString"
  [isExistingMapping]="true"
  [mappingDetail]="mappingDetail"
  (closedEvent)="onClosedMappingDialog()"
  [mappingName]="mappingName"
  (saveMapingEvent)="onSaveMapingEvent()"
  [(startIndex)]="startIndex"
  (importMChange)="onImportMappingChange()"
>
</app-import-mapping-dialog>

<app-loading-tips [tiptext]="'Integrations.OperationRunWarning' | translate" [visible]="overlayVisible">
</app-loading-tips>

<app-dialog (action)="onDialogActionWarning($event)" [(visible)]="replaceAllWarningVisible" [leadingText]="warningText">
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Understood'"></app-dialog-action>
</app-dialog>
