import { Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { MobileModalContentComponent } from './mobile-modal-content.component';

@Component({
  selector: 'app-mobile-modal',
  templateUrl: './mobile-modal.component.html'
})
export class MobileModalComponent {
  @ContentChildren(MobileModalContentComponent) content: QueryList<MobileModalContentComponent>;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  get show(): boolean {
    return this.showDialog;
  }

  set show(value: boolean) {
    this.showDialog = value;
    this.showChange.emit(value);
    setTimeout(() => {
      this.transform = true;
    });
  }

  @Input()
  get customClass(): string {
    return this.currentCustomClass;
  }

  set customClass(value: string) {
    if (value) {
      this.currentCustomClass = value;
    }
  }

  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private showDialog: boolean;
  private currentCustomClass = '';
  constructor() {}

  public raiseError(): void {
    throw new Error('Test Angular 6 error');
  }

  public transform = false;

  closeModal(): void {
    this.show = false;
    this.transform = false;
    this.close.emit();
  }
}
