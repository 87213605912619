import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { EmployeeService } from 'src/app/services/employee.service';
import { ISalaryBatch } from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { BusyIndicatorService, LoadingIndicatorTextType } from '../../../services/busy-indicator.service';
import { SessionService } from '../../../services/session/session.service';
import { CompanySalaryBatchService } from '../company-salary-batches.service';
import { SalaryBatchViewModel } from '../salary-batch-view-model';

@Component({
  selector: 'app-batch-finalization-step-4-dialog',
  templateUrl: './batch-finalization-step-4-dialog.component.html'
})
export class BatchFinalizationStep4DialogComponent implements OnDestroy {
  @Input() public doRecalculation: boolean;

  @Input()
  public get step1Completed(): boolean {
    return this.visible;
  }
  public set step1Completed(value: boolean) {
    if (value) {
      if (!this.salaryBatch.EIncomeZeroReport) {
        return;
      }
    }

    this.visible = false;
  }

  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      setTimeout(() => this.visibleChange.emit(value));
      if (value) {
        this.password = '';
        this.doRecalculation = this.salaryBatch.StatusId === 10;
      }
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public finalizationExit: EventEmitter<void> = new EventEmitter<void>();
  @Output() public finalizationDone: EventEmitter<boolean> = new EventEmitter<boolean>();

  public password: string;
  public responseDialogVisible = false;
  public response: string;
  public userName: string;

  private salaryBatch: SalaryBatchViewModel;

  constructor(
    private busyIndicatorService: BusyIndicatorService,
    private dataService: DataService,
    public sessionService: SessionService,
    private employeeServce: EmployeeService,
    private companySalaryBatchService: CompanySalaryBatchService
  ) {
    this.userName = localStorage.getItem('saveLoginUserName');

    this.companySalaryBatchService.selectedSalaryBatch
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: ISalaryBatch | any) => {
        this.salaryBatch = value;
      });
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onDialogAction(action: string): void {
    if (action === 'Finalize' && this.password) {
      this.companySalaryBatchService.onStartFinalization(this.salaryBatch, this.password);
    } else {
      this.visible = false;
      this.finalizationExit.emit();
    }
  }

  // private approveSalaryBatch(batchId: number, request: SalaryBatchApprovalRequest): void {
  //   this.busyIndicatorService.setLoadingIndicatorVisibility(true, LoadingIndicatorTextType.FINALIZE);
  //   this.dataService
  //     .SalaryBatches_FinalizeSalaryBatch(batchId, request)
  //     .pipe(
  //       finalize(() => {
  //         this.busyIndicatorService.setLoadingIndicatorVisibility(false);
  //         this.finalizationDone.emit(this.doRecalculation);
  //       })
  //     )
  //     .subscribe(() => {});
  // }
}
