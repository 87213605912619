import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ExtendSalaryPeriod } from '../../../../model/extend-salary-period';
import {
  ISalaryBatchRecord,
  ISalaryBatchValidationIssue,
  ISalaryPeriod,
  ISalaryStatement,
  ISimpleKeyValuePair,
  IUserEmployment
} from '../../../../services/api/api-model';
import { DataService } from '../../../../services/api/data.service';
import { DialogService } from '../../../../services/dialog.service';
import { SessionService } from '../../../../services/session/session.service';

@Component({
  selector: 'app-payroll-preview',
  templateUrl: './payroll-preview.component.html',
  styleUrls: ['./payroll-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PayrollPreviewComponent {
  private userEmploymentValue: IUserEmployment;
  @Input()
  public get userEmployment(): IUserEmployment {
    return this.userEmploymentValue;
  }
  public set userEmployment(value: IUserEmployment) {
    if (this.userEmploymentValue !== value) {
      this.userEmploymentValue = value;

      // reset grid state
      this.gridData = [];
      this.payslipIconsVisible = false;

      this.loadPreviewData();
    }
  }

  private recalculatePreviewValue = false;
  @Input()
  public get recalculatePreview(): boolean {
    return this.recalculatePreviewValue;
  }
  public set recalculatePreview(value: boolean) {
    if (value) {
      this.loadSalaryStatementPreview();
      setTimeout(() => {
        this.recalculatePreviewValue = false;
        this.recalculatePreviewChange.emit(false);
      });
    }
  }

  @Input() isUpdate: boolean;

  @Output() public recalculatePreviewChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private currentperiodfriendlyName: string;
  @Input()
  public get periodsName(): string {
    return this.currentperiodfriendlyName;
  }
  public set periodsName(value: string) {
    this.currentperiodfriendlyName = value;
    this.periodsNameChange.emit(value);
  }
  @Output() public periodsNameChange: EventEmitter<string> = new EventEmitter<string>();

  private currentTotal: string;
  @Input()
  public get totalValue(): string {
    return this.currentTotal;
  }
  public set totalValue(value: string) {
    this.currentTotal = value;
    this.totalValueChange.emit(value);
  }
  @Output() public totalValueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() public closePreviewArea: EventEmitter<any> = new EventEmitter<any>();

  public onClosePreview(): void {
    this.closePreviewArea.emit();
  }

  @Input() public isMobilePayrollDataSlide = false;
  public get classPayrollDataSlidePayrollDataSlide(): string {
    if (this.isMobilePayrollDataSlide) {
      return 'classPayrollDataSlidePayrollDataSlide';
    }
    return '';
  }

  @Input() public isHidePreviewGrid = false;

  public get hideClass(): string {
    if (this.isHidePreviewGrid) {
      return 'hidePreviewGrid';
    }
    return '';
  }

  public get isHidden(): string {
    if (!this.sessionService.browser.isHybridApp) {
      return 'Icon--Payslip--hide';
    } else {
      return '';
    }
  }

  public isWeb: boolean = isWeb;
  public periodId: number;
  public periods: ExtendSalaryPeriod[];
  public payslipIconsVisible: boolean;
  public gridData: ISalaryBatchRecord[] = [];
  public obserDownloadData: Subscription;

  public get IsAppOrDesktop(): boolean {
    return !this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
  }
  public get IsWeb(): boolean {
    return !this.sessionService.browser.isHybridApp;
  }

  private salaryStatement: ISalaryStatement;

  constructor(
    private dataService: DataService,
    private sessionService: SessionService,
    private dialog: DialogService,
    private translate: TranslateService
  ) {}

  public onPeriodChanged(): void {
    this.loadSalaryStatementPreview();
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  private flagisDownloading = false;
  public downloadSalaryStatementsPreview(isPopup: boolean): void {
    if (!this.flagisDownloading) {
      const outType: string = isPopup && this.isWeb ? 'a4phtmlstring' : 'htmlstring';
      this.obserDownloadData = this.dataService
        .SalaryStatements_GetHtmlPreview(outType, this.salaryStatement)
        .subscribe(
          (data: string): void => {
            if (!isPopup) {
              const newWindow: Window = window.open('about:blank');
              if (newWindow) {
                newWindow.document.write(data);
                newWindow.document.close();
              } else {
                this.dialog.showMessageBox(this.translate.instant('Report.NewTabBlocked'), ['Ok']);
              }
            } else {
              this.dialog.showIFrameDialog(data);
            }
            this.flagisDownloading = false;
          },
          (error) => {
            this.flagisDownloading = false;
            console.log(error);
          }
        );
      this.flagisDownloading = true;
    } else {
      if (this.isMobile) {
        this.flagisDownloading = false;
      }
    }
  }

  public printSalaryStatementsPreview() {
    if (!this.flagisDownloading) {
      const format = 'htmlstring';
      const salaryStatementId = this.salaryStatement.Id;
      this.dataService.SalaryStatements_GetPayslipDownloadToken(salaryStatementId).subscribe(
        (token: any): void => {
          const languageCode: string = this.sessionService.LanguageCode;
          const downloadUrl = `/api/salarystatements/${salaryStatementId}/token/${token}/lang/${languageCode}/format/${format}/Payslip/DoPrint`;
          window.open(environment.apiUrl + downloadUrl, '_blank');
          this.flagisDownloading = false;
        },
        (error) => {
          this.flagisDownloading = false;
          console.log(error);
        }
      );
      this.flagisDownloading = true;
    } else {
      if (this.isMobile) {
        this.flagisDownloading = false;
      }
    }
  }

  private loadPreviewData() {
    if (!this.userEmployment || !this.userEmployment.Id || !this.userEmployment.SalaryCycleId) {
      return;
    }

    this.getPeriods();
  }

  private getPeriods() {
    this.dataService
      .SalaryBatches_GetSalaryPeriods(this.userEmployment.SalaryCycleId)
      .subscribe((periods: ISalaryPeriod[]) => {
        if (periods && periods[0].SalaryCycleId === this.userEmployment.SalaryCycleId) {
          const dataSource: ExtendSalaryPeriod[] = [];

          const isUserFriendlyname: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
            'SalaryPeriods.FriendlyNames'
          );

          periods.forEach((period: ISalaryPeriod, key: number) => {
            const startDate: string = this.sessionService.toString(
              period.StartDate ? new Date(period.StartDate) : undefined
            );
            const endDate: string = this.sessionService.toString(period.EndDate ? new Date(period.EndDate) : undefined);
            const item = new ExtendSalaryPeriod();
            item.Id = period.Id;
            item.StartDate = period.StartDate;
            item.EndDate = period.EndDate;
            item.Period = startDate + ' - ' + endDate;
            item.FriendlyName =
              isUserFriendlyname && isUserFriendlyname.Value && isUserFriendlyname.Value === 'true'
                ? ' ' + period.FriendlyName
                : startDate + ' - ' + endDate;

            dataSource[key] = item;
          });

          this.periods = dataSource;
          this.getSuggestPeriod();
        }
      });
  }

  private getSuggestPeriod(): void {
    this.dataService
      .SalaryBatches_SuggestSalaryPeriod(this.userEmployment.SalaryCycleId)
      .subscribe((period: ISalaryPeriod) => {
        if (!period) {
          if (this.periods && this.periods.length > 0) {
            this.periodId = this.periods[0].Id;
          }

          return;
        }

        this.periodId = period.Id;
        if (
          this.periods &&
          this.periods.length > 0 &&
          !this.periods.find((item: ExtendSalaryPeriod) => item.Id === period.Id)
        ) {
          this.periodId = this.periods[0].Id;
        }

        if (this.periods && this.periods.length > 0) {
          const objectPeriods: ExtendSalaryPeriod = this.periods.find(
            (model: ExtendSalaryPeriod) => model.Id === this.periodId
          );
          if (objectPeriods && objectPeriods.FriendlyName) {
            this.periodsName = objectPeriods.FriendlyName;
          }
        }

        this.loadSalaryStatementPreview();
      });
  }

  public currentuserEmploymentId: number;
  public currentuserperiodId: number;
  public isShowError = false;
  public isShowWarning = false;
  private loadSalaryStatementPreview() {
    if (this.userEmployment.Id && this.periodId) {
      if (
        this.currentuserEmploymentId !== this.userEmployment.Id ||
        this.currentuserperiodId !== this.periodId ||
        this.isUpdate
      ) {
        this.currentuserEmploymentId = this.userEmployment.Id;
        this.currentuserperiodId = this.periodId;
      } else {
        return;
      }

      // reset grid state
      this.gridData = [];
      this.payslipIconsVisible = false;

      if (this.flagisDownloading) {
        if (this.obserDownloadData) {
          this.obserDownloadData.unsubscribe();
        }

        if (this.dialog && this.dialog.isShowing) {
          this.dialog.isShowing = false;
        }

        this.flagisDownloading = false;
      }

      this.dataService
        .SalaryStatements_GetSingleEmploymentPreviewByPeriod(this.userEmployment.Id, this.periodId)
        .subscribe((salaryStatement: ISalaryStatement) => {
          if (!salaryStatement) {
            this.gridData = [];
            this.payslipIconsVisible = false;
            this.totalValue = '0';
            return;
          }

          this.salaryStatement = salaryStatement;
          this.isShowError = salaryStatement.SalaryBatch.HasErrors;
          this.isShowWarning = salaryStatement.SalaryBatch.HasWarnings;

          this.payslipIconsVisible =
            salaryStatement.SalaryBatchRecords && salaryStatement.SalaryBatchRecords.length > 0;
          this.gridData = salaryStatement.SalaryBatchRecords;
          if (this.gridData && this.gridData.length > 0) {
            const objectTotal: ISalaryBatchRecord[] = this.gridData.filter(
              (model: ISalaryBatchRecord) => model.SpecialIdentifier === 'PAYOUT'
            );
            if (objectTotal && objectTotal.length === 1 && objectTotal[0] && objectTotal[0].Amount) {
              this.totalValue = objectTotal[0].Amount.toString();
            }
          }
        });
    }
  }

  public isShowValidationIssue = false;
  public isError = false;
  public listPayrollIssues: ISalaryBatchValidationIssue[];
  public showValidationIssues(isError: boolean) {
    this.listPayrollIssues = this.salaryStatement.SalaryBatch.SalaryBatchValidationIssues;
    this.listPayrollIssues.forEach((payroll: any) => {
      payroll.icon = payroll.IsError ? 'ErrorExclamation' : 'warning';
    });
    this.isShowValidationIssue = true;
    this.isError = isError;
  }
}
