import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from 'src/app/common/global';
import { DataService } from 'src/app/services/api/data.service';
import { SettingService } from 'src/app/services/setting.service';
import { CompanyPreferenceService } from 'src/app/shared-components/preference/preference.service';
import { FormComponentBase } from '../../../common/form-component-base';
import { SessionService } from '../../../services/session/session.service';
import { DimensionsService } from './dimensions.service';
var DimensionsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DimensionsComponent, _super);
    function DimensionsComponent(sessionService, renderer, companyPreferenceService, dimensionsService, translateService, dataService, settingService) {
        var _this = _super.call(this) || this;
        _this.sessionService = sessionService;
        _this.renderer = renderer;
        _this.companyPreferenceService = companyPreferenceService;
        _this.dimensionsService = dimensionsService;
        _this.translateService = translateService;
        _this.dataService = dataService;
        _this.settingService = settingService;
        _this.ngUnsubscribe = new Subject();
        _this.menuOpen = false;
        _this.isCollapseButtonClick = false;
        if (!Global.DimensionNumber) {
            Global.DimensionNumber = _this.dimensionsService.currentDimensionNumber || 1;
        }
        var translationServiceTerms = [
            'CompanyConfigurations.Dimension1',
            'CompanyConfigurations.Dimension2',
            'CompanyConfigurations.Dimension3',
            'Company.DimensionsPreference',
            'Company.EnableDimension'
        ];
        _this.sessionService.OnTranslateChanged.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function () {
            _this.translateService.get(translationServiceTerms).subscribe(function (translations) {
                _this.dimensionsService.translations = translations;
                _this.dimensionValueEntitiesMessage = translations['Company.DimensionsPreference'];
                _this.btnLabel = translations['Company.EnableDimension'];
                _this.dimensionsService.updateDimensionLabel();
            });
        });
        return _this;
    }
    Object.defineProperty(DimensionsComponent.prototype, "isEnableDimensionPreference", {
        get: function () {
            var pref = this.sessionService.getCompanyPreference('Dimension.UseDimensionValueEntities');
            return pref.Value === 'true' ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    DimensionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.renderer.listen(window, 'click', function (event) {
            if (!_this.isCollapseButtonClick) {
                _this.menuOpen = false;
            }
            else {
                _this.isCollapseButtonClick = false;
            }
        });
        this.dimensionsService.getDimensionData(this.dimensionsService.currentDimensionNumber);
        this.dimensionsService.updateDimensionLabel();
    };
    DimensionsComponent.prototype.ngOnDestroy = function () {
        this.menuOpen = false;
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    DimensionsComponent.prototype.onToggleNavigation = function () {
        this.isCollapseButtonClick = true;
        this.menuOpen = !this.menuOpen;
    };
    DimensionsComponent.prototype.changeTab = function (index) {
        this.menuOpen = false;
        this.dimensionsService.currentDimensionNumber = index;
        Global.DimensionNumber = this.dimensionsService.currentDimensionNumber;
        this.dimensionsService.updateDimensionLabel();
        this.dimensionsService.dimensionNaming = '';
        this.dimensionsService.getDimensionData(index);
    };
    DimensionsComponent.prototype.activeDimension = function () {
        var pref = this.sessionService.getCompanyPreference('Dimension.UseDimensionValueEntities');
        this.companyPreferenceService.submitValueChange('true', pref);
    };
    return DimensionsComponent;
}(FormComponentBase));
export { DimensionsComponent };
