import * as tslib_1 from "tslib";
import { enableProdMode } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from './environments/environment';
import { version } from './version';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (!window.cordova) {
    getConfig();
}
else {
    document.addEventListener('deviceready', function () { return getConfig(); }, false);
}
function getConfig() {
    var _this = this;
    if (fetch) {
        fetch('config.json').then(function (resp) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var conf;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, resp.json()];
                    case 1:
                        conf = (_a.sent());
                        environment.apiUrl = conf.apiUrl;
                        environment.environment = conf.environment;
                        environment.production = conf.production;
                        environment.sentryDSN = conf.sentryDSN;
                        environment.branding = conf.branding;
                        bootstrap();
                        return [2 /*return*/];
                }
            });
        }); });
    }
    else {
        environment.apiUrl = 'https://api.intect.app/';
        environment.environment = 'PROD';
        environment.production = true;
        environment.sentryDSN = 'https://9efe1c9ac10840ecba53e0b96b5f0534@sentry.io/99131';
        bootstrap();
    }
}
function bootstrap() {
    Sentry.init({
        dsn: environment.sentryDSN,
        environment: environment.environment,
        release: version
    });
    if (environment.production) {
        enableProdMode();
    }
    __NgCli_bootstrap_2.platformBrowser()
        .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
        .catch(function (err) { return console.log(err); });
}
