import { EventEmitter } from '@angular/core';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { SessionService } from '../../services/session/session.service';
var DeleteEmployeeDialogComponent = /** @class */ (function () {
    function DeleteEmployeeDialogComponent(sessionService, employeeService, staticDataService) {
        this.sessionService = sessionService;
        this.employeeService = employeeService;
        this.staticDataService = staticDataService;
        this.isVisibleValue = false;
        this.isVisibleChange = new EventEmitter();
    }
    Object.defineProperty(DeleteEmployeeDialogComponent.prototype, "isVisible", {
        get: function () {
            return this.isVisibleValue;
        },
        set: function (value) {
            if (value) {
            }
            this.isVisibleValue = value;
            this.isVisibleChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeleteEmployeeDialogComponent.prototype, "currentUserEmployment", {
        get: function () {
            return this.currentUserEmploymentValue;
        },
        set: function (value) {
            if (value) {
            }
            this.currentUserEmploymentValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeleteEmployeeDialogComponent.prototype, "currentCompanyUser", {
        get: function () {
            return this.currentCompanyUserValue;
        },
        set: function (value) {
            if (value) {
            }
            this.currentCompanyUserValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeleteEmployeeDialogComponent.prototype, "hasEmployment", {
        get: function () {
            return !!this.currentUserEmployment && !!this.currentUserEmployment.Id;
        },
        enumerable: true,
        configurable: true
    });
    DeleteEmployeeDialogComponent.prototype.onDeleteEmployeeAction = function (action) {
        var _this = this;
        switch (action) {
            case 'DeleteEmployment':
                this.employeeService.deleteEmployment().subscribe(function () {
                    _this.isVisible = false;
                    _this.staticDataService.loadUserEmployments();
                });
                break;
            case 'DeleteCompanyUser':
                this.employeeService.deleteEmployee().subscribe(function () { return (_this.isVisible = false); });
                break;
        }
    };
    return DeleteEmployeeDialogComponent;
}());
export { DeleteEmployeeDialogComponent };
