import { OnInit } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { ICompany } from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { environment } from 'src/environments/environment';
import { ReportDialogService } from '../../shared-components/report-dialog/report-dialog.service';
import { AccountantSummaryService } from '../summary/accountant-summary.service';
import { AccountantConfigurationService } from './accountant-configuration.service';
var AccountantConfigurationComponent = /** @class */ (function () {
    function AccountantConfigurationComponent(service, summaryService, staticDataService, dataService, sessionService, transitionService, reportDialogService) {
        this.service = service;
        this.summaryService = summaryService;
        this.staticDataService = staticDataService;
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.transitionService = transitionService;
        this.reportDialogService = reportDialogService;
        this.discardDialogVisible = false;
        this.addNewUserVisible = false;
        this.confirmDialogVisible = false;
        this.warningNoNameMessage = false;
        this.manager = {};
        this.dataDefaultInvoiceTypeId = [];
        this.dataAccountTypeId = [];
        this.selectedCompanyId = 1;
        this.listClassExcluded = ['Footer', '10'];
    }
    Object.defineProperty(AccountantConfigurationComponent.prototype, "isIOSApp", {
        get: function () {
            return this.sessionService.browser.isHybridApp && this.sessionService.browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantConfigurationComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantConfigurationComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    AccountantConfigurationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.service.loadData();
        this.transitionService.onStart({}, function (transition) {
            var fromState = transition.from().name;
            var toState = transition.to().name;
            _this.service.navigationToStateName = JSON.parse(JSON.stringify(toState));
            if (_this.service.editMode && fromState !== toState) {
                if (_this.service.editMode && !_this.discardDialogVisible && _this.service.isDirty) {
                    _this.service.showSaveDataConfirmationChangeState = true;
                }
                else {
                    _this.onEditModeChange(false);
                    _this.sessionService.NavigateTo(_this.service.navigationToStateName);
                    return true;
                }
                return false;
            }
            return true;
        });
        if (this.service.account.MasterCompanyId) {
            this.selectedCompanyId = 1;
        }
        else {
            this.selectedCompanyId = 2;
        }
    };
    AccountantConfigurationComponent.prototype.onConfirmSaveDataOnChangeState = function (action) {
        if (action === 'ContinueAndSave') {
            this.onEditModeChange(false);
            this.service.isDirty = false;
            this.sessionService.NavigateTo(this.service.navigationToStateName);
            return;
        }
        if (action === 'DiscardAndLeave') {
            this.service.isDirty = false;
            this.service.account = JSON.parse(this.service.rawAccount);
            this.revertCompanyData();
            this.onEditModeChange(false);
            this.sessionService.NavigateTo(this.service.navigationToStateName);
            return;
        }
    };
    AccountantConfigurationComponent.prototype.tabDblclick = function () {
        this.onEditModeChange(true);
    };
    AccountantConfigurationComponent.prototype.onEditModeChange = function ($event) {
        var _this = this;
        if ($event) {
            if (this.service.account.MasterCompanyId) {
                this.selectedCompanyId = 1;
            }
            else {
                this.selectedCompanyId = 2;
            }
        }
        if (this.service.editMode === $event) {
            return;
        }
        if ($event && (this.sessionService.isGetting || this.sessionService.isSubmitting)) {
            return;
        }
        if (this.service.account.Name.length > 0) {
            if (this.service.editMode && !this.discardDialogVisible && this.service.isDirty) {
                this.checkAccountData();
                this.dataService.Account_UpdateAccount(this.service.account).subscribe(function (data) {
                    _this.service.account = data;
                    _this.service.rawAccount = JSON.stringify(data);
                    _this.service.rawCompany = data.MasterCompanyId ? 1 : 2;
                    if (data.MasterCompanyId === null) {
                        _this.service.externalCompany = _this.service.currentCompany;
                        _this.service.rawExternalCompany = JSON.stringify(_this.service.externalCompany);
                    }
                    else {
                        _this.service.rawInternalCompany = JSON.stringify(_this.service.internalCompany);
                    }
                    _this.service.isDirty = false;
                    _this.summaryService.updateCompanyInvoiceGeneral(_this.service.account, true);
                });
            }
            this.service.editMode = $event;
        }
        else if (this.service.editMode === false) {
            this.warningNoNameMessage = true;
        }
    };
    AccountantConfigurationComponent.prototype.checkAccountData = function () {
        this.service.account.MasterCompanyEmail =
            this.service.account.MasterCompanyEmail === '' ? null : this.service.account.MasterCompanyEmail;
        var currentCompany = this.service.currentCompany;
        if (!currentCompany.CompanyId) {
            this.service.account.MasterCompanyId = null;
        }
        else {
            this.service.account.MasterCompanyId = currentCompany.CompanyId;
        }
        this.service.account.MasterCompanyVATRegistrationNumber = currentCompany.CVR || null;
        this.service.account.MasterCompanyName = currentCompany.Name || null;
        this.service.account.MasterCompanyAddress = currentCompany.Address || null;
        this.service.account.MasterCompanyPostalCode = currentCompany.PostalCode || null;
        this.service.account.MasterCompanyCity = currentCompany.City || null;
        this.service.account.MasterCompanyCountry = currentCompany.Country || null;
        this.service.account.MasterCompanyEAN = currentCompany.EAN || null;
    };
    AccountantConfigurationComponent.prototype.confirmAndDiscardChanges = function (action) {
        if (this.service.rawAccount !== JSON.stringify(this.service.account) ||
            this.service.rawInternalCompany !== JSON.stringify(this.service.internalCompany) ||
            this.service.rawExternalCompany !== JSON.stringify(this.service.externalCompany) ||
            this.selectedCompanyId !== this.service.rawCompany) {
            this.discardDialogVisible = true;
            return;
        }
        this.onEditModeChange(false);
    };
    AccountantConfigurationComponent.prototype.discardDialogAction = function (action) {
        if (action === 'ConfirmDiscard') {
            this.service.account = JSON.parse(this.service.rawAccount);
            this.revertCompanyData();
            this.service.editMode = false;
        }
    };
    AccountantConfigurationComponent.prototype.revertCompanyData = function () {
        this.selectedCompanyId = this.service.rawCompany;
        this.service.internalCompany =
            this.service.rawInternalCompany.length > 10
                ? JSON.parse(this.service.rawInternalCompany)
                : this.service.internalCompany;
        this.service.internalCompany = JSON.parse(this.service.rawInternalCompany);
        this.service.externalCompany = JSON.parse(this.service.rawExternalCompany);
        this.service.currentCompany =
            this.service.rawCompany === 1 ? this.service.internalCompany : this.service.externalCompany;
    };
    AccountantConfigurationComponent.prototype.onNameChange = function () { };
    AccountantConfigurationComponent.prototype.onEmailChange = function () { };
    AccountantConfigurationComponent.prototype.onValueChange = function () { };
    AccountantConfigurationComponent.prototype.onCompanyChange = function (companyId) {
        if (companyId === null || companyId === undefined || this.service.currentCompany.Id === companyId) {
            return;
        }
        if (companyId === 1) {
            this.service.currentCompany = this.service.internalCompany;
        }
        else if (companyId === 2) {
            this.service.currentCompany = this.service.externalCompany;
        }
        this.service.isDirty = true;
    };
    AccountantConfigurationComponent.prototype.onInternalCompanyChange = function (companyId) {
        var _this = this;
        if (typeof companyId !== 'number') {
            return;
        }
        this.dataService.Companies_Get(companyId).subscribe(function (company) {
            var internalCompany = {};
            internalCompany.CVR = company.VATRegistrationNumber || null;
            internalCompany.Name = company.Name || '';
            internalCompany.Address = company.Address
                ? (company.Address.Line1 || '') + ' ' + (company.Address.Line2 || '')
                : '';
            internalCompany.PostalCode = company.Address ? company.Address.PostalCode : '';
            internalCompany.City = company.Address ? company.Address.City : '';
            internalCompany.Country = company.Country ? company.Country.Name : '';
            internalCompany.EAN = company.InvoiceIdentifier || null;
            internalCompany.CompanyId = company.Id;
            _this.service.internalCompany = internalCompany;
            _this.service.currentCompany = internalCompany;
        });
    };
    return AccountantConfigurationComponent;
}());
export { AccountantConfigurationComponent };
