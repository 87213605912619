// tslint:disable-next-line:typedef
export const environment = {
  environment: 'DEV',
  production: true,
  html5Mode: true,
  sentryDSN: 'https://ffca6d54fac14361a777c68a1f0c891d@sentry.io/99129',
  apiUrl: 'https://intectapi.matcao.vn',
  branding: {
    SystemAlias: 'Intect DEV',
    TermsLink: 'http://support.gratisal.com/hc/da/articles/208402625',
    WhatIsLink: 'https://intect.dk/',
    AndroidLink: 'market://details?id=com.gratisal.mobile',
    IOsLink: 'https://itunes.apple.com/us/app/gratisal/id1344796373?mt=8'
  }
};
