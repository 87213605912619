/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/devextreme-angular/ui/calendar.ngfactory";
import * as i2 from "@angular/forms";
import * as i3 from "devextreme-angular/ui/calendar";
import * as i4 from "devextreme-angular/core/template-host";
import * as i5 from "devextreme-angular/core/watcher-helper";
import * as i6 from "devextreme-angular/core/iterable-differ-helper";
import * as i7 from "devextreme-angular/core/nested-option";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./dev-express-calendar.component";
import * as i10 from "../../services/session/session.service";
var styles_CalendarPickerComponent = [];
var RenderType_CalendarPickerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CalendarPickerComponent, data: {} });
export { RenderType_CalendarPickerComponent as RenderType_CalendarPickerComponent };
export function View_CalendarPickerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "dx-calendar", [], [[8, "id", 0]], [[null, "valueChange"], [null, "onBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).change($event) !== false);
        ad = (pd_0 && ad);
    } if (("onBlur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 6).touched($event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = ((_co.value = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_DxCalendarComponent_0, i1.RenderType_DxCalendarComponent)), i0.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DxCalendarComponent]), i0.ɵprd(512, null, i4.DxTemplateHost, i4.DxTemplateHost, []), i0.ɵprd(512, null, i5.WatcherHelper, i5.WatcherHelper, []), i0.ɵprd(512, null, i6.IterableDifferHelper, i6.IterableDifferHelper, [i0.IterableDiffers]), i0.ɵprd(512, null, i7.NestedOptionHost, i7.NestedOptionHost, []), i0.ɵdid(6, 7323648, null, 1, i3.DxCalendarComponent, [i0.ElementRef, i0.NgZone, i4.DxTemplateHost, i5.WatcherHelper, i6.IterableDifferHelper, i7.NestedOptionHost, i8.TransferState, i0.PLATFORM_ID], { value: [0, "value"] }, { valueChange: "valueChange", onBlur: "onBlur" }), i0.ɵqud(335544320, 1, { validator: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.value; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.e2e + "_devexpresscalendar"); _ck(_v, 0, 0, currVal_0); }); }
export function View_CalendarPickerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-dev-express-calendar", [], null, null, null, View_CalendarPickerComponent_0, RenderType_CalendarPickerComponent)), i0.ɵdid(1, 4308992, null, 0, i9.CalendarPickerComponent, [i0.ElementRef, i10.SessionService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalendarPickerComponentNgFactory = i0.ɵccf("app-dev-express-calendar", i9.CalendarPickerComponent, View_CalendarPickerComponent_Host_0, { defaultnullvalue: "defaultnullvalue", type: "type", isGrid: "isGrid", preventChange: "preventChange", value: "value", editMode: "editMode", isDirty: "isDirty", isValid: "isValid", setTimeout: "setTimeout", hostClass: "hostClass", e2e: "e2e" }, { valueChange: "valueChange", editModeChange: "editModeChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange" }, []);
export { CalendarPickerComponentNgFactory as CalendarPickerComponentNgFactory };
