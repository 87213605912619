import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../common/constants';
import { FormComponentBase } from '../../../common/form-component-base';
import { Global } from '../../../common/global';
import { Address, CompanyUser, ICity, ICompanyUser, IMunicipality } from '../../../services/api/api-model';
import { StaticDataService } from '../../../services/api/static-data.service';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html'
})
export class ContactInfoComponent extends FormComponentBase implements OnInit {
  private companyUserValue: ICompanyUser;
  @Input()
  public get companyUser(): ICompanyUser {
    if (!this.companyUserValue) {
      this.companyUserValue = new CompanyUser();
      this.companyUserValue.Address = new Address();
    }

    return this.companyUserValue;
  }
  public set companyUser(value: ICompanyUser) {
    this.companyUserValue = value;
    this.companyUserChange.emit(value);
  }
  @Output() companyUserChange: EventEmitter<ICompanyUser> = new EventEmitter<ICompanyUser>();

  public municipality: IMunicipality[];
  private cities: ICity[];

  constructor(public staticDataService: StaticDataService) {
    super();
    this.childIsValid = Array(10).fill(true);
    this.childIsDirty = Array(10).fill(false);
  }

  public ngOnInit(): void {
    this.staticDataService.City.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ICity[]) => (this.cities = data));
    this.staticDataService.Municipality.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IMunicipality[]) => {
      this.municipality = data.filter(
        (m: IMunicipality) => Global.SESSION && m.CountryId === Global.SESSION.CurrentCountryId
      );
    });
  }

  public get IsGreenlandCompanyAnduserEmloyment(): boolean {
    return (
      Global.COMPANY &&
      Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID &&
      this.companyUser &&
      this.companyUser.UserEmployments &&
      this.companyUser.UserEmployments.length > 0
    );
  }

  public onPostalCodeChanged(): void {
    if (this.companyUser.Address.CountryId !== 1) {
      return;
    }

    const postalCode = this.companyUser.Address.PostalCode;
    if (!postalCode) {
      return;
    }

    let city: ICity = this.cities.find((c: ICity) => {
      if (c.CountryId === this.companyUser.Address.CountryId && c.PostalCode) {
        const numericCode = parseInt(postalCode, 10);
        if (!isNaN(numericCode)) {
          if (c.IsRange && c.PostalCodeTo) {
            if (numericCode >= c.PostalCode && numericCode <= c.PostalCodeTo) {
              return true;
            }
          } else {
            if (numericCode === c.PostalCode) {
              return true;
            }
          }
        }
      }

      return false;
    });

    if (!city) {
      city = this.cities.find((c: ICity) => c.PostalCode && postalCode === c.PostalCode.toString());
    }

    if (city) {
      this.companyUser.Address.City = city.Name;
      if (city.CountryId !== this.companyUser.Address.CountryId) {
        this.companyUser.Address.CountryId = city.CountryId;
      }
    }
  }

  public onComboboxControlChanged(): void {
    if (
      this.companyUser.Address.CountryId !== Constants.GREENLAND_COUNTRY_ID &&
      this.companyUser.AddressId !== Constants.DENMARK_COUNTRY_ID
    ) {
      this.companyUser.MunicipalityId = 8;
    }

    this.onChange();
  }
}
