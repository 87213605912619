<div>
  <app-module-check [moduleId]="4" [(hasModule)]="hasSalaryTypeModule">
    <div class="Cards">
      <div class="Card">
        <div class="Card-inner">
          <legend
            style="padding-left: 1px;font-size: 0.75rem; font-style: italic; opacity: 0.75;"
            [innerHTML]="'CompanySalaryTypes.SalaryTypeGridHint' | translate"
          ></legend>
          <app-grid
            [editMode]="!IsReadOnly"
            [(data)]="gridFilterData"
            [idColumn]="'Id'"
            [(selectedId)]="selectedId"
            (cellClick)="onCellClick($event)"
            (saveChangesEvent)="saveChanges($event)"
            class="FormElement-grid Company-salaryTypeCustomGrid u-leader"
            [filterable]="'menu'"
            [filter]="filter"
            (filterChange)="onFilterChange($event)"
            [isUseSpanHeader]="true"
            [groupLocked]="true"
            [groupWidth]="300"
            [groupHeaderClass]="'group-locked'"
            [(radioValue)]="gridRadioAction"
            [(triggerUpdate)]="triggerUpdate"
            (triggerUpdateChange)="companySalaryTypeService.isWattingLoading = false"
          >
            <app-grid-radio
              [action]="'SimpleAction'"
              [label]="'TimeEntryTypeConfigurations.Simple' | translate"
              [value]="1"
            ></app-grid-radio>
            <app-grid-radio
              [action]="'AdvancedAction'"
              [label]="'TimeEntryTypeConfigurations.Advanced' | translate"
              [value]="2"
            ></app-grid-radio>
            <app-grid-radio
              [action]="'CompleteAction'"
              [label]="'TimeEntryTypeConfigurations.Complete' | translate"
              [value]="3"
            ></app-grid-radio>

            <app-grid-column-group
              *ngIf="completeMode || simpleMode"
              [isFilterable]="true"
              [locked]="true"
              [width]="60"
              [type]="'checkbox'"
              [editable]="!IsReadOnly"
              [field]="'IsActive'"
              [classField]="'isActiveClass'"
              [title]="'Employee.IsActive' | translate"
            >
            </app-grid-column-group>
            <app-grid-column-group
              [isFilterable]="true"
              [locked]="true"
              [width]="70"
              [type]="'text'"
              [editable]="false"
              [field]="'Id'"
              [title]="'Id'"
              [headerTooltip]="'CompanySalaryTypes.IdHeaderTooltip' | translate"
            >
            </app-grid-column-group>
            <app-grid-column-group
              [isFilterable]="true"
              [locked]="true"
              [width]="245"
              [type]="'text'"
              [viewClass]="'truncated'"
              [editable]="!companySalaryTypeService.isLanguageModuleEnable"
              [field]="'Name'"
              [headerTooltip]="'CompanySalaryTypes.NameHeaderTooltip' | translate"
              [classField]="'nameClass'"
              [title]="'CompanySalaryTypes.Name' | translate"
              [tooltipField]="'Name'"
              [IconFieldString]="'IconConfigurationIcon'"
              [addOnIconFieldString]="'IconConfigurationIcon'"
              [IconTooltipFieldString]="'IconConfigurationIconTooltip'"
            >
            </app-grid-column-group>
            <app-grid-column
              *ngIf="completeMode || simpleMode"
              [isFilterable]="true"
              [width]="245"
              [type]="'text'"
              [viewClass]="'truncated'"
              [editable]="false"
              [field]="'baseSalaryName'"
              [title]="'CompanySalaryTypes.InheritsFrom' | translate"
              [headerTooltip]="'CompanySalaryTypes.BaseSalaryTypeNameHeaderTooltip' | translate"
              [tooltipField]="'baseSalaryName'"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="completeMode || simpleMode"
              [isFilterable]="true"
              [width]="250"
              [type]="'text'"
              [viewClass]="'truncated'"
              [editable]="!companySalaryTypeService.isLanguageModuleEnable"
              [field]="'Description'"
              [classField]="'descriptionClass'"
              [title]="'CompanySalaryTypes.Description' | translate"
              [headerTooltip]="'CompanySalaryTypes.DescriptionHeaderTooltip' | translate"
              [tooltipField]="'Description'"
              [IconFieldString]="'IconConfigurationIcon'"
              [addOnIconFieldString]="'IconConfigurationIcon'"
              [IconTooltipFieldString]="'IconConfigurationIconTooltip'"
            >
            </app-grid-column>

            <app-grid-column
              *ngIf="completeMode || simpleMode"
              [isFilterable]="true"
              [width]="200"
              [type]="'text'"
              [viewClass]="'truncated'"
              [editable]="!companySalaryTypeService.isLanguageModuleEnable"
              [field]="'DefaultPayslipText'"
              [classField]="'defaultPayslipTextClass'"
              [title]="'CompanySalaryTypes.PayslipText' | translate"
              [headerTooltip]="'CompanySalaryTypes.DefaultPayslipTextHeaderTooltip' | translate"
              [tooltipField]="'DefaultPayslipText'"
              [IconFieldString]="'IconConfigurationIcon'"
              [addOnIconFieldString]="'IconConfigurationIcon'"
              [IconTooltipFieldString]="'IconConfigurationIconTooltip'"
            >
            </app-grid-column>

            <app-grid-column
              *ngIf="completeMode || !simpleMode"
              [isFilterable]="true"
              [width]="100"
              [type]="'combobox'"
              [viewClass]="'truncated'"
              [editable]="!IsReadOnly"
              [field]="'AccountTypeId'"
              [comboboxDataSource]="staticDataService.FinanceAccountType | async"
              [comboboxValueField]="'Id'"
              [comboboxDisplayField]="'Name'"
              [classField]="'accountTypeIdClass'"
              [title]="'CompanySalaryTypes.AccountType' | translate"
              [tooltipField]="'AccountTypeId'"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="completeMode || simpleMode"
              [isFilterable]="true"
              [width]="100"
              [type]="'autocomplete'"
              [viewClass]="'truncated'"
              [editable]="!IsReadOnly"
              [field]="'ExternalReference'"
              [classField]="'externalReferenceClass'"
              [comboboxDataSource]="externalReferences"
              [comboboxDisplayField]="'FriendlyName'"
              [title]="'CompanyGeneral.ERPref' | translate"
              [headerTooltip]="'CompanySalaryTypes.ExternalReferenceHeaderTooltip' | translate"
              [autocompleteby1value]="true"
              [tooltipField]="'ExternalReference'"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="completeMode || simpleMode"
              [isFilterable]="true"
              [width]="100"
              [type]="'text'"
              [viewClass]="'truncated'"
              [editable]="!IsReadOnly"
              [field]="'ImportExternalReference'"
              [classField]="'ImportExternalReferenceClass'"
              [title]="'CompanySalaryTypes.ImportExternalReference' | translate"
              [headerTooltip]="'CompanySalaryTypes.ImportExternalReferenceHeaderTooltip' | translate"
              [tooltipField]="'ImportExternalReference'"
            >
            </app-grid-column>

            <app-grid-column
              *ngIf="completeMode || !simpleMode"
              [isFilterable]="true"
              [width]="80"
              [type]="'numeric'"
              [numbericOption]="'n0'"
              [editable]="!IsReadOnly"
              [field]="'SortOrder'"
              [classField]="'sortOrderClass'"
              [title]="'CompanySalaryTypes.SortOrder' | translate"
              [headerTooltip]="'CompanySalaryTypes.SortOrderHeaderTooltip' | translate"
            >
            </app-grid-column>

            <app-grid-column
              *ngIf="completeMode || !simpleMode"
              [isFilterable]="true"
              [width]="150"
              [type]="'combobox'"
              [viewClass]="'truncated'"
              [editable]="!IsReadOnly"
              [field]="'SummaryCategoryId'"
              [comboboxDataSource]="staticDataService.SalarySummaryCategory | async"
              [comboboxValueField]="'Id'"
              [comboboxDisplayField]="'Name'"
              [classField]="'summaryCategoryClass'"
              [title]="'CompanySalaryTypes.SummaryCategory' | translate"
              [headerTooltip]="'CompanySalaryTypes.SummaryCategoryIdHeaderTooltip' | translate"
              [tooltipField]="'SummaryCategoryId'"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="completeMode || simpleMode"
              [isFilterable]="true"
              [width]="90"
              [type]="'combobox'"
              [editable]="!IsReadOnly"
              [field]="'UnitTypeId'"
              [comboboxDataSource]="unitTypes"
              [comboboxValueField]="'Id'"
              [comboboxDisplayField]="'Name'"
              [classField]="'unitTypeClass'"
              [title]="'CompanySalaryTypes.UnitType' | translate"
              [headerTooltip]="'CompanySalaryTypes.UnitTypeIdHeaderTooltip' | translate"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="completeMode || !simpleMode"
              [isFilterable]="true"
              [width]="95"
              [type]="'checkbox'"
              [editable]="!IsReadOnly"
              [field]="'AllowEditUnitType'"
              [classField]="'allowEditUnitTypeClass'"
              [title]="'CompanySalaryTypes.AllowEditUnitType' | translate"
              [headerTooltip]="'CompanySalaryTypes.AllowEditUnitTypeHeaderTooltip' | translate"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="completeMode || simpleMode"
              [isFilterable]="true"
              [width]="100"
              [type]="'numeric'"
              [numbericOption]="'n2'"
              [editable]="!IsReadOnly"
              [field]="'AmountPerUnit'"
              [classField]="'amountPerUnitClass'"
              [title]="'CompanySalaryTypes.AmountPerUnit' | translate"
              [headerTooltip]="'CompanySalaryTypes.AmountPerUnitHeaderTooltip' | translate"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="completeMode || simpleMode"
              [isFilterable]="true"
              [width]="100"
              [type]="'numeric'"
              [numbericOption]="'n2'"
              [editable]="!IsReadOnly"
              [field]="'Units'"
              [classField]="'unitsClass'"
              [title]="'CompanySalaryTypes.Units' | translate"
              [headerTooltip]="'CompanySalaryTypes.UnitsHeaderTooltip' | translate"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="completeMode || !simpleMode"
              [isFilterable]="true"
              [width]="100"
              [type]="'numeric'"
              [numbericOption]="'n2'"
              [editable]="!IsReadOnly"
              [field]="'BaseAmount'"
              [classField]="'baseAmountClass'"
              [title]="'CompanySalaryTypes.BaseAmount' | translate"
              [headerTooltip]="'CompanySalaryTypes.BaseAmountHeaderTooltip' | translate"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="completeMode || !simpleMode"
              [isFilterable]="true"
              [width]="100"
              [type]="'checkbox'"
              [editable]="!IsReadOnly"
              [field]="'AllowEditBaseAmount'"
              [classField]="'allowEditBaseAmountClass'"
              [title]="'CompanySalaryTypes.AllowEditBaseAmount' | translate"
              [headerTooltip]="'CompanySalaryTypes.AllowEditBaseAmountHeaderTooltip' | translate"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="completeMode || simpleMode"
              [isFilterable]="true"
              [width]="100"
              [type]="'numeric'"
              [numbericOption]="'n2'"
              [editable]="!IsReadOnly"
              [field]="'Amount'"
              [classField]="'amountClass'"
              [title]="'CompanySalaryTypes.FixedAmount' | translate"
              [headerTooltip]="'CompanySalaryTypes.AmountHeaderTooltip' | translate"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="completeMode || !simpleMode"
              [isFilterable]="true"
              [width]="115"
              [type]="'checkbox'"
              [editable]="!IsReadOnly"
              [field]="'NegativeDefault'"
              [classField]="'negativeDefaultClass'"
              [title]="'CompanySalaryTypes.ExpectNegativeAmount' | translate"
              [headerTooltip]="'CompanySalaryTypes.NegativeDefaultHeaderTooltip' | translate"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="completeMode || simpleMode"
              [isFilterable]="true"
              [width]="90"
              [type]="'combobox'"
              [editable]="!IsReadOnly"
              [field]="'DefaultPersistanceTypeId'"
              [comboboxDataSource]="staticDataService.SalaryRecordPersistenceType | async"
              [comboboxValueField]="'Id'"
              [comboboxDisplayField]="'Name'"
              [classField]="'defaultPersistanceTypeIdClass'"
              [title]="'CompanySalaryTypes.AfterNextbatch' | translate"
              [headerTooltip]="'CompanySalaryTypes.DefaultPersistanceTypeIdHeaderTooltip' | translate"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="completeMode || !simpleMode"
              [isFilterable]="true"
              [width]="200"
              [type]="'combobox'"
              [editable]="!IsReadOnly"
              [field]="'CategoryId'"
              [comboboxDataSource]="staticDataService.SalaryTypeCategory | async"
              [comboboxValueField]="'Id'"
              [comboboxDisplayField]="'Name'"
              [classField]="'categoryIdClass'"
              [title]="'CompanySalaryTypes.Behavior' | translate"
              [headerTooltip]="'CompanySalaryTypes.CategoryIdHeaderTooltip' | translate"
              [helpLink]="'Help.SalaryTypeBehaviorHelpLink' | translate"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="completeMode || !simpleMode"
              [isFilterable]="true"
              [width]="100"
              [type]="'combobox'"
              [viewClass]="'truncated'"
              [editable]="!IsReadOnly"
              [field]="'DimensionDistributionOptionId'"
              [comboboxDataSource]="staticDataService.DimensionRedistributionBehavior | async"
              [comboboxValueField]="'Id'"
              [comboboxDisplayField]="'Name'"
              [classField]="'dimensionRedistributionClass'"
              [title]="'CompanySalaryTypes.DimensionRedistribution' | translate"
              [headerTooltip]="'CompanySalaryTypes.DimensionDistributionOptionIdHeaderTooltip' | translate"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="isFromDenmark && (completeMode || !simpleMode)"
              [isFilterable]="true"
              [width]="90"
              [type]="'numeric'"
              [numbericOption]="'n0'"
              [editable]="!IsReadOnly"
              [field]="'EIncomeCategory'"
              [classField]="'eIncomeCategoryClass'"
              [title]="'CompanySalaryTypes.IncomeCategory' | translate"
              [headerTooltip]="'CompanySalaryTypes.EIncomeCategoryHeaderTooltip' | translate"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="isFromDenmark && (completeMode || !simpleMode)"
              [isFilterable]="true"
              [width]="90"
              [type]="'numeric'"
              [numbericOption]="'n0'"
              [editable]="!IsReadOnly"
              [field]="'EIncomeSubCategory'"
              [classField]="'eIncomeSubCategoryClass'"
              [title]="'CompanySalaryTypes.EIncomeField' | translate"
              [headerTooltip]="'CompanySalaryTypes.EIncomeSubCategoryHeaderTooltip' | translate"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="isFromSwedish && (completeMode || !simpleMode)"
              [isFilterable]="true"
              [width]="100"
              [type]="'numeric'"
              [numbericOption]="'n0'"
              [editable]="!IsReadOnly"
              [field]="'SkatteverketAgiFieldCode'"
              [title]="'CompanySalaryTypes.AGIField' | translate"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="isFromGreenland && (completeMode || !simpleMode)"
              [isFilterable]="true"
              [width]="100"
              [type]="'text'"
              [editable]="!IsReadOnly"
              [field]="'SulinalFieldNumber'"
              [classField]="'sulinalFieldNumberClass'"
              [title]="'CompanySalaryTypes.SulinalFieldNumber' | translate"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="completeMode || !simpleMode"
              [isFilterable]="true"
              [width]="90"
              [type]="'text'"
              [editable]="!IsReadOnly"
              [field]="'StatisticsFieldCode'"
              [classField]="'statisticsFieldCodeClass'"
              [title]="'CompanySalaryTypes.StatisticsFieldCode' | translate"
              [headerTooltip]="'CompanySalaryTypes.StatisticsFieldCodeHeaderTooltip' | translate"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="completeMode || !simpleMode"
              [isFilterable]="true"
              [width]="90"
              [type]="'text'"
              [editable]="!IsReadOnly"
              [field]="'SumFromIds'"
              [classField]="'sumFromIdsClass'"
              [title]="'CompanySalaryTypes.SumFromIds' | translate"
              [headerTooltip]="'CompanySalaryTypes.SumFromIdsHeaderTooltip' | translate"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="completeMode || !simpleMode"
              [isFilterable]="true"
              [width]="115"
              [type]="'checkbox'"
              [editable]="!IsReadOnly"
              [field]="'SumFromReverseSign'"
              [classField]="'sumFromReverseSignClass'"
              [title]="'CompanySalaryTypes.SumFromReverseSign' | translate"
              [headerTooltip]="'CompanySalaryTypes.SumFromReverseSignHeaderTooltip' | translate"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="completeMode || !simpleMode"
              [isFilterable]="true"
              [width]="90"
              [type]="'checkbox'"
              [editable]="!IsReadOnly"
              [field]="'IncludeAlways'"
              [classField]="'includeAlwaysClass'"
              [title]="'CompanySalaryTypes.IncludeAlways' | translate"
              [headerTooltip]="'CompanySalaryTypes.IncludeAlwaysHeaderTooltip' | translate"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="completeMode || !simpleMode"
              [isFilterable]="true"
              [width]="200"
              [type]="'text'"
              [editable]="!IsReadOnly"
              [field]="'Explanation'"
              [classField]="'explanationClass'"
              [title]="'CompanySalaryTypes.Explanation' | translate"
              [headerTooltip]="'CompanySalaryTypes.ExplanationHeaderTooltip' | translate"
              [IconFieldString]="'IconConfigurationIcon'"
              [addOnIconFieldString]="'IconConfigurationIcon'"
              [IconTooltipFieldString]="'IconConfigurationIconTooltip'"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="completeMode || !simpleMode"
              [isFilterable]="true"
              [width]="125"
              [type]="'checkbox'"
              [editable]="!IsReadOnly"
              [field]="'TriggersOvertimeSupplement'"
              [classField]="'triggersOvertimeSupplementClass'"
              [title]="'CompanySalaryTypes.TriggersOvertimeSupplement' | translate"
              [headerTooltip]="'CompanySalaryTypes.TriggersOvertimeSupplementTooltip' | translate"
            >
            </app-grid-column>
          </app-grid>
        </div>
      </div>
    </div>

    <div class="Actions" *ngIf="!companySalaryTypeService.isMobile">
      <app-menu-button
        [menuCondition]="!sessionService.role.IsReadOnly"
        (menuClick)="onAddClick()"
        [menuIcon]="'Add'"
        [menuLabel]="'Company.New'"
        [menuTooltip]="'CompanySalaryBatches.AddSalaryTypeText'"
        [actionButtonMenu]="true"
        [menuButtonClass]="'Action-button Action-buttonAdd'"
        [menuDisabled]="!hasSalaryTypeModule"
        [menuButtonId]="'ActionButtonAdd'"
      >
      </app-menu-button>
      <app-menu-button
        [menuIcon]="'Help'"
        [menuLabel]="'Help.Title'"
        [menuTooltip]="'Help.Title'"
        [multiMenuItems]="true"
      >
        <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
        <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
      </app-menu-button>
      <app-menu-button
        *ngIf="reportDialogService.isVisibleReportIcon"
        [menuCondition]="true"
        (menuClick)="reportDialogService.onShowReportsEventClick()"
        [menuIcon]="'File'"
        [menuLabel]="'Employee.Reports'"
        [menuTooltip]="'Employee.Reports'"
        [actionButtonMenu]="true"
        [menuButtonClass]="'Action-button Action-buttonReports'"
        [menuButtonId]="'ActionButtonReports'"
      >
      </app-menu-button>
    </div>

    <div class="Actions" *ngIf="companySalaryTypeService.isMobile">
      <app-menu-button
        [menuIcon]="'ThreeDots'"
        [menuLabel]="'More.Title'"
        [menuTooltip]="'More.Title'"
        [multiMenuItems]="true"
      >
        <app-menu-button-item
          *ngIf="!sessionService.role.IsReadOnly"
          (menuItemClick)="onMoreMenuOptionClick($event)"
          [field]="'SalaryTypeNew'"
        >
        </app-menu-button-item>
        <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
        <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
      </app-menu-button>
    </div>
  </app-module-check>
</div>

<app-salary-type-translation-dialog
  *ngIf="translationDialogVisible"
  [salaryType]="translationSalaryType"
  [(visible)]="translationDialogVisible"
  (translateComplete)="getSalaryTypes()"
>
</app-salary-type-translation-dialog>

<app-new-salary-type-dialog
  [(visible)]="newSalaryTypeDialogVisible"
  [salaryTypeCategories]="companySalaryTypeService.salaryTypeCategories | async"
  (add)="onAddSalaryType($event)"
>
</app-new-salary-type-dialog>

<app-dialog
  [(visible)]="isShowWarrningChangeMode"
  (action)="onCompletemodeChange($event)"
  [width]="'500'"
  [leadingText]="'CompanySalaryTypes.CompanySalaryTypesModeChanges' | translate"
>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>

<app-loading-tips [visible]="companySalaryTypeService.isWattingLoading"></app-loading-tips>
