import { EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Global } from '../../common/global';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { AccountantCompaniesService } from './accountant-companies.service';
var AccountantAddNewCompanyDialogComponent = /** @class */ (function () {
    function AccountantAddNewCompanyDialogComponent(service, staticData, api) {
        var _this = this;
        this.service = service;
        this.staticData = staticData;
        this.api = api;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.createSuccess = new EventEmitter();
        this.CVRNumberValid = false;
        this.nameValid = false;
        this.modulePackage = -1;
        this.accessRoleValid = false;
        this.isWaittingCreat = false;
        this.isWrongValue = false;
        this.sourceCompanyFilter = [];
        this.confimTermVisible = false;
        this.staticData.companiesSimple.subscribe(function (data) {
            if (data && data.length > 0) {
                data.forEach(function (model) {
                    model.Relevant = model.Name + (Global.SESSION.HasBackendAccess ? ' (' + model.Id + ')' : '');
                });
                _this.sourceCompanyFilter = data.filter(function (model) { return model.RoleId >= 30; });
            }
        });
    }
    Object.defineProperty(AccountantAddNewCompanyDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            this.visibleValue = value;
            this.visibleChange.emit(value);
            if (!value) {
                this.cvrNumber = undefined;
                this.seNumber = undefined;
                this.name = undefined;
                this.modulePackage = -1;
                this.salaryTypesFrom = undefined;
                this.templatesFrom = undefined;
                this.grantAccess = false;
                this.accessRole = undefined;
                this.seNumberRequired = false;
                this.CVRNumberValid = false;
                this.nameValid = false;
                this.seNumberRequired = false;
                this.acceptTerms = false;
                this.accessRoleValid = false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantAddNewCompanyDialogComponent.prototype, "isValid", {
        get: function () {
            return (this.cvrNumber &&
                this.name &&
                (!this.grantAccess || !!this.accessRole) &&
                (!this.seNumberRequired || !!this.seNumber));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantAddNewCompanyDialogComponent.prototype, "nonValueLableAccessRole", {
        get: function () {
            if (this.grantAccess) {
                return '';
            }
            return ' ';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantAddNewCompanyDialogComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    AccountantAddNewCompanyDialogComponent.prototype.lookupCompany = function () {
        var _this = this;
        this.isWrongValue = false;
        if (this.cvrNumber) {
            this.api.Companies_GetByCvr(this.cvrNumber).subscribe(function (data) {
                if (data) {
                    if (data.CountryId !== 2) {
                        _this.seNumberRequired = data.ExistsInIntect;
                    }
                    else {
                        _this.seNumberRequired = false;
                        _this.seNumber = undefined;
                    }
                    _this.name = data.Name;
                }
                else {
                    _this.seNumberRequired = false;
                    _this.seNumber = undefined;
                }
            });
        }
        else {
            this.seNumberRequired = false;
            this.seNumber = undefined;
        }
    };
    AccountantAddNewCompanyDialogComponent.prototype.isValidValue = function () {
        if (this.cvrNumber) {
            this.CVRNumberValid = true;
        }
        if (this.name) {
            this.nameValid = true;
        }
    };
    AccountantAddNewCompanyDialogComponent.prototype.CVRKeyUp = function () {
        if (this.cvrNumber) {
            this.cvrNumber = this.cvrNumber.replace(/[^0-9-]+/g, '');
        }
    };
    AccountantAddNewCompanyDialogComponent.prototype.setDefaultAccessRole = function () {
        var _this = this;
        this.staticData.Role.subscribe(function (model) {
            if (model && model.length > 0) {
                _this.accessRole = model[2].Id;
            }
        });
    };
    AccountantAddNewCompanyDialogComponent.prototype.ValidateInputData = function () {
        if (!this.isValid) {
            if (!this.cvrNumber) {
                this.CVRNumberValid = true;
            }
            if (!this.name) {
                this.nameValid = true;
            }
            if (this.grantAccess && !this.accessRole) {
                this.accessRoleValid = true;
            }
            return false;
        }
        return true;
    };
    AccountantAddNewCompanyDialogComponent.prototype.grantAccessChange = function () {
        if (this.grantAccess && !this.accessRole) {
            this.setDefaultAccessRole();
        }
        else {
            this.accessRole = undefined;
        }
    };
    AccountantAddNewCompanyDialogComponent.prototype.dialogAction = function (action) {
        var _this = this;
        if (action === 'Create') {
            if (!this.ValidateInputData()) {
                return;
            }
            if (!this.acceptTerms) {
                this.confimTermVisible = true;
                return;
            }
            var request = {
                CompanyName: this.name,
                DefaultRoleId: this.grantAccess ? this.accessRole : undefined,
                PackageLevel: this.modulePackage === -1 ? undefined : this.modulePackage,
                VatRegistrationNumber: this.cvrNumber,
                SecondaryVatRegistrationNumber: this.seNumber,
                CopySalaryAndTimeEntryTypesFromCompanyId: this.salaryTypesFrom,
                CopyTemplatesFromCompanyId: this.templatesFrom
            };
            // if (this.salaryTypesFrom) {
            //   const match: RegExpExecArray = new RegExp(/\[(\d+)\]$/).exec(this.salaryTypesFrom);
            //   if (match && match.length === 2) {
            //     request.CopySalaryAndTimeEntryTypesFromCompanyId = parseInt(match[1], 10);
            //   }
            // }
            // if (this.templatesFrom) {
            //   const match: RegExpExecArray = new RegExp(/\[(\d+)\]$/).exec(this.templatesFrom);
            //   if (match && match.length === 2) {
            //     request.CopyTemplatesFromCompanyId = parseInt(match[1], 10);
            //   }
            // }
            this.isWaittingCreat = true;
            this.api.Account_CreateCompany(request).subscribe(function (model) {
                _this.service.filter = _this.name;
                _this.createSuccess.emit(model.Id);
                _this.isWaittingCreat = false;
                _this.visible = false;
            }, function (err) {
                if (err.status === 400) {
                    _this.isWrongValue = true;
                    _this.isWaittingCreat = false;
                }
                else {
                    _this.visible = false;
                    _this.isWaittingCreat = false;
                }
            });
        }
    };
    return AccountantAddNewCompanyDialogComponent;
}());
export { AccountantAddNewCompanyDialogComponent };
