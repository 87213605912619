<div class="payroll-mobile-col-container">
  <div *ngFor="let payrollChild of salaryRecords" class="contentPayroll" (click)="onOpenUpdateDialog(payrollChild)">
    <div class="rowContent">
      <div class="payroll-Firstcol-Mobile">
        <div class="payroll-TypeName">
          {{ getNameByTypeId(payrollChild.SalaryTypeId) }}
        </div>
      </div>

      <div
        class="payroll-Lastcol-Mobile payrollDatte"
        *ngIf="getStringStartDateAndEnddate(payrollChild.StartDate, payrollChild.EndDate)"
      >
        <div class="payroll-TypeName">
          {{ getStringStartDateAndEnddate(payrollChild.StartDate, payrollChild.EndDate) }} &nbsp;
        </div>
      </div>
    </div>
    <div class="rowContent">
      <div class="payroll-Firstcol-Mobile">
        <div>
          {{ payrollChild.Description }}
        </div>
      </div>

      <div class="payroll-Lastcol-Mobile">
        <div>
          {{ getUnitAndType(payrollChild) }}
        </div>
      </div>
    </div>
    <div class="rowContent">
      <div class="payroll-Firstcol-Mobile">
        <div>
          {{ getPerunit(payrollChild) }}
        </div>
      </div>

      <div class="payroll-Lastcol-Mobile">
        <div>
          {{ payrollChild.Amount | kendoNumber: 'n2':'da' }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="control-payroll-data-mobile">
  <app-button
    [isDisabled]="sessionService.isGetting"
    class="ButtonInsertEmployeePayrollMobile"
    [buttonClass]="'Button--primary'"
    (clickEvent)="onAddNew()"
    [label]="'EmploymentTime.AddNewEntry' | translate"
  >
  </app-button>
</div>

<div class="payrollControlArea">
  <button
    *ngIf="isHasEmployeeTotal && isShowButtonPreview"
    (click)="onShowSelectPeriodDialog()"
    class="k-button k-grid-add-command ng-star-inserted"
  >
    <p class="previewText">
      {{ periodsButtonPreviewText }}
    </p>
    <p class="totalText">
      {{ ('EmploymentTime.Total' | translate) + ': ' + formatunit(totalButtonPreviewText) }}
    </p>
  </button>
</div>

<div class="overlayScreen {{ classoverlayScreen }}" (click)="onShowSelectPeriodDialog()"></div>
<div class="PreviewGridMobile {{ classShowSelectPeriodDialog }}">
  <app-payroll-preview
    (closePreviewArea)="onShowSelectPeriodDialog()"
    [isMobilePayrollDataSlide]="true"
    [(periodsName)]="periodsButtonPreviewText"
    [(totalValue)]="totalButtonPreviewText"
    class="Card Employee-payrollDataPreviewCard PayrollMobilePreviewGrid"
    [userEmployment]="userEmployment"
    [(recalculatePreview)]="recalculate"
  >
  </app-payroll-preview>
</div>

<app-mobile-modal
  *ngIf="isAddNew || isUpdate"
  [(show)]="isAddNew || isUpdate"
  [customClass]="'widthMax'"
  (close)="oncloseInsertOrUpdatesalaryRecords()"
>
  <app-mobile-modal-header></app-mobile-modal-header>
  <app-mobile-modal-content>
    <form>
      <fieldset>
        <!-- SalaryTypeId -->
        <div class="Card-inner">
          <app-combobox-edit
            [label]="'EmploymentPayrollData.SalaryType' | translate"
            [comboboxDataSource]="salaryTypes"
            [editMode]="true"
            [textField]="'Name'"
            [idField]="'SalaryTypeId'"
            [(value)]="salaryRecordModel.SalaryTypeId"
            (valueChange)="onValueControlChange(salaryRecordModel, 'SalaryTypeId')"
          ></app-combobox-edit>
        </div>
        <!-- SalaryTypeId -->

        <!-- Description -->
        <div class="Card-inner">
          <app-text-edit
            [label]="'EmploymentPayrollData.Description' | translate"
            [editMode]="true"
            [(value)]="salaryRecordModel.Description"
            (valueChange)="onValueControlChange(salaryRecordModel, 'Description')"
          ></app-text-edit>
        </div>
        <!-- Description -->

        <!-- BaseAmount -->
        <div class="Card-inner" *ngIf="salaryRecordModel.AllowEditBaseAmount">
          <app-numeric-edit
            [label]="'EmploymentPayrollData.BaseAmount' | translate"
            [editMode]="salaryRecordModel.AllowEditBaseAmount"
            [(value)]="salaryRecordModel.BaseAmount"
            [option]="NumericOptions"
            (valueChange)="onValueControlChange(salaryRecordModel, 'BaseAmount')"
          >
          </app-numeric-edit>
        </div>
        <!-- BaseAmount -->

        <!-- Units UnitTypeId -->
        <div class="Card-inner inLineFlex">
          <!-- Units -->
          <app-numeric-edit
            [label]="'EmploymentPayrollData.Units' | translate"
            [editMode]="true"
            [disable]="!salaryRecordModel.AllowEditUnits"
            [(value)]="salaryRecordModel.Units"
            [option]="NumericOptions"
            [isSpecialLabel]="!isHideUnitTypeControl"
            [customClass]="'margin5Label'"
            [centerText]="!isHideUnitTypeControl"
            (blur)="onValueControlChange(salaryRecordModel, 'Units')"
          >
          </app-numeric-edit>
          <!-- Units -->

          <!-- UnitTypeId -->
          <app-combobox-edit
            *ngIf="!isHideUnitTypeControl"
            [editMode]="salaryRecordModel.AllowEditUnitType"
            [textField]="'Name'"
            [idField]="'Id'"
            [comboboxDataSource]="unitTypes"
            [(value)]="salaryRecordModel.UnitTypeId"
            (valueChange)="onValueControlChange(salaryRecordModel, 'UnitTypeId')"
            [setTimeout]="false"
            [nullValueLabel]="'DropdownList.None' | translate"
          ></app-combobox-edit>
          <!-- UnitTypeId -->
        </div>
        <!-- Units UnitTypeId -->

        <!-- AmountPerUnit -->
        <div class="Card-inner">
          <app-numeric-edit
            [label]="'EmploymentPayrollData.PerUnit' | translate"
            [editMode]="true"
            [disable]="!salaryRecordModel.AllowEditAmountPerUnit"
            [(value)]="salaryRecordModel.AmountPerUnit"
            [option]="NumericOptions"
            (blur)="onValueControlChange(salaryRecordModel, 'AmountPerUnit')"
          >
          </app-numeric-edit>
        </div>
        <!-- AmountPerUnit -->

        <!-- Amount -->
        <div class="Card-inner">
          <app-numeric-edit
            [label]="'EmploymentPayrollData.Amount' | translate"
            [editMode]="true"
            [(value)]="salaryRecordModel.Amount"
            [option]="NumericOptions"
            (blur)="onValueControlChange(salaryRecordModel, 'Amount')"
          ></app-numeric-edit>
        </div>
        <!-- Amount -->

        <!-- PersistenceTypeId -->
        <div class="Card-inner">
          <app-combobox-edit
            [label]="'EmploymentPayrollData.AfterNextBatch' | translate"
            [editMode]="true"
            [(value)]="salaryRecordModel.PersistenceTypeId"
            [comboboxDataSource]="persistanceTypes"
            [textField]="'Name'"
            [idField]="'Id'"
            (valueChange)="onValueControlChange(salaryRecordModel, 'PersistenceTypeId')"
          >
          </app-combobox-edit>
        </div>
        <!-- PersistenceTypeId -->

        <!-- StartDate -->
        <div *ngIf="!isHideDateColumns" class="Card-inner">
          <app-date-picker
            [label]="'EmploymentPayrollData.FromDate' | translate"
            [editMode]="true"
            [(value)]="salaryRecordModel.StartDate"
            (valueChange)="onValueControlChange(salaryRecordModel, 'StartDate')"
          ></app-date-picker>
        </div>
        <!-- StartDate -->

        <!-- EndDate -->
        <div *ngIf="!isHideDateColumns" class="Card-inner">
          <app-date-picker
            [label]="'EmploymentPayrollData.ToDate' | translate"
            [editMode]="true"
            [(value)]="salaryRecordModel.EndDate"
            (valueChange)="onValueControlChange(salaryRecordModel, 'EndDate')"
          >
          </app-date-picker>
        </div>
        <!-- EndDate -->

        <!-- 1 Dimension1Value -->
        <div *ngIf="Dimension1Preference && Dimension1Preference.Value" class="Card-inner">
          <app-auto-complete-textbox
            *ngIf="!isEnableDimensionPreference"
            [autoCompleteDataSource]="DimensionXValue[0]"
            [(value)]="salaryRecordModel.Dimension1Value"
            [label]="Dimension1Preference?.Value"
            [editMode]="true"
          >
          </app-auto-complete-textbox>
          <app-combobox-edit
            *ngIf="isEnableDimensionPreference"
            [label]="Dimension1Preference?.Value"
            [nullValueLabel]="'DropdownList.None' | translate"
            [editMode]="true"
            [comboboxDataSource]="DimensionXValue[0]"
            [textField]="'Value'"
            [idField]="'Id'"
            [(value)]="salaryRecordModel.Dimension1ValueId"
          ></app-combobox-edit>
        </div>
        <!-- 1 Dimension1Value -->

        <!-- 2 Dimension2Value -->
        <div *ngIf="Dimension2Preference && Dimension2Preference.Value" class="Card-inner">
          <app-auto-complete-textbox
            *ngIf="!isEnableDimensionPreference"
            [autoCompleteDataSource]="DimensionXValue[1]"
            [(value)]="salaryRecordModel.Dimension2Value"
            [label]="Dimension2Preference?.Value"
            [editMode]="true"
          >
          </app-auto-complete-textbox>
          <app-combobox-edit
            *ngIf="isEnableDimensionPreference"
            [label]="Dimension2Preference?.Value"
            [nullValueLabel]="'DropdownList.None' | translate"
            [editMode]="true"
            [comboboxDataSource]="DimensionXValue[1]"
            [textField]="'Value'"
            [idField]="'Id'"
            [(value)]="salaryRecordModel.Dimension2ValueId"
          ></app-combobox-edit>
        </div>
        <!-- 2 Dimension2Value -->

        <!-- 3 Dimension3Value -->
        <div *ngIf="Dimension3Preference && Dimension3Preference.Value" class="Card-inner">
          <app-auto-complete-textbox
            *ngIf="!isEnableDimensionPreference"
            [autoCompleteDataSource]="DimensionXValue[2]"
            [(value)]="salaryRecordModel.Dimension3Value"
            [label]="Dimension3Preference?.Value"
            [editMode]="true"
          >
          </app-auto-complete-textbox>
          <app-combobox-edit
            *ngIf="isEnableDimensionPreference"
            [label]="Dimension3Preference?.Value"
            [nullValueLabel]="'DropdownList.None' | translate"
            [editMode]="true"
            [comboboxDataSource]="DimensionXValue[2]"
            [textField]="'Value'"
            [idField]="'Id'"
            [(value)]="salaryRecordModel.Dimension3ValueId"
          ></app-combobox-edit>
        </div>
        <!-- 3 Dimension3Value -->
      </fieldset>
    </form>
  </app-mobile-modal-content>
  <app-mobile-modal-footer class="hide-onkbShow">
    <button *ngIf="!flagUpdate" (click)="CreateNewModel()" class="Button--primary" [disabled]="!isValidModel">
      {{ 'GlobalDialog.Create' | translate }}
    </button>
    <button *ngIf="flagUpdate" (click)="UpdateModel()" class="Button--primary" [disabled]="!isValidModel">
      {{ 'GlobalDialog.Update' | translate }}
    </button>
    <button *ngIf="flagUpdate" (click)="confirmDeleteDialogVisible = true" class="Button--destructive">
      {{ 'GlobalDialog.Delete' | translate }}
    </button>
    <button class="Button--primary" (click)="oncloseInsertOrUpdatesalaryRecords()">
      {{ 'GlobalDialog.Close' | translate }}
    </button>
  </app-mobile-modal-footer>
</app-mobile-modal>

<app-negative-default-dialog
  [setting]="negativeDefaultMoreDialogModel.setting"
  [fieldName]="negativeDefaultMoreDialogModel.fieldName"
  [dataItem]="negativeDefaultMoreDialogModel.dataItem"
  [(showDialog)]="showNegativeDefaultMoreInfoDialog"
  (usePositiveAmount)="onMoreInfoUsePositiveAmount($event)"
  (enableAutoCorrect)="autoCorrect($event)"
></app-negative-default-dialog>

<app-dialog
  (action)="onNegativeDefaulChange($event)"
  [(visible)]="showNegativeDefaulChange"
  [leadingText]="'EmploymentPayrollData.NegativeDefaultMessage_EnableSetting' | translate"
>
  <app-dialog-action [type]="'MoreInfo'"></app-dialog-action>
  <app-dialog-action [type]="'UsePositiveAmountThisTime'"></app-dialog-action>
</app-dialog>

<app-dialog (action)="onConfirmDeleteDialogAction($event)" [(visible)]="confirmDeleteDialogVisible">
  <div [innerHTML]="'Grids.ConfirmDelete' | translate"></div>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Delete'"></app-dialog-action>
</app-dialog>
