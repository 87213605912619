import { EventEmitter, Injectable, Output } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {
  @Output() invokeConfigurationToggleNavigation = new EventEmitter();
  @Output() invokeConfigurationGetMenuOpen = new EventEmitter();
  subsVar: Subscription;
  subsVar1: Subscription;
  isOpen: any;

  constructor() {}

  public callToggleNavigation() {
    this.invokeConfigurationToggleNavigation.emit();
  }

  public async getMenuOpen() {
    await this.invokeConfigurationGetMenuOpen.emit(this.isOpen);
  }
}
