import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../common/constants';
import { FormComponentBase } from '../../common/form-component-base';
import { Global } from '../../common/global';
import { NumericTextBoxOptions } from '../../custom-controls/numeric-edit/numeric-text-box-options';
import { ITaxCardModel } from '../../model/itax-card-model';
import { ICompanyUser, ISeTaxTable, IUserEmployment, IUserTaxInfo } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { EmployeeService } from '../../services/employee.service';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-tax-card',
  templateUrl: './tax-card.component.html',
  styleUrls: ['./tax-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaxCardComponent extends FormComponentBase implements OnInit, OnDestroy {
  @Output() reloadEmploymentEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() annualTaxComment: EventEmitter<void> = new EventEmitter<void>();
  @Output() companyUserChange: EventEmitter<ICompanyUser> = new EventEmitter<ICompanyUser>();

  @Input() public disable: boolean;
  @Input() public selfService = '';
  @Input() public isSelfService = false;
  private userEmploymentValue: IUserEmployment;
  private taxcardValue: ITaxCardModel;
  private companyUserValue: ICompanyUser;
  public option: NumericTextBoxOptions = { format: 'n0' };
  public taxTableNumberDataSource: ISeTaxTable[] = [];
  public TaxColumnNumberOption: NumericTextBoxOptions = {
    min: 1,
    max: 6,
    spinners: true,
    step: 1,
    decimals: 0,
    format: 'n0'
  };
  // IUserTaxInfo
  public taxCardHistory: IUserTaxInfo[] = [];

  @Input()
  public get companyUser(): ICompanyUser {
    return this.companyUserValue;
  }
  public set companyUser(value: ICompanyUser) {
    if (!this.companyUserValue && !value) {
      return;
    }

    this.companyUserValue = value;
    this.companyUserChange.emit(value);
  }

  @Input()
  public get userEmployment(): IUserEmployment {
    if (this.userEmploymentValue === undefined) {
      this.userEmploymentValue = {} as any;
    }
    return this.userEmploymentValue;
  }

  public set userEmployment(value: IUserEmployment) {
    this.userEmploymentValue = value;
    this.childIsValid = Array(9).fill(true);
    this.childIsDirty = Array(9).fill(false);

    if (this.isSwedenCompany && this.userEmploymentValue && this.userEmploymentValue.Id) {
      this.getTaxCardHistory();
    }
  }

  @Input()
  public get taxCard(): ITaxCardModel {
    if (!this.taxcardValue) {
      this.taxcardValue = { taxInfo: {} } as any;
    }

    if (!this.taxcardValue.taxInfo) {
      this.taxcardValue.taxInfo = {} as any;
    }

    return this.taxcardValue;
  }

  public set taxCard(value: ITaxCardModel) {
    if (value && value.taxInfo === undefined) {
      value.taxInfo = {} as IUserTaxInfo;
    }
    if (value && value.userEmploymentId && JSON.stringify(this.taxcardValue) !== JSON.stringify(value)) {
      this.loadEmploymentTaxInfo(value.userEmploymentId);
    }
    this.taxcardValue = value;
  }

  @Output() public requestTaxCard: EventEmitter<void> = new EventEmitter<void>();
  @Output() public viewTaxCardHistory: EventEmitter<IUserTaxInfo> = new EventEmitter<IUserTaxInfo>();
  @Output() public requestTaxcardConfirm: EventEmitter<IUserTaxInfo> = new EventEmitter<IUserTaxInfo>();
  @Output() public refreshTaxCardChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private refreshTaxCardValue: boolean;
  @Input() public get refreshTaxCard(): boolean {
    return this.refreshTaxCardValue;
  }
  public set refreshTaxCard(value: boolean) {
    if (this.refreshTaxCardValue !== value) {
      this.refreshTaxCardValue = false;
      this.refreshTaxCardChange.emit(false);
      if (value && this.taxCard && this.taxCard.userEmploymentId) {
        this.loadEmploymentTaxInfo(this.taxCard.userEmploymentId);
      }
    }
  }

  public get allowEdit(): boolean {
    return (
      this.sessionService.role.IsSalaryAdminAndHigher && this.sessionService.currentState !== 'tabs.selfservice.general'
    );
  }

  public get hasEmployment(): boolean {
    return this.taxCard && this.taxCard.userEmploymentId ? true : false;
  }

  public get hasSpecialTaxCardRule(): boolean {
    return this.hasEmployment && this.taxCard && this.taxCard.specialTaxBrief;
  }

  public get showTaxCardInfo(): boolean {
    return this.hasEmployment && ((this.taxCard && !this.taxCard.specialTaxBrief) || !this.taxCard);
  }

  public get showFreeAmount(): boolean {
    return this.taxCard && this.taxCard.taxInfo && this.taxCard.taxInfo.TaxCardTypeId === 3;
  }

  public get showDeductionPerMonth(): boolean {
    return this.taxCard && this.taxCard.taxInfo && this.taxCard.taxInfo.TaxCardTypeId === 1;
  }

  public get isAllowEditTaxCard(): boolean {
    return this.allowEdit && this.hasEmployment;
  }

  public get isTaxCardRequested(): boolean {
    return this.taxcardValue && this.taxcardValue.taxCardRequestTime ? true : false;
  }

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public get isDanishCompany(): boolean {
    return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.DENMARK_COUNTRY_ID;
  }

  public get isSwedenCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
  }

  public get IsSelfServiceGenneral(): boolean {
    return this.sessionService.currentState === 'tabs.selfservice.general';
  }

  public get top8px(): string {
    return this.sessionService.currentState === 'tabs.selfservice.general' && this.editMode ? 'Top8px' : '';
  }

  public get isShowTaxCardJamkningExistsMessage(): boolean {
    if (this.isSwedenCompany && this.taxCardHistory && this.taxCardHistory.length > 0) {
      return this.taxCardHistory.some((value: IUserTaxInfo) => value.IsCurrent);
    }
    return false;
  }

  public get isDanishCompanyAndInSelfServiceGenneral(): boolean {
    if (this.isDanishCompany && this.IsSelfServiceGenneral) {
      return true;
    }
    return false;
  }

  public get ViewTaxCardHistoryLabel(): string {
    if (this.isSwedenCompany) {
      return 'EmployeeGeneral.PersonalTaxRate';
    }
    return 'EmployeeGeneral.ViewTaxCardHistory';
  }

  public get ViewReduceTaxCardDeductionLabel(): string {
    if (this.isSwedenCompany) {
      return 'EmployeeGeneral.ReduceTaxCardDeductionSweden';
    }
    return 'EmployeeGeneral.ReduceTaxCardDeduction';
  }

  public get admin(): boolean {
    if (
      Global.SESSION.CurrentRole.Key === 'FullAdmin' ||
      Global.SESSION.CurrentRole.Key === 'SalaryAdmin' ||
      this.sessionService.currentState === 'tabs.selfservice.general'
    ) {
      return true;
    }
    return false;
  }

  constructor(
    private dataService: DataService,
    public sessionService: SessionService,
    private employeeService: EmployeeService
  ) {
    super();
    this.childIsValid = Array(9).fill(true);
    this.childIsDirty = Array(9).fill(false);

    this.employeeService.makeRequestTaxCard.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      if (value) {
        this.onRequestTaxCardClickEvent();
      }
    });
  }

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    if (this.isSwedenCompany) {
      this.dataService.StaticData_GetSeTaxTables().subscribe((data: ISeTaxTable[]) => {
        this.taxTableNumberDataSource = data;
      });
    }
  }

  public TaxColumnNumberChange(value: number): void {
    setTimeout(() => {
      if (value || value === 0) {
        if (value < 1) {
          this.userEmployment.TaxColumnNumber = 1;
        }
        if (value > 6) {
          this.userEmployment.TaxColumnNumber = 6;
        }
      }
      this.onControlChanged();
    });
  }

  public TaxColumnNumberBlur(): void {
    if (this.userEmployment.TaxColumnNumber === null || this.userEmployment.TaxColumnNumber === undefined) {
      this.userEmployment.TaxColumnNumber = 1;
      this.onControlChanged();
    }
  }

  private showRequestTaxCardDialog(): void {
    this.requestTaxCard.emit();
  }

  public onViewTaxCardHistoryButtonClickEvent(): void {
    if (this.hasSpecialTaxCardRule) {
      return;
    }
    this.viewTaxCardHistory.emit(this.taxCard.taxInfo);
  }

  public onRequestTaxCardClickEvent(): void {
    if (this.hasSpecialTaxCardRule) {
      return;
    }
    if (this.isTaxCardRequested) {
      this.requestTaxcardConfirm.emit(this.taxCard.taxInfo);
    } else {
      this.showRequestTaxCardDialog();
    }
  }

  public reloadTaxCard(): void {
    this.loadEmploymentTaxInfo(this.taxCard.userEmploymentId);
    this.onChange();
    this.isDirty = false;
    this.reloadEmploymentEvent.emit();
  }

  public taxCardState: Subscription;
  private loadEmploymentTaxInfo(userEmploymentId: number): void {
    this.dataService.CompanyUsers_GetCurrentTaxInfo(userEmploymentId).subscribe((data: IUserTaxInfo): void => {
      this.taxCard.taxInfo = data;
    });
  }

  public onControlChanged(): void {
    this.onChange();
  }

  private getTaxCardHistory(): void {
    this.dataService.Employments_GetTaxCards(this.userEmployment.Id).subscribe((data: IUserTaxInfo[]) => {
      this.taxCardHistory = data;
    });
  }
}
