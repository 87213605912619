<app-file-upload #fileupload (fileSelected)="onFileSelected($event)" [accept]="'image/*'"></app-file-upload>
<div class="FormElement" *ngIf="!isAvatar">
  <span class="FormElement-label">{{ label }}:</span>
  <div class="FormElement-control">
    <div [hidden]="editMode">
      <img
        class="u-maxHeight75"
        id="imgUpdatedCompanyLogo"
        *ngIf="imageSource && imageSource.length > 0"
        [src]="'data:image/jpeg;base64,' + imageSource"
      />
    </div>
    <button type="button" *ngIf="editMode" (click)="onChangePictureButtonClick()">
      <div class="u-DefaultLogo" id="imgCompanyLogoEditMode" [hidden]="imageSource"></div>
      <img
        class="u-maxHeight75"
        id="imgUpdatedCompanyLogoEditMode"
        *ngIf="imageSource && imageSource.length > 0"
        [src]="'data:image/jpeg;base64,' + imageSource"
      />
      <div class="Avatar-overlay">
        <span>{{ changeText }}</span>
      </div>
    </button>
    <button type="button" *ngIf="editMode && isHybridApp && isAndroidDevice" (click)="onTakePhotoButtonClick()">
      <div class="u-DefaultLogo" id="imgCompanyLogoEditMode" [hidden]="imageSource"></div>
      <img
        class="u-maxHeight75"
        id="imgUpdatedCompanyLogoEditMode"
        *ngIf="imageSource && imageSource.length > 0"
        [src]="'data:image/jpeg;base64,' + imageSource"
      />
      <div class="Avatar-overlay">
        <span>{{ takePhotoText }}</span>
      </div>
    </button>
    <div class="FormElement-help" *ngIf="editMode">{{ hint }}</div>
  </div>
</div>
<div class="Avatar-container" *ngIf="isAvatar" [ngClass]="{ 'change-Avatar': isEmployeeAvatar }">
  <div [hidden]="editMode">
    <div
      class="Avatar-bg"
      *ngIf="imageSource && imageSource.length > 0"
      [ngStyle]="{ 'background-image': 'url(data:image/jpeg;base64,' + imageSource + ')' }"
    ></div>
  </div>
  <div *ngIf="editMode" class="u-DefaultLogo" id="imgCompanyLogoEditMode" [hidden]="imageSource"></div>
  <div
    class="Avatar-bg Avatar-relative Avatar-overlay"
    *ngIf="editMode"
    [ngStyle]="{ 'background-image': 'url(data:image/jpeg;base64,' + imageSource + ')' }"
  >
    <div class="Avatar-overlay Avatar-overlayEdit"></div>
    <button class="btn-avatar" type="button" (click)="onChangePictureButtonClick()">
      <span>{{ changeText }}</span>
    </button>
    <button class="btn-avatar" type="button" *ngIf="isHybridApp && isAndroidDevice" (click)="onTakePhotoButtonClick()">
      <span>{{ takePhotoText }}</span>
    </button>
  </div>
</div>

<app-dialog
  class="blue-dialog"
  [(visible)]="errorDialogVisible"
  [leadingText]="'CompanyGeneral.FileSizeErrorMessage' | translate"
>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>
