import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OverviewDisplayModeEnum } from '../../model/enum';
import { ISalaryType } from '../../services/api/api-model';
import { StaticDataService } from '../../services/api/static-data.service';
import { CompanyPayrollDataService } from './company-payroll-data.service';

@Component({
  selector: 'app-salarytype-configuration-dialog',
  templateUrl: './salarytype-configuration-dialog.component.html'
})
export class SalaryTypeConfigurationDialogComponent implements OnInit, OnDestroy {
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);

      this.onSalaryTypeChange(this.selectedSalaryType ? this.selectedSalaryType.Id : undefined);
    }
  }

  @Input() public get selectedSalaryType(): ISalaryType {
    return this.selectedSalaryTypeValue;
  }
  public set selectedSalaryType(value: ISalaryType) {
    this.selectedSalaryTypeValue = value;
    this.onSalaryTypeChange(value ? value.Id : undefined);
  }

  @Input() public get salaryTypes(): ISalaryType[] {
    return this.salaryTypesDataSource;
  }
  public set salaryTypes(value: ISalaryType[]) {
    this.salaryTypesDataSource = value;
    this.onSalaryTypeChange(this.selectedSalaryType ? this.selectedSalaryType.Id : undefined);
  }

  @Input() public maxIndex: number;

  public replaceSalaryTypeId: any;
  public replaceSalaryType: any = {};
  public overviewDisplayModes: any[] = [];

  private visibleValue: boolean;
  private selectedSalaryTypeValue: ISalaryType;
  private salaryTypesDataSource: ISalaryType[];

  constructor(
    public companyPayrollDataService: CompanyPayrollDataService,
    private staticDataService: StaticDataService
  ) {}

  public ngOnInit(): void {
    this.staticDataService.SalaryRecordsOverviewDisplayMode.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any[]) => (this.overviewDisplayModes = data)
    );
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onSalaryTypeChange(salaryTypeId: number): void {
    this.replaceSalaryTypeId = salaryTypeId;
    this.replaceSalaryType = JSON.parse(
      JSON.stringify(
        this.salaryTypes && this.salaryTypes.length > 0 && salaryTypeId
          ? this.salaryTypes.find((s: ISalaryType) => s.Id === salaryTypeId)
            ? this.salaryTypes.find((s: ISalaryType) => s.Id === salaryTypeId)
            : {}
          : {}
      )
    );

    if (this.replaceSalaryType) {
      this.replaceSalaryType.OverviewDisplayMode = this.replaceSalaryType.OverviewDisplayMode
        ? this.replaceSalaryType.OverviewDisplayMode
        : OverviewDisplayModeEnum.AmountOnly;
    }
  }

  public onDialogAction(action: string): void {
    if (action === 'Ok' && this.salaryTypes && this.replaceSalaryTypeId && this.replaceSalaryType) {
      this.replaceSalaryType.OverviewIndex = this.selectedSalaryType
        ? this.selectedSalaryType.OverviewIndex
        : this.maxIndex;

      const request = {
        salaryTypeId: this.replaceSalaryTypeId,
        overviewIndex: this.selectedSalaryType
          ? this.selectedSalaryType.OverviewIndex
          : this.replaceSalaryType.OverviewIndex,
        overviewDisplayMode: this.replaceSalaryType.OverviewDisplayMode
      };

      this.companyPayrollDataService.SalaryTypes_ConfigureOverviewBehavior(request).subscribe((data: any): void => {
        this.visible = false;
      });
    } else {
      this.visible = false;
    }
  }
}
