/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../../custom-controls/combobox-edit/combobox-edit.component.ngfactory";
import * as i3 from "../../../custom-controls/combobox-edit/combobox-edit.component";
import * as i4 from "../../../services/session/session.service";
import * as i5 from "@angular/common";
import * as i6 from "./department-filter.component";
import * as i7 from "../../../services/employee.service";
import * as i8 from "../../../services/api/static-data.service";
var styles_DepartmentFilterComponent = [];
var RenderType_DepartmentFilterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DepartmentFilterComponent, data: {} });
export { RenderType_DepartmentFilterComponent as RenderType_DepartmentFilterComponent };
function View_DepartmentFilterComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "label", [["class", "FormElement-label u-hiddenVisually"], ["for", "comboboxDepartment"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-combobox-edit", [["id", "comboboxDepartment"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.employeeService.departmentId = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ComboboxEditComponent_0, i2.RenderType_ComboboxEditComponent)), i0.ɵdid(5, 4440064, null, 0, i3.ComboboxEditComponent, [i4.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], idField: [2, "idField"], textField: [3, "textField"], keepDataSourceOrder: [4, "keepDataSourceOrder"], comboboxDataSource: [5, "comboboxDataSource"], disable: [6, "disable"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.employeeService.departmentId; var currVal_2 = true; var currVal_3 = "Id"; var currVal_4 = "Text"; var currVal_5 = true; var currVal_6 = _co.departmentDataSource; var currVal_7 = _co.employeeService.editMode; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Employee.SearchByDepartment")); _ck(_v, 2, 0, currVal_0); }); }
export function View_DepartmentFilterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DepartmentFilterComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasDepartmentData; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DepartmentFilterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-department-filter", [], null, null, null, View_DepartmentFilterComponent_0, RenderType_DepartmentFilterComponent)), i0.ɵdid(1, 245760, null, 0, i6.DepartmentFilterComponent, [i7.EmployeeService, i1.TranslateService, i4.SessionService, i8.StaticDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DepartmentFilterComponentNgFactory = i0.ɵccf("app-department-filter", i6.DepartmentFilterComponent, View_DepartmentFilterComponent_Host_0, { hasDepartmentData: "hasDepartmentData" }, { hasDepartmentDataChange: "hasDepartmentDataChange" }, []);
export { DepartmentFilterComponentNgFactory as DepartmentFilterComponentNgFactory };
