<app-fixed-panel
  [specificClassName]="'Island-employee-hiring'"
  [excludedComponents]="listClassExcluded"
  [editMode]="editMode"
>
  <app-employee-info
    [companyUser]="employeeService.employee | async"
    [editMode]="editMode"
    [currentPositionId]="employeeService.selectedEmploymentId | async"
    [isEmployeeTab]="true"
    (valueChange)="onChange()"
    [(isValid)]="childIsValid[10]"
    [(isDirty)]="childIsDirty[10]"
    (expandUserInfoEmitter)="onExpand($event)"
  >
  </app-employee-info>

  <div
    class="Island Island-employee Island-employee-hiring"
    *ngIf="!employeeService.isNonSelected"
    [ngClass]="{ 'is-expand': expanded }"
  >
    <!-- No Employment -->
    <div *ngIf="!hasUserEmployment">
      <div class="Card">
        <div class="Card-inner withOnlyButton">
          <h2 class="Card-title">{{ 'EmploymentPayrollData.NoEmployment' | translate }}</h2>
          <!-- GS-5298
      <app-button
        buttonClass="Button--primary"
        *ngIf="allowEdit"
        (click)="createUserEmployment()"
        [label]="'EmployeeGeneral.CreateEmployment' | translate"
        [isDisabled]="isAddNewEmploymentDisable"
      ></app-button>
      -->
          <app-button
            buttonClass="Button--primary"
            *ngIf="allowEdit"
            (clickEvent)="createUserEmployment()"
            [label]="'EmployeeGeneral.CreateEmployment' | translate"
          ></app-button>
        </div>
      </div>
    </div>
    <!-- GS-5298 <span class="EmployeeWarning"> {{ warningMessage | translate }}</span> -->
    <!-- /No Employment -->

    <div *ngIf="hasUserEmployment">
      <!--<form id="employmentDetailForm" name="employmentDetailForm" novalidate onsubmit="return false;">-->
      <div class="Cards Cards--packed" [ngClass]="{ 'Cards--packed--block': !isDanishCompany }">
        <div class="Card">
          <div class="Card-inner">
            <app-vacation
              [editMode]="editMode"
              [disable]="SecondaryIncomeType"
              [disableBySecondaryIncomeType]="SecondaryIncomeType"
              [(isValid)]="childIsValid[1]"
              [(isDirty)]="childIsDirty[1]"
              [(model)]="userEmployment"
              (valueChange)="onDataChanged()"
            ></app-vacation>
          </div>
        </div>

        <div *ngIf="isShowExtraVacationHours" class="Card">
          <div class="Card-inner">
            <app-additional-vacation
              [disableBySecondaryIncomeType]="SecondaryIncomeType"
              [editMode]="editMode"
              [(isValid)]="childIsValid[0]"
              [(isDirty)]="childIsDirty[0]"
              [model]="userEmployment.EmploymentBalanceConfigurations"
              (valueChange)="onDataChanged()"
              [EmploymentId]="userEmployment.Id"
              (modelChange)="EmploymentBalanceConfigurationsChange($event, 7)"
              [allowEdit]="allowEditUserEmployment"
              (parentChangeEditMode)="employeeService.editModeChangeEvent.emit(true)"
            >
            </app-additional-vacation>
          </div>
        </div>

        <div class="Card">
          <div class="Card-inner">
            <app-time-and-absence
              [editMode]="editMode"
              [(model)]="userEmployment"
              [(isValid)]="childIsValid[2]"
              [(isDirty)]="childIsDirty[2]"
              (valueChange)="onDataChanged()"
            ></app-time-and-absence>
          </div>
        </div>

        <div class="Card" *ngIf="showMultiPurposeOffDayCompensation && !IsGreenlandCompany">
          <div class="Card-inner">
            <app-multi-purpose-off-day-compensation
              [editMode]="editMode"
              [disable]="SecondaryIncomeType"
              [disableBySecondaryIncomeType]="SecondaryIncomeType"
              [(isValid)]="childIsValid[3]"
              [(isDirty)]="childIsDirty[3]"
              [(model)]="userEmployment"
              (valueChange)="onDataChanged()"
            >
            </app-multi-purpose-off-day-compensation>
          </div>
        </div>

        <div *ngIf="!isSwedenCompany && !IsGreenlandCompany" class="Card">
          <div class="Card-inner">
            <app-multi-purpose-balance-compensation
              [disableBySecondaryIncomeType]="SecondaryIncomeType"
              [model]="userEmployment.EmploymentBalanceConfigurations"
              [editMode]="editMode"
              [(isValid)]="childIsValid[11]"
              [(isDirty)]="childIsDirty[11]"
              (valueChange)="onDataChanged()"
              [EmploymentId]="userEmployment.Id"
              (modelChange)="EmploymentBalanceConfigurationsChange($event, 3)"
              [allowEdit]="allowEditUserEmployment"
              (parentChangeEditMode)="employeeService.editModeChangeEvent.emit(true)"
            >
            </app-multi-purpose-balance-compensation>
          </div>
        </div>

        <div class="Card">
          <div class="Card-inner">
            <app-tax-card
              id="employeeHiringStatusTaxCardView"
              [taxCard]="taxCardObject"
              [userEmployment]="userEmployment"
              [editMode]="editMode"
              [(isValid)]="childIsValid[4]"
              [(isDirty)]="childIsDirty[4]"
              (valueChange)="onDataChanged()"
              (reloadEmploymentEvent)="onDataChanged(true)"
              [(refreshTaxCard)]="triggerRefreshTaxCard"
              (requestTaxCard)="showRequestTaxcardDialog()"
              (viewTaxCardHistory)="showViewTaxCardHistory()"
              (requestTaxcardConfirm)="showRequestTaxcardConfirm($event)"
              [companyUser]="companyUser"
            ></app-tax-card>
          </div>
        </div>

        <div class="Card">
          <div class="Card-inner">
            <app-hiring-data
              [editMode]="editMode"
              [(userEmployment)]="userEmployment"
              [(isValid)]="childIsValid[5]"
              [(isDirty)]="childIsDirty[5]"
              (valueChange)="onDataChanged()"
            >
            </app-hiring-data>
          </div>
        </div>

        <div class="Card" *ngIf="showStatisticsSection">
          <div class="Card-inner">
            <app-employment-statistics
              [editMode]="editMode"
              [(isValid)]="childIsValid[6]"
              [(isDirty)]="childIsDirty[6]"
              [(statistics)]="userEmployment"
              (valueChange)="onDataChanged()"
            >
            </app-employment-statistics>
          </div>
        </div>

        <div class="Card" *ngIf="!IsGreenlandCompany && !isSwedenCompany">
          <div class="Card-inner">
            <app-tax-e-income
              [editMode]="editMode"
              [(model)]="userEmployment"
              [(isValid)]="childIsValid[8]"
              [(isDirty)]="childIsDirty[8]"
              (valueChange)="onDataChanged()"
            ></app-tax-e-income>
          </div>
        </div>

        <div
          class="Card"
          *ngIf="
            !SecondaryIncomeType &&
            !editMode &&
            !firstPensionProviderVisible &&
            !secondPensionProviderVisible &&
            !isSwedenCompany
          "
        >
          <div class="Card-inner">
            <form>
              <fieldset class="Form--horizontal">
                <legend class="Card-title">{{ 'EmployeeEmployment.Pension' | translate }}</legend>
                <div class="FormElement-static text-hint">{{ 'EmployeeEmployment.NoPension' | translate }}</div>
              </fieldset>
            </form>
          </div>
        </div>

        <div
          class="Card"
          id="employeeHiringStatusViewFirstPension"
          *ngIf="
            (firstPensionProviderVisible ||
              (editMode && (!firstPensionProviderVisible || secondPensionProviderVisible))) &&
            !isSwedenCompany
          "
        >
          <div class="Card-inner">
            <app-pension-provider
              [(model)]="userEmployment"
              [editMode]="editMode"
              [disable]="SecondaryIncomeType"
              [disableBySecondaryIncomeType]="SecondaryIncomeType"
              [firstPension]="true"
              [(isValid)]="childIsValid[7]"
              [(isDirty)]="childIsDirty[7]"
              (valueChange)="onDataChanged()"
            ></app-pension-provider>
          </div>
        </div>

        <div
          class="Card"
          id="employeeHiringStatusViewSecondPension"
          *ngIf="(secondPensionProviderVisible || (editMode && firstPensionProviderVisible)) && !isSwedenCompany"
        >
          <div class="Card-inner">
            <app-pension-provider
              [(model)]="userEmployment"
              [editMode]="editMode"
              [disable]="SecondaryIncomeType"
              [disableBySecondaryIncomeType]="SecondaryIncomeType"
              [firstPension]="false"
              [(isValid)]="childIsValid[9]"
              [(isDirty)]="childIsDirty[9]"
              (valueChange)="onDataChanged()"
            ></app-pension-provider>
          </div>
        </div>
      </div>
      <!--</form>-->
    </div>
  </div>

  <div class="NonSelected" *ngIf="employeeService.isNonSelected && sessionService.role.IsSalaryAdminAndHigher">
    <div class="Card">
      <div class="Card-inner">
        <app-button
          buttonClass="Button--primary"
          (clickEvent)="createNewEmployeeDialogVisible = true"
          [label]="'Employee.NewEmployee' | translate"
        ></app-button>
      </div>
    </div>
  </div>
</app-fixed-panel>

<div class="Actions" *ngIf="!isMobile">
  <app-action-button
    *ngIf="allowEditUserEmployment"
    id="ActionButtonAdd"
    [icon]="'AddUser'"
    [label]="'Employee.New'"
    [tooltip]="'Employee.NewEmployee'"
    [buttonClass]="'Action-button Action-buttonAdd'"
    (click)="createNewEmployeeDialogVisible = true"
  ></app-action-button>

  <app-menu-button
    *ngIf="allowEditUserEmployment && !employeeService.isNonSelected"
    [menuIcon]="'ThreeDots'"
    [menuLabel]="'More.Title'"
    [menuTooltip]="'More.Title'"
    [multiMenuItems]="true"
  >
    <app-menu-button-item
      (menuItemClick)="createTemplateConfirmationDialogVisible = true"
      [field]="'CreateTemplateFromEmployee'"
    >
    </app-menu-button-item>
    <!-- GS-5298
    <app-menu-button-item
      (menuItemClick)="createNewEmploymentDialogVisible = true"
      [isAddNewEmploymentDisable]="isAddNewEmploymentDisable"
      [field]="'AddSecondEmployee'"
    > -->
    <app-menu-button-item (menuItemClick)="createNewEmploymentDialogVisible = true" [field]="'AddSecondEmployee'">
    </app-menu-button-item>
    <app-menu-button-item
      (menuItemClick)="synchronizeEmploymentWithTemplateDialogVisble = true"
      [field]="'SynchronizeWithTemplate'"
    ></app-menu-button-item>
    <app-menu-button-item (menuItemClick)="deleteEmployeeDialogVisible = true" [field]="'DeleteEmployee'">
    </app-menu-button-item>
  </app-menu-button>

  <app-menu-button [menuIcon]="'Help'" [menuLabel]="'Help.Title'" [menuTooltip]="'Help.Title'" [multiMenuItems]="true">
    <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
    <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
  </app-menu-button>

  <!-- Temporary workaround to make space for the edit button -->
  <app-action-button
    *ngIf="!editMode && allowEditUserEmployment && !employeeService.isNonSelected && hasUserEmployment"
    buttonClass="Action-button"
  ></app-action-button>

  <!-- Temporary workaround to make space for the report button -->
  <app-action-button
    *ngIf="!editMode && reportDialogService.isVisibleReportIcon && !employeeService.isNonSelected"
    buttonClass="Action-button"
  ></app-action-button>
</div>

<div class="Actions" *ngIf="isMobile">
  <app-menu-button
    [menuIcon]="'ThreeDots'"
    [menuLabel]="'Help.More'"
    [menuTooltip]="'Help.More'"
    [multiMenuItems]="true"
  >
    <app-menu-button-item
      *ngIf="allowEditUserEmployment"
      (menuItemClick)="createNewEmployeeDialogVisible = true"
      [field]="'AddNewEmployee'"
    ></app-menu-button-item>
    <app-menu-button-item
      *ngIf="allowEditUserEmployment && !employeeService.isNonSelected"
      (menuItemClick)="createTemplateConfirmationDialogVisible = true"
      [field]="'CreateTemplateFromEmployee'"
    >
    </app-menu-button-item>
    <!-- GS-5298
    <app-menu-button-item
      (menuItemClick)="createNewEmploymentDialogVisible = true"
      [isAddNewEmploymentDisable]="isAddNewEmploymentDisable"
      [field]="'AddSecondEmployee'"
    > -->
    <app-menu-button-item
      *ngIf="allowEditUserEmployment && !employeeService.isNonSelected"
      (menuItemClick)="createNewEmploymentDialogVisible = true"
      [field]="'AddSecondEmployee'"
    >
    </app-menu-button-item>
    <app-menu-button-item
      *ngIf="allowEditUserEmployment && !employeeService.isNonSelected"
      (menuItemClick)="synchronizeEmploymentWithTemplateDialogVisble = true"
      [field]="'SynchronizeWithTemplate'"
    >
    </app-menu-button-item>
    <app-menu-button-item
      *ngIf="allowEditUserEmployment && !employeeService.isNonSelected"
      (menuItemClick)="deleteEmployeeDialogVisible = true"
      [field]="'DeleteEmployee'"
    >
    </app-menu-button-item>

    <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
    <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
  </app-menu-button>

  <!-- Temporary workaround to make space for the edit button -->
  <app-action-button
    *ngIf="!editMode && allowEditUserEmployment && !employeeService.isNonSelected && hasUserEmployment"
    buttonClass="Action-button"
  ></app-action-button>
</div>

<app-new-employee-dialog [(visible)]="createNewEmployeeDialogVisible"></app-new-employee-dialog>

<app-delete-employee
  [currentCompanyUser]="employeeService.employee | async"
  [currentUserEmployment]="employeeService.employment | async"
  [(isVisible)]="deleteEmployeeDialogVisible"
>
</app-delete-employee>

<app-view-tax-card-history-dialog
  [(isVisible)]="taxcardHistoryDialogVisible"
  [userEmploymentId]="taxCardObject?.userEmploymentId"
  (addNewTaxInfo)="onCreateTaxInfo($event)"
  (taxCardChange)="onReloadTaxCard()"
></app-view-tax-card-history-dialog>

<app-request-tax-card-dialog
  [(isVisible)]="requestTaxcardDialogVisible"
  [taxcardTypes]="taxCardObject?.taxCardTypes"
  [userEmploymentId]="taxCardObject?.userEmploymentId"
  (change)="onReloadTaxCard()"
></app-request-tax-card-dialog>

<app-new-employment-dialog [currentCompanyUserId]="companyUser?.Id" [(isVisible)]="createNewEmploymentDialogVisible">
</app-new-employment-dialog>

<app-dialog
  (action)="onDialogSynchronizeEmploymentWithTemplate($event)"
  [(visible)]="synchronizeEmploymentWithTemplateDialogVisble"
  [leadingText]="'Employee.MatchWithTemplateConfirm' | translate"
>
  <app-dialog-action type="No"></app-dialog-action>
  <app-dialog-action type="Yes"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onCreateTemplateConfirmationDialogAction($event)"
  [(visible)]="createTemplateConfirmationDialogVisible"
  [leadingText]="'EmployeeEmployment.ConfirmCreateTemplate' | translate"
>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Proceed"></app-dialog-action>
</app-dialog>

<app-dialog (action)="onTemplateNameDialogAction($event)" [(visible)]="templateNameDialogVisible">
  <app-text-edit [editMode]="true" [(value)]="newTemplateName" [label]="'CompanyHiringStatus.TemplateName' | translate">
  </app-text-edit>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Create"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="createTemplateTemplateCompletedDialogVisible"
  [leadingText]="'EmployeeEmployment.CreateTemplateCompletedMessage' | translate"
>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>

<app-dialog
  [(visible)]="requestTaxcardWarningDialog"
  (action)="onRequestTaxcardWarningDialogAction($event)"
  [leadingText]="'EmployeeGeneral.RequestTaxCardForAlreadyRequested' | translate"
>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="AlreadyRequestedTaxCardConfirmButton"></app-dialog-action>
</app-dialog>

<app-new-employment-dialog [(isVisible)]="newEmploymentDialogVisible" [currentCompanyUserId]="companyUser?.Id">
</app-new-employment-dialog>
