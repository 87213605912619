import { EventEmitter, OnDestroy, OnInit, QueryList } from '@angular/core';
import { Browser } from '../../common/browser';
import { EmployeeService } from '../../services/employee.service';
import { SessionService } from '../../services/session/session.service';
import { MenuButtonItemComponent } from '../menu-button-item/menu-button-item.component';
import { ReportDialogService } from '../report-dialog/report-dialog.service';
var MenuButtonComponent = /** @class */ (function () {
    function MenuButtonComponent(reportDialogService, sessionService, employeeService) {
        this.reportDialogService = reportDialogService;
        this.sessionService = sessionService;
        this.employeeService = employeeService;
        this.menuClick = new EventEmitter();
        this.menuChangeClick = new EventEmitter();
        this.errorChangeEditMode = new EventEmitter();
        this.menuOpen = false;
        this.keepOpen = false;
    }
    Object.defineProperty(MenuButtonComponent.prototype, "isMobile", {
        get: function () {
            var browser = new Browser();
            return browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuButtonComponent.prototype, "isShowReportOption", {
        get: function () {
            if (this.sessionService.currentState.includes('tabs.employee') && this.employeeService.isNonSelected) {
                return false;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    MenuButtonComponent.prototype.ngOnInit = function () { };
    MenuButtonComponent.prototype.ngOnDestroy = function () { };
    MenuButtonComponent.prototype.closeMenu = function () {
        this.menuOpen = this.keepOpen;
        this.keepOpen = false;
    };
    MenuButtonComponent.prototype.closeMenuDbl = function () {
        this.menuOpen = this.keepOpen;
        this.keepOpen = false;
    };
    MenuButtonComponent.prototype.toggleMenu = function (e) {
        this.menuOpen = !this.menuOpen;
        this.keepOpen = this.menuOpen;
    };
    MenuButtonComponent.prototype.onMenuClickEvent = function (data) {
        this.menuClick.emit(data);
    };
    MenuButtonComponent.prototype.onEditModeChange = function (editModeMenu) {
        this.menuChangeClick.emit(editModeMenu);
    };
    MenuButtonComponent.prototype.onErrorChangeEditMode = function () {
        this.errorChangeEditMode.emit();
    };
    MenuButtonComponent.prototype.onkeydown = function (event) {
        if (event.code === 'Escape') {
            this.closeMenu();
            event.stopPropagation();
        }
    };
    return MenuButtonComponent;
}());
export { MenuButtonComponent };
