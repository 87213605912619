import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { GridModule } from '@progress/kendo-angular-grid';

import { AccountantModule } from '../accountant/accountant.module';
import { CompanyModule } from '../company/company.module';
import { CustomControlsModule } from '../custom-controls/custom-controls.module';
import { EmployeeModule } from '../employee/employee.module';
import { SelfServiceModule } from '../self-service/self-service.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';

import { AcceptDpaDialogComponent } from './accept-dpa-dialog.component';
import { AccountComponent } from './account.component';
import { ChangeAvatarDialogComponent } from './change-avatar-dialog.component';
import { CompanySelectorComponent } from './company-selector.component';
import { LanguageSelectorDialogComponent } from './language-selector-dialog.component';
import { ChangePasswordDialogComponent } from './login/change-password-dialog.component';
import { ChangeTemporaryPasswordDialogComponent } from './login/change-temporary-password-dialog.component';
import { LoginComponent } from './login/login.component';
import { ReloginDialogComponent } from './login/relogin-dialog.component';
import { ResetPasswordDialogComponent } from './login/reset-password-dialog.component';
import { RegisterSupportUsageDialogComponent } from './register-support-usage-dialog/register-support-usage-dialog.component';
import { TabsComponent } from './tabs.component';
import { UserMenuComponent } from './user-menu/user-menu.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    GridModule,
    CustomControlsModule,
    SharedComponentsModule,
    AccountantModule,
    CompanyModule,
    EmployeeModule,
    SelfServiceModule
  ],
  declarations: [
    LoginComponent,
    TabsComponent,
    ChangePasswordDialogComponent,
    ChangeTemporaryPasswordDialogComponent,
    ReloginDialogComponent,
    ResetPasswordDialogComponent,
    AcceptDpaDialogComponent,
    AccountComponent,
    ChangeAvatarDialogComponent,
    CompanySelectorComponent,
    LanguageSelectorDialogComponent,
    UserMenuComponent,
    RegisterSupportUsageDialogComponent
  ],
  exports: [LoginComponent, TabsComponent, AccountComponent],
  entryComponents: [LoginComponent, TabsComponent, AccountComponent]
})
export class MainModule {}
