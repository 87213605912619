import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
var BroadcastService = /** @class */ (function () {
    function BroadcastService() {
        this.eventBus = new Subject();
    }
    BroadcastService.prototype.broadcast = function (key, data) {
        this.eventBus.next({ key: key, data: data });
    };
    BroadcastService.prototype.on = function (key) {
        return this.eventBus.asObservable().pipe(filter(function (event) { return event.key === key; }), map(function (event) { return event.data; }));
    };
    BroadcastService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BroadcastService_Factory() { return new BroadcastService(); }, token: BroadcastService, providedIn: "root" });
    return BroadcastService;
}());
export { BroadcastService };
