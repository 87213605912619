import { Injectable } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { IGetBalancesEmployeeViewByDate_Result } from '../../services/api/api-model';
import { BalanceAdjustmentRequest, BalancesRequest, IBalancePeriod } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { CompanyService } from '../../services/company.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';

@Injectable({
  providedIn: 'root'
})
export abstract class ResidualVacationService extends CompanyService {
  private BalancesEmployeeViewByDateSubject: BehaviorSubject<
    IGetBalancesEmployeeViewByDate_Result[]
  > = new BehaviorSubject<IGetBalancesEmployeeViewByDate_Result[]>([]);
  public get BalancesEmployeeViewByDate(): Observable<IGetBalancesEmployeeViewByDate_Result[]> {
    return this.BalancesEmployeeViewByDateSubject.asObservable();
  }

  private vacationPeriodSubject: BehaviorSubject<IBalancePeriod[]>;
  public get VacationPeriod(): Observable<IBalancePeriod[]> {
    if (!this.vacationPeriodSubject) {
      this.loadVacationPeriod();
    }
    return this.vacationPeriodSubject.asObservable();
  }

  private loadVacationPeriod(): void {
    if (!this.vacationPeriodSubject) {
      this.vacationPeriodSubject = new BehaviorSubject<IBalancePeriod[]>([]);
    }
    this.staticDataService.VacationPeriod.pipe().subscribe((data: IBalancePeriod[]): void => {
      this.vacationPeriodSubject.next(data);
    });
  }

  public VacationPeriodId: number;
  public showEmployeePaidVacation = true;
  public hideEmployeeNoRemainingBlance = true;
  public selectedPeriodItem: IBalancePeriod;
  public effectivePer: Date;
  public originalDataBalancesEmployeeViewByDate: IGetBalancesEmployeeViewByDate_Result[] | any[] = [];
  public isFirstLoad = true;

  constructor(
    protected dataService: DataService,
    protected staticDataService: StaticDataService,
    protected settingService: SettingService,
    protected sessionService: SessionService,
    protected transitionService: TransitionService,
    protected broadcaster: BroadcastService
  ) {
    super(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster);
  }

  public vacationPeriodChanged(): void {
    this.selectedPeriodItem = this.vacationPeriodSubject.value.find(
      (model: IBalancePeriod) => model.Id === this.VacationPeriodId
    );
    if (this.selectedPeriodItem) {
      // GS-7639 Require FROZEN2019 Hard code default 30.04.2020
      if (this.selectedPeriodItem.SpecialIdentifier && this.selectedPeriodItem.SpecialIdentifier === 'FROZEN2019') {
        this.effectivePer = this.staticDataService.createValidDate(2020, 3, 30);
      } else {
        this.effectivePer = this.selectedPeriodItem.SpendingEndDate;
      }
    }

    this.isFirstLoad = true;
    // this.Balances_GetResidualVacationBalances();
  }

  public Balances_GetResidualVacationBalances(): void {
    if (!this.effectivePer || !this.VacationPeriodId) {
      return;
    }
    if (!this.BalancesEmployeeViewByDateSubject) {
      this.BalancesEmployeeViewByDateSubject = new BehaviorSubject<IGetBalancesEmployeeViewByDate_Result[]>([]);
    }

    const request: BalancesRequest = {
      BalanceDefinitionId: 1,
      BalancePeriodId: this.VacationPeriodId,
      PerDate: this.effectivePer
    };

    this.BalancesEmployeeViewByDateSubject.next([]);

    this.localBlanceApiService(request).subscribe((data: IGetBalancesEmployeeViewByDate_Result[]): void => {
      this.originalDataBalancesEmployeeViewByDate = data;
      this.filterData();
    });
  }

  private localBlanceApiService(request: BalancesRequest) {
    return this.dataService.Balances_GetBalances(request).pipe(
      tap((data: IGetBalancesEmployeeViewByDate_Result[]) => {
        data = data.map((item: IGetBalancesEmployeeViewByDate_Result | any) => {
          item.DaysEarned = item.DaysEarned + item.SecondaryDaysEarned;
          item.DaysSpent = item.DaysSpent + item.SecondaryDaysSpent;

          item.DaysTotal = item.DaysTotal + item.CurrentBalanceSecondaryDays;
          item.AmountTotal = item.AmountTotal + item.SecondaryAmountTotal;

          item.AdjustDays = null;
          item.SpendDays = null;
          item.MoveDays = null;
          item.PayoutDays = null;

          return item;
        });
      })
    );
  }

  public Reload_Balances_GetResidualVacationBalances(): void {
    if (!this.effectivePer || !this.VacationPeriodId) {
      return;
    }
    if (!this.BalancesEmployeeViewByDateSubject) {
      this.BalancesEmployeeViewByDateSubject = new BehaviorSubject<IGetBalancesEmployeeViewByDate_Result[]>([]);
    }

    const request: BalancesRequest = {
      BalanceDefinitionId: 1,
      BalancePeriodId: this.VacationPeriodId,
      PerDate: this.effectivePer
    };

    this.localBlanceApiService(request).subscribe((data: IGetBalancesEmployeeViewByDate_Result[]): void => {
      if (data && data.length > 0) {
        let arrayItemDirty: IGetBalancesEmployeeViewByDate_Result[] | any[] = [];
        if (this.originalDataBalancesEmployeeViewByDate && this.originalDataBalancesEmployeeViewByDate.length > 0) {
          arrayItemDirty = this.originalDataBalancesEmployeeViewByDate.filter(
            (item: IGetBalancesEmployeeViewByDate_Result | any) => item.isDirty === true
          );
        }

        data.forEach((item: IGetBalancesEmployeeViewByDate_Result) => {
          const originalItem = this.originalDataBalancesEmployeeViewByDate.find(
            (temp: IGetBalancesEmployeeViewByDate_Result) => item.UserEmploymentId === temp.UserEmploymentId
          );
          if (originalItem) {
            // Update read only cell
            originalItem.FullName = item.FullName;
            originalItem.BasedOnTemplateName = item.BasedOnTemplateName;
            originalItem.DepartmentName = item.DepartmentName;
            originalItem.ExternalReference = item.ExternalReference;

            originalItem.DaysEarned = item.DaysEarned;
            originalItem.DaysSpent = item.DaysSpent;
            originalItem.DaysTotal = item.DaysTotal;
            originalItem.AmountTotal = item.AmountTotal;

            originalItem.BaseAmount = item.BaseAmount || null;
            originalItem.AmountEarned = item.AmountEarned || null;
            originalItem.isDirty = false;

            if (
              arrayItemDirty.find(
                (tempModel: IGetBalancesEmployeeViewByDate_Result | any) =>
                  tempModel.UserEmploymentId === originalItem.UserEmploymentId
              )
            ) {
              originalItem.isDirty = true;
            }
          }
        });

        this.originalDataBalancesEmployeeViewByDate = data;
      } else {
        this.originalDataBalancesEmployeeViewByDate = [];
      }

      this.filterData();
    });
  }

  public filterData(): void {
    let result: IGetBalancesEmployeeViewByDate_Result[] | any[] = [];
    result = this.originalDataBalancesEmployeeViewByDate;
    if (this.showEmployeePaidVacation) {
      result = result.filter((item: IGetBalancesEmployeeViewByDate_Result) => {
        return item.VacationTypeId === 1;
      });
    }
    if (this.hideEmployeeNoRemainingBlance) {
      result = result.filter((item: IGetBalancesEmployeeViewByDate_Result) => {
        return item.DaysTotal;
      });
    }
    this.BalancesEmployeeViewByDateSubject.next(result);
  }

  public save(model: BalanceAdjustmentRequest): void {
    this.dataService.Balances_AdjustBalances(model).subscribe(
      (data: IGetBalancesEmployeeViewByDate_Result[]): void => {
        this.afterSaveSucess(data);
        this.completeSave();
      },
      () => {
        setTimeout(() => {
          this.completeSave();
        }, 1000);
      },
      () => {
        // this.completeSave();
      }
    );
  }

  protected get allowShortcut(): boolean {
    return this.sessionService.currentState === 'tabs.company.balances.residualvacation';
  }

  public abstract afterSaveSucess(data: IGetBalancesEmployeeViewByDate_Result[]): void;
  public abstract completeSave(): void;
}
