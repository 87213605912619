import { ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { ControlComponentBase } from '../../common/control-component-base';

@Component({
  selector: 'app-grid-flag-column',
  templateUrl: './grid-flag-column.component.html'
})
export class GridFlagColumnComponent extends ControlComponentBase<any> {
  constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
  }
  @Input() public countryCode: string;
}
