<app-dialog [(visible)]="visible" [width]="900" (action)="dialogAction($event)">
  <div>
    <p [innerHTML]="'Accountant.JoinWarning' | translate"></p>
    <div class="Cards">
      <div class="Card" style="max-width: 50rem">
        <!-- <app-module-check [moduleId]="0"> -->
        <div class="Employee-timeControls" style="margin-top: 12px;">
          <app-text-edit
            style="width: 100%"
            [label]="'Accountant.FindAccount' | translate"
            [(value)]="service.filter"
            [editMode]="true"
          ></app-text-edit>
        </div>

        <app-grid
          class="accountant-join"
          [data]="service.joinableAccounts | async"
          [pageable]="true"
          [idColumn]="'Id'"
          [editMode]="false"
          [(selectedItem)]="service.selectedAccount"
        >
          <app-grid-column
            [field]="'Name'"
            [type]="'text'"
            [title]="'Accountant.CompanyName' | translate"
            [width]="140"
          ></app-grid-column>
          <app-grid-column
            [type]="'combobox'"
            [field]="'AccountTypeId'"
            [title]="'Accountant.AccountType' | translate"
            [comboboxDataSource]="staticDataService.AccountType | async"
            [comboboxValueField]="'Id'"
            [comboboxDisplayField]="'Name'"
            [width]="140"
          ></app-grid-column>
          <app-grid-column
            [field]="'Description'"
            [type]="'text'"
            [title]="'AccountModules.Description' | translate"
          ></app-grid-column>
        </app-grid>
        <!-- </app-module-check> -->
      </div>
    </div>
  </div>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [close]="false" [disabled]="isNonSelect" [type]="'Attach'"></app-dialog-action>
</app-dialog>

<app-accountant-join-dialog
  (onreloginVisible)="visible = false"
  [(visible)]="joinDialogVisible"
></app-accountant-join-dialog>
