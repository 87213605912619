import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { WalkthroughModule } from 'angular-walkthrough';

import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { IntlModule } from '@progress/kendo-angular-intl';
import { LayoutModule } from '@progress/kendo-angular-layout';

import { CustomControlsModule } from '../custom-controls/custom-controls.module';

import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { AddDepartmentComponent } from './add-department/add-department.component';
import { AdditionalVacationComponent } from './additional-vacation/additional-vacation.component';
import { DeleteEmployeeDialogComponent } from './employee-delete/delete-employee.component';
import { BankInfoComponent } from './employee-general/controls/bank-info.component';
import { BenefitsComponent } from './employee-general/controls/benefits.component';
import { ContactInfoComponent } from './employee-general/controls/contact-info.component';
import { EmployeeGeneralComponent } from './employee-general/employee-general.component';
import { CprInfoComponent } from './employee-info/controls/cpr-info.component';
import { EmployeeAvatarComponent } from './employee-info/controls/employee-avatar.component';
import { EmployeeNameComponent } from './employee-info/controls/employee-name.component';
import { PositionInfoComponent } from './employee-info/controls/position-info.component';
import { EmployeeInfoComponent } from './employee-info/employee-info.component';
import { EmployeeTimeMobileComponent } from './employee-time/employee-time-mobile.component';
import { EmployeeTimeComponent } from './employee-time/employee-time.component';
import { EmploymentStatisticsComponent } from './employment-statistics/employment-statistics.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FixedPanelComponent } from './fixed-panel/fixed-panel.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { HelpComponent } from './help/help.component';
import { HiringDataComponent } from './hiring-data/hiring-data.component';
import { LoadingTipsComponent } from './loading-tips/loading-tips.component';
import { MenuButtonItemComponent } from './menu-button-item/menu-button-item.component';
import { MenuButtonComponent } from './menu-button/menu-button.component';
import { MultiPurposeBalanceCompensationComponent } from './multi-purpose balance/multi-purpose-balance-compensation.component';
import { MultiPurposeOffDayCompensationComponent } from './multi-purpose-off-day-compensation/multi-purpose-off-day-compensation.component';
import { NewEmployeeDialogComponent } from './new-employee/new-employee-dialog.component';
import { NewEmploymentDialogComponent } from './new-employment/new-employment-dialog.component';
import { NewUserDialogComponent } from './new-user/new-user-dialog.component';
import { PensionProviderComponent } from './pension-provider/pension-provider.component';
import { PreferenceMobileComponent } from './preference/Mobile/preference-mobile.component';
import { PreferenceComponent } from './preference/preference.component';
import { ReportDialogComponent } from './report-dialog/report-dialog.component';
import { RevertPayslipDialogComponent } from './revert-payslip/revert-payslip-dialog.component';
import { RequestTaxCardDialogComponent } from './tax-card/request-tax-card-dialog.component';
import { TaxCardAddOnComponent } from './tax-card/tax-card-addon.component';
import { TaxCardComponent } from './tax-card/tax-card.component';
import { ViewTaxCardHistoryDialogComponent } from './tax-card/view-tax-card-history-dialog.component';
import { TaxEIncomeComponent } from './tax-e-income/tax-e-income.component';
import { TimeAndAbsenceComponent } from './time-and-absence/time-and-absence.component';
import { VacationComponent } from './vacation/vacation.component';
import { WalkThroughGSComponent } from './walk-through/walkthrough.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    CustomControlsModule,
    PerfectScrollbarModule,
    WalkthroughModule,
    LayoutModule,
    IntlModule,
    TooltipModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  declarations: [
    AdditionalVacationComponent,
    HelpComponent,
    FeedbackComponent,
    MultiPurposeOffDayCompensationComponent,
    MultiPurposeBalanceCompensationComponent,
    PensionProviderComponent,
    ReportDialogComponent,
    GetStartedComponent,
    EmploymentStatisticsComponent,
    TaxEIncomeComponent,
    TimeAndAbsenceComponent,
    PreferenceComponent,
    PreferenceMobileComponent,
    LoadingTipsComponent,
    EmployeeInfoComponent,
    CprInfoComponent,
    EmployeeAvatarComponent,
    EmployeeNameComponent,
    PositionInfoComponent,
    NewEmploymentDialogComponent,
    NewEmployeeDialogComponent,
    EmployeeGeneralComponent,
    BankInfoComponent,
    BenefitsComponent,
    ContactInfoComponent,
    TaxCardComponent,
    TaxCardAddOnComponent,
    HiringDataComponent,
    RequestTaxCardDialogComponent,
    ViewTaxCardHistoryDialogComponent,
    EmployeeTimeComponent,
    EmployeeTimeMobileComponent,
    RevertPayslipDialogComponent,
    VacationComponent,
    MenuButtonComponent,
    MenuButtonItemComponent,
    WalkThroughGSComponent,
    DeleteEmployeeDialogComponent,
    NewUserDialogComponent,
    AddDepartmentComponent,
    FixedPanelComponent
  ],
  exports: [
    AdditionalVacationComponent,
    HelpComponent,
    FeedbackComponent,
    MultiPurposeOffDayCompensationComponent,
    MultiPurposeBalanceCompensationComponent,
    PensionProviderComponent,
    ReportDialogComponent,
    GetStartedComponent,
    EmploymentStatisticsComponent,
    TaxEIncomeComponent,
    TimeAndAbsenceComponent,
    PreferenceComponent,
    PreferenceMobileComponent,
    LoadingTipsComponent,
    EmployeeInfoComponent,
    NewEmploymentDialogComponent,
    NewEmployeeDialogComponent,
    EmployeeGeneralComponent,
    TaxCardComponent,
    HiringDataComponent,
    RequestTaxCardDialogComponent,
    ViewTaxCardHistoryDialogComponent,
    EmployeeTimeComponent,
    EmployeeTimeMobileComponent,
    RevertPayslipDialogComponent,
    VacationComponent,
    MenuButtonComponent,
    MenuButtonItemComponent,
    WalkThroughGSComponent,
    DeleteEmployeeDialogComponent,
    NewUserDialogComponent,
    AddDepartmentComponent,
    FixedPanelComponent
  ]
})
export class SharedComponentsModule {}
