import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter } from '@angular/core';
import { ElementRef } from '@angular/core';
import { ControlComponentBase } from '../../common/control-component-base';
import { SessionService } from '../../services/session/session.service';
import { IconDefinitionsComponent } from './icon-definitions.component';
var IconComponent = /** @class */ (function (_super) {
    tslib_1.__extends(IconComponent, _super);
    function IconComponent(elementRef, sessionService, changeDetectorRef) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.sessionService = sessionService;
        _this.istooltipHTML = false;
        _this.disable = false;
        _this.isSelfService = '';
        _this.isOpenLink = true;
        _this.click = new EventEmitter();
        return _this;
    }
    Object.defineProperty(IconComponent.prototype, "disableClass", {
        get: function () {
            if (this.disable) {
                return 'disableClass';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IconComponent.prototype, "absoluteUrl", {
        // public get absoluteUrl(): string { return window.location.href; }
        get: function () {
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IconComponent.prototype, "isIOSMobileApp", {
        get: function () {
            return this.sessionService.browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IconComponent.prototype, "class", {
        get: function () {
            if (this.iconClass) {
                return this.iconClass;
            }
            else {
                var result = 'Icon ';
                var icon = IconDefinitionsComponent.icons[this.icon];
                if (icon) {
                    result += icon.class ? ' ' + icon.class : '';
                    result += this.size ? ' Icon--' + this.size : '';
                }
                return result;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IconComponent.prototype, "iconSvg", {
        get: function () {
            var icon = IconDefinitionsComponent.icons[this.icon];
            if (icon) {
                return IconDefinitionsComponent.icons[this.icon].svg;
            }
            else {
                console.error('Unknown icon:', this.icon);
                return 'share';
            }
        },
        enumerable: true,
        configurable: true
    });
    IconComponent.prototype.onIconClick = function () {
        if (this.link && !this.isIOSMobileApp && this.isOpenLink) {
            window.open(this.link);
        }
        else {
            // this.click.emit();
        }
    };
    return IconComponent;
}(ControlComponentBase));
export { IconComponent };
