import { WindowReference } from '../../../../windowReference';
var TooltipPriceComponent = /** @class */ (function () {
    function TooltipPriceComponent(windowRef) {
        this.windowRef = windowRef;
    }
    TooltipPriceComponent.prototype.hide = function () {
        this.tooltip.hide();
    };
    TooltipPriceComponent.prototype.onClick = function (packet) {
        var _this = this;
        setTimeout(function () {
            _this.tooltip.show(packet);
        });
    };
    Object.defineProperty(TooltipPriceComponent.prototype, "tooltipPackageWidth", {
        get: function () {
            var width = this.windowRef && this.windowRef.nativeWindow ? this.windowRef.nativeWindow.innerWidth : 800;
            return width > 790 ? 500 : width * 0.9;
        },
        enumerable: true,
        configurable: true
    });
    return TooltipPriceComponent;
}());
export { TooltipPriceComponent };
