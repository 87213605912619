import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../common/form-component-base';
import { NumericTextBoxOptions } from '../../custom-controls/numeric-edit/numeric-text-box-options';
import { IBaseCalculationMethod, IMonth } from '../../services/api/api-model';
import { StaticDataService } from '../../services/api/static-data.service';
import { EmployeeService } from '../../services/employee.service';
import { SessionService } from '../../services/session/session.service';
import { AdditionalVacationModel } from './additional-vacation-model';

@Component({
  selector: 'app-additional-vacation',
  templateUrl: './additional-vacation.component.html'
})
export class AdditionalVacationComponent extends FormComponentBase implements OnInit, OnDestroy {
  @Input() public NameOfEmplomentIdfeild = 'UserEmploymentId';
  @Input() public defaultId: number;
  @Input() public allowEdit = false;
  @Input() public disableBySecondaryIncomeType: boolean;

  private employmentId: number;
  @Input()
  public get EmploymentId(): number {
    return this.employmentId;
  }
  public set EmploymentId(value: number) {
    if (value && value !== this.employmentId) {
      this.employmentId = value;
    }
  }

  private currentmodel: AdditionalVacationModel[] = [];
  @Input()
  public get model(): AdditionalVacationModel[] {
    return this.currentmodel;
  }
  public set model(value: AdditionalVacationModel[]) {
    this.currentmodel = [];
    this.AllowEditSection = false;

    if (value && value.length > 0) {
      this.SectionData = value.find((item: AdditionalVacationModel) => item.BalanceDefinitionId === 7);

      if (this.SectionData) {
        this.createSafeModel(this.SectionData);
        this.currentmodel = value;

        this.EarningFixedAmountPrincipleIdRadioValue = this.SectionData.EarningFixedAmountPrincipleId === 13 ? 2 : 1;
      } else {
        this.SectionData = new AdditionalVacationModel();
      }
    } else {
      this.EarningFixedAmountPrincipleIdRadioValue = undefined;
      this.SectionData = new AdditionalVacationModel();
    }

    this.SectionData[this.NameOfEmplomentIdfeild] = this.employmentId;
    this.modelChange.emit(this.currentmodel);

    this.onEarningFixedAmountPrinciple();

    this.childIsValid = Array(6).fill(true);
    this.childIsDirty = Array(6).fill(false);
  }

  public SectionData: AdditionalVacationModel | any = new AdditionalVacationModel();
  public AllowEditSection = false;
  public selectedEarningFixedAmountPrinciple: { Id: number; Name: string } = {
    Id: null,
    Name: ''
  };
  public datasourceEarningFixedAmountPrinciple: IMonth[];
  public EarningFixedAmountPrincipleIdRadioValue: number;

  public AdditionalEarningFixedAmountPrincipleIdOpyion1Label = '';
  public intOption: NumericTextBoxOptions = { min: 0, format: 'n0', step: 0, spinners: false, decimals: 0 };

  private translationServiceTerms: string[];
  private AdditionalEarningFixedAmountPrincipleIdOpyion1Translate = '';
  private AdditionalEarningFixedAmountTranslate = '';
  public vacationData: any;

  public get getAdditionalEarningFixedAmountPrincipleIdOpyion1Label() {
    if (!this.editMode) {
      return this.AdditionalEarningFixedAmountPrincipleIdOpyion1Label;
    }

    return this.AdditionalEarningFixedAmountPrincipleIdOpyion1Translate.replace('{{y}}', '');
  }

  @Output() public modelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public parentChangeEditMode: EventEmitter<any> = new EventEmitter<any>();

  public get isEmptymodel(): boolean {
    if (
      !this.model ||
      (this.model &&
        (this.model.length === 0 ||
          !this.model.find((item: AdditionalVacationModel) => item.BalanceDefinitionId === 7)))
    ) {
      return true;
    }
    return false;
  }

  public get isEnableEditSection(): boolean {
    if (this.editMode) {
      if (!this.isEmptymodel) {
        return true;
      } else if (this.AllowEditSection) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  constructor(
    public translateService: TranslateService,
    private sessionService: SessionService,
    public staticDataService: StaticDataService,
    private employeeService: EmployeeService
  ) {
    super();

    this.childIsValid = Array(6).fill(true);
    this.childIsDirty = Array(6).fill(false);

    this.translationServiceTerms = [
      'EmployeeEmployment.AdditionalEarningFixedAmountPrincipleIdOpyion1',
      'EmployeeEmployment.AdditionalEarningFixedAmount'
    ];

    this.staticDataService.Month.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IMonth[]) => {
      this.datasourceEarningFixedAmountPrinciple = data;
      this.onEarningFixedAmountPrinciple();
    });

    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateService.get(this.translationServiceTerms).subscribe((translations: { [key: string]: string }) => {
        this.AdditionalEarningFixedAmountPrincipleIdOpyion1Translate =
          translations['EmployeeEmployment.AdditionalEarningFixedAmountPrincipleIdOpyion1'];
        this.AdditionalEarningFixedAmountTranslate = translations['EmployeeEmployment.AdditionalEarningFixedAmount'];
      });
    });
  }

  public get ArrayAdditionalEarningFixedAmountTranslate(): string[] {
    if (this.AdditionalEarningFixedAmountTranslate) {
      return this.AdditionalEarningFixedAmountTranslate.split('{{x}}');
    }
    return [];
  }

  public ngOnInit(): void {
    this.editModeChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((valueChange: boolean) => {
      if (!valueChange) {
        this.AllowEditSection = false;
      }
    });

    this.staticDataService.PensionBaseCalculationMethod.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (baseCalculationMethods: IBaseCalculationMethod[]) => {
        this.vacationData = baseCalculationMethods.filter((pension: any) => {
          return pension.IsExtraVacationHoursBase;
        });
      }
    );
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public EnableSection(): void {
    this.AllowEditSection = true;
    this.EarningFixedAmountPrincipleIdRadioValue = 1;

    if (this.defaultId || this.defaultId === 0) {
      this.SectionData.Id = this.defaultId;
    }
    if (this.vacationData.length > 0 && !this.SectionData.BaseCalculationMethodId) {
      this.SectionData.BaseCalculationMethodId = 2;
    }
    this.createSafeModel(this.SectionData);
    this.currentmodel.push(this.SectionData);
    this.childIsDirty[4] = true;
    this.componentChange();
  }

  public createSafeModel(model: AdditionalVacationModel | any, isChange: boolean = false): void {
    model.BalanceDefinitionId = model.BalanceDefinitionId || 7;
    model.EarningFixedAmount = this.employeeService.checkNumber(model.EarningFixedAmount);
    model.EarningFixedAmountPrincipleId = this.employeeService.checkNumber(model.EarningFixedAmountPrincipleId);
    model.PensionShareFixedAmount = this.employeeService.checkNumber(model.PensionShareFixedAmount);
    model.Id = model.Id || null;
    model.DaysConversionPercentage = this.employeeService.checkNumber(model.DaysConversionPercentage);
    model.DaysEarnedPerPayslip = this.employeeService.checkNumber(model.DaysEarnedPerPayslip);
    model.EarningPercentage = this.employeeService.checkNumber(model.EarningPercentage);
    model.BaseCalculationMethodId = model.BaseCalculationMethodId || null;
    model.PayoutPercentage = this.employeeService.checkNumber(model.PayoutPercentage);
    model.PensionSharePercentage = this.employeeService.checkNumber(model.PensionSharePercentage);
    model.CustomBehaviorId = model.CustomBehaviorId || null;
    model[this.NameOfEmplomentIdfeild] = model[this.NameOfEmplomentIdfeild] || null;

    this.onEarningFixedAmountPrinciple();

    if (isChange) {
      this.componentChange();
    }
  }

  private onEarningFixedAmountPrinciple() {
    if (
      this.SectionData.EarningFixedAmountPrincipleId &&
      this.datasourceEarningFixedAmountPrinciple &&
      this.datasourceEarningFixedAmountPrinciple.length > 0
    ) {
      const dataSelect = this.datasourceEarningFixedAmountPrinciple.find(
        (item: IMonth) => item.Id === this.SectionData.EarningFixedAmountPrincipleId
      );

      if (dataSelect) {
        this.selectedEarningFixedAmountPrinciple = {
          Id: dataSelect.Id,
          Name: dataSelect.Name
        };
      }

      this.AdditionalEarningFixedAmountPrincipleIdOpyion1Label = this.AdditionalEarningFixedAmountPrincipleIdOpyion1Translate.replace(
        '{{y}}',
        this.selectedEarningFixedAmountPrinciple.Name
      );
    }
  }

  public EarningFixedAmountPrincipleIdRadioChange() {
    this.SectionData.EarningFixedAmountPrincipleId =
      this.EarningFixedAmountPrincipleIdRadioValue === 2
        ? 13
        : this.SectionData.EarningFixedAmountPrincipleId === 13
        ? 5
        : this.SectionData.EarningFixedAmountPrincipleId;
    this.componentChange();
  }

  public componentChange() {
    this.onChange();
    this.modelChange.emit(this.currentmodel);
  }

  public changeModeAndEnableSection() {
    this.parentChangeEditMode.emit();
    this.EnableSection();
  }

  public NumbericTextBoxFFHoursEarnedPerYearOptions: NumericTextBoxOptions = {
    format: 'n2',
    decimals: 2,
    step: 1,
    spinners: false
  };

  public numberToString(value: number) {
    if (value) {
      return this.sessionService.toString(value);
    }

    return '0';
  }
}
