import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { CellClickEvent, GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Global } from '../common/global';
import { ISimpleCompany } from '../services/api/api-model';
import { SessionService } from '../services/session/session.service';

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanySelectorComponent implements OnDestroy, AfterViewInit {
  @Input() public companies: ISimpleCompany[] = [];
  @Output() public selectedCompanyIdChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public companyChange: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild(GridComponent, { static: false }) private grid: GridComponent;
  @ViewChild('companyFilter', { static: false }) private filterInput: ElementRef;
  @ViewChild('pageInfo', { static: false }) public pageInfo: any;

  private selectedCompanyIdValue: number;
  @Input()
  public get selectedCompanyId(): number {
    return this.selectedCompanyIdValue;
  }
  public set selectedCompanyId(value: number) {
    if (this.selectedCompanyIdValue !== value) {
      this.selectedCompanyIdValue = value;
      this.selectedCompanyIdChange.emit(value);
    }
  }

  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);

      if (value) {
        if (this.hasMultiCompanyModule) {
          this.companies.forEach((company: ISimpleCompany) => {
            (company as any).DisplayName =
              company.Name + (Global.SESSION.HasBackendAccess ? ' (' + company.Id + ')' : '');
          });
          this.companies.sort(this.compareByCompanyName);
          this.gridData = this.companies;

          if (!this.isMobile) {
            setTimeout(() => this.filterInput.nativeElement.focus());
          }
        } else {
          this.companies.forEach((company: ISimpleCompany) => {
            (company as any).DisplayName =
              (company.Name.length < 30 ? company.Name : company.Name.substring(0, 30) + '...') +
              (Global.SESSION.HasBackendAccess ? ' (' + company.Id + ')' : '');
          });
          this.companies.sort(this.compareByCompanyName);
          this.comboBoxData = this.companies;
        }
      } else {
        this.gridData = [];
        this.comboBoxData = [];
        this.filterValue = '';
        this.selectedRows = [];

        this.filter = '';
      }

      this.loadItems();
    }
  }

  private filterValue: string;
  public get filter(): string {
    return this.filterValue;
  }
  public set filter(value: string) {
    this.filterValue = value;
    this.filterSubject.next(value);
  }

  public get hasMultiCompanyModule(): boolean {
    return this.sessionService.feature.hasModuleId(20) || this.HasBackendAccess;
  }

  public get HasBackendAccess(): boolean {
    return Global.SESSION && Global.SESSION.HasBackendAccess;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public comboBoxData: ISimpleCompany[] = [];
  public gridView: GridDataResult;
  public pageSize = 30;
  public skip = 0;
  public selectedRows: any[] = [];
  public companyId = -1;
  public pageable = true;
  private gridData: ISimpleCompany[] = [];
  private filterSubject: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(public sessionService: SessionService, private changeDetectorRef: ChangeDetectorRef) {
    this.filterSubject
      .asObservable()
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((value: string) => this.applyFilter(value));
  }

  public ngAfterViewInit(): void {
    // this.selectedRows[0] = Global.COMPANY.Id;
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private compareByCompanyName(company1: any, company2: any): number {
    if (company1.DisplayName.toLowerCase() < company2.DisplayName.toLowerCase()) {
      return -1;
    }

    if (company1.DisplayName.toLowerCase() > company2.DisplayName.toLowerCase()) {
      return 1;
    }

    return 0;
  }

  public onFilterKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.choseCompany();
      event.stopPropagation();
      event.preventDefault();
    } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      if (this.gridView.data && this.gridView.data.length > 0) {
        let rowIndex =
          this.grid.activeRow && this.grid.activeRow.index
            ? this.grid.activeRow.index
            : this.selectedRows && this.selectedRows.length > 0
            ? this.gridView.data.indexOf(
                this.gridView.data.find((model: ISimpleCompany) => model.Id === this.selectedRows[0])
              ) + 1
            : 0;
        rowIndex = event.key === 'ArrowUp' ? (rowIndex = rowIndex - 1) : (rowIndex = rowIndex + 1);
        if (rowIndex > this.gridView.data.length + 1) {
          rowIndex = 1;
        }
        if (this.gridView.data[rowIndex - 1]) {
          this.selectedRows = [this.gridView.data[rowIndex - 1].Id];
        } else {
          this.selectedRows = [this.gridView.data[0].Id];
          this.grid.focusCell(0, 0);
        }
      }
    } else if (event.key === 'Escape') {
      this.visible = false;
    }
  }

  private choseCompany() {
    if (this.selectedRows && this.selectedRows.length === 1) {
      this.companyId = this.selectedRows[0];
      const companyafterfilter: ISimpleCompany = this.gridView.data.find(
        (model: ISimpleCompany) => model.Id === this.companyId
      );
      if (companyafterfilter) {
        this.emitCompanyId(this.companyId);
        return;
      }
    }
    if (this.gridView.data.length === 1) {
      this.companyId = this.gridView.data[0].Id;
      this.emitCompanyId(this.companyId);
    } else {
      this.selectedRows = this.gridView.data && this.gridView.data.length > 0 ? [this.gridView.data[0].Id] : [];
    }
  }

  public onDialogEnter(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.choseCompany();
      event.stopPropagation();
      event.preventDefault();
    }
  }

  public onGridKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.choseCompany();
      event.stopPropagation();
      event.preventDefault();
    } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      if (this.selectedRows && this.selectedRows.length > 0 && this.gridView.data && this.gridView.data.length > 0) {
        let rowIndex =
          this.selectedRows && this.selectedRows.length > 0
            ? this.gridView.data.indexOf(
                this.gridView.data.find((model: ISimpleCompany) => model.Id === this.selectedRows[0])
              ) + 1
            : 0;

        rowIndex = event.key === 'ArrowUp' ? (rowIndex = rowIndex - 1) : (rowIndex = rowIndex + 1);

        if (rowIndex > this.gridView.data.length + 1) {
          rowIndex = 1;
        }
        if (this.gridView.data[rowIndex - 1]) {
          this.selectedRows = [this.gridView.data[rowIndex - 1].Id];
        } else {
          this.selectedRows = [this.gridView.data[0].Id];
          this.grid.focusCell(0, 0);
        }
      }
    } else if (event.key === 'Escape') {
      this.visible = false;
    }
  }

  public onCellClick(event: CellClickEvent): void {
    this.companyId = event.dataItem.Id;
    this.emitCompanyId(this.companyId);
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }

  private emitCompanyId(companyId: number): void {
    setTimeout(() => {
      this.companyChange.emit(companyId);
      this.selectedCompanyId = companyId;
      this.visible = false;
    });
  }

  private applyFilter(value: string): void {
    if (this.companies) {
      if (value) {
        const searchText: string = value.toLocaleLowerCase();
        this.gridData = this.companies.filter(
          (c: ISimpleCompany | any) =>
            (c.VatRegistrationNumber && c.VatRegistrationNumber.toLocaleLowerCase().includes(searchText)) ||
            c.DisplayName.toLocaleLowerCase().includes(searchText) ||
            (c.Id && c.Id.toString().includes(searchText)) ||
            (c.SecondaryVatRegistrationNumber && c.SecondaryVatRegistrationNumber.toString().includes(searchText))
        );
      } else {
        this.gridData = this.companies;
      }
    }

    this.skip = 0;
    this.loadItems();
  }

  private loadItems(): void {
    this.gridView = {
      data: this.gridData.slice(this.skip, this.skip + this.pageSize),
      total: this.gridData.length
    };
    if (this.gridView.total <= this.pageSize) {
      this.pageable = false;
    } else {
      this.pageable = true;
    }

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }
}
