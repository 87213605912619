import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IIntegrationStatus } from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { SessionService } from 'src/app/services/session/session.service';
import { IntegrationsService } from '../../integrations.service';
import { integrationscontrol } from '../../integrationscontrol';

@Component({
  selector: 'app-configuration-integrations',
  templateUrl: './configuration-integrations.component.html',
  styleUrls: ['./configuration-integrations.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfigurationIntegrationsComponent implements OnDestroy {
  public isShowingAutoMap = false;
  private operationId: number;
  private operationKey: string;
  public overlayVisible = false;
  public OperationRunSucess = false;
  public statusCode: IIntegrationStatus[] = [];

  constructor(
    public integrationsService: IntegrationsService,
    public sessionService: SessionService,
    private dataService: DataService,
    private employeeService: EmployeeService
  ) {
    this.integrationsService.statusCode.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IIntegrationStatus[]) => {
      this.statusCode = data;
    });
  }

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public getStatusMessageColorOperation(statuscode: number): string {
    if (statuscode && this.statusCode && this.statusCode.length > 0) {
      return this.statusCode.find((model: IIntegrationStatus) => model.Id === statuscode).Key;
    }

    return '';
  }

  public runOperation(operationId: number, control?: integrationscontrol): void {
    this.operationKey = control.key || null;
    if (operationId === 16) {
      this.operationId = operationId;
      this.isShowingAutoMap = true;
      return;
    }

    const isReloadEmployeelist: boolean = operationId === 64 || (control && control.key === 'ImportEmployees');
    this.overlayVisible = true;
    this.dataService.Integrations_RunIntegrationsOperation(this.integrationsService.selectedId, operationId).subscribe(
      () => {
        if (isReloadEmployeelist) {
          this.employeeService.loadEmployees();
        }
        this.OperationRunSucess = true;
        this.integrationsService.reloadIntegrationModel();
        this.reloadSalaryTypeGrid();

        setTimeout(() => {
          this.overlayVisible = false;
        });
      },
      () => {
        setTimeout(() => {
          this.integrationsService.reloadIntegrationModel();
          this.overlayVisible = false;
        });
      }
    );
  }

  private reloadSalaryTypeGrid(): void {
    if (this.operationKey) {
      switch (this.operationKey) {
        case 'ImportAccounts':
          this.integrationsService.TriggerReload.emit(true);
          this.operationKey = '';
          break;
        case 'AutoMapSalaryTypes':
          this.integrationsService.TriggerReload.emit(true);
          this.operationKey = '';
          break;
        default:
          break;
      }
    }
  }

  public onAutoMap(event: string): void {
    if (event === 'Proceed') {
      this.overlayVisible = true;
      this.dataService
        .Integrations_RunIntegrationsOperation(this.integrationsService.selectedId, this.operationId)
        .subscribe(
          () => {
            this.integrationsService.loadIntegrationModel();
            this.reloadSalaryTypeGrid();

            setTimeout(() => {
              this.overlayVisible = false;
            });
          },
          () => {
            setTimeout(() => {
              this.overlayVisible = false;
            });
          }
        );
    } else {
      this.operationKey = '';
    }
  }

  public isDirtyChange(changeis: boolean): void {
    this.integrationsService.tabDirty = changeis;
  }
}
