<fieldset class="Form--horizontal">
  <app-text-edit
    class="status-company-organization"
    [label]="'Accountant.AccountConcern' | translate"
    [value]="service.multiCompanyOrganization | async"
  ></app-text-edit>
  <app-text-edit [label]="'Accountant.WhoIsInvoiced' | translate" [value]="service.multiCompanyInvoice | async">
  </app-text-edit>
  <app-button
    *ngIf="sessionService.role.IsFullAdmin"
    buttonClass="Button--card"
    class="Button--card"
    (clickEvent)="leaveDialogVisible = true"
    [label]="'Accountant.Leave' | translate"
  >
  </app-button>
</fieldset>

<app-accountant-leave-dialog [(visible)]="leaveDialogVisible"></app-accountant-leave-dialog>
