import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../common/constants';
import { FormComponentBase } from '../../common/form-component-base';
import { Global } from '../../common/global';
import { IBaseCalculationMethod } from '../../services/api/api-model';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { OffDayCompensationModel } from './off-day-compensation-model';

@Component({
  selector: 'app-multi-purpose-off-day-compensation',
  templateUrl: './multi-purpose-off-day-compensation.component.html'
})
export class MultiPurposeOffDayCompensationComponent extends FormComponentBase implements OnInit {
  @Input() public disable: boolean;
  @Input() public disableBySecondaryIncomeType: boolean;

  private modelValue: OffDayCompensationModel;
  @Input()
  public get model(): OffDayCompensationModel {
    if (!this.modelValue) {
      this.modelValue = new OffDayCompensationModel();
    }

    return this.modelValue;
  }
  public set model(value: OffDayCompensationModel) {
    this.modelValue = value;
    if (this.modelChange) {
      this.modelChange.emit(value);
    }
  }
  @Output() public modelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(public staticDataService: StaticDataService, private sessionService: SessionService) {
    super();
    this.childIsDirty = Array(2).fill(false);
  }

  public get defaulHeader(): string {
    return this.isDanishCompany
      ? 'EmployeeEmployment.OffDayCompensationDanish'
      : 'EmployeeEmployment.OffDayCompensation';
  }

  public get SectionName(): string {
    const preferenceModel = this.sessionService.getCompanyPreference('Company.LegacyFlexName');
    return preferenceModel ? preferenceModel.Value : this.defaulHeader;
  }

  public flexData: any = [];
  public ngOnInit(): void {
    this.staticDataService.PensionBaseCalculationMethod.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (baseCalculationMethods: IBaseCalculationMethod[]) => {
        this.flexData = baseCalculationMethods.filter((pension: any) => {
          return pension.IsFlexBase;
        });
        if (this.flexData.length > 0 && !this.model.FlexBaseCalculationMethodId) {
          this.model.FlexBaseCalculationMethodId = 2;
        }
      }
    );
  }

  public get isDanishCompany(): boolean {
    return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.DENMARK_COUNTRY_ID;
  }
}
