import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmployeeService } from 'src/app/services/employee.service';
import { UniqueId } from '../../../common/unique-id';
import { ImportButtonAction, ImportTypesEnum } from '../../../model/enum';
import { ImportMapping } from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { SessionService } from '../../../services/session/session.service';
import { ImportOptionHelper } from './import-option-helper';
var UploadImportFileDialogComponent = /** @class */ (function () {
    function UploadImportFileDialogComponent(dataService, sessionService, staticDataService, employeeService) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.employeeService = employeeService;
        this.visibleValue = false;
        this.importMappingChange = new EventEmitter();
        this.visibleChange = new EventEmitter();
        this.replaceAllWarningVisible = false;
        this.showEmptyMessage = false;
        this.mappingDetail = new ImportMapping();
        this.existingMappingsForCurrentImportType = [];
        this.confirmDeleteDialogVisible = false;
        this.confirmReplaceDialogVisible = false;
        this.successDialogVisible = false;
        this.successDialogFotalVisible = false;
        this.importTypes = [];
        this.overlayVisible = false;
        this.payrollImportRowOperationId = 5;
        this.timeEntriesImportRowOperationId = 6;
        this.addonLinkEmployees = 'http://support.gratisal.com/hc/da/articles/115003261669';
        this.addonLinkBalances = 'http://support.gratisal.com/hc/da/articles/115003280345 ';
        this.addonLinkPayroll = 'https://support.gratisal.com/hc/da/articles/115003178565-Import-af-l%C3%B8ndata-fra-Excel';
        this.ngUnsubscribe = new Subject();
        this.IsPreviewExpanded = false;
    }
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
                if (value) {
                    this.IsPreviewExpanded = false;
                    this.showDataImportDialog();
                    this.updateSpecificationOptions();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "addonReadMoreVisibility", {
        get: function () {
            return this.importType === ImportTypesEnum.Payroll && this.selectedMappingId < 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "intectStandardTemplateSelected", {
        get: function () {
            if (this.selectedMapping) {
                return this.selectedMapping.CompanyId === null;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "importPayrollDataOptionsVisible", {
        get: function () {
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "disableEditDefinitionButton", {
        get: function () {
            return this.intectStandardTemplateSelected || !this.formData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "importButtonEnable", {
        get: function () {
            return !!this.formData;
        },
        enumerable: true,
        configurable: true
    });
    UploadImportFileDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.ImportTypes.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.importTypes = data;
            _this.updateSpecificationOptions();
        });
        this.visibleChange.subscribe(function () { return (_this.uploadFileName = ''); });
    };
    UploadImportFileDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    UploadImportFileDialogComponent.prototype.onDialogAction = function (action) {
        switch (action) {
            case 'Edit':
                this.onEditClicked();
                break;
            case 'Delete':
                this.confirmDeleteDialogVisible = true;
                break;
            case 'Import':
                this.importMapping(ImportButtonAction.Import);
                break;
        }
    };
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "ImportTypeForMappingEditor", {
        get: function () {
            if (this.importType === ImportTypesEnum.Payroll) {
                if (this.SelectedMapping && this.SelectedMapping.TypeId === this.payrollImportRowOperationId) {
                    return ImportTypesEnum.PayrollRow;
                }
            }
            else if (this.importType === ImportTypesEnum.TimeEntriesRow) {
                if (this.SelectedMapping && this.SelectedMapping.TypeId === this.timeEntriesImportRowOperationId) {
                    return ImportTypesEnum.TimeEntriesRow;
                }
            }
            return this.importType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "SelectedMapping", {
        get: function () {
            var _this = this;
            if (this.selectedMappingId > 0 &&
                this.existingMappingsForCurrentImportType &&
                this.existingMappingsForCurrentImportType.length > 0) {
                return this.existingMappingsForCurrentImportType.find(function (mapp) {
                    return mapp.Id === _this.selectedMappingId;
                });
            }
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "addonLinks", {
        get: function () {
            switch (this.importType) {
                case ImportTypesEnum.Employees:
                    return this.addonLinkEmployees;
                case ImportTypesEnum.Initial_Balances:
                    return this.addonLinkBalances;
                case ImportTypesEnum.Payroll:
                    return this.addonLinkPayroll;
                default:
                    return this.addonLinkEmployees;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "OptionsRadioButtonName", {
        get: function () {
            if (!this.optionsRadioButtonName) {
                this.optionsRadioButtonName = 'optionsRadioButtonName_' + UniqueId();
            }
            return this.optionsRadioButtonName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadImportFileDialogComponent.prototype, "isLargeDialog", {
        get: function () {
            return this.previewDataHeader && this.previewDataHeader.length > 5 ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    UploadImportFileDialogComponent.prototype.onSaveMapingEvent = function () {
        this.loadExistingMappingComboboxDataSource();
    };
    UploadImportFileDialogComponent.prototype.onCompleteImportEvent = function () {
        if (!this.isNothingImport) {
            this.visible = false;
            return;
        }
        this.importMapping(ImportButtonAction.CompleteImport);
    };
    UploadImportFileDialogComponent.prototype.closeDialogAndFireClosedEvent = function () {
        if (this.importType === ImportTypesEnum.Employees) {
            this.employeeService.loadEmployees();
        }
        this.visible = false;
    };
    UploadImportFileDialogComponent.prototype.onComboboxSelectMappingChanged = function () {
        var _this = this;
        this.selectedMapping = this.existingMappingsForCurrentImportType.find(function (mapping) {
            return mapping.Id === _this.selectedMappingId;
        });
        this.showPreviewFile();
    };
    UploadImportFileDialogComponent.prototype.onConfirmDeleteDialogAction = function (action) {
        var _this = this;
        if (action === 'Delete') {
            this.dataService.ImportMapping_DeleteMapping(this.selectedMappingId).subscribe(function () {
                _this.importMappingChange.emit();
                _this.onDeleteMapping(_this.selectedMappingId);
            });
        }
    };
    UploadImportFileDialogComponent.prototype.onConfirmReplaceDialogAction = function (action) {
        if (action === 'Understood') {
            if (this.confirmReplaceParameter === 'mode') {
                this.previousMode = this.confirmReplaceValue;
            }
        }
        else {
            var mode = this.importOptions.find(function (option) { return option.ParameterValue === 'mode'; });
            var index = this.importOptions.indexOf(mode);
            mode.ControlValue = this.previousMode
                ? this.previousMode
                : mode.Options && mode.Options[0]
                    ? mode.Options[0].RadioValue
                    : undefined;
            this.importOptions[index] = mode;
        }
    };
    UploadImportFileDialogComponent.prototype.onImportOptionsChanged = function (parameter, value) {
        if (ImportOptionHelper.getOptionsString(this.importOptions) === 'mode=replaceall') {
            this.confirmReplaceParameter = parameter;
            this.confirmReplaceValue = value;
            this.confirmReplaceDialogVisible = true;
        }
        else {
            if (parameter === 'mode') {
                this.previousMode = value;
            }
        }
    };
    UploadImportFileDialogComponent.prototype.onFileSelected = function (file) {
        var _this = this;
        if (file) {
            this.formData = new FormData();
            this.formData.append('file', file, file.name);
            var reader_1 = new FileReader();
            reader_1.onload = function () {
                var base64 = reader_1.result;
                _this.formDataString = base64.split('base64,')[1];
                _this.showPreviewFile();
            };
            reader_1.readAsDataURL(file);
        }
    };
    UploadImportFileDialogComponent.prototype.onEditClicked = function () {
        var _this = this;
        this.dataService.ImportMapping_LoadMapping(this.selectedMappingId).subscribe(function (mapping) {
            _this.mappingName = mapping.Name;
            _this.startIndex = mapping.StartRow;
            _this.mappingDetail = mapping;
            _this.showImportMappingDialog = true;
        });
    };
    UploadImportFileDialogComponent.prototype.loadExistingMappingComboboxDataSource = function (defaultMappingId) {
        var _this = this;
        this.getExistingMappingDataSource().then(function (importMappings) {
            _this.setExistingMappingDataSource(importMappings, defaultMappingId);
            _this.showPreviewFile();
        });
    };
    UploadImportFileDialogComponent.prototype.getExistingMappingDataSource = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.getMappingsForImportType().then(function (result) {
                return resolve(result);
            });
        });
    };
    UploadImportFileDialogComponent.prototype.getMappingsForImportType = function (customTemplate, action) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.dataService.ImportMapping_CompanyMappings().subscribe(function (existingMappings) {
                _this.allCustomMappings = existingMappings;
                var result = [];
                if (existingMappings) {
                    result = existingMappings.filter(function (item) {
                        if ((_this.importType === 4 && item.TypeId === 5) || item.TypeId === _this.importType) {
                            return true;
                        }
                        return false;
                    });
                }
                if (customTemplate) {
                    _this.performImportCustomTemplate(action);
                }
                setTimeout(function () {
                    _this.overlayVisible = false;
                });
                return resolve(result);
            }, function () {
                setTimeout(function () {
                    _this.overlayVisible = false;
                });
            });
        });
    };
    UploadImportFileDialogComponent.prototype.getStandardTemplateMappingId = function () {
        var importMapping = this.allCustomMappings.find(function (item) { return !item.CompanyId; });
        return importMapping ? importMapping.Id : -1;
    };
    UploadImportFileDialogComponent.prototype.setExistingMappingDataSource = function (importMappings, defaultMappingId) {
        this.existingMappingsForCurrentImportType = [];
        this.selectedMappingId = undefined;
        if (importMappings.length === 0) {
            return;
        }
        this.existingMappingsForCurrentImportType = importMappings;
        this.selectedMappingId = defaultMappingId ? defaultMappingId : this.existingMappingsForCurrentImportType[0].Id;
    };
    UploadImportFileDialogComponent.prototype.showDataImportDialog = function () {
        this.mappingName = undefined;
        this.formData = undefined;
        this.previewDataResponse = this.previewDataHeader = undefined;
        this.showEmptyMessage = false;
        this.importOptions = undefined;
        this.loadExistingMappingComboboxDataSource();
    };
    UploadImportFileDialogComponent.prototype.importMapping = function (action) {
        this.overlayVisible = true;
        if (this.selectedMappingId === -1) {
            this.performImportCustomTemplate(action);
        }
        else {
            this.importCustomTemplate(action);
        }
    };
    UploadImportFileDialogComponent.prototype.importCustomTemplate = function (action) {
        var mappingId = this.selectedMappingId;
        if (mappingId === -2) {
            this.getMappingsForImportType(true, action);
        }
        else {
            this.performImportCustomTemplate(action);
        }
    };
    UploadImportFileDialogComponent.prototype.performImportCustomTemplate = function (action) {
        var _this = this;
        var optionString = ImportOptionHelper.getImportOptionsString(this.importOptions, action);
        var importRequest = {
            FileBase64: this.formDataString,
            MappingId: this.selectedMappingId,
            Options: optionString
        };
        this.dataService.Import_CustomImportExcelOptions(importRequest).subscribe(function (result) {
            _this.onImportDataCompleted(result, action);
            _this.isNothingImport = _this.validateImportResult(result);
            setTimeout(function () {
                _this.overlayVisible = false;
            });
        }, function () {
            setTimeout(function () {
                _this.overlayVisible = false;
            });
        });
    };
    UploadImportFileDialogComponent.prototype.onDeleteMapping = function (mappingId) {
        this.loadExistingMappingComboboxDataSource();
    };
    UploadImportFileDialogComponent.prototype.onImportDataCompleted = function (result, action) {
        if (action === ImportButtonAction.CompleteImport) {
            this.visible = false;
            if (result.FatalMessage) {
                this.resultfotalMessage = result.FatalMessage;
                this.successDialogFotalVisible = true;
                this.employeeService.loadEmployees();
                return;
            }
            this.successDialogVisible = true;
            this.employeeService.loadEmployees();
            return; //// don't show errors or warnings for complete import
        }
        if (!this.hasError) {
            this.successDialogVisible = true;
            this.employeeService.loadEmployees();
            return;
        }
        this.importResults = result;
    };
    UploadImportFileDialogComponent.prototype.hasError = function (result) {
        return result.Errors && result.Errors.length > 0;
    };
    UploadImportFileDialogComponent.prototype.showPreviewFile = function () {
        if (!this.formData) {
            this.resetPreivewOfFile();
            return;
        }
        switch (this.selectedMappingId) {
            case -1:
                if (this.importType !== ImportTypesEnum.Payroll && this.importType !== ImportTypesEnum.TimeEntriesRow) {
                    this.getPreviewOfFile(this.getStandardTemplateMappingId());
                }
                else {
                    this.resetPreivewOfFile();
                }
                break;
            case -2:
                this.getMappingsForImportType();
                break;
            default:
                this.getPreviewOfFile(this.selectedMappingId);
                break;
        }
    };
    UploadImportFileDialogComponent.prototype.getPreviewOfFile = function (mappingId) {
        var _this = this;
        if (!mappingId || !this.formDataString) {
            return;
        }
        var previewRequest = {
            Size: 4,
            FileBase64: this.formDataString,
            MappingId: mappingId
        };
        this.dataService.Import_GetPreviewOfFileImport(previewRequest).subscribe(function (response) {
            if (!response || response.length <= 0) {
                _this.showEmptyMessage = true;
                return;
            }
            _this.showEmptyMessage = false;
            _this.previewDataHeader = response.shift();
            _this.previewDataResponse = response;
            if (_this.previewDataHeader && _this.previewDataHeader.length > 4) {
                _this.IsPreviewExpanded = true;
            }
        });
    };
    UploadImportFileDialogComponent.prototype.resetPreivewOfFile = function () {
        this.IsPreviewExpanded = false;
        this.previewDataResponse = this.previewDataHeader = undefined;
    };
    UploadImportFileDialogComponent.prototype.updateSpecificationOptions = function () {
        var _this = this;
        var importTypeModel = this.importTypes.find(function (importT) {
            return importT.Id === _this.importType;
        });
        if (importTypeModel && importTypeModel.RelatedOptions && importTypeModel.RelatedOptions.length > 1) {
            this.importOptions = ImportOptionHelper.createImportOptionObjects(importTypeModel.RelatedOptions[1].Values);
        }
    };
    UploadImportFileDialogComponent.prototype.onRadioOptionChange = function (value) {
        var _this = this;
        this.importOptions.forEach(function (option) {
            if (option.Selected) {
                _this.oldOption = option;
            }
            var optionElement = document.getElementsByClassName(option.ControlValue)[0];
            if (option.ControlValue === value.ControlValue) {
                option.Selected = true;
                if (optionElement) {
                    optionElement.checked = true;
                }
            }
            else {
                option.Selected = false;
                if (optionElement) {
                    optionElement.checked = false;
                }
            }
            if (!value.WarningText && typeof value.WarningText === 'string' && !_this.replaceAllWarningVisible) {
                _this.replaceAllWarningVisible = true;
                _this.warningText = value.WarningText;
            }
        });
    };
    UploadImportFileDialogComponent.prototype.onDialogActionWarning = function (action) {
        switch (action) {
            case 'Cancel':
                if (this.oldOption) {
                    this.onRadioOptionChange(this.oldOption);
                    this.oldOption = null;
                }
                break;
            default:
                this.replaceAllWarningVisible = false;
                break;
        }
    };
    UploadImportFileDialogComponent.prototype.validateImportResult = function (results) {
        var isNothingImports = results.Errors.filter(function (result) {
            return result.TranslationEntity.Key === 'NothingImported';
        });
        return isNothingImports.length > 0 ? false : true;
    };
    UploadImportFileDialogComponent.prototype.onImportMappingChange = function () {
        this.importMappingChange.emit();
    };
    UploadImportFileDialogComponent.prototype.onClosedMappingDialog = function () {
        this.visible = false;
    };
    return UploadImportFileDialogComponent;
}());
export { UploadImportFileDialogComponent };
