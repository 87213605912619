import { OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Browser } from '../../common/browser';
import { EventEmitterService } from '../../services/event-emitter.service';
import { SessionService } from '../../services/session/session.service';
import { CompanyTemplatesService } from './templates/templates.service';
var CompanyConfigurationComponent = /** @class */ (function () {
    function CompanyConfigurationComponent(sessionService, renderer, eventEmitterService, companyTemplatesService) {
        this.sessionService = sessionService;
        this.renderer = renderer;
        this.eventEmitterService = eventEmitterService;
        this.companyTemplatesService = companyTemplatesService;
        this.showSaveDataConfirmationChangeState = false;
        this.listClassExcluded = ['Footer', '5'];
    }
    Object.defineProperty(CompanyConfigurationComponent.prototype, "companyOptionTabEnabled", {
        get: function () {
            return this.sessionService.role.IsFullAdmin || this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyConfigurationComponent.prototype, "companyDataImportTabEnabled", {
        get: function () {
            return !this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyConfigurationComponent.prototype, "companyOptionStateActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.configuration.settings';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyConfigurationComponent.prototype, "companyAdvancedSalaryTypesStateActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.configuration.advancedsalarytypes';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyConfigurationComponent.prototype, "companyAdvancedTypesStateActive", {
        get: function () {
            return this.sessionService.currentState.indexOf('tabs.company.configuration.advancedtypes') >= 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyConfigurationComponent.prototype, "companyTimeEntryTypesStateActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.configuration.timeentrytypes';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyConfigurationComponent.prototype, "companyintegrationsStateActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.configuration.integrations';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyConfigurationComponent.prototype, "companyDataImportStateActive", {
        get: function () {
            return (!this.sessionService.role.IsReadOnly &&
                this.sessionService.currentState === 'tabs.company.configuration.dataimport');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyConfigurationComponent.prototype, "companyTemplateTabVisisble", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.configuration.hiringstatus';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyConfigurationComponent.prototype, "companyDepartmentsTabVisible", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.configuration.departments';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyConfigurationComponent.prototype, "companyDimensionsStateActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.configuration.dimensions';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyConfigurationComponent.prototype, "isHybridApp", {
        get: function () {
            var browser = new Browser();
            return browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    CompanyConfigurationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.renderer.listen(window, 'click', function (event) {
            if (!_this.isCollapseButtonClick) {
                _this.menuOpen = false;
            }
            else {
                _this.isCollapseButtonClick = false;
            }
        });
        if (!environment.production) {
            console.log('state', this.sessionService.currentState);
        }
        if (this.sessionService.currentState === 'tabs.company.configuration') {
            if (this.companyOptionTabEnabled) {
                this.navigateTo('tabs.company.configuration.hiringstatus');
            }
            else {
                this.navigateTo('tabs.company.configuration.dataimport');
            }
        }
        if (this.eventEmitterService.subsVar === undefined) {
            this.eventEmitterService.subsVar = this.eventEmitterService.invokeConfigurationToggleNavigation.subscribe(function () {
                _this.onToggleNavigation();
            });
        }
        if (this.eventEmitterService.subsVar1 === undefined) {
            this.eventEmitterService.subsVar1 = this.eventEmitterService.invokeConfigurationGetMenuOpen.subscribe(function () {
                _this.eventEmitterService.isOpen = _this.getMenuOpen();
            });
        }
    };
    CompanyConfigurationComponent.prototype.ngOnDestroy = function () {
        this.menuOpen = false;
        this.eventEmitterService.subsVar = undefined;
        this.eventEmitterService.subsVar1 = undefined;
    };
    CompanyConfigurationComponent.prototype.navigateTo = function (state) {
        this.menuOpen = false;
        this.sessionService.NavigateTo(state);
    };
    CompanyConfigurationComponent.prototype.onToggleNavigation = function () {
        this.isCollapseButtonClick = true;
        this.menuOpen = !this.menuOpen;
    };
    CompanyConfigurationComponent.prototype.getMenuOpen = function () {
        return this.menuOpen;
    };
    return CompanyConfigurationComponent;
}());
export { CompanyConfigurationComponent };
