import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CellClickEvent, GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from '../../common/global';
import { OverviewDisplayModeEnum } from '../../model/enum';
import { IGetSalaryRecordsOverview_Result, ISalaryType, IUnitType } from '../../services/api/api-model';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { ReportDialogService } from '../../shared-components/report-dialog/report-dialog.service';
import { CompanyPayrollDataService } from './company-payroll-data.service';

interface IPayrollSalaryType extends ISalaryType {
  displayName?: string;
  unitTypeTitle?: string;
  IsVisibleValue?: boolean;
  IsActiveValue?: boolean;
}

enum PayrollFilterType {
  SalaryCycle = 1,
  Template = 2,
  Department = 3
}

@Component({
  selector: 'app-company-payroll-data',
  templateUrl: './company-payroll-data.component.html',
  styleUrls: ['./company-payroll-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CompanyPayrollDataComponent implements OnInit, OnDestroy {
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public selectedRows: number[] = [];
  public gridInit = false;
  public listClassExcluded = ['Footer', '10'];
  public overviewMaxIndex: number;

  public numericOptions: any = {
    format: 'n2',
    decimals: 2,
    step: 0,
    min: null,
    spinners: false
  };

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public get pageable(): boolean {
    return this.gridView && this.gridView.total <= this.pageSize ? false : true;
  }

  public get hasAddNewColumn(): boolean {
    return this.overviewMaxIndex <= 7;
  }

  public get showDepartment(): boolean {
    return this.gridData && this.gridData.some((d: IGetSalaryRecordsOverview_Result) => !!d.DepartmentName);
  }

  @ViewChild(GridComponent, { static: true }) private grid: GridComponent;
  @ViewChild('kendoGridComponent', { static: false }) private kendoGrid: GridComponent;

  private elementdown1: Element;
  private elementdown2: Element;
  private gridData: IGetSalaryRecordsOverview_Result[] = [];

  constructor(
    private sessionService: SessionService,
    private translateService: TranslateService,
    public staticDataService: StaticDataService,
    private changeDetectorRef: ChangeDetectorRef,
    public reportDialogService: ReportDialogService,
    public companyPayrollDataService: CompanyPayrollDataService
  ) {
    this.companyPayrollDataService.showReportShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && this.reportDialogService.isVisibleReportIcon) {
          this.reportDialogService.onShowReportsEventClick();
        }
      });

    this.companyPayrollDataService.componentStateChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        this.changeDetectorRef.markForCheck();
      });

    this.companyPayrollDataService.allSalaryTypes.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (salaryTypes: ISalaryType[]) => {
        if (salaryTypes && salaryTypes.length > 0) {
          this.overviewMaxIndex = 1;
          const onverviewIndexList: number[] = [];

          this.companyPayrollDataService.salaryTypes.forEach((salaryType: IPayrollSalaryType) => {
            let unitType: IUnitType;
            if (Global.UNIT_TYPES) {
              unitType = Global.UNIT_TYPES.find((ut: IUnitType) => ut.Id === salaryType.UnitTypeId);
            }

            salaryType.unitTypeTitle = unitType ? unitType.Name : '';
            this.companyPayrollDataService.assignSalaryTypeText(salaryType, salaryTypes);
            onverviewIndexList.push(salaryType.OverviewIndex);
          });

          let flag = 1;
          for (let i = 1; i <= 7; i++) {
            if (!onverviewIndexList.find((index: number) => index === i)) {
              flag = i;
              break;
            }
            flag += 1;
          }

          this.overviewMaxIndex = flag;
          this.companyPayrollDataService.getData();
        }
      },
      () => {
        this.closeOverlay();
      }
    );

    this.companyPayrollDataService.gridData.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IGetSalaryRecordsOverview_Result[]) => {
        this.gridData = data;
        this.pageChange({ skip: 0, take: this.pageSize });
        this.closeOverlay();

        setTimeout(() => {
          this.changeDetectorRef.markForCheck();
        });
      },
      () => {
        this.closeOverlay();
      }
    );
  }

  public ngOnInit(): void {
    this.companyPayrollDataService.isWattingLoading = true;
    this.translateFilterDataSource();

    if (this.isMobile) {
      this.sessionService.isShowHugeFeaturesAlert = true;
    }

    setTimeout(() => {
      this.gridInit = true;

      this.changeDetectorRef.markForCheck();
    });
  }

  private closeOverlay(): void {
    this.companyPayrollDataService
      .closeOverlay()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.changeDetectorRef.markForCheck();
      });
  }

  private translateFilterDataSource(): void {
    this.translateService
      .get(['CompanyPayrollData.Salary Cycle', 'CompanyPayrollData.Template', 'CompanyPayrollData.Department'])
      .subscribe((translations: { [key: string]: string }) => {
        const dataSource: any[] = [];
        dataSource.push({ Id: PayrollFilterType.SalaryCycle, Name: translations['CompanyPayrollData.Salary Cycle'] });
        dataSource.push({ Id: PayrollFilterType.Template, Name: translations['CompanyPayrollData.Template'] });
        dataSource.push({ Id: PayrollFilterType.Department, Name: translations['CompanyPayrollData.Department'] });
      });
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.companyPayrollDataService.ngOnDestroy();

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public trackIndex(index: number): any {
    return index;
  }

  public UnitsColumnVisible(salaryType: ISalaryType): boolean {
    return (
      salaryType &&
      (!salaryType.OverviewDisplayMode ||
        salaryType.OverviewDisplayMode === OverviewDisplayModeEnum.UnitsAndAmount ||
        salaryType.OverviewDisplayMode === OverviewDisplayModeEnum.UnitsOnly)
    );
  }

  public AmountColumnVisible(salaryType: ISalaryType): boolean {
    return (
      salaryType &&
      (!salaryType.OverviewDisplayMode ||
        salaryType.OverviewDisplayMode === OverviewDisplayModeEnum.UnitsAndAmount ||
        salaryType.OverviewDisplayMode === OverviewDisplayModeEnum.AmountOnly)
    );
  }

  public PerUnitColumnVisible(salaryType: ISalaryType): boolean {
    return (
      salaryType &&
      (!salaryType.OverviewDisplayMode || salaryType.OverviewDisplayMode === OverviewDisplayModeEnum.UnitsAndAmount)
    );
  }

  private selectedCell: CellClickEvent;
  public onKeyDown(event: any): void {
    if (
      (event.key === 'Tab' || event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Escape') &&
      this.selectedCell
    ) {
      const cell: any = this.findNextCell(this.selectedCell, event);
      if (
        cell &&
        (!this.gridView || !this.gridView.data || (cell.rowIndex >= 0 && cell.rowIndex < this.gridView.data.length))
      ) {
        const dataItem: any = this.gridView && this.gridView.data ? this.gridView.data[cell.rowIndex] : undefined;
        Object.assign(this.companyPayrollDataService.original, dataItem);
        this.selectedRows = dataItem ? [dataItem.UserEmploymentId] : [];
        if (event.key === 'Escape') {
          setTimeout(() => {
            this.kendoGrid.editCell(
              this.selectedCell.rowIndex,
              this.selectedCell.columnIndex,
              this.companyPayrollDataService.createFormGroup(dataItem)
            );
            this.kendoGrid.closeCell();
          });
        } else {
          this.kendoGrid.editCell(
            cell.rowIndex,
            cell.columnIndex,
            this.companyPayrollDataService.createFormGroup(dataItem)
          );
          this.kendoGrid.focusCell(cell.rowIndex + 2, cell.columnIndex);

          setTimeout(() => {
            const element = document.querySelector('.k-state-focused input') as HTMLElement;
            if (element) {
              element.focus();
            }
          });
        }
        this.companyPayrollDataService.formGroup = this.companyPayrollDataService.createFormGroup(dataItem);
      }

      event.preventDefault();
      event.stopPropagation();
    }
  }

  private listColumnCheck: any[] = [];
  private findNextCell(cell: any, event: any): any {
    if (!this.listColumnCheck || this.listColumnCheck.length === 0) {
      this.listColumnCheck = [];
      (this.kendoGrid.columnList as any).columns.forEach((column: any) => {
        if (!column.children) {
          this.listColumnCheck.push(column);
        } else {
          column.children.forEach((childColumn: any) => {
            if (column !== childColumn) {
              this.listColumnCheck.push(childColumn);
            }
          });
        }
      });
    }

    if (event.key === 'ArrowUp') {
      if (cell.rowIndex > 0) {
        cell.rowIndex = cell.rowIndex - 1;
      }
    } else if (event.key === 'ArrowDown') {
      if (cell.rowIndex < this.gridView.data.length - 1) {
        cell.rowIndex = cell.rowIndex + 1;
      }
    } else {
      if (!event.shiftKey) {
        if (cell.columnIndex >= 2 && cell.columnIndex < this.listColumnCheck.length - 2) {
          for (let i: number = cell.columnIndex + 1; i < this.listColumnCheck.length - 1; i++) {
            if (this.listColumnCheck[i] && this.listColumnCheck[i].editable) {
              cell.columnIndex = i;
              break;
            }
          }
        } else {
          cell.columnIndex = 2;
        }
      } else {
        if (cell.columnIndex > 2 && cell.columnIndex < this.listColumnCheck.length - 1) {
          for (let i: number = cell.columnIndex - 1; i >= 2; i--) {
            if (this.listColumnCheck[i] && this.listColumnCheck[i].editable) {
              cell.columnIndex = i;
              break;
            }
          }
        } else {
          cell.columnIndex = this.listColumnCheck.length - 2;
        }
      }
    }

    return cell;
  }

  public onCellClick(e: CellClickEvent): void {
    this.selectedCell = e;
    this.selectedRows = e.dataItem ? [e.dataItem.UserEmploymentId] : [];
    if (!this.sessionService.role.IsReadOnly) {
      Object.assign(this.companyPayrollDataService.original, e.dataItem);
      this.companyPayrollDataService.formGroup = this.companyPayrollDataService.createFormGroup(e.dataItem);
      e.sender.editCell(e.rowIndex, e.columnIndex, this.companyPayrollDataService.createFormGroup(e.dataItem));
    }
    setTimeout(() => {
      const element = document.querySelector('.k-state-focused input') as HTMLElement;
      if (element) {
        element.focus();
      }
    });
  }

  public onCellClose(e: CellCloseEvent): void {
    if (this.isColumnDataChanged(e)) {
      this.companyPayrollDataService.saveCurrent(e.dataItem, e.column ? e.column.field : undefined);
    }
    const obj: any = this.addRowTotal();
    this.rowTotal = this.CalculateTotal(obj);
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadView();
  }

  public selectedSalaryTypeForConfiguration: ISalaryType;
  public showSalaryTypeConfiguration: boolean;
  public configurationSalaryTypeDataSource: ISalaryType[];
  public onSalaryTypeConfigrationClicked(salaryType?: ISalaryType): void {
    const correctDatasource = JSON.parse(JSON.stringify(this.companyPayrollDataService.availableSalaryTypes));
    let dataSource: ISalaryType[] = correctDatasource && correctDatasource.length > 0 ? correctDatasource : [];

    if (!salaryType) {
      dataSource = dataSource.filter((s: IPayrollSalaryType) => !s.OverviewIndex);
    } else {
      const indexSalaryType: ISalaryType = dataSource.find((model: ISalaryType) => model.Id === salaryType.Id);
      if (indexSalaryType) {
        dataSource.splice(dataSource.indexOf(indexSalaryType), 1);
      }
      dataSource.unshift(salaryType);
    }

    this.configurationSalaryTypeDataSource = dataSource;
    this.selectedSalaryTypeForConfiguration = salaryType;
    this.showSalaryTypeConfiguration = true;
  }

  public rowTotal: any = {};
  private loadView(): void {
    // setTimeout(() => {
    //   try {
    //     this.elementdown1 = this.kendoGrid.wrapper.nativeElement
    //       .getElementsByClassName('k-grid-aria-root')[0]
    //       .getElementsByClassName('k-grid-container')[0]
    //       .getElementsByClassName('k-grid-content')[0];
    //     this.elementdown2 = this.kendoGrid.wrapper.nativeElement
    //       .getElementsByClassName('k-grid-aria-root')[0]
    //       .getElementsByClassName('k-grid-container')[0]
    //       .getElementsByClassName('k-grid-content-locked')[0];
    //   } catch (e) {
    //   }

    //   if (this.elementdown2) {
    //     this.elementdown2.addEventListener('scroll', (): void => {
    //       setTimeout(() => {
    //         this.elementdown1.scrollTop = this.elementdown2.scrollTop;
    //       }, 100);
    //     });
    //   }
    // }, 1000);

    this.gridFilterData = filterBy(this.gridData, this.filter);
    this.gridView = {
      data: this.gridFilterData.slice(this.skip, this.skip + this.pageSize),
      total: this.gridFilterData.length
    };
    const obj: any = this.addRowTotal();
    this.rowTotal = this.CalculateTotal(obj);
  }

  private CalculateTotal(result: any): any {
    this.gridFilterData.forEach((item: any) => {
      result.SalaryRecord1Amount = item.SalaryRecord1Amount
        ? item.SalaryRecord1Amount + result.SalaryRecord1Amount
        : result.SalaryRecord1Amount;
      result.SalaryRecord1Units = item.SalaryRecord1Units
        ? item.SalaryRecord1Units + result.SalaryRecord1Units
        : result.SalaryRecord1Units;
      result.SalaryRecord2Amount = item.SalaryRecord2Amount
        ? item.SalaryRecord2Amount + result.SalaryRecord2Amount
        : result.SalaryRecord2Amount;
      result.SalaryRecord2Units = item.SalaryRecord2Units
        ? item.SalaryRecord2Units + result.SalaryRecord2Units
        : result.SalaryRecord2Units;
      result.SalaryRecord3Amount = item.SalaryRecord3Amount
        ? item.SalaryRecord3Amount + result.SalaryRecord3Amount
        : result.SalaryRecord3Amount;
      result.SalaryRecord3Units = item.SalaryRecord3Units
        ? item.SalaryRecord3Units + result.SalaryRecord3Units
        : result.SalaryRecord3Units;
      result.SalaryRecord4Amount = item.SalaryRecord4Amount
        ? item.SalaryRecord4Amount + result.SalaryRecord4Amount
        : result.SalaryRecord4Amount;
      result.SalaryRecord4Units = item.SalaryRecord4Units
        ? item.SalaryRecord4Units + result.SalaryRecord4Units
        : result.SalaryRecord4Units;
      result.SalaryRecord5Amount = item.SalaryRecord5Amount
        ? item.SalaryRecord5Amount + result.SalaryRecord5Amount
        : result.SalaryRecord5Amount;
      result.SalaryRecord5Units = item.SalaryRecord5Units
        ? item.SalaryRecord5Units + result.SalaryRecord5Units
        : result.SalaryRecord5Units;
      result.SalaryRecord6Amount = item.SalaryRecord6Amount
        ? item.SalaryRecord6Amount + result.SalaryRecord6Amount
        : result.SalaryRecord6Amount;
      result.SalaryRecord6Units = item.SalaryRecord6Units
        ? item.SalaryRecord6Units + result.SalaryRecord6Units
        : result.SalaryRecord6Units;
      result.SalaryRecord7Amount = item.SalaryRecord7Amount
        ? item.SalaryRecord7Amount + result.SalaryRecord7Amount
        : result.SalaryRecord7Amount;
      result.SalaryRecord7Units = item.SalaryRecord7Units
        ? item.SalaryRecord7Units + result.SalaryRecord7Units
        : result.SalaryRecord7Units;
    });

    result.SalaryRecord1Amount = parseFloat((Math.round(result.SalaryRecord1Amount * 100) / 100).toFixed(2));
    result.SalaryRecord1Units = parseFloat((Math.round(result.SalaryRecord1Units * 100) / 100).toFixed(2));
    result.SalaryRecord2Amount = parseFloat((Math.round(result.SalaryRecord2Amount * 100) / 100).toFixed(2));
    result.SalaryRecord2Units = parseFloat((Math.round(result.SalaryRecord2Units * 100) / 100).toFixed(2));
    result.SalaryRecord3Amount = parseFloat((Math.round(result.SalaryRecord3Amount * 100) / 100).toFixed(2));
    result.SalaryRecord3Units = parseFloat((Math.round(result.SalaryRecord3Units * 100) / 100).toFixed(2));
    result.SalaryRecord4Amount = parseFloat((Math.round(result.SalaryRecord4Amount * 100) / 100).toFixed(2));
    result.SalaryRecord4Units = parseFloat((Math.round(result.SalaryRecord4Units * 100) / 100).toFixed(2));
    result.SalaryRecord5Amount = parseFloat((Math.round(result.SalaryRecord5Amount * 100) / 100).toFixed(2));
    result.SalaryRecord5Units = parseFloat((Math.round(result.SalaryRecord5Units * 100) / 100).toFixed(2));
    result.SalaryRecord6Amount = parseFloat((Math.round(result.SalaryRecord6Amount * 100) / 100).toFixed(2));
    result.SalaryRecord6Units = parseFloat((Math.round(result.SalaryRecord6Units * 100) / 100).toFixed(2));
    result.SalaryRecord7Amount = parseFloat((Math.round(result.SalaryRecord7Amount * 100) / 100).toFixed(2));
    result.SalaryRecord7Units = parseFloat((Math.round(result.SalaryRecord7Units * 100) / 100).toFixed(2));

    return result;
  }

  private addRowTotal(): any {
    const obj: any = {
      DepartmentName: 'Total',
      SalaryRecord1Amount: 0,
      SalaryRecord1Id: undefined,
      SalaryRecord1PerUnit: 0,
      SalaryRecord1Units: 0,
      SalaryRecord2Amount: 0,
      SalaryRecord2Id: undefined,
      SalaryRecord2PerUnit: 0,
      SalaryRecord2Units: 0,
      SalaryRecord3Amount: 0,
      SalaryRecord3Id: undefined,
      SalaryRecord3PerUnit: 0,
      SalaryRecord3Units: 0,
      SalaryRecord4Amount: 0,
      SalaryRecord4Id: undefined,
      SalaryRecord4PerUnit: 0,
      SalaryRecord4Units: 0,
      SalaryRecord5Amount: 0,
      SalaryRecord5Id: undefined,
      SalaryRecord5PerUnit: 0,
      SalaryRecord5Units: 0,
      SalaryRecord6Amount: 0,
      SalaryRecord6Id: undefined,
      SalaryRecord6PerUnit: 0,
      SalaryRecord6Units: 0,
      SalaryRecord7Amount: 0,
      SalaryRecord7Id: undefined,
      SalaryRecord7PerUnit: 0,
      SalaryRecord7Units: 0
    };
    return obj;
  }

  private isColumnDataChanged(args: any): boolean {
    let dataChanged: boolean;
    const { column, dataItem, formGroup } = args;
    if (dataItem && formGroup && formGroup.value && column && column.field) {
      dataChanged =
        ((dataItem[column.field] === null || dataItem[column.field] === undefined) &&
          formGroup.value[column.field] !== null &&
          formGroup.value[column.field] !== undefined) ||
        (dataItem[column.field] !== null &&
          dataItem[column.field] !== undefined &&
          formGroup.value.hasOwnProperty(column.field) &&
          (formGroup.value[column.field] === null || formGroup.value[column.field] === undefined)) ||
        (dataItem[column.field] !== null &&
          dataItem[column.field] !== undefined &&
          formGroup.value[column.field] !== null &&
          formGroup.value[column.field] !== undefined &&
          dataItem[column.field] !== formGroup.value[column.field]);
    }

    return dataChanged;
  }

  public filter: any;
  public gridFilterData: any[] = this.gridData;
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridFilterData = filterBy(this.gridData, this.filter);
    this.gridView = {
      data: this.gridFilterData.slice(this.skip, this.skip + this.pageSize),
      total: this.gridFilterData.length
    };
    const obj: any = this.addRowTotal();
    this.rowTotal = this.CalculateTotal(obj);
  }
}
