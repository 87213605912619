import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OverviewDisplayModeEnum } from '../../model/enum';
import { StaticDataService } from '../../services/api/static-data.service';
import { CompanyPayrollDataService } from './company-payroll-data.service';
var SalaryTypeConfigurationDialogComponent = /** @class */ (function () {
    function SalaryTypeConfigurationDialogComponent(companyPayrollDataService, staticDataService) {
        this.companyPayrollDataService = companyPayrollDataService;
        this.staticDataService = staticDataService;
        this.visibleChange = new EventEmitter();
        this.replaceSalaryType = {};
        this.overviewDisplayModes = [];
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(SalaryTypeConfigurationDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
                this.onSalaryTypeChange(this.selectedSalaryType ? this.selectedSalaryType.Id : undefined);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryTypeConfigurationDialogComponent.prototype, "selectedSalaryType", {
        get: function () {
            return this.selectedSalaryTypeValue;
        },
        set: function (value) {
            this.selectedSalaryTypeValue = value;
            this.onSalaryTypeChange(value ? value.Id : undefined);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryTypeConfigurationDialogComponent.prototype, "salaryTypes", {
        get: function () {
            return this.salaryTypesDataSource;
        },
        set: function (value) {
            this.salaryTypesDataSource = value;
            this.onSalaryTypeChange(this.selectedSalaryType ? this.selectedSalaryType.Id : undefined);
        },
        enumerable: true,
        configurable: true
    });
    SalaryTypeConfigurationDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.SalaryRecordsOverviewDisplayMode.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.overviewDisplayModes = data); });
    };
    SalaryTypeConfigurationDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    SalaryTypeConfigurationDialogComponent.prototype.onSalaryTypeChange = function (salaryTypeId) {
        this.replaceSalaryTypeId = salaryTypeId;
        this.replaceSalaryType = JSON.parse(JSON.stringify(this.salaryTypes && this.salaryTypes.length > 0 && salaryTypeId
            ? this.salaryTypes.find(function (s) { return s.Id === salaryTypeId; })
                ? this.salaryTypes.find(function (s) { return s.Id === salaryTypeId; })
                : {}
            : {}));
        if (this.replaceSalaryType) {
            this.replaceSalaryType.OverviewDisplayMode = this.replaceSalaryType.OverviewDisplayMode
                ? this.replaceSalaryType.OverviewDisplayMode
                : OverviewDisplayModeEnum.AmountOnly;
        }
    };
    SalaryTypeConfigurationDialogComponent.prototype.onDialogAction = function (action) {
        var _this = this;
        if (action === 'Ok' && this.salaryTypes && this.replaceSalaryTypeId && this.replaceSalaryType) {
            this.replaceSalaryType.OverviewIndex = this.selectedSalaryType
                ? this.selectedSalaryType.OverviewIndex
                : this.maxIndex;
            var request = {
                salaryTypeId: this.replaceSalaryTypeId,
                overviewIndex: this.selectedSalaryType
                    ? this.selectedSalaryType.OverviewIndex
                    : this.replaceSalaryType.OverviewIndex,
                overviewDisplayMode: this.replaceSalaryType.OverviewDisplayMode
            };
            this.companyPayrollDataService.SalaryTypes_ConfigureOverviewBehavior(request).subscribe(function (data) {
                _this.visible = false;
            });
        }
        else {
            this.visible = false;
        }
    };
    return SalaryTypeConfigurationDialogComponent;
}());
export { SalaryTypeConfigurationDialogComponent };
