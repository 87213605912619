import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IUser, IUserCompanyAccess, User, UserAccessPermissionsRequest } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { ModalService } from '../../services/modal.service';
import { SessionService } from '../../services/session/session.service';
import { AccountantServiceBase } from '../accountant-service-base';

@Injectable({
  providedIn: 'root'
})
export class AccountantUsersService extends AccountantServiceBase {
  public get users(): Observable<IUser[]> {
    if (!this.usersSubject || !this.allUsers || (this.allUsers && this.allUsers.length === 0)) {
      this.loadData();
    }
    return this.usersSubject.asObservable();
  }

  private usersSubject: BehaviorSubject<IUser[]> = new BehaviorSubject<IUser[]>([]);
  private usersUpdateSubject: BehaviorSubject<IUser[]> = new BehaviorSubject<IUser[]>([]);
  private allUsers: IUser[] = [];

  public get updateUsers(): Observable<IUser[]> {
    return this.usersUpdateSubject.asObservable();
  }

  public EditUserList: User[] = [];
  public EditRoleList: UserAccessPermissionsRequest[] = [];
  public isDirty = false;
  public showSaveDataConfirmationChangeState = false;
  public navigationToStateName: string;

  constructor(
    protected api: DataService,
    protected sessionService: SessionService,
    protected modalService: ModalService,
    protected staticDataService: StaticDataService
  ) {
    super(api, modalService, sessionService, staticDataService);
  }

  public clearUserSubject(): void {
    this.selectedId = null;

    this.allUsers = [];
    this.usersSubject.next([]);
    this.rolesSubject.next([]);
  }

  public loadData(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.api.Account_GetAccountUsers().subscribe((data: IUser[]) => {
      this.allUsers = data;
      this.applyFilter();

      this.isLoading = false;
    });
  }

  protected getRoles(): void {
    this.getRoleServicePublic();
  }

  public getRoleServicePublic(): void {
    if (this.selectedId) {
      this.api.Account_GetCompanyAccessByUser(this.selectedId).subscribe((data: IUserCompanyAccess[]) => {
        if (data.length > 0) {
          if (data[0].UserId === this.selectedId) {
            this.rolesSubject.next(data);
          } else {
            if (!environment.production) {
              console.error(`UserId ${this.selectedId} doesnt match recieved data`);
            }
          }
        } else {
          if (!environment.production) {
            console.log('No roles');
          }
        }
      });
    }
  }

  protected applyFilter(): void {
    if (this.filter) {
      this.currentPage = 0;
      const searchText: string = this.filter.toLocaleLowerCase();
      const data: IUser[] = this.allUsers.filter(
        (c: IUser) => c.FullName.toLocaleLowerCase().includes(searchText) || c.Id.toString().includes(searchText)
      );

      this.usersSubject.next(data);
    } else {
      this.usersSubject.next(this.allUsers);
    }

    this.selectedId = undefined;
  }

  protected updateData(model: User[]): void {
    if (model && model.length > 0) {
      this.usersUpdateSubject.next(model);
    }
  }

  protected reloadData(): void {
    this.loadData();
  }
}
