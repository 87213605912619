import * as i0 from "@angular/core";
function _window() {
    // Return the global native browser window object
    return window;
}
var WindowReference = /** @class */ (function () {
    function WindowReference() {
    }
    Object.defineProperty(WindowReference.prototype, "nativeWindow", {
        get: function () {
            return _window();
        },
        enumerable: true,
        configurable: true
    });
    WindowReference.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WindowReference_Factory() { return new WindowReference(); }, token: WindowReference, providedIn: "root" });
    return WindowReference;
}());
export { WindowReference };
