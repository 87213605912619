<app-dialog
  class="{{ hiddenScroll }}"
  (action)="onDialogAction($event)"
  [title]="'Report.ChooseReport' | translate"
  [(visible)]="visible"
  [minWidth]="diaglogWidth"
  [width]="diaglogWidth"
>
  <div class="reportContainer">
    <div *ngIf="gridVisible || !isMobile" class="listReports">
      <app-grid
        #ReportDialog
        class="FormElement-grid u-gridHeight75vh GridSelect ReportDialog-grid"
        [idColumn]="'Id'"
        [data]="reports"
        [(selectedItem)]="selectedReport"
        [editMode]="false"
        [isReportDialog]="isMobile"
        (dblclick)="onDoubleClick($event)"
        [disabled]="sessionService.isGetting || sessionService.isSubmitting"
      >
        <app-grid-column
          [type]="'text'"
          [field]="'Name'"
          [title]="'Report.Name' | translate"
          [tooltipField]="'Description'"
        ></app-grid-column>
      </app-grid>
    </div>

    <div
      *ngIf="selectedReport"
      [ngClass]="{ parameters: !isMobile, 'fixed-height-param': reports && reports.length > 1 }"
    >
      <div
        *ngIf="sessionService.isGetting"
        class="BusyIndicator BusyIndicator-dialog-area z-index101 ReportSpinner smallOverlay"
      >
        <div>
          <div class="lds-ellipsis">
            <div class="animate-child-1"></div>
            <div class="animate-child-2"></div>
            <div class="animate-child-3"></div>
            <div class="animate-child-4"></div>
          </div>
        </div>
      </div>

      <div *ngIf="formatOptions.length > 0">
        <div class="format-title">{{ 'Report.ChooseFormat' | translate }}:</div>
        <app-radio-edit [editMode]="true" [(value)]="selectedFormat">
          <app-radio-edit-option
            *ngFor="let option of formatOptions"
            [label]="option.toLocaleUpperCase()"
            [value]="option"
          ></app-radio-edit-option>
        </app-radio-edit>
      </div>

      <div *ngFor="let control of reportControls; let idx = index" class="u-leader">
        <app-check-edit
          *ngIf="control.type === 'CheckBox' || control.type === 'Checkbox'"
          [label]="control.name"
          [(value)]="control.value"
          [editMode]="true"
          class="report-checkbox"
          [hidden]="control.isHidden"
        >
        </app-check-edit>

        <app-date-picker
          [e2e]="'ReportParameter_' + control.key"
          *ngIf="control.type === 'DatePicker'"
          [label]="control.name"
          [required]="true"
          [requiredMessage]="'EmployeeGeneral.Required' | translate"
          [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
          [(value)]="control.value"
          [editMode]="true"
          [hidden]="control.isHidden"
        ></app-date-picker>

        <app-date-picker
          [e2e]="'ReportParameter_' + control.key"
          *ngIf="control.type === 'DateTimePicker'"
          [type]="'datetime'"
          [label]="control.name"
          [required]="true"
          [requiredMessage]="'EmployeeGeneral.Required' | translate"
          [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
          [(value)]="control.value"
          [editMode]="true"
          [hidden]="control.isHidden"
        ></app-date-picker>

        <!-- Uncomment if we want to have seperated Date and Time - Picker
        <app-time-pickerv
          [label]="'Employee.Time' | translate"
          attr.sortkey="{{ idx }}"
          *ngIf="control.type === 'DatePicker'"
          [editMode]="true"
          [hidden]="control.isHidden"
          [(value)]="timeValues[idx]"
        >
        </app-time-pickerv> -->

        <app-combobox-edit
          *ngIf="control.type === 'ComboBox'"
          [label]="control.name"
          [editMode]="true"
          [textField]="control.displayText"
          [idField]="control.underlyingId"
          [placeholder]="control.placeholderText | translate"
          [comboboxDataSource]="control.dataSource"
          [(value)]="control.value"
          [nullValueLabel]="control.nullValueLabel | translate"
          (valueChange)="onComboboxChanged($event, control)"
          [hidden]="control.isHidden"
        ></app-combobox-edit>

        <app-multi-select
          *ngIf="control.type === 'MultiSelectList'"
          [editMode]="true"
          [gridDataSource]="control.dataSource"
          [columns]="control.columns"
          [displayExp]="control.displayExp"
          [label]="control.name"
          [placeHolder]="control.placeholderText | translate"
          [valueExp]="control.underlyingId"
          [(SelectValueIds)]="control.value"
          [required]="control.isRequired"
          [hidden]="control.isHidden"
        >
        </app-multi-select>

        <app-text-edit
          *ngIf="control.type === 'TextBox'"
          [label]="control.name"
          [(value)]="control.value"
          [editMode]="true"
          [hidden]="control.isHidden"
        ></app-text-edit>

        <app-numeric-edit
          *ngIf="control.type === 'Integer'"
          [label]="control.name"
          [editMode]="true"
          [(value)]="control.value"
          [option]="numericTextBoxOption"
          [hidden]="control.isHidden"
        ></app-numeric-edit>
      </div>

      <button *ngIf="isMobile" (click)="onBackClick()" class="k-button k-grid-add-command ng-star-inserted back-button">
        {{ 'CompanySalaryBatches.Back' | translate }}
      </button>
    </div>
  </div>

  <app-dialog-action [close]="false" [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action
    [close]="false"
    [type]="'Download'"
    [disabled]="sessionService.isSubmitting || !selectedReport || sessionService.isGetting"
  ></app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" [(visible)]="validationDialogVisible">
  <p>{{ 'Report.RequiredFieldsMessage' | translate }} {{ missingRequiredFields }}.</p>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  *ngIf="!isCordovaApp"
  [(visible)]="newTabBlockedDialogVisible"
  [leadingText]="'Report.NewTabBlocked' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" [(visible)]="safariWarningDialogVisible">
  <p>{{ safariWarning | translate }}</p>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="htmlReportDialogVisible"
  class="PayslipPopup"
  [iframeContent]="htmlReportDialogContent"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" [(visible)]="noReportVisible" [leadingText]="'Report.NoReport' | translate">
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>
