import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from 'src/app/common/global';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { environment } from 'src/environments/environment';
import { CompanyDepartmentService } from '../../departments/company-department.service';
var TimeEntryTypeConfigurationsDialogComponent = /** @class */ (function () {
    function TimeEntryTypeConfigurationsDialogComponent(dataService, translateService, sessionService, companyDepartmentService, staticDataService) {
        this.dataService = dataService;
        this.translateService = translateService;
        this.sessionService = sessionService;
        this.companyDepartmentService = companyDepartmentService;
        this.staticDataService = staticDataService;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.IsHasChange = new EventEmitter();
        this.timeEntryTypeConfigurations = [];
        this.unitTypes = Global.UNIT_TYPES;
        this.onversionFactorAffectsDataSource = [];
        this.gridRadioAction = 1;
        this.isShowDeleteRecord = false;
        this.dimensionValues = [];
        this.isShowDimensionValue = false;
        this.isHasChange = false;
        this.ConversionFactorAffectsRateTrueLabel = '';
        this.ConversionFactorAffectsRateFalseLabel = '';
        this.ngUnsubscribe = new Subject();
        this.NumericOptions = { format: 'n2', decimals: 4, step: 1, min: 0, spinners: false };
        this.ConversionFactorNumericOptions = {
            format: 'n4',
            decimals: 4,
            step: 1,
            min: 0,
            spinners: false
        };
        this.flagCreate = 0;
        this.refreshData = false;
        this.keyPress = { 17: false, 18: false, 107: false, 109: false };
        this.gridData = this.timeEntryTypeConfigurations;
        this.translationServiceTerms = [
            'TimeEntryTypeConfigurations.ConversionFactorAffectsRateTrue',
            'TimeEntryTypeConfigurations.ConversionFactorAffectsRateFalse'
        ];
    }
    Object.defineProperty(TimeEntryTypeConfigurationsDialogComponent.prototype, "timeEntryType", {
        get: function () {
            return this.timeEntryTypeValue;
        },
        set: function (value) {
            this.timeEntryTypeValue = value;
            if (value && value.Id) {
                this.loadTimeEntryTypeConfigurations();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeEntryTypeConfigurationsDialogComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeEntryTypeConfigurationsDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            this.selectedItem = undefined;
            this.selectedId = undefined;
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
            }
            if (!value && this.isHasChange) {
                this.IsHasChange.emit(true);
                this.isHasChange = false;
            }
            if (value) {
                this.gridRadioAction = 1;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeEntryTypeConfigurationsDialogComponent.prototype, "Dimension1Name", {
        get: function () {
            var dimensionObject = this.sessionService.getCompanyPreference('Dimensions.Dimension1Name');
            return dimensionObject ? dimensionObject.Value : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeEntryTypeConfigurationsDialogComponent.prototype, "Dimension2Name", {
        get: function () {
            var dimensionObject = this.sessionService.getCompanyPreference('Dimensions.Dimension2Name');
            return dimensionObject ? dimensionObject.Value : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeEntryTypeConfigurationsDialogComponent.prototype, "Dimension3Name", {
        get: function () {
            var dimensionObject = this.sessionService.getCompanyPreference('Dimensions.Dimension3Name');
            return dimensionObject ? dimensionObject.Value : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeEntryTypeConfigurationsDialogComponent.prototype, "isEnableDimensionPreference", {
        get: function () {
            var pref = this.sessionService.getCompanyPreference('Dimension.UseDimensionValueEntities');
            return pref.Value === 'true' ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    TimeEntryTypeConfigurationsDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    TimeEntryTypeConfigurationsDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.companyDepartmentService.loadDepartment();
        this.dataService
            .EmploymentTemplates_GetEmploymentTemplates()
            .subscribe(function (templates) { return (_this.employmentTemplates = templates); });
        this.staticDataService.userEmployments.subscribe(function (userEmploymentViews) {
            _this.employmentViews = userEmploymentViews;
            _this.employmentViews.forEach(function (model) {
                model.FullName += ' (' + model.Title + ')';
            });
        });
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            _this.translateService.get(_this.translationServiceTerms).subscribe(function (translations) {
                _this.setTranslationTerms(translations);
            });
        });
        this.dataService.DimensionValues_GetDimensionValues(1).subscribe(function (data) {
            _this.dimensionValues[0] = data;
        });
        this.dataService.DimensionValues_GetDimensionValues(2).subscribe(function (data) {
            _this.dimensionValues[1] = data;
        });
        this.dataService.DimensionValues_GetDimensionValues(3).subscribe(function (data) {
            _this.dimensionValues[2] = data;
        });
        this.isShowDimensionValue =
            this.sessionService.getCompanyPreference('Dimension.UseDimensionValueEntities').Value === 'true' ? true : false;
    };
    TimeEntryTypeConfigurationsDialogComponent.prototype.setTranslationTerms = function (translations) {
        this.ConversionFactorAffectsRateTrueLabel =
            translations['TimeEntryTypeConfigurations.ConversionFactorAffectsRateTrue'];
        this.ConversionFactorAffectsRateFalseLabel =
            translations['TimeEntryTypeConfigurations.ConversionFactorAffectsRateFalse'];
        if (this.onversionFactorAffectsDataSource.length < 2) {
            this.onversionFactorAffectsDataSource.push({ Name: this.ConversionFactorAffectsRateTrueLabel, value: 1 });
            this.onversionFactorAffectsDataSource.push({ Name: this.ConversionFactorAffectsRateFalseLabel, value: 0 });
        }
    };
    Object.defineProperty(TimeEntryTypeConfigurationsDialogComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeEntryTypeConfigurationsDialogComponent.prototype, "hasSelectedItem", {
        get: function () {
            if (this.selectedItem) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeEntryTypeConfigurationsDialogComponent.prototype, "isShowSimpleMode", {
        get: function () {
            return this.gridRadioAction === 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeEntryTypeConfigurationsDialogComponent.prototype, "isShowAdvancedMode", {
        get: function () {
            return this.gridRadioAction === 2;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeEntryTypeConfigurationsDialogComponent.prototype, "isShowCompleteMode", {
        get: function () {
            return this.gridRadioAction === 3;
        },
        enumerable: true,
        configurable: true
    });
    TimeEntryTypeConfigurationsDialogComponent.prototype.loadTimeEntryTypeConfigurations = function () {
        var _this = this;
        this.gridData = [];
        this.selectedItem = undefined;
        if (this.timeEntryType && this.timeEntryType.Id) {
            this.dataService
                .TimeEntryTypes_GetTimeEntryTypeConfigurationsByTimeEntry(this.timeEntryType.Id)
                .subscribe(function (response) {
                if (response && response.length > 0) {
                    _this.timeEntryTypeConfigurations = response;
                    _this.timeEntryTypeConfigurations.forEach(function (modal) {
                        modal.IsEmploymentTemplateEditable =
                            modal.EmploymentTemplateId || (modal.UserEmploymentId === null && modal.EmploymentTemplateId === null);
                        modal.IsUserEmploymentEditable =
                            modal.UserEmploymentId || (modal.UserEmploymentId === null && modal.EmploymentTemplateId === null);
                        modal.isAllowEditPerUnits = !modal.UseDefaultRate;
                        modal.classfeilderUnits = modal.isAllowEditPerUnits ? '' : 'grid-disable-cell';
                        modal.ConversionFactorAffectsRateNumber = modal.ConversionFactorAffectsRate === true ? 1 : 0;
                    });
                    _this.gridData = filterBy(_this.timeEntryTypeConfigurations, _this.filter);
                }
            });
        }
    };
    TimeEntryTypeConfigurationsDialogComponent.prototype.addHandler = function (dataItem) {
        var defaultDataItem = this.createNewDataItem();
        Object.assign(dataItem, defaultDataItem);
        this.saveChanges(dataItem);
    };
    TimeEntryTypeConfigurationsDialogComponent.prototype.removeHandler = function (dataItem) {
        var _this = this;
        if (dataItem && dataItem.Id) {
            this.dataService.TimeEntryTypes_DeleteTimeEntryTypeConfiguration(dataItem.Id).subscribe(function () {
                _this.isHasChange = true;
                _this.loadTimeEntryTypeConfigurations();
            });
        }
        else {
            var dataIndex = this.timeEntryTypeConfigurations.indexOf(dataItem);
            this.timeEntryTypeConfigurations.splice(dataIndex, 1);
            this.gridData = filterBy(this.timeEntryTypeConfigurations, this.filter);
        }
    };
    TimeEntryTypeConfigurationsDialogComponent.prototype.saveChanges = function (args) {
        var _this = this;
        var dataItem = args.dataItem ? args.dataItem : args;
        var salaryTypeSelected = this.salaryTypes.find(function (item) { return item.SalaryTypeId === dataItem.SalaryTypeId; });
        if (!salaryTypeSelected) {
            this.loadTimeEntryTypeConfigurations();
            return;
        }
        if (dataItem) {
            dataItem.IsUserEmploymentEditable =
                dataItem.UserEmploymentId !== null ||
                    (dataItem.UserEmploymentId === null && dataItem.EmploymentTemplateId === null);
            dataItem.IsEmploymentTemplateEditable =
                dataItem.EmploymentTemplateId !== null ||
                    (dataItem.UserEmploymentId === null && dataItem.EmploymentTemplateId === null);
            dataItem.isAllowEditPerUnits = !dataItem.UseDefaultRate;
            dataItem.classfeilderUnits = dataItem.isAllowEditPerUnits ? '' : 'grid-disable-cell';
            dataItem.SalaryTypeName = salaryTypeSelected ? salaryTypeSelected.Name : '';
            dataItem.ConversionFactorAffectsRate = dataItem.ConversionFactorAffectsRateNumber === 1 ? true : false;
            dataItem.UseDefaultRate = dataItem.UseDefaultRate || false;
            dataItem.LegacyFlexEarningPercentage = dataItem.LegacyFlexEarningPercentage
                ? dataItem.LegacyFlexEarningPercentage
                : dataItem.LegacyFlexEarningPercentage === 0
                    ? 0
                    : null;
            dataItem.NewFlexEarningPercentage = dataItem.NewFlexEarningPercentage
                ? dataItem.NewFlexEarningPercentage
                : dataItem.NewFlexEarningPercentage === 0
                    ? 0
                    : null;
            dataItem.PensionPercentage = dataItem.PensionPercentage
                ? dataItem.PensionPercentage
                : dataItem.PensionPercentage === 0
                    ? 0
                    : null;
        }
        if (dataItem.Id) {
            this.dataService.TimeEntryTypes_UpdateTimeEntryTypeConfiguration(dataItem).subscribe(function () {
                // Fix GS-5317 prevent grid refresh.
                //this.loadTimeEntryTypeConfigurations();
                _this.selectedItem = dataItem;
            }, function () {
                _this.loadTimeEntryTypeConfigurations();
            });
        }
        else {
            this.dataService.TimeEntryTypes_CreateTimeEntryTypeConfiguration(dataItem).subscribe(function (response) {
                dataItem.Id = response.Id;
                // Object.assign(dataItem, response);
                _this.selectedItem = response;
            }, function () {
                _this.loadTimeEntryTypeConfigurations();
            });
        }
        this.isHasChange = true;
    };
    TimeEntryTypeConfigurationsDialogComponent.prototype.createNewDataItem = function () {
        this.flagCreate++;
        return {
            Id: null,
            TimeEntryTypeId: this.timeEntryType && this.timeEntryType.Id ? this.timeEntryType.Id : null,
            SalaryTypeId: this.salaryTypes && this.salaryTypes.length > 0 ? this.salaryTypes[0].SalaryTypeId : null,
            Description: null,
            Amount: null,
            AmountPerUnit: null,
            AppliesBefore: null,
            AppliesBeforeDate: null,
            AppliesAfter: null,
            AppliesAfterDate: null,
            AppliesMondays: true,
            AppliesTuesdays: true,
            AppliesWednesdays: true,
            AppliesThursdays: true,
            AppliesFridays: true,
            AppliesWeekends: true,
            AppliesHolidays: true,
            AppliesSaturdays: true,
            AppliesSundays: true,
            AmountPerKilometer: null,
            EmploymentTemplateId: null,
            TargetUnitTypeId: null,
            UserEmploymentId: null,
            ConversionFactor: null,
            SalaryTypeName: this.salaryTypes[0].Name,
            flagCreate: this.flagCreate,
            IsEmploymentTemplateEditable: true,
            IsUserEmploymentEditable: true,
            isAllowEditPerUnits: true,
            classfeilderUnits: '',
            ConversionFactorAffectsRate: false,
            ConversionFactorAffectsRateNumber: 0
        };
    };
    TimeEntryTypeConfigurationsDialogComponent.prototype.onKeyDown = function (event) {
        if (event.code === 'Escape') {
            this.visible = false;
            this.visibleChange.emit(false);
        }
        if (!this.IsReadOnly) {
            if (event.keyCode in this.keyPress) {
                this.keyPress[event.keyCode] = true;
                if (this.keyPress[17] && this.keyPress[18] && this.keyPress[109]) {
                    if (this.selectedItem) {
                        this.removeHandler(this.selectedItem);
                        this.setKeypress();
                        this.refreshData = true;
                        this.selectedItem = undefined;
                    }
                }
            }
            else {
                this.setKeypress();
            }
        }
    };
    TimeEntryTypeConfigurationsDialogComponent.prototype.setKeypress = function () {
        this.keyPress[17] = false;
        this.keyPress[18] = false;
        this.keyPress[107] = false;
        this.keyPress[109] = false;
    };
    TimeEntryTypeConfigurationsDialogComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        this.gridData = filterBy(this.timeEntryTypeConfigurations, filter);
    };
    TimeEntryTypeConfigurationsDialogComponent.prototype.gridAction = function (event) {
        switch (event) {
            case 'DuplicateAction':
                var newItem = JSON.parse(JSON.stringify(this.selectedItem));
                newItem.Id = undefined;
                this.gridData.push(newItem);
                this.saveChanges(newItem);
                break;
            case 'DeleteAction':
                this.isShowDeleteRecord = true;
                break;
            default:
                break;
        }
    };
    TimeEntryTypeConfigurationsDialogComponent.prototype.onDeleteRecordAction = function (event) {
        if (event === 'Delete') {
            this.removeHandler(this.selectedItem);
        }
    };
    return TimeEntryTypeConfigurationsDialogComponent;
}());
export { TimeEntryTypeConfigurationsDialogComponent };
