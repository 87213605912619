import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ImportTypesEnum } from 'src/app/model/enum';
import { ReportParametersDefaultValue } from 'src/app/shared-components/report-dialog/report-parameters-default-value';
import { Browser } from '../../../common/browser';
import { ICompanyUser, ISalaryStatement, IUserEmployment } from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { EmployeeService } from '../../../services/employee.service';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';
import { ReportDialogService } from '../../../shared-components/report-dialog/report-dialog.service';

@Component({
  selector: 'app-employee-payroll-data',
  templateUrl: './employee-payroll-data.component.html',
  styleUrls: ['./employee-payroll-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeePayrollDataComponent implements OnInit {
  public get allowEdit() {
    return this.sessionService.role.IsSalaryAdminAndHigher;
  }

  private userEmploymentValue: IUserEmployment;
  @Input()
  public get userEmployment(): IUserEmployment {
    return this.userEmploymentValue;
  }
  public set userEmployment(value: IUserEmployment) {
    if (!this.userEmploymentValue && !value) {
      return;
    }

    this.userEmploymentValue = value;
    this.hasEmployment = this.userEmployment && this.userEmployment.Id ? true : false;

    this.reportParams();
  }

  private privateCurrentCompanyUser: ICompanyUser;
  @Input()
  public get currentCompanyUser(): ICompanyUser {
    return this.privateCurrentCompanyUser;
  }

  public set currentCompanyUser(value: ICompanyUser) {
    this.privateCurrentCompanyUser = value;
    this.reportParams();
  }

  public get isMobile(): boolean {
    const browser: Browser = new Browser();
    return browser.isMobile;
  }

  public reportParams(): void {
    const params = new ReportParametersDefaultValue();
    params.employeeId = this.currentCompanyUser ? this.currentCompanyUser.Id : undefined;
    params.employmentId = this.userEmployment ? this.userEmployment.Id : undefined;
    this.reportDialogService.reportParams = params;
  }

  public reportDialogVisible = false;
  public newEmploymentDialogVisible = false;
  public recalculate = false;
  public newTabBlockedDialogVisible = false;
  public previewDialogVisible = false;
  public previewContent: any;
  public noPayslipDialogVisible = false;
  public isHidePreviewGrid = false;
  public isAddNew = false;
  public createNewEmployeeDialogVisible = false;
  public listClassExcluded = ['EmployeeInfo', 'Footer'];

  public get isCordovaApp(): boolean {
    return this.sessionService.browser.isHybridApp;
  }
  public get hasUserEmployment(): boolean {
    return this.hasEmployment;
  }

  private hasEmployment = false;

  constructor(
    public employeeService: EmployeeService,
    public sessionService: SessionService,
    private settingService: SettingService,
    private dataService: DataService,
    public reportDialogService: ReportDialogService
  ) {}

  public ngOnInit(): void {
    this.settingService.showModuleMessage('Employee.PayrollData');
  }

  public importInitialBalancesClick() {
    this.settingService.navigationParameters['dataImportSelectedOption'] = ImportTypesEnum.Initial_Balances;
    this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
  }

  public importDataClick() {
    this.settingService.navigationParameters['dataImportSelectedOption'] = ImportTypesEnum.Payroll;
    this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
  }

  public get ClassCardStack(): string {
    if (this.isHidePreviewGrid) {
      return '';
    }
    return 'Cards Cards--flex Form--stacked';
  }

  public get noCPRMessage(): string {
    return this.currentCompanyUser && this.currentCompanyUser.HasDummyIdentityNumber ? 'Employee.NoValidCPR' : '';
  }

  /* GS-5298
  public get isAddNewEmploymentHide(): boolean {
    return this.currentCompanyUser && this.currentCompanyUser.Gender === null;
  }

  public createUserEmployment(): void {
    if (this.isAddNewEmploymentHide) {
      return;
    }
    this.createUserEmploymentClick.emit();
  }
*/

  public createUserEmployment(): void {
    this.newEmploymentDialogVisible = true;
  }

  public viewFullSalaryBatch(): void {
    this.sessionService.NavigateTo('tabs.company.salarybatches');
  }

  public viewLastPayslip(): void {
    this.dataService
      .SalaryStatements_GetSalaryStatementsByCompanyUser(this.userEmployment.CompanyUserId)
      .subscribe((data: ISalaryStatement[]): void => {
        if (data.length === 0) {
          this.noPayslipDialogVisible = true;
        } else {
          // Only show the last payslip - which should be the first one in the list
          this.showPayslipInNewTab(data[0]);
        }
      });
  }

  private showPayslipInNewTab(payslip: ISalaryStatement): void {
    this.dataService.SalaryStatements_GetHtmlPreview('htmlstring', payslip).subscribe((data: string): void => {
      if (this.sessionService.browser.isHybridApp) {
        this.previewContent = data;
        this.previewDialogVisible = true;
      } else {
        const newWindow: Window = window.open('about:blank');
        if (newWindow) {
          newWindow.document.write(data);
          newWindow.document.close();
        } else {
          this.newTabBlockedDialogVisible = true;
        }
      }
    });
  }

  public isUpdate: boolean;
  public onUpdate(): void {
    this.isUpdate = true;
  }

  public onAddNewItem() {
    if (!this.sessionService.isSubmitting) {
      this.isAddNew = true;
    }
  }

  public expanded = false;
  public onExpand(event: any): void {
    this.expanded = event;
  }
}
