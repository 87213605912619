/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../module-check/module-check.component.ngfactory";
import * as i2 from "../module-check/module-check.component";
import * as i3 from "../../services/session/session.service";
import * as i4 from "../../services/api/static-data.service";
import * as i5 from "../../services/api/data.service";
import * as i6 from "../../services/authentication/authentication.service";
import * as i7 from "../pipes/price-format.pipe";
import * as i8 from "@angular/common";
import * as i9 from "@progress/kendo-angular-tooltip";
import * as i10 from "@progress/kendo-angular-popup";
import * as i11 from "./dialog-buttons.component";
var styles_DialogButtonsComponent = [];
var RenderType_DialogButtonsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogButtonsComponent, data: {} });
export { RenderType_DialogButtonsComponent as RenderType_DialogButtonsComponent };
function View_DialogButtonsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "app-module-check", [], null, [[null, "hasModuleChange"]], function (_v, en, $event) { var ad = true; if (("hasModuleChange" === en)) {
        var pd_0 = ((_v.parent.context.$implicit.hasModule = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ModuleCheckComponent_0, i1.RenderType_ModuleCheckComponent)), i0.ɵdid(1, 245760, null, 0, i2.ModuleCheckComponent, [i3.SessionService, i4.StaticDataService, i5.DataService, i6.AuthenticationService, i7.PriceFormatPipe], { moduleId: [0, "moduleId"], hasModule: [1, "hasModule"] }, { hasModuleChange: "hasModuleChange" }), (_l()(), i0.ɵeld(2, 0, null, 0, 4, "button", [["kendoTooltip", ""], ["type", "button"]], [[8, "disabled", 0], [8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.parent.context.$implicit.actionValue) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i8.ɵNgClassImpl, i8.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(4, 278528, null, 0, i8.NgClass, [i8.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(5, 9125888, null, 0, i9.TooltipDirective, [i0.ElementRef, i0.NgZone, i0.Renderer2, i10.PopupService, [2, i9.TooltipSettings], [2, i9.TOOLTIP_SETTINGS]], null, null), (_l()(), i0.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.requiredModuleId; var currVal_1 = _v.parent.context.$implicit.hasModule; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _v.parent.context.$implicit.classValue; _ck(_v, 4, 0, currVal_4); _ck(_v, 5, 0); }, function (_ck, _v) { var currVal_2 = ((_v.parent.context.$implicit.requiredModuleId && !_v.parent.context.$implicit.hasModule) || _v.parent.context.$implicit.disabled); var currVal_3 = (_v.parent.context.$implicit.tooltip || ""); _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_5 = _v.parent.context.$implicit.labelValue; _ck(_v, 6, 0, currVal_5); }); }
function View_DialogButtonsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.parent.context.$implicit.actionValue) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i8.ɵNgClassImpl, i8.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i8.NgClass, [i8.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.classValue; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.disabled; _ck(_v, 0, 0, currVal_0); var currVal_2 = _v.parent.context.$implicit.labelValue; _ck(_v, 3, 0, currVal_2); }); }
function View_DialogButtonsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["kendoTooltip", ""]], [[8, "title", 0]], null, null, null, null)), i0.ɵdid(1, 9125888, null, 0, i9.TooltipDirective, [i0.ElementRef, i0.NgZone, i0.Renderer2, i10.PopupService, [2, i9.TooltipSettings], [2, i9.TOOLTIP_SETTINGS]], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogButtonsComponent_2)), i0.ɵdid(3, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogButtonsComponent_3)), i0.ɵdid(5, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { _ck(_v, 1, 0); var currVal_1 = _v.context.$implicit.requiredModuleId; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_v.context.$implicit.requiredModuleId; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.tooltip || ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_DialogButtonsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "Modal-footer u-leader"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogButtonsComponent_1)), i0.ɵdid(2, 278528, null, 0, i8.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttons; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DialogButtonsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-dialog-buttons", [], null, null, null, View_DialogButtonsComponent_0, RenderType_DialogButtonsComponent)), i0.ɵdid(1, 49152, null, 1, i11.DialogButtonsComponent, [], null, null), i0.ɵqud(603979776, 1, { buttons: 1 })], null, null); }
var DialogButtonsComponentNgFactory = i0.ɵccf("app-dialog-buttons", i11.DialogButtonsComponent, View_DialogButtonsComponent_Host_0, {}, { buttonAction: "buttonAction" }, []);
export { DialogButtonsComponentNgFactory as DialogButtonsComponentNgFactory };
