import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Transition, TransitionService } from '@uirouter/core';
import { SettingService } from 'src/app/services/setting.service';
import { Global } from '../../common/global';
import {
  IAccount,
  IRole,
  IUser,
  IUserCompanyAccess,
  User,
  UserAccessPermissionsRequest
} from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { ReportDialogService } from '../../shared-components/report-dialog/report-dialog.service';
import { AccountantUsersService } from './accountant-users.service';

@Component({
  selector: 'app-accountant-users',
  templateUrl: './accountant-users.component.html',
  styleUrls: ['./accountant-users.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountantUsersComponent implements OnInit {
  public addNewUserDialogVisible = false;
  public removeUserDialogVisible = false;
  public dataUserList: IUser[];
  public dataRoleList: IUserCompanyAccess[];
  public accountName = '';
  public isFirstLoad = false;
  public discardDialogVisible = false;
  public triggerUpdate = false;
  public addNewUserVisible = false;
  public removeSuccessConfirm = false;
  public userSelected: any;
  public pageSize = 100;
  public listClassExcluded = ['Footer', '10'];

  private BaseSalaryAdminIdOption: number;
  private BaseFullAdminIdOption: number;

  public get isIOSApp(): boolean {
    return this.sessionService.browser.isHybridApp && this.sessionService.browser.iOSMobileDevice;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public get isAccountantAdmin(): boolean {
    return this.sessionService.role.IsMultiCompanyAdmin;
  }

  public get isRemoveUser(): boolean {
    return (
      this.sessionService.role.IsMultiCompanyAdmin &&
      this.service.selectedId !== null &&
      this.service.selectedId !== undefined
    );
  }

  public get isShowPaginationControl(): boolean {
    return this.gridUserFilterData.length <= this.pageSize ? false : true;
  }

  constructor(
    public service: AccountantUsersService,
    public staticData: StaticDataService,
    private dataService: DataService,
    public sessionService: SessionService,
    private transitionService: TransitionService,
    private settingService: SettingService,
    public reportDialogService: ReportDialogService
  ) {
    this.staticData.currentAccount.pipe().subscribe((account: IAccount) => {
      this.accountName = account.Name;
    });

    this.service.updateUsers.subscribe((model: User[]) => {
      if (model && model.length > 0 && this.gridUserFilterData && this.gridUserFilterData.length > 0) {
        model.forEach((item: User) => {
          Object.assign(this.gridUserFilterData.find((flag: User) => flag.Id === item.Id), item);
          this.triggerUpdate = true;
        });
      }
    });

    this.service.users.subscribe((data) => {
      this.dataUserList = data;
      this.gridUserFilterData = filterBy(this.dataUserList, this.userFilter);
    });

    this.service.roles.subscribe((data: any) => {
      this.dataRoleList = data;
      this.dataRoleList.sort((a, b) => {
        if (a.CompanyName && b.CompanyName) {
          return a.CompanyName.localeCompare(b.CompanyName);
        } else {
          return a.CompanyId - b.CompanyId;
        }
      });
      this.gridFilterData = filterBy(this.dataRoleList, this.filter);
    });
  }

  public onRowDataChange(event: any): void {
    const model: UserAccessPermissionsRequest = {
      RoleId: event.dataItem.RoleId,
      UserId: event.dataItem.UserId,
      isPaymentApprover: event.dataItem.IsPaymentApprover || false,
      CompanyId: event.dataItem.CompanyId
    };

    if (!this.service.EditRoleList.find((item: UserAccessPermissionsRequest) => item.CompanyId === model.CompanyId)) {
      this.service.EditRoleList.push(model);
    } else {
      Object.assign(
        this.service.EditRoleList.find((item: UserAccessPermissionsRequest) => item.CompanyId === model.CompanyId),
        model
      );
    }
  }

  public UserGridChange(event: any): void {
    if (event.dataItem && event.dataItem.Email) {
      event.dataItem.Email = event.dataItem.Email;
    }
    const model: User = {
      Id: event.dataItem.Id,
      FirstName: event.dataItem.FirstName || null,
      MiddleName: event.dataItem.MiddleName || null,
      LastName: event.dataItem.LastName || null,
      TelMobile: event.dataItem.TelMobile || null,
      Email: event.dataItem.Email || null,
      AccountRoleId: event.dataItem.AccountRoleId,
      IdentityNumberFormatted: undefined,
      HasDummyIdentityNumber: undefined,
      LanguageId: this.checkNumber(event.dataItem.LanguageId),
      AddressId: this.checkNumber(event.dataItem.AddressId),
      UseGlobalEmail: event.dataItem.UseGlobalEmail || false,
      IsFirstLogin: event.dataItem.IsFirstLogin || false,
      Created: event.dataItem.Created || null,
      ShowUpdateNotification: event.dataItem.ShowUpdateNotification || false,
      ShowUrgentNotification: event.dataItem.ShowUrgentNotification || false,
      ShowPayslipTutorial: this.checkNumber(event.dataItem.ShowPayslipTutorial),
      IdentityNumber: event.dataItem.IdentityNumber || null,
      BankRegNo: event.dataItem.BankRegNo || null,
      BankAccountNo: event.dataItem.BankAccountNo || null,
      Username: event.dataItem.Username || null,
      BicSwiftNo: event.dataItem.BicSwiftNo || null,
      IbanNo: event.dataItem.IbanNo || null,
      AccessPhrase: event.dataItem.AccessPhrase || null,
      DateOfBirth: event.dataItem.DateOfBirth || null,
      TelFixed: event.dataItem.TelFixed || null,
      DefaultInitials: event.dataItem.DefaultInitials || null,
      DefaultCompanyId: this.checkNumber(event.dataItem.DefaultCompanyId),
      Preferences: event.dataItem.Preferences || null,
      AccountId: this.checkNumber(event.dataItem.AccountId),
      IsFemale: event.dataItem.IsFemale || false,
      FullName: undefined,
      Gender: undefined,
      AccountRole: undefined,
      SystemAliasId: event.dataItem.SystemAliasId || null
    };

    if (!this.service.EditUserList.find((item: User) => item.Id === model.Id)) {
      this.service.EditUserList.push(model);
    } else {
      Object.assign(this.service.EditUserList.find((item: User) => item.Id === model.Id), model);
    }
  }

  public checkNumber(value: number): number {
    if ((!value && value === 0) || value) {
      return value;
    }
    return null;
  }

  public ngOnInit() {
    this.isFirstLoad = true;
    this.transitionService.onStart({}, (transition: Transition) => {
      const fromState: string = transition.from().name;
      const toState = transition.to().name;
      this.service.navigationToStateName = JSON.parse(JSON.stringify(toState));

      if (this.service.editMode && fromState !== toState) {
        if (
          (this.service.EditRoleList && this.service.EditRoleList.length > 0) ||
          (this.service.EditUserList && this.service.EditUserList.length > 0)
        ) {
          this.service.showSaveDataConfirmationChangeState = true;
        } else {
          this.onEditModeChange(false);
          this.sessionService.NavigateTo(this.service.navigationToStateName);
          return true;
        }
        return false;
      }

      this.service.clearUserSubject();
      return true;
    });

    this.staticData.Role.pipe().subscribe((role: IRole[]) => {
      if (role && role.length > 0) {
        this.BaseSalaryAdminIdOption = role.find((model: IRole) => model.Key === 'SalaryAdmin').Id;
        this.BaseFullAdminIdOption = role.find((model: IRole) => model.Key === 'FullAdmin').Id;
      }
    });
  }

  public onConfirmSaveDataOnChangeState(action: string): void {
    if (action === 'ContinueAndSave') {
      this.onEditModeChange(false);
      this.sessionService.NavigateTo(this.service.navigationToStateName);
      return;
    }

    if (action === 'DiscardAndLeave') {
      this.service.EditRoleList = [];
      this.service.EditUserList = [];
      this.service.loadData();
      this.service.discard();
      this.onEditModeChange(false);
      this.sessionService.NavigateTo(this.service.navigationToStateName);
      return;
    }
  }

  public tabDblclick($event?: any): void {
    if (!this.service.editMode) {
      if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      }
      this.onEditModeChange(true);
    }
  }

  public onEditModeChange($event: boolean): void {
    if (this.service.isBasicUser || this.service.isCompanyAdmin) {
      return;
    }
    if ($event && (this.sessionService.isGetting || this.sessionService.isSubmitting)) {
      return;
    }
    if (!this.service.selectedId) {
      if (this.gridUserFilterData && this.gridUserFilterData.length > 0) {
        this.onSelectedIdChange(this.gridUserFilterData[0].Id);
      } else {
        return;
      }
    }
    if (this.service.editMode) {
      this.service.save(this.service.EditRoleList);
      this.service.EditRoleList = [];
      this.service.saveUserList(this.service.EditUserList);
      this.service.EditUserList = [];
    }

    this.service.editMode = $event;
  }

  public confirmAndDiscardChanges(): void {
    if (
      (this.service.EditRoleList && this.service.EditRoleList.length > 0) ||
      (this.service.EditUserList && this.service.EditUserList.length > 0)
    ) {
      this.discardDialogVisible = true;
      return;
    }
    this.onEditModeChange(false);
  }

  public discardDialogAction(action: string): void {
    if (action === 'ConfirmDiscard') {
      this.service.EditRoleList = [];
      this.service.EditUserList = [];
      this.service.loadData();
      this.service.discard();
      this.onEditModeChange(false);
    }
  }

  public removeUserAction(action: string): void {
    if (action === 'Delete') {
      this.dataService.Account_RemoveUser(this.service.selectedId).subscribe(() => {
        this.removeSuccessConfirm = true;
      });
    }
  }

  public onDoneRemoveSuccess(event: any): void {
    this.settingService.ReloadWarrning = true;
    if (event) {
      if (event === 'Ok') {
        window.location.reload();
      }
    }
  }

  public userFilter: CompositeFilterDescriptor;
  public gridUserFilterData: IUser[] = filterBy(this.dataUserList, this.userFilter);

  public onUserFilterChange(filter: CompositeFilterDescriptor): void {
    this.userFilter = filter;
    this.gridUserFilterData = filterBy(this.dataUserList, filter);
  }

  public filter: CompositeFilterDescriptor;
  public gridFilterData: IUserCompanyAccess[] = filterBy(this.dataRoleList, this.filter);

  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridFilterData = filterBy(this.dataRoleList, filter);
  }

  public onSelectedIdChange(selectedId: any): void {
    if (this.service.editMode && this.service.EditRoleList && this.service.EditRoleList.length > 0) {
      this.service.save(this.service.EditRoleList, false);
      this.service.EditRoleList = [];
    }
    this.service.selectedId = selectedId;
    this.service.getRoleServicePublic();
    if (!this.isFirstLoad) {
      this.gridFilterData = [];
      this.service.EditRoleList = [];
    }
    this.isFirstLoad = false;
  }

  public onUserRoleSelected(event: any): void {
    // if (this.service.editMode && event) {
    //   const originApprover: boolean = JSON.parse(JSON.stringify(event.IsPaymentApprover));
    //   if (event.RoleId === this.BaseFullAdminIdOption || event.RoleId === this.BaseSalaryAdminIdOption) {
    //     event.IsPaymentApprover = true;
    //   } else {
    //     event.IsPaymentApprover = false;
    //   }
    //   if (originApprover !== event.IsPaymentApprover) {
    //     const onChangeData: any = {};
    //     onChangeData.dataItem = event;
    //     this.onRowDataChange(onChangeData);
    //   }
    // }
  }

  public onGridRoleControlDataChange(event: any): void {
    if (event && event.field === 'RoleId') {
      if (
        event.dataItem.RoleId === this.BaseFullAdminIdOption ||
        event.dataItem.RoleId === this.BaseSalaryAdminIdOption
      ) {
        event.dataItem.IsPaymentApprover = true;
      } else {
        event.dataItem.IsPaymentApprover = false;
      }
    }

    this.onRowDataChange(event);
  }

  public onMoreMenuOptionClick(menu: string): void {
    if (menu && menu === 'accountant_removeuser') {
      this.removeUserDialogVisible = true;
    }
  }

  public onCreateUserComplete(data: IUser): void {
    this.service.loadData();
    this.onEditModeChange(false);
  }
}
