import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Browser } from 'src/app/common/browser';
import { DataService } from 'src/app/services/api/data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { Constants } from '../../../common/constants';
import { FormComponentBase } from '../../../common/form-component-base';
import { Global } from '../../../common/global';
import { StaticDataService } from '../../../services/api/static-data.service';
import { EmployeeService } from '../../../services/employee.service';
import { SettingService } from '../../../services/setting.service';
import { ReportDialogService } from '../../../shared-components/report-dialog/report-dialog.service';
var EmployeeHiringStatusComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeHiringStatusComponent, _super);
    function EmployeeHiringStatusComponent(employeeService, settingService, dataService, staticDataService, sessionService, reportDialogService) {
        var _this = _super.call(this) || this;
        _this.employeeService = employeeService;
        _this.settingService = settingService;
        _this.dataService = dataService;
        _this.staticDataService = staticDataService;
        _this.sessionService = sessionService;
        _this.reportDialogService = reportDialogService;
        _this.createTemplateConfirmationDialogVisible = false;
        _this.newEmploymentDialogVisible = false;
        _this.createNewEmployeeDialogVisible = false;
        _this.listClassExcluded = ['EmployeeInfo', 'Footer'];
        _this.requestTaxcardDialogVisible = false;
        _this.taxcardHistoryDialogVisible = false;
        _this.requestTaxcardWarningDialog = false;
        _this.triggerRefreshTaxCard = false;
        _this.templateNameDialogVisible = false;
        _this.createTemplateTemplateCompletedDialogVisible = false;
        _this.createNewEmploymentDialogVisible = false;
        _this.synchronizeEmploymentWithTemplateDialogVisble = false;
        _this.deleteEmployeeDialogVisible = false;
        _this.expanded = false;
        _this.childIsValid = Array(12).fill(true);
        _this.childIsDirty = Array(12).fill(false);
        return _this;
    }
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "allowEdit", {
        get: function () {
            return this.sessionService.role.IsSalaryAdminAndHigher;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "companyUser", {
        get: function () {
            return this.companyUserValue;
        },
        set: function (value) {
            if (!this.companyUserValue && !value) {
                return;
            }
            this.companyUserValue = value;
            this.childIsValid = Array(12).fill(true);
            this.childIsDirty = Array(12).fill(false);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "userEmployment", {
        get: function () {
            return this.userEmploymentValue;
        },
        set: function (value) {
            if (!this.userEmploymentValue && !value) {
                return;
            }
            if (!value || !value.Id) {
                this.hasUserEmployment = false;
            }
            else {
                this.hasUserEmployment = true;
            }
            if (this.userEmploymentValue !== value) {
                this.taxCardDataChanged = true;
                this.userEmploymentValue = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "taxCardObject", {
        get: function () {
            this.setTaxCardData(this.userEmployment);
            return this.taxCardData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "SecondaryIncomeType", {
        get: function () {
            if (!this.userEmployment) {
                return true;
            }
            return this.userEmployment.IncomeTypeId === 5;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "firstPensionProviderVisible", {
        get: function () {
            if (this.userEmployment && this.userEmployment.PensionProviderId) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "secondPensionProviderVisible", {
        get: function () {
            if (this.userEmployment && this.userEmployment.Pension2ProviderId) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "showStatisticsSection", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.StatisticsRecipientId && Global.COMPANY.StatisticsRecipientId !== -1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "isSwedenCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "showMultiPurposeOffDayCompensation", {
        get: function () {
            var enableFlexBalanceSetting = this.sessionService.getCompanyPreference('Company.EnableFlexBalance');
            if (enableFlexBalanceSetting) {
                return enableFlexBalanceSetting.Value === 'true' ? true : false;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "isDanishCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "noCPRMessage", {
        get: function () {
            return this.companyUser && this.companyUser.HasDummyIdentityNumber;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "allowEditUserEmployment", {
        get: function () {
            return (this.sessionService.feature.AllowEditUserEmployment(this.sessionService.currentState) &&
                !this.sessionService.role.IsReadOnly);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "warningMessage", {
        get: function () {
            return this.noCPRMessage ? 'EmployeeEmployment.NoSalaryCalculationWithoutCpr' : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "isShowExtraVacationHours", {
        get: function () {
            return this.isDanishCompany && this.companyUser && !!this.userEmployment.VacationTypeId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeHiringStatusComponent.prototype, "isMobile", {
        get: function () {
            var browser = new Browser();
            return browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeHiringStatusComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.TaxCardType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.taxCardTypes = data); });
        this.settingService.showModuleMessage('Employee.Employment');
    };
    EmployeeHiringStatusComponent.prototype.setTaxCardData = function (value, newtaxInfo) {
        if (this.taxCardDataChanged || newtaxInfo) {
            var isSpecialTaxBrief = void 0;
            if (value && (value.IncomeTypeId === 0 || value.IncomeTypeId === Constants.MAGIC_NUMBER)) {
                isSpecialTaxBrief = false;
            }
            else {
                isSpecialTaxBrief = true;
            }
            // Only create a new instance of taxcard if there is changes
            this.taxCardData = {
                taxInfo: newtaxInfo ? newtaxInfo : this.saveTaxInfo ? this.saveTaxInfo : undefined,
                taxCardRequestTime: value ? value.TaxCardRequested : undefined,
                taxCardTypes: this.taxCardTypes,
                userEmploymentId: value ? value.Id : undefined,
                specialTaxBrief: isSpecialTaxBrief
            };
            this.taxCardDataChanged = false;
        }
    };
    EmployeeHiringStatusComponent.prototype.onDataChanged = function (isReload) {
        if (isReload === void 0) { isReload = false; }
        if (this.userEmployment.IncomeTypeId === 5) {
            this.userEmployment.VacationTypeId = -1;
        }
        this.onChange();
        if (isReload) {
            // this.reloadUserEmployment.emit();
            this.employeeService.selectEmployee(this.companyUser.Id, true);
        }
    };
    EmployeeHiringStatusComponent.prototype.createUserEmployment = function () {
        this.newEmploymentDialogVisible = true;
    };
    EmployeeHiringStatusComponent.prototype.onReloadTaxCard = function () {
        this.triggerRefreshTaxCard = !this.triggerRefreshTaxCard;
    };
    EmployeeHiringStatusComponent.prototype.onDialogSynchronizeEmploymentWithTemplate = function (event) {
        var _this = this;
        if (event && event === 'Yes') {
            if (this.userEmployment && this.userEmployment.Id) {
                this.dataService
                    .Employments_UpdateEmploymentToCurrentTemplate(this.userEmployment.Id)
                    .subscribe(function () { return _this.employeeService.selectEmployee(_this.companyUser.Id, true); });
            }
        }
    };
    EmployeeHiringStatusComponent.prototype.onCreateTemplateConfirmationDialogAction = function (action) {
        if (action === 'Proceed') {
            this.newTemplateName = '';
            this.templateNameDialogVisible = true;
        }
    };
    EmployeeHiringStatusComponent.prototype.onTemplateNameDialogAction = function (action) {
        var _this = this;
        if (action === 'Create' && this.newTemplateName) {
            var request = {
                BasedOnEmploymentId: this.userEmployment ? this.userEmployment.Id : undefined,
                Name: this.newTemplateName
            };
            this.dataService
                .EmploymentTemplates_CreateTemplateFromEmployment(request)
                .subscribe(function (template) { return (_this.createTemplateTemplateCompletedDialogVisible = true); });
        }
    };
    EmployeeHiringStatusComponent.prototype.onRequestTaxcardWarningDialogAction = function (action) {
        if (action === 'AlreadyRequestedTaxCardConfirmButton') {
            this.showRequestTaxcardDialog();
        }
    };
    EmployeeHiringStatusComponent.prototype.showRequestTaxcardDialog = function () {
        this.requestTaxcardDialogVisible = true;
    };
    EmployeeHiringStatusComponent.prototype.showViewTaxCardHistory = function () {
        this.taxcardHistoryDialogVisible = true;
    };
    EmployeeHiringStatusComponent.prototype.showRequestTaxcardConfirm = function (value) {
        this.saveTaxInfo = value;
        this.requestTaxcardWarningDialog = true;
    };
    EmployeeHiringStatusComponent.prototype.EmploymentBalanceConfigurationsChange = function (dataChange, changeNumber) {
        if (dataChange && dataChange.length > 0) {
            if (this.userEmployment.EmploymentBalanceConfigurations &&
                this.userEmployment.EmploymentBalanceConfigurations.length > 0) {
                var model = this.userEmployment.EmploymentBalanceConfigurations.find(function (item) { return item.BalanceDefinitionId === changeNumber; });
                if (model) {
                    this.userEmployment.EmploymentBalanceConfigurations[this.userEmployment.EmploymentBalanceConfigurations.indexOf(model)] =
                        dataChange[dataChange.indexOf(dataChange.find(function (item) { return item.BalanceDefinitionId === changeNumber; }))];
                }
                else {
                    this.userEmployment.EmploymentBalanceConfigurations.push(dataChange[0]);
                }
            }
            else {
                this.userEmployment.EmploymentBalanceConfigurations.push(dataChange[0]);
            }
        }
    };
    EmployeeHiringStatusComponent.prototype.onCreateTaxInfo = function (value) {
        if (value) {
            this.setTaxCardData(this.userEmployment, value);
        }
    };
    EmployeeHiringStatusComponent.prototype.onExpand = function (event) {
        this.expanded = event;
    };
    return EmployeeHiringStatusComponent;
}(FormComponentBase));
export { EmployeeHiringStatusComponent };
