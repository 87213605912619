<div *ngIf="showTitle" class="Card-title Card-title--top company-total">
  {{ 'CompanySalaryBatches.Totals' | translate }}
</div>
<div *ngIf="!showTitle" class="u-leader company-total"></div>

<div class="FormElement Company-totalsControls company-total">
  <div class="FormElement-legend FormElement-label u-hidden">{{ 'CompanySalaryBatches.Totals' | translate }}</div>
  <div class="FormElement-control">
    <app-radio-edit [editMode]="true" [(value)]="view" (valueChange)="viewModeChange()">
      <app-radio-edit-option
        [value]="'salarytypes'"
        [label]="'CompanySalaryBatches.SalaryTypeRadioButtonText' | translate"
      ></app-radio-edit-option>
      <app-radio-edit-option [value]="'units'" [label]="'CompanySalaryBatches.TimeRadioButtonText' | translate">
      </app-radio-edit-option>
    </app-radio-edit>
  </div>
</div>

<app-salary-batch-salary-types
  *ngIf="view === 'salarytypes'"
  [SalaryBatchId]="SalaryBatchId"
  [SalaryTypeTotals]="companySalaryBatchService.salaryTypeTotals | async"
></app-salary-batch-salary-types>
<app-salary-batch-time-and-vacation
  *ngIf="view === 'units'"
  [SalaryBatchId]="SalaryBatchId"
  [UnitTotals]="companySalaryBatchService.unitTotals | async"
>
</app-salary-batch-time-and-vacation>
