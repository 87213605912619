import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { Browser } from '../../common/browser';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-edit-button',
  templateUrl: './edit-button.component.html'
})
export class EditButtonComponent {
  @ViewChild(TooltipDirective, { static: true }) public tooltipDir: TooltipDirective;

  @Output() public change: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public errorChangeEditMode: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  get editMode(): boolean {
    return this.editModeValue;
  }

  set editMode(value: boolean) {
    if (this.editModeValue !== value) {
      this.editModeValue = value;
      this.change.emit(value);
    }
  }

  get buttonClass(): string {
    return 'editButtonFor' + this.module;
  }

  @Input() public module: string;
  @Input() public isVisible: boolean;
  @Input() public disable = false;
  @Input() public allowCompanyPreference = true;
  @Input() public isCanChangeEditMode = true;

  public isReadOnly = false;
  private editModeValue = false;
  public isFirstClick = false;

  public get EditTooltip(): string {
    if (this.allowCompanyPreference) {
      if (this.editMode) {
        return 'Employee.Save';
      } else {
        return 'Employee.Edit';
      }
    }

    return 'EmploymentTime.DisableGridTimeMessage';
  }

  constructor(private sessionService: SessionService) {
    this.isReadOnly = this.sessionService.role.IsReadOnly;
    if (this.sessionService.currentState.includes('tabs.accountant')) {
      this.isReadOnly = false;
    }
  }

  get enableVisibleClass(): string {
    return this.editMode ? 'isEnableVisible' : 'hide';
  }

  get disableVisibleClass(): string {
    return this.editMode ? 'hide' : 'isEnableVisible';
  }

  public onEditButtonClick(tooltipTarget: any): void {
    if (this.sessionService.isGetting || this.sessionService.isSubmitting) {
      return;
    }
    if (!this.isCanChangeEditMode) {
      this.errorChangeEditMode.emit();
      return;
    }
    this.isFirstClick = true;
    this.tooltipDir.toggle(tooltipTarget);
    if (this.isReadOnly && this.sessionService.currentState !== 'tabs.selfservice.general') {
      return;
    }

    const browser: Browser = new Browser();
    if (browser.isMobile) {
      setTimeout(() => {
        this.change.emit(!this.editMode);
        this.isFirstClick = false;
      }, 200);
      return;
    }
    if (browser.isChrome) {
      setTimeout(() => {
        this.change.emit(!this.editMode);
        this.isFirstClick = false;
      }, 400);
    } else {
      setTimeout(() => {
        this.change.emit(!this.editMode);
        this.isFirstClick = false;
      }, 800);
    }
  }
}
