<app-dialog [overlayClose]="false" [(visible)]="isVisible" (action)="onTaxCardAction($event)" [minWidth]="300">
  <app-combobox-edit
    [label]="'EmployeeGeneral.TaxCardType' | translate"
    [editMode]="true"
    [comboboxDataSource]="taxcardTypes"
    [(value)]="selectedTaxcardTypeId"
    [textField]="'Name'"
    [idField]="'Id'"
  >
  </app-combobox-edit>

  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" [(visible)]="isShowAlert" [leadingText]="alertMessage">
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>
