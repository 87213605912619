import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../../common/form-component-base';
import { StaticDataService } from '../../../services/api/static-data.service';
var AddressComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AddressComponent, _super);
    function AddressComponent(staticDataService) {
        var _this = _super.call(this) || this;
        _this.staticDataService = staticDataService;
        _this.childIsDirty = Array(5).fill(false);
        return _this;
    }
    AddressComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.Country.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.countries = data); });
        this.staticDataService.City.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.cities = data); });
    };
    AddressComponent.prototype.onPostalCodeChanged = function (postalCode) {
        var _this = this;
        if (this.address.CountryId === 1 && postalCode) {
            var city = this.cities
                ? this.cities.find(function (c) {
                    return c.CountryId === _this.address.CountryId && c.PostalCode && postalCode === c.PostalCode.toString();
                })
                : undefined;
            if (city) {
                this.address.City = city.Key;
            }
        }
        this.onChange();
    };
    return AddressComponent;
}(FormComponentBase));
export { AddressComponent };
