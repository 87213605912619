/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@progress/kendo-angular-dialog/dist/es2015/index.ngfactory";
import * as i2 from "@progress/kendo-angular-l10n";
import * as i3 from "@progress/kendo-angular-dialog";
import * as i4 from "@angular/common";
import * as i5 from "./i-frame-dialog.component";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../services/session/session.service";
var styles_IFrameDialogComponent = [];
var RenderType_IFrameDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IFrameDialogComponent, data: {} });
export { RenderType_IFrameDialogComponent as RenderType_IFrameDialogComponent };
function View_IFrameDialogComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["iframeInDialog", 1]], null, 0, "iframe", [["class", "k-content-frame"], ["height", "700"], ["src", "about:blank"], ["width", "960"]], [[8, "srcdoc", 1]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.iframeLoaded() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.safeContent; _ck(_v, 0, 0, currVal_0); }); }
function View_IFrameDialogComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["iframeInDialog", 1]], null, 0, "iframe", [["class", "k-content-frame"], ["height", "700"], ["width", "960"]], null, [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.iframeLoaded() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_IFrameDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "kendo-dialog", [["id", "iframeDialog"]], [[1, "dir", 0], [1, "tabIndex", 0], [2, "k-dialog-wrapper", null]], [[null, "close"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onComponentKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = ((_co.visible = false) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (_co.onkeydown($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_DialogComponent_0, i1.RenderType_DialogComponent)), i0.ɵprd(256, null, i2.L10N_PREFIX, "kendo.dialog", []), i0.ɵprd(131584, null, i2.LocalizationService, i2.LocalizationService, [i2.L10N_PREFIX, [2, i2.MessageService], [2, i2.RTL]]), i0.ɵdid(3, 5488640, [[2, 4], ["kendoDialog", 4]], 1, i3.DialogComponent, [i0.ElementRef, i0.Renderer2, i2.LocalizationService], { title: [0, "title"], width: [1, "width"], height: [2, "height"] }, { close: "close" }), i0.ɵqud(335544320, 3, { titlebarContent: 0 }), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_IFrameDialogComponent_2)), i0.ɵdid(6, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_IFrameDialogComponent_3)), i0.ɵdid(8, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.title; var currVal_4 = 960; var currVal_5 = 700; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = !_co.isEdge; _ck(_v, 6, 0, currVal_6); var currVal_7 = _co.isEdge; _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).dir; var currVal_1 = i0.ɵnov(_v, 3).tabIndex; var currVal_2 = i0.ɵnov(_v, 3).wrapperClass; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_IFrameDialogComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { iframe: 0 }), i0.ɵqud(671088640, 2, { kendoDialog: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IFrameDialogComponent_1)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible && _co.safeContent); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_IFrameDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-i-frame-dialog", [], null, null, null, View_IFrameDialogComponent_0, RenderType_IFrameDialogComponent)), i0.ɵdid(1, 180224, null, 0, i5.IFrameDialogComponent, [i6.DomSanitizer, i7.SessionService, i0.Renderer2, i0.ElementRef], null, null)], null, null); }
var IFrameDialogComponentNgFactory = i0.ɵccf("app-i-frame-dialog", i5.IFrameDialogComponent, View_IFrameDialogComponent_Host_0, { title: "title", isSelfService: "isSelfService", visible: "visible", iframeContent: "iframeContent" }, { visibleChange: "visibleChange" }, []);
export { IFrameDialogComponentNgFactory as IFrameDialogComponentNgFactory };
