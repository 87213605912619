import { Injectable } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { CompanyService } from '../../../services/company.service';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyDataImportservice extends CompanyService {
  constructor(
    protected dataService: DataService,
    protected staticDataService: StaticDataService,
    protected settingService: SettingService,
    protected sessionService: SessionService,
    protected transitionService: TransitionService,
    protected broadcaster: BroadcastService
  ) {
    super(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster);
  }

  protected get allowShortcut(): boolean {
    return this.sessionService.currentState === 'tabs.company.configuration.dataimport';
  }
}
