import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef, OnInit } from '@angular/core';
import { ControlComponentBase } from '../../common/control-component-base';
import { ReflectionHelper } from '../../common/reflection-helper';
var CheckEditSlideComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CheckEditSlideComponent, _super);
    function CheckEditSlideComponent(elementRef, changeDetectorRef) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.tooltip = '';
        _this.helpTooltip = '';
        _this.isOpenLink = true;
        _this.helpIcon = 'Help';
        _this.selfService = '';
        _this.isLink = false;
        _this.openLinkInTitle = '';
        return _this;
    }
    Object.defineProperty(CheckEditSlideComponent.prototype, "labelClass", {
        get: function () {
            return this.customClass + ' ' + (this.isGrid ? 'checkBox-label-center' : '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckEditSlideComponent.prototype, "isDisabled", {
        get: function () {
            return this.disable ? this.disable : !this.editMode;
        },
        enumerable: true,
        configurable: true
    });
    CheckEditSlideComponent.prototype.ngOnInit = function () { };
    CheckEditSlideComponent.prototype.valid = function () {
        return true;
    };
    CheckEditSlideComponent.prototype.convertValue = function (value) {
        if (value && ReflectionHelper.isString(value)) {
            return value.toLowerCase() === 'true';
        }
        else {
            return value;
        }
    };
    CheckEditSlideComponent.prototype.onOpenlinkInTitleClick = function () {
        if (this.openLinkInTitle) {
            window.open(this.openLinkInTitle);
        }
    };
    CheckEditSlideComponent.prototype.onClick = function (event) { };
    return CheckEditSlideComponent;
}(ControlComponentBase));
export { CheckEditSlideComponent };
