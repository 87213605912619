<app-dialog
  (action)="onDialogAction($event)"
  [overlayClose]="false"
  [(visible)]="visible"
  [title]="'RevertBatch.Headline' | translate"
>
  <div class="Modal-header" [innerHTML]="'RevertBatch.SubHeadline' | translate"></div>

  <div class="FormElement-row">
    <app-icon [icon]="'Success'" [size]="'small'"></app-icon>
    <span
      [innerHTML]="'RevertBatch.RevertConfirmationResult1' | translate: { SystemAlias: branding.SystemAlias }"
    ></span>
  </div>

  <div class="FormElement-row">
    <app-icon [icon]="'Success'" [size]="'small'"></app-icon>
    <span [innerHTML]="'RevertBatch.RevertConfirmationResult2' | translate"></span>
  </div>

  <div class="FormElement-row" *ngIf="salaryBatch?.NetsPaymentsProcessingDate">
    <app-icon [icon]="'Success'" [size]="'small'"></app-icon>
    <span [innerHTML]="'RevertBatch.RevertConfirmationResult3' | translate"></span>
  </div>

  <div class="FormElement-row">
    <app-icon [icon]="'ErrorExclamation'" [size]="'small'"></app-icon>
    <span [innerHTML]="'RevertBatch.RevertConfirmationResult4' | translate"></span>
  </div>

  <div *ngIf="!isGreenland" class="FormElement-row">
    <app-icon [icon]="'ErrorExclamation'" [size]="'small'"></app-icon>
    <span [innerHTML]="'RevertBatch.RevertConfirmationResult5' | translate"></span>
  </div>

  <div *ngIf="isGreenland" class="FormElement-row">
    <app-icon [icon]="'ErrorExclamation'" [size]="'small'"></app-icon>
    <span [innerHTML]="'RevertPayslip.RevertConfirmationResult8' | translate"></span>
  </div>

  <div class="FormElement-row">
    <app-icon [icon]="'ErrorExclamation'" [size]="'small'"></app-icon>
    <span [innerHTML]="'RevertBatch.RevertConfirmationResult6' | translate"></span>
  </div>
  <div class="FormElement-row" *ngIf="companySalaryBatchService.hasIntegrationName">
    <app-icon [icon]="'ErrorExclamation'" [size]="'small'"></app-icon>
    <span [innerHTML]="'RevertBatch.RevertConfirmationResult7' | translate: translationParams"></span>
  </div>

  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Understood'" [close]="false" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="passwordDialogVisible" (action)="onPasswwordDialogAction($event)">
  <form *ngIf="passwordDialogVisible">
    <input
      type="text"
      value="{{ userName }}"
      class="input-no-display"
      autocomplete="username"
      id="revertsalarybatch_username"
      name="revertsalarybatch_username"
    />
    <app-text-edit
      [label]="'RevertBatch.PasswordLabel' | translate"
      [(value)]="password"
      [editMode]="true"
      [type]="'password'"
    ></app-text-edit>
  </form>

  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Proceed'"></app-dialog-action>
</app-dialog>
