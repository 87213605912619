import { Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmployeeService } from 'src/app/services/employee.service';
import { environment } from '../../../../../environments/environment';
import { SalaryBatchStatusEnum } from '../../../../model/enum';
import { ISalaryBatch, ISimpleSalaryBatchRecord, ISimpleSalaryStatement } from '../../../../services/api/api-model';
import { DataService } from '../../../../services/api/data.service';
import { DialogService } from '../../../../services/dialog.service';
import { DownloadService } from '../../../../services/download.service';
import { SessionService } from '../../../../services/session/session.service';
import { CompanySalaryBatchService } from '../../company-salary-batches.service';

@Component({
  selector: 'app-salary-batch-payslips',
  templateUrl: './salary-batch-payslips.component.html',
  styleUrls: ['./salary-batch-payslips.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SalaryBatchPayslipsComponent implements OnDestroy {
  private selectedSalaryBatchValue: ISalaryBatch;
  @Input()
  public get selectedSalaryBatch(): ISalaryBatch {
    return this.selectedSalaryBatchValue;
  }
  public set selectedSalaryBatch(value: ISalaryBatch) {
    this.selectedSalaryBatchValue = value;
  }

  private salaryStatementsValue: ISimpleSalaryStatement[] = [];
  @Input()
  public get salaryStatements(): ISimpleSalaryStatement[] {
    return this.salaryStatementsValue;
  }
  public set salaryStatements(value: ISimpleSalaryStatement[]) {
    this.salaryStatementsValue = value;
    this.filterSalaryStatements();
  }

  @Output() public revertBatchCompleted: EventEmitter<void> = new EventEmitter<void>();

  public get IsWeb(): boolean {
    return !this.sessionService.browser.isHybridApp;
  }

  public get IsMobile(): boolean {
    return this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
  }

  public get IsFinalizedBatch(): boolean {
    return this.selectedSalaryBatch && this.selectedSalaryBatch.StatusId === SalaryBatchStatusEnum.Finalized;
  }

  public filteredSalaryStatements: ISimpleSalaryStatement[] = [];

  private selectedSalaryStatementValue: ISimpleSalaryStatement;
  public get selectedSalaryStatement(): ISimpleSalaryStatement {
    return this.selectedSalaryStatementValue;
  }
  public set selectedSalaryStatement(value: ISimpleSalaryStatement) {
    if (this.selectedSalaryStatementValue !== value) {
      this.selectedSalaryStatementValue = value;
      this.loadSalaryStatementDetails();
    }
  }

  public get selectedSalaryStatementId(): number {
    return this.selectedSalaryStatement ? this.selectedSalaryStatement.Id : undefined;
  }
  public set selectedSalaryStatementId(value: number) {
    this.selectDefaultSelectedPayslip(value);
  }

  public salaryStatementDetails: ISimpleSalaryBatchRecord[] = [];
  public newTabBlockedDialogVisible = false;
  public previewContent: any;

  private searchTextValue: string;
  public get searchText(): string {
    return this.searchTextValue;
  }
  public set searchText(value: string) {
    if (this.searchTextValue !== value) {
      this.searchTextValue = value;
      this.filterSalaryStatements();
    }
  }

  constructor(
    private sessionService: SessionService,
    private dataService: DataService,
    private downloadService: DownloadService,
    private employeeService: EmployeeService,
    private dialog: DialogService,
    private companySalaryBatchService: CompanySalaryBatchService
  ) {
    this.companySalaryBatchService.selectedSalaryBatch
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: ISalaryBatch | any) => {
        this.selectedSalaryBatchValue = value;
        this.filterSalaryStatements();
      });
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private loadSalaryStatementDetails(): void {
    if (this.selectedSalaryStatement) {
      this.dataService
        .SalaryStatements_GetSimplifiedBatchRecords(this.selectedSalaryStatement.Id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: ISimpleSalaryBatchRecord[]) => {
          this.salaryStatementDetails = data;
        });
    }
  }

  private flagDownloadSalaryStatement = false;
  public downloadSalaryStatement(type: string): void {
    if (this.sessionService.isGetting || this.sessionService.isSubmitting) {
      return;
    }
    if (!this.flagDownloadSalaryStatement) {
      if (type === 'pdf') {
        if (
          this.sessionService.browser.isHybridApp ||
          this.sessionService.browser.isIE ||
          this.sessionService.browser.isEdge
        ) {
          this.dataService.SalaryStatements_GetPdf(this.selectedSalaryStatement.Id).subscribe((data: string): void => {
            this.downloadService.download('Payslip.pdf', data);
          });
        } else {
          this.getPayslipReportByToken(this.selectedSalaryStatement.Id, 'a4ppdf');
        }
      } else if (type === 'htmlSameTab') {
        if (!this.dialog.isBindding) {
          this.dialog.isBindding = true;
          const types: string = this.IsMobile ? 'htmlstring' : 'a4phtmlstring';
          this.dataService.SalaryStatements_GetHtml(this.selectedSalaryStatement.Id, types).subscribe(
            (content: any): void => {
              this.dialog.showIFrameDialog(content);
              this.dialog.isBindding = false;
            },
            () => {
              this.dialog.isBindding = false;
            }
          );
        }
      } else if (type === 'htmlPrint') {
        this.printPayslipByToken(this.selectedSalaryStatement.Id, 'printa4phtmlstaticstring');
      } else {
        this.getPayslipReportByToken(this.selectedSalaryStatement.Id, 'html');
      }

      this.flagDownloadSalaryStatement = true;
    } else {
      this.flagDownloadSalaryStatement = false;
    }
  }

  private filterSalaryStatements(): void {
    if (this.searchText) {
      this.filteredSalaryStatements = this.salaryStatements.filter(
        (s: ISimpleSalaryStatement) =>
          s.UserFullName.toLocaleUpperCase().indexOf(this.searchTextValue.toLocaleUpperCase()) > -1 ||
          (s.UserIdentityNumber ? s.UserIdentityNumber : '')
            .toLocaleUpperCase()
            .indexOf(this.searchTextValue.toLocaleUpperCase()) > -1 ||
          (s.UserExternalReference ? s.UserExternalReference : '')
            .toLocaleUpperCase()
            .indexOf(this.searchTextValue.toLocaleUpperCase()) > -1
      );
    } else {
      this.filteredSalaryStatements = this.salaryStatements;
    }

    this.gridData = filterBy(this.filteredSalaryStatements, this.filter);
    this.selectDefaultSelectedPayslip();
  }

  private selectDefaultSelectedPayslip(selectedPayslipId?: number): void {
    selectedPayslipId = selectedPayslipId
      ? selectedPayslipId
      : this.selectedSalaryStatement
      ? this.selectedSalaryStatement.Id
      : undefined;
    if (this.filteredSalaryStatements.length > 0) {
      const existedPayslip: ISimpleSalaryStatement = selectedPayslipId
        ? this.filteredSalaryStatements.find((s: ISimpleSalaryStatement) => s.Id === selectedPayslipId)
        : undefined;
      this.selectedSalaryStatement = existedPayslip ? existedPayslip : this.filteredSalaryStatements[0];
    } else {
      this.selectedSalaryStatement = undefined;
      this.salaryStatementDetails = undefined;
    }
  }

  public printPayslipByToken(salaryStatementId: number, format: string): void {
    this.dataService.SalaryStatements_GetPayslipDownloadToken(salaryStatementId).subscribe((token: any): void => {
      const languageCode: string = this.sessionService.LanguageCode;
      const fileName = Math.random()
        .toString(36)
        .substring(7);
      this.dataService
        .SalaryStatements_DownloadPayslipByToken(salaryStatementId, token, languageCode, format, fileName)
        .subscribe((data: any) => {
          this.dialog.showIFrameDialog(data);
          this.dialog.isBindding = false;
        });
    });
  }

  private getPayslipReportByToken(salaryStatementId: number, format: string): void {
    this.dataService.SalaryStatements_GetPayslipDownloadToken(salaryStatementId).subscribe((token: any): void => {
      const languageCode: string = this.sessionService.LanguageCode;
      const downloadUrl = `/api/salarystatements/${salaryStatementId}/token/${token}/lang/${languageCode}/format/${format}/Payslip`;

      const newWindow: Window = window.open(environment.apiUrl + downloadUrl, '_blank');
      if (!newWindow) {
        this.newTabBlockedDialogVisible = true;
      }
    });
  }

  public onDoubleClick(data: ISimpleSalaryStatement): void {
    if (data && data.UserEmploymentId) {
      this.employeeService.selectEmployeeFromEmploymentId(data.UserEmploymentId);
      this.sessionService.NavigateTo('tabs.employee.payrolldata');
    }
  }

  public filter: CompositeFilterDescriptor;
  public gridData: ISimpleSalaryStatement[] = this.filteredSalaryStatements;
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridData = filterBy(this.filteredSalaryStatements, this.filter);
  }
}
