import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injectable,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DialogComponent } from '@progress/kendo-angular-dialog';
import { Subject } from 'rxjs';
import * as srcDoc from 'srcdoc-polyfill';
import { SessionService } from '../../services/session/session.service';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-i-frame-dialog',
  templateUrl: './i-frame-dialog.component.html'
})
export class IFrameDialogComponent implements OnDestroy {
  @Input() public title = ' ';
  @Input() public isSelfService = false;
  public visibleValue = false;

  @Input()
  public get visible(): boolean {
    if (this.sessionService.checkIsAliveError) {
      return false;
    }
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      if (value === false) {
        if (this.sessionService.browser.isHybridApp) {
          window.screen.orientation.lock('portrait');
        }
      }
      this.visibleValue = value;
      this.contentLoaded = false;
      this.visibleChange.emit(value);
    }

    if (value) {
      this.registerEvents();
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public iframeContentValue: string;
  @Input() public set iframeContent(value: string) {
    if (this.iframeContentValue !== value) {
      this.iframeContentValue = value;
      this.contentLoaded = false;
      this.safeContent = this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }

  public safeContent: SafeHtml;
  public helpDownloadVisible = false;

  @ViewChild('iframeInDialog', { static: false }) public iframe: ElementRef;
  @ViewChild('kendoDialog', { static: false }) public kendoDialog: any;

  public contentLoaded: boolean;
  private isDown = false;
  private defaultOffset: number[] = [0, 0];
  private mousePosition: number[] = [0, 0];

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  constructor(
    public sanitizer: DomSanitizer,
    public sessionService: SessionService,
    public renderer: Renderer2,
    public elementRef: ElementRef
  ) {}

  private registerEvents(): void {
    setTimeout(() => {
      const overlayElements: any = this.elementRef.nativeElement.querySelectorAll('.k-overlay');
      if (overlayElements && overlayElements.length > 0) {
        overlayElements[0].addEventListener('click', () => {
          this.visible = false;
        });
      }

      // move dialog by mouse
      if (this.kendoDialog._elRef && this.kendoDialog._elRef.nativeElement && !this.isSelfService) {
        if (window.innerWidth >= 1900) {
          this.moveDialog();
        }
      }
    });
  }

  private moveDialog(): void {
    // Get overlay root Dialog
    const root: HTMLElement = this.kendoDialog._elRef.nativeElement;
    root.style.display = 'block';

    // Get child Dialog
    const childElementDialog: HTMLElement = root.querySelector('div.k-window');
    childElementDialog.style.position = 'absolute';
    childElementDialog.style.left = window.innerWidth - childElementDialog.offsetWidth + 'px';
    childElementDialog.style.top = '0px';
    childElementDialog.style.cursor = 'move';

    // funtion mousedown
    childElementDialog.addEventListener(
      'mousedown',
      (event: any) => {
        this.isDown = true;
        this.defaultOffset[0] = childElementDialog.offsetLeft - event.clientX;
        this.defaultOffset[1] = childElementDialog.offsetTop - event.clientY;
      },
      true
    );

    // function mouseup
    childElementDialog.addEventListener(
      'mouseup',
      () => {
        this.isDown = false;
      },
      true
    );

    // function mouseleave
    childElementDialog.addEventListener(
      'mouseleave',
      () => {
        this.isDown = false;
      },
      true
    );

    // funtion mousemove
    childElementDialog.addEventListener(
      'mousemove',
      (event: any) => {
        event.preventDefault();
        childElementDialog.focus();
        if (window.getSelection().empty) {
          window.getSelection().empty();
        } else if (window.getSelection().removeAllRanges) {
          window.getSelection().removeAllRanges();
        }
        if (this.isDown) {
          this.mousePosition[0] = event.clientX;
          this.mousePosition[1] = event.clientY;

          let endX =
            this.mousePosition[0] + this.defaultOffset[0] > 0 ? this.mousePosition[0] + this.defaultOffset[0] : 0;
          let endY =
            this.mousePosition[1] + this.defaultOffset[1] > 0 ? this.mousePosition[1] + this.defaultOffset[1] : 0;

          endX =
            endX > window.innerWidth - childElementDialog.offsetWidth
              ? window.innerWidth - childElementDialog.offsetWidth
              : endX;
          endY =
            endY > window.innerHeight - childElementDialog.offsetHeight
              ? window.innerHeight - childElementDialog.offsetHeight
              : endY;

          childElementDialog.style.left = endX + 'px';
          childElementDialog.style.top = endY + 'px';
        }
      },
      true
    );
  }

  public onkeydown(event: any) {
    if (event.code === 'Escape') {
      this.visible = false;
    }
  }

  public get isEdge(): boolean {
    return this.sessionService.browser.isEdge;
  }

  public get isFirefox(): boolean {
    return this.sessionService.browser.isFirefox;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public iframeLoaded(): void {
    if (this.isEdge && this.iframeContentValue && !this.contentLoaded) {
      // https://stackoverflow.com/questions/42257549/angular-2-srcdoc-polyfill-doesnt-work
      srcDoc.set(this.iframe.nativeElement, this.iframeContentValue);
      this.contentLoaded = true;
    }

    if (
      this.iframe.nativeElement &&
      this.iframe.nativeElement.contentWindow &&
      this.iframe.nativeElement.contentWindow.document
    ) {
      const iframeContentWindow = this.iframe.nativeElement.contentWindow.document || null;
      iframeContentWindow.addEventListener('keydown', (e: any) => {
        this.onkeydown(e);
      });

      const content = iframeContentWindow.getElementsByClassName('payslip');
      if (content && content.length > 0) {
        content[0].style.cssText = 'margin-top: 1.5rem;';
      }
    }
  }
}
