import { Component, Input, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReportParametersDefaultValue } from 'src/app/shared-components/report-dialog/report-parameters-default-value';
import { environment } from '../../../../environments/environment';
import { FilterDateTimeGrid } from '../../../common/filter-date-time-grid';
import { Global } from '../../../common/global';
import { ICompanyUser, ISalaryStatement, IUserEmployment } from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { DialogService } from '../../../services/dialog.service';
import { DownloadService } from '../../../services/download.service';
import { EmployeeService } from '../../../services/employee.service';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';
import { ReportDialogService } from '../../../shared-components/report-dialog/report-dialog.service';

@Component({
  selector: 'app-payslip',
  templateUrl: './payslip.component.html',
  styleUrls: ['./payslip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PayslipComponent implements OnInit, OnDestroy {
  public get allowEdit() {
    return this.sessionService.role.IsSalaryAdminAndHigher;
  }

  @Input()
  public get employee(): ICompanyUser {
    return this.currentEmployee;
  }
  public set employee(value: ICompanyUser) {
    if (value && this.currentEmployee !== value) {
      this.currentEmployee = value;
      this.gridData = [];
      this.salaryStatementsNoneType = [];

      this.filter = null;
      this.filterClear = true;

      this.initializeData();
    }
  }

  // @Output() public createUserEmploymentClick: EventEmitter<any> = new EventEmitter<any>();

  public reportDialogVisible = false;
  public newEmploymentDialogVisible = false;
  public selectedFirstRow: boolean;
  public showRevertFinalizedDialog = false;
  public selectedSalaryStatement: ISalaryStatement;
  public currentEmployee: ICompanyUser;
  public salaryStatementsNoneType: any[] = [];
  public isHideWarnningColum = false;
  public newTabBlockedDialogVisible = false;
  public groupIconAction: any[] = [];
  public triggerUpdate = false;
  public createNewEmployeeDialogVisible = false;
  public filterClear = false;
  public listClassExcluded = ['EmployeeInfo', 'Footer'];

  private currentEmployeeIdDownload: number;

  // public reportParams = new ReportParametersDefaultValue();

  public get hasUserEmployment(): boolean {
    return (
      this.currentEmployee && this.currentEmployee.UserEmployments && this.currentEmployee.UserEmployments.length > 0
    );
  }
  public get IsPaymentApprover(): boolean {
    return Global.SESSION && Global.SESSION.IsPaymentApprover;
  }
  public get IsReadonly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }
  public get IsWeb(): boolean {
    return !this.sessionService.browser.isHybridApp;
  }
  public get IsAppOrDesktop(): boolean {
    return !this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
  }

  private get IsEmployeePayslipActive(): boolean {
    return this.sessionService.currentState === 'tabs.employee.payslip';
  }
  private get IsSelfServicePayslipActive(): boolean {
    return this.sessionService.currentState === 'tabs.selfservice.payslip';
  }

  public get isFirefox(): boolean {
    return this.sessionService.browser.isFirefox;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public get noCPRMessage(): string {
    return this.employee && this.employee.HasDummyIdentityNumber ? 'Employee.NoValidCPR' : '';
  }

  /* GS-5298
  public get isAddNewEmploymentHide(): boolean {
    return this.employee && this.employee.Gender === null;
  }
*/
  constructor(
    public employeeService: EmployeeService,
    private settingService: SettingService,
    private dataService: DataService,
    private downloadService: DownloadService,
    public renderer: Renderer2,
    public sessionService: SessionService,
    private dialog: DialogService,
    public reportDialogService: ReportDialogService
  ) {}

  public ngOnInit(): void {
    if (this.IsEmployeePayslipActive || this.IsSelfServicePayslipActive) {
      this.settingService.showModuleMessage(this.IsEmployeePayslipActive ? 'Employee.Payslip' : 'SelfService.Payslip');
    }

    if (this.IsAppOrDesktop) {
      const IconHtmloulineAction: any = {};
      IconHtmloulineAction.Icon = 'file-download';
      IconHtmloulineAction.Action = 'iconHtmlouline';
      IconHtmloulineAction.tooltip = 'EmploymentPayrollData.HTMLPopupToolTip';
      IconHtmloulineAction.Active = this.IsAppOrDesktop;
      if (!this.sessionService.browser.isHybridApp) {
        IconHtmloulineAction.isHidden = true;
        IconHtmloulineAction.IconPaysliphide = 'Icon--Payslip--hide';
      } else {
        IconHtmloulineAction.isHidden = false;
      }
      this.groupIconAction.push(IconHtmloulineAction);
    }

    if (this.IsWeb) {
      const IconHtmlAction: any = {};
      IconHtmlAction.Icon = 'ExternalLink';
      IconHtmlAction.Action = 'iconHtml';
      IconHtmlAction.tooltip = 'EmploymentPayrollData.HTMLFileToolTip';
      IconHtmlAction.Active = this.IsWeb;
      this.groupIconAction.push(IconHtmlAction);
    }

    const IconPdfAction: any = {};
    IconPdfAction.Icon = 'Download';
    IconPdfAction.Action = 'iconPdf';
    IconPdfAction.tooltip = 'CompanySalaryBatches.PaySlipPDFTooltip';
    IconPdfAction.Active = true;
    this.groupIconAction.push(IconPdfAction);

    const IconPrintAction: any = {};
    IconPrintAction.Icon = 'file-print';
    IconPrintAction.Action = 'iconPrint';
    IconPrintAction.tooltip = 'CompanySalaryBatches.PaySlipPrintTooltip';
    IconPrintAction.Active = true;
    if (!this.isMobile) {
      this.groupIconAction.push(IconPrintAction);
    }

    //this.initializeData(true);
    this.reportDialogService.reportParams = new ReportParametersDefaultValue();

    this.employeeService.employee
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (employee: ICompanyUser) =>
          (this.reportDialogService.reportParams.employeeId = employee ? employee.Id : undefined)
      );
    this.employeeService.employment
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (employment: IUserEmployment) =>
          (this.reportDialogService.reportParams.employmentId = employment ? employment.Id : undefined)
      );
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onRevert(): void {
    if (!this.selectedFirstRow) {
      return;
    }
    this.showRevertFinalizedDialog = true;
  }

  public createUserEmployment(): void {
    /* GS-5298
    if (this.isAddNewEmploymentHide) {
      return;
    }
    */
    this.newEmploymentDialogVisible = true;
  }

  public onRevertBatchCompleted(): void {
    this.gridData = [];
    this.getSalaryStatements(this.currentEmployee.Id);
  }

  public clickIconAction(event: any): void {
    if (this.sessionService.isGetting || this.sessionService.isSubmitting) {
      return;
    }
    if (event && event.dataItem && event.iconAction) {
      this.currentEmployeeIdDownload = JSON.parse(JSON.stringify(this.currentEmployee.Id));

      this.selectedSalaryStatement = this.salaryStatementsNoneType.find((model: any) => model.Id === event.dataItem.Id);
      switch (event.iconAction) {
        case 'iconHtml': {
          this.downloadSalaryStatementNoneEvent(event.dataItem.Id, false);
          break;
        }
        case 'iconHtmlouline': {
          this.downloadSalaryStatementNoneEvent(event.dataItem.Id, true);
          if (this.sessionService.browser.isHybridApp) {
            window.screen.orientation.unlock();
          }
          break;
        }
        case 'iconPrint': {
          this.downloadSalaryStatementNoneEvent(event.dataItem.Id, false, true);
          if (this.sessionService.browser.isHybridApp) {
            window.screen.orientation.unlock();
          }
          break;
        }
        case 'iconPdf': {
          this.downloadSalaryStatementPDFNoneEvent(event.dataItem.Id);
          break;
        }
        default:
          break;
      }
    }
  }

  public onGridCellDbClick(event: any): void {
    if (event && event.Id && event.event.target.className.indexOf('IconContainer--flex') < 0) {
      this.currentEmployeeIdDownload = JSON.parse(JSON.stringify(this.currentEmployee.Id));
      this.downloadSalaryStatementNoneEvent(event.Id, true);
    }
  }

  public onSelectRow(event: any): void {
    if (event && event.Id) {
      this.selectedSalaryStatement = this.salaryStatementsNoneType.find((model: any) => model.Id === event.Id);
      if (this.salaryStatementsNoneType.length > 0 && this.selectedSalaryStatement) {
        this.selectedFirstRow = this.salaryStatementsNoneType[0].Id === this.selectedSalaryStatement.Id;
      }
    }
  }

  private initializeData(showNavigationMessage: boolean = false): void {
    if (showNavigationMessage) {
      this.settingService.showNavigationMessage();
    }

    if (this.currentEmployee) {
      this.getSalaryStatements(this.currentEmployee.Id);
    }
  }

  private getSalaryStatements(companyUserId: number): void {
    this.dataService
      .SalaryStatements_GetSalaryStatementsByCompanyUser(companyUserId)
      .subscribe((salaryStatements: ISalaryStatement[]) => {
        if (salaryStatements) {
          salaryStatements = salaryStatements.sort(
            (salaryStatement1: ISalaryStatement, salaryStatement2: ISalaryStatement) => {
              const date1: any = salaryStatement1.PeriodTo;
              const date2: any = salaryStatement2.PeriodTo;
              return (
                new Date(date2).getFullYear() - new Date(date1).getFullYear() ||
                salaryStatement2.Id - salaryStatement1.Id
              );
            }
          );
          this.salaryStatementsNoneType = salaryStatements.map((model: ISalaryStatement) => {
            const userEmployment = this.currentEmployee.UserEmployments.find((ue) => model.UserEmploymentId === ue.Id);
            return {
              iconHtmlouline: 'file-download',
              iconHtml: 'ExternalLink',
              iconPdf: 'Download',
              PreliminaryWarning: model.IsFinalized ? '' : 'warning',
              Id: model.Id,
              UserEmployment: userEmployment,
              PeriodFrom: model.PeriodFrom,
              PeriodTo: model.PeriodTo,
              PrimaryIncomeAmount: model.PrimaryIncomeAmount,
              HoursWorked: model.HoursWorked,
              AppliedTaxDeduction: model.AppliedTaxDeduction,
              TaxRate: model.TaxRate,
              PayoutAmount: model.PayoutAmount,
              VacationDaysSpent: model.VacationDaysSpent,
              VacationMoneyEarnedGross: model.VacationMoneyEarnedGross,
              VacationMoneyEarnedNet: model.VacationMoneyEarnedNet,
              IsFinalized: model.IsFinalized,
              PayoutDateDerived: model.PayoutDateDerived
            };
          });
        }
        this.selectedFirstRow = false;
        this.gridData = filterBy(this.salaryStatementsNoneType, this.filter);
        this.triggerUpdate = true;
      });
  }

  public get isAllfinalize(): boolean {
    return this.salaryStatementsNoneType &&
      this.salaryStatementsNoneType.length > 0 &&
      this.salaryStatementsNoneType.filter((model: any) => model.IsFinalized === true).length ===
        this.salaryStatementsNoneType.length
      ? true
      : false;
  }

  private getPayslipReportByToken(salaryStatementId: number, format: string, isPrint?: boolean): void {
    this.dataService.SalaryStatements_GetPayslipDownloadToken(salaryStatementId).subscribe((token: any): void => {
      const languageCode: string = this.sessionService.LanguageCode;

      setTimeout(() => {
        if (isPrint) {
          const fileName = Math.random()
            .toString(36)
            .substring(7);
          this.dataService
            .SalaryStatements_DownloadPayslipByToken(salaryStatementId, token, languageCode, format, fileName)
            .subscribe((data: any) => {
              this.onDownloadSalaryStatementHTMLCompleted(data, true, true);
            });
        } else {
          const downloadUrl = `/api/salarystatements/${salaryStatementId}/token/${token}/lang/${languageCode}/format/${format}/Payslip`;
          const newWindow: Window = window.open(environment.apiUrl + downloadUrl);
          if (!newWindow) {
            this.newTabBlockedDialogVisible = true;
          }
        }
      });
    });
  }

  private onDownloadSalaryStatementHTMLCompleted(data: any, isPopup: boolean, isPrint?: boolean): void {
    if (!isPopup) {
      const newWindow: Window = window.open('about:blank');
      if (newWindow) {
        newWindow.document.write(data);
        newWindow.document.close();
      } else {
        this.newTabBlockedDialogVisible = true;
      }
    } else {
      if (this.currentEmployee.Id === this.currentEmployeeIdDownload || isPrint) {
        this.dialog.showIFrameDialog(data);
      }
      this.dialog.isBindding = false;
    }
  }

  private downloadSalaryStatementNoneEvent(salaryStatementId: number, isPopup?: boolean, isPrint?: boolean): void {
    if (isPopup && this.IsAppOrDesktop) {
      if (!this.dialog.isBindding) {
        const format: string = this.IsWeb ? 'a4phtmlstring' : 'htmlstring';
        this.dialog.isBindding = true;
        this.dataService.SalaryStatements_GetHtml(salaryStatementId, format).subscribe(
          (data: string): void => {
            this.onDownloadSalaryStatementHTMLCompleted(data, isPopup);
          },
          () => {
            this.dialog.isBindding = false;
          }
        );
      }
    } else {
      if (isPrint) {
        this.getPayslipReportByToken(salaryStatementId, 'printa4phtmlstaticstring', isPrint);
      } else {
        this.getPayslipReportByToken(salaryStatementId, 'html');
      }
    }
  }

  private downloadSalaryStatementPDFNoneEvent(salaryStatementId: number): void {
    if (
      this.sessionService.browser.isHybridApp ||
      this.sessionService.browser.isIE ||
      this.sessionService.browser.isEdge
    ) {
      this.dataService.SalaryStatements_GetPdf(salaryStatementId).subscribe((data: string): void => {
        this.downloadService.download('Payslip.pdf', data);
      });
    } else {
      this.getPayslipReportByToken(salaryStatementId, 'a4ppdf');
    }
  }

  public filter: CompositeFilterDescriptor;
  public gridData: any[] = this.salaryStatementsNoneType;
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.selectedSalaryStatement = null;
    this.selectedFirstRow = false;

    if (filter && filter.filters && filter.filters.length > 0) {
      const filterDateTimeGrid = new FilterDateTimeGrid();
      const filtersDate: any = [];
      // Using FilterDateTimeGrid to filter date before filter another field
      this.filter = filterDateTimeGrid.convertFilterOperator(filter, filtersDate, 'PeriodFrom', 'PeriodTo');
      this.gridData = filterDateTimeGrid.doFilterDate(
        this.salaryStatementsNoneType,
        filtersDate,
        'PeriodFrom',
        'PeriodTo'
      );
      this.gridData = filterBy(this.gridData, this.filter);
      if (filtersDate.length > 0) {
        [].push.apply(this.filter.filters, filtersDate);
      }
    } else {
      this.gridData = this.salaryStatementsNoneType;
    }
  }

  public expanded = false;
  public onExpand(event: any): void {
    this.expanded = event;
  }

  public isShowAllEmployment = true;

  public onCheckBoxOnlyEmploymentChanged(event: any) {
    let employementId = -1;
    this.employeeService.selectedEmploymentId.subscribe((id: number) => {
      employementId = id;
    });

    if (event) {
      this.isShowAllEmployment = false;
      this.gridData = this.gridData.filter((item: any) => {
        return item.UserEmployment.Id === employementId;
      });
    } else {
      this.isShowAllEmployment = true;
      this.gridData = this.salaryStatementsNoneType;
    }
  }

  public onEmploymentChanged(): void {
    if (!this.isShowAllEmployment) {
      this.gridData = this.salaryStatementsNoneType;
      let employementId = -1;
      this.employeeService.selectedEmploymentId.subscribe((id: number) => {
        employementId = id;
      });
      this.gridData = this.gridData.filter((item: any) => {
        return item.UserEmployment.Id === employementId;
      });
    }
  }
}
