import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Constants } from '../../../common/constants';
import { Global } from '../../../common/global';
import { ISalaryBatch, ProtectedOperationRequest } from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { SessionService } from '../../../services/session/session.service';
import { CompanySalaryBatchService } from '../company-salary-batches.service';
import { SalaryBatchViewModel } from '../salary-batch-view-model';

@Component({
  selector: 'app-revert-finalized-batch-dialog',
  templateUrl: './revert-finalized-batch-dialog.component.html'
})
export class RevertFinalizedBatchDialogComponent implements OnDestroy {
  public salaryBatch: SalaryBatchViewModel;

  public visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
    }
  }

  public get branding() {
    return environment.branding;
  }

  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public revertBatchCompleted: EventEmitter<void> = new EventEmitter<void>();

  public get translationParams(): any {
    if (this.companySalaryBatchService.hasIntegrationName) {
      return { externalSystem: this.companySalaryBatchService.integrationName };
    }
    return { externalSystem: '' };
  }

  public get isGreenland(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public password: string;
  public passwordDialogVisible = false;
  public currentIntegrationName: string;
  public userName: string;

  constructor(
    public sessionService: SessionService,
    private dataService: DataService,
    public companySalaryBatchService: CompanySalaryBatchService
  ) {
    this.userName = localStorage.getItem('saveLoginUserName');

    this.companySalaryBatchService.selectedSalaryBatch
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: ISalaryBatch | any) => {
        this.salaryBatch = value;
      });
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onDialogAction(action: string): void {
    if (action === 'Understood') {
      this.passwordDialogVisible = true;
    }
  }

  public onPasswwordDialogAction(action: string): void {
    if (action === 'Proceed') {
      const request: ProtectedOperationRequest = { Password: this.password };
      this.dataService.SalaryBatches_RevertSalaryBatch(this.salaryBatch.Id, request).subscribe(() => {
        this.revertBatchCompleted.emit();
        this.visible = false;
      });
    } else {
      this.visible = false;
    }
  }
}
