import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { CompanyService } from 'src/app/services/company.service';
import { SessionService } from 'src/app/services/session/session.service';
import { SettingService } from 'src/app/services/setting.service';
import { SalaryTypeView } from './salary-type-view';
import * as i0 from "@angular/core";
import * as i1 from "../../../../services/api/data.service";
import * as i2 from "../../../../services/api/static-data.service";
import * as i3 from "../../../../services/setting.service";
import * as i4 from "../../../../services/session/session.service";
import * as i5 from "@uirouter/core";
import * as i6 from "../../../../services/broadcast.service";
var CompanySalaryTypeService = /** @class */ (function (_super) {
    tslib_1.__extends(CompanySalaryTypeService, _super);
    function CompanySalaryTypeService(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) {
        var _this = _super.call(this, dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) || this;
        _this.dataService = dataService;
        _this.staticDataService = staticDataService;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.transitionService = transitionService;
        _this.broadcaster = broadcaster;
        _this.isWattingLoading = false;
        _this.ngUnsubscribe = new Subject();
        _this.staticSalaryTypes = [];
        return _this;
    }
    CompanySalaryTypeService.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(CompanySalaryTypeService.prototype, "isLanguageModuleEnable", {
        get: function () {
            return this.sessionService.feature.hasModuleId(5);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryTypeService.prototype, "salaryTypes", {
        get: function () {
            if (!this.salaryTypesSubject) {
                this.salaryTypesSubject = new BehaviorSubject([]);
            }
            return this.salaryTypesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryTypeService.prototype, "salaryTypeCategories", {
        get: function () {
            if (!this.salaryTypeCategoriesSubject) {
                this.loadSalaryTypeCategories();
            }
            return this.salaryTypeCategoriesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CompanySalaryTypeService.prototype.loadSalaryTypes = function () {
        var _this = this;
        if (!this.salaryTypesSubject) {
            this.salaryTypesSubject = new BehaviorSubject([]);
        }
        this.isWattingLoading = true;
        return this.dataService.SalaryTypes_GetRawSalaryTypesTranslated().pipe(takeUntil(this.ngUnsubscribe), tap(function (data) {
            _this.createGridData(data);
            _this.salaryTypesSubject.next(data);
        }));
    };
    CompanySalaryTypeService.prototype.loadSalaryTypeCategories = function () {
        var _this = this;
        if (!this.salaryTypeCategoriesSubject) {
            this.salaryTypeCategoriesSubject = new BehaviorSubject([]);
        }
        this.dataService
            .SalaryTypes_GetAllSalaryTypeCategories()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) {
            _this.salaryTypeCategoriesSubject.next(data);
        });
    };
    CompanySalaryTypeService.prototype.createGridData = function (data) {
        var _this = this;
        var filterSalaryTypes = data.filter(function (type) {
            return !type.BaseSalaryType || (type.BaseSalaryType.IsVisible && type.BaseSalaryType.IsActive);
        });
        var activeSalaryTypes = filterSalaryTypes.filter(function (type) {
            return type.IsActive !== false;
        });
        var inActiveSalaryType = filterSalaryTypes.filter(function (type) {
            return type.IsActive === false;
        });
        var sortedActiveSalaryTypes = activeSalaryTypes.sort(function (a, b) {
            return _this.sortCustomSalaryTypes(a, b);
        });
        var sortedInActiveSalaryTypes = inActiveSalaryType.sort(function (a, b) {
            return _this.sortCustomSalaryTypes(a, b);
        });
        var sortedSalaryTypes = sortedActiveSalaryTypes.concat(sortedInActiveSalaryTypes);
        this.staticSalaryTypes = sortedSalaryTypes.map(function (salaryType) {
            _this.transformSalaryTypeObject(data, salaryType);
            return new SalaryTypeView(salaryType, _this.isLanguageModuleEnable);
        });
    };
    CompanySalaryTypeService.prototype.sortCustomSalaryTypes = function (a, b) {
        var aOrder = a.SortOrder || a.SortOrder === 0
            ? a.SortOrder
            : a.BaseSalaryType && (a.BaseSalaryType.SortOrder || a.BaseSalaryType.SortOrder === 0)
                ? a.BaseSalaryType.SortOrder
                : undefined;
        var bOrder = b.SortOrder || b.SortOrder === 0
            ? b.SortOrder
            : b.BaseSalaryType && (b.BaseSalaryType.SortOrder || b.BaseSalaryType.SortOrder === 0)
                ? b.BaseSalaryType.SortOrder
                : undefined;
        if ((aOrder === undefined && bOrder === undefined) || (aOrder === 0 && bOrder === 0)) {
            return a.Id - b.Id;
        }
        else if (aOrder === undefined && bOrder !== undefined) {
            return -1;
        }
        else if (aOrder !== undefined && bOrder === undefined) {
            return 1;
        }
        return aOrder - bOrder;
    };
    CompanySalaryTypeService.prototype.transformSalaryTypeObject = function (data, salaryType) {
        var existingSalaryType = data.find(function (s) { return s.Id === salaryType.Id; });
        salaryType.SalaryTypeTranslations = salaryType.SalaryTypeTranslations
            ? salaryType.SalaryTypeTranslations
            : existingSalaryType
                ? existingSalaryType.SalaryTypeTranslations
                : undefined;
        salaryType.BaseSalaryType = salaryType.BaseSalaryType
            ? salaryType.BaseSalaryType
            : existingSalaryType
                ? existingSalaryType.BaseSalaryType
                : undefined;
    };
    CompanySalaryTypeService.prototype.initData = function () {
        var _this = this;
        this.staticDataService.moduleCompanyView.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            _this.loadSalaryTypeCategories();
            _this.loadSalaryTypes().subscribe(function () {
                _this.isWattingLoading = false;
            }, function () {
                _this.isWattingLoading = false;
            });
        });
    };
    CompanySalaryTypeService.prototype.serviceDestroy = function () {
        if (this.salaryTypesSubject) {
            this.salaryTypesSubject.next([]);
            this.staticSalaryTypes = [];
        }
    };
    Object.defineProperty(CompanySalaryTypeService.prototype, "allowShortcut", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.configuration.advancedtypes.advancedsalarytypes';
        },
        enumerable: true,
        configurable: true
    });
    CompanySalaryTypeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanySalaryTypeService_Factory() { return new CompanySalaryTypeService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.StaticDataService), i0.ɵɵinject(i3.SettingService), i0.ɵɵinject(i4.SessionService), i0.ɵɵinject(i5.TransitionService), i0.ɵɵinject(i6.BroadcastService)); }, token: CompanySalaryTypeService, providedIn: "root" });
    return CompanySalaryTypeService;
}(CompanyService));
export { CompanySalaryTypeService };
