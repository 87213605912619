import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { PanelBarComponent } from '@progress/kendo-angular-layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NumericTextBoxOptions } from '../../../custom-controls/numeric-edit/numeric-text-box-options';
import { SessionService } from '../../../services/session/session.service';
import { ReportDialogService } from '../../../shared-components/report-dialog/report-dialog.service';
import { CompanyPreferenceService } from '../preference.service';

@Component({
  selector: 'app-preference-mobile',
  templateUrl: './preference-mobile.component.html',
  styleUrls: ['./preference-mobile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PreferenceMobileComponent implements OnDestroy, OnInit {
  constructor(
    private sessionService: SessionService,
    public reportDialogService: ReportDialogService,
    public companyPreferenceService: CompanyPreferenceService
  ) {
    this.companyPreferenceService.reloadPanel.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      if (data) {
        this.startPanel();
      }
    });
  }

  public numericTextBoxOption: NumericTextBoxOptions = { min: 0, step: 1, spinners: false, decimals: 0, format: 'n0' };

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  private childDataCompanyPanel: any[];

  @ViewChild('CompanyPanel', { static: false }) public companyPanelBar: PanelBarComponent;

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit() {}

  private startPanel() {
    setTimeout(() => {
      if (this.companyPanelBar) {
        this.childDataCompanyPanel = (this.companyPanelBar as any).childrenItems;
        if (this.childDataCompanyPanel && this.childDataCompanyPanel.length > 0) {
          this.childDataCompanyPanel[0].selected = true;
          this.childDataCompanyPanel[0].expanded = true;
        }
      }
    });
  }

  public onCompanyPanelChanged(event: any[]): void {
    if (event && event.length > 0) {
      event.forEach((ev: any) => {
        if (ev.expanded) {
          const childs: any = this.childDataCompanyPanel.find((data: any) => data.id === ev.id);
          if (childs && childs.childrenItems && childs.childrenItems.length === 1) {
            childs.childrenItems[0].selected = true;
            childs.childrenItems[0].expanded = true;
          }
        }
      });
    }
  }
}
