import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-company-logo',
  templateUrl: './company-logo.component.html'
})
export class CompanyLogoComponent implements OnInit {
  @Input() public isSingleListcompanis: boolean;
  @Input() public currentCompanyName: string;
  @Input() public Companytooltip: string;
  @Input() public currentCompanyAbbreviation: string;
  @Input() public showCompanyAbbreviation: boolean;
  @Input() public logo: any;
  public logoDemo: any;

  @Output() toggleCompanyDropdown: EventEmitter<any> = new EventEmitter<any>();

  constructor(private sessionService: SessionService) {}

  ngOnInit() {
    if (this.sessionService.feature.IsDemoVersion) {
      this.logoDemo = 'images/demo_tag.png';
    } else {
      this.logoDemo = false;
    }
  }

  public onToggleCompanyDropdown() {
    this.toggleCompanyDropdown.emit();
  }
}
