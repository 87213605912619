import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { LayoutModule } from '@progress/kendo-angular-layout';

import { CustomControlsModule } from '../../custom-controls/custom-controls.module';
import { SharedComponentsModule } from '../../shared-components/shared-components.module';

import { CompanySalaryBatchesComponent } from './company-salary-batches.component';
import { SalaryBatchItemFinalizationComponent } from './components/FinalizationScreen/salary-batch-item-finalization.component';
import { SalaryBatchPayslipDetailsComponent } from './components/payslips/salary-batch-payslip-details.component';
import { SalaryBatchPayslipIconsComponent } from './components/payslips/salary-batch-payslip-icons.component';
import { SalaryBatchPayslipsComponent } from './components/payslips/salary-batch-payslips.component';
import { SalaryBatchDetailsComponent } from './components/salary-batch-details.component';
import { SalaryBatchInfoComponent } from './components/salary-batch-info.component';
import { SalaryBatchOtherErrorGridComponent } from './components/salary-batch-other-error-grid.component';
import { SalaryBatchesComponent } from './components/salary-batches.component';
import { SalaryBatchSalaryTotalsComponent } from './components/totals/salary-batch-salary-totals.component';
import { SalaryBatchSalaryTypesComponent } from './components/totals/salary-batch-salary-types.component';
import { SalaryBatchTimeAndVacationComponent } from './components/totals/salary-batch-time-and-vacation.component';
import { BatchFinalizationStep2DialogComponent } from './dialogs/batch-finalization-step-2-dialog.component';
import { BatchFinalizationStep3DialogComponent } from './dialogs/batch-finalization-step-3-dialog.component';
import { BatchFinalizationStep4DialogComponent } from './dialogs/batch-finalization-step-4-dialog.component';
import { LockSalaryBatchDialogComponent } from './dialogs/lock-salary-batch-dialog.component';
import { RevertFinalizedBatchDialogComponent } from './dialogs/revert-finalized-batch-dialog.component';
import { SalaryBatchErrorsDialogComponent } from './dialogs/salary-batch-errors-dialog.component';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule, LayoutModule, CustomControlsModule, SharedComponentsModule],
  declarations: [
    SalaryBatchPayslipDetailsComponent,
    SalaryBatchPayslipIconsComponent,
    SalaryBatchPayslipsComponent,
    SalaryBatchSalaryTotalsComponent,
    SalaryBatchSalaryTypesComponent,
    SalaryBatchItemFinalizationComponent,
    SalaryBatchTimeAndVacationComponent,
    SalaryBatchDetailsComponent,
    SalaryBatchesComponent,
    SalaryBatchInfoComponent,
    SalaryBatchOtherErrorGridComponent,
    BatchFinalizationStep2DialogComponent,
    BatchFinalizationStep3DialogComponent,
    BatchFinalizationStep4DialogComponent,
    LockSalaryBatchDialogComponent,
    RevertFinalizedBatchDialogComponent,
    SalaryBatchErrorsDialogComponent,
    CompanySalaryBatchesComponent
  ],
  exports: [CompanySalaryBatchesComponent],
  entryComponents: [CompanySalaryBatchesComponent]
})
export class CompanySalaryBatchesModule {}
