/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../custom-controls/grid/grid.componnent.ngfactory";
import * as i2 from "../../../../custom-controls/grid/grid.componnent";
import * as i3 from "@angular/forms";
import * as i4 from "../../../../services/session/session.service";
import * as i5 from "../../../../custom-controls/grid/grid-column.component.ngfactory";
import * as i6 from "../../../../custom-controls/grid/grid-column.component";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./template-list.component";
var styles_TemplateListComponent = [];
var RenderType_TemplateListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TemplateListComponent, data: {} });
export { RenderType_TemplateListComponent as RenderType_TemplateListComponent };
export function View_TemplateListComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { templateList: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "FormElement"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 9, "app-grid", [], [[8, "className", 0]], [[null, "isDisableSelectChange"], [null, "selectedIdChange"], [null, "triggerUpdateChange"], [null, "selectedItemChange"], ["document", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onMoewKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("isDisableSelectChange" === en)) {
        var pd_1 = ((_co.isEditMode = $event) !== false);
        ad = (pd_1 && ad);
    } if (("selectedIdChange" === en)) {
        var pd_2 = ((_co.selectedId = $event) !== false);
        ad = (pd_2 && ad);
    } if (("triggerUpdateChange" === en)) {
        var pd_3 = ((_co.triggerGridRefresh = $event) !== false);
        ad = (pd_3 && ad);
    } if (("selectedItemChange" === en)) {
        var pd_4 = (_co.onSelectionChange($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i1.View_GridComponent_0, i1.RenderType_GridComponent)), i0.ɵdid(3, 4243456, [[1, 4], ["templateList", 4]], 4, i2.GridComponent, [i3.FormBuilder, i0.ChangeDetectorRef, i4.SessionService], { editMode: [0, "editMode"], idColumn: [1, "idColumn"], companyTemplates: [2, "companyTemplates"], isDisableSelect: [3, "isDisableSelect"], selectedId: [4, "selectedId"], data: [5, "data"], triggerUpdate: [6, "triggerUpdate"] }, { selectedIdChange: "selectedIdChange", selectedItemChange: "selectedItemChange", triggerUpdateChange: "triggerUpdateChange" }), i0.ɵqud(603979776, 2, { columns: 1 }), i0.ɵqud(603979776, 3, { actions: 1 }), i0.ɵqud(603979776, 4, { radios: 1 }), i0.ɵqud(603979776, 5, { groupColumns: 1 }), (_l()(), i0.ɵeld(8, 0, null, null, 3, "app-grid-column", [], null, null, null, i5.View_GridColumnComponent_0, i5.RenderType_GridColumnComponent)), i0.ɵdid(9, 49152, [[6, 4], [2, 4]], 1, i6.GridColumnComponent, [], { title: [0, "title"], field: [1, "field"], classField: [2, "classField"], type: [3, "type"] }, null), i0.ɵqud(603979776, 6, { columns: 1 }), i0.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = false; var currVal_2 = "Id"; var currVal_3 = true; var currVal_4 = _co.isEditMode; var currVal_5 = _co.selectedId; var currVal_6 = _co.templates; var currVal_7 = _co.triggerGridRefresh; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 11).transform("CompanyHiringStatus.TemplateGridTitle")); var currVal_9 = "Name"; var currVal_10 = "isActiveFeild"; var currVal_11 = "text"; _ck(_v, 9, 0, currVal_8, currVal_9, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "FormElement-grid Company-templateGrid ", _co.classDisableSelect, ""); _ck(_v, 2, 0, currVal_0); }); }
export function View_TemplateListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-template-list", [], null, null, null, View_TemplateListComponent_0, RenderType_TemplateListComponent)), i0.ɵdid(1, 49152, null, 0, i8.TemplateListComponent, [], null, null)], null, null); }
var TemplateListComponentNgFactory = i0.ɵccf("app-template-list", i8.TemplateListComponent, View_TemplateListComponent_Host_0, { templates: "templates", isEditMode: "isEditMode", selectedId: "selectedId", refreshList: "refreshList" }, { selectedIdChange: "selectedIdChange" }, []);
export { TemplateListComponentNgFactory as TemplateListComponentNgFactory };
