<ng-template #template let-div>
  <span [innerHTML]="div.nativeElement.dataset.title"></span>
</ng-template>

<div class="FormElement-addon FormElement-addonSpace FormElement-cursorHand">
  <div *ngIf="errorIcon">
    <app-icon
      [istooltipHTML]="true"
      [icon]="'ErrorExclamation'"
      [tooltip]="'EmployeeGeneral.TaxCardRedWarning' | translate"
      [tooltipWidth]="340"
      [istooltipHTML]="true"
    ></app-icon>
  </div>

  <div *ngIf="infoIcon">
    <app-icon
      [istooltipHTML]="true"
      [icon]="'Info'"
      [tooltip]="'EmployeeGeneral.TaxCardQuestionWarning' | translate: { time: requestedTime }"
      [tooltipWidth]="340"
      [istooltipHTML]="true"
    ></app-icon>
  </div>

  <div *ngIf="warningIcon">
    <app-icon
      [istooltipHTML]="true"
      [icon]="'Warning'"
      [tooltip]="'EmployeeGeneral.TaxCardYellowWarning' | translate"
      [tooltipWidth]="340"
      [istooltipHTML]="true"
    ></app-icon>
  </div>
</div>
