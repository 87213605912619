<app-dialog
  id="reloginDialog"
  [overlayClose]="false"
  [visible]="visible"
  (action)="onDialogAction($event)"
  [width]="430"
  [isHideWhenSessionExpired]="false"
>
  <div (keydown)="keydown($event)">
    <div class="Modal-content">
      <div class="Modal-header">
        <p innerHTML="{{ 'UnsuccessfulAPI.401' | translate }}"></p>
      </div>
      <div class="FormElement" [ngClass]="{ 'has-error': errorMessage }">
        <div class="FormElement-control">
          <form>
            <input
              id="relogin_username"
              name="relogin_username"
              type="text"
              value="{{ userName }}"
              class="input-no-display"
              autocomplete="username"
            />
            <label class="FormElement-label" for="password">{{ 'Login.Password' | translate }}</label>
            <button class="FormElement-labelHelp" type="button" tabindex="-1" (click)="onResetPassword()">
              {{ 'Login.ForgotPassword' | translate }}
            </button>
            <app-text-edit [(value)]="password" [editMode]="true" [type]="'password'" [autocorrect]="true">
            </app-text-edit>
          </form>
        </div>
        <div class="FormElement-feedback">{{ errorMessage }}</div>
      </div>
    </div>
  </div>

  <app-dialog-action type="Logout" [close]="false"></app-dialog-action>
  <app-dialog-action [disabled]="isNullPassWord" type="Continue" [close]="false"></app-dialog-action>
</app-dialog>

<app-reset-password-dialog class="resetpasswordModalContent" [(isVisible)]="showResetPasswordDialog" [isReLogin]="true">
</app-reset-password-dialog>

<app-change-temporary-password-dialog
  [userName]="userName"
  [(isVisible)]="changeTemporaryPasswordVisible"
  (temporaryPasswordChanged)="onTemporaryPasswordChanged($event)"
  (notChangeTempPassword)="onNotChangeTempPassword()"
></app-change-temporary-password-dialog>
