import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from 'src/app/common/global';
import { ReportDialogService } from 'src/app/shared-components/report-dialog/report-dialog.service';
import {
  IModuleCompanyView,
  ISalaryType,
  ISalaryTypeCategoryView,
  ISalaryTypeView,
  ITimeEntryType,
  ITimeEntryTypeTranslation,
  TimeEntryType
} from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';
import { CompanyAdvancedTypesService } from './company-advanced-types.service';
import { SalaryTypesSingleComponent } from './salary-types-single/salary-types-single.component';
import { SalaryTypeView } from './salary-types/salary-type-view';
import { TimeEntryTypesSingleComponent } from './time-entry-types-single/time-entry-types-single.component';
import { TimeEntryTypeView } from './time-entry-types/time-entry-type-view';

@Component({
  selector: 'app-advanced-types',
  styleUrls: ['./advanced-types.component.scss'],
  templateUrl: './advanced-types.component.html'
})
export class AdvancedTypesComponent implements OnInit, OnDestroy {
  public externalReferences: any[] = [];
  public currentLangId: number;
  public isWattingLoading = false;
  public hasSalaryTypeModule = false;
  public hasTimeEntryModule = false;
  @ViewChild('salaryTypesSingleComponent', { static: false }) public salarySingleComponent: SalaryTypesSingleComponent;
  @ViewChild('timeEntryTypesSingleComponent', { static: false })
  public timeEntrySingleComponent: TimeEntryTypesSingleComponent;

  public salaryType: any = {
    id: 1,
    value: 'Salary',
    label: 'CompanyTimeEntryTypes.SalaryTypeSingle'
  };
  public salaryTypeOverview: any = {
    id: 3,
    value: 'SalaryOverview',
    label: 'CompanyTimeEntryTypes.SalaryTypeOverview'
  };
  public timeEntryType: any = {
    id: 2,
    value: 'TimeEntry',
    label: 'CompanyTimeEntryTypes.TimeEntryTypeSingle'
  };
  public timeEntryTypeOverview: any = {
    id: 4,
    value: 'TimeEntryOverview',
    label: 'CompanyTimeEntryTypes.TimeEntryTypeOverview'
  };
  public salaryTypes: ISalaryType[] = [];
  public salaryTypeViews: ISalaryTypeView[] = [];
  public selectedTimeEntryType: TimeEntryTypeView;
  public SalaryTypeCategories: ISalaryTypeCategoryView[];
  public TimeEntryTypes: ITimeEntryType[];
  private createFromScratch: ITimeEntryType = new TimeEntryType();
  public inheritFrom = 'baseSalaryName';
  public currentModules: IModuleCompanyView[] = [];
  public gridData: any[] = [];
  public filter: CompositeFilterDescriptor;
  public gridFilterData: any[] = this.gridData;

  private selectedType = 'Salary';
  public get selectedTypeValue(): string {
    return this.selectedType;
  }

  public set selectedTypeValue(value: string) {
    if (value !== this.selectedType) {
      this.selectedType = value;
    }
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public get companyAdvancedSalaryTypesStateActive(): boolean {
    return this.sessionService.currentState === 'tabs.company.configuration.advancedtypes.advancedsalarytypes';
  }

  public get companyTimeEntryTypesStateActive(): boolean {
    return this.sessionService.currentState === 'tabs.company.configuration.advancedtypes.timeentrytypes';
  }

  constructor(
    private dataService: DataService,
    private staticDataService: StaticDataService,
    private renderer: Renderer2,
    public sessionService: SessionService,
    public settingService: SettingService,
    public reportDialogService: ReportDialogService,
    public companyAdvancedTypesService: CompanyAdvancedTypesService
  ) {}

  public ngOnInit(): void {
    this.renderer.listen(window, 'click', (event: any) => {
      if (!this.isCollapseButtonClick) {
        this.menuOpen = false;
      } else {
        this.isCollapseButtonClick = false;
      }
    });
    this.settingService.showModuleMessage('company.advancedsalarytypes');
    if (this.isMobile) {
      this.sessionService.isShowHugeFeaturesAlert = true;
    }

    this.dataService.SalaryTypes_GetAllSalaryTypeCategories().subscribe((data: ISalaryTypeCategoryView[]) => {
      this.SalaryTypeCategories = data;
    });

    this.createFromScratch.Name = '<Create From Scratch>';
    this.createFromScratch.Id = -1;
    this.dataService.TimeEntryTypes_GetDefaultTimeEntryTypes().subscribe((data: ITimeEntryType[]) => {
      this.TimeEntryTypes = data;
      this.TimeEntryTypes.unshift(this.createFromScratch);
    });

    this.onLoadNavigate(this.sessionService.currentState);

    this.staticDataService.moduleCompanyView
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((modules: IModuleCompanyView[]) => {
        if (modules && modules.length > 0 && this.currentModules.length === 0) {
          this.currentModules = modules;
          this.checkModule();
        }
      });

    setTimeout(() => {
      this.reloadGridData();
    });
  }

  public hasModuleSalary = false;
  public hasModuleTimeEntry = false;
  private checkModule() {
    const moduleSalary: IModuleCompanyView =
      this.currentModules && this.currentModules.length
        ? this.currentModules.find((m: IModuleCompanyView) => m.ModuleId === 4)
        : undefined;
    if (moduleSalary) {
      this.hasModuleSalary = moduleSalary.IsEnabled;
    } else {
      this.hasModuleSalary = false;
    }

    const moduleTimeEntry: IModuleCompanyView =
      this.currentModules && this.currentModules.length
        ? this.currentModules.find((m: IModuleCompanyView) => m.ModuleId === 8)
        : undefined;
    if (moduleTimeEntry) {
      this.hasModuleTimeEntry = moduleTimeEntry.IsEnabled;
    } else {
      this.hasModuleTimeEntry = false;
    }
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.menuOpen = false;
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get isEditable() {
    return (
      ((this.selectedType === 'Salary' && this.hasModuleSalary) ||
        (this.selectedType === 'TimeEntry' && this.hasModuleTimeEntry)) &&
      !this.sessionService.role.IsReadOnly
    );
  }

  public onDoubleClick(): void {
    if (!this.isEditable) {
      return;
    }
    if (!this.sessionService.isGetting && !this.sessionService.isSubmitting) {
      this.onEditClick(true);
    }
  }

  private onLoadNavigate(state: string) {
    const type = state.split('.advancedtypes.')[1];
    switch (type) {
      case 'salarytypessingle':
        this.selectedType = 'Salary';
        this.navigateTo('tabs.company.configuration.advancedtypes.salarytypessingle', 1);
        break;
      case 'advancedsalarytypes':
        this.selectedType = null;
        this.navigateTo('tabs.company.configuration.advancedtypes.advancedsalarytypes', 3);
        break;
      case 'timeentrytypessingle':
        this.selectedType = 'TimeEntry';
        this.navigateTo('tabs.company.configuration.advancedtypes.timeentrytypessingle', 2);
        break;
      case 'timeentrytypes':
        this.selectedType = null;
        this.navigateTo('tabs.company.configuration.advancedtypes.timeentrytypes', 4);
        break;
    }
  }

  public loadTimeEntryTypes(event?: any, isNotLoadDefault?: boolean): void {
    if (event) {
      this.selectedTimeEntryType.TimeEntryTypeTranslations = event;
    }
    this.currentLangId = Global.SESSION.SignOnToken.LanguageId;
    this.gridData = [];
    this.gridFilterData = [];
    if (this.companyAdvancedTypesService.isLanguageModuleEnable) {
      this.dataService.TimeEntryTypes_GetRawTimeEntryTypesTranslated().subscribe(
        (timeEntryTypes: ITimeEntryType[]) => {
          this.createGridTimeEmtryData(timeEntryTypes);
          if (!isNotLoadDefault) {
            this.setDefaultType();
          } else {
            this.reSelectItem();
          }
        },
        () => {
          this.isWattingLoading = false;
        }
      );
    } else {
      this.dataService.TimeEntryTypes_GetRawTimeEntryTypes().subscribe(
        (timeEntryTypes: ITimeEntryType[]) => {
          this.createGridTimeEmtryData(timeEntryTypes);
          if (!isNotLoadDefault) {
            this.setDefaultType();
          } else {
            this.reSelectItem();
          }
        },
        () => {
          this.isWattingLoading = false;
        }
      );
    }
  }

  private createGridTimeEmtryData(timeEntryTypes: ITimeEntryType[]): void {
    const filterTimeEntryTypes: ITimeEntryType[] = timeEntryTypes.filter(
      (type: ITimeEntryType) => !type.BaseTimeEntryType || type.BaseTimeEntryType.IsActive || type.IsActive
    );
    const activeTimeEntryTypes: ITimeEntryType[] = filterTimeEntryTypes.filter(
      (type: ITimeEntryType) => type.IsActive !== false
    );
    const inActiveTimeEntryType: ITimeEntryType[] = filterTimeEntryTypes.filter(
      (type: ITimeEntryType) => type.IsActive === false
    );
    const sortedActiveTimeEntryTypes: ITimeEntryType[] = activeTimeEntryTypes.sort(
      (a: ITimeEntryType, b: ITimeEntryType) => this.sortCustomTimeEntryTypes(a, b)
    );
    const sortedInActiveTimeEntryTypes: ITimeEntryType[] = inActiveTimeEntryType.sort(
      (a: ITimeEntryType, b: ITimeEntryType) => this.sortCustomTimeEntryTypes(a, b)
    );
    const sortedTimeEntryTypes: ITimeEntryType[] = sortedActiveTimeEntryTypes.concat(sortedInActiveTimeEntryTypes);
    this.gridData = sortedTimeEntryTypes.map(
      (d: ITimeEntryType) => new TimeEntryTypeView(d, this.companyAdvancedTypesService.isLanguageModuleEnable)
    );
    this.gridData.forEach((model: any) => {
      this.processGridItemObject(model);
    });
    this.convertExternalRefCodeToFriendlyName();
    this.gridFilterData = filterBy(this.gridData, this.filter);
    this.isWattingLoading = false;
  }

  private getSalaryTypes(isNotLoadDefault?: boolean): void {
    this.gridData = [];
    this.dataService.SalaryTypes_GetRawSalaryTypesTranslated().subscribe(
      (data: ISalaryType[]) => {
        this.createGridSalaryData(data);
        if (!isNotLoadDefault) {
          this.setDefaultType();
        } else {
          this.reSelectItem();
        }
      },
      () => {
        this.isWattingLoading = false;
      }
    );
  }

  private setDefaultType() {
    setTimeout(() => {
      if (this.defaultSelectedType) {
        const selectedType: any = this.gridData.find((data: any) => {
          return data.Id === this.defaultSelectedType.Id;
        });
        this.companyAdvancedTypesService.selectedType = selectedType;
        this.companyAdvancedTypesService.selectedId = selectedType.Id;
        this.defaultSelectedType = null;
      } else if (this.gridData && this.gridData.length > 0) {
        this.companyAdvancedTypesService.selectedType = this.gridData[0];
        this.companyAdvancedTypesService.selectedId = this.gridData[0].Id;
      } else {
        this.companyAdvancedTypesService.selectedType = this.companyAdvancedTypesService.defaultSelectedType;
      }
    });
  }

  private reSelectItem(): void {
    const selectedId = this.companyAdvancedTypesService.selectedType.Id;
    this.gridData.forEach((item: any) => {
      if (selectedId === item.Id) {
        this.companyAdvancedTypesService.selectedType = item;
        return;
      }
    });
  }

  private createGridSalaryData(data: ISalaryType[]): void {
    this.salaryTypes = data;
    const filterSalaryTypes: ISalaryType[] = data.filter((type: ISalaryType) => {
      return (
        !type.BaseSalaryType ||
        (type.IsVisible && type.IsActive) ||
        (type.BaseSalaryType.IsVisible && type.BaseSalaryType.IsActive)
      );
    });
    const activeSalaryTypes: ISalaryType[] = filterSalaryTypes.filter((type: ISalaryType) => {
      return type.IsActive !== false;
    });
    const inActiveSalaryType: ISalaryType[] = filterSalaryTypes.filter((type: ISalaryType) => {
      return type.IsActive === false;
    });
    const sortedActiveSalaryTypes: ISalaryType[] = activeSalaryTypes.sort((a: ISalaryType, b: ISalaryType) => {
      return this.sortCustomSalaryTypes(a, b);
    });
    const sortedInActiveSalaryTypes: ISalaryType[] = inActiveSalaryType.sort((a: ISalaryType, b: ISalaryType) => {
      return this.sortCustomSalaryTypes(a, b);
    });
    const sortedSalaryTypes: ISalaryType[] = sortedActiveSalaryTypes.concat(sortedInActiveSalaryTypes);
    this.gridData = sortedSalaryTypes.map((salaryType: ISalaryType) => {
      this.transformSalaryTypeObject(salaryType);
      return new SalaryTypeView(salaryType, this.companyAdvancedTypesService.isLanguageModuleEnable);
    });
    this.gridFilterData = filterBy(this.gridData, this.filter);
    this.convertExternalRefCodeToFriendlyName();
    this.isWattingLoading = false;
  }

  private sortCustomSalaryTypes(a: ISalaryType, b: ISalaryType): number {
    const aOrder: number =
      a.SortOrder || a.SortOrder === 0
        ? a.SortOrder
        : a.BaseSalaryType && (a.BaseSalaryType.SortOrder || a.BaseSalaryType.SortOrder === 0)
        ? a.BaseSalaryType.SortOrder
        : undefined;
    const bOrder: number =
      b.SortOrder || b.SortOrder === 0
        ? b.SortOrder
        : b.BaseSalaryType && (b.BaseSalaryType.SortOrder || b.BaseSalaryType.SortOrder === 0)
        ? b.BaseSalaryType.SortOrder
        : undefined;
    if ((aOrder === undefined && bOrder === undefined) || (aOrder === 0 && bOrder === 0)) {
      return a.Id - b.Id;
    } else if (aOrder === undefined && bOrder !== undefined) {
      return -1;
    } else if (aOrder !== undefined && bOrder === undefined) {
      return 1;
    }

    return aOrder - bOrder;
  }

  private sortCustomTimeEntryTypes(a: any, b: any): number {
    const aOrder: number =
      a.SortOrder || a.SortOrder === 0
        ? a.SortOrder
        : a.BaseTimeEntryType && (a.BaseTimeEntryType.SortOrder || a.BaseTimeEntryType.SortOrder === 0)
        ? a.BaseTimeEntryType.SortOrder
        : undefined;
    const bOrder: number =
      b.SortOrder || b.SortOrder === 0
        ? b.SortOrder
        : b.BaseTimeEntryType && (b.BaseTimeEntryType.SortOrder || b.BaseTimeEntryType.SortOrder === 0)
        ? b.BaseTimeEntryType.SortOrder
        : undefined;
    if ((aOrder === undefined && bOrder === undefined) || (aOrder === 0 && bOrder === 0)) {
      return a.Id - b.Id;
    } else if (aOrder === undefined && bOrder !== undefined) {
      return -1;
    } else if (aOrder !== undefined && bOrder === undefined) {
      return 1;
    }

    return aOrder - bOrder;
  }

  private convertExternalRefCodeToFriendlyName(): void {
    if (this.gridData && this.gridData.length > 0 && this.externalReferences && this.externalReferences.length > 0) {
      this.gridData.forEach((salaryType: any) => {
        salaryType.ExternalReference = this.getExteralReferenceCode(salaryType.ExternalReference, true);
      });
      this.gridFilterData = filterBy(this.gridData, this.filter);
    }
  }

  private getExteralReferenceCode(refCode: string, fullName?: boolean): any {
    if (!refCode) {
      return null;
    }

    const externalRefCode: any = this.externalReferences
      ? this.externalReferences.find((item: any) => {
          return refCode === item.AccountNumber || refCode === item.FriendlyName;
        })
      : null;

    if (externalRefCode) {
      return !fullName ? externalRefCode.AccountNumber : externalRefCode.FriendlyName;
    }

    return refCode;
  }

  private transformSalaryTypeObject(salaryType: ISalaryType): void {
    const existingSalaryType: ISalaryType = this.salaryTypes.find((s: ISalaryType) => s.Id === salaryType.Id);
    salaryType.SalaryTypeTranslations = salaryType.SalaryTypeTranslations
      ? salaryType.SalaryTypeTranslations
      : existingSalaryType
      ? existingSalaryType.SalaryTypeTranslations
      : undefined;
    salaryType.BaseSalaryType = salaryType.BaseSalaryType
      ? salaryType.BaseSalaryType
      : existingSalaryType
      ? existingSalaryType.BaseSalaryType
      : undefined;
  }

  private translatetooltipsalarytype = '';
  private processGridItemObject(model: any): void {
    const existingTimeEntryType: any =
      this.gridData && model ? this.gridData.find((s: ITimeEntryType) => s.Id === model.Id) : undefined;
    model.TimeEntryTypeTranslations = model.TimeEntryTypeTranslations
      ? model.TimeEntryTypeTranslations
      : existingTimeEntryType
      ? existingTimeEntryType.TimeEntryTypeTranslations
      : undefined;
    model.BaseTimeEntryType = model.BaseTimeEntryType
      ? model.BaseTimeEntryType
      : existingTimeEntryType
      ? existingTimeEntryType.BaseTimeEntryType
      : undefined;

    if (model.TimeEntryTypeConfigurations) {
      if (model.TimeEntryTypeConfigurations.length > 0) {
        model.customSalaryTypeClass = 'grid-disable-cell';
        model.editSalaryType = false;
        model.ToolTipSalaryType = this.translatetooltipsalarytype;
        model.SalaryTypeId = null;
      } else {
        model.customSalaryTypeClass = '';
        model.editSalaryType = !this.IsReadOnly;
        model.ToolTipSalaryType = '';
      }
    } else {
      model.customSalaryTypeClass = '';
      model.editSalaryType = !this.IsReadOnly;
      model.ToolTipSalaryType = '';
    }
  }

  public salaryTypeVisible = true;
  public timeEntryTypeVisible = false;
  public salaryTypeOverviewVisible = false;
  public timeEntryTypeOverviewVisible = false;
  public onAdvancedTypeChange(type: any = 1, isKeepSelected?: boolean) {
    if (this.companyAdvancedTypesService.editMode) {
      this.onConfirmAndDiscardChanges(type);
    } else {
      setTimeout(() => {
        this.changeTab();
      });
    }
  }

  private changeTab(): void {
    const currentState = this.sessionService.currentState.split('tabs.company.configuration.')[1];
    switch (currentState) {
      case 'advancedtypes':
      case 'advancedtypes.salarytypessingle':
        this.setActiveState(1);
        this.selectedTypeValue = 'Salary';
        this.inheritFrom = 'baseSalaryName';
        break;
      case 'advancedtypes.timeentrytypessingle':
        this.setActiveState(2);
        this.selectedTypeValue = 'TimeEntry';
        this.inheritFrom = 'inheritsFrom';
        break;
      case 'advancedtypes.advancedsalarytypes':
        this.setActiveState(3);
        this.selectedTypeValue = 'SalaryOverview';
        break;
      case 'advancedtypes.timeentrytypes':
        this.setActiveState(4);
        this.selectedTypeValue = 'TimeEntryOverview';
        break;
    }

    this.companyAdvancedTypesService.selectedType = this.companyAdvancedTypesService.defaultSelectedType;
    this.reloadGridData();
  }

  private setActiveState(type: number) {
    this.salaryTypeVisible = false;
    this.timeEntryTypeVisible = false;
    this.salaryTypeOverviewVisible = false;
    this.timeEntryTypeOverviewVisible = false;

    switch (type) {
      case 1:
        this.salaryTypeVisible = true;
        break;
      case 2:
        this.timeEntryTypeVisible = true;
        break;
      case 3:
        this.salaryTypeOverviewVisible = true;
        break;
      case 4:
        this.timeEntryTypeOverviewVisible = true;
        break;
    }
  }

  private reloadGridData(isNotReloadSelected?: boolean) {
    if (this.selectedType === 'Salary') {
      this.isWattingLoading = true;
      this.staticDataService.moduleCompanyView.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        this.getSalaryTypes(isNotReloadSelected);
      });
    } else if (this.selectedType === 'TimeEntry') {
      this.isWattingLoading = true;
      this.dataService.SalaryTypes_GetSalaryTypeViews().subscribe((data: ISalaryTypeView[]) => {
        this.salaryTypeViews = data;
        this.staticDataService.moduleCompanyView.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this.loadTimeEntryTypes(null, isNotReloadSelected);
        });
      });
    }
  }

  public newSalaryTypeDialogVisible = false;
  public newCustomTimeEntryDialogVisible = false;

  public onAddClick(): void {
    if (this.selectedType === 'Salary') {
      this.newSalaryTypeDialogVisible = true;
    } else {
      this.newCustomTimeEntryDialogVisible = true;
    }
  }
  public defaultSelectedType: any = null;
  public onAddSalaryType(value: ISalaryType): void {
    this.isWattingLoading = true;
    this.salaryTypes.push(value);
    this.transformSalaryTypeObject(value);
    this.gridData.push(new SalaryTypeView(value, this.companyAdvancedTypesService.isLanguageModuleEnable));
    this.gridFilterData = filterBy(this.gridData, this.filter);
    this.defaultSelectedType = value;
    this.reloadGridData();
  }

  public addNewTimeEntryType(timeEntryType: ITimeEntryType): void {
    this.isWattingLoading = true;
    const value: TimeEntryTypeView = new TimeEntryTypeView(
      timeEntryType,
      this.companyAdvancedTypesService.isLanguageModuleEnable
    );
    this.processGridItemObject(value);
    this.gridData.push(value);
    this.selectedTimeEntryType = value;
    this.gridFilterData = filterBy(this.gridData, this.filter);
    this.defaultSelectedType = value;
    this.reloadGridData();
  }

  public onEditClick($event: boolean): void {
    if ($event !== undefined && $event === false) {
      this.companyAdvancedTypesService.editMode = $event;
      if (this.companyAdvancedTypesService.listRevertValues.length > 0) {
        this.revertInheritValue();
      }
      this.companyAdvancedTypesService.isSave = true;
      this.companyAdvancedTypesService.addInheritValueAfterEdit(this.selectedType);
      if (this.companyAdvancedTypesService.tabDirty) {
        this.save();
      }
    } else {
      setTimeout(() => {
        if (this.selectedType === 'Salary') {
          this.salarySingleComponent.resetDirty();
        } else if (this.selectedType === 'TimeEntry') {
          this.timeEntrySingleComponent.resetDirty();
        }
      });
      this.companyAdvancedTypesService.editMode = $event;
      this.companyAdvancedTypesService.removeInheritValueBeforeEdit(this.selectedType);
    }
  }

  public save() {
    if (this.selectedType === 'Salary') {
      this.salaryTypeSaveChanges();
    } else if (this.selectedType === 'TimeEntry') {
      this.timeEntrySaveChanges();
    }
  }

  public salaryTypeSaveChanges(): void {
    const dataItem: SalaryTypeView = new SalaryTypeView(
      this.companyAdvancedTypesService.selectedType,
      this.companyAdvancedTypesService.isLanguageModuleEnable
    );
    if (!dataItem.Id) {
      return;
    }
    dataItem.ExternalReference = this.getExteralReferenceCode(dataItem.ExternalReference, true);

    const salaryType: any = dataItem.toSalaryType();
    this.companyAdvancedTypesService.checkSalaryType(salaryType);
    salaryType.ExternalReference = this.getExteralReferenceCode(salaryType.ExternalReference) || null;
    this.dataService.SalaryTypes_UpdateSalaryType(salaryType).subscribe(
      (data: ISalaryType) => {
        data.ExternalReference = this.getExteralReferenceCode(data.ExternalReference, true);
        this.transformSalaryTypeObject(data);
        Object.assign(dataItem, new SalaryTypeView(data, this.companyAdvancedTypesService.isLanguageModuleEnable));
        this.companyAdvancedTypesService.selectedType = dataItem;
        this.reloadGridData(true);
      },
      (error: any) => {
        this.getSalaryTypes(true);
      }
    );
  }

  public timeEntrySaveChanges(): void {
    const dataItem: TimeEntryTypeView = new TimeEntryTypeView(
      this.companyAdvancedTypesService.selectedType,
      this.companyAdvancedTypesService.isLanguageModuleEnable
    );
    if (!dataItem.Id) {
      return;
    }
    dataItem.ExternalReference = this.getExteralReferenceCode(dataItem.ExternalReference, true);

    const timeEntryType: ITimeEntryType = dataItem.toTimeEntryType();
    timeEntryType.ExternalReference = this.getExteralReferenceCode(timeEntryType.ExternalReference);
    this.ConvertCorrectSubmitData(timeEntryType);
    this.dataService.TimeEntryTypes_UpdateTimeEntryType(timeEntryType).subscribe(
      (response: ITimeEntryType) => {
        response.ExternalReference = this.getExteralReferenceCode(response.ExternalReference, true);
        this.processGridItemObject(response);
        Object.assign(
          dataItem,
          new TimeEntryTypeView(response, this.companyAdvancedTypesService.isLanguageModuleEnable)
        );
        const timeEntryTypeConfigurations = this.companyAdvancedTypesService.selectedType.TimeEntryTypeConfigurations;
        this.companyAdvancedTypesService.selectedType = dataItem;
        this.companyAdvancedTypesService.selectedType.TimeEntryTypeConfigurations = timeEntryTypeConfigurations;
        this.reloadGridData(true);
      },
      (error: any) => {
        this.loadTimeEntryTypes(null, true);
      }
    );
  }

  private ConvertCorrectSubmitData(model: any): void {
    const classCheck = 'inherit-field';
    model.IsActive =
      model.isActiveClass === classCheck && model.IsActive === model.BaseTimeEntryType.IsActive ? null : model.IsActive;
    model.ExternalReference =
      model.externalReferenceClass === classCheck &&
      model.ExternalReference === model.BaseTimeEntryType.ExternalReference
        ? null
        : model.ExternalReference;
    model.SortOrder =
      model.sortOrderClass === classCheck && model.SortOrder === model.BaseTimeEntryType.SortOrder
        ? null
        : model.SortOrder;
    model.UnitTypeId =
      model.unitTypeClass === classCheck && model.UnitTypeId === model.BaseTimeEntryType.UnitTypeId
        ? null
        : model.UnitTypeId;
    model.AllowEditUnitType =
      model.allowEditUnitTypeClass === classCheck &&
      model.AllowEditUnitType === model.BaseTimeEntryType.AllowEditUnitType
        ? null
        : model.AllowEditUnitType;
    model.IsIllness =
      model.isIllnessClass === classCheck && model.IsIllness === model.BaseTimeEntryType.IsIllness
        ? null
        : model.IsIllness;
    model.SalaryTypeId =
      model.salaryTypeClass === classCheck && model.SalaryTypeId === model.BaseTimeEntryType.SalaryTypeId
        ? null
        : model.SalaryTypeId;
    if (!this.companyAdvancedTypesService.isLanguageModuleEnable) {
      model.Name = model.nameClass === classCheck && model.Name === model.BaseTimeEntryType.Name ? null : model.Name;
      model.Description =
        model.descriptionClass === classCheck && model.Description === model.BaseTimeEntryType.Description
          ? null
          : model.Description;
      model.DefaultPayslipText =
        model.defaultPayslipTextClass === classCheck &&
        model.DefaultPayslipText === model.BaseTimeEntryType.DefaultPayslipText
          ? null
          : model.DefaultPayslipText;
    } else {
      let bseModelLanguge: any = {};
      let modelLanguge: any = {};

      if (
        model.BaseTimeEntryType &&
        model.BaseTimeEntryType.TimeEntryTypeTranslations &&
        model.BaseTimeEntryType.TimeEntryTypeTranslations.length > 0
      ) {
        bseModelLanguge = model.BaseTimeEntryType.TimeEntryTypeTranslations.find(
          (t: ITimeEntryTypeTranslation) => t.LanguageId === Global.SESSION.SignOnToken.LanguageId
        );
      } else {
        bseModelLanguge = {
          Name: null,
          Description: null,
          DefaultPayslipText: null
        };
      }

      if (model.TimeEntryTypeTranslations && model.TimeEntryTypeTranslations.length > 0) {
        modelLanguge = model.TimeEntryTypeTranslations.find(
          (t: ITimeEntryTypeTranslation) => t.LanguageId === Global.SESSION.SignOnToken.LanguageId
        );
      } else {
        modelLanguge = {
          Name: null,
          Description: null,
          DefaultPayslipText: null
        };
      }

      let isModelBaseTimeEntryTypeNull = false;

      if (!model.BaseTimeEntryType) {
        model.BaseTimeEntryType = {
          Name: null,
          Description: null,
          DefaultPayslipText: null
        };

        isModelBaseTimeEntryTypeNull = true;
      }

      model.Name =
        model.nameClass === classCheck &&
        (model.Name === bseModelLanguge.Name ||
          model.Name === modelLanguge.Name ||
          model.Name === model.BaseTimeEntryType.Name)
          ? null
          : model.Name;

      model.Description =
        model.descriptionClass === classCheck &&
        (model.Description === bseModelLanguge.Description ||
          model.Description === modelLanguge.Description ||
          model.Description === model.BaseTimeEntryType.Description)
          ? null
          : model.Description;

      model.DefaultPayslipText =
        model.defaultPayslipTextClass === classCheck &&
        (model.DefaultPayslipText === bseModelLanguge.DefaultPayslipText ||
          model.DefaultPayslipText === modelLanguge.DefaultPayslipText ||
          model.DefaultPayslipText === model.BaseTimeEntryType.DefaultPayslipText)
          ? null
          : model.DefaultPayslipText;

      model.BaseTimeEntryType = isModelBaseTimeEntryTypeNull ? null : model.BaseTimeEntryType;
    }
  }

  public discardChangesDialogVisible = false;
  public onConfirmAndDiscardChanges(isDiscardChange?: number): void {
    if (isDiscardChange) {
      this.discardChangesDialogVisible = true;
      return;
    }

    if (!this.companyAdvancedTypesService.tabDirty) {
      this.companyAdvancedTypesService.tabDirty = false;
      this.companyAdvancedTypesService.editMode = false;
      this.companyAdvancedTypesService.isSave = false;
      this.companyAdvancedTypesService.addInheritValueAfterEdit(this.selectedType);
      return;
    }

    this.discardChangesDialogVisible = true;
  }

  public onDiscardChangesDialogAction(action: string): void {
    if (action === 'ConfirmDiscard') {
      this.companyAdvancedTypesService.editMode = false;
      this.companyAdvancedTypesService.tabDirty = false;
      this.reloadGridData(true);
    }
  }

  public timeEntryTypeConfigurationDialogVisible = false;
  public onTranslationConfigurationVisibleChange(change: boolean): void {
    if (change === true) {
      if (this.selectedType === 'Salary') {
        this.getSalaryTypes(true);
      } else if (this.selectedType === 'TimeEntry') {
        this.loadTimeEntryTypes(null, true);
      }
    }
  }
  public translationDialogSTVisible = false;
  public translationDialogTEVisible = false;
  public onShowTranslationDialog() {
    if (this.selectedType === 'Salary') {
      this.translationDialogSTVisible = true;
    } else if (this.selectedType === 'TimeEntry') {
      this.translationDialogTEVisible = true;
    }
  }

  public menuOpen = false;
  private isCollapseButtonClick = false;
  public onToggleNavigation(): void {
    this.isCollapseButtonClick = true;
    this.menuOpen = !this.menuOpen;
  }

  public navigateTo(state: string, index: number): void {
    this.menuOpen = false;
    this.onAdvancedTypeChange(index);
    this.sessionService.NavigateTo(state);
  }

  public revertInheritValue() {
    this.companyAdvancedTypesService.listRevertValues.forEach((element: any) => {
      this.companyAdvancedTypesService.selectedType[element.field] = null;
      if (this.selectedType === 'Salary') {
        this.salarySingleComponent.childIsDirty[element.index] = false;
        this.companyAdvancedTypesService.tabDirty = this.salarySingleComponent.childIsDirty.indexOf(true) >= 0;
      } else if (this.selectedType === 'TimeEntry') {
        this.timeEntrySingleComponent.childIsDirty[element.index] = false;
        this.companyAdvancedTypesService.tabDirty = this.timeEntrySingleComponent.childIsDirty.indexOf(true) >= 0;
      }
    });
  }
}
