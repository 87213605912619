import { Component, OnDestroy, OnInit } from '@angular/core';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GridCellClickEvent } from 'src/app/custom-controls/grid/cell-click-event';
import { PriceFormatPipe } from 'src/app/custom-controls/pipes/price-format.pipe';
import {
  DepartmentSalaryTypeMapping,
  IDepartmentSalaryTypeMapping,
  IExternalAccount,
  ISalaryType,
  ISimpleSalaryTypeUpdateRequest
} from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { SettingService } from 'src/app/services/setting.service';
import { environment } from 'src/environments/environment';
import { IntegrationsService } from '../../integrations/integrations.service';
import { SalaryTypeView } from '../salary-types/salary-type-view';

@Component({
  selector: 'app-standard-salary-types',
  templateUrl: './standard-salary-types.component.html'
})
export class StandardSalaryTypesComponent implements OnInit, OnDestroy {
  public salaryTypes: SalaryTypeView[] = [];
  public externalReferences: any[] = [];
  public activateModuleDialogVisible = false;
  public triggerUpdate = false;

  public get isFullAdmin(): boolean {
    return this.sessionService.role.IsFullAdmin;
  }

  public moduleActivatedDialogVisible = false;
  public moduleActivatedDialogMessage = 'AccountModules.ReloginForChanges';

  private originDepartmentSalaryTypeMapping: IDepartmentSalaryTypeMapping[] = [];

  constructor(
    private dataService: DataService,
    public sessionService: SessionService,
    private staticDataService: StaticDataService,
    public settingService: SettingService,
    private priceFormatPipe: PriceFormatPipe,
    public integrationsService: IntegrationsService
  ) {
    this.integrationsService.TriggerReload.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      if (value) {
        this.loadData();
      }
    });

    this.integrationsService.departmentSalaryTypeMapping
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: IDepartmentSalaryTypeMapping[]) => {
        this.originDepartmentSalaryTypeMapping = data;
        this.salaryTypes.forEach((item: any) => {
          const salarytype = this.originDepartmentSalaryTypeMapping.find(
            (node: IDepartmentSalaryTypeMapping) => node.SalaryTypeId === item.SalaryTypeId
          );
          item['ExternalReference'] = salarytype ? salarytype.ExternalReference : '';
        });

        this.triggerUpdate = true;
      });

    this.integrationsService.inItSalaryTypeView();
  }

  public get descriptionFeildWidth() {
    return this.integrationsService.isMobile ? 300 : undefined;
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    if (this.integrationsService.isMobile) {
      this.sessionService.isShowHugeFeaturesAlert = true;
    }
    this.loadSalaryTypes();
    this.integrationsService.getStaticDepartment();

    this.dataService.Integrations_GetAllExternalAccounts().subscribe((data: IExternalAccount[]) => {
      this.onGetAllExternalAccountsComplete(data);
    });
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  public get branding() {
    return environment.branding;
  }

  public loadData(): void {
    this.integrationsService.loadStandardSalaryTypeView().subscribe();
    this.dataService.Integrations_GetAllExternalAccounts().subscribe((data: IExternalAccount[]) => {
      this.onGetAllExternalAccountsComplete(data);
    });
  }

  private loadSalaryTypes(): void {
    this.integrationsService.standardSalaryTypeView
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: SalaryTypeView[]) => {
        this.salaryTypes = data;

        this.gridData = filterBy(this.salaryTypes, this.filter);
        this.triggerUpdate = true;
        this.convertExternalRefCodeToFriendlyName();
      });
  }

  private onGetAllExternalAccountsComplete(externalReferences: any[]): void {
    this.externalReferences = externalReferences;
    if (this.externalReferences && this.externalReferences.length > 0) {
      this.externalReferences.forEach((model: any) => {
        model.FriendlyName = model.AccountNumber + (model.AccountName ? ' - ' + model.AccountName : '');
      });
      this.convertExternalRefCodeToFriendlyName();
    }
  }

  private convertExternalRefCodeToFriendlyName(): void {
    if (
      this.salaryTypes &&
      this.salaryTypes.length > 0 &&
      this.externalReferences &&
      this.externalReferences.length > 0
    ) {
      this.salaryTypes.forEach((salaryType: SalaryTypeView) => {
        salaryType.ExternalReference = this.getExteralReferenceCode(salaryType.ExternalReference, true);
      });
    }
  }

  public onSave(args: CellCloseEvent): void {
    args.dataItem.ExternalReference = this.getExteralReferenceCode(args.dataItem.ExternalReference, true);
    const salaryType: any = args.dataItem.toSalaryType();
    salaryType.ExternalReference = this.getExteralReferenceCode(salaryType.ExternalReference);

    let request: ISimpleSalaryTypeUpdateRequest = {
      Id: salaryType.Id ? salaryType.Id : salaryType.SalaryTypeId,
      ExternalReference: salaryType.ExternalReference,
      ImportExternalReference: salaryType.ImportExternalReference
    };
    request = this.staticDataService.checkModelRecord(request);

    if (!this.integrationsService.departmentId) {
      this.dataService.SalaryTypes_UpdateSalaryTypeSimple(request).subscribe(
        (data: ISalaryType) => {
          data.ExternalReference = this.getExteralReferenceCode(data.ExternalReference, true);
          args.dataItem = data;
          args.dataItem.SalaryTypeId = salaryType.SalaryTypeId;
        },
        (error: any) => this.integrationsService.loadStandardSalaryTypeView().subscribe()
      );
    } else {
      const temp = this.originDepartmentSalaryTypeMapping.find(
        (item: IDepartmentSalaryTypeMapping) => item.SalaryTypeId === args.dataItem.Id
      );
      // TODO
      if (!temp) {
        // add new
        const dataRequest: DepartmentSalaryTypeMapping = {
          Id: null,
          DepartmentId: this.integrationsService.departmentId,
          SalaryTypeId: salaryType.Id,
          ExternalReference: request.ExternalReference
        };
        this.dataService.SalaryTypes_CreateDepartmentSalaryTypeMapping(dataRequest).subscribe(
          (data: IDepartmentSalaryTypeMapping) => {
            this.originDepartmentSalaryTypeMapping.push(data);
            args.dataItem.Id = data.SalaryTypeId;
            args.dataItem.ExternalReference = data.ExternalReference;
          },
          () => {
            this.integrationsService.loadDepartmentMapping();
          }
        );
      } else if (!request.ExternalReference) {
        // delete
        this.dataService.SalaryTypes_DeleteDepartmentSalaryTypeMapping(temp.Id).subscribe(
          () => {
            this.originDepartmentSalaryTypeMapping.splice(this.originDepartmentSalaryTypeMapping.indexOf(temp), 1);
          },
          () => {
            this.integrationsService.loadDepartmentMapping();
          }
        );
      } else {
        // update
        const dataRequest: DepartmentSalaryTypeMapping = {
          Id: temp.Id,
          DepartmentId: this.integrationsService.departmentId,
          SalaryTypeId: salaryType.Id,
          ExternalReference: request.ExternalReference
        };
        this.dataService.SalaryTypes_UpdateDepartmentSalaryTypeMapping(dataRequest).subscribe(
          (data: IDepartmentSalaryTypeMapping) => {
            args.dataItem.Id = data.SalaryTypeId;
            args.dataItem.ExternalReference = data.ExternalReference;
          },
          () => {
            this.integrationsService.loadDepartmentMapping();
          }
        );
      }
    }
  }

  private getExteralReferenceCode(refCode: string, fullName?: boolean): any {
    if (!refCode || !this.externalReferences) {
      return undefined;
    }

    const externalRefCode: any = this.externalReferences.find(
      (item: any) => refCode === item.AccountNumber || refCode === item.FriendlyName
    );
    if (externalRefCode) {
      return !fullName ? externalRefCode.AccountNumber : externalRefCode.FriendlyName;
    }

    return refCode;
  }

  public filter: CompositeFilterDescriptor;
  public gridData: any[] = this.salaryTypes;
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridData = filterBy(this.salaryTypes, filter);
  }

  public onActivateModuleDialogAction(action: string): void {
    switch (action) {
      case 'ViewPackages':
        this.sessionService.NavigateTo('tabs.company.modules');
        break;
      case 'ActivateModule':
        this.dataService.Modules_EnableModule(4).subscribe(
          (message: string) => {
            if (message) {
              this.moduleActivatedDialogMessage = message;
            }
            this.staticDataService.loadStaticData().then(() => {
              this.moduleActivatedDialogVisible = true;
            });
          },
          (): void => {
            // this.reloadPage();
          }
        );
        break;
    }
  }

  public onAddonClickEvent(): void {
    this.activateModuleDialogVisible = true;
  }

  public reloadPage(): void {
    this.settingService.ReloadWarrning = true;
    window.location.reload();
  }

  //format the price before it gets passed as a translation param
  public get translationParams(): any {
    return {
      moduleName: this.integrationsService.moduleSalaryTypeName,
      modulePrice:
        this.priceFormatPipe.transform(this.integrationsService.moduleSalaryTypePrice, true) === 'Price.Free'
          ? '0,-'
          : this.priceFormatPipe.transform(this.integrationsService.moduleSalaryTypePrice, true)
    };
  }

  public onCellClick(event: GridCellClickEvent): void {
    if (event.dataItem) {
      if (event.dataItem.IsAdvanced === true && !this.integrationsService.hasSalaryTypeModule) {
        this.activateModuleDialogVisible = true;
      }
    }
  }
}
