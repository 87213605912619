import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef } from '@angular/core';
import { ControlComponentBase } from '../../common/control-component-base';
var GridFlagColumnComponent = /** @class */ (function (_super) {
    tslib_1.__extends(GridFlagColumnComponent, _super);
    function GridFlagColumnComponent(elementRef, changeDetectorRef) {
        return _super.call(this, elementRef, changeDetectorRef) || this;
    }
    return GridFlagColumnComponent;
}(ControlComponentBase));
export { GridFlagColumnComponent };
