import { AfterViewInit, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmployeeService } from 'src/app/services/employee.service';
import { environment } from 'src/environments/environment';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { ImportTypesEnum } from '../../model/enum';
import { SalaryBatch, SalaryPeriod } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { BusyIndicatorService, LoadingIndicatorTextType } from '../../services/busy-indicator.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import { ReportDialogService } from '../../shared-components/report-dialog/report-dialog.service';
import { ReportParametersDefaultValue } from '../../shared-components/report-dialog/report-parameters-default-value';
import { CompanySalaryBatchService } from './company-salary-batches.service';
var CompanySalaryBatchesComponent = /** @class */ (function () {
    function CompanySalaryBatchesComponent(busyIndicatorService, settingService, dataService, staticDataService, sessionService, employeeService, renderer, reportDialogService, companySalaryBatchService, translateService) {
        var _this = this;
        this.busyIndicatorService = busyIndicatorService;
        this.settingService = settingService;
        this.dataService = dataService;
        this.staticDataService = staticDataService;
        this.sessionService = sessionService;
        this.employeeService = employeeService;
        this.renderer = renderer;
        this.reportDialogService = reportDialogService;
        this.companySalaryBatchService = companySalaryBatchService;
        this.translateService = translateService;
        this.errorListDetailsDialogVisible = false;
        this.lockSalaryBatchDialogVisible = false;
        this.salaryBatchFinalizationStep1Completed = false;
        this.salaryBatchFinalizationStep2Completed = false;
        this.salaryBatchFinalizationStep3Completed = false;
        this.salaryBatchFinalizationStep3RecalculationConfirm = false;
        this.salaryBatchFinalizationCloseAll = false;
        this.showRevertSalaryBatchDialog = false;
        this.alertDialogVisible = false;
        this.salaryBatchDialogVisible = false;
        this.confirmPasswordDialogVisible = false;
        this.confirmDeleteDialogVisible = false;
        this.confirmRevertLockDialogVisible = false;
        this.confirmRejectDialogVisible = false;
        this.chooseResendTargetDialogVisible = false;
        this.resendBatchExtCompletedDialogVisible = false;
        this.resendBatchToEIncomeDialogVisible = false;
        this.resendBatchToIntegrationConfirmationDialogVisible = false;
        this.emptySalaryBatchDialogVisible = false;
        this.noEmployeeActiveVisible = false;
        this.reloadEntityContext = false;
        this.listClassExcluded = ['Footer', '10'];
        this.ngUnsubscribe = new Subject();
        this.isForceRecalculate = false;
        this.isShowWarningRecalculate = false;
        this.isWaitingReCalculate = false;
        this.loadingText = '';
        this.isSalaryBatchCreating = false;
        this.salaryBatchesDataSource = [];
        this.spMouseDown = function (e) {
            _this.splitter.removeEventListener('mousedown', _this.spMouseDown);
            window.addEventListener('mousemove', _this.spMouseMove);
            window.addEventListener('mouseup', _this.spMouseUp);
            _this.lastX = e.clientX;
        };
        this.spMouseUp = function (e) {
            window.removeEventListener('mousemove', _this.spMouseMove);
            window.removeEventListener('mouseup', _this.spMouseUp);
            _this.splitter.addEventListener('mousedown', _this.spMouseDown);
            _this.resetPosition(_this.lastX);
        };
        this.spMouseMove = function (e) {
            _this.resetPosition(e.clientX);
        };
        this.spMouseDownY = function (e) {
            _this.splitterY.removeEventListener('mousedown', _this.spMouseDownY);
            window.addEventListener('mousemove', _this.spMouseMoveY);
            window.addEventListener('mouseup', _this.spMouseUpY);
            _this.lastY = e.clientY;
        };
        this.spMouseUpY = function (e) {
            window.removeEventListener('mousemove', _this.spMouseMoveY);
            window.removeEventListener('mouseup', _this.spMouseUpY);
            _this.splitterY.addEventListener('mousedown', _this.spMouseDownY);
            _this.resetPositionY(_this.lastY);
        };
        this.spMouseMoveY = function (e) {
            _this.resetPositionY(e.clientY);
        };
        this.companySalaryBatchService.showReportShortcutChange
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && _this.reportDialogService.isVisibleReportIcon) {
                _this.prepareReportDialogParams();
            }
        });
        this.reportDialogService.visibleChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (value) {
            if (value) {
                _this.prepareReportDialogParams();
            }
        });
        this.companySalaryBatchService.addNewShortcutChange
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && _this.sessionService.role.IsSalaryAdminAndHigher) {
                _this.showNewSalaryBatchDialog();
            }
        });
        this.companySalaryBatchService.selectedSalaryBatch
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            if (!value) {
                _this.companySalaryBatchService.loadSalaryStatements(value ? value.Id : undefined);
            }
            if (value &&
                ((_this.selectedSalaryBatch && _this.selectedSalaryBatch.Id !== value.Id) || !_this.selectedSalaryBatch)) {
                _this.companySalaryBatchService.loadSalaryBatchErrorsView(value);
                _this.companySalaryBatchService.loadSalaryStatements(value ? value.Id : undefined);
            }
            _this.selectedSalaryBatch = value;
            _this.basicSalaryBatch = value ? JSON.parse(JSON.stringify(value)) : undefined;
            _this.childWindowSalaryBatch = value ? JSON.parse(JSON.stringify(value)) : undefined;
            if (_this.companySalaryBatchService.IsMobileOrHybridApp) {
                _this.selectDefaultBasicTab();
                _this.companySalaryBatchService.loadSalaryStatements(value ? value.Id : undefined);
            }
        });
        this.companySalaryBatchService.salaryBatchEntityContext
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            _this.salaryBatchEntityContext = value;
        });
        this.companySalaryBatchService.viewSalaryTotalModeChange
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && !_this.companySalaryBatchService.IsMobileOrHybridApp) {
                _this.setPayrollSize();
            }
        });
        this.companySalaryBatchService.afterCreate.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            if (data) {
                _this.filtersalaryBatchesDataSource(data.salaryRecord.Id, true);
                _this.basicSalaryBatch = _this.selectedSalaryBatch
                    ? JSON.parse(JSON.stringify(_this.selectedSalaryBatch))
                    : undefined;
                if (_this.sessionService.role.IsSalaryAdminAndHigher && _this.companySalaryBatchService.emptySalaryBatchShown) {
                    _this.emptySalaryBatchDialogVisible = true;
                }
                if (!data.salaryRecord.Id) {
                    _this.reloadcaculate(_this.selectedSalaryBatch);
                }
            }
        });
    }
    Object.defineProperty(CompanySalaryBatchesComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    CompanySalaryBatchesComponent.prototype.onsalaryBatchFinalizationStep2Completed = function () {
        this.salaryBatchFinalizationStep2Completed = true;
    };
    CompanySalaryBatchesComponent.prototype.onsalaryBatchFinalizationStep3Completed = function () {
        this.salaryBatchFinalizationStep3Completed = true;
    };
    Object.defineProperty(CompanySalaryBatchesComponent.prototype, "currentBatchHasError", {
        get: function () {
            return this.selectedSalaryBatch && this.selectedSalaryBatch.HasErrors;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryBatchesComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryBatchesComponent.prototype, "selectedComboboxSalaryBatchId", {
        get: function () {
            return this.selectedComboboxSalaryBatchIdValue;
        },
        set: function (value) {
            if (this.selectedComboboxSalaryBatchIdValue !== value) {
                this.selectedComboboxSalaryBatchIdValue = value;
                var selectedBatch = value
                    ? this.companySalaryBatchService.allSalaryBatches.find(function (item) { return item.Id === value; })
                    : undefined;
                var jsonSelectedBatch = JSON.stringify(selectedBatch);
                var jsonEntityContextSelectedBatch = JSON.stringify(this.selectedSalaryBatch);
                if (this.companySalaryBatchService.IsMobileOrHybridApp && jsonSelectedBatch !== jsonEntityContextSelectedBatch) {
                    this.companySalaryBatchService.setNextValueSelectedSalaryBatch(selectedBatch);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryBatchesComponent.prototype, "salaryCycleId", {
        get: function () {
            return this.salaryCycleIdValue;
        },
        set: function (value) {
            if (this.salaryCycleIdValue !== value) {
                this.salaryCycleIdValue = value;
                this.filtersalaryBatchesDataSource();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryBatchesComponent.prototype, "isShowApprovalButtonNonScreenCheck", {
        get: function () {
            return (this.sessionService.role.IsSalaryAdminAndHigher &&
                this.salaryBatchEntityContext &&
                this.salaryBatchEntityContext.BatchId > 0 &&
                this.salaryBatchEntityContext.IsDraft);
        },
        enumerable: true,
        configurable: true
    });
    CompanySalaryBatchesComponent.prototype.ngOnInit = function () {
        this.companySalaryBatchService.isSalaryBatchLoading = true;
        // Workaround for GS-5515 to make sure employees are loaded
        this.employeeService.employees.subscribe();
        this.companySalaryBatchService.initData();
        this.getSalaryBatches();
        this.translateText();
        this.settingService.showModuleMessage('Company.SalaryBatches');
        this.settingService.showNavigationMessage();
    };
    CompanySalaryBatchesComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (!this.companySalaryBatchService.IsMobileOrHybridApp) {
            this.unsubscribeResize = this.renderer.listen(window, 'resize', function () {
                _this.setPayrollSize();
            });
            setTimeout(function () {
                _this.setPayrollSize();
            });
        }
    };
    CompanySalaryBatchesComponent.prototype.ngOnDestroy = function () {
        this.companySalaryBatchService.ngOnDestroy();
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        if (!this.companySalaryBatchService.IsMobileOrHybridApp) {
            this.unsubscribeResize();
        }
    };
    CompanySalaryBatchesComponent.prototype.setPayrollSize = function () {
        var divManHeight = 100;
        var wHeight = window.innerHeight;
        var wWidth = window.innerWidth;
        var divCompany = document.getElementById('divCompany');
        if (divCompany) {
            var listKendoGrid = divCompany.getElementsByClassName('k-grid-content');
            if (listKendoGrid && listKendoGrid.length > 1) {
                var rateLeft = wHeight * 0.602;
                var rateRight = wHeight * 0.207;
                if (listKendoGrid.length === 5 && wWidth >= 1280 && wHeight >= 500) {
                    listKendoGrid[1].style.height = wHeight - divManHeight - rateLeft + 'px';
                    listKendoGrid[2].style.height = wHeight - divManHeight - rateLeft + 'px';
                    listKendoGrid[3].style.height = wHeight - divManHeight - rateRight + 'px';
                    listKendoGrid[4].style.height = wHeight - divManHeight - rateRight + 'px';
                    this.initSplitterY();
                    this.initSplitter();
                    if (wWidth > 1520) {
                        this.resetPosition('half');
                    }
                    else {
                        this.resetPosition('full');
                    }
                }
                else if (listKendoGrid.length === 5) {
                    this.resetSplitter();
                    listKendoGrid[3].style.height = '';
                    listKendoGrid[4].style.height = '';
                }
            }
        }
    };
    Object.defineProperty(CompanySalaryBatchesComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    CompanySalaryBatchesComponent.prototype.onMoreMenuOptionClick = function (action) {
        switch (action) {
            case 'salaryBatches_recalculateDraft':
                this.onRecalculate();
                break;
            case 'salaryBatches_importData':
                this.settingService.navigationParameters['dataImportSelectedOption'] = ImportTypesEnum.Payroll;
                this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
                break;
            case 'salarybatchaddnew':
                this.showNewSalaryBatchDialog();
                break;
            case 'companyreport':
                this.prepareReportDialogParams();
                break;
        }
    };
    CompanySalaryBatchesComponent.prototype.showErrorModal = function (data) {
        this.companySalaryBatchService.setNextValueSelectedSalaryBatch(data);
        this.errorListDetailsDialogVisible = true;
    };
    CompanySalaryBatchesComponent.prototype.selectedSalaryBatchChanged = function (data) {
        this.companySalaryBatchService.setNextValueSelectedSalaryBatch(data);
    };
    CompanySalaryBatchesComponent.prototype.reloadcaculate = function (model) {
        this.companySalaryBatchService.loadSalaryTypeTotals(model ? model.Id : undefined);
        this.companySalaryBatchService.loadUnitTotals(model ? model.Id : undefined);
        this.companySalaryBatchService.loadSalaryStatements(model ? model.Id : undefined);
        this.companySalaryBatchService.loadSalaryBatchErrorsView(this.selectedSalaryBatch);
    };
    CompanySalaryBatchesComponent.prototype.onRecalculate = function () {
        var _this = this;
        if (!this.isForceRecalculate) {
            this.isShowWarningRecalculate = true;
            return;
        }
        this.isForceRecalculate = false;
        if (this.selectedSalaryBatch) {
            this.busyIndicatorService.setLoadingIndicatorVisibility(true, LoadingIndicatorTextType.RECALCULATE);
            this.loadingText = this.busyIndicatorService.getLoadingText();
            this.isWaitingReCalculate = true;
            this.dataService.SalaryBatches_RecalculateSalaryBatch(this.selectedSalaryBatch.Id).subscribe(function () {
                _this.reloadSalaryBatches();
                _this.busyIndicatorService.setLoadingIndicatorVisibility(false);
                _this.isWaitingReCalculate = false;
            }, function () {
                _this.busyIndicatorService.setLoadingIndicatorVisibility(false);
                _this.isWaitingReCalculate = false;
            }, function () {
                _this.busyIndicatorService.setLoadingIndicatorVisibility(false);
                _this.isWaitingReCalculate = false;
            });
        }
    };
    CompanySalaryBatchesComponent.prototype.prepareReportDialogParams = function () {
        if (this.selectedSalaryBatch) {
            this.reportDialogService.reportParams = new ReportParametersDefaultValue();
            this.reportDialogService.reportParams.salaryBatchId = this.selectedSalaryBatch.Id;
            this.reportDialogService.onShowReportsEventClick();
        }
        else {
            this.reportDialogService.reportDialogVisible = false;
            this.alertDialogType = 'noBatchSelectedWarning';
            this.alertDialogVisible = true;
        }
    };
    CompanySalaryBatchesComponent.prototype.showNewSalaryBatchDialog = function () {
        if (this.companySalaryBatchService.isEmptyEmployeeFromCycle) {
            this.noEmployeeActiveVisible = true;
            return;
        }
        this.prechildWindowSalaryBatch = this.childWindowSalaryBatch;
        this.childWindowSalaryBatch = undefined;
        this.salaryBatchDialogVisible = true;
    };
    CompanySalaryBatchesComponent.prototype.onEditSalaryBatch = function (batch) {
        if (!batch) {
            return;
        }
        this.companySalaryBatchService.setNextValueSelectedSalaryBatch(batch);
        this.basicSalaryBatch = JSON.parse(JSON.stringify(batch));
        this.childWindowSalaryBatch = JSON.parse(JSON.stringify(batch));
        this.salaryBatchDialogVisible = true;
    };
    CompanySalaryBatchesComponent.prototype.onRecalculateAction = function (action) {
        if (action === 'ForceRecalculate') {
            this.isForceRecalculate = true;
            this.onRecalculate();
        }
    };
    CompanySalaryBatchesComponent.prototype.onSalaryBatchDialogAction = function (event) {
        this.companySalaryBatchService.setNextValueSalaryBatchEntityContext(event.context);
        switch (event.action) {
            case 'Create':
            case 'Save':
                this.onDone();
                break;
            case 'Delete':
                this.onDelete();
                break;
            case 'SubmitForApproval':
                this.onLockSalaryBatch();
                break;
            case 'Reject':
            case 'RevertFinalizing':
                this.onRejectSalaryBatch();
                break;
            case 'RevertToDraft':
                this.onLockSalaryBatch();
                break;
            case 'Finalize':
                this.onFinalize();
                break;
            case 'Resend':
                this.resendBatch(this.salaryBatchEntityContext.BatchId);
                break;
            case 'Revert':
                this.showRevertSalaryBatchDialog = true;
                break;
            default:
                this.onDone(true);
        }
    };
    CompanySalaryBatchesComponent.prototype.onConfirmDeleteDialogAction = function (action) {
        if (action === 'Delete') {
            this.deleteSalaryBatch(this.salaryBatchEntityContext.BatchId);
        }
    };
    CompanySalaryBatchesComponent.prototype.onConfirmRevertLockDialogAction = function (action) {
        var _this = this;
        if (action === 'Ok') {
            this.dataService
                .SalaryBatches_ResetBatchToDraft(this.salaryBatchEntityContext.BatchId)
                .subscribe(function () { return _this.reloadSalaryBatches(); });
        }
    };
    CompanySalaryBatchesComponent.prototype.reloadSalaryBatches = function () {
        this.closeDialog();
        this.getSalaryBatches();
    };
    CompanySalaryBatchesComponent.prototype.onChooseResendTargetDialogAction = function (action) {
        if (action === 'eincome') {
            this.resendBatchToEIncome(this.resendBatchId);
        }
        else if (action === 'external') {
            this.apiResendToIntegration(this.resendBatchId);
        }
    };
    CompanySalaryBatchesComponent.prototype.resendBatch = function (batchId) {
        if (this.IsGreenlandCompany) {
            if (this.companySalaryBatchService.integrationName) {
                this.resendBatchToIntegrationConfirmation(batchId);
            }
        }
        else {
            if (this.companySalaryBatchService.integrationName) {
                this.resendBatchId = batchId;
                this.chooseResendTargetDialogVisible = true;
            }
            else {
                this.resendBatchToEIncome(batchId);
            }
        }
    };
    CompanySalaryBatchesComponent.prototype.resendBatchToEIncome = function (batchId) {
        this.resendBatchToEIncomeId = batchId;
        this.resendBatchToEIncomeDialogVisible = true;
    };
    CompanySalaryBatchesComponent.prototype.onResendBatchToEIncomeDialogAction = function (action) {
        if (action === 'Resend') {
            this.apiResendToEIncome(this.resendBatchToEIncomeId);
        }
    };
    CompanySalaryBatchesComponent.prototype.resendBatchToIntegrationConfirmation = function (batchId) {
        this.resendBatchToIntegrationId = batchId;
        this.resendBatchToIntegrationConfirmationDialogVisible = true;
    };
    CompanySalaryBatchesComponent.prototype.onResendBatchToIntegrationDialogAction = function (action) {
        if (action === 'Resend') {
            this.apiResendToIntegration(this.resendBatchToIntegrationId);
        }
    };
    CompanySalaryBatchesComponent.prototype.closeFinalizationWizardDialogs = function () {
        this.salaryBatchFinalizationCloseAll = true;
        this.confirmPasswordDialogVisible = false;
        this.closeDialog();
    };
    CompanySalaryBatchesComponent.prototype.onFinalizationProcessDone = function (doRecalculation) {
        this.reloadSalaryBatches();
        this.salaryBatchFinalizationCloseAll = true;
        this.confirmPasswordDialogVisible = false;
    };
    CompanySalaryBatchesComponent.prototype.onConfirmPasswordDialogAction = function (action) {
        switch (action) {
            case 'Cancel':
                this.closeFinalizationWizardDialogs();
                break;
            case 'AcceptWarnings':
                this.salaryBatchFinalizationStep1Completed = true;
                break;
            default:
                this.closeDialog();
        }
    };
    CompanySalaryBatchesComponent.prototype.onEmptySalaryBatchDialogAction = function (action) {
        if (action === 'Yes') {
            this.showNewSalaryBatchDialog();
        }
    };
    CompanySalaryBatchesComponent.prototype.getSalaryBatches = function (salaryBatchId) {
        var _this = this;
        this.busyIndicatorService.setLoadingIndicatorVisibility(true, LoadingIndicatorTextType.RECALCULATE);
        this.loadingText = this.busyIndicatorService.getLoadingText();
        this.isWaitingReCalculate = true;
        this.companySalaryBatchService.loadSalaryBatches().subscribe(function () {
            _this.filtersalaryBatchesDataSource(salaryBatchId);
            _this.basicSalaryBatch = _this.selectedSalaryBatch
                ? JSON.parse(JSON.stringify(_this.selectedSalaryBatch))
                : undefined;
            if (_this.sessionService.role.IsSalaryAdminAndHigher && _this.companySalaryBatchService.emptySalaryBatchShown) {
                _this.emptySalaryBatchDialogVisible = true;
            }
            if (!salaryBatchId) {
                _this.reloadcaculate(_this.selectedSalaryBatch);
            }
            _this.busyIndicatorService.setLoadingIndicatorVisibility(false);
            _this.isWaitingReCalculate = false;
        }, function () {
            _this.busyIndicatorService.setLoadingIndicatorVisibility(false);
            _this.isWaitingReCalculate = false;
        });
    };
    CompanySalaryBatchesComponent.prototype.setDefaultSalaryBatchForMobileDevice = function () {
        var _this = this;
        if (this.companySalaryBatchService.IsMobileOrHybridApp) {
            if (!this.companySalaryBatchService.emptySalaryBatchShown) {
                var hasItemSelected = this.companySalaryBatchService.allSalaryBatches.some(function (b) { return b.Id === _this.selectedComboboxSalaryBatchId; });
                if (!hasItemSelected) {
                    this.selectedComboboxSalaryBatchId = this.companySalaryBatchService.allSalaryBatches[0].Id;
                }
            }
            else {
                this.companySalaryBatchService.setNextValueSelectedSalaryBatch(undefined);
                this.selectedComboboxSalaryBatchId = undefined;
            }
        }
    };
    CompanySalaryBatchesComponent.prototype.closeDialog = function () {
        this.salaryBatchDialogVisible = false;
        this.salaryBatchFinalizationStep1Completed = false;
        this.salaryBatchFinalizationStep2Completed = false;
        this.salaryBatchFinalizationStep3Completed = false;
        if (!this.childWindowSalaryBatch) {
            this.basicSalaryBatch = this.prechildWindowSalaryBatch
                ? JSON.parse(JSON.stringify(this.prechildWindowSalaryBatch))
                : undefined;
            this.childWindowSalaryBatch = this.prechildWindowSalaryBatch
                ? JSON.parse(JSON.stringify(this.prechildWindowSalaryBatch))
                : undefined;
        }
    };
    CompanySalaryBatchesComponent.prototype.onShowConfirmPasswordWindow = function () {
        this.salaryBatchDialogVisible = false;
        this.salaryBatchEntityContext.Password = '';
        this.confirmPasswordDialogVisible = true;
    };
    CompanySalaryBatchesComponent.prototype.onRevertBatchCompleted = function () {
        this.getSalaryBatches();
        this.closeDialog();
    };
    CompanySalaryBatchesComponent.prototype.onFinalize = function () {
        var _this = this;
        if (this.selectedSalaryBatch) {
            var payoutDate = new Date(this.selectedSalaryBatch.PayoutDate);
            payoutDate = this.staticDataService.getCurrentdate(undefined, payoutDate);
            var contextpayoutDate = new Date(this.salaryBatchEntityContext.PaymentDate);
            contextpayoutDate = this.staticDataService.getCurrentdate(undefined, contextpayoutDate);
            if ((this.salaryBatchEntityContext.Message !== this.selectedSalaryBatch.Message ||
                contextpayoutDate.getTime() !== payoutDate.getTime()) &&
                this.selectedSalaryBatch.StatusId <= 10 &&
                Global.SESSION.CurrentRole.Id >= 60) {
                this.saveSalaryBatchChanges(true).then(function (salaryRecord) {
                    if (_this.salaryBatchEntityContext && salaryRecord) {
                        _this.salaryBatchEntityContext.HasErrors = salaryRecord.HasErrors;
                        _this.salaryBatchEntityContext.HasWarnings = salaryRecord.HasWarnings;
                        _this.beginFinalization();
                    }
                });
            }
            else {
                this.beginFinalization();
            }
        }
        else {
            this.alertDialogType = 'noBatchSelectedWarning';
            this.alertDialogVisible = true;
        }
    };
    CompanySalaryBatchesComponent.prototype.translateText = function () {
        var _this = this;
        this.translateService
            .get(['CompanySalaryBatches.FinalizationStep3ErrorMassge'])
            .subscribe(function (translations) {
            _this.companySalaryBatchService.Step3FinalizationFailText =
                translations['CompanySalaryBatches.FinalizationStep3ErrorMassge'];
        });
    };
    CompanySalaryBatchesComponent.prototype.beginFinalization = function () {
        if (Global.SESSION && !Global.SESSION.IsPaymentApprover) {
            if (Global.SESSION.CurrentRole.Id === 90) {
                // Administrator (FullAdmin)
                this.alertDialogType = 'denyFinalizeAdminText';
                this.alertDialogVisible = true;
            }
            else {
                this.alertDialogType = 'denyFinalizeText';
                this.alertDialogVisible = true;
            }
            return;
        }
        if (this.salaryBatchEntityContext.HasErrors) {
            this.alertDialogType = 'finalizeErrorText';
            this.alertDialogVisible = true;
            return;
        }
        if (!this.salaryBatchEntityContext.HasWarnings) {
            this.salaryBatchDialogVisible = false;
            this.salaryBatchFinalizationStep1Completed = true;
            return;
        }
        this.onShowConfirmPasswordWindow();
    };
    CompanySalaryBatchesComponent.prototype.afterFinalizeError = function () {
        if (this.alertDialogType && this.alertDialogType === 'finalizeErrorText') {
            this.closeDialog();
            this.errorListDetailsDialogVisible = true;
        }
    };
    CompanySalaryBatchesComponent.prototype.onDelete = function () {
        this.confirmDeleteDialogVisible = true;
    };
    CompanySalaryBatchesComponent.prototype.onDone = function (forceClose) {
        if (forceClose === void 0) { forceClose = false; }
        if (this.IsReadOnly || forceClose || !this.salaryBatchEntityContext.IsDraft) {
            this.reloadEntityContext = true;
            this.closeDialog();
            return;
        }
        this.saveSalaryBatchChanges();
    };
    CompanySalaryBatchesComponent.prototype.saveSalaryBatchChanges = function (isFinalized) {
        var _this = this;
        if (isFinalized === void 0) { isFinalized = false; }
        return new Promise(function (resolve, reject) {
            if (!_this.salaryBatchEntityContext.PaymentDate) {
                _this.alertDialogType = 'invalidDateMessage';
                _this.alertDialogVisible = true;
                return resolve(undefined);
            }
            var payoutDate = _this.staticDataService.getCurrentdate(undefined, _this.salaryBatchEntityContext.PaymentDate);
            if (_this.salaryBatchEntityContext.BatchId < 1) {
                _this.isSalaryBatchCreating = true;
                var salaryBatchRequest = {
                    PayoutDate: payoutDate,
                    Preview: false,
                    SalaryCycleId: _this.salaryBatchEntityContext.CycleId,
                    SalaryPeriodId: _this.salaryBatchEntityContext.PeriodId,
                    Message: _this.salaryBatchEntityContext.Message === undefined ? undefined : _this.salaryBatchEntityContext.Message,
                    UserEmploymentIds: _this.companySalaryBatchService.allCurrentSelectSalaryStatements,
                    EIncomeZeroReport: _this.salaryBatchEntityContext.EmployeeCategory === 3,
                    PayoutAllFlex: _this.salaryBatchEntityContext.PayoutAllFlex
                        ? _this.salaryBatchEntityContext.PayoutAllFlex
                        : false,
                    PayoutNewFlex: _this.salaryBatchEntityContext.PayoutNewFlex
                        ? _this.salaryBatchEntityContext.PayoutNewFlex
                        : false,
                    FromDateOverride: _this.salaryBatchEntityContext.FromDateOverride
                        ? _this.salaryBatchEntityContext.FromDateOverride
                        : null,
                    ToDateOverride: _this.salaryBatchEntityContext.ToDateOverride
                        ? _this.salaryBatchEntityContext.ToDateOverride
                        : null
                };
                return _this.createSalaryBatch(salaryBatchRequest).then(function (salaryBatch) { return resolve(salaryBatch); });
            }
            else {
                var selectedSalaryBatch = _this.selectedSalaryBatch;
                var salaryPeriod = new SalaryPeriod();
                salaryPeriod.Id = _this.salaryBatchEntityContext.PeriodId;
                salaryPeriod.StartDate = null;
                salaryPeriod.EndDate = null;
                salaryPeriod.SuggestedPayoutDate = selectedSalaryBatch.PayoutDate || null;
                salaryPeriod.FriendlyName = null;
                salaryPeriod.SalaryCycleId = _this.salaryBatchEntityContext.CycleId || null;
                if (selectedSalaryBatch && selectedSalaryBatch.PeriodStartDate) {
                    salaryPeriod.StartDate = _this.staticDataService.getCurrentdate(false, selectedSalaryBatch.PeriodStartDate);
                }
                if (selectedSalaryBatch && selectedSalaryBatch.PeriodEndDate) {
                    salaryPeriod.EndDate = _this.staticDataService.getCurrentdate(selectedSalaryBatch.PeriodEndDate);
                }
                selectedSalaryBatch.SalaryPeriod = salaryPeriod;
                selectedSalaryBatch.PayoutAllFlex = _this.salaryBatchEntityContext.PayoutAllFlex
                    ? _this.salaryBatchEntityContext.PayoutAllFlex
                    : false;
                selectedSalaryBatch.PayoutNewFlex = _this.salaryBatchEntityContext.PayoutNewFlex
                    ? _this.salaryBatchEntityContext.PayoutNewFlex
                    : false;
                selectedSalaryBatch.FromDateOverride = _this.salaryBatchEntityContext.FromDateOverride;
                selectedSalaryBatch.ToDateOverride = _this.salaryBatchEntityContext.ToDateOverride;
                selectedSalaryBatch.PayoutDate = payoutDate;
                selectedSalaryBatch.Message = _this.salaryBatchEntityContext.Message;
                selectedSalaryBatch.EBoksDeliveryEnabled = selectedSalaryBatch.EBoksDeliveryEnabled
                    ? selectedSalaryBatch.EBoksDeliveryEnabled
                    : false;
                selectedSalaryBatch.SpecifiedEmployees = selectedSalaryBatch.SpecifiedEmployees;
                selectedSalaryBatch.HasNemKontoPayments = selectedSalaryBatch.HasNemKontoPayments
                    ? selectedSalaryBatch.HasNemKontoPayments
                    : false;
                selectedSalaryBatch.PaymentMethodPublicPension = selectedSalaryBatch.PaymentMethodPublicPension;
                selectedSalaryBatch.PaymentMethodVacationProvider = selectedSalaryBatch.PaymentMethodVacationProvider;
                selectedSalaryBatch.ToArchive = selectedSalaryBatch.ToArchive ? selectedSalaryBatch.ToArchive : false;
                selectedSalaryBatch.Finalized = selectedSalaryBatch.Finalized;
                selectedSalaryBatch.FinalizedUserId = selectedSalaryBatch.FinalizedUserId;
                selectedSalaryBatch.AllowPreliminaryPayslips = selectedSalaryBatch.AllowPreliminaryPayslips
                    ? selectedSalaryBatch.AllowPreliminaryPayslips
                    : false;
                selectedSalaryBatch.PackageLevelWhenFinalized = selectedSalaryBatch.PackageLevelWhenFinalized;
                selectedSalaryBatch.IsAutomated = selectedSalaryBatch.IsAutomated;
                selectedSalaryBatch.FinalizationGuid = selectedSalaryBatch.FinalizationGuid || null;
                var selectedSalaryBatchRecord = new SalaryBatch();
                selectedSalaryBatch = _this.staticDataService.checkModelRecord(selectedSalaryBatch);
                Object.assign(selectedSalaryBatchRecord, selectedSalaryBatch);
                return _this.updateSalaryBatch(selectedSalaryBatchRecord, isFinalized).then(function (salaryBatch) {
                    return resolve(salaryBatch);
                });
            }
        });
    };
    CompanySalaryBatchesComponent.prototype.onRejectSalaryBatch = function () {
        this.confirmRejectDialogVisible = true;
    };
    CompanySalaryBatchesComponent.prototype.onLockSalaryBatch = function () {
        var _this = this;
        if ((this.salaryBatchEntityContext.Message !== this.selectedSalaryBatch.Message ||
            this.salaryBatchEntityContext.PaymentDate !== this.selectedSalaryBatch.PayoutDate) &&
            this.selectedSalaryBatch.StatusId <= 10 &&
            Global.SESSION.CurrentRole.Id >= 60) {
            this.saveSalaryBatchChanges(true).then(function (salaryRecord) {
                if (_this.salaryBatchEntityContext && salaryRecord) {
                    _this.salaryBatchEntityContext.HasErrors = salaryRecord.HasErrors;
                    _this.salaryBatchEntityContext.HasWarnings = salaryRecord.HasWarnings;
                    if (_this.salaryBatchEntityContext.IsDraft) {
                        _this.lockSalaryBatchDialogVisible = true;
                    }
                    else if (_this.salaryBatchEntityContext.IsLocked || _this.salaryBatchEntityContext.IsPreliminary) {
                        _this.confirmRevertLockDialogVisible = true;
                    }
                }
            });
        }
        else {
            if (this.salaryBatchEntityContext.IsDraft) {
                this.lockSalaryBatchDialogVisible = true;
            }
            else if (this.salaryBatchEntityContext.IsLocked || this.salaryBatchEntityContext.IsPreliminary) {
                this.confirmRevertLockDialogVisible = true;
            }
        }
    };
    CompanySalaryBatchesComponent.prototype.recalculateDrafts = function () {
        this.getSalaryBatches();
    };
    CompanySalaryBatchesComponent.prototype.apiResendToEIncome = function (batchId) {
        var _this = this;
        this.dataService.SalaryBatches_ResendEIncome(batchId).subscribe(function () {
            _this.alertDialogType = 'resendBatchCompletedMessage';
            _this.alertDialogVisible = true;
            _this.reloadSalaryBatches();
        });
    };
    CompanySalaryBatchesComponent.prototype.apiResendToIntegration = function (batchId) {
        var _this = this;
        this.dataService.SalaryBatches_SendOrResendToDefaultIntegration(batchId).subscribe(function () {
            _this.resendBatchExtCompletedDialogVisible = true;
            _this.reloadSalaryBatches();
        });
    };
    CompanySalaryBatchesComponent.prototype.createSalaryBatch = function (salaryBatch) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.dataService.SalaryBatches_CreateSalaryBatch(salaryBatch).subscribe(function (salaryRecord) {
                _this.handleAfterCreateBatch(salaryBatch, salaryRecord);
                _this.closeDialog();
                _this.isSalaryBatchCreating = false;
                return resolve(salaryRecord);
            }, function () {
                _this.closeDialog();
                _this.isSalaryBatchCreating = false;
                return resolve(undefined);
            });
        });
    };
    CompanySalaryBatchesComponent.prototype.handleAfterCreateBatch = function (salaryBatch, salaryRecord) {
        var _this = this;
        if (!this.salaryCycleId || this.salaryCycleId === salaryBatch.SalaryCycleId) {
            this.getSalaryBatches(salaryRecord.Id);
        }
        else {
            this.busyIndicatorService.setLoadingIndicatorVisibility(true, LoadingIndicatorTextType.RECALCULATE);
            this.loadingText = this.busyIndicatorService.getLoadingText();
            this.isWaitingReCalculate = true;
            this.companySalaryBatchService.loadSalaryBatches().subscribe(function () {
                _this.busyIndicatorService.setLoadingIndicatorVisibility(false);
                _this.isWaitingReCalculate = false;
            }, function () {
                _this.busyIndicatorService.setLoadingIndicatorVisibility(false);
                _this.isWaitingReCalculate = false;
            });
        }
    };
    CompanySalaryBatchesComponent.prototype.updateSalaryBatch = function (salaryBatch, isFinalize) {
        var _this = this;
        if (isFinalize === void 0) { isFinalize = false; }
        return new Promise(function (resolve, reject) {
            _this.dataService.SalaryBatches_UpdateSalaryBatch(salaryBatch).subscribe(function (salaryRecord) {
                _this.getSalaryBatches();
                if (!isFinalize) {
                    _this.closeDialog();
                }
                return resolve(salaryRecord);
            }, function () {
                return resolve(salaryBatch);
            });
        });
    };
    CompanySalaryBatchesComponent.prototype.deleteSalaryBatch = function (batchId) {
        var _this = this;
        this.dataService.SalaryBatches_DeleteSalaryBatch(batchId).subscribe(function () {
            _this.selectedSalaryBatch = null;
            _this.reloadSalaryBatches();
        });
    };
    CompanySalaryBatchesComponent.prototype.filtersalaryBatchesDataSource = function (salaryBatchId, isNotSelect) {
        var _this = this;
        if (this.salaryCycleId) {
            this.salaryBatchesDataSource = this.companySalaryBatchService.allSalaryBatches.filter(function (s) { return s.SalaryCycleId === _this.salaryCycleId; });
        }
        else {
            if (!this.companySalaryBatchService.emptySalaryBatchShown) {
                this.salaryBatchesDataSource = this.companySalaryBatchService.allSalaryBatches;
            }
        }
        if (isNotSelect) {
            return;
        }
        if (this.salaryBatchesDataSource && this.salaryBatchesDataSource.length > 0) {
            salaryBatchId = salaryBatchId
                ? salaryBatchId
                : this.selectedSalaryBatch
                    ? this.selectedSalaryBatch.Id
                    : undefined;
            var selectedItem = salaryBatchId
                ? this.salaryBatchesDataSource.find(function (d) { return d.Id === salaryBatchId; })
                : undefined;
            if (selectedItem) {
                this.selectedComboboxSalaryBatchId = selectedItem.Id;
                this.companySalaryBatchService.setNextValueSelectedSalaryBatch(selectedItem);
            }
            else {
                this.selectedComboboxSalaryBatchId = this.salaryBatchesDataSource[0].Id;
            }
        }
        else {
            this.selectedComboboxSalaryBatchId = undefined;
        }
    };
    CompanySalaryBatchesComponent.prototype.onSalaryBatchTabSelected = function (event) {
        this.errorTabSelected = event.index === 1; // Error.
    };
    CompanySalaryBatchesComponent.prototype.selectDefaultBasicTab = function () {
        if (this.companySalaryBatchService.IsMobileOrHybridApp) {
            var salaryBatch = this.selectedSalaryBatch;
            var basicTab = this.errorTabSelected && salaryBatch && !salaryBatch.ValidationIcon;
            if (basicTab && this.salaryBatchTab) {
                this.salaryBatchTab.selectTab(0);
            }
        }
    };
    CompanySalaryBatchesComponent.prototype.initSplitter = function () {
        this.splitter = document.getElementById('splitter');
        this.cont1 = document.getElementById('Card-splitter1');
        this.cont2 = document.getElementById('Card-splitter2');
        var leftside = document.getElementById('Card-Leftside');
        this.windowWidth = window.innerWidth;
        if (!this.splitter || !this.cont1 || !this.cont2) {
            return;
        }
        if (leftside) {
            if (this.windowWidth > 1520) {
                this.leftSideWidth =
                    leftside.clientWidth > this.windowWidth * 0.55 ? this.windowWidth * 0.55 : leftside.clientWidth;
            }
            else {
                this.leftSideWidth = leftside.clientWidth;
            }
        }
        if (this.cont2Y) {
            var extra = 133;
            if (window.innerWidth < 1520) {
                extra = 113;
            }
            var cont2YHeight = this.windowHeight - this.cont1Y.clientHeight - extra;
            this.cont1.style.height = cont2YHeight + 'px';
            this.cont2.style.height = cont2YHeight + 'px';
        }
        this.splitter.addEventListener('mousedown', this.spMouseDown);
    };
    CompanySalaryBatchesComponent.prototype.resetPosition = function (nowX) {
        var dx = 0;
        // For first time init or reset when window resize
        if (nowX === 'full' || nowX === 'half') {
            var mid = this.leftSideWidth / 2;
            if (this.leftSideWidth - dx < mid) {
                dx = this.leftSideWidth - mid;
            }
            else if (dx <= mid) {
                dx = mid;
            }
        }
        else {
            dx = nowX - this.lastX;
            dx += this.cont1.clientWidth;
            if (this.leftSideWidth - dx < 300) {
                // For next time, and whenever user resize
                dx = this.windowWidth - 300;
                if (this.leftSideWidth - dx < 300) {
                    dx = this.leftSideWidth - 300;
                }
                else if (dx < 300) {
                    dx = 300;
                }
            }
        }
        this.cont1.style.width = dx + 'px';
        dx += this.splitter.clientWidth;
        dx = this.leftSideWidth - dx;
        this.cont2.style.width = dx + 'px';
        this.lastX = nowX;
    };
    CompanySalaryBatchesComponent.prototype.initSplitterY = function () {
        this.splitterY = document.getElementById('splitterY');
        this.cont1Y = document.querySelectorAll('#Card-splitter1Y')[0];
        this.cont2Y = document.querySelectorAll('#Card-splitter2Y')[0];
        this.cont1Y1 = document.querySelectorAll('#Card-splitter1Y kendo-grid-list')[0];
        this.cont2Y1 = document.querySelectorAll('#Card-splitter2Y .k-grid-content.k-virtual-content')[0];
        this.cont2Y2 = document.querySelectorAll('#Card-splitter2Y .k-grid-content.k-virtual-content')[1];
        if (!this.cont1Y || !this.cont2Y || !this.cont1Y1 || !this.cont2Y1 || !this.cont2Y2) {
            return;
        }
        if (window.innerWidth < 1520) {
            this.windowHeight = 750;
        }
        else {
            this.windowHeight = window.innerHeight - 120;
        }
        var minHeight1 = document.querySelectorAll('.Company-payslipsGrid .k-grid-content')[0];
        var minHeight2 = document.querySelectorAll('.Company-payslipsGrid .k-grid-content')[1];
        if (minHeight1 && minHeight2) {
            minHeight1.style.minHeight = '';
            minHeight2.style.minHeight = '';
        }
        var dx = 280;
        this.cont1Y.style.height = dx + 'px';
        this.cont1Y1.style.cssText = 'height: ' + (dx - 125) + 'px !important;';
        dx = this.windowHeight - dx;
        this.cont2Y.style.height = dx - 90 + 'px';
        var extra = 0.218 * window.innerHeight;
        if (window.innerWidth < 1520) {
            extra = 220;
        }
        var cont2YHeight = dx - extra;
        this.cont2Y1.style.cssText = 'height: ' + cont2YHeight + 'px !important';
        this.cont2Y2.style.cssText = 'height: ' + cont2YHeight + 'px !important';
        this.splitterY.addEventListener('mousedown', this.spMouseDownY);
    };
    CompanySalaryBatchesComponent.prototype.resetPositionY = function (nowY) {
        if (!nowY) {
            nowY = 0;
            this.lastY = 0;
        }
        var dx = nowY - this.lastY;
        dx += this.cont1Y.clientHeight;
        this.cont1Y.style.height = dx + 'px';
        this.cont1Y1.style.cssText = 'height: ' + (dx - 125) + 'px !important';
        dx += this.splitterY.clientHeight;
        dx = this.windowHeight - dx;
        this.cont2Y.style.height = dx - 90 + 'px';
        this.lastY = nowY;
        if (this.cont2Y) {
            var extra = 0.218 * window.innerHeight;
            if (window.innerWidth < 1520) {
                extra = 220;
            }
            var cont2YHeight = this.windowHeight - this.cont1Y.clientHeight - extra;
            this.cont1.style.height = cont2YHeight + 32 + 'px';
            this.cont2.style.height = cont2YHeight + 32 + 'px';
            this.cont2Y1.style.cssText = 'height: ' + cont2YHeight + 'px !important';
            this.cont2Y2.style.cssText = 'height: ' + cont2YHeight + 'px !important';
        }
    };
    CompanySalaryBatchesComponent.prototype.resetSplitter = function () {
        if (!this.splitter ||
            !this.splitterY ||
            !this.cont1 ||
            !this.cont2 ||
            !this.cont1Y ||
            !this.cont1Y1 ||
            !this.cont2Y ||
            !this.cont2Y1 ||
            !this.cont2Y2) {
            return;
        }
        // Remove height
        this.cont1.style.height = '';
        this.cont2.style.height = '';
        this.cont1.style.width = '';
        this.cont2.style.width = '';
        this.cont1Y.style.height = '';
        this.cont1Y1.style.height = '';
        this.cont2Y.style.height = '';
        this.cont2Y1.style.height = '';
        this.cont2Y2.style.height = '';
    };
    return CompanySalaryBatchesComponent;
}());
export { CompanySalaryBatchesComponent };
