import { OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from '../common/global';
import { SessionService } from './session/session.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./session/session.service";
export var LoadingIndicatorTextType;
(function (LoadingIndicatorTextType) {
    LoadingIndicatorTextType[LoadingIndicatorTextType["RECALCULATE"] = 0] = "RECALCULATE";
    LoadingIndicatorTextType[LoadingIndicatorTextType["FINALIZE"] = 1] = "FINALIZE";
})(LoadingIndicatorTextType || (LoadingIndicatorTextType = {}));
var BusyIndicatorService = /** @class */ (function () {
    function BusyIndicatorService(translateService, sessionService) {
        var _this = this;
        this.translateService = translateService;
        this.sessionService = sessionService;
        this.ngUnsubscribe = new Subject();
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (e) {
            return _this.applyTranslations();
        });
        this.sessionService.OnLocationChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (state) {
            return _this.checkState();
        });
    }
    BusyIndicatorService.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    BusyIndicatorService.prototype.applyTranslations = function () {
        var _this = this;
        var langCode = Global.SESSION && Global.SESSION.SignOnToken.Language
            ? Global.SESSION.SignOnToken.Language.CultureCode.split('-')[0]
            : undefined;
        if (this.translateService.currentLang !== langCode) {
            this.translateService.use(langCode).subscribe(function () {
                _this.translateText();
            });
        }
        else {
            this.translateText();
        }
    };
    BusyIndicatorService.prototype.translateText = function () {
        var _this = this;
        this.translateService
            .get([
            'CompanySalaryBatches.RecalculatingDraft',
            'CompanySalaryBatches.CalculatingDraftHint',
            'CompanySalaryBatches.Finalizing'
        ])
            .subscribe(function (translations) {
            _this.recalulatingText = translations['CompanySalaryBatches.RecalculatingDraft'];
            _this.recalculatingHint = translations['CompanySalaryBatches.CalculatingDraftHint'];
            _this.finalizingText = translations['CompanySalaryBatches.Finalizing'];
            if (_this.isVisible && !_this.isTextOverride) {
                var loadingText = _this.getLoadingText();
                _this.busyIndicatorText = loadingText ? loadingText : '';
            }
        });
    };
    BusyIndicatorService.prototype.checkState = function () {
        if (this.sessionService.currentState !== 'tabs.company.salarybatches') {
            this.setLoadingIndicatorVisibility(false);
        }
    };
    BusyIndicatorService.prototype.setLoadingIndicatorVisibility = function (isVisible, textType, overrideText, showCalculatingHint) {
        var _this = this;
        if (isVisible) {
            this.isTextOverride = overrideText ? true : false;
            this.isShowHint = showCalculatingHint;
            this.textType = textType;
        }
        if (!overrideText) {
            overrideText = this.getLoadingText();
        }
        setTimeout(function () {
            _this.busyIndicatorText = overrideText;
            _this.isVisible = isVisible;
        });
    };
    BusyIndicatorService.prototype.getLoadingText = function () {
        var calculatingText = (this.recalulatingText ? this.recalulatingText : '') +
            (this.isShowHint && this.recalculatingHint ? '<br/>' + this.recalculatingHint : '');
        return (this.textType === LoadingIndicatorTextType.RECALCULATE ? calculatingText : this.finalizingText) || '';
    };
    BusyIndicatorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BusyIndicatorService_Factory() { return new BusyIndicatorService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.SessionService)); }, token: BusyIndicatorService, providedIn: "root" });
    return BusyIndicatorService;
}());
export { BusyIndicatorService };
