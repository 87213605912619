// tslint:disable:prefer-const
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
var ApiDataService = /** @class */ (function () {
    function ApiDataService() {
        this.baseUrl = environment.apiUrl;
        this.datePipe = new DatePipe('en-US');
    }
    /**
     * Updates an existing Account's data.
     */
    ApiDataService.prototype.Account_UpdateAccount = function (account) {
        var path = this.baseUrl + '/api/account';
        return this.httpPut(path, account);
    };
    /**
     * Updates an existing Account's data.
     */
    ApiDataService.prototype.Account_PatchAccount = function (account) {
        var path = this.baseUrl + '/api/account';
        return this.httpPatch(path, account);
    };
    /**
     * Retrieves information about the Account object related to the current user. An Account is a containing entity for
     * Companies and Users, and can refer to a payroll agency, accountant firm, corporation or similar.
     */
    ApiDataService.prototype.Account_GetUserAccount = function () {
        var path = this.baseUrl + '/api/account/user';
        return this.httpGet(path);
    };
    /**
     * Retrieves information about the Account object related to the current company. An Account is a containing entity
     * for Companies and Users, and can refer to a payroll agency, accountant firm, corporation or similar.
     */
    ApiDataService.prototype.Account_GetCompanyAccount = function () {
        var path = this.baseUrl + '/api/account/company';
        return this.httpGet(path);
    };
    /**
     * Gets any accounts that can the current company can join
     */
    ApiDataService.prototype.Account_GetJoinablePublicAccounts = function () {
        var path = this.baseUrl + '/api/account/public';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all companies that belong to the current Account, with various information targeted towards
     * accountants/bookkeepers. Note that this method cannot be used in conjunction with PUT or POST methods, it is
     * read-only.
     */
    ApiDataService.prototype.Account_GetAccountCompanies = function () {
        var path = this.baseUrl + '/api/account/companies';
        return this.httpGet(path);
    };
    /**
     * Creates a new company belonging to the current Account. Automatically grants administrator access to the current
     * user. Provides options to copy configuration from an existing company.
     * @param An object containing information about the company to create.
     */
    ApiDataService.prototype.Account_CreateCompany = function (request) {
        var path = this.baseUrl + '/api/account/companies';
        return this.httpPost(path, request);
    };
    /**
     * Attaches an existing Intect company to the current Account. Requires that the current user already has full
     * administrator access to the company.
     * @param The ID of the company to attach.
     */
    ApiDataService.prototype.Account_AttachCompany = function (companyId) {
        var path = this.baseUrl + '/api/account/companies/{companyId}/attach';
        path = path.replace('{companyId}', companyId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Attaches the current company to the target Account.
     * @param The id of the Account to join.
     */
    ApiDataService.prototype.Account_AttachCurrentCompany = function (accountId) {
        var path = this.baseUrl + '/api/account/{accountId}/companies/current/attach';
        path = path.replace('{accountId}', accountId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Removes an existing Intect company from the current Account. Requires that the current user already has full
     * account administrator access.
     * @param The ID of the company to remove.
     */
    ApiDataService.prototype.Account_RemoveCompany = function (companyId) {
        var path = this.baseUrl + '/api/account/companies/{companyId}';
        path = path.replace('{companyId}', companyId.toString());
        return this.httpDelete(path);
    };
    /**
     * Sets AccountComment for the relevant company. This field is only visible to users associated with the company's
     * Account.
     * @param The ID of the company for which to update the comment.
     * @param An object containing the body of the comment.
     */
    ApiDataService.prototype.Account_SetAccountComment = function (companyId, request) {
        var path = this.baseUrl + '/api/account/companies/{companyId}/comment';
        path = path.replace('{companyId}', companyId.toString());
        return this.httpPut(path, request);
    };
    /**
     * Removes a User from the current user's Account (M-C organization).
     */
    ApiDataService.prototype.Account_RemoveUser = function (userId) {
        var path = this.baseUrl + '/api/account/users/{userId}';
        path = path.replace('{userId}', userId.toString());
        return this.httpDelete(path);
    };
    /**
     * Retrieves a list of all users that belong to the current Account.
     */
    ApiDataService.prototype.Account_GetAccountUsers = function () {
        var path = this.baseUrl + '/api/account/users';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all users that belong to the current Account.
     */
    ApiDataService.prototype.Account_GetCompanyAccountUsers = function () {
        var path = this.baseUrl + '/api/account/company/users';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all users that belong to the current Account, along with information about their access to
     * the specified company.
     * @param The ID of the company for which to relate the access information.
     */
    ApiDataService.prototype.Account_GetUserCompanyAccess = function (companyId) {
        var path = this.baseUrl + '/api/account/companies/{companyId}/users';
        path = path.replace('{companyId}', companyId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all companies that belong to the current Account, along with information about a specific
     * user's access to that company.
     * @param The ID of the user for which to relate the access information.
     */
    ApiDataService.prototype.Account_GetCompanyAccessByUser = function (userId) {
        var path = this.baseUrl + '/api/account/users/{userId}/companies';
        path = path.replace('{userId}', userId.toString());
        return this.httpGet(path);
    };
    /**
     * Grants or revokes access to a specific company for a user within the relevant Account. This can have the effect
     * of either creating, updating, or deactivating a CompanyUser.
     * @param The ID of the company for which to grant or revoke access.
     * @param The ID of the user to assign access to.
     * @param The ID of the Role (api/staticdata/Role) to assign the user in the specified company. Send 0 to revoke
     *        access, deactivating the CompanyUser.
     * @param Indicates whether the user should be allowed to finalize payroll batches. This is defined independently of
     *        the role. Submit "true" or "false".
     */
    ApiDataService.prototype.Account_AssignUserAccessToCompany = function (companyId, userId, roleId, isPaymentApprover) {
        var path = this.baseUrl +
            '/api/account/companies/{companyId}/user/{userId}/role/{roleId}/ispaymentapprover/{isPaymentApprover}';
        path = path.replace('{companyId}', companyId.toString());
        path = path.replace('{userId}', userId.toString());
        path = path.replace('{roleId}', roleId.toString());
        path = path.replace('{isPaymentApprover}', isPaymentApprover.toString());
        return this.httpPut(path, undefined);
    };
    /**
     * Grants or revokes access to a specific company for a user within the relevant Account. This can have the effect
     * of either creating, updating, or deactivating a CompanyUser.
     * @param An object containing information about the user permissions to assign.
     */
    ApiDataService.prototype.Account_AssignUserAccessToCompany2 = function (request) {
        var path = this.baseUrl + '/api/account/accesspermissions';
        return this.httpPut(path, request);
    };
    /**
     * Grants or revokes access to companies for users within the relevant Account. This can have the effect of either
     * creating, updating, or deactivating CompanyUsers.
     * @param A list of objects containing information about the user permissions to assign.
     */
    ApiDataService.prototype.Account_BatchAssignUsersAccessToCompany = function (requests) {
        var path = this.baseUrl + '/api/account/accesspermissions/batch';
        return this.httpPut(path, requests);
    };
    /**
     * Submits a user identifier (e-mail, phone number, CPR number or username) to retrieve a session token.
     */
    ApiDataService.prototype.Auth_Login = function () {
        var path = this.baseUrl + '/api/auth/login';
        return this.httpPost(path, undefined);
    };
    ApiDataService.prototype.Auth_RefreshLogin = function () {
        var path = this.baseUrl + '/api/auth/login/refresh';
        return this.httpPost(path, undefined);
    };
    /**
     * Terminates a login session.
     */
    ApiDataService.prototype.Auth_Logout = function () {
        var path = this.baseUrl + '/api/auth/logout';
        return this.httpPost(path, undefined);
    };
    /**
     * Retrieves information about the current login session.
     */
    ApiDataService.prototype.Auth_GetSessionInfo = function () {
        var path = this.baseUrl + '/api/auth/session';
        return this.httpGet(path);
    };
    /**
     * Determines whether the login session is still active (response code 200 OK) or has expired (response code 401
     * Unauthorized).
     *
     * NOTE: A) Calling this method will not extend the lifetime of the session. B) This method is also used to check
     * for important notifications. If such a notification is relevant, it will be returned with a 400 Bad Request
     * response.
     */
    ApiDataService.prototype.Auth_IsSessionAlive = function () {
        var path = this.baseUrl + '/api/auth/session/isalive';
        return this.httpGet(path);
    };
    /**
     * Changes the password of the currently logged-in user.
     */
    ApiDataService.prototype.Auth_ChangePassword = function (request) {
        var path = this.baseUrl + '/api/auth/changepw';
        return this.httpPost(path, request);
    };
    ApiDataService.prototype.Auth_ValidatePassword = function (request) {
        var path = this.baseUrl + '/api/auth/validatepw';
        return this.httpPost(path, request);
    };
    /**
     * Requests a password reset. Identity number (CPR) and e-mail must match. NOTE: This method is deprecated, use
     * RequestPasswordResetWithLanguage instead.
     */
    ApiDataService.prototype.Auth_RequestPasswordReset = function (request) {
        var path = this.baseUrl + '/api/auth/resetpw';
        return this.httpPost(path, request);
    };
    /**
     * Requests a password reset. Identity number (CPR) and e-mail must match.
     * @param The language code for any messages that should be returned to the user. Valid values are "dk", "gl" or
     *        "en".
     * @param An object containing the information necessary to identify the user.
     */
    ApiDataService.prototype.Auth_RequestPasswordResetWithLanguage = function (languageCode, request) {
        var path = this.baseUrl + '/api/auth/resetpw/lang/{languageCode}';
        path = path.replace('{languageCode}', languageCode.toString());
        return this.httpPost(path, request);
    };
    /**
     * Sets the current company context for the login session. All subsequent calls will be taken in the context of the
     * specified company.
     */
    ApiDataService.prototype.Auth_SetCompanyContext = function (companyId) {
        var path = this.baseUrl + '/api/auth/company/{companyId}';
        path = path.replace('{companyId}', companyId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Sets the current language context for the login session. All subsequent server messages etc. will be served in
     * the specified language. The language will also be saved as the user's preferred one.
     */
    ApiDataService.prototype.Auth_SetLanguageContext = function (languageId) {
        var path = this.baseUrl + '/api/auth/language/{languageId}';
        path = path.replace('{languageId}', languageId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Sends invalid email notifications to the administrator of the passed list of emails. NOTE: This method is only
     * accessible to Intect employees.
     */
    ApiDataService.prototype.Backend_NotifyAdminsOfFailedEmails = function (emails) {
        var path = this.baseUrl + '/api/backend/emailnotify';
        path += '?emails=' + emails;
        return this.httpPost(path, undefined);
    };
    /**
     * Registers billable usage on a company, typically support. This method is available to Intect employees only.
     * @param An object containing information about the module and amount of units to be billed.
     */
    ApiDataService.prototype.Backend_RegisterUsage = function (usage) {
        var path = this.baseUrl + '/api/backend/usage';
        return this.httpPost(path, usage);
    };
    /**
     * Creates a copy of the current database with the specified name on the same SQL Azure server. Note this method is
     * for internal development use only. It should not be called by external API clients, and it will not work in a
     * production environment.
     * @param The desired name of the new database. Note the input is sanitized, and any spaces (among other things) are
     *        removed.
     */
    ApiDataService.prototype.Backend_CreateDatabaseCopy = function (newDatabaseName) {
        var path = this.baseUrl + '/api/backend/copydatabase/{newDatabaseName}';
        path = path.replace('{newDatabaseName}', newDatabaseName.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Retrieves a list of available balance definitions, along with any associated periods.
     */
    ApiDataService.prototype.Balances_GetBalanceDefinitions = function () {
        var path = this.baseUrl + '/api/balances/definitions';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of balance periods with descriptive names, specifically for Danish vacation.
     */
    ApiDataService.prototype.Balances_GetDanishVacationBalancePeriods = function () {
        var path = this.baseUrl + '/api/balances/periods/dkvacation';
        return this.httpPost(path, undefined);
    };
    /**
     * Retrieves a list of balances for the specified definition and optionally period.
     */
    ApiDataService.prototype.Balances_GetBalances = function (request) {
        var path = this.baseUrl + '/api/balances';
        return this.httpPost(path, request);
    };
    /**
     * Submits a request to correct balances.
     */
    ApiDataService.prototype.Balances_AdjustBalances = function (request) {
        var path = this.baseUrl + '/api/balances/adjust';
        return this.httpPost(path, request);
    };
    /**
     * Retrieves a list of all companies that the user has access to.
     */
    ApiDataService.prototype.Companies_GetAll = function () {
        var path = this.baseUrl + '/api/companies';
        return this.httpGet(path);
    };
    /**
     * Updates the target company.
     * @param The company to update. Id must match an existing company.
     */
    ApiDataService.prototype.Companies_UpdateCompany = function (company) {
        var path = this.baseUrl + '/api/companies';
        return this.httpPut(path, company);
    };
    /**
     * Updates the target company.
     * @param The company to update. Id must match an existing company.
     */
    ApiDataService.prototype.Companies_PatchCompany = function (company) {
        var path = this.baseUrl + '/api/companies';
        return this.httpPatch(path, company);
    };
    /**
     * Retrieves a simplified list of all companies that the user has access to - including only a few key properties.
     */
    ApiDataService.prototype.Companies_GetAllSimple = function () {
        var path = this.baseUrl + '/api/companies/simple';
        return this.httpGet(path);
    };
    /**
     * Submits a request to sign up as a new customer - creating an account, a company and a user. This method may be
     * called anonymously.
     * @param An object containing information about the company to create. Note that AccountId and RequestingUserId
     *        must always be null when using this route. To create a new company when logged on, call POST to
     *        api/account/companies instead.
     */
    ApiDataService.prototype.Companies_Signup = function (request) {
        var path = this.baseUrl + '/api/companies/signup';
        return this.httpPost(path, request);
    };
    /**
     * DEPRECATED: Gets information about a (danish) company by CVR number. Powered by www.cvrapi.dk.
     */
    ApiDataService.prototype.Companies_GetByCvr = function (cvrNumber) {
        var path = this.baseUrl + '/api/companies/cvr/{cvrNumber}';
        path = path.replace('{cvrNumber}', cvrNumber.toString());
        return this.httpGet(path);
    };
    /**
     * Gets information about a company by Vat number. Powered by cvrapi.dk and allabolag.se
     */
    ApiDataService.prototype.Companies_GetByVatNumber = function (vatNumber) {
        var path = this.baseUrl + '/api/companies/vatnumber/{vatNumber}';
        path = path.replace('{vatNumber}', vatNumber.toString());
        return this.httpGet(path);
    };
    /**
     * Gets information about the currently active company.
     */
    ApiDataService.prototype.Companies_GetCurrent = function () {
        var path = this.baseUrl + '/api/companies/current';
        return this.httpGet(path);
    };
    /**
     * Updates the currently active company.
     * @param The company to update.
     */
    ApiDataService.prototype.Companies_UpdateCurrent = function (company) {
        var path = this.baseUrl + '/api/companies/current';
        return this.httpPut(path, company);
    };
    /**
     * Updates the currently active company.
     * @param The company to update.
     */
    ApiDataService.prototype.Companies_PatchCurrent = function (company) {
        var path = this.baseUrl + '/api/companies/current';
        return this.httpPatch(path, company);
    };
    /**
     * Gets information about the specified company, naturally assuming you have access to it.
     */
    ApiDataService.prototype.Companies_Get = function (companyId) {
        var path = this.baseUrl + '/api/companies/{companyId}';
        path = path.replace('{companyId}', companyId.toString());
        return this.httpGet(path);
    };
    /**
     * Gets the currently active company's logo as a raw byte array. Defaults to the current user if no Id is specified.
     */
    ApiDataService.prototype.Companies_GetCompanyLogo = function () {
        var path = this.baseUrl + '/api/companies/current/logo';
        return this.httpGet(path);
    };
    /**
     * Sets the current company's logo. The image must be attached to the request as a file.
     */
    ApiDataService.prototype.Companies_SetCompanyLogo = function () {
        var path = this.baseUrl + '/api/companies/current/logo';
        return this.httpPost(path, undefined);
    };
    /**
     * Gets a list of departments in the currently active company.
     */
    ApiDataService.prototype.Companies_GetDepartments = function () {
        var path = this.baseUrl + '/api/companies/current/departments';
        return this.httpGet(path);
    };
    /**
     * Updates an existing department under the currently active company.
     * @param The department to update.
     */
    ApiDataService.prototype.Companies_UpdateDepartment = function (department) {
        var path = this.baseUrl + '/api/companies/current/departments';
        return this.httpPut(path, department);
    };
    /**
     * Updates an existing department under the currently active company.
     * @param The department to update.
     */
    ApiDataService.prototype.Companies_PatchDepartment = function (department) {
        var path = this.baseUrl + '/api/companies/current/departments';
        return this.httpPatch(path, department);
    };
    /**
     * Creates a new department under the currently active company.
     * @param The department to create.
     */
    ApiDataService.prototype.Companies_CreateDepartment = function (department) {
        var path = this.baseUrl + '/api/companies/current/departments';
        return this.httpPost(path, department);
    };
    /**
     * Deletes or deactivates an existing department in the currently active company. If the department references any
     * employees, templates or payroll batch records it will be deactivated; otherwise deleted.
     * @param The ID of the department to update.
     */
    ApiDataService.prototype.Companies_DeleteDepartment = function (departmentId) {
        var path = this.baseUrl + '/api/companies/current/departments/{departmentId}';
        path = path.replace('{departmentId}', departmentId.toString());
        return this.httpDelete(path);
    };
    /**
     * Specifies that the company has accepted Intect's general terms. This is a legacy method only relevant for
     * companies that signed up in May 2018 or earlier.
     * @param An object containing the password of the currently logged-in user.
     */
    ApiDataService.prototype.Companies_AcceptTerms = function (password) {
        var path = this.baseUrl + '/api/companies/current/terms';
        return this.httpPost(path, password);
    };
    /**
     * Gets a list of all active users in the currently active company (i.e. CompanyUsers) along with their employment
     * info (if any).
     */
    ApiDataService.prototype.CompanyUsers_GetCompanyUsers = function () {
        var path = this.baseUrl + '/api/companyusers';
        return this.httpGet(path);
    };
    /**
     * Updates an existing company user, including submitted Address and UserEmployment child objects (but not child
     * objects of UserEmployments).
     *
     * Notice that other child objects cannot be added and removed this way, only modified. Provide null values (not
     * empty lists) to leave child objects unmodified.
     *
     * Note that the convenience fields UserLanguageId and UserIdentityNumber, which really belong to the User object in
     * the hiarchy, may be updated this way.
     *
     * Finally, notice that users with the Employee role can only update their own CompanyUsers, and NOT the attached
     * UserEmployment objects.
     * @param The company user to update.
     */
    ApiDataService.prototype.CompanyUsers_UpdateCompanyUser = function (companyUser) {
        var path = this.baseUrl + '/api/companyusers';
        return this.httpPut(path, companyUser);
    };
    /**
     * Adds a user to the currently active company, optionally specifying EITHER a set of employment data OR a template
     * to use for the employment. The user is created in the Intect database if he/she does not already exist, or mapped
     * to the company if he/she does.
     * @param An object containing information about the user to create. Note: To generate a dummy CPR number, specify
     *        "DDMMYYXXXX" where DDMMYY is the user's birth date and XXXX are literal values.
     */
    ApiDataService.prototype.CompanyUsers_AddUserToCompany = function (request) {
        var path = this.baseUrl + '/api/companyusers';
        return this.httpPost(path, request);
    };
    /**
     * Gets a list of all users (including inactive ones) in the currently active company (i.e. CompanyUsers) along with
     * their employment info (if any).
     */
    ApiDataService.prototype.CompanyUsers_GetAllCompanyUsers = function () {
        var path = this.baseUrl + '/api/companyusers/all';
        return this.httpGet(path);
    };
    /**
     * Gets a simplified list of users in the currently active company (i.e. CompanyUsers), exposing only a few key
     * properties. This method is exposed as POST, allowing you to submit a set of filters and parameters in the request
     * body.
     */
    ApiDataService.prototype.CompanyUsers_GetAllCompanyUsersWithOptions = function (request) {
        var path = this.baseUrl + '/api/companyusers/options';
        return this.httpPost(path, request);
    };
    /**
     * Gets a simplified list of all active users in the currently active company (i.e. CompanyUsers), exposing only a
     * few key properties.
     */
    ApiDataService.prototype.CompanyUsers_GetCompanyUsersSimple = function () {
        var path = this.baseUrl + '/api/companyusers/simple';
        return this.httpGet(path);
    };
    /**
     * Gets a simplified list of all users (including inactive ones) in the currently active company (i.e.
     * CompanyUsers), exposing only a few key properties.
     */
    ApiDataService.prototype.CompanyUsers_GetAllCompanyUsersSimple = function () {
        var path = this.baseUrl + '/api/companyusers/simple/all';
        return this.httpGet(path);
    };
    /**
     * Gets a simplified list of all users (including inactive ones) in the currently active company (i.e.
     * CompanyUsers), exposing only a few key properties.
     */
    ApiDataService.prototype.CompanyUsers_GetAllHiddenCompanyUsersSimple = function () {
        var path = this.baseUrl + '/api/companyusers/simple/includehidden';
        return this.httpGet(path);
    };
    /**
     * Gets a simplified list of users in the currently active company (i.e. CompanyUsers), exposing only a few key
     * properties. This method is exposed as POST, allowing you to submit a set of filters and parameters in the request
     * body.
     */
    ApiDataService.prototype.CompanyUsers_GetAllCompanyUsersSimpleWithOptions = function (request) {
        var path = this.baseUrl + '/api/companyusers/simple/options';
        return this.httpPost(path, request);
    };
    /**
     * Updates existing CompanyUsers, including submitted Address and UserEmployment child objects (to be modified, not
     * added or removed).
     *
     * Additionally you may optionally submit a list of UserEmployment.EmploymentBalanceConfiguration objects, which
     * will then replace all existing records of that type.
     *
     * Provide null values (not empty lists) to leave child objects unmodified.
     *
     * Note also that the convenience fields UserLanguageId and UserIdentityNumber, which really belong to the User
     * object in the hiarchy, may be updated this way.
     *
     * Finally, notice that users with the Employee role can only update their own CompanyUser object, and NOT the
     * attached UserEmployment objects.
     * @param A list of company users to update.
     */
    ApiDataService.prototype.CompanyUsers_UpdateCompanyUsers = function (companyUsers) {
        var path = this.baseUrl + '/api/companyusers/batch';
        return this.httpPut(path, companyUsers);
    };
    /**
     * Adds users to the currently active company, optionally specifying either a set of employment data or a template
     * to use for the employment. The users are created in the Intect database if they do not already exist, or mapped
     * to the company if they do.
     * @param A list of objects containing information about the users to create. Note: To generate a dummy CPR number,
     *        specify "DDMMYYXXXX" where DDMMYY is the user's birth date and XXXX are literal values.
     */
    ApiDataService.prototype.CompanyUsers_AddUsersToCompany = function (requests) {
        var path = this.baseUrl + '/api/companyusers/batch';
        return this.httpPost(path, requests);
    };
    /**
     * Retrieves detailed information about the specified company user.
     * @param The Id of the company user to retrieve information about.
     */
    ApiDataService.prototype.CompanyUsers_GetCompanyUserById = function (companyUserId) {
        var path = this.baseUrl + '/api/companyusers/{companyUserId}';
        path = path.replace('{companyUserId}', companyUserId.toString());
        return this.httpGet(path);
    };
    /**
     * Deletes the specified company user. Only allowed if the user has no finalized payslips or other data that must be
     * preserved.
     * @param The Id of the company user to delete.
     */
    ApiDataService.prototype.CompanyUsers_DeleteCompanyUser = function (companyUserId) {
        var path = this.baseUrl + '/api/companyusers/{companyUserId}';
        path = path.replace('{companyUserId}', companyUserId.toString());
        return this.httpDelete(path);
    };
    /**
     * Resends welcome e-mail to the specified CompanyUser. This includes resetting their password.
     * @param The Id of the CompanyUser to resend the e-mail to.
     */
    ApiDataService.prototype.CompanyUsers_ResendWelcomeEmail = function (companyUserId) {
        var path = this.baseUrl + '/api/companyusers/{companyUserId}/resendemail';
        path = path.replace('{companyUserId}', companyUserId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Resets the password for the specified user.
     *
     * <param name="companyUserId">The Id of the CompanyUser whose password should be reset.</param>
     */
    ApiDataService.prototype.CompanyUsers_ResetUserPassword = function (companyUserId) {
        var path = this.baseUrl + '/api/companyusers/{companyUserId}/resetpw';
        path = path.replace('{companyUserId}', companyUserId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Retrieves a list of all possible dimension values for the specified dimension number.
     * @param The number of the dimension to retrieve values for - i.e. 1, 2 or 3.
     */
    ApiDataService.prototype.DimensionValues_GetDimensionValues = function (dimensionNumber) {
        var path = this.baseUrl + '/api/dimensionvalues/{dimensionNumber}';
        path = path.replace('{dimensionNumber}', dimensionNumber.toString());
        return this.httpGet(path);
    };
    /**
     * Updates an existing dimension value.
     * @param The dimension value to update.
     */
    ApiDataService.prototype.DimensionValues_UpdateDimensionValue = function (dimensionValue) {
        var path = this.baseUrl + '/api/dimensionvalues';
        return this.httpPut(path, dimensionValue);
    };
    /**
     * Updates an existing dimension value.
     * @param The dimension value to update.
     */
    ApiDataService.prototype.DimensionValues_PatchDimensionValue = function (dimensionValue) {
        var path = this.baseUrl + '/api/dimensionvalues';
        return this.httpPatch(path, dimensionValue);
    };
    /**
     * Creates a dimension value.
     * @param The record to create.
     */
    ApiDataService.prototype.DimensionValues_CreateDimensionValue = function (dimensionValue) {
        var path = this.baseUrl + '/api/dimensionvalues';
        return this.httpPost(path, dimensionValue);
    };
    /**
     * Deletes an existing dimension value. If existing records refer to the value, it will be deactivated behind the
     * scenes instead of deleted outright.
     * @param The ID of the dimension value to delete or deactivate.
     */
    ApiDataService.prototype.DimensionValues_DeleteDimensionValue = function (dimensionValueId) {
        var path = this.baseUrl + '/api/dimensionvalues/{dimensionValueId}';
        path = path.replace('{dimensionValueId}', dimensionValueId.toString());
        return this.httpDelete(path);
    };
    /**
     * Gets a list of all employments in the company, along with many additional properties taken from the CompanyUser
     * and User objects.
     */
    ApiDataService.prototype.Employments_GetEmploymentViews = function () {
        var path = this.baseUrl + '/api/employments/overview';
        return this.httpGet(path);
    };
    ApiDataService.prototype.Employments_GetEmploymentViewsByUpdated = function (updatedAfter) {
        var path = this.baseUrl + '/api/employments/updatedafter/{updatedAfter}';
        path = path.replace('{updatedAfter}', this.datePipe.transform(updatedAfter, 'yyyy-MM-dd') || '');
        return this.httpGet(path);
    };
    /**
     * Gets a list of all employments in the company for the current CompanyUser, along with many additional properties
     * taken from the CompanyUser and User objects.
     */
    ApiDataService.prototype.Employments_GetCurrentEmploymentViews = function () {
        var path = this.baseUrl + '/api/employments/current';
        return this.httpGet(path);
    };
    /**
     * Gets a list of all employments on the specified CompanyUser.
     */
    ApiDataService.prototype.Employments_GetEmploymentsByCompanyUser = function (companyUserId) {
        var path = this.baseUrl + '/api/employments/companyuser/{companyUserId}';
        path = path.replace('{companyUserId}', companyUserId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves detailed information about the specified employment.
     * @param The Id of the employment to retrieve information about.
     */
    ApiDataService.prototype.Employments_GetEmployment = function (userEmploymentId) {
        var path = this.baseUrl + '/api/employments/{userEmploymentId}';
        path = path.replace('{userEmploymentId}', userEmploymentId.toString());
        return this.httpGet(path);
    };
    /**
     * Updates an existing employment.
     * @param The employment to update.
     */
    ApiDataService.prototype.Employments_UpdateEmployment = function (employment) {
        var path = this.baseUrl + '/api/employments';
        return this.httpPut(path, employment);
    };
    /**
     * Updates an existing employment.
     * @param The employment to update.
     */
    ApiDataService.prototype.Employments_PatchEmployment = function (employment) {
        var path = this.baseUrl + '/api/employments';
        return this.httpPatch(path, employment);
    };
    /**
     * Creates a new employment for the specified company user.
     * @param Details about the employment. Title and CompanyUserId are always mandatory. You may fill out all other
     *        fields except BasedOnTemplateId, or just BasedOnTemplateId. If the latter field has a value, most other
     *        fields are taken from the template, ignoring the input values provided.
     */
    ApiDataService.prototype.Employments_CreateUserEmployment = function (employment) {
        var path = this.baseUrl + '/api/employments';
        return this.httpPost(path, employment);
    };
    /**
     * Creates new employments for specified company users and updates existing employments.
     * @param List of employment objects.
     *
     *        Employments with id=0 will be created with the specified details. Title and CompanyUserId are mandatory
     *        for them. You may fill out all other fields except BasedOnTemplateId, or just BasedOnTemplateId. If the
     *        latter field has a value, most other fields are taken from the template, ignoring the input values
     *        provided.
     *
     *        Employments with Id &gt; 0 will be updated based on the data provided.
     */
    ApiDataService.prototype.Employments_UpsertUserEmployments = function (employments) {
        var path = this.baseUrl + '/api/employments/batch';
        return this.httpPost(path, employments);
    };
    /**
     * Deletes the specified employment. Only allowed if the employment has no finalized payslips or other data that
     * must be preserved.
     * @param The Id of the employment to delete.
     */
    ApiDataService.prototype.Employments_DeleteUserEmployment = function (employmentId) {
        var path = this.baseUrl + '/api/employments/{employmentId}';
        path = path.replace('{employmentId}', employmentId.toString());
        return this.httpDelete(path);
    };
    /**
     * Retrieves a list of previously used titles for employments in the currently active company.
     */
    ApiDataService.prototype.Employments_GetUsedTitles = function () {
        var path = this.baseUrl + '/api/employments/titles';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of tax cards for the specified employment.
     * @param The Id of the employment to retrieve tax cards for.
     */
    ApiDataService.prototype.Employments_GetTaxCards = function (userEmploymentId) {
        var path = this.baseUrl + '/api/employments/{userEmploymentId}/taxcards';
        path = path.replace('{userEmploymentId}', userEmploymentId.toString());
        return this.httpGet(path);
    };
    /**
     * Submits a request for updated tax card information.
     * @param The Id of the employment for which to request a tax card.
     * @param The type of tax card to request (Main / Secondary / Free).
     */
    ApiDataService.prototype.Employments_RequestTaxInfo = function (userEmploymentId, taxCardTypeId) {
        var path = this.baseUrl + '/api/employments/{userEmploymentId}/taxcards/requestnew/type/{taxCardTypeId}';
        path = path.replace('{userEmploymentId}', userEmploymentId.toString());
        path = path.replace('{taxCardTypeId}', taxCardTypeId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Retrieves the currently active tax info for the specified employment.
     * @param The Id of the employment to retrieve tax information about.
     */
    ApiDataService.prototype.Employments_GetCurrentTaxInfo = function (userEmploymentId) {
        var path = this.baseUrl + '/api/employments/{userEmploymentId}/taxcards/current';
        path = path.replace('{userEmploymentId}', userEmploymentId.toString());
        return this.httpGet(path);
    };
    /**
     * Updates a tax information record. Note that for now, only the monthly deduction can be updated. The daily and
     * weekly values will be modified accordingly. This method can only be used for tax cards that were created manually
     * with a POST call, not tax cards retrieved from E-Income.
     * @param The tax information record to update.
     */
    ApiDataService.prototype.Employments_UpdateTaxInfo = function (taxInfo) {
        var path = this.baseUrl + '/api/employments/taxcards';
        return this.httpPut(path, taxInfo);
    };
    /**
     * Updates a tax information record. Note that for now, only the monthly deduction can be updated. The daily and
     * weekly values will be modified accordingly. This method can only be used for tax cards that were created manually
     * with a POST call, not tax cards retrieved from E-Income.
     * @param The tax information record to update.
     */
    ApiDataService.prototype.Employments_PatchTaxInfo = function (taxInfo) {
        var path = this.baseUrl + '/api/employments/taxcards';
        return this.httpPatch(path, taxInfo);
    };
    /**
     * Creates a new tax information record (tax card).
     * @param The tax information record to create.
     */
    ApiDataService.prototype.Employments_CreateTaxInfo = function (taxInfo) {
        var path = this.baseUrl + '/api/employments/taxcards';
        return this.httpPost(path, taxInfo);
    };
    /**
     * Updates an employment to match their currently set template.
     * @param The Id of the employment to update.
     */
    ApiDataService.prototype.Employments_UpdateEmploymentToCurrentTemplate = function (employmentId) {
        var path = this.baseUrl + '/api/employments/{employmentId}/matchtemplate';
        path = path.replace('{employmentId}', employmentId.toString());
        return this.httpPut(path, undefined);
    };
    /**
     * Gets a list of employments in the currently active company (i.e. CompanyUsers), exposing only a few key
     * properties. This method is exposed as POST, allowing you to submit a set of filters and parameters in the request
     * body.
     */
    ApiDataService.prototype.Employments_GetAllUserEmploymentsWithOptions = function (request) {
        var path = this.baseUrl + '/api/employments/options';
        return this.httpPost(path, request);
    };
    /**
     * Retrieves a list of employment templates in the currently active company.
     */
    ApiDataService.prototype.EmploymentTemplates_GetEmploymentTemplates = function () {
        var path = this.baseUrl + '/api/employmenttemplates';
        return this.httpGet(path);
    };
    /**
     * Updates an existing employment template, including (optionally) TemplateSalaryRecord, OvertimeSupplement,
     * SenioritySupplement and TemplateBalanceConfiguration records included in the request (if the required module has
     * been activated). For each of those child collections, all records of the relevant type are replaced with the data
     * contained in the request. Alternatively, submit null (not an empty list) to leave that data unchanged.
     * @param The template to update.
     */
    ApiDataService.prototype.EmploymentTemplates_UpdateEmploymentTemplate = function (template) {
        var path = this.baseUrl + '/api/employmenttemplates';
        return this.httpPut(path, template);
    };
    /**
     * Updates an existing employment template, including (optionally) TemplateSalaryRecord, OvertimeSupplement,
     * SenioritySupplement and TemplateBalanceConfiguration records included in the request (if the required module has
     * been activated). For each of those child collections, all records of the relevant type are replaced with the data
     * contained in the request. Alternatively, submit null (not an empty list) to leave that data unchanged.
     * @param The template to update.
     */
    ApiDataService.prototype.EmploymentTemplates_PatchEmploymentTemplate = function (template) {
        var path = this.baseUrl + '/api/employmenttemplates';
        return this.httpPatch(path, template);
    };
    /**
     * Creates a new employment template.
     * @param The template to create.
     */
    ApiDataService.prototype.EmploymentTemplates_CreateEmploymentTemplate = function (template) {
        var path = this.baseUrl + '/api/employmenttemplates';
        return this.httpPost(path, template);
    };
    /**
     * Retrieves a list of employment templates in the currently active company.
     */
    ApiDataService.prototype.EmploymentTemplates_GetSimpleEmploymentTemplates = function () {
        var path = this.baseUrl + '/api/employmenttemplates/simple';
        return this.httpGet(path);
    };
    /**
     * Retrieves information about the specified employment template.
     * @param The ID of the template to retrieve.
     */
    ApiDataService.prototype.EmploymentTemplates_GetEmploymentTemplate = function (templateId) {
        var path = this.baseUrl + '/api/employmenttemplates/{templateId}';
        path = path.replace('{templateId}', templateId.toString());
        return this.httpGet(path);
    };
    /**
     * Deletes the specified employment template.
     * @param The ID of the template to delete.
     */
    ApiDataService.prototype.EmploymentTemplates_DeleteEmploymentTemplate = function (templateId) {
        var path = this.baseUrl + '/api/employmenttemplates/{templateId}';
        path = path.replace('{templateId}', templateId.toString());
        return this.httpDelete(path);
    };
    /**
     * Updates an existing employment template, and applies the changed values (only) to existing employees based on
     * that template. Note that SenioritySupplement, OvertimeSupplement, TemplateSalaryRecord and
     * TemplateBalanceConfiguration objects (if included) are handled the same way as in the main PUT method.
     * @param The template to update.
     */
    ApiDataService.prototype.EmploymentTemplates_UpdateEmploymentTemplateAndApplyChanges = function (template) {
        var path = this.baseUrl + '/api/employmenttemplates/withapply';
        return this.httpPut(path, template);
    };
    /**
     * Updates an existing employment template, and applies the changed values (only) to existing employees based on
     * that template. Note that SenioritySupplement, OvertimeSupplement, TemplateSalaryRecord and
     * TemplateBalanceConfiguration objects (if included) are handled the same way as in the main PUT method.
     * @param The template to update.
     */
    ApiDataService.prototype.EmploymentTemplates_PatchEmploymentTemplateAndApplyChanges = function (template) {
        var path = this.baseUrl + '/api/employmenttemplates/withapply';
        return this.httpPatch(path, template);
    };
    /**
     * Creates an employment template from an existing employment.
     * @param An object specifing the name of the new template, and the Id of the UserEmployment entity on which to base
     *        the template. Note: Do not provide the Id of a CompanyUser or User entity.
     */
    ApiDataService.prototype.EmploymentTemplates_CreateTemplateFromEmployment = function (request) {
        var path = this.baseUrl + '/api/employmenttemplates/fromemployment';
        return this.httpPost(path, request);
    };
    /**
     * Duplicates existing employment template. Including sub-records like TemplateSalaryRecords, OvertimeSupplement,
     * SenioritySupplements
     * @param The ID of the template to duplicate.
     */
    ApiDataService.prototype.EmploymentTemplates_DuplicateEmploymentTemplate = function (templateId) {
        var path = this.baseUrl + '/api/employmenttemplates/{templateId}/duplicate';
        path = path.replace('{templateId}', templateId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Gets a list of employments that are based on the specified template.
     */
    ApiDataService.prototype.EmploymentTemplates_GetCompanyUsersByEmploymentTemplate = function (templateId) {
        var path = this.baseUrl + '/api/employmenttemplates/{templateId}/companyusers';
        path = path.replace('{templateId}', templateId.toString());
        return this.httpGet(path);
    };
    /**
     * Gets the salary template records with the passed EmploymentTemplate Id.
     * @param The Id to search for.
     */
    ApiDataService.prototype.EmploymentTemplates_GetTemplateSalaryRecordByEmploymentTemplate = function (templateId) {
        var path = this.baseUrl + '/api/employmenttemplates/{templateId}/salaryrecords';
        path = path.replace('{templateId}', templateId.toString());
        return this.httpGet(path);
    };
    /**
     * Updates an existing salary template record template.
     * @param The record to update.
     */
    ApiDataService.prototype.EmploymentTemplates_UpdateTemplateSalaryRecord = function (templateSalaryRecord, templateId) {
        var path = this.baseUrl + '/api/employmenttemplates/{templateId}/salaryrecords';
        path = path.replace('{templateId}', templateId.toString());
        return this.httpPut(path, templateSalaryRecord);
    };
    /**
     * Updates an existing salary template record template.
     * @param The record to update.
     */
    ApiDataService.prototype.EmploymentTemplates_PatchTemplateSalaryRecord = function (templateSalaryRecord, templateId) {
        var path = this.baseUrl + '/api/employmenttemplates/{templateId}/salaryrecords';
        path = path.replace('{templateId}', templateId.toString());
        return this.httpPatch(path, templateSalaryRecord);
    };
    /**
     * Creates a new salary template record.
     * @param The record to create.
     */
    ApiDataService.prototype.EmploymentTemplates_CreateTemplateSalaryRecord = function (record) {
        var path = this.baseUrl + '/api/employmenttemplates/salaryrecords';
        return this.httpPost(path, record);
    };
    /**
     * Deletes a template salary record.
     * @param The ID of the record to delete
     */
    ApiDataService.prototype.EmploymentTemplates_DeleteTemplateSalaryRecord = function (recordId) {
        var path = this.baseUrl + '/api/employmenttemplates/{recordId}/salaryrecords';
        path = path.replace('{recordId}', recordId.toString());
        return this.httpDelete(path);
    };
    /**
     * Gets the salary template records with the passed Id.
     * @param The record to get.
     */
    ApiDataService.prototype.EmploymentTemplates_GetTemplateSalaryRecord = function (recordId) {
        var path = this.baseUrl + '/api/employmenttemplates/salaryrecords/{recordId}';
        path = path.replace('{recordId}', recordId.toString());
        return this.httpGet(path);
    };
    /**
     * Experimental reporting: Retrieve all company id's accessable for the current admin user.
     */
    ApiDataService.prototype.ExternalReports_GetCompanies = function () {
        var path = this.baseUrl + '/api/externalreports/Companies';
        return this.httpPost(path, undefined);
    };
    /**
     * Experimental reporting: Retrieve all external report data for one specific company.
     * @param The ID of the company for which you want to retrieve reporting data.
     */
    ApiDataService.prototype.ExternalReports_GetReportData = function (companyId) {
        var path = this.baseUrl + '/api/externalreports/company/{companyId}';
        path = path.replace('{companyId}', companyId.toString());
        return this.httpGet(path);
    };
    /**
     * Gets the effective holidays in the company
     */
    ApiDataService.prototype.Holidays_GetHolidays = function () {
        var path = this.baseUrl + '/api/holidays';
        return this.httpGet(path);
    };
    /**
     * Sets the specified date as having the specified holiday
     */
    ApiDataService.prototype.Holidays_CreateHoliday = function (request) {
        var path = this.baseUrl + '/api/holidays';
        return this.httpPost(path, request);
    };
    /**
     * Sets the specified date as having the specified holiday
     */
    ApiDataService.prototype.Holidays_DeleteHoliday = function (request) {
        var path = this.baseUrl + '/api/holidays';
        return this.httpDelete(path);
    };
    /**
     * Sets the specified date as having a holiday
     */
    ApiDataService.prototype.Holidays_SetHolidayByDate = function (date) {
        var path = this.baseUrl + '/api/holidays/{date}';
        path = path.replace('{date}', date.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Sets the specified date as not having a holiday
     */
    ApiDataService.prototype.Holidays_DeleteHolidayByDate = function (date) {
        var path = this.baseUrl + '/api/holidays/{date}';
        path = path.replace('{date}', date.toString());
        return this.httpDelete(path);
    };
    /**
     * Generate a preview with based on a particular ImportMapping.
     * @param The request should provide the file to preview and the ImportMapping to use.
     */
    ApiDataService.prototype.Import_GetPreviewOfFileImport = function (request) {
        var path = this.baseUrl + '/api/import/preview';
        return this.httpPost(path, request);
    };
    /**
     * Retrieve the headers of the user's file.
     * @param The request should provide the file to extract headers from and the location of the headers, given as
     *        start row.
     */
    ApiDataService.prototype.Import_GetExcelHeadersOfFile = function (request) {
        var path = this.baseUrl + '/api/import/headers';
        return this.httpPost(path, request);
    };
    /**
     * Use an ImportMapping to import information from the provided user file.
     * @param The request should contain a user file, the ImportMapping and ImportOptions to use while Importing.
     */
    ApiDataService.prototype.Import_CustomImportExcelOptions = function (request) {
        var path = this.baseUrl + '/api/import';
        return this.httpPost(path, request);
    };
    /**
     * Process danløn pdfsand returns the contents as an excel file based on the referenced ImportMapping
     * @param Request must contain one of the File-related properties and one of the Mapping-related properties.
     */
    ApiDataService.prototype.Import_DanloenPdfToExcel = function (request) {
        var path = this.baseUrl + '/api/import/convert/danloenpdf';
        return this.httpPost(path, request);
    };
    /**
     * Converts an EPWin dat files into an excel file based on the referenced ImportMapping
     * @param Request must contain one of the File-related properties and one of the Mapping-related properties.
     */
    ApiDataService.prototype.Import_EpWinToExcel = function (request) {
        var path = this.baseUrl + '/api/import/convert/epwin';
        return this.httpPost(path, request);
    };
    /**
     * Retrieves a list of ImportMappings for the current Company
     */
    ApiDataService.prototype.ImportMapping_CompanyMappings = function () {
        var path = this.baseUrl + '/api/importmapping';
        return this.httpGet(path);
    };
    /**
     * Updates the passed ImportMapping. This means all ImportMappingColumns will be overwritten.
     * @param The ImportMapping to update.
     */
    ApiDataService.prototype.ImportMapping_UpdateMapping = function (importMapping) {
        var path = this.baseUrl + '/api/importmapping';
        return this.httpPut(path, importMapping);
    };
    /**
     * Updates the passed ImportMapping. This means all ImportMappingColumns will be overwritten.
     * @param The ImportMapping to update.
     */
    ApiDataService.prototype.ImportMapping_PatchMapping = function (importMapping) {
        var path = this.baseUrl + '/api/importmapping';
        return this.httpPatch(path, importMapping);
    };
    /**
     * Creates a new ImportMapping and save it.
     * @param The mapping to create.
     */
    ApiDataService.prototype.ImportMapping_SaveMapping = function (mapping) {
        var path = this.baseUrl + '/api/importmapping';
        return this.httpPost(path, mapping);
    };
    /**
     * Loads the ImportMapping with passed id.
     * @param The id of the ImportMapping to scan the file in reference to.
     */
    ApiDataService.prototype.ImportMapping_LoadMapping = function (mappingId) {
        var path = this.baseUrl + '/api/importmapping/{mappingId}';
        path = path.replace('{mappingId}', mappingId.toString());
        return this.httpGet(path);
    };
    /**
     * Deletes the ImportMapping with the specified ID.
     * @param The Id of the ImportMapping to delete.
     */
    ApiDataService.prototype.ImportMapping_DeleteMapping = function (mappingId) {
        var path = this.baseUrl + '/api/importmapping/{mappingId}';
        path = path.replace('{mappingId}', mappingId.toString());
        return this.httpDelete(path);
    };
    /**
     * Returns the ImportMappings for the current Company and given operation
     */
    ApiDataService.prototype.ImportMapping_CompanyImportTypeMappings = function (importTypeKey) {
        var path = this.baseUrl + '/api/importmapping/type/{importTypeKey}';
        path = path.replace('{importTypeKey}', importTypeKey.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a template file for importing data with the provided TemplateRequest.
     */
    ApiDataService.prototype.ImportMapping_GetImportTemplateByRequest = function (templateRequest) {
        var path = this.baseUrl + '/api/importmapping/template';
        return this.httpPost(path, templateRequest);
    };
    /**
     * Retrieves a template file for importing data with the provided ImportMapping.
     * @param The mapping to base the template on.
     */
    ApiDataService.prototype.ImportMapping_GetImportTemplate = function (mappingId) {
        var path = this.baseUrl + '/api/importmapping/{mappingId}/template';
        path = path.replace('{mappingId}', mappingId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a template file for importing data with the provided ImportMapping.
     * @param The mapping to base the template on.
     * @param Any parameters this kind of template requires.
     */
    ApiDataService.prototype.ImportMapping_GetImportTemplateWithParameters = function (mappingId, parameters) {
        var path = this.baseUrl + '/api/importmapping/{mappingId}/template';
        path = path.replace('{mappingId}', mappingId.toString());
        return this.httpPost(path, parameters);
    };
    /**
     * Exports a filled template file with the data of the company.
     */
    ApiDataService.prototype.ImportMapping_GetFilledImportTemplate = function (mappingId) {
        var path = this.baseUrl + '/api/importmapping/{mappingId}/template/filled';
        path = path.replace('{mappingId}', mappingId.toString());
        return this.httpGet(path);
    };
    /**
     * Exports a filled template file with the data of the company.
     * @param Any parameters this kind of filled template requires.
     */
    ApiDataService.prototype.ImportMapping_GetFilledImportTemplateWithParameters = function (mappingId, parameters) {
        var path = this.baseUrl + '/api/importmapping/{mappingId}/template/filled';
        path = path.replace('{mappingId}', mappingId.toString());
        return this.httpPost(path, parameters);
    };
    /**
     * Retrieves all Integrations for the current Company.
     */
    ApiDataService.prototype.Integrations_RetrieveIntegrations = function () {
        var path = this.baseUrl + '/api/integrations';
        return this.httpGet(path);
    };
    /**
     * Retrieves Integrations with the specified operation active for the current Company.
     */
    ApiDataService.prototype.Integrations_GetIntegrationsByOperation = function (operationId) {
        var path = this.baseUrl + '/api/integrations/operation/{operationId}';
        path = path.replace('{operationId}', operationId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves all Integrations for the current Company, including their child objects.
     */
    ApiDataService.prototype.Integrations_RetrieveIntegrationsAll = function () {
        var path = this.baseUrl + '/api/integrations/all';
        return this.httpGet(path);
    };
    /**
     * Gets one Integration in the current Company with all child objects.
     * @param The Id of the Integration to retrieve.
     */
    ApiDataService.prototype.Integrations_RetrieveIntegrationWithAllChildren = function (integrationId) {
        var path = this.baseUrl + '/api/integrations/{integrationId}';
        path = path.replace('{integrationId}', integrationId.toString());
        return this.httpGet(path);
    };
    /**
     * Sets values on the Integration's child objects corresponding to the passed ExternalSystemParameter keys.
     * @param Which integration to set the value in.
     * @param A collection of SimpleKeyValuePair with the following properties: Key should be set to the
     *        ExternalSystemParameter's key, Value will be parsed to the appropriate type.
     */
    ApiDataService.prototype.Integrations_SetIntegrationValuesByKey = function (integrationId, parameters) {
        var path = this.baseUrl + '/api/integrations/{integrationId}';
        path = path.replace('{integrationId}', integrationId.toString());
        return this.httpPost(path, parameters);
    };
    /**
     * Delete an Integration.
     * @param The Id of the integration to delete.
     */
    ApiDataService.prototype.Integrations_DeleteIntegration = function (integrationId) {
        var path = this.baseUrl + '/api/integrations/{integrationId}';
        path = path.replace('{integrationId}', integrationId.toString());
        return this.httpDelete(path);
    };
    /**
     * Create a new Integration based on the referenced ExternalSystem.
     * @param The Id of the ExternalSystem to create a new Integration for.
     */
    ApiDataService.prototype.Integrations_CreateIntegration = function (externalSystemId) {
        var path = this.baseUrl + '/api/integrations/externalsystem/{externalSystemId}';
        path = path.replace('{externalSystemId}', externalSystemId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Sets IsActive of a single IntegrationOperation.
     * @param Which integration to set the value in.
     * @param Id of the operation to change.
     * @param Boolean to set the operation to.
     */
    ApiDataService.prototype.Integrations_SetSpecificSingleIntegrationOperationValueByKey = function (integrationId, operationId, value) {
        var path = this.baseUrl + '/api/integrations/{integrationId}/operation/{operationId}/single';
        path = path.replace('{integrationId}', integrationId.toString());
        path = path.replace('{operationId}', operationId.toString());
        path += '?value=' + value;
        return this.httpPost(path, undefined);
    };
    /**
     * Sets IsActive of a single IntegrationOperation.
     * @param Which integration to set the value in.
     * @param Key should be set to the Key of the corresponding ExternalSystem object, Value will be parsed to the
     *        appropriate type.
     */
    ApiDataService.prototype.Integrations_SetSingleIntegrationOperationValueByKey = function (integrationId, keyValuePair) {
        var path = this.baseUrl + '/api/integrations/{integrationId}/operation/single';
        path = path.replace('{integrationId}', integrationId.toString());
        return this.httpPost(path, keyValuePair);
    };
    /**
     * Sets IsActive of IntegrationOperations.
     * @param Which integration to set the value in.
     * @param Keys should be set to the Key of the corresponding ExternalSystem object, Value will be parsed to the
     *        appropriate type.
     */
    ApiDataService.prototype.Integrations_SetIntegrationOperationValuesByKey = function (integrationId, keyValuePairList) {
        var path = this.baseUrl + '/api/integrations/{integrationId}/operation';
        path = path.replace('{integrationId}', integrationId.toString());
        return this.httpPost(path, keyValuePairList);
    };
    /**
     * Sets the Value of a single IntegrationConfiguration.
     * @param Which integration to set the value in.
     * @param Key should be set to the Key of the corresponding ExternalSystem object, Value will be parsed to the
     *        appropriate type.
     */
    ApiDataService.prototype.Integrations_SetIntegrationConfigurationValueByKey = function (integrationId, keyValuePair) {
        var path = this.baseUrl + '/api/integrations/{integrationId}/configuration/single';
        path = path.replace('{integrationId}', integrationId.toString());
        return this.httpPost(path, keyValuePair);
    };
    /**
     * Sets Value of IntegrationConfigurations.
     * @param Which integration to set the value in.
     * @param Keys should be set to the Key of the corresponding ExternalSystem object, Value will be parsed to the
     *        appropriate type.
     */
    ApiDataService.prototype.Integrations_SetIntegrationConfigurationValuesByKey = function (integrationId, keyValuePairList) {
        var path = this.baseUrl + '/api/integrations/{integrationId}/configuration';
        path = path.replace('{integrationId}', integrationId.toString());
        return this.httpPost(path, keyValuePairList);
    };
    /**
     * Sets the value of a single IntegrationCredential.
     * @param Which integration to set the value in.
     * @param Key should be set to the Key of the corresponding ExternalSystem object, Value will be parsed to the
     *        appropriate type.
     */
    ApiDataService.prototype.Integrations_SetIntegrationCredentialValueByKey = function (integrationId, keyValuePair) {
        var path = this.baseUrl + '/api/integrations/{integrationId}/credential/single';
        path = path.replace('{integrationId}', integrationId.toString());
        return this.httpPost(path, keyValuePair);
    };
    /**
     * Sets IsActive of IntegrationOperations.
     * @param Which integration to set the value in.
     * @param Keys should be set to the Key of the corresponding ExternalSystem object, Value will be parsed to the
     *        appropriate type.
     */
    ApiDataService.prototype.Integrations_SetIntegrationCredentialValuesByKey = function (integrationId, keyValuePairList) {
        var path = this.baseUrl + '/api/integrations/{integrationId}/credential';
        path = path.replace('{integrationId}', integrationId.toString());
        return this.httpPost(path, keyValuePairList);
    };
    /**
     * Set a single value on the Integration child object corresponding to the passed key.
     * @param Which integration to set the value in.
     * @param Key should be set to the Type.Key of the corresponding externalSystem object, Value will be parsed to the
     *        appropriate type.
     */
    ApiDataService.prototype.Integrations_SetIntegrationValueByKey = function (integrationId, keyValuePair) {
        var path = this.baseUrl + '/api/integrations/{integrationId}/single';
        path = path.replace('{integrationId}', integrationId.toString());
        return this.httpPost(path, keyValuePair);
    };
    /**
     * Runs all relevant operations on all implemented integrations
     */
    ApiDataService.prototype.Integrations_RunAllIntegrations = function () {
        var path = this.baseUrl + '/api/integrations/run';
        return this.httpPost(path, undefined);
    };
    /**
     * Runs all relevant operation for a specific integration
     */
    ApiDataService.prototype.Integrations_RunIntegration = function (integrationId) {
        var path = this.baseUrl + '/api/integrations/{integrationId}/run';
        path = path.replace('{integrationId}', integrationId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Runs specific operation for a specific integration
     */
    ApiDataService.prototype.Integrations_RunIntegrationsOperation = function (integrationId, operationId) {
        var path = this.baseUrl + '/api/integrations/{integrationId}/operation/{operationId}/run';
        path = path.replace('{integrationId}', integrationId.toString());
        path = path.replace('{operationId}', operationId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Gets a list of the specified integration's external accounts.
     */
    ApiDataService.prototype.Integrations_GetExternalAccounts = function (integrationId) {
        var path = this.baseUrl + '/api/integrations/{integrationId}/externalaccounts';
        path = path.replace('{integrationId}', integrationId.toString());
        return this.httpGet(path);
    };
    /**
     * Gets a list of the current company's external accounts.
     */
    ApiDataService.prototype.Integrations_GetAllExternalAccounts = function () {
        var path = this.baseUrl + '/api/integrations/externalaccounts';
        return this.httpGet(path);
    };
    /**
     * Returns the available dimensions for the specified external system.
     * @param The key of the external system for which to retrieve dimensions. (Call api/staticdata/ExternalSystem for
     *        valid values)
     */
    ApiDataService.prototype.Integrations_GetExternalSystemDimensions = function (externalSystemKey) {
        var path = this.baseUrl + '/api/integrations/dimensions/{externalSystemKey}';
        path = path.replace('{externalSystemKey}', externalSystemKey.toString());
        return this.httpGet(path);
    };
    ApiDataService.prototype.Integrations_GetJournalBatches = function (integrationId) {
        var path = this.baseUrl + '/api/integrations/{integrationId}/AvailableJournalBatches';
        path = path.replace('{integrationId}', integrationId.toString());
        return this.httpGet(path);
    };
    ApiDataService.prototype.Integrations_GetJournalTemplates = function (integrationId) {
        var path = this.baseUrl + '/api/integrations/{integrationId}/AvailableJournalTemplates';
        path = path.replace('{integrationId}', integrationId.toString());
        return this.httpGet(path);
    };
    ApiDataService.prototype.Integrations_GetCurrentWorkBookDimensions = function () {
        var path = this.baseUrl + '/api/integrations/currentworkbookdimension';
        return this.httpGet(path);
    };
    /**
     * Retrieves the current version of the API.
     */
    ApiDataService.prototype.Miscellaneous_GetVersion = function () {
        var path = this.baseUrl + '/api/version';
        return this.httpGet(path);
    };
    /**
     * Retrieves a boolean indicating whether the API is deployed in a production environment.
     */
    ApiDataService.prototype.Miscellaneous_IsProduction = function () {
        var path = this.baseUrl + '/api/isprod';
        return this.httpGet(path);
    };
    /**
     * Retrieves a string indicating the minimum allowed version number for the main Intect client. If the client is a
     * lower version number, it should update itself.
     */
    ApiDataService.prototype.Miscellaneous_GetMinimumClientVersion = function () {
        var path = this.baseUrl + '/api/clientminversion';
        return this.httpGet(path);
    };
    /**
     * Submits feedback to Intect. Note that the message will usually not invoke a reply.
     */
    ApiDataService.prototype.Miscellaneous_SubmitFeedback = function (feedback) {
        var path = this.baseUrl + '/api/feedback';
        return this.httpPost(path, feedback);
    };
    /**
     * Reports that an unexpected error occurred in a client application. Do not call this method without previous
     * approval from Intect. Please include as much information as possible in the request body.
     * @param An object containing detailed information about the error.
     */
    ApiDataService.prototype.Miscellaneous_ReportClientSideError = function (errorDetails) {
        var path = this.baseUrl + '/api/clientfail';
        return this.httpPost(path, errorDetails);
    };
    /**
     * Simulates an unexpected error.
     */
    ApiDataService.prototype.Miscellaneous_FailHard = function () {
        var path = this.baseUrl + '/api/fail/hard';
        return this.httpGet(path);
    };
    /**
     * Simulates an expected error, such as invalid user input or similar.
     */
    ApiDataService.prototype.Miscellaneous_FailSoft = function () {
        var path = this.baseUrl + '/api/fail/soft';
        return this.httpGet(path);
    };
    /**
     * Simulates an error due to insufficient access privileges or an expired authentication token.
     */
    ApiDataService.prototype.Miscellaneous_FailUnauhorized = function () {
        var path = this.baseUrl + '/api/fail/unauthorized';
        return this.httpGet(path);
    };
    /**
     * Simulates an unexpected response from the web API, of the kind that you should not normally receive.
     */
    ApiDataService.prototype.Miscellaneous_Random = function () {
        var path = this.baseUrl + '/api/fail/random';
        return this.httpGet(path);
    };
    /**
     * Simulates an long-running operation. The method will return after 120 seconds. This method is only intended for
     * an internal testing.
     */
    ApiDataService.prototype.Miscellaneous_Sleep = function () {
        var path = this.baseUrl + '/api/sleep';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all supported languages.
     */
    ApiDataService.prototype.Miscellaneous_GetLanguages = function () {
        var path = this.baseUrl + '/api/languages';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of possible DISCO codes for job functions when reporting statistics.
     */
    ApiDataService.prototype.Miscellaneous_GetStatisticsDiscoCodes = function () {
        var path = this.baseUrl + '/api/statistics/discocodes';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of relevant values for dimension no. X (1, 2 or 3). Allowed values may or may not be restricted
     * to this list, based on a company setting (preference).
     */
    ApiDataService.prototype.Miscellaneous_GetDimensionValues = function (dimensionNumber) {
        var path = this.baseUrl + '/api/dimensions/{dimensionNumber}/values';
        path = path.replace('{dimensionNumber}', dimensionNumber.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of salary cycles used by at least one active employee in the currently active company.
     */
    ApiDataService.prototype.Miscellaneous_GetUsedSalaryCycles = function () {
        var path = this.baseUrl + '/api/salarycycles/used';
        return this.httpGet(path);
    };
    /**
     * Retrieves the balance periods for a given balance.
     * @param The balance to fetch
     */
    ApiDataService.prototype.Miscellaneous_RetrieveBalancePeriodsByBalanceId = function (balanceId) {
        var path = this.baseUrl + '/api/balanceid/{balanceId}/balanceperiods';
        path = path.replace('{balanceId}', balanceId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Retrieves friendly names for given entity type in the current company.
     */
    ApiDataService.prototype.Miscellaneous_GetEntityNames = function (entityTypeId) {
        var path = this.baseUrl + '/api/entityType/{entityTypeId}/Name';
        path = path.replace('{entityTypeId}', entityTypeId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all available modules including information about package and billing principle. This method
     * is meant to be called anonymously. It currently only supports Danish pricing. Note that you should simply call
     * api/modules if you are authenticated and want to see prices for your company.
     */
    ApiDataService.prototype.Modules_GetModuleViewsTranslated = function (languageCode) {
        var path = this.baseUrl + '/api/modules/lang/{languageCode}';
        path = path.replace('{languageCode}', languageCode.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of modules with information about whether each is enabled for the current company.
     */
    ApiDataService.prototype.Modules_GetModulesForCurrentCompany = function () {
        var path = this.baseUrl + '/api/modules';
        return this.httpGet(path);
    };
    /**
     * Enables the list of modules (and optionally a package) for the active company. Disables all others. Note this has
     * billing consequences.
     * @param The package to activate, if any. See api/staticdata/ModulePackage for possible values. Specify 0 for no
     *        package. Modules that are included in the package do not need to be specified explicitly in the request
     *        body.
     * @param A list of ID's of the modules to enable. Modules not contained in either this list or the specified
     *        package will be disabled. Modules that have InfoOnly = true will be ignored.
     */
    ApiDataService.prototype.Modules_SetEnabledModules = function (packageLevel, modulesToActivate) {
        var path = this.baseUrl + '/api/modules/package/{packageLevel}';
        path = path.replace('{packageLevel}', packageLevel.toString());
        return this.httpPut(path, modulesToActivate);
    };
    /**
     * Enables the selected module for the currently active account. Note that this has billing consequences.
     */
    ApiDataService.prototype.Modules_EnableModule = function (moduleId) {
        var path = this.baseUrl + '/api/modules/{moduleId}';
        path = path.replace('{moduleId}', moduleId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Schedules the selected module deactivation in the currently active company. The notice period is 3 months to the
     * end of a month. Note that this has consequences for billing and available functionality.
     */
    ApiDataService.prototype.Modules_DisableModule = function (moduleId) {
        var path = this.baseUrl + '/api/modules/{moduleId}';
        path = path.replace('{moduleId}', moduleId.toString());
        return this.httpDelete(path);
    };
    /**
     * Gets a list of the current company's preferences.
     */
    ApiDataService.prototype.Preferences_GetCompanyPreferences = function () {
        var path = this.baseUrl + '/api/preferences/company';
        return this.httpGet(path);
    };
    /**
     * Sets one of the current company's preferences to the desired value. Note that this can have billing consequences
     * as any required modules will be automatically activated.
     */
    ApiDataService.prototype.Preferences_SetCompanyPreference = function (preference) {
        var path = this.baseUrl + '/api/preferences/company';
        return this.httpPut(path, preference);
    };
    /**
     * Gets the preferences of the current user.
     */
    ApiDataService.prototype.Preferences_GetUserPreferences = function () {
        var path = this.baseUrl + '/api/preferences/user';
        return this.httpGet(path);
    };
    /**
     * Updates a single preference of the current user.
     */
    ApiDataService.prototype.Preferences_SetUserPreference = function (preference) {
        var path = this.baseUrl + '/api/preferences/user';
        return this.httpPut(path, preference);
    };
    /**
     * Retrieves a list of all reports available in the system.
     */
    ApiDataService.prototype.Reports_GetAllReports = function () {
        var path = this.baseUrl + '/api/reports';
        return this.httpGet(path);
    };
    /**
     * Retrieves a report based on the submitted data.
     */
    ApiDataService.prototype.Reports_GetReport = function (request) {
        var path = this.baseUrl + '/api/reports';
        return this.httpPost(path, request);
    };
    /**
     * Retrieves a list of reports relevant to the specified area. See api/staticdata/ReportArea for possible values.
     * @param The Id of the ReportArea to get reports for.
     */
    ApiDataService.prototype.Reports_GetReportsByArea = function (areaId) {
        var path = this.baseUrl + '/api/reports/area/{areaId}';
        path = path.replace('{areaId}', areaId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a report based on the submitted data. This is a convenience method that simply sets OutputFormat of the
     * request body to the value specified in the route.
     * @param The desired output format for the report. Suppported values are "html", "csv", "xlsx" and "pdf", although
     *        not all formats are supported by all reports.
     * @param Spefication for information the report requires.
     */
    ApiDataService.prototype.Reports_GetReportWithOutput = function (outputFormat, request) {
        var path = this.baseUrl + '/api/reports/{outputFormat}';
        path = path.replace('{outputFormat}', outputFormat.toString());
        return this.httpPost(path, request);
    };
    /**
     * Retrieves a list of all payroll batches in the currently active company. Note that this method has the
     * side-effect of recalculating all open draft batches with pending changes, so response time can sometimes be
     * longer than usual.
     */
    ApiDataService.prototype.SalaryBatches_GetSalaryBatches = function () {
        var path = this.baseUrl + '/api/salarybatches';
        return this.httpGet(path);
    };
    /**
     * Updates an existing payroll batch. Only the Message, PayoutDate and PayoutAllFlex/PayoutNewFlex properties can be
     * modified. NOTE: This re-runs validation logic on the batch but does not recalculate it.
     * @param The payroll batch to update.
     */
    ApiDataService.prototype.SalaryBatches_UpdateSalaryBatch = function (batch) {
        var path = this.baseUrl + '/api/salarybatches';
        return this.httpPut(path, batch);
    };
    /**
     * Updates an existing payroll batch. Only the Message, PayoutDate and PayoutAllFlex/PayoutNewFlex properties can be
     * modified. NOTE: This re-runs validation logic on the batch but does not recalculate it.
     * @param The payroll batch to update.
     */
    ApiDataService.prototype.SalaryBatches_PatchSalaryBatch = function (batch) {
        var path = this.baseUrl + '/api/salarybatches';
        return this.httpPatch(path, batch);
    };
    /**
     * Creates and calculates a new payroll batch for the specified salary period, optionally as a preview-only
     * operation.
     * @param An object containing details about the request. Note that to specify an
     */
    ApiDataService.prototype.SalaryBatches_CreateSalaryBatch = function (request) {
        var path = this.baseUrl + '/api/salarybatches';
        return this.httpPost(path, request);
    };
    /**
     * Retrieves a list of all non-finalized payroll batches in the currently active company.
     */
    ApiDataService.prototype.SalaryBatches_GetOpenSalaryBatches = function () {
        var path = this.baseUrl + '/api/salarybatches/open';
        return this.httpGet(path);
    };
    /**
     * Retrieves details about a single payroll batch, including all its records and salary statements.
     */
    ApiDataService.prototype.SalaryBatches_GetSalaryBatch = function (batchId) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpGet(path);
    };
    /**
     * Deletes a payroll batch.
     * @param The ID of the batch to delete.
     */
    ApiDataService.prototype.SalaryBatches_DeleteSalaryBatch = function (batchId) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpDelete(path);
    };
    /**
     * Retrieves a list of E-Income response lines for the specified payroll batch. Note that this request is only
     * relevant for finalized payroll batches.
     */
    ApiDataService.prototype.SalaryBatches_GetEIncomeResponseLines = function (batchId) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/eincome/response';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of validation warnings and errors for the specified payroll batch. Note that this request is
     * only relevant for non-finalized payroll batches.
     */
    ApiDataService.prototype.SalaryBatches_GetValidationIssues = function (batchId) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/validation';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a sum of amounts by salary type for the specified payroll batch.
     */
    ApiDataService.prototype.SalaryBatches_GetSalaryBatchTotalsBySalaryType = function (batchId) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/totals/salarytypes';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a sum of amounts by employee for the specified salary type within the specified payroll batch.
     */
    ApiDataService.prototype.SalaryBatches_GetSalaryTypeTotalsByEmployee = function (batchId, salaryTypeId) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/totals/salarytype/{salaryTypeId}';
        path = path.replace('{batchId}', batchId.toString());
        path = path.replace('{salaryTypeId}', salaryTypeId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a sum of relevant units (time entries, vacation days etc.) for the specified payroll batch.
     */
    ApiDataService.prototype.SalaryBatches_GetSalaryBatchUnitTotals = function (batchId) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/totals/units';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a sum of relevant unit totals (time entries, vacation days etc.) by employee for the specified payroll
     * batch and category.
     * @param The ID of the salary batch to retrieve the data for.
     * @param A string designating the type of balance you want to see. Call api/salarybatches/{batchId}/totals/units
     *        for a list of possible values.
     */
    ApiDataService.prototype.SalaryBatches_GetUnitTotalsByEmployee = function (batchId, key) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/totals/units/key/{key}';
        path = path.replace('{batchId}', batchId.toString());
        path = path.replace('{key}', key.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of salary periods in the specified salary cycle.
     */
    ApiDataService.prototype.SalaryBatches_GetSalaryPeriods = function (cycleId) {
        var path = this.baseUrl + '/api/salarybatches/periods/cycle/{cycleId}';
        path = path.replace('{cycleId}', cycleId.toString());
        return this.httpGet(path);
    };
    /**
     * Suggests the next relevant salary period in the specified salary cycle. Defined as the first period after the
     * company's newest finalized payroll batch.
     */
    ApiDataService.prototype.SalaryBatches_SuggestSalaryPeriod = function (cycleId) {
        var path = this.baseUrl + '/api/salarybatches/periods/cycle/{cycleId}/suggest';
        path = path.replace('{cycleId}', cycleId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves information about whether or not the specified salary period already has one or more payroll batches.
     */
    ApiDataService.prototype.SalaryBatches_SalaryPeriodHasBatches = function (periodId) {
        var path = this.baseUrl + '/api/salarybatches/periods/{periodId}/hasbatch';
        path = path.replace('{periodId}', periodId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of potentially relevant salary periods
     */
    ApiDataService.prototype.SalaryBatches_GetRelevantPeriods = function () {
        var path = this.baseUrl + '/api/salarybatches/periods/relevant';
        return this.httpGet(path);
    };
    /**
     * Fully recalculates a payroll batch, deleting all existing batch records and re-fetching employee salary records.
     * @param The ID of the payroll batch to recalculate.
     */
    ApiDataService.prototype.SalaryBatches_RecalculateSalaryBatch = function (batchId) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/recalc';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpPut(path, undefined);
    };
    /**
     * Prepares a batch for finalization by fully recalculating it, checking that it does not contain validation errors,
     * and setting a temporary StatusId of 35, "Being finalized". This status will automatically revert after 30 minutes
     * if the batch has not been finalized in the meantime.
     * @param The ID of the padyroll batch to recalculate.
     * @param An object containing the user's password, which must be provided as an extra security measure. Note that
     *        "DoRecalc" is deprecated and will be ignored, no recalculation is performed before finalizing.
     */
    ApiDataService.prototype.SalaryBatches_PrepareSalaryBatchForFinalization = function (batchId, request) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/preparefinalize';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpPut(path, request);
    };
    /**
     * Recalculates a single employment within an existing payroll batch. This is possible even for batches that are
     * awaiting approval (NOT finalized ones). Use this when you know that only one employee's data has been changed and
     * do not wish to recalculate the entire batch.
     * @param The ID of the payroll batch to recalculate.
     * @param The UserEmploymentId of the employee to recalculate.
     */
    ApiDataService.prototype.SalaryBatches_RecalculateSalaryBatchSingleEmployment = function (batchId, userEmploymentId) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/recalc/employment/{userEmploymentId}';
        path = path.replace('{batchId}', batchId.toString());
        path = path.replace('{userEmploymentId}', userEmploymentId.toString());
        return this.httpPut(path, undefined);
    };
    /**
     * Recalculates all open draft payroll batches in the currently active company. NOTE: This method only recalculates
     * employees that have been updated since the last recalculation.
     */
    ApiDataService.prototype.SalaryBatches_RecalculateDraftSalaryBatches = function () {
        var path = this.baseUrl + '/api/salarybatches/recalcdrafts';
        return this.httpPut(path, undefined);
    };
    /**
     * Approves a payroll batch, locking it for editing. This prevents re-calculation but does not finalize the batch.
     * Employees are notified and given access to preliminary payslips.
     * @param The ID of the batch to approve.
     */
    ApiDataService.prototype.SalaryBatches_ApproveSalaryBatchWithPreliminaryPayslips = function (batchId) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/approve/withpreliminarypayslips';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Approves a payroll batch, locking it for editing. This prevents re-calculation but does not finalize the batch.
     * (Another way to think of it is submitting the batch for final approval). Employees are NOT notified nor given
     * access to preliminary paylips - to enable this, call the /withpreliminarypayslips route instead.
     * @param The ID of the batch to approve.
     */
    ApiDataService.prototype.SalaryBatches_ApproveSalaryBatch = function (batchId) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/approve';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Reverts all approval/locking of the payroll batch, resetting it to draft status and allowing it to be
     * recalculated.
     * @param The ID of the batch to approve.
     */
    ApiDataService.prototype.SalaryBatches_ResetBatchToDraft = function (batchId) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/reset';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Finalizes a payroll batch. Note that you must first call the /preparefinalize route and submit the GUID you
     * receive back from that request.
     * @param The ID of the batch to approve.
     * @param An object containing a GUID obtained by calling the /preparefinalize endpoint.
     */
    ApiDataService.prototype.SalaryBatches_FinalizeSalaryBatch = function (batchId, request) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/finalize';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpPost(path, request);
    };
    /**
     * Reverts a finalized payroll batch, setting its status to Cancelled.
     * @param The ID of the batch to revert.
     * @param An object containing the user's password, which must be provided as an extra security measure.
     */
    ApiDataService.prototype.SalaryBatches_RevertSalaryBatch = function (batchId, request) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/revert';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpPost(path, request);
    };
    /**
     * Rejects approval of a payroll batch, optionally specifying a reason. This moves the batch back to draft status.
     * @param The ID of the batch to reject.
     * @param The request to reject including reason.
     */
    ApiDataService.prototype.SalaryBatches_RejectSalaryBatch = function (batchId, request) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/reject';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpPost(path, request);
    };
    /**
     * Resends a payroll batch to E-Income. Dispatch and response are usually processed within 5-10 minutes. This will
     * also clear the error status of the batch.
     */
    ApiDataService.prototype.SalaryBatches_ResendEIncome = function (batchId) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/eincome/resend';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Sends or resends a payroll batch to the general ledger of the company's ERP system. Requires an active
     * integration with a supported system.
     */
    ApiDataService.prototype.SalaryBatches_SendOrResendToDefaultIntegration = function (batchId) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/integration/send';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Re-sends a payroll batch to the configured external system.
     */
    ApiDataService.prototype.SalaryBatches_ResendToIntegration = function (batchId, integrationId) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/integration/{integrationId}/resend';
        path = path.replace('{batchId}', batchId.toString());
        path = path.replace('{integrationId}', integrationId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Clears E-Income error and warning status for the specified payroll batch. Use this when errors have been resolved
     * manually. The actual error data will not be deleted.
     */
    ApiDataService.prototype.SalaryBatches_ClearEIncomeErrors = function (batchId) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/eincome/errors';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpDelete(path);
    };
    /**
     * Retrieves a list of accounting records for the specified payroll batch, to be imported into the general ledger of
     * an external ERP system. This method returns the totals with no grouping.
     */
    ApiDataService.prototype.SalaryBatches_GetExternalAccountingRecords = function (batchId) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/accounting';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of accounting records for the specified payroll batch, to be imported into the general ledger of
     * an external ERP system. This method returns the totals grouped by department.
     */
    ApiDataService.prototype.SalaryBatches_GetExternalAccountingRecordsByDepartment = function (batchId) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/accounting/department';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of accounting records for the specified payroll batch, to be imported into the general ledger of
     * an external ERP system. This method returns the totals grouped by employee - notice that this may expose
     * sensitive data.
     */
    ApiDataService.prototype.SalaryBatches_GetExternalAccountingRecordsByEmployee = function (batchId) {
        var path = this.baseUrl + '/api/salarybatches/{batchId}/accounting/employee';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of salary records for the specified employment.
     * @param The ID of the employment for which to retrieve salary records. Note: Do not pass a UserId or
     *        CompanyUserId.
     */
    ApiDataService.prototype.SalaryRecords_GetSalaryRecordsByEmployment = function (userEmploymentId) {
        var path = this.baseUrl + '/api/salaryrecords/employment/{userEmploymentId}';
        path = path.replace('{userEmploymentId}', userEmploymentId.toString());
        return this.httpGet(path);
    };
    /**
     * Creates and/or updates an array of SalaryRecords (payroll data). SalaryRecords passed with an Id of 0 (or
     * omitted) will be added as new records, while records with Id &gt; 0 will be updated.
     * @param A list of salary records to create and/or update. The list can span multiple employees but only within the
     *        currently active company.
     */
    ApiDataService.prototype.SalaryRecords_UpsertSalaryRecords = function (salaryRecords) {
        var path = this.baseUrl + '/api/salaryrecords/batch';
        return this.httpPost(path, salaryRecords);
    };
    /**
     * Deletes (deactivates) an array of SalaryRecords (payroll data) based on a list of Id's.
     * @param The Id's of the records to delete/deactivate.
     */
    ApiDataService.prototype.SalaryRecords_DeleteSalaryRecords = function (recordIds) {
        var path = this.baseUrl + '/api/salaryrecords/batch';
        return this.httpDelete(path);
    };
    /**
     * Updates an existing salary record.
     * @param The record to update.
     */
    ApiDataService.prototype.SalaryRecords_UpdateSalaryRecord = function (record) {
        var path = this.baseUrl + '/api/salaryrecords';
        return this.httpPut(path, record);
    };
    /**
     * Updates an existing salary record.
     * @param The record to update.
     */
    ApiDataService.prototype.SalaryRecords_PatchSalaryRecord = function (record) {
        var path = this.baseUrl + '/api/salaryrecords';
        return this.httpPatch(path, record);
    };
    /**
     * Creates a new salary record.
     * @param The record to create.
     */
    ApiDataService.prototype.SalaryRecords_CreateSalaryRecord = function (record) {
        var path = this.baseUrl + '/api/salaryrecords';
        return this.httpPost(path, record);
    };
    /**
     * Updates an existing salary record, specifying only a few essential properties.
     * @param The record to update.
     */
    ApiDataService.prototype.SalaryRecords_UpdateSalaryRecordSimple = function (request) {
        var path = this.baseUrl + '/api/salaryrecords/simple';
        return this.httpPut(path, request);
    };
    /**
     * Creates a new salary record, specifying only a few essential properties.
     * @param The essential details of the record to create.
     */
    ApiDataService.prototype.SalaryRecords_CreateSalaryRecordSimple = function (request) {
        var path = this.baseUrl + '/api/salaryrecords/simple';
        return this.httpPost(path, request);
    };
    /**
     * Deletes a salary record if it is not used in any payroll batches. Deactivates it otherwise.
     * @param The ID of the record to delete or deactivate.
     */
    ApiDataService.prototype.SalaryRecords_DeleteSalaryRecord = function (recordId) {
        var path = this.baseUrl + '/api/salaryrecords/{recordId}';
        path = path.replace('{recordId}', recordId.toString());
        return this.httpDelete(path);
    };
    /**
     * Retrieves a list of employees and selected payroll data, governed by the values of SalaryType.OverviewIndex and
     * "flipped" to provide a total of 7x3 named fields instead of a collection of records. See Company -&gt; Payroll
     * Data in the interface for the intended usage. Use the routes marked "simple" to update or add SalaryRecords based
     * on data served from this view.
     */
    ApiDataService.prototype.SalaryRecords_GetSalaryRecordsOverview = function () {
        var path = this.baseUrl + '/api/salaryrecords/overview';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of employees and selected payroll data, based on the values of SalaryType.OverviewIndex and the
     * filters supplied in the request body. This is a filtered version of api/salaryrecords/overview, see additional
     * comments on that method.
     */
    ApiDataService.prototype.SalaryRecords_GetSalaryRecordsOverviewFiltered = function (request) {
        var path = this.baseUrl + '/api/salaryrecords/overview/filtered';
        return this.httpPost(path, request);
    };
    /**
     * Retrieves a list of all employees, each with a collection of salary records.
     */
    ApiDataService.prototype.SalaryRecords_GetEmploymentsWithSalaryRecords = function () {
        var path = this.baseUrl + '/api/salaryrecords/byemployee';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all employees, each with a collection of salary records, filtered by the specified
     * SalaryCycleId.
     */
    ApiDataService.prototype.SalaryRecords_GetEmploymentsWithSalaryRecordsBySalaryCycle = function (salaryCycleId) {
        var path = this.baseUrl + '/api/salaryrecords/byemployee/cycle/{salaryCycleId}';
        path = path.replace('{salaryCycleId}', salaryCycleId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all salary statements for the current user, across companies.
     */
    ApiDataService.prototype.SalaryStatements_GetSalaryStatementsCurrentUser = function () {
        var path = this.baseUrl + '/api/salarystatements';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all salary statements for the currently logged-in company user.
     */
    ApiDataService.prototype.SalaryStatements_GetSalaryStatementsCurrentCompanyUser = function () {
        var path = this.baseUrl + '/api/salarystatements/companyUser/current';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all salary statements for the specified company user.
     * @param The ID of the CompanyUser for whom to retrieve payslips.
     */
    ApiDataService.prototype.SalaryStatements_GetSalaryStatementsByCompanyUser = function (companyUserId) {
        var path = this.baseUrl + '/api/salarystatements/companyUser/{companyUserId}';
        path = path.replace('{companyUserId}', companyUserId.toString());
        return this.httpGet(path);
    };
    /**
     * /// Retrieves a list of all salary statements for the specified employment.
     */
    ApiDataService.prototype.SalaryStatements_GetSalaryStatementsByEmployment = function (userEmploymentId) {
        var path = this.baseUrl + '/api/salarystatements/employment/{userEmploymentId}';
        path = path.replace('{userEmploymentId}', userEmploymentId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all salary statements for the specified payroll batch.
     * @param The ID of the SalaryBatch for which to retrieve payslips.
     */
    ApiDataService.prototype.SalaryStatements_GetSalaryStatementsByBatch = function (batchId) {
        var path = this.baseUrl + '/api/salarystatements/salarybatch/{batchId}';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a simplified list of all salary statements for the specified payroll batch.
     * @param The ID of the SalaryBatch for which to retrieve payslips.
     */
    ApiDataService.prototype.SalaryStatements_GetSalaryStatementsByBatchSimplified = function (batchId) {
        var path = this.baseUrl + '/api/salarystatements/salarybatch/{batchId}/simple';
        path = path.replace('{batchId}', batchId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a merged PDF file of all payslips in the specified payroll batch.
     * @param The ID of the payroll batch for which you want the payslips.
     */
    ApiDataService.prototype.SalaryStatements_GetPdfMergedBySalaryBatch = function (salaryBatchId) {
        var path = this.baseUrl + '/api/salarystatements/salarybatch/{salaryBatchId}/pdf/merged';
        path = path.replace('{salaryBatchId}', salaryBatchId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a merged PDF file of all payslips in the specified payroll batch.
     * @param The ID of the payroll batch for which you want the payslips.
     */
    ApiDataService.prototype.SalaryStatements_GetPdfMergedBySalaryBatchEncoded = function (salaryBatchId) {
        var path = this.baseUrl + '/api/salarystatements/salarybatch/{salaryBatchId}/pdf/merged/encoded';
        path = path.replace('{salaryBatchId}', salaryBatchId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a salary statement (payslip) including attached SalaryBatchRecords.
     * @param The ID of the salary statement to return.
     */
    ApiDataService.prototype.SalaryStatements_GetSingle = function (salaryStatementId) {
        var path = this.baseUrl + '/api/salarystatements/{salaryStatementId}';
        path = path.replace('{salaryStatementId}', salaryStatementId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a simplified list of the SalaryBatchRecords contained in a specified SalaryStatement.
     * @param The ID of the salary statement to return.
     */
    ApiDataService.prototype.SalaryStatements_GetSimplifiedBatchRecords = function (salaryStatementId) {
        var path = this.baseUrl + '/api/salarystatements/{salaryStatementId}/batchrecords/simple';
        path = path.replace('{salaryStatementId}', salaryStatementId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a salary statement (payslip) as a PDF file.
     * @param The ID of the salary statement to return as a PDF payslip.
     */
    ApiDataService.prototype.SalaryStatements_GetPdf = function (salaryStatementId) {
        var path = this.baseUrl + '/api/salarystatements/{salaryStatementId}/pdf';
        path = path.replace('{salaryStatementId}', salaryStatementId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a salary statement (payslip) as a PDF file, encoded as a Base64 string.
     * @param The ID of the salary statement to return as a PDF payslip.
     */
    ApiDataService.prototype.SalaryStatements_GetPdfEncoded = function (salaryStatementId) {
        var path = this.baseUrl + '/api/salarystatements/{salaryStatementId}/pdf/encoded';
        path = path.replace('{salaryStatementId}', salaryStatementId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a salary statement as a PDF file based on data submitted in the request. Use this for preview
     * functionality.
     * @param A salary statement to generate into a PDF file. The object must contain all relevant SalaryBatchRecords.
     */
    ApiDataService.prototype.SalaryStatements_GetPdfFromClientData = function (statement) {
        var path = this.baseUrl + '/api/salarystatements/pdf/fromclient';
        return this.httpPost(path, statement);
    };
    /**
     * Retrieves a salary statement as a PDF file based on data submitted in the request. Use this for preview
     * functionality.
     * @param A salary statement to generate into a PDF file. The object must contain all relevant SalaryBatchRecords.
     */
    ApiDataService.prototype.SalaryStatements_GetPdfFromClientDataEncoded = function (statement) {
        var path = this.baseUrl + '/api/salarystatements/pdf/fromclient/encoded';
        return this.httpPost(path, statement);
    };
    /**
     * Retrieves a salary statement as HTML.
     *
     * Response can be returned as JSON-wrapped HTML or raw text/HTML depending on the accept headers in the request
     */
    ApiDataService.prototype.SalaryStatements_GetHtml = function (salaryStatementId, outputType) {
        var path = this.baseUrl + '/api/salarystatements/{salaryStatementId}/{outputType}';
        path = path.replace('{salaryStatementId}', salaryStatementId.toString());
        path = path.replace('{outputType}', outputType.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a salary statement as HTML based on data submitted in the request. Use this for preview functionality.
     *
     * Response can be returned as JSON-wrapped HTML or raw text/HTML depending on the accept headers in the request
     * @param What html format to output, matching a page size or responsive html.
     * @param A salary statement to generate into an HTML file. The object must contain all relevant SalaryBatchRecords.
     */
    ApiDataService.prototype.SalaryStatements_GetHtmlPreview = function (outputType, statement) {
        var path = this.baseUrl + '/api/salarystatements/{outputType}/fromclient';
        path = path.replace('{outputType}', outputType.toString());
        return this.httpPost(path, statement);
    };
    /**
     * Retrieves a salary statement preview based on a single UserEmployment. The system suggests the most relevant
     * period. Notice that another method exists where you can specify the period to use.
     */
    ApiDataService.prototype.SalaryStatements_GetSingleEmploymentPreview = function (userEmploymentId) {
        var path = this.baseUrl + '/api/salarystatements/preview/employment/{userEmploymentId}';
        path = path.replace('{userEmploymentId}', userEmploymentId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a salary statement preview for the specified period, based on a single UserEmployment.
     */
    ApiDataService.prototype.SalaryStatements_GetSingleEmploymentPreviewByPeriod = function (userEmploymentId, periodId) {
        var path = this.baseUrl + '/api/salarystatements/preview/employment/{userEmploymentId}/period/{periodId}';
        path = path.replace('{userEmploymentId}', userEmploymentId.toString());
        path = path.replace('{periodId}', periodId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a token that can be used to download a payslip through an anonymous GET request (to facilitate standard
     * browser download functionality). Call
     * api/salarystatements/{salaryStatementId}/token/{token}/lang/{languageCode}/{unusedFileName}.{payslipFormat} to
     * retrieve the file.
     * @param The ID of the payslip (SalaryStatement) to retrieve.
     */
    ApiDataService.prototype.SalaryStatements_GetPayslipDownloadToken = function (salaryStatementId) {
        var path = this.baseUrl + '/api/salarystatements/{salaryStatementId}/downloadtoken';
        path = path.replace('{salaryStatementId}', salaryStatementId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a payslip as PDF through an anonymous GET request to facilitate standard browser download
     * functionality. Must be preceded by a call to api/salarystatement/{salaryStatementId}/downloadtoken.
     * @param The ID of the payslip (SalaryStatement) to retrieve.
     * @param A unique reference generated by calling api/salarystatements/
     * @param The language in which to retrieve the payslip. Accepted values are "en" for English or "da" or "dk" for
     *        Danish. Currently all other values will result in Danish, but this may change in the future.
     * @param The format in which the payslip will be. Accepted values are the normal formats (html, pdf, a4p). Add
     *        options print ans string to further conrtol output.
     */
    ApiDataService.prototype.SalaryStatements_DownloadPayslipByToken = function (salaryStatementId, token, languageCode, payslipFormat, unusedFileName) {
        var path = this.baseUrl +
            '/api/salarystatements/{salaryStatementId}/token/{token}/lang/{languageCode}/format/{payslipFormat}/{unusedFileName}';
        path = path.replace('{salaryStatementId}', salaryStatementId.toString());
        path = path.replace('{token}', token.toString());
        path = path.replace('{languageCode}', languageCode.toString());
        path = path.replace('{payslipFormat}', payslipFormat.toString());
        path = path.replace('{unusedFileName}', unusedFileName.toString());
        return this.httpGet(path);
    };
    /**
     * Reverts a finalized payslip, creating a new batch with status Cancelled and moving the payslip to that batch.
     * @param The ID of the salary statement (payslip) to revert.
     * @param An object containing the user's password, which must be provided as an extra security measure.
     */
    ApiDataService.prototype.SalaryStatements_RevertSalaryStatement = function (statementId, request) {
        var path = this.baseUrl + '/api/salarystatements/{statementId}/revert';
        path = path.replace('{statementId}', statementId.toString());
        return this.httpPost(path, request);
    };
    /**
     * Gets a list of all salary types belonging to the currently active company. Note that this method exposes the
     * company's raw salary types, so if inheritance is disabled then most properties will be null. Translations and
     * base salary type are included in the response.
     */
    ApiDataService.prototype.SalaryTypes_GetRawSalaryTypes = function () {
        var path = this.baseUrl + '/api/salarytypes/raw';
        return this.httpGet(path);
    };
    /**
     * Gets a list of all salary types belonging to the currently active company. Note that this method exposes the
     * company's raw salary types, so if inheritance is disabled then most properties will be null. Translations and
     * base salary type are included in the response. However, the Name, Description and DefaultPayslipText properties
     * are translated to the current session's language.
     */
    ApiDataService.prototype.SalaryTypes_GetRawSalaryTypesTranslated = function () {
        var path = this.baseUrl + '/api/salarytypes/raw/translated';
        return this.httpGet(path);
    };
    /**
     * Gets a list of all effective salary types relevant for the currently active company, excluding hidden and
     * inactive ones. The result is the final values after accounting for inheritance (if any).
     */
    ApiDataService.prototype.SalaryTypes_GetSalaryTypeViews = function () {
        var path = this.baseUrl + '/api/salarytypes';
        return this.httpGet(path);
    };
    /**
     * Updates a salary type. NOTE: Ensure that you use this in conjunction with the /raw method to avoid
     * unintentionally inheriting a base salary type.
     * @param The salary type to update.
     */
    ApiDataService.prototype.SalaryTypes_UpdateSalaryType = function (salaryType) {
        var path = this.baseUrl + '/api/salarytypes';
        return this.httpPut(path, salaryType);
    };
    /**
     * Updates a salary type. NOTE: Ensure that you use this in conjunction with the /raw method to avoid
     * unintentionally inheriting a base salary type.
     * @param The salary type to update.
     */
    ApiDataService.prototype.SalaryTypes_PatchSalaryType = function (salaryType) {
        var path = this.baseUrl + '/api/salarytypes';
        return this.httpPatch(path, salaryType);
    };
    /**
     * Gets a list of all salary types relevant for the currently active company, including hidden ones. The result is
     * the final values after accounting for inheritance (if any).
     */
    ApiDataService.prototype.SalaryTypes_GetAllSalaryTypeViews = function () {
        var path = this.baseUrl + '/api/salarytypes/all';
        return this.httpGet(path);
    };
    /**
     * Gets a list of all default salary types.
     */
    ApiDataService.prototype.SalaryTypes_GetDefaultSalaryTypes = function () {
        var path = this.baseUrl + '/api/salarytypes/default';
        return this.httpGet(path);
    };
    /**
     * Gets a list of all salary type categories.
     */
    ApiDataService.prototype.SalaryTypes_GetAllSalaryTypeCategories = function () {
        var path = this.baseUrl + '/api/salarytypes/categories';
        return this.httpGet(path);
    };
    /**
     * Creates a new custom salary type.
     */
    ApiDataService.prototype.SalaryTypes_CreateCustomSalaryType = function (salaryType) {
        var path = this.baseUrl + '/api/salarytypes/custom';
        return this.httpPost(path, salaryType);
    };
    /**
     * Deletes a salary type.
     */
    ApiDataService.prototype.SalaryTypes_DeleteSalaryType = function (salaryTypeId) {
        var path = this.baseUrl + '/api/salarytypes/{salaryTypeId}';
        path = path.replace('{salaryTypeId}', salaryTypeId.toString());
        return this.httpDelete(path);
    };
    /**
     * Inherits a default salary type in the currently active company, creating a new company-specific salary type.
     */
    ApiDataService.prototype.SalaryTypes_InheritBaseSalaryType = function (request) {
        var path = this.baseUrl + '/api/salarytypes/inherit';
        return this.httpPost(path, request);
    };
    /**
     * Retrieves the set of translations for a salary type.
     */
    ApiDataService.prototype.SalaryTypes_GetTranslations = function (salaryTypeId) {
        var path = this.baseUrl + '/api/salarytypes/{salaryTypeId}/translations';
        path = path.replace('{salaryTypeId}', salaryTypeId.toString());
        return this.httpGet(path);
    };
    /**
     * Creates / updates a set of salary type translations. Entities with Id = 0 will be created, others will be
     * updated.
     */
    ApiDataService.prototype.SalaryTypes_SetTranslations = function (translations) {
        var path = this.baseUrl + '/api/salarytypes/translations/list';
        return this.httpPost(path, translations);
    };
    /**
     * Updates an existing salary type, specifying only the external reference.
     * @param The salary type to update.
     */
    ApiDataService.prototype.SalaryTypes_UpdateSalaryTypeSimple = function (request) {
        var path = this.baseUrl + '/api/salarytypes/simple';
        return this.httpPut(path, request);
    };
    /**
     * Sets the field OverviewIndex and OverviewDisplayMode for a specific salary type. If another salary type has the
     * same value, it is automatically swapped.
     * @param The ID of the salary type to update.
     * @param The 1-based index indicating the order to display the salary type in the overview tab (Company -&gt;
     *        Payroll Data). Must be a number between 1 and 7.
     * @param A value indicating how to display the salary type in the overview. Call
     *        api/staticdata/SalaryRecordsOverviewDisplayMode for possible values.
     */
    ApiDataService.prototype.SalaryTypes_ConfigureOverviewBehavior = function (salaryTypeId, overviewIndex, overviewDisplayMode) {
        var path = this.baseUrl + '/api/salarytypes/{salaryTypeId}/overviewindex/{overviewIndex}/displaymode/{overviewDisplayMode}';
        path = path.replace('{salaryTypeId}', salaryTypeId.toString());
        path = path.replace('{overviewIndex}', overviewIndex.toString());
        path = path.replace('{overviewDisplayMode}', overviewDisplayMode.toString());
        return this.httpPut(path, undefined);
    };
    /**
     * Retrieves a list of all salary type groups in the currently active company.
     */
    ApiDataService.prototype.SalaryTypes_GetCompanySalaryTypeGroups = function () {
        var path = this.baseUrl + '/api/salarytypes/groups';
        return this.httpGet(path);
    };
    /**
     * Updates an existing salary type group. NOTE: This feature is not currently used.
     * @param The group to update.
     */
    ApiDataService.prototype.SalaryTypes_UpdateSalaryTypeGroup = function (group) {
        var path = this.baseUrl + '/api/salarytypes/groups';
        return this.httpPut(path, group);
    };
    /**
     * Updates an existing salary type group. NOTE: This feature is not currently used.
     * @param The group to update.
     */
    ApiDataService.prototype.SalaryTypes_PatchSalaryTypeGroup = function (group) {
        var path = this.baseUrl + '/api/salarytypes/groups';
        return this.httpPatch(path, group);
    };
    /**
     * Creates a new salary type group. NOTE: This feature is not currently used.
     * @param The group to create.
     */
    ApiDataService.prototype.SalaryTypes_CreateSalaryTypeGroup = function (group) {
        var path = this.baseUrl + '/api/salarytypes/groups';
        return this.httpPost(path, group);
    };
    /**
     * Deletes a salary type group. NOTE: This feature is not currently used.
     * @param The ID of the group to delete.
     */
    ApiDataService.prototype.SalaryTypes_DeleteSalaryTypeGroup = function (groupId) {
        var path = this.baseUrl + '/api/salarytypes/groups/{groupId}';
        path = path.replace('{groupId}', groupId.toString());
        return this.httpDelete(path);
    };
    /**
     * Automaps salary types to the Integration that currently has the AutoMap operation enabled.
     */
    ApiDataService.prototype.SalaryTypes_AutoMapSalaryTypes = function () {
        var path = this.baseUrl + '/api/salarytypes/automap';
        return this.httpPost(path, undefined);
    };
    /**
     * Updates an existing DepartmentSalaryTypeMapping
     * @param DepartmentSalaryTypeMapping to update
     */
    ApiDataService.prototype.SalaryTypes_UpdateDepartmentSalaryTypeMapping = function (departmentSalaryTypeMapping) {
        var path = this.baseUrl + '/api/salarytypes/department';
        return this.httpPut(path, departmentSalaryTypeMapping);
    };
    /**
     * Create a new DepartmentSalaryTypeMapping
     * @param DepartmentSalaryTypeMapping to create
     */
    ApiDataService.prototype.SalaryTypes_CreateDepartmentSalaryTypeMapping = function (departmentSalaryTypeMapping) {
        var path = this.baseUrl + '/api/salarytypes/department';
        return this.httpPost(path, departmentSalaryTypeMapping);
    };
    /**
     * Delete an existing DepartmentSalaryTypeMapping
     * @param Id of the DepartmentSalaryTypeMapping to delete
     */
    ApiDataService.prototype.SalaryTypes_DeleteDepartmentSalaryTypeMapping = function (departmentSalaryTypeMappingId) {
        var path = this.baseUrl + '/api/salarytypes/department/{departmentSalaryTypeMappingId}';
        path = path.replace('{departmentSalaryTypeMappingId}', departmentSalaryTypeMappingId.toString());
        return this.httpDelete(path);
    };
    /**
     * Fetches the existing DepartmentSalaryTypeMappings for the specified department
     * @param The id of the department to fetch the mappings of
     */
    ApiDataService.prototype.SalaryTypes_GetDepartmentSalaryTypeMappings = function (departmentId) {
        var path = this.baseUrl + '/api/salarytypes/department/{departmentId}';
        path = path.replace('{departmentId}', departmentId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of tasks and their status for the current company. Note that StatusId is 0 = Todo, 1 = Completed
     * and -1 = Skipped.
     */
    ApiDataService.prototype.Startuptasks_GetStartupTasksWithStatus = function () {
        var path = this.baseUrl + '/api/startuptasks/status';
        return this.httpGet(path);
    };
    /**
     * Skips the specified task.
     */
    ApiDataService.prototype.Startuptasks_SkipTask = function (taskId) {
        var path = this.baseUrl + '/api/startuptasks/{taskId}/skip';
        path = path.replace('{taskId}', taskId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Completes the specified task.
     */
    ApiDataService.prototype.Startuptasks_CompleteTask = function (taskId) {
        var path = this.baseUrl + '/api/startuptasks/{taskId}/done';
        path = path.replace('{taskId}', taskId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Undoes completion or skipping of the specified task.
     */
    ApiDataService.prototype.Startuptasks_ResetTask = function (taskId) {
        var path = this.baseUrl + '/api/startuptasks/{taskId}/reset';
        path = path.replace('{taskId}', taskId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Retrieves a list of all static data in the system. Note: Consider getting the data by type instead.
     */
    ApiDataService.prototype.StaticData_GetStaticData = function () {
        var path = this.baseUrl + '/api/staticdata';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all possible types of static data.
     */
    ApiDataService.prototype.StaticData_GetStaticDataTypes = function () {
        var path = this.baseUrl + '/api/staticdata/types';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all values of the specified static data type. This method allows you to specify the desired
     * output language.
     */
    ApiDataService.prototype.StaticData_GetStaticDataByTypeTranslated = function (type, languageCode) {
        var path = this.baseUrl + '/api/staticdata/{type}/lang/{languageCode}';
        path = path.replace('{type}', type.toString());
        path = path.replace('{languageCode}', languageCode.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known AccountInvoiceTypes.
     */
    ApiDataService.prototype.StaticData_GetAccountInvoiceTypes = function () {
        var path = this.baseUrl + '/api/staticdata/accountinvoicetype';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known AccountRoles.
     */
    ApiDataService.prototype.StaticData_GetAccountRoles = function () {
        var path = this.baseUrl + '/api/staticdata/accountrole';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known AccountStatuses.
     */
    ApiDataService.prototype.StaticData_GetAccountStatuses = function () {
        var path = this.baseUrl + '/api/staticdata/accountstatus';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known AccountTypes.
     */
    ApiDataService.prototype.StaticData_GetAccountTypes = function () {
        var path = this.baseUrl + '/api/staticdata/accounttype';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known AdditionalVacationTerminationMethods.
     */
    ApiDataService.prototype.StaticData_GetAdditionalVacationTerminationMethods = function () {
        var path = this.baseUrl + '/api/staticdata/additionalvacationterminationmethod';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known AtpAgreements.
     */
    ApiDataService.prototype.StaticData_GetAtpAgreements = function () {
        var path = this.baseUrl + '/api/staticdata/atpagreement';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known AtpRates.
     */
    ApiDataService.prototype.StaticData_GetAtpRates = function () {
        var path = this.baseUrl + '/api/staticdata/atprate';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known AuditLogOperations.
     */
    ApiDataService.prototype.StaticData_GetAuditLogOperations = function () {
        var path = this.baseUrl + '/api/staticdata/auditlogoperation';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known Banks.
     */
    ApiDataService.prototype.StaticData_GetBanks = function () {
        var path = this.baseUrl + '/api/staticdata/bank';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known BankHolidaies.
     */
    ApiDataService.prototype.StaticData_GetBankHolidaies = function () {
        var path = this.baseUrl + '/api/staticdata/bankholiday';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known BankHolidayTypes.
     */
    ApiDataService.prototype.StaticData_GetBankHolidayTypes = function () {
        var path = this.baseUrl + '/api/staticdata/bankholidaytype';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known BaseCalculationMethods.
     */
    ApiDataService.prototype.StaticData_GetBaseCalculationMethods = function () {
        var path = this.baseUrl + '/api/staticdata/basecalculationmethod';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known BillingPrinciples.
     */
    ApiDataService.prototype.StaticData_GetBillingPrinciples = function () {
        var path = this.baseUrl + '/api/staticdata/billingprinciple';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known Cities.
     */
    ApiDataService.prototype.StaticData_GetCities = function () {
        var path = this.baseUrl + '/api/staticdata/city';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known CompanyPreferences.
     */
    ApiDataService.prototype.StaticData_GetCompanyPreferences = function () {
        var path = this.baseUrl + '/api/staticdata/companypreference';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known CompanyPreferenceCategories.
     */
    ApiDataService.prototype.StaticData_GetCompanyPreferenceCategories = function () {
        var path = this.baseUrl + '/api/staticdata/companypreferencecategory';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known CompanyStatuses.
     */
    ApiDataService.prototype.StaticData_GetCompanyStatuses = function () {
        var path = this.baseUrl + '/api/staticdata/companystatus';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known CompanyTypes.
     */
    ApiDataService.prototype.StaticData_GetCompanyTypes = function () {
        var path = this.baseUrl + '/api/staticdata/companytype';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known Countries.
     */
    ApiDataService.prototype.StaticData_GetCountries = function () {
        var path = this.baseUrl + '/api/staticdata/country';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known Currencies.
     */
    ApiDataService.prototype.StaticData_GetCurrencies = function () {
        var path = this.baseUrl + '/api/staticdata/currency';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known DataTypes.
     */
    ApiDataService.prototype.StaticData_GetDataTypes = function () {
        var path = this.baseUrl + '/api/staticdata/datatype';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known Dimensions.
     */
    ApiDataService.prototype.StaticData_GetDimensions = function () {
        var path = this.baseUrl + '/api/staticdata/dimension';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known EconomicDepartmentSelectors.
     */
    ApiDataService.prototype.StaticData_GetEconomicDepartmentSelectors = function () {
        var path = this.baseUrl + '/api/staticdata/economicdepartmentselector';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known EIncomeGroupByParameters.
     */
    ApiDataService.prototype.StaticData_GetEIncomeGroupByParameters = function () {
        var path = this.baseUrl + '/api/staticdata/eincomegroupbyparameter';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known EmailTemplates.
     */
    ApiDataService.prototype.StaticData_GetEmailTemplates = function () {
        var path = this.baseUrl + '/api/staticdata/emailtemplate';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known EntityTypes.
     */
    ApiDataService.prototype.StaticData_GetEntityTypes = function () {
        var path = this.baseUrl + '/api/staticdata/entitytype';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known ExternalSystems.
     */
    ApiDataService.prototype.StaticData_GetExternalSystems = function () {
        var path = this.baseUrl + '/api/staticdata/externalsystem';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known ExternalSystemConfigurations.
     */
    ApiDataService.prototype.StaticData_GetExternalSystemConfigurations = function () {
        var path = this.baseUrl + '/api/staticdata/externalsystemconfiguration';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known ExternalSystemCredentials.
     */
    ApiDataService.prototype.StaticData_GetExternalSystemCredentials = function () {
        var path = this.baseUrl + '/api/staticdata/externalsystemcredential';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known ExternalSystemOperations.
     */
    ApiDataService.prototype.StaticData_GetExternalSystemOperations = function () {
        var path = this.baseUrl + '/api/staticdata/externalsystemoperation';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known ExtraVacationHoursEarningBehaviors.
     */
    ApiDataService.prototype.StaticData_GetExtraVacationHoursEarningBehaviors = function () {
        var path = this.baseUrl + '/api/staticdata/extravacationhoursearningbehavior';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known FinanceAccountTypes.
     */
    ApiDataService.prototype.StaticData_GetFinanceAccountTypes = function () {
        var path = this.baseUrl + '/api/staticdata/financeaccounttype';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known Genders.
     */
    ApiDataService.prototype.StaticData_GetGenders = function () {
        var path = this.baseUrl + '/api/staticdata/gender';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known ImportConversions.
     */
    ApiDataService.prototype.StaticData_GetImportConversions = function () {
        var path = this.baseUrl + '/api/staticdata/importconversion';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known ImportLists.
     */
    ApiDataService.prototype.StaticData_GetImportLists = function () {
        var path = this.baseUrl + '/api/staticdata/importlist';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known ImportMappingLimitations.
     */
    ApiDataService.prototype.StaticData_GetImportMappingLimitations = function () {
        var path = this.baseUrl + '/api/staticdata/importmappinglimitation';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known ImportOptions.
     */
    ApiDataService.prototype.StaticData_GetImportOptions = function () {
        var path = this.baseUrl + '/api/staticdata/importoption';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known ImportOptionValues.
     */
    ApiDataService.prototype.StaticData_GetImportOptionValues = function () {
        var path = this.baseUrl + '/api/staticdata/importoptionvalue';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known ImportTemplateParameters.
     */
    ApiDataService.prototype.StaticData_GetImportTemplateParameters = function () {
        var path = this.baseUrl + '/api/staticdata/importtemplateparameter';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known ImportTemplateParameterAssignments.
     */
    ApiDataService.prototype.StaticData_GetImportTemplateParameterAssignments = function () {
        var path = this.baseUrl + '/api/staticdata/importtemplateparameterassignment';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known ImportTypes.
     */
    ApiDataService.prototype.StaticData_GetImportTypes = function () {
        var path = this.baseUrl + '/api/staticdata/importtype';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known IncomeTypes.
     */
    ApiDataService.prototype.StaticData_GetIncomeTypes = function () {
        var path = this.baseUrl + '/api/staticdata/incometype';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known IndustryOrganizations.
     */
    ApiDataService.prototype.StaticData_GetIndustryOrganizations = function () {
        var path = this.baseUrl + '/api/staticdata/industryorganization';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known IntegrationStatuses.
     */
    ApiDataService.prototype.StaticData_GetIntegrationStatuses = function () {
        var path = this.baseUrl + '/api/staticdata/integrationstatus';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known IntervalTypes.
     */
    ApiDataService.prototype.StaticData_GetIntervalTypes = function () {
        var path = this.baseUrl + '/api/staticdata/intervaltype';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known InventioItDimensions.
     */
    ApiDataService.prototype.StaticData_GetInventioItDimensions = function () {
        var path = this.baseUrl + '/api/staticdata/inventioitdimension';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known Modules.
     */
    ApiDataService.prototype.StaticData_GetModules = function () {
        var path = this.baseUrl + '/api/staticdata/module';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known ModulePackages.
     */
    ApiDataService.prototype.StaticData_GetModulePackages = function () {
        var path = this.baseUrl + '/api/staticdata/modulepackage';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known Months.
     */
    ApiDataService.prototype.StaticData_GetMonths = function () {
        var path = this.baseUrl + '/api/staticdata/month';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known Municipalities.
     */
    ApiDataService.prototype.StaticData_GetMunicipalities = function () {
        var path = this.baseUrl + '/api/staticdata/municipality';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known OvertimeSupplementPrinciples.
     */
    ApiDataService.prototype.StaticData_GetOvertimeSupplementPrinciples = function () {
        var path = this.baseUrl + '/api/staticdata/overtimesupplementprinciple';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known PaymentExportFormats.
     */
    ApiDataService.prototype.StaticData_GetPaymentExportFormats = function () {
        var path = this.baseUrl + '/api/staticdata/paymentexportformat';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known PaymentMethods.
     */
    ApiDataService.prototype.StaticData_GetPaymentMethods = function () {
        var path = this.baseUrl + '/api/staticdata/paymentmethod';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known PaymentProviders.
     */
    ApiDataService.prototype.StaticData_GetPaymentProviders = function () {
        var path = this.baseUrl + '/api/staticdata/paymentprovider';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known PaymentStatuses.
     */
    ApiDataService.prototype.StaticData_GetPaymentStatuses = function () {
        var path = this.baseUrl + '/api/staticdata/paymentstatus';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known PaymentTypes.
     */
    ApiDataService.prototype.StaticData_GetPaymentTypes = function () {
        var path = this.baseUrl + '/api/staticdata/paymenttype';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known PensionProviders.
     */
    ApiDataService.prototype.StaticData_GetPensionProviders = function () {
        var path = this.baseUrl + '/api/staticdata/pensionprovider';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known Preferences.
     */
    ApiDataService.prototype.StaticData_GetPreferences = function () {
        var path = this.baseUrl + '/api/staticdata/preference';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known ReimbursementStatuses.
     */
    ApiDataService.prototype.StaticData_GetReimbursementStatuses = function () {
        var path = this.baseUrl + '/api/staticdata/reimbursementstatus';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known ReimbursementTypes.
     */
    ApiDataService.prototype.StaticData_GetReimbursementTypes = function () {
        var path = this.baseUrl + '/api/staticdata/reimbursementtype';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known Reports.
     */
    ApiDataService.prototype.StaticData_GetReports = function () {
        var path = this.baseUrl + '/api/staticdata/report';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known ReportAreas.
     */
    ApiDataService.prototype.StaticData_GetReportAreas = function () {
        var path = this.baseUrl + '/api/staticdata/reportarea';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known ReportParameters.
     */
    ApiDataService.prototype.StaticData_GetReportParameters = function () {
        var path = this.baseUrl + '/api/staticdata/reportparameter';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known Roles.
     */
    ApiDataService.prototype.StaticData_GetRoles = function () {
        var path = this.baseUrl + '/api/staticdata/role';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known SalaryBatchStatuses.
     */
    ApiDataService.prototype.StaticData_GetSalaryBatchStatuses = function () {
        var path = this.baseUrl + '/api/staticdata/salarybatchstatus';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known SalaryCycles.
     */
    ApiDataService.prototype.StaticData_GetSalaryCycles = function () {
        var path = this.baseUrl + '/api/staticdata/salarycycle';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known SalaryRecordPersistenceTypes.
     */
    ApiDataService.prototype.StaticData_GetSalaryRecordPersistenceTypes = function () {
        var path = this.baseUrl + '/api/staticdata/salaryrecordpersistencetype';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known SalaryRecordsOverviewDisplayModes.
     */
    ApiDataService.prototype.StaticData_GetSalaryRecordsOverviewDisplayModes = function () {
        var path = this.baseUrl + '/api/staticdata/salaryrecordsoverviewdisplaymode';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known SalarySummaryCategories.
     */
    ApiDataService.prototype.StaticData_GetSalarySummaryCategories = function () {
        var path = this.baseUrl + '/api/staticdata/salarysummarycategory';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known SalaryTypeCategories.
     */
    ApiDataService.prototype.StaticData_GetSalaryTypeCategories = function () {
        var path = this.baseUrl + '/api/staticdata/salarytypecategory';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known SalaryTypeDimensionDistributionOptions.
     */
    ApiDataService.prototype.StaticData_GetSalaryTypeDimensionDistributionOptions = function () {
        var path = this.baseUrl + '/api/staticdata/salarytypedimensiondistributionoption';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known SalaryTypeTotalsMultipleBatchesGroupByParameters.
     */
    ApiDataService.prototype.StaticData_GetSalaryTypeTotalsMultipleBatchesGroupByParameters = function () {
        var path = this.baseUrl + '/api/staticdata/salarytypetotalsmultiplebatchesgroupbyparameter';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known SeTaxTables.
     */
    ApiDataService.prototype.StaticData_GetSeTaxTables = function () {
        var path = this.baseUrl + '/api/staticdata/setaxtable';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known SignupReferrals.
     */
    ApiDataService.prototype.StaticData_GetSignupReferrals = function () {
        var path = this.baseUrl + '/api/staticdata/signupreferral';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known SkatteverketAgiFieldCodes.
     */
    ApiDataService.prototype.StaticData_GetSkatteverketAgiFieldCodes = function () {
        var path = this.baseUrl + '/api/staticdata/skatteverketagifieldcode';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known SortingOptions.
     */
    ApiDataService.prototype.StaticData_GetSortingOptions = function () {
        var path = this.baseUrl + '/api/staticdata/sortingoption';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known StatisticsEmploymentPrinciples.
     */
    ApiDataService.prototype.StaticData_GetStatisticsEmploymentPrinciples = function () {
        var path = this.baseUrl + '/api/staticdata/statisticsemploymentprinciple';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known StatisticsEmploymentTermses.
     */
    ApiDataService.prototype.StaticData_GetStatisticsEmploymentTermses = function () {
        var path = this.baseUrl + '/api/staticdata/statisticsemploymentterms';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known StatisticsJobStatuses.
     */
    ApiDataService.prototype.StaticData_GetStatisticsJobStatuses = function () {
        var path = this.baseUrl + '/api/staticdata/statisticsjobstatus';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known StatisticsRecipients.
     */
    ApiDataService.prototype.StaticData_GetStatisticsRecipients = function () {
        var path = this.baseUrl + '/api/staticdata/statisticsrecipient';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known StatisticsSalaryPrinciples.
     */
    ApiDataService.prototype.StaticData_GetStatisticsSalaryPrinciples = function () {
        var path = this.baseUrl + '/api/staticdata/statisticssalaryprinciple';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known SystemAliases.
     */
    ApiDataService.prototype.StaticData_GetSystemAliases = function () {
        var path = this.baseUrl + '/api/staticdata/systemalias';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known TaxCardTypes.
     */
    ApiDataService.prototype.StaticData_GetTaxCardTypes = function () {
        var path = this.baseUrl + '/api/staticdata/taxcardtype';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known TermExplanations.
     */
    ApiDataService.prototype.StaticData_GetTermExplanations = function () {
        var path = this.baseUrl + '/api/staticdata/termexplanation';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known TimeEntryRecordAcrossSalaryPeriodStrategies.
     */
    ApiDataService.prototype.StaticData_GetTimeEntryRecordAcrossSalaryPeriodStrategies = function () {
        var path = this.baseUrl + '/api/staticdata/timeentryrecordacrosssalaryperiodstrategy';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known TimeEntryStatuses.
     */
    ApiDataService.prototype.StaticData_GetTimeEntryStatuses = function () {
        var path = this.baseUrl + '/api/staticdata/timeentrystatus';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known TimeEntryStatusExtendeds.
     */
    ApiDataService.prototype.StaticData_GetTimeEntryStatusExtendeds = function () {
        var path = this.baseUrl + '/api/staticdata/timeentrystatusextended';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known UnicontaDimensions.
     */
    ApiDataService.prototype.StaticData_GetUnicontaDimensions = function () {
        var path = this.baseUrl + '/api/staticdata/unicontadimension';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known UnitTypes.
     */
    ApiDataService.prototype.StaticData_GetUnitTypes = function () {
        var path = this.baseUrl + '/api/staticdata/unittype';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known UserPreferences.
     */
    ApiDataService.prototype.StaticData_GetUserPreferences = function () {
        var path = this.baseUrl + '/api/staticdata/userpreference';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known VacationPeriods.
     */
    ApiDataService.prototype.StaticData_GetVacationPeriods = function () {
        var path = this.baseUrl + '/api/staticdata/vacationperiod';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known VacationProviders.
     */
    ApiDataService.prototype.StaticData_GetVacationProviders = function () {
        var path = this.baseUrl + '/api/staticdata/vacationprovider';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known VacationSupplementPayoutMethodDKs.
     */
    ApiDataService.prototype.StaticData_GetVacationSupplementPayoutMethodDKs = function () {
        var path = this.baseUrl + '/api/staticdata/vacationsupplementpayoutmethoddk';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known VacationTypes.
     */
    ApiDataService.prototype.StaticData_GetVacationTypes = function () {
        var path = this.baseUrl + '/api/staticdata/vacationtype';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known VacationTypeSEs.
     */
    ApiDataService.prototype.StaticData_GetVacationTypeSEs = function () {
        var path = this.baseUrl + '/api/staticdata/vacationtypese';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known WorkBookDimensions.
     */
    ApiDataService.prototype.StaticData_GetWorkBookDimensions = function () {
        var path = this.baseUrl + '/api/staticdata/workbookdimension';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of all known WorkBookVersions.
     */
    ApiDataService.prototype.StaticData_GetWorkBookVersions = function () {
        var path = this.baseUrl + '/api/staticdata/workbookversion';
        return this.httpGet(path);
    };
    ApiDataService.prototype.Test_TestStringFormatting = function () {
        var path = this.baseUrl + '/api/test/strings';
        return this.httpGet(path);
    };
    ApiDataService.prototype.Test_TestLanguageContext = function () {
        var path = this.baseUrl + '/api/test/translations';
        return this.httpGet(path);
    };
    ApiDataService.prototype.Test_TestBatchCalculation = function () {
        var path = this.baseUrl + '/api/test/batchcalc';
        return this.httpPost(path, undefined);
    };
    ApiDataService.prototype.Test_TestData = function () {
        var path = this.baseUrl + '/api/test/data';
        return this.httpPost(path, undefined);
    };
    ApiDataService.prototype.Test_TestSmallPdf = function () {
        var path = this.baseUrl + '/api/test/pdf/small';
        return this.httpGet(path);
    };
    ApiDataService.prototype.Test_TestLargePdf = function () {
        var path = this.baseUrl + '/api/test/pdf/large';
        return this.httpGet(path);
    };
    ApiDataService.prototype.Test_TestSmallPdfEncoded = function () {
        var path = this.baseUrl + '/api/test/pdf/small/encoded';
        return this.httpGet(path);
    };
    ApiDataService.prototype.Test_TestLargePdfEncoded = function () {
        var path = this.baseUrl + '/api/test/pdf/large/encoded';
        return this.httpGet(path);
    };
    ApiDataService.prototype.Test_TestXlsx = function () {
        var path = this.baseUrl + '/api/test/xlsx';
        return this.httpGet(path);
    };
    ApiDataService.prototype.Test_TestApiExceptionFilter = function () {
        var path = this.baseUrl + '/api/test/exception';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of time entry records across all employees that you have access to, in the specified salary
     * period.
     * @param The ID of the salary period for which to retrieve records.
     */
    ApiDataService.prototype.TimeEntry_GetTimeEntryRecordsByPeriod = function (periodId) {
        var path = this.baseUrl + '/api/timeentry/period/{periodId}';
        path = path.replace('{periodId}', periodId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of time entry records for the specified employment in the specified salary period.
     * @param The ID of the employment for which to retrieve salary records. Note: Do not pass a UserId or
     *        CompanyUserId.
     * @param The ID of the salary period for which to retrieve records.
     */
    ApiDataService.prototype.TimeEntry_GetTimeEntryRecordsByEmployment = function (userEmploymentId, periodId) {
        var path = this.baseUrl + '/api/timeentry/employment/{userEmploymentId}/period/{periodId}';
        path = path.replace('{userEmploymentId}', userEmploymentId.toString());
        path = path.replace('{periodId}', periodId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of time entry records across all periods that you are authorized to approve, optionally filtered
     * for salary cycle.
     * @param The ID of the salary cycle for which to retrieve records. Send 0 to include all cycles.
     */
    ApiDataService.prototype.TimeEntry_GetOpenTimeEntryRecordsAcrossPeriods = function (cycleId) {
        var path = this.baseUrl + '/api/timeentry/salarycycle/{cycleId}/open';
        path = path.replace('{cycleId}', cycleId.toString());
        return this.httpGet(path);
    };
    /**
     * Updates an existing time entry record. Note that the StatusId cannot be modified this way - call the approval
     * methods instead. After editing, the status will always be "Open". This works for rejected and employee-approved
     * records too.
     * @param The record to update.
     */
    ApiDataService.prototype.TimeEntry_UpdateTimeEntryRecord = function (record) {
        var path = this.baseUrl + '/api/timeentry';
        return this.httpPut(path, record);
    };
    /**
     * Updates an existing time entry record. Note that the StatusId cannot be modified this way - call the approval
     * methods instead. After editing, the status will always be "Open". This works for rejected and employee-approved
     * records too.
     * @param The record to update.
     */
    ApiDataService.prototype.TimeEntry_PatchTimeEntryRecord = function (record) {
        var path = this.baseUrl + '/api/timeentry';
        return this.httpPatch(path, record);
    };
    /**
     * Creates a time entry record. Note that the value SalaryPeriodId need not be specified - it will automatically be
     * set to match EntryDate.
     * @param The record to create.
     */
    ApiDataService.prototype.TimeEntry_CreateTimeEntryRecord = function (record) {
        var path = this.baseUrl + '/api/timeentry';
        return this.httpPost(path, record);
    };
    /**
     * Deletes an open time entry record.
     * @param The ID of the record to delete or deactivate.
     */
    ApiDataService.prototype.TimeEntry_DeleteTimeEntryRecord = function (recordId) {
        var path = this.baseUrl + '/api/timeentry/{recordId}';
        path = path.replace('{recordId}', recordId.toString());
        return this.httpDelete(path);
    };
    /**
     * Approves all open time entry records for the specified UserEmployment in the specified salary period - or across
     * periods if the value 0 is provided for periodId.
     * @param The ID of the employment for which to approve records.
     * @param The ID of the SalaryPeriod to approve. Send the value 0 to approve all open records across periods.
     */
    ApiDataService.prototype.TimeEntry_ApprovePeriodForEmployee = function (userEmploymentId, periodId) {
        var path = this.baseUrl + '/api/timeentry/employment/{userEmploymentId}/period/{periodId}/approval';
        path = path.replace('{userEmploymentId}', userEmploymentId.toString());
        path = path.replace('{periodId}', periodId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Approves all open time entry records in the specified salary period, or across periods if the value 0 is provided
     * for periodId.
     * @param The ID of the SalaryPeriod to approve. Send the value 0 to approve all open records across periods and
     *        cycles.
     */
    ApiDataService.prototype.TimeEntry_ApproveEntirePeriod = function (periodId) {
        var path = this.baseUrl + '/api/timeentry/period/{periodId}/approval';
        path = path.replace('{periodId}', periodId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Approves all open time entry records across all periods in the specified salary cycle.
     * @param The ID of the SalaryCycle to approve for.
     */
    ApiDataService.prototype.TimeEntry_ApproveEntireCycle = function (cycleId) {
        var path = this.baseUrl + '/api/timeentry/cycle/{cycleId}/approval';
        path = path.replace('{cycleId}', cycleId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Approve a single time entry record.
     * @param The ID of the record to delete or deactivate.
     */
    ApiDataService.prototype.TimeEntry_ApproveTimeEntryRecord = function (recordId) {
        var path = this.baseUrl + '/api/timeentry/{recordId}/approve';
        path = path.replace('{recordId}', recordId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Rejects an employee-approved time entry record, optionally specifying a reason.
     * @param The ID of the record to delete or deactivate.
     * @param An optional object specifying a reason for the rejection. May be omitted.
     */
    ApiDataService.prototype.TimeEntry_RejectTimeEntryRecord = function (recordId, info) {
        var path = this.baseUrl + '/api/timeentry/{recordId}/reject';
        path = path.replace('{recordId}', recordId.toString());
        return this.httpPost(path, info);
    };
    /**
     * Gets a list of all time entry types belonging to the currently active company. Note that this method exposes the
     * company's raw time entry types, so if inheritance is disabled then most properties will be null. Translations and
     * base time entry type are included in the response.
     */
    ApiDataService.prototype.TimeEntryTypes_GetRawTimeEntryTypes = function () {
        var path = this.baseUrl + '/api/timeentrytypes/raw';
        return this.httpGet(path);
    };
    /**
     * Gets a list of all time entry types belonging to the currently active company. Note that this method exposes the
     * company's raw time entry types, so if inheritance is disabled then most properties will be null. Translations and
     * base time entry type are included in the response. However, the Name, Description and DefaultPayslipText
     * properties are translated to the current session's language.
     */
    ApiDataService.prototype.TimeEntryTypes_GetRawTimeEntryTypesTranslated = function () {
        var path = this.baseUrl + '/api/timeentrytypes/raw/translated';
        return this.httpGet(path);
    };
    /**
     * Gets a list of all effective time entry types relevant for the currently active company, excluding hidden and
     * inactive ones. The result is the final values after accounting for inheritance (if any).
     */
    ApiDataService.prototype.TimeEntryTypes_GetTimeEntryTypeViews = function () {
        var path = this.baseUrl + '/api/timeentrytypes';
        return this.httpGet(path);
    };
    /**
     * Updates a time entry type. NOTE: Ensure that you use this in conjunction with the /raw method to avoid
     * unintentionally inheriting a base time entry type.
     * @param The time entry type to update.
     */
    ApiDataService.prototype.TimeEntryTypes_UpdateTimeEntryType = function (timeEntryType) {
        var path = this.baseUrl + '/api/timeentrytypes';
        return this.httpPut(path, timeEntryType);
    };
    /**
     * Updates a time entry type. NOTE: Ensure that you use this in conjunction with the /raw method to avoid
     * unintentionally inheriting a base time entry type.
     * @param The time entry type to update.
     */
    ApiDataService.prototype.TimeEntryTypes_PatchTimeEntryType = function (timeEntryType) {
        var path = this.baseUrl + '/api/timeentrytypes';
        return this.httpPatch(path, timeEntryType);
    };
    /**
     * Gets a list of all default time entry types.
     */
    ApiDataService.prototype.TimeEntryTypes_GetDefaultTimeEntryTypes = function () {
        var path = this.baseUrl + '/api/timeentrytypes/default';
        return this.httpGet(path);
    };
    /**
     * Gets a list of all default time entry types that are not currently mapped to the company.
     */
    ApiDataService.prototype.TimeEntryTypes_GetUnmappedDefaultTimeEntryTypes = function () {
        var path = this.baseUrl + '/api/timeentrytypes/default/unmapped';
        return this.httpGet(path);
    };
    /**
     * Creates a new custom time entry type.
     */
    ApiDataService.prototype.TimeEntryTypes_CreateCustomTimeEntryType = function (timeEntryType) {
        var path = this.baseUrl + '/api/timeentrytypes/custom';
        return this.httpPost(path, timeEntryType);
    };
    /**
     * Deletes a time entry type.
     */
    ApiDataService.prototype.TimeEntryTypes_DeleteTimeEntryType = function (timeEntryTypeId) {
        var path = this.baseUrl + '/api/timeentrytypes/{timeEntryTypeId}';
        path = path.replace('{timeEntryTypeId}', timeEntryTypeId.toString());
        return this.httpDelete(path);
    };
    /**
     * Inherits a default time entry type in the currently active company, creating a new company-specific time entry
     * type.
     */
    ApiDataService.prototype.TimeEntryTypes_InheritBaseTimeEntryType = function (request) {
        var path = this.baseUrl + '/api/timeentrytypes/inherit';
        return this.httpPost(path, request);
    };
    /**
     * Retrieves the set of translations for a time entry type.
     */
    ApiDataService.prototype.TimeEntryTypes_GetTranslations = function (timeEntryTypeId) {
        var path = this.baseUrl + '/api/timeentrytypes/{timeEntryTypeId}/translations';
        path = path.replace('{timeEntryTypeId}', timeEntryTypeId.toString());
        return this.httpGet(path);
    };
    /**
     * Creates / updates a set of time entry type translations. Entities with Id = 0 will be created, others will be
     * updated.
     */
    ApiDataService.prototype.TimeEntryTypes_SetTranslations = function (translations) {
        var path = this.baseUrl + '/api/timeentrytypes/translations/list';
        return this.httpPost(path, translations);
    };
    /**
     * Deletes the specified TimeEntryTypeConfiguration
     */
    ApiDataService.prototype.TimeEntryTypes_DeleteTimeEntryTypeConfiguration = function (timeEntryTypeConfigurationId) {
        var path = this.baseUrl + '/api/timeentrytypes/configurations/{timeEntryTypeConfigurationId}';
        path = path.replace('{timeEntryTypeConfigurationId}', timeEntryTypeConfigurationId.toString());
        return this.httpDelete(path);
    };
    /**
     * Get all TimeEntryTypeConfigurations for the current company.
     */
    ApiDataService.prototype.TimeEntryTypes_GetTimeEntryTypeConfigurations = function () {
        var path = this.baseUrl + '/api/timeentrytypes/configurations';
        return this.httpGet(path);
    };
    /**
     * Updates the specified TimeEntryTypeConfiguration.
     * @param The TimeEntryTypeConfiguration to update.
     */
    ApiDataService.prototype.TimeEntryTypes_UpdateTimeEntryTypeConfiguration = function (configuration) {
        var path = this.baseUrl + '/api/timeentrytypes/configurations';
        return this.httpPut(path, configuration);
    };
    /**
     * Updates the specified TimeEntryTypeConfiguration.
     * @param The TimeEntryTypeConfiguration to update.
     */
    ApiDataService.prototype.TimeEntryTypes_PatchTimeEntryTypeConfiguration = function (configuration) {
        var path = this.baseUrl + '/api/timeentrytypes/configurations';
        return this.httpPatch(path, configuration);
    };
    /**
     * Creates a new TimeEntryTypeConfiguration.
     * @param The TimeEntryTypeConfiguration to create.
     */
    ApiDataService.prototype.TimeEntryTypes_CreateTimeEntryTypeConfiguration = function (configuration) {
        var path = this.baseUrl + '/api/timeentrytypes/configurations';
        return this.httpPost(path, configuration);
    };
    /**
     * Get all TimeEntryTypeConfiguration belonging to a TimeEntryType for the current company.
     */
    ApiDataService.prototype.TimeEntryTypes_GetTimeEntryTypeConfigurationsByTimeEntry = function (timeEntryTypeId) {
        var path = this.baseUrl + '/api/timeentrytypes/{timeEntryTypeId}/configurations';
        path = path.replace('{timeEntryTypeId}', timeEntryTypeId.toString());
        return this.httpGet(path);
    };
    /**
     * Updates an existing user's master data, including Address and UserEmails. Note that only UserEmail objects can be
     * added and removed using this method. Provide null values (not empty lists) to leave child objects unmodified.
     * This method is only accessible to the user himself.
     * @param The user to update.
     */
    ApiDataService.prototype.Users_UpdateUser = function (user) {
        var path = this.baseUrl + '/api/users';
        return this.httpPut(path, user);
    };
    /**
     * Updates an existing user's master data, including Address and UserEmails. Note that only UserEmail objects can be
     * added and removed using this method. Provide null values (not empty lists) to leave child objects unmodified.
     * This method is only accessible to the user himself.
     * @param The user to update.
     */
    ApiDataService.prototype.Users_PatchUser = function (user) {
        var path = this.baseUrl + '/api/users';
        return this.httpPatch(path, user);
    };
    /**
     * Creates a new User within a Multi-Company organization. The current user must be a Multi-Company administrator.
     */
    ApiDataService.prototype.Users_CreateUser = function (user) {
        var path = this.baseUrl + '/api/users';
        return this.httpPost(path, user);
    };
    /**
     * Checks that a given identity (CPR) number is well-formed, and whether it represents an existing Intect user. Also
     * gives the associated date of birth, and whether a warning should be raised for a failed "modulo 11 check".
     */
    ApiDataService.prototype.Users_CheckIdentityNumber = function (identityNumber) {
        var path = this.baseUrl + '/api/users/identity/{identityNumber}';
        path = path.replace('{identityNumber}', identityNumber.toString());
        return this.httpGet(path);
    };
    /**
     * Checks a given username is well-formed and available
     */
    ApiDataService.prototype.Users_CheckUsername = function (username) {
        var path = this.baseUrl + '/api/users/username/{username}';
        path = path.replace('{username}', username.toString());
        return this.httpGet(path);
    };
    /**
     * Gets the current user's image as a raw byte array.
     */
    ApiDataService.prototype.Users_GetCurrentUserImage = function () {
        var path = this.baseUrl + '/api/users/current/image';
        return this.httpGet(path);
    };
    /**
     * Sets the current user's image. The image must be attached to the request as a file.
     */
    ApiDataService.prototype.Users_SetCurrentUserImage = function () {
        var path = this.baseUrl + '/api/users/current/image';
        return this.httpPost(path, undefined);
    };
    /**
     * Gets the specified user's image as a raw byte array. Defaults to the current user if no Id is specified.
     * @param The ID of the user for which to get the image. Note: Do not provide CompayUserId.
     */
    ApiDataService.prototype.Users_GetUserImageById = function (userId) {
        var path = this.baseUrl + '/api/users/{userId}/image';
        path = path.replace('{userId}', userId.toString());
        return this.httpGet(path);
    };
    /**
     * Sets the specified user's image. The image must be attached to the request as a file.
     * @param The ID of the user for which to set an image. Note: Do not provide CompanyUserId.
     */
    ApiDataService.prototype.Users_SetUserImageById = function (userId) {
        var path = this.baseUrl + '/api/users/{userId}/image';
        path = path.replace('{userId}', userId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Retrieves detailed information about the specified user.
     * @param The Id of the user to retrieve information about.
     */
    ApiDataService.prototype.Users_GetUser = function (userId) {
        var path = this.baseUrl + '/api/users/{userId}';
        path = path.replace('{userId}', userId.toString());
        return this.httpGet(path);
    };
    /**
     * Retrieves information about the currently logged-in user.
     */
    ApiDataService.prototype.Users_GetCurrentUser = function () {
        var path = this.baseUrl + '/api/users/current';
        return this.httpGet(path);
    };
    /**
     * Retrieves a list of companies that the currently logged-in user has access to.
     */
    ApiDataService.prototype.Users_GetCompanies = function () {
        var path = this.baseUrl + '/api/users/current/companies';
        return this.httpGet(path);
    };
    /**
     * Sets the specified company as default for the current user.
     * @param The Id of the company to use as default.
     */
    ApiDataService.prototype.Users_SetDefaultCompany = function (companyId) {
        var path = this.baseUrl + '/api/users/current/defaultcompany/{companyId}';
        path = path.replace('{companyId}', companyId.toString());
        return this.httpPost(path, undefined);
    };
    /**
     * Sets the specified language for the current user. Call GET to api/languages to get a list of supported languages.
     * Note that the change may not have any effect if the company has not enabled the Language module.
     * @param The Id of the language to set for the current user.
     */
    ApiDataService.prototype.Users_SetLanguage = function (languageId) {
        var path = this.baseUrl + '/api/users/current/language/{languageId}';
        path = path.replace('{languageId}', languageId.toString());
        return this.httpPut(path, undefined);
    };
    /**
     * Sets the specified language for the specified user. Call GET to api/languages to get a list of supported
     * languages. Note that the change may not have any effect if the company has not enabled the Language module.
     * @param The ID of the user whose language to change.
     * @param The Id of the language to set for the user.
     */
    ApiDataService.prototype.Users_SetLanguageUser = function (userId, languageId) {
        var path = this.baseUrl + '/api/users/{userId}/language/{languageId}';
        path = path.replace('{userId}', userId.toString());
        path = path.replace('{languageId}', languageId.toString());
        return this.httpPut(path, undefined);
    };
    /**
     * Sets the identity (CPR) number for the specified user.
     * @param The Id of the user whose identity number to change.
     * @param An object containing the new identity number (CPR) to set for the user. May optionally contain a hyphen.
     */
    ApiDataService.prototype.Users_ChangeIdentityNumber = function (userId, request) {
        var path = this.baseUrl + '/api/users/{userId}/identity';
        path = path.replace('{userId}', userId.toString());
        return this.httpPut(path, request);
    };
    /**
     * Updates existing Users, including submitted Address (to be modified, not added or removed).
     * @param A list of Users to update.
     */
    ApiDataService.prototype.Users_UpdateUsers = function (users) {
        var path = this.baseUrl + '/api/users/batch';
        return this.httpPut(path, users);
    };
    ApiDataService.prototype.httpGet = function (path, options) {
        return Observable.create(function (observer) {
            observer.complete();
        });
    };
    ApiDataService.prototype.httpPost = function (path, body, options) {
        return Observable.create(function (observer) {
            observer.complete();
        });
    };
    ApiDataService.prototype.httpPatch = function (path, body, options) {
        return Observable.create(function (observer) {
            observer.complete();
        });
    };
    ApiDataService.prototype.httpPut = function (path, body, options) {
        return Observable.create(function (observer) {
            observer.complete();
        });
    };
    ApiDataService.prototype.httpDelete = function (path, options) {
        return Observable.create(function (observer) {
            observer.complete();
        });
    };
    ApiDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiDataService_Factory() { return new ApiDataService(); }, token: ApiDataService, providedIn: "root" });
    return ApiDataService;
}());
export { ApiDataService };
