import { Global } from '../../../common/global';
import { UnitTypeEnum } from '../../../model/enum';
import { ISalaryTypeView, ISimpleKeyValuePair, IUnitType, SalaryRecord } from '../../../services/api/api-model';

export class PayrollCommon {
  public static enableDateAdjustmentPreferenceKey = 'PayrollData.EnableDateAdjustments';

  public static assignEditableFields(item: any, salaryTypes: ISalaryTypeView[]): any {
    const salaryType: ISalaryTypeView =
      salaryTypes && item.SalaryTypeId
        ? salaryTypes.find((type: any) => type.SalaryTypeId === item.SalaryTypeId)
        : undefined;
    const unitType: IUnitType = item.UnitTypeId
      ? Global.UNIT_TYPES.find((type: any) => type.Id === item.UnitTypeId)
      : undefined;
    item.salaryTypeName = salaryType && salaryType.Name ? salaryType.Name : '';
    item.AllowEditUnitType = salaryType && salaryType.AllowEditUnitType;
    item.UnitTypeClass = item.AllowEditUnitType ? '' : 'grid-disable-cell';
    item.AllowEditBaseAmount = salaryType && salaryType.AllowEditBaseAmount;
    item.tBaseAmountClass = item.AllowEditBaseAmount ? '' : 'grid-disable-cell';
    item.AllowEditAmountPerUnit = unitType && unitType.Id && unitType.Id !== UnitTypeEnum.Percent;
    item.AmountPerUnitClass = item.AllowEditAmountPerUnit ? '' : 'grid-disable-cell';
    item.AllowEditUnits = unitType && unitType.Id;
    item.UnitsClass = item.AllowEditUnits ? '' : 'grid-disable-cell';
    item.SalaryStatementId = item.SalaryStatementId || null;

    return item;
  }

  public static createSalaryRecord(salaryRecordView: any, userEmploymentId: number): SalaryRecord {
    const endDate: Date = salaryRecordView.EndDate || null;
    const startDate: Date = salaryRecordView.StartDate || null;
    const salaryRecord = new SalaryRecord();

    salaryRecord.Id = salaryRecordView.Id ? salaryRecordView.Id : salaryRecordView.id;
    salaryRecord.Amount = salaryRecordView.Amount || null;
    salaryRecord.AmountPerUnit = salaryRecordView.AmountPerUnit;
    salaryRecord.BaseAmount = salaryRecordView.BaseAmount || null;
    salaryRecord.Description = salaryRecordView.Description;
    salaryRecord.EndDate = endDate;
    salaryRecord.PersistenceTypeId = salaryRecordView.PersistenceTypeId;
    salaryRecord.SalaryTypeId = salaryRecordView.SalaryTypeId;
    salaryRecord.UnitTypeId = salaryRecordView.UnitTypeId;
    salaryRecord.Dimension1Value = salaryRecordView.Dimension1Value || null;
    salaryRecord.Dimension2Value = salaryRecordView.Dimension2Value || null;
    salaryRecord.Dimension3Value = salaryRecordView.Dimension3Value || null;
    salaryRecord.StartDate = startDate;
    salaryRecord.Units = salaryRecordView.Units || null;
    salaryRecord.UserEmploymentId = userEmploymentId;
    salaryRecord.IsActive = true;
    salaryRecord.SortOrder = salaryRecordView.SortOrder;
    salaryRecord.TimeEntryTypeId = salaryRecordView.TimeEntryTypeId || null;
    salaryRecord.ExternalIdentifier = salaryRecordView.ExternalIdentifier || null;
    salaryRecord.SalaryStatementId = salaryRecordView.SalaryStatementId || null;
    salaryRecord.Dimension1ValueId = salaryRecordView.Dimension1ValueId || null;
    salaryRecord.Dimension2ValueId = salaryRecordView.Dimension2ValueId || null;
    salaryRecord.Dimension3ValueId = salaryRecordView.Dimension3ValueId || null;

    salaryRecord.LegacyFlexEarningPercentage = salaryRecordView.LegacyFlexEarningPercentage || null;
    salaryRecord.NewFlexEarningPercentage = salaryRecordView.NewFlexEarningPercentage || null;
    salaryRecord.PensionPercentage = salaryRecordView.PensionPercentage || null;

    const unitTypes: any = Global.UNIT_TYPES.filter((tet: any) => tet.Id === salaryRecordView.UnitTypeId);
    if (unitTypes) {
      salaryRecord.UnitType = unitTypes[0];
    }

    return salaryRecord;
  }

  public static checkAmountAutoFlip(previous: SalaryRecord, dataItem: SalaryRecord): boolean {
    const res: boolean =
      (!previous.AmountPerUnit || previous.AmountPerUnit > 0 || !previous.Amount || previous.Amount > 0) &&
      dataItem.Amount < 0 &&
      dataItem.Amount !== previous.Amount;
    return res;
  }

  public static getLocalStorageEnableDatePreferenceSetting(): ISimpleKeyValuePair {
    const preferences: ISimpleKeyValuePair[] = JSON.parse(JSON.stringify(Global.COMPANY_PREFERENCES));
    if (preferences) {
      return preferences.filter(
        (pref: ISimpleKeyValuePair) => pref.Key === PayrollCommon.enableDateAdjustmentPreferenceKey
      )[0];
    }

    return undefined;
  }

  public static createLocalStorageEnableDatePreferenceSetting(defaultValue: boolean): ISimpleKeyValuePair {
    const preferences: ISimpleKeyValuePair[] = Global.COMPANY_PREFERENCES
      ? JSON.parse(JSON.stringify(Global.COMPANY_PREFERENCES))
      : [];
    const companyPreference: ISimpleKeyValuePair = {
      Key: PayrollCommon.enableDateAdjustmentPreferenceKey,
      Value: defaultValue ? 'true' : 'false'
    };
    preferences.push(companyPreference);
    Global.COMPANY_PREFERENCES = preferences;
    return companyPreference;
  }

  public static isAutoFlipAmountsSetting(): boolean {
    let result = true;
    const autoFlipSetting: ISimpleKeyValuePair =
      Global.USER_PREFERENCES && Global.USER_PREFERENCES.length > 0
        ? Global.USER_PREFERENCES.find((user: ISimpleKeyValuePair) => user.Key === 'SalaryRecords.AutoFlipAmounts')
        : undefined;
    if (autoFlipSetting) {
      result = autoFlipSetting.Value === 'true';
    }

    return result;
  }
}
