import { OnDestroy, OnInit } from '@angular/core';
import { PanelBarComponent } from '@progress/kendo-angular-layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionService } from '../../services/session/session.service';
import { ReportDialogService } from '../../shared-components/report-dialog/report-dialog.service';
import { CompanyPreferenceService } from './preference.service';
var PreferenceComponent = /** @class */ (function () {
    function PreferenceComponent(sessionService, reportDialogService, companyPreferenceService) {
        var _this = this;
        this.sessionService = sessionService;
        this.reportDialogService = reportDialogService;
        this.companyPreferenceService = companyPreferenceService;
        this.numericTextBoxOption = { step: 1, spinners: false, decimals: 0, format: 'n0' };
        this.ngUnsubscribe = new Subject();
        this.companyPreferenceService.companyReferencesCategory.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.companyReferencesCategory = data;
        });
        this.companyPreferenceService.companyReferencesInfo.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.companyReferencesInfo = data;
        });
        this.companyPreferenceService.companyReferencesStructure.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.companyReferencesStructure = data;
        });
        this.companyPreferenceService.reloveData.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            if (data) {
                _this.bindPreference();
            }
        });
        this.companyPreferenceService.reloadPanel.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            if (data) {
                _this.setDefaultPanelTab();
            }
        });
    }
    Object.defineProperty(PreferenceComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    PreferenceComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    PreferenceComponent.prototype.ngOnInit = function () {
        this.bindPreference();
    };
    PreferenceComponent.prototype.bindPreference = function () {
        var _this = this;
        this.companyPreferenceService.dataInint().subscribe(function () {
            if (_this.companyReferencesCategory && _this.companyReferencesInfo && _this.companyReferencesStructure) {
                _this.companyPreferenceService
                    .createPreferences(_this.companyReferencesInfo, _this.companyReferencesStructure)
                    .subscribe(function (preferences) {
                    _this.companyReferencesCategory.forEach(function (category) {
                        category.Preferences = preferences.filter(function (pref) { return pref.CategoryId === category.Id; });
                    });
                    _this.companyPreferenceService.reloadPanel.emit(true);
                });
            }
        });
    };
    PreferenceComponent.prototype.setDefaultPanelTab = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.companyPanelBar && _this.companyPanelBar.allItems && _this.companyPanelBar.allItems[0]) {
                _this.companyPanelBar.allItems[0].selected = true;
                _this.companyPanelBar.allItems[0].expanded = true;
            }
        });
    };
    PreferenceComponent.prototype.onCompanyPanelChanged = function (event) { };
    PreferenceComponent.prototype.onReadMoreClick = function (openLink) {
        window.open(openLink);
    };
    PreferenceComponent.prototype.onBtnEvent = function (item) {
        var value = !item.Value;
        this.companyPreferenceService.submitValueChange(value, item);
    };
    return PreferenceComponent;
}());
export { PreferenceComponent };
