import { Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterDateTimeGrid } from '../../../common/filter-date-time-grid';
import { SalaryBatchStatusEnum } from '../../../model/enum';
import { ISalaryBatch, ISalaryCycle } from '../../../services/api/api-model';
import { SessionService } from '../../../services/session/session.service';
import { CompanySalaryBatchesChildFormEntityContext } from '../company-salary-batches-child-form-entity-context';
import { CompanySalaryBatchService } from '../company-salary-batches.service';
import { SalaryBatchViewModel } from '../salary-batch-view-model';

@Component({
  selector: 'app-salary-batches',
  templateUrl: './salary-batches.component.html',
  styleUrls: ['./salary-batches.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SalaryBatchesComponent implements OnDestroy {
  private salaryCyclesValue: ISalaryCycle[] = [];
  @Input()
  public get salaryCycles(): ISalaryCycle[] {
    return this.salaryCyclesValue;
  }
  public set salaryCycles(value: ISalaryCycle[]) {
    if (this.salaryCyclesValue !== value) {
      this.salaryCyclesValue = value;
      this.filterData();
    }
  }

  private salaryBatchesValue: SalaryBatchViewModel[] = [];
  @Input()
  public get salaryBatches(): SalaryBatchViewModel[] {
    return this.salaryBatchesValue;
  }
  public set salaryBatches(value: SalaryBatchViewModel[]) {
    if (this.salaryBatchesValue !== value) {
      this.salaryBatchesValue = value;
      this.filterData();
    }
  }

  private salaryBatchValue: SalaryBatchViewModel;
  public get selectedBatch(): SalaryBatchViewModel {
    return this.salaryBatchValue;
  }
  public set selectedBatch(value: SalaryBatchViewModel) {
    this.companySalaryBatchService.setNextValueSelectedSalaryBatch(value);
  }

  @Output() public editSalaryBatch: EventEmitter<SalaryBatchViewModel> = new EventEmitter<SalaryBatchViewModel>();
  @Output() public showErrors: EventEmitter<SalaryBatchViewModel> = new EventEmitter<SalaryBatchViewModel>();
  @Output() public recalculateDraft: EventEmitter<void> = new EventEmitter<void>();
  @Output() public newSalaryBatch: EventEmitter<void> = new EventEmitter<void>();
  @Output() public finalizeDraft: EventEmitter<void> = new EventEmitter<void>();
  @Output() public submitForApproval: EventEmitter<void> = new EventEmitter<void>();

  public gridData: SalaryBatchViewModel[] = [];
  public triggerUpdate = false;

  private salaryBatchContext: CompanySalaryBatchesChildFormEntityContext;

  private salaryCycleIdValue: number;
  public get salaryCycleId(): number {
    return this.salaryCycleIdValue;
  }
  public set salaryCycleId(value: number) {
    if (this.salaryCycleIdValue !== value) {
      this.salaryCycleIdValue = value;
      this.filterData();
    }
  }

  public get isReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }
  public get isAddNewBatchButtonVisible(): boolean {
    return this.sessionService.feature.AllowEditCompanyData(this.sessionService.currentState) && !this.isReadOnly;
  }
  public get isDraft(): boolean {
    return this.selectedBatch && this.selectedBatch.StatusId === SalaryBatchStatusEnum.Draft;
  }
  public get isLocked(): boolean {
    return this.selectedBatch && this.selectedBatch.StatusId === SalaryBatchStatusEnum.ForApproval;
  }
  public get isPreliminary(): boolean {
    return this.selectedBatch && this.selectedBatch.StatusId === SalaryBatchStatusEnum.ForApproval;
  }
  public get isPaymentApprover(): boolean {
    return this.sessionService.role.IsPaymentApprover;
  }
  public get isShowApprovalButton(): boolean {
    if (window.innerWidth <= 550) {
      return false;
    } else {
      return (
        this.sessionService.role.IsSalaryAdminAndHigher &&
        this.salaryBatchContext &&
        this.salaryBatchContext.BatchId > 0 &&
        this.salaryBatchContext.IsDraft
      );
    }
  }
  public get isShowApprovalButtonNonScreenCheck(): boolean {
    return (
      this.sessionService.role.IsSalaryAdminAndHigher &&
      this.salaryBatchContext &&
      this.salaryBatchContext.BatchId > 0 &&
      this.salaryBatchContext.IsDraft
    );
  }

  public get isDisableApproval(): boolean {
    return !this.sessionService.feature.hasModuleId(3);
  }

  constructor(public sessionService: SessionService, private companySalaryBatchService: CompanySalaryBatchService) {
    this.companySalaryBatchService.selectedSalaryBatch
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: ISalaryBatch | any) => {
        this.salaryBatchValue = value;
      });

    this.companySalaryBatchService.salaryBatchEntityContext
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: CompanySalaryBatchesChildFormEntityContext) => {
        this.salaryBatchContext = value;
      });
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onSalaryBatchSelectionChanged(data: SalaryBatchViewModel): void {
    this.companySalaryBatchService.setNextValueSelectedSalaryBatch(data);
  }

  public onDoubleClick(dataItem: SalaryBatchViewModel): void {
    if (dataItem) {
      this.selectedBatch = dataItem;
      this.editSalaryBatch.emit(dataItem);
    }
  }

  public onGridIconClick(event: { dataItem: SalaryBatchViewModel; iconAction: string }): void {
    switch (event.iconAction) {
      case 'ShowErrors':
        if (event.dataItem.HasErrors || event.dataItem.HasWarnings) {
          this.showErrors.emit(event.dataItem);
        }

        break;
      case 'Edit':
        this.selectedBatch = event.dataItem;
        this.editSalaryBatch.emit(event.dataItem);
        break;
    }
  }

  public truncatedOrWrapped(): string {
    return !this.sessionService.role.IsReadOnly ? 'truncated' : 'wrapped';
  }

  private filterData(): void {
    if (this.salaryCycleId) {
      this.gridData = this.salaryBatches.filter((s: SalaryBatchViewModel) => s.SalaryCycleId === this.salaryCycleId);
    } else {
      this.gridData = this.salaryBatches;
    }

    this.gridFilterData = filterBy(this.gridData, this.filter);
    if (this.gridData && this.gridData.length > 0) {
      if (!this.selectedBatch) {
        this.selectedBatch = this.gridData[0];
      } else {
        if (!this.gridData.some((d: SalaryBatchViewModel) => d.Id === this.selectedBatch.Id)) {
          this.selectedBatch = this.gridData[0];
        }
      }
    } else {
      this.selectedBatch = null;
    }
    this.triggerUpdate = true;
  }

  public filter: CompositeFilterDescriptor;
  public gridFilterData: any[] = this.gridData;
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    if (filter && filter.filters && filter.filters.length > 0) {
      const filterDateTimeGrid = new FilterDateTimeGrid();
      const filtersDate: any = [];
      this.filter = filterDateTimeGrid.convertFilterOperator(
        filter,
        filtersDate,
        'PeriodStartDate',
        'PeriodEndDate',
        'PayoutDate'
      );
      this.gridFilterData = filterDateTimeGrid.doFilterDate(
        this.gridData,
        filtersDate,
        'PeriodStartDate',
        'PeriodEndDate',
        'PayoutDate'
      );
      this.gridFilterData = filterBy(this.gridFilterData, this.filter);
      if (filtersDate.length > 0) {
        [].push.apply(this.filter.filters, filtersDate);
      }
    } else {
      this.gridFilterData = this.gridData;
    }
  }

  public onSalaryBatchDialogAction(action: string): void {}
  public onClickButton(action: string): void {
    if (this.sessionService.isGetting || this.sessionService.isSubmitting) {
      return;
    }
    switch (action) {
      case 'Recalculate':
        this.recalculateDraft.emit();
        break;
      case 'Submit':
        this.submitForApproval.emit();
        break;
      case 'Finalize':
        this.finalizeDraft.emit();
        break;
      case 'NewSalaryBatch':
        this.newSalaryBatch.emit();
        break;
    }
  }
}
