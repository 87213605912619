import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { version } from '../../../version';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { RouterStateService } from '../../common/router-state.service';
import { EmployeeService } from '../../services/employee.service';
import { ModalService } from '../modal.service';
import { SessionService } from '../session/session.service';
import { SettingService } from '../setting.service';
import { Account, IAccount, IAccountInvoiceType, IAccountRole, IAccountType, IAdditionalVacationTerminationMethod, IApiUser, IBalancePeriod, IBank, IBankHoliday, IBaseCalculationMethod, IBillingPrinciple, ICity, ICompany, ICompanyAccountView, ICompanyType, ICompanyUser, ICompanyUserView, ICountry, ICurrency, IDepartment, IEntityType, IExternalSystem, IExternalSystemConfiguration, IExternalSystemCredential, IExternalSystemOperation, IExtraVacationHoursEarningBehavior, IFinanceAccountType, IGender, IImportOption, IImportOptionValue, IImportType, IIncomeType, IIntegration, IIntegrationStatus, IIntervalType, ILanguage, IModule, IModuleCompanyView, IModulePackage, IMonth, IMunicipality, IOvertimeSupplementPrinciple, IPaymentMethod, IPensionProvider, IReportArea, IReportParameter, IRole, ISalaryCycle, ISalaryRecordPersistenceType, ISalaryRecordsOverviewDisplayMode, ISalarySummaryCategory, ISalaryTypeCategory, ISalaryTypeDimensionDistributionOption, ISeTaxTable, ISimpleCompany, ISimpleKeyValuePair, IStartupTaskCompanyView, IStatisticsEmploymentPrinciple, IStatisticsEmploymentTerms, IStatisticsJobStatus, IStatisticsRecipient, IStatisticsSalaryPrinciple, ITaxCardType, ITimeEntryRecordAcrossSalaryPeriodStrategy, ITimeEntryStatus, IUnitType, IUserEmploymentView, IVacationProvider, IVacationSupplementPayoutMethodDK, IVacationType, IVacationTypeSE } from './api-model';
import { DataService } from './data.service';
import * as i0 from "@angular/core";
import * as i1 from "./data.service";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../setting.service";
import * as i4 from "../session/session.service";
import * as i5 from "../modal.service";
import * as i6 from "../../common/router-state.service";
import * as i7 from "../employee.service";
import * as i8 from "ngx-zendesk-webwidget";
var StaticDataService = /** @class */ (function () {
    function StaticDataService(dataService, translateService, settingsService, sessionService, modalService, state, employeeService, ngxZendeskWebwidgetService) {
        var _this = this;
        this.dataService = dataService;
        this.translateService = translateService;
        this.settingsService = settingsService;
        this.sessionService = sessionService;
        this.modalService = modalService;
        this.state = state;
        this.employeeService = employeeService;
        this.ngxZendeskWebwidgetService = ngxZendeskWebwidgetService;
        this.zendeskConfig = {
            timeOut: 0,
            lazyLoad: true,
            accountUrl: 'intect.zendesk.com',
            injectionTag: 'body',
            callback: function (zE) {
                // You can call every command you want in here
                zE('webWidget', 'hide');
            }
        };
        this.integrationChange = new EventEmitter();
        this.translateErrorMessages();
        this.configureSentryBrowser();
        this.sessionService.OnTranslateChanged.subscribe(function () { return _this.translateErrorMessages(); });
        this.ngxZendeskWebwidgetService.initZendesk(this.zendeskConfig);
    }
    StaticDataService.prototype.loadStaticData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService
                            .Auth_IsSessionAlive()
                            .toPromise()
                            .then(function () {
                            _this.sessionService.checkIsAliveError = false;
                        })
                            .catch(function () {
                            _this.sessionService.checkIsAliveError = true;
                        })];
                    case 1:
                        _a.sent();
                        if (this.sessionService.checkIsAliveError) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.loadSessionInfo()];
                    case 2:
                        _a.sent();
                        if (!this.moduleCompanyViewSubject) {
                            this.loadModuleCompanyView();
                        }
                        return [4 /*yield*/, this.loadUnitTypes()];
                    case 3:
                        _a.sent();
                        this.sessionService.hideGetStarted = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    StaticDataService.prototype.loadSessionInfo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, _a, shortCultureCode;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.dataService.Auth_GetSessionInfo().toPromise()];
                    case 1:
                        data = _b.sent();
                        // const CurrentAccount = await this.dataService.Account_GetUserAccount().toPromise();
                        // console.log(CurrentAccount);
                        environment.branding.SystemAlias = data.CurrentSystemName;
                        Global.SESSION = data;
                        // Load User employments
                        // if (Global.SESSION.CurrentCompanyUser && Global.SESSION.CurrentCompanyUser.Id) {
                        //   const currentUser = await this.dataService
                        //     .CompanyUsers_GetCompanyUserById(Global.SESSION.CurrentCompanyUser.Id)
                        //     .toPromise();
                        //   Global.USEREMPLOYMENTS = currentUser.UserEmployments;
                        // }
                        _a = Global;
                        return [4 /*yield*/, this.dataService.Companies_GetCurrent().toPromise()];
                    case 2:
                        // Load User employments
                        // if (Global.SESSION.CurrentCompanyUser && Global.SESSION.CurrentCompanyUser.Id) {
                        //   const currentUser = await this.dataService
                        //     .CompanyUsers_GetCompanyUserById(Global.SESSION.CurrentCompanyUser.Id)
                        //     .toPromise();
                        //   Global.USEREMPLOYMENTS = currentUser.UserEmployments;
                        // }
                        _a.COMPANY = _b.sent();
                        this.sessionService.loadGlobalize();
                        this.setupExtraDataSentry(data);
                        if (!this.enabledModulesSubject) {
                            this.enabledModulesSubject = new BehaviorSubject([]);
                        }
                        this.enabledModulesSubject.next(data.Modules);
                        shortCultureCode = data.SignOnToken.Language.CultureCode.substr(0, 2);
                        return [4 /*yield*/, this.translateService.use(shortCultureCode).toPromise()];
                    case 3:
                        _b.sent();
                        this.sessionService.applyLocale(shortCultureCode);
                        return [4 /*yield*/, this.getStartupTasks()];
                    case 4:
                        _b.sent();
                        return [4 /*yield*/, this.translateErrorMessages()];
                    case 5:
                        _b.sent();
                        return [4 /*yield*/, this.loadCompanyPreferences().toPromise()];
                    case 6:
                        _b.sent();
                        return [4 /*yield*/, this.loadUserPreferences().toPromise()];
                    case 7:
                        _b.sent();
                        return [4 /*yield*/, this.checkProduction().toPromise()];
                    case 8:
                        _b.sent();
                        return [4 /*yield*/, this.employeeService.loadEmployeesAsync()];
                    case 9:
                        _b.sent();
                        this.loadUserEmployments();
                        if (Global.SESSION && Global.SESSION.SignOnToken.LanguageId) {
                            try {
                                document.cookie = 'languageId=' + Global.SESSION.SignOnToken.LanguageId;
                            }
                            catch (e) {
                                this.sessionService.isDetectedCookieDisable = true;
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    StaticDataService.prototype.getStartupTasks = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tasks;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(Global.SESSION.CurrentRole.Key === 'FullAdmin' &&
                            Global.SESSION.CurrentCompanyUser.IsActive &&
                            Global.SESSION.CurrentCompany.CountryId !== Constants.GREENLAND_COUNTRY_ID)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.dataService
                                .Startuptasks_GetStartupTasksWithStatus()
                                .toPromise()];
                    case 1:
                        tasks = _a.sent();
                        Global.STARTUP_TASKS = tasks;
                        this.settingsService.updateSettingsForEmployeeCompany(tasks);
                        return [3 /*break*/, 3];
                    case 2:
                        Global.IsCompanyDataCompleted = true;
                        Global.IsEmployeeTaskCompleted = true;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    StaticDataService.prototype.translateErrorMessages = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.translateService
                                .get(['Error.UnExpectedErrorTitle', 'Error.UnExpectedErrorMessage'])
                                .toPromise()];
                    case 1:
                        _a.translations = _b.sent();
                        this.translations['Error.UnExpectedErrorMessage'] = this.translations['Error.UnExpectedErrorMessage'].replace('{{SystemAlias}}', environment.branding.SystemAlias);
                        return [2 /*return*/];
                }
            });
        });
    };
    StaticDataService.prototype.setupExtraDataSentry = function (data) {
        Sentry.setExtras({
            UserAccountId: data.UserAccountId,
            AccountRoleId: data.AccountRoleId,
            CurrentCompanyId: data.CurrentCompany.Id,
            CurrentCompanyName: data.CurrentCompany.Name,
            CurrentRoleId: data.CurrentRole.Id,
            UserId: data.Id,
            FullName: data.FullName,
            HasBackendAccess: data.HasBackendAccess,
            IsAccountAdmin: data.IsAccountAdmin,
            IsAccountMember: data.IsAccountMember,
            IsCurrentAccountAdmin: data.IsCurrentAccountAdmin,
            IsCurrentAccountMember: data.IsCurrentAccountMember,
            IsIntectSupportUser: data.IsIntectSupportUser,
            IsPaymentApprover: data.IsPaymentApprover
        });
    };
    StaticDataService.prototype.configureSentryBrowser = function () {
        var _this = this;
        // Set user information, as well as tags and further extras
        Sentry.init({
            dsn: environment.sentryDSN,
            environment: environment.environment,
            release: version,
            debug: true,
            beforeSend: function (info) {
                var extra = info.extra.extra;
                if (extra &&
                    (extra.status === 400 ||
                        (extra.status === 401 && extra.url.includes('session/isalive')) ||
                        extra.status === 404)) {
                    return null;
                }
                if (info.message && info.message.indexOf('a[b].target.className.indexOf') >= 0) {
                    return null;
                }
                var noneExceptionMessage = 'Non-Error exception captured with keys: error, headers, message, name, ok';
                if (info.exception &&
                    info.exception.values &&
                    info.exception.values.length > 0 &&
                    info.exception.values[0].value.indexOf(noneExceptionMessage) >= 0) {
                    return null;
                }
                if (version === 'Local-build') {
                    console.warn('Running local build, so error not sent to Sentry:', info);
                    return null;
                }
                // We dont show unexpected for network error that already catched.
                var isConnectionError = info.exception &&
                    info.exception.values &&
                    info.exception.values.some(function (ex) { return ex.value === 'GSNetworkError'; });
                if (isConnectionError || (extra && (extra.status || extra.status === 0))) {
                    if (extra && extra.status === 500) {
                        _this.modalService.alert(_this.defineUnExpectedErrorTitle(_this.translations['Error.UnExpectedErrorTitle']), _this.defineUnExpectedErrorMessage(_this.translations['Error.UnExpectedErrorMessage']));
                    }
                    return info;
                }
                if (extra && extra.url && extra.status && extra.status === 401 && !extra.url.includes('session/isalive')) {
                    return info;
                }
                _this.modalService.alert(_this.defineUnExpectedErrorTitle(_this.translations['Error.UnExpectedErrorTitle']), _this.defineUnExpectedErrorMessage(_this.translations['Error.UnExpectedErrorMessage']));
                return info;
            }
        });
    };
    // GS-6068
    StaticDataService.prototype.defineUnExpectedErrorTitle = function (translatetitle) {
        if (translatetitle && translatetitle !== 'Error.UnExpectedErrorTitle') {
            return translatetitle;
        }
        // default denish version
        return 'Uventet fejl';
    };
    // GS-6068
    StaticDataService.prototype.defineUnExpectedErrorMessage = function (translateErrorMessage) {
        if (translateErrorMessage && translateErrorMessage !== 'Error.UnExpectedErrorMessage') {
            return translateErrorMessage;
        }
        // default denish version
        return 'Der er opstået en uventet fejl. Hvis du forsøgte at gemme data, er dine ændringer muligvis gået tabt.<br/>Fejlen rapporteres automatisk til vores udviklere. Du kan forvente at den vil blive løst inden for en overskuelig tidshorisont.<br/>I mellemtiden kan du prøve at gentage din handling, genindlæse siden eller logge ud og ind igen. Du kan også prøve at benytte en anden browser, rydde din browser-cache, eller opdatere din app til nyeste version.<br/>Hvis dette ikke hjælper og du ikke kan ignorere fejlen, er du velkommen til at kontakte os for at få hjælp.<br/>Vi beklager ulejligheden.';
    };
    // Setup default routes and redirects depending on enabled modules and user role.
    StaticDataService.prototype.registerDefaultRoutes = function () {
        var _this = this;
        this.moduleCompanyView.subscribe(function () {
            _this.changeDefaultRoute();
        });
    };
    StaticDataService.prototype.changeDefaultRoute = function () {
        var currentRoleKey = Global.SESSION.CurrentRole.Key;
        // Company
        switch (currentRoleKey) {
            case 'FullAdmin':
                if (Global.IsTaskCompleted) {
                    this.state.registerDefault('tabs.company.salarybatches');
                    break;
                }
                this.state.registerDefault('tabs.company.general');
                break;
            case 'ReadOnly':
            case 'SalaryAdmin':
                if (Global.SESSION.IsFirstLogin) {
                    this.state.registerDefault('tabs.company.general');
                    break;
                }
                this.state.registerDefault('tabs.company.salarybatches');
                break;
            default:
                break;
        }
        // Employee
        switch (currentRoleKey) {
            case 'FullAdmin':
                if (Global.IsEmployeeTaskCompleted) {
                    this.state.registerDefault('tabs.employee.payrolldata');
                }
                else {
                    this.state.registerDefault('tabs.employee.general');
                }
                break;
            case 'ReadOnly':
            case 'SalaryAdmin':
                this.state.registerDefault('tabs.employee.payrolldata');
                break;
            case 'Employee':
                var hasManager = this.sessionService.role.EmployeeWithRoleAsManager;
                if (hasManager) {
                    this.state.registerDefault('tabs.employee.time');
                }
                break;
        }
        // Accountant
        this.state.registerDefault('tabs.accountant.companies');
        // Company - Approval
        this.state.registerDefault('tabs.company.approval.timeentry');
        // Company - Configuration
        this.state.registerDefault('tabs.company.configuration.hiringstatus');
        // Company - Blance
        this.state.registerDefault('tabs.company.balances.residualvacation');
        // Self Service
        this.state.registerDefault('tabs.selfservice.payslip');
        if (!this.sessionService.feature.hasModuleId(8)) {
            this.state.registerRedirect('tabs.selfservice.time', 'tabs.selfservice.payslip');
        }
        else {
            this.state.clearRedirect('tabs.selfservice.time');
        }
    };
    // Company Preferences
    StaticDataService.prototype.loadCompanyPreferences = function () {
        return this.dataService.Preferences_GetCompanyPreferences().pipe(map(function (data) {
            Global.COMPANY_PREFERENCES = data;
        }));
    };
    // User Preferences
    StaticDataService.prototype.loadUserPreferences = function () {
        return this.dataService.Preferences_GetUserPreferences().pipe(map(function (data) {
            Global.USER_PREFERENCES = data;
        }));
    };
    StaticDataService.prototype.checkProduction = function () {
        return this.dataService.Miscellaneous_IsProduction().pipe(map(function (isProduction) {
            Global.IsDemo = !isProduction;
        }));
    };
    Object.defineProperty(StaticDataService.prototype, "enabledModules", {
        get: function () {
            if (!this.enabledModulesSubject) {
                this.enabledModulesSubject = new BehaviorSubject([]);
                this.loadSessionInfo();
            }
            return this.enabledModulesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StaticDataService.prototype, "currentLogo", {
        get: function () {
            if (!this.currentLogoSubject) {
                this.loadCurrentLogo();
            }
            return this.currentLogoSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadCurrentLogo = function () {
        var _this = this;
        if (!this.currentLogoSubject) {
            this.currentLogoSubject = new BehaviorSubject(undefined);
        }
        this.dataService.Companies_GetCompanyLogo().subscribe(function (image) {
            _this.currentLogoSubject.next(image);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "currentUserImage", {
        get: function () {
            if (!this.currentUserImageSubject) {
                this.loadcurrentUserImage();
            }
            return this.currentUserImageSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadcurrentUserImage = function () {
        var _this = this;
        if (!this.currentUserImageSubject) {
            this.currentUserImageSubject = new BehaviorSubject(undefined);
        }
        this.dataService.Users_GetUserImage().subscribe(function (image) {
            _this.currentUserImageSubject.next(image);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "accountInvoiceTypes", {
        get: function () {
            if (!this.accountInvoiceTypesSubject) {
                this.loadAccountInvoiceTypes();
            }
            return this.accountInvoiceTypesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadAccountInvoiceTypes = function () {
        var _this = this;
        if (!this.accountInvoiceTypesSubject) {
            this.accountInvoiceTypesSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetAccountInvoiceTypes().subscribe(function (model) {
            _this.accountInvoiceTypesSubject.next(model);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "currentCompany", {
        get: function () {
            if (!this.currentCompanySubject) {
                this.loadCurrentCompany();
            }
            return this.currentCompanySubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadCurrentCompany = function () {
        var _this = this;
        if (!this.currentCompanySubject) {
            this.currentCompanySubject = new BehaviorSubject(undefined);
        }
        this.dataService.Companies_GetCurrent().subscribe(function (model) {
            _this.currentCompanySubject.next(model);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "currentAccount", {
        get: function () {
            if (!this.currentAccountSubject) {
                this.loadCurrentAccount();
            }
            return this.currentAccountSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadCurrentAccount = function () {
        var _this = this;
        if (!this.currentAccountSubject) {
            this.currentAccountSubject = new BehaviorSubject(new Account());
        }
        this.dataService.Account_GetUserAccount().subscribe(function (model) {
            _this.currentAccountSubject.next(model);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "companyAccount", {
        get: function () {
            if (!this.companyAccountSubject) {
                this.loadCompanyAccount();
            }
            return this.companyAccountSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadCompanyAccount = function () {
        var _this = this;
        if (!this.companyAccountSubject) {
            this.companyAccountSubject = new BehaviorSubject(new Account());
        }
        this.dataService.Account_GetCompanyAccount().subscribe(function (model) {
            _this.companyAccountSubject.next(model);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "multiCompanyAccount", {
        get: function () {
            if (!this.multiCompanyAccountSubject) {
                this.loadMultiCompanyAccount();
            }
            return this.multiCompanyAccountSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadMultiCompanyAccount = function () {
        var _this = this;
        if (!this.multiCompanyAccountSubject) {
            this.multiCompanyAccountSubject = new BehaviorSubject([]);
        }
        this.dataService.Account_GetAccountCompanies().subscribe(function (model) {
            _this.multiCompanyAccountSubject.next(model);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "accountantTabName", {
        get: function () {
            var _this = this;
            if (!this.accountantTabNameSubject) {
                this.accountantTabNameSubject = new BehaviorSubject('');
                this.AccountType.subscribe(function (accountTypes) {
                    if (accountTypes && accountTypes.length > 0) {
                        _this.currentAccount.subscribe(function (account) {
                            if (account) {
                                var accountType = accountTypes.find(function (t) { return t.Id === account.AccountTypeId; });
                                if (accountType) {
                                    _this.accountantTabNameSubject.next(accountType.Name);
                                }
                            }
                        });
                    }
                });
            }
            return this.accountantTabNameSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StaticDataService.prototype, "moduleCompanyView", {
        get: function () {
            if (!this.moduleCompanyViewSubject) {
                this.loadModuleCompanyView();
            }
            return this.moduleCompanyViewSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadModuleCompanyView = function () {
        var _this = this;
        if (!this.moduleCompanyViewSubject) {
            this.moduleCompanyViewSubject = new BehaviorSubject([]);
        }
        this.dataService.Modules_GetModulesForCurrentCompany().subscribe(function (data) {
            _this.sessionService.feature.modules = data;
            _this.moduleCompanyViewSubject.next(data);
            _this.registerDefaultRoutes();
        });
    };
    Object.defineProperty(StaticDataService.prototype, "companyUsers", {
        get: function () {
            if (!this.companyUsersSubject) {
                this.loadCompanyUsers();
            }
            return this.companyUsersSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadCompanyUsers = function () {
        var _this = this;
        if (!this.companyUsersSubject) {
            this.companyUsersSubject = new BehaviorSubject([]);
        }
        this.dataService.CompanyUsers_GetCompanyUsers().subscribe(function (companyUsers) {
            _this.companyUsersSubject.next(companyUsers);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "usersCompanies", {
        get: function () {
            if (!this.usersCompaniesSubject) {
                this.loadUsersCompanies();
            }
            return this.usersCompaniesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadUsersCompanies = function () {
        var _this = this;
        if (!this.usersCompaniesSubject) {
            this.usersCompaniesSubject = new BehaviorSubject([]);
        }
        this.dataService.Users_GetCompanies().subscribe(function (usersCompanies) {
            _this.usersCompaniesSubject.next(usersCompanies);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "companiesSimple", {
        get: function () {
            if (!this.companiesSimpleSubject) {
                this.loadCompaniesSimple();
            }
            return this.companiesSimpleSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadCompaniesSimple = function () {
        var _this = this;
        if (!this.usersCompaniesSubject) {
            this.companiesSimpleSubject = new BehaviorSubject([]);
        }
        this.dataService.Companies_GetAllSimple().subscribe(function (usersCompanies) {
            _this.companiesSimpleSubject.next(usersCompanies);
        });
    };
    StaticDataService.prototype.loadCompaniesSimpleAsync = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!!this.sessionService.checkIsAliveError) return [3 /*break*/, 2];
                        _b = (_a = this.companiesSimpleSubject).next;
                        return [4 /*yield*/, this.dataService.Companies_GetAllSimple().toPromise()];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        _c.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(StaticDataService.prototype, "Bank", {
        get: function () {
            if (!this.bankSubject) {
                this.loadBank();
            }
            return this.bankSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadBank = function () {
        var _this = this;
        if (!this.bankSubject) {
            this.bankSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetBanks().subscribe(function (data) {
            _this.bankSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "BankHoliday", {
        get: function () {
            if (!this.bankHolidaySubject) {
                this.loadBankHoliday();
            }
            return this.bankHolidaySubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadBankHoliday = function () {
        var _this = this;
        if (!this.bankHolidaySubject) {
            this.bankHolidaySubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetBankHolidaies().subscribe(function (data) {
            _this.bankHolidaySubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "BillingPrinciple", {
        get: function () {
            if (!this.billingPrincipleSubject) {
                this.loadBillingPrinciple();
            }
            return this.billingPrincipleSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadBillingPrinciple = function () {
        var _this = this;
        if (!this.billingPrincipleSubject) {
            this.billingPrincipleSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetBillingPrinciples().subscribe(function (data) {
            _this.billingPrincipleSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "departments", {
        get: function () {
            if (!this.departmenstSubject) {
                this.loadDepartments();
            }
            return this.departmenstSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadDepartments = function () {
        var _this = this;
        if (!this.departmenstSubject) {
            this.departmenstSubject = new BehaviorSubject([]);
        }
        this.dataService.Companies_GetDepartments().subscribe(function (departments) {
            _this.departmenstSubject.next(departments);
        });
    };
    StaticDataService.prototype.loadDepartmentsAsync = function () {
        var _this = this;
        if (!this.departmenstSubject) {
            this.departmenstSubject = new BehaviorSubject([]);
        }
        return this.dataService.Companies_GetDepartments().pipe(tap(function (departments) {
            _this.departmenstSubject.next(departments);
        }));
    };
    Object.defineProperty(StaticDataService.prototype, "languages", {
        get: function () {
            if (!this.languagesSubject) {
                this.loadLanguages();
            }
            return this.languagesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadLanguages = function () {
        var _this = this;
        if (!this.languagesSubject) {
            this.languagesSubject = new BehaviorSubject([]);
        }
        this.dataService.Miscellaneous_GetLanguages().subscribe(function (data) {
            _this.languagesSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "AccountType", {
        get: function () {
            if (!this.accountTypeSubject) {
                this.loadAccountType();
            }
            return this.accountTypeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadAccountType = function () {
        var _this = this;
        if (!this.accountTypeSubject) {
            this.accountTypeSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetAccountTypes().subscribe(function (data) {
            _this.accountTypeSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "City", {
        get: function () {
            if (!this.citySubject) {
                this.loadCity();
            }
            return this.citySubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadCity = function () {
        var _this = this;
        if (!this.citySubject) {
            this.citySubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetCities().subscribe(function (data) {
            _this.citySubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "employmentTitle", {
        get: function () {
            if (!this.employmentTitleSubject) {
                this.loadEmploymentTitle();
            }
            return this.employmentTitleSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadEmploymentTitle = function () {
        var _this = this;
        if (!this.employmentTitleSubject) {
            this.employmentTitleSubject = new BehaviorSubject([]);
        }
        this.dataService.Employments_GetUsedTitles().subscribe(function (model) {
            _this.employmentTitleSubject.next(model);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "CompanyType", {
        get: function () {
            if (!this.companyTypeSubject) {
                this.loadCompanyType();
            }
            return this.companyTypeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadCompanyType = function () {
        var _this = this;
        if (!this.companyTypeSubject) {
            this.companyTypeSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetCompanyTypes().subscribe(function (data) {
            _this.companyTypeSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "Country", {
        get: function () {
            if (!this.countrySubject) {
                this.loadCountry();
            }
            return this.countrySubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadCountry = function () {
        var _this = this;
        if (!this.countrySubject) {
            this.countrySubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetCountries().subscribe(function (data) {
            _this.countrySubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "Currency", {
        get: function () {
            if (!this.currencySubject) {
                this.loadCurrency();
            }
            return this.currencySubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadCurrency = function () {
        var _this = this;
        if (!this.currencySubject) {
            this.currencySubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetCurrencies().subscribe(function (data) {
            _this.currencySubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "ExternalSystem", {
        get: function () {
            if (!this.externalSystemSubject) {
                this.loadExternalSystem();
            }
            return this.externalSystemSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadExternalSystem = function () {
        var _this = this;
        if (!this.externalSystemSubject) {
            this.externalSystemSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetExternalSystems().subscribe(function (data) {
            _this.externalSystemSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "ExternalSystemConfiguration", {
        get: function () {
            if (!this.externalSystemConfigurationSubject) {
                this.loadExternalSystemConfiguration();
            }
            return this.externalSystemConfigurationSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadExternalSystemConfiguration = function () {
        var _this = this;
        if (!this.externalSystemConfigurationSubject) {
            this.externalSystemConfigurationSubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetExternalSystemConfigurations()
            .subscribe(function (data) {
            _this.externalSystemConfigurationSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "ExternalSystemCredential", {
        get: function () {
            if (!this.externalSystemCredentialSubject) {
                this.loadExternalSystemCredential();
            }
            return this.externalSystemCredentialSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadExternalSystemCredential = function () {
        var _this = this;
        if (!this.externalSystemCredentialSubject) {
            this.externalSystemCredentialSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetExternalSystemCredentials().subscribe(function (data) {
            _this.externalSystemCredentialSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "ExternalSystemOperation", {
        get: function () {
            if (!this.externalSystemOperationSubject) {
                this.loadExternalSystemOperation();
            }
            return this.externalSystemOperationSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadExternalSystemOperation = function () {
        var _this = this;
        if (!this.externalSystemOperationSubject) {
            this.externalSystemOperationSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetExternalSystemOperations().subscribe(function (data) {
            _this.externalSystemOperationSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "FinanceAccountType", {
        get: function () {
            if (!this.financeAccountTypeSubject) {
                this.loadFinanceAccountType();
            }
            return this.financeAccountTypeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadFinanceAccountType = function () {
        var _this = this;
        if (!this.financeAccountTypeSubject) {
            this.financeAccountTypeSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetFinanceAccountTypes().subscribe(function (data) {
            _this.financeAccountTypeSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "IncomeType", {
        get: function () {
            if (!this.incomeTypeSubject) {
                this.loadIncomeType();
            }
            return this.incomeTypeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadIncomeType = function () {
        var _this = this;
        if (!this.incomeTypeSubject) {
            this.incomeTypeSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetIncomeTypes().subscribe(function (data) {
            _this.incomeTypeSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "IntervalType", {
        get: function () {
            if (!this.intervalTypeSubject) {
                this.loadIntervalType();
            }
            return this.intervalTypeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadIntervalType = function () {
        var _this = this;
        if (!this.intervalTypeSubject) {
            this.intervalTypeSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetIntervalTypes().subscribe(function (data) {
            _this.intervalTypeSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "IntegrationStatus", {
        get: function () {
            if (!this.integrationStatusSubject) {
                this.loadIntegrationStatus();
            }
            return this.integrationStatusSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadIntegrationStatus = function () {
        var _this = this;
        if (!this.integrationStatusSubject) {
            this.integrationStatusSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetIntegrationStatuses().subscribe(function (data) {
            _this.integrationStatusSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "ModulePackage", {
        get: function () {
            if (!this.modulePackageSubject) {
                this.loadModulePackage();
            }
            return this.modulePackageSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadModulePackage = function () {
        var _this = this;
        if (!this.modulePackageSubject) {
            this.modulePackageSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetModulePackages().subscribe(function (data) {
            _this.modulePackageSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "Month", {
        get: function () {
            if (!this.monthSubject) {
                this.loadMonth();
            }
            return this.monthSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadMonth = function () {
        var _this = this;
        if (!this.monthSubject) {
            this.monthSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetMonths().subscribe(function (data) {
            _this.monthSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "Municipality", {
        get: function () {
            if (!this.municipalitySubject) {
                this.loadMunicipality();
            }
            return this.municipalitySubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadMunicipality = function () {
        var _this = this;
        if (!this.municipalitySubject) {
            this.municipalitySubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetMunicipalities().subscribe(function (data) {
            _this.municipalitySubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "OvertimeSupplementPrinciple", {
        get: function () {
            if (!this.overtimeSupplementPrincipleSubject) {
                this.loadOvertimeSupplementPrinciple();
            }
            return this.overtimeSupplementPrincipleSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadOvertimeSupplementPrinciple = function () {
        var _this = this;
        if (!this.overtimeSupplementPrincipleSubject) {
            this.overtimeSupplementPrincipleSubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetOvertimeSupplementPrinciples()
            .subscribe(function (data) {
            _this.overtimeSupplementPrincipleSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "PaymentMethod", {
        get: function () {
            if (!this.paymentMethodSubject) {
                this.loadPaymentMethod();
            }
            return this.paymentMethodSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadPaymentMethod = function () {
        var _this = this;
        if (!this.paymentMethodSubject) {
            this.paymentMethodSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetPaymentMethods().subscribe(function (data) {
            _this.paymentMethodSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "PensionBaseCalculationMethod", {
        get: function () {
            if (!this.pensionBaseCalculationMethodSubject) {
                this.loadPensionBaseCalculationMethod();
            }
            return this.pensionBaseCalculationMethodSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadPensionBaseCalculationMethod = function () {
        var _this = this;
        if (!this.pensionBaseCalculationMethodSubject) {
            this.pensionBaseCalculationMethodSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetBaseCalculationMethods().subscribe(function (data) {
            _this.pensionBaseCalculationMethodSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "ExtraVacationHoursEarningBehaviors", {
        get: function () {
            if (!this.extraVacationHoursEarningBehaviorsSubject) {
                this.loadextraVacationHoursEarningBehaviors();
            }
            return this.extraVacationHoursEarningBehaviorsSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadextraVacationHoursEarningBehaviors = function () {
        var _this = this;
        if (!this.extraVacationHoursEarningBehaviorsSubject) {
            this.extraVacationHoursEarningBehaviorsSubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetExtraVacationHoursEarningBehaviors()
            .subscribe(function (data) {
            _this.extraVacationHoursEarningBehaviorsSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "PensionProvider", {
        get: function () {
            if (!this.pensionProviderSubject) {
                this.loadPensionProvider();
            }
            return this.pensionProviderSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadPensionProvider = function () {
        var _this = this;
        if (!this.pensionProviderSubject) {
            this.pensionProviderSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetPensionProviders().subscribe(function (data) {
            _this.pensionProviderSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "ReportArea", {
        get: function () {
            if (!this.reportAreaSubject) {
                this.loadReportArea();
            }
            return this.reportAreaSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadReportArea = function () {
        var _this = this;
        if (!this.reportAreaSubject) {
            this.reportAreaSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetReportAreas().subscribe(function (data) {
            _this.reportAreaSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "ReportParameter", {
        get: function () {
            if (!this.reportParameterSubject) {
                this.loadReportParameter();
            }
            return this.reportParameterSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadReportParameter = function () {
        var _this = this;
        if (!this.reportParameterSubject) {
            this.reportParameterSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetReportParameters().subscribe(function (data) {
            _this.reportParameterSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "Role", {
        get: function () {
            if (!this.roleSubject) {
                this.loadRole();
            }
            return this.roleSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadRole = function () {
        var _this = this;
        if (!this.roleSubject) {
            this.roleSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetRoles().subscribe(function (data) {
            _this.roleSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "AccountantRole", {
        get: function () {
            if (!this.accountantRoleSubject) {
                this.loadAccountantRole();
            }
            return this.accountantRoleSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadAccountantRole = function () {
        var _this = this;
        if (!this.accountantRoleSubject) {
            this.accountantRoleSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetAccountRoles().subscribe(function (data) {
            _this.accountantRoleSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "SalaryCycle", {
        get: function () {
            if (!this.salaryCycleSubject) {
                this.loadSalaryCycle();
            }
            return this.salaryCycleSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadSalaryCycle = function () {
        var _this = this;
        if (!this.salaryCycleSubject) {
            this.salaryCycleSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetSalaryCycles().subscribe(function (data) {
            _this.salaryCycleSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "SalaryCycleUsed", {
        get: function () {
            if (!this.salaryCycleUsedSubject) {
                this.loadSalaryCycleUsed();
            }
            return this.salaryCycleUsedSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadSalaryCycleUsed = function () {
        var _this = this;
        if (!this.salaryCycleUsedSubject) {
            this.salaryCycleUsedSubject = new BehaviorSubject([]);
        }
        this.dataService.Miscellaneous_GetUsedSalaryCycles().subscribe(function (data) {
            _this.salaryCycleUsedSubject.next(data);
        });
    };
    StaticDataService.prototype.loadSalaryCycleUsedAsync = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = this.salaryCycleUsedSubject).next;
                        return [4 /*yield*/, this.dataService.Miscellaneous_GetUsedSalaryCycles().toPromise()];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(StaticDataService.prototype, "SalarySummaryCategory", {
        get: function () {
            if (!this.salarySummaryCategorySubject) {
                this.loadSalarySummaryCategory();
            }
            return this.salarySummaryCategorySubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadSalarySummaryCategory = function () {
        var _this = this;
        if (!this.salarySummaryCategorySubject) {
            this.salarySummaryCategorySubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetSalarySummaryCategories().subscribe(function (data) {
            _this.salarySummaryCategorySubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "SalaryRecordPersistenceType", {
        get: function () {
            if (!this.salaryRecordPersistenceTypeSubject) {
                this.loadSalaryRecordPersistenceType();
            }
            return this.salaryRecordPersistenceTypeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadSalaryRecordPersistenceType = function () {
        var _this = this;
        if (!this.salaryRecordPersistenceTypeSubject) {
            this.salaryRecordPersistenceTypeSubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetSalaryRecordPersistenceTypes()
            .subscribe(function (data) {
            _this.salaryRecordPersistenceTypeSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "SalaryTypeCategory", {
        get: function () {
            if (!this.salaryTypeCategorySubject) {
                this.loadSalaryTypeCategory();
            }
            return this.salaryTypeCategorySubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadSalaryTypeCategory = function () {
        var _this = this;
        if (!this.salaryTypeCategorySubject) {
            this.salaryTypeCategorySubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetSalaryTypeCategories().subscribe(function (data) {
            _this.salaryTypeCategorySubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "StatisticsEmploymentPrinciple", {
        get: function () {
            if (!this.statisticsEmploymentPrincipleSubject) {
                this.loadStatisticsEmploymentPrinciple();
            }
            return this.statisticsEmploymentPrincipleSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadStatisticsEmploymentPrinciple = function () {
        var _this = this;
        if (!this.statisticsEmploymentPrincipleSubject) {
            this.statisticsEmploymentPrincipleSubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetStatisticsEmploymentPrinciples()
            .subscribe(function (data) {
            _this.statisticsEmploymentPrincipleSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "StatisticsEmploymentTerms", {
        get: function () {
            if (!this.statisticsEmploymentTermsSubject) {
                this.loadStatisticsEmploymentTerms();
            }
            return this.statisticsEmploymentTermsSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadStatisticsEmploymentTerms = function () {
        var _this = this;
        if (!this.statisticsEmploymentTermsSubject) {
            this.statisticsEmploymentTermsSubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetStatisticsEmploymentTermses()
            .subscribe(function (data) {
            _this.statisticsEmploymentTermsSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "StatisticsJobStatus", {
        get: function () {
            if (!this.statisticsJobStatusSubject) {
                this.loadStatisticsJobStatus();
            }
            return this.statisticsJobStatusSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadStatisticsJobStatus = function () {
        var _this = this;
        if (!this.statisticsJobStatusSubject) {
            this.statisticsJobStatusSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetStatisticsJobStatuses().subscribe(function (data) {
            _this.statisticsJobStatusSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "StatisticsRecipient", {
        get: function () {
            if (!this.statisticsRecipientSubject) {
                this.loadStatisticsRecipient();
            }
            return this.statisticsRecipientSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadStatisticsRecipient = function () {
        var _this = this;
        if (!this.statisticsRecipientSubject) {
            this.statisticsRecipientSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetStatisticsRecipients().subscribe(function (data) {
            _this.statisticsRecipientSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "StatisticsSalaryPrinciple", {
        get: function () {
            if (!this.statisticsSalaryPrincipleSubject) {
                this.loadStatisticsSalaryPrinciple();
            }
            return this.statisticsSalaryPrincipleSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadStatisticsSalaryPrinciple = function () {
        var _this = this;
        if (!this.statisticsSalaryPrincipleSubject) {
            this.statisticsSalaryPrincipleSubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetStatisticsSalaryPrinciples()
            .subscribe(function (data) {
            _this.statisticsSalaryPrincipleSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "TaxCardType", {
        get: function () {
            if (!this.taxCardTypeSubject) {
                this.loadTaxCardType();
            }
            return this.taxCardTypeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadTaxCardType = function () {
        var _this = this;
        if (!this.taxCardTypeSubject) {
            this.taxCardTypeSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetTaxCardTypes().subscribe(function (data) {
            _this.taxCardTypeSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "TimeEntryRecordAcrossSalaryPeriodStrategy", {
        get: function () {
            if (!this.timeEntryRecordAcrossSalaryPeriodStrategySubject) {
                this.loadTimeEntryRecordAcrossSalaryPeriodStrategy();
            }
            return this.timeEntryRecordAcrossSalaryPeriodStrategySubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadTimeEntryRecordAcrossSalaryPeriodStrategy = function () {
        var _this = this;
        if (!this.timeEntryRecordAcrossSalaryPeriodStrategySubject) {
            this.timeEntryRecordAcrossSalaryPeriodStrategySubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetTimeEntryRecordAcrossSalaryPeriodStrategies()
            .subscribe(function (data) {
            _this.timeEntryRecordAcrossSalaryPeriodStrategySubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "TimeEntryStatus", {
        get: function () {
            if (!this.timeEntryStatusSubject) {
                this.loadTimeEntryStatus();
            }
            return this.timeEntryStatusSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadTimeEntryStatus = function () {
        var _this = this;
        if (!this.timeEntryStatusSubject) {
            this.timeEntryStatusSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetTimeEntryStatuses().subscribe(function (data) {
            _this.timeEntryStatusSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "SalaryRecordsOverviewDisplayMode", {
        get: function () {
            if (!this.salaryRecordsOverviewDisplayModeSubject) {
                this.loadSalaryRecordsOverviewDisplayMode();
            }
            return this.salaryRecordsOverviewDisplayModeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadSalaryRecordsOverviewDisplayMode = function () {
        var _this = this;
        if (!this.salaryRecordsOverviewDisplayModeSubject) {
            this.salaryRecordsOverviewDisplayModeSubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetSalaryRecordsOverviewDisplayModes()
            .subscribe(function (data) {
            _this.salaryRecordsOverviewDisplayModeSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "UnitTypes", {
        get: function () {
            if (!this.unitTypesSubject) {
                this.loadUnitTypes();
            }
            return this.unitTypesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadUnitTypes = function () {
        var _this = this;
        if (!this.unitTypesSubject) {
            this.unitTypesSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetUnitTypes().subscribe(function (data) {
            _this.unitTypesSubject.next(data);
            Global.UNIT_TYPES = data;
        });
    };
    Object.defineProperty(StaticDataService.prototype, "VacationProvider", {
        get: function () {
            if (!this.vacationProviderSubject) {
                this.loadVacationProvider();
            }
            return this.vacationProviderSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadVacationProvider = function () {
        var _this = this;
        if (!this.vacationProviderSubject) {
            this.vacationProviderSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetVacationProviders().subscribe(function (data) {
            _this.vacationProviderSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "VacationSupplementPayoutMethodDK", {
        get: function () {
            if (!this.vacationSupplementPayoutMethodDKSubject) {
                this.loadVacationSupplementPayoutMethodDK();
            }
            return this.vacationSupplementPayoutMethodDKSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadVacationSupplementPayoutMethodDK = function () {
        var _this = this;
        if (!this.vacationSupplementPayoutMethodDKSubject) {
            this.vacationSupplementPayoutMethodDKSubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetVacationSupplementPayoutMethodDKs()
            .subscribe(function (data) {
            _this.vacationSupplementPayoutMethodDKSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "VacationType", {
        get: function () {
            if (!this.vacationTypeSubject) {
                this.loadVacationType();
            }
            return this.vacationTypeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadVacationType = function () {
        var _this = this;
        if (!this.vacationTypeSubject) {
            this.vacationTypeSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetVacationTypes().subscribe(function (data) {
            _this.vacationTypeSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "AdditionalVacations", {
        get: function () {
            if (!this.additionalVacationsSubject) {
                this.loadAdditionalVacations();
            }
            return this.additionalVacationsSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadAdditionalVacations = function () {
        var _this = this;
        if (!this.additionalVacationsSubject) {
            this.additionalVacationsSubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetAdditionalVacationTerminationMethods()
            .subscribe(function (data) {
            _this.additionalVacationsSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "VacationPeriod", {
        get: function () {
            if (!this.vacationPeriodSubject) {
                this.loadVacationPeriod();
            }
            return this.vacationPeriodSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadVacationPeriod = function () {
        var _this = this;
        if (!this.vacationPeriodSubject) {
            this.vacationPeriodSubject = new BehaviorSubject([]);
        }
        this.dataService.Balances_GetDanishVacationBalancePeriods().subscribe(function (data) {
            _this.vacationPeriodSubject.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "VacationTypeSE", {
        get: function () {
            if (!this.vacationTypeSubjectSE) {
                this.loadVacationTypeSE();
            }
            return this.vacationTypeSubjectSE.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadVacationTypeSE = function () {
        var _this = this;
        if (!this.vacationTypeSubjectSE) {
            this.vacationTypeSubjectSE = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetVacationTypeSEs().subscribe(function (data) {
            _this.vacationTypeSubjectSE.next(data);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "Gender", {
        get: function () {
            if (!this.genderSubject) {
                this.loadGender();
            }
            return this.genderSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadGender = function () {
        var _this = this;
        if (!this.genderSubject) {
            this.genderSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetGenders().subscribe(function (data) {
            _this.genderSubject.next(data);
        });
    };
    StaticDataService.prototype.getCurrentdate = function (isEnddate, selectedDate) {
        var today = new Date();
        if (selectedDate !== undefined) {
            today = selectedDate instanceof Date ? selectedDate : new Date(selectedDate);
        }
        var yyyy = today.getFullYear();
        var mm = today.getMonth();
        var dd = today.getDate();
        if (isEnddate === true) {
            dd = 0;
            mm = today.getMonth() + 1;
        }
        var offset = new Date(yyyy, mm, dd).getTimezoneOffset() / 60;
        offset = offset === 12 || offset === -12 ? 0 : offset;
        if (offset > 0) {
            return new Date(yyyy, mm, dd, offset, 0, 0);
        }
        return new Date(yyyy, mm, dd, -offset, 0, 0);
    };
    StaticDataService.prototype.getUTCDate = function (selectedDate) {
        var today = new Date();
        if (selectedDate !== undefined) {
            today = selectedDate instanceof Date ? selectedDate : new Date(selectedDate);
        }
        var yyyy = today.getFullYear();
        var mm = today.getMonth();
        var dd = today.getDate();
        return new Date(yyyy, mm, dd, 0, 0, 0);
    };
    StaticDataService.prototype.getReportDate = function (selectedDate) {
        var today = new Date();
        if (selectedDate !== undefined) {
            today = selectedDate instanceof Date ? selectedDate : new Date(selectedDate);
        }
        var yyyy = today.getFullYear();
        var MM = today.getMonth();
        var dd = today.getDate();
        var hh = today.getHours();
        var mm = today.getMinutes();
        var offset = new Date(yyyy, mm, dd).getTimezoneOffset() / 60;
        offset = offset === 12 || offset === -12 ? 0 : offset;
        if (offset > 0) {
            return new Date(yyyy, mm, dd, hh + offset, mm, 0);
        }
        return new Date(yyyy, MM, dd, hh - offset, mm, 0);
    };
    StaticDataService.prototype.createValidDate = function (year, month, date) {
        var today = new Date();
        year =
            year < 100
                ? this.sessionService.parseInt(today
                    .getFullYear()
                    .toString()
                    .substr(0, 2) + this.sessionService.toString(year, '00'))
                : year;
        month = month > 11 ? 11 : month < 0 ? 0 : month;
        var lastDateOfMonth = new Date(year, month + 1, 0).getDate();
        date = date > lastDateOfMonth ? lastDateOfMonth : date < 0 ? 1 : date;
        var offset = new Date(year, month, date).getTimezoneOffset() / 60;
        offset = offset === 12 || offset === -12 ? 0 : offset;
        if (offset > 0) {
            return new Date(year, month, date, offset, 0, 0);
        }
        return new Date(year, month, date, -offset, 0, 0);
    };
    StaticDataService.prototype.dataURItoBlob = function (dataURI, type) {
        var byteString = window.atob(dataURI);
        var arrayBuffer = new ArrayBuffer(byteString.length);
        var int8Array = new Uint8Array(arrayBuffer);
        for (var i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([int8Array], { type: type });
        return blob;
    };
    StaticDataService.prototype.checkModelRecord = function (record) {
        Object.keys(record).forEach(function (key) {
            if (record[key] === undefined) {
                record[key] = null;
            }
        });
        return record;
    };
    StaticDataService.prototype.GetIntegration2Operation = function (exportNewPayrollBatchId) {
        var _this = this;
        if (this.observable) {
            // if `this.observable` is set then the request is in progress
            // return the `Observable` for the ongoing request
            return this.observable;
        }
        else {
            // create the request, store the `Observable` for subsequent subscribers
            this.observable = this.dataService.Integrations_GetIntegrationsByOperation(exportNewPayrollBatchId).pipe(map(function (integration2Operation) {
                setTimeout(function () {
                    _this.observable = undefined;
                }, 1000);
                _this.getIntegrationChangeEvent(integration2Operation);
            }));
            return this.observable.subscribe();
        }
    };
    StaticDataService.prototype.getIntegrationChangeEvent = function (Integration2Operation) {
        this.integrationChange.emit(Integration2Operation);
    };
    StaticDataService.prototype.getNavChangeEmitter = function () {
        return this.integrationChange;
    };
    Object.defineProperty(StaticDataService.prototype, "ImportTypes", {
        get: function () {
            if (!this.importTypeSubject) {
                this.loadImportTypes();
            }
            return this.importTypeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadImportTypes = function () {
        var _this = this;
        if (!this.importTypeSubject) {
            this.importTypeSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetImportTypes().subscribe(function (importTypes) {
            _this.importTypeSubject.next(importTypes);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "ImportOptionValues", {
        get: function () {
            if (!this.importOptionValueSubject) {
                this.loadImportOptionValues();
            }
            return this.importOptionValueSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadImportOptionValues = function () {
        var _this = this;
        if (!this.importOptionValueSubject) {
            this.importOptionValueSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetImportOptionValues().subscribe(function (importOptionValues) {
            _this.importOptionValueSubject.next(importOptionValues);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "ImportOptions", {
        get: function () {
            if (!this.importOptionSubject) {
                this.loadImportOptions();
            }
            return this.importOptionSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadImportOptions = function () {
        var _this = this;
        if (!this.importOptionSubject) {
            this.importOptionSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetImportOptions().subscribe(function (importOptions) {
            _this.importOptionSubject.next(importOptions);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "DefaultTaxTable", {
        get: function () {
            if (!this.defaultTaxTableSubject) {
                this.loadDefaultTaxTable();
            }
            return this.defaultTaxTableSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadDefaultTaxTable = function () {
        var _this = this;
        if (!this.defaultTaxTableSubject) {
            this.defaultTaxTableSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetSeTaxTables().subscribe(function (taxTables) {
            _this.defaultTaxTableSubject.next(taxTables);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "DimensionRedistributionBehavior", {
        get: function () {
            if (!this.dimensionRedistributionBehaviorSubject) {
                this.loadDimensionRedistributionBehavior();
            }
            return this.dimensionRedistributionBehaviorSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadDimensionRedistributionBehavior = function () {
        var _this = this;
        if (!this.dimensionRedistributionBehaviorSubject) {
            this.dimensionRedistributionBehaviorSubject = new BehaviorSubject([]);
        }
        this.dataService
            .StaticData_GetSalaryTypeDimensionDistributionOptions()
            .subscribe(function (taxTables) {
            _this.dimensionRedistributionBehaviorSubject.next(taxTables);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "userEmployments", {
        get: function () {
            if (!this.userEmploymentsSubject) {
                this.loadUserEmployments();
            }
            return this.userEmploymentsSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadUserEmployments = function () {
        var _this = this;
        var currentRoleKey = Global.SESSION.CurrentRole.Key;
        if (currentRoleKey === 'Employee') {
            return;
        }
        if (!this.userEmploymentsSubject) {
            this.userEmploymentsSubject = new BehaviorSubject([]);
        }
        this.dataService.Employments_GetEmploymentViews().subscribe(function (userEmployments) {
            _this.userEmploymentsSubject.next(userEmployments);
        });
    };
    Object.defineProperty(StaticDataService.prototype, "entityTypes", {
        get: function () {
            if (!this.entityTypeSubject) {
                this.loadEntityTypes();
            }
            return this.entityTypeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    StaticDataService.prototype.loadEntityTypes = function () {
        var _this = this;
        if (!this.entityTypeSubject) {
            this.entityTypeSubject = new BehaviorSubject([]);
        }
        this.dataService.StaticData_GetEntityTypes().subscribe(function (entityTypes) {
            _this.entityTypeSubject.next(entityTypes);
        });
    };
    StaticDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StaticDataService_Factory() { return new StaticDataService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.SettingService), i0.ɵɵinject(i4.SessionService), i0.ɵɵinject(i5.ModalService), i0.ɵɵinject(i6.RouterStateService), i0.ɵɵinject(i7.EmployeeService), i0.ɵɵinject(i8.NgxZendeskWebwidgetService)); }, token: StaticDataService, providedIn: "root" });
    return StaticDataService;
}());
export { StaticDataService };
