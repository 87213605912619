import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../common/constants';
import { FormComponentBase } from '../../common/form-component-base';
import { Global } from '../../common/global';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { TimeAndAbsenceModel } from './time-and-absence-model';
var TimeAndAbsenceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TimeAndAbsenceComponent, _super);
    function TimeAndAbsenceComponent(staticDataService, sessionService) {
        var _this = _super.call(this) || this;
        _this.staticDataService = staticDataService;
        _this.sessionService = sessionService;
        _this.modelChange = new EventEmitter();
        _this.AnnualIncomeMultiplicationFactorDataSource = [
            { Name: '12', Id: 12 },
            { Name: '12,2', Id: 12.2 }
        ];
        _this.NumbericTextBoxFFHoursEarnedPerYearOptions = {
            format: 'n2',
            decimals: 2,
            step: 1,
            spinners: false
        };
        _this.isShowSpecifyWeekDay = false;
        _this.childIsValid = Array(14).fill(true);
        _this.childIsDirty = Array(14).fill(false);
        _this.staticDataService.TimeEntryRecordAcrossSalaryPeriodStrategy.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (data) { return (_this.timeEntrySplitStrategy = data); });
        return _this;
    }
    Object.defineProperty(TimeAndAbsenceComponent.prototype, "model", {
        get: function () {
            if (!this.currentmodel) {
                this.currentmodel = {};
            }
            return this.currentmodel;
        },
        set: function (value) {
            this.currentmodel = value;
            this.valueAnnualIncomeMultiplicationFactorBeforeChange = null;
            this.childIsValid = Array(14).fill(true);
            this.childIsDirty = Array(14).fill(false);
            if (value) {
                if (value.StandardWorkHoursMonday ||
                    value.StandardWorkHoursTuesday ||
                    value.StandardWorkHoursWednesday ||
                    value.StandardWorkHoursThursday ||
                    value.StandardWorkHoursFriday ||
                    value.StandardWorkHoursSaturday ||
                    value.StandardWorkHoursSunday) {
                    this.isShowSpecifyWeekDay = true;
                }
                else {
                    this.isShowSpecifyWeekDay = false;
                }
            }
            if (value && value.AnnualIncomeMultiplicationFactor) {
                this.valueAnnualIncomeMultiplicationFactorBeforeChange = JSON.parse(JSON.stringify(value.AnnualIncomeMultiplicationFactor));
            }
            this.modelChange.emit(this.currentmodel);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeAndAbsenceComponent.prototype, "IsUserEmployment", {
        get: function () {
            return this.model && this.model.HireDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeAndAbsenceComponent.prototype, "isCompanyTemplate", {
        get: function () {
            return (this.sessionService.currentState && this.sessionService.currentState === 'tabs.company.configuration.hiringstatus');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeAndAbsenceComponent.prototype, "isSwedenCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    TimeAndAbsenceComponent.prototype.onApplyLongTermIllnessCompensationChange = function () {
        if (this.model.ApplyLongTermIllnessCompensation) {
            this.model.AnnualIncomeMultiplicationFactor = this.model.AnnualIncomeMultiplicationFactor || 12;
        }
        else {
            this.model.AnnualIncomeMultiplicationFactor = this.valueAnnualIncomeMultiplicationFactorBeforeChange
                ? JSON.parse(JSON.stringify(this.valueAnnualIncomeMultiplicationFactorBeforeChange))
                : null;
        }
        this.onChange();
    };
    TimeAndAbsenceComponent.prototype.showSpecifyWeekday = function () {
        this.isShowSpecifyWeekDay = true;
    };
    return TimeAndAbsenceComponent;
}(FormComponentBase));
export { TimeAndAbsenceComponent };
