import { IEIncomeResponseLine } from '../../../services/api/api-model';

export class SalaryBatchErrorsView implements IEIncomeResponseLine {
  public Id: number;
  public SalaryBatchId: number;
  public IsError: boolean;
  public RefLineNo: number;
  public RefFieldNo: number;
  public ResponseCode: number;
  public ResponseText?: string;
  public CprOrSeNo?: string;
  public ReceivedOn: Date;
  public ExportBatchIdentifier?: string;
  public RerunNumber: number;

  public icon: string;
  public iconTooltip: string;

  constructor(data: IEIncomeResponseLine, errorTooltip: string, warningTooltip: string) {
    Object.assign(this, data);
    this.icon = this.IsError ? 'ErrorExclamation' : 'Warning';
    this.iconTooltip = this.IsError ? errorTooltip : warningTooltip;
  }
}
