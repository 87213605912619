<div
  *ngFor="let salaryStatement of salaryStatementsNoneType"
  class="contentPayroll"
  (click)="onSelectsalaryStatement(salaryStatement)"
  [ngClass]="{ PayslipMobileActive: salaryStatement.Id === currentIdSelect }"
>
  <div class="rowContent">
    <div class="payroll-Firstcol-Mobile buttonPopUpContent" *ngFor="let buttonAction of groupIconAction">
      <app-icon
        *ngIf="!buttonAction.isHidden"
        (click)="appIconClick($event, salaryStatement, buttonAction.Action)"
        [tooltip]="buttonAction.tooltip | translate"
        [icon]="buttonAction.Icon"
        [hostClass]="'align-center'"
        class="payslipIconActionMobile"
      ></app-icon>
    </div>
    <div class="payslips-Lastcol-Mobile">
      <div class="payslip-TypeName">
        {{ 'CompanySalaryBatches.PaymentDate' | translate }}:
        {{ convertDateToString(salaryStatement.PayoutDateDerived) }}
      </div>
      <div class="payslip-Value">
        {{ salaryStatement.UserEmployment.Title }} {{ salaryStatement.PayoutAmount | kendoNumber: 'n2':'da' }}
      </div>
    </div>
  </div>
</div>

<div class="overlayScreen {{ classoverlayScreen }}" (click)="onShowSelectPeriodDialog()"></div>
<div class="PreviewGridMobile {{ classShowSelectPeriodDialog }} PreviewPayslipsGridMobileEnable">
  <div class="PayslipPreview-Control">
    <app-button
      class="Button--card"
      *ngIf="IsPaymentApprover && !IsReadonly"
      [isDisabled]="!selectedFirstRow"
      [label]="'CompanySalaryBatches.RevertThisPayslip' | translate"
      (clickEvent)="RevertPayslips()"
    ></app-button>
    <app-button
      class="Button--card"
      *ngIf="!IsPaymentApprover && !IsReadonly"
      [isDisabled]="true"
      [tooltip]="'RevertPayslip.NoPermission' | translate"
      [label]="'CompanySalaryBatches.RevertThisPayslip' | translate"
    ></app-button>
    <app-icon
      class="payslipMobileWarnning"
      *ngIf="!isFinalize"
      [tooltip]="'EmployeePayslip.PreliminaryTooltip' | translate"
      [icon]="'warning'"
    ></app-icon>

    <div class="payslip-chevron">
      <a (click)="onShowSelectPeriodDialog()">
        <app-icon [icon]="'ChevronDown-small'"></app-icon>
      </a>
    </div>
  </div>

  <div class="payslipAreaGrid">
    <app-grid
      class="PayslipPreviewGrid"
      [editMode]="false"
      [addNew]="false"
      [deleteRow]="false"
      [data]="detailSalaryStatement"
    >
      <app-grid-column [title]="''" [type]="'text'" [field]="'label'"></app-grid-column>
      <app-grid-column class="text-right" [title]="''" [type]="'text'" [field]="'value'" [width]="120">
      </app-grid-column>
    </app-grid>
  </div>
</div>
