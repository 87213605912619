<app-dialog (action)="onSaveAdHocSupport($event)" [overlayClose]="false" [(visible)]="visible" [minWidth]="380">
  <div class="FormElement">
    <app-combobox-edit
      [editMode]="true"
      [label]="'BillableSupport.Module' | translate"
      [comboboxDataSource]="moduleAdHocSupport"
      [idField]="'ModuleId'"
      [textField]="'ModuleName'"
      [(value)]="billingUsage.ModuleId"
      (valueChange)="onBillingPrincipChange()"
      (blur)="onBillingPrincipChange()"
    >
    </app-combobox-edit>
  </div>
  <div class="FormElement">
    <app-combobox-edit
      [editMode]="true"
      [label]="'AccountModules.BillingPrinciple' | translate"
      [comboboxDataSource]="billingPrinciple"
      [idField]="'Id'"
      [textField]="'Name'"
      [(value)]="billingUsage.BillingPrincipleId"
    ></app-combobox-edit>
  </div>
  <div class="FormElement">
    <app-numeric-edit
      [editMode]="true"
      [(value)]="billingUsage.Units"
      [label]="'EmploymentPayrollData.Units' | translate"
      [option]="NumericOptions"
      (blur)="onCaculateAmount()"
    >
    </app-numeric-edit>
  </div>
  <div class="FormElement">
    <app-numeric-edit
      [editMode]="true"
      [(value)]="billingUsage.AmountPerUnit"
      [label]="'EmploymentPayrollData.PerUnit' | translate"
      [option]="NumericOptions"
      (blur)="onCaculateAmount()"
    >
    </app-numeric-edit>
  </div>
  <div class="FormElement">
    <app-numeric-edit
      [editMode]="true"
      [(value)]="billingUsage.Amount"
      [label]="'EmploymentPayrollData.Amount' | translate"
      [option]="NumericOptions"
      [disable]="true"
    >
    </app-numeric-edit>
  </div>
  <div class="FormElement">
    <app-date-picker
      [e2e]="'BillingDate'"
      [editMode]="true"
      [label]="'BillableSupport.BillingDate' | translate"
      [(value)]="billingUsage.BillingDate"
    >
    </app-date-picker>
  </div>
  <div class="FormElement">
    <label class="FormElement-label">{{ 'EmploymentTime.Description' | translate }}</label>
    <br />
    <textarea class="FormElement-input" [(ngModel)]="billingUsage.Description"></textarea>
  </div>

  <div class="warningText" *ngIf="isShowPhoneSupport" [innerHTML]="'BillableSupport.PhoneSupport' | translate"></div>
  <div class="warningText" *ngIf="isShowEmailSupport" [innerHTML]="'BillableSupport.EmailSupport' | translate"></div>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Save'"></app-dialog-action>
</app-dialog>
