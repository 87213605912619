import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Global } from '../common/global';
var AccountantServiceBase = /** @class */ (function () {
    function AccountantServiceBase(api, modalService, sessionService, staticDataService) {
        var _this = this;
        this.api = api;
        this.modalService = modalService;
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.currentPageValue = 0;
        this.rolesSubject = new BehaviorSubject([]);
        this.filterSubject = new BehaviorSubject('');
        this.isLoading = false;
        this.ngUnsubscribe = new Subject();
        this.loadData();
        this.filterSubject
            .asObservable()
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(function () { return _this.applyFilter(); });
        this.modalService.modalServiceCloseErrorAlert.subscribe(function (event) {
            if (event) {
                _this.reloadData();
            }
        });
    }
    Object.defineProperty(AccountantServiceBase.prototype, "editMode", {
        get: function () {
            if (this.isBasicUser) {
                return false;
            }
            return this.editModeValue;
        },
        set: function (value) {
            this.editModeValue = value;
            this.sessionService.hideGetStarted = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantServiceBase.prototype, "roles", {
        get: function () {
            return this.rolesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantServiceBase.prototype, "isBasicUser", {
        get: function () {
            return Global.SESSION && Global.SESSION.AccountRoleId !== 90 && Global.SESSION.UserAccountId !== null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantServiceBase.prototype, "isCompanyAdmin", {
        get: function () {
            return Global.SESSION && Global.SESSION.AccountRoleId === null && Global.SESSION.CurrentRole.Id === 90;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantServiceBase.prototype, "isAccountAdmin", {
        get: function () {
            return Global.SESSION && Global.SESSION.IsAccountAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantServiceBase.prototype, "isShowEditMode", {
        get: function () {
            if (this.isCompanyAdmin || this.isBasicUser) {
                return false;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantServiceBase.prototype, "filter", {
        get: function () {
            return this.filterValue;
        },
        set: function (value) {
            this.filterValue = value;
            this.filterSubject.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantServiceBase.prototype, "currentPage", {
        get: function () {
            return this.currentPageValue;
        },
        set: function (value) {
            this.currentPageValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantServiceBase.prototype, "selectedId", {
        get: function () {
            return this.selectedIdValue;
        },
        set: function (value) {
            if (this.selectedIdValue !== value) {
                this.selectedIdValue = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    AccountantServiceBase.prototype.save = function (dataRolelist, isReload) {
        var _this = this;
        if (isReload === void 0) { isReload = true; }
        if (dataRolelist && dataRolelist.length > 0) {
            this.api.Account_BatchAssignUsersAccessToCompany(dataRolelist).subscribe(function () {
                if (isReload) {
                    _this.getRoles();
                }
            }, function () {
                if (isReload) {
                    _this.getRoles();
                }
            });
        }
    };
    AccountantServiceBase.prototype.saveUserList = function (userList) {
        var _this = this;
        if (userList && userList.length > 0) {
            this.api.Users_UpdateUsers(userList).subscribe(function (response) {
                if (response.ProcessedItems && response.ProcessedItems.length > 0) {
                    _this.updateData(response.ProcessedItems);
                }
                if (response.Errors && response.HasErrors && response.Errors['Intect.User']) {
                    _this.modalService.alert('', response.Errors['Intect.User'], true);
                }
            });
        }
    };
    AccountantServiceBase.prototype.discard = function () {
        this.getRoles();
    };
    return AccountantServiceBase;
}());
export { AccountantServiceBase };
