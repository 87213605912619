import { IImportColumn } from '../../../services/api/api-model';
import { MappingObject } from './mapping-object';

export class ImportMappingDataItem {
  public userFileColumnHeader: string;
  public userFileId: string;
  public intectColumnsDropDownDataSource: MappingObject[] = [];
  public columnsTypeDropDownDataSource: MappingObject[] = [];
  private intectColumnsValue: number;
  private columnstypeValue: number = undefined;

  private isId: boolean;
  public get IsId(): boolean {
    return this.isId;
  }

  public get columnsTypeValue(): number {
    return this.columnstypeValue;
  }

  public set columnsTypeValue(value: number) {
    this.columnstypeValue = value;
  }

  get columnsTypeKey(): string {
    const id: number = this.columnsTypeValue;
    if (!id) {
      return '';
    }

    const columnTypeItem: MappingObject = this.findColumnTypeItem(id);
    if (!columnTypeItem) {
      return '';
    }

    return columnTypeItem.Key;
  }

  public get IntectColumnsValue(): number {
    return this.intectColumnsValue;
  }

  public set IntectColumnsValue(value: number) {
    this.intectColumnsValue = value;
  }

  public setIntectColumnDropDownDataSourceAndAutoMap(dataSource: MappingObject[]): void {
    this.intectColumnsDropDownDataSource = dataSource;
  }

  public setColumnTypeDropDownDataSourceAndAutoMap(dataSource: MappingObject[]): void {
    this.columnsTypeDropDownDataSource = dataSource;
    //// this.autoMapColumnType();
  }

  private findIntectColumnItem(value: string): MappingObject {
    const dataSource: MappingObject[] = this.intectColumnsDropDownDataSource;
    if (!value || !dataSource || dataSource.length === 0) {
      return undefined;
    }

    let result: MappingObject;
    if (dataSource) {
      result = dataSource.find((val: MappingObject): boolean => {
        return val.Name === value;
      });
    }

    return result;
  }

  public findColumnTypeItem(id: number): MappingObject {
    const dataSource: MappingObject[] = this.columnsTypeDropDownDataSource;
    if (!id || !dataSource || dataSource.length === 0) {
      return undefined;
    }

    let result: MappingObject;
    if (dataSource) {
      result = dataSource.find((val: MappingObject): boolean => {
        return val.Id === id;
      });
    }

    return result;
  }

  public findColumnTypeItemByKey(key: string): MappingObject {
    const dataSource: MappingObject[] = this.columnsTypeDropDownDataSource;
    if (!key || !dataSource || dataSource.length === 0) {
      return undefined;
    }

    let result: MappingObject;
    if (dataSource) {
      result = dataSource.find((val: MappingObject): boolean => {
        return val.Key === key;
      });
    }

    return result;
  }

  private autoMapIntectColumn(): void {
    if (!this.intectColumnsDropDownDataSource) {
      return;
    }

    const intectColumn: MappingObject = this.findIntectColumnByName(this.userFileColumnHeader);
    if (!intectColumn) {
      return;
    }

    this.IntectColumnsValue = intectColumn.Id;
  }

  private findIntectColumnByName(name: string): MappingObject {
    if (this.intectColumnsDropDownDataSource) {
      return this.intectColumnsDropDownDataSource.find(
        (ctype: MappingObject) => this.userFileColumnHeader === ctype.Name
      );
    }

    return undefined;
  }

  // private autoMapColumnType(): void {
  //     if (!this.columnsTypeDropDownDataSource) {
  //         return;
  //     }

  //     let columnType: MappingObject = this.findColumnTypeByName(
  //         this.userFileColumnHeader
  //     );

  //     if (!columnType) {
  //         return;
  //     }

  //     this.columnsTypeValue = columnType.Id;
  // }

  // private findColumnTypeByName(name: string): MappingObject {
  //     return this.columnsTypeDropDownDataSource.find(
  //         (ctype: MappingObject) => {
  //             return (
  //                 this.userFileColumnHeader &&
  //                 ctype.Name &&
  //                 this.userFileColumnHeader.trim().toLowerCase() ===
  //                     ctype.Name.trim().toLowerCase()
  //             );
  //         }
  //     );
  // }
}
