<div class="Island Island--advanced-time-entry-type" [ngClass]="{ 'is-editing': companyAdvancedTypesService.editMode }">
  <div class="Cards Cards--packed Cards--flex">
    <div class="Card Card-50">
      <div class="Card-inner">
        <fieldset class="Form--horizontal">
          <legend class="Card-title">
            <span>{{ 'CompanySalaryTypes.AdvancedBasic' | translate }}</span>
          </legend>
          <div>
            <app-text-edit label="ID" [editMode]="false" [(value)]="companyAdvancedTypesService.selectedType.Id">
            </app-text-edit>
            <app-text-edit
              [label]="'CompanyTimeEntryTypes.InheritsFrom' | translate"
              [editMode]="false"
              [(value)]="companyAdvancedTypesService.selectedType.inheritsFrom"
              [tooltipContent]="'CompanySalaryTypes.BaseSalaryTypeNameHeaderTooltip' | translate"
            >
            </app-text-edit>
            <app-check-edit
              [label]="'CompanyTimeEntryTypes.IsActive' | translate"
              [editMode]="companyAdvancedTypesService.editMode"
              [(value)]="companyAdvancedTypesService.selectedType.IsActive"
              [(isDirty)]="childIsDirty[0]"
              [(isValid)]="childIsValid[0]"
              (valueChange)="onChange($event, 'IsActive', 0)"
              [ngClass]="companyAdvancedTypesService.selectedType.isActiveClassSingle"
            >
            </app-check-edit>
            <app-text-edit
              *ngIf="companyAdvancedTypesService.isLanguageModuleEnable"
              class="edit-translation-text"
              label="{{ 'CompanyTimeEntryTypes.Description' | translate }}"
              [editMode]="false"
              [(isDirty)]="childIsDirty[1]"
              [(isValid)]="childIsValid[1]"
              [value]="companyAdvancedTypesService.descriptionDisableLanguaged"
              [ngClass]="companyAdvancedTypesService.selectedType.descriptionClassSingle"
              [tooltipContent]="'CompanyTimeEntryTypes.DescriptionTooltip' | translate"
            >
            </app-text-edit>
            <app-text-edit
              *ngIf="!companyAdvancedTypesService.isLanguageModuleEnable"
              label="{{ 'CompanyTimeEntryTypes.Description' | translate }}"
              [editMode]="companyAdvancedTypesService.editMode"
              [isTextArea]="true"
              [textAreaLimitChar]="1000"
              [(isDirty)]="childIsDirty[1]"
              [(isValid)]="childIsValid[1]"
              (valueChange)="onChange($event, 'Description', 1)"
              [(value)]="companyAdvancedTypesService.selectedType.Description"
              [ngClass]="companyAdvancedTypesService.selectedType.descriptionClassSingle"
              [tooltipContent]="'CompanyTimeEntryTypes.DescriptionTooltip' | translate"
              [tooltipTextArea]="companyAdvancedTypesService.selectedType.Description"
              [placeholder]="
                companyAdvancedTypesService.selectedType.descriptionClassSingle &&
                companyAdvancedTypesService.selectedType.descriptionClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-text-edit>
            <app-text-edit
              *ngIf="!companyAdvancedTypesService.isLanguageModuleEnable"
              class="edit-translation-text"
              label="{{ 'CompanyTimeEntryTypes.PayslipText' | translate }}"
              [editMode]="companyAdvancedTypesService.editMode"
              [(isDirty)]="childIsDirty[2]"
              [(isValid)]="childIsValid[2]"
              (valueChange)="onChange($event, 'DefaultPayslipText', 2)"
              [(value)]="companyAdvancedTypesService.selectedType.DefaultPayslipText"
              [ngClass]="companyAdvancedTypesService.selectedType.defaultPayslipTextClassSingle"
              [tooltipContent]="'CompanyTimeEntryTypes.PayslipTextTooltip' | translate"
              [placeholder]="
                companyAdvancedTypesService.selectedType.defaultPayslipTextClassSingle &&
                companyAdvancedTypesService.selectedType.defaultPayslipTextClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-text-edit>
            <app-text-edit
              *ngIf="companyAdvancedTypesService.isLanguageModuleEnable"
              class="edit-translation-text"
              label="{{ 'CompanyTimeEntryTypes.PayslipText' | translate }}"
              [editMode]="false"
              [(isDirty)]="childIsDirty[2]"
              [(isValid)]="childIsValid[2]"
              [value]="companyAdvancedTypesService.defaultPayslipTextDisableLanguaged"
              [ngClass]="companyAdvancedTypesService.selectedType.defaultPayslipTextClassSingle"
              [tooltipContent]="'CompanyTimeEntryTypes.PayslipTextTooltip' | translate"
              [placeholder]="
                companyAdvancedTypesService.selectedType.defaultPayslipTextClassSingle &&
                companyAdvancedTypesService.selectedType.defaultPayslipTextClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-text-edit>
            <app-button
              class="Button--card"
              *ngIf="companyAdvancedTypesService.isLanguageModuleEnable"
              (clickEvent)="onTranslationConfigrationClicked()"
              [label]="'CompanySalaryTypes.EditTranslations' | translate"
            >
            </app-button>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="Card Card-50">
      <div class="Card-inner">
        <fieldset class="Form--horizontal">
          <legend class="Card-title">
            <span>{{ 'CompanySalaryTypes.AdvancedInterface' | translate }}</span>
          </legend>
          <div>
            <app-text-edit
              [editMode]="companyAdvancedTypesService.editMode"
              [(value)]="companyAdvancedTypesService.selectedType.ExternalReference"
              (valueChange)="onChange($event, 'ExternalReference', 3)"
              [(isDirty)]="childIsDirty[3]"
              [label]="'CompanyTimeEntryTypes.ExternalReference' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.externalReferenceClassSingle"
              [placeholder]="
                companyAdvancedTypesService.selectedType.externalReferenceClassSingle &&
                companyAdvancedTypesService.selectedType.externalReferenceClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-text-edit>

            <app-numeric-edit
              [editMode]="companyAdvancedTypesService.editMode"
              [(isDirty)]="childIsDirty[4]"
              [(value)]="companyAdvancedTypesService.selectedType.SortOrder"
              (valueChange)="onChange($event, 'SortOrder', 4)"
              [option]="companyAdvancedTypesService.option"
              [label]="'CompanyTimeEntryTypes.SortOrder' | translate"
              [helpTooltip]="'CompanyTimeEntryTypes.SortOrderTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.sortOrderClassSingle"
              [placeholder]="
                companyAdvancedTypesService.selectedType.sortOrderClassSingle &&
                companyAdvancedTypesService.selectedType.sortOrderClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-numeric-edit>

            <app-combobox-edit
              [label]="'CompanyTimeEntryTypes.UnitType' | translate"
              [editMode]="companyAdvancedTypesService.editMode"
              [comboboxDataSource]="unitTypes"
              [textField]="'Name'"
              [idField]="'Id'"
              [(value)]="companyAdvancedTypesService.selectedType.UnitTypeId"
              (valueChange)="onChange($event, 'UnitTypeId', 5)"
              [(isDirty)]="childIsDirty[5]"
              [tooltipContent]="'CompanyTimeEntryTypes.UnitTypeTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.unitTypeIdClassSingle"
              [placeholder]="
                companyAdvancedTypesService.selectedType.unitTypeIdClassSingle &&
                companyAdvancedTypesService.selectedType.unitTypeIdClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-combobox-edit>

            <app-check-edit
              class="time-entry-checkbox"
              [label]="'CompanyTimeEntryTypes.IsIllness' | translate"
              [editMode]="companyAdvancedTypesService.editMode"
              [(value)]="companyAdvancedTypesService.selectedType.IsIllness"
              [(isDirty)]="childIsDirty[6]"
              (valueChange)="onChange($event, 'IsIllness', 6)"
              [helpTooltip]="'CompanyTimeEntryTypes.IsIllnessTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.isIllnessClassSingle"
            >
            </app-check-edit>

            <app-combobox-edit
              [isTimeEntrySingle]="true"
              [disable]="isDisableSalaryType"
              [label]="'CompanyTimeEntryTypes.SalaryType' | translate"
              [nullValueLabel]="'DropdownList.None' | translate"
              [editMode]="companyAdvancedTypesService.editMode"
              [comboboxDataSource]="salaryTypes"
              [textField]="'Name'"
              [idField]="'SalaryTypeId'"
              [(value)]="isDisableSalaryType === true ? null : companyAdvancedTypesService.selectedType.SalaryTypeId"
              (valueChange)="onChange($event, 'SalaryTypeId', 7)"
              [(isDirty)]="childIsDirty[7]"
              [tooltipContent]="'CompanyTimeEntryTypes.SalaryTypeTooltip' | translate"
              [ngClass]="disableSalaryType"
              [placeholder]="
                companyAdvancedTypesService.selectedType.salaryTypeClassSingle &&
                companyAdvancedTypesService.selectedType.salaryTypeClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-combobox-edit>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="Card Card-grid">
      <div class="Card-inner">
        <fieldset class="Form--horizontal">
          <legend class="Card-title">
            <span>{{ 'TimeEntryTypeConfigurations.SalaryBehavior' | translate }}</span>
          </legend>
          <app-time-entry-type-configurations
            [timeEntryType]="companyAdvancedTypesService.selectedType"
            [salaryTypes]="salaryTypes"
            (IsHasChange)="onUpdateTimeEntryTypeConfiguration($event)"
          >
          </app-time-entry-type-configurations>
        </fieldset>
      </div>
    </div>
  </div>
</div>

<app-time-entry-type-translation-dialog
  [timeEntryType]="companyAdvancedTypesService.selectedType"
  [(visible)]="translationDialogVisible"
  (translateComplete)="onReloadTimeEntryTypes()"
>
</app-time-entry-type-translation-dialog>
