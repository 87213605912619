import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Constants } from 'src/app/common/constants';
import { environment } from 'src/environments/environment';
import { Global } from '../../common/global';
import { IStartupTaskCompanyView } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';

export enum StartUpTaskSTatus {
  Skipped = -1,
  Todo = 0,
  Completed = 1
}

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html'
})
export class GetStartedComponent implements OnInit, OnDestroy {
  @Input()
  public set hide(value: boolean) {
    this.visible =
      !(Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID) &&
      this.sessionService.role.IsFullAdmin &&
      !value &&
      Global.STARTUP_TASKS_VISIBILITY;
  }

  public get branding() {
    return environment.branding;
  }

  public isTaskListShown = false;
  public startupTasks: IStartupTaskCompanyView[] = [];
  public TaskClick: IStartupTaskCompanyView;
  public isShowTaskMess = false;
  public isShowmoreHelp = true;
  public isShowFianlDialog = false;
  public finalTask: IStartupTaskCompanyView;
  public isCompleteTask = false;
  public isShowingHiddenStartUp = false;
  public startUpProgress = 0;
  public startUpFadeout = false;
  public startUpIndicatorIsActive = true;
  public startUpStepsIsActive: boolean;
  public startUpStepsMaxHeight: number;
  public guideName = '';

  public visible = false;

  private get isAllTasksDone(): boolean {
    return this.startUpProgress === 100;
  }
  private unsubscribeResize: () => void;

  constructor(
    private dataService: DataService,
    private settingService: SettingService,
    public sessionService: SessionService,
    private renderer: Renderer2,
    private staticDataService: StaticDataService
  ) {}

  public ngOnInit(): void {
    this.loadData(true);
    this.unsubscribeResize = this.renderer.listen(window, 'resize', () => this.updateTaskListMaxHeight());
  }

  public ngOnDestroy(): void {
    //   this.unsubscribeResize();
  }

  public openTaskList(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    if (!this.isAllTasksDone && !this.hideTaskList()) {
      this.showTaskList(true);
    } else {
      this.hideTaskList();
    }
  }

  public navigateToTask(task: IStartupTaskCompanyView): void {
    if (task) {
      this.guideName = task.Name;
      this.TaskClick = task;
      const stateName: string = this.sessionService.getStateByUri(task.AppUri);
      if (stateName && this.sessionService.currentState !== stateName) {
        this.sessionService.NavigateTo(stateName);
        if (this.TaskClick.GuideMessage) {
          this.isShowTaskMess = true;
        }
      } else {
        if (task.ExternalLink) {
          if (task.GuideMessage) {
            this.isShowTaskMess = true;
          } else {
            this.navigateToExternalLink(task.ExternalLink);
          }
        } else {
          if (this.TaskClick.GuideMessage) {
            this.isShowTaskMess = true;
          }
        }
      }
    }
  }

  public get isExitLink(): boolean {
    if (this.TaskClick && (this.TaskClick.ExternalLink || this.TaskClick.HelpLink)) {
      return true;
    }
    return false;
  }

  public onTaskDialogAction(event: string): void {
    if (event && this.TaskClick) {
      switch (event) {
        case 'MoreHelp': {
          if (this.TaskClick.ExternalLink) {
            this.navigateToExternalLink(this.TaskClick.ExternalLink);
          } else if (this.TaskClick.HelpLink) {
            this.navigateToExternalLink(this.TaskClick.HelpLink);
          }

          this.TaskClick = undefined;
          break;
        }
        case 'Ok': {
          if (this.TaskClick.ExternalLink && this.TaskClick.GuideMessage) {
            this.navigateToExternalLink(this.TaskClick.ExternalLink);
            this.isShowTaskMess = false;
          }

          this.isShowTaskMess = false;
          this.TaskClick = undefined;
          break;
        }
        default: {
          this.isShowTaskMess = false;
          this.TaskClick = undefined;
          break;
        }
      }
    }
  }

  public skipTask(task: IStartupTaskCompanyView): void {
    if (this.sessionService.isSubmitting || this.sessionService.isGetting) {
      return;
    }
    if (task) {
      switch (task.StatusId) {
        case StartUpTaskSTatus.Skipped:
          this.resetTask(task);
          break;
        default:
          if (task.SkipWarning) {
            this.isShowFianlDialog = true;
            this.finalTask = task;
          } else {
            this.dataService.Startuptasks_SkipTask(task.TaskId).subscribe((startUpTask: IStartupTaskCompanyView) => {
              this.onUpdateTaskCompleted(startUpTask);
            });
          }

          break;
      }
    }
  }

  public finalDialogAction(action: string): void {
    if (action === 'Yes') {
      this.dataService
        .Startuptasks_SkipTask(this.finalTask.TaskId)
        .subscribe((startUpTask: IStartupTaskCompanyView) => {
          this.onUpdateTaskCompleted(startUpTask);
        });
    }
  }

  public completeTask(task: IStartupTaskCompanyView): void {
    if (this.sessionService.isSubmitting || this.sessionService.isGetting) {
      return;
    }
    if (task) {
      switch (task.StatusId) {
        case StartUpTaskSTatus.Completed:
          this.resetTask(task);
          break;
        default:
          this.dataService.Startuptasks_CompleteTask(task.TaskId).subscribe((startUpTask: IStartupTaskCompanyView) => {
            this.onUpdateTaskCompleted(startUpTask);
          });
          break;
      }
    }
  }

  public hideStartupTasks(event: any): void {
    this.isShowingHiddenStartUp = true;
  }

  public onCompleteTask(event: string): void {
    this.hideProgress(true);
    this.hideTaskList();
  }

  public onShowingHiddenStartUp(action: string): void {
    if (action === 'HideStartUp') {
      this.hideProgress(true);
      this.hideTaskList();
      Global.STARTUP_TASKS_VISIBILITY = false;
    }
  }

  private loadData(defaultOpen?: boolean): void {
    if (this.sessionService.role.IsFullAdmin && Global.STARTUP_TASKS_VISIBILITY) {
      // this.dataService.Startuptasks_GetStartupTasksWithStatus().subscribe((tasks: IStartupTaskCompanyView[]) => {
      //   Global.STARTUP_TASKS = this.startupTasks = tasks.sort(
      //     (first: IStartupTaskCompanyView, second: IStartupTaskCompanyView) => first.SortOrder - second.SortOrder
      //   );
      //   if (this.startupTasks.filter((data: IStartupTaskCompanyView) => data.StatusId === 0).length > 0) {
      //     this.calculateStartupProgress(defaultOpen);
      //   } else {
      //     this.hideProgress();
      //   }

      //   this.settingService.updateSettingsForEmployeeCompany(this.startupTasks);
      // });
      if (Global.STARTUP_TASKS) {
        this.startupTasks = Global.STARTUP_TASKS = Global.STARTUP_TASKS.sort(
          (first: IStartupTaskCompanyView, second: IStartupTaskCompanyView) => first.SortOrder - second.SortOrder
        );
        if (this.startupTasks.filter((data: IStartupTaskCompanyView) => data.StatusId === 0).length > 0) {
          this.calculateStartupProgress(defaultOpen);
        } else {
          this.hideProgress();
        }

        this.settingService.updateSettingsForEmployeeCompany(this.startupTasks);
      }
    }
  }

  private onUpdateTaskCompleted(updatedTask: IStartupTaskCompanyView): void {
    const task: IStartupTaskCompanyView = this.startupTasks
      ? this.startupTasks.find((ta: IStartupTaskCompanyView) => ta.Id === updatedTask.Id)
      : undefined;
    if (task && updatedTask) {
      task.StatusId = updatedTask.StatusId;
    }

    this.settingService.updateSettingsForEmployeeCompany(this.startupTasks);
    this.staticDataService.changeDefaultRoute();

    this.calculateStartupProgress();
  }

  private navigateToExternalLink(link: string): void {
    if (!this.sessionService.browser.iOSMobileDevice) {
      window.open(link);
    }
  }

  private resetTask(task: IStartupTaskCompanyView): void {
    this.dataService.Startuptasks_ResetTask(task.TaskId).subscribe((startUpTask: IStartupTaskCompanyView) => {
      this.onUpdateTaskCompleted(startUpTask);
    });
  }

  private showProgressContainer(): void {
    if (this.sessionService.role.IsFullAdmin && Global.STARTUP_TASKS_VISIBILITY && Global.STARTUP_TASKS) {
      this.startUpIndicatorIsActive = true;
    }
  }

  private showProgress(): void {
    if (this.isAllTasksDone) {
      this.isCompleteTask = true;
    }
  }

  private hideProgress(fadeOut?: boolean): void {
    if (fadeOut && fadeOut === true) {
      this.startUpFadeout = true;
    } else {
      this.startUpIndicatorIsActive = false;
    }
  }

  private showTaskList(isLoadData: boolean = false): void {
    if (!this.startUpStepsIsActive) {
      this.startUpStepsIsActive = true;
      this.startUpStepsMaxHeight = window.innerHeight - 250;
      this.updateTaskListMaxHeight();
      this.isTaskListShown = true;
      if (isLoadData) {
        this.loadData();
      }
    }
  }

  private hideTaskList(): boolean {
    if (this.startUpStepsIsActive) {
      this.startUpStepsIsActive = false;
      this.startUpStepsMaxHeight = 0;
      this.isTaskListShown = false;
      return true;
    }

    return false;
  }

  private updateTaskListMaxHeight(): void {
    this.startUpStepsMaxHeight = window.innerHeight - 250;
  }

  private calculateStartupProgress(defaultOpen?: boolean): void {
    if (this.startupTasks && this.startupTasks.length > 0) {
      const todoTasks: IStartupTaskCompanyView[] = this.startupTasks.filter(
        (data: IStartupTaskCompanyView) => data.StatusId === 0
      );
      const completeTasks: IStartupTaskCompanyView[] = this.startupTasks.filter(
        (data: IStartupTaskCompanyView) => data.StatusId === 1
      );
      this.startUpProgress = parseInt(
        ((completeTasks.length * 100) / (todoTasks.length + completeTasks.length)).toString(),
        10
      );

      this.showProgressContainer();
      if (window.innerWidth >= 500 && defaultOpen && defaultOpen === true) {
        setTimeout(() => {
          this.showTaskList();
        }, 500);
      }

      if (defaultOpen && defaultOpen === true) {
        setTimeout(() => {
          this.showProgress();
        }, 1000);
      } else {
        this.showProgress();
      }
    } else {
      this.hideProgress();
      this.hideTaskList();
    }
  }
}
