import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmployeeService } from 'src/app/services/employee.service';
import { environment } from 'src/environments/environment';
import { FilterDateTimeGrid } from '../../../../common/filter-date-time-grid';
import { Global } from '../../../../common/global';
import { ImportTypesEnum } from '../../../../model/enum';
import { ISalaryRecord, ISalaryRecordPersistenceType, ISalaryRecordView, ISalaryTypeView, ISimpleKeyValuePair, IUnitType, IUserEmployment } from '../../../../services/api/api-model';
import { DataService } from '../../../../services/api/data.service';
import { StaticDataService } from '../../../../services/api/static-data.service';
import { SessionService } from '../../../../services/session/session.service';
import { SettingService } from '../../../../services/setting.service';
import { PayrollCommon } from '../payroll-common';
import { SalaryRecordItemHelper } from './salary-record-item-helper';
var PayrollDataGridComponent = /** @class */ (function () {
    function PayrollDataGridComponent(employeeService, translateService, dataService, settingService, sessionService, staticDataService, renderer) {
        this.employeeService = employeeService;
        this.translateService = translateService;
        this.dataService = dataService;
        this.settingService = settingService;
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.renderer = renderer;
        this.triggerGridComponentUpdate = false;
        this.recalculateValue = false;
        this.recalculateChange = new EventEmitter();
        this.currentIsHidePreviewGrid = false;
        this.isHidePreviewGridChange = new EventEmitter();
        this.update = new EventEmitter();
        this.IsEnableDateAdjustment = false;
        this.DimensionXValue = [];
        this.salaryRecords = [];
        this.salaryTypeFilterFields = ['Name', 'Description'];
        this.filterClear = false;
        // private enableDateAdjustmentPreferenceKey: string = "PayrollData.EnableDateAdjustments";
        this.registerRecalculateClickedEvent = false;
        this.ngUnsubscribe = new Subject();
        this.negativeDefaultTranslationText = {};
        this.negativeDefaultMoreDialogModel = {};
        this.keyPress = { 17: false, 18: false, 107: false, 109: false };
        this.isUseHotKey = false;
        this.gridData = this.salaryRecords;
    }
    Object.defineProperty(PayrollDataGridComponent.prototype, "userEmployment", {
        get: function () {
            return this.userEmploymentValue;
        },
        set: function (value) {
            if ((value && this.userEmploymentValue && value.Id !== this.userEmploymentValue.Id) || !this.userEmploymentValue) {
                this.userEmploymentValue = value;
                if (value && value.Id) {
                    this.filter = null;
                    this.filterClear = true;
                    this.loadSalaryTypes();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "recalculate", {
        get: function () {
            return this.recalculateValue;
        },
        set: function (value) {
            this.recalculateValue = value;
            this.recalculateChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "isHidePreviewGrid", {
        get: function () {
            return this.currentIsHidePreviewGrid;
        },
        set: function (value) {
            if (this.currentIsHidePreviewGrid !== value) {
                this.currentIsHidePreviewGrid = value;
                this.isHidePreviewGridChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "NumericOptions", {
        get: function () {
            if (this.numericOptions) {
                return this.numericOptions;
            }
            return (this.numericOptions = {
                format: 'n2',
                decimals: 2,
                step: 0,
                min: null,
                spinners: false
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "isFullAdmin", {
        get: function () {
            return this.sessionService.role.IsFullAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "IconisHidePreviewGrid", {
        get: function () {
            if (!this.isHidePreviewGrid) {
                return 'angle-double-right';
            }
            return 'angle-double-left';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "isEnableDimensionPreference", {
        get: function () {
            var pref = this.sessionService.getCompanyPreference('Dimension.UseDimensionValueEntities');
            return pref.Value === 'true' ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    PayrollDataGridComponent.prototype.getIsEnableDateAdjustment = function () {
        var enableDateAdjustment = PayrollCommon.getLocalStorageEnableDatePreferenceSetting();
        if (!enableDateAdjustment) {
            enableDateAdjustment = PayrollCommon.createLocalStorageEnableDatePreferenceSetting(false);
        }
        var value = enableDateAdjustment.Value.toLowerCase();
        return value === 'true';
    };
    Object.defineProperty(PayrollDataGridComponent.prototype, "Dimension1Preference", {
        get: function () {
            return Global.COMPANY_PREFERENCES
                ? Global.COMPANY_PREFERENCES.find(function (pref) { return pref.Key === 'Dimensions.Dimension1Name'; })
                : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "Dimension2Preference", {
        get: function () {
            return Global.COMPANY_PREFERENCES
                ? Global.COMPANY_PREFERENCES.find(function (pref) { return pref.Key === 'Dimensions.Dimension2Name'; })
                : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "Dimension3Preference", {
        get: function () {
            return Global.COMPANY_PREFERENCES
                ? Global.COMPANY_PREFERENCES.find(function (pref) { return pref.Key === 'Dimensions.Dimension3Name'; })
                : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "AmountFlipMessage", {
        get: function () {
            return this.isAutoFlipAmountsSetting
                ? this.negativeDefaultTranslationText['EnableSetting']
                : this.negativeDefaultTranslationText['DisableSetting'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "AmountFlipButtons", {
        get: function () {
            return !this.isAutoFlipAmountsSetting
                ? [this.negativeDefaultTranslationText['MoreInfo']]
                : [
                    this.negativeDefaultTranslationText['MoreInfo'],
                    this.negativeDefaultTranslationText['UsePositiveAmountThisTime']
                ];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "isAutoFlipAmountsSetting", {
        get: function () {
            return PayrollCommon.isAutoFlipAmountsSetting();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "isNonDateValue", {
        get: function () {
            if (this.salaryRecords && this.salaryRecords.length > 0) {
                return !this.salaryRecords.some(function (item) { return !!item.StartDate || !!item.EndDate; });
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollDataGridComponent.prototype, "isHaveDateValuesInDataSource", {
        get: function () {
            if (this.salaryRecords &&
                this.salaryRecords.length > 0 &&
                (this.salaryRecords.some(function (model) { return model.IsActive && !!model.StartDate; }) ||
                    this.salaryRecords.some(function (model) { return model.IsActive && !!model.EndDate; }))) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    PayrollDataGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.disableGridToolbar = true;
        this.unitTypes = Global.UNIT_TYPES;
        this.salaryRecordItemHelper = undefined;
        this.translateText();
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (e) {
            _this.translateText();
        });
        this.staticDataService.SalaryRecordPersistenceType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.persistanceTypes = data;
        });
        this.IsEnableDateAdjustment = this.getIsEnableDateAdjustment();
    };
    PayrollDataGridComponent.prototype.ngOnDestroy = function () {
        //document.removeEventListener("keydown", this.onKeyDown);
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    PayrollDataGridComponent.prototype.translateText = function () {
        var _this = this;
        this.translateService
            .get([
            'EmploymentPayrollData.NegativeDefaultMessage_EnableSetting',
            'EmploymentPayrollData.NegativeDefaultMessage_DisableSetting',
            'EmploymentPayrollData.UsePositiveAmountThisTime',
            'EmploymentPayrollData.MoreInfo',
            'EmploymentPayrollData.UnitsAutoConfigured',
            'EmploymentPayrollData.HandlingOfSygedagpenge'
        ])
            .subscribe(function (translations) {
            _this.negativeDefaultTranslationText['EnableSetting'] =
                translations['EmploymentPayrollData.NegativeDefaultMessage_EnableSetting'];
            _this.negativeDefaultTranslationText['DisableSetting'] =
                translations['EmploymentPayrollData.NegativeDefaultMessage_DisableSetting'];
            _this.negativeDefaultTranslationText['UsePositiveAmountThisTime'] =
                translations['EmploymentPayrollData.UsePositiveAmountThisTime'];
            _this.negativeDefaultTranslationText['MoreInfo'] = translations['EmploymentPayrollData.MoreInfo'];
            _this.negativeDefaultTranslationText['UnitsAutoConfigured'] =
                translations['EmploymentPayrollData.UnitsAutoConfigured'];
            _this.negativeDefaultTranslationText['HandlingOfSygedagpenge'] =
                translations['EmploymentPayrollData.HandlingOfSygedagpenge'];
        });
    };
    PayrollDataGridComponent.prototype.runEnableDateAdjustmentsRules = function () {
        var enableDateAdjustment = this.IsEnableDateAdjustment;
        this.showDateColumns(enableDateAdjustment);
    };
    PayrollDataGridComponent.prototype.showDateColumns = function (show) {
        this.isHideDateColumns = !show;
        if (this.isHaveDateValuesInDataSource) {
            this.isHideDateColumns = false;
        }
        this.IsEnableDateAdjustment = !this.isHideDateColumns;
    };
    PayrollDataGridComponent.prototype.enableDateAdjustmentSetting = function (enable) {
        this.updateEnableDatePreferenceSetting(enable);
    };
    PayrollDataGridComponent.prototype.updateEnableDatePreferenceSetting = function (enableSetting) {
        var _this = this;
        var companyPreference = {
            Key: PayrollCommon.enableDateAdjustmentPreferenceKey,
            Value: enableSetting ? 'true' : 'false'
        };
        this.dataService.Preferences_SetCompanyPreference(companyPreference).subscribe(function (enableDatePref) {
            _this.updateLocalStorageEnableDatePreferenceSetting(enableDatePref);
            _this.IsEnableDateAdjustment = _this.getIsEnableDateAdjustment();
            _this.showDateColumns(enableSetting);
            _this.triggerGridComponentUpdate = true;
        }, function (error) {
            _this.showDateColumns(!enableSetting);
        });
    };
    PayrollDataGridComponent.prototype.updateLocalStorageEnableDatePreferenceSetting = function (enableDatePref) {
        var preferences = JSON.parse(JSON.stringify(Global.COMPANY_PREFERENCES));
        var enableDateAdjustment = preferences.filter(function (pref) { return pref.Key === PayrollCommon.enableDateAdjustmentPreferenceKey; })[0];
        if (enableDateAdjustment) {
            enableDateAdjustment.Value = enableDatePref.Value;
            Global.COMPANY_PREFERENCES = preferences;
        }
        return enableDateAdjustment;
    };
    PayrollDataGridComponent.prototype.loadSalaryTypes = function () {
        var _this = this;
        this.salaryRecords = undefined;
        this.salaryTypes = undefined;
        this.disableGridToolbar = true;
        this.dataService.SalaryTypes_GetSalaryTypeViews().subscribe(function (salaryTypes) {
            if (_this.userEmploymentValue.IncomeTypeId === 5) {
                _this.salaryTypes = salaryTypes.filter(function (salaryType) { return salaryType.IsVisible && salaryType.RelatesToIncomeType5 !== false; });
            }
            else {
                _this.salaryTypes = salaryTypes.filter(function (salaryType) { return salaryType.IsVisible && !salaryType.RelatesToIncomeType5; });
            }
            _this.loadSalaryRecords();
        });
        this.updateDimensionValue();
    };
    PayrollDataGridComponent.prototype.updateDimensionValue = function () {
        if (this.Dimension1Preference && this.Dimension1Preference.Value) {
            this.updateDimensionXValues(1);
        }
        if (this.Dimension2Preference && this.Dimension2Preference.Value) {
            this.updateDimensionXValues(2);
        }
        if (this.Dimension3Preference && this.Dimension3Preference.Value) {
            this.updateDimensionXValues(3);
        }
    };
    PayrollDataGridComponent.prototype.updateDimensionXValues = function (index) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var employment_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isEnableDimensionPreference) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.employeeService.employment.subscribe(function (data) {
                                employment_1 = data;
                            })];
                    case 1:
                        _a.sent();
                        this.dataService.DimensionValues_GetDimensionValues(index).subscribe(function (data) {
                            _this.DimensionXValue[index - 1] = data.filter(function (value) {
                                if (!value.LimitToDepartmentId && !value.LimitToTemplateId) {
                                    return true;
                                }
                                else if (!value.LimitToDepartmentId) {
                                    if (employment_1.BasedOnTemplateId === value.LimitToTemplateId) {
                                        return true;
                                    }
                                    return false;
                                }
                                else if (!value.LimitToTemplateId) {
                                    if (employment_1.DepartmentId === value.LimitToDepartmentId) {
                                        return true;
                                    }
                                    return false;
                                }
                                else if (value.LimitToDepartmentId && value.LimitToTemplateId) {
                                    if (employment_1.DepartmentId === value.LimitToDepartmentId &&
                                        employment_1.BasedOnTemplateId === value.LimitToTemplateId) {
                                        return true;
                                    }
                                    return false;
                                }
                                return false;
                            });
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        this.dataService.Miscellaneous_GetDimensionValues(index).subscribe(function (data) {
                            _this.DimensionXValue[index - 1] = data.filter(function (value) { return value; });
                        });
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PayrollDataGridComponent.prototype.loadSalaryRecords = function () {
        var _this = this;
        if (!this.userEmployment) {
            return;
        }
        this.dataService
            .SalaryRecords_GetSalaryRecordsByEmployment(this.userEmployment.Id)
            .subscribe(function (salaryRecords) {
            _this.salaryRecords = salaryRecords.map(function (data) {
                return PayrollCommon.assignEditableFields(data, _this.salaryTypes);
            });
            _this.gridData = filterBy(_this.salaryRecords, _this.filter);
            _this.runEnableDateAdjustmentsRules();
            _this.disableGridToolbar = false;
        });
    };
    PayrollDataGridComponent.prototype.onNegativeDefaultMoreInfoClick = function (dataItem, fieldName) {
        this.negativeDefaultMoreDialogModel = {
            setting: this.isAutoFlipAmountsSetting,
            fieldName: fieldName,
            dataItem: dataItem
        };
        this.showNegativeDefaultMoreInfoDialog = true;
    };
    PayrollDataGridComponent.prototype.onMoreInfoUsePositiveAmount = function (args) {
        this.usePositiveAmount(args.dataItem, args.fieldName);
    };
    PayrollDataGridComponent.prototype.usePositiveAmount = function (dataItem, fieldName) {
        this.salaryRecordItemHelper.usePositiveAmount(fieldName);
        this.saveChanges(dataItem);
        this.triggerGridComponentUpdate = true;
    };
    PayrollDataGridComponent.prototype.autoCorrect = function (args) {
        this.salaryRecordItemHelper.autoCorrect(args.fieldName);
        this.saveChanges(args.dataItem);
        this.triggerGridComponentUpdate = true;
    };
    PayrollDataGridComponent.prototype.removeHandler = function (e) {
        this.gridRemoveRowArgs = e;
        this.showDeleteItemConfirmation = true;
        this.update.emit();
    };
    PayrollDataGridComponent.prototype.onRemoveRowConfirmed = function (action) {
        if (action === 'Delete' && this.gridRemoveRowArgs) {
            if (this.gridRemoveRowArgs.dataItem.Id > 0) {
                this.deleteSalaryRecord(this.gridRemoveRowArgs.dataItem.Id);
            }
            else {
                var indexOfRemovedItem = this.salaryRecords.indexOf(this.gridRemoveRowArgs.dataItem);
                this.salaryRecords.splice(indexOfRemovedItem, 1);
                this.gridRemoveRowArgs = undefined;
                this.triggerGridComponentUpdate = true;
            }
        }
    };
    PayrollDataGridComponent.prototype.deleteSalaryRecord = function (salaryRecordId) {
        var _this = this;
        if (salaryRecordId && salaryRecordId > 0) {
            this.dataService.SalaryRecords_DeleteSalaryRecord(salaryRecordId).subscribe(function (data) {
                _this.recalculate = true;
                var indexOfRemovedItem = _this.salaryRecords.indexOf(_this.gridRemoveRowArgs.dataItem);
                _this.salaryRecords.splice(indexOfRemovedItem, 1);
                _this.triggerGridComponentUpdate = true;
                if (_this.salaryRecords.length > 0) {
                    var index = indexOfRemovedItem > _this.salaryRecords.length - 1 ? 0 : indexOfRemovedItem;
                    _this.kendoGrid.editCell(index, _this.kendoGrid.columns.length - (_this.isHideDateColumns ? 2 : 0), _this.createFormGroup(_this.salaryRecords[index]));
                }
                _this.gridRemoveRowArgs = undefined;
                _this.gridData = filterBy(_this.salaryRecords, _this.filter);
            }, function (error) {
                _this.gridRemoveRowArgs = undefined;
                _this.loadSalaryRecords();
            });
        }
    };
    PayrollDataGridComponent.prototype.addHandler = function (dataItem) {
        var _this = this;
        if (this.sessionService.isSubmitting) {
            return;
        }
        this.update.emit();
        this.salaryRecordItemHelper = new SalaryRecordItemHelper(dataItem, this.renderer, this.persistanceTypes, this.salaryTypes, this.settingService);
        this.salaryRecordItemHelper.updateSalaryType(this.salaryTypes[0]);
        // this.assignEditableFields(dataItem);
        PayrollCommon.assignEditableFields(dataItem, this.salaryTypes);
        var salaryRecord = PayrollCommon.createSalaryRecord(dataItem, this.userEmployment.Id);
        this.dataService.SalaryRecords_CreateSalaryRecord(salaryRecord).subscribe(function (data) {
            _this.gridData = filterBy(_this.salaryRecords, _this.filter);
            dataItem.Id = data.Id;
            // Object.assign(dataItem, data);
            _this.selectedId = data.Id;
            _this.recalculate = true;
            if (_this.isUseHotKey) {
                _this.kendoGrid.editCell(_this.salaryRecords.length - 1, 0, _this.createFormGroup(dataItem));
                _this.isUseHotKey = false;
            }
        }, function (error) {
            _this.loadSalaryRecords();
        });
    };
    // private createSalaryRecord(salaryRecordView: any): ISalaryRecord {
    //     let endDateConverter: DateTimeConvert = new DateTimeConvert(salaryRecordView.EndDate);
    //     let endDate: Date = endDateConverter.getUTCDate();
    //     let startDateConverter: DateTimeConvert = new DateTimeConvert(salaryRecordView.StartDate);
    //     let startDate: Date = startDateConverter.getUTCDate();
    //     let salaryRecord: ISalaryRecord = {
    //         Id: salaryRecordView.Id ? salaryRecordView.Id : salaryRecordView.id,
    //         Amount: salaryRecordView.Amount,
    //         AmountPerUnit: salaryRecordView.AmountPerUnit,
    //         BaseAmount: salaryRecordView.BaseAmount,
    //         Description: salaryRecordView.Description,
    //         EndDate: endDate ? endDate : salaryRecordView.EndDate,
    //         PersistenceTypeId: salaryRecordView.PersistenceTypeId,
    //         SalaryTypeId: salaryRecordView.SalaryTypeId,
    //         UnitTypeId: salaryRecordView.UnitTypeId,
    //         Dimension1Value: salaryRecordView.Dimension1Value,
    //         Dimension2Value: salaryRecordView.Dimension2Value,
    //         Dimension3Value: salaryRecordView.Dimension3Value,
    //         StartDate: startDate ? startDate : salaryRecordView.StartDate,
    //         Units: salaryRecordView.Units,
    //         UserEmploymentId: this.userEmployment.Id,
    //         IsActive: true
    //     };
    //     let unitTypes: any = Global.UNIT_TYPES.filter((tet: any) => {
    //         return tet.Id === salaryRecordView.UnitTypeId;
    //     });
    //     if (unitTypes) {
    //         salaryRecord.UnitType = unitTypes[0];
    //     }
    //     return salaryRecord;
    // }
    PayrollDataGridComponent.prototype.saveChanges = function (args, field) {
        var _this = this;
        var column = args.column, formGroup = args.formGroup;
        var dataItem = args.dataItem;
        dataItem = dataItem ? dataItem : args;
        var modifiedField = {};
        if (column) {
            field = column.field;
        }
        if (field) {
            modifiedField[field] = dataItem[field];
        }
        this.salaryRecordItemHelper = new SalaryRecordItemHelper(dataItem, this.renderer, this.persistanceTypes, this.salaryTypes, this.settingService);
        var valid = this.salaryRecordItemHelper.validate(modifiedField);
        if (!valid) {
            dataItem[field] = formGroup.value[field];
            return;
        }
        var previousDataItem = JSON.parse(JSON.stringify(dataItem));
        this.salaryRecordItemHelper.updateRelavantFields(modifiedField);
        // this.assignEditableFields(dataItem);
        PayrollCommon.assignEditableFields(dataItem, this.salaryTypes);
        if (field &&
            (modifiedField[field] === 'AmountPerUnit' || modifiedField[field] === 'Amount') &&
            PayrollCommon.checkAmountAutoFlip(previousDataItem, dataItem)) {
            // Show in-cell popup message to notify Amount has been auto-flip.
            this.salaryRecordItemHelper.showInCellPopupMessage(dataItem, 'Amount', column ? column.field : undefined);
        }
        if (dataItem.SalaryTypeId) {
            var currentSalaryTypedSelect = this.salaryTypes.find(function (model) { return dataItem.SalaryTypeId === model.SalaryTypeId; });
            if (currentSalaryTypedSelect && currentSalaryTypedSelect.Identifier === 'IllnessCompensation') {
                if ((dataItem.Units || dataItem.Amount) && !dataItem.BaseAmount) {
                    this.salaryRecordItemHelper.showInCellPopupMessage(dataItem, 'BaseAmount', column ? column.field : undefined);
                }
            }
        }
        setTimeout(function () {
            _this.triggerGridComponentUpdate = true;
        }, 5000);
        var salaryRecord = PayrollCommon.createSalaryRecord(dataItem, this.userEmployment.Id);
        this.updateSalaryRecord(salaryRecord, dataItem);
    };
    PayrollDataGridComponent.prototype.onCellCloseEvent = function (event) {
        if (event && event.dataItem && event.dataItem.UnSaved && !event.sender.isEditing()) {
            this.saveChanges({ dataItem: event.dataItem });
        }
    };
    // private assignEditableFields(item: any): any {
    //     let salaryType: ISalaryTypeView = this.salaryTypes && item.SalaryTypeId ? this.salaryTypes.find((type: any) => { return type.SalaryTypeId === item.SalaryTypeId; }) : undefined;
    //     let unitType: IUnitType = item.UnitTypeId ? Global.UNIT_TYPES.find((type: any) => { return type.Id === item.UnitTypeId; }) : undefined;
    //     item.AllowEditUnitType = salaryType && salaryType.AllowEditUnitType;
    //     item.UnitTypeClass = item.AllowEditUnitType ? "" : "grid-disable-cell";
    //     item.AllowEditBaseAmount = salaryType && salaryType.AllowEditBaseAmount;
    //     item.tBaseAmountClass = item.AllowEditBaseAmount ? "" : "grid-disable-cell";
    //     item.AllowEditAmountPerUnit = unitType && unitType.Id && unitType.Id !== UnitTypeEnum.Percent;
    //     item.AmountPerUnitClass = item.AllowEditAmountPerUnit ? "" : "grid-disable-cell";
    //     item.AllowEditUnits = unitType && unitType.Id;
    //     item.UnitsClass = item.AllowEditUnits ? "" : "grid-disable-cell";
    //     return item;
    // }
    // private checkAmountAutoFlip(previous: ISalaryRecord, dataItem: ISalaryRecord): boolean {
    //     return (!previous.AmountPerUnit || previous.AmountPerUnit > 0 || !previous.Amount || previous.Amount > 0)
    //         && dataItem.Amount < 0 && dataItem.Amount !== previous.Amount;
    // }
    PayrollDataGridComponent.prototype.updateSalaryRecord = function (salaryRecord, dataItem) {
        var _this = this;
        this.update.emit();
        this.dataService.SalaryRecords_UpdateSalaryRecord(salaryRecord).subscribe(function (data) {
            if (dataItem &&
                dataItem.SalaryTypeId === data.SalaryTypeId &&
                dataItem.Units &&
                data.Units &&
                dataItem.Units !== data.Units) {
                // Units is updated by Server.
                dataItem.Amount = data.Amount;
                dataItem.Units = data.Units;
                dataItem.SalaryStatementId = data.SalaryStatementId;
                _this.salaryRecordItemHelper.showInCellPopupMessage(dataItem, 'Units');
                _this.triggerGridComponentUpdate = true;
            }
            _this.recalculate = true;
        }, function (error) {
            _this.loadSalaryRecords();
        });
    };
    PayrollDataGridComponent.prototype.onGridAction = function (action) {
        var _this = this;
        if (this.sessionService.isSubmitting || this.sessionService.isGetting) {
            return;
        }
        switch (action) {
            case 'recalculate':
                setTimeout(function () {
                    _this.recalculate = true;
                });
                this.update.emit();
                break;
            case 'enableDate':
                if (!this.IsEnableDateAdjustment) {
                    this.showDateSettingConfirmation = true;
                }
                break;
            case 'disableDate':
                if (this.IsEnableDateAdjustment) {
                    this.enableDateAdjustmentSetting(false);
                }
                break;
            case 'correctBalance':
                this.settingService.navigationParameters['dataImportSelectedOption'] = ImportTypesEnum.Initial_Balances;
                this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
                break;
            case 'onHidePreviewGrid':
                this.isHidePreviewGrid = !this.isHidePreviewGrid;
                break;
            default:
                break;
        }
    };
    PayrollDataGridComponent.prototype.onConfirmDateAdjustmentSetting = function (action) {
        if (action === 'Enable') {
            this.enableDateAdjustmentSetting(true);
        }
    };
    PayrollDataGridComponent.prototype.onGridButtonClicked = function (args) {
        switch (args.buttonAction) {
            case this.negativeDefaultTranslationText['UsePositiveAmountThisTime']:
                this.usePositiveAmount(args.dataItem, args.field);
                break;
            case this.negativeDefaultTranslationText['MoreInfo']:
                this.onNegativeDefaultMoreInfoClick(args.dataItem, args.field);
                break;
            default:
                break;
        }
    };
    PayrollDataGridComponent.prototype.onSelectedItemChanges = function (event) {
        this.selectedItem = event;
        this.updateDimensionValue();
    };
    PayrollDataGridComponent.prototype.onKeyDown = function (event) {
        if (!this.IsReadOnly) {
            if (event.keyCode in this.keyPress) {
                this.keyPress[event.keyCode] = true;
                if (this.keyPress[17] && this.keyPress[18] && this.keyPress[109]) {
                    if (this.selectedItem) {
                        var deleteArgs = {};
                        deleteArgs.dataItem = this.selectedItem;
                        this.removeHandler(deleteArgs);
                        this.setKeypress();
                        this.triggerGridComponentUpdate = true;
                    }
                }
            }
            else {
                this.setKeypress();
            }
        }
    };
    PayrollDataGridComponent.prototype.setKeypress = function () {
        this.keyPress[17] = false;
        this.keyPress[18] = false;
        this.keyPress[107] = false;
        this.keyPress[109] = false;
    };
    PayrollDataGridComponent.prototype.createFormGroup = function (dataItem) {
        return new FormGroup({
            Id: new FormControl(dataItem ? dataItem.Id : undefined),
            Amount: new FormControl(dataItem ? dataItem.Amount : undefined),
            AmountPerUnit: new FormControl(dataItem ? dataItem.AmountPerUnit : undefined),
            BaseAmount: new FormControl(dataItem ? dataItem.BaseAmount : undefined),
            Description: new FormControl(dataItem ? dataItem.Description : undefined),
            EndDate: new FormControl(dataItem ? dataItem.EndDate : undefined),
            PersistenceTypeId: new FormControl(dataItem ? dataItem.PersistenceTypeId : undefined),
            SalaryTypeId: new FormControl(dataItem ? dataItem.SalaryTypeId : undefined),
            UnitTypeId: new FormControl(dataItem ? dataItem.UnitTypeId : undefined),
            Dimension1Value: new FormControl(dataItem ? dataItem.Dimension1Value : undefined),
            Dimension2Value: new FormControl(dataItem ? dataItem.Dimension2Value : undefined),
            Dimension3Value: new FormControl(dataItem ? dataItem.Dimension3Value : undefined),
            StartDate: new FormControl(dataItem ? dataItem.StartDate : undefined),
            Units: new FormControl(dataItem ? dataItem.Units : undefined),
            UserEmploymentId: new FormControl(dataItem ? dataItem.UserEmploymentId : undefined),
            IsActive: new FormControl(dataItem ? dataItem.IsActive : undefined),
            SalaryStatementId: new FormControl(dataItem ? dataItem.SalaryStatementId : undefined)
        });
    };
    PayrollDataGridComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        if (filter && filter.filters && filter.filters.length > 0) {
            var filterDateTimeGrid = new FilterDateTimeGrid();
            var filtersDate = [];
            // Using FilterDateTimeGrid to filter date before filter another field
            this.filter = filterDateTimeGrid.convertFilterOperator(filter, filtersDate, 'StartDate', 'EndDate');
            this.gridData = filterDateTimeGrid.doFilterDate(this.salaryRecords, filtersDate, 'StartDate', 'EndDate');
            this.gridData = filterBy(this.gridData, this.filter);
            if (filtersDate.length > 0) {
                [].push.apply(this.filter.filters, filtersDate);
            }
        }
        else {
            this.gridData = this.salaryRecords;
        }
    };
    return PayrollDataGridComponent;
}());
export { PayrollDataGridComponent };
