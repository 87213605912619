import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IExternalSystem, IIntegration, IIntegrationStatus } from 'src/app/services/api/api-model';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { IntegrationsService } from '../../integrations.service';

@Component({
  selector: 'app-external-integrations',
  templateUrl: './external-integrations.component.html',
  styleUrls: ['./external-integrations.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExternalIntegrationsComponent implements OnDestroy {
  public isShowingDeleteMessage = false;
  public isShowingChangeIemMessage = false;
  public integrationIdDelete: number;

  public extenalSystem: IExternalSystem[];
  public integrationModel: IIntegration[];
  public statusCode: IIntegrationStatus[] = [];

  constructor(public integrationsService: IntegrationsService, private staticDataService: StaticDataService) {
    this.integrationsService.extenalSystem
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((externalSystems: IExternalSystem[]) => {
        this.extenalSystem = externalSystems;
      });

    this.integrationsService.integrations.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IIntegration[]) => {
      this.integrationModel = data;
    });

    this.integrationsService.statusCode.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IIntegrationStatus[]) => {
      this.statusCode = data;
    });
  }

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public getStatusMessageColor(integrationstatusmessageId: number): string {
    if (integrationstatusmessageId && this.statusCode && this.statusCode.length > 0) {
      return this.statusCode.find((model: IIntegrationStatus) => model.Id === integrationstatusmessageId).Key;
    }

    return '';
  }

  public getStatusMessage(integrationstatusmessageId: number): string {
    if (integrationstatusmessageId && this.statusCode && this.statusCode.length > 0) {
      return this.statusCode.find((model: IIntegrationStatus) => model.Id === integrationstatusmessageId).Description;
    }

    return '';
  }

  public onDeleteDialog(integrationItem?: IIntegration): void {
    if (integrationItem && integrationItem.Id) {
      this.integrationIdDelete = integrationItem.Id;
      this.isShowingDeleteMessage = true;
    }
  }

  public onDeleteWarningDialogAction(action: string): void {
    if (action === 'Delete') {
      if (this.integrationIdDelete && this.integrationIdDelete !== -1) {
        this.integrationsService.deleteIntegration(this.integrationIdDelete).subscribe((): void => {
          this.onCloseDialogCreate();
        });
      }
    } else {
      this.onCloseDialogCreate();
    }
    this.integrationIdDelete = undefined;
  }

  public onCreateDialogCreate(action: string): void {
    if (action === 'Create' && this.integrationsService.globalExtenalSystem) {
      this.integrationsService.createIntegration().subscribe(() => {
        this.staticDataService.loadExternalSystemConfiguration();
        this.staticDataService.loadExternalSystemCredential();
        this.onCloseDialogCreate();
      });
    }
  }

  private onCloseDialogCreate(): void {
    if (this.extenalSystem && this.extenalSystem.length > 0) {
      this.integrationsService.currentExtenalSystemId = this.extenalSystem[0].Id;
      this.integrationsService.globalExtenalSystem = this.extenalSystem[0];
    }
    this.integrationsService.loadIntegrationModel();
    this.integrationsService.addNewsDialogVisible = false;
  }

  public addNewvisibleChange(visible: boolean) {
    if (visible) {
      if (this.extenalSystem && this.extenalSystem.length > 0) {
        this.integrationsService.currentExtenalSystemId = this.extenalSystem[0].Id;
        this.integrationsService.globalExtenalSystem = this.extenalSystem[0];
      }
    }
  }

  public checkIntegrationSupportUrl(model: IIntegration): boolean {
    if (this.extenalSystem) {
      const tempexternalsystem: IExternalSystem = this.extenalSystem.find(
        (z: IExternalSystem) => z.Id === model.ExternalSystemId
      );
      if (tempexternalsystem && tempexternalsystem.SupportUrl) {
        return true;
      }
    }

    return false;
  }

  public onBaseIntegrationCreateComboboxChanged(): void {
    if (this.extenalSystem && this.integrationsService.currentExtenalSystemId) {
      this.integrationsService.globalExtenalSystem = this.extenalSystem.find(
        (exs: IExternalSystem) => exs.Id === this.integrationsService.currentExtenalSystemId
      );
    }
  }

  public SelectIntegrationListView(integrationId: number): void {
    if (this.integrationsService.selectedId !== integrationId) {
      if (this.integrationsService.editMode && this.integrationsService.tabDirty) {
        this.integrationsService.nextIntegrationIdChange = integrationId;
        this.isShowingChangeIemMessage = true;
        return;
      }

      this.integrationsService.resetForm();
      this.integrationsService.selectedId = integrationId;
    }
  }

  public applyUrl(model: IIntegration): void {
    if (this.extenalSystem) {
      const tempexternalsystem: IExternalSystem = this.extenalSystem.find(
        (z: IExternalSystem) => z.Id === model.ExternalSystemId
      );
      if (tempexternalsystem && tempexternalsystem.SupportUrl) {
        if (tempexternalsystem.SupportUrl.includes('http') === false) {
          tempexternalsystem.SupportUrl = 'http://' + tempexternalsystem.SupportUrl;
        }

        window.open(tempexternalsystem.SupportUrl, '_blank');
      }
    }
  }

  public onChangeTab(event: string): void {
    if (event) {
      if (event === 'ContinueAndSave') {
        this.integrationsService.editMode = false;
        return;
      } else {
        this.integrationsService.resetForm();
      }

      if (this.integrationsService.nextIntegrationIdChange) {
        this.integrationsService.selectedId = this.integrationsService.nextIntegrationIdChange;
        this.integrationsService.nextIntegrationIdChange = undefined;
      }
    }
  }
}
