import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from 'src/app/common/constants';
import { Global } from 'src/app/common/global';
import { EmployeeService } from 'src/app/services/employee.service';
import { UserEmployment } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
var NewEmploymentDialogComponent = /** @class */ (function () {
    function NewEmploymentDialogComponent(sessionService, staticDataService, dataService, employeeService) {
        var _this = this;
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.dataService = dataService;
        this.employeeService = employeeService;
        this.isVisibleValue = false;
        this.isVisibleChange = new EventEmitter();
        this.templates = [];
        this.taxCardTypes = [];
        this.defaultTitle = 'Medarbejder';
        this.isHiringDateValid = true;
        this.ngUnsubscribe = new Subject();
        this.isValidate = true;
        this.viewModel = new UserEmployment();
        this.staticDataService.TaxCardType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.taxCardTypes = data); });
    }
    Object.defineProperty(NewEmploymentDialogComponent.prototype, "isVisible", {
        get: function () {
            return this.isVisibleValue;
        },
        set: function (value) {
            if (value) {
                this.getTemplates();
            }
            this.isVisibleValue = value;
            this.isVisibleChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewEmploymentDialogComponent.prototype, "isSwedishCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    NewEmploymentDialogComponent.prototype.ngOnInit = function () {
        if (this.isSwedishCompany) {
            this.defaultTitle = 'Metarbeter';
        }
    };
    NewEmploymentDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    NewEmploymentDialogComponent.prototype.onDialogAction = function (action) {
        if (action === 'Create') {
            this.onCreateNew();
        }
        else if (action === 'Close' || action === 'Ok') {
            this.isVisible = false;
        }
    };
    NewEmploymentDialogComponent.prototype.onHiringDatePickerChanged = function (args) {
        if (args) {
            this.isHiringDateValid = !args.isInvalid;
        }
    };
    NewEmploymentDialogComponent.prototype.onCreateNew = function () {
        var _this = this;
        if (!this.viewModel.Title || this.viewModel.Title.length === 0 || !this.isHiringDateValid) {
            return;
        }
        var userEmployment = this.createNewUserEmploymentObject(this.viewModel.HireDate, this.viewModel.Title, this.viewModel.BasedOnTemplateId, this.viewModel.PreferredTaxCardTypeId);
        this.employeeService.createEmployment(userEmployment).subscribe(function () {
            _this.isVisible = false;
            _this.staticDataService.loadUserEmployments();
        });
    };
    NewEmploymentDialogComponent.prototype.showNewUserEmploymentDialog = function () {
        this.viewModel.Title = this.defaultTitle;
        this.viewModel.HireDate = this.staticDataService.getCurrentdate();
        this.viewModel.PreferredTaxCardTypeId = this.taxCardTypes.length > 1 ? this.taxCardTypes[0].Id : undefined;
    };
    NewEmploymentDialogComponent.prototype.createNewUserEmploymentObject = function (hireDate, title, templateId, taxCardTypeId) {
        var newUserEmployment = new UserEmployment();
        newUserEmployment.CompanyUserId = this.currentCompanyUserId;
        newUserEmployment.Title = title;
        newUserEmployment.BasedOnTemplateId = Number(templateId);
        newUserEmployment.PreferredTaxCardTypeId = Number(taxCardTypeId);
        //newUserEmployment.VacationReduceSalary = false;
        newUserEmployment.WorksElsewhere = false;
        newUserEmployment.IsAtpTempWorker = false;
        newUserEmployment.HireDate = hireDate;
        return newUserEmployment;
    };
    NewEmploymentDialogComponent.prototype.getTemplates = function () {
        var _this = this;
        this.dataService
            .EmploymentTemplates_GetEmploymentTemplates()
            .subscribe(function (templates) {
            _this.templates = templates;
            if (_this.templates && _this.templates.length > 0) {
                _this.viewModel.BasedOnTemplateId = _this.templates[0].Id;
                _this.showNewUserEmploymentDialog();
            }
        });
    };
    NewEmploymentDialogComponent.prototype.onValidateChanged = function (value) {
        this.isValidate = !value;
    };
    return NewEmploymentDialogComponent;
}());
export { NewEmploymentDialogComponent };
