import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../common/form-component-base';
import { Global } from '../common/global';
import { StaticDataService } from '../services/api/static-data.service';
import { SettingService } from '../services/setting.service';
var LanguageSelectorDialogComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LanguageSelectorDialogComponent, _super);
    function LanguageSelectorDialogComponent(staticDataService, settingService) {
        var _this = _super.call(this) || this;
        _this.staticDataService = staticDataService;
        _this.settingService = settingService;
        _this.visibleChange = new EventEmitter();
        _this.languageChanged = new EventEmitter();
        return _this;
    }
    Object.defineProperty(LanguageSelectorDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    LanguageSelectorDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.languageId = Global.SESSION ? Global.SESSION.SignOnToken.LanguageId : undefined;
        this.staticDataService.languages.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (languages) {
            _this.languages =
                languages && languages.length
                    ? languages.filter(function (language) { return language.Id !== _this.languageId; })
                    : undefined;
        });
    };
    LanguageSelectorDialogComponent.prototype.raiseLanguageChange = function (languageId) {
        this.changeLanguageId = languageId;
        this.settingService.ReloadWarrning = true;
        this.languageChanged.emit({ languageId: this.changeLanguageId });
        this.visible = false;
    };
    return LanguageSelectorDialogComponent;
}(FormComponentBase));
export { LanguageSelectorDialogComponent };
