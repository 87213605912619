import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { ModalService } from '../../services/modal.service';
import { SessionService } from '../../services/session/session.service';
import { AccountantServiceBase } from '../accountant-service-base';
import * as i0 from "@angular/core";
import * as i1 from "../../services/api/data.service";
import * as i2 from "../../services/session/session.service";
import * as i3 from "../../services/modal.service";
import * as i4 from "../../services/api/static-data.service";
var AccountantUsersService = /** @class */ (function (_super) {
    tslib_1.__extends(AccountantUsersService, _super);
    function AccountantUsersService(api, sessionService, modalService, staticDataService) {
        var _this = _super.call(this, api, modalService, sessionService, staticDataService) || this;
        _this.api = api;
        _this.sessionService = sessionService;
        _this.modalService = modalService;
        _this.staticDataService = staticDataService;
        _this.usersSubject = new BehaviorSubject([]);
        _this.usersUpdateSubject = new BehaviorSubject([]);
        _this.allUsers = [];
        _this.EditUserList = [];
        _this.EditRoleList = [];
        _this.isDirty = false;
        _this.showSaveDataConfirmationChangeState = false;
        return _this;
    }
    Object.defineProperty(AccountantUsersService.prototype, "users", {
        get: function () {
            if (!this.usersSubject || !this.allUsers || (this.allUsers && this.allUsers.length === 0)) {
                this.loadData();
            }
            return this.usersSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantUsersService.prototype, "updateUsers", {
        get: function () {
            return this.usersUpdateSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    AccountantUsersService.prototype.clearUserSubject = function () {
        this.selectedId = null;
        this.allUsers = [];
        this.usersSubject.next([]);
        this.rolesSubject.next([]);
    };
    AccountantUsersService.prototype.loadData = function () {
        var _this = this;
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        this.api.Account_GetAccountUsers().subscribe(function (data) {
            _this.allUsers = data;
            _this.applyFilter();
            _this.isLoading = false;
        });
    };
    AccountantUsersService.prototype.getRoles = function () {
        this.getRoleServicePublic();
    };
    AccountantUsersService.prototype.getRoleServicePublic = function () {
        var _this = this;
        if (this.selectedId) {
            this.api.Account_GetCompanyAccessByUser(this.selectedId).subscribe(function (data) {
                if (data.length > 0) {
                    if (data[0].UserId === _this.selectedId) {
                        _this.rolesSubject.next(data);
                    }
                    else {
                        if (!environment.production) {
                            console.error("UserId " + _this.selectedId + " doesnt match recieved data");
                        }
                    }
                }
                else {
                    if (!environment.production) {
                        console.log('No roles');
                    }
                }
            });
        }
    };
    AccountantUsersService.prototype.applyFilter = function () {
        if (this.filter) {
            this.currentPage = 0;
            var searchText_1 = this.filter.toLocaleLowerCase();
            var data = this.allUsers.filter(function (c) { return c.FullName.toLocaleLowerCase().includes(searchText_1) || c.Id.toString().includes(searchText_1); });
            this.usersSubject.next(data);
        }
        else {
            this.usersSubject.next(this.allUsers);
        }
        this.selectedId = undefined;
    };
    AccountantUsersService.prototype.updateData = function (model) {
        if (model && model.length > 0) {
            this.usersUpdateSubject.next(model);
        }
    };
    AccountantUsersService.prototype.reloadData = function () {
        this.loadData();
    };
    AccountantUsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountantUsersService_Factory() { return new AccountantUsersService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.SessionService), i0.ɵɵinject(i3.ModalService), i0.ɵɵinject(i4.StaticDataService)); }, token: AccountantUsersService, providedIn: "root" });
    return AccountantUsersService;
}(AccountantServiceBase));
export { AccountantUsersService };
