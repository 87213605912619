import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef, EventEmitter, OnInit } from '@angular/core';
import { ControlComponentBase } from '../../common/control-component-base';
import { ReflectionHelper } from '../../common/reflection-helper';
var CheckEditComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CheckEditComponent, _super);
    function CheckEditComponent(elementRef, changeDetectorRef) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.tooltip = '';
        _this.helpTooltip = '';
        _this.isOpenLink = true;
        _this.helpIcon = 'Help';
        _this.selfService = '';
        _this.isLink = false;
        _this.openLinkInTitle = '';
        _this.halfCheckChange = new EventEmitter();
        return _this;
    }
    Object.defineProperty(CheckEditComponent.prototype, "labelClass", {
        get: function () {
            return this.customClass + ' ' + (this.isGrid ? 'checkBox-label-center' : '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckEditComponent.prototype, "isDisabled", {
        get: function () {
            return this.disable ? this.disable : !this.editMode;
        },
        enumerable: true,
        configurable: true
    });
    CheckEditComponent.prototype.ngOnInit = function () {
        if (this.half && this.dataItemCB) {
            if (this.halfType === 'AppliesWeekdays') {
                if (!this.dataItemCB.AppliesMondays &&
                    !this.dataItemCB.AppliesTuesdays &&
                    !this.dataItemCB.AppliesWednesdays &&
                    !this.dataItemCB.AppliesThursdays &&
                    !this.dataItemCB.AppliesFridays) {
                    this.AppliesHalfCheckbox = true;
                    this.value = false;
                }
                else {
                    this.AppliesHalfCheckbox =
                        this.dataItemCB.AppliesMondays &&
                            this.dataItemCB.AppliesTuesdays &&
                            this.dataItemCB.AppliesWednesdays &&
                            this.dataItemCB.AppliesThursdays &&
                            this.dataItemCB.AppliesFridays;
                    this.value = this.AppliesHalfCheckbox;
                }
            }
            else if (this.halfType === 'AppliesSunHolis') {
                if (!this.dataItemCB.AppliesSundays && !this.dataItemCB.AppliesHolidays) {
                    this.AppliesHalfCheckbox = true;
                    this.value = false;
                }
                else {
                    this.AppliesHalfCheckbox = this.dataItemCB.AppliesSundays && this.dataItemCB.AppliesHolidays;
                    this.value = this.AppliesHalfCheckbox;
                }
            }
        }
    };
    CheckEditComponent.prototype.valid = function () {
        return true;
    };
    CheckEditComponent.prototype.convertValue = function (value) {
        if (value && ReflectionHelper.isString(value)) {
            return value.toLowerCase() === 'true';
        }
        else {
            return value;
        }
    };
    CheckEditComponent.prototype.onOpenlinkInTitleClick = function () {
        if (this.openLinkInTitle) {
            window.open(this.openLinkInTitle);
        }
    };
    CheckEditComponent.prototype.onClick = function (event) {
        if (this.half) {
            var isChecked = event.currentTarget.checked;
            this.AppliesHalfCheckbox = true;
            this.value = isChecked;
            this.halfCheckChange.emit({ checked: isChecked, dataItem: this.dataItemCB, type: this.halfType });
        }
    };
    return CheckEditComponent;
}(ControlComponentBase));
export { CheckEditComponent };
