<app-dialog
  *ngIf="visible"
  [overlayClose]="false"
  [(visible)]="visible"
  (action)="onDialogAction($event)"
  [width]="500"
  [title]="
    selectedSalaryType
      ? ('CompanyPayrollData.ChangeSalaryTypeTitle' | translate)
      : ('CompanyPayrollData.AddNewSalaryTypeTitle' | translate)
  "
>
  <app-combobox-edit
    [label]="'CompanyPayrollData.SalaryTypeComboboxLabel' | translate"
    [editMode]="true"
    [textField]="'displayName'"
    [idField]="'Id'"
    [comboboxDataSource]="salaryTypes"
    [placeholder]="'CompanySalaryTypes.PleaseChoose' | translate"
    [(value)]="replaceSalaryTypeId"
    (valueChange)="onSalaryTypeChange($event)"
  ></app-combobox-edit>

  <app-combobox-edit
    [label]="'CompanyPayrollData.OverviewDisplayMode' | translate"
    [editMode]="true"
    [textField]="'Name'"
    [idField]="'Id'"
    [disable]="!replaceSalaryTypeId"
    [comboboxDataSource]="overviewDisplayModes"
    [placeholder]="'CompanySalaryTypes.PleaseChoose' | translate"
    [(value)]="replaceSalaryType.OverviewDisplayMode"
  ></app-combobox-edit>

  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Ok" [close]="false"></app-dialog-action>
</app-dialog>
