import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Global } from '../../common/global';
import { IDepartment, IEntityType, IReport, IReportArea, IReportParameter, IReportParameterValue, IReportRequest, ISalaryCycle } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { DownloadService } from '../../services/download.service';
import { SessionService } from '../../services/session/session.service';
import { ReportDialogService } from './report-dialog.service';
import { ReportParametersDefaultValue } from './report-parameters-default-value';
var ReportDialogComponent = /** @class */ (function () {
    function ReportDialogComponent(staticDataService, dataService, sessionService, downloadService, translateService, reportDialogService) {
        var _this = this;
        this.staticDataService = staticDataService;
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.downloadService = downloadService;
        this.translateService = translateService;
        this.reportDialogService = reportDialogService;
        this.visibleValue = false;
        this.noReportVisible = false;
        this.visibleChange = new EventEmitter();
        this.gridVisible = true;
        this.numericTextBoxOption = { min: 0, step: 1, spinners: false };
        this.missingRequiredFields = '';
        this.validationDialogVisible = false;
        this.newTabBlockedDialogVisible = false;
        this.safariWarningDialogVisible = false;
        this.safariWarning = '';
        this.htmlReportDialogVisible = false;
        this.htmlReportDialogContent = '';
        // public onSelectedChanged(values: any[]): void {
        //   this.MultiSelectValues = values;
        // }
        this.ngUnsubscribe = new Subject();
        this.timeValues = [];
        this.currentArea = '1';
        this.translationServiceTerms = ['DropdownList.None'];
        this.staticDataService.ReportParameter.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.reportParameters = data); });
        this.staticDataService.ReportArea.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.reportAreas = data;
        });
        this.staticDataService.departments
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) { return (_this.departments = data); });
        this.staticDataService.SalaryCycle.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.salaryCycles = data); });
        this.reportDialogService.appReport.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.allReportState = data;
            _this.filterStateImage();
        });
        this.staticDataService.entityTypes
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) { return (_this.entityTypes = data); });
    }
    Object.defineProperty(ReportDialogComponent.prototype, "key", {
        get: function () {
            return this.keyValue;
        },
        set: function (value) {
            if (this.keyValue !== value) {
                this.keyValue = value;
                this.filterStateImage();
                this.reports = undefined;
                this.reportControls = [];
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.gridVisible = true;
                this.visibleValue = value;
                if (value) {
                    if (!this.reports) {
                        this.getReports();
                    }
                    this.getSelectedReport();
                    this.updateReportControl();
                }
                else {
                    this.savePreviousState();
                    // this.reportControls = [];
                }
                if (this.isMobile) {
                    this.selectedReport = undefined;
                }
                this.visibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "selectedReport", {
        get: function () {
            return this.selectedReportValue;
        },
        set: function (value) {
            if (value) {
                this.gridVisible = false;
            }
            // Save previous image of report staticRoleId
            if (this.selectedReportValue) {
                this.savePreviousState();
            }
            // Change value after save State
            if (this.selectedReportValue !== value) {
                this.selectedReportValue = value;
                this.createReportControls();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "formatOptions", {
        get: function () {
            var options = [];
            if (this.selectedReport) {
                options = this.selectedReport.SupportedFormats.sort(function (a, b) {
                    var order = ['html', 'pdf', 'xlsx', 'csv', 'bank', 'si', 'nets'];
                    return order.indexOf(a) - order.indexOf(b);
                });
                if (!this.selectedFormat && options) {
                    this.selectedFormat = options[0];
                }
            }
            return options;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "diaglogWidth", {
        get: function () {
            if (this.isMobile) {
                return 600;
            }
            return 750;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "displayFlex", {
        get: function () {
            if (this.isMobile) {
                return '';
            }
            return 'displayFlex';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "hiddenScroll", {
        get: function () {
            if (this.isMobile) {
                return '';
            }
            return 'hiddenScroll';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogComponent.prototype, "isCordovaApp", {
        get: function () {
            return this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    ReportDialogComponent.prototype.filterStateImage = function () {
        var _this = this;
        if (this.reportAreas && this.reportAreas.length > 0 && this.key) {
            var area_1 = this.reportAreas.find(function (item) { return item.Key === _this.key; });
            if (area_1) {
                this.currentReportState = this.allReportState.filter(function (item) { return item.AreaId === area_1.Id; });
            }
            else {
                this.currentReportState = [];
            }
        }
        else {
            this.currentReportState = [];
        }
    };
    ReportDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            _this.translateService.get(_this.translationServiceTerms).subscribe(function (translations) {
                _this.noneItemText = translations['DropdownList.None'];
            });
        });
    };
    ReportDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    ReportDialogComponent.prototype.onDialogAction = function (action) {
        var _this = this;
        if (this.sessionService.isSubmitting || this.sessionService.isGetting) {
            return;
        }
        if (action === 'Download') {
            if (!this.validate()) {
                return;
            }
            var reportRequest = {
                ReportId: this.selectedReport.Id,
                ParameterValues: this.getReportParameterValues()
            };
            var request = this.dataService.Reports_GetReportDownloadWithOutput(this.selectedFormat, reportRequest);
            switch (this.selectedFormat) {
                case 'pdf':
                    this.safariWarning = 'Download.SafariWarning_PDF';
                    break;
                case 'xlsx':
                    this.safariWarning = 'Download.SafariWarning_XLSX';
                    break;
                case 'csv':
                    this.safariWarning = 'Download.SafariWarning_CSV';
                    break;
                case 'xml':
                    this.safariWarning = 'Download.SafariWarning_XML';
                    break;
                case 'html':
                    request = this.dataService.Reports_GetReportWithOutput('html', reportRequest);
                    break;
            }
            request.subscribe(function (response) {
                // const enc = new TextDecoder('utf-8');
                if (_this.selectedFormat === 'html') {
                    if (!_this.sessionService.browser.isMobile) {
                        var newWindow = window.open('about:blank');
                        if (newWindow) {
                            newWindow.document.write(response);
                            // newWindow.document.write(enc.decode(response.body as BufferSource) as string);
                            newWindow.document.close();
                            _this.visible = false;
                            if (!_this.isMobile) {
                                _this.reports = undefined;
                            }
                        }
                        else {
                            _this.newTabBlockedDialogVisible = true;
                        }
                    }
                    else {
                        _this.htmlReportDialogContent = response;
                        // this.htmlReportDialogContent = enc.decode(response.body as BufferSource) as string;
                        _this.htmlReportDialogVisible = true;
                        _this.visible = false;
                    }
                }
                else {
                    if (_this.sessionService.browser.isMobileSafari) {
                        _this.safariWarningDialogVisible = true;
                    }
                    else if (!_this.isMobile) {
                        _this.reports = undefined;
                    }
                    _this.downloadService.download(_this.selectedReport.Name, response);
                    // this.downloadService.download(this.selectedReport.Name, response.body);
                    _this.visible = false;
                }
            });
        }
        if (action === 'Cancel') {
            this.visible = false;
        }
    };
    ReportDialogComponent.prototype.getControlDefaultLabel = function (control) {
        return control.nullValueLabel ? control.nullValueLabel : 'Report.ComboboxDataSourceNoneFound';
    };
    ReportDialogComponent.prototype.getReports = function () {
        var _this = this;
        if (this.key) {
            this.staticRoleId = Global.SESSION.CurrentRole.Id;
            var area = this.reportAreas.find(function (a) { return a.Key === _this.key; });
            if (area && this.staticRoleId) {
                this.dataService.Reports_GetReportsByArea(area.Id).subscribe(function (reports) {
                    if (reports.length > 0) {
                        _this.reports = reports.sort(function (a, b) { return (a.SortKey > b.SortKey ? 1 : -1); });
                        _this.reports = _this.reports.filter(function (z) { return z.RequiredRoleId <= _this.staticRoleId; });
                        _this.getSelectedReport();
                    }
                    else {
                        _this.visible = false;
                        _this.noReportVisible = true;
                    }
                });
            }
        }
    };
    ReportDialogComponent.prototype.addTimeToDatePicker = function (value, currentTimeValue) {
        var dayTimeValue;
        dayTimeValue = value;
        if (!currentTimeValue) {
            currentTimeValue = '00:00';
        }
        var timeSeperate = currentTimeValue.split(':').map(Number);
        dayTimeValue.setUTCHours(timeSeperate[0], timeSeperate[1]);
        return dayTimeValue;
    };
    ReportDialogComponent.prototype.getReportParameterValues = function () {
        var _this = this;
        var parametersValue = [];
        var value;
        if (this.reportControls) {
            this.reportControls.forEach(function (reportControl, idx) {
                value = reportControl.value;
                if (reportControl.type && reportControl.type.toLowerCase() === 'datepicker' && reportControl.value) {
                    value = _this.staticDataService.getReportDate(reportControl.value);
                }
                if (reportControl.type && reportControl.type.toLowerCase() === 'checkbox' && !reportControl.value) {
                    value = false;
                }
                if (reportControl.type && reportControl.type.toLowerCase() === 'combobox') {
                    value = _this.getComboboxParameterValue(reportControl);
                }
                if (reportControl.type && reportControl.type.toLowerCase() === 'multiselectlist') {
                    value = _this.getMultiSelectListParameterValue(reportControl);
                }
                parametersValue.push({ Key: reportControl.key, Value: value });
            });
        }
        return parametersValue;
    };
    ReportDialogComponent.prototype.getMultiSelectListParameterValue = function (multiselectlistControl) {
        var multiSelectValueIds = multiselectlistControl.value;
        if (multiSelectValueIds) {
            if (multiSelectValueIds.length === 0 ||
                multiSelectValueIds.length === multiselectlistControl.dataSource.length) {
                return null;
            }
            return JSON.stringify(multiSelectValueIds);
        }
        return null;
    };
    ReportDialogComponent.prototype.getComboboxParameterValue = function (comboboxControl) {
        var value;
        if (comboboxControl.value === undefined &&
            comboboxControl.nullValueLabel &&
            comboboxControl.nullValueLabel.indexOf('Al') >= 0) {
            value = null;
        }
        if (comboboxControl.value && comboboxControl.dataSource) {
            var comboObject = comboboxControl.dataSource.find(function (data) { return data[comboboxControl.underlyingId] === comboboxControl.value; });
            value = comboObject ? comboboxControl.value : undefined;
        }
        return value;
    };
    ReportDialogComponent.prototype.savePreviousState = function () {
        var _this = this;
        if (this.reportControls && this.reportControls.length > 0) {
            var area = this.reportAreas.find(function (a) { return a.Key === _this.key; });
            var currentState_1 = {
                AreaId: area.Id,
                Id: this.selectedReport.Id,
                FormatOptions: this.formatOptions,
                FormatValue: this.selectedFormat
            };
            currentState_1.Reports = [];
            this.reportControls.forEach(function (item) {
                if (_this.isReportControlValid(item)) {
                    var control = {
                        ReportId: currentState_1.Id,
                        ControlKey: item.key,
                        ControlType: item.type,
                        ControlValues: item.value
                    };
                    currentState_1.Reports.push(control);
                }
            });
            this.reportDialogService.changeReportImage(currentState_1);
        }
    };
    ReportDialogComponent.prototype.createReportControls = function () {
        var _this = this;
        setTimeout(function () {
            // Clear current State
            _this.reportControls = [];
            _this.taskBind = [];
            _this.selectedFormat = undefined;
            _this.reportImage = undefined;
            if (_this.selectedReport && _this.selectedReport.Parameters) {
                var parameters = _this.selectedReport.Parameters.split(',');
                if (parameters) {
                    if (_this.currentReportState && _this.currentReportState.length > 0) {
                        _this.reportImage = _this.currentReportState.find(function (model) {
                            return model.AreaId === _this.selectedReport.AreaId && model.Id === _this.selectedReport.Id;
                        });
                    }
                    var controlHasDatasource_1 = [];
                    parameters.forEach(function (parameter) {
                        var control = _this.createReportControl(parameter);
                        if (control) {
                            _this.reportControls.push(control);
                            _this.timeValues.push('');
                            if (control.type === 'ComboBox') {
                                control.placeholderText = 'Report.NoAvailableOptions';
                                _this.loadComboboxDataSource(control);
                                controlHasDatasource_1.push(control);
                            }
                            else if (control.type === 'MultiSelectList') {
                                control.placeholderText = 'Report.NoAvailableOptions';
                                _this.loadMultiSelectList(control);
                                controlHasDatasource_1.push(control);
                            }
                        }
                    });
                    if (controlHasDatasource_1 && controlHasDatasource_1.length > 0) {
                        if (_this.taskBind && _this.taskBind.length > 0) {
                            _this.taskBind.forEach(function (task) {
                                if (task.api.indexOf('api/entityType') >= 0) {
                                    task.api = 'api/entityType/' + _this.currentArea + '/Name';
                                    _this.dataService.httpGet(environment.apiUrl + '/' + task.api).subscribe(function (data) {
                                        controlHasDatasource_1.forEach(function (item) {
                                            if (item.api && item.api.indexOf('api/entityType') >= 0) {
                                                item.api = 'api/entityType/' + _this.currentArea + '/Name';
                                            }
                                            if (item.api === task.api) {
                                                _this.assignControlDataSource(item, item.listDataSourceParts, data);
                                            }
                                            else {
                                                _this.dataService.httpGet(environment.apiUrl + '/' + item.api).subscribe(function (dataEntity) {
                                                    _this.assignControlDataSource(item, item.listDataSourceParts, dataEntity);
                                                });
                                            }
                                        });
                                    });
                                }
                                else {
                                    _this.dataService.httpGet(environment.apiUrl + '/' + task.api).subscribe(function (data) {
                                        controlHasDatasource_1.forEach(function (item) {
                                            if (item.api === task.api) {
                                                _this.assignControlDataSource(item, item.listDataSourceParts, data);
                                            }
                                        });
                                    });
                                }
                            });
                        }
                    }
                    if (_this.reportImage) {
                        if (_this.reportImage.FormatOptions) {
                            _this.selectedFormat = _this.reportImage.FormatValue;
                        }
                        if (_this.reportImage.Reports && _this.reportImage.Reports.length > 0) {
                            _this.reportImage.Reports.forEach(function (control) {
                                var updateItem = _this.reportControls.find(function (item) { return item.key === control.ControlKey; });
                                var indexUpdate = _this.reportControls.indexOf(updateItem);
                                if (indexUpdate !== -1) {
                                    if (control.ControlType === 'MultiSelectList' || control.ControlType === 'combobox') {
                                    }
                                    else {
                                        _this.reportControls[indexUpdate].value = control.ControlValues;
                                    }
                                }
                            });
                        }
                    }
                }
            }
        });
    };
    ReportDialogComponent.prototype.loadMultiSelectList = function (control) {
        var listDataSourceParts = this.getPartsFromListDataSourceStringOfMultiSelect(control.listDataSource);
        var displayColumns = listDataSourceParts.displayText.split(',');
        control.columns = displayColumns.map(function (c) { return ({ dataField: c, caption: c }); });
        control.displayExp = displayColumns[0];
        control.value = [];
        control.underlyingId = listDataSourceParts.underlyingId;
        control.api = listDataSourceParts.api;
        control.listDataSourceParts = listDataSourceParts;
        if (!this.taskBind.find(function (item) { return item.api === listDataSourceParts.api; })) {
            this.taskBind.push({ api: listDataSourceParts.api, data: [] });
        }
        // this.dataService.httpGet(environment.apiUrl + '/' + listDataSourceParts.api).subscribe((data: any) => {
        //   this.assignControlDataSource(control, listDataSourceParts, data);
        // });
    };
    ReportDialogComponent.prototype.loadComboboxDataSource = function (control) {
        var listDataSourceParts = this.getPartsFromListDataSourceStringOfCombobox(control.listDataSource);
        control.underlyingId = listDataSourceParts.underlyingId;
        control.displayText = listDataSourceParts.displayText;
        if (!listDataSourceParts.api) {
            return;
        }
        control.api = listDataSourceParts.api;
        control.listDataSourceParts = listDataSourceParts;
        if (!this.taskBind.find(function (item) { return item.api === listDataSourceParts.api; })) {
            this.taskBind.push({ api: listDataSourceParts.api, data: [] });
        }
        // this.dataService.httpGet(environment.apiUrl + '/' + listDataSourceParts.api).subscribe((data: any) => {
        //   this.assignControlDataSource(control, listDataSourceParts, data);
        // });
    };
    ReportDialogComponent.prototype.getPartsFromListDataSourceStringOfMultiSelect = function (stringListDataSource) {
        stringListDataSource = stringListDataSource.replace(':', ';');
        var parts = stringListDataSource.split(';');
        // const employmentId: string = this.param && this.param.employmentId ? this.param.employmentId.toString() : '';
        return {
            api: parts[0],
            underlyingId: parts[1],
            displayText: parts[2]
        };
    };
    ReportDialogComponent.prototype.assignControlDataSource = function (control, listDataSourceParts, data, isChanged) {
        var _this = this;
        var sameApiComboboxes = this.reportControls.filter(function (ctrl) {
            if (ctrl.listDataSource) {
                var ctrlListDataSourceParts = _this.getPartsFromListDataSourceStringOfCombobox(ctrl.listDataSource);
                return ctrlListDataSourceParts.api === listDataSourceParts.api;
            }
            else {
                return false;
            }
        });
        if (!sameApiComboboxes || sameApiComboboxes.length === 0) {
            return;
        }
        if (data && data.length > 0) {
            if (control.defaultValue === '{All}') {
                control.value = data.map(function (item) {
                    return item[control.underlyingId];
                });
                // control.defaultValue = JSON.parse(JSON.stringify(control.value));
            }
            else if (control.defaultValue === '{Current}' || control.key.indexOf('EmploymentId') >= 0) {
                control.displayText = 'Title';
                data = data.filter(function (item) {
                    if (item[control.underlyingId] === _this.reportDialogService.selectedEmploymentId) {
                        control.value = item[control.underlyingId];
                    }
                    return item.CompanyUserId === _this.reportDialogService.selectedUserId;
                });
            }
            else if (control.defaultValue && control.underlyingId) {
                var isExitDefaultValueDataSource = data.find(function (model) { return model[control.underlyingId] === control.defaultValue; });
                if (!isExitDefaultValueDataSource) {
                    var isExitIdValueDataSource = data.find(function (model) {
                        return (model.Id ? model.Id.toString() : model.Id) ===
                            (control.defaultValue ? control.defaultValue.toString() : control.defaultValue);
                    });
                    if (isExitIdValueDataSource) {
                        control.value = isExitIdValueDataSource[control.underlyingId];
                    }
                }
            }
            control.placeholderText = '';
            data.forEach(function (model) {
                if (!model[control.underlyingId]) {
                    control.nullValueLabel = model.Name;
                    var indexOf = data.indexOf(model, 0);
                    if (indexOf === 0) {
                        control.hasItemNull = true;
                    }
                    data.splice(indexOf, 1);
                }
            });
        }
        control.dataSource = data;
        control.dataSourceEmpty = data === undefined || data === null || data.length < 1;
        if (!control.dataSourceEmpty && control.defaultValue === '{First}') {
            if (control.hasItemNull) {
                control.value = undefined;
            }
            else {
                control.value = data[0][control.underlyingId];
            }
        }
        if (sameApiComboboxes && sameApiComboboxes.length > 0) {
            sameApiComboboxes.forEach(function (apiCombo) {
                if (!apiCombo.dataSource || apiCombo.dataSource.length === 0) {
                    apiCombo.dataSource = data;
                    apiCombo.dataSourceEmpty = data === undefined || data === null || data.length < 1;
                }
                if (!apiCombo.dataSourceEmpty && apiCombo.defaultValue === '{First}') {
                    if (control.hasItemNull) {
                        apiCombo.value = undefined;
                    }
                    else {
                        apiCombo.value = data[0][control.underlyingId];
                    }
                }
            });
        }
        if (control.value && control.value.length === 1 && control.type !== 'MultiSelectList') {
            var indexOfValue = control.dataSource.find(function (model) { return model[control.underlyingId] === control.value; });
            if (!indexOfValue) {
                control.nullValueLabel = control.value;
                control.value = undefined;
            }
        }
        // Load last State
        if (this.reportImage && this.reportImage.Reports && this.reportImage.Reports.length > 0) {
            var dataSourceControlImage = this.reportImage.Reports.filter(function (item) { return item.ControlType === 'ComboBox' || item.ControlType === 'MultiSelectList'; });
            if (dataSourceControlImage && dataSourceControlImage.length > 0) {
                dataSourceControlImage.forEach(function (controlImage) {
                    var updateItem = _this.reportControls.find(function (item) { return item.key === controlImage.ControlKey; });
                    var indexUpdate = _this.reportControls.indexOf(updateItem);
                    if (indexUpdate !== -1 && _this.reportControls[indexUpdate].defaultValue !== '{Current}' && !isChanged) {
                        if (controlImage.ControlKey === 'RequiredChangeLogType' && controlImage.ControlType === 'ComboBox') {
                            _this.currentArea = controlImage.ControlValues;
                        }
                        _this.reportControls[indexUpdate].value = controlImage.ControlValues;
                    }
                });
            }
        }
    };
    ReportDialogComponent.prototype.getPartsFromListDataSourceStringOfCombobox = function (stringListDataSource) {
        stringListDataSource = stringListDataSource.replace(':', ';');
        var parts = stringListDataSource.split(';');
        var employmentId = this.param && this.param.employmentId ? this.param.employmentId.toString() : '';
        if (parts[0].indexOf('{CurrentUserEmploymentId}') >= 0) {
            return {
                api: !!employmentId ? parts[0].replace('{CurrentUserEmploymentId}', employmentId) : '',
                underlyingId: parts[1],
                displayText: parts[2]
            };
        }
        return {
            api: parts[0],
            underlyingId: parts[1],
            displayText: parts[2]
        };
    };
    ReportDialogComponent.prototype.createReportControl = function (parameter) {
        var reportParameter = this.reportParameters.find(function (par) { return par.Key === parameter; });
        if (!reportParameter) {
            console.log('reportParameter is undefined for parameter: ' + parameter);
            return undefined;
        }
        var key = reportParameter['Key'];
        // let defaultValue: any = this.getSelectedReportControlDefaultValueByKey(key);
        var defaultValue = this.getSelectedReportControlDefaultValue(reportParameter['DefaultValue'])
            ? this.getSelectedReportControlDefaultValue(reportParameter['DefaultValue'])
            : undefined;
        if (reportParameter.UiControlType === 'DatePicker') {
            defaultValue = this.staticDataService.getUTCDate(defaultValue);
        }
        if (!defaultValue && reportParameter['IsRequired']) {
            defaultValue = this.getSelectedReportControlDefaultValueByKey(key);
        }
        var nonCombo = '';
        if (!reportParameter['IsRequired'] && reportParameter['IsRequired'] === false) {
            nonCombo = 'DropdownList.None';
        }
        return {
            type: reportParameter['UiControlType'],
            defaultValue: defaultValue,
            value: defaultValue !== '{First}' ? defaultValue : undefined,
            listDataSource: reportParameter['ListDataSource'],
            nullValueLabel: nonCombo,
            isRequired: reportParameter['IsRequired'],
            isHidden: reportParameter['IsHidden'],
            name: reportParameter['Name'],
            key: key,
            sortKey: reportParameter['SortKey'],
            displayText: '',
            underlyingId: '',
            dataSource: [],
            dataSourceEmpty: false,
            dependsOn: reportParameter.DependsOn
        };
    };
    ReportDialogComponent.prototype.getSelectedReportControlDefaultValueByKey = function (key) {
        var defaultValue;
        switch (key) {
            case 'SalaryBatchId':
                defaultValue = this.param.salaryBatchId;
                break;
            case 'OptionalSalaryBatchId':
                defaultValue = this.param.salaryBatchId;
                break;
            case 'FilterByDepartment':
                if (this.departments.length > 0) {
                    defaultValue = this.departments[0];
                }
                break;
            case 'FilterBySalaryCycle':
                if (this.salaryCycles.length > 0) {
                    defaultValue = this.salaryCycles[0];
                }
                break;
            case 'CompanyUserId':
                defaultValue = this.param.employeeId;
                break;
            case 'SelfEmploymentId':
                defaultValue = this.param.employmentId;
                break;
            case 'UserEmploymentId':
                defaultValue = this.param.employmentId;
                break;
            case 'FromDate_YearBegin':
                defaultValue = this.getFirstDayOfCurrentYear();
                break;
            case 'ToDate_Today':
                defaultValue = this.staticDataService.getCurrentdate();
                break;
            case 'FromDate_LastMonthStart':
                defaultValue = this.getFirstDayOfPreviousMonth();
                break;
            case 'ToDate_LastMonthEnd':
                defaultValue = this.getLastDateOfPreviousMonth();
                break;
        }
        return defaultValue;
    };
    ReportDialogComponent.prototype.getSelectedReportControlDefaultValue = function (defaultValue) {
        switch (defaultValue) {
            case '{Current SalaryBatchId}':
                defaultValue = this.param.salaryBatchId;
                break;
            case '{Today}':
                defaultValue = this.staticDataService.getCurrentdate();
                break;
            case '{First day of current year}':
                defaultValue = this.getFirstDayOfCurrentYear();
                break;
            case '{First day of previous month}':
                defaultValue = this.getFirstDayOfPreviousMonth();
                break;
            case '{Last day of previous month}':
                defaultValue = this.getLastDateOfPreviousMonth();
                break;
            case '{Current}':
                // just define current for employee time
                if (this.sessionService.currentState.includes('tabs.employee.time')) {
                    defaultValue = this.param.employmentId;
                }
                break;
        }
        return defaultValue;
    };
    ReportDialogComponent.prototype.getFirstDayOfCurrentYear = function () {
        var date = new Date();
        return this.staticDataService.getCurrentdate(undefined, new Date(date.getFullYear(), 0, 1));
    };
    ReportDialogComponent.prototype.getFirstDayOfPreviousMonth = function () {
        var date = new Date();
        var currentYear = date.getFullYear();
        var previousMonth = date.getMonth() - 1;
        if (previousMonth === 0) {
            currentYear -= 1;
            previousMonth = 12;
        }
        return this.staticDataService.getCurrentdate(undefined, new Date(currentYear, previousMonth, 1));
    };
    ReportDialogComponent.prototype.getLastDateOfPreviousMonth = function () {
        var date = new Date();
        return this.staticDataService.getCurrentdate(undefined, new Date(date.getFullYear(), date.getMonth(), 0));
    };
    ReportDialogComponent.prototype.validate = function () {
        this.missingRequiredFields = this.checkRequiredFields();
        if (this.missingRequiredFields) {
            this.validationDialogVisible = true;
            return false;
        }
        return true;
    };
    ReportDialogComponent.prototype.checkRequiredFields = function () {
        var _this = this;
        var requiredFields = '';
        if (this.reportControls) {
            this.reportControls.forEach(function (reportControl) {
                if (!_this.isReportControlValid(reportControl)) {
                    requiredFields += reportControl.name + ' ,';
                }
            });
        }
        if (!requiredFields) {
            return undefined;
        }
        requiredFields = requiredFields.slice(0, requiredFields.length - 2);
        return requiredFields;
    };
    ReportDialogComponent.prototype.isReportControlValid = function (reportControl) {
        if (!reportControl.isRequired && !(reportControl.type && reportControl.type.toLowerCase() === 'multiselectlist')) {
            return true;
        }
        // Special case for All selection at dropdownlist
        if (reportControl.type.toLowerCase() === 'combobox' &&
            reportControl.nullValueLabel &&
            reportControl.nullValueLabel.indexOf('Al') >= 0 &&
            reportControl.value === undefined) {
            return true;
        }
        if (reportControl.type && reportControl.type.toLowerCase() === 'checkbox') {
            return true;
        }
        if (reportControl.type &&
            reportControl.type.toLowerCase() === 'datepicker' &&
            (!reportControl.value || (reportControl.value && new Date(reportControl.value) === undefined))) {
            return false;
        }
        if (reportControl.type && reportControl.type.toLowerCase() === 'multiselectlist') {
            if (reportControl.isRequired) {
                return !(!reportControl.value || (reportControl.value && reportControl.value.length === 0));
            }
            return true;
        }
        return reportControl.value;
    };
    ReportDialogComponent.prototype.onDoubleClick = function ($event) {
        var _this = this;
        setTimeout(function () {
            if ($event.event) {
                $event.event.stopPropagation();
                $event.event.preventDefault();
            }
            if (_this.sessionService.isGetting || _this.sessionService.isSubmitting) {
                return;
            }
            _this.onDialogAction('Download');
        });
    };
    ReportDialogComponent.prototype.onBackClick = function () {
        if (this.isMobile) {
            this.savePreviousState();
            this.selectedReport = undefined;
            this.gridVisible = true;
        }
    };
    ReportDialogComponent.prototype.getSelectedReport = function () {
        var _this = this;
        if (this.reports && this.reports.length > 0 && !this.isMobile) {
            if (!this.selectedReport) {
                this.selectedReport = this.reports[0];
            }
            else {
                var existReportIndex_1 = -1;
                var existReport = this.reports.filter(function (report, index) {
                    if (report.Key === _this.selectedReport.Key) {
                        existReportIndex_1 = index;
                        return true;
                    }
                    return false;
                });
                if (existReport && existReport.length === 0 && existReportIndex_1 === -1) {
                    this.selectedReport = this.reports[0];
                }
                else {
                    this.selectedReport = this.reports[existReportIndex_1];
                }
            }
            setTimeout(function () {
                var tdSelect = _this.gridDialog.kendoGrid.wrapper.nativeElement.querySelector('tr.k-state-selected > td');
                if (tdSelect) {
                    tdSelect.focus();
                    tdSelect.click();
                }
            }, 700);
        }
    };
    ReportDialogComponent.prototype.updateReportControl = function (control, isChanged) {
        var _this = this;
        setTimeout(function () {
            if (_this.selectedReport && _this.selectedReport.Parameters) {
                var parameters = _this.selectedReport.Parameters.split(',');
                if (parameters) {
                    if (_this.currentReportState && _this.currentReportState.length > 0) {
                        _this.reportImage = _this.currentReportState.find(function (model) {
                            return model.AreaId === _this.selectedReport.AreaId && model.Id === _this.selectedReport.Id;
                        });
                    }
                    if (_this.reportControls && _this.reportControls.length > 0) {
                        if (control) {
                            var reportControl_1 = _this.reportControls.find(function (item) { return control.Key === item.Key && item.dependsOn; });
                            if (reportControl_1) {
                                reportControl_1.api = 'api/entityType/' + _this.currentArea + '/Name';
                                reportControl_1.value = null;
                                _this.dataService.httpGet(environment.apiUrl + '/' + reportControl_1.api).subscribe(function (data) {
                                    _this.assignControlDataSource(reportControl_1, reportControl_1.listDataSourceParts, data, isChanged);
                                });
                            }
                        }
                        else if (_this.taskBind && _this.taskBind.length > 0) {
                            _this.taskBind.forEach(function (task) {
                                if (task.api.indexOf('api/entityType') >= 0) {
                                    task.api = 'api/entityType/' + _this.currentArea + '/Name';
                                }
                                _this.dataService.httpGet(environment.apiUrl + '/' + task.api).subscribe(function (data) {
                                    _this.reportControls.forEach(function (item) {
                                        if (item.api && item.api.indexOf('api/entityType') >= 0) {
                                            item.api = 'api/entityType/' + _this.currentArea + '/Name';
                                        }
                                        if (item.api === task.api) {
                                            _this.assignControlDataSource(item, item.listDataSourceParts, data, isChanged);
                                        }
                                    });
                                });
                            });
                        }
                    }
                }
            }
        });
    };
    ReportDialogComponent.prototype.onComboboxChanged = function (item, control) {
        if (control.dependsOn || !item || control.listDataSource.indexOf('entityType') < 0) {
            return;
        }
        this.currentArea = item.toString();
        this.updateReportControl(control, true);
    };
    return ReportDialogComponent;
}());
export { ReportDialogComponent };
