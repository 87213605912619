import { EventEmitter } from '@angular/core';
import { SessionService } from 'src/app/services/session/session.service';
var ButtonComponent = /** @class */ (function () {
    function ButtonComponent(sessionService) {
        this.sessionService = sessionService;
        this.tooltip = '';
        this.isUseOneClick = false;
        this.isDisableSubmitOnly = false;
        this.clickEvent = new EventEmitter();
        this.disabledValue = false;
    }
    Object.defineProperty(ButtonComponent.prototype, "isDisabled", {
        get: function () {
            return this.disabledValue || this.isStateNonClick;
        },
        set: function (value) {
            var _this = this;
            if (value) {
                this.disabledValue = value;
            }
            else {
                setTimeout(function () {
                    _this.disabledValue = value;
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonComponent.prototype, "isStateNonClick", {
        get: function () {
            if (this.isDisableSubmitOnly) {
                return this.sessionService.isSubmitting;
            }
            return this.sessionService.isGetting || this.sessionService.isSubmitting;
        },
        enumerable: true,
        configurable: true
    });
    ButtonComponent.prototype.onButtonClick = function () {
        if (this.isDisabled) {
            return;
        }
        this.clickEvent.emit();
    };
    return ButtonComponent;
}());
export { ButtonComponent };
