import { EventEmitter } from '@angular/core';
import { EmployeeService } from 'src/app/services/employee.service';
import { ImportResultEnum, ImportTypesEnum } from '../../../model/enum';
var ImportErrorsDialogComponent = /** @class */ (function () {
    function ImportErrorsDialogComponent(employeeService) {
        this.employeeService = employeeService;
        this.testImport = true;
        this.closedEvent = new EventEmitter();
        this.completeImportEvent = new EventEmitter();
        this.visible = false;
        this.successDialogVisible = false;
        this.fatalDialogVisible = false;
    }
    Object.defineProperty(ImportErrorsDialogComponent.prototype, "importResult", {
        get: function () {
            if (!this.importResultValue) {
                return this.importResultValue;
            }
            this.importResultValue.Errors.forEach(function (element) {
                element.Message = element.Message.replace(/<br\s*\/?><br\s*\/?>/gi, '<br>');
                return;
            });
            return this.importResultValue;
        },
        set: function (value) {
            this.importResultValue = value;
            if (value) {
                this.InitialErrorGrid();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportErrorsDialogComponent.prototype, "showTestImportError", {
        get: function () {
            return this.hasError && this.testImport;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportErrorsDialogComponent.prototype, "hasError", {
        get: function () {
            return this.importResult && this.importResult.Errors && this.importResult.Errors.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportErrorsDialogComponent.prototype, "IsFatal", {
        get: function () {
            return this.importResult && this.importResult.Outcome === ImportResultEnum[ImportResultEnum.FatalError];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportErrorsDialogComponent.prototype, "IsError", {
        get: function () {
            return this.importResult && this.importResult.Outcome === ImportResultEnum[ImportResultEnum.Errors];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportErrorsDialogComponent.prototype, "IsWarning", {
        get: function () {
            return this.importResult && this.importResult.Outcome === ImportResultEnum[ImportResultEnum.Warnings];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportErrorsDialogComponent.prototype, "isBalanceImport", {
        get: function () {
            return this.importType === ImportTypesEnum.Initial_Balances;
        },
        enumerable: true,
        configurable: true
    });
    ImportErrorsDialogComponent.prototype.onDialogAction = function (action) {
        if (action === 'Complete') {
            this.completeImportEvent.emit();
        }
        this.closedEvent.emit();
    };
    ImportErrorsDialogComponent.prototype.InitialErrorGrid = function () {
        if (this.importResult && this.importResult.Outcome) {
            switch (this.importResult.Outcome) {
                case ImportResultEnum[ImportResultEnum.Success]:
                    this.successDialogVisible = true;
                    this.reloademployee();
                    break;
                case ImportResultEnum[ImportResultEnum.FatalError]:
                    if (this.importResult.Errors && this.importResult.Errors.length > 0) {
                        this.visible = true;
                    }
                    else {
                        this.fatalDialogVisible = true;
                    }
                    break;
                default:
                    this.visible = true;
            }
        }
    };
    ImportErrorsDialogComponent.prototype.reloademployee = function () {
        if (this.importType === ImportTypesEnum.Employees) {
            this.employeeService.loadEmployees();
        }
    };
    return ImportErrorsDialogComponent;
}());
export { ImportErrorsDialogComponent };
