<div class="company-balances">
  <app-fixed-panel [excludedComponents]="listClassExcluded" [specificClassName]="'Island-balance'">
    <div class="Island Island-balance tabs">
      <div class="ConfigNav Navigation-wrapper Navigation Navigation-inner" [ngClass]="{ 'is-active': menuOpen }">
        <div class="Navigation-expandWrapper">
          <button class="Navigation-link Navigation-expand" (click)="onToggleNavigation()">
            <div>
              <div *ngIf="ResidualVacationIsActive">
                {{ 'ResidualVacation.ResidualVacation' | translate }}
              </div>

              <div *ngIf="ResidualExtravacationhoursIsActive">
                {{ 'ResidualVacation.ExtraVacationHours' | translate }}
              </div>

              <app-icon [icon]="'ChevronDown'" [size]="'xxsmall'"></app-icon>
            </div>
          </button>
        </div>
        <ul class="Navigation-list">
          <li class="Navigation-item">
            <app-button
              id="CompanyBalancesResidualVacationTabButton"
              class="Navigation-link MainTabButton"
              (clickEvent)="changeState('tabs.company.balances.residualvacation')"
              [ngClass]="{ 'is-active': ResidualVacationIsActive }"
              [label]="'ResidualVacation.ResidualVacation' | translate"
            >
            </app-button>
          </li>

          <li class="Navigation-item">
            <app-button
              id="CompanyBalancesExtraVacationTabButton"
              class="Navigation-link MainTabButton"
              (clickEvent)="changeState('tabs.company.balances.extravacationhours')"
              [ngClass]="{ 'is-active': ResidualExtravacationhoursIsActive }"
              [label]="'ResidualVacation.ExtraVacationHours' | translate"
            >
            </app-button>
          </li>
        </ul>
      </div>

      <app-company-balances-residual-vacation *ngIf="ResidualVacationIsActive">
      </app-company-balances-residual-vacation>

      <app-company-balances-extra-vacation-hours *ngIf="ResidualExtravacationhoursIsActive">
      </app-company-balances-extra-vacation-hours>
    </div>
  </app-fixed-panel>
</div>
