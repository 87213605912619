import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Browser } from '../common/browser';
import { Constants } from '../common/constants';
import { Global } from '../common/global';
import { BroadcastService } from './broadcast.service';
import { ModalService } from './modal.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./modal.service";
import * as i3 from "./broadcast.service";
var SettingService = /** @class */ (function () {
    function SettingService(translateService, modalService, broadcaster) {
        this.translateService = translateService;
        this.modalService = modalService;
        this.broadcaster = broadcaster;
        this.showFadingMessage = {};
        this.navigationParameters = {};
        this.IsShowUpdateNotification = false;
        this.showCompanySelector = false;
        this.isReload = true;
        this.ReloadWarrning = false;
        this.showPasswordChangeDialog = false;
        this.welcomeTitles = {};
        this.welcomeMessages = {};
        this.moduleMessageQueue = [];
    }
    Object.defineProperty(SettingService.prototype, "isIOSMobileApp", {
        get: function () {
            var browser = new Browser();
            return browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    SettingService.prototype.updateSettingsForEmployeeCompany = function (tasks) {
        var companyTaskCompleted = false;
        var employeeTaskCompleted = false;
        if (tasks.length > 0) {
            var companyTask = tasks
                ? tasks.find(function (com) { return com.Key === 'CompanyData'; })
                : undefined;
            var employeeTask = tasks
                ? tasks.find(function (emp) { return emp.Key === 'CreateEmployees'; })
                : undefined;
            companyTaskCompleted = companyTask && companyTask.StatusId === 1 ? true : false;
            employeeTaskCompleted = employeeTask && employeeTask.StatusId === 1 ? true : false;
        }
        // Save settings.
        Global.IsCompanyDataCompleted = companyTaskCompleted;
        Global.IsEmployeeTaskCompleted = employeeTaskCompleted;
        Global.IsTaskCompleted = tasks.every(function (item) { return item.StatusId === 1; });
    };
    SettingService.prototype.applyTranslations = function (languageCode) {
        var _this = this;
        var langCode = languageCode
            ? languageCode
            : Global.SESSION && Global.SESSION.SignOnToken.Language
                ? Global.SESSION.SignOnToken.Language.CultureCode.split('-')[0]
                : undefined;
        if (this.translateService.currentLang !== langCode) {
            this.translateService.use(langCode).subscribe(function () {
                if (!languageCode) {
                    _this.translateApplicationText();
                }
            });
        }
        else {
            if (!languageCode) {
                this.translateApplicationText();
            }
        }
    };
    SettingService.prototype.translateApplicationText = function () {
        var _this = this;
        this.translateService
            .get([
            'Beta.BetaTitle',
            'Beta.BetaModuleWarning',
            'EmployeeGeneral.WelcomeTitle',
            'EmployeeGeneral.WelcomeMessage',
            'EmployeeGeneral.WelcomeEmployeeMessage',
            'EmployeeEmployment.WelcomeTitle',
            'EmployeeEmployment.WelcomeMessage',
            'EmploymentPayrollData.WelcomeTitle',
            'EmploymentPayrollData.WelcomeMessage',
            'EmploymentTime.WelcomeTitle',
            'EmploymentTime.WelcomeMessage',
            'EmployeePayslip.WelcomeTitle',
            'EmployeePayslip.WelcomeMessage',
            'AccountModules.WelcomeTitle',
            'AccountModules.WelcomeMessage',
            'CompanyGeneral.WelcomeTitle',
            'CompanyGeneral.WelcomeMessage',
            'CompanyHiringStatus.WelcomeTitle',
            'CompanyHiringStatus.WelcomeMessage',
            'CompanySalaryBatches.WelcomeTitle',
            'CompanySalaryBatches.WelcomeMessage',
            'CompanySalaryTypes.WelcomeTitle',
            'CompanySalaryTypes.WelcomeMessage',
            'CompanyTimeEntryTypes.WelcomeTitle',
            'CompanyTimeEntryTypes.WelcomeMessage',
            'SelfServicePayslip.WelcomeTitle',
            'SelfServicePayslip.WelcomeMessage',
            'Timeentrytypes.WelcomeTitle',
            'Timeentrytypes.WelcomeMessage',
            'Integrations.WelcomeTitle',
            'Integrations.WelcomeMessage',
            'GlobalDialog.Close',
            'GlobalDialog.ReadMore',
            'Login.UpdatedMessageUrl'
        ])
            .subscribe(function (translations) {
            _this.betaModuleTitle = translations['Beta.BetaTitle'];
            _this.betaModuleMessage = translations['Beta.BetaModuleWarning'];
            _this.welcomeTitles['Employee.General'] = translations['EmployeeGeneral.WelcomeTitle'].replace('{{SystemAlias}}', environment.branding.SystemAlias);
            _this.welcomeMessages['Employee.General'] = translations['EmployeeGeneral.WelcomeMessage'];
            _this.welcomeMessages['EmployeeGeneral.WelcomeEmployeeMessage'] = translations['EmployeeGeneral.WelcomeEmployeeMessage'].replace(/\{{SystemAlias}}/g, environment.branding.SystemAlias);
            _this.welcomeTitles['Employee.Employment'] = translations['EmployeeEmployment.WelcomeTitle'];
            _this.welcomeMessages['Employee.Employment'] = translations['EmployeeEmployment.WelcomeMessage'];
            _this.welcomeTitles['Employee.Time'] = translations['EmploymentTime.WelcomeTitle'];
            _this.welcomeMessages['Employee.Time'] = translations['EmploymentTime.WelcomeMessage'];
            _this.welcomeTitles['Employee.PayrollData'] = translations['EmploymentPayrollData.WelcomeTitle'];
            _this.welcomeMessages['Employee.PayrollData'] = translations['EmploymentPayrollData.WelcomeMessage'];
            _this.welcomeTitles['Employee.Payslip'] = translations['EmployeePayslip.WelcomeTitle'];
            _this.welcomeMessages['Employee.Payslip'] = translations['EmployeePayslip.WelcomeMessage'].replace('{{SystemAlias}}', environment.branding.SystemAlias);
            _this.welcomeTitles['Company.General'] = translations['CompanyGeneral.WelcomeTitle'];
            _this.welcomeMessages['Company.General'] = translations['CompanyGeneral.WelcomeMessage'];
            _this.welcomeTitles['Company.Templates'] = translations['CompanyHiringStatus.WelcomeTitle'];
            _this.welcomeMessages['Company.Templates'] = translations['CompanyHiringStatus.WelcomeMessage'];
            _this.welcomeTitles['Company.SalaryBatches'] = translations['CompanySalaryBatches.WelcomeTitle'];
            _this.welcomeMessages['Company.SalaryBatches'] = translations['CompanySalaryBatches.WelcomeMessage'];
            _this.welcomeTitles['Company.SalaryTypes'] = translations['CompanySalaryTypes.WelcomeTitle'];
            _this.welcomeMessages['Company.SalaryTypes'] = translations['CompanySalaryTypes.WelcomeMessage'];
            _this.welcomeTitles['Company.TimeEntryTypes'] = translations['CompanyTimeEntryTypes.WelcomeTitle'];
            _this.welcomeMessages['Company.TimeEntryTypes'] = translations['CompanyTimeEntryTypes.WelcomeMessage'];
            _this.welcomeTitles['Account.Modules'] = translations['AccountModules.WelcomeTitle'];
            _this.welcomeMessages['Account.Modules'] = translations['AccountModules.WelcomeMessage'].replace('{{SystemAlias}}', environment.branding.SystemAlias);
            _this.welcomeTitles['SelfService.Payslip'] = translations['SelfServicePayslip.WelcomeTitle'];
            _this.welcomeMessages['SelfService.Payslip'] = translations['SelfServicePayslip.WelcomeMessage'].replace(/\{{SystemAlias}}/g, environment.branding.SystemAlias);
            _this.welcomeTitles['company.timeentrytypes'] = translations['Timeentrytypes.WelcomeTitle'];
            _this.welcomeMessages['company.timeentrytypes'] = translations['Timeentrytypes.WelcomeMessage'];
            _this.welcomeTitles['company.integrations'] = translations['Integrations.WelcomeTitle'];
            _this.welcomeMessages['company.integrations'] = translations['Integrations.WelcomeMessage'].replace('{{SystemAlias}}', environment.branding.SystemAlias);
            _this.welcomeTitles['company.advancedsalarytypes'] = translations['CompanySalaryTypes.WelcomeTitle'];
            _this.welcomeMessages['company.advancedsalarytypes'] = translations['CompanySalaryTypes.WelcomeMessage'];
            _this.welcomeMessages['Login.UpdatedMessageUrl'] = translations['Login.UpdatedMessageUrl'].replace('{{SystemAlias}}', environment.branding.SystemAlias);
            if (_this.moduleMessageQueue && _this.moduleMessageQueue.length > 0) {
                _this.moduleMessageQueue.forEach(function (moduleMessage) {
                    _this.showModuleMessage(moduleMessage.moduleName, moduleMessage.overideMessage);
                });
                _this.moduleMessageQueue = [];
            }
        });
    };
    SettingService.prototype.showDoubleClickToEditHint = function (show) {
        var snackBar = document.getElementById('divHintForEdit');
        if (!snackBar) {
            return;
        }
        if (show) {
            snackBar.className = 'show';
            setTimeout(function () {
                snackBar.className = snackBar.className.replace('show', '');
            }, 5000);
        }
        else {
            snackBar.className = snackBar.className.replace('show', '');
        }
    };
    SettingService.prototype.onModuleMessageDialogAction = function (action) {
        if (action === 'ReadMore') {
            window.open(this.welcomeMessages['Login.UpdatedMessageUrl'], '_blank');
        }
        ////this.showFlipScreenHint(this.moduleName);
        this.IsShowUpdateNotification = false;
        this.broadcaster.broadcast(Constants.SHOW_EDIT_HINT);
    };
    SettingService.prototype.showModuleMessage = function (moduleName, overideMessage) {
        var _this = this;
        var welcomeSettings = Global.WELCOME_MESSAGE_SETTINGS;
        if (!this.welcomeMessages[moduleName] && !overideMessage) {
            this.moduleMessageQueue.push({ moduleName: moduleName, overideMessage: overideMessage });
            return;
        }
        var isAlertShown = false;
        var moduleMessage = overideMessage ? overideMessage : this.welcomeMessages[moduleName];
        if (welcomeSettings && welcomeSettings[moduleName] && moduleMessage) {
            isAlertShown = true;
            setTimeout(function () {
                if (moduleName === 'ShowUpdateNotification') {
                    _this.IsShowUpdateNotification = true;
                }
                else {
                    _this.moduleMessageDialogVisible = true;
                }
                _this.moduleMessage = overideMessage;
                _this.moduleName = moduleName;
                welcomeSettings[moduleName] = false;
                Global.WELCOME_MESSAGE_SETTINGS = welcomeSettings;
            });
            // Save back the updated setting.
            // Global.WELCOME_MESSAGE_SETTINGS = welcomeSettings;
        }
        isAlertShown = isAlertShown || this.showBetaMessage(moduleName);
        isAlertShown = isAlertShown || this.showNavigationMessage();
    };
    SettingService.prototype.showBetaMessage = function (moduleName) {
        var isAlertShown = false;
        var betaModuleSettings = Global.BETA_MODULE_MESSAGE_SETTINGS;
        if (betaModuleSettings && betaModuleSettings[moduleName] && this.betaModuleMessage) {
            isAlertShown = true;
            betaModuleSettings[moduleName] = false;
            this.modalService.alert(this.betaModuleTitle, this.betaModuleMessage);
            ////this.showFlipScreenHint(moduleName);
            // Save back the updated setting.
            Global.BETA_MODULE_MESSAGE_SETTINGS = betaModuleSettings;
        }
        return isAlertShown;
    };
    SettingService.prototype.onNavigationMessageDialogAction = function (action) {
        if (action === 'MoreHelp') {
            this.navigateToExternalLink(this.navigationStartUpTask.HelpLink);
        }
    };
    SettingService.prototype.showNavigationMessage = function () {
        var isAlertShown = false;
        if (!this.navigationStartUpTask) {
            return isAlertShown;
        }
        var task = this.navigationStartUpTask;
        if (task.ExternalLink) {
            if (task.GuideMessage) {
                isAlertShown = true;
                this.navigationMessage = task.GuideMessage;
                this.navigationMessageDialogVisible = true;
            }
            else {
                this.navigateToExternalLink(task.ExternalLink);
            }
        }
        else if (task.GuideMessage) {
            isAlertShown = true;
            this.navigationMessage = task.GuideMessage;
            this.navigationMessageDialogVisible = true;
        }
        this.navigationStartUpTask = undefined;
        return isAlertShown;
    };
    SettingService.prototype.navigateToExternalLink = function (link) {
        if (!this.isIOSMobileApp) {
            window.open(link);
        }
    };
    SettingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SettingService_Factory() { return new SettingService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.ModalService), i0.ɵɵinject(i3.BroadcastService)); }, token: SettingService, providedIn: "root" });
    return SettingService;
}());
export { SettingService };
