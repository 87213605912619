<app-dialog [overlayClose]="false" (action)="onDialogAction($event)" [(visible)]="visible" [customVisible]="true">
  <div class="Modal-header Modal-header--import" *ngIf="isExistingMapping">
    <div class="DialogHeaderForExistingSalarybatch">
      <strong>{{ 'CompanyDataImport.EditingImportTemplate' | translate }}</strong> &nbsp; {{ mappingName }}
    </div>
  </div>

  <div *ngIf="isExistingMapping && mappingDetail">
    <app-text-edit
      [label]="'CompanyDataImport.EditName' | translate"
      [type]="'text'"
      [required]="true"
      [requiredMessageKey]="'CompanyDataImport.ErrorsSummary_Required'"
      [disabled]="isButtonDisabled"
      [editMode]="true"
      [(value)]="mappingDetail.Name"
    >
    </app-text-edit>
  </div>

  <app-numeric-edit
    [editMode]="true"
    [(value)]="startIndex"
    [option]="option"
    [label]="'CreateDataImportTemplate.HeaderStartIndex' | translate"
    [disable]="isButtonDisabled"
    (valueChange)="onStartIndexChanged()"
  >
  </app-numeric-edit>

  <div class="u-leader1" *ngIf="importOptionsObject && importOptionsObject.length > 0">
    <div *ngFor="let option of importOptionsObject">
      <fieldset class="FormElement" *ngFor="let radioOption of option.Options">
        <div class="FormElement-control" *ngIf="option.DataType === 'Radio'">
          <div class="FormElement-static">
            <label class="FormElement-radio">
              <input
                type="radio"
                [disabled]="isButtonDisabled"
                name="{{ option.Name }}"
                [(ngModel)]="option.ControlValue"
                value="{{ radioOption.RadioValue }}"
                [ngClass]="option.ControlValue"
                (change)="onRadioOptionChange(option)"
              />
              <span class="FormElement-radioFaux"></span>
              <span class="FormElement-label">{{ radioOption.DisplayText }}</span>
            </label>
          </div>
        </div>
      </fieldset>
    </div>
  </div>

  <!--mapping grid-->
  <div class="FormElement">
    <div class="PreferencesView ImportMapping">
      <div class="ImportMappingTable FormElement-grid k-grid k-widget" *ngIf="importType === 5">
        <table resizeable mode="'BasicResizer'" class="Preferences-list">
          <thead>
            <tr>
              <th>{{ 'CompanyDataImport.YourFile' | translate }}</th>
              <th>{{ 'CompanyDataImport.SystemColumn' | translate: { SystemAlias: branding.SystemAlias } }}</th>
              <th class="ColumnType">{{ 'CompanyDataImport.ColumnType' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of userColumnsValues">
              <td>{{ item.Name }}</td>
              <td>
                <app-combobox-edit
                  [editMode]="!importMappingDataDataSource.data[item.Key].IsId"
                  [comboboxDataSource]="importMappingDataDataSource.data[item.Key].columnsTypeDropDownDataSource"
                  [disable]="isButtonDisabled"
                  [textField]="'Name'"
                  [idField]="'Id'"
                  [(value)]="importMappingDataDataSource.data[item.Key].columnsTypeValue"
                  (valueChange)="onComboboxChange(importMappingDataDataSource.data[item.Key].columnsTypeValue, item)"
                >
                </app-combobox-edit>
              </td>
              <td>
                <app-combobox-edit
                  *ngIf="importMappingDataDataSource.data[item.Key].intectColumnsDropDownDataSource.length > 0"
                  [editMode]="true"
                  [comboboxDataSource]="importMappingDataDataSource.data[item.Key].intectColumnsDropDownDataSource"
                  [disable]="isButtonDisabled"
                  [textField]="'Name'"
                  [idField]="'Id'"
                  [(value)]="importMappingDataDataSource.data[item.Key].IntectColumnsValue"
                  (valueChange)="
                    onIntectComboboxChange(importMappingDataDataSource.data[item.Key].IntectColumnsValue, item)
                  "
                >
                </app-combobox-edit>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="PreferencesView ImportMapping">
      <div class="ImportMappingTable FormElement-grid k-grid k-widget" *ngIf="importType !== 5">
        <table resizeable mode="'BasicResizer'" class="Preferences-list">
          <thead>
            <tr>
              <th>{{ 'CompanyDataImport.YourFile' | translate }}</th>
              <th>{{ 'CompanyDataImport.SystemColumn' | translate: { SystemAlias: branding.SystemAlias } }}</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let item of userColumnsValues">
              <td>{{ item.Name }}</td>
              <td>
                <app-combobox-edit
                  [editMode]="true"
                  [comboboxDataSource]="importMappingDataDataSource.data[item.Key].columnsTypeDropDownDataSource"
                  [textField]="'Name'"
                  [idField]="'Id'"
                  [(value)]="importMappingDataDataSource.data[item.Key].columnsTypeValue"
                  (valueChange)="onComboboxChange(importMappingDataDataSource.data[item.Key].columnsTypeValue, item)"
                >
                </app-combobox-edit>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--mapping grid-->
  </div>

  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Test'"></app-dialog-action>
  <app-dialog-action [type]="'ImportOnce'" [disabled]="sessionService.isSubmitting"></app-dialog-action>
  <app-dialog-action [type]="'SaveDefinition'" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" [(visible)]="alertDialogVisible" [leadingText]="alertMessage | translate">
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="successDialogVisible"
  (action)="closeDialogAndFireClosedEvent()"
  [leadingText]="'CompanyDataImport.SaveMappingTemplateSuccess' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-import-errors-dialog [importType]="importType" [importResult]="importResults" [testImport]="true">
</app-import-errors-dialog>

<app-import-errors-dialog
  [importType]="importType"
  [importResult]="importOneResults"
  (completeImportEvent)="onCompleteImportOneEvent()"
  [testImport]="false"
>
</app-import-errors-dialog>

<app-dialog (action)="onDialogActionWarning($event)" [(visible)]="replaceAllWarningVisible" [leadingText]="warningText">
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Understood'"></app-dialog-action>
</app-dialog>
