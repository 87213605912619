import { EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';

export abstract class FormComponentBase implements OnDestroy {
  private editModeValue = false;
  @Input()
  public get editMode(): boolean {
    return this.editModeValue;
  }
  public set editMode(value: boolean) {
    if (this.editModeValue !== value) {
      this.editModeValue = value;
      this.editModeChange.emit(value);
    }
  }
  @Output() public editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public childIsDirty: boolean[] = [];
  private isDirtyValue = false;
  @Input()
  public get isDirty(): boolean {
    return this.isDirtyValue;
  }
  public set isDirty(value: boolean) {
    if (this.isDirtyValue !== value) {
      this.isDirtyValue = value;
      if (!value && !this.editMode) {
        setTimeout(() => {
          this.childIsDirty.forEach((c: boolean) => (c = false));
        });
      }

      this.isDirtyChange.emit(value);
    }
  }
  @Output() public isDirtyChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public childIsValid: boolean[] = [];
  private isValidValue = true;
  @Input()
  public get isValid(): boolean {
    return this.isValidValue;
  }
  public set isValid(value: boolean) {
    if (this.isValidValue !== value) {
      this.isValidValue = value;
      this.isValidChange.emit(value);
    }
  }
  @Output() public isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public valueChange: EventEmitter<void> = new EventEmitter<void>();

  public onChange(): void {
    // setTimeout(() => {
    //   this.isValid = this.valid();
    //   this.isDirty = this.hasChanges();
    //   this.valueChange.emit();
    // });
    this.isValid = this.valid();
    this.isDirty = this.hasChanges();
    this.valueChange.emit();
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  protected valid(): boolean {
    return this.childIsValid.every((c: boolean) => c);
  }

  protected hasChanges(): boolean {
    return this.childIsDirty.some((c: boolean) => c);
  }
}
