<form>
  <fieldset class="Form--horizontal">
    <legend class="Card-title">{{ 'EmployeeEmployment.AdditionalVacationDays' | translate }}</legend>

    <app-module-check [moduleId]="4">
      <div *ngIf="editMode || (!editMode && !isEmptymodel)">
        <div class="additional-row">
          <div [ngClass]="{ 'first-element-non-edit': !editMode }" class="first-element">
            <span class="non-edit-label" *ngIf="!editMode">
              {{
                'EmployeeEmployment.AdditionalEarningFixedAmount'
                  | translate: { x: numberToString(SectionData.EarningFixedAmount) }
              }}
            </span>

            <div class="earning-fixed-amount-edit" *ngIf="editMode">
              <span class="first-label" *ngIf="ArrayAdditionalEarningFixedAmountTranslate[0]">
                {{ ArrayAdditionalEarningFixedAmountTranslate[0] }}
              </span>
              <app-numeric-edit
                [ngClass]="{ 'non-wrap-text': editMode }"
                [editMode]="editMode"
                [(value)]="SectionData.EarningFixedAmount"
                [disable]="!isEnableEditSection"
                [(isDirty)]="childIsDirty[0]"
                (valueChange)="createSafeModel(SectionData, true)"
              ></app-numeric-edit>
              <span class="last-label" *ngIf="ArrayAdditionalEarningFixedAmountTranslate[1]">
                {{ ArrayAdditionalEarningFixedAmountTranslate[1] }}
              </span>
            </div>
          </div>

          <div class="second-element Amount-principle-firstState">
            <app-radio-edit
              (valueChange)="EarningFixedAmountPrincipleIdRadioChange()"
              [editMode]="editMode"
              [vertical]="true"
              [(value)]="EarningFixedAmountPrincipleIdRadioValue"
              [(isDirty)]="childIsDirty[1]"
            >
              <app-radio-edit-option [value]="1" [label]="getAdditionalEarningFixedAmountPrincipleIdOpyion1Label">
              </app-radio-edit-option>
              <app-radio-edit-option
                [value]="2"
                [label]="'EmployeeEmployment.AdditionalEarningFixedAmountPrincipleIdOpyion2' | translate"
              >
              </app-radio-edit-option>
            </app-radio-edit>

            <app-combobox-edit
              *ngIf="editMode"
              [editMode]="editMode"
              [comboboxDataSource]="datasourceEarningFixedAmountPrinciple"
              [(value)]="SectionData.EarningFixedAmountPrincipleId"
              [textField]="'Name'"
              [idField]="'Id'"
              [disable]="EarningFixedAmountPrincipleIdRadioValue !== 1"
              (valueChange)="createSafeModel(SectionData, true)"
              [disable]="!isEnableEditSection"
              [(isDirty)]="childIsDirty[1]"
            ></app-combobox-edit>
          </div>
        </div>

        <app-combobox-edit
          *ngIf="EarningFixedAmountPrincipleIdRadioValue === 1"
          [label]="'EmployeeEmployment.AdditionalCustomBehaviorId' | translate"
          [editMode]="editMode"
          [comboboxDataSource]="staticDataService.ExtraVacationHoursEarningBehaviors | async"
          [(value)]="SectionData.CustomBehaviorId"
          [textField]="'Name'"
          [idField]="'Id'"
          (valueChange)="createSafeModel(SectionData, true)"
          [disable]="!isEnableEditSection"
          [(isDirty)]="childIsDirty[2]"
          [nullValueLabel]="'DropdownList.None' | translate"
        ></app-combobox-edit>

        <app-numeric-edit
          [label]="'EmployeeEmployment.AdditionalSeniorityRequirementMonths' | translate"
          [editMode]="editMode"
          [(value)]="SectionData.SeniorityRequirementMonths"
          [disable]="!isEnableEditSection"
          [(isDirty)]="childIsDirty[3]"
          (valueChange)="createSafeModel(SectionData, true)"
          [option]="intOption"
        >
        </app-numeric-edit>

        <app-combobox-edit
          [editMode]="editMode"
          [label]="'EmployeeEmployment.CalculationBase' | translate"
          [comboboxDataSource]="vacationData"
          [(value)]="SectionData.BaseCalculationMethodId"
          [(isDirty)]="childIsDirty[4]"
          [textField]="'Name'"
          [idField]="'Id'"
          (valueChange)="createSafeModel(SectionData, true)"
          [disable]="!isEnableEditSection"
        >
        </app-combobox-edit>

        <div [ngClass]="{ 'nondiamond-inner-wrap': !isEnableEditSection && !disableBySecondaryIncomeType }"></div>
      </div>

      <p *ngIf="!editMode && isEmptymodel && !allowEdit">
        {{ 'EmployeeEmployment.AdditionalNotUsed' | translate }}
      </p>

      <app-button
        *ngIf="!editMode && isEmptymodel && allowEdit"
        buttonClass="Button--card"
        (clickEvent)="changeModeAndEnableSection()"
        [isDisabled]="disableBySecondaryIncomeType"
        [label]="'EmployeeEmployment.ClickHereToEnable' | translate"
      ></app-button>
    </app-module-check>
  </fieldset>
</form>

<app-button
  *ngIf="!isEnableEditSection"
  buttonClass="Button--card"
  (clickEvent)="EnableSection()"
  [isDisabled]="disableBySecondaryIncomeType"
  [label]="'EmployeeEmployment.ClickHereToEnable' | translate"
></app-button>

<div
  [ngClass]="{ 'nondiamond-inner-wrap': disableBySecondaryIncomeType }"
  kendoTooltip
  [title]="
    disableBySecondaryIncomeType ? ('EmployeeEmployment.ToolTipWhenDisabledBecauseOfIncomeType5' | translate) : ''
  "
></div>
