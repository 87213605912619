/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../custom-controls/picture-edit/picture-edit.component.ngfactory";
import * as i2 from "../../../custom-controls/picture-edit/picture-edit.component";
import * as i3 from "../../../services/session/session.service";
import * as i4 from "../../../services/api/static-data.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/common";
import * as i7 from "./employee-avatar.component";
import * as i8 from "../../../services/employee.service";
var styles_EmployeeAvatarComponent = [];
var RenderType_EmployeeAvatarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmployeeAvatarComponent, data: {} });
export { RenderType_EmployeeAvatarComponent as RenderType_EmployeeAvatarComponent };
export function View_EmployeeAvatarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-picture-edit", [["style", "zoom: 0.8"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.employeeService.updateUserImage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_PictureEditComponent_0, i1.RenderType_PictureEditComponent)), i0.ɵdid(1, 49152, null, 0, i2.PictureEditComponent, [i3.SessionService, i4.StaticDataService], { editMode: [0, "editMode"], label: [1, "label"], changeText: [2, "changeText"], takePhotoText: [3, "takePhotoText"], isAvatar: [4, "isAvatar"], imageSource: [5, "imageSource"] }, { change: "change" }), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), i0.ɵpid(131072, i6.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editMode; var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 2).transform("Employee.ChangePicture")); var currVal_2 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 3).transform("Employee.ChangePicture")); var currVal_3 = i0.ɵunv(_v, 1, 3, i0.ɵnov(_v, 4).transform("Employee.TakePhoto")); var currVal_4 = true; var currVal_5 = i0.ɵunv(_v, 1, 5, i0.ɵnov(_v, 5).transform(_co.employeeService.userImage)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_EmployeeAvatarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-employee-avatar", [], null, null, null, View_EmployeeAvatarComponent_0, RenderType_EmployeeAvatarComponent)), i0.ɵdid(1, 49152, null, 0, i7.EmployeeAvatarComponent, [i8.EmployeeService], null, null)], null, null); }
var EmployeeAvatarComponentNgFactory = i0.ɵccf("app-employee-avatar", i7.EmployeeAvatarComponent, View_EmployeeAvatarComponent_Host_0, { editMode: "editMode" }, {}, []);
export { EmployeeAvatarComponentNgFactory as EmployeeAvatarComponentNgFactory };
