<form>
  <fieldset class="Form--horizontal">
    <legend class="Card-title">
      {{ SectionName | translate }}
    </legend>
    <app-module-check [moduleId]="4">
      <div *ngIf="editMode || (!editMode && !isEmptymodel)">
        <app-percentage-edit
          [label]="'EmployeeEmployment.EarningPercentage' | translate"
          [editMode]="editMode"
          [(isDirty)]="childIsDirty[0]"
          [allowDecimal]="true"
          [(value)]="SectionData.EarningPercentage"
          (valueChange)="createSafeModel(SectionData, true)"
          [disable]="!isEnableEditSection"
          [decimals]="4"
        >
        </app-percentage-edit>

        <app-percentage-edit
          [label]="'EmployeeEmployment.PaidOutContinuously' | translate"
          [editMode]="editMode"
          [(isDirty)]="childIsDirty[1]"
          [allowDecimal]="true"
          [(value)]="SectionData.PayoutPercentage"
          (valueChange)="createSafeModel(SectionData, true)"
          [labelTooltip]="'EmployeeEmployment.PaidOutContinuouslyTooltip' | translate"
          [disable]="!isEnableEditSection"
          [decimals]="4"
        >
        </app-percentage-edit>

        <app-combobox-edit
          [editMode]="editMode"
          [label]="'EmployeeEmployment.CalculationBase' | translate"
          [comboboxDataSource]="flexData"
          [(value)]="SectionData.BaseCalculationMethodId"
          [(isDirty)]="childIsDirty[2]"
          [textField]="'Name'"
          [idField]="'Id'"
          (valueChange)="createSafeModel(SectionData, true)"
          [disable]="!isEnableEditSection"
        ></app-combobox-edit>

        <app-percentage-edit
          [label]="'EmployeeEmployment.AllottedForPensionPlan' | translate"
          [editMode]="editMode"
          [(isDirty)]="childIsDirty[3]"
          [allowDecimal]="true"
          [(value)]="SectionData.PensionSharePercentage"
          (valueChange)="createSafeModel(SectionData, true)"
          [labelTooltip]="'EmployeeEmployment.PensionSharePercentageTooltip' | translate"
          [disable]="!isEnableEditSection"
          [decimals]="4"
        ></app-percentage-edit>

        <app-percentage-edit
          [label]="'EmployeeEmployment.OffDaysConversionShare' | translate"
          [editMode]="editMode"
          [allowDecimal]="true"
          [(isDirty)]="childIsDirty[4]"
          [(value)]="SectionData.DaysConversionPercentage"
          [required]="false"
          (valueChange)="createSafeModel(SectionData, true)"
          [labelTooltip]="'EmployeeEmployment.OffDaysConversionShareTooltip' | translate"
          [disable]="!isEnableEditSection"
          [decimals]="4"
        >
        </app-percentage-edit>

        <app-numeric-edit
          [label]="'EmployeeEmployment.DaysEarnedPerSalaryPeriod' | translate"
          [editMode]="editMode"
          [(value)]="SectionData.DaysEarnedPerPayslip"
          [(isDirty)]="childIsDirty[5]"
          [option]="NumbericTextBoxFFHoursEarnedPerYearOptions"
          (valueChange)="createSafeModel(SectionData, true)"
          [disable]="!isEnableEditSection"
        ></app-numeric-edit>
        <div [ngClass]="{ 'nondiamond-inner-wrap': !isEnableEditSection && !disableBySecondaryIncomeType }"></div>
      </div>

      <p *ngIf="!editMode && isEmptymodel && !allowEdit">
        {{ 'EmployeeEmployment.AdditionalNotUsed' | translate }}
      </p>

      <app-button
        *ngIf="!editMode && isEmptymodel && allowEdit"
        buttonClass="Button--card"
        (clickEvent)="changeModeAndEnableSection()"
        [isDisabled]="disableBySecondaryIncomeType"
        [label]="'EmployeeEmployment.ClickHereToEnable' | translate"
      ></app-button>
    </app-module-check>
  </fieldset>
</form>

<app-button
  *ngIf="!isEnableEditSection"
  buttonClass="Button--card"
  (clickEvent)="EnableSection()"
  [isDisabled]="disableBySecondaryIncomeType"
  [label]="'EmployeeEmployment.ClickHereToEnable' | translate"
></app-button>

<div
  [ngClass]="{ 'nondiamond-inner-wrap': disableBySecondaryIncomeType }"
  kendoTooltip
  [title]="
    disableBySecondaryIncomeType ? ('EmployeeEmployment.ToolTipWhenDisabledBecauseOfIncomeType5' | translate) : ''
  "
></div>
