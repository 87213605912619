import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../common/constants';
import { Global } from '../../../common/global';
import { GridHelper } from '../../../common/grid-helper';
import { SalaryBatchStatusEnum } from '../../../model/enum';
import { ExtendSalaryPeriod } from '../../../model/extend-salary-period';
import {
  ISalaryBatch,
  ISalaryBatchRecord,
  ISalaryCycle,
  ISalaryPeriod,
  ISimpleKeyValuePair,
  ISimpleSalaryStatement
} from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { SessionService } from '../../../services/session/session.service';
import { CompanySalaryBatchesChildFormEntityContext } from '../company-salary-batches-child-form-entity-context';
import { CompanySalaryBatchService } from '../company-salary-batches.service';
import { SalaryBatchViewModel } from '../salary-batch-view-model';

@Component({
  selector: 'app-salary-batch-details',
  templateUrl: './salary-batch-details.component.html',
  styleUrls: ['./salary-batch-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SalaryBatchDetailsComponent implements OnInit, OnDestroy {
  @Output() public reloadSalaryBatches: EventEmitter<void> = new EventEmitter<void>();
  @Output() public closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() public entityContextChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public selectedEmployeeIdsChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public triggerReloadChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public cycleChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() public get salaryCycles(): ISalaryCycle[] {
    return this.salaryCyclesValue;
  }
  public set salaryCycles(value: ISalaryCycle[]) {
    const isLoadDueToValueChanged: boolean =
      (!this.salaryCyclesValue || this.salaryCyclesValue.length === 0) && value && value.length > 0;
    this.salaryCyclesValue = value;
    if (isLoadDueToValueChanged) {
      this.loadDetailsData();
    }
  }

  @Input() public get salaryBatch(): SalaryBatchViewModel {
    return this.selectedSalaryBatchValue;
  }
  public set salaryBatch(value: SalaryBatchViewModel) {
    if (this.selectedSalaryBatchValue !== value) {
      this.selectedSalaryBatchValue = value;
      if (value && value.Message) {
        value.Message = this.decodeEntities(value.Message);
      }
      this.loadDetailsData();
    } else if (!value) {
      this.loadDetailsData();
    }
  }

  @Input()
  public get entityContext(): CompanySalaryBatchesChildFormEntityContext {
    return this.entityContextValue ? this.entityContextValue : ({} as any);
  }
  public set entityContext(value: CompanySalaryBatchesChildFormEntityContext) {
    if (this.entityContextValue !== value) {
      this.entityContextValue = value;
      this.entityContextChange.emit(value);
    }
  }

  public entityContextMobileChange() {
    if (this.companySalaryBatchService.IsMobileOrHybridApp) {
      this.entityContextChange.emit(this.entityContextValue);
    }
  }

  @Input() public salaryStatements: ISimpleSalaryStatement[];

  private selectedEmployeeIdsValue: number[];
  @Input() public get selectedEmployeeIds(): number[] {
    return this.selectedEmployeeIdsValue;
  }
  public set selectedEmployeeIds(value: number[]) {
    if (this.selectedEmployeeIdsValue !== value) {
      this.selectedEmployeeIdsValue = value;
      this.selectedEmployeeIdsChange.emit(value);
    }
  }

  @Input() public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      if (value) {
        this.loadDetailsData();
      }
    }
  }

  private triggerReloadValue = false;
  @Input() public get triggerReload(): boolean {
    return this.triggerReloadValue;
  }

  public set triggerReload(value: boolean) {
    if (value !== this.triggerReloadValue) {
      this.loadDetailsData();
      this.triggerReloadChange.emit(this.triggerReloadValue);
    }
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  public get IsExistingSalaryBatch(): boolean {
    return !(this.entityContext.BatchId === undefined || this.entityContext.BatchId < 1);
  }

  public get IsNewBatchWithEmployeeSelection(): boolean {
    return this.entityContext.BatchId === -1 && this.entityContext.EmployeeCategory === 2;
  }

  public get showMultiPurposeOffDayCompensation(): boolean {
    const enableFlexBalanceSetting: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
      'Company.EnableFlexBalance'
    );
    return enableFlexBalanceSetting ? enableFlexBalanceSetting.Value === 'true' : false;
  }

  public get isAllowOverride(): boolean {
    const pref = this.sessionService.getCompanyPreference('SalaryPeriod.AllowOverride');
    return pref && pref.Value === 'true';
  }

  public get isShowPaymentDate(): boolean {
    return this.entityContext.BatchId === -1
      ? this.entityContext.EmployeeCategory !== 3
      : this.salaryBatch && this.salaryBatch.Id === this.entityContext.BatchId
      ? !this.salaryBatch.EIncomeZeroReport
      : false;
  }

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public get IsDenmarkCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
  }

  public get PayoutAllFlexLabel(): string {
    if (this.IsGreenlandCompany) {
      return 'CompanySalaryBatches.PayoutOffDayBalances GreenlandCompany';
    }
    return 'CompanySalaryBatches.PayoutOffDayBalances';
  }

  public recalculateSingleUserEmploymentId: number;
  public session: any = Global.SESSION;
  public pickEmployeeDialogVisible = false;

  private visibleValue: boolean;
  private salaryCyclesValue: ISalaryCycle[];
  private selectedSalaryBatchValue: SalaryBatchViewModel;
  private entityContextValue: CompanySalaryBatchesChildFormEntityContext;
  private loadingPeriods = false;
  private responsePaymentDate: Date;

  constructor(
    private dataService: DataService,
    public sessionService: SessionService,
    public companySalaryBatchService: CompanySalaryBatchService
  ) {}

  public ngOnInit(): void {}

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private prepareEntityContext(batch: SalaryBatchViewModel): void {
    this.loadingPeriods = true;
    if (batch && Array.isArray(batch) === false) {
      const updateContext: CompanySalaryBatchesChildFormEntityContext = new CompanySalaryBatchesChildFormEntityContext(
        this.sessionService
      );
      updateContext.BatchId = batch.Id;
      updateContext.StatusName = batch.StatusName;

      updateContext.ChangeDate = batch.Rejected
        ? batch.Rejected
        : batch.Finalized
        ? batch.Finalized
        : batch.Approved
        ? batch.Approved
        : batch.Created;

      updateContext.SalaryBatchNumber = batch.SalaryBatchNumber;
      updateContext.PeriodId = batch.SalaryPeriodId;
      updateContext.IsDraft = batch.StatusId === SalaryBatchStatusEnum.Draft;
      updateContext.IsPendingForApproval = batch.StatusId === SalaryBatchStatusEnum.ForApproval;
      updateContext.IsFinalized = batch.StatusId === SalaryBatchStatusEnum.Finalized;
      updateContext.isFinalizing = batch.StatusId === SalaryBatchStatusEnum.Finalizing;
      updateContext.IsCancelled = batch.StatusId === SalaryBatchStatusEnum.Cancelled;
      updateContext.IsLocked = batch.StatusId === SalaryBatchStatusEnum.ForApproval;
      updateContext.IsPreliminary = batch.StatusId === SalaryBatchStatusEnum.ForApproval;
      updateContext.PaymentDate = this.sessionService.parseDate(batch.PayoutDate.toString());
      updateContext.PayoutAllFlex = batch.PayoutAllFlex;
      updateContext.PayoutNewFlex = batch.PayoutNewFlex;
      updateContext.IsRecalcBeforeFinalize = !(updateContext.IsLocked || updateContext.IsPreliminary);
      updateContext.Message = batch.Message;
      updateContext.HasErrors = batch.HasErrors;
      updateContext.HasWarnings = batch.HasWarnings;
      updateContext.CycleId = batch.SalaryCycleId;
      updateContext.EmployeeCategory = 1;
      updateContext.HasBatch = false;

      updateContext.FromDateOverride = batch.FromDateOverride;
      updateContext.ToDateOverride = batch.ToDateOverride;

      this.entityContext = updateContext;
      this.onEmployeeCategoryChanged();
    } else {
      if (/*!this.salaryBatchesContext || */ !this.salaryCycles || this.salaryCycles.length === 0) {
        return;
      }

      const newContext: CompanySalaryBatchesChildFormEntityContext = new CompanySalaryBatchesChildFormEntityContext(
        this.sessionService
      );
      newContext.BatchId = -1;
      newContext.StatusName = undefined;
      newContext.ChangeDate = undefined;
      newContext.IsDraft = true;
      newContext.CycleId = this.salaryCycles[0].Id;
      newContext.PeriodId = undefined;
      newContext.EmployeeCategory = 1;
      newContext.PayoutAllFlex = false;
      newContext.HasBatch = false;
      this.entityContext = newContext;
      this.onEmployeeCategoryChanged();
    }
  }

  public isPreparingContent: boolean;
  private loadDetailsData(): void {
    this.prepareEntityContext(this.salaryBatch);
    if (!this.visible) {
      return;
    }

    this.isPreparingContent = true;
    if (this.entityContextValue && this.entityContextValue.CycleId) {
      this.getSalaryBatchesPeriods(this.entityContextValue.CycleId);
      // this.getSalaryBatchUserEmployments();

      setTimeout(() => {
        this.entityContext.IsDirty = false;
      });
    }

    setTimeout(() => {
      this.isPreparingContent = false;
    }, 1000);
  }

  public onChildWindowSalaryCycleChange(): void {
    const cycleId: number = this.getChildWindowCycleId();
    if (cycleId) {
      this.loadingPeriods = true;
      this.getSalaryBatchesPeriods(cycleId);

      this.cycleChange.emit();
      this.entityContextMobileChange();
    }
  }

  public onSalaryPeriodChanged(value: number): void {
    if (!this.IsExistingSalaryBatch) {
      this.setPaymentDateDueToPeriodChange();
    }
    this.checkPeriod();
    this.entityContextMobileChange();
  }

  public onEmployeeCategoryChanged(category?: number): void {
    if (this.IsNewBatchWithEmployeeSelection) {
      this.companySalaryBatchService.pickEmployeeDialogVisible = true;
    }
    this.onEmployeesGridDataSaveChanges();
  }

  public onEmployeesGridDataSaveChanges(event?: any): void {
    switch (this.entityContext.EmployeeCategory) {
      case 1: // All employeess
        this.selectedEmployeeIds = null;
        break;
      case 3: // No employees
        this.selectedEmployeeIds = [];
        break;
      case 2:
        // Selected employees
        break;
      default:
        break;
    }
  }

  public onRecalculateSingleEmployeeDone(): void {
    if (this.salaryBatch && this.recalculateSingleUserEmploymentId) {
      this.dataService
        .SalaryBatches_RecalculateSalaryBatchSingleEmployment(
          this.salaryBatch.Id,
          this.recalculateSingleUserEmploymentId
        )
        .subscribe((salaryBatch: ISalaryBatchRecord[]) => {
          // re caculate batch statement
          this.companySalaryBatchService.loadSalaryTypeTotals(this.salaryBatch.Id);
          this.companySalaryBatchService.loadUnitTotals(this.salaryBatch.Id);
          this.companySalaryBatchService.loadSalaryStatements(this.salaryBatch.Id);

          this.reloadSalaryBatches.emit();
          this.closeDialog.emit();
        });
    }
  }

  private getChildWindowCycleId(): number {
    let cycleId: number;
    const dataItem: any = this.salaryCycles.find((cycle: ISalaryCycle) => cycle.Id === this.entityContext.CycleId);
    if (!dataItem) {
      cycleId = this.entityContext.CycleId =
        this.salaryCycles && this.salaryCycles.length > 0 ? this.salaryCycles[0].Id : undefined;
    } else {
      cycleId = dataItem.Id;
    }

    return cycleId;
  }

  private getSalaryBatchesPeriods(cycleId: number): void {
    this.dataService
      .SalaryBatches_GetSalaryPeriods(cycleId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((salaryPeriods: ISalaryPeriod[]) => {
        if (salaryPeriods) {
          if (salaryPeriods.length > 0) {
            this.loadingPeriods = false;
            const defaultPeriod: ISalaryPeriod = salaryPeriods[0];
            const dataSource: ExtendSalaryPeriod[] = [];

            const friendlyNamePreference: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
              'SalaryPeriods.FriendlyNames'
            );
            const isUserFriendlyname: boolean =
              friendlyNamePreference && friendlyNamePreference.Value === 'true' ? true : false;

            salaryPeriods = salaryPeriods.sort((data1: ISalaryPeriod, data2: ISalaryPeriod) =>
              GridHelper.sortByDateValue(data1.StartDate, data2.StartDate)
            );
            if (salaryPeriods) {
              salaryPeriods.forEach((salaryPeriod: ISalaryPeriod, key: number): void => {
                const startDate: string = this.sessionService.toString(
                  salaryPeriod.StartDate ? new Date(salaryPeriod.StartDate) : undefined
                );
                const endDate: string = this.sessionService.toString(
                  salaryPeriod.EndDate ? new Date(salaryPeriod.EndDate) : undefined
                );
                const extendSalaryPeriod = new ExtendSalaryPeriod();
                extendSalaryPeriod.Id = salaryPeriod.Id;
                extendSalaryPeriod.StartDate = salaryPeriod.StartDate;
                extendSalaryPeriod.EndDate = salaryPeriod.EndDate;
                extendSalaryPeriod.SuggestedPayoutDate = salaryPeriod.SuggestedPayoutDate;
                extendSalaryPeriod.Period = startDate + ' - ' + endDate;

                if (isUserFriendlyname) {
                  extendSalaryPeriod.FriendlyName = ' ' + salaryPeriod.FriendlyName;
                } else {
                  extendSalaryPeriod.FriendlyName = startDate + ' - ' + endDate;
                }

                dataSource[key] = extendSalaryPeriod;
              });
            }

            this.entityContext.Periods = dataSource;

            if (this.entityContext && this.entityContext.BatchId && this.entityContext.BatchId < 0) {
              this.entityContext.PeriodId = defaultPeriod.Id;
            }

            if (this.entityContext.BatchId === undefined || this.entityContext.BatchId < 1) {
              this.getSalaryBatchesPeriodsSuggest(this.entityContext.CycleId);
            }
          } else {
            this.entityContext.Periods = [];
            this.entityContext.PaymentDate = undefined;
          }
        }
      });
  }

  private getSalaryBatchesPeriodsSuggest(cycleId: number): void {
    const batchId: number = this.entityContext ? this.entityContext.BatchId : undefined;
    this.dataService.SalaryBatches_SuggestSalaryPeriod(cycleId).subscribe((salaryPeriod: ISalaryPeriod): void => {
      if (cycleId === this.entityContext.CycleId && batchId === this.entityContext.BatchId) {
        if (
          this.entityContext.Periods &&
          this.entityContext.Periods.length > 0 &&
          !this.entityContext.Periods.find((item: ExtendSalaryPeriod) => item.Id === salaryPeriod.Id)
        ) {
          this.entityContext.PeriodId = this.entityContext.Periods[0].Id;
          this.entityContext.PaymentDate = this.entityContext.Periods[0].SuggestedPayoutDate
            ? this.entityContext.Periods[0].SuggestedPayoutDate
            : this.entityContext.Periods[0].EndDate;
          return;
        }
        this.entityContext.PeriodId = salaryPeriod.Id;
        this.entityContext.PaymentDate = salaryPeriod.SuggestedPayoutDate
          ? salaryPeriod.SuggestedPayoutDate
          : salaryPeriod.EndDate;
      } else {
        // default for period control
        const periodItem: ExtendSalaryPeriod = this.entityContext.Periods
          ? this.entityContext.Periods.find((period: ExtendSalaryPeriod) => period.Id === this.entityContext.PeriodId)
          : undefined;
        if (!periodItem) {
          this.entityContext.PeriodId =
            this.entityContext.Periods.length > 0 ? this.entityContext.Periods[0].Id : undefined;
        }
      }
    });
  }

  private checkPeriod(): void {
    const selectedSalaryBatch: ISalaryBatch = this.salaryBatch;
    if (selectedSalaryBatch && this.entityContext.PeriodId === selectedSalaryBatch.SalaryPeriodId) {
      this.entityContext.HasBatch = false;
    } else {
      if (this.entityContext.PeriodId) {
        this.dataService
          .SalaryBatches_SalaryPeriodHasBatches(this.entityContext.PeriodId)
          .subscribe((hasBatch: boolean) => (this.entityContext.HasBatch = hasBatch));
      }
    }
  }

  private setPaymentDateDueToPeriodChange(): void {
    const changedID: number = this.entityContext.PeriodId;
    const periods: ExtendSalaryPeriod[] = this.entityContext.Periods;
    if (periods) {
      periods.forEach((salaryPeriod: ExtendSalaryPeriod, key: number): void => {
        if (periods[key].Id === changedID) {
          this.entityContext.PaymentDate = periods[key].SuggestedPayoutDate;
        }
      });
      if (this.responsePaymentDate) {
        this.entityContext.PaymentDate = this.responsePaymentDate;
        this.responsePaymentDate = undefined;
      }
    }
  }

  private decodeEntities(encodedString: string): string {
    const translatere: RegExp = /&(nbsp|amp|quot|lt|gt);/g;
    const translate: any = {
      nbsp: ' ',
      amp: '&',
      quot: '"',
      lt: '<',
      gt: '>'
    };
    return encodedString
      .replace(translatere, (match: any, entity: any): string => {
        return translate[entity];
      })
      .replace(/&#(\d+);/gi, (match: any, numStr: any): string => {
        const num: number = parseInt(numStr, 10);
        return String.fromCharCode(num);
      });
  }
}
