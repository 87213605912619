import {
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as srcDoc from 'srcdoc-polyfill';
import { Browser } from '../../common/browser';
import { SessionService } from '../../services/session/session.service';
import { DialogActionComponent } from './dialog-action.component';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html'
})
export class DialogComponent {
  @Input() public title: string;
  @Input() public minWidth = 0;
  @Input() public width: number;
  @Input() public height: number;
  @Input() public isAvatar: boolean;
  @Input() public customVisible: boolean;
  @Input() public overlayClose = true;
  @Input() public isOverlayKeydown = false;
  @Input() public isHideWhenSessionExpired = true;
  @Input() public disableCloseButtonTitle = false;

  public get isCordovaApp(): boolean {
    return this.sessionService.browser.isHybridApp;
  }
  public get isEdge(): boolean {
    return this.sessionService.browser.isEdge;
  }

  public leadingTextValue: SafeHtml;
  @Input()
  public get leadingText(): string {
    return this.leadingTextValue.toString();
  }
  public set leadingText(value: string) {
    this.leadingTextValue = this.sanitizer.bypassSecurityTrustHtml(value);
  }

  private visibleValue = false;
  @Input() public mustChangePassword = false;
  @Input()
  public get visible(): boolean {
    if (this.isHideWhenSessionExpired && this.sessionService.checkIsAliveError && !this.mustChangePassword) {
      return false;
    }
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      if (value === false) {
        if (this.sessionService.browser.isHybridApp) {
          window.screen.orientation.lock('portrait');
        }
      }

      this.visibleValue = value;
      this.contentLoaded = false;
      this.visibleChange.emit(value);
      if (value) {
        this.registerEvents();
      }

      if (value) {
        setTimeout(() => {
          if (this.disableCloseButtonTitle) {
            if (this.buttonArea && this.buttonArea.el && this.buttonArea.el.nativeElement) {
              const elRoot: Element = this.buttonArea.el.nativeElement;
              const elPrimary: Element = elRoot.getElementsByClassName('Button--primary')[0];
              if (elPrimary) {
                (elPrimary as any).remove();
              }
              const elDestructive: Element = elRoot.getElementsByClassName('Button--destructive')[0];
              if (elDestructive) {
                (elDestructive as any).remove();
              }
              const elSeconDary: Element = elRoot.getElementsByClassName('Button--secondary')[0];
              if (elSeconDary) {
                (elSeconDary as any).remove();
              }
            }
          }
          const el: any = document.getElementsByTagName('kendo-dialog');
          if (el) {
            Array.prototype.forEach.call(el, (model: any) => {
              setTimeout(() => {
                const el1: any = model
                  .getElementsByTagName('div')[1]
                  .getElementsByTagName('kendo-dialog-titlebar')[0]
                  .getElementsByTagName('div')[1]
                  .getElementsByTagName('a')[0];
                if (el1) {
                  if (this.disableCloseButtonTitle) {
                    el1.remove();
                    return;
                  }
                  el1.addEventListener('click', () => {
                    this.visible = false;
                    this.action.emit('close');
                  });
                }
              });
            });
          }
          this.onDefaultFocusbutton();
        });
      }
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public action: EventEmitter<string> = new EventEmitter<string>();

  public safeContent: SafeHtml;

  @ViewChild('iframeDialog', { static: false }) private iframeDialog: ElementRef;
  @ViewChild('iframeInDialog', { static: false }) private iframe: ElementRef;
  @ViewChild('buttonArea', { static: false }) private buttonArea: any;

  public onDefaultFocusbutton(): void {
    setTimeout(() => {
      if (this.buttonArea && this.buttonArea.el && this.buttonArea.el.nativeElement) {
        const elRoot: Element = this.buttonArea.el.nativeElement;
        const elPrimary: Element = elRoot.getElementsByClassName('Button--primary')[0];
        if (elPrimary) {
          (elPrimary as any).focus();
          return;
        }
        const elDestructive: Element = elRoot.getElementsByClassName('Button--destructive')[0];
        if (elDestructive) {
          (elDestructive as any).focus();
          return;
        }
        const elSeconDary: Element = elRoot.getElementsByClassName('Button--secondary')[0];
        if (elSeconDary) {
          (elSeconDary as any).focus();
          return;
        }
      }
    });
  }

  private iframeContentValue: string;
  @Input() public set iframeContent(value: string) {
    if (this.iframeContentValue !== value) {
      this.iframeContentValue = value;
      this.contentLoaded = false;
      this.safeContent = this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }

  @ContentChildren(DialogActionComponent) private buttonElements: QueryList<DialogActionComponent>;
  public actions: DialogActionComponent[];

  public get buttons(): DialogActionComponent[] {
    if (this.actions && this.actions.length > 0) {
      return this.actions;
    }

    return this.buttonElements.toArray();
  }

  constructor(
    private sanitizer: DomSanitizer,
    private sessionService: SessionService,
    private elementRef: ElementRef
  ) {}

  private registerEvents(): void {
    setTimeout(() => {
      const overlayElements: any = this.elementRef.nativeElement.querySelectorAll('.k-overlay');
      if (overlayElements && overlayElements.length > 0) {
        overlayElements[0].addEventListener('click', () => {
          if (this.overlayClose) {
            this.onClose();
          }
        });
      }

      const primaryButton: any = this.elementRef.nativeElement.querySelectorAll(
        '[ng-reflect-ng-class=Button--destructive]'
      );
      if (primaryButton && primaryButton.length > 0) {
        primaryButton[0].focus();
      }
    });
  }

  public onClick(action: string, close: boolean): void {
    if (!this.customVisible) {
      this.visible = !close;
    }
    this.action.emit(action);
  }

  public onClose(event?: any): void {
    if (this.overlayClose) {
      this.visible = false;
      this.action.emit('close');
    } else if (this.isOverlayKeydown) {
      this.visible = false;
      this.action.emit('close');
    }
  }

  private contentLoaded: boolean;
  public iframeLoaded(): void {
    if (this.isEdge && this.iframeContentValue && !this.contentLoaded) {
      // https://stackoverflow.com/questions/42257549/angular-2-srcdoc-polyfill-doesnt-work
      srcDoc.set(this.iframe.nativeElement, this.iframeContentValue);
      this.contentLoaded = true;
    }

    if (
      this.iframe.nativeElement &&
      this.iframe.nativeElement.contentWindow &&
      this.iframe.nativeElement.contentWindow.document
    ) {
      const iframeContentWindow = this.iframe.nativeElement.contentWindow.document || null;
      iframeContentWindow.addEventListener('keydown', (e: any) => {
        this.onkeydown(e);
      });
    }

    ////if (!this.isCordovaApp) {
    ////    this.iframe.nativeElement.style.width = "calc(44.5mm + " + this.iframe.nativeElement.contentWindow.document.body.scrollWidth + "px)";
    ////    this.iframe.nativeElement.style.height = "calc(28.25mm + " + this.iframe.nativeElement.contentWindow.document.body.scrollHeight + "px)";
    ////}
    ////this.iframe.nativeElement.style.width = "calc(44.5mm + " + this.iframe.nativeElement.contentWindow.document.body.scrollWidth + "px)";
    ////console.log(this.iframe.nativeElement.contentWindow.document.body.scrollWidth);

    ////this.iframe.nativeElement.style.height = "calc(28.25mm + " + this.iframe.nativeElement.contentWindow.document.body.scrollHeight + "px)";
    ////console.log(this.iframe.nativeElement.contentWindow.document.body.scrollHeight);
  }

  public onkeydown(event: any) {
    if (event.code === 'Escape') {
      this.onClose();
    }
  }

  public get isMobile(): boolean {
    const browser: Browser = new Browser();
    return browser.isMobile;
  }

  public get isIframeScrolling(): string {
    return this.isMobile ? 'no' : 'yes';
  }
}
