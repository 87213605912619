import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../common/constants';
import { FormComponentBase } from '../../../common/form-component-base';
import { Global } from '../../../common/global';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { EmployeeService } from '../../../services/employee.service';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';
import { ReportDialogService } from '../../../shared-components/report-dialog/report-dialog.service';
import { CompanyTemplatesService } from './templates.service';
var TemplatesComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TemplatesComponent, _super);
    function TemplatesComponent(dataService, settingService, sessionService, staticDataService, employeeService, companyTemplatesService, reportDialogService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.staticDataService = staticDataService;
        _this.employeeService = employeeService;
        _this.companyTemplatesService = companyTemplatesService;
        _this.reportDialogService = reportDialogService;
        _this.hasTemplateModule = false;
        _this.hasDepartmentModule = false;
        _this.addNewDialogVisible = false;
        _this.isPayrollGridVisible = false;
        _this.isSeniorityGridVisible = false;
        _this.isOvertimeGridVisible = false;
        _this.deleteConfirmationDialogVisible = false;
        _this.discardChangesDialogVisible = false;
        _this.updateTemplateWarningDialogVisible = false;
        _this.showInvalidFormWarning = false;
        _this.AddDepartmentVisible = false;
        _this.ngUnsubscribe = new Subject();
        _this.NumbericTextBoxFFHoursEarnedPerYearOptions = {
            format: 'n2',
            decimals: 2,
            step: 1,
            spinners: false
        };
        _this.childIsValid = Array(16).fill(true);
        _this.childIsDirty = Array(20).fill(false);
        _this.companyTemplatesService.tabDirty = _this.isDirty;
        _this.companyTemplatesService.tabValid = _this.isValid;
        _this.companyTemplatesService.editShortcutChange.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (value) {
            if (value && _this.sessionService.role.IsSalaryAdminAndHigher) {
                _this.onEditClick(true);
            }
        });
        _this.companyTemplatesService.discardChangeShortcutChange
            .pipe(takeUntil(_this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && _this.companyTemplatesService.editMode) {
                _this.onConfirmAndDiscardChanges();
            }
        });
        _this.companyTemplatesService.saveChangeShortcutChange
            .pipe(takeUntil(_this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && _this.sessionService.role.IsSalaryAdminAndHigher) {
                _this.onEditClick(false);
            }
        });
        _this.companyTemplatesService.showReportShortcutChange
            .pipe(takeUntil(_this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && _this.reportDialogService.isVisibleReportIcon) {
                _this.reportDialogService.onShowReportsEventClick();
            }
        });
        _this.companyTemplatesService.addNewShortcutChange
            .pipe(takeUntil(_this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && !_this.companyTemplatesService.editMode && _this.isAddIconVisible) {
                _this.addNewDialogVisible = true;
            }
        });
        _this.valueChange.subscribe(function () {
            _this.companyTemplatesService.tabDirty = _this.isDirty;
            _this.companyTemplatesService.tabValid = _this.isValid;
        });
        _this.companyTemplatesService.selectedTemplateIdChange.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function () {
            _this.getEmploymentTemplate();
        });
        _this.companyTemplatesService.updateTemplateOnlyChange.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function () {
            _this.resetDirtyForm();
        });
        _this.companyTemplatesService.resetFormChange.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function () {
            _this.resetDirtyForm();
        });
        return _this;
    }
    Object.defineProperty(TemplatesComponent.prototype, "IsDisableCreateDepartment", {
        get: function () {
            return this.sessionService.role.IsReadOnly || this.sessionService.role.IsSalaryAdmin;
        },
        enumerable: true,
        configurable: true
    });
    TemplatesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.companyTemplatesService.loadTemplates();
        this.getEmploymentTemplate();
        this.settingService.showModuleMessage('Company.Templates');
        this.employeeService.selectedEmployeeId.subscribe(function (Id) {
            _this.selectedEmployeeId = Id;
        });
    };
    TemplatesComponent.prototype.ngOnDestroy = function () {
        this.companyTemplatesService.serviceDestroy();
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.companyTemplatesService.ngOnDestroy();
    };
    Object.defineProperty(TemplatesComponent.prototype, "has1stPensionProvider", {
        get: function () {
            return (!!this.companyTemplatesService.templateDetail && !!this.companyTemplatesService.templateDetail.PensionProviderId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TemplatesComponent.prototype, "has2ndPensionProvider", {
        get: function () {
            return (!!this.companyTemplatesService.templateDetail && !!this.companyTemplatesService.templateDetail.Pension2ProviderId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TemplatesComponent.prototype, "isAddIconVisible", {
        get: function () {
            return this.sessionService.role.IsSalaryAdminAndHigher;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TemplatesComponent.prototype, "SecondaryIncomeType", {
        get: function () {
            return (this.companyTemplatesService.templateDetail && this.companyTemplatesService.templateDetail.IncomeTypeId === 5);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TemplatesComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TemplatesComponent.prototype, "showStatisticsSection", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.StatisticsRecipientId && Global.COMPANY.StatisticsRecipientId !== -1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TemplatesComponent.prototype, "isSwedenCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TemplatesComponent.prototype, "isDanishCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TemplatesComponent.prototype, "showMultiPurposeOffDayCompensation", {
        get: function () {
            var enableFlexBalanceSetting = this.sessionService.getCompanyPreference('Company.EnableFlexBalance');
            if (enableFlexBalanceSetting) {
                return enableFlexBalanceSetting.Value === 'true' ? true : false;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    TemplatesComponent.prototype.onEditClick = function ($event) {
        if (!$event) {
            this.companyTemplatesService.onSave();
        }
        else {
            this.companyTemplatesService.editMode = $event;
        }
    };
    TemplatesComponent.prototype.onDbClick = function () {
        if ((this.sessionService.role.IsFullAdmin || this.sessionService.role.IsSalaryAdmin) &&
            !this.sessionService.isGetting &&
            !this.sessionService.isSubmitting) {
            this.companyTemplatesService.editMode = true;
        }
    };
    TemplatesComponent.prototype.onAddNewCompanyTemplateCompleted = function (template) {
        this.companyTemplatesService.loadTemplates(template.Id);
    };
    TemplatesComponent.prototype.onDeleteEmploymentTemplate = function () {
        if (this.companyTemplatesService.selectedTemplateId) {
            this.deleteConfirmationDialogVisible = true;
        }
    };
    TemplatesComponent.prototype.onDeleteConfirmationDialogAction = function (action) {
        var _this = this;
        if (action === 'Delete') {
            this.dataService
                .EmploymentTemplates_DeleteEmploymentTemplate(this.companyTemplatesService.selectedTemplateId)
                .subscribe(function () { return _this.companyTemplatesService.loadTemplates(); });
        }
    };
    TemplatesComponent.prototype.onConfirmUpdateDialogAction = function (action) {
        if (action === 'UpdateAllEmployees') {
            this.updateTemplateWarningDialogVisible = true;
        }
        if (action === 'UpdateTemplateOnly') {
            this.companyTemplatesService.updateTemplateOnly();
        }
    };
    TemplatesComponent.prototype.onUpdateTemplateWarningAction = function (action) {
        var _this = this;
        if (action === 'Proceed') {
            this.companyTemplatesService.updateTemplateAllEmployee().subscribe(function () {
                if (_this.selectedEmployeeId) {
                    _this.employeeService.selectEmployee(_this.selectedEmployeeId, true);
                }
                _this.companyTemplatesService.moveToNextState();
            });
        }
    };
    TemplatesComponent.prototype.onConfirmAndDiscardChanges = function () {
        if (!this.companyTemplatesService.tabDirty) {
            this.companyTemplatesService.editMode = false;
            return;
        }
        this.discardChangesDialogVisible = true;
    };
    TemplatesComponent.prototype.onDiscardChangesDialogAction = function (action) {
        if (action === 'ConfirmDiscard') {
            this.companyTemplatesService.editMode = false;
            this.companyTemplatesService.onDiscard();
        }
    };
    TemplatesComponent.prototype.getEmploymentTemplate = function () {
        var _this = this;
        if (this.companyTemplatesService.selectedTemplateId) {
            this.companyTemplatesService.getEmploymentTemplate().subscribe(function () {
                _this.companyTemplatesService.updateTemplateListTemplateName();
            });
        }
    };
    TemplatesComponent.prototype.resetDirtyForm = function () {
        this.isDirty = false;
        this.childIsValid = Array(15).fill(true);
        this.childIsDirty = Array(19).fill(false);
        this.companyTemplatesService.tabDirty = this.isDirty;
        this.companyTemplatesService.tabValid = this.isValid;
    };
    TemplatesComponent.prototype.EmploymentBalanceConfigurationsChange = function (dataChange, changeNumber) {
        if (dataChange && dataChange.length > 0) {
            if (this.companyTemplatesService.templateDetail.TemplateBalanceConfigurations &&
                this.companyTemplatesService.templateDetail.TemplateBalanceConfigurations.length > 0) {
                var model = this.companyTemplatesService.templateDetail.TemplateBalanceConfigurations.find(function (item) { return item.BalanceDefinitionId === changeNumber; });
                if (model) {
                    this.companyTemplatesService.templateDetail.TemplateBalanceConfigurations[this.companyTemplatesService.templateDetail.TemplateBalanceConfigurations.indexOf(model)] =
                        dataChange[dataChange.indexOf(dataChange.find(function (item) { return item.BalanceDefinitionId === changeNumber; }))];
                }
                else {
                    this.companyTemplatesService.templateDetail.TemplateBalanceConfigurations.push(dataChange[0]);
                }
            }
            else {
                this.companyTemplatesService.templateDetail.TemplateBalanceConfigurations.push(dataChange[0]);
            }
        }
    };
    Object.defineProperty(TemplatesComponent.prototype, "isShowExtraVacationHours", {
        get: function () {
            return this.isDanishCompany && !!this.companyTemplatesService.templateDetail.VacationTypeId;
        },
        enumerable: true,
        configurable: true
    });
    return TemplatesComponent;
}(FormComponentBase));
export { TemplatesComponent };
