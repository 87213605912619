import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { EmployeeService } from 'src/app/services/employee.service';
import { SessionService } from 'src/app/services/session/session.service';
import { FormComponentBase } from '../../../common/form-component-base';
import { SettingService } from '../../../services/setting.service';
import { Position } from './position';
var PositionInfoComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PositionInfoComponent, _super);
    function PositionInfoComponent(employeeService, sessionService, settingService) {
        var _this = _super.call(this) || this;
        _this.employeeService = employeeService;
        _this.sessionService = sessionService;
        _this.settingService = settingService;
        _this.positionTextChanged = new EventEmitter();
        _this.positionIdChanged = new EventEmitter();
        _this.isEmployeeTab = false;
        _this.newEmploymentDialogVisible = false;
        _this.childIsValid = Array(2).fill(true);
        _this.childIsDirty = Array(2).fill(false);
        return _this;
    }
    Object.defineProperty(PositionInfoComponent.prototype, "companyUser", {
        get: function () {
            return this.companyUserValue;
        },
        set: function (value) {
            this.companyUserValue = value;
            this.updateEmployments();
            this.selectDefaultPosition();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PositionInfoComponent.prototype, "currentPositionId", {
        get: function () {
            return this.currentPositionIdValue;
        },
        set: function (value) {
            this.setcurrentUserEmploymentValue(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PositionInfoComponent.prototype, "hasMultipleEmployments", {
        get: function () {
            return this.companyUser && this.userEmploymentDataSource && this.userEmploymentDataSource.length > 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PositionInfoComponent.prototype, "hasSingleEmployment", {
        get: function () {
            return this.companyUser && this.userEmploymentDataSource && this.userEmploymentDataSource.length === 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PositionInfoComponent.prototype, "hasNoEmployment", {
        get: function () {
            return !this.companyUser || !this.companyUser.UserEmployments || this.companyUser.UserEmployments.length === 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PositionInfoComponent.prototype, "allowEditTitle", {
        get: function () {
            return this.editMode && !this.hasNoEmployment;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PositionInfoComponent.prototype, "userEmploymentDataSource", {
        get: function () {
            return this.positions;
        },
        set: function (value) {
            if (this.positions !== value) {
                this.positions = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PositionInfoComponent.prototype, "isSelfService", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.selfservice');
        },
        enumerable: true,
        configurable: true
    });
    PositionInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.employeeService.employments.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () { return _this.updateEmployments(); });
    };
    PositionInfoComponent.prototype.updateEmployments = function () {
        var _this = this;
        if (this.hasNoEmployment) {
            this.userEmploymentDataSource = [];
        }
        else {
            var titles_1 = [];
            if (this.companyUserValue.UserEmployments) {
                this.companyUserValue.UserEmployments.forEach(function (item) {
                    if (!item) {
                        return;
                    }
                    var position = _this.createPosition(item);
                    titles_1.push(position);
                });
            }
            this.userEmploymentDataSource = titles_1;
        }
    };
    PositionInfoComponent.prototype.createPosition = function (employment) {
        var item = new Position();
        item.DisplayTitle = employment.Title;
        var expireDate = employment.ExpireDate ? new Date(employment.ExpireDate) : undefined;
        var today = new Date();
        if (expireDate && expireDate < today) {
            item.DisplayTitle = '(' + employment.Title + ')';
        }
        item.Id = employment.Id;
        return item;
    };
    PositionInfoComponent.prototype.selectDefaultPosition = function () {
        var _this = this;
        var currentEmployment;
        if (this.companyUser && this.companyUser.UserEmployments && this.companyUser.UserEmployments.length > 1) {
            currentEmployment = this.companyUserValue.UserEmployments.find(function (item) { return item.Id === _this.currentPositionId; });
        }
        var employmentId = this.newEmployment
            ? this.newEmployment.Id
            : currentEmployment
                ? currentEmployment.Id
                : undefined;
        this.newEmployment = undefined;
        this.setcurrentUserEmploymentValue(employmentId);
        if (!employmentId) {
            this.onPositionChangedEvent();
        }
    };
    PositionInfoComponent.prototype.setcurrentUserEmploymentValue = function (value) {
        var _this = this;
        setTimeout(function () {
            if (!value) {
                if (!_this.positions || _this.positions.length === 0) {
                    _this.currentPositionIdValue = undefined;
                    _this.currentUserEmployment = undefined;
                }
                else {
                    if (_this.settingService && _this.settingService.stayEmploymentId) {
                        _this.currentPositionIdValue = _this.settingService.stayEmploymentId;
                        _this.settingService.stayEmploymentId = undefined;
                    }
                    else {
                        _this.currentPositionIdValue = _this.positions[0].Id;
                    }
                    _this.currentUserEmployment = _this.companyUserValue.UserEmployments.find(function (item) { return item.Id === _this.currentPositionIdValue; });
                }
                return;
            }
            _this.currentPositionIdValue = value;
            if (_this.companyUserValue.UserEmployments) {
                _this.currentUserEmployment = _this.companyUserValue.UserEmployments.find(function (item) { return item.Id === _this.currentPositionIdValue; });
            }
            else {
                _this.currentUserEmployment = undefined;
            }
        });
    };
    PositionInfoComponent.prototype.onPositionChangedEvent = function (employmentId) {
        if (employmentId) {
            this.employeeService.selectEmployment(employmentId);
            this.positionIdChanged.emit();
        }
    };
    PositionInfoComponent.prototype.onPositionTextInputChange = function () {
        this.onChange();
        if (this.isDirty) {
            this.positionTextChanged.emit(this.currentUserEmployment.Title);
        }
    };
    PositionInfoComponent.prototype.onAddonClick = function () {
        this.newEmploymentDialogVisible = true;
    };
    return PositionInfoComponent;
}(FormComponentBase));
export { PositionInfoComponent };
