<div class="headercontrolMobiletimeentry">
  <div class="enterpreview">
    <div>
      <a (click)="onPreviewModalDialog()">
        <app-icon [icon]="'Calendar'"></app-icon>
      </a>
    </div>
    <div class="margin-center">
      <app-combobox-edit
        [editMode]="false"
        [comboboxDataSource]="salaryPeriods"
        [textField]="'FriendlyName'"
        [isConvertTime]="false"
        [idField]="'Id'"
        class="custtomNextPrevButton"
        [specialShowNavigate]="true"
        [(value)]="selectedSalaryPeriodId"
        [showNavigationButton]="true"
        (valueChange)="onSalaryPeriodChanged()"
        [keepDataSourceOrder]="true"
      >
      </app-combobox-edit>
    </div>
    <div *ngIf="!isHideRejectAction">
      <a kendoTooltip [title]="toolTipDenyEdit | translate" (click)="ApproveEntries()">
        <app-icon [icon]="'SuccessNonGreen'"></app-icon>
      </a>
    </div>
  </div>
</div>

<div class="control-time-entry-mobile">
  <div
    *ngFor="let model of timeEntryRecords"
    class="contenttimenetry {{ LineBackground(model) }}"
    (click)="selectTimeEntryRecoredMobileCentric(model)"
  >
    <div class="datestarttoend {{ loadstatusLine(model.Status.Key, LineBackground(model)) }}">
      <div class="startdate">
        <div class="daystat">
          {{ ConvertDaytoString(model.EntryDate) }}
        </div>
        <div class="monstart">
          {{ ConvertMonthtoString(model.EntryDate) }}
        </div>
      </div>
      <div *ngIf="checkexistenddate(model.EndDate)" class="enddate">
        <div class="daystat">
          {{ ConvertDaytoString(model.EndDate) }}
        </div>
        <div class="monstart">
          {{ ConvertMonthtoString(model.EndDate) }}
        </div>
      </div>
    </div>
    <div *ngIf="!checkIsNullOrEmptyString(model.Description)" class="TimeEntrydescription">
      <p>{{ model.Description }}</p>
    </div>
    <div *ngIf="checkIsNullOrEmptyString(model.Description)" class="TimeEntrydescription">
      <p>{{ getTimeEntryTypeName(model.TimeEntryTypeId) }}</p>
    </div>
    <div class="TimeEntryUnits">
      <p>{{ formatunit(model.Units) | kendoNumber: 'n2':'da' }}</p>
    </div>
    <div class="TimeEntryUnitsType">
      <p *ngIf="showUnitType(model.UnitType.Name)">{{ model.UnitType.Name }}</p>
    </div>
  </div>
  <app-button
    *ngIf="!isHideButtonAction"
    [isDisabled]="sessionService.isGetting"
    class="ButtonInsertEmployeetimeMobile"
    [buttonClass]="'Button--primary'"
    (clickEvent)="onAddNew()"
    [label]="'EmploymentTime.AddNewEntry' | translate"
  >
  </app-button>
</div>

<app-mobile-modal [(show)]="showSelectPeriodDialog" [customClass]="'widthMax'">
  <app-mobile-modal-header></app-mobile-modal-header>
  <app-mobile-modal-content>
    <div
      *ngFor="let SalaryPeriod of salaryPeriods"
      class="ListSalaryPeriods"
      (click)="selectListSalaryPeriods(SalaryPeriod)"
    >
      <p>{{ SalaryPeriod.FriendlyName }}</p>
    </div>
  </app-mobile-modal-content>
  <app-mobile-modal-footer class="hide-onkbShow">
    <button class="Button--primary" (click)="showSelectPeriodDialog = !showSelectPeriodDialog">
      {{ 'GlobalDialog.Close' | translate }}
    </button>
  </app-mobile-modal-footer>
</app-mobile-modal>

<!-- modal add timeentry record mobile centric -->
<app-mobile-modal
  *ngIf="isAddNew || isUpdate"
  [(show)]="isAddNew || isUpdate"
  [customClass]="'widthMax'"
  (close)="onCloseDialogEdit()"
>
  <app-mobile-modal-header>{{ titleDialog | translate }}</app-mobile-modal-header>
  <app-mobile-modal-content>
    <form>
      <fieldset>
        <!-- TimeEntryTypeId -->
        <div class="Card-inner">
          <app-combobox-edit
            [label]="'EmploymentTime.Type' | translate"
            [placeholder]="'EmploymentTime.SalaryPeriod' | translate"
            [comboboxDataSource]="timeEntryTypes"
            [editMode]="!isReadOnLyControlSelfService"
            [textField]="'Name'"
            [idField]="'TimeEntryTypeId'"
            (valueChange)="onTimeEntryTypesChange($event)"
            [(value)]="timeEntryModel.TimeEntryTypeId"
          >
          </app-combobox-edit>
        </div>
        <!-- TimeEntryTypeId -->
        <!-- EntryDate -->
        <div class="Card-inner">
          <app-date-picker
            class="custoomKendoDatePickerSelect"
            [label]="'EmploymentTime.EntryDate' | translate"
            [editMode]="!isReadOnLyControlSelfService"
            [(value)]="timeEntryModel.EntryDate"
            (valueChange)="ApplyTimeEntryRecordModalLogic()"
          ></app-date-picker>
        </div>
        <!-- EntryDate -->
        <!-- EndDate -->
        <div class="Card-inner">
          <app-date-picker
            class="custoomKendoDatePickerSelect"
            [label]="'EmploymentTime.EndDate' | translate"
            [editMode]="!isReadOnLyControlSelfService"
            [(value)]="timeEntryModel.EndDate"
            (valueChange)="ApplyTimeEntryRecordModalLogic()"
            [minDate]="timeEntryModel.EntryDate"
          ></app-date-picker>
        </div>
        <!-- EndDate -->
        <!-- StartTime -->
        <div *ngIf="isVisibleFrom" class="Card-inner">
          <app-time-pickerv
            [label]="'EmploymentTime.StartTime' | translate"
            [editMode]="!isReadOnLyControlSelfService"
            [(value)]="timeEntryModel.StartTime"
            (valueChange)="ApplyTimeEntryRecordModalLogic()"
          ></app-time-pickerv>
        </div>
        <!-- StartTime -->
        <!-- EndTime -->
        <div *ngIf="isVisibleUntill" class="Card-inner">
          <app-time-pickerv
            [label]="'EmploymentTime.EndTime' | translate"
            [editMode]="!isReadOnLyControlSelfService"
            [(value)]="timeEntryModel.EndTime"
            (valueChange)="ApplyTimeEntryRecordModalLogic()"
          ></app-time-pickerv>
        </div>
        <!-- EndTime -->
        <!-- Units -->
        <div class="Card-inner inLineFlex">
          <app-numeric-edit
            [label]="'EmploymentTime.Units' | translate"
            [editMode]="isVisibleUnit && !isReadOnLyControlSelfService"
            [(value)]="timeEntryModel.Units"
            [isSpecialLabel]="true"
            [customClass]="'margin5Label'"
            [centerText]="true"
            [option]="NumericOptions"
          >
          </app-numeric-edit>

          <!-- UnitTypeId -->
          <!-- {{'EmploymentTime.UnitTypeId' | translate}}
          <app-combobox-edit
            [editMode]="timeEntryModel.isEditUnitType && !isReadOnLyControlSelfService"
            [textField]="'Name'"
            [idField]="'Id'"
            [comboboxDataSource]="unitTypes"
            [(value)]="timeEntryModel.UnitTypeId"
            (valueChange)="ApplyTimeEntryRecordModalLogic()"
            [placeholder]="'EmploymentTime.SalaryPeriod' | translate"
          >
          </app-combobox-edit> -->
          <app-combobox-edit
            [editMode]="false"
            [textField]="'Name'"
            [idField]="'Id'"
            [comboboxDataSource]="unitTypes"
            [(value)]="timeEntryModel.UnitTypeId"
            (valueChange)="ApplyTimeEntryRecordModalLogic()"
            [placeholder]="'EmploymentTime.SalaryPeriod' | translate"
          >
          </app-combobox-edit>
          <!-- UnitTypeId -->
        </div>
        <!-- Units -->
        <!-- Description -->
        <div class="Card-inner">
          <app-text-edit
            [label]="'EmploymentTime.Description' | translate"
            [editMode]="!isReadOnLyControlSelfService"
            [(value)]="timeEntryModel.Description"
          ></app-text-edit>
        </div>
        <!-- Description -->
        <!-- KilometersDriven -->
        <div class="Card-inner">
          <app-numeric-edit
            [label]="'EmploymentTime.KilometersDriven' | translate"
            [editMode]="!isReadOnLyControlSelfService"
            [(value)]="timeEntryModel.KilometersDriven"
            [option]="NumericOptions"
          ></app-numeric-edit>
        </div>
        <!-- KilometersDriven -->
        <!-- Dimension1 -->
        <div class="Card-inner" *ngIf="isHideDimension1">
          <app-text-edit
            *ngIf="!isEnableDimensionPreference"
            [label]="Dimension1Name | translate"
            [editMode]="!isReadOnLyControlSelfService"
            [(value)]="timeEntryModel.Dimension1Value"
          ></app-text-edit>
          <app-combobox-edit
            *ngIf="isEnableDimensionPreference"
            [label]="Dimension1Name | translate"
            [nullValueLabel]="'DropdownList.None' | translate"
            [editMode]="!isReadOnLyControlSelfService"
            [comboboxDataSource]="dimensionXValues[0]"
            [textField]="'Value'"
            [idField]="'Id'"
            [(value)]="timeEntryModel.Dimension1ValueId"
          ></app-combobox-edit>
        </div>
        <!-- Dimension1 -->
        <!-- Dimension2 -->
        <div class="Card-inner" *ngIf="isHideDimension2">
          <app-text-edit
            *ngIf="!isEnableDimensionPreference"
            [label]="Dimension2Name | translate"
            [editMode]="!isReadOnLyControlSelfService"
            [(value)]="timeEntryModel.Dimension2Value"
          ></app-text-edit>
          <app-combobox-edit
            *ngIf="isEnableDimensionPreference"
            [label]="Dimension2Name | translate"
            [nullValueLabel]="'DropdownList.None' | translate"
            [editMode]="!isReadOnLyControlSelfService"
            [comboboxDataSource]="dimensionXValues[1]"
            [textField]="'Value'"
            [idField]="'Id'"
            [(value)]="timeEntryModel.Dimension2ValueId"
          ></app-combobox-edit>
        </div>
        <!-- Dimension2 -->
        <!-- Dimension3 -->
        <div class="Card-inner" *ngIf="isHideDimension3">
          <app-text-edit
            *ngIf="!isEnableDimensionPreference"
            [label]="Dimension3Name | translate"
            [editMode]="!isReadOnLyControlSelfService"
            [(value)]="timeEntryModel.Dimension3Value"
          ></app-text-edit>
          <app-combobox-edit
            *ngIf="isEnableDimensionPreference"
            [label]="Dimension3Name | translate"
            [nullValueLabel]="'DropdownList.None' | translate"
            [editMode]="!isReadOnLyControlSelfService"
            [comboboxDataSource]="dimensionXValues[2]"
            [textField]="'Value'"
            [idField]="'Id'"
            [(value)]="timeEntryModel.Dimension3ValueId"
          ></app-combobox-edit>
        </div>
        <!-- Dimension3 -->
        <!-- RejectionReason -->
        <div class="Card-inner reason-reject-input" *ngIf="isRejectModel || (isVisibleRejectbutton && !isSelfService)">
          <app-text-edit
            [label]="'EmploymentTime.RejectionReason' | translate"
            [editMode]="isRejectModel || isVisibleRejectbutton"
            [(value)]="timeEntryModel.RejectionReason"
          >
          </app-text-edit>
        </div>
        <!-- RejectionReason -->
      </fieldset>
    </form>
  </app-mobile-modal-content>

  <app-mobile-modal-footer class="hide-onkbShow" *ngIf="isAddNew" kendoTooltip [title]="toolTipDenyEdit | translate">
    <button
      [disabled]="!checkIsvalidPost(timeEntryModel) || isReadOnLyControlSelfService"
      class="Button--primary"
      (click)="CreateNewTimeentryRecordMobileCentric()"
    >
      {{ 'GlobalDialog.Create' | translate }}
    </button>
    <button class="Button--secondary" (click)="isCloseModal('create')">
      {{ 'GlobalDialog.Close' | translate }}
    </button>
  </app-mobile-modal-footer>

  <app-mobile-modal-footer class="hide-onkbShow" *ngIf="isUpdate" kendoTooltip [title]="toolTipDenyEdit | translate">
    <button
      [disabled]="!checkIsvalidPost(timeEntryModel) || isReadOnLyControlSelfService"
      *ngIf="isUpdateActive"
      class="Button--primary"
      (click)="UpdateNewTimeentryRecordMobileCentric()"
    >
      {{ 'GlobalDialog.Update' | translate }}
    </button>
    <button
      *ngIf="!isHideButtonAction && isVisibleDeletebytton"
      [disabled]="isReadOnLyControlSelfService"
      class="Button--destructive"
      (click)="DeleteNewTimeentryRecordMobileCentric()"
    >
      {{ 'GlobalDialog.Delete' | translate }}
    </button>
    <button
      *ngIf="!isReadOnly && !isSelfService && isVisibleRejectbutton"
      class="Button--destructive"
      (click)="RejectNewTimeentryRecordMobileCentric()"
    >
      {{ 'GlobalDialog.Reject' | translate }}
    </button>
    <button class="Button--secondary" (click)="isCloseModal('update')">
      {{ 'GlobalDialog.Close' | translate }}
    </button>
  </app-mobile-modal-footer>
</app-mobile-modal>
<!-- modal add timeentry record mobile centric -->

<app-dialog (action)="onConfirmDeleteDialogAction($event)" [(visible)]="confirmDeleteDialogVisible">
  <div [innerHTML]="deleteConfimMessage"></div>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Delete'"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onConfirmRejectDialogAction($event)"
  [overlayClose]="false"
  [(visible)]="confirmRejectDialogVisible"
>
  <div [innerHTML]="rejectionWarningText"></div>
  <app-text-edit [editMode]="true" [(value)]="rejectReason"></app-text-edit>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Reject'"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="isgetNotMatchPeriodMessageString"
  [width]="'500'"
  [leadingText]="getNotMatchPeriodMessageString | translate"
>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>
