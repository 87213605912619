import { OnDestroy, OnInit } from '@angular/core';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from 'src/app/common/global';
import { Department } from '../../../services/api/api-model';
import { StaticDataService } from '../../../services/api/static-data.service';
import { ModalService } from '../../../services/modal.service';
import { SessionService } from '../../../services/session/session.service';
import { ReportDialogService } from '../../../shared-components/report-dialog/report-dialog.service';
import { CompanyDepartmentService } from './company-department.service';
var CompanyDepartmentComponent = /** @class */ (function () {
    function CompanyDepartmentComponent(modalService, staticDataService, sessionService, reportDialogService, companyDepartmentService) {
        var _this = this;
        this.modalService = modalService;
        this.staticDataService = staticDataService;
        this.sessionService = sessionService;
        this.reportDialogService = reportDialogService;
        this.companyDepartmentService = companyDepartmentService;
        this.deleteWarningVisible = false;
        this.departments = [];
        this.ngUnsubscribe = new Subject();
        this.refreshData = false;
        this.keyPress = { 17: false, 18: false, 107: false, 109: false };
        this.gridData = this.departments;
        this.companyDepartmentService.showReportShortcutChange
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && _this.reportDialogService.isVisibleReportIcon) {
                _this.reportDialogService.onShowReportsEventClick();
            }
        });
        this.modalService.modalServiceCloseErrorAlert.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (value) {
            _this.companyDepartmentService.loadDepartment();
        });
        this.companyDepartmentService.departments.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.departments = data;
            _this.gridData = filterBy(_this.departments, _this.filter);
        });
    }
    CompanyDepartmentComponent.prototype.ngOnInit = function () {
        this.companyDepartmentService.loadDepartment();
    };
    CompanyDepartmentComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(CompanyDepartmentComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly || this.sessionService.role.IsSalaryAdmin;
        },
        enumerable: true,
        configurable: true
    });
    CompanyDepartmentComponent.prototype.onAddNew = function (newDepartment) {
        newDepartment.GridId = this.companyDepartmentService.gridFlag++;
        newDepartment.CompanyId = Global.COMPANY.Id;
        newDepartment.IsActive = true;
    };
    CompanyDepartmentComponent.prototype.onRemoveDepartment = function (e) {
        this.gridRemoveRowArgs = e;
        this.deleteWarningVisible = true;
    };
    CompanyDepartmentComponent.prototype.onDeleteWarningAction = function (action) {
        var _this = this;
        if (action === 'Delete' && this.gridRemoveRowArgs) {
            if (this.gridRemoveRowArgs.dataItem.Id > 0) {
                this.companyDepartmentService.deleteDepartment(this.gridRemoveRowArgs.dataItem.Id).subscribe(function () {
                    _this.gridRemoveRowArgs = undefined;
                });
            }
            else {
                this.removeDeletedItem();
            }
        }
    };
    CompanyDepartmentComponent.prototype.removeDeletedItem = function () {
        var indexOfRemovedItem = this.departments.indexOf(this.gridRemoveRowArgs.dataItem);
        this.departments.splice(indexOfRemovedItem, 1);
        this.gridRemoveRowArgs = undefined;
        this.companyDepartmentService.loadDepartment();
    };
    CompanyDepartmentComponent.prototype.onSave = function (args) {
        var department = args.dataItem ? args.dataItem : args;
        var model = new Department();
        model = this.createDepartmentModel(department);
        if (model.Id) {
            this.companyDepartmentService.updateDepartment(model).subscribe(function (response) {
                Object.assign(department, response);
            });
        }
        else {
            this.companyDepartmentService.createDepartment(model).subscribe(function (response) {
                department.Id = response.Id;
            });
        }
    };
    CompanyDepartmentComponent.prototype.createDepartmentModel = function (object) {
        return {
            Id: object.Id || null,
            CompanyId: object.CompanyId || null,
            Name: object.Name || null,
            Description: object.Description || null,
            IsActive: object.IsActive || false,
            EIncomeReference: object.EIncomeReference || null,
            ExternalReference: object.ExternalReference || null,
            ManagerCompanyUserId: object.ManagerCompanyUserId || null
        };
    };
    CompanyDepartmentComponent.prototype.onKeyDown = function (event) {
        if (!this.IsReadOnly) {
            if (event.keyCode in this.keyPress) {
                this.keyPress[event.keyCode] = true;
                if (this.keyPress[17] && this.keyPress[18] && this.keyPress[109]) {
                    if (this.selectedDepartment) {
                        var item = {};
                        item.dataItem = this.selectedDepartment;
                        this.onRemoveDepartment(item);
                        this.setKeypress();
                        this.refreshData = true;
                        this.selectedDepartment = undefined;
                    }
                }
            }
            else {
                this.setKeypress();
            }
        }
    };
    CompanyDepartmentComponent.prototype.setKeypress = function () {
        this.keyPress[17] = false;
        this.keyPress[18] = false;
        this.keyPress[107] = false;
        this.keyPress[109] = false;
    };
    CompanyDepartmentComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        this.gridData = filterBy(this.departments, filter);
    };
    return CompanyDepartmentComponent;
}());
export { CompanyDepartmentComponent };
