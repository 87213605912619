import { Injectable } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { IGetBalancesEmployeeViewByDate_Result } from '../../services/api/api-model';
import { BalanceAdjustmentRequest, BalancesRequest, IBalancePeriod } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { CompanyService } from '../../services/company.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';

@Injectable({
  providedIn: 'root'
})
export abstract class ExtraVacationHoursService extends CompanyService {
  private BalancesEmployeeViewByDateSubject: BehaviorSubject<IGetBalancesEmployeeViewByDate_Result[]>;
  public get BalancesEmployeeViewByDate(): Observable<IGetBalancesEmployeeViewByDate_Result[]> {
    if (!this.BalancesEmployeeViewByDateSubject) {
      this.Balances_GetExtraVacationHours();
    }
    return this.BalancesEmployeeViewByDateSubject.asObservable();
  }

  public hideEmployeeNoRemainingBlance = true;
  public selectedPeriodItem: IBalancePeriod;
  public originalDataBalancesEmployeeViewByDate: IGetBalancesEmployeeViewByDate_Result[] | any[] = [];
  public isFirstLoad = true;
  public effectivePer = this.staticDataService.getCurrentdate();

  constructor(
    protected dataService: DataService,
    protected staticDataService: StaticDataService,
    protected settingService: SettingService,
    protected sessionService: SessionService,
    protected transitionService: TransitionService,
    protected broadcaster: BroadcastService
  ) {
    super(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster);
  }

  public Balances_GetExtraVacationHours(): void {
    if (!this.BalancesEmployeeViewByDateSubject) {
      this.BalancesEmployeeViewByDateSubject = new BehaviorSubject<IGetBalancesEmployeeViewByDate_Result[]>([]);
    }

    if (!this.effectivePer) {
      return;
    }

    const request: BalancesRequest = {
      BalanceDefinitionId: 7,
      BalancePeriodId: null,
      PerDate: this.effectivePer
    };

    this.BalancesEmployeeViewByDateSubject.next([]);

    this.localBlanceApiService(request).subscribe((data: IGetBalancesEmployeeViewByDate_Result[]): void => {
      this.originalDataBalancesEmployeeViewByDate = data;
      this.filterData();
    });
  }

  private localBlanceApiService(request: BalancesRequest) {
    return this.dataService.Balances_GetBalances(request).pipe(
      tap((data: IGetBalancesEmployeeViewByDate_Result[]) => {
        data = data.map((item: IGetBalancesEmployeeViewByDate_Result) => {
          item.DaysEarned = item.DaysEarned + item.SecondaryDaysEarned;
          item.DaysSpent = item.DaysSpent + item.SecondaryDaysSpent;

          item.DaysTotal = item.DaysTotal + item.CurrentBalanceSecondaryDays;
          item.AmountTotal = item.AmountTotal + item.SecondaryAmountTotal;

          return item;
        });
      })
    );
  }

  public Reload_Balances_GetExtraVacationHours(): void {
    if (!this.BalancesEmployeeViewByDateSubject) {
      this.BalancesEmployeeViewByDateSubject = new BehaviorSubject<IGetBalancesEmployeeViewByDate_Result[]>([]);
    }

    if (!this.effectivePer) {
      return;
    }

    const request: BalancesRequest = {
      BalanceDefinitionId: 7,
      BalancePeriodId: null,
      PerDate: this.effectivePer
    };

    this.localBlanceApiService(request).subscribe((data: IGetBalancesEmployeeViewByDate_Result[]): void => {
      if (data && data.length > 0) {
        let arrayItemDirty: IGetBalancesEmployeeViewByDate_Result[] | any[] = [];
        if (this.originalDataBalancesEmployeeViewByDate && this.originalDataBalancesEmployeeViewByDate.length > 0) {
          arrayItemDirty = this.originalDataBalancesEmployeeViewByDate.filter(
            (item: IGetBalancesEmployeeViewByDate_Result | any) => item.isDirty === true
          );
        }

        data.forEach((item: IGetBalancesEmployeeViewByDate_Result) => {
          const originalItem = this.originalDataBalancesEmployeeViewByDate.find(
            (temp: IGetBalancesEmployeeViewByDate_Result) => item.UserEmploymentId === temp.UserEmploymentId
          );
          if (originalItem) {
            // Update read only cell
            originalItem.FullName = item.FullName;
            originalItem.BasedOnTemplateName = item.BasedOnTemplateName;
            originalItem.DepartmentName = item.DepartmentName;
            originalItem.ExternalReference = item.ExternalReference;

            originalItem.DaysEarned = item.DaysEarned;
            originalItem.DaysSpent = item.DaysSpent;
            originalItem.DaysTotal = item.DaysTotal;
            originalItem.AmountTotal = item.AmountTotal;

            originalItem.BaseAmount = item.BaseAmount || null;
            originalItem.AmountEarned = item.AmountEarned || null;
            originalItem.HoursEarned = item.HoursEarned || null;
            originalItem.HoursSpent = item.HoursSpent || null;
            originalItem.HoursTotal = item.HoursTotal || null;
            originalItem.isDirty = false;

            if (
              arrayItemDirty.find(
                (tempModel: IGetBalancesEmployeeViewByDate_Result | any) =>
                  tempModel.UserEmploymentId === originalItem.UserEmploymentId
              )
            ) {
              originalItem.isDirty = true;
            }
          }
        });

        this.originalDataBalancesEmployeeViewByDate = data;
      } else {
        this.originalDataBalancesEmployeeViewByDate = [];
      }

      this.filterData();
    });
  }

  public filterData(): void {
    let result: IGetBalancesEmployeeViewByDate_Result[] | any[] = [];
    result = this.originalDataBalancesEmployeeViewByDate;
    if (this.hideEmployeeNoRemainingBlance) {
      result = result.filter((item: IGetBalancesEmployeeViewByDate_Result) => {
        return item.HoursTotal;
      });
    }
    this.BalancesEmployeeViewByDateSubject.next(result);
  }

  public save(model: BalanceAdjustmentRequest): void {
    this.dataService.Balances_AdjustBalances(model).subscribe(
      (data: IGetBalancesEmployeeViewByDate_Result[]): void => {
        this.afterSaveSucess(data);
        this.completeSave();
      },
      () => {
        setTimeout(() => {
          this.completeSave();
        }, 1000);
      },
      () => {
        // this.completeSave();
      }
    );
  }

  protected get allowShortcut(): boolean {
    return this.sessionService.currentState === 'tabs.company.balances.extravacationhours';
  }

  public abstract afterSaveSucess(data: IGetBalancesEmployeeViewByDate_Result[]): void;
  public abstract completeSave(): void;
}
