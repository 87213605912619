import { EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataService } from '../services/api/data.service';
var AcceptDpaDialogComponent = /** @class */ (function () {
    function AcceptDpaDialogComponent(dataService) {
        this.dataService = dataService;
        this.isVisibleValue = false;
        this.isVisibleChange = new EventEmitter();
        this.accepted = new EventEmitter();
    }
    Object.defineProperty(AcceptDpaDialogComponent.prototype, "isVisible", {
        get: function () {
            return this.isVisibleValue;
        },
        set: function (value) {
            if (value) {
                this.password = '';
            }
            this.isVisibleValue = value;
            this.isVisibleChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    AcceptDpaDialogComponent.prototype.onDialogAction = function (action) {
        if (action === 'Create') {
            this.acceptDPA();
        }
        else {
            this.accepted.emit(false);
        }
    };
    Object.defineProperty(AcceptDpaDialogComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    AcceptDpaDialogComponent.prototype.onAcceptDPAKeydown = function (event) {
        if (event.keyCode === 13) {
            this.acceptDPA();
        }
    };
    AcceptDpaDialogComponent.prototype.acceptDPA = function () {
        var _this = this;
        this.dataService.Companies_AcceptTerms({ Password: this.password }).subscribe(function () {
            _this.isVisible = false;
            _this.accepted.emit(true);
        });
    };
    return AcceptDpaDialogComponent;
}());
export { AcceptDpaDialogComponent };
