<div
  class="BusyIndicator z-index101"
  [ngStyle]="{ display: visible ? 'block' : 'none', bottom: 0, 'background-color': 'rgba(0, 0, 0, 0.61);' }"
></div>
<div *ngIf="visible" class="loadingTip z-index101">
  <div class="busyIndicatorContainer">
    <div id="busyIndicatorIconSpinner" class="BusyIndicator-spinner"></div>
    <div class="loadingtips-text" [innerHTML]="currentTip"></div>
  </div>
</div>
