import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RouterStateService } from '../common/router-state.service';
import { SessionService } from '../services/session/session.service';
import { ReportDialogService } from '../shared-components/report-dialog/report-dialog.service';
import { CompanyGeneralService } from './general/company-general.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyComponent implements OnInit {
  public get IsMobile(): boolean {
    return this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
  }

  public get isIOSApp(): boolean {
    return this.sessionService.browser.isHybridApp && this.sessionService.browser.iOSMobileDevice;
  }

  public get branding() {
    return environment.branding;
  }

  public mobileWarningDialogVisible = false;

  constructor(
    public sessionService: SessionService,
    public state: RouterStateService,
    public reportDialogService: ReportDialogService,
    private companyGeneralService: CompanyGeneralService
  ) {}

  public ngOnInit(): void {
    this.mobileWarningDialogVisible =
      this.sessionService.browser.isHybridApp &&
      (this.sessionService.currentState === 'tabs.company.configuration.dataimport' ||
        this.sessionService.currentState === 'tabs.company.configuration.timeentrytypes');
  }

  public clickAreamenuToggledChange(): void {
    this.sessionService.menuToggled = true;
    this.sessionService.showMoreMenuToggled = false;
    this.sessionService.showHelpMenuToggled = false;
    this.sessionService.showMenuToggled = false;
  }

  // Tabs
  public get isCompanyGeneralTabVisisble(): boolean {
    return this.sessionService.currentState.includes('tabs.company.general');
  }

  public get isCompanyBalancesVisisble(): boolean {
    return this.sessionService.currentState.includes('tabs.company.balances');
  }

  public get isCompanyApprovalVisisble(): boolean {
    return (
      this.sessionService.currentState.includes('tabs.company.approval') ||
      this.sessionService.currentState.includes('tabs.company.approval.timeentry')
    );
  }

  public get isCompanyPayrollDataTabVisisble(): boolean {
    return this.sessionService.currentState.includes('tabs.company.payrolldata');
  }

  public get isCompanySalaryBatchesTabVisisble(): boolean {
    return this.sessionService.currentState.includes('tabs.company.salarybatches');
  }

  public get isCompanyConfigurationTabVisisble(): boolean {
    return (
      this.sessionService.currentState.includes('tabs.company.configuration') ||
      this.sessionService.currentState.includes('tabs.company.configuration.advancedsalarytypes') ||
      this.sessionService.currentState.includes('tabs.company.configuration.timeentrytypes') ||
      this.sessionService.currentState.includes('tabs.company.configuration.settings') ||
      this.sessionService.currentState.includes('tabs.company.configuration.dataimport') ||
      this.sessionService.currentState.includes('tabs.company.configuration.integrations') ||
      this.sessionService.currentState.includes('tabs.company.configuration.hiringstatus')
    );
  }

  public get isCompanyExtraTabVisisble(): boolean {
    return this.sessionService.currentState.includes('tabs.company.modules');
  }
}
