import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { RouterStateService } from 'src/app/common/router-state.service';
import { SessionService } from '../../services/session/session.service';
import { AccountantJoinService } from './accountant-join.service';

@Component({
  selector: 'app-accountant-join-dialog',
  templateUrl: './accountant-join-dialog.component.html',
  styleUrls: ['./accountant-join-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountantJoinDialogComponent {
  public busy = false;
  public redirectDialogVisible = false;

  public visibleValue = false;
  @Input()
  get visible(): boolean {
    return this.visibleValue;
  }
  set visible(value: boolean) {
    this.visibleValue = value;
    this.visibleChange.emit(value);

    if (value && this.hasMultiCompanyModule) {
      this.dialogAction('Accept');
    }
  }

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onreloginVisible: EventEmitter<any> = new EventEmitter<any>();

  public get hasMultiCompanyModule(): boolean {
    return this.sessionService.feature.hasModuleId(20);
  }

  constructor(
    public service: AccountantJoinService,
    private sessionService: SessionService,
    private stateService: RouterStateService
  ) {}

  public dialogAction(action: string) {
    if (action === 'Accept') {
      this.busy = true;
      if (this.service.selectedAccount.Id) {
        this.service.joinAccount(this.service.selectedAccount.Id).subscribe(
          () => {
            this.busy = false;
            this.redirectDialogVisible = true;
            this.visible = false;
            this.onreloginVisible.emit();
          },
          () => {
            this.busy = false;
          }
        );
      }
    }
  }

  public redirectToLogin() {
    this.stateService.navigateToState('login');
  }
}
