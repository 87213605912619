<form>
  <fieldset class="Form--horizontal">
    <legend class="Card-title">{{ 'EmployeeEmployment.Tax' | translate }}</legend>
    <app-check-edit
      [label]="'EmployeeGeneral.AllowExtendedTaxCardDeductions' | translate"
      [(value)]="model.AllowExtendedTaxCardDeductions"
      [(isDirty)]="childIsDirty[3]"
      [editMode]="editMode && admin"
      (valueChange)="onChange()"
      [helpIcon]="'Info'"
      [helpLink]="'Info'"
      [helpTooltip]="'EmployeeGeneral.helpTooltipAllowExtendedTaxCardDeductions' | translate"
    ></app-check-edit>
  </fieldset>
</form>

<app-dialog (action)="onWarningDialogAction($event)" [(visible)]="warningDialogVisible">
  {{ 'EmployeeEmployment.IncomeType5WarningMessage' | translate }}
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Continue'"></app-dialog-action>
</app-dialog>
