import { Injectable, OnDestroy } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { Subject } from 'rxjs';
import { Global } from 'src/app/common/global';
import { IDimensionValue, IOkResult, ISimpleKeyValuePair } from 'src/app/services/api/api-model';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { CompanyService } from '../../../services/company.service';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';
import { DimensionView } from './dimension-view';

@Injectable({
  providedIn: 'root'
})
export class DimensionsService extends CompanyService implements OnDestroy {
  public currentDimensionNumber = Global.DimensionNumber || 1;
  public dimensionName = '';
  public dimension1 = '';
  public dimension2 = '';
  public dimension3 = '';
  public currentPref: ISimpleKeyValuePair;
  public translations: any = [];
  public listDimensionsData: IDimensionValue[];
  public isShowErrorDialog = false;
  public triggerUpdate = false;
  public dimensionNaming = '';
  public dimension1Active = false;
  public dimension2Active = false;
  public dimension3Active = false;
  public currentDimension: IDimensionValue = null;
  public currentDimensionId = 0;

  constructor(
    protected dataService: DataService,
    protected staticDataService: StaticDataService,
    protected settingService: SettingService,
    protected sessionService: SessionService,
    protected transitionService: TransitionService,
    protected broadcaster: BroadcastService
  ) {
    super(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster);
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public updateDimensionLabel() {
    Global.COMPANY_PREFERENCES.forEach((pref: ISimpleKeyValuePair) => {
      if (pref.Key.indexOf('Dimension1') >= 0) {
        this.dimension1 = pref.Value;
      } else if (pref.Key.indexOf('Dimension2') >= 0) {
        this.dimension2 = pref.Value;
      } else if (pref.Key.indexOf('Dimension3') >= 0) {
        this.dimension3 = pref.Value;
      }

      if (pref.Key.indexOf('Dimension' + this.currentDimensionNumber) >= 0) {
        this.currentPref = pref;
      }
    });

    if (this.dimension1.length === 0) {
      this.dimension1 = this.translations['CompanyConfigurations.Dimension1'];
      this.dimension1Active = false;
    } else {
      this.dimension1Active = true;
    }

    if (this.dimension2.length === 0) {
      this.dimension2 = this.translations['CompanyConfigurations.Dimension2'];
      this.dimension2Active = false;
    } else {
      this.dimension2Active = true;
    }

    if (this.dimension3.length === 0) {
      this.dimension3 = this.translations['CompanyConfigurations.Dimension3'];
      this.dimension3Active = false;
    } else {
      this.dimension3Active = true;
    }

    this.updateDimensionName();
  }

  private updateDimensionName() {
    switch (this.currentDimensionNumber) {
      case 1:
        this.dimensionName = this.dimension1;
        break;
      case 2:
        this.dimensionName = this.dimension2;
        break;
      case 3:
        this.dimensionName = this.dimension3;
        break;
    }
  }

  private createDimensionValue(dimension?: DimensionView): IDimensionValue {
    if (!dimension) {
      dimension = new DimensionView({
        Id: 0,
        CompanyId: Global.COMPANY_ID,
        DimensionNumber: this.currentDimensionNumber
      });
    }
    const dimensionItem: IDimensionValue = {
      Id: dimension.Id,
      CompanyId: dimension.CompanyId,
      DimensionNumber: dimension.DimensionNumber,
      Value: dimension.Value || null,
      Description: dimension.Description || null,
      ApproverCompanyUserId: dimension.ApproverCompanyUserId || null,
      LimitToDepartmentId: dimension.LimitToDepartmentId || null,
      LimitToTemplateId: dimension.LimitToTemplateId || null
    };

    return dimensionItem;
  }

  private convertToDimensionView(dimension: IDimensionValue): DimensionView {
    const dimensionItem: DimensionView = {
      Id: dimension.Id,
      IdView: dimension.Id > 0 ? dimension.Id : null,
      CompanyId: dimension.CompanyId,
      DimensionNumber: dimension.DimensionNumber,
      Value: dimension.Value || null,
      Description: dimension.Description || null,
      ApproverCompanyUserId: dimension.ApproverCompanyUserId || null,
      LimitToDepartmentId: dimension.LimitToDepartmentId || null,
      LimitToTemplateId: dimension.LimitToTemplateId || null
    };

    return dimensionItem;
  }

  public createDimension() {
    const item = this.createDimensionValue();
    this.dataService.DimensionValues_CreateDimensionValue(item).subscribe((dimensionValue: IDimensionValue) => {
      const dimensionView = this.convertToDimensionView(dimensionValue);
      this.listDimensionsData.push(dimensionView);
      this.currentDimensionId = dimensionView.Id;
      this.triggerUpdate = true;
    });
  }

  public updateDimension(dimension?: DimensionView) {
    if (!dimension) {
      dimension = this.currentDimension;
    }
    const rawDimension = JSON.stringify(dimension);
    const item = this.createDimensionValue(dimension);
    this.dataService.DimensionValues_UpdateDimensionValue(item).subscribe(
      (dimensionValue: IDimensionValue) => {
        const dimensionView = this.convertToDimensionView(dimensionValue);
        this.listDimensionsData.forEach((data: DimensionView) => {
          if (data.Id === dimensionView.Id) {
            data = dimensionView;
          }
        });
        this.triggerUpdate = true;
      },
      (err) => {
        const dimensionView = JSON.parse(rawDimension);
        this.listDimensionsData.forEach((data: DimensionView) => {
          if (data.Id === dimensionView.Id) {
            data = dimensionView;
          }
        });
        this.triggerUpdate = true;
      }
    );
  }

  public errorMessage = '';
  public deleteDimension(dimension?: DimensionView) {
    if (!dimension) {
      dimension = this.currentDimension;
    }
    const item = this.createDimensionValue(dimension);
    this.dataService.DimensionValues_DeleteDimensionValue(item.Id).subscribe(() => {
      this.getDimensionData(this.currentDimensionNumber);
    });
  }

  public getDimensionData(index: number) {
    this.dataService.DimensionValues_GetDimensionValues(index).subscribe((data: IDimensionValue[]) => {
      this.listDimensionsData = data || [];
      this.currentDimension = this.listDimensionsData[0];
      this.currentDimensionId = this.listDimensionsData[0] ? this.listDimensionsData[0].Id : 0;
      this.triggerUpdate = true;
    });
  }
}
