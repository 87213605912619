<div class="Island Island-configuration Cards">
  <div class="Card data-import-land">
    <div
      class="data-import-message"
      [innerHTML]="'CompanyDataImport.BriefIntroduction1' | translate: { SystemAlias: branding.SystemAlias }"
    ></div>
    <div class="data-import-message" [innerHTML]="'CompanyDataImport.BriefIntroduction2' | translate"></div>
    <div class="data-import-message" [innerHTML]="'CompanyDataImport.BriefIntroduction3' | translate"></div>
    <div
      class="data-import-message"
      [innerHTML]="'CompanyDataImport.BriefIntroduction4' | translate: { SystemAlias: branding.SystemAlias }"
    ></div>

    <fieldset class="Form--horizontal">
      <legend class="Card-title">{{ 'CompanyDataImport.ImportType' | translate }}</legend>
      <app-radio-edit
        (valueChange)="onCheckBoxImportTypeChange()"
        [editMode]="true"
        [vertical]="true"
        [(value)]="importType"
      >
        <app-radio-edit-option
          [value]="1"
          [label]="'CompanyDataImport.Employees' | translate"
          [openLink]="'http://support.gratisal.com/hc/da/articles/115003261669'"
        ></app-radio-edit-option>
        <app-radio-edit-option
          [value]="2"
          [label]="'CompanyDataImport.InitialBalances' | translate"
          [openLink]="'http://support.gratisal.com/hc/da/articles/115003280345'"
        ></app-radio-edit-option>
        <app-radio-edit-option
          [value]="4"
          [label]="'CompanyDataImport.PayrollData' | translate"
          [openLink]="'http://support.gratisal.com/hc/da/articles/115003178565'"
        ></app-radio-edit-option>
        <app-radio-edit-option
          [moduleCheckWidth]="700"
          [isCheckModule]="true"
          [ModuleId]="8"
          [value]="3"
          [label]="'CompanyDataImport.TimeEntries' | translate"
          [openLink]="'https://support.gratisal.com/hc/da/articles/360015525831-Import-af-Tidsdata-fra-Excel'"
        >
        </app-radio-edit-option>
      </app-radio-edit>
    </fieldset>
    <fieldset class="Form--horizontal">
      <legend class="Card-title">{{ 'CompanyDataImport.ActionType' | translate }}</legend>
      <div class="import-action-container">
        <div class="import-action-radio">
          <app-radio-edit
            [editMode]="true"
            [vertical]="true"
            [(value)]="actionType"
            (valueChange)="onActionTypeChange()"
          >
            <app-radio-edit-option [value]="1" [label]="'CompanyDataImport.GetTemplate' | translate">
            </app-radio-edit-option>
            <app-radio-edit-option
              *ngIf="isShowExportExistingData"
              [value]="5"
              [label]="'CompanyDataImport.ExportExistingData' | translate"
            ></app-radio-edit-option>
            <app-radio-edit-option [value]="4" [label]="'CompanyDataImport.ConverFromOtherSystem' | translate">
            </app-radio-edit-option>
            <app-radio-edit-option [value]="3" [label]="'CompanyDataImport.CustomImport' | translate">
            </app-radio-edit-option>
            <app-radio-edit-option [value]="2" [label]="'CompanyDataImport.UploadCompletedFile' | translate">
            </app-radio-edit-option>
          </app-radio-edit>
        </div>
        <div class="import-action-combobox import-action-combobox-{{ radioIndex }}">
          <app-combobox-edit
            *ngIf="getTemplateVisible"
            [disable]="uploadButtonDisable"
            [editMode]="true"
            [textField]="'Name'"
            [idField]="'Id'"
            [comboboxDataSource]="mappingDataSource"
            [(value)]="mappingDataSourceRecord"
            [label]="'CompanyDataImport.ImportMapping' | translate"
          >
          </app-combobox-edit>
          <div class="padding-external-system"></div>
          <app-combobox-edit
            *ngIf="!isPayrollDataImport && convertFromOtherSystemFieldsVisible"
            [disable]="uploadButtonDisable"
            [editMode]="true"
            [textField]="'Name'"
            [idField]="'Id'"
            [comboboxDataSource]="importType === 1 ? importFromExternalSourceEmployee : importFromExternalSource"
            [label]="'CompanyDataImport.ExternalSystem' | translate"
            [(value)]="importFromExternalSourceValue"
          >
          </app-combobox-edit>
          <app-combobox-edit
            *ngIf="isPayrollDataImport && convertFromOtherSystemFieldsVisible"
            [editMode]="true"
            [textField]="'Name'"
            [idField]="'Id'"
            [comboboxDataSource]="
              importType === 4 ? importFromExternalSourcePayrollData : importPayrollFromExternalSource
            "
            [label]="'CompanyDataImport.ExternalSystem' | translate"
            [(value)]="importPayrollFromExternalSourceValue"
          >
          </app-combobox-edit>
        </div>
      </div>
    </fieldset>

    <div class="CompanyDataImportViewButtonSection">
      <app-button
        class="Button--primary"
        *ngIf="showDownLoadButton"
        (clickEvent)="onDownLoad()"
        [label]="'CompanyDataImport.Download' | translate"
      >
      </app-button>
      <app-button
        class="Button--primary"
        [ngClass]="{ 'is-disabled': uploadButtonDisable }"
        *ngIf="showUploadButton"
        [isDisabled]="uploadButtonDisable"
        (clickEvent)="onUpload()"
        [label]="'CompanyDataImport.Upload' | translate"
      >
      </app-button>
      <app-button
        class="Button--primary"
        *ngIf="showCreateCustomFile"
        (clickEvent)="onCreate()"
        [label]="'CompanyDataImport.Create' | translate"
      >
      </app-button>
    </div>

    <app-file-upload #fileupload (fileSelected)="onFileSelected($event)" [accept]="acceptFileType"></app-file-upload>
  </div>
</div>
<div class="Actions" *ngIf="!isMobile">
  <app-menu-button [menuIcon]="'Help'" [menuLabel]="'Help.Title'" [menuTooltip]="'Help.Title'" [multiMenuItems]="true">
    <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
  </app-menu-button>

  <app-menu-button
    *ngIf="reportDialogService.isVisibleReportIcon"
    [menuCondition]="true"
    (menuClick)="reportDialogService.onShowReportsEventClick()"
    [menuIcon]="'File'"
    [menuLabel]="'Employee.Reports'"
    [menuTooltip]="'Employee.Reports'"
    [actionButtonMenu]="true"
    [menuButtonClass]="'Action-button Action-buttonReports'"
    [menuButtonId]="'ActionButtonReports'"
  >
  </app-menu-button>
</div>
<div class="Actions" *ngIf="isMobile">
  <app-menu-button
    [menuIcon]="'ThreeDots'"
    [menuLabel]="'More.Title'"
    [menuTooltip]="'More.Title'"
    [multiMenuItems]="true"
  >
    <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
  </app-menu-button>
</div>

<app-dialog [overlayClose]="false" (action)="onBalanceDateDialogAction($event)" [(visible)]="balanceDateDialogVisible">
  <div class="Modal-header" [innerHTML]="'CompanyDataImport.DateForBalance' | translate"></div>
  <app-date-picker
    [label]="'CompanyDataImport.Date' | translate"
    [editMode]="true"
    [required]="true"
    [(value)]="balanceDate"
  ></app-date-picker>
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action *ngIf="actionType !== 2" [type]="'Ok'"></app-dialog-action>
  <app-dialog-action *ngIf="actionType === 2" [type]="'Upload'"></app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" [(visible)]="alertDialogVisible">
  <div *ngIf="alertType === 'SafariWarning'" [innerHTML]="'Download.SafariWarning_XLSX' | translate"></div>
  <div *ngIf="alertType === 'FileSizeError'" [innerHTML]="'CompanyGeneral.FileSizeErrorMessage_64M' | translate"></div>
  <div
    *ngIf="alertType === 'DanlonPDFSuccess'"
    [innerHTML]="'CompanyDataImport.DanlonPDFSuccessMessage' | translate: { SystemAlias: branding.SystemAlias }"
  ></div>
  <div
    *ngIf="alertType === 'BalancesDanlonPDFSuccess'"
    [innerHTML]="'CompanyDataImport.BalancesDanlonPDFSuccessMessage' | translate: { SystemAlias: branding.SystemAlias }"
  ></div>
  <div *ngIf="alertType === 'DisableDanlon'" [innerHTML]="'CompanyDataImport.DisableDanlonMessage' | translate"></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-create-import-template-dialog
  [(visible)]="showCreateTemplateDialog"
  [importType]="importType"
  (importMappingChange)="onImportMappingChange()"
>
</app-create-import-template-dialog>
<app-upload-import-file-dialog
  [(visible)]="showUploadFileDialog"
  (importMappingChange)="onImportMappingChange()"
  [importType]="importType"
>
</app-upload-import-file-dialog>
