import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NumericTextBoxOptions } from '../../../custom-controls/numeric-edit/numeric-text-box-options';
import { ImportTypesEnum } from '../../../model/enum';

@Component({
  selector: 'app-create-import-template-dialog',
  templateUrl: './create-import-template-dialog.component.html'
})
export class CreateImportTemplateDialogComponent {
  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
      if (value) {
        this.showDataImportDialog();
      }
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public importType: ImportTypesEnum;

  public showImportMappingDialog = false;
  public mappingName: string;
  public startIndex: number;
  public option: NumericTextBoxOptions = { min: 0, step: 1, spinners: false, decimals: 0, format: 'n0' };
  public formData: FormData;
  // public fileDataString: string;
  public formDataString: string;
  public payrollMappingType: ImportTypesEnum;
  public fileSizeDialogVisible = false;
  public uploadFileName: string;

  public get createTemplateButtonEnable(): boolean {
    return !!this.formData && !!this.mappingName && !!this.startIndex;
  }
  public get IsPayrollDataImport(): boolean {
    return this.importType === ImportTypesEnum.Payroll;
  }

  private timeEntriesMappingType: ImportTypesEnum;

  public get ImportTypeForMapping(): ImportTypesEnum {
    if (this.IsPayrollDataImport) {
      return this.payrollMappingType;
    } else if (this.importType === ImportTypesEnum.TimeEntriesRow) {
      return this.timeEntriesMappingType;
    }

    return this.importType;
  }

  public onDialogAction(action: string): void {
    if (action === 'Create') {
      this.showImportMappingDialog = true;
    }
  }

  public onFileSelected(file: File): void {
    if (file) {
      this.uploadFileName = file.name;
      this.formData = new FormData();
      this.formData.append('File', file, file.name);
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result as string;
        this.formDataString = base64.split('base64,')[1];
        // const binaryString = window.atob(this.formDataString);
        // const bytes = [];
        // for (let i = 0; i < binaryString.length; i++) {
        //   bytes.push(binaryString.charCodeAt(i));
        // }
        // this.fileDataString = bytes.join();
      };

      reader.readAsDataURL(file);
    }
  }

  private showDataImportDialog(): void {
    this.mappingName = undefined;
    this.startIndex = 1;
    this.uploadFileName = undefined;
    this.formData = undefined;
    if (this.importType === ImportTypesEnum.Payroll) {
      this.payrollMappingType = this.importType;
    } else if (this.importType === ImportTypesEnum.TimeEntriesRow) {
      this.timeEntriesMappingType = ImportTypesEnum.TimeEntriesRow;
    }
  }

  @Output() public importMappingChange: EventEmitter<void> = new EventEmitter<void>();

  public onImportMappingChange() {
    this.importMappingChange.emit();
  }

  public onClosedMappingDialog() {
    this.visible = false;
  }
}
