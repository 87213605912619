import { EventEmitter } from '@angular/core';
import { RouterStateService } from 'src/app/common/router-state.service';
import { SessionService } from '../../services/session/session.service';
import { AccountantJoinService } from './accountant-join.service';
var AccountantJoinDialogComponent = /** @class */ (function () {
    function AccountantJoinDialogComponent(service, sessionService, stateService) {
        this.service = service;
        this.sessionService = sessionService;
        this.stateService = stateService;
        this.busy = false;
        this.redirectDialogVisible = false;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.onreloginVisible = new EventEmitter();
    }
    Object.defineProperty(AccountantJoinDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            this.visibleValue = value;
            this.visibleChange.emit(value);
            if (value && this.hasMultiCompanyModule) {
                this.dialogAction('Accept');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantJoinDialogComponent.prototype, "hasMultiCompanyModule", {
        get: function () {
            return this.sessionService.feature.hasModuleId(20);
        },
        enumerable: true,
        configurable: true
    });
    AccountantJoinDialogComponent.prototype.dialogAction = function (action) {
        var _this = this;
        if (action === 'Accept') {
            this.busy = true;
            if (this.service.selectedAccount.Id) {
                this.service.joinAccount(this.service.selectedAccount.Id).subscribe(function () {
                    _this.busy = false;
                    _this.redirectDialogVisible = true;
                    _this.visible = false;
                    _this.onreloginVisible.emit();
                }, function () {
                    _this.busy = false;
                });
            }
        }
    };
    AccountantJoinDialogComponent.prototype.redirectToLogin = function () {
        this.stateService.navigateToState('login');
    };
    return AccountantJoinDialogComponent;
}());
export { AccountantJoinDialogComponent };
