import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  AddEvent,
  CellClickEvent,
  ColumnBase,
  GridComponent as kendoGridComponent,
  GridDataResult,
  PageChangeEvent,
  PagerSettings,
  RemoveEvent,
  RowClassArgs,
  SelectionEvent
} from '@progress/kendo-angular-grid';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { CompositeFilterDescriptor, FilterDescriptor, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { ITimeEntryRecord } from 'src/app/services/api/api-model';
import { ListHelper } from '../../common/list-helper';
import { SessionService } from '../../services/session/session.service';
import { GridCellClickEvent } from './cell-click-event';
import { GridActionComponent } from './grid-action.component';
import { GridColumnGroupComponent } from './grid-column-group.component';
import { GridColumnComponent } from './grid-column.component';
import { GridNavigationService } from './grid-navigation.service';
import { GridRadioComponent } from './grid-radio.component';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styles: ['.disabled { cursor: not-allowed; pointer-events: none; }'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridComponent implements AfterViewInit {
  private currentEditmode = false;
  public isCurrentCellValid = true;

  @Input() public isCompanyApproval: boolean;
  @Input() public isCompanyApprovalDetail: boolean;
  @Input() public numberRowSelected: number;
  @Input() public timeEntryRecords: any;
  @Input() public currentTimeEntryStatus: any;
  @Input() public TimeEntryTypes: any;
  @Input() public reFocusAfterChangeEdit = true;
  @Input() public scrollable = 'scrollable';
  @Input() public rowHeight = this.scrollable === 'virtual' ? 28 : null;
  @Input()
  public get editMode(): boolean {
    return this.currentEditmode;
  }
  public set editMode(value: boolean) {
    this.currentEditmode = value;
    if (
      value &&
      this.selectedCell &&
      this.selectedCell.rowIndex > -1 &&
      this.reFocusAfterChangeEdit &&
      this.gridData &&
      this.gridData.data &&
      (this.gridData.data as any[]).length > 0 &&
      (this.gridData.data as any[]).length - 1 >= this.selectedCell.rowIndex
    ) {
      this.editCell(this.selectedCell.rowIndex, this.selectedCell.columnIndex);
      setTimeout(() => {
        const element = document.querySelector('.k-state-focused input') as HTMLElement;
        if (element) {
          element.focus();
        }
      });
    }
  }

  @Input() public addNew: boolean;
  @Input() public addNewText: string;
  @Input() public addNewTooltips = '';
  @Input() public addNewDisable: boolean;
  @Input() public deleteRow: boolean;
  @Input() public deleteRowField: string;
  @Input() public customDeleteRow = false;
  @Input() public idColumn: string;
  @Input() public defaultNewValue: any;
  @Input() public companyTemplates = false;
  @Input() public filterable: any;
  @Input() public filterState: any;
  @Input() public filter: any;
  @Input() public format: any;
  @Input() public isEmployeeTime = false;
  @Input() public showButDisableAddNewButton = false;
  @Input() public disableDeleteButton = false;
  @Input() public DeleteTooltips = '';
  @Input() public height: number;
  @Input() public columnIndexFocusAfterCreate = 0;
  @Input() public selectedRows: any[] = [];
  @Input() public disabled = false;
  @Input() public scrollOnDataSourceChange = true;
  @Input() public allowActionNonEditmode = false;

  // Attr PageList
  @Input() public pageSize = 20;
  @Input() public skip = 0;
  @Input() public pageable = false;
  @Input() public hiddenCount = false;
  @Input() public buttonCount = 0;
  @Input() public miniPager = false;

  // Group columns
  @Input() public isUseSpanHeader = false;
  @Input() public groupHeader: string;
  @Input() public groupWidth: number;
  @Input() public groupLocked = false;
  @Input() public groupTooltip: string;
  @Input() public groupHeaderClass: string;

  private currentRadioValue: any;
  @Input()
  public get radioValue(): any {
    return this.currentRadioValue;
  }

  public set radioValue(value: any) {
    this.currentRadioValue = value;
    this.radioValueChange.emit(value);
  }

  private currentFilterClear = false;
  @Input()
  public get filterClear(): boolean {
    return this.currentFilterClear;
  }

  public set filterClear(value: boolean) {
    if (value) {
      this.onFilterChange({ logic: 'and', filters: [] });
    }
    this.filterClearChange.emit(false);
  }

  private currentSortClear = false;
  @Input()
  public get sortClear(): boolean {
    return this.currentSortClear;
  }

  public set sortClear(value: boolean) {
    if (value) {
      this.onSortChange([]);
    }
    this.sortClearChange.emit(false);
  }

  private currentDisableSelect: boolean;
  @Input()
  public get isDisableSelect(): boolean {
    return this.currentDisableSelect;
  }

  public set isDisableSelect(value: boolean) {
    if (value) {
      setTimeout(() => {
        const gridContent = this.kendoGrid.wrapper.nativeElement.querySelector('.k-grid-content') as HTMLElement;
        const element = this.kendoGrid.wrapper.nativeElement.querySelector(
          '.k-grid-content .activeCell'
        ) as HTMLElement;
        if (element && this.scrollOnDataSourceChange) {
          gridContent.scrollTop = element.offsetTop;
        }
      });
    }
    this.currentDisableSelect = value;
  }
  private oldSelected: any;

  public get pageListSetting(): PagerSettings | boolean {
    if (this.gridData && this.gridData.total <= this.pageSize) {
      return false;
    }

    if (this.miniPager) {
      return this.showMiniPager;
    }

    if (this.buttonCount) {
      return { buttonCount: this.buttonCount, info: !this.hiddenCount };
    }

    return this.pageable;
  }

  public get showMiniPager() {
    return this.miniPager && !!this.originalData && this.originalData.length > this.pageSize;
  }

  @Input() public isReportDialog = false;

  private selectedIdValue: any;
  @Input()
  public get selectedId(): any {
    return this.selectedIdValue;
  }
  public set selectedId(value: any) {
    if (
      (this.selectedIdValue !== value || this.selectedRows.length === 0 || (!this.selectedIdValue && !value)) &&
      !this.isReportDialog
    ) {
      this.selectedIdValue = value;
      this.selectedIdChange.emit(value);
      this.selectedRows = [value];

      if (
        this.data &&
        this.idColumn &&
        (!this.selectedItem || this.selectedItem[this.idColumn] !== this.selectedIdValue)
      ) {
        const selected: any = this.data.find((e: any) => e[this.idColumn] === this.selectedIdValue);
        if (this.selectedItem !== selected) {
          this.selectedItem = selected;
        }
      }

      this.setPage();
      this.loadItems();
    }
  }
  @Output() public selectedIdChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public skipChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public disableButtonAddNewClickEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() public updateDefaultRecordEvent: EventEmitter<any> = new EventEmitter<any>();

  private selectedItemValue: any;
  @Input()
  public get selectedItem(): any {
    return this.selectedItemValue;
  }
  public set selectedItem(value: any) {
    const previousValue: any = this.selectedItemValue;
    this.selectedItemValue = value;
    if (this.idColumn) {
      this.selectedId = value ? value[this.idColumn] : undefined;
    }

    if (this.selectedItemValue !== previousValue) {
      this.selectedItemChange.emit(value);
    }
  }
  @Output() public selectedItemChange: EventEmitter<any> = new EventEmitter<any>();

  public gridData: GridDataResult;
  private originalData: any[];
  @Input()
  public get data(): any[] {
    this.onInitData(this.originalData);
    return this.originalData;
  }
  public set data(value: any[]) {
    if (this.originalData !== value) {
      this.originalData = value || [];
      if (this.originalData.length > 0) {
        this.originalData = ListHelper.createObserveArray(
          this.originalData,
          () => this.triggerComponentChanged(),
          () => this.triggerComponentChanged()
        );
        this.dataChange.emit(this.originalData);
      }

      if (
        !this.selectedItem ||
        !this.originalData ||
        this.originalData.length === 0 ||
        (this.idColumn &&
          !this.originalData.find((item: any) => item[this.idColumn] === this.selectedItem[this.idColumn]))
      ) {
        if (!this.selectedId) {
          this.selectedRows = [];
        }
      }

      if (!this.selectedId) {
        this.skip = 0;
      } else {
        this.setPage();
      }

      this.loadItems();
      setTimeout(() => {
        const gridContent = this.kendoGrid.wrapper.nativeElement.querySelector('.k-grid-content') as HTMLElement;
        const element = this.kendoGrid.wrapper.nativeElement.querySelector(
          '.k-grid-content .k-state-selected'
        ) as HTMLElement;
        if (element && this.scrollOnDataSourceChange) {
          gridContent.scrollTop = element.offsetTop;
        }
      }, 1500);

      if (this.isCompanyApproval) {
        for (let i = 0; i < this.data.length; i++) {
          this.kendoGrid.collapseRow(i);
        }
      }
    }
  }

  private setPage() {
    if (this.data && this.idColumn && this.pageable) {
      const index = this.data.findIndex((d) => d[this.idColumn] === this.selectedIdValue);
      if (index > -1) {
        const page = index / this.pageSize;
        this.skip = Math.floor(page) * this.pageSize;
      } else {
        this.skip = 0;
      }
    }
  }

  private loadItems(): void {
    if (this.originalData) {
      if (this.pageable) {
        this.gridData = {
          data: this.originalData.slice(this.skip, this.skip + this.pageSize),
          total: this.originalData.length
        };
      } else {
        this.gridData = {
          data: this.originalData,
          total: this.originalData.length
        };
      }
    }
  }

  @Output() public dataChange: EventEmitter<any> = new EventEmitter<any>();

  private triggerUpdateValue: boolean;
  @Input()
  public get triggerUpdate(): boolean {
    return this.triggerUpdateValue;
  }
  public set triggerUpdate(value: boolean) {
    if (value) {
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
      setTimeout(() => {
        this.triggerUpdateValue = false;
        this.triggerUpdateChange.emit(this.triggerUpdateValue);
      });
    }
  }
  @Output() triggerUpdateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public addNewEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() public removeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() public cellCloseEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() public saveChangesEvent: EventEmitter<CellCloseEvent> = new EventEmitter<CellCloseEvent>();
  @Output() public action: EventEmitter<string> = new EventEmitter<string>();
  @Output() public cellClick: EventEmitter<GridCellClickEvent> = new EventEmitter<GridCellClickEvent>();
  @Output() public gridButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() public clickAppGridIcon: EventEmitter<any> = new EventEmitter<any>();
  @Output() public controlDataChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public dataStateChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public filterChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public ButtonPopUpClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() public iconControlClick: EventEmitter<any> = new EventEmitter<any>();
  //@Output() public addonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public dblClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() public DisableHotKeys: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public radioValueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public filterClearChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public sortClearChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public controlValidChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('kendoGridComponent', { static: true }) public kendoGrid: kendoGridComponent;
  @ContentChildren(GridColumnComponent) public columns: QueryList<GridColumnComponent>;
  @ContentChildren(GridActionComponent) public actions: QueryList<GridActionComponent>;
  @ContentChildren(GridRadioComponent) public radios: QueryList<GridRadioComponent>;

  @ContentChildren(GridColumnGroupComponent) public groupColumns: QueryList<GridColumnGroupComponent>;

  private selectedCell: { field: string; rowIndex: number; columnIndex: number };
  private navigationService: GridNavigationService = new GridNavigationService();
  private isDataChanged = false;
  private formGroup: any;

  constructor(
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    public sessionService: SessionService
  ) {}

  public get isAppLoading(): boolean {
    return this.sessionService.isGetting;
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.skipChange.emit(this.skip);
    this.loadItems();
  }

  private triggerComponentChanged(): void {
    setTimeout(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  public trackIndex(index: number, item: any): any {
    if (item.hideColumn) {
      return null;
    }
    return index;
  }

  public rowCallback(context: RowClassArgs): any {
    return { highlightedRow: context.dataItem && context.dataItem.HightlightedRow };
  }

  public cellClass(column: GridColumnComponent): string {
    const res: string =
      `${column.class} ` + (!column.editableField && !column.editable ? 'grid-disable-cell' : 'grid-enable-cell');
    return res;
  }

  public templateClass(column: GridColumnComponent, dataItem: any): string {
    return column && dataItem && column.classField ? dataItem[column.classField] : undefined;
  }

  public get isSelected(): boolean {
    if (this.isDisableSelect) {
      return false;
    }
    return true;
  }

  public cellClickHandler(args: CellClickEvent): any {
    this.selectedCell = { field: args.column.field, rowIndex: args.rowIndex, columnIndex: args.columnIndex };
    if (this.oldSelected && this.oldSelected.rowIndex === this.selectedCell.rowIndex && this.isCompanyApprovalDetail) {
      this.onSelectedRowCompanyApproval(args.dataItem);
      const listRows = document.querySelectorAll('tr');
      let index = -1;
      for (let i = 0; i < listRows.length; i++) {
        const row = listRows[i];
        const column = row.querySelectorAll('td')[0];
        if (column && column.innerText === args.dataItem.EmployeeName) {
          index += 1;
          if (index === this.oldSelected.rowIndex) {
            row.classList.add('k-state-selected');
            return;
          }
        } else {
          index = -1;
        }
      }
    } else {
      this.oldSelected = this.selectedCell;
    }

    const isEdited: boolean = args.isEdited;
    if (this.editMode && !isEdited) {
      args.sender.editCell(args.rowIndex, args.columnIndex, this.createFormGroup(args.dataItem));
      this.isDataChanged = false;
      setTimeout(() => {
        const element = document.querySelector('.k-state-focused input') as HTMLElement;
        if (element) {
          element.focus();
        }
      });
    }
    let clickToIcon = false;
    if (args && args.originalEvent && args.originalEvent.target) {
      clickToIcon = args.originalEvent.target.className.indexOf('IconContainer') >= 0;
    }
    this.cellClick.emit({ field: args.column.field, dataItem: args.dataItem, isClickToIcon: clickToIcon });
  }

  public onSelectionChange(args: SelectionEvent): void {
    if (args && args.selectedRows && args.selectedRows.length > 0) {
      this.selectedItem = args.selectedRows[0].dataItem;
    }
    if (this.isCompanyApproval) {
      this.onSelectedRowCompanyApproval(args.selectedRows[0].dataItem, true);
    }
  }

  public onDbClickChange(event: any): void {
    // Run after onSelectionChange
    setTimeout(() => {
      if (event) {
        this.selectedItem = this.selectedItem || {};
        this.selectedItem.event = event;
        this.dblClick.emit(this.selectedItem);
      }
    });
  }

  public addHandler(args: AddEvent): void {
    this.addNewDisable = true;
    if (this.defaultNewValue) {
      this.updateDefaultRecordEvent.emit();
      setTimeout(() => {
        this.addHandlerFunction(args);
      });
    } else {
      this.addHandlerFunction(args);
    }
  }

  public addHandlerFunction(args: AddEvent): void {
    let dataItem: any = {};
    if (this.defaultNewValue) {
      dataItem = this.defaultNewValue;
    } else {
      if (this.columns && this.columns.length > 0) {
        this.columns.forEach((column: GridColumnComponent) => {
          if (column.field) {
            dataItem[column.field] = undefined;
          }
        });
      }
    }

    if (!this.data) {
      this.data = [];
    }

    if (this.isEmployeeTime) {
      this.data.unshift(dataItem);
    } else {
      this.data.push(dataItem);
    }

    this.selectedItem = dataItem;
    this.addNewEvent.emit(dataItem);
    const formGroup: any = this.createFormGroup(dataItem);

    if (this.isEmployeeTime) {
      this.selectedCell = {
        field: this.columns ? this.columns.toArray()[this.columnIndexFocusAfterCreate].field : '',
        rowIndex: 0,
        columnIndex: this.columnIndexFocusAfterCreate
      };
      args.sender.editCell(0, this.columnIndexFocusAfterCreate, formGroup);
    } else {
      this.selectedCell = {
        field: this.columns ? this.columns.toArray()[0].field : '',
        rowIndex: this.data.length - 1,
        columnIndex: this.columnIndexFocusAfterCreate
      };
      args.sender.editCell(this.data.length - 1, this.columnIndexFocusAfterCreate, formGroup);
    }
    //args.sender.editCell(0, 0, formGroup);
    this.isDataChanged = false;
    this.addNewDisable = false;
  }

  private checkModelRecord(record: any) {
    Object.keys(record).forEach((key) => {
      if (record[key] === undefined) {
        record[key] = null;
      }
    });

    return record;
  }

  public cellCloseHandler(args: CellCloseEvent): void {
    setTimeout(() => {
      const dataChanged: boolean = this.isDataChanged;
      if (this.isColumnDataChanged(args) || dataChanged) {
        this.checkModelRecord(args.dataItem);
        this.saveChangesEvent.emit(args);
        this.triggerComponentChanged();
        this.isDataChanged = false;
      }

      this.cellCloseEvent.emit(args);
    });
  }

  private isColumnDataChanged(args: any): boolean {
    let dataChanged: boolean;
    const { column, dataItem, formGroup } = args;
    if (dataItem && formGroup && formGroup.value && column && column.field) {
      dataChanged =
        ((dataItem[column.field] === null || dataItem[column.field] === undefined) &&
          formGroup.value[column.field] !== null &&
          formGroup.value[column.field] !== undefined) ||
        (dataItem[column.field] !== null &&
          dataItem[column.field] !== undefined &&
          (!formGroup.value.hasOwnProperty(column.field) ||
            formGroup.value[column.field] === null ||
            formGroup.value[column.field] === undefined)) ||
        (dataItem[column.field] !== null &&
          dataItem[column.field] !== undefined &&
          formGroup.value[column.field] !== null &&
          formGroup.value[column.field] !== undefined &&
          dataItem[column.field] !== formGroup.value[column.field]);
    }

    return dataChanged;
  }

  public removeHandler(args: RemoveEvent): void {
    if (!this.isAppLoading) {
      if (!this.customDeleteRow) {
        // this.selectedItemChange.emit(args.dataItem);
        const indexOfRemovedItem: number = this.data.indexOf(args.dataItem);
        this.data.splice(indexOfRemovedItem, 1);
        args.sender.closeRow(args.rowIndex);
        this.removeEvent.emit(args.dataItem);
      } else {
        this.removeEvent.emit(args);
      }

      this.triggerComponentChanged();
    }
  }

  public onKeyDown(event: any): void {
    if (
      (event.key === 'Tab' || event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Escape') &&
      this.selectedCell
    ) {
      const cell: any = this.navigationService.getNextEditableCell(
        this.getDisplayedColumns(),
        this.data,
        this.selectedCell.columnIndex,
        this.selectedCell.rowIndex,
        event
      );
      if (event.key === 'Escape') {
        setTimeout(() => {
          this.editCell(this.selectedCell.rowIndex, this.selectedCell.columnIndex, true);
          this.kendoGrid.closeCell();
        });
      } else if (cell) {
        this.kendoGrid.focusCell(cell.rowIndex + 1, cell.columnIndex);
        this.editCell(cell.rowIndex, cell.columnIndex);

        setTimeout(() => {
          const element = document.querySelector('.k-state-focused input') as HTMLElement;
          if (element) {
            element.focus();
          }
        });

        if (this.editMode) {
          this.createFormGroup(this.data ? this.data[cell.rowIndex] : undefined);
        }
      }

      event.preventDefault();
      event.stopPropagation();
    }
    if (event.key === 'Enter' && this.selectedCell) {
      if (this.isCurrentCellValid) {
        this.isDataChanged = true;
      }
      this.isCurrentCellValid = true;
    }
  }

  public onDataChanged(dataItem: any, field: any, editableField?: any, isAllowNullValue: boolean = true): void {
    const args: any = {
      column: { field },
      dataItem,
      formGroup: this.formGroup
    };

    if (
      this.controlDataChange.observers &&
      this.controlDataChange.observers.length > 0 &&
      this.isColumnDataChanged(args)
    ) {
      this.isDataChanged = true;
      if (isAllowNullValue) {
        this.formGroup.value[field] = dataItem[field];
      } else {
        if (dataItem[field]) {
          this.formGroup.value[field] = dataItem[field];
        } else {
          dataItem[field] = this.formGroup.value[field];
        }
      }

      this.controlDataChange.emit({ dataItem, field, editableField });
    }
  }

  public onCheckBoxCheckedChanged(dataItem: any, field: string, isIgnoreCheckData: boolean = false): void {
    const event: CellCloseEvent = new CellCloseEvent({ dataItem });
    const args: any = {
      column: { field },
      dataItem,
      formGroup: this.formGroup
    };

    if (this.isColumnDataChanged(args) || isIgnoreCheckData) {
      this.createFormGroup(dataItem);
      this.checkModelRecord(dataItem);
      this.saveChangesEvent.emit(event);
      this.triggerComponentChanged();
    }
  }

  private getDisplayedColumns(): GridColumnComponent[] {
    const displayedColumns: GridColumnComponent[] = [];
    if (this.isUseSpanHeader) {
      const arrGroupColumns: GridColumnGroupComponent[] = this.groupColumns.toArray();
      for (let index = 0; index < arrGroupColumns.length; index++) {
        const column: GridColumnGroupComponent = arrGroupColumns[index];
        displayedColumns.push(column as any);
      }
    }

    const arrColumns: GridColumnComponent[] = this.columns
      .toArray()
      .filter((col: GridColumnComponent) => !col.hideColumn);

    for (let index = 0; index < arrColumns.length; index++) {
      const column: GridColumnComponent = arrColumns[index];
      if (!column.isGroupColumn) {
        displayedColumns.push(column);
      } else {
        const arrSubColumns: GridColumnComponent[] = column.columns.toArray();
        for (let subIndex = 0; subIndex < arrSubColumns.length; subIndex++) {
          const subColumn: GridColumnComponent = arrSubColumns[subIndex];
          if (!subColumn.isGroupColumn) {
            displayedColumns.push(subColumn);
          }
        }
      }
    }

    return displayedColumns;
  }

  private createFormGroup(dataItem: any): FormGroup {
    this.formGroup = this.formBuilder.group(dataItem);
    return this.formGroup;
  }

  private editCell(rowIndex: number, columnIndex: number, isIgnore: boolean = false): void {
    if (!isIgnore && this.selectedCell) {
      this.selectedCell.rowIndex = rowIndex;
      this.selectedCell.columnIndex = columnIndex;
    }

    const dataItem: any = this.data[rowIndex];
    this.selectedItem = dataItem;
    if (this.editMode) {
      this.kendoGrid.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
    }
    if (!this.selectedItem || !this.selectedItem['EntryDate'] || !this.selectedItem['TimeEntryTypeId']) {
      this.isDataChanged = false;
    }
  }

  public getParamSubProberty(dataItem: any, subproperty: string): string {
    if (subproperty) {
      const arraySubproperty: string[] = subproperty.split('.');
      let object: any = dataItem;
      for (let i = 0; i < arraySubproperty.length; ++i) {
        if (!object[arraySubproperty[i]]) {
          break;
        }
        object = object[arraySubproperty[i]];
      }

      return object;
    }

    return '';
  }

  public appIconClick(dataItem: any, iconAction: string): void {
    this.clickAppGridIcon.emit({ dataItem, iconAction });
    this.triggerComponentChanged();
  }

  public onGridButtonClickEvent(dataItem: any, buttonAction: string, isDisable?: boolean): void {
    if (isDisable) {
      return;
    }
    this.gridButtonClick.emit({ dataItem, buttonAction });
    this.triggerComponentChanged();
  }

  public onIncellPopUpClickAction(dataItem: any, field: string, args: any): void {
    const buttonAction: string = args.action;
    this.gridButtonClick.emit({ dataItem, buttonAction, field });
    // dataItem[displayField] = false;
    this.triggerComponentChanged();
  }

  public onDataStateHandler(event: any): void {
    this.dataStateChange.emit(event);
    this.kendoGrid.expandRow(0);
  }

  public sort: SortDescriptor[] = [];

  public onSortChange(sort: SortDescriptor[]): void {
    this.columns.map((item: GridColumnComponent) => {
      if (item.addOnClass && item.addOnClass.includes(' headerSort')) {
        item.addOnClass = item.addOnClass.replace(' headerSort', '');
      }
    });

    if (sort && sort.length > 0) {
      sort.forEach((iteminlist: any) => {
        this.columns.map((item: GridColumnComponent) => {
          if (item.field === iteminlist.field && iteminlist.dir) {
            if (!item.addOnClass || (item.addOnClass && !item.addOnClass.includes('headerSort'))) {
              item.addOnClass += ' headerSort';
            }
          }
        });
      });
    }
    if (this.data && this.data.length > 0) {
      this.sort = sort;
      const gridView: any = {
        data: orderBy(this.data, this.sort),
        total: this.data.length
      };
      this.data = gridView.data;
    }

    this.collapseRowonGrid();
  }

  public onFilterChange(filter: CompositeFilterDescriptor): void {
    if (this.groupColumns || this.columns) {
      // Update header for group columns
      this.updateHeaderFilter(filter, this.groupColumns);
      // Update header for columns
      this.updateHeaderFilter(filter, this.columns);
      this.collapseRowonGrid();
      this.filterChange.emit(filter);
    }
  }

  private updateHeaderFilter(filter: CompositeFilterDescriptor, columns: any) {
    if (columns && columns.length > 0) {
      columns.map((item: any) => {
        if (item.addOnClass && item.addOnClass.includes(' headerFilter')) {
          item.addOnClass = item.addOnClass.replace(' headerFilter', '');
        }
      });
      const filterList: Array<FilterDescriptor | CompositeFilterDescriptor> = filter.filters;
      if (filterList && filterList.length > 0) {
        filterList.forEach((iteminlist: any) => {
          columns.map((item: any) => {
            if (item.field === iteminlist.filters[0].field) {
              if (!item.addOnClass || (item.addOnClass && !item.addOnClass.includes('headerFilter'))) {
                item.addOnClass += ' headerFilter';
              }
            }
          });
        });
      }
    }
  }

  public afterFitcolumns = false;
  public ngAfterViewInit(): void {}

  public show = false;

  public onToggle(): void {
    this.show = !this.show;
  }

  public AddNewDisableClick(): void {
    if (this.addNewDisable) {
      //this.disableButtonAddNewClickEvent.emit();
    }
  }

  public onGridToolbarClick(event: any): void {
    this.action.emit(event);
  }

  private keyPress: any = { 17: false, 18: false, 107: false, 109: false };
  @HostListener('document:keydown', ['$event'])
  onMoewKeyDown(event: any): void {
    if (event.keyCode in this.keyPress) {
      this.keyPress[event.keyCode] = true;
      if (this.keyPress[17] && this.keyPress[18] && this.keyPress[107]) {
        // Add new Event
        if (this.addNew) {
          setTimeout(() => {
            if (!this.sessionService.isGetting && !this.sessionService.isSubmitting) {
              this.addHandler({ dataItem: {}, isNew: true, rowIndex: 0, sender: this.kendoGrid });
            }
          });
          this.setKeypress();
        } else {
          this.DisableHotKeys.emit(true);
          this.setKeypress();
        }
      }
    } else {
      this.setKeypress();
    }
  }

  public setKeypress(): void {
    this.keyPress[17] = false;
    this.keyPress[18] = false;
    this.keyPress[107] = false;
    this.keyPress[109] = false;
  }

  public onSelectedRowCompanyApproval(event: any, isNotClick: boolean = false) {
    const listRows = document.querySelectorAll('table tbody tr.k-master-row');
    const listDetailRows = document.querySelectorAll('table tbody tr.k-detail-row');

    for (let i = 0; i < listRows.length; i++) {
      const column = listRows[i].querySelectorAll('td')[1] as HTMLElement;
      if (column && column.innerText === event.EmployeeName) {
        if (!isNotClick) {
          column.click();
        }

        this.removeSelectAnotherRow(listDetailRows, i, event.EmployeeName);
        break;
      }
    }
  }

  private removeSelectAnotherRow(listDetailRows: any, selectedIndex: number, employeeName: string) {
    listDetailRows.forEach((row: HTMLElement, index: number) => {
      const listDR = row.querySelectorAll('tr');
      for (let i = 1; i < listDR.length; i++) {
        const eNameColumn = listDR[i].querySelectorAll('td')[0] as HTMLElement;
        if (eNameColumn && eNameColumn.innerText === employeeName) {
          break;
        }
        listDR[i].classList.remove('k-state-selected');
      }
    });
  }

  public onGridRadioToolbarClick(): void {}

  private selectedApproveItem: any;
  @Output() public approveRejectData: EventEmitter<any> = new EventEmitter<any>();
  public onApproveRejectData(): void {
    this.selectedApproveItem = this.selectedItem;
    this.approveRejectData.emit();
  }

  private collapseRowonGrid() {
    if (this.isCompanyApproval) {
      for (let i = 0; i < this.data.length; i++) {
        this.kendoGrid.collapseRow(i);
      }
    }
  }

  public onOpenApprovalGridDetail(): void {
    setTimeout(() => {
      this.selectedApproveItem = null;
    });
  }

  public onInitData(data: any): void {
    let indexItem = -1;
    if (this.selectedApproveItem) {
      for (let i = 0; i < data.length; i++) {
        if (this.selectedApproveItem.EmployeeName === data[i].EmployeeName) {
          indexItem = i;
          break;
        }
      }
      if (indexItem >= 0) {
        this.selectedItem = this.selectedApproveItem;
        this.kendoGrid.expandRow(indexItem);
      }
    }
  }

  public controlValid(event: any, subColumn: string): void {
    this.isCurrentCellValid = event;
    this.controlValidChange.emit({ isvalid: event, field: subColumn });
  }

  public onHalfCheckChange(event: any): void {
    const dataItem = event.dataItem;
    const checked = event.checked;
    const type = event.type;

    if (dataItem) {
      if (type === 'AppliesWeekdays') {
        dataItem.AppliesMondays = checked;
        dataItem.AppliesTuesdays = checked;
        dataItem.AppliesWednesdays = checked;
        dataItem.AppliesThursdays = checked;
        dataItem.AppliesFridays = checked;
      } else if (type === 'AppliesSunHolis') {
        dataItem.AppliesSundays = checked;
        dataItem.AppliesHolidays = checked;
      }

      this.onCheckBoxCheckedChanged(dataItem, 'AppliesHalfCheckbox', true);
    }
  }

  public iconTextEditControlClick(action: string, dataItem: any) {
    this.iconControlClick.emit({ action, dataItem });
  }
}
