import { NgProgress } from '@ngx-progressbar/core';
import { throwError as observableThrowError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { SessionService } from './session/session.service';
import * as i0 from "@angular/core";
import * as i1 from "./session/session.service";
import * as i2 from "@ngx-progressbar/core";
var BusyInterceptor = /** @class */ (function () {
    function BusyInterceptor(service, progress) {
        this.service = service;
        this.progress = progress;
        this.inProgressCount = 0;
        this.submittingCount = 0;
        this.gettingCount = 0;
        this.progressRef = this.progress.ref('appProgress');
    }
    BusyInterceptor.prototype.isSessionIsAliveGet = function (req) {
        if (req && req.method === 'GET' && req.url.includes('session/isalive')) {
            return true;
        }
        return false;
    };
    BusyInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        this.service.ResetIsAliveTimer();
        this.inProgressCount++;
        this.progressRef.start();
        if (req.method === 'PATCH' || req.method === 'PUT' || req.method === 'POST' || req.method === 'DELETE') {
            this.submittingCount++;
            this.service.isSubmitting = true;
        }
        if (req.method === 'GET' && !this.isSessionIsAliveGet(req)) {
            this.gettingCount++;
            this.service.isGetting = true;
        }
        return next.handle(req).pipe(finalize(function () {
            _this.inProgressCount--;
            if (req.method === 'PATCH' || req.method === 'PUT' || req.method === 'POST' || req.method === 'DELETE') {
                _this.submittingCount--;
                if (_this.submittingCount <= 0) {
                    _this.service.isSubmitting = false;
                }
            }
            if (req.method === 'GET' && !_this.isSessionIsAliveGet(req)) {
                _this.gettingCount--;
                if (_this.gettingCount <= 0) {
                    _this.service.isGetting = false;
                }
            }
            if (_this.inProgressCount <= 0) {
                _this.progressRef.set(80);
                setTimeout(function () {
                    _this.progressRef.complete();
                }, 1000);
            }
        }), catchError(function (err) {
            if (err.status === 0 || err.status === -1) {
                _this.gettingCount--;
                if (_this.gettingCount <= 0) {
                    _this.service.isGetting = false;
                }
            }
            return observableThrowError(err);
        }));
    };
    BusyInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BusyInterceptor_Factory() { return new BusyInterceptor(i0.ɵɵinject(i1.SessionService), i0.ɵɵinject(i2.NgProgress)); }, token: BusyInterceptor, providedIn: "root" });
    return BusyInterceptor;
}());
export { BusyInterceptor };
