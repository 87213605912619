import { ChangeDetectorRef, Component, ContentChildren, ElementRef, Input, QueryList } from '@angular/core';
import { ControlComponentBase } from '../../common/control-component-base';
import { UniqueId } from '../../common/unique-id';
import { RadioEditOptionComponent } from './radio-edit-option.component';

@Component({
  selector: 'app-radio-edit',
  templateUrl: './radio-edit.component.html'
})
export class RadioEditComponent extends ControlComponentBase<any> {
  @Input() vertical = false;
  @Input() disable: boolean;
  @Input() openLink: string;

  @ContentChildren(RadioEditOptionComponent) public options: QueryList<RadioEditOptionComponent>;

  public controlId: string;

  constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
    this.controlId = 'radioEdit' + UniqueId();
  }

  protected valid(): boolean {
    return true;
  }

  public onClick(value: any): void {
    this.value = value;
    super.onChange();
  }

  public onReadMoreClick(openLink: any): void {
    window.open(openLink);
  }
}
