import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { IUserEmploymentTemplate } from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { CompanyService } from '../../../services/company.service';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyTemplatesService extends CompanyService implements OnDestroy {
  private templateSubject: BehaviorSubject<IUserEmploymentTemplate[]>;
  public allTemplates: IUserEmploymentTemplate[];
  public get templates(): Observable<IUserEmploymentTemplate[]> {
    if (!this.templateSubject) {
      this.templateSubject = new BehaviorSubject<IUserEmploymentTemplate[]>([]);
    }
    return this.templateSubject.asObservable();
  }

  public loadTemplates(selectTemplateId?: number) {
    if (!this.templateSubject) {
      this.templateSubject = new BehaviorSubject<IUserEmploymentTemplate[]>([]);
    }
    this.dataService
      .EmploymentTemplates_GetEmploymentTemplates(selectTemplateId ? true : false)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((templates: IUserEmploymentTemplate[]): void => {
        const res = templates;
        this.templateSubject.next(res && res.length > 0 ? res : []);
        this.allTemplates = res || [];

        if (this.allTemplates.length > 0) {
          this.isEnable = true;
        } else {
          this.isEnable = false;
        }

        if (selectTemplateId || (this.allTemplates && this.allTemplates.length > 0)) {
          this.selectedTemplateId = selectTemplateId || this.allTemplates[0].Id;
        }

        if (selectTemplateId) {
          this.editMode = true;
        }
      });
  }

  private selectedTemplateIdValue: number;
  public get selectedTemplateId(): number {
    return this.selectedTemplateIdValue;
  }
  public set selectedTemplateId(value: number) {
    if (value && this.selectedTemplateIdValue !== value) {
      this.selectedTemplateIdValue = value;
      this.selectedTemplateIdChange.emit(value);
    }
  }

  private templateDetailValue: IUserEmploymentTemplate;
  public get templateDetail(): IUserEmploymentTemplate {
    if (this.templateDetailValue === undefined) {
      return {} as any;
    }

    return this.templateDetailValue;
  }
  public set templateDetail(value: IUserEmploymentTemplate) {
    if (value !== this.templateDetailValue) {
      if (value) {
        this.upDateTemplateSenioritySupplements(value);
      }

      this.templateDetailValue = value;
    }
  }

  public selectedTemplateIdChange: EventEmitter<any> = new EventEmitter<any>();
  public updateTemplateOnlyChange: EventEmitter<any> = new EventEmitter<any>();
  public resetFormChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected dataService: DataService,
    protected staticDataService: StaticDataService,
    protected settingService: SettingService,
    protected sessionService: SessionService,
    protected transitionService: TransitionService,
    protected broadcaster: BroadcastService
  ) {
    super(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster);
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public refreshTemplateGridList: boolean;
  public isEnable: boolean;
  public templateHasEmployees: boolean;
  public confirmSaveOrUpdateDialogVisible: boolean;

  protected onContinueAction(): void {
    this.onSave();
  }

  protected onDiscardAndLeaveAction(): void {
    this.onDiscard();
  }

  public onSave() {
    if (this.tabDirty && this.templateDetail && this.tabValid) {
      if (this.templateHasEmployees) {
        this.confirmSaveOrUpdateDialogVisible = true;
      } else {
        this.updateTemplateOnly();
      }
    } else if (this.tabValid) {
      this.editMode = false;
    }
  }

  public getEmploymentTemplate() {
    return this.dataService.EmploymentTemplates_GetEmploymentTemplate(this.selectedTemplateId).pipe(
      tap((templateDetail: IUserEmploymentTemplate): void => {
        this.templateDetail = templateDetail;
        this.resetFormChange.emit();
      })
    );
  }

  public onDiscard(): void {
    if (this.selectedTemplateId) {
      this.editMode = false;
      this.getEmploymentTemplate().subscribe(() => {
        this.updateTemplateListTemplateName();
        this.resetFormChange.emit();

        this.moveToNextState();
      });
    }
  }

  public onUpdateTemplate() {
    const template: any = this.templateDetail;
    delete template['GridId'];
    this.FixCorrectTemplateValue(template);

    if (template.TemplateBalanceConfigurations && (template.TemplateBalanceConfigurations as any[]).length > 0) {
      template.TemplateBalanceConfigurations[0].EarningPercentage =
        template.TemplateBalanceConfigurations[0].EarningPercentage || null;
      template.TemplateBalanceConfigurations[0].BaseCalculationMethodId =
        template.TemplateBalanceConfigurations[0].BaseCalculationMethodId || null;
      template.TemplateBalanceConfigurations[0].PensionSharePercentage =
        template.TemplateBalanceConfigurations[0].PensionSharePercentage || null;
    }

    return this.dataService.EmploymentTemplates_UpdateEmploymentTemplate(template).pipe(
      tap((templateDetail: IUserEmploymentTemplate): void => {
        this.editMode = false;
        this.templateDetail = templateDetail;
      })
    );
  }

  public onCreateTemplate(): void {
    // TODO on create template
  }

  public updateTemplateListTemplateName(): void {
    if (this.templateDetail && this.allTemplates) {
      const templateInList: IUserEmploymentTemplate = this.allTemplates.find((template: IUserEmploymentTemplate) => {
        return template.Id === this.templateDetail.Id;
      });

      if (templateInList && templateInList.Name !== this.templateDetail.Name) {
        templateInList.Name = this.templateDetail.Name;
        this.refreshTemplateGridList = !this.refreshTemplateGridList;
      }
    }
  }

  public updateTemplateOnly(): void {
    this.onUpdateTemplate().subscribe(() => {
      this.updateTemplateListTemplateName();
      this.updateTemplateOnlyChange.emit();

      this.moveToNextState();
    });
  }

  public updateTemplateAllEmployee() {
    const templateDetail: any = this.templateDetail;
    delete templateDetail['GridId'];
    this.FixCorrectTemplateValue(templateDetail);

    return this.dataService.EmploymentTemplates_UpdateEmploymentTemplateAndApplyChanges(templateDetail).pipe(
      tap((template: IUserEmploymentTemplate): void => {
        this.resetFormChange.emit();
        this.editMode = false;
        this.templateDetail = template;
        this.updateTemplateListTemplateName();
      })
    );
  }

  private upDateTemplateSenioritySupplements(template: IUserEmploymentTemplate): void {
    if (template.SenioritySupplements && template.SenioritySupplements.length > 0) {
      template.SenioritySupplements.forEach((item: any) => {
        item.GridId = item.Id;
      });
    } else {
      template.SenioritySupplements = [];
    }

    if (template.OvertimeSupplements && template.OvertimeSupplements.length > 0) {
      template.OvertimeSupplements.forEach((item: any) => {
        item.GridId = item.Id;
      });
    } else {
      template.OvertimeSupplements = [];
    }
  }

  private FixCorrectTemplateValue(data: IUserEmploymentTemplate): void {
    data.VacationProviderId = data.VacationProviderId || null;
    data.VacationSupplementPayoutMonth =
      data.VacationSupplementPayoutMonth === undefined ? 5 : data.VacationSupplementPayoutMonth;
    data.StatisticsSalaryPrincipleId =
      data.StatisticsSalaryPrincipleId === undefined ? null : data.StatisticsSalaryPrincipleId;
    data.StatisticsEmploymentPrincipleId =
      data.StatisticsEmploymentPrincipleId === undefined ? null : data.StatisticsEmploymentPrincipleId;
    data.StatisticsEmploymentTermsId =
      data.StatisticsEmploymentTermsId === undefined ? null : data.StatisticsEmploymentTermsId;
    data.StatisticsJobStatusId = data.StatisticsJobStatusId === undefined ? null : data.StatisticsJobStatusId;
    data.DepartmentId = data.DepartmentId === undefined ? null : data.DepartmentId;
  }

  public serviceDestroy() {
    this.allTemplates = [];

    this.clearArrayDataState(this.templateSubject);
  }

  private clearArrayDataState(data: BehaviorSubject<any[]>) {
    if (data) {
      data.next([]);
    }

    this.selectedTemplateId = undefined;
    this.templateDetail = undefined;
  }

  protected get allowShortcut(): boolean {
    return this.sessionService.currentState === 'tabs.company.configuration.hiringstatus';
  }
}
