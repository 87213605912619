import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { Constants } from 'src/app/common/constants';
import { Global } from 'src/app/common/global';
import { SessionService } from 'src/app/services/session/session.service';
import { environment } from 'src/environments/environment';
import { EmployeeService } from '../../services/employee.service';

@Component({
  selector: 'app-menu-button-item',
  templateUrl: './menu-button-item.component.html'
})
export class MenuButtonItemComponent implements OnInit {
  @Input() public field: string;

  @Input() public isAddNewEmploymentDisable: boolean;

  @Output() public menuItemClick: EventEmitter<string> = new EventEmitter<string>();

  public get isGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public get isIOSMobileApp(): boolean {
    return this.sessionService.browser.iOSMobileDevice;
  }

  public get isEmployee(): boolean {
    return this.sessionService.role.IsEmployeeRole;
  }

  public hasUserEmployment = false;

  public LanguageId = 1;

  public get branding() {
    return environment.branding;
  }

  constructor(
    private sessionService: SessionService,
    public employeeService: EmployeeService,
    public ngxZendeskWebwidgetService: NgxZendeskWebwidgetService
  ) {
    this.employeeService.employment.pipe().subscribe((value) => {
      this.hasUserEmployment = !(!value || !value.Id);
    });
  }

  ngOnInit() {
    this.LanguageId = Global.SESSION ? Global.SESSION.SignOnToken.LanguageId : 1;
  }

  onMoreMenuOptionClick(event: string) {
    if (this.sessionService.isGetting || this.sessionService.isSubmitting) {
      return;
    }
    this.menuItemClick.emit(event);
  }

  public get helpLink(): string {
    switch (this.sessionService.currentState) {
      case 'tabs.employee.general':
        return 'https://support.gratisal.com/hc/da/articles/207563559-Introduktion-til-Gratisal';
      case 'tabs.employee.hiringstatus':
        return 'https://support.gratisal.com/hc/da/articles/207401959-Ans%C3%A6ttelsesdata';
      case 'tabs.employee.time':
        return 'https://support.gratisal.com/hc/da/articles/208468529-Timeregistrering-i-Timer';
      case 'tabs.employee.payrolldata':
        return 'https://support.gratisal.com/hc/da/articles/207401989-Lav-L%C3%B8n-p%C3%A5-Medarbejdere';
      case 'tabs.employee.payslip':
        return 'https://support.gratisal.com/hc/da/articles/360025211251';
      case 'tabs.company.general':
        return 'https://support.gratisal.com/hc/da/articles/207563559-Introduktion-til-Gratisal';
      case 'tabs.company.configuration.hiringstatus':
        return 'https://support.gratisal.com/hc/da/articles/208119495-Medarbejderskabeloner';
      case 'tabs.company.payrolldata':
        return 'https://support.gratisal.com/hc/da/articles/360015945992-Ret-dine-l%C3%B8ndata';
      case 'tabs.company.salarybatches':
        return 'https://support.gratisal.com/hc/da/articles/207402029-S%C3%A5dan-k%C3%B8res-l%C3%B8nnen';
      case 'tabs.company.approval.timeentry':
        return 'https://support.gratisal.com/hc/da/articles/208468529-Timeregistrering-i-Timer#2';
      case 'tabs.company.configuration.dataimport':
        return 'https://support.gratisal.com/hc/da/articles/360018843711-Dataimport-i-Gratisal';
      case 'tabs.company.configuration.advancedsalarytypes':
        return 'https://support.gratisal.com/hc/da/articles/360013219131-L%C3%B8narter-i-Gratisal';
      case 'tabs.company.configuration.integrations':
        return 'https://support.gratisal.com/hc/da/articles/208119325-Integration-med-%C3%98konomisystem';
      case 'tabs.company.configuration.settings':
        return 'https://support.gratisal.com/hc/da/articles/360025209451';
      case 'tabs.company.configuration.timeentrytypes':
        return 'https://support.gratisal.com/hc/da/articles/360025214931';
      case 'tabs.company.modules':
        return 'https://support.gratisal.com/hc/da/articles/360015566252-Gratisal-Moduler-og-Pakker-V3-0';
      case 'tabs.selfservice.payslip':
        return 'https://support.gratisal.com/hc/da/articles/207669539-Se-dine-l%C3%B8nsedler';
      case 'tabs.selfservice.general':
        return 'https://support.gratisal.com/hc/da/articles/208394175-Se-og-redig%C3%A9r-mine-personlige-data';
      case 'tabs.selfservice.time':
        return 'https://support.gratisal.com/hc/da/articles/208468469-Timeregistrering-i-Timer';
      case 'tabs.company.balances.residualvacation':
        return 'https://intect.zendesk.com/hc/da/articles/360018126400-H%C3%A5ndtering-af-restferie-merferie-og-feriefritimer';
      default:
        return 'https://support.gratisal.com/hc/da';
    }
  }

  public onShowZenDesk() {
    const email: string = Global.SESSION.CurrentCompanyUser.PersonalEmail;
    this.ngxZendeskWebwidgetService.zE('webWidget', 'prefill', {
      name: {
        value: Global.SESSION.CurrentCompanyUser.FullName,
        readOnly: true
      },
      email: {
        value: email,
        readOnly: true
      }
    });
    this.ngxZendeskWebwidgetService.zE('webWidget', 'updateSettings', {
      webWidget: {
        offset: { horizontal: '0px', vertical: '55px' },
        zIndex: 90,
        contactOptions: {
          enabled: true,
          chatLabelOnline: { '*': 'Live Chat' },
          chatLabelOffline: { '*': 'Chat is unavailable' },
          contactFormLabel: { '*': 'Leave us a message' }
        },
        chat: {
          '*': 'Live Chat'
        },
        contactForm: {
          title: {
            'en-US': 'Message us',
            '*': 'Contact us'
          }
        }
      }
    });
    // Preshow launcher when component init
    this.ngxZendeskWebwidgetService.zE('webWidget', 'show');

    this.ngxZendeskWebwidgetService.zE('webWidget:on', 'chat:connected', function() {
      console.log('successfully connected to Zendesk Chat!');
    });
    this.ngxZendeskWebwidgetService.zE('webWidget', 'open');
    setTimeout(() => {
      this.sessionService.zenDeskOpen = true;
    }, 750);

    this.ngxZendeskWebwidgetService.zE('webWidget:on', 'userEvent', (event: any) => {
      if (event.action === 'Web Widget Minimised') {
        this.sessionService.zenDeskOpen = false;
        (document.getElementsByClassName('Footer-info')[0] as HTMLElement).click();
        this.onCloseZenDesk();
      }
    });
  }

  public onCloseZenDesk() {
    this.ngxZendeskWebwidgetService.zE('webWidget', 'close');
  }
}
