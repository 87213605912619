/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../../custom-controls/dialogs/dialog.component.ngfactory";
import * as i3 from "../../../custom-controls/dialogs/dialog.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../../services/session/session.service";
import * as i6 from "../../../custom-controls/check-edit/check-edit.component.ngfactory";
import * as i7 from "../../../custom-controls/check-edit/check-edit.component";
import * as i8 from "@angular/common";
import * as i9 from "../../../custom-controls/dialogs/dialog-action.component.ngfactory";
import * as i10 from "../../../custom-controls/dialogs/dialog-action.component";
import * as i11 from "./lock-salary-batch-dialog.component";
import * as i12 from "../../../services/api/data.service";
import * as i13 from "../company-salary-batches.service";
var styles_LockSalaryBatchDialogComponent = [];
var RenderType_LockSalaryBatchDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LockSalaryBatchDialogComponent, data: {} });
export { RenderType_LockSalaryBatchDialogComponent as RenderType_LockSalaryBatchDialogComponent };
function View_LockSalaryBatchDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("CompanySalaryBatches.PreliminaryPayslipExplanation")); _ck(_v, 1, 0, currVal_0); }); }
export function View_LockSalaryBatchDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "app-dialog", [], null, [[null, "action"], [null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("action" === en)) {
        var pd_0 = (_co.onDialogAction($event) !== false);
        ad = (pd_0 && ad);
    } if (("visibleChange" === en)) {
        var pd_1 = ((_co.visible = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_DialogComponent_0, i2.RenderType_DialogComponent)), i0.ɵdid(1, 49152, null, 1, i3.DialogComponent, [i4.DomSanitizer, i5.SessionService, i0.ElementRef], { overlayClose: [0, "overlayClose"], leadingText: [1, "leadingText"], visible: [2, "visible"] }, { visibleChange: "visibleChange", action: "action" }), i0.ɵqud(603979776, 1, { buttonElements: 1 }), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, 0, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "app-check-edit", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.notifyEmployee = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_CheckEditComponent_0, i6.RenderType_CheckEditComponent)), i0.ɵdid(6, 114688, null, 0, i7.CheckEditComponent, [i0.ElementRef, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], label: [2, "label"] }, { valueChange: "valueChange" }), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_LockSalaryBatchDialogComponent_1)), i0.ɵdid(9, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, 0, 1, "app-dialog-action", [], null, null, null, i9.View_DialogActionComponent_0, i9.RenderType_DialogActionComponent)), i0.ɵdid(11, 180224, [[1, 4]], 0, i10.DialogActionComponent, [i5.SessionService], { disabled: [0, "disabled"], type: [1, "type"] }, null), (_l()(), i0.ɵeld(12, 0, null, 0, 1, "app-dialog-action", [], null, null, null, i9.View_DialogActionComponent_0, i9.RenderType_DialogActionComponent)), i0.ɵdid(13, 180224, [[1, 4]], 0, i10.DialogActionComponent, [i5.SessionService], { close: [0, "close"], disabled: [1, "disabled"], type: [2, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform("CompanySalaryBatches.LockSalaryBatchDescription")); var currVal_2 = _co.visible; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.notifyEmployee; var currVal_4 = true; var currVal_5 = i0.ɵunv(_v, 6, 2, i0.ɵnov(_v, 7).transform("CompanySalaryBatches.EmployeeViewPreliminaryPayslip")); _ck(_v, 6, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.notifyEmployee; _ck(_v, 9, 0, currVal_6); var currVal_7 = _co.sessionService.isSubmitting; var currVal_8 = "Cancel"; _ck(_v, 11, 0, currVal_7, currVal_8); var currVal_9 = false; var currVal_10 = _co.sessionService.isSubmitting; var currVal_11 = "SubmitForApproval"; _ck(_v, 13, 0, currVal_9, currVal_10, currVal_11); }, null); }
export function View_LockSalaryBatchDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-lock-salary-batch-dialog", [], null, null, null, View_LockSalaryBatchDialogComponent_0, RenderType_LockSalaryBatchDialogComponent)), i0.ɵdid(1, 180224, null, 0, i11.LockSalaryBatchDialogComponent, [i12.DataService, i5.SessionService, i13.CompanySalaryBatchService], null, null)], null, null); }
var LockSalaryBatchDialogComponentNgFactory = i0.ɵccf("app-lock-salary-batch-dialog", i11.LockSalaryBatchDialogComponent, View_LockSalaryBatchDialogComponent_Host_0, { visible: "visible" }, { visibleChange: "visibleChange", batchLocked: "batchLocked" }, []);
export { LockSalaryBatchDialogComponentNgFactory as LockSalaryBatchDialogComponentNgFactory };
