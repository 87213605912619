import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../../common/form-component-base';
import { Global } from '../../../common/global';
import { ICurrency } from '../../../services/api/api-model';
import { StaticDataService } from '../../../services/api/static-data.service';
import { SessionService } from '../../../services/session/session.service';

@Component({
  selector: 'app-bank-info',
  templateUrl: './bank-info.component.html'
})
export class BankInfoComponent extends FormComponentBase implements OnInit {
  @Output() bankInfoChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() editMode: boolean;
  @Input() get bankInfo(): any {
    if (!this.bankInfoValue) {
      this.bankInfoValue = {};
    }

    return this.bankInfoValue;
  }

  set bankInfo(value: any) {
    this.bankInfoValue = value;
    this.bankInfoChange.emit(value);
  }

  public hasNemKontoEnabled = false;
  public currencies: ICurrency[];
  private bankInfoValue: any;

  public get isAllowCompanyNemKontoPayments(): boolean {
    return Global && Global.COMPANY && Global.COMPANY.AllowAutoDetermineBankAccount;
  }

  public get isSelfService(): boolean {
    return this.sessionService.currentState === 'tabs.selfservice.general';
  }

  public get isDisableBankFeild(): boolean {
    return !(
      (this.isAllowCompanyNemKontoPayments && !this.bankInfo.AutoDetermineBankAccount) ||
      !this.isAllowCompanyNemKontoPayments
    );
  }

  constructor(private staticDataService: StaticDataService, private sessionService: SessionService) {
    super();
    this.childIsValid = Array(6).fill(true);
    this.childIsDirty = Array(6).fill(false);
  }

  public ngOnInit(): void {
    this.staticDataService.Currency.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ICurrency[]) => (this.currencies = data)
    );
  }
}
