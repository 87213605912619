import { Component, EventEmitter, Input, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../common/constants';
import { FormComponentBase } from '../../common/form-component-base';
import { Global } from '../../common/global';
import { NumericTextBoxOptions } from '../../custom-controls/numeric-edit/numeric-text-box-options';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { TimeAndAbsenceModel } from './time-and-absence-model';

@Component({
  selector: 'app-time-and-absence',
  templateUrl: './time-and-absence.component.html'
})
export class TimeAndAbsenceComponent extends FormComponentBase {
  private currentmodel: any;
  private valueAnnualIncomeMultiplicationFactorBeforeChange: number;

  @Input()
  public get model(): TimeAndAbsenceModel {
    if (!this.currentmodel) {
      this.currentmodel = {};
    }

    return this.currentmodel;
  }
  public set model(value: TimeAndAbsenceModel) {
    this.currentmodel = value;
    this.valueAnnualIncomeMultiplicationFactorBeforeChange = null;

    this.childIsValid = Array(14).fill(true);
    this.childIsDirty = Array(14).fill(false);

    if (value) {
      if (
        value.StandardWorkHoursMonday ||
        value.StandardWorkHoursTuesday ||
        value.StandardWorkHoursWednesday ||
        value.StandardWorkHoursThursday ||
        value.StandardWorkHoursFriday ||
        value.StandardWorkHoursSaturday ||
        value.StandardWorkHoursSunday
      ) {
        this.isShowSpecifyWeekDay = true;
      } else {
        this.isShowSpecifyWeekDay = false;
      }
    }

    if (value && value.AnnualIncomeMultiplicationFactor) {
      this.valueAnnualIncomeMultiplicationFactorBeforeChange = JSON.parse(
        JSON.stringify(value.AnnualIncomeMultiplicationFactor)
      );
    }

    this.modelChange.emit(this.currentmodel);
  }
  @Output() public modelChange: EventEmitter<TimeAndAbsenceModel> = new EventEmitter<TimeAndAbsenceModel>();

  public timeEntrySplitStrategy: any;
  public AnnualIncomeMultiplicationFactorDataSource: Array<{ Name: string; Id: number }> = [
    { Name: '12', Id: 12 },
    { Name: '12,2', Id: 12.2 }
  ];

  constructor(private staticDataService: StaticDataService, private sessionService: SessionService) {
    super();
    this.childIsValid = Array(14).fill(true);
    this.childIsDirty = Array(14).fill(false);
    this.staticDataService.TimeEntryRecordAcrossSalaryPeriodStrategy.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any[]) => (this.timeEntrySplitStrategy = data)
    );
  }

  get IsUserEmployment(): boolean {
    return this.model && (this.model as any).HireDate;
  }

  public get isCompanyTemplate(): boolean {
    return (
      this.sessionService.currentState && this.sessionService.currentState === 'tabs.company.configuration.hiringstatus'
    );
  }

  public get isSwedenCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
  }

  public NumbericTextBoxFFHoursEarnedPerYearOptions: NumericTextBoxOptions = {
    format: 'n2',
    decimals: 2,
    step: 1,
    spinners: false
  };

  public onApplyLongTermIllnessCompensationChange(): void {
    if (this.model.ApplyLongTermIllnessCompensation) {
      this.model.AnnualIncomeMultiplicationFactor = this.model.AnnualIncomeMultiplicationFactor || 12;
    } else {
      this.model.AnnualIncomeMultiplicationFactor = this.valueAnnualIncomeMultiplicationFactorBeforeChange
        ? JSON.parse(JSON.stringify(this.valueAnnualIncomeMultiplicationFactorBeforeChange))
        : null;
    }

    this.onChange();
  }

  public isShowSpecifyWeekDay = false;
  public showSpecifyWeekday() {
    this.isShowSpecifyWeekDay = true;
  }
}
