<label class="FormElement-label u-hiddenVisually" for="comboboxSort">
  {{ 'Employee.SortByFirstNameSurname' | translate }}
</label>

<app-combobox-edit
  [disable]="employeeService.editMode"
  [editMode]="true"
  class="FormElement-select"
  id="comboboxSort"
  [comboboxDataSource]="sortByDataSource"
  [(value)]="employeeService.sortBy"
  [textField]="'Text'"
  [idField]="'Id'"
></app-combobox-edit>
<!-- (valueChange)="onComboBoxChanged()" -->
