import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from 'src/app/common/global';
import { NumericTextBoxOptions } from 'src/app/custom-controls/numeric-edit/numeric-text-box-options';
import {
  BillingUsage,
  IAccount,
  IBillingPrinciple,
  IBillingUsage,
  IModuleCompanyView
} from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';

@Component({
  selector: 'app-register-support-usage-dialog',
  templateUrl: './register-support-usage-dialog.component.html',
  styleUrls: ['./register-support-usage-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterSupportUsageDialogComponent implements OnInit, OnDestroy {
  private visibleValue = false;
  @Input()
  public get visible() {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    this.visibleValue = value;
    this.visibleChange.emit(value);
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public billingUsage: IBillingUsage = new BillingUsage();
  public moduleAdHocSupport: IModuleCompanyView[] = [];
  public isShowEmailSupport = false;
  public isShowPhoneSupport = false;
  public billingPrinciple: IBillingPrinciple[] = [];
  public NumericOptions: NumericTextBoxOptions = { format: 'n2', decimals: 4, step: 1, min: null, spinners: false };
  constructor(private dataService: DataService, private staticDataService: StaticDataService) {}

  public ngOnInit() {
    this.checkModuleWarning();
    this.staticDataService.moduleCompanyView
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: IModuleCompanyView[]) => {
        this.moduleAdHocSupport = JSON.parse(JSON.stringify(data));
        this.checkModuleWarning();
        this.defaultBillingUsage();
      });

    this.staticDataService.BillingPrinciple.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IBillingPrinciple[]) => (this.billingPrinciple = data)
    );
  }

  private isHavePhoneSupport = false;
  private isHaveEmailSupport = false;

  private checkModuleWarning() {
    if (this.hasModuleKey('EmailSupport')) {
      this.isShowPhoneSupport = false;
      this.isShowEmailSupport = true;
      this.isHaveEmailSupport = true;
    }
    if (this.hasModuleKey('PhoneSupport')) {
      this.isShowPhoneSupport = true;
      this.isShowEmailSupport = false;
      this.isHavePhoneSupport = true;
    }

    this.staticDataService.currentAccount.pipe().subscribe((account: IAccount) => {
      if (account && account.IsPublic && Global.COMPANY.AccountId === account.Id) {
        this.isShowPhoneSupport = true;
        this.isShowEmailSupport = false;
        this.isHavePhoneSupport = true;
        this.isHaveEmailSupport = true;
      }

      if (this.moduleAdHocSupport && this.moduleAdHocSupport.length > 0) {
        this.moduleAdHocSupport.forEach((model: any) => {
          if (model.ModuleKey === 'BillableSupport' && (this.isHaveEmailSupport || this.isHavePhoneSupport)) {
            model.ModulePrice = 0;
          } else if (model.ModuleKey === 'EmailSupport' && this.isHaveEmailSupport) {
            model.ModulePrice = 0;
          } else if (model.ModuleKey === 'PhoneSupport' && this.isHavePhoneSupport) {
            model.ModulePrice = 0;
          }
        });

        this.defaultBillingUsage();
      }
    });
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onSaveAdHocSupport(event: string): void {
    if (event && event === 'Save') {
      this.dataService.Backend_RegisterUsage(this.billingUsage).subscribe(() => this.onCaculateAmount());
    }
    this.defaultBillingUsage();
  }

  public hasModuleKey(key: string): boolean {
    if (this.moduleAdHocSupport && this.moduleAdHocSupport.length > 0) {
      const objectModule: any = this.moduleAdHocSupport.find((model: any) => model.ModuleKey === key);
      if (objectModule && objectModule.IsEnabled) {
        return true;
      }
    }

    return false;
  }

  private defaultBillingUsage(): void {
    this.billingUsage = new BillingUsage();
    this.billingUsage.Units = 1;
    this.billingUsage.BillingDate = this.staticDataService.getCurrentdate();
    if (this.moduleAdHocSupport && this.moduleAdHocSupport.length > 0) {
      const objectModule: any = this.moduleAdHocSupport.find((model: any) => model.ModuleKey === 'BillableSupport');
      if (objectModule) {
        this.billingUsage.ModuleId = objectModule.ModuleId;
        this.onBillingPrincipChange();
      }
    }
  }

  public onBillingPrincipChange(): void {
    if (this.moduleAdHocSupport && this.moduleAdHocSupport.length > 0) {
      const objectModule: any = this.moduleAdHocSupport.find(
        (model: any) => model.ModuleId === this.billingUsage.ModuleId
      );
      if (objectModule) {
        this.billingUsage.AmountPerUnit = objectModule.ModulePrice ? objectModule.ModulePrice : 0;
        if (objectModule.ModuleBillingPrincipleId) {
          this.billingUsage.BillingPrincipleId = objectModule.ModuleBillingPrincipleId;
        }
      }
    }

    this.onCaculateAmount();
  }

  public onCaculateAmount(): void {
    if (this.billingUsage.Units !== undefined && this.billingUsage.AmountPerUnit !== undefined) {
      this.billingUsage.Amount = this.billingUsage.Units * this.billingUsage.AmountPerUnit;
    }
  }
}
