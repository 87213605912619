import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WalkthroughComponent, WalkthroughNavigate, WalkthroughText } from 'angular-walkthrough';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompanyUser, ICompanyUser } from 'src/app/services/api/api-model';
import { EmployeeService } from 'src/app/services/employee.service';
import { SettingService } from 'src/app/services/setting.service';
import { environment } from 'src/environments/environment';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { EventEmitterService } from '../../services/event-emitter.service';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-walkthrough',
  templateUrl: './walkthrough.component.html'
})
export class WalkThroughGSComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('EmployeeGeneralTab', { static: false }) EmployeeGeneralTab: WalkthroughComponent;
  @ViewChild('EmployeeHiringStatusTab', { static: false }) EmployeeHiringStatusTab: WalkthroughComponent;
  @ViewChild('EmployeeTimeTab', { static: false }) EmployeeTimeTab: WalkthroughComponent;
  @ViewChild('EmployeePayrollDataTab', { static: false }) EmployeePayrollDataTab: WalkthroughComponent;
  @ViewChild('EmployeePayslipTab', { static: false }) EmployeePayslipTab: WalkthroughComponent;
  @ViewChild('CompanyGeneralTab', { static: false }) CompanyGeneralTab: WalkthroughComponent;
  @ViewChild('CompanySalaryBatchesTab', { static: false }) CompanySalaryBatchesTab: WalkthroughComponent;
  @ViewChild('CompanyApprovalTab', { static: false }) CompanyApprovalTab: WalkthroughComponent;
  @ViewChild('CompanyConfigurationTemplateTab', { static: false })
  CompanyConfigurationTemplateTab: WalkthroughComponent;
  @ViewChild('CompanyConfigurationIntegrationsTab', { static: false })
  CompanyConfigurationIntegrationsTab: WalkthroughComponent;
  @ViewChild('CompanyConfigurationSalaryTypesTab', { static: false })
  CompanyConfigurationSalaryTypesTab: WalkthroughComponent;
  @ViewChild('CompanyConfigurationTimeEntryTypesTab', { static: false })
  CompanyConfigurationTimeEntryTypesTab: WalkthroughComponent;
  @ViewChild('CompanyModulesTab', { static: false })
  CompanyModulesTab: WalkthroughComponent;
  @ViewChild('SelfServicePayslipsTab', { static: false })
  SelfServicePayslipsTab: WalkthroughComponent;
  @ViewChild('SelfServiceGeneralTab', { static: false })
  SelfServiceGeneralTab: WalkthroughComponent;
  @ViewChild('SelfServiceTimeTab', { static: false })
  SelfServiceTimeTab: WalkthroughComponent;

  public state: string;
  public LanguageId = 1;
  public welcomeTitles: { [key: string]: string } = {};
  public welcomeMessages: { [key: string]: string } = {};
  public welcomeTexts: WalkthroughText = {};
  public config: PerfectScrollbarConfigInterface = {};
  public nextState: string;
  public prevState: string;
  private isNavigateToState = false;
  private timeout = 200;
  private isHasEmployments: any = null;
  public isHasSelfService = true;
  public isHasModules = true;

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public get branding() {
    return environment.branding;
  }

  constructor(
    private translateService: TranslateService,
    public sessionService: SessionService,
    private eventEmitService: EventEmitterService,
    private settingService: SettingService,
    private employeeService: EmployeeService
  ) {
    this.listener.push(
      WalkthroughComponent.onNavigateNext.subscribe(() => {
        if (this.nextState) {
          this.navigateToState(this.nextState, 1);
          this.isNavigateToState = true;
          setTimeout(() => {
            this.onShowWelcomeMessage();
          });
        }
      }),
      WalkthroughComponent.onNavigatePrevious.subscribe(() => {
        if (this.prevState) {
          this.navigateToState(this.prevState, -1);
          this.isNavigateToState = true;
          setTimeout(() => {
            this.onShowWelcomeMessage();
          });
        }
      }),
      WalkthroughComponent.onOpen.subscribe(() => {
        this.disableWalkthroughButton();
      })
    );
  }

  public ngOnInit(): void {
    this.setupEventHandlers();
    setTimeout(() => {
      this.LanguageId = Global.SESSION ? Global.SESSION.SignOnToken.LanguageId : 1;
      this.translateApplicationText();
      this.isHasSelfService = !this.isHideSelfService;
      this.isHasModules =
        this.sessionService.role.IsFullAdmin &&
        !this.IsGreenlandCompany &&
        !this.sessionService.role.EmployeeWithRoleAsManager;
    }, 1000);
    this.updateWalkthroughState();
  }
  private listener: Subscription[] = [];
  private ngUnsubscribe: Subject<{}> = new Subject();
  private wktElement: Element = null;

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    for (const list of this.listener) {
      if (list) {
        list.unsubscribe();
      }
    }

    if (this.wktElement) {
      this.wktElement.removeEventListener('click', this.clickPeventDefault, false);
    }
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      const onShowDialog = document.querySelectorAll('.blue-dialog kendo-dialog.ng-star-inserted');
      if (
        this.settingService.moduleMessageDialogVisible &&
        onShowDialog.length <= 0 &&
        !this.settingService.IsShowUpdateNotification
      ) {
        this.onShowWelcomeMessage();
        this.settingService.moduleMessageDialogVisible = false;
      }
    }, 1000);
  }

  private setupEventHandlers(): void {
    this.sessionService.OnLocationChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state: string): void => {
      this.state = state;
    });
  }

  public updateWalkthroughState(): void {
    switch (this.state) {
      case 'tabs.employee.general':
        this.prevState = null;
        this.nextState = 'tabs.employee.hiringstatus';
        break;
      case 'tabs.employee.hiringstatus':
        this.prevState = 'tabs.employee.general';
        this.nextState = 'tabs.employee.time';
        break;
      case 'tabs.employee.time':
        this.prevState = 'tabs.employee.hiringstatus';
        this.nextState = 'tabs.employee.payrolldata';
        break;
      case 'tabs.employee.payrolldata':
        this.prevState = 'tabs.employee.time';
        this.nextState = 'tabs.employee.payslip';
        break;
      case 'tabs.employee.payslip':
        this.prevState = 'tabs.employee.payrolldata';
        this.nextState = 'tabs.company.general';
        break;
      case 'tabs.company.general':
        this.prevState = 'tabs.employee.payslip';
        this.nextState = 'tabs.company.salarybatches';
        break;
      case 'tabs.company.payrolldata':
        break;
      case 'tabs.company.salarybatches':
        this.prevState = 'tabs.company.general';
        this.nextState = 'tabs.company.approval.timeentry';
        break;
      case 'tabs.company.approval.timeentry':
        this.prevState = 'tabs.company.salarybatches';
        this.nextState = 'tabs.company.configuration.hiringstatus';
        break;
      case 'tabs.company.configuration.hiringstatus':
        this.prevState = 'tabs.company.approval.timeentry';
        this.nextState = 'tabs.company.configuration.integrations';
        break;
      case 'tabs.company.configuration.integrations':
        this.prevState = 'tabs.company.configuration.hiringstatus';
        this.nextState = 'tabs.company.configuration.advancedsalarytypes';
        break;
      case 'tabs.company.configuration.advancedsalarytypes':
        this.prevState = 'tabs.company.configuration.integrations';
        this.nextState = 'tabs.company.configuration.timeentrytypes';
        break;
      case 'tabs.company.configuration.timeentrytypes':
        this.prevState = 'tabs.company.configuration.advancedsalarytypes';
        this.nextState = 'tabs.company.modules';
        break;
      case 'tabs.company.modules':
        this.prevState = 'tabs.company.configuration.timeentrytypes';
        this.nextState = 'tabs.selfservice.payslip';
        break;
      case 'tabs.selfservice.payslip':
        this.prevState = 'tabs.company.modules';
        this.nextState = 'tabs.selfservice.general';
        break;
      case 'tabs.selfservice.general':
        this.prevState = 'tabs.selfservice.payslip';
        this.nextState = 'tabs.selfservice.time';
        break;
      case 'tabs.selfservice.time':
        this.prevState = 'tabs.selfservice.general';
        this.nextState = null;
        break;
    }
  }

  private openWalkthrough(buttonWidth: number, buttonLeft: number, tab: WalkthroughComponent) {
    let size = 0;
    if (window.innerWidth < 400) {
      size = 37;
    }
    if (window.innerWidth >= 400 && window.innerWidth <= 950) {
      size = 40;

      if (window.innerWidth > 479) {
        size += 5;
      }
    }

    if (this.state.indexOf('tabs.company.configuration') >= 0) {
      this.eventEmitService.getMenuOpen();
      const isOpen = this.eventEmitService.isOpen;
      if (!isOpen) {
        this.eventEmitService.callToggleNavigation();
      }
    }
    setTimeout(() => {
      tab.open();
      const backdrop = document.querySelectorAll('.wkt-zone.backdrop')[0] as HTMLElement;
      let extraLeft = 0;
      if (backdrop && buttonLeft >= 0 && size > 0) {
        backdrop.style.width = buttonWidth + 'px';
        if (window.innerWidth >= 920) {
          extraLeft += 205;
          buttonLeft -= 1;
        }
        backdrop.style.left = buttonLeft * size + extraLeft + 'px';
      }
      if (!this.wktElement) {
        setTimeout(() => {
          this.wktElement = document.getElementsByClassName('wkt-content-block')[0];
          if (this.wktElement) {
            this.wktElement.addEventListener('click', this.clickPeventDefault, false);
          }
        });
      }

      setTimeout(() => {
        this.enableWalkthroughButton();
      }, 1000);
    }, this.timeout);
  }

  private clickPeventDefault(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }

  public onShowWelcomeMessage(): void {
    if (this.isNavigateToState) {
      this.timeout = 1000;
    } else {
      this.timeout = 200;
    }
    this.isNavigateToState = false;
    const button = document.querySelectorAll('button.TabPanel-link.is-active')[0];
    let buttonWidth = 0;
    if (button) {
      buttonWidth = button.getBoundingClientRect().width;
    }

    switch (this.state) {
      case 'tabs.employee.general':
        this.openWalkthrough(buttonWidth, 1, this.EmployeeGeneralTab);
        this.prevState = null;
        this.nextState = 'tabs.employee.hiringstatus';
        break;
      case 'tabs.employee.hiringstatus':
        this.openWalkthrough(buttonWidth, 2, this.EmployeeHiringStatusTab);
        this.prevState = 'tabs.employee.general';
        this.nextState = 'tabs.employee.time';
        break;
      case 'tabs.employee.time':
        this.openWalkthrough(buttonWidth, 3, this.EmployeeTimeTab);
        this.prevState = 'tabs.employee.hiringstatus';
        this.nextState = 'tabs.employee.payrolldata';
        break;
      case 'tabs.employee.payrolldata':
        this.openWalkthrough(buttonWidth, 4, this.EmployeePayrollDataTab);
        this.prevState = 'tabs.employee.time';
        this.nextState = 'tabs.employee.payslip';
        break;
      case 'tabs.employee.payslip':
        this.openWalkthrough(buttonWidth, 5, this.EmployeePayslipTab);
        this.prevState = 'tabs.employee.payrolldata';
        this.nextState = 'tabs.company.general';
        break;
      case 'tabs.company.general':
        this.openWalkthrough(buttonWidth, 0, this.CompanyGeneralTab);
        this.prevState = 'tabs.employee.payslip';
        this.nextState = 'tabs.company.salarybatches';
        break;
      case 'tabs.company.payrolldata':
        break;
      case 'tabs.company.salarybatches':
        this.openWalkthrough(buttonWidth, 2, this.CompanySalaryBatchesTab);
        this.prevState = 'tabs.company.general';
        this.nextState = 'tabs.company.approval.timeentry';
        break;
      case 'tabs.company.approval.timeentry':
        this.openWalkthrough(buttonWidth, 3, this.CompanyApprovalTab);
        this.prevState = 'tabs.company.salarybatches';
        this.nextState = 'tabs.company.configuration.hiringstatus';
        break;
      case 'tabs.company.configuration.hiringstatus':
        this.openWalkthrough(buttonWidth, -1, this.CompanyConfigurationTemplateTab);
        this.prevState = 'tabs.company.approval.timeentry';
        this.nextState = 'tabs.company.configuration.integrations';
        break;
      case 'tabs.company.configuration.integrations':
        this.openWalkthrough(buttonWidth, -1, this.CompanyConfigurationIntegrationsTab);
        this.prevState = 'tabs.company.configuration.hiringstatus';
        this.nextState = 'tabs.company.configuration.advancedsalarytypes';
        break;
      case 'tabs.company.configuration.advancedsalarytypes':
        this.openWalkthrough(buttonWidth, -1, this.CompanyConfigurationSalaryTypesTab);
        this.prevState = 'tabs.company.configuration.integrations';
        this.nextState = 'tabs.company.configuration.timeentrytypes';
        break;
      case 'tabs.company.configuration.timeentrytypes':
        this.openWalkthrough(buttonWidth, -1, this.CompanyConfigurationTimeEntryTypesTab);
        this.prevState = 'tabs.company.configuration.advancedsalarytypes';
        this.nextState = 'tabs.company.modules';
        break;
      case 'tabs.company.modules':
        this.openWalkthrough(buttonWidth, 5, this.CompanyModulesTab);
        this.prevState = 'tabs.company.configuration.timeentrytypes';
        this.nextState = 'tabs.selfservice.payslip';
        break;
      case 'tabs.selfservice.payslip':
        this.openWalkthrough(buttonWidth, 0, this.SelfServicePayslipsTab);
        this.prevState = 'tabs.company.modules';
        this.nextState = 'tabs.selfservice.general';
        break;
      case 'tabs.selfservice.general':
        this.openWalkthrough(buttonWidth, 1, this.SelfServiceGeneralTab);
        this.prevState = 'tabs.selfservice.payslip';
        this.nextState = 'tabs.selfservice.time';
        break;
      case 'tabs.selfservice.time':
        this.openWalkthrough(buttonWidth, 2, this.SelfServiceTimeTab);
        this.prevState = 'tabs.selfservice.general';
        this.nextState = null;
        break;
    }
  }

  public navigateToState(state: string, direction: number): void {
    // Check company module is show
    if (
      state === 'tabs.company.modules' &&
      (!this.sessionService.role.IsFullAdmin ||
        this.IsGreenlandCompany ||
        this.sessionService.role.EmployeeWithRoleAsManager)
    ) {
      if (direction > 0) {
        state = 'tabs.selfservice.payslip';
      } else {
        state = 'tabs.company.configuration.timeentrytypes';
      }

      this.navigateToState(state, direction);
      return;
    }
    // Check SelfService payslip is show
    if (state === 'tabs.selfservice.payslip') {
      this.checkHasEmployments();
      setTimeout(() => {
        if (this.isHasEmployments !== null && this.isHasEmployments === false) {
          if (direction > 0) {
            state = 'tabs.selfservice.general';
          } else {
            state = 'tabs.company.modules';
          }

          this.navigateToState(state, direction);
        } else {
          this.sessionService.NavigateTo(state);
        }
      });
      return;
    }
    // Check SelfService Time is show
    if (state === 'tabs.selfservice.time' && this.sessionService.feature.hasModuleId(8)) {
      this.checkHasEmployments();
      setTimeout(() => {
        if (this.isHasEmployments !== null && this.isHasEmployments === false) {
          if (direction > 0) {
            return;
          } else {
            state = 'tabs.selfservice.payslip';
          }

          this.navigateToState(state, direction);
        } else {
          this.sessionService.NavigateTo(state);
        }
      });
    }

    // Navigate to page
    this.sessionService.NavigateTo(state);
  }

  public checkHasEmployments() {
    this.employeeService.employee.subscribe((companyUser: ICompanyUser) => {
      if (companyUser) {
        this.isHasEmployments = companyUser && companyUser.UserEmployments && companyUser.UserEmployments.length > 0;
      } else {
        this.isHasEmployments = false;
      }
    });
  }

  private setResponsiveStyle(state: string): void {
    switch (state) {
      case 'tabs.employee.hiringstatus':
        this.sessionService.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
        break;
      case 'tabs.employee.payrolldata':
        this.sessionService.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
        break;
      default:
        if (this.sessionService.currentState !== 'tabs.employee') {
          this.sessionService.currentStyleMenuTabpanelReponsive = '';
        }
        break;
    }
  }

  private translateApplicationText(): void {
    this.translateService
      .get([
        'EmployeeGeneral.WelcomeTitle',
        'EmployeeGeneral.WelcomeMessage',
        'Employee.General',
        'EmployeeEmployment.WelcomeTitle',
        'EmployeeEmployment.WelcomeMessage',
        'Employee.Employment',
        'EmploymentPayrollData.WelcomeTitle',
        'EmploymentPayrollData.WelcomeMessage',
        'Employee.PayrollData',
        'EmploymentTime.WelcomeTitle',
        'EmploymentTime.WelcomeMessage',
        'Employee.Time',
        'EmployeePayslip.WelcomeTitle',
        'EmployeePayslip.WelcomeMessage',
        'EmployeeGeneral.Payslips',
        'AccountModules.WelcomeTitle',
        'AccountModules.WelcomeMessage',
        'Account.Modules',
        'CompanyGeneral.FirstWelcomeTitle',
        'CompanyGeneral.WelcomeTitle',
        'CompanyGeneral.WelcomeMessage',
        'Company.General',
        'CompanyHiringStatus.WelcomeTitle',
        'CompanyHiringStatus.WelcomeMessage',
        'Company.PayrollData',
        'CompanySalaryBatches.WelcomeTitle',
        'CompanySalaryBatches.WelcomeMessage',
        'Company.SalaryBatches',
        'CompanySalaryTypes.WelcomeTitle',
        'CompanySalaryTypes.WelcomeMessage',
        'CompanySalaryTypes.Approval',
        'CompanyTimeEntryTypes.WelcomeTitle',
        'CompanyTimeEntryTypes.WelcomeMessage',
        'CompanyTimeEntryTypes.TimeEntryTypes',
        'CompanyApproval.WelcomeTitle',
        'CompanyApproval.WelcomeMessage',
        'SelfServicePayslip.FirstWelcomeTitle',
        'SelfServicePayslip.WelcomeTitle',
        'SelfServicePayslip.WelcomeMessage',
        'SelfService.MyDetails',
        'Timeentrytypes.WelcomeTitle',
        'Timeentrytypes.WelcomeMessage',
        'Integrations.WelcomeTitle',
        'Integrations.WelcomeMessage',
        'GlobalDialog.Close',
        'GlobalDialog.Next',
        'GlobalDialog.Previous',
        'Company.EmploymentTemplates',
        'Company.ExternalIntegrations',
        'Company.AdvancedSalaryTypes',
        'SelfService.MyDetails'
      ])
      .subscribe((translations: { [key: string]: string }) => {
        this.welcomeTitles['EmployeeGeneral'] = translations['EmployeeGeneral.WelcomeTitle'].replace(
          '{{SystemAlias}}',
          environment.branding.SystemAlias
        );
        this.welcomeMessages['EmployeeGeneral'] = translations['EmployeeGeneral.WelcomeMessage'];
        this.welcomeTitles['EmployeeEmployment'] =
          translations['EmployeeEmployment.WelcomeTitle'] + ' ' + translations['Employee.Employment'];
        this.welcomeMessages['EmployeeEmployment'] = translations['EmployeeEmployment.WelcomeMessage'];
        this.welcomeTitles['EmployeeTime'] =
          translations['EmploymentTime.WelcomeTitle'] + ' ' + translations['Employee.Time'];
        this.welcomeMessages['EmployeeTime'] = translations['EmploymentTime.WelcomeMessage'];
        this.welcomeTitles['EmployeePayrollData'] =
          translations['EmploymentPayrollData.WelcomeTitle'] + ' ' + translations['Employee.PayrollData'];
        this.welcomeMessages['EmployeePayrollData'] = translations['EmploymentPayrollData.WelcomeMessage'];
        this.welcomeTitles['EmployeePayslip'] =
          translations['EmployeePayslip.WelcomeTitle'] + ' ' + translations['EmployeeGeneral.Payslips'];
        this.welcomeMessages['EmployeePayslip'] = translations['EmployeePayslip.WelcomeMessage'].replace(
          '{{SystemAlias}}',
          environment.branding.SystemAlias
        );
        if (this.sessionService.role.IsEmployeeRole === true) {
          this.welcomeTitles['CompanyGeneral'] =
            translations['CompanyGeneral.WelcomeTitle'] + ' ' + translations['Company.General'];
          this.welcomeTitles['SelfServicePayslip'] = translations['SelfServicePayslip.FirstWelcomeTitle'].replace(
            '{{SystemAlias}}',
            environment.branding.SystemAlias
          );
        } else {
          this.welcomeTitles['SelfServicePayslip'] =
            translations['SelfServicePayslip.WelcomeTitle'] + ' ' + translations['EmployeeGeneral.Payslips'];
          this.welcomeTitles['CompanyGeneral'] = translations['CompanyGeneral.FirstWelcomeTitle'].replace(
            '{{SystemAlias}}',
            environment.branding.SystemAlias
          );
        }
        this.welcomeMessages['CompanyGeneral'] = translations['CompanyGeneral.WelcomeMessage'];
        this.welcomeTitles['CompanyHiringStatus'] =
          translations['CompanyHiringStatus.WelcomeTitle'] + ' ' + translations['Company.EmploymentTemplates'];
        this.welcomeMessages['CompanyHiringStatus'] = translations['CompanyHiringStatus.WelcomeMessage'];
        this.welcomeTitles['CompanySalaryBatches'] =
          translations['CompanySalaryBatches.WelcomeTitle'] + ' ' + translations['Company.SalaryBatches'];
        this.welcomeMessages['CompanySalaryBatches'] = translations['CompanySalaryBatches.WelcomeMessage'];
        this.welcomeTitles['CompanySalaryTypes'] =
          translations['CompanySalaryTypes.WelcomeTitle'] + ' ' + translations['Company.AdvancedSalaryTypes'];
        this.welcomeMessages['CompanySalaryTypes'] = translations['CompanySalaryTypes.WelcomeMessage'];
        this.welcomeTitles['CompanyTimeEntryTypes'] =
          translations['CompanyTimeEntryTypes.WelcomeTitle'] +
          ' ' +
          translations['CompanyTimeEntryTypes.TimeEntryTypes'];
        this.welcomeMessages['CompanyTimeEntryTypes'] = translations['CompanyTimeEntryTypes.WelcomeMessage'];
        this.welcomeTitles['AccountModules'] =
          translations['AccountModules.WelcomeTitle'] + ' ' + translations['Account.Modules'];
        this.welcomeMessages['AccountModules'] = translations['AccountModules.WelcomeMessage'].replace(
          '{{SystemAlias}}',
          environment.branding.SystemAlias
        );
        this.welcomeTitles['Integrations'] =
          translations['Integrations.WelcomeTitle'] + ' ' + translations['Company.ExternalIntegrations'];
        this.welcomeMessages['Integrations'] = translations['Integrations.WelcomeMessage'].replace(
          '{{SystemAlias}}',
          environment.branding.SystemAlias
        );
        this.welcomeTitles['CompanyApproval'] =
          translations['CompanyApproval.WelcomeTitle'] + ' ' + translations['CompanySalaryTypes.Approval'];
        this.welcomeMessages['CompanyApproval'] = translations['CompanyApproval.WelcomeMessage'];
        this.welcomeMessages['SelfServicePayslip'] = translations['SelfServicePayslip.WelcomeMessage'].replace(
          /\{{SystemAlias}}/g,
          environment.branding.SystemAlias
        );
        this.welcomeTitles['SelfServiceTime'] =
          translations['EmploymentTime.WelcomeTitle'] + ' ' + translations['Employee.Time'];
        this.welcomeMessages['SelfServiceTime'] = translations['EmploymentTime.WelcomeMessage'];
        this.welcomeTitles['SelfServiceGeneral'] =
          translations['EmploymentTime.WelcomeTitle'] + ' ' + translations['SelfService.MyDetails'];
        this.welcomeMessages['SelfServiceGeneral'] = translations['EmployeeGeneral.WelcomeMessage'];
        this.welcomeTexts = {
          previous: translations['GlobalDialog.Previous'],
          next: translations['GlobalDialog.Next'],
          close: translations['GlobalDialog.Close']
        };
      });
  }

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public get isHideSelfService(): boolean {
    if (this.sessionService.feature.AccountantTabEnabled) {
      return this.employeeService.isCurrentUserHasEmployment;
    }
    return !this.employeeService.isCurrentUserExistIncompanyUsers;
  }

  private disableWalkthroughButton() {
    const nextButton = document.querySelector('.wkt-next-link');
    if (nextButton) {
      nextButton.classList.remove('wkt-enable');
    }
    const prevButton = document.querySelector('.wkt-previous-link');
    if (prevButton) {
      prevButton.classList.remove('wkt-enable');
    }
    const closeButton = document.querySelector('.wkt-finish-link');
    if (closeButton) {
      closeButton.classList.remove('wkt-enable');
    }
  }

  private enableWalkthroughButton() {
    const nextButton = document.querySelector('.wkt-next-link');
    if (nextButton) {
      nextButton.classList.add('wkt-enable');
    }
    const prevButton = document.querySelector('.wkt-previous-link');
    if (prevButton) {
      prevButton.classList.add('wkt-enable');
    }
    const closeButton = document.querySelector('.wkt-finish-link');
    if (closeButton) {
      closeButton.classList.add('wkt-enable');
    }
  }
}
