<form>
  <fieldset class="Form--horizontal">
    <legend class="Card-title">
      {{ SectionName | translate }}
    </legend>
    <app-percentage-edit
      [label]="'EmployeeEmployment.EarningPercentage' | translate"
      [editMode]="editMode"
      [disable]="disable"
      [(isDirty)]="childIsDirty[0]"
      [allowDecimal]="true"
      [(value)]="model.FlexEarningPercentage"
      (valueChange)="onChange()"
    ></app-percentage-edit>

    <app-combobox-edit
      [editMode]="editMode"
      [label]="'EmployeeEmployment.CalculationBase' | translate"
      [comboboxDataSource]="flexData"
      [(value)]="model.FlexBaseCalculationMethodId"
      [(isDirty)]="childIsDirty[1]"
      [textField]="'Name'"
      [idField]="'Id'"
      [disable]="disable"
      (valueChange)="onChange()"
    ></app-combobox-edit>
  </fieldset>
</form>

<div
  [ngClass]="{ 'nondiamond-inner-wrap': disableBySecondaryIncomeType }"
  kendoTooltip
  [title]="
    disableBySecondaryIncomeType ? ('EmployeeEmployment.ToolTipWhenDisabledBecauseOfIncomeType5' | translate) : ''
  "
></div>
