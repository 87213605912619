import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from 'src/app/common/global';
import { Department, IDepartment } from '../../../services/api/api-model';
import { StaticDataService } from '../../../services/api/static-data.service';
import { ModalService } from '../../../services/modal.service';
import { SessionService } from '../../../services/session/session.service';
import { ReportDialogService } from '../../../shared-components/report-dialog/report-dialog.service';
import { CompanyDepartmentService } from './company-department.service';

@Component({
  selector: 'app-company-department',
  templateUrl: './company-department.component.html',
  styleUrls: ['./company-department.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyDepartmentComponent implements OnInit, OnDestroy {
  constructor(
    private modalService: ModalService,
    public staticDataService: StaticDataService,
    public sessionService: SessionService,
    public reportDialogService: ReportDialogService,
    public companyDepartmentService: CompanyDepartmentService
  ) {
    this.companyDepartmentService.showReportShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && this.reportDialogService.isVisibleReportIcon) {
          this.reportDialogService.onShowReportsEventClick();
        }
      });

    this.modalService.modalServiceCloseErrorAlert.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      this.companyDepartmentService.loadDepartment();
    });

    this.companyDepartmentService.departments.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IDepartment[]) => {
      this.departments = data;
      this.gridData = filterBy(this.departments, this.filter);
    });
  }

  public deleteWarningVisible = false;
  public selectedDepartment: IDepartment;
  public departments: IDepartment[] = [];

  public ngOnInit() {
    this.companyDepartmentService.loadDepartment();
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly || this.sessionService.role.IsSalaryAdmin;
  }

  public onAddNew(newDepartment: IDepartment): void {
    (newDepartment as any).GridId = this.companyDepartmentService.gridFlag++;
    newDepartment.CompanyId = Global.COMPANY.Id;
    newDepartment.IsActive = true;
  }

  private gridRemoveRowArgs: any;
  public onRemoveDepartment(e: any): void {
    this.gridRemoveRowArgs = e;
    this.deleteWarningVisible = true;
  }

  public onDeleteWarningAction(action: string): void {
    if (action === 'Delete' && this.gridRemoveRowArgs) {
      if (this.gridRemoveRowArgs.dataItem.Id > 0) {
        this.companyDepartmentService.deleteDepartment(this.gridRemoveRowArgs.dataItem.Id).subscribe(() => {
          this.gridRemoveRowArgs = undefined;
        });
      } else {
        this.removeDeletedItem();
      }
    }
  }

  private removeDeletedItem(): void {
    const indexOfRemovedItem: number = this.departments.indexOf(this.gridRemoveRowArgs.dataItem);
    this.departments.splice(indexOfRemovedItem, 1);
    this.gridRemoveRowArgs = undefined;

    this.companyDepartmentService.loadDepartment();
  }

  public onSave(args: CellCloseEvent): void {
    const department: any = args.dataItem ? args.dataItem : args;

    let model = new Department();
    model = this.createDepartmentModel(department);

    if (model.Id) {
      this.companyDepartmentService.updateDepartment(model).subscribe((response: Department) => {
        Object.assign(department, response);
      });
    } else {
      this.companyDepartmentService.createDepartment(model).subscribe((response: Department) => {
        department.Id = response.Id;
      });
    }
  }

  private createDepartmentModel(object: IDepartment): IDepartment {
    return {
      Id: object.Id || null,
      CompanyId: object.CompanyId || null,
      Name: object.Name || null,
      Description: object.Description || null,
      IsActive: object.IsActive || false,
      EIncomeReference: object.EIncomeReference || null,
      ExternalReference: object.ExternalReference || null,
      ManagerCompanyUserId: object.ManagerCompanyUserId || null
    };
  }

  public refreshData = false;
  private keyPress: any = { 17: false, 18: false, 107: false, 109: false };
  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: any): void {
    if (!this.IsReadOnly) {
      if (event.keyCode in this.keyPress) {
        this.keyPress[event.keyCode] = true;
        if (this.keyPress[17] && this.keyPress[18] && this.keyPress[109]) {
          if (this.selectedDepartment) {
            const item: any = {};
            item.dataItem = this.selectedDepartment;
            this.onRemoveDepartment(item);
            this.setKeypress();
            this.refreshData = true;
            this.selectedDepartment = undefined;
          }
        }
      } else {
        this.setKeypress();
      }
    }
  }

  private setKeypress(): void {
    this.keyPress[17] = false;
    this.keyPress[18] = false;
    this.keyPress[107] = false;
    this.keyPress[109] = false;
  }

  public filter: CompositeFilterDescriptor;
  public gridData: any[] = this.departments;
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridData = filterBy(this.departments, filter);
  }
}
