import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from 'src/app/common/global';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { SettingService } from 'src/app/services/setting.service';
import { ReportDialogService } from 'src/app/shared-components/report-dialog/report-dialog.service';
import { TimeEntryTypeView } from './time-entry-type-view';
import { CompanyTimeEntryTypeService } from './time-entry-types.service';
var TimeEntryTypesComponent = /** @class */ (function () {
    function TimeEntryTypesComponent(dataService, staticDataService, translateService, sessionService, settingService, reportDialogService, companyTimeEntryTypeService) {
        var _this = this;
        this.dataService = dataService;
        this.staticDataService = staticDataService;
        this.translateService = translateService;
        this.sessionService = sessionService;
        this.settingService = settingService;
        this.reportDialogService = reportDialogService;
        this.companyTimeEntryTypeService = companyTimeEntryTypeService;
        this.unitTypes = Global.UNIT_TYPES;
        this.gridData = [];
        this.translatetooltipsalarytype = '';
        this.translationDialogVisible = false;
        this.newCustomTimeEntryDialogVisible = false;
        this.timeEntryTypeConfigurationDialogVisible = false;
        this.hasTimeEntryModule = false;
        this.triggerUpdate = false;
        this.isWattingLoading = false;
        this.numberOption = { min: undefined, step: 1, spinners: false, decimals: 0, format: 'n0' };
        this.ngUnsubscribe = new Subject();
        this.gridFilterData = this.gridData;
        this.companyTimeEntryTypeService.addNewShortcutChange
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && !_this.sessionService.role.IsReadOnly) {
                _this.newCustomTimeEntryDialogVisible = true;
            }
        });
        this.companyTimeEntryTypeService.showReportShortcutChange
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && _this.reportDialogService.isVisibleReportIcon) {
                _this.reportDialogService.onShowReportsEventClick();
            }
        });
        this.companyTimeEntryTypeService.timeEntryType
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) {
            _this.gridData = data.map(function (d) { return new TimeEntryTypeView(d, _this.isLanguageModuleEnable); });
            _this.gridData.forEach(function (model) {
                _this.processGridItemObject(model);
            });
            //this.convertExternalRefCodeToFriendlyName();
            _this.gridFilterData = filterBy(_this.gridData, _this.filter);
            _this.updateGridState();
        });
    }
    Object.defineProperty(TimeEntryTypesComponent.prototype, "isLanguageModuleEnable", {
        get: function () {
            return this.sessionService.feature.hasModuleId(5);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeEntryTypesComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeEntryTypesComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    TimeEntryTypesComponent.prototype.updateGridState = function () {
        if (this.gridFilterData && this.gridFilterData.length) {
            this.triggerUpdate = true;
        }
    };
    TimeEntryTypesComponent.prototype.enableTranslation = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            var translates = ['CompanyTimeEntryTypes.SalaryTypeDisableToolTip', 'CompanySalaryTypes.CreateFromScratch'];
            _this.translateService.get(translates).subscribe(function (translations) {
                _this.translatetooltipsalarytype = translations['CompanyTimeEntryTypes.SalaryTypeDisableToolTip'];
                _this.dataService
                    .TimeEntryTypes_GetDefaultTimeEntryTypes()
                    .pipe(takeUntil(_this.ngUnsubscribe))
                    .subscribe(function (data) {
                    _this.TimeEntryTypes = data;
                    _this.TimeEntryTypes.unshift({ Name: translations['CompanySalaryTypes.CreateFromScratch'], Id: -1 });
                });
            });
        });
    };
    TimeEntryTypesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isWattingLoading = true;
        this.staticDataService.moduleCompanyView
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function () { return _this.loadTimeEntryTypes(); });
        this.enableTranslation();
        this.companyTimeEntryTypeService.loadSalaryTypes().subscribe(function () {
            _this.updateGridState();
        });
        this.dataService
            .Integrations_GetAllExternalAccounts()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (externalAccounts) {
            _this.externalAccounts = externalAccounts;
            if (_this.externalAccounts && _this.externalAccounts.length > 0) {
                _this.externalAccounts.forEach(function (model) {
                    model.FriendlyName = model.AccountNumber + (model.AccountName ? ' - ' + model.AccountName : '');
                });
            }
        });
        this.settingService.showModuleMessage('company.timeentrytypes');
        if (this.isMobile) {
            this.sessionService.isShowHugeFeaturesAlert = true;
        }
    };
    TimeEntryTypesComponent.prototype.ngOnDestroy = function () {
        this.companyTimeEntryTypeService.serviceDestroy();
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    TimeEntryTypesComponent.prototype.saveChanges = function (data) {
        var _this = this;
        //data.dataItem.ExternalReference = this.getExteralReferenceCode(data.dataItem.ExternalReference, true);
        var timeEntryType = JSON.parse(JSON.stringify(data.dataItem));
        //timeEntryType.ExternalReference = this.getExteralReferenceCode(timeEntryType.ExternalReference);
        this.ConvertCorrectSubmitData(timeEntryType);
        this.dataService.TimeEntryTypes_UpdateTimeEntryType(timeEntryType).subscribe(function (response) {
            _this.updateGridState();
            //response.ExternalReference = this.getExteralReferenceCode(response.ExternalReference, true);
            _this.processGridItemObject(response);
            Object.assign(data.dataItem, new TimeEntryTypeView(response, _this.isLanguageModuleEnable));
        }, function (error) {
            _this.loadTimeEntryTypes();
        });
    };
    TimeEntryTypesComponent.prototype.ConvertCorrectSubmitData = function (model) {
        var classCheck = 'grid-inherit-cell';
        model.IsActive =
            model.isActiveClass === classCheck && model.IsActive === model.BaseTimeEntryType.IsActive ? null : model.IsActive;
        model.ExternalReference =
            model.externalReferenceClass === classCheck &&
                model.ExternalReference === model.BaseTimeEntryType.ExternalReference
                ? null
                : model.ExternalReference;
        model.SortOrder =
            model.sortOrderClass === classCheck && model.SortOrder === model.BaseTimeEntryType.SortOrder
                ? null
                : model.SortOrder;
        model.UnitTypeId =
            model.unitTypeClass === classCheck && model.UnitTypeId === model.BaseTimeEntryType.UnitTypeId
                ? null
                : model.UnitTypeId;
        model.AllowEditUnitType =
            model.allowEditUnitTypeClass === classCheck &&
                model.AllowEditUnitType === model.BaseTimeEntryType.AllowEditUnitType
                ? null
                : model.AllowEditUnitType;
        model.IsIllness =
            model.isIllnessClass === classCheck && model.IsIllness === model.BaseTimeEntryType.IsIllness
                ? null
                : model.IsIllness;
        model.SalaryTypeId =
            model.salaryTypeClass === classCheck && model.SalaryTypeId === model.BaseTimeEntryType.SalaryTypeId
                ? null
                : model.SalaryTypeId;
        if (!this.isLanguageModuleEnable) {
            model.Name = model.nameClass === classCheck && model.Name === model.BaseTimeEntryType.Name ? null : model.Name;
            model.Description =
                model.descriptionClass === classCheck && model.Description === model.BaseTimeEntryType.Description
                    ? null
                    : model.Description;
            model.DefaultPayslipText =
                model.defaultPayslipTextClass === classCheck &&
                    model.DefaultPayslipText === model.BaseTimeEntryType.DefaultPayslipText
                    ? null
                    : model.DefaultPayslipText;
        }
        else {
            var bseModelLanguge = {};
            var modelLanguge = {};
            if (model.BaseTimeEntryType &&
                model.BaseTimeEntryType.TimeEntryTypeTranslations &&
                model.BaseTimeEntryType.TimeEntryTypeTranslations.length > 0) {
                bseModelLanguge = model.BaseTimeEntryType.TimeEntryTypeTranslations.find(function (t) { return t.LanguageId === Global.SESSION.SignOnToken.LanguageId; });
            }
            else {
                bseModelLanguge = {
                    Name: null,
                    Description: null,
                    DefaultPayslipText: null
                };
            }
            if (model.TimeEntryTypeTranslations && model.TimeEntryTypeTranslations.length > 0) {
                modelLanguge = model.TimeEntryTypeTranslations.find(function (t) { return t.LanguageId === Global.SESSION.SignOnToken.LanguageId; });
            }
            else {
                modelLanguge = {
                    Name: null,
                    Description: null,
                    DefaultPayslipText: null
                };
            }
            var isModelBaseTimeEntryTypeNull = false;
            if (!model.BaseTimeEntryType) {
                model.BaseTimeEntryType = {
                    Name: null,
                    Description: null,
                    DefaultPayslipText: null
                };
                isModelBaseTimeEntryTypeNull = true;
            }
            model.Name =
                model.nameClass === classCheck &&
                    (model.Name === bseModelLanguge.Name ||
                        model.Name === modelLanguge.Name ||
                        model.Name === model.BaseTimeEntryType.Name)
                    ? null
                    : model.Name;
            model.Description =
                model.descriptionClass === classCheck &&
                    (model.Description === bseModelLanguge.Description ||
                        model.Description === modelLanguge.Description ||
                        model.Description === model.BaseTimeEntryType.Description)
                    ? null
                    : model.Description;
            model.DefaultPayslipText =
                model.defaultPayslipTextClass === classCheck &&
                    (model.DefaultPayslipText === bseModelLanguge.DefaultPayslipText ||
                        model.DefaultPayslipText === modelLanguge.DefaultPayslipText ||
                        model.DefaultPayslipText === model.BaseTimeEntryType.DefaultPayslipText)
                    ? null
                    : model.DefaultPayslipText;
            model.BaseTimeEntryType = isModelBaseTimeEntryTypeNull ? null : model.BaseTimeEntryType;
        }
    };
    TimeEntryTypesComponent.prototype.addNewTimeEntryType = function (timeEntryType) {
        var newRecord = new TimeEntryTypeView(timeEntryType, this.isLanguageModuleEnable);
        this.processGridItemObject(newRecord);
        this.gridData.push(newRecord);
        this.selectedTimeEntryType = newRecord;
        this.gridFilterData = filterBy(this.gridData, this.filter);
        this.scrollToNewRow();
    };
    TimeEntryTypesComponent.prototype.onCellClick = function (event) {
        if (['Name', 'Description', 'DefaultPayslipText'].includes(event.field) && this.isLanguageModuleEnable) {
            this.selectedTimeEntryType = event.dataItem;
            this.translationDialogVisible = true;
        }
        if (event.field === 'configurationIcon') {
            this.timeEntryTypeConfigurationDialogVisible = true;
        }
    };
    TimeEntryTypesComponent.prototype.loadTimeEntryTypes = function (event) {
        var _this = this;
        if (event) {
            this.selectedTimeEntryType.TimeEntryTypeTranslations = event;
        }
        this.gridData = [];
        this.gridFilterData = [];
        this.companyTimeEntryTypeService.loadTimeEntryType().subscribe(function () {
            _this.isWattingLoading = false;
        }, function () {
            _this.isWattingLoading = false;
        });
    };
    TimeEntryTypesComponent.prototype.processGridItemObject = function (model) {
        var existingTimeEntryType = this.gridData && model ? this.gridData.find(function (s) { return s.Id === model.Id; }) : undefined;
        model.TimeEntryTypeTranslations = model.TimeEntryTypeTranslations
            ? model.TimeEntryTypeTranslations
            : existingTimeEntryType
                ? existingTimeEntryType.TimeEntryTypeTranslations
                : undefined;
        model.BaseTimeEntryType = model.BaseTimeEntryType
            ? model.BaseTimeEntryType
            : existingTimeEntryType
                ? existingTimeEntryType.BaseTimeEntryType
                : undefined;
        if (model.TimeEntryTypeConfigurations) {
            if (model.TimeEntryTypeConfigurations.length > 0) {
                model.customSalaryTypeClass = 'grid-disable-cell';
                model.editSalaryType = false;
                model.ToolTipSalaryType = this.translatetooltipsalarytype;
                model.SalaryTypeId = null;
            }
            else {
                model.customSalaryTypeClass = '';
                model.editSalaryType = !this.IsReadOnly;
                model.ToolTipSalaryType = '';
            }
        }
        else {
            model.customSalaryTypeClass = '';
            model.editSalaryType = !this.IsReadOnly;
            model.ToolTipSalaryType = '';
        }
    };
    TimeEntryTypesComponent.prototype.convertExternalRefCodeToFriendlyName = function () {
        var _this = this;
        if (this.gridData && this.gridData.length > 0 && this.externalAccounts && this.externalAccounts.length > 0) {
            this.gridData.forEach(function (salaryType) {
                salaryType.ExternalReference = _this.getExteralReferenceCode(salaryType.ExternalReference, true);
            });
        }
    };
    TimeEntryTypesComponent.prototype.getExteralReferenceCode = function (refCode, fullName) {
        if (!refCode) {
            return null;
        }
        var externalRefCode = this.externalAccounts
            ? this.externalAccounts.find(function (item) {
                return refCode === item.AccountNumber || refCode === item.FriendlyName;
            })
            : null;
        if (externalRefCode) {
            return !fullName ? externalRefCode.AccountNumber : externalRefCode.FriendlyName;
        }
        return refCode;
    };
    TimeEntryTypesComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        this.gridFilterData = filterBy(this.gridData, filter);
    };
    TimeEntryTypesComponent.prototype.scrollToNewRow = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.timeEntryTypesGrid && _this.timeEntryTypesGrid.kendoGrid) {
                var element = _this.timeEntryTypesGrid.kendoGrid.wrapper.nativeElement
                    .querySelector('.k-grid-content')
                    .querySelector('.k-state-selected');
                if (element) {
                    element.scrollIntoView(false);
                }
            }
        });
    };
    TimeEntryTypesComponent.prototype.onTimeEntryTypeConfigurationVisibleChange = function (change) {
        if (change === true) {
            this.loadTimeEntryTypes();
        }
    };
    return TimeEntryTypesComponent;
}());
export { TimeEntryTypesComponent };
