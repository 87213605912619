/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../custom-controls/text-edit/text-edit.component.ngfactory";
import * as i2 from "../../../../custom-controls/text-edit/text-edit.component";
import * as i3 from "../../../../services/session/session.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../custom-controls/combobox-edit/combobox-edit.component.ngfactory";
import * as i6 from "../../../../custom-controls/combobox-edit/combobox-edit.component";
import * as i7 from "../../../../custom-controls/dialogs/dialog.component.ngfactory";
import * as i8 from "../../../../custom-controls/dialogs/dialog.component";
import * as i9 from "@angular/platform-browser";
import * as i10 from "@angular/common";
import * as i11 from "../../../../custom-controls/dialogs/dialog-action.component.ngfactory";
import * as i12 from "../../../../custom-controls/dialogs/dialog-action.component";
import * as i13 from "./new-time-entry-type-dialog.component";
import * as i14 from "../../../../services/api/data.service";
var styles_NewTimeEntryTypeDialogComponent = [];
var RenderType_NewTimeEntryTypeDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewTimeEntryTypeDialogComponent, data: {} });
export { RenderType_NewTimeEntryTypeDialogComponent as RenderType_NewTimeEntryTypeDialogComponent };
function View_NewTimeEntryTypeDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "app-text-edit", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.timeEntryTypeName = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_TextEditComponent_0, i1.RenderType_TextEditComponent)), i0.ɵdid(2, 114688, null, 0, i2.TextEditComponent, [i3.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], label: [2, "label"] }, { valueChange: "valueChange" }), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 3, "app-combobox-edit", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.baseTimeEntryTypeId = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ComboboxEditComponent_0, i5.RenderType_ComboboxEditComponent)), i0.ɵdid(5, 4440064, null, 0, i6.ComboboxEditComponent, [i3.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], idField: [2, "idField"], textField: [3, "textField"], label: [4, "label"], placeholder: [5, "placeholder"], comboboxDataSource: [6, "comboboxDataSource"] }, { valueChange: "valueChange" }), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.timeEntryTypeName; var currVal_1 = !_co.IsReadOnly; var currVal_2 = i0.ɵunv(_v, 2, 2, i0.ɵnov(_v, 3).transform("CompanyTimeEntryTypes.Name")); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.baseTimeEntryTypeId; var currVal_4 = !_co.IsReadOnly; var currVal_5 = "Id"; var currVal_6 = "Name"; var currVal_7 = i0.ɵunv(_v, 5, 4, i0.ɵnov(_v, 6).transform("CompanyTimeEntryTypes.BaseTimeEntryType")); var currVal_8 = i0.ɵunv(_v, 5, 5, i0.ɵnov(_v, 7).transform("CompanySalaryTypes.PleaseChoose")); var currVal_9 = _co.defaultTimeEntryTypes; _ck(_v, 5, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
export function View_NewTimeEntryTypeDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "app-dialog", [], null, [[null, "visibleChange"], [null, "action"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.visible = $event) !== false);
        ad = (pd_0 && ad);
    } if (("action" === en)) {
        var pd_1 = (_co.onDialogAction($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_DialogComponent_0, i7.RenderType_DialogComponent)), i0.ɵdid(1, 49152, null, 1, i8.DialogComponent, [i9.DomSanitizer, i3.SessionService, i0.ElementRef], { title: [0, "title"], width: [1, "width"], overlayClose: [2, "overlayClose"], visible: [3, "visible"] }, { visibleChange: "visibleChange", action: "action" }), i0.ɵqud(603979776, 1, { buttonElements: 1 }), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_NewTimeEntryTypeDialogComponent_1)), i0.ɵdid(5, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, 0, 1, "app-dialog-action", [], null, null, null, i11.View_DialogActionComponent_0, i11.RenderType_DialogActionComponent)), i0.ɵdid(7, 180224, [[1, 4]], 0, i12.DialogActionComponent, [i3.SessionService], { type: [0, "type"] }, null), (_l()(), i0.ɵeld(8, 0, null, 0, 1, "app-dialog-action", [], null, null, null, i11.View_DialogActionComponent_0, i11.RenderType_DialogActionComponent)), i0.ɵdid(9, 180224, [[1, 4]], 0, i12.DialogActionComponent, [i3.SessionService], { close: [0, "close"], disabled: [1, "disabled"], type: [2, "type"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 5, "app-dialog", [["class", "blue-dialog"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.validationWarningVisible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_DialogComponent_0, i7.RenderType_DialogComponent)), i0.ɵdid(11, 49152, null, 1, i8.DialogComponent, [i9.DomSanitizer, i3.SessionService, i0.ElementRef], { leadingText: [0, "leadingText"], visible: [1, "visible"] }, { visibleChange: "visibleChange" }), i0.ɵqud(603979776, 2, { buttonElements: 1 }), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(14, 0, null, 0, 1, "app-dialog-action", [], null, null, null, i11.View_DialogActionComponent_0, i11.RenderType_DialogActionComponent)), i0.ɵdid(15, 180224, [[2, 4]], 0, i12.DialogActionComponent, [i3.SessionService], { type: [0, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 3).transform("CompanyTimeEntryTypes.NewCustomTimeEntryTypeHeader")); var currVal_1 = 450; var currVal_2 = false; var currVal_3 = _co.visible; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.visible; _ck(_v, 5, 0, currVal_4); var currVal_5 = "Cancel"; _ck(_v, 7, 0, currVal_5); var currVal_6 = false; var currVal_7 = ((_co.sessionService.isSubmitting || !_co.timeEntryTypeName) || !_co.baseTimeEntryTypeId); var currVal_8 = "Create"; _ck(_v, 9, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 13).transform("CompanySalaryTypes.CreateNewFieldsIncomplete")); var currVal_10 = _co.validationWarningVisible; _ck(_v, 11, 0, currVal_9, currVal_10); var currVal_11 = "Ok"; _ck(_v, 15, 0, currVal_11); }, null); }
export function View_NewTimeEntryTypeDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-new-time-entry-type-dialog", [], null, null, null, View_NewTimeEntryTypeDialogComponent_0, RenderType_NewTimeEntryTypeDialogComponent)), i0.ɵdid(1, 114688, null, 0, i13.NewTimeEntryTypeDialogComponent, [i14.DataService, i3.SessionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewTimeEntryTypeDialogComponentNgFactory = i0.ɵccf("app-new-time-entry-type-dialog", i13.NewTimeEntryTypeDialogComponent, View_NewTimeEntryTypeDialogComponent_Host_0, { defaultTimeEntryTypes: "defaultTimeEntryTypes", visible: "visible" }, { visibleChange: "visibleChange", add: "add" }, []);
export { NewTimeEntryTypeDialogComponentNgFactory as NewTimeEntryTypeDialogComponentNgFactory };
