import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Global } from '../common/global';
import { CompanySalaryBatchService } from '../company/salary-batches/company-salary-batches.service';
import { DefaultAvatar } from '../model/default-avatar';
import { IListDataItem } from '../model/ilist-data-item';
import { SessionService } from '../services/session/session.service';
import {
  CompanyUserListRequest,
  IAddUserToCompanyRequest,
  ICompanyUser,
  ISimpleCompanyUser,
  IUserCreationData,
  IUserEmployment,
  UserEmployment
} from './api/api-model';
import { DataService } from './api/data.service';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  private employeesSubject: BehaviorSubject<IListDataItem[]>;
  public get employees() {
    if (!this.employeesSubject) {
      this.loadEmployees();
    }

    return this.employeesSubject.asObservable();
  }

  private employeesCountSubject = new BehaviorSubject<number>(0);
  public get employeesCount() {
    return this.employeesCountSubject.asObservable();
  }

  private employeeSubject = new BehaviorSubject<ICompanyUser | null>(null);
  public get employee() {
    return this.employeeSubject.asObservable();
  }

  private employmentsSubject = new BehaviorSubject<IUserEmployment[]>([]);
  public get employments() {
    return this.employmentsSubject.asObservable();
  }

  private employmentSubject = new BehaviorSubject<IUserEmployment>(new UserEmployment());
  public get employment() {
    return this.employmentSubject.asObservable();
  }

  public get employmentsCount() {
    return this.employmentsSubject.value.length;
  }

  private userImageSubject = new BehaviorSubject<string>(DefaultAvatar.imgMaleAvatar);
  public get userImage() {
    return this.userImageSubject.asObservable();
  }

  private filterValue = '';
  public get filter() {
    return this.filterValue;
  }
  public set filter(value: string) {
    if (this.filterValue !== value) {
      this.filterValue = value;
      this.filterSubject.next(value);
    }
  }

  private filterSubject: BehaviorSubject<string> = new BehaviorSubject('');

  private departmentIdValue = -1;
  public get departmentId() {
    return this.departmentIdValue;
  }
  public set departmentId(value: number) {
    if (this.departmentIdValue !== value) {
      this.departmentIdValue = value;
      this.loadEmployees();
    }
  }

  private salaryCycleIdValue = -1;
  public get salaryIdCycle() {
    return this.salaryCycleIdValue;
  }
  public set salaryIdCycle(value: number) {
    if (this.salaryCycleIdValue !== value) {
      this.salaryCycleIdValue = value;
      this.loadEmployees();
    }
  }

  private templateIdValue = -1;
  public get templateId() {
    return this.templateIdValue;
  }
  public set templateId(value: number) {
    if (this.templateIdValue !== value) {
      this.templateIdValue = value;
      this.loadEmployees();
    }
  }

  private sortByValue = 1;
  public get sortBy() {
    return this.sortByValue;
  }
  public set sortBy(value: number) {
    if (this.sortByValue !== value) {
      this.sortByValue = value;
      this.loadEmployees();
    }
  }

  private includeInactiveValue = false;
  public get includeInactive() {
    return this.includeInactiveValue;
  }
  public set includeInactive(value: boolean) {
    if (this.includeInactiveValue !== value) {
      this.includeInactiveValue = value;
      this.loadEmployees();
    }
  }

  private selectedEmployeeIdSubject = new BehaviorSubject<number | null>(null);
  public get selectedEmployeeId() {
    return this.selectedEmployeeIdSubject.asObservable();
  }

  private selectedEmploymentIdSubject = new BehaviorSubject<number | null>(null);
  public get selectedEmploymentId() {
    return this.selectedEmploymentIdSubject.asObservable();
  }

  public get selectedId() {
    return this.selectedEmployeeIdSubject.value;
  }

  public get isNonSelected(): boolean {
    return !this.employeeSubject.value;
  }

  public get isCurrentUserHasEmployment(): boolean {
    if (this.allEmployees && this.allEmployees.length > 0) {
      return (
        this.currentCompanyUser &&
        this.currentCompanyUser.UserEmploymentIds &&
        this.currentCompanyUser.UserEmploymentIds.length > 0
      );
    }
    return false;
  }

  public get isCurrentUserExistIncompanyUsers(): boolean {
    if (this.allEmployees && this.allEmployees.length > 0) {
      return !!this.currentCompanyUser;
    }
    return false;
  }

  public get currentCompanyUser() {
    if (this.allEmployees && this.allEmployees.length > 0) {
      return this.allEmployees.find((model: ISimpleCompanyUser) => model.Id === Global.SESSION.CurrentCompanyUser.Id);
    }

    return undefined;
  }

  public editMode = false;
  public editModeChangeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public makeRequestTaxCard: EventEmitter<boolean> = new EventEmitter<boolean>();

  private allEmployees: ISimpleCompanyUser[] = [];

  constructor(private sessionService: SessionService, private dataService: DataService) {
    this.filterSubject
      .asObservable()
      .pipe(
        debounceTime(700),
        distinctUntilChanged()
      )
      .subscribe((value: string) => this.filterEmployees());
  }

  public updateUserImage(data: string) {
    if (Object.keys(data).length !== 0) {
      return;
    }

    this.dataService
      .User_UploadEmployeeImage(data, this.employeeSubject.value.UserId)
      .subscribe(() => this.getUserImage());
  }

  public saveChanges(updateTemplate = false) {
    this.ConvertCorrectEmployeeObject(this.employeeSubject.value);

    const request: ICompanyUser = this.employeeSubject.value;

    if (this.employmentSubject.value && this.employmentSubject.value.Id) {
      const activeEmployment: IUserEmployment = request.UserEmployments.find(
        (z: IUserEmployment) => z.Id === this.employmentSubject.value.Id
      );
      request.UserEmployments = [];
      request.UserEmployments.push(activeEmployment);
    }

    return this.dataService.CompanyUsers_UpdateCompanyUser(request).pipe(
      tap((employee) => {
        if (
          Global.SESSION.CurrentCompanyUser &&
          Global.SESSION.CurrentCompanyUser.Id === this.employeeSubject.value.Id
        ) {
          Global.USEREMPLOYMENTS = employee.UserEmployments;
        }

        if (updateTemplate) {
          this.dataService
            .Employments_UpdateEmploymentToCurrentTemplate(this.employmentSubject.value.Id)
            .subscribe((employment) => {
              const index = employee.UserEmployments.findIndex((e) => e.Id === employment.Id);
              employee.UserEmployments[index] = employment;
              this.updateEmployee(employee);
              this.employeeSubject.next(employee);
              this.filterEmployees();
            });
        } else {
          this.updateEmployee(employee);
          this.employeeSubject.next(employee);
          this.filterEmployees();
        }
      })
    );
  }

  private ConvertCorrectEmployeeObject(employee: ICompanyUser): void {
    if (employee) {
      employee.Id = employee.Id || null;
      employee.UserId = employee.UserId || null;
      employee.CompanyId = employee.CompanyId || null;
      employee.RoleId = employee.RoleId || null;
      employee.IsActive = employee.IsActive || false;
      employee.FirstName = employee.FirstName || null;
      employee.MiddleName = employee.MiddleName || null;
      employee.LastName = employee.LastName || null;
      employee.BankRegNo = employee.BankRegNo || null;
      employee.BankAccountNo = employee.BankAccountNo || null;
      employee.BicSwiftNo = employee.BicSwiftNo || null;
      employee.IbanNo = employee.IbanNo || null;
      employee.AddressId = employee.AddressId || null;
      employee.IsPaymentApprover = employee.IsPaymentApprover || false;
      employee.Initials = employee.Initials || null;
      employee.TelMobile = employee.TelMobile || null;
      employee.TelFixed = employee.TelFixed || null;
      employee.CompanyEmail = employee.CompanyEmail || null;
      employee.PersonalEmail = employee.PersonalEmail || null;
      employee.Created = employee.Created || null;
      employee.DeactivationDate = employee.DeactivationDate || null;
      employee.PreferredCurrencyId = employee.PreferredCurrencyId || null;
      employee.AutoDetermineBankAccount = employee.AutoDetermineBankAccount || false;
      employee.MunicipalityId = employee.MunicipalityId || null;
      employee.FullName = employee.FullName || null;
      employee.Role = employee.Role || null;
      employee.UserLanguageId = employee.UserLanguageId || null;
      employee.UserIdentityNumber = employee.UserIdentityNumber || null;
      employee.UserDateOfBirth = employee.UserDateOfBirth || null;
      employee.UserIsFemale = employee.UserIsFemale || false;
      employee.HasDummyIdentityNumber = employee.HasDummyIdentityNumber || false;

      if (employee.UserEmployments) {
        this.ConvertCorrectEmploymentObject(employee.UserEmployments);
      }
    }
  }

  private ConvertCorrectEmploymentObject(employment: IUserEmployment[]): void {
    if (employment && employment.length > 0) {
      employment.forEach((item: IUserEmployment) => {
        item.Id = item.Id || null;
        item.CompanyUserId = item.CompanyUserId || null;
        item.HireDate = item.HireDate || null;
        item.ExpireDate = item.ExpireDate || null;
        item.VacationTypeId = item.VacationTypeId || null;
        item.VacationProviderId = item.VacationProviderId || null;
        item.VacationExtraPercentage = this.checkNumber(item.VacationExtraPercentage);
        item.BasedOnTemplateId = item.BasedOnTemplateId || null;
        item.Title = item.Title || null;
        item.SalaryCycleId = item.SalaryCycleId || null;
        item.ExternalReference = item.ExternalReference || null;
        item.PensionBaseCalculationMethodId = item.PensionBaseCalculationMethodId || null;
        item.IsAtpTempWorker = item.IsAtpTempWorker || false;
        item.DepartmentId = item.DepartmentId || null;
        item.IncomeTypeId = item.IncomeTypeId || null;
        item.WorksElsewhere = item.WorksElsewhere || false;
        item.PensionEmployeeShare = this.checkNumber(item.PensionEmployeeShare);
        item.PensionCompanyShare = this.checkNumber(item.PensionCompanyShare);
        item.PensionProviderId = item.PensionProviderId || null;
        item.PensionPolicyNumber = item.PensionPolicyNumber || null;
        item.PensionEmployeeAmount = this.checkNumber(item.PensionEmployeeAmount);
        item.PensionCompanyAmount = this.checkNumber(item.PensionCompanyAmount);
        item.Pension2BaseCalculationMethodId = item.Pension2BaseCalculationMethodId || null;
        item.Pension2ProviderId = item.Pension2ProviderId || null;
        item.Pension2EmployeeShare = this.checkNumber(item.Pension2EmployeeShare);
        item.Pension2CompanyShare = this.checkNumber(item.Pension2CompanyShare);
        item.Pension2EmployeeAmount = this.checkNumber(item.Pension2EmployeeAmount);
        item.Pension2CompanyAmount = this.checkNumber(item.Pension2CompanyAmount);
        item.LastStatementEndDate = item.LastStatementEndDate || null;
        //item.VacationReduceSalary = item.VacationReduceSalary || false;
        item.IsTerminated = item.IsTerminated || false;
        item.IsInMaternityProgram = item.IsInMaternityProgram || false;
        item.TaxCardRequested = item.TaxCardRequested || null;
        item.PensionProviderBankRegNo = item.PensionProviderBankRegNo || null;
        item.PensionProviderBankAccountNo = item.PensionProviderBankAccountNo || null;
        item.Pension2ProviderBankRegNo = item.Pension2ProviderBankRegNo || null;
        item.Pension2ProviderBankAccountNo = item.Pension2ProviderBankAccountNo || null;
        item.Pension2PolicyNumber = item.Pension2PolicyNumber || null;
        item.PreferredTaxCardTypeId = item.PreferredTaxCardTypeId || null;
        item.Created = item.Created || null;
        item.IllnessReducesSalary = item.IllnessReducesSalary || false;
        item.ManagerCompanyUserId = item.ManagerCompanyUserId || null;
        item.StandardHoursPerWeek = this.checkNumber(item.StandardHoursPerWeek);
        item.StandardWorkDaysPerWeek = this.checkNumber(item.StandardWorkDaysPerWeek);
        item.StandardWorkHoursMonday = this.checkNumber(item.StandardWorkHoursMonday);
        item.StandardWorkHoursTuesday = this.checkNumber(item.StandardWorkHoursTuesday);
        item.StandardWorkHoursWednesday = this.checkNumber(item.StandardWorkHoursWednesday);
        item.StandardWorkHoursThursday = this.checkNumber(item.StandardWorkHoursThursday);
        item.StandardWorkHoursFriday = this.checkNumber(item.StandardWorkHoursFriday);
        item.StandardWorkHoursSaturday = this.checkNumber(item.StandardWorkHoursSaturday);
        item.StandardWorkHoursSunday = this.checkNumber(item.StandardWorkHoursSunday);
        item.StatisticsSalaryPrincipleId = item.StatisticsSalaryPrincipleId || null;
        item.StatisticsEmploymentPrincipleId = item.StatisticsEmploymentPrincipleId || null;
        item.StatisticsEmploymentTermsId = item.StatisticsEmploymentTermsId || null;
        item.StatisticsJobStatusId = item.StatisticsJobStatusId || null;
        item.StatisticsJobFunctionCode = this.checkNumber(item.StatisticsJobFunctionCode);
        item.StatisticsSalaryPrincipleFromDate = item.StatisticsSalaryPrincipleFromDate || null;
        item.StatisticsEmploymentPrincipleFromDate = item.StatisticsEmploymentPrincipleFromDate || null;
        item.StatisticsEmploymentTermsFromDate = item.StatisticsEmploymentTermsFromDate || null;
        item.StatisticsJobStatusFromDate = item.StatisticsJobStatusFromDate || null;
        item.StatisticsJobFunctionFromDate = item.StatisticsJobFunctionFromDate || null;
        item.FlexEarningPercentage = this.checkNumber(item.FlexEarningPercentage);
        item.FlexBaseCalculationMethodId = item.FlexBaseCalculationMethodId || null;
        item.CollectiveBargainingAgreementCode = item.CollectiveBargainingAgreementCode || null;
        item.TerminationVacationOverridePercentage = this.checkNumber(item.TerminationVacationOverridePercentage);
        item.SeniorityDate = item.SeniorityDate || null;
        item.ApplyDanishRulesInGreenland = item.ApplyDanishRulesInGreenland || false;
        item.AnnualTaxComment = item.AnnualTaxComment || null;
        item.AllowExtendedTaxCardDeductions = item.AllowExtendedTaxCardDeductions || false;
        item.ReduceTaxCardDeduction = this.checkNumber(item.ReduceTaxCardDeduction);
        item.IncreaseTaxRatePercentage = this.checkNumber(item.IncreaseTaxRatePercentage);
        item.UseTaxDeductionPerWorkDay = item.UseTaxDeductionPerWorkDay || false;
        item.DefaultRate = this.checkNumber(item.DefaultRate);
        item.UseContinuousVacationReporting = item.UseContinuousVacationReporting || false;

        item.PensionStartDate = item.PensionStartDate || null;
        item.PensionEndDate = item.PensionEndDate || null;
        item.Pension2StartDate = item.Pension2StartDate || null;
        item.Pension2EndDate = item.Pension2EndDate || null;
      });
    }
  }

  public checkNumber(value: number): number {
    if ((!value && value === 0) || value) {
      return value;
    }
    return null;
  }

  public discardChanges() {
    this.selectEmployee(this.employeeSubject.value.Id, true);
  }

  public selectEmployeeFromEmploymentId(employmentId: number, isDbClicKFromGrid?: boolean) {
    const employee = this.allEmployees.find((e) => {
      if (e.UserEmploymentIds) {
        const employment = e.UserEmploymentIds.find((ue) => ue === employmentId);
        return !!employment;
      }

      return false;
    });

    if (employee) {
      if (isDbClicKFromGrid) {
        this.filter = '';
      }

      this.selectEmployee(employee.Id, true, employmentId);
    }
  }

  public selectEmployee(id?: number, reload = false, employmentId?: number) {
    if (id) {
      if (reload || id !== this.selectedEmployeeIdSubject.value) {
        this.selectedEmployeeIdSubject.next(id);
        this.dataService.CompanyUsers_GetCompanyUserById(id).subscribe((employee: ICompanyUser) => {
          this.updateEmployee(employee);
          if (employee.Id === this.selectedEmployeeIdSubject.value) {
            this.employeeSubject.next(employee);
            this.getUserImage();
            if (employee.UserEmployments && employee.UserEmployments.length > 0) {
              this.employmentsSubject.next(employee.UserEmployments);
              if (employmentId && employee.UserEmployments.some((e) => e.Id === employmentId)) {
                this.selectEmployment(employmentId);
              } else if (employee.UserEmployments.some((e) => e.Id === this.selectedEmploymentIdSubject.value)) {
                this.selectEmployment(this.selectedEmploymentIdSubject.value);
              } else {
                this.selectEmployment(employee.UserEmployments[0].Id);
              }
            } else {
              this.employmentsSubject.next([]);
              this.selectEmployment(-1);
            }
          }
        });
      }
    } else {
      this.employeeSubject.next(null);
      this.employmentsSubject.next([]);
      this.userImageSubject.next(DefaultAvatar.imgMaleAvatar);
    }
  }

  public selectEmployeeAsync(id?: number, reload = false, employmentId?: number): Observable<boolean> {
    if (id) {
      if (reload || id !== this.selectedEmployeeIdSubject.value) {
        this.selectedEmployeeIdSubject.next(id);

        return Observable.create((obserdata: any) => {
          this.dataService.CompanyUsers_GetCompanyUserById(id).subscribe((employee: ICompanyUser) => {
            this.updateEmployee(employee);
            if (employee.Id === this.selectedEmployeeIdSubject.value) {
              this.employeeSubject.next(employee);
              this.getUserImage();
              if (employee.UserEmployments && employee.UserEmployments.length > 0) {
                this.employmentsSubject.next(employee.UserEmployments);
                if (employmentId && employee.UserEmployments.some((e) => e.Id === employmentId)) {
                  this.selectEmployment(employmentId);
                } else if (employee.UserEmployments.some((e) => e.Id === this.selectedEmploymentIdSubject.value)) {
                  this.selectEmployment(this.selectedEmploymentIdSubject.value);
                } else {
                  this.selectEmployment(employee.UserEmployments[0].Id);
                }
              } else {
                this.employmentsSubject.next([]);
                this.selectEmployment(-1);
              }
            }

            obserdata.next(true);
          });
        });
      }
      return Observable.create((obserdata: any) => {
        obserdata.next(false);
      });
    } else {
      return Observable.create((obserdata: any) => {
        this.employeeSubject.next(null);
        this.employmentsSubject.next([]);
        this.userImageSubject.next(DefaultAvatar.imgMaleAvatar);

        obserdata.next(true);
      });
    }
  }

  public selectNext() {
    const employees = this.employeesSubject.value;
    const currentIndex = employees.findIndex((e) => e.Id === this.selectedEmployeeIdSubject.value);
    const nextIndex = currentIndex < employees.length - 1 ? currentIndex + 1 : 0;
    this.selectEmployee(employees[nextIndex].Id);
  }

  public selectPrevious() {
    const employees = this.employeesSubject.value;
    const currentIndex = employees.findIndex((e) => e.Id === this.selectedEmployeeIdSubject.value);
    const previousIndex = currentIndex > 0 ? currentIndex - 1 : employees.length - 1;
    this.selectEmployee(employees[previousIndex].Id);
  }

  public selectEmployment(id: number) {
    const employment = this.employmentsSubject.value.find((e) => e.Id === id);
    if (employment) {
      this.employmentSubject.next(employment);
      this.selectedEmploymentIdSubject.next(id);
    } else {
      this.employmentSubject.next(new UserEmployment());
      this.selectedEmploymentIdSubject.next(null);
    }
  }

  public loadEmployees() {
    if (!this.employeesSubject) {
      this.employeesSubject = new BehaviorSubject<IListDataItem[]>([]);
    }

    const request: CompanyUserListRequest = {
      IncludeInactive: true,
      IncludeHidden: false,
      SortBySurname: this.sortBy === 2,
      LimitToDepartmentId: this.departmentId !== -1 ? this.departmentId : undefined,
      LimitToSalaryCycleId: this.salaryIdCycle !== -1 ? this.salaryIdCycle : undefined,
      LimitToTemplateId: this.templateId !== -1 ? this.templateId : undefined
    };

    this.dataService
      .CompanyUsers_GetAllCompanyUsersSimpleWithOptions(request)
      .subscribe((data: ISimpleCompanyUser[]) => {
        this.allEmployees = data;
        this.filterEmployees();
      });
  }

  public async loadEmployeesAsync() {
    if (!this.employeesSubject) {
      this.employeesSubject = new BehaviorSubject<IListDataItem[]>([]);
    }

    const request: CompanyUserListRequest = {
      IncludeInactive: true,
      IncludeHidden: false,
      SortBySurname: this.sortBy === 2,
      LimitToDepartmentId: this.departmentId !== -1 ? this.departmentId : undefined,
      LimitToSalaryCycleId: this.salaryIdCycle !== -1 ? this.salaryIdCycle : undefined,
      LimitToTemplateId: this.templateId !== -1 ? this.templateId : undefined
    };

    this.allEmployees = await this.dataService.CompanyUsers_GetAllCompanyUsersSimpleWithOptions(request).toPromise();

    this.filterEmployees();
  }

  public createEmployee(newEmployeeRequest: IAddUserToCompanyRequest) {
    newEmployeeRequest.Details.IsActive = true;
    newEmployeeRequest.Details.CompanyId = Global.COMPANY_ID;
    if (!newEmployeeRequest.EmploymentTemplateId) {
      newEmployeeRequest.EmploymentTemplateId = undefined;
      newEmployeeRequest.Title = undefined;
      newEmployeeRequest.DepartmentId = undefined;
      newEmployeeRequest.TaxCardTypeId = undefined;
      newEmployeeRequest.ManagerCompanyUserId = undefined;
    }

    this.filter = '';
    if (this.departmentId) {
      this.departmentId = -1;
    }
    if (this.salaryIdCycle) {
      this.salaryIdCycle = -1;
    }
    if (this.templateId) {
      this.templateId = -1;
    }

    return this.dataService.CompanyUsers_AddUserToCompany(newEmployeeRequest).pipe(
      tap((data: IUserCreationData) => {
        this.selectedEmployeeIdSubject.next(data.CompanyUser.Id);
        this.loadEmployees();
      })
    );
  }

  public deleteEmployee() {
    return this.dataService.CompanyUsers_DeleteCompanyUser(this.employeeSubject.value.Id).pipe(
      tap(() => {
        this.allEmployees = this.allEmployees.filter((e) => e.Id !== this.employeeSubject.value.Id);
        this.filterEmployees();
      })
    );
  }

  public createEmployment(userEmployment: IUserEmployment) {
    return this.dataService.Employments_CreateUserEmployment(userEmployment).pipe(
      tap((data: IUserEmployment) => {
        if (!this.employeeSubject.value.UserEmployments) {
          this.employeeSubject.value.UserEmployments = [];
        }

        this.employeeSubject.value.UserEmployments.push(data);
        this.employmentsSubject.next(this.employeeSubject.value.UserEmployments);
        this.selectEmployment(data.Id);
      })
    );
  }

  public deleteEmployment() {
    return this.dataService.Employments_DeleteUserEmployment(this.employmentSubject.value.Id).pipe(
      tap(() => {
        this.employeeSubject.value.UserEmployments = this.employeeSubject.value.UserEmployments.filter(
          (e) => e.Id !== this.employmentSubject.value.Id
        );

        this.employmentsSubject.next(this.employeeSubject.value.UserEmployments);
        this.selectEmployee(this.employeeSubject.value.Id, true);
        if (this.employeeSubject.value.UserEmployments.length > 0) {
          this.selectEmployment(this.employeeSubject.value.UserEmployments[0].Id);
        } else {
          this.selectEmployment(-1);
        }
      })
    );
  }

  private getUserImage() {
    this.dataService.Users_GetUserImage(this.employeeSubject.value.UserId).subscribe(
      (data) => {
        if (data && data.Image && this.employeeSubject.value && data.UserId === this.employeeSubject.value.UserId) {
          this.userImageSubject.next(data.Image);
        } else {
          this.userImageSubject.next(
            this.employeeSubject.value && this.employeeSubject.value.UserIsFemale
              ? DefaultAvatar.imgFemaleAvatar
              : DefaultAvatar.imgMaleAvatar
          );
        }
      },
      () => {
        this.userImageSubject.next(
          this.employeeSubject.value && this.employeeSubject.value.UserIsFemale
            ? DefaultAvatar.imgFemaleAvatar
            : DefaultAvatar.imgMaleAvatar
        );
      }
    );
  }

  private updateEmployee(employee: ICompanyUser) {
    if (this.allEmployees.length > 0) {
      const index = this.allEmployees.findIndex((e) => e.Id === employee.Id);
      if (index > -1) {
        this.allEmployees[index].Id = employee.Id;
        this.allEmployees[index].IsActive = employee.IsActive;
        this.allEmployees[index].FirstName = employee.FirstName;
        this.allEmployees[index].MiddleName = employee.MiddleName;
        this.allEmployees[index].LastName = employee.LastName;
        this.allEmployees[index].FullName = employee.FullName;
        this.allEmployees[index].RoleId = employee.RoleId;
        this.allEmployees[index].UserId = employee.UserId;
        this.allEmployees[index].IdentityNumber = employee.UserIdentityNumber;
        this.allEmployees[index].PersonalEmail = employee.PersonalEmail;
        this.allEmployees[index].CompanyEmail = employee.CompanyEmail;

        if (employee.UserEmployments) {
          this.allEmployees[index].ExternalReferences = employee.UserEmployments.map((e) => e.ExternalReference);
          this.allEmployees[index].UserEmploymentIds = employee.UserEmployments.map((e) => e.Id);
        } else {
          this.allEmployees[index].ExternalReferences = [];
          this.allEmployees[index].UserEmploymentIds = [];
        }
      }
    }
  }

  public filterEmployees() {
    if (!this.allEmployees) {
      return;
    }

    let result = this.allEmployees;

    result = result.filter((e) => {
      if (!this.includeInactiveValue && !e.IsActive) {
        return false;
      }

      return true;
    });

    if (this.filter) {
      const keyword = this.filter.toLowerCase().trim();
      result = result.filter((e) => {
        if (e.FullName.toLowerCase().includes(keyword)) {
          return true;
        }

        if (e.CompanyEmail && e.CompanyEmail.toLowerCase().includes(keyword)) {
          return true;
        }

        if (e.PersonalEmail && e.PersonalEmail.toLowerCase().includes(keyword)) {
          return true;
        }

        if (
          e.IdentityNumber &&
          e.IdentityNumber.replace('-', '')
            .toLowerCase()
            .includes(keyword.replace('-', ''))
        ) {
          return true;
        }

        if (e.ExternalReferences) {
          if (e.ExternalReferences.find((ue) => ue && ue.toLowerCase().includes(keyword))) {
            return true;
          }
        }

        if (e.UserEmploymentIds) {
          if (e.UserEmploymentIds.find((ue) => ue.toString().includes(keyword))) {
            return true;
          }
        }

        return false;
      });
    }

    this.employeesCountSubject.next(result.length);

    // Dont select employee at small device
    if (this.sessionService.employeeListActive && this.sessionService.browser.isMobile) {
      this.employeesSubject.next(this.createEmployeeList(result));
      return;
    }

    // Select employee after filter
    if (result.length > 0) {
      this.employeesSubject.next(this.createEmployeeList(result));
      if (!this.selectedEmployeeIdSubject.value) {
        if (!this.sessionService.currentState.includes('tabs.accountant')) {
          this.selectEmployee(result[0].Id, true);
        }
      } else {
        if (result.some((e) => e.Id === this.selectedEmployeeIdSubject.value)) {
          this.selectEmployee(
            this.selectedEmployeeIdSubject.value,
            !this.sessionService.currentState.includes('tabs.selfservice')
          );
        } else {
          this.selectEmployee(result[0].Id, true);
        }
      }
    } else {
      if (this.employeesSubject && !this.sessionService.currentState.includes('tabs.selfservice')) {
        this.employeesSubject.next([]);
        if (this.filter && this.filter.length > 0) {
          setTimeout(() => {
            this.selectEmployee(undefined);
          }, 1000);
          return;
        }
        this.selectEmployee(undefined);
      }
    }
  }

  private createEmployeeList(employees: ISimpleCompanyUser[]) {
    const result: IListDataItem[] = employees.map((employee) => {
      let name = employee.FullName;
      if (employee.FirstName && employee.LastName) {
        name =
          this.sortBy === 1
            ? employee.FirstName.trim() + ' ' + employee.LastName.trim()
            : employee.LastName.trim() + ' ' + employee.FirstName.trim();
      }

      if (!employee.IsActive) {
        name = '(' + name + ')';
      }

      return { Id: employee.Id, Text: name };
    });

    if (this.sortBy === 2) {
      result.sort((a, b) => {
        return a.Text.replace('(', '').toLowerCase() < b.Text.replace('(', '').toLowerCase()
          ? -1
          : a.Text.replace('(', '').toLowerCase() > b.Text.replace('(', '').toLowerCase()
          ? 1
          : 0;
      });
    }

    return result;
  }
}
