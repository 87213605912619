export class AdditionalVacationModel {
  Id: number;
  EarningPercentage: number;
  BaseCalculationMethodId: number;
  PensionSharePercentage: number;
  BalanceDefinitionId: number;
  UserEmploymentId?: number;
  EmploymentTemplateId?: number;
  EarningFixedAmount?: number;
  EarningFixedAmountPrincipleId?: number;
  PensionShareFixedAmount?: number;
  DaysConversionPercentage?: number;
  DaysEarnedPerPayslip?: number;
  PayoutPercentage?: number;

  SeniorityRequirementMonths?: number;
  CustomBehaviorId?: number;

  public constructor() {
    this.BalanceDefinitionId = 7;
    this.EarningFixedAmount = null;
    this.EarningFixedAmountPrincipleId = 5;
    this.PensionShareFixedAmount = null;
    this.Id = null;
    this.DaysConversionPercentage = null;
    this.DaysEarnedPerPayslip = null;
    this.PayoutPercentage = null;

    this.SeniorityRequirementMonths = null;
    this.CustomBehaviorId = null;
    this.BaseCalculationMethodId = 2;
  }
}
