import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlComponentBase } from '../../common/control-component-base';
import { ReflectionHelper } from '../../common/reflection-helper';

@Component({
  selector: 'app-check-edit',
  templateUrl: './check-edit.component.html'
})
export class CheckEditComponent extends ControlComponentBase<boolean> implements OnInit {
  @Input() public label: string;
  @Input() public half: boolean;
  @Input() public halfType: string;
  @Input() public dataItemCB: any;
  @Input() public tooltip = '';
  @Input() public disable: boolean;
  @Input() public helpLink: string;
  @Input() public helpTooltip = '';
  @Input() public isOpenLink = true;
  @Input() public customClass: string;
  @Input() public helpIcon = 'Help';
  @Input() public selfService = '';
  @Input() public isLink = false;
  @Input() public openLinkInTitle = '';
  @Input() public isAccountantConfig: boolean;

  public get labelClass(): string {
    return this.customClass + ' ' + (this.isGrid ? 'checkBox-label-center' : '');
  }

  public get isDisabled(): boolean {
    return this.disable ? this.disable : !this.editMode;
  }

  constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
  }

  public AppliesHalfCheckbox: boolean;
  public ngOnInit() {
    if (this.half && this.dataItemCB) {
      if (this.halfType === 'AppliesWeekdays') {
        if (
          !this.dataItemCB.AppliesMondays &&
          !this.dataItemCB.AppliesTuesdays &&
          !this.dataItemCB.AppliesWednesdays &&
          !this.dataItemCB.AppliesThursdays &&
          !this.dataItemCB.AppliesFridays
        ) {
          this.AppliesHalfCheckbox = true;
          this.value = false;
        } else {
          this.AppliesHalfCheckbox =
            this.dataItemCB.AppliesMondays &&
            this.dataItemCB.AppliesTuesdays &&
            this.dataItemCB.AppliesWednesdays &&
            this.dataItemCB.AppliesThursdays &&
            this.dataItemCB.AppliesFridays;

          this.value = this.AppliesHalfCheckbox;
        }
      } else if (this.halfType === 'AppliesSunHolis') {
        if (!this.dataItemCB.AppliesSundays && !this.dataItemCB.AppliesHolidays) {
          this.AppliesHalfCheckbox = true;
          this.value = false;
        } else {
          this.AppliesHalfCheckbox = this.dataItemCB.AppliesSundays && this.dataItemCB.AppliesHolidays;
          this.value = this.AppliesHalfCheckbox;
        }
      }
    }
  }

  protected valid(): boolean {
    return true;
  }

  protected convertValue(value: any): boolean {
    if (value && ReflectionHelper.isString(value)) {
      return value.toLowerCase() === 'true';
    } else {
      return value;
    }
  }

  public onOpenlinkInTitleClick(): void {
    if (this.openLinkInTitle) {
      window.open(this.openLinkInTitle);
    }
  }

  @Output() public halfCheckChange: EventEmitter<any> = new EventEmitter<any>();
  public onClick(event: any): void {
    if (this.half) {
      const isChecked = event.currentTarget.checked;
      this.AppliesHalfCheckbox = true;
      this.value = isChecked;
      this.halfCheckChange.emit({ checked: isChecked, dataItem: this.dataItemCB, type: this.halfType });
    }
  }
}
