import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Global } from 'src/app/common/global';
import {
  IInheritSalaryTypeRequest,
  ISalaryType,
  ISalaryTypeCategoryView,
  SalaryType
} from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { SessionService } from 'src/app/services/session/session.service';
@Component({
  selector: 'app-new-salary-type-dialog',
  templateUrl: './new-salary-type-dialog.component.html'
})
export class NewSalaryTypeDialogComponent implements OnInit {
  @Input() public salaryTypeCategories: ISalaryTypeCategoryView[];

  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      if (value) {
        this.resetForm();
      }

      this.visibleValue = value;
      this.visibleChange.emit(value);
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public add: EventEmitter<ISalaryType> = new EventEmitter<ISalaryType>();

  public salaryTypeName: string;
  public defaultSalaryTypes: ISalaryType[];
  public baseSalaryTypeId: number;
  public categoryId: number;
  public validationWarningVisible = false;
  private createFromScratch: ISalaryType = new SalaryType();
  constructor(private dataService: DataService, public sessionService: SessionService) {}

  public ngOnInit(): void {
    if (Global.SESSION.SignOnToken.LanguageId === 2) {
      this.createFromScratch.Name = '<Create From Scratch>';
    } else {
      this.createFromScratch.Name = '<Opret fra bunden>';
    }
    this.createFromScratch.Id = -1;
    this.dataService.SalaryTypes_GetDefaultSalaryTypes().subscribe((salaryTypes: ISalaryType[]) => {
      this.defaultSalaryTypes = salaryTypes.filter((item: ISalaryType) => {
        return item.IsVisible && item.IsActive;
        ////return (item.IsVisible || (item.IsVisible !== false && item.BaseSalaryType && item.BaseSalaryType.IsVisible))
        ////    && (item.IsActive || (item.IsActive !== false && item.BaseSalaryType && item.BaseSalaryType.IsActive));
      });

      this.defaultSalaryTypes.unshift(this.createFromScratch);
    });
  }

  public onDialogAction(action: string): void {
    if (action === 'Create') {
      if (this.isCreateFormInvalid) {
        this.validationWarningVisible = true;
        return;
      }
      let request: Observable<ISalaryType>;
      if (this.baseSalaryTypeId === -1) {
        const customSalaryType: SalaryType = {
          CategoryId: this.categoryId,
          Name: this.salaryTypeName,
          Id: this.baseSalaryTypeId,
          IsAdvanced: false,
          DimensionDistributionOptionId: 1
        };
        request = this.dataService.SalaryTypes_CreateCustomSalaryType(customSalaryType);
      } else {
        const inheritSalaryTypeRequest: IInheritSalaryTypeRequest = {
          BaseSalaryTypeId: this.baseSalaryTypeId,
          Name: this.salaryTypeName
        };
        request = this.dataService.SalaryTypes_InheritBaseSalaryType(inheritSalaryTypeRequest);
      }

      request.subscribe((salaryType: ISalaryType) => {
        if (Global.SESSION.SignOnToken.LanguageId === 1) {
          salaryType.SalaryTypeTranslations[0].Name = salaryType.Name;
        } else if (Global.SESSION.SignOnToken.LanguageId === 2) {
          salaryType.SalaryTypeTranslations[1].Name = salaryType.Name;
        } else {
          salaryType.SalaryTypeTranslations[2].Name = salaryType.Name;
        }
        this.add.emit(salaryType);
        this.SelectInherit = false;
        this.hiddenBehavior = true;
        this.visible = false;
      });
    }
  }

  private get isCreateFormInvalid(): boolean {
    return !this.salaryTypeName || !this.baseSalaryTypeId || (this.baseSalaryTypeId === -1 && !this.categoryId);
  }

  public onInheritChange(value: number): void {
    this.baseSalaryTypeId = value;
    if (value === -1) {
      this.hiddenBehavior = false;
    } else {
      this.hiddenBehavior = true;
      this.SelectInherit = false;
      this.categoryId = undefined;
    }
  }

  public hiddenBehavior = true;
  public SelectInherit = false;
  public onBehaviorChange(value: number): void {
    this.categoryId = value;
    this.SelectInherit = true;
  }

  public resetForm(): void {
    this.salaryTypeName = '';
    this.onInheritChange(undefined);
    this.onBehaviorChange(undefined);
  }
}
