<app-dialog
  [(visible)]="visible"
  [title]="'TimeEntryTypeConfigurations.TimeEntryTypeDialogTitle' | translate"
  class="dialog-grid"
>
  <strong>
    <u>{{ 'TimeEntryTypeConfigurations.SalaryBehavior' | translate }}</u>
  </strong>
  <br />
  <div>
    <span>{{ timeEntryType?.Id }} - {{ timeEntryType?.Name }}</span>
  </div>
  <div class="mb-15">
    <span>{{ 'TimeEntryTypeConfigurations.SalaryBehaviorDescription' | translate }}</span>
  </div>
  <app-grid
    [(data)]="gridData"
    class="NewTimeEntryTypeGrid"
    [editMode]="!IsReadOnly"
    [addNew]="!IsReadOnly"
    [allowActionNonEditmode]="true"
    [addNewDisable]="sessionService.isGetting"
    addNewText="{{ 'SenioritySupplements.AddNew' | translate }}"
    (removeEvent)="removeHandler($event)"
    (addNewEvent)="addHandler($event)"
    (saveChangesEvent)="saveChanges($event)"
    [(triggerUpdate)]="refreshData"
    [(selectedItem)]="selectedItem"
    #kendoGridComponent
    [filterable]="'menu'"
    [filter]="filter"
    (filterChange)="onFilterChange($event)"
    [(radioValue)]="gridRadioAction"
    (action)="gridAction($event)"
    [(selectedId)]="selectedId"
    [idColumn]="'Id'"
  >
    <app-grid-action [isHidden]="!hasSelectedItem" [action]="'DuplicateAction'" [label]="'Grids.Duplicate' | translate">
    </app-grid-action>
    <app-grid-action [isHidden]="!hasSelectedItem" [action]="'DeleteAction'" [label]="'Grids.Delete' | translate">
    </app-grid-action>

    <app-grid-radio [action]="'SimpleAction'" [label]="'TimeEntryTypeConfigurations.Simple' | translate" [value]="1">
    </app-grid-radio>
    <app-grid-radio
      [action]="'AdvancedAction'"
      [label]="'TimeEntryTypeConfigurations.Advanced' | translate"
      [value]="2"
    ></app-grid-radio>
    <app-grid-radio
      [action]="'CompleteAction'"
      [label]="'TimeEntryTypeConfigurations.Complete' | translate"
      [value]="3"
    ></app-grid-radio>

    <app-grid-column
      [isFilterable]="true"
      field="SalaryTypeId"
      [comboboxDataSource]="salaryTypes"
      type="combobox"
      comboboxValueField="SalaryTypeId"
      comboboxDisplayField="Name"
      [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.SalaryType' | translate }}"
      [width]="160"
    >
    </app-grid-column>
    <app-grid-column
      [isFilterable]="true"
      field="Description"
      type="text"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.Description' | translate }}"
      [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
      [width]="160"
    ></app-grid-column>
    <app-grid-column
      [hideColumn]="isShowAdvancedMode"
      [field]="'LimitToDepartmentId'"
      [type]="'combobox'"
      [comboboxDataSource]="companyDepartmentService.departments | async"
      [comboboxValueField]="'Id'"
      [comboboxDisplayField]="'Name'"
      [editable]="true"
      [title]="'TimeEntryTypeConfigurations.LimitToDepartment' | translate"
      [width]="90"
      [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
    >
    </app-grid-column>
    <app-grid-column
      [hideColumn]="isShowAdvancedMode"
      [isFilterable]="true"
      field="EmploymentTemplateId"
      [comboboxDataSource]="employmentTemplates"
      type="combobox"
      comboboxValueField="Id"
      comboboxDisplayField="Name"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.LimitToTemplate' | translate }}"
      [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
      [width]="120"
      [editableField]="'IsEmploymentTemplateEditable'"
      [allowComboeditNull]="true"
      tabindex="!IsEmploymentTemplateEditable ? '-1'"
    ></app-grid-column>
    <app-grid-column
      [hideColumn]="isShowAdvancedMode"
      [isFilterable]="true"
      field="UserEmploymentId"
      [comboboxDataSource]="employmentViews"
      type="combobox"
      comboboxValueField="UserEmploymentId"
      comboboxDisplayField="FullName"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.FullName' | translate }}"
      [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
      [width]="110"
      [editableField]="'IsUserEmploymentEditable'"
      [allowComboeditNull]="true"
      tabindex="!IsUserEmploymentEditable ? '-1'"
    ></app-grid-column>
    <app-grid-column
      [hideColumn]="isShowSimpleMode"
      [isFilterable]="true"
      field="Amount"
      type="numeric"
      [numbericOption]="NumericOptions"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.FixedAmount' | translate }}"
      [width]="110"
    >
    </app-grid-column>
    <app-grid-column
      [hideColumn]="isShowAdvancedMode"
      [isFilterable]="true"
      field="AmountPerUnit"
      type="numeric"
      [numbericOption]="NumericOptions"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.PerUnit' | translate }}"
      [width]="80"
      [editableField]="'isAllowEditPerUnits'"
      [classField]="'classfeilderUnits'"
    ></app-grid-column>
    <!-- GS-5316 -->
    <app-grid-column
      [hideColumn]="isShowAdvancedMode"
      [isFilterable]="true"
      field="UseDefaultRate"
      type="checkbox"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.Usedefaultrate' | translate }}"
      [width]="90"
      [headerTooltip]="
        'TimeEntryTypeConfigurations.UsedefaultrateTooltip' | translate: { SystemAlias: branding.SystemAlias }
      "
      [tooltipTemplteClass]="'text-left-tooltip'"
    >
    </app-grid-column>
    <app-grid-column
      [hideColumn]="isShowSimpleMode"
      [isFilterable]="true"
      field="AmountPerKilometer"
      type="numeric"
      [numbericOption]="NumericOptions"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.PerKm' | translate }}"
      [width]="90"
    ></app-grid-column>

    <app-grid-column
      [hideColumn]="isShowAdvancedMode"
      [isFilterable]="true"
      field="AppliesAfter"
      type="timepicker"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.AppliesAfter' | translate }}"
      [width]="120"
    >
    </app-grid-column>
    <app-grid-column
      [hideColumn]="isShowAdvancedMode"
      [isFilterable]="true"
      field="AppliesBefore"
      type="timepicker"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.AppliesBefore' | translate }}"
      [width]="120"
    >
    </app-grid-column>

    <!-- checkbox -->
    <app-grid-column
      [hideColumn]="!isShowSimpleMode"
      [isFilterable]="true"
      field="AppliesWeekdays"
      type="checkboxhalf"
      [class]="{ 'align-center': true }"
      title="{{ 'TimeEntryTypeConfigurations.MondayToFriday' | translate }}"
      [editable]="!IsReadOnly"
      [width]="85"
    >
    </app-grid-column>

    <app-grid-column
      [hideColumn]="isShowSimpleMode || isShowAdvancedMode"
      [isFilterable]="true"
      field="AppliesMondays"
      type="checkbox"
      [class]="{ 'align-center': true }"
      title="{{ 'TimeEntryTypeConfigurations.Monday' | translate }}"
      [editable]="!IsReadOnly"
      [width]="85"
    >
    </app-grid-column>
    <app-grid-column
      [hideColumn]="isShowSimpleMode || isShowAdvancedMode"
      [isFilterable]="true"
      field="AppliesTuesdays"
      type="checkbox"
      [class]="{ 'align-center': true }"
      title="{{ 'TimeEntryTypeConfigurations.Tuesday' | translate }}"
      [editable]="!IsReadOnly"
      [width]="85"
    >
    </app-grid-column>
    <app-grid-column
      [hideColumn]="isShowSimpleMode || isShowAdvancedMode"
      [isFilterable]="true"
      field="AppliesWednesdays"
      type="checkbox"
      [class]="{ 'align-center': true }"
      title="{{ 'TimeEntryTypeConfigurations.Wednesday' | translate }}"
      [editable]="!IsReadOnly"
      [width]="85"
    >
    </app-grid-column>
    <app-grid-column
      [hideColumn]="isShowSimpleMode || isShowAdvancedMode"
      [isFilterable]="true"
      field="AppliesThursdays"
      type="checkbox"
      [class]="{ 'align-center': true }"
      title="{{ 'TimeEntryTypeConfigurations.Thursday' | translate }}"
      [editable]="!IsReadOnly"
      [width]="85"
    >
    </app-grid-column>
    <app-grid-column
      [hideColumn]="isShowSimpleMode || isShowAdvancedMode"
      [isFilterable]="true"
      field="AppliesFridays"
      type="checkbox"
      [class]="{ 'align-center': true }"
      title="{{ 'TimeEntryTypeConfigurations.Friday' | translate }}"
      [editable]="!IsReadOnly"
      [width]="85"
    >
    </app-grid-column>

    <app-grid-column
      [hideColumn]="isShowAdvancedMode"
      [isFilterable]="true"
      field="AppliesSaturdays"
      type="checkbox"
      [class]="{ 'align-center': true }"
      title="{{ 'TimeEntryTypeConfigurations.AppliesSaturday' | translate }}"
      [editable]="!IsReadOnly"
      [width]="75"
    >
    </app-grid-column>

    <app-grid-column
      [hideColumn]="isShowAdvancedMode"
      [isFilterable]="true"
      field="AppliesSundays"
      type="checkbox"
      [class]="{ 'align-center': true }"
      title="{{ 'TimeEntryTypeConfigurations.AppliesSundays' | translate }}"
      [editable]="!IsReadOnly"
      [width]="70"
    >
    </app-grid-column>

    <app-grid-column
      [hideColumn]="isShowAdvancedMode"
      [isFilterable]="true"
      field="AppliesHolidays"
      type="checkbox"
      [class]="{ 'align-center': true }"
      title="{{ 'TimeEntryTypeConfigurations.Holidays' | translate }}"
      [editable]="!IsReadOnly"
      [width]="80"
    >
    </app-grid-column>

    <app-grid-column
      [hideColumn]="isShowSimpleMode"
      [isFilterable]="true"
      field="PensionPercentage"
      type="percentage"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.PensionEarning' | translate }}"
      [width]="120"
    >
    </app-grid-column>
    <app-grid-column
      [hideColumn]="isShowSimpleMode"
      [isFilterable]="true"
      field="LegacyFlexEarningPercentage"
      type="percentage"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.PublicHolidayCompensationEarning' | translate }}"
      [width]="150"
    >
    </app-grid-column>
    <app-grid-column
      [hideColumn]="isShowSimpleMode"
      [isFilterable]="true"
      field="NewFlexEarningPercentage"
      type="percentage"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.MultiPurposeBalanceEarning' | translate }}"
      [width]="115"
    >
    </app-grid-column>

    <app-grid-column
      [hideColumn]="isShowSimpleMode"
      [isFilterable]="true"
      field="ConversionFactor"
      type="numeric"
      title="{{ 'TimeEntryTypeConfigurations.ConversionFactor' | translate }}"
      [editable]="true"
      [width]="130"
      [numbericOption]="ConversionFactorNumericOptions"
    ></app-grid-column>

    <!-- // ConversionFactor Rate -->
    <app-grid-column
      [hideColumn]="isShowSimpleMode"
      [isFilterable]="true"
      field="ConversionFactorAffectsRateNumber"
      [comboboxDataSource]="onversionFactorAffectsDataSource"
      type="combobox"
      comboboxValueField="value"
      comboboxDisplayField="Name"
      [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
      [editable]="true"
      title="{{ 'TimeEntryTypeConfigurations.ConversionFactorAffectsRate' | translate }}"
      [width]="70"
    >
    </app-grid-column>
    <!-- // ConversionFactor Rate -->

    <app-grid-column
      [hideColumn]="isShowSimpleMode"
      [isFilterable]="true"
      field="TargetUnitTypeId"
      [comboboxDataSource]="unitTypes"
      type="combobox"
      comboboxValueField="Id"
      comboboxDisplayField="Name"
      [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
      [editable]="true"
      title="{{ 'CompanyTimeEntryTypes.UnitType' | translate }}"
      [width]="90"
    ></app-grid-column>

    <app-grid-column
      [hideColumn]="isShowSimpleMode || !isShowDimensionValue || !Dimension1Name || !isEnableDimensionPreference"
      field="LimitToDimension1ValueId"
      [comboboxDataSource]="dimensionValues[0]"
      type="combobox"
      comboboxValueField="Id"
      comboboxDisplayField="Value"
      [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
      [editable]="true"
      title="{{ Dimension1Name }}"
      [width]="90"
    >
    </app-grid-column>
    <app-grid-column
      [hideColumn]="isShowSimpleMode || !isShowDimensionValue || !Dimension2Name || !isEnableDimensionPreference"
      field="LimitToDimension2ValueId"
      [comboboxDataSource]="dimensionValues[1]"
      type="combobox"
      comboboxValueField="Id"
      comboboxDisplayField="Value"
      [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
      [editable]="true"
      title="{{ Dimension2Name }}"
      [width]="90"
    >
    </app-grid-column>
    <app-grid-column
      [hideColumn]="isShowSimpleMode || !isShowDimensionValue || !Dimension3Name || !isEnableDimensionPreference"
      field="LimitToDimension3ValueId"
      [comboboxDataSource]="dimensionValues[2]"
      type="combobox"
      comboboxValueField="Id"
      comboboxDisplayField="Value"
      [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
      [editable]="true"
      title="{{ Dimension3Name }}"
      [width]="90"
    >
    </app-grid-column>
  </app-grid>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>

<app-dialog
  [(visible)]="isShowDeleteRecord"
  [width]="'500'"
  (action)="onDeleteRecordAction($event)"
  [leadingText]="'Grids.ConfirmDelete' | translate"
>
  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action type="Delete" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>
