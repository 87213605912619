<div
  class="Island-configuration"
  [ngClass]="companyAdvancedTypesService.editMode ? 'is-editing' : ''"
  (dblclick)="onDoubleClick()"
>
  <div class="ConfigNav Navigation-wrapper Navigation Navigation-inner" [ngClass]="{ 'is-active': menuOpen }">
    <div class="Navigation-expandWrapper">
      <button class="Navigation-link Navigation-expand" (click)="onToggleNavigation()">
        <div>
          <div *ngIf="salaryTypeVisible">
            {{ 'CompanyTimeEntryTypes.SalaryTypeSingle' | translate }}
          </div>
          <div *ngIf="salaryTypeOverviewVisible">
            {{ 'CompanyTimeEntryTypes.SalaryTypeOverview' | translate }}
          </div>
          <div *ngIf="timeEntryTypeVisible">
            {{ 'CompanyTimeEntryTypes.TimeEntryTypeSingle' | translate }}
          </div>
          <div *ngIf="timeEntryTypeOverviewVisible">
            {{ 'CompanyTimeEntryTypes.TimeEntryTypeOverview' | translate }}
          </div>
          <app-icon [icon]="'ChevronDown'" [size]="'xxsmall'"></app-icon>
        </div>
      </button>
    </div>
    <ul class="Navigation-list">
      <li class="Navigation-item">
        <app-button
          id="CompanyConfigurationSalaryTypesSingleTabButton"
          class="Navigation-link MainTabButton"
          (clickEvent)="navigateTo('tabs.company.configuration.advancedtypes.salarytypessingle', 1)"
          [ngClass]="{ 'is-active': salaryTypeVisible }"
          [label]="salaryType.label | translate"
        >
        </app-button>
      </li>
      <li class="Navigation-item">
        <app-button
          id="CompanyConfigurationSalaryTypesTabButton"
          class="Navigation-link MainTabButton"
          (clickEvent)="navigateTo('tabs.company.configuration.advancedtypes.advancedsalarytypes', 3)"
          [ngClass]="{ 'is-active': salaryTypeOverviewVisible }"
          [label]="salaryTypeOverview.label | translate"
        >
        </app-button>
      </li>
      <li class="Navigation-item">
        <app-button
          id="CompanyConfigurationTimeEntryTypesSingleTabButton"
          class="Navigation-link MainTabButton"
          (clickEvent)="navigateTo('tabs.company.configuration.advancedtypes.timeentrytypessingle', 2)"
          [ngClass]="{ 'is-active': timeEntryTypeVisible }"
          [label]="timeEntryType.label | translate"
        >
        </app-button>
      </li>
      <li class="Navigation-item">
        <app-button
          id="CompanyConfigurationTimeEntryTypesTabButton"
          class="Navigation-link MainTabButton"
          (clickEvent)="navigateTo('tabs.company.configuration.advancedtypes.timeentrytypes', 4)"
          [ngClass]="{ 'is-active': timeEntryTypeOverviewVisible }"
          [label]="timeEntryTypeOverview.label | translate"
        >
        </app-button>
      </li>
    </ul>
  </div>
  <div class="company-advanced-types" *ngIf="selectedTypeValue === 'Salary' || selectedTypeValue === 'TimeEntry'">
    <form onsubmit="return false;" novalidate>
      <div class="Cards Cards--flex">
        <div class="Card Card--small">
          <div class="Form--stacked">
            <app-advanced-types-list
              [types]="gridData"
              [type]="selectedTypeValue"
              [(selectedId)]="companyAdvancedTypesService.selectedId"
              (showTimeEntryTypeConfigurationDialog)="timeEntryTypeConfigurationDialogVisible = true"
              (showTranslationDialog)="onShowTranslationDialog()"
              [refreshList]="companyAdvancedTypesService.refreshTypesList"
              [(isEditMode)]="companyAdvancedTypesService.editMode"
              [inheritFrom]="inheritFrom"
            >
            </app-advanced-types-list>
          </div>
        </div>

        <div class="Card Card--large">
          <div class="Card-inner Card-inner--top">
            <app-module-check [moduleId]="4" [(hasModule)]="hasSalaryTypeModule">
              <app-salary-types-single
                #salaryTypesSingleComponent
                *ngIf="selectedTypeValue === 'Salary'"
                (editClickAction)="onEditClick($event)"
                (addClickAction)="onAddClick()"
                (showTranslationDialog)="onShowTranslationDialog()"
              >
              </app-salary-types-single>
            </app-module-check>
            <app-module-check [moduleId]="8" [(hasModule)]="hasTimeEntryModule">
              <app-time-entry-types-single
                #timeEntryTypesSingleComponent
                *ngIf="selectedTypeValue === 'TimeEntry'"
                [salaryTypes]="salaryTypeViews"
                (editClickAction)="onEditClick($event)"
                (addClickAction)="onAddClick()"
                (reloadListData)="onTranslationConfigurationVisibleChange($event)"
              >
              </app-time-entry-types-single>
            </app-module-check>
          </div>
        </div>
      </div>
    </form>

    <div class="Actions" *ngIf="!isMobile && !companyAdvancedTypesService.editMode">
      <app-menu-button
        *ngIf="selectedTypeValue === 'Salary' && hasModuleSalary"
        [menuCondition]="!sessionService.role.IsReadOnly"
        (menuClick)="onAddClick()"
        [menuIcon]="'Add'"
        [menuLabel]="'Company.New'"
        [menuTooltip]="'CompanySalaryBatches.AddSalaryTypeText'"
        [actionButtonMenu]="true"
        [menuButtonClass]="'Action-button Action-buttonAdd'"
        [menuDisabled]="!hasSalaryTypeModule"
        [menuButtonId]="'ActionButtonAdd'"
      >
      </app-menu-button>
      <app-menu-button
        *ngIf="selectedTypeValue === 'TimeEntry' && hasModuleTimeEntry"
        [menuCondition]="!sessionService.role.IsReadOnly"
        (menuClick)="newCustomTimeEntryDialogVisible = true"
        [menuIcon]="'Add'"
        [menuLabel]="'Company.New'"
        [menuTooltip]="'CompanyTimeEntryTypes.AddTimeEntryTypeText'"
        [actionButtonMenu]="true"
        [menuButtonClass]="'Action-button Action-buttonAdd'"
        [menuButtonId]="'ActionButtonAdd'"
      >
      </app-menu-button>
      <app-menu-button
        [menuIcon]="'Help'"
        [menuLabel]="'Help.Title'"
        [menuTooltip]="'Help.Title'"
        [multiMenuItems]="true"
      >
        <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
        <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
      </app-menu-button>
      <app-menu-button
        *ngIf="reportDialogService.isVisibleReportIcon"
        [menuCondition]="true"
        (menuClick)="reportDialogService.onShowReportsEventClick()"
        [menuIcon]="'File'"
        [menuLabel]="'Employee.Reports'"
        [menuTooltip]="'Employee.Reports'"
        [actionButtonMenu]="true"
        [menuButtonClass]="'Action-button Action-buttonReports'"
        [menuButtonId]="'ActionButtonReports'"
      >
      </app-menu-button>

      <app-menu-button
        [editButtonMenu]="true"
        (menuChangeClick)="onEditClick($event)"
        [menuCondition]="sessionService.role.IsFullAdmin || sessionService.role.IsSalaryAdmin"
        [editMode]="companyAdvancedTypesService.editMode"
        *ngIf="
          (hasModuleSalary && selectedTypeValue === 'Salary') ||
          (hasModuleTimeEntry && selectedTypeValue === 'TimeEntry')
        "
      >
      </app-menu-button>
    </div>

    <div class="Actions" *ngIf="isMobile && !companyAdvancedTypesService.editMode">
      <app-menu-button
        [menuIcon]="'ThreeDots'"
        [menuLabel]="'More.Title'"
        [menuTooltip]="'More.Title'"
        [multiMenuItems]="true"
      >
        <app-menu-button-item *ngIf="isEditable" (menuItemClick)="onAddClick()" [field]="'SalaryTypeNew'">
        </app-menu-button-item>
        <app-menu-button-item *ngIf="isEditable" (menuItemClick)="onAddClick()" [field]="'TimeEntryNew'">
        </app-menu-button-item>
        <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
        <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
      </app-menu-button>

      <app-menu-button
        [editButtonMenu]="true"
        (menuChangeClick)="onEditClick($event)"
        [menuCondition]="sessionService.role.IsFullAdmin || sessionService.role.IsSalaryAdmin"
        [editMode]="companyAdvancedTypesService.editMode"
      >
      </app-menu-button>
    </div>

    <div class="EditingInfo Actions" *ngIf="companyAdvancedTypesService.editMode">
      <app-button
        [buttonClass]="'Discard'"
        (clickEvent)="onConfirmAndDiscardChanges()"
        [label]="'Employee.DiscardButton' | translate"
        *ngIf="companyAdvancedTypesService.editMode"
      ></app-button>
      <app-menu-button
        [editButtonMenu]="true"
        [editMode]="true"
        (menuChangeClick)="onEditClick($event)"
        [menuCondition]="true"
        class="menu-button--editing"
      >
      </app-menu-button>
    </div>
  </div>

  <app-salary-types *ngIf="selectedTypeValue === 'SalaryOverview' && companyAdvancedSalaryTypesStateActive">
  </app-salary-types>
  <app-time-entry-types *ngIf="selectedTypeValue === 'TimeEntryOverview' && companyTimeEntryTypesStateActive">
  </app-time-entry-types>
</div>

<app-loading-tips [visible]="isWattingLoading"></app-loading-tips>

<app-new-salary-type-dialog
  [(visible)]="newSalaryTypeDialogVisible"
  [salaryTypeCategories]="SalaryTypeCategories"
  (add)="onAddSalaryType($event)"
>
</app-new-salary-type-dialog>

<app-new-time-entry-type-dialog
  [(visible)]="newCustomTimeEntryDialogVisible"
  [defaultTimeEntryTypes]="TimeEntryTypes"
  (add)="addNewTimeEntryType($event)"
>
</app-new-time-entry-type-dialog>

<app-dialog
  (action)="onDiscardChangesDialogAction($event)"
  [(visible)]="discardChangesDialogVisible"
  [leadingText]="'Employee.DiscardMessage' | translate"
>
  <app-dialog-action type="CancelDiscard"></app-dialog-action>
  <app-dialog-action type="ConfirmDiscard"></app-dialog-action>
</app-dialog>

<app-time-entry-type-translation-dialog
  [timeEntryType]="companyAdvancedTypesService.selectedType"
  [(visible)]="translationDialogTEVisible"
  (translateComplete)="onTranslationConfigurationVisibleChange(true)"
>
</app-time-entry-type-translation-dialog>

<app-salary-type-translation-dialog
  [salaryType]="companyAdvancedTypesService.selectedType"
  [(visible)]="translationDialogSTVisible"
  (translateComplete)="onTranslationConfigurationVisibleChange(true)"
>
</app-salary-type-translation-dialog>
