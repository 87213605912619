<app-dialog
  [overlayClose]="true"
  [(visible)]="visible"
  [title]="'Company.PayrollData' | translate"
  id="salaryTemplateDialog"
  class="main-dialog"
>
  <app-module-check [moduleId]="12">
    <!--[customDeleteRow]="customDeleteRow"-->
    <app-grid
      [(data)]="gridData"
      [editMode]="editMode"
      [reFocusAfterChangeEdit]="false"
      class="OvertimeSupplementGrid"
      [addNew]="editMode"
      [addNewDisable]="sessionService.isGetting"
      [deleteRow]="editMode"
      (addNewEvent)="onGridAdd($event)"
      (removeEvent)="onGridRemove($event)"
      (saveChangesEvent)="onGridSave($event)"
      [addNewText]="'Grids.AddNewRecord' | translate"
      [idColumn]="'GridId'"
      [(selectedItem)]="selectedRecord"
      (selectedItemChange)="onGridSelectionChange($event)"
      [(triggerUpdate)]="refreshData"
    >
      <app-grid-column
        field="SalaryTypeId"
        [comboboxDataSource]="salaryTypes"
        [type]="'combobox'"
        [comboboxValueField]="'SalaryTypeId'"
        [comboboxDisplayField]="'Name'"
        [editable]="true"
        [title]="'EmploymentPayrollData.SalaryType' | translate"
        [width]="110"
      ></app-grid-column>

      <app-grid-column
        field="Description"
        [type]="'text'"
        [editable]="true"
        [title]="'AccountModules.Description' | translate"
        [width]="170"
      ></app-grid-column>

      <app-grid-column
        [field]="'Units'"
        [type]="'numeric'"
        [title]="'EmploymentPayrollData.Units' | translate"
        [width]="90"
        [numbericOption]="numericOptions"
        [classField]="'UnitsClass'"
        [editableField]="'UnitsEnabled'"
      >
      </app-grid-column>

      <app-grid-column
        [field]="'UnitTypeId'"
        [comboboxDataSource]="unitTypes"
        [type]="'combobox'"
        [comboboxValueField]="'Id'"
        [comboboxDisplayField]="'Name'"
        [editable]="true"
        [title]="''"
        [width]="110"
        [classField]="'UnitTypeClass'"
        [editableField]="'AllowEditUnitType'"
      ></app-grid-column>

      <app-grid-column
        [field]="'AmountPerUnit'"
        [type]="'numeric'"
        [title]="'EmploymentPayrollData.PerUnit' | translate"
        [width]="90"
        [numbericOption]="numericOptions"
        [classField]="'AmountPerUnitClass'"
        [editableField]="'AmountPerUnitEnabled'"
      ></app-grid-column>

      <app-grid-column
        [field]="'Amount'"
        [type]="'numeric'"
        [editable]="true"
        [title]="'EmploymentPayrollData.Amount' | translate"
        [width]="90"
        [numbericOption]="numericOptions"
      >
      </app-grid-column>

      <app-grid-column
        [field]="'PersistenceTypeId'"
        [comboboxDataSource]="persistanceTypes"
        [type]="'combobox'"
        [comboboxValueField]="'Id'"
        [comboboxDisplayField]="'Name'"
        [editable]="true"
        [title]="'EmploymentPayrollData.AfterNextBatch' | translate"
        [width]="140"
      ></app-grid-column>
    </app-grid>
  </app-module-check>

  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>

<app-dialog
  [(visible)]="confirmationDialogVisible"
  (action)="onConfirmationDialogAction($event)"
  [leadingText]="'Grids.ConfirmDelete' | translate"
>
  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action type="Delete"></app-dialog-action>
</app-dialog>
