<app-dialog (action)="onDialogAction($event)" [overlayClose]="false" [(visible)]="visible" [minWidth]="500">
  <div class="Modal-content">
    <app-text-edit [label]="'CompanyHiringStatus.TemplateName' | translate" [(value)]="templateName" [editMode]="true">
    </app-text-edit>
    <app-combobox-edit
      [label]="'CompanySalaryBatches.Cycle' | translate"
      [editMode]="true"
      [comboboxDataSource]="salaryCycles"
      [textField]="'Name'"
      [idField]="'Id'"
      [(value)]="salaryCycleId"
      (valueChange)="onSalaryCycleChanged()"
    ></app-combobox-edit>
    <app-combobox-edit
      [label]="'CompanyHiringStatus.BaseOnEmployee' | translate"
      [editMode]="true"
      [comboboxDataSource]="userEmployements"
      [textField]="'FullName'"
      [idField]="'UserEmploymentId'"
      [(value)]="userEmploymentId"
      [nullValueLabel]="'DropdownList.None' | translate"
    ></app-combobox-edit>
  </div>

  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Create" [disabled]="sessionService.isSubmitting" [close]="false"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="warningDialogVisible"
  [leadingText]="'Please enter template name'"
  [minWidth]="500"
>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>
