import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../common/form-component-base';
import { SessionService } from '../../services/session/session.service';
var ShareAmountComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ShareAmountComponent, _super);
    function ShareAmountComponent(sessionService, translateService) {
        var _this = _super.call(this) || this;
        _this.sessionService = sessionService;
        _this.translateService = translateService;
        _this.firstValueChange = new EventEmitter();
        _this.PensionIsFixedAmountPerWorkDayDataSource = [];
        _this.secondValueChange = new EventEmitter();
        _this.thirdValueChange = new EventEmitter();
        _this.numericTextBoxOption = { format: 'n2', decimals: 2, min: null, step: 1, spinners: false };
        _this.ngUnsubscribe = new Subject();
        _this.childIsDirty = Array(2).fill(false);
        _this.translationServiceTerms = [
            'EmployeeEmployment.PensionIsFixedAmountPerWorkDayFalse',
            'EmployeeEmployment.PensionIsFixedAmountPerWorkDayTrue'
        ];
        return _this;
    }
    Object.defineProperty(ShareAmountComponent.prototype, "firstValue", {
        get: function () {
            return this.currentfirstValue;
        },
        set: function (value) {
            if (this.currentfirstValue !== value) {
                this.currentfirstValue = value;
                this.firstValueChange.emit(this.currentfirstValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    ShareAmountComponent.prototype.setTranslationTerms = function (translations) {
        var PensionIsFixedAmountPerWorkDayFalse = translations['EmployeeEmployment.PensionIsFixedAmountPerWorkDayFalse'];
        var PensionIsFixedAmountPerWorkDayTrue = translations['EmployeeEmployment.PensionIsFixedAmountPerWorkDayTrue'];
        if (this.PensionIsFixedAmountPerWorkDayDataSource.length < 2) {
            this.PensionIsFixedAmountPerWorkDayDataSource.push({ Name: PensionIsFixedAmountPerWorkDayFalse, value: 0 });
            this.PensionIsFixedAmountPerWorkDayDataSource.push({ Name: PensionIsFixedAmountPerWorkDayTrue, value: 1 });
        }
    };
    Object.defineProperty(ShareAmountComponent.prototype, "secondValue", {
        get: function () {
            return this.currentsecondValue;
        },
        set: function (value) {
            if (this.currentsecondValue !== value) {
                this.currentsecondValue = value;
                this.secondValueChange.emit(this.currentsecondValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShareAmountComponent.prototype, "thirdValue", {
        get: function () {
            return this.currentthirdValue;
        },
        set: function (value) {
            if (this.currentthirdValue !== value) {
                this.currentthirdValue = value;
                this.currentcomboboxthirdValue = value ? 1 : 0;
                this.thirdValueChange.emit(this.currentthirdValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShareAmountComponent.prototype, "comboboxthirdValue", {
        get: function () {
            return this.currentcomboboxthirdValue;
        },
        set: function (value) {
            if (value !== this.currentcomboboxthirdValue) {
                this.currentthirdValue = value === 1 ? true : false;
                this.currentcomboboxthirdValue = value;
                this.thirdValueChange.emit(this.currentthirdValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    ShareAmountComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    ShareAmountComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            _this.translateService.get(_this.translationServiceTerms).subscribe(function (translations) {
                _this.setTranslationTerms(translations);
            });
        });
    };
    return ShareAmountComponent;
}(FormComponentBase));
export { ShareAmountComponent };
