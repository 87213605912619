<div
  *ngIf="!hasMultiCompanyModule"
  [hidden]="!visible"
  class="is-active Dropdown Dropdown--top Settings-companyDropdown"
>
  <app-combobox-edit
    (valueChange)="companyChange.emit($event)"
    [editMode]="true"
    [disable]="sessionService.isGetting || sessionService.isSubmitting"
    [idField]="'Id'"
    [textField]="'DisplayName'"
    [comboboxDataSource]="comboBoxData"
    [filterFields]="['DisplayName', 'VatRegistrationNumber', 'SecondaryVatRegistrationNumber']"
    [isBigdataSource]="true"
    [isFocus]="!hasMultiCompanyModule && visible"
    [(value)]="selectedCompanyId"
    (blur)="visible = false"
  >
  </app-combobox-edit>
</div>

<app-dialog
  *ngIf="hasMultiCompanyModule"
  (keyup)="onDialogEnter($event)"
  [(visible)]="visible"
  [width]="800"
  [title]="'CompanySelector.SelectCompany' | translate"
>
  <div class="Form--horizontal">
    <div class="FormElement">
      <span class="FormElement-label">{{ 'CompanySelector.FilterPlaceHolder' | translate }}</span>
      <div class="FormElement-control">
        <input
          #companyFilter
          kendoTextBox
          class="FormElement-input"
          [(ngModel)]="filter"
          (keyup)="onFilterKeyUp($event)"
        />
      </div>
    </div>
  </div>

  <kendo-grid
    class="company-selector-grid GridSelect"
    [data]="gridView"
    [pageSize]="pageSize"
    [skip]="skip"
    [navigable]="true"
    (pageChange)="pageChange($event)"
    (keyup)="onGridKeyUp($event)"
    (cellClick)="onCellClick($event)"
    [pageable]="pageable"
    [selectable]="true"
    [selectedKeys]="selectedRows"
    [kendoGridSelectBy]="'Id'"
  >
    <kendo-grid-messages
      noRecords=""
      pagerOf="{{ 'Kendo.pagerOf' | translate }}"
      pagerItems="{{ 'Kendo.pagerItems' | translate }}"
      [filterIsTrue]="'Filter.IsTrue' | translate"
      [filterIsFalse]="'Filter.IsFalse' | translate"
      [filterClearButton]="'Filter.Clear' | translate"
      [filterFilterButton]="'Filter.Filter' | translate"
    ></kendo-grid-messages>
    <kendo-pager-info #pageInfo></kendo-pager-info>
    <kendo-grid-column [title]="'CompanySelector.Name' | translate" [field]="'DisplayName'" min-width="150">
    </kendo-grid-column>
    <kendo-grid-column [title]="'CompanySelector.RegNo' | translate" [field]="'VatRegistrationNumber'" [width]="80">
    </kendo-grid-column>
    <kendo-grid-column [title]="'Accountant.Role' | translate" [field]="'RoleName'" [width]="100"></kendo-grid-column>
  </kendo-grid>
</app-dialog>
