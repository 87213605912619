import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  enableProdMode,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { DatePickerComponent as kendoDatePicker } from '@progress/kendo-angular-dateinputs';
import { DxDateBoxComponent } from 'devextreme-angular';
import {
  DxCalendarModule,
  DxCheckBoxModule,
  DxDateBoxModule,
  DxSelectBoxModule,
  DxTemplateModule
} from 'devextreme-angular';
import { ControlComponentBase } from '../../common/control-component-base';
import { ReflectionHelper } from '../../common/reflection-helper';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-dev-express-calendar',
  templateUrl: './dev-express-calendar.component.html'
})
export class CalendarPickerComponent extends ControlComponentBase<Date> implements OnInit, AfterViewInit {
  @Input() public e2e: string;

  constructor(elementRef: ElementRef, public sessionService: SessionService, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
    this.setTimeout = false;
  }

  public ngOnInit(): void {}

  public ngAfterViewInit(): void {}
}
