/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@progress/kendo-angular-tooltip";
import * as i3 from "@progress/kendo-angular-popup";
import * as i4 from "../numeric-edit/numeric-edit.component.ngfactory";
import * as i5 from "../numeric-edit/numeric-edit.component";
import * as i6 from "../../services/session/session.service";
import * as i7 from "../module-check/module-check.component.ngfactory";
import * as i8 from "../module-check/module-check.component";
import * as i9 from "../../services/api/static-data.service";
import * as i10 from "../../services/api/data.service";
import * as i11 from "../../services/authentication/authentication.service";
import * as i12 from "../pipes/price-format.pipe";
import * as i13 from "../combobox-edit/combobox-edit.component.ngfactory";
import * as i14 from "../combobox-edit/combobox-edit.component";
import * as i15 from "./share-amount.component";
import * as i16 from "@ngx-translate/core";
var styles_ShareAmountComponent = [];
var RenderType_ShareAmountComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ShareAmountComponent, data: {} });
export { RenderType_ShareAmountComponent as RenderType_ShareAmountComponent };
export function View_ShareAmountComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 23, "div", [["class", "FormElement has-fixedAddon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "span", [["class", "FormElement-label"], ["kendoTooltip", ""]], [[8, "title", 0]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(4, { textWithTooltip: 0 }), i0.ɵdid(5, 9125888, null, 0, i2.TooltipDirective, [i0.ElementRef, i0.NgZone, i0.Renderer2, i3.PopupService, [2, i2.TooltipSettings], [2, i2.TOOLTIP_SETTINGS]], null, null), (_l()(), i0.ɵted(6, null, ["", ":"])), (_l()(), i0.ɵeld(7, 0, null, null, 16, "div", [["class", "FormElement-control alignItems Pension-field-area"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "app-numeric-edit", [], null, [[null, "isDirtyChange"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isDirtyChange" === en)) {
        var pd_0 = ((_co.childIsDirty[0] = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_co.firstValue = $event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = (_co.onChange() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_NumericEditComponent_0, i4.RenderType_NumericEditComponent)), i0.ɵdid(10, 49152, null, 0, i5.NumericEditComponent, [i0.ElementRef, i6.SessionService, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], isDirty: [2, "isDirty"], option: [3, "option"], disable: [4, "disable"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange" }), (_l()(), i0.ɵeld(11, 0, null, null, 1, "span", [["class", "FormElement-addon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0"])), (_l()(), i0.ɵeld(13, 0, null, null, 1, "span", [["class", "FormElement-addon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["/"])), (_l()(), i0.ɵeld(15, 0, null, null, 1, "app-numeric-edit", [], null, [[null, "isDirtyChange"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isDirtyChange" === en)) {
        var pd_0 = ((_co.childIsDirty[1] = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_co.secondValue = $event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = (_co.onChange() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_NumericEditComponent_0, i4.RenderType_NumericEditComponent)), i0.ɵdid(16, 49152, null, 0, i5.NumericEditComponent, [i0.ElementRef, i6.SessionService, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], isDirty: [2, "isDirty"], option: [3, "option"], disable: [4, "disable"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange" }), (_l()(), i0.ɵeld(17, 0, null, null, 1, "span", [["class", "FormElement-addon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0"])), (_l()(), i0.ɵeld(19, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 3, "app-module-check", [], null, null, null, i7.View_ModuleCheckComponent_0, i7.RenderType_ModuleCheckComponent)), i0.ɵdid(21, 245760, null, 0, i8.ModuleCheckComponent, [i6.SessionService, i9.StaticDataService, i10.DataService, i11.AuthenticationService, i12.PriceFormatPipe], { moduleId: [0, "moduleId"] }, null), (_l()(), i0.ɵeld(22, 0, null, 0, 1, "app-combobox-edit", [], null, [[null, "isDirtyChange"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isDirtyChange" === en)) {
        var pd_0 = ((_co.childIsDirty[2] = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_co.comboboxthirdValue = $event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = (_co.onChange() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i13.View_ComboboxEditComponent_0, i13.RenderType_ComboboxEditComponent)), i0.ɵdid(23, 4440064, null, 0, i14.ComboboxEditComponent, [i6.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], isDirty: [2, "isDirty"], idField: [3, "idField"], textField: [4, "textField"], comboboxDataSource: [5, "comboboxDataSource"], disable: [6, "disable"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "FormElement-label"; var currVal_2 = _ck(_v, 4, 0, _co.tooltip); _ck(_v, 3, 0, currVal_1, currVal_2); _ck(_v, 5, 0); var currVal_4 = _co.firstValue; var currVal_5 = _co.editMode; var currVal_6 = _co.childIsDirty[0]; var currVal_7 = _co.numericTextBoxOption; var currVal_8 = _co.disable; _ck(_v, 10, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = _co.secondValue; var currVal_10 = _co.editMode; var currVal_11 = _co.childIsDirty[1]; var currVal_12 = _co.numericTextBoxOption; var currVal_13 = _co.disable; _ck(_v, 16, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = 8; _ck(_v, 21, 0, currVal_14); var currVal_15 = _co.comboboxthirdValue; var currVal_16 = _co.editMode; var currVal_17 = _co.childIsDirty[2]; var currVal_18 = "value"; var currVal_19 = "Name"; var currVal_20 = _co.PensionIsFixedAmountPerWorkDayDataSource; var currVal_21 = _co.disable; _ck(_v, 23, 0, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20, currVal_21); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltip; _ck(_v, 1, 0, currVal_0); var currVal_3 = _co.label; _ck(_v, 6, 0, currVal_3); }); }
export function View_ShareAmountComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-share-amount", [], null, null, null, View_ShareAmountComponent_0, RenderType_ShareAmountComponent)), i0.ɵdid(1, 245760, null, 0, i15.ShareAmountComponent, [i6.SessionService, i16.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShareAmountComponentNgFactory = i0.ɵccf("app-share-amount", i15.ShareAmountComponent, View_ShareAmountComponent_Host_0, { editMode: "editMode", isDirty: "isDirty", isValid: "isValid", disable: "disable", label: "label", tooltip: "tooltip", firstValue: "firstValue", secondValue: "secondValue", thirdValue: "thirdValue" }, { editModeChange: "editModeChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange", valueChange: "valueChange", firstValueChange: "firstValueChange", secondValueChange: "secondValueChange", thirdValueChange: "thirdValueChange" }, []);
export { ShareAmountComponentNgFactory as ShareAmountComponentNgFactory };
