import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../common/form-component-base';
import { StaticDataService } from '../../services/api/static-data.service';
import { EmployeeService } from '../../services/employee.service';
import { SessionService } from '../../services/session/session.service';
import { MultiPurposeBalanceCompensationModel } from './multi-purpose-balance-compensation-model';
var MultiPurposeBalanceCompensationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MultiPurposeBalanceCompensationComponent, _super);
    function MultiPurposeBalanceCompensationComponent(staticDataService, employeeService, sessionService) {
        var _this = _super.call(this) || this;
        _this.staticDataService = staticDataService;
        _this.employeeService = employeeService;
        _this.sessionService = sessionService;
        _this.NameOfEmplomentIdfeild = 'UserEmploymentId';
        _this.allowEdit = false;
        _this.currentmodel = [];
        _this.SectionData = new MultiPurposeBalanceCompensationModel();
        _this.AllowEditSection = false;
        _this.modelChange = new EventEmitter();
        _this.parentChangeEditMode = new EventEmitter();
        _this.NumbericTextBoxFFHoursEarnedPerYearOptions = {
            format: 'n2',
            decimals: 2,
            step: 1,
            spinners: false
        };
        _this.childIsValid = Array(6).fill(true);
        _this.childIsDirty = Array(6).fill(false);
        return _this;
    }
    Object.defineProperty(MultiPurposeBalanceCompensationComponent.prototype, "EmploymentId", {
        get: function () {
            return this.employmentId;
        },
        set: function (value) {
            if (value && value !== this.employmentId) {
                this.employmentId = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultiPurposeBalanceCompensationComponent.prototype, "model", {
        get: function () {
            return this.currentmodel;
        },
        set: function (value) {
            this.currentmodel = [];
            this.AllowEditSection = false;
            if (value && value.length > 0) {
                this.SectionData = value.find(function (item) { return item.BalanceDefinitionId === 3; });
                if (this.SectionData) {
                    this.createSafeModel(this.SectionData);
                    this.currentmodel = value;
                }
                else {
                    this.SectionData = new MultiPurposeBalanceCompensationModel();
                }
            }
            else {
                this.SectionData = new MultiPurposeBalanceCompensationModel();
            }
            this.SectionData[this.NameOfEmplomentIdfeild] = this.employmentId;
            this.modelChange.emit(this.currentmodel);
            this.childIsValid = Array(6).fill(true);
            this.childIsDirty = Array(6).fill(false);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultiPurposeBalanceCompensationComponent.prototype, "defaulHeader", {
        get: function () {
            return 'EmployeeEmployment.MultiPurposeBalance';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultiPurposeBalanceCompensationComponent.prototype, "SectionName", {
        get: function () {
            var preferenceModel = this.sessionService.getCompanyPreference('Company.NewFlexName');
            return preferenceModel ? preferenceModel.Value : this.defaulHeader;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultiPurposeBalanceCompensationComponent.prototype, "isEmptymodel", {
        get: function () {
            if (!this.model ||
                (this.model &&
                    (this.model.length === 0 ||
                        !this.model.find(function (item) { return item.BalanceDefinitionId === 3; })))) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultiPurposeBalanceCompensationComponent.prototype, "isEnableEditSection", {
        get: function () {
            if (this.editMode) {
                if (!this.isEmptymodel) {
                    return true;
                }
                else if (this.AllowEditSection) {
                    return true;
                }
                else {
                    return false;
                }
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    MultiPurposeBalanceCompensationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.editModeChange.pipe().subscribe(function (valueChange) {
            if (!valueChange) {
                _this.AllowEditSection = false;
            }
        });
        this.staticDataService.PensionBaseCalculationMethod.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (baseCalculationMethods) {
            _this.flexData = baseCalculationMethods.filter(function (pension) {
                return pension.IsFlexBase;
            });
        });
    };
    MultiPurposeBalanceCompensationComponent.prototype.EnableSection = function () {
        this.AllowEditSection = true;
        if (this.defaultId || this.defaultId === 0) {
            this.SectionData.Id = this.defaultId;
        }
        if (this.flexData.length > 0 && !this.SectionData.BaseCalculationMethodId) {
            this.SectionData.BaseCalculationMethodId = 2;
        }
        this.createSafeModel(this.SectionData);
        this.currentmodel.push(this.SectionData);
        this.childIsDirty[4] = true;
        this.componentChange();
    };
    MultiPurposeBalanceCompensationComponent.prototype.createSafeModel = function (model, isChange) {
        if (isChange === void 0) { isChange = false; }
        model.BalanceDefinitionId = model.BalanceDefinitionId || 3;
        model.EarningFixedAmount = this.employeeService.checkNumber(model.EarningFixedAmount);
        model.EarningFixedAmountPrincipleId = this.employeeService.checkNumber(model.EarningFixedAmountPrincipleId);
        model.PensionShareFixedAmount = this.employeeService.checkNumber(model.PensionShareFixedAmount);
        model.Id = model.Id || null;
        model.DaysConversionPercentage = this.employeeService.checkNumber(model.DaysConversionPercentage);
        model.DaysEarnedPerPayslip = this.employeeService.checkNumber(model.DaysEarnedPerPayslip);
        model.EarningPercentage = this.employeeService.checkNumber(model.EarningPercentage);
        model.BaseCalculationMethodId = model.BaseCalculationMethodId || null;
        model.PayoutPercentage = this.employeeService.checkNumber(model.PayoutPercentage);
        model.PensionSharePercentage = this.employeeService.checkNumber(model.PensionSharePercentage);
        model[this.NameOfEmplomentIdfeild] = model[this.NameOfEmplomentIdfeild] || null;
        if (isChange) {
            this.componentChange();
        }
    };
    MultiPurposeBalanceCompensationComponent.prototype.componentChange = function () {
        this.onChange();
        this.modelChange.emit(this.currentmodel);
    };
    MultiPurposeBalanceCompensationComponent.prototype.changeModeAndEnableSection = function () {
        this.parentChangeEditMode.emit();
        this.EnableSection();
    };
    return MultiPurposeBalanceCompensationComponent;
}(FormComponentBase));
export { MultiPurposeBalanceCompensationComponent };
