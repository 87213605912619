<app-tab-panel [parentTab]="'company'"></app-tab-panel>

<div class="Main" id="divCompany" [ngClass]="{ Mobile: IsMobile }" tabindex="-1" (click)="clickAreamenuToggledChange()">
  <app-company-general *ngIf="isCompanyGeneralTabVisisble"></app-company-general>

  <app-company-balances *ngIf="isCompanyBalancesVisisble"></app-company-balances>

  <app-company-payroll-data *ngIf="isCompanyPayrollDataTabVisisble"></app-company-payroll-data>

  <app-company-salary-batches *ngIf="isCompanySalaryBatchesTabVisisble"></app-company-salary-batches>

  <app-company-configuration *ngIf="isCompanyConfigurationTabVisisble"></app-company-configuration>

  <company-modules *ngIf="isCompanyExtraTabVisisble"></company-modules>

  <app-company-approval *ngIf="isCompanyApprovalVisisble"></app-company-approval>
</div>

<app-report-dialog
  [(visible)]="reportDialogService.reportDialogVisible"
  [key]="reportDialogService.key"
  [param]="reportDialogService.reportParams"
>
</app-report-dialog>

<app-dialog class="blue-dialog" [(visible)]="mobileWarningDialogVisible">
  <div [innerHTML]="'Company.AlertAppInProblematicViews' | translate: { SystemAlias: branding.SystemAlias }"></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
