import { OnDestroy, OnInit } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from '../common/global';
import { RouterStateService } from '../common/router-state.service';
import { DataService } from '../services/api/data.service';
import { StaticDataService } from '../services/api/static-data.service';
import { EmployeeService } from '../services/employee.service';
import { SessionService } from '../services/session/session.service';
import { ReportDialogService } from '../shared-components/report-dialog/report-dialog.service';
import { ReportParametersDefaultValue } from '../shared-components/report-dialog/report-parameters-default-value';
var SelfServiceComponent = /** @class */ (function () {
    function SelfServiceComponent(sessionService, state, employeeService, dataService, staticDataService, transitionService, reportDialogService) {
        this.sessionService = sessionService;
        this.state = state;
        this.employeeService = employeeService;
        this.dataService = dataService;
        this.staticDataService = staticDataService;
        this.transitionService = transitionService;
        this.reportDialogService = reportDialogService;
        this.editModeValue = false;
        this.isValid = true;
        this.discardDialogVisible = false;
        this.allowUsersToModifyOwnData = false;
        // public isDirty = false;
        this.isDirtyValue = false;
        this.showSaveDataConfirmationChangeState = false;
        this.isDisableChangeEditMode = false;
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(SelfServiceComponent.prototype, "editMode", {
        get: function () {
            return this.editModeValue;
        },
        set: function (value) {
            if (this.editModeValue !== value) {
                this.editModeValue = value;
                this.sessionService.hideGetStarted = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServiceComponent.prototype, "reportKey", {
        get: function () {
            if (this.state.currentStateIs('tabs.selfservice.payslip')) {
                return 'SelfServicePayslips';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServiceComponent.prototype, "hasEmployments", {
        get: function () {
            return this.employeeService.employmentsCount > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServiceComponent.prototype, "reportViewEnabled", {
        get: function () {
            var _this = this;
            if (this.reportAreas && this.reportAreas.length > 0) {
                var area = this.reportAreas.find(function (a) { return a.Key === _this.reportKey; });
                if (area) {
                    return true;
                }
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServiceComponent.prototype, "disabledActionButtonClass", {
        get: function () {
            return this.allowUsersToModifyOwnData ? '' : 'disabledActionButtonClass';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServiceComponent.prototype, "isFirefox", {
        get: function () {
            return this.sessionService.browser.isFirefox;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServiceComponent.prototype, "selfServicePlayslipTabEnabled", {
        get: function () {
            return this.hasEmployments;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServiceComponent.prototype, "selfServiceTimeTabEnabled", {
        get: function () {
            return this.sessionService.feature.hasModuleId(8) && this.hasEmployments;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServiceComponent.prototype, "isIOSApp", {
        get: function () {
            return this.sessionService.browser.isHybridApp && this.sessionService.browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServiceComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelfServiceComponent.prototype, "isDirty", {
        get: function () {
            return this.isDirtyValue;
        },
        set: function (value) {
            this.isDirtyValue = value;
        },
        enumerable: true,
        configurable: true
    });
    SelfServiceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.transitionService.onStart({}, function (transition) {
            var fromState = transition.from().name;
            _this.navigationToStateName = transition.to().name;
            var generalState = 'tabs.selfservice.general';
            if (_this.editMode && fromState && fromState === generalState) {
                if (_this.isDirty) {
                    // If validation fails, alert user and do nothing.
                    // Else show action confirmation.
                    _this.showSaveDataConfirmationChangeState = true;
                    return false;
                }
                else {
                    // Editmode but no changes, we close editmode without doing anything
                    _this.editMode = false;
                    return true;
                }
            }
            else {
                _this.editMode = false;
                return true;
            }
        });
        this.staticDataService.ReportArea.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.reportAreas = data); });
        this.employeeService.selectEmployeeAsync(Global.SESSION.CurrentCompanyUser.Id).subscribe(function () {
            _this.employeeService.filter = '';
            _this.employeeService.employee.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (e) {
                _this.companyUser = e;
                // this.loadCompanyUser();
                var allowUsersToModifyOwnData = _this.sessionService.getCompanyPreference('Access.AllowUsersToModifyOwnData');
                if (allowUsersToModifyOwnData && allowUsersToModifyOwnData.Value === 'true') {
                    _this.allowUsersToModifyOwnData = true;
                }
                _this.employeeService.employments.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function () {
                    _this.checkSwitchingSelfServiceTab();
                });
            });
        });
    };
    SelfServiceComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    SelfServiceComponent.prototype.onTabClick = function () {
        this.sessionService.menuToggled = true;
        this.sessionService.showMoreMenuToggled = false;
        this.sessionService.showHelpMenuToggled = false;
    };
    SelfServiceComponent.prototype.onConfirmSaveDataOnChangeState = function (action) {
        switch (action) {
            case 'ContinueAndSave':
                this.editMode = false;
                if (this.navigationToStateName) {
                    this.sessionService.NavigateTo(this.navigationToStateName);
                }
                break;
            case 'DiscardAndLeave':
                this.discardChanges();
                if (this.navigationToStateName) {
                    this.sessionService.NavigateTo(this.navigationToStateName);
                }
                break;
        }
        this.navigationToStateName = undefined;
    };
    SelfServiceComponent.prototype.onShowReportsEventClick = function () {
        if (!this.companyUser) {
            return;
        }
        this.reportDialogService.reportParams = new ReportParametersDefaultValue();
        this.reportDialogService.reportParams.employeeId = this.companyUser ? this.companyUser.Id : undefined;
        this.reportDialogService.reportParams.employmentId = this.userEmployment ? this.userEmployment.Id : undefined;
        this.reportDialogService.onShowReportsEventClick();
    };
    SelfServiceComponent.prototype.onEmployeeGeneralChanged = function () {
        // this.shouldUpdateUserEmployment = true;
    };
    SelfServiceComponent.prototype.onEditModeChange = function (editMode) {
        if (this.editMode === editMode || !this.allowUsersToModifyOwnData || (!this.isValid && editMode === false)) {
            return;
        }
        if (editMode === false && this.isDirty) {
            this.updateUserEmployment();
        }
        this.editMode = editMode;
    };
    SelfServiceComponent.prototype.discardDialogAction = function (action) {
        if (action === 'ConfirmDiscard') {
            this.discardChanges();
        }
    };
    SelfServiceComponent.prototype.confirmAndDiscardChanges = function () {
        if (!this.isDirty) {
            this.editMode = false;
            return;
        }
        this.discardDialogVisible = true;
    };
    SelfServiceComponent.prototype.onDoubleClick = function () {
        if (!this.allowUsersToModifyOwnData) {
            this.isDisableChangeEditMode = true;
            return;
        }
        if (!this.sessionService.isGetting &&
            !this.sessionService.isSubmitting &&
            !this.editMode &&
            this.sessionService.currentState === 'tabs.selfservice.general') {
            this.onEditModeChange(true);
        }
    };
    SelfServiceComponent.prototype.updateUserEmployment = function () {
        var _this = this;
        this.employeeService.saveChanges().subscribe(function () { return (_this.isDirty = false); }, function () { return (_this.editMode = true); });
    };
    SelfServiceComponent.prototype.discardChanges = function () {
        this.editMode = false;
        this.isDirty = false;
        this.isValid = true;
        this.employeeService.discardChanges();
    };
    SelfServiceComponent.prototype.checkSwitchingSelfServiceTab = function () {
        if (this.sessionService.currentState) {
            switch (this.sessionService.currentState) {
                case 'tabs.selfservice.time':
                    if (!this.selfServiceTimeTabEnabled) {
                        this.sessionService.NavigateTo('tabs.selfservice.general');
                    }
                    else {
                        this.sessionService.NavigateTo(this.sessionService.selfServiceTabState);
                    }
                    break;
                case 'tabs.selfservice.payslip':
                    if (!this.selfServicePlayslipTabEnabled) {
                        this.sessionService.NavigateTo('tabs.selfservice.general');
                    }
                    else {
                        this.sessionService.NavigateTo(this.sessionService.selfServiceTabState);
                    }
                    break;
                default:
                    if (!this.selfServicePlayslipTabEnabled) {
                        this.sessionService.NavigateTo('tabs.selfservice.general');
                    }
                    else {
                        if (!this.sessionService.selfServiceTabState) {
                            this.sessionService.NavigateTo('tabs.selfservice.payslip');
                        }
                        else {
                            this.sessionService.NavigateTo(this.sessionService.selfServiceTabState);
                        }
                    }
                    break;
            }
        }
        else {
            this.sessionService.NavigateTo('tabs.selfservice.payslip');
        }
    };
    return SelfServiceComponent;
}());
export { SelfServiceComponent };
