import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataService } from '../services/api/data.service';

@Component({
  selector: 'app-accept-dpa-dialog',
  templateUrl: './accept-dpa-dialog.component.html'
})
export class AcceptDpaDialogComponent {
  private isVisibleValue = false;
  @Input()
  public get isVisible(): boolean {
    return this.isVisibleValue;
  }
  public set isVisible(value: boolean) {
    if (value) {
      this.password = '';
    }

    this.isVisibleValue = value;
    this.isVisibleChange.emit(value);
  }
  @Output() public isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public accepted: EventEmitter<any> = new EventEmitter<any>();

  public password: string;

  constructor(private dataService: DataService) {}

  public onDialogAction(action: string): void {
    if (action === 'Create') {
      this.acceptDPA();
    } else {
      this.accepted.emit(false);
    }
  }

  public get branding() {
    return environment.branding;
  }

  public onAcceptDPAKeydown(event: any): void {
    if (event.keyCode === 13) {
      this.acceptDPA();
    }
  }

  private acceptDPA(): void {
    this.dataService.Companies_AcceptTerms({ Password: this.password }).subscribe(() => {
      this.isVisible = false;
      this.accepted.emit(true);
    });
  }
}
