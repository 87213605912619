import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Global } from '../common/global';
import { DefaultAvatar } from '../model/default-avatar';
import { SessionService } from '../services/session/session.service';
import { UserEmployment } from './api/api-model';
import { DataService } from './api/data.service';
import * as i0 from "@angular/core";
import * as i1 from "./session/session.service";
import * as i2 from "./api/data.service";
var EmployeeService = /** @class */ (function () {
    function EmployeeService(sessionService, dataService) {
        var _this = this;
        this.sessionService = sessionService;
        this.dataService = dataService;
        this.employeesCountSubject = new BehaviorSubject(0);
        this.employeeSubject = new BehaviorSubject(null);
        this.employmentsSubject = new BehaviorSubject([]);
        this.employmentSubject = new BehaviorSubject(new UserEmployment());
        this.userImageSubject = new BehaviorSubject(DefaultAvatar.imgMaleAvatar);
        this.filterValue = '';
        this.filterSubject = new BehaviorSubject('');
        this.departmentIdValue = -1;
        this.salaryCycleIdValue = -1;
        this.templateIdValue = -1;
        this.sortByValue = 1;
        this.includeInactiveValue = false;
        this.selectedEmployeeIdSubject = new BehaviorSubject(null);
        this.selectedEmploymentIdSubject = new BehaviorSubject(null);
        this.editMode = false;
        this.editModeChangeEvent = new EventEmitter();
        this.makeRequestTaxCard = new EventEmitter();
        this.allEmployees = [];
        this.filterSubject
            .asObservable()
            .pipe(debounceTime(700), distinctUntilChanged())
            .subscribe(function (value) { return _this.filterEmployees(); });
    }
    Object.defineProperty(EmployeeService.prototype, "employees", {
        get: function () {
            if (!this.employeesSubject) {
                this.loadEmployees();
            }
            return this.employeesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeService.prototype, "employeesCount", {
        get: function () {
            return this.employeesCountSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeService.prototype, "employee", {
        get: function () {
            return this.employeeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeService.prototype, "employments", {
        get: function () {
            return this.employmentsSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeService.prototype, "employment", {
        get: function () {
            return this.employmentSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeService.prototype, "employmentsCount", {
        get: function () {
            return this.employmentsSubject.value.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeService.prototype, "userImage", {
        get: function () {
            return this.userImageSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeService.prototype, "filter", {
        get: function () {
            return this.filterValue;
        },
        set: function (value) {
            if (this.filterValue !== value) {
                this.filterValue = value;
                this.filterSubject.next(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeService.prototype, "departmentId", {
        get: function () {
            return this.departmentIdValue;
        },
        set: function (value) {
            if (this.departmentIdValue !== value) {
                this.departmentIdValue = value;
                this.loadEmployees();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeService.prototype, "salaryIdCycle", {
        get: function () {
            return this.salaryCycleIdValue;
        },
        set: function (value) {
            if (this.salaryCycleIdValue !== value) {
                this.salaryCycleIdValue = value;
                this.loadEmployees();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeService.prototype, "templateId", {
        get: function () {
            return this.templateIdValue;
        },
        set: function (value) {
            if (this.templateIdValue !== value) {
                this.templateIdValue = value;
                this.loadEmployees();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeService.prototype, "sortBy", {
        get: function () {
            return this.sortByValue;
        },
        set: function (value) {
            if (this.sortByValue !== value) {
                this.sortByValue = value;
                this.loadEmployees();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeService.prototype, "includeInactive", {
        get: function () {
            return this.includeInactiveValue;
        },
        set: function (value) {
            if (this.includeInactiveValue !== value) {
                this.includeInactiveValue = value;
                this.loadEmployees();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeService.prototype, "selectedEmployeeId", {
        get: function () {
            return this.selectedEmployeeIdSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeService.prototype, "selectedEmploymentId", {
        get: function () {
            return this.selectedEmploymentIdSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeService.prototype, "selectedId", {
        get: function () {
            return this.selectedEmployeeIdSubject.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeService.prototype, "isNonSelected", {
        get: function () {
            return !this.employeeSubject.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeService.prototype, "isCurrentUserHasEmployment", {
        get: function () {
            if (this.allEmployees && this.allEmployees.length > 0) {
                return (this.currentCompanyUser &&
                    this.currentCompanyUser.UserEmploymentIds &&
                    this.currentCompanyUser.UserEmploymentIds.length > 0);
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeService.prototype, "isCurrentUserExistIncompanyUsers", {
        get: function () {
            if (this.allEmployees && this.allEmployees.length > 0) {
                return !!this.currentCompanyUser;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeService.prototype, "currentCompanyUser", {
        get: function () {
            if (this.allEmployees && this.allEmployees.length > 0) {
                return this.allEmployees.find(function (model) { return model.Id === Global.SESSION.CurrentCompanyUser.Id; });
            }
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeService.prototype.updateUserImage = function (data) {
        var _this = this;
        if (Object.keys(data).length !== 0) {
            return;
        }
        this.dataService
            .User_UploadEmployeeImage(data, this.employeeSubject.value.UserId)
            .subscribe(function () { return _this.getUserImage(); });
    };
    EmployeeService.prototype.saveChanges = function (updateTemplate) {
        var _this = this;
        if (updateTemplate === void 0) { updateTemplate = false; }
        this.ConvertCorrectEmployeeObject(this.employeeSubject.value);
        var request = this.employeeSubject.value;
        if (this.employmentSubject.value && this.employmentSubject.value.Id) {
            var activeEmployment = request.UserEmployments.find(function (z) { return z.Id === _this.employmentSubject.value.Id; });
            request.UserEmployments = [];
            request.UserEmployments.push(activeEmployment);
        }
        return this.dataService.CompanyUsers_UpdateCompanyUser(request).pipe(tap(function (employee) {
            if (Global.SESSION.CurrentCompanyUser &&
                Global.SESSION.CurrentCompanyUser.Id === _this.employeeSubject.value.Id) {
                Global.USEREMPLOYMENTS = employee.UserEmployments;
            }
            if (updateTemplate) {
                _this.dataService
                    .Employments_UpdateEmploymentToCurrentTemplate(_this.employmentSubject.value.Id)
                    .subscribe(function (employment) {
                    var index = employee.UserEmployments.findIndex(function (e) { return e.Id === employment.Id; });
                    employee.UserEmployments[index] = employment;
                    _this.updateEmployee(employee);
                    _this.employeeSubject.next(employee);
                    _this.filterEmployees();
                });
            }
            else {
                _this.updateEmployee(employee);
                _this.employeeSubject.next(employee);
                _this.filterEmployees();
            }
        }));
    };
    EmployeeService.prototype.ConvertCorrectEmployeeObject = function (employee) {
        if (employee) {
            employee.Id = employee.Id || null;
            employee.UserId = employee.UserId || null;
            employee.CompanyId = employee.CompanyId || null;
            employee.RoleId = employee.RoleId || null;
            employee.IsActive = employee.IsActive || false;
            employee.FirstName = employee.FirstName || null;
            employee.MiddleName = employee.MiddleName || null;
            employee.LastName = employee.LastName || null;
            employee.BankRegNo = employee.BankRegNo || null;
            employee.BankAccountNo = employee.BankAccountNo || null;
            employee.BicSwiftNo = employee.BicSwiftNo || null;
            employee.IbanNo = employee.IbanNo || null;
            employee.AddressId = employee.AddressId || null;
            employee.IsPaymentApprover = employee.IsPaymentApprover || false;
            employee.Initials = employee.Initials || null;
            employee.TelMobile = employee.TelMobile || null;
            employee.TelFixed = employee.TelFixed || null;
            employee.CompanyEmail = employee.CompanyEmail || null;
            employee.PersonalEmail = employee.PersonalEmail || null;
            employee.Created = employee.Created || null;
            employee.DeactivationDate = employee.DeactivationDate || null;
            employee.PreferredCurrencyId = employee.PreferredCurrencyId || null;
            employee.AutoDetermineBankAccount = employee.AutoDetermineBankAccount || false;
            employee.MunicipalityId = employee.MunicipalityId || null;
            employee.FullName = employee.FullName || null;
            employee.Role = employee.Role || null;
            employee.UserLanguageId = employee.UserLanguageId || null;
            employee.UserIdentityNumber = employee.UserIdentityNumber || null;
            employee.UserDateOfBirth = employee.UserDateOfBirth || null;
            employee.UserIsFemale = employee.UserIsFemale || false;
            employee.HasDummyIdentityNumber = employee.HasDummyIdentityNumber || false;
            if (employee.UserEmployments) {
                this.ConvertCorrectEmploymentObject(employee.UserEmployments);
            }
        }
    };
    EmployeeService.prototype.ConvertCorrectEmploymentObject = function (employment) {
        var _this = this;
        if (employment && employment.length > 0) {
            employment.forEach(function (item) {
                item.Id = item.Id || null;
                item.CompanyUserId = item.CompanyUserId || null;
                item.HireDate = item.HireDate || null;
                item.ExpireDate = item.ExpireDate || null;
                item.VacationTypeId = item.VacationTypeId || null;
                item.VacationProviderId = item.VacationProviderId || null;
                item.VacationExtraPercentage = _this.checkNumber(item.VacationExtraPercentage);
                item.BasedOnTemplateId = item.BasedOnTemplateId || null;
                item.Title = item.Title || null;
                item.SalaryCycleId = item.SalaryCycleId || null;
                item.ExternalReference = item.ExternalReference || null;
                item.PensionBaseCalculationMethodId = item.PensionBaseCalculationMethodId || null;
                item.IsAtpTempWorker = item.IsAtpTempWorker || false;
                item.DepartmentId = item.DepartmentId || null;
                item.IncomeTypeId = item.IncomeTypeId || null;
                item.WorksElsewhere = item.WorksElsewhere || false;
                item.PensionEmployeeShare = _this.checkNumber(item.PensionEmployeeShare);
                item.PensionCompanyShare = _this.checkNumber(item.PensionCompanyShare);
                item.PensionProviderId = item.PensionProviderId || null;
                item.PensionPolicyNumber = item.PensionPolicyNumber || null;
                item.PensionEmployeeAmount = _this.checkNumber(item.PensionEmployeeAmount);
                item.PensionCompanyAmount = _this.checkNumber(item.PensionCompanyAmount);
                item.Pension2BaseCalculationMethodId = item.Pension2BaseCalculationMethodId || null;
                item.Pension2ProviderId = item.Pension2ProviderId || null;
                item.Pension2EmployeeShare = _this.checkNumber(item.Pension2EmployeeShare);
                item.Pension2CompanyShare = _this.checkNumber(item.Pension2CompanyShare);
                item.Pension2EmployeeAmount = _this.checkNumber(item.Pension2EmployeeAmount);
                item.Pension2CompanyAmount = _this.checkNumber(item.Pension2CompanyAmount);
                item.LastStatementEndDate = item.LastStatementEndDate || null;
                //item.VacationReduceSalary = item.VacationReduceSalary || false;
                item.IsTerminated = item.IsTerminated || false;
                item.IsInMaternityProgram = item.IsInMaternityProgram || false;
                item.TaxCardRequested = item.TaxCardRequested || null;
                item.PensionProviderBankRegNo = item.PensionProviderBankRegNo || null;
                item.PensionProviderBankAccountNo = item.PensionProviderBankAccountNo || null;
                item.Pension2ProviderBankRegNo = item.Pension2ProviderBankRegNo || null;
                item.Pension2ProviderBankAccountNo = item.Pension2ProviderBankAccountNo || null;
                item.Pension2PolicyNumber = item.Pension2PolicyNumber || null;
                item.PreferredTaxCardTypeId = item.PreferredTaxCardTypeId || null;
                item.Created = item.Created || null;
                item.IllnessReducesSalary = item.IllnessReducesSalary || false;
                item.ManagerCompanyUserId = item.ManagerCompanyUserId || null;
                item.StandardHoursPerWeek = _this.checkNumber(item.StandardHoursPerWeek);
                item.StandardWorkDaysPerWeek = _this.checkNumber(item.StandardWorkDaysPerWeek);
                item.StandardWorkHoursMonday = _this.checkNumber(item.StandardWorkHoursMonday);
                item.StandardWorkHoursTuesday = _this.checkNumber(item.StandardWorkHoursTuesday);
                item.StandardWorkHoursWednesday = _this.checkNumber(item.StandardWorkHoursWednesday);
                item.StandardWorkHoursThursday = _this.checkNumber(item.StandardWorkHoursThursday);
                item.StandardWorkHoursFriday = _this.checkNumber(item.StandardWorkHoursFriday);
                item.StandardWorkHoursSaturday = _this.checkNumber(item.StandardWorkHoursSaturday);
                item.StandardWorkHoursSunday = _this.checkNumber(item.StandardWorkHoursSunday);
                item.StatisticsSalaryPrincipleId = item.StatisticsSalaryPrincipleId || null;
                item.StatisticsEmploymentPrincipleId = item.StatisticsEmploymentPrincipleId || null;
                item.StatisticsEmploymentTermsId = item.StatisticsEmploymentTermsId || null;
                item.StatisticsJobStatusId = item.StatisticsJobStatusId || null;
                item.StatisticsJobFunctionCode = _this.checkNumber(item.StatisticsJobFunctionCode);
                item.StatisticsSalaryPrincipleFromDate = item.StatisticsSalaryPrincipleFromDate || null;
                item.StatisticsEmploymentPrincipleFromDate = item.StatisticsEmploymentPrincipleFromDate || null;
                item.StatisticsEmploymentTermsFromDate = item.StatisticsEmploymentTermsFromDate || null;
                item.StatisticsJobStatusFromDate = item.StatisticsJobStatusFromDate || null;
                item.StatisticsJobFunctionFromDate = item.StatisticsJobFunctionFromDate || null;
                item.FlexEarningPercentage = _this.checkNumber(item.FlexEarningPercentage);
                item.FlexBaseCalculationMethodId = item.FlexBaseCalculationMethodId || null;
                item.CollectiveBargainingAgreementCode = item.CollectiveBargainingAgreementCode || null;
                item.TerminationVacationOverridePercentage = _this.checkNumber(item.TerminationVacationOverridePercentage);
                item.SeniorityDate = item.SeniorityDate || null;
                item.ApplyDanishRulesInGreenland = item.ApplyDanishRulesInGreenland || false;
                item.AnnualTaxComment = item.AnnualTaxComment || null;
                item.AllowExtendedTaxCardDeductions = item.AllowExtendedTaxCardDeductions || false;
                item.ReduceTaxCardDeduction = _this.checkNumber(item.ReduceTaxCardDeduction);
                item.IncreaseTaxRatePercentage = _this.checkNumber(item.IncreaseTaxRatePercentage);
                item.UseTaxDeductionPerWorkDay = item.UseTaxDeductionPerWorkDay || false;
                item.DefaultRate = _this.checkNumber(item.DefaultRate);
                item.UseContinuousVacationReporting = item.UseContinuousVacationReporting || false;
                item.PensionStartDate = item.PensionStartDate || null;
                item.PensionEndDate = item.PensionEndDate || null;
                item.Pension2StartDate = item.Pension2StartDate || null;
                item.Pension2EndDate = item.Pension2EndDate || null;
            });
        }
    };
    EmployeeService.prototype.checkNumber = function (value) {
        if ((!value && value === 0) || value) {
            return value;
        }
        return null;
    };
    EmployeeService.prototype.discardChanges = function () {
        this.selectEmployee(this.employeeSubject.value.Id, true);
    };
    EmployeeService.prototype.selectEmployeeFromEmploymentId = function (employmentId, isDbClicKFromGrid) {
        var employee = this.allEmployees.find(function (e) {
            if (e.UserEmploymentIds) {
                var employment = e.UserEmploymentIds.find(function (ue) { return ue === employmentId; });
                return !!employment;
            }
            return false;
        });
        if (employee) {
            if (isDbClicKFromGrid) {
                this.filter = '';
            }
            this.selectEmployee(employee.Id, true, employmentId);
        }
    };
    EmployeeService.prototype.selectEmployee = function (id, reload, employmentId) {
        var _this = this;
        if (reload === void 0) { reload = false; }
        if (id) {
            if (reload || id !== this.selectedEmployeeIdSubject.value) {
                this.selectedEmployeeIdSubject.next(id);
                this.dataService.CompanyUsers_GetCompanyUserById(id).subscribe(function (employee) {
                    _this.updateEmployee(employee);
                    if (employee.Id === _this.selectedEmployeeIdSubject.value) {
                        _this.employeeSubject.next(employee);
                        _this.getUserImage();
                        if (employee.UserEmployments && employee.UserEmployments.length > 0) {
                            _this.employmentsSubject.next(employee.UserEmployments);
                            if (employmentId && employee.UserEmployments.some(function (e) { return e.Id === employmentId; })) {
                                _this.selectEmployment(employmentId);
                            }
                            else if (employee.UserEmployments.some(function (e) { return e.Id === _this.selectedEmploymentIdSubject.value; })) {
                                _this.selectEmployment(_this.selectedEmploymentIdSubject.value);
                            }
                            else {
                                _this.selectEmployment(employee.UserEmployments[0].Id);
                            }
                        }
                        else {
                            _this.employmentsSubject.next([]);
                            _this.selectEmployment(-1);
                        }
                    }
                });
            }
        }
        else {
            this.employeeSubject.next(null);
            this.employmentsSubject.next([]);
            this.userImageSubject.next(DefaultAvatar.imgMaleAvatar);
        }
    };
    EmployeeService.prototype.selectEmployeeAsync = function (id, reload, employmentId) {
        var _this = this;
        if (reload === void 0) { reload = false; }
        if (id) {
            if (reload || id !== this.selectedEmployeeIdSubject.value) {
                this.selectedEmployeeIdSubject.next(id);
                return Observable.create(function (obserdata) {
                    _this.dataService.CompanyUsers_GetCompanyUserById(id).subscribe(function (employee) {
                        _this.updateEmployee(employee);
                        if (employee.Id === _this.selectedEmployeeIdSubject.value) {
                            _this.employeeSubject.next(employee);
                            _this.getUserImage();
                            if (employee.UserEmployments && employee.UserEmployments.length > 0) {
                                _this.employmentsSubject.next(employee.UserEmployments);
                                if (employmentId && employee.UserEmployments.some(function (e) { return e.Id === employmentId; })) {
                                    _this.selectEmployment(employmentId);
                                }
                                else if (employee.UserEmployments.some(function (e) { return e.Id === _this.selectedEmploymentIdSubject.value; })) {
                                    _this.selectEmployment(_this.selectedEmploymentIdSubject.value);
                                }
                                else {
                                    _this.selectEmployment(employee.UserEmployments[0].Id);
                                }
                            }
                            else {
                                _this.employmentsSubject.next([]);
                                _this.selectEmployment(-1);
                            }
                        }
                        obserdata.next(true);
                    });
                });
            }
            return Observable.create(function (obserdata) {
                obserdata.next(false);
            });
        }
        else {
            return Observable.create(function (obserdata) {
                _this.employeeSubject.next(null);
                _this.employmentsSubject.next([]);
                _this.userImageSubject.next(DefaultAvatar.imgMaleAvatar);
                obserdata.next(true);
            });
        }
    };
    EmployeeService.prototype.selectNext = function () {
        var _this = this;
        var employees = this.employeesSubject.value;
        var currentIndex = employees.findIndex(function (e) { return e.Id === _this.selectedEmployeeIdSubject.value; });
        var nextIndex = currentIndex < employees.length - 1 ? currentIndex + 1 : 0;
        this.selectEmployee(employees[nextIndex].Id);
    };
    EmployeeService.prototype.selectPrevious = function () {
        var _this = this;
        var employees = this.employeesSubject.value;
        var currentIndex = employees.findIndex(function (e) { return e.Id === _this.selectedEmployeeIdSubject.value; });
        var previousIndex = currentIndex > 0 ? currentIndex - 1 : employees.length - 1;
        this.selectEmployee(employees[previousIndex].Id);
    };
    EmployeeService.prototype.selectEmployment = function (id) {
        var employment = this.employmentsSubject.value.find(function (e) { return e.Id === id; });
        if (employment) {
            this.employmentSubject.next(employment);
            this.selectedEmploymentIdSubject.next(id);
        }
        else {
            this.employmentSubject.next(new UserEmployment());
            this.selectedEmploymentIdSubject.next(null);
        }
    };
    EmployeeService.prototype.loadEmployees = function () {
        var _this = this;
        if (!this.employeesSubject) {
            this.employeesSubject = new BehaviorSubject([]);
        }
        var request = {
            IncludeInactive: true,
            IncludeHidden: false,
            SortBySurname: this.sortBy === 2,
            LimitToDepartmentId: this.departmentId !== -1 ? this.departmentId : undefined,
            LimitToSalaryCycleId: this.salaryIdCycle !== -1 ? this.salaryIdCycle : undefined,
            LimitToTemplateId: this.templateId !== -1 ? this.templateId : undefined
        };
        this.dataService
            .CompanyUsers_GetAllCompanyUsersSimpleWithOptions(request)
            .subscribe(function (data) {
            _this.allEmployees = data;
            _this.filterEmployees();
        });
    };
    EmployeeService.prototype.loadEmployeesAsync = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.employeesSubject) {
                            this.employeesSubject = new BehaviorSubject([]);
                        }
                        request = {
                            IncludeInactive: true,
                            IncludeHidden: false,
                            SortBySurname: this.sortBy === 2,
                            LimitToDepartmentId: this.departmentId !== -1 ? this.departmentId : undefined,
                            LimitToSalaryCycleId: this.salaryIdCycle !== -1 ? this.salaryIdCycle : undefined,
                            LimitToTemplateId: this.templateId !== -1 ? this.templateId : undefined
                        };
                        _a = this;
                        return [4 /*yield*/, this.dataService.CompanyUsers_GetAllCompanyUsersSimpleWithOptions(request).toPromise()];
                    case 1:
                        _a.allEmployees = _b.sent();
                        this.filterEmployees();
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeService.prototype.createEmployee = function (newEmployeeRequest) {
        var _this = this;
        newEmployeeRequest.Details.IsActive = true;
        newEmployeeRequest.Details.CompanyId = Global.COMPANY_ID;
        if (!newEmployeeRequest.EmploymentTemplateId) {
            newEmployeeRequest.EmploymentTemplateId = undefined;
            newEmployeeRequest.Title = undefined;
            newEmployeeRequest.DepartmentId = undefined;
            newEmployeeRequest.TaxCardTypeId = undefined;
            newEmployeeRequest.ManagerCompanyUserId = undefined;
        }
        this.filter = '';
        if (this.departmentId) {
            this.departmentId = -1;
        }
        if (this.salaryIdCycle) {
            this.salaryIdCycle = -1;
        }
        if (this.templateId) {
            this.templateId = -1;
        }
        return this.dataService.CompanyUsers_AddUserToCompany(newEmployeeRequest).pipe(tap(function (data) {
            _this.selectedEmployeeIdSubject.next(data.CompanyUser.Id);
            _this.loadEmployees();
        }));
    };
    EmployeeService.prototype.deleteEmployee = function () {
        var _this = this;
        return this.dataService.CompanyUsers_DeleteCompanyUser(this.employeeSubject.value.Id).pipe(tap(function () {
            _this.allEmployees = _this.allEmployees.filter(function (e) { return e.Id !== _this.employeeSubject.value.Id; });
            _this.filterEmployees();
        }));
    };
    EmployeeService.prototype.createEmployment = function (userEmployment) {
        var _this = this;
        return this.dataService.Employments_CreateUserEmployment(userEmployment).pipe(tap(function (data) {
            if (!_this.employeeSubject.value.UserEmployments) {
                _this.employeeSubject.value.UserEmployments = [];
            }
            _this.employeeSubject.value.UserEmployments.push(data);
            _this.employmentsSubject.next(_this.employeeSubject.value.UserEmployments);
            _this.selectEmployment(data.Id);
        }));
    };
    EmployeeService.prototype.deleteEmployment = function () {
        var _this = this;
        return this.dataService.Employments_DeleteUserEmployment(this.employmentSubject.value.Id).pipe(tap(function () {
            _this.employeeSubject.value.UserEmployments = _this.employeeSubject.value.UserEmployments.filter(function (e) { return e.Id !== _this.employmentSubject.value.Id; });
            _this.employmentsSubject.next(_this.employeeSubject.value.UserEmployments);
            _this.selectEmployee(_this.employeeSubject.value.Id, true);
            if (_this.employeeSubject.value.UserEmployments.length > 0) {
                _this.selectEmployment(_this.employeeSubject.value.UserEmployments[0].Id);
            }
            else {
                _this.selectEmployment(-1);
            }
        }));
    };
    EmployeeService.prototype.getUserImage = function () {
        var _this = this;
        this.dataService.Users_GetUserImage(this.employeeSubject.value.UserId).subscribe(function (data) {
            if (data && data.Image && _this.employeeSubject.value && data.UserId === _this.employeeSubject.value.UserId) {
                _this.userImageSubject.next(data.Image);
            }
            else {
                _this.userImageSubject.next(_this.employeeSubject.value && _this.employeeSubject.value.UserIsFemale
                    ? DefaultAvatar.imgFemaleAvatar
                    : DefaultAvatar.imgMaleAvatar);
            }
        }, function () {
            _this.userImageSubject.next(_this.employeeSubject.value && _this.employeeSubject.value.UserIsFemale
                ? DefaultAvatar.imgFemaleAvatar
                : DefaultAvatar.imgMaleAvatar);
        });
    };
    EmployeeService.prototype.updateEmployee = function (employee) {
        if (this.allEmployees.length > 0) {
            var index = this.allEmployees.findIndex(function (e) { return e.Id === employee.Id; });
            if (index > -1) {
                this.allEmployees[index].Id = employee.Id;
                this.allEmployees[index].IsActive = employee.IsActive;
                this.allEmployees[index].FirstName = employee.FirstName;
                this.allEmployees[index].MiddleName = employee.MiddleName;
                this.allEmployees[index].LastName = employee.LastName;
                this.allEmployees[index].FullName = employee.FullName;
                this.allEmployees[index].RoleId = employee.RoleId;
                this.allEmployees[index].UserId = employee.UserId;
                this.allEmployees[index].IdentityNumber = employee.UserIdentityNumber;
                this.allEmployees[index].PersonalEmail = employee.PersonalEmail;
                this.allEmployees[index].CompanyEmail = employee.CompanyEmail;
                if (employee.UserEmployments) {
                    this.allEmployees[index].ExternalReferences = employee.UserEmployments.map(function (e) { return e.ExternalReference; });
                    this.allEmployees[index].UserEmploymentIds = employee.UserEmployments.map(function (e) { return e.Id; });
                }
                else {
                    this.allEmployees[index].ExternalReferences = [];
                    this.allEmployees[index].UserEmploymentIds = [];
                }
            }
        }
    };
    EmployeeService.prototype.filterEmployees = function () {
        var _this = this;
        if (!this.allEmployees) {
            return;
        }
        var result = this.allEmployees;
        result = result.filter(function (e) {
            if (!_this.includeInactiveValue && !e.IsActive) {
                return false;
            }
            return true;
        });
        if (this.filter) {
            var keyword_1 = this.filter.toLowerCase().trim();
            result = result.filter(function (e) {
                if (e.FullName.toLowerCase().includes(keyword_1)) {
                    return true;
                }
                if (e.CompanyEmail && e.CompanyEmail.toLowerCase().includes(keyword_1)) {
                    return true;
                }
                if (e.PersonalEmail && e.PersonalEmail.toLowerCase().includes(keyword_1)) {
                    return true;
                }
                if (e.IdentityNumber &&
                    e.IdentityNumber.replace('-', '')
                        .toLowerCase()
                        .includes(keyword_1.replace('-', ''))) {
                    return true;
                }
                if (e.ExternalReferences) {
                    if (e.ExternalReferences.find(function (ue) { return ue && ue.toLowerCase().includes(keyword_1); })) {
                        return true;
                    }
                }
                if (e.UserEmploymentIds) {
                    if (e.UserEmploymentIds.find(function (ue) { return ue.toString().includes(keyword_1); })) {
                        return true;
                    }
                }
                return false;
            });
        }
        this.employeesCountSubject.next(result.length);
        // Dont select employee at small device
        if (this.sessionService.employeeListActive && this.sessionService.browser.isMobile) {
            this.employeesSubject.next(this.createEmployeeList(result));
            return;
        }
        // Select employee after filter
        if (result.length > 0) {
            this.employeesSubject.next(this.createEmployeeList(result));
            if (!this.selectedEmployeeIdSubject.value) {
                if (!this.sessionService.currentState.includes('tabs.accountant')) {
                    this.selectEmployee(result[0].Id, true);
                }
            }
            else {
                if (result.some(function (e) { return e.Id === _this.selectedEmployeeIdSubject.value; })) {
                    this.selectEmployee(this.selectedEmployeeIdSubject.value, !this.sessionService.currentState.includes('tabs.selfservice'));
                }
                else {
                    this.selectEmployee(result[0].Id, true);
                }
            }
        }
        else {
            if (this.employeesSubject && !this.sessionService.currentState.includes('tabs.selfservice')) {
                this.employeesSubject.next([]);
                if (this.filter && this.filter.length > 0) {
                    setTimeout(function () {
                        _this.selectEmployee(undefined);
                    }, 1000);
                    return;
                }
                this.selectEmployee(undefined);
            }
        }
    };
    EmployeeService.prototype.createEmployeeList = function (employees) {
        var _this = this;
        var result = employees.map(function (employee) {
            var name = employee.FullName;
            if (employee.FirstName && employee.LastName) {
                name =
                    _this.sortBy === 1
                        ? employee.FirstName.trim() + ' ' + employee.LastName.trim()
                        : employee.LastName.trim() + ' ' + employee.FirstName.trim();
            }
            if (!employee.IsActive) {
                name = '(' + name + ')';
            }
            return { Id: employee.Id, Text: name };
        });
        if (this.sortBy === 2) {
            result.sort(function (a, b) {
                return a.Text.replace('(', '').toLowerCase() < b.Text.replace('(', '').toLowerCase()
                    ? -1
                    : a.Text.replace('(', '').toLowerCase() > b.Text.replace('(', '').toLowerCase()
                        ? 1
                        : 0;
            });
        }
        return result;
    };
    EmployeeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmployeeService_Factory() { return new EmployeeService(i0.ɵɵinject(i1.SessionService), i0.ɵɵinject(i2.DataService)); }, token: EmployeeService, providedIn: "root" });
    return EmployeeService;
}());
export { EmployeeService };
