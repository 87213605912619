import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as srcDoc from 'srcdoc-polyfill';
import { environment } from '../../../environments/environment';
import { Global } from '../../common/global';
import { ISalaryStatement } from '../../services/api/api-model';
import { ICompany, ISimpleCompany } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { DialogService } from '../../services/dialog.service';
import { DownloadService } from '../../services/download.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';

@Component({
  selector: 'app-self-service-payslip',
  templateUrl: './self-service-payslip.component.html',
  styleUrls: ['./self-service-payslip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelfServicePayslipComponent implements OnInit {
  public salaryStatements: any[] = [];
  public previewClicked = false;
  public htmlContent = '';
  public previousBtnDisabled = false;
  public nextBtnDisabled = false;
  public printBtnDisabled = false;
  public paySlipPreviewDialog = false;
  public newTabBlockedDialogVisible = false;
  public currentCompany: ICompany;
  public companies: ISimpleCompany[];
  public ngUnsubscribe: Subject<{}> = new Subject();
  public isHidden = false;
  public contentLoaded = false;
  public safeContent: SafeHtml;
  public listClassExcluded = ['Footer'];

  public get hideColumn(): string {
    return this.previewClicked ? 'Payslip-hide-column-preview' : 'Payslip-hide-column';
  }
  public get hideColumn2(): string {
    return this.previewClicked ? 'Payslip-hide-column-2-preview' : 'Payslip-hide-column-2';
  }
  public get isCordovaApp(): boolean {
    return this.sessionService.browser.isHybridApp;
  }
  public get isEdge(): boolean {
    return this.sessionService.browser.isEdge;
  }
  public get isMoreOneCompany(): boolean {
    if (this.companies && this.companies.length > 1) {
      return true;
    }
    return false;
  }

  public get isFirefox(): boolean {
    return this.sessionService.browser.isFirefox;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  // public get safeContent(): SafeHtml { return this.sanitizer.bypassSecurityTrustHtml(this.htmlContent); }

  private currentIndex = -1;
  private currentId: number;
  private translationServiceTerms: string[];

  private get isActive(): boolean {
    return this.sessionService.currentState === 'tabs.selfservice.payslip';
  }
  private get isAboveXLargeScreen(): boolean {
    return window.innerWidth && window.innerWidth >= 1200;
  }
  private get isUntilXLargeScreen(): boolean {
    return window.innerWidth && window.innerWidth < 1200;
  }

  @ViewChild('iframePayslipPreview', { static: false }) private iframe: ElementRef;

  constructor(
    private dataService: DataService,
    private settingService: SettingService,
    private downloadService: DownloadService,
    private sessionService: SessionService,
    public translateService: TranslateService,
    public renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private staticDataService: StaticDataService,
    private dialog: DialogService
  ) {
    this.translationServiceTerms = ['SelfServicePayslip.HelpSwitchCompanies'];
  }

  public ngOnInit(): void {
    if (this.isActive) {
      this.settingService.showModuleMessage('SelfService.Payslip');
      this.loadSalaryStatements();
      this.getCompanies();
    }
  }

  public onClickOutside(event: any): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      if (
        event.target &&
        event.target.classList &&
        event.target.classList.length === 3 &&
        event.target.classList[0] === 'SelfServicePayslipContainer'
      ) {
        this.removeItemSelection();
        this.previewClicked = false;
      }

      if (this.sessionService.browser.isHybridApp) {
        window.screen.orientation.unlock();
      }
    }
  }

  public onTableKeydown(event: any): void {
    if (!this.salaryStatements || this.salaryStatements.length === 0) {
      return;
    }

    if (event && event.keyCode === 38) {
      // Up arrow
      if (this.currentIndex === 0) {
        return;
      }
      this.onViewPreviousPayslipClicked();
    }

    if (event && event.keyCode === 40) {
      // Down arrow
      if (this.currentIndex === this.salaryStatements.length - 1) {
        return;
      }

      this.onViewNextPayslipClicked();
    }
  }

  public downloadSalaryStatementsPreview(salaryStatement: ISalaryStatement): void {
    const format: string = !this.isCordovaApp ? 'a4phtmlstring' : 'htmlstring';
    this.dataService.SalaryStatements_GetHtml(salaryStatement.Id, format).subscribe((html: any) => {
      this.showSalaryStatementsPreviewDialog(html);
    });
  }

  public onPayslipSelected(item: any, index: number): void {
    if (this.isCordovaApp) {
      this.dataService.SalaryStatements_GetHtml(item.salaryStatement.Id, 'htmlstring').subscribe((html: any) => {
        this.showSalaryStatementsPreviewDialog(html);
      });

      return;
    }

    this.currentIndex = index;
    if (item.isSelected) {
      // Close preview
      item.isSelected = false;
      this.previewClicked = false;
    }

    this.currentId = item.salaryStatement.Id;

    // Opened on the side and auto closed when resize to isUntilXlargeScreen
    if (this.isUntilXLargeScreen && this.previewClicked) {
      // Close payslip review
      this.previewClicked = true;
      item.isSelected = true;
      this.dataService.SalaryStatements_GetHtml(item.salaryStatement.Id, 'a4phtmlstring').subscribe((html: any) => {
        this.onOpenPayslipNewTab(undefined, item.salaryStatement.Id);
      });
    }

    this.htmlContent = '';
    this.removeItemSelection();

    if (this.isUntilXLargeScreen) {
      this.dataService.SalaryStatements_GetHtml(item.salaryStatement.Id, 'a4phtmlstring').subscribe((html: any) => {
        this.onOpenPayslipNewTab(undefined, item.salaryStatement.Id);
      });
    } else {
      item.isSelected = true;
      this.showSlip(item, true);
    }
  }

  public onClosePayslipClicked(): void {
    this.haveSalaryStatements = false;
    this.removeItemSelection();
    this.previewClicked = false;
  }

  public onViewNextPayslipClicked(): void {
    this.htmlContent = '';
    if (this.salaryStatements[this.currentIndex + 1]) {
      this.salaryStatements[this.currentIndex].isSelected = false;
      this.currentIndex++;
      this.salaryStatements[this.currentIndex].isSelected = true;
      this.showSlip(this.salaryStatements[this.currentIndex], true);
    }

    this.resetNavigationButtons();
  }

  public onViewPreviousPayslipClicked(): void {
    this.htmlContent = '';
    if (this.salaryStatements[this.currentIndex - 1]) {
      this.salaryStatements[this.currentIndex].isSelected = false;
      this.currentIndex--;
      this.salaryStatements[this.currentIndex].isSelected = true;
      this.showSlip(this.salaryStatements[this.currentIndex], true);
    }

    this.resetNavigationButtons();
  }

  public onOpenPayslipNewTab(event?: any, id?: number): void {
    if (!id) {
      id = this.currentId;
    }

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.getPayslipReportByToken(id, 'html');
  }

  public onDowloadShownPayslip(): void {
    const current: any = this.salaryStatements[this.currentIndex];
    const currentStatementId: number = current ? current.salaryStatement.Id : undefined;
    this.onDownloadPayslip(undefined, currentStatementId);
  }

  public onDownloadPayslip(event: any, salaryStatementId: number): void {
    if (salaryStatementId) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (this.isCordovaApp || this.sessionService.browser.isIE || this.sessionService.browser.isEdge) {
        this.dataService.SalaryStatements_GetPdf(salaryStatementId).subscribe((promiseValue: any): void => {
          this.downloadService.download('Payslip.pdf', promiseValue);
        });
      } else {
        this.getPayslipReportByToken(salaryStatementId, 'a4ppdf');
      }
    }
  }

  private showSalaryStatementsPreviewDialog(html: any): void {
    this.htmlContent = html;
    if (this.isEdge) {
      this.iframeContentValue = html;
      this.contentLoaded = false;
    } else {
      this.safeContent = this.sanitizer.bypassSecurityTrustHtml(this.htmlContent);
    }

    this.dialog.showIFrameDialog(this.htmlContent);
    // this.paySlipPreviewDialog = true;
  }

  private getPayslipReportByToken(salaryStatementId: number, format: string): void {
    this.dataService.SalaryStatements_GetPayslipDownloadToken(salaryStatementId).subscribe((token: any): void => {
      const languageCode: string = this.sessionService.LanguageCode;
      const downloadUrl = `/api/salarystatements/${salaryStatementId}/token/${token}/lang/${languageCode}/format/${format}/Payslip`;
      let newWindow: Window = null;
      newWindow = window.open(environment.apiUrl + downloadUrl);

      if (!newWindow) {
        this.newTabBlockedDialogVisible = true;
      }
    });
  }

  private removeItemSelection(): void {
    if (this.salaryStatements) {
      this.salaryStatements.forEach((value: any): void => {
        if (value.isSelected === true) {
          value.isSelected = false;
        }
      });
    }
  }

  private showSlip(item: any, clicked: boolean): void {
    this.previewClicked = true;
    this.dataService.SalaryStatements_GetHtml(item.salaryStatement.Id, 'a4phtmlstring').subscribe((html: any) => {
      this.htmlContent = html;
      if (this.isEdge) {
        this.iframeContentValue = html;
        this.contentLoaded = false;
      } else {
        this.safeContent = this.sanitizer.bypassSecurityTrustHtml(this.htmlContent);
      }
    });
    this.resetNavigationButtons();
  }

  private resetNavigationButtons(): void {
    this.previousBtnDisabled = this.salaryStatements[this.currentIndex - 1] ? false : true;
    this.nextBtnDisabled = this.salaryStatements[this.currentIndex + 1] ? false : true;
    this.haveSalaryStatements = true;
  }

  public haveSalaryStatements = false;
  private loadSalaryStatements(): void {
    this.dataService
      .SalaryStatements_GetSalaryStatementsCurrentCompanyUser()
      .subscribe((statements: ISalaryStatement[]): void => {
        if (statements) {
          statements.forEach((item: ISalaryStatement): void => {
            const extendedItem: any = { salaryStatement: item, isSelected: false };
            this.salaryStatements.push(extendedItem);
            this.haveSalaryStatements = true;
          });
        }

        this.showFirstSalaryStatement();
      });
  }

  private showFirstSalaryStatement(): void {
    if (this.salaryStatements.length > 0 && this.isAboveXLargeScreen) {
      this.onPayslipSelected(this.salaryStatements[0], 0);
    }
  }

  private getCompanies(): void {
    this.staticDataService.companiesSimple.subscribe((data: ISimpleCompany[]) => {
      this.companies = data;
      if (!Global.COMPANY) {
        this.getCurrentCompany();
      } else {
        this.currentCompany = JSON.parse(JSON.stringify(Global.COMPANY));
      }
    });
  }

  companiesState: Subscription;
  private getCurrentCompany(): void {
    if (this.companiesState) {
      this.companiesState.unsubscribe();
    }
    this.companiesState = this.dataService.Companies_GetCurrent().subscribe((activeCompany: ICompany): void => {
      this.currentCompany = activeCompany;
      this.enableTranslation();
    });
  }

  public enableTranslation(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateService.get(this.translationServiceTerms).subscribe((translations: { [key: string]: string }) => {
        this.setTranslationTerms(translations);
      });
    });
  }

  public selfservicepayslipNote: string;
  public setTranslationTerms(translations: { [key: string]: string }): void {
    this.selfservicepayslipNote = translations['SelfServicePayslip.HelpSwitchCompanies'];
    if (this.currentCompany) {
      this.selfservicepayslipNote = this.selfservicepayslipNote.replace('$var$', this.currentCompany.Name);
    }
  }

  public onPrintPayslipClicked(): void {
    const current: any = this.salaryStatements[this.currentIndex];
    const salaryStatementId: number = current ? current.salaryStatement.Id : undefined;

    this.dataService.SalaryStatements_GetPayslipDownloadToken(salaryStatementId).subscribe((token: any): void => {
      const languageCode: string = this.sessionService.LanguageCode;
      const fileName = Math.random()
        .toString(36)
        .substring(7);
      this.dataService
        .SalaryStatements_DownloadPayslipByToken(
          salaryStatementId,
          token,
          languageCode,
          'printa4phtmlstaticstring',
          fileName
        )
        .subscribe((data: any) => {
          this.dialog.showIFrameDialog(data, ' ', true);
          this.dialog.isBindding = false;
        });
    });
  }

  private iframeContentValue: string;
  public onLoadIframe() {
    if (this.isEdge && this.iframeContentValue && !this.contentLoaded) {
      // https://stackoverflow.com/questions/42257549/angular-2-srcdoc-polyfill-doesnt-work
      srcDoc.set(this.iframe.nativeElement, this.iframeContentValue);
      this.contentLoaded = true;
    }

    if (
      this.iframe && // Not add css when iframe close or iframe content is null
      this.iframe.nativeElement &&
      this.iframe.nativeElement.contentWindow &&
      this.iframe.nativeElement.contentWindow.document
    ) {
      const iframeContentWindow = this.iframe.nativeElement.contentWindow.document || null;
      const content = iframeContentWindow.getElementsByClassName('payslip');
      if (content && content.length > 0) {
        content[0].style.cssText = 'margin-top: 1.5rem;';
      }
    }
  }

  public onClickButton(value: string, event?: any, item?: any) {
    if (this.sessionService.isGetting || this.sessionService.isSubmitting) {
      return;
    }
    switch (value) {
      case 'open':
        this.onOpenPayslipNewTab();
        break;
      case 'download':
        this.onDowloadShownPayslip();
        break;
      case 'print':
        this.onPrintPayslipClicked();
        break;
      case 'close':
        this.onClosePayslipClicked();
        break;
      case 'next':
        this.onViewNextPayslipClicked();
        break;
      case 'previous':
        this.onViewPreviousPayslipClicked();
        break;
      case 'downloadStatement':
        this.downloadSalaryStatementsPreview(item);
        break;
      case 'openPayslip':
        this.onOpenPayslipNewTab(event, item);
        break;
      case 'downloadPayslip':
        this.onDownloadPayslip(event, item);
        break;
      case 'select':
        this.onPayslipSelected(event, item);
        break;
    }
  }
}
