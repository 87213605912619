import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { Global } from '../../common/global';
import { GridHelper } from '../../common/grid-helper';
import { SalaryRecordCalculation } from '../../employee/employee-tab/payroll/controls/salary-record-calculation';
import { UnitTypeEnum } from '../../model/enum';
import { GetSalaryRecordsOverview_Result } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { CompanyService } from '../../services/company.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/api/data.service";
import * as i2 from "../../services/api/static-data.service";
import * as i3 from "../../services/setting.service";
import * as i4 from "../../services/session/session.service";
import * as i5 from "@uirouter/core";
import * as i6 from "../../services/broadcast.service";
var CompanyPayrollDataService = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyPayrollDataService, _super);
    function CompanyPayrollDataService(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) {
        var _this = _super.call(this, dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) || this;
        _this.dataService = dataService;
        _this.staticDataService = staticDataService;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.transitionService = transitionService;
        _this.broadcaster = broadcaster;
        _this.ngUnsubscribe = new Subject();
        _this.onverviewIndexList = [];
        _this.original = new GetSalaryRecordsOverview_Result();
        _this.salaryTypes = [];
        _this.availableSalaryTypes = [];
        _this.componentStateChange = new EventEmitter();
        return _this;
    }
    CompanyPayrollDataService.prototype.ngOnDestroy = function () {
        this.onDestroyLocalVariable();
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    CompanyPayrollDataService.prototype.onDestroyLocalVariable = function () {
        if (this.gridDataSubject) {
            this.gridDataSubject.next([]);
        }
    };
    Object.defineProperty(CompanyPayrollDataService.prototype, "salaryCycleId", {
        get: function () {
            return this.salaryCycleIdValue;
        },
        set: function (value) {
            if (value !== this.salaryCycleIdValue) {
                this.salaryCycleIdValue = value;
                this.getData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyPayrollDataService.prototype, "templateId", {
        get: function () {
            return this.templateIdValue;
        },
        set: function (value) {
            if (value !== this.templateIdValue) {
                this.templateIdValue = value;
                this.getData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyPayrollDataService.prototype, "departmentId", {
        get: function () {
            return this.departmentIdValue;
        },
        set: function (value) {
            if (value !== this.departmentIdValue) {
                this.departmentIdValue = value;
                this.getData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyPayrollDataService.prototype, "showEmployeeWithNoData", {
        get: function () {
            return this.showEmployeeWithNoDataValue;
        },
        set: function (value) {
            if (value !== this.showEmployeeWithNoDataValue) {
                this.showEmployeeWithNoDataValue = value;
                this.getData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyPayrollDataService.prototype, "gridData", {
        get: function () {
            if (!this.gridDataSubject) {
                this.gridDataSubject = new BehaviorSubject([]);
            }
            return this.gridDataSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyPayrollDataService.prototype, "allSalaryTypes", {
        get: function () {
            if (!this.allSalaryTypesSubject) {
                this.allSalaryTypesSubject = new BehaviorSubject([]);
            }
            return this.allSalaryTypesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyPayrollDataService.prototype, "salaryCycle", {
        get: function () {
            if (!this.salaryCycleSubject) {
                this.loadSalaryCycle();
            }
            return this.salaryCycleSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyPayrollDataService.prototype, "templates", {
        get: function () {
            if (!this.templatesSubject) {
                this.loadTemplates();
            }
            return this.templatesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CompanyPayrollDataService.prototype.loadTemplates = function () {
        var _this = this;
        if (!this.templatesSubject) {
            this.templatesSubject = new BehaviorSubject([]);
        }
        this.dataService
            .EmploymentTemplates_GetEmploymentTemplates(false)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (templates) {
            _this.templatesSubject.next(templates);
        });
    };
    CompanyPayrollDataService.prototype.loadSalaryCycle = function () {
        var _this = this;
        if (!this.salaryCycleSubject) {
            this.salaryCycleSubject = new BehaviorSubject([]);
        }
        this.dataService
            .Miscellaneous_GetUsedSalaryCycles()
            .pipe(takeUntil(this.ngUnsubscribe), tap(function (data) {
            _this.salaryCycleSubject.next(data);
        }))
            .subscribe(function (data) {
            _this.getSalaryTypes();
        }, function () {
            _this.onCloseOverlayDone();
        });
    };
    CompanyPayrollDataService.prototype.getSalaryTypes = function () {
        var _this = this;
        this.dataService
            .SalaryTypes_GetRawSalaryTypesTranslated()
            .pipe(takeUntil(this.ngUnsubscribe), tap(function (salaryTypes) {
            salaryTypes.forEach(function (salaryType) {
                _this.assignSalaryTypeText(salaryType, salaryTypes);
            });
            _this.availableSalaryTypes = salaryTypes.filter(function (s) { return s.IsVisibleValue && s.IsActiveValue; });
            _this.salaryTypes = _this.availableSalaryTypes.filter(function (s) { return s.OverviewIndex || s.OverviewIndex === 0; });
            _this.salaryTypes = _this.salaryTypes.sort(function (a, b) {
                return GridHelper.sortByNumberValue(a.OverviewIndex, b.OverviewIndex);
            });
            _this.salaryTypes = _this.salaryTypes.slice(0, 7);
            _this.allSalaryTypesSubject.next(salaryTypes);
        }))
            .subscribe();
    };
    CompanyPayrollDataService.prototype.closeOverlay = function () {
        var _this = this;
        return Observable.create(function (ob) {
            _this.isWattingLoading = false;
            setTimeout(function () {
                _this.componentStateChange.emit(true);
                ob.next();
            });
        });
    };
    CompanyPayrollDataService.prototype.onCloseOverlayDone = function () {
        this.closeOverlay()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe();
    };
    CompanyPayrollDataService.prototype.assignSalaryTypeText = function (salaryType, salaryTypes) {
        var existingSalaryType = salaryTypes.find(function (s) { return s.Id === salaryType.Id; });
        salaryType.SalaryTypeTranslations = salaryType.SalaryTypeTranslations
            ? salaryType.SalaryTypeTranslations
            : existingSalaryType
                ? existingSalaryType.SalaryTypeTranslations
                : undefined;
        salaryType.BaseSalaryType = salaryType.BaseSalaryType
            ? salaryType.BaseSalaryType
            : existingSalaryType
                ? existingSalaryType.BaseSalaryType
                : undefined;
        salaryType.IsActiveValue =
            salaryType.IsActive !== null
                ? salaryType.IsActive
                : salaryType.BaseSalaryType
                    ? salaryType.BaseSalaryType.IsActive
                    : false;
        salaryType.IsVisibleValue =
            salaryType.IsVisible !== null
                ? salaryType.IsVisible
                : salaryType.BaseSalaryType
                    ? salaryType.BaseSalaryType.IsVisible
                    : false;
        if (!salaryType.SalaryTypeTranslations) {
            return;
        }
        if (Global.SESSION.SignOnToken.LanguageId === 1) {
            salaryType.Name =
                salaryType.SalaryTypeTranslations[0].Name && this.hasLanguageModule
                    ? salaryType.SalaryTypeTranslations[0].Name
                    : salaryType.Name;
        }
        else if (Global.SESSION.SignOnToken.LanguageId === 2) {
            salaryType.Name =
                salaryType.SalaryTypeTranslations[1].Name && this.hasLanguageModule
                    ? salaryType.SalaryTypeTranslations[1].Name
                    : salaryType.Name;
        }
        else if (Global.SESSION.SignOnToken.LanguageId === 3) {
            salaryType.Name =
                salaryType.SalaryTypeTranslations[2].Name && this.hasLanguageModule
                    ? salaryType.SalaryTypeTranslations[2].Name
                    : salaryType.Name;
        }
        salaryType.displayName =
            salaryType.Name !== null
                ? salaryType.Name
                : salaryType.BaseSalaryType
                    ? salaryType.BaseSalaryType.Name
                    : undefined;
    };
    CompanyPayrollDataService.prototype.saveCurrent = function (dataItem, property) {
        var _this = this;
        if (!dataItem || !property) {
            return;
        }
        var index = property
            .replace('SalaryRecord', '')
            .replace('PerUnit', '')
            .replace('Units', '')
            .replace('Amount', '');
        if (index === undefined) {
            return;
        }
        index = parseInt(index, 10);
        var formGroupValue = this.formGroup.value[property];
        var updatedValue = dataItem[property];
        if (formGroupValue !== updatedValue) {
            var salaryRecordId = dataItem["SalaryRecord" + index + "Id"];
            var calculator = this.createSalaryRecordCalculation({
                Units: dataItem["SalaryRecord" + index + "Units"],
                AmountPerUnit: dataItem["SalaryRecord" + index + "PerUnit"],
                Amount: dataItem["SalaryRecord" + index + "Amount"]
            });
            var request = this.createSalaryRecordUpdateRequest(calculator, property, updatedValue);
            dataItem["SalaryRecord" + index + "Units"] = request.Units;
            dataItem["SalaryRecord" + index + "PerUnit"] = request.AmountPerUnit;
            dataItem["SalaryRecord" + index + "Amount"] = request.Amount;
            if (salaryRecordId) {
                request.Id = salaryRecordId;
                this.dataService.SalaryRecords_UpdateSalaryRecordSimple(request).subscribe(function () { }, function () {
                    _this.getSalaryTypes();
                });
            }
            else {
                request.UserEmploymentId = this.original.UserEmploymentId;
                request.SalaryTypeId = this.salaryTypes[index - 1].Id;
                this.dataService.SalaryRecords_CreateSalaryRecordSimple(request).subscribe(function (data) {
                    dataItem["SalaryRecord" + index + "Id"] = data.Id;
                }, function () {
                    _this.getSalaryTypes();
                });
            }
        }
    };
    CompanyPayrollDataService.prototype.createSalaryRecordUpdateRequest = function (calculator, property, value) {
        value = value && value !== 0 ? value : undefined;
        var isAmountPerUnitProperty = property.indexOf('PerUnit') > -1;
        var isUnitsProperty = !isAmountPerUnitProperty && property.indexOf('Units') > -1;
        var isAmountProperty = !isAmountPerUnitProperty && property.indexOf('Amount') > -1;
        var calculationResult;
        if (isAmountPerUnitProperty) {
            calculationResult = this.updateAmountPerUnit(calculator, value);
        }
        if (isUnitsProperty) {
            calculationResult = this.updateUnits(calculator, value);
        }
        if (isAmountProperty) {
            calculationResult = this.updateAmount(calculator, value);
        }
        var updateSalaryRecord = {
            Id: 0,
            AmountPerUnit: this.formatCorrectNumberForRequest(calculationResult.amountPerUnit),
            Amount: this.formatCorrectNumberForRequest(calculationResult.amount),
            Units: this.formatCorrectNumberForRequest(calculationResult.units)
        };
        return updateSalaryRecord;
    };
    CompanyPayrollDataService.prototype.formatCorrectNumberForRequest = function (value) {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return null;
        }
        return value;
    };
    CompanyPayrollDataService.prototype.createSalaryRecordCalculation = function (dataItem) {
        var calculator = new SalaryRecordCalculation();
        calculator.Amount = dataItem.Amount;
        calculator.Units = dataItem.Units;
        calculator.AmountPerUnit = dataItem.AmountPerUnit;
        calculator.UnitType = UnitTypeEnum.Days;
        return calculator;
    };
    CompanyPayrollDataService.prototype.updateAmountPerUnit = function (calculator, value) {
        calculator.AmountPerUnit = value;
        calculator.calculateAmount();
        calculator.calculateUnits();
        return {
            amountPerUnit: value,
            amount: calculator.Amount,
            units: calculator.Units
        };
    };
    CompanyPayrollDataService.prototype.updateAmount = function (calculator, value) {
        calculator.Amount = value;
        calculator.calculateUnits();
        return {
            amountPerUnit: calculator.AmountPerUnit,
            amount: calculator.Amount,
            units: calculator.Units
        };
    };
    CompanyPayrollDataService.prototype.updateUnits = function (calculator, value) {
        calculator.Units = value;
        calculator.calculateAmount();
        return {
            amountPerUnit: calculator.AmountPerUnit,
            amount: calculator.Amount,
            units: calculator.Units
        };
    };
    CompanyPayrollDataService.prototype.createFormGroup = function (dataItem) {
        return new FormGroup({
            FullName: new FormControl(dataItem ? dataItem.FullName : undefined),
            DepartmentName: new FormControl(dataItem ? dataItem.DepartmentName : undefined),
            MultipleRecordsExist1: new FormControl(dataItem ? dataItem.MultipleRecordsExist1 : undefined),
            SalaryRecord1Units: new FormControl(dataItem ? dataItem.SalaryRecord1Units : undefined),
            SalaryRecord1PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord1PerUnit : undefined),
            SalaryRecord1Amount: new FormControl(dataItem ? dataItem.SalaryRecord1Amount : undefined),
            MultipleRecordsExist2: new FormControl(dataItem ? dataItem.MultipleRecordsExist2 : undefined),
            SalaryRecord2Units: new FormControl(dataItem ? dataItem.SalaryRecord2Units : undefined),
            SalaryRecord2PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord2PerUnit : undefined),
            SalaryRecord2Amount: new FormControl(dataItem ? dataItem.SalaryRecord2Amount : undefined),
            MultipleRecordsExist3: new FormControl(dataItem ? dataItem.MultipleRecordsExist3 : undefined),
            SalaryRecord3Units: new FormControl(dataItem ? dataItem.SalaryRecord3Units : undefined),
            SalaryRecord3PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord3PerUnit : undefined),
            SalaryRecord3Amount: new FormControl(dataItem ? dataItem.SalaryRecord3Amount : undefined),
            MultipleRecordsExist4: new FormControl(dataItem ? dataItem.MultipleRecordsExist4 : undefined),
            SalaryRecord4Units: new FormControl(dataItem ? dataItem.SalaryRecord4Units : undefined),
            SalaryRecord4PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord4PerUnit : undefined),
            SalaryRecord4Amount: new FormControl(dataItem ? dataItem.SalaryRecord4Amount : undefined),
            MultipleRecordsExist5: new FormControl(dataItem ? dataItem.MultipleRecordsExist5 : undefined),
            SalaryRecord5Units: new FormControl(dataItem ? dataItem.SalaryRecord5Units : undefined),
            SalaryRecord5PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord5PerUnit : undefined),
            SalaryRecord5Amount: new FormControl(dataItem ? dataItem.SalaryRecord5Amount : undefined),
            MultipleRecordsExist6: new FormControl(dataItem ? dataItem.MultipleRecordsExist6 : undefined),
            SalaryRecord6Units: new FormControl(dataItem ? dataItem.SalaryRecord6Units : undefined),
            SalaryRecord6PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord6PerUnit : undefined),
            SalaryRecord6Amount: new FormControl(dataItem ? dataItem.SalaryRecord6Amount : undefined),
            MultipleRecordsExist7: new FormControl(dataItem ? dataItem.MultipleRecordsExist7 : undefined),
            SalaryRecord7Units: new FormControl(dataItem ? dataItem.SalaryRecord7Units : undefined),
            SalaryRecord7PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord7PerUnit : undefined),
            SalaryRecord7Amount: new FormControl(dataItem ? dataItem.SalaryRecord7Amount : undefined)
        });
    };
    CompanyPayrollDataService.prototype.getData = function () {
        var _this = this;
        if (!this.gridDataSubject) {
            this.gridDataSubject = new BehaviorSubject([]);
        }
        var filterObject = {
            SalaryCycleId: this.salaryCycleId ? this.salaryCycleId : undefined,
            EmploymentTemplateId: this.templateId ? this.templateId : undefined,
            DepartmentId: this.departmentId ? this.departmentId : undefined,
            IncludeEmployeesWithNoData: this.showEmployeeWithNoData
        };
        this.dataService
            .SalaryRecords_GetSalaryRecordsOverviewFiltered(filterObject)
            .pipe(takeUntil(this.ngUnsubscribe), tap(function (data) {
            _this.gridDataSubject.next(data);
        }))
            .subscribe();
    };
    CompanyPayrollDataService.prototype.SalaryTypes_ConfigureOverviewBehavior = function (request) {
        var _this = this;
        return this.dataService
            .SalaryTypes_ConfigureOverviewBehavior(request.salaryTypeId, request.overviewIndex, request.overviewDisplayMode)
            .pipe(tap(function (data) {
            _this.getSalaryTypes();
        }));
    };
    Object.defineProperty(CompanyPayrollDataService.prototype, "locked", {
        get: function () {
            return this.salaryTypes && this.salaryTypes.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyPayrollDataService.prototype, "hasLanguageModule", {
        get: function () {
            return this.sessionService.feature.hasModuleId(5);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyPayrollDataService.prototype, "allowShortcut", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.payrolldata';
        },
        enumerable: true,
        configurable: true
    });
    CompanyPayrollDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyPayrollDataService_Factory() { return new CompanyPayrollDataService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.StaticDataService), i0.ɵɵinject(i3.SettingService), i0.ɵɵinject(i4.SessionService), i0.ɵɵinject(i5.TransitionService), i0.ɵɵinject(i6.BroadcastService)); }, token: CompanyPayrollDataService, providedIn: "root" });
    return CompanyPayrollDataService;
}(CompanyService));
export { CompanyPayrollDataService };
