/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../node_modules/@ngx-progressbar/core/ngx-progressbar-core.ngfactory";
import * as i2 from "@ngx-progressbar/core";
import * as i3 from "./custom-controls/icon/icon-definitions.component.ngfactory";
import * as i4 from "./custom-controls/icon/icon-definitions.component";
import * as i5 from "../../node_modules/@uirouter/angular/lib/directives/uiView.ngfactory";
import * as i6 from "@uirouter/angular/lib/directives/uiView";
import * as i7 from "@uirouter/core";
import * as i8 from "./app.component";
import * as i9 from "@angular/platform-browser";
import * as i10 from "./services/session/session.service";
import * as i11 from "./services/api/static-data.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-progress", [["id", "appProgress"], ["role", "progressbar"]], [[1, "spinnerPosition", 0], [1, "dir", 0], [1, "thick", 0], [1, "fixed", 0]], null, null, i1.View_NgProgressComponent_0, i1.RenderType_NgProgressComponent)), i0.ɵdid(1, 770048, null, 0, i2.NgProgressComponent, [i2.NgProgress], { id: [0, "id"], color: [1, "color"], thick: [2, "thick"], spinner: [3, "spinner"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-icon-definitions", [], null, null, null, i3.View_IconDefinitionsComponent_0, i3.RenderType_IconDefinitionsComponent)), i0.ɵdid(3, 49152, null, 0, i4.IconDefinitionsComponent, [], null, null), (_l()(), i0.ɵeld(4, 16777216, null, null, 1, "div", [["ui-view", ""]], null, null, null, i5.View_UIView_0, i5.RenderType_UIView)), i0.ɵdid(5, 245760, null, 0, i6.UIView, [i7.UIRouter, "UIView.PARENT_INJECT", i0.ViewContainerRef], { _name: [0, "_name"] }, null)], function (_ck, _v) { var currVal_4 = "appProgress"; var currVal_5 = "#4350a3"; var currVal_6 = true; var currVal_7 = false; _ck(_v, 1, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = ""; _ck(_v, 5, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).spinnerPosition; var currVal_1 = i0.ɵnov(_v, 1).direction; var currVal_2 = i0.ɵnov(_v, 1).thick; var currVal_3 = i0.ɵnov(_v, 1).fixed; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 4308992, null, 0, i8.AppComponent, [i9.Title, i0.Renderer2, i10.SessionService, i11.StaticDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
