import { EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompanySalaryBatchService } from '../company-salary-batches.service';
var BatchFinalizationStep3DialogComponent = /** @class */ (function () {
    function BatchFinalizationStep3DialogComponent(companySalaryBatchService) {
        var _this = this;
        this.companySalaryBatchService = companySalaryBatchService;
        this.ngUnsubscribe = new Subject();
        this.autorecalculate = false;
        this.doRecalculationChange = new EventEmitter();
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.stepCompletedChange = new EventEmitter();
        this.finalizationExit = new EventEmitter();
        this.recalculateBeforeFinalizing = false;
        this.companySalaryBatchService.selectedSalaryBatch
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            _this.salaryBatch = value;
        });
    }
    BatchFinalizationStep3DialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(BatchFinalizationStep3DialogComponent.prototype, "isClose", {
        get: function () {
            return this.visible;
        },
        set: function (value) {
            if (value) {
                this.visible = false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep3DialogComponent.prototype, "doRecalculation", {
        get: function () {
            return this.doRecalculationValue;
        },
        set: function (value) {
            var _this = this;
            if (this.doRecalculationValue !== value) {
                this.doRecalculationValue = value;
                setTimeout(function () { return _this.doRecalculationChange.emit(value); });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep3DialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            var _this = this;
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                setTimeout(function () { return _this.visibleChange.emit(value); });
                if (value) {
                    var draftSalaryBatch = this.salaryBatch.StatusId === 10 || this.salaryBatch.StatusId === 20;
                    var finalizedSalaryBatch = this.salaryBatch.StatusId === 40;
                    if (finalizedSalaryBatch) {
                        this.visibleValue = false;
                        return;
                    }
                    else {
                        this.doRecalculation = true;
                        this.stepCompleted = true;
                        this.visibleValue = false;
                    }
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep3DialogComponent.prototype, "stepCompleted", {
        // @Input()
        get: function () {
            return this.stepCompletedValue;
        },
        set: function (value) {
            var _this = this;
            if (this.stepCompletedValue !== value) {
                setTimeout(function () { return _this.stepCompletedChange.emit(value); });
            }
        },
        enumerable: true,
        configurable: true
    });
    BatchFinalizationStep3DialogComponent.prototype.onDialogAction = function (action) {
        if (action === 'Proceed') {
            this.doRecalculation = this.recalculateBeforeFinalizing;
            this.stepCompleted = true;
        }
        else {
            this.finalizationExit.emit();
        }
    };
    return BatchFinalizationStep3DialogComponent;
}());
export { BatchFinalizationStep3DialogComponent };
