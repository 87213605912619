<app-grid
  #advancedTypesList
  [data]="types"
  [editMode]="true"
  (cellClick)="onCellClick($event)"
  class="Company-types-list"
  [idColumn]="'Id'"
  [(selectedId)]="selectedId"
  [(triggerUpdate)]="triggerGridRefresh"
  (selectedItemChange)="onSelectionChange($event)"
  (saveChangesEvent)="onSaveName()"
  [ngClass]="{ 'grid-block-click': companyAdvancedTypesService.editMode }"
>
  <app-grid-column
    [field]="'Name'"
    [viewClass]="'truncated'"
    [type]="'text'"
    [title]="'CompanyTimeEntryTypes.Name' | translate"
    [headerTooltip]="
      type === 'Salary'
        ? ('CompanySalaryTypes.NameHeaderTooltip' | translate)
        : ('CompanyTimeEntryTypes.NameTooltip' | translate)
    "
    [tooltipField]="'Name'"
    [editable]="!companyAdvancedTypesService.isLanguageModuleEnable && companyAdvancedTypesService.editMode"
    [width]="330"
  >
  </app-grid-column>
</app-grid>
