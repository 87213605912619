<div class="FormElement">
  <app-grid
    #templateList
    [data]="templates"
    [editMode]="false"
    [(isDisableSelect)]="isEditMode"
    [companyTemplates]="true"
    class="FormElement-grid Company-templateGrid {{ classDisableSelect }}"
    [idColumn]="'Id'"
    [(selectedId)]="selectedId"
    [(triggerUpdate)]="triggerGridRefresh"
    (selectedItemChange)="onSelectionChange($event)"
  >
    <app-grid-column
      [classField]="'isActiveFeild'"
      [field]="'Name'"
      [type]="'text'"
      [title]="'CompanyHiringStatus.TemplateGridTitle' | translate"
    ></app-grid-column>
  </app-grid>
</div>
