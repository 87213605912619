<fieldset class="Form--horizontal">
  <legend class="Card-title">{{ 'Company.ExternalIntegrationsCredential' | translate }}</legend>
  <div *ngFor="let control of integrationsService.credentialControl">
    <app-text-edit
      [(isDirty)]="integrationsService.tabDirty"
      [label]="control.name"
      [editMode]="integrationsService.editMode"
      [(value)]="control.value"
      [tooltipContent]="control.Description"
    >
    </app-text-edit>
  </div>
</fieldset>
