import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Constants } from '../../common/constants';
import { FormComponentBase } from '../../common/form-component-base';
import { Global } from '../../common/global';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { PensionProviderModel } from './pension-provider-model';
var PensionProviderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PensionProviderComponent, _super);
    function PensionProviderComponent(sessionService, staticDataService) {
        var _this = _super.call(this) || this;
        _this.sessionService = sessionService;
        _this.staticDataService = staticDataService;
        _this.disable = false;
        _this.numericOptions = {
            format: 'n0',
            decimals: 0,
            step: 0,
            min: null,
            spinners: false
        };
        _this.modelChange = new EventEmitter();
        _this.showPensionProvider = false;
        _this.childIsDirty = Array(12).fill(false);
        _this.childIsValid = Array(2).fill(true);
        _this.editModeChange.subscribe(function (value) {
            if (_this.model) {
                _this.updateDefaultComponentValue();
            }
        });
        return _this;
    }
    Object.defineProperty(PensionProviderComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "model", {
        get: function () {
            if (!this.modelValue) {
                this.modelValue = {};
            }
            return this.modelValue;
        },
        set: function (value) {
            this.modelValue = value;
            this.updateDefaultComponentValue();
            this.modelChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    PensionProviderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.PensionProvider.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.pensionProviders = data;
            _this.updateDefaultComponentValue();
        });
        this.staticDataService.PensionBaseCalculationMethod.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (baseCalculationMethods) {
            _this.pensionData = baseCalculationMethods.filter(function (pension) {
                return pension.IsPensionBase;
            });
            if (_this.pensionData.length > 0 && !_this.PensionBaseCalculationMethodId) {
                _this.PensionBaseCalculationMethodId = 1;
            }
        });
    };
    Object.defineProperty(PensionProviderComponent.prototype, "isHaveDueDate", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.company.configuration');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "isEmployeePension", {
        get: function () {
            return this.model && this.model.HireDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "isNoPensionTextHidden", {
        get: function () {
            return this.editMode || this.showPensionProvider;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "isShowBankInfo", {
        get: function () {
            return this.SelectedPensionProvider && this.SelectedPensionProvider.AccountNumberRequired;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "isOverridePolicyNo", {
        get: function () {
            return this.SelectedPensionProvider && this.SelectedPensionProvider.PolicyNumberLabelOverride;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "isDanishCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "isVisiblePensionTakenSalary", {
        get: function () {
            if (this.isDanishCompany &&
                (!this.model.IncomeTypeId ||
                    (this.model.IncomeTypeId && this.model.IncomeTypeId !== 8 && this.model.IncomeTypeId !== 99))) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "isAddButtonHidden", {
        get: function () {
            return (!this.editMode ||
                this.showPensionProvider ||
                (this.isEmployeePension && !this.sessionService.feature.AllowEditUserEmployment(this.sessionService.currentState)));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "SelectedPensionProvider", {
        get: function () {
            var _this = this;
            var pensionProvider = this.pensionProviders
                ? this.pensionProviders.find(function (ppd) {
                    return ppd.Id === _this.pensionProviderId;
                })
                : undefined;
            return pensionProvider;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "pensionTaken", {
        get: function () {
            return this.firstPension ? this.model.PensionIsNotTaxDeductible : this.model.Pension2IsNotTaxDeductible;
        },
        set: function (value) {
            if (this.firstPension) {
                this.model.PensionIsNotTaxDeductible = value;
            }
            else {
                this.model.Pension2IsNotTaxDeductible = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "pensionProviderId", {
        get: function () {
            return this.firstPension ? this.model.PensionProviderId : this.model.Pension2ProviderId;
        },
        set: function (value) {
            value = value ? this.sessionService.parseInt(value.toString()) : null;
            if (this.firstPension) {
                this.model.PensionProviderId = value;
                this.model.Pension2ProviderId = this.model.Pension2ProviderId || null;
            }
            else {
                this.model.PensionProviderId = this.model.PensionProviderId || null;
                this.model.Pension2ProviderId = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "PensionProviderBankRegNo", {
        get: function () {
            return this.firstPension ? this.model.PensionProviderBankRegNo : this.model.Pension2ProviderBankRegNo;
        },
        set: function (value) {
            if (this.firstPension) {
                this.model.PensionProviderBankRegNo = value;
            }
            else {
                this.model.Pension2ProviderBankRegNo = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "PensionProviderBankAccountNo", {
        get: function () {
            return this.firstPension ? this.model.PensionProviderBankAccountNo : this.model.Pension2ProviderBankAccountNo;
        },
        set: function (value) {
            if (this.firstPension) {
                this.model.PensionProviderBankAccountNo = value;
            }
            else {
                this.model.Pension2ProviderBankAccountNo = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "PensionStartDate", {
        get: function () {
            return this.firstPension ? this.model.PensionStartDate : this.model.Pension2StartDate;
        },
        set: function (value) {
            if (this.firstPension) {
                this.model.PensionStartDate = value;
            }
            else {
                this.model.Pension2StartDate = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "PensionEndDate", {
        get: function () {
            return this.firstPension ? this.model.PensionEndDate : this.model.Pension2EndDate;
        },
        set: function (value) {
            if (this.firstPension) {
                this.model.PensionEndDate = value;
            }
            else {
                this.model.Pension2EndDate = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "PensionFixedAmountIsPerDay", {
        get: function () {
            return this.firstPension ? this.model.PensionFixedAmountIsPerDay : this.model.Pension2FixedAmountIsPerDay;
        },
        set: function (value) {
            if (this.firstPension) {
                this.model.PensionFixedAmountIsPerDay = value;
            }
            else {
                this.model.Pension2FixedAmountIsPerDay = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "PensionBaseCalculationMethodId", {
        get: function () {
            return this.firstPension ? this.model.PensionBaseCalculationMethodId : this.model.Pension2BaseCalculationMethodId;
        },
        set: function (value) {
            value = value ? this.sessionService.parseInt(value.toString()) : undefined;
            if (this.firstPension) {
                this.model.PensionBaseCalculationMethodId = value;
            }
            else {
                this.model.Pension2BaseCalculationMethodId = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "PensionEmployeeShare", {
        get: function () {
            return this.firstPension ? this.model.PensionEmployeeShare : this.model.Pension2EmployeeShare;
        },
        set: function (value) {
            if (this.firstPension) {
                this.model.PensionEmployeeShare = value;
            }
            else {
                this.model.Pension2EmployeeShare = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "PensionCompanyShare", {
        get: function () {
            return this.firstPension ? this.model.PensionCompanyShare : this.model.Pension2CompanyShare;
        },
        set: function (value) {
            if (this.firstPension) {
                this.model.PensionCompanyShare = value;
            }
            else {
                this.model.Pension2CompanyShare = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "PensionEmployeeAmount", {
        get: function () {
            return this.firstPension ? this.model.PensionEmployeeAmount : this.model.Pension2EmployeeAmount;
        },
        set: function (value) {
            if (this.firstPension) {
                this.model.PensionEmployeeAmount = value;
            }
            else {
                this.model.Pension2EmployeeAmount = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "PensionCompanyAmount", {
        get: function () {
            return this.firstPension ? this.model.PensionCompanyAmount : this.model.Pension2CompanyAmount;
        },
        set: function (value) {
            if (this.firstPension) {
                this.model.PensionCompanyAmount = value;
            }
            else {
                this.model.Pension2CompanyAmount = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "PensionPolicyNumber", {
        get: function () {
            return this.firstPension ? this.model.PensionPolicyNumber : this.model.Pension2PolicyNumber;
        },
        set: function (value) {
            if (this.firstPension) {
                this.model.PensionPolicyNumber = value;
            }
            else {
                this.model.Pension2PolicyNumber = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PensionProviderComponent.prototype, "PensionDueDateDelayDays", {
        get: function () {
            return this.firstPension ? this.model.Pension1DueDateDelayDays : this.model.Pension2DueDateDelayDays;
        },
        set: function (value) {
            if (this.firstPension) {
                this.model.Pension1DueDateDelayDays = value;
            }
            else {
                this.model.Pension2DueDateDelayDays = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    PensionProviderComponent.prototype.updateDefaultComponentValue = function () {
        var _this = this;
        this.showPensionProvider = this.model && !!this.pensionProviderId;
        var pensionProvider = this.pensionProviders
            ? this.pensionProviders.find(function (pension) { return pension.Id === _this.pensionProviderId; })
            : undefined;
        this.PensionProviderNetsNo = pensionProvider ? pensionProvider.NetsNo : undefined;
    };
    PensionProviderComponent.prototype.onPensionProviderChanged = function () {
        this.enablePensionProviderValues();
        this.onChange();
    };
    PensionProviderComponent.prototype.enablePensionProviderValues = function () {
        var _this = this;
        setTimeout(function () {
            var pensionProvider = _this.pensionProviders
                ? _this.pensionProviders.find(function (pension) { return pension.Id === _this.pensionProviderId; })
                : undefined;
            _this.PensionProviderNetsNo = pensionProvider ? pensionProvider.NetsNo : undefined;
            if (pensionProvider) {
                if (_this.firstPension) {
                    _this.model.PensionBaseCalculationMethodId = pensionProvider.DefaultBaseCalculationMethodId;
                }
                else {
                    _this.model.Pension2BaseCalculationMethodId = pensionProvider.DefaultBaseCalculationMethodId;
                }
            }
        });
    };
    PensionProviderComponent.prototype.onAddFirstPensionProvider = function () {
        if (!this.editMode || this.disable) {
            return;
        }
        this.showPensionProvider = true;
    };
    return PensionProviderComponent;
}(FormComponentBase));
export { PensionProviderComponent };
