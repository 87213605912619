import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../common/form-component-base';
import { Global } from '../common/global';
import { ILanguage } from '../services/api/api-model';
import { StaticDataService } from '../services/api/static-data.service';
import { SettingService } from '../services/setting.service';

@Component({
  selector: 'app-language-selector-dialog',
  templateUrl: './language-selector-dialog.component.html'
})
export class LanguageSelectorDialogComponent extends FormComponentBase implements OnInit {
  private visibleValue: boolean;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public languageChanged: EventEmitter<{ languageId: number }> = new EventEmitter<{ languageId: number }>();

  public languages: any;
  public languageId: number;
  public changeLanguageId: number;

  constructor(private staticDataService: StaticDataService, public settingService: SettingService) {
    super();
  }

  public ngOnInit(): void {
    this.languageId = Global.SESSION ? Global.SESSION.SignOnToken.LanguageId : undefined;
    this.staticDataService.languages.pipe(takeUntil(this.ngUnsubscribe)).subscribe((languages: ILanguage[]) => {
      this.languages =
        languages && languages.length
          ? languages.filter((language: ILanguage) => language.Id !== this.languageId)
          : undefined;
    });
  }

  public raiseLanguageChange(languageId: number): void {
    this.changeLanguageId = languageId;
    this.settingService.ReloadWarrning = true;
    this.languageChanged.emit({ languageId: this.changeLanguageId });
    this.visible = false;
  }
}
