<app-fixed-panel [excludedComponents]="listClassExcluded" [specificClassName]="'Island-employee--payroll'">
  <app-employee-info
    [companyUser]="employeeService.employee | async"
    [currentPositionId]="employeeService.selectedEmploymentId | async"
    [isEmployeeTab]="true"
    (expandUserInfoEmitter)="onExpand($event)"
  ></app-employee-info>

  <div
    class="Island Island-employee--payroll"
    *ngIf="!employeeService.isNonSelected"
    [ngClass]="{ 'is-expand': expanded }"
  >
    <div *ngIf="!hasUserEmployment">
      <div class="Card">
        <div class="Card-inner withOnlyButton">
          <h2 class="Card-title">{{ 'EmploymentPayrollData.NoEmployment' | translate }}</h2>
          <!-- GS-5298
        <app-button
          [buttonClass]="'Button--primary'"
          *ngIf="allowEdit && !isAddNewEmploymentHide"
          (click)="createUserEmployment()"
          [label]="'EmployeeGeneral.CreateEmployment' | translate"
        ></app-button>
        -->
          <app-button
            [buttonClass]="'Button--primary'"
            *ngIf="allowEdit"
            (clickEvent)="createUserEmployment()"
            [label]="'EmployeeGeneral.CreateEmployment' | translate"
          ></app-button>
        </div>
      </div>
    </div>

    <!-- GS-5298 <span class="EmployeeWarning">{{ noCPRMessage | translate }} </span> -->

    <div *ngIf="hasUserEmployment && !isMobile">
      <div class="{{ ClassCardStack }}">
        <app-payroll-data-grid
          [(isHidePreviewGrid)]="isHidePreviewGrid"
          class="Card Employee-payrollDataCard"
          [(recalculate)]="recalculate"
          [userEmployment]="userEmployment"
          (update)="onUpdate()"
        ></app-payroll-data-grid>

        <app-payroll-preview
          [isHidePreviewGrid]="isHidePreviewGrid"
          class="Card Employee-payrollDataPreviewCard"
          [userEmployment]="userEmployment"
          [(recalculatePreview)]="recalculate"
          [isUpdate]="isUpdate"
        >
        </app-payroll-preview>
      </div>

      <div class="payroll-data-button-container">
        <app-button
          class="Button--card"
          (clickEvent)="viewFullSalaryBatch()"
          [label]="'EmploymentPayrollData.ViewFullSalaryBatch' | translate"
        ></app-button>
        <app-button
          class="Button--card"
          (clickEvent)="viewLastPayslip()"
          [label]="'EmploymentPayrollData.ViewLastPayslip' | translate"
        ></app-button>
      </div>
    </div>

    <div *ngIf="hasUserEmployment && isMobile">
      <app-employee-payroll-data-mobile
        [userEmployment]="userEmployment"
        [(isAddNew)]="isAddNew"
        [(isUpdate)]="isUpdate"
      >
      </app-employee-payroll-data-mobile>
    </div>
  </div>

  <div class="NonSelected" *ngIf="employeeService.isNonSelected && sessionService.role.IsSalaryAdminAndHigher">
    <div class="Card">
      <div class="Card-inner">
        <app-button
          buttonClass="Button--primary"
          (clickEvent)="createNewEmployeeDialogVisible = true"
          [label]="'Employee.NewEmployee' | translate"
        ></app-button>
      </div>
    </div>
  </div>
</app-fixed-panel>

<div class="Actions" *ngIf="!isMobile">
  <app-action-button
    *ngIf="sessionService.role.IsSalaryAdminAndHigher"
    id="ActionButtonAdd"
    [icon]="'AddUser'"
    [label]="'Employee.New'"
    [tooltip]="'Employee.NewEmployee'"
    [buttonClass]="'Action-button Action-buttonAdd'"
    (click)="createNewEmployeeDialogVisible = true"
  ></app-action-button>

  <app-menu-button
    *ngIf="!sessionService.role.IsReadOnly && !employeeService.isNonSelected"
    [menuIcon]="'ThreeDots'"
    [menuLabel]="'More.Title'"
    [menuTooltip]="'More.Title'"
    [multiMenuItems]="true"
  >
    <app-menu-button-item (menuItemClick)="importInitialBalancesClick()" [field]="'ImportInitialBalances'">
    </app-menu-button-item>
    <app-menu-button-item (menuItemClick)="importDataClick()" [field]="'ImportData'"></app-menu-button-item>
  </app-menu-button>

  <app-menu-button [menuIcon]="'Help'" [menuLabel]="'Help.Title'" [menuTooltip]="'Help.Title'" [multiMenuItems]="true">
    <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
    <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
  </app-menu-button>

  <app-action-button
    *ngIf="sessionService.browser.isMobile && !sessionService.role.IsReadOnly && hasUserEmployment"
    [ngClass]="{ disabledActionButtonClass: this.sessionService.isGetting }"
    [buttonClass]="'Action-button Action-buttonAdd'"
    [icon]="'Plus'"
    [label]="'Employee.New'"
    (click)="isAddNew = true"
    [tooltip]="'Employee.NewEmployee'"
    [disabled]="sessionService.isGetting"
  ></app-action-button>

  <app-action-button
    *ngIf="reportDialogService.isVisibleReportIcon && !employeeService.isNonSelected"
    buttonClass="Action-button"
  ></app-action-button>
</div>

<div class="Actions" *ngIf="isMobile">
  <app-menu-button
    *ngIf="!sessionService.role.IsReadOnly && hasUserEmployment"
    (menuClick)="onAddNewItem()"
    [menuIcon]="'Add'"
    [menuLabel]="'EmploymentTime.AddNewRecord'"
    [menuTooltip]="'EmploymentTime.AddNewRecord'"
    [actionButtonMenu]="true"
    [menuButtonClass]="'Action-button Action-buttonAdd'"
    [menuButtonId]="'ActionButtonAddPayroll'"
    [menuCondition]="true"
  >
  </app-menu-button>
  <app-menu-button
    [menuIcon]="'ThreeDots'"
    [menuLabel]="'More.Title'"
    [menuTooltip]="'More.Title'"
    [multiMenuItems]="true"
  >
    <app-menu-button-item
      *ngIf="sessionService.role.IsSalaryAdminAndHigher"
      (menuItemClick)="createNewEmployeeDialogVisible = true"
      [field]="'AddNewEmployee'"
    ></app-menu-button-item>
    <app-menu-button-item
      *ngIf="!sessionService.role.IsReadOnly && !employeeService.isNonSelected"
      (menuItemClick)="importInitialBalancesClick()"
      [field]="'ImportInitialBalances'"
    >
    </app-menu-button-item>
    <app-menu-button-item
      *ngIf="!sessionService.role.IsReadOnly && !employeeService.isNonSelected"
      (menuItemClick)="importDataClick()"
      [field]="'ImportData'"
    ></app-menu-button-item>
    <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
    <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
  </app-menu-button>
</div>

<app-new-employee-dialog [(visible)]="createNewEmployeeDialogVisible"></app-new-employee-dialog>

<!-- <app-report-dialog [(visible)]="reportDialogVisible" [key]="'PayrollData'" [param]="reportParams"></app-report-dialog> -->

<app-dialog
  class="blue-dialog"
  *ngIf="!isCordovaApp"
  [(visible)]="newTabBlockedDialogVisible"
  [leadingText]="'Report.NewTabBlocked' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="noPayslipDialogVisible"
  [leadingText]="'EmployeePayslip.NoPayslip' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog class="PayslipPopup" [(visible)]="previewDialogVisible" [iframeContent]="previewContent"></app-dialog>

<app-new-employment-dialog [(isVisible)]="newEmploymentDialogVisible" [currentCompanyUserId]="currentCompanyUser?.Id">
</app-new-employment-dialog>
