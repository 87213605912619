<app-dialog
  [overlayClose]="false"
  (action)="onDialogAction($event)"
  [(visible)]="visible"
  [title]="'CompanySalaryTypes.EditSalaryTypeTranslationHeader' | translate"
>
  <app-module-check [moduleId]="5">
    <div
      class="u-trailer"
      [innerHTML]="'CompanySalaryTypes.TranslationBrief' | translate: { SystemAlias: branding.SystemAlias }"
    ></div>
    <app-grid
      [(data)]="gridData"
      [triggerUpdate]="triggerUpdate"
      (saveChangesEvent)="onSaveChange($event)"
      [editMode]="!this.IsReadOnly"
    >
      <app-grid-column
        [field]="'LanguageId'"
        [title]="'CompanySalaryTypes.Language' | translate"
        [width]="100"
        [type]="'combobox'"
        [comboboxDataSource]="staticDataService.languages | async"
        [editable]="false"
        [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
        [comboboxValueField]="'Id'"
        [comboboxDisplayField]="'Name'"
        [CurrentTextComboboxToolTip]="false"
      ></app-grid-column>
      <app-grid-column
        [field]="'Name'"
        [editable]="!IsReadOnly"
        [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
        [classField]="'NameClass'"
        [title]="'CompanySalaryTypes.Name' | translate"
        [width]="150"
        [type]="'text'"
        [tooltipField]="'Name'"
      ></app-grid-column>
      <app-grid-column
        [field]="'Description'"
        [editable]="!IsReadOnly"
        [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
        [classField]="'DescriptionClass'"
        [title]="'CompanySalaryTypes.Description' | translate"
        [width]="150"
        [type]="'text'"
        [tooltipField]="'Description'"
      ></app-grid-column>
      <app-grid-column
        [field]="'DefaultPayslipText'"
        [editable]="!IsReadOnly"
        [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
        [classField]="'DefaultPayslipTextClass'"
        [title]="'CompanySalaryTypes.PayslipText' | translate"
        [width]="150"
        [type]="'text'"
        [tooltipField]="'DefaultPayslipText'"
      ></app-grid-column>
      <app-grid-column
        [field]="'Explanation'"
        [editable]="!IsReadOnly"
        [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
        [classField]="'ExplanationClass'"
        [title]="'CompanySalaryTypes.Explanation' | translate"
        [width]="150"
        [type]="'text'"
        [tooltipField]="'Explanation'"
      ></app-grid-column>
    </app-grid>
  </app-module-check>

  <app-dialog-action [type]="'Close'" *ngIf="IsReadOnly"></app-dialog-action>
  <app-dialog-action [type]="'Cancel'" *ngIf="!IsReadOnly"></app-dialog-action>
  <app-dialog-action
    [type]="'Update'"
    *ngIf="!IsReadOnly"
    [disabled]="!childIsDirty[0]"
    [close]="false"
  ></app-dialog-action>
</app-dialog>
