import { OnDestroy, OnInit } from '@angular/core';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from 'src/app/common/constants';
import { Global } from 'src/app/common/global';
import { IExternalAccount, ISalaryType, IUnitType } from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { SettingService } from 'src/app/services/setting.service';
import { ReportDialogService } from 'src/app/shared-components/report-dialog/report-dialog.service';
import { SalaryTypeView } from './salary-type-view';
import { CompanySalaryTypeService } from './salary-types.service';
var SalaryTypesComponent = /** @class */ (function () {
    function SalaryTypesComponent(dataService, staticDataService, sessionService, settingService, reportDialogService, companySalaryTypeService) {
        var _this = this;
        this.dataService = dataService;
        this.staticDataService = staticDataService;
        this.sessionService = sessionService;
        this.settingService = settingService;
        this.reportDialogService = reportDialogService;
        this.companySalaryTypeService = companySalaryTypeService;
        this.gridData = [];
        this.unitTypes = Global.UNIT_TYPES;
        this.externalReferences = [];
        this.hasSalaryTypeModule = false;
        this.isShowWarrningChangeMode = false;
        this.triggerUpdate = false;
        this.currentGridRadioAction = 1;
        this.lastMode = 1;
        this.simpleMode = false;
        this.completeMode = false;
        this.ngUnsubscribe = new Subject();
        this.gridFilterData = this.gridData;
        this.companySalaryTypeService.addNewShortcutChange
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && !_this.sessionService.role.IsReadOnly) {
                _this.onAddClick();
            }
        });
        this.companySalaryTypeService.showReportShortcutChange
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && _this.reportDialogService.isVisibleReportIcon) {
                _this.reportDialogService.onShowReportsEventClick();
            }
        });
        this.companySalaryTypeService.salaryTypes.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.salaryTypes = data;
            _this.gridData = [];
            _this.gridData = _this.companySalaryTypeService.staticSalaryTypes;
            _this.gridFilterData = filterBy(_this.gridData, _this.filter);
            _this.convertExternalRefCodeToFriendlyName();
        });
    }
    Object.defineProperty(SalaryTypesComponent.prototype, "gridRadioAction", {
        get: function () {
            return this.currentGridRadioAction;
        },
        set: function (value) {
            var _this = this;
            if (value !== this.currentGridRadioAction) {
                this.currentGridRadioAction = value;
                if (value !== 3) {
                    this.companySalaryTypeService.isWattingLoading = true;
                    this.lastMode = value;
                    this.triggerUpdate = true;
                    setTimeout(function () {
                        _this.ModeActive();
                    });
                }
                else {
                    this.isShowWarrningChangeMode = true;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    SalaryTypesComponent.prototype.loadStaticData = function () {
        this.staticDataService.FinanceAccountType.subscribe();
        this.staticDataService.SalarySummaryCategory.subscribe();
        this.staticDataService.SalaryRecordPersistenceType.subscribe();
        this.staticDataService.SalaryTypeCategory.subscribe();
        this.staticDataService.DimensionRedistributionBehavior.subscribe();
    };
    SalaryTypesComponent.prototype.onCompletemodeChange = function (event) {
        if (event === 'Ok') {
            this.companySalaryTypeService.isWattingLoading = true;
            this.lastMode = 3;
            this.triggerUpdate = true;
        }
        else {
            this.currentGridRadioAction = this.lastMode;
        }
        this.ModeActive();
    };
    SalaryTypesComponent.prototype.ModeActive = function () {
        this.simpleMode = this.lastMode === 1;
        this.completeMode = this.lastMode === 3;
    };
    Object.defineProperty(SalaryTypesComponent.prototype, "isFromDenmark", {
        get: function () {
            return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.DENMARK_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryTypesComponent.prototype, "isFromGreenland", {
        get: function () {
            return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryTypesComponent.prototype, "isFromSwedish", {
        get: function () {
            return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.SWEDEN_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    SalaryTypesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.ModeActive();
        this.loadStaticData();
        this.dataService.Integrations_GetAllExternalAccounts().subscribe(function (data) {
            _this.externalReferences = data;
            if (_this.externalReferences && _this.externalReferences.length > 0) {
                _this.externalReferences.forEach(function (model) {
                    model.FriendlyName = model.AccountNumber + (model.AccountName ? ' - ' + model.AccountName : '');
                });
                _this.convertExternalRefCodeToFriendlyName();
            }
        });
        this.companySalaryTypeService.initData();
        this.settingService.showModuleMessage('company.advancedsalarytypes');
        if (this.companySalaryTypeService.isMobile) {
            this.sessionService.isShowHugeFeaturesAlert = true;
        }
    };
    Object.defineProperty(SalaryTypesComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    SalaryTypesComponent.prototype.ngOnDestroy = function () {
        this.companySalaryTypeService.serviceDestroy();
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    SalaryTypesComponent.prototype.saveChanges = function (value) {
        var _this = this;
        value.dataItem.ExternalReference = this.getExteralReferenceCode(value.dataItem.ExternalReference, true);
        var salaryType = value.dataItem.toSalaryType();
        salaryType.ExternalReference = this.getExteralReferenceCode(salaryType.ExternalReference) || null;
        this.dataService.SalaryTypes_UpdateSalaryType(salaryType).subscribe(function (data) {
            data.ExternalReference = _this.getExteralReferenceCode(data.ExternalReference, true);
            _this.transformSalaryTypeObject(data);
            Object.assign(value.dataItem, new SalaryTypeView(data, _this.companySalaryTypeService.isLanguageModuleEnable));
        }, function (error) { return _this.getSalaryTypes(); });
    };
    SalaryTypesComponent.prototype.onAddClick = function () {
        this.newSalaryTypeDialogVisible = true;
    };
    SalaryTypesComponent.prototype.onAddSalaryType = function (value) {
        this.salaryTypes.push(value);
        this.transformSalaryTypeObject(value);
        this.gridData.push(new SalaryTypeView(value, this.companySalaryTypeService.isLanguageModuleEnable));
        this.gridFilterData = filterBy(this.gridData, this.filter);
        this.selectedId = value.Id;
    };
    SalaryTypesComponent.prototype.onCellClick = function (event) {
        if (['Name', 'Description', 'DefaultPayslipText', 'Explanation'].includes(event.field) &&
            this.companySalaryTypeService.isLanguageModuleEnable) {
            this.translationSalaryType = event.dataItem;
            this.translationDialogVisible = true;
        }
    };
    SalaryTypesComponent.prototype.getSalaryTypes = function () {
        var _this = this;
        this.gridData = [];
        this.companySalaryTypeService
            .loadSalaryTypes()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function () {
            _this.companySalaryTypeService.isWattingLoading = false;
        }, function () {
            _this.companySalaryTypeService.isWattingLoading = false;
        });
    };
    SalaryTypesComponent.prototype.transformSalaryTypeObject = function (salaryType) {
        var existingSalaryType = this.salaryTypes.find(function (s) { return s.Id === salaryType.Id; });
        salaryType.SalaryTypeTranslations = salaryType.SalaryTypeTranslations
            ? salaryType.SalaryTypeTranslations
            : existingSalaryType
                ? existingSalaryType.SalaryTypeTranslations
                : undefined;
        salaryType.BaseSalaryType = salaryType.BaseSalaryType
            ? salaryType.BaseSalaryType
            : existingSalaryType
                ? existingSalaryType.BaseSalaryType
                : undefined;
    };
    SalaryTypesComponent.prototype.convertExternalRefCodeToFriendlyName = function () {
        var _this = this;
        if (this.gridData && this.gridData.length > 0 && this.externalReferences && this.externalReferences.length > 0) {
            this.gridData.forEach(function (salaryType) {
                salaryType.ExternalReference = _this.getExteralReferenceCode(salaryType.ExternalReference, true);
            });
            this.gridFilterData = filterBy(this.gridData, this.filter);
        }
    };
    SalaryTypesComponent.prototype.getExteralReferenceCode = function (refCode, fullName) {
        if (!refCode) {
            return null;
        }
        var externalRefCode = this.externalReferences
            ? this.externalReferences.find(function (item) {
                return refCode === item.AccountNumber || refCode === item.FriendlyName;
            })
            : null;
        if (externalRefCode) {
            return !fullName ? externalRefCode.AccountNumber : externalRefCode.FriendlyName;
        }
        return refCode;
    };
    SalaryTypesComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        this.gridFilterData = filterBy(this.gridData, filter);
    };
    SalaryTypesComponent.prototype.onMoreMenuOptionClick = function (action) {
        switch (action) {
            case 'salarytypenew':
                this.onAddClick();
                break;
        }
    };
    return SalaryTypesComponent;
}());
export { SalaryTypesComponent };
