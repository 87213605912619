import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IModuleCompanyView } from '../../../services/api/api-model';
import { StaticDataService } from '../../../services/api/static-data.service';
import { SessionService } from '../../../services/session/session.service';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html'
})
export class BenefitsComponent implements OnInit, OnDestroy {
  public modules: IModuleCompanyView[] = ([] = []);
  public isEmployeeBenefitModuleActive = false;

  constructor(private staticDataService: StaticDataService, public sessionService: SessionService) {}

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get isIOSApp(): boolean {
    return this.sessionService.browser.isHybridApp && this.sessionService.browser.iOSMobileDevice;
  }

  public ngOnInit(): void {
    this.staticDataService.moduleCompanyView
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: IModuleCompanyView[]) => {
        this.modules = JSON.parse(JSON.stringify(data));
        if (this.modules && this.modules.length > 0) {
          const benefitModule: IModuleCompanyView = this.modules.find(
            (model: IModuleCompanyView) => model.ModuleId === 6
          );
          if (benefitModule) {
            this.isEmployeeBenefitModuleActive = benefitModule.IsEnabled;
          }
        }
      });
  }
}
