import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Constants } from '../../../common/constants';
import { FormComponentBase } from '../../../common/form-component-base';
import { Global } from '../../../common/global';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { SessionService } from '../../../services/session/session.service';
import { CompanyGeneralService } from '../company-general.service';
var CompanyOptionComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyOptionComponent, _super);
    function CompanyOptionComponent(companyGeneralService, dataService, sessionService, staticDataService) {
        var _this = _super.call(this) || this;
        _this.companyGeneralService = companyGeneralService;
        _this.dataService = dataService;
        _this.sessionService = sessionService;
        _this.staticDataService = staticDataService;
        _this.resetChildFromChange = new EventEmitter();
        _this.hasStatisticModule = false;
        _this.hasLanguageModule = false;
        _this.hasPaymentModule = false;
        _this.isStatisticsChange = false;
        _this.childIsDirty = Array(11).fill(false);
        return _this;
    }
    Object.defineProperty(CompanyOptionComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyOptionComponent.prototype, "company", {
        get: function () {
            return this.companyValue;
        },
        set: function (value) {
            this.companyValue = value;
            if (value) {
                this.componentStatisticsValue = this.company.StatisticsRecipientId;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyOptionComponent.prototype, "resetChildFrom", {
        set: function (value) {
            if (value) {
                this.childIsDirty = Array(11).fill(false);
                this.childIsValid = Array(11).fill(true);
                this.resetChildFromChange.emit(false);
            }
        },
        enumerable: true,
        configurable: true
    });
    CompanyOptionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.PaymentMethod.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.paymentMethods = data); });
        this.staticDataService.languages.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.languages = data;
        });
        this.staticDataService.StatisticsRecipient.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (statisticOptions) {
            _this.statisticOptions = statisticOptions;
        });
        this.staticDataService.Bank.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (bankOption) {
            if (_this.company && _this.company.CountryId && bankOption && bankOption.length > 0) {
                _this.bankOption = bankOption.filter(function (model) { return model.CountryId === _this.company.CountryId; });
            }
        });
        this.dataService.StaticData_GetIndustryOrganizations().subscribe(function (data) {
            _this.IndustryOrganization = data;
        });
    };
    CompanyOptionComponent.prototype.StatisticsIconClick = function () {
        window.open('https://support.gratisal.com/hc/da/articles/213857065', '_blank');
    };
    CompanyOptionComponent.prototype.onStatisticsChange = function (val) {
        if (!this.company.StatisticsRecipientId && val) {
            this.isStatisticsChange = true;
        }
        this.company.StatisticsRecipientId = val;
        this.onChange();
    };
    Object.defineProperty(CompanyOptionComponent.prototype, "isAdminUser", {
        get: function () {
            return this.sessionService.role.IsFullAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyOptionComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyOptionComponent.prototype, "IsDanishCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyOptionComponent.prototype, "IsSwedenCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyOptionComponent.prototype, "isAccountAdmin", {
        get: function () {
            return Global.SESSION && Global.SESSION.IsAccountAdmin;
        },
        enumerable: true,
        configurable: true
    });
    CompanyOptionComponent.prototype.onAdditionalOptionClick = function () {
        this.sessionService.NavigateTo('tabs.company.configuration.settings');
    };
    return CompanyOptionComponent;
}(FormComponentBase));
export { CompanyOptionComponent };
