<div [ngClass]="{ 'is-editing': editMode }">
  <div class="EmployeeInfo Form--compact">
    <!-- EmployeeInfo-avatar -->
    <div class="Avatar Avatar--circle EmployeeInfo-avatar">
      <app-employee-avatar [editMode]="editMode"></app-employee-avatar>
    </div>
    <div *ngIf="!employeeService.isNonSelected" class="EmployeeInfoDetails">
      <app-employee-name
        class="EmployeeInfo-row"
        [companyUser]="companyUser"
        [editMode]="editMode"
        [(isValid)]="childIsValid[0]"
        [(isDirty)]="childIsDirty[0]"
        (valueChange)="onChange()"
      ></app-employee-name>

      <div class="EmployeeInfo-row EmployeeInfo-rowDetails" [ngClass]="{ 'is-active': expandUserInfoDetails }">
        <!-- CPR -->
        <app-cpr-info
          [editMode]="editMode && allowEdit"
          *ngIf="!EmployeeWithRoleAsManager"
          [dummyIdentityNumber]="companyUser.HasDummyIdentityNumber"
          [(value)]="companyUser.UserIdentityNumber"
          [(isValid)]="childIsValid[1]"
          [(isDirty)]="childIsDirty[1]"
          (valueChange)="onChange()"
        ></app-cpr-info>

        <app-text-edit
          *ngIf="!editMode && companyUser.Initials"
          [label]="'Employee.Initials' | translate"
          [editMode]="false"
          [type]="'text'"
          [(value)]="companyUser.Initials"
        ></app-text-edit>

        <!-- Role -->
        <app-combobox-edit
          [label]="'Employee.Role' | translate"
          [editMode]="editMode && allowEditRole"
          [comboboxDataSource]="staticDataService.Role | async"
          [(value)]="companyUser.RoleId"
          [textField]="'Name'"
          [idField]="'Id'"
          [(isValid)]="childIsValid[2]"
          [(isDirty)]="childIsDirty[2]"
          (valueChange)="onChange()"
        ></app-combobox-edit>

        <!-- Title -->
        <app-position-info
          [editMode]="editMode && allowEdit"
          [companyUser]="companyUser"
          [currentPositionId]="currentPositionId"
          [isEmployeeTab]="isEmployeeTab"
          [(isValid)]="childIsValid[3]"
          [(isDirty)]="childIsDirty[3]"
          (positionTextChanged)="onChange()"
          (positionIdChanged)="onEmploymentChange()"
        ></app-position-info>

        <!-- Language -->
        <app-module-check [moduleId]="5" [(hasModule)]="hasLanguageModule">
          <app-combobox-edit
            [label]="'NewEmployee.Language' | translate"
            [disable]="!hasLanguageModule"
            [editMode]="editMode && (allowEdit || isGeneralTabSelfService)"
            [comboboxDataSource]="staticDataService.languages | async"
            [(value)]="companyUser.UserLanguageId"
            [textField]="'Name'"
            [idField]="'Id'"
            [(isValid)]="childIsValid[4]"
            [(isDirty)]="childIsDirty[4]"
            (valueChange)="onChange()"
          ></app-combobox-edit>
        </app-module-check>

        <!-- Payment approver -->
        <div
          *ngIf="showIsPaymentApprover"
          class="FormElement CheckBoxPadding"
          [ngClass]="{ 'FormElement-ViewMode': !editMode }"
        >
          <div class="FormElement-control">
            <label class="FormElement-checkbox">
              <input
                type="checkbox"
                [(ngModel)]="companyUser.IsPaymentApprover"
                checked="companyUser.IsPaymentApprover"
                [disabled]="!editMode || !allowEditRole"
                (click)="onPaymentApproverClick()"
                (ngModelChange)="onChange()"
              />
              <span class="FormElement-label">{{ 'Employee.IsPaymentApprover' | translate }}:</span>
              <span *ngIf="!editMode" class="FormElement-label">
                <span *ngIf="companyUser.IsPaymentApprover">{{ 'Employee.Yes' | translate }}</span>
                <span *ngIf="!companyUser.IsPaymentApprover">{{ 'Employee.No' | translate }}</span>
              </span>
              <span *ngIf="editMode" class="FormElement-checkboxFaux"></span>
            </label>
          </div>
        </div>

        <!-- Active -->
        <div class="FormElement CheckBoxPadding" [ngClass]="{ 'FormElement-ViewMode': !editMode }">
          <div class="FormElement-control">
            <label class="FormElement-checkbox">
              <input
                type="checkbox"
                [(ngModel)]="companyUser.IsActive"
                checked="companyUser.IsActive"
                [disabled]="!editMode || !allowEdit"
                (click)="onIsActiveClick()"
                (ngModelChange)="onChange()"
              />
              <span class="FormElement-label">{{ 'Employee.IsActive' | translate }}:</span>
              <span *ngIf="!editMode" class="FormElement-label">
                <span *ngIf="companyUser.IsActive">{{ 'Employee.Yes' | translate }}</span>
                <span *ngIf="!companyUser.IsActive">{{ 'Employee.No' | translate }}</span>
              </span>
              <span *ngIf="editMode" class="FormElement-checkboxFaux"></span>
            </label>
          </div>
        </div>
      </div>

      <button class="EmployeeInfo-toggleDetails" type="button" (click)="onToggleEmployeeInfoDropdown()">
        <div>
          <app-icon *ngIf="!istoogledescription" [icon]="'ChevronDown'" [size]="'xxsmall'"></app-icon>
          <app-icon *ngIf="istoogledescription" [icon]="'ChevronUp'" [size]="'xxsmall'"></app-icon>
        </div>
      </button>
    </div>
  </div>
</div>
