import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { ControlComponentBase } from '../../common/control-component-base';
import { ReflectionHelper } from '../../common/reflection-helper';

@Component({
  selector: 'app-check-edit-slide',
  templateUrl: './check-edit-slide.component.html',
  styleUrls: ['./check-edit-slide.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckEditSlideComponent extends ControlComponentBase<boolean> implements OnInit {
  @Input() public label: string;
  @Input() public tooltip = '';
  @Input() public disable: boolean;
  @Input() public helpLink: string;
  @Input() public helpTooltip = '';
  @Input() public isOpenLink = true;
  @Input() public customClass: string;
  @Input() public helpIcon = 'Help';
  @Input() public selfService = '';
  @Input() public isLink = false;
  @Input() public openLinkInTitle = '';
  @Input() public isAccountantConfig: boolean;

  public get labelClass(): string {
    return this.customClass + ' ' + (this.isGrid ? 'checkBox-label-center' : '');
  }

  public get isDisabled(): boolean {
    return this.disable ? this.disable : !this.editMode;
  }

  constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
  }

  public ngOnInit() {}

  protected valid(): boolean {
    return true;
  }

  protected convertValue(value: any): boolean {
    if (value && ReflectionHelper.isString(value)) {
      return value.toLowerCase() === 'true';
    } else {
      return value;
    }
  }

  public onOpenlinkInTitleClick(): void {
    if (this.openLinkInTitle) {
      window.open(this.openLinkInTitle);
    }
  }

  public onClick(event: any): void {}
}
