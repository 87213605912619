import { EmployeeService } from 'src/app/services/employee.service';
import { DataService } from '../../../../services/api/data.service';
import { SessionService } from '../../../../services/session/session.service';
var SalaryBatchTimeAndVacationComponent = /** @class */ (function () {
    function SalaryBatchTimeAndVacationComponent(dataService, sessionService, employeeService) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.employeeService = employeeService;
        this.unitTotalsPerEmployee = [];
        this.unitTotalsValue = [];
    }
    Object.defineProperty(SalaryBatchTimeAndVacationComponent.prototype, "SalaryBatchId", {
        get: function () {
            return this.salaryBatchIdValue;
        },
        set: function (value) {
            if (this.salaryBatchIdValue !== value) {
                this.salaryBatchIdValue = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchTimeAndVacationComponent.prototype, "UnitTotals", {
        get: function () {
            return this.unitTotalsValue;
        },
        set: function (value) {
            if (value !== this.unitTotalsValue) {
                this.unitTotalsValue = value;
                this.unitTotalsPerEmployee = [];
                if (value && value.length > 0) {
                    this.selectedUnitTotals = value[0];
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchTimeAndVacationComponent.prototype.onSelectedUnitTotalChange = function (data) {
        var _this = this;
        this.dataService
            .SalaryBatches_GetUnitTotalsByEmployee(this.SalaryBatchId, data.Key)
            .subscribe(function (perEmployee) {
            _this.unitTotalsPerEmployee = perEmployee;
        });
    };
    SalaryBatchTimeAndVacationComponent.prototype.onDoubleClick = function (data) {
        if (data && data.UserEmploymentId) {
            this.employeeService.selectEmployeeFromEmploymentId(data.UserEmploymentId);
            this.sessionService.NavigateTo('tabs.employee.payrolldata');
        }
    };
    return SalaryBatchTimeAndVacationComponent;
}());
export { SalaryBatchTimeAndVacationComponent };
