/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../custom-controls/dialogs/dialog.component.ngfactory";
import * as i2 from "../../../../custom-controls/dialogs/dialog.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../../../services/session/session.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../../custom-controls/dialogs/dialog-action.component.ngfactory";
import * as i7 from "../../../../custom-controls/dialogs/dialog-action.component";
import * as i8 from "@angular/common";
import * as i9 from "./negative-default-dialog.component";
import * as i10 from "../../../../services/api/data.service";
var styles_NegativeDefaultDialogComponent = [];
var RenderType_NegativeDefaultDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NegativeDefaultDialogComponent, data: {} });
export { RenderType_NegativeDefaultDialogComponent as RenderType_NegativeDefaultDialogComponent };
function View_NegativeDefaultDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "app-dialog", [], null, [[null, "visibleChange"], [null, "action"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.showDialog = $event) !== false);
        ad = (pd_0 && ad);
    } if (("action" === en)) {
        var pd_1 = (_co.onDialogAction($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_DialogComponent_0, i1.RenderType_DialogComponent)), i0.ɵdid(2, 49152, null, 1, i2.DialogComponent, [i3.DomSanitizer, i4.SessionService, i0.ElementRef], { width: [0, "width"], leadingText: [1, "leadingText"], visible: [2, "visible"] }, { visibleChange: "visibleChange", action: "action" }), i0.ɵqud(603979776, 1, { buttonElements: 1 }), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, 0, 1, "app-dialog-action", [["type", "Ok"]], null, null, null, i6.View_DialogActionComponent_0, i6.RenderType_DialogActionComponent)), i0.ɵdid(6, 180224, [[1, 4]], 0, i7.DialogActionComponent, [i4.SessionService], { type: [0, "type"] }, null), (_l()(), i0.ɵeld(7, 0, null, 0, 1, "app-dialog-action", [["type", "UsePositiveAmountThisTime"]], null, null, null, i6.View_DialogActionComponent_0, i6.RenderType_DialogActionComponent)), i0.ɵdid(8, 180224, [[1, 4]], 0, i7.DialogActionComponent, [i4.SessionService], { type: [0, "type"] }, null), (_l()(), i0.ɵeld(9, 0, null, 0, 1, "app-dialog-action", [["type", "DisableThisBehaviour"]], null, null, null, i6.View_DialogActionComponent_0, i6.RenderType_DialogActionComponent)), i0.ɵdid(10, 180224, [[1, 4]], 0, i7.DialogActionComponent, [i4.SessionService], { type: [0, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = 630; var currVal_1 = i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 4).transform("EmploymentPayrollData.More_Info_EnableSetting")); var currVal_2 = _co.showDialog; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "Ok"; _ck(_v, 6, 0, currVal_3); var currVal_4 = "UsePositiveAmountThisTime"; _ck(_v, 8, 0, currVal_4); var currVal_5 = "DisableThisBehaviour"; _ck(_v, 10, 0, currVal_5); }, null); }
function View_NegativeDefaultDialogComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "app-dialog", [], null, [[null, "visibleChange"], [null, "action"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.showDialog = $event) !== false);
        ad = (pd_0 && ad);
    } if (("action" === en)) {
        var pd_1 = (_co.onDialogAction($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_DialogComponent_0, i1.RenderType_DialogComponent)), i0.ɵdid(2, 49152, null, 1, i2.DialogComponent, [i3.DomSanitizer, i4.SessionService, i0.ElementRef], { width: [0, "width"], leadingText: [1, "leadingText"], visible: [2, "visible"] }, { visibleChange: "visibleChange", action: "action" }), i0.ɵqud(603979776, 2, { buttonElements: 1 }), i0.ɵpod(4, { SystemAlias: 0 }), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(6, 0, null, 0, 1, "app-dialog-action", [["type", "IKnowWhatIamDoing"]], null, null, null, i6.View_DialogActionComponent_0, i6.RenderType_DialogActionComponent)), i0.ɵdid(7, 180224, [[2, 4]], 0, i7.DialogActionComponent, [i4.SessionService], { type: [0, "type"] }, null), (_l()(), i0.ɵeld(8, 0, null, 0, 1, "app-dialog-action", [["type", "EnableAutoCorrect"]], null, null, null, i6.View_DialogActionComponent_0, i6.RenderType_DialogActionComponent)), i0.ɵdid(9, 180224, [[2, 4]], 0, i7.DialogActionComponent, [i4.SessionService], { type: [0, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = 630; var currVal_1 = i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 5).transform("EmploymentPayrollData.More_Info_DisableSetting", _ck(_v, 4, 0, _co.branding.SystemAlias))); var currVal_2 = _co.showDialog; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "IKnowWhatIamDoing"; _ck(_v, 7, 0, currVal_3); var currVal_4 = "EnableAutoCorrect"; _ck(_v, 9, 0, currVal_4); }, null); }
export function View_NegativeDefaultDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_NegativeDefaultDialogComponent_1)), i0.ɵdid(1, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NegativeDefaultDialogComponent_2)), i0.ɵdid(3, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.setting; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.setting; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_NegativeDefaultDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-negative-default-dialog", [], null, null, null, View_NegativeDefaultDialogComponent_0, RenderType_NegativeDefaultDialogComponent)), i0.ɵdid(1, 49152, null, 0, i9.NegativeDefaultDialogComponent, [i10.DataService], null, null)], null, null); }
var NegativeDefaultDialogComponentNgFactory = i0.ɵccf("app-negative-default-dialog", i9.NegativeDefaultDialogComponent, View_NegativeDefaultDialogComponent_Host_0, { setting: "setting", fieldName: "fieldName", dataItem: "dataItem", showDialog: "showDialog" }, { showDialogChange: "showDialogChange", usePositiveAmount: "usePositiveAmount", enableAutoCorrect: "enableAutoCorrect" }, []);
export { NegativeDefaultDialogComponentNgFactory as NegativeDefaultDialogComponentNgFactory };
