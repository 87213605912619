<div
  class="process-finalization"
  [ngClass]="{ 'process-active': start || complete || error, 'process-error': complete && error }"
>
  <div class="process-state">
    <!-- non-active -->
    <div class="non-active-control checkbox-group" *ngIf="!(start || complete || error)">
      <!-- <input type="checkbox" class="custom-checkbox" disabled="true" />
      <span class="custom-checkbox-span" tabindex="0"></span> -->

      <app-icon class="Checksquare" [icon]="'Osquare'"></app-icon>
    </div>

    <!-- loading -->
    <div class="loading-control" *ngIf="start && !complete && !error">
      <div class="lds-square">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <!-- done-sucess -->
    <div class="done-control checkbox-group" *ngIf="complete && !error">
      <!-- <input type="checkbox" class="custom-checkbox" checked="true" disabled="true" />
      <span class="custom-checkbox-span" tabindex="0"></span> -->

      <app-icon class="Checksquare" [icon]="'Checksquare'"></app-icon>
    </div>

    <!-- done-fail -->
    <div class="fail-control checkbox-group" *ngIf="complete && error">
      <input type="checkbox" class="custom-checkbox" checked="true" disabled="true" />
      <span class="custom-checkbox-span" tabindex="0"></span>
    </div>
  </div>

  <div class="process-title">
    {{ title }}
  </div>
</div>
