import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmployeeService } from 'src/app/services/employee.service';
import { environment } from 'src/environments/environment';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { Address, IAccount, IAccountRole, ICity, IUser, User } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-new-user-dialog',
  templateUrl: './new-user-dialog.component.html',
  styleUrls: ['./new-user-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewUserDialogComponent implements OnInit, OnDestroy {
  public noCPRWarningDialogVisible = false;
  public noEmailWarningDialogVisible = false;
  public validationErrorDialogVisible = false;
  public hasLanguageModule = false;
  public createWithoutEmail = false;
  public dateValid = true;
  public hasDepartmentEnabled = false;
  public accountName = '';
  public isShowForEmploymentTemplateId = true;
  public validationMessageKey: string;
  public noCPRNumber = false;
  public newUserRequest: User = new User();
  public requiredFirstName = false;
  public requiredIdentityNumber = false;
  public accountRoleDataSource: IAccountRole[] = [];
  public createNonEmail = false;

  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.createDefaultBlanceUser();
      this.visibleValue = value;
      this.visibleChange.emit(value);
    }
  }

  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public createUserComplete: EventEmitter<IUser> = new EventEmitter<IUser>();

  public get isGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public get isSwedenCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
  }

  public get branding() {
    return environment.branding;
  }

  constructor(
    public sessionService: SessionService,
    public staticDataService: StaticDataService,
    private employeeService: EmployeeService,
    private translateService: TranslateService,
    private dataService: DataService
  ) {}

  public ngOnInit(): void {
    this.createDefaultBlanceUser();
    this.staticDataService.City.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ICity[]) => (this.cities = data));
    this.staticDataService.AccountantRole.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IAccountRole[]) => {
      if (data && data.length > 0) {
        this.accountRoleDataSource = JSON.parse(JSON.stringify(data));
        if (this.sessionService.role.IsSalaryAdmin) {
          const adminOption = this.accountRoleDataSource.find((model: IAccountRole) => model.Key === 'Admin');
          if (adminOption) {
            this.accountRoleDataSource.splice(this.accountRoleDataSource.indexOf(adminOption), 1);
          }
        }
      }
    });
  }
  private ngUnsubscribe: Subject<{}> = new Subject();
  private cities: ICity[] = [];

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onNoCPRCheckboxClicked(): void {
    if (this.noCPRNumber) {
      this.noCPRWarningDialogVisible = true;
      this.newUserRequest.IdentityNumber = '';
    }
  }

  public createDefaultBlanceUser(): void {
    this.newUserRequest = new User();
    this.newUserRequest.Address = new Address();

    this.requiredFirstName = false;
    this.requiredIdentityNumber = false;

    this.newUserRequest.LanguageId = Global.COMPANY ? Global.COMPANY.DefaultLanguageId : 1;
    this.newUserRequest.Address.CountryId =
      Global.COMPANY && Global.COMPANY.CountryId ? Global.COMPANY.CountryId : Constants.DENMARK_COUNTRY_ID;
    this.staticDataService.AccountantRole.subscribe((data: IAccountRole[]) => {
      if (data && data.length > 0) {
        this.newUserRequest.AccountRoleId = data.find((model: IAccountRole) => model.Key === 'Basic').Id;
      }
    });
    this.newUserRequest.AccountId = Global.SESSION ? Global.SESSION.SignOnToken.UserAccountId : null;
    this.newUserRequest.IsFirstLogin = true;
    this.newUserRequest.ShowUpdateNotification = false;
    this.newUserRequest.ShowUrgentNotification = false;
    this.newUserRequest.ShowPayslipTutorial = 0;
    this.newUserRequest.UseGlobalEmail = false;
    this.newUserRequest.Created = this.staticDataService.getCurrentdate();

    this.createNonEmail = false;
    this.noCPRNumber = false;
  }

  public onPostalCodeChanged(): void {
    const postalCode = this.newUserRequest.Address.PostalCode;

    if (!postalCode) {
      return;
    }

    let city: ICity = this.cities.find((c: ICity) => {
      if (c.CountryId === this.newUserRequest.Address.CountryId && c.PostalCode) {
        const numericCode = parseInt(postalCode, 10);
        if (!isNaN(numericCode)) {
          if (c.IsRange && c.PostalCodeTo) {
            if (numericCode >= c.PostalCode && numericCode <= c.PostalCodeTo) {
              return true;
            }
          } else {
            if (numericCode === c.PostalCode) {
              return true;
            }
          }
        }
      }

      return false;
    });

    if (!city) {
      city = this.cities.find((c: ICity) => c.PostalCode && postalCode === c.PostalCode.toString());
    }

    if (city) {
      this.newUserRequest.Address.City = city.Key;
    }
  }

  public closeForm(action: string): void {
    switch (action) {
      case 'Create':
        if (!this.newUserRequest.Email) {
          this.noEmailWarningDialogVisible = true;
          this.createNonEmail = true;
          return;
        }
        this.createUser();
        break;
      case 'CreateWithoutEmails':
        this.createUser();
        break;
      default:
        this.visible = false;
        break;
    }
  }

  private createUser(): void {
    if (!this.validateInput()) {
      return;
    }
    this.dataService.Users_CreateUser(this.newUserRequest).subscribe((response: IUser) => {
      this.createUserComplete.emit(response);
      this.visible = false;
    });
  }

  private validateInput(): boolean {
    this.validationMessageKey = this.getErrorMessage();
    if (this.validationMessageKey) {
      this.validationErrorDialogVisible = true;
      return false;
    }

    return true;
  }

  private getErrorMessage(): string {
    if (!this.newUserRequest.FirstName) {
      this.requiredFirstName = true;
      return 'NewEmployee.RequiredErrorMessage';
    }

    if (!this.noCPRNumber && !this.newUserRequest.IdentityNumber) {
      this.requiredIdentityNumber = true;
      return 'NewEmployee.RequiredErrorMessage';
    }

    return '';
  }
}
