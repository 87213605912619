import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, distinct, filterBy, orderBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../common/constants';
import { FilterDateTimeGrid } from '../../../common/filter-date-time-grid';
import { FormComponentBase } from '../../../common/form-component-base';
import { Global } from '../../../common/global';
import {
  Holiday,
  HolidayRequest,
  IBank,
  ICompany,
  ICompanyHolidayView,
  ICompanyUser,
  IIndustryOrganization,
  ILanguage,
  IPaymentMethod,
  IStatisticsRecipient
} from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { SessionService } from '../../../services/session/session.service';
import { CompanyGeneralService } from '../company-general.service';

@Component({
  selector: 'app-company-holiday-setting',
  templateUrl: './company-holiday-setting.component.html',
  styleUrls: ['./company-holiday-setting.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyHolidaySettingComponent extends FormComponentBase implements OnInit, OnDestroy {
  constructor(
    public sessionService: SessionService,
    public companyGeneralService: CompanyGeneralService,
    private staticDataService: StaticDataService
  ) {
    super();
  }

  public newHolidayDate: Date;
  public isRepeatedForTenYear: boolean;
  public newHolidayDescription: string;

  public dataFilter: CompositeFilterDescriptor;
  public dataList: ICompanyHolidayView[] | any;
  public gridData: ICompanyHolidayView[] | any = filterBy(this.dataList, this.dataFilter);
  public selectedId: number;
  public triggerUpdate = false;
  public filterClear = false;
  public sortClear = false;

  @ViewChild('kendoGridComponent', { static: true }) public kendoGrid: GridComponent;

  public ngOnInit() {
    this.companyGeneralService.companyHolidayView
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: ICompanyHolidayView[] | any) => {
        let flag = 0;
        data.forEach((item: any) => {
          item.flag = flag;
          flag += 1;
        });

        data = orderBy(data, this.kendoGrid.sort);

        this.dataList = data;
        this.onGridFilterChange(this.dataFilter);

        this.selectedId = null;
        this.triggerUpdate = true;
      });
  }

  public clearGridAction(value: boolean) {
    if (!value) {
      this.filterClear = true;
      this.sortClear = true;
    }
  }

  public onGridFilterChange(filter: CompositeFilterDescriptor): void {
    this.dataFilter = filter;

    if (filter && filter.filters && filter.filters.length > 0) {
      const filterDateTimeGrid = new FilterDateTimeGrid();
      const filtersDate: any = [];

      this.dataFilter = filterDateTimeGrid.convertFilterOperator(filter, filtersDate, 'start', 'end', 'Date');
      this.gridData = filterDateTimeGrid.doFilterDate(this.dataList, filtersDate, 'start', 'end', 'Date');
      this.gridData = filterBy(this.gridData, this.dataFilter);

      if (filtersDate.length > 0) {
        [].push.apply(this.dataFilter.filters, filtersDate);
      }
    } else {
      this.gridData = this.dataList;
    }
  }

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onGridHolidaySettingButtonClicked(event: string) {
    if (event === 'AddNew') {
      this.selectedId = null;
      this.triggerUpdate = true;

      this.companyGeneralService.isVisibleAddNewHolidayDialog = true;
    }
  }

  public onDialogCreateOpen(value: boolean): void {
    if (value) {
      this.newHolidayDate = this.staticDataService.getCurrentdate();
      this.isRepeatedForTenYear = false;
      this.newHolidayDescription = '';
    }
  }

  public createNewDialogAction(event: string) {
    if (event === 'Create') {
      const holidayRequest: HolidayRequest = {
        Date: this.newHolidayDate,
        Description: this.newHolidayDescription,
        YearsToRepeat: this.isRepeatedForTenYear ? 9 : 0
      };

      this.companyGeneralService.createHoliday(holidayRequest, true);
    }
  }

  public onSaveChangeHolidayGrid(event: any) {
    if (event && event.dataItem) {
      const holidayRequest: HolidayRequest = {
        Date: event.dataItem.Date,
        Description: event.dataItem.Description,
        YearsToRepeat: 0
      };

      if (event.dataItem.ConsiderHoliday) {
        // post
        this.companyGeneralService.createHoliday(holidayRequest, false);
      } else {
        // delete
        this.companyGeneralService.deleteHoliday(holidayRequest, event.dataItem.MasterHolidayId);
      }
    }
  }

  public gridDblClick(event: any) {
    event.event.stopImmediatePropagation();
    event.event.preventDefault();
  }

  public dialogDblclick() {
    event.stopImmediatePropagation();
    event.preventDefault();
  }
}
