import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IListDataItem } from '../../../model/ilist-data-item';
import { EmployeeService } from '../../../services/employee.service';
import { SessionService } from '../../../services/session/session.service';

@Component({
  selector: 'app-sort-by',
  templateUrl: './sort-by.component.html'
})
export class SortByComponent implements OnInit, OnDestroy {
  // @Output() change: EventEmitter<number> = new EventEmitter<number>();

  // selectedId = 0;
  public sortByDataSource: IListDataItem[];

  constructor(
    public employeeService: EmployeeService,
    private translateService: TranslateService,
    private sessionService: SessionService
  ) {}

  public ngOnInit(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateSortByDataSource();
    });
    // this.translateSortByDataSource();
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private translateSortByDataSource(): void {
    this.translateService
      .get(['Employee.SortByFirstName', 'Employee.SortByLastName'])
      .subscribe((translations: { [key: string]: string }) => {
        this.sortByDataSource = [
          { Id: 1, Text: translations['Employee.SortByFirstName'] },
          { Id: 2, Text: translations['Employee.SortByLastName'] }
        ];

        // setTimeout(() => {
        //   this.selectedId = 1;
        // });
      });
  }

  // public onComboBoxChanged(): void {
  //   if (this.change && this.selectedId) {
  //     this.change.emit(this.selectedId);
  //   }
  // }
}
