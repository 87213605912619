import { Global } from '../../../../common/global';
import { ReflectionHelper } from '../../../../common/reflection-helper';
import { UnitTypeEnum } from '../../../../model/enum';
import { SalaryRecordCalculation } from './salary-record-calculation';
var SalaryRecordItemHelper = /** @class */ (function () {
    function SalaryRecordItemHelper(dataItem, renderer, salaryRecordPersistanceTypes, salaryTypes, settingService) {
        this.dataItem = dataItem;
        this.renderer = renderer;
        this.salaryRecordPersistanceTypes = salaryRecordPersistanceTypes;
        this.salaryTypes = salaryTypes;
        this.settingService = settingService;
    }
    // #region Public methods
    SalaryRecordItemHelper.prototype.validate = function (modifiedFields) {
        var valid = true;
        var _loop_1 = function (fieldName) {
            if (modifiedFields.hasOwnProperty(fieldName)) {
                switch (fieldName) {
                    case 'Id':
                        valid = false;
                        break;
                    case 'SalaryTypeId':
                        var salaryTypeId_1 = modifiedFields['SalaryTypeId'];
                        valid = this_1.salaryTypes.filter(function (e) { return e.SalaryTypeId === salaryTypeId_1; }).length > 0;
                        break;
                    case 'PersistenceTypeId':
                        var persistanceTypeId_1 = modifiedFields['PersistenceTypeId'];
                        valid =
                            this_1.salaryRecordPersistanceTypes.filter(function (e) { return e.Id === persistanceTypeId_1; })
                                .length > 0;
                        break;
                    case 'UnitTypeId':
                        var unitTypeId_1 = modifiedFields['UnitTypeId'];
                        valid = Global.UNIT_TYPES.filter(function (e) { return e.Id === unitTypeId_1; }).length > 0;
                        break;
                }
            }
        };
        var this_1 = this;
        for (var fieldName in modifiedFields) {
            _loop_1(fieldName);
        }
        return valid;
    };
    SalaryRecordItemHelper.prototype.updateRelavantFields = function (modifiedFields, negativeDefault) {
        if (negativeDefault === void 0) { negativeDefault = true; }
        var salaryTypeId = this.dataItem['SalaryTypeId'];
        var salaryType = this.getSalaryType(salaryTypeId);
        for (var fieldName in modifiedFields) {
            if (modifiedFields.hasOwnProperty(fieldName)) {
                switch (fieldName) {
                    case 'SalaryTypeId':
                        salaryTypeId = modifiedFields[fieldName];
                        salaryType = this.getSalaryType(salaryTypeId);
                        this.updateSalaryType(salaryType, negativeDefault);
                        break;
                    case 'PersistanceType':
                        this.updatePersistanceType(modifiedFields[fieldName]);
                        break;
                    case 'UnitTypeId':
                        this.updateUnitType(modifiedFields[fieldName]);
                        break;
                    case 'Units':
                        this.updateUnits(modifiedFields[fieldName]);
                        break;
                    case 'AmountPerUnit':
                        if (salaryType) {
                            modifiedFields[fieldName] = this.updateAmountPerUnit(modifiedFields[fieldName], salaryType.NegativeDefault);
                        }
                        break;
                    case 'BaseAmount':
                        this.updateBaseAmount(modifiedFields[fieldName]);
                        break;
                    case 'Amount':
                        if (salaryType) {
                            modifiedFields[fieldName] = this.updateAmount(modifiedFields[fieldName], salaryType.NegativeDefault);
                        }
                        break;
                }
            }
        }
        this.updateDataItem(modifiedFields, this.dataItem);
    };
    SalaryRecordItemHelper.prototype.getSalaryType = function (salaryTypeId) {
        return this.salaryTypes
            ? this.salaryTypes.find(function (e) { return e.SalaryTypeId === salaryTypeId; })
            : undefined;
    };
    SalaryRecordItemHelper.prototype.usePositiveAmount = function (fieldName) {
        var value = this.dataItem[fieldName];
        if (value && value < 0) {
            value = value * -1;
            this.recalculateBaseOnNegativeDefault(fieldName, value);
        }
    };
    SalaryRecordItemHelper.prototype.autoCorrect = function (fieldName) {
        var value = this.dataItem[fieldName];
        if (value > 0) {
            value = value * -1;
            this.recalculateBaseOnNegativeDefault(fieldName, value);
        }
    };
    // #endregion
    // #region Update fields
    SalaryRecordItemHelper.prototype.updatePersistanceType = function (persistanceType) {
        var newPersistanceType = this.salaryRecordPersistanceTypes
            ? this.salaryRecordPersistanceTypes.filter(function (e) { return e.Id === (persistanceType ? persistanceType.Id : 1); })
            : undefined;
        if (!newPersistanceType || newPersistanceType.length < 1) {
            var previousPersistanceType = this.dataItem['PersistanceType'];
            this.setDataItemValue(this.dataItem, 'PersistanceTypeId', previousPersistanceType.Id);
            this.setDataItemValue(this.dataItem, 'PersistanceType', previousPersistanceType);
            persistanceType = previousPersistanceType;
        }
    };
    SalaryRecordItemHelper.prototype.updateUnitType = function (unitTypeId) {
        var calculator = this.createSalaryRecordCalculation();
        calculator.UnitType = unitTypeId;
        calculator.calculateAmount();
        calculator.calculateBaseAmount();
        calculator.calculateUnits();
        var perUnit = calculator.AmountPerUnit;
        if (calculator.UnitType === UnitTypeEnum.Percent) {
            perUnit = null;
        }
        this.setDataItemValue(this.dataItem, 'Amount', calculator.Amount);
        this.setDataItemValue(this.dataItem, 'BaseAmount', calculator.BaseAmount);
        this.setDataItemValue(this.dataItem, 'Units', calculator.Units);
        this.setDataItemValue(this.dataItem, 'AmountPerUnit', perUnit);
    };
    SalaryRecordItemHelper.prototype.updateUnitsWithOtherUnitType = function (calculator) {
        if (calculator.UnitType === UnitTypeEnum.Percent) {
            return;
        }
        calculator.calculateAmount();
        this.setDataItemValue(this.dataItem, 'Amount', calculator.Amount);
    };
    SalaryRecordItemHelper.prototype.updateUnitsWithUnitTypePercent = function (calculator) {
        if (calculator.UnitType !== UnitTypeEnum.Percent) {
            return;
        }
        calculator.calculateAmount();
        calculator.calculateBaseAmount();
        this.setDataItemValue(this.dataItem, 'Amount', calculator.Amount);
        this.setDataItemValue(this.dataItem, 'BaseAmount', calculator.BaseAmount);
    };
    SalaryRecordItemHelper.prototype.updateUnits = function (units) {
        var calculator = this.createSalaryRecordCalculation();
        calculator.UnitType = this.dataItem['UnitTypeId'];
        calculator.Units = units;
        if (calculator.UnitType === UnitTypeEnum.Percent) {
            this.updateUnitsWithUnitTypePercent(calculator);
        }
        else {
            this.updateUnitsWithOtherUnitType(calculator);
        }
        return calculator.Units;
    };
    SalaryRecordItemHelper.prototype.updateAmountPerUnit = function (amountPerUnit, negativeDefault) {
        if (negativeDefault) {
            amountPerUnit = this.calculateBaseOnNegativeDefault(amountPerUnit, this.dataItem.uid, 'AmountPerUnit');
        }
        var calculator = this.createSalaryRecordCalculation();
        if (calculator.UnitType === UnitTypeEnum.Percent) {
            return amountPerUnit;
        }
        calculator.AmountPerUnit = amountPerUnit;
        calculator.calculateAmount();
        calculator.calculateUnits();
        this.setDataItemValue(this.dataItem, 'Amount', calculator.Amount);
        this.setDataItemValue(this.dataItem, 'Units', calculator.Units);
        return amountPerUnit;
    };
    SalaryRecordItemHelper.prototype.updateBaseAmount = function (baseAmount) {
        var calculator = this.createSalaryRecordCalculation();
        calculator.BaseAmount = baseAmount;
        if (calculator.UnitType !== UnitTypeEnum.Percent) {
            return baseAmount;
        }
        calculator.calculateAmount();
        calculator.calculateUnits();
        this.setDataItemValue(this.dataItem, 'Amount', calculator.Amount);
        this.setDataItemValue(this.dataItem, 'Units', calculator.Units);
        return calculator.BaseAmount;
    };
    SalaryRecordItemHelper.prototype.updateAmountWithUnitTypePercent = function (calculator) {
        if (calculator.UnitType !== UnitTypeEnum.Percent) {
            return;
        }
        calculator.calculateUnits();
        calculator.calculateBaseAmount();
        this.setDataItemValue(this.dataItem, 'BaseAmount', calculator.BaseAmount);
        this.setDataItemValue(this.dataItem, 'Units', calculator.Units);
    };
    SalaryRecordItemHelper.prototype.updateAmountWithOtherUnitType = function (calculator) {
        calculator.calculateUnits();
        this.setDataItemValue(this.dataItem, 'Units', calculator.Units);
    };
    SalaryRecordItemHelper.prototype.updateAmount = function (amount, negativeDefault) {
        var calculator = this.createSalaryRecordCalculation();
        if (negativeDefault) {
            amount = this.calculateBaseOnNegativeDefault(amount, this.dataItem.uid, 'Amount');
        }
        calculator.Amount = amount;
        if (calculator.UnitType === UnitTypeEnum.Percent) {
            this.updateAmountWithUnitTypePercent(calculator);
        }
        else {
            this.updateAmountWithOtherUnitType(calculator);
        }
        if (negativeDefault) {
            if (calculator.Units < 0 && calculator.AmountPerUnit > 0) {
                // Only Amount should be negative instead of Units.
                calculator.Units = calculator.Units * -1;
                calculator.AmountPerUnit = calculator.AmountPerUnit * -1;
                this.setDataItemValue(this.dataItem, 'Units', calculator.Units);
                this.setDataItemValue(this.dataItem, 'AmountPerUnit', calculator.AmountPerUnit);
            }
        }
        // if ((!calculator.Units || !calculator.Amount) && !calculator.BaseAmount) {
        //     this.showInCellPopupMessage(this.dataItem, "BaseAmount", "BaseAmount");
        // }
        return amount;
    };
    Object.defineProperty(SalaryRecordItemHelper.prototype, "isAutoFlipAmountsSetting", {
        // #endregion
        // #region Flip negative amount
        get: function () {
            var result = true;
            var autoFlipSetting = Global.USER_PREFERENCES && Global.USER_PREFERENCES.length > 0
                ? Global.USER_PREFERENCES.find(function (user) { return user.Key === 'SalaryRecords.AutoFlipAmounts'; })
                : undefined;
            if (autoFlipSetting) {
                result = autoFlipSetting.Value === 'true';
            }
            return result;
        },
        enumerable: true,
        configurable: true
    });
    SalaryRecordItemHelper.prototype.calculateBaseOnNegativeDefault = function (amount, uid, fieldName) {
        ////let autoFlipSetting: boolean = ();
        if (amount > 0) {
            if (this.isAutoFlipAmountsSetting) {
                amount = amount * -1;
            }
            if (!this.settingService) {
                return amount;
            }
            this.showInCellPopupMessage(this.dataItem, 'Amount', fieldName);
        }
        return amount;
    };
    SalaryRecordItemHelper.prototype.createSalaryRecordCalculation = function () {
        var unitTypeId = this.dataItem['UnitTypeId'];
        var unitType = Global.UNIT_TYPES ? Global.UNIT_TYPES.find(function (e) { return e.Id === unitTypeId; }) : undefined;
        var strAmountPerUnit = this.dataItem['AmountPerUnit'];
        var strUint = this.dataItem['Units'];
        var strAmount = this.dataItem['Amount'];
        var strBaseAmount = this.dataItem['BaseAmount'];
        var amountPerUnit = strAmountPerUnit ? parseFloat(strAmountPerUnit) : null;
        var unit = strUint ? parseFloat(strUint) : 0;
        var amount = strAmount ? parseFloat(strAmount) : 0;
        var baseAmount = strBaseAmount ? parseFloat(strBaseAmount) : 0;
        var calculator = new SalaryRecordCalculation();
        calculator.Amount = amount;
        calculator.AmountPerUnit = amountPerUnit;
        calculator.BaseAmount = baseAmount;
        calculator.UnitType = unitType ? unitType.Id : UnitTypeEnum.None;
        calculator.Units = unit;
        return calculator;
    };
    SalaryRecordItemHelper.prototype.updateDataItem = function (updatedProperties, salaryRecord) {
        for (var property in updatedProperties) {
            if (updatedProperties.hasOwnProperty(property)) {
                for (var salaryRecordViewProperty in salaryRecord) {
                    if (property === salaryRecordViewProperty) {
                        if (ReflectionHelper.isObject(property)) {
                            this.updateDataItem(property, salaryRecordViewProperty);
                        }
                        else {
                            salaryRecord[salaryRecordViewProperty] = updatedProperties[property];
                        }
                    }
                }
            }
        }
        return salaryRecord;
    };
    SalaryRecordItemHelper.prototype.recalculateBaseOnNegativeDefault = function (fieldName, value) {
        this.setDataItemValue(this.dataItem, fieldName, value);
        var calculator = this.createSalaryRecordCalculation();
        if (fieldName === 'Amount') {
            calculator.Amount = value;
            if ((value < 0 && calculator.AmountPerUnit > 0) || (value >= 0 && calculator.AmountPerUnit < 0)) {
                calculator.AmountPerUnit = calculator.AmountPerUnit * -1;
                this.setDataItemValue(this.dataItem, 'AmountPerUnit', calculator.AmountPerUnit);
            }
            calculator.calculateUnits();
            calculator.calculateBaseAmount();
            this.setDataItemValue(this.dataItem, 'BaseAmount', calculator.BaseAmount);
            this.setDataItemValue(this.dataItem, 'Units', calculator.Units);
        }
        if (fieldName === 'AmountPerUnit') {
            calculator.AmountPerUnit = value;
            calculator.calculateAmount();
            calculator.calculateUnits();
            this.setDataItemValue(this.dataItem, 'Amount', calculator.Amount);
            this.setDataItemValue(this.dataItem, 'Units', calculator.Units);
        }
    };
    // #endregion
    // #region Common methods
    SalaryRecordItemHelper.prototype.getPersistanceType = function (persistanceTypeId) {
        if (!persistanceTypeId || persistanceTypeId === 0) {
            return undefined;
        }
        var persistanceTypes = this.salaryRecordPersistanceTypes;
        if (!persistanceTypes || persistanceTypes.length === 0) {
            return undefined;
        }
        var result = persistanceTypes.filter(function (e) { return e.Id === persistanceTypeId; });
        if (result.length < 1) {
            return undefined;
        }
        return { Id: result[0].Id, Name: result[0].Name };
    };
    SalaryRecordItemHelper.prototype.showInCellPopupMessage = function (dataItem, field, triggerField) {
        var fieldName = field + 'PopupVisible';
        dataItem.triggerField = triggerField ? triggerField : undefined;
        dataItem[fieldName] = true;
        setTimeout(function () {
            dataItem[fieldName] = false;
        }, 4000);
    };
    SalaryRecordItemHelper.prototype.updateSalaryType = function (salaryType, negativeDefault) {
        if (negativeDefault === void 0) { negativeDefault = true; }
        var description = salaryType.DefaultPayslipText;
        var unitType = this.getUnitType(salaryType);
        var baseAmount = salaryType.BaseAmount;
        var units = salaryType.Units;
        var amountPerUnit = salaryType.AmountPerUnit;
        var amount = salaryType.Amount;
        if (negativeDefault && salaryType.NegativeDefault) {
            amount = this.calculateBaseOnNegativeDefault(amount, this.dataItem.uid, 'Amount');
        }
        var calculator = new SalaryRecordCalculation();
        calculator.UnitType = unitType ? unitType.Id : null;
        calculator.Amount = amount;
        calculator.AmountPerUnit =
            this.isAutoFlipAmountsSetting && negativeDefault && salaryType.NegativeDefault && amountPerUnit > 0
                ? amountPerUnit * -1
                : amountPerUnit;
        calculator.BaseAmount = baseAmount;
        calculator.Units = units;
        ////calculator.calculateAmount();
        calculator.calculateUnits();
        calculator.calculateBaseAmount();
        var persistanceType = this.getPersistanceType(salaryType.DefaultPersistanceTypeId);
        if (this.dataItem.SalaryTypeId !== salaryType.SalaryTypeId) {
            this.dataItem.SalaryTypeId = salaryType ? salaryType.SalaryTypeId : null;
            try {
                this.dataItem.trigger('change', { field: 'SalaryTypeId' });
            }
            catch (e) { }
        }
        // ObservableObject may not be about to set the value correctly.
        if (this.dataItem['Description'] !== description ||
            this.dataItem['BaseAmount'] !== calculator.BaseAmount ||
            this.dataItem['AmountPerUnit'] !== calculator.AmountPerUnit ||
            this.dataItem['Amount'] !== calculator.Amount ||
            this.dataItem['Units'] !== calculator.Units ||
            this.dataItem['UnitType'] !== unitType ||
            this.dataItem['PersistanceType'] !== persistanceType) {
            var dataItemObject = this.dataItem;
            dataItemObject.Description = description;
            //dataItemObject.BaseAmount = calculator.BaseAmount;
            // let observableUnitType: kendo.data.ObservableObject = new kendo.data.ObservableObject(unitType);
            // dataItemObject.UnitType = unitType ? observableUnitType : undefined;
            dataItemObject.UnitType = unitType;
            dataItemObject.UnitTypeId = unitType ? unitType.Id : null;
            dataItemObject.SortOrder = salaryType.SortOrder;
            this.updateDefaultUndefinedFields(dataItemObject);
            if (calculator.AmountPerUnit) {
                if (calculator.AmountPerUnit !== dataItemObject.AmountPerUnit) {
                    dataItemObject.AmountPerUnit = calculator.AmountPerUnit;
                }
            }
            if (calculator.Amount) {
                if (calculator.Amount !== dataItemObject.Amount) {
                    dataItemObject.Amount = calculator.Amount;
                }
            }
            if (calculator.Units) {
                if (calculator.Units !== dataItemObject.Units) {
                    dataItemObject.Units = calculator.Units;
                }
            }
            if (calculator.BaseAmount) {
                if (calculator.BaseAmount !== dataItemObject.BaseAmount) {
                    dataItemObject.BaseAmount = calculator.BaseAmount;
                }
            }
            if (!persistanceType && this.salaryRecordPersistanceTypes) {
                // Get the default one
                var persistanceTypes = this.salaryRecordPersistanceTypes.filter(function (e) { return e.Id === 1; });
                persistanceType = persistanceTypes[0];
            }
            // let observablePersistanceType: kendo.data.ObservableObject = new kendo.data.ObservableObject(persistanceType);
            // dataItemObject.PersistanceType = persistanceType ? observablePersistanceType : undefined;
            dataItemObject.PersistanceType = persistanceType;
            dataItemObject.PersistenceTypeId = persistanceType ? persistanceType.Id : null;
            try {
                this.dataItem.trigger('change', { field: 'Description' });
                this.dataItem.trigger('change', { field: 'BaseAmount' });
                this.dataItem.trigger('change', { field: 'Units' });
                this.dataItem.trigger('change', { field: 'UnitType' });
                this.dataItem.trigger('change', { field: 'UnitTypeId' });
                this.dataItem.trigger('change', { field: 'AmountPerUnit' });
                this.dataItem.trigger('change', { field: 'Amount' });
                this.dataItem.trigger('change', { field: 'PersistanceType' });
                this.dataItem.trigger('change', { field: 'PersistenceTypeId' });
                this.dataItem.trigger('change', { field: 'SortOrder' });
            }
            catch (e) { }
        }
    };
    SalaryRecordItemHelper.prototype.updateDefaultUndefinedFields = function (dataItem) {
        if (!dataItem.AmountPerUnit && dataItem.AmountPerUnit !== 0) {
            dataItem.AmountPerUnit = null;
        }
        if (!dataItem.Amount && dataItem.Amount !== 0) {
            dataItem.Amount = null;
        }
        if (!dataItem.Units && dataItem.Units !== 0) {
            dataItem.Units = null;
        }
        if (!dataItem.BaseAmount && dataItem.BaseAmount !== 0) {
            dataItem.BaseAmount = null;
        }
        dataItem.Dimension1ValueId = dataItem.Dimension1ValueId || null;
        dataItem.Dimension2ValueId = dataItem.Dimension2ValueId || null;
        dataItem.Dimension3ValueId = dataItem.Dimension3ValueId || null;
    };
    SalaryRecordItemHelper.prototype.getUnitType = function (salaryType) {
        var unitTypes = Global.UNIT_TYPES;
        var unitypeId = salaryType.UnitTypeId;
        if (unitypeId) {
            var result = unitTypes.filter(function (e) { return e.Id === unitypeId; });
            if (result) {
                return result[0];
            }
        }
        return null;
    };
    SalaryRecordItemHelper.prototype.setDataItemValue = function (dataItem, field, value) {
        try {
            dataItem.set(field, value);
        }
        catch (e) { }
        dataItem[field] = value;
    };
    return SalaryRecordItemHelper;
}());
export { SalaryRecordItemHelper };
