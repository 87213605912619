import { LocationStrategy } from '@angular/common';
import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Constants } from '../common/constants';
import { Global } from '../common/global';
import { RouterStateService } from '../common/router-state.service';
import { IListDataItem } from '../model/ilist-data-item';
import { IAccount, IAccountType, ICompany, ICompanyUser, IRole, ISimpleCompany } from '../services/api/api-model';
import { DataService } from '../services/api/data.service';
import { StaticDataService } from '../services/api/static-data.service';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { BroadcastService } from '../services/broadcast.service';
import { BusyIndicatorService } from '../services/busy-indicator.service';
import { EmployeeService } from '../services/employee.service';
import { ModalService } from '../services/modal.service';
import { SessionService } from '../services/session/session.service';
import { SettingService } from '../services/setting.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TabsComponent implements OnInit, OnDestroy, AfterViewInit {
  public selectedCompanyId: number;
  public currentCompanyName = 'Unknow Company';
  public currentCompanyAbbreviation = 'UC';
  public showCompanyAbbreviation: boolean;
  public logo: string;
  public showAcceptDPADialog: boolean;
  public navigationExpanded: boolean;
  public companies: ISimpleCompany[];
  public isDetecGoogleTranslate = false;

  @HostListener('window:beforeunload')
  tabsBeforeunload() {
    Global.isOpenTabs = '';
  }

  public get branding() {
    return environment.branding;
  }

  public get logoAlias() {
    if (Global.SESSION) {
      switch (Global.SESSION.CurrentSystemName) {
        case 'Colosal':
          return 'images/Colosal.svg';
          break;

        case 'Facit':
          return 'images/Facit.svg';
          break;
      }
    }
    return 'images/intect_H_logo.svg';
  }

  public get logoClass(): string {
    if (Global.SESSION) {
      switch (Global.SESSION.CurrentSystemName) {
        case 'Colosal':
        case 'Facit':
          return 'svgLine';
      }
    }
    return '';
  }

  public get isDemo(): boolean {
    return this.sessionService.feature.IsDemoVersion;
  }

  public get isIOSMobileApp(): boolean {
    return this.sessionService.browser.iOSMobileDevice;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public get isDetectedCookieDisable(): boolean {
    return this.sessionService.isDetectedCookieDisable;
  }

  public set isDetectedCookieDisable(value) {
    if (value) {
      this.sessionService.isDetectedCookieDisable = value;
    }
  }

  public get isSingleListcompanis(): boolean {
    if (this.companies && this.companies.length <= 1) {
      return true;
    }

    return false;
  }

  public get Companytooltip(): string {
    if (this.logo || this.sessionService.feature.IsDemoVersion) {
      return this.currentCompanyName;
    }

    return '';
  }

  private activeCompany: ICompany;
  private clientWidth: number;
  private updatedMessage: string;
  private updatedMessageUrl: string;
  private isNavigateBack = false;
  private loadingTipsTextChangeCompany: string;
  private limitTimeLoading = 20;

  public isLoading = true;
  public loadingTipText = '';
  public isDetectedAdBlock = false;

  constructor(
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private dataService: DataService,
    public settingService: SettingService,
    public modalService: ModalService,
    private broadcaster: BroadcastService,
    public sessionService: SessionService,
    public staticDataService: StaticDataService,
    private renderer: Renderer2,
    private meta: Meta,
    private employeeService: EmployeeService,
    public busyIndicatorService: BusyIndicatorService,
    private locationStrategy: LocationStrategy,
    public state: RouterStateService
  ) {
    const url: string = window.location.href;
    if (!this.isMobile && url.indexOf('file://') > -1) {
      window.location.href = 'https://intect.app';
    }

    if (!this.sessionService.IsAuthenticated) {
      this.sessionService.NavigateTo('login');
      return;
    }

    if (Global.SESSION && Global.SESSION.SignOnToken.Language.CultureCode) {
      const cultureCode: string = Global.SESSION.SignOnToken.Language.CultureCode;
      const shortCultureCode: string = cultureCode.substr(0, 2);
      this.translate.use(shortCultureCode).subscribe(() => {
        this.settingService.applyTranslations();
        this.translateText();
      });
    }
  }

  public ngAfterViewInit(): void {}

  // Just using for detect google translate
  // Reload page to prevent google add more html element to web
  public onDisableDetect(event: string) {
    window.location.reload();
  }

  public ngOnInit(): void {
    this.renderer.removeClass(document.body, 'Login-bg');
    this.renderer.addClass(document.body, 'main-bg');
    this.setFields();
    this.getCompanies();
    this.bindResizeEvent();
    this.useVerticalMenu();

    this.locationStrategy.onPopState(() => {
      const state = window.location.href;
      if (state.indexOf('login') >= 0) {
        this.sessionService.isBackToLogin = true;
      }
    });

    this.sessionService.checkIsAliveErrorChange.subscribe((value: boolean) => {
      if (value && this.modalService.status === 401 && this.modalService.alertVisible) {
        this.modalService.alertVisible = false;
      }
    });

    this.renderer.listen(document, 'keydown', (event: any) =>
      this.broadcaster.broadcast(Constants.GLOBAL_KEY_DOWN, event)
    );

    this.renderer.listen(window, 'click', (event: any) => {
      if (!this.isCollapseButtonClick) {
        this.navigationExpanded = false;
      } else {
        this.isCollapseButtonClick = false;
      }
    });

    this.setupEventHandlers();
    this.checkLoading();
    this.checkDuplicateTab();

    this.employeeService.employees.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {});
  }

  private checkDuplicateTab() {
    if (
      this.sessionService.IsAuthenticated &&
      !this.sessionService.browser.isMobile &&
      !this.sessionService.redirectToApp
    ) {
      this.sessionService.redirectToApp = false;
      const dataTabload = Global.isOpenTabs;
      if (!dataTabload) {
        Global.isOpenTabs = 'isOnOpen';
      } else {
        this.sessionService.restoreDefault = false;
        this.sessionService.NavigateTo('login');
      }
    }

    this.sessionService.redirectToApp = false;
  }

  public get isShowDropDown(): boolean {
    let isShow = 0;

    if (this.sessionService.feature.EmployeeTabEnabled) {
      isShow++;
    }
    if (this.sessionService.feature.CompanyTabEnabled) {
      isShow++;
    }
    if (this.sessionService.feature.AccountantTabEnabled) {
      isShow++;
    }

    return isShow > 0;
  }

  public get isHideSelfService(): boolean {
    if (this.sessionService.feature.AccountantTabEnabled) {
      return !this.employeeService.isCurrentUserHasEmployment;
    }
    return !this.employeeService.isCurrentUserExistIncompanyUsers;
  }

  private checkLoading(): void {
    this.limitTimeLoading--;
    if (this.limitTimeLoading === 0) {
      this.afterLoading();
      return;
    }
    if (!this.sessionService.isGetting && !this.sessionService.isSubmitting) {
      setTimeout(() => {
        this.afterLoading();
      }, 1000);
    } else {
      setTimeout(() => {
        this.checkLoading();
      }, 1000);
    }
  }

  private afterLoading(): void {
    this.isLoading = false;
    this.settingService.isReload = false;
    setTimeout(() => {
      if (document.getElementById('DHOebGNflhUB')) {
        this.isDetectedAdBlock = false;
      } else {
        if (!Global.isFirstCheckMessageAdBlock) {
          Global.isFirstCheckMessageAdBlock = true;
          this.isDetectedAdBlock = true;
        }
      }
      if (!this.isMobile) {
        try {
          if (document.cookie.indexOf('languageId') !== -1) {
            this.isDetectedCookieDisable = false;
          } else {
            this.isDetectedCookieDisable = true;
          }
        } catch (e) {
          this.isDetectedCookieDisable = true;
        }
      }

      window.scrollTo(0, 1);
    });
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public navigateToState(name: string): void {
    if (this.sessionService.isSubmitting) {
      return;
    }

    this.navigationExpanded = false;
    this.sessionService.menuToggled = true;

    if (!this.sessionService.currentState.includes(name)) {
      if (name === 'tabs.selfservice') {
        this.employeeService.selectEmployeeAsync(Global.SESSION.CurrentCompanyUser.Id).subscribe((data: boolean) => {
          this.sessionService.NavigateTo(name);
        });
      } else {
        this.sessionService.NavigateTo(name);
      }
      return;
    }

    if (this.sessionService.currentState === name) {
      const currentRoleKey: string = Global.SESSION.CurrentRole.Key;
      switch (name) {
        case 'tabs.employee':
          if (currentRoleKey) {
            switch (currentRoleKey) {
              case 'FullAdmin':
                if (Global.IsEmployeeTaskCompleted) {
                  this.sessionService.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
                  this.sessionService.NavigateTo('tabs.employee.payrolldata');
                } else {
                  this.sessionService.currentStyleMenuTabpanelReponsive = '';
                  this.sessionService.NavigateTo('tabs.employee.general');
                }

                break;
              case 'ReadOnly':
                this.sessionService.NavigateTo('tabs.employee.payrolldata');
                break;
              case 'Employee':
                const hasManager: boolean = this.sessionService.role.EmployeeWithRoleAsManager;
                if (hasManager) {
                  this.sessionService.NavigateTo('tabs.employee.time');
                }

                this.sessionService.NavigateTo('tabs.selfservice.payslip');
                break;
              case 'SalaryAdmin':
                this.sessionService.NavigateTo('tabs.employee.payrolldata');
                break;
              default:
                break;
            }
          }
          break;
        case 'tabs.company':
          if (currentRoleKey) {
            switch (currentRoleKey) {
              case 'FullAdmin':
                this.sessionService.NavigateTo('tabs.company.salarybatches');
                break;
              case 'ReadOnly':
                this.sessionService.NavigateTo('tabs.company.salarybatches');
                break;
              case 'Employee':
                if (Global.SESSION.ManagerForUserEmploymentIds.length > 0) {
                  this.sessionService.NavigateTo('tabs.company.approval.timeentry');
                }

                this.sessionService.NavigateTo('tabs.selfservice.payslip');
                break;
              case 'SalaryAdmin':
                this.sessionService.NavigateTo('tabs.company.salarybatches');
                break;
              default:
                break;
            }
          }
          break;
        case 'tabs.selfservice':
          this.employeeService.selectEmployeeAsync(Global.SESSION.CurrentCompanyUser.Id).subscribe((data: boolean) => {
            if (currentRoleKey) {
              switch (currentRoleKey) {
                case 'FullAdmin':
                  this.sessionService.NavigateTo('tabs.selfservice.payslip');
                  break;
                case 'ReadOnly':
                  this.sessionService.NavigateTo('tabs.selfservice.payslip');
                  break;
                case 'Employee':
                  this.sessionService.NavigateTo('tabs.selfservice.payslip');
                  break;
                case 'SalaryAdmin':
                  this.sessionService.NavigateTo('tabs.selfservice.payslip');
                  break;
                default:
                  break;
              }
            }
          });
          break;
        default:
          break;
      }
    }
  }

  public get doubleClickToEditHintVisible(): boolean {
    return (
      this.sessionService.currentState === 'tabs.employee.general' ||
      this.sessionService.currentState === 'tabs.employee.hiringstatus' ||
      this.sessionService.currentState === 'tabs.company.general' ||
      this.sessionService.currentState === 'tabs.company.configuration.hiringstatus' ||
      this.sessionService.currentState === 'tabs.selfservice.general'
    );
  }

  public onCompanyChange(companyId: number): void {
    if (!this.selectedCompanyId) {
      this.selectedCompanyId = -9999;
    }

    if (
      companyId &&
      this.selectedCompanyId &&
      this.selectedCompanyId !== companyId &&
      companyId !== Global.COMPANY_ID
    ) {
      this.settingService.ReloadWarrning = true;
      this.isLoading = true;
      this.loadingTipText = this.loadingTipsTextChangeCompany;
      this.setCompanyContext(companyId);
    }
    setTimeout(() => {
      this.settingService.showCompanySelector = false;
    });
  }

  public onDPATermAccepted(isValid: boolean): void {
    if (!isValid) {
      this.authenticationService
        .logout()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          () => {
            this.navigateToLogin();
          },
          () => {
            this.navigateToLogin();
          }
        );
    }
  }

  private isCollapseButtonClick: boolean;
  public onToggleNavigation(): void {
    if (!this.isShowDropDown) {
      return;
    }
    this.isCollapseButtonClick = true;
    this.navigationExpanded = !this.navigationExpanded;
  }

  public onToggleCompanyDropdown(): void {
    if (!this.sessionService.isGetting) {
      this.sessionService.showHelpMenuToggled = false;
      this.sessionService.showMoreMenuToggled = false;
      this.sessionService.showMenuToggled = false;

      if (this.isSingleListcompanis === false) {
        if (this.settingService.showCompanySelector === true) {
          this.settingService.showCompanySelector = false;
          return;
        }
        setTimeout(() => {
          this.settingService.showCompanySelector = !this.settingService.showCompanySelector;
        });
      }
    }
  }

  public get IsDanishCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
  }

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  private reloadStaticData() {
    return this.staticDataService
      .loadStaticData()
      .then(() => {
        let isAvailableTab = false;
        const currentState = this.sessionService.currentState;
        this.sessionService.refeshRoleAndFeature();

        if (currentState) {
          isAvailableTab = this.sessionService.isAvailableTab(
            currentState,
            this.IsDanishCompany,
            this.IsGreenlandCompany
          );
        }

        if (!isAvailableTab) {
          if (this.sessionService.role.IsEmployeeRole) {
            this.selfserviceTabHandleForOrtherCompany();
            return;
          }
          this.sessionService.switchToDefaultTab();
        }

        this.dataService.Companies_GetCurrent().subscribe(
          (activeCompany: ICompany): void => {
            Global.COMPANY_ID = this.activeCompany && this.activeCompany.Id ? this.activeCompany.Id : null;
            Global.COMPANY = activeCompany;
            window.location.reload();
          },
          () => {
            this.settingService.ReloadWarrning = false;
          }
        );
      })
      .catch(() => {
        this.settingService.ReloadWarrning = false;
      });
  }

  private selfserviceTabHandleForOrtherCompany() {
    this.dataService.Companies_GetCurrent().subscribe(
      (activeCompany: ICompany): void => {
        Global.COMPANY_ID = this.activeCompany && this.activeCompany.Id ? this.activeCompany.Id : null;
        Global.COMPANY = activeCompany;
        this.state.clearRedirect('tabs.selfservice');
        this.state.clearCurrentSubState('tabs.selfservice');

        this.employeeService.loadEmployeesAsync().then(() => {
          this.employeeService.selectEmployeeAsync(Global.SESSION.CurrentCompanyUser.Id).subscribe(() => {
            this.sessionService.switchToDefaultTab();
            window.location.reload();
          });
        });
      },
      () => {
        this.settingService.ReloadWarrning = false;
      }
    );
  }

  private resetViewPort(): void {
    const original: HTMLMetaElement = this.meta.getTag('name=viewport');
    const forceScale: string = original.content + ', user-scalable=no, minimum-scale=1.0, maximum-scale=1.0';
    this.meta.updateTag({ name: 'viewport', content: forceScale }, 'name=viewport');
    setTimeout(() => this.meta.updateTag({ name: 'viewport', content: original.content }, 'name=viewport'));
  }

  private bindResizeEvent(): void {
    this.clientWidth = document.body.clientWidth;
    this.renderer.listen(window, 'resize', () => {
      this.useVerticalMenu();
      const diff: number = document.body.clientWidth - this.clientWidth;
      if (this.isIOSMobileApp && Math.abs(diff) > 10) {
        this.renderer.removeClass(document.body, 'mobileIOSApp');
        this.clientWidth = document.body.clientWidth;
      }
    });
  }

  private useVerticalMenu(): void {
    if (window.matchMedia('(max-height: 500px)').matches || window.matchMedia('(max-width: 1000px)').matches) {
      if (!this.settingService.showCompanySelector) {
        this.sessionService.menuToggled = true;
      }
    } else {
      this.sessionService.menuToggled = false;
    }
  }

  private translateText(): void {
    this.translate
      .get([
        'Login.UpdatedMessage',
        'UnsuccessfulAPI.404',
        'Tips.ChangeCompany',
        'Tips.MobileChangeCompany',
        'Login.UpdatedMessageUrl',
        'Error.401ErrorWarningResponse'
      ])
      .subscribe((translations: { [key: string]: string }) => {
        this.updatedMessage = translations['Login.UpdatedMessage'].replace(
          '{{SystemAlias}}',
          environment.branding.SystemAlias
        );
        this.updatedMessageUrl = translations['Login.UpdatedMessageUrl'].replace(
          '{{SystemAlias}}',
          environment.branding.SystemAlias
        );
        this.updatedMessage = this.updatedMessage.replace('{UrlFeauture}', this.updatedMessageUrl);
        this.sessionService.errorMessage404 = translations['UnsuccessfulAPI.404'];
        this.sessionService.errorMessage401 = translations['Error.401ErrorWarningResponse'];
        this.settingService.showModuleMessage('ShowUpdateNotification', this.updatedMessage);
        if (this.isMobile) {
          this.loadingTipsTextChangeCompany = translations['Tips.MobileChangeCompany'];
        } else {
          this.loadingTipsTextChangeCompany = translations['Tips.ChangeCompany'];
        }
      });

    this.translate
      .get([
        'GlobalDialog.AcceptDPAButton',
        'GlobalDialog.AcceptWarnings',
        'GlobalDialog.Apply',
        'GlobalDialog.Approve',
        'GlobalDialog.AlreadyRequestedTaxCardConfirmButton',
        'GlobalDialog.Cancel',
        'GlobalDialog.CancelDiscard',
        'GlobalDialog.ChangePassword',
        'GlobalDialog.ClearErrors',
        'GlobalDialog.ConfirmDiscard',
        'GlobalDialog.Continue',
        'GlobalDialog.CompleteImportText',
        'GlobalDialog.Close',
        'GlobalDialog.Create',
        'GlobalDialog.CreateWithoutEmails',
        'GlobalDialog.Delete',
        'GlobalDialog.DisableThisBehaviour',
        'GlobalDialog.DiscardAndLeave',
        'GlobalDialog.Download',
        'GlobalDialog.DeleteEmploymentButtonText',
        'GlobalDialog.DeleteCompanyUserButtonText',
        'GlobalDialog.Edit',
        'GlobalDialog.Enable',
        'GlobalDialog.EnableAutoCorrect',
        'GlobalDialog.Finalize',
        'GlobalDialog.Hide',
        'GlobalDialog.Import',
        'GlobalDialog.IKnowWhatIamDoing',
        'GlobalDialog.Lock',
        'GlobalDialog.Lock and notify employees',
        'GlobalDialog.Logout',
        'GlobalDialog.MoreHelp',
        'GlobalDialog.No',
        'GlobalDialog.Ok',
        'GlobalDialog.Attach',
        'GlobalDialog.Proceed',
        'GlobalDialog.ReadMore',
        'GlobalDialog.Reject',
        'GlobalDialog.ReSend',
        'GlobalDialog.ResetPassword',
        'GlobalDialog.Revert',
        'GlobalDialog.RevertToDraft',
        'GlobalDialog.Save',
        'GlobalDialog.Submit',
        'GlobalDialog.SubmitForApproval',
        'GlobalDialog.Stay',
        'GlobalDialog.Test',
        'GlobalDialog.Understood',
        'GlobalDialog.Update',
        'GlobalDialog.UsePositiveAmountThisTime',
        'GlobalDialog.Yes',
        'GlobalDialog.CreateNewCompany',
        'GlobalDialog.Login',
        'GlobalDialog.ForgotPassword',
        'GlobalDialog.ContinueAndSaveChange',
        'EmploymentPayrollData.MoreInfo',
        'EmploymentPayrollData.UsePositiveAmountThisTime',
        'Error.NetworkError',
        'GlobalDialog.YesPlease',
        'GlobalDialog.NoThankYou',
        'GlobalDialog.ForceRecalculate',
        'GlobalDialog.RevertFinalizing',
        'CompanyDataImport.SaveDefinition',
        'CompanyDataImport.ImportOnce'
      ])
      .subscribe((translations: { [key: string]: string }) => {
        this.sessionService.modalTranslations = translations;
        this.sessionService.modalTranslations['Error.NetworkError'] = this.sessionService.modalTranslations[
          'Error.NetworkError'
        ].replace('{{SystemAlias}}', environment.branding.SystemAlias);
      });
  }

  private setFields(): void {
    if (Global.SESSION) {
      this.sessionService.roleKey = Global.SESSION ? Global.SESSION.CurrentRole.Key : undefined;
    }
  }

  private setupEventHandlers(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e: string) =>
      this.translateText()
    );

    document.addEventListener(
      'backbutton',
      (): void => {
        this.onBackButtonClicked();
      },
      false
    );

    this.sessionService.OnLocationChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state: string): void => {
      this.handleStatesForBackButton();
      this.resetViewPort();
    });

    this.broadcaster
      .on<any>(Constants.SHOW_EDIT_HINT)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((): any => {
        const showToastMessage: boolean = this.doubleClickToEditHintVisible;
        this.settingService.showDoubleClickToEditHint(showToastMessage);
      });
  }

  private onBackButtonClicked(): void {
    const statesHistory: string[] = Global.statesHistory ? Global.statesHistory : [];
    statesHistory.pop();
    if (statesHistory.length > 0) {
      this.isNavigateBack = true;
      this.sessionService.NavigateTo(statesHistory[statesHistory.length - 1]);
      Global.statesHistory = statesHistory;
    }
  }

  private handleStatesForBackButton(): void {
    const statesHistory: string[] = Global.statesHistory ? Global.statesHistory : [];
    if (!this.isNavigateBack) {
      statesHistory.push(this.sessionService.currentState);
      Global.statesHistory = statesHistory;
    }

    this.isNavigateBack = false;
  }

  private navigateToLogin(): void {
    this.sessionService.NavigateTo('login');
  }

  private getCompanies(): void {
    if (!this.companies || (this.companies && this.companies.length === 0)) {
      this.staticDataService.companiesSimple.subscribe((data: ISimpleCompany[]) => {
        this.staticDataService.Role.subscribe((statisticOptions: IRole[]): void => {
          const roleList: any = {};
          statisticOptions.forEach((option) => {
            roleList[option.Id] = option.Name;
          });

          data.forEach((company: any) => {
            company.RoleName = roleList[company.RoleId];
          });
        });

        this.companies = data;
        const activeCompany = Global.COMPANY;
        if (!activeCompany && !activeCompany.Id) {
          this.getCurrentCompany();
        } else {
          this.setInformationCurrentCompany(activeCompany);
        }
      });
    }
  }

  private setInformationCurrentCompany(activeCompany: ICompany): void {
    this.activeCompany = activeCompany;
    this.selectedCompanyId = this.activeCompany.Id;
    Global.COMPANY_ID = this.activeCompany.Id;
    this.getCompanyLogo();
    if (this.sessionService.role.IsFullAdmin && !activeCompany.HasAcceptedTerms) {
      this.showAcceptDPADialog = true;
    }
  }

  private getCurrentCompany(): void {
    this.staticDataService.currentCompany.subscribe((activeCompany: ICompany): void => {
      if (activeCompany) {
        Global.COMPANY = activeCompany;
        this.setInformationCurrentCompany(activeCompany);
      }
    });
  }

  private setCompanyContext(companyId: number): void {
    this.dataService.Auth_SetCompanyContext(companyId).subscribe(
      () => {
        Global.COMPANY_ID = companyId;
        this.settingService.showCompanySelector = false;
        this.reloadStaticData()
          .then(() => {})
          .catch(() => {
            this.settingService.ReloadWarrning = false;
          });
      },
      () => {
        this.settingService.ReloadWarrning = false;
      }
    );
  }

  private getCompanyLogo(): void {
    this.staticDataService.currentLogo.subscribe((image: string) => {
      this.logo = image;
      this.showCompanyAbbreviation = true;
      this.currentCompanyName = this.activeCompany.Name;
      this.currentCompanyAbbreviation = this.currentCompanyName
        ? this.currentCompanyName.toUpperCase().substring(0, 3)
        : '';
    });
  }
}
