/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../custom-controls/combobox-edit/combobox-edit.component.ngfactory";
import * as i2 from "../../../custom-controls/combobox-edit/combobox-edit.component";
import * as i3 from "../../../services/session/session.service";
import * as i4 from "./salary-cycle-filter.component";
import * as i5 from "../../../services/employee.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../services/api/static-data.service";
var styles_SalaryCycleFilterComponent = [];
var RenderType_SalaryCycleFilterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SalaryCycleFilterComponent, data: {} });
export { RenderType_SalaryCycleFilterComponent as RenderType_SalaryCycleFilterComponent };
export function View_SalaryCycleFilterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-combobox-edit", [["id", "comboboxSalaryCycle"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.employeeService.salaryIdCycle = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ComboboxEditComponent_0, i1.RenderType_ComboboxEditComponent)), i0.ɵdid(1, 4440064, null, 0, i2.ComboboxEditComponent, [i3.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], idField: [2, "idField"], textField: [3, "textField"], keepDataSourceOrder: [4, "keepDataSourceOrder"], comboboxDataSource: [5, "comboboxDataSource"], disable: [6, "disable"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.employeeService.salaryIdCycle; var currVal_1 = true; var currVal_2 = "Id"; var currVal_3 = "Text"; var currVal_4 = true; var currVal_5 = _co.listSource; var currVal_6 = _co.employeeService.editMode; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_SalaryCycleFilterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-salary-cycle-filter", [], null, null, null, View_SalaryCycleFilterComponent_0, RenderType_SalaryCycleFilterComponent)), i0.ɵdid(1, 245760, null, 0, i4.SalaryCycleFilterComponent, [i5.EmployeeService, i6.TranslateService, i3.SessionService, i7.StaticDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SalaryCycleFilterComponentNgFactory = i0.ɵccf("app-salary-cycle-filter", i4.SalaryCycleFilterComponent, View_SalaryCycleFilterComponent_Host_0, {}, {}, []);
export { SalaryCycleFilterComponentNgFactory as SalaryCycleFilterComponentNgFactory };
