<fieldset>
  <legend class="Card-title Card-title-integrations">
    <span>{{ 'Company.ExternalIntegrations' | translate }}</span>
    <app-button
      *ngIf="!integrationsService.isReadonlyUser"
      (clickEvent)="integrationsService.addNewsDialogVisible = true"
      [isDisabled]="!integrationsService.hasIntegrationModule || integrationsService.editMode"
      buttonClass="right Button--card Button-RunNow"
      [label]="'Company.ExternalIntegrationsCreate' | translate"
    >
    </app-button>
  </legend>
  <div *ngIf="!integrationsService.isEmptyIntegrations" class="scrollviewIntegration">
    <div
      *ngFor="let integrationItem of integrationModel"
      [ngClass]="{ IntegrationListViewActive: integrationItem.Id === integrationsService.selectedId }"
      class="IntegrationListView FormElement-cursorHand"
      id="{{ integrationItem.Id }}"
      (click)="SelectIntegrationListView(integrationItem.Id)"
    >
      <div class="Integrationtitlelistview">
        <a>{{ integrationItem.Name }}</a>
      </div>
      <div class="IntegrationMessagelistview">
        <ul class="ulControlList">
          <li class="IntegrationMessage">
            <a>
              <app-icon
                [tooltip]="getStatusMessage(integrationItem.StatusCode)"
                *ngIf="getStatusMessageColor(integrationItem.StatusCode) === 'Changed'"
                [icon]="'Warning'"
              >
              </app-icon>
              <app-icon
                [tooltip]="getStatusMessage(integrationItem.StatusCode)"
                *ngIf="getStatusMessageColor(integrationItem.StatusCode) === 'Success'"
                [icon]="'Success'"
              >
              </app-icon>
              <app-icon
                [tooltip]="getStatusMessage(integrationItem.StatusCode)"
                *ngIf="getStatusMessageColor(integrationItem.StatusCode) === 'Error'"
                [icon]="'ErrorExclamation'"
              >
              </app-icon>
              <app-icon
                [tooltip]="getStatusMessage(integrationItem.StatusCode)"
                *ngIf="getStatusMessageColor(integrationItem.StatusCode) === 'Inactive'"
                [icon]="'SuccessGray'"
              >
              </app-icon>
            </a>
          </li>

          <li class="ButtonsupporturlLi">
            <a *ngIf="checkIntegrationSupportUrl(integrationItem)" (click)="applyUrl(integrationItem)">
              <app-icon [icon]="'Question'"></app-icon>
            </a>
          </li>

          <li class="ButtonsupporturlLi" *ngIf="integrationsService.editMode">
            <a
              class="k-button k-button-icontext k-grid-XCustoom deleteDepartmentButton"
              (click)="onDeleteDialog(integrationItem)"
              >X</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div *ngIf="integrationsService.isEmptyIntegrations">
    <p>{{ 'Company.ExternalIntegrationsEmpty' | translate }}</p>
  </div>
</fieldset>

<app-dialog
  [(visible)]="isShowingDeleteMessage"
  (action)="onDeleteWarningDialogAction($event)"
  [leadingText]="'Grids.ConfirmDelete' | translate"
>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Delete'"></app-dialog-action>
</app-dialog>

<app-dialog
  [overlayClose]="false"
  [width]="500"
  [(visible)]="integrationsService.addNewsDialogVisible"
  (action)="onCreateDialogCreate($event)"
  (visibleChange)="addNewvisibleChange($event)"
  class="integration-dialog"
>
  <app-combobox-edit
    [label]="'Integrations.Createlabel' | translate"
    [editMode]="true"
    [textField]="'Name'"
    [idField]="'Id'"
    [comboboxDataSource]="extenalSystem"
    [(value)]="integrationsService.currentExtenalSystemId"
    [placeholder]="'Company.ExternalIntegrationsCreate' | translate"
    (valueChange)="onBaseIntegrationCreateComboboxChanged()"
  >
  </app-combobox-edit>
  <br />

  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Create'"></app-dialog-action>
</app-dialog>

<app-dialog
  [(visible)]="isShowingChangeIemMessage"
  (action)="onChangeTab($event)"
  [leadingText]="'Employee.UnsavedData' | translate"
>
  <app-dialog-action [type]="'ConfirmDiscard'"></app-dialog-action>
  <app-dialog-action [type]="'ContinueAndSave'"></app-dialog-action>
</app-dialog>
