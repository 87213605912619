<app-dialog
  [overlayClose]="false"
  (action)="onDialogAction($event)"
  [(visible)]="visible"
  [title]="'CompanyTimeEntryTypes.EditTimeEntryTypeTranslationHeader' | translate"
>
  <app-module-check [moduleId]="5">
    <app-grid
      [(data)]="gridData"
      [triggerUpdate]="triggerUpdate"
      (saveChangesEvent)="onSaveChange($event)"
      [editMode]="!IsReadOnly"
    >
      <app-grid-column
        [field]="'LanguageId'"
        [title]="'CompanySalaryTypes.Language' | translate"
        [width]="100"
        [type]="'combobox'"
        [comboboxDataSource]="languages"
        [editable]="false"
        [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
        [comboboxValueField]="'Id'"
        [comboboxDisplayField]="'Name'"
      ></app-grid-column>
      <app-grid-column
        [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
        [field]="'Name'"
        [classField]="'NameClass'"
        [editable]="!IsReadOnly"
        [title]="'CompanyTimeEntryTypes.Name' | translate"
        [width]="150"
        [type]="'text'"
      ></app-grid-column>
      <app-grid-column
        [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
        [field]="'Description'"
        [classField]="'DescriptionClass'"
        [editable]="!IsReadOnly"
        [title]="'CompanyTimeEntryTypes.Description' | translate"
        [width]="150"
        [type]="'text'"
      ></app-grid-column>
      <app-grid-column
        [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
        [field]="'DefaultPayslipText'"
        [classField]="'DefaultPayslipTextClass'"
        [editable]="!IsReadOnly"
        [title]="'CompanyTimeEntryTypes.PayslipText' | translate"
        [width]="150"
        [type]="'text'"
      ></app-grid-column>
    </app-grid>
  </app-module-check>

  <app-dialog-action [type]="'Close'" *ngIf="IsReadOnly"></app-dialog-action>
  <app-dialog-action [type]="'Cancel'" *ngIf="!IsReadOnly"></app-dialog-action>
  <app-dialog-action [type]="'Update'" *ngIf="!IsReadOnly" [disabled]="!childIsDirty[0]"></app-dialog-action>
</app-dialog>
