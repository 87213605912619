import * as tslib_1 from "tslib";
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { CompanyService } from '../../../services/company.service';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/api/data.service";
import * as i2 from "../../../services/api/static-data.service";
import * as i3 from "../../../services/setting.service";
import * as i4 from "../../../services/session/session.service";
import * as i5 from "@uirouter/core";
import * as i6 from "../../../services/broadcast.service";
var CompanyDepartmentService = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyDepartmentService, _super);
    function CompanyDepartmentService(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) {
        var _this = _super.call(this, dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) || this;
        _this.dataService = dataService;
        _this.staticDataService = staticDataService;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.transitionService = transitionService;
        _this.broadcaster = broadcaster;
        _this.gridFlag = 0;
        _this.showInactiveDepartmentsValue = false;
        return _this;
    }
    Object.defineProperty(CompanyDepartmentService.prototype, "departments", {
        get: function () {
            if (!this.departmentSubject) {
                this.departmentSubject = new BehaviorSubject([]);
            }
            return this.departmentSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CompanyDepartmentService.prototype.loadDepartment = function () {
        var _this = this;
        if (!this.departmentSubject) {
            this.departmentSubject = new BehaviorSubject([]);
        }
        this.staticDataService.loadDepartmentsAsync().subscribe(function (departments) {
            _this.gridFlag = 1;
            departments = departments.map(function (dep) {
                dep.GridId = _this.gridFlag++;
                return dep;
            });
            departments = _this.showInactiveDepartments ? departments : departments.filter(function (d) { return d.IsActive; });
            _this.departmentSubject.next(departments);
        });
    };
    CompanyDepartmentService.prototype.updateDepartment = function (model) {
        var _this = this;
        return this.dataService.Companies_UpdateDepartment(model).pipe(tap(function () {
            // this.loadDepartment();
            _this.staticDataService.loadDepartments();
        }));
    };
    CompanyDepartmentService.prototype.createDepartment = function (model) {
        var _this = this;
        return this.dataService.Companies_CreateDepartment(model).pipe(tap(function () {
            // this.loadDepartment();
            _this.staticDataService.loadDepartments();
        }));
    };
    CompanyDepartmentService.prototype.deleteDepartment = function (Id) {
        var _this = this;
        return this.dataService.Companies_DeleteDepartment(Id).pipe(tap(function () {
            _this.loadDepartment();
        }));
    };
    Object.defineProperty(CompanyDepartmentService.prototype, "showInactiveDepartments", {
        get: function () {
            return this.showInactiveDepartmentsValue;
        },
        set: function (value) {
            if (value !== this.showInactiveDepartmentsValue) {
                this.showInactiveDepartmentsValue = value;
                this.loadDepartment();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyDepartmentService.prototype, "allowShortcut", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.configuration.departments';
        },
        enumerable: true,
        configurable: true
    });
    CompanyDepartmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyDepartmentService_Factory() { return new CompanyDepartmentService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.StaticDataService), i0.ɵɵinject(i3.SettingService), i0.ɵɵinject(i4.SessionService), i0.ɵɵinject(i5.TransitionService), i0.ɵɵinject(i6.BroadcastService)); }, token: CompanyDepartmentService, providedIn: "root" });
    return CompanyDepartmentService;
}(CompanyService));
export { CompanyDepartmentService };
