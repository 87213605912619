import { Injectable } from '@angular/core';
import { Global } from 'src/app/common/global';
import { RouterStateService } from '../../common/router-state.service';
import { IAccount, ICompany, ICompanyAccountView, ISimpleCompany, User } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { ModalService } from '../../services/modal.service';
import { SessionService } from '../../services/session/session.service';
import { AccountantServiceBase } from '../accountant-service-base';

@Injectable({
  providedIn: 'root'
})
export class AccountantConfigurationService extends AccountantServiceBase {
  public account: IAccount = {
    Name: '',
    Id: -1,
    StatusId: -1,
    AccountTypeId: -1,
    IsPublic: false,
    DefaultInvoiceTypeId: -1,
    MasterCompanyId: -1,
    Description: '',
    MasterCompanyEmail: null
  };

  public internalCompany: any = {
    Title: 'Accountant.SystemCompany',
    CVR: '',
    Name: '',
    Id: 1,
    CompanyId: -1,
    Address: '',
    PostalCode: '',
    City: '',
    Country: '',
    EAN: ''
  };

  public externalCompany: any = {
    Title: 'Accountant.ExternalCompany',
    CVR: '',
    Name: '',
    Id: 2,
    CompanyId: null,
    Address: '',
    PostalCode: '',
    City: '',
    Country: '',
    EAN: ''
  };

  public currentCompany: any = {};
  public listCompany = [this.internalCompany, this.externalCompany];
  public listCompanies: any;
  public rawAccount = '';
  public rawInternalCompany = '';
  public rawExternalCompany = '';
  public rawCompany = 0;
  public isDirty = false;
  public showSaveDataConfirmationChangeState = false;
  public navigationToStateName = '';

  constructor(
    protected api: DataService,
    protected modalService: ModalService,
    protected sessionService: SessionService,
    public staticDataService: StaticDataService
  ) {
    super(api, modalService, sessionService, staticDataService);
  }

  public loadData(): void {
    this.staticDataService.currentAccount.subscribe((account: IAccount) => {
      if (!account.Description || account.Description === null) {
        account.Description = '';
      }
      if (account.MasterCompanyId) {
        this.api.Companies_Get(account.MasterCompanyId).subscribe((company: ICompany) => {
          this.internalCompany.CVR = company.VATRegistrationNumber || null;
          this.internalCompany.Name = company.Name || '';
          this.internalCompany.Address = company.Address
            ? (company.Address.Line1 || '') + ' ' + (company.Address.Line2 || '')
            : '';
          this.internalCompany.PostalCode = company.Address ? company.Address.PostalCode : '';
          this.internalCompany.City = company.Address ? company.Address.City : '';
          this.internalCompany.Country = company.Country ? company.Country.Name : '';
          this.internalCompany.EAN = company.InvoiceIdentifier || null;
          this.internalCompany.CompanyId = company.Id;

          this.currentCompany = this.internalCompany;
          this.rawCompany = 1;
          this.rawInternalCompany = JSON.stringify(this.internalCompany);
        });
      } else {
        if (!this.externalCompany) {
          return;
        }
        this.externalCompany.CVR = account.MasterCompanyVATRegistrationNumber;
        this.externalCompany.Name = account.MasterCompanyName;
        this.externalCompany.Address = account.MasterCompanyAddress;
        this.externalCompany.PostalCode = account.MasterCompanyPostalCode;
        this.externalCompany.City = account.MasterCompanyCity;
        this.externalCompany.Country = account.MasterCompanyCountry;
        this.externalCompany.EAN = account.MasterCompanyEAN;
        this.externalCompany.CompanyId = null;

        this.currentCompany = this.externalCompany;
        this.rawCompany = 2;
      }

      this.api.Account_GetAccountCompanies().subscribe((data: ICompanyAccountView[]) => {
        this.listCompanies = [];
        data.forEach((company) => {
          if (company.AccountId === account.Id) {
            this.listCompanies.push(company);
          }
        });

        if (!this.internalCompany.Name && this.listCompanies.length > 0) {
          const companyId = this.listCompanies[0].Id;
          this.api.Companies_Get(companyId).subscribe((company: ICompany) => {
            this.internalCompany.CVR = company.VATRegistrationNumber || null;
            this.internalCompany.Name = company.Name || '';
            this.internalCompany.Address = company.Address
              ? (company.Address.Line1 || '') + ' ' + (company.Address.Line2 || '')
              : '';
            this.internalCompany.PostalCode = company.Address ? company.Address.PostalCode : '';
            this.internalCompany.City = company.Address ? company.Address.City : '';
            this.internalCompany.Country = company.Country ? company.Country.Name : '';
            this.internalCompany.EAN = company.InvoiceIdentifier || null;
            this.internalCompany.CompanyId = company.Id;

            this.rawInternalCompany = JSON.stringify(this.internalCompany);
          });
        }
      });

      this.account = account;
      this.rawAccount = JSON.stringify(account);
      this.rawInternalCompany = JSON.stringify(this.internalCompany);
      this.rawExternalCompany = JSON.stringify(this.externalCompany);
    });
  }

  protected getRoles(): void {
    this.getRoleServicePublic();
  }

  public getRoleServicePublic(): void {}

  protected applyFilter(): void {}

  protected updateData(model: User[]): void {}

  protected reloadData(): void {
    this.loadData();
  }
}
