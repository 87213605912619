<kendo-grid
  [ngClass]="{ 'mini-pager': showMiniPager, 'non-edit-grid': !editMode }"
  [class.disabled]="disabled"
  #kendoGridComponent
  [data]="gridData"
  (cellClick)="cellClickHandler($event)"
  (cellClose)="cellCloseHandler($event)"
  (remove)="removeHandler($event)"
  (add)="addHandler($event)"
  [navigable]="true"
  [selectable]="isSelected"
  [rowClass]="rowCallback"
  (selectionChange)="onSelectionChange($event)"
  [kendoGridSelectBy]="idColumn"
  [selectedKeys]="selectedRows"
  (dblclick)="onDbClickChange($event)"
  (keydown)="onKeyDown($event)"
  [resizable]="true"
  [filterable]="filterable"
  [filter]="filter"
  (dataStateChange)="onDataStateHandler($event)"
  (filterChange)="onFilterChange($event)"
  [sortable]="{ allowUnsort: true, mode: 'multiple' }"
  (sortChange)="onSortChange($event)"
  [sort]="sort"
  [pageSize]="pageSize"
  [skip]="skip"
  [pageable]="pageListSetting"
  [height]="height"
  (pageChange)="pageChange($event)"
  scrollable="scrollable"
  [rowHeight]="rowHeight"
  [pageSize]="pageSize"
  [skip]="skip"
>
  <kendo-grid-messages
    noRecords=""
    pagerOf="{{ 'Kendo.pagerOf' | translate }}"
    pagerItems="{{ 'Kendo.pagerItems' | translate }}"
    [filterIsTrue]="'Filter.IsTrue' | translate"
    [filterIsFalse]="'Filter.IsFalse' | translate"
    [filterClearButton]="'Filter.Clear' | translate"
    [filterFilterButton]="'Filter.Filter' | translate"
  ></kendo-grid-messages>

  <ng-template
    kendoGridToolbarTemplate
    *ngIf="
      showButDisableAddNewButton ||
      ((editMode || allowActionNonEditmode) &&
        (addNew || (actions && actions.length > 0) || (radios && radios.length > 0)))
    "
  >
    <span kendoTooltip [title]="addNewTooltips" (click)="AddNewDisableClick()">
      <button *ngIf="addNew" kendoGridAddCommand [disabled]="addNewDisable">{{ addNewText }}</button>
    </span>
    <span *ngIf="actions && actions.length > 0">
      <span *ngFor="let action of actions" [ngClass]="action.actionsSpanClass">
        <button
          class="k-button"
          *ngIf="!action.isHidden && !action.icon"
          [disabled]="action.disable"
          (click)="onGridToolbarClick(action.action)"
        >
          {{ action.label }}
        </button>
        <button
          class="k-button"
          *ngIf="!action.isHidden && action.icon"
          [disabled]="action.disable"
          (click)="onGridToolbarClick(action.action)"
        >
          <svg aria-hidden="true" [ngClass]="'Icon Icon--xsmall'">
            <use [attr.xlink:href]="'#' + action.icon"></use>
          </svg>
        </button>
      </span>
    </span>

    <span *ngIf="radios && radios.length > 0" class="Grid-radio-action">
      <app-radio-edit [editMode]="true" [(value)]="radioValue" (valueChange)="onGridRadioToolbarClick()">
        <span *ngFor="let radio of radios">
          <app-radio-edit-option [value]="radio.value" [label]="radio.label | translate"> </app-radio-edit-option>
        </span>
      </app-radio-edit>
    </span>
  </ng-template>

  <ng-template *ngIf="showMiniPager" kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <span class="page-count">{{ currentPage }}/{{ totalPages }}</span>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
  </ng-template>

  <kendo-grid-column-group
    *ngIf="isUseSpanHeader"
    [headerClass]="groupHeaderClass"
    [title]="groupHeader"
    [width]="groupWidth"
    [locked]="groupLocked"
  >
    <ng-template kendoGridHeaderTemplate let-column>
      <div class="header-tooltip-overlay" kendoTooltip [title]="groupTooltip || column.title"></div>
      <div class="label-header-cell" [ngClass]="{ 'label-header-cell-non-filter': !column.isFilterable }">
        <a
          class="header-label-text"
          [ngClass]="{
            'label-header-cell-text-style': column.title && groupTooltip && column.title !== groupTooltip
          }"
        >
          {{ column.title }}
        </a>
      </div>
    </ng-template>

    <kendo-grid-column
      *ngFor="let subColumn of groupColumns; trackBy: trackIndex"
      [locked]="subColumn.locked"
      [field]="subColumn.field"
      [title]="subColumn.title"
      [width]="subColumn.width"
      [minResizableWidth]="subColumn.minResizableWidth"
      [editable]="
        subColumn.type === 'checkbox' || subColumn.type === 'checkboxgrid' ? false : subColumn.editable && editMode
      "
      [class]="cellClass(subColumn)"
      [headerClass]="subColumn.addOnClass"
      [filterable]="subColumn.isFilterable"
      [filter]="subColumn.filter"
      [format]="subColumn.format"
      [hidden]="subColumn.hideColumn"
    >
      <ng-template
        *ngIf="subColumn.headerTooltip || subColumn.helpLink || subColumn.title"
        kendoGridHeaderTemplate
        let-column
      >
        <ng-template #headerTooltipTemplate class="FormElement-required">
          <span
            class="{{ subColumn.tooltipTemplteClass }}"
            [innerHTML]="subColumn.headerTooltip || subColumn.title"
          ></span>
        </ng-template>
        <div [tooltipTemplate]="headerTooltipTemplate" class="header-tooltip-overlay" kendoTooltip [title]="''"></div>
        <div
          class="label-header-cell"
          [ngClass]="{ 'label-header-cell-non-filter': !column.isFilterable }"
          showAfter="0"
        >
          <a
            class="header-label-text"
            [ngClass]="{
              'label-header-cell-text-style':
                column.title && subColumn.headerTooltip && column.title !== subColumn.headerTooltip
            }"
          >
            {{ column.title }}
          </a>

          <app-icon
            *ngIf="subColumn.helpLink"
            [icon]="'Question'"
            [tooltip]="'GlobalDialog.ReadMore' | translate"
            [link]="subColumn.helpLink"
          >
          </app-icon>
        </div>
      </ng-template>

      <ng-template
        kendoGridFilterMenuTemplate
        let-column="column"
        let-filter="filter"
        let-filterService="filterService"
      >
        <ng-container [ngSwitch]="subColumn.type">
          <ng-container *ngSwitchCase="'text'">
            <kendo-grid-string-filter-menu
              [column]="column"
              [filter]="filter"
              [extra]="false"
              [filterService]="filterService"
            >
              <kendo-filter-eq-operator [text]="'Filter.IsEqualTo' | translate"></kendo-filter-eq-operator>
              <kendo-filter-neq-operator [text]="'Filter.IsNotEqualTo' | translate"></kendo-filter-neq-operator>
              <kendo-filter-contains-operator [text]="'Filter.Contains' | translate"></kendo-filter-contains-operator>
              <kendo-filter-not-contains-operator [text]="'Filter.DoesNotContain' | translate">
              </kendo-filter-not-contains-operator>
              <kendo-filter-startswith-operator [text]="'Filter.StartsWith' | translate">
              </kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator [text]="'Filter.EndsWith' | translate"></kendo-filter-endswith-operator>
              <kendo-filter-isnull-operator [text]="'Filter.IsNull' | translate"></kendo-filter-isnull-operator>
              <kendo-filter-isnotnull-operator [text]="'Filter.IsNotNull' | translate">
              </kendo-filter-isnotnull-operator>
            </kendo-grid-string-filter-menu>
          </ng-container>
          <ng-container *ngSwitchCase="'numeric'">
            <kendo-grid-numeric-filter-menu
              [column]="column"
              [extra]="false"
              operator="eq"
              [filter]="filter"
              [filterService]="filterService"
            >
              <kendo-filter-eq-operator [text]="'Filter.IsEqualTo' | translate"></kendo-filter-eq-operator>
              <kendo-filter-neq-operator [text]="'Filter.IsNotEqualTo' | translate"></kendo-filter-neq-operator>
              <kendo-filter-gt-operator [text]="'Filter.IsGreaterThan' | translate"></kendo-filter-gt-operator>
              <kendo-filter-gte-operator [text]="'Filter.IsGreaterThanOrEqualTo' | translate">
              </kendo-filter-gte-operator>
              <kendo-filter-lt-operator [text]="'Filter.IsLessThan' | translate"></kendo-filter-lt-operator>
              <kendo-filter-lte-operator [text]="'Filter.IsLessThanOrEqualTo' | translate"> </kendo-filter-lte-operator>
              <kendo-filter-isnull-operator [text]="'Filter.IsNull' | translate"></kendo-filter-isnull-operator>
              <kendo-filter-isnotnull-operator [text]="'Filter.IsNotNull' | translate">
              </kendo-filter-isnotnull-operator>
            </kendo-grid-numeric-filter-menu>
          </ng-container>
          <ng-container *ngSwitchCase="'datepicker'">
            <kendo-grid-date-filter-menu
              [column]="column"
              [extra]="false"
              [filter]="filter"
              [filterService]="filterService"
              operator="eq"
              [formatPlaceholder]="{
                year: 'yyyy',
                month: 'MM',
                day: 'dd'
              }"
            >
              <kendo-filter-eq-operator [text]="'Filter.IsEqualTo' | translate"></kendo-filter-eq-operator>
              <kendo-filter-neq-operator [text]="'Filter.IsNotEqualTo' | translate"></kendo-filter-neq-operator>
              <kendo-filter-after-operator [text]="'Filter.IsAfter' | translate"></kendo-filter-after-operator>
              <kendo-filter-after-eq-operator [text]="'Filter.IsAfterOrEqualTo' | translate">
              </kendo-filter-after-eq-operator>
              <kendo-filter-before-operator [text]="'Filter.IsBefore' | translate"></kendo-filter-before-operator>
              <kendo-filter-before-eq-operator [text]="'Filter.IsBeforeOrEqualTo' | translate">
              </kendo-filter-before-eq-operator>
              <kendo-filter-isnull-operator [text]="'Filter.IsNull' | translate"></kendo-filter-isnull-operator>
              <kendo-filter-isnotnull-operator [text]="'Filter.IsNotNull' | translate">
              </kendo-filter-isnotnull-operator>
            </kendo-grid-date-filter-menu>
          </ng-container>
          <ng-container *ngSwitchCase="'checkbox'">
            <kendo-grid-boolean-filter-menu [column]="column" [filter]="filter" [filterService]="filterService">
            </kendo-grid-boolean-filter-menu>
          </ng-container>

          <ng-container *ngSwitchCase="'checkboxgrid'">
            <kendo-grid-boolean-filter-menu [column]="column" [filter]="filter" [filterService]="filterService">
            </kendo-grid-boolean-filter-menu>
          </ng-container>

          <ng-container *ngSwitchCase="'combobox'">
            <!-- <kendo-multiselect
            style="width:220px"
            [(data)]="subColumn.comboboxDataSource"
            [textField]="subColumn.comboboxDisplayField"
            [valueField]="subColumn.comboboxValueField"
            [valuePrimitive]="true"
            (valueChange)="subColumn.onComboboxFilterChange($event, filterService)"
          >
          </kendo-multiselect> -->
            <app-dropdown-filter
              [(data)]="subColumn.comboboxDataSource"
              [textField]="subColumn.comboboxDisplayField"
              [valueField]="subColumn.comboboxValueField"
              [fieldName]="subColumn.field"
              valuePrimitive="true"
              [selectedValue]="subColumn.selectedValue"
              (filterChange)="subColumn.onFilterChange($event, filterService)"
            >
            </app-dropdown-filter>
          </ng-container>
          <ng-container *ngSwitchCase="'timepicker'">
            <kendo-grid-string-filter-menu
              [column]="column"
              [filter]="filter"
              [extra]="false"
              [filterService]="filterService"
            >
              <kendo-filter-eq-operator [text]="'Filter.IsEqualTo' | translate"></kendo-filter-eq-operator>
              <kendo-filter-neq-operator [text]="'Filter.IsNotEqualTo' | translate"></kendo-filter-neq-operator>
              <kendo-filter-contains-operator [text]="'Filter.Contains' | translate"></kendo-filter-contains-operator>
              <kendo-filter-not-contains-operator [text]="'Filter.DoesNotContain' | translate">
              </kendo-filter-not-contains-operator>
              <kendo-filter-startswith-operator [text]="'Filter.StartsWith' | translate">
              </kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator [text]="'Filter.EndsWith' | translate"></kendo-filter-endswith-operator>
              <kendo-filter-isnull-operator [text]="'Filter.IsNull' | translate"></kendo-filter-isnull-operator>
              <kendo-filter-isnotnull-operator [text]="'Filter.IsNotNull' | translate">
              </kendo-filter-isnotnull-operator>
            </kendo-grid-string-filter-menu>
          </ng-container>
          <ng-container *ngSwitchCase="'autocomplete'">
            <kendo-grid-string-filter-menu
              [column]="column"
              [filter]="filter"
              [extra]="false"
              [filterService]="filterService"
            >
              <kendo-filter-eq-operator [text]="'Filter.IsEqualTo' | translate"></kendo-filter-eq-operator>
              <kendo-filter-neq-operator [text]="'Filter.IsNotEqualTo' | translate"></kendo-filter-neq-operator>
              <kendo-filter-contains-operator [text]="'Filter.Contains' | translate"></kendo-filter-contains-operator>
              <kendo-filter-not-contains-operator [text]="'Filter.DoesNotContain' | translate">
              </kendo-filter-not-contains-operator>
              <kendo-filter-startswith-operator [text]="'Filter.StartsWith' | translate">
              </kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator [text]="'Filter.EndsWith' | translate"></kendo-filter-endswith-operator>
              <kendo-filter-isnull-operator [text]="'Filter.IsNull' | translate"></kendo-filter-isnull-operator>
              <kendo-filter-isnotnull-operator [text]="'Filter.IsNotNull' | translate">
              </kendo-filter-isnotnull-operator>
            </kendo-grid-string-filter-menu>
          </ng-container>
          <ng-container *ngSwitchCase="'percentage'">
            <app-percentage-edit
              *ngSwitchCase="'percentage'"
              [rightText]="true"
              [editMode]="true"
              [step]="true"
              [percentageSign]="false"
              [allowDecimal]="subColumn.percentageDecimals"
              (valueChange)="subColumn.onPercentageChange($event, filterService)"
              [setTimeout]="false"
            >
            </app-percentage-edit>
          </ng-container>
          <ng-container *ngSwitchCase="'image'">
            <app-dropdown-filter
              [(data)]="subColumn.comboboxDataSource"
              [textField]="subColumn.comboboxDisplayField"
              [valueField]="subColumn.comboboxValueField"
              [fieldName]="subColumn.field"
              valuePrimitive="true"
              [selectedValue]="subColumn.selectedValue"
              (filterChange)="subColumn.onFilterChange($event, filterService)"
            >
            </app-dropdown-filter>
          </ng-container>
        </ng-container>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <ng-container [ngSwitch]="subColumn.type">
          <ng-container *ngSwitchCase="'text'">
            <span
              *ngIf="subColumn.text"
              [ngClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
              kendoTooltip
              [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
            >
              {{ subColumn.text }}
            </span>
            <app-text-edit
              *ngIf="!subColumn.text"
              [viewClass]="subColumn.viewClass"
              [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
              [editMode]="false"
              [(value)]="dataItem[subColumn.field]"
              [setTimeout]="false"
              kendoTooltip
              [isGrid]="true"
              [addonIcon]="
                subColumn.addOnIcon
                  ? subColumn.addOnIcon
                  : dataItem && subColumn.addOnIconFieldString
                  ? dataItem[subColumn.addOnIconFieldString]
                  : ''
              "
              [icon]="dataItem && subColumn.IconFieldString ? dataItem[subColumn.IconFieldString] : ''"
              (iconClick)="iconTextEditControlClick($event, dataItem)"
              [addonTooltip]="
                dataItem && subColumn.IconTooltipFieldString
                  ? (dataItem[subColumn.IconTooltipFieldString] | translate)
                  : ''
              "
              [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
              (addonClick)="subColumn.addonClick.emit()"
            >
            </app-text-edit>
          </ng-container>

          <ng-container *ngSwitchCase="'numeric'">
            <app-numeric-edit
              [viewClass]="subColumn.viewClass"
              [rightText]="true"
              [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
              [editMode]="false"
              [(value)]="dataItem[subColumn.field]"
              [option]="subColumn.numbericOption"
              [isGrid]="true"
              [setTimeout]="false"
              kendoTooltip
              [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
            >
            </app-numeric-edit>
            <app-grid-in-cell-popup
              *ngIf="subColumn.hasIncellPopup"
              [content]="subColumn.popupContent"
              [actions]="subColumn.popupActions"
              [visible]="dataItem[subColumn.field + 'PopupVisible']"
              (click)="onIncellPopUpClickAction(dataItem, dataItem.triggerField, $event)"
            >
            </app-grid-in-cell-popup>
          </ng-container>

          <app-check-edit
            *ngSwitchCase="'checkbox'"
            customClass="checkBox-center"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            [isGrid]="true"
            [editMode]="subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable"
            [(value)]="dataItem[subColumn.field]"
            (valueChange)="onCheckBoxCheckedChanged(dataItem, subColumn.field)"
            [tooltip]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          >
          </app-check-edit>

          <app-grid-checkbox
            *ngSwitchCase="'checkboxgrid'"
            customClass="checkBox-center"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            [isGrid]="true"
            [editMode]="
              editMode ? (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) : false
            "
            [(value)]="dataItem[subColumn.field]"
            (valueChange)="onCheckBoxCheckedChanged(dataItem, subColumn.field, true)"
            [tooltip]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          >
          </app-grid-checkbox>

          <app-combobox-edit
            *ngSwitchCase="'combobox'"
            [viewClass]="subColumn.viewClass"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            [isGrid]="true"
            [noToggle]="true"
            [editMode]="false"
            [idField]="subColumn.comboboxValueField"
            [filterFields]="subColumn.comboboxFilterFields"
            [textField]="subColumn.comboboxDisplayField"
            [comboboxDataSource]="subColumn.comboboxDataSource"
            [(value)]="dataItem[subColumn.field]"
            [tooltipField]="subColumn.tooltipField ? subColumn.tooltipField : ''"
            kendoTooltip
            [title]="subColumn.tooltipFieldNonEdit ? dataItem[subColumn.tooltipFieldNonEdit] : ''"
            [nullValueLabel]="subColumn.allowComboeditNull ? ('DropdownList.None' | translate) : ''"
            [defaultnullvalue]="subColumn.defaultnullvalue"
            [CurrentSelectTextTooltip]="
              subColumn.CurrentTextComboboxToolTipFeild
                ? dataItem[subColumn.CurrentTextComboboxToolTipFeild]
                : subColumn.CurrentTextComboboxToolTip
            "
            [setTimeout]="false"
          >
          </app-combobox-edit>

          <app-date-picker
            *ngSwitchCase="'datepicker'"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            (customData)="onDataChanged(dataItem, subColumn.field)"
            [isGrid]="true"
            [editMode]="false"
            [minDate]="subColumn.minDate"
            [(value)]="dataItem[subColumn.field]"
            kendoTooltip
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
            [viewClass]="subColumn.viewClass"
          >
          </app-date-picker>

          <app-time-pickerv
            *ngSwitchCase="'timepicker'"
            [ngClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            [editMode]="false"
            [setTimeout]="false"
            [(value)]="dataItem[subColumn.field]"
            kendoTooltip
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
            [isGrid]="true"
          >
          </app-time-pickerv>

          <app-percentage-edit
            *ngSwitchCase="'percentage'"
            [rightText]="true"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            [isGrid]="true"
            [editMode]="false"
            [allowDecimal]="subColumn.percentageDecimals"
            [(value)]="dataItem[subColumn.field]"
            kendoTooltip
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
            [setTimeout]="false"
          >
          </app-percentage-edit>

          <app-auto-complete-textbox
            *ngSwitchCase="'autocomplete'"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            [isGrid]="true"
            [editMode]="false"
            [autoCompleteDataSource]="subColumn.comboboxDataSource"
            [viewClass]="subColumn.viewClass"
            [(value)]="dataItem[subColumn.field]"
            [textField]="subColumn.comboboxDisplayField"
            kendoTooltip
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
            [autocompleteby1value]="subColumn.autocompleteby1value"
            [setTimeout]="false"
          >
          </app-auto-complete-textbox>

          <app-icon
            *ngSwitchCase="'icon'"
            (click)="appIconClick(dataItem, subColumn.iconAction)"
            [icon]="dataItem[subColumn.iconField]"
            [tooltip]="subColumn.tooltip || dataItem[subColumn.tooltipField] || '' | translate"
            [disable]="subColumn.disableButtonFeild ? dataItem[subColumn.disableButtonFeild] : false"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : 'align-center'"
          >
          </app-icon>

          <div class="align-center" *ngSwitchCase="'buttonPopUp'">
            <app-popup-button
              [label]="subColumn.popupButtonText"
              [buttonClass]="subColumn.popupButtonClass"
              [popupButtonIcon]="subColumn.popupButtonArrayContentIcon"
              (IconClick)="appIconClick(dataItem, $event)"
              [isUseLabelAppIcon]="subColumn.isPopupButtonIcon"
              [icon]="subColumn.popupButtonIcon"
              (ButtonClick)="ButtonPopUpClick.emit()"
            >
            </app-popup-button>
          </div>

          <div kendoTooltip [title]="subColumn.disableGridButtonTooltip | translate" *ngSwitchCase="'button'">
            <app-button
              [buttonClass]="dataItem[subColumn.buttonClass] || subColumn.buttonClass"
              [tooltip]="subColumn.buttonToottip | translate"
              [isDisabled]="
                subColumn.disableButtonFeild ? dataItem[subColumn.disableButtonFeild] : subColumn.disableGridButtonValue
              "
              [label]="subColumn.buttonLabel | translate"
              (clickEvent)="
                onGridButtonClickEvent(
                  dataItem,
                  subColumn.buttonAction,
                  subColumn.disableButtonFeild
                    ? dataItem[subColumn.disableButtonFeild]
                    : subColumn.disableGridButtonValue
                )
              "
            >
            </app-button>
          </div>

          <app-grid-flag-column
            *ngSwitchCase="'image'"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : 'align-center'"
            [countryCode]="dataItem[subColumn.field]"
          >
          </app-grid-flag-column>

          <span
            *ngSwitchCase="'subProberty'"
            kendoTooltip
            [title]="
              subColumn.tooltip || subColumn.isUseSubcolumTooltip
                ? getParamSubProberty(dataItem, subColumn.subProberty)
                : dataItem[subColumn.tooltipField] || ''
            "
            [ngClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          >
            {{ getParamSubProberty(dataItem, subColumn.subProberty) }}
          </span>
        </ng-container>
      </ng-template>

      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <ng-container [ngSwitch]="subColumn.type">
          <ng-container *ngSwitchCase="'text'">
            <span
              *ngIf="subColumn.text"
              [ngClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
              kendoTooltip
              [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
            >
              {{ subColumn.text }}
            </span>
            <app-text-edit
              *ngIf="!subColumn.text"
              [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
              [isGrid]="true"
              [editMode]="
                (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
                (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
              "
              [(value)]="dataItem[subColumn.field]"
              (valueChange)="onDataChanged(dataItem, subColumn.field)"
              [setTimeout]="false"
              kendoTooltip
              [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
              [type]="subColumn.appTextType"
            >
            </app-text-edit>
          </ng-container>

          <ng-container *ngSwitchCase="'numeric'">
            <app-numeric-edit
              [viewClass]="subColumn.viewClass"
              [rightText]="true"
              [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
              [isGrid]="true"
              [editMode]="
                (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
                (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
              "
              [(value)]="dataItem[subColumn.field]"
              [option]="subColumn.numbericOption"
              (valueChange)="onDataChanged(dataItem, subColumn.field, dataItem[subColumn.editableField])"
              [setTimeout]="false"
              kendoTooltip
              [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
              (keydowns)="onKeyDown($event)"
              [addOnButtonEdit]="subColumn.addOnButtonEdit"
              [addOnButtonEditTooltip]="subColumn.addOnButtonEditTooltip"
              [addOnButtonEditClass]="subColumn.addOnButtonEditClass"
              [addOnButtonEditClassFormControl]="subColumn.addOnButtonEditClassFormControl"
              [addOnButtonEditAction]="subColumn.addOnButtonEditAction"
              (addOnButtonClick)="onGridButtonClickEvent(dataItem, $event)"
              [addOnButtonEditDisabled]="
                subColumn.addOnButtonEditDisabledFeild ? dataItem[subColumn.addOnButtonEditDisabledFeild] : false
              "
              [addOnButtonEditIcon]="subColumn.addOnButtonEditIcon"
              [addOnButtonEditLabel]="subColumn.addOnButtonEditLabel"
              [addOnButtonEditIconEnable]="subColumn.addOnButtonEditIconEnable"
            >
            </app-numeric-edit>
          </ng-container>

          <app-combobox-edit
            *ngSwitchCase="'combobox'"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            [editMode]="
              (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
              (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
            "
            [isGrid]="true"
            [noToggle]="true"
            [textField]="subColumn.comboboxDisplayField"
            [idField]="subColumn.comboboxValueField"
            [tooltipField]="subColumn.tooltipField ? subColumn.tooltipField : 'Description'"
            [filterFields]="subColumn.comboboxFilterFields"
            [comboboxDataSource]="subColumn.comboboxDataSource"
            [(value)]="dataItem[subColumn.field]"
            (valueChange)="
              onDataChanged(dataItem, subColumn.field, subColumn.editableField, subColumn.allowComboeditNull)
            "
            [setTimeout]="false"
            kendoTooltip
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
            [nullValueLabel]="subColumn.allowComboeditNull ? ('DropdownList.None' | translate) : ''"
            [defaultnullvalue]="subColumn.defaultnullvalue"
            [CurrentSelectTextTooltip]="
              subColumn.CurrentTextComboboxToolTipFeild
                ? dataItem[subColumn.CurrentTextComboboxToolTipFeild]
                : subColumn.CurrentTextComboboxToolTip
            "
            (tabClick)="onKeyDown($event)"
          >
          </app-combobox-edit>

          <app-date-picker
            *ngSwitchCase="'datepicker'"
            [editMode]="
              (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
              (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
            "
            [isGrid]="true"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            [(value)]="dataItem[subColumn.field]"
            (valueChange)="onDataChanged(dataItem, subColumn.field)"
            kendoTooltip
            [minDate]="subColumn.minDate"
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
            (customData)="onDataChanged(dataItem, subColumn.field)"
            (directional)="onKeyDown($event)"
            [viewClass]="subColumn.viewClass"
            [AllowNull]="subColumn.AllowNull"
          >
          </app-date-picker>

          <app-time-pickerv
            *ngSwitchCase="'timepicker'"
            [ngClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            [editMode]="
              (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
              (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
            "
            [setTimeout]="false"
            [(value)]="dataItem[subColumn.field]"
            (valueChange)="onDataChanged(dataItem, subColumn.field, dataItem[subColumn.editableField])"
            kendoTooltip
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
            [isGrid]="true"
          >
          </app-time-pickerv>

          <app-percentage-edit
            *ngSwitchCase="'percentage'"
            [rightText]="true"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            [isGrid]="true"
            [step]="true"
            [percentageSign]="false"
            [editMode]="
              (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
              (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
            "
            [allowDecimal]="subColumn.percentageDecimals"
            [(value)]="dataItem[subColumn.field]"
            (valueChange)="onDataChanged(dataItem, subColumn.field)"
            [setTimeout]="false"
            kendoTooltip
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          >
          </app-percentage-edit>

          <app-auto-complete-textbox
            *ngSwitchCase="'autocomplete'"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            [isGrid]="true"
            [editMode]="
              (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
              (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
            "
            [autoCompleteDataSource]="subColumn.comboboxDataSource"
            [(value)]="dataItem[subColumn.field]"
            [textField]="subColumn.comboboxDisplayField"
            (valueChange)="onDataChanged(dataItem, subColumn.field)"
            [setTimeout]="false"
            kendoTooltip
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
            [autocompleteby1value]="subColumn.autocompleteby1value"
          >
          </app-auto-complete-textbox>

          <app-icon
            *ngSwitchCase="'icon'"
            (click)="appIconClick(dataItem, subColumn.iconAction)"
            [icon]="dataItem[subColumn.iconField]"
            [tooltip]="subColumn.tooltip || dataItem[subColumn.tooltipField] || '' | translate"
            [disable]="subColumn.disableButtonFeild ? dataItem[subColumn.disableButtonFeild] : false"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : 'align-center'"
          >
          </app-icon>

          <app-grid-flag-column
            *ngSwitchCase="'image'"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : 'align-center'"
            [countryCode]="dataItem[subColumn.field]"
          >
          </app-grid-flag-column>

          <div *ngSwitchCase="'buttonPopUp'">
            <app-popup-button
              [label]="subColumn.popupButtonText"
              [buttonClass]="subColumn.popupButtonClass"
              [popupButtonIcon]="subColumn.popupButtonArrayContentIcon"
              (IconClick)="appIconClick(dataItem, $event)"
              [isUseLabelAppIcon]="subColumn.isPopupButtonIcon"
              [icon]="subColumn.popupButtonIcon"
            >
            </app-popup-button>
          </div>

          <div kendoTooltip [title]="subColumn.disableGridButtonTooltip | translate" *ngSwitchCase="'button'">
            <app-button
              [buttonClass]="dataItem[subColumn.buttonClass] || subColumn.buttonClass"
              [tooltip]="subColumn.buttonToottip | translate"
              [isDisabled]="
                subColumn.disableButtonFeild ? dataItem[subColumn.disableButtonFeild] : subColumn.disableGridButtonValue
              "
              [label]="subColumn.buttonLabel | translate"
              (clickEvent)="
                onGridButtonClickEvent(
                  dataItem,
                  subColumn.buttonAction,
                  subColumn.disableButtonFeild
                    ? dataItem[subColumn.disableButtonFeild]
                    : subColumn.disableGridButtonValue
                )
              "
            >
            </app-button>
          </div>

          <span
            *ngSwitchCase="'subProberty'"
            kendoTooltip
            [title]="
              subColumn.tooltip || subColumn.isUseSubcolumTooltip
                ? getParamSubProberty(dataItem, subColumn.subProberty)
                : dataItem[subColumn.tooltipField] || ''
            "
            [ngClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          >
            {{ getParamSubProberty(dataItem, subColumn.subProberty) }}
          </span>
        </ng-container>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column
    *ngFor="let subColumn of columns; trackBy: trackIndex"
    [locked]="subColumn.locked"
    [field]="subColumn.field"
    [title]="subColumn.title"
    [width]="subColumn.width"
    [minResizableWidth]="subColumn.minResizableWidth"
    [editable]="
      subColumn.type === 'checkbox' || subColumn.type === 'checkboxhalf' || subColumn.type === 'checkboxgrid'
        ? false
        : subColumn.editable && editMode
    "
    [class]="cellClass(subColumn)"
    [headerClass]="subColumn.addOnClass"
    [filterable]="subColumn.isFilterable"
    [filter]="subColumn.filter"
    [format]="subColumn.format"
    [hidden]="subColumn.hideColumn"
  >
    <ng-template
      *ngIf="subColumn.headerTooltip || subColumn.helpLink || subColumn.title"
      kendoGridHeaderTemplate
      let-column
    >
      <ng-template #headerTooltipTemplate class="FormElement-required">
        <span
          class="{{ subColumn.tooltipTemplteClass }}"
          [innerHTML]="subColumn.headerTooltip || subColumn.title"
        ></span>
      </ng-template>
      <div [tooltipTemplate]="headerTooltipTemplate" class="header-tooltip-overlay" kendoTooltip [title]="''"></div>

      <div class="label-header-cell" [ngClass]="{ 'label-header-cell-non-filter': !column.isFilterable }" showAfter="0">
        <a
          class="header-label-text"
          [ngClass]="{
            'label-header-cell-text-style':
              column.title && subColumn.headerTooltip && column.title !== subColumn.headerTooltip
          }"
        >
          {{ column.title }}

          <app-icon
            *ngIf="subColumn.helpLink"
            [icon]="'Question'"
            [tooltip]="'GlobalDialog.ReadMore' | translate"
            [link]="subColumn.helpLink"
          >
          </app-icon>
        </a>
      </div>
    </ng-template>

    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <ng-container [ngSwitch]="subColumn.type">
        <ng-container *ngSwitchCase="'text'">
          <kendo-grid-string-filter-menu
            [column]="column"
            [filter]="filter"
            [extra]="false"
            [filterService]="filterService"
          >
            <kendo-filter-eq-operator [text]="'Filter.IsEqualTo' | translate"></kendo-filter-eq-operator>
            <kendo-filter-neq-operator [text]="'Filter.IsNotEqualTo' | translate"></kendo-filter-neq-operator>
            <kendo-filter-contains-operator [text]="'Filter.Contains' | translate"></kendo-filter-contains-operator>
            <kendo-filter-not-contains-operator [text]="'Filter.DoesNotContain' | translate">
            </kendo-filter-not-contains-operator>
            <kendo-filter-startswith-operator [text]="'Filter.StartsWith' | translate">
            </kendo-filter-startswith-operator>
            <kendo-filter-endswith-operator [text]="'Filter.EndsWith' | translate"></kendo-filter-endswith-operator>
            <kendo-filter-isnull-operator [text]="'Filter.IsNull' | translate"></kendo-filter-isnull-operator>
            <kendo-filter-isnotnull-operator [text]="'Filter.IsNotNull' | translate"> </kendo-filter-isnotnull-operator>
          </kendo-grid-string-filter-menu>
        </ng-container>
        <ng-container *ngSwitchCase="'numeric'">
          <kendo-grid-numeric-filter-menu
            [column]="column"
            [extra]="false"
            operator="eq"
            [filter]="filter"
            [filterService]="filterService"
          >
            <kendo-filter-eq-operator [text]="'Filter.IsEqualTo' | translate"></kendo-filter-eq-operator>
            <kendo-filter-neq-operator [text]="'Filter.IsNotEqualTo' | translate"></kendo-filter-neq-operator>
            <kendo-filter-gt-operator [text]="'Filter.IsGreaterThan' | translate"></kendo-filter-gt-operator>
            <kendo-filter-gte-operator [text]="'Filter.IsGreaterThanOrEqualTo' | translate">
            </kendo-filter-gte-operator>
            <kendo-filter-lt-operator [text]="'Filter.IsLessThan' | translate"></kendo-filter-lt-operator>
            <kendo-filter-lte-operator [text]="'Filter.IsLessThanOrEqualTo' | translate"> </kendo-filter-lte-operator>
            <kendo-filter-isnull-operator [text]="'Filter.IsNull' | translate"></kendo-filter-isnull-operator>
            <kendo-filter-isnotnull-operator [text]="'Filter.IsNotNull' | translate"> </kendo-filter-isnotnull-operator>
          </kendo-grid-numeric-filter-menu>
        </ng-container>
        <ng-container *ngSwitchCase="'datepicker'">
          <kendo-grid-date-filter-menu
            [column]="column"
            [extra]="false"
            [filter]="filter"
            [filterService]="filterService"
            operator="eq"
            [formatPlaceholder]="{
              year: 'yyyy',
              month: 'MM',
              day: 'dd'
            }"
          >
            <kendo-filter-eq-operator [text]="'Filter.IsEqualTo' | translate"></kendo-filter-eq-operator>
            <kendo-filter-neq-operator [text]="'Filter.IsNotEqualTo' | translate"></kendo-filter-neq-operator>
            <kendo-filter-after-operator [text]="'Filter.IsAfter' | translate"></kendo-filter-after-operator>
            <kendo-filter-after-eq-operator [text]="'Filter.IsAfterOrEqualTo' | translate">
            </kendo-filter-after-eq-operator>
            <kendo-filter-before-operator [text]="'Filter.IsBefore' | translate"></kendo-filter-before-operator>
            <kendo-filter-before-eq-operator [text]="'Filter.IsBeforeOrEqualTo' | translate">
            </kendo-filter-before-eq-operator>
            <kendo-filter-isnull-operator [text]="'Filter.IsNull' | translate"></kendo-filter-isnull-operator>
            <kendo-filter-isnotnull-operator [text]="'Filter.IsNotNull' | translate"> </kendo-filter-isnotnull-operator>
          </kendo-grid-date-filter-menu>
        </ng-container>
        <ng-container *ngSwitchCase="'checkbox'">
          <kendo-grid-boolean-filter-menu [column]="column" [filter]="filter" [filterService]="filterService">
          </kendo-grid-boolean-filter-menu>
        </ng-container>

        <ng-container *ngSwitchCase="'checkboxgrid'">
          <kendo-grid-boolean-filter-menu [column]="column" [filter]="filter" [filterService]="filterService">
          </kendo-grid-boolean-filter-menu>
        </ng-container>

        <ng-container *ngSwitchCase="'checkboxhalf'">
          <kendo-grid-boolean-filter-menu [column]="column" [filter]="filter" [filterService]="filterService">
          </kendo-grid-boolean-filter-menu>
        </ng-container>
        <ng-container *ngSwitchCase="'combobox'">
          <!-- <kendo-multiselect
            style="width:220px"
            [(data)]="subColumn.comboboxDataSource"
            [textField]="subColumn.comboboxDisplayField"
            [valueField]="subColumn.comboboxValueField"
            [valuePrimitive]="true"
            (valueChange)="subColumn.onComboboxFilterChange($event, filterService)"
          >
          </kendo-multiselect> -->
          <app-dropdown-filter
            [(data)]="subColumn.comboboxDataSource"
            [textField]="subColumn.comboboxDisplayField"
            [valueField]="subColumn.comboboxValueField"
            [fieldName]="subColumn.field"
            valuePrimitive="true"
            [selectedValue]="subColumn.selectedValue"
            (filterChange)="subColumn.onFilterChange($event, filterService)"
          >
          </app-dropdown-filter>
        </ng-container>
        <ng-container *ngSwitchCase="'timepicker'">
          <kendo-grid-string-filter-menu
            [column]="column"
            [filter]="filter"
            [extra]="false"
            [filterService]="filterService"
          >
            <kendo-filter-eq-operator [text]="'Filter.IsEqualTo' | translate"></kendo-filter-eq-operator>
            <kendo-filter-neq-operator [text]="'Filter.IsNotEqualTo' | translate"></kendo-filter-neq-operator>
            <kendo-filter-contains-operator [text]="'Filter.Contains' | translate"></kendo-filter-contains-operator>
            <kendo-filter-not-contains-operator [text]="'Filter.DoesNotContain' | translate">
            </kendo-filter-not-contains-operator>
            <kendo-filter-startswith-operator [text]="'Filter.StartsWith' | translate">
            </kendo-filter-startswith-operator>
            <kendo-filter-endswith-operator [text]="'Filter.EndsWith' | translate"></kendo-filter-endswith-operator>
            <kendo-filter-isnull-operator [text]="'Filter.IsNull' | translate"></kendo-filter-isnull-operator>
            <kendo-filter-isnotnull-operator [text]="'Filter.IsNotNull' | translate"> </kendo-filter-isnotnull-operator>
          </kendo-grid-string-filter-menu>
        </ng-container>
        <ng-container *ngSwitchCase="'autocomplete'">
          <kendo-grid-string-filter-menu
            [column]="column"
            [filter]="filter"
            [extra]="false"
            [filterService]="filterService"
          >
            <kendo-filter-eq-operator [text]="'Filter.IsEqualTo' | translate"></kendo-filter-eq-operator>
            <kendo-filter-neq-operator [text]="'Filter.IsNotEqualTo' | translate"></kendo-filter-neq-operator>
            <kendo-filter-contains-operator [text]="'Filter.Contains' | translate"></kendo-filter-contains-operator>
            <kendo-filter-not-contains-operator [text]="'Filter.DoesNotContain' | translate">
            </kendo-filter-not-contains-operator>
            <kendo-filter-startswith-operator [text]="'Filter.StartsWith' | translate">
            </kendo-filter-startswith-operator>
            <kendo-filter-endswith-operator [text]="'Filter.EndsWith' | translate"></kendo-filter-endswith-operator>
            <kendo-filter-isnull-operator [text]="'Filter.IsNull' | translate"></kendo-filter-isnull-operator>
            <kendo-filter-isnotnull-operator [text]="'Filter.IsNotNull' | translate"> </kendo-filter-isnotnull-operator>
          </kendo-grid-string-filter-menu>
        </ng-container>
        <ng-container *ngSwitchCase="'percentage'">
          <app-percentage-edit
            *ngSwitchCase="'percentage'"
            [rightText]="true"
            [editMode]="true"
            [step]="true"
            [percentageSign]="false"
            [allowDecimal]="subColumn.percentageDecimals"
            (valueChange)="subColumn.onPercentageChange($event, filterService)"
            [setTimeout]="false"
          >
          </app-percentage-edit>
        </ng-container>
        <ng-container *ngSwitchCase="'image'">
          <app-dropdown-filter
            [(data)]="subColumn.comboboxDataSource"
            [textField]="subColumn.comboboxDisplayField"
            [valueField]="subColumn.comboboxValueField"
            [fieldName]="subColumn.field"
            valuePrimitive="true"
            [selectedValue]="subColumn.selectedValue"
            (filterChange)="subColumn.onFilterChange($event, filterService)"
          >
          </app-dropdown-filter>
        </ng-container>
      </ng-container>
    </ng-template>

    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <ng-container [ngSwitch]="subColumn.type">
        <ng-container *ngSwitchCase="'text'">
          <span
            *ngIf="subColumn.text"
            [ngClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            kendoTooltip
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          >
            {{ subColumn.text }}
          </span>
          <app-text-edit
            *ngIf="!subColumn.text"
            [viewClass]="subColumn.viewClass"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            [editMode]="false"
            [(value)]="dataItem[subColumn.field]"
            [setTimeout]="false"
            kendoTooltip
            [isGrid]="true"
            [addonIcon]="
              subColumn.addOnIcon
                ? subColumn.addOnIcon
                : dataItem && subColumn.addOnIconFieldString
                ? dataItem[subColumn.addOnIconFieldString]
                : ''
            "
            [icon]="dataItem && subColumn.IconFieldString ? dataItem[subColumn.IconFieldString] : ''"
            (iconClick)="iconTextEditControlClick($event, dataItem)"
            [addonTooltip]="
              dataItem && subColumn.IconTooltipFieldString
                ? (dataItem[subColumn.IconTooltipFieldString] | translate)
                : ''
            "
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
            (addonClick)="subColumn.addonClick.emit()"
          >
          </app-text-edit>
        </ng-container>

        <ng-container *ngSwitchCase="'numeric'">
          <app-numeric-edit
            [viewClass]="subColumn.viewClass"
            [rightText]="true"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            [editMode]="false"
            [(value)]="dataItem[subColumn.field]"
            [option]="subColumn.numbericOption"
            [isGrid]="true"
            [setTimeout]="false"
            kendoTooltip
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          >
          </app-numeric-edit>
          <app-grid-in-cell-popup
            *ngIf="subColumn.hasIncellPopup"
            [content]="subColumn.popupContent"
            [actions]="subColumn.popupActions"
            [visible]="dataItem[subColumn.field + 'PopupVisible']"
            (click)="onIncellPopUpClickAction(dataItem, dataItem.triggerField, $event)"
          >
          </app-grid-in-cell-popup>
        </ng-container>

        <app-check-edit
          *ngSwitchCase="'checkbox'"
          customClass="checkBox-center"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [isGrid]="true"
          [editMode]="subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable"
          [(value)]="dataItem[subColumn.field]"
          (valueChange)="onCheckBoxCheckedChanged(dataItem, subColumn.field)"
          [tooltip]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
        >
        </app-check-edit>

        <app-check-edit
          *ngSwitchCase="'checkboxhalf'"
          [half]="true"
          [halfType]="subColumn.field"
          [dataItemCB]="dataItem"
          customClass="checkBox-center"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [isGrid]="true"
          (halfCheckChange)="onHalfCheckChange($event)"
          [editMode]="subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable"
          [(value)]="dataItem[subColumn.field]"
          [tooltip]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
        >
        </app-check-edit>

        <app-grid-checkbox
          *ngSwitchCase="'checkboxgrid'"
          customClass="checkBox-center"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [isGrid]="true"
          [editMode]="
            editMode ? (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) : false
          "
          [(value)]="dataItem[subColumn.field]"
          (valueChange)="onCheckBoxCheckedChanged(dataItem, subColumn.field, true)"
          [tooltip]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
        >
        </app-grid-checkbox>

        <app-combobox-edit
          *ngSwitchCase="'combobox'"
          [viewClass]="subColumn.viewClass"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [isGrid]="true"
          [noToggle]="true"
          [editMode]="false"
          [idField]="subColumn.comboboxValueField"
          [filterFields]="subColumn.comboboxFilterFields"
          [textField]="subColumn.comboboxDisplayField"
          [comboboxDataSource]="subColumn.comboboxDataSource"
          [(value)]="dataItem[subColumn.field]"
          [tooltipField]="subColumn.tooltipField ? subColumn.tooltipField : ''"
          kendoTooltip
          [title]="subColumn.tooltipFieldNonEdit ? dataItem[subColumn.tooltipFieldNonEdit] : ''"
          [nullValueLabel]="subColumn.allowComboeditNull ? ('DropdownList.None' | translate) : ''"
          [defaultnullvalue]="subColumn.defaultnullvalue"
          [CurrentSelectTextTooltip]="
            subColumn.CurrentTextComboboxToolTipFeild
              ? dataItem[subColumn.CurrentTextComboboxToolTipFeild]
              : subColumn.CurrentTextComboboxToolTip
          "
          [setTimeout]="false"
        >
        </app-combobox-edit>

        <app-date-picker
          *ngSwitchCase="'datepicker'"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          (customData)="onDataChanged(dataItem, subColumn.field)"
          [isGrid]="true"
          [editMode]="false"
          [minDate]="subColumn.minDate"
          [(value)]="dataItem[subColumn.field]"
          kendoTooltip
          [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          [viewClass]="subColumn.viewClass"
        >
        </app-date-picker>

        <app-time-pickerv
          *ngSwitchCase="'timepicker'"
          [ngClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [editMode]="false"
          [setTimeout]="false"
          [(value)]="dataItem[subColumn.field]"
          kendoTooltip
          [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          [isGrid]="true"
        >
        </app-time-pickerv>

        <app-percentage-edit
          *ngSwitchCase="'percentage'"
          [rightText]="true"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [isGrid]="true"
          [editMode]="false"
          [allowDecimal]="subColumn.percentageDecimals"
          [(value)]="dataItem[subColumn.field]"
          kendoTooltip
          [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
        >
        </app-percentage-edit>

        <app-auto-complete-textbox
          *ngSwitchCase="'autocomplete'"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [isGrid]="true"
          [editMode]="false"
          [autoCompleteDataSource]="subColumn.comboboxDataSource"
          [viewClass]="subColumn.viewClass"
          [(value)]="dataItem[subColumn.field]"
          [textField]="subColumn.comboboxDisplayField"
          kendoTooltip
          [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          [autocompleteby1value]="subColumn.autocompleteby1value"
          [setTimeout]="false"
        >
        </app-auto-complete-textbox>

        <app-icon
          *ngSwitchCase="'icon'"
          (click)="appIconClick(dataItem, subColumn.iconAction)"
          [icon]="dataItem[subColumn.iconField]"
          [tooltip]="subColumn.tooltip || dataItem[subColumn.tooltipField] || '' | translate"
          [disable]="subColumn.disableButtonFeild ? dataItem[subColumn.disableButtonFeild] : false"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : 'align-center'"
        >
        </app-icon>

        <div class="align-center" *ngSwitchCase="'buttonPopUp'">
          <app-popup-button
            [label]="subColumn.popupButtonText"
            [buttonClass]="subColumn.popupButtonClass"
            [popupButtonIcon]="subColumn.popupButtonArrayContentIcon"
            (IconClick)="appIconClick(dataItem, $event)"
            [isUseLabelAppIcon]="subColumn.isPopupButtonIcon"
            [icon]="subColumn.popupButtonIcon"
            (ButtonClick)="ButtonPopUpClick.emit()"
          >
          </app-popup-button>
        </div>

        <div kendoTooltip [title]="subColumn.disableGridButtonTooltip | translate" *ngSwitchCase="'button'">
          <app-button
            [buttonClass]="dataItem[subColumn.buttonClass] || subColumn.buttonClass"
            [tooltip]="subColumn.buttonToottip | translate"
            [isDisabled]="
              subColumn.disableButtonFeild ? dataItem[subColumn.disableButtonFeild] : subColumn.disableGridButtonValue
            "
            [label]="subColumn.buttonLabel | translate"
            (clickEvent)="
              onGridButtonClickEvent(
                dataItem,
                subColumn.buttonAction,
                subColumn.disableButtonFeild ? dataItem[subColumn.disableButtonFeild] : subColumn.disableGridButtonValue
              )
            "
          >
          </app-button>
        </div>

        <app-grid-flag-column
          *ngSwitchCase="'image'"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : 'align-center'"
          [countryCode]="dataItem[subColumn.field]"
        >
        </app-grid-flag-column>

        <span
          *ngSwitchCase="'subProberty'"
          kendoTooltip
          [title]="
            subColumn.tooltip || subColumn.isUseSubcolumTooltip
              ? getParamSubProberty(dataItem, subColumn.subProberty)
              : dataItem[subColumn.tooltipField] || ''
          "
          [ngClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
        >
          {{ getParamSubProberty(dataItem, subColumn.subProberty) }}
        </span>
      </ng-container>
    </ng-template>

    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <ng-container [ngSwitch]="subColumn.type">
        <ng-container *ngSwitchCase="'text'">
          <span
            *ngIf="subColumn.text"
            [ngClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            kendoTooltip
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          >
            {{ subColumn.text }}
          </span>
          <app-text-edit
            *ngIf="!subColumn.text"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            [isGrid]="true"
            [editMode]="
              (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
              (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
            "
            [(value)]="dataItem[subColumn.field]"
            (valueChange)="onDataChanged(dataItem, subColumn.field)"
            [setTimeout]="false"
            kendoTooltip
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
            [type]="subColumn.appTextType"
            (isValidChange)="controlValid($event, subColumn.field)"
          >
          </app-text-edit>
        </ng-container>

        <ng-container *ngSwitchCase="'numeric'">
          <app-numeric-edit
            [viewClass]="subColumn.viewClass"
            [rightText]="true"
            [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
            [isGrid]="true"
            [editMode]="
              (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
              (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
            "
            [(value)]="dataItem[subColumn.field]"
            [option]="subColumn.numbericOption"
            (valueChange)="onDataChanged(dataItem, subColumn.field, dataItem[subColumn.editableField])"
            [setTimeout]="false"
            kendoTooltip
            [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
            (keydowns)="onKeyDown($event)"
            [addOnButtonEdit]="subColumn.addOnButtonEdit"
            [addOnButtonEditTooltip]="subColumn.addOnButtonEditTooltip"
            [addOnButtonEditClass]="subColumn.addOnButtonEditClass"
            [addOnButtonEditClassFormControl]="subColumn.addOnButtonEditClassFormControl"
            [addOnButtonEditAction]="subColumn.addOnButtonEditAction"
            (addOnButtonClick)="onGridButtonClickEvent(dataItem, $event)"
            [addOnButtonEditDisabled]="
              subColumn.addOnButtonEditDisabledFeild ? dataItem[subColumn.addOnButtonEditDisabledFeild] : false
            "
            [addOnButtonEditIcon]="subColumn.addOnButtonEditIcon"
            [addOnButtonEditLabel]="subColumn.addOnButtonEditLabel"
            [addOnButtonEditIconEnable]="subColumn.addOnButtonEditIconEnable"
          >
          </app-numeric-edit>
        </ng-container>

        <app-combobox-edit
          *ngSwitchCase="'combobox'"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [editMode]="
            (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
            (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
          "
          [isGrid]="true"
          [noToggle]="true"
          [textField]="subColumn.comboboxDisplayField"
          [idField]="subColumn.comboboxValueField"
          [tooltipField]="subColumn.tooltipField ? subColumn.tooltipField : 'Description'"
          [filterFields]="subColumn.comboboxFilterFields"
          [comboboxDataSource]="subColumn.comboboxDataSource"
          [(value)]="dataItem[subColumn.field]"
          (valueChange)="
            onDataChanged(dataItem, subColumn.field, subColumn.editableField, subColumn.allowComboeditNull)
          "
          [setTimeout]="false"
          kendoTooltip
          [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          [nullValueLabel]="subColumn.allowComboeditNull ? ('DropdownList.None' | translate) : ''"
          [defaultnullvalue]="subColumn.defaultnullvalue"
          [CurrentSelectTextTooltip]="
            subColumn.CurrentTextComboboxToolTipFeild
              ? dataItem[subColumn.CurrentTextComboboxToolTipFeild]
              : subColumn.CurrentTextComboboxToolTip
          "
          (tabClick)="onKeyDown($event)"
        >
        </app-combobox-edit>

        <app-date-picker
          *ngSwitchCase="'datepicker'"
          [editMode]="
            (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
            (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
          "
          [isGrid]="true"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [(value)]="dataItem[subColumn.field]"
          (valueChange)="onDataChanged(dataItem, subColumn.field)"
          kendoTooltip
          [minDate]="subColumn.minDate"
          [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          (customData)="onDataChanged(dataItem, subColumn.field)"
          (directional)="onKeyDown($event)"
          [viewClass]="subColumn.viewClass"
          [AllowNull]="subColumn.AllowNull"
        >
        </app-date-picker>

        <app-time-pickerv
          *ngSwitchCase="'timepicker'"
          [ngClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [editMode]="
            (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
            (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
          "
          [setTimeout]="false"
          [(value)]="dataItem[subColumn.field]"
          (valueChange)="onDataChanged(dataItem, subColumn.field, dataItem[subColumn.editableField])"
          kendoTooltip
          [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          [isGrid]="true"
        >
        </app-time-pickerv>

        <app-percentage-edit
          *ngSwitchCase="'percentage'"
          [rightText]="true"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [isGrid]="true"
          [step]="true"
          [percentageSign]="false"
          [editMode]="
            (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
            (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
          "
          [allowDecimal]="subColumn.percentageDecimals"
          [(value)]="dataItem[subColumn.field]"
          (valueChange)="onDataChanged(dataItem, subColumn.field)"
          [setTimeout]="false"
          kendoTooltip
          [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
        >
        </app-percentage-edit>

        <app-auto-complete-textbox
          *ngSwitchCase="'autocomplete'"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
          [isGrid]="true"
          [editMode]="
            (subColumn.editableField ? dataItem[subColumn.editableField] : subColumn.editable) &&
            (subColumn.subEditableField ? dataItem[subColumn.subEditableField] : true)
          "
          [autoCompleteDataSource]="subColumn.comboboxDataSource"
          [(value)]="dataItem[subColumn.field]"
          [textField]="subColumn.comboboxDisplayField"
          (valueChange)="onDataChanged(dataItem, subColumn.field)"
          [setTimeout]="false"
          kendoTooltip
          [title]="subColumn.tooltip || dataItem[subColumn.tooltipField] || ''"
          [autocompleteby1value]="subColumn.autocompleteby1value"
        >
        </app-auto-complete-textbox>

        <app-icon
          *ngSwitchCase="'icon'"
          (click)="appIconClick(dataItem, subColumn.iconAction)"
          [icon]="dataItem[subColumn.iconField]"
          [tooltip]="subColumn.tooltip || dataItem[subColumn.tooltipField] || '' | translate"
          [disable]="subColumn.disableButtonFeild ? dataItem[subColumn.disableButtonFeild] : false"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : 'align-center'"
        >
        </app-icon>

        <app-grid-flag-column
          *ngSwitchCase="'image'"
          [hostClass]="subColumn.classField ? dataItem[subColumn.classField] : 'align-center'"
          [countryCode]="dataItem[subColumn.field]"
        >
        </app-grid-flag-column>

        <div *ngSwitchCase="'buttonPopUp'">
          <app-popup-button
            [label]="subColumn.popupButtonText"
            [buttonClass]="subColumn.popupButtonClass"
            [popupButtonIcon]="subColumn.popupButtonArrayContentIcon"
            (IconClick)="appIconClick(dataItem, $event)"
            [isUseLabelAppIcon]="subColumn.isPopupButtonIcon"
            [icon]="subColumn.popupButtonIcon"
          >
          </app-popup-button>
        </div>

        <div kendoTooltip [title]="subColumn.disableGridButtonTooltip | translate" *ngSwitchCase="'button'">
          <app-button
            [buttonClass]="dataItem[subColumn.buttonClass] || subColumn.buttonClass"
            [tooltip]="subColumn.buttonToottip | translate"
            [isDisabled]="
              subColumn.disableButtonFeild ? dataItem[subColumn.disableButtonFeild] : subColumn.disableGridButtonValue
            "
            [label]="subColumn.buttonLabel | translate"
            (clickEvent)="
              onGridButtonClickEvent(
                dataItem,
                subColumn.buttonAction,
                subColumn.disableButtonFeild ? dataItem[subColumn.disableButtonFeild] : subColumn.disableGridButtonValue
              )
            "
          >
          </app-button>
        </div>

        <span
          *ngSwitchCase="'subProberty'"
          kendoTooltip
          [title]="
            subColumn.tooltip || subColumn.isUseSubcolumTooltip
              ? getParamSubProberty(dataItem, subColumn.subProberty)
              : dataItem[subColumn.tooltipField] || ''
          "
          [ngClass]="subColumn.classField ? dataItem[subColumn.classField] : ''"
        >
          {{ getParamSubProberty(dataItem, subColumn.subProberty) }}
        </span>
      </ng-container>
    </ng-template>
  </kendo-grid-column>

  <ng-template kendoGridNoRecordsTemplate></ng-template>

  <kendo-grid-command-column *ngIf="deleteRow" title="" width="50" class="align-center">
    <ng-template kendoGridHeaderTemplate>
      <div>
        {{ 'GlobalDialog.Delete' | translate }}
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem="dataItem">
      <span kendoTooltip [title]="DeleteTooltips">
        <a
          *ngIf="!deleteRowField || dataItem[deleteRowField]"
          kendoGridRemoveCommand
          role="button"
          class="k-button k-button-icontext deleteButtonColumn k-grid-X"
          href="#"
          [disabled]="disableDeleteButton"
        >
          <span class=""></span>X
        </a>
      </span>
    </ng-template>
  </kendo-grid-command-column>

  <div *ngIf="isCompanyApproval">
    <div *kendoGridDetailTemplate="let dataItem; let rowIndex = rowIndex">
      <app-company-approval-grid-detail
        (selectedRowEmitter)="onSelectedRowCompanyApproval($event)"
        [timeEntryRecords]="timeEntryRecords"
        [TimeEntryTypes]="TimeEntryTypes"
        [rowIndex]="rowIndex"
        [employee]="dataItem"
        [currentTimeEntryStatus]="currentTimeEntryStatus"
        (approveRejectData)="onApproveRejectData()"
        (openApprovalGridDetail)="onOpenApprovalGridDetail()"
      >
      </app-company-approval-grid-detail>
    </div>
  </div>
</kendo-grid>
