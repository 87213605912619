import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { version } from '../../../version';
import { Browser } from '../../common/browser';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { BroadcastService } from '../../services/broadcast.service';
import { EmployeeService } from '../../services/employee.service';
import { ModalService } from '../../services/modal.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  public userName = '';
  public password = '';
  public remember = false;
  public Message: string = undefined;
  public language: string;
  public showAcceptDPADialog: boolean;
  public showResetPasswordDialog = false;
  public hideFooter = false;
  public showPassword = false;
  public signupDialogVisible = false;
  public upgradeWarningDialogVisible = false;
  public unsupportedIEDialogVisible = false;
  public unsupportedBrowserDialogVisible = false;
  public localStorageDialogVisible = false;
  public changeTemporaryPasswordVisible = false;
  public languageDropdownOpen: boolean;
  public preFillEmailResetPassword: string;
  public userNameValueMissing: boolean;
  public PasswordValueMissing: boolean;
  public isWarningduplicateTabs = false;

  public get branding() {
    return environment.branding;
  }

  public get isIOSMobileApp(): boolean {
    return this.sessionService.browser.iOSMobileDevice && this.sessionService.browser.isHybridApp;
  }
  public get iOSversion(): any {
    return this.sessionService.browser.iOSversion;
  }
  public get IsIE(): boolean {
    return this.sessionService.browser.isIE;
  }
  public get IsAndroidMobile(): boolean {
    return (
      !this.sessionService.browser.isHybridApp &&
      this.sessionService.browser.isAndroidDevice &&
      this.sessionService.browser.isMobile
    );
  }
  public get IsIOsMobile(): boolean {
    return (
      !this.sessionService.browser.isHybridApp &&
      this.sessionService.browser.iOSMobileDevice &&
      this.sessionService.browser.isMobile
    );
  }
  public get signupLink(): string {
    return '/signup/' + this.urlCultureCode;
  }

  private unauthorized: string;
  private wrongUsernameOrPassword: string;
  private networkErrorMessage: string;
  private checkedBrowser = false;
  private urlCultureCode = 'da';
  private isLoggingIn = false;
  private mustChangePassword = false;
  private firstLoginMustChangePassword: boolean;
  private loginResponse: any;
  private originalSize: number;
  private originalHeight: number;

  @ViewChild('loginUsername', { static: true }) loginUsername: ElementRef;
  @ViewChild('loginPassword', { static: true }) loginPassword: ElementRef;

  constructor(
    public sessionService: SessionService,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private settingService: SettingService,
    private dataService: DataService,
    private staticDataService: StaticDataService,
    public modalService: ModalService,
    private broadcaster: BroadcastService,
    private renderer: Renderer2,
    private employeeService: EmployeeService
  ) {}

  public onCloseDialoigWarningDuplicateTabs() {
    const Backlen = history.length;
    history.go(-Backlen);
    history.replaceState({}, null, '/login');
    this.authenticationService.clearAuthentication();
  }

  public ngOnInit(): void {
    setTimeout(() => {
      if (this.sessionService.IsAuthenticated) {
        if (this.sessionService.restoreDefault) {
          if (this.sessionService.isBackToLogin) {
            this.dataService.Auth_IsSessionAlive().subscribe((result: boolean) => {
              if (result === null || result === true) {
                // set timeout to wait login page show and back to default page
                setTimeout(() => {
                  this.sessionService.isBackToLogin = false;
                  this.sessionService.redirectToApp = true;
                  this.navigateToApp();
                }, 100);
              } else {
                this.authenticationService.logout().subscribe(() => {});
              }
            });
          } else {
            this.authenticationService.logout().subscribe(() => {});
          }
        } else {
          this.isWarningduplicateTabs = true;
          this.sessionService.restoreDefault = true;
        }
      }
    });

    if (window.location.href.indexOf('file://') > -1 && !this.sessionService.browser.isHybridApp) {
      window.location.href = 'https://intect.app';
    }

    this.originalSize = window.innerWidth + window.innerHeight;
    this.originalHeight = window.innerHeight;
    this.renderer.removeClass(document.body, 'main-bg');
    this.renderer.addClass(document.body, 'Login-bg');
    this.renderer.listen(window, 'keyboardWillShow', () => {
      this.renderer.addClass(document.body, 'keyboard-show');
    });
    this.renderer.listen(window, 'keyboardDidHide', () => {
      this.renderer.removeClass(document.body, 'keyboard-show');
    });

    let languageCode: string;
    const urlFragments: string[] = window.location.href.split('/');
    const param: string = urlFragments[urlFragments.length - 1];
    if (param) {
      if (param.substring(0, 8) === 'username') {
        this.userName = param.replace('username=', '');
      }
      if (param === 'Unauthorized') {
        this.Message = this.unauthorized;
      } else {
        const shortCultureCode: string = param.substr(0, 2);
        if (this.checkLanguageCodeExist(shortCultureCode)) {
          languageCode = this.urlCultureCode = shortCultureCode;
        }
      }
    }

    if (!languageCode) {
      languageCode = this.getLanguageCodeById(this.getCookie('languageId'));
    }

    this.changeLanguageTo(languageCode || 'da', true);

    if (this.isMobile) {
      const initialScreenSize = window.innerHeight;
      window.addEventListener(
        'resize',
        () => {
          if (window.innerHeight < initialScreenSize) {
            this.renderer.addClass(document.body, 'keyboard-show');
          } else {
            this.renderer.removeClass(document.body, 'keyboard-show');
          }
        },
        false
      );
    }
  }

  public keyboardShow: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.isMobile) {
      if (document.activeElement.tagName.toLowerCase() !== 'input') {
        this.originalSize = window.innerWidth + window.innerHeight;
        this.keyboardShow = false;
      } else {
        if (window.innerHeight > this.originalHeight) {
          this.keyboardShow = false;
        } else {
          this.keyboardShow = true;
        }
        this.originalHeight = window.innerHeight;
      }

      // if (window.innerWidth + window.innerHeight !== this.originalSize) {
      //   this.hideFooter = true;
      // } else {
      //   this.hideFooter = false;
      // }
    }
  }

  public ngAfterViewInit(): void {
    if (this.checkLocalStorageBrower()) {
      this.init();
    }
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get unsupportIOSApp(): boolean {
    const ver: any = this.iOSversion;
    if (ver && ver[0] < 11) {
      return true;
    }

    return false;
  }

  public onUpgradeWarningDialogAction(action: string): void {
    if (action === 'Update') {
      if (this.sessionService.browser.isHybridApp) {
        window.open('market://details?id=com.gratisal.mobile', '_top');
      } else {
        window.location.reload();
      }
    }
  }

  public unsupportedIEDialogAction(action: string): void {
    switch (action) {
      case 'Chrome':
        window.open('https://www.google.com/chrome/');
        break;
      case 'Firefox':
        window.open('https://getfirefox.com/');
        break;
    }
  }

  public IdonthaveanaccountmodalAction(action: string): void {
    switch (action) {
      case 'CreateNewCompany':
        if (this.language === 'en') {
          window.location.replace('https://intect.app/signup/en');
        } else {
          this.language === 'da'
            ? window.location.replace('https://intect.app/signup/da')
            : window.location.replace('https://intect.app/signup/kl');
        }
        break;
      case 'ForgotPassword':
        this.onResetPassword();
        break;
    }
  }

  public onToggleLanguageDropdown(): void {
    this.languageDropdownOpen = !this.languageDropdownOpen;
  }

  public onTemporaryPasswordChanged(newPassword: string): void {
    this.password = newPassword;
    this.authenticationService.credentials.Password = this.password;
    this.login();
  }

  public gotosignup(url: string): void {
    window.location.href = url;
  }

  public changeLanguageTo(language: string, firstLoad?: boolean): void {
    this.language = language;
    this.urlCultureCode = language;
    this.settingService.applyTranslations(language);
    this.translate.use(language).subscribe(() => {
      if (firstLoad) {
        this.ShowWaringUsuportIE();
      }
    });

    this.languageDropdownOpen = false;
    localStorage.setItem('localLanguageCode', this.language);
  }

  public keydown(e: any): void {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (!this.userName) {
        this.loginUsername.nativeElement.focus();
        this.isLoggingIn = false;
        return;
      }

      if (!this.password) {
        this.loginPassword.nativeElement.focus();
        this.isLoggingIn = false;
        return;
      }

      this.login();
    } else {
      this.Message = undefined;
    }
  }

  public get checkAutoFill(): boolean {
    let userAutoFill = false;
    let passAutoFill = false;
    try {
      userAutoFill = getComputedStyle(this.loginUsername.nativeElement).webkitTextFillColor === 'rgb(27, 41, 47)';
      passAutoFill = getComputedStyle(this.loginPassword.nativeElement).webkitTextFillColor === 'rgb(27, 41, 47)';
    } catch (e) {}

    if ((userAutoFill && passAutoFill && !this.isLoggingIn) || (this.userName && passAutoFill)) {
      return false;
    }

    if (!this.userName || this.userName === '' || !this.password || this.password === '' || this.isLoggingIn) {
      return true;
    }

    return false;
  }

  public login(): void {
    if (this.isLoggingIn) {
      return;
    }

    if (this.checkAutoFill) {
      this.userName ? (this.userNameValueMissing = false) : (this.userNameValueMissing = true);
      this.password ? (this.PasswordValueMissing = false) : (this.PasswordValueMissing = true);
      this.userName ? this.loginPassword.nativeElement.focus() : this.loginUsername.nativeElement.focus();
      return;
    } else {
      this.userNameValueMissing = false;
      this.PasswordValueMissing = false;
    }

    if (!this.password) {
      return;
    }

    if (this.remember) {
      localStorage.setItem('lastUserName', this.userName);
    } else {
      localStorage.setItem('lastUserName', '');
    }

    localStorage.setItem('saveLoginUserName', this.userName);

    this.authenticationService.credentials = {
      Username: this.userName,
      Password: this.password,
      Remember: this.remember
    };

    this.isLoggingIn = true;
    let message: string;
    this.loginResponse = undefined;
    this.authenticationService
      .login()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response: any) => {
          const isFirstLogin: boolean = this.firstLoginMustChangePassword
            ? this.firstLoginMustChangePassword
            : response.IsFirstLogin || false;
          this.sessionService.isApprovalMessage = response.IsFirstLogin || false;
          const mustChangePassword: any = response.MustChangePassword;
          if (mustChangePassword) {
            this.firstLoginMustChangePassword = isFirstLogin;
            this.mustChangePassword = true;
            const cultureCode: string = response.Language.CultureCode;
            const shortCultureCode: string = cultureCode.substr(0, 2);

            if (this.mustChangePassword) {
              this.mustChangePassword = false;
              this.changeTemporaryPassword();
              this.isLoggingIn = false;
            }
          } else {
            const company: any = response.Company ? response.Company : undefined;
            const role: any = response ? response.CurrentRole : undefined;
            if (role && role.Key === 'FullAdmin' && company && !company.HasAcceptedTerms) {
              this.loginResponse = response;
              this.showAcceptDPADialog = true;
            } else {
              this.onLoginCompleted(response);
            }
          }
        },
        (reason: any): void => {
          this.isLoggingIn = false;

          // Failure
          switch (reason.status) {
            case 0:
              message = this.networkErrorMessage;
              break;
            case 401:
              message = this.wrongUsernameOrPassword;
              break;
            case 400:
              message = reason.error.Message;
              break;
            case 500:
              // Internal server error
              let errorMessage: string = reason.statusText;
              if (reason.config && reason.config.url) {
                errorMessage += ' - ' + reason.config.url;
              }
              Sentry.withScope((scope) => {
                scope.setExtra('extra', reason);
                Sentry.captureException(new Error(errorMessage));
              });

              break;
            default:
              let othermessage: string = reason.statusText;
              if (reason.config && reason.config.url) {
                othermessage += ' - ' + reason.config.url;
              }
              Sentry.withScope((scope) => {
                scope.setExtra('extra', reason);
                Sentry.captureException(new Error(othermessage));
              });
              break;
          }

          this.Message = message;
          console.error('Login failed: ');
          console.error(reason);
        }
      );
  }

  public onDPATermAccepted(isValid: boolean): void {
    this.isLoggingIn = false;
    if (isValid) {
      this.onLoginCompleted();
    }

    this.loginResponse = undefined;
  }

  public onResetPassword(): void {
    this.preFillEmailResetPassword = this.userName && this.userName.lastIndexOf('@') !== -1 ? this.userName : '';
    this.showResetPasswordDialog = true;
  }

  public openLink(): void {
    if (this.isIOSMobileApp) {
      return;
    }

    const languageCode: string =
      Global.SESSION && Global.SESSION.SignOnToken.Language
        ? Global.SESSION.SignOnToken.Language.CultureCode.split('-')[0]
        : 'da';
    location.href = 'https://www.intect.dk/' + languageCode;
  }

  private init(): void {
    this.language = this.translate.currentLang;
    this.translateText();
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e: string) => {
      this.language = this.translate.currentLang;
      this.translateText();
    });

    this.dataService.Miscellaneous_GetMinimumClientVersion().subscribe((clientMinVersion: string) => {
      const fragments: string[] = version.split('.');
      let formattedVersion: string;
      if (fragments.length < 3) {
        formattedVersion = version;
      } else {
        formattedVersion =
          this.zeroPad(this.sessionService.parseInt(fragments[0]), 2) +
          this.zeroPad(this.sessionService.parseInt(fragments[1]), 3) +
          this.zeroPad(this.sessionService.parseInt(fragments[2]), 4);
      }

      if (clientMinVersion > formattedVersion && environment.environment !== 'DEV') {
        setTimeout(() => {
          this.upgradeWarningDialogVisible = true;
        });
      }
    });
  }

  private ShowWaringUsuportIE(): void {
    if (this.sessionService.browser.isIE) {
      this.unsupportedIEDialogVisible = true;
    }
  }

  private getCookie(cookieName: string): string {
    const name: string = cookieName + '=';
    const decodedCookie: string = decodeURIComponent(document.cookie);
    const ca: string[] = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c: string = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }

    return '';
  }

  private checkBrowser(): boolean {
    if (this.checkedBrowser) {
      return true;
    }

    this.checkedBrowser = true;
    if (
      !this.sessionService.browser.isSupportedBrowser ||
      this.sessionService.browser.isOutdatedBrowser ||
      this.unsupportIOSApp
    ) {
      this.unsupportedBrowserDialogVisible = true;
      return false;
    }

    return true;
  }

  private checkLocalStorageBrower(): boolean {
    if (!this.localStorageEnabled) {
      this.localStorageDialogVisible = true;
      return false;
    }

    return true;
  }

  public get localStorageEnabled(): boolean {
    try {
      // Try to use localStorage
      sessionStorage.setItem('sessionStorageEnable', 'true');
      return true;
    } catch (e) {
      // There was an error so...
      alert(
        'Bemærk: Din browser tillader ikke cookies, og ' +
          this.branding.SystemAlias +
          ' kan derfor ikke fungere optimalt. Vi anbefaler kraftigt at du tillader cookies på dette domæne. Cookies er nødvendige for at holde styr på væsentlige detaljer om din session, eksempelvis dit sprogvalg.Læs vores fulde cookie - politik.' +
          '\n' +
          'Please note: Your browser is not allowing cookies, which means ' +
          this.branding.SystemAlias +
          ' cannot function correctly. We strongly recommend that you allow cookies for the this domain. Cookies are necessary to keep track of important details about your session, e.g.your choice of language.You can read our full policy on cookies.'
      );

      return false;
    }
  }

  private checkLanguageCodeExist(languageCode: string): boolean {
    return languageCode === 'en' || languageCode === 'da' || languageCode === 'kl' || languageCode === 'se';
  }

  private getLanguageCodeById(languageId: string): string {
    const id: number = this.sessionService.parseInt(languageId);
    switch (id) {
      case 1:
        return 'da';
      case 2:
        return 'en';
      case 3:
        return 'kl';
      case 4:
        return 'se';
      default:
        return '';
    }
  }

  private zeroPad(num: number, places: number): string {
    const zero: number = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join('0') + num;
  }

  private translateText(): void {
    this.translate
      .get([
        'GlobalDialog.Ok',
        'GlobalDialog.Yes',
        'GlobalDialog.No',
        'GlobalDialog.Cancel',
        'GlobalDialog.Close',
        'GlobalDialog.Delete',
        'GlobalDialog.Reject',
        'GlobalDialog.Create',
        'GlobalDialog.Approve',
        'GlobalDialog.Enable',
        'GlobalDialog.Apply',
        'GlobalDialog.Proceed',
        'GlobalDialog.Understood',
        'GlobalDialog.MoreHelp',
        'GlobalDialog.ReadMore',
        'GlobalDialog.ChangePassword',
        'GlobalDialog.ResetPassword',
        'Error.NetworkError',
        'GlobalDialog.YesPlease',
        'GlobalDialog.NoThankYou',
        'Warning.Continue',
        'GlobalDialog.Update',
        'GlobalDialog.CreateNewCompany',
        'GlobalDialog.Login',
        'GlobalDialog.ForgotPassword',
        'Error.401ErrorWarningResponse'
      ])
      .subscribe((translations: { [key: string]: string }) => {
        this.sessionService.modalTranslations = translations;
      });

    this.translate
      .get(['Login.AuthenticationFailed', 'Login.WrongUserNameOrPassword', 'Error.NetworkError', 'UnsuccessfulAPI.404'])
      .subscribe((translations: { [key: string]: string }) => {
        this.unauthorized = translations['Login.AuthenticationFailed'];
        this.wrongUsernameOrPassword = translations['Login.WrongUserNameOrPassword'];
        this.networkErrorMessage = translations['Error.NetworkError'].replace(
          '{{SystemAlias}}',
          environment.branding.SystemAlias
        );
        this.sessionService.errorMessage404 = translations['UnsuccessfulAPI.404'];
        this.sessionService.errorMessage401 = translations['Error.401ErrorWarningResponse'];

        if (this.checkBrowser()) {
          // if (!this.userName && localStorage) {
          //   this.userName = localStorage.getItem('lastUserName');
          // }
          if (localStorage && localStorage.getItem('lastUserName')) {
            this.userName = localStorage.getItem('lastUserName');
            this.remember = true;
          } else {
            this.userName = '';
            this.password = '';
            this.remember = false;
          }
        }

        // if (this.mustChangePassword) {
        //     this.mustChangePassword = false;
        //     this.changeTemporaryPassword();
        // }
      });
  }

  public isFullAdmin = false;
  public isReadOnly = false;
  public isSalaryAdmin = false;
  public isEmployee = false;

  private onLoginCompleted(response?: any): void {
    response = response ? response : this.loginResponse;
    if (response) {
      const isFirstLogin: boolean = this.firstLoginMustChangePassword
        ? this.firstLoginMustChangePassword
        : response.IsFirstLogin || false;
      const welcomeMessageSettings: { [key: string]: boolean } = {};
      welcomeMessageSettings['Employee.General'] = isFirstLogin;
      welcomeMessageSettings['Employee.Employment'] = isFirstLogin;
      welcomeMessageSettings['Employee.Time'] = isFirstLogin;
      welcomeMessageSettings['Employee.PayrollData'] = isFirstLogin;
      welcomeMessageSettings['Employee.Payslip'] = isFirstLogin;
      welcomeMessageSettings['Company.General'] = isFirstLogin;
      welcomeMessageSettings['Company.Templates'] = isFirstLogin;
      welcomeMessageSettings['Company.SalaryBatches'] = isFirstLogin;
      welcomeMessageSettings['Company.SalaryTypes'] = isFirstLogin;
      welcomeMessageSettings['Company.TimeEntryTypes'] = isFirstLogin;
      welcomeMessageSettings['Account.Modules'] = isFirstLogin;
      welcomeMessageSettings['SelfService.Payslip'] = isFirstLogin;
      welcomeMessageSettings['ShowUpdateNotification'] = response.ShowUpdateNotification && !isFirstLogin;
      welcomeMessageSettings['company.timeentrytypes'] = isFirstLogin;
      welcomeMessageSettings['company.integrations'] = isFirstLogin;
      welcomeMessageSettings['company.advancedsalarytypes'] = isFirstLogin;
      Global.WELCOME_MESSAGE_SETTINGS = welcomeMessageSettings;

      const betaMessageSettings: { [key: string]: boolean } = {};
      betaMessageSettings['Company.SalaryBatch.EditPayroll'] = true;
      betaMessageSettings['Company.SalaryBatch.EditPayrollIEEdge'] = true;
      Global.BETA_MODULE_MESSAGE_SETTINGS = betaMessageSettings;

      this.renderer.addClass(document.body, 'is-loggingin');
      if (response.CurrentRole && response.CurrentRole.Key) {
        switch (response.CurrentRole.Key) {
          case 'FullAdmin':
            this.isFullAdmin = true;
            break;
          case 'ReadOnly':
            this.isReadOnly = true;
            break;
          case 'Employee':
            this.isEmployee = true;
            break;
          case 'SalaryAdmin':
            this.isSalaryAdmin = true;
            break;
          default:
            break;
        }
      }
      this.staticDataService
        .loadStaticData()
        .then(() => this.navigateToApp())
        .catch(() => {
          this.isLoggingIn = false;
        });
    }
  }

  private navigateToApp(): void {
    this.employeeService.loadEmployeesAsync().then(() => {
      Global.STARTUP_TASKS_VISIBILITY = true;
      setTimeout(() => this.renderer.removeClass(document.body, 'is-loggingin'), 200);
      this.sessionService.switchToDefaultTab();
    });
  }

  private changeTemporaryPassword(): void {
    this.broadcaster.broadcast(Constants.ACCOUNT_CHANGE_TEMPORARY_PASSWORD, this.password);
    this.changeTemporaryPasswordVisible = true;
  }

  public get isMobile(): boolean {
    const browser: Browser = new Browser();
    return browser.isMobile;
  }

  public showPaswordForMobile(): void {
    if (this.isMobile) {
      this.showPassword = !this.showPassword;
    }
  }

  public onUserNameInputChange(event: any): void {
    if (event) {
      this.userNameValueMissing = false;
    } else {
      this.userNameValueMissing = true;
    }
  }

  public onPasswordInputChange(event: any): void {
    if (event) {
      this.PasswordValueMissing = false;
    } else {
      this.PasswordValueMissing = true;
    }
  }

  public oncloseErrorMessage(): void {
    if (this.modalService.reloadAfterClose) {
      window.location.reload();
    }
  }
}
