<div class="PayslipsNote" [ngClass]="{ Width900: haveSalaryStatements }" *ngIf="isMoreOneCompany">
  <span>{{ selfservicepayslipNote }}</span>
</div>
<div
  class="{{ previewClicked ? 'viewSlip loaded' : '' }} Island Island-selfService"
  (click)="onClickOutside($event)"
  tabindex="-1"
  (keypress)="onTableKeydown($event)"
>
  <table class="Payslips-list" [ngClass]="{ 'Payslips-list-narrower': previewClicked }">
    <thead>
      <tr>
        <th>{{ 'SelfServicePayslip.PayoutDate' | translate }}</th>
        <th>
          {{ 'SelfServicePayslip.Salary' | translate }}
        </th>
        <th class="show-html-payslip">
          {{ 'SelfServicePayslip.Supplements' | translate }}
        </th>
        <th class="show-html-payslip">
          {{ 'SelfServicePayslip.Pension' | translate }}
        </th>
        <th>
          {{ 'SelfServicePayslip.Taxes' | translate }}
        </th>
        <th class="show-html-payslip">
          {{ 'SelfServicePayslip.Adjustments' | translate }}
        </th>
        <th>{{ 'SelfServicePayslip.PaidOut' | translate }}</th>
        <th class="icons"></th>
        <th class="icons open-icon" *ngIf="!isCordovaApp"></th>
        <th class="icons open-icon" *ngIf="isCordovaApp"></th>
        <th *ngIf="previewClicked"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let item of salaryStatements; let i = index"
        data-id="{{ item.salaryStatement.Id }}"
        (click)="onClickButton('select', item, i)"
        [ngClass]="{ active: item.isSelected }"
      >
        <td [innerHTML]="item.salaryStatement.SalaryBatch.PayoutDate | DateFormat: undefined:true"></td>
        <td>
          {{ item.salaryStatement.SummaryAmountSalary | NumericFormat }}
        </td>
        <td class="show-html-payslip">
          {{ item.salaryStatement.SummaryAmountSupplements | NumericFormat }}
        </td>
        <td class="show-html-payslip">
          {{ item.salaryStatement.SummaryAmountPension | NumericFormat }}
        </td>
        <td>
          {{ item.salaryStatement.SummaryAmountTaxes | NumericFormat }}
        </td>
        <td class="show-html-payslip">
          {{ item.salaryStatement.SummaryAmountAdjustments | NumericFormat }}
        </td>
        <td>{{ item.salaryStatement.PayoutAmount | NumericFormat }}</td>
        <td *ngIf="!isCordovaApp" class="icons ">
          <app-action-button
            [icon]="'Download'"
            (click)="onClickButton('downloadPayslip', $event, item.salaryStatement.Id)"
            [tooltip]="'SelfServicePayslip.PaySlipViewPdf' | translate"
          >
          </app-action-button>
        </td>
        <td *ngIf="isCordovaApp" class="icons ">
          <app-action-button [icon]="'Download'" (click)="onDownloadPayslip($event, item.salaryStatement.Id)">
          </app-action-button>
        </td>
        <td *ngIf="!isCordovaApp" class="icons  open-icon">
          <app-action-button
            [icon]="'ExternalLink'"
            *ngIf="!isCordovaApp"
            (click)="onClickButton('openPayslip', $event, item.salaryStatement.Id)"
            [tooltip]="'SelfServicePayslip.PaySlipViewLink' | translate"
          >
          </app-action-button>
        </td>

        <td *ngIf="isCordovaApp" class="icons  open-icon">
          <app-action-button
            [icon]="'ExternalLink'"
            *ngIf="isCordovaApp"
            (click)="onClickButton('downloadStatement', null, item.salaryStatement)"
            [tooltip]="'EmploymentPayrollData.HTMLPopupToolTip' | translate"
          >
          </app-action-button>
        </td>
        <td *ngIf="previewClicked"></td>
      </tr>
    </tbody>
  </table>

  <div class="Payslip-view" *ngIf="!isCordovaApp">
    <div class="Payslip-container">
      <div class="Payslip-navigation" *ngIf="previewClicked">
        <app-action-button
          [icon]="'ExternalLink'"
          [buttonClass]="'Payslip-navigationItem'"
          (click)="onClickButton('open')"
          [tooltip]="'SelfServicePayslip.PaySlipViewLink' | translate"
        >
        </app-action-button>
        <app-action-button
          [icon]="'Download'"
          [buttonClass]="'Payslip-navigationItem'"
          (click)="onClickButton('download')"
          [tooltip]="'SelfServicePayslip.PaySlipViewPdf' | translate"
        >
        </app-action-button>
        <app-action-button
          [icon]="'file-print'"
          [disabled]="printBtnDisabled"
          [buttonClass]="'Payslip-navigationItem'"
          (click)="onClickButton('print')"
          [tooltip]="'Print'"
          *ngIf="!isMobile"
        >
        </app-action-button>
        <app-action-button
          [icon]="'Times'"
          [buttonClass]="'Payslip-navigationItem'"
          (click)="onClickButton('close')"
          [tooltip]="'SelfServicePayslip.PaySlipViewClose' | translate"
        >
        </app-action-button>
        <app-action-button
          [icon]="'CaretUp'"
          [disabled]="previousBtnDisabled"
          [buttonClass]="'Payslip-navigationItem'"
          (click)="onClickButton('previous')"
          [tooltip]="'SelfServicePayslip.PaySlipViewPrevious' | translate"
        >
        </app-action-button>
        <app-action-button
          [icon]="'CaretDown'"
          [disabled]="nextBtnDisabled"
          [buttonClass]="'Payslip-navigationItem'"
          (click)="onClickButton('next')"
          [tooltip]="'SelfServicePayslip.PaySlipViewNext' | translate"
        >
        </app-action-button>
      </div>

      <div class="Payslip">
        <div class="Payslip-iframe">
          <div *ngIf="htmlContent.length === 0" class="Payslip-loader"><span>Fetching payslip&hellip;</span></div>
          <div class="Payslip-iframe" *ngIf="htmlContent.length > 0">
            <iframe
              *ngIf="!isEdge"
              #iframePayslipPreview
              [srcdoc]="safeContent"
              name="payslipIframe"
              src="about:blank"
              (load)="onLoadIframe()"
            ></iframe>
            <iframe *ngIf="isEdge" #iframePayslipPreview name="payslipIframe" (load)="onLoadIframe()"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-dialog class="PayslipPopup" [(visible)]="paySlipPreviewDialog" [iframeContent]="htmlContent"> </app-dialog>

<app-dialog
  class="blue-dialog"
  *ngIf="!isCordovaApp"
  [(visible)]="newTabBlockedDialogVisible"
  [leadingText]="'Report.NewTabBlocked' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
