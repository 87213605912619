import * as tslib_1 from "tslib";
import { SalaryPeriod } from '../services/api/api-model';
var ExtendSalaryPeriod = /** @class */ (function (_super) {
    tslib_1.__extends(ExtendSalaryPeriod, _super);
    function ExtendSalaryPeriod() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ExtendSalaryPeriod;
}(SalaryPeriod));
export { ExtendSalaryPeriod };
