import { Injectable } from '@angular/core';
import { SessionService } from './session/session.service';

@Injectable({
  providedIn: 'root'
})
export class FixedPanelService {
  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public get isHybridApp(): boolean {
    return this.sessionService.browser.isHybridApp;
  }

  public get iOSMobileDevice(): boolean {
    return this.sessionService.browser.iOSMobileDevice;
  }

  public get isSafari(): boolean {
    return this.sessionService.browser.isSafari;
  }

  public get isIphoneNotch(): boolean {
    return this.sessionService.browser.isIphoneNotch;
  }

  constructor(private sessionService: SessionService) {}

  public excludedComponents: [] = [];
  public specificClassName = '';

  public calculateHeightContent(isEditMode?: boolean) {
    let pageHeight = document.querySelector('.fixed-panel-container .Island') as HTMLElement;
    if (this.specificClassName) {
      pageHeight = document.querySelector('.fixed-panel-container ' + '.' + this.specificClassName) as HTMLElement;
    }

    if (!pageHeight) {
      setTimeout(() => {
        this.calculateHeightContent();
      }, 1000);
      return;
    }
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    let totalExcludedHeight = 0;
    this.excludedComponents.forEach((component: string) => {
      const componentElement = document.getElementsByClassName(component)[0] as HTMLElement;
      if (componentElement) {
        totalExcludedHeight += componentElement.clientHeight;
      }
      const isExtraExcluded = parseInt(component, 10);
      if (!Number.isNaN(isExtraExcluded)) {
        totalExcludedHeight += isExtraExcluded;
      }
    });

    // Add header and tabpanel to exclude for all tab

    const headerComponent = document.getElementsByClassName('Header')[0] as HTMLElement;
    totalExcludedHeight += headerComponent ? headerComponent.clientHeight : 0;
    const tabPanelComponent = document.getElementsByClassName('TabPanel')[0] as HTMLElement;
    totalExcludedHeight += tabPanelComponent ? tabPanelComponent.clientHeight : 40;
    const footerComponent = document.getElementsByClassName('Footer-info')[0] as HTMLElement;
    let footerHeight = 10;
    if (windowWidth < 1400) {
      footerHeight = 35;
    }
    totalExcludedHeight += footerComponent ? footerHeight : 0;
    // End for all tab

    if (this.specificClassName === 'Island-employee--payroll' && (this.isHybridApp || this.isMobile)) {
      totalExcludedHeight += 105; //Employee payroll data, only for mobile
    }

    if (this.specificClassName === 'Island-employee--general' && (this.isHybridApp || this.isMobile)) {
      totalExcludedHeight += 30; //Employee general, only for mobile
    }

    if (this.specificClassName === 'Island-employee--payslip' && (this.isHybridApp || this.isMobile)) {
      totalExcludedHeight += 20; //Employee payslip, only for mobile
    }

    pageHeight.style.height = windowHeight - totalExcludedHeight + 'px';
  }
}
