<app-tab-panel
  [parentTab]="'selfService'"
  [payslipTabVisible]="hasEmployments"
  [timeTabVisible]="hasEmployments && sessionService.feature.hasModuleId(8)"
></app-tab-panel>

<div
  (click)="onTabClick()"
  class="Main Main-mobile"
  [ngClass]="editMode ? 'is-editing' : ''"
  (dblclick)="onDoubleClick()"
>
  <!-- Main -->
  <div
    id="selfserviceViewHolder"
    [ngClass]="{ employeetimeBackground: state.currentStateIs('tabs.selfservice.time') && isMobile }"
  >
    <div *ngIf="state.currentStateIs('tabs.selfservice.payslip')" class="wrapper">
      <app-self-service-payslip></app-self-service-payslip>
    </div>

    <div *ngIf="state.currentStateIs('tabs.selfservice.general')" class="wrapper">
      <app-employee-general
        [isSelfService]="true"
        [companyUser]="employeeService.employee | async"
        [userEmployment]="employeeService.employment | async"
        [(editMode)]="editMode"
        [(isValid)]="isValid"
        [(isDirty)]="isDirty"
        (valueChange)="onEmployeeGeneralChanged()"
        [ngClass]="editMode ? 'is-edit-mode' : ''"
      >
      </app-employee-general>
    </div>

    <div
      *ngIf="state.currentStateIs('tabs.selfservice.time')"
      class="wrapper"
      [ngClass]="isFirefox ? 'PaddingBottom60' : 'PaddingBottom25'"
    >
      <app-employee-time
        [currentCompanyUser]="employeeService.employee | async"
        [userEmployment]="employeeService.employment | async"
      ></app-employee-time>
    </div>

    <app-feedback [hidden]="editMode"></app-feedback>
  </div>
  <!-- /Main -->
  <!-- Actions -->
  <div class="Actions" *ngIf="state.currentStateIs('tabs.selfservice.payslip') && !isMobile">
    <app-menu-button
      [menuCondition]="reportDialogService.isVisibleReportIcon"
      (menuClick)="onShowReportsEventClick()"
      [menuIcon]="'File'"
      [menuLabel]="'Employee.Reports'"
      [menuTooltip]="'Employee.Reports'"
      [actionButtonMenu]="true"
      [menuButtonClass]="'Action-button Action-buttonReports'"
      [menuButtonId]="'ActionButtonReports'"
    ></app-menu-button>
    <app-menu-button
      [menuIcon]="'Help'"
      [menuLabel]="'Help.Title'"
      [menuTooltip]="'Help.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
      <app-menu-button-item [field]="'welcomeMessage'"> </app-menu-button-item>
    </app-menu-button>
  </div>

  <div class="Actions" *ngIf="state.currentStateIs('tabs.selfservice.payslip') && isMobile">
    <app-menu-button
      [menuIcon]="'ThreeDots'"
      [menuLabel]="'More.Title'"
      [menuTooltip]="'More.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
      <app-menu-button-item [field]="'welcomeMessage'"> </app-menu-button-item>
    </app-menu-button>
  </div>

  <div
    class="Actions"
    [ngClass]="isIOSApp ? 'is-ios-app' : ''"
    *ngIf="state.currentStateIs('tabs.selfservice.general') && !editMode"
  >
    <app-menu-button
      *ngIf="reportDialogService.isVisibleReportIcon"
      [menuCondition]="true"
      [menuIcon]="'File'"
      [menuLabel]="'Employee.Reports'"
      [menuTooltip]="'Employee.Reports'"
      [actionButtonMenu]="true"
      [menuButtonClass]="'Action-button Action-buttonReports'"
      [menuButtonId]="'ActionButtonReports'"
      (menuClick)="reportDialogService.onShowReportsEventClick()"
    >
    </app-menu-button>
    <app-menu-button
      *ngIf="state.currentStateIs('tabs.selfservice.general')"
      [editButtonMenuSS]="true"
      (menuChangeClick)="onEditModeChange($event)"
      [menuCondition]="true"
      [editMode]="editMode"
      [isCanChangeEditMode]="allowUsersToModifyOwnData"
      (errorChangeEditMode)="isDisableChangeEditMode = true"
      [allowCompanyPreference]="allowUsersToModifyOwnData"
      [menuButtonClass]="disabledActionButtonClass"
    >
    </app-menu-button>
  </div>

  <div class="Actions" [ngClass]="isIOSApp ? 'is-ios-app' : ''" *ngIf="state.currentStateIs('tabs.selfservice.time')">
    <app-menu-button
      *ngIf="reportDialogService.isVisibleReportIcon"
      [menuCondition]="true"
      [menuIcon]="'File'"
      [menuLabel]="'Employee.Reports'"
      [menuTooltip]="'Employee.Reports'"
      [actionButtonMenu]="true"
      [menuButtonClass]="'Action-button Action-buttonReports'"
      [menuButtonId]="'ActionButtonReports'"
      (menuClick)="reportDialogService.onShowReportsEventClick()"
    >
    </app-menu-button>
  </div>

  <div class="EditingInfo Actions" *ngIf="editMode">
    <app-button
      [buttonClass]="'Discard'"
      (clickEvent)="confirmAndDiscardChanges()"
      [label]="'Employee.DiscardButton' | translate"
      *ngIf="editMode"
    ></app-button>
    <app-menu-button
      [editButtonMenu]="true"
      [editMode]="true"
      (menuChangeClick)="onEditModeChange($event)"
      [menuCondition]="true"
      class="menu-button--editing"
    >
    </app-menu-button>
  </div>
</div>

<app-report-dialog
  [(visible)]="reportDialogService.reportDialogVisible"
  [key]="reportDialogService.key"
  [param]="reportDialogService.reportParams"
>
</app-report-dialog>

<app-dialog
  (action)="discardDialogAction($event)"
  [(visible)]="discardDialogVisible"
  [leadingText]="'Employee.DiscardMessage' | translate"
>
  <app-dialog-action type="CancelDiscard"></app-dialog-action>
  <app-dialog-action type="ConfirmDiscard"></app-dialog-action>
</app-dialog>

<app-dialog (action)="onConfirmSaveDataOnChangeState($event)" [(visible)]="showSaveDataConfirmationChangeState">
  <div [innerHTML]="'Employee.UnsavedData' | translate"></div>
  <app-dialog-action type="DiscardAndLeave"></app-dialog-action>
  <app-dialog-action type="Stay"></app-dialog-action>
  <app-dialog-action type="ContinueAndSave"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="isDisableChangeEditMode"
  [width]="'500'"
  [leadingText]="'EmploymentTime.DisableGridTimeMessage' | translate"
>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>
