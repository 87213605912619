<app-check-edit
  [editMode]="true"
  [(value)]="isSpecialMode"
  (valueChange)="onModeChange()"
  [label]="'EmploymentTime.ShowAllOpenEntriesAcrossPeriods' | translate"
  class="integrationConfigurationControl"
>
</app-check-edit>
<div class="Employee-timeControls Approval-timeCard">
  <app-combobox-edit
    class="Company-SalaryPeriod"
    [disable]="isSpecialMode"
    [label]="'EmploymentTime.SalaryPeriod' | translate"
    [editMode]="true"
    [isConvertTime]="false"
    [textField]="'FriendlyName'"
    [idField]="'Id'"
    [comboboxDataSource]="SalaryPeriod"
    [(value)]="selectedSalaryPeriodId"
    [placeholder]="'EmploymentTime.SalaryPeriod' | translate"
    (valueChange)="onBaseSalaryPeriodComboboxChanged($event)"
    [showNavigationButton]="true"
    [keepDataSourceOrder]="true"
  >
  </app-combobox-edit>
  <app-combobox-edit
    [disable]="isSpecialMode"
    class="Company-EmploymentTimeStatus"
    [label]="'EmploymentTime.Status' | translate"
    [editMode]="true"
    [textField]="'Name'"
    [idField]="'Id'"
    [comboboxDataSource]="TimeEntryStatus"
    [(value)]="selectedTimeEntryStatusId"
    [placeholder]="'EmploymentTime.Status' | translate"
    (valueChange)="onBaseTimeEntryStatusComboboxChanged($event)"
  >
  </app-combobox-edit>
  <app-combobox-edit
    *ngIf="isShowSalaryCycle"
    class="Company-PayrollCycle"
    [label]="'EmploymentTime.SalaryCycle' | translate"
    [editMode]="true"
    [textField]="'Name'"
    [idField]="'Id'"
    [comboboxDataSource]="SalaryCycle"
    [(value)]="selectedSalaryCycleId"
    [placeholder]="'EmploymentTime.SalaryCycle' | translate"
    (valueChange)="onBaseSalaryCycleComboboxChanged($event)"
  >
  </app-combobox-edit>

  <div class="leftarea" kendoTooltip [title]="allowManagersToApproveTimeEntryMessage | translate">
    <app-button
      class="Button--card equalbutton"
      [isDisabled]="
        sessionService.isSubmitting ||
        (IsReadOnly && !IsReadOnlyAndHasSubordinatesAndFilterByEligibleForApproval) ||
        !allowManagersToApproveTimeEntry
      "
      (clickEvent)="ApproveEntriesallforselectedemployee()"
      [label]="'EmploymentTime.ApproveAllForSelectedEmployee' | translate"
    >
    </app-button>
    <app-button
      *ngIf="isEnableControlNomalMode"
      class="Button--card equalbutton"
      [isDisabled]="
        sessionService.isSubmitting ||
        (IsReadOnly && !IsReadOnlyAndHasSubordinatesAndFilterByEligibleForApproval) ||
        !isEnableControlNomalMode ||
        !allowManagersToApproveTimeEntry
      "
      (clickEvent)="ApproveEntries()"
      [label]="'EmploymentTime.ApproveEntirePeriod' | translate"
    >
    </app-button>
    <app-button
      *ngIf="!isEnableControlNomalMode"
      class="Button--card equalbutton"
      [isDisabled]="
        sessionService.isSubmitting ||
        (IsReadOnly &&
          !IsReadOnlyAndHasSubordinatesAndFilterByEligibleForApproval &&
          !allowManagersToApproveTimeEntry) ||
        isEnableControlNomalMode ||
        !allowManagersToApproveTimeEntry
      "
      (clickEvent)="ApproveOpenEntries()"
      [label]="'CompanyApproval.ApproveAllOpenEntries' | translate"
    >
    </app-button>
  </div>
</div>

<div class="FormElement">
  <div class="FormElement-control">
    <app-grid
      [editMode]="false"
      [addNew]="false"
      [deleteRow]="false"
      [(data)]="TimeEntryRecord"
      (gridButtonClick)="onGridButtonClick($event)"
      (clickAppGridIcon)="onClickAppGridIcon($event)"
      class="FormElement-grid Employee-timeEntryGrid {{ EmptyEmployeetimeApproval() }}"
      [filterable]="'menu'"
      [filter]="approvalFilter"
      (filterChange)="onApprovalFilterChange($event)"
      (approveRejectData)="onApproveRejectData()"
      [(skip)]="currentpage"
      [pageable]="true"
      [pageSize]="100"
      [isCompanyApproval]="true"
      [timeEntryRecords]="timeEntryRecords"
      [currentTimeEntryStatus]="currentTimeEntryStatus"
      [TimeEntryTypes]="TimeEntryTypes"
      (selectedItemChange)="onSelectRow($event)"
    >
      <app-grid-column
        [isFilterable]="true"
        [title]="'CompanySalaryBatches.OtherStatusGrid_EmployeeName' | translate"
        [type]="'text'"
        [field]="'EmployeeName'"
        [width]="300"
        [headerTooltip]="'CompanySalaryBatches.OtherStatusGrid_EmployeeName' | translate"
        [viewClass]="'truncated'"
        [tooltipField]="'EmployeeName'"
      ></app-grid-column>
      <app-grid-column
        [isFilterable]="true"
        [title]="'EmploymentTime.TotalTime' | translate"
        [type]="'numeric'"
        [field]="'TotalTime'"
        [width]="200"
        [headerTooltip]="'EmploymentTime.TotalTime' | translate"
      ></app-grid-column>
      <app-grid-column
        [isFilterable]="true"
        [title]="'EmploymentTime.TotalDay' | translate"
        [type]="'numeric'"
        [field]="'TotalDay'"
        [width]="200"
        [headerTooltip]="'EmploymentTime.TotalDay' | translate"
      ></app-grid-column>
      <app-grid-column
        [isFilterable]="true"
        [title]="'EmploymentTime.KilometersDriven' | translate"
        [type]="'numeric'"
        [numbericOption]="numberOption"
        [field]="'KilometersDriven'"
        [width]="200"
        [headerTooltip]="'EmploymentTime.KilometersDriven' | translate"
      ></app-grid-column>
      <app-grid-column
        [isFilterable]="true"
        [title]="'EmploymentTime.DepartmentName' | translate"
        [type]="'text'"
        [field]="'DepartmentName'"
        [width]="200"
        [headerTooltip]="'EmploymentTime.DepartmentName' | translate"
      >
      </app-grid-column>
      <app-grid-column
        [isFilterable]="true"
        [title]="'EmploymentTime.Title' | translate"
        [type]="'text'"
        [field]="'Title'"
        [width]="200"
        [headerTooltip]="'EmploymentTime.Title' | translate"
      ></app-grid-column>
    </app-grid>
  </div>
</div>

<app-dialog [overlayClose]="false" [(visible)]="isShowRejectDialog" [width]="'500'" (action)="onRejectDialog($event)">
  <div innerHTML="{{ 'EmploymentTime.RejectionWarning' | translate }}"></div>
  <app-text-edit [label]="''" [editMode]="true" [(value)]="rejectMessage"></app-text-edit>
  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action type="RejectPrimary" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="isShowApproveForSelectDialog" [width]="'500'" (action)="onApproveForSelectDialog($event)">
  <div>{{ 'EmploymentTime.ApproveConfimText' | translate }} {{ nameEmployeeSelect }}?</div>
  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action type="Approve"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="isShowApproveForAllDialog" [width]="'500'" (action)="onApproveForAllDialog($event)">
  <div>{{ 'EmploymentTime.ApproveConfirmText' | translate }}</div>
  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action type="Approve"></app-dialog-action>
</app-dialog>

<app-dialog [(visible)]="isShowApprovalOpenEntries" [width]="'500'" (action)="onApprovalOpenEntries($event)">
  <div>{{ 'CompanyApproval.ApproveAllOpenEntriesMessage' | translate }}</div>
  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action type="Understood"></app-dialog-action>
</app-dialog>
