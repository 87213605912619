import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef, EventEmitter, OnInit } from '@angular/core';
import { ControlComponentBase } from '../../common/control-component-base';
import { UniqueId } from '../../common/unique-id';
import { SessionService } from '../../services/session/session.service';
var PercentageEditComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PercentageEditComponent, _super);
    function PercentageEditComponent(elementRef, sessionService, changeDetectorRef) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.sessionService = sessionService;
        _this.allowDecimal = false;
        _this.disable = false;
        _this.percentageSign = true;
        _this.step = false;
        _this.helpTooltip = '';
        _this.labelTooltip = '';
        _this.valueTooltip = '';
        _this.helpIcon = 'Help';
        _this.isOpenLink = true;
        _this.isSelfService = '';
        _this.maxHeight20 = '';
        _this.decimals = 2;
        _this.blur = new EventEmitter();
        _this.keyUp = new EventEmitter();
        _this.componentId = 'percentDecimalControlIdString' + UniqueId();
        return _this;
    }
    Object.defineProperty(PercentageEditComponent.prototype, "decimalsCode", {
        get: function () {
            if (this.decimals === 2) {
                return 'n2';
            }
            return 'n' + this.decimals;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PercentageEditComponent.prototype, "decimalsCaculateCode", {
        get: function () {
            return 'n' + (this.decimals + 2).toString();
        },
        enumerable: true,
        configurable: true
    });
    PercentageEditComponent.prototype.ngOnInit = function () {
        // this.onChange();
    };
    PercentageEditComponent.prototype.valid = function () {
        return !this.required || this.value === 0 || !!this.value;
    };
    Object.defineProperty(PercentageEditComponent.prototype, "isInvalid", {
        get: function () {
            return this.editMode && !this.valid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PercentageEditComponent.prototype, "percentageDisplayText", {
        get: function () {
            return this.percentValue || this.percentValue === 0
                ? this.sessionService.toString(this.percentValue > 0 ? this.percentValue : 0, this.allowDecimal ? this.decimalsCode : 'n0') + ' %'
                : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PercentageEditComponent.prototype, "percentValue", {
        get: function () {
            return this.value && this.value > 0 ? this.value * 100 : this.value;
        },
        set: function (value) {
            this.value = value > 0 ? value / 100 : value;
        },
        enumerable: true,
        configurable: true
    });
    PercentageEditComponent.prototype.onKeydown = function (key) {
        if (key.keyCode === 27) {
            // Escape
            this.value = this.original;
        }
    };
    PercentageEditComponent.prototype.onkeyup = function (key) {
        this.keyUp.emit(key);
    };
    PercentageEditComponent.prototype.onBlur = function () {
        this.value = this.value > 0 ? this.value * 100 : this.value;
        if (this.value !== undefined && this.value !== 0 && this.value !== null) {
            if (this.value > 1) {
                this.value = this.sessionService.parseFloat(this.sessionService.toString(this.value / 100, this.decimalsCaculateCode));
            }
            else {
                this.value =
                    this.value || this.value === 0
                        ? this.sessionService.parseFloat(this.sessionService.toString(this.value / 100, this.decimalsCaculateCode))
                        : this.value;
            }
        }
        else if (this.value === 0) {
            this.value = 0;
        }
        else {
            this.value = null;
        }
        this.blur.emit();
    };
    return PercentageEditComponent;
}(ControlComponentBase));
export { PercentageEditComponent };
