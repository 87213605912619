import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { PanelBarComponent } from '@progress/kendo-angular-layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NumericTextBoxOptions } from '../../custom-controls/numeric-edit/numeric-text-box-options';
import { ICompanyPreference, ICompanyPreferenceCategory, ISimpleKeyValuePair } from '../../services/api/api-model';
import { SessionService } from '../../services/session/session.service';
import { ReportDialogService } from '../../shared-components/report-dialog/report-dialog.service';
import { CompanyPreferenceService } from './preference.service';

@Component({
  selector: 'app-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PreferenceComponent implements OnDestroy, OnInit {
  public companyReferencesCategory: ICompanyPreferenceCategory[];
  public numericTextBoxOption: NumericTextBoxOptions = { step: 1, spinners: false, decimals: 0, format: 'n0' };
  private companyReferencesInfo: ISimpleKeyValuePair[];
  private companyReferencesStructure: ICompanyPreference[];

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  @ViewChild('companyPanelBar', { static: false }) public companyPanelBar: PanelBarComponent;

  constructor(
    private sessionService: SessionService,
    public reportDialogService: ReportDialogService,
    public companyPreferenceService: CompanyPreferenceService
  ) {
    this.companyPreferenceService.companyReferencesCategory.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      this.companyReferencesCategory = data;
    });
    this.companyPreferenceService.companyReferencesInfo.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      this.companyReferencesInfo = data;
    });
    this.companyPreferenceService.companyReferencesStructure.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      this.companyReferencesStructure = data;
    });
    this.companyPreferenceService.reloveData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      if (data) {
        this.bindPreference();
      }
    });
    this.companyPreferenceService.reloadPanel.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      if (data) {
        this.setDefaultPanelTab();
      }
    });
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit() {
    this.bindPreference();
  }

  private bindPreference() {
    this.companyPreferenceService.dataInint().subscribe(() => {
      if (this.companyReferencesCategory && this.companyReferencesInfo && this.companyReferencesStructure) {
        this.companyPreferenceService
          .createPreferences(this.companyReferencesInfo, this.companyReferencesStructure)
          .subscribe((preferences: any[]) => {
            this.companyReferencesCategory.forEach((category: any) => {
              category.Preferences = preferences.filter((pref: any) => pref.CategoryId === category.Id);
            });

            this.companyPreferenceService.reloadPanel.emit(true);
          });
      }
    });
  }

  private setDefaultPanelTab() {
    setTimeout(() => {
      if (this.companyPanelBar && (this.companyPanelBar as any).allItems && (this.companyPanelBar as any).allItems[0]) {
        (this.companyPanelBar as any).allItems[0].selected = true;
        (this.companyPanelBar as any).allItems[0].expanded = true;
      }
    });
  }

  public onCompanyPanelChanged(event: any): void {}

  public onReadMoreClick(openLink: any): void {
    window.open(openLink);
  }

  public onBtnEvent(item: any): void {
    const value = !item.Value;
    this.companyPreferenceService.submitValueChange(value, item);
  }
}
