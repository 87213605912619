import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Constants } from '../../../common/constants';
import { FormComponentBase } from '../../../common/form-component-base';
import { Global } from '../../../common/global';
import {
  IBank,
  ICompany,
  ICompanyUser,
  IIndustryOrganization,
  ILanguage,
  IPaymentMethod,
  IStatisticsRecipient
} from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { SessionService } from '../../../services/session/session.service';
import { CompanyGeneralService } from '../company-general.service';

@Component({
  selector: 'app-company-option',
  templateUrl: './company-option.component.html'
})
export class CompanyOptionComponent extends FormComponentBase implements OnInit {
  @Input() public contacts: ICompanyUser[];

  public get branding() {
    return environment.branding;
  }

  private companyValue: ICompany;
  @Input()
  public get company(): ICompany {
    return this.companyValue;
  }

  public set company(value: ICompany) {
    this.companyValue = value;
    if (value) {
      this.componentStatisticsValue = this.company.StatisticsRecipientId;
    }
  }

  @Input()
  public set resetChildFrom(value: boolean) {
    if (value) {
      this.childIsDirty = Array(11).fill(false);
      this.childIsValid = Array(11).fill(true);

      this.resetChildFromChange.emit(false);
    }
  }

  @Output() public resetChildFromChange: EventEmitter<any> = new EventEmitter<any>();

  public statisticOptions: IStatisticsRecipient[];
  public paymentMethods: IPaymentMethod[];
  public bankOption: IBank[];
  public languages: ILanguage[];
  public IndustryOrganization: IIndustryOrganization[];
  public hasStatisticModule = false;
  public hasLanguageModule = false;
  public hasPaymentModule = false;
  public componentStatisticsValue: number;
  public isStatisticsChange = false;

  constructor(
    public companyGeneralService: CompanyGeneralService,
    private dataService: DataService,
    private sessionService: SessionService,
    private staticDataService: StaticDataService
  ) {
    super();
    this.childIsDirty = Array(11).fill(false);
  }

  public ngOnInit(): void {
    this.staticDataService.PaymentMethod.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IPaymentMethod[]) => (this.paymentMethods = data)
    );
    this.staticDataService.languages.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ILanguage[]) => {
      this.languages = data;
    });

    this.staticDataService.StatisticsRecipient.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (statisticOptions: IStatisticsRecipient[]): void => {
        this.statisticOptions = statisticOptions;
      }
    );

    this.staticDataService.Bank.pipe(takeUntil(this.ngUnsubscribe)).subscribe((bankOption: IBank[]): void => {
      if (this.company && this.company.CountryId && bankOption && bankOption.length > 0) {
        this.bankOption = bankOption.filter((model: IBank) => model.CountryId === this.company.CountryId);
      }
    });

    this.dataService.StaticData_GetIndustryOrganizations().subscribe((data: IIndustryOrganization[]): void => {
      this.IndustryOrganization = data;
    });
  }

  public StatisticsIconClick() {
    window.open('https://support.gratisal.com/hc/da/articles/213857065', '_blank');
  }

  public onStatisticsChange(val: number) {
    if (!this.company.StatisticsRecipientId && val) {
      this.isStatisticsChange = true;
    }
    this.company.StatisticsRecipientId = val;
    this.onChange();
  }

  public get isAdminUser(): boolean {
    return this.sessionService.role.IsFullAdmin;
  }

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public get IsDanishCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
  }

  public get IsSwedenCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
  }

  public get isAccountAdmin(): boolean {
    return Global.SESSION && Global.SESSION.IsAccountAdmin;
  }

  public onAdditionalOptionClick(): void {
    this.sessionService.NavigateTo('tabs.company.configuration.settings');
  }
}
