<div class="Island Island--advanced-time-entry-type" [ngClass]="{ 'is-editing': companyAdvancedTypesService.editMode }">
  <div class="Cards Cards--packed">
    <div class="Card">
      <div class="Card-inner">
        <fieldset class="Form--horizontal">
          <legend class="Card-title">
            <span>{{ 'CompanySalaryTypes.AdvancedBasic' | translate }}</span>
          </legend>
          <div [ngClass]="{ 'is-editing': companyAdvancedTypesService.editMode }">
            <app-text-edit label="ID" [editMode]="false" [(value)]="companyAdvancedTypesService.selectedType.Id">
            </app-text-edit>
            <app-text-edit
              [label]="'CompanyTimeEntryTypes.InheritsFrom' | translate"
              [editMode]="false"
              [(value)]="companyAdvancedTypesService.selectedType.baseSalaryName"
              [tooltipContent]="'CompanySalaryTypes.BaseSalaryTypeNameHeaderTooltip' | translate"
            >
            </app-text-edit>
            <app-check-edit
              [label]="'CompanyTimeEntryTypes.IsActive' | translate"
              [editMode]="companyAdvancedTypesService.editMode"
              [(value)]="companyAdvancedTypesService.selectedType.IsActive"
              [(isDirty)]="childIsDirty[0]"
              (valueChange)="onChange($event, 'IsActive', 0)"
              [ngClass]="companyAdvancedTypesService.selectedType.isActiveClassSingle"
            >
            </app-check-edit>
            <app-text-edit
              *ngIf="companyAdvancedTypesService.isLanguageModuleEnable"
              class="edit-translation-text"
              label="{{ 'CompanySalaryTypes.Description' | translate }}"
              [editMode]="false"
              [(isDirty)]="childIsDirty[1]"
              [(isValid)]="childIsValid[1]"
              [ngClass]="companyAdvancedTypesService.selectedType.descriptionClassSingle"
              [value]="companyAdvancedTypesService.descriptionDisableLanguaged"
              [tooltipContent]="'CompanySalaryTypes.DescriptionHeaderTooltip' | translate"
            >
            </app-text-edit>
            <app-text-edit
              *ngIf="!companyAdvancedTypesService.isLanguageModuleEnable"
              class="edit-translation-text"
              label="{{ 'CompanySalaryTypes.Description' | translate }}"
              [editMode]="companyAdvancedTypesService.editMode"
              [isTextArea]="true"
              [textAreaLimitChar]="1000"
              [(isDirty)]="childIsDirty[1]"
              [(isValid)]="childIsValid[1]"
              (valueChange)="onChange($event, 'Description', 1)"
              [ngClass]="companyAdvancedTypesService.selectedType.descriptionClassSingle"
              [(value)]="companyAdvancedTypesService.selectedType.Description"
              [tooltipContent]="'CompanySalaryTypes.DescriptionHeaderTooltip' | translate"
              [tooltipTextArea]="companyAdvancedTypesService.selectedType.Description"
              [placeholder]="
                companyAdvancedTypesService.selectedType.descriptionClassSingle &&
                companyAdvancedTypesService.selectedType.descriptionClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-text-edit>
            <app-text-edit
              *ngIf="companyAdvancedTypesService.isLanguageModuleEnable"
              class="edit-translation-text"
              label="{{ 'CompanySalaryTypes.PayslipText' | translate }}"
              [editMode]="false"
              [(isDirty)]="childIsDirty[2]"
              [(isValid)]="childIsValid[2]"
              [ngClass]="companyAdvancedTypesService.selectedType.defaultPayslipTextClassSingle"
              [value]="companyAdvancedTypesService.defaultPayslipTextDisableLanguaged"
              [tooltipContent]="'CompanySalaryTypes.DefaultPayslipTextHeaderTooltip' | translate"
              [placeholder]="
                companyAdvancedTypesService.selectedType.defaultPayslipTextClassSingle &&
                companyAdvancedTypesService.selectedType.defaultPayslipTextClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-text-edit>
            <app-text-edit
              *ngIf="!companyAdvancedTypesService.isLanguageModuleEnable"
              class="edit-translation-text"
              label="{{ 'CompanySalaryTypes.PayslipText' | translate }}"
              [editMode]="companyAdvancedTypesService.editMode"
              [(isDirty)]="childIsDirty[2]"
              [(isValid)]="childIsValid[2]"
              (valueChange)="onChange($event, 'DefaultPayslipText', 2)"
              [ngClass]="companyAdvancedTypesService.selectedType.defaultPayslipTextClassSingle"
              [(value)]="companyAdvancedTypesService.selectedType.DefaultPayslipText"
              [tooltipContent]="'CompanySalaryTypes.DefaultPayslipTextHeaderTooltip' | translate"
              [placeholder]="
                companyAdvancedTypesService.selectedType.defaultPayslipTextClassSingle &&
                companyAdvancedTypesService.selectedType.defaultPayslipTextClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-text-edit>
            <app-text-edit
              *ngIf="companyAdvancedTypesService.isLanguageModuleEnable"
              class="edit-translation-text"
              label="{{ 'CompanySalaryTypes.Explanation' | translate }}"
              [editMode]="false"
              [(isDirty)]="childIsDirty[20]"
              [(isValid)]="childIsValid[20]"
              (valueChange)="onChange()"
              [(value)]="companyAdvancedTypesService.selectedType.Explanation"
            >
            </app-text-edit>
            <app-text-edit
              *ngIf="!companyAdvancedTypesService.isLanguageModuleEnable"
              class="edit-translation-text"
              label="{{ 'CompanySalaryTypes.Explanation' | translate }}"
              [editMode]="companyAdvancedTypesService.editMode"
              [(isDirty)]="childIsDirty[20]"
              [(isValid)]="childIsValid[20]"
              (valueChange)="onChange()"
              [(value)]="companyAdvancedTypesService.selectedType.Explanation"
            >
            </app-text-edit>
            <app-button
              class="Button--card"
              *ngIf="companyAdvancedTypesService.isLanguageModuleEnable"
              (clickEvent)="onTranslationButtonClick()"
              [label]="'CompanySalaryTypes.EditTranslations' | translate"
            >
            </app-button>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="Card">
      <div class="Card-inner">
        <fieldset class="Form--horizontal">
          <legend class="Card-title">
            <span>{{ 'CompanySalaryTypes.AdvancedInterface' | translate }}</span>
          </legend>
          <div>
            <app-auto-complete-textbox
              [editMode]="companyAdvancedTypesService.editMode"
              [autoCompleteDataSource]="externalReferences"
              [(value)]="companyAdvancedTypesService.selectedType.ExternalReference"
              [label]="'CompanyGeneral.ERPref' | translate"
              (valueChange)="onChange($event, 'ExternalReference', 22)"
              [(isDirty)]="childIsDirty[22]"
            >
            </app-auto-complete-textbox>

            <app-text-edit
              label="{{ 'CompanySalaryTypes.ImportExternalReference' | translate }}"
              [editMode]="companyAdvancedTypesService.editMode"
              [(isDirty)]="childIsDirty[3]"
              [(isValid)]="childIsValid[3]"
              (valueChange)="onChange($event, 'ImportExternalReference', 3)"
              [(value)]="companyAdvancedTypesService.selectedType.ImportExternalReference"
              [tooltipContent]="'CompanySalaryTypes.ImportExternalReferenceHeaderTooltip' | translate"
            >
            </app-text-edit>

            <app-combobox-edit
              [label]="'CompanySalaryTypes.AccountType' | translate"
              [editMode]="companyAdvancedTypesService.editMode"
              [comboboxDataSource]="accountTypes"
              [textField]="'Name'"
              [idField]="'Id'"
              (valueChange)="onChange($event, 'AccountTypeId', 23)"
              [(isDirty)]="childIsDirty[23]"
              [(value)]="companyAdvancedTypesService.selectedType.AccountTypeId"
              [ngClass]="companyAdvancedTypesService.selectedType.accountTypeIdClassSingle"
              [placeholder]="
                companyAdvancedTypesService.selectedType.accountTypeIdClassSingle &&
                companyAdvancedTypesService.selectedType.accountTypeIdClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-combobox-edit>

            <app-numeric-edit
              *ngIf="isFromDenmark"
              [editMode]="companyAdvancedTypesService.editMode"
              [(isDirty)]="childIsDirty[13]"
              [(value)]="companyAdvancedTypesService.selectedType.EIncomeSubCategory"
              (valueChange)="onChange($event, 'EIncomeSubCategory', 13)"
              [option]="companyAdvancedTypesService.option"
              [label]="'CompanySalaryTypes.EIncomeField' | translate"
              [helpTooltip]="'CompanySalaryTypes.EIncomeSubCategoryHeaderTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.eIncomeSubCategoryClassSingle"
              [placeholder]="
                companyAdvancedTypesService.selectedType.eIncomeSubCategoryClassSingle &&
                companyAdvancedTypesService.selectedType.eIncomeSubCategoryClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-numeric-edit>

            <app-numeric-edit
              *ngIf="isFromDenmark"
              [editMode]="companyAdvancedTypesService.editMode"
              [(isDirty)]="childIsDirty[12]"
              [(value)]="companyAdvancedTypesService.selectedType.EIncomeCategory"
              (valueChange)="onChange($event, 'EIncomeCategory', 12)"
              [option]="companyAdvancedTypesService.option"
              [label]="'CompanySalaryTypes.IncomeCategory' | translate"
              [helpTooltip]="'CompanySalaryTypes.EIncomeCategoryHeaderTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.eIncomeCategoryClassSingle"
              [placeholder]="
                companyAdvancedTypesService.selectedType.eIncomeCategoryClassSingle &&
                companyAdvancedTypesService.selectedType.eIncomeCategoryClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-numeric-edit>

            <app-text-edit
              *ngIf="isFromDenmark"
              label="{{ 'CompanySalaryTypes.StatisticsFieldCode' | translate }}"
              [editMode]="companyAdvancedTypesService.editMode"
              [(isDirty)]="childIsDirty[16]"
              [(isValid)]="childIsValid[16]"
              (valueChange)="onChange($event, 'StatisticsFieldCode', 16)"
              [(value)]="companyAdvancedTypesService.selectedType.StatisticsFieldCode"
              [tooltipContent]="'CompanySalaryTypes.StatisticsFieldCodeHeaderTooltip' | translate"
            >
            </app-text-edit>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="Card">
      <div class="Card-inner">
        <fieldset class="Form--horizontal">
          <legend class="Card-title">
            <span>{{ 'CompanySalaryTypes.AdvancedBehavior' | translate }}</span>
          </legend>
          <div>
            <app-combobox-edit
              [label]="'CompanySalaryTypes.AfterNextbatch' | translate"
              [editMode]="companyAdvancedTypesService.editMode"
              [comboboxDataSource]="persistanceTypes"
              [textField]="'Name'"
              [idField]="'Id'"
              (valueChange)="onChange($event, 'DefaultPersistanceTypeId', 24)"
              [(isDirty)]="childIsDirty[24]"
              [(value)]="companyAdvancedTypesService.selectedType.DefaultPersistanceTypeId"
              [tooltipContent]="'CompanySalaryTypes.DefaultPersistanceTypeIdHeaderTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.defaultPersistanceTypeIdClassSingle"
              [placeholder]="
                companyAdvancedTypesService.selectedType.defaultPersistanceTypeIdClassSingle &&
                companyAdvancedTypesService.selectedType.defaultPersistanceTypeIdClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-combobox-edit>

            <app-numeric-edit
              [editMode]="companyAdvancedTypesService.editMode"
              [(isDirty)]="childIsDirty[4]"
              [(value)]="companyAdvancedTypesService.selectedType.SortOrder"
              (valueChange)="onChange($event, 'SortOrder', 4)"
              [option]="companyAdvancedTypesService.option"
              [label]="'CompanySalaryTypes.SortOrder' | translate"
              [helpTooltip]="'CompanySalaryTypes.SortOrderHeaderTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.sortOrderClassSingle"
              [placeholder]="
                companyAdvancedTypesService.selectedType.sortOrderClassSingle &&
                companyAdvancedTypesService.selectedType.sortOrderClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-numeric-edit>

            <app-combobox-edit
              [label]="'CompanySalaryTypes.UnitType' | translate"
              [editMode]="companyAdvancedTypesService.editMode"
              [comboboxDataSource]="unitTypes"
              [textField]="'Name'"
              [idField]="'Id'"
              (valueChange)="onChange($event, 'UnitTypeId', 25)"
              [(isDirty)]="childIsDirty[25]"
              [(value)]="companyAdvancedTypesService.selectedType.UnitTypeId"
              [tooltipContent]="'CompanySalaryTypes.UnitTypeIdHeaderTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.unitTypeIdClassSingle"
              [placeholder]="
                companyAdvancedTypesService.selectedType.unitTypeIdClassSingle &&
                companyAdvancedTypesService.selectedType.unitTypeIdClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-combobox-edit>

            <app-check-edit
              [label]="'CompanySalaryTypes.AllowEditUnitType' | translate"
              [editMode]="companyAdvancedTypesService.editMode"
              [(value)]="companyAdvancedTypesService.selectedType.AllowEditUnitType"
              [(isDirty)]="childIsDirty[5]"
              (valueChange)="onChange($event, 'AllowEditUnitType', 5)"
              [helpTooltip]="'CompanySalaryTypes.AllowEditUnitTypeHeaderTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.allowEditUnitTypeClassSingle"
            >
            </app-check-edit>

            <app-check-edit
              [label]="'CompanySalaryTypes.ExpectNegativeAmount' | translate"
              [editMode]="companyAdvancedTypesService.editMode"
              [(value)]="companyAdvancedTypesService.selectedType.NegativeDefault"
              [(isDirty)]="childIsDirty[11]"
              (valueChange)="onChange($event, 'NegativeDefault', 11)"
              [helpTooltip]="'CompanySalaryTypes.NegativeDefaultHeaderTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.negativeDefaultClassSingle"
            >
            </app-check-edit>

            <app-combobox-edit
              [label]="'CompanySalaryTypes.Behavior' | translate"
              [editMode]="companyAdvancedTypesService.editMode"
              [comboboxDataSource]="categories"
              [textField]="'Name'"
              (valueChange)="onChange($event, 'CategoryId', 26)"
              [(isDirty)]="childIsDirty[26]"
              [idField]="'Id'"
              [(value)]="companyAdvancedTypesService.selectedType.CategoryId"
              [tooltipContent]="'CompanySalaryTypes.CategoryIdHeaderTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.categoryIdClassSingle"
              [placeholder]="
                companyAdvancedTypesService.selectedType.categoryIdClassSingle &&
                companyAdvancedTypesService.selectedType.categoryIdClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-combobox-edit>

            <app-combobox-edit
              [label]="'CompanySalaryTypes.SummaryCategory' | translate"
              [editMode]="companyAdvancedTypesService.editMode"
              [comboboxDataSource]="summaryCategories"
              [textField]="'Name'"
              (valueChange)="onChange($event, 'SummaryCategoryId', 27)"
              [(isDirty)]="childIsDirty[27]"
              [idField]="'Id'"
              [(value)]="companyAdvancedTypesService.selectedType.SummaryCategoryId"
              [tooltipContent]="'CompanySalaryTypes.SummaryCategoryIdHeaderTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.summaryCategoryClassSingle"
              [placeholder]="
                companyAdvancedTypesService.selectedType.summaryCategoryClassSingle &&
                companyAdvancedTypesService.selectedType.summaryCategoryClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-combobox-edit>

            <app-numeric-edit
              *ngIf="isFromSwedish"
              [editMode]="companyAdvancedTypesService.editMode"
              [(isDirty)]="childIsDirty[14]"
              [(value)]="companyAdvancedTypesService.selectedType.SkatteverketAgiFieldCode"
              (valueChange)="onChange($event, 'SkatteverketAgiFieldCode', 14)"
              [option]="companyAdvancedTypesService.option"
              [label]="'CompanySalaryTypes.AGIField' | translate"
            >
            </app-numeric-edit>

            <app-text-edit
              *ngIf="isFromGreenland"
              label="{{ 'CompanySalaryTypes.SulinalFieldNumber' | translate }}"
              [editMode]="companyAdvancedTypesService.editMode"
              [(isDirty)]="childIsDirty[15]"
              [(isValid)]="childIsValid[15]"
              (valueChange)="onChange($event, 'SulinalFieldNumber', 15)"
              [(value)]="companyAdvancedTypesService.selectedType.SulinalFieldNumber"
            >
            </app-text-edit>

            <app-text-edit
              label="{{ 'CompanySalaryTypes.SumFromIds' | translate }}"
              [editMode]="companyAdvancedTypesService.editMode"
              [(isDirty)]="childIsDirty[17]"
              [(isValid)]="childIsValid[17]"
              (valueChange)="onChange($event, 'SumFromIds', 17)"
              [(value)]="companyAdvancedTypesService.selectedType.SumFromIds"
              [tooltipContent]="'CompanySalaryTypes.SumFromIdsHeaderTooltip' | translate"
            >
            </app-text-edit>

            <app-check-edit
              [label]="'CompanySalaryTypes.SumFromReverseSign' | translate"
              [editMode]="companyAdvancedTypesService.editMode"
              [(value)]="companyAdvancedTypesService.selectedType.SumFromReverseSign"
              [(isDirty)]="childIsDirty[18]"
              (valueChange)="onChange($event, 'SumFromReverseSign', 18)"
              [helpTooltip]="'CompanySalaryTypes.SumFromReverseSignHeaderTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.sumFromReverseSignClassSingle"
            >
            </app-check-edit>

            <app-check-edit
              [label]="'CompanySalaryTypes.IncludeAlways' | translate"
              [editMode]="companyAdvancedTypesService.editMode"
              [(value)]="companyAdvancedTypesService.selectedType.IncludeAlways"
              [(isDirty)]="childIsDirty[19]"
              (valueChange)="onChange($event, 'IncludeAlways', 19)"
              [helpTooltip]="'CompanySalaryTypes.IncludeAlwaysHeaderTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.includeAlwaysClassSingle"
            >
            </app-check-edit>

            <app-check-edit
              [label]="'CompanySalaryTypes.TriggersOvertimeSupplement' | translate"
              [editMode]="companyAdvancedTypesService.editMode"
              [(value)]="companyAdvancedTypesService.selectedType.TriggersOvertimeSupplement"
              [(isDirty)]="childIsDirty[21]"
              (valueChange)="onChange($event, 'TriggersOvertimeSupplement', 21)"
              [helpTooltip]="'CompanySalaryTypes.TriggersOvertimeSupplementTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.triggersOvertimeSupplementClassSingle"
            >
            </app-check-edit>

            <app-combobox-edit
              [label]="'CompanySalaryTypes.DimensionRedistribution' | translate"
              [editMode]="companyAdvancedTypesService.editMode"
              [comboboxDataSource]="dimensionDistributions"
              [textField]="'Name'"
              [idField]="'Id'"
              (valueChange)="onChange($event, 'DimensionDistributionOptionId', 28)"
              [(isDirty)]="childIsDirty[28]"
              [(value)]="companyAdvancedTypesService.selectedType.DimensionDistributionOptionId"
              [tooltipContent]="'CompanySalaryTypes.DimensionDistributionOptionIdHeaderTooltip' | translate"
            >
            </app-combobox-edit>

            <app-check-edit
              [label]="'CompanySalaryTypes.DisplayDates' | translate"
              [editMode]="companyAdvancedTypesService.editMode"
              [(value)]="companyAdvancedTypesService.selectedType.DisplayDates"
              [(isDirty)]="childIsDirty[29]"
              (valueChange)="onChange($event, 'DisplayDates', 29)"
              [helpTooltip]="'CompanySalaryTypes.DisplayDatesTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.displayDatesClassSingle"
            >
            </app-check-edit>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="Card">
      <div class="Card-inner">
        <fieldset class="Form--horizontal">
          <legend class="Card-title">
            <span>{{ 'CompanySalaryTypes.AdvancedDefault' | translate }}</span>
          </legend>
          <div>
            <app-numeric-edit
              [editMode]="companyAdvancedTypesService.editMode"
              [(isDirty)]="childIsDirty[6]"
              [(value)]="companyAdvancedTypesService.selectedType.AmountPerUnit"
              (valueChange)="onChange($event, 'AmountPerUnit', 6)"
              [option]="companyAdvancedTypesService.optionN2"
              [label]="'CompanySalaryTypes.AmountPerUnit' | translate"
              [helpTooltip]="'CompanySalaryTypes.AmountPerUnitHeaderTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.amountPerUnitClassSingle"
              [placeholder]="
                companyAdvancedTypesService.selectedType.amountPerUnitClassSingle &&
                companyAdvancedTypesService.selectedType.amountPerUnitClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-numeric-edit>

            <app-numeric-edit
              [editMode]="companyAdvancedTypesService.editMode"
              [(isDirty)]="childIsDirty[7]"
              [(value)]="companyAdvancedTypesService.selectedType.Units"
              (valueChange)="onChange($event, 'Units', 7)"
              [option]="companyAdvancedTypesService.optionN2"
              [label]="'CompanySalaryTypes.Units' | translate"
              [helpTooltip]="'CompanySalaryTypes.UnitsHeaderTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.unitsClassSingle"
              [placeholder]="
                companyAdvancedTypesService.selectedType.unitsClassSingle &&
                companyAdvancedTypesService.selectedType.unitsClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-numeric-edit>

            <app-numeric-edit
              [editMode]="companyAdvancedTypesService.editMode"
              [(isDirty)]="childIsDirty[10]"
              [(value)]="companyAdvancedTypesService.selectedType.Amount"
              (valueChange)="onChange($event, 'Amount', 10)"
              [option]="companyAdvancedTypesService.optionN2"
              [label]="'CompanySalaryTypes.FixedAmount' | translate"
              [helpTooltip]="'CompanySalaryTypes.AmountHeaderTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.amountClassSingle"
              [placeholder]="
                companyAdvancedTypesService.selectedType.amountClassSingle &&
                companyAdvancedTypesService.selectedType.amountClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-numeric-edit>

            <app-numeric-edit
              [editMode]="companyAdvancedTypesService.editMode"
              [(isDirty)]="childIsDirty[8]"
              [(value)]="companyAdvancedTypesService.selectedType.BaseAmount"
              (valueChange)="onChange($event, 'BaseAmount', 8)"
              [option]="companyAdvancedTypesService.optionN2"
              [label]="'CompanySalaryTypes.BaseAmount' | translate"
              [helpTooltip]="'CompanySalaryTypes.BaseAmountHeaderTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.baseAmountClassSingle"
              [placeholder]="
                companyAdvancedTypesService.selectedType.baseAmountClassSingle &&
                companyAdvancedTypesService.selectedType.baseAmountClassSingle.length > 2
                  ? inheritDefaultValue
                  : ''
              "
            >
            </app-numeric-edit>

            <app-check-edit
              [label]="'CompanySalaryTypes.AllowEditBaseAmount' | translate"
              [editMode]="companyAdvancedTypesService.editMode"
              [(value)]="companyAdvancedTypesService.selectedType.AllowEditBaseAmount"
              [(isDirty)]="childIsDirty[9]"
              (valueChange)="onChange($event, 'AllowEditBaseAmount', 9)"
              [helpTooltip]="'CompanySalaryTypes.AllowEditBaseAmountHeaderTooltip' | translate"
              [ngClass]="companyAdvancedTypesService.selectedType.allowEditBaseAmountClassSingle"
            >
            </app-check-edit>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</div>
