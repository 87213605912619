import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Browser } from 'src/app/common/browser';
import { DataService } from 'src/app/services/api/data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { Constants } from '../../../common/constants';
import { FormComponentBase } from '../../../common/form-component-base';
import { Global } from '../../../common/global';
import { ITaxCardModel } from '../../../model/itax-card-model';
import {
  ICompanyUser,
  IEmploymentBalanceConfiguration,
  ISimpleKeyValuePair,
  ITaxCardType,
  ITemplateCreationRequest,
  IUserEmployment,
  IUserEmploymentTemplate,
  IUserTaxInfo
} from '../../../services/api/api-model';
import { StaticDataService } from '../../../services/api/static-data.service';
import { EmployeeService } from '../../../services/employee.service';
import { SettingService } from '../../../services/setting.service';
import { ReportDialogService } from '../../../shared-components/report-dialog/report-dialog.service';

@Component({
  selector: 'app-employee-hiring-status',
  templateUrl: './employee-hiring-status.component.html',
  styleUrls: ['./employee-hiring-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeHiringStatusComponent extends FormComponentBase implements OnInit, OnDestroy {
  public get allowEdit() {
    return this.sessionService.role.IsSalaryAdminAndHigher;
  }

  @Input()
  public get companyUser(): ICompanyUser {
    return this.companyUserValue;
  }
  public set companyUser(value: ICompanyUser) {
    if (!this.companyUserValue && !value) {
      return;
    }

    this.companyUserValue = value;
    this.childIsValid = Array(12).fill(true);
    this.childIsDirty = Array(12).fill(false);
  }

  @Input()
  public get userEmployment(): IUserEmployment {
    return this.userEmploymentValue;
  }

  public set userEmployment(value: IUserEmployment) {
    if (!this.userEmploymentValue && !value) {
      return;
    }

    if (!value || !value.Id) {
      this.hasUserEmployment = false;
    } else {
      this.hasUserEmployment = true;
    }

    if (this.userEmploymentValue !== value) {
      this.taxCardDataChanged = true;
      this.userEmploymentValue = value;
    }
  }

  public get taxCardObject(): ITaxCardModel {
    this.setTaxCardData(this.userEmployment);
    return this.taxCardData;
  }

  public get SecondaryIncomeType(): boolean {
    if (!this.userEmployment) {
      return true;
    }

    return this.userEmployment.IncomeTypeId === 5;
  }

  public get firstPensionProviderVisible(): boolean {
    if (this.userEmployment && this.userEmployment.PensionProviderId) {
      return true;
    }

    return false;
  }

  public get secondPensionProviderVisible(): boolean {
    if (this.userEmployment && this.userEmployment.Pension2ProviderId) {
      return true;
    }

    return false;
  }

  public get showStatisticsSection(): boolean {
    return Global.COMPANY && Global.COMPANY.StatisticsRecipientId && Global.COMPANY.StatisticsRecipientId !== -1;
  }

  public get isSwedenCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
  }

  public get showMultiPurposeOffDayCompensation(): boolean {
    const enableFlexBalanceSetting: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
      'Company.EnableFlexBalance'
    );
    if (enableFlexBalanceSetting) {
      return enableFlexBalanceSetting.Value === 'true' ? true : false;
    }
    return false;
  }

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public get isDanishCompany() {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
  }

  public get noCPRMessage(): boolean {
    return this.companyUser && this.companyUser.HasDummyIdentityNumber;
  }

  public get allowEditUserEmployment(): boolean {
    return (
      this.sessionService.feature.AllowEditUserEmployment(this.sessionService.currentState) &&
      !this.sessionService.role.IsReadOnly
    );
  }

  public get warningMessage(): string {
    return this.noCPRMessage ? 'EmployeeEmployment.NoSalaryCalculationWithoutCpr' : '';
  }

  public get isShowExtraVacationHours(): boolean {
    return this.isDanishCompany && this.companyUser && !!this.userEmployment.VacationTypeId;
  }

  public get isMobile(): boolean {
    const browser: Browser = new Browser();
    return browser.isMobile;
  }

  public createTemplateConfirmationDialogVisible = false;
  public newEmploymentDialogVisible = false;
  public createNewEmployeeDialogVisible = false;
  public hasUserEmployment: boolean;
  private companyUserValue: ICompanyUser;
  private userEmploymentValue: IUserEmployment;
  private taxCardData: ITaxCardModel;
  private taxCardDataChanged: boolean;
  private taxCardTypes: ITaxCardType[];
  private saveTaxInfo: IUserTaxInfo;
  public listClassExcluded = ['EmployeeInfo', 'Footer'];

  constructor(
    public employeeService: EmployeeService,
    private settingService: SettingService,
    private dataService: DataService,
    private staticDataService: StaticDataService,
    public sessionService: SessionService,
    public reportDialogService: ReportDialogService
  ) {
    super();
    this.childIsValid = Array(12).fill(true);
    this.childIsDirty = Array(12).fill(false);
  }

  public ngOnInit(): void {
    this.staticDataService.TaxCardType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ITaxCardType[]) => (this.taxCardTypes = data)
    );
    this.settingService.showModuleMessage('Employee.Employment');
  }

  private setTaxCardData(value: IUserEmployment, newtaxInfo?: IUserTaxInfo): void {
    if (this.taxCardDataChanged || newtaxInfo) {
      let isSpecialTaxBrief: boolean;
      if (value && (value.IncomeTypeId === 0 || value.IncomeTypeId === Constants.MAGIC_NUMBER)) {
        isSpecialTaxBrief = false;
      } else {
        isSpecialTaxBrief = true;
      }
      // Only create a new instance of taxcard if there is changes
      this.taxCardData = {
        taxInfo: newtaxInfo ? newtaxInfo : this.saveTaxInfo ? this.saveTaxInfo : undefined,
        taxCardRequestTime: value ? value.TaxCardRequested : undefined,
        taxCardTypes: this.taxCardTypes,
        userEmploymentId: value ? value.Id : undefined,
        specialTaxBrief: isSpecialTaxBrief
      };
      this.taxCardDataChanged = false;
    }
  }

  onDataChanged(isReload: boolean = false): void {
    if (this.userEmployment.IncomeTypeId === 5) {
      this.userEmployment.VacationTypeId = -1;
    }

    this.onChange();
    if (isReload) {
      // this.reloadUserEmployment.emit();
      this.employeeService.selectEmployee(this.companyUser.Id, true);
    }
  }

  createUserEmployment(): void {
    this.newEmploymentDialogVisible = true;
  }

  public requestTaxcardDialogVisible = false;
  public taxcardHistoryDialogVisible = false;
  public requestTaxcardWarningDialog = false;
  public triggerRefreshTaxCard = false;
  public newTemplateName: string;
  public templateNameDialogVisible = false;
  public createTemplateTemplateCompletedDialogVisible = false;
  public createNewEmploymentDialogVisible = false;
  public synchronizeEmploymentWithTemplateDialogVisble = false;
  public deleteEmployeeDialogVisible = false;

  public onReloadTaxCard(): void {
    this.triggerRefreshTaxCard = !this.triggerRefreshTaxCard;
  }

  public onDialogSynchronizeEmploymentWithTemplate(event: any): void {
    if (event && event === 'Yes') {
      if (this.userEmployment && this.userEmployment.Id) {
        this.dataService
          .Employments_UpdateEmploymentToCurrentTemplate(this.userEmployment.Id)
          .subscribe(() => this.employeeService.selectEmployee(this.companyUser.Id, true));
      }
    }
  }

  public onCreateTemplateConfirmationDialogAction(action: string): void {
    if (action === 'Proceed') {
      this.newTemplateName = '';
      this.templateNameDialogVisible = true;
    }
  }

  public onTemplateNameDialogAction(action: string): void {
    if (action === 'Create' && this.newTemplateName) {
      const request: ITemplateCreationRequest = {
        BasedOnEmploymentId: this.userEmployment ? this.userEmployment.Id : undefined,
        Name: this.newTemplateName
      };

      this.dataService
        .EmploymentTemplates_CreateTemplateFromEmployment(request)
        .subscribe((template: IUserEmploymentTemplate) => (this.createTemplateTemplateCompletedDialogVisible = true));
    }
  }

  public onRequestTaxcardWarningDialogAction(action: string): void {
    if (action === 'AlreadyRequestedTaxCardConfirmButton') {
      this.showRequestTaxcardDialog();
    }
  }

  public showRequestTaxcardDialog(): void {
    this.requestTaxcardDialogVisible = true;
  }

  public showViewTaxCardHistory(): void {
    this.taxcardHistoryDialogVisible = true;
  }

  public showRequestTaxcardConfirm(value: IUserTaxInfo): void {
    this.saveTaxInfo = value;
    this.requestTaxcardWarningDialog = true;
  }

  public EmploymentBalanceConfigurationsChange(dataChange: IEmploymentBalanceConfiguration[], changeNumber: number) {
    if (dataChange && dataChange.length > 0) {
      if (
        this.userEmployment.EmploymentBalanceConfigurations &&
        this.userEmployment.EmploymentBalanceConfigurations.length > 0
      ) {
        const model = this.userEmployment.EmploymentBalanceConfigurations.find(
          (item: IEmploymentBalanceConfiguration) => item.BalanceDefinitionId === changeNumber
        );

        if (model) {
          this.userEmployment.EmploymentBalanceConfigurations[
            this.userEmployment.EmploymentBalanceConfigurations.indexOf(model)
          ] =
            dataChange[
              dataChange.indexOf(
                dataChange.find((item: IEmploymentBalanceConfiguration) => item.BalanceDefinitionId === changeNumber)
              )
            ];
        } else {
          this.userEmployment.EmploymentBalanceConfigurations.push(dataChange[0]);
        }
      } else {
        this.userEmployment.EmploymentBalanceConfigurations.push(dataChange[0]);
      }
    }
  }

  public onCreateTaxInfo(value: IUserTaxInfo): void {
    if (value) {
      this.setTaxCardData(this.userEmployment, value);
    }
  }

  public expanded = false;
  public onExpand(event: any): void {
    this.expanded = event;
  }
}
