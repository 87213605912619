import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef, EventEmitter } from '@angular/core';
import { ControlComponentBase } from '../../common/control-component-base';
import { UniqueId } from '../../common/unique-id';
import { SessionService } from '../../services/session/session.service';
import { PensionNumericTextBoxOptions } from './pension-numeric-text-box-options';
var PensionNumericEditComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PensionNumericEditComponent, _super);
    function PensionNumericEditComponent(elementRef, sessionService, changeDetectorRef) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.sessionService = sessionService;
        _this.validationMessage = '';
        _this.option = { min: 0, step: 1, spinners: false };
        _this.disable = false;
        _this.isSpecialLabel = false;
        _this.helpTooltip = '';
        _this.helpIcon = 'Help';
        _this.isOpenLink = true;
        _this.isNotNegativeValue = false;
        _this.maxHeight20 = '';
        _this.addOnButtonEdit = false;
        _this.addOnButtonEditTooltip = '';
        _this.addOnButtonEditClass = '';
        _this.addOnButtonEditClassFormControl = '';
        _this.addOnButtonEditAction = '';
        _this.addOnButtonEditDisabled = false;
        _this.addOnButtonEditIcon = '';
        _this.addOnButtonEditLabel = '';
        _this.addOnButtonEditIconEnable = false;
        _this.keyList = { 17: false, 40: false, 38: false };
        _this.blur = new EventEmitter();
        _this.keydowns = new EventEmitter();
        _this.addOnButtonClick = new EventEmitter();
        _this.componentId = 'PensionNumericTextBoxComponent' + UniqueId();
        _this.componentIdAfter = 'PensionNumericTextBoxComponentAfter' + UniqueId();
        return _this;
    }
    PensionNumericEditComponent.prototype.valid = function () {
        if (this.required && (!this.value || this.value.length === 0)) {
            return false;
        }
        if (this.value && !this.sessionService.parseFloat(this.value)) {
            return false;
        }
        return true;
    };
    PensionNumericEditComponent.prototype.onBlur = function () {
        this.blur.emit();
    };
    PensionNumericEditComponent.prototype.onvalueChange = function (value) {
        if (value && value < 0 && this.isNotNegativeValue) {
            value = value * -1;
            this.value = value;
        }
    };
    PensionNumericEditComponent.prototype.onKeydown = function (key) {
        if (key.key === 'ArrowUp' || key.key === 'ArrowDown') {
            if (this.isGrid && this.value && this.option && this.option.step) {
                if (key.key === 'ArrowUp') {
                    this.value -= this.option.step;
                }
                else {
                    this.value += this.option.step;
                }
                if ((this.baseValues === null || this.baseValues === undefined) && this.value === 0) {
                    this.value = this.baseValues;
                }
            }
        }
        if (key.keyCode === 27) {
            // Escape
            this.value = this.original;
        }
    };
    PensionNumericEditComponent.prototype.onFocus = function () { };
    PensionNumericEditComponent.prototype.addOnButtonEditClick = function () {
        this.addOnButtonClick.emit(this.addOnButtonEditAction);
    };
    return PensionNumericEditComponent;
}(ControlComponentBase));
export { PensionNumericEditComponent };
