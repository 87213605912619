import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { environment } from 'src/environments/environment';
import { Global } from '../../common/global';
import {
  IAccount,
  IAccountInvoiceType,
  IModuleCompanyView,
  ISalaryCycle,
  IUserEmploymentView
} from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { CompanyService } from '../../services/company.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyModuleService extends CompanyService implements OnDestroy {
  constructor(
    protected dataService: DataService,
    protected staticDataService: StaticDataService,
    protected settingService: SettingService,
    protected sessionService: SessionService,
    protected transitionService: TransitionService,
    protected broadcaster: BroadcastService,
    private translate: TranslateService
  ) {
    super(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster);
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public salaryCycles: ISalaryCycle[] = [];
  public usersMonthly = 0;
  public users14Day = 0;
  public usersWeekly = 0;
  public billedMessage = '';
  public selectedPackageId = 0;
  public confirmDialogVisible = false;

  public loadSalaryCycles() {
    return this.staticDataService.SalaryCycle.pipe(
      takeUntil(this.ngUnsubscribe),
      tap((data: ISalaryCycle[]) => {
        this.salaryCycles = data;
      })
    );
  }

  private clientAccountInvoiceType: IAccountInvoiceType;

  public serviceInit() {
    this.staticDataService.accountInvoiceTypes.subscribe((data: IAccountInvoiceType[]) => {
      if (data && data.length > 0) {
        this.clientAccountInvoiceType = data.find((model: IAccountInvoiceType) => model.Key === 'Client');
      }
    });

    if (this.isFullAdmin) {
      this.staticDataService.loadStaticData().then(() => {
        this.loadSalaryCycles().subscribe(() => {
          this.staticDataService.userEmployments.subscribe((eData: IUserEmploymentView[]) => {
            this.progressEmployementData(eData);
          });
        });
      });

      this.loadModules();
    }
  }

  public get branding() {
    return environment.branding;
  }

  private modulesSubject: BehaviorSubject<IModuleCompanyView[]>;
  public get modules(): Observable<IModuleCompanyView[]> {
    if (!this.modulesSubject) {
      this.modulesSubject = new BehaviorSubject<IModuleCompanyView[]>([]);
    }
    return this.modulesSubject.asObservable();
  }

  public loadModules() {
    if (!this.modulesSubject) {
      this.modulesSubject = new BehaviorSubject<IModuleCompanyView[]>([]);
    }

    if (this.isFullAdmin) {
      this.staticDataService.moduleCompanyView
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: IModuleCompanyView[]) => {
          this.modulesSubject.next(JSON.parse(JSON.stringify(data)));
        });
    }
  }

  private employementDataSubject: BehaviorSubject<IUserEmploymentView[]>;
  public get employementData(): Observable<IUserEmploymentView[]> {
    if (!this.employementDataSubject) {
      this.employementDataSubject = new BehaviorSubject<IUserEmploymentView[]>([]);
    }
    return this.employementDataSubject.asObservable();
  }

  public progressEmployementData(employementData: IUserEmploymentView[]) {
    this.usersWeekly = 0;
    this.users14Day = 0;
    this.usersMonthly = 0;

    employementData = employementData.filter((item: IUserEmploymentView): boolean => {
      const now: Date = new Date();
      return item.IsActive && (!item.ExpireDate || new Date(item.ExpireDate) > now);
    });

    for (const employment of employementData) {
      const employmentCycle: ISalaryCycle = this.hasSalaryCycles
        ? this.salaryCycles.find((cycle: ISalaryCycle) => cycle.Id === employment.SalaryCycleId)
        : undefined;
      if (employmentCycle) {
        switch (employmentCycle.Key) {
          case 'Weekly':
            this.usersWeekly++;
            break;

          case '14Days':
          case '14Days2':
            this.users14Day++;
            break;

          default:
            // GS-2187 : Cycle not Weekly nor 14days is Monthly.
            this.usersMonthly++;
            break;
        }
      }
    }

    if (this.usersWeekly === 0 && this.users14Day === 0 && this.usersMonthly === 0) {
      this.usersMonthly = 1;
    }

    if (!this.employementDataSubject) {
      this.employementDataSubject = new BehaviorSubject<IUserEmploymentView[]>([]);
    }
    this.employementDataSubject.next(employementData);
  }

  public loadBilledMessage() {
    if (
      (this.sessionService.role.IsFullAdmin || this.sessionService.role.IsMultiCompanyAdmin) &&
      this.sessionService.feature.IsMultiCompanyMember
    ) {
      this.staticDataService.companyAccount.pipe().subscribe((data: IAccount) => {
        if (data && data.AccountType) {
          this.translate.get(['Accountant.BillViaMCMessage']).subscribe((translations: { [key: string]: string }) => {
            this.billedMessage = translations['Accountant.BillViaMCMessage'].replace(
              '{{SystemAlias}}',
              environment.branding.SystemAlias
            );
            this.billedMessage = this.billedMessage.replace(
              '{company.Account.AccountType.Name}',
              data.AccountType.Name
            );
          });
        }
      });
    }
  }

  public calculatePriceByBillingPrinciple(billingPrincipleId: number, price: number): number {
    const totalUsers: number = 0 + this.users14Day + this.usersMonthly + this.usersWeekly;
    switch (billingPrincipleId) {
      case 1: // Per payslip
        return price * (this.usersMonthly + this.users14Day * 2.17 + this.usersWeekly * 4.33);
      case 2: // Per salary batch
        //// TODO: Ask Daniel
        return 0;
      case 3: // Per month
        return price;
      case 4: // Per user per month
        return price * totalUsers;
      case 5: // Per unit
        //// TODO: Ask Daniel
        return 0;
    }

    return 0;
  }

  public onConfirmDialogAction(action: string): void {
    if (action === 'Understood') {
      const selectedModules: number[] = this.modulesSubject.value
        // .filter((m: IModuleCompanyView) => m.IsEnabled && !m.InfoOnly)
        .filter((m: IModuleCompanyView) => m.IsEnabled)
        .map((m: IModuleCompanyView) => m.ModuleId);

      this.dataService.Modules_SetEnabledModules(this.selectedPackageId, selectedModules).subscribe(
        () => {
          this.settingService.ReloadWarrning = true;
          this.staticDataService
            .loadStaticData()
            .then(() => {
              window.location.reload();
            })
            .catch(() => {
              this.settingService.ReloadWarrning = false;
            });
        },
        (err) => {
          this.loadModules();
        }
      );
    }
  }

  public onSubmit(packageId: number): void {
    this.selectedPackageId = packageId;
    this.confirmDialogVisible = true;
  }

  private get isMultiCompanyMember(): boolean {
    return this.sessionService.feature.IsMultiCompanyMember;
  }

  private get isFullAdmin(): boolean {
    return this.sessionService.role.IsFullAdmin;
  }

  public get showBilledViaMCMessage(): boolean {
    return (
      Global &&
      Global.SESSION &&
      Global.SESSION.SignOnToken.UserAccountId &&
      Global.SESSION.CurrentCompany.InvoiceType &&
      this.clientAccountInvoiceType &&
      this.isMultiCompanyMember &&
      Global.SESSION.CurrentCompany.InvoiceType.Id !== this.clientAccountInvoiceType.Id
    );
  }

  public get hasSalaryCycles(): boolean {
    return !!this.salaryCycles && !!this.salaryCycles.length;
  }

  protected get allowShortcut(): boolean {
    return this.sessionService.currentState === 'tabs.company.modules';
  }
}
