import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { FixedPanelService } from 'src/app/services/fixed-panel.service';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { RouterStateService } from '../../common/router-state.service';
import { SessionService } from '../../services/session/session.service';
var TabPanelComponent = /** @class */ (function () {
    function TabPanelComponent(session, state, staticData, fixedPanelService) {
        this.session = session;
        this.state = state;
        this.staticData = staticData;
        this.fixedPanelService = fixedPanelService;
        this.payslipTabVisible = false;
        this.timeTabVisible = false;
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(TabPanelComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabPanelComponent.prototype, "IsDanishCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabPanelComponent.prototype, "IsMobile", {
        get: function () {
            return this.session.browser.isMobile || this.session.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    TabPanelComponent.prototype.ngOnInit = function () {
        this.setResponsiveStyle(this.session.currentState);
    };
    TabPanelComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.unsubscribe();
    };
    TabPanelComponent.prototype.clickAreamenuToggledChange = function () {
        this.session.menuToggled = true;
        this.session.showMoreMenuToggled = false;
        this.session.showHelpMenuToggled = false;
    };
    TabPanelComponent.prototype.toggleMenuButton = function () {
        var _this = this;
        if (!this.session.menuToggled) {
            this.session.menuToggled = true;
        }
        else {
            this.session.menuToggled = false;
        }
        setTimeout(function () {
            _this.fixedPanelService.calculateHeightContent();
        });
        window.scrollTo(0, 0);
    };
    TabPanelComponent.prototype.navigateToState = function ($event, state) {
        // $event.stopPropagation();
        // $event.preventDefault();
        if (!this.session.isSubmitting) {
            this.setResponsiveStyle(state);
            this.session.NavigateTo(state);
        }
    };
    // beacause behavior handle in service so we don't need waitting for getting
    TabPanelComponent.prototype.navigateToStateNonWaitting = function ($event, state) {
        this.setResponsiveStyle(state);
        this.session.NavigateTo(state);
    };
    TabPanelComponent.prototype.setResponsiveStyle = function (state) {
        switch (state) {
            case 'tabs.employee.hiringstatus':
                this.session.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
                break;
            case 'tabs.employee.payrolldata':
                this.session.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
                break;
            default:
                if (this.session.currentState !== 'tabs.employee') {
                    this.session.currentStyleMenuTabpanelReponsive = '';
                }
                break;
        }
        if (this.parentTab.toLowerCase() === 'company') {
            this.session.currentStyleMenuTabpanelReponsive = 'companytabPanelLongTitle';
        }
    };
    return TabPanelComponent;
}());
export { TabPanelComponent };
