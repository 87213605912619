<div class="FormElement">
  <div class="FormElement-control">
    <app-grid
      [data]="listPayrollIssues"
      class="FormElement-grid Company-gridOthersErrorDetails u-gridHeight250"
      [editMode]="false"
    >
      <app-grid-column
        [title]="'CompanySalaryBatches.OtherStatusGrid_IsError' | translate"
        [type]="'icon'"
        [iconField]="'icon'"
        [tooltipField]="'tooltip'"
        [width]="30"
      >
      </app-grid-column>

      <app-grid-column
        [title]="'CompanySalaryBatches.OtherStatusGrid_IsErrorDescription' | translate"
        [type]="'text'"
        [field]="'Description'"
        [width]="200"
      >
      </app-grid-column>
    </app-grid>
  </div>
</div>
