import { ElementRef, EventEmitter } from '@angular/core';
import * as EXIF from 'exif-js';
import { StaticDataService } from '../../services/api/static-data.service';
var FileUploadComponent = /** @class */ (function () {
    function FileUploadComponent(staticDataService) {
        this.staticDataService = staticDataService;
        this.isOnChange = false;
        this.shortFileNameChange = new EventEmitter();
        this.fileSelected = new EventEmitter();
    }
    Object.defineProperty(FileUploadComponent.prototype, "shortFileName", {
        get: function () {
            return this.shortFileNameValue;
        },
        set: function (value) {
            if (this.shortFileNameValue !== value) {
                this.shortFileNameValue = value;
                this.shortFileNameChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    FileUploadComponent.prototype.addFile = function () {
        var _this = this;
        if (!this.isOnChange) {
            setTimeout(function () {
                _this.file.nativeElement.value = '';
                _this.file.nativeElement.click();
                // reset isOnchange after 2 seconds, prevent user action cancel event and this function never run.
                setTimeout(function () {
                    _this.isOnChange = false;
                }, 2000);
            });
            this.isOnChange = true;
        }
    };
    FileUploadComponent.prototype.onFileAdded = function () {
        var files = this.file.nativeElement.files;
        if (files && files.length > 0) {
            var file = files[0];
            var fileName = file.name;
            this.shortFileName = fileName.substr(fileName.lastIndexOf('\\') + 1);
            if (file.type.match('image/*')) {
                this.previewImage(file);
            }
            else {
                this.fileSelected.emit(file);
            }
        }
        this.isOnChange = false;
    };
    FileUploadComponent.prototype.resetOrientation = function (srcBase64, srcOrientation, file) {
        var _this = this;
        if (srcBase64 === undefined && srcOrientation === -1) {
            this.fileSelected.emit(file);
            return;
        }
        var img = new Image();
        img.onload = function () {
            var width = img.width / 4;
            var height = img.height / 4;
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            // set proper canvas dimensions before transform & export
            if (srcOrientation > 4 && srcOrientation < 9) {
                canvas.width = height;
                canvas.height = width;
            }
            else {
                canvas.width = width;
                canvas.height = height;
            }
            // transform context before drawing image
            switch (srcOrientation) {
                case 2:
                    ctx.transform(-1, 0, 0, 1, width, 0);
                    break;
                case 3:
                    ctx.transform(-1, 0, 0, -1, width, height);
                    break;
                case 4:
                    ctx.transform(1, 0, 0, -1, 0, height);
                    break;
                case 5:
                    ctx.transform(0, 1, 1, 0, 0, 0);
                    break;
                case 6:
                    ctx.transform(0, 1, -1, 0, height, 0);
                    break;
                case 7:
                    ctx.transform(0, -1, -1, 0, height, width);
                    break;
                case 8:
                    ctx.transform(0, -1, 1, 0, 0, width);
                    break;
                default:
                    break;
            }
            // draw image
            ctx.drawImage(img, 0, 0, width, height);
            // export base64
            var base64 = canvas.toDataURL();
            var dataBase64 = base64.split(',');
            base64 = dataBase64[1];
            var type = file.type;
            var blob = _this.staticDataService.dataURItoBlob(base64, type);
            var fileNew = new File([blob], file.name, { type: type });
            _this.fileSelected.emit(fileNew);
        };
        img.src = srcBase64;
    };
    FileUploadComponent.prototype.previewImage = function (file) {
        var _this = this;
        var img = [];
        img[0] = document.createElement('img');
        var reader = new FileReader();
        reader.onload = function (e) {
            img[0].setAttribute('src', reader.result);
            // important for exif-js! Set src attribute after calling img.prop
            img.src = reader.result;
            EXIF.getData(img, function () {
                var exif = EXIF.getAllTags(img);
                if (Object.keys(exif).length > 0) {
                    _this.resetOrientation(reader.result, exif.Orientation, file);
                }
                else {
                    _this.resetOrientation(undefined, -1, file);
                }
            });
        };
        reader.readAsDataURL(file);
    };
    return FileUploadComponent;
}());
export { FileUploadComponent };
