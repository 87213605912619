import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FormComponentBase } from '../../../common/form-component-base';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';
import { ReportDialogService } from '../../../shared-components/report-dialog/report-dialog.service';
import { IntegrationsService } from './integrations.service';

@Component({
  selector: 'app-integration-tab',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IntegrationsComponent extends FormComponentBase implements OnInit, OnDestroy {
  public isShowingAlertMessage = false;
  public isShowingDeleteMessage = false;
  public OperationRunSucess = false;
  public dialogVisible = false;

  public get isShowExternalIntegrationsConfiguration(): boolean {
    if (
      (this.integrationsService.configurationControl && this.integrationsService.configurationControl.length > 0) ||
      (this.integrationsService.integrationConfigurationControl &&
        this.integrationsService.integrationConfigurationControl.length > 0)
    ) {
      return true;
    }
    return false;
  }

  public get branding() {
    return environment.branding;
  }

  public get isShowExternalIntegrationsCredential(): boolean {
    if (this.integrationsService.credentialControl && this.integrationsService.credentialControl.length > 0) {
      return true;
    }
    return false;
  }

  constructor(
    public sessionService: SessionService,
    public settingService: SettingService,
    public reportDialogService: ReportDialogService,
    public integrationsService: IntegrationsService
  ) {
    super();

    this.integrationsService.editModeChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      if (!value && this.integrationsService.tabDirty) {
        this.integrationsService.saveValueControlParam();
      }
    });

    this.integrationsService.onContinuteState.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      if (value) {
        this.integrationsService.editMode = false;
      }
    });

    this.integrationsService.editShortcutChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      if (value) {
        this.onDoubleClick();
      }
    });

    this.integrationsService.addNewShortcutChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      if (value && !this.integrationsService.editMode && !this.integrationsService.isReadonlyUser) {
        this.integrationsService.addNewsDialogVisible = true;
      }
    });

    this.integrationsService.discardChangeShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && this.integrationsService.editMode) {
          this.applyDiscard();
        }
      });

    this.integrationsService.saveChangeShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && this.integrationsService.editMode) {
          this.onEditClick(true);
        }
      });
  }

  public ngOnInit(): void {
    this.integrationsService.loadIntegrationModel();
    this.serviceInIt();
    this.settingService.showModuleMessage('company.integrations');
  }

  private serviceInIt() {
    this.integrationsService.integrations.pipe(takeUntil(this.ngUnsubscribe)).subscribe();
    this.integrationsService.integration.pipe(takeUntil(this.ngUnsubscribe)).subscribe();
  }

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.integrationsService.ngOnDestroy();

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onDoubleClick(): void {
    if (
      this.integrationsService.hasIntegrationModule &&
      !this.sessionService.isGetting &&
      !this.sessionService.isSubmitting
    ) {
      if (!this.integrationsService.isReadonlyUser && !this.integrationsService.isEmptyIntegrations) {
        this.integrationsService.editMode = true;
      }
    }
  }

  public applyDiscard(): void {
    if (this.integrationsService.tabDirty) {
      this.isShowingAlertMessage = true;
    } else {
      this.integrationsService.editMode = false;
    }
  }

  public onUnSaveWarningDialogAction(action: string): void {
    if (action === 'ConfirmDiscard') {
      this.integrationsService.selectedId = undefined;
      this.integrationsService.loadIntegrationModel();

      if (this.integrationsService.nextIntegrationIdChange) {
        this.integrationsService.selectedId = this.integrationsService.nextIntegrationIdChange;
        this.integrationsService.nextIntegrationIdChange = undefined;
      }
    }
  }

  public onEditClick($event: boolean): void {
    if (this.integrationsService.editMode !== $event) {
      this.integrationsService.editMode = $event;
    }
  }
}
