/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../custom-controls/dialogs/dialog.component.ngfactory";
import * as i2 from "../../custom-controls/dialogs/dialog.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../services/session/session.service";
import * as i5 from "../../custom-controls/combobox-edit/combobox-edit.component.ngfactory";
import * as i6 from "../../custom-controls/combobox-edit/combobox-edit.component";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../custom-controls/dialogs/dialog-action.component.ngfactory";
import * as i9 from "../../custom-controls/dialogs/dialog-action.component";
import * as i10 from "./request-tax-card-dialog.component";
import * as i11 from "../../services/api/data.service";
var styles_RequestTaxCardDialogComponent = [];
var RenderType_RequestTaxCardDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RequestTaxCardDialogComponent, data: {} });
export { RenderType_RequestTaxCardDialogComponent as RenderType_RequestTaxCardDialogComponent };
export function View_RequestTaxCardDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "app-dialog", [], null, [[null, "visibleChange"], [null, "action"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.isVisible = $event) !== false);
        ad = (pd_0 && ad);
    } if (("action" === en)) {
        var pd_1 = (_co.onTaxCardAction($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_DialogComponent_0, i1.RenderType_DialogComponent)), i0.ɵdid(1, 49152, null, 1, i2.DialogComponent, [i3.DomSanitizer, i4.SessionService, i0.ElementRef], { minWidth: [0, "minWidth"], overlayClose: [1, "overlayClose"], visible: [2, "visible"] }, { visibleChange: "visibleChange", action: "action" }), i0.ɵqud(603979776, 1, { buttonElements: 1 }), (_l()(), i0.ɵeld(3, 0, null, 0, 2, "app-combobox-edit", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.selectedTaxcardTypeId = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ComboboxEditComponent_0, i5.RenderType_ComboboxEditComponent)), i0.ɵdid(4, 4440064, null, 0, i6.ComboboxEditComponent, [i4.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], idField: [2, "idField"], textField: [3, "textField"], label: [4, "label"], comboboxDataSource: [5, "comboboxDataSource"] }, { valueChange: "valueChange" }), i0.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(6, 0, null, 0, 1, "app-dialog-action", [["type", "Cancel"]], null, null, null, i8.View_DialogActionComponent_0, i8.RenderType_DialogActionComponent)), i0.ɵdid(7, 180224, [[1, 4]], 0, i9.DialogActionComponent, [i4.SessionService], { type: [0, "type"] }, null), (_l()(), i0.ɵeld(8, 0, null, 0, 1, "app-dialog-action", [["type", "Ok"]], null, null, null, i8.View_DialogActionComponent_0, i8.RenderType_DialogActionComponent)), i0.ɵdid(9, 180224, [[1, 4]], 0, i9.DialogActionComponent, [i4.SessionService], { type: [0, "type"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 4, "app-dialog", [["class", "blue-dialog"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.isShowAlert = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DialogComponent_0, i1.RenderType_DialogComponent)), i0.ɵdid(11, 49152, null, 1, i2.DialogComponent, [i3.DomSanitizer, i4.SessionService, i0.ElementRef], { leadingText: [0, "leadingText"], visible: [1, "visible"] }, { visibleChange: "visibleChange" }), i0.ɵqud(603979776, 2, { buttonElements: 1 }), (_l()(), i0.ɵeld(13, 0, null, 0, 1, "app-dialog-action", [["type", "Ok"]], null, null, null, i8.View_DialogActionComponent_0, i8.RenderType_DialogActionComponent)), i0.ɵdid(14, 180224, [[2, 4]], 0, i9.DialogActionComponent, [i4.SessionService], { type: [0, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = 300; var currVal_1 = false; var currVal_2 = _co.isVisible; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.selectedTaxcardTypeId; var currVal_4 = true; var currVal_5 = "Id"; var currVal_6 = "Name"; var currVal_7 = i0.ɵunv(_v, 4, 4, i0.ɵnov(_v, 5).transform("EmployeeGeneral.TaxCardType")); var currVal_8 = _co.taxcardTypes; _ck(_v, 4, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = "Cancel"; _ck(_v, 7, 0, currVal_9); var currVal_10 = "Ok"; _ck(_v, 9, 0, currVal_10); var currVal_11 = _co.alertMessage; var currVal_12 = _co.isShowAlert; _ck(_v, 11, 0, currVal_11, currVal_12); var currVal_13 = "Ok"; _ck(_v, 14, 0, currVal_13); }, null); }
export function View_RequestTaxCardDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-request-tax-card-dialog", [], null, null, null, View_RequestTaxCardDialogComponent_0, RenderType_RequestTaxCardDialogComponent)), i0.ɵdid(1, 49152, null, 0, i10.RequestTaxCardDialogComponent, [i11.DataService, i4.SessionService], null, null)], null, null); }
var RequestTaxCardDialogComponentNgFactory = i0.ɵccf("app-request-tax-card-dialog", i10.RequestTaxCardDialogComponent, View_RequestTaxCardDialogComponent_Host_0, { isVisible: "isVisible", userEmploymentId: "userEmploymentId", taxcardTypes: "taxcardTypes" }, { change: "change", isVisibleChange: "isVisibleChange" }, []);
export { RequestTaxCardDialogComponentNgFactory as RequestTaxCardDialogComponentNgFactory };
