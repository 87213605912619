<div *ngIf="setting">
  <app-dialog
    [(visible)]="showDialog"
    (action)="onDialogAction($event)"
    [width]="630"
    [leadingText]="'EmploymentPayrollData.More_Info_EnableSetting' | translate"
  >
    <app-dialog-action type="Ok"></app-dialog-action>
    <app-dialog-action type="UsePositiveAmountThisTime"></app-dialog-action>
    <app-dialog-action type="DisableThisBehaviour"></app-dialog-action>
  </app-dialog>
</div>
<div *ngIf="!setting">
  <app-dialog
    [(visible)]="showDialog"
    (action)="onDialogAction($event)"
    [width]="630"
    [leadingText]="'EmploymentPayrollData.More_Info_DisableSetting' | translate: { SystemAlias: branding.SystemAlias }"
  >
    <app-dialog-action type="IKnowWhatIamDoing"></app-dialog-action>
    <app-dialog-action type="EnableAutoCorrect"></app-dialog-action>
  </app-dialog>
</div>
