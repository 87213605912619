<app-module-check [moduleId]="4">
  <div class="dimension-active" *ngIf="!isEnableDimensionPreference">
    <div class="dimension-content" innerHTML="{{ dimensionValueEntitiesMessage }}"></div>
    <app-button
      buttonClass="Button--destructive"
      [isDisabled]="!sessionService.role.IsFullAdmin"
      (clickEvent)="activeDimension()"
      [label]="btnLabel"
    >
    </app-button>
  </div>
  <div *ngIf="isEnableDimensionPreference">
    <div class="ConfigNav Navigation-wrapper Navigation Navigation-inner" [ngClass]="{ 'is-active': menuOpen }">
      <div class="Navigation-expandWrapper">
        <button class="Navigation-link Navigation-expand" (click)="onToggleNavigation()">
          <div>
            <div *ngIf="dimensionsService.currentDimensionNumber === 1">
              {{ dimensionsService.dimension1 }}
            </div>
            <div *ngIf="dimensionsService.currentDimensionNumber === 2">
              {{ dimensionsService.dimension2 }}
            </div>
            <div *ngIf="dimensionsService.currentDimensionNumber === 3">
              {{ dimensionsService.dimension3 }}
            </div>
            <app-icon [icon]="'ChevronDown'" [size]="'xxsmall'"></app-icon>
          </div>
        </button>
      </div>
      <ul class="Navigation-list Navigation-list--dimension">
        <li class="Navigation-item">
          <app-button
            id="CompanyConfigurationDimension1Button"
            class="Navigation-link MainTabButton"
            (clickEvent)="changeTab(1)"
            [ngClass]="{ 'is-active': dimensionsService.currentDimensionNumber === 1 }"
            [label]="dimensionsService.dimension1"
          >
          </app-button>
        </li>
        <li class="Navigation-item">
          <app-button
            id="CompanyConfigurationDimension2Button"
            class="Navigation-link MainTabButton"
            (clickEvent)="changeTab(2)"
            [ngClass]="{ 'is-active': dimensionsService.currentDimensionNumber === 2 }"
            [label]="dimensionsService.dimension2"
          >
          </app-button>
        </li>
        <li class="Navigation-item">
          <app-button
            id="CompanyConfigurationDimension3Button"
            class="Navigation-link MainTabButton"
            (clickEvent)="changeTab(3)"
            [ngClass]="{ 'is-active': dimensionsService.currentDimensionNumber === 3 }"
            [label]="dimensionsService.dimension3"
          >
          </app-button>
        </li>
      </ul>
    </div>
    <div class="dimension-container">
      <app-dimension-detail [(selectedId)]="dimensionsService.currentDimensionId"></app-dimension-detail>
    </div>
  </div>
</app-module-check>
