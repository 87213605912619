import * as tslib_1 from "tslib";
import { IAccount, ICompany, ICompanyAccountView, User } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { ModalService } from '../../services/modal.service';
import { SessionService } from '../../services/session/session.service';
import { AccountantServiceBase } from '../accountant-service-base';
import * as i0 from "@angular/core";
import * as i1 from "../../services/api/data.service";
import * as i2 from "../../services/modal.service";
import * as i3 from "../../services/session/session.service";
import * as i4 from "../../services/api/static-data.service";
var AccountantConfigurationService = /** @class */ (function (_super) {
    tslib_1.__extends(AccountantConfigurationService, _super);
    function AccountantConfigurationService(api, modalService, sessionService, staticDataService) {
        var _this = _super.call(this, api, modalService, sessionService, staticDataService) || this;
        _this.api = api;
        _this.modalService = modalService;
        _this.sessionService = sessionService;
        _this.staticDataService = staticDataService;
        _this.account = {
            Name: '',
            Id: -1,
            StatusId: -1,
            AccountTypeId: -1,
            IsPublic: false,
            DefaultInvoiceTypeId: -1,
            MasterCompanyId: -1,
            Description: '',
            MasterCompanyEmail: null
        };
        _this.internalCompany = {
            Title: 'Accountant.SystemCompany',
            CVR: '',
            Name: '',
            Id: 1,
            CompanyId: -1,
            Address: '',
            PostalCode: '',
            City: '',
            Country: '',
            EAN: ''
        };
        _this.externalCompany = {
            Title: 'Accountant.ExternalCompany',
            CVR: '',
            Name: '',
            Id: 2,
            CompanyId: null,
            Address: '',
            PostalCode: '',
            City: '',
            Country: '',
            EAN: ''
        };
        _this.currentCompany = {};
        _this.listCompany = [_this.internalCompany, _this.externalCompany];
        _this.rawAccount = '';
        _this.rawInternalCompany = '';
        _this.rawExternalCompany = '';
        _this.rawCompany = 0;
        _this.isDirty = false;
        _this.showSaveDataConfirmationChangeState = false;
        _this.navigationToStateName = '';
        return _this;
    }
    AccountantConfigurationService.prototype.loadData = function () {
        var _this = this;
        this.staticDataService.currentAccount.subscribe(function (account) {
            if (!account.Description || account.Description === null) {
                account.Description = '';
            }
            if (account.MasterCompanyId) {
                _this.api.Companies_Get(account.MasterCompanyId).subscribe(function (company) {
                    _this.internalCompany.CVR = company.VATRegistrationNumber || null;
                    _this.internalCompany.Name = company.Name || '';
                    _this.internalCompany.Address = company.Address
                        ? (company.Address.Line1 || '') + ' ' + (company.Address.Line2 || '')
                        : '';
                    _this.internalCompany.PostalCode = company.Address ? company.Address.PostalCode : '';
                    _this.internalCompany.City = company.Address ? company.Address.City : '';
                    _this.internalCompany.Country = company.Country ? company.Country.Name : '';
                    _this.internalCompany.EAN = company.InvoiceIdentifier || null;
                    _this.internalCompany.CompanyId = company.Id;
                    _this.currentCompany = _this.internalCompany;
                    _this.rawCompany = 1;
                    _this.rawInternalCompany = JSON.stringify(_this.internalCompany);
                });
            }
            else {
                if (!_this.externalCompany) {
                    return;
                }
                _this.externalCompany.CVR = account.MasterCompanyVATRegistrationNumber;
                _this.externalCompany.Name = account.MasterCompanyName;
                _this.externalCompany.Address = account.MasterCompanyAddress;
                _this.externalCompany.PostalCode = account.MasterCompanyPostalCode;
                _this.externalCompany.City = account.MasterCompanyCity;
                _this.externalCompany.Country = account.MasterCompanyCountry;
                _this.externalCompany.EAN = account.MasterCompanyEAN;
                _this.externalCompany.CompanyId = null;
                _this.currentCompany = _this.externalCompany;
                _this.rawCompany = 2;
            }
            _this.api.Account_GetAccountCompanies().subscribe(function (data) {
                _this.listCompanies = [];
                data.forEach(function (company) {
                    if (company.AccountId === account.Id) {
                        _this.listCompanies.push(company);
                    }
                });
                if (!_this.internalCompany.Name && _this.listCompanies.length > 0) {
                    var companyId = _this.listCompanies[0].Id;
                    _this.api.Companies_Get(companyId).subscribe(function (company) {
                        _this.internalCompany.CVR = company.VATRegistrationNumber || null;
                        _this.internalCompany.Name = company.Name || '';
                        _this.internalCompany.Address = company.Address
                            ? (company.Address.Line1 || '') + ' ' + (company.Address.Line2 || '')
                            : '';
                        _this.internalCompany.PostalCode = company.Address ? company.Address.PostalCode : '';
                        _this.internalCompany.City = company.Address ? company.Address.City : '';
                        _this.internalCompany.Country = company.Country ? company.Country.Name : '';
                        _this.internalCompany.EAN = company.InvoiceIdentifier || null;
                        _this.internalCompany.CompanyId = company.Id;
                        _this.rawInternalCompany = JSON.stringify(_this.internalCompany);
                    });
                }
            });
            _this.account = account;
            _this.rawAccount = JSON.stringify(account);
            _this.rawInternalCompany = JSON.stringify(_this.internalCompany);
            _this.rawExternalCompany = JSON.stringify(_this.externalCompany);
        });
    };
    AccountantConfigurationService.prototype.getRoles = function () {
        this.getRoleServicePublic();
    };
    AccountantConfigurationService.prototype.getRoleServicePublic = function () { };
    AccountantConfigurationService.prototype.applyFilter = function () { };
    AccountantConfigurationService.prototype.updateData = function (model) { };
    AccountantConfigurationService.prototype.reloadData = function () {
        this.loadData();
    };
    AccountantConfigurationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountantConfigurationService_Factory() { return new AccountantConfigurationService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.ModalService), i0.ɵɵinject(i3.SessionService), i0.ɵɵinject(i4.StaticDataService)); }, token: AccountantConfigurationService, providedIn: "root" });
    return AccountantConfigurationService;
}(AccountantServiceBase));
export { AccountantConfigurationService };
