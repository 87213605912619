<app-loading-tips [tiptext]="loadingTipText" [visible]="isLoading"></app-loading-tips>

<div id="divMain" [ngClass]="{ WithDemoBanner: isDemo, iosAppMainDiv: isIOSMobileApp }">
  <div [ngClass]="{ 'menu-off': sessionService.menuToggled }">
    <!-- Header -->
    <div id="tabs-header" class="Header">
      <div class="Logo {{ logoClass }}">
        <a *ngIf="!isIOSMobileApp" href="https://intect.dk" target="_blank">
          <img class="{{ logoClass }}" src="{{ logoAlias }}" alt="{{ branding.SystemAlias }}" />
        </a>

        <img class="{{ logoClass }}" *ngIf="isIOSMobileApp" src="{{ logoAlias }}" alt="{{ branding.SystemAlias }}" />
      </div>

      <!-- Navigation -->
      <div [ngClass]="{ 'is-active': navigationExpanded }" class="Navigation-wrapper" id="js-navigation">
        <div class="Navigation">
          <div class="Navigation-inner">
            <div class="Navigation-expandWrapper">
              <button
                class="Navigation-link Navigation-expand MainTabButton"
                type="button"
                (click)="onToggleNavigation()"
              >
                <div>
                  <div *ngIf="sessionService.currentState.includes('tabs.employee')">
                    {{ 'Tabs.Employee' | translate }}
                  </div>
                  <div *ngIf="sessionService.currentState.includes('tabs.company')">
                    {{ 'Tabs.Company' | translate }}
                  </div>
                  <div *ngIf="sessionService.currentState.includes('tabs.selfservice')">
                    {{ 'Tabs.SelfService' | translate }}
                  </div>
                  <div *ngIf="sessionService.currentState.includes('tabs.accountant')">
                    {{ staticDataService.accountantTabName | async }}
                  </div>
                  <app-icon *ngIf="isShowDropDown" [icon]="'ChevronDown'" [size]="'xxsmall'"></app-icon>
                </div>
              </button>
            </div>
            <ul class="Navigation-list">
              <li class="Navigation-item" *ngIf="sessionService.feature.EmployeeTabEnabled">
                <button
                  id="EmployeeTabButton"
                  class="Navigation-link MainTabButton"
                  type="button"
                  (click)="navigateToState('tabs.employee')"
                  [ngClass]="{ 'is-active': sessionService.currentState.includes('tabs.employee') }"
                >
                  {{ 'Tabs.Employee' | translate }}
                </button>
              </li>

              <li class="Navigation-item" *ngIf="sessionService.feature.CompanyTabEnabled">
                <button
                  id="CompanyTabButton"
                  class="Navigation-link MainTabButton"
                  type="button"
                  (click)="navigateToState('tabs.company')"
                  [ngClass]="{ 'is-active': sessionService.currentState.includes('tabs.company') }"
                >
                  {{ 'Tabs.Company' | translate }}
                </button>
              </li>

              <li class="Navigation-item">
                <button
                  *ngIf="!isHideSelfService"
                  id="SelfServiceTabButton"
                  class="Navigation-link MainTabButton"
                  type="button"
                  (click)="navigateToState('tabs.selfservice')"
                  [ngClass]="{ 'is-active': sessionService.currentState.includes('tabs.selfservice') }"
                >
                  {{ 'Tabs.SelfService' | translate }}
                </button>
              </li>

              <li class="Navigation-item" *ngIf="sessionService.feature.AccountantTabEnabled">
                <button
                  id="AccountantTabButton"
                  class="Navigation-link MainTabButton"
                  type="button"
                  (click)="navigateToState('tabs.accountant')"
                  [ngClass]="{ 'is-active': sessionService.currentState.includes('tabs.accountant') }"
                >
                  {{ staticDataService.accountantTabName | async }}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Navigation -->
      <!-- Settings -->
      <div class="Settings">
        <!-- Settings-company -->
        <div class="Settings-companyContainer">
          <app-company-logo
            [isSingleListcompanis]="isSingleListcompanis"
            [currentCompanyName]="currentCompanyName"
            [Companytooltip]="Companytooltip"
            [currentCompanyAbbreviation]="currentCompanyAbbreviation"
            [showCompanyAbbreviation]="showCompanyAbbreviation"
            [logo]="logo"
            (toggleCompanyDropdown)="onToggleCompanyDropdown()"
          >
          </app-company-logo>

          <app-company-selector
            [(visible)]="settingService.showCompanySelector"
            [(selectedCompanyId)]="selectedCompanyId"
            [companies]="companies"
            (companyChange)="onCompanyChange($event)"
          >
          </app-company-selector>
        </div>
        <!-- /Settings-company -->

        <app-user-menu [isDisable]="isLoading"></app-user-menu>
      </div>
      <!-- /Settings -->
    </div>
    <!-- /Header -->

    <div
      id="TabViewStateFlipScreenHint"
      class="FlipScreen-Hint AlertBox AlertBox--wrap"
      innerHTML="{{ 'Tabs.FlipScreenHint' | translate }}"
    ></div>

    <div class="wrapper">
      <app-account
        *ngIf="
          sessionService.currentState.includes('tabs.account') &&
          !sessionService.currentState.includes('tabs.accountant')
        "
      ></app-account>

      <app-employee *ngIf="sessionService.currentState.includes('tabs.employee')"></app-employee>

      <app-company *ngIf="sessionService.currentState.includes('tabs.company')"></app-company>

      <app-accountant *ngIf="sessionService.currentState.includes('tabs.accountant')"></app-accountant>

      <app-selfservice *ngIf="sessionService.currentState.includes('tabs.selfservice')"></app-selfservice>
    </div>
  </div>
</div>

<app-accept-dpa-dialog [(isVisible)]="showAcceptDPADialog" (accepted)="onDPATermAccepted($event)">
</app-accept-dpa-dialog>

<app-get-started [hide]="sessionService.getStartedHidden | async"></app-get-started>

<div id="divHintForEdit" *ngIf="doubleClickToEditHintVisible">
  <div class="inner-div">
    <div *ngIf="!isMobile">
      {{ 'Tips.DoubleClickToEdit' | translate }}
    </div>

    <div *ngIf="isMobile">
      {{ 'Tips.DoubleTapToEdit' | translate }}
    </div>
  </div>
</div>

<app-dialog
  [title]="settingService.moduleMessageDialogTitle"
  (action)="settingService.onModuleMessageDialogAction($event)"
  [(visible)]="settingService.IsShowUpdateNotification"
>
  <div [innerHTML]="'<p>' + settingService.moduleMessage + '</p>'"></div>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'ReadMore'"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="settingService.onNavigationMessageDialogAction($event)"
  [(visible)]="settingService.navigationMessageDialogVisible"
>
  <div [innerHTML]="settingService.navigationMessage"></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
  <app-dialog-action [type]="'ReadMore'"></app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" [(visible)]="sessionService.isShowHugeFeaturesAlert">
  <div id="HugeFeaturesAlert" [innerHTML]="'GlobalDialog.HugeFeaturesAlert' | translate"></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" class="blue-dialog" [(visible)]="isDetectedAdBlock">
  <div [innerHTML]="'GlobalDialog.AddblockDeteced' | translate: { SystemAlias: branding.SystemAlias }"></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" [(visible)]="isDetectedCookieDisable">
  <div
    [innerHTML]="
      'GlobalDialog.CookieDisableDetected'
        | translate: { SystemAlias: branding.SystemAlias, DomainAlias: sessionService.DomainAlias }
    "
  ></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" [(visible)]="isDetecGoogleTranslate" (action)="onDisableDetect($event)">
  <div [innerHTML]="'GlobalDialog.DetecedGoogleTranslate' | translate: { SystemAlias: branding.SystemAlias }"></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-relogin-dialog></app-relogin-dialog>

<app-dialog
  class="blue-dialog"
  [title]="modalService.title"
  [isHideWhenSessionExpired]="false"
  [(visible)]="modalService.alertVisible"
  [minWidth]="380"
  (action)="modalService.onClose($event)"
>
  <div>
    <p [innerHTML]="modalService.message"></p>
  </div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  class="refresh-dialog darkDialog blue-dialog"
  [(visible)]="settingService.ReloadWarrning"
  [leadingText]="'CompanySelector.ChangeCompany' | translate: { SystemAlias: branding.SystemAlias }"
  [overlayClose]="false"
  [disableCloseButtonTitle]="true"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-change-password-dialog [(isVisible)]="settingService.showPasswordChangeDialog"></app-change-password-dialog>
