import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { RouterStateService } from 'src/app/common/router-state.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { AccountantJoinService } from './accountant-join.service';

@Component({
  selector: 'app-accountant-join',
  templateUrl: './accountant-join.component.html',
  styleUrls: ['./accountant-join.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountantJoinComponent {
  public joinDialogVisible = false;

  private componentVisible = false;
  @Input()
  public get visible(): boolean {
    return this.componentVisible;
  }
  public set visible(value: boolean) {
    if (this.componentVisible !== value) {
      this.componentVisible = value;
      if (!value) {
        this.service.filter = '';
      }
      this.visibleChange.emit(value);
    }
  }

  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get isNonSelect(): boolean {
    if (this.service.selectedAccount && this.service.selectedAccount.Id) {
      return false;
    }
    return true;
  }

  constructor(
    public service: AccountantJoinService,
    public staticDataService: StaticDataService,
    private sessionService: SessionService,
    private stateService: RouterStateService
  ) {
    if (this.sessionService.feature.IsMultiCompanyMember) {
      this.stateService.registerRedirect('tabs.accountant.join', 'tabs.accountant.summary');
    }
  }

  public onSelectedItem(event: any): void {
    this.service.selectedAccount = event;
  }

  public dialogAction(action: string): void {
    if (action === 'Attach') {
      this.joinDialogVisible = true;
    }
  }
}
