import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

export class FilterDateTimeGrid {
  constructor() {}

  public convertFilterOperator(
    filtersOperator: CompositeFilterDescriptor,
    filtersDate: any,
    startString: string,
    endString: string,
    payoutString?: string
  ) {
    if (filtersOperator && filtersOperator.filters && filtersOperator.filters.length > 0) {
      filtersOperator.filters = filtersOperator.filters.filter((value: any) => {
        if (
          value.filters[0].field === startString ||
          value.filters[0].field === endString ||
          value.filters[0].field === payoutString
        ) {
          filtersDate.push(value);
          return false;
        }
        return true;
      });
    }
    return filtersOperator;
  }

  public doFilterDate(timeRecords: any[], filters: [], startString: string, endString: string, payoutString?: string) {
    if (filters && filters.length > 0) {
      filters.forEach((filterdate: any) => {
        const operator: string = filterdate.filters[0].operator;
        const field: string = filterdate.filters[0].field;
        const compareValue: number = filterdate.filters[0].value ? filterdate.filters[0].value.getTime() : undefined;

        timeRecords = timeRecords.filter((record: any) => {
          if (field === startString) {
            if (startString) {
              const value: number = record[startString] ? new Date(record[startString]).getTime() : undefined;
              return this.compareWithOperator(compareValue, value, operator);
            }
          } else if (field === endString) {
            if (endString) {
              const value: number = record[endString] ? new Date(record[endString]).getTime() : undefined;
              return this.compareWithOperator(compareValue, value, operator);
            }
          } else if (field === payoutString) {
            if (payoutString) {
              const value: number = record[payoutString] ? new Date(record[payoutString]).getTime() : undefined;
              return this.compareWithOperator(compareValue, value, operator);
            }
          }
          return true;
        });
      });
    }
    return timeRecords;
  }

  private compareWithOperator(compareValue: number, value: number, operator: string) {
    switch (operator) {
      case 'eq':
        if (value === compareValue) {
          return true;
        }
        return false;
        break;
      case 'neq':
        if (value !== compareValue) {
          return true;
        }
        return false;
        break;
      case 'gt':
        if (value > compareValue) {
          return true;
        }
        return false;
        break;
      case 'gte':
        if (value >= compareValue) {
          return true;
        }
        return false;
        break;
      case 'lt':
        if (value < compareValue) {
          return true;
        }
        return false;
        break;
      case 'lte':
        if (value <= compareValue) {
          return true;
        }
        return false;
        break;
      case 'isnull':
        if (value === undefined || value === null) {
          return true;
        }
        return false;
        break;
      case 'isnotnull':
        if (value !== undefined || value !== null) {
          return true;
        }
        return false;
        break;
      default:
        return true;
        break;
    }
  }
}
