import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ControlComponentBase } from '../../common/control-component-base';
import { UniqueId } from '../../common/unique-id';
import { SessionService } from '../../services/session/session.service';
import { PensionNumericTextBoxOptions } from './pension-numeric-text-box-options';

@Component({
  selector: 'app-pension-numeric-edit',
  templateUrl: './pension-numeric-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PensionNumericEditComponent extends ControlComponentBase<string> {
  @Input() public label: string;
  @Input() public labelAfter: string;
  @Input() public validationMessage = '';
  @Input() public option: PensionNumericTextBoxOptions = { min: 0, step: 1, spinners: false };
  @Input() public required: boolean;
  @Input() public disable = false;
  @Input() public customModelOptions: any;
  @Input() public centerText: boolean;
  @Input() public rightText: boolean;
  @Input() public isSpecialLabel = false;
  @Input() public customClass: string;
  @Input() public helpLink: string;
  @Input() public helpTooltip = '';
  @Input() public helpIcon = 'Help';
  @Input() public isOpenLink = true;
  @Input() public isNotNegativeValue = false;
  @Input() public maxHeight20 = '';
  @Input() public viewClass: string;

  @Input() public addOnButtonEdit = false;
  @Input() public addOnButtonEditTooltip = '';
  @Input() public addOnButtonEditClass = '';
  @Input() public addOnButtonEditClassFormControl = '';
  @Input() public addOnButtonEditAction = '';
  @Input() public addOnButtonEditDisabled = false;
  @Input() public addOnButtonEditIcon = '';

  @Input() public addOnButtonEditLabel = '';
  @Input() public addOnButtonEditIconEnable = false;

  public componentId: string;
  public componentIdAfter: string;
  private keyList: any = { 17: false, 40: false, 38: false };

  constructor(elementRef: ElementRef, private sessionService: SessionService, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
    this.componentId = 'PensionNumericTextBoxComponent' + UniqueId();
    this.componentIdAfter = 'PensionNumericTextBoxComponentAfter' + UniqueId();
  }

  protected valid(): boolean {
    if (this.required && (!this.value || this.value.length === 0)) {
      return false;
    }

    if (this.value && !this.sessionService.parseFloat(this.value)) {
      return false;
    }

    return true;
  }

  @Output() blur: EventEmitter<any> = new EventEmitter<any>();
  @Output() keydowns: EventEmitter<any> = new EventEmitter<any>();
  @Output() addOnButtonClick: EventEmitter<any> = new EventEmitter<any>();

  public onBlur(): void {
    this.blur.emit();
  }

  public onvalueChange(value: number): void {
    if (value && value < 0 && this.isNotNegativeValue) {
      value = value * -1;
      (this.value as any) = value;
    }
  }

  public onKeydown(key: any): void {
    if (key.key === 'ArrowUp' || key.key === 'ArrowDown') {
      if (this.isGrid && this.value && this.option && this.option.step) {
        if (key.key === 'ArrowUp') {
          (this.value as any) -= this.option.step;
        } else {
          (this.value as any) += this.option.step;
        }

        if ((this.baseValues === null || this.baseValues === undefined) && (this.value as any) === 0) {
          this.value = this.baseValues;
        }
      }
    }
    if (key.keyCode === 27) {
      // Escape
      this.value = this.original;
    }
  }

  public onFocus(): void {}

  public addOnButtonEditClick(): void {
    this.addOnButtonClick.emit(this.addOnButtonEditAction);
  }
}
