import { ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../environments/environment';

export class SentryErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    err = err.originalError || err;
    if (err && err.message && err.message.indexOf('ExpressionChangedAfterItHasBeenCheckedError') >= 0) {
      return;
    }

    if (environment.environment === 'DEV') {
      console.error(err);
    }

    if (
      err.message === 'GSNetworkError' ||
      err.status === 0 ||
      (err.status &&
        (err.status === 400 || (err.status === 401 && err.url.includes('session/isalive')) || err.status === 404))
    ) {
      return;
    }

    if (
      err.message === 'GSNetworkError' ||
      (err.rejection &&
        (err.rejection.status === 0 ||
          (err.rejection.status &&
            (err.rejection.status === 400 || err.rejection.status === 401 || err.rejection.status === 404))))
    ) {
      return;
    }

    if (err && err.message && err.message.indexOf('a[b].target.className.indexOf') >= 0) {
      return;
    }

    Sentry.captureException(err);
  }
}
