<app-dialog
  [overlayClose]="true"
  [(visible)]="visible"
  title="{{ 'Company.OvertimeSupplements' | translate }}"
  id="overtimeDialog"
  class="main-dialog"
>
  <app-module-check [moduleId]="4">
    <app-grid
      [(data)]="overtimeSupplement"
      [editMode]="editMode"
      [reFocusAfterChangeEdit]="false"
      class="OvertimeSupplementGrid"
      [addNew]="editMode"
      [deleteRow]="editMode"
      [addNewDisable]="sessionService.isGetting"
      (addNewEvent)="addOvertimeHandler($event)"
      (removeEvent)="removeOvertimeHandler($event)"
      (saveChangesEvent)="saveChanges($event)"
      addNewText="{{ 'SenioritySupplements.AddNew' | translate }}"
      [(triggerUpdate)]="refreshData"
      [(selectedItem)]="selectedItem"
      [isUseSpanHeader]="true"
      [groupHeader]="'OvertimeSupplement.TotalHours' | translate"
      [groupWidth]="340"
      [groupLocked]="false"
      [groupHeaderClass]="'rootHeaderGroup'"
      [idColumn]="'GridId'"
    >
      <app-grid-column-group
        field="Between"
        [editable]="false"
        type="text"
        [width]="130"
        class="grid-disable-cell"
        text="{{ 'OvertimeSupplement.Between' | translate }}"
        [title]="''"
        addOnClass="headerGroup titleGroup"
      >
      </app-grid-column-group>

      <app-grid-column-group
        field="TotalHoursWorkedFrom"
        type="numeric"
        [width]="100"
        [numbericOption]="numericOptions"
        [title]="''"
        addOnClass="headerGroup"
      ></app-grid-column-group>

      <app-grid-column-group
        field="And"
        [editable]="false"
        type="text"
        [width]="80"
        class="grid-disable-cell"
        text="{{ 'OvertimeSupplement.And' | translate }}"
        [title]="''"
        addOnClass="headerGroup"
      >
      </app-grid-column-group>

      <app-grid-column-group
        field="TotalHoursWorkedUntil"
        type="numeric"
        [editable]="true"
        [width]="120"
        [numbericOption]="numericOptions"
        [title]="''"
        addOnClass="headerGroup"
      >
      </app-grid-column-group>
      <!-- </app-grid-column> -->

      <app-grid-column
        field="SupplementAmount"
        type="numeric"
        [editable]="true"
        title="{{ 'OvertimeSupplement.ExtraperHour' | translate }}"
        [width]="100"
        [numbericOption]="numericDecimalOptions"
      >
      </app-grid-column>

      <app-grid-column
        field="SupplementPercentage"
        type="percentage"
        [editable]="true"
        title="{{ 'OvertimeSupplement.ExtraPercentage' | translate }}"
        [width]="100"
      >
      </app-grid-column>

      <app-grid-column
        field="OvertimeSupplementPrinciple"
        [comboboxDataSource]="intervalTypes"
        type="combobox"
        comboboxValueField="Id"
        comboboxDisplayField="Name"
        [editable]="true"
        title="{{ 'SenioritySupplements.Principle' | translate }}"
        [width]="150"
      >
      </app-grid-column>
    </app-grid>
  </app-module-check>

  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>
