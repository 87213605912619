import { AfterViewInit, ChangeDetectorRef, EventEmitter, QueryList } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AddEvent, CellClickEvent, GridComponent as kendoGridComponent, GridDataResult, PageChangeEvent, PagerSettings, RemoveEvent, RowClassArgs, SelectionEvent } from '@progress/kendo-angular-grid';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { orderBy } from '@progress/kendo-data-query';
import { ListHelper } from '../../common/list-helper';
import { SessionService } from '../../services/session/session.service';
import { GridActionComponent } from './grid-action.component';
import { GridColumnGroupComponent } from './grid-column-group.component';
import { GridColumnComponent } from './grid-column.component';
import { GridNavigationService } from './grid-navigation.service';
import { GridRadioComponent } from './grid-radio.component';
var GridComponent = /** @class */ (function () {
    function GridComponent(formBuilder, changeDetectorRef, sessionService) {
        this.formBuilder = formBuilder;
        this.changeDetectorRef = changeDetectorRef;
        this.sessionService = sessionService;
        this.currentEditmode = false;
        this.isCurrentCellValid = true;
        this.reFocusAfterChangeEdit = true;
        this.scrollable = 'scrollable';
        this.rowHeight = this.scrollable === 'virtual' ? 28 : null;
        this.addNewTooltips = '';
        this.customDeleteRow = false;
        this.companyTemplates = false;
        this.isEmployeeTime = false;
        this.showButDisableAddNewButton = false;
        this.disableDeleteButton = false;
        this.DeleteTooltips = '';
        this.columnIndexFocusAfterCreate = 0;
        this.selectedRows = [];
        this.disabled = false;
        this.scrollOnDataSourceChange = true;
        this.allowActionNonEditmode = false;
        // Attr PageList
        this.pageSize = 20;
        this.skip = 0;
        this.pageable = false;
        this.hiddenCount = false;
        this.buttonCount = 0;
        this.miniPager = false;
        // Group columns
        this.isUseSpanHeader = false;
        this.groupLocked = false;
        this.currentFilterClear = false;
        this.currentSortClear = false;
        this.isReportDialog = false;
        this.selectedIdChange = new EventEmitter();
        this.skipChange = new EventEmitter();
        this.disableButtonAddNewClickEvent = new EventEmitter();
        this.updateDefaultRecordEvent = new EventEmitter();
        this.selectedItemChange = new EventEmitter();
        this.dataChange = new EventEmitter();
        this.triggerUpdateChange = new EventEmitter();
        this.addNewEvent = new EventEmitter();
        this.removeEvent = new EventEmitter();
        this.cellCloseEvent = new EventEmitter();
        this.saveChangesEvent = new EventEmitter();
        this.action = new EventEmitter();
        this.cellClick = new EventEmitter();
        this.gridButtonClick = new EventEmitter();
        this.clickAppGridIcon = new EventEmitter();
        this.controlDataChange = new EventEmitter();
        this.dataStateChange = new EventEmitter();
        this.filterChange = new EventEmitter();
        this.ButtonPopUpClick = new EventEmitter();
        this.iconControlClick = new EventEmitter();
        //@Output() public addonClick: EventEmitter<void> = new EventEmitter<void>();
        this.dblClick = new EventEmitter();
        this.DisableHotKeys = new EventEmitter();
        this.radioValueChange = new EventEmitter();
        this.filterClearChange = new EventEmitter();
        this.sortClearChange = new EventEmitter();
        this.controlValidChange = new EventEmitter();
        this.navigationService = new GridNavigationService();
        this.isDataChanged = false;
        this.sort = [];
        this.afterFitcolumns = false;
        this.show = false;
        this.keyPress = { 17: false, 18: false, 107: false, 109: false };
        this.approveRejectData = new EventEmitter();
    }
    Object.defineProperty(GridComponent.prototype, "editMode", {
        get: function () {
            return this.currentEditmode;
        },
        set: function (value) {
            this.currentEditmode = value;
            if (value &&
                this.selectedCell &&
                this.selectedCell.rowIndex > -1 &&
                this.reFocusAfterChangeEdit &&
                this.gridData &&
                this.gridData.data &&
                this.gridData.data.length > 0 &&
                this.gridData.data.length - 1 >= this.selectedCell.rowIndex) {
                this.editCell(this.selectedCell.rowIndex, this.selectedCell.columnIndex);
                setTimeout(function () {
                    var element = document.querySelector('.k-state-focused input');
                    if (element) {
                        element.focus();
                    }
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GridComponent.prototype, "radioValue", {
        get: function () {
            return this.currentRadioValue;
        },
        set: function (value) {
            this.currentRadioValue = value;
            this.radioValueChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GridComponent.prototype, "filterClear", {
        get: function () {
            return this.currentFilterClear;
        },
        set: function (value) {
            if (value) {
                this.onFilterChange({ logic: 'and', filters: [] });
            }
            this.filterClearChange.emit(false);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GridComponent.prototype, "sortClear", {
        get: function () {
            return this.currentSortClear;
        },
        set: function (value) {
            if (value) {
                this.onSortChange([]);
            }
            this.sortClearChange.emit(false);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GridComponent.prototype, "isDisableSelect", {
        get: function () {
            return this.currentDisableSelect;
        },
        set: function (value) {
            var _this = this;
            if (value) {
                setTimeout(function () {
                    var gridContent = _this.kendoGrid.wrapper.nativeElement.querySelector('.k-grid-content');
                    var element = _this.kendoGrid.wrapper.nativeElement.querySelector('.k-grid-content .activeCell');
                    if (element && _this.scrollOnDataSourceChange) {
                        gridContent.scrollTop = element.offsetTop;
                    }
                });
            }
            this.currentDisableSelect = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GridComponent.prototype, "pageListSetting", {
        get: function () {
            if (this.gridData && this.gridData.total <= this.pageSize) {
                return false;
            }
            if (this.miniPager) {
                return this.showMiniPager;
            }
            if (this.buttonCount) {
                return { buttonCount: this.buttonCount, info: !this.hiddenCount };
            }
            return this.pageable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GridComponent.prototype, "showMiniPager", {
        get: function () {
            return this.miniPager && !!this.originalData && this.originalData.length > this.pageSize;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GridComponent.prototype, "selectedId", {
        get: function () {
            return this.selectedIdValue;
        },
        set: function (value) {
            var _this = this;
            if ((this.selectedIdValue !== value || this.selectedRows.length === 0 || (!this.selectedIdValue && !value)) &&
                !this.isReportDialog) {
                this.selectedIdValue = value;
                this.selectedIdChange.emit(value);
                this.selectedRows = [value];
                if (this.data &&
                    this.idColumn &&
                    (!this.selectedItem || this.selectedItem[this.idColumn] !== this.selectedIdValue)) {
                    var selected = this.data.find(function (e) { return e[_this.idColumn] === _this.selectedIdValue; });
                    if (this.selectedItem !== selected) {
                        this.selectedItem = selected;
                    }
                }
                this.setPage();
                this.loadItems();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GridComponent.prototype, "selectedItem", {
        get: function () {
            return this.selectedItemValue;
        },
        set: function (value) {
            var previousValue = this.selectedItemValue;
            this.selectedItemValue = value;
            if (this.idColumn) {
                this.selectedId = value ? value[this.idColumn] : undefined;
            }
            if (this.selectedItemValue !== previousValue) {
                this.selectedItemChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GridComponent.prototype, "data", {
        get: function () {
            this.onInitData(this.originalData);
            return this.originalData;
        },
        set: function (value) {
            var _this = this;
            if (this.originalData !== value) {
                this.originalData = value || [];
                if (this.originalData.length > 0) {
                    this.originalData = ListHelper.createObserveArray(this.originalData, function () { return _this.triggerComponentChanged(); }, function () { return _this.triggerComponentChanged(); });
                    this.dataChange.emit(this.originalData);
                }
                if (!this.selectedItem ||
                    !this.originalData ||
                    this.originalData.length === 0 ||
                    (this.idColumn &&
                        !this.originalData.find(function (item) { return item[_this.idColumn] === _this.selectedItem[_this.idColumn]; }))) {
                    if (!this.selectedId) {
                        this.selectedRows = [];
                    }
                }
                if (!this.selectedId) {
                    this.skip = 0;
                }
                else {
                    this.setPage();
                }
                this.loadItems();
                setTimeout(function () {
                    var gridContent = _this.kendoGrid.wrapper.nativeElement.querySelector('.k-grid-content');
                    var element = _this.kendoGrid.wrapper.nativeElement.querySelector('.k-grid-content .k-state-selected');
                    if (element && _this.scrollOnDataSourceChange) {
                        gridContent.scrollTop = element.offsetTop;
                    }
                }, 1500);
                if (this.isCompanyApproval) {
                    for (var i = 0; i < this.data.length; i++) {
                        this.kendoGrid.collapseRow(i);
                    }
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    GridComponent.prototype.setPage = function () {
        var _this = this;
        if (this.data && this.idColumn && this.pageable) {
            var index = this.data.findIndex(function (d) { return d[_this.idColumn] === _this.selectedIdValue; });
            if (index > -1) {
                var page = index / this.pageSize;
                this.skip = Math.floor(page) * this.pageSize;
            }
            else {
                this.skip = 0;
            }
        }
    };
    GridComponent.prototype.loadItems = function () {
        if (this.originalData) {
            if (this.pageable) {
                this.gridData = {
                    data: this.originalData.slice(this.skip, this.skip + this.pageSize),
                    total: this.originalData.length
                };
            }
            else {
                this.gridData = {
                    data: this.originalData,
                    total: this.originalData.length
                };
            }
        }
    };
    Object.defineProperty(GridComponent.prototype, "triggerUpdate", {
        get: function () {
            return this.triggerUpdateValue;
        },
        set: function (value) {
            var _this = this;
            if (value) {
                this.changeDetectorRef.markForCheck();
                this.changeDetectorRef.detectChanges();
                setTimeout(function () {
                    _this.triggerUpdateValue = false;
                    _this.triggerUpdateChange.emit(_this.triggerUpdateValue);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GridComponent.prototype, "isAppLoading", {
        get: function () {
            return this.sessionService.isGetting;
        },
        enumerable: true,
        configurable: true
    });
    GridComponent.prototype.pageChange = function (event) {
        this.skip = event.skip;
        this.skipChange.emit(this.skip);
        this.loadItems();
    };
    GridComponent.prototype.triggerComponentChanged = function () {
        var _this = this;
        setTimeout(function () {
            _this.changeDetectorRef.markForCheck();
        });
    };
    Object.defineProperty(GridComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    GridComponent.prototype.trackIndex = function (index, item) {
        if (item.hideColumn) {
            return null;
        }
        return index;
    };
    GridComponent.prototype.rowCallback = function (context) {
        return { highlightedRow: context.dataItem && context.dataItem.HightlightedRow };
    };
    GridComponent.prototype.cellClass = function (column) {
        var res = column.class + " " + (!column.editableField && !column.editable ? 'grid-disable-cell' : 'grid-enable-cell');
        return res;
    };
    GridComponent.prototype.templateClass = function (column, dataItem) {
        return column && dataItem && column.classField ? dataItem[column.classField] : undefined;
    };
    Object.defineProperty(GridComponent.prototype, "isSelected", {
        get: function () {
            if (this.isDisableSelect) {
                return false;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    GridComponent.prototype.cellClickHandler = function (args) {
        this.selectedCell = { field: args.column.field, rowIndex: args.rowIndex, columnIndex: args.columnIndex };
        if (this.oldSelected && this.oldSelected.rowIndex === this.selectedCell.rowIndex && this.isCompanyApprovalDetail) {
            this.onSelectedRowCompanyApproval(args.dataItem);
            var listRows = document.querySelectorAll('tr');
            var index = -1;
            for (var i = 0; i < listRows.length; i++) {
                var row = listRows[i];
                var column = row.querySelectorAll('td')[0];
                if (column && column.innerText === args.dataItem.EmployeeName) {
                    index += 1;
                    if (index === this.oldSelected.rowIndex) {
                        row.classList.add('k-state-selected');
                        return;
                    }
                }
                else {
                    index = -1;
                }
            }
        }
        else {
            this.oldSelected = this.selectedCell;
        }
        var isEdited = args.isEdited;
        if (this.editMode && !isEdited) {
            args.sender.editCell(args.rowIndex, args.columnIndex, this.createFormGroup(args.dataItem));
            this.isDataChanged = false;
            setTimeout(function () {
                var element = document.querySelector('.k-state-focused input');
                if (element) {
                    element.focus();
                }
            });
        }
        var clickToIcon = false;
        if (args && args.originalEvent && args.originalEvent.target) {
            clickToIcon = args.originalEvent.target.className.indexOf('IconContainer') >= 0;
        }
        this.cellClick.emit({ field: args.column.field, dataItem: args.dataItem, isClickToIcon: clickToIcon });
    };
    GridComponent.prototype.onSelectionChange = function (args) {
        if (args && args.selectedRows && args.selectedRows.length > 0) {
            this.selectedItem = args.selectedRows[0].dataItem;
        }
        if (this.isCompanyApproval) {
            this.onSelectedRowCompanyApproval(args.selectedRows[0].dataItem, true);
        }
    };
    GridComponent.prototype.onDbClickChange = function (event) {
        var _this = this;
        // Run after onSelectionChange
        setTimeout(function () {
            if (event) {
                _this.selectedItem = _this.selectedItem || {};
                _this.selectedItem.event = event;
                _this.dblClick.emit(_this.selectedItem);
            }
        });
    };
    GridComponent.prototype.addHandler = function (args) {
        var _this = this;
        this.addNewDisable = true;
        if (this.defaultNewValue) {
            this.updateDefaultRecordEvent.emit();
            setTimeout(function () {
                _this.addHandlerFunction(args);
            });
        }
        else {
            this.addHandlerFunction(args);
        }
    };
    GridComponent.prototype.addHandlerFunction = function (args) {
        var dataItem = {};
        if (this.defaultNewValue) {
            dataItem = this.defaultNewValue;
        }
        else {
            if (this.columns && this.columns.length > 0) {
                this.columns.forEach(function (column) {
                    if (column.field) {
                        dataItem[column.field] = undefined;
                    }
                });
            }
        }
        if (!this.data) {
            this.data = [];
        }
        if (this.isEmployeeTime) {
            this.data.unshift(dataItem);
        }
        else {
            this.data.push(dataItem);
        }
        this.selectedItem = dataItem;
        this.addNewEvent.emit(dataItem);
        var formGroup = this.createFormGroup(dataItem);
        if (this.isEmployeeTime) {
            this.selectedCell = {
                field: this.columns ? this.columns.toArray()[this.columnIndexFocusAfterCreate].field : '',
                rowIndex: 0,
                columnIndex: this.columnIndexFocusAfterCreate
            };
            args.sender.editCell(0, this.columnIndexFocusAfterCreate, formGroup);
        }
        else {
            this.selectedCell = {
                field: this.columns ? this.columns.toArray()[0].field : '',
                rowIndex: this.data.length - 1,
                columnIndex: this.columnIndexFocusAfterCreate
            };
            args.sender.editCell(this.data.length - 1, this.columnIndexFocusAfterCreate, formGroup);
        }
        //args.sender.editCell(0, 0, formGroup);
        this.isDataChanged = false;
        this.addNewDisable = false;
    };
    GridComponent.prototype.checkModelRecord = function (record) {
        Object.keys(record).forEach(function (key) {
            if (record[key] === undefined) {
                record[key] = null;
            }
        });
        return record;
    };
    GridComponent.prototype.cellCloseHandler = function (args) {
        var _this = this;
        setTimeout(function () {
            var dataChanged = _this.isDataChanged;
            if (_this.isColumnDataChanged(args) || dataChanged) {
                _this.checkModelRecord(args.dataItem);
                _this.saveChangesEvent.emit(args);
                _this.triggerComponentChanged();
                _this.isDataChanged = false;
            }
            _this.cellCloseEvent.emit(args);
        });
    };
    GridComponent.prototype.isColumnDataChanged = function (args) {
        var dataChanged;
        var column = args.column, dataItem = args.dataItem, formGroup = args.formGroup;
        if (dataItem && formGroup && formGroup.value && column && column.field) {
            dataChanged =
                ((dataItem[column.field] === null || dataItem[column.field] === undefined) &&
                    formGroup.value[column.field] !== null &&
                    formGroup.value[column.field] !== undefined) ||
                    (dataItem[column.field] !== null &&
                        dataItem[column.field] !== undefined &&
                        (!formGroup.value.hasOwnProperty(column.field) ||
                            formGroup.value[column.field] === null ||
                            formGroup.value[column.field] === undefined)) ||
                    (dataItem[column.field] !== null &&
                        dataItem[column.field] !== undefined &&
                        formGroup.value[column.field] !== null &&
                        formGroup.value[column.field] !== undefined &&
                        dataItem[column.field] !== formGroup.value[column.field]);
        }
        return dataChanged;
    };
    GridComponent.prototype.removeHandler = function (args) {
        if (!this.isAppLoading) {
            if (!this.customDeleteRow) {
                // this.selectedItemChange.emit(args.dataItem);
                var indexOfRemovedItem = this.data.indexOf(args.dataItem);
                this.data.splice(indexOfRemovedItem, 1);
                args.sender.closeRow(args.rowIndex);
                this.removeEvent.emit(args.dataItem);
            }
            else {
                this.removeEvent.emit(args);
            }
            this.triggerComponentChanged();
        }
    };
    GridComponent.prototype.onKeyDown = function (event) {
        var _this = this;
        if ((event.key === 'Tab' || event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Escape') &&
            this.selectedCell) {
            var cell = this.navigationService.getNextEditableCell(this.getDisplayedColumns(), this.data, this.selectedCell.columnIndex, this.selectedCell.rowIndex, event);
            if (event.key === 'Escape') {
                setTimeout(function () {
                    _this.editCell(_this.selectedCell.rowIndex, _this.selectedCell.columnIndex, true);
                    _this.kendoGrid.closeCell();
                });
            }
            else if (cell) {
                this.kendoGrid.focusCell(cell.rowIndex + 1, cell.columnIndex);
                this.editCell(cell.rowIndex, cell.columnIndex);
                setTimeout(function () {
                    var element = document.querySelector('.k-state-focused input');
                    if (element) {
                        element.focus();
                    }
                });
                if (this.editMode) {
                    this.createFormGroup(this.data ? this.data[cell.rowIndex] : undefined);
                }
            }
            event.preventDefault();
            event.stopPropagation();
        }
        if (event.key === 'Enter' && this.selectedCell) {
            if (this.isCurrentCellValid) {
                this.isDataChanged = true;
            }
            this.isCurrentCellValid = true;
        }
    };
    GridComponent.prototype.onDataChanged = function (dataItem, field, editableField, isAllowNullValue) {
        if (isAllowNullValue === void 0) { isAllowNullValue = true; }
        var args = {
            column: { field: field },
            dataItem: dataItem,
            formGroup: this.formGroup
        };
        if (this.controlDataChange.observers &&
            this.controlDataChange.observers.length > 0 &&
            this.isColumnDataChanged(args)) {
            this.isDataChanged = true;
            if (isAllowNullValue) {
                this.formGroup.value[field] = dataItem[field];
            }
            else {
                if (dataItem[field]) {
                    this.formGroup.value[field] = dataItem[field];
                }
                else {
                    dataItem[field] = this.formGroup.value[field];
                }
            }
            this.controlDataChange.emit({ dataItem: dataItem, field: field, editableField: editableField });
        }
    };
    GridComponent.prototype.onCheckBoxCheckedChanged = function (dataItem, field, isIgnoreCheckData) {
        if (isIgnoreCheckData === void 0) { isIgnoreCheckData = false; }
        var event = new CellCloseEvent({ dataItem: dataItem });
        var args = {
            column: { field: field },
            dataItem: dataItem,
            formGroup: this.formGroup
        };
        if (this.isColumnDataChanged(args) || isIgnoreCheckData) {
            this.createFormGroup(dataItem);
            this.checkModelRecord(dataItem);
            this.saveChangesEvent.emit(event);
            this.triggerComponentChanged();
        }
    };
    GridComponent.prototype.getDisplayedColumns = function () {
        var displayedColumns = [];
        if (this.isUseSpanHeader) {
            var arrGroupColumns = this.groupColumns.toArray();
            for (var index = 0; index < arrGroupColumns.length; index++) {
                var column = arrGroupColumns[index];
                displayedColumns.push(column);
            }
        }
        var arrColumns = this.columns
            .toArray()
            .filter(function (col) { return !col.hideColumn; });
        for (var index = 0; index < arrColumns.length; index++) {
            var column = arrColumns[index];
            if (!column.isGroupColumn) {
                displayedColumns.push(column);
            }
            else {
                var arrSubColumns = column.columns.toArray();
                for (var subIndex = 0; subIndex < arrSubColumns.length; subIndex++) {
                    var subColumn = arrSubColumns[subIndex];
                    if (!subColumn.isGroupColumn) {
                        displayedColumns.push(subColumn);
                    }
                }
            }
        }
        return displayedColumns;
    };
    GridComponent.prototype.createFormGroup = function (dataItem) {
        this.formGroup = this.formBuilder.group(dataItem);
        return this.formGroup;
    };
    GridComponent.prototype.editCell = function (rowIndex, columnIndex, isIgnore) {
        if (isIgnore === void 0) { isIgnore = false; }
        if (!isIgnore && this.selectedCell) {
            this.selectedCell.rowIndex = rowIndex;
            this.selectedCell.columnIndex = columnIndex;
        }
        var dataItem = this.data[rowIndex];
        this.selectedItem = dataItem;
        if (this.editMode) {
            this.kendoGrid.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
        }
        if (!this.selectedItem || !this.selectedItem['EntryDate'] || !this.selectedItem['TimeEntryTypeId']) {
            this.isDataChanged = false;
        }
    };
    GridComponent.prototype.getParamSubProberty = function (dataItem, subproperty) {
        if (subproperty) {
            var arraySubproperty = subproperty.split('.');
            var object = dataItem;
            for (var i = 0; i < arraySubproperty.length; ++i) {
                if (!object[arraySubproperty[i]]) {
                    break;
                }
                object = object[arraySubproperty[i]];
            }
            return object;
        }
        return '';
    };
    GridComponent.prototype.appIconClick = function (dataItem, iconAction) {
        this.clickAppGridIcon.emit({ dataItem: dataItem, iconAction: iconAction });
        this.triggerComponentChanged();
    };
    GridComponent.prototype.onGridButtonClickEvent = function (dataItem, buttonAction, isDisable) {
        if (isDisable) {
            return;
        }
        this.gridButtonClick.emit({ dataItem: dataItem, buttonAction: buttonAction });
        this.triggerComponentChanged();
    };
    GridComponent.prototype.onIncellPopUpClickAction = function (dataItem, field, args) {
        var buttonAction = args.action;
        this.gridButtonClick.emit({ dataItem: dataItem, buttonAction: buttonAction, field: field });
        // dataItem[displayField] = false;
        this.triggerComponentChanged();
    };
    GridComponent.prototype.onDataStateHandler = function (event) {
        this.dataStateChange.emit(event);
        this.kendoGrid.expandRow(0);
    };
    GridComponent.prototype.onSortChange = function (sort) {
        var _this = this;
        this.columns.map(function (item) {
            if (item.addOnClass && item.addOnClass.includes(' headerSort')) {
                item.addOnClass = item.addOnClass.replace(' headerSort', '');
            }
        });
        if (sort && sort.length > 0) {
            sort.forEach(function (iteminlist) {
                _this.columns.map(function (item) {
                    if (item.field === iteminlist.field && iteminlist.dir) {
                        if (!item.addOnClass || (item.addOnClass && !item.addOnClass.includes('headerSort'))) {
                            item.addOnClass += ' headerSort';
                        }
                    }
                });
            });
        }
        if (this.data && this.data.length > 0) {
            this.sort = sort;
            var gridView = {
                data: orderBy(this.data, this.sort),
                total: this.data.length
            };
            this.data = gridView.data;
        }
        this.collapseRowonGrid();
    };
    GridComponent.prototype.onFilterChange = function (filter) {
        if (this.groupColumns || this.columns) {
            // Update header for group columns
            this.updateHeaderFilter(filter, this.groupColumns);
            // Update header for columns
            this.updateHeaderFilter(filter, this.columns);
            this.collapseRowonGrid();
            this.filterChange.emit(filter);
        }
    };
    GridComponent.prototype.updateHeaderFilter = function (filter, columns) {
        if (columns && columns.length > 0) {
            columns.map(function (item) {
                if (item.addOnClass && item.addOnClass.includes(' headerFilter')) {
                    item.addOnClass = item.addOnClass.replace(' headerFilter', '');
                }
            });
            var filterList = filter.filters;
            if (filterList && filterList.length > 0) {
                filterList.forEach(function (iteminlist) {
                    columns.map(function (item) {
                        if (item.field === iteminlist.filters[0].field) {
                            if (!item.addOnClass || (item.addOnClass && !item.addOnClass.includes('headerFilter'))) {
                                item.addOnClass += ' headerFilter';
                            }
                        }
                    });
                });
            }
        }
    };
    GridComponent.prototype.ngAfterViewInit = function () { };
    GridComponent.prototype.onToggle = function () {
        this.show = !this.show;
    };
    GridComponent.prototype.AddNewDisableClick = function () {
        if (this.addNewDisable) {
            //this.disableButtonAddNewClickEvent.emit();
        }
    };
    GridComponent.prototype.onGridToolbarClick = function (event) {
        this.action.emit(event);
    };
    GridComponent.prototype.onMoewKeyDown = function (event) {
        var _this = this;
        if (event.keyCode in this.keyPress) {
            this.keyPress[event.keyCode] = true;
            if (this.keyPress[17] && this.keyPress[18] && this.keyPress[107]) {
                // Add new Event
                if (this.addNew) {
                    setTimeout(function () {
                        if (!_this.sessionService.isGetting && !_this.sessionService.isSubmitting) {
                            _this.addHandler({ dataItem: {}, isNew: true, rowIndex: 0, sender: _this.kendoGrid });
                        }
                    });
                    this.setKeypress();
                }
                else {
                    this.DisableHotKeys.emit(true);
                    this.setKeypress();
                }
            }
        }
        else {
            this.setKeypress();
        }
    };
    GridComponent.prototype.setKeypress = function () {
        this.keyPress[17] = false;
        this.keyPress[18] = false;
        this.keyPress[107] = false;
        this.keyPress[109] = false;
    };
    GridComponent.prototype.onSelectedRowCompanyApproval = function (event, isNotClick) {
        if (isNotClick === void 0) { isNotClick = false; }
        var listRows = document.querySelectorAll('table tbody tr.k-master-row');
        var listDetailRows = document.querySelectorAll('table tbody tr.k-detail-row');
        for (var i = 0; i < listRows.length; i++) {
            var column = listRows[i].querySelectorAll('td')[1];
            if (column && column.innerText === event.EmployeeName) {
                if (!isNotClick) {
                    column.click();
                }
                this.removeSelectAnotherRow(listDetailRows, i, event.EmployeeName);
                break;
            }
        }
    };
    GridComponent.prototype.removeSelectAnotherRow = function (listDetailRows, selectedIndex, employeeName) {
        listDetailRows.forEach(function (row, index) {
            var listDR = row.querySelectorAll('tr');
            for (var i = 1; i < listDR.length; i++) {
                var eNameColumn = listDR[i].querySelectorAll('td')[0];
                if (eNameColumn && eNameColumn.innerText === employeeName) {
                    break;
                }
                listDR[i].classList.remove('k-state-selected');
            }
        });
    };
    GridComponent.prototype.onGridRadioToolbarClick = function () { };
    GridComponent.prototype.onApproveRejectData = function () {
        this.selectedApproveItem = this.selectedItem;
        this.approveRejectData.emit();
    };
    GridComponent.prototype.collapseRowonGrid = function () {
        if (this.isCompanyApproval) {
            for (var i = 0; i < this.data.length; i++) {
                this.kendoGrid.collapseRow(i);
            }
        }
    };
    GridComponent.prototype.onOpenApprovalGridDetail = function () {
        var _this = this;
        setTimeout(function () {
            _this.selectedApproveItem = null;
        });
    };
    GridComponent.prototype.onInitData = function (data) {
        var indexItem = -1;
        if (this.selectedApproveItem) {
            for (var i = 0; i < data.length; i++) {
                if (this.selectedApproveItem.EmployeeName === data[i].EmployeeName) {
                    indexItem = i;
                    break;
                }
            }
            if (indexItem >= 0) {
                this.selectedItem = this.selectedApproveItem;
                this.kendoGrid.expandRow(indexItem);
            }
        }
    };
    GridComponent.prototype.controlValid = function (event, subColumn) {
        this.isCurrentCellValid = event;
        this.controlValidChange.emit({ isvalid: event, field: subColumn });
    };
    GridComponent.prototype.onHalfCheckChange = function (event) {
        var dataItem = event.dataItem;
        var checked = event.checked;
        var type = event.type;
        if (dataItem) {
            if (type === 'AppliesWeekdays') {
                dataItem.AppliesMondays = checked;
                dataItem.AppliesTuesdays = checked;
                dataItem.AppliesWednesdays = checked;
                dataItem.AppliesThursdays = checked;
                dataItem.AppliesFridays = checked;
            }
            else if (type === 'AppliesSunHolis') {
                dataItem.AppliesSundays = checked;
                dataItem.AppliesHolidays = checked;
            }
            this.onCheckBoxCheckedChanged(dataItem, 'AppliesHalfCheckbox', true);
        }
    };
    GridComponent.prototype.iconTextEditControlClick = function (action, dataItem) {
        this.iconControlClick.emit({ action: action, dataItem: dataItem });
    };
    return GridComponent;
}());
export { GridComponent };
