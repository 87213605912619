<app-combobox-edit
  [disable]="employeeService.editMode"
  [editMode]="true"
  [textField]="'Text'"
  [idField]="'Id'"
  id="comboboxSalaryCycle"
  [comboboxDataSource]="listSource"
  [(value)]="employeeService.salaryIdCycle"
  [keepDataSourceOrder]="true"
></app-combobox-edit>
