import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { NgxZendeskWebwidgetConfig, NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AdditionalVacationModel } from 'src/app/shared-components/additional-vacation/additional-vacation-model';
import { environment } from 'src/environments/environment';
import { version } from '../../../version';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { RouterStateService } from '../../common/router-state.service';
import { EmployeeService } from '../../services/employee.service';
import { ModalService } from '../modal.service';
import { SessionService } from '../session/session.service';
import { SettingService } from '../setting.service';
import {
  Account,
  IAccount,
  IAccountInvoiceType,
  IAccountRole,
  IAccountType,
  IAdditionalVacationTerminationMethod,
  IApiUser,
  IBalancePeriod,
  IBank,
  IBankHoliday,
  IBaseCalculationMethod,
  IBillingPrinciple,
  ICity,
  ICompany,
  ICompanyAccountView,
  ICompanyType,
  ICompanyUser,
  ICompanyUserView,
  ICountry,
  ICurrency,
  IDepartment,
  IEntityType,
  IExternalSystem,
  IExternalSystemConfiguration,
  IExternalSystemCredential,
  IExternalSystemOperation,
  IExtraVacationHoursEarningBehavior,
  IFinanceAccountType,
  IGender,
  IImportOption,
  IImportOptionValue,
  IImportType,
  IIncomeType,
  IIntegration,
  IIntegrationStatus,
  IIntervalType,
  ILanguage,
  IModule,
  IModuleCompanyView,
  IModulePackage,
  IMonth,
  IMunicipality,
  IOvertimeSupplementPrinciple,
  IPaymentMethod,
  IPensionProvider,
  IReportArea,
  IReportParameter,
  IRole,
  ISalaryCycle,
  ISalaryRecordPersistenceType,
  ISalaryRecordsOverviewDisplayMode,
  ISalarySummaryCategory,
  ISalaryTypeCategory,
  ISalaryTypeDimensionDistributionOption,
  ISeTaxTable,
  ISimpleCompany,
  ISimpleKeyValuePair,
  IStartupTaskCompanyView,
  IStatisticsEmploymentPrinciple,
  IStatisticsEmploymentTerms,
  IStatisticsJobStatus,
  IStatisticsRecipient,
  IStatisticsSalaryPrinciple,
  ITaxCardType,
  ITimeEntryRecordAcrossSalaryPeriodStrategy,
  ITimeEntryStatus,
  IUnitType,
  IUserEmploymentView,
  IVacationPeriod,
  IVacationProvider,
  IVacationSupplementPayoutMethodDK,
  IVacationType,
  IVacationTypeSE
} from './api-model';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class StaticDataService {
  private zendeskConfig: NgxZendeskWebwidgetConfig = {
    timeOut: 0,
    lazyLoad: true,
    accountUrl: 'intect.zendesk.com',
    injectionTag: 'body',
    callback(zE: any) {
      // You can call every command you want in here
      zE('webWidget', 'hide');
    }
  };
  constructor(
    private dataService: DataService,
    private translateService: TranslateService,
    private settingsService: SettingService,
    private sessionService: SessionService,
    private modalService: ModalService,
    private state: RouterStateService,
    private employeeService: EmployeeService,
    private ngxZendeskWebwidgetService: NgxZendeskWebwidgetService
  ) {
    this.translateErrorMessages();
    this.configureSentryBrowser();
    this.sessionService.OnTranslateChanged.subscribe(() => this.translateErrorMessages());
    this.ngxZendeskWebwidgetService.initZendesk(this.zendeskConfig);
  }

  public async loadStaticData(): Promise<void> {
    await this.dataService
      .Auth_IsSessionAlive()
      .toPromise()
      .then(() => {
        this.sessionService.checkIsAliveError = false;
      })
      .catch(() => {
        this.sessionService.checkIsAliveError = true;
      });

    if (this.sessionService.checkIsAliveError) {
      return;
    }

    await this.loadSessionInfo();
    if (!this.moduleCompanyViewSubject) {
      this.loadModuleCompanyView();
    }
    await this.loadUnitTypes();
    this.sessionService.hideGetStarted = false;
  }

  private async loadSessionInfo(): Promise<void> {
    const data: IApiUser = await this.dataService.Auth_GetSessionInfo().toPromise();
    // const CurrentAccount = await this.dataService.Account_GetUserAccount().toPromise();
    // console.log(CurrentAccount);

    environment.branding.SystemAlias = data.CurrentSystemName;
    Global.SESSION = data;

    // Load User employments
    // if (Global.SESSION.CurrentCompanyUser && Global.SESSION.CurrentCompanyUser.Id) {
    //   const currentUser = await this.dataService
    //     .CompanyUsers_GetCompanyUserById(Global.SESSION.CurrentCompanyUser.Id)
    //     .toPromise();
    //   Global.USEREMPLOYMENTS = currentUser.UserEmployments;
    // }

    Global.COMPANY = await this.dataService.Companies_GetCurrent().toPromise();

    this.sessionService.loadGlobalize();
    this.setupExtraDataSentry(data);

    if (!this.enabledModulesSubject) {
      this.enabledModulesSubject = new BehaviorSubject<IModule[]>([]);
    }

    this.enabledModulesSubject.next(data.Modules);
    const shortCultureCode: string = data.SignOnToken.Language.CultureCode.substr(0, 2);
    await this.translateService.use(shortCultureCode).toPromise();
    this.sessionService.applyLocale(shortCultureCode);

    await this.getStartupTasks();
    await this.translateErrorMessages();
    await this.loadCompanyPreferences().toPromise();
    await this.loadUserPreferences().toPromise();
    await this.checkProduction().toPromise();
    await this.employeeService.loadEmployeesAsync();

    this.loadUserEmployments();

    if (Global.SESSION && Global.SESSION.SignOnToken.LanguageId) {
      try {
        document.cookie = 'languageId=' + Global.SESSION.SignOnToken.LanguageId;
      } catch (e) {
        this.sessionService.isDetectedCookieDisable = true;
      }
    }
  }

  private async getStartupTasks(): Promise<void> {
    if (
      Global.SESSION.CurrentRole.Key === 'FullAdmin' &&
      Global.SESSION.CurrentCompanyUser.IsActive &&
      Global.SESSION.CurrentCompany.CountryId !== Constants.GREENLAND_COUNTRY_ID
    ) {
      const tasks: IStartupTaskCompanyView[] = await this.dataService
        .Startuptasks_GetStartupTasksWithStatus()
        .toPromise();
      Global.STARTUP_TASKS = tasks;
      this.settingsService.updateSettingsForEmployeeCompany(tasks);
    } else {
      Global.IsCompanyDataCompleted = true;
      Global.IsEmployeeTaskCompleted = true;
    }
  }

  private translations: { [key: string]: string };
  private async translateErrorMessages(): Promise<void> {
    this.translations = await this.translateService
      .get(['Error.UnExpectedErrorTitle', 'Error.UnExpectedErrorMessage'])
      .toPromise();

    this.translations['Error.UnExpectedErrorMessage'] = this.translations['Error.UnExpectedErrorMessage'].replace(
      '{{SystemAlias}}',
      environment.branding.SystemAlias
    );
  }

  private setupExtraDataSentry(data: IApiUser) {
    Sentry.setExtras({
      UserAccountId: data.UserAccountId,
      AccountRoleId: data.AccountRoleId,
      CurrentCompanyId: data.CurrentCompany.Id,
      CurrentCompanyName: data.CurrentCompany.Name,
      CurrentRoleId: data.CurrentRole.Id,
      UserId: data.Id,
      FullName: data.FullName,
      HasBackendAccess: data.HasBackendAccess,
      IsAccountAdmin: data.IsAccountAdmin,
      IsAccountMember: data.IsAccountMember,
      IsCurrentAccountAdmin: data.IsCurrentAccountAdmin,
      IsCurrentAccountMember: data.IsCurrentAccountMember,
      IsIntectSupportUser: data.IsIntectSupportUser,
      IsPaymentApprover: data.IsPaymentApprover
    });
  }

  private configureSentryBrowser(): void {
    // Set user information, as well as tags and further extras
    Sentry.init({
      dsn: environment.sentryDSN,
      environment: environment.environment,
      release: version,
      debug: true,
      beforeSend: (info) => {
        const extra: any = info.extra.extra;
        if (
          extra &&
          (extra.status === 400 ||
            (extra.status === 401 && extra.url.includes('session/isalive')) ||
            extra.status === 404)
        ) {
          return null;
        }

        if (info.message && info.message.indexOf('a[b].target.className.indexOf') >= 0) {
          return null;
        }

        const noneExceptionMessage = 'Non-Error exception captured with keys: error, headers, message, name, ok';
        if (
          info.exception &&
          info.exception.values &&
          info.exception.values.length > 0 &&
          info.exception.values[0].value.indexOf(noneExceptionMessage) >= 0
        ) {
          return null;
        }

        if ((version as string) === 'Local-build') {
          console.warn('Running local build, so error not sent to Sentry:', info);
          return null;
        }

        // We dont show unexpected for network error that already catched.
        const isConnectionError: boolean =
          info.exception &&
          info.exception.values &&
          info.exception.values.some((ex: any) => ex.value === 'GSNetworkError');
        if (isConnectionError || (extra && (extra.status || extra.status === 0))) {
          if (extra && extra.status === 500) {
            this.modalService.alert(
              this.defineUnExpectedErrorTitle(this.translations['Error.UnExpectedErrorTitle']),
              this.defineUnExpectedErrorMessage(this.translations['Error.UnExpectedErrorMessage'])
            );
          }
          return info;
        }

        if (extra && extra.url && extra.status && extra.status === 401 && !extra.url.includes('session/isalive')) {
          return info;
        }

        this.modalService.alert(
          this.defineUnExpectedErrorTitle(this.translations['Error.UnExpectedErrorTitle']),
          this.defineUnExpectedErrorMessage(this.translations['Error.UnExpectedErrorMessage'])
        );

        return info;
      }
    });
  }

  // GS-6068
  private defineUnExpectedErrorTitle(translatetitle: string): string {
    if (translatetitle && translatetitle !== 'Error.UnExpectedErrorTitle') {
      return translatetitle;
    }

    // default denish version
    return 'Uventet fejl';
  }

  // GS-6068
  private defineUnExpectedErrorMessage(translateErrorMessage: string): string {
    if (translateErrorMessage && translateErrorMessage !== 'Error.UnExpectedErrorMessage') {
      return translateErrorMessage;
    }

    // default denish version
    return 'Der er opstået en uventet fejl. Hvis du forsøgte at gemme data, er dine ændringer muligvis gået tabt.<br/>Fejlen rapporteres automatisk til vores udviklere. Du kan forvente at den vil blive løst inden for en overskuelig tidshorisont.<br/>I mellemtiden kan du prøve at gentage din handling, genindlæse siden eller logge ud og ind igen. Du kan også prøve at benytte en anden browser, rydde din browser-cache, eller opdatere din app til nyeste version.<br/>Hvis dette ikke hjælper og du ikke kan ignorere fejlen, er du velkommen til at kontakte os for at få hjælp.<br/>Vi beklager ulejligheden.';
  }

  // Setup default routes and redirects depending on enabled modules and user role.
  private registerDefaultRoutes(): void {
    this.moduleCompanyView.subscribe(() => {
      this.changeDefaultRoute();
    });
  }

  public changeDefaultRoute(): void {
    const currentRoleKey: string = Global.SESSION.CurrentRole.Key;

    // Company
    switch (currentRoleKey) {
      case 'FullAdmin':
        if (Global.IsTaskCompleted) {
          this.state.registerDefault('tabs.company.salarybatches');
          break;
        }
        this.state.registerDefault('tabs.company.general');
        break;
      case 'ReadOnly':
      case 'SalaryAdmin':
        if (Global.SESSION.IsFirstLogin) {
          this.state.registerDefault('tabs.company.general');
          break;
        }
        this.state.registerDefault('tabs.company.salarybatches');
        break;
      default:
        break;
    }

    // Employee
    switch (currentRoleKey) {
      case 'FullAdmin':
        if (Global.IsEmployeeTaskCompleted) {
          this.state.registerDefault('tabs.employee.payrolldata');
        } else {
          this.state.registerDefault('tabs.employee.general');
        }

        break;
      case 'ReadOnly':
      case 'SalaryAdmin':
        this.state.registerDefault('tabs.employee.payrolldata');
        break;
      case 'Employee':
        const hasManager: boolean = this.sessionService.role.EmployeeWithRoleAsManager;
        if (hasManager) {
          this.state.registerDefault('tabs.employee.time');
        }
        break;
    }

    // Accountant
    this.state.registerDefault('tabs.accountant.companies');

    // Company - Approval
    this.state.registerDefault('tabs.company.approval.timeentry');

    // Company - Configuration
    this.state.registerDefault('tabs.company.configuration.hiringstatus');

    // Company - Blance
    this.state.registerDefault('tabs.company.balances.residualvacation');

    // Self Service
    this.state.registerDefault('tabs.selfservice.payslip');
    if (!this.sessionService.feature.hasModuleId(8)) {
      this.state.registerRedirect('tabs.selfservice.time', 'tabs.selfservice.payslip');
    } else {
      this.state.clearRedirect('tabs.selfservice.time');
    }
  }

  // Company Preferences
  private loadCompanyPreferences(): Observable<void> {
    return this.dataService.Preferences_GetCompanyPreferences().pipe(
      map((data: ISimpleKeyValuePair[]): void => {
        Global.COMPANY_PREFERENCES = data;
      })
    );
  }

  // User Preferences
  private loadUserPreferences(): Observable<void> {
    return this.dataService.Preferences_GetUserPreferences().pipe(
      map((data: ISimpleKeyValuePair[]): void => {
        Global.USER_PREFERENCES = data;
      })
    );
  }

  private checkProduction(): Observable<void> {
    return this.dataService.Miscellaneous_IsProduction().pipe(
      map((isProduction: boolean): void => {
        Global.IsDemo = !isProduction;
      })
    );
  }

  // Modules
  private enabledModulesSubject: BehaviorSubject<IModule[]>;
  public get enabledModules(): Observable<IModule[]> {
    if (!this.enabledModulesSubject) {
      this.enabledModulesSubject = new BehaviorSubject<IModule[]>([]);
      this.loadSessionInfo();
    }

    return this.enabledModulesSubject.asObservable();
  }

  // current Logo
  private currentLogoSubject: BehaviorSubject<string>;
  public get currentLogo(): Observable<string> {
    if (!this.currentLogoSubject) {
      this.loadCurrentLogo();
    }
    return this.currentLogoSubject.asObservable();
  }

  public loadCurrentLogo(): void {
    if (!this.currentLogoSubject) {
      this.currentLogoSubject = new BehaviorSubject<string>(undefined);
    }
    this.dataService.Companies_GetCompanyLogo().subscribe((image: string): void => {
      this.currentLogoSubject.next(image);
    });
  }

  // current User Image
  private currentUserImageSubject: BehaviorSubject<any>;
  public get currentUserImage(): Observable<any> {
    if (!this.currentUserImageSubject) {
      this.loadcurrentUserImage();
    }
    return this.currentUserImageSubject.asObservable();
  }

  public loadcurrentUserImage(): void {
    if (!this.currentUserImageSubject) {
      this.currentUserImageSubject = new BehaviorSubject<any>(undefined);
    }
    this.dataService.Users_GetUserImage().subscribe((image) => {
      this.currentUserImageSubject.next(image);
    });
  }

  // AccountInvoiceTypes
  private accountInvoiceTypesSubject: BehaviorSubject<IAccountInvoiceType[]>;
  public get accountInvoiceTypes(): Observable<IAccountInvoiceType[]> {
    if (!this.accountInvoiceTypesSubject) {
      this.loadAccountInvoiceTypes();
    }
    return this.accountInvoiceTypesSubject.asObservable();
  }

  private loadAccountInvoiceTypes(): void {
    if (!this.accountInvoiceTypesSubject) {
      this.accountInvoiceTypesSubject = new BehaviorSubject<IAccountInvoiceType[]>([]);
    }
    this.dataService.StaticData_GetAccountInvoiceTypes().subscribe((model: IAccountInvoiceType[]): void => {
      this.accountInvoiceTypesSubject.next(model);
    });
  }

  // current Company
  private currentCompanySubject: BehaviorSubject<ICompany>;
  public get currentCompany(): Observable<ICompany> {
    if (!this.currentCompanySubject) {
      this.loadCurrentCompany();
    }
    return this.currentCompanySubject.asObservable();
  }

  private loadCurrentCompany(): void {
    if (!this.currentCompanySubject) {
      this.currentCompanySubject = new BehaviorSubject<ICompany>(undefined);
    }
    this.dataService.Companies_GetCurrent().subscribe((model: ICompany): void => {
      this.currentCompanySubject.next(model);
    });
  }

  // current Account
  private currentAccountSubject: BehaviorSubject<IAccount>;
  public get currentAccount(): Observable<IAccount> {
    if (!this.currentAccountSubject) {
      this.loadCurrentAccount();
    }
    return this.currentAccountSubject.asObservable();
  }

  private loadCurrentAccount(): void {
    if (!this.currentAccountSubject) {
      this.currentAccountSubject = new BehaviorSubject<IAccount>(new Account());
    }
    this.dataService.Account_GetUserAccount().subscribe((model: IAccount): void => {
      this.currentAccountSubject.next(model);
    });
  }

  // company Account
  private companyAccountSubject: BehaviorSubject<IAccount>;
  public get companyAccount(): Observable<IAccount> {
    if (!this.companyAccountSubject) {
      this.loadCompanyAccount();
    }
    return this.companyAccountSubject.asObservable();
  }

  private loadCompanyAccount(): void {
    if (!this.companyAccountSubject) {
      this.companyAccountSubject = new BehaviorSubject<IAccount>(new Account());
    }
    this.dataService.Account_GetCompanyAccount().subscribe((model: IAccount): void => {
      this.companyAccountSubject.next(model);
    });
  }

  // multi company Account
  private multiCompanyAccountSubject: BehaviorSubject<ICompanyAccountView[]>;
  public get multiCompanyAccount(): Observable<ICompanyAccountView[]> {
    if (!this.multiCompanyAccountSubject) {
      this.loadMultiCompanyAccount();
    }
    return this.multiCompanyAccountSubject.asObservable();
  }

  private loadMultiCompanyAccount(): void {
    if (!this.multiCompanyAccountSubject) {
      this.multiCompanyAccountSubject = new BehaviorSubject<ICompanyAccountView[]>([]);
    }
    this.dataService.Account_GetAccountCompanies().subscribe((model: ICompanyAccountView[]): void => {
      this.multiCompanyAccountSubject.next(model);
    });
  }

  // AccountantTabName
  private accountantTabNameSubject: BehaviorSubject<string>;
  public get accountantTabName() {
    if (!this.accountantTabNameSubject) {
      this.accountantTabNameSubject = new BehaviorSubject<string>('');
      this.AccountType.subscribe((accountTypes: IAccountType[]) => {
        if (accountTypes && accountTypes.length > 0) {
          this.currentAccount.subscribe((account: IAccount) => {
            if (account) {
              const accountType: IAccountType = accountTypes.find((t: IAccountType) => t.Id === account.AccountTypeId);
              if (accountType) {
                this.accountantTabNameSubject.next(accountType.Name);
              }
            }
          });
        }
      });
    }

    return this.accountantTabNameSubject.asObservable();
  }

  // ModuleCompanyView
  private moduleCompanyViewSubject: BehaviorSubject<IModuleCompanyView[]>;
  public get moduleCompanyView(): Observable<IModuleCompanyView[]> {
    if (!this.moduleCompanyViewSubject) {
      this.loadModuleCompanyView();
    }

    return this.moduleCompanyViewSubject.asObservable();
  }

  private loadModuleCompanyView(): void {
    if (!this.moduleCompanyViewSubject) {
      this.moduleCompanyViewSubject = new BehaviorSubject<IModuleCompanyView[]>([]);
    }

    this.dataService.Modules_GetModulesForCurrentCompany().subscribe((data: IModuleCompanyView[]) => {
      this.sessionService.feature.modules = data;
      this.moduleCompanyViewSubject.next(data);
      this.registerDefaultRoutes();
    });
  }

  // Company Users
  private companyUsersSubject: BehaviorSubject<ICompanyUser[]>;
  public get companyUsers(): Observable<ICompanyUser[]> {
    if (!this.companyUsersSubject) {
      this.loadCompanyUsers();
    }

    return this.companyUsersSubject.asObservable();
  }

  public loadCompanyUsers(): void {
    if (!this.companyUsersSubject) {
      this.companyUsersSubject = new BehaviorSubject<ICompanyUser[]>([]);
    }

    this.dataService.CompanyUsers_GetCompanyUsers().subscribe((companyUsers: ICompanyUser[]): void => {
      this.companyUsersSubject.next(companyUsers);
    });
  }

  // User Companies
  private usersCompaniesSubject: BehaviorSubject<ICompanyUserView[]>;
  public get usersCompanies(): Observable<ICompanyUserView[]> {
    if (!this.usersCompaniesSubject) {
      this.loadUsersCompanies();
    }

    return this.usersCompaniesSubject.asObservable();
  }

  public loadUsersCompanies(): void {
    if (!this.usersCompaniesSubject) {
      this.usersCompaniesSubject = new BehaviorSubject<ICompanyUserView[]>([]);
    }

    this.dataService.Users_GetCompanies().subscribe((usersCompanies: ICompanyUserView[]): void => {
      this.usersCompaniesSubject.next(usersCompanies);
    });
  }

  // User Companies Simple
  private companiesSimpleSubject: BehaviorSubject<ISimpleCompany[]>;
  public get companiesSimple(): Observable<ISimpleCompany[]> {
    if (!this.companiesSimpleSubject) {
      this.loadCompaniesSimple();
    }

    return this.companiesSimpleSubject.asObservable();
  }

  public loadCompaniesSimple(): void {
    if (!this.usersCompaniesSubject) {
      this.companiesSimpleSubject = new BehaviorSubject<ISimpleCompany[]>([]);
    }

    this.dataService.Companies_GetAllSimple().subscribe((usersCompanies: ISimpleCompany[]): void => {
      this.companiesSimpleSubject.next(usersCompanies);
    });
  }

  public async loadCompaniesSimpleAsync() {
    if (!this.sessionService.checkIsAliveError) {
      this.companiesSimpleSubject.next(await this.dataService.Companies_GetAllSimple().toPromise());
    }
  }

  // Bank
  private bankSubject: BehaviorSubject<IBank[]>;
  public get Bank(): Observable<IBank[]> {
    if (!this.bankSubject) {
      this.loadBank();
    }

    return this.bankSubject.asObservable();
  }

  public loadBank(): void {
    if (!this.bankSubject) {
      this.bankSubject = new BehaviorSubject<IBank[]>([]);
    }

    this.dataService.StaticData_GetBanks().subscribe((data: IBank[]): void => {
      this.bankSubject.next(data);
    });
  }

  // BankHoliday
  private bankHolidaySubject: BehaviorSubject<IBankHoliday[]>;
  public get BankHoliday(): Observable<IBankHoliday[]> {
    if (!this.bankHolidaySubject) {
      this.loadBankHoliday();
    }

    return this.bankHolidaySubject.asObservable();
  }

  public loadBankHoliday(): void {
    if (!this.bankHolidaySubject) {
      this.bankHolidaySubject = new BehaviorSubject<IBankHoliday[]>([]);
    }

    this.dataService.StaticData_GetBankHolidaies().subscribe((data: IBankHoliday[]): void => {
      this.bankHolidaySubject.next(data);
    });
  }

  // BillingPrinciple
  private billingPrincipleSubject: BehaviorSubject<IBillingPrinciple[]>;
  public get BillingPrinciple(): Observable<IBillingPrinciple[]> {
    if (!this.billingPrincipleSubject) {
      this.loadBillingPrinciple();
    }

    return this.billingPrincipleSubject.asObservable();
  }

  public loadBillingPrinciple(): void {
    if (!this.billingPrincipleSubject) {
      this.billingPrincipleSubject = new BehaviorSubject<IBillingPrinciple[]>([]);
    }

    this.dataService.StaticData_GetBillingPrinciples().subscribe((data: IBillingPrinciple[]): void => {
      this.billingPrincipleSubject.next(data);
    });
  }

  // Departments
  private departmenstSubject: BehaviorSubject<IDepartment[]>;
  public get departments(): Observable<IDepartment[]> {
    if (!this.departmenstSubject) {
      this.loadDepartments();
    }

    return this.departmenstSubject.asObservable();
  }

  public loadDepartments(): void {
    if (!this.departmenstSubject) {
      this.departmenstSubject = new BehaviorSubject<IDepartment[]>([]);
    }

    this.dataService.Companies_GetDepartments().subscribe((departments: IDepartment[]): void => {
      this.departmenstSubject.next(departments);
    });
  }

  public loadDepartmentsAsync() {
    if (!this.departmenstSubject) {
      this.departmenstSubject = new BehaviorSubject<IDepartment[]>([]);
    }

    return this.dataService.Companies_GetDepartments().pipe(
      tap((departments: IDepartment[]) => {
        this.departmenstSubject.next(departments);
      })
    );
  }

  // Languages
  private languagesSubject: BehaviorSubject<ILanguage[]>;
  public get languages(): Observable<ILanguage[]> {
    if (!this.languagesSubject) {
      this.loadLanguages();
    }

    return this.languagesSubject.asObservable();
  }

  private loadLanguages(): void {
    if (!this.languagesSubject) {
      this.languagesSubject = new BehaviorSubject<ILanguage[]>([]);
    }

    this.dataService.Miscellaneous_GetLanguages().subscribe((data: ILanguage[]): void => {
      this.languagesSubject.next(data);
    });
  }

  //
  // Static data
  //

  // AccountType
  private accountTypeSubject: BehaviorSubject<IAccountType[]>;
  public get AccountType(): Observable<IAccountType[]> {
    if (!this.accountTypeSubject) {
      this.loadAccountType();
    }

    return this.accountTypeSubject.asObservable();
  }

  private loadAccountType(): void {
    if (!this.accountTypeSubject) {
      this.accountTypeSubject = new BehaviorSubject<IAccountType[]>([]);
    }

    this.dataService.StaticData_GetAccountTypes().subscribe((data: IAccountType[]): void => {
      this.accountTypeSubject.next(data);
    });
  }

  // City
  private citySubject: BehaviorSubject<ICity[]>;
  public get City(): Observable<ICity[]> {
    if (!this.citySubject) {
      this.loadCity();
    }

    return this.citySubject.asObservable();
  }

  private loadCity(): void {
    if (!this.citySubject) {
      this.citySubject = new BehaviorSubject<ICity[]>([]);
    }

    this.dataService.StaticData_GetCities().subscribe((data: ICity[]): void => {
      this.citySubject.next(data);
    });
  }

  // Employement User Title
  private employmentTitleSubject: BehaviorSubject<string[]>;
  public get employmentTitle(): Observable<string[]> {
    if (!this.employmentTitleSubject) {
      this.loadEmploymentTitle();
    }
    return this.employmentTitleSubject.asObservable();
  }

  private loadEmploymentTitle(): void {
    if (!this.employmentTitleSubject) {
      this.employmentTitleSubject = new BehaviorSubject<string[]>([]);
    }

    this.dataService.Employments_GetUsedTitles().subscribe((model: string[]): void => {
      this.employmentTitleSubject.next(model);
    });
  }

  // CompanyType
  private companyTypeSubject: BehaviorSubject<ICompanyType[]>;
  public get CompanyType(): Observable<ICompanyType[]> {
    if (!this.companyTypeSubject) {
      this.loadCompanyType();
    }

    return this.companyTypeSubject.asObservable();
  }

  private loadCompanyType(): void {
    if (!this.companyTypeSubject) {
      this.companyTypeSubject = new BehaviorSubject<ICompanyType[]>([]);
    }

    this.dataService.StaticData_GetCompanyTypes().subscribe((data: ICompanyType[]): void => {
      this.companyTypeSubject.next(data);
    });
  }

  // Country
  private countrySubject: BehaviorSubject<ICountry[]>;
  public get Country(): Observable<ICountry[]> {
    if (!this.countrySubject) {
      this.loadCountry();
    }

    return this.countrySubject.asObservable();
  }

  private loadCountry(): void {
    if (!this.countrySubject) {
      this.countrySubject = new BehaviorSubject<ICountry[]>([]);
    }

    this.dataService.StaticData_GetCountries().subscribe((data: ICountry[]): void => {
      this.countrySubject.next(data);
    });
  }

  // Currency
  private currencySubject: BehaviorSubject<ICurrency[]>;
  public get Currency(): Observable<ICurrency[]> {
    if (!this.currencySubject) {
      this.loadCurrency();
    }

    return this.currencySubject.asObservable();
  }

  private loadCurrency(): void {
    if (!this.currencySubject) {
      this.currencySubject = new BehaviorSubject<ICurrency[]>([]);
    }

    this.dataService.StaticData_GetCurrencies().subscribe((data: ICurrency[]): void => {
      this.currencySubject.next(data);
    });
  }

  // ExternalSystem
  private externalSystemSubject: BehaviorSubject<IExternalSystem[]>;
  public get ExternalSystem(): Observable<IExternalSystem[]> {
    if (!this.externalSystemSubject) {
      this.loadExternalSystem();
    }

    return this.externalSystemSubject.asObservable();
  }

  private loadExternalSystem(): void {
    if (!this.externalSystemSubject) {
      this.externalSystemSubject = new BehaviorSubject<IExternalSystem[]>([]);
    }

    this.dataService.StaticData_GetExternalSystems().subscribe((data: IExternalSystem[]): void => {
      this.externalSystemSubject.next(data);
    });
  }

  // ExternalSystemConfiguration
  private externalSystemConfigurationSubject: BehaviorSubject<IExternalSystemConfiguration[]>;
  public get ExternalSystemConfiguration(): Observable<IExternalSystemConfiguration[]> {
    if (!this.externalSystemConfigurationSubject) {
      this.loadExternalSystemConfiguration();
    }

    return this.externalSystemConfigurationSubject.asObservable();
  }

  public loadExternalSystemConfiguration(): void {
    if (!this.externalSystemConfigurationSubject) {
      this.externalSystemConfigurationSubject = new BehaviorSubject<IExternalSystemConfiguration[]>([]);
    }

    this.dataService
      .StaticData_GetExternalSystemConfigurations()
      .subscribe((data: IExternalSystemConfiguration[]): void => {
        this.externalSystemConfigurationSubject.next(data);
      });
  }

  // ExternalSystemCredential
  private externalSystemCredentialSubject: BehaviorSubject<IExternalSystemCredential[]>;
  public get ExternalSystemCredential(): Observable<IExternalSystemCredential[]> {
    if (!this.externalSystemCredentialSubject) {
      this.loadExternalSystemCredential();
    }

    return this.externalSystemCredentialSubject.asObservable();
  }

  public loadExternalSystemCredential(): void {
    if (!this.externalSystemCredentialSubject) {
      this.externalSystemCredentialSubject = new BehaviorSubject<IExternalSystemCredential[]>([]);
    }

    this.dataService.StaticData_GetExternalSystemCredentials().subscribe((data: IExternalSystemCredential[]): void => {
      this.externalSystemCredentialSubject.next(data);
    });
  }

  // ExternalSystemOperation
  private externalSystemOperationSubject: BehaviorSubject<IExternalSystemOperation[]>;
  public get ExternalSystemOperation(): Observable<IExternalSystemOperation[]> {
    if (!this.externalSystemOperationSubject) {
      this.loadExternalSystemOperation();
    }

    return this.externalSystemOperationSubject.asObservable();
  }

  private loadExternalSystemOperation(): void {
    if (!this.externalSystemOperationSubject) {
      this.externalSystemOperationSubject = new BehaviorSubject<IExternalSystemOperation[]>([]);
    }

    this.dataService.StaticData_GetExternalSystemOperations().subscribe((data: IExternalSystemOperation[]): void => {
      this.externalSystemOperationSubject.next(data);
    });
  }

  // FinanceAccountType
  private financeAccountTypeSubject: BehaviorSubject<IFinanceAccountType[]>;
  public get FinanceAccountType(): Observable<IFinanceAccountType[]> {
    if (!this.financeAccountTypeSubject) {
      this.loadFinanceAccountType();
    }

    return this.financeAccountTypeSubject.asObservable();
  }

  private loadFinanceAccountType(): void {
    if (!this.financeAccountTypeSubject) {
      this.financeAccountTypeSubject = new BehaviorSubject<IFinanceAccountType[]>([]);
    }

    this.dataService.StaticData_GetFinanceAccountTypes().subscribe((data: IFinanceAccountType[]): void => {
      this.financeAccountTypeSubject.next(data);
    });
  }

  // IncomeType
  private incomeTypeSubject: BehaviorSubject<IIncomeType[]>;
  public get IncomeType(): Observable<IIncomeType[]> {
    if (!this.incomeTypeSubject) {
      this.loadIncomeType();
    }

    return this.incomeTypeSubject.asObservable();
  }

  private loadIncomeType(): void {
    if (!this.incomeTypeSubject) {
      this.incomeTypeSubject = new BehaviorSubject<IIncomeType[]>([]);
    }

    this.dataService.StaticData_GetIncomeTypes().subscribe((data: IIncomeType[]): void => {
      this.incomeTypeSubject.next(data);
    });
  }

  // IntervalType
  private intervalTypeSubject: BehaviorSubject<IIntervalType[]>;
  public get IntervalType(): Observable<IIntervalType[]> {
    if (!this.intervalTypeSubject) {
      this.loadIntervalType();
    }

    return this.intervalTypeSubject.asObservable();
  }

  private loadIntervalType(): void {
    if (!this.intervalTypeSubject) {
      this.intervalTypeSubject = new BehaviorSubject<IIntervalType[]>([]);
    }

    this.dataService.StaticData_GetIntervalTypes().subscribe((data: IIntervalType[]): void => {
      this.intervalTypeSubject.next(data);
    });
  }

  // IntegrationStatus
  private integrationStatusSubject: BehaviorSubject<IIntegrationStatus[]>;
  public get IntegrationStatus(): Observable<IIntegrationStatus[]> {
    if (!this.integrationStatusSubject) {
      this.loadIntegrationStatus();
    }

    return this.integrationStatusSubject.asObservable();
  }

  private loadIntegrationStatus(): void {
    if (!this.integrationStatusSubject) {
      this.integrationStatusSubject = new BehaviorSubject<IIntegrationStatus[]>([]);
    }

    this.dataService.StaticData_GetIntegrationStatuses().subscribe((data: IIntegrationStatus[]): void => {
      this.integrationStatusSubject.next(data);
    });
  }

  // ModulePackage
  private modulePackageSubject: BehaviorSubject<IModulePackage[]>;
  public get ModulePackage(): Observable<IModulePackage[]> {
    if (!this.modulePackageSubject) {
      this.loadModulePackage();
    }

    return this.modulePackageSubject.asObservable();
  }

  private loadModulePackage(): void {
    if (!this.modulePackageSubject) {
      this.modulePackageSubject = new BehaviorSubject<IModulePackage[]>([]);
    }

    this.dataService.StaticData_GetModulePackages().subscribe((data: IModulePackage[]): void => {
      this.modulePackageSubject.next(data);
    });
  }

  // Month
  private monthSubject: BehaviorSubject<IMonth[]>;
  public get Month(): Observable<IMonth[]> {
    if (!this.monthSubject) {
      this.loadMonth();
    }

    return this.monthSubject.asObservable();
  }

  private loadMonth(): void {
    if (!this.monthSubject) {
      this.monthSubject = new BehaviorSubject<IMonth[]>([]);
    }

    this.dataService.StaticData_GetMonths().subscribe((data: IMonth[]): void => {
      this.monthSubject.next(data);
    });
  }

  // Municipality
  private municipalitySubject: BehaviorSubject<IMunicipality[]>;
  public get Municipality(): Observable<IMunicipality[]> {
    if (!this.municipalitySubject) {
      this.loadMunicipality();
    }

    return this.municipalitySubject.asObservable();
  }

  private loadMunicipality(): void {
    if (!this.municipalitySubject) {
      this.municipalitySubject = new BehaviorSubject<IMunicipality[]>([]);
    }

    this.dataService.StaticData_GetMunicipalities().subscribe((data: IMunicipality[]): void => {
      this.municipalitySubject.next(data);
    });
  }

  // OvertimeSupplementPrinciple
  private overtimeSupplementPrincipleSubject: BehaviorSubject<IOvertimeSupplementPrinciple[]>;
  public get OvertimeSupplementPrinciple(): Observable<IOvertimeSupplementPrinciple[]> {
    if (!this.overtimeSupplementPrincipleSubject) {
      this.loadOvertimeSupplementPrinciple();
    }

    return this.overtimeSupplementPrincipleSubject.asObservable();
  }

  private loadOvertimeSupplementPrinciple(): void {
    if (!this.overtimeSupplementPrincipleSubject) {
      this.overtimeSupplementPrincipleSubject = new BehaviorSubject<IOvertimeSupplementPrinciple[]>([]);
    }

    this.dataService
      .StaticData_GetOvertimeSupplementPrinciples()
      .subscribe((data: IOvertimeSupplementPrinciple[]): void => {
        this.overtimeSupplementPrincipleSubject.next(data);
      });
  }

  // PaymentMethod
  private paymentMethodSubject: BehaviorSubject<IPaymentMethod[]>;
  public get PaymentMethod(): Observable<IPaymentMethod[]> {
    if (!this.paymentMethodSubject) {
      this.loadPaymentMethod();
    }

    return this.paymentMethodSubject.asObservable();
  }

  private loadPaymentMethod(): void {
    if (!this.paymentMethodSubject) {
      this.paymentMethodSubject = new BehaviorSubject<IPaymentMethod[]>([]);
    }

    this.dataService.StaticData_GetPaymentMethods().subscribe((data: IPaymentMethod[]): void => {
      this.paymentMethodSubject.next(data);
    });
  }

  // PensionBaseCalculationMethod
  private pensionBaseCalculationMethodSubject: BehaviorSubject<IBaseCalculationMethod[]>;
  public get PensionBaseCalculationMethod(): Observable<IBaseCalculationMethod[]> {
    if (!this.pensionBaseCalculationMethodSubject) {
      this.loadPensionBaseCalculationMethod();
    }

    return this.pensionBaseCalculationMethodSubject.asObservable();
  }

  private loadPensionBaseCalculationMethod(): void {
    if (!this.pensionBaseCalculationMethodSubject) {
      this.pensionBaseCalculationMethodSubject = new BehaviorSubject<IBaseCalculationMethod[]>([]);
    }

    this.dataService.StaticData_GetBaseCalculationMethods().subscribe((data: IBaseCalculationMethod[]): void => {
      this.pensionBaseCalculationMethodSubject.next(data);
    });
  }

  // GetExtraVacationHoursEarningBehaviors
  private extraVacationHoursEarningBehaviorsSubject: BehaviorSubject<IExtraVacationHoursEarningBehavior[]>;
  public get ExtraVacationHoursEarningBehaviors(): Observable<IExtraVacationHoursEarningBehavior[]> {
    if (!this.extraVacationHoursEarningBehaviorsSubject) {
      this.loadextraVacationHoursEarningBehaviors();
    }

    return this.extraVacationHoursEarningBehaviorsSubject.asObservable();
  }

  private loadextraVacationHoursEarningBehaviors() {
    if (!this.extraVacationHoursEarningBehaviorsSubject) {
      this.extraVacationHoursEarningBehaviorsSubject = new BehaviorSubject<IExtraVacationHoursEarningBehavior[]>([]);
    }

    this.dataService
      .StaticData_GetExtraVacationHoursEarningBehaviors()
      .subscribe((data: IExtraVacationHoursEarningBehavior[]): void => {
        this.extraVacationHoursEarningBehaviorsSubject.next(data);
      });
  }

  // PensionProvider
  private pensionProviderSubject: BehaviorSubject<IPensionProvider[]>;
  public get PensionProvider(): Observable<IPensionProvider[]> {
    if (!this.pensionProviderSubject) {
      this.loadPensionProvider();
    }

    return this.pensionProviderSubject.asObservable();
  }

  private loadPensionProvider(): void {
    if (!this.pensionProviderSubject) {
      this.pensionProviderSubject = new BehaviorSubject<IPensionProvider[]>([]);
    }

    this.dataService.StaticData_GetPensionProviders().subscribe((data: IPensionProvider[]): void => {
      this.pensionProviderSubject.next(data);
    });
  }

  // ReportArea
  private reportAreaSubject: BehaviorSubject<IReportArea[]>;
  public get ReportArea(): Observable<IReportArea[]> {
    if (!this.reportAreaSubject) {
      this.loadReportArea();
    }

    return this.reportAreaSubject.asObservable();
  }

  private loadReportArea(): void {
    if (!this.reportAreaSubject) {
      this.reportAreaSubject = new BehaviorSubject<IReportArea[]>([]);
    }

    this.dataService.StaticData_GetReportAreas().subscribe((data: IReportArea[]): void => {
      this.reportAreaSubject.next(data);
    });
  }

  // ReportParameter
  private reportParameterSubject: BehaviorSubject<IReportParameter[]>;
  public get ReportParameter(): Observable<IReportParameter[]> {
    if (!this.reportParameterSubject) {
      this.loadReportParameter();
    }

    return this.reportParameterSubject.asObservable();
  }

  private loadReportParameter(): void {
    if (!this.reportParameterSubject) {
      this.reportParameterSubject = new BehaviorSubject<IReportParameter[]>([]);
    }

    this.dataService.StaticData_GetReportParameters().subscribe((data: IReportParameter[]): void => {
      this.reportParameterSubject.next(data);
    });
  }

  // Role
  private roleSubject: BehaviorSubject<IRole[]>;
  public get Role(): Observable<IRole[]> {
    if (!this.roleSubject) {
      this.loadRole();
    }

    return this.roleSubject.asObservable();
  }

  private loadRole(): void {
    if (!this.roleSubject) {
      this.roleSubject = new BehaviorSubject<IRole[]>([]);
    }

    this.dataService.StaticData_GetRoles().subscribe((data: IRole[]): void => {
      this.roleSubject.next(data);
    });
  }

  // AccountantRole
  private accountantRoleSubject: BehaviorSubject<IAccountRole[]>;
  public get AccountantRole(): Observable<IAccountRole[]> {
    if (!this.accountantRoleSubject) {
      this.loadAccountantRole();
    }

    return this.accountantRoleSubject.asObservable();
  }

  private loadAccountantRole(): void {
    if (!this.accountantRoleSubject) {
      this.accountantRoleSubject = new BehaviorSubject<IAccountRole[]>([]);
    }

    this.dataService.StaticData_GetAccountRoles().subscribe((data: IAccountRole[]): void => {
      this.accountantRoleSubject.next(data);
    });
  }

  // SalaryCycle
  private salaryCycleSubject: BehaviorSubject<ISalaryCycle[]>;
  public get SalaryCycle(): Observable<ISalaryCycle[]> {
    if (!this.salaryCycleSubject) {
      this.loadSalaryCycle();
    }

    return this.salaryCycleSubject.asObservable();
  }

  private loadSalaryCycle(): void {
    if (!this.salaryCycleSubject) {
      this.salaryCycleSubject = new BehaviorSubject<ISalaryCycle[]>([]);
    }

    this.dataService.StaticData_GetSalaryCycles().subscribe((data: ISalaryCycle[]): void => {
      this.salaryCycleSubject.next(data);
    });
  }

  // SalaryCycleUsed
  private salaryCycleUsedSubject: BehaviorSubject<ISalaryCycle[]>;
  public get SalaryCycleUsed(): Observable<ISalaryCycle[]> {
    if (!this.salaryCycleUsedSubject) {
      this.loadSalaryCycleUsed();
    }

    return this.salaryCycleUsedSubject.asObservable();
  }

  private loadSalaryCycleUsed(): void {
    if (!this.salaryCycleUsedSubject) {
      this.salaryCycleUsedSubject = new BehaviorSubject<ISalaryCycle[]>([]);
    }

    this.dataService.Miscellaneous_GetUsedSalaryCycles().subscribe((data: ISalaryCycle[]): void => {
      this.salaryCycleUsedSubject.next(data);
    });
  }

  public async loadSalaryCycleUsedAsync() {
    this.salaryCycleUsedSubject.next(await this.dataService.Miscellaneous_GetUsedSalaryCycles().toPromise());
  }

  // SalarySummaryCategory
  private salarySummaryCategorySubject: BehaviorSubject<ISalarySummaryCategory[]>;
  public get SalarySummaryCategory(): Observable<ISalarySummaryCategory[]> {
    if (!this.salarySummaryCategorySubject) {
      this.loadSalarySummaryCategory();
    }

    return this.salarySummaryCategorySubject.asObservable();
  }

  private loadSalarySummaryCategory(): void {
    if (!this.salarySummaryCategorySubject) {
      this.salarySummaryCategorySubject = new BehaviorSubject<ISalarySummaryCategory[]>([]);
    }

    this.dataService.StaticData_GetSalarySummaryCategories().subscribe((data: ISalarySummaryCategory[]): void => {
      this.salarySummaryCategorySubject.next(data);
    });
  }

  // SalaryRecordPersistenceType
  private salaryRecordPersistenceTypeSubject: BehaviorSubject<ISalaryRecordPersistenceType[]>;
  public get SalaryRecordPersistenceType(): Observable<ISalaryRecordPersistenceType[]> {
    if (!this.salaryRecordPersistenceTypeSubject) {
      this.loadSalaryRecordPersistenceType();
    }

    return this.salaryRecordPersistenceTypeSubject.asObservable();
  }

  private loadSalaryRecordPersistenceType(): void {
    if (!this.salaryRecordPersistenceTypeSubject) {
      this.salaryRecordPersistenceTypeSubject = new BehaviorSubject<ISalaryRecordPersistenceType[]>([]);
    }

    this.dataService
      .StaticData_GetSalaryRecordPersistenceTypes()
      .subscribe((data: ISalaryRecordPersistenceType[]): void => {
        this.salaryRecordPersistenceTypeSubject.next(data);
      });
  }

  // SalaryTypeCategory
  private salaryTypeCategorySubject: BehaviorSubject<ISalaryTypeCategory[]>;
  public get SalaryTypeCategory(): Observable<ISalaryTypeCategory[]> {
    if (!this.salaryTypeCategorySubject) {
      this.loadSalaryTypeCategory();
    }

    return this.salaryTypeCategorySubject.asObservable();
  }

  private loadSalaryTypeCategory(): void {
    if (!this.salaryTypeCategorySubject) {
      this.salaryTypeCategorySubject = new BehaviorSubject<ISalaryTypeCategory[]>([]);
    }

    this.dataService.StaticData_GetSalaryTypeCategories().subscribe((data: ISalaryTypeCategory[]): void => {
      this.salaryTypeCategorySubject.next(data);
    });
  }

  // StatisticsEmploymentPrinciple
  private statisticsEmploymentPrincipleSubject: BehaviorSubject<IStatisticsEmploymentPrinciple[]>;
  public get StatisticsEmploymentPrinciple(): Observable<IStatisticsEmploymentPrinciple[]> {
    if (!this.statisticsEmploymentPrincipleSubject) {
      this.loadStatisticsEmploymentPrinciple();
    }

    return this.statisticsEmploymentPrincipleSubject.asObservable();
  }

  private loadStatisticsEmploymentPrinciple(): void {
    if (!this.statisticsEmploymentPrincipleSubject) {
      this.statisticsEmploymentPrincipleSubject = new BehaviorSubject<IStatisticsEmploymentPrinciple[]>([]);
    }

    this.dataService
      .StaticData_GetStatisticsEmploymentPrinciples()
      .subscribe((data: IStatisticsEmploymentPrinciple[]): void => {
        this.statisticsEmploymentPrincipleSubject.next(data);
      });
  }

  // StatisticsEmploymentTerms
  private statisticsEmploymentTermsSubject: BehaviorSubject<IStatisticsEmploymentTerms[]>;
  public get StatisticsEmploymentTerms(): Observable<IStatisticsEmploymentTerms[]> {
    if (!this.statisticsEmploymentTermsSubject) {
      this.loadStatisticsEmploymentTerms();
    }

    return this.statisticsEmploymentTermsSubject.asObservable();
  }

  private loadStatisticsEmploymentTerms(): void {
    if (!this.statisticsEmploymentTermsSubject) {
      this.statisticsEmploymentTermsSubject = new BehaviorSubject<IStatisticsEmploymentTerms[]>([]);
    }

    this.dataService
      .StaticData_GetStatisticsEmploymentTermses()
      .subscribe((data: IStatisticsEmploymentTerms[]): void => {
        this.statisticsEmploymentTermsSubject.next(data);
      });
  }

  // StatisticsJobStatus
  private statisticsJobStatusSubject: BehaviorSubject<IStatisticsJobStatus[]>;
  public get StatisticsJobStatus(): Observable<IStatisticsJobStatus[]> {
    if (!this.statisticsJobStatusSubject) {
      this.loadStatisticsJobStatus();
    }

    return this.statisticsJobStatusSubject.asObservable();
  }

  private loadStatisticsJobStatus(): void {
    if (!this.statisticsJobStatusSubject) {
      this.statisticsJobStatusSubject = new BehaviorSubject<IStatisticsJobStatus[]>([]);
    }

    this.dataService.StaticData_GetStatisticsJobStatuses().subscribe((data: IStatisticsJobStatus[]): void => {
      this.statisticsJobStatusSubject.next(data);
    });
  }

  // StatisticsRecipient
  private statisticsRecipientSubject: BehaviorSubject<IStatisticsRecipient[]>;
  public get StatisticsRecipient(): Observable<IStatisticsRecipient[]> {
    if (!this.statisticsRecipientSubject) {
      this.loadStatisticsRecipient();
    }

    return this.statisticsRecipientSubject.asObservable();
  }

  private loadStatisticsRecipient(): void {
    if (!this.statisticsRecipientSubject) {
      this.statisticsRecipientSubject = new BehaviorSubject<IStatisticsRecipient[]>([]);
    }

    this.dataService.StaticData_GetStatisticsRecipients().subscribe((data: IStatisticsRecipient[]): void => {
      this.statisticsRecipientSubject.next(data);
    });
  }

  // StatisticsSalaryPrinciple
  private statisticsSalaryPrincipleSubject: BehaviorSubject<IStatisticsSalaryPrinciple[]>;
  public get StatisticsSalaryPrinciple(): Observable<IStatisticsSalaryPrinciple[]> {
    if (!this.statisticsSalaryPrincipleSubject) {
      this.loadStatisticsSalaryPrinciple();
    }

    return this.statisticsSalaryPrincipleSubject.asObservable();
  }

  private loadStatisticsSalaryPrinciple(): void {
    if (!this.statisticsSalaryPrincipleSubject) {
      this.statisticsSalaryPrincipleSubject = new BehaviorSubject<IStatisticsSalaryPrinciple[]>([]);
    }

    this.dataService
      .StaticData_GetStatisticsSalaryPrinciples()
      .subscribe((data: IStatisticsSalaryPrinciple[]): void => {
        this.statisticsSalaryPrincipleSubject.next(data);
      });
  }

  // TaxCardType
  private taxCardTypeSubject: BehaviorSubject<ITaxCardType[]>;
  public get TaxCardType(): Observable<ITaxCardType[]> {
    if (!this.taxCardTypeSubject) {
      this.loadTaxCardType();
    }

    return this.taxCardTypeSubject.asObservable();
  }

  private loadTaxCardType(): void {
    if (!this.taxCardTypeSubject) {
      this.taxCardTypeSubject = new BehaviorSubject<ITaxCardType[]>([]);
    }

    this.dataService.StaticData_GetTaxCardTypes().subscribe((data: ITaxCardType[]): void => {
      this.taxCardTypeSubject.next(data);
    });
  }

  // TimeEntryRecordAcrossSalaryPeriodStrategy
  private timeEntryRecordAcrossSalaryPeriodStrategySubject: BehaviorSubject<
    ITimeEntryRecordAcrossSalaryPeriodStrategy[]
  >;
  public get TimeEntryRecordAcrossSalaryPeriodStrategy(): Observable<ITimeEntryRecordAcrossSalaryPeriodStrategy[]> {
    if (!this.timeEntryRecordAcrossSalaryPeriodStrategySubject) {
      this.loadTimeEntryRecordAcrossSalaryPeriodStrategy();
    }

    return this.timeEntryRecordAcrossSalaryPeriodStrategySubject.asObservable();
  }

  private loadTimeEntryRecordAcrossSalaryPeriodStrategy(): void {
    if (!this.timeEntryRecordAcrossSalaryPeriodStrategySubject) {
      this.timeEntryRecordAcrossSalaryPeriodStrategySubject = new BehaviorSubject<
        ITimeEntryRecordAcrossSalaryPeriodStrategy[]
      >([]);
    }

    this.dataService
      .StaticData_GetTimeEntryRecordAcrossSalaryPeriodStrategies()
      .subscribe((data: ITimeEntryRecordAcrossSalaryPeriodStrategy[]): void => {
        this.timeEntryRecordAcrossSalaryPeriodStrategySubject.next(data);
      });
  }

  // TimeEntryStatus
  private timeEntryStatusSubject: BehaviorSubject<ITimeEntryStatus[]>;
  public get TimeEntryStatus(): Observable<ITimeEntryStatus[]> {
    if (!this.timeEntryStatusSubject) {
      this.loadTimeEntryStatus();
    }

    return this.timeEntryStatusSubject.asObservable();
  }

  private loadTimeEntryStatus(): void {
    if (!this.timeEntryStatusSubject) {
      this.timeEntryStatusSubject = new BehaviorSubject<ITimeEntryStatus[]>([]);
    }

    this.dataService.StaticData_GetTimeEntryStatuses().subscribe((data: ITimeEntryStatus[]): void => {
      this.timeEntryStatusSubject.next(data);
    });
  }

  // SalaryRecordsOverviewDisplayMode
  private salaryRecordsOverviewDisplayModeSubject: BehaviorSubject<ISalaryRecordsOverviewDisplayMode[]>;
  public get SalaryRecordsOverviewDisplayMode(): Observable<ISalaryRecordsOverviewDisplayMode[]> {
    if (!this.salaryRecordsOverviewDisplayModeSubject) {
      this.loadSalaryRecordsOverviewDisplayMode();
    }

    return this.salaryRecordsOverviewDisplayModeSubject.asObservable();
  }

  private loadSalaryRecordsOverviewDisplayMode(): void {
    if (!this.salaryRecordsOverviewDisplayModeSubject) {
      this.salaryRecordsOverviewDisplayModeSubject = new BehaviorSubject<ISalaryRecordsOverviewDisplayMode[]>([]);
    }

    this.dataService
      .StaticData_GetSalaryRecordsOverviewDisplayModes()
      .subscribe((data: ISalaryRecordsOverviewDisplayMode[]): void => {
        this.salaryRecordsOverviewDisplayModeSubject.next(data);
      });
  }

  // UnitTypes
  private unitTypesSubject: BehaviorSubject<IUnitType[]>;
  public get UnitTypes(): Observable<IUnitType[]> {
    if (!this.unitTypesSubject) {
      this.loadUnitTypes();
    }

    return this.unitTypesSubject.asObservable();
  }

  private loadUnitTypes(): void {
    if (!this.unitTypesSubject) {
      this.unitTypesSubject = new BehaviorSubject<IUnitType[]>([]);
    }

    this.dataService.StaticData_GetUnitTypes().subscribe((data: IUnitType[]): void => {
      this.unitTypesSubject.next(data);
      Global.UNIT_TYPES = data;
    });
  }

  // VacationProvider
  private vacationProviderSubject: BehaviorSubject<IVacationProvider[]>;
  public get VacationProvider(): Observable<IVacationProvider[]> {
    if (!this.vacationProviderSubject) {
      this.loadVacationProvider();
    }

    return this.vacationProviderSubject.asObservable();
  }

  private loadVacationProvider(): void {
    if (!this.vacationProviderSubject) {
      this.vacationProviderSubject = new BehaviorSubject<IVacationProvider[]>([]);
    }

    this.dataService.StaticData_GetVacationProviders().subscribe((data: IVacationProvider[]): void => {
      this.vacationProviderSubject.next(data);
    });
  }

  // Danish VacationProvider
  private vacationSupplementPayoutMethodDKSubject: BehaviorSubject<IVacationSupplementPayoutMethodDK[]>;
  public get VacationSupplementPayoutMethodDK(): Observable<IVacationSupplementPayoutMethodDK[]> {
    if (!this.vacationSupplementPayoutMethodDKSubject) {
      this.loadVacationSupplementPayoutMethodDK();
    }
    return this.vacationSupplementPayoutMethodDKSubject.asObservable();
  }

  private loadVacationSupplementPayoutMethodDK() {
    if (!this.vacationSupplementPayoutMethodDKSubject) {
      this.vacationSupplementPayoutMethodDKSubject = new BehaviorSubject<IVacationSupplementPayoutMethodDK[]>([]);
    }
    this.dataService
      .StaticData_GetVacationSupplementPayoutMethodDKs()
      .subscribe((data: IVacationSupplementPayoutMethodDK[]) => {
        this.vacationSupplementPayoutMethodDKSubject.next(data);
      });
  }

  // VacationType
  private vacationTypeSubject: BehaviorSubject<IVacationType[]>;
  public get VacationType(): Observable<IVacationType[]> {
    if (!this.vacationTypeSubject) {
      this.loadVacationType();
    }

    return this.vacationTypeSubject.asObservable();
  }

  private loadVacationType(): void {
    if (!this.vacationTypeSubject) {
      this.vacationTypeSubject = new BehaviorSubject<IVacationType[]>([]);
    }

    this.dataService.StaticData_GetVacationTypes().subscribe((data: IVacationType[]): void => {
      this.vacationTypeSubject.next(data);
    });
  }

  // AddtionalVacations
  private additionalVacationsSubject: BehaviorSubject<IAdditionalVacationTerminationMethod[]>;
  public get AdditionalVacations(): Observable<IAdditionalVacationTerminationMethod[]> {
    if (!this.additionalVacationsSubject) {
      this.loadAdditionalVacations();
    }

    return this.additionalVacationsSubject.asObservable();
  }

  private loadAdditionalVacations(): void {
    if (!this.additionalVacationsSubject) {
      this.additionalVacationsSubject = new BehaviorSubject<IAdditionalVacationTerminationMethod[]>([]);
    }

    this.dataService
      .StaticData_GetAdditionalVacationTerminationMethods()
      .subscribe((data: IAdditionalVacationTerminationMethod[]): void => {
        this.additionalVacationsSubject.next(data);
      });
  }

  // VacationPeriod
  private vacationPeriodSubject: BehaviorSubject<IBalancePeriod[]>;
  public get VacationPeriod(): Observable<IBalancePeriod[]> {
    if (!this.vacationPeriodSubject) {
      this.loadVacationPeriod();
    }

    return this.vacationPeriodSubject.asObservable();
  }

  private loadVacationPeriod(): void {
    if (!this.vacationPeriodSubject) {
      this.vacationPeriodSubject = new BehaviorSubject<IBalancePeriod[]>([]);
    }

    this.dataService.Balances_GetDanishVacationBalancePeriods().subscribe((data: IBalancePeriod[]): void => {
      this.vacationPeriodSubject.next(data);
    });
  }

  // VacationTypeSE
  private vacationTypeSubjectSE: BehaviorSubject<IVacationTypeSE[]>;
  public get VacationTypeSE(): Observable<IVacationTypeSE[]> {
    if (!this.vacationTypeSubjectSE) {
      this.loadVacationTypeSE();
    }

    return this.vacationTypeSubjectSE.asObservable();
  }

  private loadVacationTypeSE(): void {
    if (!this.vacationTypeSubjectSE) {
      this.vacationTypeSubjectSE = new BehaviorSubject<IVacationTypeSE[]>([]);
    }

    this.dataService.StaticData_GetVacationTypeSEs().subscribe((data: IVacationTypeSE[]): void => {
      this.vacationTypeSubjectSE.next(data);
    });
  }

  private genderSubject: BehaviorSubject<IGender[]>;
  public get Gender(): Observable<IGender[]> {
    if (!this.genderSubject) {
      this.loadGender();
    }

    return this.genderSubject.asObservable();
  }

  public loadGender(): void {
    if (!this.genderSubject) {
      this.genderSubject = new BehaviorSubject<IGender[]>([]);
    }

    this.dataService.StaticData_GetGenders().subscribe((data: IGender[]): void => {
      this.genderSubject.next(data);
    });
  }

  public getCurrentdate(isEnddate?: boolean, selectedDate?: Date): Date {
    let today: Date = new Date();

    if (selectedDate !== undefined) {
      today = selectedDate instanceof Date ? selectedDate : new Date(selectedDate);
    }
    const yyyy: number = today.getFullYear();
    let mm: number = today.getMonth();
    let dd = today.getDate();
    if (isEnddate === true) {
      dd = 0;
      mm = today.getMonth() + 1;
    }

    let offset = new Date(yyyy, mm, dd).getTimezoneOffset() / 60;
    offset = offset === 12 || offset === -12 ? 0 : offset;
    if (offset > 0) {
      return new Date(yyyy, mm, dd, offset, 0, 0);
    }

    return new Date(yyyy, mm, dd, -offset, 0, 0);
  }

  public getUTCDate(selectedDate: Date): Date {
    let today: Date = new Date();

    if (selectedDate !== undefined) {
      today = selectedDate instanceof Date ? selectedDate : new Date(selectedDate);
    }
    const yyyy: number = today.getFullYear();
    const mm: number = today.getMonth();
    const dd = today.getDate();

    return new Date(yyyy, mm, dd, 0, 0, 0);
  }

  public getReportDate(selectedDate: Date): Date {
    let today: Date = new Date();

    if (selectedDate !== undefined) {
      today = selectedDate instanceof Date ? selectedDate : new Date(selectedDate);
    }
    const yyyy: number = today.getFullYear();
    const MM: number = today.getMonth();
    const dd = today.getDate();
    const hh: number = today.getHours();
    const mm: number = today.getMinutes();

    let offset = new Date(yyyy, mm, dd).getTimezoneOffset() / 60;
    offset = offset === 12 || offset === -12 ? 0 : offset;
    if (offset > 0) {
      return new Date(yyyy, mm, dd, hh + offset, mm, 0);
    }

    return new Date(yyyy, MM, dd, hh - offset, mm, 0);
  }

  public createValidDate(year: number, month: number, date: number): Date {
    const today: Date = new Date();
    year =
      year < 100
        ? this.sessionService.parseInt(
            today
              .getFullYear()
              .toString()
              .substr(0, 2) + this.sessionService.toString(year, '00')
          )
        : year;
    month = month > 11 ? 11 : month < 0 ? 0 : month;
    const lastDateOfMonth: number = new Date(year, month + 1, 0).getDate();
    date = date > lastDateOfMonth ? lastDateOfMonth : date < 0 ? 1 : date;

    let offset = new Date(year, month, date).getTimezoneOffset() / 60;
    offset = offset === 12 || offset === -12 ? 0 : offset;
    if (offset > 0) {
      return new Date(year, month, date, offset, 0, 0);
    }

    return new Date(year, month, date, -offset, 0, 0);
  }

  public dataURItoBlob(dataURI: string, type: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type });
    return blob;
  }

  public checkModelRecord(record: any) {
    Object.keys(record).forEach((key) => {
      if (record[key] === undefined) {
        record[key] = null;
      }
    });

    return record;
  }

  private observable: Observable<any>;

  public GetIntegration2Operation(exportNewPayrollBatchId: any) {
    if (this.observable) {
      // if `this.observable` is set then the request is in progress
      // return the `Observable` for the ongoing request
      return this.observable;
    } else {
      // create the request, store the `Observable` for subsequent subscribers
      this.observable = this.dataService.Integrations_GetIntegrationsByOperation(exportNewPayrollBatchId).pipe(
        map((integration2Operation: IIntegration[]): void => {
          setTimeout(() => {
            this.observable = undefined;
          }, 1000);
          this.getIntegrationChangeEvent(integration2Operation);
        })
      );
      return this.observable.subscribe();
    }
  }

  integrationChange: EventEmitter<IIntegration[]> = new EventEmitter();
  private getIntegrationChangeEvent(Integration2Operation: IIntegration[]) {
    this.integrationChange.emit(Integration2Operation);
  }

  public getNavChangeEmitter() {
    return this.integrationChange;
  }

  private importTypeSubject: BehaviorSubject<IImportType[]>;
  public get ImportTypes(): Observable<IImportType[]> {
    if (!this.importTypeSubject) {
      this.loadImportTypes();
    }

    return this.importTypeSubject.asObservable();
  }

  private loadImportTypes() {
    if (!this.importTypeSubject) {
      this.importTypeSubject = new BehaviorSubject<IImportType[]>([]);
    }
    this.dataService.StaticData_GetImportTypes().subscribe((importTypes: IImportType[]) => {
      this.importTypeSubject.next(importTypes);
    });
  }

  private importOptionValueSubject: BehaviorSubject<IImportOptionValue[]>;
  public get ImportOptionValues(): Observable<IImportOptionValue[]> {
    if (!this.importOptionValueSubject) {
      this.loadImportOptionValues();
    }

    return this.importOptionValueSubject.asObservable();
  }

  private loadImportOptionValues() {
    if (!this.importOptionValueSubject) {
      this.importOptionValueSubject = new BehaviorSubject<IImportOptionValue[]>([]);
    }
    this.dataService.StaticData_GetImportOptionValues().subscribe((importOptionValues: IImportOptionValue[]) => {
      this.importOptionValueSubject.next(importOptionValues);
    });
  }

  private importOptionSubject: BehaviorSubject<IImportOption[]>;
  public get ImportOptions(): Observable<IImportOption[]> {
    if (!this.importOptionSubject) {
      this.loadImportOptions();
    }

    return this.importOptionSubject.asObservable();
  }

  private loadImportOptions() {
    if (!this.importOptionSubject) {
      this.importOptionSubject = new BehaviorSubject<IImportOption[]>([]);
    }
    this.dataService.StaticData_GetImportOptions().subscribe((importOptions: IImportOption[]) => {
      this.importOptionSubject.next(importOptions);
    });
  }

  private defaultTaxTableSubject: BehaviorSubject<ISeTaxTable[]>;
  public get DefaultTaxTable(): Observable<ISeTaxTable[]> {
    if (!this.defaultTaxTableSubject) {
      this.loadDefaultTaxTable();
    }

    return this.defaultTaxTableSubject.asObservable();
  }

  private loadDefaultTaxTable() {
    if (!this.defaultTaxTableSubject) {
      this.defaultTaxTableSubject = new BehaviorSubject<ISeTaxTable[]>([]);
    }
    this.dataService.StaticData_GetSeTaxTables().subscribe((taxTables: ISeTaxTable[]) => {
      this.defaultTaxTableSubject.next(taxTables);
    });
  }

  private dimensionRedistributionBehaviorSubject: BehaviorSubject<ISalaryTypeDimensionDistributionOption[]>;
  public get DimensionRedistributionBehavior(): Observable<ISalaryTypeDimensionDistributionOption[]> {
    if (!this.dimensionRedistributionBehaviorSubject) {
      this.loadDimensionRedistributionBehavior();
    }

    return this.dimensionRedistributionBehaviorSubject.asObservable();
  }

  private loadDimensionRedistributionBehavior() {
    if (!this.dimensionRedistributionBehaviorSubject) {
      this.dimensionRedistributionBehaviorSubject = new BehaviorSubject<ISalaryTypeDimensionDistributionOption[]>([]);
    }
    this.dataService
      .StaticData_GetSalaryTypeDimensionDistributionOptions()
      .subscribe((taxTables: ISalaryTypeDimensionDistributionOption[]) => {
        this.dimensionRedistributionBehaviorSubject.next(taxTables);
      });
  }

  private userEmploymentsSubject: BehaviorSubject<IUserEmploymentView[]>;
  public get userEmployments(): Observable<IUserEmploymentView[]> {
    if (!this.userEmploymentsSubject) {
      this.loadUserEmployments();
    }
    return this.userEmploymentsSubject.asObservable();
  }

  public loadUserEmployments() {
    const currentRoleKey: string = Global.SESSION.CurrentRole.Key;
    if (currentRoleKey === 'Employee') {
      return;
    }
    if (!this.userEmploymentsSubject) {
      this.userEmploymentsSubject = new BehaviorSubject<IUserEmploymentView[]>([]);
    }
    this.dataService.Employments_GetEmploymentViews().subscribe((userEmployments: IUserEmploymentView[]): void => {
      this.userEmploymentsSubject.next(userEmployments);
    });
  }

  private entityTypeSubject: BehaviorSubject<IEntityType[]>;
  public get entityTypes(): Observable<IEntityType[]> {
    if (!this.entityTypeSubject) {
      this.loadEntityTypes();
    }
    return this.entityTypeSubject.asObservable();
  }

  public loadEntityTypes() {
    if (!this.entityTypeSubject) {
      this.entityTypeSubject = new BehaviorSubject<IEntityType[]>([]);
    }
    this.dataService.StaticData_GetEntityTypes().subscribe((entityTypes: IEntityType[]): void => {
      this.entityTypeSubject.next(entityTypes);
    });
  }
}
