<app-dialog
  [overlayClose]="false"
  [customVisible]="true"
  [mustChangePassword]="true"
  [(visible)]="isVisible"
  (keydown)="keydown($event)"
  [width]="500"
  (action)="onChangePasswordDialogAction($event)"
  [title]="'Account.ChangePassword' | translate"
>
  <div class="Modal-header" [innerHTML]="'Account.RequestChangeMessage' | translate"></div>
  <input type="text" value="{{ userName }}" class="input-no-display" readonly="true" />
  <input type="password" class="input-no-display" />
  <app-text-edit
    id="newPassword"
    [label]="'Account.NewPassword' | translate"
    [type]="'password'"
    [editMode]="true"
    [(value)]="newPass"
    [autocorrect]="'off'"
  >
  </app-text-edit>

  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'ChangePassword'" [disabled]="!newPass || sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="isChangePassworSuccessDialog"
  [mustChangePassword]="true"
  (action)="onChangePassSuccessNotificationAction($event)"
  [title]="'Account.ChangePassword' | translate"
  [leadingText]="'Account.ChangePasswordMessagge' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
