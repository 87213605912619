import { EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmployeeService } from 'src/app/services/employee.service';
import { DataService } from '../../../services/api/data.service';
import { BusyIndicatorService } from '../../../services/busy-indicator.service';
import { SessionService } from '../../../services/session/session.service';
import { CompanySalaryBatchService } from '../company-salary-batches.service';
var BatchFinalizationStep4DialogComponent = /** @class */ (function () {
    function BatchFinalizationStep4DialogComponent(busyIndicatorService, dataService, sessionService, employeeServce, companySalaryBatchService) {
        var _this = this;
        this.busyIndicatorService = busyIndicatorService;
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.employeeServce = employeeServce;
        this.companySalaryBatchService = companySalaryBatchService;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.finalizationExit = new EventEmitter();
        this.finalizationDone = new EventEmitter();
        this.responseDialogVisible = false;
        this.ngUnsubscribe = new Subject();
        this.userName = localStorage.getItem('saveLoginUserName');
        this.companySalaryBatchService.selectedSalaryBatch
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            _this.salaryBatch = value;
        });
    }
    Object.defineProperty(BatchFinalizationStep4DialogComponent.prototype, "step1Completed", {
        get: function () {
            return this.visible;
        },
        set: function (value) {
            if (value) {
                if (!this.salaryBatch.EIncomeZeroReport) {
                    return;
                }
            }
            this.visible = false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep4DialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            var _this = this;
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                setTimeout(function () { return _this.visibleChange.emit(value); });
                if (value) {
                    this.password = '';
                    this.doRecalculation = this.salaryBatch.StatusId === 10;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    BatchFinalizationStep4DialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    BatchFinalizationStep4DialogComponent.prototype.onDialogAction = function (action) {
        if (action === 'Finalize' && this.password) {
            this.companySalaryBatchService.onStartFinalization(this.salaryBatch, this.password);
        }
        else {
            this.visible = false;
            this.finalizationExit.emit();
        }
    };
    return BatchFinalizationStep4DialogComponent;
}());
export { BatchFinalizationStep4DialogComponent };
