<app-dialog
  [overlayClose]="false"
  [(visible)]="isVisible"
  [customVisible]="true"
  (keydown)="keydown($event)"
  [width]="400"
  (action)="onChangePasswordDialogAction($event)"
  [title]="'Account.ChangePassword' | translate"
>
  <input type="text" value="{{ userName }}" class="input-no-display" autocomplete="username" />
  <app-text-edit
    id="currentPassword"
    [label]="'Account.CurrentPassword' | translate"
    [type]="'password'"
    [editMode]="true"
    [(value)]="currentPass"
  >
  </app-text-edit>
  <app-text-edit
    id="newPassword"
    [label]="'Account.NewPassword' | translate"
    [type]="'password'"
    [editMode]="true"
    [(value)]="newPass"
  >
  </app-text-edit>

  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'ChangePassword'" [disabled]="!currentPass || !newPass || sessionService.isSubmitting">
  </app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="isChangePassworSuccessDialog"
  [leadingText]="'Account.ChangePasswordMessagge' | translate"
  (action)="onChangePasswordDialogSuccessAction($event)"
>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>
<app-dialog
  class="blue-dialog"
  [(visible)]="isChangePassworFailDialog"
  [leadingText]="'Account.ChangePasswordFailMessagge' | translate"
  (action)="onChangePasswordDialogFailAction($event)"
>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>
