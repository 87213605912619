import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../common/form-component-base';
import { Department, ICompanyUser, IDepartment, IUserEmployment } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';

@Component({
  selector: 'app-add-department',
  templateUrl: './add-department.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AddDepartmentComponent extends FormComponentBase implements OnInit {
  public ModelDepartment: IDepartment = new Department();
  public departments: IDepartment[];
  @Input() public userEmployment: any;
  @Input() public companyContacts: ICompanyUser[];

  private isVisibleValue = false;
  @Input()
  public get isVisible(): boolean {
    return this.isVisibleValue;
  }
  public set isVisible(value: boolean) {
    this.isVisibleValue = value;
    this.isVisibleChange.emit(value);
  }

  @Output() public isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private staticDataService: StaticDataService, private dataService: DataService) {
    super();
  }

  public ngOnInit(): void {}

  public onCreateNewDepartment(event: any) {
    if (event && event === 'Create') {
      this.ModelDepartment.IsActive = true;
      this.dataService.Companies_CreateDepartment(this.ModelDepartment).subscribe((data: any): void => {
        this.staticDataService.loadDepartments();
        this.onCreateNewDepartmentSuccess(data.Id);
      });
    }

    this.ModelDepartment = new Department();
  }

  public onCreateNewDepartmentSuccess(newDepartmentId: number): void {
    this.staticDataService.departments.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IDepartment[]) => {
      this.departments = data;
      this.userEmployment.DepartmentId = newDepartmentId;
    });
  }
}
