import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { Global } from '../../common/global';
import { SalaryBatchStatusEnum } from '../../model/enum';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { CompanyService } from '../../services/company.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import { SalaryBatchErrorsView } from '../salary-batches/dialogs/salary-batch-errors-view';
import { SalaryBatchViewModel } from './salary-batch-view-model';
import * as i0 from "@angular/core";
import * as i1 from "../../services/api/data.service";
import * as i2 from "../../services/api/static-data.service";
import * as i3 from "../../services/setting.service";
import * as i4 from "../../services/session/session.service";
import * as i5 from "@uirouter/core";
import * as i6 from "../../services/broadcast.service";
import * as i7 from "@ngx-translate/core";
var CompanySalaryBatchService = /** @class */ (function (_super) {
    tslib_1.__extends(CompanySalaryBatchService, _super);
    function CompanySalaryBatchService(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster, translateService) {
        var _this = _super.call(this, dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) || this;
        _this.dataService = dataService;
        _this.staticDataService = staticDataService;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.transitionService = transitionService;
        _this.broadcaster = broadcaster;
        _this.translateService = translateService;
        _this.isSalaryBatchLoading = false;
        _this.allSalaryBatches = [];
        _this.allCurrentSelectSalaryStatements = [];
        _this.integrationName = '';
        _this.viewSalaryTotalModeChange = new EventEmitter();
        _this.pickEmployeeDialogVisible = false;
        _this.ngUnsubscribe = new Subject();
        _this.processFinalizationRunValue = false;
        _this.processCountValue = 0;
        _this.processStep3FinalizationFail = false;
        _this.processStep4FinalizationFail = false;
        _this.processStep5FinalizationFail = false;
        _this.step3ExepsionMessage = '';
        _this.processFinalizationInfo = {};
        _this.afterCreate = new EventEmitter();
        _this.sessionService.OnTranslateChanged.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function () {
            _this.translateService
                .get([
                'CompanySalaryBatches.OrtherStatusGridErrorIconTooltip',
                'CompanySalaryBatches.OrtherStatusGridWarningIconTooltip',
                'CompanySalaryBatches.RecalculateBatch',
                'CompanySalaryBatches.PrepareFinalization'
            ])
                .subscribe(function (translations) {
                _this.warningTooltip = translations['CompanySalaryBatches.OrtherStatusGridWarningIconTooltip'];
                _this.errorTooltip = translations['CompanySalaryBatches.OrtherStatusGridErrorIconTooltip'];
                _this.Step1FinalizeCalculate = translations['CompanySalaryBatches.RecalculateBatch'];
                _this.Step1FinalizePrepare = translations['CompanySalaryBatches.PrepareFinalization'];
                _this.Step1FinalizeTitle = _this.Step1FinalizeCalculate;
            });
        });
        _this.restoreFinalizationProcess();
        return _this;
    }
    Object.defineProperty(CompanySalaryBatchService.prototype, "salaryCycles", {
        get: function () {
            if (!this.salaryCyclesSubject) {
                this.salaryCyclesSubject = new BehaviorSubject([]);
            }
            return this.salaryCyclesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryBatchService.prototype, "isExitSalaryCycles", {
        get: function () {
            return this.salaryCyclesSubject && this.salaryCyclesSubject.value && this.salaryCyclesSubject.value.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryBatchService.prototype, "salaryBatches", {
        get: function () {
            if (!this.salaryBatchesSubject) {
                this.salaryBatchesSubject = new BehaviorSubject([]);
            }
            return this.salaryBatchesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryBatchService.prototype, "salaryStatements", {
        get: function () {
            if (!this.salaryStatementsSubject) {
                this.salaryStatementsSubject = new BehaviorSubject([]);
            }
            return this.salaryStatementsSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryBatchService.prototype, "salaryTypeTotals", {
        get: function () {
            if (!this.salaryTypeTotalsSubject) {
                this.salaryTypeTotalsSubject = new BehaviorSubject([]);
            }
            return this.salaryTypeTotalsSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CompanySalaryBatchService.prototype.loadSalaryTypeTotals = function (batchId) {
        var _this = this;
        if (!this.salaryTypeTotalsSubject) {
            this.salaryTypeTotalsSubject = new BehaviorSubject([]);
        }
        this.salaryTypeTotalsSubject.next([]);
        if (batchId) {
            this.dataService
                .SalaryBatches_GetSalaryBatchTotalsBySalaryType(batchId)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(function (data) {
                _this.salaryTypeTotalsSubject.next(data);
            });
        }
    };
    Object.defineProperty(CompanySalaryBatchService.prototype, "unitTotals", {
        get: function () {
            if (!this.unitTotalsSubject) {
                this.unitTotalsSubject = new BehaviorSubject([]);
            }
            return this.unitTotalsSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryBatchService.prototype, "salaryBatchErrorsView", {
        get: function () {
            if (!this.salaryBatchErrorsViewSubject) {
                this.salaryBatchErrorsViewSubject = new BehaviorSubject([]);
            }
            return this.salaryBatchErrorsViewSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CompanySalaryBatchService.prototype.loadUnitTotals = function (batchId) {
        var _this = this;
        if (!this.unitTotalsSubject) {
            this.unitTotalsSubject = new BehaviorSubject([]);
        }
        this.unitTotalsSubject.next([]);
        if (batchId) {
            this.dataService
                .SalaryBatches_GetSalaryBatchUnitTotals(batchId)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(function (data) {
                _this.unitTotalsSubject.next(data);
            });
        }
    };
    Object.defineProperty(CompanySalaryBatchService.prototype, "selectedSalaryBatch", {
        get: function () {
            if (!this.selectedSalaryBatchSubject) {
                this.selectedSalaryBatchSubject = new BehaviorSubject(null);
            }
            return this.selectedSalaryBatchSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CompanySalaryBatchService.prototype.setNextValueSelectedSalaryBatch = function (item) {
        if (!this.selectedSalaryBatchSubject) {
            this.selectedSalaryBatchSubject = new BehaviorSubject(null);
        }
        this.selectedSalaryBatchSubject.next(item);
    };
    Object.defineProperty(CompanySalaryBatchService.prototype, "salaryBatchEntityContext", {
        get: function () {
            if (!this.salaryBatchEntityContextSubject) {
                this.salaryBatchEntityContextSubject = new BehaviorSubject(null);
            }
            return this.salaryBatchEntityContextSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CompanySalaryBatchService.prototype.setNextValueSalaryBatchEntityContext = function (item) {
        this.salaryBatchEntityContextSubject.next(item);
    };
    CompanySalaryBatchService.prototype.loadSalaryStatements = function (batchId) {
        var _this = this;
        if (!this.salaryStatementsSubject) {
            this.salaryStatementsSubject = new BehaviorSubject([]);
        }
        this.salaryStatementsSubject.next([]);
        // this.allCurrentSelectSalaryStatements = [];
        if (batchId) {
            this.dataService
                .SalaryStatements_GetSalaryStatementsByBatchSimplified(batchId)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(function (data) {
                _this.salaryStatementsSubject.next(data);
                if (!data || (data && data.length === 0)) {
                    _this.allCurrentSelectSalaryStatements = [];
                }
                // this.allCurrentSelectSalaryStatements = data.map((item: ISimpleSalaryStatement) => {
                //   return item.UserEmploymentId;
                // });
            });
        }
    };
    CompanySalaryBatchService.prototype.loadSalaryBatchErrorsView = function (data) {
        var _this = this;
        if (data && data.Id) {
            if (!this.salaryBatchErrorsViewSubject) {
                this.salaryBatchErrorsViewSubject = new BehaviorSubject([]);
            }
            this.salaryBatchErrorsViewSubject.next([]);
            if (data.StatusId === 40 || data.StatusId === 90) {
                this.dataService
                    .SalaryBatches_GetEIncomeResponseLines(data.Id)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe(function (issue) {
                    _this.salaryBatchErrorsViewSubject.next(issue.map(function (d) { return new SalaryBatchErrorsView(d, _this.errorTooltip, _this.warningTooltip); }));
                });
            }
            else {
                this.dataService
                    .SalaryBatches_GetValidationIssues(data.Id)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe(function (validationIssues) {
                    var result = validationIssues.map(function (issue) {
                        return {
                            icon: issue.IsError ? 'ErrorExclamation' : 'Warning',
                            tooltip: issue.IsError ? _this.errorTooltip : _this.warningTooltip,
                            description: issue.Description,
                            employeeName: issue.EmployeeName
                        };
                    });
                    _this.salaryBatchErrorsViewSubject.next(result);
                });
            }
        }
    };
    CompanySalaryBatchService.prototype.loadSalaryBatches = function () {
        var _this = this;
        if (!this.salaryBatchesSubject) {
            this.salaryBatchesSubject = new BehaviorSubject([]);
        }
        return this.dataService.SalaryBatches_GetSalaryBatches().pipe(takeUntil(this.ngUnsubscribe), tap(function (data) {
            var result = data.map(function (s) { return new SalaryBatchViewModel(s, _this.sessionService); });
            _this.salaryBatchesSubject.next(result);
            _this.allSalaryBatches = result;
        }));
    };
    CompanySalaryBatchService.prototype.loadSalaryCycles = function () {
        var _this = this;
        if (!this.salaryCyclesSubject) {
            this.salaryCyclesSubject = new BehaviorSubject([]);
        }
        return this.dataService.Miscellaneous_GetUsedSalaryCycles().pipe(takeUntil(this.ngUnsubscribe), tap(function (SalaryCycles) {
            _this.salaryCyclesSubject.next(SalaryCycles);
            _this.isSalaryBatchLoading = false;
        }));
    };
    CompanySalaryBatchService.prototype.initData = function () {
        var _this = this;
        // refesh variable
        this.onDestroyLocalVariable();
        this.loadIntegrationName();
        this.tooltipFinalizeButton();
        this.restoreFinalizationProcess();
        this.loadSalaryCycles().toPromise();
        this.staticDataService.userEmployments.subscribe(function (data) {
            _this.isEmptyEmployeeFromCycle = data.length === 0;
        });
    };
    CompanySalaryBatchService.prototype.loadIntegrationName = function () {
        var _this = this;
        this.staticDataService.ExternalSystemOperation.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            var objectfillter = data.find(function (model) { return model.Key === 'ExportNewPayrollBatch'; });
            if (objectfillter) {
                _this.staticDataService.GetIntegration2Operation(objectfillter.Id);
                _this.staticDataService.getNavChangeEmitter().subscribe(function (valueIntegration) {
                    if (valueIntegration && valueIntegration.length > 0) {
                        _this.integrationName = valueIntegration[0].Name;
                    }
                });
            }
        });
    };
    CompanySalaryBatchService.prototype.tooltipFinalizeButton = function () {
        this.tooltipFinalizeContent = '';
        if (this.sessionService.role.IsReadOnly) {
            this.tooltipFinalizeContent = 'CompanySalaryBatches.DenyFinalize_Text';
        }
        else {
            if (!Global.SESSION.IsPaymentApprover) {
                this.tooltipFinalizeContent = 'CompanySalaryBatches.DenyFinalize_AdminText';
            }
        }
    };
    Object.defineProperty(CompanySalaryBatchService.prototype, "hasManyOpenDrafts", {
        get: function () {
            return (this.allSalaryBatches &&
                this.allSalaryBatches.length > 0 &&
                this.allSalaryBatches.filter(function (batch) { return batch.StatusId === SalaryBatchStatusEnum.Draft; })
                    .length >= 3);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryBatchService.prototype, "emptySalaryBatchShown", {
        get: function () {
            return !(this.allSalaryBatches && this.allSalaryBatches.length > 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryBatchService.prototype, "hasIntegrationName", {
        get: function () {
            return !!this.integrationName;
        },
        enumerable: true,
        configurable: true
    });
    CompanySalaryBatchService.prototype.ngOnDestroy = function () {
        this.onDestroyLocalVariable();
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    CompanySalaryBatchService.prototype.onDestroyLocalVariable = function () {
        this.allCurrentSelectSalaryStatements = [];
        if (this.salaryStatementsSubject) {
            this.salaryStatementsSubject.next([]);
        }
        if (this.salaryBatchesSubject) {
            this.salaryBatchesSubject.next([]);
            this.allSalaryBatches = [];
        }
        if (this.salaryTypeTotalsSubject) {
            this.salaryTypeTotalsSubject.next([]);
        }
        if (this.unitTotalsSubject) {
            this.unitTotalsSubject.next([]);
        }
        if (this.selectedSalaryBatchSubject) {
            this.selectedSalaryBatchSubject.next(null);
        }
        if (this.salaryBatchEntityContextSubject) {
            this.salaryBatchEntityContextSubject.next(null);
        }
    };
    Object.defineProperty(CompanySalaryBatchService.prototype, "processFinalizationRun", {
        get: function () {
            return this.processFinalizationRunValue;
        },
        set: function (value) {
            var _this = this;
            if (value !== this.processFinalizationRunValue) {
                if (!value) {
                    setTimeout(function () {
                        _this.processFinalizationRunValue = value;
                        _this.processCountValue = 0;
                        // Revert All process
                        setTimeout(function () {
                            _this.restoreFinalizationProcess();
                        });
                    }, 1000);
                }
                else {
                    this.processFinalizationRunValue = value;
                    // Revert All process
                    this.restoreFinalizationProcess();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanySalaryBatchService.prototype, "processCount", {
        get: function () {
            return this.processCountValue;
        },
        set: function (value) {
            if (value !== this.processCountValue) {
                this.processCountValue = value;
                if (value === 5) {
                    this.processCountValue = 0;
                    this.processFinalizationRun = false;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    CompanySalaryBatchService.prototype.restoreFinalizationProcess = function () {
        this.processFinalizationInfo['step1Finalization'] = { start: false, complete: false, error: false };
        this.processFinalizationInfo['step2Finalization'] = { start: false, complete: false, error: false };
        this.processFinalizationInfo['step3Finalization'] = { start: false, complete: false, error: false };
        this.processFinalizationInfo['step4Finalization'] = { start: false, complete: false, error: false };
        this.processFinalizationInfo['step5Finalization'] = { start: false, complete: false, error: false };
        this.step3ExepsionMessage = '';
    };
    CompanySalaryBatchService.prototype.startProcess = function (process, key) {
        process[key].start = true;
        process[key].complete = false;
        process[key].error = false;
    };
    CompanySalaryBatchService.prototype.doneProcess = function (process, key) {
        process[key].start = false;
        process[key].complete = true;
        process[key].error = false;
    };
    CompanySalaryBatchService.prototype.errorProcess = function (process, key) {
        process[key].start = false;
        process[key].complete = true;
        process[key].error = true;
    };
    CompanySalaryBatchService.prototype.revertProcess = function (process, key) {
        process[key].start = false;
        process[key].complete = false;
        process[key].error = false;
    };
    CompanySalaryBatchService.prototype.onStartFinalization = function (batch, password) {
        var _this = this;
        this.processFinalizationRun = true;
        if (batch.StatusId <= 10) {
            this.Step1FinalizeTitle = this.Step1FinalizeCalculate;
        }
        else {
            this.Step1FinalizeTitle = this.Step1FinalizePrepare;
        }
        this.startProcess(this.processFinalizationInfo, 'step1Finalization');
        this.FinalizationStep1(batch, password).subscribe(function () {
            _this.processCount += 1;
            _this.doneProcess(_this.processFinalizationInfo, 'step1Finalization');
            _this.startProcess(_this.processFinalizationInfo, 'step2Finalization');
            _this.FinalizationStep2(batch).subscribe(function () {
                _this.loadSalaryBatches().subscribe(function () {
                    _this.processCount += 1;
                    _this.doneProcess(_this.processFinalizationInfo, 'step2Finalization');
                    _this.handleFinalizationStep345(batch);
                }, function () {
                    _this.processCount += 1;
                    _this.doneProcess(_this.processFinalizationInfo, 'step2Finalization');
                    _this.handleFinalizationStep345(batch);
                });
            }, function () {
                _this.salaryBatchesSubject = new BehaviorSubject([]);
                _this.loadSalaryBatches().subscribe();
                _this.processFinalizationRun = false;
            });
        }, function () {
            _this.processFinalizationRun = false;
        });
    };
    CompanySalaryBatchService.prototype.handleFinalizationStep345 = function (batch) {
        var _this = this;
        this.startProcess(this.processFinalizationInfo, 'step3Finalization');
        this.FinalizationStep3(batch).subscribe(function () {
            _this.doneProcess(_this.processFinalizationInfo, 'step3Finalization');
            _this.processCount += 1;
        }, function (ex) {
            _this.step3ExepsionMessage = ex.message;
            _this.processStep3FinalizationFail = true;
            _this.Step3FinalizationFailText = _this.Step3FinalizationFailText.replace('$$$Message$$$', _this.step3ExepsionMessage).replace('$$$ExtSystemName$$$', _this.integrationName);
            _this.processCount += 1;
            _this.errorProcess(_this.processFinalizationInfo, 'step3Finalization');
        });
        this.startProcess(this.processFinalizationInfo, 'step4Finalization');
        this.FinalizationStep4(batch).subscribe(function () {
            _this.doneProcess(_this.processFinalizationInfo, 'step4Finalization');
            _this.processCount += 1;
        }, function () {
            _this.processStep4FinalizationFail = true;
            _this.processCount += 1;
            _this.errorProcess(_this.processFinalizationInfo, 'step4Finalization');
        });
        this.startProcess(this.processFinalizationInfo, 'step5Finalization');
        this.FinalizationStep5(batch).subscribe(function (data) {
            _this.creteNextPeriodBatch(batch, data).subscribe(function () {
                _this.doneProcess(_this.processFinalizationInfo, 'step5Finalization');
                _this.updateSelectedSalaryAfterFinalize(batch);
                _this.processCount += 1;
            }, function () {
                _this.doneProcess(_this.processFinalizationInfo, 'step5Finalization');
                _this.processCount += 1;
            });
        }, function () {
            _this.processStep5FinalizationFail = true;
            _this.processCount += 1;
            _this.errorProcess(_this.processFinalizationInfo, 'step5Finalization');
        });
    };
    CompanySalaryBatchService.prototype.updateSelectedSalaryAfterFinalize = function (batch) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var salaryBatchSelected;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        salaryBatchSelected = this.allSalaryBatches.filter(function (salaryBatch) {
                            return batch.Id === salaryBatch.Id;
                        });
                        if (!(salaryBatchSelected && salaryBatchSelected.length > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadSalaryStatements(salaryBatchSelected[0].Id)];
                    case 1:
                        _a.sent();
                        this.setNextValueSelectedSalaryBatch(batch);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    CompanySalaryBatchService.prototype.FinalizationStep1 = function (batch, password) {
        return this.dataService.SalaryBatches_PrepareSalaryBatchForFinalization(batch.Id, { Password: password }).pipe(tap(function (guid) {
            batch.FinalizationGuid = guid;
        }));
    };
    CompanySalaryBatchService.prototype.FinalizationStep2 = function (batch) {
        return this.dataService
            .SalaryBatches_FinalizeSalaryBatch(batch.Id, { FinalizationGuid: batch.FinalizationGuid })
            .pipe(tap(function () { }));
    };
    CompanySalaryBatchService.prototype.FinalizationStep3 = function (batch) {
        // skip  If there is no integration, skip this step.
        // TODO check
        if (!this.integrationName) {
            return Observable.create(function (ob) {
                ob.next();
            });
        }
        return this.dataService.SalaryBatches_SendOrResendToDefaultIntegration(batch.Id).pipe(tap(function () { }));
    };
    CompanySalaryBatchService.prototype.FinalizationStep4 = function (batch) {
        // skip this if there are no open batches with state Draft.
        // TODO check open draft
        if (!this.hasManyOpenDrafts) {
            return Observable.create(function (ob) {
                ob.next();
            });
        }
        return this.dataService.SalaryBatches_RecalculateDraftSalaryBatches().pipe(tap(function () { }));
    };
    CompanySalaryBatchService.prototype.FinalizationStep5 = function (batch) {
        //  if an open draft batch already exists for the next period. If no, then we create one automatically.
        return this.dataService
            .SalaryBatches_GetSalaryPeriods(batch.SalaryCycleId)
            .pipe(tap(function (data) { }));
    };
    CompanySalaryBatchService.prototype.creteNextPeriodBatch = function (batch, data) {
        var _this = this;
        var nextPeriod = data.find(function (item) {
            return item.StartDate &&
                batch.PeriodEndDate &&
                new Date(item.StartDate).getTime() > new Date(batch.PeriodEndDate).getTime();
        });
        if (!nextPeriod) {
            return Observable.create(function (ob) {
                ob.next();
            });
        }
        var existingBatchCount = this.allSalaryBatches.find(function (item) { return item.SalaryPeriodId === nextPeriod.Id; });
        if (!existingBatchCount) {
            // Create New Batch
            var salaryBatchRequest_1 = {
                PayoutDate: nextPeriod.SuggestedPayoutDate,
                Preview: false,
                SalaryCycleId: nextPeriod.SalaryCycleId,
                SalaryPeriodId: nextPeriod.Id,
                Message: null,
                UserEmploymentIds: this.allCurrentSelectSalaryStatements,
                EIncomeZeroReport: false,
                PayoutAllFlex: false,
                PayoutNewFlex: false,
                FromDateOverride: null,
                ToDateOverride: null
            };
            return this.dataService.SalaryBatches_CreateSalaryBatch(salaryBatchRequest_1).pipe(tap(function (salaryRecord) {
                _this.loadSalaryBatches().subscribe(function () {
                    _this.afterCreate.emit({ salaryBatchRequest: salaryBatchRequest_1, salaryRecord: salaryRecord });
                });
            }));
        }
        return Observable.create(function (ob) {
            ob.next();
        });
    };
    Object.defineProperty(CompanySalaryBatchService.prototype, "allowShortcut", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.salarybatches';
        },
        enumerable: true,
        configurable: true
    });
    CompanySalaryBatchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanySalaryBatchService_Factory() { return new CompanySalaryBatchService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.StaticDataService), i0.ɵɵinject(i3.SettingService), i0.ɵɵinject(i4.SessionService), i0.ɵɵinject(i5.TransitionService), i0.ɵɵinject(i6.BroadcastService), i0.ɵɵinject(i7.TranslateService)); }, token: CompanySalaryBatchService, providedIn: "root" });
    return CompanySalaryBatchService;
}(CompanyService));
export { CompanySalaryBatchService };
