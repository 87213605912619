import { OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionService } from '../../services/session/session.service';
import { ReportDialogService } from '../../shared-components/report-dialog/report-dialog.service';
import { ReportParametersDefaultValue } from '../../shared-components/report-dialog/report-parameters-default-value';
import { CompanyApprovalService } from './company-approval.service';
var CompanyApprovalComponent = /** @class */ (function () {
    function CompanyApprovalComponent(sessionService, renderer, reportDialogService, companyApprovalService) {
        var _this = this;
        this.sessionService = sessionService;
        this.renderer = renderer;
        this.reportDialogService = reportDialogService;
        this.companyApprovalService = companyApprovalService;
        this.isVisible = false;
        this.listClassExcluded = ['Footer', '10'];
        this.ngUnsubscribe = new Subject();
        this.reportDialogVisible = false;
        this.noReportVisible = false;
        this.companyApprovalService.showReportShortcutChange
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && _this.reportDialogService.isVisibleReportIcon) {
                _this.onShowReportsEventClick();
            }
        });
        this.reportDialogService.visibleChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (value) {
            if (value) {
                _this.onShowReportsEventClick();
            }
        });
    }
    Object.defineProperty(CompanyApprovalComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    CompanyApprovalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.renderer.listen(window, 'click', function () {
            if (!_this.isCollapseButtonClick) {
                _this.menuOpen = false;
            }
            else {
                _this.isCollapseButtonClick = false;
            }
        });
        if (this.sessionService.currentState === 'tabs.company.approval') {
            if (this.sessionService.isApprovalMessage) {
                this.sessionService.isApprovalMessage = false;
                this.isVisible = true;
            }
            this.gotoTimeEntryApproval();
        }
    };
    CompanyApprovalComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(CompanyApprovalComponent.prototype, "approvalTimeEntryIsActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.approval.timeentry';
        },
        enumerable: true,
        configurable: true
    });
    CompanyApprovalComponent.prototype.onToggleNavigation = function () {
        this.isCollapseButtonClick = true;
        this.menuOpen = !this.menuOpen;
    };
    CompanyApprovalComponent.prototype.gotoTimeEntryApproval = function () {
        console.log('run');
        this.menuOpen = false;
        this.sessionService.NavigateTo('tabs.company.approval.timeentry');
    };
    CompanyApprovalComponent.prototype.onShowReportsEventClick = function () {
        var parameters = new ReportParametersDefaultValue();
        parameters.employeeId = this.sessionService.currentSalaryPeriodId
            ? this.sessionService.currentSalaryPeriodId
            : undefined;
        this.reportDialogService.reportParams = parameters;
        if (this.sessionService.currentSalaryPeriodId) {
            this.reportDialogService.onShowReportsEventClick();
            this.noReportVisible = false;
        }
        else {
            this.reportDialogService.reportDialogVisible = false;
            this.noReportVisible = true;
        }
    };
    CompanyApprovalComponent.prototype.onMoreMenuOptionClick = function (action) {
        switch (action) {
            case 'report':
                this.onShowReportsEventClick();
                break;
        }
    };
    return CompanyApprovalComponent;
}());
export { CompanyApprovalComponent };
