<app-fixed-panel
  [excludedComponents]="listClassExcluded"
  [editMode]="editMode"
  [specificClassName]="'Island-employee--general'"
>
  <app-employee-info
    [companyUser]="employeeService.employee | async"
    [editMode]="editMode"
    [currentPositionId]="employeeService.selectedEmploymentId | async"
    [isEmployeeTab]="true"
    [(isValid)]="childIsValid[4]"
    [(isDirty)]="childIsDirty[4]"
    (valueChange)="onChange()"
    (expandUserInfoEmitter)="onExpand($event)"
  ></app-employee-info>

  <div
    class="Island Island-employee Island-employee--general"
    *ngIf="!employeeService.isNonSelected"
    [ngClass]="{ 'is-expand': expanded }"
  >
    <div *ngIf="!employeeService.isNonSelected">
      <div class="Cards Cards--packed">
        <div class="Card">
          <div class="Card-inner">
            <app-contact-info
              [(companyUser)]="companyUser"
              [editMode]="editMode"
              [(isValid)]="childIsValid[0]"
              [(isDirty)]="childIsDirty[0]"
              (valueChange)="onChange()"
            ></app-contact-info>
          </div>
        </div>
        <div class="Card">
          <div class="Card-inner">
            <app-bank-info
              [(bankInfo)]="companyUser"
              [editMode]="editMode"
              [(isValid)]="childIsValid[1]"
              [(isDirty)]="childIsDirty[1]"
              (valueChange)="onChange()"
            ></app-bank-info>
          </div>
        </div>

        <div class="Card">
          <div class="Card-inner">
            <app-tax-card
              [taxCard]="taxCardObject"
              [userEmployment]="userEmployment"
              [editMode]="editMode"
              [(isValid)]="childIsValid[2]"
              [(isDirty)]="childIsDirty[2]"
              [isSelfService]="isSelfService"
              (valueChange)="onChange()"
              [(refreshTaxCard)]="triggerRefreshTaxCard"
              (requestTaxCard)="showRequestTaxcardDialog()"
              (viewTaxCardHistory)="showViewTaxCardHistory($event)"
              (requestTaxcardConfirm)="showRequestTaxcardConfirm($event)"
              [selfService]="'width100'"
            ></app-tax-card>
          </div>
        </div>

        <div class="Card">
          <div class="Card-inner">
            <app-hiring-data
              [editMode]="editMode && allowEdit"
              [userEmployment]="userEmployment"
              [(isValid)]="childIsValid[3]"
              [(isDirty)]="childIsDirty[3]"
              (valueChange)="onChange()"
            ></app-hiring-data>
          </div>
        </div>

        <div class="Card">
          <div class="Card-inner">
            <app-benefits></app-benefits>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="NonSelected" *ngIf="employeeService.isNonSelected && sessionService.role.IsSalaryAdminAndHigher">
    <div class="Card">
      <div class="Card-inner">
        <app-button
          buttonClass="Button--primary"
          (clickEvent)="createNewEmployeeDialogVisible = true"
          [label]="'Employee.NewEmployee' | translate"
        ></app-button>
      </div>
    </div>
  </div>
</app-fixed-panel>

<div class="Actions" *ngIf="!editMode && !isMobile">
  <app-action-button
    *ngIf="!isSelfService && sessionService.role.IsSalaryAdminAndHigher"
    id="ActionButtonAdd"
    [icon]="'AddUser'"
    [label]="'Employee.New'"
    [tooltip]="'Employee.NewEmployee'"
    [buttonClass]="'Action-button Action-buttonAdd'"
    (click)="createNewEmployeeDialogVisible = true"
  >
  </app-action-button>

  <!-- <app-action-button
    *ngIf="!employeeService.isNonSelected"
    [icon]="'File'"
    [label]="'Employee.Reports'"
    [tooltip]="'Employee.Reports'"
    [buttonClass]="'Action-button Action-buttonReports'"
    (click)="reportDialogVisible = true"
  ></app-action-button> -->

  <app-menu-button
    *ngIf="!isSelfService && !sessionService.role.IsReadOnly && !employeeService.isNonSelected"
    [menuIcon]="'ThreeDots'"
    [menuLabel]="'More.Title'"
    [menuTooltip]="'More.Title'"
    [multiMenuItems]="true"
  >
    <app-menu-button-item (menuItemClick)="importEmployeesClick()" [field]="'ImportEmployee'"></app-menu-button-item>
    <app-menu-button-item (menuItemClick)="resendWelcomeEmail = true" [field]="'ResendWelcomeEmail'">
    </app-menu-button-item>
    <app-menu-button-item (menuItemClick)="resetPassword = true" [field]="'ResetPassword'"></app-menu-button-item>
    <app-menu-button-item (menuItemClick)="deleteEmployeeDialogVisible = true" [field]="'DeleteEmployee'">
    </app-menu-button-item>
  </app-menu-button>

  <app-menu-button [menuIcon]="'Help'" [menuLabel]="'Help.Title'" [menuTooltip]="'Help.Title'" [multiMenuItems]="true">
    <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
    <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
  </app-menu-button>

  <!-- Temporary workaround to make space for the edit button -->
  <app-action-button
    *ngIf="!editMode && !sessionService.role.IsReadOnly && !employeeService.isNonSelected"
    buttonClass="Action-button"
  ></app-action-button>

  <!-- Temporary workaround to make space for the report button -->
  <app-action-button
    *ngIf="!editMode && reportDialogService.isVisibleReportIcon && !employeeService.isNonSelected"
    buttonClass="Action-button"
  ></app-action-button>
</div>

<div class="Actions" *ngIf="!editMode && isMobile">
  <app-menu-button
    [menuIcon]="'ThreeDots'"
    [menuLabel]="'More.Title'"
    [menuTooltip]="'More.Title'"
    [multiMenuItems]="true"
  >
    <app-menu-button-item
      *ngIf="!isSelfService && sessionService.role.IsSalaryAdminAndHigher"
      (menuItemClick)="createNewEmployeeDialogVisible = true"
      [field]="'AddNewEmployee'"
    ></app-menu-button-item>
    <app-menu-button-item
      *ngIf="!isSelfService && !sessionService.role.IsReadOnly && !employeeService.isNonSelected"
      (menuItemClick)="importEmployeesClick()"
      [field]="'ImportEmployee'"
    ></app-menu-button-item>
    <app-menu-button-item
      *ngIf="!isSelfService && !sessionService.role.IsReadOnly && !employeeService.isNonSelected"
      (menuItemClick)="resendWelcomeEmail = true"
      [field]="'ResendWelcomeEmail'"
    >
    </app-menu-button-item>
    <app-menu-button-item
      *ngIf="!isSelfService && !sessionService.role.IsReadOnly && !employeeService.isNonSelected"
      (menuItemClick)="resetPassword = true"
      [field]="'ResetPassword'"
    ></app-menu-button-item>
    <app-menu-button-item
      *ngIf="!isSelfService && !sessionService.role.IsReadOnly && !employeeService.isNonSelected"
      (menuItemClick)="deleteEmployeeDialogVisible = true"
      [field]="'DeleteEmployee'"
    >
    </app-menu-button-item>

    <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
    <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
  </app-menu-button>

  <!-- Temporary workaround to make space for the edit button -->
  <app-action-button
    *ngIf="!editMode && !sessionService.role.IsReadOnly && !employeeService.isNonSelected"
    buttonClass="Action-button"
  ></app-action-button>
</div>

<app-new-employee-dialog [(visible)]="createNewEmployeeDialogVisible"></app-new-employee-dialog>

<!-- <app-report-dialog
  [(visible)]="reportDialogVisible"
  [key]="isSelfService ? 'SelfServiceGeneral' : 'EmployeeGeneral'"
  [param]="reportParams"
></app-report-dialog> -->

<app-dialog
  *ngIf="companyUser && companyUser.Id"
  [(visible)]="resendWelcomeEmail"
  (action)="onresendEmployeeWelcomEmailConfirmCompleted($event)"
  [leadingText]="'More.ResendEmailConfirmation' | translate: { SystemAlias: branding.SystemAlias }"
>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Proceed" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog
  *ngIf="companyUser && companyUser.Id"
  [(visible)]="resetPassword"
  (action)="onResetEmployeePasswordConfirmCompleted($event)"
  [title]="'Account.ResetPasswordTitle' | translate"
  [leadingText]="'More.ResetPasswordConfirmation' | translate"
>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Proceed" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="resendEmailSuccessVisible"
  [leadingText]="'More.ResendEmailSuccessNotification' | translate"
>
  <app-dialog-action type="Ok" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="resetPasswordSuccessVisible"
  [leadingText]="'Account.SendNewPasswordMessageAdmin' | translate"
>
  <app-dialog-action type="Ok" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-view-tax-card-history-dialog
  [(isVisible)]="taxcardHistoryDialogVisible"
  [userEmploymentId]="taxCardObject?.userEmploymentId"
  (addNewTaxInfo)="onCreateTaxInfo($event)"
  (taxCardChange)="onReloadTaxCard()"
></app-view-tax-card-history-dialog>

<app-request-tax-card-dialog
  [(isVisible)]="requestTaxcardDialogVisible"
  [taxcardTypes]="taxCardObject?.taxCardTypes"
  [userEmploymentId]="taxCardObject?.userEmploymentId"
  (change)="onReloadTaxCard()"
></app-request-tax-card-dialog>

<app-dialog
  [(visible)]="requestTaxcardWarningDialog"
  (action)="onRequestTaxcardWarningDialogAction($event)"
  [leadingText]="'EmployeeGeneral.RequestTaxCardForAlreadyRequested' | translate"
>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="AlreadyRequestedTaxCardConfirmButton"></app-dialog-action>
</app-dialog>

<app-delete-employee
  [currentCompanyUser]="employeeService.employee | async"
  [currentUserEmployment]="employeeService.employment | async"
  [(isVisible)]="deleteEmployeeDialogVisible"
>
</app-delete-employee>
