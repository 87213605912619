<legend
  *ngIf="integrationsService.isEmptyIntegrations"
  style="padding-bottom: 8px; padding-left: 1px;font-size: 0.75rem; font-style: italic; opacity: 0.75; padding: .5rem;"
  [innerHTML]="'Integrations.HelpMessage' | translate: { SystemAlias: branding.SystemAlias }"
></legend>
<div
  class="Island-configuration Island-integration"
  [ngClass]="integrationsService.editMode ? 'is-editing' : ''"
  (dblclick)="onDoubleClick()"
>
  <div class="Cards Cards--flex">
    <div class="Card Card--large Card-large-padding">
      <app-module-check [moduleId]="15" [(hasModule)]="integrationsService.hasIntegrationModule">
        <div class="Card-inner">
          <app-external-integrations></app-external-integrations>
        </div>
        <div class="Card-inner" *ngIf="isShowExternalIntegrationsConfiguration">
          <app-configuration-integrations></app-configuration-integrations>
        </div>
        <div class="Card-inner-credentials" *ngIf="isShowExternalIntegrationsCredential">
          <app-credential-integrations></app-credential-integrations>
        </div>
      </app-module-check>
    </div>

    <div class="Card Card--large">
      <app-standard-salary-types></app-standard-salary-types>
    </div>
  </div>

  <div
    class="Actions"
    *ngIf="!integrationsService.isMobile && !integrationsService.editMode"
    [ngClass]="integrationsService.isIOSApp ? 'is-ios-app' : ''"
  >
    <app-module-check [moduleId]="15">
      <app-menu-button
        [menuCondition]="!integrationsService.isReadonlyUser"
        (menuClick)="integrationsService.addNewsDialogVisible = true"
        [menuIcon]="'Add'"
        [menuLabel]="'Company.New'"
        [menuTooltip]="'Company.New'"
        [actionButtonMenu]="true"
        [menuButtonClass]="'Action-button Action-buttonAdd'"
        [menuDisabled]="!integrationsService.hasIntegrationModule"
        [menuButtonId]="'ActionButtonAdd'"
      >
      </app-menu-button>
    </app-module-check>
    <app-menu-button
      [menuIcon]="'Help'"
      [menuLabel]="'Help.Title'"
      [menuTooltip]="'Help.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
      <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
    </app-menu-button>
    <app-menu-button
      *ngIf="reportDialogService.isVisibleReportIcon"
      [menuCondition]="true"
      (menuClick)="reportDialogService.onShowReportsEventClick()"
      [menuIcon]="'File'"
      [menuLabel]="'Employee.Reports'"
      [menuTooltip]="'Employee.Reports'"
      [actionButtonMenu]="true"
      [menuButtonClass]="'Action-button Action-buttonReports'"
      [menuButtonId]="'ActionButtonReports'"
    >
    </app-menu-button>
    <app-menu-button
      *ngIf="
        !integrationsService.isReadonlyUser &&
        !integrationsService.isEmptyIntegrations &&
        integrationsService.hasIntegrationModule
      "
      [editButtonMenu]="true"
      (menuChangeClick)="onEditClick($event)"
      [menuCondition]="true"
      [editMode]="integrationsService.editMode"
    >
    </app-menu-button>
  </div>

  <div
    class="Actions"
    *ngIf="integrationsService.isMobile && !integrationsService.editMode"
    [ngClass]="integrationsService.isIOSApp ? 'is-ios-app' : ''"
  >
    <app-menu-button
      [menuIcon]="'ThreeDots'"
      [menuLabel]="'More.Title'"
      [menuTooltip]="'More.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item (menuItemClick)="dialogVisible = true" [field]="'CompanyAddNew'"> </app-menu-button-item>
      <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
      <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
    </app-menu-button>
    <app-menu-button
      *ngIf="
        !integrationsService.isReadonlyUser &&
        !integrationsService.isEmptyIntegrations &&
        integrationsService.hasIntegrationModule
      "
      [editButtonMenu]="true"
      (menuChangeClick)="onEditClick($event)"
      [menuCondition]="true"
      [editMode]="integrationsService.editMode"
    >
    </app-menu-button>
  </div>

  <div class="EditingInfo Actions" *ngIf="integrationsService.editMode">
    <app-button
      [buttonClass]="'Discard'"
      (clickEvent)="applyDiscard()"
      [label]="'Employee.DiscardButton' | translate"
      *ngIf="integrationsService.editMode"
    ></app-button>
    <app-menu-button
      [editButtonMenu]="true"
      [editMode]="true"
      (menuChangeClick)="onEditClick($event)"
      [menuCondition]="true"
      class="menu-button--editing"
    >
    </app-menu-button>
  </div>

  <app-dialog
    [(visible)]="isShowingAlertMessage"
    (action)="onUnSaveWarningDialogAction($event)"
    [leadingText]="'Employee.UnsavedData' | translate"
  >
    <app-dialog-action [type]="'CancelDiscard'"></app-dialog-action>
    <app-dialog-action [type]="'ConfirmDiscard'"></app-dialog-action>
  </app-dialog>

  <app-dialog
    (action)="integrationsService.onConfirmSaveDataOnChangeState($event)"
    [(visible)]="integrationsService.confirmUpdateDialogVisible"
  >
    <div [innerHTML]="'Employee.UnsavedData' | translate"></div>
    <app-dialog-action [type]="'DiscardAndLeave'"></app-dialog-action>
    <app-dialog-action [type]="'Continue'"></app-dialog-action>
    <app-dialog-action [type]="'Stay'"></app-dialog-action>
  </app-dialog>
</div>
