import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Browser } from '../../common/browser';
import { FilterDateTimeGrid } from '../../common/filter-date-time-grid';
import { Global } from '../../common/global';
import { GridHelper } from '../../common/grid-helper';
import { NumericTextBoxOptions } from '../../custom-controls/numeric-edit/numeric-text-box-options';
import { ExtendSalaryPeriod } from '../../model/extend-salary-period';
import {
  ICompanyUser,
  IRejectionInfo,
  ISalaryCycle,
  ISalaryPeriod,
  ISimpleKeyValuePair,
  ITimeEntryRecord,
  ITimeEntryStatus,
  ITimeEntryType,
  TimeEntryStatus
} from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';

@Component({
  selector: 'app-company-approval-grid',
  templateUrl: './company-approval-grid.component.html',
  styleUrls: ['./company-approval-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyApprovalGridComponent implements OnInit, OnDestroy {
  private indexRemovePeriod: number;
  private selectedEntryDate: any;
  private translationServiceTerms: string[];

  public selectedMinDate: Date;
  public selectedSalaryPeriodId: number;
  public SelectedTimeStatusId: number;
  public TimeEntryTypes: ITimeEntryType[] = [];
  public UnitTypes: any[];
  public SalaryPeriods: ExtendSalaryPeriod[];

  public DefaultTimeEntryRecord: ITimeEntryRecord[];
  public TimeEntryStatuses: ITimeEntryStatus[] = [];

  public ApprovalSubodinates: number[] = [];
  public allowManagersToApproveTimeEntry = false;
  public option: NumericTextBoxOptions = { min: 0, step: 1, spinners: false };
  public defaultTimeEntryType: any;

  // pageging for approval
  public currentpage = 0;

  public dimensionXValues: any[] = [];
  public isSpecialMode: boolean;

  // Translation strings
  public PeriodWarningText: string;

  public AllText = 'All';
  public isSelect1020default = true;

  public SalaryCycle: ISalaryCycle[];
  public SalaryPeriod: ISalaryPeriod[];
  public selectedSalaryCycleId: number;
  public currentSalaryCycle: ISalaryCycle;
  public currentSalaryPeriod: ISalaryPeriod;
  public TimeEntryRecord: ITimeEntryRecord[] = [];
  public defaultTimeEntryRecords: any = {};
  public timeEntryRecords: any = [];
  public currentTimeEntryRecord: ITimeEntryRecord;
  public TimeEntryStatus: ITimeEntryStatus[];
  public currentTimeEntryStatus: ITimeEntryStatus;
  public selectedTimeEntryStatusId: number;

  filterEligibleforapproval: string;
  selectedemployeeIdinGrid: number;
  selectedIdinGrid: number;
  nameEmployeeSelect: string;
  public numberOption: any = { min: undefined, step: 1, spinners: false, decimals: 2, format: 'n2' };

  private loadDataSourceSalaryCycle(): void {
    this.dataService.Miscellaneous_GetUsedSalaryCycles().subscribe((data: ISalaryCycle[]): void => {
      this.SalaryCycle = data;
      if (this.SalaryCycle && this.SalaryCycle.length > 0) {
        if (this.sessionService.currentSalaryCycleId) {
          this.selectedSalaryCycleId = this.sessionService.currentSalaryCycleId;
          this.sessionService.currentSalaryCycleId = undefined;
        } else {
          this.selectedSalaryCycleId = this.SalaryCycle[0].Id;
        }
        this.populateDataApprovalTimeEntry();
      }
    });
  }

  public populateDataApprovalTimeEntry(): void {
    this.dataService
      .SalaryBatches_GetSalaryPeriods(this.selectedSalaryCycleId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((salaryPeriods: ISalaryPeriod[]): void => {
        if (salaryPeriods && salaryPeriods.length > 0) {
          this.SalaryPeriod = [];
          salaryPeriods = salaryPeriods.sort((data1: any, data2: any) =>
            GridHelper.sortByDateValue(data1.StartDate, data2.StartDate)
          );
          salaryPeriods.forEach((salaryPeriod: any, key: number): void => {
            const startDate: string = this.sessionService.toString(
              salaryPeriod.StartDate ? new Date(salaryPeriod.StartDate) : undefined
            );
            const endDate: string = this.sessionService.toString(
              salaryPeriod.EndDate ? new Date(salaryPeriod.EndDate) : undefined
            );
            const preference: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
              'SalaryPeriods.FriendlyNames'
            );
            if ((preference && preference.Value && preference.Value === 'true') || this.isMobile) {
              const extendSalaryPeriod = new ExtendSalaryPeriod();
              (extendSalaryPeriod.Id = salaryPeriod.Id), (extendSalaryPeriod.StartDate = salaryPeriod.StartDate);
              extendSalaryPeriod.EndDate = salaryPeriod.EndDate;
              extendSalaryPeriod.Period = startDate + ' - ' + endDate;
              extendSalaryPeriod.FriendlyName = ' ' + salaryPeriod.FriendlyName;

              this.SalaryPeriod[key] = extendSalaryPeriod;
            } else {
              const extendSalaryPeriod = new ExtendSalaryPeriod();
              extendSalaryPeriod.Id = salaryPeriod.Id;
              extendSalaryPeriod.StartDate = salaryPeriod.StartDate;
              extendSalaryPeriod.EndDate = salaryPeriod.EndDate;
              extendSalaryPeriod.Period = startDate + ' - ' + endDate;
              extendSalaryPeriod.FriendlyName = startDate + ' - ' + endDate;
              this.SalaryPeriod[key] = extendSalaryPeriod;
            }
          });
          if (this.sessionService.currentSalaryPeriodId) {
            if (this.SalaryPeriod && this.SalaryPeriod.length > 0) {
              const periodChek: ISalaryPeriod[] = this.SalaryPeriod.filter(
                (model: ISalaryPeriod) => model.Id === this.selectedSalaryPeriodId
              );
              if (periodChek && periodChek.length > 0) {
                this.selectedSalaryPeriodId = this.sessionService.currentSalaryPeriodId;
                this.sessionService.currentSalaryPeriodId = this.selectedSalaryPeriodId;
              } else {
                this.sessionService.currentSalaryPeriodId = undefined;
                if (this.SalaryPeriod && this.SalaryPeriod[0]) {
                  const today: Date = this.staticDataService.getCurrentdate();
                  today.setHours(0, 0, 0, 0);
                  const selectedSalaryPeriod: ISalaryPeriod = this.SalaryPeriod.find((salaryPeriod: ISalaryPeriod) => {
                    const startDate: Date = new Date(salaryPeriod.StartDate.toString());
                    const endDate: Date = new Date(salaryPeriod.EndDate.toString());
                    startDate.setHours(0, 0, 0, 0);
                    endDate.setHours(0, 0, 0, 0);
                    return startDate <= today && endDate >= today;
                  });

                  this.selectedSalaryPeriodId = selectedSalaryPeriod.Id;
                  this.sessionService.currentSalaryPeriodId = this.selectedSalaryPeriodId;
                }
              }
            }
          } else {
            if (this.SalaryPeriod && this.SalaryPeriod[0]) {
              const today: Date = this.staticDataService.getCurrentdate();
              today.setHours(0, 0, 0, 0);
              const selectedSalaryPeriod: ISalaryPeriod = this.SalaryPeriod.find((salaryPeriod: ISalaryPeriod) => {
                const startDate: Date = new Date(salaryPeriod.StartDate.toString());
                const endDate: Date = new Date(salaryPeriod.EndDate.toString());
                startDate.setHours(0, 0, 0, 0);
                endDate.setHours(0, 0, 0, 0);
                return startDate <= today && endDate >= today;
              });

              this.selectedSalaryPeriodId = selectedSalaryPeriod.Id;
              this.sessionService.currentSalaryPeriodId = this.selectedSalaryPeriodId;
            }
          }
        } else {
          this.SalaryPeriod = [];
        }
      });
  }

  private get isMobile(): boolean {
    const browser: Browser = new Browser();
    return browser.isMobile;
  }

  public get isHideDimension1(): boolean {
    return this.Dimension1Preference && !!this.Dimension1Preference.Value;
  }
  public get isHideDimension2(): boolean {
    return this.Dimension2Preference && !!this.Dimension2Preference.Value;
  }
  public get isHideDimension3(): boolean {
    return this.Dimension3Preference && !!this.Dimension3Preference.Value;
  }

  public get Dimension1Name(): string {
    if (!this.isHideDimension1) {
      return '';
    } else {
      const dimension1Object: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
        'Dimensions.Dimension1Name'
      );
      if (dimension1Object && dimension1Object.Value) {
        return dimension1Object.Value;
      }
    }

    return '1';
  }

  public get Dimension2Name(): string {
    if (!this.isHideDimension2) {
      return '';
    } else {
      const dimension2Object: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
        'Dimensions.Dimension2Name'
      );
      if (dimension2Object && dimension2Object.Value) {
        return dimension2Object.Value;
      }
    }

    return '2';
  }

  public get Dimension3Name(): string {
    if (!this.isHideDimension3) {
      return '';
    } else {
      const dimension3Object: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
        'Dimensions.Dimension3Name'
      );
      if (dimension3Object && dimension3Object.Value) {
        return dimension3Object.Value;
      }
    }

    return '3';
  }

  public get buttonApproveEntriesisviable(): boolean {
    if (this.TimeEntryRecord && this.TimeEntryRecord.length > 0) {
      return true;
    }

    return false;
  }

  /*end variable of approval timeentry*/
  private preSalaryCycleId = 0;
  private preSalaryPeriodId = 0;
  private preStatus = 0;

  constructor(
    public translateService: TranslateService,
    public dataService: DataService,
    public settingService: SettingService,
    public staticDataService: StaticDataService,
    public sessionService: SessionService
  ) {
    this.translationServiceTerms = [
      'EmploymentTime.PeriodWarning',
      'CompanySalaryBatches.SalaryCycle_Item_All_Text',
      'EmploymentTime.EligibleForApproval'
    ];

    this.UnitTypes = Global.UNIT_TYPES;
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  private get IsReadOnlyAndHasSubordinates(): boolean {
    if (this.IsReadOnly && this.ApprovalSubodinates && this.ApprovalSubodinates.length > 0) {
      return true;
    }
    return false;
  }

  public get IsReadOnlyAndHasSubordinatesAndFilterByEligibleForApproval(): boolean {
    if (this.IsReadOnlyAndHasSubordinates && this.selectedTimeEntryStatusId === -2 && !this.isSpecialMode) {
      return true;
    }
    return false;
  }

  private get Dimension1Preference(): ISimpleKeyValuePair {
    return this.sessionService.getCompanyPreference('Dimensions.Dimension1Name');
  }

  private get Dimension2Preference(): ISimpleKeyValuePair {
    return this.sessionService.getCompanyPreference('Dimensions.Dimension2Name');
  }

  private get Dimension3Preference(): ISimpleKeyValuePair {
    return this.sessionService.getCompanyPreference('Dimensions.Dimension3Name');
  }

  public get allowManagersToApproveTimeEntryMessage(): string {
    if (!this.allowManagersToApproveTimeEntry) {
      return 'EmploymentTime.DisableGridTimeMessage';
    }
    return '';
  }

  public timeEntryStatus: ITimeEntryStatus[];
  private baseTimeEntryStatus: ITimeEntryStatus[];

  public ngOnInit(): void {
    this.staticDataService.TimeEntryStatus.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (timeEntryStatus: ITimeEntryStatus[]) => {
        this.baseTimeEntryStatus = timeEntryStatus;
        if (!this.baseTimeEntryStatus || (this.baseTimeEntryStatus && this.baseTimeEntryStatus.length === 0)) {
          return;
        }
        this.enableTranslation();
        const objectAllowManagersToApproveTimeEntry: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
          'Access.AllowManagersToApproveTimeEntry'
        );

        if (objectAllowManagersToApproveTimeEntry && objectAllowManagersToApproveTimeEntry.Value === 'true') {
          this.allowManagersToApproveTimeEntry = true;
        } else {
          if (this.sessionService.role.IsSalaryAdminAndHigher) {
            this.allowManagersToApproveTimeEntry = true;
          }
        }

        this.ApprovalSubodinates = Global.SESSION.ManagerForUserEmploymentIds;
        this.loadDataSourceSalaryCycle();
        this.dataService
          .TimeEntryTypes_GetTimeEntryTypeViews()
          .subscribe((data: any[]): void => this.onGetTimeEntryTypesCompleted(data));
      }
    );
  }

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private loadTimeEntryRecord(periodId: number): void {
    this.TimeEntryRecord = [];
    this.dataService
      .TimeEntry_GetTimeEntryRecordsByPeriod(periodId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: ITimeEntryRecord[]): void => {
        this.timeEntryRecords = [];
        data.forEach((model: any) => {
          model.RejectionReason = model.Status && model.Status.Key !== 'Rejected' ? '' : model.RejectionReason;
          model.iconFeildApprove = 'SuccessNonGreen';

          if (model.Status) {
            if (
              (model.Status.Key === 'Open' || model.Status.Key === 'EmployeeApproved') &&
              (!this.IsReadOnly ||
                (this.IsReadOnly && this.isSubordinatesModel(model.UserEmploymentId)) ||
                this.sessionService.role.EmployeeWithRoleAsManager ||
                this.sessionService.role.IsSalaryAdminAndHigher)
            ) {
              model.isCanReject = true;
              model.isCanSingleApprove = true;
            } else if (model.Status.Key === 'ManagerApproved' && this.sessionService.role.IsSalaryAdminAndHigher) {
              model.isCanReject = true;
              model.isCanSingleApprove = true;
            } else {
              model.isCanReject = false;
              model.isCanSingleApprove = false;
            }
          }

          model.isDisbaleReject = !model.isCanReject || !this.allowManagersToApproveTimeEntry;
          model.isDisbaleSingleApprove = !model.isCanSingleApprove || !this.allowManagersToApproveTimeEntry;

          const timeEntryIndex: number = this.checkTimeEntryExist(model, this.timeEntryRecords);
          if (timeEntryIndex >= 0) {
            this.timeEntryRecords[timeEntryIndex].timeEntries.push(model);
          } else {
            this.timeEntryRecords.push({
              Id: model.EmployeeName,
              DepartmentName: model.DepartmentName,
              Title: model.Title,
              timeEntries: [model]
            });
          }
        });
        this.defaultTimeEntryRecords = this.timeEntryRecords;
        const dataTimeEntry: any = [];
        this.timeEntryRecords.forEach((timeEntry: any) => {
          const TimeEntry = {
            EmployeeName: timeEntry.Id,
            DepartmentName: timeEntry.DepartmentName,
            Title: timeEntry.Title,
            TotalTime: 0,
            TotalDay: 0,
            KilometersDriven: 0,
            Status: '',
            UserEmploymentId: 0,
            timeEntries: 0,
            isHaveRecords: timeEntry.timeEntries && timeEntry.timeEntries.length > 0
          };
          const status: any = [];
          const userEmploymentId: any = [];

          timeEntry.timeEntries.forEach((entry: ITimeEntryRecord) => {
            userEmploymentId.push(entry.UserEmploymentId);
            status.push(this.baseTimeEntryStatus.find((model: ITimeEntryStatus) => model.Id === entry.Status.Id));
          });

          TimeEntry.timeEntries = timeEntry.timeEntries;
          TimeEntry.Status = status;
          TimeEntry.UserEmploymentId = userEmploymentId;
          dataTimeEntry.push(TimeEntry);
        });

        this.DefaultTimeEntryRecord = dataTimeEntry;
        this.loadDataSourceTimeEntryStatus(dataTimeEntry);
      });
  }

  public checkTimeEntryExist(timeEntry: any, timeEntries: any): number {
    let existTimeEntryIndex = -1;
    timeEntries.forEach((data: any, index: number) => {
      if (existTimeEntryIndex >= 0) {
        return;
      }
      if (data.Id === timeEntry.EmployeeName) {
        existTimeEntryIndex = index;
      }
    });

    return existTimeEntryIndex;
  }

  public get TooltipFroApproButtonAprrovalGrid(): string {
    if (!this.allowManagersToApproveTimeEntry) {
      return this.allowManagersToApproveTimeEntryMessage;
    }
    return 'EmploymentTime.ApproveThisRecord';
  }

  private isSubordinatesModel(numberEmployment: number): boolean {
    if (this.IsReadOnly && this.ApprovalSubodinates && this.ApprovalSubodinates.length > 0) {
      return this.ApprovalSubodinates.filter((dataId: number) => numberEmployment === dataId).length > 0;
    }
    return false;
  }

  private isSalaryCycleChanging = false;
  public onBaseSalaryCycleComboboxChanged(value: any): void {
    if (this.selectedSalaryCycleId) {
      if (this.isSpecialMode) {
        this.TimeEntryRecord = [];
        this.loadDataSourceForSpecialMode();
      } else {
        if (this.preSalaryCycleId !== this.selectedSalaryCycleId) {
          // this.sessionService.currentSalaryCycleId = this.selectedSalaryCycleId;
          this.TimeEntryRecord = [];
          this.TimeEntryStatus = [];
          this.currentSalaryCycle = this.SalaryCycle.find(
            (model: ISalaryCycle) => model.Id === this.selectedSalaryCycleId
          );
          this.populateDataApprovalTimeEntry();
          this.preSalaryCycleId = this.selectedSalaryCycleId;
          this.preSalaryPeriodId = 0;
          this.preStatus = 0;
          this.preSalaryPeriodId = undefined;
          this.preStatus = undefined;
          if (this.isSalaryPeriodChanging) {
            this.isSelect1020default = false;
          } else {
            this.stillSelectstatus = undefined;
            this.isSelect1020default = true;
          }

          this.isSalaryCycleChanging = true;
        }
      }
    }
  }
  private isSalaryPeriodChanging = false;
  public onBaseSalaryPeriodComboboxChanged(value: any): void {
    if (this.selectedSalaryPeriodId && this.preSalaryPeriodId !== this.selectedSalaryPeriodId) {
      this.sessionService.currentSalaryCycleId = this.selectedSalaryCycleId;
      this.sessionService.currentSalaryPeriodId = this.selectedSalaryPeriodId;
      this.currentSalaryPeriod = this.SalaryPeriod.find(
        (model: ISalaryPeriod) => model.Id === this.selectedSalaryPeriodId
      );

      this.selectedTimeEntryStatusId = undefined;
      if (this.isSalaryPeriodChanging) {
        this.isSelect1020default = false;
      } else {
        this.stillSelectstatus = undefined;
        this.isSelect1020default = true;
      }
      this.loadTimeEntryRecord(this.selectedSalaryPeriodId);
      this.preSalaryPeriodId = this.selectedSalaryPeriodId;
      this.isSalaryPeriodChanging = true;
    }
  }

  public onBaseTimeEntryStatusComboboxChanged(value: any): void {
    if (this.selectedTimeEntryStatusId && this.preStatus !== this.selectedTimeEntryStatusId) {
      setTimeout(() => {
        this.currentTimeEntryStatus = this.TimeEntryStatus.filter((model: ITimeEntryStatus) => {
          return model.Id === this.selectedTimeEntryStatusId;
        })[0];
        this.preStatus = this.selectedTimeEntryStatusId;
        this.stillSelectstatus = this.selectedTimeEntryStatusId;
        this.filterTimeEntriesByStatus();
        setTimeout(() => {
          const dataTimeEntry: ITimeEntryRecord[] = [];
          this.TimeEntryRecord.forEach((record: any) => {
            const result: any = this.calculateBasicData(record.timeEntries);
            record.TotalDay = result.TotalDay;
            record.TotalTime = result.TotalTime;
            record.KilometersDriven = result.KilometersDriven;

            dataTimeEntry.push(record);
          });
          this.TimeEntryRecord = dataTimeEntry;
        });
      });
    }
  }

  private calculateBasicData(timeEntries: any): object {
    let TotalTime = 0;
    let TotalDay = 0;
    let KilometersDriven = 0;
    timeEntries.forEach((entry: ITimeEntryRecord) => {
      if (this.currentTimeEntryStatus.Id === -2) {
        if (entry.Status.Id !== 10 && entry.Status.Id !== 20) {
          return;
        }
      } else if (this.currentTimeEntryStatus.Id !== entry.Status.Id && this.currentTimeEntryStatus.Id !== -1) {
        return;
      }
      const unitType: any =
        entry.UnitTypeId && this.UnitTypes
          ? this.UnitTypes.find((type: any) => type.Id === entry.UnitTypeId)
          : undefined;
      const isHoursUnitType: boolean = unitType && unitType.Key === 'Hours';
      const isDaysUnitType: boolean = unitType && unitType.Key === 'Days';

      if (isHoursUnitType) {
        TotalTime += entry.Units;
      } else if (isDaysUnitType) {
        TotalDay += entry.Units;
      }
      KilometersDriven += entry.KilometersDriven;
    });

    const record = {
      TotalTime,
      TotalDay,
      KilometersDriven
    };

    return record;
  }

  private loadDataSourceTimeEntryStatus(timeEntryRecord: ITimeEntryRecord[]): void {
    if (timeEntryRecord && timeEntryRecord.length > 0) {
      this.TimeEntryStatus = [];
      timeEntryRecord.forEach((item: any) => {
        if (item && item.Status) {
          item.Status.forEach((status: any) => {
            const isStatusExist: boolean =
              this.TimeEntryStatus.filter(
                (timeEntryStatus: ITimeEntryStatus): boolean => timeEntryStatus.Id === status.Id
              ).length > 0;
            if (!isStatusExist) {
              this.TimeEntryStatus.push(status);
            }
          });
        }
      });

      this.sortTimeEntryStatus();

      if (this.TimeEntryStatus && this.TimeEntryStatus.length >= 1) {
        const filter1020: ITimeEntryStatus = new TimeEntryStatus();
        filter1020.Id = -2;
        filter1020.Name = this.filterEligibleforapproval;
        this.TimeEntryStatus.push(filter1020);

        const allStatus: ITimeEntryStatus = { Id: -1, Name: this.AllText } as ITimeEntryStatus;
        this.TimeEntryStatus.unshift(allStatus);
        if (this.isSelect1020default && this.isSelect1020default === true) {
          this.currentTimeEntryStatus = filter1020;
          this.selectedTimeEntryStatusId = this.currentTimeEntryStatus.Id;
        } else {
          const tempstatus: any[] = this.TimeEntryStatus.filter(
            (model: ITimeEntryStatus) => model.Id === this.stillSelectstatus
          );
          if (tempstatus && tempstatus.length > 0) {
            this.currentTimeEntryStatus = tempstatus[0];
            this.selectedTimeEntryStatusId = this.stillSelectstatus;
          } else {
            this.currentTimeEntryStatus = allStatus;
            this.selectedTimeEntryStatusId = this.currentTimeEntryStatus.Id;
          }
        }
      } else {
        this.TimeEntryStatus = [];
        const allStatus: ITimeEntryStatus = { Id: -1, Name: this.AllText } as ITimeEntryStatus;
        this.TimeEntryStatus.unshift(allStatus);
        this.currentTimeEntryStatus = allStatus;
        this.selectedTimeEntryStatusId = this.currentTimeEntryStatus.Id;
      }
    } else {
      this.TimeEntryStatus = [];
      const allStatus: ITimeEntryStatus = { Id: -1, Name: this.AllText } as ITimeEntryStatus;
      this.TimeEntryStatus.unshift(allStatus);
      this.currentTimeEntryStatus = allStatus;
      this.selectedTimeEntryStatusId = this.currentTimeEntryStatus.Id;
    }

    this.preStatus = undefined;
    this.onBaseTimeEntryStatusComboboxChanged(undefined);
  }

  private sortTimeEntryStatus() {
    if (this.TimeEntryStatus && this.TimeEntryStatus.length > 0) {
      this.TimeEntryStatus.sort((a: ITimeEntryStatus, b: ITimeEntryStatus) => {
        return this.baseTimeEntryStatus.indexOf(a) > this.baseTimeEntryStatus.indexOf(b) ? 1 : -1;
      });
    }
  }

  private enableTranslation(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateService.get(this.translationServiceTerms).subscribe((translations: { [key: string]: string }) => {
        this.setTranslationTerms(translations);
      });
    });
  }

  private setTranslationTerms(translations: { [key: string]: string }): void {
    this.PeriodWarningText = translations['EmploymentTime.PeriodWarning'];
    this.AllText = translations['CompanySalaryBatches.SalaryCycle_Item_All_Text'];
    this.filterEligibleforapproval = translations['EmploymentTime.EligibleForApproval'];
  }

  private onGetTimeEntryTypesCompleted(timeEntryTypes: ITimeEntryType[]): void {
    if (timeEntryTypes && timeEntryTypes.length > 0) {
      this.TimeEntryTypes = timeEntryTypes;
      this.TimeEntryTypes.forEach((model: ITimeEntryType) => {
        model.Id = model.Id || model.BaseTimeEntryTypeId || null;
        model.InternalReferenceId = model.InternalReferenceId ? model.InternalReferenceId : null;
      });
      this.defaultTimeEntryType = timeEntryTypes.find((mode: ITimeEntryType) => mode.Identifier === 'Work');
      if (!this.defaultTimeEntryType) {
        this.defaultTimeEntryType = timeEntryTypes[0];
      }
    }
  }

  private filterTimeEntriesByStatus(dataSource?: any): void {
    if (this.TimeEntryRecord) {
      let isChange = false;
      if (this.selectedTimeEntryStatusId >= 0) {
        this.TimeEntryRecord = this.DefaultTimeEntryRecord.filter((model: any) => {
          return this.checkIsCorrect(model.Status, this.currentTimeEntryStatus.Name, true);
        });
        isChange = true;
      } else if (this.selectedTimeEntryStatusId === -2) {
        this.TimeEntryRecord = this.DefaultTimeEntryRecord.filter((model: any) => {
          return this.checkIsCorrect(model.Status, 10, false) || this.checkIsCorrect(model.Status, 20, false);
        });
        if (this.IsReadOnly) {
          if (this.ApprovalSubodinates && this.ApprovalSubodinates.length > 0) {
            this.TimeEntryRecord = this.TimeEntryRecord.filter((model: any) => {
              return (
                this.ApprovalSubodinates.filter((dataId: number) => {
                  return this.checkIsCorrect(model.UserEmploymentId, dataId);
                }).length > 0
              );
            });
          } else {
            this.TimeEntryRecord = [];
          }
        }
        isChange = true;
      } else {
        this.TimeEntryRecord = this.DefaultTimeEntryRecord;
        this.timeEntryRecords = this.defaultTimeEntryRecords;
      }
      if (this.approvalFilter && this.approvalFilter.filters && this.approvalFilter.filters.length > 0) {
        this.onApprovalFilterChange(this.approvalFilter);
      }
      if (isChange) {
        this.timeEntryRecords = this.defaultTimeEntryRecords.filter((model: any) => {
          const length = this.TimeEntryRecord.length;
          let isCorrect = false;
          for (let i = 0; i < length; i++) {
            if (this.TimeEntryRecord[i].EmployeeName === model.Id) {
              isCorrect = true;
              break;
            }
          }
          return isCorrect;
        });
      }
      this.currentpage = 0;
    }
  }

  private checkIsCorrect(dataCompare: any, compareValue: any, isName?: boolean): boolean {
    let isCorrect = false;
    if (dataCompare) {
      if (isName === undefined || isName === null) {
        for (let i = 0; i < dataCompare.length; i++) {
          const data = dataCompare[i];
          if (data === compareValue) {
            isCorrect = true;
            break;
          }
        }
      } else if (isName) {
        for (let i = 0; i < dataCompare.length; i++) {
          const data = dataCompare[i].Name;
          if (data === compareValue) {
            isCorrect = true;
            break;
          }
        }
      } else {
        for (let i = 0; i < dataCompare.length; i++) {
          const data = dataCompare[i].Id;
          if (data === compareValue) {
            isCorrect = true;
            break;
          }
        }
      }
    }
    return isCorrect;
  }

  private getTimeEntryType(id: number): ITimeEntryType {
    const timeEntryTypes: ITimeEntryType[] = this.TimeEntryTypes.filter(
      (timeEntryType: ITimeEntryType): boolean => timeEntryType.Id === id
    );
    return timeEntryTypes.length > 0 ? timeEntryTypes[0] : undefined;
  }

  private validateTimeEntryType(timeEntryTypeId: number): boolean {
    const newTimeEntryTypeId: number = timeEntryTypeId;
    const newTimeEntryType: ITimeEntryType[] = this.TimeEntryTypes.filter(
      (tet: any) => tet.TimeEntryTypeId === newTimeEntryTypeId
    );
    return newTimeEntryType.length > 0;
  }

  public ApproveEntries(): void {
    if (this.selectedSalaryPeriodId) {
      this.isShowApproveForAllDialog = true;
    }
  }

  public ApproveEntriesallforselectedemployee(): void {
    if (this.selectedemployeeIdinGrid && this.selectedSalaryPeriodId && this.nameEmployeeSelect) {
      this.isShowApproveForSelectDialog = true;
    }
  }

  private selectedItem: any;
  public onSelectRow(event: any): void {
    if (!event || event === undefined) {
      return;
    }
    this.selectedItem = event;
    this.selectedMinDate = this.staticDataService.getCurrentdate(false, event.EntryDate);
    this.selectedEntryDate = event.EntryDate;
    if (event && event.timeEntries && event.timeEntries.length > 0 && event.EmployeeName && event.UserEmploymentId) {
      this.selectedemployeeIdinGrid = event.UserEmploymentId[0];
      this.nameEmployeeSelect = event.EmployeeName;
    }
  }

  public isShowRejectDialog = false;
  public rejectMessage: string;
  public isShowRejectDialogNonApproveTimeEntry = false;
  public preUserEmploymentId: number;
  public onGridButtonClick(event: any): void {
    if (event && event.buttonAction && event.buttonAction === 'Reject') {
      if (event.dataItem) {
        if (event.dataItem.isCanReject) {
          this.isShowRejectDialog = true;
          this.selectedemployeeIdinGrid = event.dataItem.Id;
          this.preUserEmploymentId = event.dataItem.UserEmploymentId;
        }
      }
    }
  }

  public onRejectDialog(event: any): void {
    if (event) {
      if (event === 'RejectPrimary') {
        const rejectionInfo: IRejectionInfo = { Reason: this.rejectMessage };
        this.dataService.TimeEntry_RejectTimeEntryRecord(this.selectedemployeeIdinGrid, rejectionInfo).subscribe(
          (): void => {
            if (this.isSpecialMode) {
              this.TimeEntryRecord = [];
              this.loadDataSourceForSpecialMode();
              return;
            }
            this.stillSelectstatus = this.selectedTimeEntryStatusId;
            this.isSelect1020default = false;
            this.loadTimeEntryRecord(this.selectedSalaryPeriodId);
            this.selectedemployeeIdinGrid = undefined;
            this.rejectMessage = undefined;
          },
          (): void => {
            if (this.isSpecialMode) {
              this.TimeEntryRecord = [];
              this.loadDataSourceForSpecialMode();
              return;
            }
            this.stillSelectstatus = this.selectedTimeEntryStatusId;
            this.isSelect1020default = false;
            this.loadTimeEntryRecord(this.selectedSalaryPeriodId);
            this.selectedemployeeIdinGrid = undefined;
            this.rejectMessage = undefined;
          }
        );
      } else {
        this.rejectMessage = undefined;
        this.selectedemployeeIdinGrid = this.preUserEmploymentId;
      }
    }
  }

  public isShowApproveForSelectDialog = false;
  public stillSelectstatus: number;
  public onApproveForSelectDialog(event: any): void {
    if (event === 'Approve') {
      const periodPUT: number = this.isSpecialMode ? 0 : this.selectedSalaryPeriodId;
      this.dataService.TimeEntry_ApprovePeriodForEmployee(this.selectedemployeeIdinGrid, periodPUT).subscribe(
        (): void => {
          this.selectedemployeeIdinGrid = undefined;
          this.nameEmployeeSelect = undefined;
          if (this.isSpecialMode) {
            this.TimeEntryRecord = [];
            this.loadDataSourceForSpecialMode();
            return;
          }
          this.isSelect1020default = false;
          this.stillSelectstatus = this.selectedTimeEntryStatusId;
          this.loadTimeEntryRecord(this.selectedSalaryPeriodId);
        },
        () => {
          this.selectedemployeeIdinGrid = undefined;
          this.nameEmployeeSelect = undefined;
          if (this.isSpecialMode) {
            this.TimeEntryRecord = [];
            this.loadDataSourceForSpecialMode();
            return;
          }
          this.isSelect1020default = false;
          this.stillSelectstatus = this.selectedTimeEntryStatusId;
          this.loadTimeEntryRecord(this.selectedSalaryPeriodId);
        }
      );
    }
  }

  public isShowApproveForAllDialog = false;
  public onApproveForAllDialog(event: any): void {
    if (event === 'Approve') {
      this.dataService.TimeEntry_ApproveEntirePeriod(this.selectedSalaryPeriodId).subscribe(
        (): void => {
          this.isSelect1020default = false;
          this.stillSelectstatus = this.selectedTimeEntryStatusId;
          this.loadTimeEntryRecord(this.selectedSalaryPeriodId);
        },
        () => {
          this.isSelect1020default = false;
          this.stillSelectstatus = this.selectedTimeEntryStatusId;
          this.loadTimeEntryRecord(this.selectedSalaryPeriodId);
        }
      );
    }
  }

  public EmptyEmployeetimeApproval(): string {
    if (this.TimeEntryRecord && this.TimeEntryRecord.length > 0) {
      return '';
    } else {
      return 'EmptydataGrid';
    }
  }

  public onClickAppGridIcon(event: any): void {
    if (
      event &&
      event.iconAction === 'ApproveSingleRecord' &&
      event.dataItem &&
      event.dataItem.Id &&
      event.dataItem.isCanSingleApprove
    ) {
      this.dataService.TimeEntry_ApproveTimeEntryRecord(event.dataItem.Id).subscribe((model: ITimeEntryRecord) => {
        if (this.isSpecialMode) {
          this.TimeEntryRecord = [];
          this.loadDataSourceForSpecialMode();
          return;
        }

        this.isSelect1020default = false;
        this.loadTimeEntryRecord(this.selectedSalaryPeriodId);
      });
    }
  }

  private loadDataSourceForSpecialMode(): void {
    const cycleId: number = this.selectedSalaryCycleId ? this.selectedSalaryCycleId : 0;
    // Set status all for special mode
    this.currentTimeEntryStatus = { Id: -1, Name: this.AllText } as ITimeEntryStatus;
    this.dataService.TimeEntry_GetOpenTimeEntryRecordsAcrossPeriods(cycleId).subscribe((data: ITimeEntryRecord[]) => {
      if (data && data.length > 0) {
        this.timeEntryRecords = [];
        data.forEach((model: any) => {
          model.RejectionReason = model.Status && model.Status.Key !== 'Rejected' ? '' : model.RejectionReason;
          model.iconFeildApprove = 'SuccessNonGreen';

          if (model.Status) {
            if (
              (model.Status.Key === 'Open' || model.Status.Key === 'EmployeeApproved') &&
              (!this.IsReadOnly ||
                (this.IsReadOnly && this.isSubordinatesModel(model.UserEmploymentId)) ||
                this.sessionService.role.EmployeeWithRoleAsManager ||
                this.sessionService.role.IsSalaryAdminAndHigher)
            ) {
              model.isCanReject = true;
              model.isCanSingleApprove = true;
            } else if (model.Status.Key === 'ManagerApproved' && this.sessionService.role.IsSalaryAdminAndHigher) {
              model.isCanReject = true;
              model.isCanSingleApprove = true;
            } else {
              model.isCanReject = false;
              model.isCanSingleApprove = false;
            }
          }

          model.isDisbaleReject = !model.isCanReject || !this.allowManagersToApproveTimeEntry;
          model.isDisbaleSingleApprove = !model.isCanSingleApprove || !this.allowManagersToApproveTimeEntry;

          const timeEntryIndex: number = this.checkTimeEntryExist(model, this.timeEntryRecords);
          if (timeEntryIndex >= 0) {
            this.timeEntryRecords[timeEntryIndex].timeEntries.push(model);
          } else {
            this.timeEntryRecords.push({
              Id: model.EmployeeName,
              timeEntries: [model]
            });
          }
        });
        this.TimeEntryRecord = JSON.parse(JSON.stringify(data));
        this.defaultTimeEntryRecords = this.timeEntryRecords;
        const dataTimeEntry: any = [];
        this.timeEntryRecords.forEach((timeEntry: any) => {
          const TimeEntry = {
            EmployeeName: timeEntry.Id,
            TotalTime: 0,
            TotalDay: 0,
            KilometersDriven: 0,
            Status: '',
            UserEmploymentId: 0,
            timeEntries: 0,
            isHaveRecords: timeEntry.timeEntries && timeEntry.timeEntries.length > 0
          };
          const status: any = [];
          const userEmploymentId: any = [];

          timeEntry.timeEntries.forEach((entry: ITimeEntryRecord) => {
            const unitType: any =
              entry.UnitTypeId && this.UnitTypes
                ? this.UnitTypes.find((type: any) => type.Id === entry.UnitTypeId)
                : undefined;
            const isHoursUnitType: boolean = unitType && unitType.Key === 'Hours';
            const isDaysUnitType: boolean = unitType && unitType.Key === 'Days';
            if (isHoursUnitType) {
              TimeEntry.TotalTime += entry.Units;
            } else if (isDaysUnitType) {
              TimeEntry.TotalDay += entry.Units;
            }
            TimeEntry.KilometersDriven += entry.KilometersDriven;
            userEmploymentId.push(entry.UserEmploymentId);
            status.push(entry.Status);
          });

          TimeEntry.timeEntries = timeEntry.timeEntries;
          TimeEntry.Status = status;
          TimeEntry.UserEmploymentId = userEmploymentId;
          dataTimeEntry.push(TimeEntry);
        });
        this.TimeEntryRecord = dataTimeEntry;
        this.DefaultTimeEntryRecord = JSON.parse(JSON.stringify(dataTimeEntry));
      } else {
        this.DefaultTimeEntryRecord = [];
      }
    });
  }

  public get isShowSalaryCycle(): boolean {
    if (this.isSpecialMode && this.SalaryCycle && this.SalaryCycle.length === 1) {
      return false;
    }

    return true;
  }

  public onModeChange(): void {
    this.TimeEntryRecord = [];
    this.selectedemployeeIdinGrid = undefined;
    this.nameEmployeeSelect = undefined;
    if (this.isSpecialMode) {
      this.loadDataSourceForSpecialMode();
    } else {
      this.SalaryPeriod = [];
      this.SalaryCycle = [];
      this.selectedSalaryPeriodId = undefined;
      this.selectedTimeEntryStatusId = undefined;
      this.preSalaryPeriodId = undefined;
      this.loadDataSourceSalaryCycle();
    }
  }

  public get isEnableControlNomalMode(): boolean {
    if (this.isSpecialMode) {
      return false;
    }
    return true;
  }

  public isShowApprovalOpenEntries = false;

  public ApproveOpenEntries(): void {
    this.isShowApprovalOpenEntries = true;
  }

  public onApprovalOpenEntries(event: any): void {
    if (event && event === 'Understood') {
      this.dataService.TimeEntry_ApproveEntireCycle(this.selectedSalaryCycleId).subscribe(
        (): void => {
          this.TimeEntryRecord = [];
          this.loadDataSourceForSpecialMode();
        },
        () => {
          this.TimeEntryRecord = [];
          this.loadDataSourceForSpecialMode();
        }
      );
    }
  }

  public approvalFilter: CompositeFilterDescriptor;
  public onApprovalFilterChange(approvalFilter: CompositeFilterDescriptor): void {
    if (approvalFilter && approvalFilter.filters && approvalFilter.filters.length > 0) {
      const filterDateTimeGrid = new FilterDateTimeGrid();
      const filtersDate: any = [];
      // Using FilterDateTimeGrid to filter date before filter another field
      this.approvalFilter = filterDateTimeGrid.convertFilterOperator(
        approvalFilter,
        filtersDate,
        'EntryDate',
        'EndDate'
      );
      this.TimeEntryRecord = filterDateTimeGrid.doFilterDate(
        this.DefaultTimeEntryRecord,
        filtersDate,
        'EntryDate',
        'EndDate'
      );
      this.TimeEntryRecord = filterBy(this.TimeEntryRecord, this.approvalFilter);
      if (filtersDate.length > 0) {
        [].push.apply(this.approvalFilter.filters, filtersDate);
      }
    } else {
      this.TimeEntryRecord = this.DefaultTimeEntryRecord;
      if (!this.isSpecialMode) {
        this.selectedTimeEntryStatusId = -1;
        this.onBaseTimeEntryStatusComboboxChanged(undefined);
      }
    }
  }

  public onApproveRejectData() {
    if (this.isSpecialMode) {
      this.loadDataSourceForSpecialMode();
    } else {
      this.loadTimeEntryRecord(this.selectedSalaryPeriodId);
    }
  }
}
