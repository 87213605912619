import { EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../common/constants';
import { Global } from '../../../common/global';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';
import { CompanySalaryBatchService } from '../company-salary-batches.service';
var BatchFinalizationStep2DialogComponent = /** @class */ (function () {
    function BatchFinalizationStep2DialogComponent(settingService, sessionService, companySalaryBatchService) {
        var _this = this;
        this.settingService = settingService;
        this.sessionService = sessionService;
        this.companySalaryBatchService = companySalaryBatchService;
        this.stepCompletedChange = new EventEmitter();
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.finalizationExit = new EventEmitter();
        this.ngUnsubscribe = new Subject();
        this.companySalaryBatchService.selectedSalaryBatch
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            _this.salarybatch = value;
        });
    }
    Object.defineProperty(BatchFinalizationStep2DialogComponent.prototype, "stepCompleted", {
        get: function () {
            return this.stepCompletedValue;
        },
        set: function (value) {
            var _this = this;
            if (this.stepCompletedValue !== value) {
                // this.stepCompletedValue = value;
                setTimeout(function () { return _this.stepCompletedChange.emit(value); });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep2DialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            var _this = this;
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                setTimeout(function () { return _this.visibleChange.emit(value); });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep2DialogComponent.prototype, "isClose", {
        get: function () {
            return this.visible;
        },
        set: function (value) {
            if (value) {
                this.visible = false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep2DialogComponent.prototype, "isGreenland", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep2DialogComponent.prototype, "isSwedenCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep2DialogComponent.prototype, "isDanishCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep2DialogComponent.prototype, "eBoksModuleEnabled", {
        get: function () {
            return this.sessionService.feature.hasModuleId(9);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep2DialogComponent.prototype, "isAccountAdmin", {
        get: function () {
            return this.sessionService.role.IsAccountAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep2DialogComponent.prototype, "isFullAdmin", {
        get: function () {
            return this.sessionService.role.IsFullAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep2DialogComponent.prototype, "isIOSMobileApp", {
        get: function () {
            return this.sessionService.browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BatchFinalizationStep2DialogComponent.prototype, "company", {
        get: function () {
            return Global.COMPANY;
        },
        enumerable: true,
        configurable: true
    });
    BatchFinalizationStep2DialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    BatchFinalizationStep2DialogComponent.prototype.onDialogAction = function (action) {
        if (action === 'Understood') {
            this.stepCompleted = true;
            this.finalizationExit.emit();
        }
        else {
            this.onCancel();
        }
    };
    BatchFinalizationStep2DialogComponent.prototype.onCancel = function () {
        this.finalizationExit.emit();
        this.visible = false;
    };
    BatchFinalizationStep2DialogComponent.prototype.activateDataExport = function () {
        this.sessionService.NavigateTo('tabs.company.configuration.integrations');
        //window.open("http://support.gratisal.com/hc/da/articles/208119325-Integration-med-%C3%98konomisystem", "blank");
    };
    BatchFinalizationStep2DialogComponent.prototype.navigateToExtraTab = function () {
        this.settingService.navigationParameters['EnableEboksModule'] = true;
        this.sessionService.NavigateTo('tabs.company.modules');
        this.onCancel();
    };
    BatchFinalizationStep2DialogComponent.prototype.setStartupTaskDone = function () {
        var task = Global.STARTUP_TASKS.find(function (startupTask) { return startupTask.Key === 'NetsAgreement'; });
        if (!task.StatusId) {
            this.navigateToTask(task);
            this.onCancel();
            return;
        }
        this.sessionService.NavigateTo('tabs.company.general');
        this.onCancel();
    };
    BatchFinalizationStep2DialogComponent.prototype.openTabLink = function () {
        window.open('http://support.gratisal.com/hc/da/articles/208119325-Integration-med-%C3%98konomisystem', '_blank');
    };
    BatchFinalizationStep2DialogComponent.prototype.navigateToTask = function (task) {
        if (task) {
            this.settingService.navigationStartUpTask = task;
            if (!this.sessionService.NavigateToAppUri(task.AppUri)) {
                this.settingService.showNavigationMessage();
            }
        }
    };
    return BatchFinalizationStep2DialogComponent;
}());
export { BatchFinalizationStep2DialogComponent };
