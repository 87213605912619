var _a;
var IconDefinitionsComponent = /** @class */ (function () {
    function IconDefinitionsComponent() {
    }
    IconDefinitionsComponent.icons = (_a = {},
        _a['AddUser'] = { class: '', svg: 'adduser' },
        _a['Add'] = { class: '', svg: 'add' },
        _a['AppStore'] = { class: '', svg: 'appstore' },
        _a['Bars'] = { class: '', svg: 'bars' },
        _a['Calendar'] = { class: '', svg: 'calendar' },
        _a['CalendarCheck'] = { class: '', svg: 'calendarcheck' },
        _a['CaretDown'] = { class: '', svg: 'caret-down' },
        _a['CaretUp'] = { class: '', svg: 'caret-up' },
        _a['Check'] = { class: '', svg: 'check' },
        _a['Checksquare'] = { class: '', svg: 'checksquare' },
        _a['ChevronDown-small'] = { class: 'Icon', svg: 'chevrondown' },
        _a['ChevronDown'] = { class: '', svg: 'chevrondown' },
        _a['ChevronLeft'] = { class: '', svg: 'chevronleft' },
        _a['ChevronRight'] = { class: '', svg: 'chevronright' },
        _a['ChevronUp'] = { class: '', svg: 'chevronup' },
        _a['Circle'] = { class: '', svg: 'circle-outline' },
        _a['Clear'] = { class: '', svg: 'clear' },
        _a['Clock'] = { class: '', svg: 'clock' },
        _a['Close'] = { class: '', svg: 'close' },
        _a['Cog'] = { class: '', svg: 'cog' },
        _a['Columns'] = { class: '', svg: 'columns' },
        _a['Diamond'] = { class: '', svg: 'diamond' },
        _a['Dollar'] = { class: '', svg: 'dollar' },
        _a['Download'] = { class: '', svg: 'download' },
        _a['Edit'] = { class: '', svg: 'edit' },
        _a['EditForm'] = { class: '', svg: 'edit-form' },
        _a['Error'] = { class: 'Icon--error', svg: 'error' },
        _a['ErrorExclamation'] = { class: 'Icon--error', svg: 'error-exclamation' },
        _a['ExternalLink'] = { class: '', svg: 'ext-link' },
        _a['File'] = { class: '', svg: 'file' },
        _a['Help'] = { class: '', svg: 'question' },
        _a['Industry'] = { class: '', svg: 'industry' },
        _a['Info'] = { class: '', svg: 'info' },
        _a['LevelDown'] = { class: '', svg: 'leveldown' },
        _a['LevelUp'] = { class: '', svg: 'levelup' },
        _a['People'] = { class: '', svg: 'people' },
        _a['Plus'] = { class: '', svg: 'iplus' },
        _a['Preview'] = { class: '', svg: 'preview' },
        _a['Question'] = { class: '', svg: 'question-circle' },
        _a['Search'] = { class: '', svg: 'search' },
        _a['ShareBox'] = { class: '', svg: 'share-box' },
        _a['Success'] = { class: 'Icon--success', svg: 'success' },
        _a['SuccessWhite'] = { class: 'Icon--success--white', svg: 'success' },
        _a['SuccessGray'] = { class: 'Icon--grey', svg: 'success' },
        _a['SuccessNonGreen'] = { class: '', svg: 'success' },
        _a['Table'] = { class: '', svg: 'table' },
        _a['ThreeDots'] = { class: '', svg: 'threedots' },
        _a['Times'] = { class: '', svg: 'times' },
        _a['User'] = { class: '', svg: 'user' },
        _a['Warning'] = { class: 'Icon--warning', svg: 'warning' },
        _a['html-outline'] = { class: 'Icon--medium', svg: 'html-outline' },
        _a['html'] = { class: 'Icon--medium', svg: 'html' },
        _a['pdf'] = { class: 'Icon--medium', svg: 'pdf' },
        _a['warning'] = { class: 'Icon--warning', svg: 'warning' },
        _a['file-download'] = { class: '', svg: 'file-download' },
        _a['sign-in'] = { class: '', svg: 'sign-in' },
        _a['circleplus'] = { class: '', svg: 'circleplus' },
        _a['circleplusWhite'] = { class: 'Icon--circleplus--white', svg: 'circleplus' },
        _a['file-print'] = { class: 'Icon--fileprint', svg: 'file-print' },
        _a['balance-scale'] = { class: '', svg: 'balance-scale' },
        _a['fast-forward'] = { class: '', svg: 'fast-forward' },
        _a['Osquare'] = { class: '', svg: 'osquare' },
        _a);
    return IconDefinitionsComponent;
}());
export { IconDefinitionsComponent };
