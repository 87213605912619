import { Global } from 'src/app/common/global';
import { IDimensionValue } from 'src/app/services/api/api-model';

export class DimensionView implements IDimensionValue {
  public Id: number;
  public IdView?: number;
  public CompanyId: number;
  public DimensionNumber: number;
  public Value?: string;
  public Description?: string;
  public ApproverCompanyUserId?: number;
  public LimitToDepartmentId?: number;
  public LimitToTemplateId?: number;

  constructor(data?: IDimensionValue) {
    if (data) {
      Object.assign(this, data);
    } else {
      this.Id = 0;
    }
  }
}
