import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { CustomControlsModule } from '../../custom-controls/custom-controls.module';
import { SharedComponentsModule } from '../../shared-components/shared-components.module';

import { CompanyApprovalGridComponent } from './company-approval-grid.component';
import { CompanyApprovalComponent } from './company-approval.component';

@NgModule({
  imports: [CommonModule, TranslateModule, CustomControlsModule, SharedComponentsModule],
  declarations: [CompanyApprovalComponent, CompanyApprovalGridComponent],
  exports: [CompanyApprovalComponent, CompanyApprovalGridComponent],
  entryComponents: [CompanyApprovalComponent, CompanyApprovalGridComponent]
})
export class CompanyApprovalModule {}
