import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { SalaryType } from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { CompanyService } from 'src/app/services/company.service';
import { SessionService } from 'src/app/services/session/session.service';
import { SettingService } from 'src/app/services/setting.service';
import { environment } from 'src/environments/environment';
import { SalaryTypeView } from '../advanced-types/salary-types/salary-type-view';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/api/data.service";
import * as i2 from "../../../services/api/static-data.service";
import * as i3 from "../../../services/setting.service";
import * as i4 from "../../../services/session/session.service";
import * as i5 from "@uirouter/core";
import * as i6 from "../../../services/broadcast.service";
var IntegrationsService = /** @class */ (function (_super) {
    tslib_1.__extends(IntegrationsService, _super);
    function IntegrationsService(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) {
        var _this = _super.call(this, dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) || this;
        _this.dataService = dataService;
        _this.staticDataService = staticDataService;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.transitionService = transitionService;
        _this.broadcaster = broadcaster;
        _this.configurationControl = [];
        _this.credentialControl = [];
        _this.integrationConfigurationControl = [];
        _this.onContinuteState = new EventEmitter();
        _this.TriggerReload = new EventEmitter();
        _this.externalSystemOperation = [];
        _this.externalSystemCredential = [];
        _this.externalSystemConfiguration = [];
        _this.ApiDataList = [];
        _this.ngUnsubscribe = new Subject();
        _this.tabValid = true;
        _this.staticDataService.ExternalSystemOperation.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (data) { return (_this.externalSystemOperation = data); });
        _this.staticDataService.ExternalSystemCredential.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (data) { return (_this.externalSystemCredential = data); });
        _this.staticDataService.ExternalSystemConfiguration.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (data) { return (_this.externalSystemConfiguration = data); });
        return _this;
    }
    Object.defineProperty(IntegrationsService.prototype, "integrations", {
        get: function () {
            var _this = this;
            if (!this.integrationsSubject) {
                this.integrationsSubject = new BehaviorSubject([]);
            }
            return this.integrationsSubject.asObservable().pipe(tap(function (data) {
                if (data.length === 0) {
                    _this.resetForm();
                }
                if (_this.IdCreate) {
                    var newIntegration = data.find(function (z) { return z.Id === _this.IdCreate; });
                    _this.selectedId = newIntegration ? newIntegration.Id : null;
                }
                else if (!_this.isHasIntegrationSelected && data && data.length > 0) {
                    _this.selectedId = data[0].Id;
                }
            }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntegrationsService.prototype, "integration", {
        get: function () {
            var _this = this;
            if (!this.integrationSubject) {
                this.integrationSubject = new BehaviorSubject(null);
            }
            return this.integrationSubject.asObservable().pipe(tap(function (model) {
                _this.currentIntegrationModel = model;
                if (_this.extenalSystem && _this.currentIntegrationModel) {
                    _this.currentExtenalSystem = _this.extenalSystemValue.find(function (z) { return z.Id === _this.currentIntegrationModel.ExternalSystemId; });
                    if (_this.currentExtenalSystem) {
                        _this.sortCurrentExtenalSystem();
                    }
                }
                _this.cleanUpControl();
                if (_this.currentExtenalSystem) {
                    _this.listControlConfiguration();
                }
            }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntegrationsService.prototype, "standardSalaryTypeView", {
        get: function () {
            if (!this.standardSalaryTypeViewSubject) {
                this.standardSalaryTypeViewSubject = new BehaviorSubject([]);
            }
            return this.standardSalaryTypeViewSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    IntegrationsService.prototype.loadStandardSalaryTypeView = function () {
        var _this = this;
        if (!this.standardSalaryTypeViewSubject) {
            this.standardSalaryTypeViewSubject = new BehaviorSubject([]);
        }
        return this.dataService.SalaryTypes_GetAllSalaryTypeViews().pipe(tap(function (data) {
            var res = data.map(function (s) {
                var base = s.BaseSalaryTypeId
                    ? data.find(function (sType) { return sType.SalaryTypeId === s.BaseSalaryTypeId; })
                    : undefined;
                s.Name = s.Name ? s.Name : base ? base.Name : '';
                s.IconField = s.IsAdvanced === true && !_this.hasSalaryTypeModule ? 'Diamond' : '';
                s.Editable = !(s.IsAdvanced === true && !_this.hasSalaryTypeModule);
                // (s as any).IconField = 'Diamond';
                // (s as any).Editable = false;
                s.classAddOn = s.IconField && s.IconField === 'Diamond' ? 'field-need-module' : '';
                var salaryTypeNew = new SalaryType();
                Object.assign(salaryTypeNew, s);
                salaryTypeNew.Id = s.SalaryTypeId;
                return new SalaryTypeView(salaryTypeNew, false);
            });
            _this.standardSalaryTypeViewSubject.next(res);
        }));
    };
    Object.defineProperty(IntegrationsService.prototype, "extenalSystem", {
        get: function () {
            var _this = this;
            if (!this.extenalSystemSubject) {
                this.loadExtenalSystem();
            }
            return this.extenalSystemSubject.asObservable().pipe(tap(function (model) {
                _this.extenalSystemValue = model;
                if (_this.extenalSystemValue && _this.extenalSystemValue.length > 0) {
                    _this.currentExtenalSystemId = _this.extenalSystemValue[0].Id;
                    _this.globalExtenalSystem = _this.extenalSystemValue[0];
                }
            }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntegrationsService.prototype, "department", {
        get: function () {
            if (!this.departmentSubject) {
                this.departmentSubject = new BehaviorSubject([]);
            }
            return this.departmentSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    IntegrationsService.prototype.getStaticDepartment = function () {
        var _this = this;
        if (!this.departmentSubject) {
            this.departmentSubject = new BehaviorSubject([]);
        }
        this.staticDataService.departments.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.departmentSubject.next(data);
        });
    };
    IntegrationsService.prototype.loadExtenalSystem = function () {
        var _this = this;
        if (!this.extenalSystemSubject) {
            this.extenalSystemSubject = new BehaviorSubject([]);
        }
        // Api load exter nal system
        this.staticDataService.ExternalSystem.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            data = data.filter(function (model) { return model.IsActive === true && model.Id !== -1; });
            _this.extenalSystemSubject.next(data);
        });
    };
    Object.defineProperty(IntegrationsService.prototype, "statusCode", {
        get: function () {
            if (!this.statusCodeSubject) {
                this.loadStatusCode();
            }
            return this.statusCodeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    IntegrationsService.prototype.loadStatusCode = function () {
        var _this = this;
        if (!this.statusCodeSubject) {
            this.statusCodeSubject = new BehaviorSubject([]);
        }
        this.staticDataService.IntegrationStatus.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.statusCodeSubject.next(data);
        });
    };
    Object.defineProperty(IntegrationsService.prototype, "departmentSalaryTypeMapping", {
        get: function () {
            if (!this.departmentSalaryTypeMappingSubject) {
                this.departmentSalaryTypeMappingSubject = new BehaviorSubject([]);
            }
            return this.departmentSalaryTypeMappingSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    IntegrationsService.prototype.setDataDepartmentSalaryTypeMapping = function (data) {
        if (!this.departmentSalaryTypeMappingSubject) {
            this.departmentSalaryTypeMappingSubject = new BehaviorSubject([]);
        }
        this.departmentSalaryTypeMappingSubject.next(data);
    };
    Object.defineProperty(IntegrationsService.prototype, "selectedId", {
        get: function () {
            return this.selectedIdValue;
        },
        set: function (value) {
            if (value !== this.selectedIdValue) {
                this.selectedIdValue = value;
                if (value) {
                    this.loadIntegrationDetail();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntegrationsService.prototype, "departmentId", {
        get: function () {
            return this.departmentIdValue;
        },
        set: function (value) {
            if (value !== this.departmentIdValue) {
                this.departmentIdValue = value;
                if (value) {
                    this.loadDepartmentMapping();
                }
                else {
                    this.TriggerReload.emit(true);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    IntegrationsService.prototype.loadDepartmentMapping = function () {
        var _this = this;
        if (!this.departmentSalaryTypeMappingSubject) {
            this.departmentSalaryTypeMappingSubject = new BehaviorSubject([]);
        }
        this.dataService
            .SalaryTypes_GetDepartmentSalaryTypeMappings(this.departmentIdValue)
            .pipe(takeUntil(this.ngUnsubscribe), tap(function (data) {
            _this.departmentSalaryTypeMappingSubject.next(data);
        }))
            .subscribe();
    };
    Object.defineProperty(IntegrationsService.prototype, "isEmptyIntegrations", {
        get: function () {
            return !this.allIntegration || (this.allIntegration && this.allIntegration.length === 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntegrationsService.prototype, "isReadonlyUser", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntegrationsService.prototype, "isHasIntegrationSelected", {
        get: function () {
            var _this = this;
            if (this.selectedId && this.allIntegration && this.allIntegration.length > 0) {
                return !!this.allIntegration.find(function (item) { return _this.selectedId === item.Id; });
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    IntegrationsService.prototype.ngOnDestroy = function () {
        this.currentExtenalSystemId = null;
        this.currentExtenalSystem = null;
        this.globalExtenalSystem = null;
        this.currentIntegrationModel = undefined;
        this.departmentIdValue = undefined;
        this.selectedId = undefined;
        if (this.integrationSubject) {
            this.integrationSubject.next(null);
        }
        if (this.standardSalaryTypeViewSubject) {
            this.standardSalaryTypeViewSubject.next([]);
        }
        if (this.integrationsSubject) {
            this.integrationsSubject.next([]);
        }
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(IntegrationsService.prototype, "allowShortcut", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.configuration.integrations';
        },
        enumerable: true,
        configurable: true
    });
    IntegrationsService.prototype.onContinueAction = function () {
        this.onContinuteState.emit(true);
    };
    IntegrationsService.prototype.onGetModulesViewComplete = function (modules) {
        /// SalaryTypeModuleId === 4
        var module = modules && modules.length ? modules.find(function (m) { return m.ModuleId === 4; }) : undefined;
        if (module) {
            this.hasSalaryTypeModule = module.IsEnabled;
            this.moduleSalaryTypePrice = module.ModulePrice;
            this.moduleSalaryTypeName = module.ModuleName;
        }
        else {
            this.hasSalaryTypeModule = false;
        }
    };
    IntegrationsService.prototype.onDiscardAndLeaveAction = function () {
        var _this = this;
        this.loadIntegration().subscribe(function () {
            _this.tabDirty = false;
            _this.editMode = false;
            _this.moveToNextState();
        });
    };
    IntegrationsService.prototype.cleanUpControl = function () {
        this.credentialControl = [];
        this.configurationControl = [];
        this.integrationConfigurationControl = [];
    };
    IntegrationsService.prototype.inItSalaryTypeView = function () {
        var _this = this;
        this.staticDataService.moduleCompanyView
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (modules) {
            if (modules && modules.length > 0) {
                _this.onGetModulesViewComplete(modules);
                _this.loadStandardSalaryTypeView().subscribe();
            }
        });
    };
    IntegrationsService.prototype.loadIntegrationModel = function () {
        var _this = this;
        this.loadIntegration().subscribe(function () {
            _this.resetForm();
            if (_this.IdCreate) {
                // Alow edit for new integrations
                _this.editMode = true;
                _this.IdCreate = null;
            }
        });
    };
    IntegrationsService.prototype.reloadIntegrationModel = function () {
        var _this = this;
        this.loadIntegration().subscribe(function () {
            _this.resetForm();
            if (_this.selectedId) {
                _this.loadIntegrationDetail();
            }
        });
    };
    IntegrationsService.prototype.resetForm = function () {
        this.tabDirty = false;
        this.editMode = false;
    };
    IntegrationsService.prototype.loadIntegrationDetail = function () {
        var _this = this;
        if (!this.integrationSubject) {
            this.integrationSubject = new BehaviorSubject(null);
        }
        this.dataService
            .Integrations_RetrieveIntegrationWithAllChildren(this.selectedId)
            .subscribe(function (model) {
            _this.integrationSubject.next(model);
        });
    };
    IntegrationsService.prototype.onSave = function (iSimpleKeyValuePairArray) {
        var _this = this;
        return this.dataService.Integrations_SetIntegrationValuesByKey(this.selectedId, iSimpleKeyValuePairArray).pipe(tap(function () {
            _this.moveToNextState();
        }));
    };
    IntegrationsService.prototype.loadIntegration = function () {
        var _this = this;
        return this.dataService.Integrations_RetrieveIntegrations().pipe(tap(function (integrationLoad) {
            _this.integrationsSubject.next(integrationLoad);
            _this.allIntegration = integrationLoad;
        }));
    };
    IntegrationsService.prototype.createIntegration = function () {
        var _this = this;
        return this.dataService.Integrations_CreateIntegration(this.globalExtenalSystem.Id).pipe(tap(function (data) {
            _this.IdCreate = data.Id;
            _this.editMode = true;
        }));
    };
    IntegrationsService.prototype.deleteIntegration = function (id) {
        var _this = this;
        return this.dataService.Integrations_DeleteIntegration(id).pipe(tap(function () {
            _this.selectedId = undefined;
            _this.currentIntegrationModel = undefined;
            _this.IdCreate = undefined;
            _this.cleanUpControl();
        }));
    };
    IntegrationsService.prototype.sortCurrentExtenalSystem = function () {
        var _this = this;
        if (this.currentExtenalSystem.ConfigurationKeys && this.currentExtenalSystem.ConfigurationKeys.length > 0) {
            var resFilterCurrentConfigurationKeys_1 = [];
            this.externalSystemConfiguration.forEach(function (model) {
                if (_this.currentExtenalSystem.ConfigurationKeys.find(function (temp) { return temp === model.Key; })) {
                    resFilterCurrentConfigurationKeys_1.push(model.Key);
                }
            });
            this.currentExtenalSystem.ConfigurationKeys = resFilterCurrentConfigurationKeys_1;
        }
        if (this.currentExtenalSystem.CredentialKeys && this.currentExtenalSystem.CredentialKeys.length > 0) {
            var resFilterCurrentCredentialKeys_1 = [];
            this.externalSystemCredential.forEach(function (model) {
                if (_this.currentExtenalSystem.CredentialKeys.find(function (temp) { return temp === model.Key; })) {
                    resFilterCurrentCredentialKeys_1.push(model.Key);
                }
            });
            this.currentExtenalSystem.CredentialKeys = resFilterCurrentCredentialKeys_1;
        }
        if (this.currentExtenalSystem.Operations && this.currentExtenalSystem.Operations.length > 0) {
            var resFilterCurrentOperations_1 = [];
            this.externalSystemOperation.forEach(function (model) {
                if (_this.currentExtenalSystem.Operations.find(function (temp) { return temp.Key === model.Key; })) {
                    resFilterCurrentOperations_1.push(model);
                }
            });
            this.currentExtenalSystem.Operations = resFilterCurrentOperations_1;
        }
    };
    IntegrationsService.prototype.createControlOperation = function (extenalsystemOperations) {
        var extenal = this.externalSystemOperation.find(function (model) { return model.Key === extenalsystemOperations.Key; });
        return {
            Id: extenalsystemOperations.Id,
            name: extenalsystemOperations.Name,
            key: extenalsystemOperations.Key,
            Description: extenal && extenal.Description ? extenal.Description : '',
            SortKey: extenal.SortKey
        };
    };
    IntegrationsService.prototype.listControlConfiguration = function () {
        this.createListOperationsControl();
        this.createListCredentialControl();
        this.createListConfigurationControl();
    };
    IntegrationsService.prototype.createListOperationsControl = function () {
        var _loop_1 = function (i) {
            var control = this_1.createControlOperation(this_1.currentExtenalSystem.Operations[i]);
            if (control) {
                var filterIntegrationOperations = this_1.currentIntegrationModel.IntegrationOperations.filter(function (z) { return z.OperationId === control.Id; });
                if (filterIntegrationOperations && filterIntegrationOperations[0]) {
                    control.value = filterIntegrationOperations[0].IsActive;
                    control.translatemessage = filterIntegrationOperations[0].TranslatedStatusMessage;
                    control.statuscode = filterIntegrationOperations[0].StatusCode;
                    control.Description = filterIntegrationOperations[0].Description
                        ? filterIntegrationOperations[0].Description
                        : '';
                }
                control.integrationID = this_1.currentExtenalSystem.Id;
                this_1.configurationControl.push(control);
            }
            this_1.configurationControl.sort(function (itemA, itemB) {
                if (itemA.SortKey > itemB.SortKey) {
                    return 1;
                }
                if (itemA.SortKey < itemB.SortKey) {
                    return -1;
                }
                return 0;
            });
        };
        var this_1 = this;
        for (var i = 0; i < this.currentExtenalSystem.Operations.length; i++) {
            _loop_1(i);
        }
    };
    IntegrationsService.prototype.createControlCredential = function (name) {
        var finResult = this.externalSystemCredential.find(function (model) { return model.Key === name; });
        if (finResult) {
            return {
                name: finResult.Name ? finResult.Name : '',
                key: name,
                Description: finResult.Description || '',
                SortKey: finResult.SortKey
            };
        }
        return undefined;
    };
    IntegrationsService.prototype.createControlIntegrationConfiguration = function (name) {
        var configuration = this.externalSystemConfiguration.find(function (model) { return model.Key === name; });
        return {
            name: configuration && configuration.Name ? configuration.Name : '',
            key: name,
            type: configuration && configuration.FormElement ? configuration.FormElement : '',
            listDataSource: configuration && configuration.ListDataSource ? configuration.ListDataSource : undefined,
            Description: configuration && configuration.Description ? configuration.Description : '',
            SortKey: configuration.SortKey
        };
    };
    IntegrationsService.prototype.getPartsFromListDataSourceStringOfCombobox = function (stringListDataSource) {
        stringListDataSource = stringListDataSource.replace(':', ';');
        var parts = stringListDataSource.split(';');
        var externalSystemName = this.currentExtenalSystem
            ? this.currentExtenalSystem.Name
                ? this.currentExtenalSystem.Name
                : this.currentExtenalSystem.Key
            : '';
        if (parts[0].indexOf('{externalSystemName}') >= 0) {
            parts[0] = parts[0].replace('{externalSystemName}', externalSystemName);
        }
        if (parts[0].indexOf('{IntegrationId}') >= 0) {
            parts[0] = parts[0].replace('{IntegrationId}', this.selectedId.toString());
        }
        return {
            api: parts[0],
            underlyingId: parts[1],
            displayText: parts[2]
        };
    };
    IntegrationsService.prototype.assignControlDataSource = function (control, listDataSourceParts, data) {
        var _this = this;
        var sameApiComboboxes = this.integrationConfigurationControl.filter(function (ctrl) {
            if (ctrl.listDataSource) {
                var ctrlListDataSourceParts = _this.getPartsFromListDataSourceStringOfCombobox(ctrl.listDataSource);
                return ctrlListDataSourceParts.api === listDataSourceParts.api;
            }
            else {
                return false;
            }
        });
        if (!sameApiComboboxes || sameApiComboboxes.length === 0) {
            return;
        }
        control.dataSource = data;
        if (sameApiComboboxes && sameApiComboboxes.length > 0) {
            sameApiComboboxes.forEach(function (apiCombo) {
                if (!apiCombo.dataSource || apiCombo.dataSource.length === 0) {
                    apiCombo.dataSource = data;
                }
            });
        }
    };
    IntegrationsService.prototype.createListCredentialControl = function () {
        var _loop_2 = function (i) {
            var control = this_2.createControlCredential(this_2.currentExtenalSystem.CredentialKeys[i]);
            if (control) {
                var filterIntegrationCredentials = this_2.currentIntegrationModel.IntegrationCredentials.filter(function (z) { return z.Key === control.key; });
                if (filterIntegrationCredentials && filterIntegrationCredentials[0]) {
                    control.value = filterIntegrationCredentials[0].Value;
                    control.Description = filterIntegrationCredentials[0].ExternalSystemCredential.Description;
                }
                this_2.credentialControl.push(control);
            }
            this_2.credentialControl.sort(function (itemA, itemB) {
                if (itemA.SortKey > itemB.SortKey) {
                    return 1;
                }
                if (itemA.SortKey < itemB.SortKey) {
                    return -1;
                }
                return 0;
            });
        };
        var this_2 = this;
        for (var i = 0; i < this.currentExtenalSystem.CredentialKeys.length; i++) {
            _loop_2(i);
        }
    };
    IntegrationsService.prototype.createListConfigurationControl = function () {
        var _this = this;
        var listControls = {};
        var _loop_3 = function (i) {
            var control = this_3.createControlIntegrationConfiguration(this_3.currentExtenalSystem.ConfigurationKeys[i]);
            if (control) {
                var filterIntegrationConfigurations = this_3.currentIntegrationModel.IntegrationConfigurations.filter(function (z) { return z.Key === control.key; });
                if (filterIntegrationConfigurations && filterIntegrationConfigurations[0]) {
                    control.value = filterIntegrationConfigurations[0].Value;
                }
                this_3.integrationConfigurationControl.push(control);
                if (control.type === 'Dropdown') {
                    if (!listControls[control.listDataSource]) {
                        listControls[control.listDataSource] = [];
                    }
                    listControls[control.listDataSource].push(control);
                }
            }
        };
        var this_3 = this;
        for (var i = 0; i < this.currentExtenalSystem.ConfigurationKeys.length; i++) {
            _loop_3(i);
        }
        this.integrationConfigurationControl.sort(function (itemA, itemB) {
            if (itemA.SortKey > itemB.SortKey) {
                return 1;
            }
            if (itemA.SortKey < itemB.SortKey) {
                return -1;
            }
            return 0;
        });
        Object.keys(listControls).forEach(function (key) {
            if (listControls[key].length > 1) {
                _this.loadComboboxDataSource(null, listControls[key]);
            }
            else {
                _this.loadComboboxDataSource(listControls[key][0]);
            }
        });
    };
    IntegrationsService.prototype.loadComboboxDataSource = function (control, listControls) {
        var _this = this;
        if (control === null && listControls && listControls.length > 0) {
            control = listControls[0];
        }
        var listDataSourceParts = this.getPartsFromListDataSourceStringOfCombobox(control.listDataSource);
        control.idField = listDataSourceParts.underlyingId;
        control.textField = listDataSourceParts.displayText;
        var dataModel = this.ApiDataList.length > 0 ? this.ApiDataList.find(function (model) { return model.api === listDataSourceParts.api; }) : null;
        if (dataModel) {
            this.assignControlDataSource(control, listDataSourceParts, dataModel.data);
            this.assignDataSource(listControls, dataModel.data);
        }
        else {
            this.dataService.httpGet(environment.apiUrl + '/' + listDataSourceParts.api).subscribe(function (data) {
                if (data && data.length > 0) {
                    data.forEach(function (model) {
                        model[control.idField] = control.idField && model[control.idField] ? model[control.idField].toString() : '';
                    });
                }
                _this.assignControlDataSource(control, listDataSourceParts, data);
                _this.assignDataSource(listControls, data);
                var nextModel = {
                    api: listDataSourceParts.api,
                    data: data
                };
                _this.ApiDataList.push(nextModel);
            });
        }
    };
    IntegrationsService.prototype.assignDataSource = function (listControls, data) {
        var _this = this;
        if (listControls && listControls.length > 0) {
            listControls.forEach(function (control, index) {
                if (index === 0) {
                    return;
                }
                else {
                    var listDataSourcePartChild = _this.getPartsFromListDataSourceStringOfCombobox(control.listDataSource);
                    control.idField = listDataSourcePartChild.underlyingId;
                    control.textField = listDataSourcePartChild.displayText;
                    _this.assignControlDataSource(control, listDataSourcePartChild, data);
                }
            });
        }
    };
    IntegrationsService.prototype.saveValueControlParam = function () {
        var _this = this;
        var iSimpleKeyValuePairArray = [];
        this.configurationControl.forEach(function (control) {
            var simpleKeyValuePairtemp = {};
            simpleKeyValuePairtemp.Key = 'Operation.' + control.Id;
            simpleKeyValuePairtemp.Value = control.value ? control.value.toString() : 'false';
            iSimpleKeyValuePairArray.push(simpleKeyValuePairtemp);
        });
        this.credentialControl.forEach(function (control) {
            var simpleKeyValuePairtemp = {};
            simpleKeyValuePairtemp.Key = 'Credential.' + control.key;
            simpleKeyValuePairtemp.Value = control.value ? control.value.toString() : '';
            iSimpleKeyValuePairArray.push(simpleKeyValuePairtemp);
        });
        this.integrationConfigurationControl.forEach(function (control) {
            var simpleKeyValuePairtemp = {};
            simpleKeyValuePairtemp.Key = 'Configuration.' + control.key;
            if (control.value && control.value.toString().indexOf('GMT') >= 0) {
                var date = _this.staticDataService.getCurrentdate(null, control.value);
                simpleKeyValuePairtemp.Value = date.toISOString();
                iSimpleKeyValuePairArray.push(simpleKeyValuePairtemp);
            }
            else {
                simpleKeyValuePairtemp.Value = control.value ? control.value.toString() : '';
                iSimpleKeyValuePairArray.push(simpleKeyValuePairtemp);
            }
        });
        this.resetForm();
        this.onSave(iSimpleKeyValuePairArray).subscribe(function () {
            _this.reloadIntegrationModel();
        }, function () {
            _this.reloadIntegrationModel();
        });
    };
    IntegrationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IntegrationsService_Factory() { return new IntegrationsService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.StaticDataService), i0.ɵɵinject(i3.SettingService), i0.ɵɵinject(i4.SessionService), i0.ɵɵinject(i5.TransitionService), i0.ɵɵinject(i6.BroadcastService)); }, token: IntegrationsService, providedIn: "root" });
    return IntegrationsService;
}(CompanyService));
export { IntegrationsService };
