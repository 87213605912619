import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../../common/form-component-base';
import { ICompanyUser, IUserEmployment } from '../../../services/api/api-model';
import { EmployeeService } from '../../../services/employee.service';

@Component({
  selector: 'app-employee-name',
  templateUrl: './employee-name.component.html'
})
export class EmployeeNameComponent extends FormComponentBase implements OnInit, OnDestroy {
  @Input()
  public set companyUser(value: ICompanyUser) {
    this.companyUserValue = value;
    this.childIsValid = Array(4).fill(true);
    this.childIsDirty = Array(4).fill(false);
  }
  public get companyUser(): ICompanyUser {
    if (!this.companyUserValue) {
      this.companyUserValue = {} as any;
    }

    return this.companyUserValue;
  }

  private companyUserValue: ICompanyUser;
  private userEmploymentValue: IUserEmployment;

  constructor(private employeeService: EmployeeService) {
    super();
    this.childIsValid = Array(4).fill(true);
    this.childIsDirty = Array(4).fill(false);
  }

  public ngOnInit() {
    this.employeeService.employment.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: IUserEmployment) => {
      this.userEmploymentValue = value;
    });
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get currentExternalReference(): string {
    return this.userEmploymentValue && this.userEmploymentValue.ExternalReference
      ? `(${this.userEmploymentValue.ExternalReference})`
      : '';
  }
}
