import * as tslib_1 from "tslib";
import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import '@progress/kendo-angular-intl/locales/da-GL/all';
import '@progress/kendo-angular-intl/locales/da/all';
import '@progress/kendo-angular-intl/locales/en-GB/all';
import { version } from '../version';
import localeDa from '@angular/common/locales/da';
import { NgxZendeskWebwidgetConfig } from 'ngx-zendesk-webwidget';
registerLocaleData(localeDa);
var ZendeskConfig = /** @class */ (function (_super) {
    tslib_1.__extends(ZendeskConfig, _super);
    function ZendeskConfig() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.lazyLoad = true;
        _this.accountUrl = 'intect.zendesk.com';
        _this.injectionTag = 'body';
        return _this;
    }
    ZendeskConfig.prototype.callback = function (zE) {
        // You can call every command you want in here
        zE('webWidget', 'hide');
    };
    return ZendeskConfig;
}(NgxZendeskWebwidgetConfig));
export { ZendeskConfig };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http, 'translation/' + version + '-locale.', '.json');
}
