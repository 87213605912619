import { LocationStrategy } from '@angular/common';
import { AfterViewInit, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Constants } from '../common/constants';
import { Global } from '../common/global';
import { RouterStateService } from '../common/router-state.service';
import { ICompany, IRole, ISimpleCompany } from '../services/api/api-model';
import { DataService } from '../services/api/data.service';
import { StaticDataService } from '../services/api/static-data.service';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { BroadcastService } from '../services/broadcast.service';
import { BusyIndicatorService } from '../services/busy-indicator.service';
import { EmployeeService } from '../services/employee.service';
import { ModalService } from '../services/modal.service';
import { SessionService } from '../services/session/session.service';
import { SettingService } from '../services/setting.service';
var TabsComponent = /** @class */ (function () {
    function TabsComponent(translate, authenticationService, dataService, settingService, modalService, broadcaster, sessionService, staticDataService, renderer, meta, employeeService, busyIndicatorService, locationStrategy, state) {
        var _this = this;
        this.translate = translate;
        this.authenticationService = authenticationService;
        this.dataService = dataService;
        this.settingService = settingService;
        this.modalService = modalService;
        this.broadcaster = broadcaster;
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.renderer = renderer;
        this.meta = meta;
        this.employeeService = employeeService;
        this.busyIndicatorService = busyIndicatorService;
        this.locationStrategy = locationStrategy;
        this.state = state;
        this.currentCompanyName = 'Unknow Company';
        this.currentCompanyAbbreviation = 'UC';
        this.isDetecGoogleTranslate = false;
        this.isNavigateBack = false;
        this.limitTimeLoading = 20;
        this.isLoading = true;
        this.loadingTipText = '';
        this.isDetectedAdBlock = false;
        this.ngUnsubscribe = new Subject();
        var url = window.location.href;
        if (!this.isMobile && url.indexOf('file://') > -1) {
            window.location.href = 'https://intect.app';
        }
        if (!this.sessionService.IsAuthenticated) {
            this.sessionService.NavigateTo('login');
            return;
        }
        if (Global.SESSION && Global.SESSION.SignOnToken.Language.CultureCode) {
            var cultureCode = Global.SESSION.SignOnToken.Language.CultureCode;
            var shortCultureCode = cultureCode.substr(0, 2);
            this.translate.use(shortCultureCode).subscribe(function () {
                _this.settingService.applyTranslations();
                _this.translateText();
            });
        }
    }
    TabsComponent.prototype.tabsBeforeunload = function () {
        Global.isOpenTabs = '';
    };
    Object.defineProperty(TabsComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabsComponent.prototype, "logoAlias", {
        get: function () {
            if (Global.SESSION) {
                switch (Global.SESSION.CurrentSystemName) {
                    case 'Colosal':
                        return 'images/Colosal.svg';
                        break;
                    case 'Facit':
                        return 'images/Facit.svg';
                        break;
                }
            }
            return 'images/intect_H_logo.svg';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabsComponent.prototype, "logoClass", {
        get: function () {
            if (Global.SESSION) {
                switch (Global.SESSION.CurrentSystemName) {
                    case 'Colosal':
                    case 'Facit':
                        return 'svgLine';
                }
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabsComponent.prototype, "isDemo", {
        get: function () {
            return this.sessionService.feature.IsDemoVersion;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabsComponent.prototype, "isIOSMobileApp", {
        get: function () {
            return this.sessionService.browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabsComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabsComponent.prototype, "isDetectedCookieDisable", {
        get: function () {
            return this.sessionService.isDetectedCookieDisable;
        },
        set: function (value) {
            if (value) {
                this.sessionService.isDetectedCookieDisable = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabsComponent.prototype, "isSingleListcompanis", {
        get: function () {
            if (this.companies && this.companies.length <= 1) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabsComponent.prototype, "Companytooltip", {
        get: function () {
            if (this.logo || this.sessionService.feature.IsDemoVersion) {
                return this.currentCompanyName;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    TabsComponent.prototype.ngAfterViewInit = function () { };
    // Just using for detect google translate
    // Reload page to prevent google add more html element to web
    TabsComponent.prototype.onDisableDetect = function (event) {
        window.location.reload();
    };
    TabsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.renderer.removeClass(document.body, 'Login-bg');
        this.renderer.addClass(document.body, 'main-bg');
        this.setFields();
        this.getCompanies();
        this.bindResizeEvent();
        this.useVerticalMenu();
        this.locationStrategy.onPopState(function () {
            var state = window.location.href;
            if (state.indexOf('login') >= 0) {
                _this.sessionService.isBackToLogin = true;
            }
        });
        this.sessionService.checkIsAliveErrorChange.subscribe(function (value) {
            if (value && _this.modalService.status === 401 && _this.modalService.alertVisible) {
                _this.modalService.alertVisible = false;
            }
        });
        this.renderer.listen(document, 'keydown', function (event) {
            return _this.broadcaster.broadcast(Constants.GLOBAL_KEY_DOWN, event);
        });
        this.renderer.listen(window, 'click', function (event) {
            if (!_this.isCollapseButtonClick) {
                _this.navigationExpanded = false;
            }
            else {
                _this.isCollapseButtonClick = false;
            }
        });
        this.setupEventHandlers();
        this.checkLoading();
        this.checkDuplicateTab();
        this.employeeService.employees.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () { });
    };
    TabsComponent.prototype.checkDuplicateTab = function () {
        if (this.sessionService.IsAuthenticated &&
            !this.sessionService.browser.isMobile &&
            !this.sessionService.redirectToApp) {
            this.sessionService.redirectToApp = false;
            var dataTabload = Global.isOpenTabs;
            if (!dataTabload) {
                Global.isOpenTabs = 'isOnOpen';
            }
            else {
                this.sessionService.restoreDefault = false;
                this.sessionService.NavigateTo('login');
            }
        }
        this.sessionService.redirectToApp = false;
    };
    Object.defineProperty(TabsComponent.prototype, "isShowDropDown", {
        get: function () {
            var isShow = 0;
            if (this.sessionService.feature.EmployeeTabEnabled) {
                isShow++;
            }
            if (this.sessionService.feature.CompanyTabEnabled) {
                isShow++;
            }
            if (this.sessionService.feature.AccountantTabEnabled) {
                isShow++;
            }
            return isShow > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabsComponent.prototype, "isHideSelfService", {
        get: function () {
            if (this.sessionService.feature.AccountantTabEnabled) {
                return !this.employeeService.isCurrentUserHasEmployment;
            }
            return !this.employeeService.isCurrentUserExistIncompanyUsers;
        },
        enumerable: true,
        configurable: true
    });
    TabsComponent.prototype.checkLoading = function () {
        var _this = this;
        this.limitTimeLoading--;
        if (this.limitTimeLoading === 0) {
            this.afterLoading();
            return;
        }
        if (!this.sessionService.isGetting && !this.sessionService.isSubmitting) {
            setTimeout(function () {
                _this.afterLoading();
            }, 1000);
        }
        else {
            setTimeout(function () {
                _this.checkLoading();
            }, 1000);
        }
    };
    TabsComponent.prototype.afterLoading = function () {
        var _this = this;
        this.isLoading = false;
        this.settingService.isReload = false;
        setTimeout(function () {
            if (document.getElementById('DHOebGNflhUB')) {
                _this.isDetectedAdBlock = false;
            }
            else {
                if (!Global.isFirstCheckMessageAdBlock) {
                    Global.isFirstCheckMessageAdBlock = true;
                    _this.isDetectedAdBlock = true;
                }
            }
            if (!_this.isMobile) {
                try {
                    if (document.cookie.indexOf('languageId') !== -1) {
                        _this.isDetectedCookieDisable = false;
                    }
                    else {
                        _this.isDetectedCookieDisable = true;
                    }
                }
                catch (e) {
                    _this.isDetectedCookieDisable = true;
                }
            }
            window.scrollTo(0, 1);
        });
    };
    TabsComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    TabsComponent.prototype.navigateToState = function (name) {
        var _this = this;
        if (this.sessionService.isSubmitting) {
            return;
        }
        this.navigationExpanded = false;
        this.sessionService.menuToggled = true;
        if (!this.sessionService.currentState.includes(name)) {
            if (name === 'tabs.selfservice') {
                this.employeeService.selectEmployeeAsync(Global.SESSION.CurrentCompanyUser.Id).subscribe(function (data) {
                    _this.sessionService.NavigateTo(name);
                });
            }
            else {
                this.sessionService.NavigateTo(name);
            }
            return;
        }
        if (this.sessionService.currentState === name) {
            var currentRoleKey_1 = Global.SESSION.CurrentRole.Key;
            switch (name) {
                case 'tabs.employee':
                    if (currentRoleKey_1) {
                        switch (currentRoleKey_1) {
                            case 'FullAdmin':
                                if (Global.IsEmployeeTaskCompleted) {
                                    this.sessionService.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
                                    this.sessionService.NavigateTo('tabs.employee.payrolldata');
                                }
                                else {
                                    this.sessionService.currentStyleMenuTabpanelReponsive = '';
                                    this.sessionService.NavigateTo('tabs.employee.general');
                                }
                                break;
                            case 'ReadOnly':
                                this.sessionService.NavigateTo('tabs.employee.payrolldata');
                                break;
                            case 'Employee':
                                var hasManager = this.sessionService.role.EmployeeWithRoleAsManager;
                                if (hasManager) {
                                    this.sessionService.NavigateTo('tabs.employee.time');
                                }
                                this.sessionService.NavigateTo('tabs.selfservice.payslip');
                                break;
                            case 'SalaryAdmin':
                                this.sessionService.NavigateTo('tabs.employee.payrolldata');
                                break;
                            default:
                                break;
                        }
                    }
                    break;
                case 'tabs.company':
                    if (currentRoleKey_1) {
                        switch (currentRoleKey_1) {
                            case 'FullAdmin':
                                this.sessionService.NavigateTo('tabs.company.salarybatches');
                                break;
                            case 'ReadOnly':
                                this.sessionService.NavigateTo('tabs.company.salarybatches');
                                break;
                            case 'Employee':
                                if (Global.SESSION.ManagerForUserEmploymentIds.length > 0) {
                                    this.sessionService.NavigateTo('tabs.company.approval.timeentry');
                                }
                                this.sessionService.NavigateTo('tabs.selfservice.payslip');
                                break;
                            case 'SalaryAdmin':
                                this.sessionService.NavigateTo('tabs.company.salarybatches');
                                break;
                            default:
                                break;
                        }
                    }
                    break;
                case 'tabs.selfservice':
                    this.employeeService.selectEmployeeAsync(Global.SESSION.CurrentCompanyUser.Id).subscribe(function (data) {
                        if (currentRoleKey_1) {
                            switch (currentRoleKey_1) {
                                case 'FullAdmin':
                                    _this.sessionService.NavigateTo('tabs.selfservice.payslip');
                                    break;
                                case 'ReadOnly':
                                    _this.sessionService.NavigateTo('tabs.selfservice.payslip');
                                    break;
                                case 'Employee':
                                    _this.sessionService.NavigateTo('tabs.selfservice.payslip');
                                    break;
                                case 'SalaryAdmin':
                                    _this.sessionService.NavigateTo('tabs.selfservice.payslip');
                                    break;
                                default:
                                    break;
                            }
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    };
    Object.defineProperty(TabsComponent.prototype, "doubleClickToEditHintVisible", {
        get: function () {
            return (this.sessionService.currentState === 'tabs.employee.general' ||
                this.sessionService.currentState === 'tabs.employee.hiringstatus' ||
                this.sessionService.currentState === 'tabs.company.general' ||
                this.sessionService.currentState === 'tabs.company.configuration.hiringstatus' ||
                this.sessionService.currentState === 'tabs.selfservice.general');
        },
        enumerable: true,
        configurable: true
    });
    TabsComponent.prototype.onCompanyChange = function (companyId) {
        var _this = this;
        if (!this.selectedCompanyId) {
            this.selectedCompanyId = -9999;
        }
        if (companyId &&
            this.selectedCompanyId &&
            this.selectedCompanyId !== companyId &&
            companyId !== Global.COMPANY_ID) {
            this.settingService.ReloadWarrning = true;
            this.isLoading = true;
            this.loadingTipText = this.loadingTipsTextChangeCompany;
            this.setCompanyContext(companyId);
        }
        setTimeout(function () {
            _this.settingService.showCompanySelector = false;
        });
    };
    TabsComponent.prototype.onDPATermAccepted = function (isValid) {
        var _this = this;
        if (!isValid) {
            this.authenticationService
                .logout()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(function () {
                _this.navigateToLogin();
            }, function () {
                _this.navigateToLogin();
            });
        }
    };
    TabsComponent.prototype.onToggleNavigation = function () {
        if (!this.isShowDropDown) {
            return;
        }
        this.isCollapseButtonClick = true;
        this.navigationExpanded = !this.navigationExpanded;
    };
    TabsComponent.prototype.onToggleCompanyDropdown = function () {
        var _this = this;
        if (!this.sessionService.isGetting) {
            this.sessionService.showHelpMenuToggled = false;
            this.sessionService.showMoreMenuToggled = false;
            this.sessionService.showMenuToggled = false;
            if (this.isSingleListcompanis === false) {
                if (this.settingService.showCompanySelector === true) {
                    this.settingService.showCompanySelector = false;
                    return;
                }
                setTimeout(function () {
                    _this.settingService.showCompanySelector = !_this.settingService.showCompanySelector;
                });
            }
        }
    };
    Object.defineProperty(TabsComponent.prototype, "IsDanishCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabsComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    TabsComponent.prototype.reloadStaticData = function () {
        var _this = this;
        return this.staticDataService
            .loadStaticData()
            .then(function () {
            var isAvailableTab = false;
            var currentState = _this.sessionService.currentState;
            _this.sessionService.refeshRoleAndFeature();
            if (currentState) {
                isAvailableTab = _this.sessionService.isAvailableTab(currentState, _this.IsDanishCompany, _this.IsGreenlandCompany);
            }
            if (!isAvailableTab) {
                if (_this.sessionService.role.IsEmployeeRole) {
                    _this.selfserviceTabHandleForOrtherCompany();
                    return;
                }
                _this.sessionService.switchToDefaultTab();
            }
            _this.dataService.Companies_GetCurrent().subscribe(function (activeCompany) {
                Global.COMPANY_ID = _this.activeCompany && _this.activeCompany.Id ? _this.activeCompany.Id : null;
                Global.COMPANY = activeCompany;
                window.location.reload();
            }, function () {
                _this.settingService.ReloadWarrning = false;
            });
        })
            .catch(function () {
            _this.settingService.ReloadWarrning = false;
        });
    };
    TabsComponent.prototype.selfserviceTabHandleForOrtherCompany = function () {
        var _this = this;
        this.dataService.Companies_GetCurrent().subscribe(function (activeCompany) {
            Global.COMPANY_ID = _this.activeCompany && _this.activeCompany.Id ? _this.activeCompany.Id : null;
            Global.COMPANY = activeCompany;
            _this.state.clearRedirect('tabs.selfservice');
            _this.state.clearCurrentSubState('tabs.selfservice');
            _this.employeeService.loadEmployeesAsync().then(function () {
                _this.employeeService.selectEmployeeAsync(Global.SESSION.CurrentCompanyUser.Id).subscribe(function () {
                    _this.sessionService.switchToDefaultTab();
                    window.location.reload();
                });
            });
        }, function () {
            _this.settingService.ReloadWarrning = false;
        });
    };
    TabsComponent.prototype.resetViewPort = function () {
        var _this = this;
        var original = this.meta.getTag('name=viewport');
        var forceScale = original.content + ', user-scalable=no, minimum-scale=1.0, maximum-scale=1.0';
        this.meta.updateTag({ name: 'viewport', content: forceScale }, 'name=viewport');
        setTimeout(function () { return _this.meta.updateTag({ name: 'viewport', content: original.content }, 'name=viewport'); });
    };
    TabsComponent.prototype.bindResizeEvent = function () {
        var _this = this;
        this.clientWidth = document.body.clientWidth;
        this.renderer.listen(window, 'resize', function () {
            _this.useVerticalMenu();
            var diff = document.body.clientWidth - _this.clientWidth;
            if (_this.isIOSMobileApp && Math.abs(diff) > 10) {
                _this.renderer.removeClass(document.body, 'mobileIOSApp');
                _this.clientWidth = document.body.clientWidth;
            }
        });
    };
    TabsComponent.prototype.useVerticalMenu = function () {
        if (window.matchMedia('(max-height: 500px)').matches || window.matchMedia('(max-width: 1000px)').matches) {
            if (!this.settingService.showCompanySelector) {
                this.sessionService.menuToggled = true;
            }
        }
        else {
            this.sessionService.menuToggled = false;
        }
    };
    TabsComponent.prototype.translateText = function () {
        var _this = this;
        this.translate
            .get([
            'Login.UpdatedMessage',
            'UnsuccessfulAPI.404',
            'Tips.ChangeCompany',
            'Tips.MobileChangeCompany',
            'Login.UpdatedMessageUrl',
            'Error.401ErrorWarningResponse'
        ])
            .subscribe(function (translations) {
            _this.updatedMessage = translations['Login.UpdatedMessage'].replace('{{SystemAlias}}', environment.branding.SystemAlias);
            _this.updatedMessageUrl = translations['Login.UpdatedMessageUrl'].replace('{{SystemAlias}}', environment.branding.SystemAlias);
            _this.updatedMessage = _this.updatedMessage.replace('{UrlFeauture}', _this.updatedMessageUrl);
            _this.sessionService.errorMessage404 = translations['UnsuccessfulAPI.404'];
            _this.sessionService.errorMessage401 = translations['Error.401ErrorWarningResponse'];
            _this.settingService.showModuleMessage('ShowUpdateNotification', _this.updatedMessage);
            if (_this.isMobile) {
                _this.loadingTipsTextChangeCompany = translations['Tips.MobileChangeCompany'];
            }
            else {
                _this.loadingTipsTextChangeCompany = translations['Tips.ChangeCompany'];
            }
        });
        this.translate
            .get([
            'GlobalDialog.AcceptDPAButton',
            'GlobalDialog.AcceptWarnings',
            'GlobalDialog.Apply',
            'GlobalDialog.Approve',
            'GlobalDialog.AlreadyRequestedTaxCardConfirmButton',
            'GlobalDialog.Cancel',
            'GlobalDialog.CancelDiscard',
            'GlobalDialog.ChangePassword',
            'GlobalDialog.ClearErrors',
            'GlobalDialog.ConfirmDiscard',
            'GlobalDialog.Continue',
            'GlobalDialog.CompleteImportText',
            'GlobalDialog.Close',
            'GlobalDialog.Create',
            'GlobalDialog.CreateWithoutEmails',
            'GlobalDialog.Delete',
            'GlobalDialog.DisableThisBehaviour',
            'GlobalDialog.DiscardAndLeave',
            'GlobalDialog.Download',
            'GlobalDialog.DeleteEmploymentButtonText',
            'GlobalDialog.DeleteCompanyUserButtonText',
            'GlobalDialog.Edit',
            'GlobalDialog.Enable',
            'GlobalDialog.EnableAutoCorrect',
            'GlobalDialog.Finalize',
            'GlobalDialog.Hide',
            'GlobalDialog.Import',
            'GlobalDialog.IKnowWhatIamDoing',
            'GlobalDialog.Lock',
            'GlobalDialog.Lock and notify employees',
            'GlobalDialog.Logout',
            'GlobalDialog.MoreHelp',
            'GlobalDialog.No',
            'GlobalDialog.Ok',
            'GlobalDialog.Attach',
            'GlobalDialog.Proceed',
            'GlobalDialog.ReadMore',
            'GlobalDialog.Reject',
            'GlobalDialog.ReSend',
            'GlobalDialog.ResetPassword',
            'GlobalDialog.Revert',
            'GlobalDialog.RevertToDraft',
            'GlobalDialog.Save',
            'GlobalDialog.Submit',
            'GlobalDialog.SubmitForApproval',
            'GlobalDialog.Stay',
            'GlobalDialog.Test',
            'GlobalDialog.Understood',
            'GlobalDialog.Update',
            'GlobalDialog.UsePositiveAmountThisTime',
            'GlobalDialog.Yes',
            'GlobalDialog.CreateNewCompany',
            'GlobalDialog.Login',
            'GlobalDialog.ForgotPassword',
            'GlobalDialog.ContinueAndSaveChange',
            'EmploymentPayrollData.MoreInfo',
            'EmploymentPayrollData.UsePositiveAmountThisTime',
            'Error.NetworkError',
            'GlobalDialog.YesPlease',
            'GlobalDialog.NoThankYou',
            'GlobalDialog.ForceRecalculate',
            'GlobalDialog.RevertFinalizing',
            'CompanyDataImport.SaveDefinition',
            'CompanyDataImport.ImportOnce'
        ])
            .subscribe(function (translations) {
            _this.sessionService.modalTranslations = translations;
            _this.sessionService.modalTranslations['Error.NetworkError'] = _this.sessionService.modalTranslations['Error.NetworkError'].replace('{{SystemAlias}}', environment.branding.SystemAlias);
        });
    };
    TabsComponent.prototype.setFields = function () {
        if (Global.SESSION) {
            this.sessionService.roleKey = Global.SESSION ? Global.SESSION.CurrentRole.Key : undefined;
        }
    };
    TabsComponent.prototype.setupEventHandlers = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (e) {
            return _this.translateText();
        });
        document.addEventListener('backbutton', function () {
            _this.onBackButtonClicked();
        }, false);
        this.sessionService.OnLocationChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (state) {
            _this.handleStatesForBackButton();
            _this.resetViewPort();
        });
        this.broadcaster
            .on(Constants.SHOW_EDIT_HINT)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function () {
            var showToastMessage = _this.doubleClickToEditHintVisible;
            _this.settingService.showDoubleClickToEditHint(showToastMessage);
        });
    };
    TabsComponent.prototype.onBackButtonClicked = function () {
        var statesHistory = Global.statesHistory ? Global.statesHistory : [];
        statesHistory.pop();
        if (statesHistory.length > 0) {
            this.isNavigateBack = true;
            this.sessionService.NavigateTo(statesHistory[statesHistory.length - 1]);
            Global.statesHistory = statesHistory;
        }
    };
    TabsComponent.prototype.handleStatesForBackButton = function () {
        var statesHistory = Global.statesHistory ? Global.statesHistory : [];
        if (!this.isNavigateBack) {
            statesHistory.push(this.sessionService.currentState);
            Global.statesHistory = statesHistory;
        }
        this.isNavigateBack = false;
    };
    TabsComponent.prototype.navigateToLogin = function () {
        this.sessionService.NavigateTo('login');
    };
    TabsComponent.prototype.getCompanies = function () {
        var _this = this;
        if (!this.companies || (this.companies && this.companies.length === 0)) {
            this.staticDataService.companiesSimple.subscribe(function (data) {
                _this.staticDataService.Role.subscribe(function (statisticOptions) {
                    var roleList = {};
                    statisticOptions.forEach(function (option) {
                        roleList[option.Id] = option.Name;
                    });
                    data.forEach(function (company) {
                        company.RoleName = roleList[company.RoleId];
                    });
                });
                _this.companies = data;
                var activeCompany = Global.COMPANY;
                if (!activeCompany && !activeCompany.Id) {
                    _this.getCurrentCompany();
                }
                else {
                    _this.setInformationCurrentCompany(activeCompany);
                }
            });
        }
    };
    TabsComponent.prototype.setInformationCurrentCompany = function (activeCompany) {
        this.activeCompany = activeCompany;
        this.selectedCompanyId = this.activeCompany.Id;
        Global.COMPANY_ID = this.activeCompany.Id;
        this.getCompanyLogo();
        if (this.sessionService.role.IsFullAdmin && !activeCompany.HasAcceptedTerms) {
            this.showAcceptDPADialog = true;
        }
    };
    TabsComponent.prototype.getCurrentCompany = function () {
        var _this = this;
        this.staticDataService.currentCompany.subscribe(function (activeCompany) {
            if (activeCompany) {
                Global.COMPANY = activeCompany;
                _this.setInformationCurrentCompany(activeCompany);
            }
        });
    };
    TabsComponent.prototype.setCompanyContext = function (companyId) {
        var _this = this;
        this.dataService.Auth_SetCompanyContext(companyId).subscribe(function () {
            Global.COMPANY_ID = companyId;
            _this.settingService.showCompanySelector = false;
            _this.reloadStaticData()
                .then(function () { })
                .catch(function () {
                _this.settingService.ReloadWarrning = false;
            });
        }, function () {
            _this.settingService.ReloadWarrning = false;
        });
    };
    TabsComponent.prototype.getCompanyLogo = function () {
        var _this = this;
        this.staticDataService.currentLogo.subscribe(function (image) {
            _this.logo = image;
            _this.showCompanyAbbreviation = true;
            _this.currentCompanyName = _this.activeCompany.Name;
            _this.currentCompanyAbbreviation = _this.currentCompanyName
                ? _this.currentCompanyName.toUpperCase().substring(0, 3)
                : '';
        });
    };
    return TabsComponent;
}());
export { TabsComponent };
