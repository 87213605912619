<div class="FormElement has-fixedAddon">
  <span [ngClass]="{ textWithTooltip: tooltip }" kendoTooltip class="FormElement-label" [title]="tooltip"
    >{{ label }}:</span
  >
  <div class="FormElement-control alignItems Pension-field-area">
    <div>
      <app-numeric-edit
        [editMode]="editMode"
        [disable]="disable"
        [(isDirty)]="childIsDirty[0]"
        [(value)]="firstValue"
        (valueChange)="onChange()"
        [option]="numericTextBoxOption"
      ></app-numeric-edit>
      <span class="FormElement-addon">&nbsp;</span>
      <span class="FormElement-addon">/</span>
      <app-numeric-edit
        [editMode]="editMode"
        [disable]="disable"
        [(isDirty)]="childIsDirty[1]"
        [(value)]="secondValue"
        (valueChange)="onChange()"
        [option]="numericTextBoxOption"
      ></app-numeric-edit>
      <span class="FormElement-addon">&nbsp;</span>
    </div>
    <!-- <span class="FormElement-addon">/</span> -->

    <div>
      <app-module-check [moduleId]="8">
        <app-combobox-edit
          [(isDirty)]="childIsDirty[2]"
          [editMode]="editMode"
          [comboboxDataSource]="PensionIsFixedAmountPerWorkDayDataSource"
          [(value)]="comboboxthirdValue"
          [textField]="'Name'"
          [idField]="'value'"
          (valueChange)="onChange()"
          [disable]="disable"
        ></app-combobox-edit>
      </app-module-check>
    </div>
  </div>
</div>
