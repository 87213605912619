import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { CompanyService } from '../../../services/company.service';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/api/data.service";
import * as i2 from "../../../services/api/static-data.service";
import * as i3 from "../../../services/setting.service";
import * as i4 from "../../../services/session/session.service";
import * as i5 from "@uirouter/core";
import * as i6 from "../../../services/broadcast.service";
var CompanyTemplatesService = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyTemplatesService, _super);
    function CompanyTemplatesService(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) {
        var _this = _super.call(this, dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) || this;
        _this.dataService = dataService;
        _this.staticDataService = staticDataService;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.transitionService = transitionService;
        _this.broadcaster = broadcaster;
        _this.selectedTemplateIdChange = new EventEmitter();
        _this.updateTemplateOnlyChange = new EventEmitter();
        _this.resetFormChange = new EventEmitter();
        _this.ngUnsubscribe = new Subject();
        return _this;
    }
    Object.defineProperty(CompanyTemplatesService.prototype, "templates", {
        get: function () {
            if (!this.templateSubject) {
                this.templateSubject = new BehaviorSubject([]);
            }
            return this.templateSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CompanyTemplatesService.prototype.loadTemplates = function (selectTemplateId) {
        var _this = this;
        if (!this.templateSubject) {
            this.templateSubject = new BehaviorSubject([]);
        }
        this.dataService
            .EmploymentTemplates_GetEmploymentTemplates(selectTemplateId ? true : false)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (templates) {
            var res = templates;
            _this.templateSubject.next(res && res.length > 0 ? res : []);
            _this.allTemplates = res || [];
            if (_this.allTemplates.length > 0) {
                _this.isEnable = true;
            }
            else {
                _this.isEnable = false;
            }
            if (selectTemplateId || (_this.allTemplates && _this.allTemplates.length > 0)) {
                _this.selectedTemplateId = selectTemplateId || _this.allTemplates[0].Id;
            }
            if (selectTemplateId) {
                _this.editMode = true;
            }
        });
    };
    Object.defineProperty(CompanyTemplatesService.prototype, "selectedTemplateId", {
        get: function () {
            return this.selectedTemplateIdValue;
        },
        set: function (value) {
            if (value && this.selectedTemplateIdValue !== value) {
                this.selectedTemplateIdValue = value;
                this.selectedTemplateIdChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyTemplatesService.prototype, "templateDetail", {
        get: function () {
            if (this.templateDetailValue === undefined) {
                return {};
            }
            return this.templateDetailValue;
        },
        set: function (value) {
            if (value !== this.templateDetailValue) {
                if (value) {
                    this.upDateTemplateSenioritySupplements(value);
                }
                this.templateDetailValue = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    CompanyTemplatesService.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    CompanyTemplatesService.prototype.onContinueAction = function () {
        this.onSave();
    };
    CompanyTemplatesService.prototype.onDiscardAndLeaveAction = function () {
        this.onDiscard();
    };
    CompanyTemplatesService.prototype.onSave = function () {
        if (this.tabDirty && this.templateDetail && this.tabValid) {
            if (this.templateHasEmployees) {
                this.confirmSaveOrUpdateDialogVisible = true;
            }
            else {
                this.updateTemplateOnly();
            }
        }
        else if (this.tabValid) {
            this.editMode = false;
        }
    };
    CompanyTemplatesService.prototype.getEmploymentTemplate = function () {
        var _this = this;
        return this.dataService.EmploymentTemplates_GetEmploymentTemplate(this.selectedTemplateId).pipe(tap(function (templateDetail) {
            _this.templateDetail = templateDetail;
            _this.resetFormChange.emit();
        }));
    };
    CompanyTemplatesService.prototype.onDiscard = function () {
        var _this = this;
        if (this.selectedTemplateId) {
            this.editMode = false;
            this.getEmploymentTemplate().subscribe(function () {
                _this.updateTemplateListTemplateName();
                _this.resetFormChange.emit();
                _this.moveToNextState();
            });
        }
    };
    CompanyTemplatesService.prototype.onUpdateTemplate = function () {
        var _this = this;
        var template = this.templateDetail;
        delete template['GridId'];
        this.FixCorrectTemplateValue(template);
        if (template.TemplateBalanceConfigurations && template.TemplateBalanceConfigurations.length > 0) {
            template.TemplateBalanceConfigurations[0].EarningPercentage =
                template.TemplateBalanceConfigurations[0].EarningPercentage || null;
            template.TemplateBalanceConfigurations[0].BaseCalculationMethodId =
                template.TemplateBalanceConfigurations[0].BaseCalculationMethodId || null;
            template.TemplateBalanceConfigurations[0].PensionSharePercentage =
                template.TemplateBalanceConfigurations[0].PensionSharePercentage || null;
        }
        return this.dataService.EmploymentTemplates_UpdateEmploymentTemplate(template).pipe(tap(function (templateDetail) {
            _this.editMode = false;
            _this.templateDetail = templateDetail;
        }));
    };
    CompanyTemplatesService.prototype.onCreateTemplate = function () {
        // TODO on create template
    };
    CompanyTemplatesService.prototype.updateTemplateListTemplateName = function () {
        var _this = this;
        if (this.templateDetail && this.allTemplates) {
            var templateInList = this.allTemplates.find(function (template) {
                return template.Id === _this.templateDetail.Id;
            });
            if (templateInList && templateInList.Name !== this.templateDetail.Name) {
                templateInList.Name = this.templateDetail.Name;
                this.refreshTemplateGridList = !this.refreshTemplateGridList;
            }
        }
    };
    CompanyTemplatesService.prototype.updateTemplateOnly = function () {
        var _this = this;
        this.onUpdateTemplate().subscribe(function () {
            _this.updateTemplateListTemplateName();
            _this.updateTemplateOnlyChange.emit();
            _this.moveToNextState();
        });
    };
    CompanyTemplatesService.prototype.updateTemplateAllEmployee = function () {
        var _this = this;
        var templateDetail = this.templateDetail;
        delete templateDetail['GridId'];
        this.FixCorrectTemplateValue(templateDetail);
        return this.dataService.EmploymentTemplates_UpdateEmploymentTemplateAndApplyChanges(templateDetail).pipe(tap(function (template) {
            _this.resetFormChange.emit();
            _this.editMode = false;
            _this.templateDetail = template;
            _this.updateTemplateListTemplateName();
        }));
    };
    CompanyTemplatesService.prototype.upDateTemplateSenioritySupplements = function (template) {
        if (template.SenioritySupplements && template.SenioritySupplements.length > 0) {
            template.SenioritySupplements.forEach(function (item) {
                item.GridId = item.Id;
            });
        }
        else {
            template.SenioritySupplements = [];
        }
        if (template.OvertimeSupplements && template.OvertimeSupplements.length > 0) {
            template.OvertimeSupplements.forEach(function (item) {
                item.GridId = item.Id;
            });
        }
        else {
            template.OvertimeSupplements = [];
        }
    };
    CompanyTemplatesService.prototype.FixCorrectTemplateValue = function (data) {
        data.VacationProviderId = data.VacationProviderId || null;
        data.VacationSupplementPayoutMonth =
            data.VacationSupplementPayoutMonth === undefined ? 5 : data.VacationSupplementPayoutMonth;
        data.StatisticsSalaryPrincipleId =
            data.StatisticsSalaryPrincipleId === undefined ? null : data.StatisticsSalaryPrincipleId;
        data.StatisticsEmploymentPrincipleId =
            data.StatisticsEmploymentPrincipleId === undefined ? null : data.StatisticsEmploymentPrincipleId;
        data.StatisticsEmploymentTermsId =
            data.StatisticsEmploymentTermsId === undefined ? null : data.StatisticsEmploymentTermsId;
        data.StatisticsJobStatusId = data.StatisticsJobStatusId === undefined ? null : data.StatisticsJobStatusId;
        data.DepartmentId = data.DepartmentId === undefined ? null : data.DepartmentId;
    };
    CompanyTemplatesService.prototype.serviceDestroy = function () {
        this.allTemplates = [];
        this.clearArrayDataState(this.templateSubject);
    };
    CompanyTemplatesService.prototype.clearArrayDataState = function (data) {
        if (data) {
            data.next([]);
        }
        this.selectedTemplateId = undefined;
        this.templateDetail = undefined;
    };
    Object.defineProperty(CompanyTemplatesService.prototype, "allowShortcut", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.configuration.hiringstatus';
        },
        enumerable: true,
        configurable: true
    });
    CompanyTemplatesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyTemplatesService_Factory() { return new CompanyTemplatesService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.StaticDataService), i0.ɵɵinject(i3.SettingService), i0.ɵɵinject(i4.SessionService), i0.ɵɵinject(i5.TransitionService), i0.ɵɵinject(i6.BroadcastService)); }, token: CompanyTemplatesService, providedIn: "root" });
    return CompanyTemplatesService;
}(CompanyService));
export { CompanyTemplatesService };
