import { EventEmitter, OnInit } from '@angular/core';
import { DataService } from '../../../../services/api/data.service';
import { SessionService } from '../../../../services/session/session.service';
var NewTimeEntryTypeDialogComponent = /** @class */ (function () {
    function NewTimeEntryTypeDialogComponent(dataService, sessionService) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.add = new EventEmitter();
        this.validationWarningVisible = false;
    }
    NewTimeEntryTypeDialogComponent.prototype.ngOnInit = function () {
        // this.dataService.TimeEntryTypes_GetDefaultTimeEntryTypes().subscribe((timeEntryTypes: ITimeEntryType[]) => { });
    };
    Object.defineProperty(NewTimeEntryTypeDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                if (value) {
                    this.timeEntryTypeName = undefined;
                    this.baseTimeEntryTypeId = undefined;
                }
                this.visibleValue = value;
                this.visibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTimeEntryTypeDialogComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    NewTimeEntryTypeDialogComponent.prototype.onDialogAction = function (action) {
        var _this = this;
        if (action === 'Create') {
            if (this.isCreateFormInvalid) {
                this.validationWarningVisible = true;
                return;
            }
            var request = void 0;
            if (this.baseTimeEntryTypeId === -1) {
                var customTimeEntryType = { Name: this.timeEntryTypeName, Id: 0 };
                request = this.dataService.TimeEntryTypes_CreateCustomTimeEntryType(customTimeEntryType);
            }
            else if (this.baseTimeEntryTypeId > 0) {
                var inheritTimeEntryTypeRequest = {
                    BaseTimeEntryTypeId: this.baseTimeEntryTypeId,
                    Name: this.timeEntryTypeName
                };
                request = this.dataService.TimeEntryTypes_InheritBaseTimeEntryType(inheritTimeEntryTypeRequest);
            }
            request.subscribe(function (timeEntryType) {
                _this.add.emit(timeEntryType);
                _this.visible = false;
            });
        }
    };
    Object.defineProperty(NewTimeEntryTypeDialogComponent.prototype, "isCreateFormInvalid", {
        get: function () {
            return !this.timeEntryTypeName || !this.baseTimeEntryTypeId;
        },
        enumerable: true,
        configurable: true
    });
    return NewTimeEntryTypeDialogComponent;
}());
export { NewTimeEntryTypeDialogComponent };
