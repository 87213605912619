import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { WindowReference } from '../../../../windowReference';

@Component({
  selector: 'tool-tip-price',
  templateUrl: './tool-tip-price.component.html'
})
export class TooltipPriceComponent {
  constructor(private windowRef: WindowReference) {}

  @Input() public Tooltiptitle: string;

  @ViewChild('tooltip', { static: true }) private tooltip: any;

  @HostListener('document:click')
  public hide() {
    this.tooltip.hide();
  }

  public onClick(packet: any): void {
    setTimeout(() => {
      this.tooltip.show(packet);
    });
  }

  public get tooltipPackageWidth(): number {
    const width: number = this.windowRef && this.windowRef.nativeWindow ? this.windowRef.nativeWindow.innerWidth : 800;
    return width > 790 ? 500 : width * 0.9;
  }
}
