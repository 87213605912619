import { Injectable, OnDestroy } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ISalaryType, ISalaryTypeCategoryView } from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { CompanyService } from 'src/app/services/company.service';
import { SessionService } from 'src/app/services/session/session.service';
import { SettingService } from 'src/app/services/setting.service';
import { SalaryTypeView } from './salary-type-view';

@Injectable({
  providedIn: 'root'
})
export class CompanySalaryTypeService extends CompanyService implements OnDestroy {
  constructor(
    protected dataService: DataService,
    protected staticDataService: StaticDataService,
    protected settingService: SettingService,
    protected sessionService: SessionService,
    protected transitionService: TransitionService,
    protected broadcaster: BroadcastService
  ) {
    super(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster);
  }
  public isWattingLoading = false;

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get isLanguageModuleEnable(): boolean {
    return this.sessionService.feature.hasModuleId(5);
  }

  private salaryTypesSubject: BehaviorSubject<ISalaryType[]>;
  public get salaryTypes(): Observable<ISalaryType[]> {
    if (!this.salaryTypesSubject) {
      this.salaryTypesSubject = new BehaviorSubject<ISalaryType[]>([]);
    }
    return this.salaryTypesSubject.asObservable();
  }
  public staticSalaryTypes: SalaryTypeView[] = [];

  private salaryTypeCategoriesSubject: BehaviorSubject<ISalaryTypeCategoryView[]>;
  public get salaryTypeCategories(): Observable<ISalaryTypeCategoryView[]> {
    if (!this.salaryTypeCategoriesSubject) {
      this.loadSalaryTypeCategories();
    }
    return this.salaryTypeCategoriesSubject.asObservable();
  }

  public loadSalaryTypes() {
    if (!this.salaryTypesSubject) {
      this.salaryTypesSubject = new BehaviorSubject<ISalaryType[]>([]);
    }

    this.isWattingLoading = true;

    return this.dataService.SalaryTypes_GetRawSalaryTypesTranslated().pipe(
      takeUntil(this.ngUnsubscribe),
      tap((data: ISalaryType[]) => {
        this.createGridData(data);
        this.salaryTypesSubject.next(data);
      })
    );
  }

  private loadSalaryTypeCategories() {
    if (!this.salaryTypeCategoriesSubject) {
      this.salaryTypeCategoriesSubject = new BehaviorSubject<ISalaryTypeCategoryView[]>([]);
    }

    this.dataService
      .SalaryTypes_GetAllSalaryTypeCategories()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: ISalaryTypeCategoryView[]) => {
        this.salaryTypeCategoriesSubject.next(data);
      });
  }

  private createGridData(data: ISalaryType[]): void {
    const filterSalaryTypes: ISalaryType[] = data.filter((type: ISalaryType) => {
      return !type.BaseSalaryType || (type.BaseSalaryType.IsVisible && type.BaseSalaryType.IsActive);
    });
    const activeSalaryTypes: ISalaryType[] = filterSalaryTypes.filter((type: ISalaryType) => {
      return type.IsActive !== false;
    });
    const inActiveSalaryType: ISalaryType[] = filterSalaryTypes.filter((type: ISalaryType) => {
      return type.IsActive === false;
    });
    const sortedActiveSalaryTypes: ISalaryType[] = activeSalaryTypes.sort((a: ISalaryType, b: ISalaryType) => {
      return this.sortCustomSalaryTypes(a, b);
    });
    const sortedInActiveSalaryTypes: ISalaryType[] = inActiveSalaryType.sort((a: ISalaryType, b: ISalaryType) => {
      return this.sortCustomSalaryTypes(a, b);
    });
    const sortedSalaryTypes: ISalaryType[] = sortedActiveSalaryTypes.concat(sortedInActiveSalaryTypes);
    this.staticSalaryTypes = sortedSalaryTypes.map((salaryType: ISalaryType) => {
      this.transformSalaryTypeObject(data, salaryType);
      return new SalaryTypeView(salaryType, this.isLanguageModuleEnable);
    });
  }

  private sortCustomSalaryTypes(a: ISalaryType, b: ISalaryType): number {
    const aOrder: number =
      a.SortOrder || a.SortOrder === 0
        ? a.SortOrder
        : a.BaseSalaryType && (a.BaseSalaryType.SortOrder || a.BaseSalaryType.SortOrder === 0)
        ? a.BaseSalaryType.SortOrder
        : undefined;
    const bOrder: number =
      b.SortOrder || b.SortOrder === 0
        ? b.SortOrder
        : b.BaseSalaryType && (b.BaseSalaryType.SortOrder || b.BaseSalaryType.SortOrder === 0)
        ? b.BaseSalaryType.SortOrder
        : undefined;
    if ((aOrder === undefined && bOrder === undefined) || (aOrder === 0 && bOrder === 0)) {
      return a.Id - b.Id;
    } else if (aOrder === undefined && bOrder !== undefined) {
      return -1;
    } else if (aOrder !== undefined && bOrder === undefined) {
      return 1;
    }

    return aOrder - bOrder;
  }

  private transformSalaryTypeObject(data: ISalaryType[], salaryType: ISalaryType): void {
    const existingSalaryType: ISalaryType = data.find((s: ISalaryType) => s.Id === salaryType.Id);
    salaryType.SalaryTypeTranslations = salaryType.SalaryTypeTranslations
      ? salaryType.SalaryTypeTranslations
      : existingSalaryType
      ? existingSalaryType.SalaryTypeTranslations
      : undefined;
    salaryType.BaseSalaryType = salaryType.BaseSalaryType
      ? salaryType.BaseSalaryType
      : existingSalaryType
      ? existingSalaryType.BaseSalaryType
      : undefined;
  }

  public initData() {
    this.staticDataService.moduleCompanyView.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.loadSalaryTypeCategories();
      this.loadSalaryTypes().subscribe(
        () => {
          this.isWattingLoading = false;
        },
        () => {
          this.isWattingLoading = false;
        }
      );
    });
  }

  public serviceDestroy() {
    if (this.salaryTypesSubject) {
      this.salaryTypesSubject.next([]);
      this.staticSalaryTypes = [];
    }
  }

  protected get allowShortcut(): boolean {
    return this.sessionService.currentState === 'tabs.company.configuration.advancedtypes.advancedsalarytypes';
  }
}
