import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { NotTranslatedService } from './not-translated.service';

@Injectable({
  providedIn: 'root'
})
export class MissingTranslationService implements MissingTranslationHandler {
  constructor(private notTranslatedService: NotTranslatedService) {}

  public handle(params: MissingTranslationHandlerParams): void {
    this.notTranslatedService.notTranslated(params.key);
  }
}
