import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from 'src/app/common/constants';
import { Global } from 'src/app/common/global';
import { GridCellClickEvent } from 'src/app/custom-controls/grid/cell-click-event';
import {
  IExternalAccount,
  IFinanceAccountType,
  ILanguage,
  ISalaryRecordPersistenceType,
  ISalaryRecordsOverviewDisplayMode,
  ISalarySummaryCategory,
  ISalaryType,
  ISalaryTypeCategory,
  ISalaryTypeCategoryView,
  ISalaryTypeDimensionDistributionOption,
  IUnitType
} from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { SettingService } from 'src/app/services/setting.service';
import { ReportDialogService } from 'src/app/shared-components/report-dialog/report-dialog.service';
import { CompanyAdvancedTypesService } from '../company-advanced-types.service';
import { SalaryTypeView } from '../salary-types/salary-type-view';
import { AdvancedSalaryTypesService } from './advanced-salary-types.service';

@Component({
  selector: 'app-salary-types-single',
  styleUrls: ['./salary-types-single.component.scss'],
  templateUrl: './salary-types-single.component.html'
})
export class SalaryTypesSingleComponent implements OnInit, OnDestroy {
  public gridData: SalaryTypeView[] = [];
  public salaryTypes: ISalaryType[];
  public selectedId: number;
  public accountTypes: IFinanceAccountType[] = [];
  public summaryCategories: ISalarySummaryCategory[] = [];
  public unitTypes: IUnitType[] = [];
  public persistanceTypes: ISalaryRecordPersistenceType[] = [];
  public categories: ISalaryTypeCategoryView[] = [];
  public externalReferences: any[] = [];
  public newSalaryTypeDialogVisible: boolean;
  public translationDialogVisible: boolean;
  public translationSalaryType: SalaryTypeView;
  public dimensionDistributions: ISalaryTypeDimensionDistributionOption[] = [];

  public get isLanguageModuleEnable(): boolean {
    return this.sessionService.feature.hasModuleId(5);
  }
  public languages: ILanguage[];
  public hasSalaryTypeModule = false;
  public isShowWarrningChangeMode = false;
  public triggerUpdate = false;
  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public isWattingLoading = false;
  public get isFromDenmark(): boolean {
    return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.DENMARK_COUNTRY_ID;
  }
  public get isFromGreenland(): boolean {
    return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.GREENLAND_COUNTRY_ID;
  }
  public get isFromSwedish(): boolean {
    return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.SWEDEN_COUNTRY_ID;
  }

  public childIsDirty: boolean[] = [];
  public childIsValid: boolean[] = [];
  @Output() public editClickAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() public addClickAction: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private dataService: DataService,
    private staticDataService: StaticDataService,
    public sessionService: SessionService,
    public settingService: SettingService,
    public reportDialogService: ReportDialogService,
    public advancedSalaryTypesService: AdvancedSalaryTypesService,
    public companyAdvancedTypesService: CompanyAdvancedTypesService
  ) {
    this.advancedSalaryTypesService.addNewShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && !this.sessionService.role.IsReadOnly) {
          this.addClickAction.emit();
        }
      });

    this.advancedSalaryTypesService.editShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (
          value &&
          this.sessionService.role.IsFullAdmin &&
          !this.sessionService.isGetting &&
          !this.companyAdvancedTypesService.editMode
        ) {
          this.editClickAction.emit(true);
        }
      });

    this.resetDirty();
  }

  public inheritDefaultValue = '<Nedarvet, klik for at overstyre>';
  public ngOnInit(): void {
    if (Global.SESSION.SignOnToken.LanguageId === 2) {
      this.inheritDefaultValue = '<Inherited, click to override>';
    } else {
      this.inheritDefaultValue = '<Nedarvet, klik for at overstyre>';
    }

    this.isWattingLoading = true;

    this.staticDataService.FinanceAccountType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IFinanceAccountType[]) => (this.accountTypes = data)
    );
    this.staticDataService.SalarySummaryCategory.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ISalarySummaryCategory[]) => (this.summaryCategories = data)
    );
    this.staticDataService.SalaryRecordPersistenceType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ISalaryRecordPersistenceType[]) => (this.persistanceTypes = data)
    );
    this.staticDataService.SalaryTypeCategory.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ISalaryTypeCategory[]) => {
        this.categories = data as any;
      }
    );
    this.staticDataService.DimensionRedistributionBehavior.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ISalaryTypeDimensionDistributionOption[]) => (this.dimensionDistributions = data)
    );
    this.dataService.Integrations_GetAllExternalAccounts().subscribe((data: IExternalAccount[]) => {
      this.externalReferences = data;
      if (this.externalReferences && this.externalReferences.length > 0) {
        this.externalReferences.forEach((model: any) => {
          model.FriendlyName = model.AccountNumber + (model.AccountName ? ' - ' + model.AccountName : '');
        });
      }
    });
    this.unitTypes = Global.UNIT_TYPES;

    this.settingService.showModuleMessage('company.advancedsalarytypes');
    if (this.isMobile) {
      this.sessionService.isShowHugeFeaturesAlert = true;
    }
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onChange(event?: any, field?: string, index?: number) {
    if (field) {
      this.companyAdvancedTypesService.onChangeInheritValue(field, event, index);
    }
    this.companyAdvancedTypesService.tabDirty = this.childIsDirty.indexOf(true) >= 0;
  }

  @Output() public showTranslationDialog: EventEmitter<any> = new EventEmitter<any>();
  public onTranslationButtonClick(): void {
    this.showTranslationDialog.emit();
  }

  public resetDirty() {
    this.childIsDirty = Array(29).fill(false);
    this.childIsValid = Array(18).fill(false);
    this.companyAdvancedTypesService.tabDirty = false;
  }

  public revertInheritValue() {
    this.companyAdvancedTypesService.listRevertValues.forEach((element: any) => {
      this.companyAdvancedTypesService.selectedType[element.field] = null;
      this.childIsDirty[element.index] = false;
      this.companyAdvancedTypesService.tabDirty = this.childIsDirty.indexOf(true) >= 0;
    });
  }
}
