import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StaticDataService } from '../../../services/api/static-data.service';
import { EmployeeService } from '../../../services/employee.service';
import { SessionService } from '../../../services/session/session.service';
var DepartmentFilterComponent = /** @class */ (function () {
    function DepartmentFilterComponent(employeeService, translateService, sessionService, staticDataService) {
        var _this = this;
        this.employeeService = employeeService;
        this.translateService = translateService;
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.hasDepartmentDataValue = true;
        this.hasDepartmentDataChange = new EventEmitter();
        this.ngUnsubscribe = new Subject();
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            return _this.translateDepartmentDataSource();
        });
    }
    Object.defineProperty(DepartmentFilterComponent.prototype, "hasDepartmentData", {
        get: function () {
            return this.hasDepartmentDataValue;
        },
        set: function (value) {
            var _this = this;
            if (this.hasDepartmentDataValue !== value) {
                this.hasDepartmentDataValue = value;
                setTimeout(function () {
                    _this.hasDepartmentDataChange.emit(_this.hasDepartmentDataValue);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    DepartmentFilterComponent.prototype.ngOnInit = function () {
        this.translateDepartmentDataSource();
    };
    DepartmentFilterComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    DepartmentFilterComponent.prototype.translateDepartmentDataSource = function () {
        var _this = this;
        this.translateService.get(['Employee.AllDepartments']).subscribe(function (translations) {
            var allDepartmentItem = { Id: -1, Text: translations['Employee.AllDepartments'] };
            _this.staticDataService.departments.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (departments) {
                _this.hasDepartmentData = departments && departments.length > 0;
                _this.departmentDataSource = departments.map(function (d) { return ({ Id: d.Id, Text: d.Name }); });
                // this.departmentDataSource.sort(ListHelper.sortInactiveLast);
                _this.departmentDataSource.unshift(allDepartmentItem);
                if (!_this.employeeService.departmentId) {
                    _this.employeeService.departmentId = _this.hasDepartmentData ? -1 : undefined;
                }
                // setTimeout(() => {
                //   this.departmentId = -1;
                // });
            });
        });
    };
    return DepartmentFilterComponent;
}());
export { DepartmentFilterComponent };
