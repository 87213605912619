import { AfterViewInit, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { version } from '../../../version';
import { Browser } from '../../common/browser';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { BroadcastService } from '../../services/broadcast.service';
import { EmployeeService } from '../../services/employee.service';
import { ModalService } from '../../services/modal.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(sessionService, translate, authenticationService, settingService, dataService, staticDataService, modalService, broadcaster, renderer, employeeService) {
        this.sessionService = sessionService;
        this.translate = translate;
        this.authenticationService = authenticationService;
        this.settingService = settingService;
        this.dataService = dataService;
        this.staticDataService = staticDataService;
        this.modalService = modalService;
        this.broadcaster = broadcaster;
        this.renderer = renderer;
        this.employeeService = employeeService;
        this.userName = '';
        this.password = '';
        this.remember = false;
        this.Message = undefined;
        this.showResetPasswordDialog = false;
        this.hideFooter = false;
        this.showPassword = false;
        this.signupDialogVisible = false;
        this.upgradeWarningDialogVisible = false;
        this.unsupportedIEDialogVisible = false;
        this.unsupportedBrowserDialogVisible = false;
        this.localStorageDialogVisible = false;
        this.changeTemporaryPasswordVisible = false;
        this.isWarningduplicateTabs = false;
        this.checkedBrowser = false;
        this.urlCultureCode = 'da';
        this.isLoggingIn = false;
        this.mustChangePassword = false;
        this.ngUnsubscribe = new Subject();
        this.isFullAdmin = false;
        this.isReadOnly = false;
        this.isSalaryAdmin = false;
        this.isEmployee = false;
    }
    Object.defineProperty(LoginComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "isIOSMobileApp", {
        get: function () {
            return this.sessionService.browser.iOSMobileDevice && this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "iOSversion", {
        get: function () {
            return this.sessionService.browser.iOSversion;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "IsIE", {
        get: function () {
            return this.sessionService.browser.isIE;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "IsAndroidMobile", {
        get: function () {
            return (!this.sessionService.browser.isHybridApp &&
                this.sessionService.browser.isAndroidDevice &&
                this.sessionService.browser.isMobile);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "IsIOsMobile", {
        get: function () {
            return (!this.sessionService.browser.isHybridApp &&
                this.sessionService.browser.iOSMobileDevice &&
                this.sessionService.browser.isMobile);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "signupLink", {
        get: function () {
            return '/signup/' + this.urlCultureCode;
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.onCloseDialoigWarningDuplicateTabs = function () {
        var Backlen = history.length;
        history.go(-Backlen);
        history.replaceState({}, null, '/login');
        this.authenticationService.clearAuthentication();
    };
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.sessionService.IsAuthenticated) {
                if (_this.sessionService.restoreDefault) {
                    if (_this.sessionService.isBackToLogin) {
                        _this.dataService.Auth_IsSessionAlive().subscribe(function (result) {
                            if (result === null || result === true) {
                                // set timeout to wait login page show and back to default page
                                setTimeout(function () {
                                    _this.sessionService.isBackToLogin = false;
                                    _this.sessionService.redirectToApp = true;
                                    _this.navigateToApp();
                                }, 100);
                            }
                            else {
                                _this.authenticationService.logout().subscribe(function () { });
                            }
                        });
                    }
                    else {
                        _this.authenticationService.logout().subscribe(function () { });
                    }
                }
                else {
                    _this.isWarningduplicateTabs = true;
                    _this.sessionService.restoreDefault = true;
                }
            }
        });
        if (window.location.href.indexOf('file://') > -1 && !this.sessionService.browser.isHybridApp) {
            window.location.href = 'https://intect.app';
        }
        this.originalSize = window.innerWidth + window.innerHeight;
        this.originalHeight = window.innerHeight;
        this.renderer.removeClass(document.body, 'main-bg');
        this.renderer.addClass(document.body, 'Login-bg');
        this.renderer.listen(window, 'keyboardWillShow', function () {
            _this.renderer.addClass(document.body, 'keyboard-show');
        });
        this.renderer.listen(window, 'keyboardDidHide', function () {
            _this.renderer.removeClass(document.body, 'keyboard-show');
        });
        var languageCode;
        var urlFragments = window.location.href.split('/');
        var param = urlFragments[urlFragments.length - 1];
        if (param) {
            if (param.substring(0, 8) === 'username') {
                this.userName = param.replace('username=', '');
            }
            if (param === 'Unauthorized') {
                this.Message = this.unauthorized;
            }
            else {
                var shortCultureCode = param.substr(0, 2);
                if (this.checkLanguageCodeExist(shortCultureCode)) {
                    languageCode = this.urlCultureCode = shortCultureCode;
                }
            }
        }
        if (!languageCode) {
            languageCode = this.getLanguageCodeById(this.getCookie('languageId'));
        }
        this.changeLanguageTo(languageCode || 'da', true);
        if (this.isMobile) {
            var initialScreenSize_1 = window.innerHeight;
            window.addEventListener('resize', function () {
                if (window.innerHeight < initialScreenSize_1) {
                    _this.renderer.addClass(document.body, 'keyboard-show');
                }
                else {
                    _this.renderer.removeClass(document.body, 'keyboard-show');
                }
            }, false);
        }
    };
    LoginComponent.prototype.onResize = function (event) {
        if (this.isMobile) {
            if (document.activeElement.tagName.toLowerCase() !== 'input') {
                this.originalSize = window.innerWidth + window.innerHeight;
                this.keyboardShow = false;
            }
            else {
                if (window.innerHeight > this.originalHeight) {
                    this.keyboardShow = false;
                }
                else {
                    this.keyboardShow = true;
                }
                this.originalHeight = window.innerHeight;
            }
            // if (window.innerWidth + window.innerHeight !== this.originalSize) {
            //   this.hideFooter = true;
            // } else {
            //   this.hideFooter = false;
            // }
        }
    };
    LoginComponent.prototype.ngAfterViewInit = function () {
        if (this.checkLocalStorageBrower()) {
            this.init();
        }
    };
    LoginComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(LoginComponent.prototype, "unsupportIOSApp", {
        get: function () {
            var ver = this.iOSversion;
            if (ver && ver[0] < 11) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.onUpgradeWarningDialogAction = function (action) {
        if (action === 'Update') {
            if (this.sessionService.browser.isHybridApp) {
                window.open('market://details?id=com.gratisal.mobile', '_top');
            }
            else {
                window.location.reload();
            }
        }
    };
    LoginComponent.prototype.unsupportedIEDialogAction = function (action) {
        switch (action) {
            case 'Chrome':
                window.open('https://www.google.com/chrome/');
                break;
            case 'Firefox':
                window.open('https://getfirefox.com/');
                break;
        }
    };
    LoginComponent.prototype.IdonthaveanaccountmodalAction = function (action) {
        switch (action) {
            case 'CreateNewCompany':
                if (this.language === 'en') {
                    window.location.replace('https://intect.app/signup/en');
                }
                else {
                    this.language === 'da'
                        ? window.location.replace('https://intect.app/signup/da')
                        : window.location.replace('https://intect.app/signup/kl');
                }
                break;
            case 'ForgotPassword':
                this.onResetPassword();
                break;
        }
    };
    LoginComponent.prototype.onToggleLanguageDropdown = function () {
        this.languageDropdownOpen = !this.languageDropdownOpen;
    };
    LoginComponent.prototype.onTemporaryPasswordChanged = function (newPassword) {
        this.password = newPassword;
        this.authenticationService.credentials.Password = this.password;
        this.login();
    };
    LoginComponent.prototype.gotosignup = function (url) {
        window.location.href = url;
    };
    LoginComponent.prototype.changeLanguageTo = function (language, firstLoad) {
        var _this = this;
        this.language = language;
        this.urlCultureCode = language;
        this.settingService.applyTranslations(language);
        this.translate.use(language).subscribe(function () {
            if (firstLoad) {
                _this.ShowWaringUsuportIE();
            }
        });
        this.languageDropdownOpen = false;
        localStorage.setItem('localLanguageCode', this.language);
    };
    LoginComponent.prototype.keydown = function (e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            if (!this.userName) {
                this.loginUsername.nativeElement.focus();
                this.isLoggingIn = false;
                return;
            }
            if (!this.password) {
                this.loginPassword.nativeElement.focus();
                this.isLoggingIn = false;
                return;
            }
            this.login();
        }
        else {
            this.Message = undefined;
        }
    };
    Object.defineProperty(LoginComponent.prototype, "checkAutoFill", {
        get: function () {
            var userAutoFill = false;
            var passAutoFill = false;
            try {
                userAutoFill = getComputedStyle(this.loginUsername.nativeElement).webkitTextFillColor === 'rgb(27, 41, 47)';
                passAutoFill = getComputedStyle(this.loginPassword.nativeElement).webkitTextFillColor === 'rgb(27, 41, 47)';
            }
            catch (e) { }
            if ((userAutoFill && passAutoFill && !this.isLoggingIn) || (this.userName && passAutoFill)) {
                return false;
            }
            if (!this.userName || this.userName === '' || !this.password || this.password === '' || this.isLoggingIn) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.login = function () {
        var _this = this;
        if (this.isLoggingIn) {
            return;
        }
        if (this.checkAutoFill) {
            this.userName ? (this.userNameValueMissing = false) : (this.userNameValueMissing = true);
            this.password ? (this.PasswordValueMissing = false) : (this.PasswordValueMissing = true);
            this.userName ? this.loginPassword.nativeElement.focus() : this.loginUsername.nativeElement.focus();
            return;
        }
        else {
            this.userNameValueMissing = false;
            this.PasswordValueMissing = false;
        }
        if (!this.password) {
            return;
        }
        if (this.remember) {
            localStorage.setItem('lastUserName', this.userName);
        }
        else {
            localStorage.setItem('lastUserName', '');
        }
        localStorage.setItem('saveLoginUserName', this.userName);
        this.authenticationService.credentials = {
            Username: this.userName,
            Password: this.password,
            Remember: this.remember
        };
        this.isLoggingIn = true;
        var message;
        this.loginResponse = undefined;
        this.authenticationService
            .login()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (response) {
            var isFirstLogin = _this.firstLoginMustChangePassword
                ? _this.firstLoginMustChangePassword
                : response.IsFirstLogin || false;
            _this.sessionService.isApprovalMessage = response.IsFirstLogin || false;
            var mustChangePassword = response.MustChangePassword;
            if (mustChangePassword) {
                _this.firstLoginMustChangePassword = isFirstLogin;
                _this.mustChangePassword = true;
                var cultureCode = response.Language.CultureCode;
                var shortCultureCode = cultureCode.substr(0, 2);
                if (_this.mustChangePassword) {
                    _this.mustChangePassword = false;
                    _this.changeTemporaryPassword();
                    _this.isLoggingIn = false;
                }
            }
            else {
                var company = response.Company ? response.Company : undefined;
                var role = response ? response.CurrentRole : undefined;
                if (role && role.Key === 'FullAdmin' && company && !company.HasAcceptedTerms) {
                    _this.loginResponse = response;
                    _this.showAcceptDPADialog = true;
                }
                else {
                    _this.onLoginCompleted(response);
                }
            }
        }, function (reason) {
            _this.isLoggingIn = false;
            // Failure
            switch (reason.status) {
                case 0:
                    message = _this.networkErrorMessage;
                    break;
                case 401:
                    message = _this.wrongUsernameOrPassword;
                    break;
                case 400:
                    message = reason.error.Message;
                    break;
                case 500:
                    // Internal server error
                    var errorMessage_1 = reason.statusText;
                    if (reason.config && reason.config.url) {
                        errorMessage_1 += ' - ' + reason.config.url;
                    }
                    Sentry.withScope(function (scope) {
                        scope.setExtra('extra', reason);
                        Sentry.captureException(new Error(errorMessage_1));
                    });
                    break;
                default:
                    var othermessage_1 = reason.statusText;
                    if (reason.config && reason.config.url) {
                        othermessage_1 += ' - ' + reason.config.url;
                    }
                    Sentry.withScope(function (scope) {
                        scope.setExtra('extra', reason);
                        Sentry.captureException(new Error(othermessage_1));
                    });
                    break;
            }
            _this.Message = message;
            console.error('Login failed: ');
            console.error(reason);
        });
    };
    LoginComponent.prototype.onDPATermAccepted = function (isValid) {
        this.isLoggingIn = false;
        if (isValid) {
            this.onLoginCompleted();
        }
        this.loginResponse = undefined;
    };
    LoginComponent.prototype.onResetPassword = function () {
        this.preFillEmailResetPassword = this.userName && this.userName.lastIndexOf('@') !== -1 ? this.userName : '';
        this.showResetPasswordDialog = true;
    };
    LoginComponent.prototype.openLink = function () {
        if (this.isIOSMobileApp) {
            return;
        }
        var languageCode = Global.SESSION && Global.SESSION.SignOnToken.Language
            ? Global.SESSION.SignOnToken.Language.CultureCode.split('-')[0]
            : 'da';
        location.href = 'https://www.intect.dk/' + languageCode;
    };
    LoginComponent.prototype.init = function () {
        var _this = this;
        this.language = this.translate.currentLang;
        this.translateText();
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (e) {
            _this.language = _this.translate.currentLang;
            _this.translateText();
        });
        this.dataService.Miscellaneous_GetMinimumClientVersion().subscribe(function (clientMinVersion) {
            var fragments = version.split('.');
            var formattedVersion;
            if (fragments.length < 3) {
                formattedVersion = version;
            }
            else {
                formattedVersion =
                    _this.zeroPad(_this.sessionService.parseInt(fragments[0]), 2) +
                        _this.zeroPad(_this.sessionService.parseInt(fragments[1]), 3) +
                        _this.zeroPad(_this.sessionService.parseInt(fragments[2]), 4);
            }
            if (clientMinVersion > formattedVersion && environment.environment !== 'DEV') {
                setTimeout(function () {
                    _this.upgradeWarningDialogVisible = true;
                });
            }
        });
    };
    LoginComponent.prototype.ShowWaringUsuportIE = function () {
        if (this.sessionService.browser.isIE) {
            this.unsupportedIEDialogVisible = true;
        }
    };
    LoginComponent.prototype.getCookie = function (cookieName) {
        var name = cookieName + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    };
    LoginComponent.prototype.checkBrowser = function () {
        if (this.checkedBrowser) {
            return true;
        }
        this.checkedBrowser = true;
        if (!this.sessionService.browser.isSupportedBrowser ||
            this.sessionService.browser.isOutdatedBrowser ||
            this.unsupportIOSApp) {
            this.unsupportedBrowserDialogVisible = true;
            return false;
        }
        return true;
    };
    LoginComponent.prototype.checkLocalStorageBrower = function () {
        if (!this.localStorageEnabled) {
            this.localStorageDialogVisible = true;
            return false;
        }
        return true;
    };
    Object.defineProperty(LoginComponent.prototype, "localStorageEnabled", {
        get: function () {
            try {
                // Try to use localStorage
                sessionStorage.setItem('sessionStorageEnable', 'true');
                return true;
            }
            catch (e) {
                // There was an error so...
                alert('Bemærk: Din browser tillader ikke cookies, og ' +
                    this.branding.SystemAlias +
                    ' kan derfor ikke fungere optimalt. Vi anbefaler kraftigt at du tillader cookies på dette domæne. Cookies er nødvendige for at holde styr på væsentlige detaljer om din session, eksempelvis dit sprogvalg.Læs vores fulde cookie - politik.' +
                    '\n' +
                    'Please note: Your browser is not allowing cookies, which means ' +
                    this.branding.SystemAlias +
                    ' cannot function correctly. We strongly recommend that you allow cookies for the this domain. Cookies are necessary to keep track of important details about your session, e.g.your choice of language.You can read our full policy on cookies.');
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.checkLanguageCodeExist = function (languageCode) {
        return languageCode === 'en' || languageCode === 'da' || languageCode === 'kl' || languageCode === 'se';
    };
    LoginComponent.prototype.getLanguageCodeById = function (languageId) {
        var id = this.sessionService.parseInt(languageId);
        switch (id) {
            case 1:
                return 'da';
            case 2:
                return 'en';
            case 3:
                return 'kl';
            case 4:
                return 'se';
            default:
                return '';
        }
    };
    LoginComponent.prototype.zeroPad = function (num, places) {
        var zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join('0') + num;
    };
    LoginComponent.prototype.translateText = function () {
        var _this = this;
        this.translate
            .get([
            'GlobalDialog.Ok',
            'GlobalDialog.Yes',
            'GlobalDialog.No',
            'GlobalDialog.Cancel',
            'GlobalDialog.Close',
            'GlobalDialog.Delete',
            'GlobalDialog.Reject',
            'GlobalDialog.Create',
            'GlobalDialog.Approve',
            'GlobalDialog.Enable',
            'GlobalDialog.Apply',
            'GlobalDialog.Proceed',
            'GlobalDialog.Understood',
            'GlobalDialog.MoreHelp',
            'GlobalDialog.ReadMore',
            'GlobalDialog.ChangePassword',
            'GlobalDialog.ResetPassword',
            'Error.NetworkError',
            'GlobalDialog.YesPlease',
            'GlobalDialog.NoThankYou',
            'Warning.Continue',
            'GlobalDialog.Update',
            'GlobalDialog.CreateNewCompany',
            'GlobalDialog.Login',
            'GlobalDialog.ForgotPassword',
            'Error.401ErrorWarningResponse'
        ])
            .subscribe(function (translations) {
            _this.sessionService.modalTranslations = translations;
        });
        this.translate
            .get(['Login.AuthenticationFailed', 'Login.WrongUserNameOrPassword', 'Error.NetworkError', 'UnsuccessfulAPI.404'])
            .subscribe(function (translations) {
            _this.unauthorized = translations['Login.AuthenticationFailed'];
            _this.wrongUsernameOrPassword = translations['Login.WrongUserNameOrPassword'];
            _this.networkErrorMessage = translations['Error.NetworkError'].replace('{{SystemAlias}}', environment.branding.SystemAlias);
            _this.sessionService.errorMessage404 = translations['UnsuccessfulAPI.404'];
            _this.sessionService.errorMessage401 = translations['Error.401ErrorWarningResponse'];
            if (_this.checkBrowser()) {
                // if (!this.userName && localStorage) {
                //   this.userName = localStorage.getItem('lastUserName');
                // }
                if (localStorage && localStorage.getItem('lastUserName')) {
                    _this.userName = localStorage.getItem('lastUserName');
                    _this.remember = true;
                }
                else {
                    _this.userName = '';
                    _this.password = '';
                    _this.remember = false;
                }
            }
            // if (this.mustChangePassword) {
            //     this.mustChangePassword = false;
            //     this.changeTemporaryPassword();
            // }
        });
    };
    LoginComponent.prototype.onLoginCompleted = function (response) {
        var _this = this;
        response = response ? response : this.loginResponse;
        if (response) {
            var isFirstLogin = this.firstLoginMustChangePassword
                ? this.firstLoginMustChangePassword
                : response.IsFirstLogin || false;
            var welcomeMessageSettings = {};
            welcomeMessageSettings['Employee.General'] = isFirstLogin;
            welcomeMessageSettings['Employee.Employment'] = isFirstLogin;
            welcomeMessageSettings['Employee.Time'] = isFirstLogin;
            welcomeMessageSettings['Employee.PayrollData'] = isFirstLogin;
            welcomeMessageSettings['Employee.Payslip'] = isFirstLogin;
            welcomeMessageSettings['Company.General'] = isFirstLogin;
            welcomeMessageSettings['Company.Templates'] = isFirstLogin;
            welcomeMessageSettings['Company.SalaryBatches'] = isFirstLogin;
            welcomeMessageSettings['Company.SalaryTypes'] = isFirstLogin;
            welcomeMessageSettings['Company.TimeEntryTypes'] = isFirstLogin;
            welcomeMessageSettings['Account.Modules'] = isFirstLogin;
            welcomeMessageSettings['SelfService.Payslip'] = isFirstLogin;
            welcomeMessageSettings['ShowUpdateNotification'] = response.ShowUpdateNotification && !isFirstLogin;
            welcomeMessageSettings['company.timeentrytypes'] = isFirstLogin;
            welcomeMessageSettings['company.integrations'] = isFirstLogin;
            welcomeMessageSettings['company.advancedsalarytypes'] = isFirstLogin;
            Global.WELCOME_MESSAGE_SETTINGS = welcomeMessageSettings;
            var betaMessageSettings = {};
            betaMessageSettings['Company.SalaryBatch.EditPayroll'] = true;
            betaMessageSettings['Company.SalaryBatch.EditPayrollIEEdge'] = true;
            Global.BETA_MODULE_MESSAGE_SETTINGS = betaMessageSettings;
            this.renderer.addClass(document.body, 'is-loggingin');
            if (response.CurrentRole && response.CurrentRole.Key) {
                switch (response.CurrentRole.Key) {
                    case 'FullAdmin':
                        this.isFullAdmin = true;
                        break;
                    case 'ReadOnly':
                        this.isReadOnly = true;
                        break;
                    case 'Employee':
                        this.isEmployee = true;
                        break;
                    case 'SalaryAdmin':
                        this.isSalaryAdmin = true;
                        break;
                    default:
                        break;
                }
            }
            this.staticDataService
                .loadStaticData()
                .then(function () { return _this.navigateToApp(); })
                .catch(function () {
                _this.isLoggingIn = false;
            });
        }
    };
    LoginComponent.prototype.navigateToApp = function () {
        var _this = this;
        this.employeeService.loadEmployeesAsync().then(function () {
            Global.STARTUP_TASKS_VISIBILITY = true;
            setTimeout(function () { return _this.renderer.removeClass(document.body, 'is-loggingin'); }, 200);
            _this.sessionService.switchToDefaultTab();
        });
    };
    LoginComponent.prototype.changeTemporaryPassword = function () {
        this.broadcaster.broadcast(Constants.ACCOUNT_CHANGE_TEMPORARY_PASSWORD, this.password);
        this.changeTemporaryPasswordVisible = true;
    };
    Object.defineProperty(LoginComponent.prototype, "isMobile", {
        get: function () {
            var browser = new Browser();
            return browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.showPaswordForMobile = function () {
        if (this.isMobile) {
            this.showPassword = !this.showPassword;
        }
    };
    LoginComponent.prototype.onUserNameInputChange = function (event) {
        if (event) {
            this.userNameValueMissing = false;
        }
        else {
            this.userNameValueMissing = true;
        }
    };
    LoginComponent.prototype.onPasswordInputChange = function (event) {
        if (event) {
            this.PasswordValueMissing = false;
        }
        else {
            this.PasswordValueMissing = true;
        }
    };
    LoginComponent.prototype.oncloseErrorMessage = function () {
        if (this.modalService.reloadAfterClose) {
            window.location.reload();
        }
    };
    return LoginComponent;
}());
export { LoginComponent };
