<app-dialog
  [overlayClose]="false"
  [(visible)]="isVisible"
  [ngClass]="{ 'blue-dialog': templates.length === 0 }"
  [customVisible]="true"
  (action)="onDialogAction($event)"
  [width]="templates.length > 0 ? 500 : undefined"
>
  <ng-container *ngIf="templates.length > 0">
    <div class="u-trailer" innerHTML="{{ 'NewEmployeeEmployment.Note' | translate }}"></div>
    <div class="u-trailer">
      <app-date-picker
        [e2e]="'HiringDate'"
        [label]="'NewEmployeeEmployment.HiringDate' | translate"
        [editMode]="true"
        [required]="true"
        [requiredMessage]="'EmployeeGeneral.Required' | translate"
        [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
        [(value)]="viewModel.HireDate"
        (valueChange)="onHiringDatePickerChanged($event)"
      ></app-date-picker>
    </div>
    <div class="u-trailer">
      <app-auto-complete-textbox
        [label]="'NewEmployeeEmployment.Title' | translate"
        [editMode]="true"
        [isRequired]="true"
        [requiredMessage]="'NewEmployeeEmployment.TitleErrorMessage' | translate"
        [autoCompleteDataSource]="staticDataService.employmentTitle | async"
        (isValidateChange)="onValidateChanged($event)"
        [allowCustom]="true"
        [(value)]="viewModel.Title"
      >
      </app-auto-complete-textbox>
    </div>
    <div class="u-trailer">
      <app-combobox-edit
        *ngIf="templates && templates.length > 0"
        [label]="'NewEmployeeEmployment.Template' | translate"
        [editMode]="true"
        [comboboxDataSource]="templates"
        [textField]="'Name'"
        [idField]="'Id'"
        [(value)]="viewModel.BasedOnTemplateId"
      ></app-combobox-edit>
    </div>
    <div class="u-trailer">
      <app-combobox-edit
        [label]="'NewEmployeeEmployment.TaxCardType' | translate"
        [editMode]="true"
        [comboboxDataSource]="taxCardTypes"
        [textField]="'Name'"
        [idField]="'Id'"
        [(value)]="viewModel.PreferredTaxCardTypeId"
      ></app-combobox-edit>
    </div>
    <app-dialog-action [type]="'Close'"></app-dialog-action>
    <app-dialog-action [disabled]="sessionService.isSubmitting || !isValidate" [type]="'Create'"></app-dialog-action>
  </ng-container>

  <ng-container *ngIf="templates.length === 0">
    {{ 'NewEmployeeEmployment.NoEmploymentTemplateWarning' | translate }}
    <app-dialog-action [type]="'Ok'"></app-dialog-action>
  </ng-container>
</app-dialog>
