/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./company-modules.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared-components/menu-button/menu-button.component.ngfactory";
import * as i3 from "../../shared-components/menu-button/menu-button.component";
import * as i4 from "../../shared-components/report-dialog/report-dialog.service";
import * as i5 from "../../services/session/session.service";
import * as i6 from "../../services/employee.service";
import * as i7 from "@angular/common";
import * as i8 from "../../shared-components/menu-button-item/menu-button-item.component.ngfactory";
import * as i9 from "../../shared-components/menu-button-item/menu-button-item.component";
import * as i10 from "ngx-zendesk-webwidget";
import * as i11 from "./price/price.component.ngfactory";
import * as i12 from "./price/price.component";
import * as i13 from "../../../windowReference";
import * as i14 from "./company-modules.service";
import * as i15 from "../../custom-controls/dialogs/dialog.component.ngfactory";
import * as i16 from "../../custom-controls/dialogs/dialog.component";
import * as i17 from "@angular/platform-browser";
import * as i18 from "@ngx-translate/core";
import * as i19 from "../../custom-controls/dialogs/dialog-action.component.ngfactory";
import * as i20 from "../../custom-controls/dialogs/dialog-action.component";
import * as i21 from "./company-modules.component";
import * as i22 from "../../services/setting.service";
var styles_CompanyModulesComponent = [i0.styles];
var RenderType_CompanyModulesComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CompanyModulesComponent, data: {} });
export { RenderType_CompanyModulesComponent as RenderType_CompanyModulesComponent };
function View_CompanyModulesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-menu-button", [], null, [[null, "menuClick"], ["document", "click"], ["document", "dblclick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).closeMenu() !== false);
        ad = (pd_0 && ad);
    } if (("document:dblclick" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).closeMenuDbl() !== false);
        ad = (pd_1 && ad);
    } if (("menuClick" === en)) {
        var pd_2 = (_co.reportDialogService.onShowReportsEventClick() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MenuButtonComponent_0, i2.RenderType_MenuButtonComponent)), i1.ɵdid(1, 245760, null, 1, i3.MenuButtonComponent, [i4.ReportDialogService, i5.SessionService, i6.EmployeeService], { actionButtonMenu: [0, "actionButtonMenu"], menuCondition: [1, "menuCondition"], menuIcon: [2, "menuIcon"], menuLabel: [3, "menuLabel"], menuTooltip: [4, "menuTooltip"], menuButtonClass: [5, "menuButtonClass"], menuButtonId: [6, "menuButtonId"] }, { menuClick: "menuClick" }), i1.ɵqud(603979776, 1, { listItems: 1 })], function (_ck, _v) { var currVal_0 = true; var currVal_1 = true; var currVal_2 = "File"; var currVal_3 = "Employee.Reports"; var currVal_4 = "Employee.Reports"; var currVal_5 = "Action-button Action-buttonReports"; var currVal_6 = "ActionButtonReports"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_CompanyModulesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "Actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompanyModulesComponent_2)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "app-menu-button", [], null, [["document", "click"], ["document", "dblclick"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).closeMenu() !== false);
        ad = (pd_0 && ad);
    } if (("document:dblclick" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).closeMenuDbl() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MenuButtonComponent_0, i2.RenderType_MenuButtonComponent)), i1.ɵdid(4, 245760, null, 1, i3.MenuButtonComponent, [i4.ReportDialogService, i5.SessionService, i6.EmployeeService], { multiMenuItems: [0, "multiMenuItems"], menuIcon: [1, "menuIcon"], menuLabel: [2, "menuLabel"], menuTooltip: [3, "menuTooltip"] }, null), i1.ɵqud(603979776, 2, { listItems: 1 }), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "app-menu-button-item", [], null, null, null, i8.View_MenuButtonItemComponent_0, i8.RenderType_MenuButtonItemComponent)), i1.ɵdid(7, 114688, [[2, 4]], 0, i9.MenuButtonItemComponent, [i5.SessionService, i6.EmployeeService, i10.NgxZendeskWebwidgetService], { field: [0, "field"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "app-menu-button-item", [], null, null, null, i8.View_MenuButtonItemComponent_0, i8.RenderType_MenuButtonItemComponent)), i1.ɵdid(9, 114688, [[2, 4]], 0, i9.MenuButtonItemComponent, [i5.SessionService, i6.EmployeeService, i10.NgxZendeskWebwidgetService], { field: [0, "field"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reportDialogService.isVisibleReportIcon; _ck(_v, 2, 0, currVal_0); var currVal_1 = true; var currVal_2 = "Help"; var currVal_3 = "Help.Title"; var currVal_4 = "Help.Title"; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = "SystemHelp"; _ck(_v, 7, 0, currVal_5); var currVal_6 = "welcomeMessage"; _ck(_v, 9, 0, currVal_6); }, null); }
function View_CompanyModulesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "Actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "app-menu-button", [], null, [["document", "click"], ["document", "dblclick"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).closeMenu() !== false);
        ad = (pd_0 && ad);
    } if (("document:dblclick" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).closeMenuDbl() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MenuButtonComponent_0, i2.RenderType_MenuButtonComponent)), i1.ɵdid(2, 245760, null, 1, i3.MenuButtonComponent, [i4.ReportDialogService, i5.SessionService, i6.EmployeeService], { multiMenuItems: [0, "multiMenuItems"], menuIcon: [1, "menuIcon"], menuLabel: [2, "menuLabel"], menuTooltip: [3, "menuTooltip"] }, null), i1.ɵqud(603979776, 3, { listItems: 1 }), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "app-menu-button-item", [], null, null, null, i8.View_MenuButtonItemComponent_0, i8.RenderType_MenuButtonItemComponent)), i1.ɵdid(5, 114688, [[3, 4]], 0, i9.MenuButtonItemComponent, [i5.SessionService, i6.EmployeeService, i10.NgxZendeskWebwidgetService], { field: [0, "field"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "app-menu-button-item", [], null, null, null, i8.View_MenuButtonItemComponent_0, i8.RenderType_MenuButtonItemComponent)), i1.ɵdid(7, 114688, [[3, 4]], 0, i9.MenuButtonItemComponent, [i5.SessionService, i6.EmployeeService, i10.NgxZendeskWebwidgetService], { field: [0, "field"] }, null)], function (_ck, _v) { var currVal_0 = true; var currVal_1 = "ThreeDots"; var currVal_2 = "More.Title"; var currVal_3 = "More.Title"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = "SystemHelp"; _ck(_v, 5, 0, currVal_4); var currVal_5 = "welcomeMessage"; _ck(_v, 7, 0, currVal_5); }, null); }
export function View_CompanyModulesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "PriceContent"]], null, null, null, null, null)), i1.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "price", [], null, null, null, i11.View_PriceComponent_0, i11.RenderType_PriceComponent)), i1.ɵdid(4, 245760, null, 0, i12.PriceComponent, [i13.WindowReference, i5.SessionService, i14.CompanyModuleService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompanyModulesComponent_1)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompanyModulesComponent_3)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 7, "app-dialog", [], null, [[null, "action"], [null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("action" === en)) {
        var pd_0 = (_co.companyModuleService.onConfirmDialogAction($event) !== false);
        ad = (pd_0 && ad);
    } if (("visibleChange" === en)) {
        var pd_1 = ((_co.companyModuleService.confirmDialogVisible = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i15.View_DialogComponent_0, i15.RenderType_DialogComponent)), i1.ɵdid(10, 49152, null, 1, i16.DialogComponent, [i17.DomSanitizer, i5.SessionService, i1.ElementRef], { leadingText: [0, "leadingText"], visible: [1, "visible"] }, { visibleChange: "visibleChange", action: "action" }), i1.ɵqud(603979776, 4, { buttonElements: 1 }), i1.ɵpid(131072, i18.TranslatePipe, [i18.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, 0, 1, "app-dialog-action", [["type", "Cancel"]], null, null, null, i19.View_DialogActionComponent_0, i19.RenderType_DialogActionComponent)), i1.ɵdid(14, 180224, [[4, 4]], 0, i20.DialogActionComponent, [i5.SessionService], { type: [0, "type"] }, null), (_l()(), i1.ɵeld(15, 0, null, 0, 1, "app-dialog-action", [["type", "Understood"]], null, null, null, i19.View_DialogActionComponent_0, i19.RenderType_DialogActionComponent)), i1.ɵdid(16, 180224, [[4, 4]], 0, i20.DialogActionComponent, [i5.SessionService], { disabled: [0, "disabled"], type: [1, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "PriceContent"; var currVal_1 = (_co.companyModuleService.showBilledViaMCMessage ? "PriceContentMessage" : ""); _ck(_v, 2, 0, currVal_0, currVal_1); _ck(_v, 4, 0); var currVal_2 = !_co.companyModuleService.isMobile; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.companyModuleService.isMobile; _ck(_v, 8, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 12).transform("Price.SubmitConfirm")); var currVal_5 = _co.companyModuleService.confirmDialogVisible; _ck(_v, 10, 0, currVal_4, currVal_5); var currVal_6 = "Cancel"; _ck(_v, 14, 0, currVal_6); var currVal_7 = _co.sessionService.isSubmitting; var currVal_8 = "Understood"; _ck(_v, 16, 0, currVal_7, currVal_8); }, null); }
export function View_CompanyModulesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "company-modules", [], null, null, null, View_CompanyModulesComponent_0, RenderType_CompanyModulesComponent)), i1.ɵdid(1, 245760, null, 0, i21.CompanyModulesComponent, [i5.SessionService, i22.SettingService, i4.ReportDialogService, i14.CompanyModuleService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CompanyModulesComponentNgFactory = i1.ɵccf("company-modules", i21.CompanyModulesComponent, View_CompanyModulesComponent_Host_0, {}, {}, []);
export { CompanyModulesComponentNgFactory as CompanyModulesComponentNgFactory };
