import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DatePickerComponent as kendoDatePicker } from '@progress/kendo-angular-dateinputs';
import { DxDateBoxComponent } from 'devextreme-angular';
import { Constants } from 'src/app/common/constants';
import { Global } from 'src/app/common/global';
import { ControlComponentBase } from '../../common/control-component-base';
import { ReflectionHelper } from '../../common/reflection-helper';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html'
})
export class DatePickerComponent extends ControlComponentBase<Date> implements OnInit, AfterViewInit {
  @Input() public label: string;
  @Input() public requiredMessage: string;
  @Input() public validationMessage: string;
  @Input() public isLabelHtml: boolean;
  @Input() public disable: boolean;
  @Input() public required: boolean;
  @Input() public e2e: string;
  @Input() public minDate: Date;
  @Input() public type = 'date';
  @Input() public viewClass: string;
  @Input() public helpTooltip = '';

  @Input() public AllowNull = false;

  @ViewChild('dxDatePickerControl', { static: false }) public dxDatePickerControl: DxDateBoxComponent;

  public validationText: string;
  public format = 'dd-MM-yyyy';

  constructor(elementRef: ElementRef, public sessionService: SessionService, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
    this.setTimeout = false;
  }

  public ngOnInit(): void {
    if (!this.isGrid) {
      this.setTimeout = true;
    } else {
      if (this.sessionService.dateFormat) {
        this.format = this.sessionService.dateFormat;
      }
      if (this.type === 'datetime') {
        this.format += ' HH:mm';
      }
    }
  }

  public onChange(triggerChanged: boolean = true): void {
    if (this.setTimeout) {
      setTimeout(() => {
        this.isValid = this.valid();
        this.isDirty = this.hasChanges();
        if (triggerChanged) {
          this.onEmitValueChange(this.value as Date);
        }
      });
    } else {
      this.isValid = this.valid();
      this.isDirty = this.hasChanges();
      if (triggerChanged) {
        this.onEmitValueChange(this.value as Date);
      }
    }
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.sessionService.dateFormat) {
        this.format = this.sessionService.dateFormat;
      }
      if (this.type === 'datetime') {
        this.format += ' HH:mm';
      }
    });
  }

  public isOpened = false;
  public onfocus(event: any): void {
    // Select text when focus input
    if (this.dxDatePickerControl && !this.sessionService.browser.isMobile) {
      const element: ElementRef = (this.dxDatePickerControl as any).element;
      const input: any = element.nativeElement.getElementsByClassName('dx-texteditor-input')[0];
      if (input) {
        input.select();
      }
      this.isOpened = true;
    }
  }

  public onBlur(event: any): void {
    // Not blur when click to time picker
    if (
      this.type === 'datetime' &&
      event.event.relatedTarget &&
      event.event.relatedTarget.nodeName.toLowerCase() + '.' + event.event.relatedTarget.classList.toString() ===
        'input.dx-texteditor-input'
    ) {
      this.value = event.component._strategy.getValue();
      return;
    }
    if (!this.convertSpecialFormat()) {
      this.convertInvalidDxDate();
    }
    setTimeout(() => {
      this.blur.emit();
      this.isOpened = false;
    });
  }

  @Output() public customData: EventEmitter<any> = new EventEmitter<any>();
  @Output() public directional: EventEmitter<any> = new EventEmitter<any>();
  @Output() public blur: EventEmitter<any> = new EventEmitter<any>();
  @Output() public keydowns: EventEmitter<any> = new EventEmitter<any>();

  public onKeyDown(event: any): void {
    this.dxDatePickerControl.isValid = true;
    if (this.isGrid) {
      if (
        event.event.key === 'ArrowDown' ||
        event.event.key === 'ArrowUp' ||
        event.event.key === 'ArrowLeft' ||
        event.event.key === 'ArrowRight'
      ) {
        setTimeout(() => {
          event.event.preventDefault();
          event.event.stopPropagation();
          this.directional.emit(event.event);
        });
        return;
      }
    }
    if (this.dxDatePickerControl.opened) {
      this.dxDatePickerControl.opened = false;
    }
    if (event.event.key === 'Enter' || event.event.key === 'Tab') {
      if (!this.convertSpecialFormat()) {
        const date: Date = this.checkStringToDateCompatible(this.dxDatePickerControl.text)
          ? this.convertCustomStringToDate(this.dxDatePickerControl.text)
          : undefined;
        const controlValue: any = this.dxDatePickerControl.value
          ? this.dxDatePickerControl.value.toString()
          : undefined;
        if (
          (date &&
            ((controlValue && controlValue !== date.toString()) ||
              !controlValue ||
              !this.dxDatePickerControl.isValid)) ||
          this.AllowNull
        ) {
          if (this.isGrid) {
            this.value = date;
          } else {
            this.value = null;
            setTimeout(() => {
              this.value = date;
            });
          }
        }
      }
    } else if (event.event.keyCode === 27) {
      // Escape
      this.value = this.original;
    }
    this.keydowns.emit(event.event.key);
  }

  public onkeypress(event: any): void {
    // event.event.stopPropagation();
    // event.event.preventDefault();
  }

  public onValueChanged(event: any): void {
    ////console.log("valueChanged event raised!");
    if (this.type === 'datetime') {
      if (this.dxDatePickerControl.opened && event.event) {
        setTimeout(() => {
          event.event.preventDefault();
          event.event.stopPropagation();
          this.dxDatePickerControl.instance.open();
        });
      }
      return;
    }
  }

  public onDxDatePickerClosed(event: any): void {
    ////console.log("popup closed event raised!");
  }

  private convertSpecialFormat(): boolean {
    let result = false;
    if (this.dxDatePickerControl && this.dxDatePickerControl.text) {
      const today: Date = new Date();
      if (/^[Uu][0-9]$/g.test(this.dxDatePickerControl.text) || /^[Uu][0-9]{2}$/g.test(this.dxDatePickerControl.text)) {
        const monthStr: string = this.dxDatePickerControl.text.substring(1, 3);
        const month: any = monthStr ? this.sessionService.parseInt(monthStr) : undefined;
        if (month) {
          result = true;
          const lastDateOfMonth: number = new Date(today.getFullYear(), month, 0).getDate();
          this.value = this.createDate(today.getFullYear(), month - 1, lastDateOfMonth);
        }
      } else {
        switch (this.dxDatePickerControl.text) {
          case 'd':
            result = true;
            this.value = this.createDate(today.getFullYear(), today.getMonth(), today.getDate());
            break;
          case 'u':
            result = true;
            const lastDateOfMonth: number = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
            this.value = this.createDate(today.getFullYear(), today.getMonth(), lastDateOfMonth);
            break;
          default:
            break;
        }
      }
    }

    return result;
  }

  private convertInvalidDxDate(): void {
    // Dx control has invalid value.
    if (this.dxDatePickerControl && this.dxDatePickerControl.validationError) {
      this.value = this.checkStringToDateCompatible(this.dxDatePickerControl.text)
        ? this.convertCustomStringToDate(this.dxDatePickerControl.text)
        : undefined;
    }
  }

  protected convertValue(value: any): Date {
    return this.convertDateValue(value);
  }

  protected valid(): boolean {
    return this.validate(this.value as any);
  }

  public get showValidationMessage(): boolean {
    return !this.valid() && !this.disable && this.validationText && this.validationText.length > 0;
  }

  private convertDateValue(value: any): Date {
    const isValidationPassed: boolean = this.validate(
      ReflectionHelper.isDate(value) ? value.toISOString() : (value as any)
    );
    if (isValidationPassed && value) {
      // This should only run when the user manually changes the date string
      let date: Date = ReflectionHelper.isDate(value) ? value : new Date(value as any);
      if (this.type === 'datetime') {
        date = !date
          ? undefined
          : this.createDateWithHour(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              date.getHours(),
              date.getMinutes(),
              date.getSeconds()
            );
      } else {
        date = !date ? undefined : this.createDate(date.getFullYear(), date.getMonth(), date.getDate());
      }
      return date;
    } else {
      return value;
    }
  }

  private validate(value: string): boolean {
    if (this.required && !value) {
      this.validationText = this.requiredMessage;
      return false;
    }

    return true;
  }

  public get isNonRequired(): boolean {
    return !this.required || (this.required && !!this.value);
  }

  private checkStringToDateCompatible(value?: string): boolean {
    let isCompatible = false;
    if (this.type === 'datetime') {
      if (value && value.length > 0) {
        if (this.sessionService.isSwedenCompany) {
          if (/^\d{2}\-\d{2}\-\d{2} (00|[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9])$/.test(value)) {
            const parts = value.split(/\-| |:|\./);

            const day = parseInt(parts[2], 10);
            const month = parseInt(parts[1], 10);

            let year = parseInt(parts[0], 10);
            const century = Math.floor((new Date().getFullYear() - 1) / 100) + 1;

            year = (century - 1) * 100 + year;

            if (year < 1000 || year > 3000 || month === 0 || month > 12) {
              return isCompatible;
            }

            const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

            if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
              monthLength[1] = 29;
            }

            return !(day > 0 && day <= monthLength[month - 1]);
          }
        }

        const dateValue = value.split(/\s+/)[0];
        const dateNumberString: string = dateValue.replace(/[^0-9\.\-\/ ,]/g, '');
        let date: number = dateNumberString.substring(0, 2)
          ? this.sessionService.parseInt(dateNumberString.substring(0, 2))
          : undefined;
        if (
          !date &&
          (/[.]/g.test(dateNumberString) || /[-]/g.test(dateNumberString) || /[\/]/g.test(dateNumberString))
        ) {
          const seperator: string = /[.]/g.test(dateNumberString)
            ? '.'
            : /[-]/g.test(dateNumberString)
            ? '-'
            : /[\/]/g.test(dateNumberString)
            ? '/'
            : '';
          date = dateNumberString.split(seperator)[0]
            ? this.sessionService.parseInt(dateNumberString.split(seperator)[0])
            : undefined;
        }
        if (date) {
          isCompatible = true;
        }
      }
    } else {
      if (value && value.length > 0) {
        if (this.sessionService.isSwedenCompany) {
          if (/^\d{2}\-\d{2}\-\d{2}$/.test(value)) {
            const parts = value.split('-');

            const day = parseInt(parts[2], 10);
            const month = parseInt(parts[1], 10);

            let year = parseInt(parts[0], 10);
            const century = Math.floor((new Date().getFullYear() - 1) / 100) + 1;

            // convert year to year in current century
            year = (century - 1) * 100 + year;

            if (year < 1000 || year > 3000 || month === 0 || month > 12) {
              return isCompatible;
            }

            const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

            if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
              monthLength[1] = 29;
            }

            return !(day > 0 && day <= monthLength[month - 1]);
          }
        }

        const dateNumberString: string = value.replace(/[^0-9\.\-\/ ,]/g, '');
        let date: number = dateNumberString.substring(0, 2)
          ? this.sessionService.parseInt(dateNumberString.substring(0, 2))
          : undefined;
        if (
          !date &&
          (/[.]/g.test(dateNumberString) || /[-]/g.test(dateNumberString) || /[\/]/g.test(dateNumberString))
        ) {
          const seperator: string = /[.]/g.test(dateNumberString)
            ? '.'
            : /[-]/g.test(dateNumberString)
            ? '-'
            : /[\/]/g.test(dateNumberString)
            ? '/'
            : '';
          date = dateNumberString.split(seperator)[0]
            ? this.sessionService.parseInt(dateNumberString.split(seperator)[0])
            : undefined;
        }
        if (date) {
          isCompatible = true;
        }
      }
    }

    return isCompatible;
  }

  private convertCustomStringToDate(value: string): Date {
    if (value && value.length > 0) {
      const dateValue = value.split(/\s+/)[0];
      let dateNumberString: string = dateValue.replace(/[^0-9\.\-\/ ,]/g, '');
      let today: Date = new Date();
      today = this.createDate(today.getFullYear(), today.getMonth(), today.getDate());
      let date: number;
      let month: number;
      let year: number;
      if (/[.]/g.test(dateNumberString) || /[-]/g.test(dateNumberString) || /[\/]/g.test(dateNumberString)) {
        const seperator: string = /[.]/g.test(dateNumberString)
          ? '.'
          : /[-]/g.test(dateNumberString)
          ? '-'
          : /[\/]/g.test(dateNumberString)
          ? '/'
          : '';
        date = dateNumberString.split(seperator)[0]
          ? this.sessionService.parseInt(dateNumberString.split(seperator)[0])
          : undefined;
        month = dateNumberString.split(seperator)[1]
          ? this.sessionService.parseInt(dateNumberString.split(seperator)[1]) - 1
          : today.getMonth();
        year = dateNumberString.split(seperator)[2]
          ? this.sessionService.parseInt(dateNumberString.split(seperator)[2])
          : today.getFullYear() + this.SuggestYear(month, today.getMonth());
      } else {
        dateNumberString = dateValue.replace(/[^0-9,]/g, '');
        date = dateNumberString.substring(0, 2)
          ? this.sessionService.parseInt(dateNumberString.substring(0, 2))
          : undefined;
        month = dateNumberString.substring(2, 4)
          ? this.sessionService.parseInt(dateNumberString.substring(2, 4)) - 1
          : today.getMonth();
        year = dateNumberString.substring(4, 8)
          ? this.sessionService.parseInt(dateNumberString.substring(4, 8))
          : today.getFullYear() + this.SuggestYear(month, today.getMonth());
      }
      if (!this.isValidDataAfterConvert(month, date, year)) {
        return undefined;
      }
      if (this.type === 'datetime') {
        let hour = 0;
        let minute = 0;
        let second = 0;
        const timeValue = String(value.split(/\s+/).slice(1));
        const timeNumberString: string = timeValue.replace(/[^0-9\:\- ,]/g, '');
        if (/[.]/g.test(timeNumberString) || /[:]/g.test(timeNumberString)) {
          const seperator: string = /[.]/g.test(timeNumberString)
            ? '.'
            : /[:]/g.test(timeNumberString)
            ? ':'
            : undefined;
          hour = timeNumberString.split(seperator)[0]
            ? this.sessionService.parseInt(timeNumberString.split(seperator)[0])
            : undefined;
          minute = timeNumberString.split(seperator)[1]
            ? this.sessionService.parseInt(timeNumberString.split(seperator)[1])
            : undefined;
          second = timeNumberString.split(seperator)[2]
            ? this.sessionService.parseInt(timeNumberString.split(seperator)[2])
            : undefined;
          if ((hour && (hour > 23 || hour < 0)) || hour === undefined) {
            hour = 0;
          }
          if ((minute && (minute > 59 || minute < 0)) || minute === undefined) {
            minute = 0;
          }
          if ((second && (second > 59 || second < 0)) || second === undefined) {
            second = 0;
          }
        }
        return this.createDateWithHour(year, month, date, hour, minute, second);
      }
      return this.createDate(year, month, date);
    }

    return undefined;
  }

  public isValidDataAfterConvert(month: number, date: number, year: number): boolean {
    if ((month && (month > 11 || month < 0)) || month === undefined) {
      return false;
    }
    const lastDateOfMonth: number = new Date(year, month + 1, 0).getDate();
    if (!lastDateOfMonth || (date && (date > lastDateOfMonth || date < 1)) || !date) {
      return false;
    }
    return true;
  }

  private SuggestYear(inputMonth: number, currentMonth: number): number {
    let res = 0;
    if (inputMonth < currentMonth) {
      const temp = inputMonth + 12;
      res = temp - currentMonth;
      if (0 < res && res <= 3) {
        return 1;
      }
    } else {
      res = inputMonth - currentMonth;
      if (0 < res && res <= 3) {
        if (currentMonth + res <= 11) {
          return 0;
        } else {
          return 1;
        }
      } else if (res > 3) {
        return -1;
      }
    }
    return 0;
  }

  private createDate(year: number, month: number, date: number): Date {
    const today: Date = new Date();
    if (date) {
      year =
        year < 100
          ? this.sessionService.parseInt(
              today
                .getFullYear()
                .toString()
                .substr(0, 2) + this.sessionService.toString(year, '00')
            )
          : year;
      month = month > 11 ? 11 : month < 0 ? 0 : month;
      const lastDateOfMonth: number = new Date(year, month + 1, 0).getDate();
      date = date > lastDateOfMonth ? lastDateOfMonth : date < 0 ? 1 : date;

      let offset = new Date(year, month, date).getTimezoneOffset() / 60;
      offset = offset === 12 || offset === -12 ? 0 : offset;
      if (offset > 0) {
        return new Date(year, month, date, offset, 0, 0);
      }

      return new Date(year, month, date, -offset, 0, 0);
    }

    return undefined;
  }

  private createDateWithHour(
    year: number,
    month: number,
    date: number,
    hours: number,
    minutes: number,
    seconds: number
  ): Date {
    const today: Date = new Date();
    if (date) {
      year =
        year < 100
          ? this.sessionService.parseInt(
              today
                .getFullYear()
                .toString()
                .substr(0, 2) + this.sessionService.toString(year, '00')
            )
          : year;
      month = month > 11 ? 11 : month < 0 ? 0 : month;
      const lastDateOfMonth: number = new Date(year, month + 1, 0).getDate();
      date = date > lastDateOfMonth ? lastDateOfMonth : date < 0 ? 1 : date;

      return new Date(year, month, date, hours, minutes, seconds);
    }

    return undefined;
  }

  public get pickerType(): string {
    if (this.sessionService.browser.isMobile) {
      return 'rollers';
    }
    return 'calendar';
  }

  public showCalendarButtonConfig: any = {
    onClick: () => {
      this.dxDatePickerControl.opened = !this.dxDatePickerControl.opened;
    },
    disabled: false,
    icon: 'event'
  };

  public deleteValueButtonConfig: any = {
    onClick: () => {
      this.value = undefined;
    },
    disabled: false,
    icon: 'close'
  };
}
