import { OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RouterStateService } from '../common/router-state.service';
import { SessionService } from '../services/session/session.service';
import { ReportDialogService } from '../shared-components/report-dialog/report-dialog.service';
import { CompanyGeneralService } from './general/company-general.service';
var CompanyComponent = /** @class */ (function () {
    function CompanyComponent(sessionService, state, reportDialogService, companyGeneralService) {
        this.sessionService = sessionService;
        this.state = state;
        this.reportDialogService = reportDialogService;
        this.companyGeneralService = companyGeneralService;
        this.mobileWarningDialogVisible = false;
    }
    Object.defineProperty(CompanyComponent.prototype, "IsMobile", {
        get: function () {
            return this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyComponent.prototype, "isIOSApp", {
        get: function () {
            return this.sessionService.browser.isHybridApp && this.sessionService.browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    CompanyComponent.prototype.ngOnInit = function () {
        this.mobileWarningDialogVisible =
            this.sessionService.browser.isHybridApp &&
                (this.sessionService.currentState === 'tabs.company.configuration.dataimport' ||
                    this.sessionService.currentState === 'tabs.company.configuration.timeentrytypes');
    };
    CompanyComponent.prototype.clickAreamenuToggledChange = function () {
        this.sessionService.menuToggled = true;
        this.sessionService.showMoreMenuToggled = false;
        this.sessionService.showHelpMenuToggled = false;
        this.sessionService.showMenuToggled = false;
    };
    Object.defineProperty(CompanyComponent.prototype, "isCompanyGeneralTabVisisble", {
        // Tabs
        get: function () {
            return this.sessionService.currentState.includes('tabs.company.general');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyComponent.prototype, "isCompanyBalancesVisisble", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.company.balances');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyComponent.prototype, "isCompanyApprovalVisisble", {
        get: function () {
            return (this.sessionService.currentState.includes('tabs.company.approval') ||
                this.sessionService.currentState.includes('tabs.company.approval.timeentry'));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyComponent.prototype, "isCompanyPayrollDataTabVisisble", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.company.payrolldata');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyComponent.prototype, "isCompanySalaryBatchesTabVisisble", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.company.salarybatches');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyComponent.prototype, "isCompanyConfigurationTabVisisble", {
        get: function () {
            return (this.sessionService.currentState.includes('tabs.company.configuration') ||
                this.sessionService.currentState.includes('tabs.company.configuration.advancedsalarytypes') ||
                this.sessionService.currentState.includes('tabs.company.configuration.timeentrytypes') ||
                this.sessionService.currentState.includes('tabs.company.configuration.settings') ||
                this.sessionService.currentState.includes('tabs.company.configuration.dataimport') ||
                this.sessionService.currentState.includes('tabs.company.configuration.integrations') ||
                this.sessionService.currentState.includes('tabs.company.configuration.hiringstatus'));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyComponent.prototype, "isCompanyExtraTabVisisble", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.company.modules');
        },
        enumerable: true,
        configurable: true
    });
    return CompanyComponent;
}());
export { CompanyComponent };
