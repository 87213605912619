// tslint:disable:class-name
// tslint:disable: max-classes-per-file

export interface IAccount {
  Id: number;
  Name?: string;
  StatusId: number;
  AccountTypeId: number;
  IsPublic: boolean;
  Description?: string;
  DefaultInvoiceTypeId: number;
  MasterCompanyId?: number;
  MasterCompanyEmail?: string;
  MasterCompanyVATRegistrationNumber?: string;
  MasterCompanyName?: string;
  MasterCompanyAddress?: string;
  MasterCompanyPostalCode?: string;
  MasterCompanyCity?: string;
  MasterCompanyCountry?: string;
  MasterCompanyEAN?: string;
  MasterCompanyAttention?: string;
  Companies?: ICompany[];
  LoginHistories?: ILoginHistory[];
  MasterCompany?: ICompany;
  AccountType?: IAccountType;
  AccountStatus?: IAccountStatus;
  DefaultInvoiceType?: IAccountInvoiceType;

  setDefaultValue?(): void;
}

export interface ICompany {
  Id: number;
  Name?: string;
  StatusId: number;
  VATRegistrationNumber?: string;
  AccountId?: number;
  Email1?: string;
  Email2?: string;
  DefaultLanguageId: number;
  AddressId: number;
  CompanyTypeId: number;
  SecondaryVATRegistrationNumber?: string;
  MainContactCompanyUserId?: number;
  BankRegNo?: string;
  BankAccountNo?: string;
  BicSwiftNo?: string;
  IbanNo?: string;
  ExternalSystemComment?: string;
  PaymentMethodSalary: number;
  PaymentMethodPension: number;
  PaymentMethodTax: number;
  ExternalSystemId?: number;
  TelMobile?: string;
  TelFixed?: string;
  KnowsIntectFrom?: string;
  ExternalAuthentication?: string;
  HasNetsAgreement: boolean;
  Created: Date;
  EmployersAssociationNumber?: string;
  StaticsReportsYearly: boolean;
  StatisticsRecipientId?: number;
  SubscribeToNewsLetter: boolean;
  EstablishedDate?: Date;
  EmployeeCountEstimate?: string;
  Preferences?: string;
  InvoiceIdentifier?: string;
  PaymentMethodPublicPension: number;
  PaymentMethodVacationProvider: number;
  InvoiceEmail?: string;
  HasAcceptedTerms: boolean;
  CountryId: number;
  CurrentPackageLevel: number;
  ScheduledPackageId?: number;
  ScheduledPackageDate?: Date;
  IndustryCode?: string;
  AccountComment?: string;
  BankId?: number;
  IndustryOrganizationId?: number;
  InvoiceTypeId?: number;
  DefaultTaxTableId?: number;
  AllowAutoDetermineBankAccount: boolean;
  SystemAliasId: number;
  Language?: ILanguage;
  Address?: IAddress;
  CompanyLogo?: ICompanyLogo;
  Departments?: IDepartment[];
  ExternalAccounts?: IExternalAccount[];
  ReimbursementOpportunities?: IReimbursementOpportunity[];
  Holidays?: IHoliday[];
  DimensionValues?: IDimensionValue[];
  CompanyType?: ICompanyType;
  CompanyStatus?: ICompanyStatus;
  Country?: ICountry;
  InvoiceType?: IAccountInvoiceType;

  setDefaultValue?(): void;
}

export interface ILoginHistory {
  Id: number;
  LoginTime: Date;
  UserIp?: string;
  Country?: string;
  City?: string;
  Region?: string;
  PostalCode?: string;
  IsSuccessful: boolean;
  UserName?: string;
  UserId?: number;
  CompanyId?: number;
  UserAgent?: string;
  BrowserName?: string;
  BrowserVersion?: string;
  OSName?: string;
  OSVersion?: string;
  OperationType?: string;
  AccountId?: number;
  Company?: ICompany;
  User?: IUser;
  Account?: IAccount;

  setDefaultValue?(): void;
}

export interface IAccountType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IAccountStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IAccountInvoiceType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ILanguage {
  Id: number;
  Name?: string;
  Code?: string;
  IsSupported: boolean;
  CultureCode?: string;

  setDefaultValue?(): void;
}

export interface IAddress {
  Id: number;
  Line1?: string;
  Line2?: string;
  PostalCode?: string;
  City?: string;
  CountryId?: number;
  Country?: ICountry;

  setDefaultValue?(): void;
}

export interface ICompanyLogo {
  Id: number;
  Logo?: string;
  Company?: ICompany;

  setDefaultValue?(): void;
}

export interface IDepartment {
  Id: number;
  CompanyId: number;
  Name?: string;
  Description?: string;
  IsActive: boolean;
  EIncomeReference?: string;
  ExternalReference?: string;
  ManagerCompanyUserId?: number;
  DimensionValues?: IDimensionValue[];
  TimeEntryTypeConfigurations?: ITimeEntryTypeConfiguration[];

  setDefaultValue?(): void;
}

export interface IExternalAccount {
  Id: number;
  CompanyId: number;
  AccountIdentifier?: string;
  AccountNumber?: string;
  AccountName?: string;
  Description?: string;
  IntegrationId?: number;
  Company?: ICompany;
  Integration?: IIntegration;

  setDefaultValue?(): void;
}

export interface IReimbursementOpportunity {
  Id: number;
  ReimbursementTypeId: number;
  StartDate?: Date;
  EndDate?: Date;
  EndsEligibility: boolean;
  StatusId: number;
  Created?: Date;
  Comment?: string;
  Company?: ICompany;
  UserEmployment?: IUserEmployment;
  TimeEntryRecords?: ITimeEntryRecord[];

  setDefaultValue?(): void;
}

export interface IHoliday {
  Id: number;
  Date: Date;
  ConsiderHoliday: boolean;
  DisablesBankPayments: boolean;
  CountryId?: number;
  CompanyId?: number;
  UserEmploymentTemplateId?: number;
  Description?: string;
  Created?: Date;
  Company?: ICompany;
  UserEmploymentTemplate?: IUserEmploymentTemplate;

  setDefaultValue?(): void;
}

export interface IDimensionValue {
  Id: number;
  CompanyId: number;
  DimensionNumber: number;
  Value?: string;
  Description?: string;
  ApproverCompanyUserId?: number;
  LimitToDepartmentId?: number;
  LimitToTemplateId?: number;

  setDefaultValue?(): void;
}

export interface ICompanyType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ICompanyStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ICountry {
  IsSupported: boolean;
  DefaultLanguageId: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IUser {
  FullName?: string;
  IdentityNumberFormatted?: string;
  HasDummyIdentityNumber: boolean;
  Gender?: IGender;
  AccountRole?: IAccountRole;
  Id: number;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  IdentityNumber?: string;
  BankRegNo?: string;
  BankAccountNo?: string;
  Username?: string;
  LanguageId: number;
  BicSwiftNo?: string;
  IbanNo?: string;
  AddressId: number;
  AccessPhrase?: string;
  DateOfBirth?: Date;
  TelMobile?: string;
  TelFixed?: string;
  Email?: string;
  DefaultInitials?: string;
  DefaultCompanyId?: number;
  UseGlobalEmail: boolean;
  IsFirstLogin: boolean;
  Preferences?: string;
  Created: Date;
  ShowUpdateNotification: boolean;
  ShowUrgentNotification: boolean;
  ShowPayslipTutorial: number;
  AccountId?: number;
  AccountRoleId?: number;
  IsFemale?: boolean;
  SystemAliasId: number;
  Address?: IAddress;
  CompanyUsers?: ICompanyUser[];
  UserImage?: IUserImage;
  LoginHistories?: ILoginHistory[];
  ActivatedCompanyModules?: ICompanyModule[];

  setDefaultValue?(): void;
}

export interface ITimeEntryTypeConfiguration {
  Id: number;
  TimeEntryTypeId: number;
  SalaryTypeId: number;
  Description?: string;
  Amount?: number;
  AmountPerUnit?: number;
  AppliesBefore?: string;
  AppliesAfter?: string;
  AppliesHolidays: boolean;
  AmountPerKilometer?: number;
  EmploymentTemplateId?: number;
  TargetUnitTypeId?: number;
  ConversionFactor?: number;
  UserEmploymentId?: number;
  AppliesSaturdays: boolean;
  AppliesSundays: boolean;
  UseDefaultRate: boolean;
  ConversionFactorAffectsRate: boolean;
  LegacyFlexEarningPercentage?: number;
  NewFlexEarningPercentage?: number;
  PensionPercentage?: number;
  AppliesMondays: boolean;
  AppliesTuesdays: boolean;
  AppliesWednesdays: boolean;
  AppliesThursdays: boolean;
  AppliesFridays: boolean;
  LimitToDepartmentId?: number;
  LimitToDimension1ValueId?: number;
  LimitToDimension2ValueId?: number;
  LimitToDimension3ValueId?: number;

  setDefaultValue?(): void;
}

export interface IIntegration {
  Id: number;
  CompanyId: number;
  ExternalSystemId: number;
  Name?: string;
  Created: Date;
  StatusCode: number;
  IsSalaryTypeMappingActive: boolean;
  IntegrationOperations?: IIntegrationOperation[];
  IntegrationConfigurations?: IIntegrationConfiguration[];
  IntegrationCredentials?: IIntegrationCredential[];

  setDefaultValue?(): void;
}

export interface IUserEmployment {
  VacationType?: IVacationType;
  VacationProvider?: IVacationProvider;
  PensionProvider?: IPensionProvider;
  Pension2Provider?: IPensionProvider;
  PensionBaseCalculationMethod?: IBaseCalculationMethod;
  Pension2BaseCalculationMethod?: IBaseCalculationMethod;
  SalaryCycle?: ISalaryCycle;
  HasWorkHoursPerWeekDay: boolean;
  Id: number;
  CompanyUserId: number;
  HireDate: Date;
  ExpireDate?: Date;
  VacationTypeId: number;
  VacationProviderId?: number;
  VacationExtraPercentage?: number;
  BasedOnTemplateId?: number;
  Title?: string;
  SalaryCycleId: number;
  ExternalReference?: string;
  PensionBaseCalculationMethodId: number;
  IsAtpTempWorker: boolean;
  DepartmentId?: number;
  IncomeTypeId: number;
  WorksElsewhere: boolean;
  PensionEmployeeShare?: number;
  PensionCompanyShare?: number;
  PensionProviderId?: number;
  PensionPolicyNumber?: string;
  PensionEmployeeAmount?: number;
  PensionCompanyAmount?: number;
  Pension2BaseCalculationMethodId: number;
  Pension2ProviderId?: number;
  Pension2EmployeeShare?: number;
  Pension2CompanyShare?: number;
  Pension2EmployeeAmount?: number;
  Pension2CompanyAmount?: number;
  LastStatementEndDate?: Date;
  VacationReduceSalary: boolean;
  IsTerminated: boolean;
  VacationSupplementPayoutMonth: number;
  IsInMaternityProgram: boolean;
  TaxCardRequested?: Date;
  PensionProviderBankRegNo?: string;
  PensionProviderBankAccountNo?: string;
  Pension2ProviderBankRegNo?: string;
  Pension2ProviderBankAccountNo?: string;
  Pension2PolicyNumber?: string;
  PreferredTaxCardTypeId: number;
  Created: Date;
  IllnessReducesSalary: boolean;
  ManagerCompanyUserId?: number;
  StandardHoursPerWeek?: number;
  StatisticsSalaryPrincipleId?: number;
  StatisticsEmploymentPrincipleId?: number;
  StatisticsEmploymentTermsId?: number;
  StatisticsJobStatusId?: number;
  StatisticsJobFunctionCode?: number;
  StatisticsSalaryPrincipleFromDate?: Date;
  StatisticsEmploymentPrincipleFromDate?: Date;
  StatisticsEmploymentTermsFromDate?: Date;
  StatisticsJobStatusFromDate?: Date;
  StandardWorkDaysPerWeek?: number;
  StatisticsJobFunctionFromDate?: Date;
  FlexEarningPercentage: number;
  FlexBaseCalculationMethodId: number;
  CollectiveBargainingAgreementCode?: string;
  TerminationVacationOverridePercentage?: number;
  SeniorityDate?: Date;
  ApplyDanishRulesInGreenland: boolean;
  AnnualTaxComment?: string;
  AllowExtendedTaxCardDeductions: boolean;
  ReduceTaxCardDeduction?: number;
  IncreaseTaxRatePercentage?: number;
  UseTaxDeductionPerWorkDay: boolean;
  DefaultRate?: number;
  UseContinuousVacationReporting: boolean;
  TaxTableNumber?: number;
  TaxColumnNumber?: number;
  AdHocTaxRateOverride?: number;
  VacationDaysPerYear?: number;
  SecondaryExternalReference?: string;
  OmitVacationReporting: boolean;
  LastIllnessEndDate?: Date;
  LastIllnessStartDate?: Date;
  VacationTerminationDate?: Date;
  Pension1DueDateDelayDays?: number;
  Pension2DueDateDelayDays?: number;
  AdditionalVacationTerminationMethod?: number;
  AdditionalVacationSpentFirst: boolean;
  Updated?: Date;
  AtpHours?: number;
  IsPendingRecalc: boolean;
  PensionIsNotTaxDeductible: boolean;
  Pension2IsNotTaxDeductible: boolean;
  PensionStartDate?: Date;
  PensionEndDate?: Date;
  Pension2StartDate?: Date;
  Pension2EndDate?: Date;
  PensionFixedAmountIsPerDay: boolean;
  Pension2FixedAmountIsPerDay: boolean;
  AdvanceVacationDays?: number;
  WorkplaceIdentifier?: string;
  StandardWorkHoursMonday?: number;
  StandardWorkHoursTuesday?: number;
  StandardWorkHoursWednesday?: number;
  StandardWorkHoursThursday?: number;
  StandardWorkHoursFriday?: number;
  StandardWorkHoursSaturday?: number;
  StandardWorkHoursSunday?: number;
  CompanyUser?: ICompanyUser;
  UserTaxInfos?: IUserTaxInfo[];
  SalaryRecords?: ISalaryRecord[];
  TimeEntryTypeConfigurations?: ITimeEntryTypeConfiguration[];
  EmploymentBalanceConfigurations?: IEmploymentBalanceConfiguration[];

  setDefaultValue?(): void;
}

export interface ITimeEntryRecord {
  employeeNameWriteable?: string;
  UnitType?: IUnitType;
  Status?: ITimeEntryStatus;
  EmployeeName?: string;
  Title?: string;
  DepartmentId?: number;
  DepartmentName?: string;
  IsCalculated: boolean;
  Id: number;
  UserEmploymentId: number;
  EntryDate: Date;
  TimeEntryTypeId: number;
  Units: number;
  UnitTypeId: number;
  Description?: string;
  SalaryPeriodId: number;
  StatusId: number;
  Created: Date;
  RejectionReason?: string;
  SalaryRecordId?: number;
  SalaryStatementId?: number;
  KilometersDriven?: number;
  StartTime?: string;
  EndTime?: string;
  Dimension1Value?: string;
  Dimension2Value?: string;
  Dimension3Value?: string;
  EndDate?: Date;
  CalculatedFromRecordId?: number;
  ExternalIdentifier?: string;
  CalculatedPayout?: number;
  CalculatedPayoutKilometers?: number;
  AmountPerUnit?: number;
  ApprovedUserId?: number;
  RejectedUserId?: number;
  Dimension1ValueId?: number;
  Dimension2ValueId?: number;
  Dimension3ValueId?: number;

  setDefaultValue?(): void;
}

export interface IUserEmploymentTemplate {
  VacationType?: IVacationType;
  VacationProvider?: IVacationProvider;
  PensionProvider?: IPensionProvider;
  Pension2Provider?: IPensionProvider;
  PensionBaseCalculationMethod?: IBaseCalculationMethod;
  Pension2BaseCalculationMethod?: IBaseCalculationMethod;
  SalaryCycle?: ISalaryCycle;
  Id: number;
  CompanyId: number;
  Name?: string;
  VacationTypeId: number;
  VacationProviderId?: number;
  VacationExtraPercentage?: number;
  PensionBaseCalculationMethodId: number;
  IsAtpTempWorker: boolean;
  WorksElsewhere: boolean;
  PensionEmployeeShare?: number;
  PensionCompanyShare?: number;
  PensionProviderId?: number;
  PensionEmployeeAmount?: number;
  PensionCompanyAmount?: number;
  Pension2BaseCalculationMethodId: number;
  Pension2ProviderId?: number;
  Pension2EmployeeShare?: number;
  Pension2CompanyShare?: number;
  Pension2EmployeeAmount?: number;
  Pension2CompanyAmount?: number;
  IncomeTypeId: number;
  SalaryCycleId: number;
  VacationReduceSalary: boolean;
  VacationSupplementPayoutMonth: number;
  IsInMaternityProgram: boolean;
  PensionProviderBankRegNo?: string;
  PensionProviderBankAccountNo?: string;
  Pension2ProviderBankRegNo?: string;
  Pension2ProviderBankAccountNo?: string;
  IllnessReducesSalary: boolean;
  StandardHoursPerWeek?: number;
  StatisticsSalaryPrincipleId?: number;
  StatisticsEmploymentPrincipleId?: number;
  StatisticsEmploymentTermsId?: number;
  StatisticsJobStatusId?: number;
  StatisticsJobFunctionCode?: number;
  StandardWorkDaysPerWeek?: number;
  DepartmentId?: number;
  FlexEarningPercentage: number;
  FlexBaseCalculationMethodId: number;
  IsDefault: boolean;
  PensionPolicyNumber?: string;
  Pension2PolicyNumber?: string;
  CollectiveBargainingAgreementCode?: string;
  TerminationVacationOverridePercentage?: number;
  SystemDefaultForCountryId?: number;
  TimeEntryRecordAcrossSalaryPeriodStrategy: number;
  AllowExtendedTaxCardDeductions: boolean;
  TemplateExternalReference?: string;
  UseTaxDeductionPerWorkDay: boolean;
  DefaultRate?: number;
  VacationDaysPerYear?: number;
  ApplyLongTermIllnessCompensation: boolean;
  AnnualIncomeMultiplicationFactor?: number;
  Pension1DueDateDelayDays?: number;
  Pension2DueDateDelayDays?: number;
  AdditionalVacationTerminationMethod?: number;
  AdditionalVacationSpentFirst: boolean;
  PensionIsNotTaxDeductible: boolean;
  Pension2IsNotTaxDeductible: boolean;
  PensionStartDate?: Date;
  PensionEndDate?: Date;
  Pension2StartDate?: Date;
  Pension2EndDate?: Date;
  PensionFixedAmountIsPerDay: boolean;
  Pension2FixedAmountIsPerDay: boolean;
  AdvanceVacationDays?: number;
  WorkplaceIdentifier?: string;
  StandardWorkHoursMonday?: number;
  StandardWorkHoursTuesday?: number;
  StandardWorkHoursWednesday?: number;
  StandardWorkHoursThursday?: number;
  StandardWorkHoursFriday?: number;
  StandardWorkHoursSaturday?: number;
  StandardWorkHoursSunday?: number;
  TemplateSalaryRecords?: ITemplateSalaryRecord[];
  SenioritySupplements?: ISenioritySupplement[];
  OvertimeSupplements?: IOvertimeSupplement[];
  TemplateBalanceConfigurations?: ITemplateBalanceConfiguration[];
  Holidays?: IHoliday[];
  DimensionValues?: IDimensionValue[];

  setDefaultValue?(): void;
}

export interface IGender {
  IsFemale: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IAccountRole {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ICompanyUser {
  Id: number;
  UserId: number;
  CompanyId: number;
  RoleId: number;
  IsActive: boolean;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  BankRegNo?: string;
  BankAccountNo?: string;
  BicSwiftNo?: string;
  IbanNo?: string;
  AddressId: number;
  IsPaymentApprover: boolean;
  Initials?: string;
  TelMobile?: string;
  TelFixed?: string;
  CompanyEmail?: string;
  PersonalEmail?: string;
  Created: Date;
  DeactivationDate?: Date;
  PreferredCurrencyId?: number;
  AutoDetermineBankAccount: boolean;
  MunicipalityId?: number;
  Updated?: Date;
  UserEmployments?: IUserEmployment[];
  Address?: IAddress;
  UserImage?: IUserImage;
  DimensionValues?: IDimensionValue[];
  FullName?: string;
  Role?: IRole;
  UserLanguageId: number;
  UserIdentityNumber?: string;
  UserDateOfBirth: Date;
  UserIsFemale?: boolean;
  HasDummyIdentityNumber: boolean;

  setDefaultValue?(): void;
}

export interface IUserImage {
  Id: number;
  Image?: string;

  setDefaultValue?(): void;
}

export interface ICompanyModule {
  Id: number;
  ModuleId: number;
  LastActivated: Date;
  CompanyId: number;
  ScheduledDeactivationDate?: Date;
  ActivatedUserId?: number;
  ActivatedByUser?: IUser;
  Module?: IModule;

  setDefaultValue?(): void;
}

export interface IIntegrationOperation {
  Id: number;
  IntegrationId: number;
  IsActive: boolean;
  LastRun?: Date;
  StatusCode: number;
  OperationId: number;
  TranslationKey?: string;
  TranslationParametersList?: string;
  Integration?: IIntegration;
  TranslatedStatusMessage?: string;

  setDefaultValue?(): void;
}

export interface IIntegrationConfiguration {
  Id: number;
  IntegrationId: number;
  Key?: string;
  Value?: string;
  Integration?: IIntegration;

  setDefaultValue?(): void;
}

export interface IIntegrationCredential {
  Id: number;
  IntegrationId: number;
  Key?: string;
  Value?: string;
  Integration?: IIntegration;
  ExternalSystemCredential?: IExternalSystemCredential;
  IsPrivate: boolean;

  setDefaultValue?(): void;
}

export interface IVacationType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IVacationProvider {
  SENumber?: string;
  IsAdministrative: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IPensionProvider {
  NetsNo?: string;
  DefaultBaseCalculationMethodId: number;
  NetsInfoType: number;
  PolicyNumberRequired: boolean;
  AccountNumberRequired: boolean;
  PolicyNumberLabelOverride?: string;
  CountryId: number;
  IsDummyForBankAccount: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IBaseCalculationMethod {
  IsPensionBase: boolean;
  IsFlexBase: boolean;
  IsExtraVacationHoursBase: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ISalaryCycle {
  Months: number;
  Days: number;
  MonthEnd: boolean;
  IsForwardPaid: boolean;
  TaxDeductionFactor: number;
  IsMonthly: boolean;
  IsWeekly: boolean;
  IsBiWeekly: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IUserTaxInfo {
  TaxCardType?: ITaxCardType;
  IsCurrent: boolean;
  Id: number;
  ValidFrom: Date;
  ValidUntil?: Date;
  TaxRate?: number;
  DeductionMonth?: number;
  FreeAmount?: number;
  UserEmploymentId: number;
  IsActive: boolean;
  TaxCardTypeId: number;
  IsDummy: boolean;
  MinimumRate?: number;
  MaximumDeductionMonth?: number;
  MaximumDeductionDay?: number;
  Created: Date;
  DeductionWeek?: number;
  DeductionDay?: number;
  IsManual: boolean;
  ParsedFromFileName?: string;
  TaxCardRequestId?: number;

  setDefaultValue?(): void;
}

export interface ISalaryRecord {
  SalaryTypeView?: ISalaryTypeView;
  UnitType?: IUnitType;
  PersistenceType?: ISalaryRecordPersistenceType;
  Id: number;
  Units?: number;
  IsActive: boolean;
  StartDate?: Date;
  EndDate?: Date;
  Description?: string;
  UserEmploymentId: number;
  UnitTypeId?: number;
  AmountPerUnit?: number;
  Amount?: number;
  BaseAmount?: number;
  SalaryTypeId: number;
  SortOrder: number;
  TimeEntryTypeId?: number;
  PersistenceTypeId: number;
  Dimension1Value?: string;
  Dimension2Value?: string;
  Dimension3Value?: string;
  ExternalIdentifier?: string;
  LegacyFlexEarningPercentage?: number;
  NewFlexEarningPercentage?: number;
  PensionPercentage?: number;
  SalaryStatementId?: number;
  Dimension1ValueId?: number;
  Dimension2ValueId?: number;
  Dimension3ValueId?: number;
  TimeEntryType?: ITimeEntryType;
  SalaryStatement?: ISalaryStatement;

  setDefaultValue?(): void;
}

export interface IEmploymentBalanceConfiguration {
  Id: number;
  UserEmploymentId: number;
  BalanceDefinitionId: number;
  EarningPercentage?: number;
  BaseCalculationMethodId?: number;
  EarningFixedAmount?: number;
  EarningFixedAmountPrincipleId?: number;
  PensionSharePercentage?: number;
  PensionShareFixedAmount?: number;
  DaysConversionPercentage?: number;
  DaysEarnedPerPayslip?: number;
  PayoutPercentage?: number;
  SeniorityRequirementMonths?: number;
  CustomBehaviorId?: number;

  setDefaultValue?(): void;
}

export interface IUnitType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ITimeEntryStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ITemplateSalaryRecord {
  UnitType?: IUnitType;
  PersistenceType?: ISalaryRecordPersistenceType;
  Id: number;
  EmploymentTemplateId: number;
  Units?: number;
  IsActive: boolean;
  Description?: string;
  UnitTypeId?: number;
  AmountPerUnit?: number;
  Amount?: number;
  BaseAmount?: number;
  SalaryTypeId: number;
  PersistenceTypeId: number;
  SortOrder: number;

  setDefaultValue?(): void;
}

export interface ISenioritySupplement {
  Id: number;
  EmploymentTemplateId: number;
  SeniorityMonthsStart?: number;
  SeniorityMonthsEnd?: number;
  SupplementAmount: number;
  SupplementIntervalType: number;
  HourlyRate?: number;

  setDefaultValue?(): void;
}

export interface IOvertimeSupplement {
  Id: number;
  EmploymentTemplateId: number;
  TotalHoursWorkedFrom?: number;
  TotalHoursWorkedUntil?: number;
  SupplementAmount?: number;
  SupplementPercentage?: number;
  OvertimeSupplementPrinciple: number;

  setDefaultValue?(): void;
}

export interface ITemplateBalanceConfiguration {
  Id: number;
  EmploymentTemplateId: number;
  BalanceDefinitionId: number;
  EarningPercentage?: number;
  BaseCalculationMethodId?: number;
  EarningFixedAmount?: number;
  EarningFixedAmountPrincipleId?: number;
  PensionSharePercentage?: number;
  PensionShareFixedAmount?: number;
  DaysConversionPercentage?: number;
  DaysEarnedPerPayslip?: number;
  PayoutPercentage?: number;
  SeniorityRequirementMonths?: number;
  CustomBehaviorId?: number;

  setDefaultValue?(): void;
}

export interface IRole {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IModule {
  Price: number;
  BillingPrincipleId: number;
  FreeUntilDate?: Date;
  InfoOnly: boolean;
  PackageLevel: number;
  BillingPrinciple?: IBillingPrinciple;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IExternalSystemCredential {
  IsPrivate: boolean;
  BackendKey?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ITaxCardType {
  EIncomeReference: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ISalaryTypeView {
  SalaryTypeId: number;
  BaseSalaryTypeId?: number;
  CompanyId: number;
  LanguageId: number;
  CategoryId?: number;
  Name?: string;
  Description?: string;
  DefaultPayslipText?: string;
  IsVisible: boolean;
  IsActive: boolean;
  UnitTypeId?: number;
  AmountPerUnit?: number;
  Units?: number;
  BaseAmount?: number;
  Amount?: number;
  EIncomeCategory: number;
  EIncomeSubCategory: number;
  SummaryCategoryId?: number;
  SalaryTypeGroupId?: number;
  ExternalReference?: string;
  ImportExternalReference?: string;
  IsPrimaryIncome: boolean;
  TriggersAMB: boolean;
  IncludeInPayment: boolean;
  TriggersIncomeTax: boolean;
  TriggersATP: boolean;
  EarnsHolidayCompensation: boolean;
  IsInternal: boolean;
  SortOrder: number;
  AllowEditUnitType: boolean;
  AllowEditBaseAmount: boolean;
  NegativeDefault: boolean;
  DefaultPersistanceTypeId?: number;
  StatisticsOnly: boolean;
  StatisticsFieldCode?: string;
  SumFromIds?: string;
  SumFromReverseSign: boolean;
  IncludeAlways: boolean;
  RelatesToIncomeType5?: boolean;
  Explanation?: string;
  TriggersOvertimeSupplement?: boolean;
  SulinalFieldNumber?: string;
  OverviewIndex?: number;
  OverviewDisplayMode?: number;
  Identifier?: string;
  IsAdvanced?: boolean;
  AccountTypeId?: number;
  BalanceDefinitionId?: number;
  BalanceBehavior?: number;
  BaseName?: string;
  BaseDescription?: string;
  BasePayslipText?: string;
  SkatteverketAgiFieldCode?: number;
  DimensionDistributionOptionId?: number;
  DisplayDates: boolean;

  setDefaultValue?(): void;
}

export interface ISalaryRecordPersistenceType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ITimeEntryType {
  Id: number;
  BaseTimeEntryTypeId?: number;
  CompanyId?: number;
  Name?: string;
  Description?: string;
  IsActive?: boolean;
  UnitTypeId?: number;
  ExternalReference?: string;
  SalaryTypeId?: number;
  SortOrder?: number;
  DefaultPayslipText?: string;
  AllowEditUnitType?: boolean;
  IsIllness?: boolean;
  InternalReferenceId?: number;
  Identifier?: string;
  BaseTimeEntryType?: ITimeEntryType;
  TimeEntryTypeTranslations?: ITimeEntryTypeTranslation[];
  TimeEntryTypeConfigurations?: ITimeEntryTypeConfiguration[];

  setDefaultValue?(): void;
}

export interface ISalaryStatement {
  PayoutDateDerived?: Date;
  VacationProvider?: IVacationProvider;
  PensionProvider?: IPensionProvider;
  Pension2Provider?: IPensionProvider;
  IsFinalized: boolean;
  Description?: string;
  Id: number;
  SalaryBatchId: number;
  PeriodFrom: Date;
  PeriodTo: Date;
  PayoutAmount?: number;
  AtpEmployee: number;
  AtpEmployer: number;
  UserEmploymentId: number;
  TaxAmount: number;
  AmbAmount: number;
  PensionProviderId?: number;
  PensionEmployeeShare?: number;
  PensionEmployeeShareAmount: number;
  PensionCompanyShare?: number;
  PensionCompanyShareAmount: number;
  VacationProviderId?: number;
  VacationMoneyEarnedGross: number;
  VacationMoneyEarnedNet?: number;
  VacationDaysEarned: number;
  UserFullName?: string;
  UserIdentityNumber?: string;
  UserAddressId?: number;
  PensionBaseAmount: number;
  Pension2BaseAmount: number;
  Pension2ProviderId?: number;
  Pension2EmployeeShare?: number;
  Pension2CompanyShare?: number;
  Pension2EmployeeShareAmount: number;
  Pension2CompanyShareAmount: number;
  VacationDaysSpent: number;
  VacationBaseAmount: number;
  PrimaryIncomeAmount: number;
  HoursWorked: number;
  KmEntered: number;
  AppliedTaxDeduction: number;
  TaxCardRemainingFreeAmount?: number;
  TaxCardTypeId: number;
  TaxRate: number;
  VacationDaysSubtracted: number;
  TaxBaseAmount: number;
  AmbBaseAmount: number;
  VacationTaxDeduction?: number;
  TaxCardFreeAmountReduction?: number;
  ActualFromDate: Date;
  ActualEndDate: Date;
  IsTermination: boolean;
  TerminationTotalVacationMoneyGross?: number;
  TerminationTotalVacationMoneyNet?: number;
  TerminationYearBeforeLastVacationMoneyGross?: number;
  TerminationYearBeforeLastVacationMoneyNet?: number;
  TerminationYearBeforeLastVacationRemainingDays?: number;
  TerminationLastYearVacationMoneyGross?: number;
  TerminationLastYearVacationMoneyNet?: number;
  TerminationLastYearVacationRemainingDays?: number;
  TerminationCurrentYearVacationMoneyGross?: number;
  TerminationCurrentYearVacationMoneyNet?: number;
  TerminationCurrentYearVacationRemainingDays?: number;
  VacationTaxReduction: number;
  VacationAmbAmount: number;
  FFHoursEarned: number;
  FFHoursSpent: number;
  RecipientBankRegNo?: string;
  RecipientBankAccountNo?: string;
  RecipientBicSwiftNo?: string;
  RecipientIbanNo?: string;
  IllnessCompensationAtpAmountEmployee?: number;
  DepartmentId?: number;
  OvertimeHours: number;
  IllnessHours: number;
  TaxableIncome: number;
  VacationSupplementEarned?: number;
  SummaryAmountSalary?: number;
  SummaryAmountSupplements?: number;
  SummaryAmountTaxes?: number;
  SummaryAmountAdjustments?: number;
  FlexAmountEarned: number;
  FlexAmountReduced: number;
  SummaryAmountPension?: number;
  VacationAmbBaseAmount: number;
  TaxFreeIncome?: number;
  TravelCompensation: number;
  PensionCompanyTaxAmount?: number;
  PensionEmployeeTaxAmount?: number;
  ReportableNonTaxedIncome?: number;
  DownloadToken?: string;
  DownloadTokenExpiryTime?: Date;
  VacationPayoutEarnedLastYear?: number;
  VacationPayoutEarnedCurrentYear?: number;
  VacationBalancePeriodId?: number;
  VacationPayoutAmountGross?: number;
  VacationPayoutAmountNet?: number;
  AdditionalVacationDaysEarned?: number;
  AdditionalVacationDaysSpent?: number;
  AdditionalVacationEarnedGross?: number;
  AmExemptIncome: number;
  SalaryBatch?: ISalaryBatch;
  SalaryBatchRecords?: ISalaryBatchRecord[];
  UserEmployment?: IUserEmployment;
  BalanceAdjustments?: IBalanceAdjustment[];
  SalaryRecords?: ISalaryRecord[];

  setDefaultValue?(): void;
}

export interface IBillingPrinciple {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ITimeEntryTypeTranslation {
  Id: number;
  TimeEntryTypeId: number;
  LanguageId: number;
  Name?: string;
  Description?: string;
  DefaultPayslipText?: string;
  StatusId: number;
  Comment?: string;

  setDefaultValue?(): void;
}

export interface ISalaryBatch {
  Status?: ISalaryBatchStatus;
  IsDraft: boolean;
  IsLocked: boolean;
  IsFinalized: boolean;
  Id: number;
  CompanyId: number;
  SalaryPeriodId: number;
  Created: Date;
  StatusId: number;
  Approved?: Date;
  RejectionReason?: string;
  Rejected?: Date;
  EIncomeTransferDate?: Date;
  Message?: string;
  PayoutDate: Date;
  HasErrors: boolean;
  HasWarnings: boolean;
  NetsPaymentsProcessingDate?: Date;
  IsTermination: boolean;
  EIncomeZeroReport: boolean;
  SalaryBatchNumber: number;
  PaymentMethodSalary: number;
  PaymentMethodTax: number;
  PaymentMethodPension: number;
  EIncomeReruns: number;
  PayslipsArchived: boolean;
  LimitToSpecificEmployees: boolean;
  EBoksDeliveryEnabled: boolean;
  PayoutAllFlex: boolean;
  SpecifiedEmployees?: string;
  HasNemKontoPayments: boolean;
  PaymentMethodPublicPension: number;
  PaymentMethodVacationProvider: number;
  ToArchive: boolean;
  Finalized?: Date;
  AllowPreliminaryPayslips: boolean;
  PackageLevelWhenFinalized?: number;
  IsAutomated: boolean;
  PayoutNewFlex: boolean;
  ApprovedUserId?: number;
  CreatedUserId?: number;
  FinalizedUserId?: number;
  RejectedUserId?: number;
  FromDateOverride?: Date;
  ToDateOverride?: Date;
  FinalizationGuid?: string;
  SalaryStatements?: ISalaryStatement[];
  SalaryPeriod?: ISalaryPeriod;
  SalaryBatchRecords?: ISalaryBatchRecord[];
  SalaryBatchValidationIssues?: ISalaryBatchValidationIssue[];

  setDefaultValue?(): void;
}

export interface ISalaryBatchRecord {
  Id: number;
  SalaryTypeId: number;
  Amount: number;
  Units?: number;
  Description?: string;
  CompanyUserId: number;
  UnitTypeId?: number;
  UserEmploymentId: number;
  SalaryRecordId?: number;
  SalaryBatchId: number;
  AmountPerUnit?: number;
  BaseAmount?: number;
  SalaryStatementId?: number;
  IncludeInPayment: boolean;
  EIncomeCategory: number;
  EIncomeSubCategory: number;
  SummaryCategoryId?: number;
  PeriodFactor?: number;
  FromDate?: Date;
  ToDate?: Date;
  SortOrder: number;
  IsPrimaryIncome: boolean;
  TriggersAMB: boolean;
  TriggersIncomeTax: boolean;
  TriggersATP: boolean;
  EarnsHolidayCompensation: boolean;
  SpecialIdentifier?: string;
  IsInternal: boolean;
  BaseSalaryTypeId?: number;
  ExternalReferenceSalaryType?: string;
  ExternalReferenceEmployee?: string;
  ExternalReferenceDepartment?: string;
  InformationOnly: boolean;
  Dimension1Value?: string;
  Dimension2Value?: string;
  Dimension3Value?: string;
  SulinalFieldNumber?: string;
  IsReportableIncome: boolean;
  SkatteverketAgiFieldCode?: number;
  LegacyFlexEarningPercentage?: number;
  NewFlexEarningPercentage?: number;
  PensionPercentage?: number;
  DisplayDates: boolean;
  Dimension1ValueId?: number;
  Dimension2ValueId?: number;
  Dimension3ValueId?: number;

  setDefaultValue?(): void;
}

export interface IBalanceAdjustment {
  Id: number;
  UserEmploymentId: number;
  SalaryStatementId?: number;
  Created: Date;
  EffectPerDate: Date;
  IsSpending: boolean;
  Amount: number;
  Days: number;
  Hours: number;
  TaxAmount: number;
  NetAmount: number;
  IsDraft: boolean;
  BalancePeriodId?: number;
  BaseAmount?: number;
  BalanceDefinitionId: number;
  SecondaryAmount?: number;
  SecondaryDays?: number;
  TertiaryAmount: number;
  SpecialIdentifier?: string;
  BatchPayoutDate: Date;
  IsReverted: boolean;
  SalaryStatement?: ISalaryStatement;
  UserEmployment?: IUserEmployment;
  BalancePeriod?: IBalancePeriod;
  BalanceDefinition?: IBalanceDefinition;

  setDefaultValue?(): void;
}

export interface ISalaryBatchStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ISalaryPeriod {
  SalaryCycle?: ISalaryCycle;
  Id: number;
  StartDate: Date;
  EndDate: Date;
  SalaryCycleId: number;
  SuggestedPayoutDate?: Date;
  FriendlyName?: string;

  setDefaultValue?(): void;
}

export interface ISalaryBatchValidationIssue {
  Id: number;
  SalaryBatchId: number;
  UserEmploymentId?: number;
  IsError: boolean;
  Description?: string;
  EmployeeName?: string;
  MessageKey?: string;
  MessageParameters?: string;

  setDefaultValue?(): void;
}

export interface IBalancePeriod {
  Id: number;
  BalanceId: number;
  EarningStartDate: Date;
  EarningEndDate: Date;
  SpendingStartDate: Date;
  SpendingEndDate: Date;
  SpecialIdentifier?: string;
  BalanceDefinition?: IBalanceDefinition;
  Name?: string;

  setDefaultValue?(): void;
}

export interface IBalanceDefinition {
  Id: number;
  CompanyId?: number;
  CountryId: number;
  Name?: string;
  SpecialIdentifier?: string;
  IsConfigurable: boolean;
  AmountEnabled: boolean;
  TaxEnabled: boolean;
  SecondaryAmountEnabled: boolean;
  TertiaryAmountEnabled: boolean;
  DaysEnabled: boolean;
  SecondaryDaysEnabled: boolean;
  HoursEnabled: boolean;
  BaseAmountEnabled: boolean;
  HasPeriods: boolean;
  BalancePeriods?: IBalancePeriod[];

  setDefaultValue?(): void;
}

export interface ICompanyAccountView {
  Id: number;
  Name?: string;
  VATRegistrationNumber?: string;
  AccountId?: number;
  MainContactCompanyUserId?: number;
  MainContactFullName?: string;
  ContactEmail?: string;
  InvoiceEmail?: string;
  AccountComment?: string;
  CountryId: number;
  CountryCode?: string;
  EmployeeCount?: number;
  LastFinalized?: Date;
  LastFinalizedFullName?: string;
  NextDeadline?: Date;
  HasBatchesForApproval: boolean;
  InvoiceTypeId?: number;

  setDefaultValue?(): void;
}

export interface IAccountCompanyCreationRequest {
  VatRegistrationNumber?: string;
  SecondaryVatRegistrationNumber?: string;
  CompanyName?: string;
  PackageLevel: number;
  CopySalaryAndTimeEntryTypesFromCompanyId?: number;
  CopyTemplatesFromCompanyId?: number;
  DefaultRoleId?: number;

  setDefaultValue?(): void;
}

export interface IAccountCommentRequest {
  Comment?: string;

  setDefaultValue?(): void;
}

export interface IUserCompanyAccess {
  CompanyId: number;
  UserId: number;
  CompanyUserId?: number;
  FullName?: string;
  CompanyName?: string;
  RoleId: number;
  RoleName?: string;
  IsPaymentApprover: boolean;

  setDefaultValue?(): void;
}

export interface IUserAccessPermissionsRequest {
  UserId: number;
  CompanyId: number;
  RoleId: number;
  isPaymentApprover: boolean;

  setDefaultValue?(): void;
}

export interface IBatchOperationResponse_CompanyUser_UserAccessPermissionsRequest {
  ProcessedItems?: ICompanyUser[];
  Errors?: any;
  RejectedItems?: IRejectedItemContainer_CompanyUser_UserAccessPermissionsRequest[];
  HasErrors: boolean;

  setDefaultValue?(): void;
}

export interface IRejectedItemContainer_CompanyUser_UserAccessPermissionsRequest {
  Item?: IUserAccessPermissionsRequest;
  Reason?: string;

  setDefaultValue?(): void;
}

export interface IApiUser {
  SignOnToken?: ISignOnToken;
  RefreshToken: string;
  Id: number;
  IdentityNumber?: string;
  HasBackendAccess: boolean;
  ModuleIds?: number[];
  MustChangePassword: boolean;
  FullName?: string;
  AccountRoleId?: number;
  IsFirstLogin: boolean;
  ShowUpdateNotification: boolean;
  ShowUrgentNotification: boolean;
  CurrentSystemAlias?: ISystemAlias;
  CurrentSystemName?: string;
  CurrentSystemAliasIdentifier?: string;
  IsIntectSupportUser: boolean;
  IsAccountMember: boolean;
  IsAccountAdmin: boolean;
  IsCurrentAccountMember: boolean;
  IsCurrentAccountAdmin: boolean;
  CurrentCompany?: ICompany;
  ManagerForUserEmploymentIds?: number[];
  Identity?: IIIdentity;
  CurrentCompanyId: number;
  UserAccountId?: number;
  CompanyAccountId?: number;
  CurrentCountryId: number;
  CurrentRole?: IRole;
  CurrentCompanyUser?: ICompanyUser;
  Modules?: IModule[];
  IsPaymentApprover: boolean;

  setDefaultValue?(): void;
}

export interface ISignOnToken {
  Expires: Date;
  Language?: ILanguage;
  IsExpired: boolean;
  Id: number;
  UserId: number;
  Token?: string;
  Created: Date;
  LastRefreshed: Date;
  LastPersisted: Date;
  Type: number;
  Username?: string;
  CompanyId: number;
  LanguageId: number;
  SessionLength?: number;
  CountryId: number;
  SiteIdentifier?: string;
  CompanyAccountId?: number;
  UserAccountId?: number;
  SystemAliasId: number;

  setDefaultValue?(): void;
}

export interface ISystemAlias {
  ReportLogoName?: string;
  PayslipLogoName?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IIIdentity {
  Name?: string;
  AuthenticationType?: string;
  IsAuthenticated: boolean;

  setDefaultValue?(): void;
}

export interface IChangePasswordRequest {
  CurrentPassword?: string;
  NewPassword?: string;

  setDefaultValue?(): void;
}

export interface IOkResult {
  Request?: any;

  setDefaultValue?(): void;
}

export interface IValidatePasswordRequest {
  Password?: string;

  setDefaultValue?(): void;
}

export interface IResetPasswordRequest {
  IdentityNumber?: string;
  Email?: string;

  setDefaultValue?(): void;
}

export interface IBillingUsage {
  Id: number;
  AccountId?: number;
  ModuleId?: number;
  BillingDate?: Date;
  Units: number;
  AmountPerUnit: number;
  Amount: number;
  CompanyId?: number;
  SalaryBatchId?: number;
  SalaryStatementId?: number;
  PaymentId?: number;
  UserEmploymentId?: number;
  CompanyUserId?: number;
  Created: Date;
  PackageId?: number;
  BillingPrincipleId: number;
  Description?: string;
  InternalComment?: string;

  setDefaultValue?(): void;
}

export interface IBalancesRequest {
  BalanceDefinitionId: number;
  BalancePeriodId?: number;
  PerDate: Date;

  setDefaultValue?(): void;
}

export interface IGetBalancesEmployeeViewByDate_Result {
  UserEmploymentId: number;
  IdentityNumber?: string;
  FullName?: string;
  BalanceName?: string;
  SpecialIdentifier?: string;
  BalancePeriodId: number;
  PeriodIdentifier?: string;
  PeriodName?: string;
  EarningStartDate?: Date;
  EarningEndDate?: Date;
  SpendingStartDate?: Date;
  SpendingEndDate?: Date;
  BaseAmount?: number;
  AmountEarned?: number;
  AmountSpent?: number;
  AmountTotal?: number;
  TaxAmountTotal?: number;
  NetAmountTotal?: number;
  DaysEarned?: number;
  DaysSpent?: number;
  DaysTotal?: number;
  HoursEarned?: number;
  HoursSpent?: number;
  HoursTotal?: number;
  SecondaryDaysEarned?: number;
  SecondaryDaysSpent?: number;
  CurrentBalanceSecondaryDays?: number;
  SecondaryAmountTotal?: number;
  TertiaryAmountTotal?: number;
  ExternalReference?: string;
  DepartmentId?: number;
  DepartmentName?: string;
  DepartmentExternalReference?: string;
  BasedOnTemplateId?: number;
  BasedOnTemplateName?: string;
  VacationTypeId: number;

  setDefaultValue?(): void;
}

export interface IBalanceAdjustmentRequest {
  BalanceDefinitionId: number;
  BalancePeriodId?: number;
  PerDate: Date;
  Details?: IBalanceAdjustmentRequestDetail[];

  setDefaultValue?(): void;
}

export interface IBalanceAdjustmentRequestDetail {
  UserEmploymentId: number;
  AdjustDays: number;
  SpendDays: number;
  MoveDays: number;
  PayoutDays: number;
  AdjustHours: number;
  SpendHours: number;
  PayoutHours: number;

  setDefaultValue?(): void;
}

export interface ISimpleCompany {
  Id: number;
  Name?: string;
  VatRegistrationNumber?: string;
  SecondaryVatRegistrationNumber?: string;
  RoleId: number;
  AccountId?: number;

  setDefaultValue?(): void;
}

export interface ICompanyCreationRequest {
  VatRegistrationNumber?: string;
  SecondaryVatRegistrationNumber?: string;
  CompanyName?: string;
  ResponsibleUserUsername?: string;
  ResponsibleUserIdentityNumber?: string;
  ResponsibleUserFullName?: string;
  ResponsibleUserEmail?: string;
  PhoneNumber?: string;
  LanguageId: number;
  KnowsIntectFrom?: string;
  NewUserPassword?: string;
  SubscribeToNewsLetter: boolean;
  AcceptStartupOffer: boolean;
  PackageLevel: number;
  AccountId?: number;
  NewAccountName?: string;
  AccountTypeId?: number;
  RequestingUserId?: number;

  setDefaultValue?(): void;
}

export interface IVatCompany {
  VAT?: string;
  Name?: string;
  Address?: string;
  Zipcode?: string;
  City?: string;
  protected?: boolean;
  Phone?: string;
  Email?: string;
  Fax?: string;
  Startdate?: string;
  Enddate?: string;
  Employees?: string;
  Addressco?: string;
  Industrycode?: number;
  Industrydesc?: string;
  Companycode?: number;
  Companydesc?: string;
  Creditstartdate?: string;
  Creditstatus?: number;
  Creditbankrupt?: boolean;
  Owners?: IApiOwners[];
  Productionunits?: any[];
  T?: number;
  Version?: number;
  ExistsInIntect: boolean;
  CountryId?: number;

  setDefaultValue?(): void;
}

export interface IApiOwners {
  Name?: string;

  setDefaultValue?(): void;
}

export interface IAcceptTermsRequest {
  Password?: string;

  setDefaultValue?(): void;
}

export interface IAddUserToCompanyRequest {
  IdentityNumber?: string;
  LanguageId?: number;
  Details?: ICompanyUser;
  EmploymentTemplateId?: number;
  HireDate?: Date;
  Title?: string;
  ExternalReference?: string;
  SecondaryExternalReference?: string;
  DepartmentId?: number;
  ExpireDate?: Date;
  PensionProviderId?: number;
  PensionEmployeeSharePercentage?: number;
  PensionEmployeeFixedAmount?: number;
  PensionCompanySharePercentage?: number;
  PensionCompanyFixedAmount?: number;
  PensionPolicyNumber?: string;
  PensionProviderBankRegNo?: string;
  PensionProviderBankAccountNo?: string;
  ManagerCompanyUserId?: number;
  SeniorityDate?: Date;
  IsAccountUser: boolean;
  DefaultRate?: number;
  TaxCardTypeId?: number;
  IncomeTypeId?: number;
  UseContinuousVacationReporting: boolean;
  TaxRate?: number;
  TaxDeduction?: number;
  TaxCardFreeAmount?: number;
  ApplyDanishRulesInGreenland: boolean;
  TaxTableNumber?: number;
  TaxColumnNumber?: number;

  setDefaultValue?(): void;
}

export interface IUserCreationData {
  User?: IUser;
  CompanyUser?: ICompanyUser;
  Employment?: IUserEmployment;
  NewUserPassword?: string;

  setDefaultValue?(): void;
}

export interface ICompanyUserListRequest {
  IncludeInactive: boolean;
  IncludeHidden: boolean;
  SortBySurname: boolean;
  LimitToDepartmentId?: number;
  LimitToTemplateId?: number;
  LimitToSalaryCycleId?: number;
  LimitToPayrollBatchNumber?: number;
  LimitToUpdatedSince?: Date;

  setDefaultValue?(): void;
}

export interface ISimpleCompanyUser {
  Id: number;
  IsActive: boolean;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  FullName?: string;
  RoleId: number;
  UserId: number;
  IdentityNumber?: string;
  ExternalReferences?: string[];
  UserEmploymentIds?: number[];
  PersonalEmail?: string;
  CompanyEmail?: string;

  setDefaultValue?(): void;
}

export interface IBatchOperationResponse_UserCreationData_AddUserToCompanyRequest {
  ProcessedItems?: IUserCreationData[];
  Errors?: any;
  RejectedItems?: IRejectedItemContainer_UserCreationData_AddUserToCompanyRequest[];
  HasErrors: boolean;

  setDefaultValue?(): void;
}

export interface IRejectedItemContainer_UserCreationData_AddUserToCompanyRequest {
  Item?: IAddUserToCompanyRequest;
  Reason?: string;

  setDefaultValue?(): void;
}

export interface IBatchOperationResponse_CompanyUser_CompanyUser {
  ProcessedItems?: ICompanyUser[];
  Errors?: any;
  RejectedItems?: IRejectedItemContainer_CompanyUser_CompanyUser[];
  HasErrors: boolean;

  setDefaultValue?(): void;
}

export interface IRejectedItemContainer_CompanyUser_CompanyUser {
  Item?: ICompanyUser;
  Reason?: string;

  setDefaultValue?(): void;
}

export interface IUserEmploymentView {
  IdentityNumberFormatted?: string;
  HasDummyIdentityNumber: boolean;
  IsFemale: boolean;
  HasWorkHoursPerWeekDay: boolean;
  SalaryCycle?: ISalaryCycle;
  Updated: Date;
  UserEmploymentId: number;
  IdentityNumber?: string;
  CompanyUserId: number;
  CompanyId: number;
  RoleId: number;
  IsActive: boolean;
  FullName?: string;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  TelMobile?: string;
  TelFixed?: string;
  BankRegNo?: string;
  CompanyEmail?: string;
  PersonalEmail?: string;
  BankAccountNo?: string;
  BicSwiftNo?: string;
  IbanNo?: string;
  IsPaymentApprover: boolean;
  Initials?: string;
  HireDate: Date;
  ExpireDate?: Date;
  VacationProviderId?: number;
  VacationExtraPercentage?: number;
  BasedOnTemplateId?: number;
  Title?: string;
  SalaryCycleId: number;
  ExternalReference?: string;
  PensionBaseCalculationMethodId: number;
  IsAtpTempWorker: boolean;
  DepartmentId?: number;
  IncomeTypeId: number;
  WorksElsewhere: boolean;
  PensionEmployeeShare?: number;
  PensionCompanyShare?: number;
  PensionProviderId?: number;
  PensionProviderBankRegNo?: string;
  PensionProviderBankAccountNo?: string;
  PensionPolicyNumber?: string;
  PensionEmployeeAmount?: number;
  PensionCompanyAmount?: number;
  Pension2BaseCalculationMethodId: number;
  Pension2ProviderId?: number;
  Pension2ProviderBankRegNo?: string;
  Pension2ProviderBankAccountNo?: string;
  Pension2EmployeeShare?: number;
  Pension2CompanyShare?: number;
  Pension2EmployeeAmount?: number;
  Pension2CompanyAmount?: number;
  VacationTypeId: number;
  LastStatementEndDate?: Date;
  VacationReduceSalary: boolean;
  IsTerminated: boolean;
  VacationSupplementPayoutMonth: number;
  AddressId: number;
  AddressLine1?: string;
  AddressLine2?: string;
  PostalCode?: string;
  City?: string;
  CountryId?: number;
  TaxCardRequested?: Date;
  UserId: number;
  Username?: string;
  DefaultCompanyId?: number;
  LanguageId: number;
  CompanyName?: string;
  IllnessReducesSalary: boolean;
  EmploymentCreated: Date;
  CompanyUserCreated: Date;
  UserCreated: Date;
  DepartmentName?: string;
  DepartmentExternalReference?: string;
  DateOfBirth?: Date;
  IsPendingRecalc: boolean;
  ManagerCompanyUserId?: number;
  ManagerName?: string;
  LanguageCode?: string;
  BasedOnTemplateName?: string;
  CountryCode?: string;
  PreferredTaxCardTypeId: number;
  SeniorityDate?: Date;
  AutoDetermineBankAccount: boolean;
  CompanyCountryId: number;
  DefaultRate?: number;
  MunicipalityId?: number;
  CompanyAccountId?: number;
  UserAccountId?: number;
  UseContinuousVacationReporting: boolean;
  TaxTableNumber?: number;
  TaxColumnNumber?: number;
  AdHocTaxRateOverride?: number;
  VacationDaysPerYear?: number;
  VacationTerminationDate?: Date;
  CompanyUserUpdated?: Date;
  UserEmploymentUpdated?: Date;
  LastUpdated?: Date;
  StandardHoursPerWeek?: number;
  StandardWorkDaysPerWeek?: number;
  OmitVacationReporting: boolean;
  PensionIsNotTaxDeductible: boolean;
  Pension2IsNotTaxDeductible: boolean;
  AllowExtendedTaxCardDeductions: boolean;
  PensionStartDate?: Date;
  PensionEndDate?: Date;
  Pension2StartDate?: Date;
  Pension2EndDate?: Date;
  PensionFixedAmountIsPerDay: boolean;
  Pension2FixedAmountIsPerDay: boolean;
  AdvanceVacationDays?: number;
  WorkplaceIdentifier?: string;
  AdditionalVacationSpentFirst: boolean;
  AdditionalVacationTerminationMethod?: number;
  AtpHours?: number;
  FlexEarningPercentage: number;
  FlexBaseCalculationMethodId: number;
  StandardWorkHoursMonday?: number;
  StandardWorkHoursTuesday?: number;
  StandardWorkHoursWednesday?: number;
  StandardWorkHoursThursday?: number;
  StandardWorkHoursFriday?: number;
  StandardWorkHoursSaturday?: number;
  StandardWorkHoursSunday?: number;

  setDefaultValue?(): void;
}

export interface IBatchOperationResponse_UserEmployment_UserEmployment {
  ProcessedItems?: IUserEmployment[];
  Errors?: any;
  RejectedItems?: IRejectedItemContainer_UserEmployment_UserEmployment[];
  HasErrors: boolean;

  setDefaultValue?(): void;
}

export interface IRejectedItemContainer_UserEmployment_UserEmployment {
  Item?: IUserEmployment;
  Reason?: string;

  setDefaultValue?(): void;
}

export interface IEmploymentsListRequest {
  IncludeInactive: boolean;
  SortBySurname: boolean;
  LimitToDepartmentId?: number;
  LimitToTemplateId?: number;
  LimitToSalaryCycleId?: number;
  LimitToPayrollBatchNumber?: number;
  LimitToUpdatedSince?: Date;

  setDefaultValue?(): void;
}

export interface ITemplateCreationRequest {
  Name?: string;
  BasedOnEmploymentId: number;

  setDefaultValue?(): void;
}

export interface ICompanyHolidayView {
  CompanyId: number;
  CompanyName?: string;
  CountryId: number;
  CompanySpecificHolidayId?: number;
  MasterHolidayId?: number;
  Date: Date;
  ConsiderHoliday?: boolean;
  DisablesBankPayments: boolean;
  Description?: string;
  IsOverridden?: boolean;

  setDefaultValue?(): void;
}

export interface IHolidayRequest {
  Date: Date;
  Description?: string;
  YearsToRepeat: number;

  setDefaultValue?(): void;
}

export interface IPreviewRequest {
  Size: number;
  FileBase64?: string;
  FileBytes?: string;
  MappingId?: number;
  Mapping?: IImportMapping;

  setDefaultValue?(): void;
}

export interface IImportMapping {
  Id: number;
  CompanyId?: number;
  Name?: string;
  StartRow: number;
  CountryId?: number;
  TypeId: number;
  ImportMappingColumns?: IImportMappingColumn[];

  setDefaultValue?(): void;
}

export interface IImportMappingColumn {
  Id: number;
  ImportMappingId: number;
  ColumnKey?: string;
  UserColumnNumber: number;
  SalaryTypeId?: number;
  BackendKey?: string;

  setDefaultValue?(): void;
}

export interface IImportHeadersRequest {
  StartRow: number;
  FileBase64?: string;
  FileBytes?: string;

  setDefaultValue?(): void;
}

export interface IUserFileColumn {
  UserColumnNumber: number;
  Name?: string;

  setDefaultValue?(): void;
}

export interface IImportRequest {
  Options?: ISimpleKeyValuePair[];
  FileBase64?: string;
  FileBytes?: string;
  MappingId?: number;
  Mapping?: IImportMapping;

  setDefaultValue?(): void;
}

export interface ISimpleKeyValuePair {
  Key?: string;
  Value?: string;

  setDefaultValue?(): void;
}

export interface IImportResponse {
  Outcome?: string;
  Errors?: IImportErrorMessage[];
  FatalMessage?: string;

  setDefaultValue?(): void;
}

export interface IImportErrorMessage {
  TranslationEntity?: ITranslationEntity;
  Error: boolean;
  RowNumber: number;
  Message?: string;

  setDefaultValue?(): void;
}

export interface ITranslationEntity {
  Type: number;
  Key?: string;
  Extra?: string[];

  setDefaultValue?(): void;
}

export interface IImportMappingFileRequest {
  FileBase64?: string;
  FileBytes?: string;
  MappingId?: number;
  Mapping?: IImportMapping;

  setDefaultValue?(): void;
}

export interface ITemplateRequest {
  CountryId: number;
  Parameters?: any[];
  IsPrefilled: boolean;
  MappingId?: number;
  Mapping?: IImportMapping;

  setDefaultValue?(): void;
}

export interface IDimension {
  ExternalSystemPropertyName?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface INameObject {
  DisplayName?: string;
  Value?: string;

  setDefaultValue?(): void;
}

export interface IWorkBookDimension {
  ExternalSystemPropertyName?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IFeedback {
  Message?: string;

  setDefaultValue?(): void;
}

export interface IStatisticsDiscoCode {
  Id: number;
  Code: number;
  Name?: string;

  setDefaultValue?(): void;
}

export interface INameInformation {
  Name?: string;
  Id: number;

  setDefaultValue?(): void;
}

export interface IModuleCompanyView {
  CompanyId: number;
  CompanyName?: string;
  ModuleId: number;
  PackageId?: number;
  ModuleName?: string;
  ModuleDescription?: string;
  PackageName?: string;
  PackageDescription?: string;
  ModulePrice?: number;
  ModuleBillingPrinciple?: string;
  ModuleBillingPrincipleId?: number;
  PackagePrice?: number;
  PackageBillingPrinciple?: string;
  PackageBillingPrincipleId?: number;
  IsEnabled?: boolean;
  LanguageId: number;
  InfoOnly: boolean;
  FreeUntilDate?: Date;
  ScheduledDeactivationDate?: Date;
  CompanyCurrentPackageLevel: number;
  SortKey?: string;
  ModuleKey?: string;
  PackageKey?: string;
  ModulePriceBeforeOverride?: number;
  IsOverridden?: boolean;

  setDefaultValue?(): void;
}

export interface IReport {
  AreaId: number;
  Parameters?: string;
  SupportedFormats?: string[];
  ExcludeColumns?: string[];
  ExcludeColumnsPdf?: string[];
  PortraitOrientation: boolean;
  HaveSums: boolean;
  RelevantCountries?: ICountry[];
  RequiredRoleId: number;
  BackendKey?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IReportRequest {
  ReportId: number;
  ParameterValues?: IReportParameterValue[];
  OutputFormat?: string;

  setDefaultValue?(): void;
}

export interface IReportParameterValue {
  Key?: string;
  Value?: string;

  setDefaultValue?(): void;
}

export interface ISalaryBatchView {
  Id: number;
  SalaryBatchNumber: number;
  CompanyId: number;
  SalaryPeriodId: number;
  Created: Date;
  StatusId: number;
  Approved?: Date;
  RejectionReason?: string;
  Rejected?: Date;
  EIncomeTransferDate?: Date;
  Message?: string;
  PayoutDate: Date;
  ApprovedUserId?: number;
  RejectedUserId?: number;
  CreatedUserId?: number;
  HasErrors: boolean;
  HasWarnings: boolean;
  ValidationStatusCode?: string;
  NetsPaymentsProcessingDate?: Date;
  IsTermination: boolean;
  EIncomeZeroReport: boolean;
  PaymentMethodSalary: number;
  PaymentMethodTax: number;
  PaymentMethodPension: number;
  EIncomeReruns: number;
  PayslipsArchived: boolean;
  LimitToSpecificEmployees: boolean;
  CreatedByUserFullName?: string;
  ApprovedByUserFullName?: string;
  RejectedByUserFullName?: string;
  FinalizedByUserFullName?: string;
  PeriodStartDate: Date;
  PeriodEndDate: Date;
  SalaryCycleId: number;
  StatusName?: string;
  LanguageId: number;
  PayoutAllFlex: boolean;
  PayoutNewFlex: boolean;
  Description?: string;
  IsImport: boolean;
  PaymentMethodPublicPension: number;
  PaymentMethodVacationProvider: number;
  AllowPreliminaryPayslips: boolean;
  EBoksDeliveryEnabled: boolean;
  HasNemKontoPayments: boolean;
  IsAutomated: boolean;
  Finalized?: Date;
  ToArchive: boolean;
  FromDateOverride?: Date;
  ToDateOverride?: Date;

  setDefaultValue?(): void;
}

export interface ISalaryBatchRequest {
  SalaryCycleId?: number;
  SalaryPeriodId?: number;
  UserEmploymentIds?: number[];
  Preview: boolean;
  EIncomeZeroReport: boolean;
  PayoutDate?: Date;
  Message?: string;
  PayoutAllFlex: boolean;
  PayoutNewFlex: boolean;
  FromDateOverride?: Date;
  ToDateOverride?: Date;

  setDefaultValue?(): void;
}

export interface IEIncomeResponseLine {
  Id: number;
  SalaryBatchId: number;
  IsError: boolean;
  RefLineNo: number;
  RefFieldNo: number;
  ResponseCode: number;
  ResponseText?: string;
  CprOrSeNo?: string;
  ReceivedOn: Date;
  ExportBatchIdentifier?: string;
  RerunNumber: number;
  SalaryBatch?: ISalaryBatch;

  setDefaultValue?(): void;
}

export interface ISalaryBatchTotalsApiView {
  SalaryBatchId: number;
  SalaryTypeId: number;
  AmountSum?: number;
  LanguageId: number;
  SortOrder: number;
  Description?: string;
  SalaryBatchNumber: number;

  setDefaultValue?(): void;
}

export interface ISalaryTypeSumsByEmploymentView {
  LanguageId: number;
  SalaryBatchId: number;
  SalaryTypeId: number;
  SalaryTypeName?: string;
  UserEmploymentId: number;
  FullName?: string;
  AmountSum?: number;

  setDefaultValue?(): void;
}

export interface ISalaryBatchUnitTotal {
  Name?: string;
  Key?: string;
  Amount: number;

  setDefaultValue?(): void;
}

export interface ISalaryBatchEmployeeTotal {
  UserEmploymentId: number;
  FullName?: string;
  AmountSum: number;

  setDefaultValue?(): void;
}

export interface IProtectedOperationRequest {
  Password?: string;

  setDefaultValue?(): void;
}

export interface ISalaryBatchPrepareFinalizationRequest {
  FinalizationGuid: string;

  setDefaultValue?(): void;
}

export interface ISalaryBatchRejectionRequest {
  Reason?: string;

  setDefaultValue?(): void;
}

export interface IExternalAccountingRecord {
  Amount: number;
  AccountNumber?: string;
  EntryDate: Date;
  Description?: string;
  SalaryTypeId: number;
  SalaryTypeName?: string;
  DepartmentName?: string;
  DepartmentExternalReference?: string;
  Dimension1Value?: string;
  Dimension2Value?: string;
  Dimension3Value?: string;
  EmployeeName?: string;
  EmployeeExternalReference?: string;

  setDefaultValue?(): void;
}

export interface ISalaryRecordView {
  Id: number;
  CompanyUserId: number;
  CompanyId: number;
  SalaryTypeId: number;
  AmountPerUnit?: number;
  Units?: number;
  IsActive: boolean;
  StartDate?: Date;
  EndDate?: Date;
  Description?: string;
  UserEmploymentId: number;
  UnitTypeId?: number;
  BaseAmount?: number;
  Amount?: number;
  SortOrder: number;
  PersistenceTypeId: number;
  Dimension1Value?: string;
  Dimension2Value?: string;
  Dimension3Value?: string;
  SalaryStatementId?: number;
  EmployeeName?: string;
  CompanyName?: string;
  IdentityNumber?: string;
  Dimension1ValueId?: number;
  Dimension2ValueId?: number;
  Dimension3ValueId?: number;

  setDefaultValue?(): void;
}

export interface IBatchOperationResponse_SalaryRecord_SalaryRecord {
  ProcessedItems?: ISalaryRecord[];
  Errors?: any;
  RejectedItems?: IRejectedItemContainer_SalaryRecord_SalaryRecord[];
  HasErrors: boolean;

  setDefaultValue?(): void;
}

export interface IRejectedItemContainer_SalaryRecord_SalaryRecord {
  Item?: ISalaryRecord;
  Reason?: string;

  setDefaultValue?(): void;
}

export interface ISimpleSalaryRecordUpdateRequest {
  Id: number;
  Amount: number;
  Units?: number;
  AmountPerUnit?: number;

  setDefaultValue?(): void;
}

export interface ISimpleSalaryRecordCreationRequest {
  UserEmploymentId: number;
  SalaryTypeId: number;
  Amount?: number;
  Units?: number;
  AmountPerUnit?: number;

  setDefaultValue?(): void;
}

export interface IGetSalaryRecordsOverview_Result {
  UserEmploymentId: number;
  FullName?: string;
  DepartmentId?: number;
  DepartmentName?: string;
  SalaryCycleId: number;
  SalaryRecord1Id?: number;
  MultipleRecordsExist1?: boolean;
  SalaryRecord1Units?: number;
  SalaryRecord1PerUnit?: number;
  SalaryRecord1Amount?: number;
  SalaryRecord2Id?: number;
  MultipleRecordsExist2?: boolean;
  SalaryRecord2Units?: number;
  SalaryRecord2PerUnit?: number;
  SalaryRecord2Amount?: number;
  SalaryRecord3Id?: number;
  MultipleRecordsExist3?: boolean;
  SalaryRecord3Units?: number;
  SalaryRecord3PerUnit?: number;
  SalaryRecord3Amount?: number;
  SalaryRecord4Id?: number;
  MultipleRecordsExist4?: boolean;
  SalaryRecord4Units?: number;
  SalaryRecord4PerUnit?: number;
  SalaryRecord4Amount?: number;
  SalaryRecord5Id?: number;
  MultipleRecordsExist5?: boolean;
  SalaryRecord5Units?: number;
  SalaryRecord5PerUnit?: number;
  SalaryRecord5Amount?: number;
  SalaryRecord6Id?: number;
  MultipleRecordsExist6?: boolean;
  SalaryRecord6Units?: number;
  SalaryRecord6PerUnit?: number;
  SalaryRecord6Amount?: number;
  SalaryRecord7Id?: number;
  MultipleRecordsExist7?: boolean;
  SalaryRecord7Units?: number;
  SalaryRecord7PerUnit?: number;
  SalaryRecord7Amount?: number;

  setDefaultValue?(): void;
}

export interface ISalaryRecordsOverviewFilterRequest {
  EmploymentTemplateId?: number;
  SalaryCycleId?: number;
  DepartmentId?: number;
  IncludeEmployeesWithNoData: boolean;

  setDefaultValue?(): void;
}

export interface ISimpleSalaryStatement {
  Id: number;
  UserEmploymentId: number;
  UserFullName?: string;
  PayoutAmount: number;
  UserIdentityNumber?: string;
  UserExternalReference?: string;

  setDefaultValue?(): void;
}

export interface ISimpleSalaryBatchRecord {
  Id: number;
  SalaryTypeId: number;
  Description?: string;
  Amount: number;

  setDefaultValue?(): void;
}

export interface ISalaryType {
  SummaryCategory?: ISalarySummaryCategory;
  Id: number;
  CompanyId?: number;
  CategoryId?: number;
  Name?: string;
  Description?: string;
  IsVisible?: boolean;
  IsActive?: boolean;
  SalaryTypeGroupId?: number;
  UnitTypeId?: number;
  AmountPerUnit?: number;
  Units?: number;
  BaseAmount?: number;
  Amount?: number;
  ExternalReference?: string;
  BaseSalaryTypeId?: number;
  EIncomeCategory?: number;
  EIncomeSubCategory?: number;
  SummaryCategoryId?: number;
  SortOrder?: number;
  DefaultPayslipText?: string;
  AllowEditUnitType?: boolean;
  AllowEditBaseAmount?: boolean;
  NegativeDefault?: boolean;
  DefaultPersistanceTypeId?: number;
  SumFromIds?: string;
  SumFromReverseSign?: boolean;
  IncludeAlways?: boolean;
  StatisticsOnly?: boolean;
  StatisticsFieldCode?: string;
  RelatesToIncomeType5?: boolean;
  Explanation?: string;
  AccountTypeId?: number;
  Identifier?: string;
  TriggersOvertimeSupplement?: boolean;
  SulinalFieldNumber?: string;
  IsAdvanced: boolean;
  OverviewIndex?: number;
  OverviewDisplayMode?: number;
  InternalReferenceId?: number;
  ImportExternalReference?: string;
  BalanceDefinitionId?: number;
  BalanceBehavior?: number;
  SkatteverketAgiFieldCode?: number;
  DimensionDistributionOptionId?: number;
  DisplayDates?: boolean;
  SalaryTypeTranslations?: ISalaryTypeTranslation[];
  BaseSalaryType?: ISalaryType;
  ImportMappingColumns?: IImportMappingColumn[];
  BalanceDefinition?: IBalanceDefinition;

  setDefaultValue?(): void;
}

export interface ISalarySummaryCategory {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ISalaryTypeTranslation {
  Id: number;
  SalaryTypeId: number;
  LanguageId: number;
  Name?: string;
  Description?: string;
  DefaultPayslipText?: string;
  StatusId: number;
  Comment?: string;
  Explanation?: string;

  setDefaultValue?(): void;
}

export interface ISalaryTypeCategoryView {
  StaticDataId: number;
  Id: number;
  LanguageId: number;
  Key?: string;
  Name?: string;
  Description?: string;
  IsPrimaryIncome: boolean;
  TriggersAMB: boolean;
  IncludeInPayment: boolean;
  TriggersIncomeTax: boolean;
  TriggersATP: boolean;
  EarnsHolidayCompensation: boolean;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IInheritSalaryTypeRequest {
  BaseSalaryTypeId: number;
  Name?: string;

  setDefaultValue?(): void;
}

export interface ISimpleSalaryTypeUpdateRequest {
  Id: number;
  ExternalReference?: string;
  ImportExternalReference?: string;

  setDefaultValue?(): void;
}

export interface ISalaryTypeGroup {
  Id: number;
  Name?: string;
  CompanyId: number;
  ExternalReference?: string;

  setDefaultValue?(): void;
}

export interface IDepartmentSalaryTypeMapping {
  Id: number;
  DepartmentId: number;
  SalaryTypeId: number;
  ExternalReference?: string;
  Department?: IDepartment;
  SalaryType?: ISalaryType;

  setDefaultValue?(): void;
}

export interface IStartupTaskCompanyView {
  Id: number;
  TaskId: number;
  Key?: string;
  SortOrder: number;
  CompanyId: number;
  CompanyName?: string;
  AppUri?: string;
  IsSkippable: boolean;
  LanguageId: number;
  Name?: string;
  Description?: string;
  GuideMessage?: string;
  ExternalLink?: string;
  SkipWarning?: string;
  StatusId: number;
  HelpLink?: string;

  setDefaultValue?(): void;
}

export interface IStaticData {
  Id: number;
  TypeKey?: string;
  ValueKey?: string;
  ValueId: number;
  Name?: string;
  Description?: string;
  Int1?: number;
  Int2?: number;
  Text1?: string;
  Text2?: string;
  Date1?: Date;
  Date2?: Date;
  Bool1: boolean;
  Bool2: boolean;
  Decimal1?: number;
  Decimal2?: number;
  Long1?: number;
  Long2?: number;
  SortKey?: string;
  Text3?: string;
  Text4?: string;
  Text5?: string;
  Text6?: string;
  StaticDataTranslations?: IStaticDataTranslation[];

  setDefaultValue?(): void;
}

export interface IStaticDataTranslation {
  Id: number;
  StaticDataId: number;
  LanguageId: number;
  Name?: string;
  Description?: string;
  Text1?: string;
  Text2?: string;
  StatusId: number;
  Comment?: string;
  Text3?: string;
  Text4?: string;
  Text5?: string;
  Language?: ILanguage;
  StaticData?: IStaticData;

  setDefaultValue?(): void;
}

export interface IStaticDataEntity {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IAdditionalVacationTerminationMethod {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IAtpAgreement {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IAtpRate {
  SalaryCycleId: number;
  MinHours: number;
  MaxHours: number;
  EmployeeAmount: number;
  CompanyAmount: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IAuditLogOperation {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IBank {
  PaymentExportFormatId: number;
  CountryId: number;
  PaymentExportFormat?: IPaymentExportFormat;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IPaymentExportFormat {
  FileFormat?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IBankHoliday {
  Date: Date;
  CountryId: number;
  BankHolidayTypeId: number;
  BankHolidayType?: IBankHolidayType;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IBankHolidayType {
  DisablesBankPayments: boolean;
  IsHoliday: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ICity {
  CountryId: number;
  PostalCode: number;
  IsRange: boolean;
  PostalCodeTo?: number;
  MunicipalityId?: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ICompanyPreference {
  RequiredModuleId: number;
  RequiredModule?: IModule;
  RelevantCountries?: ICountry[];
  Default?: string;
  ListDataSource?: string;
  HelpUrl?: string;
  UiControlType?: string;
  CategoryId?: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ICompanyPreferenceCategory {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ICurrency {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IDataType {
  IsNumeric: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IEconomicDepartmentSelector {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IEIncomeGroupByParameter {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IEmailTemplate {
  Subject?: string;
  PreferPersonalEmail: number;
  GreetingType: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IEntityType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IExternalSystem {
  HasSalaryTypeMappings: boolean;
  IsActive: boolean;
  ConfigurationKeys?: string[];
  CredentialKeys?: string[];
  SupportUrl?: string;
  OperationsFlag: number;
  Operations?: IExternalSystemOperation[];
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IExternalSystemOperation {
  IsSingular: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IExternalSystemConfiguration {
  FormElement?: string;
  IsRequired: boolean;
  ListDataSource?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IExtraVacationHoursEarningBehavior {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IFinanceAccountType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IImportConversion {
  ImportTypeKey?: string;
  ImportType?: IImportType;
  ApiRoute?: string;
  ApiMethodName?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IImportType {
  ImportOptionKeys?: string[];
  ImportMappingLimitationKey?: string;
  HasFilledTemplates: boolean;
  RelatedOptions?: IImportOption[];
  ImportMappingLimitation?: IImportMappingLimitation;
  ImportColumns?: IImportColumn[];
  ImportColumnKeys?: string[];
  CountryColumnsKeys?: any;
  CountryColumns?: any;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IImportOption {
  Values?: IImportOptionValue[];
  Default?: IImportOptionValue;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IImportMappingLimitation {
  Operation?: string;
  Parameter1?: IImportMappingLimitationParameter;
  Parameter2?: IImportMappingLimitationParameter;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IImportColumn {
  DataTypeName?: string;
  RelevantCountries?: ICountry[];
  ExampleBool: boolean;
  ExampleDecimal?: number;
  ExampleDate?: Date;
  ExampleInt?: number;
  ExampleString?: string;
  MinimumSize?: number;
  AllowsSalaryType: boolean;
  ApplicableForCompanyKey?: string;
  BackendKey?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IImportOptionValue {
  IsDefault: boolean;
  OptionKey?: string;
  WarningText?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IImportMappingLimitationParameter {
  Type?: string;
  Key?: string;

  setDefaultValue?(): void;
}

export interface IImportList {
  Operations?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IImportTemplateParameter {
  UiControlType?: string;
  ListDataSource?: string;
  DefaultValue?: string;
  AvailableForTemplate: boolean;
  AvailableForPrefilled: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IImportTemplateParameterAssignment {
  ImportTypeKey?: string;
  ImportType?: IImportType;
  ImportTemplateParameterKey?: string;
  ImportTemplateParameter?: IImportTemplateParameter;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IIncomeType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IIndustryOrganization {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IIntegrationStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IIntervalType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IInventioItDimension {
  ExternalSystemPropertyName?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IModulePackage {
  Price: number;
  BillingPrincipleId: number;
  BillingPrinciple?: IBillingPrinciple;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IMonth {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IMunicipality {
  CountryId: number;
  DefaultTaxRate?: number;
  OfficialReference?: string;
  OfficialReferenceNumber: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IOvertimeSupplementPrinciple {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IPaymentMethod {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IPaymentProvider {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IPaymentStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IPaymentType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IPreference {
  Default?: string;
  ListDataSource?: string;
  HelpUrl?: string;
  UiControlType?: string;
  CategoryId?: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IReimbursementStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IReimbursementType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IReportArea {
  AppUri?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IReportParameter {
  UiControlType?: string;
  ListDataSource?: string;
  DependsOn?: string[];
  DefaultValue?: string;
  IsRequired: boolean;
  IsHidden: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ISalaryRecordsOverviewDisplayMode {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ISalaryTypeCategory {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ISalaryTypeDimensionDistributionOption {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ISalaryTypeTotalsMultipleBatchesGroupByParameter {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ISeTaxTable {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ISignupReferral {
  AdditionalInfoRelevant: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ISkatteverketAgiFieldCode {
  IsGrouped: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ISortingOption {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IStatisticsEmploymentPrinciple {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IStatisticsEmploymentTerms {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IStatisticsJobStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IStatisticsRecipient {
  Code?: number;
  IsSupported: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IStatisticsSalaryPrinciple {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ITermExplanation {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ITimeEntryRecordAcrossSalaryPeriodStrategy {
  IsDefault: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface ITimeEntryStatusExtended {
  TimeEntryStatusString?: string;
  TimeEntryStatusIds?: number[];
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IUnicontaDimension {
  ExternalSystemPropertyName?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IUserPreference {
  MinimumRoleId?: number;
  Default?: string;
  ListDataSource?: string;
  HelpUrl?: string;
  UiControlType?: string;
  CategoryId?: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IVacationPeriod {
  CountryId: number;
  SpendingStartDate: Date;
  SpendingEndDate: Date;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IVacationSupplementPayoutMethodDK {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IVacationTypeSE {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IWorkBookVersion {
  VersionNumber: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void;
}

export interface IRejectionInfo {
  Reason?: string;

  setDefaultValue?(): void;
}

export interface ITimeEntryTypeView {
  TimeEntryTypeId: number;
  BaseTimeEntryTypeId?: number;
  CompanyId: number;
  LanguageId: number;
  Name?: string;
  Description?: string;
  DefaultPayslipText?: string;
  IsActive: boolean;
  UnitTypeId?: number;
  ExternalReference?: string;
  SortOrder: number;
  AllowEditUnitType: boolean;
  IsIllness: boolean;
  SalaryTypeId?: number;
  SalaryTypeName?: string;
  Identifier?: string;
  BaseName?: string;
  BaseDescription?: string;
  BasePayslipText?: string;

  setDefaultValue?(): void;
}

export interface IInheritTimeEntryTypeRequest {
  BaseTimeEntryTypeId: number;
  Name?: string;

  setDefaultValue?(): void;
}

export interface IIdentityCheckResponse {
  ExistsInIntect: boolean;
  BirthDate?: Date;
  IsWellFormed: boolean;
  RaiseChecksumWarning: boolean;

  setDefaultValue?(): void;
}

export interface IUserImageResponse {
  Image?: string;
  UserId: number;

  setDefaultValue?(): void;
}

export interface ICompanyUserView {
  UserId: number;
  CompanyUserId: number;
  CompanyId: number;
  CompanyName?: string;
  StatusId: number;
  RoleId: number;
  IsActive: boolean;
  RoleKey?: string;
  IdentityNumber?: string;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  TelMobile?: string;
  TelFixed?: string;
  BankRegNo?: string;
  BankAccountNo?: string;
  BicSwiftNo?: string;
  IbanNo?: string;
  IsPaymentApprover: boolean;
  Initials?: string;
  CompanyEmail?: string;
  PersonalEmail?: string;
  VATRegistrationNumber?: string;
  FullName?: string;
  UserAccountId?: number;
  CompanyAccountId?: number;
  AccountRoleId?: number;
  UserName?: string;
  DefaultCompanyId?: number;
  LanguageId: number;
  LanguageCode?: string;
  AddressId: number;
  AddressLine1?: string;
  AddressLine2?: string;
  PostalCode?: string;
  City?: string;
  CountryId?: number;
  CountryCode?: string;
  CompanyUserCreated: Date;
  UserCreated: Date;
  DateOfBirth?: Date;
  AutoDetermineBankAccount: boolean;
  MunicipalityId?: number;
  CompanyCountryId: number;
  Updated?: Date;

  setDefaultValue?(): void;
}

export interface IIdentityNumberRequest {
  IdentityNumber?: string;

  setDefaultValue?(): void;
}

export interface IBatchOperationResponse_User_User {
  ProcessedItems?: IUser[];
  Errors?: any;
  RejectedItems?: IRejectedItemContainer_User_User[];
  HasErrors: boolean;

  setDefaultValue?(): void;
}

export interface IRejectedItemContainer_User_User {
  Item?: IUser;
  Reason?: string;

  setDefaultValue?(): void;
}

export class Account implements IAccount {
  Id: number;
  Name?: string;
  StatusId: number;
  AccountTypeId: number;
  IsPublic: boolean;
  Description?: string;
  DefaultInvoiceTypeId: number;
  MasterCompanyId?: number;
  MasterCompanyEmail?: string;
  MasterCompanyVATRegistrationNumber?: string;
  MasterCompanyName?: string;
  MasterCompanyAddress?: string;
  MasterCompanyPostalCode?: string;
  MasterCompanyCity?: string;
  MasterCompanyCountry?: string;
  MasterCompanyEAN?: string;
  MasterCompanyAttention?: string;
  Companies?: ICompany[];
  LoginHistories?: ILoginHistory[];
  MasterCompany?: ICompany;
  AccountType?: IAccountType;
  AccountStatus?: IAccountStatus;
  DefaultInvoiceType?: IAccountInvoiceType;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.StatusId = this.StatusId || 0;
    this.AccountTypeId = this.AccountTypeId || 0;
    this.IsPublic = this.IsPublic || false;
    this.DefaultInvoiceTypeId = this.DefaultInvoiceTypeId || 0;
  }
}

export class Company implements ICompany {
  Id: number;
  Name?: string;
  StatusId: number;
  VATRegistrationNumber?: string;
  AccountId?: number;
  Email1?: string;
  Email2?: string;
  DefaultLanguageId: number;
  AddressId: number;
  CompanyTypeId: number;
  SecondaryVATRegistrationNumber?: string;
  MainContactCompanyUserId?: number;
  BankRegNo?: string;
  BankAccountNo?: string;
  BicSwiftNo?: string;
  IbanNo?: string;
  ExternalSystemComment?: string;
  PaymentMethodSalary: number;
  PaymentMethodPension: number;
  PaymentMethodTax: number;
  ExternalSystemId?: number;
  TelMobile?: string;
  TelFixed?: string;
  KnowsIntectFrom?: string;
  ExternalAuthentication?: string;
  HasNetsAgreement: boolean;
  Created: Date;
  EmployersAssociationNumber?: string;
  StaticsReportsYearly: boolean;
  StatisticsRecipientId?: number;
  SubscribeToNewsLetter: boolean;
  EstablishedDate?: Date;
  EmployeeCountEstimate?: string;
  Preferences?: string;
  InvoiceIdentifier?: string;
  PaymentMethodPublicPension: number;
  PaymentMethodVacationProvider: number;
  InvoiceEmail?: string;
  HasAcceptedTerms: boolean;
  CountryId: number;
  CurrentPackageLevel: number;
  ScheduledPackageId?: number;
  ScheduledPackageDate?: Date;
  IndustryCode?: string;
  AccountComment?: string;
  BankId?: number;
  IndustryOrganizationId?: number;
  InvoiceTypeId?: number;
  DefaultTaxTableId?: number;
  AllowAutoDetermineBankAccount: boolean;
  SystemAliasId: number;
  Language?: ILanguage;
  Address?: IAddress;
  CompanyLogo?: ICompanyLogo;
  Departments?: IDepartment[];
  ExternalAccounts?: IExternalAccount[];
  ReimbursementOpportunities?: IReimbursementOpportunity[];
  Holidays?: IHoliday[];
  DimensionValues?: IDimensionValue[];
  CompanyType?: ICompanyType;
  CompanyStatus?: ICompanyStatus;
  Country?: ICountry;
  InvoiceType?: IAccountInvoiceType;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.StatusId = this.StatusId || 0;
    this.DefaultLanguageId = this.DefaultLanguageId || 0;
    this.AddressId = this.AddressId || 0;
    this.CompanyTypeId = this.CompanyTypeId || 0;
    this.PaymentMethodSalary = this.PaymentMethodSalary || 0;
    this.PaymentMethodPension = this.PaymentMethodPension || 0;
    this.PaymentMethodTax = this.PaymentMethodTax || 0;
    this.HasNetsAgreement = this.HasNetsAgreement || false;
    this.Created = this.Created || null;
    this.StaticsReportsYearly = this.StaticsReportsYearly || false;
    this.SubscribeToNewsLetter = this.SubscribeToNewsLetter || false;
    this.PaymentMethodPublicPension = this.PaymentMethodPublicPension || 0;
    this.PaymentMethodVacationProvider = this.PaymentMethodVacationProvider || 0;
    this.HasAcceptedTerms = this.HasAcceptedTerms || false;
    this.CountryId = this.CountryId || 0;
    this.CurrentPackageLevel = this.CurrentPackageLevel || 0;
    this.AllowAutoDetermineBankAccount = this.AllowAutoDetermineBankAccount || false;
    this.SystemAliasId = this.SystemAliasId || 0;
  }
}

export class LoginHistory implements ILoginHistory {
  Id: number;
  LoginTime: Date;
  UserIp?: string;
  Country?: string;
  City?: string;
  Region?: string;
  PostalCode?: string;
  IsSuccessful: boolean;
  UserName?: string;
  UserId?: number;
  CompanyId?: number;
  UserAgent?: string;
  BrowserName?: string;
  BrowserVersion?: string;
  OSName?: string;
  OSVersion?: string;
  OperationType?: string;
  AccountId?: number;
  Company?: ICompany;
  User?: IUser;
  Account?: IAccount;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.LoginTime = this.LoginTime || null;
    this.IsSuccessful = this.IsSuccessful || false;
  }
}

export class AccountType implements IAccountType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class AccountStatus implements IAccountStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class AccountInvoiceType implements IAccountInvoiceType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class Language implements ILanguage {
  Id: number;
  Name?: string;
  Code?: string;
  IsSupported: boolean;
  CultureCode?: string;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.IsSupported = this.IsSupported || false;
  }
}

export class Address implements IAddress {
  Id: number;
  Line1?: string;
  Line2?: string;
  PostalCode?: string;
  City?: string;
  CountryId?: number;
  Country?: ICountry;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
  }
}

export class CompanyLogo implements ICompanyLogo {
  Id: number;
  Logo?: string;
  Company?: ICompany;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
  }
}

export class Department implements IDepartment {
  Id: number;
  CompanyId: number;
  Name?: string;
  Description?: string;
  IsActive: boolean;
  EIncomeReference?: string;
  ExternalReference?: string;
  ManagerCompanyUserId?: number;
  DimensionValues?: IDimensionValue[];
  TimeEntryTypeConfigurations?: ITimeEntryTypeConfiguration[];

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.CompanyId = this.CompanyId || 0;
    this.IsActive = this.IsActive || false;
  }
}

export class ExternalAccount implements IExternalAccount {
  Id: number;
  CompanyId: number;
  AccountIdentifier?: string;
  AccountNumber?: string;
  AccountName?: string;
  Description?: string;
  IntegrationId?: number;
  Company?: ICompany;
  Integration?: IIntegration;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.CompanyId = this.CompanyId || 0;
  }
}

export class ReimbursementOpportunity implements IReimbursementOpportunity {
  Id: number;
  ReimbursementTypeId: number;
  StartDate?: Date;
  EndDate?: Date;
  EndsEligibility: boolean;
  StatusId: number;
  Created?: Date;
  Comment?: string;
  Company?: ICompany;
  UserEmployment?: IUserEmployment;
  TimeEntryRecords?: ITimeEntryRecord[];

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.ReimbursementTypeId = this.ReimbursementTypeId || 0;
    this.EndsEligibility = this.EndsEligibility || false;
    this.StatusId = this.StatusId || 0;
  }
}

export class Holiday implements IHoliday {
  Id: number;
  Date: Date;
  ConsiderHoliday: boolean;
  DisablesBankPayments: boolean;
  CountryId?: number;
  CompanyId?: number;
  UserEmploymentTemplateId?: number;
  Description?: string;
  Created?: Date;
  Company?: ICompany;
  UserEmploymentTemplate?: IUserEmploymentTemplate;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.Date = this.Date || null;
    this.ConsiderHoliday = this.ConsiderHoliday || false;
    this.DisablesBankPayments = this.DisablesBankPayments || false;
  }
}

export class DimensionValue implements IDimensionValue {
  Id: number;
  CompanyId: number;
  DimensionNumber: number;
  Value?: string;
  Description?: string;
  ApproverCompanyUserId?: number;
  LimitToDepartmentId?: number;
  LimitToTemplateId?: number;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.CompanyId = this.CompanyId || 0;
    this.DimensionNumber = this.DimensionNumber || 0;
  }
}

export class CompanyType implements ICompanyType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class CompanyStatus implements ICompanyStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class Country implements ICountry {
  IsSupported: boolean;
  DefaultLanguageId: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.IsSupported = this.IsSupported || false;
    this.DefaultLanguageId = this.DefaultLanguageId || 0;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class User implements IUser {
  FullName?: string;
  IdentityNumberFormatted?: string;
  HasDummyIdentityNumber: boolean;
  Gender?: IGender;
  AccountRole?: IAccountRole;
  Id: number;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  IdentityNumber?: string;
  BankRegNo?: string;
  BankAccountNo?: string;
  Username?: string;
  LanguageId: number;
  BicSwiftNo?: string;
  IbanNo?: string;
  AddressId: number;
  AccessPhrase?: string;
  DateOfBirth?: Date;
  TelMobile?: string;
  TelFixed?: string;
  Email?: string;
  DefaultInitials?: string;
  DefaultCompanyId?: number;
  UseGlobalEmail: boolean;
  IsFirstLogin: boolean;
  Preferences?: string;
  Created: Date;
  ShowUpdateNotification: boolean;
  ShowUrgentNotification: boolean;
  ShowPayslipTutorial: number;
  AccountId?: number;
  AccountRoleId?: number;
  IsFemale?: boolean;
  SystemAliasId: number;
  Address?: IAddress;
  CompanyUsers?: ICompanyUser[];
  UserImage?: IUserImage;
  LoginHistories?: ILoginHistory[];
  ActivatedCompanyModules?: ICompanyModule[];

  setDefaultValue?(): void {
    this.HasDummyIdentityNumber = this.HasDummyIdentityNumber || false;
    this.Id = this.Id || 0;
    this.LanguageId = this.LanguageId || 0;
    this.AddressId = this.AddressId || 0;
    this.UseGlobalEmail = this.UseGlobalEmail || false;
    this.IsFirstLogin = this.IsFirstLogin || false;
    this.Created = this.Created || null;
    this.ShowUpdateNotification = this.ShowUpdateNotification || false;
    this.ShowUrgentNotification = this.ShowUrgentNotification || false;
    this.ShowPayslipTutorial = this.ShowPayslipTutorial || 0;
    this.SystemAliasId = this.SystemAliasId || 0;
  }
}

export class TimeEntryTypeConfiguration implements ITimeEntryTypeConfiguration {
  Id: number;
  TimeEntryTypeId: number;
  SalaryTypeId: number;
  Description?: string;
  Amount?: number;
  AmountPerUnit?: number;
  AppliesBefore?: string;
  AppliesAfter?: string;
  AppliesHolidays: boolean;
  AmountPerKilometer?: number;
  EmploymentTemplateId?: number;
  TargetUnitTypeId?: number;
  ConversionFactor?: number;
  UserEmploymentId?: number;
  AppliesSaturdays: boolean;
  AppliesSundays: boolean;
  UseDefaultRate: boolean;
  ConversionFactorAffectsRate: boolean;
  LegacyFlexEarningPercentage?: number;
  NewFlexEarningPercentage?: number;
  PensionPercentage?: number;
  AppliesMondays: boolean;
  AppliesTuesdays: boolean;
  AppliesWednesdays: boolean;
  AppliesThursdays: boolean;
  AppliesFridays: boolean;
  LimitToDepartmentId?: number;
  LimitToDimension1ValueId?: number;
  LimitToDimension2ValueId?: number;
  LimitToDimension3ValueId?: number;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.TimeEntryTypeId = this.TimeEntryTypeId || 0;
    this.SalaryTypeId = this.SalaryTypeId || 0;
    this.AppliesHolidays = this.AppliesHolidays || false;
    this.AppliesSaturdays = this.AppliesSaturdays || false;
    this.AppliesSundays = this.AppliesSundays || false;
    this.UseDefaultRate = this.UseDefaultRate || false;
    this.ConversionFactorAffectsRate = this.ConversionFactorAffectsRate || false;
    this.AppliesMondays = this.AppliesMondays || false;
    this.AppliesTuesdays = this.AppliesTuesdays || false;
    this.AppliesWednesdays = this.AppliesWednesdays || false;
    this.AppliesThursdays = this.AppliesThursdays || false;
    this.AppliesFridays = this.AppliesFridays || false;
  }
}

export class Integration implements IIntegration {
  Id: number;
  CompanyId: number;
  ExternalSystemId: number;
  Name?: string;
  Created: Date;
  StatusCode: number;
  IsSalaryTypeMappingActive: boolean;
  IntegrationOperations?: IIntegrationOperation[];
  IntegrationConfigurations?: IIntegrationConfiguration[];
  IntegrationCredentials?: IIntegrationCredential[];

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.CompanyId = this.CompanyId || 0;
    this.ExternalSystemId = this.ExternalSystemId || 0;
    this.Created = this.Created || null;
    this.StatusCode = this.StatusCode || 0;
    this.IsSalaryTypeMappingActive = this.IsSalaryTypeMappingActive || false;
  }
}

export class UserEmployment implements IUserEmployment {
  VacationType?: IVacationType;
  VacationProvider?: IVacationProvider;
  PensionProvider?: IPensionProvider;
  Pension2Provider?: IPensionProvider;
  PensionBaseCalculationMethod?: IBaseCalculationMethod;
  Pension2BaseCalculationMethod?: IBaseCalculationMethod;
  SalaryCycle?: ISalaryCycle;
  HasWorkHoursPerWeekDay: boolean;
  Id: number;
  CompanyUserId: number;
  HireDate: Date;
  ExpireDate?: Date;
  VacationTypeId: number;
  VacationProviderId?: number;
  VacationExtraPercentage?: number;
  BasedOnTemplateId?: number;
  Title?: string;
  SalaryCycleId: number;
  ExternalReference?: string;
  PensionBaseCalculationMethodId: number;
  IsAtpTempWorker: boolean;
  DepartmentId?: number;
  IncomeTypeId: number;
  WorksElsewhere: boolean;
  PensionEmployeeShare?: number;
  PensionCompanyShare?: number;
  PensionProviderId?: number;
  PensionPolicyNumber?: string;
  PensionEmployeeAmount?: number;
  PensionCompanyAmount?: number;
  Pension2BaseCalculationMethodId: number;
  Pension2ProviderId?: number;
  Pension2EmployeeShare?: number;
  Pension2CompanyShare?: number;
  Pension2EmployeeAmount?: number;
  Pension2CompanyAmount?: number;
  LastStatementEndDate?: Date;
  VacationReduceSalary: boolean;
  IsTerminated: boolean;
  VacationSupplementPayoutMonth: number;
  IsInMaternityProgram: boolean;
  TaxCardRequested?: Date;
  PensionProviderBankRegNo?: string;
  PensionProviderBankAccountNo?: string;
  Pension2ProviderBankRegNo?: string;
  Pension2ProviderBankAccountNo?: string;
  Pension2PolicyNumber?: string;
  PreferredTaxCardTypeId: number;
  Created: Date;
  IllnessReducesSalary: boolean;
  ManagerCompanyUserId?: number;
  StandardHoursPerWeek?: number;
  StatisticsSalaryPrincipleId?: number;
  StatisticsEmploymentPrincipleId?: number;
  StatisticsEmploymentTermsId?: number;
  StatisticsJobStatusId?: number;
  StatisticsJobFunctionCode?: number;
  StatisticsSalaryPrincipleFromDate?: Date;
  StatisticsEmploymentPrincipleFromDate?: Date;
  StatisticsEmploymentTermsFromDate?: Date;
  StatisticsJobStatusFromDate?: Date;
  StandardWorkDaysPerWeek?: number;
  StatisticsJobFunctionFromDate?: Date;
  FlexEarningPercentage: number;
  FlexBaseCalculationMethodId: number;
  CollectiveBargainingAgreementCode?: string;
  TerminationVacationOverridePercentage?: number;
  SeniorityDate?: Date;
  ApplyDanishRulesInGreenland: boolean;
  AnnualTaxComment?: string;
  AllowExtendedTaxCardDeductions: boolean;
  ReduceTaxCardDeduction?: number;
  IncreaseTaxRatePercentage?: number;
  UseTaxDeductionPerWorkDay: boolean;
  DefaultRate?: number;
  UseContinuousVacationReporting: boolean;
  TaxTableNumber?: number;
  TaxColumnNumber?: number;
  AdHocTaxRateOverride?: number;
  VacationDaysPerYear?: number;
  SecondaryExternalReference?: string;
  OmitVacationReporting: boolean;
  LastIllnessEndDate?: Date;
  LastIllnessStartDate?: Date;
  VacationTerminationDate?: Date;
  Pension1DueDateDelayDays?: number;
  Pension2DueDateDelayDays?: number;
  AdditionalVacationTerminationMethod?: number;
  AdditionalVacationSpentFirst: boolean;
  Updated?: Date;
  AtpHours?: number;
  IsPendingRecalc: boolean;
  PensionIsNotTaxDeductible: boolean;
  Pension2IsNotTaxDeductible: boolean;
  PensionStartDate?: Date;
  PensionEndDate?: Date;
  Pension2StartDate?: Date;
  Pension2EndDate?: Date;
  PensionFixedAmountIsPerDay: boolean;
  Pension2FixedAmountIsPerDay: boolean;
  AdvanceVacationDays?: number;
  WorkplaceIdentifier?: string;
  StandardWorkHoursMonday?: number;
  StandardWorkHoursTuesday?: number;
  StandardWorkHoursWednesday?: number;
  StandardWorkHoursThursday?: number;
  StandardWorkHoursFriday?: number;
  StandardWorkHoursSaturday?: number;
  StandardWorkHoursSunday?: number;
  CompanyUser?: ICompanyUser;
  UserTaxInfos?: IUserTaxInfo[];
  SalaryRecords?: ISalaryRecord[];
  TimeEntryTypeConfigurations?: ITimeEntryTypeConfiguration[];
  EmploymentBalanceConfigurations?: IEmploymentBalanceConfiguration[];

  setDefaultValue?(): void {
    this.HasWorkHoursPerWeekDay = this.HasWorkHoursPerWeekDay || false;
    this.Id = this.Id || 0;
    this.CompanyUserId = this.CompanyUserId || 0;
    this.HireDate = this.HireDate || null;
    this.VacationTypeId = this.VacationTypeId || 0;
    this.SalaryCycleId = this.SalaryCycleId || 0;
    this.PensionBaseCalculationMethodId = this.PensionBaseCalculationMethodId || 0;
    this.IsAtpTempWorker = this.IsAtpTempWorker || false;
    this.IncomeTypeId = this.IncomeTypeId || 0;
    this.WorksElsewhere = this.WorksElsewhere || false;
    this.Pension2BaseCalculationMethodId = this.Pension2BaseCalculationMethodId || 0;
    this.VacationReduceSalary = this.VacationReduceSalary || false;
    this.IsTerminated = this.IsTerminated || false;
    this.VacationSupplementPayoutMonth = this.VacationSupplementPayoutMonth || 0;
    this.IsInMaternityProgram = this.IsInMaternityProgram || false;
    this.PreferredTaxCardTypeId = this.PreferredTaxCardTypeId || 0;
    this.Created = this.Created || null;
    this.IllnessReducesSalary = this.IllnessReducesSalary || false;
    this.FlexEarningPercentage = this.FlexEarningPercentage || 0;
    this.FlexBaseCalculationMethodId = this.FlexBaseCalculationMethodId || 0;
    this.ApplyDanishRulesInGreenland = this.ApplyDanishRulesInGreenland || false;
    this.AllowExtendedTaxCardDeductions = this.AllowExtendedTaxCardDeductions || false;
    this.UseTaxDeductionPerWorkDay = this.UseTaxDeductionPerWorkDay || false;
    this.UseContinuousVacationReporting = this.UseContinuousVacationReporting || false;
    this.OmitVacationReporting = this.OmitVacationReporting || false;
    this.AdditionalVacationSpentFirst = this.AdditionalVacationSpentFirst || false;
    this.IsPendingRecalc = this.IsPendingRecalc || false;
    this.PensionIsNotTaxDeductible = this.PensionIsNotTaxDeductible || false;
    this.Pension2IsNotTaxDeductible = this.Pension2IsNotTaxDeductible || false;
    this.PensionFixedAmountIsPerDay = this.PensionFixedAmountIsPerDay || false;
    this.Pension2FixedAmountIsPerDay = this.Pension2FixedAmountIsPerDay || false;
  }
}

export class TimeEntryRecord implements ITimeEntryRecord {
  employeeNameWriteable?: string;
  UnitType?: IUnitType;
  Status?: ITimeEntryStatus;
  EmployeeName?: string;
  Title?: string;
  DepartmentId?: number;
  DepartmentName?: string;
  IsCalculated: boolean;
  Id: number;
  UserEmploymentId: number;
  EntryDate: Date;
  TimeEntryTypeId: number;
  Units: number;
  UnitTypeId: number;
  Description?: string;
  SalaryPeriodId: number;
  StatusId: number;
  Created: Date;
  RejectionReason?: string;
  SalaryRecordId?: number;
  SalaryStatementId?: number;
  KilometersDriven?: number;
  StartTime?: string;
  EndTime?: string;
  Dimension1Value?: string;
  Dimension2Value?: string;
  Dimension3Value?: string;
  EndDate?: Date;
  CalculatedFromRecordId?: number;
  ExternalIdentifier?: string;
  CalculatedPayout?: number;
  CalculatedPayoutKilometers?: number;
  AmountPerUnit?: number;
  ApprovedUserId?: number;
  RejectedUserId?: number;
  Dimension1ValueId?: number;
  Dimension2ValueId?: number;
  Dimension3ValueId?: number;

  setDefaultValue?(): void {
    this.IsCalculated = this.IsCalculated || false;
    this.Id = this.Id || 0;
    this.UserEmploymentId = this.UserEmploymentId || 0;
    this.EntryDate = this.EntryDate || null;
    this.TimeEntryTypeId = this.TimeEntryTypeId || 0;
    this.Units = this.Units || 0;
    this.UnitTypeId = this.UnitTypeId || 0;
    this.SalaryPeriodId = this.SalaryPeriodId || 0;
    this.StatusId = this.StatusId || 0;
    this.Created = this.Created || null;
  }
}

export class UserEmploymentTemplate implements IUserEmploymentTemplate {
  VacationType?: IVacationType;
  VacationProvider?: IVacationProvider;
  PensionProvider?: IPensionProvider;
  Pension2Provider?: IPensionProvider;
  PensionBaseCalculationMethod?: IBaseCalculationMethod;
  Pension2BaseCalculationMethod?: IBaseCalculationMethod;
  SalaryCycle?: ISalaryCycle;
  Id: number;
  CompanyId: number;
  Name?: string;
  VacationTypeId: number;
  VacationProviderId?: number;
  VacationExtraPercentage?: number;
  PensionBaseCalculationMethodId: number;
  IsAtpTempWorker: boolean;
  WorksElsewhere: boolean;
  PensionEmployeeShare?: number;
  PensionCompanyShare?: number;
  PensionProviderId?: number;
  PensionEmployeeAmount?: number;
  PensionCompanyAmount?: number;
  Pension2BaseCalculationMethodId: number;
  Pension2ProviderId?: number;
  Pension2EmployeeShare?: number;
  Pension2CompanyShare?: number;
  Pension2EmployeeAmount?: number;
  Pension2CompanyAmount?: number;
  IncomeTypeId: number;
  SalaryCycleId: number;
  VacationReduceSalary: boolean;
  VacationSupplementPayoutMonth: number;
  IsInMaternityProgram: boolean;
  PensionProviderBankRegNo?: string;
  PensionProviderBankAccountNo?: string;
  Pension2ProviderBankRegNo?: string;
  Pension2ProviderBankAccountNo?: string;
  IllnessReducesSalary: boolean;
  StandardHoursPerWeek?: number;
  StatisticsSalaryPrincipleId?: number;
  StatisticsEmploymentPrincipleId?: number;
  StatisticsEmploymentTermsId?: number;
  StatisticsJobStatusId?: number;
  StatisticsJobFunctionCode?: number;
  StandardWorkDaysPerWeek?: number;
  DepartmentId?: number;
  FlexEarningPercentage: number;
  FlexBaseCalculationMethodId: number;
  IsDefault: boolean;
  PensionPolicyNumber?: string;
  Pension2PolicyNumber?: string;
  CollectiveBargainingAgreementCode?: string;
  TerminationVacationOverridePercentage?: number;
  SystemDefaultForCountryId?: number;
  TimeEntryRecordAcrossSalaryPeriodStrategy: number;
  AllowExtendedTaxCardDeductions: boolean;
  TemplateExternalReference?: string;
  UseTaxDeductionPerWorkDay: boolean;
  DefaultRate?: number;
  VacationDaysPerYear?: number;
  ApplyLongTermIllnessCompensation: boolean;
  AnnualIncomeMultiplicationFactor?: number;
  Pension1DueDateDelayDays?: number;
  Pension2DueDateDelayDays?: number;
  AdditionalVacationTerminationMethod?: number;
  AdditionalVacationSpentFirst: boolean;
  PensionIsNotTaxDeductible: boolean;
  Pension2IsNotTaxDeductible: boolean;
  PensionStartDate?: Date;
  PensionEndDate?: Date;
  Pension2StartDate?: Date;
  Pension2EndDate?: Date;
  PensionFixedAmountIsPerDay: boolean;
  Pension2FixedAmountIsPerDay: boolean;
  AdvanceVacationDays?: number;
  WorkplaceIdentifier?: string;
  StandardWorkHoursMonday?: number;
  StandardWorkHoursTuesday?: number;
  StandardWorkHoursWednesday?: number;
  StandardWorkHoursThursday?: number;
  StandardWorkHoursFriday?: number;
  StandardWorkHoursSaturday?: number;
  StandardWorkHoursSunday?: number;
  TemplateSalaryRecords?: ITemplateSalaryRecord[];
  SenioritySupplements?: ISenioritySupplement[];
  OvertimeSupplements?: IOvertimeSupplement[];
  TemplateBalanceConfigurations?: ITemplateBalanceConfiguration[];
  Holidays?: IHoliday[];
  DimensionValues?: IDimensionValue[];

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.CompanyId = this.CompanyId || 0;
    this.VacationTypeId = this.VacationTypeId || 0;
    this.PensionBaseCalculationMethodId = this.PensionBaseCalculationMethodId || 0;
    this.IsAtpTempWorker = this.IsAtpTempWorker || false;
    this.WorksElsewhere = this.WorksElsewhere || false;
    this.Pension2BaseCalculationMethodId = this.Pension2BaseCalculationMethodId || 0;
    this.IncomeTypeId = this.IncomeTypeId || 0;
    this.SalaryCycleId = this.SalaryCycleId || 0;
    this.VacationReduceSalary = this.VacationReduceSalary || false;
    this.VacationSupplementPayoutMonth = this.VacationSupplementPayoutMonth || 0;
    this.IsInMaternityProgram = this.IsInMaternityProgram || false;
    this.IllnessReducesSalary = this.IllnessReducesSalary || false;
    this.FlexEarningPercentage = this.FlexEarningPercentage || 0;
    this.FlexBaseCalculationMethodId = this.FlexBaseCalculationMethodId || 0;
    this.IsDefault = this.IsDefault || false;
    this.TimeEntryRecordAcrossSalaryPeriodStrategy = this.TimeEntryRecordAcrossSalaryPeriodStrategy || 0;
    this.AllowExtendedTaxCardDeductions = this.AllowExtendedTaxCardDeductions || false;
    this.UseTaxDeductionPerWorkDay = this.UseTaxDeductionPerWorkDay || false;
    this.ApplyLongTermIllnessCompensation = this.ApplyLongTermIllnessCompensation || false;
    this.AdditionalVacationSpentFirst = this.AdditionalVacationSpentFirst || false;
    this.PensionIsNotTaxDeductible = this.PensionIsNotTaxDeductible || false;
    this.Pension2IsNotTaxDeductible = this.Pension2IsNotTaxDeductible || false;
    this.PensionFixedAmountIsPerDay = this.PensionFixedAmountIsPerDay || false;
    this.Pension2FixedAmountIsPerDay = this.Pension2FixedAmountIsPerDay || false;
  }
}

export class Gender implements IGender {
  IsFemale: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.IsFemale = this.IsFemale || false;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class AccountRole implements IAccountRole {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class CompanyUser implements ICompanyUser {
  Id: number;
  UserId: number;
  CompanyId: number;
  RoleId: number;
  IsActive: boolean;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  BankRegNo?: string;
  BankAccountNo?: string;
  BicSwiftNo?: string;
  IbanNo?: string;
  AddressId: number;
  IsPaymentApprover: boolean;
  Initials?: string;
  TelMobile?: string;
  TelFixed?: string;
  CompanyEmail?: string;
  PersonalEmail?: string;
  Created: Date;
  DeactivationDate?: Date;
  PreferredCurrencyId?: number;
  AutoDetermineBankAccount: boolean;
  MunicipalityId?: number;
  Updated?: Date;
  UserEmployments?: IUserEmployment[];
  Address?: IAddress;
  UserImage?: IUserImage;
  DimensionValues?: IDimensionValue[];
  FullName?: string;
  Role?: IRole;
  UserLanguageId: number;
  UserIdentityNumber?: string;
  UserDateOfBirth: Date;
  UserIsFemale?: boolean;
  HasDummyIdentityNumber: boolean;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.UserId = this.UserId || 0;
    this.CompanyId = this.CompanyId || 0;
    this.RoleId = this.RoleId || 0;
    this.IsActive = this.IsActive || false;
    this.AddressId = this.AddressId || 0;
    this.IsPaymentApprover = this.IsPaymentApprover || false;
    this.Created = this.Created || null;
    this.AutoDetermineBankAccount = this.AutoDetermineBankAccount || false;
    this.UserLanguageId = this.UserLanguageId || 0;
    this.UserDateOfBirth = this.UserDateOfBirth || null;
    this.HasDummyIdentityNumber = this.HasDummyIdentityNumber || false;
  }
}

export class UserImage implements IUserImage {
  Id: number;
  Image?: string;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
  }
}

export class CompanyModule implements ICompanyModule {
  Id: number;
  ModuleId: number;
  LastActivated: Date;
  CompanyId: number;
  ScheduledDeactivationDate?: Date;
  ActivatedUserId?: number;
  ActivatedByUser?: IUser;
  Module?: IModule;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.ModuleId = this.ModuleId || 0;
    this.LastActivated = this.LastActivated || null;
    this.CompanyId = this.CompanyId || 0;
  }
}

export class IntegrationOperation implements IIntegrationOperation {
  Id: number;
  IntegrationId: number;
  IsActive: boolean;
  LastRun?: Date;
  StatusCode: number;
  OperationId: number;
  TranslationKey?: string;
  TranslationParametersList?: string;
  Integration?: IIntegration;
  TranslatedStatusMessage?: string;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.IntegrationId = this.IntegrationId || 0;
    this.IsActive = this.IsActive || false;
    this.StatusCode = this.StatusCode || 0;
    this.OperationId = this.OperationId || 0;
  }
}

export class IntegrationConfiguration implements IIntegrationConfiguration {
  Id: number;
  IntegrationId: number;
  Key?: string;
  Value?: string;
  Integration?: IIntegration;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.IntegrationId = this.IntegrationId || 0;
  }
}

export class IntegrationCredential implements IIntegrationCredential {
  Id: number;
  IntegrationId: number;
  Key?: string;
  Value?: string;
  Integration?: IIntegration;
  ExternalSystemCredential?: IExternalSystemCredential;
  IsPrivate: boolean;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.IntegrationId = this.IntegrationId || 0;
    this.IsPrivate = this.IsPrivate || false;
  }
}

export class VacationType implements IVacationType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class VacationProvider implements IVacationProvider {
  SENumber?: string;
  IsAdministrative: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.IsAdministrative = this.IsAdministrative || false;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class PensionProvider implements IPensionProvider {
  NetsNo?: string;
  DefaultBaseCalculationMethodId: number;
  NetsInfoType: number;
  PolicyNumberRequired: boolean;
  AccountNumberRequired: boolean;
  PolicyNumberLabelOverride?: string;
  CountryId: number;
  IsDummyForBankAccount: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.DefaultBaseCalculationMethodId = this.DefaultBaseCalculationMethodId || 0;
    this.NetsInfoType = this.NetsInfoType || 0;
    this.PolicyNumberRequired = this.PolicyNumberRequired || false;
    this.AccountNumberRequired = this.AccountNumberRequired || false;
    this.CountryId = this.CountryId || 0;
    this.IsDummyForBankAccount = this.IsDummyForBankAccount || false;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class BaseCalculationMethod implements IBaseCalculationMethod {
  IsPensionBase: boolean;
  IsFlexBase: boolean;
  IsExtraVacationHoursBase: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.IsPensionBase = this.IsPensionBase || false;
    this.IsFlexBase = this.IsFlexBase || false;
    this.IsExtraVacationHoursBase = this.IsExtraVacationHoursBase || false;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class SalaryCycle implements ISalaryCycle {
  Months: number;
  Days: number;
  MonthEnd: boolean;
  IsForwardPaid: boolean;
  TaxDeductionFactor: number;
  IsMonthly: boolean;
  IsWeekly: boolean;
  IsBiWeekly: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.Months = this.Months || 0;
    this.Days = this.Days || 0;
    this.MonthEnd = this.MonthEnd || false;
    this.IsForwardPaid = this.IsForwardPaid || false;
    this.TaxDeductionFactor = this.TaxDeductionFactor || 0;
    this.IsMonthly = this.IsMonthly || false;
    this.IsWeekly = this.IsWeekly || false;
    this.IsBiWeekly = this.IsBiWeekly || false;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class UserTaxInfo implements IUserTaxInfo {
  TaxCardType?: ITaxCardType;
  IsCurrent: boolean;
  Id: number;
  ValidFrom: Date;
  ValidUntil?: Date;
  TaxRate?: number;
  DeductionMonth?: number;
  FreeAmount?: number;
  UserEmploymentId: number;
  IsActive: boolean;
  TaxCardTypeId: number;
  IsDummy: boolean;
  MinimumRate?: number;
  MaximumDeductionMonth?: number;
  MaximumDeductionDay?: number;
  Created: Date;
  DeductionWeek?: number;
  DeductionDay?: number;
  IsManual: boolean;
  ParsedFromFileName?: string;
  TaxCardRequestId?: number;

  setDefaultValue?(): void {
    this.IsCurrent = this.IsCurrent || false;
    this.Id = this.Id || 0;
    this.ValidFrom = this.ValidFrom || null;
    this.UserEmploymentId = this.UserEmploymentId || 0;
    this.IsActive = this.IsActive || false;
    this.TaxCardTypeId = this.TaxCardTypeId || 0;
    this.IsDummy = this.IsDummy || false;
    this.Created = this.Created || null;
    this.IsManual = this.IsManual || false;
  }
}

export class SalaryRecord implements ISalaryRecord {
  SalaryTypeView?: ISalaryTypeView;
  UnitType?: IUnitType;
  PersistenceType?: ISalaryRecordPersistenceType;
  Id: number;
  Units?: number;
  IsActive: boolean;
  StartDate?: Date;
  EndDate?: Date;
  Description?: string;
  UserEmploymentId: number;
  UnitTypeId?: number;
  AmountPerUnit?: number;
  Amount?: number;
  BaseAmount?: number;
  SalaryTypeId: number;
  SortOrder: number;
  TimeEntryTypeId?: number;
  PersistenceTypeId: number;
  Dimension1Value?: string;
  Dimension2Value?: string;
  Dimension3Value?: string;
  ExternalIdentifier?: string;
  LegacyFlexEarningPercentage?: number;
  NewFlexEarningPercentage?: number;
  PensionPercentage?: number;
  SalaryStatementId?: number;
  Dimension1ValueId?: number;
  Dimension2ValueId?: number;
  Dimension3ValueId?: number;
  TimeEntryType?: ITimeEntryType;
  SalaryStatement?: ISalaryStatement;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.IsActive = this.IsActive || false;
    this.UserEmploymentId = this.UserEmploymentId || 0;
    this.SalaryTypeId = this.SalaryTypeId || 0;
    this.SortOrder = this.SortOrder || 0;
    this.PersistenceTypeId = this.PersistenceTypeId || 0;
  }
}

export class EmploymentBalanceConfiguration implements IEmploymentBalanceConfiguration {
  Id: number;
  UserEmploymentId: number;
  BalanceDefinitionId: number;
  EarningPercentage?: number;
  BaseCalculationMethodId?: number;
  EarningFixedAmount?: number;
  EarningFixedAmountPrincipleId?: number;
  PensionSharePercentage?: number;
  PensionShareFixedAmount?: number;
  DaysConversionPercentage?: number;
  DaysEarnedPerPayslip?: number;
  PayoutPercentage?: number;
  SeniorityRequirementMonths?: number;
  CustomBehaviorId?: number;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.UserEmploymentId = this.UserEmploymentId || 0;
    this.BalanceDefinitionId = this.BalanceDefinitionId || 0;
  }
}

export class UnitType implements IUnitType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class TimeEntryStatus implements ITimeEntryStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class TemplateSalaryRecord implements ITemplateSalaryRecord {
  UnitType?: IUnitType;
  PersistenceType?: ISalaryRecordPersistenceType;
  Id: number;
  EmploymentTemplateId: number;
  Units?: number;
  IsActive: boolean;
  Description?: string;
  UnitTypeId?: number;
  AmountPerUnit?: number;
  Amount?: number;
  BaseAmount?: number;
  SalaryTypeId: number;
  PersistenceTypeId: number;
  SortOrder: number;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.EmploymentTemplateId = this.EmploymentTemplateId || 0;
    this.IsActive = this.IsActive || false;
    this.SalaryTypeId = this.SalaryTypeId || 0;
    this.PersistenceTypeId = this.PersistenceTypeId || 0;
    this.SortOrder = this.SortOrder || 0;
  }
}

export class SenioritySupplement implements ISenioritySupplement {
  Id: number;
  EmploymentTemplateId: number;
  SeniorityMonthsStart?: number;
  SeniorityMonthsEnd?: number;
  SupplementAmount: number;
  SupplementIntervalType: number;
  HourlyRate?: number;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.EmploymentTemplateId = this.EmploymentTemplateId || 0;
    this.SupplementAmount = this.SupplementAmount || 0;
    this.SupplementIntervalType = this.SupplementIntervalType || 0;
  }
}

export class OvertimeSupplement implements IOvertimeSupplement {
  Id: number;
  EmploymentTemplateId: number;
  TotalHoursWorkedFrom?: number;
  TotalHoursWorkedUntil?: number;
  SupplementAmount?: number;
  SupplementPercentage?: number;
  OvertimeSupplementPrinciple: number;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.EmploymentTemplateId = this.EmploymentTemplateId || 0;
    this.OvertimeSupplementPrinciple = this.OvertimeSupplementPrinciple || 0;
  }
}

export class TemplateBalanceConfiguration implements ITemplateBalanceConfiguration {
  Id: number;
  EmploymentTemplateId: number;
  BalanceDefinitionId: number;
  EarningPercentage?: number;
  BaseCalculationMethodId?: number;
  EarningFixedAmount?: number;
  EarningFixedAmountPrincipleId?: number;
  PensionSharePercentage?: number;
  PensionShareFixedAmount?: number;
  DaysConversionPercentage?: number;
  DaysEarnedPerPayslip?: number;
  PayoutPercentage?: number;
  SeniorityRequirementMonths?: number;
  CustomBehaviorId?: number;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.EmploymentTemplateId = this.EmploymentTemplateId || 0;
    this.BalanceDefinitionId = this.BalanceDefinitionId || 0;
  }
}

export class Role implements IRole {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class Module implements IModule {
  Price: number;
  BillingPrincipleId: number;
  FreeUntilDate?: Date;
  InfoOnly: boolean;
  PackageLevel: number;
  BillingPrinciple?: IBillingPrinciple;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.Price = this.Price || 0;
    this.BillingPrincipleId = this.BillingPrincipleId || 0;
    this.InfoOnly = this.InfoOnly || false;
    this.PackageLevel = this.PackageLevel || 0;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class ExternalSystemCredential implements IExternalSystemCredential {
  IsPrivate: boolean;
  BackendKey?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.IsPrivate = this.IsPrivate || false;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class TaxCardType implements ITaxCardType {
  EIncomeReference: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.EIncomeReference = this.EIncomeReference || 0;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class SalaryTypeView implements ISalaryTypeView {
  SalaryTypeId: number;
  BaseSalaryTypeId?: number;
  CompanyId: number;
  LanguageId: number;
  CategoryId?: number;
  Name?: string;
  Description?: string;
  DefaultPayslipText?: string;
  IsVisible: boolean;
  IsActive: boolean;
  UnitTypeId?: number;
  AmountPerUnit?: number;
  Units?: number;
  BaseAmount?: number;
  Amount?: number;
  EIncomeCategory: number;
  EIncomeSubCategory: number;
  SummaryCategoryId?: number;
  SalaryTypeGroupId?: number;
  ExternalReference?: string;
  ImportExternalReference?: string;
  IsPrimaryIncome: boolean;
  TriggersAMB: boolean;
  IncludeInPayment: boolean;
  TriggersIncomeTax: boolean;
  TriggersATP: boolean;
  EarnsHolidayCompensation: boolean;
  IsInternal: boolean;
  SortOrder: number;
  AllowEditUnitType: boolean;
  AllowEditBaseAmount: boolean;
  NegativeDefault: boolean;
  DefaultPersistanceTypeId?: number;
  StatisticsOnly: boolean;
  StatisticsFieldCode?: string;
  SumFromIds?: string;
  SumFromReverseSign: boolean;
  IncludeAlways: boolean;
  RelatesToIncomeType5?: boolean;
  Explanation?: string;
  TriggersOvertimeSupplement?: boolean;
  SulinalFieldNumber?: string;
  OverviewIndex?: number;
  OverviewDisplayMode?: number;
  Identifier?: string;
  IsAdvanced?: boolean;
  AccountTypeId?: number;
  BalanceDefinitionId?: number;
  BalanceBehavior?: number;
  BaseName?: string;
  BaseDescription?: string;
  BasePayslipText?: string;
  SkatteverketAgiFieldCode?: number;
  DimensionDistributionOptionId?: number;
  DisplayDates: boolean;

  setDefaultValue?(): void {
    this.SalaryTypeId = this.SalaryTypeId || 0;
    this.CompanyId = this.CompanyId || 0;
    this.LanguageId = this.LanguageId || 0;
    this.IsVisible = this.IsVisible || false;
    this.IsActive = this.IsActive || false;
    this.EIncomeCategory = this.EIncomeCategory || 0;
    this.EIncomeSubCategory = this.EIncomeSubCategory || 0;
    this.IsPrimaryIncome = this.IsPrimaryIncome || false;
    this.TriggersAMB = this.TriggersAMB || false;
    this.IncludeInPayment = this.IncludeInPayment || false;
    this.TriggersIncomeTax = this.TriggersIncomeTax || false;
    this.TriggersATP = this.TriggersATP || false;
    this.EarnsHolidayCompensation = this.EarnsHolidayCompensation || false;
    this.IsInternal = this.IsInternal || false;
    this.SortOrder = this.SortOrder || 0;
    this.AllowEditUnitType = this.AllowEditUnitType || false;
    this.AllowEditBaseAmount = this.AllowEditBaseAmount || false;
    this.NegativeDefault = this.NegativeDefault || false;
    this.StatisticsOnly = this.StatisticsOnly || false;
    this.SumFromReverseSign = this.SumFromReverseSign || false;
    this.IncludeAlways = this.IncludeAlways || false;
    this.DisplayDates = this.DisplayDates || false;
  }
}

export class SalaryRecordPersistenceType implements ISalaryRecordPersistenceType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class TimeEntryType implements ITimeEntryType {
  Id: number;
  BaseTimeEntryTypeId?: number;
  CompanyId?: number;
  Name?: string;
  Description?: string;
  IsActive?: boolean;
  UnitTypeId?: number;
  ExternalReference?: string;
  SalaryTypeId?: number;
  SortOrder?: number;
  DefaultPayslipText?: string;
  AllowEditUnitType?: boolean;
  IsIllness?: boolean;
  InternalReferenceId?: number;
  Identifier?: string;
  BaseTimeEntryType?: ITimeEntryType;
  TimeEntryTypeTranslations?: ITimeEntryTypeTranslation[];
  TimeEntryTypeConfigurations?: ITimeEntryTypeConfiguration[];

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
  }
}

export class SalaryStatement implements ISalaryStatement {
  PayoutDateDerived?: Date;
  VacationProvider?: IVacationProvider;
  PensionProvider?: IPensionProvider;
  Pension2Provider?: IPensionProvider;
  IsFinalized: boolean;
  Description?: string;
  Id: number;
  SalaryBatchId: number;
  PeriodFrom: Date;
  PeriodTo: Date;
  PayoutAmount?: number;
  AtpEmployee: number;
  AtpEmployer: number;
  UserEmploymentId: number;
  TaxAmount: number;
  AmbAmount: number;
  PensionProviderId?: number;
  PensionEmployeeShare?: number;
  PensionEmployeeShareAmount: number;
  PensionCompanyShare?: number;
  PensionCompanyShareAmount: number;
  VacationProviderId?: number;
  VacationMoneyEarnedGross: number;
  VacationMoneyEarnedNet?: number;
  VacationDaysEarned: number;
  UserFullName?: string;
  UserIdentityNumber?: string;
  UserAddressId?: number;
  PensionBaseAmount: number;
  Pension2BaseAmount: number;
  Pension2ProviderId?: number;
  Pension2EmployeeShare?: number;
  Pension2CompanyShare?: number;
  Pension2EmployeeShareAmount: number;
  Pension2CompanyShareAmount: number;
  VacationDaysSpent: number;
  VacationBaseAmount: number;
  PrimaryIncomeAmount: number;
  HoursWorked: number;
  KmEntered: number;
  AppliedTaxDeduction: number;
  TaxCardRemainingFreeAmount?: number;
  TaxCardTypeId: number;
  TaxRate: number;
  VacationDaysSubtracted: number;
  TaxBaseAmount: number;
  AmbBaseAmount: number;
  VacationTaxDeduction?: number;
  TaxCardFreeAmountReduction?: number;
  ActualFromDate: Date;
  ActualEndDate: Date;
  IsTermination: boolean;
  TerminationTotalVacationMoneyGross?: number;
  TerminationTotalVacationMoneyNet?: number;
  TerminationYearBeforeLastVacationMoneyGross?: number;
  TerminationYearBeforeLastVacationMoneyNet?: number;
  TerminationYearBeforeLastVacationRemainingDays?: number;
  TerminationLastYearVacationMoneyGross?: number;
  TerminationLastYearVacationMoneyNet?: number;
  TerminationLastYearVacationRemainingDays?: number;
  TerminationCurrentYearVacationMoneyGross?: number;
  TerminationCurrentYearVacationMoneyNet?: number;
  TerminationCurrentYearVacationRemainingDays?: number;
  VacationTaxReduction: number;
  VacationAmbAmount: number;
  FFHoursEarned: number;
  FFHoursSpent: number;
  RecipientBankRegNo?: string;
  RecipientBankAccountNo?: string;
  RecipientBicSwiftNo?: string;
  RecipientIbanNo?: string;
  IllnessCompensationAtpAmountEmployee?: number;
  DepartmentId?: number;
  OvertimeHours: number;
  IllnessHours: number;
  TaxableIncome: number;
  VacationSupplementEarned?: number;
  SummaryAmountSalary?: number;
  SummaryAmountSupplements?: number;
  SummaryAmountTaxes?: number;
  SummaryAmountAdjustments?: number;
  FlexAmountEarned: number;
  FlexAmountReduced: number;
  SummaryAmountPension?: number;
  VacationAmbBaseAmount: number;
  TaxFreeIncome?: number;
  TravelCompensation: number;
  PensionCompanyTaxAmount?: number;
  PensionEmployeeTaxAmount?: number;
  ReportableNonTaxedIncome?: number;
  DownloadToken?: string;
  DownloadTokenExpiryTime?: Date;
  VacationPayoutEarnedLastYear?: number;
  VacationPayoutEarnedCurrentYear?: number;
  VacationBalancePeriodId?: number;
  VacationPayoutAmountGross?: number;
  VacationPayoutAmountNet?: number;
  AdditionalVacationDaysEarned?: number;
  AdditionalVacationDaysSpent?: number;
  AdditionalVacationEarnedGross?: number;
  AmExemptIncome: number;
  SalaryBatch?: ISalaryBatch;
  SalaryBatchRecords?: ISalaryBatchRecord[];
  UserEmployment?: IUserEmployment;
  BalanceAdjustments?: IBalanceAdjustment[];
  SalaryRecords?: ISalaryRecord[];

  setDefaultValue?(): void {
    this.IsFinalized = this.IsFinalized || false;
    this.Id = this.Id || 0;
    this.SalaryBatchId = this.SalaryBatchId || 0;
    this.PeriodFrom = this.PeriodFrom || null;
    this.PeriodTo = this.PeriodTo || null;
    this.AtpEmployee = this.AtpEmployee || 0;
    this.AtpEmployer = this.AtpEmployer || 0;
    this.UserEmploymentId = this.UserEmploymentId || 0;
    this.TaxAmount = this.TaxAmount || 0;
    this.AmbAmount = this.AmbAmount || 0;
    this.PensionEmployeeShareAmount = this.PensionEmployeeShareAmount || 0;
    this.PensionCompanyShareAmount = this.PensionCompanyShareAmount || 0;
    this.VacationMoneyEarnedGross = this.VacationMoneyEarnedGross || 0;
    this.VacationDaysEarned = this.VacationDaysEarned || 0;
    this.PensionBaseAmount = this.PensionBaseAmount || 0;
    this.Pension2BaseAmount = this.Pension2BaseAmount || 0;
    this.Pension2EmployeeShareAmount = this.Pension2EmployeeShareAmount || 0;
    this.Pension2CompanyShareAmount = this.Pension2CompanyShareAmount || 0;
    this.VacationDaysSpent = this.VacationDaysSpent || 0;
    this.VacationBaseAmount = this.VacationBaseAmount || 0;
    this.PrimaryIncomeAmount = this.PrimaryIncomeAmount || 0;
    this.HoursWorked = this.HoursWorked || 0;
    this.KmEntered = this.KmEntered || 0;
    this.AppliedTaxDeduction = this.AppliedTaxDeduction || 0;
    this.TaxCardTypeId = this.TaxCardTypeId || 0;
    this.TaxRate = this.TaxRate || 0;
    this.VacationDaysSubtracted = this.VacationDaysSubtracted || 0;
    this.TaxBaseAmount = this.TaxBaseAmount || 0;
    this.AmbBaseAmount = this.AmbBaseAmount || 0;
    this.ActualFromDate = this.ActualFromDate || null;
    this.ActualEndDate = this.ActualEndDate || null;
    this.IsTermination = this.IsTermination || false;
    this.VacationTaxReduction = this.VacationTaxReduction || 0;
    this.VacationAmbAmount = this.VacationAmbAmount || 0;
    this.FFHoursEarned = this.FFHoursEarned || 0;
    this.FFHoursSpent = this.FFHoursSpent || 0;
    this.OvertimeHours = this.OvertimeHours || 0;
    this.IllnessHours = this.IllnessHours || 0;
    this.TaxableIncome = this.TaxableIncome || 0;
    this.FlexAmountEarned = this.FlexAmountEarned || 0;
    this.FlexAmountReduced = this.FlexAmountReduced || 0;
    this.VacationAmbBaseAmount = this.VacationAmbBaseAmount || 0;
    this.TravelCompensation = this.TravelCompensation || 0;
    this.AmExemptIncome = this.AmExemptIncome || 0;
  }
}

export class BillingPrinciple implements IBillingPrinciple {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class TimeEntryTypeTranslation implements ITimeEntryTypeTranslation {
  Id: number;
  TimeEntryTypeId: number;
  LanguageId: number;
  Name?: string;
  Description?: string;
  DefaultPayslipText?: string;
  StatusId: number;
  Comment?: string;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.TimeEntryTypeId = this.TimeEntryTypeId || 0;
    this.LanguageId = this.LanguageId || 0;
    this.StatusId = this.StatusId || 0;
  }
}

export class SalaryBatch implements ISalaryBatch {
  Status?: ISalaryBatchStatus;
  IsDraft: boolean;
  IsLocked: boolean;
  IsFinalized: boolean;
  Id: number;
  CompanyId: number;
  SalaryPeriodId: number;
  Created: Date;
  StatusId: number;
  Approved?: Date;
  RejectionReason?: string;
  Rejected?: Date;
  EIncomeTransferDate?: Date;
  Message?: string;
  PayoutDate: Date;
  HasErrors: boolean;
  HasWarnings: boolean;
  NetsPaymentsProcessingDate?: Date;
  IsTermination: boolean;
  EIncomeZeroReport: boolean;
  SalaryBatchNumber: number;
  PaymentMethodSalary: number;
  PaymentMethodTax: number;
  PaymentMethodPension: number;
  EIncomeReruns: number;
  PayslipsArchived: boolean;
  LimitToSpecificEmployees: boolean;
  EBoksDeliveryEnabled: boolean;
  PayoutAllFlex: boolean;
  SpecifiedEmployees?: string;
  HasNemKontoPayments: boolean;
  PaymentMethodPublicPension: number;
  PaymentMethodVacationProvider: number;
  ToArchive: boolean;
  Finalized?: Date;
  AllowPreliminaryPayslips: boolean;
  PackageLevelWhenFinalized?: number;
  IsAutomated: boolean;
  PayoutNewFlex: boolean;
  ApprovedUserId?: number;
  CreatedUserId?: number;
  FinalizedUserId?: number;
  RejectedUserId?: number;
  FromDateOverride?: Date;
  ToDateOverride?: Date;
  FinalizationGuid?: string;
  SalaryStatements?: ISalaryStatement[];
  SalaryPeriod?: ISalaryPeriod;
  SalaryBatchRecords?: ISalaryBatchRecord[];
  SalaryBatchValidationIssues?: ISalaryBatchValidationIssue[];

  setDefaultValue?(): void {
    this.IsDraft = this.IsDraft || false;
    this.IsLocked = this.IsLocked || false;
    this.IsFinalized = this.IsFinalized || false;
    this.Id = this.Id || 0;
    this.CompanyId = this.CompanyId || 0;
    this.SalaryPeriodId = this.SalaryPeriodId || 0;
    this.Created = this.Created || null;
    this.StatusId = this.StatusId || 0;
    this.PayoutDate = this.PayoutDate || null;
    this.HasErrors = this.HasErrors || false;
    this.HasWarnings = this.HasWarnings || false;
    this.IsTermination = this.IsTermination || false;
    this.EIncomeZeroReport = this.EIncomeZeroReport || false;
    this.SalaryBatchNumber = this.SalaryBatchNumber || 0;
    this.PaymentMethodSalary = this.PaymentMethodSalary || 0;
    this.PaymentMethodTax = this.PaymentMethodTax || 0;
    this.PaymentMethodPension = this.PaymentMethodPension || 0;
    this.EIncomeReruns = this.EIncomeReruns || 0;
    this.PayslipsArchived = this.PayslipsArchived || false;
    this.LimitToSpecificEmployees = this.LimitToSpecificEmployees || false;
    this.EBoksDeliveryEnabled = this.EBoksDeliveryEnabled || false;
    this.PayoutAllFlex = this.PayoutAllFlex || false;
    this.HasNemKontoPayments = this.HasNemKontoPayments || false;
    this.PaymentMethodPublicPension = this.PaymentMethodPublicPension || 0;
    this.PaymentMethodVacationProvider = this.PaymentMethodVacationProvider || 0;
    this.ToArchive = this.ToArchive || false;
    this.AllowPreliminaryPayslips = this.AllowPreliminaryPayslips || false;
    this.IsAutomated = this.IsAutomated || false;
    this.PayoutNewFlex = this.PayoutNewFlex || false;
  }
}

export class SalaryBatchRecord implements ISalaryBatchRecord {
  Id: number;
  SalaryTypeId: number;
  Amount: number;
  Units?: number;
  Description?: string;
  CompanyUserId: number;
  UnitTypeId?: number;
  UserEmploymentId: number;
  SalaryRecordId?: number;
  SalaryBatchId: number;
  AmountPerUnit?: number;
  BaseAmount?: number;
  SalaryStatementId?: number;
  IncludeInPayment: boolean;
  EIncomeCategory: number;
  EIncomeSubCategory: number;
  SummaryCategoryId?: number;
  PeriodFactor?: number;
  FromDate?: Date;
  ToDate?: Date;
  SortOrder: number;
  IsPrimaryIncome: boolean;
  TriggersAMB: boolean;
  TriggersIncomeTax: boolean;
  TriggersATP: boolean;
  EarnsHolidayCompensation: boolean;
  SpecialIdentifier?: string;
  IsInternal: boolean;
  BaseSalaryTypeId?: number;
  ExternalReferenceSalaryType?: string;
  ExternalReferenceEmployee?: string;
  ExternalReferenceDepartment?: string;
  InformationOnly: boolean;
  Dimension1Value?: string;
  Dimension2Value?: string;
  Dimension3Value?: string;
  SulinalFieldNumber?: string;
  IsReportableIncome: boolean;
  SkatteverketAgiFieldCode?: number;
  LegacyFlexEarningPercentage?: number;
  NewFlexEarningPercentage?: number;
  PensionPercentage?: number;
  DisplayDates: boolean;
  Dimension1ValueId?: number;
  Dimension2ValueId?: number;
  Dimension3ValueId?: number;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.SalaryTypeId = this.SalaryTypeId || 0;
    this.Amount = this.Amount || 0;
    this.CompanyUserId = this.CompanyUserId || 0;
    this.UserEmploymentId = this.UserEmploymentId || 0;
    this.SalaryBatchId = this.SalaryBatchId || 0;
    this.IncludeInPayment = this.IncludeInPayment || false;
    this.EIncomeCategory = this.EIncomeCategory || 0;
    this.EIncomeSubCategory = this.EIncomeSubCategory || 0;
    this.SortOrder = this.SortOrder || 0;
    this.IsPrimaryIncome = this.IsPrimaryIncome || false;
    this.TriggersAMB = this.TriggersAMB || false;
    this.TriggersIncomeTax = this.TriggersIncomeTax || false;
    this.TriggersATP = this.TriggersATP || false;
    this.EarnsHolidayCompensation = this.EarnsHolidayCompensation || false;
    this.IsInternal = this.IsInternal || false;
    this.InformationOnly = this.InformationOnly || false;
    this.IsReportableIncome = this.IsReportableIncome || false;
    this.DisplayDates = this.DisplayDates || false;
  }
}

export class BalanceAdjustment implements IBalanceAdjustment {
  Id: number;
  UserEmploymentId: number;
  SalaryStatementId?: number;
  Created: Date;
  EffectPerDate: Date;
  IsSpending: boolean;
  Amount: number;
  Days: number;
  Hours: number;
  TaxAmount: number;
  NetAmount: number;
  IsDraft: boolean;
  BalancePeriodId?: number;
  BaseAmount?: number;
  BalanceDefinitionId: number;
  SecondaryAmount?: number;
  SecondaryDays?: number;
  TertiaryAmount: number;
  SpecialIdentifier?: string;
  BatchPayoutDate: Date;
  IsReverted: boolean;
  SalaryStatement?: ISalaryStatement;
  UserEmployment?: IUserEmployment;
  BalancePeriod?: IBalancePeriod;
  BalanceDefinition?: IBalanceDefinition;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.UserEmploymentId = this.UserEmploymentId || 0;
    this.Created = this.Created || null;
    this.EffectPerDate = this.EffectPerDate || null;
    this.IsSpending = this.IsSpending || false;
    this.Amount = this.Amount || 0;
    this.Days = this.Days || 0;
    this.Hours = this.Hours || 0;
    this.TaxAmount = this.TaxAmount || 0;
    this.NetAmount = this.NetAmount || 0;
    this.IsDraft = this.IsDraft || false;
    this.BalanceDefinitionId = this.BalanceDefinitionId || 0;
    this.TertiaryAmount = this.TertiaryAmount || 0;
    this.BatchPayoutDate = this.BatchPayoutDate || null;
    this.IsReverted = this.IsReverted || false;
  }
}

export class SalaryBatchStatus implements ISalaryBatchStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class SalaryPeriod implements ISalaryPeriod {
  SalaryCycle?: ISalaryCycle;
  Id: number;
  StartDate: Date;
  EndDate: Date;
  SalaryCycleId: number;
  SuggestedPayoutDate?: Date;
  FriendlyName?: string;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.StartDate = this.StartDate || null;
    this.EndDate = this.EndDate || null;
    this.SalaryCycleId = this.SalaryCycleId || 0;
  }
}

export class SalaryBatchValidationIssue implements ISalaryBatchValidationIssue {
  Id: number;
  SalaryBatchId: number;
  UserEmploymentId?: number;
  IsError: boolean;
  Description?: string;
  EmployeeName?: string;
  MessageKey?: string;
  MessageParameters?: string;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.SalaryBatchId = this.SalaryBatchId || 0;
    this.IsError = this.IsError || false;
  }
}

export class BalancePeriod implements IBalancePeriod {
  Id: number;
  BalanceId: number;
  EarningStartDate: Date;
  EarningEndDate: Date;
  SpendingStartDate: Date;
  SpendingEndDate: Date;
  SpecialIdentifier?: string;
  BalanceDefinition?: IBalanceDefinition;
  Name?: string;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.BalanceId = this.BalanceId || 0;
    this.EarningStartDate = this.EarningStartDate || null;
    this.EarningEndDate = this.EarningEndDate || null;
    this.SpendingStartDate = this.SpendingStartDate || null;
    this.SpendingEndDate = this.SpendingEndDate || null;
  }
}

export class BalanceDefinition implements IBalanceDefinition {
  Id: number;
  CompanyId?: number;
  CountryId: number;
  Name?: string;
  SpecialIdentifier?: string;
  IsConfigurable: boolean;
  AmountEnabled: boolean;
  TaxEnabled: boolean;
  SecondaryAmountEnabled: boolean;
  TertiaryAmountEnabled: boolean;
  DaysEnabled: boolean;
  SecondaryDaysEnabled: boolean;
  HoursEnabled: boolean;
  BaseAmountEnabled: boolean;
  HasPeriods: boolean;
  BalancePeriods?: IBalancePeriod[];

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.CountryId = this.CountryId || 0;
    this.IsConfigurable = this.IsConfigurable || false;
    this.AmountEnabled = this.AmountEnabled || false;
    this.TaxEnabled = this.TaxEnabled || false;
    this.SecondaryAmountEnabled = this.SecondaryAmountEnabled || false;
    this.TertiaryAmountEnabled = this.TertiaryAmountEnabled || false;
    this.DaysEnabled = this.DaysEnabled || false;
    this.SecondaryDaysEnabled = this.SecondaryDaysEnabled || false;
    this.HoursEnabled = this.HoursEnabled || false;
    this.BaseAmountEnabled = this.BaseAmountEnabled || false;
    this.HasPeriods = this.HasPeriods || false;
  }
}

export class CompanyAccountView implements ICompanyAccountView {
  Id: number;
  Name?: string;
  VATRegistrationNumber?: string;
  AccountId?: number;
  MainContactCompanyUserId?: number;
  MainContactFullName?: string;
  ContactEmail?: string;
  InvoiceEmail?: string;
  AccountComment?: string;
  CountryId: number;
  CountryCode?: string;
  EmployeeCount?: number;
  LastFinalized?: Date;
  LastFinalizedFullName?: string;
  NextDeadline?: Date;
  HasBatchesForApproval: boolean;
  InvoiceTypeId?: number;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.CountryId = this.CountryId || 0;
    this.HasBatchesForApproval = this.HasBatchesForApproval || false;
  }
}

export class AccountCompanyCreationRequest implements IAccountCompanyCreationRequest {
  VatRegistrationNumber?: string;
  SecondaryVatRegistrationNumber?: string;
  CompanyName?: string;
  PackageLevel: number;
  CopySalaryAndTimeEntryTypesFromCompanyId?: number;
  CopyTemplatesFromCompanyId?: number;
  DefaultRoleId?: number;

  setDefaultValue?(): void {
    this.PackageLevel = this.PackageLevel || 0;
  }
}

export class AccountCommentRequest implements IAccountCommentRequest {
  Comment?: string;

  setDefaultValue?(): void {}
}

export class UserCompanyAccess implements IUserCompanyAccess {
  CompanyId: number;
  UserId: number;
  CompanyUserId?: number;
  FullName?: string;
  CompanyName?: string;
  RoleId: number;
  RoleName?: string;
  IsPaymentApprover: boolean;

  setDefaultValue?(): void {
    this.CompanyId = this.CompanyId || 0;
    this.UserId = this.UserId || 0;
    this.RoleId = this.RoleId || 0;
    this.IsPaymentApprover = this.IsPaymentApprover || false;
  }
}

export class UserAccessPermissionsRequest implements IUserAccessPermissionsRequest {
  UserId: number;
  CompanyId: number;
  RoleId: number;
  isPaymentApprover: boolean;

  setDefaultValue?(): void {
    this.UserId = this.UserId || 0;
    this.CompanyId = this.CompanyId || 0;
    this.RoleId = this.RoleId || 0;
    this.isPaymentApprover = this.isPaymentApprover || false;
  }
}

export class BatchOperationResponse_CompanyUser_UserAccessPermissionsRequest
  implements IBatchOperationResponse_CompanyUser_UserAccessPermissionsRequest {
  ProcessedItems?: ICompanyUser[];
  Errors?: any;
  RejectedItems?: IRejectedItemContainer_CompanyUser_UserAccessPermissionsRequest[];
  HasErrors: boolean;

  setDefaultValue?(): void {
    this.HasErrors = this.HasErrors || false;
  }
}

export class RejectedItemContainer_CompanyUser_UserAccessPermissionsRequest
  implements IRejectedItemContainer_CompanyUser_UserAccessPermissionsRequest {
  Item?: IUserAccessPermissionsRequest;
  Reason?: string;

  setDefaultValue?(): void {}
}

export class ApiUser implements IApiUser {
  SignOnToken?: ISignOnToken;
  RefreshToken: string;
  Id: number;
  IdentityNumber?: string;
  HasBackendAccess: boolean;
  ModuleIds?: number[];
  MustChangePassword: boolean;
  FullName?: string;
  AccountRoleId?: number;
  IsFirstLogin: boolean;
  ShowUpdateNotification: boolean;
  ShowUrgentNotification: boolean;
  CurrentSystemAlias?: ISystemAlias;
  CurrentSystemName?: string;
  CurrentSystemAliasIdentifier?: string;
  IsIntectSupportUser: boolean;
  IsAccountMember: boolean;
  IsAccountAdmin: boolean;
  IsCurrentAccountMember: boolean;
  IsCurrentAccountAdmin: boolean;
  CurrentCompany?: ICompany;
  ManagerForUserEmploymentIds?: number[];
  Identity?: IIIdentity;
  CurrentCompanyId: number;
  UserAccountId?: number;
  CompanyAccountId?: number;
  CurrentCountryId: number;
  CurrentRole?: IRole;
  CurrentCompanyUser?: ICompanyUser;
  Modules?: IModule[];
  IsPaymentApprover: boolean;

  setDefaultValue?(): void {
    this.RefreshToken = this.RefreshToken || '';
    this.Id = this.Id || 0;
    this.HasBackendAccess = this.HasBackendAccess || false;
    this.MustChangePassword = this.MustChangePassword || false;
    this.IsFirstLogin = this.IsFirstLogin || false;
    this.ShowUpdateNotification = this.ShowUpdateNotification || false;
    this.ShowUrgentNotification = this.ShowUrgentNotification || false;
    this.IsIntectSupportUser = this.IsIntectSupportUser || false;
    this.IsAccountMember = this.IsAccountMember || false;
    this.IsAccountAdmin = this.IsAccountAdmin || false;
    this.IsCurrentAccountMember = this.IsCurrentAccountMember || false;
    this.IsCurrentAccountAdmin = this.IsCurrentAccountAdmin || false;
    this.CurrentCompanyId = this.CurrentCompanyId || 0;
    this.CurrentCountryId = this.CurrentCountryId || 0;
    this.IsPaymentApprover = this.IsPaymentApprover || false;
  }
}

export class SignOnToken implements ISignOnToken {
  Expires: Date;
  Language?: ILanguage;
  IsExpired: boolean;
  Id: number;
  UserId: number;
  Token?: string;
  Created: Date;
  LastRefreshed: Date;
  LastPersisted: Date;
  Type: number;
  Username?: string;
  CompanyId: number;
  LanguageId: number;
  SessionLength?: number;
  CountryId: number;
  SiteIdentifier?: string;
  CompanyAccountId?: number;
  UserAccountId?: number;
  SystemAliasId: number;

  setDefaultValue?(): void {
    this.Expires = this.Expires || null;
    this.IsExpired = this.IsExpired || false;
    this.Id = this.Id || 0;
    this.UserId = this.UserId || 0;
    this.Created = this.Created || null;
    this.LastRefreshed = this.LastRefreshed || null;
    this.LastPersisted = this.LastPersisted || null;
    this.Type = this.Type || 0;
    this.CompanyId = this.CompanyId || 0;
    this.LanguageId = this.LanguageId || 0;
    this.CountryId = this.CountryId || 0;
    this.SystemAliasId = this.SystemAliasId || 0;
  }
}

export class SystemAlias implements ISystemAlias {
  ReportLogoName?: string;
  PayslipLogoName?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class IIdentity implements IIIdentity {
  Name?: string;
  AuthenticationType?: string;
  IsAuthenticated: boolean;

  setDefaultValue?(): void {
    this.IsAuthenticated = this.IsAuthenticated || false;
  }
}

export class ChangePasswordRequest implements IChangePasswordRequest {
  CurrentPassword?: string;
  NewPassword?: string;

  setDefaultValue?(): void {}
}

export class OkResult implements IOkResult {
  Request?: any;

  setDefaultValue?(): void {}
}

export class ValidatePasswordRequest implements IValidatePasswordRequest {
  Password?: string;

  setDefaultValue?(): void {}
}

export class ResetPasswordRequest implements IResetPasswordRequest {
  IdentityNumber?: string;
  Email?: string;

  setDefaultValue?(): void {}
}

export class BillingUsage implements IBillingUsage {
  Id: number;
  AccountId?: number;
  ModuleId?: number;
  BillingDate?: Date;
  Units: number;
  AmountPerUnit: number;
  Amount: number;
  CompanyId?: number;
  SalaryBatchId?: number;
  SalaryStatementId?: number;
  PaymentId?: number;
  UserEmploymentId?: number;
  CompanyUserId?: number;
  Created: Date;
  PackageId?: number;
  BillingPrincipleId: number;
  Description?: string;
  InternalComment?: string;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.Units = this.Units || 0;
    this.AmountPerUnit = this.AmountPerUnit || 0;
    this.Amount = this.Amount || 0;
    this.Created = this.Created || null;
    this.BillingPrincipleId = this.BillingPrincipleId || 0;
  }
}

export class BalancesRequest implements IBalancesRequest {
  BalanceDefinitionId: number;
  BalancePeriodId?: number;
  PerDate: Date;

  setDefaultValue?(): void {
    this.BalanceDefinitionId = this.BalanceDefinitionId || 0;
    this.PerDate = this.PerDate || null;
  }
}

export class GetBalancesEmployeeViewByDate_Result implements IGetBalancesEmployeeViewByDate_Result {
  UserEmploymentId: number;
  IdentityNumber?: string;
  FullName?: string;
  BalanceName?: string;
  SpecialIdentifier?: string;
  BalancePeriodId: number;
  PeriodIdentifier?: string;
  PeriodName?: string;
  EarningStartDate?: Date;
  EarningEndDate?: Date;
  SpendingStartDate?: Date;
  SpendingEndDate?: Date;
  BaseAmount?: number;
  AmountEarned?: number;
  AmountSpent?: number;
  AmountTotal?: number;
  TaxAmountTotal?: number;
  NetAmountTotal?: number;
  DaysEarned?: number;
  DaysSpent?: number;
  DaysTotal?: number;
  HoursEarned?: number;
  HoursSpent?: number;
  HoursTotal?: number;
  SecondaryDaysEarned?: number;
  SecondaryDaysSpent?: number;
  CurrentBalanceSecondaryDays?: number;
  SecondaryAmountTotal?: number;
  TertiaryAmountTotal?: number;
  ExternalReference?: string;
  DepartmentId?: number;
  DepartmentName?: string;
  DepartmentExternalReference?: string;
  BasedOnTemplateId?: number;
  BasedOnTemplateName?: string;
  VacationTypeId: number;

  setDefaultValue?(): void {
    this.UserEmploymentId = this.UserEmploymentId || 0;
    this.BalancePeriodId = this.BalancePeriodId || 0;
    this.VacationTypeId = this.VacationTypeId || 0;
  }
}

export class BalanceAdjustmentRequest implements IBalanceAdjustmentRequest {
  BalanceDefinitionId: number;
  BalancePeriodId?: number;
  PerDate: Date;
  Details?: IBalanceAdjustmentRequestDetail[];

  setDefaultValue?(): void {
    this.BalanceDefinitionId = this.BalanceDefinitionId || 0;
    this.PerDate = this.PerDate || null;
  }
}

export class BalanceAdjustmentRequestDetail implements IBalanceAdjustmentRequestDetail {
  UserEmploymentId: number;
  AdjustDays: number;
  SpendDays: number;
  MoveDays: number;
  PayoutDays: number;
  AdjustHours: number;
  SpendHours: number;
  PayoutHours: number;

  setDefaultValue?(): void {
    this.UserEmploymentId = this.UserEmploymentId || 0;
    this.AdjustDays = this.AdjustDays || 0;
    this.SpendDays = this.SpendDays || 0;
    this.MoveDays = this.MoveDays || 0;
    this.PayoutDays = this.PayoutDays || 0;
    this.AdjustHours = this.AdjustHours || 0;
    this.SpendHours = this.SpendHours || 0;
    this.PayoutHours = this.PayoutHours || 0;
  }
}

export class SimpleCompany implements ISimpleCompany {
  Id: number;
  Name?: string;
  VatRegistrationNumber?: string;
  SecondaryVatRegistrationNumber?: string;
  RoleId: number;
  AccountId?: number;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.RoleId = this.RoleId || 0;
  }
}

export class CompanyCreationRequest implements ICompanyCreationRequest {
  VatRegistrationNumber?: string;
  SecondaryVatRegistrationNumber?: string;
  CompanyName?: string;
  ResponsibleUserUsername?: string;
  ResponsibleUserIdentityNumber?: string;
  ResponsibleUserFullName?: string;
  ResponsibleUserEmail?: string;
  PhoneNumber?: string;
  LanguageId: number;
  KnowsIntectFrom?: string;
  NewUserPassword?: string;
  SubscribeToNewsLetter: boolean;
  AcceptStartupOffer: boolean;
  PackageLevel: number;
  AccountId?: number;
  NewAccountName?: string;
  AccountTypeId?: number;
  RequestingUserId?: number;

  setDefaultValue?(): void {
    this.LanguageId = this.LanguageId || 0;
    this.SubscribeToNewsLetter = this.SubscribeToNewsLetter || false;
    this.AcceptStartupOffer = this.AcceptStartupOffer || false;
    this.PackageLevel = this.PackageLevel || 0;
  }
}

export class VatCompany implements IVatCompany {
  VAT?: string;
  Name?: string;
  Address?: string;
  Zipcode?: string;
  City?: string;
  protected?: boolean;
  Phone?: string;
  Email?: string;
  Fax?: string;
  Startdate?: string;
  Enddate?: string;
  Employees?: string;
  Addressco?: string;
  Industrycode?: number;
  Industrydesc?: string;
  Companycode?: number;
  Companydesc?: string;
  Creditstartdate?: string;
  Creditstatus?: number;
  Creditbankrupt?: boolean;
  Owners?: IApiOwners[];
  Productionunits?: any[];
  T?: number;
  Version?: number;
  ExistsInIntect: boolean;
  CountryId?: number;

  setDefaultValue?(): void {
    this.ExistsInIntect = this.ExistsInIntect || false;
  }
}

export class ApiOwners implements IApiOwners {
  Name?: string;

  setDefaultValue?(): void {}
}

export class AcceptTermsRequest implements IAcceptTermsRequest {
  Password?: string;

  setDefaultValue?(): void {}
}

export class AddUserToCompanyRequest implements IAddUserToCompanyRequest {
  IdentityNumber?: string;
  LanguageId?: number;
  Details?: ICompanyUser;
  EmploymentTemplateId?: number;
  HireDate?: Date;
  Title?: string;
  ExternalReference?: string;
  SecondaryExternalReference?: string;
  DepartmentId?: number;
  ExpireDate?: Date;
  PensionProviderId?: number;
  PensionEmployeeSharePercentage?: number;
  PensionEmployeeFixedAmount?: number;
  PensionCompanySharePercentage?: number;
  PensionCompanyFixedAmount?: number;
  PensionPolicyNumber?: string;
  PensionProviderBankRegNo?: string;
  PensionProviderBankAccountNo?: string;
  ManagerCompanyUserId?: number;
  SeniorityDate?: Date;
  IsAccountUser: boolean;
  DefaultRate?: number;
  TaxCardTypeId?: number;
  IncomeTypeId?: number;
  UseContinuousVacationReporting: boolean;
  TaxRate?: number;
  TaxDeduction?: number;
  TaxCardFreeAmount?: number;
  ApplyDanishRulesInGreenland: boolean;
  TaxTableNumber?: number;
  TaxColumnNumber?: number;

  setDefaultValue?(): void {
    this.IsAccountUser = this.IsAccountUser || false;
    this.UseContinuousVacationReporting = this.UseContinuousVacationReporting || false;
    this.ApplyDanishRulesInGreenland = this.ApplyDanishRulesInGreenland || false;
  }
}

export class UserCreationData implements IUserCreationData {
  User?: IUser;
  CompanyUser?: ICompanyUser;
  Employment?: IUserEmployment;
  NewUserPassword?: string;

  setDefaultValue?(): void {}
}

export class CompanyUserListRequest implements ICompanyUserListRequest {
  IncludeInactive: boolean;
  IncludeHidden: boolean;
  SortBySurname: boolean;
  LimitToDepartmentId?: number;
  LimitToTemplateId?: number;
  LimitToSalaryCycleId?: number;
  LimitToPayrollBatchNumber?: number;
  LimitToUpdatedSince?: Date;

  setDefaultValue?(): void {
    this.IncludeInactive = this.IncludeInactive || false;
    this.IncludeHidden = this.IncludeHidden || false;
    this.SortBySurname = this.SortBySurname || false;
  }
}

export class SimpleCompanyUser implements ISimpleCompanyUser {
  Id: number;
  IsActive: boolean;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  FullName?: string;
  RoleId: number;
  UserId: number;
  IdentityNumber?: string;
  ExternalReferences?: string[];
  UserEmploymentIds?: number[];
  PersonalEmail?: string;
  CompanyEmail?: string;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.IsActive = this.IsActive || false;
    this.RoleId = this.RoleId || 0;
    this.UserId = this.UserId || 0;
  }
}

export class BatchOperationResponse_UserCreationData_AddUserToCompanyRequest
  implements IBatchOperationResponse_UserCreationData_AddUserToCompanyRequest {
  ProcessedItems?: IUserCreationData[];
  Errors?: any;
  RejectedItems?: IRejectedItemContainer_UserCreationData_AddUserToCompanyRequest[];
  HasErrors: boolean;

  setDefaultValue?(): void {
    this.HasErrors = this.HasErrors || false;
  }
}

export class RejectedItemContainer_UserCreationData_AddUserToCompanyRequest
  implements IRejectedItemContainer_UserCreationData_AddUserToCompanyRequest {
  Item?: IAddUserToCompanyRequest;
  Reason?: string;

  setDefaultValue?(): void {}
}

export class BatchOperationResponse_CompanyUser_CompanyUser implements IBatchOperationResponse_CompanyUser_CompanyUser {
  ProcessedItems?: ICompanyUser[];
  Errors?: any;
  RejectedItems?: IRejectedItemContainer_CompanyUser_CompanyUser[];
  HasErrors: boolean;

  setDefaultValue?(): void {
    this.HasErrors = this.HasErrors || false;
  }
}

export class RejectedItemContainer_CompanyUser_CompanyUser implements IRejectedItemContainer_CompanyUser_CompanyUser {
  Item?: ICompanyUser;
  Reason?: string;

  setDefaultValue?(): void {}
}

export class UserEmploymentView implements IUserEmploymentView {
  IdentityNumberFormatted?: string;
  HasDummyIdentityNumber: boolean;
  IsFemale: boolean;
  HasWorkHoursPerWeekDay: boolean;
  SalaryCycle?: ISalaryCycle;
  Updated: Date;
  UserEmploymentId: number;
  IdentityNumber?: string;
  CompanyUserId: number;
  CompanyId: number;
  RoleId: number;
  IsActive: boolean;
  FullName?: string;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  TelMobile?: string;
  TelFixed?: string;
  BankRegNo?: string;
  CompanyEmail?: string;
  PersonalEmail?: string;
  BankAccountNo?: string;
  BicSwiftNo?: string;
  IbanNo?: string;
  IsPaymentApprover: boolean;
  Initials?: string;
  HireDate: Date;
  ExpireDate?: Date;
  VacationProviderId?: number;
  VacationExtraPercentage?: number;
  BasedOnTemplateId?: number;
  Title?: string;
  SalaryCycleId: number;
  ExternalReference?: string;
  PensionBaseCalculationMethodId: number;
  IsAtpTempWorker: boolean;
  DepartmentId?: number;
  IncomeTypeId: number;
  WorksElsewhere: boolean;
  PensionEmployeeShare?: number;
  PensionCompanyShare?: number;
  PensionProviderId?: number;
  PensionProviderBankRegNo?: string;
  PensionProviderBankAccountNo?: string;
  PensionPolicyNumber?: string;
  PensionEmployeeAmount?: number;
  PensionCompanyAmount?: number;
  Pension2BaseCalculationMethodId: number;
  Pension2ProviderId?: number;
  Pension2ProviderBankRegNo?: string;
  Pension2ProviderBankAccountNo?: string;
  Pension2EmployeeShare?: number;
  Pension2CompanyShare?: number;
  Pension2EmployeeAmount?: number;
  Pension2CompanyAmount?: number;
  VacationTypeId: number;
  LastStatementEndDate?: Date;
  VacationReduceSalary: boolean;
  IsTerminated: boolean;
  VacationSupplementPayoutMonth: number;
  AddressId: number;
  AddressLine1?: string;
  AddressLine2?: string;
  PostalCode?: string;
  City?: string;
  CountryId?: number;
  TaxCardRequested?: Date;
  UserId: number;
  Username?: string;
  DefaultCompanyId?: number;
  LanguageId: number;
  CompanyName?: string;
  IllnessReducesSalary: boolean;
  EmploymentCreated: Date;
  CompanyUserCreated: Date;
  UserCreated: Date;
  DepartmentName?: string;
  DepartmentExternalReference?: string;
  DateOfBirth?: Date;
  IsPendingRecalc: boolean;
  ManagerCompanyUserId?: number;
  ManagerName?: string;
  LanguageCode?: string;
  BasedOnTemplateName?: string;
  CountryCode?: string;
  PreferredTaxCardTypeId: number;
  SeniorityDate?: Date;
  AutoDetermineBankAccount: boolean;
  CompanyCountryId: number;
  DefaultRate?: number;
  MunicipalityId?: number;
  CompanyAccountId?: number;
  UserAccountId?: number;
  UseContinuousVacationReporting: boolean;
  TaxTableNumber?: number;
  TaxColumnNumber?: number;
  AdHocTaxRateOverride?: number;
  VacationDaysPerYear?: number;
  VacationTerminationDate?: Date;
  CompanyUserUpdated?: Date;
  UserEmploymentUpdated?: Date;
  LastUpdated?: Date;
  StandardHoursPerWeek?: number;
  StandardWorkDaysPerWeek?: number;
  OmitVacationReporting: boolean;
  PensionIsNotTaxDeductible: boolean;
  Pension2IsNotTaxDeductible: boolean;
  AllowExtendedTaxCardDeductions: boolean;
  PensionStartDate?: Date;
  PensionEndDate?: Date;
  Pension2StartDate?: Date;
  Pension2EndDate?: Date;
  PensionFixedAmountIsPerDay: boolean;
  Pension2FixedAmountIsPerDay: boolean;
  AdvanceVacationDays?: number;
  WorkplaceIdentifier?: string;
  AdditionalVacationSpentFirst: boolean;
  AdditionalVacationTerminationMethod?: number;
  AtpHours?: number;
  FlexEarningPercentage: number;
  FlexBaseCalculationMethodId: number;
  StandardWorkHoursMonday?: number;
  StandardWorkHoursTuesday?: number;
  StandardWorkHoursWednesday?: number;
  StandardWorkHoursThursday?: number;
  StandardWorkHoursFriday?: number;
  StandardWorkHoursSaturday?: number;
  StandardWorkHoursSunday?: number;

  setDefaultValue?(): void {
    this.HasDummyIdentityNumber = this.HasDummyIdentityNumber || false;
    this.IsFemale = this.IsFemale || false;
    this.HasWorkHoursPerWeekDay = this.HasWorkHoursPerWeekDay || false;
    this.Updated = this.Updated || null;
    this.UserEmploymentId = this.UserEmploymentId || 0;
    this.CompanyUserId = this.CompanyUserId || 0;
    this.CompanyId = this.CompanyId || 0;
    this.RoleId = this.RoleId || 0;
    this.IsActive = this.IsActive || false;
    this.IsPaymentApprover = this.IsPaymentApprover || false;
    this.HireDate = this.HireDate || null;
    this.SalaryCycleId = this.SalaryCycleId || 0;
    this.PensionBaseCalculationMethodId = this.PensionBaseCalculationMethodId || 0;
    this.IsAtpTempWorker = this.IsAtpTempWorker || false;
    this.IncomeTypeId = this.IncomeTypeId || 0;
    this.WorksElsewhere = this.WorksElsewhere || false;
    this.Pension2BaseCalculationMethodId = this.Pension2BaseCalculationMethodId || 0;
    this.VacationTypeId = this.VacationTypeId || 0;
    this.VacationReduceSalary = this.VacationReduceSalary || false;
    this.IsTerminated = this.IsTerminated || false;
    this.VacationSupplementPayoutMonth = this.VacationSupplementPayoutMonth || 0;
    this.AddressId = this.AddressId || 0;
    this.UserId = this.UserId || 0;
    this.LanguageId = this.LanguageId || 0;
    this.IllnessReducesSalary = this.IllnessReducesSalary || false;
    this.EmploymentCreated = this.EmploymentCreated || null;
    this.CompanyUserCreated = this.CompanyUserCreated || null;
    this.UserCreated = this.UserCreated || null;
    this.IsPendingRecalc = this.IsPendingRecalc || false;
    this.PreferredTaxCardTypeId = this.PreferredTaxCardTypeId || 0;
    this.AutoDetermineBankAccount = this.AutoDetermineBankAccount || false;
    this.CompanyCountryId = this.CompanyCountryId || 0;
    this.UseContinuousVacationReporting = this.UseContinuousVacationReporting || false;
    this.OmitVacationReporting = this.OmitVacationReporting || false;
    this.PensionIsNotTaxDeductible = this.PensionIsNotTaxDeductible || false;
    this.Pension2IsNotTaxDeductible = this.Pension2IsNotTaxDeductible || false;
    this.AllowExtendedTaxCardDeductions = this.AllowExtendedTaxCardDeductions || false;
    this.PensionFixedAmountIsPerDay = this.PensionFixedAmountIsPerDay || false;
    this.Pension2FixedAmountIsPerDay = this.Pension2FixedAmountIsPerDay || false;
    this.AdditionalVacationSpentFirst = this.AdditionalVacationSpentFirst || false;
    this.FlexEarningPercentage = this.FlexEarningPercentage || 0;
    this.FlexBaseCalculationMethodId = this.FlexBaseCalculationMethodId || 0;
  }
}

export class BatchOperationResponse_UserEmployment_UserEmployment
  implements IBatchOperationResponse_UserEmployment_UserEmployment {
  ProcessedItems?: IUserEmployment[];
  Errors?: any;
  RejectedItems?: IRejectedItemContainer_UserEmployment_UserEmployment[];
  HasErrors: boolean;

  setDefaultValue?(): void {
    this.HasErrors = this.HasErrors || false;
  }
}

export class RejectedItemContainer_UserEmployment_UserEmployment
  implements IRejectedItemContainer_UserEmployment_UserEmployment {
  Item?: IUserEmployment;
  Reason?: string;

  setDefaultValue?(): void {}
}

export class EmploymentsListRequest implements IEmploymentsListRequest {
  IncludeInactive: boolean;
  SortBySurname: boolean;
  LimitToDepartmentId?: number;
  LimitToTemplateId?: number;
  LimitToSalaryCycleId?: number;
  LimitToPayrollBatchNumber?: number;
  LimitToUpdatedSince?: Date;

  setDefaultValue?(): void {
    this.IncludeInactive = this.IncludeInactive || false;
    this.SortBySurname = this.SortBySurname || false;
  }
}

export class TemplateCreationRequest implements ITemplateCreationRequest {
  Name?: string;
  BasedOnEmploymentId: number;

  setDefaultValue?(): void {
    this.BasedOnEmploymentId = this.BasedOnEmploymentId || 0;
  }
}

export class CompanyHolidayView implements ICompanyHolidayView {
  CompanyId: number;
  CompanyName?: string;
  CountryId: number;
  CompanySpecificHolidayId?: number;
  MasterHolidayId?: number;
  Date: Date;
  ConsiderHoliday?: boolean;
  DisablesBankPayments: boolean;
  Description?: string;
  IsOverridden?: boolean;

  setDefaultValue?(): void {
    this.CompanyId = this.CompanyId || 0;
    this.CountryId = this.CountryId || 0;
    this.Date = this.Date || null;
    this.DisablesBankPayments = this.DisablesBankPayments || false;
  }
}

export class HolidayRequest implements IHolidayRequest {
  Date: Date;
  Description?: string;
  YearsToRepeat: number;

  setDefaultValue?(): void {
    this.Date = this.Date || null;
    this.YearsToRepeat = this.YearsToRepeat || 0;
  }
}

export class PreviewRequest implements IPreviewRequest {
  Size: number;
  FileBase64?: string;
  FileBytes?: string;
  MappingId?: number;
  Mapping?: IImportMapping;

  setDefaultValue?(): void {
    this.Size = this.Size || 0;
  }
}

export class ImportMapping implements IImportMapping {
  Id: number;
  CompanyId?: number;
  Name?: string;
  StartRow: number;
  CountryId?: number;
  TypeId: number;
  ImportMappingColumns?: IImportMappingColumn[];

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.StartRow = this.StartRow || 0;
    this.TypeId = this.TypeId || 0;
  }
}

export class ImportMappingColumn implements IImportMappingColumn {
  Id: number;
  ImportMappingId: number;
  ColumnKey?: string;
  UserColumnNumber: number;
  SalaryTypeId?: number;
  BackendKey?: string;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.ImportMappingId = this.ImportMappingId || 0;
    this.UserColumnNumber = this.UserColumnNumber || 0;
  }
}

export class ImportHeadersRequest implements IImportHeadersRequest {
  StartRow: number;
  FileBase64?: string;
  FileBytes?: string;

  setDefaultValue?(): void {
    this.StartRow = this.StartRow || 0;
  }
}

export class UserFileColumn implements IUserFileColumn {
  UserColumnNumber: number;
  Name?: string;

  setDefaultValue?(): void {
    this.UserColumnNumber = this.UserColumnNumber || 0;
  }
}

export class ImportRequest implements IImportRequest {
  Options?: ISimpleKeyValuePair[];
  FileBase64?: string;
  FileBytes?: string;
  MappingId?: number;
  Mapping?: IImportMapping;

  setDefaultValue?(): void {}
}

export class SimpleKeyValuePair implements ISimpleKeyValuePair {
  Key?: string;
  Value?: string;

  setDefaultValue?(): void {}
}

export class ImportResponse implements IImportResponse {
  Outcome?: string;
  Errors?: IImportErrorMessage[];
  FatalMessage?: string;

  setDefaultValue?(): void {}
}

export class ImportErrorMessage implements IImportErrorMessage {
  TranslationEntity?: ITranslationEntity;
  Error: boolean;
  RowNumber: number;
  Message?: string;

  setDefaultValue?(): void {
    this.Error = this.Error || false;
    this.RowNumber = this.RowNumber || 0;
  }
}

export class TranslationEntity implements ITranslationEntity {
  Type: number;
  Key?: string;
  Extra?: string[];

  setDefaultValue?(): void {
    this.Type = this.Type || 0;
  }
}

export class ImportMappingFileRequest implements IImportMappingFileRequest {
  FileBase64?: string;
  FileBytes?: string;
  MappingId?: number;
  Mapping?: IImportMapping;

  setDefaultValue?(): void {}
}

export class TemplateRequest implements ITemplateRequest {
  CountryId: number;
  Parameters?: any[];
  IsPrefilled: boolean;
  MappingId?: number;
  Mapping?: IImportMapping;

  setDefaultValue?(): void {
    this.CountryId = this.CountryId || 0;
    this.IsPrefilled = this.IsPrefilled || false;
  }
}

export class Dimension implements IDimension {
  ExternalSystemPropertyName?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class NameObject implements INameObject {
  DisplayName?: string;
  Value?: string;

  setDefaultValue?(): void {}
}

export class WorkBookDimension implements IWorkBookDimension {
  ExternalSystemPropertyName?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class Feedback implements IFeedback {
  Message?: string;

  setDefaultValue?(): void {}
}

export class StatisticsDiscoCode implements IStatisticsDiscoCode {
  Id: number;
  Code: number;
  Name?: string;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.Code = this.Code || 0;
  }
}

export class NameInformation implements INameInformation {
  Name?: string;
  Id: number;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
  }
}

export class ModuleCompanyView implements IModuleCompanyView {
  CompanyId: number;
  CompanyName?: string;
  ModuleId: number;
  PackageId?: number;
  ModuleName?: string;
  ModuleDescription?: string;
  PackageName?: string;
  PackageDescription?: string;
  ModulePrice?: number;
  ModuleBillingPrinciple?: string;
  ModuleBillingPrincipleId?: number;
  PackagePrice?: number;
  PackageBillingPrinciple?: string;
  PackageBillingPrincipleId?: number;
  IsEnabled?: boolean;
  LanguageId: number;
  InfoOnly: boolean;
  FreeUntilDate?: Date;
  ScheduledDeactivationDate?: Date;
  CompanyCurrentPackageLevel: number;
  SortKey?: string;
  ModuleKey?: string;
  PackageKey?: string;
  ModulePriceBeforeOverride?: number;
  IsOverridden?: boolean;

  setDefaultValue?(): void {
    this.CompanyId = this.CompanyId || 0;
    this.ModuleId = this.ModuleId || 0;
    this.LanguageId = this.LanguageId || 0;
    this.InfoOnly = this.InfoOnly || false;
    this.CompanyCurrentPackageLevel = this.CompanyCurrentPackageLevel || 0;
  }
}

export class Report implements IReport {
  AreaId: number;
  Parameters?: string;
  SupportedFormats?: string[];
  ExcludeColumns?: string[];
  ExcludeColumnsPdf?: string[];
  PortraitOrientation: boolean;
  HaveSums: boolean;
  RelevantCountries?: ICountry[];
  RequiredRoleId: number;
  BackendKey?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.AreaId = this.AreaId || 0;
    this.PortraitOrientation = this.PortraitOrientation || false;
    this.HaveSums = this.HaveSums || false;
    this.RequiredRoleId = this.RequiredRoleId || 0;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class ReportRequest implements IReportRequest {
  ReportId: number;
  ParameterValues?: IReportParameterValue[];
  OutputFormat?: string;

  setDefaultValue?(): void {
    this.ReportId = this.ReportId || 0;
  }
}

export class ReportParameterValue implements IReportParameterValue {
  Key?: string;
  Value?: string;

  setDefaultValue?(): void {}
}

export class SalaryBatchView implements ISalaryBatchView {
  Id: number;
  SalaryBatchNumber: number;
  CompanyId: number;
  SalaryPeriodId: number;
  Created: Date;
  StatusId: number;
  Approved?: Date;
  RejectionReason?: string;
  Rejected?: Date;
  EIncomeTransferDate?: Date;
  Message?: string;
  PayoutDate: Date;
  ApprovedUserId?: number;
  RejectedUserId?: number;
  CreatedUserId?: number;
  HasErrors: boolean;
  HasWarnings: boolean;
  ValidationStatusCode?: string;
  NetsPaymentsProcessingDate?: Date;
  IsTermination: boolean;
  EIncomeZeroReport: boolean;
  PaymentMethodSalary: number;
  PaymentMethodTax: number;
  PaymentMethodPension: number;
  EIncomeReruns: number;
  PayslipsArchived: boolean;
  LimitToSpecificEmployees: boolean;
  CreatedByUserFullName?: string;
  ApprovedByUserFullName?: string;
  RejectedByUserFullName?: string;
  FinalizedByUserFullName?: string;
  PeriodStartDate: Date;
  PeriodEndDate: Date;
  SalaryCycleId: number;
  StatusName?: string;
  LanguageId: number;
  PayoutAllFlex: boolean;
  PayoutNewFlex: boolean;
  Description?: string;
  IsImport: boolean;
  PaymentMethodPublicPension: number;
  PaymentMethodVacationProvider: number;
  AllowPreliminaryPayslips: boolean;
  EBoksDeliveryEnabled: boolean;
  HasNemKontoPayments: boolean;
  IsAutomated: boolean;
  Finalized?: Date;
  ToArchive: boolean;
  FromDateOverride?: Date;
  ToDateOverride?: Date;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.SalaryBatchNumber = this.SalaryBatchNumber || 0;
    this.CompanyId = this.CompanyId || 0;
    this.SalaryPeriodId = this.SalaryPeriodId || 0;
    this.Created = this.Created || null;
    this.StatusId = this.StatusId || 0;
    this.PayoutDate = this.PayoutDate || null;
    this.HasErrors = this.HasErrors || false;
    this.HasWarnings = this.HasWarnings || false;
    this.IsTermination = this.IsTermination || false;
    this.EIncomeZeroReport = this.EIncomeZeroReport || false;
    this.PaymentMethodSalary = this.PaymentMethodSalary || 0;
    this.PaymentMethodTax = this.PaymentMethodTax || 0;
    this.PaymentMethodPension = this.PaymentMethodPension || 0;
    this.EIncomeReruns = this.EIncomeReruns || 0;
    this.PayslipsArchived = this.PayslipsArchived || false;
    this.LimitToSpecificEmployees = this.LimitToSpecificEmployees || false;
    this.PeriodStartDate = this.PeriodStartDate || null;
    this.PeriodEndDate = this.PeriodEndDate || null;
    this.SalaryCycleId = this.SalaryCycleId || 0;
    this.LanguageId = this.LanguageId || 0;
    this.PayoutAllFlex = this.PayoutAllFlex || false;
    this.PayoutNewFlex = this.PayoutNewFlex || false;
    this.IsImport = this.IsImport || false;
    this.PaymentMethodPublicPension = this.PaymentMethodPublicPension || 0;
    this.PaymentMethodVacationProvider = this.PaymentMethodVacationProvider || 0;
    this.AllowPreliminaryPayslips = this.AllowPreliminaryPayslips || false;
    this.EBoksDeliveryEnabled = this.EBoksDeliveryEnabled || false;
    this.HasNemKontoPayments = this.HasNemKontoPayments || false;
    this.IsAutomated = this.IsAutomated || false;
    this.ToArchive = this.ToArchive || false;
  }
}

export class SalaryBatchRequest implements ISalaryBatchRequest {
  SalaryCycleId?: number;
  SalaryPeriodId?: number;
  UserEmploymentIds?: number[];
  Preview: boolean;
  EIncomeZeroReport: boolean;
  PayoutDate?: Date;
  Message?: string;
  PayoutAllFlex: boolean;
  PayoutNewFlex: boolean;
  FromDateOverride?: Date;
  ToDateOverride?: Date;

  setDefaultValue?(): void {
    this.Preview = this.Preview || false;
    this.EIncomeZeroReport = this.EIncomeZeroReport || false;
    this.PayoutAllFlex = this.PayoutAllFlex || false;
    this.PayoutNewFlex = this.PayoutNewFlex || false;
  }
}

export class EIncomeResponseLine implements IEIncomeResponseLine {
  Id: number;
  SalaryBatchId: number;
  IsError: boolean;
  RefLineNo: number;
  RefFieldNo: number;
  ResponseCode: number;
  ResponseText?: string;
  CprOrSeNo?: string;
  ReceivedOn: Date;
  ExportBatchIdentifier?: string;
  RerunNumber: number;
  SalaryBatch?: ISalaryBatch;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.SalaryBatchId = this.SalaryBatchId || 0;
    this.IsError = this.IsError || false;
    this.RefLineNo = this.RefLineNo || 0;
    this.RefFieldNo = this.RefFieldNo || 0;
    this.ResponseCode = this.ResponseCode || 0;
    this.ReceivedOn = this.ReceivedOn || null;
    this.RerunNumber = this.RerunNumber || 0;
  }
}

export class SalaryBatchTotalsApiView implements ISalaryBatchTotalsApiView {
  SalaryBatchId: number;
  SalaryTypeId: number;
  AmountSum?: number;
  LanguageId: number;
  SortOrder: number;
  Description?: string;
  SalaryBatchNumber: number;

  setDefaultValue?(): void {
    this.SalaryBatchId = this.SalaryBatchId || 0;
    this.SalaryTypeId = this.SalaryTypeId || 0;
    this.LanguageId = this.LanguageId || 0;
    this.SortOrder = this.SortOrder || 0;
    this.SalaryBatchNumber = this.SalaryBatchNumber || 0;
  }
}

export class SalaryTypeSumsByEmploymentView implements ISalaryTypeSumsByEmploymentView {
  LanguageId: number;
  SalaryBatchId: number;
  SalaryTypeId: number;
  SalaryTypeName?: string;
  UserEmploymentId: number;
  FullName?: string;
  AmountSum?: number;

  setDefaultValue?(): void {
    this.LanguageId = this.LanguageId || 0;
    this.SalaryBatchId = this.SalaryBatchId || 0;
    this.SalaryTypeId = this.SalaryTypeId || 0;
    this.UserEmploymentId = this.UserEmploymentId || 0;
  }
}

export class SalaryBatchUnitTotal implements ISalaryBatchUnitTotal {
  Name?: string;
  Key?: string;
  Amount: number;

  setDefaultValue?(): void {
    this.Amount = this.Amount || 0;
  }
}

export class SalaryBatchEmployeeTotal implements ISalaryBatchEmployeeTotal {
  UserEmploymentId: number;
  FullName?: string;
  AmountSum: number;

  setDefaultValue?(): void {
    this.UserEmploymentId = this.UserEmploymentId || 0;
    this.AmountSum = this.AmountSum || 0;
  }
}

export class ProtectedOperationRequest implements IProtectedOperationRequest {
  Password?: string;

  setDefaultValue?(): void {}
}

export class SalaryBatchPrepareFinalizationRequest implements ISalaryBatchPrepareFinalizationRequest {
  FinalizationGuid: string;

  setDefaultValue?(): void {
    this.FinalizationGuid = this.FinalizationGuid || '';
  }
}

export class SalaryBatchRejectionRequest implements ISalaryBatchRejectionRequest {
  Reason?: string;

  setDefaultValue?(): void {}
}

export class ExternalAccountingRecord implements IExternalAccountingRecord {
  Amount: number;
  AccountNumber?: string;
  EntryDate: Date;
  Description?: string;
  SalaryTypeId: number;
  SalaryTypeName?: string;
  DepartmentName?: string;
  DepartmentExternalReference?: string;
  Dimension1Value?: string;
  Dimension2Value?: string;
  Dimension3Value?: string;
  EmployeeName?: string;
  EmployeeExternalReference?: string;

  setDefaultValue?(): void {
    this.Amount = this.Amount || 0;
    this.EntryDate = this.EntryDate || null;
    this.SalaryTypeId = this.SalaryTypeId || 0;
  }
}

export class SalaryRecordView implements ISalaryRecordView {
  Id: number;
  CompanyUserId: number;
  CompanyId: number;
  SalaryTypeId: number;
  AmountPerUnit?: number;
  Units?: number;
  IsActive: boolean;
  StartDate?: Date;
  EndDate?: Date;
  Description?: string;
  UserEmploymentId: number;
  UnitTypeId?: number;
  BaseAmount?: number;
  Amount?: number;
  SortOrder: number;
  PersistenceTypeId: number;
  Dimension1Value?: string;
  Dimension2Value?: string;
  Dimension3Value?: string;
  SalaryStatementId?: number;
  EmployeeName?: string;
  CompanyName?: string;
  IdentityNumber?: string;
  Dimension1ValueId?: number;
  Dimension2ValueId?: number;
  Dimension3ValueId?: number;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.CompanyUserId = this.CompanyUserId || 0;
    this.CompanyId = this.CompanyId || 0;
    this.SalaryTypeId = this.SalaryTypeId || 0;
    this.IsActive = this.IsActive || false;
    this.UserEmploymentId = this.UserEmploymentId || 0;
    this.SortOrder = this.SortOrder || 0;
    this.PersistenceTypeId = this.PersistenceTypeId || 0;
  }
}

export class BatchOperationResponse_SalaryRecord_SalaryRecord
  implements IBatchOperationResponse_SalaryRecord_SalaryRecord {
  ProcessedItems?: ISalaryRecord[];
  Errors?: any;
  RejectedItems?: IRejectedItemContainer_SalaryRecord_SalaryRecord[];
  HasErrors: boolean;

  setDefaultValue?(): void {
    this.HasErrors = this.HasErrors || false;
  }
}

export class RejectedItemContainer_SalaryRecord_SalaryRecord
  implements IRejectedItemContainer_SalaryRecord_SalaryRecord {
  Item?: ISalaryRecord;
  Reason?: string;

  setDefaultValue?(): void {}
}

export class SimpleSalaryRecordUpdateRequest implements ISimpleSalaryRecordUpdateRequest {
  Id: number;
  Amount: number;
  Units?: number;
  AmountPerUnit?: number;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.Amount = this.Amount || 0;
  }
}

export class SimpleSalaryRecordCreationRequest implements ISimpleSalaryRecordCreationRequest {
  UserEmploymentId: number;
  SalaryTypeId: number;
  Amount?: number;
  Units?: number;
  AmountPerUnit?: number;

  setDefaultValue?(): void {
    this.UserEmploymentId = this.UserEmploymentId || 0;
    this.SalaryTypeId = this.SalaryTypeId || 0;
  }
}

export class GetSalaryRecordsOverview_Result implements IGetSalaryRecordsOverview_Result {
  UserEmploymentId: number;
  FullName?: string;
  DepartmentId?: number;
  DepartmentName?: string;
  SalaryCycleId: number;
  SalaryRecord1Id?: number;
  MultipleRecordsExist1?: boolean;
  SalaryRecord1Units?: number;
  SalaryRecord1PerUnit?: number;
  SalaryRecord1Amount?: number;
  SalaryRecord2Id?: number;
  MultipleRecordsExist2?: boolean;
  SalaryRecord2Units?: number;
  SalaryRecord2PerUnit?: number;
  SalaryRecord2Amount?: number;
  SalaryRecord3Id?: number;
  MultipleRecordsExist3?: boolean;
  SalaryRecord3Units?: number;
  SalaryRecord3PerUnit?: number;
  SalaryRecord3Amount?: number;
  SalaryRecord4Id?: number;
  MultipleRecordsExist4?: boolean;
  SalaryRecord4Units?: number;
  SalaryRecord4PerUnit?: number;
  SalaryRecord4Amount?: number;
  SalaryRecord5Id?: number;
  MultipleRecordsExist5?: boolean;
  SalaryRecord5Units?: number;
  SalaryRecord5PerUnit?: number;
  SalaryRecord5Amount?: number;
  SalaryRecord6Id?: number;
  MultipleRecordsExist6?: boolean;
  SalaryRecord6Units?: number;
  SalaryRecord6PerUnit?: number;
  SalaryRecord6Amount?: number;
  SalaryRecord7Id?: number;
  MultipleRecordsExist7?: boolean;
  SalaryRecord7Units?: number;
  SalaryRecord7PerUnit?: number;
  SalaryRecord7Amount?: number;

  setDefaultValue?(): void {
    this.UserEmploymentId = this.UserEmploymentId || 0;
    this.SalaryCycleId = this.SalaryCycleId || 0;
  }
}

export class SalaryRecordsOverviewFilterRequest implements ISalaryRecordsOverviewFilterRequest {
  EmploymentTemplateId?: number;
  SalaryCycleId?: number;
  DepartmentId?: number;
  IncludeEmployeesWithNoData: boolean;

  setDefaultValue?(): void {
    this.IncludeEmployeesWithNoData = this.IncludeEmployeesWithNoData || false;
  }
}

export class SimpleSalaryStatement implements ISimpleSalaryStatement {
  Id: number;
  UserEmploymentId: number;
  UserFullName?: string;
  PayoutAmount: number;
  UserIdentityNumber?: string;
  UserExternalReference?: string;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.UserEmploymentId = this.UserEmploymentId || 0;
    this.PayoutAmount = this.PayoutAmount || 0;
  }
}

export class SimpleSalaryBatchRecord implements ISimpleSalaryBatchRecord {
  Id: number;
  SalaryTypeId: number;
  Description?: string;
  Amount: number;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.SalaryTypeId = this.SalaryTypeId || 0;
    this.Amount = this.Amount || 0;
  }
}

export class SalaryType implements ISalaryType {
  SummaryCategory?: ISalarySummaryCategory;
  Id: number;
  CompanyId?: number;
  CategoryId?: number;
  Name?: string;
  Description?: string;
  IsVisible?: boolean;
  IsActive?: boolean;
  SalaryTypeGroupId?: number;
  UnitTypeId?: number;
  AmountPerUnit?: number;
  Units?: number;
  BaseAmount?: number;
  Amount?: number;
  ExternalReference?: string;
  BaseSalaryTypeId?: number;
  EIncomeCategory?: number;
  EIncomeSubCategory?: number;
  SummaryCategoryId?: number;
  SortOrder?: number;
  DefaultPayslipText?: string;
  AllowEditUnitType?: boolean;
  AllowEditBaseAmount?: boolean;
  NegativeDefault?: boolean;
  DefaultPersistanceTypeId?: number;
  SumFromIds?: string;
  SumFromReverseSign?: boolean;
  IncludeAlways?: boolean;
  StatisticsOnly?: boolean;
  StatisticsFieldCode?: string;
  RelatesToIncomeType5?: boolean;
  Explanation?: string;
  AccountTypeId?: number;
  Identifier?: string;
  TriggersOvertimeSupplement?: boolean;
  SulinalFieldNumber?: string;
  IsAdvanced: boolean;
  OverviewIndex?: number;
  OverviewDisplayMode?: number;
  InternalReferenceId?: number;
  ImportExternalReference?: string;
  BalanceDefinitionId?: number;
  BalanceBehavior?: number;
  SkatteverketAgiFieldCode?: number;
  DimensionDistributionOptionId?: number;
  DisplayDates?: boolean;
  SalaryTypeTranslations?: ISalaryTypeTranslation[];
  BaseSalaryType?: ISalaryType;
  ImportMappingColumns?: IImportMappingColumn[];
  BalanceDefinition?: IBalanceDefinition;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.IsAdvanced = this.IsAdvanced || false;
  }
}

export class SalarySummaryCategory implements ISalarySummaryCategory {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class SalaryTypeTranslation implements ISalaryTypeTranslation {
  Id: number;
  SalaryTypeId: number;
  LanguageId: number;
  Name?: string;
  Description?: string;
  DefaultPayslipText?: string;
  StatusId: number;
  Comment?: string;
  Explanation?: string;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.SalaryTypeId = this.SalaryTypeId || 0;
    this.LanguageId = this.LanguageId || 0;
    this.StatusId = this.StatusId || 0;
  }
}

export class SalaryTypeCategoryView implements ISalaryTypeCategoryView {
  StaticDataId: number;
  Id: number;
  LanguageId: number;
  Key?: string;
  Name?: string;
  Description?: string;
  IsPrimaryIncome: boolean;
  TriggersAMB: boolean;
  IncludeInPayment: boolean;
  TriggersIncomeTax: boolean;
  TriggersATP: boolean;
  EarnsHolidayCompensation: boolean;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
    this.LanguageId = this.LanguageId || 0;
    this.IsPrimaryIncome = this.IsPrimaryIncome || false;
    this.TriggersAMB = this.TriggersAMB || false;
    this.IncludeInPayment = this.IncludeInPayment || false;
    this.TriggersIncomeTax = this.TriggersIncomeTax || false;
    this.TriggersATP = this.TriggersATP || false;
    this.EarnsHolidayCompensation = this.EarnsHolidayCompensation || false;
  }
}

export class InheritSalaryTypeRequest implements IInheritSalaryTypeRequest {
  BaseSalaryTypeId: number;
  Name?: string;

  setDefaultValue?(): void {
    this.BaseSalaryTypeId = this.BaseSalaryTypeId || 0;
  }
}

export class SimpleSalaryTypeUpdateRequest implements ISimpleSalaryTypeUpdateRequest {
  Id: number;
  ExternalReference?: string;
  ImportExternalReference?: string;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
  }
}

export class SalaryTypeGroup implements ISalaryTypeGroup {
  Id: number;
  Name?: string;
  CompanyId: number;
  ExternalReference?: string;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.CompanyId = this.CompanyId || 0;
  }
}

export class DepartmentSalaryTypeMapping implements IDepartmentSalaryTypeMapping {
  Id: number;
  DepartmentId: number;
  SalaryTypeId: number;
  ExternalReference?: string;
  Department?: IDepartment;
  SalaryType?: ISalaryType;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.DepartmentId = this.DepartmentId || 0;
    this.SalaryTypeId = this.SalaryTypeId || 0;
  }
}

export class StartupTaskCompanyView implements IStartupTaskCompanyView {
  Id: number;
  TaskId: number;
  Key?: string;
  SortOrder: number;
  CompanyId: number;
  CompanyName?: string;
  AppUri?: string;
  IsSkippable: boolean;
  LanguageId: number;
  Name?: string;
  Description?: string;
  GuideMessage?: string;
  ExternalLink?: string;
  SkipWarning?: string;
  StatusId: number;
  HelpLink?: string;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.TaskId = this.TaskId || 0;
    this.SortOrder = this.SortOrder || 0;
    this.CompanyId = this.CompanyId || 0;
    this.IsSkippable = this.IsSkippable || false;
    this.LanguageId = this.LanguageId || 0;
    this.StatusId = this.StatusId || 0;
  }
}

export class StaticData implements IStaticData {
  Id: number;
  TypeKey?: string;
  ValueKey?: string;
  ValueId: number;
  Name?: string;
  Description?: string;
  Int1?: number;
  Int2?: number;
  Text1?: string;
  Text2?: string;
  Date1?: Date;
  Date2?: Date;
  Bool1: boolean;
  Bool2: boolean;
  Decimal1?: number;
  Decimal2?: number;
  Long1?: number;
  Long2?: number;
  SortKey?: string;
  Text3?: string;
  Text4?: string;
  Text5?: string;
  Text6?: string;
  StaticDataTranslations?: IStaticDataTranslation[];

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.ValueId = this.ValueId || 0;
    this.Bool1 = this.Bool1 || false;
    this.Bool2 = this.Bool2 || false;
  }
}

export class StaticDataTranslation implements IStaticDataTranslation {
  Id: number;
  StaticDataId: number;
  LanguageId: number;
  Name?: string;
  Description?: string;
  Text1?: string;
  Text2?: string;
  StatusId: number;
  Comment?: string;
  Text3?: string;
  Text4?: string;
  Text5?: string;
  Language?: ILanguage;
  StaticData?: IStaticData;

  setDefaultValue?(): void {
    this.Id = this.Id || 0;
    this.StaticDataId = this.StaticDataId || 0;
    this.LanguageId = this.LanguageId || 0;
    this.StatusId = this.StatusId || 0;
  }
}

export class StaticDataEntity implements IStaticDataEntity {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class AdditionalVacationTerminationMethod implements IAdditionalVacationTerminationMethod {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class AtpAgreement implements IAtpAgreement {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class AtpRate implements IAtpRate {
  SalaryCycleId: number;
  MinHours: number;
  MaxHours: number;
  EmployeeAmount: number;
  CompanyAmount: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.SalaryCycleId = this.SalaryCycleId || 0;
    this.MinHours = this.MinHours || 0;
    this.MaxHours = this.MaxHours || 0;
    this.EmployeeAmount = this.EmployeeAmount || 0;
    this.CompanyAmount = this.CompanyAmount || 0;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class AuditLogOperation implements IAuditLogOperation {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class Bank implements IBank {
  PaymentExportFormatId: number;
  CountryId: number;
  PaymentExportFormat?: IPaymentExportFormat;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.PaymentExportFormatId = this.PaymentExportFormatId || 0;
    this.CountryId = this.CountryId || 0;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class PaymentExportFormat implements IPaymentExportFormat {
  FileFormat?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class BankHoliday implements IBankHoliday {
  Date: Date;
  CountryId: number;
  BankHolidayTypeId: number;
  BankHolidayType?: IBankHolidayType;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.Date = this.Date || null;
    this.CountryId = this.CountryId || 0;
    this.BankHolidayTypeId = this.BankHolidayTypeId || 0;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class BankHolidayType implements IBankHolidayType {
  DisablesBankPayments: boolean;
  IsHoliday: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.DisablesBankPayments = this.DisablesBankPayments || false;
    this.IsHoliday = this.IsHoliday || false;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class City implements ICity {
  CountryId: number;
  PostalCode: number;
  IsRange: boolean;
  PostalCodeTo?: number;
  MunicipalityId?: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.CountryId = this.CountryId || 0;
    this.PostalCode = this.PostalCode || 0;
    this.IsRange = this.IsRange || false;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class CompanyPreference implements ICompanyPreference {
  RequiredModuleId: number;
  RequiredModule?: IModule;
  RelevantCountries?: ICountry[];
  Default?: string;
  ListDataSource?: string;
  HelpUrl?: string;
  UiControlType?: string;
  CategoryId?: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.RequiredModuleId = this.RequiredModuleId || 0;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class CompanyPreferenceCategory implements ICompanyPreferenceCategory {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class Currency implements ICurrency {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class DataType implements IDataType {
  IsNumeric: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.IsNumeric = this.IsNumeric || false;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class EconomicDepartmentSelector implements IEconomicDepartmentSelector {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class EIncomeGroupByParameter implements IEIncomeGroupByParameter {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class EmailTemplate implements IEmailTemplate {
  Subject?: string;
  PreferPersonalEmail: number;
  GreetingType: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.PreferPersonalEmail = this.PreferPersonalEmail || 0;
    this.GreetingType = this.GreetingType || 0;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class EntityType implements IEntityType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class ExternalSystem implements IExternalSystem {
  HasSalaryTypeMappings: boolean;
  IsActive: boolean;
  ConfigurationKeys?: string[];
  CredentialKeys?: string[];
  SupportUrl?: string;
  OperationsFlag: number;
  Operations?: IExternalSystemOperation[];
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.HasSalaryTypeMappings = this.HasSalaryTypeMappings || false;
    this.IsActive = this.IsActive || false;
    this.OperationsFlag = this.OperationsFlag || 0;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class ExternalSystemOperation implements IExternalSystemOperation {
  IsSingular: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.IsSingular = this.IsSingular || false;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class ExternalSystemConfiguration implements IExternalSystemConfiguration {
  FormElement?: string;
  IsRequired: boolean;
  ListDataSource?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.IsRequired = this.IsRequired || false;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class ExtraVacationHoursEarningBehavior implements IExtraVacationHoursEarningBehavior {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class FinanceAccountType implements IFinanceAccountType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class ImportConversion implements IImportConversion {
  ImportTypeKey?: string;
  ImportType?: IImportType;
  ApiRoute?: string;
  ApiMethodName?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class ImportType implements IImportType {
  ImportOptionKeys?: string[];
  ImportMappingLimitationKey?: string;
  HasFilledTemplates: boolean;
  RelatedOptions?: IImportOption[];
  ImportMappingLimitation?: IImportMappingLimitation;
  ImportColumns?: IImportColumn[];
  ImportColumnKeys?: string[];
  CountryColumnsKeys?: any;
  CountryColumns?: any;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.HasFilledTemplates = this.HasFilledTemplates || false;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class ImportOption implements IImportOption {
  Values?: IImportOptionValue[];
  Default?: IImportOptionValue;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class ImportMappingLimitation implements IImportMappingLimitation {
  Operation?: string;
  Parameter1?: IImportMappingLimitationParameter;
  Parameter2?: IImportMappingLimitationParameter;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class ImportColumn implements IImportColumn {
  DataTypeName?: string;
  RelevantCountries?: ICountry[];
  ExampleBool: boolean;
  ExampleDecimal?: number;
  ExampleDate?: Date;
  ExampleInt?: number;
  ExampleString?: string;
  MinimumSize?: number;
  AllowsSalaryType: boolean;
  ApplicableForCompanyKey?: string;
  BackendKey?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.ExampleBool = this.ExampleBool || false;
    this.AllowsSalaryType = this.AllowsSalaryType || false;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class ImportOptionValue implements IImportOptionValue {
  IsDefault: boolean;
  OptionKey?: string;
  WarningText?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.IsDefault = this.IsDefault || false;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class ImportMappingLimitationParameter implements IImportMappingLimitationParameter {
  Type?: string;
  Key?: string;

  setDefaultValue?(): void {}
}

export class ImportList implements IImportList {
  Operations?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class ImportTemplateParameter implements IImportTemplateParameter {
  UiControlType?: string;
  ListDataSource?: string;
  DefaultValue?: string;
  AvailableForTemplate: boolean;
  AvailableForPrefilled: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.AvailableForTemplate = this.AvailableForTemplate || false;
    this.AvailableForPrefilled = this.AvailableForPrefilled || false;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class ImportTemplateParameterAssignment implements IImportTemplateParameterAssignment {
  ImportTypeKey?: string;
  ImportType?: IImportType;
  ImportTemplateParameterKey?: string;
  ImportTemplateParameter?: IImportTemplateParameter;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class IncomeType implements IIncomeType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class IndustryOrganization implements IIndustryOrganization {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class IntegrationStatus implements IIntegrationStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class IntervalType implements IIntervalType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class InventioItDimension implements IInventioItDimension {
  ExternalSystemPropertyName?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class ModulePackage implements IModulePackage {
  Price: number;
  BillingPrincipleId: number;
  BillingPrinciple?: IBillingPrinciple;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.Price = this.Price || 0;
    this.BillingPrincipleId = this.BillingPrincipleId || 0;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class Month implements IMonth {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class Municipality implements IMunicipality {
  CountryId: number;
  DefaultTaxRate?: number;
  OfficialReference?: string;
  OfficialReferenceNumber: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.CountryId = this.CountryId || 0;
    this.OfficialReferenceNumber = this.OfficialReferenceNumber || 0;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class OvertimeSupplementPrinciple implements IOvertimeSupplementPrinciple {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class PaymentMethod implements IPaymentMethod {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class PaymentProvider implements IPaymentProvider {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class PaymentStatus implements IPaymentStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class PaymentType implements IPaymentType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class Preference implements IPreference {
  Default?: string;
  ListDataSource?: string;
  HelpUrl?: string;
  UiControlType?: string;
  CategoryId?: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class ReimbursementStatus implements IReimbursementStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class ReimbursementType implements IReimbursementType {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class ReportArea implements IReportArea {
  AppUri?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class ReportParameter implements IReportParameter {
  UiControlType?: string;
  ListDataSource?: string;
  DependsOn?: string[];
  DefaultValue?: string;
  IsRequired: boolean;
  IsHidden: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.IsRequired = this.IsRequired || false;
    this.IsHidden = this.IsHidden || false;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class SalaryRecordsOverviewDisplayMode implements ISalaryRecordsOverviewDisplayMode {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class SalaryTypeCategory implements ISalaryTypeCategory {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class SalaryTypeDimensionDistributionOption implements ISalaryTypeDimensionDistributionOption {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class SalaryTypeTotalsMultipleBatchesGroupByParameter
  implements ISalaryTypeTotalsMultipleBatchesGroupByParameter {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class SeTaxTable implements ISeTaxTable {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class SignupReferral implements ISignupReferral {
  AdditionalInfoRelevant: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.AdditionalInfoRelevant = this.AdditionalInfoRelevant || false;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class SkatteverketAgiFieldCode implements ISkatteverketAgiFieldCode {
  IsGrouped: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.IsGrouped = this.IsGrouped || false;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class SortingOption implements ISortingOption {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class StatisticsEmploymentPrinciple implements IStatisticsEmploymentPrinciple {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class StatisticsEmploymentTerms implements IStatisticsEmploymentTerms {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class StatisticsJobStatus implements IStatisticsJobStatus {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class StatisticsRecipient implements IStatisticsRecipient {
  Code?: number;
  IsSupported: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.IsSupported = this.IsSupported || false;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class StatisticsSalaryPrinciple implements IStatisticsSalaryPrinciple {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class TermExplanation implements ITermExplanation {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class TimeEntryRecordAcrossSalaryPeriodStrategy implements ITimeEntryRecordAcrossSalaryPeriodStrategy {
  IsDefault: boolean;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.IsDefault = this.IsDefault || false;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class TimeEntryStatusExtended implements ITimeEntryStatusExtended {
  TimeEntryStatusString?: string;
  TimeEntryStatusIds?: number[];
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class UnicontaDimension implements IUnicontaDimension {
  ExternalSystemPropertyName?: string;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class UserPreference implements IUserPreference {
  MinimumRoleId?: number;
  Default?: string;
  ListDataSource?: string;
  HelpUrl?: string;
  UiControlType?: string;
  CategoryId?: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class VacationPeriod implements IVacationPeriod {
  CountryId: number;
  SpendingStartDate: Date;
  SpendingEndDate: Date;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.CountryId = this.CountryId || 0;
    this.SpendingStartDate = this.SpendingStartDate || null;
    this.SpendingEndDate = this.SpendingEndDate || null;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class VacationSupplementPayoutMethodDK implements IVacationSupplementPayoutMethodDK {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class VacationTypeSE implements IVacationTypeSE {
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class WorkBookVersion implements IWorkBookVersion {
  VersionNumber: number;
  StaticDataId: number;
  Key?: string;
  Id: number;
  Name?: string;
  Description?: string;
  SortKey?: string;

  setDefaultValue?(): void {
    this.VersionNumber = this.VersionNumber || 0;
    this.StaticDataId = this.StaticDataId || 0;
    this.Id = this.Id || 0;
  }
}

export class RejectionInfo implements IRejectionInfo {
  Reason?: string;

  setDefaultValue?(): void {}
}

export class TimeEntryTypeView implements ITimeEntryTypeView {
  TimeEntryTypeId: number;
  BaseTimeEntryTypeId?: number;
  CompanyId: number;
  LanguageId: number;
  Name?: string;
  Description?: string;
  DefaultPayslipText?: string;
  IsActive: boolean;
  UnitTypeId?: number;
  ExternalReference?: string;
  SortOrder: number;
  AllowEditUnitType: boolean;
  IsIllness: boolean;
  SalaryTypeId?: number;
  SalaryTypeName?: string;
  Identifier?: string;
  BaseName?: string;
  BaseDescription?: string;
  BasePayslipText?: string;

  setDefaultValue?(): void {
    this.TimeEntryTypeId = this.TimeEntryTypeId || 0;
    this.CompanyId = this.CompanyId || 0;
    this.LanguageId = this.LanguageId || 0;
    this.IsActive = this.IsActive || false;
    this.SortOrder = this.SortOrder || 0;
    this.AllowEditUnitType = this.AllowEditUnitType || false;
    this.IsIllness = this.IsIllness || false;
  }
}

export class InheritTimeEntryTypeRequest implements IInheritTimeEntryTypeRequest {
  BaseTimeEntryTypeId: number;
  Name?: string;

  setDefaultValue?(): void {
    this.BaseTimeEntryTypeId = this.BaseTimeEntryTypeId || 0;
  }
}

export class IdentityCheckResponse implements IIdentityCheckResponse {
  ExistsInIntect: boolean;
  BirthDate?: Date;
  IsWellFormed: boolean;
  RaiseChecksumWarning: boolean;

  setDefaultValue?(): void {
    this.ExistsInIntect = this.ExistsInIntect || false;
    this.IsWellFormed = this.IsWellFormed || false;
    this.RaiseChecksumWarning = this.RaiseChecksumWarning || false;
  }
}

export class UserImageResponse implements IUserImageResponse {
  Image?: string;
  UserId: number;

  setDefaultValue?(): void {
    this.UserId = this.UserId || 0;
  }
}

export class CompanyUserView implements ICompanyUserView {
  UserId: number;
  CompanyUserId: number;
  CompanyId: number;
  CompanyName?: string;
  StatusId: number;
  RoleId: number;
  IsActive: boolean;
  RoleKey?: string;
  IdentityNumber?: string;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  TelMobile?: string;
  TelFixed?: string;
  BankRegNo?: string;
  BankAccountNo?: string;
  BicSwiftNo?: string;
  IbanNo?: string;
  IsPaymentApprover: boolean;
  Initials?: string;
  CompanyEmail?: string;
  PersonalEmail?: string;
  VATRegistrationNumber?: string;
  FullName?: string;
  UserAccountId?: number;
  CompanyAccountId?: number;
  AccountRoleId?: number;
  UserName?: string;
  DefaultCompanyId?: number;
  LanguageId: number;
  LanguageCode?: string;
  AddressId: number;
  AddressLine1?: string;
  AddressLine2?: string;
  PostalCode?: string;
  City?: string;
  CountryId?: number;
  CountryCode?: string;
  CompanyUserCreated: Date;
  UserCreated: Date;
  DateOfBirth?: Date;
  AutoDetermineBankAccount: boolean;
  MunicipalityId?: number;
  CompanyCountryId: number;
  Updated?: Date;

  setDefaultValue?(): void {
    this.UserId = this.UserId || 0;
    this.CompanyUserId = this.CompanyUserId || 0;
    this.CompanyId = this.CompanyId || 0;
    this.StatusId = this.StatusId || 0;
    this.RoleId = this.RoleId || 0;
    this.IsActive = this.IsActive || false;
    this.IsPaymentApprover = this.IsPaymentApprover || false;
    this.LanguageId = this.LanguageId || 0;
    this.AddressId = this.AddressId || 0;
    this.CompanyUserCreated = this.CompanyUserCreated || null;
    this.UserCreated = this.UserCreated || null;
    this.AutoDetermineBankAccount = this.AutoDetermineBankAccount || false;
    this.CompanyCountryId = this.CompanyCountryId || 0;
  }
}

export class IdentityNumberRequest implements IIdentityNumberRequest {
  IdentityNumber?: string;

  setDefaultValue?(): void {}
}

export class BatchOperationResponse_User_User implements IBatchOperationResponse_User_User {
  ProcessedItems?: IUser[];
  Errors?: any;
  RejectedItems?: IRejectedItemContainer_User_User[];
  HasErrors: boolean;

  setDefaultValue?(): void {
    this.HasErrors = this.HasErrors || false;
  }
}

export class RejectedItemContainer_User_User implements IRejectedItemContainer_User_User {
  Item?: IUser;
  Reason?: string;

  setDefaultValue?(): void {}
}
