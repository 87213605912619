<div>
  <app-module-check [moduleId]="8" [(hasModule)]="hasTimeEntryModule">
    <div class="Cards">
      <div class="Card">
        <div class="Card-inner">
          <legend
            style="padding-bottom: 15px; padding-left: 1px;font-size: 0.75rem; font-style: italic; opacity: 0.75;"
            [innerHTML]="'CompanyTimeEntryTypes.TimeEntryTypeGridHint' | translate"
          ></legend>
          <app-grid
            #timeEntryTypesGrid
            [(data)]="gridFilterData"
            [editMode]="!IsReadOnly"
            [(selectedItem)]="selectedTimeEntryType"
            [selectedId]="'Id'"
            [idColumn]="'Id'"
            (cellClick)="onCellClick($event)"
            (saveChangesEvent)="saveChanges($event)"
            class="FormElement-grid Company-timeEntryTypesGrid"
            [filterable]="'menu'"
            [filter]="filter"
            (filterChange)="onFilterChange($event)"
            [(triggerUpdate)]="triggerUpdate"
            [isUseSpanHeader]="true"
            [groupLocked]="true"
            [groupWidth]="300"
            [groupHeaderClass]="'group-locked'"
          >
            <app-grid-column-group
              [field]="'configurationIcon'"
              [editable]="!IsReadOnly"
              [iconField]="'configurationIcon'"
              [locked]="true"
              [type]="'icon'"
              [width]="40"
              [title]="''"
            >
            </app-grid-column-group>
            <app-grid-column-group
              [isFilterable]="true"
              [field]="'IsActive'"
              [classField]="'isActiveClass'"
              [editable]="!IsReadOnly"
              [locked]="true"
              [type]="'checkbox'"
              [title]="'Employee.IsActive' | translate"
              [width]="65"
              [headerTooltip]="'CompanyTimeEntryTypes.IsActiveTooltip' | translate"
            >
            </app-grid-column-group>
            <app-grid-column-group
              [isFilterable]="true"
              [field]="'Id'"
              [locked]="true"
              [editable]="false"
              [type]="'text'"
              [title]="'Id'"
              [width]="70"
              [headerTooltip]="'CompanyTimeEntryTypes.IdHeaderTooltip' | translate"
            >
            </app-grid-column-group>
            <app-grid-column-group
              [isFilterable]="true"
              [field]="'Name'"
              [classField]="'nameClass'"
              [editable]="!isLanguageModuleEnable"
              [locked]="true"
              [type]="'text'"
              [title]="'CompanyTimeEntryTypes.Name' | translate"
              [width]="245"
              [headerTooltip]="'CompanyTimeEntryTypes.NameTooltip' | translate"
              [tooltipField]="'Name'"
              [IconFieldString]="'IconConfigurationIcon'"
              [addOnIconFieldString]="'IconConfigurationIcon'"
              [IconTooltipFieldString]="'IconConfigurationIconTooltip'"
            >
            </app-grid-column-group>
            <app-grid-column
              [isFilterable]="true"
              [field]="'inheritsFrom'"
              [editable]="false"
              [type]="'text'"
              [title]="'CompanyTimeEntryTypes.InheritsFrom' | translate"
              [viewClass]="'truncated'"
              [width]="245"
              [headerTooltip]="'CompanyTimeEntryTypes.InheritsFromTooltip' | translate"
            >
            </app-grid-column>
            <app-grid-column
              [isFilterable]="true"
              [field]="'Description'"
              [classField]="'descriptionClass'"
              [editable]="!isLanguageModuleEnable"
              [type]="'text'"
              [title]="'CompanyTimeEntryTypes.Description' | translate"
              [viewClass]="'truncated'"
              [width]="245"
              [headerTooltip]="'CompanyTimeEntryTypes.DescriptionTooltip' | translate"
              [tooltipField]="'Description'"
              [IconFieldString]="'IconConfigurationIcon'"
              [addOnIconFieldString]="'IconConfigurationIcon'"
              [IconTooltipFieldString]="'IconConfigurationIconTooltip'"
            >
            </app-grid-column>

            <app-grid-column
              [isFilterable]="true"
              [field]="'DefaultPayslipText'"
              [classField]="'defaultPayslipTextClass'"
              [editable]="!isLanguageModuleEnable"
              [type]="'text'"
              [title]="'CompanyTimeEntryTypes.PayslipText' | translate"
              [viewClass]="'truncated'"
              [width]="200"
              [headerTooltip]="'CompanyTimeEntryTypes.PayslipTextTooltip' | translate"
              [tooltipField]="'DefaultPayslipText'"
              [IconFieldString]="'IconConfigurationIcon'"
              [addOnIconFieldString]="'IconConfigurationIcon'"
              [IconTooltipFieldString]="'IconConfigurationIconTooltip'"
            >
            </app-grid-column>

            <app-grid-column
              [isFilterable]="true"
              [field]="'ExternalReference'"
              [editable]="!IsReadOnly"
              [classField]="'externalReferenceClass'"
              [type]="'text'"
              [title]="'CompanyTimeEntryTypes.ExternalReference' | translate"
              [headerTooltip]="'CompanyTimeEntryTypes.ExternalReferenceTooltip' | translate"
              [viewClass]="'truncated'"
              [width]="100"
            >
            </app-grid-column>
            <app-grid-column
              [isFilterable]="true"
              [field]="'SortOrder'"
              [editable]="!IsReadOnly"
              [classField]="'sortOrderClass'"
              [type]="'numeric'"
              [numbericOption]="numberOption"
              [title]="'CompanyTimeEntryTypes.SortOrder' | translate"
              [width]="80"
              [headerTooltip]="'CompanyTimeEntryTypes.SortOrderTooltip' | translate"
            >
            </app-grid-column>

            <app-grid-column
              [isFilterable]="true"
              [field]="'UnitTypeId'"
              [editable]="!IsReadOnly"
              [classField]="'unitTypeClass'"
              [type]="'combobox'"
              [comboboxDataSource]="unitTypes"
              [comboboxValueField]="'Id'"
              [viewClass]="'truncated'"
              [comboboxDisplayField]="'Name'"
              [title]="'CompanyTimeEntryTypes.UnitType' | translate"
              [width]="90"
              [headerTooltip]="'CompanyTimeEntryTypes.UnitTypeTooltip' | translate"
            >
            </app-grid-column>
            <!-- <app-grid-column
              [isFilterable]="true"
              [field]="'AllowEditUnitType'"
              [classField]="'allowEditUnitTypeClass'"
              [editable]="!IsReadOnly"
              [type]="'checkbox'"
              [title]="'CompanyTimeEntryTypes.AllowEditUnitType' | translate"
              [width]="100"
              [headerTooltip]="'CompanyTimeEntryTypes.AllowEditUnitTypeTooltip' | translate"
            >
            </app-grid-column> -->
            <app-grid-column
              [isFilterable]="true"
              [field]="'IsIllness'"
              [classField]="'isIllnessClass'"
              [editable]="!IsReadOnly"
              [type]="'checkbox'"
              [title]="'CompanyTimeEntryTypes.IsIllness' | translate"
              [width]="90"
              [headerTooltip]="'CompanyTimeEntryTypes.IsIllnessTooltip' | translate"
            >
            </app-grid-column>
            <app-grid-column
              [isFilterable]="true"
              [field]="'SalaryTypeId'"
              [classField]="'customSalaryTypeClass'"
              [editable]="!IsReadOnly"
              [type]="'combobox'"
              [comboboxDataSource]="companyTimeEntryTypeService.salaryTypes | async"
              [comboboxValueField]="'SalaryTypeId'"
              [comboboxDisplayField]="'Name'"
              [title]="'CompanyTimeEntryTypes.SalaryType' | translate"
              [viewClass]="'truncated'"
              [width]="200"
              [tooltipFieldNonEdit]="'ToolTipSalaryType'"
              [tooltipField]="'ToolTipSalaryType'"
              [editableField]="'editSalaryType'"
              [headerTooltip]="'CompanyTimeEntryTypes.SalaryTypeTooltip' | translate"
              [CurrentTextComboboxToolTipFeild]="'editSalaryType'"
              [allowComboeditNull]="true"
            >
            </app-grid-column>
          </app-grid>
        </div>
      </div>
    </div>

    <div class="Actions" *ngIf="!isMobile">
      <app-menu-button
        [menuCondition]="!sessionService.role.IsReadOnly"
        (menuClick)="newCustomTimeEntryDialogVisible = true"
        [menuIcon]="'Add'"
        [menuLabel]="'Company.New'"
        [menuTooltip]="'CompanyTimeEntryTypes.AddTimeEntryTypeText'"
        [actionButtonMenu]="true"
        [menuButtonClass]="'Action-button Action-buttonAdd'"
        [menuDisabled]="!hasTimeEntryModule"
        [menuButtonId]="'ActionButtonAdd'"
      >
      </app-menu-button>
      <app-menu-button
        [menuIcon]="'Help'"
        [menuLabel]="'Help.Title'"
        [menuTooltip]="'Help.Title'"
        [multiMenuItems]="true"
      >
        <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
        <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
      </app-menu-button>
      <app-menu-button
        *ngIf="reportDialogService.isVisibleReportIcon"
        [menuCondition]="true"
        (menuClick)="reportDialogService.onShowReportsEventClick()"
        [menuIcon]="'File'"
        [menuLabel]="'Employee.Reports'"
        [menuTooltip]="'Employee.Reports'"
        [actionButtonMenu]="true"
        [menuButtonClass]="'Action-button Action-buttonReports'"
        [menuButtonId]="'ActionButtonReports'"
      >
      </app-menu-button>
    </div>
    <div class="Actions" *ngIf="isMobile">
      <app-menu-button
        [menuIcon]="'ThreeDots'"
        [menuLabel]="'More.Title'"
        [menuTooltip]="'More.Title'"
        [multiMenuItems]="true"
      >
        <app-menu-button-item
          *ngIf="!sessionService.role.IsReadOnly"
          (menuItemClick)="newCustomTimeEntryDialogVisible = true"
          [field]="'TimeEntryTimeNew'"
        >
        </app-menu-button-item>
        <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
        <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
      </app-menu-button>
    </div>
  </app-module-check>
</div>

<app-time-entry-type-translation-dialog
  [timeEntryType]="selectedTimeEntryType"
  [(visible)]="translationDialogVisible"
  (translateComplete)="loadTimeEntryTypes($event)"
>
</app-time-entry-type-translation-dialog>

<app-new-time-entry-type-dialog
  [(visible)]="newCustomTimeEntryDialogVisible"
  [defaultTimeEntryTypes]="TimeEntryTypes"
  (add)="addNewTimeEntryType($event)"
>
</app-new-time-entry-type-dialog>

<app-time-entry-type-configurations-dialog
  [(visible)]="timeEntryTypeConfigurationDialogVisible"
  [timeEntryType]="selectedTimeEntryType"
  [salaryTypes]="companyTimeEntryTypeService.salaryTypes | async"
  (IsHasChange)="onTimeEntryTypeConfigurationVisibleChange($event)"
>
</app-time-entry-type-configurations-dialog>

<app-loading-tips [visible]="isWattingLoading"></app-loading-tips>
