import { Component, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from 'src/app/common/global';
import { IDimensionValue, ISimpleKeyValuePair } from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { SettingService } from 'src/app/services/setting.service';
import { CompanyPreferenceService } from 'src/app/shared-components/preference/preference.service';
import { FormComponentBase } from '../../../common/form-component-base';
import { SessionService } from '../../../services/session/session.service';
import { DimensionsService } from './dimensions.service';

@Component({
  selector: 'app-dimensions',
  templateUrl: './dimensions.component.html',
  styleUrls: ['./dimensions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DimensionsComponent extends FormComponentBase implements OnInit, OnDestroy {
  public dimensionValueEntitiesMessage: string;
  public btnLabel: string;

  public get isEnableDimensionPreference(): boolean {
    const pref = this.sessionService.getCompanyPreference('Dimension.UseDimensionValueEntities');
    return pref.Value === 'true' ? true : false;
  }

  constructor(
    public sessionService: SessionService,
    private renderer: Renderer2,
    private companyPreferenceService: CompanyPreferenceService,
    public dimensionsService: DimensionsService,
    private translateService: TranslateService,
    private dataService: DataService,
    private settingService: SettingService
  ) {
    super();
    if (!Global.DimensionNumber) {
      Global.DimensionNumber = this.dimensionsService.currentDimensionNumber || 1;
    }
    const translationServiceTerms = [
      'CompanyConfigurations.Dimension1',
      'CompanyConfigurations.Dimension2',
      'CompanyConfigurations.Dimension3',
      'Company.DimensionsPreference',
      'Company.EnableDimension'
    ];

    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateService.get(translationServiceTerms).subscribe((translations: { [key: string]: string }) => {
        this.dimensionsService.translations = translations;
        this.dimensionValueEntitiesMessage = translations['Company.DimensionsPreference'];
        this.btnLabel = translations['Company.EnableDimension'];
        this.dimensionsService.updateDimensionLabel();
      });
    });
  }

  public ngOnInit(): void {
    this.renderer.listen(window, 'click', (event: any) => {
      if (!this.isCollapseButtonClick) {
        this.menuOpen = false;
      } else {
        this.isCollapseButtonClick = false;
      }
    });
    this.dimensionsService.getDimensionData(this.dimensionsService.currentDimensionNumber);
    this.dimensionsService.updateDimensionLabel();
  }

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.menuOpen = false;
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public menuOpen = false;
  private isCollapseButtonClick = false;
  public onToggleNavigation(): void {
    this.isCollapseButtonClick = true;
    this.menuOpen = !this.menuOpen;
  }

  public changeTab(index: number): void {
    this.menuOpen = false;
    this.dimensionsService.currentDimensionNumber = index;
    Global.DimensionNumber = this.dimensionsService.currentDimensionNumber;
    this.dimensionsService.updateDimensionLabel();
    this.dimensionsService.dimensionNaming = '';
    this.dimensionsService.getDimensionData(index);
  }

  public activeDimension() {
    const pref = this.sessionService.getCompanyPreference('Dimension.UseDimensionValueEntities');
    this.companyPreferenceService.submitValueChange('true', pref);
  }
}
