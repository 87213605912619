import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from 'src/app/common/global';
import { NumericTextBoxOptions } from 'src/app/custom-controls/numeric-edit/numeric-text-box-options';
import {
  ISalaryTypeView,
  ISimpleKeyValuePair,
  ITimeEntryTypeConfiguration,
  IUnitType,
  IUserEmploymentTemplate,
  IUserEmploymentView
} from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { environment } from 'src/environments/environment';
import { CompanyDepartmentService } from '../../departments/company-department.service';

@Component({
  selector: 'app-time-entry-type-configurations-dialog',
  templateUrl: './time-entry-type-configurations-dialog.component.html',
  styleUrls: ['./time-entry-type-configurations-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimeEntryTypeConfigurationsDialogComponent implements OnInit, OnDestroy {
  @Input() public salaryTypes: ISalaryTypeView[];

  @Input()
  public get timeEntryType() {
    return this.timeEntryTypeValue;
  }
  public set timeEntryType(value) {
    this.timeEntryTypeValue = value;
    if (value && value.Id) {
      this.loadTimeEntryTypeConfigurations();
    }
  }

  public get branding() {
    return environment.branding;
  }

  public selectedItem: ITimeEntryTypeConfiguration;
  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    this.selectedItem = undefined;
    this.selectedId = undefined;
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
    }
    if (!value && this.isHasChange) {
      this.IsHasChange.emit(true);
      this.isHasChange = false;
    }
    if (value) {
      this.gridRadioAction = 1;
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public IsHasChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public employmentTemplates: IUserEmploymentTemplate[];
  public employmentViews: IUserEmploymentView[];
  public timeEntryTypeConfigurations: ITimeEntryTypeConfiguration[] = [];
  public unitTypes: IUnitType[] = Global.UNIT_TYPES;
  public onversionFactorAffectsDataSource: Array<{ Name: string; value: number }> = [];
  public gridRadioAction = 1;
  public isShowDeleteRecord = false;
  public selectedId: number;
  public dimensionValues: any = [];
  public isShowDimensionValue = false;

  private isHasChange = false;
  private translationServiceTerms: string[];
  private timeEntryTypeValue: any;

  private ConversionFactorAffectsRateTrueLabel = '';
  private ConversionFactorAffectsRateFalseLabel = '';

  public get Dimension1Name(): string {
    const dimensionObject: ISimpleKeyValuePair = this.sessionService.getCompanyPreference('Dimensions.Dimension1Name');
    return dimensionObject ? dimensionObject.Value : null;
  }

  public get Dimension2Name(): string {
    const dimensionObject: ISimpleKeyValuePair = this.sessionService.getCompanyPreference('Dimensions.Dimension2Name');
    return dimensionObject ? dimensionObject.Value : null;
  }

  public get Dimension3Name(): string {
    const dimensionObject: ISimpleKeyValuePair = this.sessionService.getCompanyPreference('Dimensions.Dimension3Name');
    return dimensionObject ? dimensionObject.Value : null;
  }

  public get isEnableDimensionPreference(): boolean {
    const pref = this.sessionService.getCompanyPreference('Dimension.UseDimensionValueEntities');
    return pref.Value === 'true' ? true : false;
  }

  constructor(
    private dataService: DataService,
    public translateService: TranslateService,
    public sessionService: SessionService,
    public companyDepartmentService: CompanyDepartmentService,
    private staticDataService: StaticDataService
  ) {
    this.translationServiceTerms = [
      'TimeEntryTypeConfigurations.ConversionFactorAffectsRateTrue',
      'TimeEntryTypeConfigurations.ConversionFactorAffectsRateFalse'
    ];
  }

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.companyDepartmentService.loadDepartment();
    this.dataService
      .EmploymentTemplates_GetEmploymentTemplates()
      .subscribe((templates: IUserEmploymentTemplate[]) => (this.employmentTemplates = templates));
    this.staticDataService.userEmployments.subscribe((userEmploymentViews: IUserEmploymentView[]) => {
      this.employmentViews = userEmploymentViews;
      this.employmentViews.forEach((model: IUserEmploymentView) => {
        model.FullName += ' (' + model.Title + ')';
      });
    });
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateService.get(this.translationServiceTerms).subscribe((translations: { [key: string]: string }) => {
        this.setTranslationTerms(translations);
      });
    });
    this.dataService.DimensionValues_GetDimensionValues(1).subscribe((data: any) => {
      this.dimensionValues[0] = data;
    });
    this.dataService.DimensionValues_GetDimensionValues(2).subscribe((data: any) => {
      this.dimensionValues[1] = data;
    });
    this.dataService.DimensionValues_GetDimensionValues(3).subscribe((data: any) => {
      this.dimensionValues[2] = data;
    });
    this.isShowDimensionValue =
      this.sessionService.getCompanyPreference('Dimension.UseDimensionValueEntities').Value === 'true' ? true : false;
  }

  public setTranslationTerms(translations: { [key: string]: string }): void {
    this.ConversionFactorAffectsRateTrueLabel =
      translations['TimeEntryTypeConfigurations.ConversionFactorAffectsRateTrue'];
    this.ConversionFactorAffectsRateFalseLabel =
      translations['TimeEntryTypeConfigurations.ConversionFactorAffectsRateFalse'];

    if (this.onversionFactorAffectsDataSource.length < 2) {
      this.onversionFactorAffectsDataSource.push({ Name: this.ConversionFactorAffectsRateTrueLabel, value: 1 });
      this.onversionFactorAffectsDataSource.push({ Name: this.ConversionFactorAffectsRateFalseLabel, value: 0 });
    }
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  public get hasSelectedItem(): boolean {
    if (this.selectedItem) {
      return true;
    }
    return false;
  }

  public get isShowSimpleMode(): boolean {
    return this.gridRadioAction === 1;
  }

  public get isShowAdvancedMode(): boolean {
    return this.gridRadioAction === 2;
  }

  public get isShowCompleteMode(): boolean {
    return this.gridRadioAction === 3;
  }

  private loadTimeEntryTypeConfigurations(): void {
    this.gridData = [];
    this.selectedItem = undefined;
    if (this.timeEntryType && this.timeEntryType.Id) {
      this.dataService
        .TimeEntryTypes_GetTimeEntryTypeConfigurationsByTimeEntry(this.timeEntryType.Id)
        .subscribe((response: any[]) => {
          if (response && response.length > 0) {
            this.timeEntryTypeConfigurations = response;
            this.timeEntryTypeConfigurations.forEach((modal: any) => {
              modal.IsEmploymentTemplateEditable =
                modal.EmploymentTemplateId || (modal.UserEmploymentId === null && modal.EmploymentTemplateId === null);
              modal.IsUserEmploymentEditable =
                modal.UserEmploymentId || (modal.UserEmploymentId === null && modal.EmploymentTemplateId === null);
              modal.isAllowEditPerUnits = !modal.UseDefaultRate;
              modal.classfeilderUnits = modal.isAllowEditPerUnits ? '' : 'grid-disable-cell';
              modal.ConversionFactorAffectsRateNumber = modal.ConversionFactorAffectsRate === true ? 1 : 0;
            });
            this.gridData = filterBy(this.timeEntryTypeConfigurations, this.filter);
          }
        });
    }
  }

  public NumericOptions: NumericTextBoxOptions = { format: 'n2', decimals: 4, step: 1, min: 0, spinners: false };
  public ConversionFactorNumericOptions: NumericTextBoxOptions = {
    format: 'n4',
    decimals: 4,
    step: 1,
    min: 0,
    spinners: false
  };

  public addHandler(dataItem: any): void {
    const defaultDataItem: any = this.createNewDataItem();
    Object.assign(dataItem, defaultDataItem);
    this.saveChanges(dataItem);
  }

  public removeHandler(dataItem: any): void {
    if (dataItem && dataItem.Id) {
      this.dataService.TimeEntryTypes_DeleteTimeEntryTypeConfiguration(dataItem.Id).subscribe((): void => {
        this.isHasChange = true;
        this.loadTimeEntryTypeConfigurations();
      });
    } else {
      const dataIndex: any = this.timeEntryTypeConfigurations.indexOf(dataItem);
      this.timeEntryTypeConfigurations.splice(dataIndex, 1);
      this.gridData = filterBy(this.timeEntryTypeConfigurations, this.filter);
    }
  }

  public saveChanges(args: CellCloseEvent): void {
    const dataItem: any = args.dataItem ? args.dataItem : args;
    const salaryTypeSelected = this.salaryTypes.find(
      (item: ISalaryTypeView) => item.SalaryTypeId === dataItem.SalaryTypeId
    );
    if (!salaryTypeSelected) {
      this.loadTimeEntryTypeConfigurations();
      return;
    }

    if (dataItem) {
      dataItem.IsUserEmploymentEditable =
        dataItem.UserEmploymentId !== null ||
        (dataItem.UserEmploymentId === null && dataItem.EmploymentTemplateId === null);
      dataItem.IsEmploymentTemplateEditable =
        dataItem.EmploymentTemplateId !== null ||
        (dataItem.UserEmploymentId === null && dataItem.EmploymentTemplateId === null);
      dataItem.isAllowEditPerUnits = !dataItem.UseDefaultRate;
      dataItem.classfeilderUnits = dataItem.isAllowEditPerUnits ? '' : 'grid-disable-cell';
      dataItem.SalaryTypeName = salaryTypeSelected ? salaryTypeSelected.Name : '';
      dataItem.ConversionFactorAffectsRate = dataItem.ConversionFactorAffectsRateNumber === 1 ? true : false;

      dataItem.UseDefaultRate = dataItem.UseDefaultRate || false;
      dataItem.LegacyFlexEarningPercentage = dataItem.LegacyFlexEarningPercentage
        ? dataItem.LegacyFlexEarningPercentage
        : dataItem.LegacyFlexEarningPercentage === 0
        ? 0
        : null;
      dataItem.NewFlexEarningPercentage = dataItem.NewFlexEarningPercentage
        ? dataItem.NewFlexEarningPercentage
        : dataItem.NewFlexEarningPercentage === 0
        ? 0
        : null;
      dataItem.PensionPercentage = dataItem.PensionPercentage
        ? dataItem.PensionPercentage
        : dataItem.PensionPercentage === 0
        ? 0
        : null;
    }

    if (dataItem.Id) {
      this.dataService.TimeEntryTypes_UpdateTimeEntryTypeConfiguration(dataItem).subscribe(
        (): void => {
          // Fix GS-5317 prevent grid refresh.
          //this.loadTimeEntryTypeConfigurations();
          this.selectedItem = dataItem;
        },
        () => {
          this.loadTimeEntryTypeConfigurations();
        }
      );
    } else {
      this.dataService.TimeEntryTypes_CreateTimeEntryTypeConfiguration(dataItem).subscribe(
        (response: ITimeEntryTypeConfiguration) => {
          dataItem.Id = response.Id;
          // Object.assign(dataItem, response);
          this.selectedItem = response;
        },
        () => {
          this.loadTimeEntryTypeConfigurations();
        }
      );
    }
    this.isHasChange = true;
  }

  public flagCreate = 0;
  private createNewDataItem(): any {
    this.flagCreate++;
    return {
      Id: null,
      TimeEntryTypeId: this.timeEntryType && this.timeEntryType.Id ? this.timeEntryType.Id : null,
      SalaryTypeId: this.salaryTypes && this.salaryTypes.length > 0 ? this.salaryTypes[0].SalaryTypeId : null,
      Description: null,
      Amount: null,
      AmountPerUnit: null,
      AppliesBefore: null,
      AppliesBeforeDate: null,
      AppliesAfter: null,
      AppliesAfterDate: null,
      AppliesMondays: true,
      AppliesTuesdays: true,
      AppliesWednesdays: true,
      AppliesThursdays: true,
      AppliesFridays: true,
      AppliesWeekends: true,
      AppliesHolidays: true,
      AppliesSaturdays: true,
      AppliesSundays: true,
      AmountPerKilometer: null,
      EmploymentTemplateId: null,
      TargetUnitTypeId: null,
      UserEmploymentId: null,
      ConversionFactor: null,
      SalaryTypeName: this.salaryTypes[0].Name,
      flagCreate: this.flagCreate,
      IsEmploymentTemplateEditable: true,
      IsUserEmploymentEditable: true,
      isAllowEditPerUnits: true,
      classfeilderUnits: '',
      ConversionFactorAffectsRate: false,
      ConversionFactorAffectsRateNumber: 0
    };
  }

  public isEmploymentEdit: any;
  public refreshData = false;
  private keyPress: any = { 17: false, 18: false, 107: false, 109: false };
  @ViewChild('kendoGridComponent', { static: true }) public kendoGrid: GridComponent;

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: any): void {
    if (event.code === 'Escape') {
      this.visible = false;
      this.visibleChange.emit(false);
    }

    if (!this.IsReadOnly) {
      if (event.keyCode in this.keyPress) {
        this.keyPress[event.keyCode] = true;
        if (this.keyPress[17] && this.keyPress[18] && this.keyPress[109]) {
          if (this.selectedItem) {
            this.removeHandler(this.selectedItem);
            this.setKeypress();
            this.refreshData = true;
            this.selectedItem = undefined;
          }
        }
      } else {
        this.setKeypress();
      }
    }
  }

  private setKeypress(): void {
    this.keyPress[17] = false;
    this.keyPress[18] = false;
    this.keyPress[107] = false;
    this.keyPress[109] = false;
  }

  public filter: CompositeFilterDescriptor;
  public gridData: any[] = this.timeEntryTypeConfigurations;
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridData = filterBy(this.timeEntryTypeConfigurations, filter);
  }

  public gridAction(event: any): void {
    switch (event) {
      case 'DuplicateAction':
        const newItem = JSON.parse(JSON.stringify(this.selectedItem));
        newItem.Id = undefined;
        this.gridData.push(newItem);
        this.saveChanges(newItem);
        break;
      case 'DeleteAction':
        this.isShowDeleteRecord = true;
        break;
      default:
        break;
    }
  }

  public onDeleteRecordAction(event: any): void {
    if (event === 'Delete') {
      this.removeHandler(this.selectedItem);
    }
  }
}
