import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '../../services/session/session.service';
var LoadingTipsComponent = /** @class */ (function () {
    function LoadingTipsComponent(translateService, sessionService) {
        this.sessionService = sessionService;
        this.visible = false;
        this.currentTip = '';
        this.tips = [];
        switch (translateService.currentLang) {
            case 'en':
                if (!this.sessionService.browser.isMobile) {
                    this.currentTip = 'Reloading... <br />Hint: Plug in a network cable to improve the load time significantly.';
                }
                else {
                    this.currentTip = 'Reloading...';
                }
                break;
            default:
                if (!this.sessionService.browser.isMobile) {
                    this.currentTip =
                        'Indlæser data... <br />Tip: Brug et netværkskabel i stedet for wifi, så går det hurtigere!';
                }
                else {
                    this.currentTip = 'Indlæser data...';
                }
                break;
        }
        // this.tipIndex = Math.floor(Math.random() * this.tips.length);
        // this.changeTip();
    }
    Object.defineProperty(LoadingTipsComponent.prototype, "tiptext", {
        get: function () {
            return this.currentTip;
        },
        set: function (value) {
            if (value) {
                this.currentTip = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    LoadingTipsComponent.prototype.changeTip = function () {
        var _this = this;
        this.tipIndex++;
        if (this.tipIndex > this.tips.length - 1) {
            this.tipIndex = 0;
        }
        this.currentTip = this.tips[this.tipIndex];
        setTimeout(function () { return _this.changeTip(); }, 3000);
    };
    return LoadingTipsComponent;
}());
export { LoadingTipsComponent };
