/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../../custom-controls/combobox-edit/combobox-edit.component.ngfactory";
import * as i3 from "../../../custom-controls/combobox-edit/combobox-edit.component";
import * as i4 from "../../../services/session/session.service";
import * as i5 from "./sort-by.component";
import * as i6 from "../../../services/employee.service";
var styles_SortByComponent = [];
var RenderType_SortByComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SortByComponent, data: {} });
export { RenderType_SortByComponent as RenderType_SortByComponent };
export function View_SortByComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "label", [["class", "FormElement-label u-hiddenVisually"], ["for", "comboboxSort"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-combobox-edit", [["class", "FormElement-select"], ["id", "comboboxSort"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.employeeService.sortBy = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ComboboxEditComponent_0, i2.RenderType_ComboboxEditComponent)), i0.ɵdid(4, 4440064, null, 0, i3.ComboboxEditComponent, [i4.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], idField: [2, "idField"], textField: [3, "textField"], comboboxDataSource: [4, "comboboxDataSource"], disable: [5, "disable"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.employeeService.sortBy; var currVal_2 = true; var currVal_3 = "Id"; var currVal_4 = "Text"; var currVal_5 = _co.sortByDataSource; var currVal_6 = _co.employeeService.editMode; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Employee.SortByFirstNameSurname")); _ck(_v, 1, 0, currVal_0); }); }
export function View_SortByComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sort-by", [], null, null, null, View_SortByComponent_0, RenderType_SortByComponent)), i0.ɵdid(1, 245760, null, 0, i5.SortByComponent, [i6.EmployeeService, i1.TranslateService, i4.SessionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SortByComponentNgFactory = i0.ɵccf("app-sort-by", i5.SortByComponent, View_SortByComponent_Host_0, {}, {}, []);
export { SortByComponentNgFactory as SortByComponentNgFactory };
