import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';
import { ReportDialogService } from '../../../shared-components/report-dialog/report-dialog.service';
import { CompanyTemplatesService } from '../../configuration/templates/templates.service';
import { ResidualVacationService } from '../company-balances.services';
import * as i0 from "@angular/core";
import * as i1 from "../company-balances.services";
import * as i2 from "../../../services/session/session.service";
import * as i3 from "../../../services/api/data.service";
import * as i4 from "../../../services/api/static-data.service";
import * as i5 from "../../../shared-components/report-dialog/report-dialog.service";
import * as i6 from "@uirouter/core";
import * as i7 from "../../../services/broadcast.service";
import * as i8 from "../../../services/setting.service";
import * as i9 from "../../configuration/templates/templates.service";
var CompanyResidualVacationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyResidualVacationComponent, _super);
    function CompanyResidualVacationComponent(service, sessionService, dataService, staticDataService, reportDialogService, transitionService, broadcaster, settingService, companyTemplatesService) {
        var _this = _super.call(this, dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) || this;
        _this.service = service;
        _this.sessionService = sessionService;
        _this.dataService = dataService;
        _this.staticDataService = staticDataService;
        _this.reportDialogService = reportDialogService;
        _this.transitionService = transitionService;
        _this.broadcaster = broadcaster;
        _this.settingService = settingService;
        _this.companyTemplatesService = companyTemplatesService;
        _this.refreshData = false;
        _this.gridDataSource = [];
        _this.spinnerOption = {
            max: undefined,
            min: undefined,
            step: 1,
            spinners: true,
            format: 'n2'
        };
        _this.isShowDataInvalidAlert = false;
        _this.isDataValid = true;
        _this.isShowAfterSaveSucess = false;
        _this.companyUser = [];
        _this.isSaing = false;
        _this.isSmallScreenValue = false;
        _this.ngUnsubscribe = new Subject();
        _this.service.showReportShortcutChange.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (value) {
            if (value && _this.reportDialogService.isVisibleReportIcon) {
                _this.reportDialogService.onShowReportsEventClick();
            }
        });
        _this.staticDataService.companyUsers.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (value) {
            _this.companyUser = value;
        });
        return _this;
    }
    Object.defineProperty(CompanyResidualVacationComponent.prototype, "isUsesInHouseVacation", {
        get: function () {
            if (this.companyUser && this.companyUser.length > 0) {
                return this.companyUser.some(function (item) {
                    return !!item.UserEmployments.find(function (employment) { return employment.VacationProviderId === 99; });
                });
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyResidualVacationComponent.prototype, "moduleContent", {
        get: function () {
            if (this.isUsesInHouseVacation) {
                return 1;
            }
            return 4;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyResidualVacationComponent.prototype, "isSmallScreen", {
        get: function () {
            return this.isSmallScreenValue;
        },
        set: function (value) {
            if (value !== this.isSmallScreenValue) {
                this.isSmallScreenValue = value;
                this.refreshData = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    CompanyResidualVacationComponent.prototype.onResize = function (event) {
        this.onScreenCheck();
    };
    CompanyResidualVacationComponent.prototype.onScreenCheck = function () {
        this.isSmallScreen = window.innerWidth <= 1440;
    };
    Object.defineProperty(CompanyResidualVacationComponent.prototype, "minCellWidth", {
        get: function () {
            return this.isSmallScreen ? 400 : 150;
        },
        enumerable: true,
        configurable: true
    });
    CompanyResidualVacationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.onScreenCheck();
        this.staticDataService.loadCompanyUsers();
        this.companyTemplatesService.loadTemplates();
        this.service.VacationPeriod.pipe().subscribe(function (model) {
            if (model && model.length > 0 && !_this.service.VacationPeriodId) {
                model.forEach(function (item) {
                    var itemStartDate = new Date(item.SpendingStartDate).getTime();
                    var itemEndDate = new Date(item.SpendingEndDate).getTime();
                    var toDay = new Date().getTime();
                    if (toDay >= itemStartDate && toDay <= itemEndDate) {
                        _this.service.VacationPeriodId = item.Id;
                        return;
                    }
                });
                if (!_this.service.VacationPeriodId) {
                    _this.service.VacationPeriodId = model[0].Id;
                }
            }
        });
        this.service.BalancesEmployeeViewByDate.pipe().subscribe(function (model) {
            // copy dirty records
            var arrayItemDirty = [];
            if (_this.service.originalDataBalancesEmployeeViewByDate &&
                _this.service.originalDataBalancesEmployeeViewByDate.length > 0) {
                arrayItemDirty = _this.service.originalDataBalancesEmployeeViewByDate.filter(function (item) { return item.isDirty === true; });
            }
            _this.gridDataSource = model;
            _this.gridDataSource.forEach(function (item) {
                if (item.DaysTotal || item.DaysTotal === 0) {
                    item.DaysTotal = parseFloat(parseFloat(item.DaysTotal).toFixed(2));
                }
                _this.caculateLeft(item);
                item.isDirty = false;
            });
            if (_this.service.isFirstLoad) {
                _this.service.isFirstLoad = false;
            }
            else {
                if (arrayItemDirty && arrayItemDirty.length > 0) {
                    arrayItemDirty.forEach(function (item) {
                        var recordDirty = _this.service.originalDataBalancesEmployeeViewByDate.find(function (find) { return find.UserEmploymentId === item.UserEmploymentId; });
                        if (recordDirty) {
                            recordDirty.isDirty = true;
                        }
                    });
                }
            }
        });
    };
    CompanyResidualVacationComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(CompanyResidualVacationComponent.prototype, "isDirtyData", {
        get: function () {
            if (this.gridDataSource && this.gridDataSource.length > 0) {
                return this.gridDataSource.some(function (item) { return item.isDirty; });
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyResidualVacationComponent.prototype, "isReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyResidualVacationComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyResidualVacationComponent.prototype, "isIOSApp", {
        get: function () {
            return this.sessionService.browser.isHybridApp && this.sessionService.browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyResidualVacationComponent.prototype, "isDisableSubmitButton", {
        get: function () {
            return this.isReadOnly || !this.service.effectivePer;
        },
        enumerable: true,
        configurable: true
    });
    CompanyResidualVacationComponent.prototype.onGridSave = function (event) {
        if (event.dataItem) {
            event.dataItem.SpendDays =
                event.dataItem.SpendDays && event.dataItem.SpendDays > 0
                    ? event.dataItem.SpendDays * -1
                    : event.dataItem.SpendDays;
            event.dataItem.MoveDays =
                event.dataItem.MoveDays && event.dataItem.MoveDays > 0 ? event.dataItem.MoveDays * -1 : event.dataItem.MoveDays;
            event.dataItem.PayoutDays =
                event.dataItem.PayoutDays && event.dataItem.PayoutDays > 0
                    ? event.dataItem.PayoutDays * -1
                    : event.dataItem.PayoutDays;
            this.caculateLeft(event.dataItem);
        }
    };
    CompanyResidualVacationComponent.prototype.onGridButtonClick = function (event) {
        var feildChnage = '';
        switch (event.buttonAction) {
            case 'MaxSpend':
                feildChnage = 'SpendDays';
                break;
            case 'MaxMove':
                feildChnage = 'MoveDays';
                break;
            default:
                feildChnage = 'PayoutDays';
                break;
        }
        if (feildChnage) {
            if (event.dataItem['left'] === 0) {
                event.dataItem['disbaleMaxButton'] = true;
                return;
            }
            else {
                this.caculateMaxValue(feildChnage, event.dataItem);
            }
        }
    };
    CompanyResidualVacationComponent.prototype.MaxFieldDays = function (field) {
        var _this = this;
        if (this.isReadOnly) {
            return;
        }
        this.gridDataSource.forEach(function (item) {
            if (item['left'] !== 0) {
                _this.caculateMaxValue(field, item);
            }
        });
    };
    CompanyResidualVacationComponent.prototype.caculateMaxValue = function (feildMax, data) {
        if (data['left'] === 0) {
            data['disbaleMaxButton'] = true;
            return;
        }
        else {
            var leftValue = parseFloat(parseFloat(data['left']).toFixed(2));
            var dataFeildMax = data[feildMax] ? data[feildMax] : 0;
            data[feildMax] = leftValue * -1 + parseFloat(parseFloat(dataFeildMax).toFixed(2));
            data[feildMax] = parseFloat(parseFloat(data[feildMax]).toFixed(2));
            this.caculateLeft(data);
            this.refreshData = true;
        }
    };
    CompanyResidualVacationComponent.prototype.caculateLeft = function (data) {
        var DaysTotalValues = data.DaysTotal ? data.DaysTotal : 0;
        var AdjustDaysValues = data.AdjustDays ? data.AdjustDays : 0;
        var SpendDaysValues = data.SpendDays ? data.SpendDays : 0;
        var MoveDaysValues = data.MoveDays ? data.MoveDays : 0;
        var PayoutDaysValues = data.PayoutDays ? data.PayoutDays : 0;
        data.left = this.sessionService.parseFloat(DaysTotalValues +
            (parseFloat(parseFloat(AdjustDaysValues).toFixed(2)) +
                parseFloat(parseFloat(SpendDaysValues).toFixed(2)) +
                parseFloat(parseFloat(MoveDaysValues).toFixed(2)) +
                parseFloat(parseFloat(PayoutDaysValues).toFixed(2))));
        data.left = parseFloat(parseFloat(data.left).toFixed(2));
        if (data.left < 0) {
            data.errorLeftClass = 'left-error';
            data['disbaleMaxButton'] = false;
        }
        else {
            data.errorLeftClass = '';
            data['disbaleMaxButton'] = false;
            if (data.left === 0) {
                data['disbaleMaxButton'] = true;
            }
        }
        data.isDirty = true;
    };
    CompanyResidualVacationComponent.prototype.submitGridValues = function () {
        if (!this.isDirtyData) {
            return;
        }
        var request = {
            BalanceDefinitionId: 1,
            BalancePeriodId: this.service.VacationPeriodId,
            PerDate: this.service.effectivePer
        };
        var DetailDataSource = [];
        var countError = 0;
        this.gridDataSource.forEach(function (item) {
            if (item.isDirty) {
                DetailDataSource.push({
                    UserEmploymentId: item.UserEmploymentId || null,
                    AdjustDays: item.AdjustDays || null,
                    SpendDays: item.SpendDays || null,
                    MoveDays: item.MoveDays || null,
                    PayoutDays: item.PayoutDays || null,
                    AdjustHours: item.AdjustHours || null,
                    SpendHours: item.SpendHours || null,
                    PayoutHours: item.PayoutHours || null
                });
                if (item.left < 0) {
                    countError += 1;
                }
            }
        });
        if (countError > 0) {
            this.isDataValid = false;
        }
        else {
            this.isDataValid = true;
        }
        request.Details = DetailDataSource;
        if (this.isDataValid) {
            if (request.Details && request.Details.length > 0) {
                this.save(request);
            }
        }
        else {
            this.isShowDataInvalidAlert = true;
        }
    };
    CompanyResidualVacationComponent.prototype.onSubmitButtonClick = function () {
        var _this = this;
        setTimeout(function () {
            _this.submitGridValues();
        }, 500);
    };
    // public onEffecivePerKeyDown(event: any): void {
    //   if (event === 'Enter') {
    //     this.service.Reload_Balances_GetResidualVacationBalances();
    //   }
    // }
    CompanyResidualVacationComponent.prototype.save = function (model) {
        if (this.isSaing) {
            return;
        }
        this.isSaing = true;
        _super.prototype.save.call(this, model);
    };
    CompanyResidualVacationComponent.prototype.afterSaveSucess = function (data) {
        var _this = this;
        if (data && data.length > 0) {
            data.forEach(function (item) {
                var itemAfterSave = _this.gridDataSource.find(function (model) { return model.UserEmploymentId === item.UserEmploymentId; });
                if (itemAfterSave) {
                    itemAfterSave.FullName = item.FullName;
                    itemAfterSave.BasedOnTemplateName = item.BasedOnTemplateName;
                    itemAfterSave.DepartmentName = item.DepartmentName;
                    itemAfterSave.ExternalReference = item.ExternalReference;
                    itemAfterSave.BaseAmount = item.BaseAmount || null;
                    itemAfterSave.AmountEarned = item.AmountEarned || null;
                    itemAfterSave.DaysEarned = item.DaysEarned || null;
                    itemAfterSave.DaysSpent = item.DaysSpent || null;
                    itemAfterSave.DaysTotal = item.DaysTotal || null;
                    itemAfterSave.SecondaryDaysEarned = item.SecondaryDaysEarned || null;
                    itemAfterSave.SecondaryDaysSpent = item.SecondaryDaysSpent || null;
                    itemAfterSave.CurrentBalanceSecondaryDays = item.CurrentBalanceSecondaryDays || null;
                    itemAfterSave.SecondaryAmountTotal = item.SecondaryAmountTotal || null;
                    itemAfterSave.DaysEarned = itemAfterSave.DaysEarned + itemAfterSave.SecondaryDaysEarned;
                    itemAfterSave.DaysSpent = itemAfterSave.DaysSpent + itemAfterSave.SecondaryDaysSpent;
                    itemAfterSave.DaysTotal = itemAfterSave.DaysTotal + itemAfterSave.CurrentBalanceSecondaryDays;
                    itemAfterSave.AmountTotal = itemAfterSave.AmountTotal + itemAfterSave.SecondaryAmountTotal;
                    itemAfterSave.AdjustDays = null;
                    itemAfterSave.SpendDays = null;
                    itemAfterSave.MoveDays = null;
                    itemAfterSave.PayoutDays = null;
                    _this.caculateLeft(itemAfterSave);
                    itemAfterSave.isDirty = false;
                }
            });
            this.refreshData = true;
        }
        this.isShowAfterSaveSucess = true;
    };
    CompanyResidualVacationComponent.prototype.completeSave = function () {
        this.isSaing = false;
    };
    CompanyResidualVacationComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyResidualVacationComponent_Factory() { return new CompanyResidualVacationComponent(i0.ɵɵinject(i1.ResidualVacationService), i0.ɵɵinject(i2.SessionService), i0.ɵɵinject(i3.DataService), i0.ɵɵinject(i4.StaticDataService), i0.ɵɵinject(i5.ReportDialogService), i0.ɵɵinject(i6.TransitionService), i0.ɵɵinject(i7.BroadcastService), i0.ɵɵinject(i8.SettingService), i0.ɵɵinject(i9.CompanyTemplatesService)); }, token: CompanyResidualVacationComponent, providedIn: "root" });
    return CompanyResidualVacationComponent;
}(ResidualVacationService));
export { CompanyResidualVacationComponent };
