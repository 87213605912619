import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from 'src/app/common/global';
import { BillingUsage } from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
var RegisterSupportUsageDialogComponent = /** @class */ (function () {
    function RegisterSupportUsageDialogComponent(dataService, staticDataService) {
        this.dataService = dataService;
        this.staticDataService = staticDataService;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.billingUsage = new BillingUsage();
        this.moduleAdHocSupport = [];
        this.isShowEmailSupport = false;
        this.isShowPhoneSupport = false;
        this.billingPrinciple = [];
        this.NumericOptions = { format: 'n2', decimals: 4, step: 1, min: null, spinners: false };
        this.isHavePhoneSupport = false;
        this.isHaveEmailSupport = false;
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(RegisterSupportUsageDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            this.visibleValue = value;
            this.visibleChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    RegisterSupportUsageDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.checkModuleWarning();
        this.staticDataService.moduleCompanyView
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) {
            _this.moduleAdHocSupport = JSON.parse(JSON.stringify(data));
            _this.checkModuleWarning();
            _this.defaultBillingUsage();
        });
        this.staticDataService.BillingPrinciple.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.billingPrinciple = data); });
    };
    RegisterSupportUsageDialogComponent.prototype.checkModuleWarning = function () {
        var _this = this;
        if (this.hasModuleKey('EmailSupport')) {
            this.isShowPhoneSupport = false;
            this.isShowEmailSupport = true;
            this.isHaveEmailSupport = true;
        }
        if (this.hasModuleKey('PhoneSupport')) {
            this.isShowPhoneSupport = true;
            this.isShowEmailSupport = false;
            this.isHavePhoneSupport = true;
        }
        this.staticDataService.currentAccount.pipe().subscribe(function (account) {
            if (account && account.IsPublic && Global.COMPANY.AccountId === account.Id) {
                _this.isShowPhoneSupport = true;
                _this.isShowEmailSupport = false;
                _this.isHavePhoneSupport = true;
                _this.isHaveEmailSupport = true;
            }
            if (_this.moduleAdHocSupport && _this.moduleAdHocSupport.length > 0) {
                _this.moduleAdHocSupport.forEach(function (model) {
                    if (model.ModuleKey === 'BillableSupport' && (_this.isHaveEmailSupport || _this.isHavePhoneSupport)) {
                        model.ModulePrice = 0;
                    }
                    else if (model.ModuleKey === 'EmailSupport' && _this.isHaveEmailSupport) {
                        model.ModulePrice = 0;
                    }
                    else if (model.ModuleKey === 'PhoneSupport' && _this.isHavePhoneSupport) {
                        model.ModulePrice = 0;
                    }
                });
                _this.defaultBillingUsage();
            }
        });
    };
    RegisterSupportUsageDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    RegisterSupportUsageDialogComponent.prototype.onSaveAdHocSupport = function (event) {
        var _this = this;
        if (event && event === 'Save') {
            this.dataService.Backend_RegisterUsage(this.billingUsage).subscribe(function () { return _this.onCaculateAmount(); });
        }
        this.defaultBillingUsage();
    };
    RegisterSupportUsageDialogComponent.prototype.hasModuleKey = function (key) {
        if (this.moduleAdHocSupport && this.moduleAdHocSupport.length > 0) {
            var objectModule = this.moduleAdHocSupport.find(function (model) { return model.ModuleKey === key; });
            if (objectModule && objectModule.IsEnabled) {
                return true;
            }
        }
        return false;
    };
    RegisterSupportUsageDialogComponent.prototype.defaultBillingUsage = function () {
        this.billingUsage = new BillingUsage();
        this.billingUsage.Units = 1;
        this.billingUsage.BillingDate = this.staticDataService.getCurrentdate();
        if (this.moduleAdHocSupport && this.moduleAdHocSupport.length > 0) {
            var objectModule = this.moduleAdHocSupport.find(function (model) { return model.ModuleKey === 'BillableSupport'; });
            if (objectModule) {
                this.billingUsage.ModuleId = objectModule.ModuleId;
                this.onBillingPrincipChange();
            }
        }
    };
    RegisterSupportUsageDialogComponent.prototype.onBillingPrincipChange = function () {
        var _this = this;
        if (this.moduleAdHocSupport && this.moduleAdHocSupport.length > 0) {
            var objectModule = this.moduleAdHocSupport.find(function (model) { return model.ModuleId === _this.billingUsage.ModuleId; });
            if (objectModule) {
                this.billingUsage.AmountPerUnit = objectModule.ModulePrice ? objectModule.ModulePrice : 0;
                if (objectModule.ModuleBillingPrincipleId) {
                    this.billingUsage.BillingPrincipleId = objectModule.ModuleBillingPrincipleId;
                }
            }
        }
        this.onCaculateAmount();
    };
    RegisterSupportUsageDialogComponent.prototype.onCaculateAmount = function () {
        if (this.billingUsage.Units !== undefined && this.billingUsage.AmountPerUnit !== undefined) {
            this.billingUsage.Amount = this.billingUsage.Units * this.billingUsage.AmountPerUnit;
        }
    };
    return RegisterSupportUsageDialogComponent;
}());
export { RegisterSupportUsageDialogComponent };
