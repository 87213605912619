import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { CustomControlsModule } from '../../custom-controls/custom-controls.module';
import { SharedComponentsModule } from '../../shared-components/shared-components.module';

import { AdvancedTypesComponent } from './advanced-types/advanced-types.component';
import { AdvancedTypesListComponent } from './advanced-types/component/advanced-types-list/advanced-types-list.component';
import { TimeEntryTypeConfigurationsComponent } from './advanced-types/component/time-entry-type-configurations/time-entry-type-configurations.component';
import { SalaryTypesSingleComponent } from './advanced-types/salary-types-single/salary-types-single.component';
import { NewSalaryTypeDialogComponent } from './advanced-types/salary-types/new-salary-type-dialog.component';
import { SalaryTypeTranslationDialogComponent } from './advanced-types/salary-types/salary-type-translation-dialog.component';
import { SalaryTypesComponent } from './advanced-types/salary-types/salary-types.component';
import { StandardSalaryTypesComponent } from './advanced-types/standard-salary-types/standard-salary-types.component';
import { TimeEntryTypesSingleComponent } from './advanced-types/time-entry-types-single/time-entry-types-single.component';
import { NewTimeEntryTypeDialogComponent } from './advanced-types/time-entry-types/new-time-entry-type-dialog.component';
import { TimeEntryTypeConfigurationsDialogComponent } from './advanced-types/time-entry-types/time-entry-type-configurations-dialog.component';
import { TimeEntryTypeTranslationDialogComponent } from './advanced-types/time-entry-types/time-entry-type-translation-dialog.component';
import { TimeEntryTypesComponent } from './advanced-types/time-entry-types/time-entry-types.component';
import { CompanyConfigurationComponent } from './company-configuration.component';
import { CreateImportTemplateDialogComponent } from './data-import/create-import-template-dialog.component';
import { DataImportComponent } from './data-import/data-import.component';
import { ImportErrorsDialogComponent } from './data-import/import-errors-dialog.component';
import { ImportMappingDialogComponent } from './data-import/import-mapping-dialog.component';
import { UploadImportFileDialogComponent } from './data-import/upload-import-file-dialog.component';
import { CompanyDepartmentComponent } from './departments/company-department.component';
import { DimensionDetailComponent } from './dimensions/dimension-detail/dimension-detail.component';
import { DimensionsComponent } from './dimensions/dimensions.component';
import { ConfigurationIntegrationsComponent } from './integrations/integrations-module/configuration-integrations/configuration-integrations.component';
import { CredentialIntegrationsComponent } from './integrations/integrations-module/credential-integrations/credential-integrations.component';
import { ExternalIntegrationsComponent } from './integrations/integrations-module/external-integrations/external-integrations.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { GreenlandTaxComponent } from './templates/components/greenland-tax.component';
import { TemplateEmployeeListComponent } from './templates/components/template-employee-list.component';
import { TemplateListComponent } from './templates/components/template-list.component';
import { AddTemplateDialogComponent } from './templates/dialogs/add-template-dialog.component';
import { OvertimeSupplementDialogComponent } from './templates/dialogs/overtime-supplement-dialog.component';
import { SalaryTemplateDialogComponent } from './templates/dialogs/salary-template-dialog.component';
import { SenioritySupplementDialogComponent } from './templates/dialogs/seniority-supplement-dialog.component';
import { TemplatesComponent } from './templates/templates.component';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule, CustomControlsModule, SharedComponentsModule],
  declarations: [
    CompanyConfigurationComponent,
    CompanyDepartmentComponent,
    DataImportComponent,
    CreateImportTemplateDialogComponent,
    ImportErrorsDialogComponent,
    ImportMappingDialogComponent,
    UploadImportFileDialogComponent,
    IntegrationsComponent,
    SalaryTypesComponent,
    SalaryTypeTranslationDialogComponent,
    NewSalaryTypeDialogComponent,
    StandardSalaryTypesComponent,
    TemplateEmployeeListComponent,
    TemplateListComponent,
    GreenlandTaxComponent,
    AddTemplateDialogComponent,
    OvertimeSupplementDialogComponent,
    SalaryTemplateDialogComponent,
    SenioritySupplementDialogComponent,
    TemplatesComponent,
    NewTimeEntryTypeDialogComponent,
    TimeEntryTypeConfigurationsDialogComponent,
    TimeEntryTypesComponent,
    TimeEntryTypeTranslationDialogComponent,
    CredentialIntegrationsComponent,
    ExternalIntegrationsComponent,
    ConfigurationIntegrationsComponent,
    AdvancedTypesComponent,
    AdvancedTypesListComponent,
    SalaryTypesSingleComponent,
    TimeEntryTypesSingleComponent,
    TimeEntryTypeConfigurationsComponent,
    DimensionsComponent,
    DimensionDetailComponent
  ],
  exports: [CompanyConfigurationComponent],
  entryComponents: [CompanyConfigurationComponent]
})
export class CompanyConfigurationModule {}
