/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../custom-controls/icon/icon.component.ngfactory";
import * as i2 from "../../../custom-controls/icon/icon.component";
import * as i3 from "../../../services/session/session.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "../../../custom-controls/combobox-edit/combobox-edit.component.ngfactory";
import * as i7 from "../../../custom-controls/combobox-edit/combobox-edit.component";
import * as i8 from "../../../custom-controls/text-edit/text-edit.component.ngfactory";
import * as i9 from "../../../custom-controls/text-edit/text-edit.component";
import * as i10 from "../../new-employment/new-employment-dialog.component.ngfactory";
import * as i11 from "../../new-employment/new-employment-dialog.component";
import * as i12 from "../../../services/api/static-data.service";
import * as i13 from "../../../services/api/data.service";
import * as i14 from "../../../services/employee.service";
import * as i15 from "./position-info.component";
import * as i16 from "../../../services/setting.service";
var styles_PositionInfoComponent = [];
var RenderType_PositionInfoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PositionInfoComponent, data: {} });
export { RenderType_PositionInfoComponent as RenderType_PositionInfoComponent };
function View_PositionInfoComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-icon", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(1, 49152, null, 0, i2.IconComponent, [i0.ElementRef, i3.SessionService, i0.ChangeDetectorRef], { icon: [0, "icon"], size: [1, "size"], tooltip: [2, "tooltip"] }, { click: "click" }), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "circleplus"; var currVal_1 = "small"; var currVal_2 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 2).transform("EmployeeGeneral.CreateEmployment")); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_PositionInfoComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "FormElement-addon FormElement-addonSpace Icon--text"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PositionInfoComponent_3)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.isSelfService && _co.sessionService.role.IsSalaryAdminAndHigher); _ck(_v, 2, 0, currVal_0); }, null); }
function View_PositionInfoComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "FormElement EmployeeInfo-position"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [["class", "FormElement-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ":"])), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "FormElement-control"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "FormElement-static"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PositionInfoComponent_2)), i0.ɵdid(8, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.isEmployeeTab && !_co.sessionService.role.IsReadOnly); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Employee.Title")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.userEmploymentDataSource[0].DisplayTitle; _ck(_v, 6, 0, currVal_1); }); }
function View_PositionInfoComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-combobox-edit", [["class", "EmployeeInfo-position"]], null, [[null, "isValidChange"], [null, "isDirtyChange"], [null, "valueChange"], [null, "addonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isValidChange" === en)) {
        var pd_0 = ((_co.childIsValid[1] = $event) !== false);
        ad = (pd_0 && ad);
    } if (("isDirtyChange" === en)) {
        var pd_1 = ((_co.childIsDirty[1] = $event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = ((_co.currentPositionId = $event) !== false);
        ad = (pd_2 && ad);
    } if (("valueChange" === en)) {
        var pd_3 = (_co.onPositionChangedEvent($event) !== false);
        ad = (pd_3 && ad);
    } if (("addonClick" === en)) {
        var pd_4 = (_co.onAddonClick() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i6.View_ComboboxEditComponent_0, i6.RenderType_ComboboxEditComponent)), i0.ɵdid(1, 4440064, null, 0, i7.ComboboxEditComponent, [i3.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], isDirty: [2, "isDirty"], isValid: [3, "isValid"], idField: [4, "idField"], textField: [5, "textField"], keepDataSourceOrder: [6, "keepDataSourceOrder"], label: [7, "label"], comboboxDataSource: [8, "comboboxDataSource"], addonVisible: [9, "addonVisible"], addonIcon: [10, "addonIcon"], addonTooltip: [11, "addonTooltip"], addonSize: [12, "addonSize"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange", addonClick: "addonClick" }), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentPositionId; var currVal_1 = true; var currVal_2 = _co.childIsDirty[1]; var currVal_3 = _co.childIsValid[1]; var currVal_4 = "Id"; var currVal_5 = "DisplayTitle"; var currVal_6 = true; var currVal_7 = i0.ɵunv(_v, 1, 7, i0.ɵnov(_v, 2).transform("Employee.Title")); var currVal_8 = _co.userEmploymentDataSource; var currVal_9 = ((_co.isEmployeeTab && !_co.isSelfService) && _co.sessionService.role.IsSalaryAdminAndHigher); var currVal_10 = "circleplus"; var currVal_11 = i0.ɵunv(_v, 1, 11, i0.ɵnov(_v, 3).transform("EmployeeGeneral.CreateEmployment")); var currVal_12 = "small"; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }, null); }
function View_PositionInfoComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-text-edit", [], null, [[null, "isValidChange"], [null, "isDirtyChange"], [null, "valueChange"], [null, "addonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isValidChange" === en)) {
        var pd_0 = ((_co.childIsValid[0] = $event) !== false);
        ad = (pd_0 && ad);
    } if (("isDirtyChange" === en)) {
        var pd_1 = ((_co.childIsDirty[0] = $event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = ((_co.currentUserEmployment.Title = $event) !== false);
        ad = (pd_2 && ad);
    } if (("valueChange" === en)) {
        var pd_3 = (_co.onPositionTextInputChange() !== false);
        ad = (pd_3 && ad);
    } if (("addonClick" === en)) {
        var pd_4 = (_co.onAddonClick() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i8.View_TextEditComponent_0, i8.RenderType_TextEditComponent)), i0.ɵdid(1, 114688, null, 0, i9.TextEditComponent, [i3.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { type: [0, "type"], value: [1, "value"], editMode: [2, "editMode"], isDirty: [3, "isDirty"], isValid: [4, "isValid"], label: [5, "label"], required: [6, "required"], addonIcon: [7, "addonIcon"], addonTooltip: [8, "addonTooltip"], addonSize: [9, "addonSize"], addonVisible: [10, "addonVisible"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange", addonClick: "addonClick" }), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "text"; var currVal_1 = _co.currentUserEmployment.Title; var currVal_2 = _co.editMode; var currVal_3 = _co.childIsDirty[0]; var currVal_4 = _co.childIsValid[0]; var currVal_5 = i0.ɵunv(_v, 1, 5, i0.ɵnov(_v, 2).transform("Employee.Title")); var currVal_6 = true; var currVal_7 = "circleplus"; var currVal_8 = i0.ɵunv(_v, 1, 8, i0.ɵnov(_v, 3).transform("EmployeeGeneral.CreateEmployment")); var currVal_9 = "small"; var currVal_10 = ((_co.isEmployeeTab && !_co.isSelfService) && _co.sessionService.role.IsSalaryAdminAndHigher); _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); }, null); }
export function View_PositionInfoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_PositionInfoComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PositionInfoComponent_4)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PositionInfoComponent_5)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "app-new-employment-dialog", [], null, [[null, "isVisibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isVisibleChange" === en)) {
        var pd_0 = ((_co.newEmploymentDialogVisible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_NewEmploymentDialogComponent_0, i10.RenderType_NewEmploymentDialogComponent)), i0.ɵdid(7, 245760, null, 0, i11.NewEmploymentDialogComponent, [i3.SessionService, i12.StaticDataService, i13.DataService, i14.EmployeeService], { currentCompanyUserId: [0, "currentCompanyUserId"], isVisible: [1, "isVisible"] }, { isVisibleChange: "isVisibleChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.editMode && _co.hasSingleEmployment); _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_co.editMode && _co.hasMultipleEmployments); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.allowEditTitle; _ck(_v, 5, 0, currVal_2); var currVal_3 = ((_co.companyUser == null) ? null : _co.companyUser.Id); var currVal_4 = _co.newEmploymentDialogVisible; _ck(_v, 7, 0, currVal_3, currVal_4); }, null); }
export function View_PositionInfoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-position-info", [], null, null, null, View_PositionInfoComponent_0, RenderType_PositionInfoComponent)), i0.ɵdid(1, 245760, null, 0, i15.PositionInfoComponent, [i14.EmployeeService, i3.SessionService, i16.SettingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PositionInfoComponentNgFactory = i0.ɵccf("app-position-info", i15.PositionInfoComponent, View_PositionInfoComponent_Host_0, { editMode: "editMode", isDirty: "isDirty", isValid: "isValid", isEmployeeTab: "isEmployeeTab", companyUser: "companyUser", currentPositionId: "currentPositionId" }, { editModeChange: "editModeChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange", valueChange: "valueChange", positionTextChanged: "positionTextChanged", positionIdChanged: "positionIdChanged" }, []);
export { PositionInfoComponentNgFactory as PositionInfoComponentNgFactory };
