import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ISalaryBatchValidationIssue } from 'src/app/services/api/api-model';

@Component({
  selector: 'app-payroll-issues',
  templateUrl: './payroll-issues.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PayrollIssuesComponent {
  @Input() public listPayrollIssues: ISalaryBatchValidationIssue[];
  @Input() public isError: boolean;
  @Input() public visible: boolean;
}
