/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./check-edit-slide.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./check-edit-slide.component";
var styles_CheckEditSlideComponent = [i0.styles];
var RenderType_CheckEditSlideComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CheckEditSlideComponent, data: {} });
export { RenderType_CheckEditSlideComponent as RenderType_CheckEditSlideComponent };
export function View_CheckEditSlideComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "label", [["class", "switch"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "input", [["checked", "value"], ["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "click"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.value = $event) !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.onClick($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(4, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [["class", "slider round"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.isDisabled; var currVal_8 = _co.value; _ck(_v, 4, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_CheckEditSlideComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-check-edit-slide", [], null, null, null, View_CheckEditSlideComponent_0, RenderType_CheckEditSlideComponent)), i1.ɵdid(1, 114688, null, 0, i3.CheckEditSlideComponent, [i1.ElementRef, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CheckEditSlideComponentNgFactory = i1.ɵccf("app-check-edit-slide", i3.CheckEditSlideComponent, View_CheckEditSlideComponent_Host_0, { defaultnullvalue: "defaultnullvalue", type: "type", isGrid: "isGrid", preventChange: "preventChange", value: "value", editMode: "editMode", isDirty: "isDirty", isValid: "isValid", setTimeout: "setTimeout", hostClass: "hostClass", label: "label", tooltip: "tooltip", disable: "disable", helpLink: "helpLink", helpTooltip: "helpTooltip", isOpenLink: "isOpenLink", customClass: "customClass", helpIcon: "helpIcon", selfService: "selfService", isLink: "isLink", openLinkInTitle: "openLinkInTitle", isAccountantConfig: "isAccountantConfig" }, { valueChange: "valueChange", editModeChange: "editModeChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange" }, []);
export { CheckEditSlideComponentNgFactory as CheckEditSlideComponentNgFactory };
