<app-dialog *ngIf="!isTab" (action)="onDialogAction($event)" [(visible)]="visible">
  <div *ngIf="isFinalizedOrRevert">
    <app-grid
      [editMode]="false"
      [data]="companySalaryBatchService.salaryBatchErrorsView | async"
      class="FinalizedErrorsDetailsGrid u-gridHeight350"
    >
      <app-grid-column
        [iconField]="'icon'"
        [tooltipField]="'iconTooltip'"
        [title]="'CompanySalaryBatches.FinalizedGridField_IsError' | translate"
        [type]="'icon'"
        [width]="40"
      >
      </app-grid-column>
      <app-grid-column
        [field]="'RefLineNo'"
        [title]="'CompanySalaryBatches.FinalizedGridField_RefLineNo' | translate"
        [type]="'text'"
        [width]="50"
      >
      </app-grid-column>
      <app-grid-column
        [field]="'RefFieldNo'"
        [title]="'CompanySalaryBatches.FinalizedGridField_RefFieldNo' | translate"
        [type]="'text'"
        [width]="50"
      >
      </app-grid-column>
      <app-grid-column
        [field]="'ResponseCode'"
        [title]="'CompanySalaryBatches.FinalizedGridField_ResponseCode' | translate"
        [type]="'text'"
        [width]="60"
      >
      </app-grid-column>
      <app-grid-column
        [field]="'ResponseText'"
        [title]="'CompanySalaryBatches.FinalizedGridField_ResponseText' | translate"
        [type]="'text'"
        [width]="250"
      >
      </app-grid-column>
      <app-grid-column
        [field]="'CprOrSeNo'"
        [title]="'CompanySalaryBatches.FinalizedGridField_CprOrSeNo' | translate"
        [type]="'text'"
        [width]="75"
      >
      </app-grid-column>
      <app-grid-column
        [field]="'ReceivedOn'"
        [title]="'CompanySalaryBatches.FinalizedGridField_ReceivedOn' | translate"
        [type]="'datepicker'"
        [width]="75"
      >
      </app-grid-column>
    </app-grid>
  </div>

  <div *ngIf="!isFinalizedOrRevert">
    <app-salary-batch-other-error-grid
      [visible]="visible"
      class="SalaryBatchOtherErrorsGrid"
      [validationRecord]="companySalaryBatchService.salaryBatchErrorsView | async"
    ></app-salary-batch-other-error-grid>
  </div>

  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action
    *ngIf="isFinalizedOrRevert && sessionService.role.IsFullAdmin"
    [close]="false"
    [type]="'ClearErrors'"
  ></app-dialog-action>
  <app-dialog-action
    *ngIf="isFinalizedOrRevert && sessionService.role.IsFullAdmin"
    [type]="'Resend'"
  ></app-dialog-action>
</app-dialog>

<div *ngIf="isTab">
  <div *ngIf="isFinalizedOrRevert">
    <app-grid
      [editMode]="false"
      [data]="companySalaryBatchService.salaryBatchErrorsView | async"
      class="u-gridHeight350"
    >
      <app-grid-column
        [iconField]="'icon'"
        [tooltipField]="'iconTooltip'"
        [title]="'CompanySalaryBatches.FinalizedGridField_IsError' | translate"
        [type]="'icon'"
        [width]="50"
      >
      </app-grid-column>
      <app-grid-column
        [field]="'RefLineNo'"
        [title]="'CompanySalaryBatches.FinalizedGridField_RefLineNo' | translate"
        [type]="'text'"
        [width]="90"
      >
      </app-grid-column>
      <app-grid-column
        [field]="'RefFieldNo'"
        [title]="'CompanySalaryBatches.FinalizedGridField_RefFieldNo' | translate"
        [type]="'text'"
        [width]="90"
      >
      </app-grid-column>
      <app-grid-column
        [field]="'ResponseCode'"
        [title]="'CompanySalaryBatches.FinalizedGridField_ResponseCode' | translate"
        [type]="'text'"
        [width]="120"
      >
      </app-grid-column>
      <app-grid-column
        [field]="'ResponseText'"
        [title]="'CompanySalaryBatches.FinalizedGridField_ResponseText' | translate"
        [type]="'text'"
        [width]="160"
      >
      </app-grid-column>
      <app-grid-column
        [field]="'CprOrSeNo'"
        [title]="'CompanySalaryBatches.FinalizedGridField_CprOrSeNo' | translate"
        [type]="'text'"
        [width]="100"
      >
      </app-grid-column>
      <app-grid-column
        [field]="'ReceivedOn'"
        [title]="'CompanySalaryBatches.FinalizedGridField_ReceivedOn' | translate"
        [type]="'datepicker'"
        [width]="100"
      >
      </app-grid-column>
    </app-grid>
  </div>

  <div *ngIf="!isFinalizedOrRevert">
    <app-salary-batch-other-error-grid
      [visible]="visible"
      [validationRecord]="companySalaryBatchService.salaryBatchErrorsView | async"
    ></app-salary-batch-other-error-grid>
  </div>

  <app-dialog-buttons
    (buttonAction)="onDialogAction($event)"
    *ngIf="isFinalizedOrRevert && sessionService.role.IsFullAdmin"
  >
    <!--<app-dialog-action [type]="'Close'"></app-dialog-action>-->
    <app-dialog-action [close]="false" [type]="'ClearErrors'"></app-dialog-action>
    <app-dialog-action [type]="'Resend'"></app-dialog-action>
  </app-dialog-buttons>
</div>

<app-dialog
  [(visible)]="clearErrorsDialogVisible"
  (action)="clearErrorsDialogAction($event)"
  [leadingText]="'CompanySalaryBatches.ClearErrorsMessage' | translate: { SystemAlias: branding.SystemAlias }"
>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Proceed'"></app-dialog-action>
</app-dialog>
