/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../buttons/button.component.ngfactory";
import * as i2 from "../buttons/button.component";
import * as i3 from "../../services/session/session.service";
import * as i4 from "@angular/common";
import * as i5 from "./file-upload.component";
import * as i6 from "../../services/api/static-data.service";
var styles_FileUploadComponent = [];
var RenderType_FileUploadComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FileUploadComponent, data: {} });
export { RenderType_FileUploadComponent as RenderType_FileUploadComponent };
function View_FileUploadComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-button", [], null, [[null, "clickEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickEvent" === en)) {
        var pd_0 = (_co.addFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(1, 49152, null, 0, i2.ButtonComponent, [i3.SessionService], { label: [0, "label"], buttonClass: [1, "buttonClass"] }, { clickEvent: "clickEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; var currVal_1 = "Button--card"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_FileUploadComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { file: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["file", 1]], null, 0, "input", [["type", "file"]], [[8, "hidden", 0], [8, "accept", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileAdded() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_1)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.label; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.accept; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_FileUploadComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-file-upload", [], null, null, null, View_FileUploadComponent_0, RenderType_FileUploadComponent)), i0.ɵdid(1, 49152, null, 0, i5.FileUploadComponent, [i6.StaticDataService], null, null)], null, null); }
var FileUploadComponentNgFactory = i0.ɵccf("app-file-upload", i5.FileUploadComponent, View_FileUploadComponent_Host_0, { label: "label", accept: "accept", shortFileName: "shortFileName" }, { shortFileNameChange: "shortFileNameChange", fileSelected: "fileSelected" }, []);
export { FileUploadComponentNgFactory as FileUploadComponentNgFactory };
