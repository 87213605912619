<div class="Card Card--large" *ngIf="companyPreferenceService.isCompanySetting">
  <div class="Card-inner panelbar-view">
    <div class="FormElement hint">
      {{ 'Preference.Warning' | translate }}
    </div>

    <kendo-panelbar #CompanyPanel [expandMode]="2" (stateChange)="onCompanyPanelChanged($event)">
      <kendo-panelbar-item
        class="root-panel"
        *ngFor="let category of companyPreferenceService.companyReferencesCategory | async"
        title=""
      >
        <ng-template kendoPanelBarItemTitle let-dataItem>
          <span class="panel-title">{{ category.Name }}</span>
        </ng-template>

        <kendo-panelbar-item class="child-panel" *ngFor="let item of category.Preferences" [title]="''">
          <ng-template kendoPanelBarItemTitle>
            <span class="panel-title-level-2">{{ item.Name }}</span>
          </ng-template>

          <ng-template kendoPanelBarContent>
            <div class="PreferencesControls mobile-PreferencesControls FormElement-grid k-grid k-widget">
              <div class="item-description">
                {{ item.Description }}
              </div>

              <div [ngClass]="{ 'full-flex-control': !item.Description }" class="item-control">
                <app-module-check [moduleId]="item.RequiredModuleId" [(hasModule)]="item.hasModule">
                  <app-check-edit-slide
                    *ngIf="companyPreferenceService.isDataTypeBoolean(item.DataType)"
                    [(value)]="item.Value"
                    [disable]="item.RequiredModuleId && !item.hasModule"
                    customClass="checkBox-center"
                    [editMode]="!IsReadOnly"
                    (valueChange)="companyPreferenceService.submitValueChange($event, item)"
                  ></app-check-edit-slide>

                  <app-numeric-edit
                    *ngIf="companyPreferenceService.isDataTypeNumber(item.DataType)"
                    [editMode]="true"
                    [disable]="(item.RequiredModuleId && !item.hasModule) || IsReadOnly"
                    [option]="numericTextBoxOption"
                    [customModelOptions]="{ debounce: 1500 }"
                    [centerText]="true"
                    [(value)]="item.Value"
                    (valueChange)="companyPreferenceService.textControlChanges(item)"
                    (keyup)="companyPreferenceService.textControlKeyUp($event, item)"
                    (blur)="companyPreferenceService.textControlBlur(item)"
                  >
                  </app-numeric-edit>

                  <app-text-edit
                    *ngIf="companyPreferenceService.isDataTypeTextBox(item.DataType)"
                    [(value)]="item.Value"
                    [disabled]="(item.RequiredModuleId && !item.hasModule) || IsReadOnly"
                    inputClass="center"
                    [editMode]="true"
                    (valueChange)="companyPreferenceService.textControlChanges(item)"
                    (keyUp)="companyPreferenceService.textControlKeyUp($event, item)"
                    (blur)="companyPreferenceService.textControlBlur(item)"
                  ></app-text-edit>

                  <app-combobox-edit
                    *ngIf="companyPreferenceService.isDataTypeCombobox(item.DataType)"
                    [editMode]="true"
                    [textField]="item.DisplayText"
                    [idField]="item.UnderlyingId"
                    [disable]="(item.RequiredModuleId && !item.hasModule) || IsReadOnly"
                    [comboboxDataSource]="item.DataSource"
                    [(value)]="item.Value"
                    (valueChange)="companyPreferenceService.submitValueChange($event, item)"
                  ></app-combobox-edit>

                  <app-percentage-edit
                    *ngIf="companyPreferenceService.isDataTypePercent(item.DataType)"
                    [editMode]="true"
                    [disable]="(item.RequiredModuleId && !item.hasModule) || IsReadOnly"
                    [(value)]="item.Value"
                    [allowDecimal]="true"
                    [decimals]="2"
                    (valueChange)="companyPreferenceService.textControlChanges(item)"
                    (keyUp)="companyPreferenceService.textControlKeyUp($event, item)"
                    (blur)="companyPreferenceService.textControlBlur(item)"
                  ></app-percentage-edit>
                </app-module-check>
              </div>
            </div>
          </ng-template>
        </kendo-panelbar-item>
      </kendo-panelbar-item>
    </kendo-panelbar>
  </div>
</div>

<kendo-panelbar *ngIf="!companyPreferenceService.isCompanySetting" [expandMode]="2">
  <kendo-panelbar-item [expanded]="true" class="root-panel" title="">
    <ng-template kendoPanelBarItemTitle let-dataItem>
      <span class="panel-title">{{ 'Preference.UserOption' | translate }}</span>
    </ng-template>

    <kendo-panelbar-item
      class="child-panel"
      *ngFor="let item of companyPreferenceService.userPreferences | async"
      [title]="''"
      [expanded]="true"
    >
      <ng-template kendoPanelBarItemTitle>
        <span class="panel-title-level-2">{{ item.Name }}</span>
      </ng-template>

      <ng-template kendoPanelBarContent>
        <div class="PreferencesControls mobile-PreferencesControls FormElement-grid k-grid k-widget">
          <div *ngIf="item.Description" class="item-description">
            {{ item.Description }}
          </div>

          <div class="item-control" [ngClass]="{ 'full-flex-control': !item.Description }">
            <app-check-edit-slide
              *ngIf="companyPreferenceService.isDataTypeBoolean(item.DataType)"
              [(value)]="item.Value"
              customClass="checkBox-center"
              [editMode]="true"
              (valueChange)="companyPreferenceService.submitValueChange($event, item)"
            >
            </app-check-edit-slide>

            <app-numeric-edit
              *ngIf="companyPreferenceService.isDataTypeNumber(item.DataType)"
              [editMode]="true"
              [option]="numericTextBoxOption"
              [customModelOptions]="{ debounce: 1500 }"
              [centerText]="true"
              [(value)]="item.Value"
              (valueChange)="companyPreferenceService.textControlChanges(item)"
              (keyup)="companyPreferenceService.textControlKeyUp($event, item)"
              (blur)="companyPreferenceService.textControlBlur(item)"
            ></app-numeric-edit>

            <app-text-edit
              *ngIf="companyPreferenceService.isDataTypeTextBox(item.DataType)"
              [(value)]="item.Value"
              inputClass="center"
              [editMode]="true"
              (valueChange)="companyPreferenceService.textControlChanges(item)"
              (keyUp)="companyPreferenceService.textControlKeyUp($event, item)"
              (blur)="companyPreferenceService.textControlBlur(item)"
            ></app-text-edit>

            <app-combobox-edit
              *ngIf="companyPreferenceService.isDataTypeCombobox(item.DataType)"
              [editMode]="true"
              [textField]="item.DisplayText"
              [idField]="item.UnderlyingId"
              [comboboxDataSource]="item.DataSource"
              [(value)]="item.Value"
              (valueChange)="companyPreferenceService.submitValueChange($event, item)"
            ></app-combobox-edit>
          </div>
        </div>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar-item>
</kendo-panelbar>
