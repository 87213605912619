import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from 'src/app/common/global';
import { RouterStateService } from 'src/app/common/router-state.service';
import { DataService } from '../../services/api/data.service';
import { SessionService } from '../../services/session/session.service';
import { AccountantSummaryService } from './accountant-summary.service';

@Component({
  selector: 'app-accountant-leave-dialog',
  templateUrl: './accountant-leave-dialog.component.html',
  styleUrls: ['./accountant-leave-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountantLeaveDialogComponent implements OnDestroy, OnInit {
  public busy = false;
  public redirectDialogVisible = false;

  public visibleValue = false;
  @Input()
  get visible(): boolean {
    return this.visibleValue;
  }
  set visible(value: boolean) {
    this.visibleValue = value;
    this.visibleChange.emit(value);
  }
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public translationParam = { CompanyName: '', AccountName: '' };
  public leaveMessage = '';

  private translationServiceTerms: string[];

  constructor(
    public service: AccountantSummaryService,
    private stateService: RouterStateService,
    private sessionService: SessionService,
    private translateService: TranslateService,
    private dataService: DataService
  ) {
    this.translationServiceTerms = ['Accountant.LeaveMessage'];
  }

  public ngOnInit(): void {
    this.service.multiCompanyOrganization.pipe(takeUntil(this.ngUnsubscribe)).subscribe((OrganizationName: string) => {
      this.translationParam.AccountName = OrganizationName;
      this.translationParam.CompanyName = Global.COMPANY.Name;

      this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        this.translateService.get(this.translationServiceTerms).subscribe((translations: { [key: string]: string }) => {
          this.leaveMessage = translations['Accountant.LeaveMessage'];
          this.leaveMessage = this.leaveMessage.replace(/{{AccountName}}/g, OrganizationName);
          this.leaveMessage = this.leaveMessage.replace(/{{CompanyName}}/g, Global.COMPANY.Name);
        });
      });
    });
  }
  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public dialogAction(action: string) {
    if (action === 'Leave') {
      this.busy = true;
      this.service.leaveAccount().subscribe(() => {
        this.busy = false;
        this.redirectDialogVisible = true;
        this.visible = false;
      });
    }
  }

  public redirectToLogin() {
    this.stateService.navigateToState('login');
  }
}
