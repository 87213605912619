import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { Global } from '../../common/global';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { CompanyService } from '../../services/company.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/api/data.service";
import * as i2 from "../../services/api/static-data.service";
import * as i3 from "../../services/setting.service";
import * as i4 from "../../services/session/session.service";
import * as i5 from "@uirouter/core";
import * as i6 from "../../services/broadcast.service";
var CompanyGeneralService = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyGeneralService, _super);
    function CompanyGeneralService(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) {
        var _this = _super.call(this, dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) || this;
        _this.dataService = dataService;
        _this.staticDataService = staticDataService;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.transitionService = transitionService;
        _this.broadcaster = broadcaster;
        _this.companySubject = new BehaviorSubject(null);
        _this.invalidDialogVisible = false;
        _this.isVisibaleHolidayDialog = false;
        _this.isVisibleAddNewHolidayDialog = false;
        _this.ngUnsubscribe = new Subject();
        return _this;
    }
    Object.defineProperty(CompanyGeneralService.prototype, "company", {
        get: function () {
            if (!this.companySubject) {
                this.companySubject.next(Global.COMPANY);
            }
            return this.companySubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyGeneralService.prototype, "companyContact", {
        get: function () {
            if (!this.companyContactSubject) {
                this.loadCompanyContact();
            }
            return this.companyContactSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyGeneralService.prototype, "companyLogo", {
        get: function () {
            if (!this.companyLogoSubject) {
                this.loadCompanyLogo();
            }
            return this.companyLogoSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyGeneralService.prototype, "companyHolidayView", {
        get: function () {
            if (!this.companyHolidayViewSubject) {
                this.loadCompanyHolidayView();
            }
            return this.companyHolidayViewSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CompanyGeneralService.prototype.loadCompanyHolidayView = function () {
        var _this = this;
        if (!this.companyHolidayViewSubject) {
            this.companyHolidayViewSubject = new BehaviorSubject([]);
        }
        this.dataService.Holidays_GetHolidays().subscribe(function (data) {
            _this.companyHolidayViewSubject.next(data);
        });
    };
    CompanyGeneralService.prototype.createHoliday = function (holidayRequest, reload) {
        var _this = this;
        this.dataService.Holidays_CreateHoliday(holidayRequest).subscribe(function () { }, function () { }, function () {
            if (reload) {
                _this.loadCompanyHolidayView();
            }
        });
    };
    CompanyGeneralService.prototype.deleteHoliday = function (holidayRequest, MasterHolidayId) {
        var _this = this;
        var year = new Date(holidayRequest.Date).getFullYear().toString();
        var month = new Date(holidayRequest.Date).getMonth() + 1 < 10
            ? '0' + (new Date(holidayRequest.Date).getMonth() + 1).toString()
            : (new Date(holidayRequest.Date).getMonth() + 1).toString();
        var date = new Date(holidayRequest.Date).getDate() < 10
            ? '0' + new Date(holidayRequest.Date).getDate().toString()
            : new Date(holidayRequest.Date).getDate().toString();
        var dateDelete = year + month + date;
        this.dataService.Holidays_DeleteHolidayByDate(dateDelete).subscribe(function () { }, function () { }, function () {
            if (!MasterHolidayId) {
                _this.loadCompanyHolidayView();
            }
        });
    };
    CompanyGeneralService.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    CompanyGeneralService.prototype.allowChangeEdit = function (value) {
        if (!value && this.tabDirty && !this.tabValid) {
            return false;
        }
        return true;
    };
    CompanyGeneralService.prototype.onContinueAction = function () {
        this.onSave();
    };
    CompanyGeneralService.prototype.onDiscardAndLeaveAction = function () {
        var _this = this;
        this.loadCompany().subscribe(function () {
            _this.tabDirty = false;
            _this.tabValid = true;
            _this.editMode = false;
            _this.moveToNextState();
        });
    };
    CompanyGeneralService.prototype.loadCompany = function () {
        var _this = this;
        return this.dataService.Companies_GetCurrent().pipe(tap(function (data) {
            if (data) {
                _this.companySubject.next(data);
                Global.COMPANY = data;
            }
        }));
    };
    CompanyGeneralService.prototype.loadCompanyContact = function () {
        var _this = this;
        if (!this.companyContactSubject) {
            this.companyContactSubject = new BehaviorSubject([]);
        }
        this.staticDataService.companyUsers.subscribe(function (companyContact) {
            _this.companyContactSubject.next(companyContact);
        });
    };
    CompanyGeneralService.prototype.loadCompanyLogo = function () {
        var _this = this;
        if (!this.companyLogoSubject) {
            this.companyLogoSubject = new BehaviorSubject(null);
        }
        this.staticDataService.currentLogo.subscribe(function (logo) {
            _this.companyLogoSubject.next(logo);
        });
    };
    CompanyGeneralService.prototype.onSave = function () {
        var _this = this;
        if (this.currentCompany && this.tabDirty) {
            if (this.currentCompany.StatisticsRecipientId === undefined) {
                this.currentCompany.StatisticsRecipientId = null;
            }
            if (this.currentCompany.IndustryOrganizationId === undefined) {
                this.currentCompany.IndustryOrganizationId = null;
            }
            this.currentCompany.BankAccountNo = this.currentCompany.BankAccountNo || null;
            if (this.tabValid) {
                this.tabDirty = false;
                this.dataService.Companies_UpdateCurrent(this.currentCompany).subscribe(function (responseCompany) {
                    _this.companySubject.next(responseCompany);
                    Global.COMPANY = responseCompany;
                    _this.moveToNextState();
                }, function (error) {
                    _this.loadCompany().subscribe(function () { });
                });
            }
            else {
                this.invalidDialogVisible = true;
                this.loadCompany().subscribe(function () { });
            }
        }
    };
    Object.defineProperty(CompanyGeneralService.prototype, "allowShortcut", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.general';
        },
        enumerable: true,
        configurable: true
    });
    CompanyGeneralService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyGeneralService_Factory() { return new CompanyGeneralService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.StaticDataService), i0.ɵɵinject(i3.SettingService), i0.ɵɵinject(i4.SessionService), i0.ɵɵinject(i5.TransitionService), i0.ɵɵinject(i6.BroadcastService)); }, token: CompanyGeneralService, providedIn: "root" });
    return CompanyGeneralService;
}(CompanyService));
export { CompanyGeneralService };
