import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TimePickerComponent as TPComponent, TimePickerIncrementalSteps } from '@progress/kendo-angular-dateinputs';
import { ComboBoxComponent, DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ControlComponentBase } from '../../common/control-component-base';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-time-pickerv',
  templateUrl: './time-picker.component.html'
})
export class TimePickerComponent extends ControlComponentBase<string> implements OnInit, OnDestroy {
  @Input() requireSign: boolean;
  @Input() isLabelTranslated = false;
  @Input() disable = false;
  @Input() addonVisible: boolean;
  @Input() addonIcon: string;
  @Input() addonLink: string;
  @Input() addonTooltip: string;
  @Input() hasFeedback: boolean;
  @Input() feedbackKey: string;

  @Input()
  public set label(value: string) {
    if (!value) {
      // If label is not set, we hide the label
      this.hideLabel = true;
      return;
    }

    this.labelTranslationKey = value;
    if (!this.isLabelTranslated) {
      this.translateProperty(this.translatedLabelPropertyName, value);
    } else {
      (this as any)[this.translatedLabelPropertyName] = value;
    }

    this.hideLabel = false;
  }

  @Input()
  public set comboboxPlaceHolder(value: string) {
    if (value) {
      this.placeHolderTranslationKey = value;
      this.translateProperty(this.translatedPlaceHolderPropertyName, value);
    }
  }

  @Output() addonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() timeValueChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('kendoComboboxControl', { static: false }) private comboBox: ComboBoxComponent;
  @ViewChild('kendoDropdownListControl', { static: false }) private dropdownList: DropDownListComponent;
  @ViewChild('kendoTimepickerControl', { static: false }) private timePicker: TPComponent;

  public hideLabel = true;
  public translatedLabel: string;
  public translatedPlaceHolder: string;
  public steps: TimePickerIncrementalSteps = { hour: 1, minute: 15 };
  public MobileValue: Date = new Date();
  public popupContent: Element;

  public get displayTimeValue(): string {
    return this.convertTimeEnteredToDesireFormat(this.value);
  }
  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public MobileValueChange(value: Date): void {
    if (value) {
      const HourValue = value.getHours().toString();
      const MinuteValue = value.getMinutes().toString();
      this.value = HourValue + ':' + MinuteValue;
    } else {
      this.value = null;
    }
  }

  public FocusTimePicker(): void {
    this.timePicker.toggle(true);
    this.BindingStypeSpinner();
  }

  public OpenTimePickerPopUp(): void {
    this.BindingStypeSpinner();
  }

  public CloseTimePickerPopUp(): void {
    setTimeout(() => {
      if (this.popupContent) {
        this.popupContent.remove();
      }
    });
  }

  private BindingStypeSpinner(): void {
    setTimeout(() => {
      this.popupContent = document.querySelector('kendo-popup.k-animation-container');
      if (this.popupContent) {
        const childPopUp: Element = this.popupContent.querySelector('.k-animation-container .k-popup');
        childPopUp.classList.add('PinnerTemplate');
      }
    });
  }

  private dataSource: any[];
  private selectedTimeValue: string;
  private labelTranslationKey: string;
  private placeHolderTranslationKey: string;
  private translatedLabelPropertyName = 'translatedLabel';
  private translatedPlaceHolderPropertyName = 'translatedPlaceHolder';
  private timeDataSourceValue: any;

  constructor(
    private translateService: TranslateService,
    private sessionService: SessionService,
    elementRef: ElementRef,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(elementRef, changeDetectorRef);
  }

  public ngOnInit(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e: string) => {
      this.translate();
    });
    this.dataSource = this.getTimeDataSource();

    if (this.isMobile) {
      const DateVariable: Date = new Date();
      if (this.value) {
        this.MobileValue.setHours(Number(this.value.split(':')[0]), Number(this.value.split(':')[1]));
      } else {
        this.MobileValue.setHours(0, 0);
      }
    }
  }

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  protected convertValue(value: any): string {
    return this.convertTimeEnteredToDesireFormat(value);
  }

  public get timeDataSource(): any {
    if (!this.timeDataSourceValue) {
      return this.dataSource;
    }
    return this.timeDataSourceValue;
  }

  public onfocus(event: any): void {
    if (this.isGrid && this.comboBox) {
      this.comboBox.toggle(true);
    }
    setTimeout(() => {
      if (this.comboBox) {
        (this.comboBox as any).wrapper.getElementsByClassName('k-input')[0].select();
      }
    });
  }

  public onComboboxChange(value: string): void {
    let existedItem: string;
    if (this.dataSource && value) {
      // SentryId: 691325038.
      existedItem = this.dataSource.find((s: any) => {
        return s.toLowerCase() === value.toLowerCase();
      });
    }

    if (!existedItem) {
      const formattedTime: string = this.convertTimeEnteredToDesireFormat(value);
      this.value = formattedTime;
      this.comboboxObject.writeValue(formattedTime);
    }

    ////this.onChange.emit();
  }

  public onComboBoxFiltering(value: any): void {
    if (this.dropdownList) {
      if (value && value.length >= 1 && this.dataSource) {
        this.timeDataSourceValue = this.dataSource.filter(
          (s: any) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
        if (this.timeDataSourceValue.length === 0 && value.length > 3) {
          value = this.convertTimeEnteredToDesireFormat(value);
          this.timeDataSourceValue.push(value);
        }
      } else {
        this.timeDataSourceValue = undefined;
      }
    }
  }

  public onAddonButtonClick(): void {
    this.addonClick.emit();
  }

  private getTimeDataSource(): string[] {
    const timeArray: any[] = [];
    let date: Date = new Date();
    for (let count = 0; count < 96; count++) {
      date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
      date.setMinutes(count * 15);
      timeArray.push(this.sessionService.toString(date, 'HH:mm'));
    }

    timeArray.push('24:00');
    return timeArray;
  }

  private translate(): void {
    if (!this.isLabelTranslated) {
      this.translateProperty(this.translatedLabelPropertyName, this.labelTranslationKey);
    } else {
      (this as any)[this.translatedLabelPropertyName] = this.labelTranslationKey;
    }

    this.translateProperty(this.translatedPlaceHolderPropertyName, this.placeHolderTranslationKey);
  }

  private translateProperty(propertyName: string, translationKey: string): void {
    if (this.translateService && translationKey) {
      this.translateService.get(translationKey).subscribe((translated: any) => {
        (this as any)[propertyName] = translated;
      });
    } else {
      (this as any)[propertyName] = '';
    }
  }

  private get comboboxObject(): any {
    return this.isMobile ? this.dropdownList : this.comboBox;
  }

  public onkeydown(event: any): void {
    if (event) {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        if (this.comboBox && this.comboBox.isOpen) {
          event.preventDefault();
          event.stopPropagation();
        }
      } else if (event.keyCode === 9) {
        if (event.target) {
          const currentValue = this.convertTimeEnteredToDesireFormat(event.target.value || '');
          if (currentValue !== this.value) {
            this.value = currentValue;
          }
        }
      } else if (event.keyCode === 27) {
        // Escape
        this.value = this.original;
      }
    }
  }

  private convertTimeEnteredToDesireFormat(timeString: string): string {
    if (!timeString) {
      return undefined;
    }
    let hour: number;
    let minute: number;
    if (/[.]/g.test(timeString) || /[-]/g.test(timeString) || /[:]/g.test(timeString)) {
      const seperator: string = /[.]/g.test(timeString)
        ? '.'
        : /[-]/g.test(timeString)
        ? '-'
        : /[:]/g.test(timeString)
        ? ':'
        : '';
      hour = timeString.split(seperator)[0] ? this.sessionService.parseInt(timeString.split(seperator)[0]) : undefined;
      minute = timeString.split(seperator)[1]
        ? this.sessionService.parseInt(timeString.split(seperator)[1])
        : undefined;
    } else {
      timeString = timeString.replace(/[^0-9,]/g, '');
      hour = timeString.substring(0, 2) ? this.sessionService.parseInt(timeString.substring(0, 2)) : undefined;
      minute = timeString.substring(2, 4) ? this.sessionService.parseInt(timeString.substring(2, 4)) : undefined;
    }

    minute = hour && hour >= 24 ? 0 : minute;
    hour = hour || hour === 0 ? (hour <= 0 || hour > 24 ? 0 : hour) : undefined;
    // minute = minute || minute === 0 ? (minute <= 0 || minute === 60 ? 0 : (minute > 60 ? 0 : minute)) : undefined;
    if (minute || minute === 0) {
      if (minute <= 0 || minute === 60) {
        minute = 0;
      } else {
        if (minute > 60) {
          minute = 0;
          hour = hour + 1;
        }
      }
    } else {
      minute = undefined;
    }

    return hour || hour === 0 ? this.zeroPad(hour, 2) + ':' + this.zeroPad(minute, 2) : undefined;
  }

  private zeroPad(num: number, places: number): string {
    num = num ? num : 0;
    const zero: number = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join('0') + num;
  }

  public get pickerType(): string {
    if (this.sessionService.browser.isMobile) {
      return 'rollers';
    }
    return 'calendar';
  }
}
