import { EmployeeService } from '../../services/employee.service';
import { SessionService } from '../../services/session/session.service';
var EmployeeListComponent = /** @class */ (function () {
    function EmployeeListComponent(sessionService, employeeService) {
        this.sessionService = sessionService;
        this.employeeService = employeeService;
        this.hasDepartmentFilter = true;
        this.createNewEmployeeDialogVisible = false;
        this.listClassExcluded = ['filter-list', '10'];
    }
    Object.defineProperty(EmployeeListComponent.prototype, "allowEditUserEmployment", {
        get: function () {
            return this.sessionService.role.IsSalaryAdminAndHigher;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeListComponent.prototype, "employeeListVisible", {
        get: function () {
            return this.sessionService.feature.ShowEmployeeList;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeListComponent.prototype.onCloseEmployeeFilterEventClick = function () {
        this.sessionService.employeeListActive = false;
        this.employeeService.filter = '';
    };
    EmployeeListComponent.prototype.onGridSelectionChange = function (companyUser) {
        if (companyUser) {
            this.employeeService.selectEmployee(companyUser.Id);
        }
        else {
            this.employeeService.selectEmployee();
        }
        this.sessionService.employeeListActive = false;
    };
    return EmployeeListComponent;
}());
export { EmployeeListComponent };
