import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Base64 } from '../../common/base64';
import { Global } from '../../common/global';
import { SessionService } from '../session/session.service';
import { Credentials } from './credentials';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  credentials: Credentials;

  constructor(protected httpClient: HttpClient, private sessionService: SessionService) {}

  login(): Observable<any> {
    if (!this.credentials.Remember) {
      try {
        sessionStorage.removeItem('credentials');
      } catch (e) {
        this.sessionService.isDetectedCookieDisable = true;
      }
    }

    //// btoa(unescape(encodeURIComponent(this.credentials.Username + ":" + this.credentials.Password)))
    const encodeString: string = Base64.encode(
      unescape(encodeURIComponent(this.credentials.Username + ':' + this.credentials.Password))
    );
    try {
      sessionStorage.setItem('token', 'Basic ' + encodeString);
    } catch (e) {
      this.sessionService.isDetectedCookieDisable = true;
    }
    return this.httpClient.post(environment.apiUrl + '/api/auth/login', undefined).pipe(
      map((result: any) => {
        const token: string = result.Token;
        this.sessionService.IsAuthenticated = true;

        sessionStorage.setItem('token', 'Token ' + token.replace(/"/g, ''));
        if (this.credentials.Remember) {
          sessionStorage.setItem('credentials', Base64.encode(JSON.stringify(this.credentials)));
        }

        Sentry.setContext('userName', { id: this.credentials.Username });
        return result;
      }),
      catchError((error: any) => observableThrowError(error)),
      finalize(() => new Observable<any>())
    );
  }

  logout(): Observable<any> {
    this.clearAuthentication();
    return this.httpClient
      .post(environment.apiUrl + '/api/auth/logout', undefined)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  public clearAuthentication(): void {
    //// TODO: We should use ISO language code
    //// languageId == 1: Danish, languageId == 2: English
    let currentLanguageId = 1;
    if (Global.SESSION && Global.SESSION.SignOnToken.LanguageId) {
      currentLanguageId = Global.SESSION.SignOnToken.LanguageId;
    }

    this.sessionService.IsAuthenticated = false;
    const lastUserName: any = localStorage.getItem('lastUserName');
    const requestLogEnabled: any = localStorage.getItem('requestLogEnabled');

    //// TODO //// REVIEW --- IMPORTANT
    //// This is a shallow fix for the following error:
    //// Login to the app -> log out -> use the forget pass word feature -> login again with the temporary password -> watch console explodes
    //// for some reason, the above error does not happen when the login page is loaded for the first time
    // window.location.reload();
    ////
    setTimeout(() => {
      try {
        sessionStorage.clear();
        localStorage.clear();
        if (lastUserName) {
          localStorage.setItem('lastUserName', lastUserName);
        }

        localStorage.setItem('requestLogEnabled', requestLogEnabled);
        window.location.reload();
      } catch (e) {
        this.sessionService.isDetectedCookieDisable = true;
      }
    });
  }
}
