import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { Global } from '../../common/global';
import { SalaryBatchStatusEnum } from '../../model/enum';
import {
  IEIncomeResponseLine,
  IExternalSystemOperation,
  ISalaryBatch,
  ISalaryBatchRequest,
  ISalaryBatchTotalsApiView,
  ISalaryBatchUnitTotal,
  ISalaryBatchValidationIssue,
  ISalaryBatchView,
  ISalaryCycle,
  ISalaryPeriod,
  ISimpleSalaryStatement
} from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { CompanyService } from '../../services/company.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import { SalaryBatchErrorsView } from '../salary-batches/dialogs/salary-batch-errors-view';
import { CompanySalaryBatchesChildFormEntityContext } from './company-salary-batches-child-form-entity-context';
import { SalaryBatchViewModel } from './salary-batch-view-model';

@Injectable({
  providedIn: 'root'
})
export class CompanySalaryBatchService extends CompanyService implements OnDestroy {
  public Step1FinalizeTitle: string;
  public Step1FinalizePrepare: string;
  public Step1FinalizeCalculate: string;
  public isSalaryBatchLoading = false;

  private salaryCyclesSubject: BehaviorSubject<ISalaryCycle[]>;
  public get salaryCycles(): Observable<ISalaryCycle[]> {
    if (!this.salaryCyclesSubject) {
      this.salaryCyclesSubject = new BehaviorSubject<ISalaryCycle[]>([]);
    }

    return this.salaryCyclesSubject.asObservable();
  }

  public get isExitSalaryCycles() {
    return this.salaryCyclesSubject && this.salaryCyclesSubject.value && this.salaryCyclesSubject.value.length > 0;
  }

  public isEmptyEmployeeFromCycle: boolean;

  private salaryBatchesSubject: BehaviorSubject<ISalaryBatchView[]>;
  public allSalaryBatches: ISalaryBatchView[] = [];

  public get salaryBatches(): Observable<ISalaryBatchView[]> {
    if (!this.salaryBatchesSubject) {
      this.salaryBatchesSubject = new BehaviorSubject<ISalaryBatchView[]>([]);
    }
    return this.salaryBatchesSubject.asObservable();
  }

  private salaryStatementsSubject: BehaviorSubject<ISimpleSalaryStatement[]>;
  public allCurrentSelectSalaryStatements: number[] = [];
  public get salaryStatements(): Observable<ISimpleSalaryStatement[]> {
    if (!this.salaryStatementsSubject) {
      this.salaryStatementsSubject = new BehaviorSubject<ISimpleSalaryStatement[]>([]);
    }

    return this.salaryStatementsSubject.asObservable();
  }

  private salaryTypeTotalsSubject: BehaviorSubject<ISalaryBatchTotalsApiView[]>;
  public get salaryTypeTotals(): Observable<ISalaryBatchTotalsApiView[]> {
    if (!this.salaryTypeTotalsSubject) {
      this.salaryTypeTotalsSubject = new BehaviorSubject<ISalaryBatchTotalsApiView[]>([]);
    }
    return this.salaryTypeTotalsSubject.asObservable();
  }

  public loadSalaryTypeTotals(batchId: number) {
    if (!this.salaryTypeTotalsSubject) {
      this.salaryTypeTotalsSubject = new BehaviorSubject<ISalaryBatchTotalsApiView[]>([]);
    }
    this.salaryTypeTotalsSubject.next([]);

    if (batchId) {
      this.dataService
        .SalaryBatches_GetSalaryBatchTotalsBySalaryType(batchId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: any[]) => {
          this.salaryTypeTotalsSubject.next(data);
        });
    }
  }

  private unitTotalsSubject: BehaviorSubject<ISalaryBatchUnitTotal[]>;
  public get unitTotals(): Observable<ISalaryBatchUnitTotal[]> {
    if (!this.unitTotalsSubject) {
      this.unitTotalsSubject = new BehaviorSubject<ISalaryBatchUnitTotal[]>([]);
    }
    return this.unitTotalsSubject.asObservable();
  }

  private salaryBatchErrorsViewSubject: BehaviorSubject<
    SalaryBatchErrorsView[] | ISalaryBatchValidationIssue[] | any[]
  >;
  public get salaryBatchErrorsView(): Observable<SalaryBatchErrorsView[] | ISalaryBatchValidationIssue[] | any[]> {
    if (!this.salaryBatchErrorsViewSubject) {
      this.salaryBatchErrorsViewSubject = new BehaviorSubject<
        SalaryBatchErrorsView[] | ISalaryBatchValidationIssue[] | any[]
      >([]);
    }
    return this.salaryBatchErrorsViewSubject.asObservable();
  }

  public loadUnitTotals(batchId: number) {
    if (!this.unitTotalsSubject) {
      this.unitTotalsSubject = new BehaviorSubject<ISalaryBatchUnitTotal[]>([]);
    }
    this.unitTotalsSubject.next([]);

    if (batchId) {
      this.dataService
        .SalaryBatches_GetSalaryBatchUnitTotals(batchId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: any[]) => {
          this.unitTotalsSubject.next(data);
        });
    }
  }

  private selectedSalaryBatchSubject: BehaviorSubject<ISalaryBatch>;
  public get selectedSalaryBatch(): Observable<ISalaryBatch> {
    if (!this.selectedSalaryBatchSubject) {
      this.selectedSalaryBatchSubject = new BehaviorSubject<ISalaryBatch>(null);
    }

    return this.selectedSalaryBatchSubject.asObservable();
  }

  public setNextValueSelectedSalaryBatch(item: ISalaryBatch) {
    if (!this.selectedSalaryBatchSubject) {
      this.selectedSalaryBatchSubject = new BehaviorSubject<ISalaryBatch>(null);
    }

    this.selectedSalaryBatchSubject.next(item);
  }

  private salaryBatchEntityContextSubject: BehaviorSubject<CompanySalaryBatchesChildFormEntityContext>;
  public get salaryBatchEntityContext(): Observable<CompanySalaryBatchesChildFormEntityContext> {
    if (!this.salaryBatchEntityContextSubject) {
      this.salaryBatchEntityContextSubject = new BehaviorSubject<CompanySalaryBatchesChildFormEntityContext>(null);
    }
    return this.salaryBatchEntityContextSubject.asObservable();
  }

  public setNextValueSalaryBatchEntityContext(item: CompanySalaryBatchesChildFormEntityContext) {
    this.salaryBatchEntityContextSubject.next(item);
  }

  public loadSalaryStatements(batchId: number) {
    if (!this.salaryStatementsSubject) {
      this.salaryStatementsSubject = new BehaviorSubject<ISimpleSalaryStatement[]>([]);
    }

    this.salaryStatementsSubject.next([]);
    // this.allCurrentSelectSalaryStatements = [];

    if (batchId) {
      this.dataService
        .SalaryStatements_GetSalaryStatementsByBatchSimplified(batchId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: ISimpleSalaryStatement[]) => {
          this.salaryStatementsSubject.next(data);

          if (!data || (data && data.length === 0)) {
            this.allCurrentSelectSalaryStatements = [];
          }
          // this.allCurrentSelectSalaryStatements = data.map((item: ISimpleSalaryStatement) => {
          //   return item.UserEmploymentId;
          // });
        });
    }
  }

  public loadSalaryBatchErrorsView(data: SalaryBatchViewModel) {
    if (data && data.Id) {
      if (!this.salaryBatchErrorsViewSubject) {
        this.salaryBatchErrorsViewSubject = new BehaviorSubject<
          SalaryBatchErrorsView[] | ISalaryBatchValidationIssue[] | any[]
        >([]);
      }

      this.salaryBatchErrorsViewSubject.next([]);

      if (data.StatusId === 40 || data.StatusId === 90) {
        this.dataService
          .SalaryBatches_GetEIncomeResponseLines(data.Id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((issue: IEIncomeResponseLine[]): void => {
            this.salaryBatchErrorsViewSubject.next(
              issue.map(
                (d: IEIncomeResponseLine) => new SalaryBatchErrorsView(d, this.errorTooltip, this.warningTooltip)
              )
            );
          });
      } else {
        this.dataService
          .SalaryBatches_GetValidationIssues(data.Id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((validationIssues: ISalaryBatchValidationIssue[]): void => {
            const result: any = validationIssues.map((issue: ISalaryBatchValidationIssue) => {
              return {
                icon: issue.IsError ? 'ErrorExclamation' : 'Warning',
                tooltip: issue.IsError ? this.errorTooltip : this.warningTooltip,
                description: issue.Description,
                employeeName: issue.EmployeeName
              };
            });

            this.salaryBatchErrorsViewSubject.next(result);
          });
      }
    }
  }

  public loadSalaryBatches() {
    if (!this.salaryBatchesSubject) {
      this.salaryBatchesSubject = new BehaviorSubject<ISalaryBatchView[]>([]);
    }

    return this.dataService.SalaryBatches_GetSalaryBatches().pipe(
      takeUntil(this.ngUnsubscribe),
      tap((data: ISalaryBatchView[]) => {
        const result: any = data.map((s: ISalaryBatchView) => new SalaryBatchViewModel(s, this.sessionService));
        this.salaryBatchesSubject.next(result);
        this.allSalaryBatches = result;
      })
    );
  }

  public loadSalaryCycles() {
    if (!this.salaryCyclesSubject) {
      this.salaryCyclesSubject = new BehaviorSubject<ISalaryCycle[]>([]);
    }
    return this.dataService.Miscellaneous_GetUsedSalaryCycles().pipe(
      takeUntil(this.ngUnsubscribe),
      tap((SalaryCycles: ISalaryCycle[]) => {
        this.salaryCyclesSubject.next(SalaryCycles);
        this.isSalaryBatchLoading = false;
      })
    );
  }

  public initData() {
    // refesh variable
    this.onDestroyLocalVariable();

    this.loadIntegrationName();
    this.tooltipFinalizeButton();
    this.restoreFinalizationProcess();

    this.loadSalaryCycles().toPromise();
    this.staticDataService.userEmployments.subscribe((data) => {
      this.isEmptyEmployeeFromCycle = data.length === 0;
    });
  }

  public integrationName = '';
  public tooltipFinalizeContent: string;
  public viewSalaryTotalModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public pickEmployeeDialogVisible = false;

  public loadIntegrationName() {
    this.staticDataService.ExternalSystemOperation.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IExternalSystemOperation[]) => {
        const objectfillter: IExternalSystemOperation = data.find(
          (model: IExternalSystemOperation) => model.Key === 'ExportNewPayrollBatch'
        );
        if (objectfillter) {
          this.staticDataService.GetIntegration2Operation(objectfillter.Id);
          this.staticDataService.getNavChangeEmitter().subscribe((valueIntegration: any[]) => {
            if (valueIntegration && valueIntegration.length > 0) {
              this.integrationName = valueIntegration[0].Name;
            }
          });
        }
      }
    );
  }

  public tooltipFinalizeButton() {
    this.tooltipFinalizeContent = '';

    if (this.sessionService.role.IsReadOnly) {
      this.tooltipFinalizeContent = 'CompanySalaryBatches.DenyFinalize_Text';
    } else {
      if (!Global.SESSION.IsPaymentApprover) {
        this.tooltipFinalizeContent = 'CompanySalaryBatches.DenyFinalize_AdminText';
      }
    }
  }

  public get hasManyOpenDrafts(): boolean {
    return (
      this.allSalaryBatches &&
      this.allSalaryBatches.length > 0 &&
      this.allSalaryBatches.filter((batch: ISalaryBatchView) => batch.StatusId === SalaryBatchStatusEnum.Draft)
        .length >= 3
    );
  }

  public get emptySalaryBatchShown(): boolean {
    return !(this.allSalaryBatches && this.allSalaryBatches.length > 0);
  }

  public get hasIntegrationName(): boolean {
    return !!this.integrationName;
  }

  constructor(
    protected dataService: DataService,
    protected staticDataService: StaticDataService,
    protected settingService: SettingService,
    protected sessionService: SessionService,
    protected transitionService: TransitionService,
    protected broadcaster: BroadcastService,
    private translateService: TranslateService
  ) {
    super(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster);

    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateService
        .get([
          'CompanySalaryBatches.OrtherStatusGridErrorIconTooltip',
          'CompanySalaryBatches.OrtherStatusGridWarningIconTooltip',
          'CompanySalaryBatches.RecalculateBatch',
          'CompanySalaryBatches.PrepareFinalization'
        ])
        .subscribe((translations: { [key: string]: string }) => {
          this.warningTooltip = translations['CompanySalaryBatches.OrtherStatusGridWarningIconTooltip'];
          this.errorTooltip = translations['CompanySalaryBatches.OrtherStatusGridErrorIconTooltip'];
          this.Step1FinalizeCalculate = translations['CompanySalaryBatches.RecalculateBatch'];
          this.Step1FinalizePrepare = translations['CompanySalaryBatches.PrepareFinalization'];
          this.Step1FinalizeTitle = this.Step1FinalizeCalculate;
        });
    });

    this.restoreFinalizationProcess();
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();

  private warningTooltip: string;
  private errorTooltip: string;

  public ngOnDestroy() {
    this.onDestroyLocalVariable();

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private onDestroyLocalVariable() {
    this.allCurrentSelectSalaryStatements = [];

    if (this.salaryStatementsSubject) {
      this.salaryStatementsSubject.next([]);
    }

    if (this.salaryBatchesSubject) {
      this.salaryBatchesSubject.next([]);
      this.allSalaryBatches = [];
    }

    if (this.salaryTypeTotalsSubject) {
      this.salaryTypeTotalsSubject.next([]);
    }

    if (this.unitTotalsSubject) {
      this.unitTotalsSubject.next([]);
    }

    if (this.selectedSalaryBatchSubject) {
      this.selectedSalaryBatchSubject.next(null);
    }

    if (this.salaryBatchEntityContextSubject) {
      this.salaryBatchEntityContextSubject.next(null);
    }
  }

  private processFinalizationRunValue = false;
  public get processFinalizationRun(): boolean {
    return this.processFinalizationRunValue;
  }

  public set processFinalizationRun(value: boolean) {
    if (value !== this.processFinalizationRunValue) {
      if (!value) {
        setTimeout(() => {
          this.processFinalizationRunValue = value;
          this.processCountValue = 0;

          // Revert All process
          setTimeout(() => {
            this.restoreFinalizationProcess();
          });
        }, 1000);
      } else {
        this.processFinalizationRunValue = value;

        // Revert All process
        this.restoreFinalizationProcess();
      }
    }
  }

  private processCountValue = 0;
  public get processCount(): number {
    return this.processCountValue;
  }

  public set processCount(value: number) {
    if (value !== this.processCountValue) {
      this.processCountValue = value;

      if (value === 5) {
        this.processCountValue = 0;
        this.processFinalizationRun = false;
      }
    }
  }

  public processStep3FinalizationFail = false;
  public processStep4FinalizationFail = false;
  public processStep5FinalizationFail = false;

  public step3ExepsionMessage = '';

  public processFinalizationInfo: {
    [key: string]: {
      start: boolean;
      complete: boolean;
      error: boolean;
    };
  } = {};

  private restoreFinalizationProcess() {
    this.processFinalizationInfo['step1Finalization'] = { start: false, complete: false, error: false };
    this.processFinalizationInfo['step2Finalization'] = { start: false, complete: false, error: false };
    this.processFinalizationInfo['step3Finalization'] = { start: false, complete: false, error: false };
    this.processFinalizationInfo['step4Finalization'] = { start: false, complete: false, error: false };
    this.processFinalizationInfo['step5Finalization'] = { start: false, complete: false, error: false };

    this.step3ExepsionMessage = '';
  }

  private startProcess(process: any, key: string) {
    process[key].start = true;
    process[key].complete = false;
    process[key].error = false;
  }

  private doneProcess(process: any, key: string) {
    process[key].start = false;
    process[key].complete = true;
    process[key].error = false;
  }

  private errorProcess(process: any, key: string) {
    process[key].start = false;
    process[key].complete = true;
    process[key].error = true;
  }

  private revertProcess(process: any, key: string) {
    process[key].start = false;
    process[key].complete = false;
    process[key].error = false;
  }

  public onStartFinalization(batch: SalaryBatchViewModel, password: string) {
    this.processFinalizationRun = true;
    if (batch.StatusId <= 10) {
      this.Step1FinalizeTitle = this.Step1FinalizeCalculate;
    } else {
      this.Step1FinalizeTitle = this.Step1FinalizePrepare;
    }
    this.startProcess(this.processFinalizationInfo, 'step1Finalization');
    this.FinalizationStep1(batch, password).subscribe(
      () => {
        this.processCount += 1;
        this.doneProcess(this.processFinalizationInfo, 'step1Finalization');

        this.startProcess(this.processFinalizationInfo, 'step2Finalization');
        this.FinalizationStep2(batch).subscribe(
          () => {
            this.loadSalaryBatches().subscribe(
              () => {
                this.processCount += 1;
                this.doneProcess(this.processFinalizationInfo, 'step2Finalization');
                this.handleFinalizationStep345(batch);
              },
              () => {
                this.processCount += 1;
                this.doneProcess(this.processFinalizationInfo, 'step2Finalization');
                this.handleFinalizationStep345(batch);
              }
            );
          },
          () => {
            this.salaryBatchesSubject = new BehaviorSubject<ISalaryBatchView[]>([]);

            this.loadSalaryBatches().subscribe();
            this.processFinalizationRun = false;
          }
        );
      },
      () => {
        this.processFinalizationRun = false;
      }
    );
  }

  public Step3FinalizationFailText: string;
  private handleFinalizationStep345(batch: SalaryBatchViewModel) {
    this.startProcess(this.processFinalizationInfo, 'step3Finalization');
    this.FinalizationStep3(batch).subscribe(
      () => {
        this.doneProcess(this.processFinalizationInfo, 'step3Finalization');
        this.processCount += 1;
      },
      (ex: any) => {
        this.step3ExepsionMessage = ex.message;

        this.processStep3FinalizationFail = true;
        this.Step3FinalizationFailText = this.Step3FinalizationFailText.replace(
          '$$$Message$$$',
          this.step3ExepsionMessage
        ).replace('$$$ExtSystemName$$$', this.integrationName);
        this.processCount += 1;
        this.errorProcess(this.processFinalizationInfo, 'step3Finalization');
      }
    );

    this.startProcess(this.processFinalizationInfo, 'step4Finalization');
    this.FinalizationStep4(batch).subscribe(
      () => {
        this.doneProcess(this.processFinalizationInfo, 'step4Finalization');
        this.processCount += 1;
      },
      () => {
        this.processStep4FinalizationFail = true;
        this.processCount += 1;
        this.errorProcess(this.processFinalizationInfo, 'step4Finalization');
      }
    );

    this.startProcess(this.processFinalizationInfo, 'step5Finalization');
    this.FinalizationStep5(batch).subscribe(
      (data: ISalaryPeriod[]) => {
        this.creteNextPeriodBatch(batch, data).subscribe(
          () => {
            this.doneProcess(this.processFinalizationInfo, 'step5Finalization');
            this.updateSelectedSalaryAfterFinalize(batch);
            this.processCount += 1;
          },
          () => {
            this.doneProcess(this.processFinalizationInfo, 'step5Finalization');
            this.processCount += 1;
          }
        );
      },
      () => {
        this.processStep5FinalizationFail = true;
        this.processCount += 1;
        this.errorProcess(this.processFinalizationInfo, 'step5Finalization');
      }
    );
  }

  private async updateSelectedSalaryAfterFinalize(batch: SalaryBatchViewModel) {
    const salaryBatchSelected: any = this.allSalaryBatches.filter((salaryBatch: any) => {
      return batch.Id === salaryBatch.Id;
    });
    if (salaryBatchSelected && salaryBatchSelected.length > 0) {
      await this.loadSalaryStatements(salaryBatchSelected[0].Id);
      this.setNextValueSelectedSalaryBatch(batch);
    }
  }

  private FinalizationStep1(batch: SalaryBatchViewModel, password: string) {
    return this.dataService.SalaryBatches_PrepareSalaryBatchForFinalization(batch.Id, { Password: password }).pipe(
      tap((guid: string) => {
        batch.FinalizationGuid = guid;
      })
    );
  }

  private FinalizationStep2(batch: SalaryBatchViewModel) {
    return this.dataService
      .SalaryBatches_FinalizeSalaryBatch(batch.Id, { FinalizationGuid: batch.FinalizationGuid })
      .pipe(tap(() => {}));
  }

  private FinalizationStep3(batch: SalaryBatchViewModel) {
    // skip  If there is no integration, skip this step.
    // TODO check
    if (!this.integrationName) {
      return Observable.create((ob: any) => {
        ob.next();
      });
    }
    return this.dataService.SalaryBatches_SendOrResendToDefaultIntegration(batch.Id).pipe(tap(() => {}));
  }

  private FinalizationStep4(batch: SalaryBatchViewModel) {
    // skip this if there are no open batches with state Draft.
    // TODO check open draft
    if (!this.hasManyOpenDrafts) {
      return Observable.create((ob: any) => {
        ob.next();
      });
    }

    return this.dataService.SalaryBatches_RecalculateDraftSalaryBatches().pipe(tap(() => {}));
  }

  public afterCreate: EventEmitter<any> = new EventEmitter<any>();
  private FinalizationStep5(batch: SalaryBatchViewModel) {
    //  if an open draft batch already exists for the next period. If no, then we create one automatically.
    return this.dataService
      .SalaryBatches_GetSalaryPeriods(batch.SalaryCycleId)
      .pipe(tap((data: ISalaryPeriod[]) => {}));
  }

  private creteNextPeriodBatch(batch: SalaryBatchViewModel, data: ISalaryPeriod[]) {
    const nextPeriod = data.find(
      (item: ISalaryPeriod) =>
        item.StartDate &&
        batch.PeriodEndDate &&
        new Date(item.StartDate).getTime() > new Date(batch.PeriodEndDate).getTime()
    );

    if (!nextPeriod) {
      return Observable.create((ob: any) => {
        ob.next();
      });
    }

    const existingBatchCount = this.allSalaryBatches.find(
      (item: ISalaryBatchView) => item.SalaryPeriodId === nextPeriod.Id
    );
    if (!existingBatchCount) {
      // Create New Batch
      const salaryBatchRequest: ISalaryBatchRequest = {
        PayoutDate: nextPeriod.SuggestedPayoutDate,
        Preview: false,
        SalaryCycleId: nextPeriod.SalaryCycleId,
        SalaryPeriodId: nextPeriod.Id,
        Message: null,
        UserEmploymentIds: this.allCurrentSelectSalaryStatements,
        EIncomeZeroReport: false,
        PayoutAllFlex: false,
        PayoutNewFlex: false,
        FromDateOverride: null,
        ToDateOverride: null
      };

      return this.dataService.SalaryBatches_CreateSalaryBatch(salaryBatchRequest).pipe(
        tap((salaryRecord: ISalaryBatch) => {
          this.loadSalaryBatches().subscribe(() => {
            this.afterCreate.emit({ salaryBatchRequest, salaryRecord });
          });
        })
      );
    }

    return Observable.create((ob: any) => {
      ob.next();
    });
  }

  protected get allowShortcut(): boolean {
    return this.sessionService.currentState === 'tabs.company.salarybatches';
  }
}
