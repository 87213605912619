import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../common/constants';
import { Global } from '../../../common/global';
import { GridHelper } from '../../../common/grid-helper';
import { SalaryBatchStatusEnum } from '../../../model/enum';
import { ExtendSalaryPeriod } from '../../../model/extend-salary-period';
import { DataService } from '../../../services/api/data.service';
import { SessionService } from '../../../services/session/session.service';
import { CompanySalaryBatchesChildFormEntityContext } from '../company-salary-batches-child-form-entity-context';
import { CompanySalaryBatchService } from '../company-salary-batches.service';
import { SalaryBatchViewModel } from '../salary-batch-view-model';
var SalaryBatchDetailsComponent = /** @class */ (function () {
    function SalaryBatchDetailsComponent(dataService, sessionService, companySalaryBatchService) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.companySalaryBatchService = companySalaryBatchService;
        this.reloadSalaryBatches = new EventEmitter();
        this.closeDialog = new EventEmitter();
        this.entityContextChange = new EventEmitter();
        this.selectedEmployeeIdsChange = new EventEmitter();
        this.triggerReloadChange = new EventEmitter();
        this.cycleChange = new EventEmitter();
        this.triggerReloadValue = false;
        this.session = Global.SESSION;
        this.pickEmployeeDialogVisible = false;
        this.loadingPeriods = false;
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "salaryCycles", {
        get: function () {
            return this.salaryCyclesValue;
        },
        set: function (value) {
            var isLoadDueToValueChanged = (!this.salaryCyclesValue || this.salaryCyclesValue.length === 0) && value && value.length > 0;
            this.salaryCyclesValue = value;
            if (isLoadDueToValueChanged) {
                this.loadDetailsData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "salaryBatch", {
        get: function () {
            return this.selectedSalaryBatchValue;
        },
        set: function (value) {
            if (this.selectedSalaryBatchValue !== value) {
                this.selectedSalaryBatchValue = value;
                if (value && value.Message) {
                    value.Message = this.decodeEntities(value.Message);
                }
                this.loadDetailsData();
            }
            else if (!value) {
                this.loadDetailsData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "entityContext", {
        get: function () {
            return this.entityContextValue ? this.entityContextValue : {};
        },
        set: function (value) {
            if (this.entityContextValue !== value) {
                this.entityContextValue = value;
                this.entityContextChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchDetailsComponent.prototype.entityContextMobileChange = function () {
        if (this.companySalaryBatchService.IsMobileOrHybridApp) {
            this.entityContextChange.emit(this.entityContextValue);
        }
    };
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "selectedEmployeeIds", {
        get: function () {
            return this.selectedEmployeeIdsValue;
        },
        set: function (value) {
            if (this.selectedEmployeeIdsValue !== value) {
                this.selectedEmployeeIdsValue = value;
                this.selectedEmployeeIdsChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                if (value) {
                    this.loadDetailsData();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "triggerReload", {
        get: function () {
            return this.triggerReloadValue;
        },
        set: function (value) {
            if (value !== this.triggerReloadValue) {
                this.loadDetailsData();
                this.triggerReloadChange.emit(this.triggerReloadValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "IsExistingSalaryBatch", {
        get: function () {
            return !(this.entityContext.BatchId === undefined || this.entityContext.BatchId < 1);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "IsNewBatchWithEmployeeSelection", {
        get: function () {
            return this.entityContext.BatchId === -1 && this.entityContext.EmployeeCategory === 2;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "showMultiPurposeOffDayCompensation", {
        get: function () {
            var enableFlexBalanceSetting = this.sessionService.getCompanyPreference('Company.EnableFlexBalance');
            return enableFlexBalanceSetting ? enableFlexBalanceSetting.Value === 'true' : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "isAllowOverride", {
        get: function () {
            var pref = this.sessionService.getCompanyPreference('SalaryPeriod.AllowOverride');
            return pref && pref.Value === 'true';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "isShowPaymentDate", {
        get: function () {
            return this.entityContext.BatchId === -1
                ? this.entityContext.EmployeeCategory !== 3
                : this.salaryBatch && this.salaryBatch.Id === this.entityContext.BatchId
                    ? !this.salaryBatch.EIncomeZeroReport
                    : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "IsDenmarkCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchDetailsComponent.prototype, "PayoutAllFlexLabel", {
        get: function () {
            if (this.IsGreenlandCompany) {
                return 'CompanySalaryBatches.PayoutOffDayBalances GreenlandCompany';
            }
            return 'CompanySalaryBatches.PayoutOffDayBalances';
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchDetailsComponent.prototype.ngOnInit = function () { };
    SalaryBatchDetailsComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    SalaryBatchDetailsComponent.prototype.prepareEntityContext = function (batch) {
        this.loadingPeriods = true;
        if (batch && Array.isArray(batch) === false) {
            var updateContext = new CompanySalaryBatchesChildFormEntityContext(this.sessionService);
            updateContext.BatchId = batch.Id;
            updateContext.StatusName = batch.StatusName;
            updateContext.ChangeDate = batch.Rejected
                ? batch.Rejected
                : batch.Finalized
                    ? batch.Finalized
                    : batch.Approved
                        ? batch.Approved
                        : batch.Created;
            updateContext.SalaryBatchNumber = batch.SalaryBatchNumber;
            updateContext.PeriodId = batch.SalaryPeriodId;
            updateContext.IsDraft = batch.StatusId === SalaryBatchStatusEnum.Draft;
            updateContext.IsPendingForApproval = batch.StatusId === SalaryBatchStatusEnum.ForApproval;
            updateContext.IsFinalized = batch.StatusId === SalaryBatchStatusEnum.Finalized;
            updateContext.isFinalizing = batch.StatusId === SalaryBatchStatusEnum.Finalizing;
            updateContext.IsCancelled = batch.StatusId === SalaryBatchStatusEnum.Cancelled;
            updateContext.IsLocked = batch.StatusId === SalaryBatchStatusEnum.ForApproval;
            updateContext.IsPreliminary = batch.StatusId === SalaryBatchStatusEnum.ForApproval;
            updateContext.PaymentDate = this.sessionService.parseDate(batch.PayoutDate.toString());
            updateContext.PayoutAllFlex = batch.PayoutAllFlex;
            updateContext.PayoutNewFlex = batch.PayoutNewFlex;
            updateContext.IsRecalcBeforeFinalize = !(updateContext.IsLocked || updateContext.IsPreliminary);
            updateContext.Message = batch.Message;
            updateContext.HasErrors = batch.HasErrors;
            updateContext.HasWarnings = batch.HasWarnings;
            updateContext.CycleId = batch.SalaryCycleId;
            updateContext.EmployeeCategory = 1;
            updateContext.HasBatch = false;
            updateContext.FromDateOverride = batch.FromDateOverride;
            updateContext.ToDateOverride = batch.ToDateOverride;
            this.entityContext = updateContext;
            this.onEmployeeCategoryChanged();
        }
        else {
            if ( /*!this.salaryBatchesContext || */!this.salaryCycles || this.salaryCycles.length === 0) {
                return;
            }
            var newContext = new CompanySalaryBatchesChildFormEntityContext(this.sessionService);
            newContext.BatchId = -1;
            newContext.StatusName = undefined;
            newContext.ChangeDate = undefined;
            newContext.IsDraft = true;
            newContext.CycleId = this.salaryCycles[0].Id;
            newContext.PeriodId = undefined;
            newContext.EmployeeCategory = 1;
            newContext.PayoutAllFlex = false;
            newContext.HasBatch = false;
            this.entityContext = newContext;
            this.onEmployeeCategoryChanged();
        }
    };
    SalaryBatchDetailsComponent.prototype.loadDetailsData = function () {
        var _this = this;
        this.prepareEntityContext(this.salaryBatch);
        if (!this.visible) {
            return;
        }
        this.isPreparingContent = true;
        if (this.entityContextValue && this.entityContextValue.CycleId) {
            this.getSalaryBatchesPeriods(this.entityContextValue.CycleId);
            // this.getSalaryBatchUserEmployments();
            setTimeout(function () {
                _this.entityContext.IsDirty = false;
            });
        }
        setTimeout(function () {
            _this.isPreparingContent = false;
        }, 1000);
    };
    SalaryBatchDetailsComponent.prototype.onChildWindowSalaryCycleChange = function () {
        var cycleId = this.getChildWindowCycleId();
        if (cycleId) {
            this.loadingPeriods = true;
            this.getSalaryBatchesPeriods(cycleId);
            this.cycleChange.emit();
            this.entityContextMobileChange();
        }
    };
    SalaryBatchDetailsComponent.prototype.onSalaryPeriodChanged = function (value) {
        if (!this.IsExistingSalaryBatch) {
            this.setPaymentDateDueToPeriodChange();
        }
        this.checkPeriod();
        this.entityContextMobileChange();
    };
    SalaryBatchDetailsComponent.prototype.onEmployeeCategoryChanged = function (category) {
        if (this.IsNewBatchWithEmployeeSelection) {
            this.companySalaryBatchService.pickEmployeeDialogVisible = true;
        }
        this.onEmployeesGridDataSaveChanges();
    };
    SalaryBatchDetailsComponent.prototype.onEmployeesGridDataSaveChanges = function (event) {
        switch (this.entityContext.EmployeeCategory) {
            case 1: // All employeess
                this.selectedEmployeeIds = null;
                break;
            case 3: // No employees
                this.selectedEmployeeIds = [];
                break;
            case 2:
                // Selected employees
                break;
            default:
                break;
        }
    };
    SalaryBatchDetailsComponent.prototype.onRecalculateSingleEmployeeDone = function () {
        var _this = this;
        if (this.salaryBatch && this.recalculateSingleUserEmploymentId) {
            this.dataService
                .SalaryBatches_RecalculateSalaryBatchSingleEmployment(this.salaryBatch.Id, this.recalculateSingleUserEmploymentId)
                .subscribe(function (salaryBatch) {
                // re caculate batch statement
                _this.companySalaryBatchService.loadSalaryTypeTotals(_this.salaryBatch.Id);
                _this.companySalaryBatchService.loadUnitTotals(_this.salaryBatch.Id);
                _this.companySalaryBatchService.loadSalaryStatements(_this.salaryBatch.Id);
                _this.reloadSalaryBatches.emit();
                _this.closeDialog.emit();
            });
        }
    };
    SalaryBatchDetailsComponent.prototype.getChildWindowCycleId = function () {
        var _this = this;
        var cycleId;
        var dataItem = this.salaryCycles.find(function (cycle) { return cycle.Id === _this.entityContext.CycleId; });
        if (!dataItem) {
            cycleId = this.entityContext.CycleId =
                this.salaryCycles && this.salaryCycles.length > 0 ? this.salaryCycles[0].Id : undefined;
        }
        else {
            cycleId = dataItem.Id;
        }
        return cycleId;
    };
    SalaryBatchDetailsComponent.prototype.getSalaryBatchesPeriods = function (cycleId) {
        var _this = this;
        this.dataService
            .SalaryBatches_GetSalaryPeriods(cycleId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (salaryPeriods) {
            if (salaryPeriods) {
                if (salaryPeriods.length > 0) {
                    _this.loadingPeriods = false;
                    var defaultPeriod = salaryPeriods[0];
                    var dataSource_1 = [];
                    var friendlyNamePreference = _this.sessionService.getCompanyPreference('SalaryPeriods.FriendlyNames');
                    var isUserFriendlyname_1 = friendlyNamePreference && friendlyNamePreference.Value === 'true' ? true : false;
                    salaryPeriods = salaryPeriods.sort(function (data1, data2) {
                        return GridHelper.sortByDateValue(data1.StartDate, data2.StartDate);
                    });
                    if (salaryPeriods) {
                        salaryPeriods.forEach(function (salaryPeriod, key) {
                            var startDate = _this.sessionService.toString(salaryPeriod.StartDate ? new Date(salaryPeriod.StartDate) : undefined);
                            var endDate = _this.sessionService.toString(salaryPeriod.EndDate ? new Date(salaryPeriod.EndDate) : undefined);
                            var extendSalaryPeriod = new ExtendSalaryPeriod();
                            extendSalaryPeriod.Id = salaryPeriod.Id;
                            extendSalaryPeriod.StartDate = salaryPeriod.StartDate;
                            extendSalaryPeriod.EndDate = salaryPeriod.EndDate;
                            extendSalaryPeriod.SuggestedPayoutDate = salaryPeriod.SuggestedPayoutDate;
                            extendSalaryPeriod.Period = startDate + ' - ' + endDate;
                            if (isUserFriendlyname_1) {
                                extendSalaryPeriod.FriendlyName = ' ' + salaryPeriod.FriendlyName;
                            }
                            else {
                                extendSalaryPeriod.FriendlyName = startDate + ' - ' + endDate;
                            }
                            dataSource_1[key] = extendSalaryPeriod;
                        });
                    }
                    _this.entityContext.Periods = dataSource_1;
                    if (_this.entityContext && _this.entityContext.BatchId && _this.entityContext.BatchId < 0) {
                        _this.entityContext.PeriodId = defaultPeriod.Id;
                    }
                    if (_this.entityContext.BatchId === undefined || _this.entityContext.BatchId < 1) {
                        _this.getSalaryBatchesPeriodsSuggest(_this.entityContext.CycleId);
                    }
                }
                else {
                    _this.entityContext.Periods = [];
                    _this.entityContext.PaymentDate = undefined;
                }
            }
        });
    };
    SalaryBatchDetailsComponent.prototype.getSalaryBatchesPeriodsSuggest = function (cycleId) {
        var _this = this;
        var batchId = this.entityContext ? this.entityContext.BatchId : undefined;
        this.dataService.SalaryBatches_SuggestSalaryPeriod(cycleId).subscribe(function (salaryPeriod) {
            if (cycleId === _this.entityContext.CycleId && batchId === _this.entityContext.BatchId) {
                if (_this.entityContext.Periods &&
                    _this.entityContext.Periods.length > 0 &&
                    !_this.entityContext.Periods.find(function (item) { return item.Id === salaryPeriod.Id; })) {
                    _this.entityContext.PeriodId = _this.entityContext.Periods[0].Id;
                    _this.entityContext.PaymentDate = _this.entityContext.Periods[0].SuggestedPayoutDate
                        ? _this.entityContext.Periods[0].SuggestedPayoutDate
                        : _this.entityContext.Periods[0].EndDate;
                    return;
                }
                _this.entityContext.PeriodId = salaryPeriod.Id;
                _this.entityContext.PaymentDate = salaryPeriod.SuggestedPayoutDate
                    ? salaryPeriod.SuggestedPayoutDate
                    : salaryPeriod.EndDate;
            }
            else {
                // default for period control
                var periodItem = _this.entityContext.Periods
                    ? _this.entityContext.Periods.find(function (period) { return period.Id === _this.entityContext.PeriodId; })
                    : undefined;
                if (!periodItem) {
                    _this.entityContext.PeriodId =
                        _this.entityContext.Periods.length > 0 ? _this.entityContext.Periods[0].Id : undefined;
                }
            }
        });
    };
    SalaryBatchDetailsComponent.prototype.checkPeriod = function () {
        var _this = this;
        var selectedSalaryBatch = this.salaryBatch;
        if (selectedSalaryBatch && this.entityContext.PeriodId === selectedSalaryBatch.SalaryPeriodId) {
            this.entityContext.HasBatch = false;
        }
        else {
            if (this.entityContext.PeriodId) {
                this.dataService
                    .SalaryBatches_SalaryPeriodHasBatches(this.entityContext.PeriodId)
                    .subscribe(function (hasBatch) { return (_this.entityContext.HasBatch = hasBatch); });
            }
        }
    };
    SalaryBatchDetailsComponent.prototype.setPaymentDateDueToPeriodChange = function () {
        var _this = this;
        var changedID = this.entityContext.PeriodId;
        var periods = this.entityContext.Periods;
        if (periods) {
            periods.forEach(function (salaryPeriod, key) {
                if (periods[key].Id === changedID) {
                    _this.entityContext.PaymentDate = periods[key].SuggestedPayoutDate;
                }
            });
            if (this.responsePaymentDate) {
                this.entityContext.PaymentDate = this.responsePaymentDate;
                this.responsePaymentDate = undefined;
            }
        }
    };
    SalaryBatchDetailsComponent.prototype.decodeEntities = function (encodedString) {
        var translatere = /&(nbsp|amp|quot|lt|gt);/g;
        var translate = {
            nbsp: ' ',
            amp: '&',
            quot: '"',
            lt: '<',
            gt: '>'
        };
        return encodedString
            .replace(translatere, function (match, entity) {
            return translate[entity];
        })
            .replace(/&#(\d+);/gi, function (match, numStr) {
            var num = parseInt(numStr, 10);
            return String.fromCharCode(num);
        });
    };
    return SalaryBatchDetailsComponent;
}());
export { SalaryBatchDetailsComponent };
