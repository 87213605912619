import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { EmployeeService } from 'src/app/services/employee.service';
import { FixedPanelService } from 'src/app/services/fixed-panel.service';
import { Global } from '../../common/global';
import { NumericTextBoxOptions } from '../../custom-controls/numeric-edit/numeric-text-box-options';
import {
  IRejectionInfo,
  ISalaryPeriod,
  ISimpleKeyValuePair,
  ITimeEntryRecord,
  IUserEmployment,
  TimeEntryTypeView
} from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-employee-time-mobile',
  templateUrl: './employee-time-mobile.component.html',
  styleUrls: ['./employee-time-mobile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeTimeMobileComponent implements OnInit, OnDestroy {
  @Input()
  public get salaryPeriods(): ISalaryPeriod[] {
    return this.currentsalaryPeriods;
  }

  public set salaryPeriods(value: ISalaryPeriod[]) {
    this.currentsalaryPeriods = value;
  }

  @Input()
  public get selectedSalaryPeriodId(): number {
    return this.currentselectedSalaryPeriodId;
  }

  public set selectedSalaryPeriodId(value: number) {
    this.currentselectedSalaryPeriodId = value;
    this.selectedSalaryPeriodIdChange.emit(this.currentselectedSalaryPeriodId);
  }

  @Input()
  public get isAddNew(): boolean {
    return this.currentisAddNew;
  }

  public set isAddNew(value: boolean) {
    if (value === true) {
      this.createNewTimeEntryTypeModel();
      this.ApplyTimeEntryRecordModalLogic();
      this.updateDimension.emit();
    }
    this.currentisAddNew = value;
    this.isAddNewChange.emit(value);
  }
  @Output() public updateDimension: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  public get isUpdate(): boolean {
    return this.currentisUpdate;
  }

  public set isUpdate(value: boolean) {
    this.currentisUpdate = value;
    this.updateDimension.emit();
    this.isUpdateChange.emit(value);
  }

  @Input()
  public get timeEntryTypes(): any[] {
    return this.currenttimeEntryTypes;
  }

  public set timeEntryTypes(value: any[]) {
    this.currenttimeEntryTypes = value;
    if (value && value.length > 0) {
      this.defaultTimeEntryType = value.find(
        (mode: any) => mode.Identifier === 'Work' || mode.Identifier === 'Overtime'
      );

      if (this.defaultTimeEntryType === undefined || this.defaultTimeEntryType === null) {
        this.defaultTimeEntryType = value[0];
      }
    }
  }

  private timeEntryRecordsValues: ITimeEntryRecord[] = [];
  @Input()
  public get timeEntryRecords(): ITimeEntryRecord[] {
    return this.timeEntryRecordsValues;
  }

  public set timeEntryRecords(value: ITimeEntryRecord[]) {
    if (value && value.length > 0) {
      this.timeEntryRecordsValues = value.sort((item1: ITimeEntryRecord, item2: ITimeEntryRecord) => {
        if (item1.EntryDate && item2.EntryDate) {
          return new Date(item1.EntryDate).getTime() - new Date(item2.EntryDate).getTime();
        }
        return 0;
      });
    } else {
      this.timeEntryRecordsValues = [];
    }
  }

  @Input() public userEmployment: IUserEmployment;
  @Input() public unitTypes: any[];
  @Input() public deleteConfimMessage: string;
  @Input() public rejectionWarningText: string;
  @Input() public isReadOnly: boolean;
  @Input() public dimensionXValues: any;
  @Input() public isEnableDimensionPreference: boolean;

  @Input() public isHideDimension1: boolean;
  @Input() public isHideDimension2: boolean;
  @Input() public isHideDimension3: boolean;
  @Input() public Dimension1Name: string;
  @Input() public Dimension2Name: string;
  @Input() public Dimension3Name: string;
  @Input() public PeriodWarningText: string;

  @Output() public approveEntries: EventEmitter<any> = new EventEmitter<any>();
  @Output() public periodsChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public selectedSalaryPeriodIdChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public isAddNewChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public isUpdateChange: EventEmitter<any> = new EventEmitter<any>();

  public timeEntryModel: any;
  public currentselectedSalaryPeriodId: number;
  public currentsalaryPeriods: ISalaryPeriod[];
  public currentisAddNew = false;
  public currentisUpdate = false;
  public isUpdateActive = false;
  public isRejectModel = false;
  public showSelectPeriodDialog = false;

  public isVisibleUnit = false;
  public isVisibleFrom = false;
  public isVisibleUntill = false;
  public allowSelfServiceTimeEntry = false;
  public isgetNotMatchPeriodMessageString = false;

  public isVisibleRejectbutton = true;
  public isVisibleDeletebytton = true;

  public currenttimeEntryTypes: any[];
  public confirmDeleteDialogVisible: boolean;
  public confirmRejectDialogVisible: boolean;
  public rejectReason: string;
  public getNotMatchPeriodMessageString: string;
  public NumericOptions: NumericTextBoxOptions = { format: 'n2', decimals: 4, step: 1, min: 0, spinners: false };
  private defaultTimeEntryType: any;
  private employeeSelectedId: number;

  constructor(
    private dataService: DataService,
    public sessionService: SessionService,
    private staticDataService: StaticDataService,
    private employeeService: EmployeeService,
    private fixedPanelService: FixedPanelService
  ) {}

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    const objectAllowSelfServiceTimeEntry: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
      'Access.AllowSelfServiceTimeEntry'
    );
    if (objectAllowSelfServiceTimeEntry && objectAllowSelfServiceTimeEntry.Value === 'true') {
      this.allowSelfServiceTimeEntry = true;
    }

    this.employeeService.selectedEmployeeId.subscribe((value: number) => {
      this.employeeSelectedId = value;
    });
  }

  public setTranslationTerms(translations: { [key: string]: string }): void {
    this.PeriodWarningText = translations['EmploymentTime.PeriodWarning'];
  }

  public get isHideButtonAction(): boolean {
    return !this.isSelfService && this.isReadOnly;
  }

  public get isHideRejectAction(): boolean {
    return this.isSelfService || this.isReadOnly;
  }

  public get isEmptyRecord(): boolean {
    if (this.timeEntryRecords && this.timeEntryRecords.length > 0) {
      return false;
    }
    return true;
  }

  public get isEmptyTimeEntryType(): boolean {
    return !(this.timeEntryTypes && this.timeEntryTypes.length > 0);
  }

  public onAddNew(): void {
    if (this.sessionService.isGetting) {
      return;
    }
    setTimeout(() => {
      this.fixedPanelService.calculateHeightContent();
    }, 500);
    this.isAddNew = true;
  }

  public onPreviewModalDialog(): void {
    this.showSelectPeriodDialog = true;
  }

  public ApproveEntries(): void {
    if (this.isReadOnLyControlSelfService) {
      return;
    }
    this.approveEntries.emit();
  }

  public onSalaryPeriodChanged(): void {
    this.periodsChange.emit();
  }

  public selectTimeEntryRecoredMobileCentric(model: ITimeEntryRecord): void {
    this.isVisibleRejectbutton = true;
    this.isVisibleDeletebytton = true;
    this.timeEntryModel = model;
    if (this.timeEntryModel.Status.Key === 'Open' || this.timeEntryModel.Status.Key === 'Rejected') {
      this.isUpdateActive = true;
      this.isRejectModel = this.timeEntryModel.Status.Key === 'Rejected' ? true : false;
    } else {
      this.isUpdateActive = false;
    }
    if (this.isHideButtonAction) {
      this.isUpdateActive = false;
    }

    this.onCheckActionButtonVisibale(this.timeEntryModel.Status.Key);
    this.ApplyTimeEntryRecordModalLogic(false);
    this.isUpdate = !this.isUpdate;
  }

  public onCheckActionButtonVisibale(status: string): void {
    if (status) {
      switch (status) {
        case 'Open': {
          this.isVisibleRejectbutton = true;
          break;
        }
        case 'Rejected': {
          this.isVisibleRejectbutton = true;
          break;
        }
        case 'EmployeeApproved': {
          this.isVisibleDeletebytton = false;

          if (!this.isManager) {
            this.isVisibleDeletebytton = true;
            this.isVisibleRejectbutton = false;
          } else {
            this.isVisibleRejectbutton = true;
          }
          break;
        }
        case 'AdminApproved':
        case 'Finalized': {
          this.isVisibleRejectbutton = false;
          this.isVisibleDeletebytton = false;
          break;
        }
      }
    }
  }

  public get titleDialog(): string {
    if (!this.isUpdate) {
      return 'EmploymentTime.AddNewRecord';
    }

    return '';
  }

  public get isManager(): boolean {
    return (
      (Global.SESSION &&
        Global.SESSION.ManagerForUserEmploymentIds &&
        Global.SESSION.ManagerForUserEmploymentIds.length > 0 &&
        this.userEmployment &&
        !!Global.SESSION.ManagerForUserEmploymentIds.find((item: number) => item === this.userEmployment.Id)) ||
      (Global.SESSION.CurrentCompanyUser &&
        this.employeeSelectedId !== Global.SESSION.CurrentCompanyUser.Id &&
        this.sessionService.role.IsSalaryAdminAndHigher)
    );
  }

  public loadstatusLine(nameStatus: string, lineClass?: string): string {
    let res: string;
    switch (nameStatus) {
      case 'Open': {
        if (lineClass && lineClass.includes('NonWorkedBackround')) {
          res = 'NonWorkedLine';
          break;
        }
        res = 'OpenLine';
        break;
      }
      case 'AdminApproved': {
        res = 'AdminApprovedLine';
        break;
      }
      case 'Rejected': {
        res = 'RejectedLine';
        break;
      }
      case 'EmployeeApproved': {
        res = 'EmployeeApprovedLine';
        break;
      }
      case 'Finalized': {
        res = 'AdminApprovedLine';
        break;
      }
      default: {
        res = 'defaultnonestatus';
        break;
      }
    }

    return res;
  }

  public LineBackground(model: ITimeEntryRecord): string {
    let classRecord = '';
    const timeEntryType: TimeEntryTypeView = this.timeEntryTypes.find((type: any) => type.Id === model.TimeEntryTypeId);
    if (timeEntryType.Identifier !== 'Work' && timeEntryType.Identifier !== 'Overtime') {
      classRecord = 'NonWorkedBackround';
    }
    if (model.Status.Key === 'Rejected') {
      classRecord = 'RejectedBackround';
    }
    const indexOfModel: number = this.timeEntryRecords.indexOf(model);
    if ((indexOfModel === 0 || indexOfModel) && indexOfModel < this.timeEntryRecords.length - 1) {
      const nextDay = this.sessionService.toString(new Date(this.timeEntryRecords[indexOfModel + 1].EntryDate), 'dd');
      const currentDay = this.sessionService.toString(new Date(this.timeEntryRecords[indexOfModel].EntryDate), 'dd');
      if (Number(nextDay) - Number(currentDay) === 1) {
        classRecord += ' On1DayApart';
      } else {
        classRecord += ' OnMoreDayApart';
      }
    }
    return classRecord;
  }

  public get isSelfService(): boolean {
    return this.sessionService.currentState === 'tabs.selfservice.time';
  }

  public get toolTipDenyEdit(): string {
    if (!this.allowSelfServiceTimeEntry) {
      return 'EmploymentTime.DisableGridTimeMessage';
    }
    return '';
  }

  public get isReadOnLyControlSelfService(): boolean {
    if (this.isSelfService && !this.allowSelfServiceTimeEntry) {
      return true;
    }

    return false;
  }

  public ConvertDaytoString(timestring: string): string {
    if (timestring) {
      return this.sessionService.toString(new Date(timestring), 'dd');
    }

    return '';
  }

  public ConvertMonthtoString(timestring: string): string {
    if (timestring) {
      return this.sessionService.toString(new Date(timestring), 'MMM');
    }

    return '';
  }

  public checkexistenddate(enddate: string): boolean {
    if (enddate) {
      return true;
    }

    return false;
  }

  public checkIsNullOrEmptyString(value: string): boolean {
    if (!value) {
      return true;
    }

    return false;
  }

  public getTimeEntryTypeName(timeEntryTypeId: number): string {
    if (this.timeEntryTypes && this.timeEntryTypes.length > 0) {
      const res: any = this.timeEntryTypes.find((model: any) => model.TimeEntryTypeId === timeEntryTypeId);
      if (res && res.Name) {
        return res.Name;
      } else if (res && res.BaseTimeEntryType && res.BaseTimeEntryType.Name) {
        return res.BaseTimeEntryType.Name;
      }
    }

    return '';
  }

  public formatunit(unitstring: string): string {
    if (unitstring) {
      return this.sessionService.toString(parseFloat(unitstring), '0.00');
    }
    return '';
  }

  public showUnitType(nameType: string): boolean {
    if (nameType && nameType === 'Hours') {
      return false;
    }
    return true;
  }

  public selectListSalaryPeriods(model: ISalaryPeriod): void {
    if (model) {
      this.selectedSalaryPeriodId = model.Id;
      this.periodsChange.emit();
    }
    this.showSelectPeriodDialog = !this.showSelectPeriodDialog;
  }

  public createNewTimeEntryTypeModel(): void {
    this.timeEntryModel = {};
    if (this.timeEntryTypes) {
      const defaultTypeEntryType: any = this.defaultTimeEntryType;
      let defaultUnitType: any = defaultTypeEntryType
        ? this.unitTypes
          ? this.unitTypes.find((type: any) => type.Id === defaultTypeEntryType.UnitTypeId)
          : null
        : null;
      defaultUnitType = defaultUnitType ? defaultUnitType : this.unitTypes ? this.unitTypes[0] : null;
      const today: Date = this.staticDataService.getCurrentdate();
      this.timeEntryModel = {
        TimeEntryTypeId: defaultTypeEntryType ? defaultTypeEntryType.TimeEntryTypeId : null,
        EntryDate: today,
        Units: 0,
        UnitTypeId: defaultUnitType ? defaultUnitType.Id : null,
        UnitType: defaultUnitType ? defaultUnitType : null,
        Created: today,
        SalaryPeriodId: this.selectedSalaryPeriodId,
        UserEmploymentId: this.userEmployment.Id,
        CalculatedPayoutKilometers: 0
      };

      const timeEntryType: any =
        this.timeEntryTypes && this.timeEntryTypes.length > 0
          ? this.timeEntryTypes.find((e: any) => e.TimeEntryTypeId === this.timeEntryModel.TimeEntryTypeId)
          : null;
      this.timeEntryModel.isEditUnitType = timeEntryType ? timeEntryType.AllowEditUnitType : false;

      if (this.salaryPeriods && this.salaryPeriods.length > 0 && this.selectedSalaryPeriodId) {
        const salaryPeriod: ISalaryPeriod = this.salaryPeriods.find(
          (model: ISalaryPeriod) => model.Id === this.selectedSalaryPeriodId
        );
        if (salaryPeriod) {
          if (
            new Date(this.timeEntryModel.EntryDate) < new Date(salaryPeriod.StartDate) ||
            new Date(this.timeEntryModel.EntryDate) > new Date(salaryPeriod.EndDate)
          ) {
            this.timeEntryModel.EntryDate = salaryPeriod.StartDate;
          }
        }
      }
    }
  }

  public onUnitTypeChange(): void {
    if (this.timeEntryModel.UnitTypeId) {
      this.timeEntryModel.UnitType = this.unitTypes.find((model: any) => model.Id === this.timeEntryModel.UnitTypeId);
      if (
        this.timeEntryModel.UnitType &&
        this.timeEntryModel.UnitType.Key === 'Days' &&
        !this.timeEntryModel.EndDate &&
        !this.timeEntryModel.Units
      ) {
        this.timeEntryModel.Units = 1;
      }
    }
  }

  public ApplyTimeEntryRecordModalLogic(isRecalculate: boolean = true): void {
    setTimeout(() => {
      if (this.timeEntryModel) {
        if (this.timeEntryModel.UnitTypeId) {
          this.timeEntryModel.UnitType = this.unitTypes.find(
            (model: any) => model.Id === this.timeEntryModel.UnitTypeId
          );
        }
        if (this.timeEntryModel.UnitType && this.timeEntryModel.UnitType.Key === 'Hours') {
          this.isVisibleFrom = true;
          this.isVisibleUntill = true;
          this.isVisibleUnit = !(this.timeEntryModel.StartTime && this.timeEntryModel.EndTime);
        } else {
          this.isVisibleFrom = false;
          this.isVisibleUntill = false;
          this.isVisibleUnit = !(this.timeEntryModel.EntryDate && this.timeEntryModel.EndDate);
        }

        const timeEntryType: any =
          this.timeEntryTypes && this.timeEntryTypes.length > 0
            ? this.timeEntryTypes.find((e: any) => e.TimeEntryTypeId === this.timeEntryModel.TimeEntryTypeId)
            : null;
        this.timeEntryModel.isEditUnitType = timeEntryType ? timeEntryType.AllowEditUnitType : false;

        if (isRecalculate) {
          this.updateUnitsByDateAndTime(this.timeEntryModel);
        }
      }
    });
  }

  public updateUnitsByDateAndTime(model: ITimeEntryRecord): void {
    const uniType: any =
      model.UnitTypeId && this.unitTypes ? this.unitTypes.find((type: any) => type.Id === model.UnitTypeId) : null;
    const isHoursUnitType: boolean = uniType && uniType.Key === 'Hours';
    const isDaysUnitType: boolean = uniType && uniType.Key === 'Days';

    let start: Date;
    let today: Date = new Date();
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    if (model.StartTime) {
      start = new Date(today);
      start.setHours(
        this.sessionService.parseInt(model.StartTime.split(/\:/g)[0]),
        this.sessionService.parseInt(model.StartTime.split(/\:/g)[1]),
        0,
        0
      );
    }

    let end: Date;
    if (model.EndTime) {
      end = new Date(today);
      end.setHours(
        this.sessionService.parseInt(model.EndTime.split(/\:/g)[0]),
        this.sessionService.parseInt(model.EndTime.split(/\:/g)[1]),
        0,
        0
      );
    }

    model.StartTime = start ? this.sessionService.toString(start, 'HH:mm:ss') : '';
    model.EndTime = end
      ? model.EndTime.indexOf('24:00') === 0
        ? '24:00:00'
        : this.sessionService.toString(end, 'HH:mm:ss')
      : '';

    if (isHoursUnitType) {
      if (model.StartTime && model.EndTime) {
        start.setHours(start.getHours(), start.getMinutes(), 0, 0);
        let startTime: any = start.getTime();
        let endTime: any;

        if (model.EntryDate && model.EndDate) {
          const entryDate: any = new Date(model.EntryDate);
          const endDate: any = new Date(model.EndDate);
          startTime = entryDate.setHours(start.getHours(), start.getMinutes(), 0, 0);
          endTime = endDate.setHours(end.getHours(), end.getMinutes(), 0, 0);
        } else {
          end.setHours(end.getHours(), end.getMinutes(), 0, 0);
          endTime = end;
          endTime = start > end ? endTime.setDate(end.getDate() + 1) : endTime.getTime();
        }

        const timeDiff: any = endTime - startTime;
        model.Units = Math.abs(Math.floor(timeDiff / 3600) / 1000);
      }
      ////else {
      ////    this.calculateUnitByStartAndEndDate(model, isHoursUnitType);
      ////}
    }
  }

  public checkIsvalidPost(model: ITimeEntryRecord): boolean {
    if (model && model.EntryDate) {
      // switch (model.UnitType.Key) {
      //   case 'Hours': {
      //     if (model.Units !== null && model.Units >= 0) {
      //       return true;
      //     }
      //     return false;
      //   }
      //   default: {
      //     if (model.Units !== null && model.Units >= 0) {
      //       return true;
      //     } else {
      //       if (model.EndDate && model.EndDate > model.EntryDate) {
      //         return true;
      //       }
      //       return false;
      //     }
      //   }
      // }

      if (model.TimeEntryTypeId && model.EntryDate && (model.Units || model.Units === 0)) {
        return true;
      }
    }
    return false;
  }

  public CreateNewTimeentryRecordMobileCentric(): void {
    this.onUnitTypeChange();
    const newRecord: ITimeEntryRecord = Object.assign({}, this.timeEntryModel);
    newRecord.StartTime =
      newRecord.StartTime && newRecord.StartTime.indexOf('24:00') === 0 ? '1.00:00:00' : newRecord.StartTime;
    newRecord.EndTime =
      newRecord.EndTime && newRecord.EndTime.indexOf('24:00') === 0 ? '1.00:00:00' : newRecord.EndTime;
    newRecord.CalculatedPayoutKilometers = 0;

    newRecord.ApprovedUserId = newRecord.ApprovedUserId || null;
    newRecord.RejectedUserId = newRecord.RejectedUserId || null;
    newRecord.Dimension1ValueId = newRecord.Dimension1ValueId || null;
    newRecord.Dimension2ValueId = newRecord.Dimension2ValueId || null;
    newRecord.Dimension3ValueId = newRecord.Dimension3ValueId || null;

    this.dataService.TimeEntry_CreateTimeEntryRecord(newRecord).subscribe(
      (updatedRecord: any) => {
        this.periodsChange.emit();
        this.isAddNew = !this.isAddNew;
        if (updatedRecord && updatedRecord.SalaryPeriodId !== newRecord.SalaryPeriodId) {
          this.getNotMatchPeriodMessageString = this.getNotMatchPeriodMessage(newRecord);
          this.isgetNotMatchPeriodMessageString = true;
        }
      },
      (error: any) => {
        console.error('Create request failed:' + error);
        this.periodsChange.emit();
        this.isAddNew = !this.isAddNew;
      }
    );
  }

  public UpdateNewTimeentryRecordMobileCentric(): void {
    this.onUnitTypeChange();
    const updateRecord: ITimeEntryRecord = Object.assign({}, this.timeEntryModel);
    updateRecord.StartTime =
      updateRecord.StartTime && updateRecord.StartTime.indexOf('24:00') === 0 ? '1.00:00:00' : updateRecord.StartTime;
    updateRecord.EndTime =
      updateRecord.EndTime && updateRecord.EndTime.indexOf('24:00') === 0 ? '1.00:00:00' : updateRecord.EndTime;
    updateRecord.CalculatedPayoutKilometers = 0;

    updateRecord.ApprovedUserId = updateRecord.ApprovedUserId || null;
    updateRecord.RejectedUserId = updateRecord.RejectedUserId || null;
    updateRecord.Dimension1ValueId = updateRecord.Dimension1ValueId || null;
    updateRecord.Dimension2ValueId = updateRecord.Dimension2ValueId || null;
    updateRecord.Dimension3ValueId = updateRecord.Dimension3ValueId || null;

    this.dataService.TimeEntry_UpdateTimeEntryRecord(updateRecord).subscribe(
      (updatedRecord: any) => {
        this.periodsChange.emit();
        this.isUpdate = !this.isUpdate;
        if (updatedRecord && updatedRecord.SalaryPeriodId !== updateRecord.SalaryPeriodId) {
          this.getNotMatchPeriodMessageString = this.getNotMatchPeriodMessage(updateRecord);
          this.isgetNotMatchPeriodMessageString = true;
        }
      },
      (error: any) => {
        console.error('Update request failed:' + error);
        this.periodsChange.emit();
        this.isUpdate = !this.isUpdate;
      }
    );
  }

  public onConfirmDeleteDialogAction(action: string): void {
    if (action === 'Delete') {
      this.dataService.TimeEntry_DeleteTimeEntryRecord(this.timeEntryModel.Id).subscribe(
        () => {
          this.periodsChange.emit();
          this.isUpdate = !this.isUpdate;
        },
        (error: any) => {
          console.error('Delete request failed:' + error);
          this.periodsChange.emit();
          this.isUpdate = !this.isUpdate;
        }
      );
    }
  }

  public DeleteNewTimeentryRecordMobileCentric(): void {
    this.confirmDeleteDialogVisible = true;
  }

  public onConfirmRejectDialogAction(action: string): void {
    if (action === 'Reject') {
      const rejectionInfo: IRejectionInfo = { Reason: this.rejectReason };
      this.dataService.TimeEntry_RejectTimeEntryRecord(this.timeEntryModel.Id, rejectionInfo).subscribe(() => {
        this.periodsChange.emit();
        this.isUpdate = !this.isUpdate;
      });
    }
  }

  public RejectNewTimeentryRecordMobileCentric(): void {
    this.rejectReason = '';
    this.confirmRejectDialogVisible = true;
  }

  public isCloseModal(action: string): void {
    if (action === 'create') {
      this.isAddNew = !this.isAddNew;
      this.createNewTimeEntryTypeModel();
    }
    if (action === 'update') {
      this.isUpdate = !this.isUpdate;
    }
    this.periodsChange.emit();
  }

  public onTimeEntryTypesChange(timeEntryTypeId: number): void {
    ////this.newModel.TimeEntryTypeId = timeEntryTypeId;
    if (this.timeEntryTypes && this.timeEntryTypes.length > 0 && this.timeEntryModel.TimeEntryTypeId) {
      const timeEntryType: any = this.timeEntryTypes
        ? this.timeEntryTypes.find((e: any) => e.TimeEntryTypeId === timeEntryTypeId)
        : null;
      this.timeEntryModel.TimeEntryType = timeEntryType;
      this.timeEntryModel.Description = timeEntryType ? timeEntryType.Description : null;
      this.timeEntryModel.UnitTypeId =
        timeEntryType && timeEntryType.UnitTypeId
          ? timeEntryType.UnitTypeId
          : this.unitTypes && this.unitTypes.length > 0
          ? this.unitTypes[0].Id
          : null;
      if (this.timeEntryModel.UnitTypeId) {
        this.timeEntryModel.UnitType = this.unitTypes.find((type: any) => type.Id === this.timeEntryModel.UnitTypeId);
      }
    }

    this.ApplyTimeEntryRecordModalLogic();
  }

  public onCloseDialogEdit(): void {
    this.periodsChange.emit();
    this.isAddNew = false;
  }

  public get SelectedSalaryPeriod(): ISalaryPeriod {
    if (!this.selectedSalaryPeriodId || !this.salaryPeriods || this.salaryPeriods.length <= 0) {
      return null;
    }

    return this.salaryPeriods.filter((period: ISalaryPeriod): boolean => {
      return period.Id === this.selectedSalaryPeriodId;
    })[0];
  }

  public getNotMatchPeriodMessage(record: any): string {
    if (record && record.EntryDate) {
      let outOfPeriodDate: Date;
      const entryDate: Date = record.EntryDate;
      const startDate: Date = new Date(this.SelectedSalaryPeriod.StartDate.toString());
      const endDate: Date = new Date(this.SelectedSalaryPeriod.EndDate.toString());
      endDate.setHours(23, 59, 59);
      if (entryDate < startDate || entryDate > endDate) {
        outOfPeriodDate = record.EntryDate;
      } else {
        outOfPeriodDate = record.EndDate;
      }

      return this.PeriodWarningText.replace('$$$', this.sessionService.toString(outOfPeriodDate));
    }

    return '';
  }
}
