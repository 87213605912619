<div class="Card Form--stacked">
  <div class="Card-inner Card-batches">
    <div class="Card-title Card-title--top">{{ 'CompanySalaryBatches.Batches' | translate }}</div>
    <div class="Company-batchesControls">
      <app-combobox-edit
        class="Company-batchesCycles"
        [label]="'CompanySalaryBatches.Cycle' | translate"
        [editMode]="true"
        [comboboxDataSource]="salaryCycles"
        [(value)]="salaryCycleId"
        [textField]="'Name'"
        [nullValueLabel]="'CompanySalaryBatches.SalaryCycle_Item_All_Text' | translate"
        [idField]="'Id'"
      >
      </app-combobox-edit>

      <div class="Company-batchesControlsButtons">
        <app-button
          *ngIf="isShowApprovalButtonNonScreenCheck"
          class="Button--card"
          [isDisabled]="!isDraft"
          (clickEvent)="onClickButton('Recalculate')"
          [label]="'CompanySalaryBatches.Recalculate' | translate"
        >
        </app-button>
        <app-module-check [moduleId]="3">
          <app-button
            class="Button--card"
            *ngIf="isShowApprovalButton"
            (clickEvent)="onClickButton('Submit')"
            [label]="'GlobalDialog.SubmitForApproval' | translate"
          >
          </app-button>
        </app-module-check>
        <app-dialog-action
          [requiredModuleId]="3"
          [type]="'SubmitForApproval'"
          [disabled]="sessionService.isSubmitting"
          *ngIf="isShowApprovalButton"
        >
        </app-dialog-action>
        <app-button
          *ngIf="isPaymentApprover && selectedBatch && (isDraft || isLocked || isPreliminary)"
          class="Button--card"
          (clickEvent)="onClickButton('Finalize')"
          [label]="'GlobalDialog.Finalize' | translate"
        >
        </app-button>
        <app-button
          class="Button--card"
          *ngIf="isAddNewBatchButtonVisible"
          (clickEvent)="onClickButton('NewSalaryBatch')"
          [label]="'CompanySalaryBatches.SalaryBatchAddNewButton' | translate"
        >
        </app-button>
      </div>
    </div>
    <div class="FormElement">
      <app-grid
        [data]="gridFilterData"
        [idColumn]="'Id'"
        [filterable]="'menu'"
        [filter]="filter"
        (filterChange)="onFilterChange($event)"
        [(selectedItem)]="selectedBatch"
        (selectedItemChange)="onSalaryBatchSelectionChanged($event)"
        (clickAppGridIcon)="onGridIconClick($event)"
        [editMode]="false"
        class="FormElement-grid Company-salaryBatchesGrid"
        (dblClick)="onDoubleClick($event)"
        [(triggerUpdate)]="triggerUpdate"
      >
        <app-grid-column
          [isFilterable]="true"
          [field]="'SalaryBatchNumber'"
          [title]="'CompanySalaryBatches.SalaryBatchesGridField_No' | translate"
          [width]="50"
          [type]="'numeric'"
          [numbericOption]="'n0'"
          [headerTooltip]="'CompanySalaryBatches.SalaryBatchesGridField_No' | translate"
          [addOnClass]="'truncated-header'"
        >
        </app-grid-column>
        <app-grid-column
          [isFilterable]="true"
          [field]="'StatusName'"
          [title]="'CompanySalaryBatches.SalaryBatchesGridField_Status' | translate"
          [width]="120"
          [viewClass]="truncatedOrWrapped()"
          [type]="'text'"
          [headerTooltip]="'CompanySalaryBatches.SalaryBatchesGridField_Status' | translate"
          [tooltipField]="'StatusName'"
          [addOnClass]="'truncated-header'"
        ></app-grid-column>
        <app-grid-column
          [isFilterable]="true"
          [iconField]="'ValidationIcon'"
          [title]="''"
          [width]="35"
          [type]="'icon'"
          [iconAction]="'ShowErrors'"
          [headerTooltip]="'CompanySalaryBatches.SalaryBatchesGridField_Validation' | translate"
        ></app-grid-column>
        <app-grid-column
          [isFilterable]="true"
          [field]="'PayoutDate'"
          [title]="'CompanySalaryBatches.PaymentDate' | translate"
          [width]="90"
          [type]="'datepicker'"
          [headerTooltip]="'CompanySalaryBatches.PaymentDate' | translate"
          [addOnClass]="'truncated-header'"
        >
        </app-grid-column>
        <app-grid-column
          [isFilterable]="true"
          [field]="'PeriodStartDate'"
          [title]="'CompanySalaryBatches.SalaryBatchesGridField_Start' | translate"
          [width]="75"
          [type]="'datepicker'"
          [headerTooltip]="'CompanySalaryBatches.SalaryBatchesGridField_Start' | translate"
          [addOnClass]="'truncated-header'"
        ></app-grid-column>
        <app-grid-column
          [isFilterable]="true"
          [field]="'PeriodEndDate'"
          [title]="'CompanySalaryBatches.SalaryBatchesGridField_End' | translate"
          [width]="75"
          [type]="'datepicker'"
          [headerTooltip]="'CompanySalaryBatches.SalaryBatchesGridField_End' | translate"
          [addOnClass]="'truncated-header'"
        ></app-grid-column>

        <app-grid-column
          [isFilterable]="true"
          [field]="'EIncomeZeroReport'"
          [editable]="false"
          [title]="'CompanySalaryBatches.SalaryBatchesGridField_Empty' | translate"
          [width]="75"
          [type]="'checkbox'"
          [headerTooltip]="'CompanySalaryBatches.SalaryBatchesGridField_Empty' | translate"
          [addOnClass]="'truncated-header'"
        ></app-grid-column>
        <app-grid-column
          [isFilterable]="true"
          [field]="'LimitToSpecificEmployees'"
          [editable]="false"
          [tooltip]="'CompanySalaryBatches.LimitedNotification' | translate"
          [title]="'CompanySalaryBatches.SalaryBatchesGridField_Limited' | translate"
          [width]="75"
          [type]="'checkbox'"
          [headerTooltip]="'CompanySalaryBatches.SalaryBatchesGridField_Limited' | translate"
          [addOnClass]="'truncated-header'"
        ></app-grid-column>
        <app-grid-column
          [isFilterable]="true"
          [class]="'u-nowrap'"
          [field]="'ApprovedByUserFullName'"
          [title]="'CompanySalaryBatches.SalaryBatchesGridField_Approver' | translate"
          [viewClass]="truncatedOrWrapped()"
          [width]="120"
          [type]="'text'"
          [tooltipField]="'ApprovedByUserFullName'"
          [headerTooltip]="'CompanySalaryBatches.SalaryBatchesGridField_Approver' | translate"
          [addOnClass]="'truncated-header'"
        ></app-grid-column>
        <app-grid-column
          [isFilterable]="true"
          [class]="'u-nowrap'"
          [field]="'FinalizedByUserFullName'"
          [title]="'CompanySalaryBatches.FinalizedByCompanyUserFullName' | translate"
          [viewClass]="truncatedOrWrapped()"
          [width]="120"
          [type]="'text'"
          [tooltipField]="'FinalizedByUserFullName'"
          [headerTooltip]="'CompanySalaryBatches.FinalizedByCompanyUserFullName' | translate"
          [addOnClass]="'truncated-header'"
        ></app-grid-column>
        <app-grid-column
          [isFilterable]="true"
          *ngIf="!isReadOnly"
          [iconField]="'EditIcon'"
          [iconAction]="'Edit'"
          [title]="''"
          [tooltip]="'CompanySalaryBatches.SalaryBatchGridEditButtonTooltipText' | translate"
          [width]="40"
          [type]="'icon'"
        ></app-grid-column>
      </app-grid>
    </div>
  </div>
</div>
