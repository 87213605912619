<div class="PreferencesView Island-configuration">
  <div class="Card Card--large" *ngIf="companyPreferenceService.isCompanySetting && !companyPreferenceService.isMobile">
    <div class="Card-inner panelbar-view">
      <div class="FormElement hint">
        {{ 'Preference.Warning' | translate }}
      </div>

      <div class="FormElement" style="overflow:auto; overflow-x: hidden !important;">
        <div class="PreferencesControls header-only FormElement-grid k-grid k-widget">
          <table class="Preferences-list">
            <thead>
              <tr>
                <th>{{ 'Preference.Name' | translate }}</th>
                <th>{{ 'Preference.Description' | translate }}</th>
                <th>{{ 'Preference.Value' | translate }}</th>
              </tr>
            </thead>
          </table>
        </div>

        <kendo-panelbar
          class="PreferencesView-destop-panelbar"
          #companyPanelBar
          [expandMode]="1"
          [animate]="false"
          (stateChange)="onCompanyPanelChanged($event)"
        >
          <kendo-panelbar-item
            *ngFor="let category of companyPreferenceService.companyReferencesCategory | async"
            title=""
          >
            <ng-template kendoPanelBarItemTitle let-dataItem>
              <span class="panel-title">{{ category.Name }}</span>
            </ng-template>

            <ng-template kendoPanelBarContent>
              <div class="PreferencesControls FormElement-grid k-grid k-widget">
                <table class="Preferences-list">
                  <tbody>
                    <tr *ngFor="let item of category.Preferences">
                      <td>{{ item.Name }}</td>
                      <td>
                        {{ item.Description }}
                        <span
                          *ngIf="item.HelpUrl && !companyPreferenceService.isIOSMobileApp"
                          class="textWithLink"
                          (click)="onReadMoreClick(item.HelpUrl)"
                          >{{ 'Help.ReadMore' | translate }}</span
                        >
                      </td>
                      <td class="align-center">
                        <app-module-check [moduleId]="item.RequiredModuleId" [(hasModule)]="item.hasModule">
                          <app-check-edit
                            *ngIf="companyPreferenceService.isDataTypeBoolean(item.DataType)"
                            [(value)]="item.Value"
                            [disable]="item.RequiredModuleId && !item.hasModule"
                            customClass="checkBox-center"
                            [editMode]="!IsReadOnly"
                            (valueChange)="companyPreferenceService.submitValueChange($event, item)"
                          ></app-check-edit>

                          <app-numeric-edit
                            *ngIf="companyPreferenceService.isDataTypeNumber(item.DataType)"
                            [editMode]="true"
                            [disable]="(item.RequiredModuleId && !item.hasModule) || IsReadOnly"
                            [option]="numericTextBoxOption"
                            [customModelOptions]="{ debounce: 1500 }"
                            [centerText]="true"
                            [(value)]="item.Value"
                            (valueChange)="companyPreferenceService.textControlChanges(item)"
                            (keyup)="companyPreferenceService.textControlKeyUp($event, item)"
                            (blur)="companyPreferenceService.textControlBlur(item)"
                          >
                          </app-numeric-edit>

                          <app-text-edit
                            *ngIf="companyPreferenceService.isDataTypeTextBox(item.DataType)"
                            [(value)]="item.Value"
                            [disabled]="(item.RequiredModuleId && !item.hasModule) || IsReadOnly"
                            inputClass="center"
                            [editMode]="true"
                            (valueChange)="companyPreferenceService.textControlChanges(item)"
                            (keyUp)="companyPreferenceService.textControlKeyUp($event, item)"
                            (blur)="companyPreferenceService.textControlBlur(item)"
                          ></app-text-edit>

                          <app-combobox-edit
                            *ngIf="companyPreferenceService.isDataTypeCombobox(item.DataType)"
                            [editMode]="true"
                            [textField]="item.DisplayText"
                            [idField]="item.UnderlyingId"
                            [disable]="(item.RequiredModuleId && !item.hasModule) || IsReadOnly"
                            [comboboxDataSource]="item.DataSource"
                            [(value)]="item.Value"
                            (valueChange)="companyPreferenceService.submitValueChange($event, item)"
                          >
                          </app-combobox-edit>

                          <app-percentage-edit
                            *ngIf="companyPreferenceService.isDataTypePercent(item.DataType)"
                            [editMode]="true"
                            [disable]="(item.RequiredModuleId && !item.hasModule) || IsReadOnly"
                            [(value)]="item.Value"
                            [allowDecimal]="true"
                            [decimals]="2"
                            (valueChange)="companyPreferenceService.textControlChanges(item)"
                            (keyUp)="companyPreferenceService.textControlKeyUp($event, item)"
                            (blur)="companyPreferenceService.textControlBlur(item)"
                          ></app-percentage-edit>
                        </app-module-check>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </kendo-panelbar-item>
        </kendo-panelbar>
      </div>
    </div>
  </div>

  <div
    class="Card Card--large"
    *ngIf="!companyPreferenceService.isCompanySetting && !companyPreferenceService.isMobile"
  >
    <div class="Card-inner">
      <h2 class="Card-title">{{ 'Preference.UserOption' | translate }}</h2>
      <div class="FormElement">
        <div class="PreferencesControls FormElement-grid k-grid k-widget">
          <table class="Preferences-list">
            <thead>
              <tr>
                <th>{{ 'Preference.Name' | translate }}</th>
                <th>{{ 'Preference.Description' | translate }}</th>
                <th>{{ 'Preference.Value' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of companyPreferenceService.userPreferences | async">
                <td>{{ item.Name }}</td>
                <td>
                  {{ item.Description }}
                  <span
                    *ngIf="item.HelpUrl && !companyPreferenceService.isIOSMobileApp"
                    class="textWithLink"
                    (click)="onReadMoreClick(item.HelpUrl)"
                    >{{ 'Help.ReadMore' | translate }}</span
                  >
                  <span *ngIf="item.HelpUrl && !companyPreferenceService.isIOSMobileApp" class="Grid-addon">
                    <app-icon [icon]="'Question'" [tooltip]="'Price.ReadMore' | translate" [link]="item.HelpUrl">
                    </app-icon>
                  </span>
                </td>
                <td>
                  <app-check-edit
                    *ngIf="companyPreferenceService.isDataTypeBoolean(item.DataType)"
                    [(value)]="item.Value"
                    customClass="checkBox-center"
                    [editMode]="true"
                    (valueChange)="companyPreferenceService.submitValueChange($event, item)"
                  >
                  </app-check-edit>

                  <app-numeric-edit
                    *ngIf="companyPreferenceService.isDataTypeNumber(item.DataType)"
                    [editMode]="true"
                    [option]="numericTextBoxOption"
                    [customModelOptions]="{ debounce: 1500 }"
                    [centerText]="true"
                    [(value)]="item.Value"
                    (valueChange)="companyPreferenceService.textControlChanges(item)"
                    (keyup)="companyPreferenceService.textControlKeyUp($event, item)"
                    (blur)="companyPreferenceService.textControlBlur(item)"
                  ></app-numeric-edit>

                  <app-text-edit
                    *ngIf="companyPreferenceService.isDataTypeTextBox(item.DataType)"
                    [(value)]="item.Value"
                    inputClass="center"
                    [editMode]="true"
                    (valueChange)="companyPreferenceService.textControlChanges(item)"
                    (keyUp)="companyPreferenceService.textControlKeyUp($event, item)"
                    (blur)="companyPreferenceService.textControlBlur(item)"
                  ></app-text-edit>

                  <app-combobox-edit
                    *ngIf="companyPreferenceService.isDataTypeCombobox(item.DataType)"
                    [editMode]="true"
                    [textField]="item.DisplayText"
                    [idField]="item.UnderlyingId"
                    [comboboxDataSource]="item.DataSource"
                    [(value)]="item.Value"
                    (valueChange)="companyPreferenceService.submitValueChange($event, item)"
                  ></app-combobox-edit>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <app-preference-mobile *ngIf="companyPreferenceService.isMobile"></app-preference-mobile>

  <div class="Actions" *ngIf="!companyPreferenceService.isMobile">
    <app-menu-button
      *ngIf="reportDialogService.isVisibleReportIcon"
      [menuCondition]="true"
      (menuClick)="reportDialogService.onShowReportsEventClick()"
      [menuIcon]="'File'"
      [menuLabel]="'Employee.Reports'"
      [menuTooltip]="'Employee.Reports'"
      [actionButtonMenu]="true"
      [menuButtonClass]="'Action-button Action-buttonReports'"
      [menuButtonId]="'ActionButtonReports'"
    >
    </app-menu-button>
  </div>
  <div class="Actions" *ngIf="companyPreferenceService.isMobile">
    <app-menu-button
      *ngIf="reportDialogService.isVisibleReportIcon"
      [menuIcon]="'ThreeDots'"
      [menuLabel]="'More.Title'"
      [menuTooltip]="'More.Title'"
      [multiMenuItems]="true"
    >
    </app-menu-button>
  </div>
</div>
