import { ExtendSalaryPeriod } from '../../model/extend-salary-period';
import { SessionService } from '../../services/session/session.service';
import { IExtendUserEmploymentView } from './extend-user-employment-view';

export class CompanySalaryBatchesChildFormEntityContext {
  public IsDirty = false;
  public BatchId: number;
  public StatusName: string;
  public SalaryBatchNumber: number;
  public IsDraft: boolean;
  public IsPendingForApproval: boolean;
  public isFinalizing: boolean;
  public IsFinalized: boolean;
  public IsCancelled: boolean;
  public IsLocked: boolean;
  public IsPreliminary: boolean;
  public HasBatch = false;
  public CycleId: number;
  public PeriodId: number;
  public Periods: ExtendSalaryPeriod[] = [];
  public Employees: IExtendUserEmploymentView[] = [];
  public Password: string;
  public HasErrors: boolean;
  public HasWarnings: boolean;
  public EmployeeCategory: number;
  public IsCheckedAllEmployees: boolean;
  public IsRecalcBeforeFinalize: boolean;
  public PayoutAllFlex: boolean;
  public PayoutNewFlex: boolean;

  // GS-7647
  public FromDateOverride?: Date;
  public ToDateOverride?: Date;

  private changeDate: Date;
  private paymentDate: Date;
  private message: string;

  constructor(private sessionService: SessionService) {}

  public get ChangeDate(): Date {
    return this.changeDate;
  }

  public set ChangeDate(value: Date) {
    this.changeDate = value ? this.sessionService.parseDate(value.toString()) : value;
  }

  public get PaymentDate(): Date {
    return this.paymentDate;
  }

  public set PaymentDate(paymentDate: Date) {
    this.paymentDate = paymentDate;
    this.IsDirty = true;
  }

  public get Message(): string {
    return this.message;
  }

  public set Message(message: string) {
    this.message = message;
    this.IsDirty = true;
  }
}
