import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { NumericTextBoxOptions } from '../../custom-controls/numeric-edit/numeric-text-box-options';
import { ITaxCardType, IUserTaxInfo } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { EmployeeService } from '../../services/employee.service';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-view-tax-card-history-dialog',
  templateUrl: './view-tax-card-history-dialog.component.html'
})
export class ViewTaxCardHistoryDialogComponent implements OnInit, OnDestroy {
  @Output() public taxCardChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public addNewTaxInfo: EventEmitter<IUserTaxInfo> = new EventEmitter<IUserTaxInfo>();

  public refreshData = false;
  private gridDataBackup = '';
  public flagselect = 0;
  public defaultRecordValue: any = {};
  public get isFullAdmin(): boolean {
    return this.sessionService.role.IsFullAdmin;
  }

  public get isSalaryAdmin(): boolean {
    return this.sessionService.role.IsSalaryAdmin;
  }

  public get branding() {
    return environment.branding;
  }

  @Input()
  public get isVisible(): boolean {
    return this.visible;
  }

  public set isVisible(value: boolean) {
    if (this.visible !== value) {
      if (value) {
        this.getTaxCardHistory();
      } else {
        this.resetDialog();
      }
      this.visible = value;
      this.isVisibleChange.emit(value);
    }
  }

  private resetDialog() {
    if (this.gridDataBackup !== JSON.stringify(this.gridData)) {
      this.updateTaxCard();
    }
    this.taxCardHistory = [];
    this.gridData = filterBy(this.taxCardHistory, this.filter);
    this.flagselect = 0;
    this.selectedItem = null;

    this.refreshData = true;
  }

  @Input() public userEmploymentId: number;

  public taxCardTypes: ITaxCardType[];
  public NumericOptions: NumericTextBoxOptions = { format: 'n2', decimals: 2, step: 0, min: 0, spinners: false };
  // IUserTaxInfo
  public taxCardHistory: any[] = [];
  public isShowConfiumAfterCreateDanishTaxCard = false;
  public selectedItem: any;

  private visible = false;

  public get ViewTaxCardHistoryLablel(): string {
    if (this.isSwedenCompany) {
      return 'EmployeeGeneral.PersonalTaxRate';
    }
    return 'EmployeeGeneral.ViewTaxCardHistory';
  }

  constructor(
    private dataService: DataService,
    public sessionService: SessionService,
    private staticDataService: StaticDataService,
    private employeeService: EmployeeService
  ) {}

  public ngOnInit(): void {
    //document.addEventListener("keydown", (event: any): void => {
    //    this.onKeyDown(event);
    //});
    this.staticDataService.TaxCardType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ITaxCardType[]) => (this.taxCardTypes = data)
    );
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    //document.removeEventListener("keydown", (event: any): void => {
    //});
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  private getTaxCardHistory(): void {
    this.dataService.Employments_GetTaxCards(this.userEmploymentId).subscribe((data: IUserTaxInfo[]) => {
      if (data && data.length > 0) {
        data.forEach((value: any) => {
          value.HightlightedRow = value.IsCurrent;
          value.IsRequest = value.TaxCardRequestId ? true : false;
          value.idColumn = this.flagselect;
          value.Editable = value.IsManual;
          value.isActiveEditable = this.isDanishCompany ? true : value.IsManual;
          value.DeductionEditable = !value.IsManual || value.TaxCardTypeId !== 1 ? false : true;
          value.FreeAmountEditable = !value.IsManual || value.TaxCardTypeId !== 3 ? false : true;
          value.IsDummy = value.IsDummy ? true : false;
          value.MinimumRate = value.MinimumRate ? value.MinimumRate : null;
          value.MaximumDeductionMonth = value.MaximumDeductionMonth ? value.MaximumDeductionMonth : null;
          value.MaximumDeductionDay = value.MaximumDeductionDay ? value.MaximumDeductionDay : null;
          this.flagselect += 1;
        });
      }

      this.taxCardHistory = data;
      this.gridData = filterBy(this.taxCardHistory, this.filter);
      this.updateBackupData();
      // this.gridData.forEach((model: IUserTaxInfo) => {
      //   model.ValidFrom = new DateTimeConvert(new Date(model.ValidFrom)).Date;
      //   model.ValidUntil = new DateTimeConvert(new Date(model.ValidUntil)).Date;
      // });
    });
  }

  public get isSwedenCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
  }

  public addHandler(dataItem: any): void {
    // const defaultDataItem: any = this.createNewDataItem();
    // Object.assign(dataItem, defaultDataItem);
    this.saveChanges(dataItem);
  }

  public onUpdateDefaultRecord(): void {
    this.defaultRecordValue = this.createNewDataItem();
  }

  public removeHandler(dataItem: any): void {
    // To remove item from history.
  }

  public get isDanishCompany(): boolean {
    return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.DENMARK_COUNTRY_ID;
  }

  public newTaxInfo: IUserTaxInfo;
  public saveChanges(args: CellCloseEvent): void {
    this.flagselect += 1;
    const dataItem: any = args.dataItem ? args.dataItem : args;
    if (!this.taxCardTypes.filter((e: ITaxCardType) => e.Id === dataItem.TaxCardTypeId)) {
      return;
    }

    const userTaxInfo: IUserTaxInfo = this.createUserTaxInfo(dataItem);
    if (dataItem.Id) {
      this.updateTaxCard(dataItem, userTaxInfo);
    } else {
      this.dataService.Employments_CreateTaxInfo(userTaxInfo).subscribe(
        (taxInfo: IUserTaxInfo) => {
          this.newTaxInfo = taxInfo;
          dataItem.Id = taxInfo.Id;
          dataItem.Editable = taxInfo.IsManual;
          dataItem.DeductionEditable = !taxInfo.IsManual || taxInfo.TaxCardTypeId !== 1 ? false : true;
          dataItem.FreeAmountEditable = !taxInfo.IsManual || taxInfo.TaxCardTypeId !== 3 ? false : true;
          dataItem.IsDummy = taxInfo.IsDummy ? true : false;
          dataItem.MinimumRate = taxInfo.MinimumRate ? taxInfo.MinimumRate : null;
          dataItem.MaximumDeductionMonth = taxInfo.MaximumDeductionMonth ? taxInfo.MaximumDeductionMonth : null;
          dataItem.MaximumDeductionDay = taxInfo.MaximumDeductionDay ? taxInfo.MaximumDeductionDay : null;
          this.taxCardChange.emit();
          this.refreshData = true;

          if (this.isDanishCompany) {
            this.isShowConfiumAfterCreateDanishTaxCard = true;
          }
        },
        () => {
          this.getTaxCardHistory();
        }
      );
    }
  }

  public updateTaxCard(dataItem?: any, userTaxInfo?: IUserTaxInfo) {
    if (!dataItem) {
      dataItem = this.selectedItem;
    }

    if (!userTaxInfo) {
      userTaxInfo = this.createUserTaxInfo(dataItem);
    }

    if (dataItem.Id) {
      this.dataService.Employments_UpdateTaxInfo(userTaxInfo).subscribe(
        (taxInfo: IUserTaxInfo) => {
          dataItem.Editable = taxInfo.IsManual;
          dataItem.DeductionEditable = !taxInfo.IsManual || taxInfo.TaxCardTypeId !== 1 ? false : true;
          dataItem.FreeAmountEditable = !taxInfo.IsManual || taxInfo.TaxCardTypeId !== 3 ? false : true;
          dataItem.IsDummy = taxInfo.IsDummy ? true : false;
          dataItem.MinimumRate = taxInfo.MinimumRate ? taxInfo.MinimumRate : null;
          dataItem.MaximumDeductionMonth = taxInfo.MaximumDeductionMonth ? taxInfo.MaximumDeductionMonth : null;
          dataItem.MaximumDeductionDay = taxInfo.MaximumDeductionDay ? taxInfo.MaximumDeductionDay : null;
          Object.assign(dataItem, taxInfo);
          this.refreshData = true;

          setTimeout(() => {
            this.updateBackupData();
          });

          this.taxCardChange.emit();
        },
        () => {
          this.getTaxCardHistory();
        }
      );
    }
  }

  public ConfiumAfterCreateDanishTaxCard(action: string) {
    if (action === 'Yes') {
      this.employeeService.makeRequestTaxCard.emit(true);
    }
  }

  public onTaxCardHistoryAction(action: string): void {
    if (action === 'Ok') {
      this.addNewTaxInfo.emit(this.newTaxInfo);
    }
  }

  private createNewDataItem(): any {
    this.flagselect += 1;
    return {
      Id: undefined,
      TaxCardTypeId: this.taxCardTypes[0] ? this.taxCardTypes[0].Id : undefined,
      IsCurrent: undefined,
      DeductionDay: 0,
      DeductionWeek: 0,
      DeductionMonth: 0,
      FreeAmount: 0,
      ValidFrom: this.staticDataService.getCurrentdate(false),
      ValidUntil: this.staticDataService.getCurrentdate(true),
      TaxRate: 0,
      IsActive: true,
      MinimumRate: undefined,
      MaximumDeductionMonth: undefined,
      MaximumDeductionDay: undefined,
      Created: this.staticDataService.getCurrentdate(),
      IsDummy: undefined,
      IsManual: true,
      Editable: true,
      idColumn: this.flagselect,
      DeductionEditable: true,
      FreeAmountEditable: true,
      isActiveEditable: true
    };
  }

  private createUserTaxInfo(dataItem: any): IUserTaxInfo {
    const userTaxCardInfo: IUserTaxInfo = {
      Created: dataItem.Created instanceof Date ? dataItem.Created : this.sessionService.parseDate(dataItem.Created),
      DeductionDay: dataItem.DeductionDay,
      DeductionMonth: dataItem.DeductionMonth,
      DeductionWeek: dataItem.DeductionWeek,
      FreeAmount: dataItem.FreeAmount,
      Id: dataItem.Id,
      IsActive: dataItem.IsActive,
      IsCurrent: dataItem.IsCurrent,
      IsDummy: dataItem.IsDummy,
      MaximumDeductionDay: dataItem.MaximumDeductionDay,
      MaximumDeductionMonth: dataItem.MaximumDeductionMonth,
      MinimumRate: dataItem.MinimumRate,
      TaxCardTypeId: dataItem.TaxCardTypeId,
      TaxRate: dataItem.TaxRate,
      UserEmploymentId: this.userEmploymentId,
      ValidFrom: dataItem.ValidFrom,
      ValidUntil: dataItem.ValidUntil,
      IsManual: dataItem.IsManual,
      ParsedFromFileName: dataItem.ParsedFromFileName || null,
      TaxCardRequestId: dataItem.TaxCardRequestId || null
    };

    return userTaxCardInfo;
  }

  public filter: CompositeFilterDescriptor;
  public gridData: any[] = this.taxCardHistory;
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridData = filterBy(this.taxCardHistory, filter);
  }

  private updateBackupData() {
    const backup: string = JSON.stringify(this.gridData);
    this.gridDataBackup = backup;
  }
}
