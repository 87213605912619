import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Browser } from 'src/app/common/browser';
import { ImportTypesEnum } from 'src/app/model/enum';
import { EmployeeService } from 'src/app/services/employee.service';
import { environment } from 'src/environments/environment';
import { Constants } from '../../common/constants';
import { FormComponentBase } from '../../common/form-component-base';
import { ITaxCardModel } from '../../model/itax-card-model';
import { ICompanyUser, ITaxCardType, IUserEmployment, IUserTaxInfo } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import { ReportDialogService } from '../report-dialog/report-dialog.service';
import { ReportParametersDefaultValue } from '../report-dialog/report-parameters-default-value';

@Component({
  selector: 'app-employee-general',
  templateUrl: './employee-general.component.html',
  styleUrls: ['./employee-general.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeGeneralComponent extends FormComponentBase implements OnInit {
  @Input() isSelfService = false;

  @Input()
  public get companyUser(): ICompanyUser {
    return this.companyUserValue;
  }
  public set companyUser(value: ICompanyUser) {
    this.companyUserValue = value;
    this.childIsValid = Array(5).fill(true);
    this.childIsDirty = Array(5).fill(false);

    this.reportParams();
  }

  @Input()
  public get userEmployment(): IUserEmployment {
    return this.userEmploymentValue;
  }
  public set userEmployment(value: IUserEmployment) {
    if (JSON.stringify(value) !== JSON.stringify(this.userEmploymentValue)) {
      this.taxCardDataChanged = true;
    }

    this.userEmploymentValue = value;

    this.reportParams();
  }

  public get allowEdit() {
    return this.sessionService.role.IsSalaryAdminAndHigher;
  }

  public get taxCardObject(): ITaxCardModel {
    this.setTaxCardData(this.userEmployment);
    return this.taxCardData;
  }

  public reportParams(): void {
    const params = new ReportParametersDefaultValue();
    params.employeeId = this.companyUser ? this.companyUser.Id : undefined;
    params.employmentId = this.userEmployment ? this.userEmployment.Id : undefined;
    this.reportDialogService.reportParams = params;
  }

  public get isMobile() {
    const browser: Browser = new Browser();
    return browser.isMobile;
  }

  public get branding() {
    return environment.branding;
  }

  public resendWelcomeEmail = false;
  public resetPassword = false;
  public resendEmailSuccessVisible: boolean;
  public resetPasswordSuccessVisible: boolean;
  public createNewEmployeeDialogVisible = false;
  public reportDialogVisible = false;
  public taxCardData: ITaxCardModel;
  public requestTaxcardDialogVisible = false;
  public taxcardHistoryDialogVisible = false;
  public requestTaxcardWarningDialog = false;
  public triggerRefreshTaxCard = false;
  public deleteEmployeeDialogVisible = false;
  public listClassExcluded = ['EmployeeInfo', 'Footer'];

  private companyUserValue: ICompanyUser;
  private userEmploymentValue: IUserEmployment;
  private taxCardDataChanged = false;
  private taxCardTypes: ITaxCardType[];
  private saveTaxInfo: IUserTaxInfo;
  private isLoadTaxCardType = false;

  constructor(
    public employeeService: EmployeeService,
    private dataService: DataService,
    private settingService: SettingService,
    public sessionService: SessionService,
    private staticDataService: StaticDataService,
    public reportDialogService: ReportDialogService
  ) {
    super();
    this.childIsValid = Array(5).fill(true);
    this.childIsDirty = Array(5).fill(false);
  }

  public ngOnInit(): void {
    if (this.IsActive) {
      this.settingService.showModuleMessage('Employee.General');
    }
  }

  public importEmployeesClick() {
    this.settingService.navigationParameters['dataImportSelectedOption'] = ImportTypesEnum.Employees;
    this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
  }

  private get IsActive(): boolean {
    return (
      this.sessionService.currentState === 'tabs.employee.general' ||
      this.sessionService.currentState === 'tabs.selfservice.general'
    );
  }

  private setTaxCardData(value: IUserEmployment, newtaxInfo?: IUserTaxInfo): void {
    if (this.taxCardDataChanged || newtaxInfo) {
      this.taxCardDataChanged = false;

      let isSpecialTaxBrief: boolean;
      if (value && (value.IncomeTypeId === 0 || value.IncomeTypeId === Constants.MAGIC_NUMBER)) {
        isSpecialTaxBrief = false;
      } else {
        isSpecialTaxBrief = true;
      }

      if (this.taxCardTypes && this.taxCardTypes.length > 0 && this.isLoadTaxCardType) {
        // Only create a new instance of taxcard if there is changes
        this.taxCardData = {
          taxInfo: newtaxInfo ? newtaxInfo : this.saveTaxInfo ? this.saveTaxInfo : undefined,
          taxCardRequestTime: value ? value.TaxCardRequested : undefined,
          taxCardTypes: this.taxCardTypes,
          userEmploymentId: value ? value.Id : undefined,
          specialTaxBrief: isSpecialTaxBrief
        };
        this.taxCardDataChanged = false;
      } else {
        this.staticDataService.TaxCardType.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ITaxCardType[]) => {
          this.taxCardTypes = data;
          // Only create a new instance of taxcard if there is changes
          this.taxCardData = {
            taxInfo: undefined,
            taxCardRequestTime: value ? value.TaxCardRequested : undefined,
            taxCardTypes: this.taxCardTypes,
            userEmploymentId: value ? value.Id : undefined,
            specialTaxBrief: isSpecialTaxBrief
          };
          this.taxCardDataChanged = false;
          this.isLoadTaxCardType = true;
        });
      }
    }
  }

  public onresendEmployeeWelcomEmailConfirmCompleted(action: string): void {
    if (action === 'Proceed') {
      this.dataService.CompanyUsers_ResendWelcomeEmail(this.companyUser.Id).subscribe(() => {
        this.resendEmailSuccessVisible = true;
      });
    }
  }

  public onResetEmployeePasswordConfirmCompleted(action: string): void {
    if (action === 'Proceed') {
      this.dataService.CompanyUsers_ResetUserPassword(this.companyUser.Id).subscribe((data: any): any => {
        this.resetPasswordSuccessVisible = true;
      });
    }
  }

  public onReloadTaxCard(): void {
    this.triggerRefreshTaxCard = !this.triggerRefreshTaxCard;
    // this.employeeService.selectEmployee(this.companyUser.Id, true);
  }

  public onRequestTaxcardWarningDialogAction(action: string): void {
    if (action === 'AlreadyRequestedTaxCardConfirmButton') {
      this.showRequestTaxcardDialog();
    }
  }

  public showRequestTaxcardDialog(): void {
    this.requestTaxcardDialogVisible = true;
  }

  public showViewTaxCardHistory(value: IUserTaxInfo): void {
    this.saveTaxInfo = value;
    this.taxcardHistoryDialogVisible = true;
  }

  public showRequestTaxcardConfirm(value: IUserTaxInfo): void {
    this.saveTaxInfo = value;
    this.requestTaxcardWarningDialog = true;
  }

  public onCreateTaxInfo(value: IUserTaxInfo): void {
    if (value) {
      this.setTaxCardData(this.userEmployment, value);
    }
  }

  public expanded = false;
  public onExpand(event: any): void {
    this.expanded = event;
  }
}
