import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../common/form-component-base';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-share-amount',
  templateUrl: './share-amount.component.html'
})
export class ShareAmountComponent extends FormComponentBase implements OnInit, OnDestroy {
  @Input() public disable: boolean;
  @Input() public label: string;
  @Input() public tooltip: string;

  private currentfirstValue: number;
  @Input()
  get firstValue(): number {
    return this.currentfirstValue;
  }
  set firstValue(value: number) {
    if (this.currentfirstValue !== value) {
      this.currentfirstValue = value;
      this.firstValueChange.emit(this.currentfirstValue);
    }
  }
  @Output() public firstValueChange: EventEmitter<number> = new EventEmitter<number>();

  public PensionIsFixedAmountPerWorkDayDataSource: Array<{ Name: string; value: number }> = [];
  public setTranslationTerms(translations: { [key: string]: string }): void {
    const PensionIsFixedAmountPerWorkDayFalse = translations['EmployeeEmployment.PensionIsFixedAmountPerWorkDayFalse'];
    const PensionIsFixedAmountPerWorkDayTrue = translations['EmployeeEmployment.PensionIsFixedAmountPerWorkDayTrue'];

    if (this.PensionIsFixedAmountPerWorkDayDataSource.length < 2) {
      this.PensionIsFixedAmountPerWorkDayDataSource.push({ Name: PensionIsFixedAmountPerWorkDayFalse, value: 0 });
      this.PensionIsFixedAmountPerWorkDayDataSource.push({ Name: PensionIsFixedAmountPerWorkDayTrue, value: 1 });
    }
  }

  private currentsecondValue: number;
  @Input()
  get secondValue(): number {
    return this.currentsecondValue;
  }
  set secondValue(value: number) {
    if (this.currentsecondValue !== value) {
      this.currentsecondValue = value;
      this.secondValueChange.emit(this.currentsecondValue);
    }
  }
  @Output() public secondValueChange: EventEmitter<number> = new EventEmitter<number>();

  private currentthirdValue: boolean;
  @Input()
  get thirdValue(): boolean {
    return this.currentthirdValue;
  }
  set thirdValue(value: boolean) {
    if (this.currentthirdValue !== value) {
      this.currentthirdValue = value;
      this.currentcomboboxthirdValue = value ? 1 : 0;
      this.thirdValueChange.emit(this.currentthirdValue);
    }
  }
  @Output() public thirdValueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private currentcomboboxthirdValue: number;
  public get comboboxthirdValue(): number {
    return this.currentcomboboxthirdValue;
  }

  public set comboboxthirdValue(value: number) {
    if (value !== this.currentcomboboxthirdValue) {
      this.currentthirdValue = value === 1 ? true : false;
      this.currentcomboboxthirdValue = value;
      this.thirdValueChange.emit(this.currentthirdValue);
    }
  }

  public numericTextBoxOption: any = { format: 'n2', decimals: 2, min: null, step: 1, spinners: false };
  private translationServiceTerms: string[];

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit() {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateService.get(this.translationServiceTerms).subscribe((translations: { [key: string]: string }) => {
        this.setTranslationTerms(translations);
      });
    });
  }

  constructor(private sessionService: SessionService, private translateService: TranslateService) {
    super();
    this.childIsDirty = Array(2).fill(false);

    this.translationServiceTerms = [
      'EmployeeEmployment.PensionIsFixedAmountPerWorkDayFalse',
      'EmployeeEmployment.PensionIsFixedAmountPerWorkDayTrue'
    ];
  }
}
