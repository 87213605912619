import { Injectable } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { Department, IDepartment } from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { CompanyService } from '../../../services/company.service';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyDepartmentService extends CompanyService {
  constructor(
    protected dataService: DataService,
    protected staticDataService: StaticDataService,
    protected settingService: SettingService,
    protected sessionService: SessionService,
    protected transitionService: TransitionService,
    protected broadcaster: BroadcastService
  ) {
    super(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster);
  }

  public gridFlag = 0;

  private departmentSubject: BehaviorSubject<IDepartment[]>;
  public get departments(): Observable<IDepartment[]> {
    if (!this.departmentSubject) {
      this.departmentSubject = new BehaviorSubject<IDepartment[]>([]);
    }
    return this.departmentSubject.asObservable();
  }

  public loadDepartment() {
    if (!this.departmentSubject) {
      this.departmentSubject = new BehaviorSubject<IDepartment[]>([]);
    }
    this.staticDataService.loadDepartmentsAsync().subscribe((departments: IDepartment[]): void => {
      this.gridFlag = 1;
      departments = departments.map((dep: any) => {
        dep.GridId = this.gridFlag++;
        return dep;
      });

      departments = this.showInactiveDepartments ? departments : departments.filter((d: IDepartment) => d.IsActive);

      this.departmentSubject.next(departments);
    });
  }

  public updateDepartment(model: Department) {
    return this.dataService.Companies_UpdateDepartment(model).pipe(
      tap(() => {
        // this.loadDepartment();
        this.staticDataService.loadDepartments();
      })
    );
  }

  public createDepartment(model: Department) {
    return this.dataService.Companies_CreateDepartment(model).pipe(
      tap(() => {
        // this.loadDepartment();
        this.staticDataService.loadDepartments();
      })
    );
  }

  public deleteDepartment(Id: number) {
    return this.dataService.Companies_DeleteDepartment(Id).pipe(
      tap(() => {
        this.loadDepartment();
      })
    );
  }

  private showInactiveDepartmentsValue = false;
  public get showInactiveDepartments(): boolean {
    return this.showInactiveDepartmentsValue;
  }
  public set showInactiveDepartments(value: boolean) {
    if (value !== this.showInactiveDepartmentsValue) {
      this.showInactiveDepartmentsValue = value;
      this.loadDepartment();
    }
  }

  protected get allowShortcut(): boolean {
    return this.sessionService.currentState === 'tabs.company.configuration.departments';
  }
}
