<app-dialog
  width="794"
  [overlayClose]="false"
  [(visible)]="visible"
  (action)="closeForm($event)"
  [title]="'Accountant.AddNewUser' | translate"
  class="Form--newEmployeeModal ModalDialogInfo"
  [customVisible]="true"
>
  <div class="Modal-content" *ngIf="visible">
    <!-- first midle last name -->
    <div class="FormElement">
      <div class="FormElement-legend FormElement-label name">
        <span> <abbr class="FormElement-required" title="required">*</abbr>{{ 'NewEmployee.Name' | translate }} </span>
      </div>
      <div class="FormElement-row">
        <div class="FormElement ">
          <label class="FormElement-label u-hideMobile" for="firstname">
            {{ ('NewEmployee.FirstName' | translate) + ': ' }}
          </label>
          <app-text-edit
            id="firstname"
            [editMode]="true"
            placeholder="{{ 'NewEmployee.FirstName' | translate }}"
            [(value)]="newUserRequest.FirstName"
            [required]="requiredFirstName"
          ></app-text-edit>
        </div>
        <div class="FormElement">
          <label class="FormElement-label u-hideMobile" for="middlename">
            {{ ('NewEmployee.MiddleName' | translate) + ': ' }}
          </label>
          <app-text-edit
            id="middlename"
            [editMode]="true"
            placeholder="{{ 'NewEmployee.MiddleName' | translate }}"
            [(value)]="newUserRequest.MiddleName"
          ></app-text-edit>
        </div>
        <div class="FormElement ">
          <label class="FormElement-label u-hideMobile" for="lastname">
            {{ ('NewEmployee.LastName' | translate) + ': ' }}
          </label>
          <app-text-edit
            id="lastname"
            [editMode]="true"
            placeholder="{{ 'NewEmployee.LastName' | translate }}"
            [(value)]="newUserRequest.LastName"
          ></app-text-edit>
        </div>
      </div>
    </div>

    <!-- cpr -->
    <div class="FormElement cpr">
      <div class="FormElement-row">
        <div class="FormElement-control NewEmployeeEmail">
          <app-text-edit
            [label]="'NewEmployee.CPRno' | translate"
            [editMode]="true"
            style="width: 100%"
            [disabled]="noCPRNumber"
            [isShowSymbolRequired]="!noCPRNumber"
            [(value)]="newUserRequest.IdentityNumber"
            [required]="requiredIdentityNumber"
          ></app-text-edit>
        </div>

        <div class="FormElement-control ">
          <label class="FormElement-checkbox">
            <input type="checkbox" [(ngModel)]="noCPRNumber" (ngModelChange)="onNoCPRCheckboxClicked()" />
            <span class="FormElement-checkboxFaux"></span>
            <span class="FormElement-label">{{ 'NewEmployee.NoCPRnumber' | translate }}</span>
          </label>
        </div>
      </div>
    </div>

    <!-- email -->
    <div class="FormElement">
      <div class="FormElement-row">
        <div class="FormElement-control">
          <app-text-edit
            [label]="'NewEmployee.Email' | translate"
            [editMode]="true"
            [type]="'email'"
            style="width: 100%"
            [(value)]="newUserRequest.Email"
          ></app-text-edit>
        </div>
      </div>
    </div>

    <!-- account role -->
    <div class="FormElement">
      <div class="FormElement-row">
        <div class="FormElement-control">
          <app-combobox-edit
            [label]="'Accountant.Organization' | translate"
            [editMode]="true"
            [required]="true"
            [comboboxDataSource]="accountRoleDataSource"
            style="width: 100%"
            [textField]="'Name'"
            [idField]="'Id'"
            [(value)]="newUserRequest.AccountRoleId"
          ></app-combobox-edit>
        </div>
      </div>
    </div>

    <!-- language -->
    <app-module-check [moduleId]="5" [(hasModule)]="hasLanguageModule">
      <div class="FormElement">
        <div class="FormElement-control">
          <app-combobox-edit
            style="width: 100%"
            [disable]="!hasLanguageModule"
            [label]="'NewEmployee.Language' | translate"
            [editMode]="true"
            [comboboxDataSource]="staticDataService.languages | async"
            [textField]="'Name'"
            [idField]="'Id'"
            [(value)]="newUserRequest.LanguageId"
          ></app-combobox-edit>
        </div>
      </div>
    </app-module-check>

    <!-- address -->
    <div>
      <div>
        <div class="FormElement-control">
          <app-text-edit
            [label]="'NewEmployee.Address' | translate"
            [editMode]="true"
            style="width: 100%"
            [(value)]="newUserRequest.Address.Line1"
          ></app-text-edit>
        </div>
      </div>

      <div>
        <div class="FormElement-row">
          <div class="FormElement-control NewEmployeeEmail">
            <app-text-edit
              [label]="'NewEmployee.PostalCode' | translate"
              [editMode]="true"
              style="width: 100%"
              (blur)="onPostalCodeChanged()"
              [(value)]="newUserRequest.Address.PostalCode"
            ></app-text-edit>
          </div>

          <div class="FormElement-control NewEmployeeEmailPrivate">
            <app-text-edit
              [label]="'NewEmployee.City' | translate"
              [editMode]="true"
              style="width: 100%"
              [(value)]="newUserRequest.Address.City"
            ></app-text-edit>
          </div>
        </div>
      </div>

      <div class="FormElement">
        <div class="FormElement-row">
          <div class="FormElement-control">
            <app-combobox-edit
              style="width: 100%"
              [label]="'NewEmployee.Country' | translate"
              [editMode]="true"
              [comboboxDataSource]="staticDataService.Country | async"
              [textField]="'Name'"
              [idField]="'Id'"
              [(value)]="newUserRequest.Address.CountryId"
            ></app-combobox-edit>
          </div>
        </div>
      </div>
    </div>

    <!-- phone -->
    <div class="FormElement">
      <div class="FormElement-row">
        <div class="FormElement-control NewEmployeeEmail">
          <app-text-edit
            [label]="'NewEmployee.PhoneNo' | translate"
            [editMode]="true"
            [type]="'number'"
            style="width: 100%"
            [(value)]="newUserRequest.TelMobile"
          ></app-text-edit>
        </div>

        <div class="FormElement-control NewEmployeeEmailPrivate">
          <app-text-edit
            [label]="'NewEmployee.PhoneNoPrivate' | translate"
            [editMode]="true"
            [type]="'number'"
            style="width: 100%"
            [(value)]="newUserRequest.TelFixed"
          ></app-text-edit>
        </div>
      </div>
    </div>

    <!-- bank no, bank account -->
    <!-- <div class="FormElement">
      <div class="FormElement-row">
        <div class="FormElement-control NewEmployeeEmail">
          <app-text-edit
            [label]="'NewEmployee.RegisterNo' | translate"
            [editMode]="true"
            style="width: 100%"
            [(value)]="newUserRequest.BankRegNo"
          ></app-text-edit>
        </div>

        <div class="FormElement-control NewEmployeeEmailPrivate">
          <app-text-edit
            [label]="'NewEmployee.BankAccountNo' | translate"
            [editMode]="true"
            style="width: 100%"
            [(value)]="newUserRequest.BankAccountNo"
          ></app-text-edit>
        </div>
      </div>
    </div> -->

    <!-- BicSwiftNo. IbanNo -->
    <div class="FormElement">
      <div class="FormElement-row">
        <div class="FormElement-control NewEmployeeEmail">
          <app-text-edit
            [label]="'NewEmployee.BIC' | translate"
            [editMode]="true"
            style="width: 100%"
            [(value)]="newUserRequest.BicSwiftNo"
          ></app-text-edit>
        </div>

        <div class="FormElement-control NewEmployeeEmailPrivate">
          <app-text-edit
            [label]="'NewEmployee.IBAN' | translate"
            [editMode]="true"
            style="width: 100%"
            [(value)]="newUserRequest.IbanNo"
          ></app-text-edit>
        </div>
      </div>
    </div>
  </div>
  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action
    *ngIf="createNonEmail && !newUserRequest.Email"
    [disabled]="sessionService.isSubmitting"
    [type]="'CreateWithoutEmails'"
  ></app-dialog-action>
  <app-dialog-action
    *ngIf="!createNonEmail || newUserRequest.Email"
    [disabled]="sessionService.isSubmitting"
    [type]="'Create'"
  ></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="validationErrorDialogVisible"
  [width]="'400'"
  [leadingText]="validationMessageKey | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="noCPRWarningDialogVisible"
  [width]="'400'"
  [leadingText]="'NewEmployee.noCPRWarning' | translate: { SystemAlias: branding.SystemAlias }"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="noEmailWarningDialogVisible"
  (action)="createWithoutEmail = true"
  [width]="'400'"
  [leadingText]="'NewEmployee.EmailWarning' | translate: { SystemAlias: branding.SystemAlias }"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
