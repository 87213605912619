import { OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from 'src/app/common/global';
import { ReportDialogService } from 'src/app/shared-components/report-dialog/report-dialog.service';
import { TimeEntryType } from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';
import { CompanyAdvancedTypesService } from './company-advanced-types.service';
import { SalaryTypesSingleComponent } from './salary-types-single/salary-types-single.component';
import { SalaryTypeView } from './salary-types/salary-type-view';
import { TimeEntryTypesSingleComponent } from './time-entry-types-single/time-entry-types-single.component';
import { TimeEntryTypeView } from './time-entry-types/time-entry-type-view';
var AdvancedTypesComponent = /** @class */ (function () {
    function AdvancedTypesComponent(dataService, staticDataService, renderer, sessionService, settingService, reportDialogService, companyAdvancedTypesService) {
        this.dataService = dataService;
        this.staticDataService = staticDataService;
        this.renderer = renderer;
        this.sessionService = sessionService;
        this.settingService = settingService;
        this.reportDialogService = reportDialogService;
        this.companyAdvancedTypesService = companyAdvancedTypesService;
        this.externalReferences = [];
        this.isWattingLoading = false;
        this.hasSalaryTypeModule = false;
        this.hasTimeEntryModule = false;
        this.salaryType = {
            id: 1,
            value: 'Salary',
            label: 'CompanyTimeEntryTypes.SalaryTypeSingle'
        };
        this.salaryTypeOverview = {
            id: 3,
            value: 'SalaryOverview',
            label: 'CompanyTimeEntryTypes.SalaryTypeOverview'
        };
        this.timeEntryType = {
            id: 2,
            value: 'TimeEntry',
            label: 'CompanyTimeEntryTypes.TimeEntryTypeSingle'
        };
        this.timeEntryTypeOverview = {
            id: 4,
            value: 'TimeEntryOverview',
            label: 'CompanyTimeEntryTypes.TimeEntryTypeOverview'
        };
        this.salaryTypes = [];
        this.salaryTypeViews = [];
        this.createFromScratch = new TimeEntryType();
        this.inheritFrom = 'baseSalaryName';
        this.currentModules = [];
        this.gridData = [];
        this.gridFilterData = this.gridData;
        this.selectedType = 'Salary';
        this.hasModuleSalary = false;
        this.hasModuleTimeEntry = false;
        this.ngUnsubscribe = new Subject();
        this.translatetooltipsalarytype = '';
        this.salaryTypeVisible = true;
        this.timeEntryTypeVisible = false;
        this.salaryTypeOverviewVisible = false;
        this.timeEntryTypeOverviewVisible = false;
        this.newSalaryTypeDialogVisible = false;
        this.newCustomTimeEntryDialogVisible = false;
        this.defaultSelectedType = null;
        this.discardChangesDialogVisible = false;
        this.timeEntryTypeConfigurationDialogVisible = false;
        this.translationDialogSTVisible = false;
        this.translationDialogTEVisible = false;
        this.menuOpen = false;
        this.isCollapseButtonClick = false;
    }
    Object.defineProperty(AdvancedTypesComponent.prototype, "selectedTypeValue", {
        get: function () {
            return this.selectedType;
        },
        set: function (value) {
            if (value !== this.selectedType) {
                this.selectedType = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdvancedTypesComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdvancedTypesComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdvancedTypesComponent.prototype, "companyAdvancedSalaryTypesStateActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.configuration.advancedtypes.advancedsalarytypes';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdvancedTypesComponent.prototype, "companyTimeEntryTypesStateActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.configuration.advancedtypes.timeentrytypes';
        },
        enumerable: true,
        configurable: true
    });
    AdvancedTypesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.renderer.listen(window, 'click', function (event) {
            if (!_this.isCollapseButtonClick) {
                _this.menuOpen = false;
            }
            else {
                _this.isCollapseButtonClick = false;
            }
        });
        this.settingService.showModuleMessage('company.advancedsalarytypes');
        if (this.isMobile) {
            this.sessionService.isShowHugeFeaturesAlert = true;
        }
        this.dataService.SalaryTypes_GetAllSalaryTypeCategories().subscribe(function (data) {
            _this.SalaryTypeCategories = data;
        });
        this.createFromScratch.Name = '<Create From Scratch>';
        this.createFromScratch.Id = -1;
        this.dataService.TimeEntryTypes_GetDefaultTimeEntryTypes().subscribe(function (data) {
            _this.TimeEntryTypes = data;
            _this.TimeEntryTypes.unshift(_this.createFromScratch);
        });
        this.onLoadNavigate(this.sessionService.currentState);
        this.staticDataService.moduleCompanyView
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (modules) {
            if (modules && modules.length > 0 && _this.currentModules.length === 0) {
                _this.currentModules = modules;
                _this.checkModule();
            }
        });
        setTimeout(function () {
            _this.reloadGridData();
        });
    };
    AdvancedTypesComponent.prototype.checkModule = function () {
        var moduleSalary = this.currentModules && this.currentModules.length
            ? this.currentModules.find(function (m) { return m.ModuleId === 4; })
            : undefined;
        if (moduleSalary) {
            this.hasModuleSalary = moduleSalary.IsEnabled;
        }
        else {
            this.hasModuleSalary = false;
        }
        var moduleTimeEntry = this.currentModules && this.currentModules.length
            ? this.currentModules.find(function (m) { return m.ModuleId === 8; })
            : undefined;
        if (moduleTimeEntry) {
            this.hasModuleTimeEntry = moduleTimeEntry.IsEnabled;
        }
        else {
            this.hasModuleTimeEntry = false;
        }
    };
    AdvancedTypesComponent.prototype.ngOnDestroy = function () {
        this.menuOpen = false;
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(AdvancedTypesComponent.prototype, "isEditable", {
        get: function () {
            return (((this.selectedType === 'Salary' && this.hasModuleSalary) ||
                (this.selectedType === 'TimeEntry' && this.hasModuleTimeEntry)) &&
                !this.sessionService.role.IsReadOnly);
        },
        enumerable: true,
        configurable: true
    });
    AdvancedTypesComponent.prototype.onDoubleClick = function () {
        if (!this.isEditable) {
            return;
        }
        if (!this.sessionService.isGetting && !this.sessionService.isSubmitting) {
            this.onEditClick(true);
        }
    };
    AdvancedTypesComponent.prototype.onLoadNavigate = function (state) {
        var type = state.split('.advancedtypes.')[1];
        switch (type) {
            case 'salarytypessingle':
                this.selectedType = 'Salary';
                this.navigateTo('tabs.company.configuration.advancedtypes.salarytypessingle', 1);
                break;
            case 'advancedsalarytypes':
                this.selectedType = null;
                this.navigateTo('tabs.company.configuration.advancedtypes.advancedsalarytypes', 3);
                break;
            case 'timeentrytypessingle':
                this.selectedType = 'TimeEntry';
                this.navigateTo('tabs.company.configuration.advancedtypes.timeentrytypessingle', 2);
                break;
            case 'timeentrytypes':
                this.selectedType = null;
                this.navigateTo('tabs.company.configuration.advancedtypes.timeentrytypes', 4);
                break;
        }
    };
    AdvancedTypesComponent.prototype.loadTimeEntryTypes = function (event, isNotLoadDefault) {
        var _this = this;
        if (event) {
            this.selectedTimeEntryType.TimeEntryTypeTranslations = event;
        }
        this.currentLangId = Global.SESSION.SignOnToken.LanguageId;
        this.gridData = [];
        this.gridFilterData = [];
        if (this.companyAdvancedTypesService.isLanguageModuleEnable) {
            this.dataService.TimeEntryTypes_GetRawTimeEntryTypesTranslated().subscribe(function (timeEntryTypes) {
                _this.createGridTimeEmtryData(timeEntryTypes);
                if (!isNotLoadDefault) {
                    _this.setDefaultType();
                }
                else {
                    _this.reSelectItem();
                }
            }, function () {
                _this.isWattingLoading = false;
            });
        }
        else {
            this.dataService.TimeEntryTypes_GetRawTimeEntryTypes().subscribe(function (timeEntryTypes) {
                _this.createGridTimeEmtryData(timeEntryTypes);
                if (!isNotLoadDefault) {
                    _this.setDefaultType();
                }
                else {
                    _this.reSelectItem();
                }
            }, function () {
                _this.isWattingLoading = false;
            });
        }
    };
    AdvancedTypesComponent.prototype.createGridTimeEmtryData = function (timeEntryTypes) {
        var _this = this;
        var filterTimeEntryTypes = timeEntryTypes.filter(function (type) { return !type.BaseTimeEntryType || type.BaseTimeEntryType.IsActive || type.IsActive; });
        var activeTimeEntryTypes = filterTimeEntryTypes.filter(function (type) { return type.IsActive !== false; });
        var inActiveTimeEntryType = filterTimeEntryTypes.filter(function (type) { return type.IsActive === false; });
        var sortedActiveTimeEntryTypes = activeTimeEntryTypes.sort(function (a, b) { return _this.sortCustomTimeEntryTypes(a, b); });
        var sortedInActiveTimeEntryTypes = inActiveTimeEntryType.sort(function (a, b) { return _this.sortCustomTimeEntryTypes(a, b); });
        var sortedTimeEntryTypes = sortedActiveTimeEntryTypes.concat(sortedInActiveTimeEntryTypes);
        this.gridData = sortedTimeEntryTypes.map(function (d) { return new TimeEntryTypeView(d, _this.companyAdvancedTypesService.isLanguageModuleEnable); });
        this.gridData.forEach(function (model) {
            _this.processGridItemObject(model);
        });
        this.convertExternalRefCodeToFriendlyName();
        this.gridFilterData = filterBy(this.gridData, this.filter);
        this.isWattingLoading = false;
    };
    AdvancedTypesComponent.prototype.getSalaryTypes = function (isNotLoadDefault) {
        var _this = this;
        this.gridData = [];
        this.dataService.SalaryTypes_GetRawSalaryTypesTranslated().subscribe(function (data) {
            _this.createGridSalaryData(data);
            if (!isNotLoadDefault) {
                _this.setDefaultType();
            }
            else {
                _this.reSelectItem();
            }
        }, function () {
            _this.isWattingLoading = false;
        });
    };
    AdvancedTypesComponent.prototype.setDefaultType = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.defaultSelectedType) {
                var selectedType = _this.gridData.find(function (data) {
                    return data.Id === _this.defaultSelectedType.Id;
                });
                _this.companyAdvancedTypesService.selectedType = selectedType;
                _this.companyAdvancedTypesService.selectedId = selectedType.Id;
                _this.defaultSelectedType = null;
            }
            else if (_this.gridData && _this.gridData.length > 0) {
                _this.companyAdvancedTypesService.selectedType = _this.gridData[0];
                _this.companyAdvancedTypesService.selectedId = _this.gridData[0].Id;
            }
            else {
                _this.companyAdvancedTypesService.selectedType = _this.companyAdvancedTypesService.defaultSelectedType;
            }
        });
    };
    AdvancedTypesComponent.prototype.reSelectItem = function () {
        var _this = this;
        var selectedId = this.companyAdvancedTypesService.selectedType.Id;
        this.gridData.forEach(function (item) {
            if (selectedId === item.Id) {
                _this.companyAdvancedTypesService.selectedType = item;
                return;
            }
        });
    };
    AdvancedTypesComponent.prototype.createGridSalaryData = function (data) {
        var _this = this;
        this.salaryTypes = data;
        var filterSalaryTypes = data.filter(function (type) {
            return (!type.BaseSalaryType ||
                (type.IsVisible && type.IsActive) ||
                (type.BaseSalaryType.IsVisible && type.BaseSalaryType.IsActive));
        });
        var activeSalaryTypes = filterSalaryTypes.filter(function (type) {
            return type.IsActive !== false;
        });
        var inActiveSalaryType = filterSalaryTypes.filter(function (type) {
            return type.IsActive === false;
        });
        var sortedActiveSalaryTypes = activeSalaryTypes.sort(function (a, b) {
            return _this.sortCustomSalaryTypes(a, b);
        });
        var sortedInActiveSalaryTypes = inActiveSalaryType.sort(function (a, b) {
            return _this.sortCustomSalaryTypes(a, b);
        });
        var sortedSalaryTypes = sortedActiveSalaryTypes.concat(sortedInActiveSalaryTypes);
        this.gridData = sortedSalaryTypes.map(function (salaryType) {
            _this.transformSalaryTypeObject(salaryType);
            return new SalaryTypeView(salaryType, _this.companyAdvancedTypesService.isLanguageModuleEnable);
        });
        this.gridFilterData = filterBy(this.gridData, this.filter);
        this.convertExternalRefCodeToFriendlyName();
        this.isWattingLoading = false;
    };
    AdvancedTypesComponent.prototype.sortCustomSalaryTypes = function (a, b) {
        var aOrder = a.SortOrder || a.SortOrder === 0
            ? a.SortOrder
            : a.BaseSalaryType && (a.BaseSalaryType.SortOrder || a.BaseSalaryType.SortOrder === 0)
                ? a.BaseSalaryType.SortOrder
                : undefined;
        var bOrder = b.SortOrder || b.SortOrder === 0
            ? b.SortOrder
            : b.BaseSalaryType && (b.BaseSalaryType.SortOrder || b.BaseSalaryType.SortOrder === 0)
                ? b.BaseSalaryType.SortOrder
                : undefined;
        if ((aOrder === undefined && bOrder === undefined) || (aOrder === 0 && bOrder === 0)) {
            return a.Id - b.Id;
        }
        else if (aOrder === undefined && bOrder !== undefined) {
            return -1;
        }
        else if (aOrder !== undefined && bOrder === undefined) {
            return 1;
        }
        return aOrder - bOrder;
    };
    AdvancedTypesComponent.prototype.sortCustomTimeEntryTypes = function (a, b) {
        var aOrder = a.SortOrder || a.SortOrder === 0
            ? a.SortOrder
            : a.BaseTimeEntryType && (a.BaseTimeEntryType.SortOrder || a.BaseTimeEntryType.SortOrder === 0)
                ? a.BaseTimeEntryType.SortOrder
                : undefined;
        var bOrder = b.SortOrder || b.SortOrder === 0
            ? b.SortOrder
            : b.BaseTimeEntryType && (b.BaseTimeEntryType.SortOrder || b.BaseTimeEntryType.SortOrder === 0)
                ? b.BaseTimeEntryType.SortOrder
                : undefined;
        if ((aOrder === undefined && bOrder === undefined) || (aOrder === 0 && bOrder === 0)) {
            return a.Id - b.Id;
        }
        else if (aOrder === undefined && bOrder !== undefined) {
            return -1;
        }
        else if (aOrder !== undefined && bOrder === undefined) {
            return 1;
        }
        return aOrder - bOrder;
    };
    AdvancedTypesComponent.prototype.convertExternalRefCodeToFriendlyName = function () {
        var _this = this;
        if (this.gridData && this.gridData.length > 0 && this.externalReferences && this.externalReferences.length > 0) {
            this.gridData.forEach(function (salaryType) {
                salaryType.ExternalReference = _this.getExteralReferenceCode(salaryType.ExternalReference, true);
            });
            this.gridFilterData = filterBy(this.gridData, this.filter);
        }
    };
    AdvancedTypesComponent.prototype.getExteralReferenceCode = function (refCode, fullName) {
        if (!refCode) {
            return null;
        }
        var externalRefCode = this.externalReferences
            ? this.externalReferences.find(function (item) {
                return refCode === item.AccountNumber || refCode === item.FriendlyName;
            })
            : null;
        if (externalRefCode) {
            return !fullName ? externalRefCode.AccountNumber : externalRefCode.FriendlyName;
        }
        return refCode;
    };
    AdvancedTypesComponent.prototype.transformSalaryTypeObject = function (salaryType) {
        var existingSalaryType = this.salaryTypes.find(function (s) { return s.Id === salaryType.Id; });
        salaryType.SalaryTypeTranslations = salaryType.SalaryTypeTranslations
            ? salaryType.SalaryTypeTranslations
            : existingSalaryType
                ? existingSalaryType.SalaryTypeTranslations
                : undefined;
        salaryType.BaseSalaryType = salaryType.BaseSalaryType
            ? salaryType.BaseSalaryType
            : existingSalaryType
                ? existingSalaryType.BaseSalaryType
                : undefined;
    };
    AdvancedTypesComponent.prototype.processGridItemObject = function (model) {
        var existingTimeEntryType = this.gridData && model ? this.gridData.find(function (s) { return s.Id === model.Id; }) : undefined;
        model.TimeEntryTypeTranslations = model.TimeEntryTypeTranslations
            ? model.TimeEntryTypeTranslations
            : existingTimeEntryType
                ? existingTimeEntryType.TimeEntryTypeTranslations
                : undefined;
        model.BaseTimeEntryType = model.BaseTimeEntryType
            ? model.BaseTimeEntryType
            : existingTimeEntryType
                ? existingTimeEntryType.BaseTimeEntryType
                : undefined;
        if (model.TimeEntryTypeConfigurations) {
            if (model.TimeEntryTypeConfigurations.length > 0) {
                model.customSalaryTypeClass = 'grid-disable-cell';
                model.editSalaryType = false;
                model.ToolTipSalaryType = this.translatetooltipsalarytype;
                model.SalaryTypeId = null;
            }
            else {
                model.customSalaryTypeClass = '';
                model.editSalaryType = !this.IsReadOnly;
                model.ToolTipSalaryType = '';
            }
        }
        else {
            model.customSalaryTypeClass = '';
            model.editSalaryType = !this.IsReadOnly;
            model.ToolTipSalaryType = '';
        }
    };
    AdvancedTypesComponent.prototype.onAdvancedTypeChange = function (type, isKeepSelected) {
        var _this = this;
        if (type === void 0) { type = 1; }
        if (this.companyAdvancedTypesService.editMode) {
            this.onConfirmAndDiscardChanges(type);
        }
        else {
            setTimeout(function () {
                _this.changeTab();
            });
        }
    };
    AdvancedTypesComponent.prototype.changeTab = function () {
        var currentState = this.sessionService.currentState.split('tabs.company.configuration.')[1];
        switch (currentState) {
            case 'advancedtypes':
            case 'advancedtypes.salarytypessingle':
                this.setActiveState(1);
                this.selectedTypeValue = 'Salary';
                this.inheritFrom = 'baseSalaryName';
                break;
            case 'advancedtypes.timeentrytypessingle':
                this.setActiveState(2);
                this.selectedTypeValue = 'TimeEntry';
                this.inheritFrom = 'inheritsFrom';
                break;
            case 'advancedtypes.advancedsalarytypes':
                this.setActiveState(3);
                this.selectedTypeValue = 'SalaryOverview';
                break;
            case 'advancedtypes.timeentrytypes':
                this.setActiveState(4);
                this.selectedTypeValue = 'TimeEntryOverview';
                break;
        }
        this.companyAdvancedTypesService.selectedType = this.companyAdvancedTypesService.defaultSelectedType;
        this.reloadGridData();
    };
    AdvancedTypesComponent.prototype.setActiveState = function (type) {
        this.salaryTypeVisible = false;
        this.timeEntryTypeVisible = false;
        this.salaryTypeOverviewVisible = false;
        this.timeEntryTypeOverviewVisible = false;
        switch (type) {
            case 1:
                this.salaryTypeVisible = true;
                break;
            case 2:
                this.timeEntryTypeVisible = true;
                break;
            case 3:
                this.salaryTypeOverviewVisible = true;
                break;
            case 4:
                this.timeEntryTypeOverviewVisible = true;
                break;
        }
    };
    AdvancedTypesComponent.prototype.reloadGridData = function (isNotReloadSelected) {
        var _this = this;
        if (this.selectedType === 'Salary') {
            this.isWattingLoading = true;
            this.staticDataService.moduleCompanyView.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
                _this.getSalaryTypes(isNotReloadSelected);
            });
        }
        else if (this.selectedType === 'TimeEntry') {
            this.isWattingLoading = true;
            this.dataService.SalaryTypes_GetSalaryTypeViews().subscribe(function (data) {
                _this.salaryTypeViews = data;
                _this.staticDataService.moduleCompanyView.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function () {
                    _this.loadTimeEntryTypes(null, isNotReloadSelected);
                });
            });
        }
    };
    AdvancedTypesComponent.prototype.onAddClick = function () {
        if (this.selectedType === 'Salary') {
            this.newSalaryTypeDialogVisible = true;
        }
        else {
            this.newCustomTimeEntryDialogVisible = true;
        }
    };
    AdvancedTypesComponent.prototype.onAddSalaryType = function (value) {
        this.isWattingLoading = true;
        this.salaryTypes.push(value);
        this.transformSalaryTypeObject(value);
        this.gridData.push(new SalaryTypeView(value, this.companyAdvancedTypesService.isLanguageModuleEnable));
        this.gridFilterData = filterBy(this.gridData, this.filter);
        this.defaultSelectedType = value;
        this.reloadGridData();
    };
    AdvancedTypesComponent.prototype.addNewTimeEntryType = function (timeEntryType) {
        this.isWattingLoading = true;
        var value = new TimeEntryTypeView(timeEntryType, this.companyAdvancedTypesService.isLanguageModuleEnable);
        this.processGridItemObject(value);
        this.gridData.push(value);
        this.selectedTimeEntryType = value;
        this.gridFilterData = filterBy(this.gridData, this.filter);
        this.defaultSelectedType = value;
        this.reloadGridData();
    };
    AdvancedTypesComponent.prototype.onEditClick = function ($event) {
        var _this = this;
        if ($event !== undefined && $event === false) {
            this.companyAdvancedTypesService.editMode = $event;
            if (this.companyAdvancedTypesService.listRevertValues.length > 0) {
                this.revertInheritValue();
            }
            this.companyAdvancedTypesService.isSave = true;
            this.companyAdvancedTypesService.addInheritValueAfterEdit(this.selectedType);
            if (this.companyAdvancedTypesService.tabDirty) {
                this.save();
            }
        }
        else {
            setTimeout(function () {
                if (_this.selectedType === 'Salary') {
                    _this.salarySingleComponent.resetDirty();
                }
                else if (_this.selectedType === 'TimeEntry') {
                    _this.timeEntrySingleComponent.resetDirty();
                }
            });
            this.companyAdvancedTypesService.editMode = $event;
            this.companyAdvancedTypesService.removeInheritValueBeforeEdit(this.selectedType);
        }
    };
    AdvancedTypesComponent.prototype.save = function () {
        if (this.selectedType === 'Salary') {
            this.salaryTypeSaveChanges();
        }
        else if (this.selectedType === 'TimeEntry') {
            this.timeEntrySaveChanges();
        }
    };
    AdvancedTypesComponent.prototype.salaryTypeSaveChanges = function () {
        var _this = this;
        var dataItem = new SalaryTypeView(this.companyAdvancedTypesService.selectedType, this.companyAdvancedTypesService.isLanguageModuleEnable);
        if (!dataItem.Id) {
            return;
        }
        dataItem.ExternalReference = this.getExteralReferenceCode(dataItem.ExternalReference, true);
        var salaryType = dataItem.toSalaryType();
        this.companyAdvancedTypesService.checkSalaryType(salaryType);
        salaryType.ExternalReference = this.getExteralReferenceCode(salaryType.ExternalReference) || null;
        this.dataService.SalaryTypes_UpdateSalaryType(salaryType).subscribe(function (data) {
            data.ExternalReference = _this.getExteralReferenceCode(data.ExternalReference, true);
            _this.transformSalaryTypeObject(data);
            Object.assign(dataItem, new SalaryTypeView(data, _this.companyAdvancedTypesService.isLanguageModuleEnable));
            _this.companyAdvancedTypesService.selectedType = dataItem;
            _this.reloadGridData(true);
        }, function (error) {
            _this.getSalaryTypes(true);
        });
    };
    AdvancedTypesComponent.prototype.timeEntrySaveChanges = function () {
        var _this = this;
        var dataItem = new TimeEntryTypeView(this.companyAdvancedTypesService.selectedType, this.companyAdvancedTypesService.isLanguageModuleEnable);
        if (!dataItem.Id) {
            return;
        }
        dataItem.ExternalReference = this.getExteralReferenceCode(dataItem.ExternalReference, true);
        var timeEntryType = dataItem.toTimeEntryType();
        timeEntryType.ExternalReference = this.getExteralReferenceCode(timeEntryType.ExternalReference);
        this.ConvertCorrectSubmitData(timeEntryType);
        this.dataService.TimeEntryTypes_UpdateTimeEntryType(timeEntryType).subscribe(function (response) {
            response.ExternalReference = _this.getExteralReferenceCode(response.ExternalReference, true);
            _this.processGridItemObject(response);
            Object.assign(dataItem, new TimeEntryTypeView(response, _this.companyAdvancedTypesService.isLanguageModuleEnable));
            var timeEntryTypeConfigurations = _this.companyAdvancedTypesService.selectedType.TimeEntryTypeConfigurations;
            _this.companyAdvancedTypesService.selectedType = dataItem;
            _this.companyAdvancedTypesService.selectedType.TimeEntryTypeConfigurations = timeEntryTypeConfigurations;
            _this.reloadGridData(true);
        }, function (error) {
            _this.loadTimeEntryTypes(null, true);
        });
    };
    AdvancedTypesComponent.prototype.ConvertCorrectSubmitData = function (model) {
        var classCheck = 'inherit-field';
        model.IsActive =
            model.isActiveClass === classCheck && model.IsActive === model.BaseTimeEntryType.IsActive ? null : model.IsActive;
        model.ExternalReference =
            model.externalReferenceClass === classCheck &&
                model.ExternalReference === model.BaseTimeEntryType.ExternalReference
                ? null
                : model.ExternalReference;
        model.SortOrder =
            model.sortOrderClass === classCheck && model.SortOrder === model.BaseTimeEntryType.SortOrder
                ? null
                : model.SortOrder;
        model.UnitTypeId =
            model.unitTypeClass === classCheck && model.UnitTypeId === model.BaseTimeEntryType.UnitTypeId
                ? null
                : model.UnitTypeId;
        model.AllowEditUnitType =
            model.allowEditUnitTypeClass === classCheck &&
                model.AllowEditUnitType === model.BaseTimeEntryType.AllowEditUnitType
                ? null
                : model.AllowEditUnitType;
        model.IsIllness =
            model.isIllnessClass === classCheck && model.IsIllness === model.BaseTimeEntryType.IsIllness
                ? null
                : model.IsIllness;
        model.SalaryTypeId =
            model.salaryTypeClass === classCheck && model.SalaryTypeId === model.BaseTimeEntryType.SalaryTypeId
                ? null
                : model.SalaryTypeId;
        if (!this.companyAdvancedTypesService.isLanguageModuleEnable) {
            model.Name = model.nameClass === classCheck && model.Name === model.BaseTimeEntryType.Name ? null : model.Name;
            model.Description =
                model.descriptionClass === classCheck && model.Description === model.BaseTimeEntryType.Description
                    ? null
                    : model.Description;
            model.DefaultPayslipText =
                model.defaultPayslipTextClass === classCheck &&
                    model.DefaultPayslipText === model.BaseTimeEntryType.DefaultPayslipText
                    ? null
                    : model.DefaultPayslipText;
        }
        else {
            var bseModelLanguge = {};
            var modelLanguge = {};
            if (model.BaseTimeEntryType &&
                model.BaseTimeEntryType.TimeEntryTypeTranslations &&
                model.BaseTimeEntryType.TimeEntryTypeTranslations.length > 0) {
                bseModelLanguge = model.BaseTimeEntryType.TimeEntryTypeTranslations.find(function (t) { return t.LanguageId === Global.SESSION.SignOnToken.LanguageId; });
            }
            else {
                bseModelLanguge = {
                    Name: null,
                    Description: null,
                    DefaultPayslipText: null
                };
            }
            if (model.TimeEntryTypeTranslations && model.TimeEntryTypeTranslations.length > 0) {
                modelLanguge = model.TimeEntryTypeTranslations.find(function (t) { return t.LanguageId === Global.SESSION.SignOnToken.LanguageId; });
            }
            else {
                modelLanguge = {
                    Name: null,
                    Description: null,
                    DefaultPayslipText: null
                };
            }
            var isModelBaseTimeEntryTypeNull = false;
            if (!model.BaseTimeEntryType) {
                model.BaseTimeEntryType = {
                    Name: null,
                    Description: null,
                    DefaultPayslipText: null
                };
                isModelBaseTimeEntryTypeNull = true;
            }
            model.Name =
                model.nameClass === classCheck &&
                    (model.Name === bseModelLanguge.Name ||
                        model.Name === modelLanguge.Name ||
                        model.Name === model.BaseTimeEntryType.Name)
                    ? null
                    : model.Name;
            model.Description =
                model.descriptionClass === classCheck &&
                    (model.Description === bseModelLanguge.Description ||
                        model.Description === modelLanguge.Description ||
                        model.Description === model.BaseTimeEntryType.Description)
                    ? null
                    : model.Description;
            model.DefaultPayslipText =
                model.defaultPayslipTextClass === classCheck &&
                    (model.DefaultPayslipText === bseModelLanguge.DefaultPayslipText ||
                        model.DefaultPayslipText === modelLanguge.DefaultPayslipText ||
                        model.DefaultPayslipText === model.BaseTimeEntryType.DefaultPayslipText)
                    ? null
                    : model.DefaultPayslipText;
            model.BaseTimeEntryType = isModelBaseTimeEntryTypeNull ? null : model.BaseTimeEntryType;
        }
    };
    AdvancedTypesComponent.prototype.onConfirmAndDiscardChanges = function (isDiscardChange) {
        if (isDiscardChange) {
            this.discardChangesDialogVisible = true;
            return;
        }
        if (!this.companyAdvancedTypesService.tabDirty) {
            this.companyAdvancedTypesService.tabDirty = false;
            this.companyAdvancedTypesService.editMode = false;
            this.companyAdvancedTypesService.isSave = false;
            this.companyAdvancedTypesService.addInheritValueAfterEdit(this.selectedType);
            return;
        }
        this.discardChangesDialogVisible = true;
    };
    AdvancedTypesComponent.prototype.onDiscardChangesDialogAction = function (action) {
        if (action === 'ConfirmDiscard') {
            this.companyAdvancedTypesService.editMode = false;
            this.companyAdvancedTypesService.tabDirty = false;
            this.reloadGridData(true);
        }
    };
    AdvancedTypesComponent.prototype.onTranslationConfigurationVisibleChange = function (change) {
        if (change === true) {
            if (this.selectedType === 'Salary') {
                this.getSalaryTypes(true);
            }
            else if (this.selectedType === 'TimeEntry') {
                this.loadTimeEntryTypes(null, true);
            }
        }
    };
    AdvancedTypesComponent.prototype.onShowTranslationDialog = function () {
        if (this.selectedType === 'Salary') {
            this.translationDialogSTVisible = true;
        }
        else if (this.selectedType === 'TimeEntry') {
            this.translationDialogTEVisible = true;
        }
    };
    AdvancedTypesComponent.prototype.onToggleNavigation = function () {
        this.isCollapseButtonClick = true;
        this.menuOpen = !this.menuOpen;
    };
    AdvancedTypesComponent.prototype.navigateTo = function (state, index) {
        this.menuOpen = false;
        this.onAdvancedTypeChange(index);
        this.sessionService.NavigateTo(state);
    };
    AdvancedTypesComponent.prototype.revertInheritValue = function () {
        var _this = this;
        this.companyAdvancedTypesService.listRevertValues.forEach(function (element) {
            _this.companyAdvancedTypesService.selectedType[element.field] = null;
            if (_this.selectedType === 'Salary') {
                _this.salarySingleComponent.childIsDirty[element.index] = false;
                _this.companyAdvancedTypesService.tabDirty = _this.salarySingleComponent.childIsDirty.indexOf(true) >= 0;
            }
            else if (_this.selectedType === 'TimeEntry') {
                _this.timeEntrySingleComponent.childIsDirty[element.index] = false;
                _this.companyAdvancedTypesService.tabDirty = _this.timeEntrySingleComponent.childIsDirty.indexOf(true) >= 0;
            }
        });
    };
    return AdvancedTypesComponent;
}());
export { AdvancedTypesComponent };
