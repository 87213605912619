import * as tslib_1 from "tslib";
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { CompanyService } from '../../services/company.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/api/data.service";
import * as i2 from "../../services/api/static-data.service";
import * as i3 from "../../services/setting.service";
import * as i4 from "../../services/session/session.service";
import * as i5 from "@uirouter/core";
import * as i6 from "../../services/broadcast.service";
var ResidualVacationService = /** @class */ (function (_super) {
    tslib_1.__extends(ResidualVacationService, _super);
    function ResidualVacationService(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) {
        var _this = _super.call(this, dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) || this;
        _this.dataService = dataService;
        _this.staticDataService = staticDataService;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.transitionService = transitionService;
        _this.broadcaster = broadcaster;
        _this.BalancesEmployeeViewByDateSubject = new BehaviorSubject([]);
        _this.showEmployeePaidVacation = true;
        _this.hideEmployeeNoRemainingBlance = true;
        _this.originalDataBalancesEmployeeViewByDate = [];
        _this.isFirstLoad = true;
        return _this;
    }
    Object.defineProperty(ResidualVacationService.prototype, "BalancesEmployeeViewByDate", {
        get: function () {
            return this.BalancesEmployeeViewByDateSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ResidualVacationService.prototype, "VacationPeriod", {
        get: function () {
            if (!this.vacationPeriodSubject) {
                this.loadVacationPeriod();
            }
            return this.vacationPeriodSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    ResidualVacationService.prototype.loadVacationPeriod = function () {
        var _this = this;
        if (!this.vacationPeriodSubject) {
            this.vacationPeriodSubject = new BehaviorSubject([]);
        }
        this.staticDataService.VacationPeriod.pipe().subscribe(function (data) {
            _this.vacationPeriodSubject.next(data);
        });
    };
    ResidualVacationService.prototype.vacationPeriodChanged = function () {
        var _this = this;
        this.selectedPeriodItem = this.vacationPeriodSubject.value.find(function (model) { return model.Id === _this.VacationPeriodId; });
        if (this.selectedPeriodItem) {
            // GS-7639 Require FROZEN2019 Hard code default 30.04.2020
            if (this.selectedPeriodItem.SpecialIdentifier && this.selectedPeriodItem.SpecialIdentifier === 'FROZEN2019') {
                this.effectivePer = this.staticDataService.createValidDate(2020, 3, 30);
            }
            else {
                this.effectivePer = this.selectedPeriodItem.SpendingEndDate;
            }
        }
        this.isFirstLoad = true;
        // this.Balances_GetResidualVacationBalances();
    };
    ResidualVacationService.prototype.Balances_GetResidualVacationBalances = function () {
        var _this = this;
        if (!this.effectivePer || !this.VacationPeriodId) {
            return;
        }
        if (!this.BalancesEmployeeViewByDateSubject) {
            this.BalancesEmployeeViewByDateSubject = new BehaviorSubject([]);
        }
        var request = {
            BalanceDefinitionId: 1,
            BalancePeriodId: this.VacationPeriodId,
            PerDate: this.effectivePer
        };
        this.BalancesEmployeeViewByDateSubject.next([]);
        this.localBlanceApiService(request).subscribe(function (data) {
            _this.originalDataBalancesEmployeeViewByDate = data;
            _this.filterData();
        });
    };
    ResidualVacationService.prototype.localBlanceApiService = function (request) {
        return this.dataService.Balances_GetBalances(request).pipe(tap(function (data) {
            data = data.map(function (item) {
                item.DaysEarned = item.DaysEarned + item.SecondaryDaysEarned;
                item.DaysSpent = item.DaysSpent + item.SecondaryDaysSpent;
                item.DaysTotal = item.DaysTotal + item.CurrentBalanceSecondaryDays;
                item.AmountTotal = item.AmountTotal + item.SecondaryAmountTotal;
                item.AdjustDays = null;
                item.SpendDays = null;
                item.MoveDays = null;
                item.PayoutDays = null;
                return item;
            });
        }));
    };
    ResidualVacationService.prototype.Reload_Balances_GetResidualVacationBalances = function () {
        var _this = this;
        if (!this.effectivePer || !this.VacationPeriodId) {
            return;
        }
        if (!this.BalancesEmployeeViewByDateSubject) {
            this.BalancesEmployeeViewByDateSubject = new BehaviorSubject([]);
        }
        var request = {
            BalanceDefinitionId: 1,
            BalancePeriodId: this.VacationPeriodId,
            PerDate: this.effectivePer
        };
        this.localBlanceApiService(request).subscribe(function (data) {
            if (data && data.length > 0) {
                var arrayItemDirty_1 = [];
                if (_this.originalDataBalancesEmployeeViewByDate && _this.originalDataBalancesEmployeeViewByDate.length > 0) {
                    arrayItemDirty_1 = _this.originalDataBalancesEmployeeViewByDate.filter(function (item) { return item.isDirty === true; });
                }
                data.forEach(function (item) {
                    var originalItem = _this.originalDataBalancesEmployeeViewByDate.find(function (temp) { return item.UserEmploymentId === temp.UserEmploymentId; });
                    if (originalItem) {
                        // Update read only cell
                        originalItem.FullName = item.FullName;
                        originalItem.BasedOnTemplateName = item.BasedOnTemplateName;
                        originalItem.DepartmentName = item.DepartmentName;
                        originalItem.ExternalReference = item.ExternalReference;
                        originalItem.DaysEarned = item.DaysEarned;
                        originalItem.DaysSpent = item.DaysSpent;
                        originalItem.DaysTotal = item.DaysTotal;
                        originalItem.AmountTotal = item.AmountTotal;
                        originalItem.BaseAmount = item.BaseAmount || null;
                        originalItem.AmountEarned = item.AmountEarned || null;
                        originalItem.isDirty = false;
                        if (arrayItemDirty_1.find(function (tempModel) {
                            return tempModel.UserEmploymentId === originalItem.UserEmploymentId;
                        })) {
                            originalItem.isDirty = true;
                        }
                    }
                });
                _this.originalDataBalancesEmployeeViewByDate = data;
            }
            else {
                _this.originalDataBalancesEmployeeViewByDate = [];
            }
            _this.filterData();
        });
    };
    ResidualVacationService.prototype.filterData = function () {
        var result = [];
        result = this.originalDataBalancesEmployeeViewByDate;
        if (this.showEmployeePaidVacation) {
            result = result.filter(function (item) {
                return item.VacationTypeId === 1;
            });
        }
        if (this.hideEmployeeNoRemainingBlance) {
            result = result.filter(function (item) {
                return item.DaysTotal;
            });
        }
        this.BalancesEmployeeViewByDateSubject.next(result);
    };
    ResidualVacationService.prototype.save = function (model) {
        var _this = this;
        this.dataService.Balances_AdjustBalances(model).subscribe(function (data) {
            _this.afterSaveSucess(data);
            _this.completeSave();
        }, function () {
            setTimeout(function () {
                _this.completeSave();
            }, 1000);
        }, function () {
            // this.completeSave();
        });
    };
    Object.defineProperty(ResidualVacationService.prototype, "allowShortcut", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.balances.residualvacation';
        },
        enumerable: true,
        configurable: true
    });
    ResidualVacationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ResidualVacationService_Factory() { return new ResidualVacationService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.StaticDataService), i0.ɵɵinject(i3.SettingService), i0.ɵɵinject(i4.SessionService), i0.ɵɵinject(i5.TransitionService), i0.ɵɵinject(i6.BroadcastService)); }, token: ResidualVacationService, providedIn: "root" });
    return ResidualVacationService;
}(CompanyService));
export { ResidualVacationService };
