import { EventEmitter } from '@angular/core';
import { SessionService } from 'src/app/services/session/session.service';
var PopUpButtonComponent = /** @class */ (function () {
    function PopUpButtonComponent(sessionService) {
        this.sessionService = sessionService;
        this.popupButtonIcon = [];
        this.isUseLabelAppIcon = false;
        this.isHidden = false;
        this.IconClick = new EventEmitter();
        this.ButtonClick = new EventEmitter();
        this.show = false;
    }
    PopUpButtonComponent.prototype.onToggle = function () {
        this.show = !this.show;
        if (!this.show) {
            this.ButtonClick.emit();
        }
    };
    Object.defineProperty(PopUpButtonComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    PopUpButtonComponent.prototype.appIconClick = function (action) {
        if (this.isMobile) {
            if (!this.preAction || (this.preAction && this.preAction !== action)) {
                this.IconClick.emit(action);
                this.preAction = action;
            }
            else {
                this.preAction = '';
            }
        }
        else {
            this.IconClick.emit(action);
        }
    };
    return PopUpButtonComponent;
}());
export { PopUpButtonComponent };
