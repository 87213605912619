import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { CustomControlsModule } from '../../custom-controls/custom-controls.module';
import { SharedComponentsModule } from '../../shared-components/shared-components.module';

import { CompanyBalancesComponent } from './company-balances.component';
import { CompanyExtraVacationHoursComponent } from './extra-vacation-hours/company-extra-vacation-hours.component';
import { CompanyResidualVacationComponent } from './residual-vacation/company-residual-vacation.component';

@NgModule({
  imports: [CommonModule, TranslateModule, CustomControlsModule, SharedComponentsModule],
  declarations: [CompanyBalancesComponent, CompanyResidualVacationComponent, CompanyExtraVacationHoursComponent],
  exports: [CompanyBalancesComponent, CompanyResidualVacationComponent, CompanyExtraVacationHoursComponent],
  entryComponents: [CompanyBalancesComponent, CompanyResidualVacationComponent, CompanyExtraVacationHoursComponent]
})
export class CompanyBalancesModule {}
