import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmployeeService } from 'src/app/services/employee.service';
import { environment } from 'src/environments/environment';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { ImportTypesEnum } from '../../model/enum';
import {
  ISalaryBatch,
  ISalaryBatchRequest,
  ISalaryBatchView,
  SalaryBatch,
  SalaryPeriod
} from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { BusyIndicatorService, LoadingIndicatorTextType } from '../../services/busy-indicator.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import { ReportDialogService } from '../../shared-components/report-dialog/report-dialog.service';
import { ReportParametersDefaultValue } from '../../shared-components/report-dialog/report-parameters-default-value';
import { CompanySalaryBatchesChildFormEntityContext } from './company-salary-batches-child-form-entity-context';
import { CompanySalaryBatchService } from './company-salary-batches.service';
import { SalaryBatchViewModel } from './salary-batch-view-model';

@Component({
  selector: 'app-company-salary-batches',
  templateUrl: './company-salary-batches.component.html',
  styleUrls: ['./company-salary-batches.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanySalaryBatchesComponent implements OnInit, OnDestroy, AfterViewInit {
  public errorListDetailsDialogVisible = false;
  public lockSalaryBatchDialogVisible = false;
  public salaryBatchFinalizationStep1Completed = false;
  public salaryBatchFinalizationStep2Completed = false;
  public salaryBatchFinalizationStep3Completed = false;
  public salaryBatchFinalizationStep3RecalculationConfirm = false;
  public salaryBatchFinalizationCloseAll = false;
  public showRevertSalaryBatchDialog = false;
  public alertDialogVisible = false;
  public salaryBatchDialogVisible = false;
  public confirmPasswordDialogVisible = false;
  public confirmDeleteDialogVisible = false;
  public confirmRevertLockDialogVisible = false;
  public confirmRejectDialogVisible = false;
  public chooseResendTargetDialogVisible = false;
  public resendBatchExtCompletedDialogVisible = false;
  public resendBatchToEIncomeDialogVisible = false;
  public resendBatchToIntegrationConfirmationDialogVisible = false;
  public emptySalaryBatchDialogVisible = false;
  public noEmployeeActiveVisible = false;
  public reloadEntityContext = false;

  public alertDialogType: string;
  public listClassExcluded = ['Footer', '10'];

  // Parameter for splitter X
  private splitter: any;
  private cont1: any;
  private cont2: any;
  private lastX: any;
  private windowWidth: any;
  private leftSideWidth: any;

  // Parameter for splitter Y
  private splitterY: any;
  private cont1Y: any;
  private cont1Y1: any;
  private cont2Y: any;
  private cont2Y1: any;
  private cont2Y2: any;
  private lastY: any;
  private windowHeight: any;

  private selectedSalaryBatch: ISalaryBatch;
  private salaryBatchEntityContext: CompanySalaryBatchesChildFormEntityContext;

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  public onsalaryBatchFinalizationStep2Completed(): void {
    this.salaryBatchFinalizationStep2Completed = true;
  }

  public onsalaryBatchFinalizationStep3Completed(): void {
    this.salaryBatchFinalizationStep3Completed = true;
  }

  public get currentBatchHasError(): boolean {
    return this.selectedSalaryBatch && this.selectedSalaryBatch.HasErrors;
  }

  public get branding() {
    return environment.branding;
  }

  private selectedComboboxSalaryBatchIdValue: number;
  public get selectedComboboxSalaryBatchId(): number {
    return this.selectedComboboxSalaryBatchIdValue;
  }
  public set selectedComboboxSalaryBatchId(value: number) {
    if (this.selectedComboboxSalaryBatchIdValue !== value) {
      this.selectedComboboxSalaryBatchIdValue = value;

      const selectedBatch: ISalaryBatchView = value
        ? this.companySalaryBatchService.allSalaryBatches.find((item: ISalaryBatchView) => item.Id === value)
        : undefined;

      const jsonSelectedBatch = JSON.stringify(selectedBatch);
      const jsonEntityContextSelectedBatch = JSON.stringify(this.selectedSalaryBatch);
      if (this.companySalaryBatchService.IsMobileOrHybridApp && jsonSelectedBatch !== jsonEntityContextSelectedBatch) {
        this.companySalaryBatchService.setNextValueSelectedSalaryBatch(selectedBatch as any);
      }
    }
  }

  private salaryCycleIdValue: number;
  public get salaryCycleId(): number {
    return this.salaryCycleIdValue;
  }
  public set salaryCycleId(value: number) {
    if (this.salaryCycleIdValue !== value) {
      this.salaryCycleIdValue = value;
      this.filtersalaryBatchesDataSource();
    }
  }

  public get isShowApprovalButtonNonScreenCheck(): boolean {
    return (
      this.sessionService.role.IsSalaryAdminAndHigher &&
      this.salaryBatchEntityContext &&
      this.salaryBatchEntityContext.BatchId > 0 &&
      this.salaryBatchEntityContext.IsDraft
    );
  }

  public basicSalaryBatch: SalaryBatchViewModel;
  public childWindowSalaryBatch: SalaryBatchViewModel;

  private resendBatchId: number;
  private resendBatchToEIncomeId: number;
  private resendBatchToIntegrationId: number;

  constructor(
    private busyIndicatorService: BusyIndicatorService,
    private settingService: SettingService,
    private dataService: DataService,
    private staticDataService: StaticDataService,
    public sessionService: SessionService,
    private employeeService: EmployeeService,
    private renderer: Renderer2,
    public reportDialogService: ReportDialogService,
    public companySalaryBatchService: CompanySalaryBatchService,
    private translateService: TranslateService
  ) {
    this.companySalaryBatchService.showReportShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && this.reportDialogService.isVisibleReportIcon) {
          this.prepareReportDialogParams();
        }
      });

    this.reportDialogService.visibleChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      if (value) {
        this.prepareReportDialogParams();
      }
    });

    this.companySalaryBatchService.addNewShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && this.sessionService.role.IsSalaryAdminAndHigher) {
          this.showNewSalaryBatchDialog();
        }
      });

    this.companySalaryBatchService.selectedSalaryBatch
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: ISalaryBatch) => {
        if (!value) {
          this.companySalaryBatchService.loadSalaryStatements(value ? value.Id : undefined);
        }

        if (
          value &&
          ((this.selectedSalaryBatch && this.selectedSalaryBatch.Id !== value.Id) || !this.selectedSalaryBatch)
        ) {
          this.companySalaryBatchService.loadSalaryBatchErrorsView(value as any);
          this.companySalaryBatchService.loadSalaryStatements(value ? value.Id : undefined);
        }

        this.selectedSalaryBatch = value;

        this.basicSalaryBatch = value ? JSON.parse(JSON.stringify(value)) : undefined;
        this.childWindowSalaryBatch = value ? JSON.parse(JSON.stringify(value)) : undefined;

        if (this.companySalaryBatchService.IsMobileOrHybridApp) {
          this.selectDefaultBasicTab();
          this.companySalaryBatchService.loadSalaryStatements(value ? value.Id : undefined);
        }
      });

    this.companySalaryBatchService.salaryBatchEntityContext
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: CompanySalaryBatchesChildFormEntityContext) => {
        this.salaryBatchEntityContext = value;
      });

    this.companySalaryBatchService.viewSalaryTotalModeChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && !this.companySalaryBatchService.IsMobileOrHybridApp) {
          this.setPayrollSize();
        }
      });

    this.companySalaryBatchService.afterCreate.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      if (data) {
        this.filtersalaryBatchesDataSource(data.salaryRecord.Id, true);
        this.basicSalaryBatch = this.selectedSalaryBatch
          ? JSON.parse(JSON.stringify(this.selectedSalaryBatch))
          : undefined;

        if (this.sessionService.role.IsSalaryAdminAndHigher && this.companySalaryBatchService.emptySalaryBatchShown) {
          this.emptySalaryBatchDialogVisible = true;
        }

        if (!data.salaryRecord.Id) {
          this.reloadcaculate(this.selectedSalaryBatch);
        }
      }
    });
  }

  public ngOnInit(): void {
    this.companySalaryBatchService.isSalaryBatchLoading = true;
    // Workaround for GS-5515 to make sure employees are loaded
    this.employeeService.employees.subscribe();
    this.companySalaryBatchService.initData();

    this.getSalaryBatches();
    this.translateText();

    this.settingService.showModuleMessage('Company.SalaryBatches');
    this.settingService.showNavigationMessage();
  }

  private unsubscribeResize: () => void;

  public ngAfterViewInit() {
    if (!this.companySalaryBatchService.IsMobileOrHybridApp) {
      this.unsubscribeResize = this.renderer.listen(window, 'resize', () => {
        this.setPayrollSize();
      });
      setTimeout(() => {
        this.setPayrollSize();
      });
    }
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.companySalaryBatchService.ngOnDestroy();

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    if (!this.companySalaryBatchService.IsMobileOrHybridApp) {
      this.unsubscribeResize();
    }
  }

  private setPayrollSize() {
    const divManHeight = 100;
    const wHeight = window.innerHeight;
    const wWidth = window.innerWidth;

    const divCompany = document.getElementById('divCompany');
    if (divCompany) {
      const listKendoGrid = divCompany.getElementsByClassName('k-grid-content');
      if (listKendoGrid && listKendoGrid.length > 1) {
        const rateLeft = wHeight * 0.602;
        const rateRight = wHeight * 0.207;
        if (listKendoGrid.length === 5 && wWidth >= 1280 && wHeight >= 500) {
          (listKendoGrid[1] as HTMLElement).style.height = wHeight - divManHeight - rateLeft + 'px';
          (listKendoGrid[2] as HTMLElement).style.height = wHeight - divManHeight - rateLeft + 'px';
          (listKendoGrid[3] as HTMLElement).style.height = wHeight - divManHeight - rateRight + 'px';
          (listKendoGrid[4] as HTMLElement).style.height = wHeight - divManHeight - rateRight + 'px';

          this.initSplitterY();

          this.initSplitter();
          if (wWidth > 1520) {
            this.resetPosition('half');
          } else {
            this.resetPosition('full');
          }
        } else if (listKendoGrid.length === 5) {
          this.resetSplitter();
          (listKendoGrid[3] as HTMLElement).style.height = '';
          (listKendoGrid[4] as HTMLElement).style.height = '';
        }
      }
    }
  }

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public onMoreMenuOptionClick(action: string): void {
    switch (action) {
      case 'salaryBatches_recalculateDraft':
        this.onRecalculate();
        break;
      case 'salaryBatches_importData':
        this.settingService.navigationParameters['dataImportSelectedOption'] = ImportTypesEnum.Payroll;
        this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
        break;
      case 'salarybatchaddnew':
        this.showNewSalaryBatchDialog();
        break;
      case 'companyreport':
        this.prepareReportDialogParams();
        break;
    }
  }

  public showErrorModal(data: SalaryBatchViewModel): void {
    this.companySalaryBatchService.setNextValueSelectedSalaryBatch(data);
    this.errorListDetailsDialogVisible = true;
  }

  public selectedSalaryBatchChanged(data: SalaryBatchViewModel): void {
    this.companySalaryBatchService.setNextValueSelectedSalaryBatch(data);
  }

  public reloadcaculate(model: ISalaryBatch): void {
    this.companySalaryBatchService.loadSalaryTypeTotals(model ? model.Id : undefined);
    this.companySalaryBatchService.loadUnitTotals(model ? model.Id : undefined);
    this.companySalaryBatchService.loadSalaryStatements(model ? model.Id : undefined);
    this.companySalaryBatchService.loadSalaryBatchErrorsView(this.selectedSalaryBatch as any);
  }

  private isForceRecalculate = false;
  public isShowWarningRecalculate = false;
  public isWaitingReCalculate = false;
  public loadingText = '';
  public onRecalculate(): void {
    if (!this.isForceRecalculate) {
      this.isShowWarningRecalculate = true;
      return;
    }
    this.isForceRecalculate = false;
    if (this.selectedSalaryBatch) {
      this.busyIndicatorService.setLoadingIndicatorVisibility(true, LoadingIndicatorTextType.RECALCULATE);
      this.loadingText = this.busyIndicatorService.getLoadingText();
      this.isWaitingReCalculate = true;
      this.dataService.SalaryBatches_RecalculateSalaryBatch(this.selectedSalaryBatch.Id).subscribe(
        () => {
          this.reloadSalaryBatches();
          this.busyIndicatorService.setLoadingIndicatorVisibility(false);
          this.isWaitingReCalculate = false;
        },
        () => {
          this.busyIndicatorService.setLoadingIndicatorVisibility(false);
          this.isWaitingReCalculate = false;
        },
        () => {
          this.busyIndicatorService.setLoadingIndicatorVisibility(false);
          this.isWaitingReCalculate = false;
        }
      );
    }
  }

  public prepareReportDialogParams(): void {
    if (this.selectedSalaryBatch) {
      this.reportDialogService.reportParams = new ReportParametersDefaultValue();
      this.reportDialogService.reportParams.salaryBatchId = this.selectedSalaryBatch.Id;
      this.reportDialogService.onShowReportsEventClick();
    } else {
      this.reportDialogService.reportDialogVisible = false;
      this.alertDialogType = 'noBatchSelectedWarning';
      this.alertDialogVisible = true;
    }
  }

  private prechildWindowSalaryBatch: SalaryBatchViewModel;
  public showNewSalaryBatchDialog(): void {
    if (this.companySalaryBatchService.isEmptyEmployeeFromCycle) {
      this.noEmployeeActiveVisible = true;
      return;
    }
    this.prechildWindowSalaryBatch = this.childWindowSalaryBatch;
    this.childWindowSalaryBatch = undefined;
    this.salaryBatchDialogVisible = true;
  }

  public onEditSalaryBatch(batch: SalaryBatchViewModel): void {
    if (!batch) {
      return;
    }

    this.companySalaryBatchService.setNextValueSelectedSalaryBatch(batch);

    this.basicSalaryBatch = JSON.parse(JSON.stringify(batch));
    this.childWindowSalaryBatch = JSON.parse(JSON.stringify(batch));
    this.salaryBatchDialogVisible = true;
  }

  public onRecalculateAction(action: string) {
    if (action === 'ForceRecalculate') {
      this.isForceRecalculate = true;
      this.onRecalculate();
    }
  }

  public onSalaryBatchDialogAction(event: { action: string; context: any }): void {
    this.companySalaryBatchService.setNextValueSalaryBatchEntityContext(event.context);
    switch (event.action) {
      case 'Create':
      case 'Save':
        this.onDone();
        break;
      case 'Delete':
        this.onDelete();
        break;
      case 'SubmitForApproval':
        this.onLockSalaryBatch();
        break;
      case 'Reject':
      case 'RevertFinalizing':
        this.onRejectSalaryBatch();
        break;
      case 'RevertToDraft':
        this.onLockSalaryBatch();
        break;
      case 'Finalize':
        this.onFinalize();
        break;
      case 'Resend':
        this.resendBatch(this.salaryBatchEntityContext.BatchId);
        break;
      case 'Revert':
        this.showRevertSalaryBatchDialog = true;
        break;
      default:
        this.onDone(true);
    }
  }

  public onConfirmDeleteDialogAction(action: string): void {
    if (action === 'Delete') {
      this.deleteSalaryBatch(this.salaryBatchEntityContext.BatchId);
    }
  }

  public onConfirmRevertLockDialogAction(action: string): void {
    if (action === 'Ok') {
      this.dataService
        .SalaryBatches_ResetBatchToDraft(this.salaryBatchEntityContext.BatchId)
        .subscribe(() => this.reloadSalaryBatches());
    }
  }

  public reloadSalaryBatches(): void {
    this.closeDialog();
    this.getSalaryBatches();
  }

  public onChooseResendTargetDialogAction(action: string): void {
    if (action === 'eincome') {
      this.resendBatchToEIncome(this.resendBatchId);
    } else if (action === 'external') {
      this.apiResendToIntegration(this.resendBatchId);
    }
  }

  public resendBatch(batchId: number): void {
    if (this.IsGreenlandCompany) {
      if (this.companySalaryBatchService.integrationName) {
        this.resendBatchToIntegrationConfirmation(batchId);
      }
    } else {
      if (this.companySalaryBatchService.integrationName) {
        this.resendBatchId = batchId;
        this.chooseResendTargetDialogVisible = true;
      } else {
        this.resendBatchToEIncome(batchId);
      }
    }
  }

  private resendBatchToEIncome(batchId: number): void {
    this.resendBatchToEIncomeId = batchId;
    this.resendBatchToEIncomeDialogVisible = true;
  }

  public onResendBatchToEIncomeDialogAction(action: string): void {
    if (action === 'Resend') {
      this.apiResendToEIncome(this.resendBatchToEIncomeId);
    }
  }

  private resendBatchToIntegrationConfirmation(batchId: number): void {
    this.resendBatchToIntegrationId = batchId;
    this.resendBatchToIntegrationConfirmationDialogVisible = true;
  }

  public onResendBatchToIntegrationDialogAction(action: string): void {
    if (action === 'Resend') {
      this.apiResendToIntegration(this.resendBatchToIntegrationId);
    }
  }

  public closeFinalizationWizardDialogs(): void {
    this.salaryBatchFinalizationCloseAll = true;
    this.confirmPasswordDialogVisible = false;

    this.closeDialog();
  }

  public onFinalizationProcessDone(doRecalculation: boolean): void {
    this.reloadSalaryBatches();

    this.salaryBatchFinalizationCloseAll = true;
    this.confirmPasswordDialogVisible = false;
  }

  public onConfirmPasswordDialogAction(action: string): void {
    switch (action) {
      case 'Cancel':
        this.closeFinalizationWizardDialogs();
        break;
      case 'AcceptWarnings':
        this.salaryBatchFinalizationStep1Completed = true;
        break;
      default:
        this.closeDialog();
    }
  }

  public onEmptySalaryBatchDialogAction(action: string): void {
    if (action === 'Yes') {
      this.showNewSalaryBatchDialog();
    }
  }

  public getSalaryBatches(salaryBatchId?: number): void {
    this.busyIndicatorService.setLoadingIndicatorVisibility(true, LoadingIndicatorTextType.RECALCULATE);
    this.loadingText = this.busyIndicatorService.getLoadingText();
    this.isWaitingReCalculate = true;
    this.companySalaryBatchService.loadSalaryBatches().subscribe(
      () => {
        this.filtersalaryBatchesDataSource(salaryBatchId);
        this.basicSalaryBatch = this.selectedSalaryBatch
          ? JSON.parse(JSON.stringify(this.selectedSalaryBatch))
          : undefined;

        if (this.sessionService.role.IsSalaryAdminAndHigher && this.companySalaryBatchService.emptySalaryBatchShown) {
          this.emptySalaryBatchDialogVisible = true;
        }

        if (!salaryBatchId) {
          this.reloadcaculate(this.selectedSalaryBatch);
        }

        this.busyIndicatorService.setLoadingIndicatorVisibility(false);
        this.isWaitingReCalculate = false;
      },
      () => {
        this.busyIndicatorService.setLoadingIndicatorVisibility(false);
        this.isWaitingReCalculate = false;
      }
    );
  }

  private setDefaultSalaryBatchForMobileDevice(): void {
    if (this.companySalaryBatchService.IsMobileOrHybridApp) {
      if (!this.companySalaryBatchService.emptySalaryBatchShown) {
        const hasItemSelected: boolean = this.companySalaryBatchService.allSalaryBatches.some(
          (b: ISalaryBatchView) => b.Id === this.selectedComboboxSalaryBatchId
        );
        if (!hasItemSelected) {
          this.selectedComboboxSalaryBatchId = this.companySalaryBatchService.allSalaryBatches[0].Id;
        }
      } else {
        this.companySalaryBatchService.setNextValueSelectedSalaryBatch(undefined);
        this.selectedComboboxSalaryBatchId = undefined;
      }
    }
  }

  public closeDialog(): void {
    this.salaryBatchDialogVisible = false;
    this.salaryBatchFinalizationStep1Completed = false;
    this.salaryBatchFinalizationStep2Completed = false;
    this.salaryBatchFinalizationStep3Completed = false;
    if (!this.childWindowSalaryBatch) {
      this.basicSalaryBatch = this.prechildWindowSalaryBatch
        ? JSON.parse(JSON.stringify(this.prechildWindowSalaryBatch))
        : undefined;
      this.childWindowSalaryBatch = this.prechildWindowSalaryBatch
        ? JSON.parse(JSON.stringify(this.prechildWindowSalaryBatch))
        : undefined;
    }
  }

  private onShowConfirmPasswordWindow(): void {
    this.salaryBatchDialogVisible = false;
    this.salaryBatchEntityContext.Password = '';
    this.confirmPasswordDialogVisible = true;
  }

  public onRevertBatchCompleted(): void {
    this.getSalaryBatches();
    this.closeDialog();
  }

  public onFinalize(): void {
    if (this.selectedSalaryBatch) {
      let payoutDate: Date = new Date(this.selectedSalaryBatch.PayoutDate);
      payoutDate = this.staticDataService.getCurrentdate(undefined, payoutDate);

      let contextpayoutDate: Date = new Date(this.salaryBatchEntityContext.PaymentDate);
      contextpayoutDate = this.staticDataService.getCurrentdate(undefined, contextpayoutDate);

      if (
        (this.salaryBatchEntityContext.Message !== this.selectedSalaryBatch.Message ||
          contextpayoutDate.getTime() !== payoutDate.getTime()) &&
        this.selectedSalaryBatch.StatusId <= 10 &&
        Global.SESSION.CurrentRole.Id >= 60
      ) {
        this.saveSalaryBatchChanges(true).then((salaryRecord: ISalaryBatch) => {
          if (this.salaryBatchEntityContext && salaryRecord) {
            this.salaryBatchEntityContext.HasErrors = salaryRecord.HasErrors;
            this.salaryBatchEntityContext.HasWarnings = salaryRecord.HasWarnings;
            this.beginFinalization();
          }
        });
      } else {
        this.beginFinalization();
      }
    } else {
      this.alertDialogType = 'noBatchSelectedWarning';
      this.alertDialogVisible = true;
    }
  }

  private translateText(): void {
    this.translateService
      .get(['CompanySalaryBatches.FinalizationStep3ErrorMassge'])
      .subscribe((translations: { [key: string]: string }) => {
        this.companySalaryBatchService.Step3FinalizationFailText =
          translations['CompanySalaryBatches.FinalizationStep3ErrorMassge'];
      });
  }

  private beginFinalization(): void {
    if (Global.SESSION && !Global.SESSION.IsPaymentApprover) {
      if (Global.SESSION.CurrentRole.Id === 90) {
        // Administrator (FullAdmin)
        this.alertDialogType = 'denyFinalizeAdminText';
        this.alertDialogVisible = true;
      } else {
        this.alertDialogType = 'denyFinalizeText';
        this.alertDialogVisible = true;
      }

      return;
    }

    if (this.salaryBatchEntityContext.HasErrors) {
      this.alertDialogType = 'finalizeErrorText';
      this.alertDialogVisible = true;
      return;
    }

    if (!this.salaryBatchEntityContext.HasWarnings) {
      this.salaryBatchDialogVisible = false;
      this.salaryBatchFinalizationStep1Completed = true;
      return;
    }

    this.onShowConfirmPasswordWindow();
  }

  public afterFinalizeError(): void {
    if (this.alertDialogType && this.alertDialogType === 'finalizeErrorText') {
      this.closeDialog();
      this.errorListDetailsDialogVisible = true;
    }
  }

  private onDelete(): void {
    this.confirmDeleteDialogVisible = true;
  }

  private onDone(forceClose: boolean = false): void {
    if (this.IsReadOnly || forceClose || !this.salaryBatchEntityContext.IsDraft) {
      this.reloadEntityContext = true;
      this.closeDialog();
      return;
    }

    this.saveSalaryBatchChanges();
  }

  public isSalaryBatchCreating = false;
  private saveSalaryBatchChanges(isFinalized: boolean = false): Promise<ISalaryBatch> {
    return new Promise<ISalaryBatch>(
      (resolve: (value: ISalaryBatch) => void, reject: (value: ISalaryBatch) => void) => {
        if (!this.salaryBatchEntityContext.PaymentDate) {
          this.alertDialogType = 'invalidDateMessage';
          this.alertDialogVisible = true;
          return resolve(undefined);
        }

        const payoutDate = this.staticDataService.getCurrentdate(undefined, this.salaryBatchEntityContext.PaymentDate);

        if (this.salaryBatchEntityContext.BatchId < 1) {
          this.isSalaryBatchCreating = true;
          const salaryBatchRequest: ISalaryBatchRequest = {
            PayoutDate: payoutDate,
            Preview: false,
            SalaryCycleId: this.salaryBatchEntityContext.CycleId,
            SalaryPeriodId: this.salaryBatchEntityContext.PeriodId,
            Message:
              this.salaryBatchEntityContext.Message === undefined ? undefined : this.salaryBatchEntityContext.Message,
            UserEmploymentIds: this.companySalaryBatchService.allCurrentSelectSalaryStatements,
            EIncomeZeroReport: this.salaryBatchEntityContext.EmployeeCategory === 3,
            PayoutAllFlex: this.salaryBatchEntityContext.PayoutAllFlex
              ? this.salaryBatchEntityContext.PayoutAllFlex
              : false,
            PayoutNewFlex: this.salaryBatchEntityContext.PayoutNewFlex
              ? this.salaryBatchEntityContext.PayoutNewFlex
              : false,
            FromDateOverride: this.salaryBatchEntityContext.FromDateOverride
              ? this.salaryBatchEntityContext.FromDateOverride
              : null,
            ToDateOverride: this.salaryBatchEntityContext.ToDateOverride
              ? this.salaryBatchEntityContext.ToDateOverride
              : null
          };
          return this.createSalaryBatch(salaryBatchRequest).then((salaryBatch: ISalaryBatch) => resolve(salaryBatch));
        } else {
          let selectedSalaryBatch: ISalaryBatch = this.selectedSalaryBatch;
          const salaryPeriod = new SalaryPeriod();
          salaryPeriod.Id = this.salaryBatchEntityContext.PeriodId;
          salaryPeriod.StartDate = null;
          salaryPeriod.EndDate = null;
          salaryPeriod.SuggestedPayoutDate = selectedSalaryBatch.PayoutDate || null;
          salaryPeriod.FriendlyName = null;
          salaryPeriod.SalaryCycleId = this.salaryBatchEntityContext.CycleId || null;

          if (selectedSalaryBatch && (selectedSalaryBatch as any).PeriodStartDate) {
            salaryPeriod.StartDate = this.staticDataService.getCurrentdate(
              false,
              (selectedSalaryBatch as any).PeriodStartDate
            );
          }
          if (selectedSalaryBatch && (selectedSalaryBatch as any).PeriodEndDate) {
            salaryPeriod.EndDate = this.staticDataService.getCurrentdate((selectedSalaryBatch as any).PeriodEndDate);
          }

          selectedSalaryBatch.SalaryPeriod = salaryPeriod;
          selectedSalaryBatch.PayoutAllFlex = this.salaryBatchEntityContext.PayoutAllFlex
            ? this.salaryBatchEntityContext.PayoutAllFlex
            : false;
          selectedSalaryBatch.PayoutNewFlex = this.salaryBatchEntityContext.PayoutNewFlex
            ? this.salaryBatchEntityContext.PayoutNewFlex
            : false;

          selectedSalaryBatch.FromDateOverride = this.salaryBatchEntityContext.FromDateOverride;
          selectedSalaryBatch.ToDateOverride = this.salaryBatchEntityContext.ToDateOverride;

          selectedSalaryBatch.PayoutDate = payoutDate;
          selectedSalaryBatch.Message = this.salaryBatchEntityContext.Message;
          selectedSalaryBatch.EBoksDeliveryEnabled = selectedSalaryBatch.EBoksDeliveryEnabled
            ? selectedSalaryBatch.EBoksDeliveryEnabled
            : false;
          selectedSalaryBatch.SpecifiedEmployees = selectedSalaryBatch.SpecifiedEmployees;
          selectedSalaryBatch.HasNemKontoPayments = selectedSalaryBatch.HasNemKontoPayments
            ? selectedSalaryBatch.HasNemKontoPayments
            : false;
          selectedSalaryBatch.PaymentMethodPublicPension = selectedSalaryBatch.PaymentMethodPublicPension;
          selectedSalaryBatch.PaymentMethodVacationProvider = selectedSalaryBatch.PaymentMethodVacationProvider;
          selectedSalaryBatch.ToArchive = selectedSalaryBatch.ToArchive ? selectedSalaryBatch.ToArchive : false;
          selectedSalaryBatch.Finalized = selectedSalaryBatch.Finalized;
          selectedSalaryBatch.FinalizedUserId = selectedSalaryBatch.FinalizedUserId;
          selectedSalaryBatch.AllowPreliminaryPayslips = selectedSalaryBatch.AllowPreliminaryPayslips
            ? selectedSalaryBatch.AllowPreliminaryPayslips
            : false;
          selectedSalaryBatch.PackageLevelWhenFinalized = selectedSalaryBatch.PackageLevelWhenFinalized;
          selectedSalaryBatch.IsAutomated = selectedSalaryBatch.IsAutomated;
          selectedSalaryBatch.FinalizationGuid = selectedSalaryBatch.FinalizationGuid || null;

          const selectedSalaryBatchRecord = new SalaryBatch();
          selectedSalaryBatch = this.staticDataService.checkModelRecord(selectedSalaryBatch);
          Object.assign(selectedSalaryBatchRecord, selectedSalaryBatch);

          return this.updateSalaryBatch(selectedSalaryBatchRecord, isFinalized).then((salaryBatch: ISalaryBatch) =>
            resolve(salaryBatch)
          );
        }
      }
    );
  }

  private onRejectSalaryBatch(): void {
    this.confirmRejectDialogVisible = true;
  }

  public onLockSalaryBatch(): void {
    if (
      (this.salaryBatchEntityContext.Message !== this.selectedSalaryBatch.Message ||
        this.salaryBatchEntityContext.PaymentDate !== this.selectedSalaryBatch.PayoutDate) &&
      this.selectedSalaryBatch.StatusId <= 10 &&
      Global.SESSION.CurrentRole.Id >= 60
    ) {
      this.saveSalaryBatchChanges(true).then((salaryRecord: ISalaryBatch) => {
        if (this.salaryBatchEntityContext && salaryRecord) {
          this.salaryBatchEntityContext.HasErrors = salaryRecord.HasErrors;
          this.salaryBatchEntityContext.HasWarnings = salaryRecord.HasWarnings;

          if (this.salaryBatchEntityContext.IsDraft) {
            this.lockSalaryBatchDialogVisible = true;
          } else if (this.salaryBatchEntityContext.IsLocked || this.salaryBatchEntityContext.IsPreliminary) {
            this.confirmRevertLockDialogVisible = true;
          }
        }
      });
    } else {
      if (this.salaryBatchEntityContext.IsDraft) {
        this.lockSalaryBatchDialogVisible = true;
      } else if (this.salaryBatchEntityContext.IsLocked || this.salaryBatchEntityContext.IsPreliminary) {
        this.confirmRevertLockDialogVisible = true;
      }
    }
  }

  private recalculateDrafts(): void {
    this.getSalaryBatches();
  }

  private apiResendToEIncome(batchId: number): void {
    this.dataService.SalaryBatches_ResendEIncome(batchId).subscribe(() => {
      this.alertDialogType = 'resendBatchCompletedMessage';
      this.alertDialogVisible = true;
      this.reloadSalaryBatches();
    });
  }

  private apiResendToIntegration(batchId: number): void {
    this.dataService.SalaryBatches_SendOrResendToDefaultIntegration(batchId).subscribe(() => {
      this.resendBatchExtCompletedDialogVisible = true;
      this.reloadSalaryBatches();
    });
  }

  private createSalaryBatch(salaryBatch: ISalaryBatchRequest): Promise<ISalaryBatch> {
    return new Promise<ISalaryBatch>(
      (resolve: (value: ISalaryBatch) => void, reject: (value: ISalaryBatch) => void) => {
        this.dataService.SalaryBatches_CreateSalaryBatch(salaryBatch).subscribe(
          (salaryRecord: ISalaryBatch): void => {
            this.handleAfterCreateBatch(salaryBatch, salaryRecord);
            this.closeDialog();
            this.isSalaryBatchCreating = false;
            return resolve(salaryRecord);
          },
          () => {
            this.closeDialog();
            this.isSalaryBatchCreating = false;
            return resolve(undefined);
          }
        );
      }
    );
  }

  private handleAfterCreateBatch(salaryBatch: ISalaryBatchRequest, salaryRecord: ISalaryBatch) {
    if (!this.salaryCycleId || this.salaryCycleId === salaryBatch.SalaryCycleId) {
      this.getSalaryBatches(salaryRecord.Id);
    } else {
      this.busyIndicatorService.setLoadingIndicatorVisibility(true, LoadingIndicatorTextType.RECALCULATE);
      this.loadingText = this.busyIndicatorService.getLoadingText();
      this.isWaitingReCalculate = true;
      this.companySalaryBatchService.loadSalaryBatches().subscribe(
        () => {
          this.busyIndicatorService.setLoadingIndicatorVisibility(false);
          this.isWaitingReCalculate = false;
        },
        () => {
          this.busyIndicatorService.setLoadingIndicatorVisibility(false);
          this.isWaitingReCalculate = false;
        }
      );
    }
  }

  private updateSalaryBatch(salaryBatch: ISalaryBatch, isFinalize: boolean = false): Promise<ISalaryBatch> {
    return new Promise<ISalaryBatch>(
      (resolve: (value: ISalaryBatch) => void, reject: (value: ISalaryBatch) => void) => {
        this.dataService.SalaryBatches_UpdateSalaryBatch(salaryBatch).subscribe(
          (salaryRecord: ISalaryBatch): void => {
            this.getSalaryBatches();
            if (!isFinalize) {
              this.closeDialog();
            }

            return resolve(salaryRecord);
          },
          () => {
            return resolve(salaryBatch);
          }
        );
      }
    );
  }

  private deleteSalaryBatch(batchId: number): void {
    this.dataService.SalaryBatches_DeleteSalaryBatch(batchId).subscribe((): void => {
      this.selectedSalaryBatch = null;
      this.reloadSalaryBatches();
    });
  }

  public salaryBatchesDataSource: ISalaryBatchView[] = [];
  private filtersalaryBatchesDataSource(salaryBatchId?: number, isNotSelect?: boolean): void {
    if (this.salaryCycleId) {
      this.salaryBatchesDataSource = this.companySalaryBatchService.allSalaryBatches.filter(
        (s: ISalaryBatchView) => s.SalaryCycleId === this.salaryCycleId
      );
    } else {
      if (!this.companySalaryBatchService.emptySalaryBatchShown) {
        this.salaryBatchesDataSource = this.companySalaryBatchService.allSalaryBatches;
      }
    }

    if (isNotSelect) {
      return;
    }

    if (this.salaryBatchesDataSource && this.salaryBatchesDataSource.length > 0) {
      salaryBatchId = salaryBatchId
        ? salaryBatchId
        : this.selectedSalaryBatch
        ? this.selectedSalaryBatch.Id
        : undefined;
      const selectedItem: any = salaryBatchId
        ? this.salaryBatchesDataSource.find((d: ISalaryBatchView) => d.Id === salaryBatchId)
        : undefined;
      if (selectedItem) {
        this.selectedComboboxSalaryBatchId = selectedItem.Id;
        this.companySalaryBatchService.setNextValueSelectedSalaryBatch(selectedItem);
      } else {
        this.selectedComboboxSalaryBatchId = this.salaryBatchesDataSource[0].Id;
      }
    } else {
      this.selectedComboboxSalaryBatchId = undefined;
    }
  }

  @ViewChild('salaryBatchTab', { static: false }) public salaryBatchTab: TabStripComponent;

  private errorTabSelected: boolean;
  public onSalaryBatchTabSelected(event: any): void {
    this.errorTabSelected = event.index === 1; // Error.
  }

  private selectDefaultBasicTab(): void {
    if (this.companySalaryBatchService.IsMobileOrHybridApp) {
      const salaryBatch: any = this.selectedSalaryBatch;
      const basicTab: boolean = this.errorTabSelected && salaryBatch && !salaryBatch.ValidationIcon;
      if (basicTab && this.salaryBatchTab) {
        this.salaryBatchTab.selectTab(0);
      }
    }
  }

  private initSplitter() {
    this.splitter = document.getElementById('splitter') as HTMLElement;
    this.cont1 = document.getElementById('Card-splitter1') as HTMLElement;
    this.cont2 = document.getElementById('Card-splitter2') as HTMLElement;
    const leftside: any = document.getElementById('Card-Leftside') as HTMLElement;
    this.windowWidth = window.innerWidth;
    if (!this.splitter || !this.cont1 || !this.cont2) {
      return;
    }
    if (leftside) {
      if (this.windowWidth > 1520) {
        this.leftSideWidth =
          leftside.clientWidth > this.windowWidth * 0.55 ? this.windowWidth * 0.55 : leftside.clientWidth;
      } else {
        this.leftSideWidth = leftside.clientWidth;
      }
    }

    if (this.cont2Y) {
      let extra = 133;
      if (window.innerWidth < 1520) {
        extra = 113;
      }

      const cont2YHeight = this.windowHeight - this.cont1Y.clientHeight - extra;
      this.cont1.style.height = cont2YHeight + 'px';
      this.cont2.style.height = cont2YHeight + 'px';
    }

    this.splitter.addEventListener('mousedown', this.spMouseDown);
  }

  private spMouseDown = (e: any) => {
    this.splitter.removeEventListener('mousedown', this.spMouseDown);
    window.addEventListener('mousemove', this.spMouseMove);
    window.addEventListener('mouseup', this.spMouseUp);
    this.lastX = e.clientX;
  };

  private spMouseUp = (e: any) => {
    window.removeEventListener('mousemove', this.spMouseMove);
    window.removeEventListener('mouseup', this.spMouseUp);
    this.splitter.addEventListener('mousedown', this.spMouseDown);
    this.resetPosition(this.lastX);
  };

  private spMouseMove = (e: any) => {
    this.resetPosition(e.clientX);
  };

  private resetPosition(nowX: any) {
    let dx = 0;
    // For first time init or reset when window resize
    if (nowX === 'full' || nowX === 'half') {
      const mid = this.leftSideWidth / 2;
      if (this.leftSideWidth - dx < mid) {
        dx = this.leftSideWidth - mid;
      } else if (dx <= mid) {
        dx = mid;
      }
    } else {
      dx = nowX - this.lastX;
      dx += this.cont1.clientWidth;

      if (this.leftSideWidth - dx < 300) {
        // For next time, and whenever user resize
        dx = this.windowWidth - 300;
        if (this.leftSideWidth - dx < 300) {
          dx = this.leftSideWidth - 300;
        } else if (dx < 300) {
          dx = 300;
        }
      }
    }

    this.cont1.style.width = dx + 'px';
    dx += this.splitter.clientWidth;
    dx = this.leftSideWidth - dx;
    this.cont2.style.width = dx + 'px';
    this.lastX = nowX;
  }

  private initSplitterY() {
    this.splitterY = document.getElementById('splitterY') as HTMLElement;
    this.cont1Y = document.querySelectorAll('#Card-splitter1Y')[0] as HTMLElement;
    this.cont2Y = document.querySelectorAll('#Card-splitter2Y')[0] as HTMLElement;
    this.cont1Y1 = document.querySelectorAll('#Card-splitter1Y kendo-grid-list')[0] as HTMLElement;
    this.cont2Y1 = document.querySelectorAll('#Card-splitter2Y .k-grid-content.k-virtual-content')[0] as HTMLElement;
    this.cont2Y2 = document.querySelectorAll('#Card-splitter2Y .k-grid-content.k-virtual-content')[1] as HTMLElement;
    if (!this.cont1Y || !this.cont2Y || !this.cont1Y1 || !this.cont2Y1 || !this.cont2Y2) {
      return;
    }
    if (window.innerWidth < 1520) {
      this.windowHeight = 750;
    } else {
      this.windowHeight = window.innerHeight - 120;
    }

    const minHeight1 = document.querySelectorAll('.Company-payslipsGrid .k-grid-content')[0] as HTMLElement;
    const minHeight2 = document.querySelectorAll('.Company-payslipsGrid .k-grid-content')[1] as HTMLElement;
    if (minHeight1 && minHeight2) {
      minHeight1.style.minHeight = '';
      minHeight2.style.minHeight = '';
    }
    let dx = 280;
    this.cont1Y.style.height = dx + 'px';
    this.cont1Y1.style.cssText = 'height: ' + (dx - 125) + 'px !important;';
    dx = this.windowHeight - dx;
    this.cont2Y.style.height = dx - 90 + 'px';
    let extra = 0.218 * window.innerHeight;
    if (window.innerWidth < 1520) {
      extra = 220;
    }
    const cont2YHeight = dx - extra;
    this.cont2Y1.style.cssText = 'height: ' + cont2YHeight + 'px !important';
    this.cont2Y2.style.cssText = 'height: ' + cont2YHeight + 'px !important';
    this.splitterY.addEventListener('mousedown', this.spMouseDownY);
  }

  private spMouseDownY = (e: any) => {
    this.splitterY.removeEventListener('mousedown', this.spMouseDownY);
    window.addEventListener('mousemove', this.spMouseMoveY);
    window.addEventListener('mouseup', this.spMouseUpY);
    this.lastY = e.clientY;
  };

  private spMouseUpY = (e: any) => {
    window.removeEventListener('mousemove', this.spMouseMoveY);
    window.removeEventListener('mouseup', this.spMouseUpY);
    this.splitterY.addEventListener('mousedown', this.spMouseDownY);
    this.resetPositionY(this.lastY);
  };

  private spMouseMoveY = (e: any) => {
    this.resetPositionY(e.clientY);
  };

  private resetPositionY(nowY: any) {
    if (!nowY) {
      nowY = 0;
      this.lastY = 0;
    }
    let dx = nowY - this.lastY;
    dx += this.cont1Y.clientHeight;
    this.cont1Y.style.height = dx + 'px';
    this.cont1Y1.style.cssText = 'height: ' + (dx - 125) + 'px !important';
    dx += this.splitterY.clientHeight;
    dx = this.windowHeight - dx;
    this.cont2Y.style.height = dx - 90 + 'px';
    this.lastY = nowY;

    if (this.cont2Y) {
      let extra = 0.218 * window.innerHeight;
      if (window.innerWidth < 1520) {
        extra = 220;
      }
      const cont2YHeight = this.windowHeight - this.cont1Y.clientHeight - extra;
      this.cont1.style.height = cont2YHeight + 32 + 'px';
      this.cont2.style.height = cont2YHeight + 32 + 'px';

      this.cont2Y1.style.cssText = 'height: ' + cont2YHeight + 'px !important';
      this.cont2Y2.style.cssText = 'height: ' + cont2YHeight + 'px !important';
    }
  }

  private resetSplitter() {
    if (
      !this.splitter ||
      !this.splitterY ||
      !this.cont1 ||
      !this.cont2 ||
      !this.cont1Y ||
      !this.cont1Y1 ||
      !this.cont2Y ||
      !this.cont2Y1 ||
      !this.cont2Y2
    ) {
      return;
    }
    // Remove height
    this.cont1.style.height = '';
    this.cont2.style.height = '';
    this.cont1.style.width = '';
    this.cont2.style.width = '';
    this.cont1Y.style.height = '';
    this.cont1Y1.style.height = '';
    this.cont2Y.style.height = '';
    this.cont2Y1.style.height = '';
    this.cont2Y2.style.height = '';
  }
}
