import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterDateTimeGrid } from '../../common/filter-date-time-grid';
import { Global } from '../../common/global';
import { NumericTextBoxOptions } from '../../custom-controls/numeric-edit/numeric-text-box-options';
import {
  IRejectionInfo,
  ISimpleKeyValuePair,
  ITimeEntryRecord,
  ITimeEntryStatus,
  ITimeEntryType
} from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';

@Component({
  selector: 'app-company-approval-grid-detail',
  templateUrl: './company-approval-grid-detail.component.html',
  styleUrls: ['./company-approval-grid-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyApprovalGridDetailComponent implements OnInit, OnDestroy {
  @Input() public timeEntryRecords: any;
  @Input() public rowIndex: number;
  @Input() public employee: any;
  @Input() public currentTimeEntryStatus: ITimeEntryStatus;
  @Input() public TimeEntryTypes: ITimeEntryType[];

  private translationServiceTerms: string[];

  public selectedMinDate: Date;

  public UnitTypes: any[];
  public timeEntryRecord: any;

  public DefaultTimeEntryRecord: ITimeEntryRecord[];

  public allowManagersToApproveTimeEntry = false;
  public option: NumericTextBoxOptions = { min: 0, step: 1, spinners: false };
  public defaultTimeEntryType: any;

  // pageging for approval
  public currentpage = 0;

  public dimensionXValues: any[] = [];

  // Translation strings
  public PeriodWarningText: string;

  public AllText = 'All';
  public isSlect1020default = true;

  public TimeEntryRecord: ITimeEntryRecord[] = [];
  public currentTimeEntryRecord: ITimeEntryRecord;
  public TimeEntryStatus: ITimeEntryStatus[];

  public selectedTimeEntryStatusId: number;
  public timeEntryStatus: ITimeEntryStatus[];

  private filterEligibleforapproval: string;
  private selectedemployeeIdinGrid: number;
  public numberOption: any = { min: undefined, step: 1, spinners: false, decimals: 2, format: 'n2' };

  public get isHideDimension1(): boolean {
    return this.Dimension1Preference && !!this.Dimension1Preference.Value;
  }
  public get isHideDimension2(): boolean {
    return this.Dimension2Preference && !!this.Dimension2Preference.Value;
  }
  public get isHideDimension3(): boolean {
    return this.Dimension3Preference && !!this.Dimension3Preference.Value;
  }

  public get Dimension1Name(): string {
    if (!this.isHideDimension1) {
      return '';
    } else {
      const dimension1Object: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
        'Dimensions.Dimension1Name'
      );
      if (dimension1Object && dimension1Object.Value) {
        return dimension1Object.Value;
      }
    }

    return '1';
  }

  public get Dimension2Name(): string {
    if (!this.isHideDimension2) {
      return '';
    } else {
      const dimension2Object: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
        'Dimensions.Dimension2Name'
      );
      if (dimension2Object && dimension2Object.Value) {
        return dimension2Object.Value;
      }
    }

    return '2';
  }

  public get Dimension3Name(): string {
    if (!this.isHideDimension3) {
      return '';
    } else {
      const dimension3Object: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
        'Dimensions.Dimension3Name'
      );
      if (dimension3Object && dimension3Object.Value) {
        return dimension3Object.Value;
      }
    }

    return '3';
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  private get Dimension1Preference(): ISimpleKeyValuePair {
    return this.sessionService.getCompanyPreference('Dimensions.Dimension1Name');
  }

  private get Dimension2Preference(): ISimpleKeyValuePair {
    return this.sessionService.getCompanyPreference('Dimensions.Dimension2Name');
  }

  private get Dimension3Preference(): ISimpleKeyValuePair {
    return this.sessionService.getCompanyPreference('Dimensions.Dimension3Name');
  }

  public get allowManagersToApproveTimeEntryMessage(): string {
    if (!this.allowManagersToApproveTimeEntry) {
      return 'EmploymentTime.DisableGridTimeMessage';
    }
    return '';
  }

  public get TooltipFroApproButtonAprrovalGrid(): string {
    if (!this.allowManagersToApproveTimeEntry) {
      return this.allowManagersToApproveTimeEntryMessage;
    }
    return 'EmploymentTime.ApproveThisRecord';
  }

  constructor(
    public translateService: TranslateService,
    public dataService: DataService,
    public settingService: SettingService,
    public staticDataService: StaticDataService,
    public sessionService: SessionService
  ) {
    this.translationServiceTerms = [
      'EmploymentTime.PeriodWarning',
      'CompanySalaryBatches.SalaryCycle_Item_All_Text',
      'EmploymentTime.EligibleForApproval'
    ];

    this.UnitTypes = Global.UNIT_TYPES;
  }

  @Output() public openApprovalGridDetail: EventEmitter<any> = new EventEmitter<any>();
  public ngOnInit(): void {
    this.staticDataService.TimeEntryStatus.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (timeEntryStatus: ITimeEntryStatus[]) => {
        this.openApprovalGridDetail.emit();
        if (this.rowIndex === undefined || this.rowIndex === null) {
          this.timeEntryRecord = null;
        } else {
          let timeEntryRecordRaw = null;
          if (
            this.timeEntryRecords[this.rowIndex] &&
            this.timeEntryRecords[this.rowIndex].timeEntries &&
            this.timeEntryRecords[this.rowIndex].timeEntries[0].EmployeeName !== this.employee.EmployeeName
          ) {
            timeEntryRecordRaw = this.timeEntryRecords.find((timeEntry: any) => {
              return timeEntry.timeEntries[0].EmployeeName === this.employee.EmployeeName;
            });
          } else {
            timeEntryRecordRaw = this.timeEntryRecords[this.rowIndex];
          }

          this.timeEntryRecord = (timeEntryRecordRaw.timeEntries || []).filter((record: ITimeEntryRecord) => {
            if (this.currentTimeEntryStatus.Id === -2) {
              return this.checkIsCorrect(record.Status, 10) || this.checkIsCorrect(record.Status, 20);
            }
            return this.checkIsCorrect(record.Status, this.currentTimeEntryStatus.Id);
          });
        }
        this.timeEntryStatus = timeEntryStatus;
        if (!this.timeEntryStatus || (this.timeEntryStatus && this.timeEntryStatus.length === 0)) {
          return;
        }
        this.enableTranslation();
        const objectAllowManagersToApproveTimeEntry: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
          'Access.AllowManagersToApproveTimeEntry'
        );
        if (objectAllowManagersToApproveTimeEntry && objectAllowManagersToApproveTimeEntry.Value === 'true') {
          this.allowManagersToApproveTimeEntry = true;
        } else {
          if (this.sessionService.role.IsSalaryAdminAndHigher) {
            this.allowManagersToApproveTimeEntry = true;
          }
        }
      }
    );
  }

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private enableTranslation(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateService.get(this.translationServiceTerms).subscribe((translations: { [key: string]: string }) => {
        this.setTranslationTerms(translations);
      });
    });
  }

  private setTranslationTerms(translations: { [key: string]: string }): void {
    this.PeriodWarningText = translations['EmploymentTime.PeriodWarning'];
    this.AllText = translations['CompanySalaryBatches.SalaryCycle_Item_All_Text'];
    this.filterEligibleforapproval = translations['EmploymentTime.EligibleForApproval'];
  }

  private selectedItem: any;
  @Output() public selectedRowEmitter: EventEmitter<any> = new EventEmitter<any>();
  public onSelectRow(event: any): void {
    if (!event || event === undefined) {
      return;
    }
    this.selectedItem = event;
    this.selectedRowEmitter.emit(event);
  }

  public isShowRejectDialog = false;
  public rejectMessage: string;
  public isShowRejectDialogNonApproveTimeEntry = false;
  public preUserEmploymentId: number;
  public onGridButtonClick(event: any): void {
    if (event && event.buttonAction && event.buttonAction === 'Reject') {
      if (event.dataItem) {
        if (event.dataItem.isCanReject) {
          this.isShowRejectDialog = true;
          this.selectedemployeeIdinGrid = event.dataItem.Id;
          this.preUserEmploymentId = event.dataItem.UserEmploymentId;
        }
      }
    }
  }

  public onRejectDialog(event: any): void {
    if (event) {
      if (event === 'RejectPrimary') {
        const rejectionInfo: IRejectionInfo = { Reason: this.rejectMessage };
        this.dataService.TimeEntry_RejectTimeEntryRecord(this.selectedemployeeIdinGrid, rejectionInfo).subscribe(
          (): void => {
            this.stillSelectstatus = this.selectedTimeEntryStatusId;
            this.isSlect1020default = false;
            this.refreshTimeEntryList();
            this.selectedemployeeIdinGrid = undefined;
            this.rejectMessage = undefined;
          },
          (): void => {
            this.stillSelectstatus = this.selectedTimeEntryStatusId;
            this.isSlect1020default = false;
            this.refreshTimeEntryList();
            this.selectedemployeeIdinGrid = undefined;
            this.rejectMessage = undefined;
          }
        );
      } else {
        this.rejectMessage = undefined;
        this.selectedemployeeIdinGrid = this.preUserEmploymentId;
      }
    }
  }

  public stillSelectstatus: number;

  public EmptyEmployeetimeApproval(): string {
    if (this.timeEntryRecord && this.timeEntryRecord.length > 0) {
      return '';
    } else {
      return 'EmptydataGrid';
    }
  }

  public onClickAppGridIcon(event: any): void {
    if (
      event &&
      event.iconAction === 'ApproveSingleRecord' &&
      event.dataItem &&
      event.dataItem.Id &&
      event.dataItem.isCanSingleApprove
    ) {
      this.dataService.TimeEntry_ApproveTimeEntryRecord(event.dataItem.Id).subscribe((model: ITimeEntryRecord) => {
        this.isSlect1020default = false;
        this.refreshTimeEntryList();
      });
    }
  }

  @Output() public approveRejectData: EventEmitter<any> = new EventEmitter<any>();
  private refreshTimeEntryList() {
    this.approveRejectData.emit();
  }

  private checkIsCorrect(dataCompare: ITimeEntryStatus, compareValue: number): boolean {
    if (compareValue === -1) {
      return true;
    }
    return dataCompare.Id === compareValue;
  }

  public approvalFilter: CompositeFilterDescriptor;
  public onApprovalFilterChange(approvalFilter: CompositeFilterDescriptor): void {
    if (approvalFilter && approvalFilter.filters && approvalFilter.filters.length > 0) {
      const filterDateTimeGrid = new FilterDateTimeGrid();
      const filtersDate: any = [];
      // Using FilterDateTimeGrid to filter date before filter another field
      this.approvalFilter = filterDateTimeGrid.convertFilterOperator(
        approvalFilter,
        filtersDate,
        'EntryDate',
        'EndDate'
      );
      this.TimeEntryRecord = filterDateTimeGrid.doFilterDate(
        this.DefaultTimeEntryRecord,
        filtersDate,
        'EntryDate',
        'EndDate'
      );
      this.TimeEntryRecord = filterBy(this.TimeEntryRecord, this.approvalFilter);
      if (filtersDate.length > 0) {
        [].push.apply(this.approvalFilter.filters, filtersDate);
      }
    } else {
      this.TimeEntryRecord = this.DefaultTimeEntryRecord;
    }
  }
}
