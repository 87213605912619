import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
var EventEmitterService = /** @class */ (function () {
    function EventEmitterService() {
        this.invokeConfigurationToggleNavigation = new EventEmitter();
        this.invokeConfigurationGetMenuOpen = new EventEmitter();
    }
    EventEmitterService.prototype.callToggleNavigation = function () {
        this.invokeConfigurationToggleNavigation.emit();
    };
    EventEmitterService.prototype.getMenuOpen = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.invokeConfigurationGetMenuOpen.emit(this.isOpen)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EventEmitterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventEmitterService_Factory() { return new EventEmitterService(); }, token: EventEmitterService, providedIn: "root" });
    return EventEmitterService;
}());
export { EventEmitterService };
