import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html'
})
export class ButtonComponent {
  @Input() public tooltip = '';
  @Input() public label: string;
  @Input() public buttonClass: string;
  @Input() public isUseOneClick = false;
  @Input() public isDisableSubmitOnly = false;

  @Output() public clickEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(private sessionService: SessionService) {}

  private disabledValue = false;
  @Input()
  public get isDisabled(): boolean {
    return this.disabledValue || this.isStateNonClick;
  }

  public set isDisabled(value: boolean) {
    if (value) {
      this.disabledValue = value;
    } else {
      setTimeout(() => {
        this.disabledValue = value;
      });
    }
  }

  private get isStateNonClick(): boolean {
    if (this.isDisableSubmitOnly) {
      return this.sessionService.isSubmitting;
    }
    return this.sessionService.isGetting || this.sessionService.isSubmitting;
  }

  public onButtonClick(): void {
    if (this.isDisabled) {
      return;
    }

    this.clickEvent.emit();
  }
}
