/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./accountant.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./companies/accountant-companies.component.ngfactory";
import * as i3 from "./companies/accountant-companies.component";
import * as i4 from "./companies/accountant-companies.service";
import * as i5 from "../services/api/static-data.service";
import * as i6 from "../services/api/data.service";
import * as i7 from "../services/session/session.service";
import * as i8 from "@uirouter/core";
import * as i9 from "../services/setting.service";
import * as i10 from "../shared-components/report-dialog/report-dialog.service";
import * as i11 from "./users/accountant-users.component.ngfactory";
import * as i12 from "./users/accountant-users.component";
import * as i13 from "./users/accountant-users.service";
import * as i14 from "./configuration/accountant-configuration.component.ngfactory";
import * as i15 from "./configuration/accountant-configuration.component";
import * as i16 from "./configuration/accountant-configuration.service";
import * as i17 from "./summary/accountant-summary.service";
import * as i18 from "../custom-controls/tab-panel/tab-panel.component.ngfactory";
import * as i19 from "../custom-controls/tab-panel/tab-panel.component";
import * as i20 from "../common/router-state.service";
import * as i21 from "../services/fixed-panel.service";
import * as i22 from "@angular/common";
import * as i23 from "../custom-controls/module-check/module-check.component.ngfactory";
import * as i24 from "../custom-controls/module-check/module-check.component";
import * as i25 from "../services/authentication/authentication.service";
import * as i26 from "../custom-controls/pipes/price-format.pipe";
import * as i27 from "../shared-components/report-dialog/report-dialog.component.ngfactory";
import * as i28 from "../shared-components/report-dialog/report-dialog.component";
import * as i29 from "../services/download.service";
import * as i30 from "@ngx-translate/core";
import * as i31 from "./accountant.component";
import * as i32 from "../services/employee.service";
var styles_AccountantComponent = [i0.styles];
var RenderType_AccountantComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AccountantComponent, data: {} });
export { RenderType_AccountantComponent as RenderType_AccountantComponent };
function View_AccountantComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-accountant-companies", [], null, null, null, i2.View_AccountantCompaniesComponent_0, i2.RenderType_AccountantCompaniesComponent)), i1.ɵdid(1, 245760, null, 0, i3.AccountantCompaniesComponent, [i4.AccountantCompaniesService, i5.StaticDataService, i6.DataService, i5.StaticDataService, i7.SessionService, i6.DataService, i8.TransitionService, i9.SettingService, i10.ReportDialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AccountantComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-accountant-users", [], null, null, null, i11.View_AccountantUsersComponent_0, i11.RenderType_AccountantUsersComponent)), i1.ɵdid(1, 114688, null, 0, i12.AccountantUsersComponent, [i13.AccountantUsersService, i5.StaticDataService, i6.DataService, i7.SessionService, i8.TransitionService, i9.SettingService, i10.ReportDialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AccountantComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-accountant-configuration", [], null, null, null, i14.View_AccountantConfigurationComponent_0, i14.RenderType_AccountantConfigurationComponent)), i1.ɵdid(1, 114688, null, 0, i15.AccountantConfigurationComponent, [i16.AccountantConfigurationService, i17.AccountantSummaryService, i5.StaticDataService, i6.DataService, i7.SessionService, i8.TransitionService, i10.ReportDialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AccountantComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tab-panel", [], null, null, null, i18.View_TabPanelComponent_0, i18.RenderType_TabPanelComponent)), i1.ɵdid(1, 245760, null, 0, i19.TabPanelComponent, [i7.SessionService, i20.RouterStateService, i5.StaticDataService, i21.FixedPanelService], { parentTab: [0, "parentTab"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "Main"], ["tabindex", "-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickAreamenuToggledChange() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i22.ɵNgClassImpl, i22.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i22.NgClass, [i22.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { Mobile: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 7, "app-module-check", [], null, null, null, i23.View_ModuleCheckComponent_0, i23.RenderType_ModuleCheckComponent)), i1.ɵdid(8, 245760, null, 0, i24.ModuleCheckComponent, [i7.SessionService, i5.StaticDataService, i6.DataService, i25.AuthenticationService, i26.PriceFormatPipe], { moduleId: [0, "moduleId"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AccountantComponent_1)), i1.ɵdid(10, 16384, null, 0, i22.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AccountantComponent_2)), i1.ɵdid(12, 16384, null, 0, i22.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AccountantComponent_3)), i1.ɵdid(14, 16384, null, 0, i22.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-report-dialog", [], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.reportDialogService.reportDialogVisible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i27.View_ReportDialogComponent_0, i27.RenderType_ReportDialogComponent)), i1.ɵdid(16, 245760, null, 0, i28.ReportDialogComponent, [i5.StaticDataService, i6.DataService, i7.SessionService, i29.DownloadService, i30.TranslateService, i10.ReportDialogService], { param: [0, "param"], key: [1, "key"], visible: [2, "visible"] }, { visibleChange: "visibleChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "accountant"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "Main"; var currVal_2 = _ck(_v, 5, 0, _co.IsMobile); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = 20; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.state.currentStateIs("tabs.accountant.companies"); _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.state.currentStateIs("tabs.accountant.users"); _ck(_v, 12, 0, currVal_5); var currVal_6 = _co.state.currentStateIs("tabs.accountant.configuration"); _ck(_v, 14, 0, currVal_6); var currVal_7 = _co.reportDialogService.reportParams; var currVal_8 = _co.reportDialogService.key; var currVal_9 = _co.reportDialogService.reportDialogVisible; _ck(_v, 16, 0, currVal_7, currVal_8, currVal_9); }, null); }
export function View_AccountantComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-accountant", [], null, null, null, View_AccountantComponent_0, RenderType_AccountantComponent)), i1.ɵdid(1, 49152, null, 0, i31.AccountantComponent, [i7.SessionService, i20.RouterStateService, i10.ReportDialogService, i32.EmployeeService, i8.TransitionService], null, null)], null, null); }
var AccountantComponentNgFactory = i1.ɵccf("app-accountant", i31.AccountantComponent, View_AccountantComponent_Host_0, {}, {}, []);
export { AccountantComponentNgFactory as AccountantComponentNgFactory };
