import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public title: string;
  public message: string;
  public alertVisible: boolean;
  public isErrorModalMessage = false;
  public reloadAfterClose = false;
  public status: number;

  @Output() public modalServiceCloseErrorAlert: EventEmitter<boolean> = new EventEmitter<boolean>();

  public alert(
    title: string,
    message: string,
    isErrorModal?: boolean,
    reloadAfterClose?: boolean,
    status?: number
  ): void {
    this.title = title;
    this.message = message;
    this.alertVisible = true;
    this.isErrorModalMessage = isErrorModal;
    this.reloadAfterClose = reloadAfterClose;
    this.status = status || null;
  }

  public onClose(action: string) {
    if (action === 'Ok' || action === 'close') {
      this.modalServiceCloseErrorAlert.emit(true);
    }
    if (this.reloadAfterClose) {
      window.location.reload();
    }
  }
}
