<app-fixed-panel
  [excludedComponents]="listClassExcluded"
  [editMode]="companyTemplatesService.editMode"
  [specificClassName]="'Island-configuration'"
>
  <div
    class="company-configuration tabs Island Island-configuration company-preferences"
    [ngClass]="companyTemplatesService.editMode ? 'is-editing' : ''"
  >
    <div class="ConfigNav Navigation-wrapper Navigation Navigation-inner" [ngClass]="{ 'is-active': menuOpen }">
      <div class="Navigation-expandWrapper">
        <button class="Navigation-link Navigation-expand" (click)="onToggleNavigation()">
          <div>
            <div *ngIf="companyTemplateTabVisisble">
              {{ 'Company.EmploymentTemplates' | translate }}
            </div>
            <div *ngIf="companyDepartmentsTabVisible">
              {{ 'CompanyGeneral.Departments' | translate }}
            </div>
            <div *ngIf="companyintegrationsStateActive">
              {{ 'Company.ExternalIntegrations' | translate }}
            </div>
            <div *ngIf="companyDataImportStateActive">
              {{ 'Company.DataImport' | translate }}
            </div>
            <div *ngIf="companyOptionTabEnabled && companyOptionStateActive">
              {{ 'Preference.CompanyOption' | translate }}
            </div>

            <!-- <div *ngIf="companyAdvancedSalaryTypesStateActive">
            {{ 'Company.AdvancedSalaryTypes' | translate }}
          </div>

          <div *ngIf="companyTimeEntryTypesStateActive">
            {{ 'CompanyTimeEntryTypes.TimeEntryTypes' | translate }}
          </div> -->

            <div *ngIf="companyAdvancedTypesStateActive">
              {{ 'CompanyTimeEntryTypes.TimeAndSalaryTypes' | translate }}
            </div>
            <div *ngIf="companyDimensionsStateActive">
              {{ 'Company.Dimensions' | translate }}
            </div>

            <app-icon [icon]="'ChevronDown'" [size]="'xxsmall'"></app-icon>
          </div>
        </button>
      </div>
      <ul class="Navigation-list">
        <li class="Navigation-item">
          <app-button
            id="CompanyConfigurationTemplateTabButton"
            [isDisableSubmitOnly]="true"
            class="Navigation-link MainTabButton"
            (clickEvent)="navigateTo('tabs.company.configuration.hiringstatus')"
            [ngClass]="{ 'is-active': companyTemplateTabVisisble }"
            [label]="'Company.EmploymentTemplates' | translate"
          >
          </app-button>
        </li>
        <li class="Navigation-item">
          <app-button
            id="CompanyConfigurationDepartmentsTabButton"
            [isDisableSubmitOnly]="true"
            class="Navigation-link MainTabButton"
            (clickEvent)="navigateTo('tabs.company.configuration.departments')"
            [ngClass]="{ 'is-active': companyDepartmentsTabVisible }"
            [label]="'CompanyGeneral.Departments' | translate"
          >
          </app-button>
        </li>
        <li class="Navigation-item">
          <app-button
            id="CompanyConfigurationIntegrationsTabButton"
            [isDisableSubmitOnly]="true"
            class="Navigation-link MainTabButton"
            (clickEvent)="navigateTo('tabs.company.configuration.integrations')"
            [ngClass]="{ 'is-active': companyintegrationsStateActive }"
            [label]="'Company.ExternalIntegrations' | translate"
          >
          </app-button>
        </li>
        <li class="Navigation-item">
          <app-button
            *ngIf="companyDataImportTabEnabled"
            [isDisableSubmitOnly]="true"
            id="CompanyConfigurationDataImportTabButton"
            class="Navigation-link MainTabButton"
            (clickEvent)="navigateTo('tabs.company.configuration.dataimport')"
            [ngClass]="{ 'is-active': companyDataImportStateActive }"
            [label]="'Company.DataImport' | translate"
          >
          </app-button>
        </li>
        <li *ngIf="companyOptionTabEnabled" class="Navigation-item">
          <app-button
            id="CompanyConfigurationPreferencesTabButton"
            [isDisableSubmitOnly]="true"
            class="Navigation-link MainTabButton"
            (clickEvent)="navigateTo('tabs.company.configuration.settings')"
            [ngClass]="{ 'is-active': companyOptionStateActive }"
            [label]="'Preference.CompanyOption' | translate"
          >
          </app-button>
        </li>
        <!-- <li class="Navigation-item">
        <app-button
          id="CompanyConfigurationSalaryTypesTabButton"
          class="Navigation-link MainTabButton"
          (clickEvent)="navigateTo('tabs.company.configuration.advancedsalarytypes')"
          [ngClass]="{ 'is-active': companyAdvancedSalaryTypesStateActive }"
          [label]="'Company.AdvancedSalaryTypes' | translate"
        >
        </app-button>
      </li>
      <li class="Navigation-item">
        <app-button
          id="CompanyConfigurationTimeEntryTypesTabButton"
          class="Navigation-link MainTabButton"
          (clickEvent)="navigateTo('tabs.company.configuration.timeentrytypes')"
          [ngClass]="{ 'is-active': companyTimeEntryTypesStateActive }"
          [label]="'CompanyTimeEntryTypes.TimeEntryTypes' | translate"
        >
        </app-button>
      </li> -->
        <li class="Navigation-item">
          <app-button
            id="CompanyConfigurationAdvancedTypesTabButton"
            [isDisableSubmitOnly]="true"
            class="Navigation-link MainTabButton"
            (clickEvent)="navigateTo('tabs.company.configuration.advancedtypes')"
            [ngClass]="{ 'is-active': companyAdvancedTypesStateActive }"
            [label]="'CompanyTimeEntryTypes.TimeAndSalaryTypes' | translate"
          >
          </app-button>
        </li>
        <li class="Navigation-item">
          <app-module-check [moduleId]="4">
            <app-button
              id="CompanyConfigurationDimensionsTabButton"
              [isDisableSubmitOnly]="true"
              class="Navigation-link MainTabButton"
              (clickEvent)="navigateTo('tabs.company.configuration.dimensions')"
              [ngClass]="{ 'is-active': companyDimensionsStateActive }"
              [label]="'Company.Dimensions' | translate"
            >
            </app-button>
          </app-module-check>
        </li>
      </ul>
    </div>

    <!-- <div class="tab-content"> -->
    <app-templates *ngIf="companyTemplateTabVisisble"></app-templates>

    <app-company-department *ngIf="companyDepartmentsTabVisible"></app-company-department>

    <app-integration-tab *ngIf="companyintegrationsStateActive"> </app-integration-tab>

    <div *ngIf="companyDataImportStateActive">
      <app-data-import></app-data-import>
    </div>

    <app-preference *ngIf="companyOptionTabEnabled && companyOptionStateActive"></app-preference>

    <app-advanced-types *ngIf="companyAdvancedTypesStateActive"></app-advanced-types>

    <app-dimensions *ngIf="companyDimensionsStateActive"></app-dimensions>
  </div>
</app-fixed-panel>
<!-- </div> -->
