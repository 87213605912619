import * as tslib_1 from "tslib";
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { CompanyService } from '../../services/company.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/api/data.service";
import * as i2 from "../../services/api/static-data.service";
import * as i3 from "../../services/setting.service";
import * as i4 from "../../services/session/session.service";
import * as i5 from "@uirouter/core";
import * as i6 from "../../services/broadcast.service";
var ExtraVacationHoursService = /** @class */ (function (_super) {
    tslib_1.__extends(ExtraVacationHoursService, _super);
    function ExtraVacationHoursService(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) {
        var _this = _super.call(this, dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) || this;
        _this.dataService = dataService;
        _this.staticDataService = staticDataService;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.transitionService = transitionService;
        _this.broadcaster = broadcaster;
        _this.hideEmployeeNoRemainingBlance = true;
        _this.originalDataBalancesEmployeeViewByDate = [];
        _this.isFirstLoad = true;
        _this.effectivePer = _this.staticDataService.getCurrentdate();
        return _this;
    }
    Object.defineProperty(ExtraVacationHoursService.prototype, "BalancesEmployeeViewByDate", {
        get: function () {
            if (!this.BalancesEmployeeViewByDateSubject) {
                this.Balances_GetExtraVacationHours();
            }
            return this.BalancesEmployeeViewByDateSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    ExtraVacationHoursService.prototype.Balances_GetExtraVacationHours = function () {
        var _this = this;
        if (!this.BalancesEmployeeViewByDateSubject) {
            this.BalancesEmployeeViewByDateSubject = new BehaviorSubject([]);
        }
        if (!this.effectivePer) {
            return;
        }
        var request = {
            BalanceDefinitionId: 7,
            BalancePeriodId: null,
            PerDate: this.effectivePer
        };
        this.BalancesEmployeeViewByDateSubject.next([]);
        this.localBlanceApiService(request).subscribe(function (data) {
            _this.originalDataBalancesEmployeeViewByDate = data;
            _this.filterData();
        });
    };
    ExtraVacationHoursService.prototype.localBlanceApiService = function (request) {
        return this.dataService.Balances_GetBalances(request).pipe(tap(function (data) {
            data = data.map(function (item) {
                item.DaysEarned = item.DaysEarned + item.SecondaryDaysEarned;
                item.DaysSpent = item.DaysSpent + item.SecondaryDaysSpent;
                item.DaysTotal = item.DaysTotal + item.CurrentBalanceSecondaryDays;
                item.AmountTotal = item.AmountTotal + item.SecondaryAmountTotal;
                return item;
            });
        }));
    };
    ExtraVacationHoursService.prototype.Reload_Balances_GetExtraVacationHours = function () {
        var _this = this;
        if (!this.BalancesEmployeeViewByDateSubject) {
            this.BalancesEmployeeViewByDateSubject = new BehaviorSubject([]);
        }
        if (!this.effectivePer) {
            return;
        }
        var request = {
            BalanceDefinitionId: 7,
            BalancePeriodId: null,
            PerDate: this.effectivePer
        };
        this.localBlanceApiService(request).subscribe(function (data) {
            if (data && data.length > 0) {
                var arrayItemDirty_1 = [];
                if (_this.originalDataBalancesEmployeeViewByDate && _this.originalDataBalancesEmployeeViewByDate.length > 0) {
                    arrayItemDirty_1 = _this.originalDataBalancesEmployeeViewByDate.filter(function (item) { return item.isDirty === true; });
                }
                data.forEach(function (item) {
                    var originalItem = _this.originalDataBalancesEmployeeViewByDate.find(function (temp) { return item.UserEmploymentId === temp.UserEmploymentId; });
                    if (originalItem) {
                        // Update read only cell
                        originalItem.FullName = item.FullName;
                        originalItem.BasedOnTemplateName = item.BasedOnTemplateName;
                        originalItem.DepartmentName = item.DepartmentName;
                        originalItem.ExternalReference = item.ExternalReference;
                        originalItem.DaysEarned = item.DaysEarned;
                        originalItem.DaysSpent = item.DaysSpent;
                        originalItem.DaysTotal = item.DaysTotal;
                        originalItem.AmountTotal = item.AmountTotal;
                        originalItem.BaseAmount = item.BaseAmount || null;
                        originalItem.AmountEarned = item.AmountEarned || null;
                        originalItem.HoursEarned = item.HoursEarned || null;
                        originalItem.HoursSpent = item.HoursSpent || null;
                        originalItem.HoursTotal = item.HoursTotal || null;
                        originalItem.isDirty = false;
                        if (arrayItemDirty_1.find(function (tempModel) {
                            return tempModel.UserEmploymentId === originalItem.UserEmploymentId;
                        })) {
                            originalItem.isDirty = true;
                        }
                    }
                });
                _this.originalDataBalancesEmployeeViewByDate = data;
            }
            else {
                _this.originalDataBalancesEmployeeViewByDate = [];
            }
            _this.filterData();
        });
    };
    ExtraVacationHoursService.prototype.filterData = function () {
        var result = [];
        result = this.originalDataBalancesEmployeeViewByDate;
        if (this.hideEmployeeNoRemainingBlance) {
            result = result.filter(function (item) {
                return item.HoursTotal;
            });
        }
        this.BalancesEmployeeViewByDateSubject.next(result);
    };
    ExtraVacationHoursService.prototype.save = function (model) {
        var _this = this;
        this.dataService.Balances_AdjustBalances(model).subscribe(function (data) {
            _this.afterSaveSucess(data);
            _this.completeSave();
        }, function () {
            setTimeout(function () {
                _this.completeSave();
            }, 1000);
        }, function () {
            // this.completeSave();
        });
    };
    Object.defineProperty(ExtraVacationHoursService.prototype, "allowShortcut", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.balances.extravacationhours';
        },
        enumerable: true,
        configurable: true
    });
    ExtraVacationHoursService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExtraVacationHoursService_Factory() { return new ExtraVacationHoursService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.StaticDataService), i0.ɵɵinject(i3.SettingService), i0.ɵɵinject(i4.SessionService), i0.ɵɵinject(i5.TransitionService), i0.ɵɵinject(i6.BroadcastService)); }, token: ExtraVacationHoursService, providedIn: "root" });
    return ExtraVacationHoursService;
}(CompanyService));
export { ExtraVacationHoursService };
