<div
  class="FormElement CheckBoxPadding"
  [ngClass]="{
    'FormElement-ViewMode': !editMode,
    'FormElement-noHeight ': isGrid,
    textOpenLinkInTitle: openLinkInTitle
  }"
>
  <span *ngIf="isAccountantConfig" kendoTooltip class="FormElement-label keepIconInline"> </span>
  <div class="FormElement-control" [ngClass]="customClass">
    <label
      kendoTooltip
      [title]="tooltip"
      class="FormElement-checkbox {{ selfService }} {{ disable ? 'check-box-disable' : '' }}"
      [ngClass]="labelClass"
    >
      <input type="checkbox" [(ngModel)]="value" checked="value" [disabled]="isDisabled" (click)="onClick($event)" />
      <span
        class="FormElement-checkboxFaux"
        [ngClass]="{ 'FormElement-checkboxFaux--line': half && !AppliesHalfCheckbox }"
      ></span>
      <span *ngIf="label && !isLink" class="FormElement-label" kendoTooltip [title]="helpTooltip"
        ><span (click)="onOpenlinkInTitleClick()" [ngClass]="{ textWithTooltip: helpTooltip }">{{ label }}</span>
        <app-icon
          *ngIf="helpLink"
          class="infoIcon"
          [icon]="helpIcon"
          [tooltip]="helpTooltip"
          [link]="helpLink"
          [isOpenLink]="isOpenLink"
          [tooltipWidth]="340"
          [istooltipHTML]="true"
        ></app-icon>
      </span>
      <span *ngIf="label && isLink" [innerHTML]="label | translate">
        <app-icon
          *ngIf="helpLink"
          class="infoIcon"
          [icon]="helpIcon"
          [tooltip]="helpTooltip"
          [link]="helpLink"
          [isOpenLink]="isOpenLink"
          [tooltipWidth]="340"
          [istooltipHTML]="true"
        ></app-icon>
      </span>
    </label>
  </div>
</div>
