import { EventEmitter, OnDestroy } from '@angular/core';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterDateTimeGrid } from '../../../common/filter-date-time-grid';
import { SalaryBatchStatusEnum } from '../../../model/enum';
import { SessionService } from '../../../services/session/session.service';
import { CompanySalaryBatchService } from '../company-salary-batches.service';
var SalaryBatchesComponent = /** @class */ (function () {
    function SalaryBatchesComponent(sessionService, companySalaryBatchService) {
        var _this = this;
        this.sessionService = sessionService;
        this.companySalaryBatchService = companySalaryBatchService;
        this.salaryCyclesValue = [];
        this.salaryBatchesValue = [];
        this.editSalaryBatch = new EventEmitter();
        this.showErrors = new EventEmitter();
        this.recalculateDraft = new EventEmitter();
        this.newSalaryBatch = new EventEmitter();
        this.finalizeDraft = new EventEmitter();
        this.submitForApproval = new EventEmitter();
        this.gridData = [];
        this.triggerUpdate = false;
        this.ngUnsubscribe = new Subject();
        this.gridFilterData = this.gridData;
        this.companySalaryBatchService.selectedSalaryBatch
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            _this.salaryBatchValue = value;
        });
        this.companySalaryBatchService.salaryBatchEntityContext
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            _this.salaryBatchContext = value;
        });
    }
    Object.defineProperty(SalaryBatchesComponent.prototype, "salaryCycles", {
        get: function () {
            return this.salaryCyclesValue;
        },
        set: function (value) {
            if (this.salaryCyclesValue !== value) {
                this.salaryCyclesValue = value;
                this.filterData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchesComponent.prototype, "salaryBatches", {
        get: function () {
            return this.salaryBatchesValue;
        },
        set: function (value) {
            if (this.salaryBatchesValue !== value) {
                this.salaryBatchesValue = value;
                this.filterData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchesComponent.prototype, "selectedBatch", {
        get: function () {
            return this.salaryBatchValue;
        },
        set: function (value) {
            this.companySalaryBatchService.setNextValueSelectedSalaryBatch(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchesComponent.prototype, "salaryCycleId", {
        get: function () {
            return this.salaryCycleIdValue;
        },
        set: function (value) {
            if (this.salaryCycleIdValue !== value) {
                this.salaryCycleIdValue = value;
                this.filterData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchesComponent.prototype, "isReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchesComponent.prototype, "isAddNewBatchButtonVisible", {
        get: function () {
            return this.sessionService.feature.AllowEditCompanyData(this.sessionService.currentState) && !this.isReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchesComponent.prototype, "isDraft", {
        get: function () {
            return this.selectedBatch && this.selectedBatch.StatusId === SalaryBatchStatusEnum.Draft;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchesComponent.prototype, "isLocked", {
        get: function () {
            return this.selectedBatch && this.selectedBatch.StatusId === SalaryBatchStatusEnum.ForApproval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchesComponent.prototype, "isPreliminary", {
        get: function () {
            return this.selectedBatch && this.selectedBatch.StatusId === SalaryBatchStatusEnum.ForApproval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchesComponent.prototype, "isPaymentApprover", {
        get: function () {
            return this.sessionService.role.IsPaymentApprover;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchesComponent.prototype, "isShowApprovalButton", {
        get: function () {
            if (window.innerWidth <= 550) {
                return false;
            }
            else {
                return (this.sessionService.role.IsSalaryAdminAndHigher &&
                    this.salaryBatchContext &&
                    this.salaryBatchContext.BatchId > 0 &&
                    this.salaryBatchContext.IsDraft);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchesComponent.prototype, "isShowApprovalButtonNonScreenCheck", {
        get: function () {
            return (this.sessionService.role.IsSalaryAdminAndHigher &&
                this.salaryBatchContext &&
                this.salaryBatchContext.BatchId > 0 &&
                this.salaryBatchContext.IsDraft);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchesComponent.prototype, "isDisableApproval", {
        get: function () {
            return !this.sessionService.feature.hasModuleId(3);
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchesComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    SalaryBatchesComponent.prototype.onSalaryBatchSelectionChanged = function (data) {
        this.companySalaryBatchService.setNextValueSelectedSalaryBatch(data);
    };
    SalaryBatchesComponent.prototype.onDoubleClick = function (dataItem) {
        if (dataItem) {
            this.selectedBatch = dataItem;
            this.editSalaryBatch.emit(dataItem);
        }
    };
    SalaryBatchesComponent.prototype.onGridIconClick = function (event) {
        switch (event.iconAction) {
            case 'ShowErrors':
                if (event.dataItem.HasErrors || event.dataItem.HasWarnings) {
                    this.showErrors.emit(event.dataItem);
                }
                break;
            case 'Edit':
                this.selectedBatch = event.dataItem;
                this.editSalaryBatch.emit(event.dataItem);
                break;
        }
    };
    SalaryBatchesComponent.prototype.truncatedOrWrapped = function () {
        return !this.sessionService.role.IsReadOnly ? 'truncated' : 'wrapped';
    };
    SalaryBatchesComponent.prototype.filterData = function () {
        var _this = this;
        if (this.salaryCycleId) {
            this.gridData = this.salaryBatches.filter(function (s) { return s.SalaryCycleId === _this.salaryCycleId; });
        }
        else {
            this.gridData = this.salaryBatches;
        }
        this.gridFilterData = filterBy(this.gridData, this.filter);
        if (this.gridData && this.gridData.length > 0) {
            if (!this.selectedBatch) {
                this.selectedBatch = this.gridData[0];
            }
            else {
                if (!this.gridData.some(function (d) { return d.Id === _this.selectedBatch.Id; })) {
                    this.selectedBatch = this.gridData[0];
                }
            }
        }
        else {
            this.selectedBatch = null;
        }
        this.triggerUpdate = true;
    };
    SalaryBatchesComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        if (filter && filter.filters && filter.filters.length > 0) {
            var filterDateTimeGrid = new FilterDateTimeGrid();
            var filtersDate = [];
            this.filter = filterDateTimeGrid.convertFilterOperator(filter, filtersDate, 'PeriodStartDate', 'PeriodEndDate', 'PayoutDate');
            this.gridFilterData = filterDateTimeGrid.doFilterDate(this.gridData, filtersDate, 'PeriodStartDate', 'PeriodEndDate', 'PayoutDate');
            this.gridFilterData = filterBy(this.gridFilterData, this.filter);
            if (filtersDate.length > 0) {
                [].push.apply(this.filter.filters, filtersDate);
            }
        }
        else {
            this.gridFilterData = this.gridData;
        }
    };
    SalaryBatchesComponent.prototype.onSalaryBatchDialogAction = function (action) { };
    SalaryBatchesComponent.prototype.onClickButton = function (action) {
        if (this.sessionService.isGetting || this.sessionService.isSubmitting) {
            return;
        }
        switch (action) {
            case 'Recalculate':
                this.recalculateDraft.emit();
                break;
            case 'Submit':
                this.submitForApproval.emit();
                break;
            case 'Finalize':
                this.finalizeDraft.emit();
                break;
            case 'NewSalaryBatch':
                this.newSalaryBatch.emit();
                break;
        }
    };
    return SalaryBatchesComponent;
}());
export { SalaryBatchesComponent };
