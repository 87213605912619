import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Browser } from '../../common/browser';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { StaticDataService } from '../../services/api/static-data.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { BroadcastService } from '../../services/broadcast.service';
import { ModalService } from '../../services/modal.service';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-relogin-dialog',
  templateUrl: './relogin-dialog.component.html',
  styleUrls: ['./relogin-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReloginDialogComponent implements OnInit, OnDestroy {
  public message: string;
  public errorMessage: string;
  public password = '';
  public Unauthorized: string;
  public WrongUsernameOrPassword: string;
  public SystemClosed: string;
  public networkErrorMessage: string;
  // public visible: boolean;
  public userName: string;
  public changeTemporaryPasswordVisible = false;

  private isLoggingIn = false;

  private visibleValue = false;
  public get visible(): boolean {
    return this.visibleValue;
  }

  public set visible(val: boolean) {
    this.visibleValue = val;
    if (val) {
      this.modalService.alertVisible = false;
      if (this.ngxZendeskWebwidgetService && typeof this.ngxZendeskWebwidgetService.zE === 'function') {
        this.ngxZendeskWebwidgetService.zE('webWidget', 'close');
      }
    }
  }

  public get branding() {
    return environment.branding;
  }

  constructor(
    public translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private broadcaster: BroadcastService,
    private sessionService: SessionService,
    public modalService: ModalService,
    public staticDataService: StaticDataService,
    private ngxZendeskWebwidgetService: NgxZendeskWebwidgetService
  ) {
    this.setupEventHandlers();
  }

  public ngOnInit(): void {
    this.userName = localStorage.getItem('saveLoginUserName');
    this.translateText();
  }

  public get isNullPassWord(): boolean {
    if (!this.password) {
      return true;
    }
    return false;
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public translateText(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translateService
        .get(['Error.NetworkError', 'Login.WrongUserNameOrPassword'])
        .subscribe((translations: { [key: string]: string }) => {
          this.networkErrorMessage = translations['Error.NetworkError'].replace(
            '{{SystemAlias}}',
            environment.branding.SystemAlias
          );
          this.WrongUsernameOrPassword = translations['Login.WrongUserNameOrPassword'];
          this.SystemClosed = translations['Messages.SystemClosed'];
        });
    });
  }

  private setupEventHandlers(): void {
    this.broadcaster
      .on(Constants.SESSION_EXPIRED)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.visible = true;
        this.sessionService.checkIsAliveError = true;
      });

    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e: string) => {});
  }

  keydown(e: any): void {
    if (e.keyCode === 13) {
      this.login();
    } else {
      this.errorMessage = undefined;
    }
  }

  logout(): void {
    this.authenticationService
      .logout()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        () => {
          this.navigateToLogin();
        },
        () => {
          this.navigateToLogin();
        }
      );
  }

  private navigateToLogin(): void {
    this.sessionService.NavigateTo('login');
  }

  public get IsDanishCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
  }

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public get IsMobile(): boolean {
    return this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
  }

  private reloadStaticData(): void {
    this.staticDataService.loadStaticData().then(() => {
      let isAvailableTab = false;
      const currentState = this.sessionService.currentState;
      this.sessionService.refeshRoleAndFeature();

      if (currentState) {
        isAvailableTab = this.sessionService.isAvailableTab(
          currentState,
          this.IsDanishCompany,
          this.IsGreenlandCompany
        );
      }

      if (!isAvailableTab) {
        this.sessionService.switchToDefaultTab();
      }
      setTimeout(() => {
        this.close();
        window.location.reload();
      });
    });
  }

  login(): any {
    if (this.isLoggingIn) {
      return;
    }

    this.isLoggingIn = true;
    this.authenticationService.credentials = {
      Username: Global.SESSION ? Global.SESSION.SignOnToken.Username : '',
      Password: this.password ? this.password : '',
      Remember: false
    };

    let message: string;
    this.authenticationService
      .login()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response: any) => {
          const mustChangePassword: any = response.MustChangePassword;
          if (mustChangePassword) {
            this.broadcaster.broadcast(Constants.ACCOUNT_CHANGE_TEMPORARY_PASSWORD, this.password);
            this.changeTemporaryPasswordVisible = true;
            this.isLoggingIn = false;
            this.close();
          } else {
            this.reloadStaticData();
          }
        },
        (reason: any) => {
          // Failure
          switch (reason.status) {
            case 0:
              message = this.networkErrorMessage;
              break;
            case 401:
              message = this.WrongUsernameOrPassword;
              break;
            case 400:
              if (!reason || !reason.data) {
                message = this.SystemClosed;
                break;
              }
              message = reason.data.Message;
              break;
            case 500:
              // Internal server error
              let errorMessage: string = reason.statusText;
              if (reason.config && reason.config.url) {
                errorMessage += ' - ' + reason.config.url;
              }
              Sentry.withScope((scope) => {
                scope.setExtra('extra', reason);
                Sentry.captureException(new Error(errorMessage));
              });
              break;
            default:
              let othermessage: string = reason.statusText;
              if (reason.config && reason.config.url) {
                othermessage += ' - ' + reason.config.url;
              }
              Sentry.withScope((scope) => {
                scope.setExtra('extra', reason);
                Sentry.captureException(new Error(othermessage));
              });
              break;
          }

          this.errorMessage = message;
          console.error('Login failed: ');
          console.error(reason);

          this.isLoggingIn = false;
        }
      );
  }

  private close(): void {
    this.visible = false;
  }

  public show: boolean;
  public showpassword(isShow?: boolean): void {
    if (isShow) {
      this.show = isShow;
    } else {
      this.show = false;
    }
  }

  public showResetPasswordDialog = false;
  onResetPassword(): void {
    this.showResetPasswordDialog = true;
  }

  public onDialogAction(action: string): void {
    if (action === 'Continue') {
      this.login();
    } else {
      this.logout();
    }
  }

  public get isMobile(): boolean {
    const browser: Browser = new Browser();
    return browser.isMobile;
  }

  public showPaswordForMobile(): void {
    if (this.isMobile) {
      this.show = true;
    }
  }

  public onTemporaryPasswordChanged(newPassword: string): void {
    this.password = newPassword;
    this.authenticationService.credentials.Password = this.password;
    this.login();
  }

  public onNotChangeTempPassword(): void {
    this.logout();
  }
}
