import { AfterViewInit, Component, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FixedPanelService } from 'src/app/services/fixed-panel.service';

@Component({
  selector: 'app-fixed-panel',
  templateUrl: './fixed-panel.component.html',
  styleUrls: ['./fixed-panel.component.scss']
})
export class FixedPanelComponent implements AfterViewInit, OnChanges {
  @Input() excludedComponents: [] = [];
  @Input() specificClassName: string;
  @Input() editMode = false;
  @Input() hideFooter = false;

  constructor(private fixedPanelService: FixedPanelService) {}

  ngOnChanges(changes: SimpleChanges) {
    const editModeChange = changes['editMode'];
    if (editModeChange && editModeChange.currentValue !== editModeChange.previousValue) {
      setTimeout(() => {
        this.fixedPanelService.calculateHeightContent(editModeChange.currentValue);
      }, 200);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.fixedPanelService.excludedComponents = this.excludedComponents;
    this.fixedPanelService.specificClassName = this.specificClassName;
    this.fixedPanelService.calculateHeightContent();
  }

  ngAfterViewInit() {
    this.fixedPanelService.excludedComponents = this.excludedComponents;
    this.fixedPanelService.specificClassName = this.specificClassName;
    // Wait 1s to load layout first
    if (this.specificClassName === 'Island-employee-list') {
      this.fixedPanelService.calculateHeightContent();
    }
    setTimeout(() => {
      this.fixedPanelService.calculateHeightContent();
    }, 1000);
  }
}
