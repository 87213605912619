import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'price-summary',
  templateUrl: './price-summary.component.html'
})
export class PriceSummaryComponent {
  @Input() public submitVisible: boolean;
  @Input() public submitEnabled: boolean;
  @Input() public priceElements: string[] = [];

  @Input() public pricePerPayslip: number;

  private totalCostValue = 0;
  @Input()
  public get totalCost(): number {
    return this.totalCostValue;
  }
  public set totalCost(value: number) {
    if (this.totalCostValue !== value) {
      this.totalCostValue = value;
      this.changing = true;
      clearTimeout(this.costChangeTimeout);
      this.costChangeTimeout = setTimeout((): void => {
        this.changing = false;
      }, 300);
    }
  }

  private usersMonthlyValue = 0;
  @Input()
  public get usersMonthly(): number {
    return this.usersMonthlyValue;
  }
  public set usersMonthly(value: number) {
    if (this.usersMonthlyValue !== value) {
      this.usersMonthlyValue = value ? value : 0;
      this.usersMonthlyChange.emit(this.usersMonthlyValue);
    }
    this.change.emit();
  }
  @Output() public usersMonthlyChange: EventEmitter<number> = new EventEmitter<number>();

  private users14DayValue = 0;
  @Input()
  public get users14Day(): number {
    return this.users14DayValue;
  }
  public set users14Day(value: number) {
    if (this.users14DayValue !== value) {
      this.users14DayValue = value ? value : 0;
      this.users14DayChange.emit(this.users14DayValue);
      this.change.emit();
    }
  }
  @Output() public users14DayChange: EventEmitter<number> = new EventEmitter<number>();

  private usersWeeklyValue = 0;
  @Input()
  public get usersWeekly(): number {
    return this.usersWeeklyValue;
  }
  public set usersWeekly(value: number) {
    if (this.usersWeeklyValue !== value) {
      this.usersWeeklyValue = value ? value : 0;
      this.usersWeeklyChange.emit(this.usersWeeklyValue);
      this.change.emit();
    }
  }
  @Output() public usersWeeklyChange: EventEmitter<number> = new EventEmitter<number>();

  @Output() public change: EventEmitter<void> = new EventEmitter<void>();
  @Output() public submit: EventEmitter<void> = new EventEmitter<void>();

  public changing = false;

  private costChangeTimeout: number;

  public get totalEmployees(): number {
    return (
      parseInt(this.usersMonthly ? this.usersMonthly.toString() : '0', 10) +
      parseInt(this.users14Day ? this.users14Day.toString() : '0', 10) +
      parseInt(this.usersWeekly ? this.usersWeekly.toString() : '0', 10)
    );
  }

  public get totalPayslips(): number {
    return (
      parseInt(this.usersMonthly ? this.usersMonthly.toString() : '0', 10) +
      parseInt(this.users14Day ? this.users14Day.toString() : '0', 10) * 2.17 +
      parseInt(this.usersWeekly ? this.usersWeekly.toString() : '0', 10) * 4.33
    );
  }

  public submitEvent(): void {
    if (this.submitEnabled) {
      this.submit.emit();
    }
  }
}
