import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WalkthroughComponent, WalkthroughText } from 'angular-walkthrough';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICompanyUser } from 'src/app/services/api/api-model';
import { EmployeeService } from 'src/app/services/employee.service';
import { SettingService } from 'src/app/services/setting.service';
import { environment } from 'src/environments/environment';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { EventEmitterService } from '../../services/event-emitter.service';
import { SessionService } from '../../services/session/session.service';
var WalkThroughGSComponent = /** @class */ (function () {
    function WalkThroughGSComponent(translateService, sessionService, eventEmitService, settingService, employeeService) {
        var _this = this;
        this.translateService = translateService;
        this.sessionService = sessionService;
        this.eventEmitService = eventEmitService;
        this.settingService = settingService;
        this.employeeService = employeeService;
        this.LanguageId = 1;
        this.welcomeTitles = {};
        this.welcomeMessages = {};
        this.welcomeTexts = {};
        this.config = {};
        this.isNavigateToState = false;
        this.timeout = 200;
        this.isHasEmployments = null;
        this.isHasSelfService = true;
        this.isHasModules = true;
        this.listener = [];
        this.ngUnsubscribe = new Subject();
        this.wktElement = null;
        this.listener.push(WalkthroughComponent.onNavigateNext.subscribe(function () {
            if (_this.nextState) {
                _this.navigateToState(_this.nextState, 1);
                _this.isNavigateToState = true;
                setTimeout(function () {
                    _this.onShowWelcomeMessage();
                });
            }
        }), WalkthroughComponent.onNavigatePrevious.subscribe(function () {
            if (_this.prevState) {
                _this.navigateToState(_this.prevState, -1);
                _this.isNavigateToState = true;
                setTimeout(function () {
                    _this.onShowWelcomeMessage();
                });
            }
        }), WalkthroughComponent.onOpen.subscribe(function () {
            _this.disableWalkthroughButton();
        }));
    }
    Object.defineProperty(WalkThroughGSComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WalkThroughGSComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    WalkThroughGSComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setupEventHandlers();
        setTimeout(function () {
            _this.LanguageId = Global.SESSION ? Global.SESSION.SignOnToken.LanguageId : 1;
            _this.translateApplicationText();
            _this.isHasSelfService = !_this.isHideSelfService;
            _this.isHasModules =
                _this.sessionService.role.IsFullAdmin &&
                    !_this.IsGreenlandCompany &&
                    !_this.sessionService.role.EmployeeWithRoleAsManager;
        }, 1000);
        this.updateWalkthroughState();
    };
    WalkThroughGSComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        for (var _i = 0, _a = this.listener; _i < _a.length; _i++) {
            var list = _a[_i];
            if (list) {
                list.unsubscribe();
            }
        }
        if (this.wktElement) {
            this.wktElement.removeEventListener('click', this.clickPeventDefault, false);
        }
    };
    WalkThroughGSComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            var onShowDialog = document.querySelectorAll('.blue-dialog kendo-dialog.ng-star-inserted');
            if (_this.settingService.moduleMessageDialogVisible &&
                onShowDialog.length <= 0 &&
                !_this.settingService.IsShowUpdateNotification) {
                _this.onShowWelcomeMessage();
                _this.settingService.moduleMessageDialogVisible = false;
            }
        }, 1000);
    };
    WalkThroughGSComponent.prototype.setupEventHandlers = function () {
        var _this = this;
        this.sessionService.OnLocationChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (state) {
            _this.state = state;
        });
    };
    WalkThroughGSComponent.prototype.updateWalkthroughState = function () {
        switch (this.state) {
            case 'tabs.employee.general':
                this.prevState = null;
                this.nextState = 'tabs.employee.hiringstatus';
                break;
            case 'tabs.employee.hiringstatus':
                this.prevState = 'tabs.employee.general';
                this.nextState = 'tabs.employee.time';
                break;
            case 'tabs.employee.time':
                this.prevState = 'tabs.employee.hiringstatus';
                this.nextState = 'tabs.employee.payrolldata';
                break;
            case 'tabs.employee.payrolldata':
                this.prevState = 'tabs.employee.time';
                this.nextState = 'tabs.employee.payslip';
                break;
            case 'tabs.employee.payslip':
                this.prevState = 'tabs.employee.payrolldata';
                this.nextState = 'tabs.company.general';
                break;
            case 'tabs.company.general':
                this.prevState = 'tabs.employee.payslip';
                this.nextState = 'tabs.company.salarybatches';
                break;
            case 'tabs.company.payrolldata':
                break;
            case 'tabs.company.salarybatches':
                this.prevState = 'tabs.company.general';
                this.nextState = 'tabs.company.approval.timeentry';
                break;
            case 'tabs.company.approval.timeentry':
                this.prevState = 'tabs.company.salarybatches';
                this.nextState = 'tabs.company.configuration.hiringstatus';
                break;
            case 'tabs.company.configuration.hiringstatus':
                this.prevState = 'tabs.company.approval.timeentry';
                this.nextState = 'tabs.company.configuration.integrations';
                break;
            case 'tabs.company.configuration.integrations':
                this.prevState = 'tabs.company.configuration.hiringstatus';
                this.nextState = 'tabs.company.configuration.advancedsalarytypes';
                break;
            case 'tabs.company.configuration.advancedsalarytypes':
                this.prevState = 'tabs.company.configuration.integrations';
                this.nextState = 'tabs.company.configuration.timeentrytypes';
                break;
            case 'tabs.company.configuration.timeentrytypes':
                this.prevState = 'tabs.company.configuration.advancedsalarytypes';
                this.nextState = 'tabs.company.modules';
                break;
            case 'tabs.company.modules':
                this.prevState = 'tabs.company.configuration.timeentrytypes';
                this.nextState = 'tabs.selfservice.payslip';
                break;
            case 'tabs.selfservice.payslip':
                this.prevState = 'tabs.company.modules';
                this.nextState = 'tabs.selfservice.general';
                break;
            case 'tabs.selfservice.general':
                this.prevState = 'tabs.selfservice.payslip';
                this.nextState = 'tabs.selfservice.time';
                break;
            case 'tabs.selfservice.time':
                this.prevState = 'tabs.selfservice.general';
                this.nextState = null;
                break;
        }
    };
    WalkThroughGSComponent.prototype.openWalkthrough = function (buttonWidth, buttonLeft, tab) {
        var _this = this;
        var size = 0;
        if (window.innerWidth < 400) {
            size = 37;
        }
        if (window.innerWidth >= 400 && window.innerWidth <= 950) {
            size = 40;
            if (window.innerWidth > 479) {
                size += 5;
            }
        }
        if (this.state.indexOf('tabs.company.configuration') >= 0) {
            this.eventEmitService.getMenuOpen();
            var isOpen = this.eventEmitService.isOpen;
            if (!isOpen) {
                this.eventEmitService.callToggleNavigation();
            }
        }
        setTimeout(function () {
            tab.open();
            var backdrop = document.querySelectorAll('.wkt-zone.backdrop')[0];
            var extraLeft = 0;
            if (backdrop && buttonLeft >= 0 && size > 0) {
                backdrop.style.width = buttonWidth + 'px';
                if (window.innerWidth >= 920) {
                    extraLeft += 205;
                    buttonLeft -= 1;
                }
                backdrop.style.left = buttonLeft * size + extraLeft + 'px';
            }
            if (!_this.wktElement) {
                setTimeout(function () {
                    _this.wktElement = document.getElementsByClassName('wkt-content-block')[0];
                    if (_this.wktElement) {
                        _this.wktElement.addEventListener('click', _this.clickPeventDefault, false);
                    }
                });
            }
            setTimeout(function () {
                _this.enableWalkthroughButton();
            }, 1000);
        }, this.timeout);
    };
    WalkThroughGSComponent.prototype.clickPeventDefault = function (e) {
        e.preventDefault();
        e.stopPropagation();
    };
    WalkThroughGSComponent.prototype.onShowWelcomeMessage = function () {
        if (this.isNavigateToState) {
            this.timeout = 1000;
        }
        else {
            this.timeout = 200;
        }
        this.isNavigateToState = false;
        var button = document.querySelectorAll('button.TabPanel-link.is-active')[0];
        var buttonWidth = 0;
        if (button) {
            buttonWidth = button.getBoundingClientRect().width;
        }
        switch (this.state) {
            case 'tabs.employee.general':
                this.openWalkthrough(buttonWidth, 1, this.EmployeeGeneralTab);
                this.prevState = null;
                this.nextState = 'tabs.employee.hiringstatus';
                break;
            case 'tabs.employee.hiringstatus':
                this.openWalkthrough(buttonWidth, 2, this.EmployeeHiringStatusTab);
                this.prevState = 'tabs.employee.general';
                this.nextState = 'tabs.employee.time';
                break;
            case 'tabs.employee.time':
                this.openWalkthrough(buttonWidth, 3, this.EmployeeTimeTab);
                this.prevState = 'tabs.employee.hiringstatus';
                this.nextState = 'tabs.employee.payrolldata';
                break;
            case 'tabs.employee.payrolldata':
                this.openWalkthrough(buttonWidth, 4, this.EmployeePayrollDataTab);
                this.prevState = 'tabs.employee.time';
                this.nextState = 'tabs.employee.payslip';
                break;
            case 'tabs.employee.payslip':
                this.openWalkthrough(buttonWidth, 5, this.EmployeePayslipTab);
                this.prevState = 'tabs.employee.payrolldata';
                this.nextState = 'tabs.company.general';
                break;
            case 'tabs.company.general':
                this.openWalkthrough(buttonWidth, 0, this.CompanyGeneralTab);
                this.prevState = 'tabs.employee.payslip';
                this.nextState = 'tabs.company.salarybatches';
                break;
            case 'tabs.company.payrolldata':
                break;
            case 'tabs.company.salarybatches':
                this.openWalkthrough(buttonWidth, 2, this.CompanySalaryBatchesTab);
                this.prevState = 'tabs.company.general';
                this.nextState = 'tabs.company.approval.timeentry';
                break;
            case 'tabs.company.approval.timeentry':
                this.openWalkthrough(buttonWidth, 3, this.CompanyApprovalTab);
                this.prevState = 'tabs.company.salarybatches';
                this.nextState = 'tabs.company.configuration.hiringstatus';
                break;
            case 'tabs.company.configuration.hiringstatus':
                this.openWalkthrough(buttonWidth, -1, this.CompanyConfigurationTemplateTab);
                this.prevState = 'tabs.company.approval.timeentry';
                this.nextState = 'tabs.company.configuration.integrations';
                break;
            case 'tabs.company.configuration.integrations':
                this.openWalkthrough(buttonWidth, -1, this.CompanyConfigurationIntegrationsTab);
                this.prevState = 'tabs.company.configuration.hiringstatus';
                this.nextState = 'tabs.company.configuration.advancedsalarytypes';
                break;
            case 'tabs.company.configuration.advancedsalarytypes':
                this.openWalkthrough(buttonWidth, -1, this.CompanyConfigurationSalaryTypesTab);
                this.prevState = 'tabs.company.configuration.integrations';
                this.nextState = 'tabs.company.configuration.timeentrytypes';
                break;
            case 'tabs.company.configuration.timeentrytypes':
                this.openWalkthrough(buttonWidth, -1, this.CompanyConfigurationTimeEntryTypesTab);
                this.prevState = 'tabs.company.configuration.advancedsalarytypes';
                this.nextState = 'tabs.company.modules';
                break;
            case 'tabs.company.modules':
                this.openWalkthrough(buttonWidth, 5, this.CompanyModulesTab);
                this.prevState = 'tabs.company.configuration.timeentrytypes';
                this.nextState = 'tabs.selfservice.payslip';
                break;
            case 'tabs.selfservice.payslip':
                this.openWalkthrough(buttonWidth, 0, this.SelfServicePayslipsTab);
                this.prevState = 'tabs.company.modules';
                this.nextState = 'tabs.selfservice.general';
                break;
            case 'tabs.selfservice.general':
                this.openWalkthrough(buttonWidth, 1, this.SelfServiceGeneralTab);
                this.prevState = 'tabs.selfservice.payslip';
                this.nextState = 'tabs.selfservice.time';
                break;
            case 'tabs.selfservice.time':
                this.openWalkthrough(buttonWidth, 2, this.SelfServiceTimeTab);
                this.prevState = 'tabs.selfservice.general';
                this.nextState = null;
                break;
        }
    };
    WalkThroughGSComponent.prototype.navigateToState = function (state, direction) {
        var _this = this;
        // Check company module is show
        if (state === 'tabs.company.modules' &&
            (!this.sessionService.role.IsFullAdmin ||
                this.IsGreenlandCompany ||
                this.sessionService.role.EmployeeWithRoleAsManager)) {
            if (direction > 0) {
                state = 'tabs.selfservice.payslip';
            }
            else {
                state = 'tabs.company.configuration.timeentrytypes';
            }
            this.navigateToState(state, direction);
            return;
        }
        // Check SelfService payslip is show
        if (state === 'tabs.selfservice.payslip') {
            this.checkHasEmployments();
            setTimeout(function () {
                if (_this.isHasEmployments !== null && _this.isHasEmployments === false) {
                    if (direction > 0) {
                        state = 'tabs.selfservice.general';
                    }
                    else {
                        state = 'tabs.company.modules';
                    }
                    _this.navigateToState(state, direction);
                }
                else {
                    _this.sessionService.NavigateTo(state);
                }
            });
            return;
        }
        // Check SelfService Time is show
        if (state === 'tabs.selfservice.time' && this.sessionService.feature.hasModuleId(8)) {
            this.checkHasEmployments();
            setTimeout(function () {
                if (_this.isHasEmployments !== null && _this.isHasEmployments === false) {
                    if (direction > 0) {
                        return;
                    }
                    else {
                        state = 'tabs.selfservice.payslip';
                    }
                    _this.navigateToState(state, direction);
                }
                else {
                    _this.sessionService.NavigateTo(state);
                }
            });
        }
        // Navigate to page
        this.sessionService.NavigateTo(state);
    };
    WalkThroughGSComponent.prototype.checkHasEmployments = function () {
        var _this = this;
        this.employeeService.employee.subscribe(function (companyUser) {
            if (companyUser) {
                _this.isHasEmployments = companyUser && companyUser.UserEmployments && companyUser.UserEmployments.length > 0;
            }
            else {
                _this.isHasEmployments = false;
            }
        });
    };
    WalkThroughGSComponent.prototype.setResponsiveStyle = function (state) {
        switch (state) {
            case 'tabs.employee.hiringstatus':
                this.sessionService.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
                break;
            case 'tabs.employee.payrolldata':
                this.sessionService.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
                break;
            default:
                if (this.sessionService.currentState !== 'tabs.employee') {
                    this.sessionService.currentStyleMenuTabpanelReponsive = '';
                }
                break;
        }
    };
    WalkThroughGSComponent.prototype.translateApplicationText = function () {
        var _this = this;
        this.translateService
            .get([
            'EmployeeGeneral.WelcomeTitle',
            'EmployeeGeneral.WelcomeMessage',
            'Employee.General',
            'EmployeeEmployment.WelcomeTitle',
            'EmployeeEmployment.WelcomeMessage',
            'Employee.Employment',
            'EmploymentPayrollData.WelcomeTitle',
            'EmploymentPayrollData.WelcomeMessage',
            'Employee.PayrollData',
            'EmploymentTime.WelcomeTitle',
            'EmploymentTime.WelcomeMessage',
            'Employee.Time',
            'EmployeePayslip.WelcomeTitle',
            'EmployeePayslip.WelcomeMessage',
            'EmployeeGeneral.Payslips',
            'AccountModules.WelcomeTitle',
            'AccountModules.WelcomeMessage',
            'Account.Modules',
            'CompanyGeneral.FirstWelcomeTitle',
            'CompanyGeneral.WelcomeTitle',
            'CompanyGeneral.WelcomeMessage',
            'Company.General',
            'CompanyHiringStatus.WelcomeTitle',
            'CompanyHiringStatus.WelcomeMessage',
            'Company.PayrollData',
            'CompanySalaryBatches.WelcomeTitle',
            'CompanySalaryBatches.WelcomeMessage',
            'Company.SalaryBatches',
            'CompanySalaryTypes.WelcomeTitle',
            'CompanySalaryTypes.WelcomeMessage',
            'CompanySalaryTypes.Approval',
            'CompanyTimeEntryTypes.WelcomeTitle',
            'CompanyTimeEntryTypes.WelcomeMessage',
            'CompanyTimeEntryTypes.TimeEntryTypes',
            'CompanyApproval.WelcomeTitle',
            'CompanyApproval.WelcomeMessage',
            'SelfServicePayslip.FirstWelcomeTitle',
            'SelfServicePayslip.WelcomeTitle',
            'SelfServicePayslip.WelcomeMessage',
            'SelfService.MyDetails',
            'Timeentrytypes.WelcomeTitle',
            'Timeentrytypes.WelcomeMessage',
            'Integrations.WelcomeTitle',
            'Integrations.WelcomeMessage',
            'GlobalDialog.Close',
            'GlobalDialog.Next',
            'GlobalDialog.Previous',
            'Company.EmploymentTemplates',
            'Company.ExternalIntegrations',
            'Company.AdvancedSalaryTypes',
            'SelfService.MyDetails'
        ])
            .subscribe(function (translations) {
            _this.welcomeTitles['EmployeeGeneral'] = translations['EmployeeGeneral.WelcomeTitle'].replace('{{SystemAlias}}', environment.branding.SystemAlias);
            _this.welcomeMessages['EmployeeGeneral'] = translations['EmployeeGeneral.WelcomeMessage'];
            _this.welcomeTitles['EmployeeEmployment'] =
                translations['EmployeeEmployment.WelcomeTitle'] + ' ' + translations['Employee.Employment'];
            _this.welcomeMessages['EmployeeEmployment'] = translations['EmployeeEmployment.WelcomeMessage'];
            _this.welcomeTitles['EmployeeTime'] =
                translations['EmploymentTime.WelcomeTitle'] + ' ' + translations['Employee.Time'];
            _this.welcomeMessages['EmployeeTime'] = translations['EmploymentTime.WelcomeMessage'];
            _this.welcomeTitles['EmployeePayrollData'] =
                translations['EmploymentPayrollData.WelcomeTitle'] + ' ' + translations['Employee.PayrollData'];
            _this.welcomeMessages['EmployeePayrollData'] = translations['EmploymentPayrollData.WelcomeMessage'];
            _this.welcomeTitles['EmployeePayslip'] =
                translations['EmployeePayslip.WelcomeTitle'] + ' ' + translations['EmployeeGeneral.Payslips'];
            _this.welcomeMessages['EmployeePayslip'] = translations['EmployeePayslip.WelcomeMessage'].replace('{{SystemAlias}}', environment.branding.SystemAlias);
            if (_this.sessionService.role.IsEmployeeRole === true) {
                _this.welcomeTitles['CompanyGeneral'] =
                    translations['CompanyGeneral.WelcomeTitle'] + ' ' + translations['Company.General'];
                _this.welcomeTitles['SelfServicePayslip'] = translations['SelfServicePayslip.FirstWelcomeTitle'].replace('{{SystemAlias}}', environment.branding.SystemAlias);
            }
            else {
                _this.welcomeTitles['SelfServicePayslip'] =
                    translations['SelfServicePayslip.WelcomeTitle'] + ' ' + translations['EmployeeGeneral.Payslips'];
                _this.welcomeTitles['CompanyGeneral'] = translations['CompanyGeneral.FirstWelcomeTitle'].replace('{{SystemAlias}}', environment.branding.SystemAlias);
            }
            _this.welcomeMessages['CompanyGeneral'] = translations['CompanyGeneral.WelcomeMessage'];
            _this.welcomeTitles['CompanyHiringStatus'] =
                translations['CompanyHiringStatus.WelcomeTitle'] + ' ' + translations['Company.EmploymentTemplates'];
            _this.welcomeMessages['CompanyHiringStatus'] = translations['CompanyHiringStatus.WelcomeMessage'];
            _this.welcomeTitles['CompanySalaryBatches'] =
                translations['CompanySalaryBatches.WelcomeTitle'] + ' ' + translations['Company.SalaryBatches'];
            _this.welcomeMessages['CompanySalaryBatches'] = translations['CompanySalaryBatches.WelcomeMessage'];
            _this.welcomeTitles['CompanySalaryTypes'] =
                translations['CompanySalaryTypes.WelcomeTitle'] + ' ' + translations['Company.AdvancedSalaryTypes'];
            _this.welcomeMessages['CompanySalaryTypes'] = translations['CompanySalaryTypes.WelcomeMessage'];
            _this.welcomeTitles['CompanyTimeEntryTypes'] =
                translations['CompanyTimeEntryTypes.WelcomeTitle'] +
                    ' ' +
                    translations['CompanyTimeEntryTypes.TimeEntryTypes'];
            _this.welcomeMessages['CompanyTimeEntryTypes'] = translations['CompanyTimeEntryTypes.WelcomeMessage'];
            _this.welcomeTitles['AccountModules'] =
                translations['AccountModules.WelcomeTitle'] + ' ' + translations['Account.Modules'];
            _this.welcomeMessages['AccountModules'] = translations['AccountModules.WelcomeMessage'].replace('{{SystemAlias}}', environment.branding.SystemAlias);
            _this.welcomeTitles['Integrations'] =
                translations['Integrations.WelcomeTitle'] + ' ' + translations['Company.ExternalIntegrations'];
            _this.welcomeMessages['Integrations'] = translations['Integrations.WelcomeMessage'].replace('{{SystemAlias}}', environment.branding.SystemAlias);
            _this.welcomeTitles['CompanyApproval'] =
                translations['CompanyApproval.WelcomeTitle'] + ' ' + translations['CompanySalaryTypes.Approval'];
            _this.welcomeMessages['CompanyApproval'] = translations['CompanyApproval.WelcomeMessage'];
            _this.welcomeMessages['SelfServicePayslip'] = translations['SelfServicePayslip.WelcomeMessage'].replace(/\{{SystemAlias}}/g, environment.branding.SystemAlias);
            _this.welcomeTitles['SelfServiceTime'] =
                translations['EmploymentTime.WelcomeTitle'] + ' ' + translations['Employee.Time'];
            _this.welcomeMessages['SelfServiceTime'] = translations['EmploymentTime.WelcomeMessage'];
            _this.welcomeTitles['SelfServiceGeneral'] =
                translations['EmploymentTime.WelcomeTitle'] + ' ' + translations['SelfService.MyDetails'];
            _this.welcomeMessages['SelfServiceGeneral'] = translations['EmployeeGeneral.WelcomeMessage'];
            _this.welcomeTexts = {
                previous: translations['GlobalDialog.Previous'],
                next: translations['GlobalDialog.Next'],
                close: translations['GlobalDialog.Close']
            };
        });
    };
    Object.defineProperty(WalkThroughGSComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WalkThroughGSComponent.prototype, "isHideSelfService", {
        get: function () {
            if (this.sessionService.feature.AccountantTabEnabled) {
                return this.employeeService.isCurrentUserHasEmployment;
            }
            return !this.employeeService.isCurrentUserExistIncompanyUsers;
        },
        enumerable: true,
        configurable: true
    });
    WalkThroughGSComponent.prototype.disableWalkthroughButton = function () {
        var nextButton = document.querySelector('.wkt-next-link');
        if (nextButton) {
            nextButton.classList.remove('wkt-enable');
        }
        var prevButton = document.querySelector('.wkt-previous-link');
        if (prevButton) {
            prevButton.classList.remove('wkt-enable');
        }
        var closeButton = document.querySelector('.wkt-finish-link');
        if (closeButton) {
            closeButton.classList.remove('wkt-enable');
        }
    };
    WalkThroughGSComponent.prototype.enableWalkthroughButton = function () {
        var nextButton = document.querySelector('.wkt-next-link');
        if (nextButton) {
            nextButton.classList.add('wkt-enable');
        }
        var prevButton = document.querySelector('.wkt-previous-link');
        if (prevButton) {
            prevButton.classList.add('wkt-enable');
        }
        var closeButton = document.querySelector('.wkt-finish-link');
        if (closeButton) {
            closeButton.classList.add('wkt-enable');
        }
    };
    return WalkThroughGSComponent;
}());
export { WalkThroughGSComponent };
