/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dimensions.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../custom-controls/buttons/button.component.ngfactory";
import * as i3 from "../../../custom-controls/buttons/button.component";
import * as i4 from "../../../services/session/session.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../custom-controls/icon/icon.component.ngfactory";
import * as i7 from "../../../custom-controls/icon/icon.component";
import * as i8 from "./dimension-detail/dimension-detail.component.ngfactory";
import * as i9 from "./dimension-detail/dimension-detail.component";
import * as i10 from "./dimensions.service";
import * as i11 from "../../../shared-components/preference/preference.service";
import * as i12 from "../templates/templates.service";
import * as i13 from "../departments/company-department.service";
import * as i14 from "../../../services/api/static-data.service";
import * as i15 from "../../../custom-controls/module-check/module-check.component.ngfactory";
import * as i16 from "../../../custom-controls/module-check/module-check.component";
import * as i17 from "../../../services/api/data.service";
import * as i18 from "../../../services/authentication/authentication.service";
import * as i19 from "../../../custom-controls/pipes/price-format.pipe";
import * as i20 from "./dimensions.component";
import * as i21 from "@ngx-translate/core";
import * as i22 from "../../../services/setting.service";
var styles_DimensionsComponent = [i0.styles];
var RenderType_DimensionsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_DimensionsComponent, data: {} });
export { RenderType_DimensionsComponent as RenderType_DimensionsComponent };
function View_DimensionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "dimension-active"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "dimension-content"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-button", [["buttonClass", "Button--destructive"]], null, [[null, "clickEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickEvent" === en)) {
        var pd_0 = (_co.activeDimension() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(3, 49152, null, 0, i3.ButtonComponent, [i4.SessionService], { label: [0, "label"], buttonClass: [1, "buttonClass"], isDisabled: [2, "isDisabled"] }, { clickEvent: "clickEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.btnLabel; var currVal_2 = "Button--destructive"; var currVal_3 = !_co.sessionService.role.IsFullAdmin; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.dimensionValueEntitiesMessage, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_DimensionsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dimensionsService.dimension1; _ck(_v, 1, 0, currVal_0); }); }
function View_DimensionsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dimensionsService.dimension2; _ck(_v, 1, 0, currVal_0); }); }
function View_DimensionsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dimensionsService.dimension3; _ck(_v, 1, 0, currVal_0); }); }
function View_DimensionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 37, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 33, "div", [["class", "ConfigNav Navigation-wrapper Navigation Navigation-inner"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "is-active": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 10, "div", [["class", "Navigation-expandWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 9, "button", [["class", "Navigation-link Navigation-expand"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggleNavigation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DimensionsComponent_3)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DimensionsComponent_4)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DimensionsComponent_5)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-icon", [], null, null, null, i6.View_IconComponent_0, i6.RenderType_IconComponent)), i1.ɵdid(15, 49152, null, 0, i7.IconComponent, [i1.ElementRef, i4.SessionService, i1.ChangeDetectorRef], { icon: [0, "icon"], size: [1, "size"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 18, "ul", [["class", "Navigation-list Navigation-list--dimension"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 5, "li", [["class", "Navigation-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 4, "app-button", [["class", "Navigation-link MainTabButton"], ["id", "CompanyConfigurationDimension1Button"]], null, [[null, "clickEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickEvent" === en)) {
        var pd_0 = (_co.changeTab(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(20, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(21, { "is-active": 0 }), i1.ɵdid(22, 49152, null, 0, i3.ButtonComponent, [i4.SessionService], { label: [0, "label"] }, { clickEvent: "clickEvent" }), (_l()(), i1.ɵeld(23, 0, null, null, 5, "li", [["class", "Navigation-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 4, "app-button", [["class", "Navigation-link MainTabButton"], ["id", "CompanyConfigurationDimension2Button"]], null, [[null, "clickEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickEvent" === en)) {
        var pd_0 = (_co.changeTab(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(26, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(27, { "is-active": 0 }), i1.ɵdid(28, 49152, null, 0, i3.ButtonComponent, [i4.SessionService], { label: [0, "label"] }, { clickEvent: "clickEvent" }), (_l()(), i1.ɵeld(29, 0, null, null, 5, "li", [["class", "Navigation-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 4, "app-button", [["class", "Navigation-link MainTabButton"], ["id", "CompanyConfigurationDimension3Button"]], null, [[null, "clickEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickEvent" === en)) {
        var pd_0 = (_co.changeTab(3) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(32, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(33, { "is-active": 0 }), i1.ɵdid(34, 49152, null, 0, i3.ButtonComponent, [i4.SessionService], { label: [0, "label"] }, { clickEvent: "clickEvent" }), (_l()(), i1.ɵeld(35, 0, null, null, 2, "div", [["class", "dimension-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(36, 0, null, null, 1, "app-dimension-detail", [], null, [[null, "selectedIdChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedIdChange" === en)) {
        var pd_0 = ((_co.dimensionsService.currentDimensionId = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_DimensionDetailComponent_0, i8.RenderType_DimensionDetailComponent)), i1.ɵdid(37, 114688, null, 0, i9.DimensionDetailComponent, [i4.SessionService, i10.DimensionsService, i11.CompanyPreferenceService, i12.CompanyTemplatesService, i13.CompanyDepartmentService, i14.StaticDataService], { selectedId: [0, "selectedId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "ConfigNav Navigation-wrapper Navigation Navigation-inner"; var currVal_1 = _ck(_v, 4, 0, _co.menuOpen); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = (_co.dimensionsService.currentDimensionNumber === 1); _ck(_v, 9, 0, currVal_2); var currVal_3 = (_co.dimensionsService.currentDimensionNumber === 2); _ck(_v, 11, 0, currVal_3); var currVal_4 = (_co.dimensionsService.currentDimensionNumber === 3); _ck(_v, 13, 0, currVal_4); var currVal_5 = "ChevronDown"; var currVal_6 = "xxsmall"; _ck(_v, 15, 0, currVal_5, currVal_6); var currVal_7 = "Navigation-link MainTabButton"; var currVal_8 = _ck(_v, 21, 0, (_co.dimensionsService.currentDimensionNumber === 1)); _ck(_v, 20, 0, currVal_7, currVal_8); var currVal_9 = _co.dimensionsService.dimension1; _ck(_v, 22, 0, currVal_9); var currVal_10 = "Navigation-link MainTabButton"; var currVal_11 = _ck(_v, 27, 0, (_co.dimensionsService.currentDimensionNumber === 2)); _ck(_v, 26, 0, currVal_10, currVal_11); var currVal_12 = _co.dimensionsService.dimension2; _ck(_v, 28, 0, currVal_12); var currVal_13 = "Navigation-link MainTabButton"; var currVal_14 = _ck(_v, 33, 0, (_co.dimensionsService.currentDimensionNumber === 3)); _ck(_v, 32, 0, currVal_13, currVal_14); var currVal_15 = _co.dimensionsService.dimension3; _ck(_v, 34, 0, currVal_15); var currVal_16 = _co.dimensionsService.currentDimensionId; _ck(_v, 37, 0, currVal_16); }, null); }
export function View_DimensionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "app-module-check", [], null, null, null, i15.View_ModuleCheckComponent_0, i15.RenderType_ModuleCheckComponent)), i1.ɵdid(1, 245760, null, 0, i16.ModuleCheckComponent, [i4.SessionService, i14.StaticDataService, i17.DataService, i18.AuthenticationService, i19.PriceFormatPipe], { moduleId: [0, "moduleId"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DimensionsComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DimensionsComponent_2)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = 4; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isEnableDimensionPreference; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.isEnableDimensionPreference; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_DimensionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dimensions", [], null, null, null, View_DimensionsComponent_0, RenderType_DimensionsComponent)), i1.ɵdid(1, 245760, null, 0, i20.DimensionsComponent, [i4.SessionService, i1.Renderer2, i11.CompanyPreferenceService, i10.DimensionsService, i21.TranslateService, i17.DataService, i22.SettingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DimensionsComponentNgFactory = i1.ɵccf("app-dimensions", i20.DimensionsComponent, View_DimensionsComponent_Host_0, { editMode: "editMode", isDirty: "isDirty", isValid: "isValid" }, { editModeChange: "editModeChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange", valueChange: "valueChange" }, []);
export { DimensionsComponentNgFactory as DimensionsComponentNgFactory };
