import { IImportOptionValue, IImportType, ISimpleKeyValuePair } from 'src/app/services/api/api-model';
import { ImportButtonAction, ImportTypesEnum } from '../../../model/enum';

export class ImportOptionHelper {
  static getImportTypeKey(importType: ImportTypesEnum): string {
    switch (importType) {
      case ImportTypesEnum.Employees:
        return 'ImportEmployees';
      case ImportTypesEnum.Initial_Balances:
        return 'ImportBalances';
      case ImportTypesEnum.Payroll:
        return 'ImportSalaryRecords';
      case ImportTypesEnum.PayrollRow:
        return 'ImportSalaryRecordsRow';
      case ImportTypesEnum.TimeEntriesRow:
        return 'ImportTimeEntries';
    }

    return '';
  }

  static getImportTypeKeyFromList(importType: ImportTypesEnum, importTypes: IImportType[]): string {
    let importModel: IImportType;
    importModel = importTypes.find((type) => {
      return type.Id === importType;
    });

    return importModel.Key;
  }

  // static createImportOptionObjects(importOptions: any): any {
  //   const options: any[] = [];
  //   if (!importOptions) {
  //     return undefined;
  //   }

  //   for (let index = 0; index < importOptions.length; index++) {
  //     if (importOptions[index].Options.length === 1) {
  //       options.push({
  //         DataType: 'CheckBox',
  //         ParameterValue: importOptions[index]['Value'],
  //         DisplayText: importOptions[index].DisplayName
  //           ? importOptions[index].DisplayName
  //           : importOptions[index].Options[0]['Value']
  //           ? importOptions[index].Options[0]['Value']
  //           : importOptions[index]['Value'],
  //         ControlValue: importOptions[index]['DefaultKey']
  //       });
  //     } else if (importOptions[index].Options.length > 1) {
  //       for (let subIndex = 0; subIndex < importOptions[index].Options.length; subIndex++) {
  //         const existedRadioOption: any = options.find(
  //           (radio: any) => radio.ParameterValue === importOptions[index]['Value']
  //         );
  //         if (existedRadioOption) {
  //           existedRadioOption.Options.push({
  //             DisplayText: importOptions[index].Options[subIndex]['Value'],
  //             RadioValue: importOptions[index].Options[subIndex]['Key']
  //           });
  //         } else {
  //           options.push({
  //             DataType: 'Radio',
  //             ParameterValue: importOptions[index]['Value'],
  //             ControlValue: importOptions[index]['DefaultKey'],
  //             Options: [
  //               {
  //                 DisplayText: importOptions[index].Options[subIndex]['Value'],
  //                 RadioValue: importOptions[index].Options[subIndex]['Key']
  //               }
  //             ]
  //           });
  //         }
  //       }
  //     }
  //   }

  //   return options;
  // }

  static createImportOptionObjects(importOptions: IImportOptionValue[]): any {
    const options: any[] = [];
    if (!importOptions) {
      return [];
    }
    importOptions.forEach((option: IImportOptionValue) => {
      options.push({
        DataType: 'Radio',
        ParameterValue: option.OptionKey,
        Selected: option.IsDefault,
        ControlValue: option.IsDefault ? option.Key : '',
        WarningText: option.WarningText,
        Options: [
          {
            DisplayText: option.Name,
            RadioValue: option.Key
          }
        ]
      });
    });

    return options;
  }

  static getImportOptionsString(importOptions: any, action: ImportButtonAction): ISimpleKeyValuePair[] {
    const result: ISimpleKeyValuePair[] = [];
    const optionPairs: ISimpleKeyValuePair[] = this.getOptionsPairs(importOptions);
    switch (action) {
      case ImportButtonAction.Test:
        result.push({ Key: 'save', Value: 'never' });
        break;
      case ImportButtonAction.Import:
        result.push({ Key: 'save', Value: 'noerror' });
        break;
      case ImportButtonAction.CompleteImport:
        result.push({ Key: 'save', Value: 'always' });
        break;
    }

    return result.concat(optionPairs);
  }

  static getOptionsString(importOptions: any): string {
    let optionsString = '';
    if (importOptions && importOptions.length > 0) {
      importOptions.forEach((option: any): void => {
        if (optionsString.indexOf(option.ParameterValue + '=') < 0) {
          optionsString +=
            (optionsString ? '-' : '') +
            option.ParameterValue +
            '=' +
            (option.ControlValue ? option.ControlValue : 'false');
        }
      });
    }

    return optionsString;
  }

  static getOptionsPairs(importOptions: any): ISimpleKeyValuePair[] {
    const optionPairs: ISimpleKeyValuePair[] = [];
    const listKeyPairs: string[] = [];
    if (importOptions && importOptions.length > 0) {
      importOptions.forEach((option: any): void => {
        if (option.Selected === false) {
          return;
        }
        optionPairs.push({
          Key: option.ParameterValue,
          Value: option.ControlValue ? option.ControlValue : 'false'
        });
        listKeyPairs.push(option.ParameterValue);
      });
    }

    return optionPairs;
  }

  static getListColumnsKey(importType: number, importTypes: IImportType[]) {
    const importT: IImportType = importTypes.find((type: IImportType) => {
      return type.Id === importType;
    });

    return importT.ImportColumnKeys;
  }
}
