import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ControlComponentBase } from '../../common/control-component-base';
import { SessionService } from '../../services/session/session.service';
var MultiSelectComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MultiSelectComponent, _super);
    function MultiSelectComponent(elementRef, changeDetectorRef, translateService, sessionService) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.translateService = translateService;
        _this.sessionService = sessionService;
        _this.gridDataSource = [];
        _this.columns = [];
        _this.disabled = false;
        _this.SelectValueIdsChange = new EventEmitter();
        _this.selectValueIdsValue = [];
        _this.gridBoxValue = [];
        _this.ngUnsubscribe = new Subject();
        _this.setTimeout = false;
        _this.translationServiceTerms = ['DropdownList.All'];
        return _this;
    }
    Object.defineProperty(MultiSelectComponent.prototype, "emptyDataSource", {
        get: function () {
            return !this.gridDataSource || (this.gridDataSource && this.gridDataSource.length === 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultiSelectComponent.prototype, "SelectValueIds", {
        get: function () {
            return this.selectValueIdsValue;
        },
        set: function (values) {
            var _this = this;
            if (values !== this.selectValueIdsValue) {
                this.selectValueIdsValue = values;
                if (values) {
                    this.gridBoxValue = values.map(function (ids) {
                        return _this.gridDataSource.find(function (item) { return item[_this.valueExp] === ids; });
                    });
                }
                this.SelectValueIdsChange.emit(values);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultiSelectComponent.prototype, "GridBoxValue", {
        get: function () {
            return this.gridBoxValue;
        },
        set: function (value) {
            var _this = this;
            this.gridBoxValue = value;
            if (this.gridBoxValue && this.gridBoxValue.length > 0) {
                this.selectValueIdsValue = this.gridBoxValue.map(function (item) {
                    var id;
                    id = item[_this.valueExp];
                    return id;
                });
            }
            else {
                this.selectValueIdsValue = [];
            }
            this.SelectValueIdsChange.emit(this.selectValueIdsValue);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultiSelectComponent.prototype, "isSelectAll", {
        get: function () {
            return (this.selectValueIdsValue && this.gridDataSource && this.selectValueIdsValue.length === this.gridDataSource.length);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultiSelectComponent.prototype, "requiredValue", {
        get: function () {
            if (this.required && this.gridDataSource && this.gridDataSource.length > 0) {
                return !this.selectValueIdsValue || (this.selectValueIdsValue && this.selectValueIdsValue.length === 0);
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    MultiSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            _this.translateService.get(_this.translationServiceTerms).subscribe(function (translations) {
                _this.allLabel = translations['DropdownList.All'];
            });
        });
    };
    MultiSelectComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    MultiSelectComponent.prototype.controlDisplayTextChange = function () {
        this.onTextChange();
    };
    MultiSelectComponent.prototype.onTextChange = function () {
        var _this = this;
        setTimeout(function () {
            var placeHolder = _this.dxDropDownBoxControl.element.nativeElement.querySelector('.dx-texteditor-input-container .dx-placeholder');
            if (_this.isSelectAll && _this.dxDropDownBoxControl.element && _this.dxDropDownBoxControl.element.nativeElement) {
                var inputTextControl = _this.dxDropDownBoxControl.element.nativeElement.querySelector('.dx-texteditor-input-container input.dx-texteditor-input');
                if (inputTextControl) {
                    inputTextControl.value = _this.allLabel;
                }
                if (placeHolder) {
                    placeHolder.style = 'visibility: hidden';
                }
            }
            else {
                if (placeHolder) {
                    placeHolder.style = 'visibility: visible';
                }
            }
        });
    };
    return MultiSelectComponent;
}(ControlComponentBase));
export { MultiSelectComponent };
