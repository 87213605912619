<app-dialog
  [overlayClose]="true"
  [(visible)]="visible"
  [title]="'Company.SenioritySupplements' | translate"
  id="seniorityDialog"
  class="main-dialog"
>
  <app-module-check [moduleId]="4">
    <app-grid
      [(data)]="seniority"
      [editMode]="editMode"
      [reFocusAfterChangeEdit]="false"
      (saveChangesEvent)="saveChanges($event)"
      class="SenioritySupplementGrid"
      [deleteRow]="editMode"
      (removeEvent)="removeSeniorityHandler($event)"
      (addNewEvent)="addSeniorityHandler($event)"
      [addNew]="editMode"
      [addNewText]="'SenioritySupplements.AddNew' | translate"
      [(triggerUpdate)]="refreshData"
      [(selectedItem)]="selectedItem"
      [addNewDisable]="sessionService.isGetting"
      [isUseSpanHeader]="true"
      [groupHeader]="'SenioritySupplements.SeniorityInMonths' | translate"
      [groupWidth]="340"
      [groupLocked]="false"
      [groupHeaderClass]="'rootHeaderGroup'"
      [idColumn]="'GridId'"
    >
      <!-- <app-grid-column
        [field]="'Between'"
        [type]="'text'"
        [editable]="false"
        [width]="130"
        class="grid-disable-cell"
        text="{{ 'SenioritySupplements.Between' | translate }}"
        title="{{ 'SenioritySupplements.SeniorityInMonths' | translate }}"
        [addOnClass]="'headerGroup titleGroup'"
      >
      </app-grid-column>
      <app-grid-column
        [field]="'SeniorityMonthsStart'"
        [title]="''"
        [type]="'numeric'"
        [width]="70"
        [numbericOption]="numericOptions"
        [addOnClass]="'headerGroup'"
      ></app-grid-column>
      <app-grid-column
        [field]="'And'"
        [type]="'text'"
        [editable]="false"
        [width]="70"
        class="grid-disable-cell"
        [text]="'SenioritySupplements.And' | translate"
        [title]="''"
        [addOnClass]="'headerGroup'"
      ></app-grid-column>
      <app-grid-column
        [field]="'SeniorityMonthsEnd'"
        [title]="''"
        [type]="'numeric'"
        [editable]="true"
        [width]="70"
        [addOnClass]="'headerGroup'"
      ></app-grid-column> -->

      <app-grid-column-group
        [field]="'Between'"
        [type]="'text'"
        [editable]="false"
        [width]="130"
        class="grid-disable-cell"
        text="{{ 'SenioritySupplements.Between' | translate }}"
        [title]="''"
        [addOnClass]="'headerGroup titleGroup'"
      >
      </app-grid-column-group>
      <app-grid-column-group
        [field]="'SeniorityMonthsStart'"
        [title]="''"
        [type]="'numeric'"
        [width]="70"
        [addOnClass]="'headerGroup'"
        [numbericOption]="numericOptions"
      ></app-grid-column-group>
      <app-grid-column-group
        [field]="'And'"
        [type]="'text'"
        [editable]="false"
        [width]="70"
        class="grid-disable-cell"
        [text]="'SenioritySupplements.And' | translate"
        [title]="''"
        [addOnClass]="'headerGroup'"
      >
      </app-grid-column-group>
      <app-grid-column-group
        [field]="'SeniorityMonthsEnd'"
        [title]="''"
        [type]="'numeric'"
        [editable]="true"
        [width]="70"
        [addOnClass]="'headerGroup'"
        [numbericOption]="numericOptions"
      ></app-grid-column-group>

      <app-grid-column
        [field]="'SupplementAmount'"
        [type]="'numeric'"
        [editable]="true"
        [title]="'SenioritySupplements.Supplement' | translate"
        [width]="150"
        [numbericOption]="numericDecimalOptions"
      >
      </app-grid-column>
      <app-grid-column
        [field]="'SupplementIntervalType'"
        [comboboxDataSource]="intervalTypes"
        [type]="'combobox'"
        [comboboxValueField]="'Id'"
        [comboboxDisplayField]="'Name'"
        [editable]="true"
        [title]="'SenioritySupplements.Principle' | translate"
        [width]="120"
      ></app-grid-column>
      <app-grid-column
        [field]="'HourlyRate'"
        [type]="'numeric'"
        [editable]="true"
        [title]="'SenioritySupplements.HourlyRate' | translate"
        [width]="90"
        [numbericOption]="numericDecimalOptions"
      >
      </app-grid-column>
    </app-grid>
  </app-module-check>

  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
