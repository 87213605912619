<app-picture-edit
  style="zoom: 0.8"
  [label]="'Employee.ChangePicture' | translate"
  [isAvatar]="true"
  [changeText]="'Employee.ChangePicture' | translate"
  [takePhotoText]="'Employee.TakePhoto' | translate"
  [editMode]="editMode"
  [imageSource]="employeeService.userImage | async"
  (change)="employeeService.updateUserImage($event)"
></app-picture-edit>
