<div class="Company-Form">
  <form *ngIf="company" class="Card Card-inner">
    <fieldset class="Form--horizontal">
      <legend class="Card-title">{{ 'CompanyGeneral.Options' | translate }}</legend>

      <div *ngIf="!IsGreenlandCompany">
        <app-module-check [moduleId]="4" [(hasModule)]="hasStatisticModule" [companyOption]="true">
          <app-combobox-edit
            [label]="'CompanyGeneral.Statistics' | translate"
            [editMode]="editMode"
            [comboboxDataSource]="statisticOptions"
            [nullValueLabel]="'DropdownList.None' | translate"
            [textField]="'Name'"
            [idField]="'Id'"
            [(isDirty)]="childIsDirty[0]"
            [disable]="!hasStatisticModule"
            [(value)]="componentStatisticsValue"
            (valueChange)="onStatisticsChange($event)"
            *ngIf="!IsSwedenCompany"
            [tooltipContent]="'CompanyGeneral.RelevantSupportArticleReportingStatisticsToolTip' | translate"
            (labelClick)="StatisticsIconClick()"
            [hasLabelLink]="true"
          >
          </app-combobox-edit>

          <div class="FormElement" *ngIf="company.StatisticsRecipientId">
            <span class="FormElement-label"></span>
            <app-radio-edit
              [disable]="!hasStatisticModule"
              [(isDirty)]="childIsDirty[10]"
              [editMode]="editMode"
              [(value)]="company.StaticsReportsYearly"
              (valueChange)="onChange()"
            >
              <app-radio-edit-option [value]="false" [label]="'CompanyGeneral.Quarterly' | translate">
              </app-radio-edit-option>
              <app-radio-edit-option [value]="true" [label]="'CompanyGeneral.Yearly' | translate">
              </app-radio-edit-option>
            </app-radio-edit>
          </div>
        </app-module-check>
      </div>

      <app-combobox-edit
        [(isDirty)]="childIsDirty[7]"
        [label]="'CompanyGeneral.IndustryOrganization' | translate"
        [nullValueLabel]="'DropdownList.None' | translate"
        [editMode]="editMode"
        [comboboxDataSource]="IndustryOrganization"
        [textField]="'Name'"
        [idField]="'Id'"
        [(value)]="company.IndustryOrganizationId"
        (valueChange)="onChange()"
        *ngIf="!IsSwedenCompany"
      >
      </app-combobox-edit>

      <app-module-check [moduleId]="5" [(hasModule)]="hasLanguageModule" [companyOption]="true">
        <app-combobox-edit
          [disable]="!hasLanguageModule"
          [(isDirty)]="childIsDirty[2]"
          [label]="'CompanyGeneral.DefaultLanguage' | translate"
          [editMode]="editMode && isAccountAdmin"
          [comboboxDataSource]="languages"
          [textField]="'Name'"
          [idField]="'Id'"
          [(value)]="company.DefaultLanguageId"
          (valueChange)="onChange()"
        ></app-combobox-edit>
      </app-module-check>

      <app-module-check [moduleId]="10" [(hasModule)]="hasPaymentModule" [companyOption]="true">
        <app-combobox-edit
          *ngIf="!IsSwedenCompany"
          [(isDirty)]="childIsDirty[3]"
          [label]="'CompanyGeneral.PaymentHandlingSalary' | translate"
          [editMode]="editMode"
          [disable]="!hasPaymentModule"
          [comboboxDataSource]="paymentMethods"
          [textField]="'Name'"
          [idField]="'Id'"
          [(value)]="company.PaymentMethodSalary"
          (valueChange)="onChange()"
        ></app-combobox-edit>

        <app-combobox-edit
          *ngIf="!IsSwedenCompany"
          [(isDirty)]="childIsDirty[4]"
          [label]="'CompanyGeneral.PaymentHandlingPension' | translate"
          [editMode]="editMode"
          [disable]="!hasPaymentModule"
          [comboboxDataSource]="paymentMethods"
          [textField]="'Name'"
          [idField]="'Id'"
          [(value)]="company.PaymentMethodPension"
          (valueChange)="onChange()"
        ></app-combobox-edit>

        <app-combobox-edit
          [(isDirty)]="childIsDirty[5]"
          [label]="'CompanyGeneral.PaymentHandlingTax' | translate"
          [editMode]="editMode"
          [disable]="!hasPaymentModule"
          [comboboxDataSource]="paymentMethods"
          *ngIf="!IsGreenlandCompany && !IsSwedenCompany"
          [textField]="'Name'"
          [idField]="'Id'"
          [(value)]="company.PaymentMethodTax"
          (valueChange)="onChange()"
        ></app-combobox-edit>

        <app-combobox-edit
          *ngIf="IsDanishCompany"
          [(isDirty)]="childIsDirty[8]"
          [label]="'CompanyGeneral.PaymentMethodVacationProvider' | translate"
          [editMode]="editMode"
          [disable]="!hasPaymentModule"
          [comboboxDataSource]="paymentMethods"
          [textField]="'Name'"
          [idField]="'Id'"
          [(value)]="company.PaymentMethodVacationProvider"
          (valueChange)="onChange()"
        ></app-combobox-edit>
      </app-module-check>

      <app-module-check [moduleId]="13" [companyOption]="true">
        <app-check-edit
          [label]="'CompanyGeneral.AllowNemKontoPayments' | translate"
          [editMode]="editMode"
          [(isDirty)]="childIsDirty[9]"
          [(value)]="company.AllowAutoDetermineBankAccount"
          (valueChange)="onChange()"
        ></app-check-edit>
      </app-module-check>

      <app-combobox-edit
        [(isDirty)]="childIsDirty[6]"
        [label]="'CompanyGeneral.Bank' | translate"
        [nullValueLabel]="'DropdownList.None' | translate"
        [editMode]="editMode"
        [comboboxDataSource]="bankOption"
        [textField]="'Name'"
        [idField]="'Id'"
        [(value)]="company.BankId"
        (valueChange)="onChange()"
      ></app-combobox-edit>

      <app-button
        class="Button--card"
        *ngIf="isAdminUser"
        (clickEvent)="onAdditionalOptionClick()"
        [label]="'Preference.AdditionalOption' | translate"
      >
      </app-button>

      <!-- Holiday setting -->
      <app-button
        class="Button--card"
        (clickEvent)="companyGeneralService.isVisibaleHolidayDialog = true"
        [label]="'CompanyGeneral.HolidaySetting' | translate"
      >
      </app-button>
    </fieldset>
  </form>
</div>

<!-- Holiday setting -->
<app-company-holiday-setting></app-company-holiday-setting>

<app-dialog class="blue-dialog" [(visible)]="isStatisticsChange">
  <div [innerHTML]="'CompanyGeneral.StatisticsChange' | translate: { SystemAlias: branding.SystemAlias }"></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
