import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from 'src/app/common/global';
import { environment } from 'src/environments/environment';
import { ImportButtonAction, ImportResultEnum, ImportTypesEnum } from '../../../model/enum';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { SessionService } from '../../../services/session/session.service';
import { ImportMappingDataItem } from './import-mapping-data-item';
import { ImportMappingDataSource } from './import-mapping-data-source';
import { ImportOptionHelper } from './import-option-helper';
import { ImportSaveMappingParameter } from './import-save-mapping-parameter';
var ImportMappingDialogComponent = /** @class */ (function () {
    function ImportMappingDialogComponent(dataService, sessionService, staticData) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.staticData = staticData;
        this.isExistingMapping = false;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.formDataValue = '';
        this.formDataChange = new EventEmitter();
        this.startIndexChange = new EventEmitter();
        this.importMChange = new EventEmitter();
        this.saveMapingEvent = new EventEmitter();
        this.closedEvent = new EventEmitter();
        // Template properties
        this.replaceAllWarningVisible = false;
        this.userColumnsValues = [];
        this.option = { min: 0, step: 1, spinners: false, decimals: 0, format: 'n0' };
        this.isButtonDisabled = false;
        this.alertDialogVisible = false;
        this.successDialogVisible = false;
        this.importOnceAlertDialogVisible = false;
        this.mappingData = []; //// Consider to use importMappingDataDataSource instead.
        this.intectDataSource = [];
        this.columnDataSource = [];
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(ImportMappingDialogComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportMappingDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
                if (value) {
                    this.showDataImportDialog();
                }
                else {
                    this.startIndex = undefined;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportMappingDialogComponent.prototype, "formData", {
        get: function () {
            return this.formDataValue || '';
        },
        set: function (value) {
            if (this.formDataValue !== value) {
                this.formDataValue = value;
                this.formDataChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportMappingDialogComponent.prototype, "startIndex", {
        get: function () {
            return this.startIndexValue;
        },
        set: function (value) {
            if (this.startIndexValue !== value) {
                this.startIndexValue = value;
                this.startIndexChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    ImportMappingDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticData.ImportTypes.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.importTypes = data;
        });
        this.staticData.ImportOptions.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.importOptions = data;
        });
    };
    ImportMappingDialogComponent.prototype.ngOnDestroy = function () {
        this.formData = null;
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    ImportMappingDialogComponent.prototype.onDialogAction = function (action) {
        switch (action) {
            case 'Test':
                this.onTestClicked();
                break;
            case 'ImportOnce':
                this.onImportOnceClicked();
                break;
            case 'SaveDefinition':
                this.onSaveChangesClicked();
                break;
            default:
                this.onCancelClicked();
        }
    };
    ImportMappingDialogComponent.prototype.closeDialogAndFireClosedEvent = function () {
        this.closedEvent.emit();
        this.closeDialog();
    };
    ImportMappingDialogComponent.prototype.onComboboxChange = function (value, item) {
        if (value) {
            var dataSourceItem = this.importMappingDataDataSource.data[item.Key].columnsTypeDropDownDataSource.find(function (i) { return i.Id === value; });
            if (dataSourceItem) {
                item.AllowsSalaryType = dataSourceItem.AllowsSalaryType;
            }
            var previousKey = this.getColumnTypeValue(item.Key);
            this.importMappingDataDataSource.data[item.Key].columnsTypeValue = value;
            this.importMappingDataDataSource.addAvailableItemToComboboxDataSources(item.Key, previousKey);
            var selectedMappingObject = this.importMappingDataDataSource.findSelectedColumnTypeItemById(value);
            if (selectedMappingObject) {
                this.updateKeyValuePairs(item.Key, selectedMappingObject.Key);
                this.importMappingDataDataSource.removeColumnTypeDropDownItem(item.Key, selectedMappingObject.Key);
                if (item.AllowsSalaryType === false) {
                    this.importMappingDataDataSource.data[item.Key].intectColumnsDropDownDataSource = [];
                }
                else {
                    this.importMappingDataDataSource.data[item.Key].intectColumnsDropDownDataSource = this.intectDataSource;
                    if (this.intectDataSource.length > 0) {
                        this.importMappingDataDataSource.data[item.Key].IntectColumnsValue = this.intectDataSource[0].Id;
                    }
                }
            }
        }
    };
    ImportMappingDialogComponent.prototype.onIntectComboboxChange = function (value, item) {
        if (value) {
            this.setAmountAsDefaultOfColumnType(value, item.Key);
        }
    };
    ImportMappingDialogComponent.prototype.onCancelClicked = function () {
        this.closeDialog();
    };
    ImportMappingDialogComponent.prototype.onTestClicked = function () {
        if (!this.validataMappings()) {
            return;
        }
        var importMappingColumns = this.convertToMappingColumn(this.mappingData);
        if (!importMappingColumns) {
            this.alertMessage = 'CompanyDataImport.NoMappingYet';
            this.alertDialogVisible = true;
            return;
        }
        this.testImportData(importMappingColumns);
    };
    ImportMappingDialogComponent.prototype.onImportOnceClicked = function () {
        if (!this.validataMappings()) {
            return;
        }
        var importMappingColumns = this.convertToMappingColumn(this.mappingData);
        if (!importMappingColumns) {
            this.alertMessage = 'CompanyDataImport.NoMappingYet';
            this.alertDialogVisible = true;
            return;
        }
        this.ImportOneImportData(-1, importMappingColumns);
    };
    ImportMappingDialogComponent.prototype.onSaveChangesClicked = function () {
        var _this = this;
        if (!this.validataMappings()) {
            return;
        }
        var mappingString = this.convertToMappingObject(this.mappingData);
        var importMappingColumns = this.convertToMappingColumn(this.mappingData);
        if (!mappingString) {
            this.alertMessage = 'CompanyDataImport.NoMappingYet';
            this.alertDialogVisible = true;
            return;
        }
        if (!this.mappingDetail) {
            this.createImportMappings(mappingString, importMappingColumns).then(function () {
                if (!_this.isExistingMapping) {
                    _this.mappingDetail = undefined;
                }
                _this.tempMappingDetail = undefined;
                _this.successDialogVisible = true;
            });
            return;
        }
        this.updateImportMappings(mappingString, importMappingColumns).then(function (mapping) {
            if (!_this.isExistingMapping) {
                _this.mappingDetail = undefined;
            }
            _this.tempMappingDetail = undefined;
            _this.fireSaveMapingEvent(mapping.Id);
            _this.successDialogVisible = true;
        });
    };
    ImportMappingDialogComponent.prototype.showDataImportDialog = function () {
        this.isButtonDisabled = false;
        this.prepareForCustomImport();
    };
    ImportMappingDialogComponent.prototype.onStartIndexChanged = function () {
        this.prepareForCustomImport();
    };
    Object.defineProperty(ImportMappingDialogComponent.prototype, "createdTempMappingDetail", {
        get: function () {
            return this.tempMappingDetail && !this.isExistingMapping;
        },
        enumerable: true,
        configurable: true
    });
    ImportMappingDialogComponent.prototype.closeDialog = function () {
        var _this = this;
        this.isButtonDisabled = true;
        this.visible = false;
        if (this.createdTempMappingDetail) {
            this.dataService.ImportMapping_DeleteMapping(this.mappingDetail.Id).subscribe(function () {
                _this.tempMappingDetail = undefined;
                _this.mappingDetail = undefined;
                _this.importMChange.emit();
            });
        }
    };
    ImportMappingDialogComponent.prototype.displayExistingPayrollMappings = function () {
        var _this = this;
        if (!this.mappingDetail.ImportMappingColumns) {
            return;
        }
        var pairs = this.mappingDetail.ImportMappingColumns;
        if (pairs) {
            pairs.forEach(function (pair) {
                var importMappingDataItem = _this.importMappingDataDataSource.data[pair.UserColumnNumber];
                _this.extractIntectValueAndColumnTypeValue(importMappingDataItem, pair.ColumnKey);
            });
        }
    };
    ImportMappingDialogComponent.prototype.extractIntectValueAndColumnTypeValue = function (importMappingDataItem, key) {
        var keyValue = key.split(';');
        if (!importMappingDataItem || keyValue.length < 1) {
            return;
        }
        // if (keyValue.length < 2) {
        //   importMappingDataItem.IntectColumnsValue = keyValue[0];
        //   const amountColumn: MappingObject = importMappingDataItem.findColumnTypeItem(1); //// Amount
        //   if (!amountColumn) {
        //     console.warn("Can't find Amount in the ColumnTypeDataSource");
        //     return;
        //   }
        //   importMappingDataItem.columnsTypeValue = amountColumn.Id;
        //   return;
        // }
        // if (!keyValue[0]) {
        //   importMappingDataItem.IntectColumnsValue = keyValue[1];
        //   importMappingDataItem.columnsTypeValue = undefined;
        //   return;
        // }
        // importMappingDataItem.IntectColumnsValue = keyValue[0];
        // const columnItem: MappingObject = importMappingDataItem.findColumnTypeItemByKey(keyValue[1]);
        // importMappingDataItem.columnsTypeValue = columnItem ? columnItem.Id : importMappingDataItem.columnsTypeValue;
    };
    ImportMappingDialogComponent.prototype.displayExistingMappings = function () {
        var _this = this;
        if (!this.mappingDetail.ImportMappingColumns) {
            return;
        }
        var pairs = this.mappingDetail.ImportMappingColumns;
        if (pairs) {
            pairs.forEach(function (pair) {
                var intectKey = pair.ColumnKey;
                var userFileId = pair.UserColumnNumber.toString();
                if (_this.importMappingDataDataSource.data[userFileId]) {
                    var columnItem = _this.importMappingDataDataSource.data[userFileId].findColumnTypeItemByKey(intectKey);
                    _this.importMappingDataDataSource.data[userFileId].columnsTypeValue = columnItem
                        ? columnItem.Id
                        : _this.importMappingDataDataSource.data[userFileId].columnsTypeValue;
                    _this.updateKeyValuePairs(userFileId, intectKey);
                    if (columnItem) {
                        var selectedMappingObject = _this.importMappingDataDataSource.findSelectedColumnTypeItemById(columnItem.Id.toString());
                        _this.importMappingDataDataSource.removeColumnTypeDropDownItem(userFileId, selectedMappingObject.Key);
                    }
                }
            });
        }
    };
    ImportMappingDialogComponent.prototype.prepareForCustomImport = function () {
        // Check for existing mapping
        this.getImportOptions();
        this.onStartMapping();
    };
    ImportMappingDialogComponent.prototype.getImportOptions = function () {
        var _this = this;
        var option = this.importOptions.find(function (importOption) {
            if (_this.importType <= 2) {
                return importOption.Name === 'Change';
            }
            else {
                return importOption.Name === 'Mode';
            }
        });
        if (option && option.Values) {
            this.importOptionsObject = ImportOptionHelper.createImportOptionObjects(option.Values);
        }
    };
    ImportMappingDialogComponent.prototype.onStartMapping = function () {
        var _this = this;
        var startIndex = this.startIndex;
        var importType = ImportOptionHelper.getImportTypeKey(this.importType);
        if (!this.formData || importType.length === 0 || !startIndex) {
            return;
        }
        // Reset mapping data before start
        this.mappingData = [];
        this.userColumnsValues = [];
        var headerRequest = {
            StartRow: this.startIndex,
            FileBase64: this.formData
        };
        this.dataService.Import_GetExcelHeadersOfFile(headerRequest).subscribe(function (result) {
            if (!result) {
                _this.showNoColumnsFoundMessage();
                return;
            }
            _this.onStartMappingCompleted(result);
        }, function (err) {
            _this.formData = null;
        });
    };
    ImportMappingDialogComponent.prototype.showNoColumnsFoundMessage = function () {
        this.alertMessage = 'CreateDataImportTemplate.NoColumnsFound';
        this.alertDialogVisible = true;
        this.isButtonDisabled = true;
        this.visible = false;
    };
    ImportMappingDialogComponent.prototype.onStartMappingCompleted = function (results) {
        var _this = this;
        if (results && results.length >= 0) {
            this.userColumnsValues = this.createMappingObject(results);
            // Set default mapping data
            this.importMappingDataDataSource = new ImportMappingDataSource();
            this.userColumnsValues.forEach(function (col) {
                _this.importMappingDataDataSource.data[col.Key] = new ImportMappingDataItem();
                _this.importMappingDataDataSource.data[col.Key].userFileColumnHeader = col.Name;
                _this.importMappingDataDataSource.data[col.Key].userFileId = col.Key;
                var keyValPairItem = { Key: col.Key, Value: '-1' };
                _this.mappingData.push(keyValPairItem);
            });
            var importTypeModel_1 = this.importTypes.find(function (importT) {
                return importT.Id === _this.importType;
            });
            if (this.importType === 5) {
                this.loadIntectColumnsDropDownDataSource(false).then(function (dataSource) {
                    _this.columnDataSource = dataSource;
                    _this.loadComboboxesDataSources(importTypeModel_1);
                });
            }
            else {
                this.intectDataSource = [];
                this.loadComboboxesDataSources(importTypeModel_1);
            }
        }
    };
    ImportMappingDialogComponent.prototype.loadIntectColumnsDropDownDataSource = function (defaultSingleColumns) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var comboboxDataSource = [];
            _this.dataService.SalaryTypes_GetSalaryTypeViews().subscribe(function (data) {
                data.forEach(function (salaryType, index) {
                    var mappingObj = {
                        Id: salaryType.SalaryTypeId,
                        SortIndex: index + 1,
                        IsSingle: defaultSingleColumns,
                        Name: salaryType.Name,
                        Key: salaryType.SalaryTypeId.toString()
                    };
                    comboboxDataSource.push(mappingObj);
                });
                return resolve(comboboxDataSource);
            });
        });
    };
    ImportMappingDialogComponent.prototype.createMappingObject = function (arrays) {
        var userColumnsValues = [];
        arrays.forEach(function (item, index) {
            var columnValue = {
                Id: index + 1,
                SortIndex: index + 1,
                IsSingle: true,
                Name: item.Name,
                Key: item.UserColumnNumber.toString()
            };
            userColumnsValues.push(columnValue);
        });
        return userColumnsValues;
    };
    ImportMappingDialogComponent.prototype.setAmountAsDefaultOfColumnType = function (intectNewValue, key) {
        var importMappingDataItem = this.importMappingDataDataSource.data[key];
        if (!importMappingDataItem || importMappingDataItem.columnsTypeValue) {
            return;
        }
        var columTypeEditable = intectNewValue && intectNewValue !== '-1' && !importMappingDataItem.IsId;
        var columnTypeValueEmpty = !importMappingDataItem.columnsTypeValue || importMappingDataItem.columnsTypeValue === -1;
        var columnTypeValue;
        var columnTypeKey;
        if (columTypeEditable && columnTypeValueEmpty) {
            columnTypeValue = 1;
            var mappingObject = importMappingDataItem.findColumnTypeItem(1);
            columnTypeKey = mappingObject ? mappingObject.Key : columnTypeKey;
        }
        importMappingDataItem.columnsTypeValue = columnTypeValue;
    };
    ImportMappingDialogComponent.prototype.setAmountAsDefaultOfColumnTypes = function () {
        for (var key in this.importMappingDataDataSource.data) {
            if (this.importMappingDataDataSource.data.hasOwnProperty(key)) {
            }
        }
    };
    ImportMappingDialogComponent.prototype.updateKeyValuePairs = function (userItemKey, objectItemKey) {
        var keyValPairItem = { Key: userItemKey, Value: objectItemKey };
        if (this.mappingData.length === 0) {
            this.mappingData.push(keyValPairItem);
        }
        else {
            // First look up if it's updating an existing keyValuePair
            var foundIndex = this.findExistingItem(userItemKey, this.mappingData);
            if (foundIndex < 0) {
                // No existing item, can add normally
                this.mappingData.push(keyValPairItem);
            }
            else {
                // Update the object item with the new selection
                this.mappingData[foundIndex].Value = objectItemKey;
            }
        }
    };
    ImportMappingDialogComponent.prototype.getColumnTypeValue = function (userItemKey) {
        var value;
        var foundIndex = this.findExistingItem(userItemKey, this.mappingData);
        if (foundIndex > -1) {
            value = this.mappingData[foundIndex].Value;
        }
        if (!value) {
            return undefined;
        }
        var columnItem = this.importMappingDataDataSource.data[userItemKey].findColumnTypeItemByKey(value);
        return columnItem ? columnItem.Key.toString() : undefined;
    };
    ImportMappingDialogComponent.prototype.findExistingItem = function (userItemKey, collection) {
        var result = collection.findIndex(function (value) { return value.Key === userItemKey; });
        return result;
    };
    ImportMappingDialogComponent.prototype.hasError = function (result) {
        return result.Errors && result.Errors.length > 0;
    };
    ImportMappingDialogComponent.prototype.validataMappings = function () {
        if (this.mappingName.length === 0) {
            this.alertMessage = 'CompanyDataImport.MappingNameEdit';
            this.alertDialogVisible = true;
            return false;
        }
        if (!this.startIndex) {
            this.alertMessage = 'CompanyDataImport.MissingStartRow';
            this.alertDialogVisible = true;
            return false;
        }
        return true;
    };
    ImportMappingDialogComponent.prototype.validateImportResult = function () {
        var isNothingImports = this.importOneResults.Errors.filter(function (result) {
            return result.TranslationEntity.Key === 'NothingImported';
        });
        return isNothingImports.length > 0 ? false : true;
    };
    ImportMappingDialogComponent.prototype.testImportData = function (importMappingColumns) {
        var _this = this;
        var optionString = ImportOptionHelper.getImportOptionsString(this.importOptionsObject, ImportButtonAction.Test);
        var simpleImportMapping = this.createSimpleImportMapping(importMappingColumns);
        var importRequest = {
            Options: optionString,
            FileBase64: this.formData,
            Mapping: simpleImportMapping
        };
        this.dataService
            .Import_CustomImportExcelOptions(importRequest)
            .subscribe(function (result) { return _this.onImportCompleted(result, true); });
    };
    ImportMappingDialogComponent.prototype.ImportOneImportData = function (mappingId, importMappingColumns) {
        var _this = this;
        var simpleImportMapping;
        var importRequest;
        var optionString = ImportOptionHelper.getImportOptionsString(this.importOptionsObject, ImportButtonAction.Import);
        if (!mappingId || mappingId < 1) {
            simpleImportMapping = this.createSimpleImportMapping(importMappingColumns);
            importRequest = {
                Options: optionString,
                FileBase64: this.formData,
                Mapping: simpleImportMapping
            };
        }
        else {
            importRequest = {
                Options: optionString,
                FileBase64: this.formData,
                MappingId: mappingId
            };
        }
        this.dataService
            .Import_CustomImportExcelOptions(importRequest)
            .subscribe(function (result) { return _this.onImportCompleted(result, false); });
    };
    ImportMappingDialogComponent.prototype.onImportCompleted = function (result, isTest) {
        this.alertMessage =
            result && result.Outcome === ImportResultEnum[ImportResultEnum.FatalError] && result.FatalMessage
                ? result.FatalMessage
                : 'CompanyDataImport.TestImportSuccessMessage';
        if (!this.hasError(result)) {
            this.alertDialogVisible = true;
            return;
        }
        if (isTest) {
            this.importResults = result;
        }
        else {
            this.importOneResults = result;
        }
    };
    ImportMappingDialogComponent.prototype.onCompleteImportOneEvent = function () {
        if (!this.validataMappings()) {
            return;
        }
        if (!this.validateImportResult()) {
            this.visible = false;
            return;
        }
        var mappingString = this.convertToMappingObject(this.mappingData);
        var importMappingColumns = this.convertToMappingColumn(this.mappingData);
        if (!mappingString) {
            this.alertMessage = 'CompanyDataImport.NoMappingYet';
            this.alertDialogVisible = true;
            return;
        }
        this.completeImportOneEvent(importMappingColumns);
    };
    ImportMappingDialogComponent.prototype.completeImportOneEvent = function (importMappingColumns) {
        var _this = this;
        var importRequest;
        var optionString = ImportOptionHelper.getImportOptionsString(this.importOptionsObject, ImportButtonAction.CompleteImport);
        var simpleImportMapping = this.createSimpleImportMapping(importMappingColumns);
        importRequest = {
            Options: optionString,
            FileBase64: this.formData,
            Mapping: simpleImportMapping
        };
        this.dataService.Import_CustomImportExcelOptions(importRequest).subscribe(function (result) {
            _this.alertMessage =
                result && result.Outcome === ImportResultEnum[ImportResultEnum.FatalError] && result.FatalMessage
                    ? result.FatalMessage
                    : 'CompanyDataImport.CompleteImportText';
            if (_this.hasError(result)) {
                _this.alertDialogVisible = true;
            }
            else {
                _this.successDialogVisible = true;
            }
            _this.visible = false;
        }, function () {
            _this.alertMessage = 'CompanyDataImport.FatalError';
            _this.alertDialogVisible = true;
        });
    };
    ImportMappingDialogComponent.prototype.updateImportMappings = function (mappingString, mappingColumns) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (!mappingString || mappingColumns.length === 0) {
                return reject('Missing mapping string or mapping columns');
            }
            _this.mappingDetail.StartRow = _this.startIndex;
            _this.mappingDetail.ImportMappingColumns = mappingColumns;
            _this.dataService.ImportMapping_UpdateMapping(_this.mappingDetail).subscribe(function (mapping) {
                _this.mappingDetail = mapping;
                return resolve(mapping);
            });
        });
    };
    ImportMappingDialogComponent.prototype.createImportMappings = function (mappingString, importMappingColumns) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (!mappingString) {
                // return deferred.promise;
            }
            var simpleImportMapping = _this.createSimpleImportMapping(importMappingColumns);
            _this.dataService.ImportMapping_SaveMapping(simpleImportMapping).subscribe(function (mapping) {
                _this.tempMappingDetail = mapping;
                _this.mappingDetail = mapping;
                _this.importMChange.emit();
                return resolve(simpleImportMapping);
            });
        });
    };
    ImportMappingDialogComponent.prototype.createSimpleImportMapping = function (importMappingColumns) {
        var simpleImportMapping = {
            Name: this.mappingName,
            StartRow: this.startIndex,
            TypeId: this.importType,
            Id: 0,
            CompanyId: Global.COMPANY.Id,
            CountryId: Global.COMPANY.CountryId,
            ImportMappingColumns: importMappingColumns
        };
        return simpleImportMapping;
    };
    ImportMappingDialogComponent.prototype.fireSaveMapingEvent = function (mappingId) {
        if (this.saveMapingEvent) {
            this.saveMapingEvent.emit(mappingId);
        }
    };
    ImportMappingDialogComponent.prototype.convertToMappingObject = function (mappingData) {
        var parameters = '';
        if (this.importType === ImportTypesEnum.Payroll) {
            var data = this.importMappingDataDataSource.data;
            var index = 0;
            for (var key in data) {
                if (data.hasOwnProperty(key)) {
                    var parameter = new ImportSaveMappingParameter();
                    parameter.value = data[key].userFileId;
                    parameter.selectedColumnTypeValue = data[key].columnsTypeValue;
                    parameter.selectedColumnTypeKey = data[key].columnsTypeKey;
                    parameter.selectedIntectColumnValue = data[key].IntectColumnsValue;
                    var strParameter = parameter.toString();
                    if (strParameter.length > 0) {
                        if (parameters.length > 0) {
                            parameters += ',';
                        }
                        parameters += strParameter;
                    }
                    index++;
                }
            }
            parameters = '[' + parameters + ']';
        }
        else {
            if (mappingData) {
                mappingData.forEach(function (element) {
                    if (element.Value && element.Value !== '-1') {
                        var col = element.Value + ':' + element.Key;
                        parameters += col + ';';
                    }
                });
            }
        }
        return parameters;
    };
    ImportMappingDialogComponent.prototype.loadComboboxesDataSources = function (importTypeModel) {
        var _this = this;
        if (this.importTypes.length < 0) {
            this.importMappingDataDataSource.setColumnTypeDropDownDataSource([]);
            this.setDefaultypeValue();
            return;
        }
        if (!importTypeModel) {
            this.importMappingDataDataSource.setColumnTypeDropDownDataSource([]);
            this.setDefaultypeValue();
            return;
        }
        this.getComboboxDataSource(importTypeModel, true).then(function (dataSource) {
            var noneMappingObject = _this.createNoneMappingObject();
            var gDataSource = [];
            if (_this.importType === 5) {
                dataSource.forEach(function (data) {
                    if (!data.AllowsSalaryType) {
                        _this.columnDataSource.unshift(data);
                    }
                    else {
                        gDataSource.push(data);
                    }
                });
                _this.intectDataSource = gDataSource;
                _this.columnDataSource.unshift(noneMappingObject);
            }
            else {
                dataSource.unshift(noneMappingObject);
                _this.columnDataSource = dataSource;
            }
            _this.importMappingDataDataSource.setColumnTypeDropDownDataSource(_this.columnDataSource);
            _this.importMappingDataDataSource.setIntectColumnsDropDownDataSourceAndAutoMap(_this.intectDataSource);
            _this.setDefaultypeValue();
            if (!_this.isExistingMapping) {
                _this.setAmountAsDefaultOfColumnTypes();
            }
            else {
                if (_this.importType === ImportTypesEnum.Payroll) {
                    _this.displayExistingPayrollMappings();
                }
                else {
                    _this.displayExistingMappings();
                }
            }
        });
    };
    ImportMappingDialogComponent.prototype.setDefaultypeValue = function () {
        var _this = this;
        if (this.isExistingMapping) {
            return;
        }
        this.userColumnsValues.forEach(function (col) {
            var datasource = _this.importMappingDataDataSource.data[col.Key].columnsTypeDropDownDataSource;
            var modelsuggest = null;
            var listSimilar = [];
            datasource.forEach(function (model) {
                var originalName = col.Name;
                var similarRate = _this.similarity(originalName, model.Name);
                var compareRate = 0.99;
                if (_this.importType === 5) {
                    compareRate = 0.99;
                }
                if (similarRate >= compareRate) {
                    listSimilar.push(similarRate);
                }
                else {
                    listSimilar.push(0);
                }
            });
            if (listSimilar.length > 0) {
                var maxIndex = listSimilar.indexOf(Math.max.apply(Math, listSimilar));
                modelsuggest = datasource[maxIndex];
            }
            else {
                modelsuggest = datasource[0];
            }
            if (modelsuggest) {
                _this.importMappingDataDataSource.data[col.Key].columnsTypeValue = modelsuggest.Id;
                _this.onComboboxChange(_this.importMappingDataDataSource.data[col.Key].columnsTypeValue, col);
            }
        });
    };
    ImportMappingDialogComponent.prototype.createNoneMappingObject = function () {
        return {
            Id: -1,
            SortIndex: -1,
            Name: ' ',
            IsSingle: false,
            Key: '-1',
            AllowsSalaryType: false
        };
    };
    ImportMappingDialogComponent.prototype.getComboboxDataSource = function (importType, defaultSingleColumns) {
        return new Promise(function (resolve, reject) {
            var comboboxDataSource = [];
            importType.CountryColumns[Global.COMPANY.Country.Key].forEach(function (column, index) {
                var cName = column.Name.replace(/(\r\n|\n|\r)/gm, ' ');
                var mappingObj = {
                    Id: column.Id,
                    SortIndex: index + 1,
                    IsSingle: defaultSingleColumns,
                    Name: cName,
                    Key: column.Key,
                    AllowsSalaryType: column.AllowsSalaryType
                };
                comboboxDataSource.push(mappingObj);
            });
            return resolve(comboboxDataSource);
        });
    };
    ImportMappingDialogComponent.prototype.onRadioOptionChange = function (value) {
        var _this = this;
        this.importOptionsObject.forEach(function (option) {
            if (option.Selected) {
                _this.oldOption = option;
            }
            var optionElement = document.getElementsByClassName(option.ControlValue)[0];
            if (option.ControlValue === value.ControlValue) {
                option.Selected = true;
                if (optionElement) {
                    optionElement.checked = true;
                }
            }
            else {
                option.Selected = false;
                if (optionElement) {
                    optionElement.checked = false;
                }
            }
            if (!value.WarningText && typeof value.WarningText === 'string' && !_this.replaceAllWarningVisible) {
                _this.replaceAllWarningVisible = true;
                _this.warningText = value.WarningText;
            }
        });
    };
    ImportMappingDialogComponent.prototype.onDialogActionWarning = function (action) {
        switch (action) {
            case 'Cancel':
                if (this.oldOption) {
                    this.onRadioOptionChange(this.oldOption);
                    this.oldOption = null;
                }
                break;
            default:
                break;
        }
    };
    ImportMappingDialogComponent.prototype.convertToMappingColumn = function (mappingData) {
        var _this = this;
        var importMappingColumns = [];
        mappingData.forEach(function (mapping) {
            if (!mapping.Value || mapping.Value === '-1') {
                return;
            }
            var data = _this.importMappingDataDataSource.data;
            var dataMapping = data[mapping.Key];
            var importMappingColumn;
            if (_this.importType === 5) {
                if (dataMapping.IntectColumnsValue) {
                    var intectColumn = dataMapping.intectColumnsDropDownDataSource.find(function (gDataSource) { return gDataSource.Id === dataMapping.IntectColumnsValue; });
                    importMappingColumn = {
                        Id: 0,
                        ImportMappingId: 0,
                        ColumnKey: intectColumn.Key || '',
                        UserColumnNumber: parseInt(mapping.Key, 10),
                        SalaryTypeId: dataMapping.columnsTypeValue
                    };
                }
                else {
                    importMappingColumn = {
                        Id: 0,
                        ImportMappingId: 0,
                        ColumnKey: dataMapping.columnsTypeKey,
                        UserColumnNumber: parseInt(mapping.Key, 10),
                        SalaryTypeId: null
                    };
                }
            }
            else {
                var columnKey = dataMapping.columnsTypeKey;
                if (columnKey && columnKey !== '-1') {
                    importMappingColumn = {
                        Id: 0,
                        ImportMappingId: 0,
                        ColumnKey: columnKey,
                        UserColumnNumber: parseInt(mapping.Key, 10),
                        SalaryTypeId: null
                    };
                }
            }
            if (importMappingColumn && importMappingColumn.Id === 0) {
                importMappingColumns.push(importMappingColumn);
            }
        });
        return importMappingColumns;
    };
    ImportMappingDialogComponent.prototype.similarity = function (s1, s2) {
        var longer = s1;
        var shorter = s2;
        if (s1.length < s2.length) {
            longer = s2;
            shorter = s1;
        }
        var longerLength = longer.length;
        if (longerLength === 0) {
            return 1.0;
        }
        return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength.toString());
    };
    ImportMappingDialogComponent.prototype.editDistance = function (s1, s2) {
        s1 = s1.toLowerCase();
        s2 = s2.toLowerCase();
        var costs = new Array();
        for (var i = 0; i <= s1.length; i++) {
            var lastValue = i;
            for (var j = 0; j <= s2.length; j++) {
                if (i === 0) {
                    costs[j] = j;
                }
                else {
                    if (j > 0) {
                        var newValue = costs[j - 1];
                        if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
                            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
                        }
                        costs[j - 1] = lastValue;
                        lastValue = newValue;
                    }
                }
            }
            if (i > 0) {
                costs[s2.length] = lastValue;
            }
        }
        return costs[s2.length];
    };
    return ImportMappingDialogComponent;
}());
export { ImportMappingDialogComponent };
