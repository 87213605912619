import { OnInit } from '@angular/core';
import { Global } from 'src/app/common/global';
import { ModuleCheckComponent } from 'src/app/custom-controls/module-check/module-check.component';
import { DefaultAvatar } from 'src/app/model/default-avatar';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { SessionService } from 'src/app/services/session/session.service';
import { SettingService } from 'src/app/services/setting.service';
import { environment } from 'src/environments/environment';
var UserMenuComponent = /** @class */ (function () {
    function UserMenuComponent(sessionService, settingService, staticDataService, dataService, authenticationService, broadcaster) {
        this.sessionService = sessionService;
        this.settingService = settingService;
        this.staticDataService = staticDataService;
        this.dataService = dataService;
        this.authenticationService = authenticationService;
        this.broadcaster = broadcaster;
        this.isDisable = false;
        this.menuOpen = false;
        this.hasBackendAccess = false;
        this.isShowAdHocSupport = false;
        this.showcontentdialog = false;
        this.hasLanguageModule = false;
        this.isTesting = true;
        this.isFemale = false;
        this.srcImageFemale = DefaultAvatar.imgFemaleAvatar;
        this.srcImageMale = DefaultAvatar.imgMaleAvatar;
        this.keepOpen = false;
    }
    Object.defineProperty(UserMenuComponent.prototype, "environment", {
        get: function () {
            return environment.environment;
        },
        enumerable: true,
        configurable: true
    });
    UserMenuComponent.prototype.ngOnInit = function () {
        this.getUserImage();
        this.getUsercompany();
        if (!this.avatar) {
            this.avatar = this.isFemale ? this.srcImageFemale : this.srcImageMale;
        }
        this.isFemale = /* Global.SESSION ? Global.SESSION.IsFemale :*/ undefined;
        this.fullName = Global.SESSION ? Global.SESSION.CurrentCompanyUser.FullName : undefined;
        this.role = Global.SESSION ? Global.SESSION.CurrentRole.Name : undefined;
        this.hasBackendAccess = Global.SESSION ? Global.SESSION.HasBackendAccess : undefined;
        this.languageId = Global.SESSION ? Global.SESSION.SignOnToken.Language.Id : undefined;
    };
    UserMenuComponent.prototype.raiseError = function () {
        throw new Error('Test AngularJS error');
    };
    UserMenuComponent.prototype.onAvatarChanged = function () {
        this.staticDataService.loadcurrentUserImage();
    };
    UserMenuComponent.prototype.onWaittingLogOut = function () {
        var _this = this;
        if (!this.sessionService.isGetting) {
            this.onLogout();
        }
        else {
            setTimeout(function () {
                _this.onWaittingLogOut();
            }, 500);
        }
    };
    UserMenuComponent.prototype.showModuleDialog = function () {
        this.sessionService.showMoreMenuToggled = false;
        this.sessionService.showHelpMenuToggled = false;
        this.sessionService.showMenuToggled = false;
        this.userLanguageModuleCheck.onClick();
    };
    UserMenuComponent.prototype.closeOtherMenus = function () {
        this.sessionService.showHelpMenuToggled = false;
        this.sessionService.showMoreMenuToggled = false;
        this.sessionService.showMenuToggled = false;
        this.settingService.showCompanySelector = !(this.sessionService.feature.hasModuleId(20) ||
            (Global.SESSION && Global.SESSION.HasBackendAccess))
            ? false
            : this.settingService.showCompanySelector;
    };
    UserMenuComponent.prototype.closeMenu = function () {
        this.menuOpen = this.keepOpen;
        this.keepOpen = false;
        this.closeOtherMenus();
    };
    UserMenuComponent.prototype.toggleMenu = function () {
        this.menuOpen = !this.menuOpen;
        this.keepOpen = this.menuOpen;
        this.closeOtherMenus();
    };
    UserMenuComponent.prototype.onTestStatus = function (status) {
        if (this.sessionService.isGetting || this.sessionService.isSubmitting) {
            return;
        }
        switch (status) {
            case 'random': // Random test
                this.dataService.Miscellaneous_Random().subscribe();
                break;
            case 'modal': // Modal Dialog test
                this.showcontentdialog = true;
                break;
            case 400:
                this.dataService.Miscellaneous_FailSoft().subscribe();
                break;
            case 401:
                this.dataService.Miscellaneous_FailUnauhorized().subscribe();
                break;
            case 404:
                this.dataService.Miscellaneous_FailNotFound().subscribe();
                break;
            case 500:
                this.dataService.Miscellaneous_FailHard().subscribe();
                break;
        }
    };
    UserMenuComponent.prototype.changeLanguage = function (args) {
        this.setLanguageContext(args.languageId);
    };
    UserMenuComponent.prototype.setLanguageContext = function (languageId) {
        var _this = this;
        this.dataService.Users_SetLanguageContext(languageId).subscribe(function () {
            document.cookie = 'languageId=' + languageId;
            _this.staticDataService
                .loadStaticData()
                .then(function () {
                window.location.reload();
            })
                .catch(function () {
                // handle languge load fail
                _this.settingService.ReloadWarrning = false;
            })
                .finally(function () {
                // handle languge load fail
                _this.settingService.ReloadWarrning = false;
            });
        }, function () {
            // handle languge load fail
            _this.settingService.ReloadWarrning = false;
        });
    };
    UserMenuComponent.prototype.onLogout = function () {
        this.sessionService.NavigateTo('login');
        this.authenticationService.logout().subscribe(function () { }, function () { });
    };
    UserMenuComponent.prototype.onShowAvatarDialog = function () {
        this.sessionService.showMoreMenuToggled = false;
        this.sessionService.showHelpMenuToggled = false;
        this.changeAvatarDialog = true;
    };
    UserMenuComponent.prototype.onChangePasswordClick = function () {
        this.settingService.showPasswordChangeDialog = true;
    };
    UserMenuComponent.prototype.onChangeLanguageDialogClick = function () {
        this.showLanguageChooserDialog = true;
    };
    UserMenuComponent.prototype.onShowAdHocSupport = function () {
        this.isShowAdHocSupport = true;
    };
    UserMenuComponent.prototype.getUserImage = function () {
        var _this = this;
        this.staticDataService.currentUserImage.subscribe(function (image) {
            if (image && image.Image !== _this.avatar) {
                _this.avatar = image.Image || (_this.isFemale ? _this.srcImageFemale : _this.srcImageMale);
            }
        });
    };
    UserMenuComponent.prototype.getUsercompany = function () {
        this.firstname = Global.SESSION.CurrentCompanyUser.FirstName;
        this.lastname = Global.SESSION.CurrentCompanyUser.LastName;
        // this.dataService
        //   .CompanyUsers_GetCompanyUserById(Global.SESSION.CurrentCompanyUser.Id)
        //   .subscribe((data: ICompanyUser): void => {
        //     this.firstname = data.FirstName;
        //     this.lastname = data.LastName;
        //   });
    };
    return UserMenuComponent;
}());
export { UserMenuComponent };
