import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../common/form-component-base';
import { StaticDataService } from '../../services/api/static-data.service';
import { EmployeeService } from '../../services/employee.service';
import { SessionService } from '../../services/session/session.service';
import { AdditionalVacationModel } from './additional-vacation-model';
var AdditionalVacationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AdditionalVacationComponent, _super);
    function AdditionalVacationComponent(translateService, sessionService, staticDataService, employeeService) {
        var _this = _super.call(this) || this;
        _this.translateService = translateService;
        _this.sessionService = sessionService;
        _this.staticDataService = staticDataService;
        _this.employeeService = employeeService;
        _this.NameOfEmplomentIdfeild = 'UserEmploymentId';
        _this.allowEdit = false;
        _this.currentmodel = [];
        _this.SectionData = new AdditionalVacationModel();
        _this.AllowEditSection = false;
        _this.selectedEarningFixedAmountPrinciple = {
            Id: null,
            Name: ''
        };
        _this.AdditionalEarningFixedAmountPrincipleIdOpyion1Label = '';
        _this.intOption = { min: 0, format: 'n0', step: 0, spinners: false, decimals: 0 };
        _this.AdditionalEarningFixedAmountPrincipleIdOpyion1Translate = '';
        _this.AdditionalEarningFixedAmountTranslate = '';
        _this.modelChange = new EventEmitter();
        _this.parentChangeEditMode = new EventEmitter();
        _this.ngUnsubscribe = new Subject();
        _this.NumbericTextBoxFFHoursEarnedPerYearOptions = {
            format: 'n2',
            decimals: 2,
            step: 1,
            spinners: false
        };
        _this.childIsValid = Array(6).fill(true);
        _this.childIsDirty = Array(6).fill(false);
        _this.translationServiceTerms = [
            'EmployeeEmployment.AdditionalEarningFixedAmountPrincipleIdOpyion1',
            'EmployeeEmployment.AdditionalEarningFixedAmount'
        ];
        _this.staticDataService.Month.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (data) {
            _this.datasourceEarningFixedAmountPrinciple = data;
            _this.onEarningFixedAmountPrinciple();
        });
        _this.sessionService.OnTranslateChanged.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function () {
            _this.translateService.get(_this.translationServiceTerms).subscribe(function (translations) {
                _this.AdditionalEarningFixedAmountPrincipleIdOpyion1Translate =
                    translations['EmployeeEmployment.AdditionalEarningFixedAmountPrincipleIdOpyion1'];
                _this.AdditionalEarningFixedAmountTranslate = translations['EmployeeEmployment.AdditionalEarningFixedAmount'];
            });
        });
        return _this;
    }
    Object.defineProperty(AdditionalVacationComponent.prototype, "EmploymentId", {
        get: function () {
            return this.employmentId;
        },
        set: function (value) {
            if (value && value !== this.employmentId) {
                this.employmentId = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdditionalVacationComponent.prototype, "model", {
        get: function () {
            return this.currentmodel;
        },
        set: function (value) {
            this.currentmodel = [];
            this.AllowEditSection = false;
            if (value && value.length > 0) {
                this.SectionData = value.find(function (item) { return item.BalanceDefinitionId === 7; });
                if (this.SectionData) {
                    this.createSafeModel(this.SectionData);
                    this.currentmodel = value;
                    this.EarningFixedAmountPrincipleIdRadioValue = this.SectionData.EarningFixedAmountPrincipleId === 13 ? 2 : 1;
                }
                else {
                    this.SectionData = new AdditionalVacationModel();
                }
            }
            else {
                this.EarningFixedAmountPrincipleIdRadioValue = undefined;
                this.SectionData = new AdditionalVacationModel();
            }
            this.SectionData[this.NameOfEmplomentIdfeild] = this.employmentId;
            this.modelChange.emit(this.currentmodel);
            this.onEarningFixedAmountPrinciple();
            this.childIsValid = Array(6).fill(true);
            this.childIsDirty = Array(6).fill(false);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdditionalVacationComponent.prototype, "getAdditionalEarningFixedAmountPrincipleIdOpyion1Label", {
        get: function () {
            if (!this.editMode) {
                return this.AdditionalEarningFixedAmountPrincipleIdOpyion1Label;
            }
            return this.AdditionalEarningFixedAmountPrincipleIdOpyion1Translate.replace('{{y}}', '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdditionalVacationComponent.prototype, "isEmptymodel", {
        get: function () {
            if (!this.model ||
                (this.model &&
                    (this.model.length === 0 ||
                        !this.model.find(function (item) { return item.BalanceDefinitionId === 7; })))) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdditionalVacationComponent.prototype, "isEnableEditSection", {
        get: function () {
            if (this.editMode) {
                if (!this.isEmptymodel) {
                    return true;
                }
                else if (this.AllowEditSection) {
                    return true;
                }
                else {
                    return false;
                }
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdditionalVacationComponent.prototype, "ArrayAdditionalEarningFixedAmountTranslate", {
        get: function () {
            if (this.AdditionalEarningFixedAmountTranslate) {
                return this.AdditionalEarningFixedAmountTranslate.split('{{x}}');
            }
            return [];
        },
        enumerable: true,
        configurable: true
    });
    AdditionalVacationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.editModeChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (valueChange) {
            if (!valueChange) {
                _this.AllowEditSection = false;
            }
        });
        this.staticDataService.PensionBaseCalculationMethod.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (baseCalculationMethods) {
            _this.vacationData = baseCalculationMethods.filter(function (pension) {
                return pension.IsExtraVacationHoursBase;
            });
        });
    };
    AdditionalVacationComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    AdditionalVacationComponent.prototype.EnableSection = function () {
        this.AllowEditSection = true;
        this.EarningFixedAmountPrincipleIdRadioValue = 1;
        if (this.defaultId || this.defaultId === 0) {
            this.SectionData.Id = this.defaultId;
        }
        if (this.vacationData.length > 0 && !this.SectionData.BaseCalculationMethodId) {
            this.SectionData.BaseCalculationMethodId = 2;
        }
        this.createSafeModel(this.SectionData);
        this.currentmodel.push(this.SectionData);
        this.childIsDirty[4] = true;
        this.componentChange();
    };
    AdditionalVacationComponent.prototype.createSafeModel = function (model, isChange) {
        if (isChange === void 0) { isChange = false; }
        model.BalanceDefinitionId = model.BalanceDefinitionId || 7;
        model.EarningFixedAmount = this.employeeService.checkNumber(model.EarningFixedAmount);
        model.EarningFixedAmountPrincipleId = this.employeeService.checkNumber(model.EarningFixedAmountPrincipleId);
        model.PensionShareFixedAmount = this.employeeService.checkNumber(model.PensionShareFixedAmount);
        model.Id = model.Id || null;
        model.DaysConversionPercentage = this.employeeService.checkNumber(model.DaysConversionPercentage);
        model.DaysEarnedPerPayslip = this.employeeService.checkNumber(model.DaysEarnedPerPayslip);
        model.EarningPercentage = this.employeeService.checkNumber(model.EarningPercentage);
        model.BaseCalculationMethodId = model.BaseCalculationMethodId || null;
        model.PayoutPercentage = this.employeeService.checkNumber(model.PayoutPercentage);
        model.PensionSharePercentage = this.employeeService.checkNumber(model.PensionSharePercentage);
        model.CustomBehaviorId = model.CustomBehaviorId || null;
        model[this.NameOfEmplomentIdfeild] = model[this.NameOfEmplomentIdfeild] || null;
        this.onEarningFixedAmountPrinciple();
        if (isChange) {
            this.componentChange();
        }
    };
    AdditionalVacationComponent.prototype.onEarningFixedAmountPrinciple = function () {
        var _this = this;
        if (this.SectionData.EarningFixedAmountPrincipleId &&
            this.datasourceEarningFixedAmountPrinciple &&
            this.datasourceEarningFixedAmountPrinciple.length > 0) {
            var dataSelect = this.datasourceEarningFixedAmountPrinciple.find(function (item) { return item.Id === _this.SectionData.EarningFixedAmountPrincipleId; });
            if (dataSelect) {
                this.selectedEarningFixedAmountPrinciple = {
                    Id: dataSelect.Id,
                    Name: dataSelect.Name
                };
            }
            this.AdditionalEarningFixedAmountPrincipleIdOpyion1Label = this.AdditionalEarningFixedAmountPrincipleIdOpyion1Translate.replace('{{y}}', this.selectedEarningFixedAmountPrinciple.Name);
        }
    };
    AdditionalVacationComponent.prototype.EarningFixedAmountPrincipleIdRadioChange = function () {
        this.SectionData.EarningFixedAmountPrincipleId =
            this.EarningFixedAmountPrincipleIdRadioValue === 2
                ? 13
                : this.SectionData.EarningFixedAmountPrincipleId === 13
                    ? 5
                    : this.SectionData.EarningFixedAmountPrincipleId;
        this.componentChange();
    };
    AdditionalVacationComponent.prototype.componentChange = function () {
        this.onChange();
        this.modelChange.emit(this.currentmodel);
    };
    AdditionalVacationComponent.prototype.changeModeAndEnableSection = function () {
        this.parentChangeEditMode.emit();
        this.EnableSection();
    };
    AdditionalVacationComponent.prototype.numberToString = function (value) {
        if (value) {
            return this.sessionService.toString(value);
        }
        return '0';
    };
    return AdditionalVacationComponent;
}(FormComponentBase));
export { AdditionalVacationComponent };
