import { EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataService } from '../../../../services/api/data.service';
import { EmployeeService } from '../../../../services/employee.service';
import { SessionService } from '../../../../services/session/session.service';
var TemplateEmployeeListComponent = /** @class */ (function () {
    function TemplateEmployeeListComponent(sessionService, dataService, employeeService) {
        this.sessionService = sessionService;
        this.dataService = dataService;
        this.employeeService = employeeService;
        this.hasEmployeesChange = new EventEmitter();
        this.employees = [];
        this.selectedEmployee = {};
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(TemplateEmployeeListComponent.prototype, "selectedTemplateId", {
        get: function () {
            return this.currentSelectedTemplateId;
        },
        set: function (value) {
            if (this.currentSelectedTemplateId !== value) {
                this.currentSelectedTemplateId = value;
                this.getEmployees();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TemplateEmployeeListComponent.prototype, "hasEmployees", {
        get: function () {
            return this.templateHasEmployees;
        },
        set: function (value) {
            var _this = this;
            if (this.templateHasEmployees !== value) {
                this.templateHasEmployees = value;
                setTimeout(function () { return _this.hasEmployeesChange.emit(value); });
            }
        },
        enumerable: true,
        configurable: true
    });
    TemplateEmployeeListComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    TemplateEmployeeListComponent.prototype.getEmployees = function () {
        var _this = this;
        if (this.currentSelectedTemplateId) {
            this.dataService
                .EmploymentTemplates_GetCompanyUsersByEmploymentTemplate(this.currentSelectedTemplateId)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(function (employees) {
                _this.employees = employees;
                _this.hasEmployees = _this.employees && _this.employees.length > 0;
            });
        }
        else {
            this.employees = [];
            this.hasEmployees = false;
        }
    };
    TemplateEmployeeListComponent.prototype.onGridDblClick = function (data) {
        if (data && data.UserEmploymentId) {
            this.employeeService.selectEmployeeFromEmploymentId(data.UserEmploymentId, true);
            this.sessionService.NavigateTo('tabs.employee.hiringstatus');
        }
    };
    return TemplateEmployeeListComponent;
}());
export { TemplateEmployeeListComponent };
