import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../../common/form-component-base';
import { EmployeeService } from '../../../services/employee.service';
var EmployeeNameComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeNameComponent, _super);
    function EmployeeNameComponent(employeeService) {
        var _this = _super.call(this) || this;
        _this.employeeService = employeeService;
        _this.ngUnsubscribe = new Subject();
        _this.childIsValid = Array(4).fill(true);
        _this.childIsDirty = Array(4).fill(false);
        return _this;
    }
    Object.defineProperty(EmployeeNameComponent.prototype, "companyUser", {
        get: function () {
            if (!this.companyUserValue) {
                this.companyUserValue = {};
            }
            return this.companyUserValue;
        },
        set: function (value) {
            this.companyUserValue = value;
            this.childIsValid = Array(4).fill(true);
            this.childIsDirty = Array(4).fill(false);
        },
        enumerable: true,
        configurable: true
    });
    EmployeeNameComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.employeeService.employment.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (value) {
            _this.userEmploymentValue = value;
        });
    };
    EmployeeNameComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(EmployeeNameComponent.prototype, "currentExternalReference", {
        get: function () {
            return this.userEmploymentValue && this.userEmploymentValue.ExternalReference
                ? "(" + this.userEmploymentValue.ExternalReference + ")"
                : '';
        },
        enumerable: true,
        configurable: true
    });
    return EmployeeNameComponent;
}(FormComponentBase));
export { EmployeeNameComponent };
