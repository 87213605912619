import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from 'src/app/common/constants';
import { Global } from 'src/app/common/global';
import { EmployeeService } from 'src/app/services/employee.service';
import { ITaxCardType, IUserEmployment, IUserEmploymentTemplate, UserEmployment } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-new-employment-dialog',
  templateUrl: './new-employment-dialog.component.html'
})
export class NewEmploymentDialogComponent implements OnInit, OnDestroy {
  @Input() public currentCompanyUserId: number;

  private isVisibleValue = false;
  @Input()
  public get isVisible(): boolean {
    return this.isVisibleValue;
  }
  public set isVisible(value: boolean) {
    if (value) {
      this.getTemplates();
    }

    this.isVisibleValue = value;
    this.isVisibleChange.emit(value);
  }

  public get isSwedishCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
  }

  @Output() public isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public viewModel: IUserEmployment;
  public templates: IUserEmploymentTemplate[] = [];
  public taxCardTypes: ITaxCardType[] = [];

  private defaultTitle = 'Medarbejder';
  private isHiringDateValid = true;

  constructor(
    public sessionService: SessionService,
    public staticDataService: StaticDataService,
    private dataService: DataService,
    private employeeService: EmployeeService
  ) {
    this.viewModel = new UserEmployment();
    this.staticDataService.TaxCardType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ITaxCardType[]) => (this.taxCardTypes = data)
    );
  }

  public ngOnInit(): void {
    if (this.isSwedishCompany) {
      this.defaultTitle = 'Metarbeter';
    }
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onDialogAction(action: string): void {
    if (action === 'Create') {
      this.onCreateNew();
    } else if (action === 'Close' || action === 'Ok') {
      this.isVisible = false;
    }
  }

  public onHiringDatePickerChanged(args: any): void {
    if (args) {
      this.isHiringDateValid = !args.isInvalid;
    }
  }

  private onCreateNew(): void {
    if (!this.viewModel.Title || this.viewModel.Title.length === 0 || !this.isHiringDateValid) {
      return;
    }

    const userEmployment: IUserEmployment = this.createNewUserEmploymentObject(
      this.viewModel.HireDate,
      this.viewModel.Title,
      this.viewModel.BasedOnTemplateId,
      this.viewModel.PreferredTaxCardTypeId
    );

    this.employeeService.createEmployment(userEmployment).subscribe(() => {
      this.isVisible = false;
      this.staticDataService.loadUserEmployments();
    });
  }

  private showNewUserEmploymentDialog(): void {
    this.viewModel.Title = this.defaultTitle;
    this.viewModel.HireDate = this.staticDataService.getCurrentdate();
    this.viewModel.PreferredTaxCardTypeId = this.taxCardTypes.length > 1 ? this.taxCardTypes[0].Id : undefined;
  }

  private createNewUserEmploymentObject(
    hireDate: Date,
    title: string,
    templateId: number,
    taxCardTypeId: number
  ): IUserEmployment {
    const newUserEmployment: IUserEmployment = new UserEmployment();
    newUserEmployment.CompanyUserId = this.currentCompanyUserId;
    newUserEmployment.Title = title;
    newUserEmployment.BasedOnTemplateId = Number(templateId);
    newUserEmployment.PreferredTaxCardTypeId = Number(taxCardTypeId);
    //newUserEmployment.VacationReduceSalary = false;
    newUserEmployment.WorksElsewhere = false;
    newUserEmployment.IsAtpTempWorker = false;
    newUserEmployment.HireDate = hireDate;
    return newUserEmployment;
  }

  private getTemplates(): void {
    this.dataService
      .EmploymentTemplates_GetEmploymentTemplates()
      .subscribe((templates: IUserEmploymentTemplate[]): void => {
        this.templates = templates;
        if (this.templates && this.templates.length > 0) {
          this.viewModel.BasedOnTemplateId = this.templates[0].Id;
          this.showNewUserEmploymentDialog();
        }
      });
  }

  public isValidate = true;
  public onValidateChanged(value: any): void {
    this.isValidate = !value;
  }
}
