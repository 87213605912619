import { Global } from 'src/app/common/global';
import { ITimeEntryType, ITimeEntryTypeConfiguration, ITimeEntryTypeTranslation } from 'src/app/services/api/api-model';

export class TimeEntryTypeView implements ITimeEntryType {
  public configurationIcon = 'Cog';
  public IsActive: boolean;
  public isActiveClass: string;
  public Id: number;
  public Name: string;
  public nameClass: string;
  public inheritsFrom: string;
  public Description: string;
  public descriptionClass: string;
  public ExternalReference: string;
  public externalReferenceClass: string;
  public SortOrder: number;
  public sortOrderClass: string;
  public DefaultPayslipText: string;
  public defaultPayslipTextClass: string;
  public UnitTypeId: number;
  public unitTypeClass: string;
  public AllowEditUnitType: boolean;
  public allowEditUnitTypeClass: string;
  public IsIllness: boolean;
  public isIllnessClass: string;
  public SalaryTypeId: number;
  public salaryTypeClass: string;
  public IconConfigurationIcon: string;
  public IconConfigurationIconTooltip: string;
  public InternalReferenceId: number;
  public Identifier: string;

  BaseTimeEntryTypeId?: number;
  CompanyId?: number;
  BaseTimeEntryType?: ITimeEntryType;
  TimeEntryTypeTranslations?: ITimeEntryTypeTranslation[];
  TimeEntryTypeConfigurations?: ITimeEntryTypeConfiguration[];

  private inheritClass = 'grid-inherit-cell';

  constructor(data: ITimeEntryType, private languageEnabled: boolean) {
    Object.assign(this, data);
    this.TimeEntryTypeTranslations = data.TimeEntryTypeTranslations;
    const defaultName = '';
    this.inheritsFrom = this.BaseTimeEntryType ? this.BaseTimeEntryType.Name : defaultName;
    this.IconConfigurationIcon = languageEnabled ? 'Cog' : '';
    this.IconConfigurationIconTooltip = languageEnabled ? 'CompanySalaryTypes.EditTranslations' : '';

    this.isActiveClass =
      this.IsActive !== null || !this.BaseTimeEntryType || this.BaseTimeEntryType.IsActive === null
        ? ''
        : this.inheritClass;
    this.externalReferenceClass =
      this.ExternalReference !== null || !this.BaseTimeEntryType || this.BaseTimeEntryType.ExternalReference === null
        ? ''
        : this.inheritClass;
    this.sortOrderClass =
      this.SortOrder !== null || !this.BaseTimeEntryType || this.BaseTimeEntryType.SortOrder === null
        ? ''
        : this.inheritClass;
    this.unitTypeClass =
      this.UnitTypeId !== null || !this.BaseTimeEntryType || this.BaseTimeEntryType.UnitTypeId === null
        ? ''
        : this.inheritClass;
    this.allowEditUnitTypeClass =
      this.AllowEditUnitType !== null || !this.BaseTimeEntryType || this.BaseTimeEntryType.AllowEditUnitType === null
        ? ''
        : this.inheritClass;
    this.isIllnessClass =
      this.IsIllness !== null || !this.BaseTimeEntryType || this.BaseTimeEntryType.IsIllness === null
        ? ''
        : this.inheritClass;
    this.salaryTypeClass =
      this.SalaryTypeId !== null || !this.BaseTimeEntryType || this.BaseTimeEntryType.SalaryTypeId === null
        ? ''
        : this.inheritClass;

    // For single tab
    this.descriptionClassSingle =
      !this.BaseTimeEntryType || this.Description !== null || this.BaseTimeEntryType.Description === null
        ? ''
        : this.inheritClassSingle;
    this.defaultPayslipTextClassSingle =
      !this.BaseTimeEntryType || this.DefaultPayslipText !== null || this.BaseTimeEntryType.DefaultPayslipText === null
        ? ''
        : this.inheritClassSingle;
    this.isActiveClassSingle =
      this.IsActive !== null || !this.BaseTimeEntryType || this.BaseTimeEntryType.IsActive === null
        ? ''
        : this.inheritClassSingle;
    this.externalReferenceClassSingle =
      this.ExternalReference !== null || !this.BaseTimeEntryType || this.BaseTimeEntryType.ExternalReference === null
        ? ''
        : this.inheritClassSingle;
    this.sortOrderClassSingle =
      this.SortOrder !== null || !this.BaseTimeEntryType || this.BaseTimeEntryType.SortOrder === null
        ? ''
        : this.inheritClassSingle;
    this.unitTypeIdClassSingle =
      this.UnitTypeId !== null || !this.BaseTimeEntryType || this.BaseTimeEntryType.UnitTypeId === null
        ? ''
        : this.inheritClassSingle;
    this.allowEditUnitTypeClassSingle =
      this.AllowEditUnitType !== null || !this.BaseTimeEntryType || this.BaseTimeEntryType.AllowEditUnitType === null
        ? ''
        : this.inheritClassSingle;
    this.isIllnessClassSingle =
      this.IsIllness !== null || !this.BaseTimeEntryType || this.BaseTimeEntryType.IsIllness === null
        ? ''
        : this.inheritClassSingle;
    this.salaryTypeClassSingle =
      this.SalaryTypeId !== null || !this.BaseTimeEntryType || this.BaseTimeEntryType.SalaryTypeId === null
        ? ''
        : this.inheritClassSingle;

    this.setCssClassAndValueForTranslationString();
    this.IsActive =
      this.IsActive !== null ? this.IsActive : this.BaseTimeEntryType ? this.BaseTimeEntryType.IsActive : false;
    this.ExternalReference =
      this.ExternalReference !== null
        ? this.ExternalReference
        : this.BaseTimeEntryType
        ? this.BaseTimeEntryType.ExternalReference
        : undefined;
    this.SortOrder =
      this.SortOrder !== null ? this.SortOrder : this.BaseTimeEntryType ? this.BaseTimeEntryType.SortOrder : undefined;
    this.UnitTypeId =
      this.UnitTypeId !== null
        ? this.UnitTypeId
        : this.BaseTimeEntryType
        ? this.BaseTimeEntryType.UnitTypeId
        : undefined;
    this.AllowEditUnitType =
      this.AllowEditUnitType !== null
        ? this.AllowEditUnitType
        : this.BaseTimeEntryType
        ? this.BaseTimeEntryType.AllowEditUnitType
        : undefined;
    this.IsIllness =
      this.IsIllness !== null ? this.IsIllness : this.BaseTimeEntryType ? this.BaseTimeEntryType.IsIllness : undefined;
    this.SalaryTypeId =
      this.SalaryTypeId !== null
        ? this.SalaryTypeId
        : this.BaseTimeEntryType
        ? this.BaseTimeEntryType.SalaryTypeId
        : undefined;
  }

  public descriptionClassSingle: string;
  public defaultPayslipTextClassSingle: string;
  public isActiveClassSingle: string;
  public externalReferenceClassSingle: string;
  public sortOrderClassSingle: string;
  public unitTypeIdClassSingle: string;
  public allowEditUnitTypeClassSingle: string;
  public isIllnessClassSingle: string;
  public salaryTypeClassSingle: string;
  public inheritClassSingle = 'inherit-field';

  private setCssClassAndValueForTranslationString(): void {
    if (this.languageEnabled) {
      this.nameClass =
        this.getFieldTranslation('Name') || this.Name !== null || !this.getBaseTranslation('Name')
          ? ''
          : this.inheritClass;
      this.descriptionClass =
        this.getFieldTranslation('Description') || this.Description !== null || !this.getBaseTranslation('Description')
          ? ''
          : this.inheritClass;
      this.defaultPayslipTextClass =
        this.getFieldTranslation('DefaultPayslipText') ||
        this.DefaultPayslipText !== null ||
        !this.getBaseTranslation('DefaultPayslipText')
          ? ''
          : this.inheritClass;

      this.Name = this.getFieldTranslation('Name')
        ? this.getFieldTranslation('Name')
        : this.Name !== null
        ? this.Name
        : this.getBaseTranslation('Name');
      this.Description = this.getFieldTranslation('Description')
        ? this.getFieldTranslation('Description')
        : this.Description !== null
        ? this.Description
        : this.getBaseTranslation('Description');
      this.DefaultPayslipText = this.getFieldTranslation('DefaultPayslipText')
        ? this.getFieldTranslation('DefaultPayslipText')
        : this.DefaultPayslipText !== null
        ? this.DefaultPayslipText
        : this.getBaseTranslation('DefaultPayslipText');
    } else {
      this.nameClass =
        this.Name !== null || !this.BaseTimeEntryType || this.BaseTimeEntryType.Name === null ? '' : this.inheritClass;
      this.descriptionClass =
        this.Description !== null || !this.BaseTimeEntryType || this.BaseTimeEntryType.Description === null
          ? ''
          : this.inheritClass;
      this.defaultPayslipTextClass =
        this.DefaultPayslipText !== null ||
        !this.BaseTimeEntryType ||
        this.BaseTimeEntryType.DefaultPayslipText === null
          ? ''
          : this.inheritClass;

      this.Name = this.Name !== null ? this.Name : this.BaseTimeEntryType ? this.BaseTimeEntryType.Name : undefined;
      this.Description =
        this.Description !== null
          ? this.Description
          : this.BaseTimeEntryType
          ? this.BaseTimeEntryType.Description
          : undefined;
      this.DefaultPayslipText =
        this.DefaultPayslipText !== null
          ? this.DefaultPayslipText
          : this.BaseTimeEntryType
          ? this.BaseTimeEntryType.DefaultPayslipText
          : undefined;
    }
  }

  private getFieldTranslation(fieldName: string): string {
    let translationText: string;
    if (this.TimeEntryTypeTranslations && this.TimeEntryTypeTranslations.length > 0) {
      const translation: any = this.TimeEntryTypeTranslations.find(
        (t: ITimeEntryTypeTranslation) => t.LanguageId === Global.SESSION.SignOnToken.LanguageId
      );
      translationText = translation ? translation[fieldName] : undefined;
    }

    return translationText;
  }

  private getBaseTranslation(fieldName: string): string {
    if (!this.BaseTimeEntryType) {
      return undefined;
    }

    let translationText: string;
    if (
      this.BaseTimeEntryType.TimeEntryTypeTranslations &&
      this.BaseTimeEntryType.TimeEntryTypeTranslations.length > 0
    ) {
      const translation: any = this.BaseTimeEntryType.TimeEntryTypeTranslations.find(
        (t: ITimeEntryTypeTranslation) => t.LanguageId === Global.SESSION.SignOnToken.LanguageId
      );
      translationText = translation ? translation[fieldName] : undefined;
    }

    return translationText ? translationText : (this.BaseTimeEntryType as any)[fieldName];
  }

  public toTimeEntryType(): ITimeEntryType {
    return {
      Id: this.Id,
      BaseTimeEntryTypeId: this.BaseTimeEntryTypeId,
      CompanyId: this.CompanyId,
      Name:
        /*this.languageEnabled ? undefined : */ !this.nameClass ||
        !this.BaseTimeEntryType ||
        this.Name !== this.BaseTimeEntryType.Name
          ? this.Name
          : null,
      Description:
        /*this.languageEnabled ? undefined : */ !this.descriptionClass ||
        !this.BaseTimeEntryType ||
        this.Description !== this.BaseTimeEntryType.Description
          ? this.Description || null
          : null,
      DefaultPayslipText:
        /*this.languageEnabled ? undefined : */ !this.descriptionClass ||
        !this.BaseTimeEntryType ||
        this.DefaultPayslipText !== this.BaseTimeEntryType.DefaultPayslipText
          ? this.DefaultPayslipText || null
          : null,

      IsActive:
        !this.isActiveClass || !this.BaseTimeEntryType || this.IsActive !== this.BaseTimeEntryType.IsActive
          ? this.IsActive
          : null,
      UnitTypeId:
        !this.unitTypeClass || !this.BaseTimeEntryType || this.UnitTypeId !== this.BaseTimeEntryType.UnitTypeId
          ? this.UnitTypeId || null
          : null,
      ExternalReference:
        !this.externalReferenceClass ||
        !this.BaseTimeEntryType ||
        this.ExternalReference !== this.BaseTimeEntryType.ExternalReference
          ? this.ExternalReference || null
          : null,
      SalaryTypeId:
        !this.salaryTypeClass || !this.BaseTimeEntryType || this.SalaryTypeId !== this.BaseTimeEntryType.SalaryTypeId
          ? this.SalaryTypeId || null
          : null,
      SortOrder:
        !this.sortOrderClass || !this.BaseTimeEntryType || this.SortOrder !== this.BaseTimeEntryType.SortOrder
          ? this.SortOrder || null
          : null,
      AllowEditUnitType:
        !this.allowEditUnitTypeClass ||
        !this.BaseTimeEntryType ||
        this.AllowEditUnitType !== this.BaseTimeEntryType.AllowEditUnitType
          ? this.AllowEditUnitType
          : null,
      IsIllness:
        !this.isIllnessClass || !this.BaseTimeEntryType || this.IsIllness !== this.BaseTimeEntryType.IsIllness
          ? this.IsIllness
          : null,
      InternalReferenceId:
        !this.InternalReferenceId ||
        !this.BaseTimeEntryType ||
        this.InternalReferenceId !== this.BaseTimeEntryType.InternalReferenceId
          ? this.InternalReferenceId || null
          : null,
      Identifier:
        !this.Identifier || !this.BaseTimeEntryType || this.Identifier !== this.BaseTimeEntryType.Identifier
          ? this.Identifier || null
          : null
    };
  }
}
