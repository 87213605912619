import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { IBaseCalculationMethod } from 'src/app/services/api/api-model';
import { FormComponentBase } from '../../common/form-component-base';
import { NumericTextBoxOptions } from '../../custom-controls/numeric-edit/numeric-text-box-options';
import { StaticDataService } from '../../services/api/static-data.service';
import { EmployeeService } from '../../services/employee.service';
import { SessionService } from '../../services/session/session.service';
import { MultiPurposeBalanceCompensationModel } from './multi-purpose-balance-compensation-model';

@Component({
  selector: 'app-multi-purpose-balance-compensation',
  templateUrl: './multi-purpose-balance-compensation.component.html'
})
export class MultiPurposeBalanceCompensationComponent extends FormComponentBase implements OnInit {
  @Input() public NameOfEmplomentIdfeild = 'UserEmploymentId';
  @Input() public defaultId: number;
  @Input() public allowEdit = false;
  @Input() public disableBySecondaryIncomeType: boolean;

  private employmentId: number;
  @Input()
  public get EmploymentId(): number {
    return this.employmentId;
  }
  public set EmploymentId(value: number) {
    if (value && value !== this.employmentId) {
      this.employmentId = value;
    }
  }

  private currentmodel: MultiPurposeBalanceCompensationModel[] = [];
  @Input()
  public get model(): MultiPurposeBalanceCompensationModel[] {
    return this.currentmodel;
  }
  public set model(value: MultiPurposeBalanceCompensationModel[]) {
    this.currentmodel = [];
    this.AllowEditSection = false;

    if (value && value.length > 0) {
      this.SectionData = value.find((item: MultiPurposeBalanceCompensationModel) => item.BalanceDefinitionId === 3);

      if (this.SectionData) {
        this.createSafeModel(this.SectionData);
        this.currentmodel = value;
      } else {
        this.SectionData = new MultiPurposeBalanceCompensationModel();
      }
    } else {
      this.SectionData = new MultiPurposeBalanceCompensationModel();
    }

    this.SectionData[this.NameOfEmplomentIdfeild] = this.employmentId;
    this.modelChange.emit(this.currentmodel);

    this.childIsValid = Array(6).fill(true);
    this.childIsDirty = Array(6).fill(false);
  }

  public get defaulHeader(): string {
    return 'EmployeeEmployment.MultiPurposeBalance';
  }

  public get SectionName(): string {
    const preferenceModel = this.sessionService.getCompanyPreference('Company.NewFlexName');
    return preferenceModel ? preferenceModel.Value : this.defaulHeader;
  }

  public SectionData: MultiPurposeBalanceCompensationModel | any = new MultiPurposeBalanceCompensationModel();
  public AllowEditSection = false;

  @Output() public modelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public parentChangeEditMode: EventEmitter<any> = new EventEmitter<any>();

  public get isEmptymodel(): boolean {
    if (
      !this.model ||
      (this.model &&
        (this.model.length === 0 ||
          !this.model.find((item: MultiPurposeBalanceCompensationModel) => item.BalanceDefinitionId === 3)))
    ) {
      return true;
    }
    return false;
  }

  public get isEnableEditSection(): boolean {
    if (this.editMode) {
      if (!this.isEmptymodel) {
        return true;
      } else if (this.AllowEditSection) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  constructor(
    public staticDataService: StaticDataService,
    private employeeService: EmployeeService,
    private sessionService: SessionService
  ) {
    super();

    this.childIsValid = Array(6).fill(true);
    this.childIsDirty = Array(6).fill(false);
  }

  public flexData: any;
  public ngOnInit(): void {
    this.editModeChange.pipe().subscribe((valueChange: boolean) => {
      if (!valueChange) {
        this.AllowEditSection = false;
      }
    });

    this.staticDataService.PensionBaseCalculationMethod.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (baseCalculationMethods: IBaseCalculationMethod[]) => {
        this.flexData = baseCalculationMethods.filter((pension: any) => {
          return pension.IsFlexBase;
        });
      }
    );
  }

  public EnableSection(): void {
    this.AllowEditSection = true;
    if (this.defaultId || this.defaultId === 0) {
      this.SectionData.Id = this.defaultId;
    }
    if (this.flexData.length > 0 && !this.SectionData.BaseCalculationMethodId) {
      this.SectionData.BaseCalculationMethodId = 2;
    }
    this.createSafeModel(this.SectionData);
    this.currentmodel.push(this.SectionData);
    this.childIsDirty[4] = true;
    this.componentChange();
  }

  public createSafeModel(model: MultiPurposeBalanceCompensationModel | any, isChange: boolean = false): void {
    model.BalanceDefinitionId = model.BalanceDefinitionId || 3;
    model.EarningFixedAmount = this.employeeService.checkNumber(model.EarningFixedAmount);
    model.EarningFixedAmountPrincipleId = this.employeeService.checkNumber(model.EarningFixedAmountPrincipleId);
    model.PensionShareFixedAmount = this.employeeService.checkNumber(model.PensionShareFixedAmount);
    model.Id = model.Id || null;
    model.DaysConversionPercentage = this.employeeService.checkNumber(model.DaysConversionPercentage);
    model.DaysEarnedPerPayslip = this.employeeService.checkNumber(model.DaysEarnedPerPayslip);
    model.EarningPercentage = this.employeeService.checkNumber(model.EarningPercentage);
    model.BaseCalculationMethodId = model.BaseCalculationMethodId || null;
    model.PayoutPercentage = this.employeeService.checkNumber(model.PayoutPercentage);
    model.PensionSharePercentage = this.employeeService.checkNumber(model.PensionSharePercentage);
    model[this.NameOfEmplomentIdfeild] = model[this.NameOfEmplomentIdfeild] || null;

    if (isChange) {
      this.componentChange();
    }
  }

  public componentChange() {
    this.onChange();
    this.modelChange.emit(this.currentmodel);
  }

  public changeModeAndEnableSection() {
    this.parentChangeEditMode.emit();
    this.EnableSection();
  }

  public NumbericTextBoxFFHoursEarnedPerYearOptions: NumericTextBoxOptions = {
    format: 'n2',
    decimals: 2,
    step: 1,
    spinners: false
  };
}
