import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, forkJoin as observableForkJoin, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { environment } from '../../../environments/environment';
import { Global } from '../../common/global';
import { GridHelper } from '../../common/grid-helper';
import {
  ICompanyPreference,
  ICompanyPreferenceCategory,
  ISimpleKeyValuePair,
  IUserPreference
} from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { CompanyService } from '../../services/company.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import { PreferenceViewModel } from './preference-view-model';

@Injectable({
  providedIn: 'root'
})
export class CompanyPreferenceService extends CompanyService implements OnDestroy {
  private translationServiceTerms: string[];

  constructor(
    protected dataService: DataService,
    protected staticDataService: StaticDataService,
    protected settingService: SettingService,
    protected sessionService: SessionService,
    protected transitionService: TransitionService,
    protected broadcaster: BroadcastService
  ) {
    super(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster);
  }

  private apiReponses: { [api: string]: any } = {};

  private companyReferencesCategorySubject: BehaviorSubject<ICompanyPreferenceCategory[]>;
  public get companyReferencesCategory(): Observable<ICompanyPreferenceCategory[]> {
    if (!this.companyReferencesCategorySubject) {
      this.companyReferencesCategorySubject = new BehaviorSubject<ICompanyPreferenceCategory[]>([]);
    }
    return this.companyReferencesCategorySubject.asObservable();
  }

  private loadCompanyReferencesCategory() {
    if (!this.companyReferencesCategorySubject) {
      this.companyReferencesCategorySubject = new BehaviorSubject<ICompanyPreferenceCategory[]>([]);
    }
    return this.dataService.StaticData_GetCompanyPreferenceCategories().pipe(
      map((data: ICompanyPreferenceCategory[]): void => {
        this.companyReferencesCategorySubject.next(data.sort((a: any, b: any) => a.SortOrder - b.SortOrder));
      })
    );
  }

  private companyReferencesInfoSubject: BehaviorSubject<ISimpleKeyValuePair[]>;
  public get companyReferencesInfo(): Observable<ISimpleKeyValuePair[]> {
    if (!this.companyReferencesInfoSubject) {
      this.companyReferencesInfoSubject = new BehaviorSubject<ISimpleKeyValuePair[]>([]);
    }
    return this.companyReferencesInfoSubject.asObservable();
  }

  private loadCompanyReferencesInfo(): Observable<void> {
    if (!this.companyReferencesInfoSubject) {
      this.companyReferencesInfoSubject = new BehaviorSubject<ISimpleKeyValuePair[]>([]);
    }
    if (Global.COMPANY_PREFERENCES && Global.COMPANY_PREFERENCES.length > 0) {
      const data = JSON.parse(JSON.stringify(Global.COMPANY_PREFERENCES));
      this.companyReferencesInfoSubject.next(data);
      return Observable.create((observer: any) => {
        observer.next(this.companyReferencesInfoSubject);
        observer.complete();
      });
    } else {
      return this.dataService.Preferences_GetCompanyPreferences().pipe(
        map((data: ISimpleKeyValuePair[]): void => {
          Global.COMPANY_PREFERENCES = data;
          this.companyReferencesInfoSubject.next(data);
        })
      );
    }
  }

  private companyReferencesStructureSubject: BehaviorSubject<ICompanyPreference[]>;
  public get companyReferencesStructure(): Observable<ICompanyPreference[]> {
    if (!this.companyReferencesStructureSubject) {
      this.companyReferencesStructureSubject = new BehaviorSubject<ICompanyPreference[]>([]);
    }
    return this.companyReferencesStructureSubject.asObservable();
  }

  private loadCompanyReferencesStructure(): Observable<void> {
    if (!this.companyReferencesStructureSubject) {
      this.companyReferencesStructureSubject = new BehaviorSubject<ICompanyPreference[]>([]);
    }
    return this.dataService.StaticData_GetCompanyPreferences().pipe(
      map((staticData: ICompanyPreference[]): void => {
        this.companyReferencesStructureSubject.next(staticData);
      })
    );
  }

  private userPreferencesSubject: BehaviorSubject<PreferenceViewModel[]>;
  public get userPreferences(): Observable<PreferenceViewModel[]> {
    if (!this.userPreferencesSubject) {
      this.userPreferencesSubject = new BehaviorSubject<PreferenceViewModel[]>([]);
    }
    return this.userPreferencesSubject.asObservable();
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public dataInint(): Observable<any> {
    if (this.isCompanySetting) {
      return observableForkJoin(
        this.loadCompanyReferencesInfo(),
        this.loadCompanyReferencesCategory(),
        this.loadCompanyReferencesStructure()
      ).pipe(tap(() => {}));
    } else {
      return Observable.create((ob: any) => {
        if (!this.userPreferencesSubject) {
          this.userPreferencesSubject = new BehaviorSubject<PreferenceViewModel[]>([]);
        }
        if (Global.USER_PREFERENCES && Global.USER_PREFERENCES.length > 0) {
          this.dataService.StaticData_GetUserPreferences().subscribe((staticData: IUserPreference[]): void => {
            this.createPreferences(Global.USER_PREFERENCES, staticData).subscribe((preferences: any) => {
              this.userPreferencesSubject.next(preferences);
            });
          });
        } else {
          this.dataService.Preferences_GetUserPreferences().subscribe((keyValuePairs: ISimpleKeyValuePair[]): void => {
            this.dataService.StaticData_GetUserPreferences().subscribe((staticData: IUserPreference[]): void => {
              this.createPreferences(keyValuePairs, staticData).subscribe((preferences: any) => {
                this.userPreferencesSubject.next(preferences);
              });
            });
          });
        }

        ob.complete();
      });
    }
  }

  public createPreferences(keyValuePairs: ISimpleKeyValuePair[], staticData: any[]): Observable<PreferenceViewModel[]> {
    return Observable.create((ob: any) => {
      let results: PreferenceViewModel[] = [];
      const dataSourceCount: number = staticData.filter((data: any) => this.isDataTypeCombobox(data.UiControlType))
        .length;
      let dataSourceLoaded = 0;
      let processedPref = 0;
      for (const data of staticData) {
        const pref: PreferenceViewModel = new PreferenceViewModel();
        if (!data.UiControlType) {
          continue;
        }

        pref.Id = data.Id;
        pref.CategoryId = data.CategoryId;
        pref.Key = data.Key;
        pref.DataType = data.UiControlType;
        pref.Name = data.Name;
        pref.Description = data.Description;
        pref.HelpUrl = data.HelpUrl;
        pref.RequiredModuleId = data.RequiredModuleId;
        pref.MinimumRoleId = data.MinimumRoleId;
        pref.SortOrder = data.SortKey ? this.sessionService.parseInt(data.SortKey) : undefined;
        pref.listDataSource = data.listDataSource;

        if (!pref.MinimumRoleId || pref.MinimumRoleId <= Global.SESSION.CurrentRole.Id) {
          pref.IsVisible = true;
        } else {
          pref.IsVisible = false;
        }

        const foundSetting: ISimpleKeyValuePair = this.foundSetting(pref.Key, keyValuePairs);
        if (foundSetting) {
          if (this.isDataTypeBoolean(pref.DataType)) {
            pref.Value = foundSetting.Value === 'true' ? true : false;
          } else if (this.isDataTypeNumber(pref.DataType)) {
            pref.Value = parseInt(foundSetting.Value, 10);
          } else {
            pref.Value = foundSetting.Value;
          }
        } else {
          // Setting not found - get default value
          if (this.isDataTypeBoolean(pref.DataType)) {
            pref.Value = data.Default === 'true' ? true : false;
          } else if (this.isDataTypeNumber(pref.DataType)) {
            pref.Value = parseInt(data.Default, 10);
          } else {
            pref.Value = data.Default;
          }
        }

        if (this.isDataTypeCombobox(pref.DataType)) {
          this.loadComboboxDataSource(results, pref).subscribe(() => {
            dataSourceLoaded += 1;
            processedPref += 1;
            results.push(pref);
            if (dataSourceCount === dataSourceLoaded && processedPref === staticData.length) {
              results = results.sort((data1: any, data2: any) =>
                GridHelper.sortByNumberValue(data1.SortOrder, data2.SortOrder)
              );
              return ob.next(results.sort((a: any, b: any) => a.SortOrder - b.SortOrder));
            }
          });
        } else {
          processedPref += 1;
          results.push(pref);
        }
      }

      if (dataSourceCount === 0) {
        results = results.sort((data1: any, data2: any) =>
          GridHelper.sortByNumberValue(data1.SortOrder, data2.SortOrder)
        );
        return ob.next(results.sort((a: any, b: any) => a.SortOrder - b.SortOrder));
      }
    });
  }

  private loadComboboxDataSource(preferences: PreferenceViewModel[], control: PreferenceViewModel): Observable<void> {
    return Observable.create((ob: any) => {
      const listDataSourceParts: any = this.getPartsFromListDataSourceStringOfCombobox(control.listDataSource);
      control.UnderlyingId = listDataSourceParts.UnderlyingId;
      control.DisplayText = listDataSourceParts.DisplayText;

      if (this.hasDataOnClient(listDataSourceParts.api)) {
        control.DataSource = this.apiReponses[listDataSourceParts.api];
        return ob.next(control);
      }

      this.apiReponses[listDataSourceParts.api] = []; // Block request with the same api route.
      this.getComboboxDataSource(listDataSourceParts.api).subscribe((data: any) => {
        this.apiReponses[listDataSourceParts.api] = data;
        control.DataSource = data;

        // Update other controls.
        if (preferences && preferences.length > 0) {
          preferences.forEach((pref: PreferenceViewModel) => {
            const prefSourceParts: any = this.getPartsFromListDataSourceStringOfCombobox(pref.listDataSource);
            if (prefSourceParts.api === listDataSourceParts.api) {
              pref.DataSource = data;
            }
          });
        }

        return ob.next(control);
      });
    });
  }

  private getComboboxDataSource(api: string): Observable<any> {
    const path: string = environment.apiUrl + '/' + api;
    return this.dataService.httpGet(path);
  }

  private hasDataOnClient(api: string): boolean {
    return this.apiReponses[api];
  }

  private getPartsFromListDataSourceStringOfCombobox(stringListDataSource: string): any {
    stringListDataSource = stringListDataSource.replace(':', ';');
    const parts: string[] = stringListDataSource.split(';');
    return {
      api: parts[0],
      UnderlyingId: parts[1],
      DisplayText: parts[2]
    };
  }

  private foundSetting(key: string, pairs: ISimpleKeyValuePair[]): ISimpleKeyValuePair {
    for (const pair of pairs) {
      if (pair.Key === key) {
        return pair;
      }
    }

    return null;
  }

  public updateCompanySetting(item: ISimpleKeyValuePair) {
    return this.dataService.Preferences_SetCompanyPreference(item).pipe(
      tap((response: ISimpleKeyValuePair) => {
        const currentCompanySettings: ISimpleKeyValuePair[] = JSON.parse(JSON.stringify(Global.COMPANY_PREFERENCES));
        Global.COMPANY_PREFERENCES = this.updateLocalStorageSetting(response, currentCompanySettings);
      })
    );
  }

  public updateUserSetting(item: ISimpleKeyValuePair) {
    return this.dataService.Preferences_SetUserPreference(item).pipe(
      tap((response: ISimpleKeyValuePair) => {
        const currentUserSettings: ISimpleKeyValuePair[] = JSON.parse(JSON.stringify(Global.USER_PREFERENCES));
        Global.USER_PREFERENCES = this.updateLocalStorageSetting(response, currentUserSettings);
      })
    );
  }

  private updateLocalStorageSetting(
    newSetting: ISimpleKeyValuePair,
    oldSettings: ISimpleKeyValuePair[]
  ): ISimpleKeyValuePair[] {
    const existingSetting: ISimpleKeyValuePair = oldSettings.find((pref: any) => pref.Key === newSetting.Key);
    if (existingSetting) {
      existingSetting.Value = newSetting.Value;
    } else {
      oldSettings.push(newSetting);
    }

    return oldSettings;
  }

  public isDataTypeBoolean(dataType: string): boolean {
    return dataType.includes('CheckBox') || dataType.includes('Checkbox');
  }

  public isDataTypeNumber(dataType: string): boolean {
    return dataType.includes('Integer');
  }

  public isDataTypePercent(dataType: string): boolean {
    return dataType.includes('Percentage');
  }

  public isDataTypeTextBox(dataType: string): boolean {
    return dataType.includes('TextBox');
  }

  public isDataTypeCombobox(dataType: string): boolean {
    return dataType.includes('ComboBox') || dataType.includes('Combobox');
  }

  public reloveData: EventEmitter<boolean> = new EventEmitter<boolean>();
  public reloadPanel: EventEmitter<boolean> = new EventEmitter<boolean>();

  public updateSetting(item: ISimpleKeyValuePair): void {
    if (this.isCompanySetting) {
      this.updateCompanySetting(item).subscribe(
        (): void => {},
        (complete: any): void => {
          if (complete.status) {
            this.reloveData.emit(true);
          }
        }
      );
    } else {
      this.updateUserSetting(item).subscribe(
        (): void => {},
        (complete: any): void => {
          if (complete.status) {
            this.reloveData.emit(true);
          }
        }
      );
    }
  }

  public submitValueChange(value: any, item: PreferenceViewModel | any): void {
    item.Value = value;

    const itemToSubmit: ISimpleKeyValuePair = { Key: item.Key, Value: '' + item.Value };
    this.updateSetting(itemToSubmit);
    item.dirty = false;
  }

  public textControlChanges(item: PreferenceViewModel | any): void {
    if (item) {
      item.dirty = true;
    }
  }

  public textControlKeyUp(key: any, item: PreferenceViewModel | any): void {
    if (key.key === 'Enter' && item.dirty) {
      this.submitValueChange(item.Value, item);
    }
  }

  public textControlBlur(item: PreferenceViewModel | any): void {
    if (item.dirty) {
      this.submitValueChange(item.Value, item);
    }
  }

  public get isCompanySetting(): boolean {
    return this.sessionService.currentState.indexOf('tabs.company.configuration') >= 0;
  }

  protected get allowShortcut(): boolean {
    return (
      this.sessionService.currentState === 'tabs.account.userpreferences' ||
      this.sessionService.currentState === 'tabs.company.configuration.settings'
    );
  }
}
