import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Global } from '../../common/global';
import {
  IAccountCompanyCreationRequest,
  ICompany,
  IRole,
  ISimpleCompany,
  IVatCompany
} from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { AccountantCompaniesService } from './accountant-companies.service';

@Component({
  selector: 'app-accountant-add-new-company-dialog',
  styleUrls: ['./accountant-companies.component.scss'],
  templateUrl: './accountant-add-new-company-dialog.component.html'
})
export class AccountantAddNewCompanyDialogComponent {
  public visibleValue = false;
  @Input()
  get visible(): boolean {
    return this.visibleValue;
  }
  set visible(value: boolean) {
    this.visibleValue = value;
    this.visibleChange.emit(value);
    if (!value) {
      this.cvrNumber = undefined;
      this.seNumber = undefined;
      this.name = undefined;
      this.modulePackage = -1;
      this.salaryTypesFrom = undefined;
      this.templatesFrom = undefined;
      this.grantAccess = false;
      this.accessRole = undefined;
      this.seNumberRequired = false;
      this.CVRNumberValid = false;
      this.nameValid = false;
      this.seNumberRequired = false;
      this.acceptTerms = false;
      this.accessRoleValid = false;
    }
  }
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() createSuccess: EventEmitter<any> = new EventEmitter<any>();

  public get isValid(): boolean {
    return (
      this.cvrNumber &&
      this.name &&
      (!this.grantAccess || !!this.accessRole) &&
      (!this.seNumberRequired || !!this.seNumber)
    );
  }

  public get nonValueLableAccessRole(): string {
    if (this.grantAccess) {
      return '';
    }
    return ' ';
  }

  public get branding() {
    return environment.branding;
  }

  public cvrNumber: string;
  public CVRNumberValid = false;
  public seNumber: string;
  public seNumberRequired: boolean;
  public name: string;
  public nameValid = false;
  public modulePackage = -1;
  public salaryTypesFrom: number;
  public templatesFrom: number;
  public grantAccess: boolean;
  public accessRole: number;
  public accessRoleValid = false;
  public acceptTerms: boolean;
  public isWaittingCreat = false;
  public isWrongValue = false;
  public sourceCompanyFilter: any[] = [];
  public confimTermVisible = false;

  constructor(
    public service: AccountantCompaniesService,
    public staticData: StaticDataService,
    private api: DataService
  ) {
    this.staticData.companiesSimple.subscribe((data: ISimpleCompany[]) => {
      if (data && data.length > 0) {
        data.forEach((model: any) => {
          model.Relevant = model.Name + (Global.SESSION.HasBackendAccess ? ' (' + model.Id + ')' : '');
        });
        this.sourceCompanyFilter = data.filter((model: any) => model.RoleId >= 30);
      }
    });
  }

  public lookupCompany(): void {
    this.isWrongValue = false;
    if (this.cvrNumber) {
      this.api.Companies_GetByCvr(this.cvrNumber).subscribe((data: IVatCompany) => {
        if (data) {
          if (data.CountryId !== 2) {
            this.seNumberRequired = data.ExistsInIntect;
          } else {
            this.seNumberRequired = false;
            this.seNumber = undefined;
          }
          this.name = data.Name;
        } else {
          this.seNumberRequired = false;
          this.seNumber = undefined;
        }
      });
    } else {
      this.seNumberRequired = false;
      this.seNumber = undefined;
    }
  }

  public isValidValue(): void {
    if (this.cvrNumber) {
      this.CVRNumberValid = true;
    }
    if (this.name) {
      this.nameValid = true;
    }
  }

  public CVRKeyUp(): void {
    if (this.cvrNumber) {
      this.cvrNumber = this.cvrNumber.replace(/[^0-9-]+/g, '');
    }
  }

  private setDefaultAccessRole(): void {
    this.staticData.Role.subscribe((model: IRole[]) => {
      if (model && model.length > 0) {
        this.accessRole = model[2].Id;
      }
    });
  }

  private ValidateInputData(): boolean {
    if (!this.isValid) {
      if (!this.cvrNumber) {
        this.CVRNumberValid = true;
      }
      if (!this.name) {
        this.nameValid = true;
      }
      if (this.grantAccess && !this.accessRole) {
        this.accessRoleValid = true;
      }
      return false;
    }
    return true;
  }

  public grantAccessChange(): void {
    if (this.grantAccess && !this.accessRole) {
      this.setDefaultAccessRole();
    } else {
      this.accessRole = undefined;
    }
  }

  public dialogAction(action: string): void {
    if (action === 'Create') {
      if (!this.ValidateInputData()) {
        return;
      }
      if (!this.acceptTerms) {
        this.confimTermVisible = true;
        return;
      }
      const request: IAccountCompanyCreationRequest = {
        CompanyName: this.name,
        DefaultRoleId: this.grantAccess ? this.accessRole : undefined,
        PackageLevel: this.modulePackage === -1 ? undefined : this.modulePackage,
        VatRegistrationNumber: this.cvrNumber,
        SecondaryVatRegistrationNumber: this.seNumber,
        CopySalaryAndTimeEntryTypesFromCompanyId: this.salaryTypesFrom,
        CopyTemplatesFromCompanyId: this.templatesFrom
      };

      // if (this.salaryTypesFrom) {
      //   const match: RegExpExecArray = new RegExp(/\[(\d+)\]$/).exec(this.salaryTypesFrom);
      //   if (match && match.length === 2) {
      //     request.CopySalaryAndTimeEntryTypesFromCompanyId = parseInt(match[1], 10);
      //   }
      // }

      // if (this.templatesFrom) {
      //   const match: RegExpExecArray = new RegExp(/\[(\d+)\]$/).exec(this.templatesFrom);
      //   if (match && match.length === 2) {
      //     request.CopyTemplatesFromCompanyId = parseInt(match[1], 10);
      //   }
      // }

      this.isWaittingCreat = true;
      this.api.Account_CreateCompany(request).subscribe(
        (model: ICompany) => {
          this.service.filter = this.name;
          this.createSuccess.emit(model.Id);
          this.isWaittingCreat = false;
          this.visible = false;
        },
        (err: any) => {
          if (err.status === 400) {
            this.isWrongValue = true;
            this.isWaittingCreat = false;
          } else {
            this.visible = false;
            this.isWaittingCreat = false;
          }
        }
      );
    }
  }
}
