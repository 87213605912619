<span kendoTooltip #tooltip="kendoTooltip">
  <button
    #buttonTooltip
    class="Action-button Action-buttonEdit"
    [ngClass]="buttonClass"
    *ngIf="isVisible"
    type="button"
    accesskey="e"
    (click)="onEditButtonClick(buttonTooltip)"
    (dblclick)="isFirstClick = true"
    [disabled]="isFirstClick"
  >
    <div *ngIf="!disable" class="Action-buttonInner">
      <div class="{{ disableVisibleClass }}">
        <app-icon class="iconButton-save" [tooltip]="EditTooltip | translate" [icon]="'Edit'"></app-icon>
        <div class="u-hiddenVisually">{{ 'Employee.Edit' | translate }}</div>
      </div>
      <div class="{{ enableVisibleClass }}">
        <app-icon class="SaveIcon" [icon]="'Check'"></app-icon>
        <h3>{{ 'Tabs.Save' | translate }}</h3>
        <div class="u-hiddenVisually">{{ 'Employee.Save' | translate }}</div>
      </div>
    </div>
    <div *ngIf="disable" class="Action-buttonInner">
      <div>
        <app-icon class="iconButton-save" [tooltip]="EditTooltip | translate" [icon]="'Edit'"></app-icon>
        <div class="u-hiddenVisually">{{ 'Employee.Edit' | translate }}</div>
      </div>
    </div>
  </button>
</span>
