import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { WindowReference } from '../../../../windowReference';
import { GridHelper } from '../../../common/grid-helper';
import { IModuleCompanyView, ModuleCompanyView } from '../../../services/api/api-model';
import { SessionService } from '../../../services/session/session.service';
import { CompanyModuleService } from '../company-modules.service';
import { ModulePackage } from './module-package';

@Component({
  selector: 'price',
  templateUrl: './price.component.html'
})
export class PriceComponent implements OnInit, OnDestroy {
  public modules: IModuleCompanyView[];
  public submitEnabled = false;
  public packages: ModulePackage[] = [];
  public priceElements: string[] = [];
  public totalCost = 0;
  public initialModules: number[];
  public isLoading = true;
  public calculatedPricePerPayslip = 0;
  public listClassExcluded = ['Footer', '5'];

  constructor(
    private windowRef: WindowReference,
    private sessionService: SessionService,
    public companyModuleService: CompanyModuleService
  ) {
    this.companyModuleService.modules.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IModuleCompanyView[]) => {
      this.modules = data;
      this.buildPackages().then(() => {
        if (data.length > 0) {
          this.isLoading = false;
        }
      });
    });
  }

  public get branding() {
    return environment.branding;
  }

  public ngOnInit(): void {
    this.companyModuleService.loadBilledMessage();
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onSubmit(): void {
    this.companyModuleService.onSubmit(this.selectedPackageId);
  }

  public onPackageChange(packageId: number): void {
    const modulePackage: ModulePackage = this.packages.find((p: ModulePackage) => p.PackageId === packageId);
    modulePackage.Selected = !modulePackage.Selected;
    if (modulePackage.Selected) {
      this.selectPackage(modulePackage);
    } else {
      this.deSelectPackage(modulePackage);
    }
    this.updateTotal();
  }

  public automaticReselectOfPackages(activate: boolean): void {
    const nonExtraPackages: ModulePackage[] = this.packages
      .filter((p: ModulePackage) => p.PackageId !== 9999)
      .sort((a: ModulePackage, b: ModulePackage) => GridHelper.sortByNumberValue(b.Price, a.Price))
      .reverse();
    const nonExtraModulesArrays: IModuleCompanyView[][] = nonExtraPackages.map((mp: ModulePackage) => mp.Modules);
    const nonExtraModules: IModuleCompanyView[] = ([] as IModuleCompanyView[]).concat.apply([], nonExtraModulesArrays);

    if (activate) {
      // Logic to select package if price of modules exceeds pack price
      let selectPackageId: number;
      let totalModulePrice = 0;
      let currentPackagePrice = this.getPackage(this.selectedPackageId).Price || 0;

      if (currentPackagePrice > 0) {
        let totalNonPackagePrice = 0;

        for (let index = 0; index <= nonExtraPackages.length - 1; index++) {
          const p: ModulePackage = nonExtraPackages[index];
          if (
            !nonExtraModules.some(
              (module: IModuleCompanyView) =>
                module.PackageId === p.PackageId && module.IsEnabled && module.ModulePrice > 0
            )
          ) {
            continue;
          }
          totalNonPackagePrice = this.calcTotalActiveModulePrice(p.PackageId);
          totalModulePrice = totalNonPackagePrice + currentPackagePrice;
          if (totalModulePrice >= p.Price && p.Price > currentPackagePrice) {
            selectPackageId = p.PackageId;
            currentPackagePrice = p.Price;
          }
        }
      } else {
        for (let index = 0; index <= nonExtraPackages.length - 1; index++) {
          const p: ModulePackage = nonExtraPackages[index];
          if (
            !nonExtraModules.some(
              (module: IModuleCompanyView) =>
                module.PackageId === p.PackageId && module.IsEnabled && module.ModulePrice > 0
            )
          ) {
            continue;
          }

          const currentModulePrice = this.calcTotalActiveModulePrice(p.PackageId);
          totalModulePrice += currentModulePrice;
          if (currentPackagePrice > 0) {
            if (currentModulePrice + currentPackagePrice >= p.Price && p.Price > currentPackagePrice) {
              selectPackageId = p.PackageId;
              currentPackagePrice = p.Price;
            } else if (totalModulePrice > p.Price) {
              selectPackageId = p.PackageId;
              currentPackagePrice = p.Price;
            }
          } else {
            if (currentModulePrice >= p.Price && p.Price > currentPackagePrice) {
              selectPackageId = p.PackageId;
              currentPackagePrice = p.Price;
            } else if (totalModulePrice > p.Price) {
              selectPackageId = p.PackageId;
              currentPackagePrice = p.Price;
            }
          }
        }
      }

      if (selectPackageId && selectPackageId !== 0) {
        const currentPackage = this.getPackage(selectPackageId);
        if (totalModulePrice >= currentPackage.Price && selectPackageId !== this.selectedPackageId) {
          this.selectPackage(currentPackage);
          this.showAutoSelectPackageHint();
        }
      }
    } else {
      this.showAutoSelectPackageHint(false);
      let selectPackage: ModulePackage;
      for (let index = nonExtraPackages.length - 1; index >= 0; index--) {
        const p: ModulePackage = nonExtraPackages[index];
        const totalModulePrice = this.calcTotalActiveModulePrice(p.PackageId);

        if (
          totalModulePrice >= p.Price &&
          nonExtraModules
            .filter((m: IModuleCompanyView) => m.PackageId <= p.PackageId)
            .every((m: IModuleCompanyView) => m.IsEnabled)
        ) {
          selectPackage = p;
          break;
        }
      }

      let deselectPackage: ModulePackage = nonExtraPackages.find((p: ModulePackage) => p.Selected);
      deselectPackage === undefined ? (deselectPackage = undefined) : (deselectPackage.Selected = false);

      if (selectPackage !== null && selectPackage !== undefined) {
        selectPackage.Selected = true;
      }
    }
  }

  private getPackageSelectedModulesPrice(
    packages: ModulePackage[],
    nonExtraModules: IModuleCompanyView[],
    index: number
  ): number {
    let packagePrice = 0;
    const modulePackage: ModulePackage = index < packages.length ? packages[index] : undefined;
    if (modulePackage) {
      if (
        nonExtraModules
          .filter((fm: IModuleCompanyView) => fm.PackageId <= modulePackage.PackageId)
          .every((m: IModuleCompanyView) => m.IsEnabled)
      ) {
        packagePrice += modulePackage.Price;
      } else {
        nonExtraModules
          .filter((m: IModuleCompanyView) => m.PackageId === modulePackage.PackageId && m.IsEnabled)
          .forEach((m: IModuleCompanyView) => {
            packagePrice += m.ModulePrice;
          });

        packagePrice += this.getPackageSelectedModulesPrice(packages, nonExtraModules, index + 1);
      }
    }

    return packagePrice;
  }

  public AutoSelectPackageActive: boolean;
  private showAutoSelectPackageHint(active: boolean = true): void {
    this.AutoSelectPackageActive = active;
  }

  public onModuleChange(moduleId: number): void {
    const module: IModuleCompanyView = this.modules.find((m: IModuleCompanyView) => m.ModuleId === moduleId);
    if (module.InfoOnly === true || module.FreeUntilDate !== null) {
      return;
    }

    module.IsEnabled = !module.IsEnabled;

    const parentPackage: ModulePackage = this.packages.find((p: ModulePackage) => p.PackageId === module.PackageId);
    if (parentPackage) {
      this.automaticReselectOfPackages(module.IsEnabled);
    }

    this.updateTotal();
  }

  public get tooltipPosition(): string {
    return this.windowRef && this.windowRef.nativeWindow && this.windowRef.nativeWindow.innerWidth < 790
      ? 'left'
      : 'top';
  }

  public get tooltipWidth(): number {
    const width: number = this.windowRef && this.windowRef.nativeWindow ? this.windowRef.nativeWindow.innerWidth : 800;
    const result: number = width > 790 ? 500 : 500 - (790 - width) - 55;
    return result > 150 ? result : 150;
  }

  public get selectedPackageId(): number {
    const selectedPackage: ModulePackage = this.packages.find((p: ModulePackage) => p.Selected);
    return selectedPackage ? selectedPackage.PackageId : 0;
  }

  public getPackage(packageId: number): ModulePackage {
    return this.packages.find((p: ModulePackage) => p.PackageId === packageId) || {};
  }

  public getModule(moduleId: number): IModuleCompanyView {
    const module: IModuleCompanyView =
      this.modules.find((m: IModuleCompanyView) => m.ModuleId === moduleId) || new ModuleCompanyView();
    return module;
  }

  private selectPackage(modulePackage: ModulePackage): void {
    modulePackage.Selected = true;
    this.packages
      .filter((p: ModulePackage) => p.PackageId !== modulePackage.PackageId && p.Selected)
      .forEach((p: ModulePackage) => {
        this.deSelectPackage(p);
      });
    const modulesToEnable: any = this.modules.filter(
      (m: IModuleCompanyView) => (m.PackageId ? m.PackageId : Infinity) <= modulePackage.PackageId
    );
    modulesToEnable.forEach((m: IModuleCompanyView) => (m.IsEnabled = true));
  }

  private deSelectPackage(modulePackage: ModulePackage): void {
    modulePackage.Selected = false;
    this.modules
      .filter(
        (m: IModuleCompanyView) =>
          m.FreeUntilDate === null && !m.InfoOnly && (m.PackageId ? m.PackageId : Infinity) <= modulePackage.PackageId
      )
      .forEach((m: IModuleCompanyView) => (m.IsEnabled = false));
  }

  public updateTotal(): void {
    setTimeout(() => {
      this.priceElements = this.modules
        .filter((m: IModuleCompanyView) => m.IsEnabled)
        .map((m: IModuleCompanyView) => m.ModuleName);
      this.totalCost = this.calculateCost();
      const currentModules: number[] = this.modules
        // .filter((m: IModuleCompanyView) => m.IsEnabled && !m.InfoOnly)
        .filter((m: IModuleCompanyView) => m.IsEnabled)
        .map((val: IModuleCompanyView) => val.ModuleId)
        .sort();
      const modulesIncludedInPerPayslipCalc: IModuleCompanyView[] = this.modules.filter(
        // (m: IModuleCompanyView) => m.IsEnabled && m.ModuleBillingPrincipleId === 1 && !m.InfoOnly && !m.FreeUntilDate
        (m: IModuleCompanyView) => m.IsEnabled && m.ModuleBillingPrincipleId === 1 && !m.FreeUntilDate
      );
      const prices: number[] = modulesIncludedInPerPayslipCalc.map((m: IModuleCompanyView) => {
        if (this.selectedPackageId !== 0) {
          if (m.PackageId !== 0 && m.PackageId !== null && m.PackageId <= this.selectedPackageId) {
            return 0;
          } else {
            return m.ModulePrice;
          }
        } else {
          return m.ModulePrice;
        }
      });
      const currentPackagePrice: number = this.getPackage(this.selectedPackageId).Price;
      if (currentPackagePrice !== undefined) {
        prices.push(currentPackagePrice);
      }

      this.calculatedPricePerPayslip = prices.reduce((a: number, b: number) => a + b, 0);
      if (JSON.stringify(this.initialModules) !== JSON.stringify(currentModules)) {
        this.submitEnabled = true;
      } else {
        this.submitEnabled = false;
      }
    });
  }

  private calculateCost(): number {
    let total = 0;
    if (this.packages) {
      const selectedPackage: ModulePackage = this.packages.find((p: ModulePackage) => p.Selected);
      let selectedPackageId = 0;
      if (selectedPackage) {
        selectedPackageId = selectedPackage.PackageId;
        total = this.packagePrice(selectedPackage);
      }

      const otherModules: IModuleCompanyView[] = this.modules.filter(
        (m: IModuleCompanyView) =>
          m.FreeUntilDate === null && m.IsEnabled && (m.PackageId ? m.PackageId : Infinity) > selectedPackageId
      );
      if (otherModules) {
        otherModules.forEach((m: IModuleCompanyView) => (total += this.modulePrice(m)));
      }

      total = Math.round(total * 100) / 100;
    }

    return total;
  }

  private packagePrice(modulePackage: ModulePackage): number {
    return this.companyModuleService.calculatePriceByBillingPrinciple(
      modulePackage.BillingPrincipleId,
      modulePackage.Price
    );
  }

  private modulePrice(module: IModuleCompanyView): number {
    return this.companyModuleService.calculatePriceByBillingPrinciple(
      module.ModuleBillingPrincipleId,
      module.ModulePrice
    );
  }

  private async buildPackages(): Promise<void> {
    this.packages = [];
    this.modules
      .filter((m: IModuleCompanyView) => m.InfoOnly || m.FreeUntilDate !== null)
      .forEach((m: IModuleCompanyView) => (m.IsEnabled = true));
    for (const module of this.modules) {
      const packageId: number = module.PackageId || 9999;
      if (module.ModuleName.indexOf('November First') >= 0 && module.ModuleId === 14) {
        continue;
      }
      if (!this.packages[packageId]) {
        this.packages[packageId] = {
          PackageId: packageId,
          Selected: false,
          Name: module.PackageName || 'Ekstra moduler',
          Description: module.PackageDescription || '',
          Price: module.PackagePrice || 0,
          BillingPrinciple: module.PackageBillingPrinciple || '',
          BillingPrincipleId: module.PackageBillingPrincipleId || 4,
          Modules: []
        };
      }

      this.packages[packageId].Modules.push(module);
    }

    this.packages = this.packages.filter((p: ModulePackage) => p.PackageId);
    const selectedPackage: ModulePackage = this.packages.find(
      (p: ModulePackage) => p.PackageId === this.modules[0].CompanyCurrentPackageLevel
    );
    if (selectedPackage) {
      this.selectPackage(selectedPackage);
    }

    this.updateTotal();
    this.initialModules = this.modules
      .filter((m: IModuleCompanyView) => m.IsEnabled && !m.InfoOnly)
      .map((val: IModuleCompanyView) => {
        return val.ModuleId;
      })
      .sort();
  }

  public isModuleLocked(moduleId: number): boolean {
    return this.getModule(moduleId).InfoOnly || this.getModule(moduleId).FreeUntilDate !== null;
  }

  public isModulePriceStrikethrough(moduleId: number): boolean {
    const module: IModuleCompanyView = this.getModule(moduleId);
    let minSelectedPackageId: number = module.PackageId;
    if (module.ModulePrice === 0) {
      return false;
    } else {
      if (module.FreeUntilDate !== null) {
        return true;
      }
      while (minSelectedPackageId <= 3) {
        if (this.getPackage(minSelectedPackageId).Selected && module.PackageId !== null) {
          return true;
        }
        minSelectedPackageId++;
      }
      return false;
    }
  }

  public moduleTooltipHtml(link: string, des: string): string {
    if (link && des) {
      if (this.sessionService.browser.iOSMobileDevice && this.sessionService.browser.isHybridApp) {
        return '<a class="tooltipStyle">' + des + '</a>';
      }
      return '<a class="tooltipStyle" href="' + link + '" target="_blank">' + des + '</a>';
    }
    return '';
  }

  public calcTotalActiveModulePrice(packageId: number): number {
    return this.modules.reduce((prev: number, module: IModuleCompanyView) => {
      if (module.IsEnabled && module.PackageId === packageId) {
        return prev + module.ModulePrice;
      }
      return prev;
    }, 0);
  }
}
