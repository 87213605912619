/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./salary-batch-payslip-icons.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../custom-controls/buttons/payslip-icon.component.ngfactory";
import * as i3 from "../../../../custom-controls/buttons/payslip-icon.component";
import * as i4 from "../../../../services/session/session.service";
import * as i5 from "@angular/common";
import * as i6 from "./salary-batch-payslip-icons.component";
var styles_SalaryBatchPayslipIconsComponent = [i0.styles];
var RenderType_SalaryBatchPayslipIconsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SalaryBatchPayslipIconsComponent, data: {} });
export { RenderType_SalaryBatchPayslipIconsComponent as RenderType_SalaryBatchPayslipIconsComponent };
function View_SalaryBatchPayslipIconsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payslip-icon", [["id", "ViewPayslipSameTabButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPayslipIconClicked("htmlSameTab") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PayslipIconComponent_0, i2.RenderType_PayslipIconComponent)), i1.ɵdid(1, 49152, null, 0, i3.PayslipIconComponent, [i4.SessionService], { payslipIconType: [0, "payslipIconType"], isHidden: [1, "isHidden"] }, { click: "click" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "htmlSameTab"; var currVal_1 = _co.isHidden; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SalaryBatchPayslipIconsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payslip-icon", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPayslipIconClicked("htmlNewTab") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PayslipIconComponent_0, i2.RenderType_PayslipIconComponent)), i1.ɵdid(1, 49152, null, 0, i3.PayslipIconComponent, [i4.SessionService], { payslipIconType: [0, "payslipIconType"] }, { click: "click" })], function (_ck, _v) { var currVal_0 = "htmlNewTab"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SalaryBatchPayslipIconsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payslip-icon", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPayslipIconClicked("pdf") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PayslipIconComponent_0, i2.RenderType_PayslipIconComponent)), i1.ɵdid(1, 49152, null, 0, i3.PayslipIconComponent, [i4.SessionService], { payslipIconType: [0, "payslipIconType"] }, { click: "click" })], function (_ck, _v) { var currVal_0 = "pdf"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SalaryBatchPayslipIconsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payslip-icon", [["class", "print-icon-flex"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPayslipIconClicked("htmlPrint") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PayslipIconComponent_0, i2.RenderType_PayslipIconComponent)), i1.ɵdid(1, 49152, null, 0, i3.PayslipIconComponent, [i4.SessionService], { payslipIconType: [0, "payslipIconType"] }, { click: "click" })], function (_ck, _v) { var currVal_0 = "htmlPrintTab"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SalaryBatchPayslipIconsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "right right--flex payslip-icons"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SalaryBatchPayslipIconsComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SalaryBatchPayslipIconsComponent_3)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SalaryBatchPayslipIconsComponent_4)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SalaryBatchPayslipIconsComponent_5)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.IsAppOrDesktop; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.IsWeb; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.IsFinalizedBatch; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.IsWeb; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_SalaryBatchPayslipIconsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "FormEmlement Company-totalsPayslipsSearch Company-payslipLabel"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SalaryBatchPayslipIconsComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "FormElement-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hideDownloadIcons; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.selectedSalaryStatement == null) ? null : _co.selectedSalaryStatement.UserFullName); _ck(_v, 4, 0, currVal_1); }); }
export function View_SalaryBatchPayslipIconsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-salary-batch-payslip-icons", [], null, null, null, View_SalaryBatchPayslipIconsComponent_0, RenderType_SalaryBatchPayslipIconsComponent)), i1.ɵdid(1, 49152, null, 0, i6.SalaryBatchPayslipIconsComponent, [i4.SessionService], null, null)], null, null); }
var SalaryBatchPayslipIconsComponentNgFactory = i1.ɵccf("app-salary-batch-payslip-icons", i6.SalaryBatchPayslipIconsComponent, View_SalaryBatchPayslipIconsComponent_Host_0, { selectedSalaryStatement: "selectedSalaryStatement", IsFinalizedBatch: "IsFinalizedBatch" }, { downloadPayslipClick: "downloadPayslipClick" }, []);
export { SalaryBatchPayslipIconsComponentNgFactory as SalaryBatchPayslipIconsComponentNgFactory };
