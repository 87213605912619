import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import {
  IDepartment,
  IDepartmentSalaryTypeMapping,
  IExternalSystem,
  IExternalSystemConfiguration,
  IExternalSystemCredential,
  IExternalSystemOperation,
  IIntegration,
  IIntegrationConfiguration,
  IIntegrationCredential,
  IIntegrationOperation,
  IIntegrationStatus,
  IModuleCompanyView,
  ISalaryTypeView,
  ISimpleKeyValuePair,
  SalaryType
} from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { CompanyService } from 'src/app/services/company.service';
import { SessionService } from 'src/app/services/session/session.service';
import { SettingService } from 'src/app/services/setting.service';
import { environment } from 'src/environments/environment';
import { SalaryTypeView } from '../advanced-types/salary-types/salary-type-view';
import { IntegrationOperation, integrationscontrol, referenceSource } from './integrationscontrol';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService extends CompanyService implements OnDestroy {
  private integrationsSubject: BehaviorSubject<IIntegration[]>;
  public get integrations(): Observable<IIntegration[]> {
    if (!this.integrationsSubject) {
      this.integrationsSubject = new BehaviorSubject<IIntegration[]>([]);
    }
    return this.integrationsSubject.asObservable().pipe(
      tap((data: IIntegration[]) => {
        if (data.length === 0) {
          this.resetForm();
        }

        if (this.IdCreate) {
          const newIntegration = data.find((z: IIntegration) => z.Id === this.IdCreate);
          this.selectedId = newIntegration ? newIntegration.Id : null;
        } else if (!this.isHasIntegrationSelected && data && data.length > 0) {
          this.selectedId = data[0].Id;
        }
      })
    );
  }

  private integrationSubject: BehaviorSubject<IIntegration>;
  public get integration(): Observable<IIntegration> {
    if (!this.integrationSubject) {
      this.integrationSubject = new BehaviorSubject<IIntegration>(null);
    }

    return this.integrationSubject.asObservable().pipe(
      tap((model: IIntegration) => {
        this.currentIntegrationModel = model;

        if (this.extenalSystem && this.currentIntegrationModel) {
          this.currentExtenalSystem = this.extenalSystemValue.find(
            (z: IExternalSystem) => z.Id === this.currentIntegrationModel.ExternalSystemId
          );
          if (this.currentExtenalSystem) {
            this.sortCurrentExtenalSystem();
          }
        }
        this.cleanUpControl();

        if (this.currentExtenalSystem) {
          this.listControlConfiguration();
        }
      })
    );
  }

  private standardSalaryTypeViewSubject: BehaviorSubject<SalaryTypeView[]>;
  public get standardSalaryTypeView(): Observable<SalaryTypeView[]> {
    if (!this.standardSalaryTypeViewSubject) {
      this.standardSalaryTypeViewSubject = new BehaviorSubject<SalaryTypeView[]>([]);
    }

    return this.standardSalaryTypeViewSubject.asObservable();
  }

  public loadStandardSalaryTypeView() {
    if (!this.standardSalaryTypeViewSubject) {
      this.standardSalaryTypeViewSubject = new BehaviorSubject<SalaryTypeView[]>([]);
    }
    return this.dataService.SalaryTypes_GetAllSalaryTypeViews().pipe(
      tap((data: ISalaryTypeView[]) => {
        const res = data.map((s: ISalaryTypeView) => {
          const base: ISalaryTypeView = s.BaseSalaryTypeId
            ? data.find((sType: ISalaryTypeView) => sType.SalaryTypeId === s.BaseSalaryTypeId)
            : undefined;
          s.Name = s.Name ? s.Name : base ? base.Name : '';

          (s as any).IconField = s.IsAdvanced === true && !this.hasSalaryTypeModule ? 'Diamond' : '';
          (s as any).Editable = !(s.IsAdvanced === true && !this.hasSalaryTypeModule);

          // (s as any).IconField = 'Diamond';
          // (s as any).Editable = false;
          (s as any).classAddOn = (s as any).IconField && (s as any).IconField === 'Diamond' ? 'field-need-module' : '';

          const salaryTypeNew = new SalaryType();
          Object.assign(salaryTypeNew, s);
          salaryTypeNew.Id = s.SalaryTypeId;

          return new SalaryTypeView(salaryTypeNew, false);
        });

        this.standardSalaryTypeViewSubject.next(res);
      })
    );
  }

  private extenalSystemSubject: BehaviorSubject<IExternalSystem[]>;
  public get extenalSystem(): Observable<IExternalSystem[]> {
    if (!this.extenalSystemSubject) {
      this.loadExtenalSystem();
    }
    return this.extenalSystemSubject.asObservable().pipe(
      tap((model: IExternalSystem[]) => {
        this.extenalSystemValue = model;

        if (this.extenalSystemValue && this.extenalSystemValue.length > 0) {
          this.currentExtenalSystemId = this.extenalSystemValue[0].Id;
          this.globalExtenalSystem = this.extenalSystemValue[0];
        }
      })
    );
  }

  private departmentSubject: BehaviorSubject<IDepartment[]>;
  public get department(): Observable<IDepartment[]> {
    if (!this.departmentSubject) {
      this.departmentSubject = new BehaviorSubject<IDepartment[]>([]);
    }

    return this.departmentSubject.asObservable();
  }

  public getStaticDepartment() {
    if (!this.departmentSubject) {
      this.departmentSubject = new BehaviorSubject<IDepartment[]>([]);
    }

    this.staticDataService.departments.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IDepartment[]) => {
      this.departmentSubject.next(data);
    });
  }

  private loadExtenalSystem() {
    if (!this.extenalSystemSubject) {
      this.extenalSystemSubject = new BehaviorSubject<IExternalSystem[]>([]);
    }

    // Api load exter nal system
    this.staticDataService.ExternalSystem.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IExternalSystem[]) => {
      data = data.filter((model: IExternalSystem) => model.IsActive === true && model.Id !== -1);
      this.extenalSystemSubject.next(data);
    });
  }

  private statusCodeSubject: BehaviorSubject<IIntegrationStatus[]>;
  public get statusCode(): Observable<IIntegrationStatus[]> {
    if (!this.statusCodeSubject) {
      this.loadStatusCode();
    }

    return this.statusCodeSubject.asObservable();
  }

  private loadStatusCode() {
    if (!this.statusCodeSubject) {
      this.statusCodeSubject = new BehaviorSubject<IIntegrationStatus[]>([]);
    }
    this.staticDataService.IntegrationStatus.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IIntegrationStatus[]) => {
        this.statusCodeSubject.next(data);
      }
    );
  }

  private departmentSalaryTypeMappingSubject: BehaviorSubject<IDepartmentSalaryTypeMapping[]>;
  public get departmentSalaryTypeMapping(): Observable<IDepartmentSalaryTypeMapping[]> {
    if (!this.departmentSalaryTypeMappingSubject) {
      this.departmentSalaryTypeMappingSubject = new BehaviorSubject<IDepartmentSalaryTypeMapping[]>([]);
    }

    return this.departmentSalaryTypeMappingSubject.asObservable();
  }

  public setDataDepartmentSalaryTypeMapping(data: IDepartmentSalaryTypeMapping[]) {
    if (!this.departmentSalaryTypeMappingSubject) {
      this.departmentSalaryTypeMappingSubject = new BehaviorSubject<IDepartmentSalaryTypeMapping[]>([]);
    }

    this.departmentSalaryTypeMappingSubject.next(data);
  }

  private selectedIdValue: number;
  public get selectedId(): number {
    return this.selectedIdValue;
  }

  public set selectedId(value: number) {
    if (value !== this.selectedIdValue) {
      this.selectedIdValue = value;

      if (value) {
        this.loadIntegrationDetail();
      }
    }
  }

  private departmentIdValue: number;
  public get departmentId(): number {
    return this.departmentIdValue;
  }

  public set departmentId(value: number) {
    if (value !== this.departmentIdValue) {
      this.departmentIdValue = value;
      if (value) {
        this.loadDepartmentMapping();
      } else {
        this.TriggerReload.emit(true);
      }
    }
  }

  public loadDepartmentMapping() {
    if (!this.departmentSalaryTypeMappingSubject) {
      this.departmentSalaryTypeMappingSubject = new BehaviorSubject<IDepartmentSalaryTypeMapping[]>([]);
    }

    this.dataService
      .SalaryTypes_GetDepartmentSalaryTypeMappings(this.departmentIdValue)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        tap((data: IDepartmentSalaryTypeMapping[]) => {
          this.departmentSalaryTypeMappingSubject.next(data);
        })
      )
      .subscribe();
  }

  public get isEmptyIntegrations() {
    return !this.allIntegration || (this.allIntegration && this.allIntegration.length === 0);
  }

  public get isReadonlyUser() {
    return this.sessionService.role.IsReadOnly;
  }

  public get isHasIntegrationSelected(): boolean {
    if (this.selectedId && this.allIntegration && this.allIntegration.length > 0) {
      return !!this.allIntegration.find((item: IIntegration) => this.selectedId === item.Id);
    }
    return false;
  }

  public allIntegration: IIntegration[];
  public configurationControl: integrationscontrol[] = [];
  public credentialControl: integrationscontrol[] = [];
  public integrationConfigurationControl: integrationscontrol[] = [];

  public currentExtenalSystem: IExternalSystem;
  public globalExtenalSystem: IExternalSystem;
  public currentIntegrationModel: IIntegration;

  public currentExtenalSystemId: number;
  public IdCreate: number;
  public nextIntegrationIdChange: number;
  public hasIntegrationModule: boolean;
  public addNewsDialogVisible: boolean;

  public hasSalaryTypeModule: boolean;
  public moduleSalaryTypePrice: number;
  public moduleSalaryTypeName: string;

  public onContinuteState: EventEmitter<boolean> = new EventEmitter<boolean>();
  public TriggerReload: EventEmitter<boolean> = new EventEmitter<boolean>();

  private externalSystemOperation: IExternalSystemOperation[] = [];
  private externalSystemCredential: IExternalSystemCredential[] = [];
  private externalSystemConfiguration: IExternalSystemConfiguration[] = [];
  private ApiDataList: Array<{ api: string; data: any[] }> = [];
  private extenalSystemValue: IExternalSystem[];

  constructor(
    protected dataService: DataService,
    protected staticDataService: StaticDataService,
    protected settingService: SettingService,
    protected sessionService: SessionService,
    protected transitionService: TransitionService,
    protected broadcaster: BroadcastService
  ) {
    super(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster);
    this.tabValid = true;

    this.staticDataService.ExternalSystemOperation.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IExternalSystemOperation[]) => (this.externalSystemOperation = data)
    );
    this.staticDataService.ExternalSystemCredential.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IExternalSystemCredential[]) => (this.externalSystemCredential = data)
    );
    this.staticDataService.ExternalSystemConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IExternalSystemConfiguration[]) => (this.externalSystemConfiguration = data)
    );
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy() {
    this.currentExtenalSystemId = null;
    this.currentExtenalSystem = null;
    this.globalExtenalSystem = null;
    this.currentIntegrationModel = undefined;
    this.departmentIdValue = undefined;
    this.selectedId = undefined;

    if (this.integrationSubject) {
      this.integrationSubject.next(null);
    }

    if (this.standardSalaryTypeViewSubject) {
      this.standardSalaryTypeViewSubject.next([]);
    }

    if (this.integrationsSubject) {
      this.integrationsSubject.next([]);
    }

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  protected get allowShortcut(): boolean {
    return this.sessionService.currentState === 'tabs.company.configuration.integrations';
  }

  protected onContinueAction() {
    this.onContinuteState.emit(true);
  }

  private onGetModulesViewComplete(modules: IModuleCompanyView[]): void {
    /// SalaryTypeModuleId === 4
    const module: IModuleCompanyView =
      modules && modules.length ? modules.find((m: IModuleCompanyView) => m.ModuleId === 4) : undefined;

    if (module) {
      this.hasSalaryTypeModule = module.IsEnabled;
      this.moduleSalaryTypePrice = module.ModulePrice;
      this.moduleSalaryTypeName = module.ModuleName;
    } else {
      this.hasSalaryTypeModule = false;
    }
  }

  protected onDiscardAndLeaveAction() {
    this.loadIntegration().subscribe(() => {
      this.tabDirty = false;
      this.editMode = false;

      this.moveToNextState();
    });
  }

  public cleanUpControl(): void {
    this.credentialControl = [];
    this.configurationControl = [];
    this.integrationConfigurationControl = [];
  }

  public inItSalaryTypeView() {
    this.staticDataService.moduleCompanyView
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((modules: IModuleCompanyView[]) => {
        if (modules && modules.length > 0) {
          this.onGetModulesViewComplete(modules);
          this.loadStandardSalaryTypeView().subscribe();
        }
      });
  }

  public loadIntegrationModel(): void {
    this.loadIntegration().subscribe(() => {
      this.resetForm();

      if (this.IdCreate) {
        // Alow edit for new integrations
        this.editMode = true;
        this.IdCreate = null;
      }
    });
  }

  public reloadIntegrationModel(): void {
    this.loadIntegration().subscribe(() => {
      this.resetForm();

      if (this.selectedId) {
        this.loadIntegrationDetail();
      }
    });
  }

  public resetForm() {
    this.tabDirty = false;
    this.editMode = false;
  }

  public loadIntegrationDetail() {
    if (!this.integrationSubject) {
      this.integrationSubject = new BehaviorSubject<IIntegration>(null);
    }
    this.dataService
      .Integrations_RetrieveIntegrationWithAllChildren(this.selectedId)
      .subscribe((model: IIntegration) => {
        this.integrationSubject.next(model);
      });
  }

  public onSave(iSimpleKeyValuePairArray: ISimpleKeyValuePair[]) {
    return this.dataService.Integrations_SetIntegrationValuesByKey(this.selectedId, iSimpleKeyValuePairArray).pipe(
      tap(() => {
        this.moveToNextState();
      })
    );
  }

  public loadIntegration() {
    return this.dataService.Integrations_RetrieveIntegrations().pipe(
      tap((integrationLoad: IIntegration[]) => {
        this.integrationsSubject.next(integrationLoad);
        this.allIntegration = integrationLoad;
      })
    );
  }

  public createIntegration() {
    return this.dataService.Integrations_CreateIntegration(this.globalExtenalSystem.Id).pipe(
      tap((data: IIntegration) => {
        this.IdCreate = data.Id;
        this.editMode = true;
      })
    );
  }

  public deleteIntegration(id: number) {
    return this.dataService.Integrations_DeleteIntegration(id).pipe(
      tap(() => {
        this.selectedId = undefined;
        this.currentIntegrationModel = undefined;
        this.IdCreate = undefined;

        this.cleanUpControl();
      })
    );
  }

  public sortCurrentExtenalSystem() {
    if (this.currentExtenalSystem.ConfigurationKeys && this.currentExtenalSystem.ConfigurationKeys.length > 0) {
      const resFilterCurrentConfigurationKeys: string[] = [];
      this.externalSystemConfiguration.forEach((model: IExternalSystemConfiguration) => {
        if (this.currentExtenalSystem.ConfigurationKeys.find((temp: string) => temp === model.Key)) {
          resFilterCurrentConfigurationKeys.push(model.Key);
        }
      });
      this.currentExtenalSystem.ConfigurationKeys = resFilterCurrentConfigurationKeys;
    }

    if (this.currentExtenalSystem.CredentialKeys && this.currentExtenalSystem.CredentialKeys.length > 0) {
      const resFilterCurrentCredentialKeys: string[] = [];
      this.externalSystemCredential.forEach((model: IExternalSystemCredential) => {
        if (this.currentExtenalSystem.CredentialKeys.find((temp: string) => temp === model.Key)) {
          resFilterCurrentCredentialKeys.push(model.Key);
        }
      });
      this.currentExtenalSystem.CredentialKeys = resFilterCurrentCredentialKeys;
    }

    if (this.currentExtenalSystem.Operations && this.currentExtenalSystem.Operations.length > 0) {
      const resFilterCurrentOperations: IExternalSystemOperation[] = [];
      this.externalSystemOperation.forEach((model: IExternalSystemOperation) => {
        if (this.currentExtenalSystem.Operations.find((temp: IExternalSystemOperation) => temp.Key === model.Key)) {
          resFilterCurrentOperations.push(model);
        }
      });
      this.currentExtenalSystem.Operations = resFilterCurrentOperations;
    }
  }

  private createControlOperation(extenalsystemOperations: IExternalSystemOperation): integrationscontrol {
    const extenal: IExternalSystemOperation = this.externalSystemOperation.find(
      (model: IExternalSystemOperation) => model.Key === extenalsystemOperations.Key
    );
    return {
      Id: extenalsystemOperations.Id,
      name: extenalsystemOperations.Name,
      key: extenalsystemOperations.Key,
      Description: extenal && extenal.Description ? extenal.Description : '',
      SortKey: extenal.SortKey
    };
  }

  public listControlConfiguration(): void {
    this.createListOperationsControl();
    this.createListCredentialControl();
    this.createListConfigurationControl();
  }

  private createListOperationsControl(): void {
    for (let i = 0; i < this.currentExtenalSystem.Operations.length; i++) {
      const control: integrationscontrol = this.createControlOperation(this.currentExtenalSystem.Operations[i]);
      if (control) {
        const filterIntegrationOperations: IIntegrationOperation[] = this.currentIntegrationModel.IntegrationOperations.filter(
          (z: IIntegrationOperation) => z.OperationId === control.Id
        );
        if (filterIntegrationOperations && filterIntegrationOperations[0]) {
          control.value = filterIntegrationOperations[0].IsActive;
          control.translatemessage = filterIntegrationOperations[0].TranslatedStatusMessage;
          control.statuscode = filterIntegrationOperations[0].StatusCode;
          control.Description = (filterIntegrationOperations[0] as IntegrationOperation).Description
            ? (filterIntegrationOperations[0] as IntegrationOperation).Description
            : '';
        }

        control.integrationID = this.currentExtenalSystem.Id;
        this.configurationControl.push(control);
      }
      this.configurationControl.sort((itemA: integrationscontrol, itemB: integrationscontrol) => {
        if (itemA.SortKey > itemB.SortKey) {
          return 1;
        }
        if (itemA.SortKey < itemB.SortKey) {
          return -1;
        }
        return 0;
      });
    }
  }

  private createControlCredential(name: string): integrationscontrol {
    const finResult: IExternalSystemCredential = this.externalSystemCredential.find(
      (model: IExternalSystemCredential) => model.Key === name
    );
    if (finResult) {
      return {
        name: finResult.Name ? finResult.Name : '',
        key: name,
        Description: finResult.Description || '',
        SortKey: finResult.SortKey
      };
    }
    return undefined;
  }

  private createControlIntegrationConfiguration(name: string): integrationscontrol {
    const configuration: IExternalSystemConfiguration = this.externalSystemConfiguration.find(
      (model: IExternalSystemConfiguration) => model.Key === name
    );
    return {
      name: configuration && configuration.Name ? configuration.Name : '',
      key: name,
      type: configuration && configuration.FormElement ? configuration.FormElement : '',
      listDataSource: configuration && configuration.ListDataSource ? configuration.ListDataSource : undefined,
      Description: configuration && configuration.Description ? configuration.Description : '',
      SortKey: configuration.SortKey
    };
  }

  private getPartsFromListDataSourceStringOfCombobox(stringListDataSource: string): referenceSource {
    stringListDataSource = stringListDataSource.replace(':', ';');
    const parts: string[] = stringListDataSource.split(';');
    const externalSystemName: string = this.currentExtenalSystem
      ? this.currentExtenalSystem.Name
        ? this.currentExtenalSystem.Name
        : this.currentExtenalSystem.Key
      : '';

    if (parts[0].indexOf('{externalSystemName}') >= 0) {
      parts[0] = parts[0].replace('{externalSystemName}', externalSystemName);
    }

    if (parts[0].indexOf('{IntegrationId}') >= 0) {
      parts[0] = parts[0].replace('{IntegrationId}', this.selectedId.toString());
    }

    return {
      api: parts[0],
      underlyingId: parts[1],
      displayText: parts[2]
    };
  }

  private assignControlDataSource(
    control: integrationscontrol,
    listDataSourceParts: referenceSource,
    data: any[]
  ): void {
    const sameApiComboboxes: integrationscontrol[] = this.integrationConfigurationControl.filter(
      (ctrl: integrationscontrol) => {
        if (ctrl.listDataSource) {
          const ctrlListDataSourceParts: referenceSource = this.getPartsFromListDataSourceStringOfCombobox(
            ctrl.listDataSource
          );
          return ctrlListDataSourceParts.api === listDataSourceParts.api;
        } else {
          return false;
        }
      }
    );

    if (!sameApiComboboxes || sameApiComboboxes.length === 0) {
      return;
    }

    control.dataSource = data;
    if (sameApiComboboxes && sameApiComboboxes.length > 0) {
      sameApiComboboxes.forEach((apiCombo: integrationscontrol) => {
        if (!apiCombo.dataSource || apiCombo.dataSource.length === 0) {
          apiCombo.dataSource = data;
        }
      });
    }
  }

  private createListCredentialControl(): void {
    for (let i = 0; i < this.currentExtenalSystem.CredentialKeys.length; i++) {
      const control: integrationscontrol = this.createControlCredential(this.currentExtenalSystem.CredentialKeys[i]);
      if (control) {
        const filterIntegrationCredentials: IIntegrationCredential[] = this.currentIntegrationModel.IntegrationCredentials.filter(
          (z: IIntegrationCredential) => z.Key === control.key
        );
        if (filterIntegrationCredentials && filterIntegrationCredentials[0]) {
          control.value = filterIntegrationCredentials[0].Value;
          control.Description = filterIntegrationCredentials[0].ExternalSystemCredential.Description;
        }
        this.credentialControl.push(control);
      }
      this.credentialControl.sort((itemA: integrationscontrol, itemB: integrationscontrol) => {
        if (itemA.SortKey > itemB.SortKey) {
          return 1;
        }
        if (itemA.SortKey < itemB.SortKey) {
          return -1;
        }
        return 0;
      });
    }
  }

  private createListConfigurationControl(): void {
    const listControls: { [key: string]: integrationscontrol[] } = {};
    for (let i = 0; i < this.currentExtenalSystem.ConfigurationKeys.length; i++) {
      const control: integrationscontrol = this.createControlIntegrationConfiguration(
        this.currentExtenalSystem.ConfigurationKeys[i]
      );
      if (control) {
        const filterIntegrationConfigurations: IIntegrationConfiguration[] = this.currentIntegrationModel.IntegrationConfigurations.filter(
          (z: IIntegrationConfiguration) => z.Key === control.key
        );
        if (filterIntegrationConfigurations && filterIntegrationConfigurations[0]) {
          control.value = filterIntegrationConfigurations[0].Value;
        }
        this.integrationConfigurationControl.push(control);
        if (control.type === 'Dropdown') {
          if (!listControls[control.listDataSource]) {
            listControls[control.listDataSource] = [];
          }
          listControls[control.listDataSource].push(control);
        }
      }
    }
    this.integrationConfigurationControl.sort((itemA: integrationscontrol, itemB: integrationscontrol) => {
      if (itemA.SortKey > itemB.SortKey) {
        return 1;
      }
      if (itemA.SortKey < itemB.SortKey) {
        return -1;
      }
      return 0;
    });
    Object.keys(listControls).forEach((key) => {
      if (listControls[key].length > 1) {
        this.loadComboboxDataSource(null, listControls[key]);
      } else {
        this.loadComboboxDataSource(listControls[key][0]);
      }
    });
  }

  private loadComboboxDataSource(control: integrationscontrol, listControls?: integrationscontrol[]): void {
    if (control === null && listControls && listControls.length > 0) {
      control = listControls[0];
    }
    const listDataSourceParts: referenceSource = this.getPartsFromListDataSourceStringOfCombobox(
      control.listDataSource
    );
    control.idField = listDataSourceParts.underlyingId;
    control.textField = listDataSourceParts.displayText;

    const dataModel: { api: string; data: any[] } =
      this.ApiDataList.length > 0 ? this.ApiDataList.find((model: any) => model.api === listDataSourceParts.api) : null;
    if (dataModel) {
      this.assignControlDataSource(control, listDataSourceParts, dataModel.data);
      this.assignDataSource(listControls, dataModel.data);
    } else {
      this.dataService.httpGet(environment.apiUrl + '/' + listDataSourceParts.api).subscribe((data: any[]) => {
        if (data && data.length > 0) {
          data.forEach((model: any) => {
            model[control.idField] = control.idField && model[control.idField] ? model[control.idField].toString() : '';
          });
        }
        this.assignControlDataSource(control, listDataSourceParts, data);
        this.assignDataSource(listControls, data);
        const nextModel: { api: string; data: any[] } = {
          api: listDataSourceParts.api,
          data
        };
        this.ApiDataList.push(nextModel);
      });
    }
  }

  private assignDataSource(listControls: integrationscontrol[], data: any) {
    if (listControls && listControls.length > 0) {
      listControls.forEach((control: integrationscontrol, index: number) => {
        if (index === 0) {
          return;
        } else {
          const listDataSourcePartChild: referenceSource = this.getPartsFromListDataSourceStringOfCombobox(
            control.listDataSource
          );
          control.idField = listDataSourcePartChild.underlyingId;
          control.textField = listDataSourcePartChild.displayText;
          this.assignControlDataSource(control, listDataSourcePartChild, data);
        }
      });
    }
  }

  public saveValueControlParam(): void {
    const iSimpleKeyValuePairArray: ISimpleKeyValuePair[] = [];

    this.configurationControl.forEach((control: integrationscontrol) => {
      const simpleKeyValuePairtemp: ISimpleKeyValuePair = {};
      simpleKeyValuePairtemp.Key = 'Operation.' + control.Id;
      simpleKeyValuePairtemp.Value = control.value ? control.value.toString() : 'false';
      iSimpleKeyValuePairArray.push(simpleKeyValuePairtemp);
    });
    this.credentialControl.forEach((control: integrationscontrol) => {
      const simpleKeyValuePairtemp: ISimpleKeyValuePair = {};
      simpleKeyValuePairtemp.Key = 'Credential.' + control.key;
      simpleKeyValuePairtemp.Value = control.value ? control.value.toString() : '';
      iSimpleKeyValuePairArray.push(simpleKeyValuePairtemp);
    });
    this.integrationConfigurationControl.forEach((control: integrationscontrol) => {
      const simpleKeyValuePairtemp: ISimpleKeyValuePair = {};
      simpleKeyValuePairtemp.Key = 'Configuration.' + control.key;
      if (control.value && control.value.toString().indexOf('GMT') >= 0) {
        const date = this.staticDataService.getCurrentdate(null, control.value as any);
        simpleKeyValuePairtemp.Value = date.toISOString();
        iSimpleKeyValuePairArray.push(simpleKeyValuePairtemp);
      } else {
        simpleKeyValuePairtemp.Value = control.value ? control.value.toString() : '';
        iSimpleKeyValuePairArray.push(simpleKeyValuePairtemp);
      }
    });

    this.resetForm();

    this.onSave(iSimpleKeyValuePairArray).subscribe(
      (): void => {
        this.reloadIntegrationModel();
      },
      () => {
        this.reloadIntegrationModel();
      }
    );
  }
}
