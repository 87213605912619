<div class="costs-container">
  <div id="costs" class="calculator-container" [ngClass]="{ changing: changing }">
    <div id="cost-calculator">
      <h2 class="yourPayslipPrice">
        {{ 'Price.PerPayslip' | translate }}:
        <span>{{ pricePerPayslip | PriceFormat }} kr.</span>
      </h2>
      <h2 class="yourEmployeesCount">{{ 'Price.NumEmployees' | translate }}: {{ totalEmployees }}</h2>
      <div id="inputs">
        <div class="input">
          <label for="employeesmonthly">{{ 'Price.EmployeesMonthly' | translate }}</label>
          <div class="plus-minus-container">
            <div class="item middle">
              <input type="number" min="0" id="employeesmonthly" name="employeesmonthly" [(ngModel)]="usersMonthly" />
            </div>
          </div>
        </div>
        <div class="input">
          <label for="employees14day">{{ 'Price.Employees14Day' | translate }}</label>
          <div class="plus-minus-container">
            <div class="item middle">
              <input type="number" min="0" id="employees14day" name="employees14day" [(ngModel)]="users14Day" />
            </div>
          </div>
        </div>
        <div class="input">
          <label for="employeesweekly">{{ 'Price.EmployeesWeekly' | translate }}</label>
          <div class="plus-minus-container">
            <div class="item middle">
              <input type="number" min="0" id="employeesweekly" name="employeesweekly" [(ngModel)]="usersWeekly" />
            </div>
          </div>
        </div>
      </div>

      <h2 id="calculatedcostFormat">{{ 'Price.TotalPrice' | translate }}</h2>
      <div id="total">
        <span id="calculatedcostMoney">{{ totalCost | PriceFormat }} kr.</span>
        <div class="small-note">{{ 'Price.SummaryNote' | translate }}</div>
        <app-button
          *ngIf="submitVisible"
          [ngClass]="{ 'btn-join-disabled': !submitEnabled }"
          id="btnJoin"
          (clickEvent)="submitEvent()"
          [isDisabled]="!submitEnabled"
          [label]="'Price.Submit' | translate"
        >
        </app-button>
      </div>
    </div>
    <div class="costsDesc" *ngIf="priceElements">
      <strong>{{ 'Price.Includes' | translate }}</strong>
      <ul class="picked-modules">
        <li *ngFor="let element of priceElements">
          <span><img src="./images/checkmarkblue.svg"/></span>{{ element }}
        </li>
      </ul>
    </div>
  </div>
</div>
