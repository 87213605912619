import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterDateTimeGrid } from '../../common/filter-date-time-grid';
import { Global } from '../../common/global';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
var CompanyApprovalGridDetailComponent = /** @class */ (function () {
    function CompanyApprovalGridDetailComponent(translateService, dataService, settingService, staticDataService, sessionService) {
        this.translateService = translateService;
        this.dataService = dataService;
        this.settingService = settingService;
        this.staticDataService = staticDataService;
        this.sessionService = sessionService;
        this.allowManagersToApproveTimeEntry = false;
        this.option = { min: 0, step: 1, spinners: false };
        // pageging for approval
        this.currentpage = 0;
        this.dimensionXValues = [];
        this.AllText = 'All';
        this.isSlect1020default = true;
        this.TimeEntryRecord = [];
        this.numberOption = { min: undefined, step: 1, spinners: false, decimals: 2, format: 'n2' };
        this.openApprovalGridDetail = new EventEmitter();
        this.ngUnsubscribe = new Subject();
        this.selectedRowEmitter = new EventEmitter();
        this.isShowRejectDialog = false;
        this.isShowRejectDialogNonApproveTimeEntry = false;
        this.approveRejectData = new EventEmitter();
        this.translationServiceTerms = [
            'EmploymentTime.PeriodWarning',
            'CompanySalaryBatches.SalaryCycle_Item_All_Text',
            'EmploymentTime.EligibleForApproval'
        ];
        this.UnitTypes = Global.UNIT_TYPES;
    }
    Object.defineProperty(CompanyApprovalGridDetailComponent.prototype, "isHideDimension1", {
        get: function () {
            return this.Dimension1Preference && !!this.Dimension1Preference.Value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridDetailComponent.prototype, "isHideDimension2", {
        get: function () {
            return this.Dimension2Preference && !!this.Dimension2Preference.Value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridDetailComponent.prototype, "isHideDimension3", {
        get: function () {
            return this.Dimension3Preference && !!this.Dimension3Preference.Value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridDetailComponent.prototype, "Dimension1Name", {
        get: function () {
            if (!this.isHideDimension1) {
                return '';
            }
            else {
                var dimension1Object = this.sessionService.getCompanyPreference('Dimensions.Dimension1Name');
                if (dimension1Object && dimension1Object.Value) {
                    return dimension1Object.Value;
                }
            }
            return '1';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridDetailComponent.prototype, "Dimension2Name", {
        get: function () {
            if (!this.isHideDimension2) {
                return '';
            }
            else {
                var dimension2Object = this.sessionService.getCompanyPreference('Dimensions.Dimension2Name');
                if (dimension2Object && dimension2Object.Value) {
                    return dimension2Object.Value;
                }
            }
            return '2';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridDetailComponent.prototype, "Dimension3Name", {
        get: function () {
            if (!this.isHideDimension3) {
                return '';
            }
            else {
                var dimension3Object = this.sessionService.getCompanyPreference('Dimensions.Dimension3Name');
                if (dimension3Object && dimension3Object.Value) {
                    return dimension3Object.Value;
                }
            }
            return '3';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridDetailComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridDetailComponent.prototype, "Dimension1Preference", {
        get: function () {
            return this.sessionService.getCompanyPreference('Dimensions.Dimension1Name');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridDetailComponent.prototype, "Dimension2Preference", {
        get: function () {
            return this.sessionService.getCompanyPreference('Dimensions.Dimension2Name');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridDetailComponent.prototype, "Dimension3Preference", {
        get: function () {
            return this.sessionService.getCompanyPreference('Dimensions.Dimension3Name');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridDetailComponent.prototype, "allowManagersToApproveTimeEntryMessage", {
        get: function () {
            if (!this.allowManagersToApproveTimeEntry) {
                return 'EmploymentTime.DisableGridTimeMessage';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridDetailComponent.prototype, "TooltipFroApproButtonAprrovalGrid", {
        get: function () {
            if (!this.allowManagersToApproveTimeEntry) {
                return this.allowManagersToApproveTimeEntryMessage;
            }
            return 'EmploymentTime.ApproveThisRecord';
        },
        enumerable: true,
        configurable: true
    });
    CompanyApprovalGridDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.TimeEntryStatus.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (timeEntryStatus) {
            _this.openApprovalGridDetail.emit();
            if (_this.rowIndex === undefined || _this.rowIndex === null) {
                _this.timeEntryRecord = null;
            }
            else {
                var timeEntryRecordRaw = null;
                if (_this.timeEntryRecords[_this.rowIndex] &&
                    _this.timeEntryRecords[_this.rowIndex].timeEntries &&
                    _this.timeEntryRecords[_this.rowIndex].timeEntries[0].EmployeeName !== _this.employee.EmployeeName) {
                    timeEntryRecordRaw = _this.timeEntryRecords.find(function (timeEntry) {
                        return timeEntry.timeEntries[0].EmployeeName === _this.employee.EmployeeName;
                    });
                }
                else {
                    timeEntryRecordRaw = _this.timeEntryRecords[_this.rowIndex];
                }
                _this.timeEntryRecord = (timeEntryRecordRaw.timeEntries || []).filter(function (record) {
                    if (_this.currentTimeEntryStatus.Id === -2) {
                        return _this.checkIsCorrect(record.Status, 10) || _this.checkIsCorrect(record.Status, 20);
                    }
                    return _this.checkIsCorrect(record.Status, _this.currentTimeEntryStatus.Id);
                });
            }
            _this.timeEntryStatus = timeEntryStatus;
            if (!_this.timeEntryStatus || (_this.timeEntryStatus && _this.timeEntryStatus.length === 0)) {
                return;
            }
            _this.enableTranslation();
            var objectAllowManagersToApproveTimeEntry = _this.sessionService.getCompanyPreference('Access.AllowManagersToApproveTimeEntry');
            if (objectAllowManagersToApproveTimeEntry && objectAllowManagersToApproveTimeEntry.Value === 'true') {
                _this.allowManagersToApproveTimeEntry = true;
            }
            else {
                if (_this.sessionService.role.IsSalaryAdminAndHigher) {
                    _this.allowManagersToApproveTimeEntry = true;
                }
            }
        });
    };
    CompanyApprovalGridDetailComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    CompanyApprovalGridDetailComponent.prototype.enableTranslation = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            _this.translateService.get(_this.translationServiceTerms).subscribe(function (translations) {
                _this.setTranslationTerms(translations);
            });
        });
    };
    CompanyApprovalGridDetailComponent.prototype.setTranslationTerms = function (translations) {
        this.PeriodWarningText = translations['EmploymentTime.PeriodWarning'];
        this.AllText = translations['CompanySalaryBatches.SalaryCycle_Item_All_Text'];
        this.filterEligibleforapproval = translations['EmploymentTime.EligibleForApproval'];
    };
    CompanyApprovalGridDetailComponent.prototype.onSelectRow = function (event) {
        if (!event || event === undefined) {
            return;
        }
        this.selectedItem = event;
        this.selectedRowEmitter.emit(event);
    };
    CompanyApprovalGridDetailComponent.prototype.onGridButtonClick = function (event) {
        if (event && event.buttonAction && event.buttonAction === 'Reject') {
            if (event.dataItem) {
                if (event.dataItem.isCanReject) {
                    this.isShowRejectDialog = true;
                    this.selectedemployeeIdinGrid = event.dataItem.Id;
                    this.preUserEmploymentId = event.dataItem.UserEmploymentId;
                }
            }
        }
    };
    CompanyApprovalGridDetailComponent.prototype.onRejectDialog = function (event) {
        var _this = this;
        if (event) {
            if (event === 'RejectPrimary') {
                var rejectionInfo = { Reason: this.rejectMessage };
                this.dataService.TimeEntry_RejectTimeEntryRecord(this.selectedemployeeIdinGrid, rejectionInfo).subscribe(function () {
                    _this.stillSelectstatus = _this.selectedTimeEntryStatusId;
                    _this.isSlect1020default = false;
                    _this.refreshTimeEntryList();
                    _this.selectedemployeeIdinGrid = undefined;
                    _this.rejectMessage = undefined;
                }, function () {
                    _this.stillSelectstatus = _this.selectedTimeEntryStatusId;
                    _this.isSlect1020default = false;
                    _this.refreshTimeEntryList();
                    _this.selectedemployeeIdinGrid = undefined;
                    _this.rejectMessage = undefined;
                });
            }
            else {
                this.rejectMessage = undefined;
                this.selectedemployeeIdinGrid = this.preUserEmploymentId;
            }
        }
    };
    CompanyApprovalGridDetailComponent.prototype.EmptyEmployeetimeApproval = function () {
        if (this.timeEntryRecord && this.timeEntryRecord.length > 0) {
            return '';
        }
        else {
            return 'EmptydataGrid';
        }
    };
    CompanyApprovalGridDetailComponent.prototype.onClickAppGridIcon = function (event) {
        var _this = this;
        if (event &&
            event.iconAction === 'ApproveSingleRecord' &&
            event.dataItem &&
            event.dataItem.Id &&
            event.dataItem.isCanSingleApprove) {
            this.dataService.TimeEntry_ApproveTimeEntryRecord(event.dataItem.Id).subscribe(function (model) {
                _this.isSlect1020default = false;
                _this.refreshTimeEntryList();
            });
        }
    };
    CompanyApprovalGridDetailComponent.prototype.refreshTimeEntryList = function () {
        this.approveRejectData.emit();
    };
    CompanyApprovalGridDetailComponent.prototype.checkIsCorrect = function (dataCompare, compareValue) {
        if (compareValue === -1) {
            return true;
        }
        return dataCompare.Id === compareValue;
    };
    CompanyApprovalGridDetailComponent.prototype.onApprovalFilterChange = function (approvalFilter) {
        if (approvalFilter && approvalFilter.filters && approvalFilter.filters.length > 0) {
            var filterDateTimeGrid = new FilterDateTimeGrid();
            var filtersDate = [];
            // Using FilterDateTimeGrid to filter date before filter another field
            this.approvalFilter = filterDateTimeGrid.convertFilterOperator(approvalFilter, filtersDate, 'EntryDate', 'EndDate');
            this.TimeEntryRecord = filterDateTimeGrid.doFilterDate(this.DefaultTimeEntryRecord, filtersDate, 'EntryDate', 'EndDate');
            this.TimeEntryRecord = filterBy(this.TimeEntryRecord, this.approvalFilter);
            if (filtersDate.length > 0) {
                [].push.apply(this.approvalFilter.filters, filtersDate);
            }
        }
        else {
            this.TimeEntryRecord = this.DefaultTimeEntryRecord;
        }
    };
    return CompanyApprovalGridDetailComponent;
}());
export { CompanyApprovalGridDetailComponent };
