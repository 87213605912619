import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../common/constants';
import { FormComponentBase } from '../../../common/form-component-base';
import { Global } from '../../../common/global';
import { Address, CompanyUser } from '../../../services/api/api-model';
import { StaticDataService } from '../../../services/api/static-data.service';
var ContactInfoComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ContactInfoComponent, _super);
    function ContactInfoComponent(staticDataService) {
        var _this = _super.call(this) || this;
        _this.staticDataService = staticDataService;
        _this.companyUserChange = new EventEmitter();
        _this.childIsValid = Array(10).fill(true);
        _this.childIsDirty = Array(10).fill(false);
        return _this;
    }
    Object.defineProperty(ContactInfoComponent.prototype, "companyUser", {
        get: function () {
            if (!this.companyUserValue) {
                this.companyUserValue = new CompanyUser();
                this.companyUserValue.Address = new Address();
            }
            return this.companyUserValue;
        },
        set: function (value) {
            this.companyUserValue = value;
            this.companyUserChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    ContactInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.City.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.cities = data); });
        this.staticDataService.Municipality.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.municipality = data.filter(function (m) { return Global.SESSION && m.CountryId === Global.SESSION.CurrentCountryId; });
        });
    };
    Object.defineProperty(ContactInfoComponent.prototype, "IsGreenlandCompanyAnduserEmloyment", {
        get: function () {
            return (Global.COMPANY &&
                Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID &&
                this.companyUser &&
                this.companyUser.UserEmployments &&
                this.companyUser.UserEmployments.length > 0);
        },
        enumerable: true,
        configurable: true
    });
    ContactInfoComponent.prototype.onPostalCodeChanged = function () {
        var _this = this;
        if (this.companyUser.Address.CountryId !== 1) {
            return;
        }
        var postalCode = this.companyUser.Address.PostalCode;
        if (!postalCode) {
            return;
        }
        var city = this.cities.find(function (c) {
            if (c.CountryId === _this.companyUser.Address.CountryId && c.PostalCode) {
                var numericCode = parseInt(postalCode, 10);
                if (!isNaN(numericCode)) {
                    if (c.IsRange && c.PostalCodeTo) {
                        if (numericCode >= c.PostalCode && numericCode <= c.PostalCodeTo) {
                            return true;
                        }
                    }
                    else {
                        if (numericCode === c.PostalCode) {
                            return true;
                        }
                    }
                }
            }
            return false;
        });
        if (!city) {
            city = this.cities.find(function (c) { return c.PostalCode && postalCode === c.PostalCode.toString(); });
        }
        if (city) {
            this.companyUser.Address.City = city.Name;
            if (city.CountryId !== this.companyUser.Address.CountryId) {
                this.companyUser.Address.CountryId = city.CountryId;
            }
        }
    };
    ContactInfoComponent.prototype.onComboboxControlChanged = function () {
        if (this.companyUser.Address.CountryId !== Constants.GREENLAND_COUNTRY_ID &&
            this.companyUser.AddressId !== Constants.DENMARK_COUNTRY_ID) {
            this.companyUser.MunicipalityId = 8;
        }
        this.onChange();
    };
    return ContactInfoComponent;
}(FormComponentBase));
export { ContactInfoComponent };
