<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" display="none">
  <defs>
    <symbol viewBox="0 0 24 24" id="add">
      <path
        d="M13.8 13.4h7.7c.3 0 .7-.3.7-.7v-1.4c0-.4-.4-.7-.7-.7h-7.7c-.2 0-.4-.2-.4-.4V2.5c0-.3-.3-.7-.7-.7h-1.4c-.4 0-.7.4-.7.7v7.7c0 .2-.2.4-.4.4H2.5c-.3 0-.7.3-.7.7v1.4c0 .4.4.7.7.7h7.7c.2 0 .4.2.4.4v7.7c0 .3.3.7.7.7h1.4c.4 0 .7-.4.7-.7v-7.7c0-.2.2-.4.4-.4z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="adduser">
      <path
        d="M10.1 17.1c0-1.3.4-2.7 1.1-3.8.8-1.4 1.6-1.9 2.3-3 1.2-1.7 1.4-4.1.7-6-.8-1.9-2.5-3-4.6-2.9S6 2.7 5.3 4.6c-.7 2-.4 4.5 1.3 6.1.6.7 1.3 1.7.9 2.6-.3 1-1.4 1.4-2.2 1.7-1.8.8-4 1.9-4.3 4.1-.4 1.7.8 3.5 2.7 3.5h7.8c.4 0 .6-.4.4-.7-1.1-1.4-1.8-3.1-1.8-4.8zm7.4-5.6c-3.1 0-5.5 2.5-5.5 5.6s2.4 5.5 5.5 5.5 5.5-2.5 5.5-5.5-2.5-5.6-5.5-5.6zm2.8 6c0 .3-.2.5-.5.5h-1.3v1.4c0 .3-.3.4-.5.4h-1c-.2 0-.4-.1-.4-.4V18h-1.4c-.3 0-.4-.2-.4-.5v-.9c0-.3.1-.4.4-.4h1.4v-1.4c0-.3.2-.5.4-.5h1c.2 0 .5.2.5.5v1.4h1.3c.3 0 .5.1.5.4v.9z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="check">
      <path
        d="M8.8 19.6L1.2 12c-.3-.3-.3-.8 0-1.1l1-1c.3-.3.8-.3 1 0L9 15.7c.1.2.5.2.6 0L20.9 4.4c.2-.3.7-.3 1 0l1 1c.3.3.3.7 0 1L9.8 19.6c-.2.3-.7.3-1 0z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="chevrondown">
      <path
        d="M22 8.2l-9.5 9.6c-.3.2-.7.2-1 0L2 8.2c-.2-.3-.2-.7 0-1l1-1c.3-.3.8-.3 1.1 0l7.4 7.5c.3.3.7.3 1 0l7.4-7.5c.3-.2.8-.2 1.1 0l1 1c.2.3.2.7 0 1z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1024 1792" id="caret-down">
      <path
        d="M1024 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1024 1792" id="caret-up">
      <path
        d="M1024 1216q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="chevronleft">
      <path
        d="M15.8 22l-9.6-9.4c-.3-.3-.3-.8 0-1.1l9.6-9.4c.3-.3.7-.3 1 0l1 1c.3.3.3.7 0 1l-7.6 7.4c-.3.3-.3.8 0 1.1l7.5 7.4c.3.3.3.7 0 1l-1 1c-.2.2-.6.2-.9 0z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="chevronright">
      <path
        d="M8.3 2l9.5 9.5c.3.3.3.7 0 1L8.3 22c-.3.2-.8.2-1.1 0l-1-1c-.2-.3-.2-.8 0-1.1l7.6-7.4c.2-.3.2-.7 0-1L6.3 4.1C6 3.8 6 3.3 6.3 3l1-1c.3-.2.7-.2 1 0z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="chevronup">
      <path
        d="M2 15.8l9.5-9.6c.3-.2.7-.2 1 0l9.5 9.6c.2.3.2.7 0 1l-1 1c-.3.3-.8.3-1.1 0l-7.4-7.6c-.3-.2-.7-.2-1 0l-7.4 7.6c-.3.2-.8.2-1.1 0l-1-1c-.2-.3-.2-.7 0-1z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="circle-outline">
      <path
        d="M12 .9C5.9.9.9 5.9.9 12s5 11.1 11.1 11.1 11.1-5 11.1-11.1S18.1.9 12 .9zm0 19.5c-4.6 0-8.4-3.8-8.4-8.4S7.4 3.6 12 3.6s8.4 3.8 8.4 8.4-3.8 8.4-8.4 8.4z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="clear">
      <path
        d="M12 .9C5.9.9.9 5.9.9 12s5 11.1 11.1 11.1 11.1-5 11.1-11.1S18.1.9 12 .9zm2.3 11.5l3.6 3.6c.1.2.1.4 0 .6l-1.3 1.3c-.2.2-.5.2-.7 0l-3.6-3.6c-.2-.2-.4-.2-.6 0l-3.6 3.6c-.2.2-.5.2-.7 0l-1.3-1.3c-.1-.2-.1-.4 0-.6l3.6-3.6c.2-.2.2-.5 0-.7L6.1 8.1c-.2-.2-.2-.5 0-.7l1.3-1.3c.2-.1.4-.1.6 0l3.7 3.7c.2.2.4.2.6 0l3.6-3.6c.2-.2.5-.2.7 0l1.3 1.3c.1.2.1.4 0 .6l-3.6 3.6c-.2.2-.2.5 0 .7z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="clock">
      <path
        d="M12 .9C5.9.9.9 5.9.9 12s5 11.1 11.1 11.1 11.1-5 11.1-11.1S18.1.9 12 .9zm0 19.4c-4.6 0-8.3-3.7-8.3-8.3S7.4 3.7 12 3.7s8.3 3.7 8.3 8.3-3.7 8.3-8.3 8.3zm1.6-8.2c-.2-.1-.2-.3-.2-.5V7.2c0-.4-.3-.7-.7-.7h-1.4c-.4 0-.7.3-.7.7v5.5c0 .2.1.4.2.5l3.4 3.5c.3.2.7.2 1 0l1-1c.2-.3.2-.7 0-1l-2.6-2.6z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="close">
      <path
        d="M14.3 11.7l6-6c.3-.3.3-.7 0-1l-.9-1c-.3-.2-.7-.2-1 0l-6 6.1c-.2.2-.5.2-.7 0l-6-6.1c-.3-.3-.7-.3-1 0l-1 1c-.2.2-.2.7 0 .9l6.1 6.1c.2.2.2.4 0 .6l-6.1 6.1c-.3.3-.3.7 0 1l1 1c.2.2.7.2.9 0l6.1-6.1c.2-.2.4-.2.6 0l6.1 6.1c.2.2.7.2.9 0l1-1c.3-.3.3-.7 0-1l-6-6c-.2-.2-.2-.5 0-.7z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1792 1792" id="cog">
      <path
        d="M1152 896q0-106-75-181t-181-75-181 75-75 181 75 181 181 75 181-75 75-181zm512-109v222q0 12-8 23t-20 13l-185 28q-19 54-39 91 35 50 107 138 10 12 10 25t-9 23q-27 37-99 108t-94 71q-12 0-26-9l-138-108q-44 23-91 38-16 136-29 186-7 28-36 28h-222q-14 0-24.5-8.5t-11.5-21.5l-28-184q-49-16-90-37l-141 107q-10 9-25 9-14 0-25-11-126-114-165-168-7-10-7-23 0-12 8-23 15-21 51-66.5t54-70.5q-27-50-41-99l-183-27q-13-2-21-12.5t-8-23.5v-222q0-12 8-23t19-13l186-28q14-46 39-92-40-57-107-138-10-12-10-24 0-10 9-23 26-36 98.5-107.5t94.5-71.5q13 0 26 10l138 107q44-23 91-38 16-136 29-186 7-28 36-28h222q14 0 24.5 8.5t11.5 21.5l28 184q49 16 90 37l142-107q9-9 24-9 13 0 25 10 129 119 165 170 7 8 7 22 0 12-8 23-15 21-51 66.5t-54 70.5q26 50 41 98l183 28q13 2 21 12.5t8 23.5z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1792 1792" id="columns">
      <path
        d="M224 1536h608v-1152h-640v1120q0 13 9.5 22.5t22.5 9.5zm1376-32v-1120h-640v1152h608q13 0 22.5-9.5t9.5-22.5zm128-1216v1216q0 66-47 113t-113 47h-1344q-66 0-113-47t-47-113v-1216q0-66 47-113t113-47h1344q66 0 113 47t47 113z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1792 1792" id="diamond">
      <path
        d="M84 768l623 665-300-665h-323zm812 772l349-772h-698zm-486-900l204-384h-262l-288 384h346zm675 793l623-665h-323zm-530-793h682l-204-384h-274zm827 0h346l-288-384h-262zm141-486l384 512q14 18 13 41.5t-17 40.5l-960 1024q-18 20-47 20t-47-20l-960-1024q-16-17-17-40.5t13-41.5l384-512q18-26 51-26h1152q33 0 51 26z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1792 1792" id="calendarcheck">
      <path
        d="M1303 964l-512 512q-10 9-23 9t-23-9l-288-288q-9-10-9-23t9-22l46-46q9-9 22-9t23 9l220 220 444-444q10-9 23-9t22 9l46 46q9 9 9 22t-9 23zm-1175 700h1408v-1024h-1408v1024zm384-1216v-288q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v288q0 14 9 23t23 9h64q14 0 23-9t9-23zm768 0v-288q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v288q0 14 9 23t23 9h64q14 0 23-9t9-23zm384-64v1280q0 52-38 90t-90 38h-1408q-52 0-90-38t-38-90v-1280q0-52 38-90t90-38h128v-96q0-66 47-113t113-47h64q66 0 113 47t47 113v96h384v-96q0-66 47-113t113-47h64q66 0 113 47t47 113v96h128q52 0 90 38t38 90z"
      ></path>
    </symbol>

    <symbol viewBox="0 0 1792 1792" id="checksquare">
      <path
        d="M1472 930v318q0 119-84.5 203.5t-203.5 84.5h-832q-119 0-203.5-84.5t-84.5-203.5v-832q0-119 84.5-203.5t203.5-84.5h832q63 0 117 25 15 7 18 23 3 17-9 29l-49 49q-10 10-23 10-3 0-9-2-23-6-45-6h-832q-66 0-113 47t-47 113v832q0 66 47 113t113 47h832q66 0 113-47t47-113v-254q0-13 9-22l64-64q10-10 23-10 6 0 12 3 20 8 20 29zm231-489l-814 814q-24 24-57 24t-57-24l-430-430q-24-24-24-57t24-57l110-110q24-24 57-24t57 24l263 263 647-647q24-24 57-24t57 24l110 110q24 24 24 57t-24 57z"
      />
    </symbol>

    <symbol viewBox="0 0 1792 1792" id="osquare">
      <path
        d="M1312 256h-832q-66 0-113 47t-47 113v832q0 66 47 113t113 47h832q66 0 113-47t47-113v-832q0-66-47-113t-113-47zm288 160v832q0 119-84.5 203.5t-203.5 84.5h-832q-119 0-203.5-84.5t-84.5-203.5v-832q0-119 84.5-203.5t203.5-84.5h832q119 0 203.5 84.5t84.5 203.5z"
      />
    </symbol>

    <symbol viewBox="0 0 24 24" id="dollar">
      <path
        d="M17.7 15.5c0 1.3-.4 2.3-1.2 3.2-.8.9-1.9 1.5-3.2 1.7v2.1c0 .1 0 .2-.1.3-.1.2-.1.2-.3.2h-1.7c-.1 0-.2 0-.3-.1-.1-.1-.1-.2-.1-.3v-2.1c-.5-.1-1.1-.2-1.6-.4s-.9-.4-1.2-.5l-.9-.6c-.3-.2-.5-.4-.6-.5l-.2-.2c-.1-.2-.1-.3 0-.5l1.3-1.7c.1-.1.2-.1.3-.1.1 0 .2 0 .3.1.9.8 1.9 1.3 3 1.5.3.1.6.1.9.1.7 0 1.2-.2 1.7-.5.5-.4.8-.9.8-1.5 0-.2-.1-.4-.2-.7-.1-.2-.3-.4-.4-.5-.2-.1-.4-.3-.7-.5-.3-.2-.6-.3-.8-.4-.2-.1-.5-.2-1-.4-.3-.1-.6-.2-.8-.3s-.4-.2-.8-.3c-.3-.1-.6-.3-.8-.4-.1 0-.3-.2-.6-.4s-.5-.4-.7-.5c-.2-.2-.3-.4-.5-.6-.1-.2-.3-.5-.4-.7s-.2-.5-.2-.8-.1-.6-.1-1c0-1.1.4-2.1 1.2-3 .8-.9 1.8-1.4 3.1-1.6V1.4c0-.1 0-.2.1-.3.1-.1.2-.1.3-.1H13c.1 0 .2 0 .3.1s.1.2.1.3v2.2c.5 0 .9.1 1.4.3.4.1.8.3 1.1.4.3.1.5.3.8.5l.3.3.2.2c.1.1.2.3.1.5l-1 1.8c-.1.1-.2.2-.3.2-.1 0-.2 0-.3-.1 0 0-.1-.1-.2-.1-.1-.1-.3-.2-.5-.3s-.5-.3-.7-.4l-.9-.3s-.7-.1-1-.1c-.8 0-1.4.2-1.9.5s-.8.6-.8 1.2c0 .2 0 .4.1.6.1.2.2.3.4.5s.3.3.5.4c.2.1.4.2.7.4.3.1.6.3.7.3.2.1.5.2.9.3.4.2.8.3 1 .4.2.1.5.2.9.4s.7.4.9.5.5.4.8.6c.3.3.5.5.7.8.2.3.3.6.4.9v1.2z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="down">
      <path d="M3.8 6.5h16.4c.4 0 .8.6.4 1l-8 9.8c-.3.3-.9.3-1.2 0l-8-9.8c-.4-.4-.1-1 .4-1z"></path>
    </symbol>
    <symbol viewBox="0 0 1664 1792" id="download">
      <path
        d="M1280 1344q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm256 0q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128-224v320q0 40-28 68t-68 28h-1472q-40 0-68-28t-28-68v-320q0-40 28-68t68-28h465l135 136q58 56 136 56t136-56l136-136h464q40 0 68 28t28 68zm-325-569q17 41-14 70l-448 448q-18 19-45 19t-45-19l-448-448q-31-29-14-70 17-39 59-39h256v-448q0-26 19-45t45-19h256q26 0 45 19t19 45v448h256q42 0 59 39z"
      ></path>
    </symbol>
    <symbol fill="currentColor" viewBox="0 0 24 24" id="edit">
      <path
        d="M4.4 15.4l4.1 4.1c.2.2.5.2.6 0L19.4 9.2c.2-.2.2-.4 0-.6l-4.1-4.1c-.2-.2-.4-.2-.6 0L4.4 14.8c-.2.2-.2.5 0 .6zM16.7 2.6c-.2.2-.2.5 0 .7l4 4c.2.2.5.2.7 0l1.1-1.1c.8-.7.8-1.8 0-2.6l-2.1-2.1c-.8-.8-1.9-.8-2.7 0l-1 1.1zM1 22.2c-.1.5.3.9.8.8l5-1.2c.2 0 .3-.1.4-.2l.1-.1c.1-.1.1-.4-.1-.6l-4.1-4.1c-.2-.2-.5-.2-.6-.1l-.1.1c-.1.1-.2.3-.2.4l-1.2 5z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="edit-form">
      <path
        d="M14.5 7.1h3.8c.3 0 .5-.3.5-.5s-.1-.3-.2-.4l-4.7-4.7c-.1-.1-.2-.1-.3-.1-.3 0-.5.2-.5.5v3.7c0 .8.6 1.5 1.4 1.5zm8.3 4.8l-.4-.5c-.2-.2-.7-.2-1 0l-5.5 5.5v1.3c0 .1 0 .2.1.2h1.4l5.4-5.5c.4-.3.4-.8 0-1zm-4.4 8.6h-3.3c-.7 0-1.3-.6-1.3-1.3v-2.5c0-.4.1-.8.4-1l4.4-4.4c.1-.1.2-.3.2-.5v-.9c0-.4-.3-.7-.7-.7h-5c-1.2 0-2.2-1-2.2-2.1v-5c0-.4-.3-.7-.7-.7H3C1.9 1.4.9 2.4.9 3.5v17c0 1.1 1 2.1 2.1 2.1h13.6c1 0 2-.7 2.1-1.7.1-.2-.1-.4-.3-.4zM3.8 7.8c0-.4.3-.7.7-.7h2.8c.5 0 .7.3.7.7v.6c0 .4-.3.7-.7.7H4.5c-.4 0-.7-.3-.7-.7v-.6zm7.1 9.1c0 .4-.3.7-.7.7H4.5c-.4 0-.7-.3-.7-.7v-.7c0-.3.3-.6.7-.6h5.7c.4 0 .7.3.7.6v.7zm1.5-4.2c0 .4-.4.7-.7.7H4.5c-.4 0-.7-.3-.7-.7V12c0-.4.3-.7.7-.7h7.1c.4 0 .7.3.7.7v.7z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="error">
      <path
        d="M17.5 12.8a.75.75 0 0 1-.7.6H7.2a.66.66 0 0 1-.7-.6v-1.6a.75.75 0 0 1 .7-.6h9.6a.75.75 0 0 1 .7.6z"
        fill="#fff"
      ></path>
      <path
        d="M12 .9A11.1 11.1 0 1 0 23.1 12 11.14 11.14 0 0 0 12 .9zm5.5 11.9a.75.75 0 0 1-.7.6H7.2a.66.66 0 0 1-.7-.6v-1.6a.75.75 0 0 1 .7-.6h9.6a.75.75 0 0 1 .7.6z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="error-exclamation">
      <circle fill="#fff" cx="12" cy="12" r="11.1"></circle>
      <path
        d="M12 .9c2 0 3.9.5 5.6 1.5 1.7 1 3 2.3 4 4s1.5 3.6 1.5 5.6-.5 3.9-1.5 5.6c-1 1.7-2.3 3-4 4-1.7 1-3.6 1.5-5.6 1.5s-3.9-.5-5.6-1.5-3-2.3-4-4C1.4 15.9.9 14 .9 12s.5-3.9 1.5-5.6 2.3-3 4-4S10 .9 12 .9zm1.9 18v-2.7c0-.1 0-.2-.1-.3-.1-.1-.2-.1-.3-.1h-2.8c-.1 0-.2 0-.3.1s-.1.2-.1.3v2.7c0 .1 0 .2.1.3s.2.1.3.1h2.8c.1 0 .2 0 .3-.1s.1-.1.1-.3zm-.1-4.9l.3-9c0-.1 0-.2-.1-.3-.1-.1-.2-.1-.3-.1h-3.2c-.1 0-.3 0-.3.1-.2.1-.3.2-.3.3l.2 9c0 .1 0 .2.1.3s.2.1.3.1h2.7c.1 0 .2 0 .3-.1.3-.2.3-.3.3-.3z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="file">
      <path
        d="M4.5 16.7V5.2c-1.2 0-2.2 1-2.2 2.1v13.6c0 1.2 1 2.2 2.2 2.2h10.7c1.2 0 2.2-1 2.2-2.1H8.8c-2.4 0-4.3 0-4.3-4.3zM21 7.3h-3.6c-1.2 0-2.2-.9-2.2-2.1V1.6c0-.4-.3-.7-.7-.7H8.8C7.6.9 6.6 1.9 6.6 3v13.6c0 1.2 1 2.1 2.2 2.1h10.7c1.2 0 2.2-.9 2.2-2.1V8.1c0-.4-.3-.8-.7-.8zm.6-3l-3.4-3.2c-.1-.1-.2-.2-.3-.2-.3 0-.5.3-.5.5v2.4c0 .8.6 1.4 1.4 1.4h2.4c.3 0 .5-.2.5-.5 0-.1-.1-.2-.1-.4z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 32 32" id="html">
      <path
        d="M19 26v2.003C19 29.11 18.106 30 17.003 30H1.997C.89 30 0 29.1 0 27.993V5.007C0 3.897.9 3 2.01 3H12v6.002c0 1.11.898 1.998 2.006 1.998H19v2H6.007C4.347 13 3 14.342 3 16v7c0 1.657 1.336 3 3.007 3H19zM13 3v5.997c0 .554.45 1.003.99 1.003H19l-6-7zM6.007 14C4.9 14 4 14.9 4 15.992v7.016C4 24.108 4.898 25 6.007 25h22.986C30.1 25 31 24.1 31 23.008v-7.016c0-1.1-.898-1.992-2.007-1.992H6.007zM29 22v1h-5v-7h1v6h4zm-15-5v6h1v-6h2v-1h-5v1h2zm-4 2v-3h1v7h-1v-3H7v3H6v-7h1v3h3zm10.5 0L19 16h-1v7h1v-5l1 2h1l1-2v5h1v-7h-1l-1.5 3z"
        fill="#4350a3"
        fill-rule="evenodd"
      ></path>
    </symbol>
    <symbol viewBox="0 0 32 32" id="html-outline">
      <path
        d="M19 13v-3l-6-7H2.003C.897 3 0 3.898 0 5.007v22.986C0 29.1.89 30 1.997 30h15.006C18.106 30 19 29.102 19 28.01V26h10.006C30.66 26 32 24.658 32 23v-7c0-1.657-1.34-3-2.994-3H19zm-1 13v2.007c0 .548-.448.993-1 .993H2c-.545 0-1-.446-1-.995V4.995c0-.54.446-.995.996-.995H12v4.994c0 1.12.894 2.006 1.998 2.006H18v2H5.994C4.34 13 3 14.342 3 16v7c0 1.657 1.34 3 2.994 3H18zM13 4.5v4.49c0 .558.45 1.01.997 1.01H17.7L13 4.5zM6.007 14C4.9 14 4 14.9 4 15.992v7.016C4 24.108 4.898 25 6.007 25h22.986C30.1 25 31 24.1 31 23.008v-7.016c0-1.1-.898-1.992-2.007-1.992H6.007zM29 22v1h-5v-7h1v6h4zm-15-5v6h1v-6h2v-1h-5v1h2zm-4 2v-3h1v7h-1v-3H7v3H6v-7h1v3h3zm10.5 0L19 16h-1v7h1v-5l1 2h1l1-2v5h1v-7h-1l-1.5 3z"
        fill="#4350a3"
        fill-rule="evenodd"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1792 1792" id="industry">
      <path
        d="M448 0q26 0 45 19t19 45v891l536-429q17-14 40-14 26 0 45 19t19 45v379l536-429q17-14 40-14 26 0 45 19t19 45v1152q0 26-19 45t-45 19h-1664q-26 0-45-19t-19-45v-1664q0-26 19-45t45-19h384z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="info">
      <path
        d="M12 .9C5.9.9.9 5.9.9 12s5 11.1 11.1 11.1 11.1-5 11.1-11.1S18.1.9 12 .9zm0 5.6c.8 0 1.4.6 1.4 1.4s-.6 1.4-1.4 1.4-1.4-.6-1.4-1.4.6-1.4 1.4-1.4zm2.3 9.7c0 .2-.2.4-.5.4h-3.6c-.3 0-.5-.1-.5-.4v-.9c0-.3.2-.5.5-.5.2 0 .4-.2.4-.4v-1.9c0-.2-.2-.5-.4-.5-.3 0-.5-.1-.5-.4v-.9c0-.3.2-.5.5-.5h2.7c.3 0 .5.2.5.5v3.7c0 .2.2.4.4.4.3 0 .5.2.5.5v.9z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="link">
      <path
        d="M12.6 19.2l-1-.1s-.7-.1-1-.3c-.2 0-.4 0-.5.2l-.3.2c-1.3 1.3-3.5 1.5-4.9.3-1.5-1.4-1.6-3.8-.1-5.2l3.5-3.5c.4-.5 1-.7 1.5-.9.8-.2 1.6-.2 2.2.1.5.2.9.4 1.2.8.2.2.4.4.5.6.2.3.6.4.8.1l1.3-1.3c.2-.2.2-.5.1-.7-.2-.3-.4-.5-.7-.7-.3-.4-.7-.7-1.1-.9-.6-.4-1.4-.7-2.1-.8-1.5-.3-3-.1-4.3.6-.5.3-1.1.7-1.5 1.1l-3.3 3.3C.4 14.6.2 18.6 2.6 21c2.4 2.7 6.6 2.8 9.1.2l1.2-1.1c.3-.3.1-.8-.3-.9zM21 2.7C18.5.3 14.5.5 12.1 3l-1 1c-.3.3-.1.8.3.9.6 0 1.3.2 1.9.4.2 0 .5 0 .6-.2l.2-.2c1.4-1.3 3.5-1.5 4.9-.3 1.6 1.4 1.6 3.8.2 5.2l-3.5 3.5c-.5.5-1 .7-1.6.9-.7.2-1.5.2-2.2-.1-.4-.2-.8-.4-1.2-.8-.2-.2-.3-.4-.5-.6-.1-.3-.6-.4-.8-.1l-1.3 1.3c-.2.2-.2.5 0 .7.2.3.4.5.6.7.3.3.8.7 1.1.9.7.4 1.4.7 2.2.8 1.4.3 3 .1 4.2-.6.6-.3 1.1-.7 1.5-1.1l3.5-3.5c2.6-2.5 2.5-6.7-.2-9.1z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1792 1792" id="ext-link">
      <path
        d="M1408 928v320q0 119-84.5 203.5t-203.5 84.5h-832q-119 0-203.5-84.5t-84.5-203.5v-832q0-119 84.5-203.5t203.5-84.5h704q14 0 23 9t9 23v64q0 14-9 23t-23 9h-704q-66 0-113 47t-47 113v832q0 66 47 113t113 47h832q66 0 113-47t47-113v-320q0-14 9-23t23-9h64q14 0 23 9t9 23zm384-864v512q0 26-19 45t-45 19-45-19l-176-176-652 652q-10 10-23 10t-23-10l-114-114q-10-10-10-23t10-23l652-652-176-176q-19-19-19-45t19-45 45-19h512q26 0 45 19t19 45z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="page">
      <path
        d="M20.5 8.8h-5.2c-1.2 0-1.9-.8-1.9-2V1.7c0-.5-.3-.8-.8-.8H5c-1.2 0-2.2 1-2.2 2.2v17.8c0 1.2 1 2.2 2.2 2.2h14c1.2 0 2.2-1 2.2-2.2V9.5c0-.4-.3-.7-.7-.7zm.6-2.8l-4.9-4.9c-.1-.1-.3-.2-.4-.2-.3 0-.6.3-.6.5v4c0 .8.8 1.5 1.6 1.5h3.9c.3 0 .5-.3.5-.5s0-.4-.1-.4z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 32 32" id="pdf">
      <path
        d="M21 26v2.003C21 29.11 20.106 30 19.003 30H3.997C2.89 30 2 29.1 2 27.993V5.007C2 3.897 2.9 3 4.01 3H14v6.002c0 1.11.898 1.998 2.006 1.998H21v2h-8.993C10.347 13 9 14.342 9 16v7c0 1.657 1.336 3 3.007 3H21zM15 3v5.997c0 .554.45 1.003.99 1.003H21l-6-7zm-3.005 11C10.893 14 10 14.9 10 15.992v7.016c0 1.1.902 1.992 1.995 1.992h17.01C30.107 25 31 24.1 31 23.008v-7.016c0-1.1-.902-1.992-1.995-1.992h-17.01zM25 19v-2h4v-1h-5v7h1v-3h3v-1h-3zm-13-1v5h1v-3h1.995C16.102 20 17 19.112 17 18c0-1.105-.894-2-2.005-2H12v2zm1-1v2h2c.553 0 1-.444 1-1 0-.552-.443-1-1-1h-2zm5-1v7h2.995C22.102 23 23 22.113 23 20.994v-2.988C23 16.898 22.106 16 20.995 16H18zm1 1v5h2c.553 0 1-.444 1-1v-3c0-.552-.443-1-1-1h-2z"
        fill="#922"
        fill-rule="evenodd"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1536 1792" id="pdf-o">
      <path
        d="M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-514-593q33 26 84 56 59-7 117-7 147 0 177 49 16 22 2 52 0 1-1 2l-2 2v1q-6 38-71 38-48 0-115-20t-130-53q-221 24-392 83-153 262-242 262-15 0-28-7l-24-12q-1-1-6-5-10-10-6-36 9-40 56-91.5t132-96.5q14-9 23 6 2 2 2 4 52-85 107-197 68-136 104-262-24-82-30.5-159.5t6.5-127.5q11-40 42-40h22q23 0 35 15 18 21 9 68-2 6-4 8 1 3 1 8v30q-2 123-14 192 55 164 146 238zm-576 411q52-24 137-158-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132 1-7 7-44 0-3 7-43 1-4 4-8-1-1-1-2t-.5-1.5-.5-1.5q-1-22-13-36 0 1-1 2v2zm-124 661q135-54 284-81-2-1-13-9.5t-16-13.5q-76-67-127-176-27 86-83 197-30 56-45 83zm646-16q-24-24-140-24 76 28 124 28 14 0 18-1 0-1-2-3z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="people">
      <path
        d="M19.4 10.3c-1.3-.5-1.5-1-1.5-1.5s.4-1 .8-1.4c.8-.7 1.2-1.6 1.2-2.7 0-2-1.3-3.8-3.7-3.8-2.1 0-3.4 1.5-3.6 3.3 0 .2.1.3.2.4 1.8 1.1 2.8 3.1 2.8 5.4 0 1.8-.6 3.3-1.9 4.4-.1.1-.1.3 0 .4.3.2 1.1.6 1.5.8.2 0 .3.1.4.1h5.6c1 0 1.9-.9 1.9-1.9v-.2c0-1.6-1.8-2.5-3.7-3.3zm-6.2 6.4c-1.6-.6-1.8-1.2-1.8-1.8 0-.6.5-1.2 1-1.7.9-.7 1.4-1.8 1.4-3.1 0-2.4-1.6-4.5-4.4-4.5-2.8 0-4.5 2.1-4.5 4.5 0 1.3.5 2.4 1.5 3.1.5.5.9 1.1.9 1.7 0 .6-.2 1.2-1.8 1.8-2.3.9-4.6 2-4.6 3.9v.6c0 1 .9 1.9 1.9 1.9h12.8c1.1 0 1.9-.9 1.9-1.9v-.6c0-1.9-2-3-4.3-3.9z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="preview">
      <path
        d="M23.9 11.6C21.7 7.2 17.2 4.2 12 4.2S2.3 7.2.1 11.6c-.1.3-.1.6 0 .8 2.2 4.4 6.7 7.4 11.9 7.4s9.7-3 11.9-7.4c.1-.3.1-.5 0-.8zM12 17.1c-2.8 0-5.1-2.3-5.1-5.1S9.2 6.9 12 6.9s5.1 2.3 5.1 5.1-2.3 5.1-5.1 5.1zm0-8.3c-1.8 0-3.2 1.4-3.2 3.2s1.4 3.2 3.2 3.2 3.2-1.4 3.2-3.2-1.4-3.2-3.2-3.2z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="question">
      <path
        d="M13.1 17.5h-2.3c-.4 0-.6-.2-.6-.6v-.7c0-1.9 1.2-3.7 3-4.3.6-.2 1.1-.5 1.5-1 2.3-2.8.2-6.1-2.6-6.2-1 0-1.9.3-2.7 1-.6.6-1 1.3-1 2.1-.1.2-.4.5-.7.5H5.4c-.5 0-.8-.4-.7-.8.1-1.7.9-3.3 2.2-4.5C8.4 1.6 10.2.8 12.3.9c3.8.1 6.9 3.3 7.1 7.1.1 3.2-1.9 6.1-4.9 7.2-.4.2-.7.5-.7 1v.6c0 .5-.3.7-.7.7zm.7 4.9c0 .4-.3.7-.6.7h-2.4c-.3 0-.6-.3-.6-.7v-2.3c0-.4.3-.7.6-.7h2.4c.3 0 .6.3.6.7v2.3z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="question-circle">
      <path
        d="M12 .9C5.9.9.9 5.9.9 12s5 11.1 11.1 11.1 11.1-5 11.1-11.1S18.1.9 12 .9zm.8 15.4c0 .2-.1.3-.3.3h-1.1c-.1 0-.3-.1-.3-.3v-1c0-.2.1-.3.3-.3h1.1c.1 0 .3.1.3.3v1zm.3-3.3c-.2.1-.3.2-.3.5v.3c0 .2-.1.3-.3.3h-1c-.2 0-.3-.1-.3-.3v-.3c0-.9.5-1.7 1.4-2 .3-.1.5-.2.7-.5 1-1.3.1-2.8-1.2-2.8-.5 0-.9.1-1.2.5-.3.3-.5.6-.5 1 0 .1-.2.2-.3.2H9c-.2 0-.4-.2-.3-.4 0-.8.4-1.5 1-2.1.7-.6 1.5-1 2.5-1 1.7 0 3.1 1.5 3.2 3.2 0 1.6-.9 2.9-2.3 3.4z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="search">
      <path
        d="M22.9 20.9l-6.2-6.1c1.3-1.8 1.9-4 1.6-6.4-.6-3.9-3.8-7.1-7.8-7.4C5 .4.4 5 1 10.5c.3 4 3.5 7.3 7.4 7.8 2.4.3 4.6-.3 6.4-1.5l6.1 6.1c.3.3.7.3 1 0l.9-1c.3-.3.3-.7.1-1zM3.7 9.6c0-3.2 2.7-5.9 5.9-5.9s6 2.7 6 5.9-2.7 6-6 6-5.9-2.6-5.9-6z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1792 1792" id="share-box">
      <path
        d="M1472 989v259q0 119-84.5 203.5t-203.5 84.5h-832q-119 0-203.5-84.5t-84.5-203.5v-832q0-119 84.5-203.5t203.5-84.5h255q13 0 22.5 9.5t9.5 22.5q0 27-26 32-77 26-133 60-10 4-16 4h-112q-66 0-113 47t-47 113v832q0 66 47 113t113 47h832q66 0 113-47t47-113v-214q0-19 18-29 28-13 54-37 16-16 35-8 21 9 21 29zm237-496l-384 384q-18 19-45 19-12 0-25-5-39-17-39-59v-192h-160q-323 0-438 131-119 137-74 473 3 23-20 34-8 2-12 2-16 0-26-13-10-14-21-31t-39.5-68.5-49.5-99.5-38.5-114-17.5-122q0-49 3.5-91t14-90 28-88 47-81.5 68.5-74 94.5-61.5 124.5-48.5 159.5-30.5 196.5-11h160v-192q0-42 39-59 13-5 25-5 26 0 45 19l384 384q19 19 19 45t-19 45z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1792 1792" id="share">
      <path
        d="M1792 640q0 26-19 45l-512 512q-19 19-45 19t-45-19-19-45v-256h-224q-98 0-175.5 6t-154 21.5-133 42.5-105.5 69.5-80 101-48.5 138.5-17.5 181q0 55 5 123 0 6 2.5 23.5t2.5 26.5q0 15-8.5 25t-23.5 10q-16 0-28-17-7-9-13-22t-13.5-30-10.5-24q-127-285-127-451 0-199 53-333 162-403 875-403h224v-256q0-26 19-45t45-19 45 19l512 512q19 19 19 45z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="success">
      <path
        d="M12 .9C5.9.9.9 5.9.9 12s5 11.1 11.1 11.1 11.1-5 11.1-11.1S18.1.9 12 .9zm6.2 8.3l-7.1 7.2c-.3.3-.7.3-1 0l-3.9-3.9c-.2-.3-.2-.8 0-1.1l1-1c.3-.2.8-.2 1.1 0l2 2.1c.2.2.5.2.7 0l5.2-5.3c.2-.3.7-.3 1 0l1 1c.3.2.3.7 0 1z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="table">
      <path
        d="M21.5.9h-19c-.3 0-.7.3-.7.7v2.3c0 .4.4.7.7.7h19c.3 0 .7-.3.7-.7V1.6c0-.4-.4-.7-.7-.7zM6.7 6.5H2.5c-.3 0-.7.3-.7.7v1.3c0 .4.4.7.7.7h4.2c.4 0 .7-.3.7-.7V7.2c0-.4-.3-.7-.7-.7zm7.4 0H9.9c-.3 0-.7.3-.7.7v1.3c0 .4.4.7.7.7h4.2c.3 0 .7-.3.7-.7V7.2c0-.4-.4-.7-.7-.7zm7.4 0h-4.2c-.4 0-.7.3-.7.7v1.3c0 .4.3.7.7.7h4.2c.3 0 .7-.3.7-.7V7.2c0-.4-.4-.7-.7-.7zM6.7 11.1H2.5c-.3 0-.7.3-.7.7v1.4c0 .3.4.6.7.6h4.2c.4 0 .7-.3.7-.6v-1.4c0-.4-.3-.7-.7-.7zm7.4 0H9.9c-.3 0-.7.3-.7.7v1.4c0 .3.4.6.7.6h4.2c.3 0 .7-.3.7-.6v-1.4c0-.4-.4-.7-.7-.7zm7.4 0h-4.2c-.4 0-.7.3-.7.7v1.4c0 .3.3.6.7.6h4.2c.3 0 .7-.3.7-.6v-1.4c0-.4-.4-.7-.7-.7zM6.7 15.7H2.5c-.3 0-.7.3-.7.7v1.4c0 .3.4.7.7.7h4.2c.4 0 .7-.4.7-.7v-1.4c0-.4-.3-.7-.7-.7zm7.4 0H9.9c-.3 0-.7.3-.7.7v1.4c0 .3.4.7.7.7h4.2c.3 0 .7-.4.7-.7v-1.4c0-.4-.4-.7-.7-.7zm7.4 0h-4.2c-.4 0-.7.3-.7.7v1.4c0 .3.3.7.7.7h4.2c.3 0 .7-.4.7-.7v-1.4c0-.4-.4-.7-.7-.7zM6.7 20.3H2.5c-.3 0-.7.3-.7.7v1.4c0 .4.4.7.7.7h4.2c.4 0 .7-.3.7-.7V21c0-.4-.3-.7-.7-.7zm7.4 0H9.9c-.3 0-.7.3-.7.7v1.4c0 .4.4.7.7.7h4.2c.3 0 .7-.3.7-.7V21c0-.4-.4-.7-.7-.7zm7.4 0h-4.2c-.4 0-.7.3-.7.7v1.4c0 .4.3.7.7.7h4.2c.3 0 .7-.3.7-.7V21c0-.4-.4-.7-.7-.7z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="threedots">
      <path
        d="M3.7 9.2c1.5 0 2.8 1.3 2.8 2.8s-1.3 2.8-2.8 2.8S.9 13.5.9 12s1.3-2.8 2.8-2.8zm8.3 0c1.5 0 2.8 1.3 2.8 2.8s-1.3 2.8-2.8 2.8-2.8-1.3-2.8-2.8 1.3-2.8 2.8-2.8zm8.3 0c1.5 0 2.8 1.3 2.8 2.8s-1.3 2.8-2.8 2.8-2.8-1.3-2.8-2.8 1.3-2.8 2.8-2.8z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1408 1792" id="times">
      <path
        d="M1298 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="user">
      <path
        d="M23.1 19.8v1.1c0 1.2-1 2.2-2.2 2.2H3.1c-1.2 0-2.2-1-2.2-2.2v-1.1c0-2.6 3.2-4.3 6.1-5.6l.3-.1c.2-.1.5-.1.7 0 1.2.8 2.5 1.2 4 1.2s2.8-.4 3.9-1.2c.3-.1.5-.1.7 0l.3.1c3 1.3 6.2 2.9 6.2 5.6zM12 .9c3 0 5.5 2.7 5.5 6.1S15 13.1 12 13.1 6.5 10.4 6.5 7 9 .9 12 .9z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="user-search">
      <path
        d="M10.1 16.6c0-1.2.5-2.7 1.1-3.8.8-1.4 1.7-1.9 2.4-2.9 1.1-1.7 1.3-4.2.6-6-.7-1.9-2.5-3-4.5-3S6 2.2 5.3 4.1c-.7 2.1-.4 4.6 1.3 6.1.7.7 1.3 1.7 1 2.7-.4.9-1.5 1.3-2.3 1.7-1.8.8-3.9 1.9-4.3 4-.4 1.8.8 3.6 2.7 3.6h7.9c.3 0 .6-.5.3-.8-1.1-1.3-1.8-3-1.8-4.8zm7-2.1c-.8 0-1.5.7-1.5 1.5 0 .9.7 1.5 1.5 1.5s1.5-.7 1.5-1.5-.7-1.5-1.5-1.5z"
      ></path>
      <path
        d="M17.5 11.1c-3 0-5.5 2.5-5.5 5.5s2.5 5.6 5.5 5.6 5.6-2.5 5.6-5.6c0-3.1-2.5-5.5-5.6-5.5zm3 8l-.2.3c-.1.1-.2.1-.3 0l-1.5-1.5c-.5.3-1 .5-1.6.4-1-.1-1.8-1-1.9-2-.2-1.4 1-2.6 2.4-2.4 1 .1 1.8.9 2 1.9.1.6-.1 1.2-.4 1.6l1.6 1.5c-.1 0-.1.1-.1.2z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="warning">
      <path
        d="M12 18.5a1.4 1.4 0 1 1 1.4-1.4 1.37 1.37 0 0 1-1.4 1.4zm1.4-4.2a.47.47 0 0 1-.5.5h-1.8a.47.47 0 0 1-.5-.5v-6a.47.47 0 0 1 .5-.5h1.8a.47.47 0 0 1 .5.5z"
        fill="#fff"
      ></path>
      <path
        d="M23.7 19.6L13.2 2.5a1.4 1.4 0 0 0-2.4 0L.3 19.6a1.66 1.66 0 0 0 1.1 2.6h21.2a1.66 1.66 0 0 0 1.1-2.6zM12 18.5a1.4 1.4 0 1 1 1.4-1.4 1.37 1.37 0 0 1-1.4 1.4zm1.4-4.2a.47.47 0 0 1-.5.5h-1.8a.47.47 0 0 1-.5-.5v-6a.47.47 0 0 1 .5-.5h1.8a.47.47 0 0 1 .5.5z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 24 24" id="external-link">
      <path
        d="M1408 928v320q0 119-84.5 203.5t-203.5 84.5h-832q-119 0-203.5-84.5t-84.5-203.5v-832q0-119 84.5-203.5t203.5-84.5h704q14 0 23 9t9 23v64q0 14-9 23t-23 9h-704q-66 0-113 47t-47 113v832q0 66 47 113t113 47h832q66 0 113-47t47-113v-320q0-14 9-23t23-9h64q14 0 23 9t9 23zm384-864v512q0 26-19 45t-45 19-45-19l-176-176-652 652q-10 10-23 10t-23-10l-114-114q-10-10-10-23t10-23l652-652-176-176q-19-19-19-45t19-45 45-19h512q26 0 45 19t19 45z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1792 1792" id="activate">
      <path
        d="M1408 1600q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128-764q0-189-167-189-26 0-56 5-16-30-52.5-47.5t-73.5-17.5-69 18q-50-53-119-53-25 0-55.5 10t-47.5 25v-331q0-52-38-90t-90-38q-51 0-89.5 39t-38.5 89v576q-20 0-48.5-15t-55-33-68-33-84.5-15q-67 0-97.5 44.5t-30.5 115.5q0 24 139 90 44 24 65 37 64 40 145 112 81 71 106 101 57 69 57 140v32h640v-32q0-72 32-167t64-193.5 32-179.5zm128-5q0 133-69 322-59 164-59 223v288q0 53-37.5 90.5t-90.5 37.5h-640q-53 0-90.5-37.5t-37.5-90.5v-288q0-10-4.5-21.5t-14-23.5-18-22.5-22.5-24-21.5-20.5-21.5-19-17-14q-74-65-129-100-21-13-62-33t-72-37-63-40.5-49.5-55-17.5-69.5q0-125 67-206.5t189-81.5q68 0 128 22v-374q0-104 76-180t179-76q105 0 181 75.5t76 180.5v169q62 4 119 37 21-3 43-3 101 0 178 60 139-1 219.5 85t80.5 227z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1792 1792" id="bars">
      <path
        d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 448 512" id="appstore">
      <path
        d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM127 384.5c-5.5 9.6-17.8 12.8-27.3 7.3-9.6-5.5-12.8-17.8-7.3-27.3l14.3-24.7c16.1-4.9 29.3-1.1 39.6 11.4L127 384.5zm138.9-53.9H84c-11 0-20-9-20-20s9-20 20-20h51l65.4-113.2-20.5-35.4c-5.5-9.6-2.2-21.8 7.3-27.3 9.6-5.5 21.8-2.2 27.3 7.3l8.9 15.4 8.9-15.4c5.5-9.6 17.8-12.8 27.3-7.3 9.6 5.5 12.8 17.8 7.3 27.3l-85.8 148.6h62.1c20.2 0 31.5 23.7 22.7 40zm98.1 0h-29l19.6 33.9c5.5 9.6 2.2 21.8-7.3 27.3-9.6 5.5-21.8 2.2-27.3-7.3-32.9-56.9-57.5-99.7-74-128.1-16.7-29-4.8-58 7.1-67.8 13.1 22.7 32.7 56.7 58.9 102h52c11 0 20 9 20 20 0 11.1-9 20-20 20z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1792 1792" id="leveldown">
      <path
        d="M416 256h704q13 0 22.5 9.5t9.5 23.5v863h192q40 0 58 37t-9 69l-320 384q-18 22-49 22t-49-22l-320-384q-26-31-9-69 18-37 58-37h192v-640h-320q-14 0-25-11l-160-192q-13-14-4-34 9-19 29-19z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1792 1792" id="angle-double-right">
      <path
        d="M979 960q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23zm384 0q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23z"
      />
    </symbol>
    <symbol viewBox="0 0 1792 1792" id="angle-double-left">
      <path
        d="M1011 1376q0 13-10 23l-50 50q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l50 50q10 10 10 23t-10 23l-393 393 393 393q10 10 10 23zm384 0q0 13-10 23l-50 50q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l50 50q10 10 10 23t-10 23l-393 393 393 393q10 10 10 23z"
      />
    </symbol>
    <symbol viewBox="0 0 1792 1792" id="levelup">
      <path
        d="M1402 603q-18 37-58 37h-192v864q0 14-9 23t-23 9h-704q-21 0-29-18-8-20 4-35l160-192q9-11 25-11h320v-640h-192q-40 0-58-37-17-37 9-68l320-384q18-22 49-22t49 22l320 384q27 32 9 68z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1792 1792" id="calendar">
      <path
        d="M192 1664h288v-288h-288v288zm352 0h320v-288h-320v288zm-352-352h288v-320h-288v320zm352 0h320v-320h-320v320zm-352-384h288v-288h-288v288zm736 736h320v-288h-320v288zm-384-736h320v-288h-320v288zm768 736h288v-288h-288v288zm-384-352h320v-320h-320v320zm-352-864v-288q0-13-9.5-22.5t-22.5-9.5h-64q-13 0-22.5 9.5t-9.5 22.5v288q0 13 9.5 22.5t22.5 9.5h64q13 0 22.5-9.5t9.5-22.5zm736 864h288v-320h-288v320zm-384-384h320v-288h-320v288zm384 0h288v-288h-288v288zm32-480v-288q0-13-9.5-22.5t-22.5-9.5h-64q-13 0-22.5 9.5t-9.5 22.5v288q0 13 9.5 22.5t22.5 9.5h64q13 0 22.5-9.5t9.5-22.5zm384-64v1280q0 52-38 90t-90 38h-1408q-52 0-90-38t-38-90v-1280q0-52 38-90t90-38h128v-96q0-66 47-113t113-47h64q66 0 113 47t47 113v96h384v-96q0-66 47-113t113-47h64q66 0 113 47t47 113v96h128q52 0 90 38t38 90z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1792 1792" id="barchart">
      <path
        d="M640 896v512h-256v-512h256zm384-512v1024h-256v-1024h256zm1024 1152v128h-2048v-1536h128v1408h1920zm-640-896v768h-256v-768h256zm384-384v1152h-256v-1152h256z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1792 1792" id="circleplus">
      <path
        d="M1344 960v-128q0-26-19-45t-45-19h-256v-256q0-26-19-45t-45-19h-128q-26 0-45 19t-19 45v256h-256q-26 0-45 19t-19 45v128q0 26 19 45t45 19h256v256q0 26 19 45t45 19h128q26 0 45-19t19-45v-256h256q26 0 45-19t19-45zm320-64q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1792 1792" id="iplus">
      <path
        d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 512 512" id="sign-in">
      <path
        d="M137.2 110.3l21.9-21.9c9.3-9.3 24.5-9.4 33.9-.1L344.9 239c9.5 9.4 9.5 24.7 0 34.1L193 423.7c-9.4 9.3-24.5 9.3-33.9-.1l-21.9-21.9c-9.7-9.7-9.3-25.4.8-34.7l77.6-71.1H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h191.5l-77.6-71.1c-10-9.1-10.4-24.9-.7-34.5zM512 352V160c0-53-43-96-96-96h-84c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h84c17.7 0 32 14.3 32 32v192c0 17.7-14.3 32-32 32h-84c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h84c53 0 96-43 96-96z"
      />
    </symbol>
    <symbol viewBox="0 0 1792 1792" id="file-download">
      <path
        d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-1024-864q0-14 9-23t23-9h704q14 0 23 9t9 23v64q0 14-9 23t-23 9h-704q-14 0-23-9t-9-23v-64zm736 224q14 0 23 9t9 23v64q0 14-9 23t-23 9h-704q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h704zm0 256q14 0 23 9t9 23v64q0 14-9 23t-23 9h-704q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h704z"
      ></path>
    </symbol>
    <symbol viewBox="0 0 1792 1792" id="file-print">
      <path
        d="M448 1536h896v-256h-896v256zm0-640h896v-384h-160q-40 0-68-28t-28-68v-160h-640v640zm1152 64q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128 0v416q0 13-9.5 22.5t-22.5 9.5h-224v160q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-160h-224q-13 0-22.5-9.5t-9.5-22.5v-416q0-79 56.5-135.5t135.5-56.5h64v-544q0-40 28-68t68-28h672q40 0 88 20t76 48l152 152q28 28 48 76t20 88v256h64q79 0 135.5 56.5t56.5 135.5z"
      ></path>
    </symbol>

    <symbol viewBox="0 0 1792 1792" id="balance-scale">
      <path
        d="M1728 448l-384 704h768zm-1280 0l-384 704h768zm821-192q-14 40-45.5 71.5t-71.5 45.5v1291h608q14 0 23 9t9 23v64q0 14-9 23t-23 9h-1344q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h608v-1291q-40-14-71.5-45.5t-45.5-71.5h-491q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h491q21-57 70-92.5t111-35.5 111 35.5 70 92.5h491q14 0 23 9t9 23v64q0 14-9 23t-23 9h-491zm-181 16q33 0 56.5-23.5t23.5-56.5-23.5-56.5-56.5-23.5-56.5 23.5-23.5 56.5 23.5 56.5 56.5 23.5zm1088 880q0 73-46.5 131t-117.5 91-144.5 49.5-139.5 16.5-139.5-16.5-144.5-49.5-117.5-91-46.5-131q0-11 35-81t92-174.5 107-195.5 102-184 56-100q18-33 56-33t56 33q4 7 56 100t102 184 107 195.5 92 174.5 35 81zm-1280 0q0 73-46.5 131t-117.5 91-144.5 49.5-139.5 16.5-139.5-16.5-144.5-49.5-117.5-91-46.5-131q0-11 35-81t92-174.5 107-195.5 102-184 56-100q18-33 56-33t56 33q4 7 56 100t102 184 107 195.5 92 174.5 35 81z"
      ></path>
    </symbol>

    <symbol viewBox="0 0 1792 1792" id="fast-forward">
      <path
        d="M45 1651q-19 19-32 13t-13-32v-1472q0-26 13-32t32 13l710 710q9 9 13 19v-710q0-26 13-32t32 13l710 710q9 9 13 19v-678q0-26 19-45t45-19h128q26 0 45 19t19 45v1408q0 26-19 45t-45 19h-128q-26 0-45-19t-19-45v-678q-4 10-13 19l-710 710q-19 19-32 13t-13-32v-710q-4 10-13 19z"
      ></path>
    </symbol>
  </defs>
</svg>
