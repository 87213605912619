<app-fixed-panel [excludedComponents]="listClassExcluded" [specificClassName]="'Island-employee--time'">
  <app-employee-info
    [companyUser]="employeeService.employee | async"
    [currentPositionId]="employeeService.selectedEmploymentId | async"
    [isEmployeeTab]="true"
    (expandUserInfoEmitter)="onExpand($event)"
  ></app-employee-info>

  <div
    class="Island Island-employee Island-employee--time"
    *ngIf="!employeeService.isNonSelected"
    [ngClass]="{ 'is-expand': expanded }"
  >
    <div *ngIf="!HasEmployment">
      <div class="Card">
        <div class="Card-inner withOnlyButton">
          <app-module-check [moduleId]="8" [(hasModule)]="hasTimeModule">
            <h2 class="Card-title">{{ 'EmploymentPayrollData.NoEmployment' | translate }}</h2>
            <app-button
              *ngIf="sessionService.role.IsSalaryAdminAndHigher"
              [buttonClass]="'Button--primary'"
              [isDisabled]="!hasTimeModule"
              (clickEvent)="createUserEmployment()"
              [label]="'EmployeeGeneral.CreateEmployment' | translate"
            ></app-button>
          </app-module-check>
        </div>
      </div>
    </div>

    <!-- GS-5298 <span class="EmployeeWarning">{{ noCPRMessage | translate }} </span> -->

    <div class="Cards Cards--flex Form--stacked" *ngIf="HasEmployment && !isMobile">
      <div class="Card Employee-timeCard">
        <div class="Card-inner">
          <app-module-check [moduleId]="8" [(hasModule)]="hasTimeModule">
            <div class="Employee-timeControls">
              <app-combobox-edit
                class="employmentTime-SalaryPeriod fix-min-width"
                [label]="'EmploymentTime.SalaryPeriod' | translate"
                [disable]="!hasTimeModule"
                [editMode]="true"
                [comboboxDataSource]="SalaryPeriods"
                [textField]="'FriendlyName'"
                [idField]="'Id'"
                [isConvertTime]="false"
                [(value)]="selectedSalaryPeriodId"
                [showNavigationButton]="true"
                (valueChange)="onSalaryPeriodChanged($event)"
                [keepDataSourceOrder]="true"
              ></app-combobox-edit>

              <app-combobox-edit
                class="employmentTime-Status fix-min-width"
                [label]="'EmploymentTime.Status' | translate"
                [disable]="!hasTimeModule"
                [editMode]="true"
                [comboboxDataSource]="TimeEntryStatuses"
                [(value)]="SelectedTimeStatusId"
                [textField]="'Name'"
                [idField]="'Id'"
                (valueChange)="timeStatusChanged()"
              >
              </app-combobox-edit>

              <button
                *ngIf="!isHideApprovePeriod"
                class="Button--card Button--employee-time"
                type="button"
                [disabled]="!hasTimeModule || sessionService.isSubmitting || !isAllowEditGridOption"
                (click)="ApproveEntries()"
              >
                {{ 'EmploymentTime.Approve' | translate }}
              </button>
            </div>

            <div class="FormElement">
              <div class="FormElement-control">
                <app-grid
                  [editMode]="
                    !(sessionService.isGetting || sessionService.isSubmitting) &&
                    (!IsReadOnly || isSelfService) &&
                    isAllowEditGridOption
                  "
                  [addNew]="!IsReadOnly || isSelfService"
                  [addNewText]="'+ ' + ('EmploymentTime.AddNewRecord' | translate)"
                  [showButDisableAddNewButton]="true"
                  [addNewDisable]="sessionService.isGetting || !isAllowEditGridOption"
                  [addNewTooltips]="DisbaleTooltipAddNewEmployeetime | translate"
                  (disableButtonAddNewClickEvent)="isDisableAddNew = true"
                  [deleteRow]="!IsReadOnly || isSelfService"
                  [(data)]="TimeEntryRecords"
                  [idColumn]="'idColumn'"
                  (selectedItemChange)="onSelectRow($event)"
                  (gridButtonClick)="onGridButtonClick($event)"
                  (updateDefaultRecordEvent)="onUpdateDefaultRecord()"
                  (cellCloseEvent)="oncellCloseEvent($event)"
                  (saveChangesEvent)="onSaveChangeGrid($event)"
                  (addNewEvent)="onAddNewRecord($event)"
                  (controlDataChange)="onControlDataChange($event)"
                  [defaultNewValue]="defaultRecordValue"
                  [customDeleteRow]="true"
                  [selectedRows]="selectedRows"
                  (removeEvent)="onDeleteRow($event)"
                  [isEmployeeTime]="true"
                  [(triggerUpdate)]="triggerUpdate"
                  #kendoGridComponent
                  class="FormElement-grid Employee-timeEntryGrid {{ EmptyEmployeetime() }}"
                  [filterable]="'menu'"
                  [filter]="filter"
                  [(filterClear)]="filterClear"
                  (filterChange)="onFilterChange($event)"
                  [disableDeleteButton]="!isAllowEditGridOption"
                  [DeleteTooltips]="DisbaleTooltipAddNewEmployeetime | translate"
                  [deleteRowField]="'isvisibleDeletebytton'"
                  (DisableHotKeys)="isDisableAddNew = true"
                >
                  <app-grid-column
                    [isFilterable]="true"
                    [title]="'EmploymentTime.Type' | translate"
                    [type]="'combobox'"
                    [field]="'TimeEntryTypeId'"
                    [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
                    [editable]="true"
                    [comboboxDataSource]="TimeEntryTypes"
                    [subEditableField]="'isAllowEdit'"
                    [comboboxValueField]="'TimeEntryTypeId'"
                    [comboboxDisplayField]="'Name'"
                    [width]="135"
                    [tooltipField]="'Description'"
                    [tooltipFieldNonEdit]="'TimeEntryTypeName'"
                    [headerTooltip]="'EmploymentTime.Type' | translate"
                  ></app-grid-column>
                  <app-grid-column
                    [isFilterable]="true"
                    [title]="'EmploymentTime.EntryDate' | translate"
                    [type]="'datepicker'"
                    [field]="'EntryDate'"
                    [editable]="true"
                    [subEditableField]="'isAllowEdit'"
                    [width]="82"
                    [headerTooltip]="'EmploymentTime.EntryDate' | translate"
                  ></app-grid-column>
                  <app-grid-column
                    [isFilterable]="true"
                    [title]="'EmploymentTime.EndDate' | translate"
                    [type]="'datepicker'"
                    [field]="'EndDate'"
                    [minDate]="selectedMinDate"
                    [editable]="true"
                    [subEditableField]="'isAllowEdit'"
                    [width]="82"
                    [headerTooltip]="'EmploymentTime.EndDate' | translate"
                    [AllowNull]="true"
                  ></app-grid-column>
                  <app-grid-column
                    [isFilterable]="true"
                    [title]="'EmploymentTime.StartTime' | translate"
                    [type]="'timepicker'"
                    [field]="'StartTime'"
                    [editable]="true"
                    [editableField]="'isEditStartAndEndTime'"
                    [classField]="'ClassIsEditStartAndEndTime'"
                    [subEditableField]="'isAllowEdit'"
                    [width]="85"
                    [headerTooltip]="'EmploymentTime.StartTime' | translate"
                  >
                  </app-grid-column>
                  <app-grid-column
                    [isFilterable]="true"
                    [title]="'EmploymentTime.EndTime' | translate"
                    [type]="'timepicker'"
                    [field]="'EndTime'"
                    [editable]="true"
                    [editableField]="'isEditStartAndEndTime'"
                    [classField]="'ClassIsEditStartAndEndTime'"
                    [subEditableField]="'isAllowEdit'"
                    [width]="90"
                    [headerTooltip]="'EmploymentTime.EndTime' | translate"
                  >
                  </app-grid-column>
                  <app-grid-column
                    [isFilterable]="true"
                    [title]="'EmploymentTime.Units' | translate"
                    [type]="'numeric'"
                    [field]="'Units'"
                    [editable]="true"
                    [editableField]="'isEditUnits'"
                    [classField]="'ClassIsEditUnits'"
                    [numbericOption]="numberOption"
                    [subEditableField]="'isAllowEdit'"
                    [width]="80"
                    [headerTooltip]="'EmploymentTime.Units' | translate"
                  ></app-grid-column>
                  <app-grid-column
                    [isFilterable]="true"
                    [title]="'EmploymentTime.UnitType' | translate"
                    [type]="'combobox'"
                    [field]="'UnitTypeId'"
                    [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
                    [editable]="false"
                    [editableField]="'isEditUnitType'"
                    [classField]="'ClassIsEditUnitType'"
                    [comboboxDataSource]="UnitTypes"
                    [comboboxValueField]="'Id'"
                    [comboboxDisplayField]="'Name'"
                    [subEditableField]="'isAllowEdit'"
                    [width]="70"
                    [headerTooltip]="'EmploymentTime.UnitType' | translate"
                  ></app-grid-column>
                  <app-grid-column
                    [isFilterable]="true"
                    [title]="'EmploymentTime.Description' | translate"
                    [type]="'text'"
                    [field]="'Description'"
                    [tooltipField]="'Description'"
                    [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
                    [editable]="true"
                    [subEditableField]="'isAllowEdit'"
                    [width]="135"
                    [headerTooltip]="'EmploymentTime.Description' | translate"
                  ></app-grid-column>
                  <app-grid-column
                    [title]="'EmploymentTime.Status' | translate"
                    [type]="'subProberty'"
                    [field]="'Status'"
                    [editable]="true"
                    [editableField]="true"
                    [subProberty]="'Status.Name'"
                    [tooltipField]="'RejectionReason'"
                    [subEditableField]="'isAllowEdit'"
                    [width]="90"
                    [headerTooltip]="'EmploymentTime.Status' | translate"
                  ></app-grid-column>
                  <app-grid-column
                    [isFilterable]="true"
                    [title]="'EmploymentTime.KilometersDriven' | translate"
                    [type]="'numeric'"
                    [numbericOption]="numberOption"
                    [field]="'KilometersDriven'"
                    [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
                    [editable]="true"
                    [subEditableField]="'isAllowEdit'"
                    [width]="85"
                    [headerTooltip]="'EmploymentTime.KilometersDriven' | translate"
                  ></app-grid-column>
                  <app-grid-column
                    [isFilterable]="true"
                    [title]="Dimension1Name"
                    [field]="'Dimension1Value'"
                    *ngIf="isHideDimension1 && !isEnableDimensionPreference"
                    [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
                    [editable]="true"
                    [subEditableField]="'isAllowEdit'"
                    [width]="90"
                    [headerTooltip]="Dimension1Name"
                    [type]="'text'"
                  >
                  </app-grid-column>
                  <app-grid-column
                    [isFilterable]="true"
                    [title]="Dimension1Name"
                    [field]="'Dimension1ValueId'"
                    *ngIf="isHideDimension1 && isEnableDimensionPreference"
                    [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
                    [editable]="true"
                    [subEditableField]="'isAllowEdit'"
                    [width]="90"
                    [headerTooltip]="Dimension1Name"
                    [type]="'combobox'"
                    [comboboxDataSource]="dimensionXValues[0]"
                    [comboboxValueField]="'Id'"
                    [comboboxDisplayField]="'Value'"
                    [allowComboeditNull]="true"
                  >
                  </app-grid-column>
                  <app-grid-column
                    [isFilterable]="true"
                    [title]="Dimension2Name"
                    [field]="'Dimension2Value'"
                    *ngIf="isHideDimension2 && !isEnableDimensionPreference"
                    [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
                    [editable]="true"
                    [subEditableField]="'isAllowEdit'"
                    [width]="90"
                    [headerTooltip]="Dimension2Name"
                    [type]="'text'"
                  >
                  </app-grid-column>
                  <app-grid-column
                    [isFilterable]="true"
                    [title]="Dimension2Name"
                    [field]="'Dimension2ValueId'"
                    *ngIf="isHideDimension2 && isEnableDimensionPreference"
                    [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
                    [editable]="true"
                    [subEditableField]="'isAllowEdit'"
                    [width]="90"
                    [headerTooltip]="Dimension2Name"
                    [type]="'combobox'"
                    [comboboxDataSource]="dimensionXValues[1]"
                    [comboboxValueField]="'Id'"
                    [comboboxDisplayField]="'Value'"
                    [allowComboeditNull]="true"
                  >
                  </app-grid-column>
                  <app-grid-column
                    [isFilterable]="true"
                    [title]="Dimension3Name"
                    [field]="'Dimension3Value'"
                    *ngIf="isHideDimension3 && !isEnableDimensionPreference"
                    [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
                    [editable]="true"
                    [subEditableField]="'isAllowEdit'"
                    [width]="90"
                    [headerTooltip]="Dimension3Name"
                    [type]="'text'"
                  >
                  </app-grid-column>
                  <app-grid-column
                    [isFilterable]="true"
                    [title]="Dimension3Name"
                    [field]="'Dimension3ValueId'"
                    *ngIf="isHideDimension3 && isEnableDimensionPreference"
                    [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
                    [editable]="true"
                    [subEditableField]="'isAllowEdit'"
                    [width]="90"
                    [headerTooltip]="Dimension3Name"
                    [type]="'combobox'"
                    [comboboxDataSource]="dimensionXValues[2]"
                    [comboboxValueField]="'Id'"
                    [comboboxDisplayField]="'Value'"
                    [allowComboeditNull]="true"
                  >
                  </app-grid-column>
                  <app-grid-column
                    *ngIf="!isHideApprovePeriod"
                    class="align-center"
                    [isFilterable]="true"
                    [title]="''"
                    [type]="'button'"
                    [buttonToottip]="''"
                    [buttonLabel]="'EmploymentTime.Reject'"
                    [editable]="true"
                    [buttonAction]="'Reject'"
                    [width]="80"
                    [disableButtonFeild]="'isDisableRejectbutton'"
                    [buttonClass]="'rejectbuttonClass'"
                  >
                  </app-grid-column>
                </app-grid>
              </div>
            </div>
          </app-module-check>
        </div>
      </div>

      <div
        *ngIf="!isMobile"
        [ngClass]="isSelfService ? 'Selfservice-timePreviewCard' : 'Employee-timePreviewCard'"
        class="Card"
      >
        <div class="Card-inner">
          <div class="Card-title">{{ 'EmploymentTime.Preview' | translate }}</div>
          <div class="FormElement Employee-timeEntryPreviewGrid">
            <label class="FormElement-label" for="previewGrid">
              {{ 'EmploymentTime.SumForThisPeriod' | translate }}
            </label>
            <div class="FormElement-control">
              <app-grid
                [editMode]="false"
                [addNew]="false"
                [deleteRow]="false"
                [data]="newPreviewData"
                (selectedItemChange)="onSelectRow($event)"
                (gridButtonClick)="onGridButtonClick($event)"
                class="FormElement-grid {{ EmptyEmployeePreview() }}"
              >
                <app-grid-column
                  [title]="'EmploymentTime.Type' | translate"
                  [type]="'text'"
                  [field]="'Type'"
                  [width]="100"
                >
                </app-grid-column>
                <app-grid-column
                  [title]="'EmploymentTime.Units' | translate"
                  [type]="'numeric'"
                  [field]="'Units'"
                  [width]="100"
                >
                </app-grid-column>
                <app-grid-column [title]="''" [type]="'text'" [field]="'UnitName'" [width]="45"> </app-grid-column>
              </app-grid>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-dialog class="blue-dialog" [(visible)]="isErrorApiMessage" [width]="'500'" [leadingText]="MessagErrorApi">
      <app-dialog-action type="Ok"></app-dialog-action>
    </app-dialog>

    <app-dialog
      class="blue-dialog"
      [(visible)]="isDisableAddNew"
      [width]="'500'"
      [leadingText]="DisbaleTooltipAddNewEmployeetime | translate"
      (action)="setKeypress()"
    >
      <app-dialog-action type="Ok"></app-dialog-action>
    </app-dialog>

    <app-dialog
      class="blue-dialog"
      [(visible)]="isUpdateWarrningPeriods"
      [width]="'500'"
      [leadingText]="MessageUpdateWarrningPeriods"
    >
      <app-dialog-action type="Ok"></app-dialog-action>
    </app-dialog>

    <app-dialog
      class="blue-dialog"
      [(visible)]="isgetNotMatchPeriodMessageString"
      [width]="'500'"
      [leadingText]="getNotMatchPeriodMessageString | translate"
      (action)="onCloseNotMatchPeriodMessage($event)"
    >
      <app-dialog-action type="Ok"></app-dialog-action>
    </app-dialog>

    <app-dialog
      [overlayClose]="false"
      [(visible)]="isShowRejectDialogNonApproveTimeEntry"
      [width]="'500'"
      (action)="onRejectDialog($event)"
      [leadingText]="'EmploymentTime.RejectionWarning' | translate"
    >
      <app-text-edit [label]="''" [editMode]="true" [(value)]="rejectMessage"></app-text-edit>
      <app-dialog-action type="Close"></app-dialog-action>
      <app-dialog-action type="RejectPrimary" [disabled]="sessionService.isSubmitting"></app-dialog-action>
    </app-dialog>

    <app-dialog
      [(visible)]="isShowDeleteRecord"
      [width]="'500'"
      (action)="onDeleteRecordAction($event)"
      [leadingText]="'Grids.ConfirmDelete' | translate"
    >
      <app-dialog-action type="Close"></app-dialog-action>
      <app-dialog-action type="Delete" [disabled]="sessionService.isSubmitting"></app-dialog-action>
    </app-dialog>

    <app-dialog
      class="blue-dialog"
      [(visible)]="isShowDateErrorInput"
      [width]="'500'"
      [leadingText]="'EmploymentTime.StartDateBeforeEndDate' | translate"
    >
      <!-- missing title -->
      <app-dialog-action type="Ok"></app-dialog-action>
    </app-dialog>

    <div *ngIf="isMobile && HasEmployment">
      <app-module-check [moduleId]="8">
        <app-employee-time-mobile
          [isReadOnly]="IsReadOnly"
          [salaryPeriods]="SalaryPeriods"
          [timeEntryTypes]="TimeEntryTypes"
          [(selectedSalaryPeriodId)]="selectedSalaryPeriodId"
          (periodsChange)="onSalaryperiodsMobileCentricChange()"
          (approveEntries)="ApproveEntries()"
          [(timeEntryRecords)]="TimeEntryRecords"
          [(isAddNew)]="isAddNew"
          [userEmployment]="userEmployment"
          [unitTypes]="UnitTypes"
          [deleteConfimMessage]="'Grids.ConfirmDelete' | translate"
          [rejectionWarningText]="'EmploymentTime.RejectionWarning' | translate"
          [isHideDimension1]="isHideDimension1"
          [isHideDimension2]="isHideDimension2"
          [isHideDimension3]="isHideDimension3"
          [Dimension1Name]="Dimension1Name"
          [Dimension2Name]="Dimension2Name"
          [Dimension3Name]="Dimension3Name"
          [dimensionXValues]="dimensionXValues"
          [PeriodWarningText]="PeriodWarningText"
          [isEnableDimensionPreference]="isEnableDimensionPreference"
          (updateDimension)="updateDimensionValue()"
        >
        </app-employee-time-mobile>
      </app-module-check>
    </div>
  </div>

  <div class="NonSelected" *ngIf="employeeService.isNonSelected && sessionService.role.IsSalaryAdminAndHigher">
    <div class="Card">
      <div class="Card-inner">
        <app-button
          buttonClass="Button--primary"
          (clickEvent)="createNewEmployeeDialogVisible = true"
          [label]="'Employee.NewEmployee' | translate"
        ></app-button>
      </div>
    </div>
  </div>
</app-fixed-panel>

<div class="Actions" *ngIf="!isMobile">
  <app-action-button
    *ngIf="sessionService.role.IsSalaryAdminAndHigher && isEmployeeTabs"
    id="ActionButtonAdd"
    [icon]="'AddUser'"
    [label]="'Employee.New'"
    [tooltip]="'Employee.NewEmployee'"
    [buttonClass]="'Action-button Action-buttonAdd'"
    (click)="createNewEmployeeDialogVisible = true"
  >
  </app-action-button>

  <app-menu-button [menuIcon]="'Help'" [menuLabel]="'Help.Title'" [menuTooltip]="'Help.Title'" [multiMenuItems]="true">
    <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
    <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
  </app-menu-button>

  <app-action-button
    *ngIf="sessionService.browser.isMobile && !sessionService.role.IsReadOnly"
    [ngClass]="{ disabledActionButtonClass: this.sessionService.isGetting }"
    [buttonClass]="'Action-button Action-buttonAdd'"
    [icon]="'Plus'"
    [label]="'Employee.New'"
    (click)="isAddNew = true"
    [tooltip]="'Employee.NewEmployee'"
    [disabled]="sessionService.isGetting"
  ></app-action-button>

  <app-action-button
    *ngIf="reportDialogService.isVisibleReportIcon && !employeeService.isNonSelected"
    buttonClass="Action-button"
  ></app-action-button>
</div>

<div class="Actions" *ngIf="isMobile">
  <app-menu-button
    *ngIf="!sessionService.role.IsReadOnly"
    (menuClick)="onAddNewItem()"
    [menuIcon]="'Add'"
    [menuLabel]="'EmploymentTime.AddNewRecord'"
    [menuTooltip]="'EmploymentTime.AddNewRecord'"
    [actionButtonMenu]="true"
    [menuButtonClass]="'Action-button Action-buttonAdd'"
    [menuButtonId]="'ActionButtonAddEmployeeTime'"
    [menuCondition]="true"
  >
  </app-menu-button>
  <app-menu-button
    [menuIcon]="'ThreeDots'"
    [menuLabel]="'Help.More'"
    [menuTooltip]="'Help.More'"
    [multiMenuItems]="true"
  >
    <app-menu-button-item
      *ngIf="sessionService.role.IsSalaryAdminAndHigher && isEmployeeTabs"
      (menuItemClick)="createNewEmployeeDialogVisible = true"
      [field]="'AddNewEmployee'"
    ></app-menu-button-item>
    <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
    <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
  </app-menu-button>
</div>

<app-new-employee-dialog [(visible)]="createNewEmployeeDialogVisible"></app-new-employee-dialog>

<app-new-employment-dialog [(isVisible)]="newEmploymentDialogVisible" [currentCompanyUserId]="currentCompanyUser?.Id">
</app-new-employment-dialog>

<app-dialog
  [(visible)]="isShowEmployeeTimeApproveDialog"
  [width]="'500'"
  (action)="onEmployeeTimeApproveDialog($event)"
  [leadingText]="'EmploymentTime.ApproveConfirmText' | translate"
>
  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action type="Approve" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>
