import { OnInit } from '@angular/core';
import { filterBy } from '@progress/kendo-data-query';
import { TransitionService } from '@uirouter/core';
import { SettingService } from 'src/app/services/setting.service';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { ReportDialogService } from '../../shared-components/report-dialog/report-dialog.service';
import { AccountantUsersService } from './accountant-users.service';
var AccountantUsersComponent = /** @class */ (function () {
    function AccountantUsersComponent(service, staticData, dataService, sessionService, transitionService, settingService, reportDialogService) {
        var _this = this;
        this.service = service;
        this.staticData = staticData;
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.transitionService = transitionService;
        this.settingService = settingService;
        this.reportDialogService = reportDialogService;
        this.addNewUserDialogVisible = false;
        this.removeUserDialogVisible = false;
        this.accountName = '';
        this.isFirstLoad = false;
        this.discardDialogVisible = false;
        this.triggerUpdate = false;
        this.addNewUserVisible = false;
        this.removeSuccessConfirm = false;
        this.pageSize = 100;
        this.listClassExcluded = ['Footer', '10'];
        this.gridUserFilterData = filterBy(this.dataUserList, this.userFilter);
        this.gridFilterData = filterBy(this.dataRoleList, this.filter);
        this.staticData.currentAccount.pipe().subscribe(function (account) {
            _this.accountName = account.Name;
        });
        this.service.updateUsers.subscribe(function (model) {
            if (model && model.length > 0 && _this.gridUserFilterData && _this.gridUserFilterData.length > 0) {
                model.forEach(function (item) {
                    Object.assign(_this.gridUserFilterData.find(function (flag) { return flag.Id === item.Id; }), item);
                    _this.triggerUpdate = true;
                });
            }
        });
        this.service.users.subscribe(function (data) {
            _this.dataUserList = data;
            _this.gridUserFilterData = filterBy(_this.dataUserList, _this.userFilter);
        });
        this.service.roles.subscribe(function (data) {
            _this.dataRoleList = data;
            _this.dataRoleList.sort(function (a, b) {
                if (a.CompanyName && b.CompanyName) {
                    return a.CompanyName.localeCompare(b.CompanyName);
                }
                else {
                    return a.CompanyId - b.CompanyId;
                }
            });
            _this.gridFilterData = filterBy(_this.dataRoleList, _this.filter);
        });
    }
    Object.defineProperty(AccountantUsersComponent.prototype, "isIOSApp", {
        get: function () {
            return this.sessionService.browser.isHybridApp && this.sessionService.browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantUsersComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantUsersComponent.prototype, "isAccountantAdmin", {
        get: function () {
            return this.sessionService.role.IsMultiCompanyAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantUsersComponent.prototype, "isRemoveUser", {
        get: function () {
            return (this.sessionService.role.IsMultiCompanyAdmin &&
                this.service.selectedId !== null &&
                this.service.selectedId !== undefined);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantUsersComponent.prototype, "isShowPaginationControl", {
        get: function () {
            return this.gridUserFilterData.length <= this.pageSize ? false : true;
        },
        enumerable: true,
        configurable: true
    });
    AccountantUsersComponent.prototype.onRowDataChange = function (event) {
        var model = {
            RoleId: event.dataItem.RoleId,
            UserId: event.dataItem.UserId,
            isPaymentApprover: event.dataItem.IsPaymentApprover || false,
            CompanyId: event.dataItem.CompanyId
        };
        if (!this.service.EditRoleList.find(function (item) { return item.CompanyId === model.CompanyId; })) {
            this.service.EditRoleList.push(model);
        }
        else {
            Object.assign(this.service.EditRoleList.find(function (item) { return item.CompanyId === model.CompanyId; }), model);
        }
    };
    AccountantUsersComponent.prototype.UserGridChange = function (event) {
        if (event.dataItem && event.dataItem.Email) {
            event.dataItem.Email = event.dataItem.Email;
        }
        var model = {
            Id: event.dataItem.Id,
            FirstName: event.dataItem.FirstName || null,
            MiddleName: event.dataItem.MiddleName || null,
            LastName: event.dataItem.LastName || null,
            TelMobile: event.dataItem.TelMobile || null,
            Email: event.dataItem.Email || null,
            AccountRoleId: event.dataItem.AccountRoleId,
            IdentityNumberFormatted: undefined,
            HasDummyIdentityNumber: undefined,
            LanguageId: this.checkNumber(event.dataItem.LanguageId),
            AddressId: this.checkNumber(event.dataItem.AddressId),
            UseGlobalEmail: event.dataItem.UseGlobalEmail || false,
            IsFirstLogin: event.dataItem.IsFirstLogin || false,
            Created: event.dataItem.Created || null,
            ShowUpdateNotification: event.dataItem.ShowUpdateNotification || false,
            ShowUrgentNotification: event.dataItem.ShowUrgentNotification || false,
            ShowPayslipTutorial: this.checkNumber(event.dataItem.ShowPayslipTutorial),
            IdentityNumber: event.dataItem.IdentityNumber || null,
            BankRegNo: event.dataItem.BankRegNo || null,
            BankAccountNo: event.dataItem.BankAccountNo || null,
            Username: event.dataItem.Username || null,
            BicSwiftNo: event.dataItem.BicSwiftNo || null,
            IbanNo: event.dataItem.IbanNo || null,
            AccessPhrase: event.dataItem.AccessPhrase || null,
            DateOfBirth: event.dataItem.DateOfBirth || null,
            TelFixed: event.dataItem.TelFixed || null,
            DefaultInitials: event.dataItem.DefaultInitials || null,
            DefaultCompanyId: this.checkNumber(event.dataItem.DefaultCompanyId),
            Preferences: event.dataItem.Preferences || null,
            AccountId: this.checkNumber(event.dataItem.AccountId),
            IsFemale: event.dataItem.IsFemale || false,
            FullName: undefined,
            Gender: undefined,
            AccountRole: undefined,
            SystemAliasId: event.dataItem.SystemAliasId || null
        };
        if (!this.service.EditUserList.find(function (item) { return item.Id === model.Id; })) {
            this.service.EditUserList.push(model);
        }
        else {
            Object.assign(this.service.EditUserList.find(function (item) { return item.Id === model.Id; }), model);
        }
    };
    AccountantUsersComponent.prototype.checkNumber = function (value) {
        if ((!value && value === 0) || value) {
            return value;
        }
        return null;
    };
    AccountantUsersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isFirstLoad = true;
        this.transitionService.onStart({}, function (transition) {
            var fromState = transition.from().name;
            var toState = transition.to().name;
            _this.service.navigationToStateName = JSON.parse(JSON.stringify(toState));
            if (_this.service.editMode && fromState !== toState) {
                if ((_this.service.EditRoleList && _this.service.EditRoleList.length > 0) ||
                    (_this.service.EditUserList && _this.service.EditUserList.length > 0)) {
                    _this.service.showSaveDataConfirmationChangeState = true;
                }
                else {
                    _this.onEditModeChange(false);
                    _this.sessionService.NavigateTo(_this.service.navigationToStateName);
                    return true;
                }
                return false;
            }
            _this.service.clearUserSubject();
            return true;
        });
        this.staticData.Role.pipe().subscribe(function (role) {
            if (role && role.length > 0) {
                _this.BaseSalaryAdminIdOption = role.find(function (model) { return model.Key === 'SalaryAdmin'; }).Id;
                _this.BaseFullAdminIdOption = role.find(function (model) { return model.Key === 'FullAdmin'; }).Id;
            }
        });
    };
    AccountantUsersComponent.prototype.onConfirmSaveDataOnChangeState = function (action) {
        if (action === 'ContinueAndSave') {
            this.onEditModeChange(false);
            this.sessionService.NavigateTo(this.service.navigationToStateName);
            return;
        }
        if (action === 'DiscardAndLeave') {
            this.service.EditRoleList = [];
            this.service.EditUserList = [];
            this.service.loadData();
            this.service.discard();
            this.onEditModeChange(false);
            this.sessionService.NavigateTo(this.service.navigationToStateName);
            return;
        }
    };
    AccountantUsersComponent.prototype.tabDblclick = function ($event) {
        if (!this.service.editMode) {
            if ($event) {
                $event.preventDefault();
                $event.stopPropagation();
            }
            this.onEditModeChange(true);
        }
    };
    AccountantUsersComponent.prototype.onEditModeChange = function ($event) {
        if (this.service.isBasicUser || this.service.isCompanyAdmin) {
            return;
        }
        if ($event && (this.sessionService.isGetting || this.sessionService.isSubmitting)) {
            return;
        }
        if (!this.service.selectedId) {
            if (this.gridUserFilterData && this.gridUserFilterData.length > 0) {
                this.onSelectedIdChange(this.gridUserFilterData[0].Id);
            }
            else {
                return;
            }
        }
        if (this.service.editMode) {
            this.service.save(this.service.EditRoleList);
            this.service.EditRoleList = [];
            this.service.saveUserList(this.service.EditUserList);
            this.service.EditUserList = [];
        }
        this.service.editMode = $event;
    };
    AccountantUsersComponent.prototype.confirmAndDiscardChanges = function () {
        if ((this.service.EditRoleList && this.service.EditRoleList.length > 0) ||
            (this.service.EditUserList && this.service.EditUserList.length > 0)) {
            this.discardDialogVisible = true;
            return;
        }
        this.onEditModeChange(false);
    };
    AccountantUsersComponent.prototype.discardDialogAction = function (action) {
        if (action === 'ConfirmDiscard') {
            this.service.EditRoleList = [];
            this.service.EditUserList = [];
            this.service.loadData();
            this.service.discard();
            this.onEditModeChange(false);
        }
    };
    AccountantUsersComponent.prototype.removeUserAction = function (action) {
        var _this = this;
        if (action === 'Delete') {
            this.dataService.Account_RemoveUser(this.service.selectedId).subscribe(function () {
                _this.removeSuccessConfirm = true;
            });
        }
    };
    AccountantUsersComponent.prototype.onDoneRemoveSuccess = function (event) {
        this.settingService.ReloadWarrning = true;
        if (event) {
            if (event === 'Ok') {
                window.location.reload();
            }
        }
    };
    AccountantUsersComponent.prototype.onUserFilterChange = function (filter) {
        this.userFilter = filter;
        this.gridUserFilterData = filterBy(this.dataUserList, filter);
    };
    AccountantUsersComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        this.gridFilterData = filterBy(this.dataRoleList, filter);
    };
    AccountantUsersComponent.prototype.onSelectedIdChange = function (selectedId) {
        if (this.service.editMode && this.service.EditRoleList && this.service.EditRoleList.length > 0) {
            this.service.save(this.service.EditRoleList, false);
            this.service.EditRoleList = [];
        }
        this.service.selectedId = selectedId;
        this.service.getRoleServicePublic();
        if (!this.isFirstLoad) {
            this.gridFilterData = [];
            this.service.EditRoleList = [];
        }
        this.isFirstLoad = false;
    };
    AccountantUsersComponent.prototype.onUserRoleSelected = function (event) {
        // if (this.service.editMode && event) {
        //   const originApprover: boolean = JSON.parse(JSON.stringify(event.IsPaymentApprover));
        //   if (event.RoleId === this.BaseFullAdminIdOption || event.RoleId === this.BaseSalaryAdminIdOption) {
        //     event.IsPaymentApprover = true;
        //   } else {
        //     event.IsPaymentApprover = false;
        //   }
        //   if (originApprover !== event.IsPaymentApprover) {
        //     const onChangeData: any = {};
        //     onChangeData.dataItem = event;
        //     this.onRowDataChange(onChangeData);
        //   }
        // }
    };
    AccountantUsersComponent.prototype.onGridRoleControlDataChange = function (event) {
        if (event && event.field === 'RoleId') {
            if (event.dataItem.RoleId === this.BaseFullAdminIdOption ||
                event.dataItem.RoleId === this.BaseSalaryAdminIdOption) {
                event.dataItem.IsPaymentApprover = true;
            }
            else {
                event.dataItem.IsPaymentApprover = false;
            }
        }
        this.onRowDataChange(event);
    };
    AccountantUsersComponent.prototype.onMoreMenuOptionClick = function (menu) {
        if (menu && menu === 'accountant_removeuser') {
            this.removeUserDialogVisible = true;
        }
    };
    AccountantUsersComponent.prototype.onCreateUserComplete = function (data) {
        this.service.loadData();
        this.onEditModeChange(false);
    };
    return AccountantUsersComponent;
}());
export { AccountantUsersComponent };
