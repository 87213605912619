import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { EmployeeService } from 'src/app/services/employee.service';
import { SessionService } from 'src/app/services/session/session.service';
import { FormComponentBase } from '../../../common/form-component-base';
import { ICompanyUser, IUserEmployment } from '../../../services/api/api-model';
import { SettingService } from '../../../services/setting.service';
import { Position } from './position';

@Component({
  selector: 'app-position-info',
  templateUrl: './position-info.component.html'
})
export class PositionInfoComponent extends FormComponentBase implements OnInit, OnDestroy {
  @Output() public positionTextChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() public positionIdChanged: EventEmitter<any> = new EventEmitter();

  @Input() public isEmployeeTab = false;

  @Input()
  public get companyUser(): ICompanyUser {
    return this.companyUserValue;
  }
  public set companyUser(value: ICompanyUser) {
    this.companyUserValue = value;
    this.updateEmployments();
    this.selectDefaultPosition();
  }

  @Input()
  public get currentPositionId(): number {
    return this.currentPositionIdValue;
  }
  public set currentPositionId(value: number) {
    this.setcurrentUserEmploymentValue(value);
  }

  public get hasMultipleEmployments(): boolean {
    return this.companyUser && this.userEmploymentDataSource && this.userEmploymentDataSource.length > 1;
  }

  public get hasSingleEmployment(): boolean {
    return this.companyUser && this.userEmploymentDataSource && this.userEmploymentDataSource.length === 1;
  }

  public get hasNoEmployment(): boolean {
    return !this.companyUser || !this.companyUser.UserEmployments || this.companyUser.UserEmployments.length === 0;
  }

  public get allowEditTitle(): boolean {
    return this.editMode && !this.hasNoEmployment;
  }

  public get userEmploymentDataSource(): Position[] {
    return this.positions;
  }

  public set userEmploymentDataSource(value: Position[]) {
    if (this.positions !== value) {
      this.positions = value;
    }
  }

  public get isSelfService(): boolean {
    return this.sessionService.currentState.includes('tabs.selfservice');
  }

  public newEmploymentDialogVisible = false;
  public currentUserEmployment: IUserEmployment;

  private companyUserValue: ICompanyUser;
  private positions: Position[];
  private currentPositionIdValue: number;
  private newEmployment: IUserEmployment;

  constructor(
    public employeeService: EmployeeService,
    public sessionService: SessionService,
    private settingService: SettingService
  ) {
    super();
    this.childIsValid = Array(2).fill(true);
    this.childIsDirty = Array(2).fill(false);
  }

  public ngOnInit(): void {
    this.employeeService.employments.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.updateEmployments());
  }

  private updateEmployments() {
    if (this.hasNoEmployment) {
      this.userEmploymentDataSource = [];
    } else {
      const titles: Position[] = [];
      if (this.companyUserValue.UserEmployments) {
        this.companyUserValue.UserEmployments.forEach((item: IUserEmployment): void => {
          if (!item) {
            return;
          }

          const position: Position = this.createPosition(item);
          titles.push(position);
        });
      }

      this.userEmploymentDataSource = titles;
    }
  }

  private createPosition(employment: IUserEmployment): Position {
    const item: Position = new Position();
    item.DisplayTitle = employment.Title;
    const expireDate: Date = employment.ExpireDate ? new Date(employment.ExpireDate) : undefined;
    const today: Date = new Date();
    if (expireDate && expireDate < today) {
      item.DisplayTitle = '(' + employment.Title + ')';
    }

    item.Id = employment.Id;
    return item;
  }

  private selectDefaultPosition(): void {
    let currentEmployment: IUserEmployment;
    if (this.companyUser && this.companyUser.UserEmployments && this.companyUser.UserEmployments.length > 1) {
      currentEmployment = this.companyUserValue.UserEmployments.find(
        (item: IUserEmployment): boolean => item.Id === this.currentPositionId
      );
    }

    const employmentId: number = this.newEmployment
      ? this.newEmployment.Id
      : currentEmployment
      ? currentEmployment.Id
      : undefined;
    this.newEmployment = undefined;
    this.setcurrentUserEmploymentValue(employmentId);
    if (!employmentId) {
      this.onPositionChangedEvent();
    }
  }

  private setcurrentUserEmploymentValue(value?: number): void {
    setTimeout(() => {
      if (!value) {
        if (!this.positions || this.positions.length === 0) {
          this.currentPositionIdValue = undefined;
          this.currentUserEmployment = undefined;
        } else {
          if (this.settingService && this.settingService.stayEmploymentId) {
            this.currentPositionIdValue = this.settingService.stayEmploymentId;
            this.settingService.stayEmploymentId = undefined;
          } else {
            this.currentPositionIdValue = this.positions[0].Id;
          }
          this.currentUserEmployment = this.companyUserValue.UserEmployments.find(
            (item: IUserEmployment): boolean => item.Id === this.currentPositionIdValue
          );
        }
        return;
      }

      this.currentPositionIdValue = value;
      if (this.companyUserValue.UserEmployments) {
        this.currentUserEmployment = this.companyUserValue.UserEmployments.find(
          (item: IUserEmployment): boolean => item.Id === this.currentPositionIdValue
        );
      } else {
        this.currentUserEmployment = undefined;
      }
    });
  }

  public onPositionChangedEvent(employmentId?: number) {
    if (employmentId) {
      this.employeeService.selectEmployment(employmentId);
      this.positionIdChanged.emit();
    }
  }

  public onPositionTextInputChange(): void {
    this.onChange();
    if (this.isDirty) {
      this.positionTextChanged.emit(this.currentUserEmployment.Title);
    }
  }

  public onAddonClick(): void {
    this.newEmploymentDialogVisible = true;
  }
}
