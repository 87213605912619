<fieldset class="Form--horizontal">
  <legend class="Card-title">{{ 'Company.ExternalIntegrationsConfiguration' | translate }}</legend>
  <div class="clearboth configuration-control" *ngFor="let control of integrationsService.configurationControl">
    <a class="left FormElement-cursorHand">
      <app-icon
        [tooltip]="control.translatemessage"
        [istooltipHTML]="true"
        *ngIf="getStatusMessageColorOperation(control.statuscode) === 'Changed'"
        [icon]="'Warning'"
      >
      </app-icon>
      <app-icon
        [tooltip]="control.translatemessage"
        [istooltipHTML]="true"
        *ngIf="getStatusMessageColorOperation(control.statuscode) === 'Success'"
        [icon]="'Success'"
      >
      </app-icon>
      <app-icon
        [tooltip]="control.translatemessage"
        [istooltipHTML]="true"
        *ngIf="getStatusMessageColorOperation(control.statuscode) === 'Error'"
        [icon]="'ErrorExclamation'"
      >
      </app-icon>
      <app-icon
        [tooltip]="control.translatemessage"
        [istooltipHTML]="true"
        *ngIf="getStatusMessageColorOperation(control.statuscode) === 'Inactive'"
        [icon]="'SuccessGray'"
      >
      </app-icon>
      <app-icon
        [tooltip]="control.translatemessage"
        [istooltipHTML]="true"
        *ngIf="getStatusMessageColorOperation(control.statuscode) === ''"
        [icon]="'SuccessGray'"
      >
      </app-icon>
    </a>
    <app-check-edit
      (valueChange)="isDirtyChange(true)"
      [editMode]="integrationsService.editMode"
      [(value)]="control.value"
      [label]="control.name"
      class="configuration-checkbox"
      [helpTooltip]="control.Description"
    >
    </app-check-edit>
    <app-button
      [ngClass]="{
        'button-action-integration-disabled':
          !control.value || sessionService.isSubmitting || integrationsService.editMode
      }"
      *ngIf="!integrationsService.isReadonlyUser"
      [isDisabled]="!control.value || sessionService.isSubmitting || integrationsService.editMode"
      (clickEvent)="runOperation(control.Id, control)"
      class="right Button--card Button-RunNow"
      [label]="'Company.ExternalIntegrationsRunNow' | translate"
    >
    </app-button>
  </div>
  <div
    class="clearboth integration-control"
    *ngFor="let control of integrationsService.integrationConfigurationControl"
  >
    <app-check-edit
      *ngIf="control.type === 'CheckBox'"
      [(isDirty)]="integrationsService.tabDirty"
      [label]="control.name"
      class="integration-checkbox extraTop"
      [editMode]="integrationsService.editMode"
      [(value)]="control.value"
      [helpTooltip]="control.Description"
    >
    </app-check-edit>

    <app-text-edit
      *ngIf="control.type === 'TextBox'"
      [(isDirty)]="integrationsService.tabDirty"
      [label]="control.name"
      [editMode]="integrationsService.editMode"
      [(value)]="control.value"
      [tooltipContent]="control.Description"
      class="integration-textbox"
    >
    </app-text-edit>

    <app-combobox-edit
      *ngIf="control.type === 'Dropdown'"
      [(isDirty)]="integrationsService.tabDirty"
      [label]="control.name"
      [editMode]="integrationsService.editMode"
      [comboboxDataSource]="control.dataSource"
      [(value)]="control.value"
      [nullValueLabel]="'DropdownList.None' | translate"
      [keepDataSourceOrder]="true"
      [idField]="control.idField"
      [textField]="control.textField"
      [tooltipContent]="control.Description"
    >
    </app-combobox-edit>

    <app-date-picker
      *ngIf="control.type === 'DatePicker'"
      [label]="control.name"
      [editMode]="integrationsService.editMode"
      [(isDirty)]="integrationsService.tabDirty"
      [(value)]="control.value"
      class="integration-datepicker"
      [helpTooltip]="control.Description"
    >
    </app-date-picker>
  </div>
</fieldset>

<app-dialog
  [(visible)]="isShowingAutoMap"
  (action)="onAutoMap($event)"
  [leadingText]="'CompanyGeneral.ReplaceExternalReferenceConfirmationMessage' | translate"
>
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Proceed'"></app-dialog-action>
</app-dialog>

<app-loading-tips [tiptext]="'Integrations.OperationRunWarning' | translate" [visible]="overlayVisible">
</app-loading-tips>

<app-dialog class="blue-dialog" [(visible)]="OperationRunSucess" [leadingText]="'Company.OperationRun' | translate">
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
