import { Component, ViewEncapsulation } from '@angular/core';
import { RouterStateService } from 'src/app/common/router-state.service';
import { SessionService } from 'src/app/services/session/session.service';
import { AccountantSummaryService } from './accountant-summary.service';

@Component({
  selector: 'app-accountant-summary',
  templateUrl: './accountant-summary.component.html',
  styleUrls: ['./accountant-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountantSummaryComponent {
  public leaveDialogVisible = false;

  constructor(
    public service: AccountantSummaryService,
    public sessionService: SessionService,
    private stateService: RouterStateService
  ) {
    // if (!this.sessionService.feature.IsMultiCompanyMember) {
    //   this.stateService.registerRedirect('tabs.accountant.summary', 'tabs.accountant.join');
    // }
  }
}
