import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, filterBy, orderBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterDateTimeGrid } from '../../../common/filter-date-time-grid';
import { FormComponentBase } from '../../../common/form-component-base';
import { HolidayRequest, ICompanyHolidayView } from '../../../services/api/api-model';
import { StaticDataService } from '../../../services/api/static-data.service';
import { SessionService } from '../../../services/session/session.service';
import { CompanyGeneralService } from '../company-general.service';
var CompanyHolidaySettingComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyHolidaySettingComponent, _super);
    function CompanyHolidaySettingComponent(sessionService, companyGeneralService, staticDataService) {
        var _this = _super.call(this) || this;
        _this.sessionService = sessionService;
        _this.companyGeneralService = companyGeneralService;
        _this.staticDataService = staticDataService;
        _this.gridData = filterBy(_this.dataList, _this.dataFilter);
        _this.triggerUpdate = false;
        _this.filterClear = false;
        _this.sortClear = false;
        _this.ngUnsubscribe = new Subject();
        return _this;
    }
    CompanyHolidaySettingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.companyGeneralService.companyHolidayView
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) {
            var flag = 0;
            data.forEach(function (item) {
                item.flag = flag;
                flag += 1;
            });
            data = orderBy(data, _this.kendoGrid.sort);
            _this.dataList = data;
            _this.onGridFilterChange(_this.dataFilter);
            _this.selectedId = null;
            _this.triggerUpdate = true;
        });
    };
    CompanyHolidaySettingComponent.prototype.clearGridAction = function (value) {
        if (!value) {
            this.filterClear = true;
            this.sortClear = true;
        }
    };
    CompanyHolidaySettingComponent.prototype.onGridFilterChange = function (filter) {
        this.dataFilter = filter;
        if (filter && filter.filters && filter.filters.length > 0) {
            var filterDateTimeGrid = new FilterDateTimeGrid();
            var filtersDate = [];
            this.dataFilter = filterDateTimeGrid.convertFilterOperator(filter, filtersDate, 'start', 'end', 'Date');
            this.gridData = filterDateTimeGrid.doFilterDate(this.dataList, filtersDate, 'start', 'end', 'Date');
            this.gridData = filterBy(this.gridData, this.dataFilter);
            if (filtersDate.length > 0) {
                [].push.apply(this.dataFilter.filters, filtersDate);
            }
        }
        else {
            this.gridData = this.dataList;
        }
    };
    CompanyHolidaySettingComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    CompanyHolidaySettingComponent.prototype.onGridHolidaySettingButtonClicked = function (event) {
        if (event === 'AddNew') {
            this.selectedId = null;
            this.triggerUpdate = true;
            this.companyGeneralService.isVisibleAddNewHolidayDialog = true;
        }
    };
    CompanyHolidaySettingComponent.prototype.onDialogCreateOpen = function (value) {
        if (value) {
            this.newHolidayDate = this.staticDataService.getCurrentdate();
            this.isRepeatedForTenYear = false;
            this.newHolidayDescription = '';
        }
    };
    CompanyHolidaySettingComponent.prototype.createNewDialogAction = function (event) {
        if (event === 'Create') {
            var holidayRequest = {
                Date: this.newHolidayDate,
                Description: this.newHolidayDescription,
                YearsToRepeat: this.isRepeatedForTenYear ? 9 : 0
            };
            this.companyGeneralService.createHoliday(holidayRequest, true);
        }
    };
    CompanyHolidaySettingComponent.prototype.onSaveChangeHolidayGrid = function (event) {
        if (event && event.dataItem) {
            var holidayRequest = {
                Date: event.dataItem.Date,
                Description: event.dataItem.Description,
                YearsToRepeat: 0
            };
            if (event.dataItem.ConsiderHoliday) {
                // post
                this.companyGeneralService.createHoliday(holidayRequest, false);
            }
            else {
                // delete
                this.companyGeneralService.deleteHoliday(holidayRequest, event.dataItem.MasterHolidayId);
            }
        }
    };
    CompanyHolidaySettingComponent.prototype.gridDblClick = function (event) {
        event.event.stopImmediatePropagation();
        event.event.preventDefault();
    };
    CompanyHolidaySettingComponent.prototype.dialogDblclick = function () {
        event.stopImmediatePropagation();
        event.preventDefault();
    };
    return CompanyHolidaySettingComponent;
}(FormComponentBase));
export { CompanyHolidaySettingComponent };
