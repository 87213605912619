import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Global } from '../../../../common/global';
import { ISimpleSalaryBatchRecord, ISimpleSalaryStatement } from '../../../../services/api/api-model';
import { SessionService } from '../../../../services/session/session.service';

@Component({
  selector: 'app-salary-batch-payslip-details',
  templateUrl: './salary-batch-payslip-details.component.html',
  styleUrls: ['./salary-batch-payslip-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SalaryBatchPayslipDetailsComponent {
  @Output() public revertBatchCompleted: EventEmitter<void> = new EventEmitter<void>();
  @Input() public selectedSalaryStatement: ISimpleSalaryStatement;

  private salaryStatementDetailValues: ISimpleSalaryBatchRecord[];

  @Input()
  public get salaryStatementDetails(): ISimpleSalaryBatchRecord[] {
    return this.salaryStatementDetailValues;
  }
  public set salaryStatementDetails(value: ISimpleSalaryBatchRecord[]) {
    this.salaryStatementDetailValues = value;
    this.gridData =
      this.salaryStatementDetailValues && this.filter
        ? filterBy(this.salaryStatementDetailValues, this.filter)
        : this.salaryStatementDetailValues;
  }
  @Input() public IsFinalizedBatch: boolean;

  public get IsFinalizedBatchAndHaveData(): boolean {
    if (this.IsFinalizedBatch && this.salaryStatementDetails && this.salaryStatementDetails.length > 0) {
      return true;
    }
    return false;
  }

  public get isPaymentApprover(): boolean {
    return Global.SESSION && Global.SESSION.IsPaymentApprover;
  }

  public revertPayslipDialogVisible = false;

  constructor(public sessionService: SessionService) {}

  public onRevertPayslip(): void {
    this.revertPayslipDialogVisible = true;
  }

  public get revertButtonTooltip(): string {
    if (!this.isPaymentApprover) {
      return 'RevertPayslip.NoPermission';
    }
    return '';
  }

  public filter: CompositeFilterDescriptor;
  public gridData: any[] = filterBy(this.salaryStatementDetails, this.filter);
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridData = filterBy(this.salaryStatementDetails, this.filter);
  }
}
