import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from '../../../../services/api/data.service';
import { StaticDataService } from '../../../../services/api/static-data.service';
import { CompanyService } from '../../../../services/company.service';
import { SessionService } from '../../../../services/session/session.service';
import { SettingService } from '../../../../services/setting.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../services/api/data.service";
import * as i2 from "../../../../services/api/static-data.service";
import * as i3 from "../../../../services/setting.service";
import * as i4 from "../../../../services/session/session.service";
import * as i5 from "@uirouter/core";
import * as i6 from "../../../../services/broadcast.service";
var CompanyTimeEntryTypeService = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyTimeEntryTypeService, _super);
    function CompanyTimeEntryTypeService(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) {
        var _this = _super.call(this, dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) || this;
        _this.dataService = dataService;
        _this.staticDataService = staticDataService;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.transitionService = transitionService;
        _this.broadcaster = broadcaster;
        _this.ngUnsubscribe = new Subject();
        return _this;
    }
    Object.defineProperty(CompanyTimeEntryTypeService.prototype, "isLanguageModuleEnable", {
        get: function () {
            return this.sessionService.feature.hasModuleId(5);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyTimeEntryTypeService.prototype, "salaryTypes", {
        get: function () {
            if (!this.salaryTypesSubject) {
                this.salaryTypesSubject = new BehaviorSubject([]);
            }
            return this.salaryTypesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CompanyTimeEntryTypeService.prototype.loadSalaryTypes = function () {
        var _this = this;
        if (!this.salaryTypesSubject) {
            this.salaryTypesSubject = new BehaviorSubject([]);
        }
        return this.dataService.SalaryTypes_GetSalaryTypeViews().pipe(takeUntil(this.ngUnsubscribe), tap(function (data) {
            _this.salaryTypesSubject.next(data);
        }));
    };
    Object.defineProperty(CompanyTimeEntryTypeService.prototype, "timeEntryType", {
        get: function () {
            if (!this.timeEntryTypeSubject) {
                this.timeEntryTypeSubject = new BehaviorSubject([]);
            }
            return this.timeEntryTypeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CompanyTimeEntryTypeService.prototype.loadTimeEntryType = function () {
        var _this = this;
        if (!this.timeEntryTypeSubject) {
            this.timeEntryTypeSubject = new BehaviorSubject([]);
        }
        this.timeEntryTypeSubject.next([]);
        if (this.isLanguageModuleEnable) {
            return this.dataService.TimeEntryTypes_GetRawTimeEntryTypesTranslated().pipe(takeUntil(this.ngUnsubscribe), tap(function (data) {
                _this.timeEntryTypeSubject.next(_this.createGridData(data));
            }));
        }
        else {
            return this.dataService.TimeEntryTypes_GetRawTimeEntryTypes().pipe(takeUntil(this.ngUnsubscribe), tap(function (data) {
                _this.timeEntryTypeSubject.next(_this.createGridData(data));
            }));
        }
    };
    CompanyTimeEntryTypeService.prototype.createGridData = function (timeEntryTypes) {
        var _this = this;
        var filterTimeEntryTypes = timeEntryTypes.filter(function (type) { return !type.BaseTimeEntryType || type.BaseTimeEntryType.IsActive; });
        var activeTimeEntryTypes = filterTimeEntryTypes.filter(function (type) { return type.IsActive !== false; });
        var inActiveTimeEntryType = filterTimeEntryTypes.filter(function (type) { return type.IsActive === false; });
        var sortedActiveTimeEntryTypes = activeTimeEntryTypes.sort(function (a, b) { return _this.sortCustomTimeEntryTypes(a, b); });
        var sortedInActiveTimeEntryTypes = inActiveTimeEntryType.sort(function (a, b) { return _this.sortCustomTimeEntryTypes(a, b); });
        return sortedActiveTimeEntryTypes.concat(sortedInActiveTimeEntryTypes);
    };
    CompanyTimeEntryTypeService.prototype.sortCustomTimeEntryTypes = function (a, b) {
        var aOrder = a.SortOrder || a.SortOrder === 0
            ? a.SortOrder
            : a.BaseTimeEntryType && (a.BaseTimeEntryType.SortOrder || a.BaseTimeEntryType.SortOrder === 0)
                ? a.BaseTimeEntryType.SortOrder
                : undefined;
        var bOrder = b.SortOrder || b.SortOrder === 0
            ? b.SortOrder
            : b.BaseTimeEntryType && (b.BaseTimeEntryType.SortOrder || b.BaseTimeEntryType.SortOrder === 0)
                ? b.BaseTimeEntryType.SortOrder
                : undefined;
        if ((aOrder === undefined && bOrder === undefined) || (aOrder === 0 && bOrder === 0)) {
            return a.Id - b.Id;
        }
        else if (aOrder === undefined && bOrder !== undefined) {
            return -1;
        }
        else if (aOrder !== undefined && bOrder === undefined) {
            return 1;
        }
        return aOrder - bOrder;
    };
    CompanyTimeEntryTypeService.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    CompanyTimeEntryTypeService.prototype.serviceDestroy = function () {
        this.clearArrayDataState(this.timeEntryTypeSubject);
        this.clearArrayDataState(this.salaryTypesSubject);
    };
    CompanyTimeEntryTypeService.prototype.clearArrayDataState = function (subject) {
        if (subject) {
            subject.next([]);
        }
    };
    Object.defineProperty(CompanyTimeEntryTypeService.prototype, "allowShortcut", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.configuration.advancedtypes.timeentrytypes';
        },
        enumerable: true,
        configurable: true
    });
    CompanyTimeEntryTypeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyTimeEntryTypeService_Factory() { return new CompanyTimeEntryTypeService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.StaticDataService), i0.ɵɵinject(i3.SettingService), i0.ɵɵinject(i4.SessionService), i0.ɵɵinject(i5.TransitionService), i0.ɵɵinject(i6.BroadcastService)); }, token: CompanyTimeEntryTypeService, providedIn: "root" });
    return CompanyTimeEntryTypeService;
}(CompanyService));
export { CompanyTimeEntryTypeService };
