<app-dialog
  [title]="'Accountant.Leave' | translate"
  [(visible)]="visible"
  (action)="dialogAction($event)"
  [overlayClose]="true"
>
  <div [innerHtml]="leaveMessage"></div>
  <app-dialog-action [type]="'Cancel'" [disabled]="busy"></app-dialog-action>
  <app-dialog-action
    [action]="'Leave'"
    [label]="'Accountant.Leave' | translate"
    [close]="false"
    [disabled]="busy"
    [class]="'Button--destructive'"
  ></app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" [visible]="redirectDialogVisible" (action)="redirectToLogin()">
  {{ 'Accountant.RedirectToLogin' | translate }}
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
