import { OnDestroy, OnInit } from '@angular/core';
import { distinct, filterBy } from '@progress/kendo-data-query';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FilterDateTimeGrid } from '../../common/filter-date-time-grid';
import { Global } from '../../common/global';
import { Company } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import { ReportDialogService } from '../../shared-components/report-dialog/report-dialog.service';
import { AccountantCompaniesService } from './accountant-companies.service';
var AccountantCompaniesComponent = /** @class */ (function () {
    function AccountantCompaniesComponent(service, staticData, api, staticDataService, sessionService, dataService, transitionService, settingService, reportDialogService) {
        var _this = this;
        this.service = service;
        this.staticData = staticData;
        this.api = api;
        this.staticDataService = staticDataService;
        this.sessionService = sessionService;
        this.dataService = dataService;
        this.transitionService = transitionService;
        this.settingService = settingService;
        this.reportDialogService = reportDialogService;
        this.discardDialogVisible = false;
        this.addNewCompanyDialogVisible = false;
        this.createSuccessConfirm = false;
        this.removeSuccessConfirm = false;
        this.dataRoleList = [];
        this.dataList = [];
        this.accountName = '';
        this.attachexistingcompanyVisable = false;
        this.removeCompanyDialogVisible = false;
        this.isUnderStoodTheAbove = false;
        this.isCompleteAttached = false;
        this.isFirstLoad = false;
        this.AccountInvoiceTypes = [];
        this.triggerUpdate = false;
        this.addNewUserVisible = false;
        this.confimTermVisible = false;
        this.pageSize = 50;
        this.listClassExcluded = ['Footer', '10'];
        this.currentPageAttachCompany = 0;
        this.ngUnsubscribe = new Subject();
        this.filterAttachCompanySubject = new BehaviorSubject('');
        this.gridRoleFilterData = this.dataRoleList;
        this.gridFilterData = this.dataList;
        this.service.readloadUserConpanyRolChange.subscribe(function (value) {
            if (value) {
                _this.staticDataService.loadCompaniesSimpleAsync();
                _this.initFilterData();
            }
        });
        this.filterAttachCompanySubject
            .asObservable()
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(function (value) { return _this.applyFilterAttachCompany(value); });
        this.loadRole();
        this.initFilterData();
        this.staticData.companiesSimple.subscribe(function (data) {
            if (data && data.length > 0) {
                _this.userCompanyUserViews = data.filter(function (model) {
                    return model.RoleId === 90 &&
                        (!model.AccountId || (model.AccountId && model.AccountId !== Global.SESSION.SignOnToken.UserAccountId));
                });
                _this.userCompanyUserViewsFilter = filterBy(_this.userCompanyUserViews, _this.attackCompanyFilter);
            }
        });
    }
    Object.defineProperty(AccountantCompaniesComponent.prototype, "isPagingAvailable", {
        get: function () {
            if (this.gridFilterData && this.gridFilterData.length > this.pageSize) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantCompaniesComponent.prototype, "isPagingUserAvailable", {
        get: function () {
            if (this.gridRoleFilterData && this.gridRoleFilterData.length > this.pageSize) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantCompaniesComponent.prototype, "isIOSApp", {
        get: function () {
            return this.sessionService.browser.isHybridApp && this.sessionService.browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantCompaniesComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantCompaniesComponent.prototype, "isAccountantAdmin", {
        get: function () {
            return this.sessionService.role.IsMultiCompanyAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantCompaniesComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantCompaniesComponent.prototype, "isRemoveCompany", {
        get: function () {
            return (this.sessionService.role.IsMultiCompanyAdmin &&
                this.service.selectedId !== null &&
                this.service.selectedId !== undefined);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantCompaniesComponent.prototype, "isShowComment", {
        get: function () {
            if ((this.service.isBasicUser && !this.service.company.AccountComment) ||
                (this.service.isBasicUser && Object.keys(this.service.company).length === 0)) {
                return false;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantCompaniesComponent.prototype, "activeTab", {
        get: function () {
            return this.sessionService.currentState;
        },
        enumerable: true,
        configurable: true
    });
    AccountantCompaniesComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(AccountantCompaniesComponent.prototype, "filterAttachCompany", {
        get: function () {
            return this.filterAttachCompanyValue;
        },
        set: function (value) {
            this.filterAttachCompanyValue = value;
            this.filterAttachCompanySubject.next(value);
        },
        enumerable: true,
        configurable: true
    });
    AccountantCompaniesComponent.prototype.applyFilterAttachCompany = function (val) {
        if (val) {
            this.userCompanyUserViewsFilter = this.userCompanyUserViews.filter(function (item) {
                return ((item.Id + '').includes(val) ||
                    item.Name.includes(val) ||
                    (item.VatRegistrationNumber + '').includes(val) ||
                    (item.SecondaryVatRegistrationNumber + '').includes(val));
            });
        }
        else {
            this.userCompanyUserViewsFilter = this.userCompanyUserViews;
        }
        this.currentPageAttachCompany = 0;
        this.selectUserCompanyUserViews = null;
    };
    AccountantCompaniesComponent.prototype.loadRole = function () {
        var _this = this;
        this.service.roles.subscribe(function (data) {
            _this.dataRoleList = data;
            _this.dataRoleList.sort(function (a, b) { return a.UserId - b.UserId; });
            _this.gridRoleFilterData = filterBy(_this.dataRoleList, _this.roleFilter);
        });
    };
    AccountantCompaniesComponent.prototype.initFilterData = function () {
        var _this = this;
        this.service.companies.subscribe(function (data) {
            _this.staticDataService.companiesSimple.subscribe(function (myCompany) {
                _this.staticDataService.Role.subscribe(function (statisticOptions) {
                    var roleList = {};
                    statisticOptions.forEach(function (option) {
                        roleList[option.Id] = option.Name;
                    });
                    myCompany.forEach(function (company) {
                        company.UserRoleName = roleList[company.RoleId];
                    });
                    var list = data.map(function (val) { return ({
                        Name: val.CountryCode,
                        Id: val.CountryId,
                        Key: val.CountryCode
                    }); });
                    _this.countryCodes = distinct(list, 'Name');
                    _this.dataList = data;
                    _this.dataList.forEach(function (model) {
                        var companyModel = myCompany.find(function (item) {
                            return item.Id === model.Id;
                        });
                        if (companyModel) {
                            model.UserRoleName = companyModel.UserRoleName;
                            model.BlockingNavigate = false;
                            model.UserRoleId = companyModel.RoleId;
                            model.BlockingNavigateTooltip = '';
                        }
                        else {
                            model.UserRoleName = '';
                            model.BlockingNavigate = true;
                            model.UserRoleId = null;
                            model.BlockingNavigateTooltip = 'Accountant.BlockingNavigate';
                        }
                    });
                    _this.triggerUpdate = true;
                    if (_this.filter && _this.filter.filters && _this.filter.filters.length > 0) {
                        var filterDateTimeGrid = new FilterDateTimeGrid();
                        var filtersDate = [];
                        // Using FilterDateTimeGrid to filter date before filter another field
                        _this.filter = filterDateTimeGrid.convertFilterOperator(_this.filter, filtersDate, 'LastFinalized', 'NextDeadline');
                        _this.gridFilterData = filterDateTimeGrid.doFilterDate(_this.dataList, filtersDate, 'LastFinalized', 'NextDeadline');
                        _this.gridFilterData = filterBy(_this.gridFilterData, _this.filter);
                        if (filtersDate.length > 0) {
                            [].push.apply(_this.filter.filters, filtersDate);
                        }
                    }
                    else {
                        _this.gridFilterData = _this.dataList;
                    }
                });
            });
        });
    };
    AccountantCompaniesComponent.prototype.onDirtyChange = function () {
        this.service.isDirty = true;
    };
    AccountantCompaniesComponent.prototype.onRowDataChange = function (event) {
        var model = {
            RoleId: event.dataItem.RoleId,
            UserId: event.dataItem.UserId,
            isPaymentApprover: event.dataItem.IsPaymentApprover || false,
            CompanyId: this.service.selectedId
        };
        if (!this.service.EditRoleList.find(function (item) { return item.UserId === model.UserId; })) {
            this.service.EditRoleList.push(model);
        }
        else {
            Object.assign(this.service.EditRoleList.find(function (item) { return item.UserId === model.UserId; }), model);
        }
    };
    AccountantCompaniesComponent.prototype.onCompanyRowSave = function (event) {
        // Update only InvoiceTypeId with patch method
        var dataModel = new Company();
        dataModel.Id = event.dataItem.Id;
        dataModel.InvoiceTypeId = event.dataItem.InvoiceTypeId;
        dataModel.InvoiceEmail = event.dataItem.InvoiceEmail;
        if (!this.service.EditCompanyList.find(function (item) { return item.Id === dataModel.Id; })) {
            this.service.EditCompanyList.push(dataModel);
        }
        else {
            Object.assign(this.service.EditCompanyList.find(function (item) { return item.Id === dataModel.Id; }), dataModel);
        }
    };
    AccountantCompaniesComponent.prototype.saveCompanyRows = function () {
        var _this = this;
        this.service.EditCompanyList.forEach(function (item) {
            _this.dataService.Companies_PatchCompany(item).subscribe(function (model) {
                Object.assign(item, model);
            }, function () {
                _this.service.loadData(false);
            });
        });
    };
    AccountantCompaniesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isFirstLoad = true;
        this.staticData.accountInvoiceTypes.pipe().subscribe(function (InvoiceType) {
            if (InvoiceType && InvoiceType.length > 0) {
                _this.AccountInvoiceTypes = InvoiceType;
                _this.triggerUpdate = true;
            }
        });
        this.staticData.currentAccount.subscribe(function (account) {
            _this.accountName = account.Name;
        });
        this.transitionService.onStart({}, function (transition) {
            var fromState = transition.from().name;
            var toState = transition.to().name;
            _this.service.navigationToStateName = JSON.parse(JSON.stringify(toState));
            if (_this.service.editMode && fromState !== toState) {
                if ((_this.service.EditCompanyList && _this.service.EditCompanyList.length > 0) ||
                    (_this.service.EditRoleList && _this.service.EditRoleList.length > 0) ||
                    _this.service.isDirty) {
                    _this.service.showSaveDataConfirmationChangeState = true;
                }
                else {
                    _this.onEditModeChange(false);
                    _this.sessionService.NavigateTo(_this.service.navigationToStateName);
                    return true;
                }
                return false;
            }
            _this.service.clearCompanySubject();
            return true;
        });
        this.staticData.Role.pipe().subscribe(function (role) {
            if (role && role.length > 0) {
                _this.BaseSalaryAdminIdOption = role.find(function (model) { return model.Key === 'SalaryAdmin'; }).Id;
                _this.BaseFullAdminIdOption = role.find(function (model) { return model.Key === 'FullAdmin'; }).Id;
            }
        });
    };
    AccountantCompaniesComponent.prototype.onConfirmSaveDataOnChangeState = function (action) {
        if (action === 'ContinueAndSave') {
            this.onEditModeChange(false);
            this.service.isDirty = false;
            this.sessionService.NavigateTo(this.service.navigationToStateName);
            return;
        }
        if (action === 'DiscardAndLeave') {
            this.service.discard();
            this.service.EditRoleList = [];
            this.service.EditCompanyList = [];
            this.service.isDirty = false;
            this.onEditModeChange(false);
            this.sessionService.NavigateTo(this.service.navigationToStateName);
            return;
        }
    };
    AccountantCompaniesComponent.prototype.changeCompany = function (companyId, companyuserrole) {
        var _this = this;
        this.settingService.ReloadWarrning = true;
        this.api.Auth_SetCompanyContext(companyId).subscribe(function () {
            Global.COMPANY_ID = companyId;
            _this.staticDataService.loadStaticData().then(function () {
                _this.api.Companies_GetCurrent().subscribe(function (activeCompany) {
                    Global.COMPANY = activeCompany;
                    if (companyuserrole && companyuserrole >= 30) {
                        _this.sessionService.NavigateTo('tabs.company.salarybatches');
                        setTimeout(function () {
                            window.location.reload();
                        });
                    }
                    else {
                        window.location.reload();
                    }
                });
            });
        }, function () {
            _this.settingService.ReloadWarrning = false;
        });
    };
    AccountantCompaniesComponent.prototype.clickAppGridIcon = function (model) {
        if (!model.BlockingNavigate) {
            this.changeCompany(model.Id, model.UserRoleId);
            // if (model.UserRoleId && model.UserRoleId >= 30) {
            //   this.sessionService.NavigateTo('tabs.company.salarybatches');
            // }
        }
    };
    AccountantCompaniesComponent.prototype.onMoreMenuOptionClick = function (menu) {
        if (menu && menu === 'accountant_attachexistingcompany') {
            this.attachexistingcompanyVisable = true;
        }
        if (menu && menu === 'accountant_removecompany') {
            this.removeCompanyDialogVisible = true;
        }
    };
    AccountantCompaniesComponent.prototype.onEditModeChange = function ($event) {
        if (this.service.isBasicUser) {
            return;
        }
        if ($event && (this.sessionService.isGetting || this.sessionService.isSubmitting)) {
            return;
        }
        if (!this.service.selectedId) {
            if (this.gridFilterData && this.gridFilterData.length > 0) {
                this.service.selectedId = this.gridFilterData[0].Id;
                this.onSelectedIdChange(this.gridFilterData[0].Id);
            }
            else {
                return;
            }
        }
        if (this.service.editMode) {
            if ((this.service.EditRoleList && this.service.EditRoleList.length > 0) || this.service.isDirty) {
                this.service.save(this.service.EditRoleList);
                this.service.isDirty = false;
                this.service.EditRoleList = [];
            }
            if (this.service.EditCompanyList && this.service.EditCompanyList.length > 0) {
                this.saveCompanyRows();
                this.service.EditCompanyList = [];
            }
        }
        this.service.editMode = $event;
    };
    AccountantCompaniesComponent.prototype.tabDblclick = function () {
        if (this.service.editMode) {
            return;
        }
        this.onEditModeChange(true);
    };
    AccountantCompaniesComponent.prototype.gridDblclick = function ($event) {
        this.onEditModeChange(true);
    };
    AccountantCompaniesComponent.prototype.confirmAndDiscardChanges = function () {
        if ((this.service.EditRoleList && this.service.EditRoleList.length > 0) || this.service.isDirty) {
            this.discardDialogVisible = true;
            return;
        }
        this.onEditModeChange(false);
    };
    AccountantCompaniesComponent.prototype.discardDialogAction = function (action) {
        if (action === 'ConfirmDiscard') {
            this.service.discard();
            this.service.EditRoleList = [];
            this.service.EditCompanyList = [];
            this.service.isDirty = false;
            this.onEditModeChange(false);
        }
    };
    AccountantCompaniesComponent.prototype.onRoleFilterChange = function (filter) {
        this.roleFilter = filter;
        this.gridRoleFilterData = filterBy(this.dataRoleList, filter);
    };
    AccountantCompaniesComponent.prototype.onAttackCompanyFilterChange = function (filter) {
        this.selectUserCompanyUserViews = null;
        this.attackCompanyFilter = filter;
        this.userCompanyUserViewsFilter = filterBy(this.userCompanyUserViews, filter);
    };
    AccountantCompaniesComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        if (filter && filter.filters && filter.filters.length > 0) {
            var filterDateTimeGrid = new FilterDateTimeGrid();
            var filtersDate = [];
            // Using FilterDateTimeGrid to filter date before filter another field
            this.filter = filterDateTimeGrid.convertFilterOperator(this.filter, filtersDate, 'LastFinalized', 'NextDeadline');
            this.gridFilterData = filterDateTimeGrid.doFilterDate(this.dataList, filtersDate, 'LastFinalized', 'NextDeadline');
            this.gridFilterData = filterBy(this.gridFilterData, this.filter);
            if (filtersDate.length > 0) {
                [].push.apply(this.filter.filters, filtersDate);
            }
        }
        else {
            this.gridFilterData = this.dataList;
        }
    };
    AccountantCompaniesComponent.prototype.onSelectedIdChange = function (selectedId) {
        if (this.service.editMode &&
            ((this.service.EditRoleList && this.service.EditRoleList.length > 0) || this.service.isDirty)) {
            this.service.save(this.service.EditRoleList);
            this.service.isDirty = false;
            this.service.EditRoleList = [];
        }
        this.service.getRoleServicePublic();
        if (!this.isFirstLoad) {
            this.gridRoleFilterData = [];
            this.service.EditRoleList = [];
            this.service.isDirty = false;
        }
        this.isFirstLoad = false;
    };
    AccountantCompaniesComponent.prototype.onCreateSuccess = function (event) {
        if (event) {
            this.newCompanyId = event;
            this.createSuccessConfirm = true;
        }
    };
    AccountantCompaniesComponent.prototype.onDoneCreateSuccess = function (event) {
        this.settingService.ReloadWarrning = true;
        if (event) {
            if (event === 'Yes') {
                this.changeCompany(this.newCompanyId);
            }
            else {
                window.location.reload();
            }
        }
    };
    AccountantCompaniesComponent.prototype.onDoneRemoveSuccess = function (event) {
        this.settingService.ReloadWarrning = true;
        if (event) {
            if (event === 'Ok') {
                window.location.reload();
            }
        }
    };
    AccountantCompaniesComponent.prototype.onAttachingAnExistingCompany = function (event) {
        var _this = this;
        if (event) {
            if (event === 'Submit') {
                if (!this.isUnderStoodTheAbove) {
                    this.confimTermVisible = true;
                    return;
                }
                this.api.Account_AttachCompany(this.selectUserCompanyUserViews).subscribe(function () {
                    _this.isCompleteAttached = true;
                    _this.resetAttackCompanyDialog();
                });
            }
            if (event === 'close') {
                this.resetAttackCompanyDialog();
            }
        }
    };
    AccountantCompaniesComponent.prototype.resetAttackCompanyDialog = function () {
        this.isUnderStoodTheAbove = false;
        this.selectUserCompanyUserViews = null;
        this.filterAttachCompany = '';
    };
    AccountantCompaniesComponent.prototype.onRemoveCompany = function (event) {
        var _this = this;
        if (event && event === 'Delete') {
            this.dataService.Account_RemoveCompany(this.service.selectedId).subscribe(function () {
                _this.removeSuccessConfirm = true;
            });
        }
    };
    AccountantCompaniesComponent.prototype.ReplayMoreMenuLabelAttachingAnExistingCompany = function (message) {
        return message.replace(/{AccountName}/g, this.accountName);
    };
    AccountantCompaniesComponent.prototype.onUserRoleSelected = function (event) {
        // if (this.service.editMode && event) {
        //   const originApprover: boolean = JSON.parse(JSON.stringify(event.IsPaymentApprover));
        //   if (event.RoleId === this.BaseFullAdminIdOption || event.RoleId === this.BaseSalaryAdminIdOption) {
        //     event.IsPaymentApprover = true;
        //   } else {
        //     event.IsPaymentApprover = false;
        //   }
        //   if (originApprover !== event.IsPaymentApprover) {
        //     const onChangeData: any = {};
        //     onChangeData.dataItem = event;
        //     this.onRowDataChange(onChangeData);
        //   }
        // }
    };
    AccountantCompaniesComponent.prototype.onGridRoleControlDataChange = function (event) {
        if (event && event.field === 'RoleId') {
            if (event.dataItem.RoleId === this.BaseFullAdminIdOption ||
                event.dataItem.RoleId === this.BaseSalaryAdminIdOption) {
                event.dataItem.IsPaymentApprover = true;
            }
            else {
                event.dataItem.IsPaymentApprover = false;
            }
        }
        this.onRowDataChange(event);
    };
    return AccountantCompaniesComponent;
}());
export { AccountantCompaniesComponent };
