<form>
  <fieldset class="Form--horizontal">
    <legend class="Card-title">{{ 'EmployeeGeneral.ContactInfo' | translate }}</legend>
    <legend class="FormElement-legend FormElement-label u-hiddenVisually">
      {{ 'EmployeeGeneral.Address' | translate }}
    </legend>

    <app-text-edit
      [label]="'EmployeeGeneral.AddressLine1' | translate"
      [editMode]="editMode"
      [(value)]="companyUser.Address.Line1"
      [(isValid)]="childIsValid[0]"
      [(isDirty)]="childIsDirty[0]"
      (valueChange)="onChange()"
    ></app-text-edit>

    <app-text-edit
      [label]="'EmployeeGeneral.AddressLine2' | translate"
      [placeholder]="'EmployeeGeneral.Optional' | translate"
      [editMode]="editMode"
      [(value)]="companyUser.Address.Line2"
      [(isValid)]="childIsValid[1]"
      [(isDirty)]="childIsDirty[1]"
      (valueChange)="onChange()"
    ></app-text-edit>

    <app-text-edit
      [label]="'EmployeeGeneral.PostalCode' | translate"
      [editMode]="editMode"
      [(value)]="companyUser.Address.PostalCode"
      [(isValid)]="childIsValid[2]"
      [(isDirty)]="childIsDirty[2]"
      (blur)="onPostalCodeChanged()"
      (valueChange)="onChange()"
    ></app-text-edit>

    <app-text-edit
      [label]="'CompanyGeneral.City' | translate"
      [editMode]="editMode"
      [(value)]="companyUser.Address.City"
      [(isValid)]="childIsValid[3]"
      [(isDirty)]="childIsDirty[2]"
      (valueChange)="onChange()"
    ></app-text-edit>

    <app-combobox-edit
      *ngIf="IsGreenlandCompanyAnduserEmloyment"
      [label]="'NewEmployee.Municipality' | translate"
      [editMode]="editMode"
      [textField]="'Name'"
      [idField]="'Id'"
      [(isValid)]="childIsValid[4]"
      [(isDirty)]="childIsDirty[4]"
      [comboboxDataSource]="municipality"
      [(value)]="companyUser.MunicipalityId"
      (valueChange)="onComboboxControlChanged()"
    ></app-combobox-edit>

    <app-combobox-edit
      [label]="'NewEmployee.Country' | translate"
      [editMode]="editMode"
      [textField]="'Name'"
      [idField]="'Id'"
      [(isValid)]="childIsValid[5]"
      [(isDirty)]="childIsDirty[5]"
      [comboboxDataSource]="staticDataService.Country | async"
      [(value)]="companyUser.Address.CountryId"
      (valueChange)="onComboboxControlChanged()"
    ></app-combobox-edit>

    <app-text-edit
      [label]="'EmployeeGeneral.TelMobile' | translate"
      [editMode]="editMode"
      [type]="'number'"
      [(value)]="companyUser.TelMobile"
      [(isValid)]="childIsValid[6]"
      [(isDirty)]="childIsDirty[6]"
      (valueChange)="onChange()"
    ></app-text-edit>

    <app-text-edit
      [label]="'EmployeeGeneral.TelFixed' | translate"
      [editMode]="editMode"
      [type]="'number'"
      [(value)]="companyUser.TelFixed"
      [(isValid)]="childIsValid[7]"
      [(isDirty)]="childIsDirty[7]"
      (valueChange)="onChange()"
    ></app-text-edit>

    <app-text-edit
      [label]="'EmployeeGeneral.Email' | translate"
      [type]="'email'"
      [editMode]="editMode"
      [(value)]="companyUser.CompanyEmail"
      [(isValid)]="childIsValid[8]"
      [(isDirty)]="childIsDirty[8]"
      (valueChange)="onChange()"
    ></app-text-edit>

    <app-text-edit
      [label]="'EmployeeGeneral.EmailPrivate' | translate"
      [type]="'email'"
      [editMode]="editMode"
      [(value)]="companyUser.PersonalEmail"
      [(isValid)]="childIsValid[9]"
      [(isDirty)]="childIsDirty[9]"
      (valueChange)="onChange()"
    ></app-text-edit>
  </fieldset>
</form>
