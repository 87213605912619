import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Constants } from '../common/constants';
import { Utf8ArrayToStr } from '../common/utf8';
import { DataService } from './api/data.service';
import { BroadcastService } from './broadcast.service';
import { ModalService } from './modal.service';
import { SessionService } from './session/session.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private modalService: ModalService,
    private broadcaster: BroadcastService,
    private sessionService: SessionService,
    private dataService: DataService
  ) {}

  public get branding() {
    return environment.branding;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: any, caught: Observable<any>) => {
        if (err instanceof HttpErrorResponse) {
          const error: HttpErrorResponse = err;
          switch (error.status) {
            case 0:
            case -1:
              const networkError: string = this.sessionService.modalTranslations['Error.NetworkError'].replace(
                '{{SystemAlias}}',
                this.branding.SystemAlias
              );
              const localLanguageCode: string = localStorage.getItem('localLanguageCode');
              let networkErrorMessage =
                this.branding.SystemAlias +
                ' har mistet forbindelsen til serveren. Dette kan være et midlertidigt problem, især hvis du bruger wifi eller mobilt internet. Tjek din internetforbindelse og prøv igen, eller kontakt os hvis problemet fortsætter.';
              if (localLanguageCode === 'en') {
                networkErrorMessage =
                  this.branding.SystemAlias +
                  " could not connect to the server. This can happen intermittently, especially if you're using wifi or mobile data. Check your internet connection and try again, or contact us if the issue persists.";
              }
              this.modalService.alert('', networkError ? networkError : networkErrorMessage, true);
              break;
            case 400:
              if (error.error.Message) {
                this.modalService.alert('', error.error.Message, true);
              } else {
                let decodedString: string;
                try {
                  const array: Uint8Array = new Uint8Array(error.error);
                  decodedString = Utf8ArrayToStr(array);
                  const obj: any = JSON.parse(decodedString);
                  if (obj && obj.Message) {
                    this.modalService.alert('', obj.Message, true);
                  }
                } catch (e) {
                  if (decodedString) {
                    this.modalService.alert('', decodedString, true);
                  }
                }
              }

              break;
            case 401:
              const urlResponse = error.url;
              if (urlResponse.includes('session/isalive')) {
                this.broadcaster.broadcast(Constants.SESSION_EXPIRED);
              } else {
                if (!this.sessionService.currentState.includes('login') && !this.sessionService.checkIsAliveError) {
                  this.modalService.alert('', this.sessionService.errorMessage401, true, false, 401);
                  let errormessage: string = error.statusText;
                  if (urlResponse) {
                    errormessage += ' - ' + urlResponse;
                  }

                  this.dataService.Auth_IsSessionAlive().subscribe(
                    () => {
                      Sentry.withScope((scope) => {
                        scope.setExtra('extra', error);
                        Sentry.captureException(new Error(errormessage));
                      });
                    },
                    () => {
                      this.sessionService.checkIsAliveError = true;
                    }
                  );
                }
              }
              break;
            case 404:
              this.modalService.alert('', this.sessionService.errorMessage404, true);
              break;
            default:
              if (this.sessionService.checkIsAliveActive === true && error.status === 500) {
                this.sessionService.checkIsAliveActive = false;
                break;
              }
              let message: string = error.statusText;
              if (error.url) {
                message += ' - ' + error.url;
              }
              Sentry.withScope((scope) => {
                scope.setExtra('extra', error);
                Sentry.captureException(new Error(message));
              });
              break;
          }
        }

        ////return of([]);
        return throwError(err);
      })
    );
  }
}
