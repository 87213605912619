<app-dialog (action)="onDialogAction($event)" [overlayClose]="false" [(visible)]="visible">
  <div class="FormElement-row">
    <div>
      <p>{{ 'Finalize.Step3Explanatory' | translate }}</p>
    </div>
  </div>

  <div class="FormElement-row">
    <div>
      <p>{{ 'Finalize.Step3ExplanatoryNote' | translate }}</p>
    </div>
  </div>

  <div class="FormElement-row">
    <div>
      <label class="FormElement-checkbox">
        <input
          type="checkbox"
          id="checkboxRecalculateBeforeFinalizing"
          class="k-checkbox"
          [(ngModel)]="recalculateBeforeFinalizing"
        />
        <span class="FormElement-checkboxFaux"></span>
        <span for="checkboxRecalculateBeforeFinalizing">
          {{ 'Finalize.RecalculateCheckBox' | translate }}
        </span>
      </label>
    </div>
  </div>

  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Proceed'"></app-dialog-action>
</app-dialog>
