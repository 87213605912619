/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./credential-integrations.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../custom-controls/text-edit/text-edit.component.ngfactory";
import * as i3 from "../../../../../custom-controls/text-edit/text-edit.component";
import * as i4 from "../../../../../services/session/session.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/common";
import * as i7 from "./credential-integrations.component";
import * as i8 from "../../integrations.service";
var styles_CredentialIntegrationsComponent = [i0.styles];
var RenderType_CredentialIntegrationsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CredentialIntegrationsComponent, data: {} });
export { RenderType_CredentialIntegrationsComponent as RenderType_CredentialIntegrationsComponent };
function View_CredentialIntegrationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-text-edit", [], null, [[null, "isDirtyChange"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isDirtyChange" === en)) {
        var pd_0 = ((_co.integrationsService.tabDirty = $event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_v.context.$implicit.value = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_TextEditComponent_0, i2.RenderType_TextEditComponent)), i1.ɵdid(2, 114688, null, 0, i3.TextEditComponent, [i4.SessionService, i1.ElementRef, i1.ChangeDetectorRef], { value: [0, "value"], editMode: [1, "editMode"], isDirty: [2, "isDirty"], label: [3, "label"], tooltipContent: [4, "tooltipContent"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.value; var currVal_1 = _co.integrationsService.editMode; var currVal_2 = _co.integrationsService.tabDirty; var currVal_3 = _v.context.$implicit.name; var currVal_4 = _v.context.$implicit.Description; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_CredentialIntegrationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "fieldset", [["class", "Form--horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "legend", [["class", "Card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CredentialIntegrationsComponent_1)), i1.ɵdid(5, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.integrationsService.credentialControl; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Company.ExternalIntegrationsCredential")); _ck(_v, 2, 0, currVal_0); }); }
export function View_CredentialIntegrationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-credential-integrations", [], null, null, null, View_CredentialIntegrationsComponent_0, RenderType_CredentialIntegrationsComponent)), i1.ɵdid(1, 49152, null, 0, i7.CredentialIntegrationsComponent, [i8.IntegrationsService], null, null)], null, null); }
var CredentialIntegrationsComponentNgFactory = i1.ɵccf("app-credential-integrations", i7.CredentialIntegrationsComponent, View_CredentialIntegrationsComponent_Host_0, {}, {}, []);
export { CredentialIntegrationsComponentNgFactory as CredentialIntegrationsComponentNgFactory };
