<form>
  <fieldset class="Form--horizontal">
    <legend class="Card-title">{{ 'EmployeeEmployment.Pension' | translate }}</legend>

    <div class="FormElement-static" *ngIf="!isNoPensionTextHidden">
      {{ 'EmployeeEmployment.NoPension' | translate }}
    </div>
    <div *ngIf="showPensionProvider">
      <app-combobox-edit
        [(isDirty)]="childIsDirty[0]"
        [editMode]="editMode"
        [label]="'EmployeeEmployment.PensionProvider' | translate"
        [comboboxDataSource]="pensionProviders"
        [nullValueLabel]="'DropdownList.None' | translate"
        [disable]="disable"
        [(value)]="pensionProviderId"
        [textField]="'Name'"
        [idField]="'Id'"
        (valueChange)="onChange()"
      ></app-combobox-edit>
      <div class="FormElement">
        <span class="FormElement-label">{{ 'EmployeeEmployment.NetsNo' | translate }}:</span>
        <div class="FormElement-control">
          <div class="FormElement-static">{{ PensionProviderNetsNo }}</div>
        </div>
      </div>
      <div *ngIf="isShowBankInfo">
        <app-text-edit
          [(isDirty)]="childIsDirty[1]"
          [(isValid)]="childIsValid[0]"
          [label]="'EmployeeEmployment.RegNo' | translate"
          [editMode]="editMode"
          [disabled]="disable"
          [(value)]="PensionProviderBankRegNo"
          (valueChange)="onChange()"
        ></app-text-edit>
        <app-text-edit
          [(isDirty)]="childIsDirty[2]"
          [(isValid)]="childIsValid[1]"
          [label]="'EmployeeEmployment.AccountNo' | translate"
          [editMode]="editMode"
          [disabled]="disable"
          [(value)]="PensionProviderBankAccountNo"
          (valueChange)="onChange()"
        ></app-text-edit>
      </div>
      <app-combobox-edit
        [(isDirty)]="childIsDirty[3]"
        [editMode]="editMode"
        [label]="'EmployeeEmployment.BasedOn' | translate"
        [disable]="!SelectedPensionProvider || disable"
        [comboboxDataSource]="pensionData"
        [(value)]="PensionBaseCalculationMethodId"
        [textField]="'Name'"
        [idField]="'Id'"
        (valueChange)="onChange()"
      >
      </app-combobox-edit>

      <app-share-percentage
        [(isDirty)]="childIsDirty[4]"
        [label]="'EmployeeEmployment.PercentageOwnCompany' | translate"
        [(firstValue)]="PensionEmployeeShare"
        [(secondValue)]="PensionCompanyShare"
        [editMode]="editMode"
        [disable]="!SelectedPensionProvider || disable"
        (valueChange)="onChange()"
      ></app-share-percentage>

      <app-share-amount
        [(isDirty)]="childIsDirty[5]"
        [label]="'EmployeeEmployment.FixedAmountOwnCompany' | translate"
        [tooltip]="
          'EmployeeEmployment.ExplainFixedPensionAmountPerWorkday' | translate: { SystemAlias: branding.SystemAlias }
        "
        [(firstValue)]="PensionEmployeeAmount"
        [(secondValue)]="PensionCompanyAmount"
        [(thirdValue)]="PensionFixedAmountIsPerDay"
        [editMode]="editMode"
        [disable]="!SelectedPensionProvider || disable"
        (valueChange)="onChange()"
      ></app-share-amount>

      <!-- <app-module-check [moduleId]="8">
        <app-radio-edit
          (valueChange)="onChange()"
          [editMode]="editMode"
          [vertical]="false"
          [(value)]="PensionFixedAmountIsPerDay"
          [(isDirty)]="childIsDirty[11]"
        >
          <app-radio-edit-option
            [value]="false"
            [label]="'EmployeeEmployment.PensionIsFixedAmountPerWorkDayFalse' | translate"
          >
          </app-radio-edit-option>
          <app-radio-edit-option
            [value]="true"
            [label]="'EmployeeEmployment.PensionIsFixedAmountPerWorkDayTrue' | translate"
          >
          </app-radio-edit-option>
        </app-radio-edit>
      </app-module-check> -->

      <app-text-edit
        [(isDirty)]="childIsDirty[6]"
        [label]="
          isOverridePolicyNo
            ? SelectedPensionProvider.PolicyNumberLabelOverride
            : ('EmployeeEmployment.PolicyNo' | translate)
        "
        [editMode]="editMode"
        [(value)]="PensionPolicyNumber"
        [disabled]="!SelectedPensionProvider || disable"
        (valueChange)="onChange()"
      ></app-text-edit>

      <div class="pension-due-date" *ngIf="isHaveDueDate">
        <app-pension-numeric-edit
          [(isDirty)]="childIsDirty[7]"
          [editMode]="editMode"
          [(value)]="PensionDueDateDelayDays"
          [label]="'EmployeeEmployment.PensionDueDateDelayBefore' | translate"
          [labelAfter]="'EmployeeEmployment.PensionDueDateDelayAfter' | translate"
          [disable]="!SelectedPensionProvider || disable"
          [option]="numericOptions"
          (valueChange)="onChange()"
        >
        </app-pension-numeric-edit>
      </div>

      <div *ngIf="isVisiblePensionTakenSalary">
        <app-check-edit
          [label]="'EmployeeEmployment.DeductPensionFromNetSalaryLabel' | translate"
          [(value)]="pensionTaken"
          [(isDirty)]="childIsDirty[8]"
          [editMode]="editMode"
          (valueChange)="onChange()"
          [disable]="!SelectedPensionProvider || disable"
        ></app-check-edit>
      </div>

      <app-module-check [moduleId]="4">
        <div class="pension-date">
          <div>
            <app-date-picker
              [label]="'EmployeeEmployment.PensionStartDateLabel' | translate"
              [editMode]="editMode"
              [(isDirty)]="childIsDirty[9]"
              [(value)]="PensionStartDate"
              (valueChange)="onChange()"
              [disable]="!SelectedPensionProvider || disable"
            >
            </app-date-picker>
          </div>

          <div>
            <app-date-picker
              [label]="'EmployeeEmployment.PensionEndDateLabel' | translate"
              [editMode]="editMode"
              [(isDirty)]="childIsDirty[10]"
              [(value)]="PensionEndDate"
              (valueChange)="onChange()"
              [disable]="!SelectedPensionProvider || disable"
            >
            </app-date-picker>
          </div>
        </div>
      </app-module-check>
    </div>

    <div class="FormElement" *ngIf="!isAddButtonHidden">
      <app-button
        buttonClass="Button--card"
        (clickEvent)="onAddFirstPensionProvider()"
        [isDisabled]="disable || disableBySecondaryIncomeType"
        [label]="'EmployeeEmployment.AddPensionProvider' | translate"
      ></app-button>
    </div>
  </fieldset>
</form>

<div
  [ngClass]="{ 'nondiamond-inner-wrap': disableBySecondaryIncomeType }"
  kendoTooltip
  [title]="
    disableBySecondaryIncomeType ? ('EmployeeEmployment.ToolTipWhenDisabledBecauseOfIncomeType5' | translate) : ''
  "
></div>
