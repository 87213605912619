import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { EmployeeService } from '../../services/employee.service';
import { SessionService } from '../../services/session/session.service';
var ViewTaxCardHistoryDialogComponent = /** @class */ (function () {
    function ViewTaxCardHistoryDialogComponent(dataService, sessionService, staticDataService, employeeService) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.employeeService = employeeService;
        this.taxCardChange = new EventEmitter();
        this.isVisibleChange = new EventEmitter();
        this.addNewTaxInfo = new EventEmitter();
        this.refreshData = false;
        this.gridDataBackup = '';
        this.flagselect = 0;
        this.defaultRecordValue = {};
        this.NumericOptions = { format: 'n2', decimals: 2, step: 0, min: 0, spinners: false };
        // IUserTaxInfo
        this.taxCardHistory = [];
        this.isShowConfiumAfterCreateDanishTaxCard = false;
        this.visible = false;
        this.ngUnsubscribe = new Subject();
        this.gridData = this.taxCardHistory;
    }
    Object.defineProperty(ViewTaxCardHistoryDialogComponent.prototype, "isFullAdmin", {
        get: function () {
            return this.sessionService.role.IsFullAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewTaxCardHistoryDialogComponent.prototype, "isSalaryAdmin", {
        get: function () {
            return this.sessionService.role.IsSalaryAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewTaxCardHistoryDialogComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewTaxCardHistoryDialogComponent.prototype, "isVisible", {
        get: function () {
            return this.visible;
        },
        set: function (value) {
            if (this.visible !== value) {
                if (value) {
                    this.getTaxCardHistory();
                }
                else {
                    this.resetDialog();
                }
                this.visible = value;
                this.isVisibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    ViewTaxCardHistoryDialogComponent.prototype.resetDialog = function () {
        if (this.gridDataBackup !== JSON.stringify(this.gridData)) {
            this.updateTaxCard();
        }
        this.taxCardHistory = [];
        this.gridData = filterBy(this.taxCardHistory, this.filter);
        this.flagselect = 0;
        this.selectedItem = null;
        this.refreshData = true;
    };
    Object.defineProperty(ViewTaxCardHistoryDialogComponent.prototype, "ViewTaxCardHistoryLablel", {
        get: function () {
            if (this.isSwedenCompany) {
                return 'EmployeeGeneral.PersonalTaxRate';
            }
            return 'EmployeeGeneral.ViewTaxCardHistory';
        },
        enumerable: true,
        configurable: true
    });
    ViewTaxCardHistoryDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        //document.addEventListener("keydown", (event: any): void => {
        //    this.onKeyDown(event);
        //});
        this.staticDataService.TaxCardType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.taxCardTypes = data); });
    };
    ViewTaxCardHistoryDialogComponent.prototype.ngOnDestroy = function () {
        //document.removeEventListener("keydown", (event: any): void => {
        //});
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(ViewTaxCardHistoryDialogComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    ViewTaxCardHistoryDialogComponent.prototype.getTaxCardHistory = function () {
        var _this = this;
        this.dataService.Employments_GetTaxCards(this.userEmploymentId).subscribe(function (data) {
            if (data && data.length > 0) {
                data.forEach(function (value) {
                    value.HightlightedRow = value.IsCurrent;
                    value.IsRequest = value.TaxCardRequestId ? true : false;
                    value.idColumn = _this.flagselect;
                    value.Editable = value.IsManual;
                    value.isActiveEditable = _this.isDanishCompany ? true : value.IsManual;
                    value.DeductionEditable = !value.IsManual || value.TaxCardTypeId !== 1 ? false : true;
                    value.FreeAmountEditable = !value.IsManual || value.TaxCardTypeId !== 3 ? false : true;
                    value.IsDummy = value.IsDummy ? true : false;
                    value.MinimumRate = value.MinimumRate ? value.MinimumRate : null;
                    value.MaximumDeductionMonth = value.MaximumDeductionMonth ? value.MaximumDeductionMonth : null;
                    value.MaximumDeductionDay = value.MaximumDeductionDay ? value.MaximumDeductionDay : null;
                    _this.flagselect += 1;
                });
            }
            _this.taxCardHistory = data;
            _this.gridData = filterBy(_this.taxCardHistory, _this.filter);
            _this.updateBackupData();
            // this.gridData.forEach((model: IUserTaxInfo) => {
            //   model.ValidFrom = new DateTimeConvert(new Date(model.ValidFrom)).Date;
            //   model.ValidUntil = new DateTimeConvert(new Date(model.ValidUntil)).Date;
            // });
        });
    };
    Object.defineProperty(ViewTaxCardHistoryDialogComponent.prototype, "isSwedenCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    ViewTaxCardHistoryDialogComponent.prototype.addHandler = function (dataItem) {
        // const defaultDataItem: any = this.createNewDataItem();
        // Object.assign(dataItem, defaultDataItem);
        this.saveChanges(dataItem);
    };
    ViewTaxCardHistoryDialogComponent.prototype.onUpdateDefaultRecord = function () {
        this.defaultRecordValue = this.createNewDataItem();
    };
    ViewTaxCardHistoryDialogComponent.prototype.removeHandler = function (dataItem) {
        // To remove item from history.
    };
    Object.defineProperty(ViewTaxCardHistoryDialogComponent.prototype, "isDanishCompany", {
        get: function () {
            return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.DENMARK_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    ViewTaxCardHistoryDialogComponent.prototype.saveChanges = function (args) {
        var _this = this;
        this.flagselect += 1;
        var dataItem = args.dataItem ? args.dataItem : args;
        if (!this.taxCardTypes.filter(function (e) { return e.Id === dataItem.TaxCardTypeId; })) {
            return;
        }
        var userTaxInfo = this.createUserTaxInfo(dataItem);
        if (dataItem.Id) {
            this.updateTaxCard(dataItem, userTaxInfo);
        }
        else {
            this.dataService.Employments_CreateTaxInfo(userTaxInfo).subscribe(function (taxInfo) {
                _this.newTaxInfo = taxInfo;
                dataItem.Id = taxInfo.Id;
                dataItem.Editable = taxInfo.IsManual;
                dataItem.DeductionEditable = !taxInfo.IsManual || taxInfo.TaxCardTypeId !== 1 ? false : true;
                dataItem.FreeAmountEditable = !taxInfo.IsManual || taxInfo.TaxCardTypeId !== 3 ? false : true;
                dataItem.IsDummy = taxInfo.IsDummy ? true : false;
                dataItem.MinimumRate = taxInfo.MinimumRate ? taxInfo.MinimumRate : null;
                dataItem.MaximumDeductionMonth = taxInfo.MaximumDeductionMonth ? taxInfo.MaximumDeductionMonth : null;
                dataItem.MaximumDeductionDay = taxInfo.MaximumDeductionDay ? taxInfo.MaximumDeductionDay : null;
                _this.taxCardChange.emit();
                _this.refreshData = true;
                if (_this.isDanishCompany) {
                    _this.isShowConfiumAfterCreateDanishTaxCard = true;
                }
            }, function () {
                _this.getTaxCardHistory();
            });
        }
    };
    ViewTaxCardHistoryDialogComponent.prototype.updateTaxCard = function (dataItem, userTaxInfo) {
        var _this = this;
        if (!dataItem) {
            dataItem = this.selectedItem;
        }
        if (!userTaxInfo) {
            userTaxInfo = this.createUserTaxInfo(dataItem);
        }
        if (dataItem.Id) {
            this.dataService.Employments_UpdateTaxInfo(userTaxInfo).subscribe(function (taxInfo) {
                dataItem.Editable = taxInfo.IsManual;
                dataItem.DeductionEditable = !taxInfo.IsManual || taxInfo.TaxCardTypeId !== 1 ? false : true;
                dataItem.FreeAmountEditable = !taxInfo.IsManual || taxInfo.TaxCardTypeId !== 3 ? false : true;
                dataItem.IsDummy = taxInfo.IsDummy ? true : false;
                dataItem.MinimumRate = taxInfo.MinimumRate ? taxInfo.MinimumRate : null;
                dataItem.MaximumDeductionMonth = taxInfo.MaximumDeductionMonth ? taxInfo.MaximumDeductionMonth : null;
                dataItem.MaximumDeductionDay = taxInfo.MaximumDeductionDay ? taxInfo.MaximumDeductionDay : null;
                Object.assign(dataItem, taxInfo);
                _this.refreshData = true;
                setTimeout(function () {
                    _this.updateBackupData();
                });
                _this.taxCardChange.emit();
            }, function () {
                _this.getTaxCardHistory();
            });
        }
    };
    ViewTaxCardHistoryDialogComponent.prototype.ConfiumAfterCreateDanishTaxCard = function (action) {
        if (action === 'Yes') {
            this.employeeService.makeRequestTaxCard.emit(true);
        }
    };
    ViewTaxCardHistoryDialogComponent.prototype.onTaxCardHistoryAction = function (action) {
        if (action === 'Ok') {
            this.addNewTaxInfo.emit(this.newTaxInfo);
        }
    };
    ViewTaxCardHistoryDialogComponent.prototype.createNewDataItem = function () {
        this.flagselect += 1;
        return {
            Id: undefined,
            TaxCardTypeId: this.taxCardTypes[0] ? this.taxCardTypes[0].Id : undefined,
            IsCurrent: undefined,
            DeductionDay: 0,
            DeductionWeek: 0,
            DeductionMonth: 0,
            FreeAmount: 0,
            ValidFrom: this.staticDataService.getCurrentdate(false),
            ValidUntil: this.staticDataService.getCurrentdate(true),
            TaxRate: 0,
            IsActive: true,
            MinimumRate: undefined,
            MaximumDeductionMonth: undefined,
            MaximumDeductionDay: undefined,
            Created: this.staticDataService.getCurrentdate(),
            IsDummy: undefined,
            IsManual: true,
            Editable: true,
            idColumn: this.flagselect,
            DeductionEditable: true,
            FreeAmountEditable: true,
            isActiveEditable: true
        };
    };
    ViewTaxCardHistoryDialogComponent.prototype.createUserTaxInfo = function (dataItem) {
        var userTaxCardInfo = {
            Created: dataItem.Created instanceof Date ? dataItem.Created : this.sessionService.parseDate(dataItem.Created),
            DeductionDay: dataItem.DeductionDay,
            DeductionMonth: dataItem.DeductionMonth,
            DeductionWeek: dataItem.DeductionWeek,
            FreeAmount: dataItem.FreeAmount,
            Id: dataItem.Id,
            IsActive: dataItem.IsActive,
            IsCurrent: dataItem.IsCurrent,
            IsDummy: dataItem.IsDummy,
            MaximumDeductionDay: dataItem.MaximumDeductionDay,
            MaximumDeductionMonth: dataItem.MaximumDeductionMonth,
            MinimumRate: dataItem.MinimumRate,
            TaxCardTypeId: dataItem.TaxCardTypeId,
            TaxRate: dataItem.TaxRate,
            UserEmploymentId: this.userEmploymentId,
            ValidFrom: dataItem.ValidFrom,
            ValidUntil: dataItem.ValidUntil,
            IsManual: dataItem.IsManual,
            ParsedFromFileName: dataItem.ParsedFromFileName || null,
            TaxCardRequestId: dataItem.TaxCardRequestId || null
        };
        return userTaxCardInfo;
    };
    ViewTaxCardHistoryDialogComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        this.gridData = filterBy(this.taxCardHistory, filter);
    };
    ViewTaxCardHistoryDialogComponent.prototype.updateBackupData = function () {
        var backup = JSON.stringify(this.gridData);
        this.gridDataBackup = backup;
    };
    return ViewTaxCardHistoryDialogComponent;
}());
export { ViewTaxCardHistoryDialogComponent };
