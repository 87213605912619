import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { FixedPanelService } from 'src/app/services/fixed-panel.service';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { RouterStateService } from '../../common/router-state.service';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-tab-panel',
  templateUrl: './tab-panel.component.html'
})
export class TabPanelComponent implements OnInit, OnDestroy {
  @Input() public parentTab: string;
  @Input() public payslipTabVisible = false;
  @Input() public timeTabVisible = false;

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public get IsDanishCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
  }

  public get IsMobile(): boolean {
    return this.session.browser.isMobile || this.session.browser.isHybridApp;
  }

  constructor(
    public session: SessionService,
    public state: RouterStateService,
    public staticData: StaticDataService,
    private fixedPanelService: FixedPanelService
  ) {}

  public ngOnInit(): void {
    this.setResponsiveStyle(this.session.currentState);
  }

  private ngUnsubscribe: Subject<{}> = new Subject<{}>();
  public ngOnDestroy() {
    this.ngUnsubscribe.unsubscribe();
  }

  public clickAreamenuToggledChange(): void {
    this.session.menuToggled = true;
    this.session.showMoreMenuToggled = false;
    this.session.showHelpMenuToggled = false;
  }

  public toggleMenuButton(): void {
    if (!this.session.menuToggled) {
      this.session.menuToggled = true;
    } else {
      this.session.menuToggled = false;
    }
    setTimeout(() => {
      this.fixedPanelService.calculateHeightContent();
    });
    window.scrollTo(0, 0);
  }

  public navigateToState($event: any, state: string): void {
    // $event.stopPropagation();
    // $event.preventDefault();
    if (!this.session.isSubmitting) {
      this.setResponsiveStyle(state);
      this.session.NavigateTo(state);
    }
  }

  // beacause behavior handle in service so we don't need waitting for getting
  public navigateToStateNonWaitting($event: any, state: string) {
    this.setResponsiveStyle(state);
    this.session.NavigateTo(state);
  }

  private setResponsiveStyle(state: string): void {
    switch (state) {
      case 'tabs.employee.hiringstatus':
        this.session.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
        break;
      case 'tabs.employee.payrolldata':
        this.session.currentStyleMenuTabpanelReponsive = 'tabPanelLongTitle';
        break;
      default:
        if (this.session.currentState !== 'tabs.employee') {
          this.session.currentStyleMenuTabpanelReponsive = '';
        }
        break;
    }

    if (this.parentTab.toLowerCase() === 'company') {
      this.session.currentStyleMenuTabpanelReponsive = 'companytabPanelLongTitle';
    }
  }
}
