import { Component, ViewEncapsulation } from '@angular/core';
import { Transition, TransitionService } from '@uirouter/core';
import { Global } from '../common/global';
import { RouterStateService } from '../common/router-state.service';
import { EmployeeService } from '../services/employee.service';
import { SessionService } from '../services/session/session.service';
import { ReportDialogService } from '../shared-components/report-dialog/report-dialog.service';

@Component({
  selector: 'app-accountant',
  templateUrl: './accountant.component.html',
  styleUrls: ['./accountant.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountantComponent {
  public get IsMobile(): boolean {
    return this.session.browser.isMobile || this.session.browser.isHybridApp;
  }

  constructor(
    private session: SessionService,
    public state: RouterStateService,
    public reportDialogService: ReportDialogService,
    private employeeService: EmployeeService,
    private transitionService: TransitionService
  ) {
    this.transitionService.onStart({}, (transition: Transition) => {
      const fromState = transition.from().name;
      const toState = transition.to().name;

      if (
        fromState &&
        toState &&
        fromState !== toState &&
        fromState.includes('tabs.accountant') &&
        !toState.includes('tabs.accountant') &&
        this.employeeService.isNonSelected
      ) {
        if (this.session.feature.AccountantTabEnabled) {
          setTimeout(() => {
            this.employeeService.filterEmployees();
          });
        }
      }

      return true;
    });
  }

  public get IsMultiCompanyUser(): boolean {
    return Global.SESSION && Global.SESSION.IsAccountMember && Global.SESSION.SignOnToken.UserAccountId !== null;
  }

  public clickAreamenuToggledChange(): void {
    this.session.menuToggled = true;
    this.session.showMoreMenuToggled = false;
    this.session.showHelpMenuToggled = false;
    this.session.showMenuToggled = false;
  }
}
