import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import {
  DxButtonModule,
  DxCalendarModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDropDownBoxModule
} from 'devextreme-angular';

import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

import { CompanyApprovalGridDetailComponent } from '../company/approval/company-approval-grid-detail.component';
import { AutoCompleteTextBoxComponent } from './auto-complete-text-box/auto-complete-textbox.component';
import { ActionButtonComponent } from './buttons/action-button.component';
import { ButtonComponent } from './buttons/button.component';
import { EditButtonComponent } from './buttons/edit-button.component';
import { MoreMenuButtonComponent } from './buttons/more-menu-button.component';
import { PayslipIconComponent } from './buttons/payslip-icon.component';
import { PopUpButtonComponent } from './buttons/popup-button.component';
import { CheckEditSlideComponent } from './check-edit-slide/check-edit-slide.component';
import { CheckEditComponent } from './check-edit/check-edit.component';

import { ComboboxEditComponent } from './combobox-edit/combobox-edit.component';
import { CompanyLogoComponent } from './company-logo/company-logo.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { CalendarPickerComponent } from './dev-express-calendar/dev-express-calendar.component';

import { DialogActionComponent } from './dialogs/dialog-action.component';
import { DialogButtonsComponent } from './dialogs/dialog-buttons.component';
import { DialogComponent } from './dialogs/dialog.component';
import { IFrameDialogComponent } from './dialogs/i-frame-dialog.component';
import { MobileModalContentComponent } from './dialogs/mobile-modal/mobile-modal-content.component';
import { MobileModalFooterComponent } from './dialogs/mobile-modal/mobile-modal-footer.component';
import { MobileModalHeaderComponent } from './dialogs/mobile-modal/mobile-modal-header.component';
import { MobileModalComponent } from './dialogs/mobile-modal/mobile-modal.component';
import { TestDialogComponent } from './dialogs/test-dialog.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { DropDownFilterComponent } from './grid/dropdown-filter.component';
import { GridActionComponent } from './grid/grid-action.component';
import { GridCheckboxComponent } from './grid/grid-checkbox.component';
import { GridColumnGroupComponent } from './grid/grid-column-group.component';
import { GridColumnComponent } from './grid/grid-column.component';
import { GridFlagColumnComponent } from './grid/grid-flag-column.component';
import { GridInCellPopupComponent } from './grid/grid-in-cell-popup.component';
import { GridRadioComponent } from './grid/grid-radio.component';
import { GridComponent } from './grid/grid.componnent';
import { IconDefinitionsComponent } from './icon/icon-definitions.component';
import { IconComponent } from './icon/icon.component';
import { ModuleCheckComponent } from './module-check/module-check.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { NumericEditComponent } from './numeric-edit/numeric-edit.component';
import { PensionNumericEditComponent } from './pension-numeric-edit/pension-numeric-edit.component';
import { PercentageEditComponent } from './percentage-edit/percentage-edit.component';
import { PictureEditComponent } from './picture-edit/picture-edit.component';
import { CPRFormatPipe } from './pipes/cpr-format.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { KeepHtmlPipe } from './pipes/keep-html.pipe';
import { NumericFormatPipe } from './pipes/numeric-format.pipe';
import { PriceFormatPipe } from './pipes/price-format.pipe';
import { ToJsonPipe } from './pipes/to-json.pipe';
import { RadioEditOptionComponent } from './radio-edit/radio-edit-option.component';
import { RadioEditComponent } from './radio-edit/radio-edit.component';
import { ShareAmountComponent } from './share-amount/share-amount.component';
import { SharePercentageComponent } from './share-percentage/share-percentage.component';
import { TabPanelComponent } from './tab-panel/tab-panel.component';
import { TextEditComponent } from './text-edit/text-edit.component';
import { TimePickerComponent } from './time-picker/time-picker.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DxButtonModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxDropDownBoxModule,
    DxCalendarModule,
    DateInputsModule,
    DialogModule,
    DropDownsModule,
    GridModule,
    InputsModule,
    IntlModule,
    LayoutModule,
    PopupModule,
    TooltipModule,
    TranslateModule
  ],
  declarations: [
    ActionButtonComponent,
    ButtonComponent,
    PopUpButtonComponent,
    EditButtonComponent,
    MoreMenuButtonComponent,
    PayslipIconComponent,
    DialogComponent,
    DialogActionComponent,
    DialogButtonsComponent,
    IFrameDialogComponent,
    TestDialogComponent,
    GridCheckboxComponent,
    GridComponent,
    DropDownFilterComponent,
    GridColumnGroupComponent,
    GridInCellPopupComponent,
    GridFlagColumnComponent,
    GridActionComponent,
    GridColumnComponent,
    IconComponent,
    IconDefinitionsComponent,
    MobileModalComponent,
    MobileModalContentComponent,
    MobileModalFooterComponent,
    MobileModalHeaderComponent,
    ModuleCheckComponent,
    RadioEditComponent,
    RadioEditOptionComponent,
    TabPanelComponent,
    AutoCompleteTextBoxComponent,
    CheckEditComponent,
    CheckEditSlideComponent,
    ComboboxEditComponent,
    DatePickerComponent,
    CalendarPickerComponent,
    FileUploadComponent,
    MultiSelectComponent,
    NumericEditComponent,
    PensionNumericEditComponent,
    PercentageEditComponent,
    PictureEditComponent,
    ShareAmountComponent,
    SharePercentageComponent,
    TextEditComponent,
    TimePickerComponent,
    CPRFormatPipe,
    DateFormatPipe,
    KeepHtmlPipe,
    NumericFormatPipe,
    PriceFormatPipe,
    ToJsonPipe,
    CompanyApprovalGridDetailComponent,
    GridRadioComponent,
    CompanyLogoComponent
  ],
  providers: [PriceFormatPipe],
  exports: [
    ActionButtonComponent,
    ButtonComponent,
    PopUpButtonComponent,
    EditButtonComponent,
    MoreMenuButtonComponent,
    PayslipIconComponent,
    DialogComponent,
    DialogActionComponent,
    DialogButtonsComponent,
    IFrameDialogComponent,
    TestDialogComponent,
    GridCheckboxComponent,
    GridComponent,
    DropDownFilterComponent,
    GridColumnGroupComponent,
    GridInCellPopupComponent,
    GridFlagColumnComponent,
    GridActionComponent,
    GridColumnComponent,
    IconComponent,
    IconDefinitionsComponent,
    MobileModalComponent,
    MobileModalContentComponent,
    MobileModalFooterComponent,
    MobileModalHeaderComponent,
    ModuleCheckComponent,
    RadioEditComponent,
    RadioEditOptionComponent,
    TabPanelComponent,
    AutoCompleteTextBoxComponent,
    CheckEditComponent,
    CheckEditSlideComponent,
    ComboboxEditComponent,
    DatePickerComponent,
    CalendarPickerComponent,
    FileUploadComponent,
    MultiSelectComponent,
    NumericEditComponent,
    PensionNumericEditComponent,
    PercentageEditComponent,
    PictureEditComponent,
    ShareAmountComponent,
    SharePercentageComponent,
    TextEditComponent,
    TimePickerComponent,
    CPRFormatPipe,
    DateFormatPipe,
    KeepHtmlPipe,
    NumericFormatPipe,
    PriceFormatPipe,
    ToJsonPipe,
    CompanyApprovalGridDetailComponent,
    GridRadioComponent,
    CompanyLogoComponent
  ],
  entryComponents: [DialogComponent, IFrameDialogComponent]
})
export class CustomControlsModule {}
