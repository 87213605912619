<app-fixed-panel [excludedComponents]="listClassExcluded" [specificClassName]="'Island-price'">
  <div class="Island Island-price">
    <div class="price-wrapper packs-wrapper pDiv priceAlert" *ngIf="companyModuleService.showBilledViaMCMessage">
      <div class="main">
        <div class="small-note" *ngIf="companyModuleService.showBilledViaMCMessage">
          <p>{{ companyModuleService.billedMessage }}</p>
        </div>
      </div>
      <div class="sticky-parent-helper"></div>
    </div>
    <div class="price-wrapper packs-wrapper pDiv">
      <div *ngIf="isLoading" class="loaderModulesWrap">
        <div class="sticky">
          <div class="spinner"></div>
          <div class="message">
            {{ 'Price.Loading' | translate }}
          </div>
        </div>
      </div>
      <!-- HTML MARKED FOR READABILITY -->
      <!-- Minimize all rows. Figure out where you need to edit from the comment above the row (which matched the content) -->
      <div class="main" id="packs">
        <div class="grid price-list">
          <!--Pakkepris pr. lønseddel ,Bronze 5,- ,Sølv 10,- ,Guld 15,- -->
          <div class="item row-top column-left">
            <span>{{ 'Price.Module' | translate }}</span
            ><span
              >{{ 'Price.Price' | translate }}
              <span class="hideSmall">{{ 'Price.BillingPrinciple2' | translate }}</span></span
            >
          </div>
          <div
            class="item row-top column-bronze bronze-header centerContent"
            [ngClass]="{ selected: getPackage(1).Selected }"
          >
            <div class="headerPackage">
              <p (click)="onPackageChange(1)">{{ getPackage(1).Name }}</p>
              <tool-tip-price
                [Tooltiptitle]="moduleTooltipHtml('PriceCalculatorLink.Bronze' | translate, getPackage(1).Description)"
              >
              </tool-tip-price>
            </div>
          </div>
          <div
            class="item row-top column-silver silver-header centerContent"
            [ngClass]="{ selected: getPackage(2).Selected }"
          >
            <div class="headerPackage">
              <p (click)="onPackageChange(2)">{{ getPackage(2).Name }}</p>
              <tool-tip-price
                [Tooltiptitle]="moduleTooltipHtml('PriceCalculatorLink.Silver' | translate, getPackage(2).Description)"
              >
              </tool-tip-price>
            </div>
          </div>
          <div class="item row-top gold-header centerContent" [ngClass]="{ selected: getPackage(3).Selected }">
            <div class="headerPackage">
              <p (click)="onPackageChange(3)">{{ getPackage(3).Name }}</p>
              <tool-tip-price
                [Tooltiptitle]="moduleTooltipHtml('PriceCalculatorLink.Gold' | translate, getPackage(3).Description)"
              >
              </tool-tip-price>
            </div>
          </div>

          <!-- Almindelig lønstring og basisfunktioner - Gratis-Altid ikluderet-->
          <div
            class="item column-left"
            [ngClass]="{
              selected: getModule(1).IsEnabled || isModuleLocked(1),
              locked: isModuleLocked(1),
              packageSelected: isModulePriceStrikethrough(1)
            }"
          >
            <div class="IconModuleInfo">
              <tool-tip-price
                [Tooltiptitle]="
                  moduleTooltipHtml('PriceCalculatorLink.Core' | translate, getModule(1).ModuleDescription)
                "
              >
              </tool-tip-price>
            </div>

            <div
              (click)="onModuleChange(1)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(1).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(1).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(1).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(1).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>

                  <span class="u-middle">{{ getModule(1).ModuleName }}</span>
                </span>
                <span class="price priceFree">
                  {{ getModule(1).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
            </div>

            <div
              (click)="onModuleChange(1)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="!companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(1).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    [title]="getModule(1).ScheduledDeactivationDate | date: 'dd/MM/yyyy'"
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(1).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(1).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>

                  <span class="u-middle">{{ getModule(1).ModuleName }}</span>
                </span>
                <span class="price priceFree">
                  {{ getModule(1).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
              <div class="switch-container centerContent">
                <div class="switch">
                  <span>
                    <app-icon [icon]="'SuccessWhite'"></app-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="!companyModuleService.isMobile"
            class="item column-bronze item-bronze-pack centerContent"
            (click)="onPackageChange(1)"
            [ngClass]="{ selected: getPackage(1).Selected }"
          >
            <div class="price-and-tooltip">
              {{ getPackage(1).Price | PriceFormat: true | translate
              }}<span class="hideSmall"> {{ 'Price.BillingPrinciple2' | translate }}</span>
            </div>

            <div class="centerContent package-selection-icon">
              <div class="switch package-switch ">
                <span *ngIf="getPackage(1).Selected">
                  <app-icon [icon]="'SuccessWhite'"></app-icon>
                </span>
                <span *ngIf="!getPackage(1).Selected">
                  <app-icon [icon]="'circleplusWhite'"></app-icon>
                </span>
              </div>
            </div>
          </div>
          <div
            *ngIf="companyModuleService.isMobile"
            class="item column-bronze item-bronze-pack centerContent"
            (click)="onPackageChange(1)"
            [ngClass]="{ selected: getPackage(1).Selected }"
          >
            <div class="price-and-tooltip">
              {{ getPackage(1).Price | PriceFormat: true | translate
              }}<span class="hideSmall"> {{ 'Price.BillingPrinciple2' | translate }}</span>
            </div>

            <div class="centerContent package-selection-icon">
              <div class="switch package-switch ">
                <span *ngIf="getPackage(1).Selected">
                  <app-icon [icon]="'SuccessWhite'"></app-icon>
                </span>
                <span *ngIf="!getPackage(1).Selected">
                  <app-icon [icon]="'circleplusWhite'"></app-icon>
                </span>
              </div>
            </div>
            <div></div>
          </div>

          <div
            *ngIf="!companyModuleService.isMobile"
            class="item column-silver item-silver-pack withoutPadding
                       centerContent"
            (click)="onPackageChange(2)"
            [ngClass]="{ selected: getPackage(2).Selected }"
          >
            <div class="price-and-tooltip">
              {{ getPackage(2).Price | PriceFormat: true | translate
              }}<span class="hideSmall"> {{ 'Price.BillingPrinciple2' | translate }}</span>
            </div>
            <div class="centerContent package-selection-icon">
              <div class="switch package-switch ">
                <span *ngIf="getPackage(2).Selected">
                  <app-icon [icon]="'SuccessWhite'"></app-icon>
                </span>
                <span *ngIf="!getPackage(2).Selected">
                  <app-icon [icon]="'circleplusWhite'"></app-icon>
                </span>
              </div>
            </div>
            <div></div>
            <div></div>
          </div>
          <div
            *ngIf="companyModuleService.isMobile"
            class="item column-silver item-silver-pack withoutPadding"
            (click)="onPackageChange(2)"
            [ngClass]="{ selected: getPackage(2).Selected }"
          >
            <div class="price-and-tooltip">
              {{ getPackage(2).Price | PriceFormat: true | translate
              }}<span class="hideSmall"> {{ 'Price.BillingPrinciple2' | translate }}</span>
            </div>
            <div class="centerContent package-selection-icon">
              <div class="switch package-switch ">
                <span *ngIf="getPackage(2).Selected">
                  <app-icon [icon]="'SuccessWhite'"></app-icon>
                </span>
                <span *ngIf="!getPackage(2).Selected">
                  <app-icon [icon]="'circleplusWhite'"></app-icon>
                </span>
              </div>
            </div>
            <div></div>
            <div></div>
          </div>

          <div
            *ngIf="!companyModuleService.isMobile"
            class="item row-bottom column-gold item-gold-pack withoutPadding centerContent"
            (click)="onPackageChange(3)"
            [ngClass]="{ selected: getPackage(3).Selected }"
          >
            <div class="price-and-tooltip">
              {{ getPackage(3).Price | PriceFormat: true | translate
              }}<span class="hideSmall"> {{ 'Price.BillingPrinciple2' | translate }}</span>
            </div>
            <div class="centerContent package-selection-icon">
              <div class="switch package-switch ">
                <span *ngIf="getPackage(3).Selected">
                  <app-icon [icon]="'SuccessWhite'"></app-icon>
                </span>
                <span *ngIf="!getPackage(3).Selected">
                  <app-icon [icon]="'circleplusWhite'"></app-icon>
                </span>
              </div>
            </div>
            <div></div>
          </div>
          <div
            *ngIf="companyModuleService.isMobile"
            class="item row-bottom column-gold item-gold-pack withoutPadding centerContent"
            (click)="onPackageChange(3)"
            [ngClass]="{ selected: getPackage(3).Selected }"
          >
            <div class="price-and-tooltip">
              {{ getPackage(3).Price | PriceFormat: true | translate
              }}<span class="hideSmall"> {{ 'Price.BillingPrinciple2' | translate }}</span>
            </div>
            <div class="centerContent package-selection-icon">
              <div class="switch package-switch ">
                <span *ngIf="getPackage(3).Selected">
                  <app-icon [icon]="'SuccessWhite'"></app-icon>
                </span>
                <span *ngIf="!getPackage(3).Selected">
                  <app-icon [icon]="'circleplusWhite'"></app-icon>
                </span>
              </div>
            </div>
            <div></div>
          </div>

          <!-- Automatiske bankoverførsler 1,50 -->
          <div
            class="item column-left"
            [ngClass]="{
              selected: getModule(10).IsEnabled || isModuleLocked(10),
              locked: isModuleLocked(10),
              packageSelected: isModulePriceStrikethrough(10)
            }"
          >
            <div class="IconModuleInfo">
              <tool-tip-price
                [Tooltiptitle]="
                  moduleTooltipHtml('PriceCalculatorLink.BrankTransfer' | translate, getModule(10).ModuleDescription)
                "
              ></tool-tip-price>
            </div>
            <div
              (click)="onModuleChange(10)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(10).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(10).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(10).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(10).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>

                  <span class="u-middle">{{ getModule(10).ModuleName }}</span>
                </span>
                <span class="price">
                  {{ getModule(10).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
            </div>
            <div
              (click)="onModuleChange(10)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="!companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(10).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(10).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(10).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(10).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>

                  <span class="u-middle">{{ getModule(10).ModuleName }}</span>
                </span>
                <span class="price">
                  {{ getModule(10).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
              <div class="switch-container centerContent">
                <div class="switch">
                  <span *ngIf="getModule(10).IsEnabled">
                    <app-icon [icon]="'SuccessWhite'"></app-icon>
                  </span>
                  <span *ngIf="!getModule(10).IsEnabled">
                    <app-icon [icon]="'circleplusWhite'"></app-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!--Integration til økonomisystermer 5,-->
          <div
            class="item column-left"
            [ngClass]="{
              selected: getModule(15).IsEnabled || isModuleLocked(15),
              locked: isModuleLocked(15),
              packageSelected: isModulePriceStrikethrough(15)
            }"
          >
            <div class="IconModuleInfo">
              <tool-tip-price
                [Tooltiptitle]="
                  moduleTooltipHtml('PriceCalculatorLink.Integrations' | translate, getModule(15).ModuleDescription)
                "
              ></tool-tip-price>
            </div>
            <div
              (click)="onModuleChange(15)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(15).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(15).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(15).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(15).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <span class="u-middle">{{ getModule(15).ModuleName }}</span>
                </span>
                <span class="price">
                  {{ getModule(15).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
            </div>
            <div
              (click)="onModuleChange(15)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="!companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(15).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(15).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(15).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(15).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>

                  <span class="u-middle">{{ getModule(15).ModuleName }}</span>
                </span>
                <span class="price">
                  {{ getModule(15).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
              <div class="switch-container centerContent">
                <div class="switch">
                  <span *ngIf="getModule(15).IsEnabled">
                    <app-icon [icon]="'SuccessWhite'"></app-icon>
                  </span>
                  <span *ngIf="!getModule(15).IsEnabled">
                    <app-icon [icon]="'circleplusWhite'"></app-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!--Sproglag 2,50-->
          <div
            class="item column-left"
            [ngClass]="{
              selected: getModule(5).IsEnabled || isModuleLocked(5),
              locked: isModuleLocked(5),
              packageSelected: isModulePriceStrikethrough(5)
            }"
          >
            <div class="IconModuleInfo">
              <tool-tip-price
                [Tooltiptitle]="
                  moduleTooltipHtml('PriceCalculatorLink.Languages' | translate, getModule(5).ModuleDescription)
                "
              ></tool-tip-price>
            </div>
            <div
              (click)="onModuleChange(5)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(5).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(5).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(5).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(5).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <span class="u-middle">{{ getModule(5).ModuleName }}</span>
                </span>
                <span class="price">
                  {{ getModule(5).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
            </div>
            <div
              (click)="onModuleChange(5)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="!companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(5).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(5).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(5).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(5).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>

                  <span class="u-middle">{{ getModule(5).ModuleName }}</span>
                </span>
                <span class="price">
                  {{ getModule(5).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
              <div class="switch-container centerContent">
                <div class="switch">
                  <span *ngIf="getModule(5).IsEnabled">
                    <app-icon [icon]="'SuccessWhite'"></app-icon>
                  </span>
                  <span *ngIf="!getModule(5).IsEnabled">
                    <app-icon [icon]="'circleplusWhite'"></app-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!--MC 2,50-->
          <div
            class="item column-left"
            [ngClass]="{
              selected: getModule(20).IsEnabled || isModuleLocked(20),
              locked: isModuleLocked(20),
              packageSelected: isModulePriceStrikethrough(20)
            }"
          >
            <div class="IconModuleInfo">
              <tool-tip-price
                [Tooltiptitle]="
                  moduleTooltipHtml('PriceCalculatorLink.MC' | translate, getModule(20).ModuleDescription)
                "
              >
              </tool-tip-price>
            </div>
            <div
              (click)="onModuleChange(20)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(20).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(20).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(20).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(20).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <span class="u-middle">{{ getModule(20).ModuleName }}</span>
                </span>
                <span class="price">
                  {{ getModule(20).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
            </div>
            <div
              (click)="onModuleChange(20)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="!companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(20).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(20).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(20).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(20).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>

                  <span class="u-middle">{{ getModule(20).ModuleName }}</span>
                </span>
                <span class="price">
                  {{ getModule(20).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
              <div class="switch-container centerContent">
                <div class="switch">
                  <span *ngIf="getModule(20).IsEnabled">
                    <app-icon [icon]="'SuccessWhite'"></app-icon>
                  </span>
                  <span *ngIf="!getModule(20).IsEnabled">
                    <app-icon [icon]="'circleplusWhite'"></app-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!--Bankoverførsler via NemKonto 1,-->
          <div
            class="item column-left"
            [ngClass]="{
              selected: getModule(13).IsEnabled || isModuleLocked(13),
              locked: isModuleLocked(13),
              packageSelected: isModulePriceStrikethrough(13)
            }"
          >
            <div class="IconModuleInfo">
              <tool-tip-price
                [Tooltiptitle]="
                  moduleTooltipHtml('PriceCalculatorLink.BrankTransfer' | translate, getModule(13).ModuleDescription)
                "
              ></tool-tip-price>
            </div>
            <div
              (click)="onModuleChange(13)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(13).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(13).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(13).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(13).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>

                  <span class="u-middle">{{ getModule(13).ModuleName }}</span>
                </span>
                <span class="price">
                  {{ getModule(13).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
            </div>
            <div
              (click)="onModuleChange(13)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="!companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(13).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(13).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(13).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(13).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>

                  <span class="u-middle">{{ getModule(13).ModuleName }}</span>
                </span>
                <span class="price">
                  {{ getModule(13).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
              <div class="switch-container centerContent">
                <div class="switch">
                  <span *ngIf="getModule(13).IsEnabled">
                    <app-icon [icon]="'SuccessWhite'"></app-icon>
                  </span>
                  <span *ngIf="!getModule(13).IsEnabled">
                    <app-icon [icon]="'circleplusWhite'"></app-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="item column-bronze centerContent"></div>

          <!--Advancerede medarbejderskabeloner 2,50-->
          <div
            class="item column-left"
            [ngClass]="{
              selected: getModule(4).IsEnabled || isModuleLocked(4),
              locked: isModuleLocked(4),
              packageSelected: isModulePriceStrikethrough(4)
            }"
          >
            <div class="IconModuleInfo">
              <tool-tip-price
                [Tooltiptitle]="
                  moduleTooltipHtml(
                    'PriceCalculatorLink.AdvancedPayrollManagement' | translate,
                    getModule(4).ModuleDescription
                  )
                "
              ></tool-tip-price>
            </div>
            <div
              (click)="onModuleChange(4)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(4).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(4).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(4).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(4).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <span class="u-middle">{{ getModule(4).ModuleName }}</span>
                </span>
                <span class="price">
                  {{ getModule(4).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
            </div>
            <div
              (click)="onModuleChange(4)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="!companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(4).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(4).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(4).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(4).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>

                  <span class="u-middle">{{ getModule(4).ModuleName }}</span>
                </span>
                <span class="price">
                  {{ getModule(4).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
              <div class="switch-container centerContent">
                <div class="switch">
                  <span *ngIf="getModule(4).IsEnabled">
                    <app-icon [icon]="'SuccessWhite'"></app-icon>
                  </span>
                  <span *ngIf="!getModule(4).IsEnabled">
                    <app-icon [icon]="'circleplusWhite'"></app-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="item column-bronze centerContent"></div>

          <!--Support via mail/chat -->
          <div
            class="item column-left"
            [ngClass]="{
              selected: getModule(7).IsEnabled || isModuleLocked(7),
              locked: isModuleLocked(7),
              packageSelected: isModulePriceStrikethrough(7)
            }"
          >
            <div class="IconModuleInfo">
              <tool-tip-price
                [Tooltiptitle]="
                  moduleTooltipHtml('PriceCalculatorLink.Support' | translate, getModule(7).ModuleDescription)
                "
              ></tool-tip-price>
            </div>
            <div
              (click)="onModuleChange(7)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(7).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(7).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(7).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(7).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <span class="u-middle">{{ getModule(7).ModuleName }}</span>
                </span>
                <span class="price">
                  <span>
                    {{ getModule(7).ModulePrice | PriceFormat: true | translate }}
                  </span>
                </span>
              </span>
            </div>
            <div
              (click)="onModuleChange(7)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="!companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(7).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(7).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(7).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(7).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>

                  <span class="u-middle">{{ getModule(7).ModuleName }}</span>
                </span>
                <span class="price">
                  <span>
                    {{ getModule(7).ModulePrice | PriceFormat: true | translate }}
                  </span>
                </span>
              </span>
              <div class="switch-container centerContent">
                <div class="switch">
                  <span *ngIf="getModule(7).IsEnabled">
                    <app-icon [icon]="'SuccessWhite'"></app-icon>
                  </span>
                  <span *ngIf="!getModule(7).IsEnabled">
                    <app-icon [icon]="'circleplusWhite'"></app-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="item column-bronze centerContent"></div>

          <!--Advanceret lønstyring 5,-->
          <div
            class="item column-left"
            [ngClass]="{
              selected: getModule(12).IsEnabled || isModuleLocked(12),
              locked: isModuleLocked(12),
              packageSelected: isModulePriceStrikethrough(12)
            }"
          >
            <div class="IconModuleInfo">
              <tool-tip-price
                [Tooltiptitle]="
                  moduleTooltipHtml(
                    'PriceCalculatorLink.EmployeeTemplates' | translate,
                    getModule(12).ModuleDescription
                  )
                "
              ></tool-tip-price>
            </div>
            <div
              (click)="onModuleChange(12)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(12).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(12).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(12).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(12).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <span class="u-middle">{{ getModule(12).ModuleName }}</span>
                </span>
                <span class="price">
                  {{ getModule(12).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
            </div>
            <div
              (click)="onModuleChange(12)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="!companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(12).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(12).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(12).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(12).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>

                  <span class="u-middle">{{ getModule(12).ModuleName }}</span>
                </span>
                <span class="price">
                  {{ getModule(12).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
              <div class="switch-container centerContent">
                <div class="switch">
                  <span *ngIf="getModule(12).IsEnabled">
                    <app-icon [icon]="'SuccessWhite'"></app-icon>
                  </span>
                  <span *ngIf="!getModule(12).IsEnabled">
                    <app-icon [icon]="'circleplusWhite'"></app-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="item column-bronze centerContent"></div>
          <div class="item column-silver centerContent"></div>

          <!--Tidsstyring 2,50-->
          <div
            class="item column-left"
            [ngClass]="{
              selected: getModule(8).IsEnabled || isModuleLocked(8),
              locked: isModuleLocked(8),
              packageSelected: isModulePriceStrikethrough(8)
            }"
          >
            <div class="IconModuleInfo">
              <tool-tip-price
                [Tooltiptitle]="
                  moduleTooltipHtml('PriceCalculatorLink.TimeEntry' | translate, getModule(8).ModuleDescription)
                "
              ></tool-tip-price>
            </div>
            <div
              (click)="onModuleChange(8)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(8).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(8).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(8).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(8).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <span class="u-middle">{{ getModule(8).ModuleName }}</span>
                </span>
                <span class="price">
                  {{ getModule(8).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
            </div>
            <div
              (click)="onModuleChange(8)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="!companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(8).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(8).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(8).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(8).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>

                  <span class="u-middle">{{ getModule(8).ModuleName }}</span>
                </span>
                <span class="price">
                  {{ getModule(8).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
              <div class="switch-container centerContent">
                <div class="switch">
                  <span *ngIf="getModule(8).IsEnabled">
                    <app-icon [icon]="'SuccessWhite'"></app-icon>
                  </span>
                  <span *ngIf="!getModule(8).IsEnabled">
                    <app-icon [icon]="'circleplusWhite'"></app-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="item column-bronze centerContent"></div>
          <div class="item column-silver centerContent"></div>

          <!--Godkendelsesflow til lønkærsler 2,50-->
          <div
            class="item column-left"
            [ngClass]="{
              selected: getModule(3).IsEnabled || isModuleLocked(3),
              locked: isModuleLocked(3),
              packageSelected: isModulePriceStrikethrough(3)
            }"
          >
            <div class="IconModuleInfo">
              <tool-tip-price
                [Tooltiptitle]="
                  moduleTooltipHtml('PriceCalculatorLink.ApprovalFlow' | translate, getModule(3).ModuleDescription)
                "
              ></tool-tip-price>
            </div>
            <div
              (click)="onModuleChange(3)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(3).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(3).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(3).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(3).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <span class="u-middle">{{ getModule(3).ModuleName }}</span>
                </span>
                <span class="price">
                  {{ getModule(3).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
            </div>
            <div
              (click)="onModuleChange(3)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="!companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(3).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(3).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(3).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(3).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>

                  <span class="u-middle">{{ getModule(3).ModuleName }}</span>
                </span>
                <span class="price">
                  {{ getModule(3).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
              <div class="switch-container centerContent">
                <div class="switch">
                  <span *ngIf="getModule(3).IsEnabled">
                    <app-icon [icon]="'SuccessWhite'"></app-icon>
                  </span>
                  <span *ngIf="!getModule(3).IsEnabled">
                    <app-icon [icon]="'circleplusWhite'"></app-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="item column-bronze centerContent"></div>
          <div class="item column-silver centerContent"></div>

          <!--Support via telefon-->
          <div
            class="item column-left"
            [ngClass]="{
              selected: getModule(17).IsEnabled || getModule(17).InfoOnly,
              locked: isModuleLocked(17),
              packageSelected: isModulePriceStrikethrough(17)
            }"
          >
            <div class="IconModuleInfo">
              <tool-tip-price
                [Tooltiptitle]="
                  moduleTooltipHtml('PriceCalculatorLink.Support' | translate, getModule(17).ModuleDescription)
                "
              ></tool-tip-price>
            </div>
            <div
              (click)="onModuleChange(17)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(17).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(17).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(17).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(17).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <span class="u-middle">{{ getModule(17).ModuleName }}</span>
                </span>
                <span class="price">
                  {{ getModule(17).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
            </div>

            <div
              (click)="onModuleChange(17)"
              class="appHolder appHolder-AddonInfo"
              *ngIf="!companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="getModule(17).FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.FreeFeature' | translate) + ' ' + (getModule(17).FreeUntilDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="getModule(17).ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (getModule(17).ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>

                  <span class="u-middle">{{ getModule(17).ModuleName }}</span>
                </span>
                <span class="price">
                  {{ getModule(17).ModulePrice | PriceFormat: true | translate }}
                </span>
              </span>
              <div class="switch-container centerContent">
                <div class="switch">
                  <span *ngIf="getModule(17).IsEnabled">
                    <app-icon [icon]="'SuccessWhite'"></app-icon>
                  </span>
                  <span *ngIf="!getModule(17).IsEnabled">
                    <app-icon [icon]="'circleplusWhite'"></app-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="item column-bronze row-bottom centerContent"></div>
          <div class="item column-silver row-bottom centerContent"></div>
        </div>
        <div class="grid extra-modules">
          <div class="item row-top column-left">{{ 'Price.ExtraModules' | translate }}</div>
          <div
            *ngFor="let module of this.getPackage(9999).Modules"
            class="item column-left"
            [ngClass]="{
              selected: module.IsEnabled || isModuleLocked(module.ModuleId),
              locked: isModuleLocked(module.ModuleId),
              packageSelected: isModulePriceStrikethrough(module.ModuleId)
            }"
          >
            <div class="IconExtraModuleInfo">
              <tool-tip-price [Tooltiptitle]="module.ModuleDescription"></tool-tip-price>
            </div>
            <div
              (click)="onModuleChange(module.ModuleId)"
              class="appHolder appHolder-AddonExtraInfo"
              *ngIf="companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="module.FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="('Price.FreeFeature' | translate) + ' ' + (module.FreeUntilDate | date: 'dd/MM/yyyy')"
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="module.ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (module.ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <span class="u-middle">{{ module.ModuleName }}</span>
                </span>
                <span class="price priceFree">
                  {{ module.ModulePrice | PriceFormat: true | translate }} {{ module.ModuleBillingPrinciple }}
                </span>
              </span>
            </div>
            <div
              (click)="onModuleChange(module.ModuleId)"
              class="appHolder appHolder-AddonExtraInfo"
              *ngIf="!companyModuleService.isMobile"
            >
              <span>
                <span class="description">
                  <div
                    *ngIf="module.FreeUntilDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="('Price.FreeFeature' | translate) + ' ' + (module.FreeUntilDate | date: 'dd/MM/yyyy')"
                  >
                    <svg aria-hidden="true" class="Icon Icon--warning" ng-reflect-ng-class="Icon  Icon--warning">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>
                  <div
                    *ngIf="module.ScheduledDeactivationDate"
                    class="GetHelp FormElement-cursorHand"
                    (click)="$event.stopPropagation()"
                    kendoTooltip
                    [title]="
                      ('Price.SheduledDeactivationFeature' | translate) +
                      ' ' +
                      (module.ScheduledDeactivationDate | date: 'dd/MM/yyyy')
                    "
                  >
                    <svg aria-hidden="true" class="Icon Icon--error" ng-reflect-ng-class="Icon  Icon--error">
                      <use xlink:href="#error-exclamation"></use>
                    </svg>
                  </div>

                  <span class="u-middle">{{ module.ModuleName }}</span>
                </span>
                <span class="price priceFree">
                  {{ module.ModulePrice | PriceFormat: true | translate }} {{ module.ModuleBillingPrinciple }}
                </span>
              </span>
              <div class="switch-container centerContent">
                <div class="switch">
                  <span *ngIf="module.IsEnabled || isModuleLocked(module.ModuleId)">
                    <app-icon [icon]="'SuccessWhite'"></app-icon>
                  </span>
                  <span *ngIf="!module.IsEnabled && !isModuleLocked(module.ModuleId)">
                    <app-icon [icon]="'circleplusWhite'"></app-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="small-note"
          [innerHTML]="'Price.TelefoneSupportWarningNote' | translate: { SystemAlias: branding.SystemAlias }"
        ></div>
      </div>
      <div class="sticky-parent-helper">
        <price-summary
          class="sidebar"
          [(usersMonthly)]="companyModuleService.usersMonthly"
          [(users14Day)]="companyModuleService.users14Day"
          [(usersWeekly)]="companyModuleService.usersWeekly"
          [priceElements]="priceElements"
          [totalCost]="totalCost"
          [submitVisible]="true"
          [submitEnabled]="submitEnabled"
          (change)="updateTotal()"
          (submit)="onSubmit()"
          [pricePerPayslip]="calculatedPricePerPayslip"
        >
        </price-summary>
      </div>
    </div>
  </div>
</app-fixed-panel>
<app-dialog class="blue-dialog" [(visible)]="AutoSelectPackageActive">
  <div [innerHTML]="'Price.AutoSelectHint' | translate"></div>

  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
