import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { CustomControlsModule } from '../custom-controls/custom-controls.module';

import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { AccountantComponent } from './accountant.component';
import { AccountantAddNewCompanyDialogComponent } from './companies/accountant-add-new-company-dialog.component';
import { AccountantCompaniesComponent } from './companies/accountant-companies.component';
import { AccountantConfigurationComponent } from './configuration/accountant-configuration.component';
import { AccountantJoinDialogComponent } from './join/accountant-join-dialog.component';
import { AccountantJoinComponent } from './join/accountant-join.component';
import { AccountantLeaveDialogComponent } from './summary/accountant-leave-dialog.component';
import { AccountantSummaryComponent } from './summary/accountant-summary.component';
import { AccountantUsersComponent } from './users/accountant-users.component';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule, CustomControlsModule, SharedComponentsModule],
  declarations: [
    AccountantComponent,
    AccountantAddNewCompanyDialogComponent,
    AccountantCompaniesComponent,
    AccountantJoinComponent,
    AccountantJoinDialogComponent,
    AccountantSummaryComponent,
    AccountantLeaveDialogComponent,
    AccountantUsersComponent,
    AccountantConfigurationComponent
  ],
  exports: [AccountantComponent, AccountantJoinComponent, AccountantSummaryComponent],
  entryComponents: [AccountantComponent]
})
export class AccountantModule {}
