import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Constants } from '../../common/constants';
import { FormComponentBase } from '../../common/form-component-base';
import { Global } from '../../common/global';
import { NumericTextBoxOptions } from '../../custom-controls/numeric-edit/numeric-text-box-options';
import {
  IMonth,
  IVacationProvider,
  IVacationSupplementPayoutMethodDK,
  IVacationType
} from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { VacationModel } from './vacation-model';

@Component({
  selector: 'app-vacation',
  templateUrl: './vacation.component.html'
})
export class VacationComponent extends FormComponentBase implements OnInit {
  @Input() public disable: boolean;
  @Input() public disableBySecondaryIncomeType: boolean;
  @Input() public get model(): VacationModel {
    if (!this.modelValue) {
      this.modelValue = {} as any;
    }

    if (
      this.modelValue.AdditionalVacationTerminationMethod === null ||
      this.modelValue.AdditionalVacationTerminationMethod === undefined
    ) {
      this.modelValue.AdditionalVacationTerminationMethod = 1;
    }

    return this.modelValue;
  }

  public set model(value: VacationModel) {
    this.modelValue = value;

    this.vacationExtraPercentageTypeCheckbox =
      this.isVisibleVacationExtraPercentageCheckbox && this.model.VacationExtraPercentage === 0.12 ? true : false;

    this.childIsValid = Array(13).fill(true);
    this.childIsDirty = Array(13).fill(false);
  }

  public get branding() {
    return environment.branding;
  }

  public vacationTypes: IVacationType[];
  public vacationProviders: IVacationProvider[];
  public ownCompanyKey = 'Self';
  public months: IMonth[] | IVacationSupplementPayoutMethodDK[];
  public additionalVacations: any;
  public NumericOptions: NumericTextBoxOptions = { format: 'n2', decimals: 2, step: 1, min: null, spinners: false };

  private modelValue: VacationModel;
  public originalVal: any;
  public vacationProviderWarningDialogVisible = false;
  public vacationProviderChangeWarningVisible = false;
  public omitVacationReportingLink = 'https://skat.dk/skat.aspx?oid=2287087&chk=216867';

  private vacationExtraPercentageTypeCheckboxValue = false;
  public get vacationExtraPercentageTypeCheckbox(): boolean {
    return this.vacationExtraPercentageTypeCheckboxValue;
  }

  public set vacationExtraPercentageTypeCheckbox(value: boolean) {
    this.vacationExtraPercentageTypeCheckboxValue = value;
    if (this.isVisibleVacationExtraPercentageCheckbox) {
      this.model.VacationExtraPercentage = value ? 0.12 : null;
    }
  }

  constructor(
    private staticDataService: StaticDataService,
    public sessionService: SessionService,
    public dataService: DataService
  ) {
    super();
    this.childIsValid = Array(13).fill(true);
    this.childIsDirty = Array(13).fill(false);
  }

  public ngOnInit(): void {
    this.staticDataService.VacationProvider.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IVacationProvider[]) => (this.vacationProviders = data)
    );
    if (this.isDenmarkCompany) {
      this.staticDataService.VacationSupplementPayoutMethodDK.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        (data: IMonth[]) => {
          this.months = data;
        }
      );
    } else {
      this.staticDataService.Month.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IMonth[]) => {
        const months: IMonth[] = [];
        const april: IMonth = data ? data.find((month: IMonth) => month.Id === 4) : undefined;
        const may: IMonth = data ? data.find((month: IMonth) => month.Id === 5) : undefined;
        months[0] = april;
        months[1] = may;
        this.months = months;
      });
    }

    if (this.isSwedishCompany) {
      this.staticDataService.VacationTypeSE.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        (data: IVacationType[]) => (this.vacationTypes = data)
      );
    } else {
      this.staticDataService.VacationType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        (data: IVacationType[]) => (this.vacationTypes = data)
      );
      this.staticDataService.AdditionalVacations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        (data: any) => (this.additionalVacations = data)
      );
    }

    this.editModeChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((editMode: boolean) => {
      if (editMode) {
        this.originalVal = this.model.VacationProviderId;
      }
    });

    this.isDirtyChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((dirty: boolean) => {
      if (!dirty) {
        this.model.VacationProviderId = this.originalVal;
      }
    });
  }

  public get IsNoneVacation(): boolean {
    return !this.model || this.model.VacationTypeId < 0;
  }

  public get IsPaidVacation(): boolean {
    return this.model && this.model.VacationTypeId === 1 && !this.isSwedishCompany;
  }

  public get IsPaidVacation_Denmark(): boolean {
    return this.model && this.model.VacationTypeId === 1 && this.isDenmarkCompany;
  }

  public get IsExtraVacation(): boolean {
    return this.model && this.model.VacationTypeId === 2 && this.isSwedishCompany;
  }

  public get IsPaidVacation_GreendLand(): boolean {
    if (this.IsGreenlandCompany && this.model.VacationTypeId !== -1) {
      return true;
    }
    return false;
  }

  public get IsPaidVacation_Swedish(): boolean {
    if (this.isSwedishCompany && this.model.VacationTypeId === 1) {
      return true;
    }
    return false;
  }

  public get isVisibleVacationExtraPercentageCheckbox(): boolean {
    return this.isDenmarkCompany && this.model && this.model.VacationTypeId === 2;
  }

  public get VacationSupplementPayoutMonthLabel() {
    if (this.isDenmarkCompany) {
      return 'EmployeeEmployment.AnnualSupplementPaidOutInDK';
    }
    return 'EmployeeEmployment.AnnualSupplementPaidOutIn';
  }

  public get IsVacationReduceSalaryDisable(): boolean {
    return !this.model || !this.model.VacationTypeId || this.model.VacationTypeId === 1;
  }

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public get isDenmarkCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
  }

  public get isSwedishCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
  }

  public get VacationExtraPercentageLabel(): string {
    if (this.IsGreenlandCompany) {
      return 'EmployeeEmployment.EarningPercentage';
    } else if (this.isSwedishCompany) {
      return 'EmployeeEmployment.VacationSupplement';
    }
    return 'EmployeeEmployment.AnnualSupplement';
  }

  public onComboboxVacationTypeChangedEvent(): void {
    this.updateDefaultValueForPaidVacationType();
  }

  public get isUseContinuousVacationReportingVisible(): boolean {
    return (
      this.IsPaidVacation &&
      this.isDenmarkCompany &&
      this.sessionService.currentState.includes('tabs.employee') &&
      !this.isSwedishCompany
    );
  }

  private updateDefaultValueForPaidVacationType(): void {
    if (this.model && this.model.VacationTypeId === 1) {
      // Paid vacation type.
      this.model.VacationReduceSalary = true;
      this.model.VacationSupplementPayoutMonth = this.model.VacationSupplementPayoutMonth
        ? this.model.VacationSupplementPayoutMonth
        : 5; // 5 - May.
      this.model.VacationExtraPercentage = this.model.VacationExtraPercentage
        ? this.model.VacationExtraPercentage
        : 0.01; // 1 percent.
    }

    if (this.IsGreenlandCompany /*&& this.model && this.model.VacationTypeId === 2*/) {
      this.model.VacationExtraPercentage = 0.12;
    }

    this.onChange();
  }

  public onComboboxVacationProviderValueChange(): void {
    const vacationProvider: IVacationProvider = this.vacationProviders.find(
      (model: IVacationProvider) => model.Id === this.model.VacationProviderId
    );
    if (
      this.originalVal &&
      this.model.VacationProviderId !== undefined &&
      this.originalVal !== this.model.VacationProviderId
    ) {
      this.vacationProviderChangeWarningVisible = true;
      if (vacationProvider && vacationProvider.Key === this.ownCompanyKey) {
        this.vacationProviderWarningDialogVisible = true;
      }
    } else if (vacationProvider && vacationProvider.Key === this.ownCompanyKey) {
      this.vacationProviderWarningDialogVisible = true;
    } else {
      this.originalVal = this.model.VacationProviderId;
      this.onChange();
    }
  }

  public onVacationProviderWarningDialogAction(action: string): void {
    if (action === 'Proceed' || action === 'Understood') {
      this.originalVal = this.model.VacationProviderId;
    } else {
      this.model.VacationProviderId = this.originalVal;
    }

    this.onChange();
  }
}
