import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotTranslatedService {
  public notTranslated(key: string): void {
    // console.log('Not translated', key);
  }
}
