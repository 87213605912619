import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISalaryBatch } from '../../../../services/api/api-model';
import { CompanySalaryBatchService } from '../../company-salary-batches.service';

@Component({
  selector: 'app-salary-batch-salary-totals',
  templateUrl: './salary-batch-salary-totals.component.html',
  styleUrls: ['./salary-batch-salary-totals.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SalaryBatchSalaryTotalsComponent implements OnDestroy {
  @Input() public showTitle = true;

  private salaryBatchIdValue: number;
  public get SalaryBatchId(): number {
    return this.salaryBatchIdValue;
  }

  public set SalaryBatchId(value: number) {
    if (value !== this.salaryBatchIdValue) {
      this.salaryBatchIdValue = value;

      this.companySalaryBatchService.loadSalaryTypeTotals(value);
      this.companySalaryBatchService.loadUnitTotals(value);
    }
  }

  public view: 'salarytypes' | 'units' = 'salarytypes';

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public viewModeChange() {
    this.companySalaryBatchService.viewSalaryTotalModeChange.emit(true);
  }

  constructor(public companySalaryBatchService: CompanySalaryBatchService) {
    this.companySalaryBatchService.selectedSalaryBatch
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: ISalaryBatch | any) => {
        this.SalaryBatchId = value ? value.Id : null;
      });
  }
}
