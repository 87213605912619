import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import {
  ICompanyUser,
  IIntegration,
  ISalaryStatement,
  IUserEmployment,
  ProtectedOperationRequest
} from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-revert-payslip-dialog',
  templateUrl: './revert-payslip-dialog.component.html'
})
export class RevertPayslipDialogComponent implements OnInit, OnDestroy {
  @Input() public salaryStatement: ISalaryStatement;
  @Input() public employee: ICompanyUser;

  private currentEmployeeId: number;
  @Input()
  public get employeeId(): number {
    return this.currentEmployeeId;
  }

  public set employeeId(value: number) {
    if (value && this.employeeId !== value) {
      this.currentEmployeeId = value;
      this.reloadEmployee();
    }
  }

  public get branding() {
    return environment.branding;
  }

  public reloadEmployee(): void {
    this.dataService.Employments_GetEmployment(this.employeeId).subscribe((model: IUserEmployment) => {
      this.employee = model.CompanyUser;
    });
  }

  private isVisibleValue = false;
  @Input()
  public get isVisible(): boolean {
    return this.isVisibleValue;
  }
  public set isVisible(value: boolean) {
    if (this.isVisibleValue !== value) {
      this.isVisibleValue = value;
      this.isVisibleChange.emit(value);
    }
  }
  @Output() public isVisibleChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() public revertCompleted: EventEmitter<any> = new EventEmitter<any>();

  public isVisibleUnderStood = false;
  public resetPasswordModel: string;

  public get noEmail(): boolean {
    return !this.employee || (!this.employee.CompanyEmail && !this.employee.PersonalEmail);
  }

  public get isExternalSystemRelevant(): boolean {
    // GS-3436
    if (this.integrationName) {
      return true;
    }
    return false;
  }

  public get ExternalSystemRevertMessage(): string {
    // GS-3436
    return this.isExternalSystemRelevant && this.revertBatchExtSystemMessage
      ? this.revertBatchExtSystemMessage.replace('{externalSystem.Name}', this.integrationName)
      : '';
  }

  public get isGreenland(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  private revertBatchExtSystemMessage: string;
  public IntergrationOpation: any[];
  private ExportNewPayrollBatchId: number;
  public Integration2Operation: any[];
  public integrationName: string;
  public userName: string;

  constructor(
    private translate: TranslateService,
    private dataService: DataService,
    private sessionService: SessionService,
    private staticDataService: StaticDataService
  ) {}

  public ngOnInit(): void {
    this.userName = localStorage.getItem('saveLoginUserName');
    this.translateText();

    this.staticDataService.ExternalSystemOperation.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any[]) => {
      this.IntergrationOpation = data;
      const objectfillter: any = this.IntergrationOpation.find((model: any) => model.Key === 'ExportNewPayrollBatch');
      if (objectfillter) {
        this.ExportNewPayrollBatchId = objectfillter.Id;
        if (this.ExportNewPayrollBatchId) {
          if (this.sessionService.currentState.includes('company')) {
            this.staticDataService.GetIntegration2Operation(this.ExportNewPayrollBatchId);
          }
          this.staticDataService.getNavChangeEmitter().subscribe((valueIntegration: any[]) => {
            if (valueIntegration && valueIntegration.length > 0) {
              this.Integration2Operation = valueIntegration;
              this.integrationName = valueIntegration[0].Name;
            }
          });
        }
      }
    });
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public revertModalAction(action: string): void {
    if (action === 'RevertBatch') {
      this.isVisibleUnderStood = true;
    }
  }

  public revertModalUnderStoodAction(action: string): void {
    if (action === 'Proceed') {
      const request: ProtectedOperationRequest = { Password: this.resetPasswordModel };
      this.dataService.SalaryStatements_RevertSalaryStatement(this.salaryStatement.Id, request).subscribe(
        (): void => {
          this.revertCompleted.emit();
          this.onCancel();
        },
        (e: any) => {
          console.error(e);
        }
      );
    } else {
      this.onCancel();
    }
  }

  private translateText(): void {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translate
        .get(['RevertPayslip.RevertConfirmationResult7'])
        .subscribe((translations: { [key: string]: string }) => {
          this.revertBatchExtSystemMessage = translations['RevertPayslip.RevertConfirmationResult7'];
        });
    });
  }

  private onCancel(): void {
    this.employeeId = undefined;
    this.employee = undefined;
    this.isVisible = false;
    this.isVisibleUnderStood = false;
  }
}
