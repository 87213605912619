import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { CustomControlsModule } from '../custom-controls/custom-controls.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';

import { CompanyApprovalModule } from './approval/company-approval.module';
import { CompanyBalancesModule } from './balances/company-balances.module';
import { CompanyConfigurationModule } from './configuration/company-configuration.module';
import { CompanyGeneralModule } from './general/company-general.module';
import { CompanyExtraModule } from './modules/company-extra.module';
import { CompanyPayrollDataModule } from './payroll-data/company-payroll-data.module';
import { CompanySalaryBatchesModule } from './salary-batches/company-salary-batches.module';

import { CompanyComponent } from './company.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CustomControlsModule,
    SharedComponentsModule,
    CompanyApprovalModule,
    CompanyBalancesModule,
    CompanyConfigurationModule,
    CompanyGeneralModule,
    CompanyExtraModule,
    CompanyPayrollDataModule,
    CompanySalaryBatchesModule
  ],
  declarations: [CompanyComponent],
  exports: [CompanyComponent],
  entryComponents: [CompanyComponent]
})
export class CompanyModule {}
