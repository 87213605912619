import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IEIncomeResponseLine, ISalaryBatch } from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { SessionService } from '../../../services/session/session.service';
import { CompanySalaryBatchService } from '../company-salary-batches.service';
import { SalaryBatchViewModel } from '../salary-batch-view-model';
import { SalaryBatchErrorsView } from './salary-batch-errors-view';

@Component({
  selector: 'app-salary-batch-errors-dialog',
  templateUrl: './salary-batch-errors-dialog.component.html'
})
export class SalaryBatchErrorsDialogComponent implements OnDestroy {
  @Input() public isTab = true;

  private salaryBatch: SalaryBatchViewModel;

  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public refreshSource: EventEmitter<void> = new EventEmitter<void>();
  @Output() public resendBatch: EventEmitter<number> = new EventEmitter<number>();

  public clearErrorsDialogVisible = false;

  public get branding() {
    return environment.branding;
  }

  public get isFinalizedOrRevert(): boolean {
    return this.salaryBatch && (this.salaryBatch.StatusId === 40 || this.salaryBatch.StatusId === 90);
  }

  constructor(
    private dataService: DataService,
    public sessionService: SessionService,
    private translateService: TranslateService,
    public companySalaryBatchService: CompanySalaryBatchService
  ) {
    this.companySalaryBatchService.selectedSalaryBatch
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: ISalaryBatch | any) => {
        this.salaryBatch = value;
      });
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onDialogAction(action: string): void {
    switch (action) {
      case 'ClearErrors':
        this.clearErrorsDialogVisible = true;
        break;
      case 'Resend':
        this.resendBatch.emit(this.salaryBatch.Id);
        break;
    }
  }

  public clearErrorsDialogAction(action: string): void {
    if (action === 'Proceed') {
      this.dataService.SalaryBatches_ClearEIncomeErrors(this.salaryBatch.Id).subscribe(() => {
        this.refreshSource.emit();
        this.visible = false;
      });
    }
  }
}
