import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { ICompanyUser, ISalaryStatement, IUserEmployment, ProtectedOperationRequest } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
var RevertPayslipDialogComponent = /** @class */ (function () {
    function RevertPayslipDialogComponent(translate, dataService, sessionService, staticDataService) {
        this.translate = translate;
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.isVisibleValue = false;
        this.isVisibleChange = new EventEmitter();
        this.revertCompleted = new EventEmitter();
        this.isVisibleUnderStood = false;
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(RevertPayslipDialogComponent.prototype, "employeeId", {
        get: function () {
            return this.currentEmployeeId;
        },
        set: function (value) {
            if (value && this.employeeId !== value) {
                this.currentEmployeeId = value;
                this.reloadEmployee();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RevertPayslipDialogComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    RevertPayslipDialogComponent.prototype.reloadEmployee = function () {
        var _this = this;
        this.dataService.Employments_GetEmployment(this.employeeId).subscribe(function (model) {
            _this.employee = model.CompanyUser;
        });
    };
    Object.defineProperty(RevertPayslipDialogComponent.prototype, "isVisible", {
        get: function () {
            return this.isVisibleValue;
        },
        set: function (value) {
            if (this.isVisibleValue !== value) {
                this.isVisibleValue = value;
                this.isVisibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RevertPayslipDialogComponent.prototype, "noEmail", {
        get: function () {
            return !this.employee || (!this.employee.CompanyEmail && !this.employee.PersonalEmail);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RevertPayslipDialogComponent.prototype, "isExternalSystemRelevant", {
        get: function () {
            // GS-3436
            if (this.integrationName) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RevertPayslipDialogComponent.prototype, "ExternalSystemRevertMessage", {
        get: function () {
            // GS-3436
            return this.isExternalSystemRelevant && this.revertBatchExtSystemMessage
                ? this.revertBatchExtSystemMessage.replace('{externalSystem.Name}', this.integrationName)
                : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RevertPayslipDialogComponent.prototype, "isGreenland", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    RevertPayslipDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userName = localStorage.getItem('saveLoginUserName');
        this.translateText();
        this.staticDataService.ExternalSystemOperation.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.IntergrationOpation = data;
            var objectfillter = _this.IntergrationOpation.find(function (model) { return model.Key === 'ExportNewPayrollBatch'; });
            if (objectfillter) {
                _this.ExportNewPayrollBatchId = objectfillter.Id;
                if (_this.ExportNewPayrollBatchId) {
                    if (_this.sessionService.currentState.includes('company')) {
                        _this.staticDataService.GetIntegration2Operation(_this.ExportNewPayrollBatchId);
                    }
                    _this.staticDataService.getNavChangeEmitter().subscribe(function (valueIntegration) {
                        if (valueIntegration && valueIntegration.length > 0) {
                            _this.Integration2Operation = valueIntegration;
                            _this.integrationName = valueIntegration[0].Name;
                        }
                    });
                }
            }
        });
    };
    RevertPayslipDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    RevertPayslipDialogComponent.prototype.revertModalAction = function (action) {
        if (action === 'RevertBatch') {
            this.isVisibleUnderStood = true;
        }
    };
    RevertPayslipDialogComponent.prototype.revertModalUnderStoodAction = function (action) {
        var _this = this;
        if (action === 'Proceed') {
            var request = { Password: this.resetPasswordModel };
            this.dataService.SalaryStatements_RevertSalaryStatement(this.salaryStatement.Id, request).subscribe(function () {
                _this.revertCompleted.emit();
                _this.onCancel();
            }, function (e) {
                console.error(e);
            });
        }
        else {
            this.onCancel();
        }
    };
    RevertPayslipDialogComponent.prototype.translateText = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            _this.translate
                .get(['RevertPayslip.RevertConfirmationResult7'])
                .subscribe(function (translations) {
                _this.revertBatchExtSystemMessage = translations['RevertPayslip.RevertConfirmationResult7'];
            });
        });
    };
    RevertPayslipDialogComponent.prototype.onCancel = function () {
        this.employeeId = undefined;
        this.employee = undefined;
        this.isVisible = false;
        this.isVisibleUnderStood = false;
    };
    return RevertPayslipDialogComponent;
}());
export { RevertPayslipDialogComponent };
