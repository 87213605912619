<div class="Cards Cards--flex Company-total">
  <div class="Card Form--stacked">
    <div class="Card-inner Card-inner--left Card-inner--bottom">
      <div class="FormElement">
        <div class="grid-header Company-salaryTotalsGrid grid-click">
          <app-grid
            (selectedItemChange)="onSelectedSalaryTypeTotalChange($event)"
            [data]="SalaryTypeTotals"
            [idColumn]="'SalaryTypeId'"
            [(selectedItem)]="selectedSalaryTypeTotal"
            [editMode]="false"
            [pageable]="true"
            [pageSize]="100"
            [miniPager]="true"
          >
            <app-grid-column
              [field]="'Description'"
              [width]="200"
              [title]="'CompanySalaryBatches.TotalsGridField_Type' | translate"
              [type]="'text'"
            ></app-grid-column>
            <app-grid-column
              [field]="'AmountSum'"
              [width]="100"
              [title]="'CompanySalaryBatches.TotalsGridField_Amount' | translate"
              [type]="'numeric'"
            ></app-grid-column>
          </app-grid>
        </div>
      </div>
    </div>
  </div>

  <div class="Card Form--stacked Card-total--right">
    <div class="Card-inner Card-inner--right">
      <div class="FormElement">
        <div class="FormElement-grid Company-salaryTotalsGrid Custoomlinewqual">
          <app-grid [data]="salaryTypePerEmployee" [editMode]="false" (dblClick)="onDoubleClick($event)">
            <app-grid-column
              [field]="'FullName'"
              [width]="120"
              [title]="'CompanySalaryBatches.ReimbursementsGridField_Employee' | translate"
              [type]="'text'"
            >
            </app-grid-column>
            <app-grid-column
              [field]="'AmountSum'"
              [width]="80"
              [title]="'CompanySalaryBatches.ReimbursementsGridField_Amount' | translate"
              [type]="'numeric'"
            >
            </app-grid-column>
            <app-grid-column
              [field]="'SalaryTypeName'"
              [width]="100"
              [title]="'CompanySalaryBatches.ReimbursementsGridField_Description' | translate"
              [type]="'text'"
            >
            </app-grid-column>
          </app-grid>
        </div>
      </div>
    </div>
  </div>
</div>
