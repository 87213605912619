<div class="FormElement" [ngClass]="{ 'FormElement-ViewMode': editMode }">
  <span class="FormElement-label" *ngIf="isLabelHtml && label" [innerHTML]="label"></span>
  <span class="FormElement-label" *ngIf="!isLabelHtml && label">
    <span [ngClass]="{ textWithTooltip: helpTooltip }" kendoTooltip [title]="helpTooltip">{{ label }}:</span>
  </span>
  <div class="FormElement-control">
    <div class="FormElement-static {{ viewClass }}" *ngIf="!editMode">{{ value | DateFormat }}</div>
    <dx-date-box
      [id]="e2e + '_DatePicker'"
      #dxDatePickerControl
      *ngIf="editMode"
      [type]="type"
      [showAnalogClock]="false"
      class="FormElement-datePicker"
      [(value)]="value"
      [min]="minDate"
      [opened]="isOpened"
      (onValueChanged)="onValueChanged($event)"
      [disabled]="disable"
      [acceptCustomValue]="true"
      [displayFormat]="format"
      (onFocusIn)="onfocus($event)"
      (onFocusOut)="onBlur($event)"
      (onClick)="onfocus($event)"
      (onKeyDown)="onKeyDown($event)"
      (onClosed)="onDxDatePickerClosed($event)"
      (onKeyPress)="onkeypress($event)"
      [applyValueMode]="'instantly'"
      pickerType="{{ pickerType }}"
      [ngClass]="{ 'input-required': !isNonRequired }"
    >
      <dxi-button
        *ngIf="sessionService.browser.isMobile"
        [name]="e2e + 'show'"
        location="before"
        [options]="showCalendarButtonConfig"
      ></dxi-button>
      <dxi-button
        *ngIf="sessionService.browser.isMobile"
        [name]="e2e + 'delete'"
        location="before"
        [options]="deleteValueButtonConfig"
      ></dxi-button>
    </dx-date-box>
    <div class="validation-texteditor k-widget k-tooltip k-tooltip-validation" *ngIf="showValidationMessage">
      {{ validationText }}
    </div>
  </div>
</div>
