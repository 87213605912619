import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ISalaryBatch } from '../../../services/api/api-model';
import { CompanySalaryBatchService } from '../company-salary-batches.service';
import { SalaryBatchViewModel } from '../salary-batch-view-model';

@Component({
  selector: 'app-batch-finalization-step-3-dialog',
  templateUrl: './batch-finalization-step-3-dialog.component.html'
})
export class BatchFinalizationStep3DialogComponent implements OnDestroy {
  constructor(private companySalaryBatchService: CompanySalaryBatchService) {
    this.companySalaryBatchService.selectedSalaryBatch
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: ISalaryBatch | any) => {
        this.salaryBatch = value;
      });
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private salaryBatch: SalaryBatchViewModel;

  @Input() public autorecalculate = false;

  @Input()
  public get isClose(): boolean {
    return this.visible;
  }
  public set isClose(value: boolean) {
    if (value) {
      this.visible = false;
    }
  }

  private doRecalculationValue: boolean;
  @Input()
  public get doRecalculation(): boolean {
    return this.doRecalculationValue;
  }
  public set doRecalculation(value: boolean) {
    if (this.doRecalculationValue !== value) {
      this.doRecalculationValue = value;
      setTimeout(() => this.doRecalculationChange.emit(value));
    }
  }
  @Output() public doRecalculationChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      setTimeout(() => this.visibleChange.emit(value));
      if (value) {
        const draftSalaryBatch: boolean = this.salaryBatch.StatusId === 10 || this.salaryBatch.StatusId === 20;
        const finalizedSalaryBatch: boolean = this.salaryBatch.StatusId === 40;

        if (finalizedSalaryBatch) {
          this.visibleValue = false;
          return;
        } else {
          this.doRecalculation = true;
          this.stepCompleted = true;
          this.visibleValue = false;
        }
      }
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private stepCompletedValue: boolean;
  // @Input()
  public get stepCompleted(): boolean {
    return this.stepCompletedValue;
  }
  public set stepCompleted(value: boolean) {
    if (this.stepCompletedValue !== value) {
      setTimeout(() => this.stepCompletedChange.emit(value));
    }
  }

  @Output() public stepCompletedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public finalizationExit: EventEmitter<void> = new EventEmitter<void>();

  public recalculateBeforeFinalizing = false;

  public onDialogAction(action: string): void {
    if (action === 'Proceed') {
      this.doRecalculation = this.recalculateBeforeFinalizing;
      this.stepCompleted = true;
    } else {
      this.finalizationExit.emit();
    }
  }
}
