import { HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SessionService } from './session/session.service';
import * as i0 from "@angular/core";
import * as i1 from "./session/session.service";
var LogInterceptor = /** @class */ (function () {
    function LogInterceptor(service) {
        this.service = service;
    }
    LogInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        if (this.service.requestLogEnabled) {
            return next.handle(req).pipe(tap(function (event) {
                if (event instanceof HttpResponse) {
                    var entry = {
                        method: req.method,
                        url: req.urlWithParams,
                        payload: req.body,
                        status: event.status,
                        response: event.body
                    };
                    _this.service.logRequest(entry);
                }
            }), catchError(function (err, caught) {
                var entry = {
                    method: req.method,
                    url: req.urlWithParams,
                    payload: req.body,
                    status: err.status
                };
                _this.service.logRequest(entry);
                return throwError(err);
            }));
        }
        else {
            return next.handle(req);
        }
    };
    LogInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LogInterceptor_Factory() { return new LogInterceptor(i0.ɵɵinject(i1.SessionService)); }, token: LogInterceptor, providedIn: "root" });
    return LogInterceptor;
}());
export { LogInterceptor };
