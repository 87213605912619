<div *ngIf="hasDepartmentData">
  <label class="FormElement-label u-hiddenVisually" for="comboboxDepartment">
    {{ 'Employee.SearchByDepartment' | translate }}
  </label>

  <app-combobox-edit
    [disable]="employeeService.editMode"
    [editMode]="true"
    [textField]="'Text'"
    [idField]="'Id'"
    id="comboboxDepartment"
    [comboboxDataSource]="departmentDataSource"
    [(value)]="employeeService.departmentId"
    [keepDataSourceOrder]="true"
  ></app-combobox-edit>
</div>
