import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CellCloseEvent } from '@progress/kendo-angular-grid/dist/es2015/editing/cell-close-event';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from 'src/app/common/form-component-base';
import { Global } from 'src/app/common/global';
import { ILanguage, ISalaryType, ISalaryTypeTranslation } from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-salary-type-translation-dialog',
  templateUrl: './salary-type-translation-dialog.component.html'
})
export class SalaryTypeTranslationDialogComponent extends FormComponentBase implements OnInit {
  @Input() public salaryType: ISalaryType;

  public visibleValue = false;
  @Input()
  get visible(): boolean {
    return this.visibleValue;
  }
  set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
      if (value) {
        this.prepareTranslationData(this.salaryType);
      }
    }
  }
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public translateComplete: EventEmitter<void> = new EventEmitter<void>();

  public gridData: any[] = [];

  constructor(
    private dataService: DataService,
    private sessionService: SessionService,
    public staticDataService: StaticDataService
  ) {
    super();
    this.childIsDirty = Array(1).fill(false);
  }

  public get branding() {
    return environment.branding;
  }

  public ngOnInit(): void {}

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  public onDialogAction(action: string): void {
    if (action === 'Update') {
      this.updateSalaryTypeTranslation();
    }
  }

  private inheritClass = 'grid-inherit-cell';
  public NameClass = '';
  public DescriptionClass = '';
  public DefaultPayslipTextClass = '';
  public ExplanationClass = '';
  public triggerUpdate: boolean;

  public onSaveChange(value: CellCloseEvent): void {
    this.childIsDirty[0] = true;
  }

  private prepareTranslationData(salaryType: any): void {
    this.gridData = [];
    if (!salaryType) {
      return;
    }

    this.dataService.SalaryTypes_GetTranslations(salaryType.Id).subscribe((data: ISalaryTypeTranslation[]) => {
      this.gridData = data;
    });
  }

  private getFieldTranslation(fieldName: string): string {
    let translationText: string;
    if (
      this.salaryType &&
      this.salaryType.SalaryTypeTranslations &&
      this.salaryType.SalaryTypeTranslations.length > 0
    ) {
      const translation: any = this.salaryType.SalaryTypeTranslations.find(
        (t: ISalaryTypeTranslation) => t.LanguageId === Global.SESSION.SignOnToken.LanguageId
      );
      translationText = translation ? translation[fieldName] : undefined;
    }

    return translationText;
  }

  private getBaseTranslation(fieldName: string, languageId?: number): string {
    if (!this.salaryType || !this.salaryType.BaseSalaryType) {
      return undefined;
    }

    let translationText: string;
    if (
      this.salaryType.BaseSalaryType.SalaryTypeTranslations &&
      this.salaryType.BaseSalaryType.SalaryTypeTranslations.length > 0
    ) {
      const translation: any = this.salaryType.BaseSalaryType.SalaryTypeTranslations.find(
        (t: ISalaryTypeTranslation) => t.LanguageId === languageId
      );
      translationText = translation ? translation[fieldName] : undefined;
    }

    return translationText ? translationText : (this.salaryType.BaseSalaryType as any)[fieldName];
  }

  private updateSalaryTypeTranslation(): void {
    if (!this.gridData) {
      return;
    }

    if (this.childIsDirty[0] && this.gridData) {
      this.dataService.SalaryTypes_SetTranslations(this.gridData).subscribe((data: ISalaryTypeTranslation[]) => {
        this.translateComplete.emit();
        this.gridData = data;
        this.visible = false;
      });
    }
  }
}
