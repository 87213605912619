import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IListDataItem } from 'src/app/model/ilist-data-item';
import { ISalaryCycle } from 'src/app/services/api/api-model';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-salary-cycle-filter',
  templateUrl: './salary-cycle-filter.component.html'
})
export class SalaryCycleFilterComponent implements OnInit, OnDestroy {
  public listSource: IListDataItem[] = [];

  constructor(
    public employeeService: EmployeeService,
    private translateService: TranslateService,
    private sessionService: SessionService,
    private staticDataService: StaticDataService
  ) {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.translate());
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnInit(): void {
    this.translate();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private translate() {
    this.translateService.get(['Employee.AllSalaryCycles']).subscribe((translations: { [key: string]: string }) => {
      const allItem: IListDataItem = { Id: -1, Text: translations['Employee.AllSalaryCycles'] };
      this.staticDataService.SalaryCycleUsed.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ISalaryCycle[]) => {
        this.listSource = data.map((d) => ({ Id: d.Id, Text: d.Name }));
        this.listSource.unshift(allItem);
        this.employeeService.salaryIdCycle = !this.employeeService.salaryIdCycle
          ? -1
          : this.employeeService.salaryIdCycle;
      });
    });
  }
}
