import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { IInheritTimeEntryTypeRequest, ITimeEntryType } from '../../../../services/api/api-model';
import { DataService } from '../../../../services/api/data.service';
import { SessionService } from '../../../../services/session/session.service';

@Component({
  selector: 'app-new-time-entry-type-dialog',
  templateUrl: './new-time-entry-type-dialog.component.html'
})
export class NewTimeEntryTypeDialogComponent implements OnInit {
  public currentTimeEntryTypes: ITimeEntryType[];
  ngOnInit(): void {
    // this.dataService.TimeEntryTypes_GetDefaultTimeEntryTypes().subscribe((timeEntryTypes: ITimeEntryType[]) => { });
  }
  @Input() public defaultTimeEntryTypes: ITimeEntryType[];

  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      if (value) {
        this.timeEntryTypeName = undefined;
        this.baseTimeEntryTypeId = undefined;
      }

      this.visibleValue = value;
      this.visibleChange.emit(value);
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() public add: EventEmitter<ITimeEntryType> = new EventEmitter<ITimeEntryType>();

  public timeEntryTypeName: string;
  public baseTimeEntryTypeId: number;
  public validationWarningVisible = false;

  constructor(private dataService: DataService, public sessionService: SessionService) {}

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  public onDialogAction(action: string): void {
    if (action === 'Create') {
      if (this.isCreateFormInvalid) {
        this.validationWarningVisible = true;
        return;
      }

      let request: Observable<ITimeEntryType>;
      if (this.baseTimeEntryTypeId === -1) {
        const customTimeEntryType: ITimeEntryType = { Name: this.timeEntryTypeName, Id: 0 };
        request = this.dataService.TimeEntryTypes_CreateCustomTimeEntryType(customTimeEntryType);
      } else if (this.baseTimeEntryTypeId > 0) {
        const inheritTimeEntryTypeRequest: IInheritTimeEntryTypeRequest = {
          BaseTimeEntryTypeId: this.baseTimeEntryTypeId,
          Name: this.timeEntryTypeName
        };
        request = this.dataService.TimeEntryTypes_InheritBaseTimeEntryType(inheritTimeEntryTypeRequest);
      }

      request.subscribe((timeEntryType: ITimeEntryType) => {
        this.add.emit(timeEntryType);
        this.visible = false;
      });
    }
  }

  private get isCreateFormInvalid(): boolean {
    return !this.timeEntryTypeName || !this.baseTimeEntryTypeId;
  }
}
