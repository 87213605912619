import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
var ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
var SharedComponentsModule = /** @class */ (function () {
    function SharedComponentsModule() {
    }
    return SharedComponentsModule;
}());
export { SharedComponentsModule };
export { ɵ0 };
