import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmployeeService } from 'src/app/services/employee.service';
import { environment } from 'src/environments/environment';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { NumericTextBoxOptions } from '../../custom-controls/numeric-edit/numeric-text-box-options';
import {
  Address,
  AddUserToCompanyRequest,
  CompanyUser,
  IAccount,
  ICity,
  IDepartment,
  IGender,
  IMunicipality,
  IRole,
  ISeTaxTable,
  ITaxCardType,
  IUserEmploymentTemplate
} from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-new-employee-dialog',
  templateUrl: './new-employee-dialog.component.html',
  styleUrls: ['./new-employee-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewEmployeeDialogComponent implements OnInit, OnDestroy {
  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.createNewEmployeeRequest();
      this.visibleValue = value;
      this.visibleChange.emit(value);
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public noCPRWarningDialogVisible = false;
  public noEmailWarningDialogVisible = false;
  public validationErrorDialogVisible = false;
  public hasLanguageModule = false;
  public createWithoutEmail = false;
  public dateValid = true;
  public municipality: IMunicipality[] = [];
  public hasDepartmentEnabled = false;
  public accountName = '';
  public taxCardTypes: ITaxCardType[] = [];
  public isShowForEmploymentTemplateId = true;
  public validationMessageKey: string;
  public noCPRNumber = false;
  public departments: IDepartment[] = [];
  public templates: IUserEmploymentTemplate[] = [];
  public newEmployeeRequest = new AddUserToCompanyRequest();
  public taxTableNumberDataSource: ISeTaxTable[] = [];
  public TaxColumnNumberOption: NumericTextBoxOptions = {
    min: 1,
    max: 6,
    spinners: true,
    step: 1,
    decimals: 0,
    format: 'n0'
  };
  public roleDataSource: IRole[] = [];

  public get branding() {
    return environment.branding;
  }

  public get showAddToCompany(): boolean {
    return this.sessionService.role.IsAccountAdmin && this.newEmployeeRequest.Details.RoleId >= 30;
  }

  public get employmentTemplateSelected(): boolean {
    return this.newEmployeeRequest.EmploymentTemplateId && this.newEmployeeRequest.EmploymentTemplateId !== -1;
  }

  public get isGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public get isSwedenCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
  }

  public get isPrimanyTaxCard(): boolean {
    return this.newEmployeeRequest.TaxCardTypeId === 1;
  }

  public get isFreeTaxCard(): boolean {
    return this.newEmployeeRequest.TaxCardTypeId === 3;
  }

  private cities: ICity[] = [];
  private defaultTitle = 'Medarbejder';

  constructor(
    public sessionService: SessionService,
    public staticDataService: StaticDataService,
    private employeeService: EmployeeService,
    private dataService: DataService
  ) {}

  public ngOnInit(): void {
    this.staticDataService.TaxCardType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ITaxCardType[]) => (this.taxCardTypes = data)
    );

    if (this.isSwedenCompany) {
      this.defaultTitle = 'Metarbeter';
    }

    this.staticDataService.Role.subscribe((data: IRole[]) => {
      this.roleDataSource = JSON.parse(JSON.stringify(data));
      if (this.sessionService.role.IsSalaryAdmin) {
        if (this.roleDataSource && this.roleDataSource.length > 0) {
          const fullAdminOption = this.roleDataSource.find((model: IRole) => model.Key === 'FullAdmin');
          if (fullAdminOption) {
            this.roleDataSource.splice(this.roleDataSource.indexOf(fullAdminOption), 1);
          }
        }
      }
    });

    this.staticDataService.City.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ICity[]) => (this.cities = data));

    this.staticDataService.departments
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: IDepartment[]) => (this.departments = data));

    this.staticDataService.Municipality.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (municipalities: IMunicipality[]) =>
        (this.municipality = municipalities.filter(
          (m: IMunicipality) => Global.SESSION && m.CountryId === Global.SESSION.CurrentCountryId
        ))
    );

    if (this.sessionService.role.IsAccountAdmin) {
      this.staticDataService.currentAccount.subscribe((account: IAccount) => (this.accountName = account.Name));
    }

    this.createNewEmployeeRequest();

    if (this.isSwedenCompany) {
      this.dataService.StaticData_GetSeTaxTables().subscribe((data: ISeTaxTable[]) => {
        if (data && data.length > 0) {
          this.taxTableNumberDataSource = data;
          this.newEmployeeRequest.TaxTableNumber = data[0].Id;
        }
      });
    }
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onDepartmentComboBoxChanged(): void {
    if (!this.newEmployeeRequest.DepartmentId) {
      this.newEmployeeRequest.ManagerCompanyUserId = undefined;
      return;
    }

    const department = this.departments.find((d: IDepartment) => d.Id === this.newEmployeeRequest.DepartmentId);
    if (department) {
      this.newEmployeeRequest.ManagerCompanyUserId = department.ManagerCompanyUserId;
    }
  }

  public onPostalCodeChanged(): void {
    const postalCode = this.newEmployeeRequest.Details.Address.PostalCode;

    if (!postalCode) {
      return;
    }

    let city: ICity = this.cities.find((c: ICity) => {
      if (c.CountryId === this.newEmployeeRequest.Details.Address.CountryId && c.PostalCode) {
        const numericCode = parseInt(postalCode, 10);
        if (!isNaN(numericCode)) {
          if (c.IsRange && c.PostalCodeTo) {
            if (numericCode >= c.PostalCode && numericCode <= c.PostalCodeTo) {
              return true;
            }
          } else {
            if (numericCode === c.PostalCode) {
              return true;
            }
          }
        }
      }

      return false;
    });

    if (!city) {
      city = this.cities.find((c: ICity) => c.PostalCode && postalCode === c.PostalCode.toString());
    }

    if (city) {
      this.newEmployeeRequest.Details.Address.City = city.Key;
      this.autoFillMunicipality(city.Key);
      if (city.CountryId !== this.newEmployeeRequest.Details.Address.CountryId) {
        this.newEmployeeRequest.Details.Address.CountryId = city.CountryId;
        this.setDefaultApplyDanishRulesInGreenland();
      }
    }
  }

  public onCityBlurChange(value: string): void {
    this.autoFillMunicipality(this.newEmployeeRequest.Details.Address.City);
  }

  public onMunicipalityChanged(): void {
    this.updateDefaultTaxRate();
  }

  public TaxColumnNumberChange(value: number): void {
    setTimeout(() => {
      if (value || value === 0) {
        if (value < 1) {
          this.newEmployeeRequest.TaxColumnNumber = 1;
        }
        if (value > 6) {
          this.newEmployeeRequest.TaxColumnNumber = 6;
        }
      }
    });
  }

  public TaxColumnNumberBlur(): void {
    if (this.newEmployeeRequest.TaxColumnNumber === null || this.newEmployeeRequest.TaxColumnNumber === undefined) {
      this.newEmployeeRequest.TaxColumnNumber = 1;
    }
  }

  public onCountryChange(): void {
    this.setDefaultApplyDanishRulesInGreenland();
    const country = this.newEmployeeRequest.Details.Address.CountryId;
    if (country !== Constants.DENMARK_COUNTRY_ID && country !== Constants.GREENLAND_COUNTRY_ID) {
      this.newEmployeeRequest.Details.MunicipalityId = 8;
      this.updateDefaultTaxRate();
    }
  }

  public onEmploymentTemplateChanged(): void {
    if (this.newEmployeeRequest.EmploymentTemplateId) {
      this.isShowForEmploymentTemplateId = true;
    } else {
      this.isShowForEmploymentTemplateId = false;
    }

    this.updateDefaultTaxRate();
  }

  public onNoCPRCheckboxClicked(): void {
    // if (this.noCPRNumber && !this.hasGender) {
    if (this.noCPRNumber) {
      this.noCPRWarningDialogVisible = true;
      this.newEmployeeRequest.IdentityNumber = '';
      this.newEmployeeRequest.EmploymentTemplateId = undefined;
    } else {
      this.setDefaultTemplate();
    }
  }

  public closeForm(action: string): void {
    switch (action) {
      case 'Close':
        this.visible = false;
        break;
      case 'CreateWithoutEmails':
        this.createNew();
        break;
      case 'Create':
        if (!this.newEmployeeRequest.Details.PersonalEmail && !this.newEmployeeRequest.Details.CompanyEmail) {
          this.noEmailWarningDialogVisible = true;
        } else {
          this.createNew();
        }
        break;
      default:
        break;
    }
  }

  private autoFillMunicipality(cityName: string): void {
    if (!cityName || !this.isGreenlandCompany) {
      return;
    }

    const city = this.cities.find(
      (filterCity: ICity) => filterCity.Key && cityName.toLowerCase() === filterCity.Key.toString().toLowerCase()
    );
    if (city && city.MunicipalityId && this.newEmployeeRequest.Details.MunicipalityId !== city.MunicipalityId) {
      this.newEmployeeRequest.Details.MunicipalityId = city.MunicipalityId;
      this.updateDefaultTaxRate();
    }
  }

  private updateDefaultTaxRate(): void {
    if (this.isGreenlandCompany || this.employmentTemplateSelected || this.newEmployeeRequest.Details.MunicipalityId) {
      const municipality = this.municipality.find(
        (m: IMunicipality) => m.Id === this.newEmployeeRequest.Details.MunicipalityId
      );
      if (municipality) {
        this.newEmployeeRequest.TaxRate = municipality.DefaultTaxRate;
      }
    }
  }

  private createNewEmployeeRequest(): void {
    this.newEmployeeRequest = new AddUserToCompanyRequest();
    this.newEmployeeRequest.Details = new CompanyUser();
    this.newEmployeeRequest.Details.Address = new Address();
    this.newEmployeeRequest.Details.CompanyId = Global.COMPANY_ID;
    this.newEmployeeRequest.Details.IsActive = true;
    this.newEmployeeRequest.Details.RoleId = 20;
    this.newEmployeeRequest.Title = this.defaultTitle;
    this.newEmployeeRequest.HireDate = this.staticDataService.getCurrentdate();
    this.newEmployeeRequest.Details.Address.CountryId =
      Global.COMPANY && Global.COMPANY.CountryId ? Global.COMPANY.CountryId : Constants.DENMARK_COUNTRY_ID;
    this.newEmployeeRequest.LanguageId = Global.COMPANY ? Global.COMPANY.DefaultLanguageId : 1;
    this.newEmployeeRequest.TaxCardTypeId = this.taxCardTypes.length > 1 ? this.taxCardTypes[0].Id : undefined;
    this.newEmployeeRequest.TaxColumnNumber = 1;
    this.newEmployeeRequest.ExpireDate = undefined;

    this.createWithoutEmail = false;
    this.noCPRNumber = false;
    if (this.taxTableNumberDataSource && this.taxTableNumberDataSource.length > 0) {
      this.newEmployeeRequest.TaxTableNumber = this.taxTableNumberDataSource[0].Id;
    }

    this.setDefaultApplyDanishRulesInGreenland();

    if (this.sessionService.feature.ShowEmployeeEmploymentTemplate) {
      this.dataService
        .EmploymentTemplates_GetEmploymentTemplates()
        .subscribe((templates: IUserEmploymentTemplate[]): void => {
          this.templates = templates;
          this.setDefaultTemplate();
        });
    }
  }

  private setDefaultApplyDanishRulesInGreenland(): void {
    if (this.isGreenlandCompany) {
      this.newEmployeeRequest.ApplyDanishRulesInGreenland =
        this.newEmployeeRequest.Details.Address.CountryId === Constants.DENMARK_COUNTRY_ID;
    }
  }

  private setDefaultTemplate() {
    if (this.templates && this.templates.length > 0) {
      if (this.templates.length > 1) {
        this.newEmployeeRequest.EmploymentTemplateId = this.templates[1].Id;
      } else {
        this.newEmployeeRequest.EmploymentTemplateId = this.templates[0].Id;
      }
    } else {
      this.newEmployeeRequest.EmploymentTemplateId = undefined;
    }
  }

  private createNew(): void {
    if (!this.validateInput()) {
      return;
    }

    this.newEmployeeRequest.Details.IsActive = true;
    this.newEmployeeRequest.Details.CompanyId = Global.COMPANY_ID;
    if (!this.newEmployeeRequest.EmploymentTemplateId) {
      this.newEmployeeRequest.EmploymentTemplateId = undefined;
      this.newEmployeeRequest.Title = undefined;
      this.newEmployeeRequest.DepartmentId = undefined;
      this.newEmployeeRequest.TaxCardTypeId = undefined;
      this.newEmployeeRequest.ManagerCompanyUserId = undefined;
    }

    this.employeeService.createEmployee(this.newEmployeeRequest).subscribe(() => (this.visible = false));
  }

  private validateInput(): boolean {
    if (this.employmentTemplateSelected && !this.dateValid) {
      return false;
    }

    this.validationMessageKey = this.getErrorMessage();
    if (this.validationMessageKey) {
      this.validationErrorDialogVisible = true;
      return false;
    }

    return true;
  }

  private getErrorMessage(): string {
    if (
      !this.newEmployeeRequest.Details.FirstName ||
      !this.newEmployeeRequest.Details.LastName ||
      (!this.noCPRNumber && !this.newEmployeeRequest.IdentityNumber) ||
      (!this.newEmployeeRequest.Details.MunicipalityId && this.isGreenlandCompany && this.employmentTemplateSelected)
    ) {
      return 'NewEmployee.RequiredErrorMessage';
    }

    if (!this.newEmployeeRequest.EmploymentTemplateId) {
      return '';
    }

    if (!this.newEmployeeRequest.Title) {
      return 'NewEmployee.TitleErrorMessage';
    }

    if (!this.newEmployeeRequest.HireDate) {
      return 'NewEmployee.HiringErrorMessage';
    }

    return '';
  }
}
