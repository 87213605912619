import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from 'src/app/common/global';
import { environment } from 'src/environments/environment';
import { NumericTextBoxOptions } from '../../../custom-controls/numeric-edit/numeric-text-box-options';
import { ImportButtonAction, ImportResultEnum, ImportTypesEnum } from '../../../model/enum';
import {
  IImportMapping,
  IImportMappingColumn,
  IImportOption,
  IImportOptionValue,
  IImportRequest,
  IImportType,
  ImportHeadersRequest,
  ISalaryTypeView,
  ISimpleKeyValuePair,
  IUserFileColumn
} from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { SessionService } from '../../../services/session/session.service';
import { ImportMappingDataItem } from './import-mapping-data-item';
import { ImportMappingDataSource } from './import-mapping-data-source';
import { ImportOptionHelper } from './import-option-helper';
import { ImportSaveMappingParameter } from './import-save-mapping-parameter';
import { MappingObject } from './mapping-object';

@Component({
  selector: 'app-import-mapping-dialog',
  templateUrl: './import-mapping-dialog.component.html'
})
export class ImportMappingDialogComponent implements OnInit, OnDestroy {
  @Input() public importType: ImportTypesEnum;
  @Input() public isExistingMapping = false;
  @Input() public mappingDetail: IImportMapping;
  @Input() public mappingName: string;

  public get branding() {
    return environment.branding;
  }

  private visibleValue = false;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
      if (value) {
        this.showDataImportDialog();
      } else {
        this.startIndex = undefined;
      }
    }
  }
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private formDataValue = '';
  @Input()
  public get formData(): string {
    return this.formDataValue || '';
  }
  public set formData(value: string) {
    if (this.formDataValue !== value) {
      this.formDataValue = value;
      this.formDataChange.emit(value);
    }
  }
  @Output() public formDataChange: EventEmitter<string> = new EventEmitter<string>();

  private startIndexValue: number;
  @Input()
  public get startIndex(): number {
    return this.startIndexValue;
  }
  public set startIndex(value: number) {
    if (this.startIndexValue !== value) {
      this.startIndexValue = value;
      this.startIndexChange.emit(value);
    }
  }
  @Output() public startIndexChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() public importMChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() public saveMapingEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() public closedEvent: EventEmitter<void> = new EventEmitter<void>();

  // Template properties
  public replaceAllWarningVisible = false;
  public oldOption: any;
  public userColumnsValues: MappingObject[] = [];
  public importOptionsObject: any;
  public importMappingDataDataSource: ImportMappingDataSource;
  public importResults: any;
  public importOneResults: any;
  public option: NumericTextBoxOptions = { min: 0, step: 1, spinners: false, decimals: 0, format: 'n0' };
  public isButtonDisabled = false;
  public alertDialogVisible = false;
  public alertMessage: string;
  public successDialogVisible = false;
  public importOnceAlertDialogVisible = false;
  public importTypes: IImportType[];
  public importOptions: IImportOption[];
  public importOptionValues: IImportOptionValue[];
  // Private properties
  private tempMappingDetail: IImportMapping;
  private mappingData: ISimpleKeyValuePair[] = []; //// Consider to use importMappingDataDataSource instead.
  private intectDataSource: MappingObject[] = [];
  private columnDataSource: MappingObject[] = [];

  constructor(
    private dataService: DataService,
    public sessionService: SessionService,
    private staticData: StaticDataService
  ) {}

  public ngOnInit(): void {
    this.staticData.ImportTypes.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IImportType[]) => {
      this.importTypes = data;
    });
    this.staticData.ImportOptions.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IImportOption[]) => {
      this.importOptions = data;
    });
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.formData = null;
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onDialogAction(action: string): void {
    switch (action) {
      case 'Test':
        this.onTestClicked();
        break;
      case 'ImportOnce':
        this.onImportOnceClicked();
        break;
      case 'SaveDefinition':
        this.onSaveChangesClicked();
        break;
      default:
        this.onCancelClicked();
    }
  }

  public closeDialogAndFireClosedEvent(): void {
    this.closedEvent.emit();
    this.closeDialog();
  }

  public onComboboxChange(value: any, item: MappingObject): void {
    if (value) {
      const dataSourceItem = this.importMappingDataDataSource.data[item.Key].columnsTypeDropDownDataSource.find(
        (i) => i.Id === value
      );
      if (dataSourceItem) {
        item.AllowsSalaryType = dataSourceItem.AllowsSalaryType;
      }
      const previousKey: string = this.getColumnTypeValue(item.Key);
      this.importMappingDataDataSource.data[item.Key].columnsTypeValue = value;
      this.importMappingDataDataSource.addAvailableItemToComboboxDataSources(item.Key, previousKey);

      const selectedMappingObject: MappingObject = this.importMappingDataDataSource.findSelectedColumnTypeItemById(
        value
      );
      if (selectedMappingObject) {
        this.updateKeyValuePairs(item.Key, selectedMappingObject.Key);
        this.importMappingDataDataSource.removeColumnTypeDropDownItem(item.Key, selectedMappingObject.Key);
        if (item.AllowsSalaryType === false) {
          this.importMappingDataDataSource.data[item.Key].intectColumnsDropDownDataSource = [];
        } else {
          this.importMappingDataDataSource.data[item.Key].intectColumnsDropDownDataSource = this.intectDataSource;
          if (this.intectDataSource.length > 0) {
            this.importMappingDataDataSource.data[item.Key].IntectColumnsValue = this.intectDataSource[0].Id;
          }
        }
      }
    }
  }

  public onIntectComboboxChange(value: any, item: MappingObject): void {
    if (value) {
      this.setAmountAsDefaultOfColumnType(value, item.Key);
    }
  }

  private onCancelClicked(): void {
    this.closeDialog();
  }

  private onTestClicked(): void {
    if (!this.validataMappings()) {
      return;
    }

    const importMappingColumns: IImportMappingColumn[] = this.convertToMappingColumn(this.mappingData);
    if (!importMappingColumns) {
      this.alertMessage = 'CompanyDataImport.NoMappingYet';
      this.alertDialogVisible = true;
      return;
    }

    this.testImportData(importMappingColumns);
  }

  private onImportOnceClicked(): void {
    if (!this.validataMappings()) {
      return;
    }

    const importMappingColumns: IImportMappingColumn[] = this.convertToMappingColumn(this.mappingData);
    if (!importMappingColumns) {
      this.alertMessage = 'CompanyDataImport.NoMappingYet';
      this.alertDialogVisible = true;
      return;
    }

    this.ImportOneImportData(-1, importMappingColumns);
  }

  private onSaveChangesClicked(): void {
    if (!this.validataMappings()) {
      return;
    }

    const mappingString: any = this.convertToMappingObject(this.mappingData);
    const importMappingColumns: IImportMappingColumn[] = this.convertToMappingColumn(this.mappingData);
    if (!mappingString) {
      this.alertMessage = 'CompanyDataImport.NoMappingYet';
      this.alertDialogVisible = true;
      return;
    }

    if (!this.mappingDetail) {
      this.createImportMappings(mappingString, importMappingColumns).then(() => {
        if (!this.isExistingMapping) {
          this.mappingDetail = undefined;
        }

        this.tempMappingDetail = undefined;
        this.successDialogVisible = true;
      });
      return;
    }

    this.updateImportMappings(mappingString, importMappingColumns).then((mapping: IImportMapping) => {
      if (!this.isExistingMapping) {
        this.mappingDetail = undefined;
      }

      this.tempMappingDetail = undefined;
      this.fireSaveMapingEvent(mapping.Id);
      this.successDialogVisible = true;
    });
  }

  private showDataImportDialog(): void {
    this.isButtonDisabled = false;
    this.prepareForCustomImport();
  }

  public onStartIndexChanged(): void {
    this.prepareForCustomImport();
  }

  private get createdTempMappingDetail(): boolean {
    return this.tempMappingDetail && !this.isExistingMapping;
  }

  private closeDialog(): void {
    this.isButtonDisabled = true;
    this.visible = false;
    if (this.createdTempMappingDetail) {
      this.dataService.ImportMapping_DeleteMapping(this.mappingDetail.Id).subscribe(() => {
        this.tempMappingDetail = undefined;
        this.mappingDetail = undefined;
        this.importMChange.emit();
      });
    }
  }

  private displayExistingPayrollMappings(): void {
    if (!this.mappingDetail.ImportMappingColumns) {
      return;
    }

    const pairs: IImportMappingColumn[] = this.mappingDetail.ImportMappingColumns;
    if (pairs) {
      pairs.forEach((pair: IImportMappingColumn) => {
        const importMappingDataItem: ImportMappingDataItem = this.importMappingDataDataSource.data[
          pair.UserColumnNumber
        ];
        this.extractIntectValueAndColumnTypeValue(importMappingDataItem, pair.ColumnKey);
      });
    }
  }

  private extractIntectValueAndColumnTypeValue(importMappingDataItem: ImportMappingDataItem, key: string): void {
    const keyValue: string[] = key.split(';');
    if (!importMappingDataItem || keyValue.length < 1) {
      return;
    }

    // if (keyValue.length < 2) {
    //   importMappingDataItem.IntectColumnsValue = keyValue[0];
    //   const amountColumn: MappingObject = importMappingDataItem.findColumnTypeItem(1); //// Amount
    //   if (!amountColumn) {
    //     console.warn("Can't find Amount in the ColumnTypeDataSource");
    //     return;
    //   }

    //   importMappingDataItem.columnsTypeValue = amountColumn.Id;
    //   return;
    // }

    // if (!keyValue[0]) {
    //   importMappingDataItem.IntectColumnsValue = keyValue[1];
    //   importMappingDataItem.columnsTypeValue = undefined;
    //   return;
    // }

    // importMappingDataItem.IntectColumnsValue = keyValue[0];
    // const columnItem: MappingObject = importMappingDataItem.findColumnTypeItemByKey(keyValue[1]);
    // importMappingDataItem.columnsTypeValue = columnItem ? columnItem.Id : importMappingDataItem.columnsTypeValue;
  }

  private displayExistingMappings(): void {
    if (!this.mappingDetail.ImportMappingColumns) {
      return;
    }

    const pairs: IImportMappingColumn[] = this.mappingDetail.ImportMappingColumns;
    if (pairs) {
      pairs.forEach((pair: IImportMappingColumn) => {
        const intectKey: string = pair.ColumnKey;
        const userFileId: string = pair.UserColumnNumber.toString();
        if (this.importMappingDataDataSource.data[userFileId]) {
          const columnItem: MappingObject = this.importMappingDataDataSource.data[userFileId].findColumnTypeItemByKey(
            intectKey
          );
          this.importMappingDataDataSource.data[userFileId].columnsTypeValue = columnItem
            ? columnItem.Id
            : this.importMappingDataDataSource.data[userFileId].columnsTypeValue;
          this.updateKeyValuePairs(userFileId, intectKey);
          if (columnItem) {
            const selectedMappingObject: MappingObject = this.importMappingDataDataSource.findSelectedColumnTypeItemById(
              columnItem.Id.toString()
            );
            this.importMappingDataDataSource.removeColumnTypeDropDownItem(userFileId, selectedMappingObject.Key);
          }
        }
      });
    }
  }

  private prepareForCustomImport(): void {
    // Check for existing mapping
    this.getImportOptions();
    this.onStartMapping();
  }

  private getImportOptions(): void {
    const option = this.importOptions.find((importOption) => {
      if (this.importType <= 2) {
        return importOption.Name === 'Change';
      } else {
        return importOption.Name === 'Mode';
      }
    });
    if (option && option.Values) {
      this.importOptionsObject = ImportOptionHelper.createImportOptionObjects(option.Values);
    }
  }

  private onStartMapping(): void {
    const startIndex: number = this.startIndex;
    const importType: string = ImportOptionHelper.getImportTypeKey(this.importType);
    if (!this.formData || importType.length === 0 || !startIndex) {
      return;
    }

    // Reset mapping data before start
    this.mappingData = [];
    this.userColumnsValues = [];

    const headerRequest: ImportHeadersRequest = {
      StartRow: this.startIndex,
      FileBase64: this.formData
    };

    this.dataService.Import_GetExcelHeadersOfFile(headerRequest).subscribe(
      (result: IUserFileColumn[]) => {
        if (!result) {
          this.showNoColumnsFoundMessage();
          return;
        }

        this.onStartMappingCompleted(result);
      },
      (err) => {
        this.formData = null;
      }
    );
  }

  private showNoColumnsFoundMessage(): void {
    this.alertMessage = 'CreateDataImportTemplate.NoColumnsFound';
    this.alertDialogVisible = true;
    this.isButtonDisabled = true;
    this.visible = false;
  }

  private onStartMappingCompleted(results: IUserFileColumn[]): void {
    if (results && results.length >= 0) {
      this.userColumnsValues = this.createMappingObject(results);

      // Set default mapping data
      this.importMappingDataDataSource = new ImportMappingDataSource();
      this.userColumnsValues.forEach((col: MappingObject): void => {
        this.importMappingDataDataSource.data[col.Key] = new ImportMappingDataItem();
        this.importMappingDataDataSource.data[col.Key].userFileColumnHeader = col.Name;
        this.importMappingDataDataSource.data[col.Key].userFileId = col.Key;
        const keyValPairItem: ISimpleKeyValuePair = { Key: col.Key, Value: '-1' };
        this.mappingData.push(keyValPairItem);
      });

      const importTypeModel = this.importTypes.find((importT) => {
        return importT.Id === this.importType;
      });

      if (this.importType === 5) {
        this.loadIntectColumnsDropDownDataSource(false).then((dataSource) => {
          this.columnDataSource = dataSource;
          this.loadComboboxesDataSources(importTypeModel);
        });
      } else {
        this.intectDataSource = [];
        this.loadComboboxesDataSources(importTypeModel);
      }
    }
  }

  private loadIntectColumnsDropDownDataSource(defaultSingleColumns: boolean): Promise<MappingObject[]> {
    return new Promise<MappingObject[]>((resolve: (value: any) => void, reject: (value: any) => void) => {
      const comboboxDataSource: MappingObject[] = [];
      this.dataService.SalaryTypes_GetSalaryTypeViews().subscribe((data: ISalaryTypeView[]) => {
        data.forEach((salaryType: ISalaryTypeView, index: number) => {
          const mappingObj: MappingObject = {
            Id: salaryType.SalaryTypeId,
            SortIndex: index + 1,
            IsSingle: defaultSingleColumns,
            Name: salaryType.Name,
            Key: salaryType.SalaryTypeId.toString()
          };

          comboboxDataSource.push(mappingObj);
        });

        return resolve(comboboxDataSource);
      });
    });
  }

  private createMappingObject(arrays: IUserFileColumn[]) {
    const userColumnsValues: MappingObject[] = [];
    arrays.forEach((item: IUserFileColumn, index: number) => {
      const columnValue: MappingObject = {
        Id: index + 1,
        SortIndex: index + 1,
        IsSingle: true,
        Name: item.Name,
        Key: item.UserColumnNumber.toString()
      };

      userColumnsValues.push(columnValue);
    });
    return userColumnsValues;
  }

  private setAmountAsDefaultOfColumnType(intectNewValue: string, key: string): void {
    const importMappingDataItem: ImportMappingDataItem = this.importMappingDataDataSource.data[key];
    if (!importMappingDataItem || importMappingDataItem.columnsTypeValue) {
      return;
    }

    const columTypeEditable: boolean = intectNewValue && intectNewValue !== '-1' && !importMappingDataItem.IsId;
    const columnTypeValueEmpty: boolean =
      !importMappingDataItem.columnsTypeValue || importMappingDataItem.columnsTypeValue === -1;
    let columnTypeValue: number;
    let columnTypeKey: string;

    if (columTypeEditable && columnTypeValueEmpty) {
      columnTypeValue = 1;
      const mappingObject: MappingObject = importMappingDataItem.findColumnTypeItem(1);
      columnTypeKey = mappingObject ? mappingObject.Key : columnTypeKey;
    }

    importMappingDataItem.columnsTypeValue = columnTypeValue;
  }

  private setAmountAsDefaultOfColumnTypes(): void {
    for (const key in this.importMappingDataDataSource.data) {
      if (this.importMappingDataDataSource.data.hasOwnProperty(key)) {
      }
    }
  }

  private updateKeyValuePairs(userItemKey: string, objectItemKey: string): void {
    const keyValPairItem: ISimpleKeyValuePair = { Key: userItemKey, Value: objectItemKey };
    if (this.mappingData.length === 0) {
      this.mappingData.push(keyValPairItem);
    } else {
      // First look up if it's updating an existing keyValuePair
      const foundIndex: number = this.findExistingItem(userItemKey, this.mappingData);
      if (foundIndex < 0) {
        // No existing item, can add normally
        this.mappingData.push(keyValPairItem);
      } else {
        // Update the object item with the new selection
        this.mappingData[foundIndex].Value = objectItemKey;
      }
    }
  }

  private getColumnTypeValue(userItemKey: string): string {
    let value: string;
    const foundIndex: number = this.findExistingItem(userItemKey, this.mappingData);
    if (foundIndex > -1) {
      value = this.mappingData[foundIndex].Value;
    }

    if (!value) {
      return undefined;
    }

    const columnItem: MappingObject = this.importMappingDataDataSource.data[userItemKey].findColumnTypeItemByKey(value);
    return columnItem ? columnItem.Key.toString() : undefined;
  }

  private findExistingItem(userItemKey: string, collection: ISimpleKeyValuePair[]): number {
    const result: number = collection.findIndex((value: ISimpleKeyValuePair) => value.Key === userItemKey);
    return result;
  }

  private hasError(result: any): boolean {
    return result.Errors && result.Errors.length > 0;
  }

  private validataMappings(): boolean {
    if (this.mappingName.length === 0) {
      this.alertMessage = 'CompanyDataImport.MappingNameEdit';
      this.alertDialogVisible = true;
      return false;
    }
    if (!this.startIndex) {
      this.alertMessage = 'CompanyDataImport.MissingStartRow';
      this.alertDialogVisible = true;
      return false;
    }

    return true;
  }

  private validateImportResult(): boolean {
    const isNothingImports = this.importOneResults.Errors.filter((result: any) => {
      return result.TranslationEntity.Key === 'NothingImported';
    });

    return isNothingImports.length > 0 ? false : true;
  }

  private testImportData(importMappingColumns: IImportMappingColumn[]): void {
    const optionString: ISimpleKeyValuePair[] = ImportOptionHelper.getImportOptionsString(
      this.importOptionsObject,
      ImportButtonAction.Test
    );
    const simpleImportMapping = this.createSimpleImportMapping(importMappingColumns);
    const importRequest: IImportRequest = {
      Options: optionString,
      FileBase64: this.formData,
      Mapping: simpleImportMapping
    };

    this.dataService
      .Import_CustomImportExcelOptions(importRequest)
      .subscribe((result: any) => this.onImportCompleted(result, true));
  }

  private ImportOneImportData(mappingId: number, importMappingColumns: IImportMappingColumn[]): void {
    let simpleImportMapping: IImportMapping;
    let importRequest: IImportRequest;
    const optionString: ISimpleKeyValuePair[] = ImportOptionHelper.getImportOptionsString(
      this.importOptionsObject,
      ImportButtonAction.Import
    );

    if (!mappingId || mappingId < 1) {
      simpleImportMapping = this.createSimpleImportMapping(importMappingColumns);

      importRequest = {
        Options: optionString,
        FileBase64: this.formData,
        Mapping: simpleImportMapping
      };
    } else {
      importRequest = {
        Options: optionString,
        FileBase64: this.formData,
        MappingId: mappingId
      };
    }

    this.dataService
      .Import_CustomImportExcelOptions(importRequest)
      .subscribe((result: any) => this.onImportCompleted(result, false));
  }

  private onImportCompleted(result: any, isTest: boolean): void {
    this.alertMessage =
      result && result.Outcome === ImportResultEnum[ImportResultEnum.FatalError] && result.FatalMessage
        ? result.FatalMessage
        : 'CompanyDataImport.TestImportSuccessMessage';
    if (!this.hasError(result)) {
      this.alertDialogVisible = true;
      return;
    }
    if (isTest) {
      this.importResults = result;
    } else {
      this.importOneResults = result;
    }
  }

  public onCompleteImportOneEvent(): void {
    if (!this.validataMappings()) {
      return;
    }
    if (!this.validateImportResult()) {
      this.visible = false;
      return;
    }

    const mappingString: any = this.convertToMappingObject(this.mappingData);
    const importMappingColumns: IImportMappingColumn[] = this.convertToMappingColumn(this.mappingData);
    if (!mappingString) {
      this.alertMessage = 'CompanyDataImport.NoMappingYet';
      this.alertDialogVisible = true;
      return;
    }

    this.completeImportOneEvent(importMappingColumns);
  }

  public completeImportOneEvent(importMappingColumns: IImportMappingColumn[]): void {
    let importRequest: IImportRequest;
    const optionString: ISimpleKeyValuePair[] = ImportOptionHelper.getImportOptionsString(
      this.importOptionsObject,
      ImportButtonAction.CompleteImport
    );
    const simpleImportMapping = this.createSimpleImportMapping(importMappingColumns);
    importRequest = {
      Options: optionString,
      FileBase64: this.formData,
      Mapping: simpleImportMapping
    };

    this.dataService.Import_CustomImportExcelOptions(importRequest).subscribe(
      (result) => {
        this.alertMessage =
          result && result.Outcome === ImportResultEnum[ImportResultEnum.FatalError] && result.FatalMessage
            ? result.FatalMessage
            : 'CompanyDataImport.CompleteImportText';
        if (this.hasError(result)) {
          this.alertDialogVisible = true;
        } else {
          this.successDialogVisible = true;
        }

        this.visible = false;
      },
      () => {
        this.alertMessage = 'CompanyDataImport.FatalError';
        this.alertDialogVisible = true;
      }
    );
  }

  private updateImportMappings(mappingString: string, mappingColumns: IImportMappingColumn[]): Promise<IImportMapping> {
    return new Promise<IImportMapping>((resolve: (value: any) => void, reject: (value: any) => void) => {
      if (!mappingString || mappingColumns.length === 0) {
        return reject('Missing mapping string or mapping columns');
      }

      this.mappingDetail.StartRow = this.startIndex;
      this.mappingDetail.ImportMappingColumns = mappingColumns;
      this.dataService.ImportMapping_UpdateMapping(this.mappingDetail).subscribe((mapping: IImportMapping): void => {
        this.mappingDetail = mapping;
        return resolve(mapping);
      });
    });
  }

  private createImportMappings(
    mappingString: string,
    importMappingColumns: IImportMappingColumn[]
  ): Promise<IImportMapping> {
    return new Promise<IImportMapping>((resolve: (value: any) => void, reject: (value: any) => void) => {
      if (!mappingString) {
        // return deferred.promise;
      }

      const simpleImportMapping: IImportMapping = this.createSimpleImportMapping(importMappingColumns);
      this.dataService.ImportMapping_SaveMapping(simpleImportMapping).subscribe((mapping: IImportMapping): void => {
        this.tempMappingDetail = mapping;
        this.mappingDetail = mapping;
        this.importMChange.emit();
        return resolve(simpleImportMapping);
      });
    });
  }

  private createSimpleImportMapping(importMappingColumns: IImportMappingColumn[]): IImportMapping {
    const simpleImportMapping: IImportMapping = {
      Name: this.mappingName,
      StartRow: this.startIndex,
      TypeId: this.importType,
      Id: 0,
      CompanyId: Global.COMPANY.Id,
      CountryId: Global.COMPANY.CountryId,
      ImportMappingColumns: importMappingColumns
    };
    return simpleImportMapping;
  }

  private fireSaveMapingEvent(mappingId: number): void {
    if (this.saveMapingEvent) {
      this.saveMapingEvent.emit(mappingId);
    }
  }

  private convertToMappingObject(mappingData: ISimpleKeyValuePair[]): string {
    let parameters = '';
    if (this.importType === ImportTypesEnum.Payroll) {
      const data: any = this.importMappingDataDataSource.data;
      let index = 0;
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const parameter: ImportSaveMappingParameter = new ImportSaveMappingParameter();
          parameter.value = data[key].userFileId;
          parameter.selectedColumnTypeValue = data[key].columnsTypeValue;
          parameter.selectedColumnTypeKey = data[key].columnsTypeKey;
          parameter.selectedIntectColumnValue = data[key].IntectColumnsValue;
          const strParameter: string = parameter.toString();
          if (strParameter.length > 0) {
            if (parameters.length > 0) {
              parameters += ',';
            }

            parameters += strParameter;
          }

          index++;
        }
      }

      parameters = '[' + parameters + ']';
    } else {
      if (mappingData) {
        mappingData.forEach((element: ISimpleKeyValuePair): void => {
          if (element.Value && element.Value !== '-1') {
            const col: string = element.Value + ':' + element.Key;
            parameters += col + ';';
          }
        });
      }
    }

    return parameters;
  }

  private loadComboboxesDataSources(importTypeModel: IImportType): void {
    if (this.importTypes.length < 0) {
      this.importMappingDataDataSource.setColumnTypeDropDownDataSource([]);
      this.setDefaultypeValue();
      return;
    }

    if (!importTypeModel) {
      this.importMappingDataDataSource.setColumnTypeDropDownDataSource([]);
      this.setDefaultypeValue();
      return;
    }

    this.getComboboxDataSource(importTypeModel, true).then((dataSource: MappingObject[]) => {
      const noneMappingObject: MappingObject = this.createNoneMappingObject();
      const gDataSource: MappingObject[] = [];
      if (this.importType === 5) {
        dataSource.forEach((data) => {
          if (!data.AllowsSalaryType) {
            this.columnDataSource.unshift(data);
          } else {
            gDataSource.push(data);
          }
        });

        this.intectDataSource = gDataSource;
        this.columnDataSource.unshift(noneMappingObject);
      } else {
        dataSource.unshift(noneMappingObject);
        this.columnDataSource = dataSource;
      }

      this.importMappingDataDataSource.setColumnTypeDropDownDataSource(this.columnDataSource);
      this.importMappingDataDataSource.setIntectColumnsDropDownDataSourceAndAutoMap(this.intectDataSource);
      this.setDefaultypeValue();
      if (!this.isExistingMapping) {
        this.setAmountAsDefaultOfColumnTypes();
      } else {
        if (this.importType === ImportTypesEnum.Payroll) {
          this.displayExistingPayrollMappings();
        } else {
          this.displayExistingMappings();
        }
      }
    });
  }

  public setDefaultypeValue(): void {
    if (this.isExistingMapping) {
      return;
    }
    this.userColumnsValues.forEach((col: MappingObject): void => {
      const datasource: MappingObject[] = this.importMappingDataDataSource.data[col.Key].columnsTypeDropDownDataSource;
      let modelsuggest: MappingObject = null;

      const listSimilar: number[] = [];
      datasource.forEach((model: MappingObject) => {
        const originalName = col.Name;
        const similarRate = this.similarity(originalName, model.Name);
        let compareRate = 0.99;
        if (this.importType === 5) {
          compareRate = 0.99;
        }
        if (similarRate >= compareRate) {
          listSimilar.push(similarRate);
        } else {
          listSimilar.push(0);
        }
      });
      if (listSimilar.length > 0) {
        const maxIndex = listSimilar.indexOf(Math.max(...listSimilar));
        modelsuggest = datasource[maxIndex];
      } else {
        modelsuggest = datasource[0];
      }

      if (modelsuggest) {
        this.importMappingDataDataSource.data[col.Key].columnsTypeValue = modelsuggest.Id;
        this.onComboboxChange(this.importMappingDataDataSource.data[col.Key].columnsTypeValue, col);
      }
    });
  }

  private createNoneMappingObject(): MappingObject {
    return {
      Id: -1,
      SortIndex: -1,
      Name: ' ',
      IsSingle: false,
      Key: '-1',
      AllowsSalaryType: false
    };
  }

  private getComboboxDataSource(importType: IImportType, defaultSingleColumns: boolean): Promise<MappingObject[]> {
    return new Promise<MappingObject[]>((resolve: (value: any) => void, reject: (value: any) => void) => {
      const comboboxDataSource: MappingObject[] = [];

      importType.CountryColumns[Global.COMPANY.Country.Key].forEach((column: any, index: number) => {
        const cName = column.Name.replace(/(\r\n|\n|\r)/gm, ' ');
        const mappingObj: MappingObject = {
          Id: column.Id,
          SortIndex: index + 1,
          IsSingle: defaultSingleColumns,
          Name: cName,
          Key: column.Key,
          AllowsSalaryType: column.AllowsSalaryType
        };

        comboboxDataSource.push(mappingObj);
      });
      return resolve(comboboxDataSource);
    });
  }

  public warningText: string;
  public onRadioOptionChange(value: any): void {
    this.importOptionsObject.forEach((option: any) => {
      if (option.Selected) {
        this.oldOption = option;
      }

      const optionElement = document.getElementsByClassName(option.ControlValue)[0] as HTMLInputElement;
      if (option.ControlValue === value.ControlValue) {
        option.Selected = true;
        if (optionElement) {
          optionElement.checked = true;
        }
      } else {
        option.Selected = false;
        if (optionElement) {
          optionElement.checked = false;
        }
      }

      if (!value.WarningText && typeof value.WarningText === 'string' && !this.replaceAllWarningVisible) {
        this.replaceAllWarningVisible = true;
        this.warningText = value.WarningText;
      }
    });
  }

  public onDialogActionWarning(action: string): void {
    switch (action) {
      case 'Cancel':
        if (this.oldOption) {
          this.onRadioOptionChange(this.oldOption);
          this.oldOption = null;
        }
        break;
      default:
        break;
    }
  }

  private convertToMappingColumn(mappingData: ISimpleKeyValuePair[]) {
    const importMappingColumns: IImportMappingColumn[] = [];
    mappingData.forEach((mapping) => {
      if (!mapping.Value || mapping.Value === '-1') {
        return;
      }
      const data = this.importMappingDataDataSource.data;
      const dataMapping: ImportMappingDataItem = data[mapping.Key];
      let importMappingColumn: IImportMappingColumn;
      if (this.importType === 5) {
        if (dataMapping.IntectColumnsValue) {
          const intectColumn = dataMapping.intectColumnsDropDownDataSource.find(
            (gDataSource) => gDataSource.Id === dataMapping.IntectColumnsValue
          );
          importMappingColumn = {
            Id: 0,
            ImportMappingId: 0,
            ColumnKey: intectColumn.Key || '',
            UserColumnNumber: parseInt(mapping.Key, 10),
            SalaryTypeId: dataMapping.columnsTypeValue
          };
        } else {
          importMappingColumn = {
            Id: 0,
            ImportMappingId: 0,
            ColumnKey: dataMapping.columnsTypeKey,
            UserColumnNumber: parseInt(mapping.Key, 10),
            SalaryTypeId: null
          };
        }
      } else {
        const columnKey = dataMapping.columnsTypeKey;
        if (columnKey && columnKey !== '-1') {
          importMappingColumn = {
            Id: 0,
            ImportMappingId: 0,
            ColumnKey: columnKey,
            UserColumnNumber: parseInt(mapping.Key, 10),
            SalaryTypeId: null
          };
        }
      }
      if (importMappingColumn && importMappingColumn.Id === 0) {
        importMappingColumns.push(importMappingColumn);
      }
    });
    return importMappingColumns;
  }

  private similarity(s1: string, s2: string) {
    let longer = s1;
    let shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    const longerLength = longer.length;
    if (longerLength === 0) {
      return 1.0;
    }
    return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength.toString());
  }

  private editDistance(s1: string, s2: string) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    const costs = new Array();
    for (let i = 0; i <= s1.length; i++) {
      let lastValue = i;
      for (let j = 0; j <= s2.length; j++) {
        if (i === 0) {
          costs[j] = j;
        } else {
          if (j > 0) {
            let newValue = costs[j - 1];
            if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            }
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) {
        costs[s2.length] = lastValue;
      }
    }
    return costs[s2.length];
  }
}
