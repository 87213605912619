import { OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmployeeService } from 'src/app/services/employee.service';
import { environment } from 'src/environments/environment';
import { FileUploadComponent } from '../../../custom-controls/file-upload/file-upload.component';
import { ActionTypesEnum, ImportTypesEnum } from '../../../model/enum';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { DownloadService } from '../../../services/download.service';
import { SessionService } from '../../../services/session/session.service';
import { ReportDialogService } from '../../../shared-components/report-dialog/report-dialog.service';
import { CompanyDataImportservice } from './data-import.service';
import { ImportOptionHelper } from './import-option-helper';
var DataImportComponent = /** @class */ (function () {
    function DataImportComponent(translateService, dataService, downloadService, sessionService, staticDataService, employeeService, reportDialogService, companyDataImportservice) {
        var _this = this;
        this.translateService = translateService;
        this.dataService = dataService;
        this.downloadService = downloadService;
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.employeeService = employeeService;
        this.reportDialogService = reportDialogService;
        this.companyDataImportservice = companyDataImportservice;
        this.importType = ImportTypesEnum.Payroll;
        this.actionType = ActionTypesEnum.GetTemplate;
        this.importFromExternalSourceValue = 1;
        this.importPayrollFromExternalSourceValue = 1;
        this.showUploadFileDialog = false;
        this.showCreateTemplateDialog = false;
        this.mappingData = [];
        this.mappingDataSource = [];
        this.balanceDateDialogVisible = false;
        this.alertDialogVisible = false;
        this.balanceDate = new Date();
        this.ngUnsubscribe = new Subject();
        this.translateText();
        this.companyDataImportservice.showReportShortcutChange
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && _this.reportDialogService.isVisibleReportIcon) {
                _this.reportDialogService.onShowReportsEventClick();
            }
        });
    }
    Object.defineProperty(DataImportComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataImportComponent.prototype, "showDownLoadButton", {
        get: function () {
            return this.actionType === ActionTypesEnum.GetTemplate || this.actionType === 5;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataImportComponent.prototype, "showUploadButton", {
        get: function () {
            return (this.actionType === ActionTypesEnum.Upload_File || this.actionType === ActionTypesEnum.Convert_From_Other_System);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataImportComponent.prototype, "showCreateCustomFile", {
        get: function () {
            return this.actionType === ActionTypesEnum.CustomImport;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataImportComponent.prototype, "convertFromOtherSystemFieldsVisible", {
        get: function () {
            return this.actionType === ActionTypesEnum.Convert_From_Other_System;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataImportComponent.prototype, "getTemplateVisible", {
        get: function () {
            return (this.actionType === ActionTypesEnum.GetTemplate ||
                this.actionType === ActionTypesEnum.Convert_From_Other_System ||
                this.actionType === ActionTypesEnum.Export_existing_data);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataImportComponent.prototype, "isPayrollDataImport", {
        get: function () {
            return this.importType === ImportTypesEnum.Payroll || this.importType === ImportTypesEnum.TimeEntriesRow;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataImportComponent.prototype, "isTimeEntriesDataImport", {
        get: function () {
            return this.importType === ImportTypesEnum.TimeEntriesRow;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataImportComponent.prototype, "acceptFileType", {
        get: function () {
            return this.importType === ImportTypesEnum.Payroll || this.importType === ImportTypesEnum.TimeEntriesRow
                ? this.importPayrollFromExternalSourceValue === 2
                    ? '.csv'
                    : '.dat'
                : '.pdf'; /*this.importFromExternalSourceValue === 2
            ? '.csv'
            : '.pdf';*/
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataImportComponent.prototype, "hasTimeModule", {
        get: function () {
            return this.sessionService.feature.hasModuleId(8);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataImportComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataImportComponent.prototype, "isShowExportExistingData", {
        get: function () {
            return this.importType === ImportTypesEnum.Employees || this.importType === ImportTypesEnum.Initial_Balances;
        },
        enumerable: true,
        configurable: true
    });
    DataImportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (e) {
            return _this.translateText();
        });
        this.balanceDate = this.getLastDateOfPreviousMonth();
        this.selectDefaultMapping();
        if (this.isMobile) {
            this.sessionService.isShowHugeFeaturesAlert = true;
        }
    };
    DataImportComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    DataImportComponent.prototype.onDownLoad = function () {
        switch (this.importType) {
            case ImportTypesEnum.Employees:
            case ImportTypesEnum.Payroll:
            case ImportTypesEnum.TimeEntriesRow:
                this.downloadDocument();
                break;
            case ImportTypesEnum.Initial_Balances:
                // Popup date for Balances
                this.showBalancesDatePopup();
                break;
        }
    };
    DataImportComponent.prototype.onUpload = function () {
        if (this.actionType === ActionTypesEnum.Convert_From_Other_System) {
            this.fileUpload.addFile();
        }
        else {
            this.showUploadFileDialog = true;
        }
    };
    DataImportComponent.prototype.onCreate = function () {
        this.showCreateTemplateDialog = true;
    };
    DataImportComponent.prototype.translateText = function () {
        var _this = this;
        this.translateService
            .get([
            'CompanyDataImport.ImportFromExternalOption',
            'CompanyDataImport.AOFdatfile',
            'CompanyDataImport.ImportFromPlanDay'
        ])
            .subscribe(function (translations) {
            _this.importFromExternalSource = [];
            _this.importFromExternalSource.push({ Id: 1, Name: translations['CompanyDataImport.ImportFromExternalOption'] });
            _this.importFromExternalSourceEmployee = [];
            _this.importFromExternalSourceEmployee.push({
                Id: 1,
                Name: translations['CompanyDataImport.ImportFromExternalOption']
            });
            _this.importPayrollFromExternalSource = [];
            _this.importPayrollFromExternalSource.push({ Id: 1, Name: translations['CompanyDataImport.AOFdatfile'] });
            _this.importFromExternalSourcePayrollData = [];
            _this.importFromExternalSourcePayrollData.push({ Id: 1, Name: translations['CompanyDataImport.AOFdatfile'] });
        });
    };
    DataImportComponent.prototype.getLastDateOfPreviousMonth = function () {
        var date = new Date();
        var currentMonth = date.getMonth();
        var currentYear = date.getFullYear();
        return new Date(currentYear, currentMonth, 0);
    };
    DataImportComponent.prototype.onBalanceDateDialogAction = function (action) {
        if (action === 'Ok' || action === 'Upload') {
            this.downloadDocument();
        }
    };
    DataImportComponent.prototype.showBalancesDatePopup = function () {
        this.balanceDateDialogVisible = true;
        this.balanceDate = this.getLastDateOfPreviousMonth();
    };
    DataImportComponent.prototype.downloadDocument = function () {
        var _this = this;
        var date = this.getBalanceDay();
        var record = this.mappingDataSource.find(function (id) { return id.Id === _this.mappingDataSourceRecord; }) || {};
        switch (this.importType) {
            case ImportTypesEnum.Employees:
                // Employees
                if (this.actionType === 5) {
                    this.dataService.Download_ImportMapping_GetFilledImportTemplate(record.MappingId).subscribe(function (fileData) {
                        _this.onDownloadCompleted(fileData);
                    }, function (err) {
                        console.log(err);
                    });
                    break;
                }
                this.dataService.Download_ImportMapping_GetImportTemplate(record.MappingId).subscribe(function (fileData) {
                    _this.onDownloadCompleted(fileData);
                }, function (err) {
                    console.log(err);
                });
                break;
            case ImportTypesEnum.Initial_Balances:
                // Balances
                if (this.actionType === 5) {
                    this.dataService
                        .Download_ImportMapping_GetFilledImportTemplateWithParameters(record.MappingId, [date])
                        .subscribe(function (fileData) {
                        _this.onDownloadCompleted(fileData);
                    }, function (err) {
                        console.log(err);
                    });
                    break;
                }
                this.dataService.Download_ImportMapping_GetImportTemplateWithParameters(record.MappingId, [date]).subscribe(function (fileData) {
                    _this.onDownloadCompleted(fileData);
                }, function (err) {
                    console.log(err);
                });
                break;
            case ImportTypesEnum.Payroll:
            case ImportTypesEnum.TimeEntriesRow:
                this.dataService.Download_ImportMapping_GetImportTemplate(record.MappingId).subscribe(function (fileData) {
                    _this.onDownloadCompleted(fileData);
                }, function (err) {
                    console.log(err);
                });
                break;
        }
    };
    DataImportComponent.prototype.onDownloadCompleted = function (promiseValue) {
        if (this.sessionService.browser.isMobileSafari) {
            this.alertType = 'SafariWarning';
            this.alertDialogVisible = true;
        }
        this.downloadService.download('ImportTemplate.XLSX', promiseValue);
    };
    DataImportComponent.prototype.onFileSelected = function (file) {
        var _this = this;
        var maxSize = 1024 * 1024 * 64;
        if (file && file.size > maxSize) {
            this.alertType = 'FileSizeError';
            this.alertDialogVisible = true;
            return;
        }
        var reader = new FileReader();
        reader.onload = function () {
            var base64 = reader.result;
            var formDataString = base64.split('base64,')[1] || '';
            _this.postDataImportFromDatFile(formDataString);
        };
        reader.readAsDataURL(file);
    };
    DataImportComponent.prototype.postDataImportFromDatFile = function (formData) {
        var _this = this;
        var operation = ImportOptionHelper.getImportTypeKey(this.importType);
        var record = this.mappingDataSource.find(function (mapping) {
            // return mapping.CompanyId === null;
            return mapping.Id === _this.mappingDataSourceRecord;
        });
        var request = {
            FileBase64: formData,
            MappingId: record.MappingId
        };
        if (operation === 'ImportSalaryRecords') {
            if (this.importPayrollFromExternalSourceValue === 2) {
                this.dataService
                    .CompanyDataImport_PlanDayToExcel(operation, formData)
                    .subscribe(function (fileData) { return _this.onDownloadCompleted(fileData); });
            }
            else {
                this.dataService
                    .ImportMapping_DatFileToExcel(request)
                    .subscribe(function (fileData) { return _this.onDownloadCompleted(fileData); });
            }
        }
        else if (operation === 'ImportTimeEntries') {
            operation = 'TimeEntries';
            this.dataService
                .ImportMapping_DatFileToExcel(request)
                .subscribe(function (fileData) { return _this.onDownloadCompleted(fileData); });
        }
        else {
            this.dataService.ImportMapping_DanloenPdfToExcel(request).subscribe(function (fileData) {
                if (operation === 'ImportEmployees') {
                    _this.employeeService.loadEmployees();
                }
                _this.onDownloadCompleted(fileData);
            }, function (err) {
                console.log(err);
            });
        }
    };
    // private postEmployeeFileFromExternalSource(formData: FormData): void {
    //   const operation: string = ImportOptionHelper.getImportTypeKey(this.importType);
    //   this.dataService.Import_DanloenPdfToExcel(operation, formData).subscribe((fileData: string) => {
    //     this.onDownloadCompleted(fileData);
    //     if (this.importType === ImportTypesEnum.Employees) {
    //       this.staticDataService.loadDepartments();
    //       this.staticDataService.loadCompanyUsers();
    //       this.alertType = 'DanlonPDFSuccess';
    //       this.alertDialogVisible = true;
    //     } else if (this.importType === ImportTypesEnum.Initial_Balances) {
    //       this.alertType = 'BalancesDanlonPDFSuccess';
    //       this.alertDialogVisible = true;
    //     }
    //   });
    // }
    // Get date with format YYYYMMDD
    DataImportComponent.prototype.getBalanceDate = function () {
        if (!this.balanceDate) {
            return undefined;
        }
        var dateTemp = new Date(this.balanceDate);
        return (dateTemp.getFullYear().toString() +
            this.sessionService.toString(dateTemp.getMonth() + 1, '00') +
            this.sessionService.toString(dateTemp.getDate(), '00'));
    };
    // Get date with format DD-MM-YYYY
    DataImportComponent.prototype.getBalanceDay = function () {
        if (!this.balanceDate) {
            return undefined;
        }
        var dateTemp = new Date(this.balanceDate);
        return (this.sessionService.toString(dateTemp.getDate(), '00') +
            '-' +
            this.sessionService.toString(dateTemp.getMonth() + 1, '00') +
            '-' +
            dateTemp.getFullYear().toString());
    };
    DataImportComponent.prototype.onCheckBoxImportTypeChange = function () {
        this.actionType = 1;
        this.importFromExternalSourceValue = 1;
        this.importPayrollFromExternalSourceValue = 1;
        this.updateListCurrentMappingData();
    };
    DataImportComponent.prototype.selectDefaultMapping = function () {
        var _this = this;
        this.dataService.ImportMapping_CompanyMappings().subscribe(function (mappingData) {
            _this.mappingData = mappingData || [];
            _this.updateListCurrentMappingData();
        }, function () {
            _this.mappingData = [];
        });
    };
    DataImportComponent.prototype.updateListCurrentMappingData = function () {
        var _this = this;
        this.mappingDataSource = [];
        var importType = this.importType;
        this.mappingData.forEach(function (mapping, index) {
            if (importType === mapping.TypeId) {
                _this.mappingDataSource.push({
                    Id: index++,
                    Name: mapping.Name,
                    MappingId: mapping.Id,
                    CompanyId: mapping.CompanyId
                });
            }
        });
        var isDefaultMapping = this.mappingDataSource.find(function (mapping) {
            return mapping.CompanyId === null;
        });
        if (!isDefaultMapping) {
            this.mappingDataSourceRecord =
                this.mappingDataSource && this.mappingDataSource.length > 0 ? this.mappingDataSource[0].Id : undefined;
        }
        else {
            this.mappingDataSourceRecord = isDefaultMapping.Id;
        }
    };
    DataImportComponent.prototype.onActionTypeChange = function () {
        this.importFromExternalSourceValue = 1;
        switch (this.actionType) {
            case 1:
                this.radioIndex = 1;
                this.updateListCurrentMappingData();
                break;
            case 5:
                this.radioIndex = 2;
                this.updateListCurrentMappingData();
                break;
            case 4:
                if (this.importType >= 3) {
                    this.radioIndex = 2;
                }
                else {
                    this.radioIndex = 3;
                }
                this.updateListCurrentMappingData();
                break;
            case 3:
            case 2:
                if (this.importType >= 3) {
                    this.radioIndex = 2;
                }
                else {
                    this.radioIndex = 3;
                }
                break;
        }
        if (this.uploadButtonDisable) {
            this.alertType = 'DisableDanlon';
            this.alertDialogVisible = true;
        }
    };
    Object.defineProperty(DataImportComponent.prototype, "uploadButtonDisable", {
        get: function () {
            return false;
        },
        enumerable: true,
        configurable: true
    });
    DataImportComponent.prototype.onImportMappingChange = function () {
        this.selectDefaultMapping();
    };
    return DataImportComponent;
}());
export { DataImportComponent };
