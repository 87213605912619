/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./mobile-modal-footer.component";
var styles_MobileModalFooterComponent = [];
var RenderType_MobileModalFooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MobileModalFooterComponent, data: {} });
export { RenderType_MobileModalFooterComponent as RenderType_MobileModalFooterComponent };
export function View_MobileModalFooterComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_MobileModalFooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-mobile-modal-footer", [], null, null, null, View_MobileModalFooterComponent_0, RenderType_MobileModalFooterComponent)), i0.ɵdid(1, 114688, null, 0, i1.MobileModalFooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MobileModalFooterComponentNgFactory = i0.ɵccf("app-mobile-modal-footer", i1.MobileModalFooterComponent, View_MobileModalFooterComponent_Host_0, {}, {}, ["*"]);
export { MobileModalFooterComponentNgFactory as MobileModalFooterComponentNgFactory };
