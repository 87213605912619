<app-dialog
  [overlayClose]="false"
  [(visible)]="isVisible"
  class="Employment-viewTaxCardHistoryDataGrid"
  [title]="ViewTaxCardHistoryLablel | translate"
  (action)="onTaxCardHistoryAction($event)"
  id="viewTaxCardHistoryDialog"
>
  <div
    *ngIf="!isSwedenCompany"
    class="FormElement u-trailer"
    [innerHTML]="'ViewTaxCardHistory.HighlightExplanatory' | translate"
  ></div>

  <app-grid
    [editMode]="!IsReadOnly"
    [addNew]="!IsReadOnly && (isFullAdmin || isSalaryAdmin)"
    [deleteRow]="false"
    addNewText="{{ 'Grids.AddNewRecord' | translate }}"
    [addNewDisable]="sessionService.isGetting"
    [(data)]="gridData"
    [(triggerUpdate)]="refreshData"
    (addNewEvent)="addHandler($event)"
    (updateDefaultRecordEvent)="onUpdateDefaultRecord()"
    (removeEvent)="removeHandler($event)"
    (saveChangesEvent)="saveChanges($event)"
    [filterable]="'menu'"
    [filter]="filter"
    (filterChange)="onFilterChange($event)"
    [isEmployeeTime]="true"
    [idColumn]="'idColumn'"
    [columnIndexFocusAfterCreate]="2"
    [defaultNewValue]="defaultRecordValue"
    [(selectedItem)]="selectedItem"
  >
    <app-grid-column
      [isFilterable]="true"
      field="IsActive"
      [filter]="'boolean'"
      type="checkboxgrid"
      class="ViewTaxcardHistory"
      [editableField]="'isActiveEditable'"
      title="{{ 'ViewTaxCardHistory.IsActiveColumnHeader' | translate }}"
      [width]="60"
      headerTooltip="{{ 'ViewTaxCardHistory.IsActiveColumnHeader' | translate }}"
    >
    </app-grid-column>
    <app-grid-column
      [isFilterable]="true"
      field="IsManual"
      [filter]="'boolean'"
      type="checkboxgrid"
      [editable]="false"
      title="{{ 'ViewTaxCardHistory.IsManualColumnHeader' | translate }}"
      [width]="60"
      [headerTooltip]="'ViewTaxCardHistory.IsManualColumnHeaderTooltip' | translate"
      headerTooltip="{{ 'ViewTaxCardHistory.IsManualColumnHeader' | translate }}"
    ></app-grid-column>
    <app-grid-column
      [isFilterable]="true"
      field="IsRequest"
      [filter]="'boolean'"
      type="checkboxgrid"
      [editable]="false"
      title="{{ 'ViewTaxCardHistory.IsRequestColumnHeader' | translate }}"
      [width]="60"
      [headerTooltip]="
        'ViewTaxCardHistory.IsRequestColumnHeaderTooltip' | translate: { SystemAlias: branding.SystemAlias }
      "
      headerTooltip="{{ 'ViewTaxCardHistory.IsRequestColumnHeader' | translate }}"
    ></app-grid-column>

    <app-grid-column
      *ngIf="!isSwedenCompany"
      [isFilterable]="true"
      field="TaxCardTypeId"
      type="combobox"
      class="ViewTaxcardHistory"
      [comboboxDataSource]="taxCardTypes"
      comboboxValueField="Id"
      comboboxDisplayField="Name"
      [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
      [editableField]="'Editable'"
      title="{{ 'ViewTaxCardHistory.TaxCardTypeColumnHeader' | translate }}"
      [width]="150"
      headerTooltip="{{ 'ViewTaxCardHistory.TaxCardTypeColumnHeader' | translate }}"
    ></app-grid-column>

    <app-grid-column
      [isFilterable]="true"
      filter="date"
      field="ValidFrom"
      type="datepicker"
      class="ViewTaxcardHistory"
      [editableField]="'Editable'"
      title="{{ 'ViewTaxCardHistory.ValidFromColumnHeader' | translate }}"
      [width]="90"
      headerTooltip="{{ 'ViewTaxCardHistory.ValidFromColumnHeader' | translate }}"
      [AllowNull]="true"
    >
    </app-grid-column>
    <app-grid-column
      [isFilterable]="true"
      field="ValidUntil"
      type="datepicker"
      class="ViewTaxcardHistory"
      [editableField]="'Editable'"
      title="{{ 'ViewTaxCardHistory.ValidUntilColumnHeader' | translate }}"
      [width]="90"
      headerTooltip="{{ 'ViewTaxCardHistory.ValidUntilColumnHeader' | translate }}"
      [AllowNull]="true"
    >
    </app-grid-column>
    <app-grid-column
      [isFilterable]="true"
      field="TaxRate"
      type="percentage"
      class="ViewTaxcardHistory"
      [editableField]="'Editable'"
      title="{{ 'ViewTaxCardHistory.TaxRateColumnHeader' | translate }}"
      [percentageDecimals]="false"
      [width]="70"
      headerTooltip="{{ 'ViewTaxCardHistory.TaxRateColumnHeader' | translate }}"
    ></app-grid-column>
    <app-grid-column
      *ngIf="!isSwedenCompany"
      [isFilterable]="true"
      field="DeductionMonth"
      type="numeric"
      class="ViewTaxcardHistory"
      [editableField]="'DeductionEditable'"
      title="{{ 'ViewTaxCardHistory.DeductionMonthColumnHeader' | translate }}"
      [width]="130"
      [numbericOption]="NumericOptions"
      headerTooltip="{{ 'ViewTaxCardHistory.DeductionMonthColumnHeader' | translate }}"
    ></app-grid-column>
    <app-grid-column
      *ngIf="!isSwedenCompany"
      [isFilterable]="true"
      field="FreeAmount"
      type="numeric"
      class="ViewTaxcardHistory"
      title="{{ 'ViewTaxCardHistory.FreeAmountColumnHeader' | translate }}"
      [width]="90"
      [numbericOption]="NumericOptions"
      [editableField]="'FreeAmountEditable'"
      headerTooltip="{{ 'ViewTaxCardHistory.FreeAmountColumnHeader' | translate }}"
    ></app-grid-column>
    <app-grid-column
      *ngIf="!isSwedenCompany"
      [isFilterable]="true"
      field="MinimumRate"
      type="percentage"
      [editable]="false"
      title="{{ 'ViewTaxCardHistory.MinimumRateColumnHeader' | translate }}"
      [width]="80"
      headerTooltip="{{ 'ViewTaxCardHistory.MinimumRateColumnHeader' | translate }}"
    ></app-grid-column>
    <app-grid-column
      *ngIf="!isSwedenCompany"
      [isFilterable]="true"
      field="MaximumDeductionMonth"
      type="numeric"
      [editable]="false"
      title="{{ 'ViewTaxCardHistory.MaximumDeductionMonthColumnHeader' | translate }}"
      [width]="90"
      [numbericOption]="NumericOptions"
      headerTooltip="{{ 'ViewTaxCardHistory.MaximumDeductionMonthColumnHeader' | translate }}"
    ></app-grid-column>
    <app-grid-column
      [isFilterable]="true"
      field="Created"
      type="datepicker"
      class="ViewTaxcardHistory"
      [editable]="false"
      title="{{ 'ViewTaxCardHistory.CreatedColumnHeader' | translate }}"
      [width]="115"
      headerTooltip="{{ 'ViewTaxCardHistory.CreatedColumnHeader' | translate }}"
    >
    </app-grid-column>
  </app-grid>

  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="ConfiumAfterCreateDanishTaxCard($event)"
  [(visible)]="isShowConfiumAfterCreateDanishTaxCard"
  [leadingText]="'ViewTaxCardHistory.ConfimAfterDanishCreateManualTaxCard' | translate"
>
  <app-dialog-action type="No"></app-dialog-action>
  <app-dialog-action type="Yes"></app-dialog-action>
</app-dialog>
