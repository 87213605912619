<app-dialog (action)="onDialogAction($event)" [overlayClose]="false" [(visible)]="visible">
  <form *ngIf="visible">
    <p>{{ 'Finalize.VettingDataNote' | translate }}</p>

    <input
      type="text"
      id="finalizesave_username"
      name="finalizesave_username"
      value="{{ userName }}"
      class="input-no-display"
      autocomplete="username"
      readonly="true"
    />

    <app-text-edit
      id="finalizesave_password"
      name="finalizesave_password"
      [label]="'CompanySalaryBatches.PasswordLabel' | translate"
      [(value)]="password"
      [editMode]="true"
      [type]="'password'"
      [autocorrect]="true"
    >
    </app-text-edit>
  </form>

  <app-dialog-action [type]="'Cancel'" [disabled]="sessionService.isSubmitting"></app-dialog-action>
  <app-dialog-action [type]="'Finalize'" [disabled]="!password || sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" [(visible)]="responseDialogVisible" [leadingText]="response">
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
