import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-modal-content',
  templateUrl: './mobile-modal-content.component.html'
})
export class MobileModalContentComponent implements OnInit {
  constructor() {}

  private transform = false;
  ngOnInit(): void {}
}
