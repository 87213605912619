<div class="FormEmlement Company-totalsPayslipsSearch Company-payslipLabel">
  <div class="right right--flex payslip-icons" *ngIf="!hideDownloadIcons">
    <app-payslip-icon
      *ngIf="IsAppOrDesktop"
      id="ViewPayslipSameTabButton"
      [isHidden]="isHidden"
      [payslipIconType]="'htmlSameTab'"
      (click)="onPayslipIconClicked('htmlSameTab')"
    ></app-payslip-icon>
    <app-payslip-icon *ngIf="IsWeb" [payslipIconType]="'htmlNewTab'" (click)="onPayslipIconClicked('htmlNewTab')">
    </app-payslip-icon>
    <app-payslip-icon *ngIf="IsFinalizedBatch" [payslipIconType]="'pdf'" (click)="onPayslipIconClicked('pdf')">
    </app-payslip-icon>
    <app-payslip-icon
      class="print-icon-flex"
      *ngIf="IsWeb"
      [payslipIconType]="'htmlPrintTab'"
      (click)="onPayslipIconClicked('htmlPrint')"
    ></app-payslip-icon>
  </div>

  <span class="FormElement-label">{{ selectedSalaryStatement?.UserFullName }}</span>
</div>
