import * as tslib_1 from "tslib";
import { AfterViewInit, ChangeDetectorRef, ElementRef, OnInit } from '@angular/core';
import { ControlComponentBase } from '../../common/control-component-base';
import { SessionService } from '../../services/session/session.service';
var CalendarPickerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CalendarPickerComponent, _super);
    function CalendarPickerComponent(elementRef, sessionService, changeDetectorRef) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.sessionService = sessionService;
        _this.setTimeout = false;
        return _this;
    }
    CalendarPickerComponent.prototype.ngOnInit = function () { };
    CalendarPickerComponent.prototype.ngAfterViewInit = function () { };
    return CalendarPickerComponent;
}(ControlComponentBase));
export { CalendarPickerComponent };
