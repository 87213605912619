<div kendoTooltip>
  <button [disabled]="disabled" [ngClass]="buttonClass" type="button">
    <div class="Action-buttonInner">
      <app-icon
        class="App-icon--flex iconButton-action"
        [icon]="icon"
        [iconClass]="'Icon Icon-Flex' + (iconSize ? ' Icon--' + iconSize : '')"
        [tooltip]="tooltip | translate"
      >
      </app-icon>
      <div class="u-hiddenVisually">{{ label | translate }}</div>
    </div>
  </button>
</div>
