<app-dialog
  class="Form--stacked ModalDialogInfo"
  [overlayClose]="false"
  [(visible)]="isVisible"
  (action)="onDialogAction($event)"
  [title]="'Login.DPATitle' | translate"
  [width]="550"
>
  <div
    class="u-trailer"
    [innerHTML]="'Login.AcceptDPADescription' | translate: { SystemAlias: branding.SystemAlias } | keepHtml"
  ></div>
  <div class="FormElement-control">
    <input
      class="FormElement-input PasswordInput"
      type="password"
      (keydown)="onAcceptDPAKeydown($event)"
      autocomplete="new-password"
      [(ngModel)]="password"
    />
  </div>
  <app-dialog-action [disabled]="!password" type="Create" [close]="false"></app-dialog-action>
</app-dialog>
