import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { version } from '../../../version';
import { ApiDataService } from '../../services/api/api-data.service';
import { ModalService } from '../modal.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../modal.service";
import * as i3 from "../api/api-data.service";
var CustomTranslateLoader = /** @class */ (function () {
    function CustomTranslateLoader(http, modalService, dataService) {
        this.http = http;
        this.modalService = modalService;
        this.dataService = dataService;
    }
    Object.defineProperty(CustomTranslateLoader.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomTranslateLoader.prototype, "DomainAlias", {
        get: function () {
            return "<a href='" + window.location.hostname + "'>" + window.location.hostname + "</a>";
        },
        enumerable: true,
        configurable: true
    });
    CustomTranslateLoader.prototype.getTranslation = function (lang) {
        var _this = this;
        if (lang === void 0) { lang = 'da'; }
        lang = lang && lang === 'sv' ? 'se' : lang;
        var AppLangCode = ['da', 'en', 'kl', 'se'];
        if (!AppLangCode.find(function (value) { return value === lang; })) {
            lang = 'da';
        }
        return this.http.get('translation/' + version + ("-locale." + lang + ".json")).pipe(catchError(function (err) {
            return new Observable(function () {
                if (err && err.status === 200) {
                    var serverVersion_1 = '';
                    var devTitle_1 = version + ("-locale." + lang + ".json");
                    var devMessage_1 = err.error.error.message;
                    var serverVersionPromise = _this.dataService.Miscellaneous_GetVersion().toPromise();
                    serverVersionPromise
                        .then(function (value) {
                        serverVersion_1 = value;
                        if (serverVersion_1 !== version) {
                            switch (lang) {
                                case 'en':
                                    _this.modalService.alert('', _this.branding.SystemAlias +
                                        ' is updated to a newer version. Please press Ctrl+F5 in your browser to force a refresh, or update the ' +
                                        _this.branding.SystemAlias +
                                        " app in Google Play or the App Store.<br/>If you're using a mobile app and do not see an available update, please check again tomorrow. Until then, you can use your browser - just navigate to " +
                                        _this.DomainAlias, true, true);
                                    break;
                                case 'se':
                                    _this.modalService.alert('', _this.branding.SystemAlias +
                                        ' är uppdaterad till en nyare version. Vänligen tryck på Ctrl+F5 i din webbläsare för att tvinga igenom en uppdatering, eller uppdatera ' +
                                        _this.branding.SystemAlias +
                                        ' appen i Google Play eller App Store.<br/>Om du använder en mobilapp och inte ser en tillgänglig uppdatering - kontrollera igen imorgon. Till dess kan du använda din webbläsare - adressen är ' +
                                        _this.DomainAlias, true, true);
                                    break;
                                default:
                                    _this.modalService.alert('', _this.branding.SystemAlias +
                                        " er opdateret til en nyere version. Tryk Ctrl+F5 i din browser for at gennemtvinge en opdatering, eller opdatér app'en i Google Play eller App Store.<br/>Benytter du en mobil app og kan ikke se en tilgængelig opdatering, så tjek igen i morgen. Indtil da kan du benytte din browser i stedet - adressen er " +
                                        _this.DomainAlias, true, true);
                                    break;
                            }
                            return;
                        }
                        else {
                            _this.modalService.alert(devTitle_1, devMessage_1, true, true);
                            return;
                        }
                    })
                        .catch(function () {
                        _this.modalService.alert(devTitle_1, devMessage_1, true, true);
                        return;
                    });
                    _this.modalService.alert(devTitle_1, devMessage_1, true, true);
                    return;
                }
                _this.modalService.alert('', _this.branding.SystemAlias +
                    " er opdateret til en nyere version. Tryk Ctrl+F5 i din browser for at gennemtvinge en opdatering, eller opdatér app'en i Google Play eller App Store.<br/>Benytter du en mobil app og kan ikke se en tilgængelig opdatering, så tjek igen i morgen. Indtil da kan du benytte din browser i stedet - adressen er " +
                    _this.DomainAlias, true, true);
            });
        }));
    };
    CustomTranslateLoader.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomTranslateLoader_Factory() { return new CustomTranslateLoader(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ModalService), i0.ɵɵinject(i3.ApiDataService)); }, token: CustomTranslateLoader, providedIn: "root" });
    return CustomTranslateLoader;
}());
export { CustomTranslateLoader };
