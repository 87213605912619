<div>
  <ul class="TabPanel">
    <li>
      <button class="TabPanel-link is-active" type="button">
        <div>
          <app-icon [icon]="'User'"></app-icon>
          <div class="TabPanel-title">{{ 'Preference.UserOption' | translate }}</div>
        </div>
      </button>
    </li>
    <li style="margin-left: auto;">
      <!-- Top bar toggle -->
      <div class="Menu-toogle-item">
        <button
          class="TabPanel-link"
          type="button"
          [ngClass]="{ 'is-active': sessionService.menuToggled }"
          (click)="toggleMenuButton()"
        >
          <div>
            <app-icon *ngIf="sessionService.menuToggled" [icon]="'LevelDown'"></app-icon>
            <app-icon *ngIf="!sessionService.menuToggled" [icon]="'LevelUp'"></app-icon>
          </div>
        </button>
      </div>
    </li>
  </ul>
  <div class="Main Scrollable">
    <div class="Island">
      <div>
        <app-preference></app-preference>
      </div>
    </div>
  </div>
</div>
