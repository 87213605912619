<app-fixed-panel [excludedComponents]="listClassExcluded" [specificClassName]="'Island-employee--payslip'">
  <app-employee-info
    [companyUser]="employeeService.employee | async"
    [currentPositionId]="employeeService.selectedEmploymentId | async"
    [isEmployeeTab]="true"
    (expandUserInfoEmitter)="onExpand($event)"
    (EmploymentChangeEmitter)="onEmploymentChanged()"
  ></app-employee-info>

  <div
    id="employeePayslipView"
    class="Island Island-employee Island-employee--payslip"
    *ngIf="!employeeService.isNonSelected"
    [ngClass]="{ 'is-expand': expanded }"
  >
    <!-- No Employment -->
    <div *ngIf="!hasUserEmployment">
      <div class="Card">
        <div class="Card-inner withOnlyButton">
          <h2 class="Card-title">{{ 'EmploymentPayrollData.NoEmployment' | translate }}</h2>
          <!-- GS-5298
      <app-button
        buttonClass="Button--primary"
        *ngIf="allowEdit && !isAddNewEmploymentHide"
        (click)="createUserEmployment()"
        [label]="'EmployeeGeneral.CreateEmployment' | translate"
      ></app-button>
      -->
          <app-button
            buttonClass="Button--primary"
            *ngIf="allowEdit"
            (clickEvent)="createUserEmployment()"
            [label]="'EmployeeGeneral.CreateEmployment' | translate"
          ></app-button>
        </div>
      </div>
    </div>
    <!-- /No Employment -->

    <!-- GS-5298 <span class="EmployeeWarning">{{ noCPRMessage | translate }} </span> -->

    <div [ngClass]="{ Cards: isMobile }" *ngIf="hasUserEmployment">
      <app-grid-checkbox
        class="toggle-show-all-employment"
        *ngIf="currentEmployee.UserEmployments.length > 1 && isMobile"
        [label]="'EmployeePayslip.ShowOnlyCurrentEmployment' | translate"
        (valueChange)="onCheckBoxOnlyEmploymentChanged($event)"
      ></app-grid-checkbox>
      <app-payslip-mobile
        [groupIconAction]="groupIconAction"
        [salaryStatementsNoneType]="gridData"
        (iconClick)="clickIconAction($event)"
        [(selectedSalaryStatement)]="selectedSalaryStatement"
        (Revert)="onRevert()"
        *ngIf="isMobile"
      ></app-payslip-mobile>
      <div class="Card" *ngIf="!isMobile">
        <div class="Card-inner">
          <form>
            <fieldset class="Form--horizontal">
              <div class="Card-title">
                <div>{{ 'EmployeeGeneral.Payslips' | translate }}</div>
                <app-button
                  buttonClass="Button--card"
                  *ngIf="IsPaymentApprover && !IsReadonly"
                  [isDisabled]="!selectedFirstRow"
                  [label]="'CompanySalaryBatches.RevertThisPayslip' | translate"
                  (clickEvent)="onRevert()"
                ></app-button>
                <app-button
                  buttonClass="Button--card"
                  *ngIf="!IsPaymentApprover && !IsReadonly"
                  [isDisabled]="true"
                  [tooltip]="'RevertPayslip.NoPermission' | translate"
                  [label]="'CompanySalaryBatches.RevertThisPayslip' | translate"
                ></app-button>
              </div>
              <div class="wrap-flex-box">
                <div class="hint FormElement-label">
                  <div>{{ 'EmployeePayslip.Hint' | translate }}</div>
                </div>
                <app-grid-checkbox
                  class="toggle-show-all-employment"
                  *ngIf="currentEmployee.UserEmployments.length > 1"
                  [label]="'EmployeePayslip.ShowOnlyCurrentEmployment' | translate"
                  (valueChange)="onCheckBoxOnlyEmploymentChanged($event)"
                ></app-grid-checkbox>
              </div>
              <app-grid
                style="z-index: 1000"
                [editMode]="false"
                [addNew]="false"
                [deleteRow]="false"
                [data]="gridData"
                (clickAppGridIcon)="clickIconAction($event)"
                (selectedItemChange)="onSelectRow($event)"
                (dblClick)="onGridCellDbClick($event)"
                class="minHforPaySlip grid-click"
                [ngClass]="!isAllfinalize ? 'Icon--payslip' : ''"
                [(selectedItem)]="selectedSalaryStatement"
                [filterable]="'menu'"
                [filter]="filter"
                [(filterClear)]="filterClear"
                (filterChange)="onFilterChange($event)"
                [(triggerUpdate)]="triggerUpdate"
              >
                <app-grid-column
                  *ngIf="!isAllfinalize"
                  [type]="'icon'"
                  [iconField]="'PreliminaryWarning'"
                  [tooltip]="'EmployeePayslip.PreliminaryTooltip' | translate"
                  [iconAction]="'PreliminaryWarning'"
                  [width]="35"
                ></app-grid-column>
                <app-grid-column
                  [title]="'EmployeePayslip.ShowAndDownload' | translate"
                  [popupButtonText]="'GlobalDialog.Download' | translate"
                  [type]="'buttonPopUp'"
                  [popupButtonClass]="'Button--card dropMargin width100'"
                  [width]="175"
                  [popupButtonArrayContentIcon]="groupIconAction"
                  [isPopupButtonIcon]="true"
                  [popupButtonIcon]="'Download'"
                  [headerTooltip]="'EmployeePayslip.ShowAndDownload' | translate"
                >
                </app-grid-column>
                <app-grid-column
                  [title]="'EmployeePayslip.EmploymentTitle' | translate"
                  [type]="'subProberty'"
                  [field]="'EmploymentTitle'"
                  [subProberty]="'UserEmployment.Title'"
                  [width]="80"
                  [isUseSubcolumTooltip]="true"
                  [headerTooltip]="'EmployeePayslip.EmploymentTitle' | translate"
                >
                </app-grid-column>
                <app-grid-column
                  [isFilterable]="true"
                  [title]="'EmployeeGeneral.PeriodStart' | translate"
                  [type]="'datepicker'"
                  [field]="'PeriodFrom'"
                  [width]="80"
                  class="align-center"
                  [headerTooltip]="'EmployeeGeneral.PeriodStart' | translate"
                ></app-grid-column>
                <app-grid-column
                  [isFilterable]="true"
                  [title]="'EmployeeGeneral.PeriodEnd' | translate"
                  [type]="'datepicker'"
                  [field]="'PeriodTo'"
                  [width]="80"
                  class="align-center"
                  [headerTooltip]="'EmployeeGeneral.PeriodEnd' | translate"
                ></app-grid-column>
                <app-grid-column
                  [isFilterable]="true"
                  [title]="'EmployeePayslip.BaseSalary' | translate"
                  [type]="'numeric'"
                  [field]="'PrimaryIncomeAmount'"
                  [width]="80"
                  [headerTooltip]="'EmployeePayslip.BaseSalary' | translate"
                >
                </app-grid-column>
                <app-grid-column
                  [isFilterable]="true"
                  [title]="'EmployeePayslip.HoursWorked' | translate"
                  [type]="'numeric'"
                  [field]="'HoursWorked'"
                  [width]="90"
                  [headerTooltip]="'EmployeePayslip.HoursWorked' | translate"
                ></app-grid-column>
                <app-grid-column
                  [isFilterable]="true"
                  [title]="'EmployeePayslip.TaxDeduction' | translate"
                  [type]="'numeric'"
                  [field]="'AppliedTaxDeduction'"
                  [width]="90"
                  [headerTooltip]="'EmployeePayslip.TaxDeduction' | translate"
                ></app-grid-column>
                <app-grid-column
                  [isFilterable]="true"
                  [title]="'EmployeePayslip.TaxRate' | translate"
                  [type]="'percentage'"
                  [field]="'TaxRate'"
                  [width]="70"
                  [headerTooltip]="'EmployeePayslip.TaxRate' | translate"
                >
                </app-grid-column>
                <app-grid-column
                  [isFilterable]="true"
                  [title]="'SelfServicePayslip.PaidOut' | translate"
                  [type]="'numeric'"
                  [field]="'PayoutAmount'"
                  [width]="70"
                  [headerTooltip]="'SelfServicePayslip.PaidOut' | translate"
                >
                </app-grid-column>
                <app-grid-column
                  [isFilterable]="true"
                  [title]="'EmployeePayslip.SpentVacation' | translate"
                  [type]="'numeric'"
                  [field]="'VacationDaysSpent'"
                  [width]="90"
                  [headerTooltip]="'EmployeePayslip.SpentVacation' | translate"
                ></app-grid-column>
                <app-grid-column
                  [isFilterable]="true"
                  [title]="'EmployeePayslip.VacationMoneyEarnedGross' | translate"
                  [type]="'numeric'"
                  [field]="'VacationMoneyEarnedGross'"
                  [width]="100"
                  [headerTooltip]="'EmployeePayslip.VacationMoneyEarnedGross' | translate"
                ></app-grid-column>
                <app-grid-column
                  [isFilterable]="true"
                  [title]="'EmployeePayslip.VacationMoneyEarnedNet' | translate"
                  [type]="'numeric'"
                  [field]="'VacationMoneyEarnedNet'"
                  [width]="80"
                  [headerTooltip]="'EmployeePayslip.VacationMoneyEarnedNet' | translate"
                ></app-grid-column>
              </app-grid>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="NonSelected" *ngIf="employeeService.isNonSelected && sessionService.role.IsSalaryAdminAndHigher">
    <div class="Card">
      <div class="Card-inner">
        <app-button
          buttonClass="Button--primary"
          (clickEvent)="createNewEmployeeDialogVisible = true"
          [label]="'Employee.NewEmployee' | translate"
        ></app-button>
      </div>
    </div>
  </div>
</app-fixed-panel>

<div class="Actions" *ngIf="!isMobile">
  <app-action-button
    *ngIf="sessionService.role.IsSalaryAdminAndHigher"
    id="ActionButtonAdd"
    [icon]="'AddUser'"
    [label]="'Employee.New'"
    [tooltip]="'Employee.NewEmployee'"
    [buttonClass]="'Action-button Action-buttonAdd'"
    (click)="createNewEmployeeDialogVisible = true"
  ></app-action-button>

  <!-- <app-action-button
    *ngIf="!employeeService.isNonSelected"
    [icon]="'File'"
    [label]="'Employee.Reports'"
    [tooltip]="'Employee.Reports'"
    [buttonClass]="'Action-button Action-buttonReports'"
    (click)="reportDialogVisible = true"
  ></app-action-button> -->

  <app-menu-button [menuIcon]="'Help'" [menuLabel]="'Help.Title'" [menuTooltip]="'Help.Title'" [multiMenuItems]="true">
    <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
    <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
  </app-menu-button>

  <app-action-button
    *ngIf="reportDialogService.isVisibleReportIcon && !employeeService.isNonSelected"
    buttonClass="Action-button"
  ></app-action-button>
</div>
<div class="Actions" *ngIf="isMobile">
  <app-menu-button
    [menuIcon]="'ThreeDots'"
    [menuLabel]="'More.Title'"
    [menuTooltip]="'More.Title'"
    [multiMenuItems]="true"
  >
    <app-menu-button-item
      *ngIf="sessionService.role.IsSalaryAdminAndHigher"
      (menuItemClick)="createNewEmployeeDialogVisible = true"
      [field]="'AddNewEmployee'"
    ></app-menu-button-item>
    <!-- <app-menu-button-item
      *ngIf="!employeeService.isNonSelected"
      (menuItemClick)="reportDialogVisible = true"
      [field]="'Reports'"
    ></app-menu-button-item> -->
    <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
    <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
  </app-menu-button>
</div>

<app-new-employee-dialog [(visible)]="createNewEmployeeDialogVisible"></app-new-employee-dialog>

<!-- <app-report-dialog [(visible)]="reportDialogVisible" [key]="'EmployeePayslips'" [param]="reportParams">
</app-report-dialog> -->

<app-dialog
  class="blue-dialog"
  *ngIf="IsWeb"
  [(visible)]="newTabBlockedDialogVisible"
  [overlayClose]="true"
  [leadingText]="'Report.NewTabBlocked' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-revert-payslip-dialog
  [(isVisible)]="showRevertFinalizedDialog"
  [employee]="currentEmployee"
  [salaryStatement]="selectedSalaryStatement"
  (revertCompleted)="onRevertBatchCompleted()"
></app-revert-payslip-dialog>

<app-new-employment-dialog [(isVisible)]="newEmploymentDialogVisible" [currentCompanyUserId]="currentEmployee?.Id">
</app-new-employment-dialog>
