import { OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReportParametersDefaultValue } from 'src/app/shared-components/report-dialog/report-parameters-default-value';
import { environment } from '../../../../environments/environment';
import { FilterDateTimeGrid } from '../../../common/filter-date-time-grid';
import { Global } from '../../../common/global';
import { DataService } from '../../../services/api/data.service';
import { DialogService } from '../../../services/dialog.service';
import { DownloadService } from '../../../services/download.service';
import { EmployeeService } from '../../../services/employee.service';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';
import { ReportDialogService } from '../../../shared-components/report-dialog/report-dialog.service';
var PayslipComponent = /** @class */ (function () {
    /* GS-5298
    public get isAddNewEmploymentHide(): boolean {
      return this.employee && this.employee.Gender === null;
    }
  */
    function PayslipComponent(employeeService, settingService, dataService, downloadService, renderer, sessionService, dialog, reportDialogService) {
        this.employeeService = employeeService;
        this.settingService = settingService;
        this.dataService = dataService;
        this.downloadService = downloadService;
        this.renderer = renderer;
        this.sessionService = sessionService;
        this.dialog = dialog;
        this.reportDialogService = reportDialogService;
        // @Output() public createUserEmploymentClick: EventEmitter<any> = new EventEmitter<any>();
        this.reportDialogVisible = false;
        this.newEmploymentDialogVisible = false;
        this.showRevertFinalizedDialog = false;
        this.salaryStatementsNoneType = [];
        this.isHideWarnningColum = false;
        this.newTabBlockedDialogVisible = false;
        this.groupIconAction = [];
        this.triggerUpdate = false;
        this.createNewEmployeeDialogVisible = false;
        this.filterClear = false;
        this.listClassExcluded = ['EmployeeInfo', 'Footer'];
        this.ngUnsubscribe = new Subject();
        this.gridData = this.salaryStatementsNoneType;
        this.expanded = false;
        this.isShowAllEmployment = true;
    }
    Object.defineProperty(PayslipComponent.prototype, "allowEdit", {
        get: function () {
            return this.sessionService.role.IsSalaryAdminAndHigher;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipComponent.prototype, "employee", {
        get: function () {
            return this.currentEmployee;
        },
        set: function (value) {
            if (value && this.currentEmployee !== value) {
                this.currentEmployee = value;
                this.gridData = [];
                this.salaryStatementsNoneType = [];
                this.filter = null;
                this.filterClear = true;
                this.initializeData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipComponent.prototype, "hasUserEmployment", {
        // public reportParams = new ReportParametersDefaultValue();
        get: function () {
            return (this.currentEmployee && this.currentEmployee.UserEmployments && this.currentEmployee.UserEmployments.length > 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipComponent.prototype, "IsPaymentApprover", {
        get: function () {
            return Global.SESSION && Global.SESSION.IsPaymentApprover;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipComponent.prototype, "IsReadonly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipComponent.prototype, "IsWeb", {
        get: function () {
            return !this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipComponent.prototype, "IsAppOrDesktop", {
        get: function () {
            return !this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipComponent.prototype, "IsEmployeePayslipActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.employee.payslip';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipComponent.prototype, "IsSelfServicePayslipActive", {
        get: function () {
            return this.sessionService.currentState === 'tabs.selfservice.payslip';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipComponent.prototype, "isFirefox", {
        get: function () {
            return this.sessionService.browser.isFirefox;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipComponent.prototype, "noCPRMessage", {
        get: function () {
            return this.employee && this.employee.HasDummyIdentityNumber ? 'Employee.NoValidCPR' : '';
        },
        enumerable: true,
        configurable: true
    });
    PayslipComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.IsEmployeePayslipActive || this.IsSelfServicePayslipActive) {
            this.settingService.showModuleMessage(this.IsEmployeePayslipActive ? 'Employee.Payslip' : 'SelfService.Payslip');
        }
        if (this.IsAppOrDesktop) {
            var IconHtmloulineAction = {};
            IconHtmloulineAction.Icon = 'file-download';
            IconHtmloulineAction.Action = 'iconHtmlouline';
            IconHtmloulineAction.tooltip = 'EmploymentPayrollData.HTMLPopupToolTip';
            IconHtmloulineAction.Active = this.IsAppOrDesktop;
            if (!this.sessionService.browser.isHybridApp) {
                IconHtmloulineAction.isHidden = true;
                IconHtmloulineAction.IconPaysliphide = 'Icon--Payslip--hide';
            }
            else {
                IconHtmloulineAction.isHidden = false;
            }
            this.groupIconAction.push(IconHtmloulineAction);
        }
        if (this.IsWeb) {
            var IconHtmlAction = {};
            IconHtmlAction.Icon = 'ExternalLink';
            IconHtmlAction.Action = 'iconHtml';
            IconHtmlAction.tooltip = 'EmploymentPayrollData.HTMLFileToolTip';
            IconHtmlAction.Active = this.IsWeb;
            this.groupIconAction.push(IconHtmlAction);
        }
        var IconPdfAction = {};
        IconPdfAction.Icon = 'Download';
        IconPdfAction.Action = 'iconPdf';
        IconPdfAction.tooltip = 'CompanySalaryBatches.PaySlipPDFTooltip';
        IconPdfAction.Active = true;
        this.groupIconAction.push(IconPdfAction);
        var IconPrintAction = {};
        IconPrintAction.Icon = 'file-print';
        IconPrintAction.Action = 'iconPrint';
        IconPrintAction.tooltip = 'CompanySalaryBatches.PaySlipPrintTooltip';
        IconPrintAction.Active = true;
        if (!this.isMobile) {
            this.groupIconAction.push(IconPrintAction);
        }
        //this.initializeData(true);
        this.reportDialogService.reportParams = new ReportParametersDefaultValue();
        this.employeeService.employee
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (employee) {
            return (_this.reportDialogService.reportParams.employeeId = employee ? employee.Id : undefined);
        });
        this.employeeService.employment
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (employment) {
            return (_this.reportDialogService.reportParams.employmentId = employment ? employment.Id : undefined);
        });
    };
    PayslipComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    PayslipComponent.prototype.onRevert = function () {
        if (!this.selectedFirstRow) {
            return;
        }
        this.showRevertFinalizedDialog = true;
    };
    PayslipComponent.prototype.createUserEmployment = function () {
        /* GS-5298
        if (this.isAddNewEmploymentHide) {
          return;
        }
        */
        this.newEmploymentDialogVisible = true;
    };
    PayslipComponent.prototype.onRevertBatchCompleted = function () {
        this.gridData = [];
        this.getSalaryStatements(this.currentEmployee.Id);
    };
    PayslipComponent.prototype.clickIconAction = function (event) {
        if (this.sessionService.isGetting || this.sessionService.isSubmitting) {
            return;
        }
        if (event && event.dataItem && event.iconAction) {
            this.currentEmployeeIdDownload = JSON.parse(JSON.stringify(this.currentEmployee.Id));
            this.selectedSalaryStatement = this.salaryStatementsNoneType.find(function (model) { return model.Id === event.dataItem.Id; });
            switch (event.iconAction) {
                case 'iconHtml': {
                    this.downloadSalaryStatementNoneEvent(event.dataItem.Id, false);
                    break;
                }
                case 'iconHtmlouline': {
                    this.downloadSalaryStatementNoneEvent(event.dataItem.Id, true);
                    if (this.sessionService.browser.isHybridApp) {
                        window.screen.orientation.unlock();
                    }
                    break;
                }
                case 'iconPrint': {
                    this.downloadSalaryStatementNoneEvent(event.dataItem.Id, false, true);
                    if (this.sessionService.browser.isHybridApp) {
                        window.screen.orientation.unlock();
                    }
                    break;
                }
                case 'iconPdf': {
                    this.downloadSalaryStatementPDFNoneEvent(event.dataItem.Id);
                    break;
                }
                default:
                    break;
            }
        }
    };
    PayslipComponent.prototype.onGridCellDbClick = function (event) {
        if (event && event.Id && event.event.target.className.indexOf('IconContainer--flex') < 0) {
            this.currentEmployeeIdDownload = JSON.parse(JSON.stringify(this.currentEmployee.Id));
            this.downloadSalaryStatementNoneEvent(event.Id, true);
        }
    };
    PayslipComponent.prototype.onSelectRow = function (event) {
        if (event && event.Id) {
            this.selectedSalaryStatement = this.salaryStatementsNoneType.find(function (model) { return model.Id === event.Id; });
            if (this.salaryStatementsNoneType.length > 0 && this.selectedSalaryStatement) {
                this.selectedFirstRow = this.salaryStatementsNoneType[0].Id === this.selectedSalaryStatement.Id;
            }
        }
    };
    PayslipComponent.prototype.initializeData = function (showNavigationMessage) {
        if (showNavigationMessage === void 0) { showNavigationMessage = false; }
        if (showNavigationMessage) {
            this.settingService.showNavigationMessage();
        }
        if (this.currentEmployee) {
            this.getSalaryStatements(this.currentEmployee.Id);
        }
    };
    PayslipComponent.prototype.getSalaryStatements = function (companyUserId) {
        var _this = this;
        this.dataService
            .SalaryStatements_GetSalaryStatementsByCompanyUser(companyUserId)
            .subscribe(function (salaryStatements) {
            if (salaryStatements) {
                salaryStatements = salaryStatements.sort(function (salaryStatement1, salaryStatement2) {
                    var date1 = salaryStatement1.PeriodTo;
                    var date2 = salaryStatement2.PeriodTo;
                    return (new Date(date2).getFullYear() - new Date(date1).getFullYear() ||
                        salaryStatement2.Id - salaryStatement1.Id);
                });
                _this.salaryStatementsNoneType = salaryStatements.map(function (model) {
                    var userEmployment = _this.currentEmployee.UserEmployments.find(function (ue) { return model.UserEmploymentId === ue.Id; });
                    return {
                        iconHtmlouline: 'file-download',
                        iconHtml: 'ExternalLink',
                        iconPdf: 'Download',
                        PreliminaryWarning: model.IsFinalized ? '' : 'warning',
                        Id: model.Id,
                        UserEmployment: userEmployment,
                        PeriodFrom: model.PeriodFrom,
                        PeriodTo: model.PeriodTo,
                        PrimaryIncomeAmount: model.PrimaryIncomeAmount,
                        HoursWorked: model.HoursWorked,
                        AppliedTaxDeduction: model.AppliedTaxDeduction,
                        TaxRate: model.TaxRate,
                        PayoutAmount: model.PayoutAmount,
                        VacationDaysSpent: model.VacationDaysSpent,
                        VacationMoneyEarnedGross: model.VacationMoneyEarnedGross,
                        VacationMoneyEarnedNet: model.VacationMoneyEarnedNet,
                        IsFinalized: model.IsFinalized,
                        PayoutDateDerived: model.PayoutDateDerived
                    };
                });
            }
            _this.selectedFirstRow = false;
            _this.gridData = filterBy(_this.salaryStatementsNoneType, _this.filter);
            _this.triggerUpdate = true;
        });
    };
    Object.defineProperty(PayslipComponent.prototype, "isAllfinalize", {
        get: function () {
            return this.salaryStatementsNoneType &&
                this.salaryStatementsNoneType.length > 0 &&
                this.salaryStatementsNoneType.filter(function (model) { return model.IsFinalized === true; }).length ===
                    this.salaryStatementsNoneType.length
                ? true
                : false;
        },
        enumerable: true,
        configurable: true
    });
    PayslipComponent.prototype.getPayslipReportByToken = function (salaryStatementId, format, isPrint) {
        var _this = this;
        this.dataService.SalaryStatements_GetPayslipDownloadToken(salaryStatementId).subscribe(function (token) {
            var languageCode = _this.sessionService.LanguageCode;
            setTimeout(function () {
                if (isPrint) {
                    var fileName = Math.random()
                        .toString(36)
                        .substring(7);
                    _this.dataService
                        .SalaryStatements_DownloadPayslipByToken(salaryStatementId, token, languageCode, format, fileName)
                        .subscribe(function (data) {
                        _this.onDownloadSalaryStatementHTMLCompleted(data, true, true);
                    });
                }
                else {
                    var downloadUrl = "/api/salarystatements/" + salaryStatementId + "/token/" + token + "/lang/" + languageCode + "/format/" + format + "/Payslip";
                    var newWindow = window.open(environment.apiUrl + downloadUrl);
                    if (!newWindow) {
                        _this.newTabBlockedDialogVisible = true;
                    }
                }
            });
        });
    };
    PayslipComponent.prototype.onDownloadSalaryStatementHTMLCompleted = function (data, isPopup, isPrint) {
        if (!isPopup) {
            var newWindow = window.open('about:blank');
            if (newWindow) {
                newWindow.document.write(data);
                newWindow.document.close();
            }
            else {
                this.newTabBlockedDialogVisible = true;
            }
        }
        else {
            if (this.currentEmployee.Id === this.currentEmployeeIdDownload || isPrint) {
                this.dialog.showIFrameDialog(data);
            }
            this.dialog.isBindding = false;
        }
    };
    PayslipComponent.prototype.downloadSalaryStatementNoneEvent = function (salaryStatementId, isPopup, isPrint) {
        var _this = this;
        if (isPopup && this.IsAppOrDesktop) {
            if (!this.dialog.isBindding) {
                var format = this.IsWeb ? 'a4phtmlstring' : 'htmlstring';
                this.dialog.isBindding = true;
                this.dataService.SalaryStatements_GetHtml(salaryStatementId, format).subscribe(function (data) {
                    _this.onDownloadSalaryStatementHTMLCompleted(data, isPopup);
                }, function () {
                    _this.dialog.isBindding = false;
                });
            }
        }
        else {
            if (isPrint) {
                this.getPayslipReportByToken(salaryStatementId, 'printa4phtmlstaticstring', isPrint);
            }
            else {
                this.getPayslipReportByToken(salaryStatementId, 'html');
            }
        }
    };
    PayslipComponent.prototype.downloadSalaryStatementPDFNoneEvent = function (salaryStatementId) {
        var _this = this;
        if (this.sessionService.browser.isHybridApp ||
            this.sessionService.browser.isIE ||
            this.sessionService.browser.isEdge) {
            this.dataService.SalaryStatements_GetPdf(salaryStatementId).subscribe(function (data) {
                _this.downloadService.download('Payslip.pdf', data);
            });
        }
        else {
            this.getPayslipReportByToken(salaryStatementId, 'a4ppdf');
        }
    };
    PayslipComponent.prototype.onFilterChange = function (filter) {
        this.filter = filter;
        this.selectedSalaryStatement = null;
        this.selectedFirstRow = false;
        if (filter && filter.filters && filter.filters.length > 0) {
            var filterDateTimeGrid = new FilterDateTimeGrid();
            var filtersDate = [];
            // Using FilterDateTimeGrid to filter date before filter another field
            this.filter = filterDateTimeGrid.convertFilterOperator(filter, filtersDate, 'PeriodFrom', 'PeriodTo');
            this.gridData = filterDateTimeGrid.doFilterDate(this.salaryStatementsNoneType, filtersDate, 'PeriodFrom', 'PeriodTo');
            this.gridData = filterBy(this.gridData, this.filter);
            if (filtersDate.length > 0) {
                [].push.apply(this.filter.filters, filtersDate);
            }
        }
        else {
            this.gridData = this.salaryStatementsNoneType;
        }
    };
    PayslipComponent.prototype.onExpand = function (event) {
        this.expanded = event;
    };
    PayslipComponent.prototype.onCheckBoxOnlyEmploymentChanged = function (event) {
        var employementId = -1;
        this.employeeService.selectedEmploymentId.subscribe(function (id) {
            employementId = id;
        });
        if (event) {
            this.isShowAllEmployment = false;
            this.gridData = this.gridData.filter(function (item) {
                return item.UserEmployment.Id === employementId;
            });
        }
        else {
            this.isShowAllEmployment = true;
            this.gridData = this.salaryStatementsNoneType;
        }
    };
    PayslipComponent.prototype.onEmploymentChanged = function () {
        if (!this.isShowAllEmployment) {
            this.gridData = this.salaryStatementsNoneType;
            var employementId_1 = -1;
            this.employeeService.selectedEmploymentId.subscribe(function (id) {
                employementId_1 = id;
            });
            this.gridData = this.gridData.filter(function (item) {
                return item.UserEmployment.Id === employementId_1;
            });
        }
    };
    return PayslipComponent;
}());
export { PayslipComponent };
