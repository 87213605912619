import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../common/form-component-base';
import { Global } from '../../common/global';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { EmploymentStatisticsModel } from './employment-statistics-model';
var EmploymentStatisticsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmploymentStatisticsComponent, _super);
    function EmploymentStatisticsComponent(dataService, staticData) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.staticData = staticData;
        _this.statisticsValue = {};
        _this.jobFunctionCodeStrings = [];
        _this.childIsValid = Array(4).fill(true);
        _this.childIsDirty = Array(10).fill(false);
        return _this;
    }
    Object.defineProperty(EmploymentStatisticsComponent.prototype, "statistics", {
        get: function () {
            return this.statisticsValue;
        },
        set: function (value) {
            this.statisticsValue = value || {};
        },
        enumerable: true,
        configurable: true
    });
    EmploymentStatisticsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticData.StatisticsEmploymentPrinciple.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.statisticsEmploymentPrinciple = data;
        });
        this.staticData.StatisticsEmploymentTerms.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.statisticsEmploymentTerms = data;
        });
        this.dataService.Miscellaneous_GetStatisticsDiscoCodes().subscribe(function (data) {
            _this.jobFunctionCodes = data;
            _this.jobFunctionCodes.map(function (item) {
                item.displayName = _this.formatJobFunctionCodes(item);
            });
        });
        this.staticData.StatisticsJobStatus.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.statisticsJobStatus = data;
        });
        this.staticData.StatisticsSalaryPrinciple.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.salaryPrinciples = data;
        });
    };
    EmploymentStatisticsComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    EmploymentStatisticsComponent.prototype.StatisticsJobFunctionCodeChange = function () {
        this.statistics.StatisticsJobFunctionCode = this.statistics.StatisticsJobFunctionCode || null;
        this.onChange();
    };
    Object.defineProperty(EmploymentStatisticsComponent.prototype, "IsEmployeeEmploymentStatistics", {
        get: function () {
            return this.statistics && this.statistics.HireDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmploymentStatisticsComponent.prototype, "JobFunctionName", {
        get: function () {
            var _this = this;
            if (!this.statistics ||
                !this.statistics.StatisticsJobFunctionCode ||
                !this.jobFunctionCodes ||
                this.jobFunctionCodes.length === 0) {
                return '';
            }
            var code = this.jobFunctionCodes.find(function (c) { return c.Code.toString() === _this.statistics.StatisticsJobFunctionCode.toString(); });
            return code ? code.Name : '';
        },
        enumerable: true,
        configurable: true
    });
    EmploymentStatisticsComponent.prototype.prepareDataForSearchJobFunctionCodes = function (jobFunctionCodes) {
        var _this = this;
        var codes = jobFunctionCodes.map(function (item) { return _this.formatJobFunctionCodes(item); });
        return codes;
    };
    EmploymentStatisticsComponent.prototype.formatJobFunctionCodes = function (jobFunctionCode) {
        return jobFunctionCode.Code.toString() + ' - ' + jobFunctionCode.Name;
    };
    Object.defineProperty(EmploymentStatisticsComponent.prototype, "isShow", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.StatisticsRecipientId && Global.COMPANY.StatisticsRecipientId === 7;
        },
        enumerable: true,
        configurable: true
    });
    EmploymentStatisticsComponent.prototype.onReadMoreClick = function () {
        window.open('http://support.gratisal.com/hc/da/articles/213857065-Ops%C3%A6tning-og-indberetning-til-statistik');
    };
    return EmploymentStatisticsComponent;
}(FormComponentBase));
export { EmploymentStatisticsComponent };
