import { BehaviorSubject } from 'rxjs';
import { Global } from 'src/app/common/global';
import { DataService } from 'src/app/services/api/data.service';
import { StaticDataService } from 'src/app/services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/api/data.service";
import * as i2 from "../../services/api/static-data.service";
import * as i3 from "../../services/session/session.service";
var AccountantSummaryService = /** @class */ (function () {
    function AccountantSummaryService(dataService, staticDataService, sessionService) {
        var _this = this;
        this.dataService = dataService;
        this.staticDataService = staticDataService;
        this.sessionService = sessionService;
        this.multiCompanyOrganizationSubject = new BehaviorSubject('');
        this.multiCompanyInvoiceSubject = new BehaviorSubject('');
        if (this.sessionService.role.IsFullAdmin && this.sessionService.feature.IsMultiCompanyMember) {
            this.staticDataService.companyAccount.pipe().subscribe(function (data) {
                if (data && data.Name) {
                    _this.multiCompanyOrganizationSubject.next(data.Name);
                    if (data.DefaultInvoiceType) {
                        _this.multiCompanyInvoiceSubject.next(data.DefaultInvoiceType.Name);
                    }
                }
            });
        }
    }
    AccountantSummaryService.prototype.updateCompanyInvoiceGeneral = function (company, isConfiguration) {
        if (isConfiguration && company && company.DefaultInvoiceType) {
            this.multiCompanyInvoiceSubject.next(company.DefaultInvoiceType.Name);
        }
        else if (company && company.InvoiceType) {
            this.multiCompanyInvoiceSubject.next(company.InvoiceType.Name);
        }
    };
    Object.defineProperty(AccountantSummaryService.prototype, "multiCompanyOrganization", {
        get: function () {
            return this.multiCompanyOrganizationSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantSummaryService.prototype, "multiCompanyInvoice", {
        get: function () {
            return this.multiCompanyInvoiceSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    AccountantSummaryService.prototype.leaveAccount = function () {
        return this.dataService.Account_RemoveCompany(Global.COMPANY.Id);
    };
    AccountantSummaryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountantSummaryService_Factory() { return new AccountantSummaryService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.StaticDataService), i0.ɵɵinject(i3.SessionService)); }, token: AccountantSummaryService, providedIn: "root" });
    return AccountantSummaryService;
}());
export { AccountantSummaryService };
