import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { Subject } from 'rxjs';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { CompanyService } from '../../../services/company.service';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/api/data.service";
import * as i2 from "../../../services/api/static-data.service";
import * as i3 from "../../../services/setting.service";
import * as i4 from "../../../services/session/session.service";
import * as i5 from "@uirouter/core";
import * as i6 from "../../../services/broadcast.service";
var CompanyAdvancedTypesService = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyAdvancedTypesService, _super);
    function CompanyAdvancedTypesService(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) {
        var _this = _super.call(this, dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) || this;
        _this.dataService = dataService;
        _this.staticDataService = staticDataService;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.transitionService = transitionService;
        _this.broadcaster = broadcaster;
        _this.defaultSelectedType = {
            descriptionClassSingle: '',
            defaultPayslipTextClassSingle: '',
            isActiveClassSingle: '',
            allowEditUnitTypeClassSingle: '',
            allowEditBaseAmountClassSingle: '',
            negativeDefaultClassSingle: '',
            sumFromReverseSignClassSingle: '',
            includeAlwaysClassSingle: '',
            triggersOvertimeSupplementClassSingle: '',
            sortOrderClassSingle: '',
            summaryCategoryClassSingle: '',
            unitTypeIdClassSingle: '',
            amountPerUnitClassSingle: '',
            unitsClassSingle: '',
            baseAmountClassSingle: '',
            amountClassSingle: '',
            defaultPersistanceTypeIdClassSingle: '',
            categoryIdClassSingle: '',
            eIncomeCategoryClassSingle: '',
            eIncomeSubCategoryClassSingle: '',
            accountTypeIdClassSingle: '',
            externalReferenceClassSingle: '',
            isIllnessClassSingle: '',
            salaryTypeClassSingle: '',
            displayDatesClassSingle: '',
            BaseSalaryType: {}
        };
        _this.selectedType = _this.defaultSelectedType;
        _this.rawSelectedTypeString = '';
        _this.option = { step: 0, spinners: false, decimals: 0, format: 'n0' };
        _this.optionN2 = { step: 0, spinners: false, decimals: 2, format: 'n2' };
        _this.ngUnsubscribe = new Subject();
        _this.listRevertValues = [];
        _this.isSave = false;
        return _this;
    }
    Object.defineProperty(CompanyAdvancedTypesService.prototype, "descriptionDisableLanguaged", {
        get: function () {
            if (this.selectedType.Description) {
                return this.selectedType.Description;
            }
            if (this.selectedType.BaseTimeEntryType) {
                return this.selectedType.BaseTimeEntryType.Description;
            }
            else if (this.selectedType.BaseSalaryType) {
                return this.selectedType.BaseSalaryType.Description;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyAdvancedTypesService.prototype, "defaultPayslipTextDisableLanguaged", {
        get: function () {
            if (this.selectedType.DefaultPayslipText) {
                return this.selectedType.DefaultPayslipText;
            }
            if (this.selectedType.BaseTimeEntryType) {
                return this.selectedType.BaseTimeEntryType.DefaultPayslipText;
            }
            else if (this.selectedType.BaseSalaryType) {
                return this.selectedType.BaseSalaryType.DefaultPayslipText;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyAdvancedTypesService.prototype, "isLanguageModuleEnable", {
        get: function () {
            return this.sessionService.feature.hasModuleId(5);
        },
        enumerable: true,
        configurable: true
    });
    CompanyAdvancedTypesService.prototype.ngOnInit = function () {
        this.rawSelectedTypeString = '';
    };
    Object.defineProperty(CompanyAdvancedTypesService.prototype, "allowShortcut", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.configuration.timeentrytypes';
        },
        enumerable: true,
        configurable: true
    });
    CompanyAdvancedTypesService.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    CompanyAdvancedTypesService.prototype.removeInheritValueBeforeEdit = function (advancedType) {
        // Save raw type
        this.listRevertValues = [];
        if (this.selectedType === this.defaultSelectedType) {
            return;
        }
        if (this.rawSelectedTypeString === '') {
            this.rawSelectedTypeString = JSON.stringify(this.selectedType);
        }
        // Specific value
        if (advancedType === 'Salary') {
            // string
            if (this.selectedType.descriptionClassSingle.length > 2) {
                this.selectedType.Description = null;
            }
            if (this.selectedType.defaultPayslipTextClassSingle.length > 2) {
                this.selectedType.DefaultPayslipText = null;
            }
            // another field
            if (this.selectedType.accountTypeIdClassSingle.length > 2) {
                this.selectedType.AccountTypeId = null;
            }
            if (this.selectedType.eIncomeSubCategoryClassSingle.length > 2) {
                this.selectedType.EIncomeSubCategory = null;
            }
            if (this.selectedType.eIncomeCategoryClassSingle.length > 2) {
                this.selectedType.EIncomeCategory = null;
            }
            if (this.selectedType.defaultPersistanceTypeIdClassSingle.length > 2) {
                this.selectedType.DefaultPersistanceTypeId = null;
            }
            if (this.selectedType.sortOrderClassSingle.length > 2) {
                this.selectedType.SortOrder = null;
            }
            if (this.selectedType.unitTypeIdClassSingle.length > 2) {
                this.selectedType.UnitTypeId = null;
            }
            if (this.selectedType.categoryIdClassSingle.length > 2) {
                this.selectedType.CategoryId = null;
            }
            if (this.selectedType.summaryCategoryClassSingle.length > 2) {
                this.selectedType.SummaryCategoryId = null;
            }
            if (this.selectedType.amountPerUnitClassSingle.length > 2) {
                this.selectedType.AmountPerUnit = null;
            }
            if (this.selectedType.unitsClassSingle.length > 2) {
                this.selectedType.Units = null;
            }
            if (this.selectedType.amountClassSingle.length > 2) {
                this.selectedType.Amount = null;
            }
            // Checkbox
            if (this.selectedType.isActiveClassSingle.length > 2) {
                this.selectedType.RawIsActive = null;
            }
            if (this.selectedType.allowEditUnitTypeClassSingle.length > 2) {
                this.selectedType.RawAllowEditUnitType = null;
            }
            if (this.selectedType.negativeDefaultClassSingle.length > 2) {
                this.selectedType.RawNegativeDefault = null;
            }
            if (this.selectedType.sumFromReverseSignClassSingle.length > 2) {
                this.selectedType.RawSumFromReverseSign = null;
            }
            if (this.selectedType.includeAlwaysClassSingle.length > 2) {
                this.selectedType.RawIncludeAlways = null;
            }
            if (this.selectedType.triggersOvertimeSupplementClassSingle.length > 2) {
                this.selectedType.RawTriggersOvertimeSupplement = null;
            }
            if (this.selectedType.displayDatesClassSingle.length > 2) {
                this.selectedType.RawDisplayDates = null;
            }
            if (this.selectedType.allowEditBaseAmountClassSingle.length > 2) {
                this.selectedType.RawAllowEditBaseAmount = null;
            }
        }
        else if (advancedType === 'TimeEntry') {
            // string
            if (this.selectedType.descriptionClassSingle.length > 2) {
                this.selectedType.Description = null;
            }
            if (this.selectedType.defaultPayslipTextClassSingle.length > 2) {
                this.selectedType.DefaultPayslipText = null;
            }
            // another field
            if (this.selectedType.sortOrderClassSingle.length > 2) {
                this.selectedType.SortOrder = null;
            }
            if (this.selectedType.externalReferenceClassSingle.length > 2) {
                this.selectedType.ExternalReference = null;
            }
            if (this.selectedType.unitTypeIdClassSingle.length > 2) {
                this.selectedType.UnitTypeId = null;
            }
            if (this.selectedType.salaryTypeClassSingle.length > 2) {
                this.selectedType.SalaryTypeId = null;
            }
            // Checkbox
            if (this.selectedType.isActiveClassSingle.length > 2) {
                this.selectedType.RawIsActive = null;
            }
            if (this.selectedType.isIllnessClassSingle.length > 2) {
                this.selectedType.RawIsIllness = null;
            }
        }
    };
    CompanyAdvancedTypesService.prototype.addInheritValueAfterEdit = function (advancedType) {
        var rawSelectedType = JSON.parse(this.rawSelectedTypeString);
        // Specific value
        if (advancedType === 'Salary') {
            // string
            if (this.selectedType.Description === null) {
                this.revertValue('BaseSalaryType', 'Description', rawSelectedType);
            }
            if (this.selectedType.DefaultPayslipText === null) {
                this.revertValue('BaseSalaryType', 'DefaultPayslipText', rawSelectedType);
            }
            // another field
            if (this.selectedType.AccountTypeId === null) {
                this.revertValue('BaseSalaryType', 'AccountTypeId', rawSelectedType);
            }
            if (this.selectedType.EIncomeSubCategory === null) {
                this.revertValue('BaseSalaryType', 'EIncomeSubCategory', rawSelectedType);
            }
            if (this.selectedType.EIncomeCategory === null) {
                this.revertValue('BaseSalaryType', 'EIncomeCategory', rawSelectedType);
            }
            if (this.selectedType.DefaultPersistanceTypeId === null) {
                this.revertValue('BaseSalaryType', 'DefaultPersistanceTypeId', rawSelectedType);
            }
            if (this.selectedType.SortOrder === null) {
                this.revertValue('BaseSalaryType', 'SortOrder', rawSelectedType);
            }
            if (this.selectedType.UnitTypeId === null) {
                this.revertValue('BaseSalaryType', 'UnitTypeId', rawSelectedType);
            }
            if (this.selectedType.CategoryId === null) {
                this.revertValue('BaseSalaryType', 'CategoryId', rawSelectedType);
            }
            if (this.selectedType.SummaryCategoryId === null) {
                this.revertValue('BaseSalaryType', 'SummaryCategoryId', rawSelectedType);
            }
            if (this.selectedType.AmountPerUnit === null) {
                this.revertValue('BaseSalaryType', 'AmountPerUnit', rawSelectedType);
            }
            if (this.selectedType.Units === null) {
                this.revertValue('BaseSalaryType', 'Units', rawSelectedType);
            }
            if (this.selectedType.Amount === null) {
                this.revertValue('BaseSalaryType', 'Amount', rawSelectedType);
            }
            if (this.selectedType.BaseAmount === null) {
                this.revertValue('BaseSalaryType', 'BaseAmount', rawSelectedType);
            }
            // Checkbox
            if (this.selectedType.RawIsActive === null) {
                this.revertValue('BaseSalaryType', 'IsActive', rawSelectedType);
            }
            if (this.selectedType.RawAllowEditUnitType === null) {
                this.revertValue('BaseSalaryType', 'AllowEditUnitType', rawSelectedType);
            }
            if (this.selectedType.RawNegativeDefault === null) {
                this.revertValue('BaseSalaryType', 'NegativeDefault', rawSelectedType);
            }
            if (this.selectedType.RawSumFromReverseSign === null) {
                this.revertValue('BaseSalaryType', 'SumFromReverseSign', rawSelectedType);
            }
            if (this.selectedType.RawIncludeAlways === null) {
                this.revertValue('BaseSalaryType', 'IncludeAlways', rawSelectedType);
            }
            if (this.selectedType.RawTriggersOvertimeSupplement === null) {
                this.revertValue('BaseSalaryType', 'TriggersOvertimeSupplement', rawSelectedType);
            }
            if (this.selectedType.RawDisplayDates === null) {
                this.revertValue('BaseSalaryType', 'DisplayDates', rawSelectedType);
            }
            if (this.selectedType.RawAllowEditBaseAmount === null) {
                this.revertValue('BaseSalaryType', 'AllowEditBaseAmount', rawSelectedType);
            }
        }
        else if (advancedType === 'TimeEntry') {
            // string
            if (this.selectedType.Description === null) {
                this.revertValue('BaseTimeEntryType', 'Description', rawSelectedType);
            }
            if (this.selectedType.DefaultPayslipText === null) {
                this.revertValue('BaseTimeEntryType', 'DefaultPayslipText', rawSelectedType);
            }
            // another field
            if (this.selectedType.SortOrder === null) {
                this.revertValue('BaseTimeEntryType', 'SortOrder', rawSelectedType);
            }
            if (this.selectedType.ExternalReference === null) {
                this.revertValue('BaseTimeEntryType', 'ExternalReference', rawSelectedType);
            }
            if (this.selectedType.UnitTypeId === null) {
                this.revertValue('BaseTimeEntryType', 'UnitTypeId', rawSelectedType);
            }
            if (this.selectedType.SalaryTypeId === null) {
                this.revertValue('BaseTimeEntryType', 'SalaryTypeId', rawSelectedType);
            }
            // Checkbox
            if (this.selectedType.RawIsActive === null) {
                this.revertValue('BaseTimeEntryType', 'IsActive', rawSelectedType);
            }
            if (this.selectedType.RawIsIllness === null) {
                this.revertValue('BaseTimeEntryType', 'IsIllness', rawSelectedType);
            }
        }
        this.rawSelectedTypeString = '';
    };
    CompanyAdvancedTypesService.prototype.onChangeInheritValue = function (valueField, value, index) {
        this.listRevertValues = this.listRevertValues.filter(function (element) {
            return element.index !== index;
        });
        var valueFieldClassSingle = this.capitalizeFirstLetter(valueField);
        if (this.selectedType.BaseSalaryType &&
            this.selectedType.BaseSalaryType[valueField] === value &&
            this.selectedType[valueFieldClassSingle] &&
            this.selectedType[valueFieldClassSingle].length > 2) {
            this.listRevertValues.push({
                field: valueField,
                index: index
            });
        }
    };
    CompanyAdvancedTypesService.prototype.capitalizeFirstLetter = function (text) {
        return text.charAt(0).toLowerCase() + text.slice(1) + 'ClassSingle';
    };
    CompanyAdvancedTypesService.prototype.revertValue = function (type, field, rawObject) {
        var inheritString = this.capitalizeFirstLetter(field);
        if (!this.isSave || !this.tabDirty) {
            if (rawObject[field] !== null) {
                this.selectedType[field] = rawObject[field];
            }
        }
        else {
            if (this.selectedType[type] === null ||
                (this.selectedType[field] !== null && this.selectedType[field] !== this.selectedType[type][field])) {
                return;
            }
            if (this.selectedType[type][field] === rawObject[field] && rawObject[field] !== null) {
                this.selectedType[field] = null;
            }
            else {
                this.selectedType[field] = rawObject[field];
            }
        }
    };
    CompanyAdvancedTypesService.prototype.checkSalaryType = function (type) {
        type.DimensionDistributionOptionId = type.DimensionDistributionOptionId || 1;
    };
    CompanyAdvancedTypesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyAdvancedTypesService_Factory() { return new CompanyAdvancedTypesService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.StaticDataService), i0.ɵɵinject(i3.SettingService), i0.ɵɵinject(i4.SessionService), i0.ɵɵinject(i5.TransitionService), i0.ɵɵinject(i6.BroadcastService)); }, token: CompanyAdvancedTypesService, providedIn: "root" });
    return CompanyAdvancedTypesService;
}(CompanyService));
export { CompanyAdvancedTypesService };
