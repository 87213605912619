import { EventEmitter, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Global } from '../../../common/global';
import { SessionService } from '../../../services/session/session.service';
var PayslipMobileComponent = /** @class */ (function () {
    function PayslipMobileComponent(renderer, sessionService, translateService) {
        this.renderer = renderer;
        this.sessionService = sessionService;
        this.translateService = translateService;
        this.iconClick = new EventEmitter();
        this.selectedSalaryStatementChange = new EventEmitter();
        this.Revert = new EventEmitter();
        this.classoverlayScreen = '';
        this.classShowSelectPeriodDialog = '';
        this.detailSalaryStatement = [];
        this.isFinalize = false;
        this.currentSalaryStatementsNoneType = [];
        this.translationServiceTerms = [
            'EmployeeGeneral.PeriodStart',
            'EmployeeGeneral.PeriodEnd',
            'EmployeePayslip.BaseSalary',
            'EmployeePayslip.HoursWorked',
            'EmployeePayslip.TaxDeduction',
            'EmployeePayslip.TaxRate',
            'EmployeePayslip.PaidOut',
            'EmployeePayslip.SpentVacation',
            'EmployeePayslip.VacationMoneyEarnedGross',
            'EmployeePayslip.VacationMoneyEarnedNet'
        ];
    }
    Object.defineProperty(PayslipMobileComponent.prototype, "selectedSalaryStatement", {
        get: function () {
            return this.currentSelectedSalaryStatement;
        },
        set: function (value) {
            if (value !== this.currentSelectedSalaryStatement) {
                this.currentSelectedSalaryStatement = value;
                this.selectedSalaryStatementChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipMobileComponent.prototype, "salaryStatementsNoneType", {
        get: function () {
            return this.currentSalaryStatementsNoneType;
        },
        set: function (value) {
            this.currentSalaryStatementsNoneType = value;
            this.selectedFirstRow = false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipMobileComponent.prototype, "IsPaymentApprover", {
        get: function () {
            return Global.SESSION && Global.SESSION.IsPaymentApprover;
        },
        enumerable: true,
        configurable: true
    });
    PayslipMobileComponent.prototype.convertDateToString = function (dateconvert) {
        if (dateconvert) {
            var currentdate = new Date(dateconvert);
            return this.sessionService.toString(currentdate);
        }
        return '';
    };
    Object.defineProperty(PayslipMobileComponent.prototype, "IsReadonly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    PayslipMobileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translateService.get(this.translationServiceTerms).subscribe(function (translations) {
            _this.setTranslationTerms(translations);
        });
    };
    PayslipMobileComponent.prototype.setTranslationTerms = function (translations) {
        this.labelPeriodFrom = translations['EmployeeGeneral.PeriodStart'];
        this.labelPeriodTo = translations['EmployeeGeneral.PeriodEnd'];
        this.labelBaseSalary = translations['EmployeePayslip.BaseSalary'];
        this.labelHoursWorked = translations['EmployeePayslip.HoursWorked'];
        this.labelTaxDeduction = translations['EmployeePayslip.TaxDeduction'];
        this.labelTaxRate = translations['EmployeePayslip.TaxRate'];
        this.labelPaidOut = translations['EmployeePayslip.PaidOut'];
        this.labelSpentVacation = translations['EmployeePayslip.SpentVacation'];
        this.labelVacationMoneyEarnedGross = translations['EmployeePayslip.VacationMoneyEarnedGross'];
        this.labelVacationMoneyEarnedNet = translations['EmployeePayslip.VacationMoneyEarnedNet'];
    };
    PayslipMobileComponent.prototype.appIconClick = function ($event, salaryStatement, action) {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
            var event_1 = {};
            event_1.dataItem = salaryStatement;
            event_1.iconAction = action;
            this.iconClick.emit(event_1);
        }
    };
    Object.defineProperty(PayslipMobileComponent.prototype, "isAllfinalize", {
        get: function () {
            return this.salaryStatementsNoneType &&
                this.salaryStatementsNoneType.length > 0 &&
                this.salaryStatementsNoneType.filter(function (model) { return model.IsFinalized === true; }).length ===
                    this.salaryStatementsNoneType.length
                ? true
                : false;
        },
        enumerable: true,
        configurable: true
    });
    PayslipMobileComponent.prototype.onSelectsalaryStatement = function (salaryStatement) {
        this.selectedSalaryStatement = this.salaryStatementsNoneType.find(function (model) { return model.Id === salaryStatement.Id; });
        this.selectedFirstRow = this.salaryStatementsNoneType[0].Id === this.selectedSalaryStatement.Id;
        this.detailSalaryStatement = this.createDataSourceSalaryStatements();
        this.currentIdSelect = salaryStatement.Id;
        this.isFinalize = salaryStatement.IsFinalized;
        this.onShowSelectPeriodDialog();
    };
    PayslipMobileComponent.prototype.RevertPayslips = function () {
        var _this = this;
        if (!this.selectedFirstRow) {
            return;
        }
        this.onShowSelectPeriodDialog();
        setTimeout(function () {
            _this.Revert.emit();
        }, 300);
    };
    PayslipMobileComponent.prototype.onShowSelectPeriodDialog = function () {
        var _this = this;
        if (this.classShowSelectPeriodDialog) {
            this.classShowSelectPeriodDialog = '';
            setTimeout(function () {
                _this.currentIdSelect = null;
                _this.isFinalize = false;
                _this.classoverlayScreen = '';
            }, 200);
            return;
        }
        this.classoverlayScreen = 'overlayScreen-enable';
        setTimeout(function () {
            _this.classShowSelectPeriodDialog = 'PreviewGridMobileEnable';
        });
    };
    PayslipMobileComponent.prototype.formatNumber = function (unitstring) {
        if (unitstring) {
            return this.sessionService
                .toString(parseFloat(unitstring.toString()), '0.00')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        else if (unitstring === 0) {
            return '0,00';
        }
        return '';
    };
    PayslipMobileComponent.prototype.createDataSourceSalaryStatements = function () {
        return [
            {
                label: this.labelPeriodFrom,
                value: this.convertDateToString(this.selectedSalaryStatement.PeriodFrom.toString())
            },
            {
                label: this.labelPeriodTo,
                value: this.convertDateToString(this.selectedSalaryStatement.PeriodTo.toString())
            },
            {
                label: this.labelBaseSalary,
                value: this.formatNumber(this.selectedSalaryStatement.PrimaryIncomeAmount)
            },
            { label: this.labelHoursWorked, value: this.formatNumber(this.selectedSalaryStatement.HoursWorked) },
            {
                label: this.labelTaxDeduction,
                value: this.formatNumber(this.selectedSalaryStatement.AppliedTaxDeduction)
            },
            { label: this.labelTaxRate, value: this.formatNumber(this.selectedSalaryStatement.TaxRate * 100) + '%' },
            { label: this.labelPaidOut, value: this.formatNumber(this.selectedSalaryStatement.PayoutAmount) },
            {
                label: this.labelSpentVacation,
                value: this.formatNumber(this.selectedSalaryStatement.VacationDaysSpent)
            },
            {
                label: this.labelVacationMoneyEarnedGross,
                value: this.formatNumber(this.selectedSalaryStatement.VacationMoneyEarnedGross)
            },
            {
                label: this.labelVacationMoneyEarnedNet,
                value: this.formatNumber(this.selectedSalaryStatement.VacationMoneyEarnedNet)
            }
        ];
    };
    return PayslipMobileComponent;
}());
export { PayslipMobileComponent };
