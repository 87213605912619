import { NotTranslatedService } from './not-translated.service';
import * as i0 from "@angular/core";
import * as i1 from "./not-translated.service";
var MissingTranslationService = /** @class */ (function () {
    function MissingTranslationService(notTranslatedService) {
        this.notTranslatedService = notTranslatedService;
    }
    MissingTranslationService.prototype.handle = function (params) {
        this.notTranslatedService.notTranslated(params.key);
    };
    MissingTranslationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MissingTranslationService_Factory() { return new MissingTranslationService(i0.ɵɵinject(i1.NotTranslatedService)); }, token: MissingTranslationService, providedIn: "root" });
    return MissingTranslationService;
}());
export { MissingTranslationService };
