<app-fixed-panel
  [excludedComponents]="listClassExcluded"
  [editMode]="service.editMode"
  [specificClassName]="'Island-accountant--config'"
>
  <div class="accountant-container" (dblclick)="tabDblclick()" [ngClass]="{ 'is-editing': service.editMode }">
    <div class="Island Island-accountant Island-accountant--config">
      <div class="accountant-managing">
        <fieldset class="Form--horizontal">
          <legend class="Card-title">{{ 'Accountant.MCO' | translate }}</legend>
          <div class="Cards">
            <div class="Card Card-configuration">
              <app-text-edit
                [label]="'Accountant.FullName' | translate"
                [required]="true"
                [editMode]="service.editMode"
                [type]="'text'"
                [(value)]="service.account.Name"
                (valueChange)="onNameChange()"
                [(isDirty)]="service.isDirty"
              >
              </app-text-edit>
              <app-text-edit
                [label]="'Accountant.ContactEmail' | translate"
                [editMode]="service.editMode"
                [type]="'text'"
                [(value)]="service.account.MasterCompanyEmail"
                (valueChange)="onEmailChange()"
                [(isDirty)]="service.isDirty"
              >
              </app-text-edit>
              <app-check-edit
                [label]="'Accountant.IsPublic' | translate"
                [editMode]="service.editMode"
                [(value)]="service.account.IsPublic"
                [(isDirty)]="service.isDirty"
                [helpTooltip]="'Accountant.IsPublicTooltip' | translate: { SystemAlias: branding.SystemAlias }"
                [isAccountantConfig]="true"
              >
              </app-check-edit>
              <app-combobox-edit
                [label]="'Accountant.AccountTypeId' | translate"
                [nullValueLabel]="'DropdownList.None' | translate"
                [editMode]="service.editMode"
                [comboboxDataSource]="staticDataService.AccountType | async"
                [idField]="'Id'"
                [textField]="'Name'"
                [(value)]="service.account.AccountTypeId"
                [(isDirty)]="service.isDirty"
              >
              </app-combobox-edit>
              <app-combobox-edit
                [label]="'Accountant.InvoiceTypeId' | translate"
                [editMode]="service.editMode"
                [comboboxDataSource]="staticDataService.accountInvoiceTypes | async"
                [textField]="'Name'"
                [idField]="'Id'"
                [(value)]="service.account.DefaultInvoiceTypeId"
                [(isDirty)]="service.isDirty"
              >
              </app-combobox-edit>
              <app-text-edit
                [label]="'Accountant.Description' | translate"
                [editMode]="service.editMode"
                [(value)]="service.account.Description"
                [isTextArea]="true"
                (valueChange)="onValueChange()"
                *ngIf="service.account.Description.length > 0"
                [(isDirty)]="service.isDirty"
                [textAreaLimitChar]="1024"
                [isShowLimit]="false"
                placeholder="{{ 'Accountant.Description' | translate }}"
              >
              </app-text-edit>

              <div
                *ngIf="service.account.Description.length <= 0"
                [ngClass]="{ 'accountant-description-container': !service.editMode }"
              >
                <app-text-edit
                  [label]="'Accountant.Description' | translate"
                  [editMode]="service.editMode"
                  [(value)]="service.account.Description"
                  [isTextArea]="true"
                  [textAreaLimitChar]="10000"
                  [isShowLimit]="false"
                  (valueChange)="onValueChange()"
                  placeholder="{{ 'Accountant.Description' | translate }}"
                  [(isDirty)]="service.isDirty"
                >
                </app-text-edit>
                <span class="text-hint" *ngIf="!service.account.Description && !service.editMode">{{
                  'Accountant.Description' | translate
                }}</span>
              </div>
            </div>
          </div>
        </fieldset>
      </div>

      <div class="accountant-managing">
        <fieldset class="Form--horizontal">
          <legend class="Card-title">{{ 'Accountant.INV' | translate }}</legend>
          <div class="Cards">
            <div class="Card Card-configuration">
              <div class="Card-company-combobox FormElement FormElement-ViewMode">
                <app-radio-edit
                  class="FormElement-label"
                  [editMode]="service.editMode"
                  [(value)]="selectedCompanyId"
                  (valueChange)="onCompanyChange($event)"
                >
                  <span *ngFor="let type of service.listCompany">
                    <app-radio-edit-option
                      [isDisabled]="type.Id !== selectedCompanyId && !service.editMode"
                      [value]="type.Id"
                      [label]="type.Title | translate"
                    >
                    </app-radio-edit-option>
                  </span>
                </app-radio-edit>

                <app-combobox-edit
                  *ngIf="selectedCompanyId === 1"
                  class="FormElement-control"
                  [editMode]="service.editMode"
                  [comboboxDataSource]="service.listCompanies"
                  [textField]="'Name'"
                  [idField]="'Id'"
                  [(value)]="service.internalCompany.CompanyId"
                  (valueChange)="onInternalCompanyChange($event)"
                  [(isDirty)]="service.isDirty"
                >
                </app-combobox-edit>
              </div>
              <div *ngIf="service.currentCompany.CompanyId">
                <app-text-edit
                  [label]="'Accountant.CVR' | translate"
                  [editMode]="false"
                  [type]="'text'"
                  [(value)]="service.currentCompany.CVR"
                >
                </app-text-edit>
                <app-text-edit
                  [label]="'Accountant.FullName' | translate"
                  [editMode]="false"
                  [type]="'text'"
                  [(value)]="service.currentCompany.Name"
                >
                </app-text-edit>
                <app-text-edit
                  [label]="'Accountant.Address' | translate"
                  [editMode]="false"
                  [type]="'text'"
                  [(value)]="service.currentCompany.Address"
                >
                </app-text-edit>
                <app-text-edit
                  [label]="'Accountant.PostalCode' | translate"
                  [editMode]="false"
                  [type]="'text'"
                  [(value)]="service.currentCompany.PostalCode"
                >
                </app-text-edit>
                <app-text-edit
                  [label]="'Accountant.City' | translate"
                  [editMode]="false"
                  [type]="'text'"
                  [(value)]="service.currentCompany.City"
                >
                </app-text-edit>
                <app-text-edit
                  [label]="'Accountant.Country' | translate"
                  [editMode]="false"
                  [type]="'text'"
                  [(value)]="service.currentCompany.Country"
                >
                </app-text-edit>
                <app-text-edit
                  [label]="'Accountant.EAN' | translate"
                  [editMode]="false"
                  [type]="'text'"
                  [(value)]="service.currentCompany.EAN"
                >
                </app-text-edit>
              </div>
              <div *ngIf="!service.currentCompany.CompanyId">
                <app-text-edit
                  [label]="'Accountant.CVR' | translate"
                  [editMode]="service.editMode"
                  [type]="'text'"
                  [(value)]="service.currentCompany.CVR"
                  [(isDirty)]="service.isDirty"
                >
                </app-text-edit>
                <app-text-edit
                  [label]="'Accountant.FullName' | translate"
                  [editMode]="service.editMode"
                  [type]="'text'"
                  [(value)]="service.currentCompany.Name"
                  [(isDirty)]="service.isDirty"
                >
                </app-text-edit>
                <app-text-edit
                  [label]="'Accountant.Address' | translate"
                  [editMode]="service.editMode"
                  [type]="'text'"
                  [(value)]="service.currentCompany.Address"
                  [(isDirty)]="service.isDirty"
                >
                </app-text-edit>
                <app-text-edit
                  [label]="'Accountant.PostalCode' | translate"
                  [editMode]="service.editMode"
                  [type]="'text'"
                  [(value)]="service.currentCompany.PostalCode"
                  [(isDirty)]="service.isDirty"
                >
                </app-text-edit>
                <app-text-edit
                  [label]="'Accountant.City' | translate"
                  [editMode]="service.editMode"
                  [type]="'text'"
                  [(value)]="service.currentCompany.City"
                  [(isDirty)]="service.isDirty"
                >
                </app-text-edit>
                <app-text-edit
                  [label]="'Accountant.Country' | translate"
                  [editMode]="service.editMode"
                  [type]="'text'"
                  [(value)]="service.currentCompany.Country"
                  [(isDirty)]="service.isDirty"
                >
                </app-text-edit>
                <app-text-edit
                  [label]="'Accountant.EAN' | translate"
                  [editMode]="service.editMode"
                  [type]="'text'"
                  [(value)]="service.currentCompany.EAN"
                  [(isDirty)]="service.isDirty"
                >
                </app-text-edit>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</app-fixed-panel>

<div [ngClass]="{ 'is-editing': service.editMode }">
  <div class="Actions" *ngIf="!isMobile && !service.editMode" [ngClass]="isIOSApp ? 'is-ios-app' : ''">
    <app-menu-button
      [editButtonMenu]="true"
      [editMode]="false"
      (menuChangeClick)="onEditModeChange($event)"
      [menuCondition]="true"
      *ngIf="service.isShowEditMode"
    >
    </app-menu-button>
    <app-menu-button
      *ngIf="reportDialogService.isVisibleReportIcon"
      [menuCondition]="true"
      [menuIcon]="'File'"
      [menuLabel]="'Employee.Reports'"
      [menuTooltip]="'Employee.Reports'"
      [actionButtonMenu]="true"
      [menuButtonClass]="'Action-button Action-buttonReports'"
      [menuButtonId]="'ActionButtonReports'"
      (menuClick)="reportDialogService.onShowReportsEventClick()"
    >
    </app-menu-button>
  </div>
  <div class="Actions" *ngIf="isMobile && !service.editMode" [ngClass]="isIOSApp ? 'is-ios-app' : ''">
    <app-menu-button
      [menuIcon]="'ThreeDots'"
      [menuLabel]="'More.Title'"
      [menuTooltip]="'More.Title'"
      [multiMenuItems]="true"
    >
    </app-menu-button>
    <app-menu-button
      [editButtonMenu]="true"
      [editMode]="false"
      (menuChangeClick)="onEditModeChange($event)"
      [menuCondition]="true"
      *ngIf="service.isShowEditMode"
    >
    </app-menu-button>
  </div>
  <div class="EditingInfo Actions" *ngIf="service.editMode">
    <app-button
      [buttonClass]="'Discard'"
      (clickEvent)="confirmAndDiscardChanges($event)"
      [label]="'Employee.DiscardButton' | translate"
      *ngIf="service.editMode"
    ></app-button>
    <app-menu-button
      [editButtonMenu]="true"
      [editMode]="true"
      (menuChangeClick)="onEditModeChange($event)"
      [menuCondition]="true"
      class="menu-button--editing"
    >
    </app-menu-button>
  </div>
</div>

<app-dialog
  (action)="discardDialogAction($event)"
  [(visible)]="discardDialogVisible"
  [leadingText]="'Employee.DiscardMessage' | translate"
>
  <app-dialog-action type="CancelDiscard"></app-dialog-action>
  <app-dialog-action type="ConfirmDiscard"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  (action)="discardDialogAction('ConfirmDiscard')"
  [(visible)]="warningNoNameMessage"
  [leadingText]="'Accountant.NameRequired' | translate"
>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>

<app-dialog (action)="onConfirmSaveDataOnChangeState($event)" [(visible)]="service.showSaveDataConfirmationChangeState">
  <div [innerHTML]="'Employee.UnsavedData' | translate"></div>
  <app-dialog-action [disabled]="sessionService.isSubmitting" type="DiscardAndLeave"></app-dialog-action>
  <app-dialog-action [disabled]="sessionService.isSubmitting" type="Stay"></app-dialog-action>
  <app-dialog-action [disabled]="sessionService.isSubmitting" type="ContinueAndSave"></app-dialog-action>
</app-dialog>
