import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { Global } from '../../common/global';
import { GridHelper } from '../../common/grid-helper';
import { SalaryRecordCalculation } from '../../employee/employee-tab/payroll/controls/salary-record-calculation';
import { UnitTypeEnum } from '../../model/enum';
import {
  GetSalaryRecordsOverview_Result,
  IGetSalaryRecordsOverview_Result,
  ISalaryCycle,
  ISalaryRecord,
  ISalaryRecordsOverviewFilterRequest,
  ISalaryType,
  ISimpleSalaryRecordUpdateRequest,
  IUserEmploymentTemplate
} from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { CompanyService } from '../../services/company.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';

interface IPayrollSalaryType extends ISalaryType {
  displayName?: string;
  unitTypeTitle?: string;
  IsVisibleValue?: boolean;
  IsActiveValue?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CompanyPayrollDataService extends CompanyService implements OnDestroy {
  constructor(
    protected dataService: DataService,
    protected staticDataService: StaticDataService,
    protected settingService: SettingService,
    protected sessionService: SessionService,
    protected transitionService: TransitionService,
    protected broadcaster: BroadcastService
  ) {
    super(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster);
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy() {
    this.onDestroyLocalVariable();

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private onDestroyLocalVariable() {
    if (this.gridDataSubject) {
      this.gridDataSubject.next([]);
    }
  }

  private salaryCycleIdValue: number;
  public get salaryCycleId(): number {
    return this.salaryCycleIdValue;
  }

  public set salaryCycleId(value: number) {
    if (value !== this.salaryCycleIdValue) {
      this.salaryCycleIdValue = value;
      this.getData();
    }
  }

  private templateIdValue: number;
  public get templateId(): number {
    return this.templateIdValue;
  }

  public set templateId(value: number) {
    if (value !== this.templateIdValue) {
      this.templateIdValue = value;
      this.getData();
    }
  }

  private departmentIdValue: number;
  public get departmentId(): number {
    return this.departmentIdValue;
  }

  public set departmentId(value: number) {
    if (value !== this.departmentIdValue) {
      this.departmentIdValue = value;
      this.getData();
    }
  }

  private showEmployeeWithNoDataValue: boolean;
  public get showEmployeeWithNoData(): boolean {
    return this.showEmployeeWithNoDataValue;
  }

  public set showEmployeeWithNoData(value: boolean) {
    if (value !== this.showEmployeeWithNoDataValue) {
      this.showEmployeeWithNoDataValue = value;
      this.getData();
    }
  }

  public overviewMaxIndex: number;
  public isWattingLoading: boolean;
  public hasAddNewColumn: boolean;

  public onverviewIndexList: number[] = [];
  public original: IGetSalaryRecordsOverview_Result = new GetSalaryRecordsOverview_Result();
  public formGroup: FormGroup;
  public salaryTypes: IPayrollSalaryType[] = [];
  public availableSalaryTypes: ISalaryType[] = [];

  public componentStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private gridDataSubject: BehaviorSubject<IGetSalaryRecordsOverview_Result[]>;
  public get gridData(): Observable<IGetSalaryRecordsOverview_Result[]> {
    if (!this.gridDataSubject) {
      this.gridDataSubject = new BehaviorSubject<IGetSalaryRecordsOverview_Result[]>([]);
    }
    return this.gridDataSubject.asObservable();
  }

  private allSalaryTypesSubject: BehaviorSubject<ISalaryType[]>;
  public get allSalaryTypes(): Observable<ISalaryType[]> {
    if (!this.allSalaryTypesSubject) {
      this.allSalaryTypesSubject = new BehaviorSubject<ISalaryType[]>([]);
    }

    return this.allSalaryTypesSubject.asObservable();
  }

  private salaryCycleSubject: BehaviorSubject<ISalaryCycle[]>;
  public get salaryCycle(): Observable<ISalaryCycle[]> {
    if (!this.salaryCycleSubject) {
      this.loadSalaryCycle();
    }
    return this.salaryCycleSubject.asObservable();
  }

  private templatesSubject: BehaviorSubject<IUserEmploymentTemplate[]>;
  public get templates(): Observable<IUserEmploymentTemplate[]> {
    if (!this.templatesSubject) {
      this.loadTemplates();
    }
    return this.templatesSubject.asObservable();
  }

  private loadTemplates() {
    if (!this.templatesSubject) {
      this.templatesSubject = new BehaviorSubject<IUserEmploymentTemplate[]>([]);
    }

    this.dataService
      .EmploymentTemplates_GetEmploymentTemplates(false)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((templates: IUserEmploymentTemplate[]) => {
        this.templatesSubject.next(templates);
      });
  }

  private loadSalaryCycle() {
    if (!this.salaryCycleSubject) {
      this.salaryCycleSubject = new BehaviorSubject<ISalaryCycle[]>([]);
    }

    this.dataService
      .Miscellaneous_GetUsedSalaryCycles()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        tap((data: ISalaryCycle[]) => {
          this.salaryCycleSubject.next(data);
        })
      )
      .subscribe(
        (data: ISalaryCycle[]) => {
          this.getSalaryTypes();
        },
        () => {
          this.onCloseOverlayDone();
        }
      );
  }

  public getSalaryTypes() {
    this.dataService
      .SalaryTypes_GetRawSalaryTypesTranslated()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        tap((salaryTypes: ISalaryType[]) => {
          salaryTypes.forEach((salaryType: ISalaryType) => {
            this.assignSalaryTypeText(salaryType as IPayrollSalaryType, salaryTypes);
          });

          this.availableSalaryTypes = (salaryTypes as IPayrollSalaryType[]).filter(
            (s: IPayrollSalaryType) => s.IsVisibleValue && s.IsActiveValue
          );

          this.salaryTypes = this.availableSalaryTypes.filter(
            (s: ISalaryType) => s.OverviewIndex || s.OverviewIndex === 0
          );
          this.salaryTypes = this.salaryTypes.sort((a: ISalaryType, b: ISalaryType) => {
            return GridHelper.sortByNumberValue(a.OverviewIndex, b.OverviewIndex);
          });
          this.salaryTypes = this.salaryTypes.slice(0, 7);
          this.allSalaryTypesSubject.next(salaryTypes);
        })
      )
      .subscribe();
  }

  public closeOverlay(): Observable<void> {
    return Observable.create((ob: any) => {
      this.isWattingLoading = false;

      setTimeout(() => {
        this.componentStateChange.emit(true);
        ob.next();
      });
    });
  }

  private onCloseOverlayDone() {
    this.closeOverlay()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe();
  }

  public assignSalaryTypeText(salaryType: IPayrollSalaryType, salaryTypes: ISalaryType[]): void {
    const existingSalaryType: ISalaryType = salaryTypes.find((s: ISalaryType) => s.Id === salaryType.Id);

    salaryType.SalaryTypeTranslations = salaryType.SalaryTypeTranslations
      ? salaryType.SalaryTypeTranslations
      : existingSalaryType
      ? existingSalaryType.SalaryTypeTranslations
      : undefined;
    salaryType.BaseSalaryType = salaryType.BaseSalaryType
      ? salaryType.BaseSalaryType
      : existingSalaryType
      ? existingSalaryType.BaseSalaryType
      : undefined;

    salaryType.IsActiveValue =
      salaryType.IsActive !== null
        ? salaryType.IsActive
        : salaryType.BaseSalaryType
        ? salaryType.BaseSalaryType.IsActive
        : false;
    salaryType.IsVisibleValue =
      salaryType.IsVisible !== null
        ? salaryType.IsVisible
        : salaryType.BaseSalaryType
        ? salaryType.BaseSalaryType.IsVisible
        : false;

    if (!salaryType.SalaryTypeTranslations) {
      return;
    }

    if (Global.SESSION.SignOnToken.LanguageId === 1) {
      salaryType.Name =
        salaryType.SalaryTypeTranslations[0].Name && this.hasLanguageModule
          ? salaryType.SalaryTypeTranslations[0].Name
          : salaryType.Name;
    } else if (Global.SESSION.SignOnToken.LanguageId === 2) {
      salaryType.Name =
        salaryType.SalaryTypeTranslations[1].Name && this.hasLanguageModule
          ? salaryType.SalaryTypeTranslations[1].Name
          : salaryType.Name;
    } else if (Global.SESSION.SignOnToken.LanguageId === 3) {
      salaryType.Name =
        salaryType.SalaryTypeTranslations[2].Name && this.hasLanguageModule
          ? salaryType.SalaryTypeTranslations[2].Name
          : salaryType.Name;
    }

    salaryType.displayName =
      salaryType.Name !== null
        ? salaryType.Name
        : salaryType.BaseSalaryType
        ? salaryType.BaseSalaryType.Name
        : undefined;
  }

  public saveCurrent(dataItem: any, property: string): void {
    if (!dataItem || !property) {
      return;
    }

    let index: any = property
      .replace('SalaryRecord', '')
      .replace('PerUnit', '')
      .replace('Units', '')
      .replace('Amount', '');
    if (index === undefined) {
      return;
    }

    index = parseInt(index, 10);
    const formGroupValue: number = this.formGroup.value[property];
    const updatedValue: number = dataItem[property];

    if (formGroupValue !== updatedValue) {
      const salaryRecordId: number = dataItem[`SalaryRecord${index}Id`];
      const calculator: SalaryRecordCalculation = this.createSalaryRecordCalculation({
        Units: dataItem[`SalaryRecord${index}Units`],
        AmountPerUnit: dataItem[`SalaryRecord${index}PerUnit`],
        Amount: dataItem[`SalaryRecord${index}Amount`]
      });
      const request: any = this.createSalaryRecordUpdateRequest(calculator, property, updatedValue);
      dataItem[`SalaryRecord${index}Units`] = request.Units;
      dataItem[`SalaryRecord${index}PerUnit`] = request.AmountPerUnit;
      dataItem[`SalaryRecord${index}Amount`] = request.Amount;

      if (salaryRecordId) {
        request.Id = salaryRecordId;
        this.dataService.SalaryRecords_UpdateSalaryRecordSimple(request).subscribe(
          () => {},
          () => {
            this.getSalaryTypes();
          }
        );
      } else {
        request.UserEmploymentId = this.original.UserEmploymentId;
        request.SalaryTypeId = this.salaryTypes[index - 1].Id;
        this.dataService.SalaryRecords_CreateSalaryRecordSimple(request).subscribe(
          (data: ISalaryRecord) => {
            dataItem[`SalaryRecord${index}Id`] = data.Id;
          },
          () => {
            this.getSalaryTypes();
          }
        );
      }
    }
  }

  private createSalaryRecordUpdateRequest(
    calculator: SalaryRecordCalculation,
    property: string,
    value: number
  ): ISimpleSalaryRecordUpdateRequest {
    value = value && value !== 0 ? value : undefined;
    const isAmountPerUnitProperty: boolean = property.indexOf('PerUnit') > -1;
    const isUnitsProperty: boolean = !isAmountPerUnitProperty && property.indexOf('Units') > -1;
    const isAmountProperty: boolean = !isAmountPerUnitProperty && property.indexOf('Amount') > -1;

    let calculationResult: any;
    if (isAmountPerUnitProperty) {
      calculationResult = this.updateAmountPerUnit(calculator, value);
    }

    if (isUnitsProperty) {
      calculationResult = this.updateUnits(calculator, value);
    }

    if (isAmountProperty) {
      calculationResult = this.updateAmount(calculator, value);
    }

    const updateSalaryRecord: ISimpleSalaryRecordUpdateRequest = {
      Id: 0,
      AmountPerUnit: this.formatCorrectNumberForRequest(calculationResult.amountPerUnit),
      Amount: this.formatCorrectNumberForRequest(calculationResult.amount),
      Units: this.formatCorrectNumberForRequest(calculationResult.units)
    };

    return updateSalaryRecord;
  }

  private formatCorrectNumberForRequest(value: number): number {
    if (value === 0) {
      return 0;
    }
    if (!value) {
      return null;
    }
    return value;
  }

  private createSalaryRecordCalculation(dataItem: any): SalaryRecordCalculation {
    const calculator: SalaryRecordCalculation = new SalaryRecordCalculation();
    calculator.Amount = dataItem.Amount;
    calculator.Units = dataItem.Units;
    calculator.AmountPerUnit = dataItem.AmountPerUnit;
    calculator.UnitType = UnitTypeEnum.Days;
    return calculator;
  }

  private updateAmountPerUnit(calculator: SalaryRecordCalculation, value: number): any {
    calculator.AmountPerUnit = value;
    calculator.calculateAmount();
    calculator.calculateUnits();
    return {
      amountPerUnit: value,
      amount: calculator.Amount,
      units: calculator.Units
    };
  }

  private updateAmount(calculator: SalaryRecordCalculation, value: number): any {
    calculator.Amount = value;
    calculator.calculateUnits();
    return {
      amountPerUnit: calculator.AmountPerUnit,
      amount: calculator.Amount,
      units: calculator.Units
    };
  }

  private updateUnits(calculator: SalaryRecordCalculation, value: number): any {
    calculator.Units = value;
    calculator.calculateAmount();
    return {
      amountPerUnit: calculator.AmountPerUnit,
      amount: calculator.Amount,
      units: calculator.Units
    };
  }

  public createFormGroup(dataItem: IGetSalaryRecordsOverview_Result): FormGroup {
    return new FormGroup({
      FullName: new FormControl(dataItem ? dataItem.FullName : undefined),
      DepartmentName: new FormControl(dataItem ? dataItem.DepartmentName : undefined),
      MultipleRecordsExist1: new FormControl(dataItem ? dataItem.MultipleRecordsExist1 : undefined),
      SalaryRecord1Units: new FormControl(dataItem ? dataItem.SalaryRecord1Units : undefined),
      SalaryRecord1PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord1PerUnit : undefined),
      SalaryRecord1Amount: new FormControl(dataItem ? dataItem.SalaryRecord1Amount : undefined),
      MultipleRecordsExist2: new FormControl(dataItem ? dataItem.MultipleRecordsExist2 : undefined),
      SalaryRecord2Units: new FormControl(dataItem ? dataItem.SalaryRecord2Units : undefined),
      SalaryRecord2PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord2PerUnit : undefined),
      SalaryRecord2Amount: new FormControl(dataItem ? dataItem.SalaryRecord2Amount : undefined),
      MultipleRecordsExist3: new FormControl(dataItem ? dataItem.MultipleRecordsExist3 : undefined),
      SalaryRecord3Units: new FormControl(dataItem ? dataItem.SalaryRecord3Units : undefined),
      SalaryRecord3PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord3PerUnit : undefined),
      SalaryRecord3Amount: new FormControl(dataItem ? dataItem.SalaryRecord3Amount : undefined),
      MultipleRecordsExist4: new FormControl(dataItem ? dataItem.MultipleRecordsExist4 : undefined),
      SalaryRecord4Units: new FormControl(dataItem ? dataItem.SalaryRecord4Units : undefined),
      SalaryRecord4PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord4PerUnit : undefined),
      SalaryRecord4Amount: new FormControl(dataItem ? dataItem.SalaryRecord4Amount : undefined),
      MultipleRecordsExist5: new FormControl(dataItem ? dataItem.MultipleRecordsExist5 : undefined),
      SalaryRecord5Units: new FormControl(dataItem ? dataItem.SalaryRecord5Units : undefined),
      SalaryRecord5PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord5PerUnit : undefined),
      SalaryRecord5Amount: new FormControl(dataItem ? dataItem.SalaryRecord5Amount : undefined),
      MultipleRecordsExist6: new FormControl(dataItem ? dataItem.MultipleRecordsExist6 : undefined),
      SalaryRecord6Units: new FormControl(dataItem ? dataItem.SalaryRecord6Units : undefined),
      SalaryRecord6PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord6PerUnit : undefined),
      SalaryRecord6Amount: new FormControl(dataItem ? dataItem.SalaryRecord6Amount : undefined),
      MultipleRecordsExist7: new FormControl(dataItem ? dataItem.MultipleRecordsExist7 : undefined),
      SalaryRecord7Units: new FormControl(dataItem ? dataItem.SalaryRecord7Units : undefined),
      SalaryRecord7PerUnit: new FormControl(dataItem ? dataItem.SalaryRecord7PerUnit : undefined),
      SalaryRecord7Amount: new FormControl(dataItem ? dataItem.SalaryRecord7Amount : undefined)
    });
  }

  public getData() {
    if (!this.gridDataSubject) {
      this.gridDataSubject = new BehaviorSubject<IGetSalaryRecordsOverview_Result[]>([]);
    }

    const filterObject: ISalaryRecordsOverviewFilterRequest = {
      SalaryCycleId: this.salaryCycleId ? this.salaryCycleId : undefined,
      EmploymentTemplateId: this.templateId ? this.templateId : undefined,
      DepartmentId: this.departmentId ? this.departmentId : undefined,
      IncludeEmployeesWithNoData: this.showEmployeeWithNoData
    };

    this.dataService
      .SalaryRecords_GetSalaryRecordsOverviewFiltered(filterObject)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        tap((data: IGetSalaryRecordsOverview_Result[]) => {
          this.gridDataSubject.next(data);
        })
      )
      .subscribe();
  }

  public SalaryTypes_ConfigureOverviewBehavior(request: any) {
    return this.dataService
      .SalaryTypes_ConfigureOverviewBehavior(request.salaryTypeId, request.overviewIndex, request.overviewDisplayMode)
      .pipe(
        tap((data: any) => {
          this.getSalaryTypes();
        })
      );
  }

  public get locked(): boolean {
    return this.salaryTypes && this.salaryTypes.length > 0;
  }

  private get hasLanguageModule(): boolean {
    return this.sessionService.feature.hasModuleId(5);
  }

  protected get allowShortcut(): boolean {
    return this.sessionService.currentState === 'tabs.company.payrolldata';
  }
}
