<li class="Dropdown-item">
  <button type="button" (click)="onShowWelcomeMessage()">
    {{ 'Help.ShowWelcomeMessage' | translate }}
  </button>
</li>

<ng-walkthrough
  #EmployeeGeneralTab
  focusElementSelector="#EmployeeGeneralTabButton"
  focusBackdrop="true"
  [contentTemplate]="templateEG"
  finishButton="true"
  showArrow="false"
  closeAnywhere="false"
  [nextStep]="EmployeeHiringStatusTab"
  [texts]="welcomeTexts"
>
  <ng-template #templateEG>
    <div class="welcome-title" [innerHTML]="welcomeTitles['EmployeeGeneral']"></div>
    <perfect-scrollbar class="scroll-container" fxFlex="auto" [config]="config" [scrollIndicators]="true">
      <div class="welcome-body" [innerHTML]="welcomeMessages['EmployeeGeneral']"></div>
    </perfect-scrollbar>
  </ng-template>
</ng-walkthrough>

<ng-walkthrough
  #EmployeeHiringStatusTab
  focusElementSelector="#EmployeeHiringStatusTabButton"
  focusBackdrop="true"
  [contentTemplate]="templateEHS"
  finishButton="true"
  showArrow="false"
  closeAnywhere="false"
  [previousStep]="EmployeeGeneralTab"
  [nextStep]="EmployeeTimeTab"
  [texts]="welcomeTexts"
>
  <ng-template #templateEHS>
    <div class="welcome-title" [innerHTML]="welcomeTitles['EmployeeEmployment']"></div>
    <perfect-scrollbar class="scroll-container" fxFlex="auto" [config]="config" [scrollIndicators]="true">
      <div class="welcome-body" [innerHTML]="welcomeMessages['EmployeeEmployment']"></div>
    </perfect-scrollbar>
  </ng-template>
</ng-walkthrough>

<ng-walkthrough
  #EmployeeTimeTab
  focusElementSelector="#EmployeeTimeTabButton"
  focusBackdrop="true"
  [contentTemplate]="templateET"
  finishButton="true"
  showArrow="false"
  closeAnywhere="false"
  [previousStep]="EmployeeHiringStatusTab"
  [nextStep]="EmployeePayrollDataTab"
  [texts]="welcomeTexts"
>
  <ng-template #templateET>
    <div class="welcome-title" [innerHTML]="welcomeTitles['EmployeeTime']"></div>
    <perfect-scrollbar class="scroll-container" fxFlex="auto" [config]="config" [scrollIndicators]="true">
      <div class="welcome-body" [innerHTML]="welcomeMessages['EmployeeTime']"></div>
    </perfect-scrollbar>
  </ng-template>
</ng-walkthrough>

<ng-walkthrough
  #EmployeePayrollDataTab
  focusElementSelector="#EmployeePayrollDataTabButton"
  focusBackdrop="true"
  [contentTemplate]="templateEPD"
  finishButton="true"
  showArrow="false"
  closeAnywhere="false"
  [previousStep]="EmployeeTimeTab"
  [nextStep]="EmployeePayslipTab"
  [texts]="welcomeTexts"
>
  <ng-template #templateEPD>
    <div class="welcome-title" [innerHTML]="welcomeTitles['EmployeePayrollData']"></div>
    <perfect-scrollbar class="scroll-container" fxFlex="auto" [config]="config" [scrollIndicators]="true">
      <div class="welcome-body" [innerHTML]="welcomeMessages['EmployeePayrollData']"></div>
    </perfect-scrollbar>
  </ng-template>
</ng-walkthrough>

<ng-walkthrough
  #EmployeePayslipTab
  focusElementSelector="#EmployeePayslipTabButton"
  focusBackdrop="true"
  [contentTemplate]="templateEPS"
  finishButton="true"
  showArrow="false"
  closeAnywhere="false"
  [previousStep]="EmployeePayrollDataTab"
  [nextStep]="CompanyGeneralTab"
  [texts]="welcomeTexts"
>
  <ng-template #templateEPS>
    <div class="welcome-title" [innerHTML]="welcomeTitles['EmployeePayslip']"></div>
    <perfect-scrollbar class="scroll-container" fxFlex="auto" [config]="config" [scrollIndicators]="true">
      <div class="welcome-body" [innerHTML]="welcomeMessages['EmployeePayslip']"></div>
    </perfect-scrollbar>
  </ng-template>
</ng-walkthrough>

<ng-walkthrough
  #CompanyGeneralTab
  focusElementSelector="#CompanyGeneralTabButton"
  focusBackdrop="true"
  [contentTemplate]="templateCG"
  finishButton="true"
  showArrow="false"
  closeAnywhere="false"
  [previousStep]="EmployeePayslipTab"
  [nextStep]="CompanySalaryBatchesTab"
  [texts]="welcomeTexts"
>
  <ng-template #templateCG>
    <div class="welcome-title" [innerHTML]="welcomeTitles['CompanyGeneral']"></div>
    <perfect-scrollbar class="scroll-container" fxFlex="auto" [config]="config" [scrollIndicators]="true">
      <div class="welcome-body" [innerHTML]="welcomeMessages['CompanyGeneral']"></div>
    </perfect-scrollbar>
  </ng-template>
</ng-walkthrough>

<ng-walkthrough
  #CompanySalaryBatchesTab
  focusElementSelector="#CompanySalaryBatchesTabButton"
  focusBackdrop="true"
  [contentTemplate]="templateCSB"
  finishButton="true"
  showArrow="false"
  closeAnywhere="false"
  [previousStep]="CompanyGeneralTab"
  [nextStep]="CompanyApprovalTab"
  [texts]="welcomeTexts"
>
  <ng-template #templateCSB>
    <div class="welcome-title" [innerHTML]="welcomeTitles['CompanySalaryBatches']"></div>
    <perfect-scrollbar class="scroll-container" fxFlex="auto" [config]="config" [scrollIndicators]="true">
      <div class="welcome-body" [innerHTML]="welcomeMessages['CompanySalaryBatches']"></div>
    </perfect-scrollbar>
  </ng-template>
</ng-walkthrough>

<ng-walkthrough
  #CompanyApprovalTab
  focusElementSelector="#CompanyApprovalTabButton"
  focusBackdrop="true"
  [contentTemplate]="templateCA"
  finishButton="true"
  showArrow="false"
  closeAnywhere="false"
  [previousStep]="CompanySalaryBatchesTab"
  [nextStep]="CompanyConfigurationTemplateTab"
  [texts]="welcomeTexts"
>
  <ng-template #templateCA>
    <div class="welcome-title" [innerHTML]="welcomeTitles['CompanyApproval']"></div>
    <perfect-scrollbar class="scroll-container" fxFlex="auto" [config]="config" [scrollIndicators]="true">
      <div class="welcome-body" [innerHTML]="welcomeMessages['CompanyApproval']"></div>
    </perfect-scrollbar>
  </ng-template>
</ng-walkthrough>

<ng-walkthrough
  #CompanyConfigurationTemplateTab
  focusElementSelector="#CompanyConfigurationTemplateTabButton"
  focusBackdrop="true"
  [contentTemplate]="templateCCT"
  finishButton="true"
  showArrow="false"
  closeAnywhere="false"
  [previousStep]="CompanyApprovalTab"
  [nextStep]="CompanyConfigurationIntegrationsTab"
  [texts]="welcomeTexts"
>
  <ng-template #templateCCT>
    <div class="welcome-title" [innerHTML]="welcomeTitles['CompanyHiringStatus']"></div>
    <perfect-scrollbar class="scroll-container" fxFlex="auto" [config]="config" [scrollIndicators]="true">
      <div class="welcome-body" [innerHTML]="welcomeMessages['CompanyHiringStatus']"></div>
    </perfect-scrollbar>
  </ng-template>
</ng-walkthrough>

<ng-walkthrough
  #CompanyConfigurationIntegrationsTab
  focusElementSelector="#CompanyConfigurationIntegrationsTabButton"
  focusBackdrop="true"
  [contentTemplate]="templateCCI"
  finishButton="true"
  showArrow="false"
  closeAnywhere="false"
  [previousStep]="CompanyConfigurationTemplateTab"
  [nextStep]="CompanyConfigurationSalaryTypesTab"
  [texts]="welcomeTexts"
>
  <ng-template #templateCCI>
    <div class="welcome-title" [innerHTML]="welcomeTitles['Integrations']"></div>
    <perfect-scrollbar class="scroll-container" fxFlex="auto" [config]="config" [scrollIndicators]="true">
      <div class="welcome-body" [innerHTML]="welcomeMessages['Integrations']"></div>
    </perfect-scrollbar>
  </ng-template>
</ng-walkthrough>

<ng-walkthrough
  #CompanyConfigurationSalaryTypesTab
  focusElementSelector="#CompanyConfigurationSalaryTypesTabButton"
  focusBackdrop="true"
  [contentTemplate]="templateCCST"
  finishButton="true"
  showArrow="false"
  closeAnywhere="false"
  [previousStep]="CompanyConfigurationIntegrationsTab"
  [nextStep]="CompanyConfigurationTimeEntryTypesTab"
  [texts]="welcomeTexts"
>
  <ng-template #templateCCST>
    <div class="welcome-title" [innerHTML]="welcomeTitles['CompanySalaryTypes']"></div>
    <perfect-scrollbar class="scroll-container" fxFlex="auto" [config]="config" [scrollIndicators]="true">
      <div class="welcome-body" [innerHTML]="welcomeMessages['CompanySalaryTypes']"></div>
    </perfect-scrollbar>
  </ng-template>
</ng-walkthrough>

<ng-walkthrough
  *ngIf="isHasModules"
  #CompanyConfigurationTimeEntryTypesTab
  focusElementSelector="#CompanyConfigurationTimeEntryTypesTabButton"
  focusBackdrop="true"
  [contentTemplate]="templateCCTT"
  finishButton="true"
  showArrow="false"
  closeAnywhere="false"
  [previousStep]="CompanyConfigurationSalaryTypesTab"
  [nextStep]="CompanyModulesTab"
  [texts]="welcomeTexts"
>
  <ng-template #templateCCTT>
    <div class="welcome-title" [innerHTML]="welcomeTitles['CompanyTimeEntryTypes']"></div>
    <perfect-scrollbar class="scroll-container" fxFlex="auto" [config]="config" [scrollIndicators]="true">
      <div class="welcome-body" [innerHTML]="welcomeMessages['CompanyTimeEntryTypes']"></div>
    </perfect-scrollbar>
  </ng-template>
</ng-walkthrough>

<ng-walkthrough
  *ngIf="!isHasModules"
  #CompanyConfigurationTimeEntryTypesTab
  focusElementSelector="#CompanyConfigurationTimeEntryTypesTabButton"
  focusBackdrop="true"
  [contentTemplate]="templateCCTT"
  finishButton="true"
  showArrow="false"
  closeAnywhere="false"
  [previousStep]="CompanyConfigurationSalaryTypesTab"
  [texts]="welcomeTexts"
>
  <ng-template #templateCCTT>
    <div class="welcome-title" [innerHTML]="welcomeTitles['CompanyTimeEntryTypes']"></div>
    <perfect-scrollbar class="scroll-container" fxFlex="auto" [config]="config" [scrollIndicators]="true">
      <div class="welcome-body" [innerHTML]="welcomeMessages['CompanyTimeEntryTypes']"></div>
    </perfect-scrollbar>
  </ng-template>
</ng-walkthrough>

<ng-walkthrough
  *ngIf="isHasSelfService"
  #CompanyModulesTab
  focusElementSelector="#CompanyModulesTabButton"
  focusBackdrop="true"
  [contentTemplate]="templateCM"
  finishButton="true"
  showArrow="false"
  closeAnywhere="false"
  [previousStep]="CompanyConfigurationTimeEntryTypesTab"
  [nextStep]="SelfServicePayslipsTab"
  [texts]="welcomeTexts"
>
  <ng-template #templateCM>
    <div class="welcome-title" [innerHTML]="welcomeTitles['AccountModules']"></div>
    <perfect-scrollbar class="scroll-container" fxFlex="auto" [config]="config" [scrollIndicators]="true">
      <div class="welcome-body" [innerHTML]="welcomeMessages['AccountModules']"></div>
    </perfect-scrollbar>
  </ng-template>
</ng-walkthrough>

<ng-walkthrough
  *ngIf="!isHasSelfService"
  #CompanyModulesTab
  focusElementSelector="#CompanyModulesTabButton"
  focusBackdrop="true"
  [contentTemplate]="templateCM"
  finishButton="true"
  showArrow="false"
  closeAnywhere="false"
  [previousStep]="CompanyConfigurationTimeEntryTypesTab"
  [texts]="welcomeTexts"
>
  <ng-template #templateCM>
    <div class="welcome-title" [innerHTML]="welcomeTitles['AccountModules']"></div>
    <perfect-scrollbar class="scroll-container" fxFlex="auto" [config]="config" [scrollIndicators]="true">
      <div class="welcome-body" [innerHTML]="welcomeMessages['AccountModules']"></div>
    </perfect-scrollbar>
  </ng-template>
</ng-walkthrough>

<ng-walkthrough
  #SelfServicePayslipsTab
  focusElementSelector="#SelfServicePayslipsTabButton"
  focusBackdrop="true"
  [contentTemplate]="templateSSP"
  finishButton="true"
  showArrow="false"
  closeAnywhere="false"
  [previousStep]="CompanyModulesTab"
  [nextStep]="SelfServiceGeneralTab"
  [texts]="welcomeTexts"
>
  <ng-template #templateSSP>
    <div class="welcome-title" [innerHTML]="welcomeTitles['SelfServicePayslip']"></div>
    <perfect-scrollbar class="scroll-container" fxFlex="auto" [config]="config" [scrollIndicators]="true">
      <div class="welcome-body" [innerHTML]="welcomeMessages['SelfServicePayslip']"></div>
    </perfect-scrollbar>
  </ng-template>
</ng-walkthrough>

<ng-walkthrough
  *ngIf="true"
  #SelfServiceGeneralTab
  focusElementSelector="#SelfServiceGeneralTabButton"
  focusBackdrop="true"
  [contentTemplate]="templateSSG"
  finishButton="true"
  showArrow="false"
  closeAnywhere="false"
  [previousStep]="SelfServicePayslipsTab"
  [nextStep]="SelfServiceTimeTab"
  [texts]="welcomeTexts"
>
  <ng-template #templateSSG>
    <div class="welcome-title" [innerHTML]="welcomeTitles['SelfServiceGeneral']"></div>
    <perfect-scrollbar class="scroll-container" fxFlex="auto" [config]="config" [scrollIndicators]="true">
      <div class="welcome-body" [innerHTML]="welcomeMessages['SelfServiceGeneral']"></div>
    </perfect-scrollbar>
  </ng-template>
</ng-walkthrough>

<ng-walkthrough
  #SelfServiceTimeTab
  focusElementSelector="#SelfServiceTimeTabButton"
  focusBackdrop="true"
  [contentTemplate]="templateSST"
  finishButton="true"
  showArrow="false"
  closeAnywhere="false"
  [previousStep]="SelfServiceGeneralTab"
  [texts]="welcomeTexts"
>
  <ng-template #templateSST>
    <div class="welcome-title" [innerHTML]="welcomeTitles['SelfServiceTime']"></div>
    <perfect-scrollbar class="scroll-container" fxFlex="auto" [config]="config" [scrollIndicators]="true">
      <div class="welcome-body" [innerHTML]="welcomeMessages['SelfServiceTime']"></div>
    </perfect-scrollbar>
  </ng-template>
</ng-walkthrough>
