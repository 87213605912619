import { EventEmitter } from '@angular/core';
import { SessionService } from '../../services/session/session.service';
var PayslipIconComponent = /** @class */ (function () {
    function PayslipIconComponent(sessionService) {
        this.sessionService = sessionService;
        this.click = new EventEmitter();
        this.isHidden = '';
        this.isVisible = true;
        this.showInCurrentTab = false;
        this.showInNewTab = false;
        this.showPDF = false;
        this.showPrintTab = false;
        this.showWarningTab = false;
        this.showErrorTab = false;
    }
    Object.defineProperty(PayslipIconComponent.prototype, "payslipIconType", {
        get: function () {
            return this.iconType;
        },
        set: function (value) {
            this.iconType = value;
            this.showIcon();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayslipIconComponent.prototype, "IsWeb", {
        get: function () {
            return !this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    PayslipIconComponent.prototype.onButtonClick = function () {
        if (this.click) {
            this.click.emit();
            if (this.sessionService.browser.isHybridApp) {
                window.screen.orientation.unlock();
            }
        }
    };
    PayslipIconComponent.prototype.showIcon = function () {
        if (!this.iconType) {
            return;
        }
        switch (this.iconType) {
            case 'htmlSameTab':
                this.showInCurrentTab = true;
                break;
            case 'htmlNewTab':
                this.showInNewTab = true;
                break;
            case 'pdf':
                this.showPDF = true;
                break;
            case 'htmlPrintTab':
                if (!this.sessionService.browser.isMobile) {
                    this.showPrintTab = true;
                }
                break;
            case 'warningTab':
                this.showWarningTab = true;
                break;
            case 'errorTab':
                this.showErrorTab = true;
                break;
            default:
                break;
        }
    };
    return PayslipIconComponent;
}());
export { PayslipIconComponent };
