import { StateService, TransitionService } from '@uirouter/core';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@uirouter/core";
var RouterStateService = /** @class */ (function () {
    function RouterStateService(transitionService, stateService) {
        var _this = this;
        this.transitionService = transitionService;
        this.stateService = stateService;
        this.defaultStates = {};
        this.redirects = {};
        this.currentSubStates = {};
        this.transitionService.onBefore({}, function (transition) {
            var toState = transition.to().name;
            var redirectState = _this.redirects[toState] || _this.currentSubStates[toState] || _this.defaultStates[toState];
            if (redirectState) {
                var rootState = toState.includes('tabs.company') ? 'tabs.company' : toState;
                if (rootState !== toState) {
                    _this.currentSubStates[rootState] = redirectState;
                }
                _this.currentSubStates[toState] = redirectState;
                if (!environment.production) {
                    console.log('RouterStateService onBefore - redirect to:', redirectState);
                }
                return transition.router.stateService.target(redirectState);
            }
        });
        this.transitionService.onSuccess({}, function (transition) {
            var toState = transition.to().name;
            var redirectState = _this.redirects[toState];
            if (redirectState) {
                if (!environment.production) {
                    console.log('RouterStateService onSuccess - redirect to:', redirectState);
                }
                transition.router.stateService.go(redirectState);
            }
            else {
                // Check all sub state
                _this.saveAllParentSubState(toState);
                var rootState = toState.includes('tabs.company') ? 'tabs.company' : toState;
                if (rootState !== toState) {
                    _this.currentSubStates[rootState] = toState;
                }
                _this.currentState = toState;
            }
        });
    }
    RouterStateService.prototype.saveAllParentSubState = function (toState) {
        var stateParts = toState.split('.');
        while (stateParts.length >= 3) {
            var parentState = this.getParentState(toState, true);
            if (parentState) {
                this.currentSubStates[parentState] = toState;
            }
            return this.saveAllParentSubState(parentState);
        }
        return;
    };
    RouterStateService.prototype.registerDefault = function (state) {
        var parentState = this.getParentState(state);
        if (parentState) {
            this.defaultStates[parentState] = state;
        }
    };
    RouterStateService.prototype.registerRedirect = function (fromState, toState) {
        this.redirects[fromState] = toState;
        if (this.currentStateIs(fromState)) {
            this.stateService.go(toState);
        }
    };
    RouterStateService.prototype.clearRedirect = function (fromState) {
        this.redirects[fromState] = undefined;
    };
    RouterStateService.prototype.clearCurrentSubState = function (fromState) {
        this.currentSubStates[fromState] = undefined;
    };
    RouterStateService.prototype.currentStateIs = function (state) {
        if (typeof state === 'string') {
            return this.currentState === state;
        }
        else {
            return state.includes(this.currentState);
        }
    };
    RouterStateService.prototype.parentStateIs = function (state) {
        return this.getParentState(this.currentState) === state;
    };
    RouterStateService.prototype.currentStateStartsWith = function (state) {
        return this.currentState.startsWith(state);
    };
    RouterStateService.prototype.navigateToState = function (state) {
        this.stateService.go(state);
    };
    RouterStateService.prototype.getParentState = function (state, isGetSubState) {
        if (state === void 0) { state = ''; }
        var stateParts = state.split('.');
        if (stateParts.length < 3 || stateParts[0] !== 'tabs') {
            return '';
        }
        else {
            stateParts.pop();
            while (stateParts.length > 3 && !isGetSubState) {
                stateParts.pop();
            }
            return stateParts.join('.');
        }
    };
    RouterStateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RouterStateService_Factory() { return new RouterStateService(i0.ɵɵinject(i1.TransitionService), i0.ɵɵinject(i1.StateService)); }, token: RouterStateService, providedIn: "root" });
    return RouterStateService;
}());
export { RouterStateService };
