import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewEncapsulation } from '@angular/core';
import { ISimpleKeyValuePair } from 'src/app/services/api/api-model';
import { environment } from '../../../environments/environment';
import { Browser } from '../../common/browser';
import { EventEmitterService } from '../../services/event-emitter.service';
import { SessionService } from '../../services/session/session.service';
import { CompanyTemplatesService } from './templates/templates.service';

@Component({
  selector: 'app-company-configuration',
  templateUrl: './company-configuration.component.html',
  styleUrls: ['./company-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyConfigurationComponent implements OnInit, OnDestroy {
  public menuOpen: boolean;
  public get companyOptionTabEnabled(): boolean {
    return this.sessionService.role.IsFullAdmin || this.sessionService.role.IsReadOnly;
  }
  public get companyDataImportTabEnabled(): boolean {
    return !this.sessionService.role.IsReadOnly;
  }
  public get companyOptionStateActive(): boolean {
    return this.sessionService.currentState === 'tabs.company.configuration.settings';
  }
  public get companyAdvancedSalaryTypesStateActive(): boolean {
    return this.sessionService.currentState === 'tabs.company.configuration.advancedsalarytypes';
  }
  public get companyAdvancedTypesStateActive(): boolean {
    return this.sessionService.currentState.indexOf('tabs.company.configuration.advancedtypes') >= 0;
  }
  public get companyTimeEntryTypesStateActive(): boolean {
    return this.sessionService.currentState === 'tabs.company.configuration.timeentrytypes';
  }
  public get companyintegrationsStateActive(): boolean {
    return this.sessionService.currentState === 'tabs.company.configuration.integrations';
  }
  public get companyDataImportStateActive(): boolean {
    return (
      !this.sessionService.role.IsReadOnly &&
      this.sessionService.currentState === 'tabs.company.configuration.dataimport'
    );
  }
  public get companyTemplateTabVisisble(): boolean {
    return this.sessionService.currentState === 'tabs.company.configuration.hiringstatus';
  }

  public get companyDepartmentsTabVisible() {
    return this.sessionService.currentState === 'tabs.company.configuration.departments';
  }

  public get companyDimensionsStateActive() {
    return this.sessionService.currentState === 'tabs.company.configuration.dimensions';
  }

  constructor(
    private sessionService: SessionService,
    private renderer: Renderer2,
    private eventEmitterService: EventEmitterService,
    public companyTemplatesService: CompanyTemplatesService
  ) {}

  private isCollapseButtonClick: boolean;
  public showSaveDataConfirmationChangeState = false;
  public listClassExcluded = ['Footer', '5'];

  public get isHybridApp(): boolean {
    const browser: Browser = new Browser();
    return browser.isHybridApp;
  }

  public ngOnInit(): void {
    this.renderer.listen(window, 'click', (event: any) => {
      if (!this.isCollapseButtonClick) {
        this.menuOpen = false;
      } else {
        this.isCollapseButtonClick = false;
      }
    });
    if (!environment.production) {
      console.log('state', this.sessionService.currentState);
    }

    if (this.sessionService.currentState === 'tabs.company.configuration') {
      if (this.companyOptionTabEnabled) {
        this.navigateTo('tabs.company.configuration.hiringstatus');
      } else {
        this.navigateTo('tabs.company.configuration.dataimport');
      }
    }

    if (this.eventEmitterService.subsVar === undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.invokeConfigurationToggleNavigation.subscribe(() => {
        this.onToggleNavigation();
      });
    }
    if (this.eventEmitterService.subsVar1 === undefined) {
      this.eventEmitterService.subsVar1 = this.eventEmitterService.invokeConfigurationGetMenuOpen.subscribe(() => {
        this.eventEmitterService.isOpen = this.getMenuOpen();
      });
    }
  }

  public ngOnDestroy(): void {
    this.menuOpen = false;
    this.eventEmitterService.subsVar = undefined;
    this.eventEmitterService.subsVar1 = undefined;
  }

  public navigateTo(state: string): void {
    this.menuOpen = false;
    this.sessionService.NavigateTo(state);
  }

  public onToggleNavigation(): void {
    this.isCollapseButtonClick = true;
    this.menuOpen = !this.menuOpen;
  }

  public getMenuOpen(): boolean {
    return this.menuOpen;
  }
}
