import { EventEmitter, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ISalaryBatch } from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { SessionService } from '../../../services/session/session.service';
import { CompanySalaryBatchService } from '../company-salary-batches.service';
var SalaryBatchErrorsDialogComponent = /** @class */ (function () {
    function SalaryBatchErrorsDialogComponent(dataService, sessionService, translateService, companySalaryBatchService) {
        var _this = this;
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.translateService = translateService;
        this.companySalaryBatchService = companySalaryBatchService;
        this.isTab = true;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.refreshSource = new EventEmitter();
        this.resendBatch = new EventEmitter();
        this.clearErrorsDialogVisible = false;
        this.ngUnsubscribe = new Subject();
        this.companySalaryBatchService.selectedSalaryBatch
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            _this.salaryBatch = value;
        });
    }
    Object.defineProperty(SalaryBatchErrorsDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchErrorsDialogComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SalaryBatchErrorsDialogComponent.prototype, "isFinalizedOrRevert", {
        get: function () {
            return this.salaryBatch && (this.salaryBatch.StatusId === 40 || this.salaryBatch.StatusId === 90);
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchErrorsDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    SalaryBatchErrorsDialogComponent.prototype.onDialogAction = function (action) {
        switch (action) {
            case 'ClearErrors':
                this.clearErrorsDialogVisible = true;
                break;
            case 'Resend':
                this.resendBatch.emit(this.salaryBatch.Id);
                break;
        }
    };
    SalaryBatchErrorsDialogComponent.prototype.clearErrorsDialogAction = function (action) {
        var _this = this;
        if (action === 'Proceed') {
            this.dataService.SalaryBatches_ClearEIncomeErrors(this.salaryBatch.Id).subscribe(function () {
                _this.refreshSource.emit();
                _this.visible = false;
            });
        }
    };
    return SalaryBatchErrorsDialogComponent;
}());
export { SalaryBatchErrorsDialogComponent };
