<span kendoTooltip #componentId id="{{ componentId }}">
  <button
    [title]="tooltip"
    (click)="onButtonClick()"
    class="FormElement-cursorHand {{ buttonClass }}"
    [ngClass]="{ 'is-disabled': isDisabled }"
    type="button"
    [disabled]="isDisabled"
  >
    {{ label }}
  </button>
</span>
