import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IListDataItem } from 'src/app/model/ilist-data-item';
import { IUserEmploymentTemplate } from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-template-filter',
  templateUrl: './template-filter.component.html'
})
export class TemplateFilterComponent implements OnInit, OnDestroy {
  public listSource: IListDataItem[] = [];

  constructor(
    public employeeService: EmployeeService,
    private translateService: TranslateService,
    private sessionService: SessionService,
    private dataService: DataService
  ) {
    // this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.translate());
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnInit(): void {
    this.translate();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private translate() {
    this.translateService.get(['Employee.AllTemplates']).subscribe((translations: { [key: string]: string }) => {
      const allItem: IListDataItem = { Id: -1, Text: translations['Employee.AllTemplates'] };
      this.dataService
        .EmploymentTemplates_GetSimpleEmploymentTemplates()
        .subscribe((data: IUserEmploymentTemplate[]) => {
          this.listSource = data.map((d) => ({ Id: d.Id, Text: d.Name }));
          this.listSource.unshift(allItem);
          this.employeeService.templateId = !this.employeeService.templateId ? -1 : this.employeeService.templateId;
        });
    });
  }
}
