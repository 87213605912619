import * as tslib_1 from "tslib";
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnInit } from '@angular/core';
import { DxDateBoxComponent } from 'devextreme-angular';
import { ControlComponentBase } from '../../common/control-component-base';
import { ReflectionHelper } from '../../common/reflection-helper';
import { SessionService } from '../../services/session/session.service';
var DatePickerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DatePickerComponent, _super);
    function DatePickerComponent(elementRef, sessionService, changeDetectorRef) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.sessionService = sessionService;
        _this.type = 'date';
        _this.helpTooltip = '';
        _this.AllowNull = false;
        _this.format = 'dd-MM-yyyy';
        _this.isOpened = false;
        _this.customData = new EventEmitter();
        _this.directional = new EventEmitter();
        _this.blur = new EventEmitter();
        _this.keydowns = new EventEmitter();
        _this.showCalendarButtonConfig = {
            onClick: function () {
                _this.dxDatePickerControl.opened = !_this.dxDatePickerControl.opened;
            },
            disabled: false,
            icon: 'event'
        };
        _this.deleteValueButtonConfig = {
            onClick: function () {
                _this.value = undefined;
            },
            disabled: false,
            icon: 'close'
        };
        _this.setTimeout = false;
        return _this;
    }
    DatePickerComponent.prototype.ngOnInit = function () {
        if (!this.isGrid) {
            this.setTimeout = true;
        }
        else {
            if (this.sessionService.dateFormat) {
                this.format = this.sessionService.dateFormat;
            }
            if (this.type === 'datetime') {
                this.format += ' HH:mm';
            }
        }
    };
    DatePickerComponent.prototype.onChange = function (triggerChanged) {
        var _this = this;
        if (triggerChanged === void 0) { triggerChanged = true; }
        if (this.setTimeout) {
            setTimeout(function () {
                _this.isValid = _this.valid();
                _this.isDirty = _this.hasChanges();
                if (triggerChanged) {
                    _this.onEmitValueChange(_this.value);
                }
            });
        }
        else {
            this.isValid = this.valid();
            this.isDirty = this.hasChanges();
            if (triggerChanged) {
                this.onEmitValueChange(this.value);
            }
        }
    };
    DatePickerComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.sessionService.dateFormat) {
                _this.format = _this.sessionService.dateFormat;
            }
            if (_this.type === 'datetime') {
                _this.format += ' HH:mm';
            }
        });
    };
    DatePickerComponent.prototype.onfocus = function (event) {
        // Select text when focus input
        if (this.dxDatePickerControl && !this.sessionService.browser.isMobile) {
            var element = this.dxDatePickerControl.element;
            var input = element.nativeElement.getElementsByClassName('dx-texteditor-input')[0];
            if (input) {
                input.select();
            }
            this.isOpened = true;
        }
    };
    DatePickerComponent.prototype.onBlur = function (event) {
        var _this = this;
        // Not blur when click to time picker
        if (this.type === 'datetime' &&
            event.event.relatedTarget &&
            event.event.relatedTarget.nodeName.toLowerCase() + '.' + event.event.relatedTarget.classList.toString() ===
                'input.dx-texteditor-input') {
            this.value = event.component._strategy.getValue();
            return;
        }
        if (!this.convertSpecialFormat()) {
            this.convertInvalidDxDate();
        }
        setTimeout(function () {
            _this.blur.emit();
            _this.isOpened = false;
        });
    };
    DatePickerComponent.prototype.onKeyDown = function (event) {
        var _this = this;
        this.dxDatePickerControl.isValid = true;
        if (this.isGrid) {
            if (event.event.key === 'ArrowDown' ||
                event.event.key === 'ArrowUp' ||
                event.event.key === 'ArrowLeft' ||
                event.event.key === 'ArrowRight') {
                setTimeout(function () {
                    event.event.preventDefault();
                    event.event.stopPropagation();
                    _this.directional.emit(event.event);
                });
                return;
            }
        }
        if (this.dxDatePickerControl.opened) {
            this.dxDatePickerControl.opened = false;
        }
        if (event.event.key === 'Enter' || event.event.key === 'Tab') {
            if (!this.convertSpecialFormat()) {
                var date_1 = this.checkStringToDateCompatible(this.dxDatePickerControl.text)
                    ? this.convertCustomStringToDate(this.dxDatePickerControl.text)
                    : undefined;
                var controlValue = this.dxDatePickerControl.value
                    ? this.dxDatePickerControl.value.toString()
                    : undefined;
                if ((date_1 &&
                    ((controlValue && controlValue !== date_1.toString()) ||
                        !controlValue ||
                        !this.dxDatePickerControl.isValid)) ||
                    this.AllowNull) {
                    if (this.isGrid) {
                        this.value = date_1;
                    }
                    else {
                        this.value = null;
                        setTimeout(function () {
                            _this.value = date_1;
                        });
                    }
                }
            }
        }
        else if (event.event.keyCode === 27) {
            // Escape
            this.value = this.original;
        }
        this.keydowns.emit(event.event.key);
    };
    DatePickerComponent.prototype.onkeypress = function (event) {
        // event.event.stopPropagation();
        // event.event.preventDefault();
    };
    DatePickerComponent.prototype.onValueChanged = function (event) {
        var _this = this;
        ////console.log("valueChanged event raised!");
        if (this.type === 'datetime') {
            if (this.dxDatePickerControl.opened && event.event) {
                setTimeout(function () {
                    event.event.preventDefault();
                    event.event.stopPropagation();
                    _this.dxDatePickerControl.instance.open();
                });
            }
            return;
        }
    };
    DatePickerComponent.prototype.onDxDatePickerClosed = function (event) {
        ////console.log("popup closed event raised!");
    };
    DatePickerComponent.prototype.convertSpecialFormat = function () {
        var result = false;
        if (this.dxDatePickerControl && this.dxDatePickerControl.text) {
            var today = new Date();
            if (/^[Uu][0-9]$/g.test(this.dxDatePickerControl.text) || /^[Uu][0-9]{2}$/g.test(this.dxDatePickerControl.text)) {
                var monthStr = this.dxDatePickerControl.text.substring(1, 3);
                var month = monthStr ? this.sessionService.parseInt(monthStr) : undefined;
                if (month) {
                    result = true;
                    var lastDateOfMonth = new Date(today.getFullYear(), month, 0).getDate();
                    this.value = this.createDate(today.getFullYear(), month - 1, lastDateOfMonth);
                }
            }
            else {
                switch (this.dxDatePickerControl.text) {
                    case 'd':
                        result = true;
                        this.value = this.createDate(today.getFullYear(), today.getMonth(), today.getDate());
                        break;
                    case 'u':
                        result = true;
                        var lastDateOfMonth = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
                        this.value = this.createDate(today.getFullYear(), today.getMonth(), lastDateOfMonth);
                        break;
                    default:
                        break;
                }
            }
        }
        return result;
    };
    DatePickerComponent.prototype.convertInvalidDxDate = function () {
        // Dx control has invalid value.
        if (this.dxDatePickerControl && this.dxDatePickerControl.validationError) {
            this.value = this.checkStringToDateCompatible(this.dxDatePickerControl.text)
                ? this.convertCustomStringToDate(this.dxDatePickerControl.text)
                : undefined;
        }
    };
    DatePickerComponent.prototype.convertValue = function (value) {
        return this.convertDateValue(value);
    };
    DatePickerComponent.prototype.valid = function () {
        return this.validate(this.value);
    };
    Object.defineProperty(DatePickerComponent.prototype, "showValidationMessage", {
        get: function () {
            return !this.valid() && !this.disable && this.validationText && this.validationText.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    DatePickerComponent.prototype.convertDateValue = function (value) {
        var isValidationPassed = this.validate(ReflectionHelper.isDate(value) ? value.toISOString() : value);
        if (isValidationPassed && value) {
            // This should only run when the user manually changes the date string
            var date = ReflectionHelper.isDate(value) ? value : new Date(value);
            if (this.type === 'datetime') {
                date = !date
                    ? undefined
                    : this.createDateWithHour(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
            }
            else {
                date = !date ? undefined : this.createDate(date.getFullYear(), date.getMonth(), date.getDate());
            }
            return date;
        }
        else {
            return value;
        }
    };
    DatePickerComponent.prototype.validate = function (value) {
        if (this.required && !value) {
            this.validationText = this.requiredMessage;
            return false;
        }
        return true;
    };
    Object.defineProperty(DatePickerComponent.prototype, "isNonRequired", {
        get: function () {
            return !this.required || (this.required && !!this.value);
        },
        enumerable: true,
        configurable: true
    });
    DatePickerComponent.prototype.checkStringToDateCompatible = function (value) {
        var isCompatible = false;
        if (this.type === 'datetime') {
            if (value && value.length > 0) {
                if (this.sessionService.isSwedenCompany) {
                    if (/^\d{2}\-\d{2}\-\d{2} (00|[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9])$/.test(value)) {
                        var parts = value.split(/\-| |:|\./);
                        var day = parseInt(parts[2], 10);
                        var month = parseInt(parts[1], 10);
                        var year = parseInt(parts[0], 10);
                        var century = Math.floor((new Date().getFullYear() - 1) / 100) + 1;
                        year = (century - 1) * 100 + year;
                        if (year < 1000 || year > 3000 || month === 0 || month > 12) {
                            return isCompatible;
                        }
                        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
                        if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
                            monthLength[1] = 29;
                        }
                        return !(day > 0 && day <= monthLength[month - 1]);
                    }
                }
                var dateValue = value.split(/\s+/)[0];
                var dateNumberString = dateValue.replace(/[^0-9\.\-\/ ,]/g, '');
                var date = dateNumberString.substring(0, 2)
                    ? this.sessionService.parseInt(dateNumberString.substring(0, 2))
                    : undefined;
                if (!date &&
                    (/[.]/g.test(dateNumberString) || /[-]/g.test(dateNumberString) || /[\/]/g.test(dateNumberString))) {
                    var seperator = /[.]/g.test(dateNumberString)
                        ? '.'
                        : /[-]/g.test(dateNumberString)
                            ? '-'
                            : /[\/]/g.test(dateNumberString)
                                ? '/'
                                : '';
                    date = dateNumberString.split(seperator)[0]
                        ? this.sessionService.parseInt(dateNumberString.split(seperator)[0])
                        : undefined;
                }
                if (date) {
                    isCompatible = true;
                }
            }
        }
        else {
            if (value && value.length > 0) {
                if (this.sessionService.isSwedenCompany) {
                    if (/^\d{2}\-\d{2}\-\d{2}$/.test(value)) {
                        var parts = value.split('-');
                        var day = parseInt(parts[2], 10);
                        var month = parseInt(parts[1], 10);
                        var year = parseInt(parts[0], 10);
                        var century = Math.floor((new Date().getFullYear() - 1) / 100) + 1;
                        // convert year to year in current century
                        year = (century - 1) * 100 + year;
                        if (year < 1000 || year > 3000 || month === 0 || month > 12) {
                            return isCompatible;
                        }
                        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
                        if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
                            monthLength[1] = 29;
                        }
                        return !(day > 0 && day <= monthLength[month - 1]);
                    }
                }
                var dateNumberString = value.replace(/[^0-9\.\-\/ ,]/g, '');
                var date = dateNumberString.substring(0, 2)
                    ? this.sessionService.parseInt(dateNumberString.substring(0, 2))
                    : undefined;
                if (!date &&
                    (/[.]/g.test(dateNumberString) || /[-]/g.test(dateNumberString) || /[\/]/g.test(dateNumberString))) {
                    var seperator = /[.]/g.test(dateNumberString)
                        ? '.'
                        : /[-]/g.test(dateNumberString)
                            ? '-'
                            : /[\/]/g.test(dateNumberString)
                                ? '/'
                                : '';
                    date = dateNumberString.split(seperator)[0]
                        ? this.sessionService.parseInt(dateNumberString.split(seperator)[0])
                        : undefined;
                }
                if (date) {
                    isCompatible = true;
                }
            }
        }
        return isCompatible;
    };
    DatePickerComponent.prototype.convertCustomStringToDate = function (value) {
        if (value && value.length > 0) {
            var dateValue = value.split(/\s+/)[0];
            var dateNumberString = dateValue.replace(/[^0-9\.\-\/ ,]/g, '');
            var today = new Date();
            today = this.createDate(today.getFullYear(), today.getMonth(), today.getDate());
            var date = void 0;
            var month = void 0;
            var year = void 0;
            if (/[.]/g.test(dateNumberString) || /[-]/g.test(dateNumberString) || /[\/]/g.test(dateNumberString)) {
                var seperator = /[.]/g.test(dateNumberString)
                    ? '.'
                    : /[-]/g.test(dateNumberString)
                        ? '-'
                        : /[\/]/g.test(dateNumberString)
                            ? '/'
                            : '';
                date = dateNumberString.split(seperator)[0]
                    ? this.sessionService.parseInt(dateNumberString.split(seperator)[0])
                    : undefined;
                month = dateNumberString.split(seperator)[1]
                    ? this.sessionService.parseInt(dateNumberString.split(seperator)[1]) - 1
                    : today.getMonth();
                year = dateNumberString.split(seperator)[2]
                    ? this.sessionService.parseInt(dateNumberString.split(seperator)[2])
                    : today.getFullYear() + this.SuggestYear(month, today.getMonth());
            }
            else {
                dateNumberString = dateValue.replace(/[^0-9,]/g, '');
                date = dateNumberString.substring(0, 2)
                    ? this.sessionService.parseInt(dateNumberString.substring(0, 2))
                    : undefined;
                month = dateNumberString.substring(2, 4)
                    ? this.sessionService.parseInt(dateNumberString.substring(2, 4)) - 1
                    : today.getMonth();
                year = dateNumberString.substring(4, 8)
                    ? this.sessionService.parseInt(dateNumberString.substring(4, 8))
                    : today.getFullYear() + this.SuggestYear(month, today.getMonth());
            }
            if (!this.isValidDataAfterConvert(month, date, year)) {
                return undefined;
            }
            if (this.type === 'datetime') {
                var hour = 0;
                var minute = 0;
                var second = 0;
                var timeValue = String(value.split(/\s+/).slice(1));
                var timeNumberString = timeValue.replace(/[^0-9\:\- ,]/g, '');
                if (/[.]/g.test(timeNumberString) || /[:]/g.test(timeNumberString)) {
                    var seperator = /[.]/g.test(timeNumberString)
                        ? '.'
                        : /[:]/g.test(timeNumberString)
                            ? ':'
                            : undefined;
                    hour = timeNumberString.split(seperator)[0]
                        ? this.sessionService.parseInt(timeNumberString.split(seperator)[0])
                        : undefined;
                    minute = timeNumberString.split(seperator)[1]
                        ? this.sessionService.parseInt(timeNumberString.split(seperator)[1])
                        : undefined;
                    second = timeNumberString.split(seperator)[2]
                        ? this.sessionService.parseInt(timeNumberString.split(seperator)[2])
                        : undefined;
                    if ((hour && (hour > 23 || hour < 0)) || hour === undefined) {
                        hour = 0;
                    }
                    if ((minute && (minute > 59 || minute < 0)) || minute === undefined) {
                        minute = 0;
                    }
                    if ((second && (second > 59 || second < 0)) || second === undefined) {
                        second = 0;
                    }
                }
                return this.createDateWithHour(year, month, date, hour, minute, second);
            }
            return this.createDate(year, month, date);
        }
        return undefined;
    };
    DatePickerComponent.prototype.isValidDataAfterConvert = function (month, date, year) {
        if ((month && (month > 11 || month < 0)) || month === undefined) {
            return false;
        }
        var lastDateOfMonth = new Date(year, month + 1, 0).getDate();
        if (!lastDateOfMonth || (date && (date > lastDateOfMonth || date < 1)) || !date) {
            return false;
        }
        return true;
    };
    DatePickerComponent.prototype.SuggestYear = function (inputMonth, currentMonth) {
        var res = 0;
        if (inputMonth < currentMonth) {
            var temp = inputMonth + 12;
            res = temp - currentMonth;
            if (0 < res && res <= 3) {
                return 1;
            }
        }
        else {
            res = inputMonth - currentMonth;
            if (0 < res && res <= 3) {
                if (currentMonth + res <= 11) {
                    return 0;
                }
                else {
                    return 1;
                }
            }
            else if (res > 3) {
                return -1;
            }
        }
        return 0;
    };
    DatePickerComponent.prototype.createDate = function (year, month, date) {
        var today = new Date();
        if (date) {
            year =
                year < 100
                    ? this.sessionService.parseInt(today
                        .getFullYear()
                        .toString()
                        .substr(0, 2) + this.sessionService.toString(year, '00'))
                    : year;
            month = month > 11 ? 11 : month < 0 ? 0 : month;
            var lastDateOfMonth = new Date(year, month + 1, 0).getDate();
            date = date > lastDateOfMonth ? lastDateOfMonth : date < 0 ? 1 : date;
            var offset = new Date(year, month, date).getTimezoneOffset() / 60;
            offset = offset === 12 || offset === -12 ? 0 : offset;
            if (offset > 0) {
                return new Date(year, month, date, offset, 0, 0);
            }
            return new Date(year, month, date, -offset, 0, 0);
        }
        return undefined;
    };
    DatePickerComponent.prototype.createDateWithHour = function (year, month, date, hours, minutes, seconds) {
        var today = new Date();
        if (date) {
            year =
                year < 100
                    ? this.sessionService.parseInt(today
                        .getFullYear()
                        .toString()
                        .substr(0, 2) + this.sessionService.toString(year, '00'))
                    : year;
            month = month > 11 ? 11 : month < 0 ? 0 : month;
            var lastDateOfMonth = new Date(year, month + 1, 0).getDate();
            date = date > lastDateOfMonth ? lastDateOfMonth : date < 0 ? 1 : date;
            return new Date(year, month, date, hours, minutes, seconds);
        }
        return undefined;
    };
    Object.defineProperty(DatePickerComponent.prototype, "pickerType", {
        get: function () {
            if (this.sessionService.browser.isMobile) {
                return 'rollers';
            }
            return 'calendar';
        },
        enumerable: true,
        configurable: true
    });
    return DatePickerComponent;
}(ControlComponentBase));
export { DatePickerComponent };
