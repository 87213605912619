import * as tslib_1 from "tslib";
import { TransitionService } from '@uirouter/core';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { CompanyService } from '../../services/company.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/api/data.service";
import * as i2 from "../../services/api/static-data.service";
import * as i3 from "../../services/setting.service";
import * as i4 from "../../services/session/session.service";
import * as i5 from "@uirouter/core";
import * as i6 from "../../services/broadcast.service";
var CompanyApprovalService = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyApprovalService, _super);
    function CompanyApprovalService(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) {
        var _this = _super.call(this, dataService, staticDataService, settingService, sessionService, transitionService, broadcaster) || this;
        _this.dataService = dataService;
        _this.staticDataService = staticDataService;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.transitionService = transitionService;
        _this.broadcaster = broadcaster;
        return _this;
    }
    Object.defineProperty(CompanyApprovalService.prototype, "allowShortcut", {
        get: function () {
            return this.sessionService.currentState === 'tabs.company.approval.timeentry';
        },
        enumerable: true,
        configurable: true
    });
    CompanyApprovalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyApprovalService_Factory() { return new CompanyApprovalService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.StaticDataService), i0.ɵɵinject(i3.SettingService), i0.ɵɵinject(i4.SessionService), i0.ɵɵinject(i5.TransitionService), i0.ɵɵinject(i6.BroadcastService)); }, token: CompanyApprovalService, providedIn: "root" });
    return CompanyApprovalService;
}(CompanyService));
export { CompanyApprovalService };
