import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-modal-header',
  templateUrl: './mobile-modal-header.component.html'
})
export class MobileModalHeaderComponent implements OnInit {
  constructor() {}

  private transform = false;
  ngOnInit(): void {}
}
