import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import { ReportDialogService } from '../../shared-components/report-dialog/report-dialog.service';
import { CompanyModuleService } from './company-modules.service';

@Component({
  selector: 'company-modules',
  templateUrl: './company-modules.component.html',
  styleUrls: ['./company-modules.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyModulesComponent implements OnInit, OnDestroy {
  constructor(
    public sessionService: SessionService,
    public settingService: SettingService,
    public reportDialogService: ReportDialogService,
    public companyModuleService: CompanyModuleService
  ) {
    this.companyModuleService.showReportShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && this.reportDialogService.isVisibleReportIcon) {
          this.reportDialogService.onShowReportsEventClick();
        }
      });
  }

  public ngOnInit(): void {
    this.companyModuleService.serviceInit();
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
