import { Component, HostListener } from '@angular/core';
import { IListDataItem } from 'src/app/model/ilist-data-item';
import { EmployeeService } from '../../services/employee.service';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html'
})
export class EmployeeListComponent {
  public hasDepartmentFilter = true;
  public createNewEmployeeDialogVisible = false;
  public listClassExcluded = ['filter-list', '10'];

  constructor(public sessionService: SessionService, public employeeService: EmployeeService) {}

  public get allowEditUserEmployment(): boolean {
    return this.sessionService.role.IsSalaryAdminAndHigher;
  }

  public get employeeListVisible(): boolean {
    return this.sessionService.feature.ShowEmployeeList;
  }

  public onCloseEmployeeFilterEventClick(): void {
    this.sessionService.employeeListActive = false;
    this.employeeService.filter = '';
  }

  public onGridSelectionChange(companyUser: IListDataItem) {
    if (companyUser) {
      this.employeeService.selectEmployee(companyUser.Id);
    } else {
      this.employeeService.selectEmployee();
    }

    this.sessionService.employeeListActive = false;
  }
}
