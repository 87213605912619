<app-fixed-panel
  [excludedComponents]="listClassExcluded"
  [editMode]="service.editMode"
  [specificClassName]="'Island-accountant--company'"
>
  <div
    class="accountant-container accountant-container--companies"
    [ngClass]="{ 'is-editing': service.editMode }"
    (dblclick)="tabDblclick()"
  >
    <div class="Island Island-accountant Island-accountant--company" [ngClass]="{ 'is-editing': service.editMode }">
      <h1 class="Company-accountName" [innerHTML]="accountName"></h1>
      <div class="Cards Cards--flex">
        <div class="Card Card--large Card-inner accountantCompaniesGrid">
          <div class="Employee-timeControls">
            <div class="FormElement-control">
              <div class="FormElement-control">
                <app-text-edit
                  class="FormEmlement accountant-companies-search"
                  [label]="'CompanySalaryBatches.Search' | translate"
                  [icon]="'Search'"
                  [placeholder]="'Accountant.FilterCompany' | translate"
                  [editMode]="true"
                  [(value)]="service.filter"
                  [readonlyOnNotFocus]="true"
                  [disabled]="service.editMode"
                ></app-text-edit>
                <app-button
                  *ngIf="sessionService.role.IsMultiCompanyAdmin"
                  buttonClass="Button--card"
                  class="Button--card accountantAdNewButton"
                  (clickEvent)="addNewCompanyDialogVisible = true"
                  [label]="'Accountant.AddNewCompany' | translate"
                >
                </app-button>
              </div>
            </div>
          </div>

          <app-grid
            class="accountant"
            [ngClass]="{ 'is-hide-comment': !isShowComment, 'accountant-paging': isPagingAvailable }"
            [data]="gridFilterData"
            [(skip)]="service.currentPage"
            [pageable]="true"
            [(selectedId)]="service.selectedId"
            [idColumn]="'Id'"
            (clickAppGridIcon)="clickAppGridIcon($event.dataItem)"
            [pageSize]="pageSize"
            [filterable]="'menu'"
            [filter]="filter"
            (filterChange)="onFilterChange($event)"
            [(selectedItem)]="service.companySelected"
            (selectedIdChange)="onSelectedIdChange($event)"
            (dblClick)="gridDblclick($event)"
            (skipChange)="onEditModeChange(false)"
            [(triggerUpdate)]="triggerUpdate"
            [editMode]="service.editMode && isAccountantAdmin"
            (controlDataChange)="onCompanyRowSave($event)"
          >
            <app-grid-column
              [field]="'Id'"
              [type]="'numeric'"
              [title]="'Accountant.Id' | translate"
              [isFilterable]="true"
              [numbericOption]="'0'"
              [headerTooltip]="'Accountant.Id' | translate"
              [editable]="false"
              [width]="40"
              [viewClass]="'truncated'"
            ></app-grid-column>
            <app-grid-column
              [field]="'CountryCode'"
              [type]="'image'"
              [title]="''"
              [width]="40"
              [isFilterable]="false"
              [comboboxDataSource]="countryCodes"
              [comboboxValueField]="'Name'"
              [comboboxDisplayField]="'Name'"
              [editable]="false"
              [viewClass]="'truncated'"
            >
            </app-grid-column>
            <app-grid-column
              [field]="'Name'"
              [type]="'text'"
              [title]="'Accountant.CompanyName' | translate"
              [headerTooltip]="'Accountant.CompanyName' | translate"
              [viewClass]="'truncated'"
              [tooltipField]="'Name'"
              [editable]="false"
              [width]="140"
            ></app-grid-column>
            <app-grid-column
              [field]="'VATRegistrationNumber'"
              [type]="'text'"
              [title]="'Accountant.VATRegistrationNumber' | translate"
              [width]="95"
              [headerTooltip]="'Accountant.VATRegistrationNumber' | translate"
              [editable]="false"
              [viewClass]="'truncated'"
            >
            </app-grid-column>
            <app-grid-column
              [field]="'MainContactFullName'"
              [type]="'text'"
              [title]="'Accountant.MainContactFullName' | translate"
              [width]="140"
              [isFilterable]="true"
              [headerTooltip]="'Accountant.MainContactFullName' | translate"
              [viewClass]="'truncated'"
              [tooltipField]="'MainContactFullName'"
              [editable]="false"
            >
            </app-grid-column>
            <app-grid-column
              [field]="'ContactEmail'"
              [type]="'text'"
              [title]="'Accountant.ContactEmail' | translate"
              [width]="140"
              [isFilterable]="true"
              [headerTooltip]="'Accountant.ContactEmail' | translate"
              [viewClass]="'truncated'"
              [tooltipField]="'ContactEmail'"
              [editable]="false"
              [appTextType]="'email'"
            >
            </app-grid-column>
            <app-grid-column
              [field]="'InvoiceEmail'"
              [type]="'text'"
              [title]="'Accountant.InvoiceEmail' | translate"
              [isFilterable]="true"
              [width]="140"
              [headerTooltip]="'Accountant.InvoiceEmail' | translate"
              [editable]="service.editMode"
              [viewClass]="'truncated'"
              [appTextType]="'email'"
            ></app-grid-column>
            <app-grid-column
              [field]="'InvoiceTypeId'"
              [type]="'combobox'"
              [comboboxDataSource]="AccountInvoiceTypes"
              [comboboxValueField]="'Id'"
              [comboboxDisplayField]="'Name'"
              [title]="'Accountant.InvoiceTypeId' | translate"
              [width]="150"
              [isFilterable]="true"
              [headerTooltip]="'Accountant.InvoiceTypeId' | translate"
              [editable]="service.editMode"
              [viewClass]="'truncated'"
            >
            </app-grid-column>
            <app-grid-column
              [field]="'EmployeeCount'"
              [type]="'numeric'"
              [numbericOption]="'n0'"
              [title]="'Accountant.EmployeeCount' | translate"
              [width]="90"
              [isFilterable]="true"
              [headerTooltip]="'Accountant.EmployeeCount' | translate"
              [editable]="false"
              [viewClass]="'truncated'"
            >
            </app-grid-column>
            <app-grid-column
              [field]="'LastFinalized'"
              [type]="'datepicker'"
              [title]="'Accountant.LastFinalized' | translate"
              [isFilterable]="true"
              [headerTooltip]="'Accountant.LastFinalized' | translate"
              [editable]="false"
              [width]="105"
              [viewClass]="'truncated'"
            >
            </app-grid-column>
            <app-grid-column
              [field]="'LastFinalizedFullName'"
              [type]="'text'"
              [title]="'Accountant.LastFinalizedFullName' | translate"
              [width]="120"
              [isFilterable]="true"
              [headerTooltip]="'Accountant.LastFinalizedFullName' | translate"
              [viewClass]="'truncated'"
              [tooltipField]="'LastFinalizedFullName'"
              [editable]="false"
            >
            </app-grid-column>
            <app-grid-column
              [field]="'NextDeadline'"
              [type]="'datepicker'"
              [title]="'Accountant.NextDeadline' | translate"
              [isFilterable]="true"
              [headerTooltip]="'Accountant.NextDeadline' | translate"
              [editable]="false"
              [width]="105"
              [viewClass]="'truncated'"
            >
            </app-grid-column>

            <app-grid-column
              [field]="'UserRoleName'"
              [type]="'text'"
              [title]="'Accountant.YourRolse' | translate"
              [width]="110"
              [isFilterable]="true"
              [headerTooltip]="'Accountant.YourRolse' | translate"
              [viewClass]="'truncated'"
              [tooltipField]="'UserRoleName'"
              [editable]="false"
            >
            </app-grid-column>
            <app-grid-column
              [iconField]="'GoToIcon'"
              [type]="'icon'"
              [title]="'Accountant.GoToIcon' | translate"
              [headerTooltip]="'Accountant.GoToIcon' | translate"
              [disableButtonFeild]="'BlockingNavigate'"
              [tooltipField]="'BlockingNavigateTooltip'"
              [width]="65"
            >
            </app-grid-column>
          </app-grid>
        </div>
        <div
          class="Card Card--normal Card-inner accountantCompaniesGrid-users"
          [ngClass]="{ 'accountantCompaniesGrid-users-paggingavailable': isPagingUserAvailable }"
        >
          <div class="Card-title">
            {{ 'Accountant.UserAccess' | translate }} {{ service.companySelected ? service.companySelected.Name : '' }}
          </div>
          <app-grid
            class="company-accountant-role"
            [ngClass]="{
              'is-hide-comment': !isShowComment
            }"
            [data]="gridRoleFilterData"
            [editMode]="service.editMode"
            [reFocusAfterChangeEdit]="false"
            [pageSize]="pageSize"
            [hiddenCount]="true"
            [buttonCount]="5"
            [pageable]="true"
            [filterable]="'menu'"
            [filter]="roleFilter"
            (filterChange)="onRoleFilterChange($event)"
            (dblClick)="gridDblclick($event)"
            (saveChangesEvent)="onRowDataChange($event)"
            (controlDataChange)="onGridRoleControlDataChange($event)"
            (selectedItemChange)="onUserRoleSelected($event)"
            [idColumn]="'UserId'"
          >
            <app-grid-column
              [field]="'FullName'"
              [editable]="false"
              [type]="'text'"
              [title]="'Accountant.FullName' | translate"
              [isFilterable]="true"
              [headerTooltip]="'Accountant.FullName' | translate"
              [viewClass]="'truncated'"
              [width]="42"
            >
            </app-grid-column>
            <app-grid-column
              [field]="'RoleId'"
              [type]="'combobox'"
              [comboboxDataSource]="staticData.Role | async"
              [comboboxValueField]="'Id'"
              [comboboxDisplayField]="'Name'"
              [allowComboeditNull]="true"
              [editable]="service.editMode"
              [title]="'Accountant.Role' | translate"
              [width]="29"
              [isFilterable]="true"
              [defaultnullvalue]="0"
              [CurrentTextComboboxToolTip]="false"
              [headerTooltip]="'Accountant.Role' | translate"
              [viewClass]="'truncated'"
            >
            </app-grid-column>
            <app-grid-column
              [field]="'IsPaymentApprover'"
              [editable]="service.editMode"
              [type]="'checkboxgrid'"
              [title]="'Accountant.Finalize' | translate"
              [headerTooltip]="'Accountant.FinalizeTooltip' | translate"
              [width]="29"
              [isFilterable]="true"
              [headerTooltip]="'Accountant.Finalize' | translate"
            ></app-grid-column>
          </app-grid>

          <div class="internal-comment" *ngIf="isShowComment">
            <div class="Card-title Card-title-equal-margin-top">{{ 'Accountant.AccountComment' | translate }}</div>
            <span class="text-hint" *ngIf="service.company && !service.company.AccountComment && !service.editMode">{{
              'Accountant.InternalCommentHint' | translate
            }}</span>
            <app-text-edit
              *ngIf="service.company"
              [label]=""
              [editMode]="service.editMode"
              [(value)]="service.company.AccountComment"
              [isTextArea]="true"
              [textAreaLimitChar]="200"
              (valueChange)="onDirtyChange()"
              placeholder="{{ 'Accountant.InternalCommentHint' | translate }}"
            >
            </app-text-edit>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-fixed-panel>
<div [ngClass]="{ 'is-editing': service.editMode }">
  <div class="Actions" *ngIf="!isMobile && !service.editMode" [ngClass]="isIOSApp ? 'is-ios-app' : ''">
    <app-menu-button
      id="ActionButtonAdd"
      (menuClick)="addNewCompanyDialogVisible = true"
      [menuIcon]="'Add'"
      [menuLabel]="'Accountant.AddNewCompany'"
      [menuTooltip]="'Accountant.AddNewCompany'"
      [actionButtonMenu]="true"
      [menuButtonClass]="'Action-button Action-buttonAdd'"
      [menuButtonId]="'ActionButtonAdd'"
      [menuCondition]="true"
      *ngIf="!service.isBasicUser"
    >
    </app-menu-button>
    <app-menu-button
      (menuClick)="onMoreMenuOptionClick($event)"
      [menuIcon]="'ThreeDots'"
      [menuLabel]="'More.Title'"
      [menuTooltip]="'More.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item (menuItemClick)="onMoreMenuOptionClick($event)" [field]="'AttachExistingCompany'">
      </app-menu-button-item>
      <app-menu-button-item
        *ngIf="isRemoveCompany && !removeCompanyDialogVisible"
        (menuItemClick)="onMoreMenuOptionClick($event)"
        [field]="'RemoveCompany'"
      >
      </app-menu-button-item>
    </app-menu-button>
    <app-menu-button
      [menuIcon]="'Help'"
      [menuLabel]="'Help.Title'"
      [menuTooltip]="'Help.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
    </app-menu-button>
    <app-menu-button
      *ngIf="!service.isBasicUser"
      [editButtonMenu]="true"
      [editMode]="service.editMode"
      (menuChangeClick)="onEditModeChange($event)"
      [menuCondition]="true"
    >
    </app-menu-button>

    <app-menu-button
      *ngIf="reportDialogService.isVisibleReportIcon"
      [menuCondition]="true"
      [menuIcon]="'File'"
      [menuLabel]="'Employee.Reports'"
      [menuTooltip]="'Employee.Reports'"
      [actionButtonMenu]="true"
      [menuButtonClass]="'Action-button Action-buttonReports'"
      [menuButtonId]="'ActionButtonReports'"
      (menuClick)="reportDialogService.onShowReportsEventClick()"
    >
    </app-menu-button>
  </div>

  <div class="Actions" *ngIf="isMobile && !service.editMode" [ngClass]="isIOSApp ? 'is-ios-app' : ''">
    <app-menu-button
      (menuClick)="onMoreMenuOptionClick($event)"
      [menuIcon]="'ThreeDots'"
      [menuLabel]="'More.Title'"
      [menuTooltip]="'More.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item
        *ngIf="!service.isBasicUser"
        (menuItemClick)="addNewCompanyDialogVisible = true"
        [field]="'AccountantAddNewCompany'"
      ></app-menu-button-item>
      <app-menu-button-item (menuItemClick)="onMoreMenuOptionClick($event)" [field]="'AttachExistingCompany'">
      </app-menu-button-item>
      <app-menu-button-item
        *ngIf="isRemoveCompany"
        (menuItemClick)="onMoreMenuOptionClick($event)"
        [field]="'RemoveCompany'"
      >
      </app-menu-button-item>
      <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
    </app-menu-button>
    <app-menu-button
      *ngIf="!service.isBasicUser"
      [editButtonMenu]="true"
      [editMode]="false"
      (menuChangeClick)="onEditModeChange($event)"
      [menuCondition]="true"
    >
    </app-menu-button>
  </div>

  <div class="EditingInfo Actions" *ngIf="service.editMode">
    <app-button
      [isUseOneClick]="true"
      [buttonClass]="'Discard'"
      (clickEvent)="confirmAndDiscardChanges()"
      [label]="'Employee.DiscardButton' | translate"
      *ngIf="service.editMode"
    ></app-button>
    <app-menu-button
      [editButtonMenu]="true"
      [editMode]="true"
      (menuChangeClick)="onEditModeChange($event)"
      [menuCondition]="true"
      class="menu-button--editing"
    >
    </app-menu-button>
  </div>
</div>

<app-dialog
  (action)="discardDialogAction($event)"
  [(visible)]="discardDialogVisible"
  [leadingText]="'Employee.DiscardMessage' | translate"
>
  <app-dialog-action type="CancelDiscard"></app-dialog-action>
  <app-dialog-action type="ConfirmDiscard"></app-dialog-action>
</app-dialog>

<app-accountant-add-new-company-dialog
  (createSuccess)="onCreateSuccess($event)"
  [(visible)]="addNewCompanyDialogVisible"
></app-accountant-add-new-company-dialog>

<app-dialog
  (action)="onDoneCreateSuccess($event)"
  [(visible)]="createSuccessConfirm"
  [leadingText]="'Accountant.CreateConfirm' | translate"
>
  <app-dialog-action type="No"></app-dialog-action>
  <app-dialog-action type="Yes"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  (action)="onDoneRemoveSuccess($event)"
  [(visible)]="removeSuccessConfirm"
  [leadingText]="'Accountant.RemoveConfirm' | translate"
>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>

<app-dialog
  [width]="600"
  (action)="onAttachingAnExistingCompany($event)"
  [(visible)]="attachexistingcompanyVisable"
  class="attckCompanyDialog"
>
  <label
    [innerHTML]="
      ReplayMoreMenuLabelAttachingAnExistingCompany(
        'Accountant.MoreMenuLabelAttachingAnExistingCompany' | translate: { SystemAlias: branding.SystemAlias }
      )
    "
  ></label>

  <div class="Form--horizontal">
    <div class="FormElement">
      <span class="FormElement-label label-seach-attach-company">
        {{ 'Accountant.MoreMenuLabelAttachingAnExistingCompany2' | translate }}
      </span>
      <div class="FormElement-control">
        <input kendoTextBox class="FormElement-input" [(ngModel)]="filterAttachCompany" />
      </div>
    </div>
  </div>

  <app-grid
    [idColumn]="'Id'"
    [data]="userCompanyUserViewsFilter"
    [(selectedId)]="selectUserCompanyUserViews"
    [editMode]="false"
    [filterable]="'menu'"
    [filter]="attackCompanyFilter"
    (filterChange)="onAttackCompanyFilterChange($event)"
    [pageSize]="150"
    [(skip)]="currentPageAttachCompany"
    [pageable]="true"
  >
    <app-grid-column
      [width]="75"
      [type]="'text'"
      [field]="'Id'"
      [title]="'Id' | translate"
      [isFilterable]="false"
    ></app-grid-column>
    <app-grid-column
      [width]="350"
      [type]="'text'"
      [field]="'Name'"
      [title]="'Report.Name' | translate"
      [isFilterable]="false"
    ></app-grid-column>
  </app-grid>

  <label
    class="accountant-warning"
    *ngIf="userCompanyUserViews && userCompanyUserViews.length === 0"
    [innerHTML]="'Accountant.WarningNoCompany' | translate"
  ></label>
  <app-check-edit
    [editMode]="true"
    [(value)]="isUnderStoodTheAbove"
    [label]="'Accountant.UnderstoodTheAbove' | translate"
  >
  </app-check-edit>
  <app-dialog-action
    [disabled]="(userCompanyUserViews && userCompanyUserViews.length <= 0) || !selectUserCompanyUserViews"
    type="Submit"
    [close]="false"
  >
  </app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onRemoveCompany($event)"
  [(visible)]="removeCompanyDialogVisible"
  [leadingText]="'Accountant.WarningRemoveCompany' | translate: { SystemAlias: branding.SystemAlias }"
>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Delete"></app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" [(visible)]="isCompleteAttached" (action)="onDoneCreateSuccess($event)">
  <p>{{ 'Accountant.WarnAModuleAutoEnable' | translate }}</p>
  <p>{{ 'Accountant.CompleteAttached' | translate }}</p>

  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>

<app-dialog (action)="onConfirmSaveDataOnChangeState($event)" [(visible)]="service.showSaveDataConfirmationChangeState">
  <div [innerHTML]="'Employee.UnsavedData' | translate"></div>
  <app-dialog-action [disabled]="sessionService.isSubmitting" type="DiscardAndLeave"></app-dialog-action>
  <app-dialog-action [disabled]="sessionService.isSubmitting" type="Stay"></app-dialog-action>
  <app-dialog-action [disabled]="sessionService.isSubmitting" type="ContinueAndSave"></app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" [(visible)]="confimTermVisible">
  <p>{{ 'GlobalDialog.CheckboxValidationMessage' | translate }}</p>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
