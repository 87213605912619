import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { EmployeeService } from 'src/app/services/employee.service';
import { FixedPanelService } from 'src/app/services/fixed-panel.service';
import { FormComponentBase } from '../../common/form-component-base';
import { ICompanyUser, IRole } from '../../services/api/api-model';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-employee-info',
  templateUrl: './employee-info.component.html',
  styleUrls: ['./employee-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeInfoComponent extends FormComponentBase implements OnInit {
  @Output() public validated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public isEmployeeTab = false;
  @Input()
  public get companyUser(): ICompanyUser {
    if (!this.currentCompanyUser) {
      this.currentCompanyUser = {} as any;
    }

    return this.currentCompanyUser;
  }
  public set companyUser(value: ICompanyUser) {
    this.currentCompanyUser = value;
    this.childIsValid = Array(7).fill(true);
    this.childIsDirty = Array(7).fill(false);
  }

  @Input() public get currentPositionId(): number {
    return this.positionId;
  }
  public set currentPositionId(value: number) {
    this.positionId = value;
  }

  public get allowEdit() {
    return this.sessionService.role.IsSalaryAdminAndHigher;
  }

  public get allowEditRole() {
    return this.sessionService.role.IsFullAdmin;
  }

  public expandUserInfoDetails = false;
  public hasLanguageModule = false;
  private currentCompanyUser: ICompanyUser;
  private positionId: number;
  public istoogledescription = false;

  public get isGeneralTabSelfService(): boolean {
    return this.sessionService.currentState.includes('tabs.selfservice.general');
  }

  constructor(
    public employeeService: EmployeeService,
    public staticDataService: StaticDataService,
    private sessionService: SessionService,
    private fixedPanelService: FixedPanelService
  ) {
    super();
    this.childIsValid = Array(7).fill(true);
    this.childIsDirty = Array(7).fill(false);
  }

  public ngOnInit(): void {}

  public get userId(): number {
    if (this.companyUser) {
      return this.companyUser.UserId;
    }

    return undefined;
  }

  public get showIsPaymentApprover(): boolean {
    return this.companyUser && this.companyUser.RoleId > 20 ? true : false;
  }

  public get EmployeeWithRoleAsManager(): boolean {
    return this.sessionService.role.EmployeeWithRoleAsManager;
  }

  @Output() expandUserInfoEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() EmploymentChangeEmitter: EventEmitter<any> = new EventEmitter();
  public onToggleEmployeeInfoDropdown(): void {
    this.expandUserInfoDetails = !this.expandUserInfoDetails;
    this.expandUserInfoEmitter.emit(this.expandUserInfoDetails);
    this.istoogledescription = !this.istoogledescription;
    setTimeout(() => {
      this.fixedPanelService.calculateHeightContent();
    }, 500);
  }

  public onPaymentApproverClick(): void {
    this.childIsDirty[5] = true;
    this.childIsValid[5] = true;
  }

  public onIsActiveClick(): void {
    this.childIsDirty[6] = true;
    this.childIsValid[6] = true;
  }

  public onEmploymentChange(): void {
    this.EmploymentChangeEmitter.emit();
  }
}
