import { OnInit } from '@angular/core';
import { ImportTypesEnum } from 'src/app/model/enum';
import { ReportParametersDefaultValue } from 'src/app/shared-components/report-dialog/report-parameters-default-value';
import { Browser } from '../../../common/browser';
import { DataService } from '../../../services/api/data.service';
import { EmployeeService } from '../../../services/employee.service';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';
import { ReportDialogService } from '../../../shared-components/report-dialog/report-dialog.service';
var EmployeePayrollDataComponent = /** @class */ (function () {
    function EmployeePayrollDataComponent(employeeService, sessionService, settingService, dataService, reportDialogService) {
        this.employeeService = employeeService;
        this.sessionService = sessionService;
        this.settingService = settingService;
        this.dataService = dataService;
        this.reportDialogService = reportDialogService;
        this.reportDialogVisible = false;
        this.newEmploymentDialogVisible = false;
        this.recalculate = false;
        this.newTabBlockedDialogVisible = false;
        this.previewDialogVisible = false;
        this.noPayslipDialogVisible = false;
        this.isHidePreviewGrid = false;
        this.isAddNew = false;
        this.createNewEmployeeDialogVisible = false;
        this.listClassExcluded = ['EmployeeInfo', 'Footer'];
        this.hasEmployment = false;
        this.expanded = false;
    }
    Object.defineProperty(EmployeePayrollDataComponent.prototype, "allowEdit", {
        get: function () {
            return this.sessionService.role.IsSalaryAdminAndHigher;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeePayrollDataComponent.prototype, "userEmployment", {
        get: function () {
            return this.userEmploymentValue;
        },
        set: function (value) {
            if (!this.userEmploymentValue && !value) {
                return;
            }
            this.userEmploymentValue = value;
            this.hasEmployment = this.userEmployment && this.userEmployment.Id ? true : false;
            this.reportParams();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeePayrollDataComponent.prototype, "currentCompanyUser", {
        get: function () {
            return this.privateCurrentCompanyUser;
        },
        set: function (value) {
            this.privateCurrentCompanyUser = value;
            this.reportParams();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeePayrollDataComponent.prototype, "isMobile", {
        get: function () {
            var browser = new Browser();
            return browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    EmployeePayrollDataComponent.prototype.reportParams = function () {
        var params = new ReportParametersDefaultValue();
        params.employeeId = this.currentCompanyUser ? this.currentCompanyUser.Id : undefined;
        params.employmentId = this.userEmployment ? this.userEmployment.Id : undefined;
        this.reportDialogService.reportParams = params;
    };
    Object.defineProperty(EmployeePayrollDataComponent.prototype, "isCordovaApp", {
        get: function () {
            return this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeePayrollDataComponent.prototype, "hasUserEmployment", {
        get: function () {
            return this.hasEmployment;
        },
        enumerable: true,
        configurable: true
    });
    EmployeePayrollDataComponent.prototype.ngOnInit = function () {
        this.settingService.showModuleMessage('Employee.PayrollData');
    };
    EmployeePayrollDataComponent.prototype.importInitialBalancesClick = function () {
        this.settingService.navigationParameters['dataImportSelectedOption'] = ImportTypesEnum.Initial_Balances;
        this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
    };
    EmployeePayrollDataComponent.prototype.importDataClick = function () {
        this.settingService.navigationParameters['dataImportSelectedOption'] = ImportTypesEnum.Payroll;
        this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
    };
    Object.defineProperty(EmployeePayrollDataComponent.prototype, "ClassCardStack", {
        get: function () {
            if (this.isHidePreviewGrid) {
                return '';
            }
            return 'Cards Cards--flex Form--stacked';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeePayrollDataComponent.prototype, "noCPRMessage", {
        get: function () {
            return this.currentCompanyUser && this.currentCompanyUser.HasDummyIdentityNumber ? 'Employee.NoValidCPR' : '';
        },
        enumerable: true,
        configurable: true
    });
    /* GS-5298
    public get isAddNewEmploymentHide(): boolean {
      return this.currentCompanyUser && this.currentCompanyUser.Gender === null;
    }
  
    public createUserEmployment(): void {
      if (this.isAddNewEmploymentHide) {
        return;
      }
      this.createUserEmploymentClick.emit();
    }
  */
    EmployeePayrollDataComponent.prototype.createUserEmployment = function () {
        this.newEmploymentDialogVisible = true;
    };
    EmployeePayrollDataComponent.prototype.viewFullSalaryBatch = function () {
        this.sessionService.NavigateTo('tabs.company.salarybatches');
    };
    EmployeePayrollDataComponent.prototype.viewLastPayslip = function () {
        var _this = this;
        this.dataService
            .SalaryStatements_GetSalaryStatementsByCompanyUser(this.userEmployment.CompanyUserId)
            .subscribe(function (data) {
            if (data.length === 0) {
                _this.noPayslipDialogVisible = true;
            }
            else {
                // Only show the last payslip - which should be the first one in the list
                _this.showPayslipInNewTab(data[0]);
            }
        });
    };
    EmployeePayrollDataComponent.prototype.showPayslipInNewTab = function (payslip) {
        var _this = this;
        this.dataService.SalaryStatements_GetHtmlPreview('htmlstring', payslip).subscribe(function (data) {
            if (_this.sessionService.browser.isHybridApp) {
                _this.previewContent = data;
                _this.previewDialogVisible = true;
            }
            else {
                var newWindow = window.open('about:blank');
                if (newWindow) {
                    newWindow.document.write(data);
                    newWindow.document.close();
                }
                else {
                    _this.newTabBlockedDialogVisible = true;
                }
            }
        });
    };
    EmployeePayrollDataComponent.prototype.onUpdate = function () {
        this.isUpdate = true;
    };
    EmployeePayrollDataComponent.prototype.onAddNewItem = function () {
        if (!this.sessionService.isSubmitting) {
            this.isAddNew = true;
        }
    };
    EmployeePayrollDataComponent.prototype.onExpand = function (event) {
        this.expanded = event;
    };
    return EmployeePayrollDataComponent;
}());
export { EmployeePayrollDataComponent };
