import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from 'src/app/common/global';
import { IExternalAccount, ISalaryTypeView, IUnitType } from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { CompanyAdvancedTypesService } from '../company-advanced-types.service';
import { AdvancedTimeEntryTypesService } from './advanced-time-entry-types.service';
@Component({
  selector: 'app-time-entry-types-single',
  styleUrls: ['./time-entry-types-single.component.scss'],
  templateUrl: './time-entry-types-single.component.html'
})
export class TimeEntryTypesSingleComponent implements OnInit, OnDestroy {
  public childIsDirty: boolean[] = [];
  public childIsValid: boolean[] = [];
  public translationDialogVisible = false;
  public externalAccounts: IExternalAccount[];
  public unitTypes: IUnitType[] = Global.UNIT_TYPES;

  @Input() public salaryTypes: ISalaryTypeView[];

  public get isLanguageModuleEnable(): boolean {
    return this.sessionService.feature.hasModuleId(5);
  }

  public get isDisableSalaryType() {
    return (
      this.companyAdvancedTypesService.selectedType.TimeEntryTypeConfigurations &&
      this.companyAdvancedTypesService.selectedType.TimeEntryTypeConfigurations.length > 0
    );
  }

  @Output() public editClickAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() public addClickAction: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private dataService: DataService,
    public translateService: TranslateService,
    public sessionService: SessionService,
    public advancedTimeEntryTypesService: AdvancedTimeEntryTypesService,
    public companyAdvancedTypesService: CompanyAdvancedTypesService
  ) {
    this.advancedTimeEntryTypesService.addNewShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && !this.sessionService.role.IsReadOnly) {
          this.addClickAction.emit();
        }
      });

    this.advancedTimeEntryTypesService.editShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (
          value &&
          this.sessionService.role.IsFullAdmin &&
          !this.sessionService.isGetting &&
          !this.companyAdvancedTypesService.editMode
        ) {
          this.editClickAction.emit(true);
        }
      });

    this.resetDirty();
  }

  public inheritDefaultValue = '<Nedarvet, klik for at overstyre>';
  public ngOnInit(): void {
    if (Global.SESSION.SignOnToken.LanguageId === 2) {
      this.inheritDefaultValue = '<Inherited, click to override>';
    } else {
      this.inheritDefaultValue = '<Nedarvet, klik for at overstyre>';
    }

    this.dataService.Integrations_GetAllExternalAccounts().subscribe((externalAccounts: IExternalAccount[]) => {
      this.externalAccounts = externalAccounts;
      if (this.externalAccounts && this.externalAccounts.length > 0) {
        this.externalAccounts.forEach((model: any) => {
          model.FriendlyName = model.AccountNumber + (model.AccountName ? ' - ' + model.AccountName : '');
        });
      }
    });
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  @Output() public reloadListData: EventEmitter<any> = new EventEmitter<any>();
  public onReloadTimeEntryTypes(): void {
    this.reloadListData.emit(true);
  }

  public get disableSalaryType(): string {
    if (
      this.companyAdvancedTypesService.selectedType.TimeEntryTypeConfigurations &&
      this.companyAdvancedTypesService.selectedType.TimeEntryTypeConfigurations.length > 0
    ) {
      return this.companyAdvancedTypesService.selectedType.salaryTypeClassSingle + ', disable-class';
    }

    return this.companyAdvancedTypesService.selectedType.salaryTypeClassSingle;
  }

  public onUpdateTimeEntryTypeConfiguration(timeEntryTypeConfigurations: any): void {
    if (timeEntryTypeConfigurations) {
      this.companyAdvancedTypesService.selectedType.TimeEntryTypeConfigurations = timeEntryTypeConfigurations;

      if (timeEntryTypeConfigurations.length > 0) {
        this.companyAdvancedTypesService.selectedType.SalaryTypeId = null;
      } else if (timeEntryTypeConfigurations.isDeleteAllData) {
        this.reloadListData.emit(true);
      }
    }
  }

  public onChange(event?: any, field?: string, index?: number) {
    if (field) {
      this.companyAdvancedTypesService.onChangeInheritValue(field, event, index);
    }
    this.companyAdvancedTypesService.tabDirty = this.childIsDirty.indexOf(true) >= 0;
  }

  public onTranslationConfigrationClicked() {
    this.translationDialogVisible = true;
  }

  public resetDirty() {
    this.childIsDirty = Array(8).fill(false);
    this.childIsValid = Array(3).fill(false);
    this.companyAdvancedTypesService.tabDirty = false;
  }
}
