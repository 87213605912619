import { EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Constants } from '../../../common/constants';
import { Global } from '../../../common/global';
import { DataService } from '../../../services/api/data.service';
import { SessionService } from '../../../services/session/session.service';
import { CompanySalaryBatchService } from '../company-salary-batches.service';
var RevertFinalizedBatchDialogComponent = /** @class */ (function () {
    function RevertFinalizedBatchDialogComponent(sessionService, dataService, companySalaryBatchService) {
        var _this = this;
        this.sessionService = sessionService;
        this.dataService = dataService;
        this.companySalaryBatchService = companySalaryBatchService;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.revertBatchCompleted = new EventEmitter();
        this.passwordDialogVisible = false;
        this.ngUnsubscribe = new Subject();
        this.userName = localStorage.getItem('saveLoginUserName');
        this.companySalaryBatchService.selectedSalaryBatch
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            _this.salaryBatch = value;
        });
    }
    Object.defineProperty(RevertFinalizedBatchDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.visibleValue = value;
                this.visibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RevertFinalizedBatchDialogComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RevertFinalizedBatchDialogComponent.prototype, "translationParams", {
        get: function () {
            if (this.companySalaryBatchService.hasIntegrationName) {
                return { externalSystem: this.companySalaryBatchService.integrationName };
            }
            return { externalSystem: '' };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RevertFinalizedBatchDialogComponent.prototype, "isGreenland", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    RevertFinalizedBatchDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    RevertFinalizedBatchDialogComponent.prototype.onDialogAction = function (action) {
        if (action === 'Understood') {
            this.passwordDialogVisible = true;
        }
    };
    RevertFinalizedBatchDialogComponent.prototype.onPasswwordDialogAction = function (action) {
        var _this = this;
        if (action === 'Proceed') {
            var request = { Password: this.password };
            this.dataService.SalaryBatches_RevertSalaryBatch(this.salaryBatch.Id, request).subscribe(function () {
                _this.revertBatchCompleted.emit();
                _this.visible = false;
            });
        }
        else {
            this.visible = false;
        }
    };
    return RevertFinalizedBatchDialogComponent;
}());
export { RevertFinalizedBatchDialogComponent };
