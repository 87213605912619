import { Component, ViewEncapsulation } from '@angular/core';
import { IntegrationsService } from '../../integrations.service';

@Component({
  selector: 'app-credential-integrations',
  templateUrl: './credential-integrations.component.html',
  styleUrls: ['./credential-integrations.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CredentialIntegrationsComponent {
  constructor(public integrationsService: IntegrationsService) {}
}
