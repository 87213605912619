<!--Sub View For "Hiring Data" section-->
<fieldset class="Form--horizontal hiringDataComponent">
  <legend class="Card-title">{{ 'EmployeeGeneral.HiringData' | translate }}</legend>
  <div *ngIf="!hasEmployment">
    <p>{{ 'EmploymentPayrollData.NoEmployment' | translate }}</p>
  </div>

  <div *ngIf="hasEmployment">
    <!--Employment ID-->
    <app-text-edit
      [label]="'EmployeeGeneral.EmployeeID' | translate"
      [editMode]="false"
      [type]="'text'"
      [(isValid)]="childIsValid[0]"
      [(isDirty)]="childIsDirty[0]"
      [(value)]="userEmployment.Id"
      (valueChange)="onChange()"
    ></app-text-edit>
    <!--Employment ID-->
    <!--Departments-->
    <app-module-check [moduleId]="4" [(hasModule)]="hasDepartmentEnabled">
      <div
        class="Control--wrapper IconAdd-container"
        [ngClass]="editMode && !IsDisableCreateDepartment ? 'controlFlex' : ''"
      >
        <app-combobox-edit
          [ngClass]="editMode ? 'customFlexComboAddNew' : ''"
          [editMode]="editMode"
          [label]="'EmployeeGeneral.Department' | translate"
          [nullValueLabel]="'DropdownList.None' | translate"
          [disable]="!hasDepartmentEnabled"
          [keepDataSourceOrder]="true"
          [comboboxDataSource]="staticDataService.departments | async"
          [(isValid)]="childIsValid[1]"
          [(isDirty)]="childIsDirty[1]"
          [textField]="'Name'"
          [idField]="'Id'"
          [(value)]="userEmployment.DepartmentId"
          (valueChange)="onComboboxEmployeeDetailDepartmentSelectionChanged()"
        >
        </app-combobox-edit>
        <app-icon
          *ngIf="editMode && !IsDisableCreateDepartment"
          [icon]="'circleplus'"
          class="iconDepartment"
          (click)="AddDepartmentVisible = true"
        ></app-icon>
      </div>
    </app-module-check>
    <!--Departments-->
    <!--Workplace identifier -->
    <app-text-edit
      [label]="'EmployeeGeneral.Workplace' | translate"
      *ngIf="isSwedenCompany"
      [editMode]="editMode"
      [type]="'text'"
      [(isValid)]="childIsValid[10]"
      [(isDirty)]="childIsDirty[10]"
      [(value)]="userEmployment.WorkplaceIdentifier"
      (valueChange)="onChange()"
    ></app-text-edit>
    <!--Workplace identifier -->
    <!-- Manager -->
    <app-combobox-edit
      [label]="'NewEmployee.Manager' | translate"
      [editMode]="editMode"
      [comboboxDataSource]="staticDataService.companyUsers | async"
      [nullValueLabel]="'DropdownList.None' | translate"
      [(isValid)]="childIsValid[2]"
      [(isDirty)]="childIsDirty[2]"
      [textField]="'FullName'"
      [idField]="'Id'"
      [(value)]="userEmployment.ManagerCompanyUserId"
      (valueChange)="onChange()"
    ></app-combobox-edit>
    <!-- /Manager -->
    <!--External Reference-->
    <app-text-edit
      [label]="'EmployeeGeneral.ExternalReference' | translate"
      [editMode]="editMode"
      [type]="'text'"
      [(isValid)]="childIsValid[3]"
      [(isDirty)]="childIsDirty[3]"
      [(value)]="userEmployment.ExternalReference"
      (valueChange)="onChange()"
    ></app-text-edit>
    <!--External Reference-->
    <!--Hiring Date-->
    <app-date-picker
      [label]="'EmployeeGeneral.HiringDate' | translate"
      [editMode]="editMode"
      [required]="true"
      [requiredMessage]="'EmployeeGeneral.Required' | translate"
      [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
      [(value)]="userEmployment.HireDate"
      [(isValid)]="childIsValid[4]"
      [(isDirty)]="childIsDirty[4]"
      (valueChange)="onChange()"
    ></app-date-picker>

    <!--Hiring Date-->
    <!--Seniority Date-->
    <!--required="true" required-message="EmployeeGeneral.Required"-->
    <app-date-picker
      [label]="'EmployeeGeneral.SeniorityDate' | translate"
      [editMode]="editMode"
      [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
      [(isValid)]="childIsValid[5]"
      [(isDirty)]="childIsDirty[5]"
      [(value)]="userEmployment.SeniorityDate"
      (valueChange)="onChange()"
    >
    </app-date-picker>
    <!-- SeniorityDate -->
    <!--Salary Cycle-->
    <app-combobox-edit
      [label]="'EmployeeGeneral.SalaryCycle' | translate"
      [editMode]="editMode"
      [comboboxDataSource]="staticDataService.SalaryCycle | async"
      [(isValid)]="childIsValid[6]"
      [(isDirty)]="childIsDirty[6]"
      [(value)]="salaryCycleId"
      [textField]="'Name'"
      [idField]="'Id'"
      (valueChange)="onCycleChange($event)"
    >
    </app-combobox-edit>
    <!--Salary Cycle-->
    <!--Termination Date-->
    <app-date-picker
      [label]="'EmployeeGeneral.TerminationDate' | translate"
      *ngIf="!shouldHideTerminationDate"
      [editMode]="editMode"
      [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
      [(isValid)]="childIsValid[7]"
      [(isDirty)]="childIsDirty[7]"
      [(value)]="userEmployment.ExpireDate"
      (valueChange)="onChange()"
    ></app-date-picker>
    <!--Termination Date-->
    <!--Base on template-->
    <app-combobox-edit
      *ngIf="showTemplates"
      [label]="'EmployeeEmployment.BasedOnTemplate' | translate"
      [editMode]="editMode"
      [comboboxDataSource]="templates"
      [nullValueLabel]="'DropdownList.None' | translate"
      [(isValid)]="childIsValid[8]"
      [(isDirty)]="childIsDirty[8]"
      [textField]="'Name'"
      [idField]="'Id'"
      [(value)]="userEmployment.BasedOnTemplateId"
      (valueChange)="onChange()"
    ></app-combobox-edit>
    <!--Base on template-->

    <app-text-edit
      [editMode]="editMode"
      [type]="'text'"
      [label]="'EmploymentPayrollData.CollectiveAgreementCode' | translate"
      [(isValid)]="childIsValid[9]"
      [(isDirty)]="childIsDirty[9]"
      [(value)]="userEmployment.CollectiveBargainingAgreementCode"
      (valueChange)="onChange()"
    ></app-text-edit>
  </div>
</fieldset>

<app-add-department
  [(isVisible)]="AddDepartmentVisible"
  [(userEmployment)]="userEmployment"
  [companyContacts]="staticDataService.companyUsers | async"
></app-add-department>

<app-dialog
  [(visible)]="isShowSalaryCycleChangeWarnning"
  [leadingText]="'EmployeeGeneral.SalaryCycleChangeWarnning' | translate"
  (action)="confimCycleChange($event)"
>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Understood"></app-dialog-action>
</app-dialog>
