<div class="FormElement" [ngClass]="!editMode ? 'FormElement-ViewMode' : ''">
  <span class="FormElement-label" *ngIf="label && !isLabelHtml && !editMode">{{ label }}</span>
  <span class="FormElement-label" *ngIf="label && isLabelHtml && !editMode" [innerHTML]="label"></span>

  <label class="FormElement-label" for="{{ componentId }}" *ngIf="label && !isLabelHtml && editMode"
    >{{ label }}:</label
  >
  <label
    class="FormElement-label"
    for="{{ componentId }}"
    *ngIf="label && isLabelHtml && editMode"
    [innerHTML]="label"
  ></label>

  <div class="FormElement-control FormElement-control-validation">
    <div class="FormElement-static {{ viewClass }}" *ngIf="!editMode">{{ value }}</div>

    <kendo-autocomplete
      #autoCompleteTextbox
      class="FormElement-autoComplete"
      *ngIf="editMode"
      id="{{ componentId }}"
      [disabled]="disable"
      [filterable]="true"
      [suggest]="isSuggest"
      [data]="dataSource"
      [placeholder]="placeholder"
      [(value)]="value"
      [valueField]="textField"
      (filterChange)="autoCompleteFilterChanged($event)"
      (valueChange)="onChange()"
      (keyup)="onKeyup($event)"
      (focus)="onfocus()"
    >
    </kendo-autocomplete>

    <div class="validation-texteditor k-widget k-tooltip-validation" *ngIf="showValidationMessage">
      {{ requiredMessage }}
    </div>
  </div>
</div>
