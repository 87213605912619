import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CompanyAccountView } from 'src/app/services/api/api-model';
import { DataService } from 'src/app/services/api/data.service';
import { environment } from 'src/environments/environment';
import { StaticDataService } from '../../services/api/static-data.service';
import { ModalService } from '../../services/modal.service';
import { SessionService } from '../../services/session/session.service';
import { AccountantServiceBase } from '../accountant-service-base';
import * as i0 from "@angular/core";
import * as i1 from "../../services/api/data.service";
import * as i2 from "../../services/session/session.service";
import * as i3 from "../../services/modal.service";
import * as i4 from "../../services/api/static-data.service";
var AccountantCompaniesService = /** @class */ (function (_super) {
    tslib_1.__extends(AccountantCompaniesService, _super);
    function AccountantCompaniesService(api, sessionService, modalService, staticDataService) {
        var _this = _super.call(this, api, modalService, sessionService, staticDataService) || this;
        _this.api = api;
        _this.sessionService = sessionService;
        _this.modalService = modalService;
        _this.staticDataService = staticDataService;
        _this.ngUnsubscribe = new Subject();
        _this.isDirty = false;
        _this.showSaveDataConfirmationChangeState = false;
        _this.company = new CompanyAccountView();
        _this.EditRoleList = [];
        _this.EditCompanyList = [];
        _this.readloadUserConpanyRolChange = new EventEmitter();
        _this.companiesSubject = new BehaviorSubject([]);
        _this.allCompanies = [];
        _this.sourceCompaniesSubject = new BehaviorSubject([]);
        return _this;
    }
    Object.defineProperty(AccountantCompaniesService.prototype, "sourceCompanies", {
        get: function () {
            return this.sourceCompaniesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountantCompaniesService.prototype, "companies", {
        get: function () {
            if (!this.companiesSubject || !this.allCompanies || (this.allCompanies && this.allCompanies.length === 0)) {
                this.loadData();
            }
            return this.companiesSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    AccountantCompaniesService.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    AccountantCompaniesService.prototype.clearCompanySubject = function () {
        this.selectedId = null;
        this.currentSelectId = null;
        this.companySelected = null;
        this.allCompanies = [];
        this.companiesSubject.next([]);
        this.rolesSubject.next([]);
    };
    AccountantCompaniesService.prototype.loadData = function (isClearSelect) {
        var _this = this;
        if (isClearSelect === void 0) { isClearSelect = true; }
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        this.api
            .Account_GetAccountCompanies()
            .pipe(tap(function (d) { return d.forEach(function (rec) { return (rec.GoToIcon = 'sign-in'); }); }))
            .subscribe(function (data) {
            _this.allCompanies = data;
            _this.sourceCompaniesSubject.next(data.map(function (c) {
                return tslib_1.__assign({}, c, { Name: c.Name + " [" + c.Id + "]" });
            }));
            _this.isLoading = false;
            _this.applyFilter(isClearSelect);
        });
    };
    AccountantCompaniesService.prototype.save = function (dataRoleList) {
        var _this = this;
        this.company.AccountComment = this.company.AccountComment || null;
        if (this.company && this.originalComment !== this.company.AccountComment) {
            this.api
                .Account_SetAccountComment(this.selectedId, { Comment: this.company.AccountComment })
                .subscribe(function () { return (_this.originalComment = _this.company.AccountComment); });
        }
        _super.prototype.save.call(this, dataRoleList);
    };
    AccountantCompaniesService.prototype.discard = function () {
        this.company.AccountComment = this.originalComment;
        _super.prototype.discard.call(this);
    };
    AccountantCompaniesService.prototype.getRoles = function () {
        this.readloadUserConpanyRolChange.emit(true);
        this.getRoleServicePublic();
    };
    AccountantCompaniesService.prototype.getRoleServicePublic = function () {
        var _this = this;
        if (this.selectedId && this.selectedId !== this.currentSelectId) {
            this.currentSelectId = this.selectedId;
            this.rolesSubject.next([]);
            this.company = this.companiesSubject.value.find(function (c) { return c.Id === _this.selectedId; });
            this.originalComment = this.company ? this.company.AccountComment : undefined;
            this.api.Account_GetUserCompanyAccess(this.selectedId).subscribe(function (data) {
                if (data && data.length > 0) {
                    if (data[0].CompanyId === _this.selectedId) {
                        _this.rolesSubject.next(data);
                    }
                    else {
                        if (!environment.production) {
                            console.error("CompanyId " + _this.selectedId + " doesnt match recieved data");
                        }
                    }
                }
                else {
                    if (!environment.production) {
                        console.log('No roles');
                    }
                }
            });
        }
    };
    AccountantCompaniesService.prototype.applyFilter = function (isClearSelect) {
        if (isClearSelect === void 0) { isClearSelect = true; }
        if (this.filter) {
            this.currentPage = 0;
            var searchText_1 = this.filter.toLocaleLowerCase();
            var data = this.allCompanies.filter(function (c) {
                return (c.VATRegistrationNumber && c.VATRegistrationNumber.toLocaleLowerCase().includes(searchText_1)) ||
                    c.Name.toLocaleLowerCase().includes(searchText_1) ||
                    c.Id.toString().includes(searchText_1) ||
                    (c.MainContactFullName && c.MainContactFullName.toLocaleLowerCase().includes(searchText_1)) ||
                    (c.InvoiceEmail && c.InvoiceEmail.toLocaleLowerCase().includes(searchText_1)) ||
                    (c.AccountComment && c.AccountComment.toLocaleLowerCase().includes(searchText_1));
            });
            this.companiesSubject.next(data);
        }
        else {
            this.companiesSubject.next(this.allCompanies);
        }
        if (isClearSelect) {
            this.selectedId = undefined;
        }
    };
    AccountantCompaniesService.prototype.updateData = function (model) { };
    AccountantCompaniesService.prototype.reloadData = function () { };
    AccountantCompaniesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountantCompaniesService_Factory() { return new AccountantCompaniesService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.SessionService), i0.ɵɵinject(i3.ModalService), i0.ɵɵinject(i4.StaticDataService)); }, token: AccountantCompaniesService, providedIn: "root" });
    return AccountantCompaniesService;
}(AccountantServiceBase));
export { AccountantCompaniesService };
