declare let window: any;

export class Browser {
  private browserName: string;
  private fullVersion: string;
  private majorVersion: number;
  private userAgent: string;

  constructor() {
    this.getBrowserData(window.navigator || navigator);
  }

  public get isHybridApp(): boolean {
    return this.isMobile && (window.cordova as any);
  }

  public get isMacOS(): boolean {
    const navi: Navigator = window.navigator || navigator;
    return navi.platform.toUpperCase().indexOf('MAC') >= 0;
  }

  public get isMobile(): boolean {
    return /Mobile/i.test(this.userAgent);
  }

  public get iOSMobileDevice(): boolean {
    const navi: Navigator = window.navigator || navigator;
    const iDevices: string[] = ['iPad', 'iPhone', 'iPod'];
    if (!!navi.platform) {
      while (iDevices.length) {
        if (navi.platform === iDevices.pop()) {
          return true;
        }
      }
    }

    return false;
  }

  public get iOSversion(): any {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      const v: any = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
      return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
    }
  }

  public get isSafari(): boolean {
    return this.browserName && this.browserName.toLowerCase() === 'safari';
  }

  public get isIphoneNotch(): boolean {
    const ratio: number = window.devicePixelRatio || 1;
    const screen: any = {
      width: window.screen.width * ratio,
      height: window.screen.height * ratio
    };

    if (
      (this.iOSMobileDevice && (screen.width === 1125 && screen.height === 2436)) || // x, xs, 11
      screen.height === 2688 || // xs max, 11 pro max
      screen.height === 2532 || // 12, 12 pro
      screen.height === 2340 || // 12 mini
      screen.height === 2778 || // 12 pro max
      screen.height === 1792 // XR
    ) {
      return true;
    }

    return false;
  }

  public get isMobileSafari(): boolean {
    return this.iOSMobileDevice && this.isMobile && this.isSafari;
  }

  public get isFirefox(): boolean {
    return this.browserName && this.browserName.toLowerCase() === 'firefox';
  }

  public get isChrome(): boolean {
    return this.browserName && this.browserName.toLowerCase() === 'chrome';
  }

  public get isIE(): boolean {
    return this.browserName && this.browserName.toLowerCase() === 'ie';
  }

  public get isEdge(): boolean {
    return this.browserName && this.browserName.toLowerCase() === 'edge';
  }

  public get isAndroidDevice(): boolean {
    return /Android/i.test(this.userAgent);
  }

  public get isSupportedBrowser(): boolean {
    if (window.cordova) {
      return true;
    }

    const brName: string = this.browserName.toLowerCase();
    return (
      brName === 'ie' ||
      brName === 'opera' ||
      brName === 'firefox' ||
      brName === 'safari' ||
      brName === 'chrome' ||
      brName === 'edge' ||
      brName === 'opera mobile' ||
      brName === 'firefox mobile' ||
      brName === 'android' ||
      brName === 'ios' ||
      brName === 'windows'
    );
  }

  public get isOutdatedBrowser(): boolean {
    if (window.cordova) {
      return false;
    }

    if (this.isMobile) {
      return this.checkOutdatedMobileBrowser();
    }

    return this.checkOutdatedBrowser();
  }

  private checkOutdatedMobileBrowser(): boolean {
    if (!this.browserName) {
      return true;
    }

    let result: boolean;
    const brName: string = this.browserName.toLowerCase();
    switch (brName) {
      case 'chrome':
        result = this.majorVersion < 69;
        break;
      case 'opera':
      case 'opera mobile':
        result = this.majorVersion < 47;
        break;
      case 'firefox':
      case 'firefox mobile':
        result = this.majorVersion < 62;
        break;
      case 'android':
        result = this.majorVersion < 6;
        break;
      case 'ios':
        result = this.majorVersion < 11;
        break;
      case 'safari':
        result = this.majorVersion < 11;
        break;
      case 'windows':
        result = this.majorVersion < 10;
        break;
      default:
        result = true;
        break;
    }

    return result;
  }

  private checkOutdatedBrowser(): boolean {
    if (!this.browserName) {
      return true;
    }

    let result: boolean;
    const brName: string = this.browserName.toLowerCase();
    switch (brName) {
      case 'ie':
        result = this.majorVersion < 11;
        break;
      case 'firefox':
        result = this.majorVersion < 62;
        break;
      case 'safari':
        result = this.majorVersion < 12;
        break;
      case 'chrome':
        result = this.majorVersion < 69;
        break;
      case 'edge':
        result = this.majorVersion < 42;
        break;
      default:
        result = true;
        break;
    }

    return result;
  }

  /* tslint:disable:no-conditional-assignment */
  private getBrowserData(navigator: Navigator): any {
    this.userAgent = navigator.userAgent;
    this.browserName = navigator.appName;
    this.fullVersion = '' + parseFloat(navigator.appVersion);
    this.majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset: number;
    let ix: number;
    let verOffset: number;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = this.userAgent.indexOf('Opera')) !== -1) {
      this.browserName = 'Opera';
      this.fullVersion = this.userAgent.substring(verOffset + 6);
      if ((verOffset = this.userAgent.indexOf('Version')) !== -1) {
        this.fullVersion = this.userAgent.substring(verOffset + 8);
      }
    } else if ((verOffset = this.userAgent.indexOf('OPR')) !== -1) {
      this.browserName = 'Opera';
      this.fullVersion = this.userAgent.substring(verOffset + 4);
      if ((verOffset = this.userAgent.indexOf('Version')) !== -1) {
        this.fullVersion = this.userAgent.substring(verOffset + 12);
      }
    } else if ((verOffset = this.userAgent.indexOf('Firefox')) !== -1) {
      // In Firefox, the true version is after "Firefox"
      this.browserName = 'Firefox';
      this.fullVersion = this.userAgent.substring(verOffset + 8);
    } else if ((verOffset = this.userAgent.indexOf('MSIE')) !== -1) {
      // In MSIE, the true version is after "MSIE" in userAgent
      this.browserName = 'ie';
      this.fullVersion = this.userAgent.substring(verOffset + 5);
    } else if (/Trident\/7\./.test(navigator.userAgent)) {
      this.browserName = 'ie';
      this.fullVersion = '11';
      this.majorVersion = 11;
    } else if (
      (verOffset = this.userAgent.indexOf('Chrome')) !== -1 &&
      this.userAgent.indexOf('OPR') === -1 &&
      this.userAgent.indexOf('Vivaldi') === -1
    ) {
      // In Chrome, the true version is after "Chrome"
      /* Condition table :
              Chrome Browser
            | must contain      |  must not contain
            ________________________________________
            | "Chrome"          |  "OPR"
            |                   |  "Vivaldi"
            */
      this.browserName = 'Chrome';
      this.fullVersion = this.userAgent.substring(verOffset + 7);
    } else if (
      (verOffset = this.userAgent.indexOf('Safari')) !== -1 &&
      this.userAgent.indexOf('OPR') === -1 &&
      this.userAgent.indexOf('Vivaldi') === -1
    ) {
      // In Safari, the true version is after "Safari" or after "Version"
      /* Condition table :
                Safari Browser
            | must contain      |  must not contain
            ________________________________________
            | "Safari"          |  "OPR"
            |                   |  "Vivaldi"
            */
      if (this.userAgent.indexOf('CriOS') > -1) {
        this.browserName = 'Chrome';
        verOffset = this.userAgent.indexOf('CriOS');
        this.fullVersion = this.userAgent.substring(verOffset + 6);
      } else {
        this.browserName = 'Safari';
        this.fullVersion = this.userAgent.substring(verOffset + 7);
        if ((verOffset = this.userAgent.indexOf('Version')) !== -1) {
          this.fullVersion = this.userAgent.substring(verOffset + 8);
        }

        if (this.userAgent.indexOf('like Mac OS X') !== -1) {
          // In IOS, the true version is after "CPU OS "
          verOffset = this.userAgent.indexOf('OS');
          this.fullVersion = this.userAgent.substring(verOffset + 3);
          this.fullVersion = this.fullVersion.replace('_', '.');
        }
      }
    } else if ((nameOffset = this.userAgent.lastIndexOf(' ') + 1) < (verOffset = this.userAgent.lastIndexOf('/'))) {
      // In most other browsers, "name/version" is at the end of userAgent
      this.browserName = this.userAgent.substring(nameOffset, verOffset);
      this.fullVersion = this.userAgent.substring(verOffset + 1);
      if (this.browserName.toLowerCase() === this.browserName.toUpperCase()) {
        this.browserName = navigator.appName;
      }
    }

    if (/Edge/i.test(navigator.userAgent)) {
      // This is Microsoft Edge
      this.browserName = 'Edge';
    }

    // Trim the fullVersion string at semicolon/space if present
    if ((ix = this.fullVersion.indexOf(';')) !== -1) {
      this.fullVersion = this.fullVersion.substring(0, ix);
    }

    if ((ix = this.fullVersion.indexOf(' ')) !== -1) {
      this.fullVersion = this.fullVersion.substring(0, ix);
    }

    this.majorVersion = parseInt('' + this.fullVersion, 10);
    if (isNaN(this.majorVersion)) {
      this.fullVersion = '' + parseFloat(navigator.appVersion);
      this.majorVersion = parseInt(navigator.appVersion, 10);
    }
  }
  /* tslint:enable:no-conditional-assignment */
}
