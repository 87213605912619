import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IUserEmploymentTemplate } from '../../../../services/api/api-model';

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html'
})
export class TemplateListComponent {
  @Input() public templates: IUserEmploymentTemplate[];

  private currentEditmode = false;
  @Input()
  public get isEditMode(): boolean {
    return this.currentEditmode;
  }
  public set isEditMode(value: boolean) {
    if (this.templates && this.templates.length > 0) {
      if (value) {
        this.templates.forEach((model: any) => {
          model.isActiveFeild = model.Id === this.selectedId ? 'activeCell' : '';
        });
      } else {
        this.templates.forEach((model: any) => {
          model.isActiveFeild = '';
        });
      }

      this.refreshList = true;
    }
    this.currentEditmode = value;
  }

  private selectedIdValue: number;
  @Input()
  public get selectedId(): number {
    return this.selectedIdValue;
  }
  public set selectedId(value: number) {
    if (value && this.selectedIdValue !== value) {
      if (this.isEditMode) {
        this.scrollToNewRow(value);
      }

      this.selectedIdValue = value;
      this.selectedIdChange.emit(value);
    }
  }

  @Output() public selectedIdChange: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('templateList', { static: false }) public templateList: ElementRef;

  @Input() public get refreshList(): boolean {
    return this.refreshListValue;
  }
  public set refreshList(value: boolean) {
    if (value) {
      this.triggerGridRefresh = true;
    }
  }

  public triggerGridRefresh: boolean;
  private refreshListValue: boolean;

  public onSelectionChange(template: IUserEmploymentTemplate): void {
    this.selectedIdChange.emit(template ? template.Id : undefined);
  }

  public get classDisableSelect(): string {
    if (this.isEditMode) {
      return 'disableSelect';
    }
    return 'GridSelect';
  }

  public scrollToNewRow(selectTemplateId: number): void {
    setTimeout(() => {
      let count = 0;
      let check = false;
      this.templates.forEach((item: any) => {
        item.Id !== selectTemplateId && !check ? count++ : (check = true);
        item.isActiveFeild = item.Id === this.selectedId ? 'activeCell' : '';
      });
      if (this.templateList && (this.templateList as any).kendoGrid) {
        const element = (this.templateList as any).kendoGrid.wrapper.nativeElement.getElementsByTagName('tr')[
          count + 1
        ];
        if (element) {
          element.scrollIntoView(false);
        }
      }
    });
  }
}
