<div class="Card-inner">
  <legend class="Card-title salary-type-card-title">
    <span>
      {{ 'Company.SalaryTypes' | translate }}
    </span>

    <app-combobox-edit
      [label]="'EmployeeGeneral.Department' | translate"
      [editMode]="true"
      [idField]="'Id'"
      [textField]="'Name'"
      [comboboxDataSource]="integrationsService.department | async"
      [(value)]="integrationsService.departmentId"
      [keepDataSourceOrder]="true"
      [nullValueLabel]="'DropdownList.None' | translate"
    >
    </app-combobox-edit>
  </legend>

  <div>
    <div class="Card">
      <div class="Card--large">
        <app-grid
          [editMode]="!IsReadOnly"
          [(data)]="gridData"
          class="Company-salaryType"
          (saveChangesEvent)="onSave($event)"
          [filterable]="'menu'"
          [filter]="filter"
          (filterChange)="onFilterChange($event)"
          (cellClick)="onCellClick($event)"
          [(triggerUpdate)]="triggerUpdate"
          [idColumn]="'SalaryTypeId'"
          (iconControlClick)="onAddonClickEvent()"
        >
          <app-grid-column
            [isFilterable]="true"
            [field]="'Name'"
            [viewClass]="'truncated'"
            [editable]="false"
            [type]="'text'"
            [width]="250"
            [title]="'CompanyGeneral.SalaryType' | translate"
            [IconFieldString]="'IconField'"
            [tooltipField]="'Name'"
            [classField]="'classAddOn'"
          ></app-grid-column>
          <app-grid-column
            [isFilterable]="true"
            [field]="'Description'"
            [viewClass]="'truncated'"
            [editable]="false"
            [width]="descriptionFeildWidth"
            [type]="'text'"
            [title]="'CompanySalaryTypes.Description' | translate"
            [tooltipField]="'Description'"
          ></app-grid-column>
          <app-grid-column
            [isFilterable]="true"
            [editableField]="'Editable'"
            [field]="'ExternalReference'"
            [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
            [type]="'autocomplete'"
            [comboboxDataSource]="externalReferences"
            [comboboxDisplayField]="'FriendlyName'"
            [width]="150"
            [title]="'CompanyGeneral.SalaryTypeERPref' | translate"
            [autocompleteby1value]="true"
          >
          </app-grid-column>

          <app-grid-column
            *ngIf="!integrationsService.departmentId"
            [isFilterable]="true"
            [width]="150"
            [type]="'text'"
            [field]="'ImportExternalReference'"
            [title]="'CompanySalaryTypes.ImportExternalReference' | translate"
            [headerTooltip]="'CompanySalaryTypes.ImportExternalReferenceHeaderTooltip' | translate"
            [tooltipField]="'ImportExternalReference'"
          >
          </app-grid-column>
        </app-grid>
      </div>
    </div>
  </div>
  <app-dialog (action)="onActivateModuleDialogAction($event)" [(visible)]="activateModuleDialogVisible" [width]="800">
    <div *ngIf="isFullAdmin" [innerHTML]="'AccountModules.DisabledModuleAdmin' | translate: translationParams"></div>
    <div
      *ngIf="!isFullAdmin"
      [innerHTML]="'AccountModules.DisabledModuleEmployee' | translate: { SystemAlias: branding.SystemAlias }"
    ></div>
    <app-dialog-action *ngIf="isFullAdmin" [type]="'Cancel'"></app-dialog-action>
    <app-dialog-action *ngIf="!isFullAdmin" [type]="'Ok'"></app-dialog-action>
    <app-dialog-action
      *ngIf="isFullAdmin"
      [action]="'ViewPackages'"
      [label]="'AccountModules.ViewPackages' | translate"
      [class]="'Button--primary'"
    ></app-dialog-action>
    <app-dialog-action
      *ngIf="isFullAdmin"
      [action]="'ActivateModule'"
      [label]="'AccountModules.ActivateModule' | translate"
      [class]="'Button--destructive'"
    ></app-dialog-action>
  </app-dialog>
  <app-dialog class="blue-dialog" (action)="reloadPage()" [(visible)]="moduleActivatedDialogVisible">
    <div *ngIf="isFullAdmin" [innerHTML]="moduleActivatedDialogMessage | translate"></div>
    <app-dialog-action *ngIf="isFullAdmin" [type]="'Ok'"></app-dialog-action>
  </app-dialog>
</div>
