import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISalaryBatchValidationIssue, ISalaryBatchView } from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';

@Component({
  selector: 'app-salary-batch-other-error-grid',
  templateUrl: './salary-batch-other-error-grid.component.html',
  styleUrls: ['./salary-batch-other-error-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SalaryBatchOtherErrorGridComponent {
  private visibleValue: boolean;
  private validationRecordValue: any[] = [];

  @Input()
  public get validationRecord(): any[] {
    return this.validationRecordValue;
  }

  public set validationRecord(data: any[]) {
    this.validationRecordValue = data;
    this.gridData = this.validationRecord;
  }

  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;
    }
  }

  public gridData: Array<{ icon?: string; tooltip?: string; description?: string; employeeName?: string }> = [];
  public filter: CompositeFilterDescriptor;

  constructor() {}

  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.gridData = filterBy(this.validationRecord, filter);
  }
}
