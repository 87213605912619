import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Constants } from '../../common/constants';
import { FormComponentBase } from '../../common/form-component-base';
import { Global } from '../../common/global';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { VacationModel } from './vacation-model';
var VacationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(VacationComponent, _super);
    function VacationComponent(staticDataService, sessionService, dataService) {
        var _this = _super.call(this) || this;
        _this.staticDataService = staticDataService;
        _this.sessionService = sessionService;
        _this.dataService = dataService;
        _this.ownCompanyKey = 'Self';
        _this.NumericOptions = { format: 'n2', decimals: 2, step: 1, min: null, spinners: false };
        _this.vacationProviderWarningDialogVisible = false;
        _this.vacationProviderChangeWarningVisible = false;
        _this.omitVacationReportingLink = 'https://skat.dk/skat.aspx?oid=2287087&chk=216867';
        _this.vacationExtraPercentageTypeCheckboxValue = false;
        _this.childIsValid = Array(13).fill(true);
        _this.childIsDirty = Array(13).fill(false);
        return _this;
    }
    Object.defineProperty(VacationComponent.prototype, "model", {
        get: function () {
            if (!this.modelValue) {
                this.modelValue = {};
            }
            if (this.modelValue.AdditionalVacationTerminationMethod === null ||
                this.modelValue.AdditionalVacationTerminationMethod === undefined) {
                this.modelValue.AdditionalVacationTerminationMethod = 1;
            }
            return this.modelValue;
        },
        set: function (value) {
            this.modelValue = value;
            this.vacationExtraPercentageTypeCheckbox =
                this.isVisibleVacationExtraPercentageCheckbox && this.model.VacationExtraPercentage === 0.12 ? true : false;
            this.childIsValid = Array(13).fill(true);
            this.childIsDirty = Array(13).fill(false);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationComponent.prototype, "vacationExtraPercentageTypeCheckbox", {
        get: function () {
            return this.vacationExtraPercentageTypeCheckboxValue;
        },
        set: function (value) {
            this.vacationExtraPercentageTypeCheckboxValue = value;
            if (this.isVisibleVacationExtraPercentageCheckbox) {
                this.model.VacationExtraPercentage = value ? 0.12 : null;
            }
        },
        enumerable: true,
        configurable: true
    });
    VacationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.VacationProvider.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.vacationProviders = data); });
        if (this.isDenmarkCompany) {
            this.staticDataService.VacationSupplementPayoutMethodDK.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
                _this.months = data;
            });
        }
        else {
            this.staticDataService.Month.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
                var months = [];
                var april = data ? data.find(function (month) { return month.Id === 4; }) : undefined;
                var may = data ? data.find(function (month) { return month.Id === 5; }) : undefined;
                months[0] = april;
                months[1] = may;
                _this.months = months;
            });
        }
        if (this.isSwedishCompany) {
            this.staticDataService.VacationTypeSE.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.vacationTypes = data); });
        }
        else {
            this.staticDataService.VacationType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.vacationTypes = data); });
            this.staticDataService.AdditionalVacations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.additionalVacations = data); });
        }
        this.editModeChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (editMode) {
            if (editMode) {
                _this.originalVal = _this.model.VacationProviderId;
            }
        });
        this.isDirtyChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (dirty) {
            if (!dirty) {
                _this.model.VacationProviderId = _this.originalVal;
            }
        });
    };
    Object.defineProperty(VacationComponent.prototype, "IsNoneVacation", {
        get: function () {
            return !this.model || this.model.VacationTypeId < 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationComponent.prototype, "IsPaidVacation", {
        get: function () {
            return this.model && this.model.VacationTypeId === 1 && !this.isSwedishCompany;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationComponent.prototype, "IsPaidVacation_Denmark", {
        get: function () {
            return this.model && this.model.VacationTypeId === 1 && this.isDenmarkCompany;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationComponent.prototype, "IsExtraVacation", {
        get: function () {
            return this.model && this.model.VacationTypeId === 2 && this.isSwedishCompany;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationComponent.prototype, "IsPaidVacation_GreendLand", {
        get: function () {
            if (this.IsGreenlandCompany && this.model.VacationTypeId !== -1) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationComponent.prototype, "IsPaidVacation_Swedish", {
        get: function () {
            if (this.isSwedishCompany && this.model.VacationTypeId === 1) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationComponent.prototype, "isVisibleVacationExtraPercentageCheckbox", {
        get: function () {
            return this.isDenmarkCompany && this.model && this.model.VacationTypeId === 2;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationComponent.prototype, "VacationSupplementPayoutMonthLabel", {
        get: function () {
            if (this.isDenmarkCompany) {
                return 'EmployeeEmployment.AnnualSupplementPaidOutInDK';
            }
            return 'EmployeeEmployment.AnnualSupplementPaidOutIn';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationComponent.prototype, "IsVacationReduceSalaryDisable", {
        get: function () {
            return !this.model || !this.model.VacationTypeId || this.model.VacationTypeId === 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationComponent.prototype, "isDenmarkCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationComponent.prototype, "isSwedishCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationComponent.prototype, "VacationExtraPercentageLabel", {
        get: function () {
            if (this.IsGreenlandCompany) {
                return 'EmployeeEmployment.EarningPercentage';
            }
            else if (this.isSwedishCompany) {
                return 'EmployeeEmployment.VacationSupplement';
            }
            return 'EmployeeEmployment.AnnualSupplement';
        },
        enumerable: true,
        configurable: true
    });
    VacationComponent.prototype.onComboboxVacationTypeChangedEvent = function () {
        this.updateDefaultValueForPaidVacationType();
    };
    Object.defineProperty(VacationComponent.prototype, "isUseContinuousVacationReportingVisible", {
        get: function () {
            return (this.IsPaidVacation &&
                this.isDenmarkCompany &&
                this.sessionService.currentState.includes('tabs.employee') &&
                !this.isSwedishCompany);
        },
        enumerable: true,
        configurable: true
    });
    VacationComponent.prototype.updateDefaultValueForPaidVacationType = function () {
        if (this.model && this.model.VacationTypeId === 1) {
            // Paid vacation type.
            this.model.VacationReduceSalary = true;
            this.model.VacationSupplementPayoutMonth = this.model.VacationSupplementPayoutMonth
                ? this.model.VacationSupplementPayoutMonth
                : 5; // 5 - May.
            this.model.VacationExtraPercentage = this.model.VacationExtraPercentage
                ? this.model.VacationExtraPercentage
                : 0.01; // 1 percent.
        }
        if (this.IsGreenlandCompany /*&& this.model && this.model.VacationTypeId === 2*/) {
            this.model.VacationExtraPercentage = 0.12;
        }
        this.onChange();
    };
    VacationComponent.prototype.onComboboxVacationProviderValueChange = function () {
        var _this = this;
        var vacationProvider = this.vacationProviders.find(function (model) { return model.Id === _this.model.VacationProviderId; });
        if (this.originalVal &&
            this.model.VacationProviderId !== undefined &&
            this.originalVal !== this.model.VacationProviderId) {
            this.vacationProviderChangeWarningVisible = true;
            if (vacationProvider && vacationProvider.Key === this.ownCompanyKey) {
                this.vacationProviderWarningDialogVisible = true;
            }
        }
        else if (vacationProvider && vacationProvider.Key === this.ownCompanyKey) {
            this.vacationProviderWarningDialogVisible = true;
        }
        else {
            this.originalVal = this.model.VacationProviderId;
            this.onChange();
        }
    };
    VacationComponent.prototype.onVacationProviderWarningDialogAction = function (action) {
        if (action === 'Proceed' || action === 'Understood') {
            this.originalVal = this.model.VacationProviderId;
        }
        else {
            this.model.VacationProviderId = this.originalVal;
        }
        this.onChange();
    };
    return VacationComponent;
}(FormComponentBase));
export { VacationComponent };
