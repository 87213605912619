import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../common/constants';
import { FormComponentBase } from '../../common/form-component-base';
import { Global } from '../../common/global';
import { DataService } from '../../services/api/data.service';
import { EmployeeService } from '../../services/employee.service';
import { SessionService } from '../../services/session/session.service';
var TaxCardComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TaxCardComponent, _super);
    function TaxCardComponent(dataService, sessionService, employeeService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.sessionService = sessionService;
        _this.employeeService = employeeService;
        _this.reloadEmploymentEvent = new EventEmitter();
        _this.annualTaxComment = new EventEmitter();
        _this.companyUserChange = new EventEmitter();
        _this.selfService = '';
        _this.isSelfService = false;
        _this.option = { format: 'n0' };
        _this.taxTableNumberDataSource = [];
        _this.TaxColumnNumberOption = {
            min: 1,
            max: 6,
            spinners: true,
            step: 1,
            decimals: 0,
            format: 'n0'
        };
        // IUserTaxInfo
        _this.taxCardHistory = [];
        _this.requestTaxCard = new EventEmitter();
        _this.viewTaxCardHistory = new EventEmitter();
        _this.requestTaxcardConfirm = new EventEmitter();
        _this.refreshTaxCardChange = new EventEmitter();
        _this.ngUnsubscribe = new Subject();
        _this.childIsValid = Array(9).fill(true);
        _this.childIsDirty = Array(9).fill(false);
        _this.employeeService.makeRequestTaxCard.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (value) {
            if (value) {
                _this.onRequestTaxCardClickEvent();
            }
        });
        return _this;
    }
    Object.defineProperty(TaxCardComponent.prototype, "companyUser", {
        get: function () {
            return this.companyUserValue;
        },
        set: function (value) {
            if (!this.companyUserValue && !value) {
                return;
            }
            this.companyUserValue = value;
            this.companyUserChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "userEmployment", {
        get: function () {
            if (this.userEmploymentValue === undefined) {
                this.userEmploymentValue = {};
            }
            return this.userEmploymentValue;
        },
        set: function (value) {
            this.userEmploymentValue = value;
            this.childIsValid = Array(9).fill(true);
            this.childIsDirty = Array(9).fill(false);
            if (this.isSwedenCompany && this.userEmploymentValue && this.userEmploymentValue.Id) {
                this.getTaxCardHistory();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "taxCard", {
        get: function () {
            if (!this.taxcardValue) {
                this.taxcardValue = { taxInfo: {} };
            }
            if (!this.taxcardValue.taxInfo) {
                this.taxcardValue.taxInfo = {};
            }
            return this.taxcardValue;
        },
        set: function (value) {
            if (value && value.taxInfo === undefined) {
                value.taxInfo = {};
            }
            if (value && value.userEmploymentId && JSON.stringify(this.taxcardValue) !== JSON.stringify(value)) {
                this.loadEmploymentTaxInfo(value.userEmploymentId);
            }
            this.taxcardValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "refreshTaxCard", {
        get: function () {
            return this.refreshTaxCardValue;
        },
        set: function (value) {
            if (this.refreshTaxCardValue !== value) {
                this.refreshTaxCardValue = false;
                this.refreshTaxCardChange.emit(false);
                if (value && this.taxCard && this.taxCard.userEmploymentId) {
                    this.loadEmploymentTaxInfo(this.taxCard.userEmploymentId);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "allowEdit", {
        get: function () {
            return (this.sessionService.role.IsSalaryAdminAndHigher && this.sessionService.currentState !== 'tabs.selfservice.general');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "hasEmployment", {
        get: function () {
            return this.taxCard && this.taxCard.userEmploymentId ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "hasSpecialTaxCardRule", {
        get: function () {
            return this.hasEmployment && this.taxCard && this.taxCard.specialTaxBrief;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "showTaxCardInfo", {
        get: function () {
            return this.hasEmployment && ((this.taxCard && !this.taxCard.specialTaxBrief) || !this.taxCard);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "showFreeAmount", {
        get: function () {
            return this.taxCard && this.taxCard.taxInfo && this.taxCard.taxInfo.TaxCardTypeId === 3;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "showDeductionPerMonth", {
        get: function () {
            return this.taxCard && this.taxCard.taxInfo && this.taxCard.taxInfo.TaxCardTypeId === 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "isAllowEditTaxCard", {
        get: function () {
            return this.allowEdit && this.hasEmployment;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "isTaxCardRequested", {
        get: function () {
            return this.taxcardValue && this.taxcardValue.taxCardRequestTime ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "isDanishCompany", {
        get: function () {
            return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.DENMARK_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "isSwedenCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "IsSelfServiceGenneral", {
        get: function () {
            return this.sessionService.currentState === 'tabs.selfservice.general';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "top8px", {
        get: function () {
            return this.sessionService.currentState === 'tabs.selfservice.general' && this.editMode ? 'Top8px' : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "isShowTaxCardJamkningExistsMessage", {
        get: function () {
            if (this.isSwedenCompany && this.taxCardHistory && this.taxCardHistory.length > 0) {
                return this.taxCardHistory.some(function (value) { return value.IsCurrent; });
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "isDanishCompanyAndInSelfServiceGenneral", {
        get: function () {
            if (this.isDanishCompany && this.IsSelfServiceGenneral) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "ViewTaxCardHistoryLabel", {
        get: function () {
            if (this.isSwedenCompany) {
                return 'EmployeeGeneral.PersonalTaxRate';
            }
            return 'EmployeeGeneral.ViewTaxCardHistory';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "ViewReduceTaxCardDeductionLabel", {
        get: function () {
            if (this.isSwedenCompany) {
                return 'EmployeeGeneral.ReduceTaxCardDeductionSweden';
            }
            return 'EmployeeGeneral.ReduceTaxCardDeduction';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaxCardComponent.prototype, "admin", {
        get: function () {
            if (Global.SESSION.CurrentRole.Key === 'FullAdmin' ||
                Global.SESSION.CurrentRole.Key === 'SalaryAdmin' ||
                this.sessionService.currentState === 'tabs.selfservice.general') {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    TaxCardComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    TaxCardComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.isSwedenCompany) {
            this.dataService.StaticData_GetSeTaxTables().subscribe(function (data) {
                _this.taxTableNumberDataSource = data;
            });
        }
    };
    TaxCardComponent.prototype.TaxColumnNumberChange = function (value) {
        var _this = this;
        setTimeout(function () {
            if (value || value === 0) {
                if (value < 1) {
                    _this.userEmployment.TaxColumnNumber = 1;
                }
                if (value > 6) {
                    _this.userEmployment.TaxColumnNumber = 6;
                }
            }
            _this.onControlChanged();
        });
    };
    TaxCardComponent.prototype.TaxColumnNumberBlur = function () {
        if (this.userEmployment.TaxColumnNumber === null || this.userEmployment.TaxColumnNumber === undefined) {
            this.userEmployment.TaxColumnNumber = 1;
            this.onControlChanged();
        }
    };
    TaxCardComponent.prototype.showRequestTaxCardDialog = function () {
        this.requestTaxCard.emit();
    };
    TaxCardComponent.prototype.onViewTaxCardHistoryButtonClickEvent = function () {
        if (this.hasSpecialTaxCardRule) {
            return;
        }
        this.viewTaxCardHistory.emit(this.taxCard.taxInfo);
    };
    TaxCardComponent.prototype.onRequestTaxCardClickEvent = function () {
        if (this.hasSpecialTaxCardRule) {
            return;
        }
        if (this.isTaxCardRequested) {
            this.requestTaxcardConfirm.emit(this.taxCard.taxInfo);
        }
        else {
            this.showRequestTaxCardDialog();
        }
    };
    TaxCardComponent.prototype.reloadTaxCard = function () {
        this.loadEmploymentTaxInfo(this.taxCard.userEmploymentId);
        this.onChange();
        this.isDirty = false;
        this.reloadEmploymentEvent.emit();
    };
    TaxCardComponent.prototype.loadEmploymentTaxInfo = function (userEmploymentId) {
        var _this = this;
        this.dataService.CompanyUsers_GetCurrentTaxInfo(userEmploymentId).subscribe(function (data) {
            _this.taxCard.taxInfo = data;
        });
    };
    TaxCardComponent.prototype.onControlChanged = function () {
        this.onChange();
    };
    TaxCardComponent.prototype.getTaxCardHistory = function () {
        var _this = this;
        this.dataService.Employments_GetTaxCards(this.userEmployment.Id).subscribe(function (data) {
            _this.taxCardHistory = data;
        });
    };
    return TaxCardComponent;
}(FormComponentBase));
export { TaxCardComponent };
