<form>
  <fieldset class="Form--horizontal">
    <legend class="Card-title">{{ 'EmployeeGeneral.BankAccount' | translate }}</legend>

    <app-text-edit
      [label]="'EmployeeGeneral.BankRegNo' | translate"
      [viewClass]="isDisableBankFeild ? 'u-strikeThrough' : ''"
      [editMode]="editMode"
      [disabled]="isDisableBankFeild"
      [(value)]="bankInfo.BankRegNo"
      [(isValid)]="childIsValid[0]"
      [(isDirty)]="childIsDirty[0]"
      (valueChange)="onChange()"
    ></app-text-edit>

    <app-text-edit
      [label]="'EmployeeGeneral.BankAccountNo' | translate"
      [viewClass]="isDisableBankFeild ? 'u-strikeThrough' : ''"
      [editMode]="editMode"
      [disabled]="isDisableBankFeild"
      [(isValid)]="childIsValid[1]"
      [(isDirty)]="childIsDirty[1]"
      [(value)]="bankInfo.BankAccountNo"
      (valueChange)="onChange()"
    ></app-text-edit>

    <app-module-check
      *ngIf="!isSelfService || (isSelfService && isAllowCompanyNemKontoPayments)"
      [moduleId]="13"
      [(hasModule)]="hasNemKontoEnabled"
    >
      <app-check-edit
        *ngIf="isAllowCompanyNemKontoPayments"
        [label]="'EmployeeGeneral.UseNemKonto' | translate"
        [disable]="!hasNemKontoEnabled"
        [editMode]="editMode"
        [(value)]="bankInfo.AutoDetermineBankAccount"
        [(isValid)]="childIsValid[2]"
        [(isDirty)]="childIsDirty[2]"
        (valueChange)="onChange()"
      ></app-check-edit>

      <app-check-edit
        *ngIf="!isAllowCompanyNemKontoPayments"
        [label]="'EmployeeGeneral.UseNemKonto' | translate"
        [disable]="true"
        [editMode]="false"
        [value]="false"
        [helpTooltip]="'CompanyGeneral.AllowNemKontoPaymentsDisbaleTooltip' | translate"
      ></app-check-edit>
    </app-module-check>

    <app-text-edit
      [label]="'EmployeeGeneral.IBAN' | translate"
      [editMode]="editMode"
      [(value)]="bankInfo.IbanNo"
      [(isValid)]="childIsValid[3]"
      [(isDirty)]="childIsDirty[3]"
      (valueChange)="onChange()"
    ></app-text-edit>

    <app-text-edit
      [label]="'EmployeeGeneral.BicSwift' | translate"
      [editMode]="editMode"
      [(value)]="bankInfo.BicSwiftNo"
      [(isValid)]="childIsValid[4]"
      [(isDirty)]="childIsDirty[4]"
      (valueChange)="onChange()"
    ></app-text-edit>

    <app-combobox-edit
      [label]="'EmployeeGeneral.PreferredCurrency' | translate"
      [editMode]="editMode"
      [textField]="'Name'"
      [idField]="'Id'"
      [nullValueLabel]="'DropdownList.None' | translate"
      [comboboxDataSource]="currencies"
      [(value)]="bankInfo.PreferredCurrencyId"
      [(isValid)]="childIsValid[5]"
      [(isDirty)]="childIsDirty[5]"
      (valueChange)="onChange()"
    ></app-combobox-edit>
  </fieldset>
</form>
