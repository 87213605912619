import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GridCellClickEvent } from 'src/app/custom-controls/grid/cell-click-event';
import { CompanyAdvancedTypesService } from '../../company-advanced-types.service';

@Component({
  selector: 'app-advanced-types-list',
  styleUrls: ['./advanced-types-list.component.scss'],
  templateUrl: './advanced-types-list.component.html'
})
export class AdvancedTypesListComponent {
  @Input() public types: any;
  @Input() public type: string;
  @Input() public inheritFrom: string;

  private currentEditmode = false;
  @Input()
  public get isEditMode(): boolean {
    return this.currentEditmode;
  }
  public set isEditMode(value: boolean) {
    if (this.types && this.types.length > 0) {
      if (value) {
        this.types.forEach((model: any) => {
          model.isActiveFeild = model.Id === this.selectedId ? 'activeCell' : '';
        });
      } else {
        this.types.forEach((model: any) => {
          model.isActiveFeild = '';
        });
      }

      this.refreshList = true;
    }
    this.currentEditmode = value;
  }

  private selectedIdValue: number;
  @Input()
  public get selectedId(): number {
    return this.selectedIdValue;
  }
  public set selectedId(value: number) {
    if (value && this.selectedIdValue !== value) {
      if (this.isEditMode) {
        this.scrollToNewRow(value);
      }

      this.selectedIdValue = value;
    }
  }

  @ViewChild('advancedTypesList', { static: false }) public advancedTypesList: ElementRef;

  private refreshListValue: boolean;
  @Input() public get refreshList(): boolean {
    return this.refreshListValue;
  }
  public set refreshList(value: boolean) {
    if (value) {
      this.triggerGridRefresh = true;
    }
  }

  public triggerGridRefresh: boolean;
  public IconConfigurationIcon: string;
  public IconConfigurationIconTooltip: string;

  constructor(public companyAdvancedTypesService: CompanyAdvancedTypesService) {}

  public onSelectionChange(type: any): void {
    this.companyAdvancedTypesService.selectedType = type;
  }

  public get classDisableSelect(): string {
    if (this.isEditMode) {
      return 'disableSelect';
    }
    return 'GridSelect';
  }

  public scrollToNewRow(selectTemplateId: number): void {
    setTimeout(() => {
      let count = 0;
      let check = false;
      this.types.forEach((item: any) => {
        item.Id !== selectTemplateId && !check ? count++ : (check = true);
        item.isActiveFeild = item.Id === this.selectedId ? 'activeCell' : '';
      });
      if (this.advancedTypesList && (this.advancedTypesList as any).kendoGrid) {
        const element = (this.advancedTypesList as any).kendoGrid.wrapper.nativeElement.getElementsByTagName('tr')[
          count + 1
        ];
        if (element) {
          element.scrollIntoView(false);
        }
      }
    });
  }

  @Output() public showTranslationDialog: EventEmitter<any> = new EventEmitter<any>();
  public onCellClick(event: GridCellClickEvent): void {
    if (event.field === 'Name' && this.companyAdvancedTypesService.isLanguageModuleEnable && event.isClickToIcon) {
      this.showTranslationDialog.emit();
    }
  }

  public onSaveName() {
    if (!this.companyAdvancedTypesService.isLanguageModuleEnable) {
      this.companyAdvancedTypesService.tabDirty = true;
    }
  }
}
