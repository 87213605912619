<div class="company-salarybatch">
  <app-fixed-panel [excludedComponents]="listClassExcluded" [specificClassName]="'Island-salarybatch'">
    <div *ngIf="!companySalaryBatchService.IsMobileOrHybridApp" class="companySalaryBatches Island Island-salarybatch">
      <div class="Cards Cards--flex">
        <div class="Card Card--large Card--flex-column Card--large-left" id="Card-Leftside">
          <app-salary-batches
            [salaryBatches]="companySalaryBatchService.salaryBatches | async"
            [salaryCycles]="companySalaryBatchService.salaryCycles | async"
            (recalculateDraft)="onRecalculate()"
            (newSalaryBatch)="showNewSalaryBatchDialog()"
            (editSalaryBatch)="onEditSalaryBatch($event)"
            (showErrors)="showErrorModal($event)"
            (finalizeDraft)="onFinalize()"
            (submitForApproval)="onLockSalaryBatch()"
            id="Card-splitter1Y"
          >
          </app-salary-batches>
          <div id="splitterY"></div>
          <app-salary-batch-payslips
            id="Card-splitter2Y"
            [selectedSalaryBatch]="companySalaryBatchService.selectedSalaryBatch | async"
            (revertBatchCompleted)="onRevertBatchCompleted()"
            [salaryStatements]="companySalaryBatchService.salaryStatements | async"
          >
          </app-salary-batch-payslips>
        </div>

        <div class="Card Card--large" id="Card-Rightside">
          <app-salary-batch-salary-totals></app-salary-batch-salary-totals>
        </div>
      </div>
    </div>
    <app-loading-tips [visible]="isWaitingReCalculate" [tiptext]="loadingText"></app-loading-tips>

    <div *ngIf="companySalaryBatchService.IsMobileOrHybridApp" class="Island Island-salarybatch tabs">
      <app-combobox-edit
        *ngIf="companySalaryBatchService.isExitSalaryCycles"
        class="Company-batchesCycles"
        [label]="'CompanySalaryBatches.Cycle' | translate"
        [editMode]="true"
        [idField]="'Id'"
        [textField]="'Name'"
        [comboboxDataSource]="companySalaryBatchService.salaryCycles | async"
        [(value)]="salaryCycleId"
        [nullValueLabel]="'CompanySalaryBatches.SalaryCycle_Item_All_Text' | translate"
      ></app-combobox-edit>
      <div class="u-trailer"></div>

      <!-- Salary batch combobobox -->
      <app-combobox-edit
        [disable]="salaryBatchesDataSource && salaryBatchesDataSource.length === 1"
        [label]="'CompanySalaryBatches.Batches' | translate"
        [resetDropdownOnNullDS]="true"
        [editMode]="true"
        [idField]="'Id'"
        [textField]="'TitleDescription'"
        [comboboxDataSource]="salaryBatchesDataSource"
        [(value)]="selectedComboboxSalaryBatchId"
      ></app-combobox-edit>
      <div class="u-trailer"></div>

      <kendo-tabstrip #salaryBatchTab (tabSelect)="onSalaryBatchTabSelected($event)">
        <kendo-tabstrip-tab [title]="'CompanySalaryBatches.Basic' | translate" [selected]="true">
          <ng-template kendoTabContent>
            <app-salary-batch-info
              [popup]="false"
              [visible]="true"
              [salaryBatch]="basicSalaryBatch"
              [salaryCycles]="companySalaryBatchService.salaryCycles | async"
              [(selectedEmployeeIds)]="companySalaryBatchService.allCurrentSelectSalaryStatements"
              (createPayrollBatch)="showNewSalaryBatchDialog()"
              (salaryBatchAction)="onSalaryBatchDialogAction($event)"
              (reloadSalaryBatches)="getSalaryBatches()"
              (closeDialog)="closeDialog()"
              [(reloadEntityContext)]="reloadEntityContext"
            ></app-salary-batch-info>
          </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab *ngIf="currentBatchHasError" [title]="'CompanySalaryBatches.Error' | translate">
          <ng-template kendoTabContent>
            <app-salary-batch-errors-dialog
              [visible]="true"
              (resendBatch)="resendBatch($event)"
              (refreshSource)="getSalaryBatches()"
            ></app-salary-batch-errors-dialog>
          </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [title]="'CompanySalaryBatches.Payslips' | translate">
          <ng-template kendoTabContent>
            <app-salary-batch-payslips
              [selectedSalaryBatch]="companySalaryBatchService.selectedSalaryBatch | async"
              (revertBatchCompleted)="onRevertBatchCompleted()"
              [salaryStatements]="companySalaryBatchService.salaryStatements | async"
            >
            </app-salary-batch-payslips>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'CompanySalaryBatches.Totals' | translate">
          <ng-template kendoTabContent>
            <app-salary-batch-salary-totals [showTitle]="false"></app-salary-batch-salary-totals>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </div>
  </app-fixed-panel>
  <div class="Actions" *ngIf="!companySalaryBatchService.IsMobileOrHybridApp">
    <app-menu-button
      *ngIf="sessionService.role.IsSalaryAdminAndHigher"
      [menuCondition]="true"
      (menuClick)="showNewSalaryBatchDialog()"
      [menuIcon]="'Add'"
      [menuLabel]="'Company.New'"
      [menuTooltip]="'CompanySalaryBatches.SalaryBatchAddNewButton'"
      [actionButtonMenu]="true"
      [menuButtonClass]="'Action-button Action-buttonAdd'"
      [menuButtonId]="'ActionButtonAdd'"
    >
    </app-menu-button>
    <app-menu-button
      *ngIf="reportDialogService.isVisibleReportIcon"
      [menuCondition]="true"
      (menuClick)="prepareReportDialogParams()"
      [menuIcon]="'File'"
      [menuLabel]="'Company.Reports'"
      [menuTooltip]="'Company.Reports'"
      [actionButtonMenu]="true"
      [menuButtonClass]="'Action-button Action-buttonReports'"
      [menuButtonId]="'ActionButtonReports'"
    >
    </app-menu-button>

    <app-menu-button
      *ngIf="sessionService.role.IsSalaryAdminAndHigher"
      (menuClick)="onMoreMenuOptionClick($event)"
      [menuIcon]="'ThreeDots'"
      [menuLabel]="'More.Title'"
      [menuTooltip]="'More.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item
        *ngIf="isShowApprovalButtonNonScreenCheck"
        (menuItemClick)="onMoreMenuOptionClick($event)"
        [field]="'Recalculate'"
      >
      </app-menu-button-item>
      <app-menu-button-item
        *ngIf="!IsReadOnly"
        (menuItemClick)="onMoreMenuOptionClick($event)"
        [field]="'SalaryImportData'"
      >
      </app-menu-button-item>
    </app-menu-button>
    <app-menu-button
      [menuIcon]="'Help'"
      [menuLabel]="'Help.Title'"
      [menuTooltip]="'Help.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
      <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
    </app-menu-button>
  </div>

  <div class="Actions" *ngIf="companySalaryBatchService.IsMobileOrHybridApp">
    <app-menu-button
      *ngIf="sessionService.role.IsSalaryAdminAndHigher"
      (menuClick)="onMoreMenuOptionClick($event)"
      [menuIcon]="'ThreeDots'"
      [menuLabel]="'More.Title'"
      [menuTooltip]="'More.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item (menuItemClick)="onMoreMenuOptionClick($event)" [field]="'SalaryBatchAddNew'">
      </app-menu-button-item>
      <app-menu-button-item
        *ngIf="isShowApprovalButtonNonScreenCheck"
        (menuItemClick)="onMoreMenuOptionClick($event)"
        [field]="'Recalculate'"
      >
      </app-menu-button-item>
      <app-menu-button-item
        *ngIf="!IsReadOnly"
        (menuItemClick)="onMoreMenuOptionClick($event)"
        [field]="'SalaryImportData'"
      >
      </app-menu-button-item>
      <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
      <app-menu-button-item [field]="'welcomeMessage'"></app-menu-button-item>
    </app-menu-button>
  </div>
</div>

<!-- New salary batch dialog -->
<app-salary-batch-info
  [popup]="true"
  [(visible)]="salaryBatchDialogVisible"
  [salaryBatch]="childWindowSalaryBatch"
  [salaryCycles]="companySalaryBatchService.salaryCycles | async"
  [(selectedEmployeeIds)]="companySalaryBatchService.allCurrentSelectSalaryStatements"
  (salaryBatchAction)="onSalaryBatchDialogAction($event)"
  (reloadSalaryBatches)="getSalaryBatches()"
  (closeDialog)="closeDialog()"
  [(reloadEntityContext)]="reloadEntityContext"
></app-salary-batch-info>
<!-- /New salary batch dialog -->

<app-dialog
  *ngIf="confirmPasswordDialogVisible"
  (action)="onConfirmPasswordDialogAction($event)"
  [overlayClose]="false"
  [(visible)]="confirmPasswordDialogVisible"
>
  <div class="FormElement">
    <label class="FormElement-label">{{ 'Finalize.Warnings' | translate }}</label>
  </div>
  <div>
    <app-salary-batch-other-error-grid
      [visible]="confirmPasswordDialogVisible"
      [validationRecord]="companySalaryBatchService.salaryBatchErrorsView | async"
    ></app-salary-batch-other-error-grid>
  </div>

  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'AcceptWarnings'"></app-dialog-action>
</app-dialog>

<app-dialog class="blue-dialog" [(visible)]="alertDialogVisible" (action)="afterFinalizeError()">
  <div
    *ngIf="alertDialogType === 'noBatchSelectedWarning'"
    [innerHTML]="'CompanySalaryBatches.NoSalaryBatchSelectedWarning' | translate"
  ></div>
  <div
    *ngIf="alertDialogType === 'denyFinalizeAdminText'"
    [innerHTML]="'CompanySalaryBatches.DenyFinalize_AdminText' | translate"
  ></div>
  <div
    *ngIf="alertDialogType === 'denyFinalizeText'"
    [innerHTML]="'CompanySalaryBatches.DenyFinalize_Text' | translate"
  ></div>
  <div
    *ngIf="alertDialogType === 'finalizeErrorText'"
    [innerHTML]="'CompanySalaryBatches.FinalizeErrorText' | translate"
  ></div>
  <div
    *ngIf="alertDialogType === 'invalidDateMessage'"
    [innerHTML]="'CompanySalaryBatches.InvalidPaymentDate' | translate"
  ></div>
  <div
    *ngIf="alertDialogType === 'resendBatchCompletedMessage'"
    [innerHTML]="'CompanySalaryBatches.ResendBatchCompleteMessage' | translate"
  ></div>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onConfirmDeleteDialogAction($event)"
  [(visible)]="confirmDeleteDialogVisible"
  [leadingText]="'CompanySalaryBatches.deletingConfirmMessage' | translate"
>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Delete'" [disabled]="sessionService.isSubmitting"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onConfirmRevertLockDialogAction($event)"
  [(visible)]="confirmRevertLockDialogVisible"
  [leadingText]="'CompanySalaryBatches.RevertToDraftConfirm' | translate"
>
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onConfirmRevertLockDialogAction($event)"
  [(visible)]="confirmRejectDialogVisible"
  [leadingText]="'CompanySalaryBatches.RejectToDraftConfirm' | translate"
>
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onEmptySalaryBatchDialogAction($event)"
  [(visible)]="emptySalaryBatchDialogVisible"
  [leadingText]="'CompanySalaryBatches.EmptySalaryBatchMessage' | translate"
>
  <app-dialog-action [type]="'No'"></app-dialog-action>
  <app-dialog-action [type]="'Yes'"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onChooseResendTargetDialogAction($event)"
  [(visible)]="chooseResendTargetDialogVisible"
  [leadingText]="'CompanySalaryBatches.ResendBatch' | translate"
>
  <app-dialog-action [action]="'eincome'" [label]="'CompanySalaryBatches.ResendEIncome' | translate">
  </app-dialog-action>
  <app-dialog-action
    *ngIf="companySalaryBatchService.hasIntegrationName"
    [action]="'external'"
    [label]="
      'CompanySalaryBatches.ResendExternalSystem'
        | translate: { ExtSystemName: companySalaryBatchService.integrationName }
    "
  ></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onResendBatchToEIncomeDialogAction($event)"
  [(visible)]="resendBatchToEIncomeDialogVisible"
  [leadingText]="'CompanySalaryBatches.ResendBatchMessage' | translate"
>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Resend'"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onResendBatchToIntegrationDialogAction($event)"
  [(visible)]="resendBatchToIntegrationConfirmationDialogVisible"
  [leadingText]="
    'CompanySalaryBatches.ResendExternalSystem'
      | translate: { ExtSystemName: companySalaryBatchService.integrationName }
  "
>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Resend'"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  (action)="closeDialog()"
  [(visible)]="resendBatchExtCompletedDialogVisible"
  [leadingText]="
    'CompanySalaryBatches.ResendBatchToExtSystemCompletedMessage'
      | translate: { ExtSystemName: companySalaryBatchService.integrationName }
  "
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-lock-salary-batch-dialog [(visible)]="lockSalaryBatchDialogVisible" (batchLocked)="reloadSalaryBatches()">
</app-lock-salary-batch-dialog>

<app-salary-batch-errors-dialog
  [isTab]="false"
  [(visible)]="errorListDetailsDialogVisible"
  (resendBatch)="resendBatch($event)"
  (refreshSource)="getSalaryBatches()"
>
</app-salary-batch-errors-dialog>

<app-batch-finalization-step-2-dialog
  [(visible)]="salaryBatchFinalizationStep1Completed"
  [isClose]="salaryBatchFinalizationCloseAll"
  (stepCompletedChange)="onsalaryBatchFinalizationStep2Completed()"
  (finalizationExit)="closeFinalizationWizardDialogs()"
>
</app-batch-finalization-step-2-dialog>
<!--StatusId: 10 => "Draft"-->
<app-batch-finalization-step-3-dialog
  [(visible)]="salaryBatchFinalizationStep2Completed"
  [isClose]="salaryBatchFinalizationCloseAll"
  [(doRecalculation)]="salaryBatchFinalizationStep3RecalculationConfirm"
  (stepCompletedChange)="onsalaryBatchFinalizationStep3Completed()"
  (finalizationExit)="closeFinalizationWizardDialogs()"
>
</app-batch-finalization-step-3-dialog>

<app-batch-finalization-step-4-dialog
  [step1Completed]="salaryBatchFinalizationStep1Completed"
  [doRecalculation]="salaryBatchFinalizationStep3RecalculationConfirm"
  [(visible)]="salaryBatchFinalizationStep3Completed"
  (finalizationExit)="closeFinalizationWizardDialogs()"
  (finalizationDone)="onFinalizationProcessDone($event)"
>
</app-batch-finalization-step-4-dialog>

<app-revert-finalized-batch-dialog
  [(visible)]="showRevertSalaryBatchDialog"
  (revertBatchCompleted)="onRevertBatchCompleted()"
>
</app-revert-finalized-batch-dialog>

<!-- process Finaliza screen -->
<div class="FinalizationScreen" *ngIf="companySalaryBatchService.processFinalizationRun">
  <div class="centerFinalizationScreen">
    <b [innerHTML]="'CompanySalaryBatches.Finalizing' | translate"></b>

    <br />

    <div class="finalization-item-area">
      <!-- step 1 -->
      <app-salary-batch-item-finalization
        [title]="companySalaryBatchService.Step1FinalizeTitle"
        [start]="companySalaryBatchService.processFinalizationInfo['step1Finalization'].start"
        [complete]="companySalaryBatchService.processFinalizationInfo['step1Finalization'].complete"
        [error]="companySalaryBatchService.processFinalizationInfo['step1Finalization'].error"
      >
      </app-salary-batch-item-finalization>
      <div class="line-connecting"></div>
      <!-- step 1 -->

      <!-- step 2 -->
      <app-salary-batch-item-finalization
        [title]="'CompanySalaryBatches.FinalizeBatch' | translate"
        [start]="companySalaryBatchService.processFinalizationInfo['step2Finalization'].start"
        [complete]="companySalaryBatchService.processFinalizationInfo['step2Finalization'].complete"
        [error]="companySalaryBatchService.processFinalizationInfo['step2Finalization'].error"
      >
      </app-salary-batch-item-finalization>
      <div class="line-connecting"></div>
      <!-- step 2 -->

      <!-- step 3 -->
      <app-salary-batch-item-finalization
        *ngIf="companySalaryBatchService.integrationName"
        [title]="
          'CompanySalaryBatches.ExportToEconomic'
            | translate: { ExternalSystem: companySalaryBatchService.integrationName }
        "
        [start]="companySalaryBatchService.processFinalizationInfo['step3Finalization'].start"
        [complete]="companySalaryBatchService.processFinalizationInfo['step3Finalization'].complete"
        [error]="companySalaryBatchService.processFinalizationInfo['step3Finalization'].error"
      >
      </app-salary-batch-item-finalization>
      <div *ngIf="companySalaryBatchService.integrationName" class="line-connecting"></div>
      <!-- step 3 -->

      <!-- step 4 -->
      <app-salary-batch-item-finalization
        [title]="'CompanySalaryBatches.RecalculateOtherBatches' | translate"
        [start]="companySalaryBatchService.processFinalizationInfo['step4Finalization'].start"
        [complete]="companySalaryBatchService.processFinalizationInfo['step4Finalization'].complete"
        [error]="companySalaryBatchService.processFinalizationInfo['step4Finalization'].error"
      >
      </app-salary-batch-item-finalization>
      <div class="line-connecting"></div>
      <!-- step 4 -->

      <!-- step 5 -->
      <app-salary-batch-item-finalization
        [title]="'CompanySalaryBatches.CreateBatchForNewPeriod' | translate"
        [start]="companySalaryBatchService.processFinalizationInfo['step5Finalization'].start"
        [complete]="companySalaryBatchService.processFinalizationInfo['step5Finalization'].complete"
        [error]="companySalaryBatchService.processFinalizationInfo['step5Finalization'].error"
      >
      </app-salary-batch-item-finalization>
      <!-- step 5 -->
    </div>
  </div>
</div>
<!-- process Finaliza screen -->

<app-dialog
  class="blue-dialog"
  [(visible)]="noEmployeeActiveVisible"
  [leadingText]="'CompanySalaryBatches.NoActiveEmployee' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="onRecalculateAction($event)"
  [(visible)]="isShowWarningRecalculate"
  [leadingText]="'CompanySalaryBatches.WarningRecalculate' | translate: { SystemAlias: branding.SystemAlias }"
>
  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action [type]="'ForceRecalculate'"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="companySalaryBatchService.processStep3FinalizationFail"
  [leadingText]="companySalaryBatchService.Step3FinalizationFailText"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="companySalaryBatchService.processStep4FinalizationFail"
  [leadingText]="'CompanySalaryBatches.FinalizationStep4ErrorMassge' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="companySalaryBatchService.processStep5FinalizationFail"
  [leadingText]="'CompanySalaryBatches.FinalizationStep5ErrorMassge' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-loading-tips
  [visible]="isSalaryBatchCreating"
  [tiptext]="'CompanySalaryBatches.SalaryBatchAddNewButton' | translate"
></app-loading-tips>

<app-loading-tips
  [visible]="!isWaitingReCalculate && (sessionService.isGetting || sessionService.isSubmitting)"
  [tiptext]="'Tips.ChangeCompany' | translate"
></app-loading-tips>
