<div
  class="FormElement {{ customClass }} {{ addOnButtonEditClassFormControl }}"
  [ngClass]="!editMode ? 'FormElement-ViewMode' : ''"
>
  <span *ngIf="label && !editMode && !isSpecialLabel" class="FormElement-label keepIconInline">
    <abbr *ngIf="required" class="FormElement-required" title="required">*</abbr
    ><span [ngClass]="{ textWithTooltip: helpTooltip }" kendoTooltip [title]="helpTooltip">{{ label }}:</span>
    <app-icon
      *ngIf="helpLink"
      class="infoIcon {{ maxHeight20 }}"
      [icon]="helpIcon"
      [tooltip]="helpTooltip"
      [link]="helpLink"
      [isOpenLink]="isOpenLink"
      [tooltipWidth]="340"
      [istooltipHTML]="true"
    ></app-icon>
  </span>
  <label
    *ngIf="label && editMode && !isSpecialLabel"
    class="FormElement-label pension-editting keepIconInline"
    for="{{ componentId }}"
  >
    <abbr *ngIf="required" class="FormElement-required" title="required">*</abbr
    ><span [ngClass]="{ textWithTooltip: helpTooltip }" kendoTooltip [title]="helpTooltip">{{ label }}:</span>
    <app-icon
      *ngIf="helpLink"
      class="infoIcon {{ maxHeight20 }}"
      [icon]="helpIcon"
      [tooltip]="helpTooltip"
      [link]="helpLink"
      [isOpenLink]="isOpenLink"
      [tooltipWidth]="340"
      [istooltipHTML]="true"
    ></app-icon>
  </label>

  <div class="FormElement-control">
    <span
      *ngIf="label && !editMode && isSpecialLabel"
      class="FormElement-label"
      [ngClass]="{ textWithTooltip: helpTooltip }"
      kendoTooltip
      [title]="helpTooltip"
      >{{ label }}:</span
    >
    <label *ngIf="label && editMode && isSpecialLabel" class="FormElement-label" for="{{ componentId }}"
      >{{ label }}:</label
    >
    <div
      *ngIf="!editMode"
      class="FormElement-static {{ viewClass }}"
      [ngClass]="{ 'align-center': centerText, 'text-right': rightText }"
    >
      {{ value | kendoNumber: (option ? option.format : 'n2'):'da' }}
    </div>

    <kendo-numerictextbox
      id="{{ componentId }}"
      *ngIf="editMode"
      [autoCorrect]="true"
      class="FormElement-numericInput"
      [ngClass]="{
        disable: disable,
        'align-center': centerText,
        'text-right': rightText
      }"
      [hidden]="!editMode"
      [disabled]="disable"
      [min]="option ? option.min : undefined"
      [max]="option ? option.max : undefined"
      [step]="option ? option.step : undefined"
      [spinners]="option ? option.spinners : false"
      [format]="option ? option.format : undefined"
      [decimals]="option ? option.decimals : 0"
      [(ngModel)]="value"
      (blur)="onBlur()"
      (valueChange)="onvalueChange($event)"
      [ngModelOptions]="customModelOptions"
      (focus)="onFocus()"
      (keydown)="onKeydown($event)"
    >
      <kendo-numerictextbox-messages increment="" decrement=""></kendo-numerictextbox-messages>
    </kendo-numerictextbox>

    <button
      (click)="addOnButtonEditClick()"
      class="{{ addOnButtonEditClass }}"
      *ngIf="editMode && addOnButtonEdit"
      [ngClass]="{ addOnButtonEditDisabled: addOnButtonEditDisabled }"
    >
      <span *ngIf="!addOnButtonEditIconEnable">{{ addOnButtonEditLabel }}</span>
      <app-icon *ngIf="addOnButtonEditIconEnable" [tooltip]="addOnButtonEditTooltip" [icon]="addOnButtonEditIcon">
      </app-icon>
    </button>

    <div
      class="validation-texteditor k-widget k-tooltip k-tooltip-validation"
      *ngIf="!isValid && editMode && validationMessage.length !== 0"
    >
      {{ validationMessage }}
    </div>
  </div>

  <span *ngIf="label && !editMode && !isSpecialLabel" class="FormElement-label FormElement-label--small keepIconInline">
    <abbr *ngIf="required" class="FormElement-required" title="required">*</abbr
    ><span [ngClass]="{ textWithTooltip: helpTooltip }" kendoTooltip [title]="helpTooltip">{{ labelAfter }}</span>
    <app-icon
      *ngIf="helpLink"
      class="infoIcon {{ maxHeight20 }}"
      [icon]="helpIcon"
      [tooltip]="helpTooltip"
      [link]="helpLink"
      [isOpenLink]="isOpenLink"
      [tooltipWidth]="340"
      [istooltipHTML]="true"
    ></app-icon>
  </span>
  <label
    *ngIf="label && editMode && !isSpecialLabel"
    class="FormElement-label FormElement-label--small pension-editting keepIconInline"
    for="{{ componentId }}"
  >
    <abbr *ngIf="required" class="FormElement-required" title="required">*</abbr
    ><span [ngClass]="{ textWithTooltip: helpTooltip }" kendoTooltip [title]="helpTooltip">{{ labelAfter }}</span>
    <app-icon
      *ngIf="helpLink"
      class="infoIcon {{ maxHeight20 }}"
      [icon]="helpIcon"
      [tooltip]="helpTooltip"
      [link]="helpLink"
      [isOpenLink]="isOpenLink"
      [tooltipWidth]="340"
      [istooltipHTML]="true"
    ></app-icon>
  </label>
</div>
