import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { NumericTextBoxOptions } from '../../../custom-controls/numeric-edit/numeric-text-box-options';
import {
  BalanceAdjustmentRequest,
  IBalanceAdjustmentRequestDetail,
  IBalancePeriod,
  ICompanyUser,
  IGetBalancesEmployeeViewByDate_Result,
  IUserEmployment
} from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';
import { ReportDialogService } from '../../../shared-components/report-dialog/report-dialog.service';
import { CompanyTemplatesService } from '../../configuration/templates/templates.service';
import { ResidualVacationService } from '../company-balances.services';

@Component({
  selector: 'app-company-balances-residual-vacation',
  templateUrl: './company-residual-vacation.component.html',
  styleUrls: ['./company-residual-vacation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyResidualVacationComponent extends ResidualVacationService implements OnInit, OnDestroy {
  constructor(
    public service: ResidualVacationService,
    protected sessionService: SessionService,
    protected dataService: DataService,
    protected staticDataService: StaticDataService,
    public reportDialogService: ReportDialogService,
    protected transitionService: TransitionService,
    protected broadcaster: BroadcastService,
    protected settingService: SettingService,
    public companyTemplatesService: CompanyTemplatesService
  ) {
    super(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster);

    this.service.showReportShortcutChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      if (value && this.reportDialogService.isVisibleReportIcon) {
        this.reportDialogService.onShowReportsEventClick();
      }
    });

    this.staticDataService.companyUsers.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: ICompanyUser[]) => {
      this.companyUser = value;
    });
  }

  public get isUsesInHouseVacation(): boolean {
    if (this.companyUser && this.companyUser.length > 0) {
      return this.companyUser.some((item: ICompanyUser) => {
        return !!item.UserEmployments.find((employment: IUserEmployment) => employment.VacationProviderId === 99);
      });
    }
    return false;
  }

  public get moduleContent(): number {
    if (this.isUsesInHouseVacation) {
      return 1;
    }
    return 4;
  }

  public refreshData = false;
  public gridDataSource: IGetBalancesEmployeeViewByDate_Result[] = [];
  public spinnerOption: NumericTextBoxOptions = {
    max: undefined,
    min: undefined,
    step: 1,
    spinners: true,
    format: 'n2'
  };
  public isShowDataInvalidAlert = false;
  public isDataValid = true;
  public isShowAfterSaveSucess = false;
  public companyUser: ICompanyUser[] = [];

  private isSaing = false;

  private isSmallScreenValue = false;
  public get isSmallScreen(): boolean {
    return this.isSmallScreenValue;
  }

  public set isSmallScreen(value: boolean) {
    if (value !== this.isSmallScreenValue) {
      this.isSmallScreenValue = value;
      this.refreshData = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.onScreenCheck();
  }

  private onScreenCheck() {
    this.isSmallScreen = window.innerWidth <= 1440;
  }

  public get minCellWidth(): number {
    return this.isSmallScreen ? 400 : 150;
  }

  public ngOnInit(): void {
    this.onScreenCheck();
    this.staticDataService.loadCompanyUsers();
    this.companyTemplatesService.loadTemplates();

    this.service.VacationPeriod.pipe().subscribe((model: IBalancePeriod[]): void => {
      if (model && model.length > 0 && !this.service.VacationPeriodId) {
        model.forEach((item: IBalancePeriod) => {
          const itemStartDate = new Date(item.SpendingStartDate).getTime();
          const itemEndDate = new Date(item.SpendingEndDate).getTime();
          const toDay = new Date().getTime();

          if (toDay >= itemStartDate && toDay <= itemEndDate) {
            this.service.VacationPeriodId = item.Id;
            return;
          }
        });
        if (!this.service.VacationPeriodId) {
          this.service.VacationPeriodId = model[0].Id;
        }
      }
    });

    this.service.BalancesEmployeeViewByDate.pipe().subscribe((model: IGetBalancesEmployeeViewByDate_Result[]): void => {
      // copy dirty records
      let arrayItemDirty: IGetBalancesEmployeeViewByDate_Result[] | any[] = [];
      if (
        this.service.originalDataBalancesEmployeeViewByDate &&
        this.service.originalDataBalancesEmployeeViewByDate.length > 0
      ) {
        arrayItemDirty = this.service.originalDataBalancesEmployeeViewByDate.filter(
          (item: IGetBalancesEmployeeViewByDate_Result | any) => item.isDirty === true
        );
      }

      this.gridDataSource = model;
      this.gridDataSource.forEach((item: IGetBalancesEmployeeViewByDate_Result | any) => {
        if (item.DaysTotal || item.DaysTotal === 0) {
          item.DaysTotal = parseFloat(parseFloat(item.DaysTotal).toFixed(2));
        }
        this.caculateLeft(item);
        item.isDirty = false;
      });
      if (this.service.isFirstLoad) {
        this.service.isFirstLoad = false;
      } else {
        if (arrayItemDirty && arrayItemDirty.length > 0) {
          arrayItemDirty.forEach((item: IGetBalancesEmployeeViewByDate_Result | any) => {
            const recordDirty:
              | IGetBalancesEmployeeViewByDate_Result
              | any = this.service.originalDataBalancesEmployeeViewByDate.find(
              (find: IGetBalancesEmployeeViewByDate_Result | any) => find.UserEmploymentId === item.UserEmploymentId
            );
            if (recordDirty) {
              recordDirty.isDirty = true;
            }
          });
        }
      }
    });
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get isDirtyData(): boolean {
    if (this.gridDataSource && this.gridDataSource.length > 0) {
      return this.gridDataSource.some((item: any) => item.isDirty);
    }
    return false;
  }

  public get isReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public get isIOSApp(): boolean {
    return this.sessionService.browser.isHybridApp && this.sessionService.browser.iOSMobileDevice;
  }

  public get isDisableSubmitButton(): boolean {
    return this.isReadOnly || !this.service.effectivePer;
  }

  public onGridSave(event: any): void {
    if (event.dataItem) {
      event.dataItem.SpendDays =
        event.dataItem.SpendDays && event.dataItem.SpendDays > 0
          ? event.dataItem.SpendDays * -1
          : event.dataItem.SpendDays;
      event.dataItem.MoveDays =
        event.dataItem.MoveDays && event.dataItem.MoveDays > 0 ? event.dataItem.MoveDays * -1 : event.dataItem.MoveDays;
      event.dataItem.PayoutDays =
        event.dataItem.PayoutDays && event.dataItem.PayoutDays > 0
          ? event.dataItem.PayoutDays * -1
          : event.dataItem.PayoutDays;

      this.caculateLeft(event.dataItem);
    }
  }

  public onGridButtonClick(event: any): void {
    let feildChnage = '';
    switch (event.buttonAction) {
      case 'MaxSpend':
        feildChnage = 'SpendDays';
        break;
      case 'MaxMove':
        feildChnage = 'MoveDays';
        break;
      default:
        feildChnage = 'PayoutDays';
        break;
    }
    if (feildChnage) {
      if (event.dataItem['left'] === 0) {
        event.dataItem['disbaleMaxButton'] = true;
        return;
      } else {
        this.caculateMaxValue(feildChnage, event.dataItem);
      }
    }
  }

  public MaxFieldDays(field: string): void {
    if (this.isReadOnly) {
      return;
    }
    this.gridDataSource.forEach((item: any) => {
      if (item['left'] !== 0) {
        this.caculateMaxValue(field, item);
      }
    });
  }

  private caculateMaxValue(feildMax: string, data: any) {
    if (data['left'] === 0) {
      data['disbaleMaxButton'] = true;
      return;
    } else {
      const leftValue = parseFloat(parseFloat(data['left']).toFixed(2));

      const dataFeildMax = data[feildMax] ? data[feildMax] : 0;
      data[feildMax] = leftValue * -1 + parseFloat(parseFloat(dataFeildMax).toFixed(2));
      data[feildMax] = parseFloat(parseFloat(data[feildMax]).toFixed(2));

      this.caculateLeft(data);
      this.refreshData = true;
    }
  }

  private caculateLeft(data: IGetBalancesEmployeeViewByDate_Result | any): void {
    const DaysTotalValues = data.DaysTotal ? data.DaysTotal : 0;
    const AdjustDaysValues = data.AdjustDays ? data.AdjustDays : 0;
    const SpendDaysValues = data.SpendDays ? data.SpendDays : 0;
    const MoveDaysValues = data.MoveDays ? data.MoveDays : 0;
    const PayoutDaysValues = data.PayoutDays ? data.PayoutDays : 0;

    data.left = this.sessionService.parseFloat(
      DaysTotalValues +
        (parseFloat(parseFloat(AdjustDaysValues).toFixed(2)) +
          parseFloat(parseFloat(SpendDaysValues).toFixed(2)) +
          parseFloat(parseFloat(MoveDaysValues).toFixed(2)) +
          parseFloat(parseFloat(PayoutDaysValues).toFixed(2)))
    );

    data.left = parseFloat(parseFloat(data.left).toFixed(2));

    if (data.left < 0) {
      data.errorLeftClass = 'left-error';
      data['disbaleMaxButton'] = false;
    } else {
      data.errorLeftClass = '';
      data['disbaleMaxButton'] = false;

      if (data.left === 0) {
        data['disbaleMaxButton'] = true;
      }
    }
    data.isDirty = true;
  }

  private submitGridValues(): void {
    if (!this.isDirtyData) {
      return;
    }
    const request: BalanceAdjustmentRequest = {
      BalanceDefinitionId: 1,
      BalancePeriodId: this.service.VacationPeriodId,
      PerDate: this.service.effectivePer
    };
    const DetailDataSource: IBalanceAdjustmentRequestDetail[] = [];
    let countError = 0;
    this.gridDataSource.forEach((item: any) => {
      if (item.isDirty) {
        DetailDataSource.push({
          UserEmploymentId: item.UserEmploymentId || null,
          AdjustDays: item.AdjustDays || null,
          SpendDays: item.SpendDays || null,
          MoveDays: item.MoveDays || null,
          PayoutDays: item.PayoutDays || null,

          AdjustHours: item.AdjustHours || null,
          SpendHours: item.SpendHours || null,
          PayoutHours: item.PayoutHours || null
        });
        if (item.left < 0) {
          countError += 1;
        }
      }
    });
    if (countError > 0) {
      this.isDataValid = false;
    } else {
      this.isDataValid = true;
    }
    request.Details = DetailDataSource;
    if (this.isDataValid) {
      if (request.Details && request.Details.length > 0) {
        this.save(request);
      }
    } else {
      this.isShowDataInvalidAlert = true;
    }
  }

  public onSubmitButtonClick() {
    setTimeout(() => {
      this.submitGridValues();
    }, 500);
  }

  // public onEffecivePerKeyDown(event: any): void {
  //   if (event === 'Enter') {
  //     this.service.Reload_Balances_GetResidualVacationBalances();
  //   }
  // }

  public save(model: BalanceAdjustmentRequest): void {
    if (this.isSaing) {
      return;
    }
    this.isSaing = true;
    super.save(model);
  }

  public afterSaveSucess(data: IGetBalancesEmployeeViewByDate_Result[]): void {
    if (data && data.length > 0) {
      data.forEach((item: IGetBalancesEmployeeViewByDate_Result) => {
        const itemAfterSave: any = this.gridDataSource.find(
          (model: IGetBalancesEmployeeViewByDate_Result | any) => model.UserEmploymentId === item.UserEmploymentId
        );
        if (itemAfterSave) {
          itemAfterSave.FullName = item.FullName;
          itemAfterSave.BasedOnTemplateName = item.BasedOnTemplateName;
          itemAfterSave.DepartmentName = item.DepartmentName;
          itemAfterSave.ExternalReference = item.ExternalReference;

          itemAfterSave.BaseAmount = item.BaseAmount || null;
          itemAfterSave.AmountEarned = item.AmountEarned || null;
          itemAfterSave.DaysEarned = item.DaysEarned || null;
          itemAfterSave.DaysSpent = item.DaysSpent || null;
          itemAfterSave.DaysTotal = item.DaysTotal || null;

          itemAfterSave.SecondaryDaysEarned = item.SecondaryDaysEarned || null;
          itemAfterSave.SecondaryDaysSpent = item.SecondaryDaysSpent || null;
          itemAfterSave.CurrentBalanceSecondaryDays = item.CurrentBalanceSecondaryDays || null;
          itemAfterSave.SecondaryAmountTotal = item.SecondaryAmountTotal || null;

          itemAfterSave.DaysEarned = itemAfterSave.DaysEarned + itemAfterSave.SecondaryDaysEarned;
          itemAfterSave.DaysSpent = itemAfterSave.DaysSpent + itemAfterSave.SecondaryDaysSpent;
          itemAfterSave.DaysTotal = itemAfterSave.DaysTotal + itemAfterSave.CurrentBalanceSecondaryDays;
          itemAfterSave.AmountTotal = itemAfterSave.AmountTotal + itemAfterSave.SecondaryAmountTotal;

          itemAfterSave.AdjustDays = null;
          itemAfterSave.SpendDays = null;
          itemAfterSave.MoveDays = null;
          itemAfterSave.PayoutDays = null;

          this.caculateLeft(itemAfterSave);
          itemAfterSave.isDirty = false;
        }
      });
      this.refreshData = true;
    }

    this.isShowAfterSaveSucess = true;
  }

  public completeSave() {
    this.isSaing = false;
  }
}
