import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { defer, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { version } from '../../../version';
import { ApiDataService } from '../../services/api/api-data.service';
import { ModalService } from '../modal.service';

@Injectable({
  providedIn: 'root'
})
export class CustomTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient, private modalService: ModalService, private dataService: ApiDataService) {}

  public get branding() {
    return environment.branding;
  }

  public get DomainAlias(): string {
    return `<a href='${window.location.hostname}'>${window.location.hostname}</a>`;
  }

  getTranslation(lang: string = 'da'): Observable<any> {
    lang = lang && lang === 'sv' ? 'se' : lang;
    const AppLangCode: string[] = ['da', 'en', 'kl', 'se'];
    if (!AppLangCode.find((value: string) => value === lang)) {
      lang = 'da';
    }
    return this.http.get('translation/' + version + `-locale.${lang}.json`).pipe(
      catchError(
        (err) =>
          new Observable(() => {
            if (err && err.status === 200) {
              let serverVersion = '';
              const devTitle = version + `-locale.${lang}.json`;
              const devMessage = err.error.error.message;
              const serverVersionPromise = this.dataService.Miscellaneous_GetVersion().toPromise();
              serverVersionPromise
                .then((value: string) => {
                  serverVersion = value;
                  if (serverVersion !== version) {
                    switch (lang) {
                      case 'en':
                        this.modalService.alert(
                          '',
                          this.branding.SystemAlias +
                            ' is updated to a newer version. Please press Ctrl+F5 in your browser to force a refresh, or update the ' +
                            this.branding.SystemAlias +
                            " app in Google Play or the App Store.<br/>If you're using a mobile app and do not see an available update, please check again tomorrow. Until then, you can use your browser - just navigate to " +
                            this.DomainAlias,
                          true,
                          true
                        );
                        break;
                      case 'se':
                        this.modalService.alert(
                          '',
                          this.branding.SystemAlias +
                            ' är uppdaterad till en nyare version. Vänligen tryck på Ctrl+F5 i din webbläsare för att tvinga igenom en uppdatering, eller uppdatera ' +
                            this.branding.SystemAlias +
                            ' appen i Google Play eller App Store.<br/>Om du använder en mobilapp och inte ser en tillgänglig uppdatering - kontrollera igen imorgon. Till dess kan du använda din webbläsare - adressen är ' +
                            this.DomainAlias,
                          true,
                          true
                        );
                        break;
                      default:
                        this.modalService.alert(
                          '',
                          this.branding.SystemAlias +
                            " er opdateret til en nyere version. Tryk Ctrl+F5 i din browser for at gennemtvinge en opdatering, eller opdatér app'en i Google Play eller App Store.<br/>Benytter du en mobil app og kan ikke se en tilgængelig opdatering, så tjek igen i morgen. Indtil da kan du benytte din browser i stedet - adressen er " +
                            this.DomainAlias,
                          true,
                          true
                        );
                        break;
                    }
                    return;
                  } else {
                    this.modalService.alert(devTitle, devMessage, true, true);
                    return;
                  }
                })
                .catch(() => {
                  this.modalService.alert(devTitle, devMessage, true, true);
                  return;
                });
              this.modalService.alert(devTitle, devMessage, true, true);
              return;
            }
            this.modalService.alert(
              '',
              this.branding.SystemAlias +
                " er opdateret til en nyere version. Tryk Ctrl+F5 i din browser for at gennemtvinge en opdatering, eller opdatér app'en i Google Play eller App Store.<br/>Benytter du en mobil app og kan ikke se en tilgængelig opdatering, så tjek igen i morgen. Indtil da kan du benytte din browser i stedet - adressen er " +
                this.DomainAlias,
              true,
              true
            );
          })
      )
    );
  }
}
