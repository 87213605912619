<div class="company-payroll-data tabs">
  <app-fixed-panel [excludedComponents]="listClassExcluded" [specificClassName]="'Island-payrollData'">
    <div class="Island Island-payrollData Cards">
      <div class="Company-batchesControls Company-payroll">
        <app-combobox-edit
          [label]="'EmployeeGeneral.SalaryCycle' | translate"
          [editMode]="true"
          class="Company-batchesCycles"
          [editMode]="true"
          [idField]="'Id'"
          [textField]="'Name'"
          [nullValueLabel]="'CompanySalaryBatches.SalaryCycle_Item_All_Text' | translate"
          [comboboxDataSource]="companyPayrollDataService.salaryCycle | async"
          [(value)]="companyPayrollDataService.salaryCycleId"
        >
        </app-combobox-edit>

        <app-combobox-edit
          [label]="'NewEmployeeEmployment.Template' | translate"
          [editMode]="true"
          class="Company-batchesCycles"
          [editMode]="true"
          [idField]="'Id'"
          [textField]="'Name'"
          [nullValueLabel]="'CompanySalaryBatches.SalaryCycle_Item_All_Text' | translate"
          [comboboxDataSource]="companyPayrollDataService.templates | async"
          [(value)]="companyPayrollDataService.templateId"
        ></app-combobox-edit>

        <app-combobox-edit
          [label]="'EmployeeGeneral.Department' | translate"
          [editMode]="true"
          [editMode]="true"
          class="Company-departmentCycles"
          [idField]="'Id'"
          [textField]="'Name'"
          [nullValueLabel]="'Employee.AllDepartments' | translate"
          [comboboxDataSource]="staticDataService.departments | async"
          [(value)]="companyPayrollDataService.departmentId"
          [keepDataSourceOrder]="true"
        >
        </app-combobox-edit>

        <div class="Company-batchesControlsButtons">
          <app-check-edit
            [label]="'CompanyPayrollData.EmployeeWithNoData' | translate"
            [(value)]="companyPayrollDataService.showEmployeeWithNoData"
            [editMode]="true"
          ></app-check-edit>
        </div>
      </div>
      <div class="Card Company-payrollData" [ngClass]="{ hasAddNewColumn: hasAddNewColumn }">
        <app-module-check [moduleId]="4">
          <kendo-grid
            *ngIf="gridInit"
            id="companyPayrollDataGrid"
            [data]="gridView"
            [pageSize]="pageSize"
            [skip]="skip"
            [pageable]="pageable"
            (pageChange)="pageChange($event)"
            [height]="575"
            [navigable]="true"
            [selectable]="true"
            (cellClick)="onCellClick($event)"
            [selectedKeys]="selectedRows"
            [kendoGridSelectBy]="'UserEmploymentId'"
            (cellClose)="onCellClose($event)"
            #kendoGridComponent
            (keydown)="onKeyDown($event)"
            [resizable]="true"
            [filterable]="'menu'"
            [filter]="filter"
            (filterChange)="onFilterChange($event)"
          >
            <kendo-grid-messages
              pagerOf="{{ 'Kendo.pagerOf' | translate }}"
              pagerItems="{{ 'Kendo.pagerItems' | translate }}"
              [filterClearButton]="'Filter.Clear' | translate"
              [filterFilterButton]="'Filter.Filter' | translate"
            ></kendo-grid-messages>

            <ng-template kendoGridNoRecordsTemplate></ng-template>

            <kendo-grid-column-group [width]="400" [locked]="true">
              <kendo-grid-column
                [filterable]="true"
                [field]="'FullName'"
                [title]="'CompanySalaryBatches.PaySlipsGridField_Employee' | translate"
                [width]="200"
                [editable]="false"
                [locked]="companyPayrollDataService.locked"
              >
                <ng-template
                  kendoGridFilterMenuTemplate
                  let-column="column"
                  let-filter="filter"
                  let-filterService="filterService"
                >
                  <kendo-grid-string-filter-menu
                    [column]="column"
                    [filter]="filter"
                    [extra]="false"
                    [filterService]="filterService"
                  >
                    <kendo-filter-eq-operator [text]="'Filter.IsEqualTo' | translate"></kendo-filter-eq-operator>
                    <kendo-filter-neq-operator [text]="'Filter.IsNotEqualTo' | translate"></kendo-filter-neq-operator>
                    <kendo-filter-contains-operator [text]="'Filter.Contains' | translate">
                    </kendo-filter-contains-operator>
                    <kendo-filter-not-contains-operator [text]="'Filter.DoesNotContain' | translate">
                    </kendo-filter-not-contains-operator>
                    <kendo-filter-startswith-operator [text]="'Filter.StartsWith' | translate">
                    </kendo-filter-startswith-operator>
                    <kendo-filter-endswith-operator [text]="'Filter.EndsWith' | translate">
                    </kendo-filter-endswith-operator>
                    <kendo-filter-isnull-operator [text]="'Filter.IsNull' | translate"></kendo-filter-isnull-operator>
                    <kendo-filter-isnotnull-operator [text]="'Filter.IsNotNull' | translate">
                    </kendo-filter-isnotnull-operator>
                  </kendo-grid-string-filter-menu>
                </ng-template>

                <ng-template kendoGridHeaderTemplate let-column>
                  <div class="header-tooltip-overlay" kendoTooltip [title]="column.title"></div>
                  <div class="label-header-cell">
                    <a class="header-label-text">
                      {{ column.title }}
                    </a>
                  </div>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                [filterable]="true"
                *ngIf="showDepartment"
                [field]="'DepartmentName'"
                [title]="'CompanySalaryBatches.Department' | translate"
                [width]="200"
                [editable]="false"
                [locked]="companyPayrollDataService.locked"
                [footerStyle]="{ 'border-left': '0' }"
              >
                <ng-template
                  kendoGridFilterMenuTemplate
                  let-column="column"
                  let-filter="filter"
                  let-filterService="filterService"
                >
                  <kendo-grid-string-filter-menu
                    [column]="column"
                    [filter]="filter"
                    [extra]="false"
                    [filterService]="filterService"
                  >
                    <kendo-filter-eq-operator [text]="'Filter.IsEqualTo' | translate"></kendo-filter-eq-operator>
                    <kendo-filter-neq-operator [text]="'Filter.IsNotEqualTo' | translate"></kendo-filter-neq-operator>
                    <kendo-filter-contains-operator [text]="'Filter.Contains' | translate">
                    </kendo-filter-contains-operator>
                    <kendo-filter-not-contains-operator [text]="'Filter.DoesNotContain' | translate">
                    </kendo-filter-not-contains-operator>
                    <kendo-filter-startswith-operator [text]="'Filter.StartsWith' | translate">
                    </kendo-filter-startswith-operator>
                    <kendo-filter-endswith-operator [text]="'Filter.EndsWith' | translate">
                    </kendo-filter-endswith-operator>
                    <kendo-filter-isnull-operator [text]="'Filter.IsNull' | translate"></kendo-filter-isnull-operator>
                    <kendo-filter-isnotnull-operator [text]="'Filter.IsNotNull' | translate">
                    </kendo-filter-isnotnull-operator>
                  </kendo-grid-string-filter-menu>
                </ng-template>
                <ng-template kendoGridFooterTemplate>
                  Total :
                </ng-template>

                <ng-template kendoGridHeaderTemplate let-column>
                  <div class="header-tooltip-overlay" kendoTooltip [title]="column.title"></div>
                  <div class="label-header-cell">
                    <a class="header-label-text">
                      {{ column.title }}
                    </a>
                  </div>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid-column-group>

            <kendo-grid-column-group
              *ngFor="let salaryType of companyPayrollDataService.salaryTypes; trackBy: trackIndex; let i = index"
              [width]="330"
              [locked]="false"
            >
              <ng-template kendoGridHeaderTemplate let-dataItem="dataItem">
                <div *ngIf="!salaryType.IsNew" class="header-configuration">
                  <div class="header-tooltip-overlay" kendoTooltip [title]="salaryType?.displayName"></div>
                  <div class="config-name">{{ salaryType?.displayName }}</div>
                  <div class="config-icon">
                    <app-icon
                      (click)="onSalaryTypeConfigrationClicked(salaryType)"
                      [icon]="'Cog'"
                      [tooltip]="'CompanyPayrollData.ChangeSalaryTypeTooltip' | translate"
                    >
                    </app-icon>
                  </div>
                </div>
              </ng-template>

              <kendo-grid-column
                [filterable]="true"
                *ngIf="!salaryType.IsNew && UnitsColumnVisible(salaryType)"
                [field]="'SalaryRecord' + (i + 1) + 'Units'"
                [footerStyle]="{ 'text-align': 'right' }"
                [editor]="'numeric'"
                [title]="salaryType.unitTypeTitle ? salaryType.unitTypeTitle : ('CompanySalaryTypes.Units' | translate)"
                [width]="100"
              >
                <ng-template
                  kendoGridFilterMenuTemplate
                  let-column="column"
                  let-filter="filter"
                  let-filterService="filterService"
                >
                  <kendo-grid-numeric-filter-menu
                    [column]="column"
                    [extra]="false"
                    operator="eq"
                    [filter]="filter"
                    [filterService]="filterService"
                  >
                    <kendo-filter-eq-operator [text]="'Filter.IsEqualTo' | translate"></kendo-filter-eq-operator>
                    <kendo-filter-neq-operator [text]="'Filter.IsNotEqualTo' | translate"></kendo-filter-neq-operator>
                    <kendo-filter-gt-operator [text]="'Filter.IsGreaterThan' | translate"></kendo-filter-gt-operator>
                    <kendo-filter-gte-operator [text]="'Filter.IsGreaterThanOrEqualTo' | translate">
                    </kendo-filter-gte-operator>
                    <kendo-filter-lt-operator [text]="'Filter.IsLessThan' | translate"></kendo-filter-lt-operator>
                    <kendo-filter-lte-operator [text]="'Filter.IsLessThanOrEqualTo' | translate">
                    </kendo-filter-lte-operator>
                    <kendo-filter-isnull-operator [text]="'Filter.IsNull' | translate"></kendo-filter-isnull-operator>
                    <kendo-filter-isnotnull-operator [text]="'Filter.IsNotNull' | translate">
                    </kendo-filter-isnotnull-operator>
                  </kendo-grid-numeric-filter-menu>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                  <app-numeric-edit
                    [rightText]="true"
                    [editMode]="false"
                    [(value)]="dataItem['SalaryRecord' + (i + 1) + 'Units']"
                    [option]="numericOptions"
                    [setTimeout]="false"
                  ></app-numeric-edit>
                </ng-template>
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                  <app-numeric-edit
                    [rightText]="true"
                    [editMode]="true"
                    [(value)]="dataItem['SalaryRecord' + (i + 1) + 'Units']"
                    [option]="numericOptions"
                    [setTimeout]="false"
                  ></app-numeric-edit>
                </ng-template>
                <ng-template kendoGridFooterTemplate let-column let-columnIndex="columnIndex">
                  {{ rowTotal[column.field] ? (rowTotal[column.field] | kendoNumber: 'n2') : '' }}
                </ng-template>

                <ng-template kendoGridHeaderTemplate let-column>
                  <div class="header-tooltip-overlay" kendoTooltip [title]="column.title"></div>
                  <div class="label-header-cell">
                    <a class="header-label-text">
                      {{ column.title }}
                    </a>
                  </div>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                [filterable]="true"
                *ngIf="!salaryType.IsNew && PerUnitColumnVisible(salaryType)"
                [field]="'SalaryRecord' + (i + 1) + 'PerUnit'"
                [editor]="'numeric'"
                [title]="'CompanySalaryBatches.SalaryRecordsGridField_AmountPerUnit' | translate"
                [width]="100"
                [footerStyle]="{ 'text-align': 'right' }"
              >
                <ng-template
                  kendoGridFilterMenuTemplate
                  let-column="column"
                  let-filter="filter"
                  let-filterService="filterService"
                >
                  <kendo-grid-numeric-filter-menu
                    [column]="column"
                    [extra]="false"
                    operator="eq"
                    [filter]="filter"
                    [filterService]="filterService"
                  >
                    <kendo-filter-eq-operator [text]="'Filter.IsEqualTo' | translate"></kendo-filter-eq-operator>
                    <kendo-filter-neq-operator [text]="'Filter.IsNotEqualTo' | translate"></kendo-filter-neq-operator>
                    <kendo-filter-gt-operator [text]="'Filter.IsGreaterThan' | translate"></kendo-filter-gt-operator>
                    <kendo-filter-gte-operator [text]="'Filter.IsGreaterThanOrEqualTo' | translate">
                    </kendo-filter-gte-operator>
                    <kendo-filter-lt-operator [text]="'Filter.IsLessThan' | translate"></kendo-filter-lt-operator>
                    <kendo-filter-lte-operator [text]="'Filter.IsLessThanOrEqualTo' | translate">
                    </kendo-filter-lte-operator>
                    <kendo-filter-isnull-operator [text]="'Filter.IsNull' | translate"></kendo-filter-isnull-operator>
                    <kendo-filter-isnotnull-operator [text]="'Filter.IsNotNull' | translate">
                    </kendo-filter-isnotnull-operator>
                  </kendo-grid-numeric-filter-menu>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                  <app-numeric-edit
                    [rightText]="true"
                    [editMode]="false"
                    [(value)]="dataItem['SalaryRecord' + (i + 1) + 'PerUnit']"
                    [option]="numericOptions"
                    [setTimeout]="false"
                  >
                  </app-numeric-edit>
                </ng-template>
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                  <app-numeric-edit
                    [rightText]="true"
                    [editMode]="true"
                    [(value)]="dataItem['SalaryRecord' + (i + 1) + 'PerUnit']"
                    [option]="numericOptions"
                    [setTimeout]="false"
                  >
                  </app-numeric-edit>
                </ng-template>
                <ng-template kendoGridFooterTemplate let-column let-columnIndex="columnIndex">
                  <!--{{ rowTotal[column.field] | kendoNumber:'n2'}}-->
                </ng-template>

                <ng-template kendoGridHeaderTemplate let-column>
                  <div class="header-tooltip-overlay" kendoTooltip [title]="column.title"></div>
                  <div class="label-header-cell">
                    <a class="header-label-text">
                      {{ column.title }}
                    </a>
                  </div>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                [filterable]="true"
                *ngIf="!salaryType.IsNew && AmountColumnVisible(salaryType)"
                [field]="'SalaryRecord' + (i + 1) + 'Amount'"
                [editor]="'numeric'"
                [title]="'CompanySalaryBatches.SalaryRecordsGridField_Amount' | translate"
                [width]="100"
                [footerStyle]="{ 'text-align': 'right' }"
              >
                <ng-template
                  kendoGridFilterMenuTemplate
                  let-column="column"
                  let-filter="filter"
                  let-filterService="filterService"
                >
                  <kendo-grid-numeric-filter-menu
                    [column]="column"
                    [extra]="false"
                    operator="eq"
                    [filter]="filter"
                    [filterService]="filterService"
                  >
                    <kendo-filter-eq-operator [text]="'Filter.IsEqualTo' | translate"></kendo-filter-eq-operator>
                    <kendo-filter-neq-operator [text]="'Filter.IsNotEqualTo' | translate"></kendo-filter-neq-operator>
                    <kendo-filter-gt-operator [text]="'Filter.IsGreaterThan' | translate"></kendo-filter-gt-operator>
                    <kendo-filter-gte-operator [text]="'Filter.IsGreaterThanOrEqualTo' | translate">
                    </kendo-filter-gte-operator>
                    <kendo-filter-lt-operator [text]="'Filter.IsLessThan' | translate"></kendo-filter-lt-operator>
                    <kendo-filter-lte-operator [text]="'Filter.IsLessThanOrEqualTo' | translate">
                    </kendo-filter-lte-operator>
                    <kendo-filter-isnull-operator [text]="'Filter.IsNull' | translate"></kendo-filter-isnull-operator>
                    <kendo-filter-isnotnull-operator [text]="'Filter.IsNotNull' | translate">
                    </kendo-filter-isnotnull-operator>
                  </kendo-grid-numeric-filter-menu>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                  <!--<app-icon *ngIf="dataItem['MultipleRecordsExist' + (i + 1)]" [icon]="'Warning'" [tooltip]="'CompanySalaryBatches.SalaryTypeDuplicationWarning' | translate: { name: salaryType?.displayName }"></app-icon>-->
                  <app-numeric-edit
                    [rightText]="true"
                    [editMode]="false"
                    [(value)]="dataItem['SalaryRecord' + (i + 1) + 'Amount']"
                    [option]="numericOptions"
                    [setTimeout]="false"
                  ></app-numeric-edit>
                </ng-template>
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                  <app-numeric-edit
                    [rightText]="true"
                    [editMode]="true"
                    [(value)]="dataItem['SalaryRecord' + (i + 1) + 'Amount']"
                    [option]="numericOptions"
                    [setTimeout]="false"
                  ></app-numeric-edit>
                </ng-template>
                <ng-template kendoGridFooterTemplate let-column let-columnIndex="columnIndex">
                  {{ rowTotal[column.field] ? (rowTotal[column.field] | kendoNumber: 'n2') : '' }}
                </ng-template>

                <ng-template kendoGridHeaderTemplate let-column>
                  <div class="header-tooltip-overlay" kendoTooltip [title]="column.title"></div>
                  <div class="label-header-cell">
                    <a class="header-label-text">
                      {{ column.title }}
                    </a>
                  </div>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                *ngIf="!salaryType.IsNew"
                [title]="''"
                [width]="40"
                [editable]="false"
                [footerStyle]="{ 'text-align': 'right', 'border-width': '0 1px 0 1px' }"
                [style]="{ 'border-width': '0 1px 1px 1px' }"
                [headerStyle]="{ 'border-width': '0 1px 1px 1px' }"
              >
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                  <app-icon
                    class="margin-left"
                    *ngIf="dataItem['MultipleRecordsExist' + (i + 1)]"
                    [icon]="'Warning'"
                    [tooltip]="
                      'CompanySalaryBatches.SalaryTypeDuplicationWarning' | translate: { name: salaryType?.displayName }
                    "
                  ></app-icon>
                </ng-template>
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                  <app-icon
                    class="margin-left"
                    *ngIf="dataItem['MultipleRecordsExist' + (i + 1)]"
                    [icon]="'Warning'"
                    [tooltip]="
                      'CompanySalaryBatches.SalaryTypeDuplicationWarning' | translate: { name: salaryType?.displayName }
                    "
                  ></app-icon>
                </ng-template>
                <ng-template kendoGridFooterTemplate let-column let-columnIndex="columnIndex">
                  {{ rowTotal[column.field] ? (rowTotal[column.field] | kendoNumber: 'n2') : '' }}
                </ng-template>

                <ng-template kendoGridHeaderTemplate let-column>
                  <div class="header-tooltip-overlay" kendoTooltip [title]="column.title"></div>
                  <div class="label-header-cell">
                    <a class="header-label-text">
                      {{ column.title }}
                    </a>
                  </div>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid-column-group>

            <kendo-grid-column
              [filterable]="true"
              *ngIf="hasAddNewColumn"
              [width]="150"
              [locked]="false"
              [footerStyle]="{ 'text-align': 'right' }"
            >
              <ng-template kendoGridHeaderTemplate let-dataItem="dataItem">
                <div class="align-center">
                  <app-button
                    class="k-button"
                    (clickEvent)="onSalaryTypeConfigrationClicked()"
                    [label]="'CompanySalaryBatches.AddSalaryTypeText' | translate"
                  >
                  </app-button>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem="dataItem"> </ng-template>
              <ng-template kendoGridEditTemplate let-dataItem="dataItem"> </ng-template>
              <ng-template kendoGridFooterTemplate let-column let-columnIndex="columnIndex">
                {{ rowTotal[column.field] ? (rowTotal[column.field] | kendoNumber: 'n2') : '' }}
              </ng-template>

              <ng-template kendoGridHeaderTemplate let-column>
                <div class="header-tooltip-overlay" kendoTooltip [title]="column.title"></div>
                <div class="label-header-cell">
                  <a class="header-label-text">
                    {{ column.title }}
                  </a>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-command-column width="1">
              <ng-template kendoGridCellTemplate let-dataItem="dataItem"> </ng-template>
              <ng-template kendoGridEditTemplate let-dataItem="dataItem"> </ng-template>
            </kendo-grid-command-column>
          </kendo-grid>
        </app-module-check>
      </div>
    </div>
  </app-fixed-panel>

  <div class="Actions" *ngIf="!isMobile">
    <app-menu-button
      [menuIcon]="'Help'"
      [menuLabel]="'Help.Title'"
      [menuTooltip]="'Help.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
    </app-menu-button>
    <app-menu-button
      *ngIf="reportDialogService.isVisibleReportIcon"
      [menuCondition]="true"
      (menuClick)="reportDialogService.onShowReportsEventClick()"
      [menuIcon]="'File'"
      [menuLabel]="'Company.Reports'"
      [menuTooltip]="'Company.Reports'"
      [actionButtonMenu]="true"
      [menuButtonClass]="'Action-button Action-buttonReports'"
      [menuButtonId]="'ActionButtonReports'"
    >
    </app-menu-button>
  </div>

  <div class="Actions" *ngIf="isMobile">
    <app-menu-button
      [menuIcon]="'ThreeDots'"
      [menuLabel]="'More.Title'"
      [menuTooltip]="'More.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item [field]="'SystemHelp'"></app-menu-button-item>
    </app-menu-button>
  </div>
</div>
<app-salarytype-configuration-dialog
  [(visible)]="showSalaryTypeConfiguration"
  [selectedSalaryType]="selectedSalaryTypeForConfiguration"
  [salaryTypes]="configurationSalaryTypeDataSource"
  [maxIndex]="overviewMaxIndex"
></app-salarytype-configuration-dialog>

<app-loading-tips [visible]="companyPayrollDataService.isWattingLoading"></app-loading-tips>
