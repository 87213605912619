<span [ngClass]="isHidden ? 'Icon--Payslip--hide' : ''">
  <button
    *ngIf="showWarningTab"
    kendoTooltip
    title="{{ 'CompanySalaryBatches.WarningPayrollPreview' | translate }}"
    type="button"
    (click)="onButtonClick()"
  >
    <span title="{{ 'CompanySalaryBatches.WarningPayrollPreview' | translate }}">
      <app-icon [tooltip]="'CompanySalaryBatches.WarningPayrollPreview' | translate" [icon]="'warning'"> </app-icon>
    </span>
  </button>
  <button
    *ngIf="showErrorTab"
    kendoTooltip
    title="{{ 'CompanySalaryBatches.ErrorPayrollPreview' | translate }}"
    type="button"
    (click)="onButtonClick()"
  >
    <span title="{{ 'CompanySalaryBatches.ErrorPayrollPreview' | translate }}">
      <app-icon [tooltip]="'CompanySalaryBatches.ErrorPayrollPreview' | translate" [icon]="'ErrorExclamation'">
      </app-icon>
    </span>
  </button>
  <button
    kendoTooltip
    title="{{ 'EmploymentPayrollData.HTMLPopupToolTip' | translate }}"
    type="button"
    (click)="onButtonClick()"
  >
    <span *ngIf="showInCurrentTab" title="{{ 'EmploymentPayrollData.HTMLPopupToolTip' | translate }}">
      <app-icon [tooltip]="'EmploymentPayrollData.HTMLPopupToolTip' | translate" [icon]="'html-outline'"> </app-icon>
    </span>
  </button>
  <button
    *ngIf="showInNewTab && IsWeb"
    kendoTooltip
    title="{{ 'EmploymentPayrollData.HTMLFileToolTip' | translate }}"
    type="button"
    (click)="onButtonClick()"
  >
    <span title="{{ 'EmploymentPayrollData.HTMLFileToolTip' | translate }}">
      <app-icon [tooltip]="'EmploymentPayrollData.HTMLFileToolTip' | translate" [icon]="'html'"> </app-icon>
    </span>
  </button>
  <button
    *ngIf="showPDF"
    kendoTooltip
    title="{{ 'CompanySalaryBatches.PaySlipPDFTooltip' | translate }}"
    type="button"
    (click)="onButtonClick()"
  >
    <span title="{{ 'CompanySalaryBatches.PaySlipPDFTooltip' | translate }}">
      <app-icon [tooltip]="'CompanySalaryBatches.PaySlipPDFTooltip' | translate" [icon]="'pdf'"> </app-icon>
    </span>
  </button>
  <button
    *ngIf="showPrintTab"
    kendoTooltip
    title="{{ 'CompanySalaryBatches.PaySlipPrintTooltip' | translate }}"
    type="button"
    (click)="onButtonClick()"
  >
    <span title="{{ 'CompanySalaryBatches.PaySlipPrintTooltip' | translate }}">
      <app-icon [tooltip]="'CompanySalaryBatches.PaySlipPrintTooltip' | translate" [icon]="'file-print'"> </app-icon>
    </span>
  </button>
</span>
