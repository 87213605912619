<div *ngIf="company" class="Card-inner">
  <form>
    <fieldset class="Form--horizontal">
      <legend class="Card-title">{{ 'CompanyGeneral.CompanyData' | translate }}</legend>

      <app-text-edit
        [label]="'CompanyGeneral.BankRegNo' | translate"
        [editMode]="editMode"
        [type]="'number'"
        [(isDirty)]="childIsDirty[0]"
        [(value)]="company.BankRegNo"
        [(isValid)]="childIsValid[0]"
        (valueChange)="onChange()"
      ></app-text-edit>

      <app-text-edit
        [label]="'CompanyGeneral.BankAccountNo' | translate"
        [editMode]="editMode"
        [type]="'number'"
        [(value)]="company.BankAccountNo"
        [(isDirty)]="childIsDirty[1]"
        [(isValid)]="childIsValid[1]"
        (valueChange)="onBankAccountNoChanged()"
      ></app-text-edit>

      <app-text-edit
        [(isDirty)]="childIsDirty[2]"
        [label]="'CompanyGeneral.IBAN' | translate"
        [editMode]="editMode"
        [(value)]="company.IbanNo"
        (valueChange)="onChange()"
      ></app-text-edit>

      <app-text-edit
        [(isDirty)]="childIsDirty[3]"
        [label]="'CompanyGeneral.BicSWIFT' | translate"
        [editMode]="editMode"
        [(value)]="company.BicSwiftNo"
        (valueChange)="onChange()"
      ></app-text-edit>

      <app-text-edit
        [(isDirty)]="childIsDirty[4]"
        [label]="
          IsSwedenCompany === true ? ('CompanyGeneral.CVRnoSE' | translate) : ('CompanyGeneral.CVRno' | translate)
        "
        [required]="true"
        [editMode]="editMode"
        [type]="'number'"
        [(isValid)]="childIsValid[2]"
        [(value)]="company.VATRegistrationNumber"
        (valueChange)="onChange()"
      ></app-text-edit>

      <app-text-edit
        *ngIf="!IsSwedenCompany"
        [(isDirty)]="childIsDirty[5]"
        [label]="'CompanyGeneral.SENo' | translate"
        [editMode]="editMode"
        [type]="'number'"
        [(value)]="company.SecondaryVATRegistrationNumber"
        [tooltipContent]="'CompanyGeneral.SENoTooltip' | translate: { SystemAlias: branding.SystemAlias }"
        (valueChange)="onChange()"
      ></app-text-edit>

      <app-text-edit
        [(isDirty)]="childIsDirty[6]"
        [label]="'CompanyGeneral.EAN' | translate"
        [editMode]="editMode"
        [type]="'number'"
        [(value)]="company.InvoiceIdentifier"
        (valueChange)="onChange()"
      ></app-text-edit>

      <app-combobox-edit
        [(isDirty)]="childIsDirty[7]"
        [label]="'CompanyGeneral.TaxCategory' | translate"
        *ngIf="!isGreenland && !IsSwedenCompany"
        [editMode]="editMode"
        [comboboxDataSource]="taxCategories"
        [textField]="'Name'"
        [idField]="'Id'"
        [(value)]="company.CompanyTypeId"
        (valueChange)="onChange()"
      >
      </app-combobox-edit>

      <app-combobox-edit
        [(isDirty)]="childIsDirty[8]"
        [label]="'CompanyGeneral.DefaultTaxTable' | translate"
        *ngIf="IsSwedenCompany"
        [editMode]="editMode"
        [comboboxDataSource]="taxTables"
        [textField]="'Name'"
        [idField]="'Id'"
        [(value)]="company.DefaultTaxTableId"
        (valueChange)="onChange()"
      >
      </app-combobox-edit>
    </fieldset>
  </form>

  <app-dialog
    [(visible)]="isWarningVivible"
    (action)="onWarningAction($event)"
    [leadingText]="'CompanyGeneral.EditBankAccountNoWarning' | translate"
  >
    <app-dialog-action [type]="'Cancel'"></app-dialog-action>
    <app-dialog-action [type]="'Understood'"></app-dialog-action>
  </app-dialog>
</div>
