/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../module-check/module-check.component.ngfactory";
import * as i2 from "../module-check/module-check.component";
import * as i3 from "../../services/session/session.service";
import * as i4 from "../../services/api/static-data.service";
import * as i5 from "../../services/api/data.service";
import * as i6 from "../../services/authentication/authentication.service";
import * as i7 from "../pipes/price-format.pipe";
import * as i8 from "@angular/common";
import * as i9 from "@progress/kendo-angular-tooltip";
import * as i10 from "@progress/kendo-angular-popup";
import * as i11 from "../../../../node_modules/@progress/kendo-angular-dialog/dist/es2015/index.ngfactory";
import * as i12 from "@progress/kendo-angular-dialog";
import * as i13 from "@progress/kendo-angular-l10n";
import * as i14 from "./dialog.component";
import * as i15 from "@angular/platform-browser";
var styles_DialogComponent = [];
var RenderType_DialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogComponent, data: {} });
export { RenderType_DialogComponent as RenderType_DialogComponent };
function View_DialogComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.leadingTextValue; _ck(_v, 1, 0, currVal_0); }); }
function View_DialogComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "app-module-check", [], null, null, null, i1.View_ModuleCheckComponent_0, i1.RenderType_ModuleCheckComponent)), i0.ɵdid(1, 245760, null, 0, i2.ModuleCheckComponent, [i3.SessionService, i4.StaticDataService, i5.DataService, i6.AuthenticationService, i7.PriceFormatPipe], { moduleId: [0, "moduleId"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 4, "button", [["kendoButton", ""], ["kendoTooltip", ""], ["type", "button"]], [[8, "id", 0], [8, "disabled", 0], [8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.parent.context.$implicit.action, _v.parent.context.$implicit.close) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i8.ɵNgClassImpl, i8.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(4, 278528, null, 0, i8.NgClass, [i8.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(5, 9125888, null, 0, i9.TooltipDirective, [i0.ElementRef, i0.NgZone, i0.Renderer2, i10.PopupService, [2, i9.TooltipSettings], [2, i9.TOOLTIP_SETTINGS]], null, null), (_l()(), i0.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.requiredModuleId; _ck(_v, 1, 0, currVal_0); var currVal_4 = _v.parent.context.$implicit.class; _ck(_v, 4, 0, currVal_4); _ck(_v, 5, 0); }, function (_ck, _v) { var currVal_1 = ("DialogButton_" + _v.parent.context.$implicit.action); var currVal_2 = _v.parent.context.$implicit.disabled; var currVal_3 = (_v.parent.context.$implicit.tooltip || ""); _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); var currVal_5 = _v.parent.context.$implicit.label; _ck(_v, 6, 0, currVal_5); }); }
function View_DialogComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["autofocus", ""], ["kendoButton", ""], ["type", "button"]], [[8, "id", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.parent.context.$implicit.action, _v.parent.context.$implicit.close) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i8.ɵNgClassImpl, i8.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i8.NgClass, [i8.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.class; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = ("DialogButton_" + _v.parent.context.$implicit.action); var currVal_1 = _v.parent.context.$implicit.disabled; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.context.$implicit.label; _ck(_v, 3, 0, currVal_3); }); }
function View_DialogComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["kendoTooltip", ""]], [[8, "title", 0]], null, null, null, null)), i0.ɵdid(1, 9125888, null, 0, i9.TooltipDirective, [i0.ElementRef, i0.NgZone, i0.Renderer2, i10.PopupService, [2, i9.TooltipSettings], [2, i9.TOOLTIP_SETTINGS]], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogComponent_5)), i0.ɵdid(3, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogComponent_6)), i0.ɵdid(5, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { _ck(_v, 1, 0); var currVal_1 = _v.context.$implicit.requiredModuleId; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_v.context.$implicit.requiredModuleId; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.tooltip || ""); _ck(_v, 0, 0, currVal_0); }); }
function View_DialogComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "kendo-dialog-actions", [["class", "Modal-footer"]], [[2, "k-dialog-buttongroup", null], [2, "k-dialog-button-layout-stretched", null]], null, null, i11.View_DialogActionsComponent_0, i11.RenderType_DialogActionsComponent)), i0.ɵdid(1, 49152, [[3, 4], ["buttonArea", 4]], 0, i12.DialogActionsComponent, [i0.ElementRef], null, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_DialogComponent_4)), i0.ɵdid(3, 278528, null, 0, i8.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.buttons; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).buttonGroupClassName; var currVal_1 = i0.ɵnov(_v, 1).className; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "kendo-dialog", [], [[1, "dir", 0], [1, "tabIndex", 0], [2, "k-dialog-wrapper", null]], [[null, "close"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onComponentKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.onClose() !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (_co.onkeydown($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i11.View_DialogComponent_0, i11.RenderType_DialogComponent)), i0.ɵprd(256, null, i13.L10N_PREFIX, "kendo.dialog", []), i0.ɵprd(131584, null, i13.LocalizationService, i13.LocalizationService, [i13.L10N_PREFIX, [2, i13.MessageService], [2, i13.RTL]]), i0.ɵdid(3, 5488640, null, 1, i12.DialogComponent, [i0.ElementRef, i0.Renderer2, i13.LocalizationService], { title: [0, "title"], width: [1, "width"], minWidth: [2, "minWidth"], height: [3, "height"] }, { close: "close" }), i0.ɵqud(335544320, 4, { titlebarContent: 0 }), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_DialogComponent_2)), i0.ɵdid(6, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵncd(1, 0), (_l()(), i0.ɵand(16777216, null, 2, 1, null, View_DialogComponent_3)), i0.ɵdid(9, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.title || " "); var currVal_4 = _co.width; var currVal_5 = _co.minWidth; var currVal_6 = _co.height; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.leadingTextValue; _ck(_v, 6, 0, currVal_7); var currVal_8 = ((!_co.isAvatar && _co.buttons) && (_co.buttons.length > 0)); _ck(_v, 9, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).dir; var currVal_1 = i0.ɵnov(_v, 3).tabIndex; var currVal_2 = i0.ɵnov(_v, 3).wrapperClass; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_DialogComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[2, 0], ["iframeInDialog", 1]], null, 0, "iframe", [["class", "k-content-frame"], ["height", "700"], ["src", "about:blank"], ["width", "960"]], [[8, "srcdoc", 1], [8, "scrolling", 0]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.iframeLoaded() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.safeContent; var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.isIframeScrolling, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DialogComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[2, 0], ["iframeInDialog", 1]], null, 0, "iframe", [["class", "k-content-frame"], ["height", "700"], ["width", "960"]], [[8, "scrolling", 0]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.iframeLoaded() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.isIframeScrolling, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_DialogComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "kendo-dialog", [], [[1, "dir", 0], [1, "tabIndex", 0], [2, "k-dialog-wrapper", null]], [[null, "close"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onComponentKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.onClose() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i11.View_DialogComponent_0, i11.RenderType_DialogComponent)), i0.ɵprd(256, null, i13.L10N_PREFIX, "kendo.dialog", []), i0.ɵprd(131584, null, i13.LocalizationService, i13.LocalizationService, [i13.L10N_PREFIX, [2, i13.MessageService], [2, i13.RTL]]), i0.ɵdid(3, 5488640, [[1, 4], ["iframeDialog", 4]], 1, i12.DialogComponent, [i0.ElementRef, i0.Renderer2, i13.LocalizationService], { title: [0, "title"], width: [1, "width"], minWidth: [2, "minWidth"], height: [3, "height"] }, { close: "close" }), i0.ɵqud(335544320, 5, { titlebarContent: 0 }), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_DialogComponent_8)), i0.ɵdid(6, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_DialogComponent_9)), i0.ɵdid(8, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.title || " "); var currVal_4 = _co.width; var currVal_5 = _co.minWidth; var currVal_6 = _co.height; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = !_co.isEdge; _ck(_v, 6, 0, currVal_7); var currVal_8 = _co.isEdge; _ck(_v, 8, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).dir; var currVal_1 = i0.ɵnov(_v, 3).tabIndex; var currVal_2 = i0.ɵnov(_v, 3).wrapperClass; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_DialogComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { iframeDialog: 0 }), i0.ɵqud(671088640, 2, { iframe: 0 }), i0.ɵqud(671088640, 3, { buttonArea: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogComponent_1)), i0.ɵdid(4, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogComponent_7)), i0.ɵdid(6, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible && !_co.safeContent); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.visible && _co.safeContent); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_DialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-dialog", [], null, null, null, View_DialogComponent_0, RenderType_DialogComponent)), i0.ɵdid(1, 49152, null, 1, i14.DialogComponent, [i15.DomSanitizer, i3.SessionService, i0.ElementRef], null, null), i0.ɵqud(603979776, 1, { buttonElements: 1 })], null, null); }
var DialogComponentNgFactory = i0.ɵccf("app-dialog", i14.DialogComponent, View_DialogComponent_Host_0, { title: "title", minWidth: "minWidth", width: "width", height: "height", isAvatar: "isAvatar", customVisible: "customVisible", overlayClose: "overlayClose", isOverlayKeydown: "isOverlayKeydown", isHideWhenSessionExpired: "isHideWhenSessionExpired", disableCloseButtonTitle: "disableCloseButtonTitle", leadingText: "leadingText", mustChangePassword: "mustChangePassword", visible: "visible", iframeContent: "iframeContent" }, { visibleChange: "visibleChange", action: "action" }, ["*"]);
export { DialogComponentNgFactory as DialogComponentNgFactory };
