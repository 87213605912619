import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CompositeFilterDescriptor, distinct, filterBy } from '@progress/kendo-data-query';
import { Transition, TransitionService } from '@uirouter/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FilterDateTimeGrid } from '../../common/filter-date-time-grid';
import { Global } from '../../common/global';
import {
  Company,
  IAccount,
  IAccountInvoiceType,
  ICompany,
  ICompanyAccountView,
  IRole,
  ISimpleCompany,
  IUserCompanyAccess,
  UserAccessPermissionsRequest
} from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import { ReportDialogService } from '../../shared-components/report-dialog/report-dialog.service';
import { AccountantCompaniesService } from './accountant-companies.service';

@Component({
  selector: 'app-accountant-companies',
  templateUrl: './accountant-companies.component.html',
  styleUrls: ['./accountant-companies.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountantCompaniesComponent implements OnInit, OnDestroy {
  public discardDialogVisible = false;
  public addNewCompanyDialogVisible = false;
  public createSuccessConfirm = false;
  public removeSuccessConfirm = false;
  public newCompanyId: number;
  public dataRoleList: IUserCompanyAccess[] = [];
  public dataList: ICompanyAccountView[] = [];
  public countryCodes: any[];
  public accountName = '';
  public attachexistingcompanyVisable = false;
  public removeCompanyDialogVisible = false;

  public userCompanyUserViews: any[];
  public userCompanyUserViewsFilter: any[];

  public selectUserCompanyUserViews: number;
  public isUnderStoodTheAbove = false;
  public isCompleteAttached = false;
  public isFirstLoad = false;
  public AccountInvoiceTypes: IAccountInvoiceType[] = [];
  public triggerUpdate = false;
  public addNewUserVisible = false;
  public confimTermVisible = false;
  public pageSize = 50;
  public listClassExcluded = ['Footer', '10'];
  public currentPageAttachCompany = 0;

  private BaseSalaryAdminIdOption: number;
  private BaseFullAdminIdOption: number;

  public get isPagingAvailable(): boolean {
    if (this.gridFilterData && this.gridFilterData.length > this.pageSize) {
      return true;
    }
    return false;
  }

  public get isPagingUserAvailable(): boolean {
    if (this.gridRoleFilterData && this.gridRoleFilterData.length > this.pageSize) {
      return true;
    }
    return false;
  }

  public get isIOSApp(): boolean {
    return this.sessionService.browser.isHybridApp && this.sessionService.browser.iOSMobileDevice;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public get isAccountantAdmin(): boolean {
    return this.sessionService.role.IsMultiCompanyAdmin;
  }

  public get branding() {
    return environment.branding;
  }

  public get isRemoveCompany(): boolean {
    return (
      this.sessionService.role.IsMultiCompanyAdmin &&
      this.service.selectedId !== null &&
      this.service.selectedId !== undefined
    );
  }

  public get isShowComment() {
    if (
      (this.service.isBasicUser && !this.service.company.AccountComment) ||
      (this.service.isBasicUser && Object.keys(this.service.company).length === 0)
    ) {
      return false;
    }
    return true;
  }

  public get activeTab(): string {
    return this.sessionService.currentState;
  }

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  constructor(
    public service: AccountantCompaniesService,
    public staticData: StaticDataService,
    private api: DataService,
    private staticDataService: StaticDataService,
    public sessionService: SessionService,
    private dataService: DataService,
    private transitionService: TransitionService,
    public settingService: SettingService,
    public reportDialogService: ReportDialogService
  ) {
    this.service.readloadUserConpanyRolChange.subscribe((value: boolean): void => {
      if (value) {
        this.staticDataService.loadCompaniesSimpleAsync();
        this.initFilterData();
      }
    });

    this.filterAttachCompanySubject
      .asObservable()
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((value: string) => this.applyFilterAttachCompany(value));

    this.loadRole();
    this.initFilterData();

    this.staticData.companiesSimple.subscribe((data: ISimpleCompany[]) => {
      if (data && data.length > 0) {
        this.userCompanyUserViews = data.filter(
          (model: any) =>
            model.RoleId === 90 &&
            (!model.AccountId || (model.AccountId && model.AccountId !== Global.SESSION.SignOnToken.UserAccountId))
        );

        this.userCompanyUserViewsFilter = filterBy(this.userCompanyUserViews, this.attackCompanyFilter);
      }
    });
  }

  private filterAttachCompanyValue: string;
  public get filterAttachCompany(): string {
    return this.filterAttachCompanyValue;
  }
  public set filterAttachCompany(value: string) {
    this.filterAttachCompanyValue = value;
    this.filterAttachCompanySubject.next(value);
  }
  private filterAttachCompanySubject: BehaviorSubject<string> = new BehaviorSubject('');

  private applyFilterAttachCompany(val: string) {
    if (val) {
      this.userCompanyUserViewsFilter = this.userCompanyUserViews.filter((item: any) => {
        return (
          (item.Id + '').includes(val) ||
          item.Name.includes(val) ||
          (item.VatRegistrationNumber + '').includes(val) ||
          (item.SecondaryVatRegistrationNumber + '').includes(val)
        );
      });
    } else {
      this.userCompanyUserViewsFilter = this.userCompanyUserViews;
    }
    this.currentPageAttachCompany = 0;
    this.selectUserCompanyUserViews = null;
  }

  private loadRole(): void {
    this.service.roles.subscribe((data) => {
      this.dataRoleList = data;
      this.dataRoleList.sort((a, b) => a.UserId - b.UserId);
      this.gridRoleFilterData = filterBy(this.dataRoleList, this.roleFilter);
    });
  }

  private initFilterData() {
    this.service.companies.subscribe((data) => {
      this.staticDataService.companiesSimple.subscribe((myCompany: ISimpleCompany[] | any) => {
        this.staticDataService.Role.subscribe((statisticOptions: IRole[]): void => {
          const roleList: any = {};
          statisticOptions.forEach((option) => {
            roleList[option.Id] = option.Name;
          });

          myCompany.forEach((company: any) => {
            company.UserRoleName = roleList[company.RoleId];
          });

          const list = data.map((val: ICompanyAccountView | any) => ({
            Name: val.CountryCode,
            Id: val.CountryId,
            Key: val.CountryCode
          }));

          this.countryCodes = distinct(list, 'Name');
          this.dataList = data;
          this.dataList.forEach((model: ICompanyAccountView | any) => {
            const companyModel = myCompany.find((item: any) => {
              return item.Id === model.Id;
            });
            if (companyModel) {
              model.UserRoleName = companyModel.UserRoleName;
              model.BlockingNavigate = false;
              model.UserRoleId = companyModel.RoleId;
              model.BlockingNavigateTooltip = '';
            } else {
              model.UserRoleName = '';
              model.BlockingNavigate = true;
              model.UserRoleId = null;
              model.BlockingNavigateTooltip = 'Accountant.BlockingNavigate';
            }
          });

          this.triggerUpdate = true;

          if (this.filter && this.filter.filters && this.filter.filters.length > 0) {
            const filterDateTimeGrid = new FilterDateTimeGrid();
            const filtersDate: any = [];
            // Using FilterDateTimeGrid to filter date before filter another field
            this.filter = filterDateTimeGrid.convertFilterOperator(
              this.filter,
              filtersDate,
              'LastFinalized',
              'NextDeadline'
            );
            this.gridFilterData = filterDateTimeGrid.doFilterDate(
              this.dataList,
              filtersDate,
              'LastFinalized',
              'NextDeadline'
            );
            this.gridFilterData = filterBy(this.gridFilterData, this.filter);
            if (filtersDate.length > 0) {
              [].push.apply(this.filter.filters, filtersDate);
            }
          } else {
            this.gridFilterData = this.dataList;
          }
        });
      });
    });
  }

  public onDirtyChange(): void {
    this.service.isDirty = true;
  }

  public onRowDataChange(event: any): void {
    const model: UserAccessPermissionsRequest = {
      RoleId: event.dataItem.RoleId,
      UserId: event.dataItem.UserId,
      isPaymentApprover: event.dataItem.IsPaymentApprover || false,
      CompanyId: this.service.selectedId
    };
    if (!this.service.EditRoleList.find((item: UserAccessPermissionsRequest) => item.UserId === model.UserId)) {
      this.service.EditRoleList.push(model);
    } else {
      Object.assign(
        this.service.EditRoleList.find((item: UserAccessPermissionsRequest) => item.UserId === model.UserId),
        model
      );
    }
  }

  public onCompanyRowSave(event: any): void {
    // Update only InvoiceTypeId with patch method
    const dataModel: Company = new Company();
    dataModel.Id = event.dataItem.Id;
    dataModel.InvoiceTypeId = event.dataItem.InvoiceTypeId;
    dataModel.InvoiceEmail = event.dataItem.InvoiceEmail;

    if (!this.service.EditCompanyList.find((item: Company) => item.Id === dataModel.Id)) {
      this.service.EditCompanyList.push(dataModel);
    } else {
      Object.assign(this.service.EditCompanyList.find((item: Company) => item.Id === dataModel.Id), dataModel);
    }
  }

  private saveCompanyRows(): void {
    this.service.EditCompanyList.forEach((item: ICompany) => {
      this.dataService.Companies_PatchCompany(item).subscribe(
        (model: ICompany) => {
          Object.assign(item, model);
        },
        () => {
          this.service.loadData(false);
        }
      );
    });
  }

  public ngOnInit() {
    this.isFirstLoad = true;
    this.staticData.accountInvoiceTypes.pipe().subscribe((InvoiceType: IAccountInvoiceType[]) => {
      if (InvoiceType && InvoiceType.length > 0) {
        this.AccountInvoiceTypes = InvoiceType;
        this.triggerUpdate = true;
      }
    });

    this.staticData.currentAccount.subscribe((account: IAccount) => {
      this.accountName = account.Name;
    });

    this.transitionService.onStart({}, (transition: Transition) => {
      const fromState: string = transition.from().name;
      const toState = transition.to().name;
      this.service.navigationToStateName = JSON.parse(JSON.stringify(toState));

      if (this.service.editMode && fromState !== toState) {
        if (
          (this.service.EditCompanyList && this.service.EditCompanyList.length > 0) ||
          (this.service.EditRoleList && this.service.EditRoleList.length > 0) ||
          this.service.isDirty
        ) {
          this.service.showSaveDataConfirmationChangeState = true;
        } else {
          this.onEditModeChange(false);
          this.sessionService.NavigateTo(this.service.navigationToStateName);
          return true;
        }
        return false;
      }

      this.service.clearCompanySubject();
      return true;
    });

    this.staticData.Role.pipe().subscribe((role: IRole[]) => {
      if (role && role.length > 0) {
        this.BaseSalaryAdminIdOption = role.find((model: IRole) => model.Key === 'SalaryAdmin').Id;
        this.BaseFullAdminIdOption = role.find((model: IRole) => model.Key === 'FullAdmin').Id;
      }
    });
  }

  public onConfirmSaveDataOnChangeState(action: string): void {
    if (action === 'ContinueAndSave') {
      this.onEditModeChange(false);
      this.service.isDirty = false;
      this.sessionService.NavigateTo(this.service.navigationToStateName);
      return;
    }

    if (action === 'DiscardAndLeave') {
      this.service.discard();
      this.service.EditRoleList = [];
      this.service.EditCompanyList = [];
      this.service.isDirty = false;
      this.onEditModeChange(false);
      this.sessionService.NavigateTo(this.service.navigationToStateName);
      return;
    }
  }

  public changeCompany(companyId: number, companyuserrole?: number): void {
    this.settingService.ReloadWarrning = true;
    this.api.Auth_SetCompanyContext(companyId).subscribe(
      () => {
        Global.COMPANY_ID = companyId;
        this.staticDataService.loadStaticData().then(() => {
          this.api.Companies_GetCurrent().subscribe((activeCompany: ICompany): void => {
            Global.COMPANY = activeCompany;
            if (companyuserrole && companyuserrole >= 30) {
              this.sessionService.NavigateTo('tabs.company.salarybatches');
              setTimeout(() => {
                window.location.reload();
              });
            } else {
              window.location.reload();
            }
          });
        });
      },
      () => {
        this.settingService.ReloadWarrning = false;
      }
    );
  }

  public clickAppGridIcon(model: any): void {
    if (!model.BlockingNavigate) {
      this.changeCompany(model.Id, model.UserRoleId);
      // if (model.UserRoleId && model.UserRoleId >= 30) {
      //   this.sessionService.NavigateTo('tabs.company.salarybatches');
      // }
    }
  }

  public onMoreMenuOptionClick(menu: string): void {
    if (menu && menu === 'accountant_attachexistingcompany') {
      this.attachexistingcompanyVisable = true;
    }
    if (menu && menu === 'accountant_removecompany') {
      this.removeCompanyDialogVisible = true;
    }
  }

  public onEditModeChange($event: boolean): void {
    if (this.service.isBasicUser) {
      return;
    }
    if ($event && (this.sessionService.isGetting || this.sessionService.isSubmitting)) {
      return;
    }
    if (!this.service.selectedId) {
      if (this.gridFilterData && this.gridFilterData.length > 0) {
        this.service.selectedId = this.gridFilterData[0].Id;
        this.onSelectedIdChange(this.gridFilterData[0].Id);
      } else {
        return;
      }
    }
    if (this.service.editMode) {
      if ((this.service.EditRoleList && this.service.EditRoleList.length > 0) || this.service.isDirty) {
        this.service.save(this.service.EditRoleList);
        this.service.isDirty = false;
        this.service.EditRoleList = [];
      }

      if (this.service.EditCompanyList && this.service.EditCompanyList.length > 0) {
        this.saveCompanyRows();
        this.service.EditCompanyList = [];
      }
    }

    this.service.editMode = $event;
  }

  public tabDblclick(): void {
    if (this.service.editMode) {
      return;
    }
    this.onEditModeChange(true);
  }

  public gridDblclick($event: any): void {
    this.onEditModeChange(true);
  }

  public confirmAndDiscardChanges(): void {
    if ((this.service.EditRoleList && this.service.EditRoleList.length > 0) || this.service.isDirty) {
      this.discardDialogVisible = true;
      return;
    }
    this.onEditModeChange(false);
  }

  public discardDialogAction(action: string): void {
    if (action === 'ConfirmDiscard') {
      this.service.discard();
      this.service.EditRoleList = [];
      this.service.EditCompanyList = [];
      this.service.isDirty = false;
      this.onEditModeChange(false);
    }
  }

  public roleFilter: CompositeFilterDescriptor;
  public gridRoleFilterData: IUserCompanyAccess[] = this.dataRoleList;
  public onRoleFilterChange(filter: CompositeFilterDescriptor): void {
    this.roleFilter = filter;
    this.gridRoleFilterData = filterBy(this.dataRoleList, filter);
  }

  public attackCompanyFilter: CompositeFilterDescriptor;
  public onAttackCompanyFilterChange(filter: CompositeFilterDescriptor): void {
    this.selectUserCompanyUserViews = null;
    this.attackCompanyFilter = filter;
    this.userCompanyUserViewsFilter = filterBy(this.userCompanyUserViews, filter);
  }

  public filter: CompositeFilterDescriptor;
  public gridFilterData: ICompanyAccountView[] = this.dataList;
  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    if (filter && filter.filters && filter.filters.length > 0) {
      const filterDateTimeGrid = new FilterDateTimeGrid();
      const filtersDate: any = [];
      // Using FilterDateTimeGrid to filter date before filter another field
      this.filter = filterDateTimeGrid.convertFilterOperator(this.filter, filtersDate, 'LastFinalized', 'NextDeadline');
      this.gridFilterData = filterDateTimeGrid.doFilterDate(
        this.dataList,
        filtersDate,
        'LastFinalized',
        'NextDeadline'
      );
      this.gridFilterData = filterBy(this.gridFilterData, this.filter);
      if (filtersDate.length > 0) {
        [].push.apply(this.filter.filters, filtersDate);
      }
    } else {
      this.gridFilterData = this.dataList;
    }
  }

  public onSelectedIdChange(selectedId: any): void {
    if (
      this.service.editMode &&
      ((this.service.EditRoleList && this.service.EditRoleList.length > 0) || this.service.isDirty)
    ) {
      this.service.save(this.service.EditRoleList);
      this.service.isDirty = false;
      this.service.EditRoleList = [];
    }
    this.service.getRoleServicePublic();
    if (!this.isFirstLoad) {
      this.gridRoleFilterData = [];
      this.service.EditRoleList = [];
      this.service.isDirty = false;
    }
    this.isFirstLoad = false;
  }

  public onCreateSuccess(event: any): void {
    if (event) {
      this.newCompanyId = event;
      this.createSuccessConfirm = true;
    }
  }

  public onDoneCreateSuccess(event: any): void {
    this.settingService.ReloadWarrning = true;
    if (event) {
      if (event === 'Yes') {
        this.changeCompany(this.newCompanyId);
      } else {
        window.location.reload();
      }
    }
  }

  public onDoneRemoveSuccess(event: any): void {
    this.settingService.ReloadWarrning = true;
    if (event) {
      if (event === 'Ok') {
        window.location.reload();
      }
    }
  }

  public onAttachingAnExistingCompany(event: any): void {
    if (event) {
      if (event === 'Submit') {
        if (!this.isUnderStoodTheAbove) {
          this.confimTermVisible = true;
          return;
        }
        this.api.Account_AttachCompany(this.selectUserCompanyUserViews).subscribe(() => {
          this.isCompleteAttached = true;
          this.resetAttackCompanyDialog();
        });
      }

      if (event === 'close') {
        this.resetAttackCompanyDialog();
      }
    }
  }

  private resetAttackCompanyDialog() {
    this.isUnderStoodTheAbove = false;
    this.selectUserCompanyUserViews = null;
    this.filterAttachCompany = '';
  }

  public onRemoveCompany(event: any): void {
    if (event && event === 'Delete') {
      this.dataService.Account_RemoveCompany(this.service.selectedId).subscribe(() => {
        this.removeSuccessConfirm = true;
      });
    }
  }

  public ReplayMoreMenuLabelAttachingAnExistingCompany(message: string): string {
    return message.replace(/{AccountName}/g, this.accountName);
  }

  public onUserRoleSelected(event: any): void {
    // if (this.service.editMode && event) {
    //   const originApprover: boolean = JSON.parse(JSON.stringify(event.IsPaymentApprover));
    //   if (event.RoleId === this.BaseFullAdminIdOption || event.RoleId === this.BaseSalaryAdminIdOption) {
    //     event.IsPaymentApprover = true;
    //   } else {
    //     event.IsPaymentApprover = false;
    //   }
    //   if (originApprover !== event.IsPaymentApprover) {
    //     const onChangeData: any = {};
    //     onChangeData.dataItem = event;
    //     this.onRowDataChange(onChangeData);
    //   }
    // }
  }

  public onGridRoleControlDataChange(event: any): void {
    if (event && event.field === 'RoleId') {
      if (
        event.dataItem.RoleId === this.BaseFullAdminIdOption ||
        event.dataItem.RoleId === this.BaseSalaryAdminIdOption
      ) {
        event.dataItem.IsPaymentApprover = true;
      } else {
        event.dataItem.IsPaymentApprover = false;
      }
    }

    this.onRowDataChange(event);
  }
}
