import { EventEmitter } from '@angular/core';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { FileUploadComponent } from '../file-upload/file-upload.component';
var PictureEditComponent = /** @class */ (function () {
    function PictureEditComponent(sessionService, staticDataService) {
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.takePhotoText = 'Take photo';
        this.change = new EventEmitter();
        this.imageSourceChange = new EventEmitter();
        this.errorDialogVisible = false;
    }
    Object.defineProperty(PictureEditComponent.prototype, "imageSource", {
        get: function () {
            return this.currentimageSource;
        },
        set: function (value) {
            this.currentimageSource = value;
            this.imageSourceChange.emit(this.currentimageSource);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PictureEditComponent.prototype, "isIOSMobileApp", {
        get: function () {
            return this.sessionService.browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PictureEditComponent.prototype, "isSafari", {
        get: function () {
            return this.sessionService.browser.isSafari;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PictureEditComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PictureEditComponent.prototype, "isHybridApp", {
        get: function () {
            return this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PictureEditComponent.prototype, "isAndroidDevice", {
        get: function () {
            return this.sessionService.browser.isAndroidDevice;
        },
        enumerable: true,
        configurable: true
    });
    PictureEditComponent.prototype.onChangePictureButtonClick = function () {
        this.fileUpload.addFile();
    };
    PictureEditComponent.prototype.onTakePhotoButtonClick = function () {
        if (this.isHybridApp && this.isAndroidDevice) {
            var otps = {};
            navigator.camera.getPicture(this.onPictureSuccess, this.onPictureFail, otps);
        }
    };
    PictureEditComponent.prototype.onPictureSuccess = function (imageData) {
        var name = new Date().getTime().toString();
        var type = 'image/jpeg';
        var blob = this.staticDataService.dataURItoBlob(imageData, type);
        var file = new File([blob], 'image_'.concat(name), { type: type });
        var formData = new FormData();
        formData.append('file', file, 'image_'.concat(name));
        this.change.emit(formData);
    };
    PictureEditComponent.prototype.onPictureFail = function () { };
    PictureEditComponent.prototype.onFileSelected = function (file) {
        var maxSize = 1024 * 1024 * 4;
        if (file.size > maxSize) {
            this.errorDialogVisible = true;
            return;
        }
        var formData = new FormData();
        formData.append('file', file, file.name);
        this.change.emit(formData);
    };
    return PictureEditComponent;
}());
export { PictureEditComponent };
