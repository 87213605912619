import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Global } from 'src/app/common/global';
import { DataService } from 'src/app/services/api/data.service';
import { SessionService } from 'src/app/services/session/session.service';
import { CompanyAdvancedTypesService } from '../company-advanced-types.service';
import { AdvancedTimeEntryTypesService } from './advanced-time-entry-types.service';
var TimeEntryTypesSingleComponent = /** @class */ (function () {
    function TimeEntryTypesSingleComponent(dataService, translateService, sessionService, advancedTimeEntryTypesService, companyAdvancedTypesService) {
        var _this = this;
        this.dataService = dataService;
        this.translateService = translateService;
        this.sessionService = sessionService;
        this.advancedTimeEntryTypesService = advancedTimeEntryTypesService;
        this.companyAdvancedTypesService = companyAdvancedTypesService;
        this.childIsDirty = [];
        this.childIsValid = [];
        this.translationDialogVisible = false;
        this.unitTypes = Global.UNIT_TYPES;
        this.editClickAction = new EventEmitter();
        this.addClickAction = new EventEmitter();
        this.inheritDefaultValue = '<Nedarvet, klik for at overstyre>';
        this.ngUnsubscribe = new Subject();
        this.reloadListData = new EventEmitter();
        this.advancedTimeEntryTypesService.addNewShortcutChange
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && !_this.sessionService.role.IsReadOnly) {
                _this.addClickAction.emit();
            }
        });
        this.advancedTimeEntryTypesService.editShortcutChange
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value &&
                _this.sessionService.role.IsFullAdmin &&
                !_this.sessionService.isGetting &&
                !_this.companyAdvancedTypesService.editMode) {
                _this.editClickAction.emit(true);
            }
        });
        this.resetDirty();
    }
    Object.defineProperty(TimeEntryTypesSingleComponent.prototype, "isLanguageModuleEnable", {
        get: function () {
            return this.sessionService.feature.hasModuleId(5);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeEntryTypesSingleComponent.prototype, "isDisableSalaryType", {
        get: function () {
            return (this.companyAdvancedTypesService.selectedType.TimeEntryTypeConfigurations &&
                this.companyAdvancedTypesService.selectedType.TimeEntryTypeConfigurations.length > 0);
        },
        enumerable: true,
        configurable: true
    });
    TimeEntryTypesSingleComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (Global.SESSION.SignOnToken.LanguageId === 2) {
            this.inheritDefaultValue = '<Inherited, click to override>';
        }
        else {
            this.inheritDefaultValue = '<Nedarvet, klik for at overstyre>';
        }
        this.dataService.Integrations_GetAllExternalAccounts().subscribe(function (externalAccounts) {
            _this.externalAccounts = externalAccounts;
            if (_this.externalAccounts && _this.externalAccounts.length > 0) {
                _this.externalAccounts.forEach(function (model) {
                    model.FriendlyName = model.AccountNumber + (model.AccountName ? ' - ' + model.AccountName : '');
                });
            }
        });
    };
    TimeEntryTypesSingleComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    TimeEntryTypesSingleComponent.prototype.onReloadTimeEntryTypes = function () {
        this.reloadListData.emit(true);
    };
    Object.defineProperty(TimeEntryTypesSingleComponent.prototype, "disableSalaryType", {
        get: function () {
            if (this.companyAdvancedTypesService.selectedType.TimeEntryTypeConfigurations &&
                this.companyAdvancedTypesService.selectedType.TimeEntryTypeConfigurations.length > 0) {
                return this.companyAdvancedTypesService.selectedType.salaryTypeClassSingle + ', disable-class';
            }
            return this.companyAdvancedTypesService.selectedType.salaryTypeClassSingle;
        },
        enumerable: true,
        configurable: true
    });
    TimeEntryTypesSingleComponent.prototype.onUpdateTimeEntryTypeConfiguration = function (timeEntryTypeConfigurations) {
        if (timeEntryTypeConfigurations) {
            this.companyAdvancedTypesService.selectedType.TimeEntryTypeConfigurations = timeEntryTypeConfigurations;
            if (timeEntryTypeConfigurations.length > 0) {
                this.companyAdvancedTypesService.selectedType.SalaryTypeId = null;
            }
            else if (timeEntryTypeConfigurations.isDeleteAllData) {
                this.reloadListData.emit(true);
            }
        }
    };
    TimeEntryTypesSingleComponent.prototype.onChange = function (event, field, index) {
        if (field) {
            this.companyAdvancedTypesService.onChangeInheritValue(field, event, index);
        }
        this.companyAdvancedTypesService.tabDirty = this.childIsDirty.indexOf(true) >= 0;
    };
    TimeEntryTypesSingleComponent.prototype.onTranslationConfigrationClicked = function () {
        this.translationDialogVisible = true;
    };
    TimeEntryTypesSingleComponent.prototype.resetDirty = function () {
        this.childIsDirty = Array(8).fill(false);
        this.childIsValid = Array(3).fill(false);
        this.companyAdvancedTypesService.tabDirty = false;
    };
    return TimeEntryTypesSingleComponent;
}());
export { TimeEntryTypesSingleComponent };
