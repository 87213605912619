/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@progress/kendo-angular-tooltip";
import * as i2 from "@progress/kendo-angular-popup";
import * as i3 from "@angular/common";
import * as i4 from "./button.component";
import * as i5 from "../../services/session/session.service";
var styles_ButtonComponent = [];
var RenderType_ButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ButtonComponent, data: {} });
export { RenderType_ButtonComponent as RenderType_ButtonComponent };
export function View_ButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [["componentId", 1]], null, 6, "span", [["kendoTooltip", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 9125888, null, 0, i1.TooltipDirective, [i0.ElementRef, i0.NgZone, i0.Renderer2, i2.PopupService, [2, i1.TooltipSettings], [2, i1.TOOLTIP_SETTINGS]], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 4, "button", [["type", "button"]], [[8, "title", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(4, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(5, { "is-disabled": 0 }), (_l()(), i0.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_3 = i0.ɵinlineInterpolate(1, "FormElement-cursorHand ", _co.buttonClass, ""); var currVal_4 = _ck(_v, 5, 0, _co.isDisabled); _ck(_v, 4, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", i0.ɵnov(_v, 0), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.tooltip; var currVal_2 = _co.isDisabled; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_5 = _co.label; _ck(_v, 6, 0, currVal_5); }); }
export function View_ButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-button", [], null, null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i0.ɵdid(1, 49152, null, 0, i4.ButtonComponent, [i5.SessionService], null, null)], null, null); }
var ButtonComponentNgFactory = i0.ɵccf("app-button", i4.ButtonComponent, View_ButtonComponent_Host_0, { tooltip: "tooltip", label: "label", buttonClass: "buttonClass", isUseOneClick: "isUseOneClick", isDisableSubmitOnly: "isDisableSubmitOnly", isDisabled: "isDisabled" }, { clickEvent: "clickEvent" }, []);
export { ButtonComponentNgFactory as ButtonComponentNgFactory };
