import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { StateService, TransitionService } from '@uirouter/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Constants } from 'src/app/common/constants';
import { environment } from '../../../environments/environment';
import { Browser } from '../../common/browser';
import { Global } from '../../common/global';
import { Role } from './role';
import { RoleFeatures } from './role-features';
import Globalize from 'globalize';
import 'devextreme/localization/globalize/currency';
import 'devextreme/localization/globalize/date';
import 'devextreme/localization/globalize/message';
import 'devextreme/localization/globalize/number';
import daMessages from 'devextreme/localization/messages/de.json';
import enMessages from 'devextreme/localization/messages/en.json';
import seMessages from 'devextreme/localization/messages/sv.json';
// Common and language-specific CLDR JSONs
import deCldrData from 'devextreme-cldr-data/da.json';
import enCldrData from 'devextreme-cldr-data/en.json';
import supplemental from 'devextreme-cldr-data/supplemental.json';
import svCldrData from 'devextreme-cldr-data/sv.json';
import { RouterStateService } from 'src/app/common/router-state.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@progress/kendo-angular-intl";
import * as i3 from "@uirouter/core";
import * as i4 from "../../common/router-state.service";
var SessionService = /** @class */ (function () {
    function SessionService(translateService, localeId, intlService, transitionService, stateService, routerState) {
        var _this = this;
        this.translateService = translateService;
        this.localeId = localeId;
        this.intlService = intlService;
        this.transitionService = transitionService;
        this.stateService = stateService;
        this.routerState = routerState;
        this.browser = new Browser();
        this.role = new Role();
        this.feature = new RoleFeatures(this.role);
        this.isSubmitting = false;
        this.isGetting = false;
        this.currentState = '';
        this.menuToggled = false;
        this.showMoreMenuToggled = false;
        this.showHelpMenuToggled = false;
        this.selectedId = true;
        this.showMenuToggled = false;
        this.employeeListActive = false;
        this.currentStyleMenuTabpanelReponsive = '';
        this.isApprovalMessage = false;
        this.employeeTabState = '';
        this.companyTabState = '';
        this.selfServiceTabState = '';
        this.isShowHugeFeaturesAlert = false;
        this.nextState = '';
        this.checkIsAliveActive = false;
        this.isDetectedCookieDisable = false;
        this.isBackToLogin = false;
        this.redirectToApp = false;
        this.restoreDefault = true;
        this.zenDeskOpen = false;
        this.checkIsAliveErrorValue = false;
        this.checkIsAliveErrorChange = new EventEmitter();
        this.modalTranslationsValue = {};
        this.modalTranslationsChangeSubject = new BehaviorSubject({});
        this.translateChangeSubject = new BehaviorSubject('unknown');
        this.locationChangedSubject = new BehaviorSubject('');
        this.locationChangeStartSubject = new BehaviorSubject({});
        this.isAuthenticationKey = 'isAuthenticationKey';
        this.isAliveSubject = new Subject();
        this.dateFormat = 'dd-MM-yyyy'; // GS-5677 use only 'da' format
        this.requestLog = [];
        this.requestLogEnabledValue = undefined;
        this.getStartedHiddenSubject = new BehaviorSubject(true);
        this.OnTranslateChanged.subscribe(function (locale) {
            if (locale === 'unknown') {
                if (!environment.production) {
                    console.log('intlService.localeId not changed to', locale);
                }
            }
            else {
                _this.localeId = _this.applyLocale(locale);
                _this.intlService.localeId = 'da'; // Apply for GS-4780
            }
            _this.loadGlobalize();
        });
        this.transitionService.onSuccess({}, function (transition) {
            _this.currentState = transition.to().name;
            if (_this.currentState !== 'tabs.employee' && _this.currentState.includes('tabs.employee')) {
                _this.employeeTabState = _this.currentState;
            }
            else if (_this.currentState !== 'tabs.company' && _this.currentState.includes('tabs.company')) {
                _this.companyTabState = _this.currentState;
            }
            else if (_this.currentState !== 'tabs.selfservice' && _this.currentState.includes('tabs.selfservice')) {
                _this.selfServiceTabState = _this.currentState;
            }
            // if (environment.environment === 'DEV') {
            //   console.log('state: ', this.currentState);
            // }
            _this.locationChangedSubject.next(_this.currentState);
        });
        this.transitionService.onStart({}, function (transition) {
            _this.locationChangeStartSubject.next(transition);
        });
        this.translateService.onTranslationChange.subscribe(function (event) {
            _this.translateChangeSubject.next(event.lang);
        });
        this.translateService.onLangChange.subscribe(function (event) {
            _this.translateChangeSubject.next(event.lang);
        });
        this.translateService.onDefaultLangChange.subscribe(function (event) {
            _this.translateChangeSubject.next(event.lang);
        });
    }
    Object.defineProperty(SessionService.prototype, "checkIsAliveError", {
        get: function () {
            return this.checkIsAliveErrorValue;
        },
        set: function (value) {
            this.checkIsAliveErrorValue = value;
            this.checkIsAliveErrorChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "DomainAlias", {
        get: function () {
            return window.location.hostname;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "OnModalTranslateChanged", {
        get: function () {
            return this.modalTranslationsChangeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "modalTranslations", {
        get: function () {
            return this.modalTranslationsValue;
        },
        set: function (value) {
            this.modalTranslationsValue = value;
            this.modalTranslationsChangeSubject.next({});
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "OnTranslateChanged", {
        get: function () {
            return this.translateChangeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "OnLocationChanged", {
        get: function () {
            return this.locationChangedSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "OnLocationChangeStart", {
        get: function () {
            return this.locationChangeStartSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "isAlive", {
        get: function () {
            return this.isAliveSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "requestLogEnabled", {
        get: function () {
            if (this.requestLogEnabledValue === undefined) {
                var value = localStorage.getItem('requestLogEnabled');
                this.requestLogEnabledValue = value ? JSON.parse(value) : false;
            }
            return this.requestLogEnabledValue;
        },
        set: function (value) {
            this.requestLogEnabledValue = value;
            localStorage.setItem('requestLogEnabled', JSON.stringify(value));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "getStartedHidden", {
        get: function () {
            return this.getStartedHiddenSubject;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "hideGetStarted", {
        set: function (value) {
            this.getStartedHiddenSubject.next(value);
        },
        enumerable: true,
        configurable: true
    });
    SessionService.prototype.logRequest = function (entry) {
        this.requestLog.push(entry);
        if (this.requestLog.length > 100) {
            this.requestLog.shift();
        }
        console.log(JSON.stringify(entry, null, 2));
    };
    Object.defineProperty(SessionService.prototype, "isSwedenCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    SessionService.prototype.applyLocale = function (cultureCode) {
        var locale;
        if (cultureCode) {
            cultureCode = cultureCode.substring(0, 2);
            if (this.isSwedenCompany) {
                locale = 'en-CA';
                this.dateFormat = 'yy-MM-dd';
            }
            else {
                switch (cultureCode) {
                    case 'kl':
                        locale = 'da-GL';
                        break;
                    case 'en':
                        locale = 'en-GB';
                        break;
                    case 'sv':
                        locale = 'da';
                        break;
                    default:
                        locale = 'da';
                        break;
                }
                this.dateFormat = 'dd-MM-yyyy';
            }
        }
        return locale;
    };
    SessionService.prototype.loadGlobalize = function () {
        Globalize.load(supplemental, deCldrData, enCldrData, svCldrData);
        var lang = 'en';
        switch (this.LanguageCode) {
            case 'en':
                lang = 'en';
                break;
            case 'da':
            case 'gl':
                lang = 'da';
                break;
            default:
                lang = 'sv';
                break;
        }
        Globalize.loadMessages(enMessages);
        Globalize.loadMessages(daMessages);
        Globalize.loadMessages(seMessages);
        Globalize.locale(lang);
    };
    SessionService.prototype.toString = function (value, format) {
        if (typeof value === 'number') {
            return this.intlService.toString(value, format ? format : 'n2', 'da');
        }
        return this.intlService.toString(value, format ? format : this.dateFormat, this.localeId);
    };
    SessionService.prototype.parseDate = function (value, format) {
        return this.intlService.parseDate(value, format, 'da');
    };
    SessionService.prototype.parseFloat = function (value) {
        return this.intlService.parseNumber(value);
    };
    SessionService.prototype.parseInt = function (value) {
        return this.intlService.parseNumber(value, { maximumFractionDigits: 0 });
    };
    SessionService.prototype.NavigateTo = function (state) {
        if (Global.SESSION && Global.SESSION.CurrentRole) {
            var currentRoleKey = Global.SESSION.CurrentRole.Key;
            if (currentRoleKey === 'Employee') {
                if (state.includes('tabs.company')) {
                    if (Global.SESSION.ManagerForUserEmploymentIds.length > 0) {
                        this.stateService.go('tabs.company.approval.timeentry');
                        return;
                    }
                    if (Global.SESSION.IsAccountAdmin || Global.SESSION.IsAccountMember) {
                        this.stateService.go('tabs.accountant.companies');
                        return;
                    }
                    this.stateService.go('tabs.selfservice.payslip');
                    return;
                }
                if (state.includes('tabs.employee')) {
                    var hasManager = this.role.EmployeeWithRoleAsManager;
                    if (hasManager) {
                        this.stateService.go('tabs.employee.time');
                        return;
                    }
                    else {
                        if (Global.SESSION.IsAccountAdmin || Global.SESSION.IsAccountMember) {
                            this.stateService.go('tabs.accountant.companies');
                            return;
                        }
                        this.stateService.go('tabs.selfservice.payslip');
                        return;
                    }
                }
            }
        }
        this.stateService.go(state);
    };
    SessionService.prototype.NavigateToAppUri = function (uri) {
        var stateName = this.getStateByUri(uri);
        if (stateName && this.currentState !== stateName) {
            this.NavigateTo(stateName);
            return true;
        }
        return false;
    };
    SessionService.prototype.getStateByUri = function (uri) {
        var matchedState;
        var allStates = this.stateService.get();
        if (!uri || !allStates || allStates.length === 0) {
            return undefined;
        }
        for (var index = 0; index < allStates.length; index++) {
            var absState = this.stateService.href(allStates[index], undefined, { absolute: true });
            if (absState && absState.indexOf(uri) >= 0) {
                matchedState = allStates[index].name;
                break;
            }
        }
        return matchedState;
    };
    Object.defineProperty(SessionService.prototype, "IsAuthenticated", {
        get: function () {
            try {
                var value = sessionStorage.getItem(this.isAuthenticationKey);
                return value === 'true';
            }
            catch (e) {
                this.isDetectedCookieDisable = true;
                return false;
            }
        },
        set: function (isAuthenticate) {
            try {
                var value = isAuthenticate ? 'true' : 'false';
                sessionStorage.setItem(this.isAuthenticationKey, value);
            }
            catch (e) {
                this.isDetectedCookieDisable = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionService.prototype, "LanguageCode", {
        get: function () {
            return Global.SESSION && Global.SESSION.SignOnToken.Language
                ? Global.SESSION.SignOnToken.Language.CultureCode.split('-')[0]
                : undefined;
        },
        enumerable: true,
        configurable: true
    });
    SessionService.prototype.ResetIsAliveTimer = function () {
        var _this = this;
        if (this.isAliveTimerId) {
            clearInterval(this.isAliveTimerId);
            this.isAliveTimerId = undefined;
        }
        this.isAliveTimerId = setInterval(function () {
            if (_this.currentState !== 'login' && _this.IsAuthenticated) {
                _this.isAliveSubject.next();
            }
        }, 30000);
    };
    SessionService.prototype.refeshRoleAndFeature = function () {
        this.role = new Role();
        this.feature = new RoleFeatures(this.role);
    };
    SessionService.prototype.switchToDefaultTab = function () {
        var isFullAdmin = false;
        var isReadOnly = false;
        var isEmployee = false;
        var isSalaryAdmin = false;
        switch (Global.SESSION.CurrentRole.Key) {
            case 'FullAdmin':
                isFullAdmin = true;
                break;
            case 'ReadOnly':
                isReadOnly = true;
                break;
            case 'Employee':
                isEmployee = true;
                break;
            case 'SalaryAdmin':
                isSalaryAdmin = true;
                break;
            default:
                break;
        }
        if (Global.SESSION.SignOnToken.LanguageId) {
            try {
                document.cookie = 'languageId=' + Global.SESSION.SignOnToken.LanguageId;
            }
            catch (e) {
                this.isDetectedCookieDisable = true;
            }
        }
        if (Global.SESSION.SignOnToken.UserAccountId && (Global.SESSION.IsAccountAdmin || Global.SESSION.IsAccountMember)) {
            this.NavigateTo('tabs.accountant.companies');
            return;
        }
        if (isFullAdmin) {
            if (Global.IsTaskCompleted) {
                this.NavigateTo('tabs.company.salarybatches');
                return;
            }
            this.NavigateTo('tabs.company.general');
            return;
        }
        if (isReadOnly || isSalaryAdmin) {
            if (Global.SESSION.IsFirstLogin) {
                this.NavigateTo('tabs.company.general');
                return;
            }
            this.NavigateTo('tabs.company.salarybatches');
            return;
        }
        if (isEmployee) {
            this.NavigateTo('tabs.selfservice');
        }
    };
    SessionService.prototype.getCompanyPreference = function (key) {
        this.companyPreference = Global.COMPANY_PREFERENCES;
        if (this.companyPreference && this.companyPreference.length > 0) {
            var preference = this.companyPreference.find(function (item) { return item.Key === key; });
            return preference;
        }
        return null;
    };
    SessionService.prototype.isAvailableTab = function (State, isDanishCompany, isGreenlandCompany) {
        if (State.includes('tabs.employee') && this.feature.EmployeeTabEnabled) {
            switch (State) {
                case 'tabs.employee.payslip':
                case 'tabs.employee.hiringstatus':
                case 'tabs.employee.general':
                    if (!this.role.EmployeeWithRoleAsManager) {
                        return true;
                    }
                    break;
                case 'tabs.employee.time':
                    if (this.feature.EmployeeTimeTabEnabled) {
                        return true;
                    }
                    break;
                case 'tabs.employee.payrolldata':
                    if (this.feature.EmployeePayrollDataTabEnabled && !this.role.EmployeeWithRoleAsManager) {
                        return true;
                    }
                    break;
            }
        }
        else if (State.includes('tabs.company') && this.feature.CompanyTabEnabled) {
            switch (State) {
                case 'tabs.company.general':
                case 'tabs.company.payrolldata':
                case 'tabs.company.salarybatches':
                case 'tabs.company.configuration':
                case 'tabs.company.configuration.hiringstatus':
                case 'tabs.company.configuration.departments':
                case 'tabs.company.configuration.integrations':
                case 'tabs.company.configuration.advancedtypes.advancedsalarytypes':
                case 'tabs.company.configuration.advancedtypes.timeentrytypes':
                case 'tabs.company.configuration.advancedtypes':
                case 'tabs.company.configuration.advancedtypes.salarytypessingle':
                case 'tabs.company.configuration.advancedtypes.timeentrytypessingle':
                    if (!this.role.EmployeeWithRoleAsManager) {
                        return true;
                    }
                    break;
                case 'tabs.company.balances':
                case 'tabs.company.balances.residualvacation':
                case 'tabs.company.balances.extravacationhours':
                    if (!this.role.IsEmployeeRole && isDanishCompany) {
                        return true;
                    }
                    break;
                case 'tabs.company.modules':
                    if (this.role.IsFullAdmin && !isGreenlandCompany) {
                        return true;
                    }
                    break;
                case 'tabs.company.configuration.dataimport':
                    if (!this.role.IsReadOnly) {
                        return true;
                    }
                    break;
                case 'tabs.company.configuration.settings':
                    if (this.role.IsFullAdmin || this.role.IsReadOnly) {
                        return true;
                    }
                    break;
                case 'tabs.company.approval.timeentry':
                    return true;
                case 'tabs.company.configuration.dimensions':
                    return true;
            }
        }
        else if (State.includes('tabs.selfservice')) {
            return true;
        }
        else if (State.includes('tabs.accountant') && this.feature.AccountantTabEnabled) {
            switch (State) {
                case 'tabs.accountant.companies':
                case 'tabs.accountant.users':
                    return true;
                case 'tabs.accountant.configuration':
                    if (this.role.IsAccountAdmin) {
                        return true;
                    }
                    break;
            }
        }
        else if (State.includes('tabs.account.userpreferences')) {
            return true;
        }
        return false;
    };
    SessionService.prototype.checkUrlPermission = function () {
        if (this.currentState.indexOf('tabs') < 0) {
            return;
        }
        var isDanishCompany = Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
        var isGreenlandCompany = Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        var isAvailable = this.isAvailableTab(this.currentState, isDanishCompany, isGreenlandCompany);
        if (!isAvailable) {
            this.switchToDefaultTab();
        }
    };
    SessionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionService_Factory() { return new SessionService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i0.LOCALE_ID), i0.ɵɵinject(i2.IntlService), i0.ɵɵinject(i3.TransitionService), i0.ɵɵinject(i3.StateService), i0.ɵɵinject(i4.RouterStateService)); }, token: SessionService, providedIn: "root" });
    return SessionService;
}());
export { SessionService };
