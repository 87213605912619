import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// import { ListHelper } from '../../../common/list-helper';
import { IListDataItem } from '../../../model/ilist-data-item';
import { IDepartment } from '../../../services/api/api-model';
import { StaticDataService } from '../../../services/api/static-data.service';
import { EmployeeService } from '../../../services/employee.service';
import { SessionService } from '../../../services/session/session.service';

@Component({
  selector: 'app-department-filter',
  templateUrl: './department-filter.component.html'
})
export class DepartmentFilterComponent implements OnInit, OnDestroy {
  private hasDepartmentDataValue = true;
  @Input()
  public get hasDepartmentData(): boolean {
    return this.hasDepartmentDataValue;
  }
  public set hasDepartmentData(value: boolean) {
    if (this.hasDepartmentDataValue !== value) {
      this.hasDepartmentDataValue = value;
      setTimeout(() => {
        this.hasDepartmentDataChange.emit(this.hasDepartmentDataValue);
      });
    }
  }
  @Output() hasDepartmentDataChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  // @Output() change: EventEmitter<number> = new EventEmitter<number>();

  // public departmentId = 0;
  public departmentDataSource: IListDataItem[];

  constructor(
    public employeeService: EmployeeService,
    private translateService: TranslateService,
    private sessionService: SessionService,
    private staticDataService: StaticDataService
  ) {
    this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() =>
      this.translateDepartmentDataSource()
    );
  }

  public ngOnInit(): void {
    this.translateDepartmentDataSource();
  }

  private ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private translateDepartmentDataSource(): void {
    this.translateService.get(['Employee.AllDepartments']).subscribe((translations: { [key: string]: string }) => {
      const allDepartmentItem: IListDataItem = { Id: -1, Text: translations['Employee.AllDepartments'] };

      this.staticDataService.departments.pipe(takeUntil(this.ngUnsubscribe)).subscribe((departments: IDepartment[]) => {
        this.hasDepartmentData = departments && departments.length > 0;
        this.departmentDataSource = departments.map((d) => ({ Id: d.Id, Text: d.Name }));
        // this.departmentDataSource.sort(ListHelper.sortInactiveLast);
        this.departmentDataSource.unshift(allDepartmentItem);
        if (!this.employeeService.departmentId) {
          this.employeeService.departmentId = this.hasDepartmentData ? -1 : undefined;
        }
        // setTimeout(() => {
        //   this.departmentId = -1;
        // });
      });
    });
  }

  // public onComboBoxChanged(): void {
  //   setTimeout(() => {
  //     this.change.emit(this.departmentId);
  //   });
  //   // this.change.emit(this.employeeService.departmentId);
  // }
}
