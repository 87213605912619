import { Injectable, OnDestroy } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ISalaryTypeView, ITimeEntryType } from 'src/app/services/api/api-model';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { DataService } from '../../../../services/api/data.service';
import { StaticDataService } from '../../../../services/api/static-data.service';
import { CompanyService } from '../../../../services/company.service';
import { SessionService } from '../../../../services/session/session.service';
import { SettingService } from '../../../../services/setting.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyTimeEntryTypeService extends CompanyService implements OnDestroy {
  constructor(
    protected dataService: DataService,
    protected staticDataService: StaticDataService,
    protected settingService: SettingService,
    protected sessionService: SessionService,
    protected transitionService: TransitionService,
    protected broadcaster: BroadcastService
  ) {
    super(dataService, staticDataService, settingService, sessionService, transitionService, broadcaster);
  }

  public get isLanguageModuleEnable(): boolean {
    return this.sessionService.feature.hasModuleId(5);
  }

  private salaryTypesSubject: BehaviorSubject<ISalaryTypeView[]>;
  public get salaryTypes(): Observable<ISalaryTypeView[]> {
    if (!this.salaryTypesSubject) {
      this.salaryTypesSubject = new BehaviorSubject<ISalaryTypeView[]>([]);
    }
    return this.salaryTypesSubject.asObservable();
  }

  public loadSalaryTypes() {
    if (!this.salaryTypesSubject) {
      this.salaryTypesSubject = new BehaviorSubject<ISalaryTypeView[]>([]);
    }

    return this.dataService.SalaryTypes_GetSalaryTypeViews().pipe(
      takeUntil(this.ngUnsubscribe),
      tap((data: ISalaryTypeView[]) => {
        this.salaryTypesSubject.next(data);
      })
    );
  }

  private timeEntryTypeSubject: BehaviorSubject<ITimeEntryType[]>;
  public get timeEntryType(): Observable<ITimeEntryType[]> {
    if (!this.timeEntryTypeSubject) {
      this.timeEntryTypeSubject = new BehaviorSubject<ITimeEntryType[]>([]);
    }
    return this.timeEntryTypeSubject.asObservable();
  }

  public loadTimeEntryType() {
    if (!this.timeEntryTypeSubject) {
      this.timeEntryTypeSubject = new BehaviorSubject<ITimeEntryType[]>([]);
    }

    this.timeEntryTypeSubject.next([]);

    if (this.isLanguageModuleEnable) {
      return this.dataService.TimeEntryTypes_GetRawTimeEntryTypesTranslated().pipe(
        takeUntil(this.ngUnsubscribe),
        tap((data: ITimeEntryType[]) => {
          this.timeEntryTypeSubject.next(this.createGridData(data));
        })
      );
    } else {
      return this.dataService.TimeEntryTypes_GetRawTimeEntryTypes().pipe(
        takeUntil(this.ngUnsubscribe),
        tap((data: ITimeEntryType[]) => {
          this.timeEntryTypeSubject.next(this.createGridData(data));
        })
      );
    }
  }

  private createGridData(timeEntryTypes: ITimeEntryType[]): ITimeEntryType[] {
    const filterTimeEntryTypes: ITimeEntryType[] = timeEntryTypes.filter(
      (type: ITimeEntryType) => !type.BaseTimeEntryType || type.BaseTimeEntryType.IsActive
    );
    const activeTimeEntryTypes: ITimeEntryType[] = filterTimeEntryTypes.filter(
      (type: ITimeEntryType) => type.IsActive !== false
    );
    const inActiveTimeEntryType: ITimeEntryType[] = filterTimeEntryTypes.filter(
      (type: ITimeEntryType) => type.IsActive === false
    );
    const sortedActiveTimeEntryTypes: ITimeEntryType[] = activeTimeEntryTypes.sort(
      (a: ITimeEntryType, b: ITimeEntryType) => this.sortCustomTimeEntryTypes(a, b)
    );
    const sortedInActiveTimeEntryTypes: ITimeEntryType[] = inActiveTimeEntryType.sort(
      (a: ITimeEntryType, b: ITimeEntryType) => this.sortCustomTimeEntryTypes(a, b)
    );
    return sortedActiveTimeEntryTypes.concat(sortedInActiveTimeEntryTypes);
  }

  private sortCustomTimeEntryTypes(a: any, b: any): number {
    const aOrder: number =
      a.SortOrder || a.SortOrder === 0
        ? a.SortOrder
        : a.BaseTimeEntryType && (a.BaseTimeEntryType.SortOrder || a.BaseTimeEntryType.SortOrder === 0)
        ? a.BaseTimeEntryType.SortOrder
        : undefined;
    const bOrder: number =
      b.SortOrder || b.SortOrder === 0
        ? b.SortOrder
        : b.BaseTimeEntryType && (b.BaseTimeEntryType.SortOrder || b.BaseTimeEntryType.SortOrder === 0)
        ? b.BaseTimeEntryType.SortOrder
        : undefined;
    if ((aOrder === undefined && bOrder === undefined) || (aOrder === 0 && bOrder === 0)) {
      return a.Id - b.Id;
    } else if (aOrder === undefined && bOrder !== undefined) {
      return -1;
    } else if (aOrder !== undefined && bOrder === undefined) {
      return 1;
    }

    return aOrder - bOrder;
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public serviceDestroy() {
    this.clearArrayDataState(this.timeEntryTypeSubject);
    this.clearArrayDataState(this.salaryTypesSubject);
  }

  private clearArrayDataState(subject: BehaviorSubject<any[]>) {
    if (subject) {
      subject.next([]);
    }
  }

  protected get allowShortcut(): boolean {
    return this.sessionService.currentState === 'tabs.company.configuration.advancedtypes.timeentrytypes';
  }
}
