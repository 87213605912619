import { Global } from 'src/app/common/global';
import {
  ISalarySummaryCategory,
  ISalaryType,
  ISalaryTypeTranslation,
  ITimeEntryType
} from 'src/app/services/api/api-model';

export class SalaryTypeView implements ISalaryType {
  // boolean
  public IsNew?: boolean;
  public IsActive?: boolean;
  public isActiveClass: string;
  public AllowEditUnitType?: boolean;
  public allowEditUnitTypeClass: string;
  public AllowEditBaseAmount?: boolean;
  public allowEditBaseAmountClass: string;
  public NegativeDefault?: boolean;
  public negativeDefaultClass: string;
  public SumFromReverseSign?: boolean;
  public sumFromReverseSignClass: string;
  public IncludeAlways?: boolean;
  public includeAlwaysClass: string;
  public TriggersOvertimeSupplement?: boolean;
  public triggersOvertimeSupplementClass: string;
  public DisplayDates?: boolean;
  public displayDatesClass: string;

  // number
  public Id: number;
  public SalaryTypeId: number;
  public SortOrder?: number;
  public sortOrderClass: string;
  public SummaryCategoryId?: number;
  public summaryCategoryClass: string;
  public UnitTypeId?: number;
  public unitTypeClass: string;
  public AmountPerUnit?: number;
  public amountPerUnitClass: string;
  public Units?: number;
  public unitsClass: string;
  public BaseAmount?: number;
  public baseAmountClass: string;
  public Amount?: number;
  public amountClass: string;
  public DefaultPersistanceTypeId?: number;
  public defaultPersistanceTypeIdClass: string;
  public CategoryId?: number;
  public categoryIdClass: string;
  public EIncomeCategory?: number;
  public eIncomeCategoryClass: string;
  public EIncomeSubCategory?: number;
  public eIncomeSubCategoryClass: string;
  public AccountTypeId: number;
  public accountTypeIdClass: string;
  public OverviewIndex?: number;
  public overviewIndexClass: string;
  public OverviewDisplayMode?: number;
  public OverviewDisplayModeClass: string;
  public InternalReferenceId: number;
  public BalanceDefinitionId?: number;
  public BalanceBehavior?: number;
  public DimensionDistributionOptionId: number;

  // string
  public Name: string;
  public nameClass: string;
  public baseSalaryName: string;
  public Description: string;
  public SkatteverketAgiFieldCode: number;
  public descriptionClass: string;
  public ExternalReference: string;
  public externalReferenceClass: string;
  public ImportExternalReferenceClass: string;
  public DefaultPayslipText?: string;
  public defaultPayslipTextClass: string;
  public SulinalFieldNumber?: string;
  public sulinalFieldNumberClass: string;
  public StatisticsFieldCode?: string;
  public statisticsFieldCodeClass: string;
  public SumFromIds?: string;
  public sumFromIdsClass: string;
  public Explanation?: string;
  public explanationClass: string;
  public ImportExternalReference: string;
  public IconConfigurationIcon: string;
  public IconConfigurationIconTooltip: string;

  public IconField: string;
  public Editable: boolean;

  private inheritClass = 'grid-inherit-cell';

  constructor(value: ISalaryType, private languageEnabled: boolean) {
    Object.assign(this, value);
    this.IconConfigurationIcon = languageEnabled ? 'Cog' : '';
    this.IconConfigurationIconTooltip = languageEnabled ? 'CompanySalaryTypes.EditTranslations' : '';
    const defaultName = '';
    this.baseSalaryName = this.BaseSalaryType ? this.BaseSalaryType.Name : defaultName;
    this.ImportExternalReference = value.ImportExternalReference || null;
    this.DimensionDistributionOptionId =
      value.DimensionDistributionOptionId !== null
        ? value.DimensionDistributionOptionId
        : this.BaseSalaryType
        ? this.BaseSalaryType.DimensionDistributionOptionId
        : 1;
    this.setCssClassForBooleans();
    // Values for boolean properties
    this.IsActive = this.IsActive !== null ? this.IsActive : this.BaseSalaryType ? this.BaseSalaryType.IsActive : false;
    this.AllowEditUnitType =
      this.AllowEditUnitType !== null
        ? this.AllowEditUnitType
        : this.BaseSalaryType
        ? this.BaseSalaryType.AllowEditUnitType
        : false;
    this.AllowEditBaseAmount =
      this.AllowEditBaseAmount !== null
        ? this.AllowEditBaseAmount
        : this.BaseSalaryType
        ? this.BaseSalaryType.AllowEditBaseAmount
        : false;
    this.NegativeDefault =
      this.NegativeDefault !== null
        ? this.NegativeDefault
        : this.BaseSalaryType
        ? this.BaseSalaryType.NegativeDefault
        : false;
    this.SumFromReverseSign =
      this.SumFromReverseSign !== null
        ? this.SumFromReverseSign
        : this.BaseSalaryType
        ? this.BaseSalaryType.SumFromReverseSign
        : false;
    this.IncludeAlways =
      this.IncludeAlways !== null
        ? this.IncludeAlways
        : this.BaseSalaryType
        ? this.BaseSalaryType.IncludeAlways
        : false;
    this.TriggersOvertimeSupplement =
      this.TriggersOvertimeSupplement !== null
        ? this.TriggersOvertimeSupplement
        : this.BaseSalaryType
        ? this.BaseSalaryType.TriggersOvertimeSupplement
        : false;
    this.DisplayDates =
      this.DisplayDates !== null ? this.DisplayDates : this.BaseSalaryType ? this.BaseSalaryType.DisplayDates : false;

    this.setCssClassForNumbers();
    // Values for number properties
    this.SortOrder =
      this.SortOrder !== null ? this.SortOrder : this.BaseSalaryType ? this.BaseSalaryType.SortOrder : null;
    this.SummaryCategoryId =
      this.SummaryCategoryId !== null
        ? this.SummaryCategoryId
        : this.BaseSalaryType
        ? this.BaseSalaryType.SummaryCategoryId
        : null;
    this.SkatteverketAgiFieldCode =
      this.SkatteverketAgiFieldCode !== null
        ? this.SkatteverketAgiFieldCode
        : this.BaseSalaryType
        ? this.BaseSalaryType.SkatteverketAgiFieldCode
        : null;
    this.UnitTypeId =
      this.UnitTypeId !== null ? this.UnitTypeId : this.BaseSalaryType ? this.BaseSalaryType.UnitTypeId : null;
    this.AmountPerUnit =
      this.AmountPerUnit !== null ? this.AmountPerUnit : this.BaseSalaryType ? this.BaseSalaryType.AmountPerUnit : null;
    this.Units = this.Units !== null ? this.Units : this.BaseSalaryType ? this.BaseSalaryType.Units : null;
    this.BaseAmount =
      this.BaseAmount !== null ? this.BaseAmount : this.BaseSalaryType ? this.BaseSalaryType.BaseAmount : null;
    this.Amount = this.Amount !== null ? this.Amount : this.BaseSalaryType ? this.BaseSalaryType.Amount : null;
    this.DefaultPersistanceTypeId =
      this.DefaultPersistanceTypeId !== null
        ? this.DefaultPersistanceTypeId
        : this.BaseSalaryType
        ? this.BaseSalaryType.DefaultPersistanceTypeId
        : null;
    this.CategoryId =
      this.CategoryId !== null ? this.CategoryId : this.BaseSalaryType ? this.BaseSalaryType.CategoryId : null;
    this.EIncomeCategory =
      this.EIncomeCategory !== null
        ? this.EIncomeCategory
        : this.BaseSalaryType
        ? this.BaseSalaryType.EIncomeCategory
        : null;
    this.EIncomeSubCategory =
      this.EIncomeSubCategory !== null
        ? this.EIncomeSubCategory
        : this.BaseSalaryType
        ? this.BaseSalaryType.EIncomeSubCategory
        : null;
    this.AccountTypeId =
      this.AccountTypeId !== null ? this.AccountTypeId : this.BaseSalaryType ? this.BaseSalaryType.AccountTypeId : null;

    // Values for Translation.
    this.setCssClassAndValueForTranslationString();

    this.setCssClassForStrings();
    // Values for string properties
    // this.ExternalReference = this.ExternalReference !== null ? this.ExternalReference : (this.BaseSalaryType ? this.BaseSalaryType.ExternalReference : undefined);
    this.SulinalFieldNumber =
      this.SulinalFieldNumber !== null
        ? this.SulinalFieldNumber
        : this.BaseSalaryType
        ? this.BaseSalaryType.SulinalFieldNumber
        : null;
    this.InternalReferenceId =
      this.InternalReferenceId !== null
        ? this.InternalReferenceId
        : this.BaseSalaryType
        ? this.BaseSalaryType.InternalReferenceId
        : null;
    this.StatisticsFieldCode =
      this.StatisticsFieldCode !== null
        ? this.StatisticsFieldCode
        : this.BaseSalaryType
        ? this.BaseSalaryType.StatisticsFieldCode
        : null;
    this.SumFromIds =
      this.SumFromIds !== null ? this.SumFromIds : this.BaseSalaryType ? this.BaseSalaryType.SumFromIds : null;
  }

  private setCssClassForBooleans(): void {
    this.isActiveClass =
      !this.BaseSalaryType || this.IsActive !== null || this.BaseSalaryType.IsActive === null ? '' : this.inheritClass;
    this.allowEditUnitTypeClass =
      !this.BaseSalaryType || this.AllowEditUnitType !== null || this.BaseSalaryType.AllowEditUnitType === null
        ? ''
        : this.inheritClass;
    this.allowEditBaseAmountClass =
      !this.BaseSalaryType || this.AllowEditBaseAmount !== null || this.BaseSalaryType.AllowEditBaseAmount === null
        ? ''
        : this.inheritClass;
    this.negativeDefaultClass =
      !this.BaseSalaryType || this.NegativeDefault !== null || this.BaseSalaryType.NegativeDefault === null
        ? ''
        : this.inheritClass;
    this.sumFromReverseSignClass =
      !this.BaseSalaryType || this.SumFromReverseSign !== null || this.BaseSalaryType.SumFromReverseSign === null
        ? ''
        : this.inheritClass;
    this.includeAlwaysClass =
      !this.BaseSalaryType || this.IncludeAlways !== null || this.BaseSalaryType.IncludeAlways === null
        ? ''
        : this.inheritClass;
    this.triggersOvertimeSupplementClass =
      !this.BaseSalaryType ||
      this.TriggersOvertimeSupplement !== null ||
      this.BaseSalaryType.TriggersOvertimeSupplement === null
        ? ''
        : this.inheritClass;

    // For single tab
    this.isActiveClassSingle =
      !this.BaseSalaryType || this.IsActive !== null || this.BaseSalaryType.IsActive === null
        ? ''
        : this.inheritClassSingle;
    this.allowEditUnitTypeClassSingle =
      !this.BaseSalaryType || this.AllowEditUnitType !== null || this.BaseSalaryType.AllowEditUnitType === null
        ? ''
        : this.inheritClassSingle;
    this.allowEditBaseAmountClassSingle =
      !this.BaseSalaryType || this.AllowEditBaseAmount !== null || this.BaseSalaryType.AllowEditBaseAmount === null
        ? ''
        : this.inheritClassSingle;
    this.negativeDefaultClassSingle =
      !this.BaseSalaryType || this.NegativeDefault !== null || this.BaseSalaryType.NegativeDefault === null
        ? ''
        : this.inheritClassSingle;
    this.sumFromReverseSignClassSingle =
      !this.BaseSalaryType || this.SumFromReverseSign !== null || this.BaseSalaryType.SumFromReverseSign === null
        ? ''
        : this.inheritClassSingle;
    this.includeAlwaysClassSingle =
      !this.BaseSalaryType || this.IncludeAlways !== null || this.BaseSalaryType.IncludeAlways === null
        ? ''
        : this.inheritClassSingle;
    this.triggersOvertimeSupplementClassSingle =
      !this.BaseSalaryType ||
      this.TriggersOvertimeSupplement !== null ||
      this.BaseSalaryType.TriggersOvertimeSupplement === null
        ? ''
        : this.inheritClassSingle;
    this.displayDatesClassSingle =
      !this.BaseSalaryType || this.DisplayDates !== null || this.BaseSalaryType.DisplayDates === null
        ? ''
        : this.inheritClassSingle;
  }

  public inheritClassSingle = 'inherit-field';
  // For single tab boolean
  public isActiveClassSingle: string;
  public allowEditUnitTypeClassSingle: string;
  public allowEditBaseAmountClassSingle: string;
  public negativeDefaultClassSingle: string;
  public sumFromReverseSignClassSingle: string;
  public includeAlwaysClassSingle: string;
  public triggersOvertimeSupplementClassSingle: string;
  public displayDatesClassSingle: string;

  // For single tab number
  public descriptionClassSingle: string;
  public defaultPayslipTextClassSingle: string;
  public sortOrderClassSingle: string;
  public summaryCategoryClassSingle: string;
  public unitTypeIdClassSingle: string;
  public amountPerUnitClassSingle: string;
  public unitsClassSingle: string;
  public baseAmountClassSingle: string;
  public amountClassSingle: string;
  public defaultPersistanceTypeIdClassSingle: string;
  public categoryIdClassSingle: string;
  public eIncomeCategoryClassSingle: string;
  public eIncomeSubCategoryClassSingle: string;
  public accountTypeIdClassSingle: string;

  private setCssClassForNumbers(): void {
    this.sortOrderClass =
      !this.BaseSalaryType || this.SortOrder !== null || this.BaseSalaryType.SortOrder === null
        ? ''
        : this.inheritClass;
    this.summaryCategoryClass =
      !this.BaseSalaryType || this.SummaryCategory !== null || this.BaseSalaryType.SummaryCategory === null
        ? ''
        : this.inheritClass;
    this.unitTypeClass =
      !this.BaseSalaryType || this.UnitTypeId !== null || this.BaseSalaryType.UnitTypeId === null
        ? ''
        : this.inheritClass;
    this.amountPerUnitClass =
      !this.BaseSalaryType || this.AmountPerUnit !== null || this.BaseSalaryType.AmountPerUnit === null
        ? ''
        : this.inheritClass;
    this.unitsClass =
      !this.BaseSalaryType || this.Units !== null || this.BaseSalaryType.Units === null ? '' : this.inheritClass;
    this.baseAmountClass =
      !this.BaseSalaryType || this.BaseAmount !== null || this.BaseSalaryType.BaseAmount === null
        ? ''
        : this.inheritClass;
    this.amountClass =
      !this.BaseSalaryType || this.Amount !== null || this.BaseSalaryType.Amount === null ? '' : this.inheritClass;
    this.defaultPersistanceTypeIdClass =
      !this.BaseSalaryType ||
      this.DefaultPersistanceTypeId !== null ||
      this.BaseSalaryType.DefaultPersistanceTypeId === null
        ? ''
        : this.inheritClass;
    this.categoryIdClass =
      !this.BaseSalaryType || this.CategoryId !== null || this.BaseSalaryType.CategoryId === null
        ? ''
        : this.inheritClass;
    this.eIncomeCategoryClass =
      !this.BaseSalaryType || this.EIncomeCategory !== null || this.BaseSalaryType.EIncomeCategory === null
        ? ''
        : this.inheritClass;
    this.eIncomeSubCategoryClass =
      !this.BaseSalaryType || this.EIncomeSubCategory !== null || this.BaseSalaryType.EIncomeSubCategory === null
        ? ''
        : this.inheritClass;
    this.accountTypeIdClass =
      !this.BaseSalaryType || this.AccountTypeId !== null || this.BaseSalaryType.AccountTypeId === null
        ? ''
        : this.inheritClass;
    // this.overviewIndexClass = !this.BaseSalaryType || this.OverviewIndex !== null || this.BaseSalaryType.OverviewIndex === null ? "" : this.inheritClass;
    // this.OverviewDisplayModeClass = !this.BaseSalaryType || this.OverviewDisplayMode !== null || this.BaseSalaryType.OverviewDisplayMode === null ? "" : this.inheritClass;

    // For single tab
    this.descriptionClassSingle =
      !this.BaseSalaryType || this.Description !== null || this.BaseSalaryType.Description === null
        ? ''
        : this.inheritClassSingle;
    this.defaultPayslipTextClassSingle =
      !this.BaseSalaryType || this.DefaultPayslipText !== null || this.BaseSalaryType.DefaultPayslipText === null
        ? ''
        : this.inheritClassSingle;
    this.sortOrderClassSingle =
      !this.BaseSalaryType || this.SortOrder !== null || this.BaseSalaryType.SortOrder === null
        ? ''
        : this.inheritClassSingle;
    this.summaryCategoryClassSingle =
      !this.BaseSalaryType || this.SummaryCategory !== null || this.BaseSalaryType.SummaryCategory === null
        ? ''
        : this.inheritClassSingle;
    this.unitTypeIdClassSingle =
      !this.BaseSalaryType || this.UnitTypeId !== null || this.BaseSalaryType.UnitTypeId === null
        ? ''
        : this.inheritClassSingle;
    this.amountPerUnitClassSingle =
      !this.BaseSalaryType || this.AmountPerUnit !== null || this.BaseSalaryType.AmountPerUnit === null
        ? ''
        : this.inheritClassSingle;
    this.unitsClassSingle =
      !this.BaseSalaryType || this.Units !== null || this.BaseSalaryType.Units === null ? '' : this.inheritClassSingle;
    this.baseAmountClassSingle =
      !this.BaseSalaryType || this.BaseAmount !== null || this.BaseSalaryType.BaseAmount === null
        ? ''
        : this.inheritClassSingle;
    this.amountClassSingle =
      !this.BaseSalaryType || this.Amount !== null || this.BaseSalaryType.Amount === null
        ? ''
        : this.inheritClassSingle;
    this.defaultPersistanceTypeIdClassSingle =
      !this.BaseSalaryType ||
      this.DefaultPersistanceTypeId !== null ||
      this.BaseSalaryType.DefaultPersistanceTypeId === null
        ? ''
        : this.inheritClassSingle;
    this.categoryIdClassSingle =
      !this.BaseSalaryType || this.CategoryId !== null || this.BaseSalaryType.CategoryId === null
        ? ''
        : this.inheritClassSingle;
    this.eIncomeCategoryClassSingle =
      !this.BaseSalaryType || this.EIncomeCategory !== null || this.BaseSalaryType.EIncomeCategory === null
        ? ''
        : this.inheritClassSingle;
    this.eIncomeSubCategoryClassSingle =
      !this.BaseSalaryType || this.EIncomeSubCategory !== null || this.BaseSalaryType.EIncomeSubCategory === null
        ? ''
        : this.inheritClassSingle;
    this.accountTypeIdClassSingle =
      !this.BaseSalaryType || this.AccountTypeId !== null || this.BaseSalaryType.AccountTypeId === null
        ? ''
        : this.inheritClassSingle;
  }

  private setCssClassAndValueForTranslationString(): void {
    if (this.languageEnabled) {
      this.nameClass =
        this.getFieldTranslation('Name') || this.Name !== null || !this.getBaseTranslation('Name')
          ? ''
          : this.inheritClass;
      this.descriptionClass =
        this.getFieldTranslation('Description') || this.Description !== null || !this.getBaseTranslation('Description')
          ? ''
          : this.inheritClass;
      this.explanationClass =
        this.getFieldTranslation('Explanation') || this.Explanation !== null || !this.getBaseTranslation('Explanation')
          ? ''
          : this.inheritClass;
      this.defaultPayslipTextClass =
        this.getFieldTranslation('DefaultPayslipText') ||
        this.DefaultPayslipText !== null ||
        !this.getBaseTranslation('DefaultPayslipText')
          ? ''
          : this.inheritClass;

      this.Name = this.getFieldTranslation('Name')
        ? this.getFieldTranslation('Name')
        : this.Name !== null
        ? this.Name
        : this.getBaseTranslation('Name');
      this.Description = this.getFieldTranslation('Description')
        ? this.getFieldTranslation('Description')
        : this.Description !== null
        ? this.Description
        : this.getBaseTranslation('Description');
      this.Explanation = this.getFieldTranslation('Explanation')
        ? this.getFieldTranslation('Explanation')
        : this.Explanation !== null
        ? this.Explanation
        : this.getBaseTranslation('Explanation');
      this.DefaultPayslipText = this.getFieldTranslation('DefaultPayslipText')
        ? this.getFieldTranslation('DefaultPayslipText')
        : this.DefaultPayslipText !== null
        ? this.DefaultPayslipText
        : this.getBaseTranslation('DefaultPayslipText');
    } else {
      this.nameClass =
        this.Name !== null || !this.BaseSalaryType || this.BaseSalaryType.Name === null ? '' : this.inheritClass;
      this.descriptionClass =
        this.Description !== null || !this.BaseSalaryType || this.BaseSalaryType.Description === null
          ? ''
          : this.inheritClass;
      this.explanationClass =
        this.Explanation !== null || !this.BaseSalaryType || this.BaseSalaryType.Explanation === null
          ? ''
          : this.inheritClass;
      this.defaultPayslipTextClass =
        this.DefaultPayslipText !== null || !this.BaseSalaryType || this.BaseSalaryType.DefaultPayslipText === null
          ? ''
          : this.inheritClass;

      this.Name = this.Name !== null ? this.Name : this.BaseSalaryType ? this.BaseSalaryType.Name : undefined;
      this.Description =
        this.Description !== null ? this.Description : this.BaseSalaryType ? this.BaseSalaryType.Description : null;
      this.Explanation =
        this.Explanation !== null ? this.Explanation : this.BaseSalaryType ? this.BaseSalaryType.Explanation : null;
      this.DefaultPayslipText =
        this.DefaultPayslipText !== null
          ? this.DefaultPayslipText
          : this.BaseSalaryType
          ? this.BaseSalaryType.DefaultPayslipText
          : null;
    }
  }

  private getFieldTranslation(fieldName: string): string {
    let translationText: string;
    if (this.SalaryTypeTranslations && this.SalaryTypeTranslations.length > 0) {
      const translation: any = this.SalaryTypeTranslations.find(
        (t: ISalaryTypeTranslation) => t.LanguageId === Global.SESSION.SignOnToken.LanguageId
      );
      translationText = translation ? translation[fieldName] : null;
    }

    return translationText;
  }

  private getBaseTranslation(fieldName: string): string {
    if (!this.BaseSalaryType) {
      return null;
    }

    let translationText: string;
    if (this.BaseSalaryType.SalaryTypeTranslations && this.BaseSalaryType.SalaryTypeTranslations.length > 0) {
      const translation: any = this.BaseSalaryType.SalaryTypeTranslations.find(
        (t: ISalaryTypeTranslation) => t.LanguageId === Global.SESSION.SignOnToken.LanguageId
      );
      translationText = translation ? translation[fieldName] : null;
    }

    return translationText ? translationText : (this.BaseSalaryType as any)[fieldName];
  }

  private setCssClassForStrings(): void {
    // this.externalReferenceClass = !this.BaseSalaryType || this.ExternalReference !== null || this.BaseSalaryType.ExternalReference === null ? "" : this.inheritClass;
    this.sulinalFieldNumberClass =
      !this.BaseSalaryType || this.SulinalFieldNumber !== null || this.BaseSalaryType.SulinalFieldNumber === null
        ? ''
        : this.inheritClass;
    this.statisticsFieldCodeClass =
      !this.BaseSalaryType || this.StatisticsFieldCode !== null || this.BaseSalaryType.StatisticsFieldCode === null
        ? ''
        : this.inheritClass;
    this.sumFromIdsClass =
      !this.BaseSalaryType || this.SumFromIds !== null || this.BaseSalaryType.SumFromIds === null
        ? ''
        : this.inheritClass;
  }

  public toSalaryType(): any {
    return {
      Id: this.Id ? this.Id : this.SalaryTypeId,
      SalaryTypeId: this.SalaryTypeId ? this.SalaryTypeId : this.Id,
      CompanyId: this.CompanyId,
      BaseSalaryTypeId: this.BaseSalaryTypeId,
      IsVisible: this.IsVisible,
      OverviewIndex: this.OverviewIndex,
      OverviewDisplayMode: this.OverviewDisplayMode,
      Identifier: this.Identifier,
      RelatesToIncomeType5: this.RelatesToIncomeType5,
      SalaryTypeGroupId: this.SalaryTypeGroupId,
      StatisticsOnly: this.StatisticsOnly,
      IsAdvanced: this.IsAdvanced || false,
      ImportExternalReference: this.ImportExternalReference || null,
      DimensionDistributionOptionId: this.DimensionDistributionOptionId,
      DisplayDates: this.DisplayDates,

      SulinalFieldNumber: this.SulinalFieldNumber || null,
      InternalReferenceId: this.InternalReferenceId || null,
      BalanceDefinitionId: this.BalanceDefinitionId || null,
      BalanceBehavior: this.BalanceBehavior || null,

      Name:
        /*this.languageEnabled ? undefined : */ !this.nameClass ||
        !this.BaseSalaryType ||
        this.Name !== this.BaseSalaryType.Name
          ? this.Name
          : null,
      Description:
        /*this.languageEnabled ? undefined : */ !this.descriptionClass ||
        !this.BaseSalaryType ||
        this.Description !== this.BaseSalaryType.Description
          ? this.Description
          : null,
      DefaultPayslipText:
        /*this.languageEnabled ? undefined : */ !this.defaultPayslipTextClass ||
        !this.BaseSalaryType ||
        this.DefaultPayslipText !== this.BaseSalaryType.DefaultPayslipText
          ? this.DefaultPayslipText
          : null,
      Explanation:
        /*this.languageEnabled ? undefined : */ !this.explanationClass ||
        !this.BaseSalaryType ||
        this.Explanation !== this.BaseSalaryType.Explanation
          ? this.Explanation
          : null,

      AccountTypeId:
        !this.BaseSalaryType || !this.accountTypeIdClass || this.AccountTypeId !== this.BaseSalaryType.AccountTypeId
          ? this.AccountTypeId
          : null,
      AllowEditBaseAmount:
        !this.BaseSalaryType ||
        !this.allowEditBaseAmountClass ||
        this.AllowEditBaseAmount !== this.BaseSalaryType.AllowEditBaseAmount
          ? this.AllowEditBaseAmount
          : null,
      AllowEditUnitType:
        !this.BaseSalaryType ||
        !this.allowEditUnitTypeClass ||
        this.AllowEditUnitType !== this.BaseSalaryType.AllowEditUnitType
          ? this.AllowEditUnitType
          : null,
      Amount:
        !this.BaseSalaryType || !this.amountClass || this.Amount !== this.BaseSalaryType.Amount ? this.Amount : null,
      AmountPerUnit:
        !this.BaseSalaryType || !this.amountPerUnitClass || this.AmountPerUnit !== this.BaseSalaryType.AmountPerUnit
          ? this.AmountPerUnit
          : null,
      BaseAmount:
        !this.BaseSalaryType || !this.baseAmountClass || this.BaseAmount !== this.BaseSalaryType.BaseAmount
          ? this.BaseAmount
          : null,
      CategoryId:
        !this.BaseSalaryType || !this.categoryIdClass || this.CategoryId !== this.BaseSalaryType.CategoryId
          ? this.CategoryId
          : null,
      DefaultPersistanceTypeId:
        !this.BaseSalaryType ||
        !this.defaultPersistanceTypeIdClass ||
        this.DefaultPersistanceTypeId !== this.BaseSalaryType.DefaultPersistanceTypeId
          ? this.DefaultPersistanceTypeId
          : null,
      EIncomeCategory:
        !this.BaseSalaryType ||
        !this.eIncomeCategoryClass ||
        this.EIncomeCategory !== this.BaseSalaryType.EIncomeCategory
          ? this.EIncomeCategory
          : null,
      EIncomeSubCategory:
        !this.BaseSalaryType ||
        !this.eIncomeSubCategoryClass ||
        this.EIncomeSubCategory !== this.BaseSalaryType.EIncomeSubCategory
          ? this.EIncomeSubCategory
          : null,
      ExternalReference:
        !this.BaseSalaryType ||
        !this.externalReferenceClass ||
        this.ExternalReference !== this.BaseSalaryType.ExternalReference
          ? this.ExternalReference || null
          : null,
      IncludeAlways:
        !this.BaseSalaryType || !this.includeAlwaysClass || this.IncludeAlways !== this.BaseSalaryType.IncludeAlways
          ? this.IncludeAlways
          : null,
      IsActive:
        !this.BaseSalaryType || !this.isActiveClass || this.IsActive !== this.BaseSalaryType.IsActive
          ? this.IsActive
          : null,
      NegativeDefault:
        !this.BaseSalaryType ||
        !this.negativeDefaultClass ||
        this.NegativeDefault !== this.BaseSalaryType.NegativeDefault
          ? this.NegativeDefault
          : null,
      SortOrder:
        !this.BaseSalaryType || !this.sortOrderClass || this.SortOrder !== this.BaseSalaryType.SortOrder
          ? this.SortOrder
          : null,
      StatisticsFieldCode:
        !this.BaseSalaryType ||
        !this.statisticsFieldCodeClass ||
        this.StatisticsFieldCode !== this.BaseSalaryType.StatisticsFieldCode
          ? this.StatisticsFieldCode
          : null,
      SumFromIds:
        !this.BaseSalaryType || !this.sumFromIdsClass || this.SumFromIds !== this.BaseSalaryType.SumFromIds
          ? this.SumFromIds
          : null,
      SumFromReverseSign:
        !this.BaseSalaryType ||
        !this.sumFromReverseSignClass ||
        this.SumFromReverseSign !== this.BaseSalaryType.SumFromReverseSign
          ? this.SumFromReverseSign
          : null,
      SummaryCategoryId:
        !this.BaseSalaryType ||
        !this.summaryCategoryClass ||
        this.SummaryCategoryId !== this.BaseSalaryType.SummaryCategoryId
          ? this.SummaryCategoryId
          : null,
      SkatteverketAgiFieldCode:
        !this.BaseSalaryType || this.SkatteverketAgiFieldCode !== this.BaseSalaryType.SkatteverketAgiFieldCode
          ? this.SkatteverketAgiFieldCode
          : null,
      TriggersOvertimeSupplement:
        !this.BaseSalaryType ||
        !this.triggersOvertimeSupplementClass ||
        this.TriggersOvertimeSupplement !== this.BaseSalaryType.TriggersOvertimeSupplement
          ? this.TriggersOvertimeSupplement
          : null,
      UnitTypeId:
        !this.BaseSalaryType || !this.unitTypeClass || this.UnitTypeId !== this.BaseSalaryType.UnitTypeId
          ? this.UnitTypeId
          : null,
      Units: !this.BaseSalaryType || !this.unitsClass || this.Units !== this.BaseSalaryType.Units ? this.Units : null
    };
  }

  public BaseSalaryType?: ISalaryType;

  // Not used in grid.
  IsVisible?: boolean;
  StatisticsOnly?: boolean;
  RelatesToIncomeType5?: boolean;
  IsAdvanced: boolean;
  Identifier?: string;
  SalaryTypeGroupId?: number;

  BaseSalaryTypeId?: number;
  CompanyId?: number;
  SummaryCategory?: ISalarySummaryCategory;
  SalaryTypeTranslations?: ISalaryTypeTranslation[];
  TimeEntryTypes?: ITimeEntryType[];

  private hasValue(value: any): boolean {
    return value !== undefined && value !== null;
  }
}
