import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../common/form-component-base';
import { Department, ICompanyUser, IDepartment } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
var AddDepartmentComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AddDepartmentComponent, _super);
    function AddDepartmentComponent(staticDataService, dataService) {
        var _this = _super.call(this) || this;
        _this.staticDataService = staticDataService;
        _this.dataService = dataService;
        _this.ModelDepartment = new Department();
        _this.isVisibleValue = false;
        _this.isVisibleChange = new EventEmitter();
        return _this;
    }
    Object.defineProperty(AddDepartmentComponent.prototype, "isVisible", {
        get: function () {
            return this.isVisibleValue;
        },
        set: function (value) {
            this.isVisibleValue = value;
            this.isVisibleChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    AddDepartmentComponent.prototype.ngOnInit = function () { };
    AddDepartmentComponent.prototype.onCreateNewDepartment = function (event) {
        var _this = this;
        if (event && event === 'Create') {
            this.ModelDepartment.IsActive = true;
            this.dataService.Companies_CreateDepartment(this.ModelDepartment).subscribe(function (data) {
                _this.staticDataService.loadDepartments();
                _this.onCreateNewDepartmentSuccess(data.Id);
            });
        }
        this.ModelDepartment = new Department();
    };
    AddDepartmentComponent.prototype.onCreateNewDepartmentSuccess = function (newDepartmentId) {
        var _this = this;
        this.staticDataService.departments.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.departments = data;
            _this.userEmployment.DepartmentId = newDepartmentId;
        });
    };
    return AddDepartmentComponent;
}(FormComponentBase));
export { AddDepartmentComponent };
