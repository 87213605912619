<button
  id="CompanySelectorButton"
  kendoTooltip
  [title]="Companytooltip"
  type="button"
  (click)="onToggleCompanyDropdown()"
  [ngClass]="showCompanyAbbreviation ? 'use-abbreviation' : ''"
>
  <div [title]="Companytooltip">
    <img class="Settings-companyImage" *ngIf="logoDemo" src="{{ logoDemo }}" />
    <img class="Settings-companyImage" *ngIf="logo && !logoDemo" [src]="'data:image/jpeg;base64,' + logo" />
    <div class="Settings-companyName Display-companyName" *ngIf="!logo && !logoDemo">{{ currentCompanyName }}</div>
    <div class="Settings-companyAbbreviation Avatar Avatar--circle Avatar--small">
      {{ currentCompanyAbbreviation }}
    </div>
    <app-icon *ngIf="!isSingleListcompanis" [icon]="'ChevronDown'" [size]="'xxsmall'"></app-icon>
  </div>
</button>
