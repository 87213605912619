<div class="FormElement">
  <div class="Card-title">{{ 'CompanyHiringStatus.EmployeesBasedOnThisTemplate' | translate }}</div>
  <app-grid
    [data]="employees"
    [editMode]="false"
    class="Company-templateEmployeesGrid GridSelect"
    [pageable]="true"
    [pageSize]="100"
    [miniPager]="true"
    [idColumn]="'UserId'"
    (dblClick)="onGridDblClick($event)"
    [(selectedItem)]="selectedEmployee"
  >
    <app-grid-column
      [field]="'FullName'"
      [type]="'text'"
      [width]="200"
      [title]="'CompanyHiringStatus.EmployeeGridFullName' | translate"
    ></app-grid-column>
    <app-grid-column
      [field]="'Title'"
      [type]="'text'"
      [width]="100"
      [title]="'CompanyHiringStatus.EmployeeGridTitle' | translate"
    ></app-grid-column>
  </app-grid>
</div>
