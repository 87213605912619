import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-grid-action',
  template: ''
})
export class GridActionComponent {
  @Input() public action: string;
  @Input() public label: string;
  @Input() public icon: string;
  @Input() public actionsSpanClass = '';
  @Input() public disable: boolean;
  @Input() public isHidden: boolean;
  @Input() public isRadioButton: boolean;
}
