import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../common/constants';
import { FormComponentBase } from '../../common/form-component-base';
import { Global } from '../../common/global';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { OffDayCompensationModel } from './off-day-compensation-model';
var MultiPurposeOffDayCompensationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MultiPurposeOffDayCompensationComponent, _super);
    function MultiPurposeOffDayCompensationComponent(staticDataService, sessionService) {
        var _this = _super.call(this) || this;
        _this.staticDataService = staticDataService;
        _this.sessionService = sessionService;
        _this.modelChange = new EventEmitter();
        _this.flexData = [];
        _this.childIsDirty = Array(2).fill(false);
        return _this;
    }
    Object.defineProperty(MultiPurposeOffDayCompensationComponent.prototype, "model", {
        get: function () {
            if (!this.modelValue) {
                this.modelValue = new OffDayCompensationModel();
            }
            return this.modelValue;
        },
        set: function (value) {
            this.modelValue = value;
            if (this.modelChange) {
                this.modelChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultiPurposeOffDayCompensationComponent.prototype, "defaulHeader", {
        get: function () {
            return this.isDanishCompany
                ? 'EmployeeEmployment.OffDayCompensationDanish'
                : 'EmployeeEmployment.OffDayCompensation';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultiPurposeOffDayCompensationComponent.prototype, "SectionName", {
        get: function () {
            var preferenceModel = this.sessionService.getCompanyPreference('Company.LegacyFlexName');
            return preferenceModel ? preferenceModel.Value : this.defaulHeader;
        },
        enumerable: true,
        configurable: true
    });
    MultiPurposeOffDayCompensationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.PensionBaseCalculationMethod.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (baseCalculationMethods) {
            _this.flexData = baseCalculationMethods.filter(function (pension) {
                return pension.IsFlexBase;
            });
            if (_this.flexData.length > 0 && !_this.model.FlexBaseCalculationMethodId) {
                _this.model.FlexBaseCalculationMethodId = 2;
            }
        });
    };
    Object.defineProperty(MultiPurposeOffDayCompensationComponent.prototype, "isDanishCompany", {
        get: function () {
            return Global.SESSION && Global.SESSION.CurrentCountryId === Constants.DENMARK_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    return MultiPurposeOffDayCompensationComponent;
}(FormComponentBase));
export { MultiPurposeOffDayCompensationComponent };
