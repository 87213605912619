import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../../common/form-component-base';
import { Global } from '../../../common/global';
import { StaticDataService } from '../../../services/api/static-data.service';
import { SessionService } from '../../../services/session/session.service';
var BankInfoComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BankInfoComponent, _super);
    function BankInfoComponent(staticDataService, sessionService) {
        var _this = _super.call(this) || this;
        _this.staticDataService = staticDataService;
        _this.sessionService = sessionService;
        _this.bankInfoChange = new EventEmitter();
        _this.hasNemKontoEnabled = false;
        _this.childIsValid = Array(6).fill(true);
        _this.childIsDirty = Array(6).fill(false);
        return _this;
    }
    Object.defineProperty(BankInfoComponent.prototype, "bankInfo", {
        get: function () {
            if (!this.bankInfoValue) {
                this.bankInfoValue = {};
            }
            return this.bankInfoValue;
        },
        set: function (value) {
            this.bankInfoValue = value;
            this.bankInfoChange.emit(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BankInfoComponent.prototype, "isAllowCompanyNemKontoPayments", {
        get: function () {
            return Global && Global.COMPANY && Global.COMPANY.AllowAutoDetermineBankAccount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BankInfoComponent.prototype, "isSelfService", {
        get: function () {
            return this.sessionService.currentState === 'tabs.selfservice.general';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BankInfoComponent.prototype, "isDisableBankFeild", {
        get: function () {
            return !((this.isAllowCompanyNemKontoPayments && !this.bankInfo.AutoDetermineBankAccount) ||
                !this.isAllowCompanyNemKontoPayments);
        },
        enumerable: true,
        configurable: true
    });
    BankInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.Currency.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.currencies = data); });
    };
    return BankInfoComponent;
}(FormComponentBase));
export { BankInfoComponent };
