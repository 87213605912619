import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompanySalaryBatchService } from '../../company-salary-batches.service';
var SalaryBatchSalaryTotalsComponent = /** @class */ (function () {
    function SalaryBatchSalaryTotalsComponent(companySalaryBatchService) {
        var _this = this;
        this.companySalaryBatchService = companySalaryBatchService;
        this.showTitle = true;
        this.view = 'salarytypes';
        this.ngUnsubscribe = new Subject();
        this.companySalaryBatchService.selectedSalaryBatch
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (value) {
            _this.SalaryBatchId = value ? value.Id : null;
        });
    }
    Object.defineProperty(SalaryBatchSalaryTotalsComponent.prototype, "SalaryBatchId", {
        get: function () {
            return this.salaryBatchIdValue;
        },
        set: function (value) {
            if (value !== this.salaryBatchIdValue) {
                this.salaryBatchIdValue = value;
                this.companySalaryBatchService.loadSalaryTypeTotals(value);
                this.companySalaryBatchService.loadUnitTotals(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    SalaryBatchSalaryTotalsComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    SalaryBatchSalaryTotalsComponent.prototype.viewModeChange = function () {
        this.companySalaryBatchService.viewSalaryTotalModeChange.emit(true);
    };
    return SalaryBatchSalaryTotalsComponent;
}());
export { SalaryBatchSalaryTotalsComponent };
