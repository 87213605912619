/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../icon/icon.component.ngfactory";
import * as i4 from "../icon/icon.component";
import * as i5 from "../../services/session/session.service";
import * as i6 from "../module-check/module-check.component.ngfactory";
import * as i7 from "../module-check/module-check.component";
import * as i8 from "../../services/api/static-data.service";
import * as i9 from "../../services/api/data.service";
import * as i10 from "../../services/authentication/authentication.service";
import * as i11 from "../pipes/price-format.pipe";
import * as i12 from "./radio-edit.component";
var styles_RadioEditComponent = [];
var RenderType_RadioEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RadioEditComponent, data: {} });
export { RenderType_RadioEditComponent as RenderType_RadioEditComponent };
function View_RadioEditComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "label", [["class", "FormElement-radio"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "edit-mode": 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 0, "input", [["type", "radio"]], [[8, "id", 0], [8, "checked", 0], [8, "name", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "span", [["class", "FormElement-radioFaux"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 4, "span", [["class", "FormElement-label"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(8, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(9, { "radio-disabled": 0 }), (_l()(), i0.ɵted(10, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "FormElement-radio"; var currVal_1 = _ck(_v, 3, 0, (_co.editMode && !_co.disable)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_6 = "FormElement-label"; var currVal_7 = _ck(_v, 9, 0, _v.context.$implicit.isDisabled); _ck(_v, 8, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.context.$implicit.value; var currVal_3 = (_co.value === _v.context.$implicit.value); var currVal_4 = _co.controlId; var currVal_5 = (_co.disable || !_co.editMode); _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_8 = _v.context.$implicit.label; _ck(_v, 10, 0, currVal_8); }); }
function View_RadioEditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "FormElement-control"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioEditComponent_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 2, 0, currVal_0); }, null); }
function View_RadioEditComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "FormElement-label textWithLink"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onReadMoreClick(_v.parent.parent.context.$implicit.openLink) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Help.ReadMore")); _ck(_v, 1, 0, currVal_0); }); }
function View_RadioEditComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-icon", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.parent.parent.context.$implicit.iconClick.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IconComponent_0, i3.RenderType_IconComponent)), i0.ɵdid(1, 49152, null, 0, i4.IconComponent, [i0.ElementRef, i5.SessionService, i0.ChangeDetectorRef], { icon: [0, "icon"] }, { click: "click" })], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.addonIcon; _ck(_v, 1, 0, currVal_0); }, null); }
function View_RadioEditComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["class", "FormElement-static"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 14, "label", [["class", "FormElement-radio"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(4, { "edit-mode": 0 }), (_l()(), i0.ɵeld(5, 0, null, null, 0, "input", [["type", "radio"]], [[8, "id", 0], [8, "checked", 0], [8, "name", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.parent.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [["class", "FormElement-radioFaux"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "span", [["class", "FormElement-label"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(9, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(10, { "radio-disabled": 0 }), (_l()(), i0.ɵted(11, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioEditComponent_6)), i0.ɵdid(13, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioEditComponent_7)), i0.ɵdid(15, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "FormElement-radio"; var currVal_1 = _ck(_v, 4, 0, (_co.editMode && !_co.disable)); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_6 = "FormElement-label"; var currVal_7 = _ck(_v, 10, 0, _v.parent.context.$implicit.isDisabled); _ck(_v, 9, 0, currVal_6, currVal_7); var currVal_9 = _v.parent.context.$implicit.openLink; _ck(_v, 13, 0, currVal_9); var currVal_10 = (_v.parent.context.$implicit.addonIcon && _v.parent.context.$implicit.visibleIcon); _ck(_v, 15, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.parent.context.$implicit.value; var currVal_3 = (_co.value === _v.parent.context.$implicit.value); var currVal_4 = _co.controlId; var currVal_5 = (_co.disable || !_co.editMode); _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_8 = _v.parent.context.$implicit.label; _ck(_v, 11, 0, currVal_8); }); }
function View_RadioEditComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "FormElement-label textWithLink"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onReadMoreClick(_v.parent.parent.context.$implicit.openLink) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Help.ReadMore")); _ck(_v, 1, 0, currVal_0); }); }
function View_RadioEditComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "FormElement-static"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "app-module-check", [], null, null, null, i6.View_ModuleCheckComponent_0, i6.RenderType_ModuleCheckComponent)), i0.ɵdid(2, 245760, null, 0, i7.ModuleCheckComponent, [i5.SessionService, i8.StaticDataService, i9.DataService, i10.AuthenticationService, i11.PriceFormatPipe], { moduleId: [0, "moduleId"], dialogWidth: [1, "dialogWidth"] }, null), (_l()(), i0.ɵeld(3, 0, null, 0, 6, "label", [["class", "FormElement-radio"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "input", [["type", "radio"]], [[8, "id", 0], [8, "checked", 0], [8, "name", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.parent.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "span", [["class", "FormElement-radioFaux"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "FormElement-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioEditComponent_9)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.ModuleId; var currVal_1 = _v.parent.context.$implicit.moduleCheckWidth; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_7 = _v.parent.context.$implicit.openLink; _ck(_v, 9, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.parent.context.$implicit.value; var currVal_3 = (_co.value === _v.parent.context.$implicit.value); var currVal_4 = _co.controlId; var currVal_5 = !_co.editMode; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _v.parent.context.$implicit.label; _ck(_v, 7, 0, currVal_6); }); }
function View_RadioEditComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "FormElement-addon FormElement-addonSpace Icon-addOn"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "app-icon", [], null, null, null, i3.View_IconComponent_0, i3.RenderType_IconComponent)), i0.ɵdid(2, 49152, null, 0, i4.IconComponent, [i0.ElementRef, i5.SessionService, i0.ChangeDetectorRef], { icon: [0, "icon"], tooltip: [1, "tooltip"], link: [2, "link"] }, null), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "Question"; var currVal_1 = i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 3).transform("GlobalDialog.ReadMore")); var currVal_2 = _v.parent.context.$implicit.addonLink; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_RadioEditComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "FormElement-control"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioEditComponent_5)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioEditComponent_8)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioEditComponent_10)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.isCheckModule; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.isCheckModule; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.addonLink; _ck(_v, 6, 0, currVal_2); }, null); }
function View_RadioEditComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioEditComponent_4)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RadioEditComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioEditComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioEditComponent_3)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.vertical; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.vertical; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_RadioEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-radio-edit", [], null, null, null, View_RadioEditComponent_0, RenderType_RadioEditComponent)), i0.ɵdid(1, 49152, null, 1, i12.RadioEditComponent, [i0.ElementRef, i0.ChangeDetectorRef], null, null), i0.ɵqud(603979776, 1, { options: 1 })], null, null); }
var RadioEditComponentNgFactory = i0.ɵccf("app-radio-edit", i12.RadioEditComponent, View_RadioEditComponent_Host_0, { defaultnullvalue: "defaultnullvalue", type: "type", isGrid: "isGrid", preventChange: "preventChange", value: "value", editMode: "editMode", isDirty: "isDirty", isValid: "isValid", setTimeout: "setTimeout", hostClass: "hostClass", vertical: "vertical", disable: "disable", openLink: "openLink" }, { valueChange: "valueChange", editModeChange: "editModeChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange" }, []);
export { RadioEditComponentNgFactory as RadioEditComponentNgFactory };
