import { Component, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionService } from '../../services/session/session.service';
import { ReportDialogService } from '../../shared-components/report-dialog/report-dialog.service';
import { ReportParametersDefaultValue } from '../../shared-components/report-dialog/report-parameters-default-value';
import { CompanyApprovalService } from './company-approval.service';

@Component({
  selector: 'app-company-approval',
  templateUrl: './company-approval.component.html',
  styleUrls: ['./company-approval.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyApprovalComponent implements OnInit, OnDestroy {
  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }

  public isVisible = false;
  public listClassExcluded = ['Footer', '10'];

  constructor(
    private sessionService: SessionService,
    private renderer: Renderer2,
    public reportDialogService: ReportDialogService,
    public companyApprovalService: CompanyApprovalService
  ) {
    this.companyApprovalService.showReportShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && this.reportDialogService.isVisibleReportIcon) {
          this.onShowReportsEventClick();
        }
      });
    this.reportDialogService.visibleChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      if (value) {
        this.onShowReportsEventClick();
      }
    });
  }

  public ngOnInit(): void {
    this.renderer.listen(window, 'click', () => {
      if (!this.isCollapseButtonClick) {
        this.menuOpen = false;
      } else {
        this.isCollapseButtonClick = false;
      }
    });
    if (this.sessionService.currentState === 'tabs.company.approval') {
      if (this.sessionService.isApprovalMessage) {
        this.sessionService.isApprovalMessage = false;
        this.isVisible = true;
      }
      this.gotoTimeEntryApproval();
    }
  }

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public menuOpen: boolean;
  public reportParams: ReportParametersDefaultValue;
  private isCollapseButtonClick: boolean;

  public get approvalTimeEntryIsActive(): boolean {
    return this.sessionService.currentState === 'tabs.company.approval.timeentry';
  }

  public onToggleNavigation(): void {
    this.isCollapseButtonClick = true;
    this.menuOpen = !this.menuOpen;
  }

  public gotoTimeEntryApproval(): void {
    console.log('run');
    this.menuOpen = false;
    this.sessionService.NavigateTo('tabs.company.approval.timeentry');
  }

  public reportDialogVisible = false;
  public noReportVisible = false;

  public onShowReportsEventClick(): void {
    const parameters = new ReportParametersDefaultValue();
    parameters.employeeId = this.sessionService.currentSalaryPeriodId
      ? this.sessionService.currentSalaryPeriodId
      : undefined;
    this.reportDialogService.reportParams = parameters;

    if (this.sessionService.currentSalaryPeriodId) {
      this.reportDialogService.onShowReportsEventClick();
      this.noReportVisible = false;
    } else {
      this.reportDialogService.reportDialogVisible = false;
      this.noReportVisible = true;
    }
  }

  public onMoreMenuOptionClick(action: string): void {
    switch (action) {
      case 'report':
        this.onShowReportsEventClick();
        break;
    }
  }
}
