import { Injectable } from '@angular/core';
import { HookResult, StateService, Transition, TransitionService } from '@uirouter/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RouterStateService {
  private defaultStates: { [index: string]: string } = {};
  private redirects: { [index: string]: string } = {};
  private currentSubStates: { [index: string]: string } = {};
  private currentState: string;

  constructor(private transitionService: TransitionService, private stateService: StateService) {
    this.transitionService.onBefore(
      {},
      (transition: Transition): HookResult => {
        const toState: string = transition.to().name;

        const redirectState: string =
          this.redirects[toState] || this.currentSubStates[toState] || this.defaultStates[toState];

        if (redirectState) {
          const rootState = toState.includes('tabs.company') ? 'tabs.company' : toState;
          if (rootState !== toState) {
            this.currentSubStates[rootState] = redirectState;
          }
          this.currentSubStates[toState] = redirectState;
          if (!environment.production) {
            console.log('RouterStateService onBefore - redirect to:', redirectState);
          }

          return transition.router.stateService.target(redirectState);
        }
      }
    );

    this.transitionService.onSuccess(
      {},
      (transition: Transition): HookResult => {
        const toState: string = transition.to().name;
        const redirectState: string = this.redirects[toState];
        if (redirectState) {
          if (!environment.production) {
            console.log('RouterStateService onSuccess - redirect to:', redirectState);
          }

          transition.router.stateService.go(redirectState);
        } else {
          // Check all sub state
          this.saveAllParentSubState(toState);
          const rootState = toState.includes('tabs.company') ? 'tabs.company' : toState;
          if (rootState !== toState) {
            this.currentSubStates[rootState] = toState;
          }

          this.currentState = toState;
        }
      }
    );
  }

  private saveAllParentSubState(toState: string): void {
    const stateParts: string[] = toState.split('.');
    while (stateParts.length >= 3) {
      const parentState: string = this.getParentState(toState, true);
      if (parentState) {
        this.currentSubStates[parentState] = toState;
      }

      return this.saveAllParentSubState(parentState);
    }
    return;
  }

  public registerDefault(state: string): void {
    const parentState: string = this.getParentState(state);
    if (parentState) {
      this.defaultStates[parentState] = state;
    }
  }

  public registerRedirect(fromState: string, toState: string): void {
    this.redirects[fromState] = toState;
    if (this.currentStateIs(fromState)) {
      this.stateService.go(toState);
    }
  }

  public clearRedirect(fromState: string): void {
    this.redirects[fromState] = undefined;
  }

  public clearCurrentSubState(fromState: string): void {
    this.currentSubStates[fromState] = undefined;
  }

  public currentStateIs(state: string | string[]): boolean {
    if (typeof state === 'string') {
      return this.currentState === state;
    } else {
      return state.includes(this.currentState);
    }
  }

  public parentStateIs(state: string): boolean {
    return this.getParentState(this.currentState) === state;
  }

  public currentStateStartsWith(state: string): boolean {
    return this.currentState.startsWith(state);
  }

  public navigateToState(state: string): void {
    this.stateService.go(state);
  }

  private getParentState(state: string = '', isGetSubState?: boolean): string {
    const stateParts: string[] = state.split('.');
    if (stateParts.length < 3 || stateParts[0] !== 'tabs') {
      return '';
    } else {
      stateParts.pop();
      while (stateParts.length > 3 && !isGetSubState) {
        stateParts.pop();
      }
      return stateParts.join('.');
    }
  }
}
