<div class="FormElement">
  <app-grid
    [data]="gridData"
    [editMode]="false"
    [filterable]="'menu'"
    [filter]="filter"
    (filterChange)="onFilterChange($event)"
    class="FormElement-grid margin-bottom-1 Company-payslipsGrid"
    [pageable]="true"
    [pageSize]="100"
    [miniPager]="true"
  >
    <app-grid-column
      [isFilterable]="true"
      [field]="'Description'"
      [title]="'EmployeePayslip.Description' | translate"
      [width]="190"
      [type]="'text'"
    ></app-grid-column>
    <app-grid-column
      [isFilterable]="true"
      [field]="'Amount'"
      [title]="'EmployeePayslip.Amount' | translate"
      [width]="100"
      [type]="'numeric'"
    ></app-grid-column>
  </app-grid>

  <app-button
    class="payslip-revert Button--card"
    [buttonClass]="'Button--card width-100'"
    [isDisabled]="!isPaymentApprover || sessionService.role.IsReadOnly"
    *ngIf="IsFinalizedBatch && selectedSalaryStatement"
    [tooltip]="revertButtonTooltip | translate"
    [label]="'CompanySalaryBatches.RevertThisPayslip' | translate"
    (clickEvent)="onRevertPayslip()"
  ></app-button>
</div>

<app-revert-payslip-dialog
  [(isVisible)]="revertPayslipDialogVisible"
  [employeeId]="selectedSalaryStatement?.UserEmploymentId"
  [salaryStatement]="selectedSalaryStatement"
  (revertCompleted)="revertBatchCompleted.emit()"
></app-revert-payslip-dialog>
