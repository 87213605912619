<div class="FormElement">
  <span class="FormElement-label" *ngIf="!tooltipContent && label">
    <abbr *ngIf="required && requiredLabel" class="FormElement-required" title="required">*</abbr> {{ label }}:
  </span>
  <div class="dx-field">
    <div [ngClass]="disabled || emptyDataSource ? 'multipleDropDownNotAllow' : ''">
      <dx-drop-down-box
        #dxDropDownBoxControl
        [ngClass]="{ 'input-required': requiredValue, multipleDropDownDisabled: disabled || emptyDataSource }"
        [valueExpr]="valueExp"
        [displayExpr]="displayExp"
        [placeholder]="'DropdownList.MultiSelectPlaceHolder' | translate"
        [showClearButton]="false"
        [dataSource]="gridDataSource"
        [(value)]="SelectValueIds"
        [disabled]="disabled || emptyDataSource"
        (textChange)="controlDisplayTextChange()"
      >
        <div *dxTemplate="let data of 'content'">
          <dx-data-grid
            showColumnHeaders="true"
            showColumnLines="false"
            [dataSource]="gridDataSource"
            [selection]="{ mode: 'multiple' }"
            [columns]="columns"
            [hoverStateEnabled]="true"
            [paging]="{ enabled: true, pageSize: 100 }"
            [height]="345"
            [(selectedRowKeys)]="GridBoxValue"
          ></dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>
  </div>
</div>
