<div class="Card-inner {{ hideClass }} breakHidePreviewGrid">
  <div class="Employee-payrollControlsPreViewTitle">
    <div class="Card-title Employee-payroll-title">
      <div>{{ 'EmploymentPayrollData.Preview' | translate }}</div>
      <div class="Employee-payrollButtons" *ngIf="payslipIconsVisible">
        <app-payslip-icon
          *ngIf="IsWeb && isShowError"
          id="ViewErrorTabButton"
          [payslipIconType]="'errorTab'"
          (click)="showValidationIssues(true)"
        >
        </app-payslip-icon>
        <app-payslip-icon
          *ngIf="IsWeb && isShowWarning && !isShowError"
          id="ViewWarningTabButton"
          [payslipIconType]="'warningTab'"
          (click)="showValidationIssues(false)"
        ></app-payslip-icon>
        <app-payslip-icon
          *ngIf="IsAppOrDesktop"
          id="ViewPayslipSameTabButton"
          [isHidden]="isHidden"
          [payslipIconType]="'htmlSameTab'"
          (click)="downloadSalaryStatementsPreview(true)"
        ></app-payslip-icon>
        <app-payslip-icon
          *ngIf="IsWeb"
          id="ViewPayslipNewTabButton"
          [payslipIconType]="'htmlNewTab'"
          (click)="downloadSalaryStatementsPreview(false)"
        ></app-payslip-icon>
        <!-- <app-payslip-icon
          class="print-icon-flex"
          *ngIf="IsWeb"
          id="ViewPayslipPrintTabButton"
          [payslipIconType]="'htmlPrintTab'"
          (click)="printSalaryStatementsPreview()"
        ></app-payslip-icon> -->
        <a *ngIf="isMobilePayrollDataSlide" (click)="onClosePreview()">
          <app-icon [icon]="'ChevronDown-small'"></app-icon>
        </a>
      </div>
    </div>
  </div>

  <div class="Employee-payrollControlsWrapper">
    <div class="Employee-payrollControlsSalaryPeriod">
      <div class="Period-control {{ classPayrollDataSlidePayrollDataSlide }}">
        <app-combobox-edit
          *ngIf="!isMobilePayrollDataSlide"
          [label]="'EmploymentPayrollData.SalaryPeriod' | translate"
          [editMode]="true"
          [comboboxDataSource]="periods"
          [(value)]="periodId"
          [textField]="'FriendlyName'"
          [idField]="'Id'"
          (valueChange)="onPeriodChanged()"
          [showNavigationButton]="true"
          [isConvertTime]="false"
          [keepDataSourceOrder]="true"
          [isDisbaleNavigationOnGettingOrSubmitting]="true"
        ></app-combobox-edit>

        <app-combobox-edit
          *ngIf="isMobilePayrollDataSlide"
          [editMode]="false"
          [comboboxDataSource]="periods"
          [textField]="'FriendlyName'"
          [idField]="'Id'"
          [specialShowNavigate]="true"
          [(value)]="periodId"
          [showNavigationButton]="true"
          (valueChange)="onPeriodChanged()"
          [isConvertTime]="false"
          [keepDataSourceOrder]="true"
        >
        </app-combobox-edit>
      </div>
    </div>

    <div class="FormElement">
      <div class="FormElement-control">
        <app-grid [data]="gridData" [editMode]="false" class="FormElement-grid Employee-payrollDataPreviewGrid">
          <app-grid-column [title]="''" [field]="'Description'" [type]="'text'" [width]="200"></app-grid-column>
          <app-grid-column [title]="''" [field]="'Amount'" [type]="'numeric'" [width]="90"></app-grid-column>
        </app-grid>
      </div>
    </div>
  </div>
</div>

<app-dialog
  [title]="'EmploymentPayrollData.PayrollIssue' | translate"
  [(visible)]="isShowValidationIssue"
  [overlayClose]="true"
>
  <app-payroll-issues class="SalaryBatchOtherErrorsGrid" [listPayrollIssues]="listPayrollIssues" [isError]="isError">
  </app-payroll-issues>
  <app-dialog-action type="Close"></app-dialog-action>
</app-dialog>
