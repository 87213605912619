import { OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Browser } from '../../common/browser';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { StaticDataService } from '../../services/api/static-data.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { BroadcastService } from '../../services/broadcast.service';
import { ModalService } from '../../services/modal.service';
import { SessionService } from '../../services/session/session.service';
var ReloginDialogComponent = /** @class */ (function () {
    function ReloginDialogComponent(translateService, authenticationService, broadcaster, sessionService, modalService, staticDataService, ngxZendeskWebwidgetService) {
        this.translateService = translateService;
        this.authenticationService = authenticationService;
        this.broadcaster = broadcaster;
        this.sessionService = sessionService;
        this.modalService = modalService;
        this.staticDataService = staticDataService;
        this.ngxZendeskWebwidgetService = ngxZendeskWebwidgetService;
        this.password = '';
        this.changeTemporaryPasswordVisible = false;
        this.isLoggingIn = false;
        this.visibleValue = false;
        this.ngUnsubscribe = new Subject();
        this.showResetPasswordDialog = false;
        this.setupEventHandlers();
    }
    Object.defineProperty(ReloginDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (val) {
            this.visibleValue = val;
            if (val) {
                this.modalService.alertVisible = false;
                if (this.ngxZendeskWebwidgetService && typeof this.ngxZendeskWebwidgetService.zE === 'function') {
                    this.ngxZendeskWebwidgetService.zE('webWidget', 'close');
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReloginDialogComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    ReloginDialogComponent.prototype.ngOnInit = function () {
        this.userName = localStorage.getItem('saveLoginUserName');
        this.translateText();
    };
    Object.defineProperty(ReloginDialogComponent.prototype, "isNullPassWord", {
        get: function () {
            if (!this.password) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    ReloginDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    ReloginDialogComponent.prototype.translateText = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            _this.translateService
                .get(['Error.NetworkError', 'Login.WrongUserNameOrPassword'])
                .subscribe(function (translations) {
                _this.networkErrorMessage = translations['Error.NetworkError'].replace('{{SystemAlias}}', environment.branding.SystemAlias);
                _this.WrongUsernameOrPassword = translations['Login.WrongUserNameOrPassword'];
                _this.SystemClosed = translations['Messages.SystemClosed'];
            });
        });
    };
    ReloginDialogComponent.prototype.setupEventHandlers = function () {
        var _this = this;
        this.broadcaster
            .on(Constants.SESSION_EXPIRED)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) {
            _this.visible = true;
            _this.sessionService.checkIsAliveError = true;
        });
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (e) { });
    };
    ReloginDialogComponent.prototype.keydown = function (e) {
        if (e.keyCode === 13) {
            this.login();
        }
        else {
            this.errorMessage = undefined;
        }
    };
    ReloginDialogComponent.prototype.logout = function () {
        var _this = this;
        this.authenticationService
            .logout()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function () {
            _this.navigateToLogin();
        }, function () {
            _this.navigateToLogin();
        });
    };
    ReloginDialogComponent.prototype.navigateToLogin = function () {
        this.sessionService.NavigateTo('login');
    };
    Object.defineProperty(ReloginDialogComponent.prototype, "IsDanishCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReloginDialogComponent.prototype, "IsGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReloginDialogComponent.prototype, "IsMobile", {
        get: function () {
            return this.sessionService.browser.isMobile || this.sessionService.browser.isHybridApp;
        },
        enumerable: true,
        configurable: true
    });
    ReloginDialogComponent.prototype.reloadStaticData = function () {
        var _this = this;
        this.staticDataService.loadStaticData().then(function () {
            var isAvailableTab = false;
            var currentState = _this.sessionService.currentState;
            _this.sessionService.refeshRoleAndFeature();
            if (currentState) {
                isAvailableTab = _this.sessionService.isAvailableTab(currentState, _this.IsDanishCompany, _this.IsGreenlandCompany);
            }
            if (!isAvailableTab) {
                _this.sessionService.switchToDefaultTab();
            }
            setTimeout(function () {
                _this.close();
                window.location.reload();
            });
        });
    };
    ReloginDialogComponent.prototype.login = function () {
        var _this = this;
        if (this.isLoggingIn) {
            return;
        }
        this.isLoggingIn = true;
        this.authenticationService.credentials = {
            Username: Global.SESSION ? Global.SESSION.SignOnToken.Username : '',
            Password: this.password ? this.password : '',
            Remember: false
        };
        var message;
        this.authenticationService
            .login()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (response) {
            var mustChangePassword = response.MustChangePassword;
            if (mustChangePassword) {
                _this.broadcaster.broadcast(Constants.ACCOUNT_CHANGE_TEMPORARY_PASSWORD, _this.password);
                _this.changeTemporaryPasswordVisible = true;
                _this.isLoggingIn = false;
                _this.close();
            }
            else {
                _this.reloadStaticData();
            }
        }, function (reason) {
            // Failure
            switch (reason.status) {
                case 0:
                    message = _this.networkErrorMessage;
                    break;
                case 401:
                    message = _this.WrongUsernameOrPassword;
                    break;
                case 400:
                    if (!reason || !reason.data) {
                        message = _this.SystemClosed;
                        break;
                    }
                    message = reason.data.Message;
                    break;
                case 500:
                    // Internal server error
                    var errorMessage_1 = reason.statusText;
                    if (reason.config && reason.config.url) {
                        errorMessage_1 += ' - ' + reason.config.url;
                    }
                    Sentry.withScope(function (scope) {
                        scope.setExtra('extra', reason);
                        Sentry.captureException(new Error(errorMessage_1));
                    });
                    break;
                default:
                    var othermessage_1 = reason.statusText;
                    if (reason.config && reason.config.url) {
                        othermessage_1 += ' - ' + reason.config.url;
                    }
                    Sentry.withScope(function (scope) {
                        scope.setExtra('extra', reason);
                        Sentry.captureException(new Error(othermessage_1));
                    });
                    break;
            }
            _this.errorMessage = message;
            console.error('Login failed: ');
            console.error(reason);
            _this.isLoggingIn = false;
        });
    };
    ReloginDialogComponent.prototype.close = function () {
        this.visible = false;
    };
    ReloginDialogComponent.prototype.showpassword = function (isShow) {
        if (isShow) {
            this.show = isShow;
        }
        else {
            this.show = false;
        }
    };
    ReloginDialogComponent.prototype.onResetPassword = function () {
        this.showResetPasswordDialog = true;
    };
    ReloginDialogComponent.prototype.onDialogAction = function (action) {
        if (action === 'Continue') {
            this.login();
        }
        else {
            this.logout();
        }
    };
    Object.defineProperty(ReloginDialogComponent.prototype, "isMobile", {
        get: function () {
            var browser = new Browser();
            return browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    ReloginDialogComponent.prototype.showPaswordForMobile = function () {
        if (this.isMobile) {
            this.show = true;
        }
    };
    ReloginDialogComponent.prototype.onTemporaryPasswordChanged = function (newPassword) {
        this.password = newPassword;
        this.authenticationService.credentials.Password = this.password;
        this.login();
    };
    ReloginDialogComponent.prototype.onNotChangeTempPassword = function () {
        this.logout();
    };
    return ReloginDialogComponent;
}());
export { ReloginDialogComponent };
