<app-fixed-panel
  [excludedComponents]="listClassExcluded"
  [editMode]="service.editMode"
  [specificClassName]="'Island-accountant--user'"
>
  <div class="accountant-container" [ngClass]="{ 'is-editing': service.editMode }" (dblclick)="tabDblclick($event)">
    <div class="Island Island-accountant Island-accountant--user">
      <h1 class="Company-accountName" [innerHTML]="accountName"></h1>
      <div class="Cards Cards--flex">
        <div
          class="Card Card--large Card-inner max-width1000 accountant-user-grid"
          [ngClass]="isShowPaginationControl ? 'isShowPaginationControl' : ''"
        >
          <div class="Employee-timeControls">
            <div class="FormElement-control">
              <div class="FormElement-control">
                <app-text-edit
                  class="FormEmlement accountant-user-search"
                  [label]="'CompanySalaryBatches.Search' | translate"
                  [icon]="'Search'"
                  [placeholder]="'Accountant.FilterUsers' | translate"
                  [editMode]="true"
                  [(value)]="service.filter"
                  [readonlyOnNotFocus]="true"
                  [disabled]="false"
                ></app-text-edit>
                <app-button
                  buttonClass="Button--card"
                  class="Button--card accountantAdNewButton"
                  (clickEvent)="addNewUserVisible = true"
                  [label]="'Accountant.AddNewUser' | translate"
                >
                </app-button>
              </div>
            </div>
          </div>

          <app-grid
            class="accountant"
            [data]="gridUserFilterData"
            [editMode]="service.editMode"
            [pageable]="true"
            [(skip)]="service.currentPage"
            [idColumn]="'Id'"
            [pageSize]="pageSize"
            [(selectedItem)]="userSelected"
            [(selectedId)]="service.selectedId"
            [filterable]="'menu'"
            [filter]="userFilter"
            (filterChange)="onUserFilterChange($event)"
            (selectedIdChange)="onSelectedIdChange($event)"
            (dblClick)="tabDblclick($event.event)"
            (controlDataChange)="UserGridChange($event)"
            [(triggerUpdate)]="triggerUpdate"
            [reFocusAfterChangeEdit]="false"
          >
            <app-grid-column
              [field]="'Id'"
              [type]="'numeric'"
              [width]="25"
              [title]="'Accountant.Id' | translate"
              [isFilterable]="true"
              [numbericOption]="'0'"
              [editable]="false"
              [viewClass]="'truncated'"
            >
            </app-grid-column>
            <app-grid-column
              [field]="'FirstName'"
              [type]="'text'"
              [title]="'Employee.FirstName' | translate"
              [width]="70"
              [editable]="service.editMode"
              [viewClass]="'truncated'"
              [tooltipField]="'FirstName'"
              *ngIf="!service.isCompanyAdmin"
            ></app-grid-column>
            <app-grid-column
              [field]="'MiddleName'"
              [type]="'text'"
              [title]="'Employee.MiddleName' | translate"
              [width]="70"
              [isFilterable]="true"
              [editable]="service.editMode"
              *ngIf="!service.isCompanyAdmin"
              [viewClass]="'truncated'"
            >
            </app-grid-column>
            <app-grid-column
              [field]="'LastName'"
              [type]="'text'"
              [title]="'Employee.LastName' | translate"
              [width]="70"
              [editable]="service.editMode"
              [viewClass]="'truncated'"
              [tooltipField]="'LastName'"
              *ngIf="!service.isCompanyAdmin"
            ></app-grid-column>
            <app-grid-column
              [field]="'FullName'"
              [type]="'text'"
              [title]="'Employee.FullName' | translate"
              [width]="300"
              [isFilterable]="true"
              *ngIf="service.isCompanyAdmin"
              [viewClass]="'truncated'"
            >
            </app-grid-column>
            <app-grid-column
              [field]="'TelMobile'"
              [type]="'text'"
              [title]="'EmployeeGeneral.TelMobile' | translate"
              [width]="90"
              [isFilterable]="true"
              [editable]="service.editMode"
              [appTextType]="'number'"
              *ngIf="!service.isCompanyAdmin"
              [viewClass]="'truncated'"
            ></app-grid-column>
            <app-grid-column
              [field]="'Email'"
              [type]="'text'"
              [title]="'EmployeeGeneral.Email' | translate"
              [width]="90"
              [isFilterable]="true"
              [viewClass]="'truncated'"
              [tooltipField]="'Email'"
              [editable]="service.editMode"
              [appTextType]="'email'"
              *ngIf="!service.isCompanyAdmin"
            >
            </app-grid-column>
            <app-grid-column
              [type]="'combobox'"
              [field]="'AccountRoleId'"
              [title]="'Accountant.Organization' | translate"
              [comboboxDataSource]="staticData.AccountantRole | async"
              [comboboxValueField]="'Id'"
              [comboboxDisplayField]="'Name'"
              [width]="95"
              [isFilterable]="true"
              [headerTooltip]="'Accountant.OrganizationTooltip' | translate"
              [editable]="service.editMode"
              [viewClass]="'truncated'"
            >
            </app-grid-column>
            <app-grid-column
              *ngIf="service.isCompanyAdmin"
              [field]="'IsPaymentApprover'"
              [editable]="service.editMode"
              [type]="'checkboxgrid'"
              [title]="'Accountant.IsPaymentApprover' | translate"
              [width]="90"
              [isFilterable]="true"
              [headerTooltip]="'Accountant.IsPaymentApprover' | translate"
              [viewClass]="'truncated'"
            ></app-grid-column>
          </app-grid>
        </div>
        <div class="Card Card--small Card-inner">
          <div class="Card-title ExtraMarginBottom">
            {{ 'Accountant.CompanyAccess' | translate }} {{ userSelected ? userSelected.FirstName : '' }}
          </div>
          <app-grid
            class="accountantemployeecompanies"
            [data]="gridFilterData"
            [pageable]="true"
            [pageSize]="100"
            [filterable]="'menu'"
            [filter]="filter"
            (filterChange)="onFilterChange($event)"
            [editMode]="service.editMode"
            [reFocusAfterChangeEdit]="false"
            (dblClick)="tabDblclick($event)"
            (controlDataChange)="onGridRoleControlDataChange($event)"
            (saveChangesEvent)="onRowDataChange($event)"
            (selectedItemChange)="onUserRoleSelected($event)"
            [idColumn]="'CompanyId'"
            [reFocusAfterChangeEdit]="false"
          >
            <app-grid-column
              [field]="'CompanyName'"
              [editable]="false"
              [type]="'text'"
              [title]="'Accountant.CompanyName' | translate"
              [isFilterable]="true"
              [headerTooltip]="'Accountant.CompanyName' | translate"
              [viewClass]="'truncated'"
              [width]="42"
            >
            </app-grid-column>
            <app-grid-column
              [field]="'RoleId'"
              [type]="'combobox'"
              [comboboxDataSource]="staticData.Role | async"
              [comboboxValueField]="'Id'"
              [comboboxDisplayField]="'Name'"
              [allowComboeditNull]="true"
              [defaultnullvalue]="0"
              [editable]="service.editMode"
              [title]="'Accountant.Role' | translate"
              [width]="29"
              [isFilterable]="true"
              [CurrentTextComboboxToolTip]="false"
              [headerTooltip]="'Accountant.Role' | translate"
              [viewClass]="'truncated'"
            ></app-grid-column>
            <app-grid-column
              [field]="'IsPaymentApprover'"
              [editable]="service.editMode"
              [type]="'checkboxgrid'"
              [title]="'Accountant.IsPaymentApprover' | translate"
              [width]="29"
              [isFilterable]="true"
              [headerTooltip]="'Accountant.IsPaymentApprover' | translate"
              [viewClass]="'truncated'"
            ></app-grid-column>
          </app-grid>
        </div>
      </div>
    </div>
  </div>
</app-fixed-panel>
<div [ngClass]="{ 'is-editing': service.editMode }">
  <div class="Actions" *ngIf="!isMobile && !service.editMode" [ngClass]="isIOSApp ? 'is-ios-app' : ''">
    <app-action-button
      *ngIf="isAccountantAdmin && !service.isBasicUser"
      [buttonClass]="'Action-button Action-buttonAdd'"
      (click)="addNewUserVisible = true"
      [icon]="'AddUser'"
      [tooltip]="'Accountant.AddNewUser' | translate"
    >
    </app-action-button>
    <!-- <app-action-button
      id="ActionButtonAdd"
      [buttonClass]="'Action-button Action-buttonAdd'"
      [icon]="'Add'"
      [label]="'Accountant.AddNewCompany'"
      (click)="addNewUserDialogVisible = true"
      [tooltip]="'Accountant.AddNewCompany'"
    ></app-action-button> -->
    <app-menu-button
      *ngIf="isRemoveUser"
      (menuClick)="onMoreMenuOptionClick($event)"
      [menuIcon]="'ThreeDots'"
      [menuLabel]="'More.Title'"
      [menuTooltip]="'More.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item (menuItemClick)="onMoreMenuOptionClick($event)" [field]="'RemoveUser'">
      </app-menu-button-item>
    </app-menu-button>
    <app-menu-button
      [editButtonMenu]="true"
      [editMode]="false"
      (menuChangeClick)="onEditModeChange($event)"
      [menuCondition]="true"
      *ngIf="service.isShowEditMode"
    ></app-menu-button>

    <app-menu-button
      *ngIf="reportDialogService.isVisibleReportIcon"
      [menuCondition]="true"
      [menuIcon]="'File'"
      [menuLabel]="'Employee.Reports'"
      [menuTooltip]="'Employee.Reports'"
      [actionButtonMenu]="true"
      [menuButtonClass]="'Action-button Action-buttonReports'"
      [menuButtonId]="'ActionButtonReports'"
      (menuClick)="reportDialogService.onShowReportsEventClick()"
    >
    </app-menu-button>
  </div>
  <div class="Actions" *ngIf="isMobile && !service.editMode" [ngClass]="isIOSApp ? 'is-ios-app' : ''">
    <app-menu-button
      (menuClick)="onMoreMenuOptionClick($event)"
      [menuIcon]="'ThreeDots'"
      [menuLabel]="'More.Title'"
      [menuTooltip]="'More.Title'"
      [multiMenuItems]="true"
    >
      <app-menu-button-item
        *ngIf="isAccountantAdmin && !service.isBasicUser"
        (menuItemClick)="addNewUserVisible = true"
        [field]="'AccountantAddNewUser'"
      >
      </app-menu-button-item>
      <app-menu-button-item *ngIf="isRemoveUser" (menuItemClick)="onMoreMenuOptionClick($event)" [field]="'RemoveUser'">
      </app-menu-button-item>
    </app-menu-button>
    <app-menu-button
      [editButtonMenu]="true"
      [editMode]="false"
      (menuChangeClick)="onEditModeChange($event)"
      [menuCondition]="true"
      *ngIf="service.isShowEditMode"
    ></app-menu-button>
  </div>
  <div class="EditingInfo Actions" *ngIf="service.editMode">
    <app-button
      [buttonClass]="'Discard'"
      (clickEvent)="confirmAndDiscardChanges()"
      [label]="'Employee.DiscardButton' | translate"
      *ngIf="service.editMode"
    ></app-button>
    <app-menu-button
      [editButtonMenu]="true"
      [editMode]="true"
      (menuChangeClick)="onEditModeChange($event)"
      [menuCondition]="true"
      class="menu-button--editing"
    >
    </app-menu-button>
  </div>
</div>

<app-dialog
  class="blue-dialog"
  [(visible)]="addNewUserDialogVisible"
  [leadingText]="'Accountant.WarnningAddNewUser' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="removeUserAction($event)"
  [(visible)]="removeUserDialogVisible"
  [leadingText]="'Accountant.WarningRemoveUser' | translate"
>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action type="Delete"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  (action)="onDoneRemoveSuccess($event)"
  [(visible)]="removeSuccessConfirm"
  [leadingText]="'Accountant.RemoveUserConfirm' | translate"
>
  <app-dialog-action type="Ok"></app-dialog-action>
</app-dialog>

<app-dialog
  (action)="discardDialogAction($event)"
  [(visible)]="discardDialogVisible"
  [leadingText]="'Employee.DiscardMessage' | translate"
>
  <app-dialog-action type="CancelDiscard"></app-dialog-action>
  <app-dialog-action type="ConfirmDiscard"></app-dialog-action>
</app-dialog>

<app-dialog (action)="onConfirmSaveDataOnChangeState($event)" [(visible)]="service.showSaveDataConfirmationChangeState">
  <div [innerHTML]="'Employee.UnsavedData' | translate"></div>
  <app-dialog-action [disabled]="sessionService.isSubmitting" type="DiscardAndLeave"></app-dialog-action>
  <app-dialog-action [disabled]="sessionService.isSubmitting" type="Stay"></app-dialog-action>
  <app-dialog-action [disabled]="sessionService.isSubmitting" type="ContinueAndSave"></app-dialog-action>
</app-dialog>

<app-new-user-dialog (createUserComplete)="onCreateUserComplete($event)" [(visible)]="addNewUserVisible">
</app-new-user-dialog>
