import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DataService } from '../../../services/api/data.service';
import { SessionService } from '../../../services/session/session.service';
import { CompanySalaryBatchesChildFormEntityContext } from '../company-salary-batches-child-form-entity-context';
import { CompanySalaryBatchService } from '../company-salary-batches.service';

@Component({
  selector: 'app-lock-salary-batch-dialog',
  templateUrl: './lock-salary-batch-dialog.component.html'
})
export class LockSalaryBatchDialogComponent implements OnDestroy {
  private visibleValue: boolean;
  @Input()
  public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (value !== this.visibleValue) {
      this.visibleValue = value;
      this.visibleChange.emit(value);
      if (value) {
        this.notifyEmployee = true;
      }
    }
  }

  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public batchLocked: EventEmitter<boolean> = new EventEmitter<boolean>();

  public notifyEmployee = true;
  private salaryBatchId: number;

  constructor(
    private dataService: DataService,
    public sessionService: SessionService,
    private companySalaryBatchService: CompanySalaryBatchService
  ) {
    this.companySalaryBatchService.salaryBatchEntityContext
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: CompanySalaryBatchesChildFormEntityContext) => {
        if (value && value.BatchId) {
          this.salaryBatchId = value.BatchId;
        }
      });
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onDialogAction(action: string): void {
    if (action === 'SubmitForApproval') {
      if (this.notifyEmployee) {
        this.dataService.SalaryBatches_ApproveSalaryBatchWithPreliminaryPayslips(this.salaryBatchId).subscribe(() => {
          this.batchLocked.emit();
          this.visible = false;
        });
      } else {
        this.dataService.SalaryBatches_ApproveSalaryBatch(this.salaryBatchId).subscribe(() => {
          this.batchLocked.emit();
          this.visible = false;
        });
      }
    }
  }
}
