<app-dialog
  width="794"
  [overlayClose]="false"
  [(visible)]="visible"
  (action)="closeForm($event)"
  [title]="'NewEmployee.ManualDetailsInput' | translate"
  class="Form--newEmployeeModal ModalDialogInfo"
  [ngClass]="{ 'employee-greenland': isGreenlandCompany }"
  [customVisible]="true"
>
  <div class="Modal-content" *ngIf="visible">
    <div class="FormElement">
      <div class="FormElement-legend FormElement-label name">
        <span> <abbr class="FormElement-required" title="required">*</abbr>{{ 'NewEmployee.Name' | translate }} </span>
      </div>
      <div class="FormElement-row">
        <div class="FormElement">
          <label class="FormElement-label u-hideMobile" for="firstname">
            {{ ('NewEmployee.FirstName' | translate) + ': ' }}
          </label>
          <input
            class="FormElement-input"
            type="text"
            id="firstname"
            placeholder="{{ 'NewEmployee.FirstName' | translate }}"
            [(ngModel)]="newEmployeeRequest.Details.FirstName"
          />
        </div>
        <div class="FormElement">
          <label class="FormElement-label u-hideMobile" for="middlename">
            {{ ('NewEmployee.MiddleName' | translate) + ': ' }}
          </label>
          <input
            class="FormElement-input"
            type="text"
            id="middlename"
            placeholder="{{ 'NewEmployee.MiddleName' | translate }}"
            [(ngModel)]="newEmployeeRequest.Details.MiddleName"
          />
        </div>
        <div class="FormElement">
          <label class="FormElement-label u-hideMobile" for="lastname">
            {{ ('NewEmployee.LastName' | translate) + ': ' }}
          </label>
          <input
            class="FormElement-input"
            type="text"
            id="lastname"
            placeholder="{{ 'NewEmployee.LastName' | translate }}"
            [(ngModel)]="newEmployeeRequest.Details.LastName"
          />
        </div>
      </div>
    </div>

    <div class="FormElement cpr">
      <div class="FormElement-row">
        <div class="FormElement-control NewEmployeeLeft">
          <app-text-edit
            [label]="'NewEmployee.CPRno' | translate"
            [editMode]="true"
            style="width: 100%"
            [disabled]="noCPRNumber"
            [isShowSymbolRequired]="!noCPRNumber"
            [(value)]="newEmployeeRequest.IdentityNumber"
          ></app-text-edit>
        </div>

        <div class="FormElement-control">
          <label class="FormElement-checkbox">
            <input type="checkbox" [(ngModel)]="noCPRNumber" (ngModelChange)="onNoCPRCheckboxClicked()" />
            <span class="FormElement-checkboxFaux"></span>
            <span class="FormElement-label">{{ 'NewEmployee.NoCPRnumber' | translate }}</span>
          </label>
        </div>

        <!-- <div class="FormElement-control gender" *ngIf="noCPRNumber">
          <app-combobox-edit
            style="width: 100%"
            [label]="'NewEmployee.Gender' | translate"
            [editMode]="true"
            [required]="false"
            [comboboxDataSource]="staticDataService.Gender | async"
            [textField]="'Name'"
            [idField]="'Id'"
            [nullValueLabel]="'NewEmployee.NoGender' | translate"
            [(value)]="GenderId"
            (valueChange)="onGenderValueChange()"
          ></app-combobox-edit>
        </div> -->
      </div>
    </div>

    <div class="FormElement">
      <div class="FormElement-row">
        <div class="FormElement-control NewEmployeeLeft">
          <app-text-edit
            [label]="'NewEmployee.Email' | translate"
            [editMode]="true"
            [type]="'email'"
            style="width: 100%"
            [(value)]="newEmployeeRequest.Details.CompanyEmail"
          ></app-text-edit>
        </div>

        <div class="FormElement-control NewEmployeeEmailPrivate">
          <app-text-edit
            [label]="'NewEmployee.EmailPrivate' | translate"
            [editMode]="true"
            [type]="'email'"
            style="width: 100%"
            [(value)]="newEmployeeRequest.Details.PersonalEmail"
          ></app-text-edit>
        </div>
      </div>
    </div>
    <div class="FormElement">
      <div class="FormElement-row">
        <div class="FormElement-control NewEmployeeLeft NewEmployeeRole">
          <app-combobox-edit
            style="width: 100%"
            [label]="'NewEmployee.Role' | translate"
            [editMode]="true"
            [required]="true"
            [comboboxDataSource]="roleDataSource"
            [textField]="'Name'"
            [idField]="'Id'"
            [(value)]="newEmployeeRequest.Details.RoleId"
          ></app-combobox-edit>
        </div>

        <app-module-check [moduleId]="5" [(hasModule)]="hasLanguageModule" class="NewEmployeeRole">
          <div class="FormElement-control NewEmployeeRight">
            <app-combobox-edit
              style="width: 100%"
              [disable]="!hasLanguageModule"
              [label]="'NewEmployee.Language' | translate"
              [editMode]="true"
              [comboboxDataSource]="staticDataService.languages | async"
              [textField]="'Name'"
              [idField]="'Id'"
              [(value)]="newEmployeeRequest.LanguageId"
            ></app-combobox-edit>
          </div>
        </app-module-check>
      </div>
    </div>

    <div class="FormElement">
      <div class="FormElement-row">
        <div
          class="FormElement-control"
          [ngClass]="{ NewEmployeeLeft: !noCPRNumber && employmentTemplateSelected, NewEmployeeFull: noCPRNumber }"
        >
          <app-combobox-edit
            style="width: 100%"
            [label]="'NewEmployee.Template' | translate"
            [editMode]="true"
            [disable]="noCPRNumber"
            [comboboxDataSource]="templates"
            [textField]="'Name'"
            [idField]="'Id'"
            [(value)]="newEmployeeRequest.EmploymentTemplateId"
            (valueChange)="onEmploymentTemplateChanged()"
            [nullValueLabel]="'NewEmployee.ChooseExistingOrAddNewPosition' | translate"
          ></app-combobox-edit>
        </div>
        <div class="FormElement-control NewEmployeeRight" *ngIf="employmentTemplateSelected">
          <app-auto-complete-textbox
            style="width: 100%"
            id="aucompleteNewEmployee_Title"
            class="FormElement-autoComplete"
            [autoCompleteDataSource]="staticDataService.employmentTitle | async"
            [isLabelHtml]="true"
            [label]="'NewEmployee.Title' | translate"
            [editMode]="true"
            [(value)]="newEmployeeRequest.Title"
          >
          </app-auto-complete-textbox>
        </div>
      </div>
    </div>

    <div class="FormElement">
      <div class="FormElement-row">
        <div class="FormElement-control NewEmployeeLeft" *ngIf="isShowForEmploymentTemplateId">
          <app-combobox-edit
            style="width: 100%"
            [label]="'NewEmployee.Manager' | translate"
            [editMode]="true"
            [comboboxDataSource]="staticDataService.companyUsers | async"
            [textField]="'FullName'"
            [idField]="'Id'"
            [(value)]="newEmployeeRequest.ManagerCompanyUserId"
            [nullValueLabel]="'DropdownList.None' | translate"
          >
          </app-combobox-edit>
        </div>
        <app-module-check
          [ngClass]="{
            NewEmployeeRole: !noCPRNumber,
            NewEmployeeFull: noCPRNumber || !employmentTemplateSelected,
            NewEmployeeLeft: noCPRNumber || !employmentTemplateSelected
          }"
          *ngIf="!departments || !(departments.length === 0)"
          [moduleId]="4"
          [(hasModule)]="hasDepartmentEnabled"
        >
          <div class="FormElement-control" [ngClass]="{ NewEmployeeRight: !noCPRNumber && employmentTemplateSelected }">
            <app-combobox-edit
              style="width: 100%"
              [disable]="!hasDepartmentEnabled"
              [label]="'EmployeeGeneral.Department' | translate"
              [editMode]="true"
              [comboboxDataSource]="departments"
              [textField]="'Name'"
              [idField]="'Id'"
              [(value)]="newEmployeeRequest.DepartmentId"
              (valueChange)="onDepartmentComboBoxChanged()"
              [nullValueLabel]="'DropdownList.None' | translate"
              [keepDataSourceOrder]="true"
            ></app-combobox-edit>
          </div>
        </app-module-check>
      </div>
    </div>

    <div class="FormElement">
      <div class="FormElement-row">
        <div class="FormElement-control NewEmployeeLeft">
          <app-date-picker
            [e2e]="'HiringDate'"
            style="width: 100%"
            [isLabelHtml]="true"
            [label]="('NewEmployee.HiringDate' | translate) + ': '"
            [editMode]="true"
            [required]="employmentTemplateSelected"
            [requiredMessage]="'EmployeeGeneral.Required' | translate"
            [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
            [(value)]="newEmployeeRequest.HireDate"
            [(isValid)]="dateValid"
          ></app-date-picker>
        </div>
        <div class="FormElement-control NewEmployeeRight NewEmployeeRight--label">
          <app-date-picker
            style="width: 100%"
            [label]="'EmployeeGeneral.TerminationDate' | translate"
            [editMode]="true"
            [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
            [(value)]="newEmployeeRequest.ExpireDate"
            [(isValid)]="dateValid"
          ></app-date-picker>
        </div>
      </div>
    </div>

    <div class="FormElement">
      <div class="FormElement-control">
        <app-text-edit
          [label]="'NewEmployee.Address' | translate"
          [editMode]="true"
          style="width: 100%"
          [(value)]="newEmployeeRequest.Details.Address.Line1"
        ></app-text-edit>
      </div>
    </div>

    <div class="FormElement">
      <div class="FormElement-control NewEmployeeCountry">
        <app-combobox-edit
          [label]="'NewEmployee.Country' | translate"
          [editMode]="true"
          [comboboxDataSource]="staticDataService.Country | async"
          [textField]="'Name'"
          [idField]="'Id'"
          [(value)]="newEmployeeRequest.Details.Address.CountryId"
          (valueChange)="onCountryChange()"
        >
        </app-combobox-edit>
      </div>
      <div class="FormElement-control NewEmployeePostal">
        <app-text-edit
          [placeholder]="'NewEmployee.PostalCode' | translate"
          [editMode]="true"
          (blur)="onPostalCodeChanged()"
          [(value)]="newEmployeeRequest.Details.Address.PostalCode"
        >
        </app-text-edit>
      </div>
      <div class="FormElement-control NewEmployeeCity">
        <app-text-edit
          [placeholder]="'NewEmployee.City' | translate"
          [editMode]="true"
          [(value)]="newEmployeeRequest.Details.Address.City"
          (blur)="onCityBlurChange(newEmployeeRequest.Details.Address.City)"
        ></app-text-edit>
      </div>
    </div>

    <div class="FormElement" *ngIf="isGreenlandCompany && employmentTemplateSelected">
      <div class="FormElement-control">
        <app-combobox-edit
          style="width: 100%"
          [label]="'NewEmployee.Municipality' | translate"
          [editMode]="true"
          [textField]="'Name'"
          [idField]="'Id'"
          [required]="true"
          [comboboxDataSource]="municipality"
          [(value)]="newEmployeeRequest.Details.MunicipalityId"
          (valueChange)="onMunicipalityChanged()"
        >
        </app-combobox-edit>
      </div>
    </div>

    <div class="NewEmployeeCompany FormElement greenland" *ngIf="isGreenlandCompany && employmentTemplateSelected">
      <div class="FormElement-row">
        <span class="FormElement-label"></span>
        <div class="FormElement-control">
          <label class="FormElement-checkbox">
            <input type="checkbox" [(ngModel)]="newEmployeeRequest.ApplyDanishRulesInGreenland" />
            <span class="FormElement-checkboxFaux"></span>
            <span class="FormElement-label FormElement-label--new-employee">
              {{ 'NewEmployee.CalculateDanishATPandAMcontribution' | translate }}
            </span>
          </label>
        </div>
      </div>
    </div>

    <div *ngIf="!isSwedenCompany" class="FormElement">
      <div class="FormElement-control">
        <app-combobox-edit
          style="width: 100%"
          [label]="'NewEmployee.TaxCardType' | translate"
          [editMode]="true"
          [comboboxDataSource]="taxCardTypes"
          [textField]="'Name'"
          [idField]="'Id'"
          [(value)]="newEmployeeRequest.TaxCardTypeId"
        ></app-combobox-edit>
      </div>
    </div>

    <div *ngIf="isSwedenCompany" class="FormElement">
      <div class="FormElement-row">
        <div class="FormElement-control NewEmployeeLeft">
          <app-combobox-edit
            style="width: 100%"
            [label]="'NewEmployee.Tax table' | translate"
            [editMode]="true"
            [comboboxDataSource]="taxTableNumberDataSource"
            [textField]="'Name'"
            [idField]="'Id'"
            [(value)]="newEmployeeRequest.TaxTableNumber"
          ></app-combobox-edit>
        </div>

        <div class="FormElement-control NewEmployeeRight">
          <app-numeric-edit
            style="width: 100%"
            [label]="'NewEmployee.Column' | translate"
            [editMode]="true"
            [(value)]="newEmployeeRequest.TaxColumnNumber"
            [option]="TaxColumnNumberOption"
            (valueChange)="TaxColumnNumberChange($event)"
            (blur)="TaxColumnNumberBlur()"
          ></app-numeric-edit>
        </div>
      </div>
    </div>

    <div class="FormElement" *ngIf="employmentTemplateSelected && isGreenlandCompany">
      <div class="FormElement-row">
        <div class="FormElement-control NewEmployeeLeft">
          <app-percentage-edit
            [label]="'NewEmployee.TaxRate' | translate"
            style="width: 100%"
            [editMode]="true"
            [(value)]="newEmployeeRequest.TaxRate"
          ></app-percentage-edit>
        </div>
        <div class="FormElement-control NewEmployeeRight NewEmployeeRight--label" *ngIf="isPrimanyTaxCard">
          <app-numeric-edit
            [label]="'NewEmployee.MonthlyDeduction' | translate"
            [editMode]="true"
            [(value)]="newEmployeeRequest.TaxDeduction"
          ></app-numeric-edit>
        </div>
        <div class="FormElement-control NewEmployeeRight" *ngIf="isFreeTaxCard">
          <app-numeric-edit
            [label]="'NewEmployee.RemainingFreeAmount' | translate"
            [editMode]="true"
            [(value)]="newEmployeeRequest.TaxCardFreeAmount"
          ></app-numeric-edit>
        </div>
      </div>
    </div>

    <div class="FormElement">
      <div class="FormElement-row">
        <div class="FormElement-control NewEmployeeLeft">
          <app-text-edit
            [label]="'NewEmployee.PhoneNo' | translate"
            [editMode]="true"
            [type]="'number'"
            style="width: 100%"
            [(value)]="newEmployeeRequest.Details.TelMobile"
          ></app-text-edit>
        </div>

        <div class="FormElement-control NewEmployeeEmailPrivate">
          <app-text-edit
            [label]="'NewEmployee.PhoneNoPrivate' | translate"
            [editMode]="true"
            [type]="'number'"
            style="width: 100%"
            [(value)]="newEmployeeRequest.Details.TelFixed"
          ></app-text-edit>
        </div>
      </div>
    </div>

    <div class="FormElement" *ngIf="isShowForEmploymentTemplateId">
      <div class="FormElement-row">
        <div class="FormElement-control NewEmployeeLeft">
          <app-text-edit
            [label]="'NewEmployee.RegisterNo' | translate"
            [editMode]="true"
            style="width: 100%"
            [(value)]="newEmployeeRequest.Details.BankRegNo"
          ></app-text-edit>
        </div>

        <div class="FormElement-control NewEmployeeRight NewEmployeeRight--label">
          <app-text-edit
            [label]="'NewEmployee.BankAccountNo' | translate"
            [editMode]="true"
            style="width: 100%"
            [(value)]="newEmployeeRequest.Details.BankAccountNo"
          ></app-text-edit>
        </div>
      </div>
    </div>
    <div class="FormElement">
      <div class="FormElement-row">
        <div class="FormElement-control NewEmployeeLeft">
          <app-text-edit
            [label]="'NewEmployee.BIC' | translate"
            [editMode]="true"
            style="width: 100%"
            [(value)]="newEmployeeRequest.Details.BicSwiftNo"
          ></app-text-edit>
        </div>

        <div class="FormElement-control NewEmployeeRight NewEmployeeRight--label">
          <app-text-edit
            [label]="'NewEmployee.IBAN' | translate"
            [editMode]="true"
            style="width: 100%"
            [(value)]="newEmployeeRequest.Details.IbanNo"
          ></app-text-edit>
        </div>
      </div>
    </div>

    <div class="NewEmployeeCompany FormElement showAddToCompany FormElement-row" *ngIf="showAddToCompany">
      <span class="FormElement-label"></span>
      <div class="FormElement-control FormElement">
        <label class="FormElement-control FormElement-checkbox" style="margin-left: 0;">
          <input type="checkbox" [(ngModel)]="newEmployeeRequest.IsAccountUser" />
          <span class="FormElement-checkboxFaux"></span>
          <span class="FormElement-label">
            {{ 'NewEmployee.AddToCompany' | translate: { company: accountName } }}
          </span>
        </label>
      </div>
    </div>
  </div>

  <app-dialog-action type="Close"></app-dialog-action>
  <app-dialog-action
    *ngIf="createWithoutEmail && !newEmployeeRequest.Details.CompanyEmail && !newEmployeeRequest.Details.PersonalEmail"
    [disabled]="sessionService.isSubmitting"
    [type]="'CreateWithoutEmails'"
  ></app-dialog-action>
  <app-dialog-action
    *ngIf="
      !(createWithoutEmail && !newEmployeeRequest.Details.CompanyEmail && !newEmployeeRequest.Details.PersonalEmail)
    "
    [disabled]="sessionService.isSubmitting"
    [type]="'Create'"
  ></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="validationErrorDialogVisible"
  [width]="'400'"
  [leadingText]="validationMessageKey | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="noCPRWarningDialogVisible"
  [width]="'400'"
  [leadingText]="'NewEmployee.noCPRWarning' | translate: { SystemAlias: branding.SystemAlias }"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="noEmailWarningDialogVisible"
  (action)="createWithoutEmail = true"
  [width]="'400'"
  [leadingText]="'NewEmployee.EmailWarning' | translate: { SystemAlias: branding.SystemAlias }"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
