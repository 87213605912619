<!-- New salary batch dialog -->
<app-dialog
  *ngIf="popup"
  (action)="onSalaryBatchDialogAction($event)"
  [width]="'650'"
  [overlayClose]="true"
  [(visible)]="visible"
>
  <div class="Modal-header">
    <div class="DialogHeaderForExistingSalarybatch" *ngIf="salaryBatchContext?.BatchId > 0">
      <strong>
        {{ 'CompanySalaryBatches.SalaryBatchNo' | translate }} {{ salaryBatchContext?.SalaryBatchNumber }}
      </strong>
      <span class="right">
        {{ salaryBatchContext?.StatusName }}
        ({{ salaryBatchContext?.ChangeDate | DateFormat: undefined:undefined:true }})
      </span>
    </div>
    <div class="DialogHeaderForNewSalaryBatch" *ngIf="salaryBatchContext?.BatchId < 1">
      {{ 'CompanySalaryBatches.CreateNewSalaryBatch' | translate }}
    </div>
  </div>

  <app-salary-batch-details
    [salaryBatch]="salaryBatch"
    [(selectedEmployeeIds)]="selectedEmployeeIds"
    [(entityContext)]="entityContext"
    [salaryCycles]="salaryCycles"
    [salaryStatements]="companySalaryBatchService.salaryStatements | async"
    [visible]="visible"
    (entityContextChange)="onDetailContextChange($event)"
    (reloadSalaryBatches)="reloadSalaryBatches.emit()"
    (closeDialog)="closeDialog.emit()"
    [(triggerReload)]="reloadEntityContext"
    (cycleChange)="setSalaryBatchEmployeeGridDataSource()"
  ></app-salary-batch-details>

  <app-dialog-action [type]="'Close'" [disabled]="sessionService.isSubmitting"></app-dialog-action>
  <app-dialog-action
    *ngIf="!IsReadOnly && salaryBatchContext?.BatchId < 1"
    [type]="'Create'"
    [disabled]="sessionService.isSubmitting"
  ></app-dialog-action>
  <app-dialog-action
    *ngIf="!IsReadOnly && !(!salaryBatchContext?.IsDraft || salaryBatchContext?.BatchId < 0)"
    [type]="'Delete'"
    [disabled]="sessionService.isSubmitting"
  >
  </app-dialog-action>
  <app-dialog-action
    [requiredModuleId]="3"
    *ngIf="
      ((IsReadOnly && isPaymentApprover) || sessionService.role.IsSalaryAdminAndHigher) &&
      salaryBatchContext?.BatchId > 0 &&
      salaryBatchContext?.IsDraft
    "
    [type]="'SubmitForApproval'"
    [disabled]="sessionService.isSubmitting"
  >
  </app-dialog-action>
  <app-dialog-action
    [requiredModuleId]="3"
    *ngIf="!IsReadOnly && salaryBatchContext?.BatchId > 0 && salaryBatchContext?.IsPendingForApproval"
    [type]="'Reject'"
    [disabled]="sessionService.isSubmitting"
  >
  </app-dialog-action>
  <app-dialog-action
    *ngIf="
      !IsReadOnly && salaryBatchContext?.BatchId > 0 && salaryBatchContext?.isFinalizing && salaryBatch.StatusId === 35
    "
    [type]="'RevertFinalizing'"
    [disabled]="sessionService.isSubmitting"
  >
  </app-dialog-action>
  <app-dialog-action
    [requiredModuleId]="3"
    *ngIf="
      !IsReadOnly && salaryBatchContext?.BatchId > 0 && salaryBatchContext?.isFinalizing && salaryBatch.StatusId !== 35
    "
    [type]="'RevertFinalizing'"
    [disabled]="sessionService.isSubmitting"
  >
  </app-dialog-action>
  <app-dialog-action
    *ngIf="
      salaryBatchContext?.BatchId > 0 &&
      (salaryBatchContext?.IsDraft ||
        (salaryBatchContext?.IsPendingForApproval || salaryBatchContext?.isFinalizing) ||
        salaryBatchContext?.IsPreliminary)
    "
    [type]="'Finalize'"
    [tooltip]="companySalaryBatchService.tooltipFinalizeContent | translate"
    [close]="false"
    [disabled]="sessionService.isSubmitting || !isPaymentApprover"
  >
  </app-dialog-action>
  <app-dialog-action
    *ngIf="!IsReadOnly && salaryBatchContext?.IsFinalized"
    [type]="'Resend'"
    [tooltip]="'CompanySalaryBatches.ResendFinalizedPayrollBatchTooltip' | translate"
    [disabled]="sessionService.isSubmitting || (IsGreenlandCompany && !companySalaryBatchService.hasIntegrationName)"
  >
  </app-dialog-action>
  <app-dialog-action
    *ngIf="isPaymentApprover && salaryBatchContext?.IsFinalized && !IsReadOnly"
    [type]="'Revert'"
    [disabled]="sessionService.isSubmitting"
  >
  </app-dialog-action>
  <app-dialog-action
    *ngIf="salaryBatchContext?.BatchId > 0 && salaryBatchContext?.IsDraft"
    [type]="'Save'"
    [disabled]="sessionService.isSubmitting"
  >
  </app-dialog-action>
</app-dialog>
<!-- /New salary batch dialog -->

<div *ngIf="!popup">
  <div class="Card" *ngIf="!salaryBatch">
    <div class="Card-inner">
      <app-button
        [buttonClass]="'Button--primary'"
        [label]="'CompanySalaryBatches.SalaryBatchAddNewButton' | translate"
        (clickEvent)="onCreatePayrollBatchClicked()"
      >
      </app-button>
    </div>
  </div>

  <div class="Modal-header u-leader" *ngIf="salaryBatch">
    <div class="DialogHeaderForExistingSalarybatch">
      <strong>
        {{ 'CompanySalaryBatches.SalaryBatchNo' | translate }} {{ salaryBatchContext?.SalaryBatchNumber }}
      </strong>
      <span class="right">
        {{ salaryBatchContext?.StatusName }}
        ({{ salaryBatchContext?.ChangeDate | DateFormat: undefined:undefined:true }})
      </span>
    </div>
  </div>

  <app-salary-batch-details
    *ngIf="salaryBatch"
    [salaryBatch]="salaryBatch"
    [(selectedEmployeeIds)]="selectedEmployeeIds"
    [(entityContext)]="entityContext"
    [salaryCycles]="salaryCycles"
    [visible]="true"
    [salaryStatements]="companySalaryBatchService.salaryStatements | async"
    (entityContextChange)="onDetailContextChange($event)"
    (reloadSalaryBatches)="reloadSalaryBatches.emit()"
    (closeDialog)="closeDialog.emit()"
    [(triggerReload)]="reloadEntityContext"
    (cycleChange)="setSalaryBatchEmployeeGridDataSource()"
  ></app-salary-batch-details>

  <app-dialog-buttons *ngIf="salaryBatch" (buttonAction)="onSalaryBatchDialogAction($event)">
    <!--<app-dialog-action [type]="'Close'" [disabled]="sessionService.isSubmitting"></app-dialog-action>-->
    <app-dialog-action
      *ngIf="!IsReadOnly && salaryBatchContext?.BatchId < 1"
      [type]="'Create'"
      [disabled]="sessionService.isSubmitting"
    ></app-dialog-action>
    <app-dialog-action
      *ngIf="!IsReadOnly && !(!salaryBatchContext?.IsDraft || salaryBatchContext?.BatchId < 0)"
      [type]="'Delete'"
      [disabled]="sessionService.isSubmitting"
    >
    </app-dialog-action>
    <app-dialog-action
      [requiredModuleId]="3"
      [type]="'SubmitForApproval'"
      [disabled]="sessionService.isSubmitting"
      *ngIf="
        ((IsReadOnly && isPaymentApprover) || sessionService.role.IsSalaryAdminAndHigher) &&
        salaryBatchContext?.BatchId > 0 &&
        salaryBatchContext?.IsDraft
      "
    >
    </app-dialog-action>
    <app-dialog-action
      [requiredModuleId]="3"
      *ngIf="!IsReadOnly && salaryBatchContext?.BatchId > 0 && salaryBatchContext?.IsPendingForApproval"
      [type]="'Reject'"
      [disabled]="sessionService.isSubmitting"
    >
    </app-dialog-action>
    <app-dialog-action
      [requiredModuleId]="3"
      *ngIf="!IsReadOnly && salaryBatchContext?.BatchId > 0 && salaryBatchContext?.isFinalizing"
      [type]="'RevertFinalizing'"
      [disabled]="sessionService.isSubmitting"
    >
    </app-dialog-action>
    <app-dialog-action
      *ngIf="
        salaryBatchContext?.BatchId > 0 &&
        (salaryBatchContext?.IsDraft ||
          (salaryBatchContext?.IsPendingForApproval || salaryBatchContext?.isFinalizing) ||
          salaryBatchContext?.IsPreliminary)
      "
      [type]="'Finalize'"
      [tooltip]="companySalaryBatchService.tooltipFinalizeContent | translate"
      [close]="false"
      [disabled]="sessionService.isSubmitting || !isPaymentApprover"
    >
    </app-dialog-action>
    <app-dialog-action
      *ngIf="!IsReadOnly && salaryBatchContext?.IsFinalized"
      [type]="'Resend'"
      [disabled]="sessionService.isSubmitting || (IsGreenlandCompany && !companySalaryBatchService.hasIntegrationName)"
    >
    </app-dialog-action>
    <app-dialog-action
      *ngIf="isPaymentApprover && salaryBatchContext?.IsFinalized && !IsReadOnly"
      [type]="'Revert'"
      [disabled]="sessionService.isSubmitting"
    >
    </app-dialog-action>
    <app-dialog-action
      *ngIf="salaryBatchContext?.BatchId > 0 && salaryBatchContext?.IsDraft"
      [type]="'Save'"
      [disabled]="sessionService.isSubmitting"
    >
    </app-dialog-action>
  </app-dialog-buttons>
</div>

<app-dialog
  [(visible)]="companySalaryBatchService.pickEmployeeDialogVisible"
  [width]="'500'"
  (action)="onPickEmloyeeAction($event)"
  class="filterdialogpickemployeeconytol"
>
  <div class="filter-control">
    <app-multi-select
      [editMode]="true"
      [gridDataSource]="departmentSource"
      [valueExp]="'Id'"
      [displayExp]="'Name'"
      [label]="'CompanyGeneral.Departments' | translate"
      [(SelectValueIds)]="selectDepartmentFilter"
      [columns]="['Name']"
      (SelectValueIdsChange)="filterEmployeesGrid()"
      [disabled]="DisbaleDepartmentFilter"
    >
    </app-multi-select>

    <app-multi-select
      [editMode]="true"
      [gridDataSource]="templateSource"
      [valueExp]="'Id'"
      [displayExp]="'Name'"
      [label]="'CompanyPayrollData.Template' | translate"
      [(SelectValueIds)]="selectTemplateFilter"
      [columns]="['Name']"
      (SelectValueIdsChange)="filterEmployeesGrid()"
      [disabled]="DisbaleTemplateFilter"
    >
    </app-multi-select>
  </div>
  <div>
    <app-grid
      [data]="filterEmployeesGridData"
      [editMode]="true"
      (action)="onEmployeeGridAction($event)"
      class="u-gridHeight250"
      (saveChangesEvent)="onEmployeesGridDataSaveChanges($event)"
      [(triggerUpdate)]="triggerUpdate"
      [pageable]="true"
      [pageSize]="100"
      [miniPager]="true"
    >
      <app-grid-action [label]="'CompanySalaryBatches.SelectAll' | translate" [action]="'SelectAll'"></app-grid-action>
      <app-grid-action [label]="'CompanySalaryBatches.DeselectAll' | translate" [action]="'DeselectAll'">
      </app-grid-action>
      <app-grid-column [title]="' '" [field]="'IsChecked'" [type]="'checkbox'" [editable]="true" [width]="40">
      </app-grid-column>
      <app-grid-column
        [editable]="false"
        [title]="'CompanySalaryBatches.Name' | translate"
        [field]="'FullName'"
        [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
        [type]="'text'"
        [width]="150"
      ></app-grid-column>
      <app-grid-column
        [editable]="false"
        [title]="'CompanySalaryBatches.Department' | translate"
        [field]="'DepartmentName'"
        [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
        [type]="'text'"
        [width]="150"
      >
      </app-grid-column>
      <app-grid-column
        [editable]="false"
        [title]="'CompanySalaryBatches.Title' | translate"
        [field]="'Title'"
        [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
        [type]="'text'"
        [width]="150"
      ></app-grid-column>
    </app-grid>
  </div>
  <app-dialog-action type="Cancel"></app-dialog-action>
  <app-dialog-action [disabled]="nonemployeePicked" type="Ok"></app-dialog-action>
</app-dialog>
