import { Component, ContentChildren, EventEmitter, Output, QueryList } from '@angular/core';
import { DialogActionComponent } from './dialog-action.component';

@Component({
  selector: 'app-dialog-buttons',
  templateUrl: './dialog-buttons.component.html'
})
export class DialogButtonsComponent {
  @Output() public buttonAction: EventEmitter<string> = new EventEmitter<string>();
  @ContentChildren(DialogActionComponent) public buttons: QueryList<DialogActionComponent>;

  public onClick(action: string): void {
    this.buttonAction.emit(action);
  }
}
