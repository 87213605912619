export class GridHelper {
  static sortByString(obj1: any, obj2: any, field: string): any {
    if (!obj1 || !obj1.hasOwnProperty(field) || !obj1[field]) {
      return -1;
    } else if (!obj2 || !obj2.hasOwnProperty(field) || !obj2[field]) {
      return 1;
    }

    return obj1[field].toString().localeCompare(obj2[field].toString());
  }

  static sortByStringValue(obj1: any, obj2: any): any {
    if (!obj1) {
      return -1;
    } else if (!obj2) {
      return 1;
    }

    return obj1.localeCompare(obj2);
  }

  static sortByNumberValue(obj1: any, obj2: any): number {
    if (!obj1 || isNaN(obj1)) {
      return -1;
    } else if (!obj2 || isNaN(obj2)) {
      return 1;
    }

    return parseFloat(obj1) - parseFloat(obj2);
  }

  static sortByDateValue(obj1: any, obj2: any): any {
    if (obj1 > obj2) {
      return 1;
    } else if (obj1 < obj2) {
      return -1;
    }
    return 0;
  }

  static sortDescendingByDateValue(obj1: any, obj2: any): any {
    if (obj1 < obj2) {
      return 1;
    } else if (obj1 > obj2) {
      return -1;
    }
    return 0;
  }
}
