<legend class="Card-title Card-title-inline">
  <span>
    {{ 'EmployeeEmployment.Statistics' | translate }}
  </span>
  <span class="textWithLink" (click)="onReadMoreClick()">
    {{ 'Help.ReadMore' | translate }}
  </span>
</legend>
<form>
  <fieldset class="Form--horizontal">
    <div>
      <app-combobox-edit
        *ngIf="!isShow"
        [(isDirty)]="childIsDirty[0]"
        [label]="'EmployeeEmployment.SalaryPrinciple' | translate"
        [editMode]="editMode"
        [comboboxDataSource]="salaryPrinciples"
        [(value)]="statistics.StatisticsSalaryPrincipleId"
        [textField]="'Name'"
        [idField]="'Id'"
        (valueChange)="onChange()"
      ></app-combobox-edit>
      <app-date-picker
        *ngIf="IsEmployeeEmploymentStatistics && !isShow"
        [(isDirty)]="childIsDirty[1]"
        [(isValid)]="childIsValid[0]"
        [label]="'EmployeeEmployment.SalaryPrincipleFromDate' | translate"
        [editMode]="editMode"
        [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
        [(value)]="statistics.StatisticsSalaryPrincipleFromDate"
        (valueChange)="onChange()"
      ></app-date-picker>
      <app-combobox-edit
        *ngIf="!isShow"
        [(isDirty)]="childIsDirty[2]"
        [label]="'EmployeeEmployment.EmploymentPrinciple' | translate"
        [editMode]="editMode"
        [comboboxDataSource]="statisticsEmploymentPrinciple"
        [(value)]="statistics.StatisticsEmploymentPrincipleId"
        [textField]="'Name'"
        [idField]="'Id'"
        (valueChange)="onChange()"
      ></app-combobox-edit>
      <app-combobox-edit
        *ngIf="!isShow"
        [(isDirty)]="childIsDirty[3]"
        [label]="'EmployeeEmployment.EmploymentTerms' | translate"
        [editMode]="editMode"
        [comboboxDataSource]="statisticsEmploymentTerms"
        [(value)]="statistics.StatisticsEmploymentTermsId"
        [textField]="'Name'"
        [idField]="'Id'"
        (valueChange)="onChange()"
      ></app-combobox-edit>
      <app-date-picker
        *ngIf="IsEmployeeEmploymentStatistics && !isShow"
        [(isDirty)]="childIsDirty[4]"
        [(isValid)]="childIsValid[1]"
        [label]="'EmployeeEmployment.EmploymentTermsFromDate' | translate"
        [editMode]="editMode"
        [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
        [(value)]="statistics.StatisticsEmploymentTermsFromDate"
        (valueChange)="onChange()"
      ></app-date-picker>
      <app-combobox-edit
        *ngIf="!isShow"
        [(isDirty)]="childIsDirty[5]"
        [label]="'EmployeeEmployment.JobStatus' | translate"
        [editMode]="editMode"
        [comboboxDataSource]="statisticsJobStatus"
        [(value)]="statistics.StatisticsJobStatusId"
        [textField]="'Name'"
        [idField]="'Id'"
        (valueChange)="onChange()"
      ></app-combobox-edit>
      <app-date-picker
        *ngIf="IsEmployeeEmploymentStatistics && !isShow"
        [(isDirty)]="childIsDirty[6]"
        [(isValid)]="childIsValid[2]"
        [label]="'EmployeeEmployment.JobStatusFromDate' | translate"
        [editMode]="editMode"
        [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
        [(value)]="statistics.StatisticsJobStatusFromDate"
        (valueChange)="onChange()"
      ></app-date-picker>

      <app-combobox-edit
        *ngIf="editMode"
        [(isDirty)]="childIsDirty[7]"
        [label]="'EmployeeEmployment.JobFunctionCode' | translate"
        [nullValueLabel]="'DropdownList.None' | translate"
        [editMode]="true"
        [comboboxDataSource]="jobFunctionCodes"
        [(value)]="statistics.StatisticsJobFunctionCode"
        [textField]="'displayName'"
        [idField]="'Code'"
        (valueChange)="StatisticsJobFunctionCodeChange()"
      ></app-combobox-edit>
      <app-combobox-edit
        *ngIf="!editMode"
        [(isDirty)]="childIsDirty[7]"
        [label]="'EmployeeEmployment.JobFunctionCode' | translate"
        [editMode]="false"
        [comboboxDataSource]="jobFunctionCodes"
        [(value)]="statistics.StatisticsJobFunctionCode"
        [textField]="'Code'"
        [idField]="'Code'"
      ></app-combobox-edit>

      <app-text-edit
        *ngIf="!isShow"
        [(isDirty)]="childIsDirty[8]"
        [label]="'EmployeeEmployment.JobFunctionName' | translate"
        [editMode]="false"
        [disabled]="true"
        [value]="JobFunctionName"
      ></app-text-edit>
      <app-date-picker
        *ngIf="IsEmployeeEmploymentStatistics && !isShow"
        [(isDirty)]="childIsDirty[9]"
        [(isValid)]="childIsValid[3]"
        [label]="'EmployeeEmployment.StatisticsJobFunctionFromDate' | translate"
        [editMode]="editMode"
        [validationMessage]="'EmployeeGeneral.InvalidDateFormat' | translate"
        [(value)]="statistics.StatisticsJobFunctionFromDate"
        (valueChange)="onChange()"
      ></app-date-picker>
    </div>
  </fieldset>
</form>
