import { Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ListHelper } from 'src/app/common/list-helper';
import { DataService } from '../../../../services/api/data.service';
import { EmployeeService } from '../../../../services/employee.service';
import { SessionService } from '../../../../services/session/session.service';

@Component({
  selector: 'app-template-employee-list',
  templateUrl: './template-employee-list.component.html',
  styleUrls: ['./template-employee-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TemplateEmployeeListComponent implements OnDestroy {
  private currentSelectedTemplateId: number;
  @Input()
  public get selectedTemplateId(): number {
    return this.currentSelectedTemplateId;
  }
  public set selectedTemplateId(value: number) {
    if (this.currentSelectedTemplateId !== value) {
      this.currentSelectedTemplateId = value;
      this.getEmployees();
    }
  }

  private templateHasEmployees: boolean;
  @Input()
  public get hasEmployees(): boolean {
    return this.templateHasEmployees;
  }
  public set hasEmployees(value: boolean) {
    if (this.templateHasEmployees !== value) {
      this.templateHasEmployees = value;
      setTimeout(() => this.hasEmployeesChange.emit(value));
    }
  }
  @Output() public hasEmployeesChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public employees: any[] = [];
  public selectedEmployee: any = {};

  constructor(
    private sessionService: SessionService,
    private dataService: DataService,
    private employeeService: EmployeeService
  ) {}

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private getEmployees(): void {
    if (this.currentSelectedTemplateId) {
      this.dataService
        .EmploymentTemplates_GetCompanyUsersByEmploymentTemplate(this.currentSelectedTemplateId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((employees: any[]) => {
          this.employees = employees;
          this.hasEmployees = this.employees && this.employees.length > 0;
        });
    } else {
      this.employees = [];
      this.hasEmployees = false;
    }
  }

  public onGridDblClick(data: any): void {
    if (data && data.UserEmploymentId) {
      this.employeeService.selectEmployeeFromEmploymentId(data.UserEmploymentId, true);
      this.sessionService.NavigateTo('tabs.employee.hiringstatus');
    }
  }
}
