import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Transition, TransitionService } from '@uirouter/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { environment } from 'src/environments/environment';
import { version } from '../../../version';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { KeyDownHelper } from '../../common/key-down-helper';
import { RouterStateService } from '../../common/router-state.service';
import { IApiUser, ICompanyUser, IUserEmployment } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { EmployeeService } from '../../services/employee.service';
import { SessionService } from '../../services/session/session.service';
import { ReportDialogService } from '../../shared-components/report-dialog/report-dialog.service';

@Component({
  selector: 'app-employee-tabs',
  templateUrl: './employee-tabs.component.html',
  styleUrls: ['./employee-tabs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeTabsComponent implements OnInit, OnDestroy {
  private currentEmployee: ICompanyUser;
  @Input()
  public get employee(): ICompanyUser {
    return this.currentEmployee;
  }
  public set employee(value: ICompanyUser) {
    this.currentEmployee = value;
    this.isApiValidationValid = true;
    this.isDirty = false;
    if (value) {
      this.reportDialogService.selectedUserId = value.Id;
    }
  }

  private originalBasedOnTemplateId: number;
  @Input()
  public get currentUserEmployment(): IUserEmployment {
    return this.currentUserEmploymentValue;
  }
  public set currentUserEmployment(value: IUserEmployment) {
    this.currentUserEmploymentValue = value;
    this.originalBasedOnTemplateId = value.BasedOnTemplateId;
    this.isApiValidationValid = true;
    this.isDirty = false;
    if (value) {
      this.reportDialogService.selectedEmploymentId = value.Id;
    }
  }

  private editModeValue: boolean;
  public get editMode(): boolean {
    return this.editModeValue;
  }
  public set editMode(value: boolean) {
    if (this.editModeValue !== value) {
      this.editModeValue = value;
      this.sessionService.hideGetStarted = value;
      this.employeeService.editMode = value;
    }
  }

  public get showEmployeeList(): boolean {
    return this.sessionService.feature.ShowEmployeeList;
  }
  public get employeeGeneralTabVisible(): boolean {
    return this.sessionService.currentState.includes('tabs.employee.general') && !this.EmployeeWithRoleAsManager;
  }
  public get employeeHiringStatusTabVisible(): boolean {
    return this.sessionService.currentState.includes('tabs.employee.hiringstatus') && !this.EmployeeWithRoleAsManager;
  }
  public get employeeTimeTabVisible(): boolean {
    return this.sessionService.currentState.includes('tabs.employee.time');
  }
  public get employeePayrollDataTabVisible(): boolean {
    return this.sessionService.currentState.includes('tabs.employee.payrolldata') && !this.EmployeeWithRoleAsManager;
  }
  public get employeePayslipTabVisible(): boolean {
    return this.sessionService.currentState.includes('tabs.employee.payslip') && !this.EmployeeWithRoleAsManager;
  }
  public get allowEditUserEmployment(): boolean {
    return (
      this.sessionService.feature.AllowEditUserEmployment(this.sessionService.currentState) &&
      !this.sessionService.role.IsReadOnly &&
      !this.sessionService.isGetting &&
      !this.sessionService.isGetting &&
      !this.employeeService.isNonSelected &&
      this.isEnableEditButtonInEmployeeHirring
    );
  }
  public get isIOSApp(): boolean {
    return this.sessionService.browser.isHybridApp && this.sessionService.browser.iOSMobileDevice;
  }

  public get isEnableEditButtonInEmployeeHirring(): boolean {
    if (this.sessionService.currentState === 'tabs.employee.hiringstatus') {
      return this.hasUserEmployment;
    }

    return true;
  }

  public get isMobile(): boolean {
    return this.sessionService.browser.isMobile;
  }
  private get isActive(): boolean {
    return this.sessionService.currentState.indexOf('tabs.employee') >= 0;
  }
  private get EmployeeWithRoleAsManager(): boolean {
    return this.sessionService.role.EmployeeWithRoleAsManager;
  }
  private get employeeTimeTabEnabled(): boolean {
    return this.sessionService.feature.EmployeeTimeTabEnabled;
  }
  private get employeePayrollDataTabEnabled(): boolean {
    return this.sessionService.feature.EmployeePayrollDataTabEnabled;
  }

  public isDirty = false;
  public isTabsValid = true;
  public showInvalidFormWarning = false;
  public showSaveDataConfirmationChangeState = false;
  public discardDialogVisible = false;
  public synchTemplateDialogVisible = false;
  public createNewEmployeeDialogVisible = false;
  // public unsavedDataDialogVisible = false;

  private touchTime = 0;
  private isApiValidationValid = true;
  private currentUserEmploymentValue: IUserEmployment;
  private navigateToAfterUpdate: string;
  private navigationToStateName: string;
  private hasUserEmployment = false;

  constructor(
    public employeeService: EmployeeService,
    private sessionService: SessionService,
    private transitionService: TransitionService,
    private broadcaster: BroadcastService,
    private staticDataService: StaticDataService,
    public state: RouterStateService,
    private dataService: DataService,
    public reportDialogService: ReportDialogService
  ) {
    this.employeeService.employment.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IUserEmployment) => {
      this.hasUserEmployment = !!(data && data.Id);
    });
  }

  public ngOnInit(): void {
    this.broadcaster
      .on<any>(Constants.GLOBAL_KEY_DOWN)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => this.onGlobalKeyDown(data));

    this.transitionService.onStart({}, (transition: Transition) => {
      const fromState = transition.from().name;
      this.navigationToStateName = transition.to().name;
      const generalState = 'tabs.employee.general';
      const hiringstatusState = 'tabs.employee.hiringstatus';

      if (this.editMode && fromState && (fromState === generalState || fromState === hiringstatusState)) {
        if (this.isDirty) {
          // If validation fails, alert user and do nothing.
          // Else show action confirmation.
          if (!this.validate()) {
            this.showInvalidFormWarning = true;
          } else {
            this.showSaveDataConfirmationChangeState = true;
          }

          return false;
        } else {
          // Editmode but no changes, we close editmode without doing anything
          this.editMode = false;
          return true;
        }
      } else {
        this.editMode = false;
        return true;
      }
    });

    this.employeeService.editModeChangeEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((val: boolean) => {
      if (val) {
        this.editMode = true;
      }
    });
  }

  private ngUnsubscribe: Subject<{}> = new Subject();

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onConfirmSaveDataOnChangeState(action: string) {
    switch (action) {
      case 'ContinueAndSave':
        this.editMode = false;
        this.prepareUpdateUserEmployment(this.navigationToStateName);
        break;
      case 'DiscardAndLeave':
        this.discardChanges();
        this.sessionService.NavigateTo(this.navigationToStateName);
        break;
    }

    this.navigationToStateName = undefined;
  }

  public clickAreamenuToggledChange(e: any): void {
    if (this.isMobile) {
      this.checkMobileDoubleClick();
    }
  }

  private checkMobileDoubleClick(): void {
    if (this.touchTime === 0) {
      // set first click
      this.touchTime = new Date().getTime();
    } else {
      // compare first click to this click and see if they occurred within double click threshold
      if (new Date().getTime() - this.touchTime < 800) {
        // double click occurred
        this.onDoubleClick();
        this.touchTime = 0;
      } else {
        // not a double click so set as a new first click
        this.touchTime = new Date().getTime();
      }
    }
  }

  public PreonDoubleClick(e: any): void {
    if (this.editMode === true || this.sessionService.isGetting || this.employeeService.isNonSelected) {
      return;
    }

    if (!this.isEnableEditButtonInEmployeeHirring) {
      return;
    }

    this.onDoubleClick();
  }

  private onDoubleClick(): void {
    if (!this.allowEditUserEmployment) {
      return;
    }

    if (
      !this.editMode &&
      (this.sessionService.currentState === 'tabs.employee.general' ||
        this.sessionService.currentState === 'tabs.employee.hiringstatus')
    ) {
      if (this.editMode === true) {
        return;
      }
      setTimeout(() => {
        this.onEditModeChange(true);
      });
    }
  }

  public onEditModeChange(editMode: boolean): void {
    if (this.editMode !== editMode) {
      this.isApiValidationValid = true;
      if (!this.validate() && editMode === false) {
        if ((version as string) === 'Local-build') {
          console.log('Keep editmode open');
        }

        return;
      }

      if (editMode === false && this.isDirty) {
        // this.unsavedDataDialogVisible = true;
        this.prepareUpdateUserEmployment();
      }

      this.editMode = editMode;
    }
    // Assume that the user has fixed the api validation and try again
  }

  public confirmAndDiscardChanges(): void {
    if (!this.isDirty) {
      this.editMode = false;
      return;
    }

    this.discardDialogVisible = true;
  }

  public discardDialogAction(action: string): void {
    if (action === 'ConfirmDiscard') {
      this.discardChanges();
    }
  }

  // public onUnsavedDataDialogAction(action: string): void {
  //   if (action === 'DiscardAndLeave') {
  //     this.discardChanges();
  //   } else {
  //     this.editMode = false;
  //     this.prepareUpdateUserEmployment();
  //   }
  // }

  public onSynchTemplateDialogAction(action: string): void {
    if (action !== 'No') {
      this.performUpdateUserEmployment(true, this.navigateToAfterUpdate);
    } else {
      this.performUpdateUserEmployment(false, this.navigateToAfterUpdate);
    }
  }

  private onGlobalKeyDown($event: any): void {
    if (!this.isActive) {
      return;
    }

    if (KeyDownHelper.isEditShortcutKey($event)) {
      $event.preventDefault();
      if (this.allowEditUserEmployment) {
        // waitting bindding async employee service
        setTimeout(() => {
          this.onEditModeChange(true);
        });
      }

      return;
    }

    if (!this.editMode) {
      return;
    }

    if (KeyDownHelper.isDiscardingChangesShortcutKey($event)) {
      $event.preventDefault();
      if (!this.sessionService.isGetting && !this.sessionService.isSubmitting) {
        this.confirmAndDiscardChanges();
      }
    }

    if (KeyDownHelper.isSavingChangesShortcutKey($event)) {
      $event.preventDefault();
      if ((version as string) === 'Local-build') {
        console.log('Save');
      }

      this.onEditModeChange(false);
    }
  }

  private discardChanges(): void {
    this.isDirty = false;
    this.editMode = false;
    this.isTabsValid = true;
    this.employeeService.discardChanges();
  }

  private prepareUpdateUserEmployment(navigateTo?: string) {
    this.navigateToAfterUpdate = navigateTo;
    if (this.currentUserEmployment.BasedOnTemplateId !== this.originalBasedOnTemplateId) {
      this.synchTemplateDialogVisible = true;
    } else {
      this.performUpdateUserEmployment(false, navigateTo);
    }
  }

  private performUpdateUserEmployment(syncTemplate: boolean, navigateTo?: string): void {
    this.employeeService.saveChanges(syncTemplate).subscribe(
      () => {
        this.isDirty = false;
        this.staticDataService.loadSalaryCycleUsedAsync();
        this.reloadSession();
        this.staticDataService.loadUserEmployments();
        if (navigateTo) {
          this.sessionService.NavigateTo(navigateTo);
        }
      },
      () => {
        this.isApiValidationValid = false;
        this.editMode = true;
        this.isDirty = true;
      }
    );
  }

  private async reloadSession() {
    const data: IApiUser = await this.dataService.Auth_GetSessionInfo().toPromise();
    if (data) {
      environment.branding.SystemAlias = data.CurrentSystemName;
      Global.SESSION = data;
    }
  }

  private validate(): boolean {
    return this.isTabsValid && this.isApiValidationValid;
  }
}
