import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmployeeService } from 'src/app/services/employee.service';
import { environment } from 'src/environments/environment';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { Address, IAccountRole, ICity, IUser, User } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
var NewUserDialogComponent = /** @class */ (function () {
    function NewUserDialogComponent(sessionService, staticDataService, employeeService, translateService, dataService) {
        this.sessionService = sessionService;
        this.staticDataService = staticDataService;
        this.employeeService = employeeService;
        this.translateService = translateService;
        this.dataService = dataService;
        this.noCPRWarningDialogVisible = false;
        this.noEmailWarningDialogVisible = false;
        this.validationErrorDialogVisible = false;
        this.hasLanguageModule = false;
        this.createWithoutEmail = false;
        this.dateValid = true;
        this.hasDepartmentEnabled = false;
        this.accountName = '';
        this.isShowForEmploymentTemplateId = true;
        this.noCPRNumber = false;
        this.newUserRequest = new User();
        this.requiredFirstName = false;
        this.requiredIdentityNumber = false;
        this.accountRoleDataSource = [];
        this.createNonEmail = false;
        this.visibleValue = false;
        this.visibleChange = new EventEmitter();
        this.createUserComplete = new EventEmitter();
        this.ngUnsubscribe = new Subject();
        this.cities = [];
    }
    Object.defineProperty(NewUserDialogComponent.prototype, "visible", {
        get: function () {
            return this.visibleValue;
        },
        set: function (value) {
            if (this.visibleValue !== value) {
                this.createDefaultBlanceUser();
                this.visibleValue = value;
                this.visibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewUserDialogComponent.prototype, "isGreenlandCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewUserDialogComponent.prototype, "isSwedenCompany", {
        get: function () {
            return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewUserDialogComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    NewUserDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createDefaultBlanceUser();
        this.staticDataService.City.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) { return (_this.cities = data); });
        this.staticDataService.AccountantRole.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            if (data && data.length > 0) {
                _this.accountRoleDataSource = JSON.parse(JSON.stringify(data));
                if (_this.sessionService.role.IsSalaryAdmin) {
                    var adminOption = _this.accountRoleDataSource.find(function (model) { return model.Key === 'Admin'; });
                    if (adminOption) {
                        _this.accountRoleDataSource.splice(_this.accountRoleDataSource.indexOf(adminOption), 1);
                    }
                }
            }
        });
    };
    NewUserDialogComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    NewUserDialogComponent.prototype.onNoCPRCheckboxClicked = function () {
        if (this.noCPRNumber) {
            this.noCPRWarningDialogVisible = true;
            this.newUserRequest.IdentityNumber = '';
        }
    };
    NewUserDialogComponent.prototype.createDefaultBlanceUser = function () {
        var _this = this;
        this.newUserRequest = new User();
        this.newUserRequest.Address = new Address();
        this.requiredFirstName = false;
        this.requiredIdentityNumber = false;
        this.newUserRequest.LanguageId = Global.COMPANY ? Global.COMPANY.DefaultLanguageId : 1;
        this.newUserRequest.Address.CountryId =
            Global.COMPANY && Global.COMPANY.CountryId ? Global.COMPANY.CountryId : Constants.DENMARK_COUNTRY_ID;
        this.staticDataService.AccountantRole.subscribe(function (data) {
            if (data && data.length > 0) {
                _this.newUserRequest.AccountRoleId = data.find(function (model) { return model.Key === 'Basic'; }).Id;
            }
        });
        this.newUserRequest.AccountId = Global.SESSION ? Global.SESSION.SignOnToken.UserAccountId : null;
        this.newUserRequest.IsFirstLogin = true;
        this.newUserRequest.ShowUpdateNotification = false;
        this.newUserRequest.ShowUrgentNotification = false;
        this.newUserRequest.ShowPayslipTutorial = 0;
        this.newUserRequest.UseGlobalEmail = false;
        this.newUserRequest.Created = this.staticDataService.getCurrentdate();
        this.createNonEmail = false;
        this.noCPRNumber = false;
    };
    NewUserDialogComponent.prototype.onPostalCodeChanged = function () {
        var _this = this;
        var postalCode = this.newUserRequest.Address.PostalCode;
        if (!postalCode) {
            return;
        }
        var city = this.cities.find(function (c) {
            if (c.CountryId === _this.newUserRequest.Address.CountryId && c.PostalCode) {
                var numericCode = parseInt(postalCode, 10);
                if (!isNaN(numericCode)) {
                    if (c.IsRange && c.PostalCodeTo) {
                        if (numericCode >= c.PostalCode && numericCode <= c.PostalCodeTo) {
                            return true;
                        }
                    }
                    else {
                        if (numericCode === c.PostalCode) {
                            return true;
                        }
                    }
                }
            }
            return false;
        });
        if (!city) {
            city = this.cities.find(function (c) { return c.PostalCode && postalCode === c.PostalCode.toString(); });
        }
        if (city) {
            this.newUserRequest.Address.City = city.Key;
        }
    };
    NewUserDialogComponent.prototype.closeForm = function (action) {
        switch (action) {
            case 'Create':
                if (!this.newUserRequest.Email) {
                    this.noEmailWarningDialogVisible = true;
                    this.createNonEmail = true;
                    return;
                }
                this.createUser();
                break;
            case 'CreateWithoutEmails':
                this.createUser();
                break;
            default:
                this.visible = false;
                break;
        }
    };
    NewUserDialogComponent.prototype.createUser = function () {
        var _this = this;
        if (!this.validateInput()) {
            return;
        }
        this.dataService.Users_CreateUser(this.newUserRequest).subscribe(function (response) {
            _this.createUserComplete.emit(response);
            _this.visible = false;
        });
    };
    NewUserDialogComponent.prototype.validateInput = function () {
        this.validationMessageKey = this.getErrorMessage();
        if (this.validationMessageKey) {
            this.validationErrorDialogVisible = true;
            return false;
        }
        return true;
    };
    NewUserDialogComponent.prototype.getErrorMessage = function () {
        if (!this.newUserRequest.FirstName) {
            this.requiredFirstName = true;
            return 'NewEmployee.RequiredErrorMessage';
        }
        if (!this.noCPRNumber && !this.newUserRequest.IdentityNumber) {
            this.requiredIdentityNumber = true;
            return 'NewEmployee.RequiredErrorMessage';
        }
        return '';
    };
    return NewUserDialogComponent;
}());
export { NewUserDialogComponent };
