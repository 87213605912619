import { Component } from '@angular/core';
import { SessionService } from '../services/session/session.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html'
})
export class AccountComponent {
  public toggleMenuButton(): void {
    if (!this.sessionService.menuToggled) {
      this.sessionService.menuToggled = true;
    } else {
      this.sessionService.menuToggled = false;
    }
  }

  constructor(public sessionService: SessionService) {}
}
