import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-payslip-icon',
  templateUrl: './payslip-icon.component.html',
  styleUrls: ['./payslip-icon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PayslipIconComponent {
  @Output() public click: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  get payslipIconType(): string {
    return this.iconType;
  }

  set payslipIconType(value: string) {
    this.iconType = value;
    this.showIcon();
  }

  @Input() public isHidden = '';
  public isVisible = true;
  private iconType: string;
  showInCurrentTab = false;
  showInNewTab = false;
  showPDF = false;
  showPrintTab = false;
  showWarningTab = false;
  showErrorTab = false;

  constructor(private sessionService: SessionService) {}

  public get IsWeb(): boolean {
    return !this.sessionService.browser.isHybridApp;
  }

  onButtonClick(): void {
    if (this.click) {
      this.click.emit();
      if (this.sessionService.browser.isHybridApp) {
        window.screen.orientation.unlock();
      }
    }
  }

  private showIcon(): void {
    if (!this.iconType) {
      return;
    }
    switch (this.iconType) {
      case 'htmlSameTab':
        this.showInCurrentTab = true;
        break;
      case 'htmlNewTab':
        this.showInNewTab = true;
        break;
      case 'pdf':
        this.showPDF = true;
        break;
      case 'htmlPrintTab':
        if (!this.sessionService.browser.isMobile) {
          this.showPrintTab = true;
        }
        break;
      case 'warningTab':
        this.showWarningTab = true;
        break;
      case 'errorTab':
        this.showErrorTab = true;
        break;
      default:
        break;
    }
  }
}
