<div *ngIf="!IsMobile" class="Card-inner Card-payslip">
  <div class="Card-title">{{ 'CompanySalaryBatches.Payslips' | translate }}</div>
</div>

<div *ngIf="!IsMobile" class="Cards Cards--flex Company-payslipSearch">
  <div class="Card Form--stacked Company-payslipStacked">
    <div class="Card-inner">
      <app-text-edit
        class="FormEmlement Company-totalsPayslipsSearch"
        [label]="'CompanySalaryBatches.Search' | translate"
        [icon]="'Search'"
        [placeholder]="'CompanySalaryBatches.Search' | translate"
        [editMode]="true"
        [(value)]="searchText"
        [readonlyOnNotFocus]="true"
      ></app-text-edit>
    </div>
  </div>

  <div class="Card Form--stacked Company-payslipStacked">
    <div class="Card-inner Card-inner--right">
      <app-salary-batch-payslip-icons
        [IsFinalizedBatch]="IsFinalizedBatch"
        [selectedSalaryStatement]="selectedSalaryStatement"
        (downloadPayslipClick)="downloadSalaryStatement($event)"
      >
      </app-salary-batch-payslip-icons>
    </div>
  </div>
</div>

<div *ngIf="!IsMobile" class="Cards Cards--flex hint">
  <span class="Card-inner FormElement-label">{{
    'CompanySalaryBatches.HintDoubleClickViewEmployeeData' | translate
  }}</span>
</div>

<div *ngIf="!IsMobile" class="Cards Cards--flex Company-payslipKendo">
  <div class="Card-inner Card-inner--left" id="Card-splitter1">
    <div class="FormElement">
      <app-grid
        [data]="gridData"
        [idColumn]="'Id'"
        [(selectedItem)]="selectedSalaryStatement"
        [editMode]="false"
        class="FormElement-grid Company-payslipsGrid employee-select-payslips"
        (dblClick)="onDoubleClick($event)"
        [filterable]="'menu'"
        [scrollable]="'virtual'"
        [pageSize]="50"
        [filter]="filter"
        (filterChange)="onFilterChange($event)"
      >
        <app-grid-column
          [isFilterable]="true"
          [field]="'UserFullName'"
          [title]="'EmployeePayslip.Employee' | translate"
          [width]="200"
          [type]="'text'"
        ></app-grid-column>
        <app-grid-column
          [isFilterable]="true"
          [field]="'PayoutAmount'"
          [title]="'EmployeePayslip.PaidOut' | translate"
          [width]="100"
          [type]="'numeric'"
        ></app-grid-column>
      </app-grid>
    </div>
  </div>
  <div id="splitter"></div>
  <div class="Card-inner Card-inner--right" id="Card-splitter2">
    <app-salary-batch-payslip-details
      [IsFinalizedBatch]="IsFinalizedBatch"
      [selectedSalaryStatement]="selectedSalaryStatement"
      [salaryStatementDetails]="salaryStatementDetails"
      (revertBatchCompleted)="revertBatchCompleted.emit()"
    ></app-salary-batch-payslip-details>
  </div>
</div>

<div *ngIf="IsMobile" class="Cards Cards--flex mobile-mode">
  <div class="Card Form--stacked">
    <div class="Card-inner">
      <!-- Payslip combobox -->
      <app-combobox-edit
        [disable]="filteredSalaryStatements && filteredSalaryStatements.length === 1"
        [editMode]="true"
        [idField]="'Id'"
        [textField]="'UserFullName'"
        [comboboxDataSource]="filteredSalaryStatements"
        [(value)]="selectedSalaryStatementId"
      ></app-combobox-edit>

      <!-- Payslip download icons. -->
      <app-salary-batch-payslip-icons
        [IsFinalizedBatch]="IsFinalizedBatch"
        [selectedSalaryStatement]="selectedSalaryStatement"
        (downloadPayslipClick)="downloadSalaryStatement($event)"
      >
      </app-salary-batch-payslip-icons>

      <app-salary-batch-payslip-details
        [IsFinalizedBatch]="IsFinalizedBatch"
        [selectedSalaryStatement]="selectedSalaryStatement"
        [salaryStatementDetails]="salaryStatementDetails"
        (revertBatchCompleted)="revertBatchCompleted.emit()"
      ></app-salary-batch-payslip-details>
    </div>
  </div>
</div>

<app-dialog
  class="blue-dialog"
  *ngIf="IsWeb"
  [(visible)]="newTabBlockedDialogVisible"
  [leadingText]="'Report.NewTabBlocked' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
