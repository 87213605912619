import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../common/constants';
import { FormComponentBase } from '../../../common/form-component-base';
import { Global } from '../../../common/global';
import { NumericTextBoxOptions } from '../../../custom-controls/numeric-edit/numeric-text-box-options';
import {
  ISimpleKeyValuePair,
  ITemplateBalanceConfiguration,
  IUserEmploymentTemplate
} from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { EmployeeService } from '../../../services/employee.service';
import { SessionService } from '../../../services/session/session.service';
import { SettingService } from '../../../services/setting.service';
import { ReportDialogService } from '../../../shared-components/report-dialog/report-dialog.service';
import { CompanyTemplatesService } from './templates.service';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TemplatesComponent extends FormComponentBase implements OnInit, OnDestroy {
  public hasTemplateModule = false;
  public hasDepartmentModule = false;
  public addNewDialogVisible = false;
  public isPayrollGridVisible = false;
  public isSeniorityGridVisible = false;
  public isOvertimeGridVisible = false;
  public deleteConfirmationDialogVisible = false;
  public discardChangesDialogVisible = false;
  public updateTemplateWarningDialogVisible = false;
  public showInvalidFormWarning = false;
  public AddDepartmentVisible = false;
  private selectedEmployeeId: number;

  constructor(
    private dataService: DataService,
    private settingService: SettingService,
    public sessionService: SessionService,
    public staticDataService: StaticDataService,
    private employeeService: EmployeeService,
    public companyTemplatesService: CompanyTemplatesService,
    public reportDialogService: ReportDialogService
  ) {
    super();
    this.childIsValid = Array(16).fill(true);
    this.childIsDirty = Array(20).fill(false);

    this.companyTemplatesService.tabDirty = this.isDirty;
    this.companyTemplatesService.tabValid = this.isValid;

    this.companyTemplatesService.editShortcutChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      if (value && this.sessionService.role.IsSalaryAdminAndHigher) {
        this.onEditClick(true);
      }
    });

    this.companyTemplatesService.discardChangeShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && this.companyTemplatesService.editMode) {
          this.onConfirmAndDiscardChanges();
        }
      });

    this.companyTemplatesService.saveChangeShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && this.sessionService.role.IsSalaryAdminAndHigher) {
          this.onEditClick(false);
        }
      });

    this.companyTemplatesService.showReportShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && this.reportDialogService.isVisibleReportIcon) {
          this.reportDialogService.onShowReportsEventClick();
        }
      });

    this.companyTemplatesService.addNewShortcutChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        if (value && !this.companyTemplatesService.editMode && this.isAddIconVisible) {
          this.addNewDialogVisible = true;
        }
      });

    this.valueChange.subscribe(() => {
      this.companyTemplatesService.tabDirty = this.isDirty;
      this.companyTemplatesService.tabValid = this.isValid;
    });

    this.companyTemplatesService.selectedTemplateIdChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getEmploymentTemplate();
    });

    this.companyTemplatesService.updateTemplateOnlyChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.resetDirtyForm();
    });

    this.companyTemplatesService.resetFormChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.resetDirtyForm();
    });
  }

  public get IsDisableCreateDepartment(): boolean {
    return this.sessionService.role.IsReadOnly || this.sessionService.role.IsSalaryAdmin;
  }

  public ngOnInit(): void {
    this.companyTemplatesService.loadTemplates();
    this.getEmploymentTemplate();

    this.settingService.showModuleMessage('Company.Templates');
    this.employeeService.selectedEmployeeId.subscribe((Id: number): void => {
      this.selectedEmployeeId = Id;
    });
  }

  public ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.companyTemplatesService.serviceDestroy();

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    this.companyTemplatesService.ngOnDestroy();
  }

  public get has1stPensionProvider(): boolean {
    return (
      !!this.companyTemplatesService.templateDetail && !!this.companyTemplatesService.templateDetail.PensionProviderId
    );
  }

  public get has2ndPensionProvider(): boolean {
    return (
      !!this.companyTemplatesService.templateDetail && !!this.companyTemplatesService.templateDetail.Pension2ProviderId
    );
  }

  public get isAddIconVisible(): boolean {
    return this.sessionService.role.IsSalaryAdminAndHigher;
  }

  public get SecondaryIncomeType(): boolean {
    return (
      this.companyTemplatesService.templateDetail && this.companyTemplatesService.templateDetail.IncomeTypeId === 5
    );
  }

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public get showStatisticsSection(): boolean {
    return Global.COMPANY && Global.COMPANY.StatisticsRecipientId && Global.COMPANY.StatisticsRecipientId !== -1;
  }

  public get isSwedenCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.SWEDEN_COUNTRY_ID;
  }

  public get isDanishCompany() {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.DENMARK_COUNTRY_ID;
  }

  public get showMultiPurposeOffDayCompensation(): boolean {
    const enableFlexBalanceSetting: ISimpleKeyValuePair = this.sessionService.getCompanyPreference(
      'Company.EnableFlexBalance'
    );
    if (enableFlexBalanceSetting) {
      return enableFlexBalanceSetting.Value === 'true' ? true : false;
    }

    return false;
  }

  public onEditClick($event: boolean): void {
    if (!$event) {
      this.companyTemplatesService.onSave();
    } else {
      this.companyTemplatesService.editMode = $event;
    }
  }

  public onDbClick(): void {
    if (
      (this.sessionService.role.IsFullAdmin || this.sessionService.role.IsSalaryAdmin) &&
      !this.sessionService.isGetting &&
      !this.sessionService.isSubmitting
    ) {
      this.companyTemplatesService.editMode = true;
    }
  }

  public onAddNewCompanyTemplateCompleted(template: IUserEmploymentTemplate): void {
    this.companyTemplatesService.loadTemplates(template.Id);
  }

  public onDeleteEmploymentTemplate(): void {
    if (this.companyTemplatesService.selectedTemplateId) {
      this.deleteConfirmationDialogVisible = true;
    }
  }

  public onDeleteConfirmationDialogAction(action: string): void {
    if (action === 'Delete') {
      this.dataService
        .EmploymentTemplates_DeleteEmploymentTemplate(this.companyTemplatesService.selectedTemplateId)
        .subscribe(() => this.companyTemplatesService.loadTemplates());
    }
  }

  public onConfirmUpdateDialogAction(action: string): void {
    if (action === 'UpdateAllEmployees') {
      this.updateTemplateWarningDialogVisible = true;
    }

    if (action === 'UpdateTemplateOnly') {
      this.companyTemplatesService.updateTemplateOnly();
    }
  }

  public onUpdateTemplateWarningAction(action: string): void {
    if (action === 'Proceed') {
      this.companyTemplatesService.updateTemplateAllEmployee().subscribe(() => {
        if (this.selectedEmployeeId) {
          this.employeeService.selectEmployee(this.selectedEmployeeId, true);
        }

        this.companyTemplatesService.moveToNextState();
      });
    }
  }

  public onConfirmAndDiscardChanges(): void {
    if (!this.companyTemplatesService.tabDirty) {
      this.companyTemplatesService.editMode = false;
      return;
    }

    this.discardChangesDialogVisible = true;
  }

  public onDiscardChangesDialogAction(action: string): void {
    if (action === 'ConfirmDiscard') {
      this.companyTemplatesService.editMode = false;
      this.companyTemplatesService.onDiscard();
    }
  }

  private getEmploymentTemplate(): void {
    if (this.companyTemplatesService.selectedTemplateId) {
      this.companyTemplatesService.getEmploymentTemplate().subscribe(() => {
        this.companyTemplatesService.updateTemplateListTemplateName();
      });
    }
  }

  private resetDirtyForm(): void {
    this.isDirty = false;
    this.childIsValid = Array(15).fill(true);
    this.childIsDirty = Array(19).fill(false);

    this.companyTemplatesService.tabDirty = this.isDirty;
    this.companyTemplatesService.tabValid = this.isValid;
  }

  public EmploymentBalanceConfigurationsChange(dataChange: ITemplateBalanceConfiguration[], changeNumber: number) {
    if (dataChange && dataChange.length > 0) {
      if (
        this.companyTemplatesService.templateDetail.TemplateBalanceConfigurations &&
        this.companyTemplatesService.templateDetail.TemplateBalanceConfigurations.length > 0
      ) {
        const model = this.companyTemplatesService.templateDetail.TemplateBalanceConfigurations.find(
          (item: ITemplateBalanceConfiguration) => item.BalanceDefinitionId === changeNumber
        );

        if (model) {
          this.companyTemplatesService.templateDetail.TemplateBalanceConfigurations[
            this.companyTemplatesService.templateDetail.TemplateBalanceConfigurations.indexOf(model)
          ] =
            dataChange[
              dataChange.indexOf(
                dataChange.find((item: ITemplateBalanceConfiguration) => item.BalanceDefinitionId === changeNumber)
              )
            ];
        } else {
          this.companyTemplatesService.templateDetail.TemplateBalanceConfigurations.push(dataChange[0]);
        }
      } else {
        this.companyTemplatesService.templateDetail.TemplateBalanceConfigurations.push(dataChange[0]);
      }
    }
  }

  public get isShowExtraVacationHours(): boolean {
    return this.isDanishCompany && !!this.companyTemplatesService.templateDetail.VacationTypeId;
  }

  public NumbericTextBoxFFHoursEarnedPerYearOptions: NumericTextBoxOptions = {
    format: 'n2',
    decimals: 2,
    step: 1,
    spinners: false
  };
}
