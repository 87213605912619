import { CommonModule, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';

import { NgProgressModule } from '@ngx-progressbar/core';

import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { UIRouterModule } from '@uirouter/angular';

import { MessageService } from '@progress/kendo-angular-l10n';

import '@progress/kendo-angular-intl/locales/da-GL/all';
import '@progress/kendo-angular-intl/locales/da/all';
import '@progress/kendo-angular-intl/locales/en-GB/all';

import { version } from '../version';
import { AppComponent } from './app.component';

import { routerConfig, routerStates } from './router.config';
import { ApiDataService } from './services/api/api-data.service';
import { AuthenticationTokenInterceptor } from './services/authentication/authentication-token.interceptor';
import { BusyInterceptor } from './services/busy.interceptor';
import { ErrorInterceptor } from './services/error.interceptor';
import { LogInterceptor } from './services/log.interceptor';
import { ModalService } from './services/modal.service';
import { MissingTranslationService } from './services/translation/missing-translation.service';
import { NotTranslatedService } from './services/translation/not-translated.service';

import { CustomControlsModule } from './custom-controls/custom-controls.module';
import { MainModule } from './main/main.module';

import { SentryErrorHandler } from './sentry-error-handler';

import localeDa from '@angular/common/locales/da';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DxButtonModule } from 'devextreme-angular';
import { NgxZendeskWebwidgetConfig, NgxZendeskWebwidgetModule } from 'ngx-zendesk-webwidget';
import { CustomTranslateLoader } from './services/translation/custom-translate-loader';

registerLocaleData(localeDa);

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  lazyLoad = true;
  accountUrl = 'intect.zendesk.com';
  injectionTag = 'body';
  callback(zE: any) {
    // You can call every command you want in here
    zE('webWidget', 'hide');
  }
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    DxButtonModule,
    NgProgressModule.withConfig({
      trickleSpeed: 400,
      speed: 300
    }),
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, ModalService, ApiDataService],
        useClass: CustomTranslateLoader
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationService,
        deps: [NotTranslatedService]
      }
    }),
    UIRouterModule.forRoot({
      states: routerStates,
      useHash: true,
      config: routerConfig
    }),
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    MainModule,
    CustomControlsModule,
    DateInputsModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: LOCALE_ID, useValue: 'da' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BusyInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    File,
    FileOpener,
    FileChooser,
    FileTransfer,
    FilePath
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'translation/' + version + '-locale.', '.json');
}
