import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import * as EXIF from 'exif-js';
import { StaticDataService } from '../../services/api/static-data.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html'
})
export class FileUploadComponent {
  @Input() public label: string;
  @Input() public accept: string;

  private shortFileNameValue: string;
  private isOnChange = false;
  @Input()
  public get shortFileName(): string {
    return this.shortFileNameValue;
  }
  public set shortFileName(value: string) {
    if (this.shortFileNameValue !== value) {
      this.shortFileNameValue = value;
      this.shortFileNameChange.emit(value);
    }
  }
  @Output() public shortFileNameChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() public fileSelected: EventEmitter<File> = new EventEmitter<File>();

  @ViewChild('file', { static: true }) public file: ElementRef;

  constructor(private staticDataService: StaticDataService) {}

  public addFile(): void {
    if (!this.isOnChange) {
      setTimeout(() => {
        this.file.nativeElement.value = '';
        this.file.nativeElement.click();
        // reset isOnchange after 2 seconds, prevent user action cancel event and this function never run.
        setTimeout(() => {
          this.isOnChange = false;
        }, 2000);
      });
      this.isOnChange = true;
    }
  }

  public onFileAdded() {
    const files: File[] = this.file.nativeElement.files;
    if (files && files.length > 0) {
      const file: File = files[0];
      const fileName: string = file.name;
      this.shortFileName = fileName.substr(fileName.lastIndexOf('\\') + 1);
      if (file.type.match('image/*')) {
        this.previewImage(file);
      } else {
        this.fileSelected.emit(file);
      }
    }
    this.isOnChange = false;
  }

  public resetOrientation(srcBase64: string, srcOrientation: number, file: File) {
    if (srcBase64 === undefined && srcOrientation === -1) {
      this.fileSelected.emit(file);
      return;
    }

    const img = new Image();
    img.onload = () => {
      const width = img.width / 4;
      const height = img.height / 4;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // set proper canvas dimensions before transform & export
      if (srcOrientation > 4 && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          break;
      }

      // draw image
      ctx.drawImage(img, 0, 0, width, height);

      // export base64
      let base64 = canvas.toDataURL();

      const dataBase64 = base64.split(',');
      base64 = dataBase64[1];
      const type = file.type;
      const blob = this.staticDataService.dataURItoBlob(base64, type);
      const fileNew = new File([blob], file.name, { type });
      this.fileSelected.emit(fileNew);
    };

    img.src = srcBase64;
  }

  private previewImage(file: File) {
    const img: any = [];
    img[0] = document.createElement('img');
    const reader: any = new FileReader();

    reader.onload = (e: any) => {
      img[0].setAttribute('src', reader.result);
      // important for exif-js! Set src attribute after calling img.prop
      img.src = reader.result;
      EXIF.getData(img, () => {
        const exif: any = EXIF.getAllTags(img);
        if (Object.keys(exif).length > 0) {
          this.resetOrientation(reader.result, exif.Orientation, file);
        } else {
          this.resetOrientation(undefined, -1, file);
        }
      });
    };
    reader.readAsDataURL(file);
  }
}
