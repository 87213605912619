/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../custom-controls/text-edit/text-edit.component.ngfactory";
import * as i2 from "../../../custom-controls/text-edit/text-edit.component";
import * as i3 from "../../../services/session/session.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./employee-name.component";
import * as i7 from "../../../services/employee.service";
var styles_EmployeeNameComponent = [];
var RenderType_EmployeeNameComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmployeeNameComponent, data: {} });
export { RenderType_EmployeeNameComponent as RenderType_EmployeeNameComponent };
function View_EmployeeNameComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h3", [["class", "EmployeeInfo-name"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " ", " ", " ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.companyUser.FirstName; var currVal_1 = _co.companyUser.MiddleName; var currVal_2 = _co.companyUser.LastName; var currVal_3 = _co.currentExternalReference; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_EmployeeNameComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-text-edit", [], null, [[null, "isValidChange"], [null, "isDirtyChange"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isValidChange" === en)) {
        var pd_0 = ((_co.childIsValid[0] = $event) !== false);
        ad = (pd_0 && ad);
    } if (("isDirtyChange" === en)) {
        var pd_1 = ((_co.childIsDirty[0] = $event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = ((_co.companyUser.FirstName = $event) !== false);
        ad = (pd_2 && ad);
    } if (("valueChange" === en)) {
        var pd_3 = (_co.onChange() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_TextEditComponent_0, i1.RenderType_TextEditComponent)), i0.ɵdid(1, 114688, null, 0, i2.TextEditComponent, [i3.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { type: [0, "type"], value: [1, "value"], editMode: [2, "editMode"], isDirty: [3, "isDirty"], isValid: [4, "isValid"], label: [5, "label"], required: [6, "required"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange" }), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "text"; var currVal_1 = _co.companyUser.FirstName; var currVal_2 = _co.editMode; var currVal_3 = _co.childIsDirty[0]; var currVal_4 = _co.childIsValid[0]; var currVal_5 = i0.ɵunv(_v, 1, 5, i0.ɵnov(_v, 2).transform("Employee.FirstName")); var currVal_6 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_EmployeeNameComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-text-edit", [], null, [[null, "isValidChange"], [null, "isDirtyChange"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isValidChange" === en)) {
        var pd_0 = ((_co.childIsValid[1] = $event) !== false);
        ad = (pd_0 && ad);
    } if (("isDirtyChange" === en)) {
        var pd_1 = ((_co.childIsDirty[1] = $event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = ((_co.companyUser.MiddleName = $event) !== false);
        ad = (pd_2 && ad);
    } if (("valueChange" === en)) {
        var pd_3 = (_co.onChange() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_TextEditComponent_0, i1.RenderType_TextEditComponent)), i0.ɵdid(1, 114688, null, 0, i2.TextEditComponent, [i3.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { type: [0, "type"], value: [1, "value"], editMode: [2, "editMode"], isDirty: [3, "isDirty"], isValid: [4, "isValid"], label: [5, "label"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange" }), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "text"; var currVal_1 = _co.companyUser.MiddleName; var currVal_2 = _co.editMode; var currVal_3 = _co.childIsDirty[1]; var currVal_4 = _co.childIsValid[1]; var currVal_5 = i0.ɵunv(_v, 1, 5, i0.ɵnov(_v, 2).transform("Employee.MiddleName")); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_EmployeeNameComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-text-edit", [], null, [[null, "isValidChange"], [null, "isDirtyChange"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isValidChange" === en)) {
        var pd_0 = ((_co.childIsValid[2] = $event) !== false);
        ad = (pd_0 && ad);
    } if (("isDirtyChange" === en)) {
        var pd_1 = ((_co.childIsDirty[2] = $event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = ((_co.companyUser.LastName = $event) !== false);
        ad = (pd_2 && ad);
    } if (("valueChange" === en)) {
        var pd_3 = (_co.onChange() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_TextEditComponent_0, i1.RenderType_TextEditComponent)), i0.ɵdid(1, 114688, null, 0, i2.TextEditComponent, [i3.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { type: [0, "type"], value: [1, "value"], editMode: [2, "editMode"], isDirty: [3, "isDirty"], isValid: [4, "isValid"], label: [5, "label"], required: [6, "required"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange" }), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "text"; var currVal_1 = _co.companyUser.LastName; var currVal_2 = _co.editMode; var currVal_3 = _co.childIsDirty[2]; var currVal_4 = _co.childIsValid[2]; var currVal_5 = i0.ɵunv(_v, 1, 5, i0.ɵnov(_v, 2).transform("Employee.LastName")); var currVal_6 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_EmployeeNameComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-text-edit", [], null, [[null, "isValidChange"], [null, "isDirtyChange"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isValidChange" === en)) {
        var pd_0 = ((_co.childIsValid[3] = $event) !== false);
        ad = (pd_0 && ad);
    } if (("isDirtyChange" === en)) {
        var pd_1 = ((_co.childIsDirty[3] = $event) !== false);
        ad = (pd_1 && ad);
    } if (("valueChange" === en)) {
        var pd_2 = ((_co.companyUser.Initials = $event) !== false);
        ad = (pd_2 && ad);
    } if (("valueChange" === en)) {
        var pd_3 = (_co.onChange() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_TextEditComponent_0, i1.RenderType_TextEditComponent)), i0.ɵdid(1, 114688, null, 0, i2.TextEditComponent, [i3.SessionService, i0.ElementRef, i0.ChangeDetectorRef], { type: [0, "type"], value: [1, "value"], editMode: [2, "editMode"], isDirty: [3, "isDirty"], isValid: [4, "isValid"], label: [5, "label"] }, { valueChange: "valueChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange" }), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "text"; var currVal_1 = _co.companyUser.Initials; var currVal_2 = _co.editMode; var currVal_3 = _co.childIsDirty[3]; var currVal_4 = _co.childIsValid[3]; var currVal_5 = i0.ɵunv(_v, 1, 5, i0.ɵnov(_v, 2).transform("Employee.Initials")); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_EmployeeNameComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmployeeNameComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmployeeNameComponent_2)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmployeeNameComponent_3)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmployeeNameComponent_4)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmployeeNameComponent_5)), i0.ɵdid(9, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.editMode; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.editMode; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.editMode; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.editMode; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.editMode; _ck(_v, 9, 0, currVal_4); }, null); }
export function View_EmployeeNameComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-employee-name", [], null, null, null, View_EmployeeNameComponent_0, RenderType_EmployeeNameComponent)), i0.ɵdid(1, 245760, null, 0, i6.EmployeeNameComponent, [i7.EmployeeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmployeeNameComponentNgFactory = i0.ɵccf("app-employee-name", i6.EmployeeNameComponent, View_EmployeeNameComponent_Host_0, { editMode: "editMode", isDirty: "isDirty", isValid: "isValid", companyUser: "companyUser" }, { editModeChange: "editModeChange", isDirtyChange: "isDirtyChange", isValidChange: "isValidChange", valueChange: "valueChange" }, []);
export { EmployeeNameComponentNgFactory as EmployeeNameComponentNgFactory };
