import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountantSummaryService } from 'src/app/accountant/summary/accountant-summary.service';
import { FormComponentBase } from '../../common/form-component-base';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import { ReportDialogService } from '../../shared-components/report-dialog/report-dialog.service';
import { CompanyGeneralService } from './company-general.service';
var CompanyGeneralComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyGeneralComponent, _super);
    function CompanyGeneralComponent(settingService, sessionService, companyGeneralService, reportDialogService, accountantSummaryService) {
        var _this = _super.call(this) || this;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.companyGeneralService = companyGeneralService;
        _this.reportDialogService = reportDialogService;
        _this.accountantSummaryService = accountantSummaryService;
        _this.discardDialogVisible = false;
        _this.invalidDialogVisible = false;
        _this.isShowtestEmailDialog = false;
        _this.showInvalidFormWarning = false;
        _this.resetChildFrom = false;
        _this.openJoinGridDialog = false;
        _this.reportDialogVisible = false;
        _this.listClassExcluded = ['Footer', '10'];
        _this.ngUnsubscribe = new Subject();
        _this.childIsValid = Array(3).fill(true);
        _this.childIsDirty = Array(3).fill(false);
        _this.companyGeneralService.editModeChange.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (value) {
            if (!value && _this.companyGeneralService.tabDirty) {
                _this.companyGeneralService.onSave();
            }
        });
        _this.companyGeneralService.editShortcutChange.pipe(takeUntil(_this.ngUnsubscribe)).subscribe(function (value) {
            if (value &&
                _this.sessionService.role.IsFullAdmin &&
                !_this.sessionService.isGetting &&
                !_this.companyGeneralService.editMode) {
                _this.onEditModeChange(true);
            }
        });
        _this.companyGeneralService.discardChangeShortcutChange
            .pipe(takeUntil(_this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && _this.companyGeneralService.editMode) {
                _this.confirmDiscardChanges();
            }
        });
        _this.companyGeneralService.saveChangeShortcutChange
            .pipe(takeUntil(_this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && _this.companyGeneralService.editMode) {
                _this.onEditModeChange(false);
            }
        });
        _this.companyGeneralService.showReportShortcutChange
            .pipe(takeUntil(_this.ngUnsubscribe))
            .subscribe(function (value) {
            if (value && !_this.companyGeneralService.editMode && _this.reportDialogService.isVisibleReportIcon) {
                _this.reportDialogService.onShowReportsEventClick();
            }
        });
        _this.valueChange.subscribe(function () {
            _this.companyGeneralService.tabDirty = _this.isDirty;
            _this.companyGeneralService.tabValid = _this.isValid;
        });
        return _this;
    }
    CompanyGeneralComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.companyGeneralService.company.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.companyGeneralService.currentCompany = data;
            _this.resetForm();
        });
        this.getCurrentCompany();
        this.settingService.showModuleMessage('Company.General');
        this.companyGeneralService.navigationToStateName = undefined;
    };
    CompanyGeneralComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.companyGeneralService.ngOnDestroy();
    };
    CompanyGeneralComponent.prototype.onDoubleClick = function () {
        if (this.sessionService.role.IsFullAdmin && !this.sessionService.isGetting) {
            this.onEditModeChange(true);
        }
    };
    CompanyGeneralComponent.prototype.onMoreMenuOptionClick = function (menu) {
        switch (menu) {
            case 'companyGeneral_importData':
                this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
                break;
            case 'companyGeneral_testMail':
                this.isShowtestEmailDialog = true;
                break;
            case 'companyreport':
                this.reportDialogVisible = true;
                break;
            default:
                break;
        }
    };
    CompanyGeneralComponent.prototype.onEditModeChange = function ($event) {
        if (this.companyGeneralService.editMode !== $event) {
            this.companyGeneralService.tabDirty = this.isDirty;
            this.companyGeneralService.tabValid = this.isValid;
            this.companyGeneralService.editMode = $event;
        }
    };
    CompanyGeneralComponent.prototype.ChangeState = function () {
        if (this.sessionService.nextState) {
            this.sessionService.NavigateTo(this.sessionService.nextState);
        }
    };
    CompanyGeneralComponent.prototype.confirmDiscardChanges = function () {
        if (this.isDirty) {
            this.discardDialogVisible = true;
        }
        else {
            this.onEditModeChange(false);
        }
    };
    CompanyGeneralComponent.prototype.discardDialogAction = function (action) {
        if (action === 'ConfirmDiscard') {
            this.getCurrentCompany();
        }
    };
    CompanyGeneralComponent.prototype.getCurrentCompany = function () {
        var _this = this;
        this.companyGeneralService.loadCompany().subscribe(function (company) {
            if (_this.sessionService.role.IsFullAdmin && _this.sessionService.feature.IsMultiCompanyMember) {
                _this.accountantSummaryService.updateCompanyInvoiceGeneral(company);
            }
        });
    };
    CompanyGeneralComponent.prototype.resetForm = function () {
        this.companyGeneralService.tabDirty = false;
        this.childIsValid = Array(3).fill(true);
        this.childIsDirty = Array(3).fill(false);
        this.resetChildFrom = true;
        this.isDirty = false;
        this.companyGeneralService.editMode = false;
    };
    return CompanyGeneralComponent;
}(FormComponentBase));
export { CompanyGeneralComponent };
