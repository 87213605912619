<div class="Card Island-configuration">
  <form class="Card-inner">
    <fieldset class="Form--horizontal">
      <app-module-check [moduleId]="4">
        <app-check-edit
          [label]="'CompanyGeneral.ShowInactiveDepartments' | translate"
          [editMode]="true"
          [(value)]="companyDepartmentService.showInactiveDepartments"
        ></app-check-edit>

        <app-grid
          [(data)]="gridData"
          class="FormElement-grid Company-DepartmentGrid"
          [editMode]="!IsReadOnly"
          [addNew]="!IsReadOnly"
          [deleteRow]="!IsReadOnly"
          [deleteRowField]="'IsActive'"
          [addNewDisable]="sessionService.isGetting"
          [addNewText]="'CompanyGeneral.AddNewDepartment' | translate"
          [(selectedItem)]="selectedDepartment"
          [idColumn]="'GridId'"
          [customDeleteRow]="true"
          [deleteRowField]="'IsActive'"
          (removeEvent)="onRemoveDepartment($event)"
          (addNewEvent)="onAddNew($event)"
          (saveChangesEvent)="onSave($event)"
          [(triggerUpdate)]="refreshData"
          [filterable]="'menu'"
          [filter]="filter"
          (filterChange)="onFilterChange($event)"
        >
          <app-grid-column
            [isFilterable]="true"
            [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
            [field]="'Name'"
            [type]="'text'"
            [editable]="true"
            [title]="'CompanyGeneral.DepartmentName' | translate"
            [width]="120"
          >
          </app-grid-column>
          <app-grid-column
            [isFilterable]="true"
            [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
            [field]="'ManagerCompanyUserId'"
            [type]="'combobox'"
            [comboboxDataSource]="staticDataService.companyUsers | async"
            [type]="'combobox'"
            [comboboxValueField]="'Id'"
            [comboboxDisplayField]="'FullName'"
            [editable]="true"
            [title]="'CompanyGeneral.Manager' | translate"
            [width]="100"
          ></app-grid-column>
          <app-grid-column
            [isFilterable]="true"
            [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
            [field]="'ExternalReference'"
            [type]="'text'"
            [editable]="true"
            [title]="'CompanyGeneral.ERPref' | translate"
            [width]="95"
          ></app-grid-column>
          <app-grid-column
            [isFilterable]="true"
            [viewClass]="!IsReadOnly ? 'truncated' : 'wrapped'"
            [field]="'EIncomeReference'"
            [type]="'text'"
            [editable]="true"
            [title]="'CompanyGeneral.EIncomeReference' | translate"
            [width]="95"
          ></app-grid-column>
        </app-grid>
      </app-module-check>
    </fieldset>
  </form>
</div>

<app-dialog
  [(visible)]="deleteWarningVisible"
  (action)="onDeleteWarningAction($event)"
  [leadingText]="'Grids.ConfirmDelete' | translate"
>
  <app-dialog-action [type]="'Close'"></app-dialog-action>
  <app-dialog-action [type]="'Delete'"></app-dialog-action>
</app-dialog>

<div class="Actions" *ngIf="!companyDepartmentService.isMobile">
  <app-menu-button
    *ngIf="reportDialogService.isVisibleReportIcon"
    [menuCondition]="true"
    (menuClick)="reportDialogService.onShowReportsEventClick()"
    [menuIcon]="'File'"
    [menuLabel]="'Employee.Reports'"
    [menuTooltip]="'Employee.Reports'"
    [actionButtonMenu]="true"
    [menuButtonClass]="'Action-button Action-buttonReports'"
    [menuButtonId]="'ActionButtonReports'"
  >
  </app-menu-button>
</div>
<div class="Actions" *ngIf="companyDepartmentService.isMobile">
  <app-menu-button
    *ngIf="reportDialogService.isVisibleReportIcon"
    [menuIcon]="'ThreeDots'"
    [menuLabel]="'More.Title'"
    [menuTooltip]="'More.Title'"
    [multiMenuItems]="true"
  >
  </app-menu-button>
</div>
