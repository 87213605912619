import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { environment } from 'src/environments/environment';
import { version } from '../../../version';
import { Constants } from '../../common/constants';
import { Global } from '../../common/global';
import { KeyDownHelper } from '../../common/key-down-helper';
import { RouterStateService } from '../../common/router-state.service';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { EmployeeService } from '../../services/employee.service';
import { SessionService } from '../../services/session/session.service';
import { ReportDialogService } from '../../shared-components/report-dialog/report-dialog.service';
var EmployeeTabsComponent = /** @class */ (function () {
    function EmployeeTabsComponent(employeeService, sessionService, transitionService, broadcaster, staticDataService, state, dataService, reportDialogService) {
        var _this = this;
        this.employeeService = employeeService;
        this.sessionService = sessionService;
        this.transitionService = transitionService;
        this.broadcaster = broadcaster;
        this.staticDataService = staticDataService;
        this.state = state;
        this.dataService = dataService;
        this.reportDialogService = reportDialogService;
        this.isDirty = false;
        this.isTabsValid = true;
        this.showInvalidFormWarning = false;
        this.showSaveDataConfirmationChangeState = false;
        this.discardDialogVisible = false;
        this.synchTemplateDialogVisible = false;
        this.createNewEmployeeDialogVisible = false;
        // public unsavedDataDialogVisible = false;
        this.touchTime = 0;
        this.isApiValidationValid = true;
        this.hasUserEmployment = false;
        this.ngUnsubscribe = new Subject();
        this.employeeService.employment.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
            _this.hasUserEmployment = !!(data && data.Id);
        });
    }
    Object.defineProperty(EmployeeTabsComponent.prototype, "employee", {
        get: function () {
            return this.currentEmployee;
        },
        set: function (value) {
            this.currentEmployee = value;
            this.isApiValidationValid = true;
            this.isDirty = false;
            if (value) {
                this.reportDialogService.selectedUserId = value.Id;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "currentUserEmployment", {
        get: function () {
            return this.currentUserEmploymentValue;
        },
        set: function (value) {
            this.currentUserEmploymentValue = value;
            this.originalBasedOnTemplateId = value.BasedOnTemplateId;
            this.isApiValidationValid = true;
            this.isDirty = false;
            if (value) {
                this.reportDialogService.selectedEmploymentId = value.Id;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "editMode", {
        get: function () {
            return this.editModeValue;
        },
        set: function (value) {
            if (this.editModeValue !== value) {
                this.editModeValue = value;
                this.sessionService.hideGetStarted = value;
                this.employeeService.editMode = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "showEmployeeList", {
        get: function () {
            return this.sessionService.feature.ShowEmployeeList;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "employeeGeneralTabVisible", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.employee.general') && !this.EmployeeWithRoleAsManager;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "employeeHiringStatusTabVisible", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.employee.hiringstatus') && !this.EmployeeWithRoleAsManager;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "employeeTimeTabVisible", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.employee.time');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "employeePayrollDataTabVisible", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.employee.payrolldata') && !this.EmployeeWithRoleAsManager;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "employeePayslipTabVisible", {
        get: function () {
            return this.sessionService.currentState.includes('tabs.employee.payslip') && !this.EmployeeWithRoleAsManager;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "allowEditUserEmployment", {
        get: function () {
            return (this.sessionService.feature.AllowEditUserEmployment(this.sessionService.currentState) &&
                !this.sessionService.role.IsReadOnly &&
                !this.sessionService.isGetting &&
                !this.sessionService.isGetting &&
                !this.employeeService.isNonSelected &&
                this.isEnableEditButtonInEmployeeHirring);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "isIOSApp", {
        get: function () {
            return this.sessionService.browser.isHybridApp && this.sessionService.browser.iOSMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "isEnableEditButtonInEmployeeHirring", {
        get: function () {
            if (this.sessionService.currentState === 'tabs.employee.hiringstatus') {
                return this.hasUserEmployment;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "isActive", {
        get: function () {
            return this.sessionService.currentState.indexOf('tabs.employee') >= 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "EmployeeWithRoleAsManager", {
        get: function () {
            return this.sessionService.role.EmployeeWithRoleAsManager;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "employeeTimeTabEnabled", {
        get: function () {
            return this.sessionService.feature.EmployeeTimeTabEnabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTabsComponent.prototype, "employeePayrollDataTabEnabled", {
        get: function () {
            return this.sessionService.feature.EmployeePayrollDataTabEnabled;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTabsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.broadcaster
            .on(Constants.GLOBAL_KEY_DOWN)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) { return _this.onGlobalKeyDown(data); });
        this.transitionService.onStart({}, function (transition) {
            var fromState = transition.from().name;
            _this.navigationToStateName = transition.to().name;
            var generalState = 'tabs.employee.general';
            var hiringstatusState = 'tabs.employee.hiringstatus';
            if (_this.editMode && fromState && (fromState === generalState || fromState === hiringstatusState)) {
                if (_this.isDirty) {
                    // If validation fails, alert user and do nothing.
                    // Else show action confirmation.
                    if (!_this.validate()) {
                        _this.showInvalidFormWarning = true;
                    }
                    else {
                        _this.showSaveDataConfirmationChangeState = true;
                    }
                    return false;
                }
                else {
                    // Editmode but no changes, we close editmode without doing anything
                    _this.editMode = false;
                    return true;
                }
            }
            else {
                _this.editMode = false;
                return true;
            }
        });
        this.employeeService.editModeChangeEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (val) {
            if (val) {
                _this.editMode = true;
            }
        });
    };
    EmployeeTabsComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    EmployeeTabsComponent.prototype.onConfirmSaveDataOnChangeState = function (action) {
        switch (action) {
            case 'ContinueAndSave':
                this.editMode = false;
                this.prepareUpdateUserEmployment(this.navigationToStateName);
                break;
            case 'DiscardAndLeave':
                this.discardChanges();
                this.sessionService.NavigateTo(this.navigationToStateName);
                break;
        }
        this.navigationToStateName = undefined;
    };
    EmployeeTabsComponent.prototype.clickAreamenuToggledChange = function (e) {
        if (this.isMobile) {
            this.checkMobileDoubleClick();
        }
    };
    EmployeeTabsComponent.prototype.checkMobileDoubleClick = function () {
        if (this.touchTime === 0) {
            // set first click
            this.touchTime = new Date().getTime();
        }
        else {
            // compare first click to this click and see if they occurred within double click threshold
            if (new Date().getTime() - this.touchTime < 800) {
                // double click occurred
                this.onDoubleClick();
                this.touchTime = 0;
            }
            else {
                // not a double click so set as a new first click
                this.touchTime = new Date().getTime();
            }
        }
    };
    EmployeeTabsComponent.prototype.PreonDoubleClick = function (e) {
        if (this.editMode === true || this.sessionService.isGetting || this.employeeService.isNonSelected) {
            return;
        }
        if (!this.isEnableEditButtonInEmployeeHirring) {
            return;
        }
        this.onDoubleClick();
    };
    EmployeeTabsComponent.prototype.onDoubleClick = function () {
        var _this = this;
        if (!this.allowEditUserEmployment) {
            return;
        }
        if (!this.editMode &&
            (this.sessionService.currentState === 'tabs.employee.general' ||
                this.sessionService.currentState === 'tabs.employee.hiringstatus')) {
            if (this.editMode === true) {
                return;
            }
            setTimeout(function () {
                _this.onEditModeChange(true);
            });
        }
    };
    EmployeeTabsComponent.prototype.onEditModeChange = function (editMode) {
        if (this.editMode !== editMode) {
            this.isApiValidationValid = true;
            if (!this.validate() && editMode === false) {
                if (version === 'Local-build') {
                    console.log('Keep editmode open');
                }
                return;
            }
            if (editMode === false && this.isDirty) {
                // this.unsavedDataDialogVisible = true;
                this.prepareUpdateUserEmployment();
            }
            this.editMode = editMode;
        }
        // Assume that the user has fixed the api validation and try again
    };
    EmployeeTabsComponent.prototype.confirmAndDiscardChanges = function () {
        if (!this.isDirty) {
            this.editMode = false;
            return;
        }
        this.discardDialogVisible = true;
    };
    EmployeeTabsComponent.prototype.discardDialogAction = function (action) {
        if (action === 'ConfirmDiscard') {
            this.discardChanges();
        }
    };
    // public onUnsavedDataDialogAction(action: string): void {
    //   if (action === 'DiscardAndLeave') {
    //     this.discardChanges();
    //   } else {
    //     this.editMode = false;
    //     this.prepareUpdateUserEmployment();
    //   }
    // }
    EmployeeTabsComponent.prototype.onSynchTemplateDialogAction = function (action) {
        if (action !== 'No') {
            this.performUpdateUserEmployment(true, this.navigateToAfterUpdate);
        }
        else {
            this.performUpdateUserEmployment(false, this.navigateToAfterUpdate);
        }
    };
    EmployeeTabsComponent.prototype.onGlobalKeyDown = function ($event) {
        var _this = this;
        if (!this.isActive) {
            return;
        }
        if (KeyDownHelper.isEditShortcutKey($event)) {
            $event.preventDefault();
            if (this.allowEditUserEmployment) {
                // waitting bindding async employee service
                setTimeout(function () {
                    _this.onEditModeChange(true);
                });
            }
            return;
        }
        if (!this.editMode) {
            return;
        }
        if (KeyDownHelper.isDiscardingChangesShortcutKey($event)) {
            $event.preventDefault();
            if (!this.sessionService.isGetting && !this.sessionService.isSubmitting) {
                this.confirmAndDiscardChanges();
            }
        }
        if (KeyDownHelper.isSavingChangesShortcutKey($event)) {
            $event.preventDefault();
            if (version === 'Local-build') {
                console.log('Save');
            }
            this.onEditModeChange(false);
        }
    };
    EmployeeTabsComponent.prototype.discardChanges = function () {
        this.isDirty = false;
        this.editMode = false;
        this.isTabsValid = true;
        this.employeeService.discardChanges();
    };
    EmployeeTabsComponent.prototype.prepareUpdateUserEmployment = function (navigateTo) {
        this.navigateToAfterUpdate = navigateTo;
        if (this.currentUserEmployment.BasedOnTemplateId !== this.originalBasedOnTemplateId) {
            this.synchTemplateDialogVisible = true;
        }
        else {
            this.performUpdateUserEmployment(false, navigateTo);
        }
    };
    EmployeeTabsComponent.prototype.performUpdateUserEmployment = function (syncTemplate, navigateTo) {
        var _this = this;
        this.employeeService.saveChanges(syncTemplate).subscribe(function () {
            _this.isDirty = false;
            _this.staticDataService.loadSalaryCycleUsedAsync();
            _this.reloadSession();
            _this.staticDataService.loadUserEmployments();
            if (navigateTo) {
                _this.sessionService.NavigateTo(navigateTo);
            }
        }, function () {
            _this.isApiValidationValid = false;
            _this.editMode = true;
            _this.isDirty = true;
        });
    };
    EmployeeTabsComponent.prototype.reloadSession = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.Auth_GetSessionInfo().toPromise()];
                    case 1:
                        data = _a.sent();
                        if (data) {
                            environment.branding.SystemAlias = data.CurrentSystemName;
                            Global.SESSION = data;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeTabsComponent.prototype.validate = function () {
        return this.isTabsValid && this.isApiValidationValid;
    };
    return EmployeeTabsComponent;
}());
export { EmployeeTabsComponent };
