import { Global } from '../../common/global';

export class Role {
  public get IsRoleEmployeeAndHigher(): boolean {
    return (
      Global.SESSION &&
      (Global.SESSION.CurrentRole.Key === 'FullAdmin' ||
        Global.SESSION.CurrentRole.Key === 'SalaryAdmin' ||
        Global.SESSION.CurrentRole.Key === 'Employee')
    );
  }

  public get IsSalaryAdminAndHigher(): boolean {
    return (
      Global.SESSION &&
      (Global.SESSION.CurrentRole.Key === 'FullAdmin' || Global.SESSION.CurrentRole.Key === 'SalaryAdmin')
    );
  }

  public get IsAccountant(): boolean {
    return Global.SESSION && (Global.SESSION.IsAccountAdmin || Global.SESSION.IsAccountMember);
  }

  public get IsAccountAdmin(): boolean {
    return Global.SESSION && Global.SESSION.IsAccountAdmin;
  }

  public get IsFullAdmin(): boolean {
    return Global.SESSION && Global.SESSION.CurrentRole.Key === 'FullAdmin';
  }

  public get IsSalaryAdmin(): boolean {
    return Global.SESSION && Global.SESSION.CurrentRole.Key === 'SalaryAdmin';
  }

  public get IsEmployeeRole(): boolean {
    return Global.SESSION && Global.SESSION.CurrentRole.Key === 'Employee';
  }

  public get EmployeeWithRoleAsManager(): boolean {
    return (
      Global.SESSION &&
      Global.SESSION.CurrentRole &&
      Global.SESSION.CurrentRole.Key === 'Employee' &&
      Global.SESSION.ManagerForUserEmploymentIds &&
      Global.SESSION.ManagerForUserEmploymentIds.length > 0
    );
  }

  public get IsPaymentApprover(): boolean {
    return Global.SESSION && Global.SESSION.IsPaymentApprover;
  }

  public get IsReadOnly(): boolean {
    return Global.SESSION && Global.SESSION.CurrentRole.Key === 'ReadOnly';
  }

  public get IsAdminWithoutMultiCompanyRole(): boolean {
    return !Global.SESSION.AccountRoleId && Global.SESSION.CurrentRole.Key === 'FullAdmin';
  }

  public get IsMultiCompanyUser(): boolean {
    return (
      Global.SESSION &&
      (Global.SESSION.IsAccountAdmin || Global.SESSION.IsAccountMember) &&
      Global.SESSION.SignOnToken.UserAccountId !== null
    );
  }

  public get IsMultiCompanyAdmin(): boolean {
    return (
      Global.SESSION &&
      Global.SESSION.IsAccountAdmin &&
      Global.SESSION.SignOnToken.UserAccountId !== null &&
      Global.SESSION.AccountRoleId === 90
    );
  }
}
