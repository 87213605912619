import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from '../../../common/constants';
import { Global } from '../../../common/global';
import { SalaryBatchStatusEnum } from '../../../model/enum';
import {
  IDepartment,
  ISalaryCycle,
  IUserEmploymentTemplate,
  IUserEmploymentView
} from '../../../services/api/api-model';
import { DataService } from '../../../services/api/data.service';
import { StaticDataService } from '../../../services/api/static-data.service';
import { SessionService } from '../../../services/session/session.service';
import { CompanySalaryBatchesChildFormEntityContext } from '../company-salary-batches-child-form-entity-context';
import { CompanySalaryBatchService } from '../company-salary-batches.service';
import { IExtendUserEmploymentView } from '../extend-user-employment-view';
import { SalaryBatchViewModel } from '../salary-batch-view-model';

@Component({
  selector: 'app-salary-batch-info',
  templateUrl: './salary-batch-info.component.html'
})
export class SalaryBatchInfoComponent implements OnInit, OnDestroy {
  @Output() public createPayrollBatch: EventEmitter<void> = new EventEmitter<void>();
  @Output() public salaryBatchAction: EventEmitter<{ action: string; context: any }> = new EventEmitter<{
    action: string;
    context: any;
  }>();
  @Output() public reloadSalaryBatches: EventEmitter<void> = new EventEmitter<void>();
  @Output() public closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() public reloadEntityContextChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public popup: boolean;

  @Input() public get salaryBatch(): SalaryBatchViewModel {
    return this.salaryBatchValue;
  }
  public set salaryBatch(value: SalaryBatchViewModel) {
    if (this.salaryBatchValue !== value) {
      this.salaryBatchValue = value;
    } else if (!this.popup && !value) {
      this.salaryBatchValue = value;
    }

    this.prepareEntityContext(this.salaryBatch);
  }

  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public get visible(): boolean {
    return this.visibleValue;
  }
  public set visible(value: boolean) {
    if (this.visibleValue !== value) {
      this.visibleValue = value;

      if (value) {
        this.selectDepartmentFilter = [];
        this.selectTemplateFilter = [];
        this.multiSelectDepartmentFilter = [];
        this.multiSelectTemplateFilter = [];
      }
      this.visibleChange.emit(value);
    }
  }

  @Output() public selectedEmployeeIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Input() public get selectedEmployeeIds(): number[] {
    return this.selectedEmployeeIdsValue;
  }
  public set selectedEmployeeIds(value: number[]) {
    if (this.selectedEmployeeIdsValue !== value) {
      this.selectedEmployeeIdsValue = value;
      this.selectedEmployeeIdsChange.emit(value);
    }
  }

  @Input() public salaryCycles: ISalaryCycle[];

  private reloadEntityContextValue = false;
  @Input() public get reloadEntityContext(): boolean {
    return this.reloadEntityContextValue;
  }

  public set reloadEntityContext(value: boolean) {
    if (value !== this.reloadEntityContextValue) {
      this.reloadEntityContextValue = value;
      this.reloadEntityContextChange.emit(value);
    }
  }

  public salaryBatchContext: CompanySalaryBatchesChildFormEntityContext;

  private visibleValue: boolean;
  private selectedEmployeeIdsValue: number[];
  private salaryBatchValue: SalaryBatchViewModel;
  private userEmploymentView: IUserEmploymentView[] = [];

  public isPaymentApprover: boolean = Global.SESSION.IsPaymentApprover;

  constructor(
    private dataService: DataService,
    private staticDataService: StaticDataService,
    public sessionService: SessionService,
    public companySalaryBatchService: CompanySalaryBatchService
  ) {
    this.companySalaryBatchService.salaryBatchEntityContext
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: CompanySalaryBatchesChildFormEntityContext) => {
        this.salaryBatchContext = value;
      });

    this.staticDataService.userEmployments
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: IUserEmploymentView[]) => {
        this.userEmploymentView = value;
      });
  }

  public ngOnInit(): void {
    this.staticDataService.departments.pipe(takeUntil(this.ngUnsubscribe)).subscribe((departments: IDepartment[]) => {
      this.departmentSource = departments.map((d) => ({ Id: d.Id, Name: d.Name }));
    });

    this.dataService
      .EmploymentTemplates_GetEmploymentTemplates(false)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((templates: IUserEmploymentTemplate[]) => {
        this.templateSource = templates;
      });
  }

  protected ngUnsubscribe: Subject<{}> = new Subject();
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get IsGreenlandCompany(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public onCreatePayrollBatchClicked(): void {
    this.createPayrollBatch.emit();
  }

  public get IsReadOnly(): boolean {
    return this.sessionService.role.IsReadOnly;
  }

  public onDetailContextChange(value: any): void {
    this.companySalaryBatchService.setNextValueSalaryBatchEntityContext(value);
  }

  public get isGreenland(): boolean {
    return Global.COMPANY && Global.COMPANY.CountryId === Constants.GREENLAND_COUNTRY_ID;
  }

  public onSalaryBatchDialogAction(action: string): void {
    this.salaryBatchAction.emit({ action, context: this.salaryBatchContext });
  }

  // pick employee area
  private entityContextValue: CompanySalaryBatchesChildFormEntityContext;
  public get entityContext(): CompanySalaryBatchesChildFormEntityContext {
    return this.entityContextValue ? this.entityContextValue : ({} as any);
  }
  public set entityContext(value: CompanySalaryBatchesChildFormEntityContext) {
    if (this.entityContextValue !== value) {
      this.entityContextValue = value;

      this.entityContext.Employees =
        this.userEmploymentView && this.userEmploymentView.length > 0
          ? JSON.parse(JSON.stringify(this.userEmploymentView))
          : [];
    }
  }

  private prepareEntityContext(batch: SalaryBatchViewModel): void {
    if (batch && Array.isArray(batch) === false) {
      const updateContext: CompanySalaryBatchesChildFormEntityContext = new CompanySalaryBatchesChildFormEntityContext(
        this.sessionService
      );
      updateContext.BatchId = batch.Id;
      updateContext.StatusName = batch.StatusName;

      updateContext.ChangeDate = batch.Rejected
        ? batch.Rejected
        : batch.Finalized
        ? batch.Finalized
        : batch.Approved
        ? batch.Approved
        : batch.Created;

      updateContext.SalaryBatchNumber = batch.SalaryBatchNumber;
      updateContext.PeriodId = batch.SalaryPeriodId;
      updateContext.IsDraft = batch.StatusId === SalaryBatchStatusEnum.Draft;
      updateContext.IsPendingForApproval = batch.StatusId === SalaryBatchStatusEnum.ForApproval;
      updateContext.isFinalizing = batch.StatusId === SalaryBatchStatusEnum.Finalizing;
      updateContext.IsFinalized = batch.StatusId === SalaryBatchStatusEnum.Finalized;
      updateContext.IsCancelled = batch.StatusId === SalaryBatchStatusEnum.Cancelled;
      updateContext.IsLocked = batch.StatusId === SalaryBatchStatusEnum.ForApproval;
      updateContext.IsPreliminary = batch.StatusId === SalaryBatchStatusEnum.ForApproval;
      updateContext.PaymentDate = new Date(batch.PayoutDate.toString());
      updateContext.PayoutAllFlex = batch.PayoutAllFlex;
      updateContext.PayoutNewFlex = batch.PayoutNewFlex;
      updateContext.IsRecalcBeforeFinalize = !(updateContext.IsLocked || updateContext.IsPreliminary);
      updateContext.Message = batch.Message;
      updateContext.HasErrors = batch.HasErrors;
      updateContext.HasWarnings = batch.HasWarnings;
      updateContext.CycleId = batch.SalaryCycleId;
      updateContext.EmployeeCategory = 1;
      updateContext.HasBatch = false;

      updateContext.FromDateOverride = batch.FromDateOverride;
      updateContext.ToDateOverride = batch.ToDateOverride;

      this.entityContext = updateContext;
      this.onEmployeeCategoryChanged();
    } else {
      if (/*!this.salaryBatchesContext || */ !this.salaryCycles || this.salaryCycles.length === 0) {
        return;
      }

      const newContext: CompanySalaryBatchesChildFormEntityContext = new CompanySalaryBatchesChildFormEntityContext(
        this.sessionService
      );
      newContext.BatchId = -1;
      newContext.StatusName = undefined;
      newContext.ChangeDate = undefined;
      newContext.IsDraft = true;
      newContext.CycleId = this.salaryCycles[0].Id;
      newContext.PeriodId = undefined;
      newContext.EmployeeCategory = 1;
      newContext.PayoutAllFlex = false;
      newContext.HasBatch = false;
      this.entityContext = newContext;

      this.entityContext.Employees =
        this.userEmploymentView && this.userEmploymentView.length > 0
          ? JSON.parse(JSON.stringify(this.userEmploymentView))
          : [];
      this.setSalaryBatchEmployeeGridDataSource();
      this.onEmployeeCategoryChanged();
    }
  }

  public onEmployeeCategoryChanged(category?: number): void {
    if (this.IsNewBatchWithEmployeeSelection) {
      this.showPickEmployeeDialog();
    }
    this.onEmployeesGridDataSaveChanges();
  }

  public showPickEmployeeDialog() {
    this.companySalaryBatchService.pickEmployeeDialogVisible = true;
    this.filterEmployeesGrid();
  }

  public departmentSource: any[] = [];
  public templateSource: any[] = [];

  public selectDepartmentFilter: number[] = [];
  public selectTemplateFilter: number[] = [];
  public multiSelectDepartmentFilter: Array<{ Id: number; Name: string }> = [];
  public multiSelectTemplateFilter: Array<{ Id: number; Name: string }> = [];

  // grid pick employee
  public defaultEmployeesGridData: IExtendUserEmploymentView[] = [];
  public selectEmployeesGridData: IExtendUserEmploymentView[] = [];
  public filterEmployeesGridData: IExtendUserEmploymentView[] = [];
  public triggerUpdate = false;

  public get DisbaleDepartmentFilter(): boolean {
    return !(this.departmentSource && this.departmentSource.length > 0);
  }

  public get DisbaleTemplateFilter(): boolean {
    return !(this.templateSource && this.templateSource.length > 0);
  }

  public get IsNewBatchWithEmployeeSelection(): boolean {
    return this.entityContext.BatchId === -1 && this.entityContext.EmployeeCategory === 2;
  }

  public get nonemployeePicked(): boolean {
    return this.selectEmployeesGridData.every((model: IExtendUserEmploymentView) => !model.IsChecked);
  }

  public onPickEmloyeeAction(event: any): void {
    if (event && event === 'Ok') {
      this.defaultEmployeesGridData = this.selectEmployeesGridData;
      if (this.entityContext.IsCheckedAllEmployees && this.filterEmployeesGridData.length === 0) {
        this.selectedEmployeeIds = null;
      }
    } else {
      this.resetData();
    }
  }

  public filterEmployeesGrid(): void {
    this.filterEmployeesGridData = this.selectEmployeesGridData;
    if (this.selectDepartmentFilter && this.selectDepartmentFilter.length > 0) {
      this.filterEmployeesGridData = this.filterEmployeesGridData.filter((model: IExtendUserEmploymentView) =>
        this.selectDepartmentFilter.some((item: number) => model.DepartmentId === item)
      );
    }
    if (this.selectTemplateFilter && this.selectTemplateFilter.length > 0) {
      this.filterEmployeesGridData = this.filterEmployeesGridData.filter((model: IExtendUserEmploymentView) =>
        this.selectTemplateFilter.some((item: number) => model.BasedOnTemplateId === item)
      );
    }
    this.selectedEmployeeIds = this.filterEmployeesGridData
      .filter((e: IExtendUserEmploymentView) => e.IsChecked)
      .map((e: IExtendUserEmploymentView) => e.UserEmploymentId);
    this.onEmployeesGridDataSaveChanges();
  }

  public onEmployeeGridAction(action: string): void {
    if (action === 'SelectAll') {
      this.onSelectAllEmployee(true);
    } else {
      this.onSelectAllEmployee(false);
    }

    this.filterEmployeesGrid();

    this.onEmployeesGridDataSaveChanges();
    this.triggerUpdate = true;
  }

  public onEmployeesGridDataSaveChanges(event?: any): void {
    switch (this.entityContext.EmployeeCategory) {
      case 1: // All employeess
        this.selectedEmployeeIds = null;
        break;
      case 3: // No employees
        this.selectedEmployeeIds = [];
        break;
      case 2:
        // Selected employees
        if (event && event.dataItem) {
          this.selectEmployeesGridData.find(
            (model: IExtendUserEmploymentView) => model.UserEmploymentId === event.dataItem.UserEmploymentId
          ).IsChecked = event.dataItem.IsChecked;
        }
        this.selectedEmployeeIds = this.filterEmployeesGridData
          .filter((e: IExtendUserEmploymentView) => e.IsChecked)
          .map((e: IExtendUserEmploymentView) => e.UserEmploymentId);
        this.triggerUpdate = true;

        this.entityContext.IsCheckedAllEmployees = this.selectEmployeesGridData.every(
          (model: IExtendUserEmploymentView) => model.IsChecked
        );
        break;
      default:
        break;
    }
  }

  private resetData() {
    this.onSelectAllEmployee(true);
    this.defaultEmployeesGridData = this.selectEmployeesGridData;
    this.selectedEmployeeIds = this.defaultEmployeesGridData
      .filter((e: IExtendUserEmploymentView) => e.IsChecked)
      .map((e: IExtendUserEmploymentView) => e.UserEmploymentId);

    this.selectDepartmentFilter = [];
    this.selectTemplateFilter = [];
    this.multiSelectDepartmentFilter = [];
    this.multiSelectTemplateFilter = [];
    this.filterEmployeesGrid();
  }

  public onSelectAllEmployee(isChecked: boolean): void {
    this.filterEmployeesGridData.forEach((e: IExtendUserEmploymentView) => (e.IsChecked = isChecked));
    this.entityContext.IsCheckedAllEmployees = this.selectEmployeesGridData.every(
      (model: IExtendUserEmploymentView) => model.IsChecked
    );
  }

  public setSalaryBatchEmployeeGridDataSource(): void {
    if (this.entityContext.Employees) {
      const cycleId: number = this.getChildWindowCycleId();
      let dataSource: IExtendUserEmploymentView[] = [];
      if (cycleId) {
        dataSource = this.entityContext.Employees.filter(
          (e: IExtendUserEmploymentView) => e.SalaryCycleId === cycleId && e.IsActive
        );
      } else {
        dataSource = this.entityContext.Employees.filter((e: IExtendUserEmploymentView) => e.IsActive);
      }

      dataSource.forEach((e: IExtendUserEmploymentView) => {
        if (e.IsTerminated) {
          e.FullName = '(' + e.FullName + ')';
          e.IsChecked = false;
        } else {
          e.IsChecked = true;
        }
      });

      this.defaultEmployeesGridData = JSON.parse(JSON.stringify(dataSource));
      this.selectEmployeesGridData = JSON.parse(JSON.stringify(dataSource));

      this.resetData();
    } else {
      this.defaultEmployeesGridData = [];
      this.selectEmployeesGridData = [];
      this.filterEmployeesGridData = [];
    }
  }

  private getChildWindowCycleId(): number {
    let cycleId: number;
    const dataItem: any = this.salaryCycles.find((cycle: ISalaryCycle) => cycle.Id === this.entityContext.CycleId);
    if (!dataItem) {
      cycleId = this.entityContext.CycleId =
        this.salaryCycles && this.salaryCycles.length > 0 ? this.salaryCycles[0].Id : undefined;
    } else {
      cycleId = dataItem.Id;
    }

    return cycleId;
  }
}
