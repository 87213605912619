import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Browser } from 'src/app/common/browser';
import { ImportTypesEnum } from 'src/app/model/enum';
import { EmployeeService } from 'src/app/services/employee.service';
import { environment } from 'src/environments/environment';
import { Constants } from '../../common/constants';
import { FormComponentBase } from '../../common/form-component-base';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import { ReportDialogService } from '../report-dialog/report-dialog.service';
import { ReportParametersDefaultValue } from '../report-dialog/report-parameters-default-value';
var EmployeeGeneralComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeGeneralComponent, _super);
    function EmployeeGeneralComponent(employeeService, dataService, settingService, sessionService, staticDataService, reportDialogService) {
        var _this = _super.call(this) || this;
        _this.employeeService = employeeService;
        _this.dataService = dataService;
        _this.settingService = settingService;
        _this.sessionService = sessionService;
        _this.staticDataService = staticDataService;
        _this.reportDialogService = reportDialogService;
        _this.isSelfService = false;
        _this.resendWelcomeEmail = false;
        _this.resetPassword = false;
        _this.createNewEmployeeDialogVisible = false;
        _this.reportDialogVisible = false;
        _this.requestTaxcardDialogVisible = false;
        _this.taxcardHistoryDialogVisible = false;
        _this.requestTaxcardWarningDialog = false;
        _this.triggerRefreshTaxCard = false;
        _this.deleteEmployeeDialogVisible = false;
        _this.listClassExcluded = ['EmployeeInfo', 'Footer'];
        _this.taxCardDataChanged = false;
        _this.isLoadTaxCardType = false;
        _this.expanded = false;
        _this.childIsValid = Array(5).fill(true);
        _this.childIsDirty = Array(5).fill(false);
        return _this;
    }
    Object.defineProperty(EmployeeGeneralComponent.prototype, "companyUser", {
        get: function () {
            return this.companyUserValue;
        },
        set: function (value) {
            this.companyUserValue = value;
            this.childIsValid = Array(5).fill(true);
            this.childIsDirty = Array(5).fill(false);
            this.reportParams();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeGeneralComponent.prototype, "userEmployment", {
        get: function () {
            return this.userEmploymentValue;
        },
        set: function (value) {
            if (JSON.stringify(value) !== JSON.stringify(this.userEmploymentValue)) {
                this.taxCardDataChanged = true;
            }
            this.userEmploymentValue = value;
            this.reportParams();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeGeneralComponent.prototype, "allowEdit", {
        get: function () {
            return this.sessionService.role.IsSalaryAdminAndHigher;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeGeneralComponent.prototype, "taxCardObject", {
        get: function () {
            this.setTaxCardData(this.userEmployment);
            return this.taxCardData;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeGeneralComponent.prototype.reportParams = function () {
        var params = new ReportParametersDefaultValue();
        params.employeeId = this.companyUser ? this.companyUser.Id : undefined;
        params.employmentId = this.userEmployment ? this.userEmployment.Id : undefined;
        this.reportDialogService.reportParams = params;
    };
    Object.defineProperty(EmployeeGeneralComponent.prototype, "isMobile", {
        get: function () {
            var browser = new Browser();
            return browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeGeneralComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeGeneralComponent.prototype.ngOnInit = function () {
        if (this.IsActive) {
            this.settingService.showModuleMessage('Employee.General');
        }
    };
    EmployeeGeneralComponent.prototype.importEmployeesClick = function () {
        this.settingService.navigationParameters['dataImportSelectedOption'] = ImportTypesEnum.Employees;
        this.sessionService.NavigateTo('tabs.company.configuration.dataimport');
    };
    Object.defineProperty(EmployeeGeneralComponent.prototype, "IsActive", {
        get: function () {
            return (this.sessionService.currentState === 'tabs.employee.general' ||
                this.sessionService.currentState === 'tabs.selfservice.general');
        },
        enumerable: true,
        configurable: true
    });
    EmployeeGeneralComponent.prototype.setTaxCardData = function (value, newtaxInfo) {
        var _this = this;
        if (this.taxCardDataChanged || newtaxInfo) {
            this.taxCardDataChanged = false;
            var isSpecialTaxBrief_1;
            if (value && (value.IncomeTypeId === 0 || value.IncomeTypeId === Constants.MAGIC_NUMBER)) {
                isSpecialTaxBrief_1 = false;
            }
            else {
                isSpecialTaxBrief_1 = true;
            }
            if (this.taxCardTypes && this.taxCardTypes.length > 0 && this.isLoadTaxCardType) {
                // Only create a new instance of taxcard if there is changes
                this.taxCardData = {
                    taxInfo: newtaxInfo ? newtaxInfo : this.saveTaxInfo ? this.saveTaxInfo : undefined,
                    taxCardRequestTime: value ? value.TaxCardRequested : undefined,
                    taxCardTypes: this.taxCardTypes,
                    userEmploymentId: value ? value.Id : undefined,
                    specialTaxBrief: isSpecialTaxBrief_1
                };
                this.taxCardDataChanged = false;
            }
            else {
                this.staticDataService.TaxCardType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (data) {
                    _this.taxCardTypes = data;
                    // Only create a new instance of taxcard if there is changes
                    _this.taxCardData = {
                        taxInfo: undefined,
                        taxCardRequestTime: value ? value.TaxCardRequested : undefined,
                        taxCardTypes: _this.taxCardTypes,
                        userEmploymentId: value ? value.Id : undefined,
                        specialTaxBrief: isSpecialTaxBrief_1
                    };
                    _this.taxCardDataChanged = false;
                    _this.isLoadTaxCardType = true;
                });
            }
        }
    };
    EmployeeGeneralComponent.prototype.onresendEmployeeWelcomEmailConfirmCompleted = function (action) {
        var _this = this;
        if (action === 'Proceed') {
            this.dataService.CompanyUsers_ResendWelcomeEmail(this.companyUser.Id).subscribe(function () {
                _this.resendEmailSuccessVisible = true;
            });
        }
    };
    EmployeeGeneralComponent.prototype.onResetEmployeePasswordConfirmCompleted = function (action) {
        var _this = this;
        if (action === 'Proceed') {
            this.dataService.CompanyUsers_ResetUserPassword(this.companyUser.Id).subscribe(function (data) {
                _this.resetPasswordSuccessVisible = true;
            });
        }
    };
    EmployeeGeneralComponent.prototype.onReloadTaxCard = function () {
        this.triggerRefreshTaxCard = !this.triggerRefreshTaxCard;
        // this.employeeService.selectEmployee(this.companyUser.Id, true);
    };
    EmployeeGeneralComponent.prototype.onRequestTaxcardWarningDialogAction = function (action) {
        if (action === 'AlreadyRequestedTaxCardConfirmButton') {
            this.showRequestTaxcardDialog();
        }
    };
    EmployeeGeneralComponent.prototype.showRequestTaxcardDialog = function () {
        this.requestTaxcardDialogVisible = true;
    };
    EmployeeGeneralComponent.prototype.showViewTaxCardHistory = function (value) {
        this.saveTaxInfo = value;
        this.taxcardHistoryDialogVisible = true;
    };
    EmployeeGeneralComponent.prototype.showRequestTaxcardConfirm = function (value) {
        this.saveTaxInfo = value;
        this.requestTaxcardWarningDialog = true;
    };
    EmployeeGeneralComponent.prototype.onCreateTaxInfo = function (value) {
        if (value) {
            this.setTaxCardData(this.userEmployment, value);
        }
    };
    EmployeeGeneralComponent.prototype.onExpand = function (event) {
        this.expanded = event;
    };
    return EmployeeGeneralComponent;
}(FormComponentBase));
export { EmployeeGeneralComponent };
