import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormComponentBase } from '../../common/form-component-base';
import { Global } from '../../common/global';
import {
  IStatisticsDiscoCode,
  IStatisticsEmploymentPrinciple,
  IStatisticsEmploymentTerms,
  IStatisticsJobStatus,
  IStatisticsSalaryPrinciple
} from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { EmploymentStatisticsModel } from './employment-statistics-model';

@Component({
  selector: 'app-employment-statistics',
  templateUrl: './employment-statistics.component.html'
})
export class EmploymentStatisticsComponent extends FormComponentBase implements OnInit, OnDestroy {
  private statisticsValue: EmploymentStatisticsModel = {} as any;
  @Input()
  public get statistics(): EmploymentStatisticsModel {
    return this.statisticsValue;
  }
  public set statistics(value: EmploymentStatisticsModel) {
    this.statisticsValue = value || ({} as any);
  }

  public salaryPrinciples: IStatisticsSalaryPrinciple[];
  public statisticsEmploymentPrinciple: IStatisticsEmploymentPrinciple[];
  public statisticsEmploymentTerms: IStatisticsEmploymentTerms[];
  public statisticsJobStatus: IStatisticsJobStatus[];
  public jobFunctionCodeStrings: string[] = [];
  public jobFunctionCodes: IStatisticsDiscoCode[];

  constructor(private dataService: DataService, private staticData: StaticDataService) {
    super();
    this.childIsValid = Array(4).fill(true);
    this.childIsDirty = Array(10).fill(false);
  }

  public ngOnInit(): void {
    this.staticData.StatisticsEmploymentPrinciple.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IStatisticsEmploymentPrinciple[]) => {
        this.statisticsEmploymentPrinciple = data;
      }
    );

    this.staticData.StatisticsEmploymentTerms.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IStatisticsEmploymentTerms[]) => {
        this.statisticsEmploymentTerms = data;
      }
    );

    this.dataService.Miscellaneous_GetStatisticsDiscoCodes().subscribe((data: IStatisticsDiscoCode[]) => {
      this.jobFunctionCodes = data;
      this.jobFunctionCodes.map((item: any) => {
        item.displayName = this.formatJobFunctionCodes(item);
      });
    });

    this.staticData.StatisticsJobStatus.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IStatisticsJobStatus[]) => {
        this.statisticsJobStatus = data;
      }
    );

    this.staticData.StatisticsSalaryPrinciple.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: IStatisticsSalaryPrinciple[]) => {
        this.salaryPrinciples = data;
      }
    );
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public StatisticsJobFunctionCodeChange(): void {
    this.statistics.StatisticsJobFunctionCode = this.statistics.StatisticsJobFunctionCode || null;
    this.onChange();
  }

  public get IsEmployeeEmploymentStatistics(): boolean {
    return this.statistics && (this.statistics as any).HireDate;
  }

  public get JobFunctionName(): string {
    if (
      !this.statistics ||
      !this.statistics.StatisticsJobFunctionCode ||
      !this.jobFunctionCodes ||
      this.jobFunctionCodes.length === 0
    ) {
      return '';
    }

    const code: IStatisticsDiscoCode = this.jobFunctionCodes.find(
      (c: IStatisticsDiscoCode) => c.Code.toString() === this.statistics.StatisticsJobFunctionCode.toString()
    );
    return code ? code.Name : '';
  }

  private prepareDataForSearchJobFunctionCodes(jobFunctionCodes: any): string[] {
    const codes: string[] = jobFunctionCodes.map((item: any) => this.formatJobFunctionCodes(item));
    return codes;
  }

  private formatJobFunctionCodes(jobFunctionCode: any): string {
    return jobFunctionCode.Code.toString() + ' - ' + jobFunctionCode.Name;
  }

  public get isShow(): boolean {
    return Global.COMPANY && Global.COMPANY.StatisticsRecipientId && Global.COMPANY.StatisticsRecipientId === 7;
  }

  public onReadMoreClick(): void {
    window.open('http://support.gratisal.com/hc/da/articles/213857065-Ops%C3%A6tning-og-indberetning-til-statistik');
  }
}
