import { OnInit } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../../../environments/environment';
import { version } from '../../../version';
import { Global } from '../../common/global';
import { DataService } from '../../services/api/data.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
var FeedbackComponent = /** @class */ (function () {
    function FeedbackComponent(dataService, sessionService, settingService) {
        this.dataService = dataService;
        this.sessionService = sessionService;
        this.settingService = settingService;
        this.apiVersion = '';
        this.clientVersion = '';
        this.feedbackDialogVisible = false;
        this.successDialogVisible = false;
        this.country = '';
        this.widthDestopFooter = '';
        this.paddingDestopFooter = '';
        this.languageCode = 'en/prices';
        this.clientVersion = version;
        this.getApiVersion();
    }
    Object.defineProperty(FeedbackComponent.prototype, "environment", {
        get: function () {
            return environment.environment;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FeedbackComponent.prototype, "branding", {
        get: function () {
            return environment.branding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FeedbackComponent.prototype, "demo", {
        get: function () {
            return this.sessionService.feature.IsDemoVersion;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FeedbackComponent.prototype, "isMobile", {
        get: function () {
            return this.sessionService.browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FeedbackComponent.prototype, "isHaveSupportModule", {
        get: function () {
            return this.sessionService.feature.hasModuleId(7) || this.sessionService.feature.hasModuleId(17);
        },
        enumerable: true,
        configurable: true
    });
    FeedbackComponent.prototype.ngOnInit = function () {
        this.userId = Global.SESSION.CurrentCompanyUser.UserId;
        this.accountId = Global.SESSION.SignOnToken.UserAccountId;
        this.companyId = Global.SESSION.CurrentCompanyId;
        this.country = Global.SESSION.CurrentCompany.Country.Name;
        if (!this.isMobile) {
            this.widthDestopFooter = 'widthDestopFooter';
        }
        var isEng = (Global.SESSION ? Global.SESSION.SignOnToken.LanguageId : 1) === 2;
        if (isEng) {
            this.languageCode = 'en/prices';
        }
        else {
            this.languageCode = 'da/priser';
        }
    };
    FeedbackComponent.prototype.onAction = function (action) {
        if (action === 'Submit') {
            this.submitFeedback();
        }
    };
    FeedbackComponent.prototype.getApiVersion = function () {
        var _this = this;
        if (this.settingService.apiVersion) {
            this.apiVersion = this.settingService.apiVersion;
            Sentry.setTags({ apiVersion: this.settingService.apiVersion });
            Sentry.setTags({ clientVersion: this.clientVersion });
        }
        else {
            this.dataService.Miscellaneous_GetVersion().subscribe(function (data) {
                _this.apiVersion = data;
                _this.settingService.apiVersion = data;
                Sentry.setTags({ apiVersion: data });
                Sentry.setTags({ clientVersion: _this.clientVersion });
            });
        }
    };
    FeedbackComponent.prototype.submitFeedback = function () {
        var _this = this;
        if (!this.feedbackText || this.feedbackText.trim() === '') {
            this.feedbackDialogVisible = false;
            return;
        }
        this.dataService
            .Miscellaneous_SubmitFeedback({ Message: this.feedbackText })
            .subscribe(function () { return (_this.successDialogVisible = true); });
    };
    return FeedbackComponent;
}());
export { FeedbackComponent };
