import { EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IReportArea } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/api/static-data.service";
import * as i2 from "../../services/session/session.service";
import * as i3 from "../../services/setting.service";
import * as i4 from "../../services/api/data.service";
var ReportDialogService = /** @class */ (function () {
    function ReportDialogService(staticDataService, sessionService, settingService, dataService) {
        var _this = this;
        this.staticDataService = staticDataService;
        this.sessionService = sessionService;
        this.settingService = settingService;
        this.dataService = dataService;
        this.reportDialogVisibleValue = false;
        this.key = '';
        this.visibleChange = new EventEmitter();
        this.staticDataService.ReportArea.pipe().subscribe(function (data) { return (_this.reportAreas = data); });
    }
    Object.defineProperty(ReportDialogService.prototype, "appReport", {
        get: function () {
            if (!this.appReportSubject) {
                this.appReportSubject = new BehaviorSubject([]);
            }
            return this.appReportSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    ReportDialogService.prototype.changeReportImage = function (data) {
        if (!this.appReportSubject) {
            this.appReportSubject = new BehaviorSubject([]);
        }
        var allState = this.appReportSubject.value;
        var currentState = allState.find(function (model) { return model.Id === data.Id && model.AreaId === data.AreaId; });
        if (currentState) {
            var indexOfCurrentState = allState.indexOf(currentState);
            allState[indexOfCurrentState] = data;
        }
        else {
            allState.push(data);
        }
        this.appReportSubject.next(allState);
    };
    Object.defineProperty(ReportDialogService.prototype, "reportDialogVisible", {
        get: function () {
            return this.reportDialogVisibleValue;
        },
        set: function (value) {
            if (value !== this.reportDialogVisibleValue) {
                this.reportDialogVisibleValue = value;
                this.visibleChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogService.prototype, "localUrl", {
        get: function () {
            if (this.sessionService.currentState) {
                switch (this.sessionService.currentState) {
                    // Employee Area
                    case 'tabs.employee.general':
                        return '/employee/general';
                    case 'tabs.employee.hiringstatus':
                        return '/employee/hiringstatus';
                    case 'tabs.employee.time':
                        return '/employee/time';
                    case 'tabs.employee.payrolldata':
                        return '/employee/payrolldata';
                    case 'tabs.employee.payslip':
                        return '/employee/payslip';
                    // Company Area
                    case 'tabs.company.general':
                        return '/company/general';
                    case 'tabs.company.payrolldata':
                        return '/company/payrolldata';
                    case 'tabs.company.salarybatches':
                        return '/company/salarybatches';
                    case 'tabs.company.balances.residualvacation':
                        return '/company/residualvacation';
                    case 'tabs.company.balances.extravacationhours':
                        return '/company/extravacationhours';
                    case 'tabs.company.approval.timeentry':
                        return '/company/approval/timeentry';
                    case 'tabs.company.configuration.hiringstatus':
                        return '/company/employmenttemplates';
                    case 'tabs.company.configuration.departments':
                        return '/company/departments';
                    case 'tabs.company.configuration.integrations':
                        return '/company/integrations';
                    case 'tabs.company.configuration.dataimport':
                        return '/company/dataimport';
                    case 'tabs.company.configuration.settings':
                        return '/company/settings';
                    case 'tabs.company.configuration.advancedtypes.advancedsalarytypes':
                        return '/company/advancedsalarytypes';
                    case 'tabs.company.configuration.advancedtypes.timeentrytypes':
                        return '/company/timeentrytypes';
                    case 'tabs.company.configuration.advancedtypes.salarytypessingle':
                        return '/company/salarytypessingle';
                    case 'tabs.company.configuration.advancedtypes.timeentrytypessingle':
                        return '/company/timeentrytypessingle';
                    case 'tabs.company.modules':
                        return '/company/modules';
                    // Self service Area
                    case 'tabs.selfservice.general':
                        return '/selfservice/general';
                    case 'tabs.selfservice.payslip':
                        return '/selfservice/payslip';
                    case 'tabs.selfservice.time':
                        return '/selfservice/time';
                    // Accountant Area
                    case 'tabs.accountant.companies':
                        return '/accountant/companies';
                    case 'tabs.accountant.users':
                        return '/accountant/users';
                    case 'tabs.accountant.configuration':
                        return '/accountant/configuration';
                    default:
                        return '';
                }
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDialogService.prototype, "isVisibleReportIcon", {
        get: function () {
            var _this = this;
            if (this.reportAreas && this.reportAreas.length > 0 && this.localUrl) {
                return !!this.reportAreas.find(function (item) { return item.AppUri === _this.localUrl; });
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    ReportDialogService.prototype.onShowReportsEventClick = function () {
        var _this = this;
        if (this.isVisibleReportIcon) {
            this.key = this.reportAreas.find(function (item) { return item.AppUri === _this.localUrl; }).Key;
            this.reportDialogVisible = true;
        }
    };
    ReportDialogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReportDialogService_Factory() { return new ReportDialogService(i0.ɵɵinject(i1.StaticDataService), i0.ɵɵinject(i2.SessionService), i0.ɵɵinject(i3.SettingService), i0.ɵɵinject(i4.DataService)); }, token: ReportDialogService, providedIn: "root" });
    return ReportDialogService;
}());
export { ReportDialogService };
