/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./grid-in-cell-popup.component";
var styles_GridInCellPopupComponent = [];
var RenderType_GridInCellPopupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GridInCellPopupComponent, data: {} });
export { RenderType_GridInCellPopupComponent as RenderType_GridInCellPopupComponent };
function View_GridInCellPopupComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "AlertBox--content"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "addCursor"], ["style", "font-size: large;\n    \t\t\t\tfont-weight: bolder;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onHidePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["x"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content; _ck(_v, 0, 0, currVal_0); }); }
function View_GridInCellPopupComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "AlertBox-buttonSecondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onActionClicked(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_GridInCellPopupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "AlertBox AlertBox--disable"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridInCellPopupComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "AlertBox-footer"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridInCellPopupComponent_3)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.actions; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_GridInCellPopupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_GridInCellPopupComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GridInCellPopupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-grid-in-cell-popup", [], null, null, null, View_GridInCellPopupComponent_0, RenderType_GridInCellPopupComponent)), i0.ɵdid(1, 49152, null, 0, i2.GridInCellPopupComponent, [], null, null)], null, null); }
var GridInCellPopupComponentNgFactory = i0.ɵccf("app-grid-in-cell-popup", i2.GridInCellPopupComponent, View_GridInCellPopupComponent_Host_0, { content: "content", actions: "actions", model: "model", visible: "visible" }, { click: "click" }, []);
export { GridInCellPopupComponentNgFactory as GridInCellPopupComponentNgFactory };
