import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { EmployeeService } from 'src/app/services/employee.service';
import { ISalaryBatchTotalsApiView, ISalaryTypeSumsByEmploymentView } from '../../../../services/api/api-model';
import { DataService } from '../../../../services/api/data.service';
import { SessionService } from '../../../../services/session/session.service';

@Component({
  selector: 'app-salary-batch-salary-types',
  templateUrl: './salary-batch-salary-types.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SalaryBatchSalaryTypesComponent {
  @Input()
  public get SalaryBatchId(): number {
    return this.salaryBatchIdValue;
  }
  public set SalaryBatchId(value: number) {
    if (this.salaryBatchIdValue !== value) {
      this.salaryBatchIdValue = value;
    }
  }

  @Input()
  public get SalaryTypeTotals(): ISalaryBatchTotalsApiView[] {
    return this.salaryTypeTotalsValue;
  }

  public set SalaryTypeTotals(value: ISalaryBatchTotalsApiView[]) {
    if (value !== this.salaryTypeTotalsValue) {
      this.salaryTypeTotalsValue = value;
      this.salaryTypePerEmployee = [];

      if (value && value.length > 0 && this.SalaryBatchId) {
        this.selectedSalaryTypeTotal = value[0];
      }

      this.componentUpdate();
    }
  }

  public selectedSalaryTypeTotal: ISalaryBatchTotalsApiView;
  public salaryTypePerEmployee: ISalaryTypeSumsByEmploymentView[] = [];
  private salaryBatchIdValue: number;
  private salaryTypeTotalsValue: ISalaryBatchTotalsApiView[] = [];

  constructor(
    private dataService: DataService,
    private sessionService: SessionService,
    private employeeService: EmployeeService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  private componentUpdate(): void {
    setTimeout(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  public onSelectedSalaryTypeTotalChange(data: ISalaryBatchTotalsApiView): void {
    this.dataService.SalaryBatches_GetSalaryTypeTotalsByEmployee(this.SalaryBatchId, data.SalaryTypeId).subscribe(
      (salaryTypeSum: ISalaryTypeSumsByEmploymentView[]) => {
        this.salaryTypePerEmployee = salaryTypeSum;
      },
      () => {},
      () => {
        this.componentUpdate();
      }
    );
  }

  public onDoubleClick(data: ISalaryTypeSumsByEmploymentView): void {
    if (data && data.UserEmploymentId) {
      this.employeeService.selectEmployeeFromEmploymentId(data.UserEmploymentId);
      this.sessionService.NavigateTo('tabs.employee.payrolldata');
    }
  }
}
