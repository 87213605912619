<app-dialog
  [overlayClose]="false"
  [width]="450"
  [(visible)]="visible"
  (action)="onDialogAction($event)"
  [title]="'CompanyTimeEntryTypes.NewCustomTimeEntryTypeHeader' | translate"
>
  <div *ngIf="visible">
    <app-text-edit
      [label]="'CompanyTimeEntryTypes.Name' | translate"
      [editMode]="!IsReadOnly"
      [(value)]="timeEntryTypeName"
    ></app-text-edit>

    <app-combobox-edit
      [label]="'CompanyTimeEntryTypes.BaseTimeEntryType' | translate"
      [placeholder]="'CompanySalaryTypes.PleaseChoose' | translate"
      [editMode]="!IsReadOnly"
      [textField]="'Name'"
      [idField]="'Id'"
      [comboboxDataSource]="defaultTimeEntryTypes"
      [(value)]="baseTimeEntryTypeId"
    ></app-combobox-edit>
  </div>

  <app-dialog-action [type]="'Cancel'"></app-dialog-action>
  <app-dialog-action
    [type]="'Create'"
    [disabled]="sessionService.isSubmitting || !timeEntryTypeName || !baseTimeEntryTypeId"
    [close]="false"
  >
  </app-dialog-action>
</app-dialog>

<app-dialog
  class="blue-dialog"
  [(visible)]="validationWarningVisible"
  [leadingText]="'CompanySalaryTypes.CreateNewFieldsIncomplete' | translate"
>
  <app-dialog-action [type]="'Ok'"></app-dialog-action>
</app-dialog>
