import { OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filterBy } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Browser } from '../../common/browser';
import { FilterDateTimeGrid } from '../../common/filter-date-time-grid';
import { Global } from '../../common/global';
import { GridHelper } from '../../common/grid-helper';
import { ExtendSalaryPeriod } from '../../model/extend-salary-period';
import { IRejectionInfo, ISalaryCycle, ISalaryPeriod, ISimpleKeyValuePair, ITimeEntryRecord, ITimeEntryStatus, ITimeEntryType, TimeEntryStatus } from '../../services/api/api-model';
import { DataService } from '../../services/api/data.service';
import { StaticDataService } from '../../services/api/static-data.service';
import { SessionService } from '../../services/session/session.service';
import { SettingService } from '../../services/setting.service';
var CompanyApprovalGridComponent = /** @class */ (function () {
    function CompanyApprovalGridComponent(translateService, dataService, settingService, staticDataService, sessionService) {
        this.translateService = translateService;
        this.dataService = dataService;
        this.settingService = settingService;
        this.staticDataService = staticDataService;
        this.sessionService = sessionService;
        this.TimeEntryTypes = [];
        this.TimeEntryStatuses = [];
        this.ApprovalSubodinates = [];
        this.allowManagersToApproveTimeEntry = false;
        this.option = { min: 0, step: 1, spinners: false };
        // pageging for approval
        this.currentpage = 0;
        this.dimensionXValues = [];
        this.AllText = 'All';
        this.isSelect1020default = true;
        this.TimeEntryRecord = [];
        this.defaultTimeEntryRecords = {};
        this.timeEntryRecords = [];
        this.numberOption = { min: undefined, step: 1, spinners: false, decimals: 2, format: 'n2' };
        /*end variable of approval timeentry*/
        this.preSalaryCycleId = 0;
        this.preSalaryPeriodId = 0;
        this.preStatus = 0;
        this.ngUnsubscribe = new Subject();
        this.isSalaryCycleChanging = false;
        this.isSalaryPeriodChanging = false;
        this.isShowRejectDialog = false;
        this.isShowRejectDialogNonApproveTimeEntry = false;
        this.isShowApproveForSelectDialog = false;
        this.isShowApproveForAllDialog = false;
        this.isShowApprovalOpenEntries = false;
        this.translationServiceTerms = [
            'EmploymentTime.PeriodWarning',
            'CompanySalaryBatches.SalaryCycle_Item_All_Text',
            'EmploymentTime.EligibleForApproval'
        ];
        this.UnitTypes = Global.UNIT_TYPES;
    }
    CompanyApprovalGridComponent.prototype.loadDataSourceSalaryCycle = function () {
        var _this = this;
        this.dataService.Miscellaneous_GetUsedSalaryCycles().subscribe(function (data) {
            _this.SalaryCycle = data;
            if (_this.SalaryCycle && _this.SalaryCycle.length > 0) {
                if (_this.sessionService.currentSalaryCycleId) {
                    _this.selectedSalaryCycleId = _this.sessionService.currentSalaryCycleId;
                    _this.sessionService.currentSalaryCycleId = undefined;
                }
                else {
                    _this.selectedSalaryCycleId = _this.SalaryCycle[0].Id;
                }
                _this.populateDataApprovalTimeEntry();
            }
        });
    };
    CompanyApprovalGridComponent.prototype.populateDataApprovalTimeEntry = function () {
        var _this = this;
        this.dataService
            .SalaryBatches_GetSalaryPeriods(this.selectedSalaryCycleId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (salaryPeriods) {
            if (salaryPeriods && salaryPeriods.length > 0) {
                _this.SalaryPeriod = [];
                salaryPeriods = salaryPeriods.sort(function (data1, data2) {
                    return GridHelper.sortByDateValue(data1.StartDate, data2.StartDate);
                });
                salaryPeriods.forEach(function (salaryPeriod, key) {
                    var startDate = _this.sessionService.toString(salaryPeriod.StartDate ? new Date(salaryPeriod.StartDate) : undefined);
                    var endDate = _this.sessionService.toString(salaryPeriod.EndDate ? new Date(salaryPeriod.EndDate) : undefined);
                    var preference = _this.sessionService.getCompanyPreference('SalaryPeriods.FriendlyNames');
                    if ((preference && preference.Value && preference.Value === 'true') || _this.isMobile) {
                        var extendSalaryPeriod = new ExtendSalaryPeriod();
                        (extendSalaryPeriod.Id = salaryPeriod.Id), (extendSalaryPeriod.StartDate = salaryPeriod.StartDate);
                        extendSalaryPeriod.EndDate = salaryPeriod.EndDate;
                        extendSalaryPeriod.Period = startDate + ' - ' + endDate;
                        extendSalaryPeriod.FriendlyName = ' ' + salaryPeriod.FriendlyName;
                        _this.SalaryPeriod[key] = extendSalaryPeriod;
                    }
                    else {
                        var extendSalaryPeriod = new ExtendSalaryPeriod();
                        extendSalaryPeriod.Id = salaryPeriod.Id;
                        extendSalaryPeriod.StartDate = salaryPeriod.StartDate;
                        extendSalaryPeriod.EndDate = salaryPeriod.EndDate;
                        extendSalaryPeriod.Period = startDate + ' - ' + endDate;
                        extendSalaryPeriod.FriendlyName = startDate + ' - ' + endDate;
                        _this.SalaryPeriod[key] = extendSalaryPeriod;
                    }
                });
                if (_this.sessionService.currentSalaryPeriodId) {
                    if (_this.SalaryPeriod && _this.SalaryPeriod.length > 0) {
                        var periodChek = _this.SalaryPeriod.filter(function (model) { return model.Id === _this.selectedSalaryPeriodId; });
                        if (periodChek && periodChek.length > 0) {
                            _this.selectedSalaryPeriodId = _this.sessionService.currentSalaryPeriodId;
                            _this.sessionService.currentSalaryPeriodId = _this.selectedSalaryPeriodId;
                        }
                        else {
                            _this.sessionService.currentSalaryPeriodId = undefined;
                            if (_this.SalaryPeriod && _this.SalaryPeriod[0]) {
                                var today_1 = _this.staticDataService.getCurrentdate();
                                today_1.setHours(0, 0, 0, 0);
                                var selectedSalaryPeriod = _this.SalaryPeriod.find(function (salaryPeriod) {
                                    var startDate = new Date(salaryPeriod.StartDate.toString());
                                    var endDate = new Date(salaryPeriod.EndDate.toString());
                                    startDate.setHours(0, 0, 0, 0);
                                    endDate.setHours(0, 0, 0, 0);
                                    return startDate <= today_1 && endDate >= today_1;
                                });
                                _this.selectedSalaryPeriodId = selectedSalaryPeriod.Id;
                                _this.sessionService.currentSalaryPeriodId = _this.selectedSalaryPeriodId;
                            }
                        }
                    }
                }
                else {
                    if (_this.SalaryPeriod && _this.SalaryPeriod[0]) {
                        var today_2 = _this.staticDataService.getCurrentdate();
                        today_2.setHours(0, 0, 0, 0);
                        var selectedSalaryPeriod = _this.SalaryPeriod.find(function (salaryPeriod) {
                            var startDate = new Date(salaryPeriod.StartDate.toString());
                            var endDate = new Date(salaryPeriod.EndDate.toString());
                            startDate.setHours(0, 0, 0, 0);
                            endDate.setHours(0, 0, 0, 0);
                            return startDate <= today_2 && endDate >= today_2;
                        });
                        _this.selectedSalaryPeriodId = selectedSalaryPeriod.Id;
                        _this.sessionService.currentSalaryPeriodId = _this.selectedSalaryPeriodId;
                    }
                }
            }
            else {
                _this.SalaryPeriod = [];
            }
        });
    };
    Object.defineProperty(CompanyApprovalGridComponent.prototype, "isMobile", {
        get: function () {
            var browser = new Browser();
            return browser.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridComponent.prototype, "isHideDimension1", {
        get: function () {
            return this.Dimension1Preference && !!this.Dimension1Preference.Value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridComponent.prototype, "isHideDimension2", {
        get: function () {
            return this.Dimension2Preference && !!this.Dimension2Preference.Value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridComponent.prototype, "isHideDimension3", {
        get: function () {
            return this.Dimension3Preference && !!this.Dimension3Preference.Value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridComponent.prototype, "Dimension1Name", {
        get: function () {
            if (!this.isHideDimension1) {
                return '';
            }
            else {
                var dimension1Object = this.sessionService.getCompanyPreference('Dimensions.Dimension1Name');
                if (dimension1Object && dimension1Object.Value) {
                    return dimension1Object.Value;
                }
            }
            return '1';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridComponent.prototype, "Dimension2Name", {
        get: function () {
            if (!this.isHideDimension2) {
                return '';
            }
            else {
                var dimension2Object = this.sessionService.getCompanyPreference('Dimensions.Dimension2Name');
                if (dimension2Object && dimension2Object.Value) {
                    return dimension2Object.Value;
                }
            }
            return '2';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridComponent.prototype, "Dimension3Name", {
        get: function () {
            if (!this.isHideDimension3) {
                return '';
            }
            else {
                var dimension3Object = this.sessionService.getCompanyPreference('Dimensions.Dimension3Name');
                if (dimension3Object && dimension3Object.Value) {
                    return dimension3Object.Value;
                }
            }
            return '3';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridComponent.prototype, "buttonApproveEntriesisviable", {
        get: function () {
            if (this.TimeEntryRecord && this.TimeEntryRecord.length > 0) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridComponent.prototype, "IsReadOnly", {
        get: function () {
            return this.sessionService.role.IsReadOnly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridComponent.prototype, "IsReadOnlyAndHasSubordinates", {
        get: function () {
            if (this.IsReadOnly && this.ApprovalSubodinates && this.ApprovalSubodinates.length > 0) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridComponent.prototype, "IsReadOnlyAndHasSubordinatesAndFilterByEligibleForApproval", {
        get: function () {
            if (this.IsReadOnlyAndHasSubordinates && this.selectedTimeEntryStatusId === -2 && !this.isSpecialMode) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridComponent.prototype, "Dimension1Preference", {
        get: function () {
            return this.sessionService.getCompanyPreference('Dimensions.Dimension1Name');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridComponent.prototype, "Dimension2Preference", {
        get: function () {
            return this.sessionService.getCompanyPreference('Dimensions.Dimension2Name');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridComponent.prototype, "Dimension3Preference", {
        get: function () {
            return this.sessionService.getCompanyPreference('Dimensions.Dimension3Name');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyApprovalGridComponent.prototype, "allowManagersToApproveTimeEntryMessage", {
        get: function () {
            if (!this.allowManagersToApproveTimeEntry) {
                return 'EmploymentTime.DisableGridTimeMessage';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    CompanyApprovalGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staticDataService.TimeEntryStatus.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (timeEntryStatus) {
            _this.baseTimeEntryStatus = timeEntryStatus;
            if (!_this.baseTimeEntryStatus || (_this.baseTimeEntryStatus && _this.baseTimeEntryStatus.length === 0)) {
                return;
            }
            _this.enableTranslation();
            var objectAllowManagersToApproveTimeEntry = _this.sessionService.getCompanyPreference('Access.AllowManagersToApproveTimeEntry');
            if (objectAllowManagersToApproveTimeEntry && objectAllowManagersToApproveTimeEntry.Value === 'true') {
                _this.allowManagersToApproveTimeEntry = true;
            }
            else {
                if (_this.sessionService.role.IsSalaryAdminAndHigher) {
                    _this.allowManagersToApproveTimeEntry = true;
                }
            }
            _this.ApprovalSubodinates = Global.SESSION.ManagerForUserEmploymentIds;
            _this.loadDataSourceSalaryCycle();
            _this.dataService
                .TimeEntryTypes_GetTimeEntryTypeViews()
                .subscribe(function (data) { return _this.onGetTimeEntryTypesCompleted(data); });
        });
    };
    CompanyApprovalGridComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    CompanyApprovalGridComponent.prototype.loadTimeEntryRecord = function (periodId) {
        var _this = this;
        this.TimeEntryRecord = [];
        this.dataService
            .TimeEntry_GetTimeEntryRecordsByPeriod(periodId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) {
            _this.timeEntryRecords = [];
            data.forEach(function (model) {
                model.RejectionReason = model.Status && model.Status.Key !== 'Rejected' ? '' : model.RejectionReason;
                model.iconFeildApprove = 'SuccessNonGreen';
                if (model.Status) {
                    if ((model.Status.Key === 'Open' || model.Status.Key === 'EmployeeApproved') &&
                        (!_this.IsReadOnly ||
                            (_this.IsReadOnly && _this.isSubordinatesModel(model.UserEmploymentId)) ||
                            _this.sessionService.role.EmployeeWithRoleAsManager ||
                            _this.sessionService.role.IsSalaryAdminAndHigher)) {
                        model.isCanReject = true;
                        model.isCanSingleApprove = true;
                    }
                    else if (model.Status.Key === 'ManagerApproved' && _this.sessionService.role.IsSalaryAdminAndHigher) {
                        model.isCanReject = true;
                        model.isCanSingleApprove = true;
                    }
                    else {
                        model.isCanReject = false;
                        model.isCanSingleApprove = false;
                    }
                }
                model.isDisbaleReject = !model.isCanReject || !_this.allowManagersToApproveTimeEntry;
                model.isDisbaleSingleApprove = !model.isCanSingleApprove || !_this.allowManagersToApproveTimeEntry;
                var timeEntryIndex = _this.checkTimeEntryExist(model, _this.timeEntryRecords);
                if (timeEntryIndex >= 0) {
                    _this.timeEntryRecords[timeEntryIndex].timeEntries.push(model);
                }
                else {
                    _this.timeEntryRecords.push({
                        Id: model.EmployeeName,
                        DepartmentName: model.DepartmentName,
                        Title: model.Title,
                        timeEntries: [model]
                    });
                }
            });
            _this.defaultTimeEntryRecords = _this.timeEntryRecords;
            var dataTimeEntry = [];
            _this.timeEntryRecords.forEach(function (timeEntry) {
                var TimeEntry = {
                    EmployeeName: timeEntry.Id,
                    DepartmentName: timeEntry.DepartmentName,
                    Title: timeEntry.Title,
                    TotalTime: 0,
                    TotalDay: 0,
                    KilometersDriven: 0,
                    Status: '',
                    UserEmploymentId: 0,
                    timeEntries: 0,
                    isHaveRecords: timeEntry.timeEntries && timeEntry.timeEntries.length > 0
                };
                var status = [];
                var userEmploymentId = [];
                timeEntry.timeEntries.forEach(function (entry) {
                    userEmploymentId.push(entry.UserEmploymentId);
                    status.push(_this.baseTimeEntryStatus.find(function (model) { return model.Id === entry.Status.Id; }));
                });
                TimeEntry.timeEntries = timeEntry.timeEntries;
                TimeEntry.Status = status;
                TimeEntry.UserEmploymentId = userEmploymentId;
                dataTimeEntry.push(TimeEntry);
            });
            _this.DefaultTimeEntryRecord = dataTimeEntry;
            _this.loadDataSourceTimeEntryStatus(dataTimeEntry);
        });
    };
    CompanyApprovalGridComponent.prototype.checkTimeEntryExist = function (timeEntry, timeEntries) {
        var existTimeEntryIndex = -1;
        timeEntries.forEach(function (data, index) {
            if (existTimeEntryIndex >= 0) {
                return;
            }
            if (data.Id === timeEntry.EmployeeName) {
                existTimeEntryIndex = index;
            }
        });
        return existTimeEntryIndex;
    };
    Object.defineProperty(CompanyApprovalGridComponent.prototype, "TooltipFroApproButtonAprrovalGrid", {
        get: function () {
            if (!this.allowManagersToApproveTimeEntry) {
                return this.allowManagersToApproveTimeEntryMessage;
            }
            return 'EmploymentTime.ApproveThisRecord';
        },
        enumerable: true,
        configurable: true
    });
    CompanyApprovalGridComponent.prototype.isSubordinatesModel = function (numberEmployment) {
        if (this.IsReadOnly && this.ApprovalSubodinates && this.ApprovalSubodinates.length > 0) {
            return this.ApprovalSubodinates.filter(function (dataId) { return numberEmployment === dataId; }).length > 0;
        }
        return false;
    };
    CompanyApprovalGridComponent.prototype.onBaseSalaryCycleComboboxChanged = function (value) {
        var _this = this;
        if (this.selectedSalaryCycleId) {
            if (this.isSpecialMode) {
                this.TimeEntryRecord = [];
                this.loadDataSourceForSpecialMode();
            }
            else {
                if (this.preSalaryCycleId !== this.selectedSalaryCycleId) {
                    // this.sessionService.currentSalaryCycleId = this.selectedSalaryCycleId;
                    this.TimeEntryRecord = [];
                    this.TimeEntryStatus = [];
                    this.currentSalaryCycle = this.SalaryCycle.find(function (model) { return model.Id === _this.selectedSalaryCycleId; });
                    this.populateDataApprovalTimeEntry();
                    this.preSalaryCycleId = this.selectedSalaryCycleId;
                    this.preSalaryPeriodId = 0;
                    this.preStatus = 0;
                    this.preSalaryPeriodId = undefined;
                    this.preStatus = undefined;
                    if (this.isSalaryPeriodChanging) {
                        this.isSelect1020default = false;
                    }
                    else {
                        this.stillSelectstatus = undefined;
                        this.isSelect1020default = true;
                    }
                    this.isSalaryCycleChanging = true;
                }
            }
        }
    };
    CompanyApprovalGridComponent.prototype.onBaseSalaryPeriodComboboxChanged = function (value) {
        var _this = this;
        if (this.selectedSalaryPeriodId && this.preSalaryPeriodId !== this.selectedSalaryPeriodId) {
            this.sessionService.currentSalaryCycleId = this.selectedSalaryCycleId;
            this.sessionService.currentSalaryPeriodId = this.selectedSalaryPeriodId;
            this.currentSalaryPeriod = this.SalaryPeriod.find(function (model) { return model.Id === _this.selectedSalaryPeriodId; });
            this.selectedTimeEntryStatusId = undefined;
            if (this.isSalaryPeriodChanging) {
                this.isSelect1020default = false;
            }
            else {
                this.stillSelectstatus = undefined;
                this.isSelect1020default = true;
            }
            this.loadTimeEntryRecord(this.selectedSalaryPeriodId);
            this.preSalaryPeriodId = this.selectedSalaryPeriodId;
            this.isSalaryPeriodChanging = true;
        }
    };
    CompanyApprovalGridComponent.prototype.onBaseTimeEntryStatusComboboxChanged = function (value) {
        var _this = this;
        if (this.selectedTimeEntryStatusId && this.preStatus !== this.selectedTimeEntryStatusId) {
            setTimeout(function () {
                _this.currentTimeEntryStatus = _this.TimeEntryStatus.filter(function (model) {
                    return model.Id === _this.selectedTimeEntryStatusId;
                })[0];
                _this.preStatus = _this.selectedTimeEntryStatusId;
                _this.stillSelectstatus = _this.selectedTimeEntryStatusId;
                _this.filterTimeEntriesByStatus();
                setTimeout(function () {
                    var dataTimeEntry = [];
                    _this.TimeEntryRecord.forEach(function (record) {
                        var result = _this.calculateBasicData(record.timeEntries);
                        record.TotalDay = result.TotalDay;
                        record.TotalTime = result.TotalTime;
                        record.KilometersDriven = result.KilometersDriven;
                        dataTimeEntry.push(record);
                    });
                    _this.TimeEntryRecord = dataTimeEntry;
                });
            });
        }
    };
    CompanyApprovalGridComponent.prototype.calculateBasicData = function (timeEntries) {
        var _this = this;
        var TotalTime = 0;
        var TotalDay = 0;
        var KilometersDriven = 0;
        timeEntries.forEach(function (entry) {
            if (_this.currentTimeEntryStatus.Id === -2) {
                if (entry.Status.Id !== 10 && entry.Status.Id !== 20) {
                    return;
                }
            }
            else if (_this.currentTimeEntryStatus.Id !== entry.Status.Id && _this.currentTimeEntryStatus.Id !== -1) {
                return;
            }
            var unitType = entry.UnitTypeId && _this.UnitTypes
                ? _this.UnitTypes.find(function (type) { return type.Id === entry.UnitTypeId; })
                : undefined;
            var isHoursUnitType = unitType && unitType.Key === 'Hours';
            var isDaysUnitType = unitType && unitType.Key === 'Days';
            if (isHoursUnitType) {
                TotalTime += entry.Units;
            }
            else if (isDaysUnitType) {
                TotalDay += entry.Units;
            }
            KilometersDriven += entry.KilometersDriven;
        });
        var record = {
            TotalTime: TotalTime,
            TotalDay: TotalDay,
            KilometersDriven: KilometersDriven
        };
        return record;
    };
    CompanyApprovalGridComponent.prototype.loadDataSourceTimeEntryStatus = function (timeEntryRecord) {
        var _this = this;
        if (timeEntryRecord && timeEntryRecord.length > 0) {
            this.TimeEntryStatus = [];
            timeEntryRecord.forEach(function (item) {
                if (item && item.Status) {
                    item.Status.forEach(function (status) {
                        var isStatusExist = _this.TimeEntryStatus.filter(function (timeEntryStatus) { return timeEntryStatus.Id === status.Id; }).length > 0;
                        if (!isStatusExist) {
                            _this.TimeEntryStatus.push(status);
                        }
                    });
                }
            });
            this.sortTimeEntryStatus();
            if (this.TimeEntryStatus && this.TimeEntryStatus.length >= 1) {
                var filter1020 = new TimeEntryStatus();
                filter1020.Id = -2;
                filter1020.Name = this.filterEligibleforapproval;
                this.TimeEntryStatus.push(filter1020);
                var allStatus = { Id: -1, Name: this.AllText };
                this.TimeEntryStatus.unshift(allStatus);
                if (this.isSelect1020default && this.isSelect1020default === true) {
                    this.currentTimeEntryStatus = filter1020;
                    this.selectedTimeEntryStatusId = this.currentTimeEntryStatus.Id;
                }
                else {
                    var tempstatus = this.TimeEntryStatus.filter(function (model) { return model.Id === _this.stillSelectstatus; });
                    if (tempstatus && tempstatus.length > 0) {
                        this.currentTimeEntryStatus = tempstatus[0];
                        this.selectedTimeEntryStatusId = this.stillSelectstatus;
                    }
                    else {
                        this.currentTimeEntryStatus = allStatus;
                        this.selectedTimeEntryStatusId = this.currentTimeEntryStatus.Id;
                    }
                }
            }
            else {
                this.TimeEntryStatus = [];
                var allStatus = { Id: -1, Name: this.AllText };
                this.TimeEntryStatus.unshift(allStatus);
                this.currentTimeEntryStatus = allStatus;
                this.selectedTimeEntryStatusId = this.currentTimeEntryStatus.Id;
            }
        }
        else {
            this.TimeEntryStatus = [];
            var allStatus = { Id: -1, Name: this.AllText };
            this.TimeEntryStatus.unshift(allStatus);
            this.currentTimeEntryStatus = allStatus;
            this.selectedTimeEntryStatusId = this.currentTimeEntryStatus.Id;
        }
        this.preStatus = undefined;
        this.onBaseTimeEntryStatusComboboxChanged(undefined);
    };
    CompanyApprovalGridComponent.prototype.sortTimeEntryStatus = function () {
        var _this = this;
        if (this.TimeEntryStatus && this.TimeEntryStatus.length > 0) {
            this.TimeEntryStatus.sort(function (a, b) {
                return _this.baseTimeEntryStatus.indexOf(a) > _this.baseTimeEntryStatus.indexOf(b) ? 1 : -1;
            });
        }
    };
    CompanyApprovalGridComponent.prototype.enableTranslation = function () {
        var _this = this;
        this.sessionService.OnTranslateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function () {
            _this.translateService.get(_this.translationServiceTerms).subscribe(function (translations) {
                _this.setTranslationTerms(translations);
            });
        });
    };
    CompanyApprovalGridComponent.prototype.setTranslationTerms = function (translations) {
        this.PeriodWarningText = translations['EmploymentTime.PeriodWarning'];
        this.AllText = translations['CompanySalaryBatches.SalaryCycle_Item_All_Text'];
        this.filterEligibleforapproval = translations['EmploymentTime.EligibleForApproval'];
    };
    CompanyApprovalGridComponent.prototype.onGetTimeEntryTypesCompleted = function (timeEntryTypes) {
        if (timeEntryTypes && timeEntryTypes.length > 0) {
            this.TimeEntryTypes = timeEntryTypes;
            this.TimeEntryTypes.forEach(function (model) {
                model.Id = model.Id || model.BaseTimeEntryTypeId || null;
                model.InternalReferenceId = model.InternalReferenceId ? model.InternalReferenceId : null;
            });
            this.defaultTimeEntryType = timeEntryTypes.find(function (mode) { return mode.Identifier === 'Work'; });
            if (!this.defaultTimeEntryType) {
                this.defaultTimeEntryType = timeEntryTypes[0];
            }
        }
    };
    CompanyApprovalGridComponent.prototype.filterTimeEntriesByStatus = function (dataSource) {
        var _this = this;
        if (this.TimeEntryRecord) {
            var isChange = false;
            if (this.selectedTimeEntryStatusId >= 0) {
                this.TimeEntryRecord = this.DefaultTimeEntryRecord.filter(function (model) {
                    return _this.checkIsCorrect(model.Status, _this.currentTimeEntryStatus.Name, true);
                });
                isChange = true;
            }
            else if (this.selectedTimeEntryStatusId === -2) {
                this.TimeEntryRecord = this.DefaultTimeEntryRecord.filter(function (model) {
                    return _this.checkIsCorrect(model.Status, 10, false) || _this.checkIsCorrect(model.Status, 20, false);
                });
                if (this.IsReadOnly) {
                    if (this.ApprovalSubodinates && this.ApprovalSubodinates.length > 0) {
                        this.TimeEntryRecord = this.TimeEntryRecord.filter(function (model) {
                            return (_this.ApprovalSubodinates.filter(function (dataId) {
                                return _this.checkIsCorrect(model.UserEmploymentId, dataId);
                            }).length > 0);
                        });
                    }
                    else {
                        this.TimeEntryRecord = [];
                    }
                }
                isChange = true;
            }
            else {
                this.TimeEntryRecord = this.DefaultTimeEntryRecord;
                this.timeEntryRecords = this.defaultTimeEntryRecords;
            }
            if (this.approvalFilter && this.approvalFilter.filters && this.approvalFilter.filters.length > 0) {
                this.onApprovalFilterChange(this.approvalFilter);
            }
            if (isChange) {
                this.timeEntryRecords = this.defaultTimeEntryRecords.filter(function (model) {
                    var length = _this.TimeEntryRecord.length;
                    var isCorrect = false;
                    for (var i = 0; i < length; i++) {
                        if (_this.TimeEntryRecord[i].EmployeeName === model.Id) {
                            isCorrect = true;
                            break;
                        }
                    }
                    return isCorrect;
                });
            }
            this.currentpage = 0;
        }
    };
    CompanyApprovalGridComponent.prototype.checkIsCorrect = function (dataCompare, compareValue, isName) {
        var isCorrect = false;
        if (dataCompare) {
            if (isName === undefined || isName === null) {
                for (var i = 0; i < dataCompare.length; i++) {
                    var data = dataCompare[i];
                    if (data === compareValue) {
                        isCorrect = true;
                        break;
                    }
                }
            }
            else if (isName) {
                for (var i = 0; i < dataCompare.length; i++) {
                    var data = dataCompare[i].Name;
                    if (data === compareValue) {
                        isCorrect = true;
                        break;
                    }
                }
            }
            else {
                for (var i = 0; i < dataCompare.length; i++) {
                    var data = dataCompare[i].Id;
                    if (data === compareValue) {
                        isCorrect = true;
                        break;
                    }
                }
            }
        }
        return isCorrect;
    };
    CompanyApprovalGridComponent.prototype.getTimeEntryType = function (id) {
        var timeEntryTypes = this.TimeEntryTypes.filter(function (timeEntryType) { return timeEntryType.Id === id; });
        return timeEntryTypes.length > 0 ? timeEntryTypes[0] : undefined;
    };
    CompanyApprovalGridComponent.prototype.validateTimeEntryType = function (timeEntryTypeId) {
        var newTimeEntryTypeId = timeEntryTypeId;
        var newTimeEntryType = this.TimeEntryTypes.filter(function (tet) { return tet.TimeEntryTypeId === newTimeEntryTypeId; });
        return newTimeEntryType.length > 0;
    };
    CompanyApprovalGridComponent.prototype.ApproveEntries = function () {
        if (this.selectedSalaryPeriodId) {
            this.isShowApproveForAllDialog = true;
        }
    };
    CompanyApprovalGridComponent.prototype.ApproveEntriesallforselectedemployee = function () {
        if (this.selectedemployeeIdinGrid && this.selectedSalaryPeriodId && this.nameEmployeeSelect) {
            this.isShowApproveForSelectDialog = true;
        }
    };
    CompanyApprovalGridComponent.prototype.onSelectRow = function (event) {
        if (!event || event === undefined) {
            return;
        }
        this.selectedItem = event;
        this.selectedMinDate = this.staticDataService.getCurrentdate(false, event.EntryDate);
        this.selectedEntryDate = event.EntryDate;
        if (event && event.timeEntries && event.timeEntries.length > 0 && event.EmployeeName && event.UserEmploymentId) {
            this.selectedemployeeIdinGrid = event.UserEmploymentId[0];
            this.nameEmployeeSelect = event.EmployeeName;
        }
    };
    CompanyApprovalGridComponent.prototype.onGridButtonClick = function (event) {
        if (event && event.buttonAction && event.buttonAction === 'Reject') {
            if (event.dataItem) {
                if (event.dataItem.isCanReject) {
                    this.isShowRejectDialog = true;
                    this.selectedemployeeIdinGrid = event.dataItem.Id;
                    this.preUserEmploymentId = event.dataItem.UserEmploymentId;
                }
            }
        }
    };
    CompanyApprovalGridComponent.prototype.onRejectDialog = function (event) {
        var _this = this;
        if (event) {
            if (event === 'RejectPrimary') {
                var rejectionInfo = { Reason: this.rejectMessage };
                this.dataService.TimeEntry_RejectTimeEntryRecord(this.selectedemployeeIdinGrid, rejectionInfo).subscribe(function () {
                    if (_this.isSpecialMode) {
                        _this.TimeEntryRecord = [];
                        _this.loadDataSourceForSpecialMode();
                        return;
                    }
                    _this.stillSelectstatus = _this.selectedTimeEntryStatusId;
                    _this.isSelect1020default = false;
                    _this.loadTimeEntryRecord(_this.selectedSalaryPeriodId);
                    _this.selectedemployeeIdinGrid = undefined;
                    _this.rejectMessage = undefined;
                }, function () {
                    if (_this.isSpecialMode) {
                        _this.TimeEntryRecord = [];
                        _this.loadDataSourceForSpecialMode();
                        return;
                    }
                    _this.stillSelectstatus = _this.selectedTimeEntryStatusId;
                    _this.isSelect1020default = false;
                    _this.loadTimeEntryRecord(_this.selectedSalaryPeriodId);
                    _this.selectedemployeeIdinGrid = undefined;
                    _this.rejectMessage = undefined;
                });
            }
            else {
                this.rejectMessage = undefined;
                this.selectedemployeeIdinGrid = this.preUserEmploymentId;
            }
        }
    };
    CompanyApprovalGridComponent.prototype.onApproveForSelectDialog = function (event) {
        var _this = this;
        if (event === 'Approve') {
            var periodPUT = this.isSpecialMode ? 0 : this.selectedSalaryPeriodId;
            this.dataService.TimeEntry_ApprovePeriodForEmployee(this.selectedemployeeIdinGrid, periodPUT).subscribe(function () {
                _this.selectedemployeeIdinGrid = undefined;
                _this.nameEmployeeSelect = undefined;
                if (_this.isSpecialMode) {
                    _this.TimeEntryRecord = [];
                    _this.loadDataSourceForSpecialMode();
                    return;
                }
                _this.isSelect1020default = false;
                _this.stillSelectstatus = _this.selectedTimeEntryStatusId;
                _this.loadTimeEntryRecord(_this.selectedSalaryPeriodId);
            }, function () {
                _this.selectedemployeeIdinGrid = undefined;
                _this.nameEmployeeSelect = undefined;
                if (_this.isSpecialMode) {
                    _this.TimeEntryRecord = [];
                    _this.loadDataSourceForSpecialMode();
                    return;
                }
                _this.isSelect1020default = false;
                _this.stillSelectstatus = _this.selectedTimeEntryStatusId;
                _this.loadTimeEntryRecord(_this.selectedSalaryPeriodId);
            });
        }
    };
    CompanyApprovalGridComponent.prototype.onApproveForAllDialog = function (event) {
        var _this = this;
        if (event === 'Approve') {
            this.dataService.TimeEntry_ApproveEntirePeriod(this.selectedSalaryPeriodId).subscribe(function () {
                _this.isSelect1020default = false;
                _this.stillSelectstatus = _this.selectedTimeEntryStatusId;
                _this.loadTimeEntryRecord(_this.selectedSalaryPeriodId);
            }, function () {
                _this.isSelect1020default = false;
                _this.stillSelectstatus = _this.selectedTimeEntryStatusId;
                _this.loadTimeEntryRecord(_this.selectedSalaryPeriodId);
            });
        }
    };
    CompanyApprovalGridComponent.prototype.EmptyEmployeetimeApproval = function () {
        if (this.TimeEntryRecord && this.TimeEntryRecord.length > 0) {
            return '';
        }
        else {
            return 'EmptydataGrid';
        }
    };
    CompanyApprovalGridComponent.prototype.onClickAppGridIcon = function (event) {
        var _this = this;
        if (event &&
            event.iconAction === 'ApproveSingleRecord' &&
            event.dataItem &&
            event.dataItem.Id &&
            event.dataItem.isCanSingleApprove) {
            this.dataService.TimeEntry_ApproveTimeEntryRecord(event.dataItem.Id).subscribe(function (model) {
                if (_this.isSpecialMode) {
                    _this.TimeEntryRecord = [];
                    _this.loadDataSourceForSpecialMode();
                    return;
                }
                _this.isSelect1020default = false;
                _this.loadTimeEntryRecord(_this.selectedSalaryPeriodId);
            });
        }
    };
    CompanyApprovalGridComponent.prototype.loadDataSourceForSpecialMode = function () {
        var _this = this;
        var cycleId = this.selectedSalaryCycleId ? this.selectedSalaryCycleId : 0;
        // Set status all for special mode
        this.currentTimeEntryStatus = { Id: -1, Name: this.AllText };
        this.dataService.TimeEntry_GetOpenTimeEntryRecordsAcrossPeriods(cycleId).subscribe(function (data) {
            if (data && data.length > 0) {
                _this.timeEntryRecords = [];
                data.forEach(function (model) {
                    model.RejectionReason = model.Status && model.Status.Key !== 'Rejected' ? '' : model.RejectionReason;
                    model.iconFeildApprove = 'SuccessNonGreen';
                    if (model.Status) {
                        if ((model.Status.Key === 'Open' || model.Status.Key === 'EmployeeApproved') &&
                            (!_this.IsReadOnly ||
                                (_this.IsReadOnly && _this.isSubordinatesModel(model.UserEmploymentId)) ||
                                _this.sessionService.role.EmployeeWithRoleAsManager ||
                                _this.sessionService.role.IsSalaryAdminAndHigher)) {
                            model.isCanReject = true;
                            model.isCanSingleApprove = true;
                        }
                        else if (model.Status.Key === 'ManagerApproved' && _this.sessionService.role.IsSalaryAdminAndHigher) {
                            model.isCanReject = true;
                            model.isCanSingleApprove = true;
                        }
                        else {
                            model.isCanReject = false;
                            model.isCanSingleApprove = false;
                        }
                    }
                    model.isDisbaleReject = !model.isCanReject || !_this.allowManagersToApproveTimeEntry;
                    model.isDisbaleSingleApprove = !model.isCanSingleApprove || !_this.allowManagersToApproveTimeEntry;
                    var timeEntryIndex = _this.checkTimeEntryExist(model, _this.timeEntryRecords);
                    if (timeEntryIndex >= 0) {
                        _this.timeEntryRecords[timeEntryIndex].timeEntries.push(model);
                    }
                    else {
                        _this.timeEntryRecords.push({
                            Id: model.EmployeeName,
                            timeEntries: [model]
                        });
                    }
                });
                _this.TimeEntryRecord = JSON.parse(JSON.stringify(data));
                _this.defaultTimeEntryRecords = _this.timeEntryRecords;
                var dataTimeEntry_1 = [];
                _this.timeEntryRecords.forEach(function (timeEntry) {
                    var TimeEntry = {
                        EmployeeName: timeEntry.Id,
                        TotalTime: 0,
                        TotalDay: 0,
                        KilometersDriven: 0,
                        Status: '',
                        UserEmploymentId: 0,
                        timeEntries: 0,
                        isHaveRecords: timeEntry.timeEntries && timeEntry.timeEntries.length > 0
                    };
                    var status = [];
                    var userEmploymentId = [];
                    timeEntry.timeEntries.forEach(function (entry) {
                        var unitType = entry.UnitTypeId && _this.UnitTypes
                            ? _this.UnitTypes.find(function (type) { return type.Id === entry.UnitTypeId; })
                            : undefined;
                        var isHoursUnitType = unitType && unitType.Key === 'Hours';
                        var isDaysUnitType = unitType && unitType.Key === 'Days';
                        if (isHoursUnitType) {
                            TimeEntry.TotalTime += entry.Units;
                        }
                        else if (isDaysUnitType) {
                            TimeEntry.TotalDay += entry.Units;
                        }
                        TimeEntry.KilometersDriven += entry.KilometersDriven;
                        userEmploymentId.push(entry.UserEmploymentId);
                        status.push(entry.Status);
                    });
                    TimeEntry.timeEntries = timeEntry.timeEntries;
                    TimeEntry.Status = status;
                    TimeEntry.UserEmploymentId = userEmploymentId;
                    dataTimeEntry_1.push(TimeEntry);
                });
                _this.TimeEntryRecord = dataTimeEntry_1;
                _this.DefaultTimeEntryRecord = JSON.parse(JSON.stringify(dataTimeEntry_1));
            }
            else {
                _this.DefaultTimeEntryRecord = [];
            }
        });
    };
    Object.defineProperty(CompanyApprovalGridComponent.prototype, "isShowSalaryCycle", {
        get: function () {
            if (this.isSpecialMode && this.SalaryCycle && this.SalaryCycle.length === 1) {
                return false;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    CompanyApprovalGridComponent.prototype.onModeChange = function () {
        this.TimeEntryRecord = [];
        this.selectedemployeeIdinGrid = undefined;
        this.nameEmployeeSelect = undefined;
        if (this.isSpecialMode) {
            this.loadDataSourceForSpecialMode();
        }
        else {
            this.SalaryPeriod = [];
            this.SalaryCycle = [];
            this.selectedSalaryPeriodId = undefined;
            this.selectedTimeEntryStatusId = undefined;
            this.preSalaryPeriodId = undefined;
            this.loadDataSourceSalaryCycle();
        }
    };
    Object.defineProperty(CompanyApprovalGridComponent.prototype, "isEnableControlNomalMode", {
        get: function () {
            if (this.isSpecialMode) {
                return false;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    CompanyApprovalGridComponent.prototype.ApproveOpenEntries = function () {
        this.isShowApprovalOpenEntries = true;
    };
    CompanyApprovalGridComponent.prototype.onApprovalOpenEntries = function (event) {
        var _this = this;
        if (event && event === 'Understood') {
            this.dataService.TimeEntry_ApproveEntireCycle(this.selectedSalaryCycleId).subscribe(function () {
                _this.TimeEntryRecord = [];
                _this.loadDataSourceForSpecialMode();
            }, function () {
                _this.TimeEntryRecord = [];
                _this.loadDataSourceForSpecialMode();
            });
        }
    };
    CompanyApprovalGridComponent.prototype.onApprovalFilterChange = function (approvalFilter) {
        if (approvalFilter && approvalFilter.filters && approvalFilter.filters.length > 0) {
            var filterDateTimeGrid = new FilterDateTimeGrid();
            var filtersDate = [];
            // Using FilterDateTimeGrid to filter date before filter another field
            this.approvalFilter = filterDateTimeGrid.convertFilterOperator(approvalFilter, filtersDate, 'EntryDate', 'EndDate');
            this.TimeEntryRecord = filterDateTimeGrid.doFilterDate(this.DefaultTimeEntryRecord, filtersDate, 'EntryDate', 'EndDate');
            this.TimeEntryRecord = filterBy(this.TimeEntryRecord, this.approvalFilter);
            if (filtersDate.length > 0) {
                [].push.apply(this.approvalFilter.filters, filtersDate);
            }
        }
        else {
            this.TimeEntryRecord = this.DefaultTimeEntryRecord;
            if (!this.isSpecialMode) {
                this.selectedTimeEntryStatusId = -1;
                this.onBaseTimeEntryStatusComboboxChanged(undefined);
            }
        }
    };
    CompanyApprovalGridComponent.prototype.onApproveRejectData = function () {
        if (this.isSpecialMode) {
            this.loadDataSourceForSpecialMode();
        }
        else {
            this.loadTimeEntryRecord(this.selectedSalaryPeriodId);
        }
    };
    return CompanyApprovalGridComponent;
}());
export { CompanyApprovalGridComponent };
